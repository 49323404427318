import React, {useEffect,useState } from 'react';

import { getTeachersForSchool,getClassroomsForSchoolId,sendInvite,setSelecetdSchool} from '../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from "reactstrap";
import { matchPath } from "react-router";
//i18n
import { withNamespaces } from 'react-i18next';
import TeacherCard from './TeacherCard';
import TecaherList from './TecaherList';
import Topbar from './Topbar';
import * as Constants from '../../Constents';

const Index = (props) => { 
    
    const match1 = matchPath(props.history.location.pathname, {
        path: "/staff/:id",
        exact: true,
        strict: false,
    });
    const match2 = matchPath(props.history.location.pathname, {
        path: "/coordinators/:id",
        exact: true,
        strict: false,
    });

    const match=match1??match2;

    const [displayType, setType] = useState(Constants.DISPLAY_TYPE_LIST);
    const handleDisplay=(type)=>{
        localStorage.setItem(Constants.TEACHER_DISPLAY_TYPE,type);
        setType(type);
    }
    const schoolId = match.params.id;
    const [schoolType, setSchoolType] = useState(1);
    const [showIDCard, setShowIdCard] = useState(false);
    useEffect(() => {
        props.getTeachersForSchool(schoolId);
        props.getClassroomsForSchoolId(schoolId)
        let dt = localStorage.getItem(Constants.TEACHER_DISPLAY_TYPE);
        if ( dt) {
            setType(dt);
        }
    }, [schoolId]);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if (props.selectedSchool) {
            setSchoolType(props.selectedSchool.attributes.schoolType);
        }
        if (props.selectedSchool && (!props.selectedSchool.attributes.franchiseId || props.selectedSchool.attributes.franchiseId.length == 0)) {
            setShowIdCard(true)
        }
    }, [props.selectedSchool, schoolId]);
    
    
    useEffect(() => {
        if (props.franchise) {
            if (props.franchise.attributes.features &&
                props.franchise.attributes.features.length > Constants.FRANCHISE_ID_CARD_DISABLE_INDEX &&
                props.franchise.attributes.features[Constants.FRANCHISE_ID_CARD_DISABLE_INDEX] > 0) {
                setShowIdCard(false)
            }
            else if (!showIDCard) {
                setShowIdCard(true)
            }
        }
   
    }, [props.franchise]);


    const [dirctorList, setDirector] = useState([]);
    const [centrHeadList, setCenterHead] = useState([]);
    const [teacherList, setTeacher] = useState([]);
    const [otherStaffList, setOtherStaff] = useState([]);
    useEffect(() => {
        var tempList = [...props.teachers];
        updateLists(tempList);
    }, [props.teachers]);
    
    function updateLists(tempList) {
        var DList = [];
        var CList = [];
        var TList = [];
        var OList = [];
        if (props.teachers) {
            for (const i in tempList) {
                if (tempList[i].attributes.Role === 3) {
                    DList.push(tempList[i]);
                } else if (tempList[i].attributes.Role === 2) {
                    CList.push(tempList[i]);
                } else if (tempList[i].attributes.Role === 1) {
                    TList.push(tempList[i]);
                } else {
                    OList.push(tempList[i]);
                }
               
            }
        }
        setDirector(DList);
        setCenterHead(CList);
        setTeacher(TList);
        setOtherStaff(OList);
    }

    const handleSearch = (search) => {
        var tempList = [...props.teachers];
        if (search === undefined || search === null || search.length === 0) {
            updateLists(tempList);
        } else if(tempList){
            let filter = tempList.filter( (item)=> {
                return (item.attributes.Name.toLowerCase()).includes(search.toLowerCase()) ||
                    (item.attributes.EMail && (item.attributes.EMail.toLowerCase()).includes(search.toLowerCase())) ||
                (item.attributes.PhoneNumber&&(item.attributes.PhoneNumber.toLowerCase()).includes(search.toLowerCase()));
            });
            updateLists(filter);
        }
    }

    const handelSendInvite = (teacherId) => {
        var schoolName = "";
        if (props.selectedSchool) {
            schoolName = props.selectedSchool.attributes.Name;
        }
        props.sendInvite("", schoolName, props.t('Invite sent successfully!'), teacherId);
    }



    return (
            
        <React.Fragment>
            
            <div className="page-content ">
                <Container>
                    <Topbar  title={schoolType==10?props.t('Coordinators'):props.t('Staff')}schoolId={schoolId} schoolType={schoolType} selected={displayType} onSelect={handleDisplay} onSearch={handleSearch} />
                    <Row>
                        
                        {
                            displayType === Constants.DISPLAY_TYPE_LIST ?
                                <Col>

                                    {dirctorList && dirctorList.length > 0 && <TecaherList teachers={dirctorList} classrooms={props.classrooms} schoolId={schoolId}showIDCard={showIDCard} schoolType={ schoolType}role={schoolType ===10? props.t('Admins') :props.t('Directors')} sendInvite={ handelSendInvite} selectedSchool={props.selectedSchool}/>}
                                    {centrHeadList && centrHeadList.length > 0 && <TecaherList teachers={centrHeadList} classrooms={props.classrooms} schoolId={ schoolId}showIDCard={showIDCard}  schoolType={ schoolType} role={schoolType ===10? props.t('Course Coordinators') :props.t('Center Heads')}sendInvite={ handelSendInvite} selectedSchool={props.selectedSchool}/>}
                                    {teacherList && teacherList.length > 0 && <TecaherList teachers={teacherList} classrooms={props.classrooms} schoolId={schoolId} showIDCard={showIDCard} schoolType={ schoolType} role={props.t('Teachers')}sendInvite={ handelSendInvite} selectedSchool={props.selectedSchool}/>}
                                    {otherStaffList && otherStaffList.length>0 &&<TecaherList teachers={otherStaffList} classrooms={props.classrooms} schoolId={schoolId}showIDCard={showIDCard}   schoolType={ schoolType}role={props.t('Other Staff')}sendInvite={ handelSendInvite} selectedSchool={props.selectedSchool} />}
                                </Col> :
                                
                                <Col>
                                    {dirctorList && dirctorList.length>0 &&<TeacherCard teachers={dirctorList} classrooms={props.classrooms}schoolId={schoolId} showIDCard={showIDCard} schoolType={ schoolType}role={schoolType ===10? props.t('Admins') : props.t('Directors')} />}
                                    {centrHeadList && centrHeadList.length>0 &&<TeacherCard teachers={centrHeadList} classrooms={props.classrooms}schoolId={schoolId}showIDCard={showIDCard} schoolType={ schoolType} role={schoolType ===10? props.t('Course Coordinators') :props.t('Center Heads')}/>}
                                    {teacherList && teacherList.length>0 &&<TeacherCard teachers={teacherList}classrooms={props.classrooms}schoolId={schoolId}showIDCard={showIDCard}  schoolType={ schoolType}role={props.t('Teachers')} />}
                                    {otherStaffList && otherStaffList.length>0 &&<TeacherCard teachers={otherStaffList} classrooms={props.classrooms}schoolId={schoolId}showIDCard={showIDCard}  schoolType={ schoolType}role={props.t('Other Staff')} />}
                                </Col> 
                        }
                    
                    </Row>
                  
                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { teachers } = state.Teacher;
    const { schools,selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { franchise } = state.Franchise;
    return { teachers,schools,classrooms,selectedSchool,franchise};
}
 
export default withNamespaces()(withRouter(connect(mapStatetoProps, { getTeachersForSchool ,getClassroomsForSchoolId,setSelecetdSchool,sendInvite})(Index)));