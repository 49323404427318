import * as actions from "./actionTypes"

export const updateTicket = (ticket) => {
    return {
        type: actions.UPDATE_TICKET,
        payload: { ticket },
    }
}

export const deleteTicket = (ticket,logObject) =>{
    return{
        type:actions.DELETE_TICKET,
        payload: {ticket,logObject},
    }
}

export const updateDeleteTicket = (ticket) =>{
    return{
        type:actions.UPDATE_DELETE_TICKET,
        payload: {ticket},
    }
}

export const updateTickets = (tickets) =>{
    return{
        type:actions.UPDATE_TICKETS,
        payload: {tickets},
    }
}

export const getTicketsForRecipientId = (recipientId, recipientType) =>{
    return{
    type: actions.GET_TICKETS,
    payload: {recipientId, recipientType},
    }
}

export const clearTickets = () =>{
    return{
        type: actions.CLEAR_TICKETS,
        payload: {},
    }
}

export const getTicketsForAssignedFor = (assignedFor, assignedForType) => {
    return {
        type: actions.GET_TICKETS_FOR_ASSIGNED_FOR,
        payload: {assignedFor, assignedForType}
    }
}

export const editTicket = (ticket,ticketLog,shouldLogUpdate) =>{
    return{
        type: actions.EDIT_TICKET,
        payload: {ticket,ticketLog,shouldLogUpdate}
    }
}

export const setTicket = (state) =>{
    return{
        type: actions.SET_TICKET,
        payload: {state}
    }
}

export const addTicket = (ticket,ticketLog) =>{
    return{
        type: actions.ADD_TICKET,
        payload: {ticket,ticketLog}
    }
}

export const setSelectedTicket = (ticketId) => {
    return {
        type: actions.SET_SELECTED_TICKET,
        payload: { ticketId }
    }
}

export const updateSelectedTicket = (ticket) => {
    return {
        type: actions.UPDATE_SELECTED_TICKET,
        payload: { ticket },
    };
};

export const setSelecetdTicketTab = (seletedTab) => {
    return {
        type: actions.SET_SELECTED_TAB,
        payload: { seletedTab },
    };
};


//TICKET LOG

export const addTicketLog = (ticketLog,exObject) => {
    return{
        type: actions.ADD_TICKET_LOG,
        payload: {ticketLog,exObject}
    }
}


export const setSelectedTicketLog = (ticketId) => {
    return {
        type: actions.SET_SELECTED_TICKET_LOG,
        payload: { ticketId }
    }
}

export const updateSelectedTicketLog = (ticketLog, unshift, update) => {
    return {
        type: actions.UPDATE_SELECTED_TICKET_LOG,
        payload: { ticketLog, unshift, update },
    };
};

export const deleteTicketLog = (ticketLog) =>{
    return{
        type:actions.DELETE_TICKET_LOG,
        payload: {ticketLog},
    }
}
