import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Label,
    Card,
    CardBody,
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import * as Constants from "../../Constents";

import toastr from "toastr";
import TopBar from "./Topbar";
import moment from "moment";

import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";

import {
    setSelecetdSchool,
    getTeacherForIds,
    getUserTeacher
} from "../../store/actions";
import ReactDatePicker from "react-datepicker";

const MonthlyReportSession = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/monthly-reports-view/:schoolId/:reportId",
        exact: true,
        strict: false,
    });

    const matchFranchise = matchPath(props.history.location.pathname, {
        path: "/franchise-monthly-reports-view/:reportId",
        exact: true,
        strict: false,
    });



    var schoolId = undefined;
    var reportId = undefined;



    if (match) {
        schoolId = match.params.schoolId;
        reportId = match.params.reportId;
    } else if (matchFranchise) {
        schoolId = matchFranchise.params.schoolId;
        reportId = matchFranchise.params.reportId;
    }


    const [showDuplicateModal, setShowDuplicateModal] = useState(undefined);
    const [reportIsDuplicating, setReportIsDuplicating] = useState(false)
    const [selectedUserId, setSelectedUserId] = useState("");
    const [selectedUserName, setSelectedUserName] = useState("");
    const [reportName, setExamName] = useState("");
    const [examObject, setExamObject] = useState(undefined)

    const [selectedSessionObject, setSelectedSessionObject] = useState(undefined);
    const [selectedUserType, setSelectedUserType] = useState("");
    const [isLoading, setIsLoading] = useState(true);
    const [displayList, setDisplayList] = useState([]);
    const [showMappingModal, setShowMappingModal] = useState(false);
    const [classroomList, setClassroomList] = useState([]);
    const [selectedClassroomFilter, setSelectedClassroomFilter] =
        useState(undefined);
    const [quizVisibility, setQuizVisibility] = useState(undefined);
    const [activeTab, setactiveTab] = useState("1");

    const [searchBarString, setSearchBarString] = useState("");
    const [isMappingInProgress, setIsMappingInProgress] = useState(false);
    const handleSearchBarTextChange = (value) => {
        setSearchBarString(value);
    };
    const [selectedMonthDateWhileDuplicate, setSelectedMonthDateWhileDuplicate] = useState(undefined)
    const [reportTemplateName, setReportTemplateName] = useState("Monthly Reports")
    const [consolidatedReport, setConsolidatedReport] = useState(false);
    const [reportType, setReportType] = useState(undefined)
    const [isSuperAdmin, setIsSuperAdmin] = useState(undefined)
    const [dataIsReadyToDisplay, setDataIsReadyToDisplay] = useState(false)
    const [sessionObjectReady, setSessionObjectReady] = useState(false)

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        //props.getTeachersForSchool(schoolId);
    }, [props.selectedSchool, schoolId]);

    const getTeacherName = (objectId) => {
        let teacherName = "-"
        if (props.teachersForIds && props.teachersForIds.length > 0) {
            let tempUser = props.teachersForIds.filter((teacher) => {
                return teacher.id === objectId;
            });
            if (tempUser && tempUser.length > 0) {
                teacherName = tempUser[0].attributes.Name;
            }
        }
        return teacherName


    }

    useEffect(() => {
        if (props.userTeacher) {
            if (props.userTeacher.attributes.Role === 11) {
                setIsSuperAdmin(true)
            } else {
                setIsSuperAdmin(false)
            }
        }
    }, [props.userTeacher]);



    useEffect(() => {
        if (isSuperAdmin != undefined && dataIsReadyToDisplay) {
            if (isLoading === true) {
                setIsLoading(false)
            }
        }
    }, [isSuperAdmin, dataIsReadyToDisplay]);

    useEffect(() => {
        if (Parse.User.current()) {
            
            if (Parse.User.current().attributes.teacherId) {
                props.getUserTeacher(Parse.User.current().attributes.teacherId);
            }
        }
    }, []);

    const getSchoolName = (objectId) => {
        let schoolName = "-"
        if (props.schools && props.schools.length > 0) {
            let tempUser = props.schools.filter((school) => {
                return school.id === objectId;
            });
            if (tempUser && tempUser.length > 0) {
                schoolName = tempUser[0].attributes.Name;
            }
        }


        return schoolName


    }

    useEffect(() => {
        if (props.teachersForIds && props.teachersForIds) {
            let tempDisplayList = [...displayList]
            for (var i = 0; i < tempDisplayList.length; i++) {
                let tempObject = tempDisplayList[i]
                tempObject.createdBy = getTeacherName(tempObject.sessionParseObject.attributes.createdBy)
                if (tempObject.sessionParseObject.attributes.approvedBy) {
                    tempObject.approvedBy = getTeacherName(tempObject.sessionParseObject.attributes.approvedBy)
                }
                if (tempObject.sessionParseObject.attributes.rejectedBy) {
                    tempObject.rejectedBy = getTeacherName(tempObject.sessionParseObject.attributes.rejectedBy)
                }
            }

            setDisplayList(tempDisplayList)

        }

    }, [props.teachersForIds]);




    useEffect(() => {
        if (isSuperAdmin != undefined) {
            fetchReportTemplate()
        }
    }, [schoolId, props.schools, isSuperAdmin]);

    const fetchReportTemplate = () => {
        var query = new Parse.Query("CustomReportTemplate");
        query.equalTo("objectId", reportId);
        query.notEqualTo("isDeleted", true);
        query.limit(1000);
        query.find().then((results) => {
            if (results != null) {
                if (results.length > 0) {

                    setConsolidatedReport(results[0].attributes.consolidatedReport)

                    setReportTemplateName(results[0].attributes.reportName)
                    if (results[0].attributes.reportType) {
                        setReportType(results[0].attributes.reportType)
                    }

                    var query = new Parse.Query("CustomReportSession");
                    query.notEqualTo("isDeleted", true);
                    query.limit(1000);
                    query.equalTo("reportId", reportId);
                    if (matchFranchise) {
                        if (results[0].attributes.reportType !== Constants.CR_SUPERVISION || isSuperAdmin) {
                            query.notEqualTo("status", 0);
                        }
                    } else {
                        if (results[0].attributes.reportType === Constants.CR_SUPERVISION) {
                            query.equalTo("status", 4);
                        }
                        query.equalTo("schoolId", schoolId)
                    }
                    query.descending("createdAt");
                    query.find().then((results) => {

                        var tempList = [];

                        if (results != null) {
                            if (results.length > 0) {
                                let tempList = [];
                                let teacherIds = [];
                                for (var i = 0; i < results.length; i++) {
                                    let tempObject = {}

                                    tempObject.name = results[i].attributes.sessionName

                                    tempObject.status = results[i].attributes.status
                                    tempObject.createdAt = results[i].attributes.createdAt
                                    tempObject.sessionParseObject = results[i]
                                    if (results[i].attributes.remarks && results[i].attributes.remarks.length > 0) {
                                        tempObject.remarks = results[i].attributes.remarks
                                    } else {
                                        tempObject.remarks = "-"
                                    }
                                    if (results[i].attributes.grade && results[i].attributes.grade.length > 0) {
                                        tempObject.grade = results[i].attributes.grade
                                    } else {
                                        tempObject.grade = "-"
                                    }

                                    tempObject.schoolName = getSchoolName(results[i].attributes.schoolId)
                                    if (!teacherIds.includes(results[i].attributes.createdBy)) {
                                        teacherIds.push(results[i].attributes.createdBy)
                                    }
                                    if (results[i].attributes.approvedBy) {
                                        if (!teacherIds.includes(results[i].attributes.approvedBy)) {
                                            teacherIds.push(results[i].attributes.approvedBy)
                                        }
                                    }
                                    if (results[i].attributes.rejectedBy) {
                                        if (!teacherIds.includes(results[i].attributes.rejectedBy)) {
                                            teacherIds.push(results[i].attributes.rejectedBy)
                                        }
                                    }


                                    tempList.push(tempObject)

                                }
                                props.getTeacherForIds(teacherIds)
                                setDisplayList(tempList);
                            } else {
                                //setIsQuestionsAvailable(false);
                            }
                        }
                        setDataIsReadyToDisplay(true)


                    });
                }
            }
        });
    };




    function getStatusTextColor(status) {
        let textColor = "font-size-12 mb-1"

        switch (status) {
            case 0:
                // code block
                textColor = ""
                break;
            case 1:
                // code block
                if (reportType === Constants.CR_SUPERVISION) {
                    textColor = "text-success font-size-12 mb-1"

                } else {
                    textColor = "text-warning font-size-12 mb-1"
                }
                break;
            case 2:
                // code block

                textColor = "text-warning font-size-12 mb-1"
                break;

            case 3:
                // code block
                textColor = "text-danger font-size-12 mb-1"
                break;
            case 4:
                // code block
                textColor = "text-success font-size-12 mb-1"
                break;
            default:
                textColor = "font-size-12 mb-1"
                break;
        }

        return textColor;
    }


    function getStatus(status, sessionObject) {
        let statusString = "-"
        let approvedBy
        let rejectedBy
        if (sessionObject.approvedBy) {
            approvedBy = sessionObject.approvedBy
        }
        if (sessionObject.rejectedBy) {
            rejectedBy = sessionObject.rejectedBy
        }
        switch (status) {
            case 0:
                // code block
                statusString = "Not Submitted"
                break;
            case 1:
                // code block
                if (reportType === Constants.CR_SUPERVISION) {
                    statusString = "Submitted"

                } else {
                    statusString = "Submitted, waiting for approval"
                }
                break;
            case 2:
                // code block
                statusString = "Re-submitted, waiting for approval"
                break;

            case 3:
                // code block
                statusString = "Rejected"

                if (rejectedBy) {
                    statusString = "Rejected By" + " " + rejectedBy

                } else {
                    statusString = "Rejected"

                }
                break;
            case 4:
                // code block
                if (approvedBy) {
                    statusString = "Approved By" + " " + approvedBy

                } else {
                    statusString = "Approved"

                }
                break;
            default:
                statusString = "-"
                break;
        }

        return statusString;
    }


    function strReplace(stringValue) {
        var newStr = stringValue.replace(/%20/g, " ");

        return newStr;
    }

    function convertDateToString(date) {
        return moment(date).format('DD/MM/YYYY')
    }


    const handleDuplicateReport = (report) => {

        if (selectedMonthDateWhileDuplicate) {
            setReportIsDuplicating(true)

            let sessionObject = report.sessionParseObject
            let CustomReportSession = Parse.Object.extend("CustomReportSession");
            let newSessionObject = new CustomReportSession()


            newSessionObject.set("reportId", sessionObject.attributes.reportId)
            newSessionObject.set("status", 0)
            newSessionObject.set("schoolId", sessionObject.attributes.schoolId)

            if (Parse.User.current().attributes.teacherId) {
                newSessionObject.set("createdBy", Parse.User.current().attributes.teacherId)
            } else {
                newSessionObject.set("createdBy", Parse.User.current().id)
            }



            if (selectedMonthDateWhileDuplicate) {
                newSessionObject.set("reportMonth", selectedMonthDateWhileDuplicate)
                let selectedMonthString = moment(selectedMonthDateWhileDuplicate).format("MMM YYYY")
                newSessionObject.set("createdMonth", selectedMonthString)
                newSessionObject.set(
                    "sessionName",
                    reportTemplateName + " " + selectedMonthString
                );
            }


            newSessionObject.save().then(
                (result) => {

                    let query = new Parse.Query('ReportData');
                    query.equalTo("sessionId", sessionObject.id);
                    query.notEqualTo('isDeleted', true);
                    query.find().then((dataResults) => {


                        let newDataResultsList = []
                        for (var i = 0; i < dataResults.length; i++) {
                            let dataResult = dataResults[i].attributes
                            let ReportData = Parse.Object.extend("ReportData");
                            let newReportData = new ReportData()

                            newReportData.set("sectionId", dataResult.sectionId)
                            newReportData.set("questionId", dataResult.questionId)
                            newReportData.set("groupId", dataResult.groupId)
                            newReportData.set("answer", dataResult.answer)
                            newReportData.set("dimensionResults", dataResult.dimensionResults)
                            newReportData.set("reportId", dataResult.reportId)
                            newReportData.set("sessionId", result.id)

                            newDataResultsList.push(newReportData)

                        }

                        if (newDataResultsList.length > 0) {
                            Parse.Object.saveAll(newDataResultsList).then(
                                (newDataResults) => {



                                    let tempObject = {}

                                    tempObject.name = result.attributes.sessionName

                                    tempObject.status = result.attributes.status
                                    tempObject.createdAt = result.attributes.createdAt
                                    tempObject.sessionParseObject = result
                                    if (result.attributes.remarks && result.attributes.remarks.length > 0) {
                                        tempObject.remarks = result.attributes.remarks
                                    } else {
                                        tempObject.remarks = "-"
                                    }
                                    tempObject.schoolName = getSchoolName(result.attributes.schoolId)

                                    tempObject.createdBy = getTeacherName(result.attributes.createdBy)
                                    if (result.attributes.approvedBy) {
                                        tempObject.approvedBy = getTeacherName(result.attributes.approvedBy)
                                    }
                                    if (result.attributes.rejectedBy) {
                                        tempObject.rejectedBy = getTeacherName(result.attributes.rejectedBy)
                                    }

                                    let tempDisplayList = [...displayList]
                                    tempDisplayList.unshift(tempObject)
                                    setDisplayList(tempDisplayList)





                                    setReportIsDuplicating(false)
                                    setShowDuplicateModal(undefined)


                                },
                                (error) => {
                                    console.log(error)
                                }
                            );
                        }


                    })
                },
                (error) => {
                    console.log(error)
                }
            );
        } else {
            toastr.warning("Please Select Month")
        }
    }

    function formatDateForMonthSelection(d) {
        if (d == null || d === "" || typeof d === "string" || d instanceof String) {
            return "";
        } else {
            const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
            const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
            const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

            return `${ye}-${mo}`;
        }
    }


    const shouldHideCreateReportButton = (type) => {

        if (type === Constants.CR_SUPERVISION) {
            if (matchFranchise) {
                return false
            } else {
                return true
            }
        } else if (type !== Constants.CR_SUPERVISION) {
            if (matchFranchise) {
                return true
            } else {
                return false
            }
        }

        return false
    }


    return (
        <React.Fragment>
            <div className="page-content">


                {!isLoading && (
                    <Container fluid>

                        <Modal
                            isOpen={showDuplicateModal}
                            toggle={() => {
                                setShowDuplicateModal(undefined);
                            }}
                        >
                            <ModalHeader>{props.t("Select Month")}</ModalHeader>
                            <ModalBody>




                                <ReactDatePicker
                                    disabled={matchFranchise}
                                    id={"questionDatePickMonth"}
                                    selected={
                                        selectedMonthDateWhileDuplicate ? new Date(selectedMonthDateWhileDuplicate) : undefined
                                    }
                                    onChange={(date) => {

                                        setSelectedMonthDateWhileDuplicate(new Date(date))


                                    }}
                                    className="form-control"
                                    placeholderText="mm/yyyy"
                                    dateFormat="MM/yyyy"
                                    showMonthYearPicker
                                    showFullMonthYearPicker
                                />

                            </ModalBody>
                            <ModalFooter>
                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setShowDuplicateModal(undefined);
                                    }}
                                    disabled={reportIsDuplicating}
                                >
                                    {props.t("Close")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary"
                                    onClick={() => {
                                        handleDuplicateReport(showDuplicateModal);
                                    }}
                                >
                                    {reportIsDuplicating && (
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    )}
                                    {props.t("Create")}
                                </button>
                            </ModalFooter>
                        </Modal>

                        <TopBar
                            title={reportTemplateName}
                            buttonName={"Create Report"}
                            buttonName2={"Analytics"}
                            backButton={true}
                            hideButton={shouldHideCreateReportButton(reportType)}
                            hideButton2={consolidatedReport ? false : true}
                            onClickAdd={() => { }}
                            onClickBackButton={() => {
                                window.history.back();
                            }}
                            reportLink2={(matchFranchise && consolidatedReport) ? "/franchise-consolidated-reports/" + reportId : "/monthly-consolidated-reports/" +
                                schoolId + "/" + reportId}

                            reportLink={matchFranchise ? "/franchise-monthly-reports/" + reportId : "/monthly-reports/" + schoolId + "/" + reportId}
                        />

                        <Card>
                            <CardBody>
                                {displayList.length > 0 && (
                                    <div className="table-responsive">
                                        <table className="table table-centered table-nowrap mb-0">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{props.t("Title / School Name")}</th>

                                                    <th >{props.t("Created By")}</th>
                                                    <th >{props.t("Created Date")}</th>
                                                    <th >{props.t("Status")}</th>
                                                    {(reportType === Constants.CR_SUPERVISION && false) ? <th style={{ textAlign: "center" }}>{props.t("Grade")}</th> : <th>{props.t("Remarks")}</th>}

                                                    {!matchFranchise && reportType !== Constants.CR_SUPERVISION && <th style={{ textAlign: "center" }}>
                                                        {props.t("Duplicate")}
                                                    </th>}
                                                    <th style={{ width: "20px" }}>{props.t("View")}</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayList.map((object, key) => (
                                                    <tr key={key}>
                                                        <td>
                                                            <span><Label className="font-size-12 mb-1">
                                                                {object.name}
                                                            </Label>
                                                                <br />

                                                                <Label className="font-size-12 mb-1">
                                                                    {object.schoolName}
                                                                </Label></span>


                                                        </td>

                                                        <td>
                                                            <Label className="font-size-12 mb-1">

                                                                {object.createdBy}
                                                            </Label>
                                                        </td>

                                                        <td>
                                                            <Label className="font-size-12 mb-1">
                                                                {convertDateToString(object.createdAt)}
                                                            </Label>
                                                        </td>

                                                        <td>
                                                            <Label className={getStatusTextColor(object.status)}>

                                                                {getStatus(object.status, object)}
                                                            </Label>
                                                        </td>

                                                        {(reportType === Constants.CR_SUPERVISION && false)? <td style={{ textAlign: "center" }}>
                                                            <Label className="font-size-12 mb-1">
                                                                {object.grade}
                                                            </Label>
                                                        </td> : <td>
                                                            <Label className="font-size-12 mb-1">
                                                                {object.remarks}
                                                            </Label>
                                                        </td>}

                                                        {!matchFranchise && reportType !== Constants.CR_SUPERVISION && <td>
                                                            <div className="flex-fill text-center ">

                                                                <Link
                                                                    to="#"
                                                                    id={"duplicate-form" + key}
                                                                    onClick={() => {
                                                                        setShowDuplicateModal(object)
                                                                    }}
                                                                >
                                                                    <i className=" font-size-18  bx bx-copy"></i>
                                                                </Link>

                                                            </div>
                                                        </td>}
                                                        <td>
                                                            {

                                                                <Link
                                                                    to={matchFranchise ? "/franchise-monthly-reports/" + reportId + "/" + object.sessionParseObject.id :
                                                                        "/monthly-reports/" +
                                                                        schoolId +
                                                                        "/" + reportId + "/" +
                                                                        object.sessionParseObject.id
                                                                    }
                                                                >
                                                                    <Button
                                                                        type="button"
                                                                        color="primary"
                                                                        size="sm"
                                                                        className="btn-rounded waves-effect waves-light"
                                                                    >
                                                                        {props.t("View")}
                                                                    </Button>
                                                                </Link>}
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {displayList.length === 0 && (
                                    <p className="text-center">No Reports to display !!</p>
                                )}
                            </CardBody>
                        </Card>
                    </Container>
                )}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Reports...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </React.Fragment >
    );
};
const mapStatetoProps = (state) => {
    const { selectedSchool, schools } = state.School;
    const { teachersForIds } = state.Teacher;
    const { userTeacher } = state.Login;



    return { userTeacher, selectedSchool, teachersForIds, schools };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            getUserTeacher,
            getTeacherForIds,
        })(MonthlyReportSession)
    )
);
