import React, {  } from 'react';
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { Col, Row,Card, CardBody, CardFooter, UncontrolledTooltip, Label } from "reactstrap";


const TeacherCard = (props) => {

    function getDesignation(teacher) {
        if (teacher.designation) {
            return teacher.designation;
        } else if(teacher.Role === 3){
            return props.t('Director');
        }else  if(teacher.Role === 2){
            return props.t('Center Head');
        } else {
            return props.t('Teacher');
        }
    }

    function getClassRoomNames(classroomIds) {
        var list = [];
        if (props.classrooms && props.classrooms.length > 0) {
            for (var i in classroomIds) {
                var calssroom = props.classrooms.filter((item) => item.id === classroomIds[i]);
                if (calssroom.length > 0) {
                    list.push(calssroom[0].attributes.Name)
                }
            }

            if (list.length > 0) {
                return list;
            } else {
                return ["No Classrooms"];
            }
        }
        return ["Loading..."] 
    }

    
    
    return (
        <React.Fragment>
            <Col lg={12}><h4>{props.role}</h4></Col>
            <Row>
                {props.teachers.map((teacher) =>         
                    <Col xl="3" sm="6" key={teacher.id}>
                        <Card className="text-center">
                            <CardBody>
                                {
                                    !teacher.attributes.Photo ?
                                        <div className="avatar-sm mx-auto mb-4">
                                            <span className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-16"}>
                                                {teacher.attributes.Name.charAt(0).toUpperCase()}
                                            </span>
                                        </div>
                                        :
                                        <div className="mb-4">
                                            <img className="rounded-circle avatar-sm" src={teacher.attributes.Photo._url} alt="" />
                                        </div>
                                }

                                <h5 className="font-size-15">
                                    <Link
                                        to={teacher.attributes.Role === 3? "/staffprofile/d/" + teacher.id : "/staffprofile/"+teacher.attributes.SchoolID+"/"+teacher.id}
                                        className="text-dark">{teacher.attributes.Name}
                                    </Link>
                                </h5>
                                <p className="text-muted">{getDesignation(teacher.attributes)}</p>

                                <div>
                                   
                                    {teacher.attributes.Role === 1 && <div>
                                        {teacher.attributes.ClassRooms && teacher.attributes.ClassRooms.length > 0 ?
                                            <div>
                                                {
                                                    getClassRoomNames(teacher.attributes.ClassRooms).map((skill, key) =>
                                                        <Link to="#" className="badge badge-soft-primary font-size-11 m-1" key={"_skill_" + key}>{skill}</Link>  
                                                    )
                                                }
                                            </div>:
                                            <Label to="#" className="badge badge-soft-primary font-size-11 m-1" >No Classrooms</Label>
                                        }
                                
                                    </div>
                                    }
                                    {
                                        (teacher.attributes.Role === 3 || teacher.attributes.Role === 2)&&
                                        <Label to="#" className="badge badge-soft-primary font-size-11 m-1" >All Classrooms</Label>
                                    }

                                    {
                                        (teacher.attributes.Role >3)&&
                                        <Label to="#" className="badge badge-soft-primary font-size-11 m-1" >No Classrooms</Label>
                                    }
                                </div>
                            </CardBody>
                            <CardFooter className="bg-transparent border-top">
                                <div className="contact-links d-flex font-size-20">
                                    {/* <div className="flex-fill">
                                        <Link to="" id={"message" + teacher.id}>
                                            <i className="bx bx-message-square-dots"></i>
                                            <UncontrolledTooltip placement="top" target={"message" + teacher.id}>
                                                {props.t('Message')}  
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div> */}
                                    {/* <div className="flex-fill">
                                        <Label  id={"project" + teacher.id} onClick={()=>{props.onClickIDCard(teacher)}}>
                                            <i className="bx bx-id-card" style={{ cursor: "pointer" }} ></i>
                                            <UncontrolledTooltip placement="top" target={"project" + teacher.id}>
                                                {props.t('ID Card')}
                                            </UncontrolledTooltip>
                                        </Label>
                                    </div> */}
                                    <div className="flex-fill">
                                        <Link
                                             to={teacher.attributes.Role === 3? "/staffprofile/d/" + teacher.id : "/staffprofile/"+teacher.attributes.SchoolID+"/"+teacher.id}
                                            id={"profile" + teacher.id}>
                                            <i className="bx bx-user-circle"></i>
                                            <UncontrolledTooltip placement="top" target={"profile" + teacher.id}>
                                                {props.t('Profile')}
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>      
                )}
               
            </Row>
            
        </React.Fragment>
          
    );
    
}
        
export default withNamespaces() (TeacherCard);