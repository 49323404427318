import Parse from "parse";
import { printLog } from "./loghelper";
export const getProgramFromParse = (programId) => {
  printLog("HL: program_helper : getProgramFromParse")

  try {
    return new Promise((resolve, reject) => {
      var Program = Parse.Object.extend("Program");
      var query = new Parse.Query(Program);
      query.get(programId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null)
        }
      )
    }
    )
  } catch (error) { }
}

export const deleteProgramFromParse = (program) => {
  printLog("HL: program_helper : deleteProgramFromParse")
  try {
    return new Promise((resolve, reject) => {
      program.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );

    });
  } catch (error) { }
}

export const saveProgramToParse = (program) => {
  printLog("HL: program_helper : saveProgramToParse")
  try {
    return new Promise((resolve, reject) => {
      program.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    });
  } catch (error) { }
}



export const getProgramsForSchoolId = (id, updatedDate, isLocal) => {
  printLog("HL: program_helper : getProgramsForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Program = Parse.Object.extend("Program");
      var query = new Parse.Query(Program);
      query.equalTo("schoolId", id)

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate))
      } else {
        query.notEqualTo("deleted", true);
      }

      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    }
    )
  } catch (error) { }
}

export const getProgramForGroupId = (id) => {
  printLog("HL: program_helper : getProgramForGroupId")
  try {
    return new Promise((resolve, reject) => {
      var Program = Parse.Object.extend("Program");
      var query = new Parse.Query(Program);
      query.equalTo("groupId", id)
      query.first().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );


    }
    )
  } catch (error) { }
}

export const getProgramTermsForProgramId = (id) => {
  printLog("HL: program_helper : getProgramTermsForProgramId")
  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query('ProgramTerms');
      query.equalTo('programId', id);
      query.ascending("order")
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );


    }
    )
  } catch (error) { }
}

export const editProgramTerms = (terms) => {
  printLog("HL: program_helper : editProgramTerms")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(terms).then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}


