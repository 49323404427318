export const UPDATE_TEACHER = 'UPDATE_TEACHER';
export const UPDATE_TEACHERS = 'UPDATE_TEACHERS';
export const EDIT_TEACHER = 'EDIT_TEACHER';
export const CLEAR_ALL_TEACHERS = "CLEAR_ALL_TEACHERS";

export const GET_TEACHERS_FOR_IDS = 'GET_TEACHERS_FOR_IDS';
export const UPDATE_TEACHERS_FOR_IDS = 'UPDATE_TEACHERS_FOR_IDS';

export const GET_TEACHERS = 'GET_TEACHERS';
export const GET_TEACHER = 'GET_TEACHER';
export const DELETE_TEACHER = 'DELETE_TEACHER';
export const DELETE_TEACHER_SUCCESS = 'DELETE_TEACHER_SUCCESS';

export const GET_DELETED_TEACHERS = 'GET_DELETED_TEACHERS';
export const UPDATE_DELETED_TEACHERS = 'UPDATE_DELETED_TEACHERS';

export const SAVE_TEACHERS = 'SAVE_TEACHERS'

export const GET_TEACHER_ATTENDANCE_FOR_DAY = 'GET_TEACHER_ATTENDANCE_FOR_DAY'
export const UPDATE_TEACHER_ATTENDANCE_FOR_DAY = "UPDATE_TEACHER_ATTENDANCE_FOR_DAY"

export const GET_DAYCARE_ATTENDANCE_FOR_DAY = "GET_DAYCARE_ATTENDANCE_FOR_DAY"
export const UPDATE_DAYCARE_ATTENDANCE_FOR_DAY = "UPDATE_DAYCARE_ATTENDANCE_FOR_DAY"

export const GET_SCHOOL_ATTENDANCE = "GET_SCHOOL_ATTENDANCE"
export const UPDATE_SCHOOL_ATTENDANCE = "UPDATE_SCHOOL_ATTENDANCE"



export const UPDATE_SELECTED_TEACHER = "UPDATE_SELECTED_TEACHER";
export const SET_SELECTED_TEACHER = "SET_SELECTED_TEACHER";

export const LOADING = "LOADING"
export const GET_TEACHER_FOR_CLASSROOM = "GET_TEACHER_FOR_CLASSROOM"
export const SET_TEACHER_FOR_CLASSROOM = "SET_TEACHER_FOR_CLASSROOM"
export const UPDATE_TEACHERS_OBJS = "UPDATE_TEACHERS_OBJS"


export const REQUEST_FOR_WEB_ACCESS = "REQUEST_FOR_WEB_ACCESS";
export const RESPONSE_FOR_WEB_ACCESS = "RESPONSE_FOR_WEB_ACCESS";

export const REQUEST_FOR_WEB_ACCESS_REMOVAL = "REQUEST_FOR_WEB_ACCESS_REMOVAL";
export const RESPONSE_FOR_WEB_ACCESS_REMOVAL = "RESPONSE_FOR_WEB_ACCESS_REMOVAL";

export const GET_FRANCHISE_TEACHERS = 'GET_FRANCHISE_TEACHERS';
export const UPDATE_FRANCHISE_TEACHERS = 'UPDATE_FRANCHISE_TEACHERS';
export const ADD_FRANCHISE_TEACHER = 'ADD_FRANCHISE_TEACHER';




//DELETED TEACHERS FOR MONTH TEMP VARIABLE
export const GET_DELETED_TEACHERS_FOR_MONTH = 'GET_DELETED_TEACHERS_FOR_MONTH';
export const UPDATE_DELETED_TEACHERS_FOR_MONTH = "UPDATE_DELETED_TEACHERS_FOR_MONTH";
