export const UPDATE_ENQUIRY_NOTE = "UPDATE_ENQUIRY_NOTE";
export const DELETE_ENQUIRY_NOTE = "DELETE_ENQUIRY_NOTE";

export const UPDATE_ENQUIRY_NOTES = "UPDATE_ENQUIRY_NOTES";
export const GET_ENQUIRY_NOTES = "GET_ENQUIRY_NOTES";
export const GET_ENQUIRY_NOTE = "GET_ENQUIRY_NOTE";

export const ENQUIRY_NOTE_SUCCESS = "ENQUIRY_NOTE_SUCCESS";
export const ENQUIRY_NOTE_ERROR = "ENQUIRY_NOTE_ERROR";

export const EDIT_ENQUIRY_NOTE = "EDIT_ENQUIRY_NOTE";

export const GET_ENQUIRY_NOTES_EQUIRY_IDS = "GET_ENQUIRY_NOTES_EQUIRY_IDS";
export const UPDATE_ENQUIRY_NOTES_EQUIRY_IDS = "UPDATE_ENQUIRY_NOTES_EQUIRY_IDS";