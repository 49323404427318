import Parse from "parse";
import { printLog } from "./loghelper";

const getCamerasForSchoolId = (id, updatedDate, isLocal) => {
  printLog("HL: camera_helper : getCamerasForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Camera = Parse.Object.extend("Camera");
      var query = new Parse.Query(Camera);
      query.limit(1000);
      query.equalTo("schoolId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("deleted", true);
      }

      if (isLocal) {
        query.fromLocalDatastore();
      }

      query.ascending("name");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const saveCameraToParse = (cameraObject) => {
  printLog("HL: camera_helper : saveCameraToParse")
  try {
    return new Promise((resolve, reject) => {
      cameraObject.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
          console.log("Error", error);
        }
      );
    });
  } catch (error) {
    console.log("Error", error);
  }
};

const saveCameraAccessObjects = (cameraAccessObjects) => {
  printLog("HL: camera_helper : saveCameraAccessObjects")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(cameraAccessObjects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
          console.log("Error", error);
        }
      );
    });
  } catch (error) {
    console.log("Error", error);
  }
};
const getCameraAccessObjectsForCameraId = (cameraId) => {
  printLog("HL: camera_helper : getCameraAccessObjectsForCameraId")
  try {
    return new Promise((resolve, reject) => {
      let CameraAccess = Parse.Object.extend("CameraAccess");
      let query = new Parse.Query(CameraAccess);
      query.limit(1000);
      query.equalTo("cameraId", cameraId);
      query.ascending("startDate");
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const getCameraForId = (cameraId) => {
  printLog("HL: camera_helper : getCameraForId")
  try {
    return new Promise((resolve, reject) => {
      var Camera = Parse.Object.extend("Camera");
      var query = new Parse.Query(Camera);
      query.notEqualTo("deleted", true);
      query.get(cameraId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error);
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

const getCameraLiveStreamsForSchoolId = (id, updatedDate, isLocal) => {
  printLog("HL: camera_helper : getCameraLiveStreamsForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Camera = Parse.Object.extend("CameraLiveStreams");
      var query = new Parse.Query(Camera);
      query.limit(1000);
      query.equalTo("schoolId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("deleted", true);
      }

      if (isLocal) {
        query.fromLocalDatastore();
      }

      query.ascending("name");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  } catch (error) { 
    console.log("error c saga", error);
  }
};

export {
  getCamerasForSchoolId,
  saveCameraToParse,
  getCameraForId,
  saveCameraAccessObjects,
  getCameraAccessObjectsForCameraId,
  getCameraLiveStreamsForSchoolId,
};
