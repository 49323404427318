export const UPDATE_LEARNING_ACTIVITY = "UPDATE_LEARNING_ACTIVITY";
export const DELETE_LEARNING_ACTIVITY = "DELETE_LEARNING_ACTIVITY";

export const UPDATE_LEARNING_ACTIVITYS = "UPDATE_LEARNING_ACTIVITYS";   
export const GET_LEARNING_ACTIVITYS = "GET_LEARNING_ACTIVITYS";
export const GET_LEARNING_ACTIVITY = "GET_LEARNING_ACTIVITY";
export const GET_LEARNING_ACTIVITY_FOR_CURRICULUM_ID = "GET_LEARNING_ACTIVITY_FOR_CURRICULUM_ID";

export const GET_ACTIVITIES_FOR_SUBCATEGORY_IDS = "GET_ACTIVITIES_FOR_SUBCATEGORY_IDS";
export const GET_ACTIVITIES_FOR_CATEGORY_ID = "GET_ACTIVITIES_FOR_CATEGORY_ID";

export const ADD_LEARNING_ACTIVITY = "ADD_LEARNING_ACTIVITY";

export const REPLACE_LEARNING_ACTIVITIES = "REPLACE_LEARNING_ACTIVITIES";

export const SET_SELECTED_LEARNING_ACTIVITY = "SET_SELECTED_LEARNING_ACTIVITY";
export const UPDATE_SELECTED_LEARNING_ACTIVITY = "UPDATE_SELECTED_LEARNING_ACTIVITY";

export const LEARNING_ACTIVITY_ADD_LOADING_STATE = "LEARNING_ACTIVITY_ADD_LOADING_STATE";

export const LEARNING_ACTIVITY_REORDER = "LEARNING_ACTIVITY_REORDER";

export const GET_ACTIVITIES_FOR_COURSE_ID = "GET_ACTIVITIES_FOR_COURSE_ID";

export const GET_QUIZ_ACTIVITIES_FOR_COURSE_ID = "GET_QUIZ_ACTIVITIES_FOR_COURSE_ID"
export const UPDATE_QUIZ_ACTIVITIES_FOR_COURSE_ID = "UPDATE_QUIZ_ACTIVITIES_FOR_COURSE_ID"

export const GET_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID = "GET_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID"
export const UPDATE_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID= "UPDATE_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID"

export const GET_LEARNING_ACTIVITIES_FOR_IDS = "GET_LEARNING_ACTIVITIES_FOR_IDS";