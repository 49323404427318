import * as actions from "./actionTypes";

export const updateEnquiryNote = (enquiryNote) => {
  return {
    type: actions.UPDATE_ENQUIRY_NOTE,
    payload: { enquiryNote },
  };
};

export const deleteEnquiryNote = (enquiryNote) => {
  return {
    type: actions.DELETE_ENQUIRY_NOTE,
    payload: {enquiryNote},
  };
};

export const updateEnquirynotes = (enquiryNotes,isLocal) => {
  return {
    type: actions.UPDATE_ENQUIRY_NOTES,
    payload: { enquiryNotes,isLocal },
  };
};

export const getEnquirynoteForEnquiryId = (enquiryId) => {
  return {
    type: actions.GET_ENQUIRY_NOTES,
    payload: { enquiryId },
  };
};

export const editEnquiryNote = (enquiryNote) => {
  return {
      type: actions.EDIT_ENQUIRY_NOTE,
      payload: { enquiryNote }
  }
}

export const getEnquiryNoteForEnquiryIds = (enquiryIds) => {
  return {
    type: actions.GET_ENQUIRY_NOTES_EQUIRY_IDS,
    payload: { enquiryIds },
  };
};

export const updateEnquiryNotesForEnquiryIds = (enquiryNotes) => {
  return {
    type: actions.UPDATE_ENQUIRY_NOTES_EQUIRY_IDS,
    payload: { enquiryNotes },
  };
};
