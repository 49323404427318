import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as CourseBatchHelper from "../../../helpers/ParseHelpers/courseBatch_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as CourseBatchActions from "./actions";

function* getCourseBatch({ payload: { courseBatchId } }) {
  try {
    const response = yield call(CourseBatchHelper.getCourseBatchFromParse, courseBatchId, false);
    
    if (response) {
      yield put(CourseBatchActions.updateCourseBatch([response]));
      yield put(CourseBatchActions.setSlectedCourseBatch(response))
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteCourseBatch({ payload: { courseBatch } }) {
  try {
    const response = yield call(
      CourseBatchHelper.deleteCourseBatchFromParse,
      courseBatch
    );
    if (response) {
      yield put(CourseBatchActions.updateCourseBatch(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getCourseBatchForCourseId({ payload: { courseId } }) {
  try {
    yield put(CourseBatchActions.setCourseBatchLoading(true));
    var key = Constants.COURSE_BATCH_UPDATED_TIME_FOR_SCHOOL_ID + "_" + courseId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(CourseBatchHelper.getCourseBatchForCourseId, courseId, null, true);
    if (localResponse && localResponse.length > 0) {
      yield put(CourseBatchActions.updateCourseBatch(localResponse));
      yield put(CourseBatchActions.setCourseBatchLoading(false));
    }
    

    const serverResponse = yield call(CourseBatchHelper.getCourseBatchForCourseId, courseId, updatedTime, false);
    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(CourseBatchActions.updateCourseBatch(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
    yield put(CourseBatchActions.setCourseBatchLoading(false));
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* addNewCourseBatch({ payload: { courseBatch,selectedBatch } }) {
  try {
    yield put(CourseBatchActions.setAddCourseBatchLoading(true));
    const response = yield call(CourseBatchHelper.saveCourseBatchObject, courseBatch);
    if (response) {
      yield put(CourseBatchActions.updateCourseBatch([response]));
      response.pin();
      if (selectedBatch) {
        yield put(CourseBatchActions.setSlectedCourseBatch(response))
      }
    } else {

    }
    yield put(CourseBatchActions.setAddCourseBatchLoading(false));
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getCourseBatchForCourseIds({ payload: {courseIds } }) {
  try {
    const response = yield call(CourseBatchHelper.getCourseBatchForCourseIds, courseIds);
    console.log(response);
    if (response) {
      yield put(CourseBatchActions.updateCourseBatchForCourseIds(response));
      response.pin();
     
    }
  } catch (error) {
    
  }
}

export function* watchGetCourseBatch() {
  yield takeEvery(actionTypes.GET_COURSE_BATCH, getCourseBatch);
}

export function* watchDeleteCourseBatch() {
  yield takeEvery(actionTypes.DELETE_COURSE_BATCH, deleteCourseBatch);
}

export function* watchGetCourseBatchForCourseId() {
  yield takeEvery(actionTypes.GET_COURSE_BATCH_FOR_COURSE_ID, getCourseBatchForCourseId);
}

export function* watchAddNewCourseBatch() {
  yield takeEvery(actionTypes.ADD_COURSE_BATCH, addNewCourseBatch);
}

export function* watchGetCourseBatchForCourseIds() {
  yield takeEvery(actionTypes.GET_COURSE_BATCH_FOR_COURSE_IDS, getCourseBatchForCourseIds);
}

function* CourseBatchSaga() {
  yield all([
    fork(watchGetCourseBatch),
    fork(watchDeleteCourseBatch),
    fork(watchGetCourseBatchForCourseId),
    fork(watchAddNewCourseBatch),
    fork(watchGetCourseBatchForCourseIds),
  ]);
}
export default CourseBatchSaga;