import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as InventoryWarehouseHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryWarehouse_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../../Constents';
import * as InventoryWarehouseActions from "./actions";



function* getInventoryWarehouseForOwnerId({ payload: { ownerId, ownerType } }) {
    try {
      // console.log(ownerId, ownerType)
      const localResponse = yield call(InventoryWarehouseHelper.getInventoryWareHouseForOwnerId, ownerId, ownerType, true);
      yield put(InventoryWarehouseActions.updateInventoryWarehouses(localResponse));
  
      const serverResponse = yield call(InventoryWarehouseHelper.getInventoryWareHouseForOwnerId, ownerId, ownerType, false);
      
  
      if (serverResponse && serverResponse.length > 0) {
        yield put(InventoryWarehouseActions.updateInventoryWarehouses(serverResponse))
        Parse.Object.pinAll(serverResponse);
      }
    } catch (error) {
      // yield put(apiError(error));
  
    }
  }

function* getInventoryStockForWarehouse({ payload: { warehouseId } }) {
  try {

    var key = "InventoryStockForWarehouse_" + warehouseId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(InventoryWarehouseHelper.getInventoryStockForWarehouse, warehouseId, null, true);
    yield put(InventoryWarehouseActions.updateInventoryStockForWarehouse(localResponse));


    const serverResponse = yield call(InventoryWarehouseHelper.getInventoryStockForWarehouse, warehouseId, updatedTime, false);
    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(InventoryWarehouseActions.updateInventoryStockForWarehouse(serverResponse));


      var nonDeleted = serverResponse.filter(
        (inventoryTag) =>
          inventoryTag.attributes.isDeleted !== true
      );

      var deleted = serverResponse.filter(
        (inventoryTag) =>
          inventoryTag.attributes.isDeleted
      );

      if (nonDeleted) {
        Parse.Object.pinAll(nonDeleted);
      }

      if (deleted) {
        Parse.Object.unPinAll(deleted);
      }

    }
  } catch (error) {

  }
}

  export function* watchGetInventoryWarehouseForOwnerId() {
    yield takeEvery(actionTypes.GET_INVENTORY_WAREHOUSE_FOR_OWNER_ID, getInventoryWarehouseForOwnerId);
  }

  export function* watchGetInventoryStockForWarehouse() {
    yield takeEvery(actionTypes.GET_INVENTORY_STOCK_FOR_WAREHOUSES, getInventoryStockForWarehouse);
  }
  
  
  function* InventoryWarehouseSaga() {
    yield all([
      fork(watchGetInventoryWarehouseForOwnerId),
      fork(watchGetInventoryStockForWarehouse),
    ]);
  }
  export default InventoryWarehouseSaga;
  