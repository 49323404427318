import * as actions from "./actionTypes";

export const getExamsForSchoolId = (schoolId) => {
  return {
    type: actions.GET_EXAMS_FOR_SCHOOL_ID,
    payload: { schoolId },
  };
};

export const updateExamsForSchoolId = (exams) => {
  return {
    type: actions.UPDATE_EXAMS_FOR_SCHOOL_ID,
    payload: { exams },
  };
};


export const getExamForExamId = (examId) => {
  return {
    type: actions.GET_EXAM_FOR_EXAM_ID,
    payload: { examId }
  }
}

export const updateSelectedExam = (exam) => {
  return {
    type: actions.UPDATE_EXAM,
    payload: { exam },
  };
};


export const updateDeleteExam = (exam) =>{
  return{
      type:actions.UPDATE_DELETE_EXAM,
      payload: {exam},
  }
}

export const deleteExam = (exam) =>{
  return{
      type:actions.DELETE_EXAM,
      payload: {exam},
  }
}

export const saveMarksCard = (exam) =>{
  return{
      type: actions.SAVE_MARKS_CARD,
      payload: {exam}
  }
}