import Parse from "parse";
import { printLog } from "./loghelper";

export const getExamsForSchoolIds = (schoolIds) => {
  printLog("HL: exam_helper : getExamsForSchoolIds")

  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Quiz");

      query.containedIn("schoolId", schoolIds);

      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export const saveExam = (exam) => {
  printLog("HL: exam_helper : saveExam")
  try {
    return new Promise((resolve, reject) => {
      exam.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};
