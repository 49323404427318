import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as InvoiceItemHelper from "../../../helpers/ParseHelpers/invoiceItems_helper";
import * as actionTypes from "./actionTypes";
import * as InvoiceItemActions from "./actions";

function* getAInvoiceItem({ payload: { id } }) {
  try {
    const response = yield call(
      InvoiceItemHelper.getInvoiceItemFromParse,
      id,
      false
    );
    yield put(InvoiceItemActions.updateInvoiceItem(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAInvoiceItem({ payload: { invoiceItemId } }) {
  try {
    const response = yield call(
      InvoiceItemHelper.deleteInvoiceItemFromParse,
      invoiceItemId
    );
    if (response) {
      yield put(InvoiceItemActions.deleteInvoiceItem(response));
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getInvoiceItemsForInvoiceId({ payload: { invoiceId } }) {
  try {
    // const localResponse = yield call( InvoiceItemHelper.getInvoiceItemsForInvoiceId,invoiceId,true);
    // if (localResponse && localResponse.length > 0) {
    //   yield put(InvoiceItemActions.updateInvoiceItems(localResponse));
    // }

    const serverResponse = yield call(
      InvoiceItemHelper.getInvoiceItemsForInvoiceId,
      invoiceId,
      false
    );

    yield put(InvoiceItemActions.updateInvoiceItems(serverResponse));

    // if (serverResponse && serverResponse.length > 0) {
    //   yield put(InvoiceItemActions.updateInvoiceItems(serverResponse));
    //   Parse.Object.pinAll(serverResponse);
    // }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getItemsForInvoiceIds({ payload: { invoiceIds, isForDiscount,fromDate,toDate } }) {
  const response = yield call(
    InvoiceItemHelper.getItemsForInvoiceIds,
    invoiceIds,
    isForDiscount,fromDate,toDate
  );
  yield put(InvoiceItemActions.setItemsForInvoiceIds(response));
}

export function* watchGetInvoiceItem() {
  yield takeEvery(actionTypes.UPDATE_INVOICE_ITEM, getAInvoiceItem);
}

export function* watchDeleteInvoiceItem() {
  yield takeEvery(actionTypes.DELETE_INVOICE_ITEM, deleteAInvoiceItem);
}
export function* watchGetInvoicesForInvoiceId() {
  yield takeEvery(actionTypes.GET_INVOICE_ITEMS, getInvoiceItemsForInvoiceId);
}

export function* watchGetItemsForInvoiceIds() {
  yield takeEvery(actionTypes.GET_ITEMS_FOR_INVOICE_IDS, getItemsForInvoiceIds);
}

function* invoiceItemSaga() {
  yield all([
    fork(watchGetInvoiceItem),
    fork(watchDeleteInvoiceItem),
    fork(watchGetInvoicesForInvoiceId),
    fork(watchGetItemsForInvoiceIds),
  ]);
}
export default invoiceItemSaga;
