import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventoryVendorHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryVendor_helper";
import * as actionType from "./actionTypes";
import * as InventoryVendorAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* deleteAInventoryVendor({ payload: { inventoryVendor } }) {
    try {
      yield put(InventoryVendorAction.updateDeleteInventoryVendor(inventoryVendor));
      const response = yield call(
        InventoryVendorHelper.deleteInventoryVendorFromParse,inventoryVendor
      );
      if (response === "error") {
        inventoryVendor.set("isDeleted", undefined);
        yield put(InventoryVendorAction.updateInventoryVendor(inventoryVendor));
      } else
      if (response) {
        toastr.success("Vendor successfully removed", { timeOut: 3000 });
        response.unPin();
      }else{

      }

    } catch (error) {
     
    }
  }

  function* addAInventoryVendor({ payload: { inventoryVendor } }) {
    try {
    
      yield put(InventoryVendorAction.updateInventoryVendor(inventoryVendor));
      const response = yield call(
        InventoryVendorHelper.saveCustomObjects,inventoryVendor
      );
      if (response === "Error") {
  
        toastr.error("Please try again", { timeOut: 3000 });
        const local = yield call(
          InventoryVendorHelper.getInventoryVendorFromLocal,inventoryVendor.id
        );
  
        if (local) {
          yield put(InventoryVendorAction.updateInventoryVendor(local));
        }
    
      }else 
      if (response) {
        yield put(InventoryVendorAction.updateInventoryVendor(response));
        toastr.success("Vendor successfully added", { timeOut: 3000 });
        response.pin();
      } else {

      }
    } catch (error) {
 
    }
  }

  function* getInventoryVendorsForOwnerId({ payload: { ownerId, ownerType } }) {
    try {
      
      var key = Constants.INVENTORY_VENDOR_UPDATED_TIME + "_" + ownerId;
      var updatedTime = localStorage.getItem(key)
      var newTime = new Date();
      const localResponse = yield call(InventoryVendorHelper.getInventoryVendorForOwnerId, ownerId, ownerType, null, true);
      yield put(InventoryVendorAction.updateInventoryVendors(localResponse));
  
  
      const serverResponse = yield call(InventoryVendorHelper.getInventoryVendorForOwnerId, ownerId, ownerType, updatedTime, false);
      localStorage.setItem(key,newTime)
  
      if (serverResponse && serverResponse.length > 0) {
        yield put(InventoryVendorAction.updateInventoryVendors(serverResponse));
        
  
        var nonDeleted = serverResponse.filter(
          (inventoryvendor) =>
            inventoryvendor.attributes.isDeleted !== true
        );
  
        var deleted = serverResponse.filter(
          (inventoryvendor) =>
            inventoryvendor.attributes.isDeleted 
        );
       
        if (nonDeleted) {
          Parse.Object.pinAll(nonDeleted);
        }
  
        if (deleted) {
          Parse.Object.unPinAll(deleted);
        }
             
      }
    } catch (error) {
      
    }
  }

  function* editAInventoryVendor({ payload: { inventoryVendor } }) {
    try {
      const response = yield call(
        InventoryVendorHelper.editInventoryVendor,
        inventoryVendor
      );
      if (response === "Error") {
        toastr.error("Please try again", { timeOut: 3000 });
      }else 
      if (response) {
        yield put(InventoryVendorAction.updateInventoryVendor(response));
        response.pin();
        toastr.success("Vendor successfully updated", { timeOut: 3000 });
      } else {
      }
    } catch (error) {

    }
  }

  function* setSelectedVendor({ payload: {inventoryVendorId } }) {
    try {
      if (inventoryVendorId) {
        const localResponse = yield call(
          InventoryVendorHelper.getInventoryVendorFromParse,
          inventoryVendorId,
          true
        );
        if (localResponse) {
          yield put(InventoryVendorAction.updateSelectedVendor(localResponse));
        }
  
        const serverResponse = yield call(
          InventoryVendorHelper.getInventoryVendorFromParse,
          inventoryVendorId,
          false
        );
        if (serverResponse) {
          yield put(InventoryVendorAction.updateSelectedVendor(serverResponse));
          serverResponse.pin();
        }
      }
    } catch (error) {
      
    }
  }
  

  export function* watchDeleteInventoryVendor() {
    yield takeEvery(actionType.DELETE_INVENTORY_VENDOR, deleteAInventoryVendor);
  }

  export function* watchGetInventoryVendorsForOwnerId() {
    yield takeEvery(
      actionType.GET_INVENTORY_VENDORS,
      getInventoryVendorsForOwnerId
    );
  }
  export function* watchEditInventoryVendor() {
    yield takeEvery(actionType.EDIT_INVENTORY_VENDOR, editAInventoryVendor);
  }

  export function* watchAddInventoryVendor() {
    yield takeLeading(actionType.ADD_INVENTORY_VENDOR, addAInventoryVendor);
  }
  export function* watchSetSelectedVendor() {
    yield takeEvery(actionType.SET_SELECTED_VENDOR, setSelectedVendor);
  }
  

  function* InventoryVendorSaga() {
    yield all([
      fork(watchDeleteInventoryVendor),
      fork(watchGetInventoryVendorsForOwnerId),
      fork(watchEditInventoryVendor),
      fork(watchAddInventoryVendor),
      fork(watchSetSelectedVendor),

    ]);
  }
  export default InventoryVendorSaga;
  
