import React from 'react';
import {Row, Col, Form,Button} from "reactstrap";
import { withNamespaces } from 'react-i18next';

const Topbar = (props) => { 
    return (
        <Row className="navbar-header p-0">
            <div className ="d-flex" >
                {
                    (props.backButton  )?
                        <div className="navbar-header p-0">
                           
                            <div
                                style={{ cursor: "pointer" }} 
                                onClick={() => { props.onClickBackButton(); }}>
                                <ul className="m-2 list-unstyled">
                                    <div >
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            

                            <h4 >{props.title}</h4>
                        </div>
                        :
                        <h4 className="ml-2">{props.title}</h4>
                }
               
            </div>
            {!props.hideButton &&
                
                <div className="d-flex" >
                    {props.reorderButton&&<Form className=" mt-sm-0  mr-2 float-sm-right form-inline"> 
                       <Button onClick={() => props.onClickReorder()} type="button" color="success" className="btn waves-effect waves-light  mb-2"><i className="bx bxs-analyse mr-1"></i> {"Reorder"}</Button>
                    </Form>}
                    <Form className=" mt-sm-0 float-sm-right form-inline"> 
                       <Button onClick={() => props.onClickAdd()} type="button" color="success" className="btn waves-effect waves-light  mb-2"><i className="mdi mdi-plus mr-1"></i> {props.buttonName}</Button>
                    </Form>
                </div>
            }
        </Row>
       );
}
export default withNamespaces()(Topbar);
