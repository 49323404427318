import React from 'react';
import { Container } from "reactstrap";
import { matchPath } from "react-router";
//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import UserDisableWarning from "./UserDisableWarning";

const ProfileDisabledPage = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/profile-access-warning/:type",
        exact: true,
        strict: false,
    });

    const type = match && match.params.type;

    const profileDeleteMessage =  "The profile has been deleted, and no longer have access to it."
    const roleAccessMessage = "Your profile dosen't have access to use dashboard. Please contact your Institute admin team or lilTriangle customer care!"


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {type==1 && <UserDisableWarning
                        message={profileDeleteMessage} />}
                    {type==2 && <UserDisableWarning
                        message={roleAccessMessage} />}

                </Container>
            </div>
        </React.Fragment>
    );
}

export default ProfileDisabledPage;