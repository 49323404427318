import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Alert,
  Card,
  CardBody,
  Col,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Row,
} from "reactstrap";

import {
  getFeeTemplateForSchoolId,
  getProgramsForSchoolId,
  getFeeTemplateItemsForInvoiceIds,
  deleteATemplateForSchool,
  getFeeType,
  editFeeTemplate,
} from "../../store/actions";
import Moment from "moment";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";

import NewTemplate from "./newTemplate";
import Parse from "parse";

const SchoolTemplate = (props) => {

  const [templateList, templateListOps] = useState([]);
  const [newTemplateTog, newTemplateTogOps] = useState(false);
  const [allItems, allItemsOps] = useState([]);
  const [showDeleteLoader, showDeleteLoaderOps] = useState(false);
  const [templateTitle, templateTitleOps] = useState("");
  const [editTemplateObj, editTemplateObjOps] = useState();
  const [feeType, feeTypeOps] = useState([]);
  const [showDeleteTemplateModel, setShowDeleteTemplateModel] = useState();
  const [checkingForTemplateUsedInProgram, setCheckingForTemplateUsedInProgram] = useState(true);
  const [usedInPrograms, setUsedInPrograms] = useState([]);
  const [actionType, setActionType] = useState('Add');
  const [selectedFeeTemplate, setSelectedFeeTemplate] = useState(false);

  useEffect(() => {
    if (props.activeTab === "3") {
      loadAllTemplates();
    }
    props.getProgramsForSchoolId(props.schoolId)
  }, [props.schoolId, props.activeTab]);
  useEffect(() => {
    let tempTemplate = [];
    let disabledList = [];
    let templateIds = [];
    for (const val of props.feeTemplates) {
      let temp = {};
      temp.id = val.id;
      templateIds.push(val.id);
      temp.Obj = val;
      temp.name = val.attributes.name;
      temp.isDisabled = val.attributes.isDisabled;
      temp.totalAmount = val.attributes.totalAmount || 0;
      temp.createdAt = Moment(val.attributes.createdAt).format("DD-MMM-YYYY");
      if (val.attributes.type == 2) {
        temp.franchiseTemplate = true;
      } else {
        temp.franchiseTemplate = false;
      }
      temp.actionActive = false;
      temp.items = [];
      temp.itemIds = [];
      

      if (val.attributes.isDisabled) {
        disabledList.push(temp);
      } else {
        tempTemplate.push(temp);
      }
    }
    templateListOps(tempTemplate.concat(disabledList));
    if (templateIds.length > 0) {
      props.getFeeTemplateItemsForInvoiceIds(templateIds);
    }
  }, [props.feeTemplates]);
  useEffect(() => {
    let tempList = Object.assign([], templateList);
    for (const val of props.feeTemplateItems) {
      for (const val2 of templateList) {
        if (val.attributes.feeTemplateId === val2.id) {
          if (val2.items.length === 0) {
            // val2.totalAmount += val.attributes.amount
            val2.totalAmount +=
              val.attributes.discountType == 1
                ? val.attributes.amount - val.attributes.discount
                : val.attributes.amount -
                (val.attributes.amount * val.attributes.discount) / 100;
            val2.itemIds.push(val.id);
            val2.items.push(val);
          } else {
            if (!val2.itemIds.includes(val.id)) {
              // val2.totalAmount += val.attributes.amount
              val2.totalAmount +=
                val.attributes.discountType == 1
                  ? val.attributes.amount - val.attributes.discount
                  : val.attributes.amount -
                  (val.attributes.amount * val.attributes.discount) / 100;
              val2.itemIds.push(val.id);
              val2.items.push(val);
            }
          }
          break;
        }
      }
    }
    allItemsOps(props.feeTemplateItems);
    templateListOps(tempList);
  }, [props.feeTemplateItems]);
  useEffect(() => {
    if (showDeleteLoader) {
      if (!props.showDeleteLoader) {
        toastr.success("Template Delete Successfully", { timeOut: 2000 });
      }
    }
    showDeleteLoaderOps(props.showDeleteLoader);
  }, [props.showDeleteLoader]);
  const activeDropDown = (id) => {
    let temp = Object.assign([], templateList);
    for (const val of temp) {
      if (val.id === id) {
        val.actionActive = !val.actionActive;
      } else {
        val.actionActive = false;
      }
    }
    templateListOps(temp);
  };
  const tog_newTemplate = () => {
    newTemplateTogOps(!newTemplateTog);
  };
  const setEditNewTemplate = (obj) => {
    editTemplateObjOps(obj);
    tog_newTemplate();
  };
  const loadAllTemplates = () => {
    props.getFeeTemplateForSchoolId(props.schoolId);
    props.getFeeType();
  };
  const deleteThisTemplate = (id) => {
    props.deleteATemplateForSchool(id);
  };
  useEffect(() => {
    feeTypeOps(props.feeTypes);
  }, [props.feeTypes]);
  const canEditTemplet = (franchiseTemplate) => {

    if (
      Parse.User.current() && Parse.User.current().attributes.franchiseId &&
      Parse.User.current().attributes.franchiseId.length > 0) {
      if (franchiseTemplate) {
        return true;
      } else {
        return true;
      }

    } else if (props.showRoyalty) {
      if (franchiseTemplate) {
        return false;
      } else {
        return true;
      }
    } else {
      if (franchiseTemplate) {
        return false;
      } else {
        return true;
      }
    }

  }
  const canAddTemplet = () => {
    if (
      Parse.User.current() && Parse.User.current().attributes.franchiseId &&
      Parse.User.current().attributes.franchiseId.length > 0) {
      return true;
    } else if (props.showRoyalty) {
      return false;
    } else {
      return true;
    }
  }

  const checkForCanDeleteTemplate = (templateToDeleteId) => {
    setCheckingForTemplateUsedInProgram(true)

    let tempList = []
    if (props.programs && props.programs.length > 0) {
      let programIds = []
      for (const program of props.programs) {
        if (program.attributes.hasTerm) {
          programIds.push(program.id)
        }
      }
      if (programIds.length > 0) {
        var query = new Parse.Query('ProgramTerms');
        query.containedIn('programId', programIds);
        query.limit(1000);
        query.find().then((programTerms) => {
          if (programTerms) {
            for (const program of props.programs) {
              let tempObj = {}

              if (program.attributes.hasTerm) {

                let termsList = programTerms.filter((term) => (term.attributes.programId === program.id) && (term.attributes.feeTemplateId === templateToDeleteId))

                if (termsList.length > 0) {
                  tempObj.terms = termsList
                  tempObj.program = program
                  tempList.push(tempObj)
                }
              } else {
                if (program.attributes.templateId === templateToDeleteId) {
                  tempObj.program = program
                  tempList.push(tempObj)
                }
              }
            }
            if (tempList.length > 0) {
              setUsedInPrograms(tempList)
            }
            setCheckingForTemplateUsedInProgram(false)
          }
        })
      } else {
        for (const program of props.programs) {

          if (program.attributes.templateId === templateToDeleteId) {
            let tempObj = {}
            tempObj.program = program
            tempList.push(tempObj)
          }
        }
        if (tempList.length > 0) {
          setUsedInPrograms(tempList)
        }
        setCheckingForTemplateUsedInProgram(false)
      }
    } else {
      setCheckingForTemplateUsedInProgram(false)
    }
  }

  const closeDeleteTemplateModal = () => {
    setShowDeleteTemplateModel(undefined)
  }

  const getCorporatePartnerName = (id) => {
    if (id && props.corporatePartners) {
      for (const cp of props.corporatePartners) {
        if (cp.id == id) {
          return cp.attributes.name;
        }
      }
    }
    return "--"
  }

  const handelStatusDisable = (status) => {
    let ft = selectedFeeTemplate;
    ft.set("isDisabled", status)
    props.editFeeTemplate(ft);
    setSelectedFeeTemplate(undefined);
  }

  return (
    <React.Fragment>
      <NewTemplate
        schoolId={props.schoolId}
        currency={props.currency}
        loadAllTemplates={loadAllTemplates}
        tog_newTemplate={tog_newTemplate}
        newTemplateTog={newTemplateTog}
        title={templateTitle}
        templateObj={editTemplateObj}
        feeTypes={feeType}
        showRoyalty={props.showRoyalty}
        actionType={actionType}
        corporatePartners={props.corporatePartners}
        showCorporatePartners={props.showCorporatePartners}
        selectedSchool={props.selectedSchool}
      />
      <Card>
        <CardBody>
          {canAddTemplet() && <Row>
            <Col>
            <div className="d-flex justify-content-end">
              <button
                className="btn btn-primary m-1"
                onClick={() => {
                  setEditNewTemplate({});
                  templateTitleOps("New Template");
                  setActionType("new")
                }}
              >
                {props.t("New Template")}
              </button>
              </div>
            </Col>
          </Row>}
          <Row className="mt-2">
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>{props.t("Name")}</th>
                    <th>
                      {props.t("Total Amount")}(
                      <span style={{ fontFamily: "Arial, sans-serif" }}>
                        {props.currency}
                      </span>
                      )
                    </th>
                    <th>{props.t("Created At")}</th>
                    <th>{props.t("Action")}</th>
                  </tr>
                </thead>
                <tbody>
                  {templateList.length === 0 && (
                    <tr>
                      <td colSpan="5" className="pt-4">
                        <h3 className="text-center text-info">
                          {props.t("No Template Available")}
                        </h3>
                      </td>
                    </tr>
                  )}
                  {templateList.map((val, key) => (
                    <tr key={key}>
                      <td>{key + 1}</td>
                      <td>
                        {val.name}
                        {!val.isDisabled ? <p className="text-success m-o p-0">Active</p> : <p className="text-danger m-o p-0">InActive</p>}
                        <p>
                          {val.Obj.attributes.feeType && val.Obj.attributes.feeType + ", "}
                          {val.Obj.attributes.programType && val.Obj.attributes.programType + ", "}
                          {val.Obj.attributes.corporateId &&getCorporatePartnerName(val.Obj.attributes.corporateId) }
                        </p>
                        
                      </td>
                      <td>{parseFloat(val.totalAmount).toFixed(2)}</td>
                      <td>{val.createdAt}</td>
                      <td>
                        {(canEditTemplet(val.franchiseTemplate)) ? (
                          <Dropdown
                            isOpen={val.actionActive}
                            toggle={() => activeDropDown(val.id)}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-secondary"
                            >
                              {props.t("Action")}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  setEditNewTemplate(val);
                                  templateTitleOps("View Template");
                                  setActionType("view")
                                }}
                              >
                                {props.t("View")}
                              </DropdownItem>
                              {!val.isDisabled && <DropdownItem
                                onClick={() => {
                                  setEditNewTemplate(val);
                                  templateTitleOps("Edit Template");
                                  setActionType("edit")
                                }}
                              >
                                {props.t("Edit")}
                              </DropdownItem>}
                              <DropdownItem
                                onClick={() => {
                                  setUsedInPrograms([])
                                  setShowDeleteTemplateModel(val.id)
                                  checkForCanDeleteTemplate(val.id)
                                }
                                }


                              // deleteThisTemplate(val.id)}
                              >
                                {props.t("Delete")}
                              </DropdownItem>
                              <DropdownItem
                                onClick={() => {
                                  setSelectedFeeTemplate(val.Obj);
                                }}
                              >
                                {val.isDisabled ? props.t("Activate"): props.t("Deactivate") }
                              </DropdownItem>
                            </DropdownMenu>
                          </Dropdown>
                        ) :
                        <Dropdown
                            isOpen={val.actionActive}
                            toggle={() => activeDropDown(val.id)}
                          >
                            <DropdownToggle
                              tag="button"
                              className="btn btn-secondary"
                            >
                              {props.t("Action")}
                            </DropdownToggle>
                            <DropdownMenu>
                              <DropdownItem
                                onClick={() => {
                                  setEditNewTemplate(val);
                                  templateTitleOps("View Template");
                                  setActionType("view")
                                }}
                              >
                                {props.t("View")}
                              </DropdownItem>
                              
                            </DropdownMenu>
                          </Dropdown>
                        
                        }
                      </td>
                    </tr>
                  ))}
                  {templateList.length == 1 && (
                    <tr>
                      <td colSpan="5"></td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </Row>
        </CardBody>
      </Card>
      <Modal isOpen={showDeleteTemplateModel}>

        <ModalBody>
          {checkingForTemplateUsedInProgram ? <Row><Col xs="12">
            <div className="text-center my-3 text-success">
              <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
              {props.t("Checking...")}{" "}
            </div>
          </Col></Row> :
            <>
              {usedInPrograms.length === 0 ? <div className="text-center">
                <h2>
                  <i style={{ fontSize: "60px" }} className="bx bx-trash bx-tada text-danger mr-2"></i>
                </h2>
                <h5>Are you sure to delete this template?</h5>
              </div> :
                <div>
                  <Alert
                    color="danger"
                    role="alert"
                  >
                    Before deleting this template, please remove it from the following programs/terms.
                  </Alert>
                  {usedInPrograms.map((item, key) => (
                    <div className="border rounded p-2 m-2">
                      <Label key={key} className="mr-3"><b>{`${(item.program && item.program.attributes.name) ?? "-"}`}</b></Label>
                      {item.terms && item.terms.map((term, key) => (
                        <Label key={key + "term"} className="mr-3">{`${(term.attributes.termName) ?? "Term Name"}`}</Label>
                      ))}
                    </div>
                  ))}

                </div>
              }
            </>
          }

        </ModalBody>
        <ModalFooter>
          {!checkingForTemplateUsedInProgram && <>
            <button className="btn btn-primary" onClick={() => {
              closeDeleteTemplateModal()
            }}>
              {usedInPrograms.length > 0 ? "Close" : "Cancel"}
            </button>

            {usedInPrograms.length === 0 && <button className="btn btn-danger" onClick={() => {
              deleteThisTemplate(showDeleteTemplateModel)
              closeDeleteTemplateModal()
            }}>
              Delete
            </button>}
          </>}
        </ModalFooter>
      </Modal>
      
      {selectedFeeTemplate && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText={selectedFeeTemplate.attributes.isDisabled ? "Activate" : "Deactivate" }
          cancelBtnText="Cancel"
          confirmBtnBsStyle={selectedFeeTemplate.attributes.isDisabled ? "success" : "primary"}
          cancelBtnBsStyle="danger"
          title="Alert!"
          onCancel={() => {
            setSelectedFeeTemplate(undefined);
          }}
          onConfirm={() => handelStatusDisable(selectedFeeTemplate.attributes.isDisabled ? false : true)}
        >
          {props.t(`${selectedFeeTemplate.attributes.isDisabled ? 'Upon activation, this template will become accessible for use during the process of creating an invoice.' : 'Upon deactivation, this template will no longer be accessible during invoice creation. However, it can be reactivated at any time from the action menu.'}`)}
          <br></br>
        </SweetAlert>
      )}

    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { feeTemplates, showDeleteLoader, feeTypes } = state.FeeTemplate;
  const { feeTemplateItems } = state.FeeTemplateItem;
  const { programs } = state.Program;
  return { feeTemplates, feeTemplateItems, programs, showDeleteLoader, feeTypes };
};
export default connect(mapStatetoProps, {
  getFeeTemplateForSchoolId,
  getProgramsForSchoolId,
  getFeeTemplateItemsForInvoiceIds,
  deleteATemplateForSchool,
  getFeeType,
  editFeeTemplate,
})(withRouter(withNamespaces()(SchoolTemplate)));
