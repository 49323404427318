import * as actions from "./actionTypes";

export const addOrupdateClassRoom = (classRoom) => {
  return {
    type: actions.UPDATE_CLASSROOM,
    payload: { classRoom },
  };
};

export const deleteClassRoom = (classRoomId,toast) => {
  return {
    type: actions.DELETE_CLASSROOM,
    payload: { classRoomId ,toast}
  };
};
export const setClassRoomDelete= classRoom =>{
  return{
    type : actions.SET_DELETED_CLASS,
    payload: { classRoom }
  }
}
export const updateclassrooms = (classrooms) => {
  return {
      type: actions.UPDATE_CLASSROOMS,
      payload: { classrooms }
  }
}

export const getClassroomsForSchoolId = (schoolId) => {
  return {
      type: actions.GET_CLASSROOMS,
      payload: { schoolId }
  }
}
export const getClassroomsForSchoolIds = (schoolIds)=>{
  return{
    type: actions.GET_CLASSROOM_FOR_SCHOOLIDS,
    payload : { schoolIds }
  }
}
export const createClassForASchool = classDetails =>{
  return{
    type : actions.CREATE_NEW_CLASS,
    payload : { classDetails}
  }
}

export const checkForDaycare = (schoolId,classLevel,successMsg,errorMsg) => {
  return{
    type : actions.CHECK_FOR_DAYCARE,
    payload : { schoolId,classLevel,successMsg,errorMsg}
  }
}


export const updateAClassroomObj = classObj =>{
  return{
    type : actions.UPDATE_A_CLASSROOM_OBJ,
    payload : { classObj }
  }
}
