import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";

import * as EnquiryQuestionHelper from "../../../helpers/ParseHelpers/enquiryQuestions_helper";
import * as actionTypes from "./actionTypes";
import * as EnquiryQuestionActions from "./actions";
import * as Constants from "../../../Constents";

import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* deleteAEnquiryQuestion({ payload: { enquiryQuestion } }) {
  try {
    yield put(EnquiryQuestionActions.updateDeleteEnquiryQuestion(enquiryQuestion));
    const response = yield call(
      EnquiryQuestionHelper.deleteEnquiryQuestionFromParse,
      enquiryQuestion
    );
    if (response === "error") {
      enquiryQuestion.set("isDeleted", undefined);
      yield put(EnquiryQuestionActions.updateEnquiryQuestion(enquiryQuestion));
    }else 
    if (response) {
      toastr.success("Question successfully removed", { timeOut: 3000 });
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* addAEnquiryQuestion({ payload: { enquiryQuestion } }) {
  try {
  
    yield put(EnquiryQuestionActions.updateEnquiryQuestion(enquiryQuestion));
    const response = yield call(
      EnquiryQuestionHelper.editEnquiryQuestion,enquiryQuestion
    );
    if (response === "Error") {

      toastr.error("Please try again", { timeOut: 3000 });
      const local = yield call(
        EnquiryQuestionHelper.getEnquiryQuestionFromLocal,enquiryQuestion.id
      );

      if (local) {
        yield put(EnquiryQuestionActions.updateEnquiryQuestion(local));
      }
  
    }else 
    if (response) {
      yield put(EnquiryQuestionActions.updateEnquiryQuestion(response));
      toastr.success("Question successfully added", { timeOut: 3000 });
      response.pin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getEnquiryQuestionsForSchoolId({ payload: { schoolId } }) {
  try {
    
    var key = Constants.ENQUIRY_QUESTIONS_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(EnquiryQuestionHelper.getEnquiryQuestionForSchoolId, schoolId, null, true);
    yield put(EnquiryQuestionActions.updateEnquiryQuestions(localResponse));


    const serverResponse = yield call(EnquiryQuestionHelper.getEnquiryQuestionForSchoolId, schoolId, updatedTime, false);
    localStorage.setItem(key,newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(EnquiryQuestionActions.updateEnquiryQuestions(serverResponse));
      

      var nonDeleted = serverResponse.filter(
        (enquiryquestion) =>
          enquiryquestion.attributes.isDeleted !== true
      );

      var deleted = serverResponse.filter(
        (enquiryquestion) =>
          enquiryquestion.attributes.isDeleted 
      );
     
      if (nonDeleted) {
        Parse.Object.pinAll(nonDeleted);
      }

      if (deleted) {
        Parse.Object.unPinAll(deleted);
      }
      
        
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}



function* saveCustomObjects({ payload: { object } }) {
  try {
    const response = yield call(EnquiryQuestionHelper.saveCustomObjects, object);
    if (response) {
        //yield put(SchoolActions.updateSchool(response));
    } else {

    }
  } catch (error) {
      // yield put(apiError(error));
  }
}

function* editEnquiryQuestion({ payload: { enquiryQuestion } }) {
  try {
    const response = yield call(
      EnquiryQuestionHelper.editEnquiryQuestion,
      enquiryQuestion
    );
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    }else 
    if (response) {
      yield put(EnquiryQuestionActions.updateEnquiryQuestion(response));
      response.pin();
      toastr.success("Question successfully updated", { timeOut: 3000 });
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchDeleteEnquiryQuestion() {
  yield takeEvery(actionTypes.DELETE_ENQUIRY_QUESTION, deleteAEnquiryQuestion);
}

export function* watchGetEnquiryQuestionsForSchoolId() {
  yield takeEvery(
    actionTypes.GET_ENQUIRY_QUESTIONS,
    getEnquiryQuestionsForSchoolId
  );
}
export function* watchEditEquiryQuestion() {
  yield takeEvery(actionTypes.EDIT_ENQUIRY_QUESTION, editEnquiryQuestion);
}
export function* watchSaveCustomObjects() {
  yield takeEvery(actionTypes.SAVE_CUSTOM_OBJECTS, saveCustomObjects);
}


export function* watchAddEquiryQuestion() {
  yield takeLeading(actionTypes.ADD_ENQUIRY_QUESTION, addAEnquiryQuestion);
}

function* EnquiryQuestionSaga() {
  yield all([
    fork(watchDeleteEnquiryQuestion),
    fork(watchGetEnquiryQuestionsForSchoolId),
    fork(watchEditEquiryQuestion),
    fork(watchAddEquiryQuestion),
    fork(watchSaveCustomObjects),
  ]);
}
export default EnquiryQuestionSaga;
