import * as actions from "./actionTypes";

export const updateEnquiryReminder = (enquiryReminder) => {
  return {
    type: actions.UPDATE_ENQUIRY_REMINDER,
    payload: { enquiryReminder },
  };
};

export const deleteEnquiryReminder = (enquiryReminder) => {
  return {
    type: actions.DELETE_ENQUIRY_REMINDER,
    payload: {enquiryReminder},
  };
};

export const updateEnquiryReminders = (enquiryReminders) => {
  return {
    type: actions.UPDATE_ENQUIRY_REMINDERS,
    payload: { enquiryReminders },
  };
};

export const getEnquiryReminderforEnquiryId = (enquiryId) => {
  return {
    type: actions.GET_ENQUIRY_REMINDERS,
    payload: { enquiryId },
  };
};

export const setSelectedEnquiryReminder = (id) => {
  return {
    type: actions.SET_SELECTED_ENQUIRY_REMINDER,
    payload: { id },
  };
};
export const updateSelectedEnquiryReminder = (enquiryReminder) => {
  return {
    type: actions.UPDATE_SELECTED_ENQUIRY_REMINDER,
    payload: { enquiryReminder },
  };
};

export const editEnquiryReminder = (enquiryReminder) => {
  return {
      type: actions.EDIT_ENQUIRY_REMINDER,
      payload: { enquiryReminder }
  }
}

export const relplaceEnquiryReminders = (enquiryReminders) => {
  return {
    type: actions.REPLACE_ENQUIRY_REMINDERS,
    payload: { enquiryReminders },
  };
};

export const getEnquiryRemindersForEnquiryIds = (enquiryIds) => {
  return {
    type: actions.GET_ENQUIRY_REMINDERS_EQUIRY_IDS,
    payload: { enquiryIds },
  };
};

export const updateEnquiryRemindersForEnquiryIds = (enquiryReminders) => {
  return {
    type: actions.UPDATE_ENQUIRY_REMINDERS_EQUIRY_IDS,
    payload: { enquiryReminders },
  };
};

export const getEnquiryRemindersForSchoolId = (schoolId,startDate,endDate) => {
  return {
    type: actions.GET_ENQUIRY_REMINDERS_SCHOOL,
    payload: { schoolId ,startDate,endDate},
  };
};

export const updateEnquiryRemindersForSchoolId = (enquiryReminders) => {
  return {
    type: actions.UPDATE_ENQUIRY_REMINDERS_SCHOOL,
    payload: { enquiryReminders },
  };
};

export const saveEnquiryReminder = (enquiryReminder) =>{
  return{
      type: actions.SAVE_ENQUIRY_REMINDER,
      payload: {enquiryReminder}
  }
}