import React, { useEffect, useState } from 'react'
import { Card, CardBody, Row, Col, Table, UncontrolledTooltip, Tooltip, CardTitle, CardHeader, Button, Badge } from 'reactstrap'
import Editable from "react-bootstrap-editable";
import Parse from 'parse';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool, getInventoryTagsForOwnerId, updateInventoryTags } from '../../../store/actions';


const Tags = (props) => {

    var schoolId = props.schoolId;
    var franchiseId = props.franchiseId;

    const [addNewTag, setAddNewTag] = useState(false);
    const [newTags, setNewTag] = useState("");
    const [tags, setTags] = useState("");
    const [tagError, setTagError] = useState(false);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getInventoryTagsForOwnerId(schoolId, 'school');
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryTagsForOwnerId(franchiseId, 'franchise');
        }
    }, [franchiseId]);

    console.log(props.inventoryTags);

    const handelSave = (value) => {

        const InventoryTags = Parse.Object.extend("InventoryTags");
        var tag = new InventoryTags();

        tag.set("name", value);
        if (franchiseId) {
            tag.set("ownerType", "franchise");
            tag.set("ownerId", franchiseId);
        } else {
            tag.set("ownerType", "school");
            tag.set("ownerId", schoolId);
        }
        tag.set("createdBy", Parse.User.current().attributes.teacherId);
        console.log(tag)

        tag.save().then(
            (result) => {
                result.pin()
                props.updateInventoryTags([result]);

            },
            (error) => {
                console.log("error", error);

            }
        );

    }


    return (
        <Card>
            <CardBody>
                <CardHeader>
                    <b>Tags</b>
                    {!addNewTag && <Badge color="success" className=" m-3 font-size-12"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setAddNewTag(true)
                        }}>Add <i className="bx bx-plus ml-1" ></i></Badge>}

                    {addNewTag && <Badge color="danger" className=" m-3 font-size-12"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            setAddNewTag(false)
                        }}>Close <i className="mdi mdi-window-close ml-1"></i></Badge>}
                    {tagError && <p className="text-danger">Tags can not be empty</p>}
                </CardHeader>
                {addNewTag && <Editable
                    alwaysEditing={true}
                    editText={newTags ? " ✎" : "No value ✎"}
                    style={{ cursor: "pointer" }}
                    disabled={false}
                    id={null}
                    initialValue={newTags}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    onCancel={() => {
                        setAddNewTag(false)
                    }}
                    showText
                    type="textfield"
                    validate={(value) => {
                        if (!(value && value.length > 0)) {
                            return "Tag Can not be empty"
                        } else if (tags.includes(value)) {
                            return "This Tag is already added"
                        }
                    }}
                    onValidated={(value) => {
                        var temp = [...tags];
                        var res = value.split(",");
                        var newList = temp.concat(res);
                        if (newList.length > 0) {
                            setTagError(false);
                        }
                        handelSave(value)
                        setTags(newList);
                        setAddNewTag(false)
                    }}
                />}
                {
                    props.inventoryTags.map((tag, key) =>
                    (
                        <Badge key={key} color="primary" className="m-1 p-2 font-size-12">{tag.attributes.name}
                        </Badge>
                    ))
                }

            </CardBody>
        </Card>
    )
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryTags } = state.InventoryTag;
    return { selectedSchool, inventoryTags };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryTagsForOwnerId, updateInventoryTags })(Tags)));

