import React, { useState} from "react";
import {Container, Row, Col, Card, CardBody,Button ,CardHeader} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import Parse from "parse";
import { withRouter } from "react-router-dom";
import {
    showPricingPopup
} from "../../store/actions";

import Countdown from "react-countdown";
import moment from "moment";

const SubscriptionInfoComp = (props) => {

    //bg-soft-danger

    const [completed, setcompleted] = useState(false)

    function convertDateToString() {
        let str = "YYYY/MM/DD hh:mm a"
        return moment(props.subscriptionExpiryDate).format(str)
    }
    console.log(props.subscriptionExpiryDate)
    const renderer = ({ days, hours, minutes, seconds, completed }) => {
        if (completed) {
            // Render a completed state
            setcompleted(true)
            return <span></span>;
        } else {
            // Render a countdown
            return <>
                <div className="coming-box">{days}<span>Days</span></div>
                <div className="coming-box">{hours}<span>Hours</span></div>
                <div className="coming-box">{minutes}<span>Minutes</span></div>
                <div className="coming-box">{seconds}<span>Seconds</span></div>
            </>
        }
    }
    const features = [
        { id: 1, desc: "Please write to  sales@liltriangle.com OR Call on +91 7676548853" },
       
    ];

    return (
        <React.Fragment>
            <div className=" hero-section mb-5 p-0" >
                {/* <div className="bg-overlay bg-danger"></div> */}
                {/* <Container> */}
                <Row className="align-items-center">
                       
                    <Col lg="12" md="12" sm="12" >
                        <Card className=" mb-0 ">
                            <CardHeader className="text-center">
                                <h5 className="mb-0">{completed ? "Your lilTriangle Subscription Expired !" : "Your lilTriangle Subscription Expires In"}</h5>
                            </CardHeader>
                            <CardBody>
                                <div className="text-center">
                                    <div >
                                        <div className="counter-number ico-countdown">
                                            <Countdown
                                                date={convertDateToString()}
                                                renderer={renderer}
                                            />
                                        </div>
                                    </div>
                                    <h6 className="mt-4" >{completed ? "Please Renew Now" : "Please Renew Before Expiry"}</h6>

                                    <div className="mt-4">
                                        {!Parse.User.current().attributes.franchiseId &&<Button type="button" onClick={() => {
                                            localStorage.setItem("SHOW_PRICING_POPUP", "show");
                                            props.showPricingPopup(true)
                                        }} color="success" className="w-md">Explore Subscription Plans</Button>}
                                        
                                       
                                        <div className="text-muted mt-4">
                                            {
                                                features.map((feature, key) =>
                                                    <p key={key} className={feature.id === 1 ? "mb-2" : ""}><i className="mdi mdi-circle-medium text-success mr-1"></i>{feature.desc}</p>
                                                )
                                            }
                                        </div>
                                    </div>

                                       
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
                        
                {/* </Container> */}
                    
            </div>
        </React.Fragment>
    );
    
    
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  
  return {
    selectedSchool,
  };
};

export default withRouter(connect(mapStatetoProps, {showPricingPopup})(withNamespaces()(SubscriptionInfoComp)));