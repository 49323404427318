import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardSubtitle,
  CardHeader,
  Collapse,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import { } from "../../store/actions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import * as Constants from "../../Constents";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller, } from "react-scroll";
import {
  getAdmissiontitlesForSchoolId,
  getAdmissionQuestionForAdmissionTitleIds,
  editAdmissionQuestion,
  updateSortOrder,
  deleteAdmissionQuestion,
  addAdmissionTitle,
  updateTitlesSortOrder,
  addAdmissionQuestion,
  moveAdmissionQuestion,
  getDefaultAdmissionQuestions,
  editAdmissionTitle,
  deleteAdmissionTitle,
} from "../../store/actions";

import AdmissionQuestion from "./AdmissionQuestion";
import GeneralQuestions from "./GeneralQuestions";
import DragDropTables from '../LMS/DragDropTables'

const AdmissionFormSetup = (props) => {
  var schoolId = props.schoolId;
  useEffect(() => {
    props.getAdmissiontitlesForSchoolId(schoolId, true);
    props.getDefaultAdmissionQuestions();
  }, [schoolId]);

  const [titleIds, setTitleIds] = useState([]);
  const [tab, setTab] = useState("1");
  const [newTitle, showNewTitleUi] = useState(false);
  const [title, setNewTitle] = useState("");
  const [activeTab, setactiveTab] = useState("1");
  const [titlesQuesitionsList, setTitlesQuestions] = useState([]);

  const [nameError, setNameError] = useState(false);
  const [deleteTitleObject, setDeleteAdmissionTitle] = useState(undefined);
  const [editTitleObject, setEditAdmissionTitle] = useState(undefined);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [rearrange, setRearrange] = useState(false);
  const [questionRearrange, setQuestionRearrange] = useState(false);

  useEffect(() => {
    if (props.admissionTitles && props.admissionTitles.length > 0) {
      var list = [];
      var objectList = [];
      for (const i in props.admissionTitles) {
        list.push(props.admissionTitles[i].id);
        var obj = {
          className: "AdmissionTitle",
          titleObject: props.admissionTitles[i]
        };
        objectList.push(obj);
      }
      setTitleIds(list);
      setTitlesQuestions(objectList);
      props.getAdmissionQuestionForAdmissionTitleIds(list, schoolId);
    }
  }, [props.admissionTitles]);

  useEffect(() => {
    if (props.admissionTitles && props.admissionTitles.length > 0) {
      var list = [];
      for (const i in props.admissionTitles) {
        var filter = props.admissionQuestions.filter(
          (question) =>
            question.attributes.admissionTitleId === props.admissionTitles[i].id
        );
        var obj = { className: "AdmissionTitle", titleObject: props.admissionTitles[i], questions: filter };
        list.push(obj);
      }
      setTitlesQuestions(list);
    }
  }, [props.admissionQuestions]);


  function handelMoveAdmissionQuestion(question) {
    props.moveAdmissionQuestion(question, titleIds);
  }

  function handelAddAdmissionQuestion(question) {
    props.addAdmissionQuestion(question);
  }
  function handelRemoveQuestion(question) {
    props.deleteAdmissionQuestion(question);
  }

  function handelUpdateOrder(questionObjects) {
    var list = [];
    for (const i in questionObjects) {
      var temp = questionObjects[i];
      if (temp) {
        var obj = temp;
        obj.set("order", parseInt(i));
        list.push(obj);
      }
    }

    if (list.length > 0) {
      props.updateSortOrder(list, titleIds);
    }
    setQuestionRearrange(false)
  }

  function handelEditQuestion(question) {
    props.editAdmissionQuestion(question);
  }

  function handelAddAdmissionTitle() {
    const AdmissionTitle = Parse.Object.extend("AdmissionTitle");
    var admissionTitle = new AdmissionTitle();
    var order = 0;
    if (props.admissionTitles && props.admissionTitles.length > 0) {
      order =
        1 +
        props.admissionTitles[props.admissionTitles.length - 1].attributes
          .pageNumber;
    }
    admissionTitle.set("title", title);
    admissionTitle.set("pageNumber", order);
    admissionTitle.set("schoolId", schoolId);
    props.addAdmissionTitle(admissionTitle);
  }

  function handelTitlesSort(keys) {
    var list = [];
    for (const i in keys) {
      var id = keys[i].content.key;
      var temp = props.admissionTitles.filter((q) => q.id === id);
      if (temp.length > 0) {
        var obj = temp[0];
        obj.set("pageNumber", parseInt(i));
        list.push(obj);
      }
    }

    if (list.length > 0) {
      props.updateTitlesSortOrder(list, schoolId);
    }
  }
  function handelTitlesSort2(sortedList) {
    var list = [];
    setRearrange(false)
    setTitlesQuestions(sortedList)

    for (const i in sortedList) {

      let titleObject = sortedList[1].titleObject;
      if (titleObject) {
        var obj = titleObject;
        obj.set("pageNumber", parseInt(i));
        list.push(obj);
      }
    }

    if (list.length > 0) {
      props.updateTitlesSortOrder(list, schoolId);
    }
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="checkout-tabs">
            <Row>
              <Col lg="2">
                <Nav className="flex-column" pills>
                  <Element name="navTabs">
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "1" })}
                        onClick={() => {
                          setactiveTab("1");
                        }}
                      >
                        <i className="bx bx-clipboard d-block check-nav-icon mt-4 mb-2"></i>
                        <p className="font-weight-bold mb-4">
                          {props.t("School Admission Form")}
                        </p>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({ active: activeTab === "2" })}
                        onClick={() => {
                          setactiveTab("2");
                        }}
                      >
                        <i className="bx bx-check-shield d-block check-nav-icon mt-4 mb-2"></i>
                        <p className="font-weight-bold mb-4">
                          {props.t("General Admission Form Questions")}
                        </p>
                      </NavLink>
                    </NavItem>

                  </Element>

                </Nav>
              </Col>
              <Col lg="10">
                <Card>
                  <CardBody>
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1">
                        <Row>
                          <Col lg={10}>
                            <CardTitle> {props.t("Admission Form sections")}</CardTitle>
                          </Col>


                          {!rearrange && <Col lg={2}>
                            <button
                              className="btn btn-sm btn-primary float-right mb-2"
                              onClick={() => {
                                setRearrange(true)
                              }}
                            > Rearrange
                            </button>
                          </Col>}



                        </Row>
                        {rearrange &&
                          <DragDropTables
                            rearrangeObjects={titlesQuesitionsList}
                            updatedOrderList={handelTitlesSort2}
                            onClickClose={() => { setRearrange(false) }}
                          />}
                        {!rearrange && <div className="cursors move">

                          {titlesQuesitionsList.map((object) =>

                            <Card
                              className="mb-1 bg-white"
                              key={object.titleObject.id}
                            >
                              <CardHeader
                                className="p-3"
                                id="headingOne"
                              >
                                <div>

                                  {editTitleObject && editTitleObject.id === object.titleObject.id ? <Col>
                                    <div className="list-inline contact-links mb-0">
                                      <AvForm className="outer-repeater mt-3"
                                        onValidSubmit={(e, v) => {
                                          if (v.edittitle && v.edittitle.length > 0) {
                                            var cur = object.titleObject;
                                            cur.set("title", v.edittitle);
                                            props.editAdmissionTitle(cur);
                                            setEditAdmissionTitle(undefined);
                                            setNewTitle("");
                                            setNameError(false);
                                          } else {
                                            setNameError(true);
                                          }
                                        }}
                                      >
                                        <div data-repeater-item className="outer">
                                          <FormGroup >
                                            <AvField
                                              type="text"
                                              name="edittitle"
                                              placeholder={props.t('Section Name')}
                                              className="form-control"
                                              value={title}

                                            />
                                            {nameError && <p className="text-danger"> {props.t('Section name can not be empty')}</p>}
                                          </FormGroup>
                                        </div>
                                        <div className="d-flex justify-content-between">
                                          <button
                                            type="submit"
                                            className="btn btn-primary mr-1 "
                                          >
                                            {props.t('Save')}
                                          </button>
                                          <button type="button"
                                            className="btn btn-danger "
                                            onClick={() => {
                                              setEditAdmissionTitle(undefined);
                                              setNewTitle("");
                                              setNameError(false);
                                            }}  >
                                            {props.t('Close')}

                                          </button>
                                        </div>
                                      </AvForm>

                                    </div>

                                  </Col>
                                    :
                                    <Row>
                                      <Col lg={10} xs={10}
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          setQuestionRearrange(false)
                                          if (tab === object.titleObject.id) {
                                            setTab("");
                                          } else {
                                            setTab(object.titleObject.id);
                                          }
                                        }}
                                      >
                                        <h6 className="m-0 font-14">
                                          <span
                                            style={{ cursor: "pointer" }}
                                            className="text-dark"
                                          >
                                            {object.titleObject.attributes.title}{" "}
                                          </span>
                                          <span className="text-secondary">
                                            {" ("}
                                            {object.questions &&
                                              object.questions.length &&
                                              object.questions.length}
                                            {" " + props.t("Questions")}
                                            {")  "}

                                          </span>

                                          <i
                                            style={{ cursor: "pointer" }}
                                            className={
                                              tab === object.titleObject.id
                                                ? "bx bxs-chevron-up  ml-2"
                                                : "bx bxs-chevron-down  ml-2"
                                            }
                                          />
                                        </h6>
                                      </Col>
                                      <Col>
                                        <ul className="list-inline font-size-18 contact-links mb-0 float-right">

                                          {(tab === object.titleObject.id) && <li className="list-inline-item px-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setQuestionRearrange(true);
                                            }}
                                            id={"rearrange" + object.titleObject.id}>
                                            <i className="bx bxs-analyse"></i>
                                            <UncontrolledTooltip placement="top" target={"rearrange" + object.titleObject.id}>
                                              {props.t('Rearrange questions')}
                                            </UncontrolledTooltip>
                                          </li>}
                                          <li className="list-inline-item px-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              setEditAdmissionTitle(object.titleObject);
                                              setNewTitle(object.titleObject.attributes.title);
                                            }}
                                            id={"edit" + object.titleObject.id}>
                                            <i className="bx bx-edit-alt"></i>
                                            <UncontrolledTooltip placement="top" target={"edit" + object.titleObject.id}>
                                              {props.t('Edit')}
                                            </UncontrolledTooltip>
                                          </li>

                                          <li className="list-inline-item px-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              if (object.questions.length > 0) {
                                                setDeleteWarning(true);
                                              } else {
                                                setDeleteAdmissionTitle(object);
                                              }

                                            }}
                                            id={"delete" + object.titleObject.id}>
                                            <i className="bx bx-trash text-danger"></i>
                                            <UncontrolledTooltip placement="top" target={"delete" + object.titleObject.id}>
                                              {props.t('Delete')}
                                            </UncontrolledTooltip>
                                          </li>
                                        </ul>
                                      </Col>
                                    </Row>}


                                </div>
                              </CardHeader>

                              <Collapse
                                isOpen={tab === object.titleObject.id}
                              >
                                <AdmissionQuestion
                                  schoolId={schoolId}
                                  questions={object.questions}
                                  admissionTitles={props.admissionTitles}
                                  admissionTitleObject={object.titleObject}
                                  onEditQuestion={handelEditQuestion}
                                  onAddQuestion={handelAddAdmissionQuestion}
                                  onUpdateOrder={handelUpdateOrder}
                                  onRemoveQuestion={handelRemoveQuestion}
                                  onMoveQuestion={
                                    handelMoveAdmissionQuestion
                                  }
                                  onClickGAQ={() => {
                                    setactiveTab("2");
                                    scroller.scrollTo("navTabs", {
                                      duration: 500,
                                      delay: 100,
                                      smooth: true,
                                      offset: -100,
                                    });
                                  }}
                                  questionRearrange={questionRearrange}
                                  closeQuestionRearrange={() => { setQuestionRearrange(false) }}
                                />
                              </Collapse>
                            </Card>

                          )}

                          <Col lg={12}>
                            {newTitle && (
                              <AvForm className="outer-repeater">
                                <div data-repeater-item className="outer">
                                  <FormGroup>
                                    <Label htmlFor="formname">
                                      {props.t("Admission title")}:{" "}
                                    </Label>
                                    <AvField
                                      type="text"
                                      name="name"
                                      placeholder={props.t(
                                        "Enter Admission title..."
                                      )}
                                      className="form-control"
                                      value={title}
                                      validate={{
                                        required: {
                                          value: true,
                                          errorMessage: props.t(
                                            "Enter Admission title..."
                                          ),
                                        },
                                      }}
                                      onChange={(e) => {
                                        if (e.target.value.length > 0 && nameError) {
                                          setNameError(false);
                                        }
                                        setNewTitle(e.target.value);
                                      }}
                                    />
                                    {nameError && <p className="text-danger">{props.t('Question can not be empty')}</p>}
                                  </FormGroup>
                                </div>
                              </AvForm>
                            )}
                            {!newTitle ? (
                              <button
                                onClick={() => {
                                  showNewTitleUi(!newTitle);
                                }}
                                type="button"
                                className="btn btn-primary waves-effect waves-light float-right m-2"
                              >
                                <i className="bx bx-plus font-size-16 align-middle mr-1"></i>{" "}
                                {props.t("Add Section")}
                              </button>
                            ) : (
                              <div className="d-flex justify-content-between">
                                <button
                                  type="button"
                                  className="btn btn-secondary"
                                  onClick={() => {
                                    showNewTitleUi(!newTitle);
                                    setNewTitle("");
                                    setNameError(false);
                                  }}
                                >
                                  {props.t("Close")}
                                </button>

                                <button
                                  onClick={() => {
                                    if (title && title.length > 0) {
                                      handelAddAdmissionTitle();
                                      showNewTitleUi(!newTitle);
                                      setNewTitle("");
                                      setNameError(false);
                                    } else {
                                      setNameError(true);
                                    }
                                  }}
                                  type="button"
                                  className="btn btn-primary waves-effect waves-light"
                                >
                                  <i className="bx bx-save font-size-16 align-middle"></i>{" "}
                                  {props.t("Save Section")}
                                </button>
                              </div>
                            )}
                          </Col>
                        </div>}
                      </TabPane>
                      <TabPane tabId="2">
                        <GeneralQuestions
                          schoolId={schoolId}
                          admissionQuestions={props.admissionQuestions}
                          defaultQuestions={props.defaultQuestions}
                          admissionTitles={props.admissionTitles}
                          onRemoveQuestion={handelRemoveQuestion}
                          onAddQuestion={handelAddAdmissionQuestion}
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </CardBody>
      </Card>
      {deleteTitleObject ? (

        <SweetAlert
          title={props.t('Are you sure?')}
          warning
          showCancel
          confirmBtnText="Delete"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            props.deleteAdmissionTitle(deleteTitleObject.titleObject);
            setDeleteAdmissionTitle(undefined);
            setsuccess_dlg(true);
          }}
          onCancel={() => {
            setDeleteAdmissionTitle(undefined);
            setsuccess_dlg(false);

          }}>
          {props.t("You won't be able to revert this!")}

        </SweetAlert>

      ) : null}
      {success_dlg ? (
        <SweetAlert
          success
          title={props.t('Deleted')}
          onConfirm={() => {
            setsuccess_dlg(false)
          }}
        >
          {props.t('Section has been deleted.')}
        </SweetAlert>
      ) : null}
      {
        deleteWarning && <SweetAlert
          title={props.t('Warning')}
          warning
          confirmBtnBsStyle="warning"
          onConfirm={() => {
            setDeleteWarning(false);
          }}>
          {props.t('Remove or move all the  questions from selected section and delete ')}

        </SweetAlert>
      }
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { admissionTitles } = state.AdmissionTitle;
  const { admissionQuestions, defaultQuestions } = state.AdmissionQuestion;
  return { admissionTitles, admissionQuestions, defaultQuestions };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getAdmissiontitlesForSchoolId,
      getAdmissionQuestionForAdmissionTitleIds,
      editAdmissionQuestion,
      updateSortOrder,
      deleteAdmissionQuestion,
      addAdmissionTitle,
      updateTitlesSortOrder,
      addAdmissionQuestion,
      moveAdmissionQuestion,
      getDefaultAdmissionQuestions,
      editAdmissionTitle,
      deleteAdmissionTitle,
    })(AdmissionFormSetup)
  )
);
