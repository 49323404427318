import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, TabContent, TabPane, Card, Form, Button, CardText, Nav, NavItem, NavLink, CardBody, FormGroup, Input } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import classnames from 'classnames';
import SweetAlert from "react-bootstrap-sweetalert";
import Parse from 'parse';
import Moment from 'moment';
import ListForProgram from "./ListForProgram"

import {
  getGroupsForSchoolId,
  getUserTeacher,
  deleteGroup, getProgramsForSchoolId, getGroupsForGroupIds, deleteProgram, setSelecetdSchool,
  setForwardToProgramView
} from '../../store/actions';
import * as Constants from '../../Constents';
//Import Cards
import CardForProgram from "./CardForProgram";

//Import Image
import img3 from "../../assets/images/companies/img-3.png";

const GroupDetailPage = (props) => {
  
  const [displayType, setType] = useState(Constants.PROGRAM_DISPLAY_TYPE_LIST);
  const [isTeacherPermission, setIsTeacherPermission] = useState(false);

  const handleDisplay = (type) => {
    localStorage.setItem(Constants.PROGRAM_DISPLAY_TYPE, type);
    setType(type);
  }

  useEffect(() => {
    if (localStorage.getItem(Constants.PROGRAM_DISPLAY_TYPE)) {
      setType(localStorage.getItem(Constants.PROGRAM_DISPLAY_TYPE));
    }
  });



  const match = matchPath(props.history.location.pathname, {
    path: "/programdetail/:id",
    exact: true,
    strict: false,
  });

  const schoolId = match.params.id;

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
    if (Parse.User.current().attributes.teacherId) {
      props.getUserTeacher(Parse.User.current().attributes.teacherId)
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    if (props.userTeacher && props.userTeacher.attributes.featurePermissions && props.userTeacher.attributes.featurePermissions.length > Constants.TEACHER_PROGRAMS && props.userTeacher.attributes.featurePermissions[Constants.TEACHER_PROGRAMS] === 2) {
      setIsTeacherPermission(true)
    } else {
      setIsTeacherPermission(false)

    }
  }, [props.userTeacher])

  const [displayList, setDisplayList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const filterList = ["All", "Upcoming", "In Progress", "Completed"];
  const [todayDate, setTodayDate] = useState(new Date());
  const [selectedFilter, setSelectedFilter] = useState("All");
  const [fullList, setFullList] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [programToDelete, setProgramToDelete] = useState([]);
  const [searchString, setSearchString] = useState("");

  useEffect(() => {
    if (props.forwardToProgramView) {
      let programId = props.forwardToProgramView.id
      let groupId = props.forwardToProgramView.attributes.groupId
      props.setForwardToProgramView(undefined)
      props.history.push("/programview/" + schoolId + "/" + programId + "/" + groupId);
    }
  }, [props.forwardToProgramView])


  useEffect(() => {
    if (schoolId && schoolId !== "") {
      props.getProgramsForSchoolId(schoolId)
    }
  }, [schoolId]);
  useEffect(() => {

    if (props.noProgramsToDisplay === true) {
      setIsLoading(false)
    }

  }, [props.noProgramsToDisplay]);




  useEffect(() => {

    if (props.programs) {
      if (props.programs.length > 0) {
        var groupIds = []
        for (var i = 0; i < props.programs.length; i++) {
          groupIds.push(props.programs[i].attributes.groupId)
        }
        props.getGroupsForGroupIds(groupIds)
      }
      else {
        if (props.isLoading === false) {
          setIsLoading(false)
        }
      }
    }

  }, [props.programs]);



  useEffect(() => {

    if (props.groupsForIds) {

      if (props.groupsForIds.length > 0) {
        // console.log("groups checking")
        // console.log(props.groupsForIds)

        var groups = props.groupsForIds
        var programs = props.programs


        var displayList1 = []

        var i = 0
        var j = 0
        for (i = 0; i < groups.length; i++) {
          for (j = 0; j < programs.length; j++) {
            if (programs[j].attributes.groupId === groups[i].id) {
              var newObject = {
                id: i,
                parseObjectGroup: groups[i],
                programName: programs[j].attributes.name,
                img: img3,
                childrenCount: programs[j].attributes.participantsCount ? programs[j].attributes.participantsCount : groups[i].attributes.participantsCount ?? 0,
                parseObjectProgram: programs[j],
                createdAt: programs[j].createdAt,
                startDate: getTimeStringForDate(programs[j].attributes.startDate),
                endDate: getTimeStringForDate(programs[j].attributes.endDate),
                programBatch: programs[j].attributes.tags,
                programType: programs[j].attributes.type,
              }

              if (programs[j].attributes.startDate < todayDate && programs[j].attributes.endDate > todayDate) {

                newObject["color"] = "warning"
                newObject["status"] = "In Progress"

                // color: "success",
                // status: "Completed",

              } else if (programs[j].attributes.startDate > todayDate && programs[j].attributes.endDate > todayDate) {
                newObject["color"] = "primary"
                newObject["status"] = "Upcoming"
              } else {
                newObject["color"] = "success"
                newObject["status"] = "Completed"

              }

              displayList1.push(newObject)

            }
          }
        }

        let tempUpcomingList = displayList1.filter((temp) =>
          temp.status === "Upcoming"
        )
        let tempInProgressList = displayList1.filter((temp) =>
          temp.status === "In Progress"
        )
        let tempCompletedList = displayList1.filter((temp) =>
          temp.status === "Completed"
        )

        displayList1 = [...tempUpcomingList]
        displayList1 = displayList1.concat(tempInProgressList)
        displayList1 = displayList1.concat(tempCompletedList)
        displayList1 = displayList1.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)
        setFullList(displayList1)
        setDisplayList(displayList1)
        setIsLoading(false)
      } else {
        if (props.isLoadingForGroup === false) {
          setIsLoading(false)
        }

      }


    }

  }, [props.groupsForIds]);

  useEffect(() => {

    if (props.deletedSuccessfullyProgram) {
      var tempFullList = [...fullList];
      var tempDisplayList = [...displayList];
      removeElement(tempFullList, programToDelete)
      removeElement(tempDisplayList, programToDelete)
      setFullList(tempFullList)
      setDisplayList(tempDisplayList)
      setIsLoading(false)
    }

  }, [props.programs, props.deletedSuccessfullyProgram]);

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  useEffect(() => {

    var tempList = [...fullList]
    if (selectedFilter === "All") {

    } else if (selectedFilter === "Upcoming") {
      tempList = tempList.filter((object) =>
        object.parseObjectProgram.attributes.startDate > todayDate
      )
    } else if (selectedFilter === "Completed") {
      tempList = tempList.filter((object) =>
        object.parseObjectProgram.attributes.endDate < todayDate
      )
    } else if (selectedFilter === "In Progress") {
      tempList = tempList.filter((object) =>
        object.parseObjectProgram.attributes.startDate < todayDate && object.parseObjectProgram.attributes.endDate > todayDate
      )
    }

    handleSearch(tempList,searchString)

  }, [selectedFilter, searchString])
  const handleSearch = (tempList,searchString) => {
    if (searchString && tempList && tempList.length>0) {
      const tList = tempList.filter(
        (item) => item.programName && item.programName.toLowerCase().includes(searchString.toLowerCase()));
      setDisplayList(tList);
    } else {
      setDisplayList(tempList);
    } 
  };
  


const showNoPrograms = () => {
    if (!isLoading) {

      if (props.noProgramsToDisplay && isLoading === false) {
        return (
          <Card>

            <CardBody>
              <p className="text-center">No programs to display !!</p>
            </CardBody>
          </Card>)
      } else if (!displayList || displayList.length === 0) {
        if (selectedFilter === "All") {
          return (
            <Card>
              <CardBody>
                <p className="text-center">{"No programs to display !!"}</p>
              </CardBody>
            </Card>);
        }
        return (
          <Card>
            <CardBody>
              <p className="text-center">{"No " + selectedFilter + " programs to display !!"}</p>
            </CardBody>
          </Card>);
      }

    } else {
      return (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Programs... </Label>
            </div>
          </Col>
        </Row>
      )
    }



  }

  function getTimeStringForDate(date) {
    return Moment(date).format("MMM Do YY");
  }

  const handleDeleteProgram = () => {
    if (programToDelete != null) {
      var temp = programToDelete.parseObjectProgram
      temp.set("deleted", true)
      props.deleteProgram(temp)
      setShowDeleteAlert(false)
    }
  }


  const handleDeleteButtonClickFromCard = (program) => {
    setProgramToDelete(program)
    setShowDeleteAlert(true)
  }




  return (

    <React.Fragment>
      <div className="page-content">
        {!isLoading && <Container fluid>
          <Row className="justify-content-between">
            <Col lg={6} sm={6}>
              <div className='m-3'>
                <h4 className="font-size-18" >PROGRAMS</h4>
              </div>
            </Col>
            <Col lg={2} md={6}>
              <div className="search-box m-2 mr-2 ">
                <div className="position-relative">
                  <Input
                    type="text"
                    className="form-control border-0"
                    placeholder="Search..."
                    value={searchString}
                    onChange={(e) => {
                      setSearchString(e.target.value)
                     
                    }}
                  />
                  <i className="bx bx-search-alt search-icon"></i>
                </div>
              </div>
            </Col>

            <Col lg={4} sm={6}>
              <Form className="d-flex justify-content-end">
                <div className="m-2">
                  <FormGroup>
                    <select
                      className="form-control"
                      onChange={(e) => {
                        setSelectedFilter(e.target.value);
                      }}
                      value={selectedFilter}
                    >
                      {filterList.map((filter, key) => (
                        <option key={key}>{filter}</option>
                      ))}
                    </select>
                  </FormGroup>
                </div>
                <div className="m-2">
                  <Form className="form-inline">
                    {isTeacherPermission && (
                      <Link
                        className="btn btn-primary waves-effect waves-light"
                        to={"/addprogram/" + schoolId}
                        role="button"
                      >
                        Add Program
                      </Link>
                    )}
                  </Form>
                </div>
                <Nav className="product-view-nav justify-content-end m-2" pills>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: displayType === Constants.PROGRAM_DISPLAY_TYPE_LIST,
                      })}
                      onClick={() => {
                        handleDisplay(Constants.PROGRAM_DISPLAY_TYPE_LIST);
                      }}
                    >
                      <i className="bx bx-list-ul"></i>
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: displayType === Constants.PROGRAM_DISPLAY_TYPE_GRID,
                      })}
                      onClick={() => {
                        handleDisplay(Constants.PROGRAM_DISPLAY_TYPE_GRID);
                      }}
                    >
                      <i className="bx bx-grid-alt"></i>
                    </NavLink>
                  </NavItem>
                </Nav>
              </Form>
            </Col>
          </Row>


          <Row>
            {showDeleteAlert && <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes"
              cancelBtnText="No"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="success"
              title="Alert!"
              onCancel={() => {
                setShowDeleteAlert(false);
              }}
              onConfirm={() => handleDeleteProgram()}
            >
              Are you sure to delete this program?<br></br>
            </SweetAlert>}

            {
              displayType === Constants.PROGRAM_DISPLAY_TYPE_GRID ?
                <CardForProgram programs={displayList} selectedFilter={selectedFilter} programToDeleteButtonClicked={(program) => { handleDeleteButtonClickFromCard(program) }} />
                :
                <ListForProgram programs={displayList} selectedFilter={selectedFilter} programToDeleteButtonClicked={(program) => { handleDeleteButtonClickFromCard(program) }} />
            }
          </Row>
        </Container >}

        {showNoPrograms()}
      </div >
    </React.Fragment >

  );
}

const mapStatetoProps = state => {
  const { groups, deletedSuccessfully, groupsForIds, isLoadingForGroup } = state.Group;
  const { programs, isLoading, noProgramsToDisplay, forwardToProgramView } = state.Program;
  const { selectedSchool } = state.School;
  const { userTeacher } = state.Login;



  return {
    groups, deletedSuccessfully, groupsForIds, isLoadingForGroup,
    programs, isLoading, noProgramsToDisplay, forwardToProgramView,
    selectedSchool,
    userTeacher
  }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
  getGroupsForSchoolId,
  deleteGroup,
  getProgramsForSchoolId,
  getUserTeacher,
  getGroupsForGroupIds,
  deleteProgram,
  setSelecetdSchool,
  setForwardToProgramView
})(GroupDetailPage)));
