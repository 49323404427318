import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Label, 
  Button,
} from "reactstrap";
import Moment from 'moment';
import { Link } from "react-router-dom";
import Parse from "parse";
import { withNamespaces } from "react-i18next";


const FormTab = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [displayList, setDisplayList] = useState([]);
  useEffect(() => {
    if (props.selectedKid) {
      var query = new Parse.Query("FormSessions");
      query.equalTo("userId", props.selectedKid.id);
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.find().then((results) => {

        let formIds = []
        for (var i = 0; i < results.length; i++) {
          formIds.push(results[i].attributes.formId)
        }

        if (formIds.length > 0) {

          var query = new Parse.Query("Forms");
          query.containedIn("objectId", formIds);
          query.notEqualTo("isDeleted", true);
          query.limit(1000);
          query.find().then((formResults) => {


            setIsLoading(false);
            var tempList = [];

            if (results != null) {
              if (results.length > 0) {
                let tempList = [];
                for (var i = 0; i < results.length; i++) {
                  let tempFormObject = formResults.filter(form =>
                    form.id === results[i].attributes.formId

                  )
                  let tempFormName = "-"
                  if (tempFormObject.length > 0) {
                    tempFormName = tempFormObject[0].attributes.name
                  }

                  let tempObj = {
                    mappedTo: "",
                    formName: tempFormName,
                    sessionParseObject: results[i],
                  };

                  let tempMappedTo = "";
                  if (
                    results[i].attributes.userId != undefined &&
                    results[i].attributes.userId.length > 0
                  ) {
                    if (results[i].attributes.userType === "teacher") {
                      if (props.teachers && props.teachers.length > 0) {
                        let tempUser = props.teachers.filter((teacher) => {
                          return teacher.id === results[i].attributes.userId;
                        });
                        if (tempUser && tempUser.length > 0) {
                          tempMappedTo = tempUser[0].attributes.Name;
                        }
                      }
                    } else if (results[i].attributes.userType === "kid") {
                      if (props.kids && props.kids.length > 0) {
                        let tempUser = props.kids.filter((kid) => {
                          return kid.id === results[i].attributes.userId;
                        });
                        if (tempUser && tempUser.length > 0) {
                          tempMappedTo = tempUser[0].attributes.Name;
                        }
                      }
                    }
                  }
                  tempObj.mappedTo = tempMappedTo;
                  if (tempFormObject.length === 0 || tempFormObject[0].attributes.isDeleted) {
                  } else {
                    tempList.push(tempObj);
                  }
                }
                setDisplayList(tempList);
              } else {
                //setIsQuestionsAvailable(false);
              }
            }

          })
        } else {
          setIsLoading(false);

        }
      });
    }
  }, [props.selectedKid]);



  useEffect(() => {
    if (props.selectedTeacher) {
      var query = new Parse.Query("FormSessions");
      query.equalTo("userId", props.selectedTeacher.id);
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.find().then((results) => {

        let formIds = []
        for (var i = 0; i < results.length; i++) {
          formIds.push(results[i].attributes.formId)
        }

        if (formIds.length > 0) {
          var query = new Parse.Query("Forms");
          query.containedIn("objectId", formIds);
          query.notEqualTo("isDeleted", true);
          query.limit(1000);
          query.find().then((formResults) => {

            setIsLoading(false);
            var tempList = [];

            if (results != null) {
              if (results.length > 0) {
                let tempList = [];
                for (var i = 0; i < results.length; i++) {
                  let tempFormObject = formResults.filter(form =>
                    form.id === results[i].attributes.formId

                  )
                  let tempFormName = "-"
                  if (tempFormObject.length > 0) {
                    tempFormName = tempFormObject[0].attributes.name
                  }

                  let tempObj = {
                    mappedTo: "",
                    formName: tempFormName,
                    sessionParseObject: results[i],
                  };

                  let tempMappedTo = "";
                  if (
                    results[i].attributes.userId != undefined &&
                    results[i].attributes.userId.length > 0
                  ) {
                    if (results[i].attributes.userType === "teacher") {
                      if (props.teachers && props.teachers.length > 0) {
                        let tempUser = props.teachers.filter((teacher) => {
                          return teacher.id === results[i].attributes.userId;
                        });
                        if (tempUser && tempUser.length > 0) {
                          tempMappedTo = tempUser[0].attributes.Name;
                        }
                      }
                    } else if (results[i].attributes.userType === "kid") {
                      if (props.kids && props.kids.length > 0) {
                        let tempUser = props.kids.filter((kid) => {
                          return kid.id === results[i].attributes.userId;
                        });
                        if (tempUser && tempUser.length > 0) {
                          tempMappedTo = tempUser[0].attributes.Name;
                        }
                      }
                    }
                  }
                  tempObj.mappedTo = tempMappedTo;
                  if (tempFormObject.length === 0 || tempFormObject[0].attributes.isDeleted) {
                  } else {
                    tempList.push(tempObj);
                  }
                }
                setDisplayList(tempList);
              } else {
                //setIsQuestionsAvailable(false);
              }
            }
          });
        } else {
          setIsLoading(false);

        }

      })
    }
  }, [props.selectedTeacher]);




  return (
    <React.Fragment>



      {!isLoading && (
        <div>

          {displayList.length > 0 && (
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>{props.t("Form Name")}</th>
                    <th style={{ width: "40px", textAlign: "center" }}>
                      {props.t("Submitted On")}
                    </th>
                    <th style={{ width: "20px" }}>
                      {props.t("View Details")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {displayList.map((object, key) => (
                    <tr key={key}>
                      <td>
                        <Label className="font-size-12 mb-1">
                          {object.formName
                            ? object.formName
                            : "-"}
                        </Label>
                      </td>
                      <td style={{ textAlign: "center" }}>
                        <Label className="font-size-12 mb-1">
                          {object.sessionParseObject && Moment(object.sessionParseObject.createdAt).format('DD-MM-YYYY, h:mm a')}
                        </Label>
                      </td>

                      <td>
                        <Link
                          to={
                            "/printformview/" +
                            props.schoolId +
                            "/" +
                            object.sessionParseObject.attributes.formId +
                            "/" +
                            object.formName +
                            "/" +
                            object.sessionParseObject.id
                          }
                        >
                          <Button
                            type="button"
                            color="primary"
                            size="sm"
                            className="btn-rounded waves-effect waves-light"
                          >
                            {props.t("View Details")}
                          </Button>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          )}
          {displayList.length === 0 && (
            <p className="text-center mt-4">No form entries to display !!</p>
          )}
        </div>
      )}
      {isLoading && (
        <Row>
          <Col xs="12">
            <div className="text-center my-3">
              <Link to="#" className="text-success">
                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                Loading Forms...{" "}
              </Link>
            </div>
          </Col>
        </Row>
      )}
    </React.Fragment>
  );
};

export default withNamespaces()(FormTab);

