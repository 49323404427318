import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  CardTitle,
  Collapse,
  Button,
  Modal,
  Media,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Nav,
  Progress,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Alert
} from "reactstrap";
import toastr from "toastr";
import * as Constants from "../../Constents";
import ReactApexChart from "react-apexcharts";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from "moment";
import { Parse } from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import KidPicker from "../ProgramDetails/KidPicker";

import {
  setSelecetdSchool,
  getUserTeacher,
  getClassroomsForSchoolId,
  getGroupMapsForGroupId,
  getAllKidsForGroup,
  updateResultGroupMaps,
  updateTrips,
  saveGroupMaps,
  getParentsForFamilyIds
} from "../../store/actions";
import classnames from "classnames";


const TripView = (props) => {

  const matchTripView = matchPath(props.history.location.pathname, {
    path: "/trip-view/:schoolId/:tripId/:groupId",
    exact: true,
    strict: false,
  });


  const schoolId = matchTripView && matchTripView.params.schoolId;
  const tripId = matchTripView && matchTripView.params.tripId;
  const groupId = matchTripView && matchTripView.params.groupId;

  const [kidPickerModal, setKidPickerModal] = useState(false);
  const [selectedKidListForPicker, setSelectedKidListForPicker] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tripName, setTripName] = useState("");
  const [tripObject, setTripObject] = useState();
  const [mainList, setMainList] = useState([]);
  const [isTeacherPermission, setIsTeacherPermission] = useState(true);
  const [selectedKidsFromPickerView, setSelectedKidsFromPickerView] = useState([]);
  const [groupObject, setGroupObject] = useState();
  const [selectedTeachers, setSelectedTeachers] = useState([]);

  useEffect(() => {
    clearProps();

    if (matchTripView && schoolId && tripId && groupId) {
      if (groupId && groupId.length > 0) {
        getGroupObject()
      }
      if (tripId && tripId.length > 0) {
        getTripObject()
      }
      props.getAllKidsForGroup(schoolId);
      props.getGroupMapsForGroupId(groupId);
      if (Parse.User.current().attributes.teacherId) {
        props.getUserTeacher(Parse.User.current().attributes.teacherId)
      }
      props.getClassroomsForSchoolId(schoolId);

    }
  }, [schoolId, tripId]);


  // useEffect(() => {
  //   if (props.userTeacher && props.userTeacher.attributes.featurePermissions && props.userTeacher.attributes.featurePermissions.length > Constants.TEACHER_TRANSPORT_INDEX && props.userTeacher.attributes.featurePermissions[Constants.TEACHER_TRANSPORT_INDEX] === 2) {
  //     setIsTeacherPermission(true)
  //   } else {
  //     setIsTeacherPermission(false)
  //   }
  // }, [props.userTeacher])


  const getGroupObject = () => {
    var query = new Parse.Query('Group');
    query.equalTo('objectId', groupId);
    query.limit(1000);
    query.find().then((results) => {
      if (results && results.length > 0) {
        setGroupObject(results[0])
      }
    })
  }
  const getTripObject = () => {
    var query = new Parse.Query('Trip');
    query.equalTo('objectId', tripId);
    query.limit(1000);
    query.find().then((results) => {
      if (results && results.length > 0) {
        setTripObject(results[0])
        setTripName(results[0].attributes.name)
      }
    })
  }


  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool]);

  useEffect(() => {
    if (props.groupmaps && props.groupmaps.length > 0 && props.kids && props.kids.length > 0) {
      let familyIds = []


      for (var i = 0; i < props.groupmaps.length; i++) {
        if (props.groupmaps[i].attributes.groupId === groupId) {
          let kidObjList = props.kids.filter((kid) =>
            kid.id === props.groupmaps[i].attributes.userId
          )
          if (kidObjList.length > 0) {
            if (kidObjList[0].attributes.FamilyID && kidObjList[0].attributes.FamilyID.length > 0) {
              familyIds.push(kidObjList[0].attributes.FamilyID)
            }
          }
        }
      }
      if (familyIds.length > 0) {
        props.getParentsForFamilyIds(familyIds);
      }
    }
  }, [props.groupmaps, props.kids])

  useEffect(() => {
    if (props.groupmaps && props.groupmaps.length > 0 && props.kids) {
      let tempList = []
      let tempSelectedKidList = []

      let tempKidList = []
      let familyIds = []

      tempKidList.push.apply(tempKidList, props.kids)
      for (var i = 0; i < props.groupmaps.length; i++) {
        if (props.groupmaps[i].attributes.groupId === groupId) {
          let kidObjList = tempKidList.filter((kid) =>
            kid.id === props.groupmaps[i].attributes.userId
          )
          if (kidObjList.length > 0) {
            let tempObject = {}
            tempObject.kidName = kidObjList[0].attributes.Name
            tempObject.kidId = kidObjList[0].id
            tempObject.kidImage = kidObjList[0].attributes.Photo ? kidObjList[0].attributes.Photo._url : undefined
            tempObject.kidParseObject = kidObjList[0]
            tempObject.groupmapId = props.groupmaps[i].id
            if (kidObjList[0].attributes.FamilyID && kidObjList[0].attributes.FamilyID.length > 0) {
              familyIds.push(kidObjList[0].attributes.FamilyID)
            }


            let classDec = []
            for (let i in props.classrooms) {
              classDec[props.classrooms[i].id] = props.classrooms[i]
            }
            let primaryClass = classDec[kidObjList[0].attributes.ClassRoomID]
            tempObject.className = primaryClass ? primaryClass.attributes.Name : ""

            tempList.push(tempObject)
            tempSelectedKidList.push(kidObjList[0])
          }
        }
      }
      setMainList(tempList)
      setSelectedKidListForPicker(tempSelectedKidList)
    }
  }, [props.groupmaps, props.kids, props.classrooms]);

  useEffect(() => {
    if (props.parents) {
      let tempList = [...mainList]

      for (const tempObject of tempList) {
        let kidObjList = [tempObject.kidParseObject]
        for (var i = 0; i < props.parents.length; i++) {
          if (
            props.parents[i].attributes.FamilyID ===
            kidObjList[0].attributes.FamilyID
          ) {
            if (props.parents[i].attributes.Relation &&
              props.parents[i].attributes.Relation.toLowerCase() ===
              "father"
            ) {
              tempObject.fatherName =
                props.parents[i].attributes.Name;
              tempObject.fatherMobile =
                props.parents[i].attributes.PhoneNumber;
            } else {
              tempObject.motherName =
                props.parents[i].attributes.Name;
              tempObject.motherMobile =
                props.parents[i].attributes.PhoneNumber;
            }
          }
        }
      }
      setMainList(tempList)







    }
  }, [props.parents])





  function getTimeStringForDate(date) {
    return Moment(date).format("MMM Do YY");
  }


  const handleDeleteTrip = () => {
    if (tripObject != null) {
      tripObject.set("isDeleted", true)
      setIsLoading(true)
      tripObject.save().then(
        (result) => {

          if (groupObject != null) {
            groupObject.set("deleted", true)
            groupObject.save()
          }

          props.updateTrips([result])
          clearProps()
          setShowDeleteAlert(false)
          setIsLoading(false)
          window.history.back()
        },
        (error) => {
          setIsLoading(false)
          setShowDeleteAlert(false)
          toastr.warning("Error, please try again")
        }
      );
    }
  }




  const handleSaveButtonPressedForAddStudent = (selectedKids) => {
    if (groupObject && tripObject) {

      if (
        groupObject.attributes.participantsCount !== selectedKids
      ) {
        groupObject.set("participantsCount", selectedKids.length);
        groupObject.save()
      }
      if (
        tripObject.attributes.participantsCount !== selectedKids
      ) {
        tripObject.set("participantsCount", selectedKids.length);
        tripObject.save()
      }
      handleEditSaveGroupMaps(groupObject, selectedKids);
    } else {
      //Show toast Please try again.
      toastr.warning("Please refresh, and try again")
    }
  };

  const handleEditSaveGroupMaps = (resultGroup, selectedKids) => {
    const GroupMapObject = Parse.Object.extend("GroupMap");

    var groupMapTeacherIds = [];
    var groupMapKidIds = [];
    var groupMapsToDelete = [];

    for (var l = 0; l < props.groupmaps.length; l++) {
      if (props.groupmaps[l].attributes.userType === 2) {
        groupMapTeacherIds.push(props.groupmaps[l].attributes.userId);
      } else if (props.groupmaps[l].attributes.userType === 1) {
        groupMapKidIds.push(props.groupmaps[l].attributes.userId);
      }
    }

    for (var i = 0; i < props.groupmaps.length; i++) {
      var shouldDelete = true;
      if (props.groupmaps[i].attributes.userType === 2) {
        for (var j = 0; j < selectedTeachers.length; j++) {
          if (props.groupmaps[i].attributes.userId === selectedTeachers[j].id) {
            shouldDelete = false;
          }
        }
      } else if (props.groupmaps[i].attributes.userType === 1) {
        for (var j = 0; j < selectedKids.length; j++) {
          if (props.groupmaps[i].attributes.userId === selectedKids[j].id) {
            shouldDelete = false;
          }
        }
      }
      if (shouldDelete) {
        groupMapsToDelete.push(props.groupmaps[i]);
      }
    }

    var teacherObjectsToSave = selectedTeachers.filter(
      (selectedTeacher) => !groupMapTeacherIds.includes(selectedTeacher.id)
    );

    var kidObjectsToSave = selectedKids.filter(
      (selectedKid) => !groupMapKidIds.includes(selectedKid.id)
    );

    var groupMapObjectList = [];

    if (teacherObjectsToSave.length > 0) {
      var i = 0;
      for (i = 0; i < teacherObjectsToSave.length; i++) {
        var groupMapObject = new GroupMapObject();
        groupMapObject.set("groupId", resultGroup.id);
        groupMapObject.set("userId", teacherObjectsToSave[i].id);
        groupMapObject.set("userType", 2);
        groupMapObjectList.push(groupMapObject);
      }
    }
    if (kidObjectsToSave.length > 0) {


      var j = 0;
      for (j = 0; j < kidObjectsToSave.length; j++) {
        var groupMapObject = new GroupMapObject();
        groupMapObject.set("groupId", resultGroup.id);
        groupMapObject.set("userId", kidObjectsToSave[j].id);
        groupMapObject.set("userType", 1);
        groupMapObjectList.push(groupMapObject);
      }
    }
    if (groupMapsToDelete.length > 0) {
      var i = 0;
      for (i = 0; i < groupMapsToDelete.length; i++) {
        var tempObject = groupMapsToDelete[i];
        tempObject.set("isDeleted", true);
        groupMapObjectList.push(tempObject);
      }
    }


    if (groupMapObjectList.length > 0) {
      props.saveGroupMaps(groupMapObjectList);
    } else {
      setKidPickerModal(false)

    }
  };

  const closeAddStudentModal = () => {
    setKidPickerModal(false)
  }

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }


  const getTableView = (listToDisplay) => {
    return (<div className="table-responsive mt-4" >
      <table className="table table-centered table-nowrap mb-0">
        <thead className="thead-light">
          <tr>
            <th>{props.t("Student's Name")}</th>
            <th>{props.t("Father's Name")}</th>
            <th>{props.t("Father's Contact")}</th>
            <th>{props.t("Mother's Name")}</th>

            <th>{props.t("Mother's Contact")}</th>
          </tr>
        </thead>
        <tbody>
          {listToDisplay && listToDisplay.map((detail, key) => (
            <tr key={key}>
              <td>
                <h5 className="font-size-14 mb-1">
                  <Label className="text-dark">
                    {" "}
                    {detail.kidName}
                  </Label>
                </h5>
              </td>
              <td>
                <h5 className="font-size-14 mb-1">
                  <Label className="text-dark">
                    {" "}
                    {detail.fatherName}
                  </Label>
                </h5>
              </td>
              <td>
                <h5 className="font-size-14 mb-1">
                  <Label className="text-dark">
                    {" "}
                    {detail.fatherMobile}
                  </Label>
                </h5>
              </td>
              <td>
                <h5 className="font-size-14 mb-1">
                  <Label className="text-dark">
                    {" "}
                    {detail.motherName}
                  </Label>
                </h5>
              </td>
              <td>
                <h5 className="font-size-14 mb-1">
                  <Label className="text-dark">
                    {" "}
                    {detail.motherMobile}
                  </Label>
                </h5>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div >
    )
  }


  function clearProps() {
    props.updateResultGroupMaps(null);
  }



  const next = "NEXT";
  const previous = "PREVIOUS";

  return (
    <React.Fragment>


      <Modal isOpen={kidPickerModal} className={"modal-dialog modal-xl"} scrollable={true} backdrop={true}>
        <ModalHeader toggle={() => {

          closeAddStudentModal()

        }}>
        </ModalHeader>
        <ModalBody>
          <div>

            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
              <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                {<NavItem>
                  <NavLink
                    className={classnames({
                      active: true,
                    })}
                    onClick={() => {
                      //addStudentPopupToggleTab(0, previous);
                    }}
                  >
                    <span className="step-number mr-2">01</span>
                    Select Students
                  </NavLink>
                </NavItem>}

              </ul>

              <TabContent
                activeTab={0}
                className="twitter-bs-wizard-tab-content"
              >
                <TabPane tabId={0} id="pick-student">
                  <KidPicker
                    schoolId={schoolId}
                    selectedKids={selectedKidListForPicker}
                    headerForAlreadyIn={"Already in Trip"}
                    onButtonClick={(selectedKids) => {
                      if (selectedKids && selectedKids.length > 0) {
                        setSelectedKidsFromPickerView(selectedKids)
                        handleSaveButtonPressedForAddStudent(selectedKids)
                        closeAddStudentModal()
                      } else {
                        toastr.warning("Please select atleast one student to proceed")
                      }

                    }}>
                  </KidPicker>
                </TabPane>

              </TabContent>


            </div>
          </div>
        </ModalBody>

      </Modal>

      {
        showDeleteAlert && <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setShowDeleteAlert(false);
          }}
          onConfirm={() => handleDeleteTrip()}
        >
          Are you sure to delete this trip ?<br></br>
        </SweetAlert>
      }


      <div className="page-content">
        {!(isLoading || props.loadingGroupMap) && (
          <Container fluid>
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=' m-2'>
                  <h4>{tripName}</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                {tripObject && isTeacherPermission && <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                  {<button
                    className="btn btn-primary waves-effect waves-light m-1"
                    onClick={() => {
                      //openStudentPicker
                      //resetAddStudentPopup()
                      setKidPickerModal(true)
                    }}
                  >
                    Add Students
                  </button>}
                  <Link
                    className="btn btn-warning waves-effect waves-light m-1"
                    to={
                      "/tripAdd/" + schoolId + "/" + tripId + "/" + groupId
                    }
                    role="button"
                  >
                    Edit
                  </Link>

                  {<button
                    onClick={() => {
                      setShowDeleteAlert(true)
                    }}
                    className="btn btn-danger waves-effect waves-light m-1"
                  >
                    {props.t("Delete")}
                  </button>}
                </div>}
              </Col>
            </Row>

            <Card>
              <CardBody>
                {mainList && mainList.length > 0 ? getTableView(mainList) :

                  <div className="text-center mt-0">

                    <h5 className="p-5">No Students in this trip !!</h5>

                  </div>}
              </CardBody>
            </Card>
          </Container>
        )}
        {(isLoading || props.loadingGroupMap) && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading trips details...{" "}
                </Label>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment >
  );
};

const mapStatetoProps = (state) => {
  const { kids } = state.Kid;
  const { classrooms } = state.Classroom;
  const { groupmaps, loadingGroupMap, resultGroupMapsAfterSave } = state.GroupMap;
  const { selectedSchool } = state.School;
  const { userTeacher } = state.Login;
  const { parents } = state.Parent;




  return {
    kids,
    classrooms,
    groupmaps, loadingGroupMap, resultGroupMapsAfterSave,
    selectedSchool,
    userTeacher,
    parents
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getUserTeacher,
      getClassroomsForSchoolId,
      getGroupMapsForGroupId,
      getAllKidsForGroup,
      updateResultGroupMaps,
      updateTrips,
      saveGroupMaps,
      getParentsForFamilyIds
    })(TripView)
  )
);
