import React, { useEffect, useState } from "react";
import Iframe from 'react-iframe';
import { Card, CardBody, CardTitle, Badge, Row, Col, Container, UncontrolledTooltip, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, CardHeader, CardText, Label, Table, Form, FormGroup } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Parse from "parse";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import { setSelecetdSchool } from "../../store/actions";
import toastr from "toastr";
import Moment from 'moment';
import SimpleBar from "simplebar-react";
import Image from "../../assets/images/undraw_new_notifications_re_xpcv.svg";
import { Button, CardSubtitle, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap/lib";


const JobList = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/job-list/:schoolId",
        exact: true,
        strict: false,
    });


    const schoolId = match.params.schoolId;

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const [jobList, setJobList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [jobApplicationList, setJobApplicationList] = useState([]);
    const [subscriptionPopup, setSubscriptionPopup] = useState(false);
    const [openDropDown, setOpenDropDown] = useState(false);
    const [phone, setPhone] = useState();

    useEffect(() => {
        setIsLoading(true)
        var JobNotifications = Parse.Object.extend("JobNotifications");
        var query = new Parse.Query(JobNotifications);
        query.equalTo("schoolId", schoolId);
        query.descending("createdAt");
        query.notEqualTo("isDeleted", true);
        query.find().then(
            (results) => {
                console.log(results)
                setJobList(results);
                setIsLoading(false)
            },
            (error) => {

            }
        );
    }, [schoolId])

    useEffect(() => {
        setIsLoading(true)
        console.log(schoolId)
        var JobApplications = Parse.Object.extend("JobApplications");
        var query = new Parse.Query(JobApplications);
        query.equalTo("schoolId", schoolId);
        query.descending("createdAt");
        query.notEqualTo("isDeleted", true);
        query.limit(10)
        query.find().then(
            (results) => {
                console.log(results)
                setJobApplicationList(results);
                setIsLoading(false)
            },
            (error) => {

            }
        );
    }, [schoolId])

    function getTimeStringForDate(date) {
        return Moment(date).format("MMM Do YY");
    }


    const getReviewStatus = (status) => {
        if (status == '0') {
            return "Under Review"
        } else if (status == '-1') {
            return " Review Failed"
        } else if (status == '1') {
            return " Review Success"
        }

    }

    const getBadgeColor = (status) => {
        if (status == '0') {
            return "warning"
        } else if (status == '-1') {
            return "danger"
        } else if (status == '1') {
            return "success"
        }

    }

    function getTimeStringForDate1(date, format = "DD-MMM-YYYY hh:mm a") {
        return Moment(date).format(format);
    }

    const getJobNameForId = (jobId) => {
        if (jobList && jobList.length > 0) {
            let temp = jobList.filter(
                (job) => job.id === jobId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.designation + " " ?? "-")
            }
        }
    }

    const handleSendEmail = (event) => {
        const subject = encodeURIComponent('Hello');
        const body = encodeURIComponent(copyJobLink(event, true));
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
    }

    const handleNewWhatsapp = () => {
        const formattedNumber = phone.replace('+91', '').slice(-10);
        const whatsappUrl = `https://api.whatsapp.com/send?phone=+91${formattedNumber}&text=${encodeURIComponent(copyJobLink(null, true))}`;
        window.open(whatsappUrl);
    };

    const copyJobLink = (jobId, onlyLink = false) => {
        const schoolName = props.selectedSchool?.attributes?.Name || 'Your School';
        const jobLink = `https://liltriangle.com/liltriangle-job-detail/${jobId}`;
        const allJobLink = `https://liltriangle.com/liltriangle-jobs/`;
        const str = `Dear Candidate,\n\nPlease submit your job Application by clicking on the below link.\n${jobLink}\n\nFor further jobs, click the link below.\n${allJobLink}\n\nRegards,\n${schoolName}.\n\nPowered by lilTriangle`;

        if (onlyLink) {
            return str;
        }

        document.oncopy = function (event) {
            event.clipboardData.setData('Text', str);
            event.preventDefault();
        };
        document.execCommand('Copy');
        document.oncopy = undefined;
        toastr.success(props.t('Link copied to clipboard'), { timeOut: 1000 });
    };


    return (

        <React.Fragment>

            <div className="page-content">
                {/* <Topbar
                    title={"Job Portal"}
                    buttonName={"Create Job"}
                    hideButton={false}
                    link={jobList.length >= 3 ? `/new-job/${schoolId}` : ""}
                /> */}
                <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className=" list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className=' m-2'>
                            <h4 >{"Job Portal"}</h4>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                            {jobList.length >= 3 ? (
                                <div className="d-flex">
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn waves-effect waves-light mb-2"
                                        onClick={() => setSubscriptionPopup(true)}
                                    >
                                        Create Job
                                    </Button>
                                </div>
                            ) : (
                                <Link to={`/new-job/${schoolId}`}>
                                    <div className="d-flex">
                                        <Button
                                            type="button"
                                            color="primary"
                                            className="btn waves-effect waves-light mb-2"
                                        >
                                            Create Job
                                        </Button>
                                    </div>
                                </Link>
                            )}

                        </div>
                    </Col>
                </Row>
                <Container>
                    <Modal isOpen={subscriptionPopup}>
                        <ModalHeader toggle={() => {
                            setSubscriptionPopup(!subscriptionPopup)
                        }}>
                            Alert!!
                        </ModalHeader>
                        <ModalBody>
                            You can publish a maximum of three job postings. For additional notifications, please contact lilTriangle support at <a href="mailto:hello@liltriangle.com">hello@liltriangle.com</a> or call us at <b>7676548853</b> to inquire about premium subscription options.
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                type="button"
                                color="secondary"
                                className="btn waves-effect waves-light mb-2"
                                onClick={() => setSubscriptionPopup(false)}
                            >
                                Close
                            </Button>
                        </ModalFooter>
                    </Modal>
                    <Row>

                        {isLoading && (
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                        Loading....{" "}
                                    </Link>
                                </div>
                            </Col>
                        )}
                        {jobList.length === 0 && !isLoading && <Card className='text-center'>
                            <CardBody>
                                <Row className="justify-content-center py-2">
                                    <Col lg={6}>
                                        <div className="maintenance-img">
                                            <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                                        </div>
                                    </Col>
                                    <Col lg={6}>
                                        <p className="display-6 ">
                                            Publish your requirements, and the lilTriangle job portal platform will connect you with the educator community to fulfill your needs.
                                        </p>
                                        <Link
                                            className="btn btn-primary waves-effect waves-light"
                                            to={`/new-job/${schoolId}`}
                                            role="button"
                                            style={{ textDecoration: 'none' }}
                                        >
                                            Create Job Post
                                        </Link>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                        }
                        <Col lg={7}>
                            <Row>
                                {jobList.map((job, key) => (
                                    <Col key={key} xl="6" sm="12">
                                        <Col>
                                            <Card className="border-0 rounded-lg shadow-sm bg-white sticky-bar">
                                                <CardBody>
                                                    <div className="mt-2">
                                                        <Row>
                                                            <Col className="d-flex justify-content-start">
                                                                <h4 className="title mb-3">{job.attributes.designation}</h4>
                                                            </Col>
                                                            <Col className="d-flex justify-content-end">
                                                                {job.attributes.isActive && job.attributes.reviewStatus == '1' && (
                                                                    <Dropdown
                                                                        id={`tooltip-share-${key}`}
                                                                        isOpen={openDropDown === key}
                                                                        toggle={(e) => {
                                                                            if (['wa-phoneNumber', 'wa-newWhatsApp', 'wa-submitButton'].includes(e.target.id)) {
                                                                                return;
                                                                            }
                                                                            setOpenDropDown(prevState => (prevState === key ? null : key));
                                                                        }}
                                                                        direction="right"
                                                                    >
                                                                        <DropdownToggle tag="button" className="btn btn-success mr-2" style={{ marginLeft: '10px' }}>
                                                                            <i className="bx bx-share-alt"></i>
                                                                        </DropdownToggle>
                                                                        <UncontrolledTooltip placement="top" target={`tooltip-share-${key}`}>
                                                                            Share
                                                                        </UncontrolledTooltip>
                                                                        <DropdownMenu>
                                                                            <DropdownItem style={{ width: '250px' }} onClick={(event) => copyJobLink(event, job.id)}>
                                                                                Copy link <i className="bx bx-copy float-right"></i>
                                                                            </DropdownItem>
                                                                            <div className='dropdown-divider'></div>
                                                                            <DropdownItem style={{ width: '250px' }} onClick={(e) => handleSendEmail(e)}>
                                                                                Send link via Email<i className="bx bx-mail-send float-right"></i>
                                                                            </DropdownItem>
                                                                            <div className='dropdown-divider'></div>
                                                                            <DropdownItem id="wa-newWhatsApp" style={{ width: '250px', margin: '10px' }}>
                                                                                Send WhatsApp <i className="bx bxl-whatsapp float-right"></i>
                                                                                <Form onSubmit={handleNewWhatsapp} style={{ width: '200px' }}>
                                                                                    <FormGroup>
                                                                                        <Input
                                                                                            id="wa-phoneNumber"
                                                                                            style={{ margin: '10px' }}
                                                                                            type="tel"
                                                                                            placeholder="10 digit Phone Number"
                                                                                            className='form-control'
                                                                                            onChange={(e) => {
                                                                                                setPhone(e.target.value)
                                                                                            }}
                                                                                        />
                                                                                    </FormGroup>
                                                                                    <Button
                                                                                        color="primary"
                                                                                        id="wa-submitButton"
                                                                                        onClick={(e) => {
                                                                                            e.preventDefault();
                                                                                            console.log(phone);
                                                                                            if (phone) {
                                                                                                handleNewWhatsapp();
                                                                                                setOpenDropDown(null);
                                                                                            }
                                                                                        }}
                                                                                        className='btn-sm mb-2 mr-2'
                                                                                    >
                                                                                        Send
                                                                                    </Button>
                                                                                </Form>
                                                                            </DropdownItem>
                                                                        </DropdownMenu>
                                                                    </Dropdown>
                                                                )}
                                                            </Col>
                                                        </Row>
                                                        <p className="mb-1"><b>Location:</b> {`${job.attributes.area}, ${job.attributes.location}`}</p>
                                                        <p className="mb-1"><b>Experience:</b> {job.attributes.experience} {job.attributes.experience !== "fresher" && "Years"}</p>
                                                        <div className="py-1 border-top">
                                                            <ul className="list-inline mb-0">
                                                                <li className="list-inline-item mr-3" id={"startDate" + key}>
                                                                    <i className="bx bx-calendar mr-1"></i> {getTimeStringForDate(job.attributes.startDate)}
                                                                    <UncontrolledTooltip placement="top" target={"startDate" + key}>
                                                                        Start Date
                                                                    </UncontrolledTooltip>
                                                                </li>
                                                                <li className="list-inline-item" id={"endDate" + key}>
                                                                    <i className="bx bx-calendar mr-1"></i> {getTimeStringForDate(job.attributes.endDate)}
                                                                    <UncontrolledTooltip placement="top" target={"endDate" + key}>
                                                                        End Date
                                                                    </UncontrolledTooltip>
                                                                </li>
                                                            </ul>
                                                        </div>
                                                        <Row>
                                                            <Col xs={4}>
                                                                {job.attributes.reviewStatus !== "1" ? (
                                                                    <div>
                                                                        <Badge className="badge badge-md m-1" color={getBadgeColor(job.attributes.reviewStatus)}>
                                                                            {getReviewStatus(job.attributes.reviewStatus)}
                                                                        </Badge>
                                                                    </div>
                                                                ) : (
                                                                    <div>
                                                                        <Badge className="badge badge-md m-1" color={job.attributes.reviewStatus === true ? "success" : "info"}>
                                                                            {job.attributes.reviewStatus === true ? "Published" : "Not Published"}
                                                                        </Badge>
                                                                    </div>
                                                                )}
                                                            </Col>
                                                            <Col xs={8} className="d-flex justify-content-end float-end">
                                                                <Link to={`/job-applications/${schoolId}/${job.id}`} className='float-end m-1'>
                                                                    <u>View Applications</u>
                                                                </Link>
                                                                <Link to={`/edit-job/${schoolId}/${job.id}`} className='float-end m-1'>
                                                                    <span className="border rounded px-2">
                                                                        <li className="list-inline-item" style={{ cursor: "pointer" }} id={"job-edit" + key}>
                                                                            <i className="bx bx-edit-alt"></i>
                                                                            <UncontrolledTooltip placement="top" target={"job-edit" + key}>
                                                                                Edit
                                                                            </UncontrolledTooltip>
                                                                        </li>
                                                                    </span>
                                                                </Link>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    </Col>
                                ))}
                            </Row>
                        </Col >
                        <Col lg={5}>
                            {jobApplicationList.length > 0 && <Row>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={8}>
                                                <CardTitle><h4>Recent Applications</h4></CardTitle>
                                            </Col>
                                            <Col lg={4} className="float-end">
                                                <Link to={`/job-applications-all/${schoolId}`} className='btn btn-primary float-end' role='button'>
                                                    <li className="list-inline-item" style={{ cursor: "pointer" }} id={"job-app"}>
                                                        All
                                                        <UncontrolledTooltip placement="top" target={"job-app"}>
                                                            All Applications
                                                        </UncontrolledTooltip>
                                                    </li>
                                                </Link>
                                            </Col>
                                        </Row>
                                        <div className="mt-2">
                                            <SimpleBar style={{ maxHeight: "400px" }}>

                                                <div className="table-responsive">
                                                    <Table className="table table-nowrap table-centered table-hover mb-0">
                                                        <tbody>
                                                            {jobApplicationList.map((application, index) => (
                                                                <tr key={index}>
                                                                    <Card className="shadow-sm bg-light">
                                                                        <Row>
                                                                            <Col lg={8}>
                                                                                <div className="p-0">
                                                                                    <h5 className="text-truncate font-size-15 mb-1"><Link to="#" className="text-dark">{application.attributes.name}</Link></h5>
                                                                                    <p className=" mb-0"><b>{getJobNameForId(application.attributes.jobId)}</b></p>
                                                                                    <p className="text-muted mb-0"><i className="bx bx-envelope" />{application.attributes.emailId}</p>
                                                                                    <p className="text-muted mb-0"><i className="bx bx-mobile" />{application.attributes.phoneNumber}</p>
                                                                                </div>
                                                                            </Col>
                                                                            <Col xs={4} className="float-end">
                                                                                <div className="float-end">
                                                                                    <p> {getTimeStringForDate1(application.attributes.createdAt, "DD-MMM-YYYY")} <br /> {getTimeStringForDate1(application.attributes.createdAt, "hh:mm a")} </p>
                                                                                    <a href={application.attributes.resume.url()} target="_blank" rel="noopener noreferrer">
                                                                                        <u> View Resume</u>
                                                                                    </a>
                                                                                </div>
                                                                            </Col>
                                                                        </Row>
                                                                    </Card>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </SimpleBar>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Row>}
                        </Col>
                    </Row >
                </Container >
            </div >
        </React.Fragment >
    )
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool })(JobList)));
