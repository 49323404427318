import React, { useState, useEffect } from 'react'
import {
  Container, Row, Col, Table, Badge, Label, Progress,
  Dropdown, DropdownMenu, DropdownItem, DropdownToggle,
  Card, CardBody, CardTitle, CardText,
  Media,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Nav, NavItem, NavLink, TabContent, TabPane,
  UncontrolledTooltip,
} from "reactstrap";
import classnames from "classnames";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import Editable from "react-bootstrap-editable";
import Lightbox from 'react-image-lightbox';
import SweetAlert from 'react-bootstrap-sweetalert';
import Switch from "react-switch";
import Moment from "moment";
import { v4 as uuidv4 } from "uuid";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool, setSelectedTicket, setSelectedTicketLog, editTicket, getTeachersForSchool, getTeacher, getFilesForFolderId, AddFile, clearFiles, deleteFile, addTicketLog, deleteTicketLog, getFranchiseTeachers } from '../../store/actions';

function TicketDetails(props) {

  const imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];

  const match = matchPath(props.history.location.pathname, {
    path: "/ticket-details/:schoolId/:ticketId",
    exact: true,
    strict: false,
  });

  const franchiseMatch = matchPath(props.history.location.pathname, {
    path: "/franchise-ticket-details/:schoolId/:ticketId",
    exact: true,
    strict: false,
  });

  const tempMatch = match ?? franchiseMatch;
  const schoolId = tempMatch && tempMatch.params.schoolId;
  const ticketId = tempMatch && tempMatch.params.ticketId;
  const franchiseId = franchiseMatch && franchiseMatch.params.franchiseMatch;

  const [toggleId, setToggleId] = useState(false);
  const [timeLine, setTimeLine] = useState([]);
  const [ticketName, setTicketName] = useState();
  const [ticketDescription, setTicketDescription] = useState();
  const [imagePopup, setImagePopup] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  const [getImage, setGetImage] = useState([]);
  const [attachment, setAttachment] = useState();
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState(0);
  const [deleteObject, setDeleteObject] = useState(undefined);
  const [showDelete, setShowDelete] = useState(false);
  const [fileUploadLoading, setFileUploadLoading] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const [noteModelTitle, setNoteModelTitle] = useState(undefined);
  const [noteModelMessage, setNoteModelMessage] = useState(undefined);
  const [showNoteModel, setShowNoteModel] = useState(false);
  const [selectedNoteForEdit, setSelectedNoteForEdit] = useState(undefined);
  const [selectedNoteForDelete, setSelectedNoteForDelete] = useState(undefined);

  const [activeTab, setactiveTab] = useState("1");
  const [notes, setNotes] = useState([]);

  const [success_dlg, setsuccess_dlg] = useState(false);

  const [teacherToggleId, setTeacherToggleId] = useState(false);

  const [showToRecipent, setShowToRecipent] = useState(false);


  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    if (ticketId) {
      props.clearFiles()
      props.setSelectedTicket(ticketId);
      props.setSelectedTicketLog(ticketId);
    }
  }, [ticketId]);

  useEffect(() => {
    if (ticketId) {
      props.getFranchiseTeachers()
    }
  }, [franchiseMatch]);


  useEffect(() => {
    if (props.selectedTicket && props.selectedTicket.id == ticketId && props.selectedTicket.attributes.folderId) {
      props.getFilesForFolderId(props.selectedTicket.attributes.folderId);
    }
  }, [props.selectedTicket]);


  useEffect(() => {
    if (Parse.User.current()) {
      props.getTeacher(Parse.User.current().attributes.teacherId)
    }
  }, [schoolId]);

  useEffect(() => {
    if (props.selectedTicket && props.selectedTicket.id == ticketId) {
      setTicketName(props.selectedTicket.attributes.title);
      setTicketDescription(props.selectedTicket.attributes.description);
    }

  }, [props.selectedTicket])



  const getTeacherName = (teacherId) => {
    if (props.teachers && props.teachers.length > 0) {
      let temp = props.teachers.filter(
        (teacher) => teacher.id === teacherId
      );
      if (temp.length > 0) {
        return (temp[0].attributes.Name ?? "-")
      }
    }
  }

  const getSchoolName = (schoolId) => {
    if (props.schools && props.schools.length > 0) {
      let temp = props.schools.filter(
        (school) => school.id === schoolId
      );
      if (temp.length > 0) {
        return (temp[0].attributes.Name ?? "-")
      }
    }
  }

  useEffect(() => {
    if (props.selectedTicketLog && props.selectedTicketLog.length > 0) {
      let items = [];
      let note1 = [];
      for (const log of props.selectedTicketLog) {
        if (log.attributes.isSystemGenerated) {
          let newItem = {
            message: log.attributes.message,
            toStatus: log.attributes.toStatus,
            fromStatus: log.attributes.fromStatus,
            createdAt: log.attributes.createdAt,
            createdBy: log.attributes.createdBy,
          }
          items.push(newItem)
        } else {
          if (match) {
            if (log.attributes.showToRecipent || log.attributes.showToRecipent) {
              note1.push(log)
            }
          } else {
            note1.push(log)
          }

        }
      }
      setNotes(note1);
      setTimeLine(items)
    }

  }, [props.selectedTicketLog])

  const handleTitleChange = (value) => {
    setTicketName(value);
    var temp = props.selectedTicket;
    let fromStatus = props.selectedTicket.attributes.status;
    let fromState = getStatus(fromStatus);
    let toStatus = getstatusFromState("Open");
    let toState = "Open";
    if (fromStatus != toStatus) {
      //create ticket log and save in db
      let message = fromState + " to " + toState;
      let logObject = {
        ticketId: temp.id,
        fromStatus: fromStatus,
        toStatus: toStatus,
        message: message,
        teacherId: Parse.User.current().attributes.teacherId
      }
      temp.set("status", toStatus)
      console.log(logObject);
      //props.editTicket(temp, logObject, true);
    }
    temp.set("title", value);
    props.editTicket(temp);
  };

  const handleDescriptionChange = (value) => {
    setTicketDescription(value);
    var temp = props.selectedTicket;
    var temp = props.selectedTicket;
    let fromStatus = props.selectedTicket.attributes.status;
    let fromState = getStatus(fromStatus);
    let toStatus = getstatusFromState("Open");
    let toState = "Open";
    if (fromStatus != toStatus) {
      //create ticket log and save in db
      let message = fromState + " to " + toState;
      let logObject = {
        ticketId: temp.id,
        fromStatus: fromStatus,
        toStatus: toStatus,
        message: message,
        teacherId: Parse.User.current().attributes.teacherId
      }
      temp.set("status", toStatus)
      console.log(logObject);
      //props.editTicket(temp, logObject, true);
    }
    temp.set("description", value);
    props.editTicket(temp);
    setIsEdit(true);
  };

  useEffect(() => {
    if (props.files && props.files.length > 0) {
      let images = []
      for (const img of props.files) {
        let image = {
          imgs: img.attributes.file._url,
        }
        images.push(image)
      }
      setGetImage(images)
    }

  }, [props.files])

  const getLightBoxImagesUI = () => {

    if (props.files && props.files.length > 0) {
      let images = []
      for (const album of props.files) {
        let image = album.attributes.file._url;
        images.push(image)
      }

      return (
        <Lightbox
          mainSrc={images[photoIndex]}
          nextSrc={images[(photoIndex + 1) % images.length]}
          prevSrc={
            images[(photoIndex + images.length - 1) % images.length]
          }
          enableZoom={true}
          onCloseRequest={() => { setImagePopup(!imagePopup) }}
          onMovePrevRequest={() => { setphotoIndex((photoIndex + images.length - 1) % images.length) }}
          onMoveNextRequest={() => { setphotoIndex((photoIndex + 1) % images.length) }}
          imageCaption={"Image " + parseFloat(photoIndex + 1)}
        />
      )
    }
  }

  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };



  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }

    return "bx bxs-file-blank";
  }

  const getFileExtension = (result) => {
    if (typeof result === "object") {
      let name = result._name.split(/_(.+)/)[1];
      let fileExtension = name.split(".");
      return fileExtension[1].toLowerCase()
    } else {
      return result
    }
  }

  const getActualFileName = (result) => {
    if (typeof result === "object") {
      let name = result._name.split(/_(.+)/)[1];
      return name

    } else {
      return result
    }
  }

  const showUploadProgress = () => {
    if (attachment && attachmentUploadProgress >= 1 && attachmentUploadProgress < 100) {
      return true
    } else if (attachment && !attachment._url) {
      return true
    } else {
      return false
    }
  }

  //   const getUploadStatus = () => {

  //     if (attachment && !attachment._url) {
  //       return true
  //     }else{
  //     return false
  //   }
  // }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var resultWithName = { name: file.name, result: reader.result };
      cb(resultWithName);
    };
    reader.onerror = function (error) {
    };
  }

  function handleFileUploadChange(e, files) {
    setAttachment(undefined)
    setAttachmentUploadProgress(1)
    let fileExtension = e.target.files[0].name.split(".").pop();

    let tempFile = {
      actualFileName: e.target.files[0].name,
      fileExtension: fileExtension.toLowerCase(),
      fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
      file: e.target.files[0],
    };

    var name = tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + tempFile.fileExtension;
    getBase64(tempFile.file, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result });
      parseFile
        .save({
          progress: (value) => {
            if (value && value > 0.1) {
              setAttachmentUploadProgress(value * 100)
            }
          },
        })
        .then(
          (result) => {

            setAttachment(result)


            let folderId = props.selectedTicket.attributes.folderId ?? uuidv4();
            var fileObject = props.files;
            var Files = Parse.Object.extend("Files");
            var fileObject = new Files();
            fileObject.set("folderId", folderId);
            fileObject.set("fileType", "image");
            fileObject.set("file", result);
            fileObject.set("name", result._name);

            props.AddFile(fileObject, "Upload successfully!");
            if (!props.selectedTicket.attributes.folderId) {
              let ticket = props.selectedTicket
              ticket.set("folderId", folderId);
              props.editTicket(ticket);
            }


            setAttachmentUploadProgress(false)
            console.log(result);
          },
          (error) => {
            console.log(error)
          }
        );
    });
    setAttachment(tempFile.actualFileName)
    setFileUploadLoading(true);

  }

  const handelDeleteFile = () => {

    props.deleteFile(deleteObject)

    setShowDelete(false);
  };

  const handleNoteSubmit = () => {

    const TicketLog = Parse.Object.extend("TicketLog");
    var ticketLog = new TicketLog();

    if (selectedNoteForEdit) {
      ticketLog = selectedNoteForEdit;
    } else {
      ticketLog.set("ticketId", ticketId);
      ticketLog.set('createdBy', Parse.User.current().attributes.teacherId)
    }

    ticketLog.set("message", noteModelMessage);
    ticketLog.set("title", noteModelTitle);
    if (showToRecipent) {
      if(franchiseMatch){
        ticketLog.set("showToSchool", true);
      }
      ticketLog.set("showToRecipent", true);
    }
    props.addTicketLog(ticketLog, selectedNoteForEdit);

    setShowNoteModel(false);
    setNoteModelMessage(undefined);
    setNoteModelTitle(undefined);
    setSelectedNoteForEdit(undefined);
  }

  function getStatus(status) {

    if (status == 0) {
      return "Open";
    } else if (status == 1) {
      return "Pending";
    } else if (status == 2) {
      return "Resolved ";
    } else if (status == 3) {
      return "Closed";
    }
    return "Open"
  }

  function getstatusFromState(state) {

    if (state == "Open") {
      return 0;
    } else if (state == "Pending") {
      return 1;
    } else if (state == "Resolved") {
      return 2;
    } else if (state == "Closed") {
      return 3;
    }
    return 0
  }

  function getStatusColor(status) {

    if (status == 0) {
      return "primary";
    } else if (status == 1) {
      return "warning ";
    } else if (status == 2) {
      return "success";
    } else if (status == 3) {
      return "success";
    }
    return "primary"
  }

  function getDropdwn() {
    let ticket = props.selectedTicket;
    var currentState = getStatus(ticket.attributes.status)
    var list = [
      "Open",
      "Pending",
      "Resolved",
      "Closed",
    ];

    return (
      <Dropdown
        isOpen={toggleId}
        toggle={() => setToggleId(!toggleId)}
      >
        <DropdownToggle className="bg-white border-0" caret>


          <Badge
            className={
              "font-size-12 badge-soft-" +
              getStatusColor(ticket.attributes.status)
            }
            // color={isEdit ? getStatus(1) : currentState}

            pill
          >
            {currentState}
            {(franchiseMatch || (match && props.selectedTicket.attributes.assignedForType == "school"))&& <i className="mdi mdi-chevron-down"></i>}
          </Badge>
        </DropdownToggle>
        {(franchiseMatch || (match && props.selectedTicket.attributes.assignedForType == "school")) && <DropdownMenu>
          {list.map((item, key) => (
            <DropdownItem
              key={key}
              onClick={() => {
                let fromStatus = props.selectedTicket.attributes.status;
                let fromState = getStatus(fromStatus);
                let toStatus = getstatusFromState(item);
                let toState = item;
                if (fromStatus != toStatus) {
                  //create ticket log and save in db
                  let message = fromState + " to " + toState;
                  let logObject = {
                    ticketId: ticket.id,
                    fromStatus: fromStatus,
                    toStatus: toStatus,
                    message: message,
                    teacherId: Parse.User.current().attributes.teacherId
                  }
                  ticket.set("status", toStatus)
                  props.editTicket(ticket, logObject, true);
                }
              }}
            >
              {item}
            </DropdownItem>
          ))}
        </DropdownMenu>}
      </Dropdown>
    );
  }

  function getTimeStringForDate(date) {
    return Moment(date).format('DD-MMM-YYYY hh:mm a');
  }

  function getTeacherDropDown(teacherId) {
    let ticket = props.selectedTicket;
    var ct = props.franchiseTeachers && props.franchiseTeachers.filter((teacher) => teacherId == teacher.id)
    let currentTeacher = ct && ct[0]

    let list = [...props.franchiseTeachers]
    if (teacherId) {
      list = props.franchiseTeachers && props.franchiseTeachers.filter((teacher) => teacherId != teacher.id)
    }

    return (
      <Dropdown
        isOpen={teacherToggleId}
        toggle={() => setTeacherToggleId(!teacherToggleId)}
      >
        <DropdownToggle className="bg-white border-0" caret>


          <Badge
            className="font-size-12 badge-soft-success"
            pill
          >
            {currentTeacher ? currentTeacher.attributes.Name : "Assign To"}
            <i className="mdi mdi-chevron-down"></i>
          </Badge>
        </DropdownToggle>
        <DropdownMenu>
          {list.map((item, key) => (
            <DropdownItem
              key={key}
              onClick={() => {
                let from = props.selectedTicket.attributes.assignedTo;
                let to = item.id;
                if (from != to) {
                  //create ticket log and save in db
                  let message = "Assigned to " + item.attributes.Name;
                  let logObject = {
                    ticketId: ticket.id,
                    message: message,
                    teacherId: Parse.User.current().attributes.teacherId
                  }
                  ticket.set("assignedTo", to)
                  props.editTicket(ticket, logObject, true);
                }
              }}
            >
              {item.attributes.Name}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </Dropdown>
    );
  }

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        <small>No</small>
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 10,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        <small>Yes</small>
      </div>
    );
  };


  return (
    <React.Fragment>
      <div className='page-content'>
        {imagePopup ? (
          getLightBoxImagesUI()
        ) : null}
        {showDelete && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Delete"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            title="Alert!"
            onCancel={() => {
              setShowDelete(false);
            }}
            onConfirm={() => {
              handelDeleteFile()
            }}
          >
            {"Are you sure you want to delete"}
            <br></br>
          </SweetAlert>
        )}
        <Row className='d-flex align-items-start'>
          <Col className="d-flex align-items-start">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => { window.history.back() }}>
              <ul className=" list-unstyled">
                <div className=''>
                  <i className="bx bx-chevron-left h1 text-primary"></i>
                </div>
              </ul>
            </div>
            <div className=' m-2'>
              <h4>Ticket details (Ticket number: {props.selectedTicket && props.selectedTicket.attributes.ticketNumber})</h4>
            </div>
          </Col>
        </Row>
        <Row>
          <Col lg={8}>
            <Card>
              <CardBody>
                <Table className=" table mb-0 table-bordered">
                  <tbody>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Date</th>
                      <td>{getTimeStringForDate(props.selectedTicket && props.selectedTicket.attributes.createdAt)}</td>
                    </tr>
                    {franchiseMatch && <tr>
                      <th scope="row" style={{ width: "200px" }}>School</th>
                      <td>{getSchoolName(props.selectedTicket && props.selectedTicket.attributes.recipientId)}</td>
                    </tr>}

                    {franchiseMatch && <tr>
                      <th scope="row" style={{ width: "200px" }}>Assigned To</th>
                      <td>{getTeacherDropDown(props.selectedTicket && props.selectedTicket.attributes.assignedTo)}</td>
                    </tr>}
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Ticket title</th>
                      <td>
                        <Editable
                          className={'d-flex'}
                          alwaysEditing={false}
                          onValidated={handleTitleChange}
                          onCancel={() => {
                            props.selectedTicket && setTicketName(props.selectedTicket.attributes.title);
                          }}
                          disabled={false}
                          editText={ticketName ? " ✎" : "No value ✎"}
                          id={null}
                          isValueClickable={false}
                          mode="inline"
                          placement="top"
                          initialValue={
                            ticketName && ticketName.length > 0 ? ticketName : ""
                          }
                          showText
                          type="textfield"
                          validate={(value) => {
                            if (!value || value.length == 0) {
                              return "Please enter title"
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Ticket description</th>
                      <td>
                        <Editable
                          className={'d-flex'}
                          alwaysEditing={false}
                          onValidated={handleDescriptionChange}
                          onCancel={() => {
                            props.selectedTicket && setTicketName(props.selectedTicket.attributes.description);
                          }}
                          disabled={false}
                          editText={ticketDescription ? " ✎" : "No value ✎"}
                          id={null}
                          isValueClickable={false}
                          mode="inline"
                          placement="top"
                          initialValue={
                            ticketDescription && ticketDescription.length > 0 ? ticketDescription : ""
                          }
                          showText
                          type="textfield"
                          validate={(value) => {
                            if (!value || value.length == 0) {
                              return "Please enter ticket description"
                            }
                          }}
                        />
                      </td>
                    </tr>
                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Status</th>
                      <td>{props.selectedTicket && getDropdwn()}</td>
                    </tr>


                    <tr>
                      <th scope="row" style={{ width: "200px" }}>Images</th>
                      <td>
                        {
                          props.files.map((file, key) =>
                            <Card key={key}>
                              <CardBody>
                                <Row>
                                  <Col>
                                    <div className="popup-gallery">
                                      <div className="img-fluid float-left">
                                        <img
                                          src={file.attributes.file._url}
                                          onClick={() => {
                                            setImagePopup(true);
                                            setphotoIndex(key)
                                          }}
                                          alt=""
                                          width="120"
                                        />
                                      </div>
                                    </div>
                                  </Col>
                                  <Col>
                                    <div
                                      className="flex-fill text-center"
                                      style={{ cursor: "pointer", float: "right" }}
                                      onClick={() => {
                                        setDeleteObject(file)
                                        setShowDelete(true);
                                      }}
                                    >
                                      <i className="bx bxs-trash  text-danger"></i>
                                    </div>
                                  </Col>
                                </Row>
                              </CardBody>
                            </Card>)}
                        <br />
                        <div className="d-flex">
                          <Label
                            htmlFor={"input-file-expense"}
                            color="primary"
                            className="btn btn-primary"
                          >
                            Add image
                            <input
                              id={"input-file-expense"}
                              style={inputStyle}
                              type="file"
                              onChange={(e) => {
                                handleFileUploadChange(e);
                              }}
                              accept=".doc,.docx,.pdf,.xlsx,image/*"
                            />
                          </Label>
                        </div>
                        {/* {getUploadStatus() && (
                          <Col xl="12" md="12" sm="12" xs="12">
                            <div className="border p-3">
                              <Row>
                                <div
                                  className="avatar ml-3"
                                  style={{ width: "5rem", height: "5rem" }}
                                >
                                  <span
                                    className="avatar-title bg-white ml-0 text-primary h1"
                                    style={{ fontSize: "60px" }}
                                  >
                                    <i
                                      className={getFileTypeImage(
                                        getFileExtension(attachment)
                                      )}
                                    ></i>
                                  </span>
                                </div>

                                <div className="float-right ml-4">
                                  <div
                                    className="text-muted font-weight-bold text-truncate"
                                    style={{ width: "200px" }}
                                  >
                                    {getActualFileName(attachment)}
                                  </div>
                                  <div className="d-flex mt-3">
                                    <Button
                                      className="btn btn-danger float-right ml-2"
                                      color="danger"
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      onClick={() => {
                                        setAttachment(undefined)
                                        setAttachmentUploadProgress(0)
                                      }}
                                    >
                                      Clear
                                    </Button>
                                  </div>
                                </div>
                              </Row>
                            </div>
                          </Col>
                        )} */}
                        {
                          showUploadProgress() && (
                            <div class="d-flex">
                              <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                <div className="border p-3">
                                  <Row>
                                    <div
                                      className="avatar ml-3"
                                      style={{ width: "5rem", height: "5rem" }}
                                    >
                                      <span className="avatar-title bg-white ml-0 text-primary h1">
                                        <i
                                          className={getFileTypeImage(attachment)}
                                        ></i>
                                      </span>
                                    </div>
                                    <div
                                      className="float-right ml-4"
                                      style={{ width: "200px" }}
                                    >
                                      <div className="text-muted font-weight-bold">
                                        {attachment}
                                      </div>
                                      <div className=" mt-3">
                                        <Progress
                                          color="primary"
                                          value={attachmentUploadProgress}
                                        ></Progress>
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            </div>
                          )}
                      </td>
                    </tr>
                  </tbody>
                </Table>
              </CardBody>
            </Card>


            {props.selectedTicket && ticketId === props.selectedTicket.id && <Card>
              <CardBody>
                <Nav pills className="navtab-bg nav-justified">
                  <NavItem>
                    <NavLink
                      style={{ cursor: "pointer" }}
                      className={classnames({
                        active: activeTab === "1",
                      })}
                      onClick={() => {

                      }}
                    >
                      {props.t("Notes")}
                    </NavLink>
                  </NavItem>


                </Nav>

                <TabContent activeTab={activeTab}>
                  <TabPane tabId="1" className="p-3">

                    {(franchiseMatch || (match && props.selectedTicket && props.selectedTicket.attributes.assignedForType == "school")) && <button
                      className="btn btn-info"
                      type="button"

                      onClick={() => {
                        setShowNoteModel(true);
                        setSelectedNoteForEdit(undefined);
                        setShowToRecipent(false)
                      }}
                    >
                      {props.t('Add New Note')}
                    </button>}
                    {
                      notes && notes.length > 0 ?
                        <div>
                          {
                            notes.map(note =>

                              <Card key={note.id}>
                                <CardBody>

                                  <Row>
                                    <Col lg={9} className=''>
                                      {(franchiseMatch || (match && props.selectedTicket.attributes.assignedForType == "school")) &&<Badge className='badge badge-sm' color={(note.attributes.showToSchool || note.attributes.showToRecipient) == true ? 'success' : 'warning'}>{(note.attributes.showToSchool || note.attributes.showToRecipient) ? "public note" : "Private Note"}</Badge>}
                                      <CardTitle>{note.attributes.title ? note.attributes.title : props.t('Note')}</CardTitle>
                                    </Col>
                                    <Col lg={3}>

                                      {(franchiseMatch || (match && props.selectedTicket.attributes.assignedForType == "school")) && <ul className="list-inline font-size-18 contact-links mb-0">
                                        <li className="list-inline-item px-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setShowNoteModel(true);
                                            setSelectedNoteForEdit(note);
                                            setNoteModelTitle(note.attributes.title);
                                            setNoteModelMessage(note.attributes.message);
                                            setShowToRecipent((note.attributes.showToSchool || note.attributes.showToRecipient));
                                          }}
                                          id={"note-edit" + note.id}>
                                          <i className="bx bx-edit-alt"></i>
                                          <UncontrolledTooltip placement="top" target={"note-edit" + note.id}>
                                            {props.t('Edit')}
                                          </UncontrolledTooltip>

                                        </li>
                                        <li className="list-inline-item px-2"
                                          style={{ cursor: "pointer" }}
                                          onClick={() => {
                                            setSelectedNoteForDelete(note);
                                          }}
                                          id={"note-delete" + note.id}>
                                          <i className="bx bx-trash text-danger"></i>
                                          <UncontrolledTooltip placement="top" target={"note-delete" + note.id}>
                                            {props.t('Delete')}
                                          </UncontrolledTooltip>

                                        </li>
                                      </ul>}

                                    </Col>
                                  </Row>
                                  <CardText style={{ whiteSpace: "pre-wrap" }}>{note.attributes.message}</CardText>
                                  <CardText><small className="text-muted">{getTimeStringForDate(note.attributes.createdAt)}</small></CardText>
                                </CardBody>
                              </Card>
                            )

                          }
                        </div> :
                        <h5></h5>
                    }

                  </TabPane>


                </TabContent>
              </CardBody>
            </Card>}
          </Col>

          <Col lg={4}>
            <Card>
              <CardBody>
                <CardTitle className="mb-5">Ticket TimeLine </CardTitle>
                <div className="">
                  <ul className="verti-timeline list-unstyled">
                    {
                      timeLine.map((tLine, key) =>
                        <li id={"main" + tLine.key} key={key} className="event-list active" >
                          <div className="event-timeline-dot">
                            <i className="bx bx-right-arrow-circle"></i>
                          </div>
                          <Media>
                            <Media body>
                              <div>
                                <h5 className="font-size-15 text-dark ">{tLine.message}</h5>
                                <span>{getTeacherName(tLine.createdBy)}<br /></span>
                                <span className="text-primary">{getTimeStringForDate(tLine.createdAt)}</span>

                              </div>
                            </Media>
                          </Media>
                        </li>
                      )}
                  </ul>
                </div>
              </CardBody>
            </Card>
          </Col>

        </Row>
      </div>

      <Modal
        isOpen={showNoteModel}
        toggle={() => {
          setShowNoteModel(false);
          setNoteModelTitle(undefined);
          setNoteModelMessage(undefined);
          setSelectedNoteForEdit(undefined);
        }} className="">
        <ModalHeader toggle={() => {
          setShowNoteModel(false);
          setNoteModelMessage(undefined);
          setNoteModelTitle(undefined);
          setSelectedNoteForEdit(undefined);
        }} tag="h4">
          {selectedNoteForEdit ? "Edit Note" : "Add Note"}
        </ModalHeader>
        <ModalBody>
          <AvForm onValidSubmit={handleNoteSubmit}>
            <Row>
              <Col>
                <div>
                  <div style={{ display: "inline-block" }}>
                    <label>Show notes to recipient :</label>
                  </div>
                  <div style={{ display: "inline-block", marginLeft: "15px" }}>
                    <Switch
                      height={15}
                      width={35}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      onColor={"#6add7d"}
                      checked={showToRecipent}
                      disabled={selectedNoteForEdit}
                      onChange={() => {
                        setShowToRecipent(!showToRecipent)
                      }}
                    />
                  </div>
                </div>
              </Col>
            </Row>
            <Row form>

              <Col className="col-12">

                <AvField name="title"
                  label={props.t('Title')}
                  type="text"
                  placeholder={props.t('Title')}
                  value={noteModelTitle}
                  onChange={(e) => {
                    setNoteModelTitle(e.target.value);
                  }}

                />

              </Col>
              <Col className="col-12">
                <AvField
                  name="note"
                  label={props.t('Note')}
                  type="textarea"
                  className="form-control"
                  rows="3"
                  placeholder={props.t('your note...')}
                  errorMessage={props.t('Note can not be empty')}
                  validate={{
                    required: { value: true }
                  }}
                  value={noteModelMessage}
                  onChange={(e) => {
                    setNoteModelMessage(e.target.value);
                  }}
                />
              </Col>

            </Row>
            <Row>
              <Col className='d-flex justify-content-end'>
                <div className="text-right">
                  <button type="button" className="btn btn-light m-1" onClick={() => {
                    setShowNoteModel(false);
                    setNoteModelMessage(undefined);
                    setNoteModelTitle(undefined);
                    setSelectedNoteForEdit(undefined);
                  }}>{props.t('Close')}</button>
                  <button type="submit" className="btn btn-success save-event m-1">{props.t('Save')}</button>
                </div>
              </Col>
            </Row>
          </AvForm>
        </ModalBody>
      </Modal>

      {selectedNoteForDelete ? (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            if (selectedNoteForDelete) {
              props.deleteTicketLog(selectedNoteForDelete);
              setSelectedNoteForDelete(undefined);
              setsuccess_dlg(true);
            }

          }}
          onCancel={() => {
            setSelectedNoteForDelete(undefined);
            setsuccess_dlg(false);

          }}
        >
          {props.t("You won't be able to revert this!")}
        </SweetAlert>
      ) : null}

      {success_dlg ? (
        <SweetAlert
          success
          title={props.t('Deleted')}
          onConfirm={() => {
            setsuccess_dlg(false)
          }}
        >
          {"Note has been deleted."}
        </SweetAlert>
      ) : null}
    </React.Fragment>
  )
}
const mapStatetoProps = state => {
  const { selectedSchool, schools } = state.School;
  const { selectedTicket } = state.Ticket;
  const { selectedTicketLog } = state.Ticket;
  const { teachers, franchiseTeachers } = state.Teacher;
  const { files } = state.Files;
  return { selectedSchool, schools, selectedTicket, selectedTicketLog, teachers, files, franchiseTeachers };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, setSelectedTicket, setSelectedTicketLog, editTicket, getTeachersForSchool, getTeacher, clearFiles, getFilesForFolderId, AddFile, deleteFile, addTicketLog, deleteTicketLog, getFranchiseTeachers })(TicketDetails)));
