import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as EnquiryFollowupHelper from "../../../helpers/ParseHelpers/enquiryFollowup_helper";
import * as actionTypes from "./actionTypes";
import * as EnquiryFollowupActions from "./actions";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* deleteAEnquiryFollowup({ payload: { enquiryFollowupId } }) {
  try {
    const response = yield call(
      EnquiryFollowupHelper.deleteEnquiryFollowupFromParse,
      enquiryFollowupId
    );
    if (response) {
      yield put(EnquiryFollowupActions.updateEnquiryFollowup(response));
      yield response.unPin();

    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getEnquiryFollowupsForSchoolId({ payload: { schoolId } }) {
  try {
    const localResponse = yield call( EnquiryFollowupHelper.getEnquiryFollowupForSchoolId,schoolId,true);
    if (localResponse && localResponse.length > 0) {
      yield put(EnquiryFollowupActions.updateEnquiryFollowups(localResponse,true));
      
    }

    const serverResponse = yield call( EnquiryFollowupHelper.getEnquiryFollowupForSchoolId,schoolId,false);
  
    if (serverResponse && serverResponse.length > 0) {
      yield put(EnquiryFollowupActions.updateEnquiryFollowups(serverResponse,true));
      if (localResponse && localResponse.length > 0) {
        Parse.Object.unPinAll(localResponse)
      }
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editEnquiryFollowup({ payload: {enquiryFollowup} }) {
  try {
      const response = yield call(EnquiryFollowupHelper.editEnquiryFollowup , enquiryFollowup);
      if (response) {
        yield put(EnquiryFollowupActions.updateEnquiryFollowup(response));
        response.pin();
      } else {
          
      }
  } catch (error) {
      // yield put(apiError(error));
  }
}

function* saveEnquiryFollowup({ payload: {enquiryFollowups} }) {
  try {
     yield put(EnquiryFollowupActions.setSaveLoding(true));
      const response = yield call(EnquiryFollowupHelper.saveEnquiryFollowups , enquiryFollowups);
      if (response) {
        yield put(EnquiryFollowupActions.updateEnquiryFollowups(response,false));
        Parse.Object.pinAll(response);
        toastr.success("Followups successfully updated", { timeOut: 3000 });
      } else {
          
      }
  } catch (error) {
      // yield put(apiError(error));
  }
}



export function* watchDeleteEnquiryFollowup() {
  yield takeEvery(actionTypes.DELETE_ENQUIRY_FOLLOWUP, deleteAEnquiryFollowup);
}


export function* watchGetEnquiryFollowupsForSchoolId() {
  yield takeEvery(actionTypes.GET_ENQUIRY_FOLLOWUPS, getEnquiryFollowupsForSchoolId);
}
export function* watchEditEquiryFollowup() {
  yield takeEvery(actionTypes.EDIT_ENQUIRY_FOLLOWUP, editEnquiryFollowup);
}
export function* watchSaveEquiryFollowups() {
  yield takeEvery(actionTypes.SAVE_ENQUIRY_FOLLOWUPS, saveEnquiryFollowup);
}

function* EnquiryFollowupSaga() {
  yield all([
    fork(watchDeleteEnquiryFollowup),
    fork(watchGetEnquiryFollowupsForSchoolId),
    fork(watchEditEquiryFollowup),
    fork(watchSaveEquiryFollowups),
  ]);
}
export default EnquiryFollowupSaga;
