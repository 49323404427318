import Parse from "parse";

export const getSaleOrderForOwnerId = (ownerId, ownerType, updatedDate, isLocal,) => {
  try {
    return new Promise((resolve, reject) => {
      var InventorySaleOrder = Parse.Object.extend("InventorySaleOrders");
      var query = new Parse.Query(InventorySaleOrder);
      query.equalTo("ownerId", ownerId);
      query.equalTo("ownerType", ownerType)
  
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
  
      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getSaleOrderForBuyer = (buyerId, buyerType, updatedDate, isLocal,) => {
  try {
    return new Promise((resolve, reject) => {
      var InventorySaleOrder = Parse.Object.extend("InventorySaleOrders");
      var query = new Parse.Query(InventorySaleOrder);
      query.equalTo("buyerId", buyerId);
      query.equalTo("buyerType", buyerType)
  
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
  
      query.descending('createdAt')
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const editInventorySaleOrder = (inventorySaleOrder) => {
  try {
    return new Promise((resolve, reject) => {
      inventorySaleOrder.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

export const deleteInventorySaleOrderFromParse = (inventorySaleOrder) => {
  try {
    return new Promise((resolve, reject) => {
      inventorySaleOrder.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        inventorySaleOrder.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        inventorySaleOrder.set("deletedBy", Parse.User.current().id);
      }
  
      inventorySaleOrder.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("error");
        }
      );
    });
  } catch (error) { }
};
  
 export const getInventorySaleOrderFromLocal = (inventorySaleOrderId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventorySaleOrder = Parse.Object.extend("InventorySaleOrders");
        var query = new Parse.Query(InventorySaleOrder);
        query.fromLocalDatastore();
        query.get(InventorySaleOrder).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

export const getInventorySaleOrderFromParse = (inventorySaleOrderId) => {
  try {
    return new Promise((resolve, reject) => {
      var InventorySaleOrder = Parse.Object.extend("InventorySaleOrders");
      var query = new Parse.Query(InventorySaleOrder);
      query.get(inventorySaleOrderId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {

  }
};
  
export const getCartForOwnerId = (ownerId, ownerType, updatedDate, isLocal,) => {
  try {
    return new Promise((resolve, reject) => {
      var InventorySaleOrder = Parse.Object.extend("ShoppingCart");
      var query = new Parse.Query(InventorySaleOrder);
      if (ownerType == 'school') {
        query.equalTo("schoolId", ownerId);
      } else {
        query.equalTo("franchiseId", ownerId);
      }
        
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
  
      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

  