import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, Table, Nav, NavItem, NavLink, TabContent, TabPane, Button, Form, FormGroup, Label, Input, Progress, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from 'classnames';
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Parse from 'parse';
import Moment from 'moment';
import { matchPath } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import TopBar from '../Inventory/Topbar';
import { setSelecetdSchool, getUserTeacher, getTeachersForSchool } from '../../store/actions';
import toastr from "toastr";

function allcards(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/all-cards/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-all-cards/:frachiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const frachiseId = franchiseMatch && franchiseMatch.params.frachiseId;

    const [mediaCategories, setMediaCategories] = useState([])
    const [showCardModal, setShowCardModal] = useState(false)
    const [selectedCategory, setSelectedCategory] = useState()
    const [showDeleteCard, setShowDeleteCard] = useState(false)
    const [deleteAlertBoxMessage, setDeleteAlertBoxMessage] = useState(false)
    const [loding, setLoading] = useState(false);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        props.getTeachersForSchool(schoolId);
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (Parse.User.current().attributes.teacherId) {
            props.getUserTeacher(Parse.User.current().attributes.teacherId)
        }
    }, [])

    useEffect(() => {
        setLoading(true)
        let MediaCategories = Parse.Object.extend("MediaCategories");
        let query = new Parse.Query(MediaCategories);

        let ids = [];
        if (franchiseMatch) {
            query.equalTo('targetAudienceId', frachiseId)
            query.notEqualTo("isDeleted", true);
            query.descending('createdAt')
            query.find().then(
                (result) => {
                    // console.log(result)
                    setLoading(false)
                    setMediaCategories(result)
                },
                (error) => {
                    console.log(error)
                }
            );
        } else if (props.selectedSchool) {
            ids.push(props.selectedSchool.id)
            ids.push(props.selectedSchool.attributes.instituteId)
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId)
            }

            query.containedIn('targetAudienceId', ids)
            query.notEqualTo("isDeleted", true);
            query.descending('createdAt')
            query.find().then(
                (result) => {
                    // console.log(result)
                    setLoading(false)
                    setMediaCategories(result)
                },
                (error) => {
                    console.log(error)
                }
            );
        }
    }, [props.selectedSchool])

    function getDateTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }

    const handleDeleteCard = () => {

        selectedCategory.set("isDeleted", true);
        selectedCategory.save().then(
            (result) => {
                // console.log(result)

                toastr.success("Deleted successfully")

                let l = mediaCategories.filter(mc => mc.id != result.id)
                setMediaCategories(l);

                setSelectedCategory(undefined)
                setShowDeleteCard(false);
                setShowCardModal(false)
            },);
    }

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const checkDeleteConditions = (selectedCard) => {
        let user = props.userTeacher;
        let selectedSchool = props.selectedSchool;

        if (selectedCard.attributes.selfDelete) {
            if (selectedCard.attributes.createdBy == user.id) {
                setShowDeleteCard(true)
            } else {
                setDeleteAlertBoxMessage("You don't have permission to delete this card")
                //don't have permission to delete
            }
        } else if (!selectedCard.attributes.targetAudienceId) {
            setDeleteAlertBoxMessage("You don't have permission to delete this card")
            //Dont have permission to delete the card
        } else if (selectedCard.attributes.targetAudienceId == selectedSchool.id) {
            if (user.attributes.Role == 2 || user.attributes.Role == 3) {
                //Director and center head can delete
                setShowDeleteCard(true)
            } else {
                setDeleteAlertBoxMessage("You don't have permission to delete this card")
                //You Dont have permission to delete the card
            }
        } else if (selectedCard.attributes.targetAudienceId == selectedSchool.attributes.instituteId) {
            if (user.attributes.Role == 3) {
                setShowDeleteCard(true)
                //Director can delete
            } else {
                setDeleteAlertBoxMessage("You don't have permission to delete this card")
                //You Dont have permission to delete the card
            }
        } else if (selectedCard.attributes.targetAudienceId == selectedSchool.attributes.franchiseId) {
            if (user.attributes.Role > 10) {
                setShowDeleteCard(true)
                //franchiser can delete
            } else {
                setDeleteAlertBoxMessage("You don't have permission to delete this card")
                //You Dont have permission to delete the card
            }
        } else {
            setDeleteAlertBoxMessage("You don't have permission to delete this card")
            //You Dont have permission to delete the card
        }
    }


    return (
        <div className='page-content'>
            {showDeleteCard && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDeleteCard(false);
                    }}
                    onConfirm={() => handleDeleteCard()}
                >
                    {"Are you sure you want to delete"}
                    <br></br>
                </SweetAlert>
            )}
            {deleteAlertBoxMessage && (
                <SweetAlert
                    title="Alert"
                    danger
                    showConfirm
                    confirmBtnText="Ok"
                    confirmBtnBsStyle="danger"
                    onConfirm={() => setDeleteAlertBoxMessage(false)}
                >
                    {deleteAlertBoxMessage}
                </SweetAlert>
            )}
            <TopBar
                title={"All Card"}
                buttonName={"Create Card"}
                link={franchiseMatch ? `/franchise-add-card/${frachiseId}` : `/add-card/${schoolId}`}
                hideButton={false}
            />
            <Card>
                <CardBody>
                    <Row>
                        {loding && (
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                        Loading....{" "}
                                    </Link>
                                </div>
                            </Col>
                        )}
                        {mediaCategories.map((category, key) => (
                            <Col key={key} xl="3" md="3" sm="6">
                                <Card className="" style={{ background: category.attributes.backgroundHex, height: "230px", width: '230px', position: 'relative', border: '1px solid lightGray' }}>
                                    <div className="position-relative">
                                        <div className=" p-2" style={{ position: 'absolute', left: '8px', bottom: '23px', width: '10px' }}>
                                            <i className="bx bx-info-circle  ml-3 text-white" onClick={(e) => {
                                                setShowCardModal(true)
                                                setSelectedCategory(category)
                                            }} style={{ fontSize: '1.5em', textAlign: 'center', fontWeight: 'bold' }} ></i>
                                        </div>
                                        <Link to={category.attributes.targetType == 'BLOG' ? franchiseMatch ? `/franchise-blog/${frachiseId}/${category.attributes.mediaContentId}` : `/blog/${schoolId}/${category.attributes.mediaContentId}` : franchiseMatch ? `/franchise-blog-list/${frachiseId}/${category.id}` : `/blog-list/${schoolId}/${category.id}`}>
                                            <div className='d-flex justify-content-end py-4'>
                                                <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', border: 'none', }} type="button" color="secondary" size="sm" className="btn-rounded waves-effect waves-light  float-right text-white p-2">
                                                    <strong style={{ color: category.attributes.backgroundHex }}>{category.attributes.title}</strong>
                                                </Button>
                                            </div>
                                        </Link>
                                        <Link to={category.attributes.targetType == 'BLOG' ? franchiseMatch ? `/franchise-blog/${frachiseId}/${category.attributes.mediaContentId}` : `/blog/${schoolId}/${category.attributes.mediaContentId}` : franchiseMatch ? `/franchise-blog-list/${frachiseId}/${category.id}` : `/blog-list/${schoolId}/${category.id}`}>
                                            <div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 50%)', }}>
                                                <h6 className="text-white text-center">{category.attributes.subTitle}</h6>
                                            </div>
                                        </Link>
                                        <Link to={category.attributes.targetType == 'BLOG' ? franchiseMatch ? `/franchise-blog/${frachiseId}/${category.attributes.mediaContentId}` : `/blog/${schoolId}/${category.attributes.mediaContentId}` : franchiseMatch ? `/franchise-blog-list/${frachiseId}/${category.id}` : `/blog-list/${schoolId}/${category.id}`}>
                                            <div className="d-flex justify-content-start " style={{ position: 'absolute', left: '-8px', bottom: '-120px', width: '60px' }}>
                                                <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', width: 'calc(100% + 9px)', textAlign: 'center', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', }} type="button" color="secondary" size="sm" className="btn-rounded waves-effect waves-light  float-right text-white p-2">
                                                    <strong style={{ color: category.attributes.backgroundHex }}><i className="fas fa-arrow-right" style={{ fontSize: '1.2em' }} ></i></strong>
                                                </Button>
                                            </div>
                                        </Link>
                                        <Link to={category.attributes.targetType == 'BLOG' ? franchiseMatch ? `/franchise-blog/${frachiseId}/${category.attributes.mediaContentId}` : `/blog/${schoolId}/${category.attributes.mediaContentId}` : franchiseMatch ? `/franchise-blog-list/${frachiseId}/${category.id}` : `/blog-list/${schoolId}/${category.id}`}>
                                            <div className="d-flex justify-content-end p-2" style={{ position: 'absolute', right: '10px', bottom: '-130px' }}>
                                                <img
                                                    src={category.attributes.icon ? category.attributes.icon._url : ""}
                                                    className="img-fluid mx-auto d-block" style={category.attributes.icon ? { width: "3rem", height: "3rem" } : {}}
                                                    onClick={() => {
                                                        // setImagePopup(true);
                                                        // setphotoIndex(key)
                                                    }}
                                                    alt=""
                                                    width="80"
                                                />
                                            </div>
                                        </Link>
                                    </div>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </CardBody>
            </Card>
            <Modal size='md' isOpen={showCardModal} toggle={() => { setShowCardModal(!showCardModal) }} scrollable={true}>
                <ModalHeader toggle={() => {
                    setShowCardModal(!showCardModal)
                }}>
                    {`${selectedCategory && selectedCategory.attributes.title}${""} Details`}
                </ModalHeader>
                <ModalBody>
                    <div className='d-flex justify-content-center align-items-center'>
                        {/* <Card className="" style={{ background: selectedCategory && selectedCategory.attributes.backgroundHex, height: "230px", width: '230px', position: 'relative', border: '1px solid lightGray' }}>
                            <div className="position-relative">
                                <div className='float-right py-2'>
                                    <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', border: 'none' }} type="button" color="secondary" size="sm" className="btn-rounded waves-effect waves-light  float-right text-white p-2">
                                        <strong style={{ color: selectedCategory && selectedCategory.attributes.backgroundHex }}>{selectedCategory && selectedCategory.attributes.title}</strong>
                                    </Button>
                                </div>
                                <div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', bottom: '-135%', left: '50%', transform: 'translate(-50%, 50%)', }}>
                                    <h6 className="text-white text-center">{selectedCategory && selectedCategory.attributes.subTitle}</h6>
                                </div>
                                <div className="position-absolute bottom-0 start-0 p-2" style={{ left: '-8px', bottom: '-160px', width: '60px' }}>
                                    <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', width: 'calc(100% + 9px)', textAlign: 'center', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }} type="button" color="secondary" size="sm" className="text-white">
                                        <strong style={{ color: selectedCategory && selectedCategory.attributes.backgroundHex }}><i className="fas fa-arrow-right" style={{ fontSize: '1.2em' }} ></i></strong>
                                    </Button>
                                </div>

                                <div className="position-absolute bottom-0 end-0 p-2" style={{ right: '10px', bottom: '-160px' }}>
                                    <img
                                        src={selectedCategory && selectedCategory.attributes.icon ? selectedCategory && selectedCategory.attributes.icon._url : ""}
                                        className="img-fluid mx-auto d-block" style={selectedCategory && selectedCategory.attributes.icon ? { width: "3rem", height: "3rem" } : {}}
                                        onClick={() => {
                                            // setImagePopup(true);
                                            // setphotoIndex(key)
                                        }}
                                        alt=""
                                        width="80"
                                    />
                                </div>
                            </div>
                        </Card> */}


                        <Card className="" style={{ background: selectedCategory && selectedCategory.attributes.backgroundHex, height: "230px", width: '230px', position: 'relative', border: '1px solid lightGray' }}>
                            <div className="position-relative">
                                <div className='d-flex justify-content-end py-4'>
                                    <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', border: 'none', }} type="button" color="secondary" size="sm" className="btn-rounded waves-effect waves-light  float-right text-white p-2">
                                        <strong style={{ color: selectedCategory && selectedCategory.attributes.backgroundHex }}>{selectedCategory && selectedCategory.attributes.title}</strong>
                                    </Button>
                                </div>
                                <div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', left: '50%', transform: 'translate(-50%, 50%)', }}>
                                    <h6 className="text-white text-center">{selectedCategory && selectedCategory.attributes.subTitle}</h6>
                                </div>
                                <div className="d-flex justify-content-start " style={{ position: 'absolute', left: '-8px', bottom: '-120px', width: '60px' }}>
                                    <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', width: 'calc(100% + 9px)', textAlign: 'center', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', }} type="button" color="secondary" size="sm" className="btn-rounded waves-effect waves-light  float-right text-white p-2">
                                        <strong style={{ color: selectedCategory && selectedCategory.attributes.backgroundHex }}><i className="fas fa-arrow-right" style={{ fontSize: '1.2em' }} ></i></strong>
                                    </Button>
                                </div>
                                <div className="d-flex justify-content-end p-2" style={{ position: 'absolute', right: '10px', bottom: '-130px' }}>
                                    <img
                                        src={selectedCategory && selectedCategory.attributes.icon ? selectedCategory && selectedCategory.attributes.icon._url : ""}
                                        className="img-fluid mx-auto d-block" style={selectedCategory && selectedCategory.attributes.icon ? { width: "3rem", height: "3rem" } : {}}
                                        onClick={() => {
                                            // setImagePopup(true);
                                            // setphotoIndex(key)
                                        }}
                                        alt=""
                                        width="80"
                                    />
                                </div>
                            </div>
                        </Card>
                    </div>

                    <Table className=" table mb-0 table-bordered">
                        <tbody>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Title</th>
                                <td>{selectedCategory && selectedCategory.attributes.title}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Description</th>
                                <td>{selectedCategory && selectedCategory.attributes.subTitle}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}> Type</th>
                                <td>{selectedCategory && selectedCategory.attributes.targetType == "BLOG" ? "Single Post" : "Multi Post"}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Send To</th>
                                <td>{selectedCategory && selectedCategory.attributes.userType}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Created On</th>
                                <td>{getDateTimeStringForDate(selectedCategory && selectedCategory.attributes.createdAt)}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Created By</th>
                                <td>{getTeacherName(selectedCategory && selectedCategory.attributes.createdBy)}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Delete</th>
                                <td><i className="bx bxs-trash  text-danger"
                                    onClick={() => {
                                        checkDeleteConditions(selectedCategory)
                                        // setShowDeleteCard(true)
                                    }}
                                    style={{ cursor: 'pointer' }}></i>
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </ModalBody>
            </Modal>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { userTeacher } = state.Login;
    const { teachers } = state.Teacher;
    return { selectedSchool, schools, userTeacher, teachers };
}

export default withRouter(connect(mapStatetoProps, { setSelecetdSchool, getUserTeacher, getTeachersForSchool })(withNamespaces()(allcards)));
