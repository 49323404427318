// import React, { Component } from "react";
// import ReactApexChart from "react-apexcharts";

// class EnquiryColumnChart extends Component {
//   constructor(props) {
//     super(props);
//     this.state = {
//       options: {
//         chart: {
//           height: 359,
//           type: "bar",
//           stacked: !0,
//           toolbar: {
//             show: 1,
//           },
//           zoom: {
//             enabled: !0,
//           },
//         },
//         plotOptions: {
//           bar: {
//             horizontal: !1,
//             columnWidth: "15%",
//             // endingShape: "rounded"
//           },
//         },
//         dataLabels: {
//           enabled: !1,
//         },
//         xaxis: {
//           categories: this.props.categories,
//         },
//         yaxis: {
//           min: 0,
//           labels: {
//             formatter: function (val) {
//               return val.toFixed(0);
//             },
//           },
//         },
//         colors: ["#34c38f", "#74788d", "#f46a6a"],
//         legend: {
//           position: "bottom",
//         },
//         fill: {
//           opacity: 1,
//         },
//       },
//       series: [
//         {
//           name: "Enquiry Count",
//           data: this.props.inCountList,
//         },
//         {
//           name: "Admission Count",
//           data: this.props.outCountList,
//         },
//         {
//           name: "Absent",
//           data: this.props.absentCountList,
//         },
//       ],
//     };
//   }

//   render() {
//     return (
//       <React.Fragment>
//         <ReactApexChart
//           options={this.state.options}
//           series={this.state.series}
//           type="bar"
//           height="359"
//         />
//       </React.Fragment>
//     );
//   }
// }

// export default EnquiryColumnChart;
import React from 'react';
import ReactApexChart from 'react-apexcharts';

const Apaexlinecolumn = (props) => {

  const series = [{
    name: 'Enquiry Count',
    data: props.inCountList
  }, {
    name: 'Admission Count',
    data: props.outCountList
  }];
  const options = {
    chart: {
      toolbar: {
        show: false,
      }
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '45%',
        endingShape: 'rounded'
      },
    },
    dataLabels: {
      enabled: false
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },

    colors: ['#34c38f', '#556ee6', '#f46a6a'],
    xaxis: {
      categories: props.categories,
    },
    yaxis: {
      title: {
        text: ''
      }
    },
    grid: {
      borderColor: '#f1f1f1',
    },
    fill: {
      opacity: 1

    },
    tooltip: {
      y: {
        formatter: function (val) {
          return "" + val + ""
        }
      }
    }
  };

  return (
    <ReactApexChart options={options} series={series} type="bar" height={350} />

  );
}

export default Apaexlinecolumn;