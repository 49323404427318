import * as actions from "./actionTypes";

const initialState = {
  teachers: [],
  selectedTeacher: null,
  error: "",
  success: "",
  loading: false,
  classTeachers: [],
  webAccessResponse: null,
  webAccessRemovalResponse: null,
  deletedTeachers: undefined,
  teacherAttendanceObjects: undefined,
  daycareAttendanceObjects: undefined,
  attendanceObjects: undefined,
  deletedTeachersLoading: true,
  franchiseTeachers: [],
  teacherReport: false,
  deletedTeachersForMonth: undefined,
  teachersForIds: [],
};

const teacher = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_TEACHER:
      var teachers = [...state.teachers];
      var temp = state.teachers.filter(
        (teacher) => teacher.id === action.payload.teacher.id
      );
      if (temp.length > 0) {
        if (action.payload.teacher.attributes.deleted) {
          var others = action.payload.teachers.filter(
            (teacher) => teacher.id != action.payload.teacher.id
          );

          state = { ...state, teachers: others };
        } else {
          var index = teachers.indexOf(temp[0]);
          teachers[index] = action.payload.teacher;
          if (
            state.selectedTeacher &&
            state.selectedTeacher.id === action.payload.teacher.id
          ) {
            state = {
              ...state,
              teachers: teachers,
              selectedTeacher: action.payload.teacher,
            };
          } else {
            state = { ...state, teachers: teachers };
          }
        }
      } else {
        if (!action.payload.teacher.attributes.deleted) {
          teachers.push(action.payload.teacher);
          if (
            state.selectedTeacher &&
            state.selectedTeacher.id === action.payload.teacher.id
          ) {
            state = {
              ...state,
              teachers: teachers,
              selectedTeacher: action.payload.teacher,
            };
          } else {
            state = { ...state, teachers: teachers };
          }
        }
      }
      break;

    case actions.CLEAR_ALL_TEACHERS:
      state = { ...state, teachers: [] };
      break;
    case actions.GET_TEACHERS:
      state = { ...state, teacherReport: false };
      break;
    case actions.UPDATE_TEACHERS:
      var teachers = [...state.teachers];

      if (teachers.length > 0) {
        for (const latest in action.payload.teachers) {
          var temp = teachers.filter(
            (teacher) => teacher.id === action.payload.teachers[latest].id
          );
          if (temp.length > 0) {
            if (action.payload.teachers[latest].attributes.deleted) {
              var others = teachers.filter(
                (classroom) =>
                  classroom.id !== action.payload.teachers[latest].id
              );
              teachers = [...others];
            } else {
              var index = teachers.lastIndexOf(temp[0]);
              teachers[index] = action.payload.teachers[latest];
            }
          } else {
            if (!action.payload.teachers[latest].attributes.deleted) {
              teachers.push(action.payload.teachers[latest]);
            }
          }
        }
        
      } else {
        teachers = action.payload.teachers.filter(
          (teacher) => !teacher.attributes.deleted
        ); 
      }

      if (action.payload.schoolId) {

        let tempT = [];
        for (const t of teachers) {
          if (t.attributes.Role == 3 && t.attributes.schools && t.attributes.schools.length > 0) {
            if (t.attributes.schools.includes(action.payload.schoolId)) {
              tempT.push(t);
            } 
          } else {
            tempT.push(t);
          }
        }
        state = { ...state, teachers: tempT, teacherReport: true };
      } else {
        state = { ...state, teachers: teachers, teacherReport: true };
      }

      break;

    case actions.RESPONSE_FOR_WEB_ACCESS:
      state = { ...state, webAccessResponse: action.payload.responseResult };
      break;

    case actions.UPDATE_DELETED_TEACHERS_FOR_MONTH:
      state = { ...state, deletedTeachersForMonth: action.payload.teachers };
      break;
    case actions.RESPONSE_FOR_WEB_ACCESS_REMOVAL:
      state = {
        ...state,
        webAccessRemovalResponse: action.payload.responseResult,
      };
      break;
    case actions.UPDATE_DAYCARE_ATTENDANCE_FOR_DAY:
      state = {
        ...state,
        daycareAttendanceObjects: action.payload.daycareAttendanceObjects,
      };
      break;

    case actions.DELETE_TEACHER_SUCCESS:
      var teachers = state.teachers.filter(
        (teacher) => teacher.id !== action.payload.teacher.id
      );
      state = { ...state, teachers: teachers };
      break;
    case actions.UPDATE_SELECTED_TEACHER:
      state = {
        ...state,
        teachers: state.teachers,
        selectedTeacher: action.payload.teacher,
        loading: false,
      };
      break;
    case actions.UPDATE_TEACHER_ATTENDANCE_FOR_DAY:
      state = {
        ...state,
        teacherAttendanceObjects: action.payload.teacherAttendanceObjects,
      };
      break;
    case actions.UPDATE_SCHOOL_ATTENDANCE:
      state = { ...state, attendanceObjects: action.payload.attendanceObjects };
      break;

    case actions.LOADING:
      state = { ...state, loading: action.payload };
      break;
    case actions.SET_TEACHER_FOR_CLASSROOM:
      state = { ...state, classTeachers: action.payload.teacherObj };
      break;

    case actions.UPDATE_DELETED_TEACHERS:
      state = {
        ...state,
        deletedTeachers: action.payload.teachers,
        deletedTeachersLoading: false,
      };
      break;
    case actions.UPDATE_FRANCHISE_TEACHERS:
      var teachers = [...state.franchiseTeachers];

      if (teachers.length > 0) {
        for (const latest in action.payload.teachers) {
          var temp = teachers.filter(
            (classroom) => classroom.id === action.payload.teachers[latest].id
          );
          if (temp.length > 0) {
            if (action.payload.teachers[latest].attributes.deleted) {
              var others = teachers.filter(
                (classroom) =>
                  classroom.id !== action.payload.teachers[latest].id
              );
              teachers = [...others];
            } else {
              var index = teachers.lastIndexOf(temp[0]);
              teachers[index] = action.payload.teachers[latest];
            }
          } else {
            if (!action.payload.teachers[latest].attributes.deleted) {
              teachers.push(action.payload.teachers[latest]);
            }
          }
        }
        state = { ...state, franchiseTeachers: teachers, };
      } else {
        var others = action.payload.teachers.filter(
          (teacher) => !teacher.attributes.deleted
        );

        state = { ...state, franchiseTeachers: others, };
      }


      break;

    case actions.UPDATE_TEACHERS_FOR_IDS:
      if (action.payload.isConcat) {
        state = {
          ...state,
          teachersForIds: state.teachersForIds.concat(action.payload.teachers)
        };
      } else {
        state = {
          ...state,
          teachersForIds: action.payload.teachers
        };
      }

      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default teacher;
