import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardText, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane, Button, Form, FormGroup, Label, Input, Progress, UncontrolledTooltip } from "reactstrap";
import classnames from 'classnames';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';
import Editable from "react-bootstrap-editable";
import ColorPicker from "../SchoolRating/colorPicker";
import { setSelecetdSchool } from '../../store/actions';
import TopBar from '../Inventory/Topbar'
import Compressor from 'compressorjs';
import { IMAGE_COMPRESSION_RATE } from '../../Constents';
import { v4 as uuidv4 } from "uuid";
import * as WhiteLabel from '../../components/Common/WhiteLable'

function addCard(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/add-card/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-add-card/:frachiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const frachiseId = franchiseMatch && franchiseMatch.params.frachiseId;

    const colors = ['#6f42c1', '#556ee6', '#74788d', '#f1734f', '#f46a6a', "#50a5f1", '#050505', "#34c38f", '#f1b44c', "#e83e8c"];
    const Content = ["VIDEO", "LINK", "TEXT"];
    const MAX_TITLE_LENGTH = 15;
    const MAX_DESCRIPTION_LENGTH = 30;

    const [activeTraType, setActiveTraType] = useState("VIDEO");
    const [actTab, setactTab] = useState("VIDEO");
    const [activeTab, setactiveTab] = useState(1);
    const [progressValue, setprogressValue] = useState(33);
    const [newBranch, setNewBranch] = useState(false);
    const [activeButton, setActiveButton] = useState('ALL');
    const [activeBlog, setActiveBlog] = useState('BLOG');
    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState(false);
    const [titleExceedError, setTitleExceedError] = useState(false);
    const [description, setDescription] = useState('');
    const [descriptionError, setDescriptionError] = useState(false);
    const [descriptionExceedError, setDescriptionExceedError] = useState(false);
    const [selectedColor, setSelectedColor] = useState(colors[0]);
    const [all, setAll] = useState()
    const [teacher, setTeacher] = useState()
    const [parent, setParent] = useState()
    const [director, setDirector] = useState()
    const [centerHead, SetCenterHead] = useState()
    const [singleBlog, SetSingleBlog] = useState()
    const [multiBlog, SetMultiBlog] = useState()
    const [blogError, setBlogError] = useState(false)
    const [sendToError, SetSendToError] = useState(false)
    const [textPhoto, setTextPhoto] = useState();
    const [textDocs, setTextDocs] = useState();
    const [cardPhoto, setCardPhoto] = useState();

    const [albumImageList, setAlbumImageList] = useState([]);
    const [albumUUIDId, setAlbumUUIDId] = useState();
    const [imagesList, setImagesList] = useState();
    const [currentIndexForUpload, setCurrentIndexForUpload] = useState(0);
    const [progressText, setProgressText] = useState();

    const [attachmentUploadProgress, setAttachmentUploadProgress] = useState(0);
    const [textPhotoUploadProgress, setTextPhotoUploadProgress] = useState(0);
    const [textDocsUploadProgress, setTextDocsUploadProgress] = useState(0);

    const [videoTitle, setVideoTitle] = useState();
    const [linkTitle, setLinkTitle] = useState();
    const [textTitle, setTextTitle] = useState();

    const [videoDescription, setVideoDescription] = useState();
    const [linkDescription, setLinkDescription] = useState();
    const [textDescription, setTextDescription] = useState();

    const [videoUrl, setVideoUrl] = useState();
    const [linkUrl, setLinkUrl] = useState();
    const [isInvalidUrl, setIsInvalidUrl] = useState(false);

    const [showSelfDelete, setShowSelfDelete] = useState(false);

    const [forInstitute, setForInstitute] = useState(false);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const handleClick = (button) => {
        setActiveButton(button);
    };

    const handleBlogClick = (button) => {
        setActiveBlog(button);
    };

    const canShowCheckboxForAllSchool = () => {
        let returnValue = false

        if (Parse.User.current() && Parse.User.current().attributes.instituteId && Parse.User.current().attributes.isSuperUser) {
            if (Parse.User.current().attributes.teacherId) {
                if (props.userTeacher && props.userTeacher.attributes.instituteId && props.userTeacher.attributes.instituteId.length > 0) {
                    returnValue = true
                }
            } else {
                returnValue = true
            }
        }
        return returnValue
    }

    function addCardValidation() {
        var res = true;
        if (!title || title.trim().length === 0) {
            setTitleError(true);
            res = false;
        }
        if (!description || description.trim().length === 0) {
            setDescriptionError(true);
            res = false;
        }
        // if (!teacher || teacher.trim().length === 0 || !parent || parent.trim().length === 0 || !director || director.trim().length === 0 || !centerHead || centerHead.trim().length === 0) {
        //     SetSendToError(true);
        //     res = false;
        // }
        // if (!multiBlog || multiBlog.trim().length === 0) {
        //     setBlogError(true);
        //     res = false;
        // }

        return res;
    }

    function toggleTab(tab) {
        if (activeTab !== tab) {
            if (tab >= 1 && tab <= 3) {
                setactiveTab(tab);
            }
            if (tab === 1) {
                setprogressValue(33)
            } else if (tab === 2) {
                setprogressValue(66)
            } else if (tab === 3) {
                setprogressValue(100)
            }

        }
    }

    function switchTab(newTab) {
        if (activeTab === 1) {
            if (addCardValidation()) {
                toggleTab(newTab);
            }
        } else if (activeTab === 2) {
            toggleTab(newTab);

        } else {
            toggleTab(newTab);
        }
    }

    const inputStyle = {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    };

    function getFileTypeImage(fileExtension) {
        if (fileExtension === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtension === "ppt" || fileExtension === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "doc" || fileExtension === "docx") {
            return "bx bxs-file-doc";
        }

        return "bx bxs-file-blank";
    }


    const showUploadProgress = () => {
        if (cardPhoto && attachmentUploadProgress >= 1 && attachmentUploadProgress < 100) {
            return true
        } else if (cardPhoto && !cardPhoto._url) {
            return true
        } else {
            return false
        }
    }

    const showTextPhotoUploadProgress = () => {
        if (textPhoto && textPhotoUploadProgress >= 1 && textPhotoUploadProgress < 100) {
            return true
        } else if (textPhoto && !textPhoto._url) {
            return true
        } else {
            return false
        }
    }

    const showTextDocsUploadProgress = () => {
        if (textDocs && textDocsUploadProgress >= 1 && textDocsUploadProgress < 100) {
            return true
        } else if (textDocs && !textDocs._url) {
            return true
        } else {
            return false
        }
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
        };
    }

    function handleFileUploadChange(e, files) {
        setCardPhoto(undefined)
        setAttachmentUploadProgress(1)
        let fileExtension = e.target.files[0].name.split(".").pop();

        let tempFile = {
            actualFileName: e.target.files[0].name,
            fileExtension: fileExtension.toLowerCase(),
            fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
            file: e.target.files[0],
        };

        var name = tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + tempFile.fileExtension;
        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            setAttachmentUploadProgress(value * 100)
                        }
                    },
                })
                .then(
                    (result) => {

                        setCardPhoto(result)
                        console.log(result)
                        setAttachmentUploadProgress(false)
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        });
        setCardPhoto(tempFile.actualFileName)
    }


    function handleImageUploadChange(e) {
        let tempFileList = [];
        console.log(e.target.files.length);
        for (const i in e.target.files) {

            if (e.target.files.length > i) {
                let tempFile = e.target.files[i];
                if (tempFile && tempFile.name) {
                    let tempfileExtension = tempFile.name.split(".");
                    let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
                    let newFile = {
                        id: "fiele_" + i,
                        file: tempFile,
                        type: "Image",
                        downloadeble: true,
                        actualFileName: tempFile.name,
                        name: tempfileExtension[0],
                        extention: tempExt,
                        extentionIcon: getFileTypeImage(tempExt),
                        preview: URL.createObjectURL(tempFile)
                    }
                    tempFileList.push(newFile);
                }
            }
        }
        setCurrentIndexForUpload(0)
        setImagesList(tempFileList);
    }
    // console.log(imagesList)
    // console.log(albumUUIDId)
    // console.log(albumImageList)
    useEffect(() => {
        if (currentIndexForUpload >= 0 && imagesList) {
            if (imagesList.length > currentIndexForUpload) {
                onImageListUpload(imagesList[currentIndexForUpload], currentIndexForUpload);
            } else {
                setCurrentIndexForUpload(-1);
                setProgressText()
                setImagesList([]);
            }
        }
    }, [imagesList, currentIndexForUpload]);

    function onImageListUpload(file, count) {
        setProgressText(props.t('Uploading ') + (count + 1) + " of " + imagesList.length)
        new Compressor(file.file, {
            quality: IMAGE_COMPRESSION_RATE, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                convertImageToBase64(file, count, compressedResult)
            },
        });
    }

    function convertImageToBase64(file, count, compressedResult) {
        var albumId = albumUUIDId ?? uuidv4()
        setAlbumUUIDId(albumId)
        var name = `file`;
        if (file.type === "Image") {
            name = name + "." + file.extention;
        }
        getBase64(compressedResult, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setProgressText(`${props.t('Uploading ') + (count + 1) + " of " + imagesList.length + " Images "} ( ${(value * 100).toFixed(2)}% )`)
                    }
                }
            }).then(
                result => {
                    let parseImagesList = [...albumImageList]
                    var Files = Parse.Object.extend("Album");
                    var fileObject = new Files();
                    fileObject.set("albumId", albumId);
                    fileObject.set("image", result);
                    // fileObject.set("schoolId", schoolId)
                    parseImagesList.push(fileObject)
                    setAlbumImageList(parseImagesList)
                    if (imagesList.length > currentIndexForUpload) {
                        setCurrentIndexForUpload((currentIndexForUpload + 1))
                    } else {
                        setCurrentIndexForUpload(-1);
                        setProgressText()
                        setImagesList([]);
                    }
                },
                error => {
                    setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                }
            );
        });
    }


    function handleTextPhotoUploadChange(e, files) {
        setTextPhoto(undefined)
        setTextPhotoUploadProgress(1)
        let fileExtension = e.target.files[0].name.split(".").pop();

        let tempFile = {
            actualFileName: e.target.files[0].name,
            fileExtension: fileExtension.toLowerCase(),
            fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
            file: e.target.files[0],
        };

        var name = tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + tempFile.fileExtension;
        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            setTextPhotoUploadProgress(value * 100)
                        }
                    },
                })
                .then(
                    (result) => {

                        setTextPhoto(result)
                        console.log("text photo", result)
                        setTextPhotoUploadProgress(false)
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        });
        setTextPhoto(tempFile.actualFileName)
    }

    function handleTextDocsUploadChange(e, files) {
        setTextDocs(undefined)
        setTextDocsUploadProgress(1)
        let fileExtension = e.target.files[0].name.split(".").pop();

        let tempFile = {
            actualFileName: e.target.files[0].name,
            fileExtension: fileExtension.toLowerCase(),
            fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
            file: e.target.files[0],
        };

        var name = tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + tempFile.fileExtension;
        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            setTextDocsUploadProgress(value * 100)
                        }
                    },
                })
                .then(
                    (result) => {

                        setTextDocs(result)
                        console.log("text docs", result)
                        setTextDocsUploadProgress(false)
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        });
        setTextDocs(tempFile.actualFileName)
    }

    const handleSave = () => {

        const MediaCategories = Parse.Object.extend("MediaCategories");
        const categories = new MediaCategories();

        categories.set("userType", activeButton);
        categories.set("targetType", activeBlog);
        categories.set("backgroundHex", selectedColor);
        categories.set("dataTableName", "MediaLink");
        if (franchiseMatch) {
            categories.set("targetAudienceId", frachiseId);
        } else if (forInstitute) {
            categories.set("targetAudienceId", props.selectedSchool.attributes.instituteId);
        } else {
            categories.set("targetAudienceId", schoolId);
        }
        categories.set("title", title);
        categories.set("subTitle", description);
        categories.set("icon", cardPhoto);
        categories.set("filters", [uuidv4()]);
        categories.set("selfDelete", showSelfDelete);

        categories.save().then(
            (rMediaCategory) => {
                console.log(rMediaCategory)

                const MediaLink = Parse.Object.extend("MediaLink");
                const mediaLink = new MediaLink();

                if (actTab === 'VIDEO') {
                    mediaLink.set("title", videoTitle);
                    mediaLink.set("description", videoDescription);
                    mediaLink.set("url", videoUrl);
                    mediaLink.set("mediaType", 'YouTube');
                }
                if (actTab === 'LINK') {
                    mediaLink.set("title", linkTitle);
                    mediaLink.set("description", linkDescription);
                    mediaLink.set("url", linkUrl);
                    mediaLink.set("mediaType", "URL");
                }
                if (actTab === 'TEXT') {
                    mediaLink.set("title", textTitle);
                    mediaLink.set("description", textDescription);
                    if (albumUUIDId) {
                        mediaLink.set('albumId', albumUUIDId)
                        mediaLink.set("previewImage", albumImageList[0].attributes.image);
                        Parse.Object.saveAll(albumImageList).then(
                          (results) => {},
                          (error) => {
                            console.log("Error", error);
                          }
                        );
                    } else if (textPhoto) {
                        mediaLink.set("file", textPhoto);
                    } else {
                        mediaLink.set("file", textDocs);
                    }
                    mediaLink.set("mediaType", "TEXT");
                }

                if (textPhoto) {
                    mediaLink.set("previewImage", textPhoto);
                }

                mediaLink.set("targetAudienceId", rMediaCategory.attributes.targetAudienceId);
                mediaLink.set("tags", rMediaCategory.attributes.filters);
                mediaLink.set("createdBy", Parse.User.current().attributes.teacherId);

                mediaLink.save().then(
                    (rMediaLink) => {
                        if (activeBlog == 'BLOG') {
                            rMediaCategory.set("mediaContentId", rMediaLink.id)

                            rMediaCategory.save().then((result) => {
                                console.log(result)
                            }
                            )
                        }

                        console.log("SUCCESS", rMediaLink);
                        window.history.back()
                    },
                    (error) => {
                        console.log("ERROR", error);
                    }
                );
            },
            (error) => {
                console.log("ERROR", error);
            }
        );
    }

    const validateYouTubeUrl = (url) => {
        // Regular expression to check if the URL is a valid YouTube URL
        const youtubeUrlRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
        return youtubeUrlRegex.test(url);
    };

    const handleChange = (e) => {
        const inputValue = e.target.value;
        setVideoUrl(inputValue);

        // Check if the entered URL is a valid YouTube URL
        setIsInvalidUrl(!validateYouTubeUrl(inputValue));
    };

    return (
        <div className='page-content'>
            <TopBar
                title={"Create Card"}
                buttonName={"Create Card"}
                hideButton={true}
            />
            <Card>
                <CardBody>
                    <div id="basic-pills-wizard" className="twitter-bs-wizard">
                        <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                            {<NavItem>
                                <NavLink className={classnames({ active: activeTab === 1 })}
                                    onClick={() => {
                                        switchTab(1);
                                    }} >
                                    <span className="step-number m-2">01</span>
                                    {props.t('ADD CARD')}
                                </NavLink>
                            </NavItem>}
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === 2 })}
                                    onClick={() => {
                                        switchTab(2);
                                    }} >
                                    <span className="step-number m-2">02</span>
                                    <span>{props.t('PREVIEW')} </span>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink className={classnames({ active: activeTab === 3 })}
                                    onClick={() => {
                                        switchTab(3);
                                    }} >
                                    <span className="step-number m-2">03</span>
                                    <span>{props.t('ADD STORY')} </span>
                                </NavLink>
                            </NavItem>
                        </ul>
                        <div id="bar" className="mt-4">
                            <Progress color="success" striped animated value={progressValue} />
                            <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                        </div>
                        <TabContent activeTab={activeTab} className="twitter-bs-wizard-tab-content">
                            <TabPane tabId={1}>
                                <div className="ml-4" style={{ minHeight: '60vh' }}>                                <Form>
                                    <Row>
                                        <Col lg="8">
                                            <FormGroup>
                                                <Label >Title({MAX_TITLE_LENGTH - title.length})<span className="text-danger">*</span></Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    value={title}
                                                    maxLength={MAX_TITLE_LENGTH}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        setTitle(inputValue);

                                                        if (inputValue.length > 14) {
                                                            setTitleExceedError(true);
                                                        } else {
                                                            setTitleExceedError(false);
                                                        }
                                                        setTitleError(false)
                                                    }} />
                                                {titleError && <p className="text-danger">{props.t('Title is mandatory')}</p>}
                                                {titleExceedError && <p className="text-danger">Title should not exceed {MAX_TITLE_LENGTH} characters</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="8">
                                            <FormGroup>
                                                <Label >Description({MAX_DESCRIPTION_LENGTH - description.length})<span className="text-danger">*</span></Label>
                                                <Input type="text"
                                                    className="form-control"
                                                    value={description}
                                                    maxLength={MAX_DESCRIPTION_LENGTH}
                                                    onChange={(e) => {
                                                        const inputValue = e.target.value;
                                                        setDescription(inputValue);

                                                        if (inputValue.length > 29) {
                                                            setDescriptionExceedError(true);
                                                        } else {
                                                            setDescriptionExceedError(false);
                                                        }
                                                        setDescriptionError(false)
                                                    }} />
                                                {descriptionError && <p className="text-danger">{props.t('Description is mandatory')}</p>}
                                                {descriptionExceedError && <p className="text-danger">Description should not exceed {MAX_DESCRIPTION_LENGTH} characters</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                    <Row>
                                        <Col lg="2">
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Button
                                                    style={{ width: '110px' }}
                                                    color={activeButton === 'ALL' ? 'primary' : 'light'}
                                                    className="mr-1"
                                                    onClick={() => {
                                                        handleClick('ALL')
                                                        setAll("ALL")
                                                    }}
                                                >
                                                    ALL
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Button
                                                    style={{ width: '110px' }}
                                                    color={activeButton === 'TEACHER' ? 'primary' : 'light'}
                                                    className="mr-1"
                                                    onClick={() => {
                                                        handleClick('TEACHER')
                                                        setTeacher("TEACHER")
                                                    }}
                                                >
                                                    Teacher
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Button
                                                    style={{ width: '110px' }}
                                                    color={activeButton === 'PARENT' ? 'primary' : 'light'}
                                                    className="mr-1"
                                                    onClick={() => {
                                                        handleClick('PARENT')
                                                        setParent("PARENT")
                                                    }}
                                                >
                                                    Parents
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col lg="2">
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Button
                                                    style={{ width: '110px' }}
                                                    color={activeButton === 'DIRECTOR' ? 'primary' : 'light'}
                                                    className="mr-1"
                                                    onClick={() => {
                                                        handleClick('DIRECTOR')
                                                        setDirector('DIRECTOR')
                                                    }}
                                                >
                                                    Directors
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                        <Col lg="2">
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Button
                                                    style={{ width: '110px' }}
                                                    color={activeButton === 'CENTERHEAD' ? 'primary' : 'light'}
                                                    className="mr-1"
                                                    onClick={() => {
                                                        handleClick('CENTERHEAD')
                                                        SetCenterHead('CENTERHEAD')
                                                    }}
                                                >
                                                    CENTERHEAD
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col>
                                            {canShowCheckboxForAllSchool() && <FormGroup>
                                                <div className="custom-control custom-checkbox mb-2 ml-3" >
                                                    <input type="checkbox" className="custom-control-input" id="instituteCheck" onChange={() => false} checked={forInstitute} />
                                                    <label className="custom-control-label" onClick={() => { setForInstitute(!forInstitute); }} >{props.t('Apply this Card to all schools')}</label>
                                                </div>
                                            </FormGroup>}
                                        </Col>
                                    </Row>
                                    {/* {sendToError && <p className="text-danger">{props.t('Please Select whom to send')}</p>} */}
                                    <hr></hr>
                                    <Row>
                                        <Col lg='2'>
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Button
                                                    style={{ width: '110px' }}
                                                    color={activeBlog === 'BLOG' ? 'primary' : 'light'}
                                                    className="mr-1"
                                                    onClick={() => {
                                                        handleBlogClick('BLOG')
                                                        SetSingleBlog('BLOG')
                                                    }}
                                                >
                                                    Single Post
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                        <Col lg='2'>
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Button
                                                    style={{ width: '110px' }}
                                                    color={activeBlog === 'BLOGLIST' ? 'primary' : 'light'}
                                                    className="mr-1"
                                                    onClick={() => {
                                                        handleBlogClick('BLOGLIST')
                                                        SetMultiBlog('BLOGLIST')
                                                    }}
                                                >
                                                    Multi Post
                                                </Button>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    {/* {blogError && <p className="text-danger">{props.t('Please Select type of blog')}</p>} */}
                                    <hr></hr>
                                    <Row>
                                        <Col>
                                            <div className="popup-gallery">
                                                <div className="img-fluid float-left">
                                                    <Row>
                                                        <Col>
                                                            <img
                                                                src={cardPhoto ? cardPhoto._url : ""}
                                                                onClick={() => {
                                                                    // setImagePopup(true);
                                                                    // setphotoIndex(key)
                                                                }}
                                                                alt=""
                                                                width="50"
                                                            />
                                                        </Col>
                                                        <Col>
                                                            <h5>{cardPhoto ? cardPhoto._name : ''}</h5>
                                                        </Col>
                                                        <Col className='float-right'>
                                                            {cardPhoto && cardPhoto._url && <h5><i className="bx bxs-trash  text-danger" onClick={() => { setCardPhoto(undefined) }} style={{ cursor: 'pointer' }}></i></h5>}
                                                        </Col>
                                                    </Row>

                                                </div>
                                            </div>
                                        </Col>
                                    </Row>
                                    {
                                        showUploadProgress() && (
                                            <div className="d-flex">
                                                <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                    <div className="border p-3">
                                                        <Row>
                                                            <div
                                                                className="avatar ml-3"
                                                                style={{ width: "5rem", height: "5rem" }}
                                                            >
                                                                <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                    <i
                                                                        className={getFileTypeImage(cardPhoto)}
                                                                    ></i>
                                                                </span>
                                                            </div>
                                                            <div
                                                                className="float-right ml-4"
                                                                style={{ width: "200px" }}
                                                            >
                                                                <div className="text-muted font-weight-bold">
                                                                    {cardPhoto}
                                                                </div>
                                                                <div className=" mt-3">
                                                                    <Progress
                                                                        color="primary"
                                                                        value={attachmentUploadProgress}
                                                                    ></Progress>
                                                                </div>
                                                            </div>
                                                        </Row>
                                                    </div>
                                                </Col>
                                            </div>
                                        )}
                                    {!cardPhoto && <Row>
                                        <Col>
                                            <FormGroup className="mt-2 mb-2 p-1">
                                                <Label
                                                    htmlFor={"input-file-attachment"}
                                                    color="primary"
                                                    className="btn btn-primary"
                                                >
                                                    <i className='align-middle fa fa-paperclip font-size-12 mr-2'></i>Attach Photo
                                                    <input
                                                        id={"input-file-attachment"}
                                                        style={inputStyle}
                                                        type="file"
                                                        onChange={(e) => {
                                                            handleFileUploadChange(e);
                                                        }}
                                                        accept="image/*"
                                                    />
                                                </Label>
                                            </FormGroup>
                                        </Col>
                                    </Row>}
                                    <hr></hr>
                                    <Row>
                                        <Col>
                                            <div>
                                                <h5>Select Card Background Color:</h5>
                                                <Row className='py-4'>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#6f42c1' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#6f42c1', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#6f42c1' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[0])}>
                                                        </span>
                                                    </Col>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#556ee6' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#556ee6', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#556ee6' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[1])}>
                                                        </span>
                                                    </Col>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#74788d' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#74788d', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#74788d' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[2])}>
                                                        </span>
                                                    </Col>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#f1734f' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#f1734f', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#f1734f' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[3])}>
                                                        </span>
                                                    </Col>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#f46a6a' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#f46a6a', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#f46a6a' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[4])}>
                                                        </span>
                                                    </Col>
                                                </Row>
                                                <Row className='py-4'>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#50a5f1' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#50a5f1', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#50a5f1' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[5])}>
                                                        </span>
                                                    </Col>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#050505' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#050505', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#050505' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[6])}>
                                                        </span>
                                                    </Col>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#34c38f' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#34c38f', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#34c38f' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[7])}>
                                                        </span>
                                                    </Col>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#f1b44c' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#f1b44c', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#f1b44c' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[8])}>
                                                        </span>
                                                    </Col>
                                                    <Col lg='1'>
                                                        <span
                                                            className={`mr-2 ${selectedColor === '#e83e8c' ? 'selected' : ''}`}
                                                            style={{ backgroundColor: '#e83e8c', width: '30px', height: '30px', borderRadius: '50%', display: 'inline-block', border: selectedColor === '#e83e8c' ? '2px solid black' : 'none' }}
                                                            onClick={() => setSelectedColor(colors[9])}>
                                                        </span>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Row>
                                                <Col>
                                                    <h5>Select card background color Manually:</h5>
                                                </Col>
                                            </Row>
                                            <Row className='py-4'>
                                                <Col>
                                                    <ColorPicker
                                                        color={selectedColor}
                                                        onChangeComplete={(color) => {
                                                            setSelectedColor(color);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                    <hr></hr>
                                </Form>
                                </div>
                            </TabPane>
                            <TabPane tabId={2}>
                                <div>
                                    <Form>
                                        <Row>
                                            <Col lg={4}></Col>
                                            <Col lg={5}>
                                                {/* <Card className="" style={{ background: selectedColor, height: "300px", width: '300px', position: 'relative', border: '1px solid lightGray' }}>
                                                    <div className="position-relative">
                                                        <div className='float-right py-2'>
                                                            <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px' }} type="button" color="secendary" size="sm" className="btn-rounded waves-effect waves-light  float-right text-white p-2">
                                                                <strong style={{ color: selectedColor }}>{title}</strong>
                                                            </Button>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', bottom: '-140%', left: '50%', transform: 'translate(-50%, 50%)', }}>
                                                            <h5 className="text-white text-center">{description}</h5>
                                                        </div>
                                                        <div className="position-absolute bottom-0 start-0 p-2" style={{ left: '-8px', bottom: '-230px', width: '60px' }}>
                                                            <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', width: 'calc(100% + 9px)', textAlign: 'center', borderLeft: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center' }} type="button" color="secondary" size="sm" className="text-white">
                                                                <strong style={{ color: selectedColor }}><i className="fas fa-arrow-right" style={{ fontSize: '1.2em' }} ></i></strong>
                                                            </Button>
                                                        </div>

                                                        <div className="position-absolute bottom-0 end-0 p-2" style={{ right: '10px', bottom: '-230px' }}>
                                                            <img
                                                                src={cardPhoto ? cardPhoto._url : ""}
                                                                className="img-fluid mx-auto d-block" style={cardPhoto ? { width: "4.frem", height: "4.5rem" } : {}}
                                                                onClick={() => {
                                                                    // setImagePopup(true);
                                                                    // setphotoIndex(key)
                                                                }}
                                                                alt=""
                                                                width="80"
                                                            />
                                                        </div>
                                                    </div>
                                                </Card> */}

                                                <Card className="" style={{ background: selectedColor, height: "300px", width: '300px', position: 'relative', border: '1px solid lightGray' }}>
                                                    <div className="position-relative">
                                                        <div className='d-flex justify-content-end py-4'>
                                                            <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '20px', borderBottomLeftRadius: '20px', borderTopRightRadius: '0px', borderBottomRightRadius: '0px', border: 'none', }} type="button" color="secondary" size="sm" className="btn-rounded waves-effect waves-light  float-right text-white p-2">
                                                                <strong style={{ color: selectedColor }}>{title}</strong>
                                                            </Button>
                                                        </div>
                                                        <div className="d-flex align-items-center justify-content-center" style={{ position: 'absolute', left: '50%', bottom: '-40px', transform: 'translate(-50%, 50%)', }}>
                                                            <h6 className="text-white text-center">{description}</h6>
                                                        </div>
                                                        <div className="d-flex justify-content-start " style={{ position: 'absolute', left: '-8px', bottom: '-200px', width: '60px' }}>
                                                            <Button style={{ backgroundColor: 'white', borderTopLeftRadius: '0px', borderBottomLeftRadius: '0px', borderTopRightRadius: '20px', borderBottomRightRadius: '20px', width: 'calc(100% + 9px)', textAlign: 'center', border: 'none', display: 'flex', justifyContent: 'center', alignItems: 'center', border: 'none', }} type="button" color="secondary" size="sm" className="btn-rounded waves-effect waves-light  float-right text-white p-2">
                                                                <strong style={{ color: selectedColor }}><i className="fas fa-arrow-right" style={{ fontSize: '1.2em' }} ></i></strong>
                                                            </Button>
                                                        </div>
                                                        <div className="d-flex justify-content-end p-2" style={{ position: 'absolute', right: '10px', bottom: '-200px' }}>
                                                            <img
                                                                src={cardPhoto ? cardPhoto._url : ""}
                                                                className="img-fluid mx-auto d-block" style={cardPhoto ? { width: "4.frem", height: "4.5rem" } : {}}
                                                                onClick={() => {
                                                                    // setImagePopup(true);
                                                                    // setphotoIndex(key)
                                                                }}
                                                                alt=""
                                                                width="80"
                                                            />
                                                        </div>
                                                    </div>
                                                </Card>
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col>
                                                <div className='text-center py-4'>
                                                    <h5 className='text-center'>This card will be publish to {props.selectedSchool && props.selectedSchool.attributes.Name} , for {activeButton === "ALL" ? "for Directors, Centerhead , teachers and parents" : activeButton}.</h5>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </TabPane>
                            <TabPane tabId={3}>
                                <div className="ml-4" style={{ minHeight: '60vh' }}>
                                    <Form>
                                        <h4 className="mb-2">{props.t('Choose Your Content:')} </h4>
                                        <Row>
                                            <Col lg={7}>
                                                <div>
                                                    <Nav pills className="bg-light rounded justify-content-center" role="tablist">
                                                        {Content.map((val, key) => (
                                                            <NavItem key={key} className="mt-3  mb-3" style={{ marginRight: '130px' }}>
                                                                <NavLink
                                                                    className={classnames({ active: actTab === val })}
                                                                    onClick={() => {
                                                                        setActiveTraType(val);
                                                                        setactTab(val);
                                                                    }}
                                                                >
                                                                    {val}
                                                                </NavLink>
                                                            </NavItem>
                                                        ))}
                                                    </Nav>

                                                    {activeTraType === "VIDEO" && (
                                                        <Form>
                                                            <FormGroup className="mb-1 mt-3">
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <FormGroup>
                                                                            <Label >Title:<span className="text-danger">*</span></Label>
                                                                            <Input type="text"
                                                                                className="form-control"
                                                                                placeholder='Title'
                                                                                value={videoTitle}
                                                                                onChange={(e) => {
                                                                                    setVideoTitle(e.target.value)
                                                                                }} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <FormGroup>
                                                                            <Label >Description:<span className="text-danger">*</span></Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                rows="10"
                                                                                className="form-control"
                                                                                placeholder='Description/Content'
                                                                                value={videoDescription}
                                                                                onChange={(e) => {
                                                                                    setVideoDescription(e.target.value)
                                                                                }} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <FormGroup>
                                                                            <Label >Link/URL:<span className="text-danger">*</span></Label>
                                                                            <Input type="text"
                                                                                className="form-control"
                                                                                placeholder='URL: http://youtute.com'
                                                                                value={videoUrl}
                                                                                onChange={(e) => {
                                                                                    handleChange(e)
                                                                                }} />
                                                                            {isInvalidUrl ? (
                                                                                <p className="text-danger">Please enter a valid YouTube URL.</p>
                                                                            ) : null}                                                                    </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <small className='text-danger'>*Disclaimer: {WhiteLabel.getAppName()} is not liable for the ads or content found on above websites/Link.</small>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Col lg='12'>
                                                                    <div className="custom-checkbox ml-3 py-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            onChange={() => {
                                                                                setShowSelfDelete(!showSelfDelete);
                                                                            }}
                                                                            checked={showSelfDelete}
                                                                        />
                                                                        <label className="custom-control-label m-1" id="selfDelete" onClick={() => { setShowSelfDelete(!showSelfDelete) }}>
                                                                            Self Delete
                                                                        </label>
                                                                        <span>
                                                                            <i className="fas fa-question-circle ml-2" id="selfDelete"></i>
                                                                            <UncontrolledTooltip placement="top" target="selfDelete">
                                                                                On Enabling Only you can delete this card.
                                                                            </UncontrolledTooltip>
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Form>
                                                    )}
                                                    {activeTraType === "LINK" && (
                                                        <Form>
                                                            <FormGroup className="mb-1 mt-3">
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <FormGroup>
                                                                            <Label >Title:<span className="text-danger">*</span></Label>
                                                                            <Input type="text"
                                                                                className="form-control"
                                                                                placeholder='Title'
                                                                                value={linkTitle}
                                                                                onChange={(e) => {
                                                                                    setLinkTitle(e.target.value)
                                                                                }} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <FormGroup>
                                                                            <Label >Description:<span className="text-danger">*</span></Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                rows="10"
                                                                                className="form-control"
                                                                                placeholder='Description/Content'
                                                                                value={linkDescription}
                                                                                onChange={(e) => {
                                                                                    setLinkDescription(e.target.value)
                                                                                }} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <FormGroup>
                                                                            <Label >Link/URL:<span className="text-danger">*</span></Label>
                                                                            <Input type="text"
                                                                                className="form-control"
                                                                                placeholder='URL: http://story.com'
                                                                                value={linkUrl}
                                                                                onChange={(e) => {
                                                                                    setLinkUrl(e.target.value)
                                                                                }} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <small className='text-danger'>*Disclaimer: {WhiteLabel.getAppName()} is not liable for the ads or content found on above websites/Link.</small>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="popup-gallery">
                                                                            <div className="img-fluid float-left">
                                                                                <Row>
                                                                                    <Col>
                                                                                        <img
                                                                                            src={textPhoto ? textPhoto._url : ""}
                                                                                            onClick={() => {
                                                                                                // setImagePopup(true);
                                                                                                // setphotoIndex(key)
                                                                                            }}
                                                                                            alt=""
                                                                                            width="50"
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <h5>{textPhoto ? textPhoto._name : ''}</h5>
                                                                                    </Col>
                                                                                    <Col className='float-right'>
                                                                                        {textPhoto && textPhoto._url && <h5><i className="bx bxs-trash  text-danger" onClick={() => { setTextPhoto(undefined) }} style={{ cursor: 'pointer' }}></i></h5>}
                                                                                    </Col>
                                                                                </Row>

                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                               {
                                                                    showTextPhotoUploadProgress() && (
                                                                        <div className="d-flex">
                                                                            <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                                                <div className="border p-3">
                                                                                    <Row>
                                                                                        <div
                                                                                            className="avatar ml-3"
                                                                                            style={{ width: "5rem", height: "5rem" }}
                                                                                        >
                                                                                            <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                                                <i
                                                                                                    className={getFileTypeImage(textPhoto)}
                                                                                                ></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="float-right ml-4"
                                                                                            style={{ width: "200px" }}
                                                                                        >
                                                                                            <div className="text-muted font-weight-bold">
                                                                                                {textPhoto}
                                                                                            </div>
                                                                                            <div className=" mt-3">
                                                                                                <Progress
                                                                                                    color="primary"
                                                                                                    value={attachmentUploadProgress}
                                                                                                ></Progress>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </div>
                                                                    )}

                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup className="mt-2 mb-2 p-1">
                                                                            
                                                                            <Label
                                                                                htmlFor={"input-photo-expense"}
                                                                                color="primary"
                                                                                className="btn btn-primary "
                                                                            >
                                                                                Add Preview image
                                                                                <input
                                                                                    id={"input-photo-expense"}
                                                                                    style={inputStyle}
                                                                                    type="file"
                                                                                    onChange={(e) => {
                                                                                        handleTextPhotoUploadChange(e);
                                                                                    }}
                                                                                    accept="image/*"
                                                                                />
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Col lg='12'>
                                                                    <div className="custom-checkbox ml-3 py-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            onChange={() => {
                                                                                setShowSelfDelete(!showSelfDelete);
                                                                            }}
                                                                            checked={showSelfDelete}
                                                                        />
                                                                        <label className="custom-control-label m-1" id="selfDelete" onClick={() => { setShowSelfDelete(!showSelfDelete) }}>
                                                                            Self Delete
                                                                        </label>
                                                                        <span>
                                                                            <i className="fas fa-question-circle ml-2" id="selfDelete"></i>
                                                                            <UncontrolledTooltip placement="top" target="selfDelete">
                                                                                On Enabling Only you can delete this card.
                                                                            </UncontrolledTooltip>
                                                                        </span>
                                                                    </div>
                                                                </Col>
                                                            </FormGroup>
                                                        </Form>
                                                    )}
                                                    {activeTraType === "TEXT" && (
                                                        <Form>
                                                            <FormGroup className="mb-1 mt-3">
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <FormGroup>
                                                                            <Label >Title:<span className="text-danger">*</span></Label>
                                                                            <Input type="text"
                                                                                className="form-control"
                                                                                placeholder='Title'
                                                                                value={textTitle}
                                                                                onChange={(e) => {
                                                                                    setTextTitle(e.target.value)
                                                                                }} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col lg="12">
                                                                        <FormGroup>
                                                                            <Label >Description:<span className="text-danger">*</span></Label>
                                                                            <Input
                                                                                type="textarea"
                                                                                rows="10"
                                                                                className="form-control"
                                                                                placeholder='Description/Content'
                                                                                value={textDescription}
                                                                                onChange={(e) => {
                                                                                    setTextDescription(e.target.value)
                                                                                }} />
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>

                                                                <Row>
                                                                    <Col>
                                                                        <div className="popup-gallery">
                                                                            <div className="img-fluid float-left">
                                                                                {albumImageList && albumImageList.map((img, key) => (
                                                                                    <Row className='m-2' key={key}>
                                                                                        <Col>
                                                                                            <img
                                                                                                src={img.attributes.image ? img.attributes.image._url : ""}
                                                                                                onClick={() => {
                                                                                                    // setImagePopup(true);
                                                                                                    // setphotoIndex(key)
                                                                                                }}
                                                                                                alt=""
                                                                                                width="50"
                                                                                            />
                                                                                        </Col>
                                                                                        <Col>
                                                                                            <h5>{img.attributes.image ? img.attributes.image._name : ''}</h5>
                                                                                        </Col>
                                                                                        <Col className='d-flex justify-content-end'>
                                                                                            {img.attributes.image && img.attributes.image._url && (
                                                                                                <h5>
                                                                                                    <i
                                                                                                        className="bx bxs-trash text-danger"
                                                                                                        onClick={() => { 
                                                                                                            let list = [...albumImageList];
                                                                                                            console.log(list)
                                                                                                            list.splice(key, 1);
                                                                                                            console.log(list)
                                                                                                            setAlbumImageList(list)

                                                                                                        }}
                                                                                                        style={{ cursor: 'pointer' }}
                                                                                                    ></i>
                                                                                                </h5>
                                                                                            )}
                                                                                        </Col>
                                                                                    </Row>
                                                                                ))}
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="popup-gallery">
                                                                            <div className="img-fluid float-left">
                                                                                <Row>
                                                                                    <Col>
                                                                                        <img
                                                                                            src={textDocs ? textDocs._url : ""}
                                                                                            onClick={() => {
                                                                                                // setImagePopup(true);
                                                                                                // setphotoIndex(key)
                                                                                            }}
                                                                                            alt=""
                                                                                            width="50"
                                                                                        />
                                                                                    </Col>
                                                                                    <Col>
                                                                                        <h5>{textDocs ? textDocs._name : ''}</h5>
                                                                                    </Col>
                                                                                    <Col className='d-flex justify-content-end'>
                                                                                        {textDocs && textDocs._url && <h5><i className="bx bxs-trash  text-danger" onClick={() => { setTextDocs(undefined) }} style={{ cursor: 'pointer' }}></i></h5>}
                                                                                    </Col>
                                                                                </Row>

                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row>

                                                                {
                                                                    showTextPhotoUploadProgress() && (
                                                                        <div className="d-flex">
                                                                            <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                                                <div className="border p-3">
                                                                                    <Row>
                                                                                        <div
                                                                                            className="avatar ml-3"
                                                                                            style={{ width: "5rem", height: "5rem" }}
                                                                                        >
                                                                                            <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                                                <i
                                                                                                    className={getFileTypeImage(textPhoto)}
                                                                                                ></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="float-right ml-4"
                                                                                            style={{ width: "200px" }}
                                                                                        >
                                                                                            <div className="text-muted font-weight-bold">
                                                                                                {textPhoto}
                                                                                            </div>
                                                                                            <div className=" mt-3">
                                                                                                <Progress
                                                                                                    color="primary"
                                                                                                    value={attachmentUploadProgress}
                                                                                                ></Progress>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </div>
                                                                    )}
                                                                {
                                                                    showTextDocsUploadProgress() && (
                                                                        <div className="d-flex">
                                                                            <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                                                <div className="border p-3">
                                                                                    <Row>
                                                                                        <div
                                                                                            className="avatar ml-3"
                                                                                            style={{ width: "5rem", height: "5rem" }}
                                                                                        >
                                                                                            <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                                                <i
                                                                                                    className={getFileTypeImage(textDocs)}
                                                                                                ></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="float-right ml-4"
                                                                                            style={{ width: "200px" }}
                                                                                        >
                                                                                            <div className="text-muted font-weight-bold">
                                                                                                {textDocs}
                                                                                            </div>
                                                                                            <div className=" mt-3">
                                                                                                <Progress
                                                                                                    color="primary"
                                                                                                    value={textDocsUploadProgress}
                                                                                                ></Progress>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </div>
                                                                    )}
                                                                <div className='text-center' style={{ height: "30px" }}>
                                                                    {progressText ? <label style={{ fontSize: "3mm" }} className=" mb-1">{progressText}<i className='align-middle font-size-16 ml-2 text-dark bx bx-loader bx-spin'></i></label> :
                                                                        <>
                                                                            {albumImageList && albumImageList.length > 0 &&
                                                                                <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                                                                                    {`${albumImageList.length} images attached`}
                                                                                    <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                                                                                        setAlbumImageList([])
                                                                                    }}></i>
                                                                                </div>
                                                                            }
                                                                        </>}
                                                                </div>
                                                                <Row>
                                                                    <Col>
                                                                        <FormGroup className="mt-2 mb-2 p-1">
                                                                            <Label
                                                                                htmlFor={"input-file-expense"}
                                                                                color="primary"
                                                                                className="btn btn-primary m-2"
                                                                            >
                                                                                Attach Document
                                                                                <input
                                                                                    id={"input-file-expense"}
                                                                                    style={inputStyle}
                                                                                    type="file"
                                                                                    onChange={(e) => {
                                                                                        handleTextDocsUploadChange(e);
                                                                                    }}
                                                                                    accept=".doc,.docx,.pdf,.xlsx"
                                                                                />
                                                                            </Label>
                                                                            <Label
                                                                                htmlFor={"input-photo-blog"}
                                                                                color="primary"
                                                                                className="btn btn-primary m-2"
                                                                            >
                                                                                Attach Photo
                                                                                <input type="file"
                                                                                    name="upload-images-card"
                                                                                    className="sr-only"
                                                                                    id="input-photo-blog"
                                                                                    accept="image/*"
                                                                                    onChange={(e) => {
                                                                                        // handleTextPhotoUploadChange(e);
                                                                                        handleImageUploadChange(e)
                                                                                    }}
                                                                                    multiple
                                                                                />
                                                                            </Label>
                                                                        </FormGroup>
                                                                    </Col>
                                                                </Row>
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Col lg='12'>
                                                                    <div className="custom-checkbox ml-3 py-2">
                                                                        <input
                                                                            type="checkbox"
                                                                            className="custom-control-input"
                                                                            onChange={() => {
                                                                                setShowSelfDelete(!showSelfDelete);
                                                                            }}
                                                                            checked={showSelfDelete}
                                                                        />
                                                                        <label className="custom-control-label m-1" id="selfDelete" onClick={() => { setShowSelfDelete(!showSelfDelete) }}>
                                                                            Self Delete
                                                                        </label>
                                                                        <span>
                                                                            <i className="fas fa-question-circle ml-2" id="selfDelete"></i>
                                                                            <UncontrolledTooltip placement="top" target="selfDelete">
                                                                                On Enabling Only you can delete this card.
                                                                            </UncontrolledTooltip>
                                                                        </span>
                                                                    </div>

                                                                </Col>
                                                            </FormGroup>
                                                        </Form>
                                                    )}
                                                </div>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </TabPane>

                        </TabContent>

                        <Element name="toCreate">
                            <ul className="pager wizard twitter-bs-wizard-pager-link">
                                <li className={activeTab === 1 ? "previous disabled" : "previous"}><button
                                    className="btn btn-primary"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {

                                        if (activeTab !== 2) {
                                            toggleTab(activeTab - 1);
                                        } else {
                                            toggleTab(activeTab - 1);
                                        }

                                    }
                                    }>Previous</button></li>
                                <li className={activeTab === 3 ? "next " : "next"}><button
                                    className="btn btn-primary"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        if (activeTab === 3) {
                                            handleSave();
                                        } else {
                                            if (activeTab === 1 || activeTab === 2) {
                                                switchTab(activeTab + 1)
                                            } else {
                                                toggleTab(activeTab + 1);
                                            }
                                        }
                                    }
                                    }>{activeTab === 3 ? "Save " : "Next"}</button></li>
                            </ul>


                        </Element>

                    </div>
                </CardBody>
            </Card>
        </div>
    )
}


const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { userTeacher } = state.Login;
    return { selectedSchool, schools, userTeacher };
}

export default withRouter(connect(mapStatetoProps, { setSelecetdSchool })(withNamespaces()(addCard)));

