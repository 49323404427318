
import React from 'react';
import { Row, Col, Form, Button, ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';

const Topbar = (props) => {


    return (
        <React.Fragment>
            <Row className="d-flex align-items-start" >
                <Col >
                    <div>
                        {
                            (props.backButton) ?
                                <div className="d-flex align-items-start">

                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { props.onClickBackButton(); }}>
                                        <ul className="list-unstyled">
                                            <div >
                                                <i className="bx bx-chevron-left h1 text-primary"></i>
                                            </div>
                                        </ul>
                                    </div>


                                    <h4 className="mt-2">{props.title}</h4>
                                </div>
                                :
                                <h4 className="m-2">{props.title}</h4>
                        }

                    </div>
                </Col>
                <Col>
                    {!props.hideButton &&
                        <div className="d-flex justify-content-end" >
                            {props.reorderButton && <Form className=" mt-sm-0 float-sm-right form-inline">
                                <Button onClick={() => props.onClickReorder()} type="button" color="success" className="btn waves-effect waves-light  mb-2 m-1"><i className="bx bxs-analyse mr-1"></i> {"Reorder"}</Button>
                            </Form>}
                            {props.hidePublish && <Form >
                                <Link to={props.learningSession}>
                                    <Button type="button" color="success" className="btn waves-effect waves-light mb-2 m-1"> Published History</Button>
                                </Link>
                            </Form>}
                            <Form className=" mt-sm-0 float-sm-right form-inline">
                                <Button onClick={() => props.onClickAdd()} type="button" color="success" className="btn waves-effect waves-light mb-2  m-1"><i className="mdi mdi-plus mr-1"></i>{props.buttonName}</Button>
                            </Form>
                        </div>
                    }
                </Col>
            </Row>
        </React.Fragment>
    );
}
export default withNamespaces()(Topbar);
