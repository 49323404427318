import Parse from "parse";
import { printLog } from "./loghelper";
export const getFranchiseFromParse = (id,isLocal) => {
    printLog("HL: franchise_helper : getFranchiseFromParse")

    try {
        return new Promise((resolve, reject) => {
            var Franchise = Parse.Object.extend("Franchise");
            var query = new Parse.Query(Franchise);
            if (isLocal) {
                query.fromLocalDatastore();
            }
            query.get(id).then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(null);
                }
            )
        }
        )
    } catch (error) {}
}