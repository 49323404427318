export const ADD_COURSE_BATCH = "ADD_COURSE_BATCH";
export const DELETE_COURSE_BATCH = "DELETE_COURSE_BATCH";

export const UPDATE_COURSES_BATCH = "UPDATE_COURSES_BATCH";
export const GET_COURSE_BATCH_FOR_COURSE_ID = "GET_COURSE_BATCH_FOR_COURSE_ID";

export const GET_COURSE_BATCH = "GET_COURSE_BATCH";
export const SELECTED_COURSE_BATCH = "SELECTED_COURSE_BATCH";

export const UPDATE_COURSE_BATCH_INITIAL_LOADING = "UPDATE_COURSE_BATCH_INITIAL_LOADING";

export const ADD_COURSE_BATCH_LOADING = "ADD_COURSE_BATCH_LOADING";

export const GET_COURSE_BATCH_FOR_COURSE_IDS = "GET_COURSE_BATCH_FOR_COURSE_IDS";
export const UPDATE_COURSE_BATCH_FOR_COURSE_IDS = "UPDATE_COURSE_BATCH_FOR_COURSE_IDS";