import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
    learningCurriculumsSchoolMapForCurriculumId: [], loding: undefined, learningCurriculumsSchoolMapForSchoolId: [],
    learningCurriculumsSchoolMapForCurriculumIdAndSchoolId: undefined,
};

const LearningCurriculumsSchoolMap = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_LEARNING_CURRICULUMS_SCHOOL_MAP_FOR_CURRICULUM_ID:
            var learningCurriculumsSchoolMapForCurriculumId = [...state.learningCurriculumsSchoolMapForCurriculumId];
            if (learningCurriculumsSchoolMapForCurriculumId.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.learningCurriculumsSchoolMapForCurriculumId], action.payload.learningCurriculumsSchoolMapForCurriculumId, "isDeleted")

                state = { ...state, learningCurriculumsSchoolMapForCurriculumId: updatedList };
            } else {
                var i = 0
                var learningCurriculumsToSave = []
                for (i = 0; i < action.payload.learningCurriculumsSchoolMapForCurriculumId.length; i++) {
                    if (action.payload.learningCurriculumsSchoolMapForCurriculumId[i].attributes.isDeleted !== true) {
                        learningCurriculumsToSave.unshift(action.payload.learningCurriculumsSchoolMapForCurriculumId[i])
                    }
                }
                state = { ...state, learningCurriculumsSchoolMapForCurriculumId: learningCurriculumsToSave };
            }
            break;

        case actions.UPDATE_LEARNING_CURRICULUMS_SCHOOL_MAP_FOR_SCHOOL_ID:
            var learningCurriculumsSchoolMapForSchoolId = [...state.learningCurriculumsSchoolMapForSchoolId];
            if (learningCurriculumsSchoolMapForSchoolId.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.learningCurriculumsSchoolMapForSchoolId], action.payload.learningCurriculumsSchoolMapForSchoolId, "isDeleted")

                state = { ...state, learningCurriculumsSchoolMapForSchoolId: updatedList };
            } else {
                var i = 0
                var learningCurriculumsToSave = []
                for (i = 0; i < action.payload.learningCurriculumsSchoolMapForSchoolId.length; i++) {
                    if (action.payload.learningCurriculumsSchoolMapForSchoolId[i].attributes.isDeleted !== true) {
                        learningCurriculumsToSave.push(action.payload.learningCurriculumsSchoolMapForSchoolId[i])
                    }
                }
                state = { ...state, learningCurriculumsSchoolMapForSchoolId: learningCurriculumsToSave };
            }
            break;

        case actions.UPDATE_LEARNING_CURRICULUMS_SCHOOL_MAP_FOR_CURRICULUM_ID_AND_SCHOOL_ID:
                state = { ...state, learningCurriculumsSchoolMapForCurriculumIdAndSchoolId: action.payload.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId };
            break;

        case actions.CLEAR_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_CURRICULUM_ID:
            state = { ...state, learningCurriculumsSchoolMapForCurriculumId: [] }
            break;

        case actions.GET_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_SCHOOL_ID:
            state = { ...state, learningCurriculumsSchoolMapForSchoolId: [] }
            break;

        case actions.GET_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_CURRICULUM_ID_AND_SCHOOL_ID:
            state = { ...state, learningCurriculumsSchoolMapForCurriculumIdAndSchoolId: undefined }
            break;

        default:
            state = { ...state };
            break;

    }
    return state;
};

export default LearningCurriculumsSchoolMap;
