import React, { useState, useEffect } from 'react'
import { Row, Col, Container, Input, Button, NavItem, Nav, NavLink, Card, CardBody } from "reactstrap";
import Topbar from './Topbar';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool } from '../../store/actions';

const index = (props) => {

  const match = matchPath(props.history.location.pathname, {
    path: "/inventory-management/:id",
    exact: true,
    strict: false,
  });

  const franchiseMatch = matchPath(props.history.location.pathname, {
    path: "/franchise-inventory-management/:franchiseId",
    exact: true,
    strict: false,
  });

  const schoolId = match && match.params.id;
  const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  return (
    <div className="page-content">
      <Row >
        <Col>
          <h4 className="mt-2 text-dark">INVENTORY DASHBOARD</h4>
        </Col>
        {<Col className='float-right'>
          <Link to={schoolId ? `/inventory-report/${schoolId}/` : `/franchise-inventory-report/${franchiseId}/`}>
            <Button
              color="primary"
              style={{ float: "right" }}
              className="btn waves-effect waves-light float-right  m-2"
            >
              Inventory Reports
            </Button>
          </Link>
        </Col>}
      </Row>
      <section >
        <div className="py-3 ">
          <Container>
            <Row>
              <Col>
                <h5>Inventory Activities</h5>
              </Col>
              <Col>
                {match && <Button
                  type="button"
                  color="primary"
                  className="btn waves-effect waves-light  float-right"
                  onClick={() => { props.history.push("/inventory-products/" + schoolId) }}
                >
                  Products
                </Button>}
              </Col>
            </Row>
            <hr />
            {franchiseMatch && <Row className="checkout-tabs">
              <Col>
                <Nav className="d-flex flex-row" pills>
                  <Col >
                    <Card>
                      <CardBody>
                        <NavItem>
                          <NavLink
                            onClick={() => {
                              props.history.push(schoolId ? `/sale-order/${schoolId} ` : `/franchise-sale-order/${franchiseId}`);
                            }}
                          >
                            <i className="bx bxs-shopping-bag d-block check-nav-icon mt-4 mb-2 "></i>
                            <p className="font-weight-bold mb-4">
                              Sale Orders
                            </p>
                          </NavLink>
                        </NavItem>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col >
                    <Card>
                      <CardBody>
                        <NavItem>
                          <NavLink
                            onClick={() => {
                              props.history.push(schoolId ? `/purchase-order/${schoolId} ` : `/franchise-purchase-order/${franchiseId}`);
                            }}
                          >
                            <i className="bx bxs-cart d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="font-weight-bold mb-4">
                              Purchase Orders
                            </p>
                          </NavLink>
                        </NavItem>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col >
                    <Card>
                      <CardBody>
                        <NavItem>
                          <NavLink
                            onClick={() => {
                              props.history.push(schoolId ? `/inventory-items/${schoolId} ` : `/franchise-inventory-items/${franchiseId}`);
                            }}
                          >
                            <i className="bx bxs-store d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="font-weight-bold mb-4">
                              Items
                            </p>
                          </NavLink>
                        </NavItem>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col >
                    <Card>
                      <CardBody>
                        <NavItem>
                          <NavLink
                            onClick={() => {
                              props.history.push(schoolId ? `/inventory-item-group/${schoolId} ` : `/franchise-inventory-item-group/${franchiseId}`);
                            }}
                          >
                            <i className="bx bxs-store-alt d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="font-weight-bold mb-4">
                              Kits
                            </p>
                          </NavLink>
                        </NavItem>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col >
                    <Card >
                      <CardBody>
                        <NavItem>
                          <NavLink
                            onClick={() => {
                              props.history.push(schoolId ? `/inventory-vendor/${schoolId} ` : `/franchise-inventory-vendor/${franchiseId}`);
                            }}
                          >
                            <i className="bx bxs-user-detail d-block check-nav-icon mt-4 mb-2"></i>
                            <p className="font-weight-bold mb-4">
                              Vendor
                            </p>
                          </NavLink>
                        </NavItem>
                      </CardBody>
                    </Card>
                  </Col>
                </Nav>
              </Col>
            </Row>
            }
          </Container>
        </div>
      </section>
    </div>
  )
}

const mapStatetoProps = state => {
  const { selectedSchool } = state.School;
  return { selectedSchool };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool })(index)));
