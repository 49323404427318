export const UPDATE_INVENTORY_VENDOR = "UPDATE_INVENTORY_VENDOR";
export const DELETE_INVENTORY_VENDOR = "DELETE_INVENTORY_VENDOR";
export const UPDATE_DELETE_INVENTORY_VENDOR = "UPDATE_DELETE_INVENTORY_VENDOR";
export const UPDATE_INVENTORY_VENDORS = "UPDATE_INVENTORY_VENDORS";
export const GET_INVENTORY_VENDOR = "GET_INVENTORY_VENDOR";
export const GET_INVENTORY_VENDORS = "GET_INVENTORY_VENDORS";
export const EDIT_INVENTORY_VENDOR = "EDIT_INVENTORY_VENDOR";
export const SET_INVENTORY_VENDOR = "SET_INVENTORY_VENDOR";
export const ADD_INVENTORY_VENDOR = "ADD_INVENTORY_VENDOR";
export const SET_SELECTED_VENDOR = "SET_SELECTED_VENDOR";
export const UPDATE_SELECTED_VENDOR = "UPDATE_SELECTED_VENDOR";
