import React,{useState,useEffect} from 'react';
import { Container } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import SchoolList from './SchoolList';
import SchoolStatistics from './SchoolStatistics'
import TopBar from './Topbar';
import {  getUserTeacher } from '../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import *as Constents from '../../Constents'

const Franchise = (props) => {

    const [selectedTab, setSelectedTab] = useState(0);
    const [selectedTabTitle, setSelectedTabTitle] = useState("ALL BRANCHES");
    const [schoolCount, setSchoolCount] = useState(0);
    const [searchString, setSearchString] = useState(undefined);

    const [isSupervisor, setIsSupervisor] = useState(false);

    const handelSchoolCount = (count) => {
        setSchoolCount(count)
    }

    useEffect(() => {
        if (props.userTeacher) {
            if (props.userTeacher.attributes.Role == Constents.FRANCHISE_STAFF_SUPERVISOR) {
                setIsSupervisor(true);
            }
        } else {
            if (Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.teacherId) {
                props.getUserTeacher(Parse.User.current().attributes.teacherId)
            }
        }
    }, [props.userTeacher]);

    const handelSearch = (str) => {
        setSearchString(str)
    }

    const handelOnSelctTab = (tab) => {
        setSelectedTab(tab)
        if (tab == 1) {
            setSelectedTabTitle("BRANCH ANALYTICS")
        } else {
            setSelectedTabTitle("ALL BRANCHES")
        }
    }
    
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <TopBar title={schoolCount != null ? selectedTabTitle + " (" + schoolCount + ")" : "(0)"} search={true} navBar={!isSupervisor} onSearch={handelSearch} onSelctTab={handelOnSelctTab} />
                    {selectedTab == 1 ? <SchoolStatistics handelSchoolCount={handelSchoolCount} searchString={searchString} /> :
                        <SchoolList handelSchoolCount={handelSchoolCount} searchString={searchString} isSupervisor={isSupervisor} />
                    }
                </Container>
            </div>
        </React.Fragment>
    );
    
}
  
const mapStatetoProps = state => {
  const { schools, schoolAnalytics } = state.School;
  const { franchise } = state.Franchise;
  const { userTeacher } = state.Login;
  return {
    schools, schoolAnalytics,
    franchise,
    userTeacher,
  };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps, { getUserTeacher})(Franchise))); 
