import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Label,
  FormGroup,
  Button,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import toastr from "toastr";

const DiaryMenuItems = (props) => {

  


  const [editMenuItemObject, setEditMenuItemObject] = useState(undefined)
  const [editedItems, setEditedItems] = useState(undefined)
  const [editedItemName, setEditedItemName] = useState(undefined)


  const [isAddNewItemsViewOn, setIsAddNewItemsViewOn] = useState(false)
  const [creatingQuestion, setCreatingQuestion] = useState(false);
  const [isAddNewMenuItemViewOn, setIsAddNewMenuItemViewOn] = useState(false)
  const [selectedMenuItemListToRemove, setSelectedMenuItemListToRemove] = useState(undefined)

  const resetVariables = () => {
    setEditMenuItemObject(undefined);
    setEditedItems(undefined);
    setEditedItemName(undefined);

  };




  function getEditViewUI(isAddNewMenuItem, menuItem, keyOuter) {

    let menuItems = menuItem && menuItem.items

    return (
      <Row
        key={menuItem && menuItem.id ? menuItem.id : ""}
        className="border-bottom m-3"
      >

        <Col lg="8" md="8" sm="12" className="m-3 ">
          {isAddNewMenuItemViewOn === true ?
            getItemEditView(true)

            :

            getItemEditView(false, menuItem)}

        </Col>



        <Col lg="4" md="4" sm="12" className="mt-2 mb-2">
          <Row>
            {!editMenuItemObject && (
              <ul className="list-inline font-size-20 contact-links  mb-0 m-2">
                <li className="list-inline-item px-2"></li>
              </ul>
            )}
            <Col>



              <Button
                type="button"
                color="danger"
                size="sm"
                onClick={() => {
                  setEditMenuItemObject(undefined);
                  setIsAddNewMenuItemViewOn(false);
                }}
                className="btn-rounded waves-effect waves-light m-1 float-right "
              >
                {props.t("Cancel")}
              </Button>

              <Button
                onClick={(e) => {
                  let canProceed = true;

                  if (canProceed) {
                    if (isAddNewMenuItem) {
                      handleAddNewMenuItem();
                    } else {
                      handleEditMenuItem();
                      setEditMenuItemObject(undefined);

                    }
                  }
                }}
                type="button"
                color="primary"
                size="sm"
                disabled={creatingQuestion}
                className="btn-rounded waves-effect waves-light m-2 float-right"
              >

                {props.t("Save")}
              </Button>






            </Col>
          </Row>
        </Col>
      </Row>
    );
  }


  function handleEditMenuItem() {

    props.onEditMenuItems(editMenuItemObject, props.sectionObject, editedItems, props.isFoodType, props.outerSectionKey);
    setEditMenuItemObject(undefined);
    setEditedItems([]);

  }


  const handleAddNewMenuItem = () => {
    toastr.warning("Please wait adding list item...")

    props.onAddNewMenuItems(props.sectionObject, editedItems, props.isFoodType, props.outerSectionKey);
    setEditMenuItemObject(undefined);
    setIsAddNewMenuItemViewOn(false);
    setEditedItems([]);

  };

  const getItemEditView = (isAddNew, menuItem) => {

    let menuItems = menuItem && menuItem.items

    return (
      <div>
        <AvForm className="outer-repeater m-3"
          onValidSubmit={(e, v) => {

          }}
        >
          <div data-repeater-item className="outer m-3">
            {false && <FormGroup>
              <Label htmlFor="ItemListLabel">{props.t("List Item Name")}: </Label>
              <AvInput
                type="text"
                name={"editMenuItemName"}

                placeholder={props.t("Enter list item name...")}
                className="form-control"
                value={editedItemName}
                required
                validate={{
                  required: {
                    value: true,
                    errorMessage: props.t("List Item Name can not be empty"),
                  },
                }}

              />

            </FormGroup>}






            {(
              <FormGroup>
                <h5
                  className=" font-size-14 m-0"
                  style={{ whiteSpace: "pre-wrap" }}
                >
                  {editedItemName}

                </h5>
                <Label htmlFor="ItemListLabel">{props.t("Items")}: </Label>
                {editedItems && editedItems.map((item, key) => (
                  <Badge
                    key={key}
                    className={"badge badge-soft-primary font-size-12 m-2"}
                  >
                    {item}

                    <i
                      className="mdi mdi-window-close ml-1"
                      id={item}
                      onClick={(e) => {
                        var others = editedItems.filter(
                          (item) => item !== e.target.id
                        );
                        setEditedItems(others);
                      }}
                    >
                      {" "}
                    </i>
                  </Badge>
                ))}
                {!isAddNewItemsViewOn && <Badge
                  className={"badge badge-soft-success font-size-12 m-2"}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    setIsAddNewItemsViewOn(true);
                  }}
                >
                  {props.t("Add")} <i className="bx bx-plus ml-1"> </i>
                </Badge>}



              </FormGroup>
            )}

            {isAddNewItemsViewOn && (
              <FormGroup className="col-12">
                <Row>
                  <Col lg={8}>
                    <AvInput
                      type="text"
                      name="newTag"
                      id="newTagTextQ"
                      className="inner form-control m-1"
                      placeholder="Enter items"
                    />

                  </Col>


                  <button
                    type="button"
                    className="btn btn-success btn-sm m-1"
                    style={{ width: "34px", height: "34px" }}
                    onClick={() => {

                      let item = document.getElementById("newTagTextQ").value;

                      if (item && item.length > 0) {
                        var temp = editedItems ? [...editedItems] : [];
                        var res = item.split(",");
                        var newList = temp.concat(res);
                        if (newList.length > 0) {
                        }
                        setEditedItems(newList);
                        setIsAddNewItemsViewOn(false);
                      }


                    }}
                  >
                    <i className="fa fa-check"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm m-1"
                    style={{ width: "34px", height: "34px" }}
                    onClick={() => {
                      setIsAddNewItemsViewOn(false);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>

                </Row>

              </FormGroup>
            )}
          </div>
        </AvForm>
      </div>
    );
  }

  function handleDeleteAdditionalDiaryItem(menuItem, sectionObject) {

    props.onRemoveMenuItems(menuItem, sectionObject, props.outerSectionKey)
  }


  const shouldShowDeleteIcon = (selectedItemListToDelete, menuItem) => {

    let returnValue = true

    if (selectedItemListToDelete === menuItem.id && props.deletingMenuItemList) {
      returnValue = false
    }




    return returnValue
  }


  const getMenuItemsUI = (menuItem, sectionObject, key) => {

    return (

      <Row key={key + menuItem.id} className="border-bottom ml-4">

        <Col lg="8" md="8" sm="12" className="mt-3 mb-2">

          <h5
            className=" font-size-14 m-0"
            style={{ whiteSpace: "pre-wrap" }}
          >
            <Link to="#" className="text-dark">
              {menuItem.name}
            </Link>
          </h5>

          {
            (
              <FormGroup>
                <Label>{props.t("Items")}: </Label>
                {menuItem.items &&
                  menuItem.items.map((item, itemKey) => (
                    <Badge
                      key={itemKey}
                      className={
                        "badge badge-soft-primary font-size-12 m-1"
                      }
                    >
                      {item}
                    </Badge>
                  ))}
              </FormGroup>
            )
          }

        </Col>

        <Col lg="4" md="4" sm="12" className="mt-2 mb-2">
          <Row>
            {!editMenuItemObject && (
              <ul className="list-inline font-size-20 contact-links  mb-0 m-2">
                <li className="list-inline-item px-2"></li>
              </ul>
            )}
            <Col>
              {!editMenuItemObject && <ul className="list-inline font-size-18 contact-links float-right " style={{ marginBottom: '20px'}}>


                <li
                  className="list-inline-item px-2"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    if (menuItem.parseObject) {
                      setEditMenuItemObject(menuItem);
                      setEditedItemName(menuItem.name);
                      setEditedItems(menuItem.items)
                    } else {
                      toastr.warning("Please enable this category to Edit !!")
                    }

                  }}
                  id={"edit" + menuItem.id}
                >
                  <i className="bx bx-edit-alt"></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={"edit" + menuItem.id}
                  >
                    {props.t("Edit")}
                  </UncontrolledTooltip>
                </li>
                <li
                  className="list-inline-item px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (props.isFoodType && !props.deletingMenuItemList) {
                      if (menuItem.parseObject) {
                        handleDeleteAdditionalDiaryItem(menuItem, sectionObject)
                        setSelectedMenuItemListToRemove(menuItem.id)
                      } else {
                        toastr.warning("Please enable this category to remove this List !!")
                      }
                    }
                  }}
                  disabled={creatingQuestion}
                  id={"delete" + menuItem.id}
                >
                  {props.isFoodType && shouldShowDeleteIcon(selectedMenuItemListToRemove, menuItem) && <i className="bx bx-trash text-danger"></i>}
                  {props.deletingMenuItemList && selectedMenuItemListToRemove && menuItem.id === selectedMenuItemListToRemove && (
                    <i className="bx bx-loader bx-spin text-danger"></i>
                  )}
                  <UncontrolledTooltip
                    placement="top"
                    target={"delete" + menuItem.id}
                  >
                    {props.t("Delete")}
                  </UncontrolledTooltip>
                </li>
              </ul>}
            </Col>
          </Row>
        </Col>
      </Row>
    )
  }

  const getHelpText = (type) => {
    let returnValue = ""

    //Duration - You can select how much time kid is slept, like 30 mins, 1 hour, 1 hour 30 mins

    if (type === 20) {
      returnValue = "Start time - Endtime - Use a range bar to select when the child started to sleep and when he woke up. You can record this at your comfort."
    } else if (type === 21) {
      returnValue = "Live Notifications - you can use this option when you want to update parent live about when their child slept and woke up, care taker/teacher should record at real time to use this option."
    } else if (type === 17) {
      returnValue = "Observation - Caretaker/Teacher can capture their observations here with free text."
    }

    return returnValue
  }




  return (
    <React.Fragment>


      {props.menuItems && !props.showHelpText && props.menuItems.map((menuItem, key) => (

        <React.Fragment key={key}> {editMenuItemObject &&
          menuItem.id === editMenuItemObject.id ? (
          <div>{getEditViewUI(false, menuItem, key)}</div>
        ) : (
          <div>
            {getMenuItemsUI(menuItem, props.sectionObject, key)}
          </div>
        )}</React.Fragment>
      ))
      }
      {
        props.showHelpText &&
        <Row

          className="border-bottom justify-content-center bg-white"
        >
          <Col lg={12} className="mt-3 ml-5 mr-4 mb-3">
            <h5
              className=" font-size-14"
            >
              {getHelpText(props.sectionObject.type)}
            </h5>
          </Col>
        </Row>
      }


      {
        !isAddNewMenuItemViewOn && props.showAddMenuItemsButton &&
        <Row className="border-bottom justify-content-center bg-white">
          <Col lg={12}>
            <div className="mr-2">
              <button
                onClick={() => {
                  if (props.sectionObject && props.sectionObject.parseObject) {
                    if (isAddNewMenuItemViewOn === false) {
                      resetVariables();
                      setEditMenuItemObject(undefined);
                    }
                    setIsAddNewMenuItemViewOn(true);
                  } else {
                    toastr.warning("Please enable this category to Add List Items !!")
                  }



                }}
                type="button"
                className="btn btn-primary waves-effect float-right waves-light m-2 mb-4 mt-4 "
              >
                <i className="bx bx-plus font-size-16 align-middle mr-2"></i>{" "}
                {props.t("Add List Item")}
              </button>

            </div>
          </Col>
        </Row>
      }

      {isAddNewMenuItemViewOn && <>{getEditViewUI(true)}</>}


    </React.Fragment >
  );
};

export default withNamespaces()(DiaryMenuItems);