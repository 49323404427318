import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as InvoiceHelper from "../../../helpers/ParseHelpers/invoice_helper";
import * as actionTypes from "./actionTypes";
import * as InvoiceActions from "./actions";
import * as Constants from "../../../Constents";
import actions from "redux-form/lib/actions";
import { getPaymentOptionsForSchool } from "../school/actions";
import toastr from "toastr"
import * as InvoiceItemActions from "../invoiceItems/actions";

function* getAInvoice({ payload: { id } }) {
  try {
    const response = yield call(InvoiceHelper.getInvoiceFromParse, id, false);
    yield put(InvoiceActions.updateInvoice(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAInvoice({ payload: { invoiceId } }) {
  try {
    const response = yield call(
      InvoiceHelper.deleteInvoiceFromParse,
      invoiceId
    );
    if (response) {
      yield put(InvoiceActions.deleteInvoice(response));
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getInvoiceCountForFilters({ payload: { filters, schoolId } }) {
  try {
    yield put(InvoiceActions.setInvoiceCount(0));
    const serverResponse = yield call(
      InvoiceHelper.getInvoiceCountForFilters,
      filters
    );
    yield put(InvoiceActions.setInvoiceCount(serverResponse));
  } catch (err) { }
}

function* getInvoicesForFilters({ payload: { filters, schoolId, skipLocal,loop } }) {
  try {
    let key = "_" + schoolId;
    let updatedTime = localStorage.getItem(key);
    const newTime = new Date();

    if(!skipLocal){
      const localResponse = yield call(
        InvoiceHelper.getInvoicesForFilter,
        filters,
        null,
        true
      );
      if (localResponse.length > 0) {
        yield put(InvoiceActions.setInvoices(localResponse));
      }
    }
    
    
    if(loop){
      let allInvoices =[];
      let loopIt = true;
      while(loopIt){
        let updateServerResponse = yield call(
          InvoiceHelper.getInvoicesForFilter,
          filters,
          null,
          false,
          allInvoices.length
        );

        allInvoices= [...allInvoices,...updateServerResponse]

        if(updateServerResponse.length!=1000){
          loopIt=false
        }
      }
      if (allInvoices && allInvoices.length > 0) {
        yield put(InvoiceActions.setInvoices(allInvoices));
        Parse.Object.pinAll(allInvoices);
      }else{
        yield put(InvoiceActions.setInvoices([]));
      }

    }else{
      let updateServerResponse = yield call(
        InvoiceHelper.getInvoicesForFilter,
        filters,
        null,
        false
      );
      if (updateServerResponse && updateServerResponse.length > 0) {
        yield put(InvoiceActions.setInvoices(updateServerResponse));
        Parse.Object.pinAll(updateServerResponse);
      }else{
        yield put(InvoiceActions.setInvoices([]));
      }

    }
    
  } catch (error) { }
}



function* getInvoicesForProgramIdSaga({ payload: { programId, reload } }) {
  try {
    let response = yield call(InvoiceHelper.getInvoicesForProgramId, programId);
    yield put(InvoiceActions.updateInvoicesForProgramId(response, reload));
  } catch (err) {
    console.log(err);
  }
}

function* getInvoiceForSchoolId({ payload: { schoolId } }) {
  try {
    var key = "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      InvoiceHelper.getInvoicesForSchoolId,
      schoolId,
      null,
      true
    );
    yield put(InvoiceActions.setInvoices(localResponse));

    const serverResponse = yield call(
      InvoiceHelper.getInvoicesForSchoolId,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(InvoiceActions.setInvoices(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* createInvoiceForKidsParse({ payload: { invoiceObject } }) {
  try {
    const response = yield call(
      InvoiceHelper.createInvoiceForKids,
      invoiceObject
    );
    if (response) {
      yield put(InvoiceActions.responseForCreateInvoiceForKids(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* clearResponseForCreateInvoiceForKids({ payload: { } }) {
  try {
    yield put(InvoiceActions.responseForCreateInvoiceForKids(undefined));
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* createInvoiceWithFunction({ payload: { inputParam } }) {
  try {
    const response = yield call(
      InvoiceHelper.createInvoiceWithFunction,
      inputParam
    );
    yield put(InvoiceActions.setInvoiceUpdate(undefined));
  } catch (error) { }
}

function* updateInvoiceAndItem({ payload: { invoiceNItems } }) {
  try {
    const response = yield call(
      InvoiceHelper.updateInvoiceAndItem,
      invoiceNItems
    );
    if (response) {
      yield put(InvoiceActions.setUpdateInvoiceUpdate(response.invoice));
      yield put(InvoiceItemActions.updateInvoiceItems(response.invoiceItems));
    }

  } catch (err) {
    console.log(err);
  }
}

function* getInvoiceForIds({ payload: { ids } }) {
  try {
    let response = yield call(InvoiceHelper.gerInvoiceForIds, ids, true);
    if (response.length > 0) {
      yield put(InvoiceActions.setInvoices(response));
    }
    response = yield call(InvoiceHelper.gerInvoiceForIds, ids, false);
    if (response.length > 0) {
      yield put(InvoiceActions.setInvoices(response));
    }
  } catch (err) {
    console.log(err);
  }
}
function* getInvoiceDetailsFromFunction({ payload: { id } }) {
  try {
    let response = yield call(InvoiceHelper.getinvoiceByFunction, id);
    yield put(InvoiceActions.setInvoice(response));
  } catch (err) {
    console.log(err);
  }
}
function* deleteSelectedInvoice({ payload: { id, programInvoice, kidProfileInvoice } }) {
  try {
    yield put(InvoiceActions.setDeleteSuccess(false));
    let response = yield call(InvoiceHelper.deleteSelectedInvoice, id);
    response.pin();
    yield put(InvoiceActions.setDeleteSuccess(response));
    if (programInvoice) {
      yield put(InvoiceActions.updateInvoicesForProgramId([response], true))
      toastr.success("Invoice deleted successfully !!");
    }
    else if (kidProfileInvoice) {
      toastr.success("Invoice deleted successfully !!");
    }
  } catch (err) {
    console.error(err);
  }
}
function* notifyPaymentToParent({ payload: { invoiceId, kidId } }) {
  try {
    let response = yield call(
      InvoiceHelper.sendNotifyPaymentToParent,
      invoiceId,
      kidId
    );
    if (response) {
      toastr.success("Notification Sent Successfully", { timeOut: 1500 });
    }
  } catch (err) {
    console.log(err);
  }
}

function* getInvoiceCountForSchoolId({ payload: { schoolId, tags } }) {
  try {
    let response = yield call(
      InvoiceHelper.getInvoiceCountForSchoolId,
      schoolId,
      tags
    );
    yield put(InvoiceActions.setInvoicecountForSchoolId(response));
  } catch (err) {
    console.log("error");
  }
}

function* saveInvoiceObject({ payload: { invoice } }) {
  try {
    let response = yield call(
      InvoiceHelper.updateOrSaveInvoice,
      invoice
    );
    yield put(InvoiceActions.updateInvoice(response));
  } catch (err) {
    console.log("error");
  }
}
function* createInvoiceForProgramKidSaga({ payload: { kidId, templateId, school, programObject } }) {
  try {
    yield put(InvoiceActions.setProgramInvoiceCreationLoader(true))
    let response = yield call(
      InvoiceHelper.createInvoiceForProgramKid,
      kidId, templateId, school, programObject
    );
    yield put(InvoiceActions.setProgramInvoiceCreationLoader(false))
  } catch (err) {
    console.log("error");
  }
}

function* getRecurringMasterInvoicesForSchoolId({ payload: { schoolId } }) {
  try {
    var key = "RecurringMasterInvoices_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      InvoiceHelper.getRecurringMasterInvoicesForSchoolId,
      schoolId,
      null,
      true
    );
    if (localResponse && localResponse.length >0) {
      yield put(InvoiceActions.updateRecurringMasterInvoices(localResponse));
    }
    

    const serverResponse = yield call(
      InvoiceHelper.getRecurringMasterInvoicesForSchoolId,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(InvoiceActions.updateRecurringMasterInvoices(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getRecurringInvoicesForSchoolId({ payload: { schoolId } }) {
  try {
    var key = "ReccurungInvoices_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      InvoiceHelper.getRecurringInvoicesForSchoolId,
      schoolId,
      null,
      true
    );
    yield put(InvoiceActions.updateRecurringInvoices(localResponse));

    const serverResponse = yield call(
      InvoiceHelper.getRecurringInvoicesForSchoolId,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(InvoiceActions.updateRecurringInvoices(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editRecurringMasterInvoice({ payload: { masterInvoice} }) {
  try {
    const response = yield call(InvoiceHelper.editRecurringMasterInvoice, masterInvoice);
    if (response) {
      yield put(InvoiceActions.updateRecurringMasterInvoices([response]));
      toastr.success('Updated successfully', { timeOut: 1500 })
      response.pin();
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* stopRecurringMasterInvoice({ payload: { masterInvoice } }) {
  try {
    const response = yield call(InvoiceHelper.stopRecurringMasterInvoice, masterInvoice);
    if (response) {
      yield put(InvoiceActions.removeRecurringMasterInvoiceFromList(response));
      toastr.success('Stoped successfully', { timeOut: 1500 })
      response.pin();
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetInvoice() {
  yield takeEvery(actionTypes.UPDATE_INVOICE, getAInvoice);
}

export function* watchDeleteInvoice() {
  yield takeEvery(actionTypes.DELETE_INVOICE, deleteAInvoice);
}
export function* watchGetInvoicesForSchoolId() {
  yield takeEvery(actionTypes.GET_INVOICES, getInvoiceForSchoolId);
}

export function* watchGetInvoicesForFilters() {
  yield takeEvery(actionTypes.GET_INVOICES_FOR_FILTERS, getInvoicesForFilters);
}

export function* watchGetInvoicesCountForFilters() {
  yield takeEvery(
    actionTypes.GET_INVOICES_COUNT_FOR_FILTERS,
    getInvoiceCountForFilters
  );
}

export function* watchCreateInvoiceForKidsParse() {
  yield takeEvery(
    actionTypes.CREATE_INVOICE_FOR_KIDS,
    createInvoiceForKidsParse
  );
}

export function* watchCreateInvoiceWithFunction() {
  yield takeEvery(
    actionTypes.CREATE_INVOICE_WITH_FUNCTION,
    createInvoiceWithFunction
  );
}

export function* wathcUpdateInvoiceAndItem() {
  yield takeEvery(actionTypes.UPDATE_INVOICE_AND_ITEMS, updateInvoiceAndItem);
}

export function* watchGetInvoicesForIds() {
  yield takeEvery(actionTypes.GET_INVOICE_FOR_IDS, getInvoiceForIds);
}
export function* watchGetInvoiceDetailsFromFunction() {
  yield takeEvery(
    actionTypes.GET_INVOICE_DETAILS_BY_FUNCTION,
    getInvoiceDetailsFromFunction
  );
}
export function* watchDeleteSelectedInvoice() {
  yield takeEvery(actionTypes.DELETE_SELECTED_INVOICE, deleteSelectedInvoice);
}
export function* watchNotifyPaymentToParent() {
  yield takeEvery(actionTypes.NOTIFY_PAYMENT_TO_PARENTS, notifyPaymentToParent);
}
export function* watchClearResponseForCreateInvoiceForKids() {
  yield takeEvery(
    actionTypes.CLEAR_RESPONSE_FOR_CREATE_INVOICE_FOR_KIDS,
    clearResponseForCreateInvoiceForKids
  );
}
export function* watchGetInvoiceCountForSchoolId() {
  yield takeEvery(
    actionTypes.GET_INVOICE_COUNT_FOR_SCHOOLID,
    getInvoiceCountForSchoolId
  );
}


export function* watchGetInvoicesForProgramIdSaga() {
  yield takeEvery(actionTypes.GET_INVOICES_FOR_PROGRAM_ID, getInvoicesForProgramIdSaga);
}
export function* watchSaveInvoice() {
  yield takeEvery(actionTypes.SAVE_INVOICE, saveInvoiceObject);
}

export function* watchCreateInvoiceForProgramKidSaga() {
  yield takeEvery(actionTypes.CREATE_INVOICE_FOR_PROGRAM_KID, createInvoiceForProgramKidSaga);
}

export function* watchGetRecurringMasterInvoicesForSchoolId() {
  yield takeEvery(actionTypes.GET_RECURRING_MASTER_INVOICES_FOR_SCHOOLID, getRecurringMasterInvoicesForSchoolId);
}

export function* watchgetRecurringInvoicesForSchoolId() {
  yield takeEvery(actionTypes.GET_RECURRING_INVOICES_FOR_SCHOOLID, getRecurringInvoicesForSchoolId);
}

export function* watchEditRecurringMasterInvoice() {
  yield takeEvery(actionTypes.EDIT_RECURRING_MASTER_INVOICES_FOR_SCHOOLID, editRecurringMasterInvoice);
}

export function* watchStopRecurringMasterInvoice() {
  yield takeEvery(actionTypes.STOP_RECURRING_MASTER_INVOICES_FOR_SCHOOLID, stopRecurringMasterInvoice);
}

function* invoiceSaga() {
  yield all([
    //fork(watchGetInvoice),
    fork(watchDeleteInvoice),
    fork(watchGetInvoicesForSchoolId),
    fork(watchGetInvoicesForFilters),
    fork(watchCreateInvoiceForKidsParse),
    fork(watchCreateInvoiceWithFunction),
    fork(wathcUpdateInvoiceAndItem),
    fork(watchGetInvoicesForIds),
    fork(watchGetInvoiceDetailsFromFunction),
    fork(watchDeleteSelectedInvoice),
    fork(watchNotifyPaymentToParent),
    fork(watchClearResponseForCreateInvoiceForKids),
    fork(watchGetInvoicesCountForFilters),
    fork(watchGetInvoiceCountForSchoolId),
    fork(watchGetInvoicesForProgramIdSaga),
    fork(watchSaveInvoice),
    fork(watchCreateInvoiceForProgramKidSaga),
    fork(watchGetRecurringMasterInvoicesForSchoolId),
    fork(watchgetRecurringInvoicesForSchoolId),
    fork(watchEditRecurringMasterInvoice),
    fork(watchStopRecurringMasterInvoice),
  ]);
}
export default invoiceSaga;
