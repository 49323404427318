import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withNamespaces } from 'react-i18next';
import { Button, Card, CardBody, Label, Table } from 'reactstrap';
import { Link } from "react-router-dom";
import Moment from "moment"

const ListView = props => {

    const getDate = (date) => {
        let time = Moment(date).format("DD/MM/YYYY");
        return time;
    };
    const getTime = (date) => {
        let time = Moment(date).format("hh:mm a");
        return time;
    }

    // const getStringFromList = (list) => {
    //     let listToString;
    //     if (list && list.length > 0) {
    //         for (const i in list) {
    //             if (i == 0) {
    //                 listToString = list[i];
    //             } else {
    //                 listToString = listToString + list[i];
    //             }
    //             if (list.length != i - 1) {
    //                 listToString = listToString + ","
    //             }
    //         }
    //     }
    //     return listToString
    // }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <Table className="table-centered table-nowrap table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope="col">{props.t('Name')}</th>
                                    <th scope="col"><div >{props.t('Date/Time')}</div></th>
                                    <th scope="col"><div >
                                        {props.t('Purpose of visit')}
                                    </div></th>
                                    <th scope="col"><div >{props.t('Phone Number')}</div></th>
                                    <th scope="col"><div >{props.t('Person to meet')}</div></th>
                                    <th scope="col"><div >{props.t('Visiting from')}</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.mainList.map((val, key) => (
                                    <tr key={key}>
                                        <td>
                                            <div>
                                                <Label className='mb-0'>
                                                    {val.attributes.name}
                                                </Label>
                                            </div>
                                            <div className='font-size-12'>
                                                {val.attributes.visitorType}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {getDate(val.createdAt)}
                                            </div>
                                            <div className='font-size-12'>
                                                {getTime(val.createdAt)}
                                            </div>
                                        </td>
                                        <td>
                                            <div >
                                                {val.attributes.visitingPurpose ? val.attributes.visitingPurpose.join(", ") : ""}
                                            </div>
                                        </td>

                                        <td>
                                            <div >
                                                {val.attributes.phoneNumber}
                                            </div>
                                        </td>
                                        <td>
                                            <div >
                                                {val.attributes.meetingPerson}
                                            </div>
                                        </td>
                                        <td>
                                            <div>
                                                {val.attributes.visitingFrom}
                                            </div>
                                        </td>



                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment >
    )
}
const mapStatetoProps = state => {
    return {}
}

export default connect(mapStatetoProps, {})(withRouter(withNamespaces()(ListView)));
