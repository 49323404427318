import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as QabMapHelper from "../../../helpers/ParseHelpers/quizActivityBatch_helper";
import * as actions from "./actionTypes";
import * as Constants from '../../../Constents';
import * as QABMapActions from './actions';
import Parse from 'parse';




function* getQabMapForBatch({ payload: { courseId, batchId } }) {
  try {
    //console.log(courseId, batchId )
    const response = yield call(QabMapHelper.getQuizActivityBatchMapForBatch, courseId, batchId);
    yield put(QABMapActions.updateQABMapForBatchId(response));
    Parse.Object.pinAll(response);
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* addQabMap({ payload: { qabMap,schoolId,sendNotofication } }) {
  try {
    yield put(QABMapActions.setQABMapLoading(true));
    const response = yield call(QabMapHelper.saveQabMap, qabMap,schoolId,sendNotofication);
    yield put(QABMapActions.updateQABMapForBatchId([response]));
    Parse.Object.pinAll(response);
    yield put(QABMapActions.setQABMapLoading(false));
  } catch (error) {
    // yield put(apiError(error));
  }
}



export function* watchGetQabMapForBatch() {
  yield takeEvery(actions.GET_QAB_MAP_FOR_BATCH, getQabMapForBatch);
}
export function* watchAddQabMap() {
  yield takeEvery(actions.ADD_QAB, addQabMap);
}

function* QuizActivityBatchSaga() {
  yield all([fork(watchGetQabMapForBatch),fork(watchAddQabMap),]);
}
export default QuizActivityBatchSaga;