import React, { useEffect, useState } from "react";
import Iframe from 'react-iframe';
import { Card, CardBody, CardTitle, CardSubtitle, Row, Col, Container, Input, Nav, NavItem, NavLink, TabContent, TabPane,} from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Parse from "parse";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import ModalVideo from "react-modal-video";
import { setSelecetdSchool } from "../../store/actions";

function HelpVideo(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/help-videos/:id",
        exact: true,
        strict: false,
    });


    const schoolId = match.params.id;

    const [videocontent, setVideoContent] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [displaylist, setDisplayList] = useState([]);
    const [activeTab, setActiveTab] = useState("1");
    const [isOpen, setOpen] = useState(false);
    const [videoUrl, setVideoUrl] = useState('');
    
    useEffect(() => {
        let query = new Parse.Query('Help')
        query.find().then((videosobj) => {
            // console.log("Videos", videosobj);
            setIsLoading(false)
            setVideoContent(videosobj)
            setDisplayList(videosobj)
        });
    }, [schoolId])

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    function searchVideo(search) {
        var tempList = [...videocontent];
        if (search === undefined || search === null || search.length === 0) {
            setDisplayList(tempList);
        } else if (tempList) {
            let filter = tempList.filter((item) => {
                return (item.attributes.title.toLowerCase()).includes(search.toLowerCase()) ||
                    (item.attributes.description && (item.attributes.description.toLowerCase()).includes(search.toLowerCase()))
            });
            setDisplayList(filter);
        }
    }

    const tab1Videos = displaylist.filter(item => item.attributes.videoType === "web");
    const tab2Videos = displaylist.filter(item => item.attributes.videoType !== "web")
    
    const handleThumbnailClick = (url) => {
        setVideoUrl(url);
        setOpen(true); 
    };
    
    return (

        <React.Fragment>

            <div className="page-content">
                <Container>
                    <Row>
                        <Col><Breadcrumbs title="HELP VIDEOS" breadcrumbItem="Video" /></Col>
                        <Col lg={6} md={6} xs={12}>
                            <div className="mt-4 mt-sm-0 float-sm-right form-inline">
                                <div className="search-box mr-2">
                                    <div className="position-relative">
                                        <Input type="text" className="form-control border-1 mb-3 
                                        " placeholder="Search..."
                                            onChange={(e) => {
                                                searchVideo(e.target.value)
                                            }}
                                        />
                                        <i className="bx bx-search-alt search-icon"></i>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {isLoading || displaylist.length === 0 && (
                                <h3 className="text-center text-info">
                                    No videos found!!
                                </h3>
                            )}
                        </Col>
                    </Row>
                    <Row>

                        {isLoading && (
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                        Loading....{" "}
                                    </Link>
                                </div>
                            </Col>
                        )}
                        <Row className="checkout-tabs">
                            <Col lg="3">
                                <Nav className="flex-column" pills>
                                    <NavItem>
                                        <NavLink
                                            className={`font-weight-bold mb-4 ${activeTab === "1" ? "active" : ""}`}
                                            onClick={() => setActiveTab("1")}
                                        >
                                            <h5>Dashboard</h5>
                                        </NavLink>
                                    </NavItem>
                                    <NavItem>
                                        <NavLink
                                            className={`font-weight-bold mb-4 ${activeTab === "2" ? "active" : ""}`}
                                            onClick={() => setActiveTab("2")}
                                        >
                                            <h5>App</h5>
                                        </NavLink>
                                    </NavItem>
                                </Nav>
                            </Col>

                            <Col lg="9">
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1">
                                    <Row>
                                    {tab1Videos.map((item, index) => (
                                        <Col key={index} xl="6" sm="12" className="mb-4">
                                            <Card>
                                                <CardBody>
                                                    <center>
                                                    <div 
                                                        className="embed-responsive embed-responsive-16by9 play-icon" 
                                                        onClick={() => handleThumbnailClick(item.attributes.videoURL)} 
                                                        style={{ position: 'relative', display: 'inline-block' }}
                                                    >
                                                        <img
                                                            src={item.attributes.thumbnailURL}
                                                            alt={item.attributes.title}
                                                            width="240" 
                                                            height="130"
                                                            className="play-btn video-play-icon"
                                                            style={{ cursor: 'pointer' }}
                                                        />
                                                        <i
                                                            className="mdi mdi-play text-danger rounded-circle bg-white shadow"
                                                            style={{
                                                                position: 'absolute',
                                                                top: '50%', 
                                                                left: '50%',
                                                                transform: 'translate(-50%, -50%)',
                                                                height: '1cm',
                                                                width: '1cm',
                                                                fontSize: '1cm',
                                                                display: 'flex',
                                                                alignItems: 'center',
                                                                justifyContent: 'center',
                                                                borderRadius: '50%',
                                                                boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                                                                cursor: 'pointer',
                                                            }}
                                                        ></i>
                                                    </div>

                                                        <CardTitle>{item.attributes.title}</CardTitle>
                                                        <CardSubtitle className="mb-3">
                                                            {item.attributes.description}
                                                        </CardSubtitle>
                                                    </center>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    ))}
                                    </Row>
                                    </TabPane>

                                    <TabPane tabId="2">
                                        <Row>
                                            {tab2Videos.map((item, index) => (
                                                <Col key={index} xl="6" sm="12" className="mb-4">
                                                <Card>
                                                    <CardBody>
                                                        <center>
                                                        <div 
                                                            className="embed-responsive embed-responsive-16by9 play-icon" 
                                                            onClick={() => handleThumbnailClick(item.attributes.videoURL)} 
                                                            style={{ position: 'relative', display: 'inline-block' }}
                                                        >
                                                            <img
                                                                src={item.attributes.thumbnailURL}
                                                                alt={item.attributes.title}
                                                                width="240" 
                                                                height="130"
                                                                className="play-btn video-play-icon"
                                                                style={{ cursor: 'pointer' }}
                                                            />
                                                            <i
                                                                className="mdi mdi-play text-danger rounded-circle bg-white shadow"
                                                                style={{
                                                                    position: 'absolute',
                                                                    top: '50%', 
                                                                    left: '50%',
                                                                    transform: 'translate(-50%, -50%)',
                                                                    height: '1cm',
                                                                    width: '1cm',
                                                                    fontSize: '1cm',
                                                                    display: 'flex',
                                                                    alignItems: 'center',
                                                                    justifyContent: 'center',
                                                                    borderRadius: '50%',
                                                                    boxShadow: '0 4px 10px rgba(0, 0, 0, 0.2)',
                                                                    cursor: 'pointer',
                                                                }}
                                                            ></i>
                                                        </div>
    
                                                            <CardTitle>{item.attributes.title}</CardTitle>
                                                            <CardSubtitle className="mb-3">
                                                                {item.attributes.description}
                                                            </CardSubtitle>
                                                        </center>
                                                    </CardBody>
                                                </Card>
                                            </Col>
                                            ))}
                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </Col>
                        </Row>
                    </Row>
                </Container>
            </div>
            <ModalVideo
                channel="youtube"
                isOpen={isOpen}
                videoId={videoUrl.split('/').pop()} 
                onClose={() => setOpen(false)}
            />
        </React.Fragment>
    )
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool })(HelpVideo)));
