import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { matchPath, Redirect } from "react-router";
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withNamespaces } from 'react-i18next';
import { Button, Card, CardBody, Col, Container, FormGroup, Label, Row } from 'reactstrap';
import TopBar from "./Topbar";

import Breadcrumb from "../../components/Common/Breadcrumb";
import { getAKid, getClassroomsForSchoolId, updateAKid, updateAParent, getChildAdditionalDetailsForKidId, updateAdditionalDetailsForAKid, setSelecetdSchool, getAdmissionQuestions } from '../../store/actions'
import AvForm from 'availity-reactstrap-validation/lib/AvForm';
import AvField from 'availity-reactstrap-validation/lib/AvField';
import Parse from 'parse';


const EditStudent = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/studentEdit/:schoolId/:kidId",
        exact: true,
        strict: false,
    });
    // const pram = { schoolId : match.params.schoolId , kidId : match.params.kidId}
    const kidId = match.params.kidId
    const schoolId = match.params.schoolId
    const [name, nameOps] = useState('')
    const [admissionNumber, admissionOps] = useState('')
    const [gender, genderOps] = useState('')
    const [DOB, DOBOps] = useState('')
    const [mothername, mothernameOps] = useState('')
    const [motherNumber, motherNumberOps] = useState('')
    const [motherEmail, motherEmailOps] = useState('')
    const [fatherName, fatherNameOps] = useState('')
    const [fatherNumber, fatherNumberOps] = useState('')
    const [fatherEmail, fatherEmailOps] = useState('')
    const [saveLoading, setSaveLoading] = useState(false);
    const [email, setEmail] = useState(undefined)
    const [phoneNumber, serPhoneNumber] = useState(undefined)
    const [additionalQuestions, additionalQuestionsOps] = useState([])
    useEffect(() => {
        props.getAKid(kidId)
        props.getClassroomsForSchoolId(schoolId)

        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [kidId, schoolId])

    useEffect(() => {
        if (props.selectedKid) {
            nameOps(props.selectedKid.attributes.Name ? props.selectedKid.attributes.Name : '')
            admissionOps(props.selectedKid.attributes.admissionNumber ? props.selectedKid.attributes.admissionNumber : '')
            genderOps(props.selectedKid.attributes.Gender ? props.selectedKid.attributes.Gender : '')
            DOBOps(props.selectedKid.attributes.DateOfBirth ? formatDate(props.selectedKid.attributes.DateOfBirth) : '')
            setEmail(props.selectedKid.attributes.email ? props.selectedKid.attributes.email : '')
            serPhoneNumber(props.selectedKid.attributes.phoneNumber ? props.selectedKid.attributes.phoneNumber : '')
            if (props.selectedKid.parents.length > 0) {
                for (let i in props.selectedKid.parents) {
                    if (props.selectedKid.parents[i].attributes.Relation === 'Mother') {
                        mothernameOps(props.selectedKid.parents[i].attributes.Name)
                        motherNumberOps(props.selectedKid.parents[i].attributes.PhoneNumber)
                        motherEmailOps(props.selectedKid.parents[i].attributes.EMail)
                    } else if (props.selectedKid.parents[i].attributes.Relation === 'Father') {
                        fatherNameOps(props.selectedKid.parents[i].attributes.Name)
                        fatherNumberOps(props.selectedKid.parents[i].attributes.PhoneNumber)
                        fatherEmailOps(props.selectedKid.parents[i].attributes.EMail)
                    }
                }
            }
        }
    }, [props.selectedKid])
    const saveDetails = () => {
        setSaveLoading(true);
        let kid = props.selectedKid
        if (admissionNumber) {
            kid.set('admissionNumber', admissionNumber)
        }
        if (name) {
            kid.set('Name', name)
        }
        if (gender) {
            kid.set('Gender', gender)
        }
        if (DOB) {
            let tempDate = new Date(DOB)
            tempDate.setDate(tempDate.getDate());
            kid.set('DateOfBirth', tempDate)
        }
        kid.set('email', email)
        kid.set('phoneNumber', phoneNumber)
        props.updateAKid(kid)
        let motherObject;
        let fatherObject;


        for (var i in props.selectedKid.parents) {
            if (props.selectedKid.parents[i].attributes.Relation === "Mother") {
                motherObject = props.selectedKid.parents[i]


            } else if (props.selectedKid.parents[i].attributes.Relation === "Father") {
                fatherObject = props.selectedKid.parents[i]

            }
        }

        let familyId = kid.attributes.FamilyID;

        var groupACL = new Parse.ACL();

        groupACL.setRoleWriteAccess(schoolId + "_Admin", true);
        groupACL.setRoleReadAccess(schoolId + "_Admin", true);

        groupACL.setRoleWriteAccess(schoolId + "_Teacher", false);
        groupACL.setRoleReadAccess(schoolId + "_Teacher", true);

        groupACL.setRoleWriteAccess(familyId + "_Role", true);
        groupACL.setRoleReadAccess(familyId + "_Role", true);

        if (motherObject == null) {
            let MotherObject = Parse.Object.extend("Parent");
            motherObject = new MotherObject();
            motherObject.set('FamilyID', familyId)
            motherObject.set("Relation", "Mother");
            motherObject.set("NotificationChoice", 16127);
            if (kid.attributes.SchoolID) {
                motherObject.setACL(groupACL);
            }
        }

        if (mothername) {
            motherObject.set('Name', mothername);
        }
        motherObject.set('PhoneNumber', motherNumber);
        motherObject.set('EMail', motherEmail);

        props.updateAParent(motherObject);

        if (fatherObject == null) {
            let FatherObject = Parse.Object.extend("Parent");
            fatherObject = new FatherObject();
            fatherObject.set('FamilyID', familyId)
            fatherObject.set("Relation", "Father");
            fatherObject.set("NotificationChoice", 16127);
            if (kid.attributes.SchoolID) {
                fatherObject.setACL(groupACL);
            }
        }

        if (fatherName) {
            fatherObject.set('Name', fatherName)
        }
        fatherObject.set('PhoneNumber', fatherNumber);
        fatherObject.set('EMail', fatherEmail)

        props.updateAParent(fatherObject)

        window.history.back();
        // props.history.push(`/studentProfile/${schoolId}/${kidId}`)
    }
    function formatDate(d) {
        if (d === "") {
            return ""
        } else {
            const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
            const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
            const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
            return (`${ye}-${mo}-${da}`);
        }
    }
    return (
        <React.Fragment>
            {props.selectedKid &&
                <div className="page-content">
                    <Container fluid>
                        {/* <Breadcrumb title="Student Profile" breadcrumbItem="Edit" /> */}
                        <TopBar
                            title={"Student Profile"}
                            buttonName={""}
                            backButton={true}
                            hideButton={true}
                            onClickAdd={() => { }}
                            onClickBackButton={() => {
                                window.history.back();
                            }}
                        />
                        <Row>
                            <Col lg={12}>
                                <Card>
                                    <CardBody>
                                        <AvForm className="outer-repeater" onSubmit={saveDetails}>
                                            <div data-repeater-list="outer-group" className="outer">
                                                <div data-repeater-item className="outer">
                                                    <FormGroup >
                                                        <Label htmlFor="studentName">{props.t('Name')}:</Label>
                                                        <AvField
                                                            type="text"
                                                            name="studentName"
                                                            placeholder={props.t('Enter Name...')}
                                                            className="form-control"
                                                            value={name}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: props.t('Please enter Name...'),
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                nameOps(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="admissionNumber">{props.t('Admission Number')}: </Label>
                                                        <AvField
                                                            type="text"
                                                            name="admissionNumber"
                                                            placeholder={props.t('Enter Admission Number...')}
                                                            className="form-control"
                                                            value={admissionNumber}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: props.t('Please enter admission number...'),
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                admissionOps(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="gender">{props.t('Gender')}: </Label>
                                                        <FormGroup >
                                                            <select
                                                                className="form-control"
                                                                name='gender'
                                                                onChange={(e) => {
                                                                    genderOps(e.target.value);
                                                                }}
                                                                value={gender}
                                                            >
                                                                <option key={1} value="Male" >MALE</option>
                                                                <option key={2} value="Female">FEMALE</option>
                                                            </select>
                                                        </FormGroup>
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="dob">{props.t('Date Of Birth')}: </Label>
                                                        <AvField
                                                            type="date"
                                                            name="dob"
                                                            placeholder={props.t('Enter Date Of Birth...')}
                                                            className="form-control"
                                                            value={DOB}
                                                            // validate={{ required: {
                                                            //     value: true,
                                                            //     errorMessage: props.t('Please enter mother name...'),
                                                            //   },}}
                                                            onChange={(e) => {
                                                                DOBOps(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    {props.selectedSchool && props.selectedSchool.attributes.schoolType == 11 && <FormGroup >
                                                        <Label htmlFor="studentEmail">{props.t('Email')}:</Label>
                                                        <AvField
                                                            type="text"
                                                            name="studentEmail"
                                                            placeholder={props.t('Enter Email...')}
                                                            className="form-control"
                                                            value={email}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: props.t('Please enter Email...'),
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                setEmail(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>}

                                                    {props.selectedSchool && props.selectedSchool.attributes.schoolType == 11 && <FormGroup >
                                                        <Label htmlFor="studenPhoneNumber">{props.t('Phone Number')}:</Label>
                                                        <AvField
                                                            type="text"
                                                            name="studenPhoneNumber"
                                                            placeholder={props.t('Enter Phone Number...')}
                                                            className="form-control"
                                                            value={phoneNumber}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: props.t('Please enter Phone Number...'),
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                serPhoneNumber(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>}
                                                    <FormGroup >
                                                        <Label htmlFor="motherName">{props.t('Mother Name')}: </Label>
                                                        <AvField
                                                            type="text"
                                                            name="motherName"
                                                            placeholder={props.t('Enter Mother Name...')}
                                                            className="form-control"
                                                            value={mothername}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: props.t('Please enter mother name...'),
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                mothernameOps(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="motherPhone">{props.t('Mother Phone Number')}: </Label>
                                                        <AvField
                                                            type="text"
                                                            name="motherPhone"
                                                            placeholder={props.t('Enter Mother Phone Number...')}
                                                            className="form-control"
                                                            value={motherNumber}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: props.t('Please enter mother phone number...'),
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                let value = e.target.value
                                                                const formattedNumber = value.replace(/\s/g, '');
                                                                motherNumberOps(formattedNumber);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="motherEmail">{props.t('Mother Email')}: </Label>
                                                        <AvField
                                                            type="email"
                                                            name="motherEmail"
                                                            placeholder={props.t('Enter Mother Email...')}
                                                            className="form-control"
                                                            value={motherEmail}
                                                            // validate={{ required: {
                                                            //     value: true,
                                                            //     errorMessage: props.t('Please enter mother email...'),
                                                            //   },}}
                                                            onChange={(e) => {
                                                                motherEmailOps(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="fatherName">{props.t('Father Name')}: </Label>
                                                        <AvField
                                                            type="text"
                                                            name="fatherName"
                                                            placeholder={props.t('Enter Father Name...')}
                                                            className="form-control"
                                                            value={fatherName}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: props.t('Please enter father name...'),
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                fatherNameOps(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="fatherPhone">{props.t('Father Phone Number')}: </Label>
                                                        <AvField
                                                            type="text"
                                                            name="fatherPhone"
                                                            placeholder={props.t('Enter Father Phone Number...')}
                                                            className="form-control"
                                                            value={fatherNumber}
                                                            validate={{
                                                                required: {
                                                                    value: true,
                                                                    errorMessage: props.t('Please enter father phone number...'),
                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                let value = e.target.value
                                                                const formattedNumber = value.replace(/\s/g, '');
                                                                fatherNumberOps(formattedNumber);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                    <FormGroup >
                                                        <Label htmlFor="fatherEmail">{props.t('Father Email')}: </Label>
                                                        <AvField
                                                            type="email"
                                                            name="fatherEmail"
                                                            placeholder={props.t('Enter Father Email...')}
                                                            className="form-control"
                                                            value={fatherEmail}
                                                            // validate={{ required: {
                                                            //     value: true,
                                                            //     errorMessage: props.t('Please enter father email...'),
                                                            //   },}}
                                                            onChange={(e) => {
                                                                fatherEmailOps(e.target.value);
                                                            }}
                                                        />
                                                    </FormGroup>

                                                    <div className='d-flex justify-content-end'>
                                                        {saveLoading ?
                                                            <button type="button" className="btn btn-light waves-effect">
                                                                <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>
                                                                {props.t(" Saving....")}
                                                            </button> : <Button type="submit"
                                                                color="primary">
                                                                {props.t('SAVE')}
                                                            </Button>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </AvForm>
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </div>
            }
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { selectedKid } = state.Kid;
    const { classrooms } = state.Classroom;
    const { kidCAD } = state.Kid
    const { selectedSchool } = state.School
    const { adQuestionObj } = state.Enquiry
    return { selectedKid, classrooms, kidCAD, selectedSchool, adQuestionObj };
};

export default connect(mapStatetoProps, { getAKid, getClassroomsForSchoolId, updateAKid, updateAParent, getChildAdditionalDetailsForKidId, updateAdditionalDetailsForAKid, getAdmissionQuestions, setSelecetdSchool })(withRouter(withNamespaces()(EditStudent)));

