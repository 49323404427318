import React, { useEffect, useState } from 'react';

import { Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import * as Constants from '../../Constents';
import { getSchool } from '../../store/actions'
import { matchPath } from "react-router";

const PagesDelay = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/switching-school/:id",
        exact: true,
        strict: false,
    });
    const schoolId = match.params.schoolId;
    const [showSchoolName, setShowSchoolName] = useState(false)

    useEffect(() => {
        props.getSchool(schoolId)
    }, [schoolId]);

    useEffect(() => {
        setTimeout(() => {
            window.location = "/"
        }, 1000)
    }, []);
    useEffect(() => {
        if (props.selectedSchool) {
            setShowSchoolName(true)
        }
    }, [props.selectedSchool])

   

    return (
        <React.Fragment>
            <div
                style={{
                    position: 'absolute', left: '50%', top: '50%',
                    transform: 'translate(-50%, -50%)'
                }} className="text-center"
            >{!showSchoolName && <Link to="#" className="text-success">
                <div style={{ fontSize: "50px" }}><i className="bx bxs-buildings ml-2"></i><i className="bx bxs-right-arrow-alt bx-fade-left"></i><i className="bx bxs-buildings"></i></div>{" "}
                {"Switching School..."}{" "}
            </Link>}
                {showSchoolName && <Link to="#" className="text-success">
                    <div style={{ fontSize: "50px" }}><i className="bx bxs-buildings"></i><i className="bx bxs-right-arrow-alt bx-fade-right"></i><i className="bx bxs-buildings"></i></div>{" Switching to "}
                    {props.selectedSchool.attributes.Name}{" "}
                </Link>}
            </div>
        </React.Fragment>
    );
}

//export default PagesDelay;
const mapStatetoProps = state => {
    const { selectedSchool } = state.School
    return { selectedSchool };
};

export default connect(mapStatetoProps, { getSchool })(withRouter((PagesDelay)));