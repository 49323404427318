import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import profileImg from "../../assets/images/users/userXYZ.png";
import * as WhiteLabel from "../../components/Common/WhiteLable";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Label,
} from "reactstrap";
import * as Constants from "../../Constents";
import Moment from "moment";

import { withNamespaces } from "react-i18next";
import QRCode from 'qrcode'


const EscortIdCardUI = (props) => {

    const [QrCode, setQrCode] = useState(undefined);

    useEffect(() => {
        if (props.escort) {
            getQRCode()
        }
    }, [props.escort.kid])

    function getQRCode() {
        QRCode.toDataURL(props.escort.type.toUpperCase() + "-" + props.escort.object.id + "-" + props.escort.kid.id, { errorCorrectionLevel: 'H' })
            .then(url => {
                setQrCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }

    const getClassroomName = (ClassRoomID) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === ClassRoomID
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getBodyFontSize = (obj) => {
        let tempObject = { ...obj }
        tempObject.fontSize = (((props.bodyFontSize * 0.25)) + "mm")
        return tempObject
    }

    const getPotrite = () => {
        return (
            <React.Fragment>
                {props.orientaion === "P" &&
                    <div style={{
                        width: props.selectedPrintSetting.cardWidth,
                        minHeight: props.selectedPrintSetting.cardHeight,
                        backgroundColor: "white",
                        border: props.isBorderOff ? "" : "1px solid lightGray",
                        borderRadius: "10px",
                    }}
                        className="m-3 dont-cut-element-when-printing"
                    >
                        <div className=' bg-lilpink text-center' style={{
                            borderTop: "1px solid lightGray",
                            borderLeft: "1px solid lightGray",
                            borderRight: "1px solid lightGray",
                            borderRadius: "10px 10px 0px 0px",
                        }}>
                            <h6 className='text-white ' style={{ marginBottom: "0" }}>ESCORT ID CARD</h6>
                        </div>
                        <div style={{ minHeight: props.selectedPrintSetting.topHeaderDivHeight, maxHeight: props.selectedPrintSetting.topHeaderDivHeight, overflow: "hidden" }}>
                            <Row className="">
                                <div
                                    className={`d-flex mx-auto align-items-center justify-content-center ${props.idCardLogo ? "m-0" : "m-2"}`}
                                    style={{
                                        maxWidth: `${props.idCardLogo ? "100%" : "80%"}`,
                                    }}
                                >
                                    {props.idCardLogo ? (
                                        <div className="" >
                                            <img
                                                style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain", }}
                                                alt="img"
                                                src={props.idCardLogo ? (props.idCardLogo.imageURL ?? "") : (props.escort.school.attributes.logo && props.escort.school.attributes.logo._url)}
                                                className="justify-content-center"
                                            />
                                        </div>
                                    ) : (
                                        <div className="avatar-xs">
                                            <img
                                                alt="img"
                                                src={props.idCardLogo ? (props.idCardLogo.imageURL ?? "") : (props.escort.school.attributes.logo && props.escort.school.attributes.logo._url)}
                                                className="rounded avatar-xs"
                                            />
                                        </div>
                                    )}
                                    {props.selectedHeaderTypeSetting == "logoAndName" && (
                                        <div className="my-auto ml-1">
                                            <label className="my-auto ml-0" style={{ fontSize: (props.headerFontSize != undefined) ? (((props.headerFontSize * 0.25)) + "mm") : "" }}>{props.escort.school.attributes.Name}</label>
                                        </div>
                                    )}
                                </div>
                            </Row>
                        </div>
                        <hr style={{ marginTop: "0mm" }}></hr>

                        <div style={{ minHeight: props.selectedPrintSetting.kidDetailHeight, maxHeight: props.selectedPrintSetting.kidDetailHeight, overflow: "hidden" }}>

                            <div className="mt-2 ml-2 mb-0">
                                <Row className="m-0 p-0" lg={props.selectedPrintSetting.rowForKidDetails} md={props.selectedPrintSetting.rowForKidDetails} sm={props.selectedPrintSetting.rowForKidDetails} xs={props.selectedPrintSetting.rowForKidDetails}>
                                    <Col lg={props.selectedPrintSetting.colForKidDetails} md={props.selectedPrintSetting.colForKidDetails} sm={props.selectedPrintSetting.colForKidDetails} xs={props.selectedPrintSetting.colForKidDetails}
                                        className="pl-0 pr-2 text-center justify-content-center"

                                    >
                                        <div className="" >
                                            <img src={props.escort.kidPhot ?? profileImg} alt="img" className="rounded text-center" style={{ height: props.selectedPrintSetting.kidPhotoHeight, width: props.selectedPrintSetting.kidPhotoHeight, objectFit: "contain" }} />
                                        </div>
                                        <p style={props.bodyFontSize != undefined ? getBodyFontSize(props.selectedPrintSetting.nameStyle) : props.selectedPrintSetting.nameStyle} className={props.selectedPrintSetting.marginTopForKidName + " mb-0 p-0"}>{props.escort.kidName}</p>
                                        <p style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "" }} className="m-0 p-0">{getClassroomName(props.escort.kidClasRoom)}</p>
                                    </Col>
                                    <Col lg={props.selectedPrintSetting.colForKidDetails} md={props.selectedPrintSetting.colForKidDetails} sm={props.selectedPrintSetting.colForKidDetails} xs={props.selectedPrintSetting.colForKidDetails}
                                        className="pl-0 pr-0 text-center justify-content-center"

                                    >
                                        <div className="" >
                                            <img src={props.escort.type == "P" ? (props.escort.object.attributes.Photo ? props.escort.object.attributes.Photo._url : profileImg) : (props.escort.object.attributes.Photo ? props.escort.object.attributes.Photo._url : profileImg)} alt="img" className="rounded text-center" style={{ height: props.selectedPrintSetting.kidPhotoHeight, width: props.selectedPrintSetting.kidPhotoHeight, objectFit: "contain" }} />
                                        </div>
                                        <p style={props.bodyFontSize != undefined ? getBodyFontSize(props.selectedPrintSetting.nameStyle) : props.selectedPrintSetting.nameStyle} className={props.selectedPrintSetting.marginTopForKidName + " mb-0 p-0"} >{props.escort.type == "P" ? props.escort.object.attributes.Name : props.escort.object.attributes.PersonName}</p>
                                        <p className="m-0 p-0" style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "" }} >{props.escort.type == "P" ? props.escort.object.attributes.Relation : props.escort.object.attributes.Relation}</p>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                        <div className="text-center" style={{ position: "relative", textAlign: "center", minHeight: props.selectedPrintSetting.qrCodeDivHeight, maxHeight: props.selectedPrintSetting.qrCodeDivHeight, overflow: "hidden" }}>
                            <div className="" style={{
                                position: "absolute", top: "50%"
                                , left: "50%", margin: "0",
                                transform: "translate(-50%, -50%)"
                            }}>
                                <img className="align-middle" src={QrCode} alt="img" style={{ minHeight: props.selectedPrintSetting.qrCodeDivHeight, maxHeight: props.selectedPrintSetting.qrCodeDivHeight }} />
                            </div>
                        </div>

                        <div className="" style={{ minHeight: props.selectedPrintSetting.bottomFooterDivHeight, maxHeight: props.selectedPrintSetting.bottomFooterDivHeight, overflow: "hidden", position: "relative" }}>
                            <hr className="mb-0 ml-4 mr-4" style={{ marginTop: "0mm" }}></hr>

                            <center className="m-0" >
                                <div className="text-center pl-2 pr-2">
                                    <p className="p-0 m-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}><b>If found please return to</b></p>
                                    <p className="m-0 p-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}>{props.escort.school.attributes.Name}</p>
                                    <p className="mb-2 p-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}>
                                        {/* {props.escort.school.attributes.Address}, */}
                                        {" "} {props.escort.school.attributes.primaryPhoneNumber}, <br />
                                        {" "} {props.escort.school.attributes.EMail}</p>
                                </div>

                            </center>
                            <div className='bg-lilpink' style={{
                                position: "absolute", bottom: "0", left: "0", width: "100%", height: "4mm",
                                borderTop: "1px solid lightGray",
                                borderLeft: "1px solid lightGray",
                                borderRight: "1px solid lightGray",
                                borderRadius: "0px 0px 10px 10px",
                            }}>
                                <h6 className='text-lilpink'>.</h6>
                            </div>
                        </div>
                    </div>}
            </React.Fragment>

        );
    }

    const getLandScape = () => {
        return (
            <React.Fragment>
                {props.orientaion == "L" &&
                    <div style={{
                        width: props.selectedPrintSetting.cardWidth,
                        minHeight: props.selectedPrintSetting.cardHeight,
                        backgroundColor: "white",
                        border: props.isBorderOff ? "" : "1px solid lightGray",
                        borderRadius: "10px",
                    }}
                        className="m-3 dont-cut-element-when-printing bg-white"
                    >


                        <div style={{ minHeight: props.selectedPrintSetting.topHeaderDivHeight, maxHeight: props.selectedPrintSetting.topHeaderDivHeight, overflow: "hidden", paddingTop: "0mm" }}>

                            <Col>
                                <div
                                    className={`d-flex mx-auto align-text-center justify-content-center ${props.idCardLogo ? "m-0" : "p-1"}`}
                                    style={{
                                        maxWidth: `${props.idCardLogo ? "100%" : "80%"}`,
                                    }}
                                >
                                    {props.idCardLogo ? (
                                        <div>
                                            <img
                                                style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain", borderRadius: "10px 10px 0 0" }}
                                                alt="img"
                                                src={props.idCardLogo ? (props.idCardLogo.imageURL ?? "") : (props.escort.school.attributes.logo && props.escort.school.attributes.logo._url)}
                                                className="justify-content-center"
                                            />
                                        </div>
                                    ) : (
                                        <div className="">
                                            <img
                                                alt="img"
                                                src={props.idCardLogo ? (props.idCardLogo.imageURL ?? "") : (props.escort.school.attributes.logo && props.escort.school.attributes.logo._url)}
                                                className="rounded "
                                                style={{ height: props.selectedPrintSetting.schoolLogoHeight, width: props.selectedPrintSetting.schoolLogoHeight, objectFit: "contain", }}
                                            />
                                        </div>
                                    )}
                                    {props.selectedHeaderTypeSetting == "logoAndName" && (
                                        <div className="my-auto m-1">
                                            <label className="my-auto m-0" style={{ fontSize: (props.headerFontSize != undefined) ? (((props.headerFontSize * 0.25)) + "mm") : "" }}>{props.escort.school.attributes.Name}</label>
                                        </div>
                                    )}
                                </div>
                            </Col>
                        </div>

                        <div className="d-flex align-items-center m-0 p-0"
                            style={{ maxHeight: props.selectedPrintSetting.topTitleDivHeight }}

                        >
                            <hr className="flex-grow-1 m-0 text-dark m-0" style={{ borderColor: "black" }} />
                            <p
                                style={{
                                    backgroundColor: 'black',
                                    borderTopLeftRadius: '3mm',
                                    borderBottomLeftRadius: '3mm',
                                    borderTopRightRadius: '0px',
                                    borderBottomRightRadius: '0px',
                                    border: 'none',
                                    paddingLeft: '10px',
                                    paddingRight: '10px',
                                    color: 'white',
                                }}
                                className=" text-white  m-0"
                            >
                                ESCORT ID CARD
                            </p>
                        </div>

                        <div style={{ minHeight: props.selectedPrintSetting.kidDetailHeight, maxHeight: props.selectedPrintSetting.kidDetailHeight, overflow: "hidden" }}>

                            <div>
                                <Row className="m-0 p-0" lg={props.selectedPrintSetting.rowForKidDetails} md={props.selectedPrintSetting.rowForKidDetails} sm={props.selectedPrintSetting.rowForKidDetails} xs={props.selectedPrintSetting.rowForKidDetails}>
                                    <Col lg={4} md={props.selectedPrintSetting.colForKidDetails} sm={props.selectedPrintSetting.colForKidDetails} xs={props.selectedPrintSetting.colForKidDetails}
                                        className="pl-0 pr-2 text-center justify-content-center"

                                    >
                                        <div className="" >
                                            <img src={props.escort.kidPhot ?? profileImg} alt="img" className="rounded text-center" style={{ height: props.selectedPrintSetting.kidPhotoHeight, width: props.selectedPrintSetting.kidPhotoHeight, objectFit: "contain", }} />
                                        </div>
                                        <p style={props.bodyFontSize != undefined ? getBodyFontSize(props.selectedPrintSetting.nameStyle) : props.selectedPrintSetting.nameStyle} className={props.selectedPrintSetting.marginTopForKidName + " mb-0 p-0"}>{props.escort.kidName}</p>
                                        <p style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "" }} className="m-0 p-0">{getClassroomName(props.escort.kidClasRoom)}</p>
                                    </Col>
                                    <Col lg={4} md={props.selectedPrintSetting.colForKidDetails} sm={props.selectedPrintSetting.colForKidDetails} xs={props.selectedPrintSetting.colForKidDetails}
                                        className="pl-0 pr-0 text-center justify-content-center"

                                    >
                                        <div className="" >
                                            <img src={props.escort.type == "P" ? (props.escort.object.attributes.Photo ? props.escort.object.attributes.Photo._url : profileImg) : (props.escort.object.attributes.Photo ? props.escort.object.attributes.Photo._url : profileImg)} alt="img" className="rounded text-center" style={{ height: props.selectedPrintSetting.kidPhotoHeight, width: props.selectedPrintSetting.kidPhotoHeight, objectFit: "contain" }} />
                                        </div>
                                        <p style={props.bodyFontSize != undefined ? getBodyFontSize(props.selectedPrintSetting.nameStyle) : props.selectedPrintSetting.nameStyle} className={props.selectedPrintSetting.marginTopForKidName + " mb-0 p-0"} >{props.escort.type == "P" ? props.escort.object.attributes.Name : props.escort.object.attributes.PersonName}</p>
                                        <p className="m-0 p-0" style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "" }} >{props.escort.type == "P" ? props.escort.object.attributes.Relation : props.escort.object.attributes.Relation}</p>
                                    </Col>
                                    <Col lg="4">
                                        {props.layoutType == "QR" ?
                                            <img
                                                src={QrCode}
                                                alt="img"
                                                style={{
                                                    maxHeight: props.selectedPrintSetting.qrCodeDivHeight, marginTop: '10px'
                                                }} />
                                            :
                                            <img
                                                src={props.escort.school.attributes.logo && props.escort.school.attributes.logo._url}
                                                alt="img"
                                                style={{
                                                    maxHeight: props.selectedPrintSetting.noQRLogoDivHeight, marginTop: '10px'

                                                }} />
                                        }
                                    </Col>

                                </Row>
                            </div>
                        </div>

                        <div style={{ minHeight: props.selectedPrintSetting.bottomFooterDivHeight, maxHeight: props.selectedPrintSetting.bottomFooterDivHeight, overflow: "hidden", paddingBottom: "2mm" }}>
                            <hr className="m-0" style={{ borderColor: "black" }}></hr>

                            <Row>
                                <div className="text-end p-1 m-0" style={{ width: props.selectedPrintSetting.footerW1, borderRight: '1px solid', minHeight: props.selectedPrintSetting.bottomFooterDivHeight }} >
                                    <p className="p-0 m-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}><b>IF FOUND PLEASE</b> <br></br> <b>RETURN TO</b></p>
                                </div>
                                <div className="text-start p-1 m-0" style={{ width: props.selectedPrintSetting.footerW2 }}>
                                    <div>
                                        <p className="m-0 p-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}>{props.escort.school.attributes.Name}</p>
                                        <p className="mb-2 p-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}>
                                            {/* {props.escort.school.attributes.Address}, */}
                                            {" "} {props.escort.school.attributes.primaryPhoneNumber},
                                            {" "} {props.escort.school.attributes.EMail}</p>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </div>}

            </React.Fragment>
        );
    }

    const getOnlyQR = () => {

        return (
            <React.Fragment>
                {props.onlyQR &&
                    <div
                        style={{
                            width: props.selectedPrintSetting.cardWidth,
                            minHeight: props.selectedPrintSetting.cardHeight,

                        }}
                        className="ml-3 dont-cut-element-when-printing"
                    >
                        <div className="text-center" style={{ position: "relative", textAlign: "center", minHeight: props.selectedPrintSetting.qrCodeDivHeight, overflow: "hidden" }}>
                            <div className="" style={{
                                position: "absolute", top: "50%"
                                , left: "50%", margin: "0",
                                transform: "translate(-50%, -50%)"
                            }}>
                                <img className="align-middle" src={QrCode} alt="img" style={{ minHeight: props.selectedPrintSetting.qrCodeDivHeight }} />
                            </div>

                        </div>
                        <hr></hr>
                        <div className="mt-2 ml-2 mb-0 ml-5">
                            <div className="m-0 p-0 text-left ml-3" lg={props.selectedPrintSetting.rowForKidDetails} md={props.selectedPrintSetting.rowForKidDetails} sm={props.selectedPrintSetting.rowForKidDetails} xs={props.selectedPrintSetting.rowForKidDetails}>
                                <div><label className="mb-0">{`Student - ${props.escort.kid.attributes.Name}, ${getClassroomName(props.escort.kid.attributes.ClassRoomID)}`}</label></div>
                                <div><label className="mb-0">{`Escort -`}</label><label className="mb-0 ml-3">{`- ${props.escort.type == 'P' ? props.escort.object.attributes.Name : props.escort.object.attributes.PersonName}, ${props.escort.type == 'P' ? props.escort.object.attributes.Relation : props.escort.object.attributes.Relation}`}</label></div>

                            </div>
                        </div>
                    </div>}
            </React.Fragment>
        )
    }

    return (
        <React.Fragment>
            {props.onlyQR ?
                <>{getOnlyQR()}</> :
                <div>
                    {(props.orientaion == "P") ? getPotrite() : getLandScape()}
                </div>}
        </React.Fragment>

    );
};

const mapStatetoProps = (state) => { return {}; };


export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {

        })(EscortIdCardUI)
    )
);