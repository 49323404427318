export const UPDATE_FILE = "UPDATE_FILE";
export const UPDATE_FILES = "UPDATE_FILES"; 

export const DELETE_FILE = "DELETE_FILE";
export const DELETE_FILES = "DELETE_FILES";

export const GET_FILE = "GET_FILE";
export const GET_FILES = "GET_FILES";

export const ADD_FILE = "ADD_FILE";

export const CLEAR_FILES = "CLEAR_FILES";
export const FILE_LOADING_STATE = "FILE_LOADING_STATE";

export const GET_FILES_FOR_COURSE_ID = "GET_FILES_FOR_COURSE_ID";

export const FILE_ADD_STATE = "FILE_ADD_STATE";

export const GET_NOTICE_BOARD_FILES_FOR_SCHOOL_ID = "GET_NOTICE_BOARD_FILES_FOR_SCHOOL_ID";
export const UPDATE_NOTICE_BOARD_FILES = "UPDATE_NOTICE_BOARD_FILES";
export const DELETE_NOTICE_BOARD_FILE = "DELETE_NOTICE_BOARD_FILE";
