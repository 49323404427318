import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import DatePicker from "react-datepicker";
import {
  Col,
  FormGroup,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  Row,
} from "reactstrap";

import {
  
} from "../../store/actions";
import Moment from "moment";


const RecurringModal = (props) => {

   
    const [showRecurringModal, setShowRecurringModal] = useState();
    const intervelList = [
        { label: "Select Payment Cycle", value: 0 },
        { label: "Monthly", value: 30 },
        { label: "Quarterly", value: 90 },
        { label: "Half Yearly", value: 180 }
    ];

    const today = new Date()

    const [selectedIntervel, setSelectedIntervel] = useState(undefined);

    const [occurrences, setOccurrences] = useState();

    const [lastData, setUpdateLastData] = useState(undefined);
    const [startDate, setUpdatestartDate] = useState(today)
    
    useEffect(() => {
        setShowRecurringModal(props.showRecurringModal)
    }, [props.showRecurringModal]);

    useEffect(() => {
        if (props.recurringObject) {
            setSelectedIntervel(props.recurringObject.selectedIntervel);
            setOccurrences(props.recurringObject.occurrences);
            setUpdateLastData(props.recurringObject.lastData)
            setUpdatestartDate(props.recurringObject.startDate)
        } else {
            setSelectedIntervel(undefined);
            setOccurrences(undefined);
            setUpdateLastData(undefined);
            setUpdatestartDate(today)
        }
    }, [props.recurringObject]);

 
    const onSelectPaymentCycle = (value) => {
        setSelectedIntervel(value);
        let d = new Date(startDate);
        if (value == 30) {
            setOccurrences(12)
            let d1= d.setMonth(d.getMonth() + 11);
            setUpdateLastData(d1);
        }
        if (value == 90) {
            setOccurrences(4)
            let d1= d.setMonth(d.getMonth() + 9);
            setUpdateLastData(d1);
        }
        if (value == 180) {
            setOccurrences(2)
            let d1= d.setMonth(d.getMonth() + 6);
            setUpdateLastData(d1);
        }
    }

    const updateDateBasedOnOccurnces = (occurrences,startDate) => {
        
        let d = new Date(startDate);
        let d1 
        if (selectedIntervel == 30) {
             d1= d.setMonth(d.getMonth() +( occurrences-1));
        }else  if (selectedIntervel == 90) {
             d1= d.setMonth(d.getMonth() + (occurrences-1)*3);
        }else  if (selectedIntervel == 180) {
            d1= d.setMonth(d.getMonth() + (occurrences-1)*6);
        }
        setUpdateLastData(d1);
    }

    return (
        <React.Fragment>
     
      
            <Modal isOpen={showRecurringModal}>
               

                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Custom recurrence")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => {
                            props.onCloseRecurringModal();
                        }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>

                    <Row>

                        <Col>
                            <FormGroup >
                                <Label className="mb-0">{props.t("Repeat every")} :</Label>
                                <select
                                    className="form-control "
                                    value={selectedIntervel}
                                    onChange={(e) => {
                                        if (e.target.value != 0) {
                                            onSelectPaymentCycle(e.target.value);
                                        }
                                    }}
                                >
                                    {intervelList.map((val, key) => (
                                        <option key={key} value={val.value}>
                                            {val.label}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label className="mb-0">{props.t("Reccuring starts On")} :</Label>
                                <DatePicker
                                    selected={startDate}
                                    onChange={(date) => {
                                        updateDateBasedOnOccurnces(occurrences,date)
                                        setUpdatestartDate(date);
                                    }}
                                    disabled={selectedIntervel > 0 ? false : true}
                                    className="form-control"
                                    placeholderText={props.t("Reccuring End On")}
                                   
                                    dateFormat="dd/MM/yyyy"
                                    minDate={new Date()}
                                    
                                    
                                />
                            </FormGroup>
                        </Col>
                    </Row>

                    <Row>

                        <Col>
                            <FormGroup>
                                <Label className="mb-0">{props.t("Occurrences")} :</Label>
                                <input
                                    type="number"
                                    name={`occurrences`}
                                    placeholder={props.t("Particular amount...")}
                                    className="form-control"
                                    value={occurrences}
                                    min={1}
                                    disabled={selectedIntervel > 0 ? false : true}
                                    onChange={(e) => {
                                        setOccurrences(e.target.value);
                                        updateDateBasedOnOccurnces(e.target.value,startDate)
                                    }}
                                />
                            </FormGroup>
                        </Col>
                        <Col>
                            <FormGroup>
                                <Label className="mb-0">{props.t("Reccuring End On")} :</Label>
                                <DatePicker
                                    selected={lastData}
                                    disabled={true}
                                    className="form-control"
                                    placeholderText={props.t("Reccuring End On")}
                                    dateFormat="dd/MM/yyyy"
                                    startDate={startDate}
                                />
                            </FormGroup>
                        </Col>
                    </Row>
                   
                </ModalBody>
                <ModalFooter>
          
                    

                    <button
                        className="btn btn-sm btn-secondary"
                        disabled={selectedIntervel ? false : true}
                        onClick={() => {
                            setSelectedIntervel(undefined);
                            setOccurrences(undefined);
                            setUpdateLastData(undefined);
                            setUpdatestartDate(new Date())
                            props.onSetRecurringObject(undefined);
                        }}
                    >
                        Clear
                    </button>

                    <button
                        className="btn btn-sm btn-primary"
                        disabled={selectedIntervel ? false : true}
                        onClick={() => {
                            let ld = new Date(lastData)
                            let sd = new Date(startDate);
                            props.onSetRecurringObject({
                                selectedIntervel: selectedIntervel,
                                occurrences: occurrences,
                                lastData: new Date(ld.getFullYear(), ld.getMonth(), ld.getDate(),23,59,59,999),
                                startDate:new Date(sd.getFullYear(), sd.getMonth(), sd.getDate(),7),
                            })
                        }}
                    >
                        Done
                    </button>
          
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
  return { };
};
export default connect(mapStatetoProps, {
 
})(withRouter(withNamespaces()(RecurringModal)))
