import React, { useEffect, useState } from 'react';

import { getTeachersForSchool, getClassroomsForSchoolId, sendInvite, editSchool, setSelecetdSchool, getAllKids, getDaycareAttendanceForKidIds } from '../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Container, Row, Col,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Nav,
    Card,
    CardBody,
    Label,
} from "reactstrap";
import Moment from "moment"
import { matchPath } from "react-router";
import img4 from "../../assets/images/covidDashboardIcons/img4.svg";

//i18n
import { withNamespaces } from 'react-i18next';
import TecaherList from './TecaherList';
import Topbar from './Topbar';
import * as Constants from '../../Constents';
import Parse from "parse";
import classnames from "classnames";
import Switch from "react-switch";
import ReactDatePicker from 'react-datepicker';

const TemperatureView = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/temperatureView/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const [staffTemperatureList, setStaffTemperatureList] = useState([]);
    const [studentTemperatureList, setStudentTemperatureList] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [activeTab, setactiveTab] = useState("1");
    const [selectedDate, setSelectedDate] = useState(new Date());
    const [kidIds, setKidIds] = useState([]);
    const [teacherIds, setTeacherIds] = useState([]);
    const [changingDate, setChangingDate] = useState(true);

    useEffect(() => {
        var tempTeacherList = [...props.teachers];
        let teacherIds = []
        for (const i in tempTeacherList) {
            teacherIds.push(tempTeacherList[i].id)
        }

        updateStaffLists(tempTeacherList, [], teacherIds);
    }, [props.teachers]);

    useEffect(() => {

        let tempClassroomList = [...props.classrooms]
        let kidIds = []
        for (const i in props.kids) {
            if (!kidIds.includes(props.kids[i].id)) {
                kidIds.push(props.kids[i].id)
            }
        }
        updateStudentLists(tempClassroomList, [], [], kidIds);
    }, [props.classrooms, props.kids]);



    useEffect(() => {
        props.getTeachersForSchool(schoolId);
        props.getClassroomsForSchoolId(schoolId);
        props.getAllKids(schoolId)

    }, [schoolId]);

    function convertDateToString(date) {
        return Moment(date).format('hh:mm A')
    }

    function updateStaffLists(tempTeacherList, teacherAttendanceRecords, teacherIds) {
        var DList = [];
        var CList = [];
        var TList = [];
        var OList = [];


        for (const i in tempTeacherList) {
            let tempObject = {

                parseObject: tempTeacherList[i],
                attendanceObject: undefined,
                id: tempTeacherList[i].id,
                checkInTime: '-',
                temperature: '-'
            }
            if (teacherAttendanceRecords && teacherAttendanceRecords.length > 0) {
                for (const j in teacherAttendanceRecords) {
                    if (teacherAttendanceRecords[j].attributes.teacherId === tempTeacherList[i].id) {
                        tempObject.attendanceObject = teacherAttendanceRecords[j]
                        tempObject.checkInTime = convertDateToString(teacherAttendanceRecords[j].attributes.ReachedSchoolAt)
                        tempObject.temperature = teacherAttendanceRecords[j].attributes.checkInTemperature ?? "Not Recorded"
                    }
                }
            }


            if (tempTeacherList[i].attributes.Role === 3) {
                DList.push(tempObject);
            } else if (tempTeacherList[i].attributes.Role === 2) {
                CList.push(tempObject);
            } else if (tempTeacherList[i].attributes.Role === 1) {
                TList.push(tempObject);
            } else {
                OList.push(tempObject);
            }

        }





        let tempMainList = [{ role: "Directors", list: DList }, { role: "Center Heads", list: CList }, { role: "Teachers", list: TList }, { role: "Other Staff", list: OList }]


        setStaffTemperatureList(tempMainList)

        if (teacherIds && teacherIds.length > 0) {
            setTeacherIds(teacherIds)
            getAttendanceForTeacherIds(teacherIds, selectedDate)
        }


    }

    function updateStudentLists(tempClassroomList, studentAttendanceRecords, studentDaycareAttendanceRecords, kidIds) {
        let tempMainList = []
        if (tempClassroomList && tempClassroomList.length > 0) {
            for (const i in tempClassroomList) {

                let tempKidList = []
                for (const j in props.kids) {

                    if (props.kids[j].attributes.ClassRoomID === tempClassroomList[i].id || props.kids[j].attributes.daycareClassId === tempClassroomList[i].id) {
                        let tempKidObject = {

                            parseObject: props.kids[j],
                            attendanceObject: undefined,
                            id: props.kids[j].id,
                            checkInTime: '-',
                            temperature: '-'
                        }
                        if (tempClassroomList[i].attributes.classroomType === 2) {

                            if (studentDaycareAttendanceRecords && studentDaycareAttendanceRecords.length > 0) {
                                for (const k in studentDaycareAttendanceRecords) {
                                    if (studentDaycareAttendanceRecords[k].attributes.kidId === tempKidObject.id) {
                                        tempKidObject.attendanceObject = studentDaycareAttendanceRecords[k]
                                        tempKidObject.checkInTime = convertDateToString(studentDaycareAttendanceRecords[k].attributes.checkInTime)
                                        tempKidObject.temperature = studentDaycareAttendanceRecords[k].attributes.checkInTemperature ?? "Not Recorded"
                                    }
                                }
                            }
                        } else {
                            if (studentAttendanceRecords && studentAttendanceRecords.length > 0) {
                                for (const k in studentAttendanceRecords) {
                                    if (studentAttendanceRecords[k].attributes.Kid === tempKidObject.id) {
                                        tempKidObject.attendanceObject = studentAttendanceRecords[k]
                                        tempKidObject.checkInTime = convertDateToString(studentAttendanceRecords[k].attributes.ReachedSchoolAt)
                                        tempKidObject.temperature = studentAttendanceRecords[k].attributes.checkInTemperature ?? "Not Recorded"
                                    }
                                }
                            }
                        }

                        tempKidList.push(tempKidObject)
                    }
                }

                tempMainList.push({ role: tempClassroomList[i].attributes.Name, list: tempKidList })
            }
        }

        setStudentTemperatureList(tempMainList)

        if (kidIds && kidIds.length > 0) {
            setKidIds(kidIds)
            getAttendanceForKidIds(kidIds, selectedDate)
        }
    }

    function getAttendanceForTeacherIds(teacherIds, selectedDate) {
        var query = new Parse.Query('TeacherAttendance');
        query.containedIn('teacherId', teacherIds);
        query.greaterThanOrEqualTo('createdAt', new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));
        query.lessThanOrEqualTo('createdAt', new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23,
            59,
            59,
            999));
        query.limit(2000);
        query.find().then((results) => {
            setChangingDate(false)
            if (results) {
                updateStaffLists([...props.teachers], results)
            }
        })
    }

    function getDaycareAttendanceForKidIds(kidIds, selectedDate, attendanceResultObjects) {
        var query = new Parse.Query('DaycareTimeSheet');
        query.containedIn('kidId', kidIds);
        query.greaterThanOrEqualTo('createdAt', new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));
        query.lessThanOrEqualTo('createdAt', new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23,
            59,
            59,
            999));

        query.limit(2000);
        query.find().then((results) => {
            setChangingDate(false)
            if (results) {
                updateStudentLists([...props.classrooms], attendanceResultObjects, results)
            }
        })
    }

    function getAttendanceForKidIds(kidIds, selectedDate) {
        var query = new Parse.Query('Attendance');
        query.containedIn('Kid', kidIds);
        query.greaterThanOrEqualTo('createdAt', new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 0, 0, 0));
        query.lessThanOrEqualTo('createdAt', new Date(selectedDate.getFullYear(), selectedDate.getMonth(), selectedDate.getDate(), 23,
            59,
            59,
            999));

        query.limit(2000);
        query.find().then((results) => {
            setChangingDate(false)
            if (results) {

                getDaycareAttendanceForKidIds(kidIds, selectedDate, results)

            }
            setIsLoading(false)

        })
    }


    const shouldShowEnableTemperatureSetting = () => {
        let returnValue = true

        if (props.selectedSchool && props.selectedSchool.attributes.featuresList && props.selectedSchool.attributes.featuresList.length > Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE) {
            if (props.selectedSchool.attributes.featuresList[Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE] != undefined && props.selectedSchool.attributes.featuresList[Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE] === 2) {
                returnValue = false
            }
        }

        return returnValue
    }

    const handleListLength = (value, list, indexNumber, directValue) => {
        var additionalSettingsList = [...list];

        if (list == null) {
            //additionalSettingsList = [0, 0, 0, 0, 0, parseInt(value, 10)]
        } else {
            if (additionalSettingsList.length > indexNumber) {
                additionalSettingsList[indexNumber] = directValue
                    ? value
                    : parseInt(value, 10);
            } else {
                //Manage if length is less than indexNumber
                var i = additionalSettingsList.length;
                for (i = additionalSettingsList.length; i < indexNumber + 1; i++) {
                    if (i === indexNumber) {
                        additionalSettingsList[i] = directValue
                            ? value
                            : parseInt(value, 10);
                    } else {
                        additionalSettingsList.push(0);
                    }
                }
            }
        }
        return additionalSettingsList;
    };


    return (

        <React.Fragment>


            <div className="page-content">

                {!isLoading && <Container fluid>

                    <Topbar title={props.t('Temperature')} schoolId={schoolId} hideButton={true} backButton={true} onClickBackButton={() => { window.history.back(); }} />

                    {shouldShowEnableTemperatureSetting() && <Card>
                        <CardBody className="text-center" >
                            <Row className="justify-content-center">
                                <Col sm={4}>
                                    <div className="maintenance-img">
                                        <img src={img4} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>
                            </Row>
                            <h4 className="mt-5">{props.t('Enable temperature recording for your school')}</h4>
                            <p>{props.t('')}</p>

                            <button type="button"
                                className="btn btn-primary "
                                onClick={() => {

                                    let schoolFeaturesList;
                                    if (props.selectedSchool.attributes.featuresList) {
                                        schoolFeaturesList = [...props.selectedSchool.attributes.featuresList];
                                    }
                                    if (schoolFeaturesList) {

                                        schoolFeaturesList = [
                                            ...handleListLength(
                                                2,
                                                schoolFeaturesList,
                                                Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE,
                                                true
                                            ),
                                        ];
                                        if (schoolFeaturesList[Constants.FEATURE_ATTENDANCE_INDEX] == undefined || schoolFeaturesList[Constants.FEATURE_ATTENDANCE_INDEX] === 0) {
                                            schoolFeaturesList = [
                                                ...handleListLength(
                                                    1,
                                                    schoolFeaturesList,
                                                    Constants.FEATURE_ATTENDANCE_INDEX,
                                                    true
                                                ),
                                            ];
                                        }
                                        props.selectedSchool.set("featuresList", schoolFeaturesList);
                                        props.editSchool(props.selectedSchool);
                                    }
                                }}
                            >
                                {props.t('Enable')}
                            </button>
                        </CardBody>
                    </Card>}

                    {!shouldShowEnableTemperatureSetting() && <><Card className="mb-0">
                        <CardBody>
                            <Nav pills className="navtab-bg nav-justified ">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "1",
                                        })}
                                        onClick={() => {
                                            setactiveTab("1");
                                        }}
                                    >
                                        {props.t('Students').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: activeTab === "2",
                                        })}
                                        onClick={() => {
                                            setactiveTab("2");
                                        }}
                                    >
                                        {props.t('Staff').toUpperCase()}
                                    </NavLink>
                                </NavItem>



                            </Nav>

                            <Col lg={4} md={4} sm={4} className="mt-3 mb-2">
                                <Label htmlFor="PickMonthTemperatureView">
                                    {"Select Date"}
                                </Label>




                                <ReactDatePicker

                                    id={"questionDatePickMonthTemperatureView"}
                                    selected={
                                        selectedDate ? new Date(selectedDate) : undefined
                                    }
                                    onChange={(date) => {
                                        setChangingDate(true)
                                        setSelectedDate(new Date(date))
                                        getAttendanceForKidIds(kidIds, new Date(date))
                                        getAttendanceForTeacherIds(teacherIds, new Date(date))

                                    }}
                                    className="form-control"
                                    placeholderText="dd/mm/yyyy"
                                    dateFormat="dd/MM/yyyy"


                                />

                                {changingDate && <h6 className="mt-2"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>Please wait</h6>}
                            </Col>
                        </CardBody>
                    </Card>
                        <TabContent activeTab={activeTab}>
                            <TabPane tabId="1">
                                {studentTemperatureList && studentTemperatureList.length > 0 && <TecaherList teachers={studentTemperatureList} isStudent={true} classrooms={props.classrooms} schoolId={schoolId} />}

                            </TabPane>
                            <TabPane tabId="2">
                                {staffTemperatureList && staffTemperatureList.length > 0 && <TecaherList teachers={staffTemperatureList} classrooms={props.classrooms} schoolId={schoolId} />}

                            </TabPane>



                        </TabContent></>}




                </Container>}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Temperatures...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { teachers } = state.Teacher;
    const { schools, selectedSchool } = state.School;
    const { kids } = state.Kid;
    const { classrooms } = state.Classroom;
    return { teachers, schools, classrooms, selectedSchool, kids };

}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { editSchool, getTeachersForSchool, getClassroomsForSchoolId, setSelecetdSchool, sendInvite, getAllKids })(TemperatureView)));