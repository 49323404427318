import * as actions from "./actionTypes";

const initialState = {
  expenseSuppliers: [], isLoadingExpenseSupplier: false
};

const ExpenseSupplier = (state = initialState, action) => {
  switch (action.type) {


    case actions.UPDATE_EXPENSE_SUPPLIERS:
      var expenseSuppliers = [...state.expenseSuppliers];
      if (expenseSuppliers.length > 0) {
        for (const latest in action.payload.expenseSuppliers) {
          var temp = expenseSuppliers.filter(
            (expenseSupplier) =>
              expenseSupplier.id === action.payload.expenseSuppliers[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.expenseSuppliers[latest].attributes.isDeleted) {

              var others = expenseSuppliers.filter(
                (expenseSupplier) =>
                  expenseSupplier.id !== action.payload.expenseSuppliers[latest].id
              );

              expenseSuppliers = others;
            } else {
              var index = expenseSuppliers.lastIndexOf(temp[0]);
              expenseSuppliers[index] = action.payload.expenseSuppliers[latest];
            }

          } else {
            if (!action.payload.expenseSuppliers[latest].attributes.isDeleted) {
              expenseSuppliers.push(action.payload.expenseSuppliers[latest]);
            }

          }
        }
        state = { ...state, expenseSuppliers: expenseSuppliers };
      } else {

        var others = action.payload.expenseSuppliers.filter(
          (expenseSupplier) =>
            expenseSupplier.attributes.isDeleted !== true
        );
        state = { ...state, expenseSuppliers: others };
      }

      break;

    case actions.UPDATE_EXPENSE_SUPPLIER:
      var expenseSuppliers = [...state.expenseSuppliers];
      var temp = state.expenseSuppliers.filter(
        (expenseSupplier) =>
          expenseSupplier.id === action.payload.expenseSupplier.id
      );
      if (temp.length > 0) {

        if (action.payload.expenseSupplier.attributes.isDeleted) {

          var others = state.expenseSuppliers.filter(
            (expenseSupplier) =>
              expenseSupplier.id !== action.payload.expenseSupplier.id
          );
          state = { ...state, expenseSuppliers: others };

        } else {
          var index = expenseSuppliers.indexOf(temp[0]);
          expenseSuppliers[index] = action.payload.expenseSupplier;
          state = { ...state, expenseSuppliers: expenseSuppliers };
        }


      } else {
        if (!action.payload.expenseSupplier.attributes.isDeleted) {
          expenseSuppliers.unshift(action.payload.expenseSupplier);
          state = { ...state, expenseSuppliers: expenseSuppliers };
        }

      }
      break;
    case actions.UPDATE_LOADING_EXPENSE_SUPPLIER:
      state = { ...state, isLoadingExpenseSupplier: action.payload.boolValue };
      break

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ExpenseSupplier;
