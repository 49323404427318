import Parse from "parse";

export const getLearningCurriculumSchoolMapForCurriculumId = (curriculumId) => {
    try {
        return new Promise((resolve, reject) => {
            var LearningCurriculumSchoolMap = Parse.Object.extend("LearningCurriculumSchoolMap");
            var query = new Parse.Query(LearningCurriculumSchoolMap);
            query.equalTo("curriculumId", curriculumId);
            query.notEqualTo("isDeleted", true);

            query.limit(10000);
            query.find().then(
                (result) => {
                    // console.log(result)
                    resolve(result);
                },
                (error) => {
                    // console.log(error)
                    resolve(error);
                }
            );
        });
    } catch (error) { }
};

export const getLearningCurriculumSchoolMapForSchoolId = (schoolId) => {
    try {
        return new Promise((resolve, reject) => {
            var LearningCurriculumSchoolMap = Parse.Object.extend("LearningCurriculumSchoolMap");
            var query = new Parse.Query(LearningCurriculumSchoolMap);
            query.equalTo("schoolId", schoolId);
            query.notEqualTo("isDeleted", true);

            query.limit(10000);
            query.find().then(
                (result) => {
                    // console.log(result)
                    resolve(result);
                },
                (error) => {
                    // console.log(error)
                    resolve(error);
                }
            );
        });
    } catch (error) { }
};

export const getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId = (schoolId,curriculumId) => {
    try {
        return new Promise((resolve, reject) => {
            var LearningCurriculumSchoolMap = Parse.Object.extend("LearningCurriculumSchoolMap");
            var query = new Parse.Query(LearningCurriculumSchoolMap);
            query.equalTo("schoolId", schoolId);
            query.equalTo("curriculumId", curriculumId);
            query.notEqualTo("isDeleted", true);

            query.limit(10000);
            query.first().then(
                (result) => {
                    // console.log(result)
                    resolve(result);
                },
                (error) => {
                    // console.log(error)
                    resolve(error);
                }
            );
        });
    } catch (error) { }
};


export const saveLearningCurriculumSchoolMap = (CurriculumSchoolMaps) => {
    try {
        return new Promise((resolve, reject) => {
            Parse.Object.saveAll(CurriculumSchoolMaps).then(
                (result) => {
                    resolve(result);
                }

            );

        });
    } catch (error) { }
}