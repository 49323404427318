import Parse from "parse";
import { printLog } from "./loghelper";

export const getLiveMeetingForBatch = (courseId, batchId) => {
  printLog("HL: liveMeeting_helper : getLiveMeetingForBatch")

    try {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("LiveMeeting");
            query.equalTo("batchId", batchId);
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.descending("createdAt");
            query.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(null);
                }
            )
        })
    } catch (error) { }
}

export const saveLiveMeeting = (object) => {
  printLog("HL: liveMeeting_helper : saveLiveMeeting")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
  
    });
  } catch (error) { }
}