import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Row, Col, Table, UncontrolledTooltip, Label,Modal,ModalBody, ModalFooter,FormGroup,Form } from "reactstrap";
import Image from "../../../assets/images/book_lover.svg";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import { Link } from "react-router-dom";
import Moment from 'moment';

import { AvForm, AvField, } from "availity-reactstrap-validation";
import Parse from 'parse';
import DatePicker from "react-datepicker";


import * as CommonFunctions from "../../../components/Common/CommonFunctions";
import {
    setSelecetdSchool,
    setSelectedCourse,
    getCourseBatchesForCourseId,
    addCourseBatch,
} from "../../../store/actions";

import TopBar from '../TopBar';


const BatchList = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/course-batch/:schoolId/:courseId',
        exact: true,
        strict: false,

    });

    const schoolId = match ? match.params.schoolId : "";
    const courseId = match ? match.params.courseId : "";

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.getCourseBatchesForCourseId(courseId);
        props.setSelectedCourse(courseId);
    }, [courseId]);

   

    
                                                                        
    const [seleteCourse, setDeleteCourseBatch] = useState(undefined);
    const [showModel, setShowModel] = useState(false);
    const [editCourseObject, setEditCourseObject] = useState(undefined);

    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    
    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);

     useEffect(() => {
        if (!props.addCourseBatchLoading) {
            setEditCourseObject(undefined);
            setFromDateError(false);
            setFromDate(undefined);
            setToDateError(false);
            setToDate(undefined);
        }
    }, [props.addCourseBatchLoading]);

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }

    const handelAddCouseBatch = () => {

        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(),0,0,0);
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59);
        
        console.log(startDate);
        console.log(endDate);

        let CourseBatch = Parse.Object.extend("CourseBatch");
        let courseBatch = new CourseBatch();
        if (editCourseObject) {
            courseBatch = editCourseObject;
        }
        courseBatch.set("startDate", startDate);
        courseBatch.set("endDate", endDate);
        courseBatch.set("courseId", courseId);
        
        props.addCourseBatch(courseBatch);
        setShowModel(false);
        
    }

    const handelOnClickBack = () => {
        window.history.back();
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <TopBar title={"COURSE BATCHES"}
                        buttonName={"Add Batch"}
                        backButton={true}
                        hideButton={false}
                        onClickAdd={()=>{setShowModel(true);}}
                        reorderButton={false}
                        onClickBackButton={handelOnClickBack}
                    />
                    <Card>
                        <CardBody>
                            <h5 className="text-truncate font-size-14  mb-1 text-dark">{props.selectedCourse ? "Course Name: " + props.selectedCourse.attributes.name : "Course Name: "}</h5>
                        </CardBody>
                    </Card>
                    {props.courseBatchInitialLoding && <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Label to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
                            </div>
                        </Col>
                    </Row>}
                    {(!props.courseBatch || props.courseBatch.length === 0) && !props.courseBatchInitialLoding && <Card>
                        <CardBody className="text-center" >
                            <Row className="justify-content-center">
                                <Col sm={4}>
                                    <div className="maintenance-img">
                                        <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>
                            </Row>
                            <h3 className="mt-5">{props.t('No Batches for Course')}</h3>

                            <button type="button"
                                className="btn btn-primary "
                                onClick={() => {
                                    setShowModel(true);
                                }}  >
                                {props.t('Add Batch')}
                            </button>
                        </CardBody>
                    </Card>
                    }

                    {props.courseBatch && props.courseBatch.length > 0 && <Row >
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="col-1" scope="col" style={{ width: "100px" }}>#</th>
                                                <th className="col-9" scope="col">Batch Name</th>
                                                <th className="col-2" scope="col" align="right">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.courseBatch.map((cb, key) =>
                                                <tr key={cb.id} >
                                                    <td><img src={Image} alt="" className="avatar-sm" /></td>
                                                    {

                                                        <td
                                                            style={{ cursor: "pointer" }}
                                                            // onClick={() => {
                                                            //     props.onSelectCourse(course.id);
                                                            //     props.history.push("/course-participants/" + schoolId + "/"+course.id);
                                                            //     props.history.push("/lms-syllabus/" + schoolId + "/" + course.id);
                                                            // }}
                                                        >
                                                            <h5 className="text-truncate font-size-14"><Link to={"/course-batch-details/"+schoolId+"/"+courseId+"/"+cb.id} className="text-dark">{getTimeStringForDate(cb.attributes.startDate)+" to "+ getTimeStringForDate(cb.attributes.endDate)}</Link></h5>
                                                            {/* <p className="text-muted mb-0">{"getOwnerName(course)"}</p>
                                                            <p className="text-success">{cb.attributes.state}</p> */}

                                                           
                                                        </td>
                                                    }
                                                    <td>
                                                        {

                                                            <ul className="list-inline font-size-20 contact-links mb-0 float-right">

                                                                 {/* <button
                                                                    className="btn btn-primary btn-sm ml-2"
                                                                    onClick={() => {
                                                                        props.history.push("/course-participants/" + schoolId + "/" + cb.attributes.courseId+"/"+cb.id);
                                                                    }}>

                                                                    Subscribers
                                                                </button> */}
                                                                {CommonFunctions.canEdit(props.selectedCourse, props.userTeacher) &&
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setEditCourseObject(cb);
                                                                            setFromDate(cb.attributes.startDate);
                                                                            setToDate(cb.attributes.endDate);
                                                                            setFromDateError(false);
                                                                            setToDateError(false);
                                                                            setShowModel(true);
                                                                        }}
                                                                        id={"sub-edit" + key}>
                                                                        <i className="bx bx-edit-alt"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-edit" + key}>
                                                                            {props.t('Edit')}
                                                                        </UncontrolledTooltip>
                                                                
                                                                    </li>}

                                                                {CommonFunctions.canEdit(props.selectedCourse, props.userTeacher) && <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setDeleteCourseBatch(cb);
                                                                        
                                                                    }}
                                                                    id={"sub-delete" + key}>
                                                                    <i className="bx bx-trash text-danger"></i>
                                                                    <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                        {props.t('Delete')}
                                                                    </UncontrolledTooltip>

                                                                </li>

                                                                }
                                                                <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        // props.onSelectCourse(course.id);
                                                                        props.history.push("/course-batch-details/"+schoolId+"/"+courseId+"/"+cb.id);
                                                                    }}
                                                                    id={"sub-cyc" + key}>
                                                                    <i className="bx bx-right-arrow-circle"></i>
                                                                    <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                        {props.t('Details')}
                                                                    </UncontrolledTooltip>
                                                                </li>
                                                            </ul>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                    </Row>}
                    

                    {/* {ADD BATCH POPUP} */}

                   
                    <Modal
                        isOpen={showModel || props.addCourseBatchLoading}
                        toggle={() => { setShowModel(!showModel) }}
                    >
                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myLargeModalLabel"
                            >
                               {editCourseObject?"Edit Batch":" Add Batch"}
                            </h5>

                            <button
                                onClick={() => {
                                    setShowModel(false)
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <ModalBody >               
                            <Form>
                                <Col className="col-12">
                                    <FormGroup className="mt-2 mb-2">
                                        <Label >
                                            {props.t("Batch starts on")} :
                                        </Label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => {
                                                setFromDateError(false)
                                                setFromDate(date)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Date")}
                                            startDate={fromDate}
                                            endDate={toDate}
                                            maxDate={toDate}
                                            dateFormat="dd-MM-yyyy"
                                            required
                                            errorMessage="Please select"
                                        />
                                    </FormGroup>
                                    {fromDateError && <p className="text-danger ml-2">Please select</p>}
                                </Col>
                                <Col className="col-12" >
                                    <FormGroup className="mt-2 mb-2 ">
                                        <Label >
                                            {props.t("Batch ends on")} :
                                        </Label>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => {
                                                setToDateError(false);
                                                setToDate(date)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Date")}
                                            startDate={fromDate}
                                            endDate={toDate}
                                            minDate={fromDate}
                                            dateFormat="dd-MM-yyyy"
                                            required
                                            errorMessage="Please select"
                                        />
                                    </FormGroup>
                                    {toDateError && <p className="text-danger ml-2">Please select</p>}
                                </Col>
                            
                            </Form>
                                   
                            
                            
                 
                            

                        </ModalBody>
                       
                    

                        <ModalFooter>

                            {props.addCourseBatchLoading ?
                                <button type="button" className="btn btn-light waves-effect">
                                    <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>
                                    {props.t(" Saving....")}
                                </button>

                                :
                                <button
                                    onClick={(e, v) => {
                                        let next = true;
                                        if (!toDate) {
                                            setToDateError(true);
                                            next = false;
                                        }
                                        if (!fromDate) {
                                            setFromDateError(true);
                                            next = false;
                                        }
                                        if (next) {
                                            handelAddCouseBatch()
                                                
                                        }
                            
                                    }}
                                    className="w-md  btn btn-primary float-right"
                                >
                                    {editCourseObject?props.t("Save"):props.t("Add")}
                                </button>
                            }

                        </ModalFooter>
                    </Modal>

                    

                </Container>
            </div>
        </React.Fragment>
    );
}


const mapStatetoProps = (state) => {
    const { userTeacher } = state.Login;
    const { selectedSchool } = state.School;
    const { selectedCourse } = state.Course;
    const { courseBatch,courseBatchInitialLoding,addCourseBatchLoading } = state.CourseBatch;
    const { teachers } = state.Teacher;

    return { userTeacher, selectedSchool, selectedCourse,courseBatch,courseBatchInitialLoding,addCourseBatchLoading, teachers };
};


export default connect(mapStatetoProps, {
    setSelecetdSchool,
    setSelectedCourse,
    getCourseBatchesForCourseId,
    addCourseBatch
})(withNamespaces()(BatchList));
