import React, { useState, useEffect } from 'react';;
import { Row, Col, Button, Input, Container, Label, Card, CardBody, FormGroup, } from "reactstrap";
import moment from 'moment';
import KidPrizeCertificate from '../../../assets/images/certificate/kidPrizeCertificate.png'
import PrizeCertificate1 from '../../../assets/images/certificate/prizeCertificate1.png';
import PrizeCertificate2 from '../../../assets/images/certificate/prizeCertificate2.png';
import PrizeCertificate3 from '../../../assets/images/certificate/prizeCertificate3.png';
import PrizeCertificate4 from '../../../assets/images/certificate/prizeCertificate4.png';
import * as WhiteLable from "../../../components/Common/WhiteLable";

const prize = (props) => {
    return (
        <div className="certificate-card"
            style={{
                width: '90%',
                height: '90%',
                padding: '20px',
                borderRadius: '10px',
            }}>
            <div
                className="certificate-container"
            // style={{
            //   margin: '0 auto',
            //   padding: '20px',
            //   backgroundImage:
            //     props.styleType === '1'
            //       ? `url(${KidPrizeCertificate})`
            //       : props.styleType === '2'
            //       ? `url(${PrizeCertificate2})`
            //       : props.styleType === '3'
            //       ? `url(${PrizeCertificate3})`
            //       : props.styleType === '4'
            //       ? `url(${PrizeCertificate4})`
            //       : undefined,
            //   backgroundSize: 'cover',
            //   backgroundPosition: 'center',
            //   boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            //   width: '800px',
            //   height: '500px',
            //   display: 'flex',
            //   flexDirection: 'column',
            //   justifyContent: 'center',
            //   alignItems: 'center',
            // }}
            >
                <style>
                    {`
      .certificate-container {
        margin: 0 auto;
        padding: 20px;
        width: 820px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: ${props.styleType === '1'
                            ? `url(${KidPrizeCertificate})`
                            : props.styleType === '2'
                                ? `url(${PrizeCertificate2})`
                                : props.styleType === '3'
                                    ? `url(${PrizeCertificate3})`
                                    : props.styleType === '4'
                                        ? `url(${PrizeCertificate4})`
                                        : 'none'
                        };
        background-size: cover;
        background-position: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
      }


      .no-print {
        display: none;
      }

      @media print {
        body {
          margin: 0;
          padding: 0;
        }

        .certificate-container {
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          overflow: hidden;
          z-index: 9999;
        }

        @page {
          size: A4 landscape;
          margin: 0;
        }
      }
      `}
                </style>
                <div>
                    <Row className='text-center'>
                        {props.selectedSchool && props.selectedSchool.attributes.logo && (
                            <Col className='text-center'>
                                {" "}
                                <img className='text-center'
                                    src={props.selectedSchool && props.selectedSchool.attributes.logo._url}
                                    style={{ height: "6rem" }}
                                />{" "}
                            </Col>
                        )}
                    </Row>
                    <Row className='py-4'>
                        <Col lg={12} className="d-flex align-items-center justify-content-center">
                            <h1 className="text-center" style={{ fontSize: '2.5rem', letterSpacing: '0.1rem', color: 'black', fontFamily: 'Georgia, serif' }}>
                                C E R T I F I C A T E
                            </h1>
                        </Col>
                    </Row>
                    {<Row className="py-4">
                        <Col lg={12} className="d-flex align-items-center justify-content-center">
                            {props.prizeType === "1" && <h6 className="text-center font-size-18" style={{ letterSpacing: '0.1rem', color: 'black', fontFamily: 'Georgia, serif' }}>
                                OF   P A R T I C I P A T I O N
                            </h6>}
                        </Col>
                    </Row>}
                    <div className="text-center">
                        <h4 className="text-center font-size-17">This certificate is presented to</h4>
                        <b className="text-center font-size-24" style={{ borderBottom: '2px solid black', fontFamily: 'cursive', fontStyle: 'oblique', textTransform: 'uppercase' }}>{props.name}</b>
                        <h6 className='py-4 font-size-16 text-center'>
                            by <b>{props.selectedSchool && props.selectedSchool.attributes.Name}</b> for participating in the<b> {""}{props.event} {""}</b> competition  {props.prizeType === "2" && <h6 className='font-size-16'>and secured <b>First prize</b>.</h6>}
                            {props.prizeType === "3" && <h6 className='font-size-16'>and secured <b>Second prize</b>.</h6>}{props.prizeType === "4" && <h6 className='font-size-16'>and secured <b>Third prize</b>.</h6>}
                        </h6>
                    </div>
                    <div className='py-4'  >
                        <Row className='align-items-center'>
                            <Col className='d-flex flex-column align-items-center'>
                                <h6 style={{ margin: '0', fontSize: '0' }}>{""}</h6>
                                <span style={{ borderTop: '1px solid black', width: '3cm', textAlign: 'center', lineHeight: '1.2' }}>Signature</span>
                            </Col>
                            <Col className='d-flex flex-column align-items-center'>
                                <h6 style={{ margin: '0' }}>{moment(props.date).format("DD/MM/YYYY")}</h6>
                                <span style={{ borderTop: '1px solid black', width: '3cm', textAlign: 'center' }}>Date</span>
                            </Col>
                        </Row>
                    </div>
                    {false && <Row className='float-right'>
                        <Col>
                            <Label className="logo logo-dark float-right">
                                <img src={WhiteLable.getAppLogoWithName()} alt="" className="avatar " height={'15'} />
                            </Label>
                        </Col>
                    </Row>}
                </div>
                {/* </div> */}
            </div>
        </div>
    )
}


export default prize




