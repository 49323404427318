import * as actions from "./actionTypes";

export const getInventoryPurchaseOrderItemsForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_PURCHASE_ORDER_ITEMS,
    payload: {ownerId, ownerType},
    }
}

export const updateInventoryPurchaseOrderItems = (inventoryPurchaseOrderItems) =>{
    return{
        type:actions.UPDATE_INVENTORY_PURCHASE_ORDER_ITEMS,
        payload: {inventoryPurchaseOrderItems},
    }
}

export const addInventoryPurchaseOrderItem = (inventoryPurchaseOrderItem) =>{
    return{
        type: actions.ADD_INVENTORY_PURCHASE_ORDER_ITEM,
        payload: {inventoryPurchaseOrderItem}
    }
}

export const deleteInventoryPurchaseOrderItem = (inventoryPurchaseOrderItem) =>{
    return{
        type:actions.DELETE_INVENTORY_PURCHASE_ORDER_ITEM,
        payload: {inventoryPurchaseOrderItem},
    }
}

export const updateDeleteInventoryPurchaseOrderItem = (inventoryPurchaseOrderItem) =>{
    return{
        type:actions.UPDATE_DELETE_INVENTORY_PURCHASE_ORDER_ITEM,
        payload: {inventoryPurchaseOrderItem},
    }
}

export const setSelectedPurchaseOrderItems = (inventoryPurchaseOrderItemId) => {
    return {
        type: actions.SET_SELECTED_PURCHASE_ORDER_ITEMS,
        payload: {inventoryPurchaseOrderItemId }
    }
}

export const updateSelectedPurchaseOrderItems = (inventoryPurchaseOrderItems) => {
    return {
      type: actions.UPDATE_SELECTED_PURCHASE_ORDER_ITEMS,
      payload: { inventoryPurchaseOrderItems },
    };
  };

  export const editPurchaseOrderItem = (inventoryPurchaseOrderItems) =>{
    return{
        type: actions.EDIT_INVENTORY_PURCHASE_ORDER_ITEM,
        payload: {inventoryPurchaseOrderItems}
    }
}