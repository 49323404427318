import * as actions from "./actionTypes";

const initialState = {
  courses: [], initialLoding: true, selectedCourse: undefined,
};

const Course = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_COURSE:
      var courses = [...state.courses];
      var temp = state.courses.filter(
        (course) =>
        course.id === action.payload.course.id
      );
      if (temp.length > 0) {

        if (action.payload.course.attributes.isDeleted) {

          var others = state.courses.filter(
            (course) =>
            course.id !== action.payload.course.id
          );
          state = { ...state, courses: others };
          
        } else {
          var index = courses.indexOf(temp[0]);
          courses[index] = action.payload.course;


          if (
            state.selectedCourse &&
            state.selectedCourse.id === action.payload.course.id
          ) {
            state = {
              ...state,
              courses: courses,
              selectedCourse: action.payload.course,
            };
          } else {
            state = { ...state, courses: courses };
          }

          
        }

       
      } else {
        if (!action.payload.course.attributes.isDeleted) {
            var list =[action.payload.course].concat(courses);
          state = { ...state, courses: list };
        }
        
      }
      break;
    case actions.UPDATE_COURSES:
      if (action.payload.replace) {
        state = { ...state, courses: action.payload.courses };
      } else {
        var courses = [...state.courses];
        if (courses.length > 0) {
          for (const latest in action.payload.courses) {
            var temp = courses.filter(
              (course) =>
              course.id === action.payload.courses[latest].id
            );
            if (temp.length > 0) {
              if (action.payload.courses[latest].attributes.isDeleted) {

                var others = courses.filter(
                  (course) =>
                  course.id !== action.payload.courses[latest].id
                );
               
                courses = others;
              } else {
                var index = courses.lastIndexOf(temp[0]);
                courses[index] = action.payload.courses[latest];
              }
            } else {
              courses.push(action.payload.courses[latest]);
            }
          }
          state = { ...state, courses: courses, };
        } else {
          var others = action.payload.courses.filter(
            (course) => course.attributes.isDeleted !== true
          );
            
          state = { ...state, courses: others , };
        }
      }
      break;
    case actions.DELETE_COURSE:
      var courses = state.courses.filter(
        (course) => course.id !== action.payload.id
      );
      state = { ...state, courses: courses };
      
      break;
    
      case actions.UPDATE_COURSE_INITIAL_LOADING:
        
        state = { ...state, initialLoding: action.payload.state };
        
      break;
    
      case actions.UPDATE_COURSE_INITIAL_LOADING:
        
        state = { ...state, courses: [] };
        
      break;
    case actions.UPDATE_SELECTED_COURSE:
      state = { ...state, selectedCourse: action.payload.course };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Course;
