import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
  curriculums: [], initialLoding: true, selectedCurriculum: undefined,
};

const Curriculum = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_CURRICULUM:
      var curriculums = [...state.curriculums];
      var temp = state.curriculums.filter(
        (curriculum) =>
          curriculum.id === action.payload.curriculum.id
      );
      if (temp.length > 0) {

        if (action.payload.curriculum.attributes.isDeleted) {

          var others = state.curriculums.filter(
            (curriculum) =>
              curriculum.id !== action.payload.curriculum.id
          );
          state = { ...state, curriculums: others };

        } else {
          var index = curriculums.indexOf(temp[0]);
          curriculums[index] = action.payload.curriculum;


          if (
            state.selectedCurriculum &&
            state.selectedCurriculum.id === action.payload.curriculum.id
          ) {
            state = {
              ...state,
              curriculums: curriculums,
              selectedCurriculum: action.payload.curriculum,
            };
          } else {
            state = { ...state, curriculums: curriculums };
          }


        }


      } else {
        if (!action.payload.curriculum.attributes.isDeleted) {
          var list = [action.payload.curriculum].concat(curriculums);
          state = { ...state, curriculums: list };
        }

      }
      break;
    case actions.UPDATE_CURRICULUMS:
      var curriculums = [...state.curriculums];

      if (curriculums.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.curriculums], action.payload.curriculums, "isDeleted", false)
        state = { ...state, curriculums: updatedList };
      } else {
        var others = action.payload.curriculums.filter(
          (curriculums) => curriculums.attributes.isDeleted !== true
        );
        state = { ...state, curriculums: others };
      }
      break;
    case actions.DELETE_CURRICULUM:
      var curriculums = state.curriculums.filter(
        (curriculum) => curriculum.id !== action.payload.id
      );
      state = { ...state, curriculums: curriculums };

      break;

    case actions.UPDATE_CURRICULUM_INITIAL_LOADING:

      state = { ...state, initialLoding: action.payload.state };

      break;

    case actions.UPDATE_CURRICULUM_INITIAL_LOADING:

      state = { ...state, curriculums: [] };

      break;
    case actions.UPDATE_SELECTED_CURRICULUM:
      state = { ...state, selectedCurriculum: action.payload.curriculum };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Curriculum;
