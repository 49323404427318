import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as ProgramHelper from "../../../helpers/ParseHelpers/program_helper";
import * as actions from "./actionTypes";
import * as Constants from '../../../Constents';
import * as ProgramActions from './actions';
import Parse from 'parse';

function* getAProgram({ payload: { programId } }) {
  try {
    const response = yield call(ProgramHelper.getProgramFromParse, programId);
    yield put(ProgramActions.updateProgram(response));
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deleteAProgram({ payload: { program } }) {
  try {
    const response = yield call(ProgramHelper.deleteProgramFromParse, program);
    if (response) {
      yield put(ProgramActions.updateProgram(response));
      yield put(ProgramActions.setDeletedSuccessfullyProgram(true));

    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* saveProgramToParse({ payload: { program } }) {
  try {
    const response = yield call(ProgramHelper.saveProgramToParse, program);
    if (response) {
      yield put(ProgramActions.updateSelectedProgram(response));
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }

}



function* getProgramsForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.PROGRAM_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    var isNoProgramsFromLocal = false;
    const localResponse = yield call(ProgramHelper.getProgramsForSchoolId, schoolId, null, true);
    if (localResponse && localResponse.length > 0) {
      yield put(ProgramActions.updateprograms(localResponse));

    } else {
      isNoProgramsFromLocal = true
    }


    const serverResponse = yield call(ProgramHelper.getProgramsForSchoolId, schoolId, updatedTime, false);

    yield put(ProgramActions.setProgramLoading(false));

    if (isNoProgramsFromLocal && (serverResponse == undefined || serverResponse.length === 0)) {
      yield put(ProgramActions.setNoPrograms(true));
    } else {
      yield put(ProgramActions.setNoPrograms(false));

    }
    if (serverResponse && serverResponse.length > 0) {



      localStorage.setItem(key, newTime)
      yield put(ProgramActions.updateprograms(serverResponse));
      Parse.Object.pinAll(serverResponse);
    } else {

    }


  } catch (error) {
    // yield put(apiError(error));
  }
}



function* getProgramsForGroupIdSaga({ payload: { groupId } }) {
  try {




    const serverResponse = yield call(ProgramHelper.getProgramForGroupId, groupId);

    if (serverResponse) {
      yield put(ProgramActions.updateSelectedProgram(serverResponse));
    }


  } catch (error) {
    // yield put(apiError(error));
  }
}


//******************* PROGRAM TERMS *******************//


function* getProgramTermsForProgramIdSaga({ payload: { programId } }) {
  try {
    const serverResponse = yield call(ProgramHelper.getProgramTermsForProgramId, programId);
    if (serverResponse) {
      yield put(ProgramActions.updateProgramTermsForProgramId(serverResponse));
    }
  } catch (error) {
  }
}

function* editProgramTermsSaga({ payload: { terms } }) {
  try {
    const response = yield call(ProgramHelper.editProgramTerms, terms);
    if (response) {
      yield put(ProgramActions.updateProgramTermsForProgramId(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchEditProgramTermsSaga() {
  yield takeEvery(actions.EDIT_PROGRAM_TERMS, editProgramTermsSaga)
}

export function* watchGetProgramTermsForProgramIdSaga() {
  yield takeEvery(actions.GET_PROGRAM_TERMS_FOR_PROGRAM_ID, getProgramTermsForProgramIdSaga);
}

export function* watchGetProgram() {
  yield takeEvery(actions.UPDATE_PROGRAM, getAProgram);
}
export function* watchGetProgramsForGroupIdSaga() {
  yield takeEvery(actions.GET_PROGRAM_FOR_GROUPID, getProgramsForGroupIdSaga);
}

export function* watchSaveProgramToParse() {
  yield takeEvery(actions.SAVE_SELECTED_PROGRAM, saveProgramToParse);
}

export function* watchDeleteprogram() {
  yield takeEvery(actions.DELETE_PROGRAM, deleteAProgram);
}
export function* watchGetProgramsForSchoolId() {
  yield takeEvery(actions.GET_PROGRAMS, getProgramsForSchoolId);
}


function* programSaga() {
  yield all([fork(watchGetProgram), fork(watchDeleteprogram), fork(watchGetProgramsForSchoolId), fork(watchGetProgramsForGroupIdSaga), fork(watchSaveProgramToParse),
  fork(watchGetProgramTermsForProgramIdSaga),
  fork(watchEditProgramTermsSaga)


  ]);
}
export default programSaga;
