import React from 'react';
import { Radar, Pie } from 'react-chartjs-2';

const PieChart = (props) => {
    const colors = ["#34c38f", "#f1b44c", "rgba(244, 244, 244, 1.0)"]
    const data = {
        labels: props.measureSkillLabels ?? [],
        datasets: [
            {
                label: props.measureName,
                backgroundColor: colors,
                borderColor: "#fff",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#fff",
                data: props.measureSkillDatas ?? []
            },
        ]
    };

    return (
        <Pie width={474} height={300} data={data} />
    );
}

export default PieChart;