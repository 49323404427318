import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import Moment from "moment";
import DatePicker from "react-datepicker";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import * as Constants from "../../Constents";
import * as ReduxUtil from "../../store/ReduxUtil"
import SweetAlert from "react-bootstrap-sweetalert";
import { Row, Col, Card, CardBody, Table, Label, Button, Input, Pagination, PaginationItem, PaginationLink, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup } from "reactstrap";
import { setSelecetdSchool, toggleLeftmenu, getSchoolsForTools, getSchoolAnalyticsForSchoolObject } from "../../store/actions";
import PaginationView from '../../components/Common/PaginationView';

const schoolSubscription = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/tools-schools-analytics/",
        exact: true,
        strict: false,
    });

    const PAGE_SIZE = 10;

    const [displayPageSchools, setDisplayPageSchools] = useState([]);
    const [loading, setLoading] = useState(false);
    const [currentPage, setCurrentPage] = useState("1");

    const [schoolAnalyticsData, setSchoolAnalyticsData] = useState([])
    const [filterData, setFilterData] = useState([])
    const [searchSchoolAnalyticsdata, setSearchSchoolAnalyticsdata] = useState([])
    const [selectedStatus, setSelectedStatus] = useState(1)

    useEffect(() => {
        setLoading(true)
        const query = new Parse.Query('SchoolAnalytics');
        query.limit(5000);
        query.find().then((result) => {
            console.log(result);
            setSchoolAnalyticsData(result)
            setLoading(false)

        })
            .catch(error => {

            });

    }, [])


    useEffect(() => {
        const currentDate = new Date();
        const sevenDaysAgo = new Date(currentDate);
        sevenDaysAgo.setDate(sevenDaysAgo.getDate() - 7);

        let filteredSchools = [];

        for (const school of schoolAnalyticsData) {
            if (selectedStatus == 1) {
                filteredSchools.push(school);
            }
            const lastMessageSent = school.attributes.lastMessageSent;

            if (lastMessageSent) {
                const timeDifference = Math.abs(currentDate.getTime() - lastMessageSent.getTime());
                const differenceInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

                if (selectedStatus == 2) {
                    if ((differenceInDays <= 7 && lastMessageSent >= sevenDaysAgo) || differenceInDays == 0) {
                        filteredSchools.push(school);
                    }
                } else if (selectedStatus == 3) {
                    if (differenceInDays > 7 && differenceInDays <= 15) {
                        filteredSchools.push(school);
                    }
                } else if (selectedStatus == 4) {
                    if (differenceInDays > 15 && differenceInDays <= 30) {
                        filteredSchools.push(school);
                    }
                } else if (selectedStatus == 5) {
                    if (differenceInDays > 30) {
                        filteredSchools.push(school);
                    }
                }
            }
        }

        setFilterData(filteredSchools);
        setSearchSchoolAnalyticsdata(filteredSchools)

    }, [schoolAnalyticsData, selectedStatus]);

    function searchData(search) {
        const tempList = [...searchSchoolAnalyticsdata];
        if (search === undefined || search === null || search.length === 0) {
            setFilterData(tempList);
        } else if (tempList) {
            let filter = tempList.filter((school) => {
                return (
                    school.attributes &&
                    school.attributes.schoolName &&
                    school.attributes.schoolName.toLowerCase().includes(search.toLowerCase())
                ) || (
                        school.attributes &&
                        school.attributes.schoolId &&
                        search === school.attributes.schoolId
                    );
            });
            setFilterData(filter);
        }
    }


    const getDate = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };


    const getMessage = (lastMessageSent) => {
        if (!lastMessageSent) {
            return { message: '--', color: 'black' };
        }

        const currentDate = new Date();
        const timeDifference = Math.abs(currentDate.getTime() - lastMessageSent.getTime());
        const differenceInDays = Math.ceil(timeDifference / (1000 * 3600 * 24));

        if (differenceInDays <= 7) {
            return { message: 'Active', color: 'green' };
        } else if (differenceInDays > 7 && differenceInDays <= 15) {
            return { message: 'Active in 7 days ago', color: 'black' };
        } else if (differenceInDays > 15 && differenceInDays <= 30) {
            return { message: 'Active in 15 days ago', color: 'black' };
        } else {
            return { message: 'Inactive', color: 'red' };
        }
    };




    useEffect(() => {
        let is = (parseInt(currentPage) - 1) * PAGE_SIZE;
        let ie = (parseInt(currentPage) * PAGE_SIZE) - 1;
        if (ie > filterData.length) {
            ie = filterData.length
        }
        if (ie != 0) {
            let tempSchools = [];
            for (let i = is; i <= ie; i++) {
                if (filterData.length > i) {
                    tempSchools.push(filterData[i]);
                }
            }
            setDisplayPageSchools(tempSchools)
        } else {
            setDisplayPageSchools([]);
        }

    }, [filterData, currentPage]);

    return (
        <div className='page-content  w-100 '  >
            <div>
                <Row>
                    <Col lg={5} md={6} xs={12}>
                        <div className="search-box position-relative" style={{ width: "75%" }} >
                            <Input
                                type="text"
                                // htmlFor="search"
                                id="search"
                                className="form-control border-1 mb-3 search-input"
                                placeholder="Search..."
                                onChange={(e) => {
                                    searchData(e.target.value)
                                }}
                            />
                            <i
                                className="bx bx-search-alt search-icon"
                                style={{
                                    fontSize: "20px",
                                    color: "blue"
                                }}
                            ></i>
                        </div>
                    </Col>
                </Row>
            </div>
            <div className="">
                <Card style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <CardBody>
                        <Row><Col>
                            {<p><strong>{`All Schools(${filterData.length})`}</strong></p>}</Col>
                            <Col>
                                <PaginationView
                                    listSize={filterData.length}
                                    pageSize={PAGE_SIZE}
                                    intervalSize={7}
                                    initialInterval={15}
                                    handelCurrentPage={
                                        (cp) => {
                                            setCurrentPage(cp);
                                        }
                                    }
                                />
                            </Col>
                            <Col>
                                <select
                                    className="form-control "
                                    value={selectedStatus}
                                    disabled={loading}
                                    onChange={(e) => {
                                        const searchInputElem = document.getElementById('search');
                                        if (searchInputElem) {
                                            searchInputElem.value = '';
                                        }
                                        setSelectedStatus(e.target.value)
                                    }}>
                                    <option value={1}>All</option>
                                    <option value={2}>Active</option>
                                    <option value={3}>Active in 7 days Ago</option>
                                    <option value={4}>Active in 15 days Ago</option>
                                    <option value={5}>Inactive</option>
                                </select>
                            </Col>
                        </Row>
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Schools</th>
                                    <th>Childrens</th>
                                    <th>Teachers</th>
                                    <th>School created </th>
                                    <th>Last message</th>
                                    <th>App Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    displayPageSchools.map((data, key) =>
                                        <tr key={key}>
                                            <th>
                                                {key + 1 + ((parseInt(currentPage) - 1) * PAGE_SIZE)}
                                            </th>
                                            <td>
                                                <div className="pt-1">
                                                    {data.attributes.schoolName}
                                                    <br />
                                                    <small>{data.attributes.schoolId}</small>
                                                </div>
                                            </td>
                                            <td className='text-center'>
                                                {data.attributes.kidsCount}
                                            </td>
                                            <td className='text-center'>
                                                {data.attributes.teachers}
                                            </td>

                                            <td className='text-center'>
                                                {data.attributes.schoolCreatedOn ? getDate(data.attributes.schoolCreatedOn) : "--"}
                                            </td>
                                            <td className='text-center'>
                                                {data.attributes.lastMessageSent ? getDate(data.attributes.lastMessageSent) : "--"}
                                            </td>
                                            <td className='text-center'>
                                                <span style={{ color: getMessage(data.attributes.lastMessageSent).color }}>
                                                    {getMessage(data.attributes.lastMessageSent).message}
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                }
                            </tbody>
                        </table>
                    </CardBody>
                </Card>
            </div>
            {loading && (
                <Col xs="12">
                    <div className="text-center py-4">
                        <Link to="#" className="text-success">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                            Loading....{" "}
                        </Link>
                    </div>
                </Col>
            )}
            {displayPageSchools.length === 0 && !loading && (
                <Col>
                    <h3 className="text-center text-info mt-3">
                        {props.t("No Data Found For The Filter")}
                    </h3>
                </Col>
            )}
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { selectedSchool, toolsSchools, schoolAnalyticsForSchoolObject } = state.School;
    return { selectedSchool, toolsSchools, schoolAnalyticsForSchoolObject };
};
export default connect(mapStatetoProps, {
    setSelecetdSchool, toggleLeftmenu, getSchoolsForTools, getSchoolAnalyticsForSchoolObject
})(withRouter(withNamespaces()(schoolSubscription)));
