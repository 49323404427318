import actions from "redux-form/lib/actions";
import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLeading,
} from "redux-saga/effects";
import {
  getKidFromParse,
  deleteKidFromParse,
  getAllKidForSchoolParse,
  getCADForAKid,
  getMedicalDetailsForAKid,
  getescortsForKid,
  getVaccineDetailsForKid,
  getAdmissionsKidsFroSchool,
  getAttendanceForKid,
  getDaycareAttendanceForKid,
  updateKidFromParse,
  updateCADForAKidParse,
  getAllKidForClassIdsParse,
  getAllDeletedKidsForSchoolId,
  addKidToDBParse,
  getAllDeletedKidsForIds,
  getAllKidsForGroups,
  getKidsfromKidsIds,
  saveKids,
  deleteKidsInListParse,
  getAllCADKidsForSchoolIdParse,
  sendAppInstallInvite,
  sendAdmissionLinkToParentServer,
  getAllDeltedKidsForSchool,
  createStudentUser,
  getKidObjFromLocal,
  getMedicalDetailsForKidIds,
} from "../../../helpers/ParseHelpers/kid_helper";
import * as kidActions from "./actions";
import * as KidHelper from '../../../helpers/ParseHelpers/kid_helper'
import * as actionsTypes from "./actionTypes";
import * as Constants from "../../../Constents";
import Parse from "parse";
import toastr, { clear } from "toastr";
import "toastr/build/toastr.min.css";

function* getKidObjectFromLocaSaga({ payload: { id } }) {
  try {
    const localResponse = yield call(getKidObjFromLocal, id);
    if (localResponse) {
      yield put(kidActions.updateSelectedKid(localResponse));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getAKid({ payload: { kidId } }) {
  try {
    const response = yield call(getKidFromParse, kidId);
    yield put(kidActions.updateSelectedKid(response));
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* updateAKidDetails({ payload: { kid, classId } }) {
  try {
    const response = yield call(updateKidFromParse, kid);
    response.pin();
    if (response && response.attributes.SchoolID) {
      yield put(kidActions.updateAllKids([response]));
    } else {
      yield put(kidActions.updateAdmissionKids([response], 2));
    }
    let temp = [];
    temp.push(classId);
    yield put(kidActions.setkidUpdatedsuccessfully(true));
  } catch (error) { }
}
function* updateCADForAKid({ payload: { adObj } }) {
  try {
    const response = yield call(updateCADForAKidParse, adObj);
    yield put(kidActions.setChildAdditionalDetailsForKid(response));
  } catch (error) { }
}
function* deleteKidsInListSaga({ payload: { kidsToDelete, message } }) {
  try {
    yield put(kidActions.setdeletekidLoading(true));
    const response = yield call(deleteKidsInListParse, kidsToDelete);

    if (response) {
      if (message) {
        toastr.success(message, { timeOut: 2000 });
        window.history.back();
      }
      Parse.Object.pinAll(response);
      yield put(kidActions.updateAllKids(response));
    }
    yield put(kidActions.kidsDeletedSuccessfully(true));
    yield put(kidActions.setdeletekidLoading(false));
  } catch (error) { }
}

function* getAllKidsForSchoolId({ payload: { schoolId } }) {


  try {
    var key = Constants.KIDS_UPDATED_TIME_FOR_SCHOOL_ID + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      getAllKidsForGroups,
      schoolId,
      null,
      true
    );
    yield put(kidActions.updateAllKids(localResponse));

    const serverResponse = yield call(
      getAllKidsForGroups,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(kidActions.updateAllKids(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getAllKidsForGroupsSaga({ payload: { schoolId } }) {
  try {
    try {
      if (schoolId) {
        var key = Constants.KIDS_UPDATED_TIME_FOR_GROUP + "_" + schoolId;
        var updatedTime = localStorage.getItem(key);
        var newTime = new Date();
        const localResponse = yield call(
          getAllKidsForGroups,
          schoolId,
          null,
          true
        );
        yield put(kidActions.updateAllKidsForGroups(localResponse));

        const serverResponse = yield call(
          getAllKidsForGroups,
          schoolId,
          updatedTime,
          false
        );
        localStorage.setItem(key, newTime);

        if (serverResponse && serverResponse.length > 0) {
          yield put(kidActions.updateAllKidsForGroups(serverResponse));
          Parse.Object.pinAll(serverResponse);
        }
      }
    } catch (error) {
      // yield put(apiError(error));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}



function* getAllDeletedKidsForSchoolIdForArchiveOnlySaga({
  payload: { schoolId },
}) {
  try {
    const response = yield call(getAllDeletedKidsForSchoolId, schoolId);
    yield put(kidActions.updateAllDeletedKids(response));
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getAllDeletedKidsForIdsSaga({ payload: { kidIds } }) {
  try {
    const response = yield call(getAllDeletedKidsForIds, kidIds);
    yield put(kidActions.updateAllDeletedKids(response));
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* clearDeletedKids({ payload: { boolValue } }) {
  try {
    yield put(kidActions.updateAllDeletedKidsInitial([]));
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* saveKidsSaga({ payload: { kids } }) {
  try {
    const response = yield call(saveKids, kids);
    //yield put(kidActions.updateAllKidsForGroups(response));
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getCad({ payload: { kidId } }) {
  try {
    const response = yield call(getCADForAKid, kidId);
    yield put(kidActions.setChildAdditionalDetailsForKid(response));
  } catch (error) {
    yield error;
  }
}

function* getMedDetail({ payload: { medicalId } }) {
  try {
    const response = yield call(getMedicalDetailsForAKid, medicalId);
    yield put(kidActions.setMedicalDetailsForAKid(response));
  } catch (error) {
    yield error;
  }
}

function* getMedDetailsForKidIds({ payload: { kidIds } }) {
  try {
    const response = yield call(getMedicalDetailsForKidIds, kidIds);
    yield put(kidActions.setMedicalDetailsForKidIds(response));
  } catch (error) {
    yield error;
  }
}

function* getescortsDetail({ payload: { kidId } }) {
  try {
    const response = yield call(getescortsForKid, kidId);
    yield put(kidActions.setPickAndDropDetailForKid(response));
  } catch (error) {
    yield error;
  }
}

function* getVaccineDetail({ payload: { kidId } }) {
  try {
    const response = yield call(getVaccineDetailsForKid, kidId);
    yield put(kidActions.setgetVaccinationList(response));
  } catch (error) {
    yield error;
  }
}

function* getAttendanceDetail({ payload: { kidId, month } }) {
  try {
    const response = yield call(getAttendanceForKid, kidId, month);
    yield put(kidActions.setAttendanceForKid(response));
  } catch (error) {
    yield error;
  }
}
function* getDaycareAttendanceDetail({ payload: { kidId, month } }) {
  try {
    const response = yield call(getDaycareAttendanceForKid, kidId, month);
    yield put(kidActions.setAttendanceForKidDaycare(response));
  } catch (error) {
    yield error;
  }
}


function* getAdmissionForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.ADMISSIONS_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      getAdmissionsKidsFroSchool,
      schoolId,
      null,
      true
    );
    yield put(
      kidActions.updateAdmissionKids(
        localResponse,
        localResponse && localResponse.length > 0 ? 2 : 1
      )
    );

    const serverResponse = yield call(
      getAdmissionsKidsFroSchool,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(kidActions.updateAdmissionKids(serverResponse, 2));
      Parse.Object.pinAll(serverResponse);
    } else {
      yield put(kidActions.updateAdmissionKids([], 2));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* removeKidFromAdmissions({ payload: { kid } }) {
  yield put(kidActions.updateRemovedKid(kid));
}
function* addKidToDB({ payload: { refObj } }) {
  const response = yield call(addKidToDBParse, refObj);
  if (response) {
    yield put(kidActions.updateAllKids([response]));
    response.pin();
  }

}



function* getAllCADKidsForSchoolId({ payload: { schoolId } }) {

  let response = yield call(getAllCADKidsForSchoolIdParse, schoolId, false);
  Parse.Object.pinAll(response);
  yield put(kidActions.setAllKidsForSchool(response));

}

function* sendInvite({
  payload: { kidId, schoolName, successMessage, teacherId, parentId },
}) {
  try {
    const response = yield call(
      sendAppInstallInvite,
      kidId,
      schoolName,
      teacherId,
      parentId
    );
    if (response) {
      if (successMessage) {
        toastr.success(successMessage, { timeOut: 2000 });
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* sendAdmissionLink({
  payload: {
    enquiryId,
    emails,
    phoneNumbers,
    admissionURL,
    schoolName,
    schoolId,
  },
}) {
  try {
    const response = yield call(
      sendAdmissionLinkToParentServer,
      enquiryId,
      emails,
      phoneNumbers,
      admissionURL,
      schoolName,
      schoolId
    );
    toastr.success("Link sent successfully", { timeOut: 2000 });
  } catch (err) {
    console.log(err);
  }
}

function* getAllDeletedKidsForSchool({ payload: { schoolId } }) {
  // try {


  //   const response = yield call(getAllDeltedKidsForSchool, schoolId);
  //   yield put(kidActions.setAllDeletedKidsForSchool(response));
  // } catch (err) {
  //   console.log(err);
  // }


  try {
    var key = Constants.DELETED_KID_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      getAllDeltedKidsForSchool,
      schoolId,
      null,
      true
    );
    yield put(kidActions.setAllDeletedKidsForSchool(localResponse));

    const serverResponse = yield call(
      getAllDeltedKidsForSchool,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(kidActions.setAllDeletedKidsForSchool(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }

}

function* createUserForStudent({ payload: { kid, schoolId } }) {
  try {
    yield put(kidActions.updateStudentUser(undefined, true));
    const serverResponse = yield call(createStudentUser, kid, schoolId);
    yield put(kidActions.updateStudentUser(serverResponse));
    if (serverResponse && serverResponse.status) {
      yield put(kidActions.updateAllKids([serverResponse.kid], false));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
  function* saveMedicalDetailsForAKid({ payload: { medObj,kid } }) {
    try {
     
      const serverResponse = yield call(updateCADForAKidParse, medObj);
      if (serverResponse) {
        yield put(kidActions.setMedicalDetailsForAKid(serverResponse));
        toastr.success("Medical details updated successfully...", { timeOut: 2000 });
        if (kid && !kid.attributes.MedicalID) {
          kid.set('MedicalID', serverResponse.id);
          const response = yield call(updateKidFromParse, kid);
          response.pin();
          if (response ) {
            yield put(kidActions.updateAllKids([response]));
          }
        }
      }
    } catch (error) {
      // yield put(apiError(error));
    }
  }

  function* getEscortsForKidIds({ payload: { kidIds } }) {
    try {

      const serverResponse = yield call(
        KidHelper.getEscortsForKidIds,
        kidIds
      );
      if (serverResponse.length > 0) {
        yield put(kidActions.updateEscorts(serverResponse));
        Parse.Object.pinAll(serverResponse);
      }
    } catch (error) {
      // yield put(apiError(error));
    }
  }

  function* disableKid({ payload: { kidId, disabledBy, isDisabled } }) {
    try {
      console.log("disabled kid saga")
      const response = yield call(KidHelper.disableKid, kidId, disabledBy, isDisabled);
      
      if (response ) {
        if(response.errorCode == 1){
          toastr.danger('Invalid User', { timeOut: 2000 });
        }else if(response.errorCode == 2){
          toastr.danger('Invalid Student', { timeOut: 2000 });
        }else if(response.errorCode == 3){
          toastr.danger('Invalid School', { timeOut: 2000 });
        }else{
          yield put(kidActions.updateAKid(response));
          toastr.success (isDisabled ? "Successfully Deactivated": "Successfully Activated", { timeOut: 2000 });
        }
      } 
    } catch (error) { }
  }



export function* watchGetAllDeletedKidsForSchoolIdSaga() {
  yield takeEvery(
    actionsTypes.GET_ALL_DELETED_KIDS_SCHOOL_ID,
    getAllDeletedKidsForSchoolIdForArchiveOnlySaga
  );
}

export function* watchGetAdmissionKids() {
  yield takeEvery(actionsTypes.GET_ADMISSION_KIDS, getAdmissionForSchoolId);
}

export function* watchGetAllDeletedKidsForIdsSaga() {
  yield takeEvery(
    actionsTypes.GET_ALL_DELETED_KIDS_FOR_IDS,
    getAllDeletedKidsForIdsSaga
  );
}
export function* watchSaveKidsSaga() {
  yield takeEvery(actionsTypes.SAVE_KIDS, saveKidsSaga);
}

export function* watchClearDeletedKids() {
  yield takeEvery(actionsTypes.CLEAR_DELETED_KIDS, clearDeletedKids);
}

export function* watchGetAllKidsForGroups() {
  yield takeEvery(actionsTypes.GET_ALL_KIDS_FOR_GROUP, getAllKidsForGroupsSaga);
}
export function* watchDeleteKidsInListSaga() {
  yield takeEvery(actionsTypes.DELETE_KIDS_IN_LIST, deleteKidsInListSaga);
}

export function* watchGetAllKidsForSchoolId() {
  yield takeEvery(actionsTypes.GET_ALL_KIDS, getAllKidsForSchoolId);
}


export function* watchGetAKid() {
  yield takeEvery(actionsTypes.A_KID_DETAILS, getAKid);
}
export function* watchGetCAD() {
  yield takeEvery(actionsTypes.GET_CAD_FOR_A_KID, getCad);
}
export function* watchGetMed() {
  yield takeEvery(actionsTypes.GET_MEDICAL_DETAILS_FOR_KID, getMedDetail);
}

export function* watchGetMedicalDetailsForKidIds() {
  yield takeEvery(actionsTypes.GET_MEDICAL_DETAILS_FOR_KID_IDS, getMedDetailsForKidIds);
}

export function* watchGetEscorts() {
  yield takeEvery(
    actionsTypes.GET_PICK_AND_DROP_DETAL_FOR_KID,
    getescortsDetail
  );
}
export function* watchGetVaccine() {
  yield takeEvery(actionsTypes.GET_VACCINE_DETAL_FOR_KID, getVaccineDetail);
}
export function* watchGetAttendanceKid() {
  yield takeEvery(actionsTypes.GET_ATTENDANCE_FOR_KID, getAttendanceDetail);
}
export function* watchGetAttendanceKidDaycare() {
  yield takeEvery(
    actionsTypes.GET_ATTENDANCE_FOR_KID_DAYCARE,
    getDaycareAttendanceDetail
  );
}
export function* watchGetUpdateAKid() {
  yield takeEvery(actionsTypes.UPDATE_A_KID, updateAKidDetails);
}
export function* watchGetUpdateCADforAKid() {
  yield takeEvery(
    actionsTypes.UPDATE_ADDITIONAL_DETAILS_FOR_A_KID,
    updateCADForAKid
  );
}
export function* watchRemoveKidFromAdmissions() {
  yield takeEvery(
    actionsTypes.REMOVE_KID_FROM_ADMISSIONS,
    removeKidFromAdmissions
  );
}
export function* watchAddKidToDB() {
  yield takeEvery(actionsTypes.ADD_A_KID_TO_DB, addKidToDB);
}

export function* watchGetAllCADKidsForSchoolId() {
  yield takeEvery(
    actionsTypes.GET_ALL_KIDS_FOR_SCHOOL_ID,
    getAllCADKidsForSchoolId
  );
}
export function* watchSendInvite() {
  yield takeLeading(actionsTypes.SEND_INVITE, sendInvite);
}
export function* watchSendAdmissionLink() {
  yield takeEvery(
    actionsTypes.SEND_ADMISSION_LINK_FOR_PARENTS,
    sendAdmissionLink
  );
}

export function* watchGetDeletedKidsForSchoolId() {
  yield takeEvery(
    actionsTypes.GET_ALL_DELETED_KIDS_FOR_SCHOOL,
    getAllDeletedKidsForSchool
  );
}

export function* watchCreateStudentUser() {
  yield takeEvery(
    actionsTypes.CREATE_STUDENT_USER,
    createUserForStudent
  );
}

export function* watchSetSelectedKidSaga() {
  yield takeEvery(
    actionsTypes.GET_KID_OBJECT_FROM_LOCAL,
    getKidObjectFromLocaSaga
  );
}

export function* watchSaveMedicalDetailsForAKid() {
  yield takeEvery(
    actionsTypes.SAVE_MEDICAL_DETAILS_FOR_KID,
    saveMedicalDetailsForAKid
  );
}

export function* watchGetEscortsForKidIds() {
  yield takeEvery(actionsTypes.GET_ESCORTS_FOR_KID_IDS, getEscortsForKidIds);
}

export function* watchDisableKid() {
  yield takeEvery(actionsTypes.DISABLED_KID, disableKid);
}

function* KidSaga() {
  yield all([
    fork(watchSetSelectedKidSaga),
    fork(watchGetAllKidsForSchoolId),
    fork(watchGetAKid),
    fork(watchGetCAD),
    fork(watchGetMed),
    fork(watchGetMedicalDetailsForKidIds),
    fork(watchGetEscorts),
    fork(watchGetVaccine),
    fork(watchGetAdmissionKids),
    fork(watchGetAttendanceKid),
    fork(watchGetAttendanceKidDaycare),
    fork(watchGetUpdateAKid),
    fork(watchGetUpdateCADforAKid),
    fork(watchRemoveKidFromAdmissions),
    fork(watchAddKidToDB),
    fork(watchGetAllKidsForGroups),
    fork(watchGetAllDeletedKidsForSchoolIdSaga),
    fork(watchSaveKidsSaga),
    fork(watchDeleteKidsInListSaga),
    fork(watchGetAllCADKidsForSchoolId),
    fork(watchGetAllDeletedKidsForIdsSaga),
    fork(watchClearDeletedKids),
    fork(watchSendInvite),
    fork(watchSendAdmissionLink),
    fork(watchGetDeletedKidsForSchoolId),
    fork(watchCreateStudentUser),
    fork(watchSaveMedicalDetailsForAKid),
    fork(watchGetEscortsForKidIds),
    fork(watchDisableKid),
  ]);
}
export default KidSaga;
