import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool, getAllKids, getCamerasForSchoolId } from '../../store/actions';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label } from "reactstrap";
import { Table } from 'react-super-responsive-table';
import * as XLSX from 'xlsx/xlsx.mjs';

const CctvReport = (props) => {
    const [displayData, setDisplayData] = useState([]);
    const [displayTitles, setDisplayTitles] = useState([]);
    const [removeNoCamKids, setRemoveNoCamKids] = useState(true);
    const [cameraStudentCounts, setCameraStudentCounts] = useState([]);


    useEffect(() => {
        if (props.schoolId) {
            props.getCamerasForSchoolId(props.schoolId);
        }
    }, [props.schoolId]);

    const getClassRoomName = (classroomId) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === classroomId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name + " " ?? "-")
            }
        }
    }

    useEffect(() => {
        if (props.kids && props.kids.length > 0 && props.cameras && props.cameras.length > 0) {
            let list = [];
            let titles = ["Sl No", "Student Name", "Classroom"];
            let camCounts = [];
            for (let i = 0; i < props.cameras.length; i++) {
                camCounts.push(0);
            }
            for (const camera of props.cameras) {
                titles.push(camera.attributes.name);
            }
            titles.push("Total");
            setDisplayTitles(titles);

            for (const i in props.kids) {
                let kidList = [];
                let kidCamCount = 0;
                kidList.push(list.length + 1);
                kidList.push(props.kids[i].attributes.Name);
                kidList.push(getClassRoomName(props.kids[i].attributes.ClassRoomID));

                for (const j in props.cameras) {
                    if (props.cameras[j].attributes.kids.includes(props.kids[i].id)) {
                        kidList.push('✔️');
                        kidCamCount++;
                        camCounts[j]++;
                    } else {
                        kidList.push('');
                    }
                }

                kidList.push(kidCamCount);
                if (removeNoCamKids) {
                    if(kidCamCount > 0){
                        list.push(kidList);
                    }
                } else {
                    list.push(kidList);
                }
            }

            setDisplayData(list);
            setCameraStudentCounts(camCounts);
        }
    }, [props.kids, props.cameras, removeNoCamKids]);
    
    
    const onClickDownload = () => {
        let finalData3 = [];
        let fileName = `Cctv_Report.xlsx`;
        finalData3.push(["Cctv Report"]);
        finalData3.push([`Report for: ${props.isFranchise ? props.selectedSchool.label : props.selectedSchool && props.selectedSchool.attributes.Name}`]);
        finalData3.push(["Total Students:" + displayData.length]);
        finalData3.push([]);
        finalData3.push(displayTitles);
        finalData3 = finalData3.concat(displayData);
        let cam = ["","Total Students per Camera",""].concat(cameraStudentCounts);
        finalData3.push(cam);
        
        let wb = XLSX.utils.book_new();
        let ws3 = XLSX.utils.aoa_to_sheet(finalData3);
        XLSX.utils.book_append_sheet(wb, ws3, "Cctv Report");
        XLSX.writeFile(wb, fileName);
    }

    return (
        <React.Fragment>
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <Label className='m-1'>{props.t("Filter by")} :</Label>
                        <select
                            className="form-control"
                            value={removeNoCamKids?2:1}
                            onChange={(e) => {
                                if(e.target.value==1 && removeNoCamKids){
                                    setRemoveNoCamKids(false)
                                }
                                if(e.target.value==2 && !removeNoCamKids){
                                    setRemoveNoCamKids(true)
                                }
                            }}
                        >
                            <option value={1}>{props.t("All Students")}</option>
                            <option value={2}>{props.t("Students with more than 1 Camera Access")}</option>
                        </select>
                    </FormGroup>
                </Col>
                <Col lg={6} className='mt-4'>
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={() => {
                                if (displayData && displayData.length > 0) {
                                    onClickDownload();
                                }
                            }}
                            className="w-md btn btn-primary"
                        >
                            <i className='bx bx-download font-size-16 align-middle m-1'></i>
                            {props.t("Download Report")}
                        </button>
                    </div>
                </Col>
            </Row>

            <div className="table-responsive mb-0" data-pattern="priority-columns">
                <Table className="table table-striped table-bordered mt-3">
                    <thead>
                        <tr>
                            {displayTitles.map((value, index) => (
                                <th key={index}>{value}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayData.map((kid, index) => (
                            <tr key={index}>
                                {kid.map((value, index2) => (
                                    <td key={index2}>{value}</td>
                                ))}
                            </tr>
                        ))}
                        <tr>
                            <td colSpan={3}>Total Students under each CCTV</td>
                            {cameraStudentCounts.map((count, index3) => (
                                <td key={index3}>{count}</td>
                            ))}
                        </tr>
                    </tbody>
                </Table>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { cameras } = state.Camera;
    const { kids } = state.Kid;
    return {
        schools,
        selectedSchool,
        kids,
        cameras,
        classrooms
    };
}

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            getAllKids,
            getCamerasForSchoolId,
        })(CctvReport)
    )
);
