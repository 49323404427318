import * as actions from "./actionTypes";


export const updateExpenseCategories = (expenseCategories) => {
  return {
    type: actions.UPDATE_EXPENSE_CATEGORIES,
    payload: { expenseCategories },
  };
};

export const getExpenseCategoryForSchoolId = (schoolId, instituteId ) => {
  return {
    type: actions.GET_EXPENSE_CATEGORY_FOR_SCHOOL_ID,
    payload: { schoolId, instituteId },
  };
};



export const updateExpenseCategory = (expenseCategory) => {
  return {
    type: actions.UPDATE_EXPENSE_CATEGORY,
    payload: { expenseCategory },
  };
};

export const editExpenseCategory = (expenseCategory, instituteId) => {
  return {
    type: actions.EDIT_EXPENSE_CATEGORY,
    payload: { expenseCategory, instituteId },
  };
};

export const updateLoadingExpenseCategory = (boolValue) => {
  return {
    type: actions.UPDATE_LOADING_EXPENSE_CATEGORY,
    payload: { boolValue },
  }
}
