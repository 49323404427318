import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Label,
    Card,

    Button,

    CardBody,

} from "reactstrap";
import { matchPath } from "react-router";


import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import {
    setSelecetdSchool
} from "../../store/actions";


const CustomReportsView = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/custom-reports-view/:schoolId",
        exact: true,
        strict: false,
    });
    const matchFranchise = matchPath(props.history.location.pathname, {
        path: "/franchise-custom-reports-view",
        exact: true,
        strict: false,
    });

    var schoolId = undefined;

    if (match) {
        schoolId = match.params.schoolId;
    } else if (matchFranchise) {
        schoolId = matchFranchise.params.schoolId;
    }

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);

        }
        fetchReports(schoolId)

    }, [props.selectedSchool, schoolId]);


    const [displayList, setDisplayList] = useState([]);

    const [isLoading, setIsLoading] = useState(true);


    const fetchReports = (schoolId) => {
        let ids = [];

        if (props.selectedSchool) {
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }
        }
        ids.push(schoolId)


        if (ids.length > 0) {
            var query = new Parse.Query("CustomReportTemplate");
            if (matchFranchise) {
                query.equalTo("instituteId", Parse.User.current().attributes.franchiseId);
            } else {
                query.containedIn("instituteId", ids);
            }
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.descending("createdAt");

            query.find().then((results) => {
                setIsLoading(false);
                var tempList = [];

                if (results != null) {
                    if (results.length > 0) {

                        setDisplayList(results);
                    } else {
                        //setIsQuestionsAvailable(false);
                    }
                }
            });
        }
    }












    return (
        <React.Fragment>
            <div className="page-content">
                {!isLoading && (
                    <Container fluid>


                        <Row>
                            <Col lg={8} md={8} xs={12}>
                                <h4>Reports</h4>
                            </Col>
                            {/* 
                            <Col lg={4} md={4} xs={12}>
                                <Form className="mb-3  float-sm-right form-inline">

                                </Form>
                            </Col> */}
                        </Row>

                        <Card>

                            <CardBody>
                                {displayList.length > 0 && (
                                    <div className="table-responsive">
                                        <table className="table table-centered  mb-0">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th style={{ width: "60% !important" }}>{props.t("Name")}</th>


                                                    <th style={{ textAlign: "center", width: "120px" }}>
                                                        {props.t("View Details")}
                                                    </th>

                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayList.map((object, key) => (
                                                    <tr key={key}>
                                                        <td >


                                                            <Label>{object.attributes.reportName}</Label>
                                                        </td>


                                                        <td style={{ textAlign: "center" }}>
                                                            <Link
                                                                to={matchFranchise ? "/franchise-monthly-reports-view/" + object.id :
                                                                    "/monthly-reports-view/" + schoolId + "/" +
                                                                    object.id
                                                                }
                                                            >
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    size="sm"
                                                                    className="btn-rounded waves-effect waves-light"
                                                                >
                                                                    {props.t("View Details")}
                                                                </Button>
                                                            </Link>
                                                        </td>


                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}
                                {displayList.length === 0 && (
                                    <p className="text-center">No Reports to display !!</p>
                                )}
                            </CardBody>
                        </Card>
                    </Container>
                )}


                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Reports...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>

        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool
        })(CustomReportsView)
    )
);
