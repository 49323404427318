import React, { useState, useEffect } from 'react';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool, getAllKids, getClassroomsForSchoolId, getDaycareAttendanceForKidIds } from '../../store/actions';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from 'react-datepicker';
import { Row, Col, Table, Label, FormGroup, Modal, ModalBody, ModalHeader, ModalFooter, Button, Input } from 'reactstrap';
import * as XLSX from 'xlsx/xlsx.mjs';
import Moment from 'moment';

const DairyReport = (props) => {
    const [allCategoryKids, setAllCategoryKids] = useState([]);
    const [categoryKids, setCategoryKids] = useState([]);
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [CategoryModal, setCategoryModal] = useState(false);
    const [categories, setCategories] = useState([]);
    const [tempCategories, setTempCategories] = useState([]);
    const [noRecordsFound, setNoRecordsFound] = useState(false);
    const [showTable, setShowTable] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [classroomList, setClassroomList] = useState([]);
    const [dateRange, setDateRange] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const allClassObject = () => {
        let tempSelectObj = {}
        tempSelectObj.id = "all"
        tempSelectObj.value = "all"
        tempSelectObj.name = "All Classrooms"
        return tempSelectObj;
    }
    const [selectedClassroom, setSelectedClassroom] = useState(allClassObject());
    const [selectedChild, setSelectedChild] = useState('all');
    
    useEffect(() => {
        getDiaryFeatures();
        generateDateRange();
    }, [selectedStartDate, selectedEndDate]);

    useEffect(() => {
        if (props.classrooms && props.classrooms.length > 0) {
            let tempSelectList = []
            for (const classRoom of props.classrooms) {
                tempSelectList.push(getSelectedClass(classRoom))
            }
            setClassroomList(tempSelectList)
        }
    }, [props.classrooms]);

    useEffect(() => {
        if (CategoryModal) {
            const initiallySelectedCategories = categories.map(category => category.id);
    
            setTempCategories(prevTempCategories => {
                return prevTempCategories.length > 0 ? prevTempCategories : initiallySelectedCategories;
            });
        }
    }, [CategoryModal, categories]);
    
    const getSelectedClass = (classRoom) => {
        let tempSelectObj = {}
        let obj = classRoom.attributes
        tempSelectObj.id = classRoom.id
        tempSelectObj.name = obj.Name ?? ""
        tempSelectObj.value = classRoom.id
        tempSelectObj.label = obj.Name ?? ""
        tempSelectObj.classroomType = obj.classroomType;
        tempSelectObj.parseObject = classRoom
        return tempSelectObj;
    }

    const getKidObject = (val) => {
        let temp = {};
        temp.id = val.id;
        temp.label = val.attributes.Name;
        temp.value = val.id;
        temp.object = val;

        return temp
    }

    useEffect(() => {
        if (props.kids && props.kids.length > 0 && classroomList && classroomList.length > 0 && selectedClassroom) {
            let kidList = [];
            for (const val of props.kids) {
                if (selectedClassroom.id === 'all' || selectedClassroom.id === val.attributes.ClassRoomID || selectedClassroom.id === val.attributes.daycareClassId) {
                    kidList.push(getKidObject(val));
                }
            }
            setStudentList(kidList);
        }
    }, [props.kids, classroomList, selectedClassroom]);

    const handleClassroomChange = (e) => {
        const selectedClass = classroomList.find(classroom => classroom.id === e.target.value);
        setSelectedClassroom(selectedClass);
    };

    const CategoryToggle = () => {
        setCategoryModal(!CategoryModal);
    }

    const generateDateRange = () => {
        let dates = [];
        let currentDate = new Date(selectedStartDate);
        while (currentDate <= selectedEndDate) {
            dates.push(new Date(currentDate));
            currentDate.setDate(currentDate.getDate() + 1);
        }
        setDateRange(dates);
    }

    const getKIdFromStudentName = (kidId) => {
        if (studentList && studentList.length > 0) {
            let temp = studentList.find(kid => kid.id === kidId);

            return temp
        }
        return {};
    };

    const getClassRoomName = (classroomId) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === classroomId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name + " " ?? "-")
            }
        }
    }
    const handleStudentChange = (e) => {
        setSelectedChild(e.target.value);
    };

    const getDiaryFeatures = () => {
        const DiaryFeatures = Parse.Object.extend("DiaryFeatures");
        const queryFeatures = new Parse.Query(DiaryFeatures);
        queryFeatures.equalTo("schoolId", props.schoolId);
        queryFeatures.notEqualTo("isDeleted", true);
        queryFeatures.limit(1000);
        queryFeatures.ascending("order");
        queryFeatures.find().then(
            (result) => {
                const categoriesData = result.map(feature => ({
                    id: feature.id,
                    name: feature.attributes.routine,
                    selected: true,
                }));
                setCategories(categoriesData);
                getDiaryRecord(result);
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const getDiaryRecord = (params) => {
        let featureIds = categories
            .filter(category => category.selected)
            .map(category => category.id);

        let queryRecords = new Parse.Query("DiaryRecord");
        queryRecords.containedIn("diaryFeaturesId", featureIds);
        queryRecords.containedIn("diaryFeaturesId", featureIds);

        if (selectedChild != "all") {
            queryRecords.containedIn("receiverId", selectedChild);
        }
        queryRecords.greaterThanOrEqualTo("createdAt", params.startDate);
        queryRecords.lessThanOrEqualTo("createdAt", params.endDate);
        queryRecords.ascending("createdAt");
        queryRecords.limit(5000);

        queryRecords.find().then(
            (result) => {
                if (result.length === 0) {
                    setNoRecordsFound(true);
                } else {
                    setNoRecordsFound(false);
                    prepareDiaryData(categories, result);
                }
                setIsLoading(false);
            },
            (error) => {
                console.log(error);
                setIsLoading(false);
            }
        );
    }


    const prepareDiaryData = (diaryFeatures, diaryRecords) => {
        // console.log("DairyFeautures:", diaryFeatures);
        // console.log("DairyRecords:", diaryRecords);
        const categoryKidsMap = {};
        
        let masterList = []
        let masterList2 = {}

        let kidList = selectedChild == "all" ? studentList : [getKIdFromStudentName(selectedChild)]
        diaryFeatures.forEach((feature) => {

            let fKidMap = []
            kidList.forEach((kid) => {

                let kidMap = []

                kidMap.push(feature.name)
                kidMap.push(kid.object.attributes.Name)
                kidMap.push(getClassRoomName(kid.object.attributes.ClassRoomID))


                for (const date of dateRange) {
                    const dateKey = Moment(date).format('DD MMM YYYY');
                    let kRecord = undefined

                    for (const record of diaryRecords) {

                        const rKey = Moment(record.attributes.createdAt).format('DD MMM YYYY');
                        if (dateKey == rKey && feature.id == record.attributes.diaryFeaturesId && kid.id == record.attributes.receiverId) {
                            kRecord = record;

                            break;
                        }
                    }
                    if (kRecord) {
                        let rating = kRecord.attributes.rating ? kRecord.attributes.rating : '-';
                        let description = kRecord.attributes.description ? kRecord.attributes.description : '-';
                        
                        kidMap.push(rating + " (" + description + ")");
                    } else {
                        kidMap.push(''); 
                    }
                    
                };
                masterList.push(kidMap)
                fKidMap.push(kidMap)

            });

            masterList2[feature.id] = fKidMap;
        });
        setCategoryKids(masterList);
        setAllCategoryKids(masterList2);
        
        diaryFeatures.forEach((feature) => {
            const categoryMap = {
                category: feature,
                allKids: [],
                routines: new Set(),
            };
            diaryRecords.forEach((record) => {
                const recordDate = Moment(record.createdAt).format('DD MMM YYYY');
                if (record.attributes.diaryFeaturesId === feature.id) {
                    if (!categoryMap[recordDate]) {
                        categoryMap[recordDate] = {
                            kids: [],
                            descriptions: [],
                            rating: [],
                            receiverId: [],
                        };
                    }
                    categoryMap.routines.add(record.attributes.routine);

                    categoryMap[recordDate].kids.push(record.attributes.receiverId);
                    categoryMap[recordDate].descriptions.push(record.attributes.description);
                    categoryMap[recordDate].rating.push(record.attributes.rating);
                    categoryMap[recordDate].receiverId.push(record.attributes.receiverId);

                    categoryMap['allKids'].push(record.attributes.receiverId);
                }
            });
            categoryKidsMap[feature.id] = categoryMap;
        });

        
        setShowTable(true);
    }


    const generateReportClick = () => {
        setIsLoading(true);
        let params = {};
        params.kidIds = [];
        params.selectedClassroom = selectedClassroom.id;
        params.selectedChild = selectedChild;
        params.dateRange = dateRange;

        params.startDate = new Date(
            selectedStartDate.getFullYear(),
            selectedStartDate.getMonth(),
            selectedStartDate.getDate(),
            0,
            0,
            0,
            0,
        );
        params.endDate = new Date(
            selectedEndDate.getFullYear(),
            selectedEndDate.getMonth(),
            selectedEndDate.getDate(),
            23,
            59,
            59,
            999
        );

        if (selectedClassroom.id === 'all' && selectedChild === 'all') {
            // Case 1: All Class, All Students
            params.kidIds = props.kids.map(kid => kid.id);
        } else if (selectedClassroom.id !== 'all' && selectedChild === 'all') {
            // Case 2: Specific Class, All Students
            params.kidIds = props.kids
                .filter(kid => kid.attributes.ClassRoomID === selectedClassroom.id || kid.attributes.daycareClassId === selectedClassroom.id)
                .map(kid => kid.id);
        } else if (selectedClassroom.id !== 'all' && selectedChild !== 'all') {
            // Case 3: Specific Class, Specific Student
            params.kidIds = [selectedChild];
        }
        getDiaryRecord(params);
        console.log("Params", params)
    }

    const doneClick = () => {
        const updatedCategories = categories.map(category => ({
            ...category,
            selected: tempCategories.includes(category.id)
        }));
        setCategories(updatedCategories);
        const selectedCategories = updatedCategories.filter(category => category.selected);
        const selectedCategoryKidsMap = {};
        let allList = [];
        for (let i = 0; i < selectedCategories.length; i++) {
            const selectedCategory = selectedCategories[i];
            selectedCategoryKidsMap[selectedCategory.id] = allCategoryKids[selectedCategory.id];

            allList = allList.concat(allCategoryKids[selectedCategory.id]);
        }

        setCategoryKids(allList); 
        setCategoryModal(false); 
    };

    const handleCategoryChange = (categoryId) => {
        let updatedCategories = [...tempCategories];
        if(updatedCategories.includes(categoryId)){
            updatedCategories = updatedCategories.filter(id => id !== categoryId);
        } else {
            updatedCategories.push(categoryId);
        }
        setTempCategories(updatedCategories);
    };

    const downloadReportClick = () => {
        let finalData = [];
        let fileName = `Diary_report.xlsx`;
        finalData.push(["Diary Report"]);
        finalData.push([`Report for: ${props.isFranchise ? props.selectedSchool.label : (props.selectedSchool && props.selectedSchool.attributes.Name)}`]);
        finalData.push([`Data Range from ${Moment(selectedStartDate).format('DD MMM YYYY')} to ${Moment(selectedEndDate).format('DD MMM YYYY')}`]);
        finalData.push([]);
    
        const headerRow = ["Category", "Student Name", "Class of That Student"];
        dateRange.forEach(date => {
            headerRow.push(Moment(date).format('DD MMM YYYY'));
        });
        finalData.push(headerRow);
    
        categoryKids.forEach((kidMap) => {
            finalData.push(kidMap);
        });
    
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
    
        const dateRangeStartIndex = 3; 
        const colWidths = finalData[0].map((_, colIndex) => {
            if (colIndex >= dateRangeStartIndex) {
                return { wpx: 200 };
            } else {
                return { wpx: Math.min(Math.max(...finalData.map(row => (row[colIndex] || "").toString().length)) * 10, 100) };
            }
        });
        ws['!cols'] = colWidths;
        XLSX.utils.book_append_sheet(wb, ws, "Diary Report");
        XLSX.writeFile(wb, fileName);
    };
    
    return (
        <React.Fragment>
            <Row>
                <Col md="2" xs="12" xl="2" lg="2">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select Class</Label>
                        <select
                            className="form-control"
                            onChange={handleClassroomChange}
                        >
                            <option value="all">All</option>
                            {classroomList.map(classroom => (
                                <option key={classroom.id} value={classroom.id}>
                                    {classroom.name}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select Child</Label>
                        <select className="form-control" onChange={handleStudentChange}>
                            <option value="all">All</option>

                            {studentList.map(student => (
                                <option key={student.id} value={student.id}>
                                    {student.label}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Start Date</Label>
                        <DatePicker className="form-control" selected={selectedStartDate} onChange={(date) => setSelectedStartDate(date)} maxDate={new Date()} />
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>End Date</Label>
                        <DatePicker className="form-control" selected={selectedEndDate} onChange={(date) => setSelectedEndDate(date)} maxDate={new Date()} />
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <div style={{ marginTop: '1cm' }}>
                        <FormGroup className="mt-4">
                            <Button color="primary" onClick={generateReportClick}>Generate</Button>
                        </FormGroup>
                    </div>
                </Col>
                <Col md="4" xs="12" xl="2" lg="2" style={{ width: '100%', }} className='d-flex justify-content-end'>
                    <FormGroup>
                        <Button color="primary" style={{ marginRight: '10px' }} onClick={CategoryToggle}>Add/Remove Category</Button>
                    </FormGroup>
                    {selectedStartDate && selectedEndDate && <FormGroup>
                        <Button color="primary" onClick={downloadReportClick}><i className='bx bx-download font-size-16 align-middle mr-1'></i>Download Report</Button>
                    </FormGroup>}
                </Col>
            </Row>

            {isLoading && (
                <div className="text-center mt-4">
                    <Label className="text-success">
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>
                        {"Preparing Report"}
                    </Label>
                </div>
            )}
            {noRecordsFound ? !isLoading && (
                <div className="text-center mt-4">
                    <h3 className="text-center text-info mt-3">
                        No Report Found For The Filter
                    </h3>
                </div>
            ) : (
                showTable && (
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" className="table table-striped table-bordered table-md">
                            <thead>
                                <tr>
                                    <th>Category</th>
                                    <th>Student Name</th>
                                    <th>Class Of That Student</th>
                                    {dateRange.map((date, index) => (
                                        <th key={index}>{Moment(date).format('DD MMM YYYY')}</th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {categoryKids.map((kidMap, key) => (
                                    <tr key={key}>
                                        {kidMap.map((value, key2) => (
                                            <td key={key2}>{value}</td>

                                        ))}
                                    </tr>
                                ))}

                            </tbody>
                        </Table>
                    </div>
                )
            )}
            
            <Modal isOpen={CategoryModal} toggle={CategoryToggle} scrollable={true}>
                <ModalHeader toggle={CategoryToggle}>Add/Remove Category</ModalHeader>
                <FormGroup>
                    <Col>
                        <Input
                            type="checkbox"
                            className='text-center'
                            style={{ marginLeft: '10px' }} 
                            checked={categories && categories.length== tempCategories.length}
                            onChange={() => {
                                const allSelected = categories.every(category => tempCategories.includes(category.id));
                                const updatedCategories = categories.map(category => ({
                                    ...category,
                                    selected: !allSelected 
                                }));
    
                                if (categories.length== tempCategories.length) {
                                    setTempCategories([]);
                                } else {
                                    setTempCategories(updatedCategories.map(category => category.id));
                                }
                            }}
                        />
                        <Label>Select All</Label>
                    </Col>
                </FormGroup>

                <ModalBody>
                    {categories.map(category => (
                        <FormGroup key={category.id}>
                            <Input
                                type="checkbox"
                                checked={tempCategories.includes(category.id)}
                                onChange={() => handleCategoryChange(category.id)}
                            />
                            <Label className="m-1">{category.name}</Label>
                        </FormGroup>
                    ))}
                </ModalBody>
                <ModalFooter>
                    <Button color="primary" onClick={doneClick}>
                        Done
                    </Button>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { kids } = state.Kid;
    const { classrooms } = state.Classroom;
    const { daycareAttendance } = state.Attendance;
    return { selectedSchool, kids, classrooms, daycareAttendance };
}

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            getAllKids, getClassroomsForSchoolId, getDaycareAttendanceForKidIds,
        })(DairyReport)
    )
);