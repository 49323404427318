import Parse from "parse";

export const getInventoryPurchaseOrderItemsForOwnerId = (ownerId, ownerType, updatedDate,isLocal,) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryPurchaseOrderItem = Parse.Object.extend("InventoryPurchaseOrderItems");
        var query = new Parse.Query(InventoryPurchaseOrderItem);
        query.equalTo("ownerId", ownerId);
        query.equalTo("ownerType", ownerType)
  
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
       } else {
         query.notEqualTo("isDeleted", true);
       }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
        query.addAscending("order");
        query.find().then(
          (result) => {
           resolve(result); 
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };

  export const deleteInventoryPurchaseOrderItemFromParse = (inventoryPurchaseOrderItem) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryPurchaseOrderItem.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            inventoryPurchaseOrderItem.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            inventoryPurchaseOrderItem.set("deletedBy", Parse.User.current().id);
        }
  
        inventoryPurchaseOrderItem.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("error");
          }
        );
      });
    } catch (error) {}
  };
  
  export const getInventoryPurchaseOrderItemFromLocal = (inventoryPurchaseOrderItemId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryPurchaseOrderItem = Parse.Object.extend("InventoryPurchaseOrderItems");
        var query = new Parse.Query(InventoryPurchaseOrderItem);
        query.fromLocalDatastore();
        query.get(inventoryPurchaseOrderItemId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  export const getInventoryPurchaseOrderItemFromParse = (inventoryPurchaseOrderItemId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryPurchaseOrderItem = Parse.Object.extend("InventoryPurchaseOrderItems");
        var query = new Parse.Query(InventoryPurchaseOrderItem);
        query.equalTo("purchaseOrderId",inventoryPurchaseOrderItemId);
        query.notEqualTo("isDeleted", true);
        query.find().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  export const saveCustomObjects = (object) => {
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) { }
  };

