import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as VerifiedLogo } from "../../assets/images/certificate/verifiedLogo.svg";
import { ReactComponent as Divider } from "../../assets/images/certificate/separator.svg";
import {
  Container,
  Col,
  Table,
  Modal,
  ModalBody,
  ModalHeader,
  Media,
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import avatar1 from "../../assets/images/users/user_2.png";

import { matchPath } from "react-router";
import img1 from "../../assets/images/companies/img-1.png";
import {
  getTeachersForSchool,
  getAllKids,
  getParentsForFamilyIds,
  getGroupMapsForGroupId
} from "../../store/actions";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import Parse from "parse";
import StarRatings from "react-star-ratings";
import toastr from "toastr";
import logo from "../../assets/images/logo-dark.png";

const MessageStatusView = (props) => {

  const sent = { value: "sent", icon: "check", iconColor: "secondary" }
  const delivered = { value: "delivered", icon: "check-double", iconColor: "secondary" }
  const read = { value: "read", icon: "check-double", iconColor: "primary" }

  const [isLoading, setIsLoading] = useState(false);
  const [teacherList, setTeacherList] = useState([]);
  const [kidList, setKidList] = useState([]);
  const [readByList, setReadByList] = useState([]);
  const [deliveredToList, setDeliveredToList] = useState([]);


  useEffect(() => {
    if (props.messageObject) {
      setIsLoading(true)
      if (props.groupId && props.groupId.length > 0) {
        props.getGroupMapsForGroupId(props.groupId)
      }
      getReadBy()
    }
  }, [props.messageObject])

  useEffect(() => {
    if (props.teachers) {
      let tempTeacherList = []
      for (const teacher of props.teachers) {
        let tempObj = {}
        tempObj.name = teacher.attributes.Name
        tempObj.id = teacher.id
        tempObj.role = teacher.attributes.Role
        tempObj.parseObject = teacher
        tempObj.photo = teacher.attributes.Photo ? teacher.attributes.Photo._url : undefined
        let readBy = readByList.filter((read) => read.attributes.readByUserId === teacher.id)
        let deliveredTo = deliveredToList.filter((read) => read.attributes.deliveredToUserId === teacher.id)
        if (readBy.length > 0) {
          tempObj.status = read
        } else if (deliveredTo.length > 0) {
          tempObj.status = delivered
        } else {
          tempObj.status = sent
        }
        tempTeacherList.push(tempObj)
      }
      setTeacherList(tempTeacherList)
    }
  }, [props.teachers])

  useEffect(() => {
    if (props.kids) {
      let familyIds = []
      for (const kid of props.kids) {
        familyIds.push(kid.attributes.FamilyID)
      }
      if (familyIds.length) {
        props.getParentsForFamilyIds(familyIds, true)
      }
    }
  }, [props.kids])

  useEffect(() => {
    if (props.kids && props.parents) {
      let tempKidList = []
      for (const kid of props.kids) {
        let tempObj = {}
        tempObj.name = kid.attributes.Name
        tempObj.id = kid.id
        tempObj.parseObject = kid
        tempObj.photo = kid.attributes.Photo ? kid.attributes.Photo._url : undefined
        let parents = props.parents.filter((parent) => parent.attributes.FamilyID === kid.attributes.FamilyID)
        tempObj.fatherStatus = sent
        tempObj.motherStatus = sent
        for (const i in parents) {
          if (parents[i].attributes.Relation === "Mother") {
            tempObj.motherStatus = readByList.filter((obj) => obj.attributes.readByUserId === parents[i].id).length > 0 ? read :
              deliveredToList.filter((obj) => obj.attributes.deliveredToUserId === parents[i].id).length > 0 ? delivered : sent
          } else {
            tempObj.fatherStatus = readByList.filter((obj) => obj.attributes.readByUserId === parents[i].id).length > 0 ? read :
              deliveredToList.filter((obj) => obj.attributes.deliveredToUserId === parents[i].id).length > 0 ? delivered : sent
          }
        }

        tempKidList.push(tempObj)
      }
      setKidList(tempKidList)
    }
  }, [props.kids, props.parents])


  const getReadBy = () => {
    var query = new Parse.Query('ReadBy');
    query.equalTo('messageId', props.messageObject.parseObject.id);
    query.limit(1000);
    query.find().then((results) => {
      setReadByList(results)
      getDeliveredToTeacher()
    })
  }

  const getDeliveredToTeacher = () => {
    var query = new Parse.Query('DeliveredTo');
    query.equalTo('messageId', props.messageObject.parseObject.id);
    query.limit(1000);
    query.find().then((results) => {
      setIsLoading(false)
      setDeliveredToList(results)
      props.getTeachersForSchool(props.schoolId);
      props.getAllKids(props.schoolId)
    })
  }

  const getKidTableUI = () => {
    return (
      <div className="table-responsive mt-2">
        <Table className="table-centered table-nowrap">
          <tbody>
            {getFilteredKidList(kidList).map((obj, key) => (
              <tr key={"kid_" + key}>
                <td>
                  <Media>
                    <div className="align-self-center mr-3">
                      <img src={obj.photo ?? avatar1} className="avatar-xs rounded-circle" alt="" />
                    </div>
                    <Media body>
                      <h5 className="font-size-15 align-middle mt-2">{obj.name}</h5>
                    </Media>
                  </Media>
                </td>
                <td style={{ textAlign: "center" }}>
                  <h5 className="font-size-24 mt-2">
                    &#128105;
                    <i className={`ml-2 mb-1 bx bx-${obj.motherStatus && obj.motherStatus.icon} font-size-24 align-middle text-${obj.motherStatus && obj.motherStatus.iconColor}`}></i>
                  </h5>
                </td>
                <td style={{ textAlign: "center" }}>
                  <h5 className="font-size-24 mt-2">
                    &#128104;
                    <i className={`ml-2 mb-1 bx bx-${obj.fatherStatus && obj.fatherStatus.icon} font-size-24 align-middle text-${obj.fatherStatus && obj.fatherStatus.iconColor}`}></i>
                  </h5>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }

  const getFilteredKidList = (kidList) => {
    if (props.isAllSchoolSelected) {
      return kidList
    }
    if (props.isAllTeachersSelected) {
      return []
    }
    let newList = []
    if (props.classId && props.classId.length > 0) {
      for (const kid of kidList) {
        let kidClassRoomId = kid.parseObject && kid.parseObject.attributes.ClassRoomID
        let kidDaycareClassId = kid.parseObject && kid.parseObject.attributes.daycareClassId
        if (kidClassRoomId && kidClassRoomId.length > 0) {
          if (kidClassRoomId === props.classId) {
            newList.push(kid)
          }
        } else if (kidDaycareClassId && kidDaycareClassId.length > 0) {
          if (kidDaycareClassId === props.classId) {
            newList.push(kid)
          }
        }
      }
    }

    if (props.groupId && props.groupId.length > 0 && props.groupmaps && props.groupmaps.length > 0) {
      for (const groupmap of props.groupmaps) {
        if (groupmap.attributes.userType === 1) {
          let getKid = kidList.filter((kid) => kid.parseObject.id === groupmap.attributes.userId)
          if (getKid.length > 0) {
            newList.push(getKid[0])
          }
        }
      }
    }




    return newList
  }

  const getFilteredTeacherList = (teacherList) => {
    if ((props.isAllSchoolSelected) || (props.isAllTeachersSelected)) {
      return teacherList
    }


    let newList = []


    if (props.classId && props.classId.length > 0) {
      for (const teacher of teacherList) {
        let teacherClassIdList = teacher.parseObject && teacher.parseObject.attributes.ClassRooms

        if (teacherClassIdList) {
          if (teacherClassIdList.includes(props.classId) && teacher.role === 1) {
            newList.push(teacher)
          }
        }
      }
    }

    if (props.groupId && props.groupId.length > 0 && props.groupmaps && props.groupmaps.length > 0) {
      for (const groupmap of props.groupmaps) {
        if (groupmap.attributes.userType === 2) {
          let getTeacher = teacherList.filter((teacher) => teacher.parseObject.id === groupmap.attributes.userId && teacher.role === 1)
          if (getTeacher.length > 0) {
            newList.push(getTeacher[0])
          }
        }
      }
    }

    let filteredList = (teacherList.filter((teacher) => {
      if ((teacher.role === 2 || teacher.role === 3 ||
        teacher.role > 9) && !newList.includes(teacher)) {
        return true
      }
    }))

    newList = newList.concat(filteredList)

    return newList
  }

  const getTeacherTableUI = () => {
    return (
      <div className="table-responsive mt-2">
        <Table className="table-centered table-nowrap">
          <tbody>
            {getFilteredTeacherList(teacherList).map((obj, key) => (
              <tr key={"teacher_" + key}>
                <td>
                  <Media>
                    <div className="align-self-center mr-3">
                      <img src={obj.photo ?? avatar1} className="avatar-xs rounded-circle" alt="" />
                    </div>
                    <Media body>
                      <h5 className="font-size-15 align-middle mt-2">{obj.name}</h5>
                    </Media>
                  </Media>
                </td>
                <td style={{ textAlign: "center" }}>
                  <h5 className="font-size-14  mt-2">
                    <i className={`bx bx-${obj.status && obj.status.icon} font-size-24 align-middle text-${obj.status && obj.status.iconColor}`}></i>
                  </h5>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    )
  }


  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          <Modal
            isOpen={props.messageObject ? true : false}
            fade={false}
            className="modal-dialog modal-md"
          >
            <ModalHeader toggle={() => {
              props.onClose()
            }}>
              <div className="d-flex">
                <label className="mr-4"> Status</label><label className=""> ( <i className="bx bx-check font-size-20 align-middle" />Sent <i className="bx bx-check-double font-size-20 align-middle" />Delivered <i className="bx bx-check-double text-primary font-size-20 align-middle" />Read )</label>

              </div>
            </ModalHeader>
            <ModalBody
              style={{ maxHeight: `calc(100vh - 125px)`, overflowY: "auto" }}
            >
              {isLoading ? (
                <Col xs="12">
                  <div className="text-center my-3">
                    <Link to="#" className="text-success">
                      <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                      {props.t("Loading")}{" "}
                    </Link>
                  </div>
                </Col>
              ) : (
                <>{props.messageObject && <div>
                  <div style={{ backgroundColor: "white", border: "1px solid lightGrey", borderRadius: "5px" }}>
                    <label className="p-3">Message - {props.messageObject.message}</label>
                  </div>
                  <center className="mt-3" style={{ backgroundColor: "white", border: "1px solid lightGrey", borderRadius: "20px" }}>
                    <label className="mt-2 font-size-16">
                      Parents
                    </label>
                  </center>
                  {getKidTableUI()}
                  <center className="mt-3" style={{ backgroundColor: "white", border: "1px solid lightGrey", borderRadius: "20px" }}>
                    <label className="mt-2 font-size-16">
                      Teachers
                    </label>
                  </center>
                  {getTeacherTableUI()}
                </div>}</>

              )}

            </ModalBody>

          </Modal>

        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { teachers } = state.Teacher;
  const { kids } = state.Kid;
  const { parents } = state.Parent;
  const { groupmaps } = state.GroupMap;

  return {
    teachers,
    kids,
    parents,
    groupmaps
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getTeachersForSchool,
      getAllKids,
      getParentsForFamilyIds,
      getGroupMapsForGroupId
    })(MessageStatusView)
  )
);
