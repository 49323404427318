import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventorySaleOrderHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventorySaleOrder_helper";
import * as actionType from "./actionTypes";
import * as InventorySaleOrderAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getSaleOrdersForOwnerId({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_SALE_ORDER_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventorySaleOrderHelper.getSaleOrderForOwnerId, ownerId, ownerType, null, true);
        yield put(InventorySaleOrderAction.updateSaleOrders(localResponse));


        const serverResponse = yield call(InventorySaleOrderHelper.getSaleOrderForOwnerId, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventorySaleOrderAction.updateSaleOrders(serverResponse));

            var nonDeleted = serverResponse.filter(
                (inventorySaleOrder) =>
                    inventorySaleOrder.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventorySaleOrder) =>
                    inventorySaleOrder.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* getSaleOrdersForBuyer({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_SALE_ORDER_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventorySaleOrderHelper.getSaleOrderForBuyer, ownerId, ownerType, null, true);
        yield put(InventorySaleOrderAction.updateSaleOrders(localResponse));


        const serverResponse = yield call(InventorySaleOrderHelper.getSaleOrderForBuyer, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventorySaleOrderAction.updateSaleOrders(serverResponse));

            var nonDeleted = serverResponse.filter(
                (inventorySaleOrder) =>
                    inventorySaleOrder.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventorySaleOrder) =>
                    inventorySaleOrder.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}
function* editInventorySaleOrder({ payload: { inventorySaleOrder } }) {
    try {
        const response = yield call(InventorySaleOrderHelper.editInventorySaleOrder, inventorySaleOrder);
        if (response === "Error") {
            toastr.error("Please try again", { timeOut: 3000 });
        } else
            if (response) {
                yield put(InventorySaleOrderAction.updateSaleOrders(response));
                response.pin();
                toastr.success("Sale order successfully updated", { timeOut: 3000 });
            } else {
            }
    } catch (error) {

    }
}

function* deleteInventorySaleOrder({ payload: { inventorySaleOrder } }) {
    try {
        inventorySaleOrder.set("isDeleted", TrustedScriptURL);
        yield put(InventorySaleOrderAction.updateSaleOrders([inventorySaleOrder]));
        const response = yield call(InventorySaleOrderHelper.deleteInventorySaleOrderFromParse, inventorySaleOrder);
        if (response === "error") {
            inventorySaleOrder.set("isDeleted", undefined);
            yield put(InventorySaleOrderAction.updateSaleOrders([inventorySaleOrder]));
        } else
            if (response) {
                toastr.success("Sale order successfully removed", { timeOut: 3000 });
                response.unPin();
            } else {

            }

    } catch (error) {

    }
}

function* setSelectedSaleOrder({ payload: { inventorySaleOrderId } }) {
    try {
        if (inventorySaleOrderId) {
            const localResponse = yield call(
                InventorySaleOrderHelper.getInventorySaleOrderFromParse,
                inventorySaleOrderId, true
            );
            if (localResponse) {
                yield put(InventorySaleOrderAction.updateSelectedSaleOrder(localResponse));
            }

            const serverResponse = yield call(
                InventorySaleOrderHelper.getInventorySaleOrderFromParse,
                inventorySaleOrderId,
                false
            );
            if (serverResponse) {
                yield put(InventorySaleOrderAction.updateSelectedSaleOrder(serverResponse));
                serverResponse.pin();
            }
        }
    } catch (error) {

    }
}

function* addInventorySaleOrder({ payload: { inventorySaleOrder } }) {
    try {

        yield put(InventorySaleOrderAction.updateSaleOrders(inventorySaleOrder));
        const response = yield call(
            InventorySaleOrderHelper.editInventorySaleOrder, inventorySaleOrder
        );
        if (response === "Error") {

            toastr.error("Please try again", { timeOut: 3000 });
            const local = yield call(
                InventorySaleOrderHelper.getInventorySaleOrderFromLocal, inventorySaleOrder.id
            );

            if (local) {
                yield put(InventorySaleOrderAction.updateSaleOrders(local));
            }

        } else
            if (response) {
                yield put(InventorySaleOrderAction.updateSaleOrders(response));
                toastr.success("Sale Order successfully added", { timeOut: 3000 });
                response.pin();
            } else {

            }
    } catch (error) {

    }
}


function* getCartForOwnerId({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_CART_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventorySaleOrderHelper.getCartForOwnerId, ownerId, ownerType, null, true);
        yield put(InventorySaleOrderAction.updateCartForOwnerId(localResponse));


        const serverResponse = yield call(InventorySaleOrderHelper.getCartForOwnerId, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventorySaleOrderAction.updateCartForOwnerId(serverResponse));

            var nonDeleted = serverResponse.filter(
                (inventorySaleOrder) =>
                    inventorySaleOrder.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventorySaleOrder) =>
                    inventorySaleOrder.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* addInventoryCart({ payload: { cartObject, type } }) {
    try {
        const response = yield call(InventorySaleOrderHelper.editInventorySaleOrder, cartObject);
        if (response === "Error") {
            toastr.error("Please try again", { timeOut: 3000 });
        } else
            if (response) {
                yield put(InventorySaleOrderAction.updateCartForOwnerId([response]));
                response.pin();
                if (type == "added") {
                    toastr.success("Added to cart", { timeOut: 2000 });
                }

                if (type == "removed") {
                    toastr.warning("Removed From cart", { timeOut: 2000 });
                }

            } else {
            }
    } catch (error) {

    }
}


export function* watchGetSaleOrdersForOwnerId() {
    yield takeEvery(actionType.GET_INVENTORY_SALE_ORDERS, getSaleOrdersForOwnerId);
}
export function* watchEditInventorySaleOrder() {
    yield takeEvery(actionType.EDIT_INVENTORY_SALE_ORDER, editInventorySaleOrder);
}

export function* watchDeleteInventorySaleOrder() {
    yield takeEvery(actionType.DELETE_INVENTORY_SALE_ORDER, deleteInventorySaleOrder);
}

export function* watchAddInventorySaleOrder() {
    yield takeEvery(actionType.ADD_INVENTORY_SALE_ORDER, addInventorySaleOrder);
}

export function* watchSetSelectedSaleOrder() {
    yield takeEvery(actionType.SET_SELECTED_SALE_ORDER, setSelectedSaleOrder);
}

export function* watchGetCartForOwnerId() {
    yield takeEvery(actionType.GET_CART_FOR_OWNER_ID, getCartForOwnerId);
}

export function* watchAddInventoryCart() {
    yield takeEvery(actionType.ADD_INVENTORY_CART, addInventoryCart);
}

export function* watchGetSaleOrdersForBuyer() {
    yield takeEvery(actionType.GET_INVENTORY_SALE_ORDERS_FOR_BUYER, getSaleOrdersForBuyer);
}



function* InventorySaleOrderSaga() {
    yield all([
        fork(watchGetSaleOrdersForOwnerId),
        fork(watchEditInventorySaleOrder),
        fork(watchDeleteInventorySaleOrder),
        fork(watchAddInventorySaleOrder),
        fork(watchSetSelectedSaleOrder),
        fork(watchGetCartForOwnerId),
        fork(watchAddInventoryCart),
        fork(watchGetSaleOrdersForBuyer),
    ]);
}
export default InventorySaleOrderSaga;