import React, { useState, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Alert,
  CardBody,
  Media,
  Button,
  Table,
  NavItem,
  Nav,
  TabContent,
  TabPane,
  CardText,
  NavLink,
  UncontrolledTooltip,
  Form,
  FormGroup,
  Label,
  Input,
} from "reactstrap";
import FormTab from "../studentView/formTab";

import { withNamespaces } from "react-i18next";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import Editable from "react-bootstrap-editable";
import Parse from 'parse';
import Select from "react-select";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import * as Constants from "../../Constents";

//Import Breadcrumb
import Breadcrumb from "../../components/Common/Breadcrumb";
import UpdatePhoto from "../../components/Common/UpdatePhoto";

// actions
import {
  getTeacher,
  setSelecetdTeacher,
  setSelecetdSchool,
  getClassroomsForSchoolId,
  editTeacher,
  deleteTeacher,
  responseForWebAccess,
  requestForWebAccess,
  requestForWebAccessRemoval,
} from "../../store/actions";
import { matchPath } from "react-router";

//components
import TeacherPermissions from "./TeacherPermissions";
import TecaherClassrooms from "./TecaherClassrooms";
import OtherDetails from './OtherDetails';

const TeacherProfile = (props) => {
  const [name, setName] = useState(" ");
  const [email, setEmail] = useState(undefined);
  const [phone, setPhoneNumber] = useState(undefined);
  const [role, setRole] = useState(1);
  const [designation, setDesignation] = useState(1);
  const [dob, setDOB] = useState(undefined);
  const [doj, setDOJ] = useState(undefined);
  const [gender, setGender] = useState(undefined);
  const [about, setAbout] = useState(undefined);
  const [image, setImage] = useState(undefined);
  const [emergencyContact, setEmergencyContact] = useState(undefined);
  const [bloodGroup, setBloodGroup] = useState(undefined);
  const [isBloodGroupEditOn, setIsBloodGroupEditOn] = useState(false);
  const [employeeCode, setEmployeeCode] = useState(undefined);
  const [webAccessResponse, setWebAccessResponse] = useState(undefined);

  const [isEditDesignationOn, setIsEditDesignationOn] = useState(false);
  const [dl, setDl] = useState([
    "Teacher",
    "CenterHead",
    "Centre Director",
    "Academic Coordinator",
    "Principal",
    "Admission Coordinator",
    "Nanny",
    "Helper",
    "Security",
    "Others",
  ]);

  const [otherFetures, setOtherFeatures] = useState([])

  const designations = [
    { title: props.t("Teacher"), role: 1 },
    { title: props.t("CenterHead"), role: 2 },
    { title: props.t("Director"), role: 3 },
    { title: props.t("Centre Director"), role: 2 },
    { title: props.t("Academic Coordinator"), role: 2 },
    { title: props.t("Principal"), role: 2 },
    { title: props.t("Admission Coordinator"), role: 2 },
    { title: props.t("Nanny"), role: 1 },
    { title: props.t("Driver"), role: 5 },
    { title: props.t("Helper"), role: 6 },
    { title: props.t("Security"), role: 7 },
    { title: props.t("Others"), role: 1 },
  ];

  const bloodGroups = [
    { value: "A+", label: "A+" },
    { value: "B+", label: "B+" },
    { value: "O+", label: "O+" },
    { value: "AB+", label: "AB+" },
    { value: "A-", label: "A-" },
    { value: "B-", label: "B-" },
    { value: "O-", label: "O-" },
    { value: "AB-", label: "AB-" },
  ];

  const [isOther, setIsOther] = useState(false);
  const [customDesignation, setCustomDesignation] = useState("");
  const [activeTab, setactiveTab] = useState("1");
  const [showDelete, setShowDelete] = useState(false);
  const [isWebAccess, setWebAccess] = useState(false);

  const [canEdit, setCanEdit] = useState(false);

  const [showIDCard, setShowIdCard] = useState(false)

  const match1 = matchPath(props.history.location.pathname, {
    path: "/staffprofile/:schoolId/:id",
    exact: true,
    strict: false,
  });

  const match2 = matchPath(props.history.location.pathname, {
    path: "/coordinators-profile/:schoolId/:id",
    exact: true,
    strict: false,
  });
  const match = match1 ?? match2;
  const [schoolId, setSchoolId] = useState(match.params.schoolId);
  const parameter = match.params.id;

  const [roleDesignations, setRoleDesignations] = useState([
    { title: "Teacher/Nanny", role: 1 },
    { title: "CenterHead/Principal", role: 2 },
    { title: "Security", role: 7 },
  ]);

  useEffect(() => {
    props.setSelecetdTeacher(parameter);
    props.responseForWebAccess(undefined);

    if (schoolId && schoolId.startsWith("d-")) {
      let res = schoolId.split("-");
      if (res.length >= 1) {
        let sId = res[1];
        setSchoolId(sId);
        props.setSelecetdSchool(sId);
        props.getClassroomsForSchoolId(sId);
      }
    } else if (schoolId) {
      props.setSelecetdSchool(schoolId);
      props.getClassroomsForSchoolId(schoolId);
    }
  }, [parameter]);

  useEffect(() => {
    if (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) {
      toggleTab("2");
    }

  }, [props.selectedSchool]);

  useEffect(() => {
    if (!props.userTeacher) {
      if (Parse.User.current() && Parse.User.current().attributes.teacherId) {
        props.getTeacher(Parse.User.current().attributes.teacherId)
      }
    }
    setCanEdit(checkForEdit())
  }, [props.userTeacher, Parse.User.current()]);

  const checkForEdit = () => {
    if (props.selectedTeacher && props.selectedTeacher.id && parameter === props.selectedTeacher.id) {
      if (props.selectedTeacher && props.selectedTeacher.attributes.Role === 3) {
        if (props.userTeacher && (props.userTeacher.attributes.Role === 3 || props.userTeacher.attributes.Role > 10) &&
          Parse.User.current() && Parse.User.current().attributes.isSuperUser) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    }
  }

  useEffect(() => {
    if (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) {
      setOtherFeatures(["feature"])
    } else if (props.selectedTeacher && (props.selectedTeacher.attributes.Role <= 3)) {
      setOtherFeatures(["classroom", "feature", "forms"])
    } else if (props.selectedTeacher && (props.selectedTeacher.attributes.Role >= 10)) {
      setOtherFeatures(["classroom", "feature", "forms"])
    }
  }, [props.selectedSchool, props.selectedTeacher]);

  useEffect(() => {
    let teacher = props.selectedTeacher;
    if (teacher && teacher.id && parameter === teacher.id) {
      setName(teacher.attributes.Name);
      setEmail(teacher.attributes.EMail);
      setPhoneNumber(teacher.attributes.PhoneNumber);
      setRole(teacher.attributes.Role);
      // setDesignation(getDesignation());

      var temp = designations.filter((item) => item.title === teacher.attributes.designation);

      if (temp.length > 0) {
        setDesignation(getDesignation());
        setIsOther(false);
        setCustomDesignation(teacher.attributes.designation);
      } else {
        setDesignation("Others");
        setIsOther(true);
        setCustomDesignation(teacher.attributes.designation);
      }
      setDOB(teacher.attributes.dob);
      setDOJ(teacher.attributes.doj);
      if (teacher.attributes.bloodGroup) {
        setBloodGroup({ label: teacher.attributes.bloodGroup, value: teacher.attributes.bloodGroup })
      }

      setGender(teacher.attributes.Gender);
      setAbout(teacher.attributes.about);
      setEmployeeCode(teacher.attributes.empCode);
      setEmergencyContact(teacher.attributes.emergencyContact);
      setWebAccess(teacher.attributes.isWebAccess ? true : false);
      if (teacher.attributes.Photo) {
        setImage(teacher.attributes.Photo._url);
      } else {
        setImage(undefined);
      }

      if (teacher.attributes.Role === 3) {
        setDl(["Director", "Others"]);
        setRoleDesignations([
          { title: "Teacher/Nanny", role: 1 },
          { title: "CenterHead/Principal", role: 2 },
          { title: "Director", role: 3 },
          { title: "Security", role: 7 },
        ]);
      }
      setCanEdit(checkForEdit())
    }
  }, [props.selectedTeacher]);

  useEffect(() => {
    if (props.franchise) {
      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_ID_CARD_DISABLE_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_ID_CARD_DISABLE_INDEX] > 0) {
        setShowIdCard(false)
      }
      else if (!showIDCard) {
        setShowIdCard(true)
      }
    }

  }, [props.franchise]);

  function getDesignation() {
    if (props.selectedTeacher) {
      let teacherAttributes = props.selectedTeacher.attributes;
      if (teacherAttributes) {
        if (props.selectedSchool && props.selectedSchool.attributes.type == 10) {
          if (teacherAttributes.designation) {
            return teacherAttributes.designation;
          } else if (teacherAttributes.Role === 3) {
            return props.t("Admin");
          } else {
            return props.t("Teacher");
          }
        } else if (teacherAttributes.designation) {
          return teacherAttributes.designation;
        } else if (teacherAttributes.Role === 3) {
          return props.t("Director");
        } else if (teacherAttributes.Role === 2) {
          return props.t("CenterHead");
        } else {
          return props.t("Teacher");
        }
      }
    }
    return props.t("Teacher");
  }
  function toggleTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const handlePicChange = (imageFile, cr) => {
    // if (props.selectedTeacher) {
    //     var temp = props.selectedTeacher;
    //     temp.set("Photo", imageFile);
    //     console.log(imageFile);

    //     temp.save().then(
    //         (result) => {
    //             console.log(result)
    //         },
    //         (error) => {
    //             console.log(error)

    //         }
    //     );
    // }
    var src = "data:image/jpeg;base64,";

    var image = new Image();
    image.src = src + imageFile._data;

    setImage(cr);
    imageFile.save().then(
      (result) => {
        setImage(result._url);
        var temp = props.selectedTeacher;
        temp.set("Photo", result);
        props.editTeacher(temp);
      },
      (error) => {
        console.log("File:", error);
      },
      (progress) => {
        console.log(progress);
      }
    );
  };
  const handelChangeFeatureList = (list) => {
    var temp = props.selectedTeacher;
    temp.set("featurePermissions", list);
    props.editTeacher(temp);
  };
  const handelChangeTeacherWebAccess = (boolValue) => {
    // var temp = props.selectedTeacher;
    // temp.set("isWebAccess", boolValue);
    // props.editTeacher(temp);

    if (props.selectedTeacher.attributes.Role === 2) {
      props.requestForWebAccessRemoval(
        props.selectedTeacher.attributes.EMail,
        props.selectedTeacher.attributes.Role,
        props.selectedTeacher.id,
        props.selectedTeacher.attributes.SchoolID,
        Constants.SCHOOL_LEVEL,
        props.selectedTeacher,
      );
    } else if (props.selectedTeacher.attributes.Role === 3) {
      props.requestForWebAccessRemoval(
        props.selectedTeacher.attributes.EMail,
        props.selectedTeacher.attributes.Role,
        props.selectedTeacher.id,
        props.selectedTeacher.attributes.instituteId,
        Constants.INSTITUTE_LEVEL,
        props.selectedTeacher
      );
    }
  };

  const handelClassroomList = (list) => {
    var temp = props.selectedTeacher;
    temp.set("ClassRooms", list);
    props.editTeacher(temp);
  };

  const handelDeleteTeacher = () => {
    if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {
      props.deleteTeacher(parameter, props.t("Coordinator deleted Successfully!"));
    } else {
      props.deleteTeacher(parameter, props.t("Teacher deleted Successfully!"));
    }

    setShowDelete(false);
    // window.location = "/staff";
  };

  const handlePhoneChange = (value) => {
    setPhoneNumber(value);
    var temp = props.selectedTeacher;
    temp.set("PhoneNumber", value);
    props.editTeacher(temp);
  };
  const handleEmailChange = (value) => {
    setEmail(value);
    var temp = props.selectedTeacher;
    temp.set("EMail", value);
    props.editTeacher(temp);
  };
  const handleEmoployeCodeChange = (value) => {
    setEmployeeCode(value);
    var temp = props.selectedTeacher;
    temp.set("empCode", value);
    props.editTeacher(temp);
  };
  const handleEmergencyContactChange = (value) => {
    setEmergencyContact(value);
    var temp = props.selectedTeacher;
    temp.set("emergencyContact", value);
    props.editTeacher(temp);
  };
  const handleDOBChange = (value) => {
    setDOB(value);
    var temp = props.selectedTeacher;
    temp.set("dob", value);
    props.editTeacher(temp);
  };
  const handleDOJChange = (value) => {
    setDOJ(value);
    var temp = props.selectedTeacher;
    temp.set("doj", value);
    props.editTeacher(temp);
  };
  const handleBloodGroupChange = () => {
    setIsBloodGroupEditOn(false);
    var temp = props.selectedTeacher;
    temp.set("bloodGroup", bloodGroup ? bloodGroup.value : undefined);
    props.editTeacher(temp);
  }

  function ValidateEmail(value) {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }
  function ValidatePhonenumber(value) {
    var phoneno = /^([0|\+[0-9]{1,5})?([7-9][0-9]{9,14})$/;
    if (value.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    if (props.webAccessResponse) {
      setWebAccessResponse(props.webAccessResponse);
    }
  }, [props.webAccessResponse]);

  const handleEditDesignation = () => {
    setIsEditDesignationOn(true);
  };
  const clearWebAccessResponce = () => {
    props.responseForWebAccess(undefined);
  }
  const handleWebAccessRequest = async () => {
    //email,teacherRole,teacherId,levelId
    if (props.selectedTeacher.attributes.Role === 2) {
      props.requestForWebAccess(
        props.selectedTeacher.attributes.EMail,
        props.selectedTeacher.attributes.Role,
        props.selectedTeacher.id,
        props.selectedTeacher.attributes.SchoolID,
        Constants.SCHOOL_LEVEL,
        props.selectedTeacher,
        props.selectedSchool.attributes.instituteId
      );
    } else if (props.selectedTeacher.attributes.Role === 3) {
      props.requestForWebAccess(
        props.selectedTeacher.attributes.EMail,
        props.selectedTeacher.attributes.Role,
        props.selectedTeacher.id,
        props.selectedTeacher.attributes.instituteId,
        Constants.INSTITUTE_LEVEL,
        props.selectedTeacher
      );
    } else if (props.selectedTeacher.attributes.Role === 1) {
    }

    //         const response = await Parse.Cloud.run("grantWebAccesstoStaff", params);
    //         console.log("working For response")
    //         console.log(response)

    //props.requestForWebAccess(props.selectedTeacher.attributes.EMail, props.selectedTeacher.attributes.Role,props.selectedTeacher.id)

    if (props.webAccessResponse != undefined) {
      setWebAccessResponse(props.webAccessResponse);
    }
  };

  const saveTeacherDesignation = () => {
    var designationValue = "";
    if (designation === "Others") {
      designationValue = customDesignation;
    } else {
      designationValue = designation;
    }
    var roleValue = 1;
    if (isOther) {
      roleValue = role;
    }
    if (props.selectedTeacher && props.selectedTeacher.attributes.Role === 3) {
    } else {
      var result = designations.filter((item) => item.title === designation && designation!="Others");
      if (result && result.length > 0) {
        roleValue = result[0].role;
      }
    }

    var temp = props.selectedTeacher;
    temp.set("designation", designationValue);
    if (props.selectedTeacher && props.selectedTeacher.attributes.Role !== 3) {
      temp.set("Role", roleValue);
    } else {
      if (props.selectedTeacher == null) {
        temp.set("Role", roleValue);
      }
    }

    //temp.set("Role", 3);

    console.log(temp);

    props.editTeacher(temp);
    setIsEditDesignationOn(false);
  };


  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {showDelete && (
            <SweetAlert
              warning
              showCancel
              confirmBtnText="Delete"
              cancelBtnText="Cancel"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="success"
              title="Alert!"
              onCancel={() => {
                setShowDelete(false);
              }}
              onConfirm={() => handelDeleteTeacher()}
            >
              {props.t("Are you sure you want to delete") + " " + name}{" "}
              <br></br>
            </SweetAlert>
          )}
          <Row className='d-flex align-items-start'>
            <Col className="d-flex align-items-start">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => { window.history.back() }}>
                <ul className=" list-unstyled">
                  <div className=''>
                    <i className="bx bx-chevron-left h1 text-primary"></i>
                  </div>
                </ul>
              </div>
              <div className=' m-2'>
                <Breadcrumb title={props.selectedSchool && props.selectedSchool.attributes.schoolType == 10 ? "Coordinator Profile" : "Staff Profile"} breadcrumbItem="Profile" />
              </div>
            </Col>
          </Row>
          {!props.loading ? (
            <Row>
              <Col lg="12">
                {props.error && props.error ? (
                  <Alert color="danger">{props.error}</Alert>
                ) : null}
                {props.success && props.success ? (
                  <Alert color="success">{props.success}</Alert>
                ) : null}

                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={3} md={4} xs={12}></Col>
                      <Col lg={9} md={8} xs={12}>
                        <div className=" d-flex align-items-center justify-content-between">
                          <h3 className="center">{name.toUpperCase()}</h3>

                          {canEdit && <div className="page-title-right mr-2 contact-links d-flex font-size-20">
                            {showIDCard && <div className="flex-fill m-1">
                              <Link
                                to={"/staffIdcard/" + schoolId + "/" + parameter} target="_blank" id={"idCard" + parameter} >
                                <i className="bx bx-id-card"></i>
                                <UncontrolledTooltip placement="top" target={"idCard" + parameter}>
                                  {props.t('ID Card')}
                                </UncontrolledTooltip>
                              </Link>
                            </div>}
                            <div className="flex-fill m-1 ">
                              <Link
                                to={
                                  props.selectedSchool && props.selectedSchool.attributes.schoolType == 10 ?
                                    "/editCoordinator/" + schoolId + "/" + parameter : "/editstaff/" + schoolId + "/" + parameter
                                }
                                id="profile-edit"
                              >
                                <i className="bx bx-edit-alt"></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"profile-edit"}
                                >
                                  {props.t("Edit")}
                                </UncontrolledTooltip>
                              </Link>
                            </div>
                            <div
                              className="flex-fill m-1"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowDelete(true);
                              }}
                              id="profile-delete"
                            >
                              <i className="bx bxs-trash text-danger"></i>
                              <UncontrolledTooltip
                                placement="top"
                                target={"profile-delete"}
                              >
                                {props.t("Delete")}
                              </UncontrolledTooltip>
                            </div>
                          </div>}
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3} md={4} xs={12} className="text-center  ">
                        <div className="border p-2">
                          {!image ? (
                            <div
                              className="avatar-lg mx-auto mb-2"
                              style={{ width: "10rem", height: "10rem" }}
                            >
                              <span
                                className={
                                  "avatar-title rounded-circle bg-soft-primary text-primary"
                                }
                                style={{ fontSize: "60px" }}
                              >
                                {name.charAt(0).toUpperCase()}
                              </span>
                            </div>
                          ) : (
                            <div>
                              <img
                                style={{ width: "10rem", height: "10rem" }}
                                className="rounded-circle avatar mb-2"
                                src={image}
                                alt=""
                              />
                            </div>
                          )}

                          {canEdit && <UpdatePhoto
                            className="p-1"
                            isPopupOn={false}
                            onPicChange={(imageFile, cr) => {
                              handlePicChange(imageFile, cr);
                            }}
                            initialImage={image}
                          ></UpdatePhoto>}
                        </div>
                      </Col>
                      <Col lg={9} md={8} xs={12}>
                        <div className="">
                          <div className="table-responsive">
                            <Table className="table mb-0 table-bordered">
                              <tbody>
                                <tr>
                                  <th scope="row" style={{ width: "200px" }}>
                                    {props.t("Phone")}
                                  </th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onValidated={handlePhoneChange}
                                      onCancel={() => {
                                        props.selectedTeacher &&
                                          setPhoneNumber(
                                            props.selectedTeacher.attributes
                                              .PhoneNumber
                                          );
                                      }}
                                      disabled={canEdit ? false : true}
                                      editText={phone ? " ✎" : "No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={
                                        phone && phone.length > 0 ? phone : ""
                                      }
                                      showText
                                      type="textfield"
                                      validate={(value) => {
                                        if (!value || value.length == 0) {
                                          return props.t(
                                            "Please enter phone number!."
                                          );
                                        } else
                                          if (!ValidatePhonenumber(value)) {
                                            return props.t(
                                              "You have entered an invalid phone number!"
                                            );
                                          }
                                      }}
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Email")}</th>
                                  {

                                    (isWebAccess) ?
                                      <td>
                                        {props.selectedTeacher.attributes.EMail}
                                      </td>
                                      :
                                      <td>
                                        <Editable
                                          className={'d-flex'}
                                          alwaysEditing={false}
                                          onValidated={handleEmailChange}
                                          onCancel={() => {
                                            props.selectedTeacher &&
                                              setEmail(
                                                props.selectedTeacher.attributes
                                                  .EMail
                                              );
                                          }}
                                          disabled={canEdit ? false : true}
                                          editText={email ? " ✎" : "No value ✎"}
                                          id={null}
                                          isValueClickable={false}
                                          mode="inline"
                                          placement="top"
                                          initialValue={
                                            email && email.length > 0 ? email : ""
                                          }
                                          showText
                                          type="textfield"
                                          validate={(value) => {
                                            if (!value || value.length == 0) {
                                              return props.t(
                                                "Please enter email."
                                              );
                                            } else if (!ValidateEmail(value)) {
                                              return props.t(
                                                "You have entered an invalid email address!"
                                              );
                                            }
                                          }}
                                        />
                                      </td>
                                  }
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Designation")}</th>
                                  {!isEditDesignationOn && (
                                    <td>
                                      {getDesignation()}
                                      {canEdit && props.selectedSchool && props.selectedSchool.attributes.schoolType != 10 && <button
                                        onClick={handleEditDesignation}
                                        className="bg-white btn btn-sm b-0"
                                      >
                                        <i className="font-size-13 m-1 text-primary  cursors move">
                                          ✎
                                        </i>
                                      </button>}
                                    </td>
                                  )}
                                  {isEditDesignationOn && (
                                    <td>
                                      <Label> {props.t("Designation")}:</Label>
                                      <FormGroup>
                                        <select
                                          className="form-control"
                                          onChange={(e) => {
                                            if (e.target.value === "Others") {
                                              setIsOther(true);
                                            } else {
                                              setIsOther(false);
                                              if (
                                                e.target.value === "Teacher" ||
                                                e.target.value ===
                                                "CenterHead" ||
                                                e.target.value === "Dirctor"
                                              ) {
                                                setCustomDesignation(undefined);
                                              }
                                            }

                                            setDesignation(e.target.value);
                                          }}
                                          value={designation}
                                        >
                                          {dl.map((item, key) => (
                                            <option key={key}>{item}</option>
                                          ))}
                                        </select>
                                      </FormGroup>
                                      {
                                        isOther && <Col lg={4} >
                                          <FormGroup >
                                            <Label >Role</Label>
                                            <select
                                              className="form-control"
                                              onChange={(e) => {
                                                setRole(parseInt(e.target.value))
                                              }}
                                              
                                            value={role}
                                          >
                                            {roleDesignations.map((item, key) => (
                                              <option key={key} value={item.role}>{item.title}</option>
                                            ))}
                                              </select>
                                          </FormGroup>

                                        </Col>
                                      }
                                      {isOther && (
                                        <FormGroup >
                                          <Label >Custom Designation</Label>
                                          <Input
                                            type="text"
                                            name="desig"
                                            className="inner form-control mb-3"
                                            placeholder="Enter Designation"
                                            value={customDesignation}
                                            onChange={(e) => {
                                              setCustomDesignation(
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </FormGroup>

                                      )}

                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm m-1"
                                        style={{ width: "34px" }}
                                        onClick={saveTeacherDesignation}
                                      >
                                        <i className="fa fa-check"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm m-1"
                                        style={{ width: "34px" }}
                                        onClick={() => {
                                          setDOB(props.selectedTeacher.attributes.Role);
                                          var temp = designations.filter((item) => item.title === props.selectedTeacher.attributes.designation);

                                          if (temp.length > 0) {
                                            setDesignation(getDesignation());
                                            setIsOther(false);
                                            setCustomDesignation(props.selectedTeacher.attributes.designation);
                                          } else {
                                            setDesignation("Others");
                                            setIsOther(true);
                                            setCustomDesignation(props.selectedTeacher.attributes.designation);
                                          }
                                          setIsEditDesignationOn(false);
                                        }}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                                {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <tr>
                                  <th scope="row">
                                    {props.t("Employee Code")}
                                  </th>

                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onSubmit={handleEmoployeCodeChange}
                                      onCancel={() => {
                                        props.selectedTeacher &&
                                          setEmployeeCode(
                                            props.selectedTeacher.attributes
                                              .empCode
                                          );
                                      }}
                                      disabled={canEdit ? false : true}
                                      editText={employeeCode ? " ✎" : "No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={
                                        employeeCode && employeeCode.length > 0
                                          ? employeeCode
                                          : ""
                                      }
                                      showText
                                      type="textfield"
                                    />
                                  </td>
                                </tr>}

                                <tr>
                                  <th scope="row">
                                    {props.t("Blood Group")}
                                  </th>
                                  {!isBloodGroupEditOn && (
                                    <td>
                                      {bloodGroup && bloodGroup.value}
                                      <button
                                        onClick={() => {
                                          setIsBloodGroupEditOn(true);
                                        }}
                                        className="bg-white btn btn-sm b-0"
                                      >
                                        <i className="font-size-13 m-1 text-primary cursors move">
                                          ✎
                                        </i>
                                      </button>
                                    </td>
                                  )}
                                  {isBloodGroupEditOn && (
                                    <td>
                                      <Label>{props.t("Blood Group")}:</Label>
                                      <FormGroup>
                                        <Select
                                          value={bloodGroup}
                                          onChange={(v) => { setBloodGroup(v) }}
                                          options={bloodGroups}
                                          classNamePrefix="select2-selection"
                                        />
                                      </FormGroup>
                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm "
                                        style={{ width: "34px" }}
                                        onClick={handleBloodGroupChange}
                                      >
                                        <i className="fa fa-check"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm ml-2"
                                        style={{ width: "34px" }}
                                        onClick={() => {
                                          setBloodGroup('');
                                          let teacher = props.selectedTeacher;
                                          if (teacher.attributes.bloodGroup) {
                                            setBloodGroup({ label: teacher.attributes.bloodGroup, value: teacher.attributes.bloodGroup })
                                          } else {
                                            setBloodGroup(undefined);
                                          }
                                          setIsBloodGroupEditOn(false);
                                        }}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th scope="row">
                                    {props.t("Date of Birth")}
                                  </th>

                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onSubmit={handleDOBChange}
                                      onCancel={() => {
                                        props.selectedTeacher &&
                                          setDOB(
                                            props.selectedTeacher.attributes.dob
                                          );
                                      }}
                                      disabled={canEdit ? false : true}
                                      editText={dob ? " ✎" : "No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={dob ? dob : ""}
                                      showText
                                      type="date"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row">
                                    {props.t("Date of Joining")}
                                  </th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onSubmit={handleDOJChange}
                                      onCancel={() => {
                                        props.selectedTeacher &&
                                          setDOJ(
                                            props.selectedTeacher.attributes.doj
                                          );
                                      }}
                                      disabled={canEdit ? false : true}
                                      editText={doj ? " ✎" : "No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={doj ? doj : ""}
                                      showText
                                      type="date"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">
                                    {props.t("Emergency Contact ")}
                                  </th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onValidated={handleEmergencyContactChange}
                                      onCancel={() => {
                                        props.selectedTeacher &&
                                          setEmergencyContact(
                                            props.selectedTeacher.attributes
                                              .emergencyContact
                                          );
                                      }}
                                      disabled={canEdit ? false : true}
                                      editText={emergencyContact ? " ✎" : "No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={
                                        (emergencyContact &&
                                          emergencyContact.length) > 0
                                          ? emergencyContact
                                          : ""
                                      }
                                      showText
                                      type="textfield"
                                      validate={(value) => {
                                        if (!ValidatePhonenumber(value)) {
                                          return props.t(
                                            "You have entered an invalid phone number!"
                                          );
                                        }
                                      }}
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>

              <Col lg={12}>
                {otherFetures && otherFetures.length > 0 && <Card>
                  <CardBody>
                    <Nav pills className="navtab-bg nav-justified">
                      {otherFetures.includes('classroom') && <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          {props.t("CLASSROOMS")}
                        </NavLink>
                      </NavItem>}
                      {otherFetures.includes('feature') && <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          {props.t("FEATURE PERMISSIONS")}
                        </NavLink>
                      </NavItem>}
                      {<NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "3",
                          })}
                          onClick={() => {
                            toggleTab("3");
                          }}
                        >
                          {props.t("EMPLOYEE DETAILS")}
                        </NavLink>
                      </NavItem>}
                      {otherFetures.includes('forms') && <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "4",
                          })}
                          onClick={() => {
                            toggleTab("4");
                          }}
                        >
                          {props.t("FORMS")}
                        </NavLink>
                      </NavItem>}
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1" className="p-3">
                        <TecaherClassrooms
                          classrooms={props.classrooms}
                          selectedTeacher={props.selectedTeacher}
                          onChangeClassroms={handelClassroomList}
                        />
                      </TabPane>
                      <TabPane tabId="2" className="p-3">
                        <TeacherPermissions
                          selectedTeacher={props.selectedTeacher}
                          selectedSchool={props.selectedSchool}
                          onChangeWebAccess={(value) => {
                            handelChangeTeacherWebAccess(value);
                          }}
                          onRequestForWebAccess={handleWebAccessRequest}
                          webAccessResponse={webAccessResponse}
                          onChangeFeatureList={handelChangeFeatureList}
                          clearWebAccessResponce={clearWebAccessResponce}
                          canEdit={canEdit}
                        />
                      </TabPane>
                      <TabPane tabId="3" className="p-3">
                        <OtherDetails
                          selectedTeacher={props.selectedTeacher}
                          schoolId={schoolId}
                        />
                      </TabPane>
                      <TabPane tabId="4" className="p-3">
                        <FormTab
                          selectedTeacher={props.selectedTeacher}
                          schoolId={
                            props.selectedSchool
                              ? props.selectedSchool.id
                              : schoolId
                          }
                        />
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>}
              </Col>
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <div className="text-center my-3 text-success">
                  <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                  {props.t("Loading")}{" "}
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    teachers,
    error,
    success,
    selectedTeacher,
    loading,
    webAccessResponse,
    webAccessRemovalResponse,
  } = state.Teacher;
  const { selectedSchool } = state.School;
  const { classrooms } = state.Classroom;
  const { userTeacher } = state.Login;
  const { franchise } = state.Franchise
  return {
    teachers,
    error,
    success,
    selectedTeacher,
    loading,
    webAccessResponse,
    webAccessRemovalResponse,
    selectedSchool,
    classrooms,
    userTeacher,
    franchise
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getTeacher,
      setSelecetdTeacher,
      setSelecetdSchool,
      getClassroomsForSchoolId,
      editTeacher,
      deleteTeacher,
      responseForWebAccess,
      requestForWebAccess,
      requestForWebAccessRemoval,
    })(TeacherProfile)
  )
);
