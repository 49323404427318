import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Media, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts"
const SchoolStats = (props) => {
  const [boys, setBoys] = useState([]);
  const [girls, setGirls] = useState([]);
  const [genderOptions, setGenderOptions] = useState({});
  const [genderSeries, setGenderSeries] = useState([]);
  const [download, setDownload] = useState([]);
  const [notDownload, setNotDowmload] = useState([]);
  const [downloadOptions, setDownloadOptions] = useState({});
  const [downloadSeries, setDownloadSeries] = useState([])

  useEffect(() => {
    if (props.kids) {
      let maleCount = 0;
      let femaleCount = 0;
      let registerd = 0;
      let notRegisterd = 0;
      for (const kid of props.kids) {
        if (kid.attributes.Gender == "Male") {
          maleCount++;
        } else {
          femaleCount++;
        }
        if (kid.attributes.Registered) {
          registerd++;
        } else {
          notRegisterd++;
        }

      }
      setGenderSeries([maleCount, femaleCount]);
      setBoys(maleCount);
      setGirls(femaleCount)
      setGenderOptions({
        labels: ["Boys", "Girls"],
        colors: ["#34c38f", "#f46a6a"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          show: false,
        },
      });
      setDownloadSeries([registerd, notRegisterd]);
      setDownload(registerd);
      setNotDowmload(notRegisterd)
      setDownloadOptions({
        labels: ["Downloaded", "Not downloaded"],
        colors: ["#34c38f", "#f46a6a"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          show: false,
        },
      });
    }
  }, [props.kids]);

  return (
    <React.Fragment>
      {" "}
      <Row>
        <Col >
          <Card>
            <CardBody>
              <Row className="d-flex justify-content-between">
            <Col xs={8} className="d-flex justify-content-start">
              <CardTitle className="mb-4">
                {props.t("Boys and Girls Ratio")}
              </CardTitle>
            </Col>
            <Col xs={4} className="d-flex justify-content-end">
              <Link to={`/student/${props.schoolId}`} className='text-primary'><u>Details</u></Link>
            </Col>
          </Row>
              <Row>
                <Col sm="6">
                  <ul className="list-unstyled">
                    <li
                      className="event-list mb-0 pb-2"
                      style={{ cursor: "pointer" }}
                    >
                      <Media>
                        <Media body>
                          <div className="mt-1">
                            <span className="badge badge-pill badge-soft-success font-size-13">
                              Boys ({boys})
                            </span>
                          </div>
                        </Media>
                      </Media>
                    </li>
                    <li
                      className="event-list mb-0 pb-2"
                      style={{ cursor: "pointer" }}
                    >

                    </li>
                    <li className="event-list" style={{ cursor: "pointer" }}>
                      <Media>
                        <Media body>
                          <div className="mt-1">
                            <span className="badge badge-pill badge-soft-danger font-size-13">
                              Girls ({girls})
                            </span>
                          </div>
                        </Media>
                      </Media>
                    </li>
                  </ul>
                </Col>
                <Col sm="6">
                  <div className="mt-4 mt-sm-0">
                    <ReactApexChart
                      options={genderOptions}
                      series={genderSeries}
                      type="donut"
                      height={130}
                    />
                  </div>
                </Col>

              </Row>
            </CardBody>
          </Card>
        </Col>
        </Row>
        <Row>
        <Col>
          <Card >
            <CardBody>
              <CardTitle className="mb-4">
                Number of app downloads
              </CardTitle>
              <Row>
                <Col sm="6">
                  <ul className="list-unstyled">
                    <li
                      className="event-list mb-0 pb-2"
                      style={{ cursor: "pointer" }}
                    >

                      <Media>
                        <Media body>
                          <div className="mt-1">
                            <span className="badge badge-pill badge-soft-success font-size-13">
                              Downloaded ({download})
                            </span>
                          </div>
                        </Media>
                      </Media>
                    </li>
                    <li
                      className="event-list mb-0 pb-2"
                      style={{ cursor: "pointer" }}
                    >
                      <Media>
                        <Media body>
                          <div className="mt-1">
                            <span className="badge badge-pill badge-soft-danger font-size-13">
                              Not downloaded ({notDownload})
                            </span>
                          </div>
                        </Media>
                      </Media>

                    </li>

                    <li className="event-list" style={{ cursor: "pointer" }}>
                    </li>
                  </ul>
                </Col>
                <Col sm="6">
                  <div className="mt-4 mt-sm-0">
                    <ReactApexChart
                      options={downloadOptions}
                      series={downloadSeries}
                      type="donut"
                      height={130}  
                    />
                  </div>
                </Col>
              </Row>
            </CardBody>
          </Card>
        </Col>
      </Row>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {

  return {};
};

export default connect(mapStatetoProps, {
})(withRouter(withNamespaces()(SchoolStats)));
