import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Table, Col, Row, Progress, Button, UncontrolledTooltip, Label, FormGroup } from "reactstrap";
import { withNamespaces } from "react-i18next";
import Editable from "react-bootstrap-editable";
import UpdatePhoto from '../../components/Common/UpdatePhoto';
import Switch from "react-switch";
import Select from "react-select";
import Parse from 'parse';

import { editTeacher } from '../../store/actions';



const OtherDetails = (props) => {
    
    const [attachmentAdhar, setAttachmentAdhar] = useState(null);
    const [attachmentAdharUploadProgress, setAttachmentAdharUploadProgress] = useState(0);
    const [attachmentPan, setAttachmentPan] = useState(null);
    const [attachmentPanUploadProgress, setAttachmentPanUploadProgress] = useState(0);


    const [isBloodGroupEditOn, setIsBloodGroupEditOn] = useState(false);
    const [bloodGroup, setBloodGroup] = useState(undefined);

    const [isMaritualStatusEditOn, setIsMaritualStatusEditOn] = useState(false);
    const [maritualStatus, setMaritualStatus] = useState();
    const [pan, setPan] = useState();
    const [adhar, setAdhar] = useState();
    const [address, setAddress] = useState();
    const [nationality, setNationality] = useState();
    const [religion, setReligion] = useState();
    const [father, setFather] = useState();
    const [spouseName, setSpouseName] = useState()

    const [bankAccount, setBankAccount] = useState({})
    const [acNumber, setAcNumber] = useState()
    const [asPerName, setAsPerName] = useState()
    const [branchName, setBranchName] = useState()
    const [bankName, setBankName] = useState()
    const [ifsc, setIfsc] = useState()

    const maritualStats = [
        { value: "Married", label: "Married" },
        { value: "Single", label: "Single" },
    ];

    useEffect(() => {

        if (props.selectedTeacher) {
            setAddress(props.selectedTeacher.attributes.address);
            setNationality(props.selectedTeacher.attributes.nationality);
            setReligion(props.selectedTeacher.attributes.religion);
            setAdhar(props.selectedTeacher.attributes.aadharNumber);
            setPan(props.selectedTeacher.attributes.pan);
            setFather(props.selectedTeacher.attributes.fatherName);
            {props.selectedTeacher.attributes.maritalStatus &&  setMaritualStatus({value:props.selectedTeacher.attributes.maritalStatus,label:props.selectedTeacher.attributes.maritalStatus})}
            setSpouseName(props.selectedTeacher.attributes.spouseName);
            if (props.selectedTeacher.attributes.bankAccount) {
                let ba = props.selectedTeacher.attributes.bankAccount;
                setBankAccount(ba)
                setAcNumber(ba.accountNumber);
                setAsPerName(ba.nameAsPerBank);
                setBankName(ba.bankName);
                setIfsc(ba.IFSC);
                setBranchName(ba.branchName)
            }
            if (props.selectedTeacher.attributes.panDocument) {
                let fileExtension = props.selectedTeacher.attributes.panDocument._url.split('.').pop().toLowerCase();
                setAttachmentPan({
                    actualFileName: "pan_card."+fileExtension,
                    fileURL: props.selectedTeacher.attributes.panDocument._url
                });
            }
            if (props.selectedTeacher.attributes.aadharDocument) {

                let fileExtension = props.selectedTeacher.attributes.aadharDocument._url.split('.').pop().toLowerCase();
                setAttachmentAdhar({
                    actualFileName: "aadhar_card."+fileExtension,
                    fileURL: props.selectedTeacher.attributes.aadharDocument._url
                });
            }
        }
        
    }, [props.selectedTeacher]);


    const handleChange = (type, value) => {
        var temp = props.selectedTeacher;

        if (type === 1) {
            temp.set("address", value);
            setAddress(value);
        } else if (type === 2) {
            temp.set("nationality", value);
            setNationality(value);
        } else if (type === 3) {
            temp.set("religion", value);
            setReligion(value);
        } else if (type === 4) {
            temp.set("aadharNumber", value);
            setAdhar(value);
        }
        else if (type === 5) {
            temp.set("pan", value);
            setPan(value);
        }
        else if (type === 6) {
            temp.set("fatherName", value);
            setFather(value);
        }
        else if (type === 7) {
            temp.set("spouseName", value);
            setSpouseName(value);
        }
        else if (type === 8) {
            let obj = {...bankAccount, accountNumber: value }
            temp.set("bankAccount", obj);
            setBankAccount(obj)
            setAcNumber(value);
        }
        else if (type === 9) {
            let obj = {...bankAccount, nameAsPerBank: value }
            temp.set("bankAccount", {...bankAccount, nameAsPerBank: value });
            setBankAccount(obj)
            setAsPerName(value);
        }
        else if (type === 10) {
            let obj = {...bankAccount, bankName: value }
            temp.set("bankAccount", {...bankAccount, bankName: value });
            setBankAccount(obj)
            setBankName(value);
        }
        else if (type === 11) {
            let obj = {...bankAccount, IFSC: value }
            temp.set("bankAccount", {...bankAccount, IFSC: value });
            setBankAccount(obj)
            setIfsc(value);
        }
        else if (type === 12) {
            let obj = {...bankAccount, branchName: value }
            temp.set("bankAccount", {...bankAccount, branchName: value });
            setBankAccount(obj)
            setBranchName(value);
        }
        
        props.editTeacher(temp);
    }

    const handleMaritualStatusChange = () => {
        
        setIsMaritualStatusEditOn(false);
        var temp = props.selectedTeacher;
        temp.set("maritalStatus", maritualStatus.value);
        props.editTeacher(temp);
    }

    // useEffect(() => {
    //     if (props.selectedSchool && props.selectedSchool.id === props.schoolId) {
    //         setAdmissionNumberFormat(props.selectedSchool.attributes.admissionNumberFormat);
    //         setAdmissionRunningNumber(props.selectedSchool.attributes.admissionRunningNumber);

    //         setEmployeeCodeFormat(props.selectedSchool.attributes.employeeCodeFormat);
    //         setEmployeeCodeRunningNumber(props.selectedSchool.attributes.employeeCodeRunningNumber);

    //         setGst(props.selectedSchool.attributes.GSTIN);
    //         setPan(props.selectedSchool.attributes.pan);
    //         setCin(props.selectedSchool.attributes.cin);
    //         setFooter(props.selectedSchool.attributes.invoiceFooter)
    //         setResetInvoiceNumberForAY(props.selectedSchool.attributes.resetInvoiceNumberForAY ? true : false)
    //         if (props.selectedSchool.attributes.letterheadLogo) {
    //             setLetterHeadLogo({ imageURL: props.selectedSchool.attributes.letterheadLogo._url, actualImageName: "logo.jpeg" })
    //         }
    //     }
    // }, [props.selectedSchool, props.schoolId]);



    function handleAdharImageUploadChange(imageFile) {
        setAttachmentAdharUploadProgress(1); 

        let fileExtension = imageFile.name.split('.').pop().toLowerCase();

        let parseFile = new Parse.File("aadhar_card."+fileExtension, imageFile);

        parseFile.save({
            progress: (progress) => {
                console.log("progress:",progress)
                setAttachmentAdharUploadProgress(progress.percent);
            }
        }).then(
            (result) => {
                if (props.selectedTeacher) {
                    props.selectedTeacher.set('aadharDocument', result);
                    props.selectedTeacher.save().then(
                        (teacherResult) => {
                            setAttachmentAdhar({
                                actualFileName: "aadhar_card."+fileExtension,
                                fileURL: result.url()
                            });
                            setAttachmentAdharUploadProgress(100);
                        },
                        (error) => {
                            console.error('Error saving teacher:', error);
                        }
                    );
                }
            },
            (error) => {
                console.error('Error saving file:', error);
                setAttachmentAdharUploadProgress(0); 
            }
        );
    }

    function handlePanImageUploadChange(imageFile) {
        setAttachmentPanUploadProgress(1);

        let fileExtension = imageFile.name.split('.').pop().toLowerCase();

        let parseFile = new Parse.File("pan_card."+fileExtension, imageFile);

        parseFile.save({
            progress: (progress) => {
                setAttachmentPanUploadProgress(progress.percent);
            }
        }).then(
            (result) => {
                if (props.selectedTeacher) {
                    props.selectedTeacher.set('panDocument', result);
                    props.selectedTeacher.save().then(
                        (teacherResult) => {
                            setAttachmentPan({
                                actualFileName: "pan_card."+fileExtension,
                                fileURL: result.url()
                            });
                            setAttachmentPanUploadProgress(100);
                        },
                        (error) => {
                            console.error('Error saving teacher:', error);
                        }
                    );
                }
            },
            (error) => {
                console.error('Error saving file:', error);
                setAttachmentPanUploadProgress(0);
            }
        );
    }

    function handleClearAdhar() {
        var temp = props.selectedTeacher;
        temp.unSet("aadharDocument")
        props.editTeacher(temp);
        setAttachmentAdhar(null);
        setAttachmentAdharUploadProgress(0);
    }

    function handleClearPan() {
        var temp = props.selectedTeacher;
        temp.unset("panDocument")
        props.editTeacher(temp);
        setAttachmentPan(null);
        setAttachmentPanUploadProgress(0);
    }



    return (
      <React.Fragment>
        <h5 className="mt-3">Details</h5>
        <div className="table-responsive m-2">
          <Table className="table mb-0 table-bordered">
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Address
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(1, value);
                    }}
                    disabled={false}
                    editText={address ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={address}
                    showText
                    type="textarea"
                    rows="3"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Nationality
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(2, value);
                    }}
                    disabled={false}
                    editText={nationality ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={nationality}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Religion
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(3, value);
                    }}
                    disabled={false}
                    editText={religion ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={religion}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Aadhar Number
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(4, value);
                    }}
                    disabled={false}
                    editText={adhar ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={adhar}
                    validate={(value) => {
                      if (isNaN(value)) {
                        return props.t("Please enter a number");
                      }
                    }}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  PAN
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(5, value);
                    }}
                    disabled={false}
                    editText={pan ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={pan}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Father Name
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(6, value);
                    }}
                    disabled={false}
                    editText={father ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={father}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row">{props.t("Maritual Status")}</th>
                {!isMaritualStatusEditOn && (
                  <td>
                    {maritualStatus && maritualStatus.value}
                    <Label
                      onClick={() => {
                        setIsMaritualStatusEditOn(true);
                      }}
                    >
                      <p className="font-size-13 text-primary cursors move">
                        {maritualStatus && maritualStatus.value
                          ? " ✎"
                          : "No value ✎"}
                      </p>
                    </Label>
                  </td>
                )}
                {isMaritualStatusEditOn && (
                  <td>
                    <Label>{props.t("Maritual Status")}:</Label>
                    <FormGroup>
                      <Select
                        value={maritualStatus}
                        onChange={(v) => {
                          setMaritualStatus(v);
                        }}
                        options={maritualStats}
                        classNamePrefix="select2-selection"
                      />
                    </FormGroup>
                    <button
                      type="button"
                      className="btn btn-success btn-sm "
                      style={{ width: "34px" }}
                      onClick={handleMaritualStatusChange}
                    >
                      <i className="fa fa-check"></i>
                    </button>
                    <button
                      type="button"
                      className="btn btn-danger btn-sm ml-2"
                      style={{ width: "34px" }}
                      onClick={() => {
                        let teacher = props.selectedTeacher;
                        if (teacher.attributes.maritalStatus) {
                          setMaritualStatus({
                            label: teacher.attributes.maritalStatus,
                            value: teacher.attributes.maritalStatus,
                          });
                        } else {
                          setMaritualStatus(undefined);
                        }
                        setIsMaritualStatusEditOn(false);
                      }}
                    >
                      <i className="fa fa-times"></i>
                    </button>
                  </td>
                )}
              </tr>
            </tbody>
            {maritualStatus &&
              maritualStatus.value &&
              maritualStatus.value == "Married" && (
                <tbody>
                  <tr>
                    <th scope="row" style={{ width: "300px" }}>
                      Spouse Name
                    </th>
                    <td>
                      <Editable
                        className={"d-flex"}
                        alwaysEditing={false}
                        onSubmit={(value) => {
                          handleChange(7, value);
                        }}
                        disabled={false}
                        editText={spouseName ? " ✎" : "No value ✎"}
                        id={null}
                        isValueClickable={false}
                        mode="inline"
                        placement="top"
                        initialValue={spouseName}
                        showText
                        type="textfield"
                      />
                    </td>
                  </tr>
                </tbody>
              )}
          </Table>
        </div>
        <hr />
        <h5 className="mt-3">Employee Bank Details</h5>
        <div className="table-responsive m-2">
          <Table className="table mb-0 table-bordered">
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Account Number
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(8, value);
                    }}
                    disabled={false}
                    editText={acNumber ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={acNumber}
                    validate={(value) => {
                      if (isNaN(value)) {
                        return props.t("Please enter a Account Number");
                      }
                    }}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Name as per bank
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(9, value);
                    }}
                    disabled={false}
                    editText={asPerName ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={asPerName}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Bank Name
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(10, value);
                    }}
                    disabled={false}
                    editText={bankName ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={bankName}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  IFSC
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(11, value);
                    }}
                    disabled={false}
                    editText={ifsc ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={ifsc}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Branch Name
                </th>
                <td>
                  <Editable
                    className={"d-flex"}
                    alwaysEditing={false}
                    onSubmit={(value) => {
                      handleChange(12, value);
                    }}
                    disabled={false}
                    editText={branchName ? " ✎" : "No value ✎"}
                    id={null}
                    isValueClickable={false}
                    mode="inline"
                    placement="top"
                    initialValue={branchName}
                    showText
                    type="textfield"
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
        <hr />
        <h5 className="mt-3">Documents</h5>
        <div className="table-responsive m-2">
          <Table className="table mb-0 table-bordered">
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  Aadhar Card
                </th>
                <td>
                  {attachmentAdhar && (
                    <Col xl="12" md="12" sm="12" xs="12">
                      <div className="border p-3">
                                            <Row>
                                                <Col xl="2" md="2" sm="2" xs="2">
                                                    <div
                                                        className="avatar ml-3"
                                                        style={{ width: "5rem", height: "5rem" }}
                                                    >
                                                        <span
                                                        className="avatar-title bg-white ml-0 text-primary h1"
                                                        style={{ fontSize: "60px" }}
                                                        >
                                                        <i className="fa fa-file"></i>
                                                        </span>
                                                    </div>
                                                </Col>

                                                <Col xl="10" md="10" sm="10" xs="10">
                                                    <div className="float-right ml-4">
                                                        <div
                                                        className="text-muted font-weight-bold text-truncate"
                                                        style={{ width: "200px" }}
                                                        >
                                                        {attachmentAdhar.actualFileName}
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                        <Button
                                                            className="btn btn-primary float-right m-1"
                                                            color="primary"
                                                            target="_blank"
                                                            rel="noopener noreferrer"
                                                            onClick={() => {
                                                            window.open(
                                                                attachmentAdhar.fileURL,
                                                                "_blank"
                                                            );
                                                            }}
                                                        >
                                                            Open
                                                        </Button>
                                                        <Button
                                                            className="btn btn-danger float-right m-1"
                                                            color="danger"
                                                            onClick={handleClearAdhar}
                                                        >
                                                            Clear
                                                            </Button>
                                                            <Label
                                                                htmlFor="input-file-adhar-r"
                                                                className="btn btn-primary m-1"
                                                                >
                                                                Update Adhar Card
                                                                <input
                                                                    className="btn btn-primary"
                                                                    id="input-file-adhar-r"
                                                                    style={{ display: "none" }}
                                                                    type="file"
                                                                    onChange={(e) => {
                                                                    handleAdharImageUploadChange(e.target.files[0]);
                                                                    }}
                                                                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                                                                />
                                                                </Label>
                                                        </div>
                                                    </div>

                                                </Col>
                         

                          
                        </Row>
                      </div>
                    </Col>
                  )}

                  {attachmentAdharUploadProgress > 0 &&
                    attachmentAdharUploadProgress < 100 && (
                      <div>Uploading... {attachmentAdharUploadProgress}%</div>
                    )}

                  {(!attachmentAdhar)&& (
                    <Label
                      htmlFor="input-file-adhar"
                      className="btn btn-primary btn-sm"
                    >
                      Upload Adhar Card
                      <input
                        className="btn btn-primary"
                        id="input-file-adhar"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => {
                          handleAdharImageUploadChange(e.target.files[0]);
                        }}
                        accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                      />
                    </Label>
                  )}
                </td>
              </tr>
            </tbody>
            <tbody>
              <tr>
                <th scope="row" style={{ width: "300px" }}>
                  PAN
                </th>
                <td>
                  {attachmentPan && (
                    <Col xl="12" md="12" sm="12" xs="12">
                      <div className="border p-3">
                            <Row>
                                <Col xl="2" md="2" sm="2" xs="2">
                                    <div
                                        className="avatar ml-3"
                                        style={{ width: "5rem", height: "5rem" }}
                                    >
                                        <span
                                        className="avatar-title bg-white ml-0 text-primary h1"
                                        style={{ fontSize: "60px" }}
                                        >
                                        <i className="fa fa-file"></i>
                                        </span>
                                    </div>
                                
                                </Col>

                                <Col xl="10" md="10" sm="10" xs="10">
                                    <div className="float-right ml-4">
                                        <div
                                        className="text-muted font-weight-bold text-truncate"
                                        style={{ width: "200px" }}
                                        >
                                        {attachmentPan.actualFileName}
                                        </div>
                                        <div className="d-flex mt-3">
                                        <Button
                                            className="btn btn-primary float-right m-1"
                                            color="primary"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => {
                                            window.open(attachmentPan.fileURL, "_blank");
                                            }}
                                        >
                                            Open
                                        </Button>
                                        <Button
                                            className="btn btn-danger float-right m-1"
                                            color="danger"
                                            onClick={handleClearPan}
                                        >
                                            Clear
                                        </Button>
                                                            
                                        <Label
                                            htmlFor="input-file-pan-r"
                                            className="btn btn-primary m-1"
                                            >
                                            Update PAN Card
                                            <input
                                                id="input-file-pan-r"
                                                style={{ display: "none" }}
                                                type="file"
                                                onChange={(e) => {
                                                handlePanImageUploadChange(e.target.files[0]);
                                                }}
                                                accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                                            />
                                            </Label>
                                        </div>
                                    </div>
                                
                                </Col>
                            </Row>
                      </div>
                    </Col>
                  )}

                  {attachmentPanUploadProgress > 0 &&
                    attachmentPanUploadProgress < 100 && (
                      <div>Uploading... {attachmentPanUploadProgress}%</div>
                    )}

                  {(!attachmentPan ) && (
                    <Label
                      htmlFor="input-file-pan"
                      className="btn btn-primary btn-sm"
                    >
                      Upload PAN Card
                      <input
                        id="input-file-pan"
                        style={{ display: "none" }}
                        type="file"
                        onChange={(e) => {
                          handlePanImageUploadChange(e.target.files[0]);
                        }}
                        accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                      />
                    </Label>
                  )}
                </td>
              </tr>
            </tbody>
          </Table>
        </div>
      </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const {selectedTeacher, } = state.Teacher;
    return { selectedSchool,selectedTeacher };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    editTeacher
})(OtherDetails)));