import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  Form,
  Button,
  CardBody,
  UncontrolledTooltip
} from "reactstrap";
import { Parse } from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCamerasForSchoolId, saveCamera, deleteCamera, setSelecetdSchool, getCameraLiveStreamsForSchoolId } from "../../store/actions";
import * as Constants from "../../Constents";

const CameraDetailPage = (props) => {

  const [displayList, setDisplayList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [cameraToDelete, setCameraToDelete] = useState([]);

  const [isLoading, setIsLoading] = useState(true);

  const match = matchPath(props.history.location.pathname, {
    path: "/cameradetail/:id",
    exact: true,
    strict: false,
  });

  const schoolId = match.params.id;
  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool]);

  useEffect(() => {
    props.getCamerasForSchoolId(schoolId);
    props.getCameraLiveStreamsForSchoolId(schoolId);
  }, [schoolId]);

  useEffect(() => {
    if (props.cameras) {
      var temp = [...props.cameras];
      setDisplayList(temp);
      setIsLoading(false);
    }
  }, [props.cameras]);

  useEffect(() => {
    if (props.resultCameraAfterSave) {
      setIsLoading(false);
    }
  }, [props.resultCameraAfterSave]);

  const handleDeleteCamera = () => {
    var camera = cameraToDelete;
    camera.set("deleted", true);
    if (Parse.User.current().attributes.teacherId) {
      camera.set("deletedBy", Parse.User.current().attributes.teacherId);
    } else {
      camera.set("deletedBy", Parse.User.current().id);
    }

    props.deleteCamera(camera, props.t('CCTV deleted successfully..!'));
    setShowDeleteAlert(false);
  };

  const getDVRName = (hls, htmlUrl) => {
    if (props.cameraLiveStreams) {
      for (const cls of props.cameraLiveStreams) {
        if ((hls && cls.attributes.hls === hls) || (htmlUrl && cls.attributes.htmlUrl === htmlUrl)) {
          return cls.attributes.name;
        }
      }
    }
    return "--"
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading && (
          <Container fluid>
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div className=' mb-3'>
                  <h4 className="m-0">CCTV</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end mb-2" style={{ marginTop: '30px' }}>
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to={"/addCamera/" + schoolId}
                    role="button"
                  >
                    Add CCTV
                  </Link>
                </div>
              </Col>
            </Row>
            <Card>
              {showDeleteAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowDeleteAlert(false);
                  }}
                  onConfirm={() => handleDeleteCamera()}
                >
                  Are you sure to delete this camera?<br></br>
                </SweetAlert>
              )}

              {displayList.length === 0 && (
                <p className="text-center">No CCTV to display !!</p>
              )}
              <CardBody>
                {displayList.length > 0 && (
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>{props.t("Name")}</th>
                          <th>{props.t("DVR/NVR Camera Name")}</th>
                          <th style={{ width: "20px" }}>{props.t('View Details')}</th>
                          <th style={{ width: "20px" }}>{props.t("Edit")}</th>
                          <th style={{ width: "20px" }}>{props.t("Delete")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayList.map((camera, key) => (
                          <tr key={key}>
                            <td>
                              <Label className="font-size-12 mb-1">
                                <Link
                                  to={
                                    "/cameraview/" +
                                    schoolId +
                                    "/" +
                                    camera.id
                                  }
                                  className="text-dark"
                                >
                                  {" "}
                                  {camera.attributes.name}
                                </Link>
                              </Label><br />
                              {camera.attributes.isDisabled && <small className="text-secondary "
                                id={"kidDisable"}>
                                (Disabled)
                                <UncontrolledTooltip placement="right" target={"kidDisable"}>
                                  Parental access to the camera is currently disabled. To re-enable it, please navigate to the camera details section.
                                </UncontrolledTooltip>
                              </small>}
                            </td>
                            <td>
                              {getDVRName(camera.attributes.hlsURL, camera.attributes.htmlUrl)}
                            </td>
                            <td>
                              <Link to={
                                "/cameraview/" +
                                schoolId +
                                "/" +
                                camera.id
                              }>
                                <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">{props.t('View Details')}</Button>
                              </Link>

                            </td>

                            <td>
                              <div className="flex-fill text-center ">
                                <Link
                                  to={
                                    "/editcamera/" +
                                    schoolId +
                                    "/" +
                                    camera.id
                                  }
                                  id={"edit-cctv" + key}
                                >
                                  <i className="bx bx-edit-alt"></i>
                                </Link>
                              </div>
                            </td>

                            <td>
                              <div className="flex-fill text-center">
                                <Label
                                  onClick={() => {
                                    setCameraToDelete(camera);
                                    setShowDeleteAlert(true);
                                  }}
                                >
                                  <i className="bx bxs-trash text-danger"></i>
                                </Label>
                              </div>
                            </td>

                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}

              </CardBody>
            </Card>
          </Container>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading CCTV details...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { cameras, resultCameraAfterSave, cameraLiveStreams } = state.Camera;
  const { selectedSchool } = state.School;
  return { cameras, resultCameraAfterSave, selectedSchool, cameraLiveStreams };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, { getCamerasForSchoolId, saveCamera, deleteCamera, setSelecetdSchool, getCameraLiveStreamsForSchoolId })(
      CameraDetailPage
    )
  )
);
