import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Table,
    CardBody,
    Card,
} from "reactstrap";
import {
    setSelecetdSchool,
    editSchool,
    
} from "../../store/actions";
import { withRouter, Link } from "react-router-dom";
import Switch from "react-switch";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Parse from "parse";
//components
import Breadcrumbs from "../../components/Common/Breadcrumb";

const FranchiseSettings = (props) => {

    const controls = ["invoice", "template", "discount", "temp"]
    const displayNames = ["Invoice", "Template", "Discount", "Temp"]

    var match = matchPath(props.history.location.pathname, {
        path: "/franchise-settings",
        exact: true,
        strict: false,
    });

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };

    const [isLoading, setIsLoading] = useState(false);
    const [paymentControls, setPaymentControls] = useState([]);

    

    useEffect(() => {
        if (props.schools && props.schools.length > 0) {
            let paymentList = []
            for (var i = 0; i < props.schools.length; i++) {
                let paymentControlObject = {}
                for (const j in controls) {
                    paymentControlObject[controls[j]] = 0
                    if (props.schools[i].attributes.franchiseControls && props.schools[i].attributes.franchiseControls.length > 0) {
                        paymentControlObject[controls[j]] = props.schools[i].attributes.franchiseControls[j] ?? 0
                    }
                }
                paymentControlObject.schoolObject = props.schools[i]
                paymentList.push(paymentControlObject)
            }
            setPaymentControls(paymentList)
        }
    }, [props.schools])


    const handleSchoolEdit = (schoolObject) => {
        props.editSchool(schoolObject);
    };

    const handleSettingChange = (item, type) => {
        let settingsList = item.schoolObject.attributes.franchiseControls ? [...item.schoolObject.attributes.franchiseControls] : undefined;
        let indexNumber = type
        let value = item[controls[type]] === 1 ? 0 : 1
        if (settingsList == null) {
            settingsList = []
        }
        if (settingsList.length > indexNumber) {
            settingsList[indexNumber] = value;
        } else {
            //Manage if length is less than indexNumber
            var i = settingsList.length;
            for (i = settingsList.length; i < indexNumber + 1; i++) {
                if (i === indexNumber) {
                    settingsList[i] = parseInt(value, 10);
                } else {
                    settingsList.push(0);
                }
            }
        }
        item.schoolObject.set("franchiseControls", settingsList)
        let index = paymentControls.indexOf(item)

        if (index > -1) {
            let tempList = [...paymentControls]
            for (const i in controls) {
                if (type == i) {
                    item[controls[i]] = value
                }
            }
            tempList[index] = item
            setPaymentControls(tempList)
        }
        handleSchoolEdit(item.schoolObject)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="navbar-header p-0">
                        <div className="d-flex">
                            {
                                <div className="navbar-header p-0">
                                    <Breadcrumbs
                                        title="Franchise Settings"
                                        breadcrumbItem="Franchise Settings"
                                    />
                                </div>
                            }
                        </div>
                    </Row>

                    {!isLoading ? (
                        <Card><CardBody>
                            <div className="ml-2 mb-2"><h5>Payment Controls</h5></div>
                            <Table className="table-centered table-nowrap table-hover mt-3">
                                <thead className="thead-light" style={{ height: "0" }}>
                                    <tr>
                                        <th scope="col" style={{ width: "450px" }}>
                                            {props.t("Schools")}
                                        </th>

                                        {
                                            displayNames.map((name, key) => (
                                                <th key={key} scope="col">{props.t(name)}</th>
                                            ))
                                        }
                                    </tr>
                                </thead>
                                <tbody>
                                    {paymentControls.map((item, i) => (
                                        <tr key={"_user_" + i}>
                                            <td>{item.schoolObject.attributes.Name}</td>
                                            {
                                                controls.map((control, key) => (
                                                    <td key={key}>
                                                        <Switch
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            onColor="#626ed4"
                                                            onChange={() => {
                                                                handleSettingChange(item, key)
                                                            }}
                                                            checked={item[control] === 1 ? true : false}
                                                        />
                                                    </td>
                                                ))
                                            }
                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </CardBody>
                        </Card>
                    ) : (
                        <Row>
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success">
                                        <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                                        {props.t("Loading")}{" "}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    )}
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { schools, selectedSchool } = state.School;
    return {
        schools,
        selectedSchool
    };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            editSchool,
        })(FranchiseSettings)
    )
);
