import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, CardBody, Card, FormGroup, Label, Table, Button, UncontrolledTooltip } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Moment from 'moment';
import Lightbox from "react-image-lightbox";

import {
    setSelecetdSchool, setSelectedLearingSession, getLearningActivitiesForIds, getLearningCategoriesForIds, getAllKids, setSelectedOnlineLearingSession,
    getTeachersForSchool, getAlbumsForAlbumIds, getOnlineLearningTrackForSessionId, getLearningTrackingForSessionId, getClassroomsForSchoolId
} from '../../../store/actions';


const LearningSessionDetails = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/learning-session-details/:schoolId/:type/:sessionId",
        exact: true,
        strict: false,
    });


    const schoolId = match && match.params.schoolId;
    const sessionId = match && match.params.sessionId;
    const type = match && match.params.type;

    const [loading, setLoading] = useState(false);
    const [isShowGallery, setIsShowGallery] = useState(false);
    const [photoIndex, setphotoIndex] = useState(0);

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        setLoading(true);
        if (type == "assesment") {
            props.setSelectedLearingSession(sessionId);
            props.getLearningTrackingForSessionId(sessionId);
        } else {
            props.setSelectedOnlineLearingSession(sessionId);
            props.getOnlineLearningTrackForSessionId(sessionId);

        }
        props.getAllKids(schoolId);
        props.getTeachersForSchool(schoolId);
        props.getClassroomsForSchoolId(schoolId);
        setLoading(false)
    }, [sessionId, schoolId])

    useEffect(() => {
        const processSession = (session) => {
            if (session) {
                props.getLearningActivitiesForIds([session.attributes.activityId]);
                props.getLearningCategoriesForIds([session.attributes.categoryId]);
                if (session.attributes.albumId > 0) {
                    props.getAlbumsForAlbumIds([session.attributes.albumId])
                }
            }
        };

        processSession(props.selectedLearingSession);
        processSession(props.selectedOnlineLearningSession);
    }, [props.selectedLearingSession, props.selectedOnlineLearningSession]);




    const getActivityName = (activityId) => {
        if (props.learningActivities && props.learningActivities.length > 0) {
            let temp = props.learningActivities.filter(
                (activity) => activity.id === activityId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.name ?? "-")
            }
        }
    }

    const getCategoryName = (categoryId) => {
        if (props.learningCategories && props.learningCategories.length > 0) {
            let temp = props.learningCategories.filter(
                (category) => category.id === categoryId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.name ?? "-")
            }
        }
    }


    const getClassRoomName = (classroomId) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === classroomId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name + " " ?? "-")
            }
        }
    }

    const kidDetails = (kidId) => {
        if (props.kids && props.kids.length > 0) {
            for (const kid of props.kids) {
                if (kid.id == kidId) {
                    return {
                        kid: kid,
                        kidName: kid.attributes.Name,
                        classRoom: getClassRoomName(kid.attributes.ClassRoomID)
                    }
                }
            }
        }
    }



    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getAlbumsForAlbumId = (albumId) => {
        if (props.albums && props.albums.length > 0) {
            let albums = props.albums.filter((album) =>
                album.attributes.albumId === albumId
            )
            if (albums.length > 0) {
                return albums
            }
        }
        return undefined
    }

    const getLightBoxImagesUI = (albumId) => {
        var albums = getAlbumsForAlbumId(albumId)
        var images = []
        if (albums && albums.length > 0) {
            images = []
            for (const album of albums) {
                let image = album.attributes.image ? album.attributes.image._url : ""
                images.push(image)
            }
        }
        return (<Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={
                images[(photoIndex + images.length - 1) % images.length]
            }
            enableZoom={true}
            onCloseRequest={() => { setIsShowGallery(false) }}
            onMovePrevRequest={() => { setphotoIndex((photoIndex + images.length - 1) % images.length) }}
            onMoveNextRequest={() => { setphotoIndex((photoIndex + 1) % images.length) }}
            imageCaption={"Image " + parseFloat(photoIndex + 1)}
        />)
    }

    function getOnlineTracking() {
        return (
            <Row>
                <Col>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Class Name</th>
                                <th>Status</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.onlineLearningTracking.map((onlineLearning, key) => {
                                const details = kidDetails(onlineLearning.attributes.kidId);

                                return (
                                    <tr key={key}>
                                        <td style={{ width: "200px" }}>
                                            {details ? details.kidName : ''}
                                        </td>
                                        <td style={{ width: "200px" }}>
                                            {details ? details.classRoom : ""}
                                        </td>
                                        <td>
                                            {onlineLearning.attributes.rating}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>
        );
    }

    const getLearningTracking = () => {
        return (
            <Row>
                <Col>
                    <table className="table table-bordered">
                        <thead>
                            <tr>
                                <th>Name</th>
                                <th>Class Name</th>
                                <th>Rating</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.selectedLearingSession && props.selectedLearingSession.attributes.recipients.map((kidId, key) => {
                                const details = leaningTrackingDetails(kidId);

                                return (
                                    <tr key={key}>
                                        <td style={{ width: "200px" }}>
                                            {details ? details.kidName : ''}
                                        </td>
                                        <td style={{ width: "200px" }}>
                                            {details ? details.classRoom : ""}
                                        </td>
                                        <td>
                                            {(details && details.rating) ? details.rating.attributes.rating : "--"}
                                        </td>
                                    </tr>
                                );
                            })}
                        </tbody>
                    </table>
                </Col>
            </Row>
        );
    }

    const leaningTrackingDetails = (id) => {


        if (props.kids && props.kids.length > 0) {
            for (const kid of props.kids) {
                if (kid.id == id) {


                    return {
                        kid: kid,
                        kidName: kid.attributes.Name,
                        classRoom: getClassRoomName(kid.attributes.ClassRoomID),
                        rating: getStudentRating(kid.id)
                    }

                }
            }
        }


    }
    console.log(props.learningTracking)
    const getStudentRating = (studentId) => {

        if (props.learningTracking && props.learningTracking.length > 0) {
            for (const lt of props.learningTracking) {
                if (lt.attributes.kidId == studentId) {
                    return lt
                }
            }
        }

        return undefined;
    };



    const onlineLearningRatingCount = () => {
        if (props.onlineLearningTracking && props.onlineLearningTracking.length > 0) {
            const statusCounts = {
                Published: 0,
                Progress: 0,
                Completed: 0
            };

            for (const onlineLearningTrack of props.onlineLearningTracking) {
                const status = onlineLearningTrack.attributes.rating;

                if (statusCounts.hasOwnProperty(status)) {
                    statusCounts[status]++;
                }
            }

            return statusCounts;
        }

        return {};
    }

    function OnlineLearningStatusCounts() {
        const statusCounts = onlineLearningRatingCount();

        return (
            <table className="table table-bordered">
                <thead>
                    <tr>
                        <th style={{ width: "20px" }}>Published</th>
                        <th style={{ width: "20px" }}>Progress</th>
                        <th style={{ width: "20px" }}>Completed</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{statusCounts.Published}</td>
                        <td>{statusCounts.Progress}</td>
                        <td>{statusCounts.Completed}</td>
                    </tr>
                </tbody>
            </table>
        );
    }




    return (
        <div className='page-content'>
            {isShowGallery && isShowGallery.length > 0 ? (
                getLightBoxImagesUI(isShowGallery)
            ) : null}
            <Row className='d-flex align-items-start'>
                <Col className="d-flex align-items-start">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => { window.history.back() }}>
                        <ul className=" list-unstyled">
                            <div className=''>
                                <i className="bx bx-chevron-left h1 text-primary"></i>
                            </div>
                        </ul>
                    </div>
                    <div className=' m-2'>
                        <h4 className="m-0">Session Details</h4>
                    </div>
                </Col>
            </Row>
            <Row >
                <Col>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg='5'>
                                    <h6 className="mb-0 py-1">
                                        Subject: {type == "assesment" ?
                                            getCategoryName(props.selectedLearingSession && props.selectedLearingSession.attributes.categoryId) :
                                            getCategoryName(props.selectedOnlineLearningSession && props.selectedOnlineLearningSession.attributes.categoryId)
                                        }
                                    </h6>
                                    <h6 className='mb-0 py-1'>
                                        Topic: {type == "assesment" ?
                                            getActivityName(props.selectedLearingSession && props.selectedLearingSession.attributes.activityId) :
                                            getActivityName(props.selectedOnlineLearningSession && props.selectedOnlineLearningSession.attributes.activityId)
                                        }
                                    </h6>
                                    <h6 className="mb-0 py-1">
                                        Description: {type == "assesment" ?
                                            (props.selectedLearingSession && props.selectedLearingSession.attributes.description) :
                                            (props.selectedOnlineLearningSession && props.selectedOnlineLearningSession.attributes.description)
                                        }
                                    </h6>
                                    <h6 className="mb-0 py-1">
                                        Created By: {type == "assesment" ?
                                            getTeacherName(props.selectedLearingSession && props.selectedLearingSession.attributes.createdBy) :
                                            getTeacherName(props.selectedOnlineLearningSession && props.selectedOnlineLearningSession.attributes.createdBy)
                                        }
                                    </h6>
                                    <h6 className="mb-0 py-1">
                                        Date: {type == "assesment" ?
                                            Moment(props.selectedLearingSession && props.selectedLearingSession.attributes.createdAt).format('DD-MMM-YYYY') :
                                            Moment(props.selectedOnlineLearningSession && props.selectedOnlineLearningSession.attributes.createdAt).format('DD-MMM-YYYY')
                                        }
                                    </h6>
                                </Col>
                                <Col lg="4">
                                    {type == "homeLearning" && OnlineLearningStatusCounts()}
                                </Col>
                                <Col lg="2">
                                    {type === "assesment" ? (
                                        props.selectedLearingSession && props.selectedLearingSession.attributes.albumId && (
                                            <>
                                                <p
                                                    id={"msgAttachment"}
                                                    className=" float-right  py-4"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setIsShowGallery(props.selectedLearingSession.attributes.albumId);
                                                        setphotoIndex(0);
                                                    }}
                                                >
                                                    <u>Attachments</u>
                                                </p>
                                                <UncontrolledTooltip placement="top" target={"msgAttachment"}>
                                                    Attachments
                                                </UncontrolledTooltip>
                                            </>
                                        )
                                    ) : (
                                        props.selectedOnlineLearningSession && props.selectedOnlineLearningSession.attributes.albumId && (
                                            <>
                                                <p
                                                    id={"msgAttachment"}
                                                    className=" float-right  py-4"
                                                    style={{ cursor: 'pointer' }}
                                                    onClick={() => {
                                                        setIsShowGallery(props.selectedOnlineLearningSession.attributes.albumId);
                                                        setphotoIndex(0);
                                                    }}
                                                >
                                                    <u>View photos</u>
                                                </p>
                                                <UncontrolledTooltip placement="top" target={"msgAttachment"}>
                                                    Attachments
                                                </UncontrolledTooltip>
                                            </>
                                        )
                                    )}
                                </Col>

                            </Row>

                        </CardBody>

                    </Card>
                </Col>
            </Row>
            <Row>
                {loading && (
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Link to="#" className="text-success">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                Loading....{" "}
                            </Link>
                        </div>
                    </Col>
                )}
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <table className='table mb-0 table-bordereds'>
                                <tbody>

                                    {type == "assesment" && getLearningTracking()}
                                    {type == "homeLearning" && getOnlineTracking()}
                                </tbody>
                            </table>
                        </CardBody>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { curriculumAcademicCycles, loadState } = state.AcademicCycles;
    const { learningCategories } = state.LearningCategory;
    const { learningSubcategories, subcategoryState } = state.LearningSubcategory;
    const { learningActivities } = state.LearningActivity;
    const { teachers } = state.Teacher;
    const { userTeacher } = state.Login;
    const { curriculums } = state.Curriculum;
    const { learningSession, onlineLearningSession, selectedLearingSession, selectedOnlineLearningSession, learningTracking, onlineLearningTracking } = state.LearningSession;
    const { kids } = state.Kid;
    const { albums } = state.Message;
    const { classrooms } = state.Classroom;

    return { schools, selectedSchool, curriculumAcademicCycles, loadState, learningCategories, learningSubcategories, subcategoryState, learningActivities, learningTracking, onlineLearningTracking, teachers, classrooms, userTeacher, selectedLearingSession, selectedOnlineLearningSession, curriculums, learningSession, onlineLearningSession, kids, albums };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, setSelectedLearingSession, getLearningActivitiesForIds, getLearningCategoriesForIds, getAllKids, setSelectedOnlineLearingSession,
    getTeachersForSchool, getAlbumsForAlbumIds, getOnlineLearningTrackForSessionId, getLearningTrackingForSessionId, getClassroomsForSchoolId
})(LearningSessionDetails)));