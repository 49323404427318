import React, { useState, useEffect, useRef } from 'react';
import { Link } from "react-router-dom";
import { Modal, ModalBody, Container, Row, Spinner, Col, UncontrolledTooltip, Button, Media, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Input, Nav, NavItem, NavLink, TabContent, TabPane, Card, Form, FormGroup, InputGroup, InputGroupAddon, Label, CardBody } from "reactstrap";
import classnames from 'classnames';
import Iframe from "react-iframe";
import toastr from "toastr";
import Compressor from 'compressorjs';

import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import { v4 as uuidv4 } from "uuid";

//Import Scrollbar
import PerfectScrollbar from 'react-perfect-scrollbar';
import 'react-perfect-scrollbar/dist/css/styles.css';
import moment from 'moment';
import * as Constants from "../../Constents";
import Parse from 'parse';
import MessageStatusView from './MessageStatusView';

import {
    setSelecetdSchool,
    getClassroomsForSchoolId,
    getGroupsForSchoolId,
    directAssignMessages,
    getTeachersForSchool,


    getMessagesForClassId,
    getLatestMessagesForClassId,
    getOldMessagesForClassId,

    getMessagesForGroupId,
    getLatestMessagesForGroupId,
    getOldMessagesForGroupId,


    getMessagesForSchoolId,
    getLatestMessagesForSchoolId,
    getOldMessagesForSchoolId,

    getMessagesForAllTeachers,
    getLatestMessagesForAllTeachers,
    getOldMessagesForAllTeachers,


    getAlbumsForAlbumIds,
    updateMessages,
    updateAlbums,
    getUserTeacher,

    getCoursesForOwnerIds,
    getAllKids,
    getCoureseSubscriptions,

    getMessagesForCourseKid,
    getLatestMessagesForCourseKid,
    getOldMessagesForCourseKid,

} from '../../store/actions';
import defaultUserPic from "../../assets/images/users/user_2.png";
import alert from "../../assets/images/messageIcons/alert.png"
import text from "../../assets/images/messageIcons/text.png"
import birthday from "../../assets/images/messageIcons/birthday.png"
import assignment from "../../assets/images/messageIcons/assignment.png"
import attendance from "../../assets/images/messageIcons/attendance.png"
import notice from "../../assets/images/messageIcons/notice.png"
import reminder from "../../assets/images/messageIcons/reminder.png"
import livemeeting from "../../assets/images/messageIcons/webcam.png"
import paymentImg from "../../assets/images/messageIcons/payment.png"
import MessageTypeDropdown from './MessageTypeDropdown';

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import DocumentViewerModal from './DocumentViewerModal';
import { cssNumber } from 'jquery';

const scrollViewHeight = 510


const Chat = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/messaging/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId


    const [isGallery, setisGallery] = useState(false);
    const [galleryImages, setGalleryImages] = useState([]);
    const [photoIndex, setphotoIndex] = useState(0);


    const allowedTypes = [Constants.MESSAGE, Constants.ALERT, Constants.ASSIGNMENT, Constants.NOTICE, Constants.REMINDER, Constants.QUERY]

    const [schoolType, setSchoolType] = useState();
    const [messages, setmessages] = useState([]);
    const [activeTab, setactiveTab] = useState('1');
    const [Chat_Box_Username, setChat_Box_Username] = useState("");
    const [Chat_Box_Object, setChat_Box_Object] = useState("")
    const [classList, setClassList] = useState([]);
    const [groupList, setGroupList] = useState([]);
    const [isAllSchoolSelected, setIsAllSchoolSelected] = useState(true);
    const [isAllTeachersSelected, setIsAllTeachersSelected] = useState(false);
    const [messagesMultiDimensionalArray, setMessagesMultiDimensionalArray] = useState([]);
    const [arrayOfDatesForSection, setArrayOfDatesForSection] = useState([]);
    const [selectedMessageType, setselectedMessageType] = useState(Constants.MESSAGE);
    const [messageText, setMessageText] = useState("");
    const [exceededSMSLimit, setExceededSMSLimit] = useState(false);
    const [clickedFile, setClickedFile] = useState();

    const [imagesList, setImagesList] = useState();
    const [currentIndexForUpload, setCurrentIndexForUpload] = useState(0);
    const [progressText, setProgressText] = useState();
    const [albumImageList, setAlbumImageList] = useState([]);
    const [albumVideoList, setAlbumVideoList] = useState([]);
    const [documentParseFile, setDocumentParseFile] = useState();

    const [albumUUIDId, setAlbumUUIDId] = useState();
    const [videosList, setVideosList] = useState();
    const [messageForStatusObject, setMessageForStatusObject] = useState();

    const [ownerIds, setOwnerIds] = useState([]);
    const [courseToDisplay, setCourseToDisplay] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(undefined);

    const [allCourseSubscriptions, setCourseSubscriptions] = useState([]);
    const [selectedKid, setSelectedKid] = useState(undefined);

    const myRef = useRef(null)

    useEffect(() => {
        if (currentIndexForUpload >= 0 && imagesList) {
            if (imagesList.length > currentIndexForUpload) {
                onImageListUpload(imagesList[currentIndexForUpload], currentIndexForUpload);
            } else {
                setCurrentIndexForUpload(-1);
                setProgressText()
                setImagesList([]);
            }
        }
    }, [imagesList, currentIndexForUpload]);

    function onImageListUpload(file, count) {
        setProgressText(props.t('Uploading ') + (count + 1) + " of " + imagesList.length)
        new Compressor(file.file, {
            quality: Constants.IMAGE_COMPRESSION_RATE, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                convertImageToBase64(file, count, compressedResult)
            },
        });
    }

    function convertImageToBase64(file, count, compressedResult) {
        var albumId = albumUUIDId ?? uuidv4()
        setAlbumUUIDId(albumId)
        var name = `file`;
        if (file.type === "Image") {
            name = name + "." + file.extention;
        }
        getBase64(compressedResult, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setProgressText(`${props.t('Uploading ') + (count + 1) + " of " + imagesList.length + " Images "} ( ${(value * 100).toFixed(2)}% )`)
                    }
                }
            }).then(
                result => {
                    let parseImagesList = [...albumImageList]
                    var Files = Parse.Object.extend("Album");
                    var fileObject = new Files();
                    fileObject.set("albumId", albumId);
                    fileObject.set("image", result);
                    fileObject.set("schoolId", schoolId)
                    parseImagesList.push(fileObject)
                    setAlbumImageList(parseImagesList)
                    if (imagesList.length > currentIndexForUpload) {
                        setCurrentIndexForUpload((currentIndexForUpload + 1))
                    } else {
                        setCurrentIndexForUpload(-1);
                        setProgressText()
                        setImagesList([]);
                    }
                },
                error => {
                    setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                }
            );
        });
    }

    function copyToClipboard(str, event) {
        document.oncopy = function (event) {
            event.clipboardData.setData("Text", str);
            event.preventDefault();
        };
        document.execCommand("Copy");
        document.oncopy = undefined;
        toastr.success(props.t("message text copied to clipboard"), { timeOut: 1000 });
    }

    useEffect(() => {
        if (currentIndexForUpload >= 0 && videosList) {
            if (videosList.length > currentIndexForUpload) {
                onVideoListUpload(videosList[currentIndexForUpload], currentIndexForUpload);
            } else {
                setCurrentIndexForUpload(-1);
                setProgressText()
                setVideosList([]);
            }
        }
    }, [videosList, currentIndexForUpload]);

    function onVideoListUpload(file, count) {
        var albumId = albumUUIDId ?? uuidv4()
        setAlbumUUIDId(albumId)

        var name = file.name.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
        if (file.type === "Image") {
            name = name + "." + file.extention;
        }
        setProgressText(props.t('Uploading ') + (count + 1) + " of " + videosList.length)
        let oneFileWhite = 100 / videosList.length;
        let percentOffSet = oneFileWhite * count;
        getBase64(file.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setProgressText(`${props.t('Uploading ') + (count + 1) + " of " + videosList.length} ( ${(value * 100).toFixed(2)}% )`)
                    }
                }
            }).then(
                result => {
                    let parseVideosList = [...albumVideoList]
                    var Files = Parse.Object.extend("Album");
                    var fileObject = new Files();
                    fileObject.set("albumId", albumId);
                    fileObject.set("video", result);
                    fileObject.set("schoolId", schoolId)
                    parseVideosList.push(fileObject)
                    setAlbumVideoList(parseVideosList)
                    if (videosList.length > currentIndexForUpload) {
                        setCurrentIndexForUpload((currentIndexForUpload + 1))
                    } else {
                        setCurrentIndexForUpload(-1);
                        setProgressText()
                        setVideosList([]);
                    }
                },
                error => {
                    setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                }
            );
        });
    }


    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }




    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        } else {
            setSchoolType(props.selectedSchool.attributes.schoolType)
            if (props.selectedSchool.attributes.schoolType == 10) {
                setselectedMessageType(Constants.QUERY)
                let ids = [];
                ids.push(props.selectedSchool.id);
                ids.push(props.selectedSchool.attributes.instituteId);
                if (props.selectedSchool.attributes.franchiseId) {
                    ids.push(props.selectedSchool.attributes.franchiseId);
                }


                if (ownerIds.length === ids.length) {

                    var call = false;
                    for (const i in ownerIds) {
                        if (ownerIds[i] !== ids[i]) {
                            call = true;
                        }
                    }
                    if (call) {
                        props.getCoursesForOwnerIds(ids);
                    }

                } else {
                    setOwnerIds(ids);
                    props.getCoursesForOwnerIds(ids);
                }
            }


        }
        if (Parse.User.current().attributes.teacherId) {
            props.getUserTeacher(Parse.User.current().attributes.teacherId)
        }
        props.getTeachersForSchool(schoolId);
        props.getClassroomsForSchoolId(schoolId);
        props.getGroupsForSchoolId(schoolId);
        props.getAllKids(schoolId);
        if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 10) {

        } else if (props.selectedSchool) {
            UserChatOpen(true, false, schoolId, schoolId)
        }


    }, [props.selectedSchool, schoolId]);

    // courses

    useEffect(() => {
        let list = [];
        for (const co of props.courses) {
            if (co.attributes.state === "Published") {
                list.push({

                    id: co.id,
                    objectId: co.id,
                    name: co.attributes.name,
                    isActive: false,
                    object: co,
                });
            }
        }
        setCourseToDisplay(list)
    }, [props.courses])

    useEffect(() => {

        // console.log("k", props.kids, selectedCourse, props.courseSubscriptions)
        // console.log("sc",  selectedCourse)
        // console.log("cs",props.courseSubscriptions)

        if (selectedCourse && props.kids && props.courseSubscriptions) {
            let list = [];
            for (const cs of props.courseSubscriptions) {
                let others

                others = props.kids.filter(
                    (k) => k.id == cs.attributes.kidId
                );

                if (others.length > 0) {
                    list.push({
                        id: cs.id,
                        objectId: cs.id,
                        courseId: selectedCourse.id,
                        course: selectedCourse,
                        kidId: others[0].id,
                        kid: others[0],
                        name: others[0].attributes.Name,
                        object: cs,
                        isActive: false,
                    })
                }
            }
            setCourseSubscriptions(list);
            // console.log("acs", props.list);
        }

    }, [props.kids, selectedCourse, props.courseSubscriptions])

    useEffect(() => {
        if (props.classrooms) {
            let tempClass = [];
            let classIds = [];

            let tempClassSort = props.classrooms.sort(function (a, b) {
                var nameA = a.attributes.Name.toLowerCase(),
                    nameB = b.attributes.Name.toLowerCase();
                if (nameA < nameB)
                    //sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0; //default return value (no sorting)
            });
            let daycareFirst = [];
            for (const val of tempClassSort) {
                if (val.attributes.classroomType === 2) {
                    daycareFirst.unshift(val);
                } else {
                    daycareFirst.push(val);
                }
            }



            var i = 1
            for (const val of daycareFirst) {
                if (val.attributes.isDeleted) {
                    break;
                }
                let temp = {};
                temp.id = i++;
                temp.objectId = val.id
                temp.obj = val;
                temp.name = val.attributes.Name;
                temp.isActive = false
                temp.schoolId = val.attributes.SchoolID;
                temp.classType = val.attributes.classroomType;
                temp.classLevel = val.attributes.classLevel;
                temp.classCss =
                    val.attributes.classroomType === 2 ? "text-danger" : "text-primary";
                temp.kidCount = "-";
                temp.kidIds = [];
                tempClass.push(temp);
                classIds.push(val.id);
            }


            setClassList(tempClass);
        }
    }, [props.classrooms]);

    useEffect(() => {
        if (props.groups) {
            let tempGroupList = [];
            let tempSortedGroupList = props.groups.sort(function (a, b) {
                var nameA = a.attributes.name.toLowerCase(),
                    nameB = b.attributes.name.toLowerCase();
                if (nameA < nameB)
                    //sort string ascending
                    return -1;
                if (nameA > nameB) return 1;
                return 0; //default return value (no sorting)
            });


            var i = 1
            for (const val of tempSortedGroupList) {
                if (val.attributes.deleted) {
                    break;
                }

                let temp = {};
                temp.id = i++;
                temp.objectId = val.id
                temp.obj = val;
                temp.name = val.attributes.name;
                temp.isActive = false
                temp.schoolId = val.attributes.SchoolID;
                tempGroupList.push(temp);


            }



            setGroupList(tempGroupList);
        }
    }, [props.groups]);



    function toggleTab(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab)
        }
    }

    //Use For Chat Box
    function UserChatOpen(isAllSchoolSelected, isAllTeachersSelected, id, name, obj, isCourse, isCourseSubscription) {

        if (isCourse) {
            if (isCourseSubscription && selectedKid && selectedKid.id == obj.id) {
                return;
            }
            if (isCourseSubscription) {
                let list = [];
                for (let cs of allCourseSubscriptions) {
                    if (obj.id == cs.id) {
                        cs.isActive = true
                    } else {
                        cs.isActive = false
                    }
                    list.push(cs);
                }
                props.getMessagesForCourseKid(obj.courseId, obj.kidId, true)
                setCourseSubscriptions(list);
                setSelectedKid(obj)
                setChat_Box_Username(obj.name);
                setChat_Box_Object(obj);
            } else {
                setSelectedCourse(obj)
                let list = [];
                for (let co of courseToDisplay) {
                    if (obj.id == co.id) {
                        co.isActive = true
                    } else {
                        co.isActive = false
                    }
                    list.push(co);
                }
                setCourseToDisplay(list);

                props.getCoureseSubscriptions(id);
            }
        } else {

            let chatModule
            if (activeTab === '1') {
                chatModule = [...classList];
            } else if (activeTab === '2') {
                chatModule = [...groupList];
            } else if (activeTab === '3') {
                chatModule = [...classList];
                if (chatModule) {
                    for (let k = 0; k < chatModule.length; k++) { chatModule[k].isActive = false; } // Enable All Option First
                }
                chatModule = [...groupList];
                if (chatModule) {
                    for (let k = 0; k < chatModule.length; k++) { chatModule[k].isActive = false; } // Enable All Option First
                }
            }

            if (chatModule) {
                for (let k = 0; k < chatModule.length; k++) { chatModule[k].isActive = false; } // Enable All Option First
            }
            if (!isAllSchoolSelected && !isAllTeachersSelected) {
                setIsAllSchoolSelected(false)
                setIsAllTeachersSelected(false)
                if (chatModule) {
                    chatModule[id - 1].isActive = true;
                }
            } else {
                setIsAllSchoolSelected(isAllSchoolSelected)
                setIsAllTeachersSelected(isAllTeachersSelected)
            }

            if (isAllSchoolSelected) {
                setChat_Box_Username(props.selectedSchool ? props.selectedSchool.attributes.Name : "");
            } else {
                setChat_Box_Username(name);
            }

            props.directAssignMessages([])

            if (isAllSchoolSelected) {
                props.getMessagesForSchoolId(schoolId)
                setChat_Box_Object({ objectId: schoolId })
            } else if (isAllTeachersSelected) {
                props.getMessagesForAllTeachers(schoolId)
                setChat_Box_Object({ objectId: schoolId })

            } else {
                if (activeTab === '1') {
                    setChat_Box_Object(obj);
                    props.getMessagesForClassId(obj.objectId)
                    setClassList(chatModule);
                } else if (activeTab === '2') {
                    setChat_Box_Object(obj)
                    props.getMessagesForGroupId(obj.objectId)
                    setGroupList(chatModule);
                }
            }
        }
    }


    const getSectionArrayOfDates = (messages) => {
        let tempMultiList = []
        let tempArrayOfSectionDates = []
        if (messages != undefined && messages.length > 0) {

            tempArrayOfSectionDates.push(messages[0].createdAt)
            tempMultiList.push([messages[0]])
            var j = 0
            var k = 0
            if (messages.length > 0) {
                for (var i = 1; i < messages.length; i++) {
                    let date1 = new Date(tempArrayOfSectionDates[j])
                    let date2 = new Date(messages[i].createdAt)
                    if (date1.withoutTime().getTime() !== date2.withoutTime().getTime()) {
                        tempArrayOfSectionDates.push(date2)
                        k = k + 1
                        tempMultiList.push([messages[i]])
                        j = j + 1
                    } else {
                        tempMultiList[k].push(messages[i])
                    }
                }
            }
            setArrayOfDatesForSection(tempArrayOfSectionDates)
            setMessagesMultiDimensionalArray(tempMultiList)
        }
    }

    useEffect(() => {
        if (messagesMultiDimensionalArray && messagesMultiDimensionalArray.length > 0 && !props.isOldMessages) {
            executeScroll()
        }
    }, [messagesMultiDimensionalArray])

    const getChatRowsUI = (list, isCourse, isCourseSubscription) => {
        return (
            <>
                {list.map((item, key) =>
                    <li key={item.objectId} className={item.isActive ? "active" : ""}>
                        <Link to="#" onClick={() => { UserChatOpen(false, false, item.id, item.name, item, isCourse, isCourseSubscription) }}>
                            <div className="d-flex ml-2">
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    id={"radioButton" + "messaging" + key}
                                    checked={item.isActive}
                                    onChange={() => {
                                        UserChatOpen(false, false, item.id, item.name, item, isCourse, isCourseSubscription)
                                    }}
                                />
                                <div className="float-right">
                                    <label
                                        className="form-check-label"
                                        htmlFor={"radioButton" + "messaging" + key}
                                    >
                                        {item.name}

                                    </label>

                                </div>
                            </div>

                            {/* <Media className="overflow-hidden" body>
                                    <h5 className="text-truncate font-size-14 mb-1">{chat.name}</h5>
                                    <p className="text-truncate mb-0">{chat.description}</p>
                                </Media> 
                                        <div className="font-size-11">{chat.time}</div> */}


                        </Link>
                    </li>
                )}
            </>
        )
    }

    useEffect(() => {
        if (props.messages) {
            let albumIds = []
            for (const message of props.messages) {
                if (message.attributes.albumId && !albumIds.includes(message.attributes.albumId)) {
                    albumIds.push(message.attributes.albumId)
                }
                if (message.attributes.videoAlbumId && !albumIds.includes(message.attributes.videoAlbumId)) {
                    albumIds.push(message.attributes.videoAlbumId)
                }

            }
            if (albumIds.length > 0) {
                props.getAlbumsForAlbumIds(albumIds)
            }
            //{ id: "34", isRight: true, name: "Henry Wells", message: "Hello!", time: "10:00" },
            var i = 0
            var tempList = []
            for (const message of props.messages) {
                let tmp = {}
                tmp.id = i++
                tmp.isRight = Parse.User.current().attributes.teacherId === message.attributes.Sender ? true : false
                tmp.senderName = getSenderNamePhoto(message.attributes.Sender).name
                tmp.senderPhoto = getSenderNamePhoto(message.attributes.Sender).photo
                tmp.receiverName = getReceiverNamePhoto(message.attributes.Receiver).name
                tmp.receiverPhoto = getReceiverNamePhoto(message.attributes.Receiver).photo
                tmp.messageTypeString = getMessageTypeString(message.attributes.Type)
                tmp.message = message.attributes.Message
                tmp.albumId = message.attributes.albumId
                tmp.videoAlbumId = message.attributes.videoAlbumId

                tmp.time = convertDateToStringWithTime(message.createdAt)
                tmp.createdAt = message.createdAt
                tmp.updatedAt = message.attributes.customUpdatedTime
                tmp.Attachment = message.attributes.Attachment
                tmp.icon = getMessageIcon(message.attributes.Type)
                tmp.parseObject = message
                tempList.push(tmp)
            }
            setmessages(tempList)
            getSectionArrayOfDates(tempList)
        }
    }, [props.messages])

    function getSenderNamePhoto(id) {
        if (schoolType == 10) {
            if (props.userTeacher && id === props.userTeacher.id) {
                return { name: props.userTeacher.attributes.Name, photo: props.userTeacher.attributes.Photo }
            } else if (selectedKid && selectedKid.kid && selectedKid.kidId === id) {
                return { name: selectedKid.kid.attributes.Name, photo: selectedKid.kid.attributes.Photo }
            }
        } else {
            if (props.teachers && props.teachers.length > 0) {
                var temp = props.teachers.filter((teacher) => teacher.id === id);
                if (temp && temp.length > 0) {
                    return { name: temp[0].attributes.Name, photo: temp[0].attributes.Photo }
                }
            }
        }
        return { name: "", photo: undefined }
    }

    function getReceiverNamePhoto(id) {
        if (schoolType == 10) {
            if (props.userTeacher && id === props.userTeacher.id) {
                return { name: props.userTeacher.attributes.Name, photo: props.userTeacher.attributes.Photo }
            } else if (selectedKid && selectedKid.kid && selectedKid.kidId === id) {
                return { name: selectedKid.kid.attributes.Name, photo: selectedKid.kid.attributes.Photo }
            }
        } else {
            if (props.teachers && props.teachers.length > 0) {
                var temp = props.teachers.filter((teacher) => teacher.id === id);
                if (temp && temp.length > 0) {
                    return { name: temp[0].attributes.Name, photo: temp[0].attributes.Photo }
                }
            }
        }
        return { name: "", photo: undefined }
    }

    function getMessageTypeString(type) {
        switch (type) {
            case Constants.ALERT:
                return "Alert"
            case Constants.REMINDER:
                return "Reminder"
            case Constants.ASSIGNMENT:
                return "Assignment"
            case Constants.NOTICE:
                return "Notice"
            case Constants.LIVEMEETING:
                return "Live Meeting"
            case Constants.PAYMENT_MESSAGE:
                return "Payment Notification"
            case Constants.TRANSPORT:
            case Constants.TRANSPORT_FINISH:
            case Constants.TRANSPORT_UPDATE:
                return "Transport"
            default:
                return ""
        }
    }

    function getMessageIcon(type) {
        switch (type) {
            case Constants.ALERT:
                return alert

            case Constants.REMINDER:
                return reminder

            case Constants.ASSIGNMENT:
                return assignment

            case Constants.NOTICE:
                return notice

            case Constants.LIVEMEETING:
                return livemeeting

            case Constants.PAYMENT_MESSAGE:
                return paymentImg

            case Constants.TRANSPORT:
            case Constants.TRANSPORT_FINISH:
            case Constants.TRANSPORT_UPDATE:

                return attendance

            default:
                return undefined
        }
    }

    function convertDateToStringWithTime(date) {
        return moment(date).format("h:mm a");
    }

    function convertDateToString(date) {
        return moment(date).format("DD MMM YYYY");
    }

    const onPostMessage = () => {
        // if (exceededSMSLimit) {
        //     toastr.error("Message Limit of 320 is exceeded for Alert Type, Please change the type")
        //     return
        // }
        if (messageText.trim().length === 0) {
            toastr.error("Please enter message")
            return
        }

        let newMessage = getMessage()
        if (schoolType == 10) {
            newMessage.set("Sender", Parse.User.current().attributes.teacherId)
            newMessage.set("KidID", selectedKid.kidId)
            newMessage.set("Receiver", selectedKid.kidId)
            newMessage.set("courseId", selectedKid.courseId)
            newMessage.set("chatroomId", selectedKid.courseId + "_" + selectedKid.kidId);
        } else if (isAllSchoolSelected) {
            newMessage.set("SchoolID", schoolId)
        } else if (isAllTeachersSelected) {
            newMessage.set("Receiver", "T")
            newMessage.set("SchoolID", schoolId)
            if (selectedMessageType === Constants.MESSAGE) {
                newMessage.set("Type", 10)
            }
        } else {
            if (activeTab === "1" && Chat_Box_Object) {
                newMessage.set("ClassID", Chat_Box_Object.objectId)
            } else if (activeTab === "2") {
                newMessage.set("groupId", Chat_Box_Object.objectId)
            }
        }
        newMessage.save().then(
            (result) => {
                saveAlbums()
                setAlbumUUIDId()
                setMessageText("")
                clearAllAttachments()
                executeScroll()
                props.updateMessages([result])
            },
            (error) => {
                setAlbumUUIDId()
                toastr.error("Error occured while sending message, please refresh & try again")
            }
        );
    }

    const saveAlbums = () => {
        if (albumImageList && albumImageList.length > 0) {
            Parse.Object.saveAll(albumImageList).then(
                (results) => {
                    props.updateAlbums(results)
                },
                (error) => {
                    console.log("Error", error);
                }
            );
        }
        if (albumVideoList && albumVideoList.length > 0) {
            Parse.Object.saveAll(albumVideoList).then(
                (results) => {
                    props.updateAlbums(results)
                },
                (error) => {
                    console.log("Error", error);
                }
            );
        }
    }

    const getMessage = () => {
        const Messages = Parse.Object.extend('Messages');
        let newMessage = new Messages();
        newMessage.set("Message", messageText)
        newMessage.set("Type", parseInt(selectedMessageType, 10))
        if (selectedMessageType === Constants.ALERT) {
            newMessage.set("alertTypes", ["app", "email"])
        }
        newMessage.set("SenderId", Parse.User.current().attributes.teacherId)
        newMessage.set("Sender", Parse.User.current().attributes.teacherId)

        newMessage.set("defaultSchoolId", schoolId)
        newMessage.set("clintType", 4)

        if (albumImageList && albumImageList.length > 0) {
            newMessage.set("albumId", albumImageList[0].attributes.albumId)
        } else if (documentParseFile && documentParseFile.file) {
            newMessage.set("Attachment", documentParseFile.file)
        } else if (albumVideoList && albumVideoList.length > 0) {
            newMessage.set("videoAlbumId", albumVideoList[0].attributes.albumId)
        }

        return newMessage
    }

    const executeScroll = () => myRef.current && myRef.current.scrollIntoView()

    const getAlbumsForAlbumId = (albumId) => {
        if (props.albums && props.albums.length > 0) {
            let albums = props.albums.filter((album) =>
                album.attributes.albumId === albumId
            )
            if (albums.length > 0) {
                return albums
            }
        }
        return undefined
    }

    const getImagesUI = (message) => {
        let albums = undefined
        if (message.albumId && message.albumId.length > 0) {
            albums = getAlbumsForAlbumId(message.albumId)
        }
        if (albums && albums.length > 0) {
            var images = []
            for (const album of albums) {
                let image = album.attributes.image ? album.attributes.image._url : ""
                images.push(image)
            }

            return (<div className='mt-3 mb-5'>
                <div className="popup-gallery">
                    {
                        images.map((image, key) => (
                            <>{key < 3 && <div className="img-fluid float-left" key={key}>
                                <img
                                    src={image}
                                    onClick={() => { setGalleryImages(images); setisGallery(true); setphotoIndex(key); }}
                                    alt=""
                                    width="120"
                                    height="84"
                                />
                            </div>}</>
                        ))
                    }
                    {images.length > 3 && <div className="img-fluid float-left">
                        <div onClick={() => { setisGallery(true); setphotoIndex(3); }} style={{ width: "33mm", height: "21.20mm", backgroundColor: "rgba(0, 0, 0, 0.5)", backgroundImage: `url("${images[3]}")`, backgroundRepeat: "no-repeat", backgroundSize: "cover", backgroundPosition: "center" }}>
                            <center><label className="text-white text-center mr-2 mt-2" style={{ fontSize: "12mm" }}>
                                {`+ ${images.length - 3}`}</label></center>
                        </div>
                    </div>}
                </div>
            </div>)
        }
        return
    }

    const getVideoUI = (message) => {
        let videoAlbum
        if (message.videoAlbumId && message.videoAlbumId.length > 0) {
            videoAlbum = getAlbumsForAlbumId(message.videoAlbumId)
        }
        if (videoAlbum && videoAlbum.length > 0) {
            if (videoAlbum[0].attributes.video) {
                return (
                    <div className="embed-responsive embed-responsive-16by9" style={{ minWidth: "120mm", maxWidth: "120mm", minHeight: "70mm", maxHeight: "70mm" }}>
                        <Iframe allowFullScreen={true} title="test" className="embed-responsive-item" src={videoAlbum[0].attributes.video._url}></Iframe>
                    </div>
                )
            }

        }
        return
    }




    const getActualFileName = (result) => {
        if (typeof result === "object") {
            let name = result._name.split(/_(.+)/)[1];
            return name

        } else {
            return result
        }
    }

    const getFileExtension = (result) => {
        if (typeof result === "object") {
            let name = result._name.split(/_(.+)/)[1];
            let fileExtension = name.split(".");
            return fileExtension[1].toLowerCase()
        } else {
            return result
        }
    }

    function getFileTypeImage(fileExtension) {
        if (fileExtension === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtension === "ppt" || fileExtension === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "doc" || fileExtension === "docx") {
            return "bx bxs-file-doc";
        }
        // else if(fileType === "Video"){
        //     return "bx bx-play-circle";
        // }else if (_audioExtensions.includes(fileExtension)) {
        //     return "bx bxs-music";
        // }
        return "bx bxs-file-blank";
    }

    const getDocumentUI = (message) => {
        return (<div className='border' style={{ backgroundColor: "white", borderRadius: "3mm" }}>

            <Col xl="12" md="12" sm="12" xs="12">
                <div className="p-3">
                    <Row>
                        <div
                            className="avatar ml-3 border rounded"
                            style={{ width: "5rem", height: "5rem" }}
                        >
                            <span
                                className="avatar-title bg-white ml-0 text-primary h1"
                                style={{ fontSize: "60px", borderRadius: "3mm" }}
                            >
                                <i
                                    className={getFileTypeImage(
                                        getFileExtension(message.Attachment)
                                    )}
                                ></i>
                            </span>
                        </div>

                        <div className="float-right ml-4">
                            <div
                                className="text-muted font-weight-bold text-truncate"
                                style={{ width: "200px" }}
                            >
                                {getActualFileName(message.Attachment)}
                            </div>
                            <div className="d-flex mt-3">

                                <Button
                                    className="btn btn-primary float-right ml-2"
                                    color="primary"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    onClick={() => {
                                        setClickedFile(message.Attachment)
                                    }}
                                >
                                    Open
                                </Button>
                            </div>
                        </div>
                    </Row>
                </div>
            </Col>

        </div>)
    }

    function handleVideoUploadChange(e) {
        let tempFileList = [];
        if (!validateSize(e.target, 50)) {
            return
        }
        for (const i in e.target.files) {

            if (e.target.files.length > i) {
                let tempFile = e.target.files[i];
                if (tempFile && tempFile.name) {
                    let tempfileExtension = tempFile.name.split(".");
                    let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
                    let newFile = {
                        id: "fiele_" + i,
                        file: tempFile,
                        type: "Video",
                        downloadeble: true,
                        actualFileName: tempFile.name,
                        name: tempfileExtension[0],
                        extention: tempExt,
                        extentionIcon: getFileTypeImage(tempExt),
                        preview: URL.createObjectURL(tempFile)
                    }
                    tempFileList.push(newFile);
                }
            }
        }
        setVideosList(tempFileList);
    }

    function handleImageUploadChange(e) {
        let tempFileList = [];
        console.log(e.target.files.length);
        for (const i in e.target.files) {

            if (e.target.files.length > i) {
                let tempFile = e.target.files[i];
                if (tempFile && tempFile.name) {
                    let tempfileExtension = tempFile.name.split(".");
                    let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
                    let newFile = {
                        id: "fiele_" + i,
                        file: tempFile,
                        type: "Image",
                        downloadeble: true,
                        actualFileName: tempFile.name,
                        name: tempfileExtension[0],
                        extention: tempExt,
                        extentionIcon: getFileTypeImage(tempExt),
                        preview: URL.createObjectURL(tempFile)
                    }
                    tempFileList.push(newFile);
                }
            }
        }
        setImagesList(tempFileList);
    }

    const deleteMessage = (message) => {
        if (message.parseObject) {
            message.parseObject.set("isDeleted", true)

            message.parseObject.save().then(
                (result) => {
                    props.updateMessages([result])
                },
                (error) => {

                }
            );
        }
    }

    const canShowStatus = (message) => {
        let returnValue = false
        if (schoolType == 10) {
            return false;
        }
        if (allowedTypes.includes(message.parseObject.attributes.Type)) {
            returnValue = true
        }
        return returnValue
    }

    const canShowDelete = (message) => {
        let returnValue = false

        if (schoolType == 10) {
            if (props.userTeacher && message && message.parseObject && message.parseObject.attributes && (props.userTeacher.id == message.parseObject.attributes.SenderId || props.userTeacher.id == message.parseObject.attributes.Sender)) {
                return true;
            }
        } else
            if (props.userTeacher && props.userTeacher.attributes.featurePermissions != undefined &&
                props.userTeacher.attributes.featurePermissions.length >
                Constants.TEACHER_MESSAGING_INDEX) {
                if (props.userTeacher.attributes.featurePermissions[Constants.TEACHER_MESSAGING_INDEX] === 2) {
                    if (allowedTypes.includes(message.parseObject.attributes.Type)) {
                        if (isToday(message.parseObject.createdAt)) {
                            returnValue = true
                        }
                    }
                }
            }
        return returnValue
    }
    const isToday = (lastDate) => {
        let date = new Date(lastDate);
        const today = new Date()
        return date.getDate() === today.getDate() &&
            date.getMonth() === today.getMonth() &&
            date.getFullYear() === today.getFullYear();
    }

    const getCellUI = (message, key) => {
        return (
            <li ref={myRef} key={"test_k" + message.id + key} className={message.isRight ? "right" : ""}>
                <div className="conversation-list">
                    <UncontrolledDropdown>
                        <DropdownToggle href="#" className="btn nav-btn" tag="i">
                            <i className="bx bx-dots-vertical-rounded"></i>
                        </DropdownToggle>
                        <DropdownMenu direction="right">
                            {
                                canShowStatus(message) && <DropdownItem href="#" onClick={() => {
                                    setMessageForStatusObject(message)
                                }}>Status</DropdownItem>
                            }
                            <DropdownItem href="#" onClick={() => {
                                copyToClipboard(message.message ?? "")
                            }}>Copy</DropdownItem>
                            {canShowDelete(message) && <DropdownItem href="#" onClick={() => {
                                deleteMessage(message)
                            }}>Delete</DropdownItem>}
                        </DropdownMenu>
                    </UncontrolledDropdown>
                    <div className="ctext-wrap">
                        <Media>
                            <div className="mr-3">
                                <img src={message.icon ?? (message.senderPhoto ? message.senderPhoto._url : defaultUserPic)} className="rounded-circle avatar-xs" alt="" />
                            </div>
                            <Media className="overflow-hidden" body>
                                <h5 className="conversation-name text-truncate font-size-14 mb-1">{message.senderName ? message.messageTypeString + "(" + message.senderName + ")" : message.messageTypeString}</h5>
                                <p className="mb-0" style={{ wordWrap: "break-word" }}>{message.message}</p>
                                <div><p className="chat-time mb-0 mt-2">{message.time}</p></div>
                                {getVideoUI(message)}
                                {
                                    (message.Attachment) && getDocumentUI(message)
                                }
                                {
                                    getImagesUI(message)
                                }
                            </Media>
                        </Media>
                    </div>
                </div>
            </li>
        )
    }

    const getLightBoxImagesUI = () => {
        var images = galleryImages
        return (<Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={
                images[(photoIndex + images.length - 1) % images.length]
            }
            enableZoom={true}
            onCloseRequest={() => { setisGallery(false) }}
            onMovePrevRequest={() => { setphotoIndex((photoIndex + images.length - 1) % images.length) }}
            onMoveNextRequest={() => { setphotoIndex((photoIndex + 1) % images.length) }}
            imageCaption={"Image " + parseFloat(photoIndex + 1)}
        />)
    }

    const handleFileUploadChange = (e) => {
        if (e.target.files && e.target.files[0] && validateSize(e.target, 20)) {
            setDocumentParseFile(undefined);
            setProgressText("Uploading 1 of 1 file")

            let fileExtension = e.target.files[0].name.split(".");
            let tempFile = {
                actualFileName: e.target.files[0].name,
                fileExtension: fileExtension[1].toLowerCase(),
                fileName: fileExtension[0],
                file: e.target.files[0],
            };

            var name = tempFile.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

            getBase64(tempFile.file, (resultWithName) => {
                var parseFile = new Parse.File(name, { base64: resultWithName.result });
                //setUploadProgress(1);
                parseFile
                    .save({
                        progress: (value) => {
                            if (value) {
                                if (value && value > 0.1)
                                    setProgressText(`Uploading 1 of 1 file ( ${(value * 100).toFixed(2)}% )`)
                                //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")
                            }
                        },
                    })
                    .then(
                        (result) => {
                            setProgressText()
                            tempFile.file = result;
                            setDocumentParseFile(tempFile);
                        },
                        (error) => {
                            setProgressText()
                            //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                        }
                    );
            });

            setDocumentParseFile({ actualFileName: tempFile.actualFileName })

        }
    }

    function validateSize(input, limit) {
        const fileSize = input.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > limit) {
            toastr.warning(`File size exceeds ${limit} MB, Please use a smaller file`)
            return false
        }
        return true
    }

    const getMessageStatusUI = () => {
        return (<MessageStatusView
            schoolId={schoolId}
            isAllSchoolSelected={isAllSchoolSelected}
            isAllTeachersSelected={isAllTeachersSelected}
            classId={(!isAllSchoolSelected && !isAllTeachersSelected && activeTab === "1") ? Chat_Box_Object.objectId : undefined}
            groupId={(!isAllSchoolSelected && !isAllTeachersSelected && activeTab === "2") ? Chat_Box_Object.objectId : undefined}

            messageObject={messageForStatusObject}
            onClose={() => {
                setMessageForStatusObject()
            }}
        >

        </MessageStatusView>
        )
    }

    const clearAllAttachments = () => {
        setCurrentIndexForUpload(0)
        setAlbumImageList([])
        setAlbumVideoList([])
        setDocumentParseFile()
    }

    return (
        <React.Fragment>
            {isGallery ? (
                getLightBoxImagesUI()
            ) : null}
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="d-lg-flex">
                                {schoolType == 10 ? <div className="chat-leftsidebar mr-lg-4">
                                    <Card>
                                        <CardBody>
                                            <div>

                                                {
                                                    selectedCourse ? <div>
                                                        <h4>
                                                            {selectedCourse.name}
                                                        </h4>
                                                        <ul className="list-inline mb-0">

                                                            <li className="list-inline-item">

                                                                <Label className="text-primary waves-effect float-right" style={{ cursor: 'pointer' }}
                                                                    onClick={() => {
                                                                        setSelectedCourse(undefined);
                                                                        let list = []
                                                                        for (let co of courseToDisplay) {
                                                                            co.isActive = false
                                                                            list.push(co);
                                                                        }
                                                                        setCourseToDisplay(list);
                                                                    }}
                                                                    id='close-course'
                                                                >
                                                                    <i className="font-size-18 bx bx-x-circle"></i>
                                                                </Label>
                                                                <UncontrolledTooltip placement="top" target="close-course">
                                                                    Close
                                                                </UncontrolledTooltip >

                                                            </li>

                                                        </ul>
                                                    </div> :
                                                        <h4>
                                                            Select Course
                                                        </h4>
                                                }

                                            </div>
                                            {selectedCourse ? <div>

                                                <ul className="list-unstyled chat-list">
                                                    <PerfectScrollbar style={{ height: scrollViewHeight }}>
                                                        {getChatRowsUI(allCourseSubscriptions, true, true)}
                                                    </PerfectScrollbar>
                                                </ul>

                                            </div> : <ul className="list-unstyled chat-list">
                                                <PerfectScrollbar style={{ height: scrollViewHeight }}>
                                                    {getChatRowsUI(courseToDisplay, true)}
                                                </PerfectScrollbar>
                                            </ul>}
                                        </CardBody>
                                    </Card>
                                </div> :
                                    <div className="chat-leftsidebar mr-lg-4">
                                        <div className="">
                                            <div className="form-check py-4">
                                                <div className="d-flex">
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        id={"radioButton" + "messagingall"}
                                                        checked={isAllSchoolSelected}
                                                        onChange={() => {
                                                            setIsAllSchoolSelected(!isAllSchoolSelected)
                                                            UserChatOpen(!isAllSchoolSelected, false, schoolId, schoolId,)
                                                        }}
                                                    />
                                                    <div className="float-right">
                                                        <label
                                                            className="form-check-label"
                                                            htmlFor={"radioButton" + "messagingall"}
                                                        >
                                                            {"@ALL"}
                                                        </label>

                                                    </div>
                                                </div>
                                            </div>

                                            <div className="chat-leftsidebar-nav">
                                                <Nav pills justified>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === '1' })}
                                                            onClick={() => { toggleTab('1'); }}
                                                        >
                                                            <i className="bx bx-chat font-size-20 d-sm-none"></i>
                                                            <span className="d-none d-sm-block">Classes</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === '2' })}
                                                            onClick={() => { toggleTab('2'); }}
                                                        >
                                                            <i className="bx bx-group font-size-20 d-sm-none"></i>
                                                            <span className="d-none d-sm-block">Groups</span>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink
                                                            className={classnames({ active: activeTab === '3' })}
                                                            onClick={() => { toggleTab('3'); }}
                                                        >
                                                            <i className="bx bx-book-content font-size-20 d-sm-none"></i>
                                                            <span className="d-none d-sm-block">Teachers</span>
                                                        </NavLink>
                                                    </NavItem>
                                                </Nav>
                                                <TabContent activeTab={activeTab} className="py-4">
                                                    <TabPane tabId="1">
                                                        <div>
                                                            <ul className="list-unstyled chat-list">
                                                                <PerfectScrollbar style={{ height: scrollViewHeight }}>
                                                                    {
                                                                        getChatRowsUI(classList)
                                                                    }
                                                                </PerfectScrollbar>
                                                            </ul>
                                                        </div>
                                                    </TabPane>

                                                    <TabPane tabId="2">
                                                        <ul className="list-unstyled chat-list">
                                                            <PerfectScrollbar style={{ height: scrollViewHeight }}>
                                                                {getChatRowsUI(groupList)}
                                                            </PerfectScrollbar>
                                                        </ul>
                                                    </TabPane>

                                                    <TabPane tabId="3">

                                                        <div>
                                                            <div className="form-check py-4">
                                                                <div className="d-flex">
                                                                    <input
                                                                        className="form-check-input"
                                                                        type="radio"
                                                                        id={"radioButton" + "messagingallTeachers"}
                                                                        checked={isAllTeachersSelected}
                                                                        onChange={() => {
                                                                            setIsAllTeachersSelected(!isAllTeachersSelected)
                                                                            UserChatOpen(false, !isAllTeachersSelected, schoolId, schoolId)
                                                                        }}
                                                                    />
                                                                    <div className="float-right">
                                                                        <label
                                                                            className="form-check-label"
                                                                            htmlFor={"radioButton" + "messagingallTeachers"}
                                                                        >
                                                                            {"@ALL"}
                                                                        </label>

                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </div>

                                                    </TabPane>
                                                </TabContent>
                                            </div>
                                        </div>
                                    </div>}
                                <div className="w-100 user-chat">
                                    <Card>
                                        <div className="p-4 border-bottom ">
                                            <Row>
                                                {Chat_Box_Username &&
                                                    <Col className='col-auto'>
                                                        <h5 className="font-size-15 mb-1">{`${Chat_Box_Username}`}</h5>
                                                    </Col>
                                                }
                                                {(schoolType == 10) ?
                                                    <Col>
                                                        {(Chat_Box_Object && selectedKid) ?
                                                            <label style={{ fontSize: "3mm" }} className=" mb-1">{`( ${Chat_Box_Object.course.name})`}</label> :
                                                            <label style={{ fontSize: "3mm" }} className=" mb-1">{`Please select paricipant by selcting course`}</label>
                                                        }
                                                    </Col> :
                                                    <Col>
                                                        <label style={{ fontSize: "3mm" }} className=" mb-1">{`(On sending messages here it will get delivered to whole ${Chat_Box_Username})`}</label>
                                                    </Col>}
                                            </Row>
                                        </div>

                                        {(schoolType == 10 && !selectedKid) ? <div></div> : <div>
                                            <div className="chat-conversation pt-3 pr-3 pl-3">
                                                <ul className="list-unstyled">

                                                    {!props.noMessagesToShow ? <PerfectScrollbar style={{ height: "500px" }}>

                                                        <>
                                                            <button className='btn btn-primary btn-sm float-right' onClick={() => {

                                                                if (schoolType == 10) {
                                                                    props.getOldMessagesForCourseKid(Chat_Box_Object.courseId, Chat_Box_Object.kidId, messages[0].updatedAt)
                                                                } else
                                                                    if (isAllSchoolSelected) {
                                                                        props.getOldMessagesForSchoolId(Chat_Box_Object.objectId, messages[0].updatedAt)
                                                                    } else if (isAllTeachersSelected) {
                                                                        props.getOldMessagesForAllTeachers(Chat_Box_Object.objectId, messages[0].updatedAt)
                                                                    } else {
                                                                        if (activeTab === "1") {
                                                                            props.getOldMessagesForClassId(Chat_Box_Object.objectId, messages[0].updatedAt)
                                                                        } else if (activeTab === "2") {
                                                                            props.getOldMessagesForGroupId(Chat_Box_Object.objectId, messages[0].updatedAt)
                                                                        }
                                                                    }


                                                            }}>Load previous messages</button>
                                                            {arrayOfDatesForSection && arrayOfDatesForSection.map((item, key) => (
                                                                <div key={key}>

                                                                    <li >
                                                                        <div className="chat-day-title">
                                                                            <span className="title">{convertDateToString(item)}</span>
                                                                        </div>
                                                                    </li>
                                                                    {
                                                                        messagesMultiDimensionalArray[key].map((message, key) => (
                                                                            getCellUI(message, key)
                                                                        )
                                                                        )
                                                                    }
                                                                </div>
                                                            ))
                                                            }</>

                                                    </PerfectScrollbar> : <div style={{ height: "470px" }}>
                                                        <center><label className='mt-5'>No Messages to Show</label></center>
                                                    </div>}

                                                    <div className='text-center' style={{ height: "30px" }}>
                                                        {props.isLoadingMessages && <><Spinner type="grow" className="mr-2 ml-5 spinner-grow-sm" color='primary' />
                                                            <Spinner type="grow" className="mr-2 spinner-grow-sm" color="primary" />
                                                            <Spinner type="grow" className="mr-2 spinner-grow-sm" color="primary" />
                                                            <Spinner type="grow" className="mr-2 spinner-grow-sm" color="primary" />
                                                        </>}
                                                        {progressText ? <label style={{ fontSize: "3mm" }} className=" mb-1">{progressText}<i className='align-middle font-size-16 ml-2 text-dark bx bx-loader bx-spin'></i></label> :
                                                            <>
                                                                {albumImageList && albumImageList.length > 0 &&
                                                                    <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                                                                        {`${albumImageList.length} images attached`}
                                                                        <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                                                                            setAlbumImageList([])
                                                                        }}></i>
                                                                    </div>
                                                                }
                                                                {albumVideoList && albumVideoList.length > 0 &&
                                                                    <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                                                                        {`${albumVideoList.length} video attached`}
                                                                        <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                                                                            setAlbumVideoList([])
                                                                        }}></i>
                                                                    </div>
                                                                }
                                                                {documentParseFile &&
                                                                    <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                                                                        {`${1} document attached`}
                                                                        <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                                                                            setDocumentParseFile()
                                                                        }}></i>
                                                                    </div>
                                                                }
                                                            </>}
                                                        {arrayOfDatesForSection && arrayOfDatesForSection.length > 0 && <Link
                                                            to="#"
                                                            className="float-right"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                executeScroll()
                                                                if (schoolType == 10) {
                                                                    props.getLatestMessagesForCourseKid(Chat_Box_Object.courseId, Chat_Box_Object.kidId, messages[messages.length - 1].updatedAt)
                                                                } else if (isAllSchoolSelected) {
                                                                    props.getLatestMessagesForSchoolId(Chat_Box_Object.objectId, messages[messages.length - 1].updatedAt)

                                                                } else if (isAllTeachersSelected) {
                                                                    props.getLatestMessagesForAllTeachers(Chat_Box_Object.objectId, messages[messages.length - 1].updatedAt)

                                                                } else {
                                                                    if (activeTab === "1") {
                                                                        props.getLatestMessagesForClassId(Chat_Box_Object.objectId, messages[messages.length - 1].updatedAt)
                                                                    } else if (activeTab === "2") {
                                                                        props.getLatestMessagesForGroupId(Chat_Box_Object.objectId, messages[messages.length - 1].updatedAt)
                                                                    } else if (activeTab === "3") {
                                                                    }
                                                                }
                                                            }}
                                                            id="messagesReloadButton"
                                                        >
                                                            <i style={{ fontSize: "30px" }} className="bx bx-refresh text-primary"></i>
                                                            <UncontrolledTooltip
                                                                placement="top"
                                                                target={"messagesReloadButton"}
                                                            >
                                                                {props.t("Refresh Messages")}
                                                            </UncontrolledTooltip>
                                                        </Link>}
                                                    </div>


                                                </ul>
                                            </div>
                                            <div className="p-3 chat-input-section">
                                                <Row>
                                                    <Col>
                                                        <div className="position-relative">

                                                            <input type="text" value={messageText} onChange={(e) => {
                                                                let msg = e.target.value
                                                                // if (selectedMessageType === Constants.ALERT) {
                                                                //     if (msg.length > 320) {
                                                                //         setExceededSMSLimit(true)
                                                                //     } else {
                                                                //         setExceededSMSLimit(false)
                                                                //     }
                                                                // }
                                                                setMessageText(msg)
                                                            }}
                                                                className="form-control chat-input-shivam" style={{ border: "3px solid #e37171" }} placeholder="Enter Message..." />
                                                            <MessageTypeDropdown
                                                                messageType={selectedMessageType}
                                                                schoolType={schoolType}
                                                                onChangeMessageType={(type) => { setselectedMessageType(type) }}
                                                            >
                                                            </MessageTypeDropdown>
                                                            {schoolType != 10 && <div className="chat-input-links">
                                                                <ul className="list-inline mb-0">
                                                                    {/* <li className="list-inline-item">
                                                                        <Link to="#">
                                                                            <i className="mdi mdi-emoticon-happy-outline" id="Emojitooltip"></i>
                                                                            <UncontrolledTooltip placement="top" target="Emojitooltip">
                                                                                Emojis
                                                                            </UncontrolledTooltip >
                                                                        </Link>
                                                                    </li> */}
                                                                    <li className="list-inline-item">

                                                                        <Label className="text-primary waves-effect mt-3" style={{ cursor: 'pointer' }} htmlFor='upload-images-messages'><i className="font-size-18 mdi mdi-file-image" id="upload-images-messages-tooltip"></i></Label>
                                                                        <UncontrolledTooltip placement="top" target="upload-images-messages-tooltip">
                                                                            Add Images
                                                                        </UncontrolledTooltip >


                                                                        <input type="file"
                                                                            name="upload-images-messages"
                                                                            className="sr-only"
                                                                            id="upload-images-messages"
                                                                            accept="image/*"
                                                                            onChange={(e) => {
                                                                                clearAllAttachments()
                                                                                handleImageUploadChange(e)
                                                                                let tempInput = document.getElementById("upload-images-messages").value = null
                                                                            }}
                                                                            multiple
                                                                        />
                                                                    </li>
                                                                    <li className="list-inline-item">

                                                                        <Label className="text-primary waves-effect mt-3" style={{ cursor: 'pointer' }} htmlFor='upload-files-messages'><i className="font-size-18 mdi mdi-file-document" id="upload-files-messages-tooltip"></i></Label>
                                                                        <UncontrolledTooltip placement="top" target="upload-files-messages-tooltip">
                                                                            Add Files
                                                                        </UncontrolledTooltip >
                                                                        <input type="file"
                                                                            className="sr-only"
                                                                            name="upload-files-messages"
                                                                            id="upload-files-messages"
                                                                            accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,/*"
                                                                            onChange={(e) => {
                                                                                clearAllAttachments()
                                                                                handleFileUploadChange(e)
                                                                                let tempInput = document.getElementById("upload-files-messages").value = null
                                                                            }}
                                                                        />
                                                                    </li>
                                                                    <li className="list-inline-item">

                                                                        <Label className="text-primary waves-effect mt-3" style={{ cursor: 'pointer' }} htmlFor='upload-video-messages'><i className="font-size-18 mdi mdi-file-video" id="upload-video-messages-tooltip"></i></Label>
                                                                        <UncontrolledTooltip placement="top" target="upload-video-messages-tooltip">
                                                                            Add Video
                                                                        </UncontrolledTooltip >
                                                                        <input type="file"
                                                                            className="sr-only"
                                                                            name="upload-video-messages"
                                                                            id="upload-video-messages"
                                                                            accept="video/*"
                                                                            onChange={(e) => {
                                                                                clearAllAttachments()
                                                                                handleVideoUploadChange(e)
                                                                                e.target.value = null
                                                                            }}
                                                                        />
                                                                    </li>

                                                                </ul>
                                                            </div>}
                                                        </div>
                                                    </Col>
                                                    <Col className="col-auto">
                                                        <Button id="sendMessageBtn" type="button" color="primary" onClick={() => {
                                                            if (progressText && progressText.length > 0) {

                                                            } else {
                                                                onPostMessage()
                                                            }
                                                        }} className="btn-rounded chat-send w-md waves-effect waves-light"><span className="d-none d-sm-inline-block mr-2">Send</span> <i className="mdi mdi-send"></i></Button>
                                                        {progressText && <UncontrolledTooltip placement="top" target="sendMessageBtn">
                                                            Upload is in progress
                                                        </UncontrolledTooltip >}
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>}
                                    </Card>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
            <DocumentViewerModal
                clickedFile={clickedFile}
                closeModal={() => {
                    setClickedFile()
                }}
            ></DocumentViewerModal>

            {getMessageStatusUI()}

        </React.Fragment>

    );
}

Date.prototype.withoutTime = function () {
    var d = new Date(this);
    d.setHours(0, 0, 0, 0);
    return d;
}


const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { groups } = state.Group;
    const { messages, noMessagesToShow, isLoadingMessages, isOldMessages, albums } = state.Message;
    const { teachers } = state.Teacher;
    const { userTeacher } = state.Login;
    const { courses } = state.Course;
    const { courseSubscriptions, initialLoding } = state.CourseSubscription;
    const { kids } = state.Kid;
    return {
        selectedSchool,
        classrooms,
        groups,
        messages, noMessagesToShow, isLoadingMessages, isOldMessages, albums,
        teachers,
        userTeacher,
        courses,
        courseSubscriptions, initialLoding,
        kids
    }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getClassroomsForSchoolId,
    getGroupsForSchoolId,
    getMessagesForClassId,
    getMessagesForGroupId,
    getMessagesForSchoolId,
    directAssignMessages,
    getLatestMessagesForClassId,
    getOldMessagesForClassId,
    getLatestMessagesForGroupId,
    getOldMessagesForGroupId,
    getLatestMessagesForSchoolId,
    getOldMessagesForSchoolId,
    getTeachersForSchool,
    getMessagesForAllTeachers,
    getLatestMessagesForAllTeachers,
    getOldMessagesForAllTeachers,
    updateMessages,
    getAlbumsForAlbumIds,
    updateAlbums,
    getUserTeacher,
    getCoursesForOwnerIds,
    getAllKids,
    getCoureseSubscriptions,
    getMessagesForCourseKid,
    getLatestMessagesForCourseKid,
    getOldMessagesForCourseKid,
})(Chat)));
