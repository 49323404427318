import Parse from "parse";
import { printLog } from "./loghelper";

export const getnotifactionForUser = userId =>{
    printLog("HL: notifaction_helper : getnotifactionForUser")

    try{
        return new Promise((resolve, reject)=>{
            let query = new Parse.Query('Notifications')
            query.equalTo('status', 0)
            query.find().then((results)=>{
                let notifications = results
                let tempNotify = []
                for(const val of notifications){
                    tempNotify.push(val.id)
                }
                let query = new Parse.Query('NotificationReadStatus')
                query.containedIn('notificationId', tempNotify)
                query.equalTo('userId', Parse.User.current().id)
                query.find().then((results)=>{
                    resolve({notifications : notifications, notificationReadStatus: results})
                })
            })
        })
    }catch(err){
        console.error(err)
    }
}

export const updateNotificationStatus = id =>{
    printLog("HL: notifaction_helper : updateNotificationStatus")
    try{
        return new Promise((resolve, reject)=>{
            let NotificationReadStatus = Parse.Object.extend('NotificationReadStatus')
            let readnotifaction = new NotificationReadStatus()
            readnotifaction.set('userId', Parse.User.current().id)
            readnotifaction.set('notificationId', id)
            readnotifaction.save().then((result)=>{
                resolve(result)
            })
        })
    }catch(err){
        console.error(err)
    }
}