import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil"

const initialState = {
  kids: [],
  selectedKid: "",
  pickAndDropObj: [],
  vaccinationDetails: [],
  admissionKids: [],
  admissionKidsLoasState: 1,
  attendanceDetails: [],
  daycareAttendanceDetails: {},
  deletedKids: undefined,
  updatedKidSuccess: false,
  offShowLoader: false,
  kidCAD: undefined,
  kidsDeletedSuccessfully: false,
  allKidsForSchool: [],
  deletekidLoading: false,
  isLoading: true,
  isAdmissionLinkSuccess: false,
  allDeletedKids: [],
  kidReport: false,
  studentUser: undefined,
  studentUserLoading: false,
  escorts: [],
  kidMedicalObjects: [],
};

const Kid = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_ALL_KIDS_FOR_GROUPS:
      var kids = [...state.kids];
      if (kids.length > 0) {
        for (const latest in action.payload.kids) {
          var temp = kids.filter(
            (kid) => kid.id === action.payload.kids[latest].id
          );
          if (temp.length > 0) {
            var index = kids.lastIndexOf(temp[0]);
            kids[index] = action.payload.kids[latest];
          } else {
            kids.push(action.payload.kids[latest]);
          }
        }
        state = { ...state, kids: kids };
      } else {
        state = { ...state, kids: action.payload.kids };
      }

      break;

    case actions.GET_ALL_KIDS:
      state = { ...state, kidReport: false };
      break;

    case actions.CLEAR_ALL_KIDS:
      state = { ...state, kids: [] }
      break;

    case actions.UPDATE_ALL_KIDS:
      // var kids = [...state.kids]
      // if (kids.length > 0) {
      //     for (const latest in action.payload.kids) {
      //         var temp = kids.filter(kid => kid.id === action.payload.kids[latest].id)
      //         if (temp.length > 0) {
      //             var index = kids.lastIndexOf(temp[0]);
      //             kids[index] = action.payload.kids[latest];
      //         } else {
      //             kids.push(action.payload.kids[latest]);
      //         }
      //     }
      //     state = { ...state, kids: kids, offShowLoader : true, updatedKidSuccess : false };
      // } else {
      //     state = { ...state, kids: action.payload.kids, offShowLoader : true, updatedKidSuccess : false };
      // }

      var kids = [...state.kids];
      if (kids.length > 0) {
        if (action.payload.requestedDeleted && false) {
          kids = kids.concat(action.payload.kids);
        } else {
          for (const latest in action.payload.kids) {
            var temp = kids.filter(
              (kid) => kid.id === action.payload.kids[latest].id
            );

            if (temp.length > 0) {
              if (action.payload.kids[latest].attributes.Deleted) {
                var others = kids.filter(
                  (kid) => kid.id !== action.payload.kids[latest].id
                );

                kids = others;
              } else {
                var index = kids.lastIndexOf(temp[0]);
                kids[index] = action.payload.kids[latest];
              }
            } else {
              //deleted kids are discrding so in triansaction deleted kids are not getting vesible need to remove this logic.
              // false ||
              if (action.payload.kids[latest].attributes.Deleted !== true) {
                kids.push(action.payload.kids[latest]);
              }
            }
          }
        }
        state = {
          ...state,
          kids: kids,
          offShowLoader: true,
          updatedKidSuccess: false,
          kidReport: true,
        };
      } else {
        var i = 0;
        var groupsToSave = [];
        for (i = 0; i < action.payload.kids.length; i++) {
          if (action.payload.kids[i].attributes.Deleted !== true) {
            groupsToSave.push(action.payload.kids[i]);
          }
        }

        state = {
          ...state,
          kids: groupsToSave,
          offShowLoader: true,
          updatedKidSuccess: false,
          kidReport: true,
        };
      }

      break;
    case actions.SELECTED_A_KID_DETAILS:
      state = { ...state, selectedKid: action.payload.kidObj };
      break;
    case actions.SET_CAD_FOR_A_KID:
      state = { ...state, kidCAD: action.payload.cadObj };
      break;
    case actions.SET_MEDICAL_DETAILS_FOR_KID:
      state = { ...state, kidMedicalObj: action.payload.medObj };
      break;
    case actions.SET_MEDICAL_DETAILS_FOR_KID_IDS:
      state = { ...state, kidMedicalObjects: action.payload.medObjects };
      break;
    case actions.GET_PICK_AND_DROP_DETAL_FOR_KID:
      state = { ...state, pickAndDropObj: [] };
      break;
    case actions.SET_PICK_AND_DROP_DETAL_FOR_KID:
      let updatedList = ReduxUtil.updateParseObjects([...state.pickAndDropObj], action.payload.escortObjs, "Deleted")
      state = { ...state, pickAndDropObj: updatedList };
      //state = { ...state, pickAndDropObj: action.payload.escortObjs };
      break;
    case actions.SET_VACCINE_DETAL_FOR_KID:
      state = { ...state, vaccinationDetails: action.payload.vaccineObjs };
      break;
    case actions.SET_ATTENDANCE_FOR_KID:
      state = { ...state, attendanceDetails: action.payload.attObjs };
      break;
    case actions.SET_ATTENDANCE_FOR_KID_DAYCARE:
      state = {
        ...state,
        daycareAttendanceDetails: action.payload.attobjs
          ? action.payload.attobjs
          : [],
      };
      break;
    case actions.DELETE_KID_LOADING:
      state = { ...state, deletekidLoading: action.payload.state };
      break;



    case actions.UPDATE_ADMISSION_KIDS:
      var admissionKids = [...state.admissionKids];
      if (admissionKids.length > 0) {
        for (const latest in action.payload.admissionKids) {
          var temp = admissionKids.filter(
            (kid) => kid.id === action.payload.admissionKids[latest].id
          );
          if (temp.length > 0) {
            if (
              (temp[0].attributes.SchoolID &&
                temp[0].attributes.SchoolID.length > 0) ||
              temp[0].attributes.Deleted
            ) {
              var otherKids = admissionKids.filter(
                (kid) => kid.id !== action.payload.admissionKids[latest].id
              );
              admissionKids = [...otherKids];
            } else {
              var index = admissionKids.lastIndexOf(temp[0]);
              admissionKids[index] = action.payload.admissionKids[latest];
            }
          } else {
            let tempKid = action.payload.admissionKids[latest];
            if (
              (!tempKid.attributes.SchoolID ||
                tempKid.attributes.SchoolID.length === 0) &&
              tempKid.attributes.schoolAdmitted &&
              tempKid.attributes.schoolAdmitted.length > 0 &&
              !tempKid.attributes.Deleted
            ) {
              admissionKids = [action.payload.admissionKids[latest]].concat(
                admissionKids
              );
            }
          }
        }
        state = {
          ...state,
          admissionKids: admissionKids,
          admissionKidsLoasState: action.payload.state,
        };
      } else {
        var otherKids = action.payload.admissionKids.filter(
          (kid) =>
            (!kid.attributes.SchoolID ||
              kid.attributes.SchoolID.length === 0) &&
            kid.attributes.schoolAdmitted &&
            kid.attributes.schoolAdmitted.length > 0 &&
            !kid.attributes.Deleted
        );

        state = {
          ...state,
          admissionKids: otherKids,
          admissionKidsLoasState: action.payload.state,
        };
      }

      break;
    case actions.UPDATE_REMOVED_KID:
      var admissionKids = [...state.admissionKids];
      if (admissionKids.length > 0) {
        var admissionKids = state.admissionKids.filter(
          (kid) => kid.id !== action.payload.kid.id
        );
        state = { ...state, admissionKids: admissionKids };
      }
      break;
    case actions.UPDATE_ALL_DELETED_KIDS:
      state = {
        ...state,
        deletedKids: action.payload.deletedKids,
        isLoading: false,
      };
      break;
    case actions.UPDATE_ALL_DELETED_KIDS_INITIAL:
      state = {
        ...state,
        deletedKids: action.payload.deletedKids,
        isLoading: true,
      };
      break;

    case actions.SET_UPDATED_SUCCESSFULLY:
      state = { ...state, updatedKidSuccess: true };
      break;
    case actions.KIDS_DELETED_SUCCESSFULLY:
      state = { ...state, kidsDeletedSuccessfully: action.payload.boolValue };
      break;
    case actions.SET_KIDS_FOR_SCHOOL:
      state = { ...state, allKidsForSchool: action.payload.serverObj };
      break;
    case actions.UPDATE_KID_TOSTER_SUCCESS:
      state = { ...state, updatedKidSuccess: false };
      break;

    case actions.SET_ALL_DELETED_KIDS_FOR_SCHOOL:
      state = { ...state, allDeletedKids: action.payload.kidObjs };

      var kids = [...state.allDeletedKids];
      if (kids && kids.length > 0) {
        for (const latest in action.payload.kidObjs) {
          var temp = kids.filter(
            (kid) => kid.id === action.payload.kidObjs[latest].id
          );

          if (temp.length > 0) {
            if (!action.payload.kidObjs[latest].attributes.Deleted) {
              var others = kids.filter(
                (kid) => kid.id !== action.payload.kidObjs[latest].id
              );

              kids = others;
            } else {
              var index = kids.lastIndexOf(temp[0]);
              kids[index] = action.payload.kidObjs[latest];
            }
          } else {

            if (action.payload.kidObjs[latest].attributes.Deleted) {
              kids.push(action.payload.kidObjs[latest]);
            }
          }
        }
        state = { ...state, allDeletedKids: kids };
      } else {
        state = { ...state, allDeletedKids: action.payload.kidObjs };
      }

      break;
    case actions.UPDATE_STUDENT_USER:
      state = { ...state, studentUser: action.payload.user, studentUserLoading: action.payload.loading };
      break;

    case actions.UPDATE_ESCORTS:
      var escorts = [...state.escorts]
      if (escorts.length > 0) {
        for (const latest in action.payload.escorts) {
          var temp = escorts.filter(escort => escort.id === action.payload.escorts[latest].id)
          if (temp.length > 0) {
            var index = escorts.lastIndexOf(temp[0]);
            escorts[index] = action.payload.escorts[latest];
          } else {
            escorts.push(action.payload.escorts[latest]);
          }
        }
        state = { ...state, escorts: escorts };
      } else {
        state = { ...state, escorts: action.payload.escorts };
      }

      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Kid;
