import ReactApexChart from 'react-apexcharts';
const Spinearea = (props) => {

    const getRate = () => {
        let list = [];
        if (props.selectedMonths) {
            for (const i in props.selectedMonths) {
                let mon = props.selectedMonths[i];
                if (i == 0) {
                    let rt = getRateOfIncrice(0, mon.admissionCount ? mon.admissionCount : 0);
                    list.push(rt);
                } else {
                    let lm = props.selectedMonths[i - 1];
                    let rt = getRateOfIncrice(lm.admissionCount ? lm.admissionCount : 0, mon.admissionCount ? mon.admissionCount : 0);
                    list.push(rt)
                }
               
                
            }
        }
        // console.log(list)
        return list;
    }

    const getFeeRate = () => {
        let list = [];
        if (props.selectedMonths) {
            for (const i in props.selectedMonths) {
                let mon = props.selectedMonths[i];
                if (i == 0) {
                    let rt = getRateOfIncrice(0, mon.transactionAmount ? mon.transactionAmount : 0);
                    list.push(rt);
                } else {
                    let lm = props.selectedMonths[i - 1];
                    let rt = getRateOfIncrice(lm.transactionAmount ? lm.transactionAmount : 0, mon.transactionAmount ? mon.transactionAmount : 0);
                    list.push(rt)
                }
               
                
            }
        }
        // console.log(list)
        return list;
    }


    const getRateOfIncrice = (oldNumber, newNumber) => {
        if (oldNumber == 0) {
            return newNumber;
        }
        if (oldNumber > 0) {
            var decreaseValue = oldNumber >newNumber? oldNumber -newNumber:newNumber -oldNumber;
            let rate = (decreaseValue / oldNumber) * 100;
            return oldNumber >newNumber ? -1*rate.toFixed(2):rate.toFixed(2);
        } else {
            return 0;
        } 
    }

    const getMonths = () => {
        let list = [];
        if (props.selectedMonths) {
            for (const mon of props.selectedMonths) {
                list.push(mon.date+"")
            }
        }
        // console.log(list)
        return list;
    }

    const series = [{
        name: 'Conversion Trend ',
        data: getRate()
    }];

    const series2 = [{
        name: 'Collection Trend ',
        data: getFeeRate()
    }];

    const options = {
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 3,
        },
                
        colors: ['#556ee6', '#34c38f'],
        xaxis: {
            type: 'datetime',
            categories: getMonths(),
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        tooltip: {
            x: {
                format: 'MMM yyyy'
            }
        },
        chart: {
          id:"admissionGrowth"
        }
    };

    return (
        <ReactApexChart options={options} series={props.type==="fee"?series2:series} type="area" height="300" />
    );
}

export default Spinearea;