import React, { useEffect, useState } from 'react';

import { getTeachersForSchool, getClassroomsForSchoolId, sendInvite, setSelecetdSchool } from '../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import {
    Container, Row, Col, UncontrolledTooltip,
    UncontrolledAlert,
    Label,
    Button,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
} from "reactstrap";
import { matchPath } from "react-router";
//i18n
import { withNamespaces } from 'react-i18next';
import TeacherCard from './TeacherCard';
import TecaherList from './TecaherList';
import Topbar from './Topbar';
import error from "../../assets/images/error-img.png";
import * as Constants from '../../Constents';
import Parse from "parse";

import img1 from "../../assets/images/covidDashboardIcons/img1.svg";
import img2 from "../../assets/images/covidDashboardIcons/img2.svg";
import img3 from "../../assets/images/covidDashboardIcons/img3.svg";
import img4 from "../../assets/images/covidDashboardIcons/img4.svg";
import img5 from "../../assets/images/covidDashboardIcons/img5.svg";

import Switch from "react-switch";

const Index = (props) => {

    const vaccination = "vaccination"
    const socialDistance = "socialDistance"
    const mask = "mask"
    const temperatureChecks = "temperatureChecks"
    const sanitisation = "sanitisation"


    const match = matchPath(props.history.location.pathname, {
        path: "/coviddashboard/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        getCovidDefaultSOPs()
        getDefaultMediaCategoryForCovidType()
    }, [props.selectedSchool, schoolId]);

    const [mainList, setMainList] = useState([])
    const [showEditPopup, setShowEditPopup] = useState(false)
    const [descriptionForSOP, setDescriptionForSOP] = useState("")
    const [selectedRecordForEdit, setSelectedRecordForEdit] = useState(undefined)
    const [savingRecordObject, setSavingRecordObject] = useState(undefined)
    const [defaultMediaCategory, setDefaultMediaCategory] = useState(undefined)
    const [schoolMediaCategory, setSchoolMediaCategory] = useState(undefined)
    const [isLoading, setIsLoading] = useState(true)
    const [isMediaCategoryQueryFinished, setIsMediaCategoryQueryFinished] = useState(undefined)
    const [isEnablingFirstSOP, setIsEnablingFirstSOP] = useState(false)
    const [isMediaCategoryEnable, setIsMediaCategoryEnable] = useState(false)

    const getDefaultMediaCategoryForCovidType = () => {
        var query = new Parse.Query("MediaCategories");
        query.equalTo("cardType", "COVID")
        query.equalTo("targetAudienceId", "COVIDSOP")
        query.limit(1000);
        query.find().then((results) => {
            if (results && results.length > 0) {

                setDefaultMediaCategory(results[0])

            }
            getMediaCategoryForCovidTypeForSchoolId()
        })
    }

    const getMediaCategoryForCovidTypeForSchoolId = () => {
        var query = new Parse.Query("MediaCategories");
        query.equalTo("cardType", "COVID")
        query.equalTo("targetAudienceId", schoolId)
        query.limit(1000);
        query.find().then((results) => {
            if (results && results.length > 0) {
                setSchoolMediaCategory(results[0])
            }
            setIsMediaCategoryQueryFinished(true)
        })
    }

    useEffect(() => {

        if (isMediaCategoryQueryFinished != undefined && mainList.length > 0) {
            setIsLoading(false)
        }

    }, [isMediaCategoryQueryFinished, mainList])

    const firstDose = "firstDose"
    const secondDose = "secondDose"

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    const getCovidDefaultSOPs = () => {
        var query = new Parse.Query("CovidSOPs");
        query.equalTo("schoolId", "default")
        query.limit(1000);
        query.find().then((results) => {
            if (results && results.length > 0) {
                let tempList = []
                for (const i in results) {
                    let tempObject = {

                        img: getDisplayImage(results[i].attributes.type),
                        detailText: results[i].attributes.detailText,
                        parseObject: results[i],
                        type: results[i].attributes.type,
                        title: results[i].attributes.title,
                        isEnabled: false,
                    }
                    tempList.push(tempObject)
                }
                //setMainList(tempList)
                getCovidSOPsForSchoolId(schoolId, tempList)
            }
        })
    }
    const getDisplayImage = (type) => {
        if (type === vaccination) {
            return img1;
        } else if (type === sanitisation) {
            return img2;
        } else if (type === mask) {
            return img3;
        } else if (type === temperatureChecks) {
            return img4;
        } else if (type === socialDistance) {
            return img5;
        }
        return
    }

    const getVaccineMessage = (covidSOP) => {
        let returnMessage = "0% Staff Vaccinated"
        if (covidSOP) {
            let tempCustomData = covidSOP.parseObject.attributes.CustomData
            if (tempCustomData) {
                if (tempCustomData["secondDose"] && tempCustomData["totalTeacherCount"] && tempCustomData["totalTeacherCount"] === tempCustomData["secondDose"]) {
                    returnMessage = "100% Vaccinated"
                } else {
                    if (tempCustomData["firstDose"] != undefined && tempCustomData["secondDose"] != undefined && tempCustomData["totalTeacherCount"] != undefined) {
                        let tempFirstDose = tempCustomData["firstDose"] * 100
                        let firstDosePercent = tempFirstDose / tempCustomData["totalTeacherCount"]

                        let tempSecondDose = tempCustomData["secondDose"] * 100
                        let secondDosePercent = (tempSecondDose / tempCustomData["totalTeacherCount"])

                        returnMessage = `${firstDosePercent.toFixed()}% Staff vaccinated with First Dose and ${secondDosePercent.toFixed()}% Staff vaccinated with Second Dose`
                    }

                }
            }
        }
        return returnMessage
    }

    const getCovidSOPsForSchoolId = (schoolId, main) => {
        var query = new Parse.Query("CovidSOPs");
        query.equalTo("schoolId", schoolId)
        query.limit(1000);
        query.find().then((results) => {
            let tempList = [...main]
            if (results && results.length > 0) {
                setIsEnablingFirstSOP(false)

                for (const i in results) {
                    let list = main.filter((sop) =>
                        sop.type === results[i].attributes.type
                    )
                    if (list.length > 0) {
                        let index = main.indexOf(list[0])
                        tempList[index].parseObject = results[i]
                        tempList[index].detailText = results[i].attributes.detailText
                        tempList[index].title = results[i].attributes.title
                        tempList[index].isEnabled = (results[i].attributes.isDeleted === false) ? true : false
                        if (list[0].type === vaccination) {
                            tempList[index].vaccineMessage = getVaccineMessage(list[0])
                        }
                    }
                }
            } else {
                setIsEnablingFirstSOP(true)
            }
            setMainList(tempList)
        })
    }

    const handleOnEnableDisableSOP = (changedValue, record) => {
        if (record.parseObject.attributes.schoolId === "default") {

            let CovidSOPs = Parse.Object.extend("CovidSOPs");
            let covidSOP = new CovidSOPs();
            covidSOP.set("schoolId", schoolId);
            covidSOP.set("isDeleted", !changedValue)
            covidSOP.set("detailText", record.detailText ?? "")
            covidSOP.set("type", record.type);
            covidSOP.set("title", record.title)
            covidSOP.save().then((result) => {
                if (isEnablingFirstSOP && defaultMediaCategory) {
                    setIsEnablingFirstSOP(false)

                    let MediaLink = Parse.Object.extend("MediaLink");
                    let mediaLink = new MediaLink();
                    mediaLink.set("title", defaultMediaCategory.title);
                    mediaLink.set("mediaType", "URL")
                    mediaLink.set("url", ("https://liltriangle-pages.web.app/" + schoolId));
                    mediaLink.save().then((result) => {




                        let MediaCategories = Parse.Object.extend("MediaCategories");
                        let mediaCategories = new MediaCategories();

                        mediaCategories.set("userType", defaultMediaCategory.attributes.userType);
                        mediaCategories.set("cardType", defaultMediaCategory.attributes.cardType);
                        mediaCategories.set("dataTableName", defaultMediaCategory.attributes.dataTableName);
                        mediaCategories.set("order", defaultMediaCategory.attributes.order);
                        mediaCategories.set("backgroundHex", defaultMediaCategory.attributes.backgroundHex);
                        mediaCategories.set("targetType", defaultMediaCategory.attributes.targetType);
                        mediaCategories.set("isDeleted", true);
                        mediaCategories.set("targetAudienceId", schoolId);
                        mediaCategories.set("mediaContentId", result.id);
                        mediaCategories.set("title", defaultMediaCategory.attributes.title);
                        mediaCategories.set("subTitle", defaultMediaCategory.attributes.subTitle)
                        mediaCategories.set("icon", defaultMediaCategory.attributes.icon)
                        mediaCategories.set("filters", defaultMediaCategory.attributes.filters)
                        mediaCategories.save().then((result) => {

                            setSchoolMediaCategory(result)

                        })
                    })
                }
                let tempList = [...mainList]
                let index = tempList.indexOf(record)
                tempList[index].parseObject = result
                tempList[index].isEnabled = result.attributes.isDeleted ? false : true
                setMainList(tempList)
            })
        } else {
            record.parseObject.set("isDeleted", !changedValue)
            record.parseObject.save().then((result) => {
                let tempList = [...mainList]
                let index = tempList.indexOf(record)
                tempList[index].parseObject = result
                tempList[index].isEnabled = result.attributes.isDeleted ? false : true
                setMainList(tempList)
            })
        }
    }

    const handleEditDescription = (text) => {
        if (selectedRecordForEdit && selectedRecordForEdit.parseObject) {
            let objectToSave = selectedRecordForEdit.parseObject
            objectToSave.set("detailText", text)
            setSavingRecordObject(true)
            objectToSave.save().then((result) => {


                let tempList = [...mainList]
                let index = tempList.indexOf(selectedRecordForEdit)
                tempList[index].detailText = result.attributes.detailText ?? ""
                tempList[index].parseObject = result
                setMainList(tempList)


                setShowEditPopup(false)
                setSavingRecordObject(false)
            })
        }

    }

    const shouldShowEditButton = (record) => {
        let returnValue = false
        if (record.parseObject && record.parseObject.attributes.schoolId !== "default") {
            returnValue = true
        }
        return returnValue
    }

    useEffect(() => {

        if (schoolMediaCategory && schoolMediaCategory.attributes.isDeleted === false) {
            setIsMediaCategoryEnable(true)
        } else {
            setIsMediaCategoryEnable(false)
        }

    }, [schoolMediaCategory])

    const handleEnableDisableMediaCategory = () => {
        let tempParseObject = schoolMediaCategory
        tempParseObject.set("isDeleted", !schoolMediaCategory.attributes.isDeleted)
        tempParseObject.save().then((tempParse) => {
            setSchoolMediaCategory(undefined)
            setSchoolMediaCategory(tempParse)
        })
    }

    const isEnabledMediaCategories = () => {
        let returnValue = false
        if (schoolMediaCategory && schoolMediaCategory.attributes.isDeleted === false) {
            returnValue = true
        }
        return returnValue
    }

    return (

        <React.Fragment>
            <Modal isOpen={showEditPopup}>
                <ModalHeader>Edit Description</ModalHeader>
                <ModalBody>
                    <textarea id="input-Description-ForSOP"
                        className="form-control"
                        rows="5"
                        value={descriptionForSOP}
                        placeholder="Type here"
                        onChange={(e) => setDescriptionForSOP(e.target.value)}
                    ></textarea>

                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-secondary" onClick={() => {
                        setShowEditPopup(false)
                    }}>
                        Cancel
                    </button>
                    <button className="btn btn-primary" disabled={savingRecordObject} onClick={() => {
                        handleEditDescription(descriptionForSOP)
                    }}>
                        {savingRecordObject && (
                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                        )}
                        Save
                    </button>
                </ModalFooter>
            </Modal>

            <div className="page-content">
                {!isLoading && <Container fluid>
                    <Topbar title={props.t('Covid Dashboard')} schoolId={schoolId} hideButton={true} backButton={true} onClickBackButton={() => { window.history.back(); }} />
                    <Row>
                        <Col>

                            <div className="list-group list-group-flush mt-0">
                                <div className="list-group-item text-muted py-3 px-2 mb-0 mt-0" style={{
                                    border: "1px solid #e9e9e9", borderRadius: "15px"

                                }}>

                                    <h5 className="pl-2">Covid Dashboard helps you to display the SOPS and measures you are taking at the school, this will boost the parent confidence </h5>
                                    {schoolMediaCategory &&
                                        <div className="media align-items-center">
                                            <div className="ml-5 mt-2">
                                                <img
                                                    src={(schoolMediaCategory && schoolMediaCategory.attributes.icon._url) ?? ""}
                                                    alt="Safety Icon"
                                                    width="150px"
                                                    height="150px"
                                                    className="float-right"
                                                />
                                            </div>

                                            <div className="ml-5 mt-2">
                                                <h5 className="font-size-18">
                                                    Inform your Parents
                                                </h5>
                                                <p className="mb-0">
                                                    {"On Enabling this, a story will be created in lilTriangle app about all the SOP's schools following to fight against covid"}
                                                </p>
                                                <div className="d-flex mr-4 mt-3 " id={"switchForMediaCategory"} style={{ maxWidth: "150px" }}>
                                                    <Label className="mb-1 align-middle">Disable</Label>
                                                    <Switch
                                                        height={15}
                                                        width={25}
                                                        className="float-right pl-2 pr-2"
                                                        uncheckedIcon={<Offsymbol />}
                                                        checkedIcon={<OnSymbol />}
                                                        onColor={"#6add7d"}
                                                        onChange={() => {
                                                            handleEnableDisableMediaCategory()
                                                        }}
                                                        checked={isMediaCategoryEnable}
                                                    />
                                                    <UncontrolledTooltip placement="top" target={"switchForMediaCategory"}>
                                                        {props.t('Disable/Enable')}
                                                    </UncontrolledTooltip>
                                                    <Label className="float-right mb-1 align-middle">Enable</Label>


                                                </div>


                                            </div>

                                        </div>


                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>

                            <div className="list-group list-group-flush mt-4">

                                {
                                    mainList && mainList.map((record, key) => (
                                        <div className="list-group-item text-muted py-3 px-2 mb-3" key={key} style={record.isEnabled ? { minHeight: "200px", border: "2px solid #6add7d", borderRadius: "15px" } : {
                                            minHeight: "200px", border: "1px solid #e9e9e9", borderRadius: "15px"
                                        }}>
                                            <div className="media align-items-center">
                                                <div className="ml-5">
                                                    <img
                                                        src={record.img}
                                                        alt=""
                                                        width="150px"
                                                    />
                                                </div>
                                                <div className="ml-5">
                                                    <h1 className="font-size-18">
                                                        {record.title}
                                                    </h1>
                                                    <p className="mb-0">{record.detailText ?? "Description..."}</p>
                                                    {shouldShowEditButton(record) && <Row className="ml-0">

                                                        <button className="font-size-12 btn btn-sm btn-primary mt-2" onClick={
                                                            () => {
                                                                setSelectedRecordForEdit(record)
                                                                setDescriptionForSOP(record.detailText ?? "")
                                                                setShowEditPopup(true)
                                                            }
                                                        } ><i className="bx bx-pencil font-size-14 mr-1 align-middle" />Edit</button>

                                                    </Row>}
                                                    <div className="d-flex mt-3" id={"switchCovidSop" + key} style={{ maxWidth: "150px" }}>
                                                        <Label className="mb-1 align-middle">Disable</Label>
                                                        <Switch

                                                            height={15}
                                                            width={25}
                                                            className="float-right pl-2 pr-2"
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            onColor={record.isEnabled ? "#6add7d" : "#556ee6"}
                                                            onChange={() => {
                                                                handleOnEnableDisableSOP(!record.isEnabled, record)
                                                            }}
                                                            checked={record.isEnabled ?? false}
                                                        />
                                                        <UncontrolledTooltip placement="top" target={"switchCovidSop" + key}>
                                                            {props.t('Disable/Enable')}
                                                        </UncontrolledTooltip>
                                                        <Label className="float-right mb-1 align-middle">Enable</Label>


                                                    </div>
                                                    {record.type === vaccination && record.parseObject && record.parseObject.attributes.schoolId !== "default" && <Row className="ml-0">

                                                        <Link to={`/covidvaccine/${props.selectedSchool.id}/${record.parseObject.id}`} className="font-size-12 btn btn-sm btn-primary mt-2">Teacher Vaccine Status</Link>

                                                    </Row>}
                                                    {record.type === vaccination && record.parseObject && record.parseObject.attributes.schoolId !== "default" && record.vaccineMessage && <Row className="ml-0">

                                                        <p className="mt-3">{record.vaccineMessage}</p>

                                                    </Row>}

                                                </div>

                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>

                </Container>}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Covid Dashboard...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { teachers } = state.Teacher;
    const { schools, selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    return { teachers, schools, classrooms, selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getTeachersForSchool, getClassroomsForSchoolId, setSelecetdSchool, sendInvite })(Index)));