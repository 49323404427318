import { takeEvery, fork, put, all, call, take, takeLeading } from "redux-saga/effects";
import Parse from "parse";

import * as AcademicCycleHelper from "../../../helpers/ParseHelpers/academicCycles_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as AcademicCycleActions from "./actions";
import actions from "redux-form/lib/actions";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getAAcademicCycle({ payload: { id } }) {
  try {
    const response = yield call( AcademicCycleHelper.getAcademicCycleFromParse, id,false);
    yield put(AcademicCycleActions.updateAcademicCycle(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAAcademicCycle({ payload: { academicCycle } }) {
  try {
    const response = yield call(
        AcademicCycleHelper.deleteAcademicCycleFromParse,
        academicCycle
    );
    if (response) {
      yield put(AcademicCycleActions.updateAcademicCycle(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getAcademicCycleForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.ACADEMIC_CYCLE_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    yield put(AcademicCycleActions.setAcademicCycleLodingState("request"));
    const localResponse = yield call(AcademicCycleHelper.getAcademicCycleForSchoolId, schoolId, null, true);
    yield put(AcademicCycleActions.updateAcademicCycles(localResponse));
    yield put(AcademicCycleActions.setAcademicCycleLodingState("responce-local"));
    const serverResponse = yield call(AcademicCycleHelper.getAcademicCycleForSchoolId, schoolId, updatedTime, false);
    localStorage.setItem(key,newTime)
    yield put(AcademicCycleActions.setAcademicCycleLodingState("responce-server"));
    if (serverResponse && serverResponse.length > 0) {
        yield put(AcademicCycleActions.updateAcademicCycles(serverResponse));
        Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getAcademicCyclesForCurriculumId({ payload: { curriculumId } }) {
  try {
    var key = Constants.ACADEMIC_CYCLE_UPDATED_TIME + "_" + curriculumId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date(); 
    yield put (AcademicCycleActions.clearAcademicCycles())
    yield put(AcademicCycleActions.setAcademicCycleLodingState("request"));
    const localResponse = yield call(AcademicCycleHelper.getAcademicCycleForCurriculumId, curriculumId, null, true);
    yield put(AcademicCycleActions.updateCurriculumAcademicCycles(localResponse));

    yield put(AcademicCycleActions.setAcademicCycleLodingState("responce-local"));
    const serverResponse = yield call(AcademicCycleHelper.getAcademicCycleForCurriculumId, curriculumId, updatedTime, false);
    localStorage.setItem(key,newTime)
    yield put(AcademicCycleActions.setAcademicCycleLodingState("responce-server"));
    if (serverResponse && serverResponse.length > 0 || true) {
      yield put(AcademicCycleActions.updateCurriculumAcademicCycles(serverResponse,"replace"));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* addAcademicCycle({ payload: { academicCycle,message } }) {
  try {
    const response = yield call(AcademicCycleHelper.saveCycleObjects, academicCycle);
    if (response) {
      if (message) {
        yield put(AcademicCycleActions.updateAcademicCycles(response));
        toastr.success(message, { timeOut: 3000 });
      } else {
        yield put(AcademicCycleActions.updateCurriculumAcademicCycles(response));
      }
      Parse.Object.pinAll(response);
    } else {
      
    }
  } catch (error) {
   console.log(error);
  }
}

function* reorderCycles({ payload: { cycles,type,message} }) {
  try {
    const response = yield call(AcademicCycleHelper.saveCycleObjects, cycles);
    if (response) {
      if (message) {
        toastr.success(message, { timeOut: 3000 });
      } 
      if (type === "LMS") {
        yield put(AcademicCycleActions.updateCurriculumAcademicCycles(response,"replace"));
      } else {
        yield put(AcademicCycleActions.updateAcademicCycles(response,"replace"));
      }
      Parse.Object.pinAll(response);
    } else {
      
    }
  } catch (error) {
   console.log(error);
  }
}

function* setSelectedAcademicCycle({ payload: { academicCycleId } }) {
  try {
    const response = yield call( AcademicCycleHelper.getAcademicCycleFromParse, academicCycleId);
    if (response) {
      yield put(AcademicCycleActions.updateSelectedAcademicCycle(response));
      response.pin();
    } else {
      const sResponse = yield call(AcademicCycleHelper.getAcademicCycleFromParse, academicCycleId);
      if (sResponse) {
        sResponse.pin();
        yield put(AcademicCycleActions.updateSelectedAcademicCycle(sResponse));
      }
    
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetAcademicCycle() {
    yield takeEvery(actionTypes.GET_ACADEMIC_CYCLE, getAAcademicCycle);
  }
  
  export function* watchDeleteAcademicCycle() {
    yield takeEvery(actionTypes.DELETE_ACADEMIC_CYCLE, deleteAAcademicCycle);
  }
  
  
  export function* watchGetAcademicCycleForSchoolId() {
    yield takeEvery(actionTypes.GET_ACADEMIC_CYCLES, getAcademicCycleForSchoolId);
  }

  export function* watchGetCurriculumAcademicCycleForSchoolId() {
    yield takeEvery(actionTypes.GET_CURRICULUM_ACADEMIC_CYCLES, getAcademicCyclesForCurriculumId);
} 
  
export function* watchAddAcademicCycle() {
  yield takeLeading(actionTypes.ADD_CURRICULUM_ACADEMIC_CYCLE, addAcademicCycle);
}

export function* watchReorderCycles() {
  yield takeLeading(actionTypes.ACADEMIC_CYCLE_REORDER, reorderCycles);
}

export function* watchSetSelectedAcademicCycle(){
  yield takeEvery(actionTypes.SET_SELECTED_ACADEMIC_CYCLE, setSelectedAcademicCycle);
}
  
function* AcademicCycleSaga() {
    yield all([
      fork(watchGetAcademicCycle),
      fork(watchDeleteAcademicCycle),
      fork(watchGetAcademicCycleForSchoolId),
      fork(watchGetCurriculumAcademicCycleForSchoolId),
      fork(watchAddAcademicCycle),
      fork(watchReorderCycles),
      fork(watchSetSelectedAcademicCycle)
    ]);
  }
  export default AcademicCycleSaga;
  