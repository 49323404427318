import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Container, Label, Card, CardBody } from "reactstrap";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import {
    getAKid, setSelecetdSchool, getClassroomsForSchoolId, getSubjectsForExamId, getExamForExamId, getExamResultsForExamId,
    getGroupForId, getGroupMapsForGroupId, getTeachersForSchool, getExamResultsForExamIdAndKidId,
    getAllKidsForGroup, saveGroup, saveGroupMaps, getAllKids
} from "../../store/actions";
import HallTicketSchoolLogo from '../../assets/images/NIECCE/niecceTitlepng.png'
import Header from './Header'

const HallTicket = (props) => {

    const matchKid = matchPath(props.history.location.pathname, {
        path: "/exam-hall-ticket/:schoolId/:examId/:kidId",
        exact: true,
        strict: false,
    });


    const matchGroup = matchPath(props.history.location.pathname, {
        path: "/exam-hall-tickets/:schoolId/:examId/:groupId",
        exact: true,
        strict: false,
    });

    const match = matchKid ?? matchGroup

    const kidId = matchKid && matchKid.params.kidId;
    const schoolId = match && match.params.schoolId;
    const examId = match && match.params.examId;
    const groupId = matchGroup && matchGroup.params.groupId;


    const [tableData, setTableData] = useState([]);
    const [selectedExamKids, setSelectedExamKids] = useState([])
    const [kidSubjectList, setKidSubjectList] = useState([])

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId])

    useEffect(() => {
        if (kidId) {
            props.getAKid(kidId);
        }
    }, [kidId]);


    useEffect(() => {
        if (schoolId) {
            props.getAllKids(schoolId);
        }
    }, [schoolId])

    useEffect(() => {
        if (examId) {
            props.getSubjectsForExamId(examId);
            props.getExamForExamId(examId);
            if (kidId) {
                props.getExamResultsForExamIdAndKidId(examId, kidId)
            } else {
                props.getExamResultsForExamId(examId)
            }

        }
    }, [examId]);

    useEffect(() => {
        if (groupId) {
            props.getGroupForId(groupId);
            props.getGroupMapsForGroupId(groupId);
        }
    }, [groupId])

    useEffect(() => {

        if (props.groupmaps && props.groupmaps.length > 0 && props.kids) {
            let tempSelectedKidList = []


            for (var i = 0; i < props.groupmaps.length; i++) {
                if (props.groupmaps[i].attributes.groupId === groupId) {
                    let kidObjList = props.kids.filter((kid) =>
                        kid.id === props.groupmaps[i].attributes.userId
                    )
                    if (kidObjList.length > 0) {
                        tempSelectedKidList.push(kidObjList[0]);
                    }
                }
            }
            setSelectedExamKids(tempSelectedKidList)
        }
    }, [props.groupmaps, props.kids]);

    useEffect(() => {
        if (props.subjects && props.subjects.length > 0) {
            let theorySubjects = []
            let practicalSubjects = []
            let subjects = [];
            for (const sub of props.subjects) {
                if (sub.attributes.examId == examId) {
                    if (sub.attributes.theoryMarks) {
                        theorySubjects.push(sub)
                    } else {
                        practicalSubjects.push(sub)
                    }
                }
            }
            for (const s of theorySubjects) {

                let newSubject = {
                    subjectId: uuidv4(),
                    id: s.id,
                    parseObject: s,
                    subjectName: s.attributes.name,
                    examStartDate: new Date(s.attributes.examDate),
                    duration: s.attributes.duration,
                    theory: s.attributes.theoryMarks,
                    passing: s.attributes.passMarks,
                    totalMarks: s.attributes.maxMarks,

                    practicalData: [
                    ]
                }


                let pList = []
                for (const p of practicalSubjects) {

                    if (p.attributes.subjectId == s.id) {
                        let pObj = {
                            practicalId: uuidv4(),
                            id: p.id,
                            parseObject: p,
                            practicalName: p.attributes.name,
                            practicalStartDate: new Date(p.attributes.examDate),
                            duration: p.attributes.duration,
                            practicalMarks: p.attributes.practicalMarks,
                            passingMarks: p.attributes.passMarks,
                            totalMarks: p.attributes.maxMarks,
                        }
                        pList.push(pObj)
                    }
                }

                newSubject.practicalData = pList;
                subjects.push(newSubject)


            }
            setTableData(subjects)
        }

    }, [props.subjects])

    useEffect(() => {

        if (tableData && tableData.length > 0) {
            let kidList = [];

            if (kidId && props.selectedKid) {
                kidList.push(props.selectedKid);
            } else {
                kidList = kidList.concat(selectedExamKids);
            }

            let mainList = [];
            for (const kid of kidList) {

                let kidTableDate = [...tableData];
                let kidSubjects = [];

                for (const i in kidTableDate) {
                    let subject = { ...kidTableDate[i] };
                    let practicalData = [];

                    if (subject.practicalData && subject.practicalData.length > 0) {
                        for (const j in subject.practicalData) {
                            let ps = { ...subject.practicalData[j] };
                            practicalData.push(ps);
                        }
                    }
                    subject.practicalData = practicalData;
                    kidSubjects.push(subject);
                }

                mainList.push({
                    id: kid.id,
                    kid: kid,
                    subjects: kidSubjects,
                });
            }
            setKidSubjectList(mainList);
        }
    }, [props.selectedKid, selectedExamKids, tableData, props.examResults]);

    const containerStyle = {
        width: '25cm',
        minHeight: '27cm',
        padding: '2cm',
        margin: '1cm auto',
        background: 'white',
        pageBreakBefore: 'always'
    };

    const formatDuration = (minutes) => {
        console.log(minutes);
        const hours = Math.floor(minutes / 60);
        const remainingMinutes = minutes % 60;

        let formattedDuration = '';

        if (hours > 0) {
            formattedDuration += `${hours} ${hours === 1 ? 'Hr' : 'Hr'}`;
        }

        if (remainingMinutes > 0) {
            if (formattedDuration !== '') {
                formattedDuration += ' ';
            }
            formattedDuration += `${remainingMinutes} ${remainingMinutes === 1 ? 'Min' : 'Min'}`;
        }

        return formattedDuration;
    };

    const getUI = (kid, kidSubjects) => {
        return (
            <div >
                {
                    !(props.selectedSchool) ? <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading.....</Label>
                        </div>

                    </Col> :
                        <div className="page" style={containerStyle}>
                            {props.selectedSchool.attributes.instituteId === "0E6RAQWzHo" ? <div className="col-md-12 col-sm-12 col-xs-12">
                                <center>
                                    <img src={HallTicketSchoolLogo} style={{ marginTop: '-2cm' }} />
                                </center>
                            </div> :
                            <div className="col-md-12 col-sm-12 col-xs-12">
                            <center>
                                <img src={props.selectedSchool.attributes.logo._url} style={{ marginTop: '-2cm' }} />
                            </center>
                        </div>}
                            <div className="col-md-12 col-sm-12 col-xs-12">
                                <center>
                                    <h3 style={{ fontWeight: 'bolder' }}>{props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.name}</h3>
                                    <h4 style={{ fontWeight: 'bolder' }}>{props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.batch ? props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.batch.toUpperCase() : ''} </h4>
                                </center>
                            </div>
                            <div style={{ height: '1cm' }}>
                            </div>
                            <center>
                                <h4 style={{ fontWeight: 'bolder', textDecoration: 'underline' }}>
                                    ADMISSION TICKET
                                </h4>
                            </center>
                            <div style={{ height: '0.5cm' }}></div>
                            <div className="contrainer">
                                <div className="font-size-15">
                                    <p >
                                        Name of the Candidate:
                                        <b>{kid && kid.attributes.Name}</b>
                                    </p>
                                </div>
                                <div className="d-flex justify-content-between font-size-15">
                                    <div>
                                        <p>
                                            Reg No.: <b>{kid && kid.attributes.admissionNumber} </b>
                                        </p>
                                    </div>

                                </div>
                                <div>
                                    <p>
                                        Authorized Centre:
                                        <b style={{ fontSize: '13px' }}>{props.selectedSchool.attributes.Address}</b>
                                    </p>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12">
                                    <center>
                                        <h4 style={{ fontWeight: 'bolder', textDecoration: 'underline', marginTop: '1cm' }}>
                                            EXAMINATION TIMETABLE {props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.batch ? props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.batch.toUpperCase() : ''}
                                        </h4>
                                    </center>
                                </div>
                                <div className="table-responsive">
                                    <table className="table table-bordered" style={{ textAlign: 'center', marginTop: '0.8cm' }}>
                                        <tr>
                                            <th width="5%" height='50px' style={{ textAlign: 'center', border: '1px solid lightgray' }}>SLNO</th>
                                            <th width="20%" style={{ textAlign: 'center', border: '1px solid lightgray' }}>DATE</th>
                                            <th width="15%" style={{ textAlign: 'center', border: '1px solid lightgray' }}>DAY</th>
                                            <th width="15%" style={{ textAlign: 'center', border: '1px solid lightgray' }}>TIME</th>
                                            <th width="25%" style={{ textAlign: 'center', border: '1px solid lightgray' }}>SUBJECT</th>
                                            <th width="20%" style={{ textAlign: 'center', border: '1px solid lightgray' }}>INVIGILATOR SIGNATURE</th>
                                        </tr>
                                        {kidSubjects && kidSubjects.subjects && kidSubjects.subjects.map((sub, subKey) => (
                                            <tr >
                                                {console.log(sub)}
                                                <td height='70px' style={{ border: '1px solid lightgray' }}>{subKey + 1}</td>
                                                <td style={{ border: '1px solid lightgray' }}>{moment(sub.examStartDate).format("DD/MM/YYYY")}</td>
                                                <td style={{ border: '1px solid lightgray' }}>{moment(sub.examStartDate).format("dddd")}</td>
                                                <td style={{ border: '1px solid lightgray' }}>{formatDuration(sub.duration)}</td>
                                                <th style={{ border: '1px solid lightgray' }}>{sub.subjectName}</th>
                                                <td style={{ border: '1px solid lightgray' }}></td>
                                            </tr>))}
                                    </table>
                                </div>
                                <div className="col-md-12 col-sm-12 col-xs-12" style={{ height: '4cm' }}></div>
                                <div className="d-flex justify-content-between font-size-15">
                                    <div>
                                        SIGNATURE OF THE CANDIDATE
                                    </div>
                                    <div>
                                        SIGNATURE OF THE PRINCIPAL
                                    </div>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }

    return (
        <React.Fragment>
            <Header certificate={kidId ? 'Hall Ticket' : 'Hall Tickets'}
                matchGroup={matchGroup}
                exam={props.selectedExam && props.selectedExam}
                selectedKid={kidId ? props.selectedKid : ''} />
            <div className="account-pages">
                {kidSubjectList && kidSubjectList.length > 0 ? <div className="account-pages">
                    {kidId && props.selectedKid && <Container fluid='md'>{getUI(props.selectedKid, kidSubjectList[0])}</Container>}

                    {
                        groupId && kidSubjectList.map((ksl) =>

                            <Container fluid='md' key={ksl.id}>{getUI(ksl.kid, ksl)}</Container>
                        )
                    }
                </div> :
                    <div className='text-center'>
                        {/* Loading.... */}
                    </div>

                }
            </div>
        </React.Fragment >

    )
}

const mapStatetoProps = (state) => {

    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { subjects } = state.Subjects;
    const { selectedExam } = state.Examination;
    const { examResults } = state.ExamResults;
    const { kids, admissionKids, selectedKid } = state.Kid;
    const { resultGroup, singleGroupHolder } = state.Group;
    const { groupmaps, resultGroupMapsAfterSave } = state.GroupMap;


    return { selectedKid, selectedSchool, classrooms, subjects, selectedExam, examResults, kids, groupmaps };
};

export default (withRouter(connect(mapStatetoProps, {
    getAKid, setSelecetdSchool, getClassroomsForSchoolId, getSubjectsForExamId, getExamForExamId, getExamResultsForExamId,
    getGroupForId, getGroupMapsForGroupId, getTeachersForSchool, getExamResultsForExamIdAndKidId,
    getAllKidsForGroup, saveGroup, saveGroupMaps, getAllKids
})(HallTicket)));