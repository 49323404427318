import * as actions from "./actionTypes";

export const getInventoryKitsForOwnerId = (ownerId, ownerType, showInStore) =>{
    return{
    type: actions.GET_INVENTORY_KITS,
    payload: {ownerId, ownerType, showInStore},
    }
}

export const updateInventoryKits = (inventoryKits) =>{
    return{
        type:actions.UPDATE_INVENTORY_KITS,
        payload: {inventoryKits},
    }
}

export const updateDeleteInventoryKit = (inventoryKit) =>{
    return{
        type:actions.UPDATE_DELETE_INVENTORY_KIT,
        payload: {inventoryKit},
    }
}

export const editInventoryKit = (inventoryKit) =>{
    return{
        type: actions.EDIT_INVENTORY_KIT,
        payload: {inventoryKit}
    }
}

export const deleteInventoryKit = (inventoryKit) =>{
    return{
        type:actions.DELETE_INVENTORY_KIT,
        payload: {inventoryKit},
    }
}

export const getInventoryKitItemsForInventoryKitId = (inventoryKit) =>{
    return{
        type:actions.GET_INVENTORY_KIT_ITEM,
        payload: {inventoryKit},
    }
}

export const addInventoryKit = (inventoryKit) =>{
    return{
        type: actions.ADD_INVENTORY_KIT,
        payload: {inventoryKit}
    }
}

export const setSelectedInventoryKit = (inventoryKitId) => {
    return {
        type: actions.SET_SELECTED_INVENTORY_KIT,
        payload: {inventoryKitId }
    }
}

export const updateSelectedInventoryKit = (inventoryKit) => {
    return {
      type: actions.UPDATE_SELECTED_INVENTORY_KIT,
      payload: { inventoryKit },
    };
  };

 