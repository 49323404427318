import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
    getParentsForFamilyIds
} from '../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';

import { Row, Col, Modal, ModalBody, ModalFooter, ModalHeader, FormGroup, Label, Card, CardBody, CardTitle, Button, Table } from "reactstrap";
import { contains } from 'jquery';

const attendanceDetailsModal = (props) => {


    const [attendanceReport, setAttendanceReport] = useState([])
    const [selectedClassRoomId, setSelectedClassRoomId] = useState(props.selectedClassroom);
    const [selectedAttendanceStatus, setSelectedAttendanceStatus] = useState('');
    const [isSelectedIsDaycare, setIsSelectedIsDaycare] = useState(false);

    useEffect(() => {
        if (props.kids) {
            let familyIds = []
            for (const kid of props.kids) {
                familyIds.push(kid.attributes.FamilyID)
            }
            props.getParentsForFamilyIds(familyIds)
        }
    }, [props.kids]);

    useEffect(() => {
        if (props.classrooms) {
            let isDc = false
            for (const cl of props.classrooms) {
                if (cl.id == selectedClassRoomId && cl.attributes.classroomType == 2) {
                    isDc = true;
                    break;
                }
            }
            console.log("selectedClassRoomId",selectedClassRoomId)
            setIsSelectedIsDaycare(isDc)
        }
    }, [selectedClassRoomId]);



    useEffect(() => {
        if (props.kidAttendanceList && props.kids && props.classrooms && props.parents && props.daycareAttendance) {
            let tempAttendance = [];

            for (const kid of props.kids) {
                let kidDO = {
                    id: kid.id,
                    attendanceStatus: 'Absent',
                    attendanceState: 'Out',
                    daycareAttendanceStatus: 'Absent',
                    daycareAttendanceState: "Out",
                    kidName: kid.attributes.Name,
                    kid: kid,
                    classroomId: kid.attributes.ClassRoomID,
                    classroom: undefined,
                    classroomName: undefined,
                    daycareClassId: kid.attributes.daycareClassId,
                    isDaycareChild: kid.attributes.daycareClassId && kid.attributes.isDaycareChild == 1,
                    daycareClassroom: undefined,
                    daycareClassroomName: undefined,
                    isOnlyDayCare: false,
                };

                for (const classRoom of props.classrooms) {
                    if (kidDO.classroomId == classRoom.id) {
                        kidDO.classroomName = classRoom.attributes.Name;
                        kidDO.classroom = classRoom;

                        if (classRoom.attributes.classroomType == 2) {
                            kidDO.daycareClassroomName = classRoom.attributes.Name;
                            kidDO.daycareClassroom = classRoom;
                            kidDO.isDaycareChild = true;
                            kidDO.isOnlyDayCare = true;
                        }
                    }

                    if (!kidDO.isOnlyDayCare && kidDO.isDaycareChild && kidDO.daycareClassId == classRoom.id) {
                        kidDO.daycareClassroomName = classRoom.attributes.Name;
                        kidDO.daycareClassroom = classRoom;
                    }
                }

                if (!kidDO.isOnlyDayCare) {
                    for (const attendance of props.kidAttendanceList) {
                        if (attendance.attributes.Kid == kid.id && attendance.attributes.ReachedSchoolAt) {
                            kidDO.attendanceStatus = 'Present';
                            kidDO.attendanceState = attendance.attributes.LeftSchoolAt ? 'Out' : 'In';
                        }
                    }
                }

                if (kidDO.isDaycareChild) {
                    let state = "Out";
                    for (const dAttendance of props.daycareAttendance) {
                        if (dAttendance.attributes.kidId == kid.id) {
                            kidDO.daycareAttendanceStatus = 'Present';
                            if (!dAttendance.attributes.checkOutTime && state != "In") {
                                state = "In";
                            }
                        }
                    }
                    kidDO.daycareAttendanceState = state;
                }

                for (const parent of props.parents) {
                    if (parent.attributes.FamilyID == kid.attributes.FamilyID) {
                        if (parent.attributes.Relation == "Mother") {
                            kidDO.motherName = parent.attributes.Name;
                            kidDO.motherPhone = parent.attributes.PhoneNumber;
                            kidDO.motherObj = parent;
                        } else if (parent.attributes.Relation == "Father") {
                            kidDO.fatherName = parent.attributes.Name;
                            kidDO.fatherPhone = parent.attributes.PhoneNumber;
                            kidDO.fatherObj = parent;
                        }
                    }
                }

                kidDO.status = getAttentendanceStatus(kidDO)
                tempAttendance.push(kidDO);
            }
            // console.log(tempAttendance);
            setAttendanceReport(tempAttendance);
        }
    }, [props.kidAttendanceList, props.kids, props.classrooms, props.parents, props.daycareAttendance]);

    // const filteredAttendanceReport = attendanceReport.filter(item => {
    //     const matchClassRoom = selectedClassRoomId == 'all' || (item.classroomId == selectedClassRoomId || item.daycareClassId == selectedClassRoomId);

    //     let matchAttendanceStatus = true;
    //     if (selectedAttendanceStatus == 'Present') {
    //         matchAttendanceStatus = item.attendanceStatus == 'Present' || item.daycareAttendanceStatus == 'Present';
    //     } else if (selectedAttendanceStatus == 'Absent') {
    //         matchAttendanceStatus = item.attendanceStatus == 'Absent' && item.daycareAttendanceStatus == 'Absent';
    //     }

    //     return matchClassRoom && matchAttendanceStatus;
    // });

    const filteredAttendanceReport = attendanceReport.filter(item => {
        const matchClassRoom = selectedClassRoomId === 'all' || (item.classroomId === selectedClassRoomId || item.daycareClassId === selectedClassRoomId);
    
        let matchAttendanceStatus = true;
        if (selectedAttendanceStatus === 'Present') {
            if (selectedClassRoomId === item.daycareClassId) {
                matchAttendanceStatus = item.daycareAttendanceStatus === 'Present';
            } else {
                matchAttendanceStatus = item.attendanceStatus === 'Present' || item.daycareAttendanceStatus === 'Present';
            }
        } else if (selectedAttendanceStatus === 'Absent') {
            if (selectedClassRoomId === item.daycareClassId) {
                matchAttendanceStatus = item.daycareAttendanceStatus === 'Absent';
            } else {
                matchAttendanceStatus = item.attendanceStatus === 'Absent' && item.daycareAttendanceStatus === 'Absent';
            }
        }
    
        return matchClassRoom && matchAttendanceStatus;
    });
    


    const getAttentendanceStatus = (kidDO) => {

        if (kidDO.isDaycareChild && kidDO.daycareClassId) {
            if (kidDO.attendanceStatus == "Present" && kidDO.daycareAttendanceStatus == "Present") {
                return "Present"
            }
            if (kidDO.attendanceStatus != "Present" && kidDO.daycareAttendanceStatus != "Present") {
                return "Absent"
            }
            if (kidDO.attendanceStatus == "Present" && kidDO.daycareAttendanceStatus != "Present") {
                if (isSelectedIsDaycare) {
                    return <p>Absent <br></br> (Present in class)</p>
                } else {
                    return "Present"
                }
            }
            if (kidDO.attendanceStatus != "Present" && kidDO.daycareAttendanceStatus == "Present") {
                if (isSelectedIsDaycare) {
                    return "Present"
                } else {
                    return <p>Absent <br></br> (Present in daycare)</p>
                }
            }
        } else {
            return (kidDO.attendanceStatus == "Present" || kidDO.daycareAttendanceStatus == "Present") ? "Present" : "Absent"
        }
    }


    const reset = () => {
        props.closeDetailsPopUp()
    }

    return (
        <div>
            <Modal size='xl' isOpen={props.detailsPopup} scrollable={true} >
                <ModalHeader toggle={() => {
                    reset()
                }}>
                    <CardTitle>Attendance Report</CardTitle>
                </ModalHeader>
                <ModalBody className='bg-white'>
                    <Row className="bg-light m-2" style={{ width: '100%', position: 'sticky', top: '0cm', }}>
                        <Col md="3" xs="12" xl="3" lg="3">
                            <FormGroup className="mt-2 mb-2 p-1">
                                <Label>{props.t("Select Classroom")} :</Label>
                                <select
                                    className="form-control"
                                    onChange={(e) => {
                                        setSelectedClassRoomId(e.target.value);
                                    }}
                                    value={selectedClassRoomId || 'all'}
                                >
                                    <option value="all">All</option>
                                    {props.classrooms.map(classRoom => (
                                        <option key={classRoom.id} value={classRoom.id}>
                                            {classRoom.attributes.Name}
                                        </option>
                                    ))}
                                </select>
                            </FormGroup>
                        </Col>
                        <Col md="3" xs="12" xl="3" lg="3">
                            <FormGroup className="mt-2 mb-2 p-1">
                                <Label>{props.t("Select Attendance Status")} :</Label>
                                <select
                                    className="form-control"
                                    onChange={(e) => setSelectedAttendanceStatus(e.target.value)}
                                    value={selectedAttendanceStatus || ''}
                                >
                                    <option value="">All</option>
                                    <option value="Present">Present</option>
                                    <option value="Absent">Absent</option>
                                </select>
                            </FormGroup>
                        </Col>
                    </Row>

                    {filteredAttendanceReport.length > 0 && (
                        <div className="table-responsive m-2" data-pattern="priority-columns">
                            <Table className="table table-striped table-bordered">
                                <thead>
                                    <tr>
                                        <th className="text-center">{props.t("SL No")}</th>
                                        <th className="text-center">{props.t("Name")}</th>
                                        <th className="text-center">{props.t("Class")}</th>
                                        <th className="text-center">{props.t("Father")}</th>
                                        <th className="text-center">{props.t("Father Phone")}</th>
                                        <th className="text-center">{props.t("Mother")}</th>
                                        <th className="text-center">{props.t("Mother Phone")}</th>
                                        <th className="text-center">{props.t("In/Out")}</th>
                                        <th className="text-center">{props.t("Daycare Status")}</th>
                                        {!isSelectedIsDaycare && <th className="text-center">{props.t("Classroom")}</th>}
                                        
                                    </tr>
                                </thead>
                                <tbody>
                                    {filteredAttendanceReport.map((val, key) => (
                                        <tr className="text-center" key={key}>
                                            <td>{key + 1}</td>
                                            <td>{val.kidName}</td>
                                            <td>{val.classroomName}</td>
                                            <td>{val.fatherName}</td>
                                            <td>{val.fatherPhone}</td>
                                            <td>{val.motherName}</td>
                                            <td>{val.motherPhone}</td>
                                            <td>
                                                {(val.attendanceStatus == "Present" || val.daycareAttendanceStatus == "Present") ?
                                                    (val.attendanceState == "In" || val.daycareAttendanceState == "In") ? "In" : "Out" : "-"}
                                            </td>

                                            <td>
                                                {val.isDaycareChild ? val.daycareAttendanceStatus :"-"}
                                            </td>

                                            {!isSelectedIsDaycare &&<td>
                                                {val.isOnlyDayCare?"-":val.attendanceStatus}
                                            </td>}
                                            

                                        </tr>
                                    ))}
                                </tbody>
                            </Table>
                        </div>
                    )}
                    {(filteredAttendanceReport.length == 0) && <Row>
                        <Col>
                            <h4 className='text-info text-center'>No data found for this filter</h4>
                        </Col>
                    </Row>}
                </ModalBody>
                <ModalFooter>
                    <Button
                        className='btn btn-secondary float-end'
                        onClick={() => {
                            reset()
                        }}>
                        Close
                    </Button>
                </ModalFooter>
            </Modal>

        </div>
    )
}

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners } = state.CorporatePartners;
    const { teachers } = state.Teacher;
    const { classrooms } = state.Classroom;
    const { kids } = state.Kid;
    const { parents } = state.Parent;
    const {
        teacherAttendanceList,
        gotAttendance,
        kidAttendanceList,
        daycareAttendance,
    } = state.Attendance;
    return {
        schools, selectedSchool,
        franchise,
        corporatePartners,
        teacherAttendanceList,
        gotAttendance,
        kidAttendanceList,
        daycareAttendance,
        teachers,
        classrooms,
        kids,
        parents,
    };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getParentsForFamilyIds
        })(attendanceDetailsModal)
    )
);