import Parse from "parse";
import { printLog } from "./loghelper";

const getGroupFromParse = (groupId) => {
    printLog("HL: group_helper : getGroupFromParse")

    try {
        return new Promise((resolve, reject) => {
            var Group = Parse.Object.extend("Group");
            var query = new Parse.Query(Group);
            query.notEqualTo("deleted", true);
            query.get(groupId).then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            )
        }
        )
    } catch (error) { }
}

const deleteGroupFromParse = (group) => {
    printLog("HL: group_helper : deleteGroupFromParse")
    try {
        return new Promise((resolve, reject) => {
            group.save().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            );

        });
    } catch (error) { }
}

const saveArchiveGroup = (group) => {
    printLog("HL: group_helper : saveArchiveGroup")
    try {
        return new Promise((resolve, reject) => {
            group.save().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            );

        });
    } catch (error) { }
}


const saveGroup = (group) => {
    printLog("HL: group_helper : saveGroup")
    try {
        return new Promise((resolve, reject) => {
            group.save().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            );

        });
    } catch (error) { }
}

const getArchiveGroupsForSchoolId = (id, updatedDate, isLocal) => {
    printLog("HL: group_helper : getArchiveGroupsForSchoolId")
    try {
        return new Promise((resolve, reject) => {
            var Group = Parse.Object.extend("Group");
            var query = new Parse.Query(Group);
            query.limit(1000);
            query.equalTo("schoolId", id)
            query.equalTo("type",4)
            
            if (updatedDate) {
                query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate))
            } else {
                query.notEqualTo("deleted", true);
            }

            if (isLocal) {
                query.fromLocalDatastore();
            }

            query.ascending("name");
            query.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            );
        }
        )
    } catch (error) { }
}

const getGroupsForSchoolId = (id, updatedDate, isLocal) => {
    printLog("HL: group_helper : getGroupsForSchoolId")
    try {
        return new Promise((resolve, reject) => {
            var Group = Parse.Object.extend("Group");
            var query = new Parse.Query(Group);
            
            query.equalTo("type",1)
            var query1 = new Parse.Query(Group);
            query1.doesNotExist("type")

      

            var mainQuery = Parse.Query.or(query1, query);
            mainQuery.limit(1000);
            mainQuery.equalTo("schoolId", id)
            if (updatedDate) {
                mainQuery.greaterThanOrEqualTo("updatedAt", new Date(updatedDate))
            } else {
                mainQuery.notEqualTo("deleted", true);
            }

            if (isLocal) {
                mainQuery.fromLocalDatastore();
            }

            mainQuery.ascending("name");
            mainQuery.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            );
        }
        )
    } catch (error) { }
}

const getGroupsForGroupIds = (groupIds) => {
    printLog("HL: group_helper : getGroupsForGroupIds")
    try {
        return new Promise((resolve, reject) => {
            var Group = Parse.Object.extend("Group");
            var query = new Parse.Query(Group);
            query.containedIn("objectId", groupIds);
            query.limit(1000);
            query.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(null);
                }
            )
        }
        )
    } catch (error) { }
}




export { getGroupFromParse, deleteGroupFromParse, getGroupsForSchoolId, getGroupsForGroupIds,saveGroup,saveArchiveGroup,getArchiveGroupsForSchoolId }