import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventoryKitHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryKit_helper";
import * as actionType from "./actionTypes";
import * as InventoryKitAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getInventoryKitsForOwnerId({ payload: { ownerId, ownerType, showInStore } }) {
    try {

        var key = Constants.INVENTORY_KIT_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventoryKitHelper.getInventoryKitForOwnerId, ownerId, ownerType, showInStore, null, true);
        yield put(InventoryKitAction.updateInventoryKits(localResponse));


        const serverResponse = yield call(InventoryKitHelper.getInventoryKitForOwnerId, ownerId, ownerType,showInStore, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventoryKitAction.updateInventoryKits(serverResponse));

            var nonDeleted = serverResponse.filter(
                (inventoryKit) =>
                    inventoryKit.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventoryKit) =>
                    inventoryKit.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* editInventoryKit({ payload: { inventoryKit } }) {
    try {
        const response = yield call(InventoryKitHelper.editInventoryKit, inventoryKit);
        if (response === "Error") {
            toastr.error("Please try again", { timeOut: 3000 });
        } else
            if (response) {
                yield put(InventoryKitAction.updateInventoryKits([response]));
                response.pin();
                toastr.success("Inventory kit successfully updated", { timeOut: 3000 });
            } else {
            }
    } catch (error) {

    }
}

function* deleteInventoryKit({ payload: { inventoryKit } }) {
    try {
        yield put(InventoryKitAction.updateDeleteInventoryKit(inventoryKit));
        const response = yield call(InventoryKitHelper.deleteInventoryKitFromParse, inventoryKit);
        if (response === "error") {
            inventoryKit.set("isDeleted", undefined);
            yield put(InventoryKitAction.updateDeleteInventoryKit(inventoryKit));
        } else
            if (response) {
                toastr.success("inventory kit successfully removed", { timeOut: 3000 });
                response.unPin();
            } else {

            }

    } catch (error) {

    }
}

function* setSelectedInventoryKit({ payload: { inventoryKitId } }) {
    try {
        const response = yield call(InventoryKitHelper.getInventoryKitFromParse, inventoryKitId, true);
        if (response) {
          response.pin();
          yield put(InventoryKitAction.updateSelectedInventoryKit(response));
        } 
      } catch (error) {
      }
}

function* addInventoryKit({ payload: { inventoryKit } }) {
    try {

        yield put(InventoryKitAction.updateInventoryKits(inventoryKit));
        const response = yield call(InventoryKitHelper.editInventoryKit, inventoryKit
        );
        if (response === "Error") {

            toastr.error("Please try again", { timeOut: 3000 });
            const local = yield call(
                InventoryKitHelper.getInventoryKitFromLocal, inventoryKit.id
            );

            if (local) {
                yield put(InventoryKitAction.updateInventoryKits(local));
            }

        } else
            if (response) {
                yield put(InventoryKitAction.updateInventoryKits(response));
                toastr.success("Inventory kit successfully added", { timeOut: 3000 });
                response.pin();
            } else {

            }
    } catch (error) {

    }
}

export function* watchGetInventoryKitsForOwnerId() {
    yield takeEvery(actionType.GET_INVENTORY_KITS, getInventoryKitsForOwnerId);
}
export function* watchEditInventoryKit() {
    yield takeEvery(actionType.EDIT_INVENTORY_KIT, editInventoryKit);
}

export function* watchDeleteInventoryKit() {
    yield takeEvery(actionType.DELETE_INVENTORY_KIT, deleteInventoryKit);
}

export function* watchAddInventoryKit() {
    yield takeEvery(actionType.ADD_INVENTORY_KIT, addInventoryKit);
}

export function* watchSetSelectedInventoryKit() {
    yield takeEvery(actionType.SET_SELECTED_INVENTORY_KIT, setSelectedInventoryKit);
}


function* InventoryKitSaga() {
    yield all([
        fork(watchGetInventoryKitsForOwnerId),
        fork(watchEditInventoryKit),
        fork(watchDeleteInventoryKit),
        fork(watchAddInventoryKit),
        fork(watchSetSelectedInventoryKit),
    ]);
}
export default InventoryKitSaga;