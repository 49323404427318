import Parse from "parse";
import * as Constants from "../../Constents";
import { printLog } from "./loghelper";

export const getTeacherAttendanceForIds = (param) => {
  printLog("HL: attendance_helper : getTeacherAttendanceForIds")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("TeacherAttendance");
      query.equalTo("schoolId", param.schoolId);
      query.containedIn("teacherId", param.teacherIds);
      query.greaterThanOrEqualTo("createdAt", param.startDate);
      query.lessThanOrEqualTo("createdAt", param.endDate);
      query.ascending("createdAt");
      query.limit(10000);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAttendanceForKidIds = (param) => {
  printLog("HL: attendance_helper : getAttendanceForKidIds")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Attendance");

      if (param.forSchool) {
        query.equalTo("School", param.schoolId);
      } else {
        query.containedIn("Kid", param.kidIds);
        query.equalTo("School", param.schoolId);
      }

      query.greaterThanOrEqualTo("createdAt", param.startDate);
      query.lessThanOrEqualTo("createdAt", param.endDate);
      query.ascending("createdAt");
      query.limit(10000);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const getDaycareAttendanceForKidIds = (param) => {
  printLog("HL: attendance_helper : getDaycareAttendanceForKidIds")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("DaycareTimeSheet");

      if (param.forSchool) {
        query.equalTo("schoolId", param.schoolId);
      } else {
        query.containedIn("kidId", param.kidIds);
        query.equalTo("schoolId", param.schoolId);
      }
      query.greaterThanOrEqualTo("createdAt", param.startDate);
      query.lessThanOrEqualTo("createdAt", param.endDate);
      query.ascending("createdAt");
      query.limit(10000);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const getEnquiryKidsForFilter = (param) => {
  printLog("HL: attendance_helper : getEnquiryKidsForFilter")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Enquiry");
      query.equalTo("schoolId", param.schoolId);
      if (param.status.length > 0) {
        query.containedIn("status", param.status);
        query.notEqualTo("isDeleted", true);
      } else {
        if (param.QRcode) {
          query.equalTo("createdBy", "QRCode");
          query.notEqualTo("isDeleted", true);
        } else if (param.isBulkUpdate) {
          query.equalTo("isBulkUpdate", true);
          query.notEqualTo("isDeleted", true);
        } else if (param.schoolWebsite) {
          query.equalTo("createdBy", "API");
          query.notEqualTo("isDeleted", true);
        } else if (param.deleted) {
          query.equalTo("isDeleted", true);
        } else {
          query.notEqualTo("isDeleted", true);
        }
      }
      query.greaterThanOrEqualTo("createdAt", param.startDate);
      query.lessThanOrEqualTo("createdAt", param.endDate);
      query.limit(10000);
      query.ascending("createdAt");
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const getAttendanceForClassId = (classId, startDate, endDate) => {
  try {
    return new Promise((resolve, reject) => {
      var Attendance = Parse.Object.extend("Attendance");
      var query = new Parse.Query(Attendance);
      query.equalTo("Class", classId);
      query.greaterThanOrEqualTo("createdAt", startDate);
      query.lessThanOrEqualTo("createdAt", endDate);

      query.find().then((results) => {
        resolve(results);
      });

    })
  } catch (error) {
    console.log(error)
  }
}

export const getAttendanceForDaycareId = (daycareId, startDate, endDate) => {
  try {
    return new Promise((resolve, reject) => {
      var Attendance = Parse.Object.extend("DaycareTimeSheet");
      var query = new Parse.Query(Attendance);
      query.equalTo("daycareClassId", daycareId);
      query.greaterThanOrEqualTo("createdAt", startDate);
      query.lessThanOrEqualTo("createdAt", endDate);

      query.find().then((results) => {
        resolve(results);
      });

    })
  } catch (error) {
    console.log(error)
  }
}