import React, { useEffect, useState } from 'react';
import { Container,Row, Col,TabContent,TabPane,Card,CardText,Nav,NavItem ,NavLink, CardBody,Input} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { matchPath } from "react-router";
import AdmissionList from './AdmissionList'
import TopBar from '../Enquiry/Topbar';
import AdmissionFormSetup from './AdmissionFormSetup'
import { getAdmissionKidsForSchool, setSelecetdSchool, getFranchise } from '../../store/actions';
import * as Constants from '../../Constents';
import { set } from 'react-hook-form';

const Index = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/admissions/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;

    const [customActiveTab, setcustomActiveTab] = useState("1");
    function toggleCustom(tab) {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    }

    const [showSetUp, setShowSetUp] = useState(true);
    const [searchString, setSearchString] = useState('');
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
            props.getFranchise(props.selectedSchool.attributes.franchiseId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {

        if (props.franchise &&
            props.franchise.attributes.features &&
            props.franchise.attributes.features.length > 1 &&
            props.franchise.attributes.features[1] > 0) {

            setShowSetUp(false);
            setcustomActiveTab("1");
        } else {
            setShowSetUp(true);
        }
    }, [props.franchise]);





    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid>



                    <TopBar
                        title="Admissions "
                        showSearchBar={customActiveTab === "1"?true:false}
                        schoolId={schoolId}
                        onSearch={(s)=>{setSearchString(s);}}
                    />
                   {showSetUp && <Card className="mb-0">
                        <CardBody>
                            <Nav pills className="navtab-bg nav-justified ">
                                
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1");
                                        }}
                                    >
                                        {props.t('Admissions').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                               
                                       
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2");
                                        }}
                                    >
                                        {props.t('Setup').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                            </Nav>

                        
                        </CardBody>
                    </Card>}
                     <TabContent activeTab={customActiveTab}>
                        <TabPane tabId="1" > <AdmissionList schoolId={schoolId} searchString={searchString} /></TabPane>
                        <TabPane tabId="2" ><AdmissionFormSetup schoolId={schoolId} /> </TabPane>
                        
                    </TabContent>
                </Container>
            </div>
        </React.Fragment>
            
    );
}
        
const mapStatetoProps = state => {
    const { admissionKids } = state.Kid;
    const { selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    return { admissionKids,selectedSchool,franchise};
}
            
export default withNamespaces()(withRouter(connect(mapStatetoProps,{getAdmissionKidsForSchool,setSelecetdSchool,getFranchise})(Index)));