export const UPDATE_REMINDER = "UPDATE_REMINDER";
export const DELETE_REMINDER = "DELETE_REMINDER";


export const UPDATE_REMINDERS = "UPDATE_REMINDERS";
export const GET_REMINDER = "GET_REMINDER";
export const GET_REMINDERS = "GET_REMINDERS";
export const SET_SELECTED_REMINDER = "SET_SELECTED_REMINDER";
export const UPDATE_SELECTED_REMINDER = "UPDATE_SELECTED_REMINDER";


export const REMINDER_SUCCESS = "REMINDER_SUCCESS";
export const REMINDER_ERROR = "REMINDER_ERROR";

export const GET_ALL_REMINDERS_FOR_SCHOOL = "GET_ALL_REMINDERS_FOR_SCHOOL";
export const SAVE_ALL_REMINDERS = "SAVE_ALL_REMINDERS";

export const EDIT_REMINDER = "EDIT_REMINDER";

