import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, CardSubtitle } from "reactstrap";
import Parse from 'parse';

//Import Images
import error from "../../assets/images/user-login.png";
import * as WhiteLable from "../../components/Common/WhiteLable";

const UserDisableWarning = (props) => {

    return (

        <React.Fragment>
            <section className="py-4" >
                <Container className='align-items-center'>
                    <Card className='mt-4'>
                        <CardBody>

                            <Row className=" align-items-center">
                                <Col >
                                    <div className='p-4'>
                                        <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "150px" }} />
                                    </div>
                                    <div>
                                        <img src={error} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>
                                <Col >
                                    <div>

                                    </div>
                                    <blockquote className="blockquote">
                                        <header className='text-danger'>Alert!</header>
                                        <p>{props.message ?? "Your access has been removed. Please contact your Institute admin team or lilTriangle customer care!"}</p>
                                        <p className='' style={{ border: '1px solid black', padding: '5px' }}>
                                            {`User Id: ${Parse.User.current().id}`}<br />
                                            {`User Name: ${Parse.User.current().attributes.username}`}
                                        </p>
                                        <br />
                                        <footer className="blockquote-footer">Call Us On: +91 7676548853 or  <cite title="Source Title">Email to: <a href="mailto: hello@liltriangle.com">hello@liltriangle.com</a></cite></footer>
                                    </blockquote>
                                </Col>

                            </Row>
                            <Row className="d-flex justify-content-end">
                                <Col className="d-flex justify-content-end">
                                    <Link to="/login"> Login</Link>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Container>
            </section>
        </React.Fragment>
    );
}

export default UserDisableWarning;