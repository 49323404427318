import Parse from "parse";
import { printLog } from "./loghelper";

export const getCourseFromParse = (courseId, isLocal) => {
  printLog("HL: course_helper : getCourseFromParse")
  try {
    return new Promise((resolve, reject) => {
      var Course = Parse.Object.extend("Course");
      var query = new Parse.Query(Course);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.get(courseId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}

export const deleteCourseFromParse = (course) => {
  printLog("HL: course_helper : deleteCourseFromParse")
  try {
    return new Promise((resolve, reject) => {

      course.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        course.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        course.set("deletedBy", Parse.User.current().id);
      }

      course.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
}

export const getAllCoursesForSchoolId = (id) => {
  printLog("HL: course_helper : getAllCoursesForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Course = Parse.Object.extend("Course");
      var query = new Parse.Query(Course);
      query.equalTo("ownerId", id);


      query.notEqualTo("isDeleted", true);

      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}


export const getCourseForownerId = (id, updatedDate, isLoacal) => {
  printLog("HL: course_helper : getCourseForownerId")
  try {
    return new Promise((resolve, reject) => {
      var Course = Parse.Object.extend("Course");
      var query = new Parse.Query(Course);
      query.equalTo("ownerId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}


export const getCoursesForOwnerIds = (ids, isLoacal) => {
  printLog("HL: course_helper : getCoursesForOwnerIds")
  try {
    return new Promise((resolve, reject) => {
      var Course = Parse.Object.extend("Course");
      var query = new Parse.Query(Course);
      query.containedIn("ownerId", ids);



      if (isLoacal) {
        query.fromLocalDatastore();

      }
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}
export const saveCourseObject = (object) => {
  printLog("HL: course_helper : saveCourseObject")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    });
  } catch (error) { }
}

export const saveObjects = (objects) => {
  printLog("HL: course_helper : saveObjects")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
}