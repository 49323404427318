import { takeEvery, takeLeading,fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as AdmissionTitleHelper from "../../../helpers/ParseHelpers/admissionTitle_helper";
import * as actionTypes from "./actionTypes";
import * as AdmissionTitleActions from "./actions";

import * as Constants from '../../../Constents';

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getAAdmissionTitle({ payload: { id } }) {
  try {
    const response = yield call( AdmissionTitleHelper.getAdmissionTitleFromParse, id,false);
    yield put(AdmissionTitleActions.updateAdmissionTitle(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAAdmissionTitle({ payload: { admissionTitle } }) {
  try {
    const response = yield call(
        AdmissionTitleHelper.deleteAdmissionTitleFromParse,
        admissionTitle
    );
    if (response) {
      yield put(AdmissionTitleActions.updateAdmissionTitle(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getAdmissionTitleForSchoolId({ payload: { schoolId,setup } }) {
  try {
    var key = Constants.ADMISSION_TITLE_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call( AdmissionTitleHelper.getAdmissionTitleForSchoolId,schoolId,null,true);
    if (localResponse && localResponse.length > 0) {
      yield put(AdmissionTitleActions.updateAdmissiontitles(localResponse));
      if (setup) {
        setup = false;
      }
    }

    const serverResponse = yield call( AdmissionTitleHelper.getAdmissionTitleForSchoolId,schoolId,updatedTime,false,setup);
    localStorage.setItem(key,newTime)
    if (serverResponse && serverResponse.length > 0) {
      yield put(AdmissionTitleActions.updateAdmissiontitles(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }

  
}

function* addAdmissionTitle({ payload: { admissionTitle } }) {
  try {
  
    yield put(AdmissionTitleActions.updateAdmissionTitle(admissionTitle));
    const response = yield call(
      AdmissionTitleHelper.editTitle,admissionTitle
    );
    if (response === "Error") {

      toastr.error("Please try again", { timeOut: 3000 });
      const local = yield call(
        AdmissionTitleHelper.getAdmissionTitleFromLocal,admissionTitle.id
      );

      if (local) {
        yield put(AdmissionTitleActions.updateAdmissionTitle(local));
      }
  
    }else 
    if (response) {
      yield put(AdmissionTitleActions.updateAdmissionTitle(response));
      toastr.success("Section successfully added", { timeOut: 3000 });
      response.pin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editAdmissionTitle({ payload: { admissionTitle } }) {
  try {
    const response = yield call(AdmissionTitleHelper.editTitle, admissionTitle );
   
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    }else 
    if (response) {
      yield put(AdmissionTitleActions.updateAdmissionTitle(response));
      response.pin();
      toastr.success("Section name updated", { timeOut: 3000 });
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* updateAdmissionTitleOrder({ payload: { admissionTitles ,schoolId} }) {
  try {
    const response = yield call(AdmissionTitleHelper.saveAdmissionTitle,admissionTitles );
   
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    }else 
    if (response) {
      
      Parse.Object.pinAll(response);


      const localResponse = yield call( AdmissionTitleHelper.getAdmissionTitleForSchoolId, schoolId,null,true);
      if (localResponse && localResponse.length > 0) {
        yield put(AdmissionTitleActions.reloadAdmissionTitles(localResponse));
      }
      toastr.success("Titles successfully updated", { timeOut: 3000 });
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
export function* watchGetAdmissionTitle() {
  yield takeEvery(actionTypes.GET_ADMISSION_TITLE, getAAdmissionTitle);
}

export function* watchDeleteAdmissionTitle() {
  yield takeEvery(actionTypes.DELETE_ADMISSION_TITLE, deleteAAdmissionTitle);
}

export function* watchGetAdmissionTitleForSchoolId() {
  yield takeEvery(actionTypes.GET_ADMISSION_TITLES, getAdmissionTitleForSchoolId);
}

export function* watchAddAdmissionTitle() {
  yield takeEvery(actionTypes.ADD_ADMISSION_TITLE, addAdmissionTitle);
}

export function* watchEditAdmissionTitle() {
  yield takeEvery(actionTypes.EDIT_ADMISSION_TITLE, editAdmissionTitle);
}
export function* watchUpdateAdmissionTitleSortOrder() {
  yield takeLeading(actionTypes.UPDATE_ADMISSION_TITLE_SORT_ORDER,updateAdmissionTitleOrder );
}

function* AdmissionTitleSaga() {
  yield all([
    fork(watchGetAdmissionTitle),
    fork(watchDeleteAdmissionTitle),
    fork(watchGetAdmissionTitleForSchoolId),
    fork(watchAddAdmissionTitle),
    fork(watchEditAdmissionTitle),
    fork(watchUpdateAdmissionTitleSortOrder),
  ]);
}
export default AdmissionTitleSaga;
