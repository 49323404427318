import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as ReminderHelper from "../../../helpers/ParseHelpers/reminder_helper";
import * as actionTypes from "./actionTypes";
import * as ReminderActions from "./actions";
import * as Constants from "../../../Constents";

function* getAReminder({ payload: { id } }) {
  try {
    const response = yield call(
      ReminderHelper.getReminderFromParse,
      id,
      false
    );
    yield put(ReminderActions.updateReminder(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAReminder({ payload: { reminder } }) {
  try {
    const response = yield call(
      ReminderHelper.deleteReminderFromParse,
      reminder
    );
    if (response) {
      yield put(ReminderActions.updateReminder(response));
      response.unPin();
    } else {
      // not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getAllRemindersForSchool({ payload: { schoolId, classIds, groupIds, familyIds } }) {
  try {
    var key = Constants.REMINDER_UPDATED_TIME + "__" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(ReminderHelper.getRemindersForSchool, schoolId, classIds, groupIds, familyIds, null, true);
    if (localResponse && localResponse.length > 0) {
      yield put(ReminderActions.updateReminders(localResponse));
    }


    const serverResponse = yield call(ReminderHelper.getRemindersForSchool, schoolId, classIds, groupIds, familyIds, updatedTime, false);

    if (serverResponse ) {
      localStorage.setItem(key, newTime)

      yield put(ReminderActions.updateReminders(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }


  } catch (error) {
    // yield put(apiError(error));
  }
}


function* saveAllRemindersSaga({ payload: { reminders } }) {
  try {
    const response = yield call(ReminderHelper.saveAllReminders, reminders);
    if (response) {
      yield put(ReminderActions.updateReminders(response));
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* editReminder({ payload: { reminder,emailNotification } }) {
  try {
    const response = yield call(ReminderHelper.editReminder, reminder,emailNotification);
    if (response) {
      yield put(ReminderActions.updateReminder(response));
      response.pin();
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}




// function* setSelectedReminder({ payload: { id } }) {
//   try {
//     const localResponse = yield call(
//       ReminderHelper.getReminderFromParse,
//       id,
//       true
//     );
//     if (localResponse) {
//       yield put(
//         ReminderActions.updateSelectedReminder(localResponse)
//       );
//     }

//     const serverResponse = yield call(
//       ReminderHelper.getReminderFromParse,
//       id,
//       false
//     );
//     if (serverResponse) {
//       yield put(
//         ReminderActions.updateSelectedReminder(serverResponse)
//       );
//       serverResponse.pin();
//     }
//   } catch (error) {
//     // yield put(apiError(error));
//   }
// }


export function* watchEditReminder() {
  yield takeEvery(actionTypes.EDIT_REMINDER, editReminder);
}
export function* watchGetReminder() {
  yield takeEvery(actionTypes.GET_REMINDER, getAReminder);
}
export function* watchSaveAllRemindersSaga() {
  yield takeEvery(actionTypes.SAVE_ALL_REMINDERS, saveAllRemindersSaga);
}

export function* watchGetAllRemindersForSchool() {
  yield takeEvery(actionTypes.GET_ALL_REMINDERS_FOR_SCHOOL, getAllRemindersForSchool);
}


export function* watchDeleteReminder() {
  yield takeEvery(actionTypes.DELETE_REMINDER, deleteAReminder);
}

// export function* watchSetSelectedReminder() {
//   yield takeEvery(
//     actionTypes.SET_SELECTED_REMINDER,
//     setSelectedReminder
//   );
// }


function* reminderSaga() {
  yield all([
    fork(watchGetReminder),
    fork(watchDeleteReminder),
    fork(watchGetAllRemindersForSchool),
    fork(watchSaveAllRemindersSaga),
    fork(watchEditReminder),

    // fork(watchSetSelectedReminder),
  ]);
}
export default reminderSaga;
