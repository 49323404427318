import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as VerifiedLogo } from "../../assets/images/certificate/verifiedLogo.svg";
import { ReactComponent as Divider } from "../../assets/images/certificate/separator.svg";

import profileImg from "../../assets/images/users/userXYZ.png";
import * as WhiteLabel from "../../components/Common/WhiteLable";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Label,
} from "reactstrap";
import * as Constants from "../../Constents";
import Moment from "moment";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Parse from "parse";
import toastr from "toastr";
import logo from "../../assets/images/logo-dark.png";
import QRCode from 'qrcode'


const StudentIdCardBackView = (props) => {

    const [QrCode, setQrCode] = useState(undefined);
    const [parent, setParent] = useState(undefined);

    useEffect(() => {
        if (props.kid) {
            getQRCode(props.kid.id)
            let parent = {}
            if (props.kid.parents) {
                for (let k in props.kid.parents) {
                    if (props.kid.parents[k].attributes.Relation === "Mother") {
                        parent.mother = props.kid.parents[k];
                    } else {
                        parent.father = props.kid.parents[k];
                    }
                }
            } else if (props.parents) {
                for (let k in props.parents) {
                    if (props.parents[k].attributes.FamilyID === props.kid.attributes.FamilyID && props.parents[k].attributes.Relation === "Mother") {
                        parent.mother = props.parents[k];
                    } else if (props.parents[k].attributes.FamilyID === props.kid.attributes.FamilyID) {
                        parent.father = props.parents[k];
                    }
                }
            }
            setParent(parent)
        }
    }, [props.kid])

    function getQRCode(kidId) {
        QRCode.toDataURL(kidId, { errorCorrectionLevel: 'H' })
            .then(url => {
                setQrCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }



    const getPotrite = () => {
        return (

            <div style={{
                width: props.selectedPrintSetting.cardWidth,
                minHeight: props.selectedPrintSetting.cardHeight,
                backgroundColor: "white",
                border: props.selectedPrintSetting.borderOff ?? "1px solid lightGray",
                borderRadius: "10px",
            }}
                className="ml-3 dont-cut-element-when-printing"
            >



                <div style={{ minHeight: props.selectedPrintSetting.topHeaderDivHeight, maxHeight: props.selectedPrintSetting.topHeaderDivHeight, overflow: "hidden", paddingTop: "2mm" }}>
                </div>
                {parent && <div>

                    <div className="mt-1 mr-2 mb-0">
                        <Row className="m-0 p-0" lg={props.selectedPrintSetting.rowForKidDetails} md={props.selectedPrintSetting.rowForKidDetails} sm={props.selectedPrintSetting.rowForKidDetails} xs={props.selectedPrintSetting.rowForKidDetails}>
                            <Col lg={props.selectedPrintSetting.colForKidDetails} md={props.selectedPrintSetting.colForKidDetails} sm={props.selectedPrintSetting.colForKidDetails} xs={props.selectedPrintSetting.colForKidDetails}
                                className="pl-0 pr-2 text-center justify-content-center"
                            >
                                {parent.father && <>
                                    <div className={""} >
                                        <img src={(parent.father.attributes.Photo) ? parent.father.attributes.Photo._url : profileImg} alt="img" className="rounded text-center" style={{ height: "17mm", width: "17mm", objectFit: "contain" }} />
                                    </div>
                                    <p style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "x-small" }} className={" mb-0 p-0"}><strong>{`${parent.father.attributes.Name}`}</strong>{`, ${parent.father.attributes.Relation}`}</p>
                                    <p style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "x-small" }} className="m-0 p-0 mb-3">{parent.father.attributes.PhoneNumber}</p>
                                </>}
                                {parent.mother && <>
                                    <div className={""} >
                                        <img src={parent.mother.attributes.Photo ? parent.mother.attributes.Photo._url : profileImg} alt="img" className="rounded text-center" style={{ height: "17mm", width: "17mm", objectFit: "contain" }} />
                                    </div>
                                    <p style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "x-small" }} className={" mb-0 p-0"}><strong>{`${parent.mother.attributes.Name}`}</strong>{`, ${parent.mother.attributes.Relation}`}</p>
                                    <p style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "x-small" }} className="m-0 p-0">{parent.mother.attributes.PhoneNumber}</p>
                                </>}
                            </Col>

                        </Row>
                    </div>
                </div>}
            </div >
        );
    }

    const getLandscape = () => {
        return (

            <div style={{
                width: props.selectedPrintSetting.cardWidth,
                height: props.selectedPrintSetting.cardHeight,
                backgroundColor: "white",
                border: props.selectedPrintSetting.borderOff ?? "1px solid lightGray",
                borderRadius: "10px",
            
            }}
                className="ml-3 dont-cut-element-when-printing"
            >



                <div style={{ minHeight: props.selectedPrintSetting.topHeaderDivHeight, maxHeight: props.selectedPrintSetting.topHeaderDivHeight, }}>
                    
                </div>
                {parent && (
                    <div className="text-center justify-content-center " style={{ minHeight: props.selectedPrintSetting.kidDetailHeight, overflow: "hidden" }}>
                        <Row >
                            <Col lg={6} md={6} sm={6} xs={6}>
                                {parent.father && (
                                    <>
                                        <div>
                                            <img
                                                src={parent.father.attributes.Photo ? parent.father.attributes.Photo._url : profileImg}
                                                alt="img"
                                                className="rounded text-center"
                                                style={{ height: "25mm", width: "25mm", objectFit: "contain" }}
                                            />
                                        </div>
                                        <p style={{ fontSize: props.bodyFontSize ? `${props.bodyFontSize * 0.25}mm` : "x-small" }} className="mb-0 p-0">
                                            <strong>{`${parent.father.attributes.Name}`}</strong>, {`${parent.father.attributes.Relation}`}
                                        </p>
                                        <p style={{ fontSize: props.bodyFontSize ? `${props.bodyFontSize * 0.25}mm` : "x-small" }} className="m-0 p-0 mb-3">
                                            {parent.father.attributes.PhoneNumber}
                                        </p>
                                    </>
                                )}
                            </Col>
                            <Col lg={6} md={6} sm={6} xs={6}>
                                {parent.mother && (
                                    <>
                                        <div>
                                            <img
                                                src={parent.mother.attributes.Photo ? parent.mother.attributes.Photo._url : profileImg}
                                                alt="img"
                                                className="rounded text-center"
                                                style={{ height: "25mm", width: "25mm", objectFit: "contain" }}
                                            />
                                        </div>
                                        <p style={{ fontSize: props.bodyFontSize ? `${props.bodyFontSize * 0.25}mm` : "x-small" }} className="mb-0 p-0">
                                            <strong>{`${parent.mother.attributes.Name}`}</strong>, {`${parent.mother.attributes.Relation}`}
                                        </p>
                                        <p style={{ fontSize: props.bodyFontSize ? `${props.bodyFontSize * 0.25}mm` : "x-small" }} className="m-0 p-0">
                                            {parent.mother.attributes.PhoneNumber}
                                        </p>
                                    </>
                                )}
                            </Col>
                        </Row>
                    </div>
                )}

            </div >
        );
    }

    return (
        <div>
            {props.orientaion == "P" ? getPotrite() : getLandscape()}
        </div>
    );
};

const mapStatetoProps = (state) => { return {}; };


export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {

        })(StudentIdCardBackView)
    )
);