import Parse from "parse";
import school from "../../store/model/school/reducer";
import { printLog } from "./loghelper";
const getEnquiryFromParse = (enquiryId) => {
  printLog("HL: enquiry_helper : getEnquiryFromParse")

  try {
    return new Promise((resolve, reject) => {
      var Enquiry = Parse.Object.extend("Enquiry");
      var query = new Parse.Query(Enquiry);
      query.get(enquiryId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

const deleteEnquiryFromParse = (enquiryId) => {
  printLog("HL: enquiry_helper : deleteEnquiryFromParse")
  try {
    return new Promise((resolve, reject) => {
      const Enquiry = Parse.Object.extend("Enquiry");
      var enquiry = new Enquiry();
      enquiry.id = enquiryId;
      enquiry.set("deleted", true);
      enquiry.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        enquiry.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        enquiry.set("deletedBy", Parse.User.current().id);
      }

      enquiry.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

const deleteEnquiriesFromParse = (enquiries) => {
  printLog("HL: enquiry_helper : deleteEnquiriesFromParse")
  try {
    return new Promise((resolve, reject) => {
      for (const i in enquiries) {
        enquiries[i].set("deleted", true);
        enquiries[i].set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
          enquiries[i].set(
            "deletedBy",
            Parse.User.current().attributes.teacherId
          );
        } else {
          enquiries[i].set("deletedBy", Parse.User.current().id);
        }
      }
      Parse.Object.saveAll(enquiries).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(undefined);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
};

const restoreEnquiriesFromParse = (enquiries) => {
  printLog("HL: enquiry_helper : restoreEnquiriesFromParse")
  try {
    return new Promise((resolve, reject) => {
      for (const i in enquiries) {
        enquiries[i].set("deleted", false);
        enquiries[i].set("isDeleted", false);
        if (Parse.User.current().attributes.teacherId) {
          enquiries[i].set(
            "restoredBy",
            Parse.User.current().attributes.teacherId
          );
        } else {
          enquiries[i].set("restoredBy", Parse.User.current().id);
        }
      }
      Parse.Object.saveAll(enquiries).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(undefined);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
};

const getEnquirysForSchoolId = (id, updatedDate, isLoacal) => {
  printLog("HL: enquiry_helper : getEnquirysForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Enquiry = Parse.Object.extend("Enquiry");
      var query = new Parse.Query(Enquiry);
      query.equalTo("schoolId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("deleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("deleted", true);
      }

      query.descending("createdAt");
      // query.notEqualTo("status", 1)
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

const getDeletedEnquirysForSchoolId = (id, updatedDate, isLoacal) => {
  printLog("HL: enquiry_helper : getDeletedEnquirysForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Enquiry = Parse.Object.extend("Enquiry");
      var query = new Parse.Query(Enquiry);
      query.equalTo("schoolId", id);
      query.equalTo("deleted", true);
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } 
      if (isLoacal) {
        query.fromLocalDatastore();
      }
      query.descending("updatedAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {
    console.log(error)
  }
};

const getEnquiryforTag = (id) => {
  printLog("HL: enquiry_helper : getEnquiryforTag")
  try {
    return new Promise((resolve, reject) => {
      var Enquiry = Parse.Object.extend("Enquiry");
      var query = new Parse.Query(Enquiry);
      query.exists("enquiryFor", id);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

const editEnquiry = (enquiry) => {
  printLog("HL: enquiry_helper : editEnquiry")
  try {
    return new Promise((resolve, reject) => {
      enquiry.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
const saveParseObject = (parseObject) => {
  printLog("HL: enquiry_helper : saveParseObject")
  try {
    return new Promise((resolve, reject) => {
      parseObject.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

const getEnquirisForGivenDates = (id, startDate, endDate, isLoacal) => {
  printLog("HL: enquiry_helper : getEnquirisForGivenDates")
  try {
    return new Promise((resolve, reject) => {
      var Enquiry = Parse.Object.extend("Enquiry");
      var query = new Parse.Query(Enquiry);
      query.equalTo("schoolId", id);

      query.greaterThanOrEqualTo("createdAt", startDate);
      query.lessThanOrEqualTo("createdAt", endDate);

      if (isLoacal) {
        query.fromLocalDatastore();
      }
      query.notEqualTo("deleted", true);
      query.ascending("createdAt");
      // query.notEqualTo("status",1)
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
export const getKidObjFromEnquiry = (enqId, isLoacal) => {
  printLog("HL: enquiry_helper : getKidObjFromEnquiry")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Kid");
      query.equalTo("enquiryId", enqId);
      if (isLoacal) {
        query.fromLocalDatastore();
      }
      query.first().then((result) => {
        resolve(result);
      });
    });
  } catch (err) {
    console.log(err);
  }
};
export const getAdduQuestionParse = (schoolId, instituteId, franchiseId) => {
  printLog("HL: enquiry_helper : getAdduQuestionParse")
  try {
    return new Promise((resolve, reject) => {
      let query1 = new Parse.Query("AdmissionTitle");
      query1.equalTo("schoolId", schoolId);
      let query2 = new Parse.Query("AdmissionTitle");
      query2.equalTo("instituteId", instituteId);
      let query3;
      if (franchiseId) {
        query3 = new Parse.Query("AdmissionTitle");
        query3.equalTo("schoolId", schoolId);
      }
      let query;
      if (query3) {
        query = Parse.Query.or(query1, query2, query3);
      } else {
        query = Parse.Query.or(query1, query2);
      }
      query.find().then((results) => {
        let questionsIds = [];
        for (let i in results) {
          questionsIds.push(results[i].id);
        }
        if (questionsIds.length > 0) {
          let query = new Parse.Query("AdmissionQuestion");
          query.containedIn("admissionTitleId", questionsIds);
          query.notEqualTo("deleted", true);
          query.ascending("order");
          query.find().then((results) => {
            resolve(results);
          });
        } else {
          resolve(results);
        }
      });
    });
  } catch (error) { }
};
const saveParseObjects = (objects) => {
  printLog("HL: enquiry_helper : saveParseObjects")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(undefined);
          console.log("Error", error);
        }
      );
    });
  } catch (error) {
    console.log("Error", error);
  }
};

const getParentsForFamilyId = (familyId) => {
  printLog("HL: enquiry_helper : getParentsForFamilyId")
  try {
    return new Promise((resolve, reject) => {
      var parentQueryObj = Parse.Object.extend("Parent");
      var mainQuery = new Parse.Query(parentQueryObj);
      mainQuery.equalTo("FamilyID", familyId);
      mainQuery.notEqualTo("isDeleted", true);
      mainQuery.limit(1000);
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

const getCADForEnquiryId = (id) => {
  printLog("HL: enquiry_helper : getCADForEnquiryId")
  try {
    return new Promise((resolve, reject) => {
      var ChildAdditionalDetails = Parse.Object.extend(
        "ChildAdditionalDetails"
      );
      var mainQuery = new Parse.Query(ChildAdditionalDetails);
      mainQuery.equalTo("enquiryId", id);
      mainQuery.notEqualTo("isDeleted", true);
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

const getKidForEnquiryId = (id) => {
  printLog("HL: enquiry_helper : getKidForEnquiryId")
  try {
    return new Promise((resolve, reject) => {
      var Kid = Parse.Object.extend(
        "Kid"
      );
      var mainQuery = new Parse.Query(Kid);
      mainQuery.equalTo("enquiryId", id);
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
};

const getEnquiryTemplate = (teacherId, schoolId, instituteId, updatedDate, isLocal) => {
  try {
    return new Promise((resolve, reject) => {
      var TemplateUser = Parse.Object.extend("Template");
      var TemplateSchool = Parse.Object.extend("Template");
      var TemplateInstitute = Parse.Object.extend("Template");
      var TemplateDefault = Parse.Object.extend("Template");


      var queryU = new Parse.Query(TemplateUser);
      queryU.equalTo("userId", teacherId);

      var queryS = new Parse.Query(TemplateSchool);
      queryS.equalTo("schoolId", schoolId);
      // queryS.doesNotExist('userId')

      var queryI = new Parse.Query(TemplateInstitute);
      queryI.equalTo("instituteId", instituteId);
      // queryI.doesNotExist('userId')

      var queryD = new Parse.Query(TemplateDefault);
      queryD.equalTo("defaultTemplate", true);


      let query = Parse.Query.or(queryS,queryI,queryD)

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted",true);
      }

      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted",true);
      }

      // query.descending("defaultTemplate")
      query.descending("createdAt")

      query.limit(1000);
      query.find().then(
        (result) => {
          // console.log(result)
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

// FRANCHISE ENQUIRY
const getFranchiseEnquiryForFranchiseId = (franchiseid, updatedDate,isLoacal) => {
  printLog("HL: enquiry_helper : getFranchiseEnquiryForFranchiseId")
  try {
    return new Promise((resolve, reject) => {
      var enquiry = Parse.Object.extend("FranchiseEnquiry");
      var query = new Parse.Query(enquiry);
      query.equalTo("franchiseId", franchiseid);
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt")
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const saveFranchiseEnquiry = (franchiseEnquiry) => {
  try {
    return new Promise((resolve, reject) => {
      franchiseEnquiry.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

const getFranchiseEnquiryFromParse = (enquiryId) => {

  try {
    return new Promise((resolve, reject) => {
      var FranchiseEnquiry = Parse.Object.extend("FranchiseEnquiry");
      var query = new Parse.Query(FranchiseEnquiry);
      query.get(enquiryId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export {
  getEnquiryFromParse,
  deleteEnquiryFromParse,
  deleteEnquiriesFromParse,
  getEnquirysForSchoolId,
  getEnquiryforTag,
  editEnquiry,
  getEnquirisForGivenDates,
  saveParseObject,
  saveParseObjects,
  getParentsForFamilyId,
  getCADForEnquiryId,
  getKidForEnquiryId,
  getDeletedEnquirysForSchoolId,
  restoreEnquiriesFromParse,
  getEnquiryTemplate,
  getFranchiseEnquiryForFranchiseId,
  saveFranchiseEnquiry,
  getFranchiseEnquiryFromParse
};
