import React, { useEffect,useState} from 'react';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool} from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  CardHeader,
  CardTitle
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import Image from "../../../assets/images/growing_chart.svg";


const RoyaltyReport = (props) => {

    const schoolId = props.schoolId

    
    const [reportData, setReportData] = useState(undefined);

    const [monetTransactions, setMonetTransactions] = useState(undefined);
    const [invoiceList, setInvoiceList] = useState(undefined);
    
    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    
    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [loadingState, setLoadingState] = useState(1);

    const titles = ["School Name", "Invoice No","Transaction Date","Transaction Mode","Transaction Amount","Transaction Royalty","Transaction School Share", "Invoice Amount", "Collected Amount", "Total Royalty ", "Collected Royalty",];

    useEffect(() => {
        setFromDate(props.fromDate)
    }, [props.fromDate]);

    useEffect(() => {
        setToDate(props.toDate);
    }, [props.toDate]);

    useEffect(() => {
        if (props.onClickApply) {
            handelDateChange();
        }
    }, [props.onClickApply]);
   


    const handelDateChange = () => {
        setLoadingState(2);
        getReortReportSessions();
        
    }
    
    

    const getDayBetweenDates = (d1, d2) => {
        var diff = d2 - d1;
        return Math.floor(diff / 86400000);

    }
    

    const getReortReportSessions = () => {

        if (!fromDate || !toDate) {
            return;
        }

        
        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {
              
                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('DD-MM-YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }
        setSelectedMonths(dateList);

        let days = getDayBetweenDates(startDate,endDate);

        let query = new Parse.Query("MoneyTransactions");

        query.greaterThanOrEqualTo('createdAt', startDate);
        query.lessThanOrEqualTo('createdAt', endDate);
        query.greaterThan('royaltyAmount', 0);
        query.notEqualTo('deleted', true);
        
        query.limit(10000)


        let schoolIds = [];
        if (props.isFranchise) {
            for (const school of props.schools) {
                schoolIds.push(school.id)
            }
            query.containedIn('schoolId', schoolIds)
        } else {
            query.equalTo('schoolId', schoolId)
        }
        query.ascending("createdAt");
        query.find().then((mts) => {
            
            if (!mts || mts.length === 0) {
                setLoadingState(5)
            } else {
                setMonetTransactions(mts);

                let invoiceIds = [];
                for (const mt of mts) {
                    if(!invoiceIds.includes(mt.attributes.invoiceId)){
                        invoiceIds.push(mt.attributes.invoiceId);
                    }
                }

                let queryI = new Parse.Query("Invoice");
                queryI.containedIn("objectId", invoiceIds);
                queryI.limit(10000);

                queryI.find().then((invoiceList) => {

                    setInvoiceList(invoiceList);
                    prepareDate(mts, invoiceList);
                });
            }
        });

        // Parse.Cloud.run("function_getAllSchoolFromTable").then(
        //     (result) => {
        //         prepareDate(result);
        //     },
        //     (error) => {
        //         resolve(null);
        //     }
        // );
        
    }

    const prepareDate = (mts, invoiceList) => {
        let dataList = [];
        
        
        for (const school of props.schools) {
            // if (school.attributes.franchiseId === "AoJXWHSvRj") {
            //     dataList.push([school.attributes.Name, school.attributes.city, school.attributes.Address, school.attributes.EMail, school.attributes.primaryPhoneNumber, Moment(school.attributes.createdAt).format('DD-MM-YYYY')]);
            // }
            // dataList.push([school.attributes.Name, "2022-23/INV-2", 50000, 35000, 6000, 4000]);
            // dataList.push([school.attributes.Name, "2022-23/INV-3", 40000, 2000, 4000, 200]);
            // dataList.push([school.attributes.Name, "2022-23/INV-4", 20000, 20000, 6000, 6000]);
            // dataList.push([school.attributes.Name, "2022-23/INV-5", 35000, 0, 3500, 0]);

            let schoolsMts = mts.filter(
                (mt) => mt.attributes.schoolId === school.id
            );

            for (const mt of schoolsMts) {

                let invoice = invoiceList.filter(
                    (i) => i.id === mt.attributes.invoiceId);
                
                dataList.push({
                    id: mt.id,
                    moneyTransaction: mt,
                    invoice:invoice[0],
                    
                    transactionData: [
                        school.attributes.Name,
                        invoice[0].attributes.invoiceNumber,

                        Moment(mt.attributes.createdAt).format('DD-MM-YYYY hh:mm a'),
                        mt.attributes.transactionType,
                        mt.attributes.amount,
                        mt.attributes.royaltyAmount,
                        mt.attributes.schoolAmount,

                        invoice[0].attributes.amount,
                        invoice[0].attributes.paidAmount,

                        invoice[0].attributes.royaltyAmount,
                        invoice[0].attributes.collectedRoyalty,
            
                    ]
                });

                
                
            }


            
        }
        setReportData(dataList);
        setLoadingState(3)
    }

    

    const getStringFromList = (list) => {
        let str = "";
        if (list) {
            for (const s of list) {
                str = s + ",";
            }
        }
        return str;
            
    }

  


   

    const onClickDownload = () => {
        let sDate = Moment(fromDate).format('DD-MM-YYYY')
        let eDate = Moment(fromDate).format('DD-MM-YYYY')
            
        let finalData = [];
        let fileName = `Royalty_report_${sDate}_${eDate}.xlsx`;
        let sheetName = sDate + "-" + eDate;


        finalData.push([`Royalty report for all branches`]);
        finalData.push([`Selected Dates : ${sDate} to ${eDate}`,]);

        finalData.push([]);
        finalData.push(titles);

        // finalData = finalData.concat(reportData);

        for (const rd of reportData) {
            let temp = rd.transactionData;
            finalData.push(temp)
        }
        

        // console.log(finalData)
         

        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            
            XLSX.utils.book_append_sheet(wb, ws, "all");
            
            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;
            

            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            XLSX.utils.book_append_sheet(wb, ws, "all");
            XLSX.writeFile(wb,fileName);
        }

       
    }
    

    
    const onClickBackButton = () => {
        window.history.back();
    }

    const getIndexList = () => {
        let list = [];
        for (const i in reportData) {
            list.push(i);
        }
        return list;
    }

    return (
        <React.Fragment>
            {props.showTitle && <CardTitle>Enquiry Analytics Report</CardTitle>}
            {loadingState > 0 && props.showDatePickers &&
                <Col >
                    <Row className="justify-content-center bg-light ml-2">
               
                        <Row>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("From")} :
                                    </Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {
                                            setFromDateError(false)
                                            setFromDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        maxDate={toDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {fromDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("To")} :
                                    </Label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(date) => {
                                            setToDateError(false);
                                            setToDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        minDate={fromDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {toDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={3} >
                                <div className="mt-4 mb-2 p-1">
                                    <button
                                        disabled={loadingState === 2}
                                        onClick={(e, v) => {
                                            let next = true;
                                            if (!toDate) {
                                                setToDateError(true);
                                                next = false;
                                            }
                                            if (!fromDate) {
                                                setFromDateError(true);
                                                next = false;
                                            }
                                            if (next) {
                                                handelDateChange();
                                            }
                            
                                        }}
                                        className="w-md  btn btn-primary"
                                    >
                                        {props.t("Apply")}
                                    </button>
                      
                                </div>
                            </Col>
                        </Row>
                        {/* </Form> */}
                    </Row>
                </Col>
            }
            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "Setting up..." : "Preparing report, it may take more time than anticipated"}</Label>
                    </div>
                </Col>
            }

                
            <Element name={"scrollPossition"} >
                <div></div>
            </Element>

                
            {loadingState === 5 && <Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img mt-8">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{'No data avilable between ' + selectedMonths[0].dateString + " and " + selectedMonths[selectedMonths.length - 1].dateString}</h3>
                    <p>{'Plese select other dates '}.</p>
                    
                    
                </CardBody>
            </Card>}

           
           
            {loadingState == 3 && selectedMonths && selectedMonths.length > 0  && ( <Col xs="12">
                <div className="mt-2 mb-2 p-1 text-center d-flex justify-content-end">
                    <button
                        onClick={() => {
                            if (selectedMonths && selectedMonths.length > 0) {
                                onClickDownload();
                            }
                                                    
                        }}
                        className="w-md  btn btn-primary"
                    >
                        <i className='bx bx-download font-size-16 align-middle m-1'></i>
                        {props.t("Download Report")}
                    </button>   

                </div>
             
            </Col> )
            }
            

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && <Col xs="12">
                <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                            <Thead>
                                <Tr>
                                        

                                    <Th>#</Th>
                                    {titles.map((t, k) => (
                                        <Th key={k} data-priority={k}>{t}</Th>
                                    ))}
                                                           
                                </Tr>
                            </Thead>
                            <Tbody>
                                       
                                {
                                   reportData&&  getIndexList().map((i, key) => (
                                        <Tr key={key}>
                                            <Th >{parseInt(i)+1}</Th>
                                            {reportData[i].transactionData.map((e, key2) => (
                                                <Td key={key2}>{e}</Td>
                                            ))}
                                                    
                                        </Tr>
                                    ))
                                }
                                                      
                            </Tbody>
                        </Table>
                    </div>
                </div>
                
            </Col>
            }

            <div style={{ height: "400px" }}></div>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { schools,selectedSchool } = state.School;
    return { schools, selectedSchool};
}
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
     setSelecetdSchool,
    })(RoyaltyReport)
  )
);
