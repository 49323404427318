import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";
import * as LearningSessionHelper from "../../../helpers/ParseHelpers/learningSession_helper";
import * as actionTypes from "./actionTypes";
import * as LearningSessionAction from "./actions";

function* getLearningSessionForSchoolId({ payload: { schoolId, filter } }) {
    try {
      const localResponse = yield call(LearningSessionHelper.getLearningSessionForSchoolId, schoolId, filter);
      yield put(LearningSessionAction.updateLearningSession(localResponse,true));
  
      const serverResponse = yield call(LearningSessionHelper.getLearningSessionForSchoolId, schoolId, filter);
  
      if (serverResponse && serverResponse.length > 0) {
          yield put(LearningSessionAction.updateLearningSession(serverResponse,true));
          Parse.Object.pinAll(serverResponse);
      }
    } catch (error) {
      // yield put(apiError(error));
  
    }
}

function* getOnlineLearingSessionForSchoolId({ payload: { schoolId, filter } }) {
  try {
    const localResponse = yield call(LearningSessionHelper.getOnlineLearingSessionForSchoolId, schoolId, filter);
    yield put(LearningSessionAction.updateOnlineLearingSession(localResponse));

    const serverResponse = yield call(LearningSessionHelper.getOnlineLearingSessionForSchoolId, schoolId, filter);

    if (serverResponse && serverResponse.length > 0) {
        yield put(LearningSessionAction.updateOnlineLearingSession(serverResponse));
        Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getSelectedLearningSession({ payload: { id } }) {
  try {
    const response = yield call(LearningSessionHelper.getLearningSessionFromParse, id);
    yield put(LearningSessionAction.updateSelectedLearningSession(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getSelectedOnlineLearningSession({ payload: { id } }) {
  try {
    const response = yield call(LearningSessionHelper.getSelectedOnlineLearningSessionFromParse, id);
    yield put(LearningSessionAction.updateSelectedOnlineLearningSession(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getLearningTrackingForSessionId({ payload: { sessionId } }) {
  try {
    const response = yield call(LearningSessionHelper.getLearningTrackForSessionId, sessionId);
    yield put(LearningSessionAction.updateLearningTrackingForSessionId(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getOnlineLearningTrackForSessionId({ payload: { sessionId } }) {
  try {
    const response = yield call(LearningSessionHelper.getOnlineLearningTrackForSessionId, sessionId);
    yield put(LearningSessionAction.updateOnlineLearningTrackForSessionId(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

  export function* watchGetAssessmentForSchoolId() {
    yield takeEvery(actionTypes.GET_LEARNING_SESSION_FOR_SCHOOL_ID, getLearningSessionForSchoolId);
  }

  export function* watchGetHomeLearningForSchoolId() {
    yield takeEvery(actionTypes.GET_ONLINE_LEARNING_SESSION_FOR_SCHOOL_ID, getOnlineLearingSessionForSchoolId);
  }

  export function* watchGetSelectedLearningSession(){
    yield takeEvery(actionTypes.SET_SELECTED_LEARNING_SESSION, getSelectedLearningSession);
  }

  export function* watchSelectedOnlineLearningSession(){
    yield takeEvery(actionTypes.SET_SELECTED_ONLINE_LEARNING_SESSION, getSelectedOnlineLearningSession);
  }

  export function* watchGetLearningTrackingForSessionId(){
    yield takeEvery(actionTypes.GET_LEARNING_TRACKING_FOR_SESSION_ID, getLearningTrackingForSessionId);
  }

  export function* watchGetOnlineLearningTrackForSessionId(){
    yield takeEvery(actionTypes.GET_ONLINE_LEARNING_TRACK_FOR_SESSION_ID, getOnlineLearningTrackForSessionId);
  }

  
function* LearningSessionSaga() {
    yield all([
      fork(watchGetAssessmentForSchoolId),
      fork(watchGetHomeLearningForSchoolId),
      fork(watchGetSelectedLearningSession),
      fork(watchSelectedOnlineLearningSession),
      fork(watchGetLearningTrackingForSessionId),
      fork(watchGetOnlineLearningTrackForSessionId),
    ]);
  }
  export default LearningSessionSaga;
  