import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, CardFooter, UncontrolledTooltip, Label, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
const CardKidView = (props) => {
    const getCorporatePartner = (corporateId) => {
        if (props.corporatePartners && corporateId) {
            for (const cp of props.corporatePartners) {
                if (corporateId == cp.id) {
                    return cp.attributes.name;
                }
            }
        }
    }
    return(
        <React.Fragment>
            
            {
                props.kids && props.kids.map((kid, key) =>
                
                    <Col xl="3" sm="6" key={kid.id}>
                        <Card className="text-center">
                            <CardBody>
                                {
                                    !kid.img ?
                                        <div className="avatar-sm mx-auto mb-4">
                                            <span className={"avatar-title rounded-circle bg-soft-" + kid.color + " text-" + kid.color + " font-size-16"}>
                                                {kid.name ? kid.name.charAt(0) : ''}
                                            </span>
                                        </div>
                                        :
                                        <div className="mb-4">
                                            <img className="rounded-circle avatar-sm" src={kid.img} alt="" />
                                        </div>
                                }
    
                                <h5 className="font-size-15"><Link to="#" className="text-dark">{kid.name}</Link></h5>
                                {kid.motherName && <p className="text-muted mb-0">{kid.motherName}</p>}
                                {kid.fatherName && <p className="text-muted mb-0">{kid.fatherName}</p>}
                                {!kid.motherName && <p className="mb-0" style={{ color: '#fff' }}>.</p>}
                                {!kid.fatherName && <p className="mb-0" style={{ color: '#fff' }}>.</p>}
    
                                <div>
                                    {
                                        kid.className.map((cr, key) =>
                                            <Label
                                                // to={"/classroom/" + kid.schoolId + "/" + cr.id}
                                                className={`badge font-size-11 m-1 badge-${cr.color}`} key={"_skill_" + key}>
                                                {cr.name}
                                            </Label>
                                        )
                                    }
                                    {
                                        kid.className.length === 0 &&
                                        <Link to="#" className="badge badge-primary font-size-11 m-1"></Link>
                                    }
                                    {
                                        kid.corporateId && getCorporatePartner(kid.corporateId) && 
                                        <Label
                                            className="badge font-size-11 m-1 badge-success" >
                                            {getCorporatePartner(kid.corporateId)}
                                        </Label>
                                    }
                                </div>
                            </CardBody>
                            <CardFooter className="bg-transparent border-top">
                                <div className="contact-links d-flex font-size-20">
                                    {/* <div className="flex-fill">
                                        <Link to="#" id={"message" + kid.id}>
                                            <i className="bx bx-message-square-dots"></i>
                                            <UncontrolledTooltip placement="top" target={"message" + kid.id}>
                                                Message
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>*/}
                                    {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && props.showIDCard&&<div className="flex-fill">
                                        <Link
                                            to={"/studentIdcard/" + kid.schoolId + "/" + kid.id} target="_blank" id={"idCard" + kid.id} >
                                            <i className="bx bx-id-card" ></i>
                                            <UncontrolledTooltip placement="top" target={"idCard" + kid.id}>
                                                ID Card
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>}
                                    {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && props.showCertificate && <li className="list-inline-item px-2">
                                        <Link
                                            to={"/bonafide-certificate/" + kid.schoolId + "/" + kid.id} target="_blank" id={"certificate" + kid.id} >
                                            <i className="bx bx-user-check" ></i>
                                            <UncontrolledTooltip placement="top" target={"certificate" + kid.id}>
                                                Bonafide Certificate
                                            </UncontrolledTooltip>
                                        </Link>
                                    </li>}
                                    <div className="flex-fill">
                                        <Link to={`/studentProfile/${kid.schoolId}/${kid.id}`} id={"profile" + kid.id}>
                                            <i className="bx bx-user-circle"></i>
                                            <UncontrolledTooltip placement="top" target={"profile" + kid.id}>
                                                Profile
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
            
                )
            }
            
        </React.Fragment>
    )
}
export default CardKidView;