import React from "react";
import { Row, Col, Form } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-router-dom";

const Breadcrumb = (props) => {
  return (
    <Row className="">
      <Col xl="4" sm="6">
        <div className="m-2">
          <h4 className="mb-0 ml-2 font-size-18">
            {props.t(props.title.toUpperCase())}
          </h4>
        </div>
      </Col>
      <Col lg="8" sm="6">
        <div className="d-flex justify-content-end" style={{ marginBottom: '20px'}}>
          <Link className="btn btn-primary float-right m-1" target="_blank" rel="noopener noreferrer"  to={props.visitorPosterLink}>Poster</Link>
          <Link to={props.addVisitorLink} target="_blank" rel="noopener noreferrer" className="btn btn-primary float-right m-1">{'Add Visitor'}</Link>
        </div>
      </Col>
    </Row>
  );
};

export default withRouter(withNamespaces()(Breadcrumb));
