import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, Table, CardImg } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Parse from 'parse';
import QRCode from 'qrcode'
import * as WhiteLable from "../../components/Common/WhiteLable";
import AppDownalodPosterBg from "../../assets/images/AppPoster.png"
import SaveAsPdf from '../../components/Common/SaveAsPdf';

const AppPoster = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/app-poster/:id",
        exact: true,
        strict: false,
    });
    const parameter = match.params.id;

    const [loading, setLoading] = useState(true);
    const [formQRCode, setFormQRCode] = useState(undefined);

    const [school, setSchool] = useState(undefined);

    const [schoolName, setSchoolName] = useState(undefined);
    const [schoolCity, setSchoolCity] = useState(undefined);
    const [schoolLogo, setSchoolLogo] = useState(undefined);

    const [posterType, setPosterType] = useState("a4");
    const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [machineType, setMachineType] = useState('');

    useEffect(() => {
        getSchool();
        formQr();
    }, [parameter]);

    function formQr() {
        let appLink = WhiteLable.getAppOneLink()
        QRCode.toDataURL(appLink, { errorCorrectionLevel: 'H' })
            .then(url => {
                setFormQRCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }
    function getSchool() {
        var School = Parse.Object.extend("School");
        var query = new Parse.Query(School);

        query.get(parameter).then(
            (result) => {
                setLoading(false);
                setSchoolName(result.attributes.Name);
                setSchoolCity(result.attributes.city);
                if (result.attributes.logo) {
                    setSchoolLogo(result.attributes.logo._url);
                }
                setSchool(result)
            },
            (error) => {
                console.log(error)
            }
        );
    }
    function printPoster() {

        var css = "@page {margin: 0; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        setTimeout(() => {
            window.print();
        },300);
    }

    useEffect(() => {
        const savedValue = localStorage.getItem('dontShowAgain');
        if (savedValue === 'true') {
            setDontShowAgain(true);
        }
        const userAgent = navigator.userAgent.toLowerCase();
        
        if (userAgent.includes('mac')) {
            setMachineType('Mac');
        } else if (userAgent.includes('windows')) {
            setMachineType('Windows');
        } else {
            setMachineType('Unknown');
        }
      }, []);
    
    const handleNextClick = () => {
        setShowSaveAsPdfModal(false);
        setTimeout(() => {
            printPoster(); 
        }, 300); 
    };

    const getButtonSelectedClass = (type) => {

        if (type == posterType) {
            return "btn btn-primary waves-effect waves-light m-2"
        } else {
            return "btn btn-light waves-effect waves-light border-1 m-2"
        }
    }
    return (
        <React.Fragment >
            <Container fluid="lg" className='p-0' >



                {loading ?
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Link to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Link>
                        </div>
                    </Col>

                    :
                    <div className="text-center" >
                        <Row className="text-center" >
                            <Col xs="12" className="d-print-none m-2 mt-3">
                                <div className="text-center">
                                    <button onClick={() => { setPosterType("a4") }} className={getButtonSelectedClass("a4")}>A4</button>
                                    <button onClick={() => { setPosterType("card") }} className={getButtonSelectedClass("card")}>Card</button>
                                    <button onClick={() => { setPosterType("qrcode") }} className={getButtonSelectedClass("qrcode")}>Qr Code</button>
                                    <button onClick={() => {
                                        if (['Windows', 'Mac'].includes(machineType)) {
                                            if (!dontShowAgain) {
                                                setShowSaveAsPdfModal(true); 
                                            } else {
                                                printPoster();
                                            }
                                        }else{
                                            printPoster();
                                        }
                                    }} className="btn btn-success waves-effect waves-light m-2"><i className="fa fa-print"></i>Print/Download Poster</button>
                                    <SaveAsPdf
                                        showModal={showSaveAsPdfModal}
                                        toggleModal={() => setShowSaveAsPdfModal(false)}
                                        dontShowAgain={dontShowAgain}
                                        setDontShowAgain={setDontShowAgain}
                                        onNextClick={handleNextClick}
                                        machineType={machineType}
                                    /> 
                                </div>
                            </Col>
                        </Row>


                        {posterType == "a4" && <Col xs="12" >

                            <center className="bg-white">
                                {/* <hr style={{ border: "solid #ffffff", height: "1mm", backgroundColor: "#fff", margin: '1' }} /> */}

                                <table>
                                    {schoolLogo ? <tr>
                                        <td >
                                            <img className="m-1" src={schoolLogo} width="160px" alt="" />
                                        </td>
                                        <td >
                                            <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                            <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                        </td>
                                    </tr> :

                                        <tr>
                                            <td >
                                                <img className="p2" src={WhiteLable.getAppLogoWithName()} width="0px" height="200px" alt="" />
                                            </td>

                                            <td >
                                                <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                            </td>
                                        </tr>
                                    }
                                </table>
                                <hr style={{ border: "solid #ffffff", height: "1mm", backgroundColor: "#fff", margin: '1' }} />



                                <div style={{ position: "relative" }}>

                                    {/* Linear Gradient Pink */}
                                    <div className="col-xl-12" style={{ height: "395px", paddingTop: "5px", background: "linear-gradient(to bottom, #fe1386,#fe1386)", clipPath: "polygon(0% 0%, 100% 0%, 100% 50%, 0% 100%)" }}>


                                        <div className="col-xl-12  mb-4 mt-4">
                                            <span style={{ fontSize: "20px", color: "#fff" }}>
                                                We know how much you love your child
                                            </span>
                                        </div>
                                        <div className="col-md-12 mt-2 mb-2">
                                            <span style={{ fontSize: " 200px", color: "#fff" }}>
                                                <h2>Scan & Download {WhiteLable.getAppName()} App</h2>
                                            </span>
                                        </div>
                                        <div className="col-md-12 mt-4  ">
                                            <span style={{ fontSize: " 20px", color: "#fff", }}>
                                                if scan not Working search
                                                lilTriangle <br />
                                                in playstore/appstore
                                            </span>
                                        </div>

                                    </div>

                                </div>
                                {/* QR-CODE */}
                                <Row>

                                    <div className='text-center' >
                                        <img className="img-responsive" style={{ width: "280px", marginTop: "1%" }} src={formQRCode} />
                                    </div>
                                    {/* Logo */}
                                    <div className="col-md-12 m-1">
                                        <p className='m-1' style={{ marginLeft: "30mm" }}>{props.t('Powered By')}</p>
                                        <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "130px" }} />
                                    </div>
                                </Row>
                                {/* Linear Gradient Blue */}
                                <div className="col-xl-12 m-1">

                                    <div className="m-2" style={{ height: "37mm", width: "100%", background: "linear-gradient(to bottom, #00008b,#00008b)", clipPath: "polygon(0% 100%, 102% 30%, 100% 102%,0% 100%)" }}>
                                    </div>
                                </div>
                            </center>
                        </Col>}
                        {posterType == "card" && <Col>
                            <center>

                                <div
                                    style={{
                                        minHeight: "200mm",
                                        width: "150mm",
                                        backgroundColor: "#fff",
                                        backgroundImage: `url('${AppDownalodPosterBg}')`,
                                        backgroundPosition: 'bottom',
                                        backgroundSize: '150mm'
                                        // zIndex: -1,
                                    }}
                                >
                                    <hr style={{ border: "solid #ffffff", height: "3mm", backgroundColor: "#fff", margin: '1' }} />

                                    <table>
                                        {schoolLogo ? <tr>
                                            <td >
                                                <img className="p2" src={schoolLogo} width="160" alt="" />
                                            </td>
                                            <td >
                                                <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                            </td>
                                        </tr> :

                                            <tr>
                                                <td >
                                                    <img className="p2" src={WhiteLable.getAppLogoWithName()} width="0px" height="200px" alt="" />
                                                </td>

                                                <td >
                                                    <center><span style={{ fontSize: "30px", fontWeight: "bold" }}>{schoolName}</span></center>
                                                    <center><span style={{ fontSize: "20px", fontWeight: "bold" }}>{schoolCity}</span></center>
                                                </td>
                                            </tr>
                                        }
                                    </table>
                                    <hr className='m-1' style={{ border: "0.1px solid #000000" }} />


                                    <Row>

                                        <div className='text-center'>
                                            <img className="img-responsive" style={{ width: "280px", margin: "1%" }} src={formQRCode} alt="QR Code" />
                                            <p className='m-0' style={{ fontSize: "24px" }}>Scan & Download</p>
                                            <p className='m-0' style={{ fontWeight: 'bolder', fontSize: '36px' }}>{WhiteLable.getAppName()}</p>
                                            <p className='m-0' style={{ fontSize: "24px" }}>App</p>
                                            <hr style={{ border: "1px solid #000000", marginLeft: "5cm", marginRight: "5cm" }} />
                                            {/* <p className='m-0' style={{ fontSize: "16px" }}>If scan not working search</p>
                                            <p className='m-0' style={{ fontWeight: 'bolder', fontSize: '24px' }}>lilTriangle</p>
                                            <p className='m-0' style={{ fontSize: "16px" }}>in Playstore/Appstore</p> */}
                                            {/* <hr style={{ border: "0.1px solid #000000" }} /> */}
                                        </div>
                                        <div className="col-md-12 m-0">
                                            <p className='m-1' style={{ marginLeft: "15mm" }}>{props.t('Powered By')}</p>
                                            <img className="img-responsive" src={WhiteLable.getAppLogoWithName()} style={{ width: "160px" }} />
                                            {/* <img className="img-responsive" src={Appbg} style={{ width: "100%", height:"30mm",}} /> */}

                                        </div>


                                    </Row>
                                </div>
                            </center>
                        </Col>}

                        {posterType == "qrcode" && <Col>
                            <div className='text-center' >
                                <img className="img-responsive" style={{ width: "280px", marginTop: "1%" }} src={formQRCode} />
                                <p className='m-4'>Download lilTriangle App </p>
                            </div>
                        </Col>}

                    </div>
                }
            </Container>
        </React.Fragment>

    );
}



export default withNamespaces()(AppPoster);;