import React, { useState, useEffect } from "react";
import {
  getClassroomsForSchoolId,
  getAllKidsForGroup,
  getCameraForId,
  resetResultCameraAfterSave,
  saveCamera,
  saveCameraAccessObjects,
  getCameraAccessObjectsForCameraId,
  setSelecetdSchool,
  getCameraLiveStreamsForSchoolId,
} from "../../store/actions";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Input,
  Container,

} from "reactstrap";

import DatePicker from "react-datepicker";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import avatar2 from "../../assets/images/users/user.png";

import toastr from "toastr";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";
import Select from "react-select";


const AddCamera = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/addCamera/:schoolId",
    exact: true,
    strict: false,
  });
  const matchEditCamera = matchPath(props.history.location.pathname, {
    path: "/editCamera/:schoolId/:cameraId",
    exact: true,
    strict: false,
  });

  var schoolId = null;
  var cameraId = null;

  if (match) {
    schoolId = match && match.params.schoolId;
  } else if (matchEditCamera) {
    schoolId = matchEditCamera && matchEditCamera.params.schoolId;
    cameraId = matchEditCamera && matchEditCamera.params.cameraId;
  }
  const [saveClicked, setSaveClicked] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  const [selectedKids, setSelectedKids] = useState([]);
  const [initialSelctedKids, setInitialSelctedKids] = useState([])
  const [searchBarString, setSeachBarString] = useState("");
  const [selectedClassroomFilter, setSelectedClassroomFilter] = useState(
    undefined
  );

  const daysOptions = [
    // { value: 1, label: "Sunday", isDisabled: true  },
    { value: 2, label: "Monday" },
    { value: 3, label: "Tuesday" },
    { value: 4, label: "Wednesday" },
    { value: 5, label: "Thursday" },
    { value: 6, label: "Friday" },
    { value: 7, label: "Saturday" },
  ];

  const allDaysOptions = [
    { value: 0, label: "@All Week/(Mon-Sat)" },
    { label: `Days`, options: daysOptions }
  ];

  const [selectedDays, setSelectedDays] = useState([])

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool]);

  var defaultStartTime = new Date();
  defaultStartTime.setHours(9, 0, 0, 0);

  var defaultEndTime = new Date();
  defaultEndTime.setHours(19, 0, 0, 0);

  var defaultMinTime = new Date();
  defaultMinTime.setHours(0, 0, 0, 0);
  var defaultMaxTime = new Date();
  defaultMaxTime.setHours(23, 59, 0, 0);

  const [timeRangeArray, setTimeRangeArray] = useState([]);
  const [classroomList, setClassroomList] = useState([]);
  const [newCameraName, setNewCameraName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [streamingObjectId, setStreamingObjectId] = useState(undefined);

  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }

  useEffect(() => {
    props.resetResultCameraAfterSave(null);
    props.getClassroomsForSchoolId(schoolId);
    props.getAllKidsForGroup(schoolId);

    if (matchEditCamera && schoolId && cameraId) {
      props.getCameraForId(cameraId);
      props.getCameraAccessObjectsForCameraId(cameraId);

    } else if (match) {
      setIsLoading(false);
    }
  }, [schoolId, cameraId]);

  useEffect(() => {
    if (saveSuccess === true) {
      console.log("Coming here to window.location");
      // window.location = "/cameradetail";
      window.history.back();
    }
  }, [saveSuccess]);

  useEffect(() => {
    if (props.resultCameraAfterSave && saveClicked) {
      if (timeRangeArray.length > 0 || (props.cameraAccessObjects && props.cameraAccessObjects.length > 0)) {
        var listOfObjects = [];

        for (var i = 0; i < timeRangeArray.length; i++) {
          let cameraAccessObject = undefined
          if (timeRangeArray[i].accessParseObject == undefined) {
            const CameraAccessObject = Parse.Object.extend("CameraAccess");
            cameraAccessObject = new CameraAccessObject();
          } else {
            cameraAccessObject = timeRangeArray[i].accessParseObject;
          }


          cameraAccessObject.set("schoolId", schoolId);
          cameraAccessObject.set("cameraId", props.resultCameraAfterSave.id);
          cameraAccessObject.set("startTime", timeRangeArray[i].startTime);
          cameraAccessObject.set("endTime", timeRangeArray[i].endTime);
          listOfObjects.push(cameraAccessObject);
        }


        if (props.cameraAccessObjects && props.cameraAccessObjects.length > 0 && matchEditCamera) {
          let deleteList = [];
          for (var i = 0; i < props.cameraAccessObjects.length; i++) {

            var others = listOfObjects.filter(
              (range) => range.id === props.cameraAccessObjects[i].id
            );
            if (!others || others.length === 0) {
              let obj = props.cameraAccessObjects[i];
              obj.set("isDeleted", true);
              if (Parse.User.current().attributes.teacherId) {
                obj.set("deletedBy", Parse.User.current().attributes.teacherId);
              } else {
                obj.set("deletedBy", Parse.User.current().id);
              }
              deleteList.push(obj)
            }
          }
          if (deleteList.length > 0) {
            listOfObjects = listOfObjects.concat(deleteList);
          }
        }
        props.saveCameraAccessObjects(listOfObjects);
      } else {
        setSaveSuccess(true);
      }
    }
  }, [props.resultCameraAfterSave]);

  useEffect(() => {
    if (props.cameraAccessSaveSuccess === true && saveClicked) {
      setSaveSuccess(true);
    }
  }, [props.cameraAccessSaveSuccess]);

  useEffect(() => {
    if (props.classrooms && props.classrooms.length > 0) {
      var tempArray = [...props.classrooms];
      const ClassroomObject = Parse.Object.extend("ClassRoom");
      var tempClassroomObject = new ClassroomObject();
      tempClassroomObject.id = "allClassrooms"
      tempClassroomObject.set("Name", "All Classrooms");
      tempArray.unshift(tempClassroomObject);
      setClassroomList(tempArray);
      setSelectedClassroomFilter(tempClassroomObject);
    }
  }, [props.classrooms]);

  useEffect(() => {
    if (cameraId) {
      props.getCameraForId(cameraId);
    }
  }, [cameraId]);

  useEffect(() => {
    if (props.camera && props.camera.id === cameraId && matchEditCamera) {
      console.log(props.camera)
      setNewCameraName(props.camera.attributes.name);
      setStreamingObjectId(getLiveStreamId(props.camera.attributes.hlsURL, props.camera.attributes.htmlUrl))
      var tempSelectedKidList = [];
      var kidIds = props.camera.attributes.kids;

      for (var i = 0; i < kidIds.length; i++) {
        if (props.kids && props.kids.length > 0) {
          var tempKid = props.kids.filter(
            (kid) => kid.id === kidIds[i]
          );

          if (tempKid && tempKid.length > 0) {
            tempSelectedKidList.push(tempKid[0]);
          }
        }
      }
      setSelectedKids(tempSelectedKidList);
      setInitialSelctedKids(tempSelectedKidList);

      if (props.camera.attributes.availableDays && props.camera.attributes.availableDays.length > 0) {
        let list = []
        for (const day of props.camera.attributes.availableDays) {
          list.push(daysOptions[day - 2])
        }
        setSelectedDays(list)
      } else {
        setSelectedDays(allDaysOptions[0])
      }
      if (isLoading === true) {
        setIsLoading(false);
      }
    }
  }, [props.camera]);

  const getLiveStreamId = (hls, htmlUrl) => {
    if (props.cameraLiveStreams) {
      for (const cls of props.cameraLiveStreams) {
        if ((hls && cls.attributes.hls === hls) || (htmlUrl && cls.attributes.htmlUrl === htmlUrl)) {
          return cls.id;
        }
      }
    }
    return "--"
  }

  const getLiveStreamObject = (id) => {
    if (props.cameraLiveStreams) {
      for (const cls of props.cameraLiveStreams) {
        if (cls.id == id) {
          return cls;
        }
      }
    }
    return undefined
  }

  useEffect(() => {
    if (props.cameraAccessObjects && matchEditCamera) {
      let tempList = []
      for (var i = 0; i < props.cameraAccessObjects.length; i++) {
        let cameraAccessAttributes = props.cameraAccessObjects[i].attributes;
        if (cameraId === cameraAccessAttributes.cameraId) {
          let tempObject = {
            id: props.cameraAccessObjects[i].id,
            startTime: cameraAccessAttributes.startTime,
            endTime: cameraAccessAttributes.endTime,
            accessParseObject: props.cameraAccessObjects[i]
          }
          tempList.push(tempObject)
        }
      }
      setTimeRangeArray(tempList);
    }
  }, [props.cameraAccessObjects]);


  //Day picker

  function handleMulti(selectedMulti) {
    let finalList = [];

    if (!selectedMulti || selectedMulti.length === 0) {
      finalList = selectedMulti;
    } else if (
      selectedDays &&
      selectedDays.length === 1 &&
      selectedDays[0].value == 0
    ) {
      let others = selectedMulti.filter((kid) => kid.value != 0);
      finalList = others;
    } else {
      let others = selectedMulti.filter((kid) => kid.value == 0);

      if (others && others.length > 0) {
        finalList = [allDaysOptions[0]];
      } else {
        finalList = selectedMulti;
      }
    }
    setSelectedDays(finalList);
  }

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const handleCheckboxChange = (kObject) => {
    console.log(kObject.id)
    if (kObject.id === "pickAll") {
      handleSelectAll()
    } else
      if (selectedKids.includes(kObject) == false) {
        var tempSelectedList = [...selectedKids]
        tempSelectedList.push(kObject)
        setSelectedKids(tempSelectedList)
      } else {
        handleCheckboxChangeForSelected(kObject)
      }
  }
  const handleCheckboxChangeForSelected = (kObject) => {
    var tempSelectedList = [...selectedKids];
    removeElement(tempSelectedList, kObject);
    setSelectedKids(tempSelectedList);
  };

  function allPick() {

    let tempList = [...getAllKidsFilteredList()]
    if (tempList && tempList.length > 0) {
      for (const kid of tempList) {
        if (!selectedKids.includes(kid) && kid.id != "pickAll") {
          return false

        }
      }
    }

    return true
  }

  function handleSelectAll() {
    let tempList = [...getAllKidsFilteredList()]

    if (allPick()) {
      let update = selectedKids.filter((kid) => {
        return !tempList.includes(kid)
      })
      setSelectedKids(update)
    }
    else {
      setSelectedKids(tempList)
      let oldList = [...selectedKids]

      for (const kobj of tempList) {

        if (!oldList.includes(kobj) && kobj.id != "pickAll") {
          oldList.push(kobj)
        }

      }
      //let temp = oldList.concat(tempList)
      setSelectedKids(oldList)
    }

  }

  const handleClassroomFilterChange = (value) => {
    var classrooms = classroomList.filter(
      (classroom) => classroom.id === value
    );

    if (classrooms.length > 0) {
      setSelectedClassroomFilter(classrooms[0]);
    }
  };

  const getAllKidsFilteredList = () => {
    var listToReturn = [];
    if (selectedClassroomFilter != undefined) {
      if (selectedClassroomFilter.id === "allClassrooms") {
        listToReturn = [...props.kids];
      } else {
        var i = 0;
        for (i = 0; i < props.kids.length; i++) {
          if (
            props.kids[i].attributes.ClassRoomID === selectedClassroomFilter.id || props.kids[i].attributes.daycareClassId === selectedClassroomFilter.id
          ) {
            listToReturn.push(props.kids[i]);
          }
        }
      }
    }

    if (searchBarString === "") {
    } else {
      listToReturn = listToReturn.filter((object) =>
        object.attributes.Name.toLowerCase().includes(
          searchBarString.toLowerCase()
        )
      );
    }

    // if (selectedKids.length > 0) {
    //   listToReturn = listToReturn.filter(
    //     (object) => !selectedKids.includes(object)
    //   );
    // }

    if (
      listToReturn != null &&
      listToReturn.length > 0 &&
      listToReturn[0].id !== "pickAll"
    ) {
      const KidObjectParse = Parse.Object.extend("Kid");
      var kidObjectParse = new KidObjectParse();
      kidObjectParse.id = "pickAll";
      kidObjectParse.set("Name", "Pick All");
      listToReturn.unshift(kidObjectParse);
    }

    return listToReturn;
  };

  const handleSearchBarTextChange = (value) => {
    setSeachBarString(value);
  };

  const handleSaveButtonPressed = () => {
    if (newCameraName === "") {
      toastr.info("Please fill cctv name !!", { timeOut: 2000 });
      setIsLoading(false);
      return;
    }

    if (selectedKids.length === 0) {
      toastr.info("Please select atleast one student for cctv !!", {
        timeOut: 2000,
      });
      setIsLoading(false);
      return;
    }
    let selectedKidIds = [];
    for (var i = 0; i < selectedKids.length; i++) {
      selectedKidIds.push(selectedKids[i].id);
    }

    let days = [];
    for (var i = 0; i < selectedDays.length; i++) {
      days.push(selectedDays[i].value);
    }

    const CameraObject = Parse.Object.extend("Camera");
    var cameraObject = new CameraObject();

    cameraObject.set("schoolId", schoolId);
    cameraObject.set("kids", selectedKidIds);
    cameraObject.set("name", newCameraName);

    let streamObject = getLiveStreamObject(streamingObjectId);
    if (streamObject) {
      if (streamObject.attributes.hls) {
        cameraObject.set("hlsURL", streamObject.attributes.hls)
      }
      if (streamObject.attributes.htmlUrl) {
        cameraObject.set("htmlUrl", streamObject.attributes.htmlUrl)
      }
    }

    if (days.length == 1 && days[0] == 0) {
      cameraObject.set("availableDays", [])
    } else {
      cameraObject.set("availableDays", days.sort())
    }
    setNewCameraName(newCameraName);
    setIsLoading(true);
    setSaveClicked(true);
    props.saveCamera(cameraObject);
  };

  const handleEditSaveButtonPressed = () => {
    if (newCameraName === "") {
      toastr.info("Please fill camera name !!", { timeOut: 2000 });
      return;
    }

    if (selectedKids.length === 0) {
      toastr.info("Please select atleast one student for cctv !!", {
        timeOut: 2000,
      });
      return;
    }

    if (props.camera) {
      let selectedKidIds = [];
      for (var i = 0; i < selectedKids.length; i++) {
        selectedKidIds.push(selectedKids[i].id);
      }

      let days = [];
      for (var i = 0; i < selectedDays.length; i++) {
        days.push(selectedDays[i].value);
      }

      var cameraObject = props.camera;
      cameraObject.set("name", newCameraName);
      cameraObject.set("kids", selectedKidIds);

      let streamObject = getLiveStreamObject(streamingObjectId);
      if (streamObject) {
        if (streamObject.attributes.hls) {
          cameraObject.set("hlsURL", streamObject.attributes.hls)
        } else {
          cameraObject.unset('hlsURL')
        }
        if (streamObject.attributes.htmlUrl) {
          cameraObject.set("htmlUrl", streamObject.attributes.htmlUrl)
        } else {
          cameraObject.unset("htmlUrl")
        }
      }

      if (days.length == 1 && days[0] == 0) {
        cameraObject.set("availableDays", [])
      } else {
        cameraObject.set("availableDays", days.sort())
      }
      setNewCameraName(newCameraName);

      setIsLoading(true);
      setSaveClicked(true);
      console.log(cameraObject)
      props.saveCamera(cameraObject);
    } else {
      toastr.info("Some error occured, please try again !!", { timeOut: 2000 });
    }
  };

  const getKidPhoto = (kObject) => {
    var kidPhoto = "";

    kidPhoto =
      kObject.attributes.Photo != undefined
        ? kObject.attributes.Photo._url
        : avatar2;

    return kidPhoto;
  };

  const handleAddTimeRange = () => {
    let tempList = [...timeRangeArray];
    tempList.push({ startTime: defaultStartTime, endTime: defaultEndTime });
    setTimeRangeArray(tempList);
  };

  const handleDeleteTimeRange = (timeRange) => {
    let tempList = [...timeRangeArray];
    var others = tempList.filter(
      (range) => range.id !== timeRange.id
    );
    setTimeRangeArray(others);
  };

  return (
    <React.Fragment>
      <Container fluid={true} className={"page-content"}>
        {!isLoading && (
          <Row>
            <Col lg={12} className="mx-auto">
              <Card>
                <CardBody>
                  <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                      <div
                        style={{ cursor: "pointer" }}
                        onClick={() => { window.history.back() }}>
                        <ul className=" list-unstyled">
                          <div className=''>
                            <i className="bx bx-chevron-left h1 text-primary"></i>
                          </div>
                        </ul>
                      </div>
                      <div className=' m-2'>
                        <h4> {matchEditCamera ? "EDIT CAMERA" : "ADD CAMERA"}</h4>
                      </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                      <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                        <button
                          className="btn  btn-primary waves-effect waves-light mt-0 mr-2"
                          onClick={() => {
                            if (matchEditCamera) {
                              handleEditSaveButtonPressed();
                            } else {
                              handleSaveButtonPressed();
                            }
                          }}
                          style={{ float: "right" }}
                          role="button"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <hr />

                  <Row>
                    <Col lg={6} className="ml-0 pl-0 pr-0">
                      <FormGroup className="m-2">
                        <Label className="mt-2 mb-0 ">Camera Name :</Label>
                        <Input
                          type="text"
                          name="singleLineAG"
                          placeholder="Camera Name"
                          className="form-control"
                          value={newCameraName}
                          onChange={(e) => {
                            setNewCameraName(e.target.value);
                          }}
                        />
                      </FormGroup>
                    </Col>


                    <Col lg={6} className="ml-0 pl-0 pr-0">
                      <FormGroup className="m-2">
                        <Label className="mt-2 mb-0 ">DVR/NVR Camera:</Label>
                        <select
                          className="form-control"
                          value={streamingObjectId}
                          onChange={(e) => {
                            setStreamingObjectId(e.target.value);
                          }}
                        >
                          <option disabled selected value>
                            Pick live stream
                          </option>
                          {props.cameraLiveStreams && props.cameraLiveStreams.map((cls, key) => (
                            <option key={cls.id} value={cls.id}>
                              {cls.attributes.name}
                            </option>
                          ))}
                        </select>
                      </FormGroup>
                    </Col>
                  </Row>

                  <Row>
                    <Col xs={6}>
                      <FormGroup className="m-2">
                        <Label className="mb-0">
                          {props.t("Select Days")} :
                        </Label>
                        <Select
                          value={selectedDays}
                          isMulti={true}
                          onChange={(v) => {
                            handleMulti(v)
                          }}
                          options={allDaysOptions}
                        />
                      </FormGroup>
                    </Col>
                  </Row>
                  <div className="flex-fill m-2">
                    <Label className="pb-0" style={{ verticalAlign: "middle" }}>
                      Select Time
                    </Label>

                    <Label
                      className="m-2 text-primary"
                      onClick={() => {
                        handleAddTimeRange();
                      }}
                      id="add-time-camera"
                      style={{ fontSize: "20px", cursor: "pointer" }}
                    >
                      <i className=" bx bx-plus-circle"></i>
                    </Label>
                  </div>
                  <hr />

                  {timeRangeArray.map((timeRange, key) => (
                    // <Col className="col-6 mt-3" xs={12} key={key}>
                    <Row className="d-flex">
                      <Col>
                        <div>
                          <Label>Start Time</Label>
                          <div className="mr-4">
                            <DatePicker
                              selected={timeRange.startTime}
                              className="form-control"
                              onChange={(date) => {
                                let tempObject = { ...timeRange };
                                tempObject.startTime = date;
                                let tempList = [...timeRangeArray];
                                tempList[key] = tempObject;

                                setTimeRangeArray(tempList);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              minTime={defaultMinTime}
                              maxTime={timeRange.endTime}
                              dateFormat="h:mm aa"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div>
                          <Label>End Time</Label>
                          <div className="mr-2">
                            <DatePicker
                              className="form-control"
                              selected={timeRange.endTime}
                              onChange={(date) => {
                                let tempObject = { ...timeRange };
                                tempObject.endTime = date;
                                let tempList = [...timeRangeArray];
                                tempList[key] = tempObject;

                                setTimeRangeArray(tempList);
                              }}
                              showTimeSelect
                              showTimeSelectOnly
                              timeIntervals={15}
                              timeCaption="Time"
                              minTime={timeRange.startTime}
                              maxTime={defaultMaxTime}
                              dateFormat="h:mm aa"
                            />
                          </div>
                        </div>
                      </Col>
                      <Col>
                        <div
                          className="float-right mt-0"
                          style={{ verticalAlign: "middle" }}
                        >
                          <Label
                            className="ml-2 float-right text-danger"
                            onClick={() => {
                              handleDeleteTimeRange(timeRange);
                            }}
                            id="add-time-camera"
                            style={{ fontSize: "20px", cursor: "pointer" }}
                          >
                            <i className="bx bx-minus-circle"></i>
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  ))}

                  <br />

                  <Label className="mt-3 mb-0 ml-0">Select Student</Label>
                  <hr />

                  <Row lg={12}>
                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <FormGroup>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                handleClassroomFilterChange(e.target.value);
                              }}
                            >
                              {classroomList.map((classroomObject, key) => (
                                <option key={key} value={classroomObject.id}>
                                  {classroomObject.attributes.Name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <Input
                            type="text"
                            name="searchAG"
                            className="inner form-control mb-3"
                            placeholder="Search"
                            onChange={(e) => {
                              handleSearchBarTextChange(e.target.value);
                            }}
                          />
                        </Col>
                      </Row>
                      <Row><p className="ml-4">* Existing selected list</p></Row>
                      <Row>
                        <div className="table-responsive ml-3 mr-2">
                          <table className="table table-nowrap table-centered mb-0">
                            <tbody>
                              {getAllKidsFilteredList().length === 0 && (
                                <div className="ml-3 mt-3">
                                  <p>No Student found</p>
                                  <hr />
                                </div>
                              )}
                              {getAllKidsFilteredList().map(
                                (kObject, keyInside) => (
                                  <tr key={keyInside}>
                                    <td style={{ width: "60px" }}>
                                      <input
                                        type="checkbox"
                                        id={"customCheck12_" + keyInside}
                                        onChange={() => {
                                          handleCheckboxChange(kObject);
                                        }}
                                        checked={kObject.id == "pickAll" ? allPick() : selectedKids.includes(kObject)}
                                      />
                                    </td>

                                    <td>
                                      <Label className="text-truncate font-size-14 m-0">
                                        {kObject.id !==
                                          "pickAll" && (
                                            <img
                                              src={getKidPhoto(kObject)}
                                              className="rounded-circle avatar-xs m-1"
                                              alt=""
                                            />
                                          )}
                                        <Label className="text-dark ml-2">
                                          {kObject.attributes.Name}{initialSelctedKids && initialSelctedKids.includes(kObject) && <span>*</span>}
                                        </Label>
                                      </Label>
                                    </td>
                                    <td></td>
                                    <td></td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </Col>
                    <Col lg={6}>
                      <Row lg={12}>
                        <Col lg={12}>
                          <div
                            className="text-center pt-2 py-auto bg-primary text-white"
                            style={{ borderRadius: "5px", height: "35px" }}
                          >
                            Selected Student
                          </div>
                        </Col>
                      </Row>

                      <Row>
                        <div className="table-responsive mt-3 ml-2 mr-3">
                          <table className="table table-nowrap table-centered mb-0">
                            <tbody>
                              {selectedKids.length === 0 && (
                                <div className="ml-1">
                                  <p className="ml-3">No Student Selected</p>
                                  <hr />
                                </div>
                              )}

                              {selectedKids.map((kObject, keyInside) => (
                                <tr key={keyInside}>
                                  <td style={{ width: "60px" }}>
                                    <input
                                      type="checkbox"
                                      id={"customCheck1_" + keyInside}
                                      onChange={() => {
                                        handleCheckboxChangeForSelected(
                                          kObject
                                        );
                                      }}
                                      checked={selectedKids.includes(kObject)}
                                    />
                                  </td>

                                  <td>
                                    <Label className="text-truncate font-size-14 m-0">
                                      <img
                                        src={
                                          kObject.attributes.Photo != undefined
                                            ? kObject.attributes.Photo._url
                                            : avatar2
                                        }
                                        className="rounded-circle avatar-xs m-1"
                                        alt=""
                                      />
                                      <Label className="text-dark ml-2">
                                        {kObject.attributes.Name}{initialSelctedKids && initialSelctedKids.includes(kObject) && <span>*</span>}
                                      </Label>{" "}
                                    </Label>
                                  </td>
                                  <td></td>
                                  <td></td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  {saveClicked ? "Saving CCTV..." : "Loading CCTV..."}
                </Label>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  const { selectedSchool } = state.School;
  const {
    resultCameraAfterSave,
    camera,
    cameraAccessSaveSuccess,
    cameraAccessObjects,
    cameraLiveStreams,
  } = state.Camera;

  return {
    classrooms,
    cameraAccessObjects,
    kids,
    resultCameraAfterSave,
    camera,
    cameraAccessSaveSuccess,
    selectedSchool,
    cameraLiveStreams,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      resetResultCameraAfterSave,
      saveCamera,
      saveCameraAccessObjects,
      getClassroomsForSchoolId,
      getAllKidsForGroup,
      getCameraForId,
      getCameraAccessObjectsForCameraId,
      setSelecetdSchool,
      getCameraLiveStreamsForSchoolId,
    })(AddCamera)
  )
);
