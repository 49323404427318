import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Modal, Container, FormGroup, Label, Button, Row, Col, Form, Card, CardBody, Input } from 'reactstrap';
import { setSelecetdSchool, getClassroomsForSchoolId,updateNoticeBoardFiles } from '../../store/actions';
import { matchPath } from 'react-router';
import Select from "react-select";
import Parse from 'parse';
import toastr from 'toastr';
import { v4 as uuidv4 } from "uuid";
import { AvForm, AvField } from "availity-reactstrap-validation";

const AddNotice = props => {
    const match = matchPath(props.history.location.pathname, {
        path: "/add-notice/:schoolId",
        exact: true,
        strict: false,
    });
    const match2 = matchPath(props.history.location.pathname, {
        path: "/add-notice/:schoolId/:tabId",
        exact: true,
        strict: false,
    });
    const schoolId = (match ?? match2).params.schoolId;
    const tabId = match2 && match2.params.tabId;

    const SendTo = {
        allSchool: "@All School",
        Classroom: "@Classroom",
        allTeachers: "@All Teachers",
    };

    const [title, setTitle] = useState('');
    const [titleError, setTitleError] = useState();
    const [description, setDescription] = useState();
    const [descriptionError, setDescriptionError] = useState(false);
    const [selectedSendTo, setSelectedSendTo] = useState(undefined);
    const [selectedSendToError, setSelectedSendToError] = useState();
    const [selectedClass, setSelectedClass] = useState();
    const [selectedClassError, setSelectedClassError] = useState();
    const [uploadedFile, setUploadedFile] = useState(null);
    const [uploadedFileError, setUploadedFileError] = useState();
    const [fileURL, setFileURL] = useState(null);
    const [fileName, setFileName] = useState('');
    const [recipientId, setRecipientId] = useState('');
    const [loading, setLoading] = useState(false);


    const [sendToList, setSendToList] = useState([
        { value: 1, label: SendTo.allSchool },
        { value: 2, label: SendTo.Classroom },
        { value: 3, label: SendTo.allTeachers },
    ]);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId, tabId]);

    useEffect(() => {
        if (schoolId) {
            if (props.classrooms != null || props.classrooms.length === 0) {
                props.getClassroomsForSchoolId(schoolId);
            }
        }
        if (tabId == "school") {
            setSelectedSendTo(1)
        } else if (tabId == "teachers") {
            setSelectedSendTo(3)
        } else if (tabId) {
            setSelectedSendTo(2)
            setSelectedClass(tabId)
        }
    }, [schoolId, tabId]);

    const validateSize = (input, limit) => {
        const fileSize = input.files[0].size / 1024 / 1024; // in MiB
        if (fileSize > limit) {
            toastr.warning(`File size exceeds ${limit} MB, Please use a smaller document`);
            return false;
        }
        return true;
    }

    
    const handleFileUploadChange = (e) => {
        if (e.target.files && e.target.files[0] && validateSize(e.target, 20)) {
            const file = e.target.files[0];
            let fileName = file.name;
            let uniqueFileName = fileName.replace(/[^a-zA-Z0-9._]/g, '_');
            
            setUploadedFile(file);
            setFileName(uniqueFileName);
            setFileURL(URL.createObjectURL(file));
        }
    };
    

    


    const clearFile = () => {
        setUploadedFile(null);
        setFileURL(null);
        setFileName('');
    };

    const updateFile = () => {
        document.getElementById('upload-files').click();
    };



    function handleSubmit() {
        let upload = true;
        if (!uploadedFile) {
            setUploadedFileError(true);
            upload = false;
        }
        if (!title) {
            setTitleError(true);
            upload = false;
        }
        if (!description) {
            setDescriptionError(true);
            upload = false;
        }
        if (!selectedSendTo) {
            setSelectedSendToError(true);
            upload = false;
        }
        if (selectedSendTo == 2) {
            if (!selectedClass) {
                selectedClassError(true);
                upload = false;
            }
        }
        if (upload) {
            setLoading(true)
            const Files = Parse.Object.extend("Files");
            var files = new Files();

            files.set("name", title);
            files.set("fileDescription", description);
            files.set("schoolId", schoolId)
            if (selectedSendTo == 1) {
                files.set("recipientId", schoolId);
            } else if (selectedSendTo == 3) {
                files.set("recipientId", 'T');
            } else if (selectedSendTo == 2) {
                files.set("recipientId", selectedClass);
            }
            files.set("createdBy",Parse.User.current().attributes.teacherId)
            const parseFile = new Parse.File(fileName, uploadedFile);
            parseFile.save().then(
                (result) => {
                    files.set("file", result);
                    files.save().then(
                        (result) => {
                            props.updateNoticeBoardFiles([result])
                            toastr.success("Notice added successfully")
                            window.history.back();
                            setLoading(false);
                        },
                        (error) => {
                            console.error('Error saving file:', error);
                            setLoading(false); 
                        }
                    );

                },
                (error) => {
                    console.error('Error saving file:', error);
                }
            );
        }

    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col>
                        <Row className="d-flex align-items-start">
                            <Col className="d-flex align-items-start">
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { window.history.back() }}>
                                    <ul className=" list-unstyled">
                                        <div className=''>
                                            <i className="bx bx-chevron-left h1 text-primary"></i>
                                        </div>
                                    </ul>
                                </div>
                                <div className=' m-2'>
                                    <h4>Add Notice</h4>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Card>
                    <CardBody>
                        <Label className="col-form-label">Notice Document(PDF, Doc, PPT, Excel,Image)</Label>
                        {!uploadedFile && (<div>
                            <Row lg={12}>
                                <Col lg={6}>
                                    <label className='btn btn-info m-2' htmlFor='upload-files'>
                                        Upload
                                    </label>
                                </Col>
                            </Row>
                            {uploadedFileError && <small className='text-danger'>Upload File</small>}
                        </div>
                        )}
                        <input type="file"
                            className="sr-only"
                            name="upload-files"
                            id="upload-files"
                            accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*,/*"
                            onChange={(e) => {
                                clearFile();
                                handleFileUploadChange(e);
                            }} />
                        {uploadedFile && (
                            <Col lg={6}>
                                <div className='mt-2 p-2' style={{ border: '1px solid #808080', }}>
                                    <p>{fileName}</p>
                                    <Button color="info" onClick={updateFile}>Change File</Button>
                                </div>
                            </Col>
                        )}
                        <Row lg={12}>
                            <Col lg={6}>
                                <FormGroup>
                                    <Label htmlFor="Title" className="col-form-label col-lg-2">Title</Label>
                                    <Input id="Title" name="Title" type="text" className="form-control" placeholder="Enter Title..."
                                        value={title}
                                        onChange={(e) => {
                                            setTitle(e.target.value)
                                            setTitleError(false);
                                        }}
                                    />
                                    {titleError && <small className='text-danger'>Enter Title</small>}

                                </FormGroup>
                            </Col>
                        </Row>
                        <Row lg={12}>
                            <Col lg={6}>
                                <FormGroup>
                                    <Label htmlFor="Description" className="col-form-label col-lg-2">Description</Label>
                                    <Input id="Description" name="Description" type="textarea" rows="3" className="form-control" placeholder="Description..."
                                        value={description}
                                        onChange={(e) => {
                                            setDescription(e.target.value);
                                            setDescriptionError(false);
                                        }}
                                    />
                                    {descriptionError && <small className='text-danger'>Enter Description</small>}
                                </FormGroup>
                            </Col>
                        </Row>

                        <Row lg={12}>
                            <Col lg={6}>
                                <FormGroup>
                                    <Label className="">Recipients</Label>
                                    <select
                                        className="form-control"
                                        value={selectedSendTo}
                                        onChange={(e) => {
                                            setSelectedSendTo(e.target.value);
                                            setSelectedSendToError(false);
                                        }}
                                    >
                                        <option disabled selected value>Select Send To</option>
                                        {sendToList.map((object, key) => (
                                            <option key={key} value={object.value}>{object.label}</option>
                                        ))}
                                    </select>
                                    {selectedSendToError && <small className='text-danger'>Select</small>}
                                </FormGroup>
                                {selectedSendTo == 2 &&
                                    <Row>
                                        <Col>
                                            <FormGroup>
                                                <Label className="mb-2">Select Class</Label>
                                                <select
                                                    className="form-control"
                                                    value={selectedClass}
                                                    onChange={(e) => {
                                                        setSelectedClass(e.target.value);
                                                        setSelectedClassError(false);
                                                    }}
                                                >
                                                    <option disabled selected value>Select class</option>
                                                    {props.classrooms && props.classrooms.map((object, key) => (
                                                        <option key={key} value={object.id}>{object.attributes.Name}</option>
                                                    ))}
                                                </select>
                                                {selectedClassError && <small className='text-danger'>Select</small>}
                                            </FormGroup>
                                        </Col>
                                    </Row>}
                            </Col>
                        </Row>

                        <Row className="text-center mt-3">
                           {loading? <Col lg="11">
                            <Button className="text-white"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving </Button>
                            </Col>
                            :
                            <Col lg="11">
                                <Button type="submit" color="primary" onClick={handleSubmit}>Save</Button>
                            </Col>}

                            
                        </Row>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
};

const mapStateToProps = state => {
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;

    return { selectedSchool, classrooms, };
};

export default connect(mapStateToProps, { setSelecetdSchool, getClassroomsForSchoolId ,updateNoticeBoardFiles})(withRouter(withNamespaces()(AddNotice)));
