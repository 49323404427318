import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
  teacherAttendanceList: [],
  kidAttendanceList: [],
  daycareAttendance: [],
  enquiryReport: [],
  gotAttendance: false,
  gotEnquiryReport: false,
  classAttendance: [],
  daycareAttendance: [],
  attendanceQueueTimer: null,
};

const TeacherAttendance = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ATTENDANCE_FOR_TEACHER_IDS:
      state = {
        ...state,
        gotAttendance: false,
        gotEnquiryReport: false,
        teacherAttendanceList: [],
        kidAttendanceList: [],
        daycareAttendance: [],
        enquiryReport: [],
      };
      break;
    case actions.GET_ATTENDANCE_FOR_KID_IDS:
      state = {
        ...state,
        gotAttendance: false,
        gotEnquiryReport: false,
        teacherAttendanceList: [],
        kidAttendanceList: [],
        daycareAttendance: [],
        enquiryReport: [],
      };
      break;
    case actions.GET_DAYCARE_ATTENDANCE_FOR_KID_IDS:
      state = {
        ...state,
        gotAttendance: false,
        gotEnquiryReport: false,
        teacherAttendanceList: [],
        kidAttendanceList: [],
        daycareAttendance: [],
        enquiryReport: [],
      };
      break;
    case actions.GET_ENQUIRY_KIDS_FOR_FILTERS:
      state = {
        gotAttendance: false,
        gotEnquiryReport: false,
        teacherAttendanceList: [],
        kidAttendanceList: [],
        daycareAttendance: [],
        enquiryReport: [],
      };
      break;
    case actions.SET_ATTENDANCE_FOR_TEACHER_IDS:
      state = {
        ...state,
        teacherAttendanceList: action.payload.attendanceObjs,
        gotAttendance: true,
      };
      break;
    case actions.SET_ATTENDANCE_FOR_KID_IDS:
      state = {
        ...state,
        kidAttendanceList: action.payload.attendanceObjs,
        gotAttendance: true,
      };
      break;
    case actions.SET_DAYCARE_ATTENDANCE_FOR_KID_IDS:
      state = {
        ...state,
        daycareAttendance: action.payload.attendanceObjs,
        gotAttendance: true,
      };
      break;
    case actions.UPDATE_CLASS_ATTENDANCE:
      var classAttendance = [...state.classAttendance];

      if (classAttendance.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.classAttendance], action.payload.classAttendance, "isDeleted", false)
        state = { ...state, classAttendance: updatedList };
      } else {
        var others = action.payload.classAttendance.filter(
          (classAttendance) => classAttendance.attributes.isDeleted !== true
        );
        state = { ...state, classAttendance: others };
      }
      break;
    case actions.UPDATE_DAYCARE_ATTENDANCE:
      var daycareAttendance = [...state.daycareAttendance];

      if (daycareAttendance.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.daycareAttendance], action.payload.daycareAttendance, "isDeleted", false)
        state = { ...state, daycareAttendance: updatedList };
      } else {
        var others = action.payload.daycareAttendance.filter(
          (daycareAttendance) => daycareAttendance.attributes.isDeleted !== true
        );
        state = { ...state, daycareAttendance: others };
      }
      break;
    case actions.SET_ENQUIRY_REPORT_FOR_FILTER:
      state = {
        ...state,
        enquiryReport: action.payload.enquiryObjs,
        gotEnquiryReport: true,
      };
      case actions.ATTENDANCE_QUEUE_TIMER:
      state = { ...state, attendanceQueueTimer: action.payload.value };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default TeacherAttendance;
