import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    CardTitle,
    CardSubtitle,
    CardHeader,
    Collapse,
    FormGroup,
    Label,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledTooltip,
    UncontrolledAlert,
} from "reactstrap";
import toastr from "toastr";
import Switch from "react-switch";

import { } from "../../store/actions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import * as Constants from "../../Constents";
import classnames from "classnames";

import foodImage from "../../assets/images/DiaryIcons/junior_diary_food.png";
import observationImage from "../../assets/images/DiaryIcons/learning_style.png";
import medicalImage from "../../assets/images/DiaryIcons/medicalnotes.png";
import otherImage from "../../assets/images/DiaryIcons/junior_diary_icon.png";

import DragDropTables from '../LMS/DragDropTables'


import { Element, Events, animateScroll as scroll, scrollSpy, scroller, } from "react-scroll";
import {
    setSelecetdSchool

} from "../../store/actions";
import Parse from "parse";
import DiaryMenuItems from "./DiaryMenuItems";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import { matchPath } from "react-router";


const Food = "Food"
const Medical = "Medical"
const Observation = "Observation"
const Naptime_Diaperchange = "Naptime/Diaper Change"
const Others = "Others"
const reOrder="Reorder"




const DiaryView = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/diaryview/:schoolId",
        exact: true,
        strict: false,
    });

    var schoolId = match && match.params.schoolId;

    const naptimeConstantsList = [20, 21, 13]
    const outerSections = [
        {
            id: "1OS", name: Food, icon: foodImage, sectionsList: [

                {
                    id: "default1",
                    name: "Breakfast",
                    type: 11,
                    isDeleted: true,
                    options: [],
                    measures: ["Full", "Quarter", "Half", "Three fourth", "Not touched"]
                },
                {
                    id: "default2",
                    name: "Lunch",
                    type: 11,
                    isDeleted: true,
                    options: [],
                    measures: ["Full", "Quarter", "Half", "Three fourth", "Not touched"]
                },
                {
                    id: "default3",
                    name: "Snacks",
                    type: 11,
                    isDeleted: true,
                    options: [],
                    measures: ["Full", "Quarter", "Half", "Three fourth", "Not touched"]
                }, {
                    id: "default4",
                    name: "Dinner",
                    type: 11,
                    isDeleted: true,
                    options: [],
                    measures: ["Full", "Quarter", "Half", "Three fourth", "Not touched"]
                }, {
                    id: "default5",
                    name: "Milk",
                    type: 19,
                    isDeleted: true,
                    options: [],
                    measures: ["Half Glass", "One Glass", "Two Glasses", "Three Glass", "Not Touched"]
                }, {
                    id: "default6",
                    name: "Water",
                    type: 19,
                    isDeleted: true,
                    options: [],
                    measures: ["Half Glass", "One Glass", "Two Glasses", "Three Glass", "Not Touched"]
                }
            ]
        },
        {
            id: "2OS", name: Medical, icon: medicalImage, sectionsList: [

                {
                    id: "default1",
                    name: "Accident Reporting",
                    type: 14,
                    isDeleted: true,
                    options: [],
                    measures: []
                },
                {
                    id: "default2",
                    name: "Medical Notes",
                    type: 14,
                    isDeleted: true,
                    options: [],
                    measures: []
                },
                {
                    id: "default3",
                    name: "Body Temperature",
                    type: 14,
                    isDeleted: true,
                    options: [],
                    measures: []
                }, {
                    id: "default4",
                    name: "Air Quality Index",
                    type: 30,
                    isDeleted: true,
                    options: [
                        {
                            items: ["Good(0-50)", "Moderate(51-100)", "Unhealthy for Sensitive Groups(101-150)", "Very Unhealthy(201-300)", "Hazardous(301-500)"],
                            id: "item1",
                            name: "List Items"
                        }
                    ],
                    measures: []
                }
            ]
        },
        {
            id: "3OS", name: Naptime_Diaperchange, icon: otherImage, sectionsList: [

                {
                    id: "default1",
                    name: "Diaper Change",
                    type: 12,
                    isDeleted: true,
                    options: [
                        {
                            items: ["Peed", "Potty", "Wet", "Soiled", "Routine"],
                            id: "item1",
                            name: "List Items"
                        }
                    ],
                    measures: ["Peed", "Potty", "Wet", "Soiled", "Routine"]
                },
                {
                    id: "default2",
                    name: "Naptime",
                    type: 13,
                    isDeleted: true,
                    options: [
                        {
                            items: ["30 min", "1 hour", "1.5 hours", "2 hours"],
                            id: "item1",
                            name: "List Items"
                        }
                    ],
                    measures: ["30 min", "1 hour", "1.5 hours", "2 hours"]
                },
                {
                    id: "default3",
                    name: "Naptime",
                    type: 20,
                    isDeleted: true,
                    options: [],
                    measures: [],
                    showHelpText: true,
                }, {
                    id: "default4",
                    name: "Naptime",
                    type: 21,
                    isDeleted: true,
                    options: [],
                    measures: [],
                    showHelpText: true,

                }
            ]
        },
        {
            id: "4OS", name: Observation, icon: observationImage, sectionsList: [

                {
                    id: "default1",
                    name: "Ma'am Observed",
                    type: 17,
                    isDeleted: true,
                    options: [],
                    measures: [],
                    showHelpText: true,

                }
            ]
        },
        {
            id: "5OS", name: reOrder, sectionsList: [],
            desc:"Arrange as per your school routine"
        },
    ]

    const getNaptimeHeading = (categoryObject) => {
        if (categoryObject.type === 13) {
            return "Naptime (Time Duration)"
        } else if (categoryObject.type === 20) {
            return "Naptime (StartTime EndTime)"
        } else if (categoryObject.type === 21) {
            return "Naptime (Real Time)"
        }
        return categoryObject.name ?? ""
    }

    const [activeTab, setactiveTab] = useState("1");
    const [outerSectionsState, setOuterSectionsState] = useState(outerSections);
    const [selectedOptionId, setSelectedOptionId] = useState("")
    const [showAddNewSectionView, setShowAddNewSectionView] = useState(false)
    const [newSectionName, setNewSectionName] = useState("")
    const [sectionNameError, setSectionNameError] = useState(false)
    const [isLoading, setIsLoading] = useState(true);
    const [creatingSection, setCreatingSection] = useState(false)
    const [enablingFeature, setEnablingFeature] = useState(false)
    const [deletingMenuItemList, setDeletingMenuItemList] = useState(false)
    const [showAlertWhileEnable, setShowAlertWhileEnable] = useState(false)




    const [editSectionObject, setEditSectionObject] = useState(undefined);
    const [editSectionName, setEditSectionName] = useState("");

    const [rearrange, setRearrange] = useState(false);


    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    const getComparedSectionListFromLocalAndServerObjects = (tempMainList, i, serverList) => {
        let tempList = []
        let indexesOfItemReplaced = []
        for (var k = 0; k < tempMainList[i].sectionsList.length; k++) {

            let matchFound = undefined

            for (var j = 0; j < serverList.length; j++) {

                if (tempMainList[i].sectionsList[k].name === serverList[j].name && tempMainList[i].sectionsList[k].type === serverList[j].type) {
                    matchFound = serverList[j]
                    indexesOfItemReplaced.push(j)
                    break
                }

            }

            if (matchFound) {
                tempList.push(matchFound)
            } else {
                tempList.push(tempMainList[i].sectionsList[k])
            }
        }

        for (var j = 0; j < serverList.length; j++) {

            if (!indexesOfItemReplaced.includes(j)) {
                tempList.push(serverList[j])
            }

        }

        return tempList
    }

    const [allDiaryFeatues, setAllDiaryFeatues] = useState([]);
    const [nonDeletedDiaryFeatues, setNonDeletedDiaryFeatues] = useState([]);

    useEffect(() => {
        let nonDel=[]
        for (const po of allDiaryFeatues) {
            if (!po.attributes.isDeleted) {
                nonDel.push(po);
            }
        }
        setNonDeletedDiaryFeatues(nonDel)
    }, [allDiaryFeatues]);
    
    
    const handelDiaryFeaturesSort = (features) => {
        console.log(features)

        for (const i in features) {
            features[parseInt(i+"")].set("order",parseInt(i+""))
        }

        Parse.Object.saveAll(features).then(
            (results) => {
                if (results) {
                    setNonDeletedDiaryFeatues(results)
                    toastr.success("Successfully updated")
                }
            }
        )


    }
    const getDiaryFeatures = () => {
        var query = new Parse.Query("DiaryFeatures");
        query.equalTo("schoolId", schoolId)
        query.ascending('order');
        query.addAscending('createdAt')
        query.limit(1000);
        query.find().then((results) => {
            if (results) {
                setAllDiaryFeatues(results);
                if (i === 0) {
                    setSelectedOptionId(results[i].id)
                }
                let ids = []
                for (var i = 0; i < results.length; i++) {
                    let additionalItems = results[i].attributes.additionalItems
                    if (additionalItems && additionalItems.length > 0) {
                        for (var j = 0; j < additionalItems.length; j++) {
                            if (!ids.includes(additionalItems[j])) {
                                ids.push(additionalItems[j])
                            }
                        }
                    }

                }

                var query = new Parse.Query("DiaryItemsAdditional");
                query.containedIn("objectId", ids)
                query.limit(1000);
                query.find().then((diaryResults) => {

                    let sectionsList = []
                    let tempMainList = [...outerSectionsState]

                    for (var i = 0; i < results.length; i++) {


                        let tempObject = {}
                        tempObject.name = results[i].attributes.routine ?? ""
                        tempObject.id = results[i].id
                        tempObject.menuItems = []
                        tempObject.measures = results[i].attributes.measures ?? []
                        tempObject.type = results[i].attributes.type ?? ""
                        tempObject.isDeleted = results[i].attributes.isDeleted ?? false
                        tempObject.parseObject = results[i]
                        let additionalItems = []

                        if (results[i].attributes.type === 19 || results[i].attributes.type === 11) {
                            if (results[i].attributes.additionalItems) {
                                additionalItems = results[i].attributes.additionalItems
                            }

                            let diaryItems = []
                            diaryItems = getDiaryAdditionalItemsFromList(diaryResults, additionalItems)
                            tempObject.options = diaryItems
                            let menuItems = []

                            if (tempObject.options && tempObject.options.length > 0) {
                                for (var j = 0; j < tempObject.options.length; j++) {

                                    let menuItemObject = tempObject.options[j]
                                    let tempMenuItemObject = {}
                                    tempMenuItemObject.items = menuItemObject.attributes.items
                                    tempMenuItemObject.id = menuItemObject.id
                                    tempMenuItemObject.name = "List Items " + (j + 1)
                                    tempMenuItemObject.parseObject = menuItemObject
                                    menuItems.push(tempMenuItemObject)
                                }
                            }
                            tempObject.options = menuItems

                            sectionsList.push(tempObject)
                        } else {

                            if (results[i].attributes.type === 21 || results[i].attributes.type === 20) {
                                tempObject.showHelpText = true
                            } else {
                                tempObject.options = [
                                    {
                                        items: results[i].attributes.measures ?? [],
                                        id: results[i].id,
                                        parseObject: results[i],
                                        name: "List Items"
                                    }
                                ]
                            }

                            sectionsList.push(tempObject)
                        }


                    }
                    let foodList = []
                    let medicalList = []
                    let observationList = []
                    let naptimeDiaperList = []

                    for (var i = 0; i < sectionsList.length; i++) {

                        if (sectionsList[i].type === 11 || sectionsList[i].type === 19) {
                            foodList.push(sectionsList[i])
                        } else if (sectionsList[i].type === 14 || sectionsList[i].type === 30) {
                            medicalList.push(sectionsList[i])
                        } else if (sectionsList[i].type === 20 || sectionsList[i].type === 21 || sectionsList[i].type === 13 || sectionsList[i].type === 12) {
                            naptimeDiaperList.push(sectionsList[i])
                        } else if (sectionsList[i].type === 16 || sectionsList[i].type === 17 || sectionsList[i].type === 18 ||
                            sectionsList[i].type === 38 || sectionsList[i].type === 39 || sectionsList[i].type === 40) {
                            observationList.push(sectionsList[i])
                        } else {
                            observationList.push(sectionsList[i])
                        }

                    }

                    for (var i = 0; i < tempMainList.length; i++) {
                        if (i === 0) {
                            tempMainList[i].sectionsList = getComparedSectionListFromLocalAndServerObjects(tempMainList, i, foodList)
                        } else if (i === 1) {
                            tempMainList[i].sectionsList = getComparedSectionListFromLocalAndServerObjects(tempMainList, i, medicalList)
                        } else if (i === 2) {
                            tempMainList[i].sectionsList = getComparedSectionListFromLocalAndServerObjects(tempMainList, i, naptimeDiaperList)
                        } else if (i === 3) {
                            tempMainList[i].sectionsList = getComparedSectionListFromLocalAndServerObjects(tempMainList, i, observationList)
                        }
                    }
                    setIsLoading(false)
                    setOuterSectionsState(tempMainList)


                })
            }
        })
    }

    const getDiaryAdditionalItemsFromList = (list, itemIds) => {
        let tempList = []

        tempList = list.filter((ls) => (
            itemIds.includes(ls.id)
        ))
        return tempList
    }

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        getDiaryFeatures()
    }, [props.selectedSchool, schoolId]);

    const getSectionListIndex = (type) => {

        let index = 3
        if (type === 11 || type === 19) {
            index = 0
        } else if (type === 14 || type === 30) {
            index = 1
        } else if (type === 20 || type === 21 || type === 13 || type === 12) {
            index = 2
        }

        return index
    }




    const handleAddNewSection = (outerSection, outerKey) => {

        let type = getDefaultTypeWhileDiaryFeatureObject(outerSection.name)


        setCreatingSection(true);
        let DiaryFeatures = Parse.Object.extend("DiaryFeatures");
        let diaryFeature = new DiaryFeatures();
        diaryFeature.set("routine", newSectionName);
        diaryFeature.set("schoolId", schoolId);
        diaryFeature.set("type", type)
        diaryFeature.set("order", allDiaryFeatues.length)

        if (type === 19 || type === 11) {
            diaryFeature.set("measures", ["Full", "Quarter", "Half", "Three fourth", "Not touched"])
        }
        diaryFeature.save().then(
            (result) => {

                let ol = [...allDiaryFeatues];
                ol.push(result)
                setAllDiaryFeatues(ol)

                setNewSectionName("");
                setShowAddNewSectionView(!showAddNewSectionView);
                setCreatingSection(false);

                let optionObject = {
                    id: result.id,
                    name: newSectionName,
                    menuItems: [],
                    type: type,
                    parseObject: result,
                };

                if (outerSection.name !== Food) {
                    optionObject.options = [{
                        items: [],
                        id: result.id,
                        parseObject: result,
                        name: "List Items"
                    }]
                    optionObject.measures = []
                }

                let tempList = [...outerSectionsState];
                tempList[outerKey].sectionsList.push(optionObject)
                setSelectedOptionId(tempList[outerKey].id);
                setOuterSectionsState(tempList);
            },
            (error) => {
                setCreatingSection(false);
                setNewSectionName("");
                setShowAddNewSectionView(!showAddNewSectionView);
            }
        );

    }



    const handleMenuItemsEdit = (editMenuItemObject, sectionObject, editedItems, isFoodType, outerKey) => {

        if (isFoodType) {
            let pObject = editMenuItemObject.parseObject
            pObject.set("items", editedItems)
            pObject.save().then((result) => {
                let tempList = [...outerSectionsState];
                let outerIndex = outerKey
                let sectionList = [...tempList[outerIndex].sectionsList]
                let optionIndex = sectionList.indexOf(sectionObject)
                sectionList[optionIndex].measures = result.attributes.measures ?? []
                let tempOptionObject = [...sectionList[optionIndex].options]
                let itemIndex = tempOptionObject.indexOf(editMenuItemObject)
                tempOptionObject[itemIndex].items = editedItems
                sectionList[optionIndex].options = tempOptionObject
                tempList[outerIndex].sectionsList = sectionList

                setOuterSectionsState(tempList);
                setEditSectionObject(undefined);
                setEditSectionName("");



            })
        } else {
            let pObject = sectionObject.parseObject
            pObject.set("measures", editedItems)
            pObject.save().then((result) => {

                let tempList = [...outerSectionsState];
                let outerIndex = outerKey
                let sectionList = [...tempList[outerIndex].sectionsList]
                let optionIndex = sectionList.indexOf(sectionObject)
                sectionList[optionIndex].measures = result.attributes.measures ?? []
                sectionList[optionIndex].parseObject = result
                let tempOptionObject = [...sectionList[optionIndex].options]
                tempOptionObject[0].items = result.attributes.measures ?? []
                sectionList[optionIndex].options = tempOptionObject
                tempList[outerIndex].sectionsList = sectionList

                setOuterSectionsState(tempList);
                setEditSectionObject(undefined);
                setEditSectionName("");

            })
        }

    }



    const handleMenuItemsAdd = (sectionObject, editedItems, isFoodType, outerKey) => {

        if (isFoodType) {

            const DiaryItemsAdditional = Parse.Object.extend("DiaryItemsAdditional");
            var diaryItemsAdditional = new DiaryItemsAdditional();
            diaryItemsAdditional.set("items", editedItems)
            diaryItemsAdditional.set("schoolId", schoolId)
            diaryItemsAdditional.save().then((dairyAddItemResult) => {

                let diaryFeatureObject = sectionObject
                let addItems = diaryFeatureObject.parseObject.attributes.additionalItems ? [...diaryFeatureObject.parseObject.attributes.additionalItems] : []
                addItems.push(dairyAddItemResult.id)
                diaryFeatureObject.parseObject.set("additionalItems", addItems)
                diaryFeatureObject.parseObject.save().then((diaryFeatureResult) => {

                    let tempList = [...outerSectionsState];
                    let outerIndex = outerKey
                    let sectionList = [...tempList[outerIndex].sectionsList]
                    let optionIndex = sectionList.indexOf(sectionObject)


                    let tempOptionObjectList = sectionList[optionIndex].options ? [...sectionList[optionIndex].options] : []
                    let tempNewOptionObject = {


                        id: dairyAddItemResult.id,
                        name: "List Items " + (tempOptionObjectList.length + 1),
                        parseObject: dairyAddItemResult,
                        items: dairyAddItemResult.attributes.items


                    }
                    sectionList[optionIndex].parseObject = diaryFeatureResult
                    tempOptionObjectList.push(tempNewOptionObject)
                    sectionList[optionIndex].options = tempOptionObjectList
                    tempList[outerIndex].sectionsList = sectionList

                    setOuterSectionsState(tempList);
                    setEditSectionObject(undefined);
                    setEditSectionName("");
                    toastr.success("List Item added successfully !!")

                })
            })
        } else {




        }

    }

    const getDefaultTypeWhileDiaryFeatureObject = (name) => {

        if (name === Food) {
            return 11
        } else if (name === Medical) {
            return 14
        } else if (name === Observation) {
            return 17
        }

    }

    function handleDeleteAdditionalDiaryItem(menuItem, sectionObject, outerKey) {

        let additionalItems = sectionObject.parseObject.attributes.additionalItems ? [...sectionObject.parseObject.attributes.additionalItems] : []
        let idToRemove = menuItem.parseObject.id
        removeElement(additionalItems, idToRemove)
        let parseObjectToSave = sectionObject.parseObject
        parseObjectToSave.set("additionalItems", additionalItems)
        setDeletingMenuItemList(true)
        parseObjectToSave.save().then((result) => {

            let tempList = [...outerSectionsState];
            let outerIndex = outerKey
            let sectionList = [...tempList[outerIndex].sectionsList]
            let optionIndex = sectionList.indexOf(sectionObject)


            let tempOptionObjectList = sectionList[optionIndex].options ? [...sectionList[optionIndex].options] : []

            removeElement(tempOptionObjectList, menuItem)


            sectionList[optionIndex].options = tempOptionObjectList
            tempList[outerIndex].sectionsList = sectionList

            setOuterSectionsState(tempList);
            setEditSectionObject(undefined);
            setEditSectionName("");
            setDeletingMenuItemList(false)

        })
    }

    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    const handleEnableDisableCategory = (object, os, key, outerKey) => {
        if (!object.parseObject && object.isDeleted) {
            enableLocalFeature(object, os, key, outerKey)
        } else if (object.parseObject) {
            enableDisableParseObject(object, os, key, outerKey)
        }
    }

    const enableDisableParseObject = (object, os, key, outerKey) => {


        let indexForChanges = []
        let parseObjectsToSave = []
        let stateObjects = []


        for (var i = 0; i < os.sectionsList.length; i++) {

            if (os.sectionsList[i].isDeleted !== true && os.sectionsList[i].parseObject && object.type !== 12 && naptimeConstantsList.includes(os.sectionsList[i].type)) {
                let tempObj = os.sectionsList[i].parseObject
                tempObj.set("isDeleted", true)
                parseObjectsToSave.push(tempObj)
                stateObjects.push(os.sectionsList[i])
                indexForChanges.push(i)
            }
        }

        object.parseObject.set("isDeleted", !object.isDeleted)
        parseObjectsToSave.push(object.parseObject)
        indexForChanges.push(key)
        stateObjects.push(object)

        if (!object.isDeleted) {
            toastr.warning("Please wait disabling category")
        } else {
            toastr.warning("Please wait enabling category")
        }
        Parse.Object.saveAll(parseObjectsToSave).then(
            (results) => {
                if (results) {
                    let ol = [...allDiaryFeatues];
                    for (const df of results) {
                        let add = true;
                        for (let ex of ol) {
                            if (df.id == ex.id) {
                                ex = df;
                                add=false
                            }
                        }
                        if (add) {
                            ol.push(df)
                        }
                    }
                    setAllDiaryFeatues(ol);
                    let tempList = [...outerSectionsState];
                    let outerIndex = getSectionListIndex(object.type)
                    let sectionList = [...tempList[outerIndex].sectionsList]

                    for (var i = 0; i < indexForChanges.length; i++) {
                        let optionIndex = indexForChanges[i]
                        sectionList[optionIndex].isDeleted = results[i].attributes.isDeleted ?? false
                        sectionList[optionIndex].parseObject = results[i]
                    }

                    tempList[outerIndex].sectionsList = sectionList
                    if (object.isDeleted) {
                        toastr.error("Category Disabled")
                    } else {
                        toastr.success("Category Enabled")
                    }
                    setShowAlertWhileEnable(true)
                    setOuterSectionsState(tempList);
                }
            }
        )
    }




    const enableLocalFeature = (localObject, outerSection, sectionIndex, outerKey) => {

        setEnablingFeature(true);
        toastr.warning("Please wait enabling category")

        let DiaryFeatures = Parse.Object.extend("DiaryFeatures");
        let diaryFeature = new DiaryFeatures();
        diaryFeature.set("routine", localObject.name);
        if (localObject.measures && localObject.measures.length > 0) {
            diaryFeature.set("measures", localObject.measures);
        }
        diaryFeature.set("schoolId", schoolId);
        diaryFeature.set("type", localObject.type)
        diaryFeature.set("order", allDiaryFeatues.length)






        let indexForChanges = []
        let parseObjectsToSave = []
        let stateObjects = []


        for (var i = 0; i < outerSection.sectionsList.length; i++) {

            if (outerSection.sectionsList[i].isDeleted !== true && outerSection.sectionsList[i].parseObject && localObject.type !== 12 && naptimeConstantsList.includes(outerSection.sectionsList[i].type)) {
                let tempObj = outerSection.sectionsList[i].parseObject
                tempObj.set("isDeleted", true)
                parseObjectsToSave.push(tempObj)
                stateObjects.push(outerSection.sectionsList[i])
                indexForChanges.push(i)
            }
        }



        parseObjectsToSave.push(diaryFeature)
        indexForChanges.push(sectionIndex)
        stateObjects.push(localObject)


        Parse.Object.saveAll(parseObjectsToSave).then(
            (results) => {

                let ol = [...allDiaryFeatues];
                for (const df of results) {
                    let add = true;
                    for (let ex of ol) {
                        if (df.id == ex.id) {
                            ex = df;
                            add=false
                        }
                    }
                    if (add) {
                        ol.push(df)
                    }
                }
                setAllDiaryFeatues(ol);


                setEnablingFeature(false);

                let lastIndex = results.length - 1

                let optionObject = {
                    id: results[lastIndex].id,
                    name: results[lastIndex].attributes.routine,
                    menuItems: [],
                    type: results[lastIndex].attributes.type,
                    parseObject: results[lastIndex],
                };
                if (outerSection.name !== Food) {
                    if (results[lastIndex].attributes.type === 21 || results[lastIndex].attributes.type === 20) {
                        optionObject.showHelpText = true
                    } else {

                        if (results[lastIndex].attributes.measures && results[lastIndex].attributes.measures.length > 0) {
                            optionObject.options = [{
                                items: results[lastIndex].attributes.measures,
                                id: results[lastIndex].id,
                                parseObject: results[lastIndex],
                                name: "List Items"
                            }]
                        } else {
                            optionObject.options = [{
                                items: [],
                                id: results[lastIndex].id,
                                parseObject: results[lastIndex],
                                name: "List Items"
                            }]
                        }
                        optionObject.measures = results[lastIndex].attributes.measures
                    }


                }

                let tempList = [...outerSectionsState];



                tempList[outerKey].sectionsList[sectionIndex] = optionObject



                for (var i = 0; i < indexForChanges.length; i++) {
                    let optionIndex = indexForChanges[i]
                    tempList[outerKey].sectionsList[optionIndex].isDeleted = results[i].attributes.isDeleted ?? false
                    tempList[outerKey].sectionsList[optionIndex].parseObject = results[i]
                }



                setSelectedOptionId(tempList[outerKey].id);
                setOuterSectionsState(tempList);
                setShowAlertWhileEnable(true)

                toastr.success("Category Enabled")

            },
            (error) => {
                toastr.warning("Please try again")
                setEnablingFeature(false);
            }
        );
    }



    return (
        <React.Fragment>
            <div className="page-content">
                {!isLoading &&
                    <Card >
                        <CardBody>
                            <div className="checkout-tabs">
                                <Row>
                                    <Col lg="2">
                                        <Nav className="flex-column" pills>
                                            <Element name="navTabs">

                                                {
                                                    outerSectionsState.map((os, key) => (
                                                        <NavItem key={key + os.id + ""}>
                                                            <NavLink
                                                                className={classnames({ active: activeTab === "" + (key + 1) })}
                                                                onClick={() => {
                                                                    setactiveTab((key + 1) + "");
                                                                }}
                                                            >
                                                                {os.icon ? <img width="60px" height="60px" className="mt-4 mb-2" src={os.icon}></img> :
                                                                    <i className="bx bx-list-ol d-block check-nav-icon mt-0 text-warning "></i>
                                                                }
                                                                <p className="font-weight-bold mb-4">
                                                                    {props.t(os.name)}
                                                                </p>
                                                                {os.desc && <p>{os.desc}</p>}
                                                            </NavLink>
                                                        </NavItem>

                                                    ))
                                                }


                                            </Element>

                                        </Nav>
                                    </Col>
                                    <Col lg="10">
                                        <Card>
                                            <CardBody>

                                                {showAlertWhileEnable && <UncontrolledAlert color="info" className="alert-dismissible fade show mb-2 mt-0" role="alert">
                                                    <i className="mdi mdi-alert-circle-outline mr-2"></i>
                                                    Teachers need to refresh in there mobile app inside diary section for the changes to apply.
                                                    <p className="mt-3">* Go to left menu in app</p>
                                                    <p>* Then select diary from list of options</p>
                                                    <p>* Then click on plus icon on top right corner of screen</p>
                                                    <p>* Click on refresh button on top right corner of screen</p>

                                                </UncontrolledAlert>}

                                                <TabContent activeTab={activeTab}>
                                                    {
                                                        outerSectionsState.map((os, outerKey) => (
                                                            (os.name != reOrder) ?
                                                                    
                                                                <TabPane tabId={"" + (outerKey + 1)} key={outerKey}>
                                                                    <CardTitle> {props.t("Categories")}</CardTitle>
                                                                    <div className="cursors move">
                                                                        {

                                                                            os.sectionsList.map((object, key) => (
                                                                                <Card
                                                                                    className="mb-1 bg-white"
                                                                                    key={key}
                                                                                >
                                                                                    <CardHeader
                                                                                        className="p-3"
                                                                                        id="headingOne"
                                                                                    >
                                                                                        <div>
                                                                                            {(object.parseObject && (editSectionObject && editSectionObject.id) === object.parseObject.id) ? <Col>
                                                                                                <div className="list-inline contact-links mb-0">
                                                                                                    <AvForm className="outer-repeater mt-3"
                                                                                                        onValidSubmit={(e, v) => {
                                                                                                            if (v.edittitle && v.edittitle.length > 0) {


                                                                                                                var pObject = object.parseObject;
                                                                                                                pObject.set("routine", v.edittitle);
                                                                                                                object.parseObject.set("routine", v.edittitle)
                                                                                                                object.parseObject.save().then(
                                                                                                                    (result) => {

                                                                                                                        let tempList = [...outerSectionsState];
                                                                                                                        let outerIndex = getSectionListIndex(object.type)
                                                                                                                        let sectionList = [...tempList[outerIndex].sectionsList]
                                                                                                                        let optionIndex = sectionList.indexOf(object)
                                                                                                                        sectionList[optionIndex].name = v.edittitle
                                                                                                                        tempList[outerIndex].sectionsList = sectionList

                                                                                                                        setOuterSectionsState(tempList);
                                                                                                                        setEditSectionObject(undefined);
                                                                                                                        setEditSectionName("");
                                                                                                                    }
                                                                                                                )

                                                                                                            } else {
                                                                                                                toastr.warning("diary feature name cannot be empty !!")
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <div data-repeater-item className="outer">
                                                                                                            <FormGroup >
                                                                                                                <AvField
                                                                                                                    type="text"
                                                                                                                    name="edittitle"
                                                                                                                    placeholder={props.t('Section Name')}
                                                                                                                    className="form-control"
                                                                                                                    value={editSectionName}

                                                                                                                />
                                                                                                            </FormGroup>
                                                                                                        </div>

                                                                                                        <button
                                                                                                            type="submit"
                                                                                                            className="btn btn-primary m-1 "
                                                                                                        >
                                                                                                            {props.t('Save')}
                                                                                                        </button>
                                                                                                        <button type="button"
                                                                                                            className="btn btn-danger "
                                                                                                            onClick={() => {
                                                                                                                setEditSectionObject(undefined);
                                                                                                                setEditSectionName("");
                                                                                                            }}  >
                                                                                                            {props.t('Close')}

                                                                                                        </button>
                                                                                                    </AvForm>

                                                                                                </div>

                                                                                            </Col> :


                                                                                                <Row>
                                                                                                    <Col lg={10} xs={10}
                                                                                                        style={{ cursor: "pointer" }}
                                                                                                        onClick={() => {
                                                                                                            if (selectedOptionId === object.id) {
                                                                                                                setSelectedOptionId("");
                                                                                                            } else {
                                                                                                                setSelectedOptionId(object.id);
                                                                                                            }
                                                                                                        }}
                                                                                                    >
                                                                                                        <h6 className="m-0 font-14">
                                                                                                            <span
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className="text-dark"
                                                                                                            >
                                                                                                                {getNaptimeHeading(object)}{" "}
                                                                                                            </span>


                                                                                                            <i
                                                                                                                style={{ cursor: "pointer" }}
                                                                                                                className={
                                                                                                                    object.id === selectedOptionId
                                                                                                                        ? "bx bxs-chevron-up  ml-2"
                                                                                                                        : "bx bxs-chevron-down  ml-2"
                                                                                                                }
                                                                                                            />

                                                                                                        </h6>



                                                                                                    </Col>



                                                                                                    <Col>
                                                                                                        <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                                                                                            {!naptimeConstantsList.includes(object.type) && object.parseObject && <li className="list-inline-item px-2"
                                                                                                                style={{ cursor: "pointer", marginLeft: '30px' }}
                                                                                                                onClick={() => {
                                                                                                                    setEditSectionObject(object.parseObject);
                                                                                                                    setEditSectionName(object.name);
                                                                                                                }}
                                                                                                                id={"edit" + activeTab + key + outerKey}>
                                                                                                                <i className="bx bx-edit-alt"></i>
                                                                                                                <UncontrolledTooltip placement="top" target={"edit" + activeTab + key + outerKey}>
                                                                                                                    {props.t('Edit')}
                                                                                                                </UncontrolledTooltip>
                                                                                                            </li>}
                                                                                                        </ul>
                                                                                                    </Col>
                                                                                                    <Col>
                                                                                                        <div className="float-right" id={"switch" + activeTab + key + outerKey}>
                                                                                                            <Switch

                                                                                                                height={15}
                                                                                                                width={25}
                                                                                                                uncheckedIcon={<Offsymbol />}
                                                                                                                checkedIcon={<OnSymbol />}
                                                                                                                onColor="#556ee6"
                                                                                                                onChange={() => {

                                                                                                                    handleEnableDisableCategory(object, os, key, outerKey)


                                                                                                                }}
                                                                                                                checked={!object.isDeleted}
                                                                                                            />
                                                                                                            <UncontrolledTooltip placement="top" target={"switch" + activeTab + key + outerKey}>
                                                                                                                {props.t('Disable/Enable')}
                                                                                                            </UncontrolledTooltip>
                                                                                                        </div>

                                                                                                    </Col>
                                                                                                </Row>
                                                                                            }
                                                                                        </div>
                                                                                    </CardHeader>

                                                                                    <Collapse
                                                                                        isOpen={object.id === selectedOptionId}
                                                                                    >
                                                                                        <DiaryMenuItems
                                                                                            schoolId={schoolId}
                                                                                            menuItems={object.options}
                                                                                            sectionObject={object}
                                                                                            measures={object.measures}
                                                                                            diaryFeatureJsObject={object}
                                                                                            outerSectionKey={outerKey}
                                                                                            isFoodType={object.type === 11 || object.type === 19}
                                                                                            showAddMenuItemsButton={object.type === 11 || object.type === 19}
                                                                                            onEditMenuItems={(editMenuItemObject, sectionObject, editedItems, isFoodType, outerKey) => {
                                                                                                handleMenuItemsEdit(editMenuItemObject, sectionObject, editedItems, isFoodType, outerKey)
                                                                                            }}
                                                                                            onAddNewMenuItems={(sectionObject, editedItems, isFoodType, outerKey) => {
                                                                                                handleMenuItemsAdd(sectionObject, editedItems, isFoodType, outerKey)
                                                                                            }}
                                                                                            onRemoveMenuItems={(menuItem, sectionObject, outerKey) => {
                                                                                                handleDeleteAdditionalDiaryItem(menuItem, sectionObject, outerKey)
                                                                                            }}
                                                                                            deletingMenuItemList={deletingMenuItemList}
                                                                                            showHelpText={object.showHelpText ?? false}

                                                                                        />
                                                                                    </Collapse>

                                                                                </Card>
                                                                            ))
                                                                        }
                                                                        <Col lg={12}>
                                                                            {showAddNewSectionView && (
                                                                                <AvForm className="outer-repeater">
                                                                                    <div data-repeater-item className="outer">
                                                                                        <FormGroup>
                                                                                            <Label htmlFor="formname">
                                                                                                {props.t("Diary Category Name")}:{" "}
                                                                                            </Label>
                                                                                            <AvField
                                                                                                type="text"
                                                                                                name="name"
                                                                                                placeholder={props.t(
                                                                                                    "Enter diary category name..."
                                                                                                )}
                                                                                                className="form-control"
                                                                                                value={newSectionName}
                                                                                                validate={{
                                                                                                    required: {
                                                                                                        value: true,
                                                                                                        errorMessage: props.t(
                                                                                                            "Enter Diary category name..."
                                                                                                        ),
                                                                                                    },
                                                                                                }}
                                                                                                onChange={(e) => {
                                                                                                    if (e.target.value.length > 0 && sectionNameError) {
                                                                                                        setSectionNameError(false);
                                                                                                    }
                                                                                                    setNewSectionName(e.target.value);
                                                                                                }}
                                                                                            />
                                                                                            {sectionNameError && <p className="text-danger">{props.t('Category Name can not be empty')}</p>}
                                                                                        </FormGroup>
                                                                                    </div>
                                                                                </AvForm>
                                                                            )}
                                                                            {!showAddNewSectionView ? (
                                                                                <>{os.name !== Naptime_Diaperchange && <button
                                                                                    onClick={() => {
                                                                                        setShowAddNewSectionView(!showAddNewSectionView);
                                                                                    }}
                                                                                    type="button"
                                                                                    className="btn btn-primary waves-effect waves-light float-right m-2 mt-3"
                                                                                >
                                                                                    <i className="bx bx-plus font-size-16 align-middle mr-1"></i>{" "}
                                                                                    {props.t("Add Category")}
                                                                                </button>}</>
                                                                            ) : (
                                                                                <div className="d-flex justify-content-between">
                                                                                    <button
                                                                                        type="button"
                                                                                        className="btn btn-secondary"
                                                                                        onClick={() => {
                                                                                            setShowAddNewSectionView(!showAddNewSectionView);
                                                                                            setNewSectionName("");
                                                                                            setSectionNameError(false);
                                                                                        }}
                                                                                    >
                                                                                        {props.t("Close")}
                                                                                    </button>

                                                                                    <div className="text-right">
                                                                                        <button
                                                                                            onClick={() => {
                                                                                                if (newSectionName && newSectionName.length > 0) {
                                                                                                    let nameAlreadyExists = false;
                                                                                                    for (var i = 0; i < os.sectionsList.length; i++) {
                                                                                                        if (newSectionName === os.sectionsList[i].name) {
                                                                                                            nameAlreadyExists = true;
                                                                                                        }
                                                                                                    }
                                                                                                    if (nameAlreadyExists) {
                                                                                                        toastr.warning("This category name already exists, try with a different name !!");
                                                                                                        return;
                                                                                                    } else {
                                                                                                        handleAddNewSection(os, outerKey);
                                                                                                        setShowAddNewSectionView(!showAddNewSectionView);
                                                                                                        setNewSectionName("");
                                                                                                        setSectionNameError(false);
                                                                                                    }
                                                                                                } else {
                                                                                                    setSectionNameError(true);
                                                                                                }
                                                                                            }}
                                                                                            type="button"
                                                                                            className="btn btn-primary waves-effect waves-light"
                                                                                        >
                                                                                            <i className="bx bx-save font-size-16 align-middle mr-1"></i>{" "}
                                                                                            {props.t("Save Category")}
                                                                                        </button>
                                                                                    </div>
                                                                                </div>

                                                                            )}
                                                                        </Col>
                                                                    </div>
                                                                </TabPane>
                                                            :
                                                                <TabPane tabId={"" + (outerKey + 1)} key={outerKey}>
                                                                    <CardTitle> {props.t("Rearrange")}</CardTitle>
                                                                    <DragDropTables
                                                                        rearrangeObjects={nonDeletedDiaryFeatues}
                                                                        updatedOrderList={handelDiaryFeaturesSort}
                                                                        onClickClose={() => { setRearrange(false) }}
                                                                        hideClose={true}
                                                                    />
                                                                        
                                                                </TabPane>
                                                            
                                                        ))
                                                    }
                                                </TabContent>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                </Row>
                            </div>


                        </CardBody>
                    </Card>}



                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Diary...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}</div></React.Fragment >
    );
};
const mapStatetoProps = (state) => {
    const { selectedSchool, schools } = state.School;
    const { admissionTitles } = state.AdmissionTitle;
    const { admissionQuestions, defaultQuestions } = state.AdmissionQuestion;
    return { selectedSchool, admissionTitles, admissionQuestions, defaultQuestions };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,

        })(DiaryView)
    )
);
