export const UPDATE_GROUP_MAP = "UPDATE_GROUP_MAP";
export const DELETE_GROUP_MAP = "DELETE_GROUP_MAP";

export const CHECK_AND_ADD_GROUP_MAPS_FOR_KIDS = "CHECK_AND_ADD_GROUP_MAPS_FOR_KIDS"

export const SAVE_GROUP_MAPS = 'SAVE_GROUP_MAPS';
export const UPDATE_RESULT_GROUP_MAPS = "UPDATE_RESULT_GROUP_MAPS";

export const UPDATE_GROUP_MAPS = 'UPDATE_GROUP_MAPS';
export const GET_GROUP_MAPS_FOR_GROUP_ID = 'GET_GROUP_MAPS_FOR_GROUP_ID';
export const GET_GROUP_MAP = 'GET_GROUP_MAP';

export const GET_GROUP_MAPS_FOR_TEACHER_ID = "GET_GROUP_MAPS_FOR_TEACHER_ID";

export const GROUP_MAP_SUCCESS = "GROUP_MAP_SUCCESS";
export const GROUP_MAP_ERROR = "GROUP_MAP_ERROR";

export const SET_LOADING_GROUPMAPS = "SET_LOADING_GROUPMAPS"

export const GET_GROUP_MAP_FOR_PROGRAM_GROUPIDS = "GET_GROUP_MAP_FOR_PROGRAM_GROUPIDS";
export const UPDATE_GROUP_MAP_FOR_PROGRAM_GROUPIDS = "UPDATE_GROUP_MAP_FOR_PROGRAM_GROUPIDS";

export const GET_GROUP_MAPS_FOR_KID_ID =  "GET_GROUP_MAPS_FOR_KID_ID"

export const GET_GROUP_MAP_FOR_TRIP_GROUPIDS="GET_GROUP_MAP_FOR_TRIP_GROUPIDS"
export const UPDATE_GROUP_MAP_FOR_TRIP_GROUPIDS= "UPDATE_GROUP_MAP_FOR_TRIP_GROUPIDS"