import React, { useEffect, useState } from "react";
import {
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import Moment from "moment";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import Parse from "parse";
import Select from "react-select";
import {
  getTeachersForSchool,
  getClassroomsForSchoolId,
  getAllKids,
  getAttendanceForTeacherIds,
  getAttendanceForKidIds,
  getDaycareAttendanceForKidIds,
} from "../../../store/actions";

const AttendanceReport = (props) => {
    const [reportType, setReportType] = useState("1");
    const [teacherList, setTeacherList] = useState([]);
    const [classList, setClassList] = useState([]);
    const [daycareList, setDaycareList] = useState([]);
    const [classStudent, setClassStudent] = useState([]);
    const [daycareStudent, setDaycareStudent] = useState([]);
    const [allClassStudent, setAllClassStudent] = useState([]);
    const [allDaycareStudent, setAllDaycareStudent] = useState([]);
    const [activeTeacher, setActiveTeacher] = useState("all");
    const [activeClass, setActiveClass] = useState("all");
    const [activeChild, setActiveChild] = useState("all");
    const [activemonth, setActiveMonth] = useState(new Date());
    const [gotReport, setGotReport] = useState(false);
    const [attendanceReport, setAttendanceReport] = useState([]);
    const [totalCheckIns, setTotalCheckIns] = useState(undefined);
    const [totalCheckOuts, setTotalCheckOuts] = useState();


    

    const [loading, setloading] = useState(false);

    //dec
    const [teacherDec, setTeacherDec] = useState([]);
    const [kidDec, setKidDec] = useState([]);
    const [classDec, setClassDec] = useState([]);
    const [daycareDec, setDaycareDec] = useState([]);

  
    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });
    const [selectedDate, selectedDateOps] = useState(new Date());
    const [selectedEndDate, selectedEndDateOps] = useState(new Date());

    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);
    

   

    
    
    const getAttendanceReport = () => {
        setloading(true)
        if (reportType === "1") {
            let params = {};
            params.teacherIds = [];
            params.schoolId = props.schoolId;

            params.startDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate()
            );
            params.endDate = new Date(
                selectedEndDate.getFullYear(),
                selectedEndDate.getMonth(),
                selectedEndDate.getDate(),
                23,
                59,
                59,
                999
            );

            if (activeTeacher === "all") {
                for (const val of teacherList) {
                    params.teacherIds.push(val.id);
                }
            } else {
                params.teacherIds.push(activeTeacher);
            }
            props.getAttendanceForTeacherIds(params);
        } else if (reportType === "2") {
            //class
            let params = {};
            params.kidIds = [];
            params.schoolId = props.schoolId;

            params.startDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate()
            );
            params.endDate = new Date(
                selectedEndDate.getFullYear(),
                selectedEndDate.getMonth(),
                selectedEndDate.getDate(),
                23,
                59,
                59,
                999
            );

            if (activeChild === "all") {
                for (const val of classStudent) {
                    params.kidIds.push(val.id);
                }
            } else {
                params.kidIds.push(activeChild);
            }
            props.getAttendanceForKidIds(params);
        } else if (reportType === "3") {
            //daycare
            let params = {};
            params.kidIds = [];
            params.schoolId = props.schoolId;
      

            params.startDate = new Date(
                selectedDate.getFullYear(),
                selectedDate.getMonth(),
                selectedDate.getDate()
            );
            params.endDate = new Date(
                selectedEndDate.getFullYear(),
                selectedEndDate.getMonth(),
                selectedEndDate.getDate(),
                23,
                59,
                59,
                999
            );


            if (activeChild === "all") {
                for (const val of daycareStudent) {
                    params.kidIds.push(val.id);
                }
            } else {
                params.kidIds.push(activeChild);
            }
            props.getDaycareAttendanceForKidIds(params);
        }
    };
    useEffect(() => {
        setGotReport(props.gotAttendance);
        if (props.gotAttendance) {
            setloading(false)
        }
    }, [props.gotAttendance]);

    useEffect(() => {
  
        let tempTotalCheckIns = 0
        let tempTotalCheckOuts = 0
        let tempAttendance = [];
        for (const val of props.teacherAttendanceList) {
            let temp = {};
            temp.name = teacherDec[val.attributes.teacherId]
                ? teacherDec[val.attributes.teacherId].name
                : "--";
            temp.jsInTime = val.attributes.ReachedSchoolAt
            // ? val.attributes.ReachedSchoolAt
            // : val.attributes.createdAt;
            temp.jsOutTime = val.attributes.LeftSchoolAt;
            if (temp.jsInTime) {
                temp.checkInTime = `${getTimeIn12Hr(temp.jsInTime)} ${getDateInDDMMYYYY(
                    temp.jsInTime
                )}`; //10:30Am 10/1/2020

                tempTotalCheckIns += 1
            } else {
                temp.checkInTime = "--:-- -- --/--/----";
            }
            if (temp.jsOutTime) {
                temp.checkOutTime = `${getTimeIn12Hr(
                    temp.jsOutTime
                )} ${getDateInDDMMYYYY(temp.jsOutTime)}`;

                tempTotalCheckOuts += 1

            } else {
                temp.checkOutTime = "--:-- -- --/--/----";

            }
            temp.totalHrs =
                temp.jsInTime && temp.jsOutTime
                    ? getTimeDifference(temp.jsInTime, temp.jsOutTime)
                    : "-- Hrs -- Min";
            temp.obj = val;
            tempAttendance.push(temp);
        }
        setAttendanceReport(tempAttendance);
        setTotalCheckIns(tempTotalCheckIns)
        setTotalCheckOuts(tempTotalCheckOuts)
        // }
    }, [props.teacherAttendanceList]);
  
    useEffect(() => {
        // if (props.kidAttendanceList.length > 0) {
        let tempTotalCheckIns = 0
        let tempTotalCheckOuts = 0
        let tempAttendance = [];
        for (const val of props.kidAttendanceList) {
            let temp = {};
            temp.id = val.id;
            temp.obj = val;
            temp.kidId = val.attributes.Kid;
            temp.name = kidDec[val.attributes.Kid]
                ? kidDec[val.attributes.Kid].name
                : "--";
            temp.jsInTime = val.attributes.ReachedSchoolAt
            // ? val.attributes.ReachedSchoolAt
            // : val.attributes.createdAt;
            temp.jsOutTime = val.attributes.LeftSchoolAt;
            if (temp.jsInTime) {
                temp.checkInTime = `${getTimeIn12Hr(temp.jsInTime)} ${getDateInDDMMYYYY(
                    temp.jsInTime
                )}`; //10:30Am 10/1/2020
                tempTotalCheckIns += 1
            } else {
                temp.checkInTime = "--:-- -- --/--/----";
            }
            if (temp.jsOutTime) {
                temp.checkOutTime = `${getTimeIn12Hr(
                    temp.jsOutTime
                )} ${getDateInDDMMYYYY(temp.jsOutTime)}`;
                tempTotalCheckOuts += 1
            } else {
                temp.checkOutTime = "--:-- -- --/--/----";
            }
            temp.totalHrs =
                temp.jsInTime && temp.jsOutTime
                    ? getTimeDifference(temp.jsInTime, temp.jsOutTime)
                    : "-- Hrs -- Min";
            tempAttendance.push(temp);
        }
        setAttendanceReport(tempAttendance);
        setTotalCheckIns(tempTotalCheckIns)
        setTotalCheckOuts(tempTotalCheckOuts)
        // }
    }, [props.kidAttendanceList]);

    const arrangeBasedOnKids = () => {
        let tempReport = []
        for (const kid of props.kids) {
            for (const ar of attendanceReport) {
                if (ar.kidId && kid.id == ar.kidId) {
                    tempReport.push(ar)
                }
            }
      
        }
        if (tempReport.length > 0) {
            setAttendanceReport(tempReport);
        }
    }

    useEffect(() => {
        let tempTotalCheckIns = 0
        let tempTotalCheckOuts = 0
        let tempAttendance = [];
        for (const val of props.daycareAttendance) {
            let temp = {};
            temp.id = val.id;
            temp.obj = val;
            temp.kidId = val.attributes.kidId
            temp.name = kidDec[val.attributes.kidId]
                ? kidDec[val.attributes.kidId].name
                : "--";
            temp.jsInTime = val.attributes.checkInTime
                ? val.attributes.checkInTime
                : val.attributes.createdAt;
            temp.jsOutTime = val.attributes.checkOutTime;
            if (temp.jsInTime) {
                temp.checkInTime = `${getTimeIn12Hr(temp.jsInTime)} ${getDateInDDMMYYYY(
                    temp.jsInTime
                )}`; //10:30Am 10/1/2020
                tempTotalCheckIns += 1

            } else {
                temp.checkInTime = "--:-- -- --/--/----";
            }
            if (temp.jsOutTime) {
                temp.checkOutTime = `${getTimeIn12Hr(
                    temp.jsOutTime
                )} ${getDateInDDMMYYYY(temp.jsOutTime)}`;
                tempTotalCheckOuts += 1

            } else {
                temp.checkOutTime = "--:-- -- --/--/----";
            }
            temp.totalHrs =
                temp.jsInTime && temp.jsOutTime
                    ? getTimeDifference(temp.jsInTime, temp.jsOutTime)
                    : "-- Hrs -- Min";
            tempAttendance.push(temp);
        }
        setAttendanceReport(tempAttendance);
        setTotalCheckIns(tempTotalCheckIns)
        setTotalCheckOuts(tempTotalCheckOuts)
    }, [props.daycareAttendance]);

    const getTimeIn12Hr = (date) => {
        return Moment(date).format("LT");
    };
    const getDateInDDMMYYYY = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };
    const getTimeDifference = (inTime, outTime) => {
        let startTime = Moment(inTime);
        let endTime = Moment(outTime);
        let duration = Moment.duration(endTime.diff(startTime));
        let hours = parseInt(duration.asHours());
        let minutes = parseInt(duration.asMinutes()) % 60;
        return `${hours} Hrs ${minutes} Min`;
    };

    const getDaysDifference = (inTime, outTime) => {
        let startTime = Moment(inTime);
        let endTime = Moment(outTime);
        let duration = Moment.duration(endTime.diff(startTime));
   
        let days = parseInt(duration.asDays());
    
        return days;
    };

    const downloadTheReport = () => {
        let rawData = attendanceReport;
        let finalData = [];
        let titles = [];
        let fileName = "";
        let sheetName = "";

        finalData.push([`School Name: ${props.selectedSchool.attributes.Name}`]);
        if (reportType === "1") {
            sheetName = "All Staff"
            if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
                sheetName = teacherDec[activeTeacher].name
            }
            fileName = `${sheetName} Attendance ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
            let reportType = "Staff Attendance"
            finalData.push([`Report Type: ${reportType}`]);
            if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
                finalData.push([
                    `Teacher Details: ${teacherDec[activeTeacher].name}`,
                ]);
            } else {
                finalData.push([
                    `Selected Staff: ${activeTeacher === "all"
                        ? "All Staff"
                        : teacherDec[activeTeacher]
                            ? teacherDec[activeTeacher].name
                            : "Staff"
                    }`,
                ]);
            }
      
            finalData.push([
                `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
            ]);
            finalData.push([]);

            if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
                titles = [
                    "Date",
                    "Check-In",
                    "Check-Out",
                    "Total Hrs",
                    "Check In By",
                    "Check Out By",
                    "Check-In Type",
                    "Check-Out Type",
                ];
            } else {
                titles = [
                    "Date",
                    "Name",
                    "Check-In",
                    "Check-Out",
                    "Total Hrs",
                    "Check In By",
                    "Check Out By",
                    "Check-In Type",
                    "Check-Out Type",
                ];

            }

            finalData.push(titles);
            for (const val of rawData) {
                let temp = [];
                temp.push(
                    `${val.obj.createdAt.getDate()}-${val.obj.createdAt.getMonth() + 1
                    }-${val.obj.createdAt.getFullYear()}`
                );

                if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
                } else {
                    temp.push(val.name);
                }

                temp.push(getTimeIn12Hr(val.jsInTime));
                temp.push(val.jsOutTime ? getTimeIn12Hr(val.jsOutTime) : "--");
                temp.push(val.totalHrs);
                temp.push(
                    val.obj.attributes.checkedInBy
                        ? teacherDec[val.obj.attributes.checkedInBy]
                            ? teacherDec[val.obj.attributes.checkedInBy].name
                            : "--"
                        : "--"
                );
                temp.push(
                    val.obj.attributes.checkedOutBy
                        ? teacherDec[val.obj.attributes.checkedOutBy]
                            ? teacherDec[val.obj.attributes.checkedOutBy].name
                            : "--"
                        : "--"
                );
                temp.push(
                    val.obj.attributes.checkInType ? val.obj.attributes.checkInType : null
                );
                temp.push(
                    val.obj.attributes.checkOutType
                        ? val.obj.attributes.checkOutType
                        : null
                );
                finalData.push(temp);
            }
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);

            //monthly sheet
            if (activeTeacher === "all" || true) {
                let finalData = [];
                let title = [];
                let sheetName = `Calendar Report `;
                finalData.push([
                    `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
                ]);
                title.push("#");

                // const noOfDays = new Date( activemonth.getFullYear(),activemonth.getMonth() + 1, 0).getDate();
                const noOfDays = getDaysDifference(selectedDate, selectedEndDate) + 1;

                let startDate = selectedDate;
                for (let i = 1; i <= noOfDays; i++) {
                    title.push(getDateInDDMMYYYY(startDate));
                    startDate = Moment(startDate).add(1, 'days');
                }
                finalData.push(title);

                for (const val of teacherList) {
                    if (activeTeacher !== "all" && teacherDec[activeTeacher]) {
                        if (teacherDec[activeTeacher].id !== val.id) {
                            continue;
                        }
                    }
                    let temp = [];
                    temp.push(val.name);
                    let tempAttFor = [];
                    for (const attVal of rawData) {
                        // console.log(getDateInDDMMYYYY(attVal.jsInTime));
                        if (attVal.obj.attributes.teacherId === val.id) {
                            tempAttFor.push(getDateInDDMMYYYY(attVal.jsInTime));
                        }
                    }
                    for (let i = 0; i <= title.length; i++) {
                        if (i == 0) {

                        } else if (tempAttFor.includes(title[i])) {
                            temp.push("P");
                        } else {
                            temp.push(null);
                        }
                    }
                    finalData.push(temp);
                }
                let ws = XLSX.utils.aoa_to_sheet(finalData);
                XLSX.utils.book_append_sheet(wb, ws, sheetName);
            }

            XLSX.writeFile(wb, fileName);
        } else if (reportType === "2") {


            sheetName = "School"

            if (activeClass !== "all") {
                sheetName = "Class"
            }
            if (activeChild !== "all" && kidDec[activeChild]) {
                sheetName = kidDec[activeChild].name
            }

            fileName = `${sheetName} Attendance ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;


            let reportType = "Class Attendance"
            if (activeChild !== "all" && kidDec[activeChild]) {
                reportType = "Student Attendance"
            }
            finalData.push([`Report Type: ${reportType}`]);
            if (activeChild !== "all" && kidDec[activeChild]) {
                finalData.push([
                    `Student Details: ${kidDec[activeChild].name}, ${kidDec[activeChild]
                        ? kidDec[activeChild].obj.attributes.ClassRoomID
                            ? classDec[
                                kidDec[activeChild].obj.attributes.ClassRoomID
                            ]
                                ? classDec[
                                    kidDec[activeChild].obj.attributes.ClassRoomID
                                ].name
                                : "--"
                            : "--"
                        : "--"}`,
                ]);
            } else {
                finalData.push([
                    `Selected Class: ${activeClass === "all"
                        ? "All Class"
                        : classDec[activeClass]
                            ? classDec[activeClass].name
                            : "Class"
                    }`,
                ]);
            }
     
            finalData.push([
                `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
            ]);
            finalData.push([]);

            if (activeChild !== "all" && kidDec[activeChild]) {
                titles = [
                    "Date",
                    "Class",
                    "Check-In",
                    "Check-Out",
                    "Total Hrs",
                    "Check In By",
                    "Check Out By",
                    "Check-In Type",
                    "Check-Out Type",
                ];
            } else {
                titles = [
                    "Date",
                    "Name",
                    "Class",
                    "Check-In",
                    "Check-Out",
                    "Total Hrs",
                    "Check In By",
                    "Check Out By",
                    "Check-In Type",
                    "Check-Out Type",
                ];
            }

            finalData.push(titles);
            for (const val of rawData) {
                let temp = [];
                temp.push(
                    `${val.obj.createdAt.getDate()}-${val.obj.createdAt.getMonth() + 1
                    }-${val.obj.createdAt.getFullYear()}`
                );

                if (activeChild !== "all" && kidDec[activeChild]) {
                } else {
                    temp.push(val.name);
                }
                temp.push(
                    kidDec[val.obj.attributes.Kid]
                        ? kidDec[val.obj.attributes.Kid].obj.attributes.ClassRoomID
                            ? classDec[
                                kidDec[val.obj.attributes.Kid].obj.attributes.ClassRoomID
                            ]
                                ? classDec[
                                    kidDec[val.obj.attributes.Kid].obj.attributes.ClassRoomID
                                ].name
                                : "--"
                            : "--"
                        : "--"
                );
                temp.push(getTimeIn12Hr(val.jsInTime));
                temp.push(val.jsOutTime ? getTimeIn12Hr(val.jsOutTime) : "--");
                temp.push(val.totalHrs);
                temp.push(
                    val.obj.attributes.checkedInBy
                        ? teacherDec[val.obj.attributes.checkedInBy]
                            ? teacherDec[val.obj.attributes.checkedInBy].name
                            : "--"
                        : "--"
                );
                temp.push(
                    val.obj.attributes.checkedOutBy
                        ? teacherDec[val.obj.attributes.checkedOutBy]
                            ? teacherDec[val.obj.attributes.checkedOutBy].name
                            : "--"
                        : "--"
                );
                temp.push(
                    val.obj.attributes.checkInType ? val.obj.attributes.checkInType : null
                );
                temp.push(
                    val.obj.attributes.checkOutType
                        ? val.obj.attributes.checkOutType
                        : null
                );
                finalData.push(temp);
            }
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);

            if (activeChild === "all" || true) {
                let finalData = [];
                let title = [];
                let sheetName = `Calendar Report`;
                finalData.push([
                    `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
                ]);
                title.push("Name");
                title.push("Class");
                const noOfDays = getDaysDifference(selectedDate, selectedEndDate) + 1;
                let startDate = selectedDate;
                for (let i = 1; i <= noOfDays; i++) {
                    title.push(getDateInDDMMYYYY(startDate));
                    startDate = Moment(startDate).add(1, 'days');
                }
                finalData.push(title);

                for (const val of classStudent) {
                    if (activeChild !== "all" && kidDec[activeChild]) {
                        if (kidDec[activeChild].id !== val.id) {
                            continue;
                        }
                    }
                    let temp = [];
                    temp.push(val.name);
                    temp.push(classDec[val.classId] ? classDec[val.classId].name : "--");
                    let tempAttFor = [];
                    for (const attVal of rawData) {
                        if (attVal.obj.attributes.Kid === val.id) {
                            tempAttFor.push(getDateInDDMMYYYY(attVal.jsInTime));
                        }
                    }
                    for (let i = 0; i <= title.length; i++) {
                        if (i == 0 || i == 1) {

                        } else if (tempAttFor.includes(title[i])) {
                            temp.push("P");
                        } else {
                            temp.push(null);
                        }
                    }
                    finalData.push(temp);
                }
                let ws = XLSX.utils.aoa_to_sheet(finalData);
                XLSX.utils.book_append_sheet(wb, ws, sheetName);
            }

            XLSX.writeFile(wb, fileName);
        } else if (reportType === "3") {

            sheetName = "Daycare"
            let className = ""

            if (activeClass !== "all" && daycareDec[activeClass]) {
                sheetName = daycareDec[activeClass] ? daycareDec[activeClass].name : "Daycare"
                className = daycareDec[activeClass] ? daycareDec[activeClass].name : "Daycare"

            }
            if (activeChild !== "all" && kidDec[activeChild]) {
                sheetName = kidDec[activeChild] ? kidDec[activeChild].name : "Daycare"


                fileName = `${sheetName}_${className} Attendance ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
            } else {
                fileName = `${sheetName} Attendance ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
            }
            sheetName = activeChild !== "all"
                ? `${kidDec[activeChild] ? kidDec[activeChild].name : "Student"
                } Attendance`
                : activeClass !== "all"
                    ? `${classDec[activeClass] ? classDec[activeClass].name : "Class"
                    } Attendance`
                    : "";

            let reportType = "Daycare Attendance"
            finalData.push([`Report Type: ${reportType}`]);

            if (activeChild !== "all" && kidDec[activeChild]) {

                let tempClassName = "Daycare"
                if (kidDec[activeChild] && !kidDec[activeChild].obj.attributes.daycareClassId
                    && kidDec[activeChild].obj.attributes.ClassRoomID && classDec[kidDec[activeChild].obj.attributes.ClassRoomID]) {
                    tempClassName = classDec[kidDec[activeChild].obj.attributes.ClassRoomID].name ?? "--"
                } else if (kidDec[activeChild] && kidDec[activeChild].obj.attributes.daycareClassId
                    && daycareDec[kidDec[activeChild].obj.attributes.daycareClassId]) {
                    tempClassName = daycareDec[kidDec[activeChild].obj.attributes.daycareClassId].name ?? "--"
                }


                finalData.push([
                    `Student Details: ${kidDec[activeChild].name}, ${tempClassName}`,
                ]);
            } else {
                finalData.push([
                    `Selected Class: ${activeClass === "all"
                        ? "All Class"
                        : daycareDec[activeClass]
                            ? daycareDec[activeClass].name
                            : "Daycare"
                    }`,
                ]);
            }
     
            finalData.push([
                `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
            ]);

            finalData.push([]);
            if (activeChild !== "all" && kidDec[activeChild]) {
                titles = [
                    "Date",
                    "Class",
                    "Check-In",
                    "Check-Out",
                    "Total Hrs",
                    "Check In By",
                    "Check Out By",
                    "Check-In Type",
                    "Check-Out Type",
                ];
            } else {
                titles = [
                    "Date",
                    "Name",
                    "Class",
                    "Check-In",
                    "Check-Out",
                    "Total Hrs",
                    "Check In By",
                    "Check Out By",
                    "Check-In Type",
                    "Check-Out Type",
                ];
            }

            finalData.push(titles);
            for (const val of rawData) {
                let temp = [];
                temp.push(
                    `${val.obj.createdAt.getDate()}-${val.obj.createdAt.getMonth() + 1
                    }-${val.obj.createdAt.getFullYear()}`
                );
                if (activeChild !== "all" && kidDec[activeChild]) {
                } else {
                    temp.push(val.name);
                }

                let className = "--"
                if (kidDec[val.obj.attributes.kidId] && !kidDec[val.obj.attributes.kidId].obj.attributes.daycareClassId
                    && kidDec[val.obj.attributes.kidId].obj.attributes.ClassRoomID && classDec[kidDec[val.obj.attributes.kidId].obj.attributes.ClassRoomID]) {
                    className = classDec[kidDec[val.obj.attributes.kidId].obj.attributes.ClassRoomID].name ?? "--"
                } else if (kidDec[val.obj.attributes.kidId] && kidDec[val.obj.attributes.kidId].obj.attributes.daycareClassId
                    && daycareDec[kidDec[val.obj.attributes.kidId].obj.attributes.daycareClassId]) {
                    className = daycareDec[kidDec[val.obj.attributes.kidId].obj.attributes.daycareClassId].name ?? "--"
                }
                temp.push(className)


                temp.push(getTimeIn12Hr(val.jsInTime));
                temp.push(val.jsOutTime ? getTimeIn12Hr(val.jsOutTime) : "--");
                temp.push(val.totalHrs);
                temp.push(
                    val.obj.attributes.checkedInBy
                        ? teacherDec[val.obj.attributes.checkedInBy]
                            ? teacherDec[val.obj.attributes.checkedInBy].name
                            : "--"
                        : "--"
                );
                temp.push(
                    val.obj.attributes.checkedOutBy
                        ? teacherDec[val.obj.attributes.checkedOutBy]
                            ? teacherDec[val.obj.attributes.checkedOutBy].name
                            : "--"
                        : "--"
                );
                temp.push(
                    val.obj.attributes.checkInType ? val.obj.attributes.checkInType : null
                );
                temp.push(
                    val.obj.attributes.checkOutType
                        ? val.obj.attributes.checkOutType
                        : null
                );
                finalData.push(temp);
            }
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);

            if (activeChild === "all") {
                let finalData = [];
                let title = [];
                let sheetName = `Calendar Report `;
                finalData.push([
                    `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
                ]);
                title.push("Name");
                title.push("Class");
                const noOfDays = getDaysDifference(selectedDate, selectedEndDate) + 1;

                let startDate = selectedDate;
                for (let i = 1; i <= noOfDays; i++) {
                    title.push(getDateInDDMMYYYY(startDate));
                    startDate = Moment(startDate).add(1, 'days');
                }

                for (const val of daycareStudent) {
                    let temp = [];
                    temp.push(val.name);
                    temp.push(
                        daycareDec[val.classId] ? daycareDec[val.classId].name : "--"
                    );
                    let tempAttFor = [];
                    for (const attVal of rawData) {
                        if (attVal.obj.attributes.kidId === val.id) {
                            tempAttFor.push(getDateInDDMMYYYY(attVal.jsInTime));
                        }
                    }
                    for (let i = 0; i <= title.length; i++) {
                        if (i == 0 || i == 1) {

                        } else if (tempAttFor.includes(title[i])) {
                            temp.push("P");
                        } else {
                            temp.push(null);
                        }
                    }
                    finalData.push(temp);
                }
                let ws = XLSX.utils.aoa_to_sheet(finalData);
                XLSX.utils.book_append_sheet(wb, ws, sheetName);
            }

            XLSX.writeFile(wb, fileName);
        }
    };
    return (
        <React.Fragment>
            

            <Row className="bg-light">
               
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Report Type")} :</Label>
                        <select
                            className="form-control "
                            value={reportType}
                            onChange={(e) => {
                                setReportType(e.target.value);
                            }}
                        >
                            <option value={2}>{props.t("Class")}</option>
                            <option value={3}>{props.t("Daycare")}</option>
                        
                        </select>
                    </FormGroup>
                </Col>

                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Selcted school")} :</Label>
                        
                         <Select
                            value={selectedSchool}
                            onChange={(v) => {
                                setSelctedSchool(v)
                            }}
                            options={allSchools}
                            className="selectPaymentType"
                            classNamePrefix="selectPayType"
                            
                        />
                    </FormGroup>
                </Col>
            
                
                <Col md="2" xs="12" xl="2" lg="2">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select Start Date</Label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => {
                                date ? selectedDateOps(date) : selectedDateOps(new Date());
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
              
                            maxDate={selectedEndDate}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select End Date</Label>
                        <DatePicker
                            selected={selectedEndDate}
                            onChange={(date) => {
                                date
                                    ? selectedEndDateOps(date)
                                    : selectedEndDateOps(new Date());
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
              
                            minDate={selectedDate}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <div className="mt-2">
                        <div className="mt-3">
                            <button
                                className="btn btn-primary mt-4 mr-2"
                                onClick={getAttendanceReport}
                            >
                                {props.t("Generate")}
                            </button>
                        </div>
                    </div>
                </Col>



            </Row>
     
            {gotReport && attendanceReport.length > 0 && (
                <Row>
                    <Col md="12" xs="12" xl="12" lg="12" className="mt-1 mb-1">
                        {<Label className="align-middle mt-2">{`Total check in : ${totalCheckIns}`}</Label>}
                        {<Label className="align-middle ml-3 mt-2">{`Total check out : ${totalCheckOuts}`}</Label>}
                        <button
                            className="btn btn-info float-right"
                            onClick={downloadTheReport}
                        >
                            <i className="bx bxs-download mr-1" />
                            {props.t("Download")}
                        </button>
                    </Col>
                </Row>
            )}
            {gotReport && (
                <Row>
                    {attendanceReport.length > 0 && (
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap">
                                <thead>
                                    <tr>
                                        <th onClick={arrangeBasedOnKids}>{props.t("Name")}</th>
                                        <th>{props.t("Check-in")}</th>
                                        <th>{props.t("Check-out")}</th>
                                        <th>{props.t("Total Hrs")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {attendanceReport.map((val, key) => (
                                        <tr key={key}>
                                            <td>{val.name}</td>
                                            <td>{val.checkInTime}</td>
                                            <td>{val.checkOutTime}</td>
                                            <td>{val.totalHrs}</td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {attendanceReport.length === 0 && (
                        <Col>
                            <h3 className="text-center text-info mt-3">
                                {props.t("No Report Found For The Filter")}
                            </h3>
                        </Col>
                    )}
                </Row>
            )}
            {loading && !gotReport &&
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {"Preparing Report"}</Label>
                        </div>
                    </Col>
                </Row>
            }
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { schools, selectedSchool } = state.School;
    const { teachers } = state.Teacher;
    const { classrooms } = state.Classroom;
    const { kids } = state.Kid;
    
    const {
        teacherAttendanceList,
        gotAttendance,
        kidAttendanceList,
        daycareAttendance,
    } = state.Attendance;
    return {
        schools,
        teachers,
        classrooms,
        kids,
        teacherAttendanceList,
        gotAttendance,
        kidAttendanceList,
        daycareAttendance,
    };
};
export default withNamespaces()(
  withRouter(
      connect(mapStatetoProps, {
      getTeachersForSchool,
      getClassroomsForSchoolId,
      getAllKids,
      getAttendanceForTeacherIds,
      getAttendanceForKidIds,
      getDaycareAttendanceForKidIds,
    })(AttendanceReport)
  )
);
