
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from 'classnames';
import * as Constants from '../../Constents';
import { Link } from "react-router-dom";

const Topbar = (props) => {

    return (
        <Row className="navbar-header p-0">
            <div className="d-flex" >
                {
                    (props.backButton) ?
                        <div className="navbar-header p-0">

                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { props.onClickBackButton(); }}>
                                <ul className="verti-timeline list-unstyled">
                                    <div >
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>


                            <h4 >{props.title}</h4>
                        </div>
                        :
                        <h4 className="ml-2">{props.title}</h4>
                }

            </div>
            {
                !props.hideButton && <div className="d-flex" >
                    <Form className=" mt-sm-0 mr-5 float-sm-right form-inline">
                        <Button
                            onClick={() => {
                                props.printButtonClicked()
                            }}
                            className="btn btn-success waves-effect waves-light mr-2"
                        >
                            <i className="fa fa-print mr-2"></i>Print
                        </Button>
                    </Form>
                </div>
            }
        </Row>
    );
}
export default Topbar;
