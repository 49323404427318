import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil"

const initialState = {
  cameras: undefined,
  camera: undefined,
  resultCameraAfterSave: undefined,
  cameraAccessSaveSuccess: false,
  cameraAccessObjects: undefined,
  cameraLiveStreams:undefined,
};

const Camera = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_CAMERA:
      if (state.cameras != undefined) {
        var cameras = [...state.cameras];
        var temp = state.cameras.filter(
          (camera) => camera.id === action.payload.camera.id
        );

        if (temp.length > 0) {
          if (action.payload.camera.attributes.deleted) {
            var others = state.cameras.filter(
              (camera) => camera.id !== action.payload.camera.id
            );
            state = { ...state, cameras: others };
          } else {
            var index = cameras.indexOf(temp[0]);
            cameras[index] = action.payload.camera;
            state = { ...state, cameras: cameras };
          }
        } else {
          cameras.push(action.payload.camera);
          state = { ...state, cameras: cameras };
        }
      } else {
        state = { ...state, cameras: [action.payload.camera] };
      }

      break;

    case actions.UPDATE_CAMERAS:
      if (state.cameras != undefined) {
        var cameras = [...state.cameras];
        if (cameras.length > 0) {
          for (const latest in action.payload.cameras) {
            var temp = cameras.filter(
              (camera) => camera.id === action.payload.cameras[latest].id
            );

            if (temp.length > 0) {
              if (action.payload.cameras[latest].attributes.deleted) {
                var others = cameras.filter(
                  (camera) => camera.id !== action.payload.cameras[latest].id
                );

                cameras = others;
              } else {
                var index = cameras.lastIndexOf(temp[0]);
                cameras[index] = action.payload.cameras[latest];
              }
            } else {
              if (action.payload.cameras[latest].attributes.deleted !== true) {
                cameras.push(action.payload.cameras[latest]);
              }
            }
          }
          state = { ...state, cameras: cameras };
        } else {
          var i = 0;
          var camerasToSave = [];
          for (i = 0; i < action.payload.cameras.length; i++) {
            if (action.payload.cameras[i].attributes.deleted !== true) {
              camerasToSave.push(action.payload.cameras[i]);
            }
          }

          state = { ...state, cameras: camerasToSave };
        }
      } else {
        var i = 0;
        var camerasToSave = [];
        for (i = 0; i < action.payload.cameras.length; i++) {
          if (action.payload.cameras[i].attributes.deleted !== true) {
            camerasToSave.push(action.payload.cameras[i]);
          }
        }

        state = { ...state, cameras: camerasToSave };
      }

      break;
    case actions.UPDATE_CAMERA_LIVESTREAMS_FOR_SCHOOL_ID:
      let oldObjects = state.cameraLiveStreams?[...state.cameraLiveStreams] : [];
      var updatedList = ReduxUtil.updateParseObjects(oldObjects, action.payload.cameraLiveStreams, "isDeleted")
      state = { ...state, cameraLiveStreams: updatedList };
      break;
    

    case actions.SINGLE_CAMERA_UPDATE:
      state = { ...state, camera: action.payload.camera };
      break;
    case actions.RESULT_CAMERA_AFTER_SAVE:
      state = { ...state, resultCameraAfterSave: action.payload.camera };

      break;

    case actions.RESULT_CAMERA_ACCESS_AFTER_SAVE:
      state = { ...state, cameraAccessSaveSuccess: action.payload.response };
      break;

    case actions.UPDATE_CAMERA_ACCESS_OBJECTS:
      state = {
        ...state,
        cameraAccessObjects: action.payload.cameraAccessObjects,
      };

      break;
    case actions.CLEAR_PROPS_CAMERA_AND_ACCESS:
      state = {
        ...state,
        cameraAccessObjects: undefined,camera:undefined
      };

      break;

      

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Camera;
