import React from 'react';
import { Row, Col, Form, Input, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from 'classnames';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import * as Constants from "../../Constents";

const Breadcrumb = (props) => {
    return (

        <Row className="mb-3">
            <Col lg={3} sm={6}>
                <div className="mt-2">
                    <h4 className="mb-0 font-size-18">{props.t(props.title.toUpperCase())} </h4>


                </div>
            </Col>

            <Col lg={9} sm={6}>

                    <Form className='mt-sm-0 float-sm-end d-sm-flex align-items-center'>
                        <Col lg={4} sm={4}>
                            <div className="search-box mr-2 float-right ">
                                <div className="position-relative">
                                    <Input type="text" className="form-control border-0" value={props.searchValue} placeholder="Search..." onChange={(e) => props.handleSearch(e.target.value)} />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                        <Col lg={3} sm={3}>
                            <div className='justify-content-end m-1'>
                                {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <select className="form-control mr-2"
                                    // style={{ width: '10rem' }}
                                    value={props.activeSchool}
                                    onChange={(e) => {
                                        props.showforOps(e.target.value);
                                    }}
                                >
                                    <option className="text-primary" value="All" key={0} >All</option>
                                    {props.classrooms.map((val, key) => (<option key={key} className={val.attributes.classroomType === 2 ? 'text-danger' : 'text-primary'} value={val.id} >{val.attributes.Name}</option>))}
                                </select>}</div>
                        </Col>
                        <Col lg={3} sm={3}>
                            <div className='justify-content-end '>
                                <button
                                    className="btn btn-primary waves-effect waves-light mr-2"
                                    onClick={() => {
                                        if (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 ||
                                            props.selectedSchool && props.selectedSchool.attributes.schoolType === 11) {
                                            props.history.push("/add-import/" + props.schoolId);
                                        } else {
                                            props.history.push("/student-import/" + props.schoolId);
                                        }

                                    }}
                                >
                                    {props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 ? "Add participant" : "Import Students"}
                                </button>
                            </div>
                        </Col>
                        <Col lg={2} sm={2}>
                            <Nav className="product-view-nav justify-content-end mt-3 mt-sm-0" pills>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: props.displayType === Constants.DISPLAY_TYPE_GRID })}
                                        onClick={() => { props.toggleDisplay(Constants.DISPLAY_TYPE_GRID) }}
                                    >
                                        <i className="bx bx-grid-alt"></i>
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({ active: props.displayType === Constants.DISPLAY_TYPE_LIST })}
                                        onClick={() => { props.toggleDisplay(Constants.DISPLAY_TYPE_LIST) }}
                                    >
                                        <i className="bx bx-list-ul"></i>
                                    </NavLink>
                                </NavItem>
                            </Nav>
                        </Col>
                    </Form>

            </Col>
        </Row>


        // <Row>
        //     <Col xs="12">
        //         <div className="page-title-box d-flex align-items-center justify-content-between">
        //             <h4 className="mb-0 font-size-18">{props.title}</h4>
        //             <div className="page-title-right">
        //                 <ol className="breadcrumb m-0">
        //                     <BreadcrumbItem>
        //                         <Link to="#">{props.title}</Link>
        //                     </BreadcrumbItem>
        //                     <BreadcrumbItem active>
        //                         <Link to="#">{props.breadcrumbItem}</Link>
        //                     </BreadcrumbItem>
        //                 </ol>
        //             </div>
        //         </div>
        //     </Col>
        // </Row>
    );
}

export default withRouter(withNamespaces()(Breadcrumb));