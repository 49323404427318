
import React from 'react';
import { Row, Col, Form, Button, UncontrolledTooltip, Media } from "reactstrap";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

const Topbar = (props) => {


    // return(
    //     <Row className="mb-3">
    //         <Col xl="4" sm="6">
    //             <div className="mt-2">
    //                 <h4 className="mb-0 font-size-18">
    //                     <Row>
    //                         {props.backButton && <Col sm="1" xl="1" md="1" style={{marginTop : '-0.5rem'}}>
    //                             {props.backButton && <i onClick={() => { props.onClickBackButton()}} className="bx bx-chevron-left h1 text-justify text-primary"></i>}
    //                         </Col>}
    //                         <Col sm="11" xl="11" md="11">
    //                             <span>{props.t(props.title.toUpperCase()) }</span>
    //                         </Col>
    //                     </Row>
    //                 </h4>
    //             </div>
    //         </Col>
    //         {!props.hideButton && <Col lg="8" sm="6">
    //             <button onClick={() => props.onClickAdd()} className="btn btn-success float-right"><i className="mdi mdi-plus mr-1"></i>{props.t(props.buttonName)}</button>
    //         </Col>}
    //     </Row>
    // )

    return (

        // <Row className="navbar-header p-0">
        //     <div className="d-flex" >
        //         {
        //             (props.backButton) ?
        //                 <div className="d-flex">

        //                     <div 
        //                         style={{ cursor: "pointer" }}
        //                         onClick={() => { props.onClickBackButton(); }}>
        //                         <ul className="verti-timeline list-unstyled">
        //                             <div >
        //                                 <i className="bx bx-chevron-left h1 text-primary"></i>
        //                             </div>
        //                         </ul>
        //                     </div>


        //                     <h4 >{props.title}</h4>
        //                 </div>
        //                 :
        //                 <h4 className="ml-2">{props.title}</h4>
        //         }

        //     </div>
        //     {
        //         !props.hideButton && <div className="d-flex" >
        //             <Form className=" mt-sm-0 mr-5 float-sm-right form-inline">
        //                 <Button
        //                     onClick={() => {
        //                         props.printButtonClicked()
        //                     }}
        //                     className="btn btn-success waves-effect waves-light mr-2"
        //                 >
        //                     <i className="fa fa-print mr-2"></i>Print
        //                 </Button>
        //             </Form>
        //         </div>
        //     }
        // </Row>



        <Row className="d-flex align-items-start" >
            <Col >
                <div>
                    {
                        (props.backButton) ?
                            <div className="d-flex align-items-start">

                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { props.onClickBackButton(); }}>
                                    <ul className="list-unstyled">
                                        <div >
                                            <i className="bx bx-chevron-left h1 text-primary"></i>
                                        </div>
                                    </ul>
                                </div>


                                <h4 className="mt-2">{props.title}</h4>
                            </div>
                            :
                            <h4 className="m-2">{props.title}</h4>
                    }

                </div>
            </Col>
            <Col>
                {!props.hideButton &&
                    <div className="d-flex justify-content-end" >
                        <Button
                            onClick={() => {
                                props.printButtonClicked()
                            }}
                            className="btn btn-success waves-effect waves-light mr-2"
                        >
                            <i className="fa fa-print mr-2"></i>Print
                        </Button>
                    </div>
                }
            </Col>
        </Row>



    );
}
export default withNamespaces()(Topbar);






{/* <Row>

<Col lg={6} md={6} xs={12}>
    <h4>{props.title.toUpperCase()}</h4>

</Col>
<Col lg={6} md={6} xs={12}>
    <div className="d-flex justify-content-end mb-2">
        {props.showSearchBar && <div className="search-box mr-2 mb-2">
            <div className="position-relative">
                <Input type="text" className="form-control border-0" placeholder="Search..."

                    onChange={(e) => {

                        props.onSearch(e.target.value)
                    }}
                />
                <i className="bx bx-search-alt search-icon"></i>
            </div>
        </div>}
        {props.hideSharebutton === false && <Dropdown
            isOpen={openDropDown}
            toggle={(e) => {
                if (e.target.id == "wa-phoneNumber" ||
                    e.target.id == "wa-newWhatsApp" ||
                    e.target.id == "wa-submitButton") {
                } else {
                    setOpenDropDown(!openDropDown)
                }
            }}
            direction="left"
        >
            <DropdownToggle tag="button" className="btn btn-success mr-2" style={{ marginLeft: '10px'}}>
                <i className="bx bx-share-alt"></i>
            </DropdownToggle>
            <DropdownMenu>
                <DropdownItem style={{ width: '250px' }} onClick={(event) => { copyEnquiryLink(event) }}>Copy link <i className=" bx bx-copy float-right"></i></DropdownItem><div className='dropdown-divider'></div>
                <DropdownItem style={{ width: '250px' }} target="_blank" href={  "/enquiryposter/" + props.schoolId }>Enquiry Poster<i className="bx bx-barcode float-right"></i></DropdownItem><div className='dropdown-divider'></div>
                <DropdownItem style={{ width: '250px' }} onClick={(e) => { handleSendEmail(e) }}>Send link via Email<i className="bx bx-mail-send float-right"></i></DropdownItem><div className='dropdown-divider'></div>
                <DropdownItem style={{ width: '250px' }} onClick={(e) => { handleSendExistingWhatsApp(e) }}>Existing whatsapp<i className="bx bxl-whatsapp float-right"></i></DropdownItem><div className='dropdown-divider'></div>
                <DropdownItem id="wa-newWhatsApp" style={{ width: '250px', margin: '10px' }} onClick={() => { }}>New whatsapp<i className="bx bxl-whatsapp float-right"></i>
                    <Form onSubmit={handleNewWhatsapp} style={{ width: '200px' }}>
                        <FormGroup>
                            <Input
                                id="wa-phoneNumber"
                                style={{ margin: '10px' }}
                                type="tel"
                                placeholder="10 digit Phone Number"
                                className='form-control'

                            /></FormGroup>
                        <Button color="primary" id="wa-submitButton"
                            onClick={(e) => {
                                let ph = document.getElementById('wa-phoneNumber').value
                                if (ph) {
                                    handleNewWhatsapp(e, ph)
                                    setOpenDropDown(!openDropDown)
                                }
                            }}

                            className='btn-sm mb-2 mr-2' >Send</Button>
                    </Form>
                </DropdownItem>
            </DropdownMenu>
        </Dropdown>}
        {props.hideButton === false && <Link to={"/editenquiryinside/" + props.schoolId}>
            <Button
                type="button"
                color="primary"
                className="btn waves-effect waves-light  mb-2" style={{ marginLeft: '10px'}}>
                {props.t('Add Enquiry')}
            </Button>
        </Link>}
    </div>
</Col>
</Row> */}