import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Parse from "parse";
import Moment from "moment";
import {
    Alert,
    Card,
    CardBody,
    CardTitle,
    Col,
    Form,
    FormGroup,
    Input,
    Label,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
    Progress
} from "reactstrap";
import Lightbox from 'react-image-lightbox';
import DocumentViewerModal from '../../Messaging/DocumentViewerModal';
import { getSaleOrdersForOwnerId, editSaleOrder, updateSaleOrders } from '../../../store/actions';


const orderStatus = (props) => {

    const [currentStatus, setCurrentStatus] = useState(0);
    const [statusList, setStatusList] = useState([
        {
            id: 1,
            title: props.orderStatusObject && props.orderStatusObject.attributes && props.orderStatusObject.attributes.status == 0 ? "Order Placed" : "COD Order Placed",
            desciption: undefined,
            buttonStatus: -1,
            buttonText: undefined,
            status: (props.orderStatusObject && props.orderStatusObject.attributes && props.orderStatusObject.attributes.status) ?? 0,
            done: true,

        },
        {
            id: 2,
            title: "Order Accepted",
            desciption: undefined,
            buttonStatus: props.orderStatusObject && props.orderStatusObject.attributes && props.orderStatusObject.attributes.status == 4 ? 4 : 0,
            buttonText: "Modify & Accept",
            status: 2
        },
        {
            id: 3,
            title: "Order Shipped",
            desciption: undefined,
            buttonStatus: 2,
            buttonText: "Shipped",
            status: 3
        },
        {
            id: 4,
            title: props.schoolMatch ? "Order Received" : "Order Delivered",
            desciption: undefined,
            buttonStatus: 3,
            buttonText: props.schoolMatch ? "Received" : "Delivered",
            status: 1
        },

    ]);

    const [currentLog, setCurrentLog] = useState([]);
    const [courierName, setCourierName] = useState();
    const [courierTrackingId, setCourierTrackingId] = useState();
    const [courierNameError, setCourierNameError] = useState(false);
    const [courierTrackingIdError, setCourierTrackingIdError] = useState(false);
    const [courierDocument, setcourierDocument] = useState();
    const [imagePopup, setImagePopup] = useState(false);
    const [photoIndex, setphotoIndex] = useState(0);
    const [getImage, setGetImage] = useState([]);
    const [attachmentUploadProgress, setAttachmentUploadProgress] = useState(0);
    const [editShipingDetails, setEditShipingDetails] = useState(false)
    const [clickedFile, setClickedFile] = useState(false);


    useEffect(() => {
        if (props.orderStatusObject) {
            setCurrentStatus(props.orderStatusObject.attributes.status ?? 0)

            getSaleOrderLog();
        }
    }, [props.orderStatusObject]);


    const reset = () => {
        props.onCloseOrderStatus()
    }

    const getSaleOrderLog = () => {
        var InventoryItem = Parse.Object.extend("InventorySaleOrderLog");
        var query = new Parse.Query(InventoryItem);
        query.equalTo("saleOrderId", props.saleOrderId)
        query.notEqualTo('isDeleted', true);
        query.ascending('createdAt');
        query.find().then(
            (results) => {
                setCurrentLog(results);
                updateStatus(results)
                // console.log(results)
            },
            (error) => {

            }
        );
    }

    const updateStatus = (results) => {
        let sl = [...statusList];

        let nsl = []

        for (let s of sl) {
            if (s.id == 1) {
                s.desciption = "On " + getTimeStringForDate(props.orderStatusObject.attributes.createdAt);

            } else {
                if (results) {
                    for (const log of results) {
                        if (s.buttonStatus == log.attributes.fromStatus) {
                            s.desciption = "On " + getTimeStringForDate(log.attributes.createdAt);
                            s.done = true

                            if (s.id == 3) {
                                setCourierName(log.attributes.courierName);
                                setCourierTrackingId(log.attributes.courierTrackingId);
                                setcourierDocument(log.attributes.courierDocument)
                            }
                        }
                    }
                }
            }
            nsl.push(s);
        }
        setStatusList(nsl)
    }

    const handelOnClick = (item) => {
        setCurrentStatus(item.status);
        let tempObject = props.orderStatusObject;
        tempObject.set("status", item.status);
        tempObject.save().then(
            (result) => {
                result.pin()
                // console.log(result)
                props.updateSaleOrders([result])
                handleSave(item)

            },
            (error) => {
                console.log(error);
            }
        );
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }

    function handleSave(status) {
        var InventorySaleOrderLog = Parse.Object.extend("InventorySaleOrderLog");
        var saleOrderLog = new InventorySaleOrderLog();

        saleOrderLog.set("saleOrderId", props.saleOrderId);
        saleOrderLog.set("logTitle", status.title);
        saleOrderLog.set("fromStatus", status.buttonStatus);
        saleOrderLog.set("toStatus", status.status);
        saleOrderLog.set("createdBy", Parse.User.current().attributes.teacherId);

        if (status.id == 3) {
            saleOrderLog.set("courierName", courierName);
            saleOrderLog.set("courierTrackingId", courierTrackingId);
            saleOrderLog.set("courierDocument", courierDocument);
        }

        saleOrderLog.save().then(
            (result) => {
                let cl = [...currentLog];
                cl.push(result);
                updateStatus(cl)
                result.pin()
                // console.log(result)

            },
            (error) => {
                console.log(error);
            }
        );
    }

    const handleEditShippingDetailsSave = () => {

        let saleOrderLog = currentLog[1];

        saleOrderLog.set("courierName", courierName);
        saleOrderLog.set("courierTrackingId", courierTrackingId);
        saleOrderLog.set("courierDocument", courierDocument);
        saleOrderLog.save().then(
            (result) => {
                let cl = [...currentLog];
                cl[1] = result;
                updateStatus(cl)
                result.pin()
                // console.log(result)

            },
            (error) => {
                console.log(error);
            }
        );
    }


    function getFileTypeImage(fileExtension) {
        if (fileExtension === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtension === "ppt" || fileExtension === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "doc" || fileExtension === "docx") {
            return "bx bxs-file-doc";
        }

        return "bx bxs-file-blank";
    }


    const showUploadProgress = () => {
        if (courierDocument && attachmentUploadProgress >= 1 && attachmentUploadProgress < 100) {
            return true
        } else if (courierDocument && !courierDocument._url) {
            return true
        } else {
            return false
        }
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
        };
    }

    function handleFileUploadChange(e, files) {
        setcourierDocument(undefined)
        setAttachmentUploadProgress(1)
        let fileExtension = e.target.files[0].name.split(".").pop();

        let tempFile = {
            actualFileName: e.target.files[0].name,
            fileExtension: fileExtension.toLowerCase(),
            fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
            file: e.target.files[0],
        };

        var name = tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + tempFile.fileExtension;
        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            setAttachmentUploadProgress(value * 100)
                        }
                    },
                })
                .then(
                    (result) => {

                        setcourierDocument(result)
                        setAttachmentUploadProgress(false)
                    },
                    (error) => {
                        console.log(error)
                    }
                );
        });
        setcourierDocument(tempFile.actualFileName)
    }

    const inputStyle = {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    };

    return (
        <React.Fragment>
            <DocumentViewerModal
                clickedFile={clickedFile}
                closeModal={() => {
                    setClickedFile()
                }}
            ></DocumentViewerModal>
            <Modal
                size="md"
                isOpen={props.showOrderStatus}
                toggle={() => {
                }}
            >
                <ModalHeader
                    toggle={() => {
                        reset();
                    }}
                >
                    {" "}
                    Order Status
                </ModalHeader>
                <ModalBody>
                    {console.log("statusList", statusList)}
                    {statusList.map((item, key) =>
                        <div className="" key={key}>
                            <ul className="verti-timeline list-unstyled mt-2">
                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className={"bx bx-right-arrow-circle "}></i>
                                    </div>
                                    <div className="media">
                                        <div className="media-body">
                                            <div>
                                                <h5 className="mb-0">{item.title}{" "}{item.done && <i className="text-success bx bx-check-circle "></i>}</h5>
                                                <p className="text-muted mb-0">
                                                    {
                                                        item.desciption ?? "Pending"
                                                    }
                                                </p>

                                                {(item.id == 3 && currentLog && currentLog.length >= 2 && !editShipingDetails) &&
                                                    <Card >
                                                        <CardBody>
                                                            <Row>
                                                                <Col>
                                                                    {<p className="align-middle mt-2 ml-2 row gy-1 mb-0">{`Courier Name : ${courierName}`}</p>}
                                                                    {<p className="align-middle mt-2 ml-2 row gy-1 mb-0">{`Tracking Id : ${courierTrackingId}`}</p>}
                                                                    {courierDocument && <Label className="align-middle fa fa-paperclip  text-primary mt-2 ml-2 row gy-1 mb-0" style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setClickedFile(courierDocument)
                                                                        }}>
                                                                        <u>Attachment</u>
                                                                    </Label>}
                                                                </Col>
                                                                <Col className="float-right">
                                                                    {!props.schoolMatch && <button
                                                                        className="btn btn-sm btn-primary float-right"
                                                                        onClick={() => {
                                                                            setEditShipingDetails(true)
                                                                        }}
                                                                    >
                                                                        Edit
                                                                    </button>}
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Card>
                                                }
                                                {((item.buttonStatus == currentStatus && currentLog.length < 2) || editShipingDetails) && !props.schoolMatch && item.id == 3 &&
                                                    <div>
                                                        <Form>
                                                            <Col lg={8}>
                                                                <FormGroup className="mt-2 mb-2 p-1">
                                                                    <Label>Courier Name:</Label>
                                                                    <Input className="form-control"
                                                                        type="text"
                                                                        id="displayName"
                                                                        placeholder="Courier Name"
                                                                        required
                                                                        value={courierName}
                                                                        onChange={(e) => {
                                                                            setCourierName(e.target.value)
                                                                            setCourierNameError(false);
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                                {courierNameError && <small className="text-danger ml-2">Please Enter Courier Name</small>}
                                                            </Col>
                                                            <Col lg={8}>
                                                                <FormGroup className="mt-2 mb-2 p-1">
                                                                    <Label>Tracking Id :</Label>
                                                                    <Input className="form-control"
                                                                        type="text"
                                                                        id="displayName"
                                                                        placeholder="Tracking Id"
                                                                        required
                                                                        value={courierTrackingId}
                                                                        onChange={(e) => {
                                                                            setCourierTrackingId(e.target.value)
                                                                            setCourierTrackingIdError(false);
                                                                        }}
                                                                    />
                                                                </FormGroup>
                                                                {courierTrackingIdError && <small className="text-danger ml-2">Please Enter Courier Tracking Id</small>}
                                                            </Col>
                                                            <Col>
                                                                <Row>
                                                                    <Col>
                                                                        <div className="popup-gallery">
                                                                            <div className="img-fluid float-left">
                                                                                <img
                                                                                    src={courierDocument ? courierDocument._url : ""}
                                                                                    onClick={() => {
                                                                                        setImagePopup(true);
                                                                                        setphotoIndex(key)
                                                                                    }}
                                                                                    alt=""
                                                                                    width="120"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                    {/* <Col>
                                                                        <div
                                                                            className="flex-fill text-center"
                                                                            style={{ cursor: "pointer", float: "right" }}
                                                                            onClick={() => {
                                                                                setDeleteObject(file)
                                                                                setShowDelete(true);
                                                                            }}
                                                                        >
                                                                            <i className="bx bxs-trash  text-danger"></i>
                                                                        </div>
                                                                    </Col> */}
                                                                </Row>
                                                                <FormGroup className="mt-2 mb-2 p-1">
                                                                    {editShipingDetails ? <Label
                                                                        htmlFor={"input-file-expense"}
                                                                        color="primary"
                                                                        className="btn-sm"
                                                                    >
                                                                        <u className="text-primary">{courierDocument ? "Replace" : "Add attachment"}</u>
                                                                        <input
                                                                            id={"input-file-expense"}
                                                                            style={inputStyle}
                                                                            type="file"
                                                                            onChange={(e) => {
                                                                                handleFileUploadChange(e);
                                                                            }}
                                                                            accept=".doc,.docx,.pdf,.xlsx,image/*"
                                                                        />
                                                                    </Label>
                                                                        :
                                                                        <Label
                                                                            htmlFor={"input-file-expense"}
                                                                            color="primary"
                                                                            className="btn btn-primary btn-sm"
                                                                        >
                                                                            Attachment
                                                                            <input
                                                                                id={"input-file-expense"}
                                                                                style={inputStyle}
                                                                                type="file"
                                                                                onChange={(e) => {
                                                                                    handleFileUploadChange(e);
                                                                                }}
                                                                                accept=".doc,.docx,.pdf,.xlsx,image/*"
                                                                            />
                                                                        </Label>}
                                                                </FormGroup>
                                                                {
                                                                    showUploadProgress() && (
                                                                        <div className="d-flex">
                                                                            <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                                                                <div className="border p-3">
                                                                                    <Row>
                                                                                        <div
                                                                                            className="avatar ml-3"
                                                                                            style={{ width: "5rem", height: "5rem" }}
                                                                                        >
                                                                                            <span className="avatar-title bg-white ml-0 text-primary h1">
                                                                                                <i
                                                                                                    className={getFileTypeImage(courierDocument)}
                                                                                                ></i>
                                                                                            </span>
                                                                                        </div>
                                                                                        <div
                                                                                            className="float-right ml-4"
                                                                                            style={{ width: "200px" }}
                                                                                        >
                                                                                            <div className="text-muted font-weight-bold">
                                                                                                {courierDocument}
                                                                                            </div>
                                                                                            <div className=" mt-3">
                                                                                                <Progress
                                                                                                    color="primary"
                                                                                                    value={attachmentUploadProgress}
                                                                                                ></Progress>
                                                                                            </div>
                                                                                        </div>
                                                                                    </Row>
                                                                                </div>
                                                                            </Col>
                                                                        </div>
                                                                    )}
                                                            </Col>
                                                        </Form>
                                                        {editShipingDetails && (
                                                            <button
                                                                className="btn btn-danger"
                                                                onClick={() => {
                                                                    let log = currentLog[1];
                                                                    if (log) {
                                                                        setCourierName(log.attributes.courierName);
                                                                        setCourierTrackingId(log.attributes.courierTrackingId);
                                                                        setcourierDocument(log.attributes.courierDocument);
                                                                    }
                                                                    setEditShipingDetails(false);
                                                                }}
                                                            >
                                                                Close
                                                            </button>
                                                        )}

                                                        {editShipingDetails && (
                                                            <button
                                                                className="btn btn-primary mb-2 mt-2"
                                                                onClick={() => {
                                                                    handleEditShippingDetailsSave();
                                                                }}
                                                                style={{ marginLeft: '10px' }} // Add left margin to create space between buttons
                                                            >
                                                                Save
                                                            </button>
                                                        )}
                                                    </div>
                                                }
                                                {console.log("item", item)}
                                                {item.buttonStatus == currentStatus && !props.schoolMatch && (
                                                    <button
                                                        onClick={() => {
                                                            if (item.id == 3) {
                                                                let next = true;
                                                                if (!courierName) {
                                                                    setCourierNameError(true);
                                                                    next = false;
                                                                }
                                                                if (!courierTrackingId) {
                                                                    setCourierTrackingIdError(true);
                                                                    next = false;
                                                                }

                                                                if (next) {
                                                                    handelOnClick(item)
                                                                }
                                                            } else if (item.id == 2) {
                                                                props.history.push(`/franchise-sale-order-details/${props.franchiseMatch}/${props.saleOrderId}`);
                                                            } else {
                                                                handelOnClick(item)
                                                            }
                                                        }}
                                                        type="button"
                                                        className="btn btn-primary waves-effect waves-light " >
                                                        <i className="bx bx-arrow-to-right font-size-16 align-middle"></i>
                                                        {item.buttonText}
                                                    </button>
                                                )}
                                                {props.schoolMatch && item.id == 4 &&
                                                    currentStatus == 3
                                                    && (
                                                        <button
                                                            onClick={() => {
                                                                handelOnClick(item)
                                                            }}
                                                            type="button"
                                                            className="btn btn-primary waves-effect waves-light mb-2 " >
                                                            <i className="bx bx-arrow-to-right font-size-16 align-middle"></i>
                                                            {item.buttonText}
                                                        </button>
                                                    )}
                                            </div>
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </div>)}

                </ModalBody>
            </Modal>

        </React.Fragment >
    );
};
const mapStatetoProps = (state) => {
    const { } = state.InventorySaleOrder;
    return {};
};
export default connect(mapStatetoProps, { updateSaleOrders })(withRouter(withNamespaces()(orderStatus)))
