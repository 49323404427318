export const UPDATE_CLASSROOM = "UPDATE_CLASSROOM";
export const DELETE_CLASSROOM = "DELETE_CLASSROOM";

export const UPDATE_CLASSROOMS = 'UPDATE_CLASSROOMS';
export const GET_CLASSROOMS = 'GET_CLASSROOMS';
export const GET_CLASSROOM = 'GET_CLASSROOM';

export const CLASSROOM_SUCCESS = "CLASSROOM_SUCCESS";
export const CLASSROOM_ERROR = "CLASSROOM_ERROR";
export const GET_CLASSROOM_FOR_SCHOOLIDS = "GET_CLASSROOM_FOR_SCHOOLIDS";
export const CREATE_NEW_CLASS = "CREATE_NEW_CLASS";
export const UPDATE_A_CLASSROOM_OBJ = "UPDATE_A_CLASSROOM_OBJ";
export const SET_DELETED_CLASS = "SET_DELETED_CLASS";
export const CHECK_FOR_DAYCARE = "CHECK_FOR_DAYCARE";