import * as actions from './actionTypes';

export const getFranchise = (id,fromServer) => {
    return {
        type: actions.GET_FRANCHISE,
        payload: { id ,fromServer}
    }
}

export const updateFranchise = (franchise) => {
    return {
        type: actions.UPDATE_FRANCHISE,
        payload: { franchise }
    }
}