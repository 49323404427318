import React from 'react';
import { Row, Col, Form, Button, } from "reactstrap";
import { Link } from "react-router-dom";


const Topbar = (props) => {
    return (
        <Row className='d-flex align-items-start'>
            <Col className="d-flex align-items-start">
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => { window.history.back() }}>
                    <ul className=" list-unstyled">
                        <div className=''>
                            <i className="bx bx-chevron-left h1 text-primary"></i>
                        </div>
                    </ul>
                </div>
                <div className=' m-2'>
                    <h4 >{props.title}</h4>
                </div>
            </Col>
            <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                    {props.hideButton === false && <Link to={props.link}>
                        <div className="d-flex" >
                            <Button
                                type="button"
                                color="primary"
                                className="btn waves-effect waves-light  mb-2"
                            >
                                {props.buttonName}
                            </Button>
                        </div>
                    </Link>}
                </div>
            </Col>
        </Row>
    );
}

export default Topbar