import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
  Badge, Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
import {
  getTeachersForSchool,
  getClassroomsForSchoolId,
  getAllKidsForGroup, saveGroup,
  saveGroupMaps,
  getGroupMapsForGroupId,
  getGroupForId,
  setSelecetdSchool,
} from '../../store/actions';

import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll';

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
//Import Images
import avatar2 from "../../assets/images/users/user.png";
import toastr from 'toastr'



import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from 'parse';
import { template } from "lodash";

const AddGroups = (props) => {




  const match = matchPath(props.history.location.pathname, {
    path: "/addGroup/:schoolId",
    exact: true,
    strict: false,
  });
  const matchEditGroup = matchPath(props.history.location.pathname, {
    path: "/editgroup/:schoolId/:groupId",
    exact: true,
    strict: false,
  });

  var parameter = null
  var groupId = null

  if (match) {
    parameter = match && match.params.schoolId;

  } else if (matchEditGroup) {
    parameter = matchEditGroup && matchEditGroup.params.schoolId;
    groupId = matchEditGroup && matchEditGroup.params.groupId;
  }

  //SchoolId is parameter
  const [classroomList, setClassroomList] = useState([])
  const [saveSuccess, setSaveSuccess] = useState(false)
  const [selectedTeachers, setSelectedTeachers] = useState([])
  const [tempSelectedTeachers, setTempSelectedTeachers] = useState([])
  const [selectedKids, setSelectedKids] = useState([])
  const [searchBarString, setSeachBarString] = useState("")
  const [selectedClassroomFilter, setSelectedClassroomFilter] = useState(undefined)
  const [showEditTeacherTeamPopup, setShowEditTeacherTeamPopup] = useState(false)
  const [newGroupName, setNewGroupName] = useState("")
  const [isLoading, setIsLoading] = useState(true)
  const [initialSelctedKids, setInitialSelctedKids] = useState([])

  useEffect(() => {

    var schoolId = parameter;

    props.getTeachersForSchool(schoolId);
    props.getClassroomsForSchoolId(schoolId)
    props.getAllKidsForGroup(schoolId);

    if (matchEditGroup && parameter && groupId) {
      props.getGroupForId(groupId)
    }
    else if (match) {
      setIsLoading(false)

    }

    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }



  }, [parameter, groupId]);

  useEffect(() => {

    if (props.singleGroupHolder) {
      props.getGroupMapsForGroupId(props.singleGroupHolder.id);
      setNewGroupName(props.singleGroupHolder.attributes.name)
    }


  }, [props.singleGroupHolder])


  useEffect(() => {
    if (props.resultGroup) {
      if (matchEditGroup) {
        handleEditSaveGroupMaps(props.resultGroup)

      } else {

        handleSaveGroupMaps(props.resultGroup)

      }
    }
  }, [props.resultGroup]);


  useEffect(() => {
    if (props.resultGroupMapsAfterSave) {
      //console.log("resultGroupMaps after save is comign")
      setSaveSuccess(true)
    }
  }, [props.resultGroupMapsAfterSave]);

  useEffect(() => {

    if (saveSuccess === true) {
      //console.log("Coming here to window.location")
      window.location = "/groupdetail/" + parameter;
      //window.history.back() 
    }

  }, [saveSuccess])



  useEffect(() => {
    if (props.groupmaps && props.groupmaps.length > 0 && props.teachers && props.teachers.length > 0 && matchEditGroup && props.kids && props.kids.length > 0) {
      var i = 0
      var tempSelectedKidList = []
      var tempSelectedTeachersList = []

      for (i = 0; i < props.groupmaps.length; i++) {
        var userId = props.groupmaps[i].attributes.userId
        var userType = props.groupmaps[i].attributes.userType
        if (userId) {
          if (userType && userType === 2) {

            var j = 0
            for (j = 0; j < props.teachers.length; j++) {
              if (props.teachers[j].id === userId) {
                tempSelectedTeachersList.push(props.teachers[j])
              }
            }

          } else if (userType && userType === 1) {
            var k = 0
            for (k = 0; k < props.kids.length; k++) {
              var tempObject = props.kids[k]
              if (tempObject.id === userId) {
                tempSelectedKidList.push(tempObject)
              }
            }
          }
        }
      }
      setSelectedTeachers(tempSelectedTeachersList)
      setSelectedKids(tempSelectedKidList);
      setInitialSelctedKids(tempSelectedKidList);
      if (isLoading === true) {
        setIsLoading(false)
      }

    }
  }, [props.groupmaps, props.teachers, props.kids]);

  useEffect(() => {

    if (props.classrooms && props.classrooms.length > 0) {
      var tempArray = [...props.classrooms]
      const ClassroomObject = Parse.Object.extend("ClassRoom");
      var tempClassroomObject = new ClassroomObject();
      tempClassroomObject.id = "allClassrooms"
      tempClassroomObject.set("Name", "All Classrooms")
      tempArray.unshift(tempClassroomObject)
      setClassroomList(tempArray)
      setSelectedClassroomFilter(tempClassroomObject)
    }

  }, [props.classrooms]);

  const handleEditTeacherTeam = () => {
    var tempArray = [...selectedTeachers]
    setTempSelectedTeachers(tempArray)
    setShowEditTeacherTeamPopup(true)
  }

  const handleEditTeacherTeamClicks = (value) => {
    var selectedList = [...selectedTeachers]
    if (!selectedList.includes(value)) {
      selectedList.push(value)
    } else {
      removeElement(selectedList, value)
    }
    setSelectedTeachers(selectedList)
  }

  const handleCancelButtonInAddTeacher = () => {

    var previousSelectedTeachers = [...tempSelectedTeachers]
    setSelectedTeachers(previousSelectedTeachers)

  }

  //// Utilities functions 
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const handleCheckboxChange = (kObject) => {
    console.log(kObject.id)
    if (kObject.id === "pickAll") {
      handleSelectAll()
    } else
      if (selectedKids.includes(kObject) == false) {
        var tempSelectedList = [...selectedKids]
        tempSelectedList.push(kObject)
        setSelectedKids(tempSelectedList)
      } else {
        handleCheckboxChangeForSelected(kObject)
      }
  }
  const handleCheckboxChangeForSelected = (kObject) => {
    var tempSelectedList = [...selectedKids]
    removeElement(tempSelectedList, kObject)
    setSelectedKids(tempSelectedList)
  }

  const handleClassroomFilterChange = (value) => {
    var classrooms = classroomList.filter(
      (classroom) => classroom.id === value
    );

    if (classrooms.length > 0) {
      setSelectedClassroomFilter(classrooms[0])
    }
  }

  const getAllKidsFilteredList = () => {

    var listToReturn = []
    if (selectedClassroomFilter != undefined) {

      if (selectedClassroomFilter.id === "allClassrooms") {
        listToReturn = [...props.kids]
      } else {
        var i = 0
        for (i = 0; i < props.kids.length; i++) {

          if (props.kids[i].attributes.ClassRoomID === selectedClassroomFilter.id) {

            listToReturn.push(props.kids[i])

          }
        }
      }
    }


    if (searchBarString === "") {
    } else {
      listToReturn = listToReturn.filter(object =>
        object.attributes.Name.toLowerCase().includes(searchBarString.toLowerCase())
      )
    }

    // if (selectedKids.length > 0) {
    //   listToReturn = listToReturn.filter(object =>
    //     !selectedKids.includes(object))
    // }



    if (listToReturn != null && listToReturn.length > 0 && listToReturn[0].id !== "pickAll") {
      const KidObjectParse = Parse.Object.extend("Kid");
      var kidObjectParse = new KidObjectParse();
      kidObjectParse.id = "pickAll";
      kidObjectParse.set("Name", "Pick All");
      listToReturn.unshift(kidObjectParse);
    }
    return (listToReturn)
  }

  function allPick() {

    let tempList = [...getAllKidsFilteredList()]
    if (tempList && tempList.length > 0) {
      for (const kid of tempList) {
        if (!selectedKids.includes(kid) && kid.id != "pickAll") {
          return false

        }
      }
    }

    return true
  }

  function handleSelectAll() {
    let tempList = [...getAllKidsFilteredList()]

    if (allPick()) {
      let update = selectedKids.filter((kid) => {
        return !tempList.includes(kid)
      })
      setSelectedKids(update)
    }
    else {
      setSelectedKids(tempList)
      let oldList = [...selectedKids]

      for (const kobj of tempList) {

        if (!oldList.includes(kobj) && kobj.id != "pickAll") {
          oldList.push(kobj)
        }

      }
      setSelectedKids(oldList)

    }

  }

  const handleSearchBarTextChange = (value) => {
    setSeachBarString(value)
  }

  const handleSaveButtonPressed = () => {

    if (newGroupName === "") {
      toastr.info('Please fill group name !!', { timeOut: 2000 })
      return
    }

    if (selectedTeachers.length === 0) {
      toastr.info('Please select atleast one teacher for group !!', { timeOut: 2000 })
      return
    }

    const GroupObject = Parse.Object.extend("Group");
    var groupObject = new GroupObject();

    groupObject.set("schoolId", parameter)
    groupObject.set("ownerId", Parse.User.current().id)
    groupObject.set("name", newGroupName)
    setIsLoading(true)
    props.saveGroup(groupObject)
  }

  const handleSaveGroupMaps = (resultGroup) => {

    const GroupMapObject = Parse.Object.extend("GroupMap");

    var groupMapObjectList = []
    var i = 0
    for (i = 0; i < selectedTeachers.length; i++) {
      var groupMapObject = new GroupMapObject();
      groupMapObject.set("groupId", resultGroup.id)
      groupMapObject.set("userId", selectedTeachers[i].id)
      groupMapObject.set("userType", 2)
      groupMapObjectList.push(groupMapObject)
    }
    var j = 0
    for (j = 0; j < selectedKids.length; j++) {
      var groupMapObject = new GroupMapObject();
      groupMapObject.set("groupId", resultGroup.id)
      groupMapObject.set("userId", selectedKids[j].id)
      groupMapObject.set("userType", 1)
      groupMapObjectList.push(groupMapObject)
    }

    if (groupMapObjectList.length > 0) {
      props.saveGroupMaps(groupMapObjectList)
    }
  }


  const handleEditSaveButtonPressed = () => {

    if (newGroupName === "") {
      toastr.info('Please fill group name !!', { timeOut: 2000 })
      return
    }

    // if (selectedTeachers.length === 0) {
    //   toastr.info('Please select atleast one teacher for group !!', { timeOut: 2000 })
    //   return
    // }

    if (props.singleGroupHolder) {

      var groupObject = props.singleGroupHolder
      groupObject.set("name", newGroupName)
      setIsLoading(true)
      props.saveGroup(groupObject)

    } else {
      //Show toast Please try again.
      toastr.info("Some error occured, please try again !!", { timeOut: 2000 })

    }
  }

  const handleEditSaveGroupMaps = (resultGroup) => {

    const GroupMapObject = Parse.Object.extend("GroupMap");
    var groupMapTeacherIds = []
    var groupMapKidIds = []
    var groupMapsToDelete = []
    var l = 0
    for (l = 0; l < props.groupmaps.length; l++) {
      if (props.groupmaps[l].attributes.userType === 2) {
        groupMapTeacherIds.push(props.groupmaps[l].attributes.userId)
      } else if (props.groupmaps[l].attributes.userType === 1) {
        groupMapKidIds.push(props.groupmaps[l].attributes.userId)
      }
    }

    //groupMapsToDelete = props.groupmaps.filter((groupMap) => 

    for (var i = 0; i < props.groupmaps.length; i++) {
      var shouldDelete = true
      if (props.groupmaps[i].attributes.userType === 2) {
        for (var j = 0; j < selectedTeachers.length; j++) {
          if (props.groupmaps[i].attributes.userId === selectedTeachers[j].id) {
            shouldDelete = false
          }
        }


      } else if (props.groupmaps[i].attributes.userType === 1) {
        for (var j = 0; j < selectedKids.length; j++) {
          if (props.groupmaps[i].attributes.userId === selectedKids[j].id) {
            shouldDelete = false
          }
        }
      }
      if (shouldDelete) {
        groupMapsToDelete.push(props.groupmaps[i])

      }
    }

    var teacherObjectsToSave = selectedTeachers.filter((selectedTeacher) =>
      !groupMapTeacherIds.includes(selectedTeacher.id)
    )
    var kidObjectsToSave = selectedKids.filter((selectedKid) =>
      !groupMapKidIds.includes(selectedKid.id)
    )
    var groupMapObjectList = []

    if (teacherObjectsToSave.length > 0) {
      var i = 0
      for (i = 0; i < teacherObjectsToSave.length; i++) {
        var groupMapObject = new GroupMapObject();
        groupMapObject.set("groupId", resultGroup.id)
        groupMapObject.set("userId", teacherObjectsToSave[i].id)
        groupMapObject.set("userType", 2)
        groupMapObjectList.push(groupMapObject)
      }
    }
    if (kidObjectsToSave.length > 0) {
      var j = 0
      for (j = 0; j < kidObjectsToSave.length; j++) {
        var groupMapObject = new GroupMapObject();
        groupMapObject.set("groupId", resultGroup.id)
        groupMapObject.set("userId", kidObjectsToSave[j].id)
        groupMapObject.set("userType", 1)
        groupMapObjectList.push(groupMapObject)
      }
    }

    if (groupMapsToDelete.length > 0) {
      var i = 0
      for (i = 0; i < groupMapsToDelete.length; i++) {
        var tempObject = groupMapsToDelete[i]
        tempObject.set("isDeleted", true)
        groupMapObjectList.push(tempObject)
      }

    }
    if (groupMapObjectList.length > 0) {
      props.saveGroupMaps(groupMapObjectList)
    } else {
      setSaveSuccess(true)
    }
  }

  const getKidPhoto = (kObject) => {
    var kidPhoto = ""
    kidPhoto = kObject.attributes.Photo != undefined ? kObject.attributes.Photo._url : avatar2
    return kidPhoto
  }

  const handleRemoveTeacherTag = (teacher) => {
    var tempList = [...selectedTeachers]
    if (tempList.includes(teacher)) {
      removeElement(tempList, teacher)
      setSelectedTeachers(tempList)
    }
  }


  return (
    <React.Fragment>
      <Container fluid={true} className={"page-content"}>
        {!isLoading &&
          <Row>
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=' m-2'>
                  <h4>{matchEditGroup ? "Edit Group" : "Add Group"}</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                  <button
                    className="btn btm-sm btn-primary waves-effect waves-light mt-0 mr-2"
                    onClick={
                      () => {

                        if (matchEditGroup) {
                          handleEditSaveButtonPressed()
                        } else {
                          handleSaveButtonPressed()
                        }
                      }
                    }
                    style={{ float: "right" }}
                    role="button"

                  >
                    Save
                  </button>
                </div>
              </Col>
            </Row>

            <Col lg={12} className="mx-auto">

              <Card>
                <CardBody>
                  <Element name="topScrollArea">
                  </Element>
                  <Col lg={6}>
                    <AvForm>
                      <Label>
                        Group Name:
                      </Label>
                      <AvField
                        type="text"
                        name="singleLineAG"
                        placeholder="Group Name"
                        className="form-control"
                        value={newGroupName}
                        onChange={(e) => {
                          setNewGroupName(e.target.value)
                        }}
                      />
                    </AvForm>
                  </Col>


                  <div className="flex-fill mr-2 ml-1 mt-4 mb-0">
                    <Label className="pb-0" style={{ verticalAlign: "middle" }}>
                      Add Teachers
                    </Label>



                    <Label 
                      className="ml-2 text-primary"
                      onClick={handleEditTeacherTeam}
                      id="profile-edit-enquiry"
                      style={{ fontSize: "20px",cursor :'pointer'}}
                    >
                      <i className=" bx bx-plus-circle"></i>
                    </Label>

                  </div>
                  <hr />
                  {selectedTeachers.map((tObject, keyInside) =>

                    <Badge key={keyInside} className={"badge badge-soft-primary font-size-12 m-2"}>{tObject.attributes.Name}

                      <i className="mdi mdi-window-close ml-1" id={keyInside}
                        onClick={() => {
                          handleRemoveTeacherTag(tObject)
                        }}
                      > </i>
                    </Badge>
                  )}
                  <br />

                  <Label className="mt-3 mb-0 ml-1">
                    Select Student
                  </Label>
                  <hr />

                  <Row lg={12}>

                    <Col lg={6}>
                      <Row>
                        <Col lg={6}>
                          <FormGroup >
                            <select
                              className="form-control"

                              onChange={(e) => {

                                handleClassroomFilterChange(e.target.value)

                              }}

                            >
                              {classroomList.map((classroomObject, key) => (
                                <option key={key} value={classroomObject.id}>{classroomObject.attributes.Name}</option>
                              ))}
                            </select>
                          </FormGroup>
                        </Col>
                        <Col lg={6}>
                          <Input
                            type="text"
                            name="searchAG"
                            className="inner form-control mb-3"
                            placeholder="Search"
                            onChange={(e) => {
                              handleSearchBarTextChange(e.target.value)
                            }}

                          />
                        </Col>
                      </Row>
                      {<Row><p class="ml-4">* Existing selected list</p></Row>}
                      <Row>
                        <div className="table-responsive ml-3 mr-2">
                          <table className="table table-nowrap table-centered mb-0">
                            <tbody>
                              {getAllKidsFilteredList().length === 0 && <div className="ml-3 mt-3">
                                <p>No Student found</p>
                                <hr />
                              </div>}
                              {getAllKidsFilteredList().map((kObject, keyInside) => (
                                <tr key={keyInside}>

                                  <td style={{ width: "60px" }}>

                                    <input type="checkbox" id={"customCheck12_" + keyInside} onChange={

                                      () => {
                                        handleCheckboxChange(kObject)
                                      }

                                    } checked={kObject.id == "pickAll" ? allPick() : selectedKids.includes(kObject)} />

                                  </td>


                                  <td>


                                    <Label className="text-truncate font-size-14 m-0">{kObject.id !== "pickAll" &&
                                      <img src={getKidPhoto(kObject)} className="rounded-circle avatar-xs m-1" alt="" />}
                                      <Label className="text-dark ml-2">{kObject.attributes.Name}
                                        {initialSelctedKids && initialSelctedKids.includes(kObject) && <span>*</span>}</Label>
                                    </Label>
                                  </td>
                                  <td>
                                  </td>
                                  <td>

                                  </td>
                                </tr>
                              ))}



                            </tbody>
                          </table>
                        </div>

                      </Row>


                    </Col>
                    <Col lg={6}>
                      <Row lg={12}>
                        <Col lg={12}>
                          <div className="text-center pt-2 py-auto bg-primary text-white" style={{ borderRadius: "5px", height: "35px" }}>Already In Group</div>
                        </Col>
                      </Row>



                      <Row>
                        <div className="table-responsive mt-3 ml-2 mr-3">
                          <table className="table table-nowrap table-centered mb-0">
                            <tbody>

                              {selectedKids.length === 0 && <div className="ml-1">
                                <p className="ml-3">No Student Selected</p>
                                <hr />
                              </div>}

                              {selectedKids.map((kObject, keyInside) => (
                                <tr key={keyInside}>
                                  <td style={{ width: "60px" }}>
                                    <input type="checkbox" id={"customCheck1_" + keyInside} onChange={

                                      () => {
                                        handleCheckboxChangeForSelected(kObject)
                                      }

                                    } checked={selectedKids.includes(kObject)} />

                                  </td>
                                  <td>
                                    <Label className="text-truncate font-size-14 m-0">
                                      <img src={kObject.attributes.Photo != undefined ? kObject.attributes.Photo._url : avatar2}
                                        className="rounded-circle avatar-xs m-1" alt="" />
                                      <Label className="text-dark ml-2">{kObject.attributes.Name}{initialSelctedKids && initialSelctedKids.includes(kObject) && <span>*</span>}</Label>
                                    </Label>
                                  </td>
                                  <td>
                                  </td>
                                  <td>

                                  </td>
                                </tr>
                              ))}

                            </tbody>
                          </table>
                        </div>

                      </Row>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        }

        {
          isLoading && <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <button className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Group... </button>
              </div>
            </Col>
          </Row>
        }

        {
          <Modal isOpen={showEditTeacherTeamPopup}>


            <ModalHeader>

              <Label className="modal-title mt-0" id="myModalLabel">
                {props.t('Add Teacher')}
              </Label>
            </ModalHeader>
            <ModalBody >


              {props.teachers && props.teachers.length > 0 && <div className="tagcloud mt-1">
                {props.teachers.map((tObject, keyInside) => (
                  <Button
                    key={keyInside}
                    id={tObject.attributes.Name + keyInside}
                    color={selectedTeachers.includes(tObject) === true ? "primary" : "secondary"}
                    className=
                    "btn btn-sm rounded m-1"

                    onClick={() => {

                      handleEditTeacherTeamClicks(tObject)


                    }}
                  >
                    {tObject.attributes.Name}
                  </Button>
                ))}
              </div>
              }




            </ModalBody>

            <ModalFooter>
              <Row className="text-center">
                <div className="d-flex">
                <button
                  type="button"
                  onClick={() => {
                    handleCancelButtonInAddTeacher()
                    setShowEditTeacherTeamPopup(false)
                  }}
                  className="btn btn-secondary waves-effect m-1"
                  data-dismiss="modal"
                >
                  {props.t('Cancel')}
                </button>
                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light m-1"
                  onClick={() => {
                    setShowEditTeacherTeamPopup(false)
                  }}
                >
                  {props.t('Save')}
                </button>
                </div>
              </Row>




            </ModalFooter>
          </Modal>
        }



      </Container>

    </React.Fragment >
  );
};



const mapStatetoProps = state => {
  const { teachers } = state.Teacher;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  const { resultGroup, singleGroupHolder } = state.Group;
  const { groupmaps, resultGroupMapsAfterSave } = state.GroupMap;
  const { selectedSchool } = state.School;

  return { groupmaps, teachers, classrooms, kids, resultGroup, resultGroupMapsAfterSave, singleGroupHolder, selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getTeachersForSchool, getClassroomsForSchoolId, getAllKidsForGroup, saveGroup, saveGroupMaps, getGroupMapsForGroupId, getGroupForId, setSelecetdSchool })(AddGroups)));


