import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil"

const initialState = {
  messages: [], selectedMessage: undefined, isLoadingMessages: false, noMessagesToShow: undefined, isOldMessages: undefined,
  albums: [], messageQueues: [], messageQueueTimer: null,
};

const Message = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_MESSAGES:
      var updatedList = ReduxUtil.updateParseObjects([...state.messages], action.payload.messages, "isDeleted", action.payload.isPush)
      let sortedList = updatedList.sort(function (a, b) {
        if (new Date(a.attributes.customUpdatedTime) < new Date(b.attributes.customUpdatedTime)) {
          return -1;
        }
        if (new Date(a.attributes.customUpdatedTime) > new Date(b.attributes.customUpdatedTime)) {
          return 1;
        }
        return 0;

      });
      state = { ...state, messages: sortedList };
      break
    case actions.UPDATE_MESSAGE_QUEUES:
      var updatedList = ReduxUtil.updateParseObjects([...state.messageQueues], action.payload.messages, "isDeleted", action.payload.isPush)
      // let sortedList = updatedList.sort(function (a, b) {
      //   if (new Date(a.attributes.customUpdatedTime) < new Date(b.attributes.customUpdatedTime)) {
      //     return -1;
      //   }
      //   if (new Date(a.attributes.customUpdatedTime) > new Date(b.attributes.customUpdatedTime)) {
      //     return 1;
      //   }
      //   return 0;

      // });
      state = { ...state, messageQueues: updatedList };
      break

    case actions.DELETE_ALBUM:
      var albums = state.albums.filter(
        (album) => album.id !== action.payload.album.id
      );
      state = { ...state, albums: albums };
      break;

    case actions.UPDATE_ALBUMS:
      var updatedList = ReduxUtil.updateParseObjects([...state.albums], action.payload.albums, "isDeleted", action.payload.isPush)
      state = { ...state, albums: updatedList };
      break

    case actions.SET_NO_MESSAGES_TO_SHOW:
      state = { ...state, noMessagesToShow: action.payload.value };
      break

    case actions.DIRECT_ASSIGN_TO_STATE_MESSAGES:
      state = { ...state, messages: action.payload.messages };
      break
    case actions.SET_LOADING_MESSAGES:
      state = { ...state, isLoadingMessages: action.payload.boolValue };
      break
    case actions.TOGGLE_IS_OLD_MESSAGES:
      state = { ...state, isOldMessages: action.payload.boolValue };
      break
    case actions.MESSAGE_QUEUE_TIMER:
      state = { ...state, messageQueueTimer: action.payload.value };
      break
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Message;
