import * as actions from "./actionTypes";

const initState = {
  triansactionsItems: [],
};

const MoneyTransactionDetails = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_ALL_TRIANSACTION_FOR_ITEM_IDS:
      state = { ...state, triansactionsItems: [] };
      break;
    case actions.SET_ALL_TRIANSACTION_FOR_IDS:
      var tempTriansactionsItems = [...state.triansactionsItems];
      if (tempTriansactionsItems.length > 0) {
        for (const latest in action.payload.objs) {
          var temp = tempTriansactionsItems.filter(
            (schoolRating) => schoolRating.id === action.payload.objs[latest].id
          );
          if (temp.length > 0) {
            var index = tempTriansactionsItems.lastIndexOf(temp[0]);
            tempTriansactionsItems[index] = action.payload.objs[latest];
          } else {
            tempTriansactionsItems.push(action.payload.objs[latest]);
          }
        }
        state = { ...state, triansactionsItems: tempTriansactionsItems };
      } else {
        state = { ...state, triansactionsItems: action.payload.objs };
      }
      break;
  }
  return state;
};

export default MoneyTransactionDetails;
