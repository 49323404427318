import  * as actions from './actionTypes';

export const getMTDForItemIds = ids =>{
    return{
        type : actions.GET_ALL_TRIANSACTION_FOR_ITEM_IDS,
        payload : { ids }
    }
}

export const setMTDForIds = objs =>{
    return{
        type : actions.SET_ALL_TRIANSACTION_FOR_IDS,
        payload : { objs }
    }
}