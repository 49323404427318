import * as actions from './actionTypes';

export const updateTeacher = (teacher) => {
    return {
        type: actions.UPDATE_TEACHER,
        payload: { teacher }
    }
}
export const updateTeachers = (teachers,schoolId) => {
    return {
        type: actions.UPDATE_TEACHERS,
        payload: { teachers,schoolId }
    }
}

export const clearAllTeachers = () => {
    return {
        type: actions.CLEAR_ALL_TEACHERS,
        payload: { }
    }
}

export const getTeacherAttendanceForDay = (schoolId) => {
    return {
        type: actions.GET_TEACHER_ATTENDANCE_FOR_DAY,
        payload: { schoolId }
    }
}
export const updateTeacherAttendanceForDay = (teacherAttendanceObjects) => {
    return {
        type: actions.UPDATE_TEACHER_ATTENDANCE_FOR_DAY,
        payload: { teacherAttendanceObjects }
    }
}
export const getDaycareAttendanceForDay = (schoolId) => {
    return {
        type: actions.GET_DAYCARE_ATTENDANCE_FOR_DAY,
        payload: { schoolId }
    }
}
export const getSchoolAttendance = (schoolId) => {
    return {
        type: actions.GET_SCHOOL_ATTENDANCE,
        payload: { schoolId }
    }
}
export const updateSchoolAttendance = (attendanceObjects) => {
    return {
        type: actions.UPDATE_SCHOOL_ATTENDANCE,
        payload: { attendanceObjects }
    }
}

export const updateDaycareAttendanceForDay = (daycareAttendanceObjects) => {
    return {
        type: actions.UPDATE_DAYCARE_ATTENDANCE_FOR_DAY,
        payload: { daycareAttendanceObjects }
    }
}






export const saveTeachers = (teachers) => {
    return {
        type: actions.SAVE_TEACHERS,
        payload: { teachers }
    }
}
export const updateDeletedTeachers = (teachers) => {
    return {
        type: actions.UPDATE_DELETED_TEACHERS,
        payload: { teachers }
    }
}

export const editTeacher = (teacher, franchiseTeacher) => {
    return {
        type: actions.EDIT_TEACHER,
        payload: { teacher, franchiseTeacher }
    }
}

export const getTeacher = (id, isUpdate) => {
    return {
        type: actions.GET_TEACHER,
        payload: { id, isUpdate }
    }
}
export const requestForWebAccess = (email, teacherRole, teacherId, levelId, level, teacher, instituteId) => {
    return {
        type: actions.REQUEST_FOR_WEB_ACCESS,
        payload: { email, teacherRole, teacherId, levelId, level, teacher, instituteId }
    }
}

export const responseForWebAccess = (responseResult) => {
    return {
        type: actions.RESPONSE_FOR_WEB_ACCESS,
        payload: { responseResult }
    }
}
export const requestForWebAccessRemoval = (email, teacherRole, teacherId, levelId, level, teacher) => {
    return {
        type: actions.REQUEST_FOR_WEB_ACCESS_REMOVAL,
        payload: { email, teacherRole, teacherId, levelId, level, teacher }
    }
}

export const responseForWebAccessRemoval = (responseResult) => {
    return {
        type: actions.RESPONSE_FOR_WEB_ACCESS_REMOVAL,
        payload: { responseResult }
    }
}

export const getTeachersForSchool = (schoolId) => {
    return {
        type: actions.GET_TEACHERS,
        payload: { schoolId }
    }
}

export const getDeletedTeachersForSchoolId = (schoolId) => {
    return {
        type: actions.GET_DELETED_TEACHERS,
        payload: { schoolId }
    }
}


export const setSelecetdTeacher = (id) => {
    return {
        type: actions.SET_SELECTED_TEACHER,
        payload: { id }
    }
}
export const updateSelecetdTeacher = (teacher) => {
    return {
        type: actions.UPDATE_SELECTED_TEACHER,
        payload: { teacher }
    }
}

export const setLoading = (state) => {
    return {
        type: actions.LOADING,
        payload: { state }
    }
}

export const deleteTeacher = (id, successMessage) => {
    return {
        type: actions.DELETE_TEACHER,
        payload: { id, successMessage }
    }
}
export const deleteTeacherSuccess = (teacher) => {
    return {
        type: actions.DELETE_TEACHER_SUCCESS,
        payload: { teacher }
    }
}
export const getTeacherForClassroom = classId => {
    return {
        type: actions.GET_TEACHER_FOR_CLASSROOM,
        payload: { classId }
    }
}
export const setTeacherForClassroom = teacherObj => {
    return {
        type: actions.SET_TEACHER_FOR_CLASSROOM,
        payload: { teacherObj }
    }
}

export const updateTeachersObjs = (teachersObjs, classId) => {
    return {
        type: actions.UPDATE_TEACHERS_OBJS,
        payload: { teachersObjs, classId }
    }
}

export const getFranchiseTeachers = () => {
    return {
        type: actions.GET_FRANCHISE_TEACHERS,
        payload: {}
    }
}
export const updateFranchiseTeachers = (teachers) => {
    return {
        type: actions.UPDATE_FRANCHISE_TEACHERS,
        payload: { teachers }
    }
}

export const addFranchiseTeacher = (teacher) => {
    return {
        type: actions.ADD_FRANCHISE_TEACHER,
        payload: { teacher }
    }
}

export const getDeletedTeachersForMonth = (schoolId, date) => {
    return {
        type: actions.GET_DELETED_TEACHERS_FOR_MONTH,
        payload: { schoolId, date }
    }
}

export const updateDeletedTeachersForMonth = (teachers) => {
    return {
        type: actions.UPDATE_DELETED_TEACHERS_FOR_MONTH,
        payload: { teachers }
    }
}

export const getTeacherForIds = (ids) => {
    return {
        type: actions.GET_TEACHERS_FOR_IDS,
        payload: { ids }
    }
}

export const updateTeacherForIds = (teachers, isConcat) => {
    return {
        type: actions.UPDATE_TEACHERS_FOR_IDS,
        payload: { teachers, isConcat }
    }
}
