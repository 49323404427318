import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";

const initialState = {
    inventoryTags: [], loding: undefined,
};

const InventoryTag = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_INVENTORY_TAGS:
            var inventoryTags = [...state.inventoryTags];
            if (inventoryTags.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.inventoryTags], action.payload.inventoryTags, "isDeleted")

                state = { ...state, inventoryTags: updatedList };
            } else {
                var i = 0
                var inventoryTagsToSave = []
                for (i = 0; i < action.payload.inventoryTags.length; i++) {
                    if (action.payload.inventoryTags[i].attributes.isDeleted !== true) {
                        inventoryTagsToSave.push(action.payload.inventoryTags[i])
                    }
                }
                state = { ...state, inventoryTags: inventoryTagsToSave };
            }
            break;

        case actions.UPDATE_DELETE_INVENTORY_TAG:
            var inventoryTags = state.inventoryTags.filter(
                (inventoryTag) => inventoryTag.id !== action.payload.inventoryTag.id
            );
            state = { ...state, inventoryTags: inventoryTags };
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
};

export default InventoryTag;
