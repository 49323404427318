export const UPDATE_COURSE = "UPDATE_COURSE";
export const DELETE_COURSE = "DELETE_COURSE";

export const UPDATE_COURSES = "UPDATE_COURSES";
export const GET_COURSES = "GET_COURSES";
export const GET_COURSE = "GET_COURSE";
export const GET_COURSE_FOR_OWNER_ID = "GET_COURSE_FOR_OWNER_ID"

export const SET_COURSE_INITIAL_LOADING = "SET_COURSE_INITIAL_LOADING";
export const UPDATE_COURSE_INITIAL_LOADING = "UPDATE_COURSE_INITIAL_LOADING";

export const ADD_NEW_COURSE = "ADD_NEW_COURSE"

export const CLEAR_COURSE = "CLEAR_COURSE"

export const SET_SELECTED_COURSE = "SET_SELECTED_COURSE";
export const UPDATE_SELECTED_COURSE = "UPDATE_SELECTED_COURSE";


export const GET_ALL_COURSES_FOR_SCHOOL_ID = "GET_ALL_COURSES_FOR_SCHOOL_ID";