import React, { useState, useEffect } from 'react';;
import { Row, Col, Button, Input, Container, Label, Card, CardBody, FormGroup, } from "reactstrap";
import moment from 'moment';
import GraduationSymbol from '../../../assets/images/certificate/graduation.png';
import KidGraduation1 from '../../../assets/images/certificate/kidsGraduation1.png'
import KidGraduation2 from '../../../assets/images/certificate/kidsGraduation2.png'
import KidGraduation3 from '../../../assets/images/certificate/kidsGraduation3.png'
import KidGraduation4 from '../../../assets/images/certificate/kidsGraduation4.png'
import * as WhiteLable from "../../../components/Common/WhiteLable";


const graduation = (props) => {
    return (
        <div className="certificate-card"
            style={{
                width: '90%',
                height: '90%',
                padding: '20px',
                borderRadius: '10px',
            }}>
            <div
                className="certificate-container"
            // style={{
            //     margin: '0 auto',
            //     padding: '20px',
            //     backgroundColor: props.styleType === "1" ? 'white' : undefined,
            //     backgroundImage: props.styleType === "2" ? `url(${Certificate5})` :
            //         props.styleType === "3" ? `url(${Certificate6})` :
            //             props.styleType === "4" ? `url(${Certificate7})` :
            //                 undefined,
            //     backgroundSize: 'cover',
            //     backgroundPosition: 'center',
            //     boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
            //     width: '800px',
            //     height: '500px',
            //     display: 'flex',
            //     flexDirection: 'column',
            //     justifyContent: 'center',
            //     alignItems: 'center',
            //     border: '30px solid',
            //     borderImage: 'linear-gradient(135deg, #AED9E0, #B8E994)',
            //     borderImageSlice: '1',
            // }}
            >
                <style>
                    {`
      .certificate-container {
        margin: 0 auto;
        padding: 20px;
        width: 820px;
        height: 500px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-image: ${props.styleType === '1'
                            ? `url(${KidGraduation4})`
                            : props.styleType === '2'
                                ? `url(${KidGraduation1})`
                                : props.styleType === '3'
                                    ? `url(${KidGraduation2})`
                                    : props.styleType === '4'
                                        ? `url(${KidGraduation3})`
                                        : 'none'
                        };
        background-size: cover;
        background-position: center;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        
      }


      .no-print {
        display: none;
      }

      @media print {
        body {
          margin: 0;
          padding: 0;
        }

        .certificate-container {
          position: fixed;
          left: 0;
          top: 0;
          width: 100vw;
          height: 100vh;
          overflow: hidden;
          z-index: 9999;
        }

        @page {
          size: A4 landscape;
          margin: 0;
        }
      }
      `}
                </style>
                <div>
                    <Row className='text-center'>
                        {props.selectedSchool && props.selectedSchool.attributes.logo && (
                            <Col className='text-center'>
                                {" "}
                                <img className='text-center'
                                    src={props.selectedSchool && props.selectedSchool.attributes.logo._url}
                                    style={{ height: "6rem" }}
                                />{" "}
                            </Col>
                        )}
                    </Row>
                    <Row className='p-4'>
                        <Col lg="12">
                            <Row className=''>
                                <Col lg={12} className="d-flex align-items-center justify-content-center">
                                    <div className="m-2" >
                                        <img src={GraduationSymbol} alt="" className=" avatar-xs" />
                                    </div>
                                    <h2 className='text-center m-1' style={{ borderBottom: '1px solid black', fontSize: '2rem', letterSpacing: '0.1rem', color: 'black', fontFamily: 'Georgia, serif' }}>
                                        <strong>Graduation Certificate</strong>
                                    </h2>
                                </Col>
                            </Row>
                            <Row className="py-4">
                                <Col lg={12} className="d-flex align-items-center justify-content-center">
                                    <h5 className="text-center font-size-18" style={{ letterSpacing: '0.1rem', color: 'black', fontFamily: 'Georgia, serif' }}>
                                        C O N G R A T U L A T I O N S
                                    </h5>
                                </Col>
                            </Row>
                            <Row className="">
                                <div className="text-center">
                                    <b className="text-center font-size-24" style={{ borderBottom: '2px solid black', fontFamily: 'cursive', fontStyle: 'oblique', textTransform: 'uppercase' }}>{props.name}</b>
                                    <h6 className="py-4 text-center font-size-16">
                                        You have successfully completed Preschool in <b>{props.selectedSchool.attributes.Name}</b> and are therefore<br /> awarded this Preschool Diploma in recognition of your accomplishments.
                                    </h6>
                                </div>
                            </Row>

                            <div className=''>
                                <Row className='align-items-center'>
                                    <Col className='d-flex flex-column align-items-center'>
                                        <h6 style={{ margin: '0', fontSize: '0' }}>{""}</h6>
                                        <span style={{ borderTop: '1px solid black', width: '3cm', textAlign: 'center', lineHeight: '1.2' }}>Signature</span>
                                    </Col>
                                    <Col className='d-flex flex-column align-items-center'>
                                        <h6 style={{ margin: '0' }}>{moment(props.date).format("DD/MM/YYYY")}</h6>
                                        <span style={{ borderTop: '1px solid black', width: '3cm', textAlign: 'center' }}>Date</span>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    </Row>
                    {false &&<Row className='float-right'>
                        <Col>
                            <Label className="logo logo-dark float-right">
                                <img src={WhiteLable.getAppLogoWithName()} alt="" className="avatar " height={'15'} />
                            </Label>
                        </Col>
                    </Row>}
                </div>

            </div>
        </div>
    )
}


export default graduation




