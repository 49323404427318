import Parse from "parse";


export const getInventoryWareHouseForOwnerId = (ownerId, ownerType, isLocal) => {
  try {
    return new Promise((resolve, reject) => {
      var InventoryWarehouse = Parse.Object.extend("InventoryWarehouse");
      var query = new Parse.Query(InventoryWarehouse);
      query.equalTo("ownerId", ownerId);
      query.equalTo("ownerType", ownerType);
  
        
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.find().then(
        (result) => {
          // console.log(result)
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
  
export const getInventoryStockForWarehouse = (id, updatedDate, isLocal,) => {
  try {
    return new Promise((resolve, reject) => {
      var Table = Parse.Object.extend("InventoryItemStockMap");
      var query = new Parse.Query(Table);
      query.equalTo("warehouseId", id);
        
  
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
        
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};