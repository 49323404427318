
import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    Button,
    Collapse,
    Container,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    UncontrolledTooltip,
    Progress,
    Alert
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";



import { withNamespaces } from "react-i18next";
import Parse from "parse";


const ImageUploadComponent = (props) => {

    const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
    const _pdfExtensions = [".pdf", "pdf"];
    const _audioExtensions = [".mp3", "mp3", ".wma", "wma", ".acc", "acc", ".wav", "wav", ".flac", "flac",];

    const [filesList, setFilesList] = useState([]);
    const [uploadPogress, setUploadProgress] = useState();
    const [progressText, setProgressText] = useState()

    const [hideRemoveButton, setHideRemoveButton] = useState(false);
    const [uploadedAlbums, setUploadedAlbums] = useState([]);

    const [errorId, setErrorId] = useState(undefined);

    useEffect(() => {
        setFilesList(props.fileList)
    }, [props.fileList]);


    const handelRemoveFile = (file) => {
        let oList = [...filesList];
        let nList = oList.filter(
            (f) => f.id !== file.id
        );
        if (nList == undefined || nList.length === 0) {
            props.onCancelClickedOnImageUpload()
        }
        setFilesList(nList);
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };

    }


    const setFileDownloadeble = (file, downloadeble) => {
        let temp = file;
        temp.downloadeble = downloadeble;
        setUpdateFile(file, temp)
    }

    const setUpdateFile = (oldFile, newFile) => {
        let oList = [...filesList];
        let index = oList.indexOf(oldFile);
        oList[index] = newFile;

        setFilesList(oList);
    }


    const onClickSaveAndUpload = () => {
        let errorId = undefined;
        let nList = []
        for (const i in filesList) {
            let temp = filesList[i];
            if (!filesList[i].name || filesList[i].name.length === 0) {
                if (!errorId) {
                    errorId = filesList[i].id;

                }

                temp.nameError = true;
            }

            nList.push(temp)
        }

        if (errorId) {

            setErrorId(errorId);
            setFilesList(nList);
        } else {
            setHideRemoveButton(true);
            handelFilesUpload(filesList);
        }
    }

    const [uploadofFiles, setUploadofFiles] = useState(-1);
    const [newFiles, setNewFiles] = useState([]);
    const handelFilesUpload = (filesList) => {
        console.log(filesList);
        setNewFiles(filesList);
        setUploadofFiles(0);

        console.log("P:-1")
    }

    useEffect(() => {

        console.log("P:1: " + uploadofFiles);
        if (uploadofFiles >= 0) {
            console.log("P:2=" + uploadofFiles);
            if (newFiles.length > uploadofFiles) {
                onFileListUpload(newFiles[uploadofFiles], uploadofFiles);
            } else {
                setUploadofFiles(-1);
                setNewFiles([]);
                setProgressText("")

            }

        }

    }, [uploadofFiles, newFiles]);

    function onFileListUpload(file, count) {

        console.log("P:3" + count)
        var name = file.name.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
        if (file.type === "Image") {
            name = name + "." + file.extention;
        }

        setProgressText(props.t('Uploading ') + (count + 1) + " of " + newFiles.length)
        let oneFileWhite = 100 / newFiles.length;
        let percentOffSet = oneFileWhite * count;
        getBase64(file.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            setUploadProgress(1);
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        let newProgress = percentOffSet + (value * oneFileWhite);
                        setUploadProgress(newProgress);

                    }
                }
            }).then(
                result => {
                    var Files = Parse.Object.extend("Album");
                    var fileObject = new Files();
                    fileObject.set("albumId", props.albumId);
                    fileObject.set("file", result);
                    fileObject.save().then(
                        (result) => {
                            let list = [...uploadedAlbums]
                            list.push(result)
                            setUploadedAlbums(list)
                            let countOfUploadedFiles = uploadofFiles + 1
                            setUploadofFiles(countOfUploadedFiles)


                            if (newFiles.length > countOfUploadedFiles) {
                            } else {
                                setUploadofFiles(-1);
                                setNewFiles([]);
                                setUploadProgress(0);

                                props.onComplete(list, props.albumId)
                            }


                        })






                },
                error => {
                    setProgressText(props.t('Error while uploading Photo, Please refresh the page and try again'))
                }
            );
        });
    }

    return (
        <React.Fragment >

            <Modal isOpen={true} size="lg">
                <ModalHeader>
                    <div className="modal-title mt-0" id="modalUploadPhotos">
                        {props.t("Upload Photos")}

                        <br />

                    </div>
                </ModalHeader>
                <ModalBody>

                    {
                        filesList && filesList.map((file, i) => <div name={file.id} key={file.id}><Row className="border mt-2">




                            <Col lg={10} md={10} sm={10} xs={10}>
                                <div className="d-flex"><img
                                    data-dz-thumbnail=""

                                    className="rounded m-3"
                                    width="100"
                                    height="100"
                                    alt={file.name}
                                    src={file.preview}
                                />
                                    <h6 style={{ marginTop: "58px", float: "right" }}>{file.actualFileName}</h6></div>
                            </Col>
                            <Col lg={2} md={2} sm={2} xs={2}>{!hideRemoveButton && <ul className="list-inline font-size-20 contact-links" style={{ marginTop: "55px" }}>
                                <li className="list-inline-item" id={"file_" + file.id}
                                    onClick={() => {
                                        handelRemoveFile(file);
                                    }}
                                >
                                    <i className="bx bx-message-square-x text-danger"></i>
                                    <UncontrolledTooltip placement="top" target={"file_" + file.id}>
                                        {props.t('Remove')}
                                    </UncontrolledTooltip>
                                </li>
                            </ul>}</Col>




                        </Row>
                        </div>)
                    }
                    {filesList && filesList.length > 0 && (
                        <div>
                            {newFiles && newFiles.length > 0 ? (
                                <div className="mb-30 mt-2">
                                    <p className="text-info mb-2">
                                        {props.t('Please do not close or refresh the page while uploading photos')}
                                    </p>
                                    <Progress striped color="primary" value={uploadProgress}></Progress>
                                    <h6 className="float-right mt-4 text-primary">{progressText}</h6>
                                </div>
                            ) : (
                                <div className="mb-30 mt-2 d-flex justify-content-between">
                                    <button
                                        className="btn btn-primary mt-2"
                                        onClick={() => {
                                            onClickSaveAndUpload();
                                        }}
                                    >
                                        {props.t('Upload & Save')}
                                    </button>
                                    {uploadofFiles === -1 && (
                                        <button
                                            className="btn btn-primary mt-2"
                                            onClick={() => {
                                                props.onCancelClickedOnImageUpload();
                                            }}
                                        >
                                            {props.t('Cancel')}
                                        </button>
                                    )}
                                </div>
                            )}
                        </div>
                    )}




                </ModalBody>


            </Modal></React.Fragment>
    )
}
export default withNamespaces()(ImageUploadComponent);