import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Label,
    Button,
    UncontrolledTooltip,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import toastr from "toastr";
import Moment from 'moment';
import { Link, withRouter } from "react-router-dom";
import Parse from "parse";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";
import { getTripsForSchoolId, getGroupsForSchoolId, saveGroupMaps, getGroupMapsForKidId } from "../../store/actions";

const TripsDetails = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [displayTripsList, setDisplayTripList] = useState([]);
    const [tripToRemove, setTripToRemove] = useState(undefined);
    const [tripDeleteAlert, setTripDeleteAlert] = useState(false)
    const [addTripModal, setAddTripModal] = useState(false);
    const [selectedTrip, setSelectedTrip] = useState([]);

    useEffect(() => {
        if (props.schoolId) {
            props.getTripsForSchoolId(props.schoolId);
            props.getGroupsForSchoolId(props.schoolId);
        }
    }, [props.schoolId])

    useEffect(() => {
        if (props.selectedKid) {
            props.getGroupMapsForKidId(props.selectedKid.id)
        }

    }, [props.groups])

    useEffect(() => {
        // console.log("trips", props.trips)
        // console.log("displaytripList", displayTripsList)
        // console.log("groupmaps", props.groupmaps)
        // console.log("groups", props.groups)
        if (props.groupmaps && props.trips) {
            let list = []
            for (const trip of props.trips) {
                for (const groupMap of props.groupmaps) {
                    if (trip.attributes.groupId == groupMap.attributes.groupId) {
                        list.push(trip)
                    }
                }
            }
            setDisplayTripList(list)

        }
    }, [props.groupmaps, props.selectedKid, props.trips])

    //// Utilities functions


    const removeTrip = () => {
        let tripGroupMap = []
        for (let i = 0; i < props.groupmaps.length; i++) {
            if (props.groupmaps[i].attributes.groupId == tripToRemove.attributes.groupId) {
                tripGroupMap.push(props.groupmaps[i])
            }
        }
        if (tripGroupMap.length > 0) {
            let remove = []
            for (let i = 0; i < tripGroupMap.length; i++) {
                tripGroupMap[i].set("isDeleted", true);
                remove.push(tripGroupMap[i])
            }
            props.saveGroupMaps(remove,undefined, undefined, [tripToRemove.id])
            setSelectedTrip([])
            setTripDeleteAlert(false)
        }
    }


    const handleAddingTrips = () => {

        const GroupMapObject = Parse.Object.extend("GroupMap");

        var groupMapObjectList = []
        let tripIds=[];

        for (let i = 0; i < selectedTrip.length; i++) {
            var groupMapObject = new GroupMapObject();

            for(const t of props.trips){
                if(t.attributes.groupId == selectedTrip[i]){
                    tripIds.push(t.id) 
                }
            }
            groupMapObject.set("groupId", selectedTrip[i])
            groupMapObject.set("userId", props.selectedKid.id)
            groupMapObject.set("userType", 1)
            groupMapObjectList.push(groupMapObject)
        }
        props.saveGroupMaps(groupMapObjectList, undefined, undefined, tripIds)
        setAddTripModal(false)
    }



    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    const handleCheckboxChange = (trip) => {
        let selectedList = [...selectedTrip]
        if (selectedList.includes(trip.attributes.groupId)) {
            removeElement(selectedList, trip.attributes.groupId)
        } else {
            selectedList.push(trip.attributes.groupId)
        }
        setSelectedTrip(selectedList)
    }

    return (
        <React.Fragment>
            {tripDeleteAlert && (
                <SweetAlert
                    title={props.t('Are you sure to remove')}
                    warning
                    showCancel
                    confirmBtnText="Remove"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="secondary"
                    onConfirm={() => {
                        removeTrip();
                    }}
                    onCancel={() => {
                        setTripDeleteAlert(false);
                    }}>
                    {props.t("Are you sure to remove from trip")}

                </SweetAlert>

            )}

            <Modal isOpen={addTripModal} scrollable={true}>
                <ModalHeader isOpen={addTripModal} toggle={() => {
                    setAddTripModal(!addTripModal)
                    setSelectedTrip([])
                }}>
                    <h5 className="modal-title mt-0">{props.t(`Select Trip to add ${props.selectedKid.attributes.Name}`)}</h5>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row>
                        <Col>
                            <Row>
                                <div className="table-responsive mt-3 ml-2 mr-3">
                                    <table className="table table-nowrap table-centered mb-0">
                                        <tbody>
                                            {displayTripsList && displayTripsList.length == 0 && (
                                                <div className="ml-1">
                                                    <p className="ml-3">No Group Assigned !!</p>
                                                    <hr />
                                                </div>
                                            )}

                                            {props.trips && props.trips.map((trip, key) => (
                                                <tr key={key}>
                                                    <td style={{ width: "60px" }}>
                                                        <input
                                                            type="checkbox"
                                                            id={"CCTVStudentProfile" + key}
                                                            disabled={displayTripsList.includes(trip)}
                                                            onChange={() => {
                                                                handleCheckboxChange(trip)
                                                            }}
                                                            checked={selectedTrip.includes(trip.attributes.groupId) || displayTripsList.includes(trip)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Label className="text-truncate font-size-14 m-0">

                                                            <Label className="text-dark ml-2">
                                                                {trip.attributes.name}
                                                            </Label>{" "}
                                                        </Label>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary"
                        onClick={() => {
                            handleAddingTrips()
                        }}
                        disabled={selectedTrip && selectedTrip.length > 0 ? false : true}>
                        Done
                    </button>
                </ModalFooter>
            </Modal>
            {
                <div>
                    <Row>
                        <div className="d-flex">
                            <button className="btn btn-primary btn-sm mb-4 ml-auto" style={{ marginLeft: 'auto' }} onClick={() => {
                                setAddTripModal(true)
                            }}>
                                Add to Trip
                            </button>
                        </div>
                    </Row>
                    {displayTripsList.length > 0 && (
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th style={{ width: '30%', textAlign: 'left' }}>{props.t("Trip Name")}</th>
                                        <th className="text-center" style={{ width: '20%', textAlign: 'left' }}>{props.t("Trip Type")}</th>
                                        <th style={{ width: '50%', textAlign: 'right' }}>{props.t("Actions")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {displayTripsList.map((trip, key) => (
                                        <tr key={key}>
                                            <td style={{ textAlign: 'left' }}>
                                                <Label className="font-size-12 mb-1">
                                                    {trip.attributes.name}
                                                </Label>
                                            </td>
                                            <td className="text-center" style={{ textAlign: 'left' }}>
                                                <Label className="font-size-12 mb-1">
                                                    {trip.attributes.tripType === 1 ? "Pick up" : "Drop"}
                                                </Label>
                                            </td>
                                            <td style={{ textAlign: 'right', }}>
                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                    <li className="list-inline-item px-2">
                                                        <Link
                                                            to="#" id={"deleteTrip" + trip.id}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setTripToRemove(trip)
                                                                setTripDeleteAlert(true)
                                                            }}>
                                                            <i className="bx bx-trash text-danger"></i>
                                                            <UncontrolledTooltip placement="top" target={"deleteTrip" + trip.id}>
                                                                {props.t('Remove')}
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {displayTripsList.length == 0 && (
                        <div className=""><p className="text-center">No Group Assigned for this student !!</p></div>
                    )}
                </div>
            }
            {/* {isLoading && (
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Link to="#" className="text-success">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                Loading Cameras...{" "}
                            </Link>
                        </div>
                    </Col>
                </Row>
            )} */}
        </React.Fragment>
    );
};


const mapStatetoProps = (state) => {
    const { groups, deletedSuccessfully } = state.Group;
    const { trips } = state.Trips;
    const { groupmaps, resultGroupMapsAfterSave } = state.GroupMap;

    return {
        groups, deletedSuccessfully,
        trips,
        groupmaps, resultGroupMapsAfterSave
    };
};
export default connect(mapStatetoProps, {
    getTripsForSchoolId, getGroupsForSchoolId, saveGroupMaps, getGroupMapsForKidId
})(withRouter(withNamespaces()(TripsDetails)));


