export const UPDATE_ADMISSION_QUESTION = "UPDATE_ADMISSION_QUESTION";
export const DELETE_ADMISSION_QUESTION = "DELETE_ADMISSION_QUESTION";

export const UPDATE_ADMISSION_QUESTIONS = "UPDATE_ADMISSION_QUESTIONS";   
export const GET_ADMISSION_QUESTIONS = "GET_ADMISSION_QUESTIONS";
export const GET_ADMISSION_QUESTION = "GET_ADMISSION_QUESTION";

export const EDIT_ADMISSION_QUESTION = "EDIT_ADMISSION_QUESTION";
export const ADD_ADMISSION_QUESTION = "ADD_ADMISSION_QUESTION";

export const UPDATE_ADMISSION_QUESTION_SORT_ORDER = "UPDATE_ADMISSION_QUESTION_SORT_ORDER";
export const RELOAD_ADMISSION_QUESTION = "RELOAD_ADMISSION_QUESTION";

export const MOVE_ADMISSION_QUESTION = "MOVE_ADMISSION_QUESTION";

export const GET_DEFAULT_ADMISSION_QUESTIONS = "GET_DEFAULT_ADMISSION_QUESTIONS";
export const UPDATE_DEFAULT_ADMISSION_QUESTIONS = "UPDATE_DEFAULT_ADMISSION_QUESTIONS";

