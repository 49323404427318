import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as VerifiedLogo } from "../../assets/images/certificate/verifiedLogo.svg";
import { ReactComponent as Divider } from "../../assets/images/certificate/separator.svg";
import profileImg from "../../assets/images/users/userXYZ.png";
import * as WhiteLabel from "../../components/Common/WhiteLable";
import {
    Container,
    Row,
    Col,
} from "reactstrap";
import * as Constants from "../../Constents";
import Moment from "moment";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import logo from "../../assets/images/logo-dark.png";
import QRCode from 'qrcode'


const StaffIdCardView = (props) => {

    const [QrCode, setQrCode] = useState(undefined);

    useEffect(() => {
        if (props.teacher) {
            getQRCode(props.teacher.id)
        }
    }, [props.teacher])




    function getQRCode(teacherId) {
        QRCode.toDataURL(teacherId, { errorCorrectionLevel: 'H' }).then(url => {
            setQrCode(url)
        }).catch(err => {
            console.error(err)
        })
    }

    function getDesignation(teacher) {
        if (teacher.attributes.designation) {
            return teacher.attributes.designation;
        } else if (teacher.attributes.Role === 3) {
            return props.t('Director');
        } else if (teacher.attributes.Role === 2) {
            return props.t('Center Head');
        } else {
            return props.t('Teacher');
        }
    }

    const getBodyFontSize = (obj) => {
        let tempObject = { ...obj }
        tempObject.fontSize = (((props.bodyFontSize * 0.25)) + "mm")
        return tempObject
    }

    const getPotrite = () => {
        return (

            <div style={{
                width: props.selectedPrintSetting.cardWidth,
                minHeight: props.selectedPrintSetting.cardHeight,
                backgroundColor: "white",
                border: props.isBorderOff ? "" : "1px solid lightGray",
                borderRadius: "10px",
            }}
                className="ml-3 dont-cut-element-when-printing"
            >
                <div style={{ minHeight: props.selectedPrintSetting.topHeaderDivHeight, maxHeight: props.selectedPrintSetting.topHeaderDivHeight, overflow: "hidden", paddingTop: "0mm" }}>

                    <Row className="mt-0">
                        <div
                            className={`d-flex mx-auto align-items-center justify-content-center ${props.idCardLogo ? "m-0" : "m-2"}`}
                            style={{
                                maxWidth: `${props.idCardLogo ? "100%" : "80%"}`,
                            }}
                        >
                            {props.idCardLogo ? (
                                <div>
                                    <img
                                        style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain", borderRadius: "10px 10px 0 0" }}
                                        alt="img"
                                        src={props.idCardLogo ? (props.idCardLogo.imageURL ?? "") : (props.school.attributes.logo && props.school.attributes.logo._url)}
                                        className="justify-content-center"
                                    />
                                </div>
                            ) : (
                                <div className="avatar-xs">
                                    <img
                                        alt="img"
                                        src={props.idCardLogo ? (props.idCardLogo.imageURL ?? "") : (props.school.attributes.logo && props.school.attributes.logo._url)}
                                        className="rounded avatar-xs"
                                    />
                                </div>
                            )}
                            {props.selectedHeaderTypeSetting == "logoAndName" && (
                                <div className="my-auto m-1">
                                    <label className="my-auto m-0" style={{ fontSize: (props.headerFontSize != undefined) ? (((props.headerFontSize * 0.25)) + "mm") : "" }}>{props.school.attributes.Name}</label>
                                </div>
                            )}
                        </div>

                    </Row>
                </div>
                <hr className="mb-0 ml-4 mr-4" style={{ marginTop: "-0.5mm" }}></hr>

                <div style={{ minHeight: props.selectedPrintSetting.kidDetailHeight, maxHeight: props.selectedPrintSetting.kidDetailHeight, overflow: "hidden" }}>

                    <div className="mt-1 ml-2 mb-0">
                        <Row className="m-0 p-0" lg={props.selectedPrintSetting.rowForKidDetails} md={props.selectedPrintSetting.rowForKidDetails} sm={props.selectedPrintSetting.rowForKidDetails} xs={props.selectedPrintSetting.rowForKidDetails}>
                            <Col lg={props.selectedPrintSetting.colForKidDetails} md={props.selectedPrintSetting.colForKidDetails} sm={props.selectedPrintSetting.colForKidDetails} xs={props.selectedPrintSetting.colForKidDetails}
                                className="pl-0 pr-2 text-center justify-content-center"
                            >
                                <div className={props.selectedPrintSetting.kidPhotoMarginTop} >
                                    <img src={props.teacher.attributes.Photo ? props.teacher.attributes.Photo._url : profileImg} alt="img" className="rounded text-center" style={{ height: props.selectedPrintSetting.kidPhotoHeight, width: props.selectedPrintSetting.kidPhotoHeight, objectFit: "contain" }} />
                                </div>
                                <p style={props.bodyFontSize != undefined ? getBodyFontSize(props.selectedPrintSetting.nameStyle) : props.selectedPrintSetting.nameStyle} className={props.selectedPrintSetting.marginTopForKidName + " mb-0 p-0"}>{<strong>{props.teacher.attributes.Name}</strong>}</p>
                                {<p style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "" }} className="m-0 p-0">{getDesignation(props.teacher)}</p>}
                                {props.teacher.attributes.bloodGroup && <p style={{ fontSize: "x-small" }} className="m-0 p-0">{props.teacher.attributes.bloodGroup}</p>}
                                {props.teacher.attributes.empCode && <p style={{ fontSize: "x-small" }} className="m-0 p-0">{props.teacher.attributes.empCode}</p>}
                                <div className="text-center" style={{ position: "relative", textAlign: "center", minHeight: props.selectedPrintSetting.qrCodeDivHeight, maxHeight: props.selectedPrintSetting.qrCodeDivHeight, overflow: "hidden" }}>
                                    <div className="" style={{
                                        position: "absolute", top: "50%"
                                        , left: "50%", margin: "0",
                                        transform: "translate(-50%, -50%)"
                                    }}>
                                        <img className="align-middle" src={QrCode} alt="img" style={{ minHeight: props.selectedPrintSetting.qrCodeDivHeight, maxHeight: props.selectedPrintSetting.qrCodeDivHeight }} />
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                </div>


                <div className="" style={{ minHeight: props.selectedPrintSetting.bottomFooterDivHeight, maxHeight: props.selectedPrintSetting.bottomFooterDivHeight, overflow: "hidden", position: "relative", paddingBottom: "2mm" }}>
                    <hr className="mb-0 ml-4 mr-4" style={{ marginTop: "0mm" }}></hr>
                    <center className="mt-0" >
                        <div className="text-center pl-2 pr-2">
                            <p className="p-0 m-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}><b>If found please return to</b></p>
                            <p className="m-0 p-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}>{props.school.attributes.Name}</p>
                            <p className="m-0 p-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}>
                                {/* {props.school.attributes.Address}, */}
                                {props.school.attributes.primaryPhoneNumber} <br /> {props.school.attributes.EMail}</p>
                        </div>
                    </center>
                </div>
            </div>

        );
    }

    const getLandScape = () => {
        return (
            <div style={{
                width: props.selectedPrintSetting.cardWidth,
                minHeight: props.selectedPrintSetting.cardHeight,
                backgroundColor: "white",
                border: props.isBorderOff ? "" : "1px solid lightGray",
                borderRadius: "10px",
            }}
                className="ml-3 dont-cut-element-when-printing"
            >
                <div style={{ minHeight: props.selectedPrintSetting.topHeaderDivHeight, maxHeight: props.selectedPrintSetting.topHeaderDivHeight, overflow: "hidden", paddingTop: "0mm" }}>

                    <Row className="mt-0">
                        <div
                            className={`d-flex mx-auto align-items-center justify-content-center ${props.idCardLogo ? "m-0" : "m-2"}`}
                            style={{
                                maxWidth: `${props.idCardLogo ? "100%" : "80%"}`,
                            }}
                        >
                            {props.idCardLogo ? (
                                <div>
                                    <img
                                        style={{ maxHeight: "100%", maxWidth: "100%", objectFit: "contain", borderRadius: "10px 10px 0 0" }}
                                        alt="img"
                                        src={props.idCardLogo ? (props.idCardLogo.imageURL ?? "") : (props.school.attributes.logo && props.school.attributes.logo._url)}
                                        className="justify-content-center"
                                    />
                                </div>
                            ) : (
                                <div className="">
                                    <img
                                        alt="img"
                                        src={props.idCardLogo ? (props.idCardLogo.imageURL ?? "") : (props.school.attributes.logo && props.school.attributes.logo._url)}
                                        className="rounded "
                                        style={{ height: props.selectedPrintSetting.schoolLogoHeight, width: props.selectedPrintSetting.schoolLogoHeight, objectFit: "contain", }}
                                    />
                                </div>
                            )}
                            {props.selectedHeaderTypeSetting == "logoAndName" && (
                                <div className="my-auto m-1">
                                    <label className="my-auto m-0" style={{ fontSize: (props.headerFontSize != undefined) ? (((props.headerFontSize * 0.25)) + "mm") : "" }}>{props.school.attributes.Name}</label>
                                </div>
                            )}
                        </div>

                    </Row>
                </div>
                <div className="d-flex align-items-center m-0 p-0"
                    style={{maxHeight: props.selectedPrintSetting.topTitleDivHeight}}

                >
                    <hr className="flex-grow-1 m-0 text-dark m-0" style={{ borderColor: 'black' }} />
                    <p
                        style={{
                            backgroundColor: 'black',
                            borderTopLeftRadius: '3mm',
                            borderBottomLeftRadius: '3mm',
                            borderTopRightRadius: '0px',
                            borderBottomRightRadius: '0px',
                            border: 'none',
                            paddingLeft: '2.5mm',
                            paddingRight: '2.5mm',
                            color: 'white',
                            maxHeight: props.selectedPrintSetting.topTitleDivHeight,
                        }}
                        className=" text-white m-0"
                    >
                        STAFF ID CARD
                    </p>
                </div>

                <div style={{ minHeight: props.selectedPrintSetting.kidDetailHeight, maxHeight: props.selectedPrintSetting.kidDetailHeight, overflow: "hidden" }}>

                    <div>
                        <Row className="m-0 p-0">
                            <Col lg={4} md={4} sm={4} xs={4} className="p-0 m-0">
                                <div className={props.selectedPrintSetting.kidPhotoMarginTop+"p-0 m-0" } style={{ marginLeft: '-2mm' }} >
                                    <img src={props.teacher.attributes.Photo ? props.teacher.attributes.Photo._url : profileImg} alt="img" className="rounded" style={{ height: props.selectedPrintSetting.kidPhotoHeight, width: props.selectedPrintSetting.kidPhotoHeight,objectFit: "contain",  }} />
                                </div>
                            </Col>
                            <Col lg={8} md={8} sm={8} xs={8} className={props.selectedPrintSetting.kidPhotoMarginTop+"p-0 m-0"}>
                                <p style={{fontSize: props.bodyFontSize !== undefined ? getBodyFontSize(props.selectedPrintSetting.nameStyle)
                                            : props.selectedPrintSetting.nameStyle,
                                            textAlign: "left",}}
                                    className={props.selectedPrintSetting.marginTopForKidName + " mb-0 p-0"}>
                                    <strong>{props.teacher.attributes.Name}</strong>
                                </p>
                                <Row className="p-0 m-0">
                                    <Col lg={7} md={7} sm={7} xs={7}className="p-0 m-0">
                                    <div className="d-flex flex-column align-items-start">
                                        {<p style={{ fontSize: (props.bodyFontSize != undefined) ? (((props.bodyFontSize * 0.25)) + "mm") : "" }} className="m-0 p-0">{getDesignation(props.teacher)}</p>}
                                        {props.teacher.attributes.bloodGroup && <p style={{ fontSize: "x-small" }} className="m-0 p-0">{props.teacher.attributes.bloodGroup}</p>}
                                        {props.teacher.attributes.empCode && <p style={{ fontSize: "x-small" }} className="m-0 p-0">{props.teacher.attributes.empCode}</p>}
                                    </div>
                                    </Col>
                                    <Col lg={5} md={5} sm ={5} xs={5} className="p-0 m-0">
                                        {props.layoutType == "QR" ?
                                            <img
                                                src={QrCode}
                                                alt="img"
                                                style={{
                                                    maxHeight: props.selectedPrintSetting.qrCodeDivHeight,
                                                }} />
                                            :
                                            <img
                                                src={props.school.attributes.logo && props.school.attributes.logo._url}
                                                alt="img"
                                                style={{
                                                    height: props.selectedPrintSetting.noQRLogoDivHeight,
                                                    opacity: 0.85,
                                                }} />
                                        }
                                    </Col>
                                </Row>
                                
                            </Col>
                            
                        </Row>
                    </div>
                </div>


                <div style={{ minHeight: props.selectedPrintSetting.bottomFooterDivHeight, maxHeight: props.selectedPrintSetting.bottomFooterDivHeight, overflow: "hidden", paddingBottom: "2mm" }}>
                    <hr className="m-0" style={{ borderColor: "black" }}></hr>

                    <Row>
                        <div className="text-end p-1 m-0" style={{ width: props.selectedPrintSetting.footerW1, borderRight: '1px solid', minHeight: props.selectedPrintSetting.bottomFooterDivHeight }} >
                            <p className="p-0 m-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}><b>IF FOUND PLEASE</b> <br></br> <b>RETURN TO</b></p>
                        </div>
                        <div className="text-start p-1 m-0" style={{ width: props.selectedPrintSetting.footerW2 }}>
                            <div>
                                <p className="m-0 p-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}>{props.school.attributes.Name}</p>
                                <p className="mb-2 p-0" style={{ fontSize: (props.footerFontSize != undefined) ? (((props.footerFontSize * 0.25)) + "mm") : "" }}>
                                    {/* {props.escort.school.attributes.Address}, */}
                                    {" "} {props.school.attributes.primaryPhoneNumber},
                                    {" "} {props.school.attributes.EMail}</p>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>

        );
    }
    
    return (
        <React.Fragment>
            {(props.orientaion == "P") ? getPotrite() : getLandScape()}
        </React.Fragment>

    );

};

const mapStatetoProps = (state) => { return {}; };


export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {

        })(StaffIdCardView)
    )
);