import * as actions from "./actionTypes";

const initialState = {
    courseRequests: [], initialLoding: true, addState: false,
};

const CourseRequest = (state = initialState, action) => {
    switch (action.type) {

        case actions.UPDATE_COURSE_REQUESTS:
            state = { ...state, courseRequests: action.payload.courseRequests };
            break;

        case actions.UPDATE_COURSE_REQUESTS_INITIAL_LOADING:
            if (action.payload.state) {
                state = { ...state, courseRequests: [], initialLoding: action.payload.state };
            } else {
                state = { ...state, initialLoding: action.payload.state };
            }
            break;
        case actions.UPDATE_COURSE_REQUESTS_ADD_STATE:
            state = { ...state, addState: action.payload.state };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default CourseRequest;
