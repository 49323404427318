import Parse from "parse";
import { printLog } from "./loghelper";

export const getLearningCategoryFromParse = (learningCategoryId,isLocal) => {
  printLog("HL: learningCategory_helper : getLearningCategoryFromParse")

  try {
    return new Promise((resolve, reject) => {
      var LearningCategory = Parse.Object.extend("LearningCategory");
      var query = new Parse.Query(LearningCategory);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.get(learningCategoryId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    })
  } catch (error) { }
}

export const deleteLearningCategoryFromParse = (learningCategory) => {
  printLog("HL: learningCategory_helper : deleteLearningCategoryFromParse")
  try {
    return new Promise((resolve, reject) => {
       
      learningCategory.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        learningCategory.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        learningCategory.set("deletedBy", Parse.User.current().id);
      }
  
      learningCategory.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getLearningCategoryForCurriculumId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningCategory_helper : getLearningCategoryForCurriculumId")
  try {
    return new Promise((resolve, reject) => {
      var LearningCategory = Parse.Object.extend("LearningCategory");
      var query = new Parse.Query(LearningCategory);
      query.equalTo("curriculumId", id);
      query.doesNotExist("cycleId");
      if (updatedDate && false) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
   
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
   
      query.ascending("order")
      query.addAscending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
   
export const getLearningCategoryForCycleId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningCategory_helper : getLearningCategoryForCycleId")
  try {
    return new Promise((resolve, reject) => {
      var LearningCategory = Parse.Object.extend("LearningCategory");
      var query = new Parse.Query(LearningCategory);
      query.equalTo("cycleId", id);
  
      if (updatedDate && false) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
   
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
   
      query.ascending("order")
      query.addAscending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
   
export const getLearningCategoryForCourseId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningCategory_helper : getLearningCategoryForCourseId")
  try {
    return new Promise((resolve, reject) => {
      var LearningCategory = Parse.Object.extend("LearningCategory");
      var query = new Parse.Query(LearningCategory);
      query.equalTo("courseId", id);
  
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
   
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
   
      query.ascending("order")
      query.addAscending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
  
export const getLearningCategoryForSchoolId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningCategory_helper : getLearningCategoryForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var LearningCategory = Parse.Object.extend("LearningCategory");
      var query = new Parse.Query(LearningCategory);
      query.equalTo("schoolId", id);
  
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
  
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
  
      query.ascending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const saveLearningCategory = (object) => {
  printLog("HL: learningCategory_helper : saveLearningCategory")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

export const saveLearningCategoryObjects = (objects) => {
  printLog("HL: learningCategory_helper : saveLearningCategoryObjects")
  try {
      	
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
}
  
export const getLearningCategoriesForIds = (ids) => {
  try {

    return new Promise((resolve, reject) => {
      var LearningCategory = Parse.Object.extend("LearningCategory");
      var query = new Parse.Query(LearningCategory);

      query.containedIn("objectId", ids)
      query.find().then(
        (result) => {
          resolve(result);
          // console.log(result)
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}