import { REGISTER_USER, REGISTER_USER_SUCCESSFUL, REGISTER_USER_FAILED, REGISTER_LOADING } from './actionTypes';

export const registerUser = (user,appName) => {
    return {
        type: REGISTER_USER,
        payload: { user ,appName}
    }
}

export const registerUserSuccessful = (user) => {
    return {
        type: REGISTER_USER_SUCCESSFUL,
        payload: user
    }
}

export const registerUserFailed = (error) => {
    return {
        type: REGISTER_USER_FAILED,
        payload: {error}
    }
}

export const setRegisterLoading = (state) => {
    return {
        type: REGISTER_LOADING,
        payload: {state}
    }
}