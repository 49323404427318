import Parse from "parse";
import school from "../../store/model/school/reducer";
import { printLog } from "./loghelper";
const getClassRoomFromParse = (classRoomId) => {
  printLog("HL: classroom_helper : getClassRoomFromParse")

  try {
    return new Promise((resolve, reject) => {
      var ClassRoom = Parse.Object.extend("ClassRoom");
      var query = new Parse.Query(ClassRoom);
      query.get(classRoomId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const checkForDaycareParse = (schoolId,classLevel) => {
  printLog("HL: classroom_helper : checkForDaycareParse")
  try {
    return new Promise((resolve, reject) => {
      var ClassRoom = Parse.Object.extend("ClassRoom");
      var query = new Parse.Query(ClassRoom);
      query.equalTo("SchoolID", schoolId);
      query.equalTo("classroomType", 2);
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          if (result && result.length > 0) {
            resolve(null)
          } else {
            let ClassRoom = Parse.Object.extend('ClassRoom')
            let cr = new ClassRoom()
            cr.set('Name', "Daycare")
            cr.set('classLevel', classLevel)
            cr.set('classroomType', 2)
            cr.set('SchoolID', schoolId)
            cr.save().then((result) => {
              resolve(result)
            }, (error) => {
              console.log("CLASS: " + error);
              resolve(null);
            });
          }
          
        },
        (error) => {
          reject(null);
        }
      );
    }
    )
} catch (error) { }
}


const deleteClassRoomFromParse = (classRoomId) => {
  printLog("HL: classroom_helper : deleteClassRoomFromParse")
  try {
    return new Promise((resolve, reject) => {
      const ClassRoom = Parse.Object.extend("ClassRoom");
      var classRoom = new ClassRoom();
      classRoom.id = classRoomId;
      classRoom.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        classRoom.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        classRoom.set("deletedBy", Parse.User.current().id);
      }

      classRoom.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};
export const getClassroomForSchoolIdsParse = schoolIds =>{
  printLog("HL: classroom_helper : getClassroomForSchoolIdsParse")
  try{
    return new Promise((resolve, rejected)=>{
      let query = new Parse.Query('ClassRoom')
      query.containedIn("SchoolID", schoolIds)
      query.notEqualTo('isDeleted', true)
      query.limit(1000)
      query.find().then((result)=>{
        resolve(result)
      })
    })
  }catch(error){}
}
export const createNewClassRoom = classDetails =>{
  printLog("HL: classroom_helper : createNewClassRoom")
  try{
    return new Promise((resolve, reject)=>{
      let ClassRoom = Parse.Object.extend('ClassRoom')
      let cr = new ClassRoom()
      cr.set('Name', classDetails.name)
      cr.set('classLevel', classDetails.level)
      cr.set('SchoolID', classDetails.schoolId);
      if (classDetails.classroomType === 0) {
        cr.unset('classroomType');
      } else {
        cr.set('classroomType', classDetails.classroomType);
      }
      
      cr.save().then((result) => {
        resolve(result)
      }, (error) => {
        console.log("CLASS: " + error);
        reject(error);
      });
    })
  } catch (error) {
    console.log("CLASS-catch: " + error);
  }
}
const getClassroomsForSchoolId = (id ,updatedDate,isLoacal) => {
  printLog("HL: classroom_helper : getClassroomsForSchoolId")
  try {

    return new Promise((resolve, reject) => {
      var ClassRoom = Parse.Object.extend("ClassRoom");
      var query = new Parse.Query(ClassRoom);
      query.equalTo("SchoolID", id)
      
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate))
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
      }
      
      // query.notEqualTo("isDeleted", true);
      query.ascending("Name");
      query.find().then(
          (results) => {
            let result = []
            for(let i in results){
              if(results[i].attributes.classroomType === 2){
                result.push(results[i])
              }
            }
            for(let i in results){
              if(results[i].attributes.classroomType !== 2){
                result.push(results[i])
              }
            }
            resolve(result);
          },
          (error) => {
            resolve(error);
          }
        );
  });	
} catch (error) { }
}

const getClassRoomforGivenIds = (classRoomIds) => {
  printLog("HL: classroom_helper : getClassRoomforGivenIds")
  try {
    return new Promise((resolve, reject) => {
      var ClassRoom = Parse.Object.extend("ClassRoom");
      var query = new Parse.Query(ClassRoom);
      query.containedIn("objectId", classRoomIds);
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};


const getDaycareClassforSchoolId = (schoolId) => {
  printLog("HL: classroom_helper : getDaycareClassforSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var ClassRoom = Parse.Object.extend("ClassRoom");
      var query = new Parse.Query(ClassRoom);
      query.equalTo("SchoolId", schoolId);
      query.equalTo("classroomType", 2);
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const updateAClassroomObj = classObj =>{
  printLog("HL: classroom_helper : updateAClassroomObje")
  try{
    return new Promise((resolve, reject)=>{
      classObj.save().then((result)=>{
        resolve(result)
      })
    })
  }catch(error){}
}


export { getClassRoomFromParse, deleteClassRoomFromParse,getClassroomsForSchoolId,checkForDaycareParse };
