import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Label,
    Button,
    Table,
    Card,
    CardBody,
    Media, FormGroup,
    Input
} from "reactstrap";
import { matchPath, withRouter } from "react-router-dom/cjs/react-router-dom.min";
import Moment from 'moment';
import { Link } from "react-router-dom";
import * as Constants from "../../Constents";
import Parse from "parse";
import { withNamespaces } from "react-i18next";
import UploadFiles from "./UploadFileForSchoolDocuments";
import DocumentViewerModal from "../Messaging/DocumentViewerModal";
import {

} from "../../store/actions";
import Editable from "react-bootstrap-editable";

const SchoolDocuments = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/franchise-school-documents/:franchiseId/:schoolId",
        exact: true,
        strict: false,
    });
    const franchiseId = match.params.franchiseId;
    const schoolId = match.params.schoolId;
    const [isLoading, setIsLoading] = useState(true);
    const [displayList, setDisplayList] = useState([]);
    const [schoolObject, setSchoolObject] = useState();
    const [clickedFile, setClickedFile] = useState();

    useEffect(() => {
        if (franchiseId != null && franchiseId.length > 0 && schoolObject) {
            getSchoolDocParams(franchiseId)
        }
    }, [franchiseId, schoolObject])

    useEffect(() => {
        if (schoolId != null && schoolId.length > 0) {
            getSchoolObject(schoolId)
        }
    }, [schoolId])

    const getSchoolObject = () => {
        var query = new Parse.Query('School');
        query.equalTo('objectId', schoolId);
        query.limit(1);
        query.find().then((results) => {
            if (results && results.length > 0) {
                setSchoolObject(results[0])
            }
        })
    }

    const getSchoolDocParams = (franchiseId) => {
        var query = new Parse.Query('SchoolDocumentParams');
        query.equalTo('franchiseId', franchiseId);
        query.limit(1000);
        query.ascending("order")
        query.find().then((results) => {
            if (results && results.length > 0) {
                let documentIds = []
                let tempList = []
                for (const result of results) {
                    let temp = {}
                    temp.schoolDoc = undefined
                    temp.schoolDocumentParam = result
                    tempList.push(temp)
                    documentIds.push(result.id)
                }
                getSchoolDocuments(franchiseId, documentIds, tempList)
            } else {
                setIsLoading(false)
            }
        })
    }
    const getSchoolDocuments = (franchiseId, documentIds, list) => {
        var query = new Parse.Query('SchoolDocuments');
        query.equalTo('franchiseId', franchiseId);
        query.equalTo('schoolId', schoolId);
        query.containedIn("documentId", documentIds);
        query.notEqualTo("isDeleted", true)
        query.limit(1000);
        query.find().then((results) => {
            if (results) {
                for (const item of list) {
                    for (const result of results) {
                        if (item.schoolDocumentParam.id === result.attributes.documentId) {
                            item.schoolDoc = result.attributes.file
                            item.schoolDocObject = result
                        }
                    }
                }
                setDisplayList(list)
            }
            setIsLoading(false)
        })
    }

    const saveDocuments = (answer, item) => {
        const SchoolDocuments = Parse.Object.extend('SchoolDocuments');
        var schoolDocuments = new SchoolDocuments();
        if (item.schoolDocObject) {
            schoolDocuments = item.schoolDocObject
        } else {
            schoolDocuments.set("documentId", item.schoolDocumentParam.id);
            schoolDocuments.set("documentName", item.schoolDocumentParam.attributes.documentName ?? "")
            schoolDocuments.set("schoolId", schoolId)
            schoolDocuments.set("franchiseId", franchiseId)
        }

        if (item.schoolDocumentParam.attributes.questionType == Constants.QESTION_TEXT_SINGLE_LINE ||
            item.schoolDocumentParam.attributes.questionType == Constants.QESTION_TEXT_MULTI_LINE ||
            item.schoolDocumentParam.attributes.questionType == Constants.QUESTION_EMAIL ||
            item.schoolDocumentParam.attributes.questionType == Constants.QUESTION_PHONE_NUMBER ||
            item.schoolDocumentParam.attributes.questionType == Constants.QESTION_NUMBER) {
            schoolDocuments.set("answer", answer + "");
        } else {
            schoolDocuments.set("answerDate", answer)
        }

        schoolDocuments.save().then(
            (result) => {
                console.log(result)
            },
            (error) => {

            }
        );
    }


    const componentForQuestionType = (questionType, item, key) => {
        switch (questionType) {
            case Constants.QESTION_UPLOAD_DOCUMENT:
                return (
                    <UploadFiles
                        fileUploadKey={item.schoolDocumentParam.attributes.documentName + key}
                        initialFile={item.schoolDoc ? item.schoolDoc : undefined}
                        fileName={item.schoolDocumentParam.attributes.documentName + "_" + schoolObject.attributes.Name}
                        schoolId={schoolId}
                        onClickViewDoc={(clickedFile) => { setClickedFile(clickedFile) }}
                        schoolDocObject={item.schoolDocObject}
                        franchiseId={franchiseId}
                        documentParam={item.schoolDocumentParam}
                        onFileChange={(file, result) => {
                            let tempList = [...displayList];
                            if (file === undefined) {
                                tempList[key].schoolDocObject = undefined;
                                tempList[key].schoolDoc = undefined;
                            } else {
                                tempList[key].schoolDoc = file.file;
                                tempList[key].schoolDocObject = result;
                            }
                            setDisplayList(tempList);
                        }}
                        showIcon={false}
                    />
                );

            case Constants.QESTION_TEXT_SINGLE_LINE:
                return (
                    <FormGroup key={key}>
                        <Editable
                            className={'d-flex'}
                            onValidated={(value) => {
                                saveDocuments(value, item)
                            }
                            }
                            editText={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? " ✎" : "No value ✎"}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            initialValue={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer : ""}
                            showText
                            type="textfield"
                            validate={(value) => {
                                if (!value) {
                                    return props.t("You have entered an invalid admission number!")
                                }
                            }}
                        />
                    </FormGroup>
                );

            case Constants.QESTION_TEXT_MULTI_LINE:
                return (
                    <FormGroup key={key}>
                        <Editable
                            className={'d-flex'}
                            onValidated={(value) => {
                                saveDocuments(value, item)
                            }}
                            // disabled={isProfileDeleted}
                            editText={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? " ✎" : "No value ✎"}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            initialValue={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer : ""}
                            showText
                            type="textfield"
                            validate={(value) => {
                                if (!value) {
                                    return props.t("You have entered an invalid admission number!")
                                }
                            }}
                        />
                    </FormGroup>
                );

            case Constants.QESTION_NUMBER:
                return (
                    <FormGroup key={key}>
                        <Editable
                            className={'d-flex'}
                            onValidated={(value) => {
                                saveDocuments(value, item)
                            }}
                            // disabled={isProfileDeleted}
                            editText={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? " ✎" : "No value ✎"}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            initialValue={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer : ""}
                            showText
                            type="textfield"
                            validate={(value) => {
                                if (!value) {
                                    return props.t("You have entered an invalid admission number!")
                                }
                            }}
                        />
                    </FormGroup>
                );

            case Constants.QESTION_DATE:
                const maxDateString = Moment().format("YYYY-MM-DD");
                return (
                    <FormGroup key={key}>
                        <Editable
                            className={'d-flex'}
                            onValidated={(value) => {
                                saveDocuments(value, item)
                            }}
                            // disabled={isProfileDeleted}
                            editText={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answerDate ? " ✎" : "No value ✎"}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            initialValue={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answerDate ? item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answerDate : undefined}
                            showText
                            type="date"
                            validate={(value) => {
                                if (!value) {
                                    return props.t("You have entered an invalid DOB!")
                                }
                            }}
                        />
                    </FormGroup>
                );

            case Constants.QUESTION_EMAIL:
                return (
                    <FormGroup key={key}>
                        <Editable
                            className={'d-flex'}
                            onValidated={(value) => {
                                saveDocuments(value, item)
                            }}
                            // disabled={isProfileDeleted}
                            editText={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? " ✎" : "No value ✎"}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            initialValue={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer : ""}
                            showText
                            type="textfield"
                            validate={(value) => {
                                if (!value) {
                                    return props.t("You have entered an invalid admission number!")
                                }
                            }}
                        />
                    </FormGroup>
                );

            case Constants.QUESTION_PHONE_NUMBER:
                return (
                    <FormGroup key={key}>
                        <Editable
                            className={'d-flex'}
                            onValidated={(value) => {
                                saveDocuments(value, item)
                            }}
                            // disabled={isProfileDeleted}
                            editText={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? " ✎" : "No value ✎"}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            initialValue={item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer ? item && item.schoolDocObject && item.schoolDocObject.attributes && item.schoolDocObject.attributes.answer : ""}
                            showText
                            type="textfield"
                            validate={(value) => {
                                if (!value) {
                                    return props.t("You have entered an invalid admission number!")
                                }
                            }}
                        />
                    </FormGroup>
                );

            default:
                return null;
        }
    };

    return (
        <React.Fragment>
            {!isLoading && (
                <div className="page-content">
                    <Row className='d-flex align-items-start'>
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <h4>{"School Documents"}</h4>
                            </div>
                        </Col>
                    </Row>
                    {schoolObject && <Card>
                        <CardBody>
                            <Row>
                                <Col lg={7}>
                                    <Row className="d-flex justify-content-start">
                                        <Col lg={2}>
                                            {schoolObject.attributes.logo != undefined ? (
                                                <div className="avatar-md  mr-4 mt-0">
                                                    <img
                                                        src={
                                                            schoolObject.attributes.logo == undefined
                                                                ? logo
                                                                : schoolObject.attributes.logo._url
                                                        }
                                                        alt=""
                                                        className="img-thumbnail rounded-circle"
                                                    />
                                                </div>
                                            ) : (
                                                <div className="avatar-md mr-4">
                                                    <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                                        <div style={{ fontSize: "48px" }}>
                                                            <i className="bx bx-buildings"></i>
                                                        </div>
                                                    </span>
                                                </div>
                                            )}
                                        </Col>
                                        <Col className="d-flex justify-content-start" lg={10}>
                                            <Media className="overflow-hidden" body>
                                                <h5 className="text-truncate font-size-15 mt-3">
                                                    {schoolObject.attributes.Name}
                                                </h5>
                                                <p className="text-muted mb-0">
                                                    <span>{schoolObject.attributes.locality && schoolObject.attributes.locality + ", "}</span>
                                                    <span>{schoolObject.attributes.city}</span>
                                                    {schoolObject.attributes.areaCode && <span>
                                                        {", " + Constants.stateNameCode[schoolObject.attributes.areaCode]}
                                                    </span>
                                                    }
                                                </p>
                                            </Media>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>}
                    <Card>
                        <CardBody>
                            <div className="table-responsive">
                                <Table className="table mb-0 table-bordered">
                                    <tbody>
                                        {
                                            displayList.map((item, key) => (
                                                <tr key={key} style={{ width: "30%" }}>
                                                    <th scope="row" style={{ width: "30%" }}>
                                                        {item.schoolDocumentParam.attributes.documentName ?? ""}
                                                    </th>
                                                    <td>
                                                        <div>
                                                            {componentForQuestionType(item.schoolDocumentParam.attributes.questionType, item, key)}
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))
                                        }
                                    </tbody>
                                </Table>
                            </div>
                        </CardBody>
                    </Card>
                    <DocumentViewerModal
                        clickedFile={clickedFile}
                        closeModal={() => {
                            setClickedFile()
                        }}
                    ></DocumentViewerModal>
                </div>
            )}
            {isLoading && (
                <Row className="page-content">
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Link to="#" className="text-success">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                Loading School Documents...{" "}
                            </Link>
                        </div>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};

export default withRouter(withNamespaces()(SchoolDocuments));