import Parse from "parse";
import { printLog } from "./loghelper";


export const getExpenseCategoryForSchoolId = (id, instituteId, updatedDate, isLoacal) => {
    printLog("HL: expenseCategory_helper : getExpenseCategoryForSchoolId")

    try {
        return new Promise((resolve, reject) => {
            var ExpenseCategory = Parse.Object.extend("ExpenseCategory");
           
            var query1 = new Parse.Query(ExpenseCategory);
            query1.equalTo("schoolId", id);
           
            var query2 = new Parse.Query(ExpenseCategory);
            query2.equalTo("instituteId", instituteId);
            query2.doesNotExist("schoolId");
      
            var query3 = new Parse.Query(ExpenseCategory);
            query3.equalTo("isDefault", true);

            var mainQuery = Parse.Query.or(query1, query3);
            if (instituteId) {
                mainQuery = Parse.Query.or(query1, query2, query3);
            }
            if (isLoacal) {
                mainQuery.fromLocalDatastore();
                mainQuery.notEqualTo("isDeleted", true);
            }
            if (updatedDate) {
                mainQuery.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
            } else {
                mainQuery.notEqualTo("isDeleted", true);
            }

            mainQuery.descending("createdAt");
            mainQuery.limit(1000)
            mainQuery.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            );
        });
    } catch (error) { }
};


export const editExpenseCategory = (object, isInstitute) => {
    printLog("HL: expenseCategory_helper : editExpenseCategory")
    try {
        return new Promise((resolve, reject) => {
            object.save().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve("Error");
                }
            );
        });
    } catch (error) { }
};
