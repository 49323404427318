import * as actions from './actionTypes';

export const getNotifactionForUser = () => {
    return {
        type: actions.GET_NOTIFACTIONS_FOR_USER,
        payload: { }
    }
}

export const setNotifactionForUser = notifactionObj =>{
    return{
        type: actions.SET_NOTIFACTIONS_FOR_USER,
        payload: { notifactionObj }
    }
}

export const updateNotificationStatus = id =>{
    return{
        type: actions.UPDATE_NOTIFICATION_STATUS,
        payload: { id }
    }
}