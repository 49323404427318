import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import {
    Label, FormGroup, Row, Col, Card, CardBody, Collapse, Form, Button, UncontrolledTooltip, Badge, Modal, ModalFooter, ModalBody, ModalHeader, NavItem, NavLink, Progress, TabContent,
    TabPane, Nav, Input, ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem
} from "reactstrap";
import { AvForm, AvField } from "availity-reactstrap-validation";
import Parse from 'parse';
import TopBar from './Topbar';
import { withNamespaces } from "react-i18next";
import { Element, animateScroll as scroller } from 'react-scroll'
import SweetAlert from "react-bootstrap-sweetalert";
import * as CommonFunctions from "../../components/Common/CommonFunctions";
import {
    setSelecetdSchool, setSelectedCurriculum,
    getLearningSubcategoryForCategoryId, getLearningActivitiesForSubcategoryIds, addLearningSubcategory,
    addLearningActivity, setLearningActivityLoadingState, getSelectedLearningCategory, deleteLearningSubcategory,
    deleteLearningActivity, reorderSubCatrgories, reorderActivities, getClassroomsForSchoolId, getAllKids

} from '../../store/actions';
import DragDropTables from './DragDropTables';
import StudentSelectionModal from './Publish/StudentSelectionModal';
import classnames from "classnames";
import toastr from "toastr";
import { v4 as uuidv4 } from "uuid";
import Compressor from 'compressorjs';
import { IMAGE_COMPRESSION_RATE } from "../../Constents";

const SubcategoryList = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/lms-chapter/:schoolId/:curriculumId/:cycleId/:subjectId',
        exact: true,
        strict: false,

    });

    const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31];
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,];
    const curriculumId = match.params.curriculumId;
    const schoolId = match.params.schoolId;
    const cycleId = match.params.cycleId;
    const subjectId = match.params.subjectId;

    const [subcategoryIds, setSubcategoryIds] = useState([]);
    const [loading, setLoading] = useState(true);
    const [selectedSubjectName, setSelectedSubjectName] = useState(undefined);
    const [editSubcategoryObject, setEditSubcategory] = useState(undefined)

    const [deleteSubcategoryObject, setDeleteSubcategoryObject] = useState(undefined);;
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [deleteWarning, setDeleteWarning] = useState(false);

    const [editActivity, setEditActivity] = useState(undefined);
    const [deleteActivity, setDeleteActivity] = useState(undefined)

    const [selectedTab, setSelectedTab] = useState("subcategoryList");
    const [hideButton, setHideButton] = useState(true);

    const [pageName, setPageName] = useState("/lms-chapter-content/");

    const [activeTab, setactiveTab] = useState(0);
    const [addStudentPopupActiveTab, setAddStudentPopupActiveTab] = useState(0);
    const [addStudentPopupProgressValue, setAddStudentPopupProgressValue] = useState(50.00);
    const [showStudentSelectionModal, setShowStudentSelectionModal] = useState(false);
    const [kidPickerNextButtonClick, setKidPickerNextButtonClick] = useState(false);
    const [selectedKidsFromPickerView, setSelectedKidsFromPickerView] = useState([]);
    const [description, setDescription] = useState(undefined);
    const [dropDownOpen, setDropDownOpen] = useState(false)
    const [publishType, setPublishType] = useState()
    const [publishActivity, setPublishActivity] = useState()

    const [progressText, setProgressText] = useState();
    const [albumImageList, setAlbumImageList] = useState([]);
    const [albumUUIDId, setAlbumUUIDId] = useState();
    const [imagesList, setImagesList] = useState();
    const [currentIndexForUpload, setCurrentIndexForUpload] = useState(0);

    const next = "NEXT";
    const previous = "PREVIOUS";

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.getClassroomsForSchoolId(schoolId);
        props.getAllKids(schoolId);
    }, [schoolId])

    useEffect(() => {
        props.getLearningSubcategoryForCategoryId(subjectId);
        props.getSelectedLearningCategory(subjectId);
    }, [subjectId]);

    useEffect(() => {
        if (!props.selectedCurriculum || props.selectedCurriculum.id !== curriculumId) {
            props.setSelectedCurriculum(curriculumId);
        }
    }, [curriculumId]);

    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setCanEdit(CommonFunctions.canEdit(props.selectedCurriculum, props.userTeacher));
        if (props.selectedCurriculum && props.selectedCurriculum.attributes.accessType == 2) {
            setPageName("/lms-chapter-content2/")
        }
    }, [props.selectedCurriculum, props.userTeacher]);


    useEffect(() => {

        let ids = [...subcategoryIds];
        let change = false;
        if (props.learningSubcategories) {
            setHideButton(false);
            for (const i in props.learningSubcategories) {
                if (!ids.includes(props.learningSubcategories[i].id) && props.learningSubcategories[i].attributes.categoryId === subjectId) {
                    ids.push(props.learningSubcategories[i].id);
                    change = true;
                }

            }
        }
        if (change && !props.lscReplace) {
            setSubcategoryIds(ids);
        }

        if (subjectId) {
            if (props.subcategoryState === "responce") {
                props.getLearningActivitiesForSubcategoryIds(ids, subjectId);
            }
        }



    }, [props.subcategoryState]);

    useEffect(() => {
        if (props.selectedLearningCategory && props.selectedLearningCategory.id === subjectId) {
            setSelectedSubjectName(props.selectedLearningCategory.attributes.name);
        }

    }, [props.selectedLearningCategory]);


    const [newSubcategory, setNewSubcategory] = useState(undefined);
    const [showNewChapter, setShowNewChapter] = useState(false);

    const [newActivity, setNewActivity] = useState(undefined);
    const [newActivityDescription, setNewActivityDescription] = useState(undefined);
    const [newActivityDay, setNewActivityDay] = useState(1);
    const [newActivityMonth, setNewActivityMonth] = useState(1);
    const [showNewTopic, setShowNewTopic] = useState(undefined);

    const [tab, setTab] = useState(0);
    const [reorderTopic, setReorderTopic] = useState(undefined);



    function getActivitiesForSubcategoryId(subcategoryId) {
        if (subcategoryId === "CommonTopics") {
            return getCoomomActivities();
        } else if (props.learningActivities) {
            var list = [];
            for (const i in props.learningActivities) {
                if (props.learningActivities[i].attributes.subcategoryId === subcategoryId) {
                    list.push(props.learningActivities[i]);
                }
            }
            return list;
        }
        return [];
    }

    function getCoomomActivities() {
        if (props.learningActivities) {
            var list = [];
            for (const i in props.learningActivities) {
                if (!props.learningActivities[i].attributes.subcategoryId || props.learningActivities[i].attributes.subcategoryId.length === 0) {
                    list.push(props.learningActivities[i]);
                }
            }

            return list;
        }
        return [];
    }


    function handelAddChapter() {
        setShowNewChapter(true);
        scroller.scrollTo('AddChapter', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 0,
        });
    }

    function handelAddTopic(id) {
        setShowNewTopic(id);
        setNewActivityMonth(1);
        setNewActivityDay(1);
        props.setLearningActivityLoadingState(false);
        scroller.scrollTo('AddTopic' + id, {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -200,
        });
    }

    function getSubcategoryList() {

        if (props.subcategoryState === "responce") {
            let list = [...props.learningSubcategories];
            list.push({ id: "CommonTopics" });
            return list;
        } else {
            return [];
        }

    }

    function handelAddSubcategory(name) {
        var LearningSubcategory = Parse.Object.extend("LearningSubcategory");
        var learningSubcategory = new LearningSubcategory();
        learningSubcategory.set("name", name);
        learningSubcategory.set("curriculumId", curriculumId)
        learningSubcategory.set("categoryId", subjectId);
        learningSubcategory.set("order", props.learningSubcategory ? props.learningSubcategory.length : 0);
        props.addLearningSubcategory(learningSubcategory);
    }

    function handelAddActivity(name, subcategoryId, activity) {
        var LearningActivity = Parse.Object.extend("LearningActivity");
        var learningActivity = new LearningActivity();
        if (activity) {
            var learningActivity = activity;
        } else {
            let list = getActivitiesForSubcategoryId(subcategoryId);
            if (list && list.length > 0) {
                learningActivity.set("orderBy", parseInt(list.length));
                learningActivity.set("order", list.length + "");
            } else {
                learningActivity.set("orderBy", 0);
                learningActivity.set("order", "0");
            }
        }

        learningActivity.set("name", name);
        learningActivity.set("curriculumId", curriculumId)
        learningActivity.set("category", subjectId);
        if (subcategoryId && subcategoryId !== "CommonTopics") {
            learningActivity.set("subcategoryId", subcategoryId);
        }

        if (newActivityDay) {
            learningActivity.set("day", parseInt(newActivityDay));
        }
        if (newActivityMonth) {
            learningActivity.set("month", parseInt(newActivityMonth));
        }

        learningActivity.set("description", newActivityDescription);

        props.addLearningActivity(learningActivity);

        setEditActivity(undefined);
        setNewActivityDay(undefined);
        setNewActivityMonth(undefined);
        setNewActivityDescription(undefined);

    }
    useEffect(() => {
        if (!props.loadingState) {
            setShowNewTopic(undefined);
            setNewActivity("");
            setNewActivityDescription("");
        }

    }, [props.loadingState]);

    function handelOnClickBack() {
        // props.onClickBack();
        if (selectedTab === "rearrange") {
            setSelectedTab("subcategoryList");
            setHideButton(false);
        } else {
            window.history.back();
        }
    }

    const handelSort = (list) => {

        var tempList = [];
        for (var i in list) {
            // if (parseInt(i) !== list[i].attributes.order) {
            list[i].set("order", parseInt(i));
            tempList.push(list[i]);
            // }  
        }
        props.reorderSubCatrgories(tempList, "order updated successfully");
        setSelectedTab("subcategoryList");
        setHideButton(false);
    }

    const handelSortActivities = (list) => {
        var tempList = [];
        for (var i in list) {
            // if (parseInt(i) !== list[i].attributes.order) {
            list[i].set("orderBy", parseInt(i));
            tempList.push(list[i]);
            // }  
        }
        props.reorderActivities(tempList, subjectId, subcategoryIds, "order updated successfully");
        setReorderTopic(undefined);
    }
    const handelCloseActivitySort = () => {
        setReorderTopic(undefined);
    }
    const handelReorder = () => {
        setSelectedTab("rearrange")
        setHideButton(true);
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    function getFileTypeImage(fileExtension) {
        if (fileExtension === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtension === "ppt" || fileExtension === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "doc" || fileExtension === "docx") {
            return "bx bxs-file-doc";
        }
        return "bx bxs-file-blank";
    }

    const resetAddStudentPopup = () => {
        setAddStudentPopupActiveTab(0)
        setSelectedKidsFromPickerView([])
        setAddStudentPopupProgressValue(50.00)
        setAlbumUUIDId(undefined)
        setDescription(undefined)
    }

    const changeAddStudentPopupProgress = (tab, changeTab = true) => {
        if (addStudentPopupActiveTab !== tab) {
            if (tab >= 0 && tab <= 1) {
                if (changeTab) {
                    setAddStudentPopupActiveTab(tab);
                }
                var tempRatio = 100 / 2;
                var tempMultiplier = tab + 1;
                var tempProgress = tempMultiplier * tempRatio;
                setAddStudentPopupProgressValue(tempProgress);
            }
        }
    }

    const addStudentPopupToggleTab = (tab, choice) => {
        if (tab !== addStudentPopupActiveTab) {
            if (tab - addStudentPopupActiveTab === 1 || tab - addStudentPopupActiveTab === -1) {
            } else {
                if (choice === previous) {
                    tab = addStudentPopupActiveTab - 1;
                }
            }
            changeAddStudentPopupProgress(tab)
        }
    };

    const clearAllAttachments = () => {
        setCurrentIndexForUpload(0)
        setAlbumImageList([])
    }

    const getAttachmentsUI = () => {
        return (
            <>
                {progressText ? <label style={{ fontSize: "4mm" }} className=" mb-1 text-center mt-2">{progressText}<i className='align-middle font-size-22 ml-2 text-dark bx bx-loader bx-spin'></i></label> :
                    <>
                        {albumImageList && albumImageList.length > 0 &&
                            <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                                {`${albumImageList.length} images attached`}
                                <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                                    setAlbumImageList([])
                                }}></i>
                            </div>
                        }
                    </>}</>
        )
    }

    function handleImageUploadChange(e) {
        let tempFileList = [];
        console.log(e.target.files.length);
        for (const i in e.target.files) {

            if (e.target.files.length > i) {
                let tempFile = e.target.files[i];
                if (tempFile && tempFile.name) {
                    let tempfileExtension = tempFile.name.split(".");
                    let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
                    let newFile = {
                        id: "fiele_" + i,
                        file: tempFile,
                        type: "Image",
                        downloadeble: true,
                        actualFileName: tempFile.name,
                        name: tempfileExtension[0],
                        extention: tempExt,
                        extentionIcon: getFileTypeImage(tempExt),
                        preview: URL.createObjectURL(tempFile)
                    }
                    tempFileList.push(newFile);
                }
            }
        }
        setImagesList(tempFileList);
    }
    // console.log(imagesList)
    useEffect(() => {
        if (currentIndexForUpload >= 0 && imagesList) {
            if (imagesList.length > currentIndexForUpload) {
                onImageListUpload(imagesList[currentIndexForUpload], currentIndexForUpload);
            } else {
                setCurrentIndexForUpload(-1);
                setProgressText()
                setImagesList([]);
            }
        }
    }, [imagesList, currentIndexForUpload]);

    function onImageListUpload(file, count) {
        setProgressText(props.t('Uploading ') + (count + 1) + " of " + imagesList.length)
        new Compressor(file.file, {
            quality: IMAGE_COMPRESSION_RATE, // 0.6 can also be used, but its not recommended to go below.
            success: (compressedResult) => {
                convertImageToBase64(file, count, compressedResult)
            },
        });
    }

    function convertImageToBase64(file, count, compressedResult) {
        var albumId = albumUUIDId ?? uuidv4()
        setAlbumUUIDId(albumId)
        var name = `file`;
        if (file.type === "Image") {
            name = name + "." + file.extention;
        }
        getBase64(compressedResult, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setProgressText(`${props.t('Uploading ') + (count + 1) + " of " + imagesList.length + " Images "} ( ${(value * 100).toFixed(2)}% )`)
                    }
                }
            }).then(
                result => {
                    let parseImagesList = [...albumImageList]
                    var Files = Parse.Object.extend("Album");
                    var fileObject = new Files();
                    fileObject.set("albumId", albumId);
                    fileObject.set("image", result);
                    fileObject.set("schoolId", schoolId)
                    parseImagesList.push(fileObject)
                    setAlbumImageList(parseImagesList)
                    fileObject.save();
                    if (imagesList.length > currentIndexForUpload) {
                        setCurrentIndexForUpload((currentIndexForUpload + 1))
                    } else {
                        setCurrentIndexForUpload(-1);
                        setProgressText()
                        setImagesList([]);
                    }
                },
                error => {
                    setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                }
            );
        });
    }


    const handlePublish = (selectedKids) => {

        let recipientIds = [];
        let classroomIds = []
        for (const kid of selectedKids) {
            recipientIds.push(kid.id);
            if (!classroomIds.includes(kid.attributes.ClassRoomID)) {
                classroomIds.push(kid.attributes.ClassRoomID)
            }
        }

        if (classroomIds.length == 1) {

            let ckids = props.kids.filter(k => k.attributes.ClassRoomID == classroomIds[0])

            if (ckids.length != recipientIds.length) {
                classroomIds = [];
            }
        } else {
            classroomIds = [];
        }

        let tableName = "LearningSession"
        if (publishType == 2) {
            tableName = "OnlineLearningSession"
        }
        const LearningSession = Parse.Object.extend(tableName);
        var session = new LearningSession();

        session.set("activityId", publishActivity.id);
        session.set("categoryId", publishActivity.attributes.category);
        session.set("schoolId", schoolId);
        session.set("recipients", recipientIds);
        session.set("createdBy", Parse.User.current().attributes.teacherId);
        if (publishType == 1) {
            session.set("description", description);
            if (albumImageList && albumImageList.length > 0) {
                session.set("albumId", albumUUIDId)
            }
        }
        if (classroomIds && classroomIds.length == 1) {
            session.set("classroomId", classroomIds[0]);
        }
        session.set("clientType", "web-dashboard")

        session.save().then(
            (result) => {
                console.log(result)
                toastr.success("Activity published Successfully")

                if (publishType == 2) {
                    let objects = []
                    for (const kidId of recipientIds) {
                        const OLT = Parse.Object.extend("OnlineLearningTracking");
                        var olt = new OLT();
                        olt.set("kidId", kidId);
                        olt.set("activityId", result.attributes.activityId);
                        olt.set("schoolId", result.attributes.schoolId);
                        olt.set("categoryId", result.attributes.categoryId);
                        olt.set("learningSessionId", result.id);
                        olt.set("rating", "Published");
                        olt.set("ratingValue", parseInt(5));
                        olt.set("clientType", result.attributes.clientType);
                        objects.push(olt)
                    }
                    Parse.Object.saveAll(objects).then(
                        (r) => {
                            console.log(r)
                            resetAddStudentPopup()
                            setShowStudentSelectionModal(false)
                        },
                        (error) => {
                            console.log("Error", error);
                        }
                    );
                } else {
                    resetAddStudentPopup()
                    setShowStudentSelectionModal(false)
                }

            },
            (error) => {
                console.log(error);
            }
        );

    }

    const addTopicUI = (subcategory, activity) => {
        return (<Col className="border-top  " style={{ borderTopWidth: 3 }}>
            <Card className="mt-2">
                <CardBody>

                    <AvForm className="outer-repeater"
                        onValidSubmit={(e, v) => {
                            handelAddActivity(newActivity, subcategory.id, activity);
                        }}
                    >
                        <div data-repeater-item className="outer">
                            <FormGroup >
                                <Label htmlFor="formname">{activity ? props.t('Edit Topic') : props.t('Add New Topic')}: </Label>
                                <AvField
                                    type="text"
                                    name="name"
                                    placeholder={props.t('Topic name')}
                                    className="form-control"
                                    value={newActivity}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: props.t('Topic name can not be empty'),
                                        },
                                    }}
                                    onChange={(e) => {
                                        setNewActivity(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup >
                                <Label htmlFor="formname">{props.t('Description')}: </Label>
                                <textarea
                                    type="text"
                                    name="name"
                                    placeholder={props.t('Description')}
                                    className="form-control"
                                    value={newActivityDescription}

                                    onChange={(e) => {
                                        setNewActivityDescription(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <Row>
                                <Col lg={4}>

                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('Month')}: </Label>
                                        <select className="form-control"
                                            onChange={(e) => {
                                                setNewActivityMonth(e.target.value)
                                            }}
                                            value={newActivityMonth}
                                        >

                                            {months.map((item, key) => (
                                                <option key={key}>{item}</option>
                                            ))}
                                        </select>

                                    </FormGroup>
                                    <p className="m-2 text-info font-size-8">{props.t('Month-1 is starting month of your academic cycle, it may be April, June or July based on your location/region')}</p>

                                </Col>
                                <Col lg={4}>
                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('Day')}: </Label>
                                        <select className="form-control"
                                            onChange={(e) => {
                                                setNewActivityDay(e.target.value)
                                            }}
                                            value={newActivityDay}
                                        >

                                            {days.map((item, key) => (
                                                <option key={key}>{item}</option>
                                            ))}

                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <button type="button"
                                className="btn btn-secondary m-1"
                                onClick={() => {
                                    setShowNewTopic(undefined);
                                    setNewActivity("");
                                    setNewActivityDescription("")
                                    setEditActivity(undefined);
                                    setNewActivityDay(undefined);
                                    setNewActivityMonth(undefined);
                                }}  >
                                {props.t('Close')}
                            </button>
                            {
                                props.loadingState ? <button type="button" className="btn btn-dark waves-effect waves-light float-right m-1">
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                                </button> :
                                    <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light float-right m-1">
                                        <i className="bx bx-save font-size-16 align-middle "></i>  {props.t('Save Topic')}
                                    </button>
                            }
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>)
    }

    return (
        <React.Fragment >
            <div className="page-content">

                <TopBar
                    title={selectedSubjectName ? selectedSubjectName : "Chapters"}
                    buttonName="Add New Chapter"
                    backButton={true}
                    hideButton={canEdit ? hideButton : true}
                    onClickAdd={handelAddChapter}
                    onClickBackButton={handelOnClickBack}
                    reorderButton={canEdit ? true : false}
                    onClickReorder={handelReorder}
                />
                {getSubcategoryList().length === 0 && <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
                        </div>
                    </Col>
                </Row>}
                {selectedTab === "rearrange" && <DragDropTables rearrangeObjects={props.learningSubcategories} updatedOrderList={handelSort} onClickClose={handelOnClickBack} />}
                {selectedTab === "subcategoryList" && getSubcategoryList().length > 0 && getSubcategoryList().map((subcategory, key) =>
                    <Card key={key}>
                        <CardBody>
                            {(!editSubcategoryObject || editSubcategoryObject.id !== subcategory.id) && <Row
                                className="px-2">
                                <Col lg={8} md={8} xs={8}
                                    onClick={() => {
                                        if (key === tab) {
                                            setTab(undefined);
                                        } else {
                                            setTab(key);
                                        }
                                    }}
                                >
                                    <Row>
                                        <Col>
                                            <div className='d-flex justify-content-start'>
                                                <span><h4 className='m-1'>{(key + 1)}. </h4></span>
                                                <span className="m-1"><h4>{subcategory.id === "CommonTopics" ? "No Chapter Mapping Topics" : subcategory.attributes.name} </h4></span>
                                                <span><h4 className={tab === key ? "bx bxs-chevron-up  m-1  " : "bx bxs-chevron-down  m-1 "} ></h4></span>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg={4} md={4} xs={4}>
                                    <Row className="float-right">
                                        <Col>
                                            <div className='d-flex justify-content-end m-0'>


                                                {(subcategory.id !== "CommonTopics") && <ul className="list-inline font-size-20 contact-links mb-0">
                                                    {canEdit && <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setEditSubcategory(subcategory);

                                                        }}
                                                        id={"sub-edit" + key}>
                                                        <i className="bx bx-edit-alt"></i>
                                                        <UncontrolledTooltip placement="top" target={"sub-edit" + key}>
                                                            {props.t('Edit')}
                                                        </UncontrolledTooltip>

                                                    </li>}
                                                    {canEdit && <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (getActivitiesForSubcategoryId(subcategory.id).length > 0) {
                                                                setDeleteWarning(true);
                                                            } else {
                                                                setDeleteSubcategoryObject(subcategory);
                                                            }
                                                        }}
                                                        id={"sub-delete" + key}>
                                                        <i className="bx bx-trash text-danger"></i>
                                                        <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                            {props.t('Delete')}
                                                        </UncontrolledTooltip>

                                                    </li>}


                                                </ul>}
                                                {canEdit && getActivitiesForSubcategoryId(subcategory.id).length > 0 && <button
                                                    onClick={() => {
                                                        setTab(key);
                                                        setReorderTopic(subcategory.id)
                                                        handelAddTopic(undefined);
                                                    }}
                                                    type="button"
                                                    className="btn btn-sm btn-success waves-effect waves-light m-1 "> <i className="bx bxs-analyse mr-1"></i>{props.t('Reorder')}
                                                </button>}

                                                {canEdit && <button
                                                    onClick={() => {
                                                        handelAddTopic(subcategory.id);
                                                        setReorderTopic(undefined)
                                                        setTab(key);
                                                    }}
                                                    type="button"
                                                    className="btn btn-sm btn-success waves-effect waves-light  m-1 ">+ {props.t('New Topic')}
                                                </button>}
                                            </div>
                                        </Col>
                                    </Row>


                                </Col>

                                {/* <Col lg={1} md={1} xs={1}>
                                    
                                </Col> */}
                            </Row>}
                            {editSubcategoryObject && editSubcategoryObject.id === subcategory.id && <Col>

                                <AvForm className="outer-repeater mt-3"

                                    onValidSubmit={(e, v) => {
                                        var name = v.activityName
                                        let learningSubcategory = editSubcategoryObject;
                                        learningSubcategory.set("name", name);
                                        props.addLearningSubcategory(learningSubcategory);
                                        setEditSubcategory(undefined);

                                    }}
                                >

                                    <Row>

                                        <Col lg="10">
                                            <div data-repeater-item className="outer">
                                                <FormGroup >
                                                    <AvField
                                                        type="text"
                                                        name="activityName"
                                                        placeholder={props.t('Category Name')}
                                                        className="form-control"
                                                        value={editSubcategoryObject.attributes.name}

                                                        validate={{
                                                            required: {
                                                                value: true,
                                                                errorMessage: props.t("Chapter name can not be empty"),
                                                            },
                                                        }}

                                                    />

                                                </FormGroup>
                                            </div>

                                        </Col>
                                        <Col lg="2">
                                            <div className="list-inline font-size-20 contact-links mb-0 float-right">
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary mr-1 "
                                                >
                                                    {props.t('Save')}
                                                </button>
                                                <button type="button"
                                                    className="btn btn-danger "
                                                    onClick={() => {
                                                        setEditSubcategory(undefined);
                                                    }}  >
                                                    {props.t('Close')}

                                                </button>
                                            </div>

                                        </Col>
                                    </Row>



                                </AvForm>



                            </Col>}

                            <Collapse isOpen={tab === key}>
                                <Row>
                                    {/* <Col lg={12}><button
                                        onClick={() => {
                                            handelAddTopic(subcategory.id);
                                        }}
                                        type="button"
                                        className="btn btn-success waves-effect waves-light float-right ">+ {props.t('New Topic')}
                                    </button></Col> */}
                                    <Col >

                                        {reorderTopic === subcategory.id ?
                                            <DragDropTables rearrangeObjects={getActivitiesForSubcategoryId(subcategory.id)} updatedOrderList={handelSortActivities} onClickClose={handelCloseActivitySort} /> :
                                            <div>
                                                {props.learningActivities && getActivitiesForSubcategoryId(subcategory.id).map((activity, key) =>
                                                    <Col lg={12} className="border-top mt-2" key={key}>

                                                        {editActivity && editActivity.id === activity.id ? <div>{addTopicUI(subcategory, activity)}</div> :
                                                            <Row >
                                                                <Col className="d-flex align-items-start">
                                                                    <div>
                                                                        <h5><i className="bx bxs-check-circle m-2"></i></h5>
                                                                    </div>

                                                                    <div
                                                                        onClick={() => {
                                                                            props.history.push(pageName + schoolId + "/" + curriculumId + "/" + cycleId + "/" + activity.id);
                                                                        }}
                                                                    >
                                                                        <div className='m-2'>
                                                                            <h5 className=""><span>{activity.attributes.name}</span></h5>
                                                                            {activity.attributes.description && <h6 className="text-muted ml-6">{"Description: " + activity.attributes.description}</h6>}
                                                                            <h6 className="text-muted  ml-6">
                                                                                {activity.attributes.month && activity.attributes.day ?
                                                                                    "Month: " + activity.attributes.month + ", " + "Day: " + activity.attributes.day
                                                                                    :
                                                                                    ""}
                                                                            </h6>
                                                                            {subcategory.id === "CommonTopics" && activity.attributes.filterValue &&
                                                                                activity.attributes.filterValue.map((option, key) =>
                                                                                    <Badge key={key} className={"badge badge-soft-primary font-size-12 m-1"}>{option.length > 20 ? option.slice(0, 19) + "..." : option} </Badge>)

                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </Col>


                                                                <Col lg={2}>
                                                                    <div className='d-flex justify-content-end'>
                                                                        {/* <h5><i className="bx bx-right-arrow-circle float-right  mt-2 ml-2"></i></h5> */}
                                                                        <ul className="list-inline font-size-16 contact-links mb-0 mt-2 float-right">

                                                                            {canEdit && <li className="list-inline-item px-2"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    setEditActivity(activity);
                                                                                    setNewActivity(activity.attributes.name);
                                                                                    setNewActivityDescription(activity.attributes.description);
                                                                                    setNewActivityDay(activity.attributes.day ? activity.attributes.day + "" : "1");
                                                                                    setNewActivityMonth(activity.attributes.month ? activity.attributes.month + "" : "1");
                                                                                }}
                                                                                id={"activity-edit" + key}>
                                                                                <i className="bx bx-edit-alt text-dark"></i>
                                                                                <UncontrolledTooltip placement="top" target={"activity-edit" + key}>
                                                                                    {props.t('Edit')}
                                                                                </UncontrolledTooltip>

                                                                            </li>}
                                                                            {canEdit && <li className="list-inline-item px-2"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    setDeleteActivity(activity)
                                                                                }}
                                                                                id={"activity-delete" + key}>
                                                                                <i className="bx bx-trash text-danger"></i>
                                                                                <UncontrolledTooltip placement="top" target={"activity-delete" + key}>
                                                                                    {props.t('Delete')}
                                                                                </UncontrolledTooltip>

                                                                            </li>}
                                                                            <li className="list-inline-item px-2"
                                                                                style={{ cursor: "pointer" }}
                                                                                onClick={() => {
                                                                                    props.history.push(pageName + schoolId + "/" + curriculumId + "/" + cycleId + "/" + activity.id);
                                                                                }}
                                                                                id={"activity-cyc" + key}>
                                                                                <i className="bx bx-right-arrow-circle text-dark"></i>
                                                                                <UncontrolledTooltip placement="top" target={"activity-cyc" + key}>
                                                                                    {props.t('Details')}
                                                                                </UncontrolledTooltip>

                                                                            </li>
                                                                        </ul>
                                                                    </div>
                                                                </Col>
                                                            </Row>}
                                                        {schoolId !== "franchise" && <Row>
                                                            <Col className='d-flex justify-content-end'>
                                                                <ButtonDropdown
                                                                    size='sm'
                                                                    className="custom-button-dropdown float-right"
                                                                    isOpen={dropDownOpen == activity.id}
                                                                    toggle={() => {
                                                                        if (dropDownOpen && dropDownOpen.length > 0) {
                                                                            setDropDownOpen()
                                                                        } else {
                                                                            setDropDownOpen(activity.id)
                                                                        }
                                                                    }}
                                                                >
                                                                    <DropdownToggle
                                                                        caret
                                                                        color="success"
                                                                        className="btn btn-success"
                                                                    >
                                                                        Publish &nbsp;{" "}
                                                                        <i className="mdi mdi-chevron-down"></i>
                                                                    </DropdownToggle>
                                                                    <DropdownMenu>
                                                                        <DropdownItem onClick={() => {
                                                                            setPublishType(1)
                                                                            setPublishActivity(activity)
                                                                            resetAddStudentPopup()
                                                                            setShowStudentSelectionModal(true)
                                                                        }}>Assessment</DropdownItem>
                                                                        <DropdownItem onClick={() => {
                                                                            setPublishType(2)
                                                                            setPublishActivity(activity)
                                                                            resetAddStudentPopup()
                                                                            setShowStudentSelectionModal(true)
                                                                        }}>Home Learning</DropdownItem>
                                                                    </DropdownMenu>
                                                                </ButtonDropdown>
                                                            </Col>
                                                        </Row>}
                                                    </Col>
                                                )}
                                            </div>}
                                    </Col>
                                </Row>
                                <Element name={"AddTopic" + subcategory.id}> {showNewTopic && showNewTopic === subcategory.id &&
                                    <div>{addTopicUI(subcategory)}</div>
                                }
                                </Element>
                            </Collapse>

                            {(tab !== key || true) && <Row className="border-top mt-2">

                                {getActivitiesForSubcategoryId(subcategory.id).length > 0 ? <Row className="mt-4 ml-4">

                                    <Col>
                                        <div className='d-flex justify-content-start'>
                                            <h5 className="m-2">{getActivitiesForSubcategoryId(subcategory.id).length} </h5>
                                            <h5 className="text-muted m-2"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => { setTab(key) }}
                                            >{"TOPICS"} </h5>

                                            {/* <h5 className="ml-3">{"8"} </h5>  <h5 className="text-muted ml-2">{"VIDEOS"} </h5> 

                                       <h5 className="ml-3">{"12"} </h5>  <h5 className="text-muted ml-2">{"DOCUMENTS/IMAGES"} </h5>  */}
                                        </div>
                                    </Col>

                                </Row>
                                    :
                                    <Row className="mt-4 ml-4">
                                        <div className='d-flex'>
                                            <span><h5 className="ml-3">{"No topics found,"}</h5></span>
                                            {canEdit && <span><h5 className="text-primary ml-2"
                                                style={{ cursor: "pointer" }}
                                                onClick={() => {
                                                    setTab(key);
                                                    handelAddTopic(subcategory.id);
                                                }}>
                                                {" Add topic?"}
                                            </h5></span>}
                                        </div>
                                    </Row>

                                }
                            </Row>}
                        </CardBody>
                    </Card>
                )
                }
                <Row>
                    {canEdit && !hideButton && !showNewChapter && getSubcategoryList().length > 0 && <Col lg={12} >
                        <div className="d-flex justify-content-end" >
                            <Form className="mt-4 mt-sm-0 float-sm-right form-inline">
                                <Button onClick={() => handelAddChapter()}
                                    type="button"
                                    color="success"
                                    className="btn waves-effect waves-light  mb-2">
                                    <i className="mdi mdi-plus mr-1"></i> {props.t('Add New Chapter')}
                                </Button>
                            </Form>
                        </div>
                    </Col>}
                </Row>
                <Element name="AddChapter">{
                    showNewChapter && <Col >
                        <Card>
                            <CardBody>
                                <AvForm className="outer-repeater"

                                    onValidSubmit={(e, v) => {
                                        handelAddSubcategory(newSubcategory);
                                        setShowNewChapter(false);
                                        setNewSubcategory("");
                                    }}
                                >
                                    <div data-repeater-item className="outer">
                                        <FormGroup >
                                            <Label htmlFor="formname">{props.t('New Chapter')}: </Label>
                                            <AvField
                                                type="text"
                                                name="name"
                                                placeholder={props.t('New Chapter...')}
                                                className="form-control"
                                                value={newSubcategory}
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: props.t('Chapter name can not be empty'),
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    setNewSubcategory(e.target.value);
                                                }}
                                            />

                                        </FormGroup>
                                    </div>
                                    <Row>
                                        <Col>
                                            <button type="button"
                                                className="btn btn-secondary "
                                                onClick={() => {
                                                    setShowNewChapter(false);
                                                    setNewSubcategory("");
                                                }}  >
                                                {props.t('Close')}
                                            </button>
                                        </Col>
                                        <Col>
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    type="submit"
                                                    className="btn btn-primary waves-effect waves-light">
                                                    <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save Chapter')}
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                </AvForm>
                            </CardBody>
                        </Card>
                    </Col>
                }
                </Element>
            </div >

            {
                deleteSubcategoryObject || deleteActivity ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            let obj = deleteSubcategoryObject ? deleteSubcategoryObject : deleteActivity;
                            if (obj.className === "LearningSubcategory") {
                                props.deleteLearningSubcategory(deleteSubcategoryObject);
                                setDeleteSubcategoryObject(undefined);
                                setsuccess_dlg("LearningSubcategory");
                            } else {
                                props.deleteLearningActivity(deleteActivity);
                                setDeleteActivity(undefined);
                                setsuccess_dlg("LearningActivity");
                            }

                        }}
                        onCancel={() => {
                            setDeleteSubcategoryObject(undefined);
                            setDeleteActivity(undefined);
                            setsuccess_dlg(false);
                        }}
                    >
                        {deleteSubcategoryObject ?
                            props.t("You won't be able to revert this!") :
                            props.t("On Delete of this topic, you are deleting all the content under this topic. You won't be able to revert this!")}
                    </SweetAlert>
                ) : null
            }
            {
                success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t('Deleted')}
                        onConfirm={() => {
                            setsuccess_dlg(false)
                        }}
                    >

                        {success_dlg === "LearningActivity" ? props.t('Your topic has been deleted.') : props.t('Your chapter has been deleted.')}
                    </SweetAlert>
                ) : null
            }
            {
                deleteWarning && <SweetAlert
                    info
                    title={props.t('Alert')}
                    onConfirm={() => {
                        setDeleteWarning(false)
                    }}
                >
                    {props.t('Please delete all the topics under chapter to delete chapter')}
                </SweetAlert>
            }
            <Modal isOpen={showStudentSelectionModal} className="modal-xl" scrollable={true} backdrop={true}>
                <ModalHeader toggle={() => {
                    setShowStudentSelectionModal(!showStudentSelectionModal)
                }}>
                    {publishType == 1 ? "Publish Assessment" : "Publish Home Learning"}
                </ModalHeader>
                <ModalBody>
                    <div>
                        <div id="kyc-verify-wizard" className="twitter-bs-wizard">
                            <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: addStudentPopupActiveTab === 0,
                                        })}
                                        onClick={() => {
                                            addStudentPopupToggleTab(0, previous);
                                        }}
                                    >
                                        <span className="step-number m-1">01</span>
                                        Select Students
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        className={classnames({
                                            active: addStudentPopupActiveTab === 1,
                                        })}
                                        onClick={() => {
                                            setKidPickerNextButtonClick(true)
                                        }}
                                    >
                                        <span className="step-number m-1">02</span>
                                        Post
                                    </NavLink>
                                </NavItem>
                            </ul>
                            <div id="bar" className="mt-4">
                                <Progress
                                    color="success"
                                    striped
                                    animated
                                    value={addStudentPopupProgressValue}
                                />
                                <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                            </div>
                            <TabContent
                                activeTab={addStudentPopupActiveTab}
                                className="twitter-bs-wizard-tab-content"
                            >
                                <TabPane tabId={0} id="pick-student">
                                    <StudentSelectionModal
                                        showAdmissionKids={true}
                                        schoolId={schoolId}
                                        kids={props.kids}
                                        classrooms={props.classrooms}
                                        clickNextButtonProgramatically={kidPickerNextButtonClick}
                                        onButtonClick={(selectedKids) => {
                                            if (selectedKids && selectedKids.length > 0) {
                                                setKidPickerNextButtonClick(false)
                                                addStudentPopupToggleTab(1, next)
                                                setSelectedKidsFromPickerView(selectedKids)
                                            } else {
                                                setKidPickerNextButtonClick(false)
                                                toastr.warning("Please select atleast one student to proceed")
                                            }
                                        }}>
                                    </StudentSelectionModal>
                                </TabPane>
                                <TabPane tabId={1} id="select-terms">
                                    <Card className="mt-0 pt-0">
                                        <CardBody className="mt-0 pt-0">
                                            <Row lg={12} className="mt-0 pt-0">
                                                <Col lg={12}>
                                                    <Label className="mt-0 pt-0">Selected Students -
                                                        {selectedKidsFromPickerView && selectedKidsFromPickerView.map((kid, key) => (<Label key={key} className="text-muted">{
                                                            (key === 0) ? kid.attributes.Name : `, ${kid.attributes.Name}`
                                                        }</Label>))}
                                                    </Label>
                                                </Col>
                                            </Row>
                                            <hr />
                                            {publishType == 1 && <Label>Description</Label>}
                                            {publishType == 1 && <Input className="form-control"
                                                type="textarea"
                                                id="description"
                                                placeholder="Write here..."
                                                rows="5"
                                                value={description}
                                                onChange={(e) => {
                                                    setDescription(e.target.value);
                                                }}
                                            />}
                                            <div className='py-4'>{getAttachmentsUI()}</div>
                                            <div className='py-4'>
                                                {publishType == 1 && <label className='btn btn-primary' htmlFor="upload-images-messagesfra">
                                                    {""}Upload Photos{""}<i className='ml-1 mb-2 bx bx-image-add font-size-16 '></i>
                                                </label>}
                                                {publishType == 1 && <input type="file"
                                                    name="upload-images-messagesfra"
                                                    className="sr-only"
                                                    id="upload-images-messagesfra"
                                                    accept="image/*"
                                                    onChange={(e) => {
                                                        clearAllAttachments()
                                                        handleImageUploadChange(e)
                                                    }}
                                                    multiple
                                                />}
                                            </div>
                                            <div className='d-flex justify-content-end'>
                                                <button
                                                    className="btn btn-primary float-right"
                                                    onClick={() => {
                                                        handlePublish(selectedKidsFromPickerView)
                                                    }}
                                                >
                                                    Publish
                                                </button>
                                            </div>
                                        </CardBody>
                                    </Card>

                                </TabPane>
                            </TabContent>


                        </div>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { curriculums, initialLoding, selectedCurriculum } = state.Curriculum;
    const { curriculumAcademicCycles, loadState } = state.AcademicCycles;
    const { learningCategories, selectedLearningCategory } = state.LearningCategory;
    const { learningSubcategories, subcategoryState, lscReplace } = state.LearningSubcategory;
    const { learningActivities, loadingState } = state.LearningActivity;
    const { userTeacher } = state.Login;
    const { kids } = state.Kid;
    const { classrooms } = state.Classroom;

    return { schools, selectedSchool, curriculums, initialLoding, selectedCurriculum, curriculumAcademicCycles, loadState, learningCategories, selectedLearningCategory, learningSubcategories, subcategoryState, lscReplace, learningActivities, loadingState, userTeacher, kids, classrooms };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, setSelectedCurriculum,
    getLearningSubcategoryForCategoryId, getLearningActivitiesForSubcategoryIds, addLearningSubcategory,
    addLearningActivity, setLearningActivityLoadingState, getSelectedLearningCategory, deleteLearningSubcategory,
    deleteLearningActivity, reorderSubCatrgories, reorderActivities, getClassroomsForSchoolId, getAllKids
})(SubcategoryList)));