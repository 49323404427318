import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventoryKitItemsHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryKitItems_helper";
import * as actionType from "./actionTypes";
import * as InventoryKitItemsAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getInventoryKitItemsForOwnerId({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_KIT_ITEMS_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventoryKitItemsHelper.getInventoryKitItemsForOwnerId, ownerId, ownerType, null, true);
        yield put(InventoryKitItemsAction.updateInventoryKitItems(localResponse));


        const serverResponse = yield call(InventoryKitItemsHelper.getInventoryKitItemsForOwnerId, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventoryKitItemsAction.updateInventoryKitItems(serverResponse));


            var nonDeleted = serverResponse.filter(
                (inventoryKitItem) =>
                inventoryKitItem.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventoryKitItem) =>
                inventoryKitItem.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* deleteInventoryKitItem({ payload: { inventoryKitItem } }) {
    try {
        yield put(InventoryKitItemsAction.updateInventoryKitItems(inventoryKitItem));
        const response = yield call(InventoryKitItemsHelper.deleteInventoryKitItemFromParse, inventoryKitItem);
        if (response === "error") {
            inventoryKitItem.set("isDeleted", undefined);
            yield put(InventoryKitItemsAction.updateInventoryKitItems(inventoryKitItem));
        } else
            if (response) {
                toastr.success(" Inventory kit item successfully removed", { timeOut: 3000 });
                response.unPin();
            } else {

            }

    } catch (error) {

    }
}

function* addInventoryKitItem({ payload: { inventoryKitItem } }) {
    try {
    
      yield put(InventoryKitItemsAction.updateInventoryKitItems(inventoryKitItem));
      const response = yield call(
        InventoryKitItemsHelper.saveCustomObjects,inventoryKitItem
      );
      if (response === "Error") {
  
        toastr.error("Please try again", { timeOut: 3000 });
        const local = yield call(
            InventoryKitItemsHelper.saveCustomObjects,inventoryKitItem.id
        );
  
        if (local) {
          yield put(InventoryKitItemsAction.updateInventoryKitItems(local));
        }
    
      }else 
      if (response) {
        yield put(InventoryKitItemsAction.updateInventoryKitItems(response));
        toastr.success("Purchase order item successfully added", { timeOut: 3000 });
        response.pin();
      } else {

      }
    } catch (error) {
 
    }
  }

  
  function* setSelectedInventoryKitItems({ payload: { inventoryKitItemId } }) {
    try {
      const response = yield call(InventoryKitItemsHelper.getInventoryKitItemFromParse, inventoryKitItemId, true);
      if (response) {
        Parse.Object.pinAll(response);
        yield put(InventoryKitItemsAction.updateSelectedKitItems(response));
      } 
    } catch (error) {

    }
  }

  export function* watchGetInventoryKitItemsForOwnerId() {
    yield takeEvery(actionType.GET_INVENTORY_KIT_ITEMS, getInventoryKitItemsForOwnerId);
}

export function* watchDeleteInventoryKitItem() {
    yield takeEvery(actionType.DELETE_INVENTORY_KIT_ITEM, deleteInventoryKitItem);
}

export function* watchAddInventoryKitItem(){
    yield takeEvery(actionType.ADD_INVENTORY_KIT_ITEM, addInventoryKitItem);
}

export function* watchSetSelectedInventoryKitItem() {
    yield takeEvery(actionType.SET_SELECTED_INVENTORY_KIT_ITEMS, setSelectedInventoryKitItems);
}


function* InventoryKitItemsSaga() {
    yield all([
        fork(watchGetInventoryKitItemsForOwnerId),
        fork(watchDeleteInventoryKitItem),
        fork(watchAddInventoryKitItem),
        fork(watchSetSelectedInventoryKitItem),
    ]);
}
export default InventoryKitItemsSaga;