import * as actions from "./actionTypes";

export const updateExpenseSuppliers = (expenseSuppliers) => {
  return {
    type: actions.UPDATE_EXPENSE_SUPPLIERS,
    payload: { expenseSuppliers },
  };
};

export const getExpenseSupplierForSchoolId = (schoolId, instituteId) => {
  return {
    type: actions.GET_EXPENSE_SUPPLIER_FOR_SCHOOL_ID,
    payload: { schoolId, instituteId },
  };
};


export const updateExpenseSupplier = (expenseSupplier) => {
  return {
    type: actions.UPDATE_EXPENSE_SUPPLIER,
    payload: { expenseSupplier },
  };
};

export const editExpenseSupplier = (expenseSupplier, isInstitute = false) => {
  return {
    type: actions.EDIT_EXPENSE_SUPPLIER,
    payload: { expenseSupplier, isInstitute },
  };
};

export const updateLoadingExpenseSupplier = (boolValue) => {
  return {
    type: actions.UPDATE_LOADING_EXPENSE_SUPPLIER,
    payload: { boolValue },
  }
}
