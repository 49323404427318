import * as actions from "./actionTypes";

export const getAllCreditsForAllUser = (schoolId, instituteId, franchiseId) => {
  return {
    type: actions.GET_ALL_CREDITS_FOR_ALL_USER,
    payload: { schoolId, instituteId, franchiseId },
  };
};

export const setAllCreditsForAllUser = (creditObj) => {
  return {
    type: actions.SET_ALL_CREDITS_FOR_USER,
    payload: { creditObj },
  };
};

export const getPricingForMsg = () => {
  return {
    type: actions.GET_ALLPRICING_FOR_MESSAGES,
    payload: {},
  };
};
export const setPricingForMsg = (pricingObj) => {
  return {
    type: actions.SET_ALLPRICING_FOR_MESSAGES,
    payload: { pricingObj },
  };
};
export const processToPaySMSAndWhatsApp = (
  amountToPay,
  invoiceItems,
  schoolId
) => {
  return {
    type: actions.PROCESS_TO_PAY_SMS_AND_WHATS_APP,
    payload: { amountToPay, invoiceItems, schoolId },
  };
};

export const paymentCreationSuccess = (mtObj) => {
  return {
    type: actions.PAYMENT_CREATION_SUCCESS,
    payload: { mtObj },
  };
};
