import React, { useState } from "react";

import { Form, FormGroup, Card, CardBody, Col, Row, CardTitle, CardSubtitle,Container,Label, Button } from "reactstrap";

// Form Editor
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


const FormEditors = (props) => {


const [content, setContent] = useState('');
const [editorState, setEditorState] = useState();
    
return (
  <React.Fragment>
    <div className="page-content">
      <Container fluid={true}>

        <Breadcrumbs title="Form" breadcrumbItem="Form Editors" />

        <Row>
          <Col>
            <Card>
              <CardBody>
                <CardTitle>react-draft-wysiwyg</CardTitle>
                <CardSubtitle className="mb-3">
                  Bootstrap-wysihtml5 is a javascript plugin that makes it
                  easy to create simple, beautiful wysiwyg editors with the
                  help of wysihtml5 and Twitter Bootstrap.
                </CardSubtitle>

                <Form method="post">
                  <FormGroup>
                    <Editor
                      editorState={editorState}
                      toolbarClassName="toolbarClassName"
                      wrapperClassName="wrapperClassName"
                      editorClassName="editorClassName"
                      onEditorStateChange={newState => {
                        setEditorState(newState);
                        setContent(draftToHtml(convertToRaw(newState.getCurrentContent())));
                      }}
                    />
                  </FormGroup>
                  <FormGroup>
                        <Button  color="primary"
                            onClick={() => {
                              setEditorState(newState);                            }}
                        >Print 
                        </Button>
                  </FormGroup>
                  <FormGroup>
                  <Label>Preview</Label>
                        <div className="border ql-container p-5 ">
                            <div 
                                dangerouslySetInnerHTML={{ 
                                    __html: content
                                }} 
                            />
                        </div>
                  </FormGroup>
                 

                </Form>

              </CardBody>
            </Card>
          </Col>
        </Row>

      </Container>
    </div>
  </React.Fragment>
      );
    }
    
export default FormEditors;