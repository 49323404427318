import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Parse from "parse";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import {
  Row,
  Col,
  Card,
  CardBody,
  Badge,
  Button,
  Input,
  UncontrolledTooltip,
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownToggle,
  FormGroup,
  Form,
  Label,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
  InputGroup,
} from "reactstrap";

import { withNamespaces } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  getEnquiryForSchoolId,
  editEnquiry,
  updateEnquiry,
  editEnquiryNote,
  getEnquirisForGivenDates,
  moveToAdmissions,
  enquiryBulkDelete,
  getPaymentOptionsForSchool,
  getFeeTemplateForSchoolId,
  getFeeTemplateItemsForTemplateId,
  createInvoiceWithFunction,
  getKidObjFromEnquiry,
  getFranchise,
  getDeletedEnquiryForSchoolId,
  enquiryBulkRestore,
  setInvoiceSuccessComplete,
  sendAdmissionLinkToParent,
  getTeachersForSchool,
  getReceiptAddressForSchoolId, getUserTeacher,
} from "../../store/actions";
import * as Constants from "../../Constents";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import Moment from "moment";
import * as WhiteLable from "../../components/Common/WhiteLable";
import COUNTRY_JSON from '../../assets/other/countries.json'
import EnquiryResharePopup from './EnquiryResharePopup';

const EnquiryList = (props) => {
  const [toggleId, setToggleId] = useState(undefined);
  const [filterValue, setFilterValue] = useState("All");
  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);

  const [displayList, setDisplayList] = useState([]);
  const [toggleLostReason, toggleLostReasonModel] = useState(false);

  const [selectedEquiryObject, setSelectedEquiryObject] = useState(undefined);
  const [lostError, setLostError] = useState(false);
  const [showAdmissionPopup, setShowAdmissionPopup] = useState(false);
  const [showEnquiryResharePopup, setShowEnquiryResharePopup] = useState(false);
  const [enquiryObject, setEnquiryObject] = useState();

  const [showAdmissionLink, setShowAdmissionLink] = useState(false);

  const [selectedEquiryIds, setSelectedEquiryIds] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [showRestorePopup, setShowRestorePopup] = useState(false);

  const [showNoKidName, setShowNoKidName] = useState(undefined);

  //payment model
  const [showPaymentModal, showPaymentModalOps] = useState(false);
  const [paymentTitle, paymentTitleOps] = useState("");
  const [paymentTitleError, paymentTitleErrorOps] = useState("");
  const [activeTemplate, activeTemplateOps] = useState("");
  const [feeTemplate, feeTemplateOps] = useState([]);
  const [activeYear, activeYearOps] = useState("2024-25");
  const [paymentOptions, paymentOptionsOps] = useState([]);
  const [invoiceItemList, invoiceItemListOps] = useState([]);
  const [newInvoiceTotal, newInvoiceTotalOps] = useState(0);
  const [amountError, amountErrorOps] = useState("");
  const [activeAcc, activeAccOps] = useState("");
  const [payToggleId, payToggleIdOps] = useState("");
  const [currency, currencyOps] = useState("₹");
  const [activeStudent, activeStudentOps] = useState("");
  const [activeStudentId, activeStudentIdOps] = useState("");
  const [list, setList] = useState(["2025-26","2024-25","2023-24", "2022-23",]);

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [admissionDeleteObject, setAdmissionDeleteObject] = useState(undefined);
  const [admissionKidLoading, setAdmissionKidLoading] = useState(false);
  const [newState, setNewState] = useState(undefined);
  const [admissionKid, setAdmissionKid] = useState(false);


  const [toDateError, setToDateError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);

  const [sendCourseInvite, setSendCourseInvite] = useState(undefined);
  const [sendInviteEnquiryLoading, setSendInviteEnquiryLoading] = useState(undefined)
  //sorting 
  const [onSort, setOnSort] = useState(true);
  const [sortBy, setSortBy] = useState(undefined);
  const [nameOrder, setNameOrder] = useState(undefined);
  const [dateOrder, setDateOrder] = useState('descending');
  const [sourceOrder, setSourceOrder] = useState(undefined);
  const [enquiryForOrder, setEnquiryForOrder] = useState(undefined);

  const [receiptAddress, setReceiptAddress] = useState([]);
  const [selectedAddress, setSelectedAddress] = useState([]);
  const [newInvoiceTax, setInvoiceTax] = useState(0);
  const [taxable, setTaxable] = useState(true);
  const [taxError, setTaxError] = useState(false);

  const [showRoyalty, setShowRoyalty] = useState(false);
  const [royaltyAmount, setRoyaltyAmount] = useState(0);

  const [templetRestiction, setTempletRestiction] = useState(false);
  const [revenueStartDate, setRevenueStartDate] = useState(new Date());
  const [revenueEndDate, setRevenueEndDate] = useState(new Date());

  const [loadMore, setLoadMore] = useState(-1);
  var schoolId = props.schoolId;
  useEffect(() => {
    props.getEnquiryForSchoolId(schoolId);
    props.getTeachersForSchool(schoolId);
    if (Parse.User.current()) {
      if (Parse.User.current().attributes.teacherId) {
        props.getUserTeacher(Parse.User.current().attributes.teacherId);
      }
    }
  }, [schoolId]);

  useEffect(() => {
    if (
      props.selectedSchool &&
      props.selectedSchool.attributes.franchiseId &&
      props.selectedSchool.attributes.franchiseId.length > 0
    ) {
      props.getFranchise(props.selectedSchool.attributes.franchiseId);
    }
    if (props.selectedSchool) {
      if (props.selectedSchool.attributes.countryCode != "IN") {
        for (const cc of COUNTRY_JSON) {
          if (cc.code == props.selectedSchool.attributes.countryCode) {
            currencyOps(cc.currency.currencySymbol);
            break;
          }
        }
        if (props.selectedSchool.attributes.countryCode == "LK") {
          setList(["2025", "2024", "2023"])
          activeYearOps('2023')
        }
      }
    }
  }, [props.selectedSchool]);

  useEffect(() => {

    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[2] > 0) {
      setShowRoyalty(true);
    } else {
      setShowRoyalty(false);
    }

    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[4] > 0) {
      if (props.selectedSchool && (props.selectedSchool.attributes.franchiseId == "sP8TeR7BSG"
        || props.selectedSchool.attributes.franchiseId == "AwiwOAmsEf1")) {
        setTempletRestiction(true);
      }

    } else {
      setTempletRestiction(false);
    }


  }, [props.franchise, props.selectedSchool]);

  const enquiryStates = [
    "All",
    "Admitted",
    "Enquired",
    "Rejected",
    "Waitlisted",
    "Cold",
    "Warm",
    "Hot",
    "Lost",
    "Consulted",
    "Evaluated",
    "QR Code",
    "Bulk Import",
    "School Website",
    "Deleted",
  ];
  const enquiryStates2 = [
    "All",
    "Signed up",
    "Not Interested",
    "No response",
    "Not qualified",
    "Warm",
    "Active",
    "Met",
    "Not Met",
    "QR Code",
    "Bulk Import",
    "School Website",
    "Deleted",
  ];
  const enquiryLostReasons = [
    "Pricing",
    "Distance",
    "Infrastructure",
    "Curriculum",
    "Safety",
    "Fee Affordability",
    "No Guaranteed Admission",
    "No Live streaming",
  ];

  function updateFilter(value) {
    setFilterValue(value);
    setSelectedEquiryIds([]);
    if (value === "Deleted") {
      props.getDeletedEnquiryForSchoolId(schoolId);
    }
  }

  function onClickStatus(enquiry, item) {
    var newStatus = getStatusNumber(item);
    var note = getStatue(enquiry.attributes.status) + " to " + item;
    var obj = {
      equiryObject: enquiry,
      newStatus: newStatus,
      note: note,
      reason: "",
      lostReasons: [],
    };
    setSelectedEquiryObject(obj);
    if (item === "Admitted") {
      if (
        enquiry &&
        (!enquiry.attributes.kidName || enquiry.attributes.kidName.length === 0)
      ) {
        setShowNoKidName(enquiry);
      } else {
        setShowAdmissionPopup(true);
        setShowAdmissionLink(false);
      }
    } else if (item === "Lost") {
      setLostError(false);
      toggleLostReasonModel(true);
    } else {
      handelStatusChange(enquiry, newStatus, note, []);
    }
  }
  function handelLostEnquiryRespose() {
    var note = selectedEquiryObject.note;
    if (selectedEquiryObject.lostReasons.length > 0) {
      note = note + " due to ";
      for (const i in selectedEquiryObject.lostReasons) {
        note = note + selectedEquiryObject.lostReasons[i];
        if (i !== selectedEquiryObject.lostReasons.length - 1) {
          note = note + ", ";
        }
      }
    }

    if (selectedEquiryObject.reason && selectedEquiryObject.reason.length > 0) {
      note = note + "and  " + selectedEquiryObject.reason;

      var splitRes = selectedEquiryObject.reason.split(",");
      if (splitRes && splitRes.length > 0) {
        var lostReasons = selectedEquiryObject.lostReasons;
        lostReasons.concat(splitRes);
        var obj = { ...selectedEquiryObject, lostReasons: lostReasons };
        setSelectedEquiryObject(obj);
      }
    }

    handelStatusChange(
      selectedEquiryObject.equiryObject,
      selectedEquiryObject.newStatus,
      note,
      selectedEquiryObject.lostReasons
    );
    toggleLostReasonModel(false);
  }
  const handelStatusChange = (enquiry, status, note, lostReasons) => {
    enquiry.set("status", status);
    if (status == 7 && lostReasons && lostReasons.length > 0) {
      enquiry.set("lostReasons", lostReasons);
    }
    props.editEnquiry(enquiry);

    const EnquiryNote = Parse.Object.extend("EnquiryNotes");
    const noteObject = new EnquiryNote();

    if (Parse.User.current().attributes.teacherId) {
      noteObject.set("createdBy", Parse.User.current().attributes.teacherId);
    } else {
      noteObject.set("createdBy", Parse.User.current().id);
    }
    noteObject.set("enquiryId", enquiry.id);
    noteObject.set("schoolId", enquiry.attributes.schoolId);
    noteObject.set("noteMessage", note);
    noteObject.set("systemLog", true);

    props.editEnquiryNote(noteObject);
  };

  const handelMoveToAdmission = () => {
    var enquiry = selectedEquiryObject.equiryObject;
    const EnquiryNote = Parse.Object.extend("EnquiryNotes");
    const noteObject = new EnquiryNote();

    if (Parse.User.current().attributes.teacherId) {
      noteObject.set("createdBy", Parse.User.current().attributes.teacherId);
    } else {
      noteObject.set("createdBy", Parse.User.current().id);
    }
    noteObject.set("enquiryId", enquiry.id);
    noteObject.set("schoolId", enquiry.attributes.schoolId);
    noteObject.set("noteMessage", selectedEquiryObject.note);
    noteObject.set("systemLog", true);

    props.editEnquiryNote(noteObject);
    props.moveToAdmissions(selectedEquiryObject.equiryObject);

    // setShowAdmissionPopup(false);
    setShowAdmissionLink(true);
  };

  const handelDateChange = () => {
    // setDisplayList(undefined);
    var firstDay = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0);
    var lastDay = new Date(
      toDate.getFullYear(),
      toDate.getMonth(),
      toDate.getDate(),
      23,
      59,
      59
    );
    props.getEnquirisForGivenDates(schoolId, firstDay, lastDay);
  };

  function handelSelecteAll() {
    if (filterValue === "Deleted") {
      if (props.deletedEnquiries && props.deletedEnquiries.length > 0) {
        if (selectAll) {
          setSelectAll(false);
          var list = [];
          setSelectedEquiryIds(list);
        } else {
          var list = [];
          for (const i in props.deletedEnquiries) {
            list.push(props.deletedEnquiries[i].id);
          }
          setSelectAll(true);
          setSelectedEquiryIds(list);
        }
      }
    } else {
      let eList = getDisplayList();
      if (eList&& eList.length > 0) {
        if (selectAll) {
          setSelectAll(false);
          var list = [];
          setSelectedEquiryIds(list);
        } else {
          var list = [];
          for (const i in eList) {
            list.push(props.enquiries[i].id);
          }
          setSelectAll(true);
          setSelectedEquiryIds(list);
        }
      }
    }
  }
  function calculateAge(birthDate) {
    if (!birthDate) {
        return "";
    } else {
      const birthDateTimeStamp = new Date(birthDate).getTime();
      const now = new Date().getTime();

      const ageMilliseconds = now - birthDateTimeStamp;

      const years = Math.floor(ageMilliseconds / (1000 * 60 * 60 * 24 * 365.25));
      const remainingMilliseconds = ageMilliseconds % (1000 * 60 * 60 * 24 * 365.25);
      const months = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24 * (365.25 / 12)));

      let ageString = `${years} years`;
      if (months > 0) {
        ageString += ` ${months} months`;
      }

      return ageString;
    }
}
        
  function handelSelecteEnquiry(enquiry) {
    if (props.enquiries && props.enquiries.length > 0) {
      if (selectedEquiryIds && selectedEquiryIds.includes(enquiry.id)) {
        var others = selectedEquiryIds.filter((item) => item !== enquiry.id);
        setSelectAll(false);
        setSelectedEquiryIds(others);
      } else {
        var list = [...selectedEquiryIds];
        list.push(enquiry.id);
        setSelectAll(false);
        setSelectedEquiryIds(list);
      }
    }
  }

  function handelBulkDelete() {
    if (selectedEquiryIds && selectedEquiryIds.length > 0) {
      var temp = props.enquiries.filter((enquiry) =>
        selectedEquiryIds.includes(enquiry.id)
      );

      props.enquiryBulkDelete(temp);
      setShowDeletePopup(false);
      setSelectAll(false);
      setSelectedEquiryIds([]);
    }
  }

  function handelBulkRestore() {
    if (selectedEquiryIds && selectedEquiryIds.length > 0) {
      var temp = props.deletedEnquiries.filter((enquiry) =>
        selectedEquiryIds.includes(enquiry.id)
      );

      props.enquiryBulkRestore(temp);
      setShowRestorePopup(false);
      setSelectAll(false);
      setSelectedEquiryIds([]);
    }
  }

  function formatDate(d) {
    if (d === "") {
      return "";
    } else {
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      // return `${da}/${mo}/${ye}`;

      return Moment(d).format("DD/MM/YYYY");
    }
  }
  function getDays(d) {
    if (d === "") {
      return "";
    } else {
      let today = new Date();
      let diffTime = today - d;
      let diffDays = diffTime / (1000 * 3600 * 24);
      let diffDaysInt = parseInt(diffDays + "")

      if (diffDaysInt == 0) {
        if (today.getDate() == d.getDate()) {
          return "Today"
        }
        return "1 day old"
      } else if (diffDaysInt == 1) {
        return "1 day old "
      } else if (diffDaysInt > 0) {
        return diffDaysInt + " day's old "
      } else {
        return "";
      }

    }
  }

  const getparentEmailsAndPhoneNubers = () => {
    if (selectedEquiryObject) {
      let emails = [];
      let phoneNumbers = [];
      if (selectedEquiryObject.equiryObject.attributes.fatherMobile) {
        if (!phoneNumbers.includes(selectedEquiryObject.equiryObject.attributes.fatherMobile)) {
          phoneNumbers.push(selectedEquiryObject.equiryObject.attributes.fatherMobile)
        }
      }
      if (selectedEquiryObject.equiryObject.attributes.motherMobile) {
        if (!phoneNumbers.includes(selectedEquiryObject.equiryObject.attributes.motherMobile)) {
          phoneNumbers.push(selectedEquiryObject.equiryObject.attributes.motherMobile)
        }
      }

      if (selectedEquiryObject.equiryObject.attributes.fatherEmail) {
        if (!emails.includes(selectedEquiryObject.equiryObject.attributes.fatherEmail)) {
          emails.push(selectedEquiryObject.equiryObject.attributes.fatherEmail)
        }
      }
      if (selectedEquiryObject.equiryObject.attributes.motherEmail) {
        if (!emails.includes(selectedEquiryObject.equiryObject.attributes.motherEmail)) {
          emails.push(selectedEquiryObject.equiryObject.attributes.motherEmail)
        }

      }
      return { emails: emails, phoneNumbers: phoneNumbers }
    }
    return { emails: [], phoneNumbers: [] }
  }

  function getStatusNumber(item) {
    var newStatus = 0;
    if (props.schoolType == 15 || props.schoolId == 'HbckcFaL7m' || props.schoolId == 'rb7dgz7aYT') {
      if (item == "Signed up") {
        return 1;
      } else if (item == "Not Interested") {
        return 2;
      } else if (item == "No response") {
        return 3;
      } else if (item == "Not qualified") {
        return 4;
      } else if (item == "Active") {
        return 5;
      } else if (item == "Met") {
        return 6;
      } else if (item == "Not Met") {
        return 7;
      }
    } else {
      if (item === "Admitted") {
        newStatus = 1;
      } else if (item === "Rejected") {
        newStatus = 2;
      } else if (item === "Waitlisted") {
        newStatus = 3;
      } else if (item == "Cold") {
        newStatus = 4;
      } else if (item == "Warm") {
        newStatus = 5;
      } else if (item === "Hot") {
        newStatus = 6;
      } else if (item === "Lost") {
        newStatus = 7;
      } else if (item === "Consulted") {
        newStatus = 9;
      } else if (item === "Evaluated") {
        newStatus = 10;
      }
    }
    return newStatus;
  }

  function getStatue(status) {


    if (props.schoolType == 15 || props.schoolId == 'HbckcFaL7m' || props.schoolId == 'rb7dgz7aYT') {
      if (status == 1) {
        return "Signed up";
      } else if (status == 2) {
        return "Not Interested";
      } else if (status == 3) {
        return "No response";
      } else if (status == 4) {
        return "Not qualified";
      } else if (status == 5) {
        return "Active";
      } else if (status == 6) {
        return "Met";
      } else if (status == 7) {
        return "Not Met";
      }
    } else {
      if (status == 1) {
        return "Admitted";
      } else if (status == 2) {
        return "Rejected";
      } else if (status == 3) {
        return "Waitlisted ";
      } else if (status == 4) {
        return "Cold";
      } else if (status == 5) {
        return "Warm";
      } else if (status == 6) {
        return "Hot";
      } else if (status == 7) {
        return "Lost";
      } else if (status == 8) {
        return "Drop";
      } else if (status == 9) {
        return "Consulted"
      } else if (status == 10) {
        return "Evaluated"
      }
    }
    return "Enquired";
  }
  function getStatueColor(status) {

    if (status == 1) {
      return "success";
    } else if (status == 2) {
      return "dark ";
    } else if (status == 3) {
      return "warning";
    } else if (status == 4) {
      return "dark";
    } else if (status == 5) {
      return "secondary";
    } else if (status == 6) {
      return "success";
    } else if (status == 7) {
      return "danger";
    } else if (status == 8) {
      return "info";
    } else if (status == 9) {
      return "secondary"
    }
    return "primary";
  }

  function getDisplayList() {

    let rtList = [];

    var temp = [...props.enquiries];
    if (filterValue === "Bulk Import") {
      var filter = temp.filter(function (item) {
        return (item.attributes.isBulkUpdate);
      });
      rtList = [...filter];
    } else if (filterValue === "QR Code") {
      var filter = temp.filter(function (item) {
        return (item.attributes.createdBy === "QRCode");
      });
      rtList = [...filter];
    } else if (filterValue === "School Website") {
      var filter = temp.filter(function (item) {
        return (item.attributes.createdBy === "API");
      });
      rtList = [...filter];
    } else if (filterValue !== "All") {

      var state = getStatusNumber(filterValue);
      var list = temp.filter(
        (item) =>
          item.attributes.status === state ||
          (state === 0 && !item.attributes.status)
      );
      rtList = [...list];
    } else {
      rtList = [...props.enquiries];
    }

    if (props.searchString && rtList) {
      let search = props.searchString;
      var filter = rtList.filter(function (item) {
        return (item.attributes.kidName && (item.attributes.kidName.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.kidGender && (item.attributes.kidGender.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.source && (item.attributes.source.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.year && (item.attributes.year.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.enquiryFor && listSearch(item.attributes.enquiryFor, search)) ||
          (item.attributes.fatherName && (item.attributes.fatherName.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.fatherMobile && (item.attributes.fatherMobile.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.fatherEmail && (item.attributes.fatherEmail.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.motherName && (item.attributes.motherName.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.motherMobile && (item.attributes.motherMobile.toLowerCase()).includes(search.toLowerCase())) ||
          (item.attributes.motherEmail && (item.attributes.motherEmail.toLowerCase()).includes(search.toLowerCase()))
      });
      rtList = [...filter];
    }

    if (sortBy) {

      let tempList = getSortList(rtList, sortBy);
      // setDisplayList(tempList);
      if (loadMore==1 || tempList.length<50) {
        return tempList;
      } else {
        return tempList.slice(0,10)
      }
      
    } else {
      // setDisplayList(rtList);
      // return rtList;
      if (loadMore==1 || rtList.length<50) {
        return rtList;
      } else {
        return rtList.slice(0,10)
      }
    }
  }
  useEffect(() => {
    if (loadMore) {
      getDisplayList()
    }
  }, [loadMore]);

  const listSearch = (list, search) => {
    var filter = list.filter(function (item) {
      return (item.toLowerCase()).includes(search.toLowerCase());
    });
    if (filter && filter.length > 0) {
      return true
    }
    return false;
  }

  const getSortList = (rtList, sortBy) => {
    let sortingFor = sortBy.type;
    let sortFor = sortBy.order === "ascending" ? false : true;
    let sortedList = rtList.sort(function (a, b) {

      let aVal = a.attributes[sortingFor] ? a.attributes[sortingFor] : " ";
      let bVal = b.attributes[sortingFor] ? b.attributes[sortingFor] : " ";
      if (sortFor) {
        // console.log(a[sortingFor]);
        if (aVal < bVal) {
          return -1;
        }
        if (aVal > bVal) {
          return 1;
        }
        return 0;
      } else {
        if (aVal < bVal) {
          return 1;
        }
        if (aVal > bVal) {
          return -1;
        }
        return 0;
      }
    });

    return sortedList;
  }

  const handelSortBy = (type, order) => {
    setSortBy({ type: type, order: order })
  }

  useEffect(() => {

    // console.log(props.searchString)
    // console.log("673:",props.schoolType)

  }, [props.searchString, props.schoolType]);

  function getDropdwn(enquiry) {
    if (filterValue === "Deleted") {
      var list = ["Restore"];
      return (
        <Dropdown
          isOpen={toggleId === enquiry.id}
          toggle={() => setToggleId(toggleId ? undefined : enquiry.id)}
        >
          <DropdownToggle className="bg-white border-0">
            <Badge className="font-size-12 badge-soft-danger" pill>
              Deleted
              <i className="mdi mdi-chevron-down"></i>
            </Badge>
          </DropdownToggle>
          <DropdownMenu>
            {list.map((item, key) => (
              <DropdownItem
                key={key}
                onClick={() => {
                  setShowRestorePopup(enquiry);
                }}
              >
                {item}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      );
    } else {
      var currentState = getStatue(enquiry.attributes.status);
      var list = [
        "Admitted",
        "Enquired",
        "Rejected",
        "Waitlisted",
        "Cold",
        "Warm",
        "Hot",
        "Lost",
        "Consulted",
        "Evaluated",
      ];

      if (props.schoolType == 15 || props.schoolId == 'HbckcFaL7m' || props.schoolId == 'rb7dgz7aYT') {
        list = [
          "Signed up",
          "Not Interested",
          "No response",
          "Not qualified",
          "Warm",
          "Active",
          "Met",
          "Not Met"
        ];
      }

      var newList = list.filter((item) => item !== currentState);

      if (currentState === "Admitted" || currentState === "Signed up") {
        newList = newList.filter((item) => item !== "Lost");
      }

      return (
        <Dropdown
          isOpen={toggleId === enquiry.id}
          toggle={() => setToggleId(toggleId ? undefined : enquiry.id)}
        >
          <DropdownToggle className="bg-white border-0">
            <Badge
              className={
                "font-size-12 badge-soft-" +
                getStatueColor(enquiry.attributes.status)
              }
              color="success"
              pill
            >
              {currentState}
              <i className="mdi mdi-chevron-down"></i>
            </Badge>
          </DropdownToggle>
          <DropdownMenu>
            {newList.map((item, key) => (
              <DropdownItem
                key={key}
                onClick={() => {
                  if (enquiry.attributes.status == 1) {
                    setNewState(item);
                    setAdmissionKidLoading(enquiry);
                    getKidFromEnquiry(enquiry);
                  } else {
                    onClickStatus(enquiry, item);
                  }
                }}
              >
                {item}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </Dropdown>
      );
    }
  }

  const getKidFromEnquiry = (enquiry) => {
    var Kid = Parse.Object.extend("Kid");
    var query = new Parse.Query(Kid);
    query.equalTo("enquiryId", enquiry.id);
    query.find().then(
      (result) => {
        if (result) {
          setAdmissionKid(result[0]);
          setAdmissionDeleteObject(enquiry);
          setAdmissionKidLoading(false);
        }
      },
      (error) => { }
    );
  };

  const removeFromAdmission = () => {
    let enquiry = admissionDeleteObject;
    let kid = admissionKid;
    let state = newState;
    setAdmissionDeleteObject(undefined);
    setNewState(undefined);
    kid.set("Deleted", true);
    kid.set("deletedOn", new Date());
    kid.set("deletedBy", Parse.User.current().attributes.teacherId);
    kid.save().then(
      (result) => {
        if (result) {
          result.pin();
          onClickStatus(enquiry, state);
          setAdmissionKid(undefined);
        }
      },
      (error) => { }
    );
  };

  // if (!props.loding) {
  //     setShowAdmissionPopup(false);
  // }

  function copyAdmissionLink(str, event) {
    document.oncopy = function (event) {
      event.clipboardData.setData("Text", str);
      event.preventDefault();
    };
    document.execCommand("Copy");
    document.oncopy = undefined;
    toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
  }


  const prepareInvoiceToGenerate = (enqObj) => {
    let enqKidId = enqObj.id;
    let schoolId = enqObj.attributes.schoolId ?? props.schoolId;
    props.getPaymentOptionsForSchool(schoolId);
    props.getFeeTemplateForSchoolId(schoolId);
    props.getReceiptAddressForSchoolId(schoolId)
    props.getKidObjFromEnquiry(enqKidId);
    activeStudentOps(enqObj.attributes.kidName);
    feeTemplateOps([]);
    invoiceItemListOps([]);
  };
  useEffect(() => {
    updateAddress();
  }, [props.receiptAddres]);

  const updateAddress = () => {
    if (props.receiptAddres.length > 0) {
      let temp = [];
      let schoolAddress = {
        id: "schoolId",
        name: "School Address"
      }
      temp.push(schoolAddress)
      for (const val of props.receiptAddres) {
        let tempIn = {};
        tempIn.id = val.id;
        tempIn.object = val;
        tempIn.name = val.attributes.name;

        temp.push(tempIn);
      }
      setSelectedAddress(temp[0]);
      setReceiptAddress(temp);

    }
  }
  useEffect(() => {
    if (props.paymentOptionsForSchool.length > 1) {
      let temp = [];
      for (const val of props.paymentOptionsForSchool) {
        let tempIn = {};
        tempIn.id = val.id;
        tempIn.Obj = val;
        tempIn.accName = val.attributes.bankName;
        tempIn.accNum = val.attributes.accNo.toString();
        tempIn.x = "";
        for (let j = 0; j < tempIn.accNum.length - 5; j++) {
          tempIn.x += "X";
        }
        tempIn.accNum1 = tempIn.accNum.substr(tempIn.accNum.length - 5);
        tempIn.showAccNo = tempIn.x + tempIn.accNum1;
        tempIn.displayName = tempIn.accName + "(" + tempIn.showAccNo + ")";
        temp.push(tempIn);
      }
      paymentOptionsOps(temp);
      activeAccOps(temp[0]);
    }
  }, [props.paymentOptionsForSchool]);
  useEffect(() => {
    if (props.feeTemplates) {
      let tempTemplate = [];
      for (let val of props.feeTemplates) {
        if (!val.attributes.isDisabled) {
          let temp = {};
          temp.Obj = val;
          temp.id = val.id;
          temp.title = val.attributes.name;
          tempTemplate.push(temp);
        }
      }
      feeTemplateOps(tempTemplate);
    }
  }, [props.feeTemplates]);
  useEffect(() => {
    if (activeTemplate !== "") {
      props.getFeeTemplateItemsForTemplateId(activeTemplate);
    } else {
      let tempItems = [];
      let totalAmount = 0;
      newInvoiceTotalOps(totalAmount);
      invoiceItemListOps(tempItems);
    }
  }, [activeTemplate]);
  useEffect(() => {
    if (props.kidObj) {
      activeStudentIdOps(props.kidObj.id);
    } else {
      activeStudentIdOps("");
    }
  }, [props.kidObj]);
  useEffect(() => {
    if (props.feeTemplateItems) {
      let tempItems = [];
      let totalAmount = 0;
      let totalTax = 0;
      let totalRoyalty = 0;
      for (let val of props.feeTemplateItems) {
        let temp = {};
        temp.itemDescription = val.attributes.itemDescription;
        temp.slno = val.attributes.slno;
        temp.dueDate = val.attributes.dueDate;
        temp.amount = val.attributes.amount;
        temp.discount = val.attributes.discount;
        temp.discountType = val.attributes.discountType + "";
        temp.discountRemark = val.attributes.discountRemark;
        temp.discountDisabled = val.attributes.discount > 0;
        temp.totalAmount =
          (val.attributes.discountType == "1" || val.attributes.discountType == 1)
            ? val.attributes.amount - val.attributes.discount
            : val.attributes.amount -
            (val.attributes.amount * val.attributes.discount) / 100;
        temp.feeType = val.attributes.feeType;
        totalAmount += temp.totalAmount;

        if (temp.tax > 0) {
          let tTax = roundToTwo(temp.totalAmount * 0.01 * temp.tax);
          temp.taxAmount = tTax
          totalTax = totalTax + tTax
          temp.totalAmount = temp.totalAmount + tTax
        }

        temp.royalty = val.attributes.royalty;
        if (temp.royalty > 0) {
          temp.royaltyAmount = (temp.totalAmount * temp.royalty) / 100
          totalRoyalty += temp.royaltyAmount;
        } else {
          temp.royaltyAmount = 0;
        }
        temp.hsn = val.attributes.hsn;



        tempItems.push(temp);
      }
      setRoyaltyAmount(totalRoyalty);
      setInvoiceTax(totalTax);
      newInvoiceTotalOps(totalAmount);
      invoiceItemListOps(tempItems);
    }
  }, [props.feeTemplateItems]);
  const addAnItemToInvoice = () => {
    let tempItems = Object.assign([], invoiceItemList);
    let temp = {};
    temp.itemDescription = `Item ${invoiceItemList.length + 1}`;
    temp.dueDate = new Date();
    temp.slno = tempItems.length;
    temp.amount = 0;
    temp.discount = 0;
    temp.discountType = "1";
    temp.discountRemark = "";
    temp.discountDisabled = true;
    temp.totalAmount = 0;
    tempItems.push(temp);
    invoiceItemListOps(tempItems);
  };
  const removeItemFromNewInvoice = (key) => {
    let tempItems = Object.assign([], invoiceItemList);
    let totalAmount = 0;
    let totalTax = 0;
    let totalRoyalty = 0;
    tempItems.splice(key, 1);
    for (let i in tempItems) {
      tempItems[i].totalAmount =
        (tempItems[i].discountType === "1" || tempItems[i].discountType === 1)
          ? tempItems[i].amount - tempItems[i].discount
          : tempItems[i].amount -
          (tempItems[i].amount * tempItems[i].discount) / 100;
      totalAmount += tempItems[i].totalAmount;
      if (tempItems[i].tax > 0) {
        let tTax = roundToTwo(tempItems[i].totalAmount * 0.01 * tempItems[i].tax);
        tempItems[i].taxAmount = tTax;
        totalTax = totalTax + tTax
        tempItems[i].totalAmount = tempItems[i].totalAmount + tTax
      }

      let royalty = tempItems[i].royalty;
      if (royalty > 0) {
        let royaltyAmount = (tempItems[i].totalAmount * royalty) / 100
        tempItems[i].royaltyAmount = royaltyAmount;
        totalRoyalty += royaltyAmount;
      } else {
        tempItems[i].royaltyAmount = 0;
      }
    }
    setRoyaltyAmount(totalRoyalty);
    setInvoiceTax(totalTax);
    newInvoiceTotalOps(totalAmount);
    invoiceItemListOps(tempItems);
  };
  const createInvoice = () => {
    let errorC = 0;
    if (!paymentTitle) {
      paymentTitleErrorOps("Please enter the title");
      errorC++;
    } else {
      paymentTitleErrorOps(" ");
    }
    // if (activeFilterKid.length === 0) {
    //   errorC++;
    //   childrenErrorOps("please select student to create invoice");
    // } else {
    //   childrenErrorOps("");
    // }
    if (newInvoiceTotal === 0) {
      errorC++;
      amountErrorOps("Amount cannot be zero");
    } else {
      amountErrorOps("");
    }
    if (errorC === 0) {
      let inputParam = {};
      inputParam.amount = newInvoiceTotal;
      inputParam.instituteId =
        props.selectedSchool && props.selectedSchool.attributes.instituteId;
      inputParam.schoolId = schoolId;
      let tempItems = Object.assign([], invoiceItemList);
      for (let val of tempItems) {
        val.discountType = +val.discountType;
        val.amount = +val.amount;
        val.discount = +val.discount;
      }
      inputParam.items = tempItems;
      if (royaltyAmount > 0) {
        inputParam.royaltyAmount = royaltyAmount;
        inputParam.isRoyaltyInvoice = true;
      }
      if (revenueStartDate && revenueEndDate) {

        let startDate = new Date(revenueStartDate.getFullYear(), revenueStartDate.getMonth(), 1);
        let endDate = new Date(revenueEndDate.getFullYear(), revenueEndDate.getMonth() + 1, 0, 23, 59);

        inputParam.revenueStartDate = revenueStartDate;
        inputParam.revenueEndDate = revenueEndDate;
      }
      //   inputParam.programId =
      //     activeFilterBy === "program"
      //       ? activeFilterTo !== "all"
      //         ? activeFilterTo
      //         : null
      //       : null;
      inputParam.recipientType = 6; //1 - school 5&2 d=for calss 3 group 6 kid/admission
      inputParam.tags = [activeYear]; //2020-21
      inputParam.title = paymentTitle;
      let kidsList = [];
      //   for (const val of activeFilterKid) {
      //     kidsList.push(val.value);
      //   }
      kidsList.push(activeStudentId);
      inputParam.recipientIds = kidsList;
      if (activeAcc !== "" || activeAcc) {
        inputParam.paymentOptionId = activeAcc.id;
      }
      if (receiptAddress.length > 0 && selectedAddress.id != "schoolId") {
        inputParam.receiptAddressId = selectedAddress.id;
      }
      // inputParam.createdBy = //teacherId
      props.createInvoiceWithFunction(inputParam);
    }
  };
  useEffect(() => {
    if (props.successResCreateInvoice && !props.showLoaderCreateInvoice) {
      toastr.success("Invoice Created Successfully", { timeOut: 1500 });
      showPaymentModalOps(false);
      props.setInvoiceSuccessComplete();
    }
  }, [props.successResCreateInvoice]);
  const updateInvoiceData = (key, val, updatedValue) => {
    let tempItems = Object.assign([], invoiceItemList);
    let totalAmount = 0;
    let totalTax = 0;
    let totalRoyalty = 0;
    tempItems[key][val] = updatedValue;
    for (let i in tempItems) {
      tempItems[i].totalAmount =
        (tempItems[i].discountType === "1" || tempItems[i].discountType === 1)
          ? tempItems[i].amount - tempItems[i].discount
          : tempItems[i].amount -
          (tempItems[i].amount * tempItems[i].discount) / 100;
      totalAmount += tempItems[i].totalAmount;
      tempItems[i].discountDisabled = tempItems[i].discount > 0 ? true : false;
      tempItems[i].discountRemark = tempItems[i].discountDisabled
        ? tempItems[i].discountRemark
        : "";

      if (tempItems[i].tax > 0) {
        let tTax = roundToTwo(tempItems[i].totalAmount * 0.01 * tempItems[i].tax);
        tempItems[i].taxAmount = tTax
        totalTax = totalTax + tTax
        tempItems[i].totalAmount = tempItems[i].totalAmount + tTax
      } else {
        tempItems[i].taxAmount = 0;
      }

      if (tempItems[i].royalty > 0) {
        tempItems[i].royalty = parseFloat(tempItems[i].royalty)
        tempItems[i].royaltyAmount = (tempItems[i].totalAmount * tempItems[i].royalty) / 100;
        totalRoyalty += tempItems[i].royaltyAmount;
      } else {
        tempItems[i].royaltyAmount = 0;
      }
    }
    setRoyaltyAmount(totalRoyalty);
    setInvoiceTax(totalTax);
    newInvoiceTotalOps(totalAmount);
    invoiceItemListOps(tempItems);
    if (totalAmount > 0) {
      amountErrorOps("");
    } else {
      amountErrorOps("Amount cannot be zero");
    }
  };

  const getAdmissionLink = () => {
    if (
      props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[1] > 0
    ) {
      return (
        WhiteLable.getHost() + "/franchise-admission-form/" +
        props.franchise.id +
        "/" +
        selectedEquiryObject.equiryObject.attributes.schoolId +
        "/" +
        selectedEquiryObject.equiryObject.id
      );
    } else {
      return (
        WhiteLable.getHost() + "/admission-form/" +
        selectedEquiryObject.equiryObject.attributes.schoolId +
        "/" +
        selectedEquiryObject.equiryObject.id
      );
    }
  };

  const getEnquiryCreatedBy = (enquiry, teachers) => {
    let createdBy = enquiry.attributes.createdBy

    if (createdBy === "DM") {
      return (
        <p className="mt-1 font-size-10">
          {"Created via: "}
          <br />
          {"Digital Marketing Lead"}
        </p>
      );
    } else if (createdBy === "API") {
      return (
        <p className="mt-1 font-size-10">
          {"Created via: "}
          <br />
          {"School website"}
        </p>
      );
    } else if (createdBy === "QRCode") {
      return (
        <p className="mt-1 font-size-10">
          {"Created via: "}
          <br />
          {"QR code"}
        </p>
      );
    } else if (enquiry.attributes.isBulkUpdate) {
      return (
        <p className="mt-1 font-size-10">
          {"Created via: "}
          <br />
          {"Bulk import"}
        </p>
      );
    } else if (
      teachers &&
      teachers.length > 0 &&
      createdBy &&
      createdBy.length > 0
    ) {
      var temp = teachers.filter((teacher) => teacher.id === createdBy);
      if (temp && temp.length > 0) {
        return (
          <p className="mt-1 font-size-10">
            {"Created by: "}
            <br />
            {temp[0].attributes.Name.length > 20
              ? temp[0].attributes.Name.slice(0, 19)
              : temp[0].attributes.Name}
          </p>
        );
      }
    }

    return ''
  }

  function isFeatureAvilable(schoolPermissionIndex, userPermissionIndex) {

    if (props.selectedSchool && props.selectedSchool.attributes.featuresList &&
      props.selectedSchool.attributes.featuresList.length >= schoolPermissionIndex &&
      props.selectedSchool.attributes.featuresList[schoolPermissionIndex] > 0
    ) {
      if (props.userTeacher.attributes.featurePermissions != undefined &&
        props.userTeacher.attributes.featurePermissions.length >
        userPermissionIndex
        &&
        props.userTeacher.attributes.featurePermissions[userPermissionIndex] >=
        1) {
        return true
      }
    }
    return false
  }



  const sendCourseSubscribeInvite = (enquiry) => {
    setSendInviteEnquiryLoading(true);
    // console.log("enquiryId: ", enquiry.id);
    Parse.Cloud.run("function_sendCourseSubscribeInvite", {
      enquiryId: enquiry.id
    }).then((response) => {
      setSendCourseInvite(undefined);
      props.updateEnquiry(response.enquiry)
      toastr.success(props.t("Invite sent successfully"), { timeOut: 1000 });
    }, (error) => {
      console.log("error" + error.message)
    });
  }


  function getEnquiryRow(enquiry) {
    return (
      <tr key={enquiry.id}>
        <td>
          <div className="custom-control custom-checkbox">
            <input
              type="checkbox"
              className="custom-control-input"
              id={enquiry.id}
              checked={selectedEquiryIds.includes(enquiry.id)}
              onChange={() => { handelSelecteEnquiry(enquiry) }}
            />
            <label
              className="custom-control-label"
              onClick={() => {
                // handelSelecteEnquiry(enquiry);
              }}
            ></label>
          </div>
        </td>
        <td>
          <h5 className="font-size-14 ">
            {props.schoolType === 10 ? <Label className="text-dark">
              {enquiry.attributes.kidName && enquiry.attributes.kidName.length > 20
                ? enquiry.attributes.kidName.slice(0, 15)
                : enquiry.attributes.kidName}
            </Label> : <Link
              to={
                "/enquiryprofile/" +
                enquiry.attributes.schoolId +
                "/" +
                enquiry.id
              }
              className="text-dark"
            >
              {" "}
              {enquiry.attributes.kidName && enquiry.attributes.kidName.length > 20
                ? enquiry.attributes.kidName.slice(0, 15)
                : enquiry.attributes.kidName}
            </Link>
            }

          </h5>
          {getEnquiryCreatedBy(enquiry, props.teachers)}
        </td>
        <td>
          {formatDate(enquiry.createdAt)}
          <br />
          {getDays(enquiry.createdAt)}
        </td>
        {props.schoolType === 10 || props.schoolType === 11 || props.schoolType === 15 ?
          <td>
            <p className="text-muted mb-0">

              <i className="bx bx-mobile" />{" " + enquiry.attributes.phoneNumber}
              <br />
              <i className="bx bx-envelope" />{" " + enquiry.attributes.email}
            </p>

          </td>
          :
          <td>
            {enquiry.attributes.motherName && (
              <p className="text-muted mb-0">
                <i className="bx bx-female" />
                {enquiry.attributes.motherName.length > 20
                  ? enquiry.attributes.motherName.slice(0, 19)
                  : enquiry.attributes.motherName}{" "}
                : <br /> <i className="bx bx-mobile" />
                {enquiry.attributes.motherMobile}
              </p>
            )}
            {enquiry.attributes.fatherName && (
              <p className="text-muted mb-0">
                <i className="bx bx-male" />
                {enquiry.attributes.fatherName.length > 20
                  ? enquiry.attributes.fatherName.slice(0, 19)
                  : enquiry.attributes.fatherName}{" "}
                : <br /> <i className="bx bx-mobile" />
                {enquiry.attributes.fatherMobile}
              </p>
            )}
          </td>
        }
        <td>
          {enquiry.attributes.enquiryFor &&
            enquiry.attributes.enquiryFor.map((item, key) => (
              <p key={key} className="text-muted mb-0">
                {item && item.length > 20 ? item.slice(0, 19) : item}
              </p>
          ))}
          <small>Age: {calculateAge(enquiry.attributes.dateOfBirth)}</small>                                
        </td>

        {props.schoolType == 10 ? <td>
          {enquiry.attributes.workPlace && enquiry.attributes.workPlace.length > 20
            ? enquiry.attributes.workPlace.slice(0, 19)
            : enquiry.attributes.workPlace}
        </td> : <td>
          {enquiry.attributes.source && enquiry.attributes.source.length > 20
            ? enquiry.attributes.source.slice(0, 19)
            : enquiry.attributes.source}
        </td>}
        {props.schoolType != 10 ? <td>
          {enquiry.attributes.deleted && false ? (
            <Badge
              className="font-size-12 badge-soft-danger ml-2"
              color="danger"
              pill
            >
              {"Deleted"}
            </Badge>
          ) : (
            getDropdwn(enquiry)
          )}
          {enquiry.attributes.status != 1 && enquiry.attributes.isDeleted != true && <Badge
            style={{ cursor: 'pointer', marginLeft: "12px" }}
            className="font-size-13 badge-soft-success"
            color="success"
            pill
            onClick={() => {
              setShowEnquiryResharePopup(true);
              setEnquiryObject(enquiry)
            }}
          >
            Reshare
          </Badge>}
          {/* <Badge className={"font-size-12 badge-soft-"+getStatueColor(enquiry.attributes.status)} color="success" pill>{getStatue(enquiry.attributes.status)}<i className="mdi mdi-chevron-down"></i></Badge> */}
        </td>
          :
          <td>

            {enquiry.attributes.status == 1 ? (
              <Badge
                className="font-size-12 badge-soft-success ml-2"
                color="success"
                pill
              >
                {"Enrolled"}
              </Badge>
            ) : (
              <Badge
                className="font-size-12 badge-soft-primary ml-2"
                color="success"
                pill
              >
                {"Enquiry"}
              </Badge>
            )}

          </td>
        }
        {props.schoolType != 10 ? <td>
          <Link
            to={
              "/enquiryprofile/" +
              enquiry.attributes.schoolId +
              "/" +
              enquiry.id
            }
          >
            <Button
              type="button"
              color="primary"
              size="sm"
              className="btn-rounded waves-effect waves-light"
            >
              {props.t("View Details")}
            </Button>
          </Link>
        </td>
          :
          <td>

            {enquiry.attributes.status == 2 && enquiry.attributes.courseId ? (
              <Button
                type="button"
                color="primary"
                size="sm"
                className="btn-rounded waves-effect waves-light"
                onClick={() => {
                  setSendInviteEnquiryLoading(false);
                  setSendCourseInvite({ type: "resend", enquiry: enquiry })
                }}
              >
                {props.t("Resend Invite")}
              </Button>
            ) : (
              <>
                {(enquiry.attributes.status == 0 || !enquiry.attributes.status) && enquiry.attributes.courseId && <Button
                  type="button"
                  color="primary"
                  size="sm"
                  className="btn-rounded waves-effect waves-light"
                  onClick={() => {
                    setSendInviteEnquiryLoading(false);
                    setSendCourseInvite({ type: "send", enquiry: enquiry })
                  }}
                >
                  {props.t("Send Invite")}
                </Button>}
              </>
            )}

          </td>
        }
      </tr>
    );
  }

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <Container className="mb-1 py-1 row">
            {props.schoolType !== 10 && <Col lg={3} xs={12} className="justify-content-center bg-light  ">
              <FormGroup className="mt-2 mb-2 p-1">
                <Label className="mb-0">
                   {props.t("Filter by Status") } {filterValue === "Deleted" ?( props.deletedEnquiries ? `(${props.deletedEnquiries.length})`:"(0)") :  `(${getDisplayList().length})`}
                </Label>
                <select
                  className="form-control "
                  style={{ width: "14rem" }}
                  value={filterValue}
                  onChange={(e) => {
                    updateFilter(e.target.value);
                  }}
                >
                  {props.schoolType == 15 ?
                    enquiryStates2.map((value, key) => (
                      <option key={key}>{value} </option>
                    )) :
                    enquiryStates.map((value, key) => (
                      <option key={key}>{value} </option>
                    ))}
                </select>
              </FormGroup>
            </Col>}
            <Col lg={9} xs={12}>
              <Row className="justify-content-center bg-light ml-2">
                {/* <Form
                  onValidSubmit={(e, v) => {
                    handelDateChange();
                  }}
                > */}
                <Row>
                  <Col lg={4} xs={6}>
                    <FormGroup className="mt-2 mb-2 p-1">
                      <Label className="mb-0">
                        {props.t("From")} :
                      </Label>
                      <DatePicker
                        selected={fromDate}
                        onChange={(date) => {
                          setFromDateError(false);
                          setFromDate(date)
                        }}
                        className="form-control"
                        placeholderText={props.t("Select Date")}
                        startDate={fromDate}
                        endDate={toDate}
                        maxDate={toDate}
                        dateFormat="dd/MM/yyyy"
                        required
                        errorMessage="Please select"
                      />
                    </FormGroup>
                    {fromDateError && <p className="text-danger ml-2">Please select</p>}
                  </Col>
                  <Col lg={4} xs={6}>
                    <FormGroup className="mt-2 mb-2 p-1">
                      <Label className="mb-0">
                        {props.t("To")} :
                      </Label>
                      <DatePicker
                        selected={toDate}
                        onChange={(date) => {
                          setToDateError(false);
                          setToDate(date)
                        }}
                        className="form-control"
                        placeholderText={props.t("Select Date")}
                        startDate={fromDate}
                        endDate={toDate}
                        minDate={fromDate}
                        dateFormat="dd/MM/yyyy"
                        required
                        errorMessage="Please select"
                      />
                    </FormGroup>
                    {toDateError && <p className="text-danger ml-2">Please select</p>}
                  </Col>
                  <Col lg={3} >
                    <div className="mt-4 mb-2 p-1">
                      <button
                        onClick={(e, v) => {
                          let next = true;
                          if (!toDate) {
                            setToDateError(true);
                            next = false;
                          }
                          if (!fromDate) {
                            setFromDateError(true);
                            next = false;
                          }
                          if (next) {
                            handelDateChange();
                          }

                        }}
                        className="w-md  btn btn-primary"
                      >
                        {props.t("Apply")}
                      </button>

                    </div>
                  </Col>
                </Row>
                {/* </Form> */}
              </Row>
            </Col>
          </Container>
          {selectedEquiryIds && selectedEquiryIds.length > 0 && (
            <Row>
              <Col className="d-flex justify-content-start">
                {filterValue === "Deleted" ? (
                  <button
                    onClick={() => {
                      setShowRestorePopup(true);
                    }}
                    type="button"
                    className="btn btn-success waves-effect waves-light ml-4 mb-2 ml-2"
                  >
                    <i className="bx bx-refresh font-size-16 align-middle"></i>
                    {props.t(`Restore Enquiries (${selectedEquiryIds.length})`)}
                  </button>
                ) : (
                  <button
                    onClick={() => {
                      setShowDeletePopup(true);
                    }}
                    type="button"
                    className="btn btn-danger waves-effect waves-light m-1"
                  >
                    <i className="bx bx-trash-alt font-size-16 align-middle m-1"></i>
                      {props.t(`Delete (${selectedEquiryIds.length})`)}
                  </button>
                )}
              </Col>
            </Row>
          )}
          <div className="table-responsive">
            <table className="table table-centered table-nowrap mb-0">
              <thead className="thead-light">
                <tr>
                  <th style={{ width: "20px" }}>
                    <div className="custom-control custom-checkbox">
                      <input
                        type="checkbox"
                        className="custom-control-input"
                        id="customCheck1"
                        checked={selectAll}
                        onChange={() => {
                          handelSelecteAll();
                        }}
                      />
                      <label
                        className="custom-control-label"
                        onClick={() => {
                          // handelSelecteAll();
                        }}
                      ></label>
                    </div>
                  </th>



                  <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      nameOrder === "ascending"
                        ? setNameOrder("descending")
                        : setNameOrder("ascending");
                      handelSortBy("kidName", nameOrder);
                    }}

                  >
                    <i
                      className={nameOrder ? `mdi mdi-18px mdi-order-alphabetical-${nameOrder} mr-1` : 'mdi mdi-18px mdi-sort mr-1'}
                    ></i>
                    {props.t("Name")}
                  </th>


                  <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      dateOrder === "ascending"
                        ? setDateOrder("descending")
                        : setDateOrder("ascending");
                      handelSortBy("createdAt", dateOrder);
                    }}
                  >
                    <i
                      className={dateOrder ? `mdi mdi-18px mdi-sort-numeric-${dateOrder} mr-1` : 'mdi mdi-18px mdi-sort mr-1'}
                    ></i>
                    {props.t("Date")}
                  </th>
                  <th>{(props.schoolType === 10 || props.schoolType == 15) ? props.t("Contact Details") : props.t("Parent Details")}</th>


                  <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      enquiryForOrder === "ascending"
                        ? setEnquiryForOrder("descending")
                        : setEnquiryForOrder("ascending");
                      handelSortBy("enquiryFor", enquiryForOrder);
                    }}
                  >
                    <i
                      className={enquiryForOrder ? `mdi mdi-18px mdi-order-alphabetical-${enquiryForOrder} mr-1` : 'mdi mdi-18px mdi-sort mr-1'}
                    ></i>
                    {props.t("Enquiry For")}
                  </th>


                  {props.schoolType == 10 ? <th>{props.t("Organisation")}</th> : <th
                    style={{ width: "0.5rem", cursor: "pointer" }}
                    onClick={() => {
                      sourceOrder === "ascending"
                        ? setSourceOrder("descending")
                        : setSourceOrder("ascending");
                      handelSortBy("source", sourceOrder);
                    }}
                  >
                    <i
                      className={sourceOrder ? `mdi mdi-18px mdi-order-alphabetical-${sourceOrder} mr-1` : 'mdi mdi-18px mdi-sort mr-1'}
                    ></i>
                    {props.t("Source")}
                  </th>}
                  {<th>{props.t("Status")}</th>}

                  {props.schoolType == 10 ? <th>{props.t("Action")}</th> : <th>{props.t("View Details")}</th>}
                </tr>
              </thead>
              {(onSort || !onSort) && <tbody>
                {filterValue !== "Deleted" && getDisplayList(onSort).length === 0 && (
                  <tr>
                    <td colSpan="10" className="pt-4">
                      <h3 className="text-center text-info">
                        {props.t("No Enquiries found .")}
                      </h3>
                    </td>
                  </tr>
                )}
                {filterValue !== "Deleted" &&
                  getDisplayList(onSort).map((enquiry, key) =>
                    getEnquiryRow(enquiry, key)
                  )}

                {filterValue === "Deleted" &&
                  (!props.deletedEnquiries ||
                    props.deletedEnquiries.length === 0) && (
                    <tr>
                      <td colSpan="10" className="pt-4">
                        <h3 className="text-center text-info">
                          {props.deletedEnquiries ? (
                            props.t("No Deleted Enquiries found ")
                          ) : (
                            <div>
                              <i className="bx bx-loader bx-spin  align-middle mr-2"></i>{" "}
                              {props.t("Loading Deleted Enquiries")}
                            </div>
                          )}
                        </h3>
                      </td>
                    </tr>
                  )}
                {filterValue === "Deleted" &&
                  props.deletedEnquiries &&
                  props.deletedEnquiries.length > 0 &&
                  props.deletedEnquiries.map((enquiry, key) =>
                    getEnquiryRow(enquiry, key)
                  )}
                {filterValue !== "Deleted" && loadMore ==-1&&
                  // <p onClick={() => { setLoadMore(1) }}><u>Load More</u></p>
                  <tr className="text-center">
                    <td colSpan="10" className="pt-4">
                      <button className="btn btn-primary  text-center " onClick={() => { setLoadMore(1) }}>
                        {props.t("Load More")}
                      </button>
                    </td>
                  </tr>
                
                }
              </tbody>}
            </table>
            <div style={{ height: "400px" }}></div>
          </div>

          <Modal
            isOpen={toggleLostReason}
            toggle={() => {
              toggleLostReasonModel(!toggleLostReason);
              setSelectedEquiryObject(undefined);
            }}
          >
            <ModalHeader
              toggle={() => {
                toggleLostReasonModel(false);
                setSelectedEquiryObject(undefined);
              }}
            >
              <div className="modal-title mt-0" id="myModalLabel">
                {props.t("Why we lost Enquiry?")}
              </div>
            </ModalHeader>
            <ModalBody>
              <div>
                {enquiryLostReasons.map((skill, key) => (
                  <Badge
                    style={{ cursor: "pointer" }}
                    key={key}
                    onClick={(e) => {
                      var lostReasons = [...selectedEquiryObject.lostReasons];
                      if (lostReasons.includes(skill)) {
                        var temp = lostReasons.filter((r) => r != skill);
                        lostReasons = temp;
                      } else {
                        setLostError(false);
                        lostReasons.push(skill);
                      }
                      var obj = {
                        ...selectedEquiryObject,
                        lostReasons: lostReasons,
                      };
                      setSelectedEquiryObject(obj);
                    }}
                    className={
                      selectedEquiryObject &&
                        selectedEquiryObject.lostReasons.includes(skill)
                        ? "badge badge-primary font-size-14 m-2"
                        : "badge badge-soft-primary font-size-14 m-2"
                    }
                  >
                    {skill}
                  </Badge>
                ))}
              </div>

              <FormGroup className="px-1">
                <Label size="16">{props.t("OR")}</Label>
                <Input
                  type="text"
                  name="lostReason"
                  className="inner form-control noscroll"
                  placeholder="Type your reason"
                  value={selectedEquiryObject && selectedEquiryObject.reason}
                  onChange={(e) => {
                    if (e.target.value && e.target.value.length > 0) {
                      setLostError(false);
                    }
                    var obj = {
                      ...selectedEquiryObject,
                      reason: e.target.value,
                    };
                    setSelectedEquiryObject(obj);
                  }}
                />
              </FormGroup>

              {lostError && (
                <p className="text-danger ml-2">
                  {props.t("Please choose the reason")}
                </p>
              )}
            </ModalBody>
            <ModalFooter>
              <button
                type="button"
                onClick={() => {
                  toggleLostReasonModel(false);
                  setSelectedEquiryObject(undefined);
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                {props.t("Close")}
              </button>
              <button
                type="submit"
                className="btn btn-primary waves-effect waves-light"
                onClick={() => {
                  if (
                    selectedEquiryObject.lostReasons.length === 0 &&
                    selectedEquiryObject.reason.length === 0
                  ) {
                    setLostError(true);
                  } else {
                    handelLostEnquiryRespose();
                  }
                }}
              >
                {props.t("Save")}
              </button>
            </ModalFooter>
          </Modal>

          {showNoKidName && (
            <Modal
              isOpen={showNoKidName}
              toggle={() => {
                setShowNoKidName(undefined);
              }}
            >
              <ModalHeader
                toggle={() => {
                  setShowNoKidName(undefined);
                }}
              >
                <div className="modal-title mt-0" id="myModalLabel">
                  {props.t("Alert")}
                </div>
              </ModalHeader>

              <ModalBody>
                <p className=" m-4">
                  {props.t(
                    "To move this enquiry to admission please update student name. To update please click on Goto Details button."
                  )}
                </p>
              </ModalBody>

              <ModalFooter>
                <button
                  type="button"
                  onClick={() => {
                    setShowNoKidName(undefined);
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  {props.t("Close")}
                </button>

                <Link
                  to={
                    "/enquiryprofile/" +
                    showNoKidName.attributes.schoolId +
                    "/" +
                    showNoKidName.id
                  }
                >
                  <button
                    type="submit"
                    className="btn btn-primary waves-effect waves-light"
                  >
                    {props.t("Goto Details")}
                  </button>
                </Link>
              </ModalFooter>
            </Modal>
          )}

          <Modal isOpen={showAdmissionPopup || props.loding}>
            <ModalHeader
              toggle={() => {
                setShowAdmissionPopup(false);
                setShowAdmissionLink(false);
                setSelectedEquiryObject(undefined);
              }}
            >
              <div className="modal-title mt-0" id="myModalLabel">
                {showAdmissionLink && !props.loding
                  ? props.t("Moved to Admissions successfully!")
                  : props.t("Move to Admissions")}
                <br />
                {/* {showAdmissionLink && !props.loding && (
                  <p
                    style={{
                      fontSize: "13px",
                      marginBottom: "-8px",
                      marginTop: "7px",
                      fontWeight: "100",
                    }}
                  >
                    Do you want to{" "}
                    <a
                      onclick={() => {
                        prepareInvoiceToGenerate(
                          selectedEquiryObject.equiryObject
                        );
                        setShowAdmissionPopup(false);
                        setShowAdmissionLink(false);
                        setSelectedEquiryObject(undefined);
                        //create invoice here
                        showPaymentModalOps(true);
                      }}
                    >
                      create invoice
                    </a>{" "}
                    for the admission?
                  </p>
                )} */}
              </div>
            </ModalHeader>
            <ModalBody>
              {showAdmissionLink && !props.loding ? (
                <div>
                  <p>{props.t("Admission form link")}</p>
                  <Card className="border-1">
                    <Row>
                      <Col lg={10}>
                        <p
                          disabled
                          type="text"
                          className=" m-2 text-primary"
                          id="linkarea"
                        >
                          {getAdmissionLink()}
                        </p>
                      </Col>
                      <Col
                        lg={2}
                        id="admissionlink"
                        onClick={(e) => {
                          copyAdmissionLink(getAdmissionLink());
                        }}
                      >
                        <li className="list-inline-item px-2">
                          <h1
                            style={{ cursor: "pointer" }}
                            className="text-primary"
                          >
                            <i className="bx bxs-copy"></i>
                          </h1>
                          <p style={{ cursor: "pointer" }}>{props.t("copy")}</p>
                          <UncontrolledTooltip
                            placement="top"
                            target="admissionlink"
                          >
                            {props.t("copy to clipboard")}
                          </UncontrolledTooltip>
                        </li>
                      </Col>
                    </Row>
                  </Card>
                </div>
              ) : (
                <p className=" m-4">
                  {props.t(
                    "Are you sure you want to move enquiry to admissions"
                  )}
                </p>
              )}
            </ModalBody>

            <ModalFooter>
              {showAdmissionLink && !props.loding && (
                <>{isFeatureAvilable(
                  Constants.FEATURE_PAYMENTS_INDEX,
                  Constants.TEACHER_PAYMENTS_INDEX
                ) && <button
                  type="button"
                  onClick={() => {
                    prepareInvoiceToGenerate(selectedEquiryObject.equiryObject);
                    setShowAdmissionPopup(false);
                    setShowAdmissionLink(false);
                    setSelectedEquiryObject(undefined);
                    //create invoice here
                    setTaxable(false);
                    setTaxError(false);
                    showPaymentModalOps(true);
                  }}
                  className="btn btn-primary waves-effect btn-sm"
                  data-dismiss="modal"
                >
                    {props.t("Create Invoice")}
                  </button>}</>
              )}
              {showAdmissionLink && !props.loding && <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={() => {
                  // console.log(getparentEmailsAndPhoneNubers())
                  let phoneNumber = (getparentEmailsAndPhoneNubers().phoneNumbers[0])
                  let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name

                  let link = getAdmissionLink();
                  const message = `Dear Parent,\n\nWe extend a very warm welcome to you into our ${schoolName} Family.\n\nThank you for sharing your most precious treasure with us. Please fill the admission form:\n${link}\n\nWe look forward to having your little one at ${schoolName}.\n\nPowered by lilTriangle`;
                  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                  window.open(whatsappUrl);
                  // web.whatsapp.com
                }}
              ><a className="bx bxl-whatsapp text-white"></a>Mother</button>}
              {showAdmissionLink && !props.loding && <button
                type="button"
                className="btn btn-success btn-sm"
                onClick={() => {
                  let phoneNumber = (getparentEmailsAndPhoneNubers().phoneNumbers[1])
                  let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name

                  let link = getAdmissionLink()
                  const message = `Dear Parent,\n\nWe extend a very warm welcome to you into our ${schoolName} Family.\n\nThank you for sharing your most precious treasure with us. Please fill the admission form:\n${link}\n\nWe look forward to having your little one at ${schoolName}.\n\nPowered by lilTriangle`;
                  const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                  window.open(whatsappUrl);
                  // web.whatsapp.com
                }}
              ><a className="bx bxl-whatsapp text-white"></a>Father</button>}
              {showAdmissionLink && !props.loding &&

                <button
                  type="button"
                  className="btn btn-info btn-sm"
                  //here
                  onClick={() => {
                    let details = getparentEmailsAndPhoneNubers();
                    let schoolName = (props.selectedSchool && props.selectedSchool.attributes.Name) ? props.selectedSchool.attributes.Name : "School"
                    props.sendAdmissionLinkToParent(
                      selectedEquiryObject.equiryObject.id, details.emails, details.phoneNumbers, getAdmissionLink(), schoolName, props.schoolId
                    );
                  }}
                >
                  {props.t("Send Link To Parent")}
                </button>
              }

              {!props.loding ? (
                <Row className="text-center">
                  <div className="d-flex">
                    {!showAdmissionLink && <button
                      type="button"
                      onClick={() => {
                        setShowAdmissionPopup(false);
                        setShowAdmissionLink(false);
                        setSelectedEquiryObject(undefined);
                      }}
                      className="btn btn-secondary waves-effect m-1"
                      data-dismiss="modal"
                    >
                      {props.t("Cancel")}
                    </button>}
                    {!showAdmissionLink && (
                      <button
                        type="submit"
                        className="btn btn-primary waves-effect waves-light m-1"
                        onClick={() => {
                          handelMoveToAdmission();
                          // setShowAdmissionLink(true);
                        }}
                      >
                        {props.t("Move to Admissions")}
                      </button>
                    )}
                  </div>
                </Row>
              ) : (
                <button type="button" className="btn btn-light waves-effect">
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                  {props.t(" Moving to Admissions")}
                </button>
              )}
            </ModalFooter>
          </Modal>

          <Modal isOpen={showDeletePopup || props.bulkDeleteLoading}>
            <ModalHeader>
              <div className="modal-title mt-0" id="myModalLabel">
                {props.t("Delete Enquiries")}
              </div>
            </ModalHeader>
            <ModalBody>
              <p className=" m-4">
                {props.t("Are you sure you want delete selected Enquiries?")}
              </p>
            </ModalBody>

            <ModalFooter>
              {!props.bulkDeleteLoading ? (
                <Row className="text-center">
                  <Col className="d-flex justify-content-end">
                    <button
                      type="button"
                      onClick={() => {
                        setShowDeletePopup(false);
                      }}
                      className="btn btn-secondary waves-effect m-1"
                      data-dismiss="modal"
                    >
                      {props.t("Cancel")}
                    </button>
                    <button
                      type="submit"
                      className="btn btn-danger waves-effect waves-light m-1"
                      onClick={() => {
                        handelBulkDelete();
                      }}
                    >
                      {props.t("Delete")}
                    </button>
                  </Col>
                </Row>
              ) : (
                <button type="button" className="btn btn-light waves-effect float-end">
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                  {props.t(" Deleting Enquiries")}
                </button>
              )}
            </ModalFooter>
          </Modal>

          <Modal isOpen={showRestorePopup || props.bulkRestoreLoading}>
            <ModalHeader>
              <div className="modal-title mt-0" id="myModalLabel">
                {showRestorePopup === true
                  ? props.t("Restore Enquiries")
                  : props.t("Restore Enquiry ")}
              </div>
            </ModalHeader>
            <ModalBody>
              <p className=" m-4">
                {showRestorePopup === true
                  ? props.t(
                    "Are you sure you want restore selected Enquiries?"
                  )
                  : props.t("Are you sure you want restore selected Enquiry?")}
                { }
              </p>
            </ModalBody>

            <ModalFooter>
              {!props.bulkRestoreLoading ? (
                <Row className="text-center">
                  <Col>
                    <div className="d-flex justify-content-end">
                      <button
                        type="button"
                        onClick={() => {
                          setShowRestorePopup(false);
                        }}
                        className="btn btn-secondary waves-effect m-1"
                        data-dismiss="modal"
                      >
                        {props.t("Cancel")}
                      </button>
                      <button
                        type="submit"
                        className="btn btn-success waves-effect waves-light m-1"
                        onClick={() => {
                          if (showRestorePopup === true) {
                            handelBulkRestore();
                          } else {
                            props.enquiryBulkRestore([showRestorePopup]);
                            setShowRestorePopup(false);
                          }
                        }}
                      >
                        {props.t("Restore")}
                      </button>
                    </div>
                  </Col>
                </Row>
              ) : (
                <button type="button" className="btn btn-light waves-effect">
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                  {props.t(" Restoring")}
                </button>
              )}
            </ModalFooter>
          </Modal>

          <Modal
            size="xl"
            isOpen={showPaymentModal}
            toggle={() => {
              showPaymentModalOps(false);
            }}
          >
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                {props.t("New Invoice")}
              </h5>
              <button
                type="button"
                onClick={() => {
                  showPaymentModalOps(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <h5>
                {props.t("Selected Student")}: {activeStudent}
              </h5>
              <AvForm className="outer-repeater">
                <Row>
                  <Col>
                    <FormGroup className="mt-2 mb-2 p-1">
                      <Label className="mb-0">
                        {props.t("Payment Title")} :
                      </Label>
                      <AvField
                        type="text"
                        name="name"
                        placeholder={props.t("Enter enter title...")}
                        className="form-control"
                        value={paymentTitle}
                        validate={{
                          required: {
                            value: true,
                            errorMessage: props.t("Please enter title..."),
                          },
                        }}
                        onChange={(e) => {
                          paymentTitleOps(e.target.value);
                          if (e.target.value) {
                            paymentTitleErrorOps("");
                          }
                        }}
                      />
                      <span className="text-danger">{paymentTitleError}</span>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mt-2 mb-2 p-1">
                      <Label className="mb-0">
                        {props.t("Select Template")} :
                      </Label>
                      <select
                        className="form-control "
                        value={activeTemplate}
                        onChange={(e) => {
                          activeTemplateOps(e.target.value);
                          for (let val of feeTemplate) {
                            if (val.id === e.target.value) {
                              paymentTitleOps(val.title);
                            }
                          }
                        }}
                        disabled={templetRestiction}
                      >
                        <option value="">Select template</option>
                        {feeTemplate &&
                          feeTemplate.map((val, key) => (
                            <option key={key} value={val.id}>
                              {val.title}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>
                  <Col>
                    <FormGroup className="mt-2 mb-2 p-1">
                      <Label className="mb-0">{props.t("Select Year")} :</Label>
                      <select
                        className="form-control"
                        value={activeYear}
                        onChange={(e) => {
                          activeYearOps(e.target.value);
                        }}
                      >
                        {list.map((val, key) => (
                          <option key={key} value={val}>
                            {val}
                          </option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                </Row>
                <Row>
                  {paymentOptions.length > 1 && <Col lg={4}>
                    <FormGroup >
                      <Label className="mb-0">{props.t("Payment Account")} </Label>


                      <select
                        className="form-control "
                        value={activeAcc.id}
                        onChange={(e) => {
                          let list = paymentOptions.filter(
                            (item) => item.id === e.target.value
                          );
                          activeAccOps(list[0]);
                        }}
                        placeholder="Select Program"
                      >

                        {
                          paymentOptions.map((val, key) => (
                            <option key={key} value={val.id}>
                              {val.displayName}
                            </option>
                          ))}
                      </select>


                    </FormGroup>
                  </Col>}

                  {receiptAddress.length > 0 && <Col Col lg={4}>
                    <FormGroup >
                      <Label className="mb-0 ">{props.t("Receipt Address")} </Label>
                      <select
                        className="form-control "
                        value={selectedAddress.id}
                        onChange={(e) => {
                          let list = receiptAddress.filter(
                            (item) => item.id === e.target.value
                          );
                          if (list[0].object) {
                            setTaxable(list[0].object.attributes.taxable)
                          } else {
                            setTaxable(false)
                          }


                          setSelectedAddress(list[0]);
                          // console.log(selectedAddress)
                        }}
                        placeholder="Select Program"
                      >

                        {
                          receiptAddress.map((val, key) => (
                            <option key={key} value={val.id}>
                              {val.name}
                            </option>
                          ))}
                      </select>
                    </FormGroup>
                  </Col>}

                  {templetRestiction && <Col lg={4}>
                    <FormGroup >
                      <Label className="mb-0">{props.t("Revenue period ")} </Label>


                      <Row>
                        <Col>
                          <DatePicker
                            className="form-control "
                            selected={revenueStartDate}
                            onChange={(date) => setRevenueStartDate(date)}
                            selectsStart
                            startDate={revenueStartDate}
                            endDate={revenueEndDate}
                            dateFormat="MMM-yyyy"
                            showMonthYearPicker
                            placeholder={"Revenue Starts on"}
                            maxDate={revenueEndDate}
                          />
                        </Col>
                        <p className="mt-2">to</p>
                        <Col>
                          <DatePicker
                            className="form-control "
                            selected={revenueEndDate}
                            onChange={(date) => setRevenueEndDate(date)}
                            selectsEnd
                            startDate={revenueStartDate}
                            endDate={revenueEndDate}
                            minDate={revenueStartDate}
                            dateFormat="MMM-yyyy"
                            showMonthYearPicker
                            placeholder={"Revenue Ends on"}
                          />
                        </Col>
                      </Row>

                    </FormGroup>
                  </Col>}

                  {false && <Col>
                    <FormGroup>
                      <div className="custom-control custom-checkbox mb-2 ml-3 float-right " >
                        <input type="checkbox" className="custom-control-input" id="instituteCheck" onChange={() => false} checked={taxable} />
                        <label className="custom-control-label" onClick={() => {
                          if (taxable) {
                            let istaxed = false
                            for (const ii of invoiceItemList) {
                              if (ii.tax > 0) {
                                istaxed = true;
                              }
                            }

                            if (!istaxed) {
                              setTaxable(!taxable);
                              setTaxError(false);
                            } else {
                              setTaxError(true)
                            }
                          } else {
                            setTaxable(!taxable);
                          }

                        }} >{props.t('GST Invoice')}</label>
                      </div>

                    </FormGroup>
                    {taxError && <Alert color="info" className="mt-1 text-center" role="alert">
                      <i className="mdi mdi-alert-circle-outline mr-2"></i>
                      Remove GST from all items
                    </Alert>}

                  </Col>}
                </Row>
                <Row>
                  <Col>
                    <Table className="table table-nowrap mb-0">
                      <thead>
                        <tr>
                          <th>{props.t("Slno")}</th>
                          <th>{props.t("Particular")}</th>
                          <th>{props.t("Due Date")}</th>
                          <th>{props.t("Amount")}</th>
                          <th>{props.t("Discount")}</th>
                          <th>{props.t("Discount Remark")}</th>
                          {taxable && <th>{props.t("GST")}</th>}
                          {showRoyalty && <th>{props.t("Royalty")}</th>}
                          <th>{props.t("Item Total")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {invoiceItemList.map((val, key) => (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              <FormGroup>
                                <AvField
                                  type="text"
                                  name="Particular"
                                  placeholder={props.t("Enter Particular...")}
                                  className="form-control"
                                  value={val.itemDescription}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: props.t(
                                        "Please enter particular..."
                                      ),
                                    },
                                  }}
                                  onChange={(e) => {
                                    updateInvoiceData(
                                      key,
                                      "itemDescription",
                                      e.target.value
                                    );
                                  }}
                                />
                                {val.hsn && <p className="text-size-12">{"HSN: " + val.hsn}</p>}
                              </FormGroup>
                            </td>
                            <td>
                              <FormGroup>
                                <DatePicker
                                  selected={val.dueDate}
                                  onChange={(date) => {
                                    updateInvoiceData(key, "dueDate", date);
                                  }}
                                  className="form-control"
                                  placeholderText={props.t("Selecte Date")}
                                  startDate={val.dueDate}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </FormGroup>
                            </td>
                            <td>
                              <FormGroup>
                                <AvField
                                  type="number"
                                  name={`particular_amount_${key}`}
                                  placeholder={props.t("Particular amount...")}
                                  className="form-control"
                                  value={val.amount}
                                  min={0}
                                  validate={{
                                    required: {
                                      value: true,
                                      errorMessage: props.t(
                                        "Please enter amount..."
                                      ),
                                    },
                                  }}
                                  onChange={(e) => {
                                    updateInvoiceData(
                                      key,
                                      "amount",
                                      e.target.value
                                    );
                                  }}
                                />
                              </FormGroup>
                            </td>
                            <td style={{ minWidth: "7rem" }}>
                              <InputGroup>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={val.discount}
                                  placeholder="number"
                                  min={0}
                                  onChange={(e) => {
                                    updateInvoiceData(
                                      key,
                                      "discount",
                                      e.target.value
                                    );
                                  }}
                                />
                                <div className="input-group-append">
                                  {/* <span className="input-group-text">%</span> */}
                                  <select
                                    value={val.discountType}
                                    onChange={(e) => {
                                      updateInvoiceData(
                                        key,
                                        "discountType",
                                        e.target.value
                                      );
                                    }}
                                    style={{ fontFamily: "Arial, sans-serif" }}
                                  >
                                    <option value="2">%</option>
                                    <option value="1">{currency}</option>
                                  </select>
                                </div>
                              </InputGroup>
                            </td>
                            <td>
                              <FormGroup>
                                <AvField
                                  type="text"
                                  name="remark"
                                  placeholder={props.t("Remark...")}
                                  className="form-control"
                                  disabled={val.discountEnabled}
                                  value={val.discountRemark}
                                  // validate={{ required: {
                                  //     value: true,
                                  //     errorMessage: props.t('Please enter father email...'),
                                  //   },}}
                                  onChange={(e) => {
                                    updateInvoiceData(
                                      key,
                                      "discountRemark",
                                      e.target.value
                                    );
                                  }}
                                />
                              </FormGroup>
                            </td>
                            {taxable && <td style={{ minWidth: "5rem" }}>
                              <InputGroup>

                                <div className="input-group-append">
                                  {/* <span className="input-group-text">%</span> */}
                                  <select
                                    value={val.tax}
                                    onChange={(e) => {
                                      updateInvoiceData(
                                        key,
                                        "tax",
                                        e.target.value === "0" ? undefined : parseInt(e.target.value)
                                      );
                                    }}
                                    style={{ fontFamily: "Arial, sans-serif" }}
                                  >
                                    <option value="0">0%</option>
                                    <option value="5">5%</option>
                                    <option value="12">12%</option>
                                    <option value="18">18%</option>
                                    <option value="28">28%</option>

                                  </select>
                                </div>
                                <input
                                  type="number"
                                  className="form-control"
                                  value={val.taxAmount}
                                  placeholder="00"
                                  min={0}
                                  disabled

                                />
                              </InputGroup>
                            </td>}
                            {showRoyalty &&
                              <td style={{ minWidth: "5rem" }}>
                                <InputGroup>
                                  <input
                                    type="number"
                                    className="form-control"
                                    value={val.royalty}
                                    placeholder="Royalty"
                                    min={0}
                                    onChange={(e) => {
                                      updateInvoiceData(
                                        key,
                                        "royalty",
                                        e.target.value
                                      );
                                    }}
                                  />
                                  <div className="input-group-append" style={{ fontFamily: "Arial, sans-serif", }}>
                                    {/* <span className="input-group-text">%</span> */}
                                    <select>
                                      <option value="2">%</option>
                                    </select>
                                  </div>
                                </InputGroup>
                                {val.royalty > 0 && <p className="text-center text-size-12">{currency}{val.royaltyAmount ?? 0}</p>}
                              </td>
                            }
                            <td>
                              <FormGroup>
                                <AvField
                                  type="number"
                                  name="total"
                                  placeholder={props.t("Total...")}
                                  className="form-control"
                                  disabled={true}
                                  value={parseFloat(val.totalAmount).toFixed(2)}
                                // validate={{ required: {
                                //     value: true,
                                //     errorMessage: props.t('Please enter father email...'),
                                //   },}}
                                // onChange={(e) => {
                                //     fatherEmailOps(e.target.value);
                                // }}
                                />
                              </FormGroup>
                            </td>
                            {(!showRoyalty || templetRestiction) && <td>
                              <i
                                onClick={() => removeItemFromNewInvoice(key)}
                                className="bx bx-message-square-x text-danger h3 mt-1"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>}
                          </tr>
                        ))}
                        {invoiceItemList.length === 0 && (
                          <tr>
                            <td
                              className="text-center text-Secondary h5 pt-5"
                              colSpan="7"
                            >
                              {showRoyalty ? props.t("To create invoice select template") : props.t("To create invoice start")}{" "}
                              {(!showRoyalty || templetRestiction) && <span
                                className="text-info"
                                onClick={addAnItemToInvoice}
                                style={{ cursor: "pointer" }}
                              >
                                <button className="btn btn-info">
                                  {" "}
                                  {props.t("Adding items")}{" "}
                                </button>
                              </span>}
                            </td>
                          </tr>
                        )}
                        {invoiceItemList.length > 0 && (!showRoyalty || templetRestiction) && (
                          <tr>
                            <td colSpan={taxable && showRoyalty ? "8" : (taxable || showRoyalty) ? "7" : "6"}></td>
                            <td>
                              <button
                                className="btn btn-info"
                                onClick={addAnItemToInvoice}
                                style={{ width: "100%" }}
                              >
                                + Add Item
                              </button>
                            </td>
                          </tr>
                        )}
                        {invoiceItemList.length > 0 && taxable && (
                          <tr>
                            <td colSpan="6"></td>
                            <td className="pb-0">
                              <FormGroup className="mb-0">
                                <Label className="mb-0">
                                  {props.t("GST")} :
                                </Label>
                                <input
                                  type="number"
                                  className="form-control mb-0"
                                  value={parseFloat(newInvoiceTax).toFixed(2)}
                                  disabled={true}
                                ></input>
                                <p className="text-danger">{amountError}</p>
                              </FormGroup>
                            </td>
                            {showRoyalty && <td className="pb-0">
                              <FormGroup className="mb-0">
                                <Label className="mb-0">
                                  {props.t("Royalty Amount")} :
                                </Label>
                                <input
                                  type="number"
                                  className="form-control mb-0"
                                  value={parseFloat(royaltyAmount).toFixed(2)}
                                  disabled={true}
                                ></input>

                              </FormGroup>
                            </td>}
                            <td className="pb-0">
                              <FormGroup className="mb-0">
                                <Label className="mb-0">
                                  {props.t("Total Amount")} :
                                </Label>
                                <input
                                  type="number"
                                  className="form-control mb-0"
                                  value={parseFloat(newInvoiceTotal).toFixed(2)}
                                  disabled={true}
                                ></input>
                                <p className="text-danger">{amountError}</p>
                              </FormGroup>
                            </td>
                          </tr>
                        )}
                        {invoiceItemList.length > 0 && !taxable && (
                          <tr>
                            <td colSpan={showRoyalty ? "6" : "6"}></td>
                            {showRoyalty && <td className="pb-0">
                              <FormGroup className="mb-0">
                                <Label className="mb-0">
                                  {props.t("Royalty Amount")} :
                                </Label>
                                <input
                                  type="number"
                                  className="form-control mb-0"
                                  value={parseFloat(royaltyAmount).toFixed(2)}
                                  disabled={true}
                                ></input>

                              </FormGroup>
                            </td>}
                            <td className="pb-0">
                              <FormGroup className="mb-0">
                                <Label className="mb-0">
                                  {props.t("Total Amount")} :
                                </Label>
                                <input
                                  type="number"
                                  className="form-control mb-0"
                                  value={parseFloat(newInvoiceTotal).toFixed(2)}
                                  disabled={true}
                                ></input>
                                <p className="text-danger">{amountError}</p>
                              </FormGroup>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </Table>
                  </Col>
                </Row>
              </AvForm>
            </div>
            <div className="modal-footer table-responsive">
              <Table className="table table-nowrap table-borderless mb-0">
                <thead>
                  <tr>
                    <td style={{ minWidth: "7rem" }}></td>
                    <td style={{ minWidth: "16rem" }} colSpan="2"></td>
                    {/* <td style={{minWidth : '8rem'}}></td> */}
                    <td style={{ minWidth: "8rem" }}></td>
                    <td style={{ minWidth: "8rem" }}></td>
                    <td>
                      <button
                        type="button"
                        onClick={() => {
                          showPaymentModalOps(false);
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                        style={{ width: "100%" }}
                      >
                        {props.t("Close")}
                      </button>
                    </td>
                    <td>
                      <button
                        type="button"
                        className="btn btn-primary waves-effect waves-light"
                        onClick={createInvoice}
                        disabled={invoiceItemList.length === 0}
                        style={{ width: "90%" }}
                      >
                        {props.showLoaderCreateInvoice && (
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                        )}
                        {props.t("Create Invoice")}
                      </button>
                    </td>
                    <td></td>
                  </tr>
                </thead>
              </Table>
            </div>
          </Modal>
          {enquiryObject && <EnquiryResharePopup
            showEnquiryResharePopup={showEnquiryResharePopup}
            closeEnquiryResharePopup={() => {
              setEnquiryObject(undefined);
              setShowEnquiryResharePopup(!showEnquiryResharePopup)
            }}
            enquiryObject={enquiryObject}
            selectedSchool={props.selectedSchool}
          />}
        </CardBody>
      </Card>

      {admissionDeleteObject &&
        admissionKid &&
        (!admissionKid.attributes.SchoolID ||
          admissionKid.attributes.SchoolID.length === 0) && (
          <SweetAlert
            title="Are you sure?"
            warning
            showCancel
            confirmBtnBsStyle="success"
            cancelBtnBsStyle="danger"
            onConfirm={() => {
              removeFromAdmission();
              setsuccess_dlg(true);
            }}
            onCancel={() => {
              setNewState(undefined);
              setAdmissionDeleteObject(undefined);
              setAdmissionKidLoading(false);
              setAdmissionKid(undefined);
            }}
          >
            {props.t(
              "On Changing status from Admission to " +
              newState +
              ", all Admission related data will be deleted."
            )}
          </SweetAlert>
        )}
      {admissionDeleteObject &&
        admissionKid &&
        admissionKid.attributes.SchoolID &&
        admissionKid.attributes.SchoolID.length > 0 && (
          <SweetAlert
            title="Action is not possible"
            warning
            confirmBtnBsStyle="success"
            onConfirm={() => {
              setNewState(undefined);
              setAdmissionDeleteObject(undefined);
              setAdmissionKidLoading(false);
              setAdmissionKid(undefined);
            }}
          >
            {props.t(
              "This Enquiry has been moved from Admission to classroom, it is not possible to move to "
            ) + newState}
          </SweetAlert>
        )}
      {success_dlg ? (
        <SweetAlert
          success
          title={props.t("Deleted")}
          onConfirm={() => {
            setsuccess_dlg(false);
          }}
        >
          {props.t("Selected Enquiry has been removed from Admissions")}
        </SweetAlert>
      ) : null}

      {/* {admissionKidLoading && <SweetAlert
        title={"Checking Admission Ststus"}
        // onConfirm={() => { }}
        // onCancel={() => { }}
        // dependencies={[]}
      >
        {() => (
          <div className="text-center">
            <h1 className="display-2 font-weight-medium"><i className="bx bx-loader bx-spin text-primary display-3"></i></h1>
          </div>
        )}
      </SweetAlert>
      } */}

      {admissionKidLoading && (
        <Modal isOpen={true} centered={true}>
          <div className="modal-body text-center mb-4 mt-4">
            <h5 className="modal-title mt-0">
              {props.t("Checking Admission Ststus of ") +
                admissionKidLoading.attributes.kidName.toUpperCase()}
            </h5>
            <h1 className="display-2 font-weight-medium">
              <i className="bx bx-loader bx-spin text-primary display-3"></i>
            </h1>
            <h5>{props.t("Please wait...")}</h5>
          </div>
        </Modal>
      )}

      {sendCourseInvite && (
        <Modal
          isOpen={true}
          toggle={() => {
            setSendCourseInvite(undefined);
          }}
        >
          <ModalHeader
            toggle={() => {
              setSendCourseInvite(undefined);
            }}
          >
            <div className="modal-title mt-0" id="myModalLabel">
              {sendCourseInvite.type == "send" ? props.t("Send Invite") : props.t("Resend Invite")}
            </div>
          </ModalHeader>

          <ModalBody>
            <p className=" m-4">
              {
                "Are you sure you want to " + sendCourseInvite.type + " invite to course enrollment?"

              }
            </p>
          </ModalBody>

          {
            sendInviteEnquiryLoading ? <ModalFooter>
              <button type="button" className="btn btn-light waves-effect">
                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                {props.t(" Sending Invite")}
              </button>
            </ModalFooter> :


              <ModalFooter>
                <button
                  type="button"
                  onClick={() => {
                    setSendCourseInvite(undefined);
                  }}
                  className="btn btn-secondary waves-effect"
                  data-dismiss="modal"
                >
                  {props.t("Close")}
                </button>

                <button
                  type="submit"
                  className="btn btn-primary waves-effect waves-light"

                  onClick={() => {
                    sendCourseSubscribeInvite(sendCourseInvite.enquiry);
                  }}
                >
                  {props.t("Invite")}
                </button>
              </ModalFooter>
          }
        </Modal>
      )}
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {
    enquiries,
    loding,
    bulkDeleteLoading,
    kidObj,
    deletedEnquiries,
    bulkRestoreLoading,
  } = state.Enquiry;
  const { selectedSchool, paymentOptionsForSchool, receiptAddres } = state.School;
  const { feeTemplates } = state.FeeTemplate;
  const { feeTemplateItems } = state.FeeTemplateItem;
  const { successResCreateInvoice, showLoaderCreateInvoice } = state.Invoice;
  const { franchise } = state.Franchise;
  const { teachers } = state.Teacher;
  const { userTeacher } = state.Login;

  return {
    enquiries,
    loding,
    bulkDeleteLoading,
    paymentOptionsForSchool,
    receiptAddres,
    feeTemplates,
    feeTemplateItems,
    successResCreateInvoice,
    showLoaderCreateInvoice,
    kidObj,
    selectedSchool,
    franchise,
    deletedEnquiries,
    bulkRestoreLoading,
    teachers,
    userTeacher,
  };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getEnquiryForSchoolId,
      editEnquiry,
      updateEnquiry,
      editEnquiryNote,
      getEnquirisForGivenDates,
      moveToAdmissions,
      enquiryBulkDelete,
      getPaymentOptionsForSchool,
      getFeeTemplateForSchoolId,
      getFeeTemplateItemsForTemplateId,
      createInvoiceWithFunction,
      getKidObjFromEnquiry,
      setInvoiceSuccessComplete,
      getFranchise,
      getDeletedEnquiryForSchoolId,
      enquiryBulkRestore,
      sendAdmissionLinkToParent,
      getTeachersForSchool,
      getReceiptAddressForSchoolId,
      getUserTeacher,
    })(EnquiryList)
  )
);
