import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventoryPurchaseOrderHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryPurchaseOrder_helper";
import * as actionType from "./actionTypes";
import * as InventoryPurchaseOrderAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getPurchaseOrdersForOwnerId({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_PURCHASE_ORDER_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventoryPurchaseOrderHelper.getPurchaseOrderForOwnerId, ownerId, ownerType, null, true);
        yield put(InventoryPurchaseOrderAction.updatePurchaseOrders(localResponse));


        const serverResponse = yield call(InventoryPurchaseOrderHelper.getPurchaseOrderForOwnerId, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventoryPurchaseOrderAction.updatePurchaseOrders(serverResponse));

            var nonDeleted = serverResponse.filter(
                (inventoryPurchaseOrder) =>
                    inventoryPurchaseOrder.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventoryPurchaseOrder) =>
                    inventoryPurchaseOrder.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* editInventoryPurchaseOrder({ payload: { inventoryPurchaseOrder } }) {
    try {
        const response = yield call( InventoryPurchaseOrderHelper.editInventoryPurchaseOrder, inventoryPurchaseOrder );
        if (response === "Error") {
            toastr.error("Please try again", { timeOut: 3000 });
        } else
            if (response) {
                yield put(InventoryPurchaseOrderAction.updatePurchaseOrders(response));
                response.pin();
                toastr.success("purchase order successfully updated", { timeOut: 3000 });
            } else {
            }
    } catch (error) {

    }
}

function* deleteInventoryPurchaseOrder({ payload: { inventoryPurchaseOrder } }) {
    try {
        yield put(InventoryPurchaseOrderAction.updateDeletePurchaseOrder(inventoryPurchaseOrder));
        const response = yield call(InventoryPurchaseOrderHelper.deleteInventoryPurchaseOrderFromParse, inventoryPurchaseOrder);
        if (response === "error") {
            inventoryPurchaseOrder.set("isDeleted", undefined);
            yield put(InventoryPurchaseOrderAction.updateDeletePurchaseOrder(inventoryPurchaseOrder));
        } else
            if (response) {
                toastr.success("Purchase order successfully removed", { timeOut: 3000 });
                response.unPin();
            } else {

            }

    } catch (error) {

    }
}

function* setSelectedPurchaseOrder({ payload: { inventoryPurchaseOrderId } }) {
  try {
    const response = yield call(InventoryPurchaseOrderHelper.getInventoryPurchaseOrderFromParse, inventoryPurchaseOrderId, true);
    yield put(InventoryPurchaseOrderAction.updateSelectedPurchaseOrder(response));
    if (response) {
      response.pin();
    } else {
      const sResponse = yield call(InventoryPurchaseOrderHelper.getInventoryPurchaseOrderFromParse, inventoryPurchaseOrderId, false);
      if (sResponse) {
        response.pin();
        yield put(InventoryPurchaseOrderAction.updateSelectedPurchaseOrder(sResponse));
      }
    }
  } catch (error) {
  }
}

  function* addInventoryPurchaseOrder({ payload: { inventoryPurchaseOrder } }) {
    try {
    
      yield put(InventoryPurchaseOrderAction.updatePurchaseOrders(inventoryPurchaseOrder));
      const response = yield call(
        InventoryPurchaseOrderHelper.editInventoryPurchaseOrder,inventoryPurchaseOrder
      );
      if (response === "Error") {
  
        toastr.error("Please try again", { timeOut: 3000 });
        const local = yield call(
          InventoryPurchaseOrderHelper.getInventoryPurchaseOrderFromLocal,inventoryPurchaseOrder.id
        );
  
        if (local) {
          yield put(InventoryPurchaseOrderAction.updatePurchaseOrders(local));
        }
    
      }else 
      if (response) {
        yield put(InventoryPurchaseOrderAction.updatePurchaseOrders(response));
        toastr.success("Purchase Order successfully added", { timeOut: 3000 });
        response.pin();
      } else {

      }
    } catch (error) {
 
    }
  }

export function* watchGetPurchaseOrdersForOwnerId() {
    yield takeEvery(actionType.GET_INVENTORY_PURCHASE_ORDERS, getPurchaseOrdersForOwnerId);
}
export function* watchEditInventoryPurchaseOrder() {
    yield takeEvery(actionType.EDIT_INVENTORY_PURCHASE_ORDER, editInventoryPurchaseOrder);
}

export function* watchDeleteInventoryPurchaseOrder() {
    yield takeEvery(actionType.DELETE_INVENTORY_PURCHASE_ORDER, deleteInventoryPurchaseOrder);
}

export function* watchAddInventoryPurhaseOrder(){
    yield takeEvery(actionType.ADD_INVENTORY_PURCHASE_ORDER, addInventoryPurchaseOrder);
}

export function* watchSetSelectedPurchaseOrder() {
    yield takeEvery(actionType.SET_SELECTED_PURCHASE_ORDER, setSelectedPurchaseOrder);
}


function* InventoryPurchaseOrderSaga() {
    yield all([
        fork(watchGetPurchaseOrdersForOwnerId),
        fork(watchEditInventoryPurchaseOrder),
        fork(watchDeleteInventoryPurchaseOrder),
        fork(watchAddInventoryPurhaseOrder),
        fork(watchSetSelectedPurchaseOrder),
    ]);
}
export default InventoryPurchaseOrderSaga;