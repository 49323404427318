import actions from 'redux-form/lib/actions';
import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import * as NotifactionHelper from '../../../helpers/ParseHelpers/notifaction_helper'
import * as NotifactionActions from './actions';
import * as actionTypes from './actionTypes';

function* getNotifactionForUser({ payload : {}}){
    try{
        const response = yield call(NotifactionHelper.getnotifactionForUser)
        yield put(NotifactionActions.setNotifactionForUser(response))
    }catch(err){
        console.error(err)
    }
}
function* updateNotificationStatus({ payload: {id }}){
    try{
        const response = yield call(NotifactionHelper.updateNotificationStatus, id)
        yield put(NotifactionActions.getNotifactionForUser())
    }catch(err){
        console.log(err)
    }
}

export function* watchGetNotifaction(){
    yield takeEvery(actionTypes.GET_NOTIFACTIONS_FOR_USER, getNotifactionForUser)
}
export function* watchUpdateNotification(){
    yield takeEvery(actionTypes.UPDATE_NOTIFICATION_STATUS, updateNotificationStatus)
}
function* NotifactionSaga() {
    yield all([
        fork(watchGetNotifaction),
        fork(watchUpdateNotification)
    ])
}
export default NotifactionSaga