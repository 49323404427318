import Parse from "parse";
// import Group from "react-select/src/components/Group";
import school from "../../store/model/school/reducer";
import { printLog } from "./loghelper";
export const getGroupMapFromParse = (groupMapId) => {
    printLog("HL: groupMap_helper : getGroupMapFromParse")

    try {
        return new Promise((resolve, reject) => {
            var GroupMap = Parse.Object.extend("GroupMap");
            var query = new Parse.Query(GroupMap);
            query.get(groupMapId).then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(null);
                }
            )
        }
        )
    } catch (error) { }
}

export const deleteGroupMapFromParse = (groupMapId) => {
    printLog("HL: groupMap_helper : deleteGroupMapFromParse")
    try {
        return new Promise((resolve, reject) => {
            const GroupMap = Parse.Object.extend("GroupMap");
            var groupMap = new GroupMap();
            groupMap.id = groupMapId;
            groupMap.set("isDeleted", true);
            if (Parse.User.current().attributes.teacherId) {
                groupMap.set("deletedBy", Parse.User.current().attributes.teacherId);
            } else {
                groupMap.set("deletedBy", Parse.User.current().id)
            }

            groupMap.save().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(null);
                }
            )
        }
        )
    } catch (error) { }
}

export const getGroupMapForGroupId = (groupId) => {
    printLog("HL: groupMap_helper : getGroupMapForGroupId")
    try {
        return new Promise((resolve, reject) => {
            var GroupMap = Parse.Object.extend("GroupMap");
            var query = new Parse.Query(GroupMap);
            query.equalTo("groupId", groupId);
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.find().then(
                (result) => {
                    resolve(result);
                },
            )
        }
        )
    } catch (error) { }
}

export const checkAndAddGroupMapForKidsParse = (groupId,selectedKidIds) => {
    printLog("HL: groupMap_helper : checkAndAddGroupMapForKidsParse")
    try {
        return new Promise((resolve, reject) => {
            var GroupMap = Parse.Object.extend("GroupMap");
            var query = new Parse.Query(GroupMap);
            query.equalTo("groupId", groupId);
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.find().then(
                (result) => {
                    let groupMapKidIds = []


                    let l = 0
                    for (l = 0; l < result.length; l++) {
                        if (result[l].attributes.userType === 1) {
                            groupMapKidIds.push(result[l].attributes.userId)
                        }
                    }
                    let kidIdsToSave = selectedKidIds.filter((kidId) =>

                        !groupMapKidIds.includes(kidId)

                    )
                    if (kidIdsToSave.length > 0) {
                        let j = 0
                        let groupMapObjectList = []
                        let GroupMapObject = Parse.Object.extend("GroupMap");
                        for (j = 0; j < kidIdsToSave.length; j++) {
                          let groupMapObject = new GroupMapObject();
                          groupMapObject.set("groupId", groupId)
                          groupMapObject.set("userId", kidIdsToSave[j])
                          groupMapObject.set("userType", 1)
                          groupMapObjectList.push(groupMapObject)
                        }
                        if (groupMapObjectList.length > 0) {
                            Parse.Object.saveAll(groupMapObjectList).then(
                                (result) => {
                                    resolve(result);
                                }
                                
                            );
                        }
                    } else {
                        resolve(null)
                    }
                },
            )
        }
        )
    } catch (error) { }
}



export const getGroupMapForGroupIds = (groupMapIds) => {
    printLog("HL: groupMap_helper : getGroupMapForGroupIds")
    try {
        return new Promise((resolve, reject) => {
            var GroupMap = Parse.Object.extend("GroupMap");
            var query = new Parse.Query(GroupMap);
            query.containedIn("groupId", groupMapIds);
            query.notEqualTo("isDeleted", true);
            query.limit(10000);
            query.find().then(
                (result) => {
                    resolve(result);
                }
            )
        }
        )
    } catch (error) { }
}


export const getGroupMapsForTeacherId = (teacherId) => {
    printLog("HL: groupMap_helper : getGroupMapsForTeacherId")
    try {
        return new Promise((resolve, reject) => {
            var GroupMap = Parse.Object.extend("GroupMap");
            var query = new Parse.Query(GroupMap);
            query.equalTo("userId", teacherId);
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(null);
                }
            )
        }
        )
    } catch (error) { }
}



export const saveGroupMaps = (groupMaps,tripIds) => {
    printLog("HL: groupMap_helper : saveGroupMaps")
    try {
        return new Promise((resolve, reject) => {
            Parse.Object.saveAll(groupMaps).then(
                (result) => {

                    if(tripIds && tripIds.length>0){
                        for(const id of tripIds){
                            Parse.Cloud.run("function_updateTripParticipants", {
                                tripId: id,
                            }).then(trip => {
                                trip.pin()
                            },error=>{
                                console.log(error)
                            });
                        }
                    }
                    resolve(result);
                }
                
            );

        });
    } catch (error) { }
}

export const getGroupMapsForKidId = (kidId) => {
    printLog("HL: groupMap_helper : getGroupMapsForTeacherId")
    try {
        return new Promise((resolve, reject) => {
            var GroupMap = Parse.Object.extend("GroupMap");
            var query = new Parse.Query(GroupMap);
            query.equalTo("userId", kidId);
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.find().then(
                (result) => {
                    resolve(result);
                    // console.log(result)
                },
                (error) => {
                    reject(null);
                }
            )
        }
        )
    } catch (error) { }
}
