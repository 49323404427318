import Parse from "parse";
import { printLog } from "./loghelper";

export const getCourseSubscriptions = (id, batchId,updatedDate, isLoacal) => {
  printLog("HL: courseSubscription_helper : getCourseSubscriptions")
  try {
    return new Promise((resolve, reject) => {
      var Course = Parse.Object.extend("CourseSubscription");
      var query = new Parse.Query(Course);
      query.equalTo("courseId", id);
      if (batchId) {
        query.equalTo("batchId", batchId);
      }

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getCourseSubscriptionForUserId = (id) => {
  printLog("HL: courseSubscription_helper : getCourseSubscriptionForUserId")
  try {
    return new Promise((resolve, reject) => {
      var Course = Parse.Object.extend("CourseSubscription");
      var query = new Parse.Query(Course);
      query.equalTo("kidId", id);
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const deleteCourseFromParse = (courseSub) => {
  printLog("HL: courseSubscription_helper : deleteCourseFromParse")
  try {
    return new Promise((resolve, reject) => {

      courseSub.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        courseSub.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        courseSub.set("deletedBy", Parse.User.current().id);
      }
      courseSub.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log("error deleteCourseFromParse :",error)
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const saveParseObjects = (objects) => {
  printLog("HL: courseSubscription_helper : saveParseObjects")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(undefined);
          console.log("Error", error);
        }
      );
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const getCourseSubscriptionId = (id) => {
  printLog("HL: courseSubscription_helper : getCourseSubscriptionId")
  try {
    return new Promise((resolve, reject) => {
      var Course = Parse.Object.extend("CourseSubscription");
      var query = new Parse.Query(Course);
      query.equalTo('objectId', id);
      query.first().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

