import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil"


const initialState = {
  corporatePartners: [], corporatePartnerAddLoading: false,
  kidObj: [],
  selectedCorporatePartner: null,
};

const CorporatePartners = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_CORPORATE_PARTNERS:

      var corporatePartners = [...state.corporatePartners];
      if (corporatePartners.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.corporatePartners], action.payload.corporatePartners, "isDeleted")

        state = { ...state, corporatePartners: updatedList };
      } else {
        var i = 0
        var corporatePartnersToSave = []
        for (i = 0; i < action.payload.corporatePartners.length; i++) {
          if (action.payload.corporatePartners[i].attributes.isDeleted !== true) {
            corporatePartnersToSave.push(action.payload.corporatePartners[i])
          }
        }
        state = { ...state, corporatePartners: corporatePartnersToSave };
      }
      break;

    case actions.UPDATE_KIDS_FOR_CORPORATE_PARTNERS:
      state = { ...state, kidObj: action.payload.kidObj };
      break;

    case actions.ADD_CORPORATE_PARTNERS_LOADING:
      state = { ...state, corporatePartnerAddLoading: action.payload.state };
      break;

    case actions.UPDATE_SELECTED_CORPORATE_PARTNER:
      state = { ...state, selectedCorporatePartner: action.payload.corporatePartner };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CorporatePartners;
