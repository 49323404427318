import React, { useEffect, useState } from "react";
import { Button, CardBody, Container } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Parse from "parse";
import {
    Card,
    CardSubtitle,
    CardTitle,
    Col,
    FormGroup,
    Label,
    Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import { setSelecetdSchool, getClassroomsForSchoolId } from "../../store/actions";
import Moment from 'moment';

//Lightbox
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const Gallery = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/gallery/:id",
        exact: true,
        strict: false,
    });

    const classMatch = matchPath(props.history.location.pathname, {
        path: "/gallery/:id/:classroomId",
        exact: true,
        strict: false,
    });

    const tempMatch = match ?? classMatch;
    const schoolId = tempMatch.params.id;
    const classroomId = classMatch && classMatch.params.classroomId;

    const [fromDate, setFromDate] = useState(new Date());
    const [fromDateError, setFromDateError] = useState(false);
    const [albumData, setAlbumData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [isGallery, setisGallery] = useState(false);
    const [photoIndex, setphotoIndex] = useState(0);
    const [displayImages, setDisplayImages] = useState([]);

    const [showLoadMore, setShowLoadMore] = useState(false);
    const [displayName, setDisplayName] = useState('Gallery');

    const [selctedClassroomId, setSelctedClassroomId] = useState('All');

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        props.getClassroomsForSchoolId(schoolId)
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.getClassroomsForSchoolId(schoolId)
    }, [schoolId]);

    useEffect(() => {
        if (classroomId && props.classrooms) {
            for (const cr of props.classrooms) {
                if (cr.id == classroomId) {
                    setDisplayName(cr.attributes.Name + " Gallery");
                    break;
                }
            }
        }
    }, [classroomId, props.classrooms]);

    useEffect(() => {
        handelGetAlbums();
    }, [schoolId]);




    const handelGetAlbums = () => {
        setLoading(true)
        let month = fromDate.getMonth() + 1;
        let year = fromDate.getFullYear();
        if (classMatch || selctedClassroomId != 'All') {
            let classId;
            if (classMatch) {
                classId = classroomId
            } else {
                classId = selctedClassroomId;
            }
            Parse.Cloud.run("getPicturesForClass", {
                classId: classId,
                month: month,
                year: year,
            }).then((response) => {
                initialLoad(response)
            }, (error) => {
                console.log("error" + error.message)
            });
        } else {
            Parse.Cloud.run("getPicturesForSchool", {
                schoolId: schoolId,
                month: month,
                year: year,
            }).then((response) => {
                initialLoad(response)
            }, (error) => {
                console.log("error" + error.message)
            });
        }

    }

    const initialLoad = (response) => {
        setLoading(false)
        // console.log(response)
        if (response.length > 36) {
            setDisplayImages(response.slice(0, 36))
            setShowLoadMore(true);
        } else {
            setDisplayImages(response)
        }
        setAlbumData(response)
    }

    const handelLoadMore = () => {

        let fi = displayImages.length
        let si = fi + 36;
        if (albumData.length > si) {
            setShowLoadMore(true);
            setDisplayImages(albumData.slice(0, si))
        } else {
            setShowLoadMore(false);
            setDisplayImages(albumData)
        }

    }



    return (
        <React.Fragment>
            <div className="page-content">
                <Container>
                    <Breadcrumbs title={displayName} breadcrumbItem="Starter Page" />
                    <Card>
                        <Row>
                            {match && <Col lg={4} xs={6}>
                                <FormGroup className="m-2 p-1">
                                    <Label >
                                        {props.t("Select classroom")} :
                                    </Label>

                                    <select className="form-control mr-2"
                                        value={selctedClassroomId}
                                        onChange={(e) => {
                                            setSelctedClassroomId(e.target.value);
                                        }}
                                    >
                                        <option className="text-primary" value="All" key={0} >All</option>
                                        {props.classrooms.map((val, key) => (<option key={val.id} className={val.attributes.classroomType === 2 ? 'text-danger' : 'text-primary'} value={val.id} >{val.attributes.Name}</option>))}
                                    </select>

                                </FormGroup>

                            </Col>}
                            <Col lg={4} xs={6}>
                                <FormGroup className="m-2 p-1">
                                    <Label >
                                        {props.t("Select Month")} :
                                    </Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {

                                            setFromDateError(false)
                                            setFromDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Month")}
                                        startDate={fromDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {fromDateError && <p className="text-danger ml-2">Please select month</p>}
                            </Col>
                            <Col lg={3} >
                                <div className="mt-4 mb-1 p-1">
                                    <button
                                        // disabled={loadingState === 2}
                                        onClick={(e, v) => {
                                            let next = true;

                                            if (!fromDate) {
                                                setFromDateError(true);
                                                next = false;
                                            }
                                            if (next) {
                                                handelGetAlbums();
                                            }

                                        }}
                                        className="w-md mt-2  btn btn-primary"
                                    >
                                        {props.t("Apply")}
                                    </button>


                                </div>
                            </Col>
                        </Row>
                    </Card>

                    <Card className="text-center">
                        {!loading && albumData && albumData.length > 0 && (
                            <CardBody>
                                <div className="popup-gallery">
                                    <div className="row">
                                        {displayImages.map((album, key) => (
                                            <div className="col-md-2" key={key}>
                                                <img
                                                    src={album.ThumbnailImage ? album.ThumbnailImage._url : album.Image._url}
                                                    onClick={() => { setisGallery(true); setphotoIndex(key); }}
                                                    alt=""
                                                    className="img-fluid border mb-2"
                                                    style={{ background: "#667679" }}
                                                />
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </CardBody>
                        )}

                        <div className="align-center">
                            {showLoadMore && <Button
                                style={{ bottom: 0, }}

                                color="primary"
                                className=" p-2 "
                                onClick={() => {
                                    handelLoadMore()
                                }}
                            >
                                Load More...
                            </Button>}
                        </div>





                        {loading && <CardBody >
                            <Label className="text-success">
                                <i className="bx bx-hourglass bx-spin mr-2"></i> Loading...{" "}
                            </Label>
                        </CardBody>}

                        {!loading && albumData && albumData.length == 0 && <CardBody >
                            <Label className="text-success ">
                                <i> No photos to display for selected month</i>
                            </Label>
                        </CardBody>}



                        <div style={{ height: "400px" }}></div>
                    </Card>
                    {(isGallery && albumData) ? (
                        <Lightbox
                            mainSrc={albumData[photoIndex].Image._url}
                            nextSrc={albumData[(photoIndex + 1) % albumData.length].Image._url}
                            prevSrc={
                                albumData[(photoIndex + albumData.length - 1) % albumData.length].Image._url
                            }
                            enableZoom={true}
                            onCloseRequest={() => { setisGallery(false) }}
                            onMovePrevRequest={() => { setphotoIndex((photoIndex + albumData.length - 1) % albumData.length) }}
                            onMoveNextRequest={() => { setphotoIndex((photoIndex + 1) % albumData.length) }}
                            imageCaption={albumData[photoIndex + 1] && "Date & Time: " + Moment(albumData[photoIndex + 1].DateTime).format('DD-MM-YYYY hh:mm a')}
                        />
                    ) : null}

                </Container>
            </div>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    return { selectedSchool, classrooms };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, getClassroomsForSchoolId })(Gallery)));