import * as actions from "./actionTypes";

export const getLiveMeetingForBatchId = (courseId,batchId) => {
  return {
    type: actions.GET_LIVE_MEETING_FOR_BATCH,
    payload: { courseId,batchId }
  }
}

export const updateLiveMeetingForBatchId = (liveMeetings) => {
  return {
    type: actions.UPDATE_LIVE_MEETING_FOR_BATCH,
    payload: { liveMeetings }
  }
}

export const addLiveMeeting = (liveMeeting,loadingState,remainderObject) => {
  return {
    type: actions.ADD_LIVE_MEETING,
    payload: { liveMeeting,loadingState ,remainderObject}
  }
}

export const setLiveMeetingLoading = (state) => {
  return {
    type: actions.ADD_LIVE_MEETING_LOADING,
    payload: { state }
  }
}