import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Form, FormGroup, Alert, Input, Container, Row, TabContent, TabPane, Card, CardText, Nav, NavItem, NavLink, CardBody, Label, CardHeader, Button, Col, Table } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { matchPath } from "react-router";
import moment from 'moment';
import TopBar from './Topbar';
import { MDBDataTable } from "mdbreact"
import "./datatables.scss";
import Parse from 'parse';
import Moment from "moment";
import DatePicker from 'react-datepicker';
import { setSelecetdSchool, getExpenseWalletLogForSchoolId, getTeacher, getExpenseWalletLogForDate, clearExpenseWalletLog } from '../../store/actions';

const ExpenseWallet = (props) => {

    const [fromDate, setFromDate] = useState();
    const [toDate, setToDate] = useState();

    const columns = [
        {
            label: "Sl No",
            field: "slno",
            sort: "asc",
            searchable: true,
            width: 150
        },
        {
            label: "Type",
            field: "type",
            sort: "asc",
            searchable: true,
            width: 200
        },
        {
            label: "Date",
            field: "date",
            sort: "asc",
            searchable: true,
            width: 200
        },
        {
            label: "Payee",
            field: "payee",
            sort: "asc",
            searchable: true,
            width: 200
        },
        {
            label: "Amount",
            field: "amount",
            sort: "asc",
            searchable: true,
            width: 200
        },
        {
            label: "Expense Ref",
            field: "expenseNumber",
            sort: "asc",
            searchable: true,
            width: 200
        },
        {
            label: "WalletBalance",
            field: "walletBalance",
            sort: "asc",
            searchable: true,
            width: 200
        },
    ]



    const [expenseWalletLog, setExpenseWalletLog] = useState({
        columns: columns,
        rows: [],
    });

    // useEffect(() => {
    //     props.getExpenseWalletLogForDate(props.schoolId, fromDate, toDate)
    // }, [props.schoolId])

    useEffect(() => {
        if (props.expensesWalletLog && props.expensesWalletLog.length > 0) {
            let expenses = [];            
            let order = 1;
            for (const expenseLog of props.expensesWalletLog) {
                let newExpenses = {
                    slno: order++,
                    type: expenseLog.attributes.type,
                    payee: getTeacherName(expenseLog.attributes.createdBy),
                    date: getTimeStringForDate(expenseLog.attributes.createdAt),
                    amount: <Label onClick={(e) => { console.log(expenseLog.id, e) }} className={expenseLog.attributes.amount > 0 ? 'text-success' : 'text-danger'}>{expenseLog.attributes.amount}</Label>,
                    expenseNumber: expenseLog.attributes.expenseNumber ?? "",
                    walletBalance: expenseLog.attributes.walletBalance ?? "",
                }
                expenses.push(newExpenses)
            }
            setExpenseWalletLog({
                columns: columns, rows: expenses
            });
        }
    }, [props.expensesWalletLog])

    useEffect(() => {
        if (Parse.User.current()) {
            props.getTeacher(Parse.User.current().attributes.teacherId)
        }
    }, [props.schoolId]);

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }


    const getExpencesForDate = (fromDate, toDate) => {
        props.clearExpenseWalletLog(props.schoolId)
        props.getExpenseWalletLogForDate(props.schoolId, fromDate, toDate);
    }

    const handleApply = () => {
        let tempFromDate = new Date(
            fromDate.getFullYear(),
            fromDate.getMonth(),
            fromDate.getDate(),
            0,
            0,
            0,
            0,
        );

        let tempToDate = new Date(
            toDate.getFullYear(),
            toDate.getMonth(),
            toDate.getDate(),
            23,
            59,
            59,
            999
        );
        getExpencesForDate(tempFromDate, tempToDate)
    }

    return (

        <React.Fragment>

            <Card>
                <CardBody>
                    <Row>
                        <Col>
                        <Button
                            color="light"
                            className="btn waves-effect waves-light  mb-3  pb-2"
                        ><i className="mdi mdi-wallet mr-1"></i><b>Wallet Balance:{props.currency} {props.balance}</b>
                        </Button>
                        </Col>
                    </Row>
                    <Row className="bg-light ">
                        <Col lg={5}>
                            <FormGroup className="mt-2 mb-2 p-1">
                                <Label className="mb-0">{props.t("From (Date)")} :</Label>
                                <DatePicker
                                    selected={fromDate}
                                    onChange={(date) => {
                                        setFromDate(date);
                                    }}
                                    className="form-control"
                                    placeholderText={props.t("Select Date")}
                                    maxDate={toDate}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={5}>
                            <FormGroup className="mt-2 mb-2 p-1">
                                <Label className="mb-0">{props.t("To (Date)")} :</Label>
                                <DatePicker
                                    selected={toDate}
                                    onChange={(date) => setToDate(date)}
                                    className="form-control"
                                    placeholderText={props.t("Select Date")}
                                    minDate={fromDate}
                                    dateFormat="dd/MM/yyyy"
                                />
                            </FormGroup>
                        </Col>
                        <Col lg={2}>
                        <div className="mt-2 text-center">
                            <button
                                onClick={() => {
                                    handleApply()
                                }}
                                className="btn btn-primary mt-4 mr-2"
                            >
                                Wallet Transactions
                            </button>
                        </div>
                        </Col>
                    </Row>
                    <Alert
                        color="danger"
                        role="alert"
                        className='mt-3'
                    >
                        <p className="mb-0">To get Wallet Transactions please select the from date and to date</p>
                    </Alert>
                    {expenseWalletLog.rows && expenseWalletLog.rows.length > 0 && (
                        <MDBDataTable
                            striped
                            hover
                            responsive
                            bordered
                            searching={{
                                searchPlaceholder: 'Search...',
                            }}
                            data={expenseWalletLog}
                        />
                    )}
                </CardBody>
            </Card>
            {/* {expenseWalletLog.rows.length === 0 && (
                <Col>
                    <h3 className="text-center text-info mt-3">
                        No Report Found For The Filter
                    </h3>
                </Col>
            )} */}
            <div style={{ height: "200px" }}></div>
        </React.Fragment>

    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { expensesWalletLog } = state.Expenses;
    const { teachers, } = state.Teacher;
    return { selectedSchool, expensesWalletLog, teachers };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, getExpenseWalletLogForSchoolId, getTeacher, getExpenseWalletLogForDate, clearExpenseWalletLog })(ExpenseWallet)));
