import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as VerifiedLogo } from "../../assets/images/certificate/verifiedLogo.svg";
import { ReactComponent as Divider } from "../../assets/images/certificate/separator.svg";

import profileImg from "../../assets/images/users/userXYZ.png";
import * as WhiteLabel from "../../components/Common/WhiteLable";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Label,
    UncontrolledTooltip,
    Progress
} from "reactstrap";
import * as Constants from "../../Constents";
import Moment from "moment";
import Switch from "react-switch";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Parse from "parse";
import toastr from "toastr";
import logo from "../../assets/images/logo-dark.png";
import QRCode from 'qrcode'
import EscortIdCardUI from "./EscortIdCardUI";
import UpdatePhoto from '../../components/Common/UpdatePhoto';
import { setSelecetdSchool, getAllKids, getParentsForFamilyIds, getEscortsForKidIds, getClassroomsForSchoolId, } from "../../store/actions";
import Slider from "react-rangeslider";
import SaveAsPdf from "../../components/Common/SaveAsPdf"; 

const EscortIdCard = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/escortIdcard/:schoolId/:type/:kidId/:escortId",
        exact: true,
        strict: false,
    });

    const matchSchool = matchPath(props.history.location.pathname, {
        path: "/escortIdcard/:schoolId",
        exact: true,
    });

    const forSchool = matchSchool ? true : false;
    const schoolId = match ? match.params.schoolId : (matchSchool ? matchSchool.params.schoolId : undefined);
    const type = match && match.params.type;
    const kidId = match && match.params.kidId;
    const userId = match && match.params.escortId;



    const [loading, setLoading] = useState(true);
    const [schoolObject, setSchoolObject] = useState(undefined);
    const [kidObject, setKidObject] = useState(undefined);
    const [kidName, setKidName] = useState("")
    const [kidClassName, setKidClassName] = useState("");
    const [escortName, setEscortName] = useState('');
    const [escortRelation, setEscortRelation] = useState('');
    const [gotClass, setGotClass] = useState(false);
    const [gotEscort, setGotEscort] = useState(false);
    const [kidPhoto, setKidPhoto] = useState(undefined);
    const [escortPhoto, setEscortPhoto] = useState(undefined);
    const [allEscorts, setAllEscorts] = useState([])
    const [allEscortsListMaster, setAllEscortsListMaster] = useState([])
    const [allEscortsListIds, setAllEscortsListIds] = useState([])
    const [allEscortsMasterListIds, setAllEscortsMasterListIds] = useState([])


    const [showSettingsModal, setShowSettingsModal] = useState(false);
    const [isBorderOff, setIsBorderOff] = useState(false);

    const [headerFontSize, setHeaderFontSize] = useState(14);
    const [bodyFontSize, setBodyFontSize] = useState(11);
    const [footerFontSize, setFooterFontSize] = useState(10);
    const [showLogoUploadView, setShowLogoUploadView] = useState(false);
    const [idCardLogo, setIdCardLogo] = useState();
    const [idCardLogoUploadProgress, setIdCardLogoUploadProgress] = useState(0);
    const [showPopup, setShowPopup] = useState(false);
    const [sliderValue, setSliderValue] = useState(0);
    const [orientaion, setOrientaion] = useState("P");
    const [layoutType, setLayoutType] = useState("QR");
    const [onlyQR, setOnlyQR] = useState(false);
    const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [machineType, setMachineType] = useState('');


    const HeaderSettingTypes = {
        logoAndName: "logoAndName",
        logoOnly: "logoOnly",
        customLogo: "customLogo",
    };

    Object.freeze(HeaderSettingTypes);
    const [selectedHeaderTypeSetting, setSelectedHeaderTypeSetting] = useState(HeaderSettingTypes.logoAndName);


    const potraitSize = {
        id: 0,

        schoolLogoHeight: "8mm",
        topHeaderDivHeight: "13.5mm",
        bottomFooterDivHeight: "19.1mm",
        kidDetailHeight: "28mm",
        kidPhotoHeight: "14mm",
        colForKidDetails: 6,
        rowForKidDetails: 12,
        qrCodeDivHeight: "21mm",

        cardHeight: "86mm",
        cardWidth: "54mm",
        nameStyle: { fontSize: "x-small", maxHeight: "8mm", overflow: "hidden" },
    }

    const landscapeSize = {
        id: 1,

        schoolLogoHeight: "12mm",
        topHeaderDivHeight: "14mm",
        bottomFooterDivHeight: "12mm",
        kidDetailHeight: "22mm",
        kidPhotoHeight: "12mm",
        colForKidDetails: 4,
        rowForKidDetails: 12,
        qrCodeDivHeight: "16mm",

        cardHeight: "54mm",
        cardWidth: "86mm",
        nameStyle: { fontSize: "x-small", minHeight: "5mm", maxHeight: "5mm", overflow: "hidden" },
        marginTopForKidName: "",
        kidPhotoMarginTop: "",
        footerW1: "28mm",
        footerW2: "57mm"
    }

    const withoutQRCodePortrait = {
        id: 2,

        schoolLogoHeight: "5mm",
        topHeaderDivHeight: "17.5mm",
        bottomFooterDivHeight: "19.1mm",
        kidDetailHeight: "49mm",
        kidPhotoHeight: "14.5mm",
        colForKidDetails: 12,
        rowForKidDetails: 12,
        qrCodeDivHeight: "0mm",

        cardHeight: "86mm",
        cardWidth: "54mm",
        nameStyle: { fontSize: "x-small", maxHeight: "5mm", overflow: "hidden" },

    }

    const withoutQRCodeLandscape = {
        id: 3,

        schoolLogoHeight: "12mm",
        topHeaderDivHeight: "14mm",
        bottomFooterDivHeight: "12mm",
        kidDetailHeight: "22mm",
        kidPhotoHeight: "12mm",
        colForKidDetails: 4,
        rowForKidDetails: 12,
        noQRLogoDivHeight: "16mm",

        cardHeight: "54mm",
        cardWidth: "86mm",
        nameStyle: { fontSize: "x-small", minHeight: "5mm", maxHeight: "5mm", overflow: "hidden" },
        marginTopForKidName: "",
        kidPhotoMarginTop: "",
        footerW1: "28mm",
        footerW2: "57mm"
    }

    const onlyQRCode = {
        id: 4,

        schoolLogoHeight: "5mm",
        topHeaderDivHeight: "20.3090275mm",
        bottomFooterDivHeight: "20.3090275mm",
        kidDetailHeight: "42.3090275mm",
        kidPhotoHeight: "25mm",
        colForKidDetails: 6,
        rowForKidDetails: 12,
        qrCodeDivHeight: "60.3090275mm",

        cardHeight: "125.23611mm",
        cardWidth: "78.636627209302mm",
        nameStyle: { fontSize: "x-small" },

    }

    const updateSizeScale = (scale) => {
        let ratio = 1 + scale;
        if (orientaion == "P" && layoutType == "QR") {
            let os = {

                id: 0,

                schoolLogoHeight: 8 * ratio + "mm",
                topHeaderDivHeight: 13.5 * ratio + "mm",
                bottomFooterDivHeight: 19.1 * ratio + "mm",
                kidDetailHeight: 28 * ratio + "mm",
                kidPhotoHeight: 14 * ratio + "mm",
                // colForKidDetails: 6 * ratio,
                // rowForKidDetails: 12 * ratio,
                qrCodeDivHeight: 21 * ratio + "mm",

                cardHeight: 86 * ratio + "mm",
                cardWidth: 54 * ratio + "mm",
                nameStyle: { fontSize: "x-small", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
            }
            setSelectedPrintSetting(os)

        } else if (orientaion == "P" && layoutType == "NoQR") {
            let oq = {
                id: 2,

                schoolLogoHeight: 5 * ratio + "mm",
                topHeaderDivHeight: 17.5 * ratio + "mm",
                bottomFooterDivHeight: 19.1 * ratio + "mm",
                kidDetailHeight: 49 * ratio + "mm",
                kidPhotoHeight: 14.5 * ratio + "mm",
                colForKidDetails: 12 * ratio,
                rowForKidDetails: 12 * ratio,
                qrCodeDivHeight: "0mm",

                cardHeight: 86 * ratio + "mm",
                cardWidth: 54 * ratio + "mm",
                nameStyle: { fontSize: "x-small", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
            }

            setSelectedPrintSetting(oq)
        } else if (orientaion == "L" && layoutType == "QR") {
            let oh = {
                id: 1,

                schoolLogoHeight: 12 * ratio + "mm",
                topHeaderDivHeight: 14 * ratio + "mm",
                bottomFooterDivHeight: 12 * ratio + "mm",
                kidDetailHeight: 22 * ratio + "mm",
                kidPhotoHeight: 12 * ratio + "mm",
                colForKidDetails: 4 * ratio,
                rowForKidDetails: 12 * ratio,
                qrCodeDivHeight: 16 * ratio + "mm",

                cardHeight: 54 * ratio + "mm",
                cardWidth: 86 * ratio + "mm",
                nameStyle: { fontSize: "x-small", minHeight: 5 * ratio + "mm", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
                marginTopForKidName: "",
                kidPhotoMarginTop: "",
                footerW1: 28 * ratio + "mm",
                footerW2: 57 * ratio + "mm"
            }

            setSelectedPrintSetting(oh)
        } else if (orientaion == "L" && layoutType == "NoQR") {
            let os = {

                id: 3,

                schoolLogoHeight: 12 * ratio + "mm",
                topHeaderDivHeight: 14 * ratio + "mm",
                bottomFooterDivHeight: 12 * ratio + "mm",
                kidDetailHeight: 22 * ratio + "mm",
                kidPhotoHeight: 12 * ratio + "mm",
                colForKidDetails: 4 * ratio + "mm",
                rowForKidDetails: 12 * ratio + "mm",
                noQRLogoDivHeight: 16 * ratio + "mm",

                cardHeight: 54 * ratio + "mm",
                cardWidth: 86 * ratio + "mm",
                nameStyle: { fontSize: "x-small", minHeight: 5 * ratio + "mm", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
                marginTopForKidName: "",
                kidPhotoMarginTop: "",
                footerW1: 28 * ratio + "mm",
                footerW2: 57 * ratio + "mm"

            }
            setSelectedPrintSetting(os)
        }

    }

    const listOfPrintSettings = [potraitSize, landscapeSize, onlyQRCode, withoutQRCodePortrait, withoutQRCodeLandscape,]
    const [selectedPrintSetting, setSelectedPrintSetting] = useState(potraitSize);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.getClassroomsForSchoolId(schoolId);
        if (forSchool) {
            props.getAllKids(schoolId);
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.kids) {
            let familyIds = [];
            let kidIds = [];
            for (var j = 0; j < props.kids.length; j++) {
                familyIds.push(props.kids[j].attributes.FamilyID);
                kidIds.push(props.kids[j].id)
            }
            if (familyIds.length > 0) {
                props.getParentsForFamilyIds(familyIds);
            }
            if (kidIds.length > 0) {
                props.getEscortsForKidIds(kidIds)
            }
        }
    }, [props.kids]);

    useEffect(() => {
        let list = [];
        for (const kid of props.kids) {

            for (const parent of props.parents) {
                if (parent.attributes.FamilyID == kid.attributes.FamilyID) {
                    let escortsObject = {
                        school: props.selectedSchool,
                        kid: kid,
                        kidPhot: kid.attributes.Photo ? kid.attributes.Photo._url : undefined,
                        kidName: kid.attributes.Name,
                        kidClasRoom: kid.attributes.ClassRoomID,
                        id: parent.id,
                        type: "P",
                        object: parent

                    }

                    list.push(escortsObject);
                }
            }

            for (const escort of props.escorts) {
                if (escort.attributes.KidID == kid.id) {
                    let escortsObject = {
                        school: props.selectedSchool,
                        kid: kid,
                        kidPhot: kid.attributes.Photo ? kid.attributes.Photo._url : undefined,
                        kidName: kid.attributes.Name,
                        kidClasRoom: kid.attributes.ClassRoomID,
                        id: escort.id,
                        type: "E",
                        object: escort

                    }
                    list.push(escortsObject);
                }
            }
        }

        setAllEscorts(list)
        setAllEscortsListMaster(list)

        let ids = [];
        if (list) {
            for (const i in list) {
                ids.push(list[i].id)
            }
        }
        setAllEscortsListIds(ids)
        setAllEscortsMasterListIds(ids)

    }, [props.kids, props.escorts, props.parents]);

    // console.log(allEscorts)
    // console.log(allEscortsListMaster)
    useEffect(() => {
        if (Parse.User.current()) {
            if (match) {
                getSchoolForId()
            }
        }
    }, [schoolId])

    useEffect(() => {
        if (gotClass && gotEscort) {
            setLoading(false)
        }
    }, [gotClass, gotEscort])

    useEffect(() => {
        const savedValue = localStorage.getItem('dontShowAgain');
        if (savedValue === 'true') {
            setDontShowAgain(true);
        }
    }, []);
    

    const getSchoolForId = () => {
        var query = new Parse.Query('School');
        query.equalTo('objectId', schoolId);
        query.limit(1);
        query.first().then((result) => {
            if (result) {
                setSchoolObject(result)
                getKidForId(result)
            }
        })
    }

    const getKidForId = (school) => {
        var query = new Parse.Query('Kid');
        query.equalTo('objectId', kidId);
        query.limit(1);
        query.first().then((kid) => {
            if (kid) {
                setKidObject(kid)
                setKidName(kid.attributes.Name)
                setKidPhoto(kid.attributes.Photo && kid.attributes.Photo._url)
                getClassroomForId(kid.attributes.ClassRoomID)

                if (type === "P") {
                    getParentsForFamilyId(school, kid)
                } else {
                    getEscortForId(school, kid)
                }
            }
        })
    }

    const getEscortForId = (school, kid) => {
        var query = new Parse.Query('PickNDrop');
        query.equalTo('objectId', userId);
        query.limit(1);
        query.first().then((pnd) => {
            if (pnd) {
                setEscortName(pnd.attributes.PersonName)
                setEscortRelation(pnd.attributes.Relation)
                setEscortPhoto(pnd.attributes.Photo ? pnd.attributes.Photo._url : undefined)
                setGotEscort(true)

                createEscortObjectForMatch(school, kid, pnd)
            }
        })
    }

    const getParentsForFamilyId = (school, kid) => {
        var query = new Parse.Query('Parent');
        query.equalTo('objectId', userId);
        query.limit(1);
        query.first().then((parent) => {
            if (parent) {
                setEscortName(parent.attributes.Name)
                setEscortRelation(parent.attributes.Relation)
                setEscortPhoto(parent.attributes.Photo ? parent.attributes.Photo._url : undefined)
                setGotEscort(true)
                createEscortObjectForMatch(school, kid, parent)
            }
        })
    }

    const getClassroomForId = (id) => {
        var query = new Parse.Query('ClassRoom');
        query.equalTo('objectId', id);
        query.limit(1);
        query.find().then((results) => {
            if (results && results.length > 0) {
                setKidClassName(results[0].attributes.Name)
                setGotClass(true)
            }
        })
    }

    const createEscortObjectForMatch = (school, kid, object) => {
        let escortsObject = {
            school: school,
            kid: kid,
            kidPhot: kid.attributes.Photo ? kid.attributes.Photo._url : undefined,
            kidName: kid.attributes.Name,
            kidClasRoom: kid.attributes.ClassRoomID,
            id: object.id,
            type: type,
            object: object

        }

        setAllEscorts([escortsObject])
    }

    function printPoster() {
        var css = "@page { size: A3 ;margin:0; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        setTimeout(() => {
            window.print()
        }, 300);
    }

    useEffect(() => {
        const savedValue = localStorage.getItem('dontShowAgain');
        if (savedValue === 'true') {
            setDontShowAgain(true);
        }
    
        const userAgent = navigator.userAgent.toLowerCase();
        
        if (userAgent.includes('mac')) {
            setMachineType('Mac');
        } else if (userAgent.includes('windows')) {
            setMachineType('Windows');
        } else {
            setMachineType('Unknown');
        }
      }, []);

      const handleNextClick = () => {
        setShowSaveAsPdfModal(false);
        setTimeout(() => {
            printPoster(); 
        }, 300); 
      };

    const getCardUI = (isPrintOnly) => {

        let classNameTemp = isPrintOnly ? "d-none d-print-block " : "d-print-none "
        let styleTemp = isPrintOnly ? { width: "100%", minHeight: "296mm" } : { width: "100%", minHeight: "1420px" }
        if (isPrintOnly) {
            styleTemp.backgroundColor = "white"
        }

        return (

            <Col lg="12" md="12" sm="12" xs="12" className={classNameTemp + " m-0 p-0 m-0 p-0 d-flex justify-content-center"}>
                <center>
                    <div
                        style={styleTemp}
                    >
                        {getUIForAllEscorts(allEscorts[0])}
                    </div>
                </center>
            </Col>

        );

    }
    const getUIForAllEscorts = (escort) => {
        return (
            <div style={{ pageBreakInside: 'avoid' }}>
                <EscortIdCardUI
                    idCardLogo={idCardLogo}
                    selectedPrintSetting={onlyQR ? onlyQRCode : selectedPrintSetting}
                    escort={escort}
                    classrooms={props.classrooms}
                    isBorderOff={isBorderOff}
                    bodyFontSize={bodyFontSize}
                    footerFontSize={footerFontSize}
                    headerFontSize={headerFontSize}
                    orientaion={orientaion}
                    layoutType={layoutType}
                    selectedHeaderTypeSetting={selectedHeaderTypeSetting}
                    onlyQR={onlyQR}
                />
            </div>
        );
    };

    const getParentUIWithHeader = (isSingleCardUI) => {
        return (


            <Row className="text-center bg-white">
                {
                    <>
                        <Col xs="12" className="d-print-none mt-3 mb-3">
                            <div className="navbar-header p-0" style={{ borderBottom: "1px solid gray" }}>
                                {/* <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { window.history.back() }}>
                                        <i className="bx bx-chevron-left h1 ml-3 text-primary"></i>
                                    </div> */}

                                <h4 className="text-center m-4"><img src={WhiteLabel.getAppLogoWithName()} className="m-5" height="50px" style={{ border: "1px solid lightGray", borderRadius: "10px", padding: "10px" }}></img>{"ESCORT ID CARD"}</h4>

                                <div className="d-flex m-4">
                                    <Row className="d-flex m-4" style={{ width: "110mm" }}>
                                        <Col className='d-flex justify-content-start'>
                                            {<div>
                                                <Button
                                                    color="primary"
                                                    className='d-flex justify-content-start btn btn-primary waves-effect waves-light m-1'
                                                    onClick={() => {
                                                        setShowPopup(true)
                                                    }
                                                    }
                                                >
                                                    Add/Remove
                                                </Button>
                                            </div>}
                                            <div>
                                                <Button
                                                    color="primary"
                                                    className='d-flex justify-content-start btn btn-primary waves-effect waves-light m-1'
                                                    onClick={() => {
                                                        setShowSettingsModal(true)
                                                    }
                                                    }
                                                >
                                                    Customise
                                                </Button>
                                            </div>
                                        </Col>
                                        <Col>
                                            <div>
                                            <Button onClick={() => {
                                                if (['Windows', 'Mac'].includes(machineType)) {
                                                    if (!dontShowAgain) {
                                                        setShowSaveAsPdfModal(true); 
                                                    } else {
                                                        printIdCard();
                                                    }
                                                }
                                            }}
                                            className="d-flex justify-content-start btn btn-success waves-effect waves-light m-1"><i className="fa fa-print m-1"></i> Print
                                            </Button>
                                            <SaveAsPdf
                                                showModal={showSaveAsPdfModal}
                                                toggleModal={() => setShowSaveAsPdfModal(false)}
                                                dontShowAgain={dontShowAgain}
                                                setDontShowAgain={setDontShowAgain}
                                                onNextClick={handleNextClick}
                                                machineType={machineType}
                                            />
                                            </div>
                                        </Col>
                                    </Row>
                                </div>
                            </div>
                        </Col>

                        {getMultiCardUI()}

                    </>}
            </Row>
        )
    }

    const getLoadingUI = () => {
        return (
            <Col xs="12">
                <div className="text-center my-3">
                    <Link to="#" className="text-success" onClick={(e) => {
                        e.preventDefault()
                    }}>
                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                        {forSchool ? "Loading Escort Id Cards..." : "Loading Escort Id Card..."}  {" "}
                    </Link>
                </div>
            </Col>
        )
    }


    const getMultiCardUI = () => {
        let styleTemp = { width: "100%", minHeight: "1420px" }
        styleTemp.backgroundColor = "white"
        return (
            <div className="mx-auto">
                <div
                    style={styleTemp}
                    className="d-flex justify-content-center"
                >
                    <div className='ml-4 pl-3'>
                        <Row>
                            {allEscorts.map((escort, key) => (
                                <Col className="mb-4">
                                    <React.Fragment key={key}>{
                                        <div className='d-flex justify-content-center'>
                                            {getUIForAllEscorts(escort)}
                                        </div>
                                    }
                                    </React.Fragment>
                                </Col>
                            ))
                            }
                        </Row>
                    </div>
                </div>

            </div>
        )
    }

    const changeHeaderFontSize = (isLeft) => {
        if (isLeft) {
            if (headerFontSize != 0) {
                setHeaderFontSize(headerFontSize - 1)
            }
        } else {
            setHeaderFontSize(headerFontSize + 1)
        }
    }

    const changeFooterFontSize = (isLeft) => {
        if (isLeft) {
            if (footerFontSize != 0) {
                setFooterFontSize(footerFontSize - 1)
            }
        } else {
            setFooterFontSize(footerFontSize + 1)
        }
    }

    const changeBodyFontSize = (isLeft) => {
        if (isLeft) {
            if (bodyFontSize != 0) {
                setBodyFontSize(bodyFontSize - 1)
            }
        } else {
            setBodyFontSize(bodyFontSize + 1)
        }
    }

    // On image upload
    function handleImageUploadChange(
        changedImageURL,
        imageFile,

    ) {
        setIdCardLogo(undefined)
        setIdCardLogoUploadProgress(1)
        let fileExtension = imageFile._name.split(".");
        let tempFile = {
            actualImageName: imageFile._name,
            fileExtension: fileExtension[1].toLowerCase(),
            imageName: fileExtension[0],
            image: imageFile,
            imageURL: undefined,
        };
        tempFile.image
            .save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        //setUploadProgress(value * 100);
                        //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")
                        setIdCardLogoUploadProgress(value * 100)
                    }
                },
            })
            .then(
                (result) => {
                    if (props.selectedSchool) {
                        props.selectedSchool.set("idCardLogo", result)
                        props.selectedSchool.save().then(
                            (schoolResult) => {
                                tempFile.imageURL = result._url;
                                tempFile.image = result;
                                setIdCardLogo(tempFile)
                                // tempQuestionObject.answerImage = tempFile;
                            },
                            (error) => {

                            }
                        );
                    }
                },
                (error) => {
                }
            );
        setIdCardLogo({ actualImageName: tempFile.actualImageName, imageURL: changedImageURL })
    }

    const changeCustomHeaderLogo = (result) => {
        if (result) {
            let name = result._name.split(/_(.+)/)[1];
            let fileExtension = name.split(".");
            let tempFile = {
                actualImageName: name,
                fileExtension: fileExtension[1].toLowerCase(),
                imageName: fileExtension[0],
                image: result,
                imageURL: result._url,
            };
            setIdCardLogoUploadProgress(100);
            setIdCardLogo(tempFile)

        }
        setHeaderFontSize(0)
        setSelectedHeaderTypeSetting(HeaderSettingTypes.customLogo)
        setShowLogoUploadView(true)

    }

    const changeToDefaultHeaderType = () => {
        setIdCardLogo()
        setHeaderFontSize(14)
        setShowLogoUploadView(false)
        setSelectedHeaderTypeSetting(HeaderSettingTypes.logoAndName)
    }

    useEffect(() => {
        if (props.selectedSchool && props.selectedSchool.attributes.idCardLogo) {
            changeCustomHeaderLogo(props.selectedSchool.attributes.idCardLogo)
        } else {
            if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
                props.setSelecetdSchool(schoolId);
            }
            changeToDefaultHeaderType()
        }
    }, [props.selectedSchool, schoolId])


    const getClassroomName = (ClassRoomID) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === ClassRoomID
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }
    
    return (
        <React.Fragment>
            {schoolObject && <Row className="text-center bg-white">
                <Col xs="12" className="d-print-none mt-3 mb-3">
                    <div className="navbar-header p-0" style={{ borderBottom: "1px solid gray" }}>
                        <h4 className="text-center m-4"><img src={WhiteLabel.getAppLogoWithName()} className="m-5" height="50px" style={{ border: "1px solid lightGray", borderRadius: "10px", padding: "10px" }}></img>{"ESCORT ID CARD"}</h4>

                        <Row className="d-flex m-4" style={{ width: "110mm" }}>
                            <Col className='d-flex justify-content-start m-4'>
                                <div>
                                    <Button
                                        color="primary"
                                        className='d-flex justify-content-start btn btn-primary waves-effect waves-light m-2'
                                        onClick={() => {
                                            setShowSettingsModal(true)
                                        }
                                        }
                                    >
                                        Customise
                                    </Button>
                                </div>
                                <div>
                                <Button onClick={() => {
                                    if (['Windows', 'Mac'].includes(machineType)) {

                                        if (!dontShowAgain) {
                                            setShowSaveAsPdfModal(true); 
                                        } else {
                                            printPoster();
                                        }
                                    }else{
                                        printPoster();
                                    }
                                    }}className="btn btn-success waves-effect waves-light m-2"><i className="fa fa-print m-1"></i> Print/Download
                                </Button>
                                <SaveAsPdf
                                    showModal={showSaveAsPdfModal}
                                    toggleModal={() => setShowSaveAsPdfModal(false)}
                                    dontShowAgain={dontShowAgain}
                                    setDontShowAgain={setDontShowAgain}
                                    onNextClick={handleNextClick}
                                    machineType={machineType}
                                />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Col>

                {allEscorts && allEscorts.length == 1 && (<Row className="">{getCardUI(true)}</Row>)}
                {allEscorts && allEscorts.length == 1 && (<Row className="">{getCardUI()}</Row>)}

            </Row>}
            <Modal isOpen={showSettingsModal} toggle={() => { setShowSettingsModal(!showSettingsModal) }} scrollable={true}>
                <ModalHeader toggle={() => {
                    setShowSettingsModal(!showSettingsModal)
                }}>
                    Customise ID Card
                </ModalHeader>
                <ModalBody>
                    <div className='mt-2'>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <button
                                    onClick={() => {
                                        setOrientaion("P")
                                        if (onlyQR) {
                                            setOnlyQR(false)
                                        }

                                        if (layoutType == "NoQR") {
                                            setSelectedPrintSetting(withoutQRCodePortrait)
                                        } else {
                                            setSelectedPrintSetting(potraitSize)
                                        }
                                    }}
                                    className={
                                        (orientaion == "P")
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    Portrait
                                </button>
                                <button
                                    onClick={() => {
                                        setOrientaion("L")
                                        if (onlyQR) {
                                            setOnlyQR(false)
                                        }
                                        if (layoutType == "NoQR") {
                                            setSelectedPrintSetting(withoutQRCodeLandscape)
                                        } else {
                                            setSelectedPrintSetting(landscapeSize)
                                        }
                                    }}
                                    className={
                                        (orientaion == "L")
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    Landscape
                                </button>
                                <button
                                    onClick={() => {
                                        setLayoutType("QR")
                                        if (onlyQR) {
                                            setOnlyQR(false)
                                        }
                                        if (orientaion == "P") {
                                            setSelectedPrintSetting(potraitSize)
                                        } else {
                                            setSelectedPrintSetting(landscapeSize)
                                        }
                                    }}
                                    className={
                                        layoutType === "QR"
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    With QR
                                </button>
                                <button
                                    onClick={() => {
                                        setLayoutType("NoQR")
                                        if (onlyQR) {
                                            setOnlyQR(false)
                                        }
                                        if (orientaion == "P") {
                                            setSelectedPrintSetting(withoutQRCodePortrait)
                                        } else {
                                            setSelectedPrintSetting(withoutQRCodeLandscape)
                                        }
                                    }}
                                    className={
                                        layoutType === "NoQR"
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    Without QR
                                </button>
                                <button
                                    onClick={() => {
                                        setSelectedPrintSetting(onlyQR);
                                        setOnlyQR(!onlyQR)
                                    }}
                                    className={
                                        onlyQR
                                            ? "btn btn-success btn-sm waves-effect waves-light m-2"
                                            : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                                    }
                                >
                                    Only QR
                                </button>
                            </div>
                        </Row>

                        <hr></hr>
                        <Row className='m-1'><Col><label>Header Settings</label></Col></Row>
                        <Row>
                            <div className="d-flex justify-content-start">
                                <button onClick={() => {
                                    setHeaderFontSize(14)
                                    setSelectedHeaderTypeSetting(HeaderSettingTypes.logoAndName)
                                    setShowLogoUploadView()
                                    setIdCardLogo()
                                    setIdCardLogoUploadProgress(0)
                                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.logoAndName ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Logo & Name"}</button>
                                <button onClick={() => {
                                    setHeaderFontSize(0)
                                    setSelectedHeaderTypeSetting(HeaderSettingTypes.logoOnly)
                                    setShowLogoUploadView()
                                    setIdCardLogo()
                                    setIdCardLogoUploadProgress(0)

                                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.logoOnly ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Logo Only"}</button>
                                <button onClick={() => {
                                    changeCustomHeaderLogo(props.selectedSchool && props.selectedSchool.attributes.idCardLogo)

                                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.customLogo ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Custom Logo"}</button>
                            </div>
                        </Row>
                        {showLogoUploadView && <Row>
                            <Col>
                                {idCardLogo &&
                                    idCardLogoUploadProgress === 100 && (
                                        <Col xl="12" md="12" sm="12" xs="12">
                                            <div className="border p-3">
                                                <Row>
                                                    <div className="ml-3">
                                                        <img
                                                            style={{ width: "12rem", height: "4rem" }}
                                                            className="rounded avatar"
                                                            src={idCardLogo.imageURL}
                                                            alt=""
                                                        />
                                                    </div>
                                                    <div
                                                        className="float-right m-2"
                                                        style={{ width: "200px" }}
                                                    >
                                                        <div className="text-muted font-weight-bold">
                                                            {idCardLogo.actualImageName}
                                                        </div>
                                                        <div className="d-flex mt-3">
                                                            <UpdatePhoto
                                                                isRectangle={true}
                                                                id={"idCardCustom"}
                                                                tempId={"idCardCustom"}
                                                                className="m-2"
                                                                buttonTitle="Change"
                                                                isPopupOn={false}
                                                                onPicChange={(imageFile, url) => {
                                                                    handleImageUploadChange(

                                                                        url,
                                                                        imageFile,
                                                                    );
                                                                }}
                                                                imageFileName={
                                                                    idCardLogo
                                                                        ? idCardLogo.actualImageName
                                                                        : ""
                                                                }
                                                                initialImage={
                                                                    idCardLogo.imageURL
                                                                }
                                                            ></UpdatePhoto>
                                                            <Button
                                                                style={{ marginLeft: "10px" }}
                                                                className="btn-danger float-right"
                                                                onClick={() => {
                                                                    setIdCardLogo(undefined)
                                                                    setIdCardLogoUploadProgress(0)
                                                                    if (props.selectedSchool) {
                                                                        props.selectedSchool.unset("idCardLogo")
                                                                        props.selectedSchool.save().then(
                                                                            (result) => {

                                                                            },
                                                                            (error) => {

                                                                            }
                                                                        );
                                                                    }
                                                                }}
                                                            >
                                                                Clear
                                                            </Button>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Col>
                                    )}
                                {idCardLogo &&
                                    idCardLogoUploadProgress >= 1 &&
                                    idCardLogoUploadProgress < 100 && (
                                        <Col xl="12" md="12" sm="12" xs="12">
                                            <div className="border p-3">
                                                <Row>
                                                    <div
                                                        className="avatar ml-3"
                                                        style={{ width: "5rem", height: "5rem" }}
                                                    >
                                                        <span
                                                            className={
                                                                "avatar-title rounded-circle bg-soft-primary text-primary"
                                                            }
                                                            style={{ fontSize: "60px" }}
                                                        ></span>
                                                    </div>
                                                    <div
                                                        className="float-right ml-4"
                                                        style={{ width: "200px" }}
                                                    >
                                                        <div className="text-muted font-weight-bold">
                                                            {idCardLogo.actualImageName}
                                                        </div>
                                                        <div className=" mt-3">
                                                            <Progress

                                                                color="primary"
                                                                value={idCardLogoUploadProgress}
                                                            ></Progress>
                                                        </div>
                                                    </div>
                                                </Row>
                                            </div>
                                        </Col>
                                    )}
                                {idCardLogo == undefined && (
                                    <div className="d-flex">
                                        <div>
                                            {" "}
                                            <UpdatePhoto
                                                isRectangle={true}
                                                buttonTitle={"Upload Custom Logo"}
                                                buttonTitleInitial={"Upload Custom Logo"}
                                                id={"idCardCustomImage"}
                                                tempId={"idCardCustomImage"}
                                                className="p-1"
                                                isPopupOn={false}
                                                onPicChange={(imageFile, url) => {
                                                    handleImageUploadChange(
                                                        url,
                                                        imageFile,
                                                    );
                                                }}
                                                initialImage={""}
                                            ></UpdatePhoto>
                                        </div>
                                    </div>
                                )}
                            </Col>
                        </Row>}
                        <hr></hr>

                        <Row className='d-flex justify-content-between'>
                            <Col>
                                <label className='mb-1 mt-2'>Card Border</label>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <div>

                                    {<Link
                                        to="#"
                                        className="float-right"
                                        style={{ cursor: "pointer" }}
                                        onClick={(e) => {
                                            e.preventDefault()
                                            setIsBorderOff(false)
                                            setHeaderFontSize(14)
                                            setBodyFontSize(11)
                                            setFooterFontSize(10)
                                        }}
                                        id="IdCardSettingsReloadButton"
                                    >
                                        <i style={{ fontSize: "30px" }} className="bx bx-refresh text-primary"></i>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={"IdCardSettingsReloadButton"}
                                        >
                                            {props.t("Reset")}
                                        </UncontrolledTooltip>
                                    </Link>}
                                </div>
                            </Col>

                        </Row>
                        <Row>
                            <div className='d-flex'>
                                <div className='m-2'>
                                    <label className='float-left'>Disable</label>
                                </div>
                                <Switch
                                    height={15}
                                    width={25}
                                    className="m-2"
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor={"#6add7d"}
                                    onChange={() => {
                                        setIsBorderOff(!isBorderOff)
                                    }}
                                    checked={!isBorderOff}
                                />
                                <div className='m-2'>
                                    <label className='float-left'>Enable</label>
                                </div>
                            </div>
                        </Row>
                        <Row>
                            <Col>
                                <div className='d-flex mt-2'>
                                    <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Header font size</label>
                                    <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                                        <div className='m-2' onClick={() => changeHeaderFontSize(true)}>
                                            <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                                        </div>
                                        <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{headerFontSize}</div>
                                        {headerFontSize < 20 && (
                                            <div className='m-2' onClick={() => changeHeaderFontSize()}>
                                                <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>


                        <Row>
                            <Col>
                                <div className='d-flex mt-2'>
                                    <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Body font size </label>
                                    <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                                        <div className='m-2' onClick={() => changeBodyFontSize(true)}>
                                            <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                                        </div>
                                        <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{bodyFontSize}</div>
                                        {bodyFontSize < 20 && (
                                            <div className='m-2' onClick={() => changeBodyFontSize()}>
                                                <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col>
                                <div className='d-flex mt-2'>
                                    <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Footer font size </label>
                                    <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                                        <div className='m-2' onClick={() => changeFooterFontSize(true)}>
                                            <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                                        </div>
                                        <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{footerFontSize}</div>
                                        {footerFontSize < 20 && (
                                            <div className='m-2' onClick={() => changeFooterFontSize()}>
                                                <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="p-3">
                                    <Label>Card Size</Label>
                                    <Slider
                                        value={sliderValue}
                                        orientation="horizontal"
                                        min={0}
                                        max={1}
                                        step={0.1}
                                        onChange={value => {
                                            updateSizeScale(value);
                                            setSliderValue(value);
                                        }}
                                    />
                                </div>

                            </Col>
                        </Row>
                        <div>
                            <div className='d-flex justify-content-end'>
                                <button className='btn btn-primary' onClick={() => {
                                    setShowSettingsModal(!showSettingsModal)
                                }}>Done</button>
                            </div>
                        </div>
                    </div>
                </ModalBody>
            </Modal>
            {
                forSchool &&
                (props.selectedSchool && props.selectedSchool.id == schoolId &&
                    allEscorts && allEscorts.length > 0 ? getParentUIWithHeader() : getLoadingUI())
            }
            <Modal size="lg" isOpen={showPopup} toggle={() => { setShowPopup(!showPopup) }} scrollable={true}>
                <ModalHeader toggle={() => {
                    setShowPopup(!showPopup)
                }}>
                    Add/Remove <br></br>
                    <small>Please select/unselect the profile which you want to print</small>
                </ModalHeader>

                <ModalBody>
                    <div className='mt-2'>
                        <Table>
                            <thead>
                                <tr>
                                    <th>
                                        <div className="custom-control custom-checkbox">
                                            <input
                                                type="checkbox"
                                                className="custom-control-input"
                                                id="checkbox"
                                                name="checkbox"
                                                checked={allEscorts.length === allEscortsListMaster.length}
                                                onChange={() => {
                                                    if (allEscortsListIds.length !== 1) {

                                                        setAllEscorts([allEscortsListMaster[0]])
                                                        setAllEscortsListIds([allEscortsListMaster[0].id])
                                                    } else {
                                                        setAllEscortsListIds(allEscortsMasterListIds)
                                                        setAllEscorts(allEscortsListMaster)
                                                    }
                                                }}
                                            />
                                            <label className="custom-control-label" htmlFor="checkbox">
                                                ({allEscorts.length})
                                            </label>
                                        </div>

                                    </th>
                                    <th>{props.t("Kid Photo")}</th>
                                    <th>{props.t("Escort Photo")}</th>
                                    <th>{props.t("Name")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {allEscortsListMaster.map((val, key) => (
                                    <tr key={key}>
                                        <td>
                                            <div className="custom-control custom-checkbox mb-2">
                                                <input
                                                    type="checkbox"
                                                    className="custom-control-input"
                                                    id={`checkbox${val.id}`}
                                                    name="checkbox"
                                                    checked={allEscortsListIds.includes(val.id)}
                                                    onChange={(e) => {
                                                        if (allEscortsListIds.includes(val.id)) {
                                                            if (allEscorts.length != 1) {
                                                                let updatedStudentList = allEscorts.filter((student) => {
                                                                    return student.id != val.id
                                                                })
                                                                let updatedStudentListIds = allEscortsListIds.filter((student) => {
                                                                    return student != val.id
                                                                })
                                                                setAllEscorts(updatedStudentList)
                                                                setAllEscortsListIds(updatedStudentListIds)
                                                            }
                                                        } else {
                                                            let tempIds = [val.id, ...allEscortsListIds]
                                                            setAllEscortsListIds(tempIds)
                                                            let tempStudentList = [];
                                                            for (const student of allEscortsListMaster) {
                                                                if (tempIds.includes(student.id)) {
                                                                    tempStudentList.push(student)
                                                                }
                                                            }
                                                            setAllEscorts(tempStudentList)
                                                        }
                                                    }}
                                                />
                                                <label className="custom-control-label" htmlFor={`checkbox${val.id}`}></label>
                                            </div>

                                        </td>
                                        <td>
                                            {
                                                (!val.kid.attributes.Photo ?
                                                    <div className="avatar-sm  mb-4">
                                                        <span className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-16"}>
                                                            {val.kid.attributes.Name.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>
                                                    :
                                                    <div className="avatar-sm  mb-4">
                                                        <img className="rounded-circle avatar-sm" src={val.kid.attributes.Photo._url} alt="" />
                                                    </div>)
                                            }
                                        </td>
                                        <td>
                                            {val.type == "P" ?
                                                (val.object.attributes.Photo ?
                                                    <div className="avatar-sm  mb-4">
                                                        <img className="rounded-circle avatar-sm" src={val.object.attributes.Photo._url} alt="" />
                                                    </div>
                                                    :
                                                    <div className="avatar-sm  mb-4">
                                                        <span className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-16"}>
                                                            {val.object.attributes.Name && val.object.attributes.Name.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>) :
                                                (val.object.attributes.Photo ?
                                                    <div className="avatar-sm  mb-4">
                                                        <img className="rounded-circle avatar-sm" src={val.object.attributes.Photo._url} alt="" />
                                                    </div>
                                                    :
                                                    <div className="avatar-sm  mb-4">
                                                        <span className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-16"}>
                                                            {val.object.attributes.PersonName && val.object.attributes.PersonName.charAt(0).toUpperCase()}
                                                        </span>
                                                    </div>)
                                            }
                                        </td>
                                        <td>
                                            <p className=" mb-0">{`Student-${val.kid.attributes.Name}, ${getClassroomName(val.kid.attributes.ClassRoomID)}`}</p>
                                            <p className=" mb-0">{`Escort-${val.type == 'P' ? val.object.attributes.Name : val.object.attributes.PersonName}, ${val.type == 'P' ? val.object.attributes.Relation : val.object.attributes.Relation}`}</p>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </ModalBody>
            </Modal>
        </React.Fragment >
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { escorts, kids } = state.Kid;
    const { parents } = state.Parent;
    const { classrooms } = state.Classroom;

    return { selectedSchool, kids, escorts, parents, classrooms };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getAllKids,
            setSelecetdSchool,
            getParentsForFamilyIds,
            getEscortsForKidIds,
            getClassroomsForSchoolId,
        })(EscortIdCard)
    )
);
