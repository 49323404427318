export const UPDATE_CHILD_ADDITIONAL_DETAIL = "UPDATE_CHILD_ADDITIONAL_DETAIL";
export const DELETE_CHILD_ADDITIONAL_DETAIL = "DELETE_CHILD_ADDITIONAL_DETAIL";

export const UPDATE_CHILD_ADDITIONAL_DETAILS =
  "UPDATE_CHILD_ADDITIONAL_DETAILS";
export const GET_CHILD_ADDITIONAL_DETAILS = "GET_CHILD_ADDITIONAL_DETAILS";
export const GET_CHILD_ADDITIONAL_DETAIL = "GET_CHILD_ADDITIONAL_DETAIL";

export const CHILD_ADDITIONAL_DETAIL_SUCCESS =
  "CHILD_ADDITIONAL_DETAIL_SUCCESS";
export const CHILD_ADDITIONAL_DETAIL_ERROR = "CHILD_ADDITIONAL_DETAIL_ERROR";

export const EDIT_CHILD_ADDITIONAL_DETAIL = "EDIT_CHILD_ADDITIONAL_DETAIL";
export const SET_CHILD_ADDITIONAL_DETAIL_OBJECT =
  "SET_CHILD_ADDITIONAL_DETAIL_OBJECT";

export const GET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS =
  "GET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS";
export const SET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS =
  "SET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS";
export const CLEAR_CHILD_ADDITIONAL_DETAILS = "CLEAR_CHILD_ADDITIONAL_DETAILS"