import React, { useState, useEffect } from "react";
import { Table, Card, CardBody, Row, Col } from "reactstrap";
import { withNamespaces } from "react-i18next";
import Switch from "react-switch";
import AddClass from "../classes/addClass";
import Image from "../../assets/images/getStartedImages/classroomInitial.svg";

const TeacherClassrooms = (props) => {
  const [classroomIds, setClassroomIds] = useState([]);
  const [modal_standard, modal_standardOps] = useState(false);
  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };
  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  useEffect(() => {
    if (props.selectedTeacher && props.selectedTeacher.attributes.Role === 1) {
      if (props.selectedTeacher.attributes.ClassRooms) {
        setClassroomIds(props.selectedTeacher.attributes.ClassRooms);
      }
    }
  }, [props.selectedTeacher]);

  function isClassAcilable(classroom) {
    if (props.selectedTeacher) {
      if (
        props.selectedTeacher.attributes.Role === 2 ||
        props.selectedTeacher.attributes.Role === 3
      ) {
        return true;
      } else if (
        props.selectedTeacher.attributes.ClassRooms &&
        classroomIds.includes(classroom.id)
      ) {
        return true;
      }
    }
    return false;
  }

  function onclickAccess(classroom) {
    if (props.selectedTeacher) {
      if (props.selectedTeacher.attributes.Role === 1) {
        var newList = [];
        if (props.selectedTeacher.attributes.ClassRooms) {
          newList = props.selectedTeacher.attributes.ClassRooms;
        }

        if (newList.includes(classroom.id)) {
          var list = newList.filter((item) => item !== classroom.id);
          setClassroomIds(list);
          props.onChangeClassroms(list);
        } else {
          newList.push(classroom.id);
          var list = [...newList];
          setClassroomIds(list);
          props.onChangeClassroms(list);
        }
      }
    }
  }
  const tog_standard = () => {
    modal_standardOps(!modal_standard);
  };

  return (
    <React.Fragment>
      <AddClass modal_standard={modal_standard} tog_standard={tog_standard} />
      {props.classrooms.length === 0 && (
        <Card>
          <CardBody>
            <div className="text-center">
              <Row className="justify-content-center">
                <Col sm={4}>
                  <div className="maintenance-img">
                    <img
                      src={Image}
                      alt=""
                      className="img-fluid mx-auto d-block"
                    />
                  </div>
                </Col>
              </Row>
              <h3 className="mt-5">
                {props.t("Create a new class for your School")}
              </h3>
              <p>{props.t("create a new class just in few steps")}.</p>

              <button onClick={tog_standard} className="btn btn-primary">
                {props.t("Add Class")}
              </button>
            </div>
          </CardBody>
        </Card>
      )}
      {props.classrooms.length > 0 && (
        // <p className="card-title-desc">
        //   {props.t(
        //     "Based on your selection classroom access granted to your staff in  mobile application"
        //   )}
        // </p>
         <p className="card-title-desc">{
               (props.selectedTeacher&&( props.selectedTeacher.attributes.Role == 3 || props.selectedTeacher.attributes.Role == 2))?
                    props.t('Director and Center Head profiles will have access to all classrooms and access can not disabled') :
                    props.t('Based on your selection classroom access granted to your staff in  mobile application')
            }</p>
      )}
      <Table className="table-centered table-nowrap table-hover">
        {props.classrooms.length > 0 && (
          <thead className="thead-light">
            <tr>
              <th scope="col" style={{ width: "70px" }}>
                #
              </th>
              <th scope="col" style={{ width: "450px" }}>
                {props.t("Classroom Name")}
              </th>
              <th scope="col">{props.t("Access")}</th>
            </tr>
          </thead>
        )}
        <tbody>
          {props.classrooms.map((item, i) => (
            <tr key={item.id}>
              <td>{i + 1}</td>
              <td>{item.attributes.Name}</td>
              <td>
                {props.selectedTeacher &&
                props.selectedTeacher.attributes.Role === 1 ? (
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={() => {
                      onclickAccess(item);
                    }}
                    checked={isClassAcilable(item)}
                  />
                ) : (
                  <Switch
                    uncheckedIcon={<Offsymbol />}
                    checkedIcon={<OnSymbol />}
                    onColor="#626ed4"
                    onChange={() => {
                      onclickAccess(item);
                    }}
                    checked={isClassAcilable(item)}
                    disabled
                  />
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </React.Fragment>
  );
};

export default withNamespaces()(TeacherClassrooms);
