import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Alert,
  Table
} from "reactstrap";
import LineChart from "./lineChart";
import { setSelecetdSchool, getClassroomsForSchoolId, getDeletedTeachersForMonth, updateDeletedTeachersForMonth } from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import { Parse } from "parse";
import * as Constants from "../../Constents";
import Moment from "moment";
import defaultKidPhoto from "../../assets/images/users/user.png";
import PieChart from "./pieChart";
import RadarChart from "./radarChart";



const LearningAnalytics = (props) => {

  const dateStringList = [
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
    "April"
  ]

  const match = matchPath(props.history.location.pathname, {
    path: "/learning-analytics/:schoolId/:kidId",
    exact: true,
    strict: false,
  });

  var schoolId = match && match.params.schoolId;
  var kidId = match && match.params.kidId;

  if (match) {
    schoolId = match && match.params.schoolId;
    kidId = match && match.params.kidId;
  }

  const [isLoading, setIsLoading] = useState(true);
  const [categoryGraphList, setCategoryGraphList] = useState([]);
  const [individualMeasureList, setIndividualMeasureList] = useState([]);
  //Ses - SocioEmotionalSkill GraphType in Learning Measure.
  const [sesMeasureList, setSesMeasureList] = useState([]);

  const [schoolObject, setSchoolObject] = useState(undefined)
  const [schoolLogo, setSchoolLogo] = useState(undefined);
  const [kidPhoto, setKidPhoto] = useState(defaultKidPhoto)
  const [kidObject, setKidObject] = useState(undefined)
  const [studentClassroom, setStudentClassroom] = useState("")
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear())
  const [pastYearsList, setPastYearsList] = useState([new Date().getFullYear(), new Date().getFullYear() - 1, new Date().getFullYear() - 2])
  const [pastYearDisplayList, setPastYearDisplayList] = useState([`${new Date().getFullYear()}-${new Date().getFullYear() + 1}`,
  `${new Date().getFullYear() - 1}-${new Date().getFullYear()}`,
  `${new Date().getFullYear() - 2}-${new Date().getFullYear() - 1}`])
  const [showLegendModal, setShowLegendModal] = useState(false)
  const [selectedCategoryForLegend, setSelectedCategoryForLegend] = useState(undefined)
  const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [machineType, setMachineType] = useState('');


  useEffect(() => {
    if (schoolId && schoolId.length > 0) {
      fetchSchoolFromServer(schoolId);
      fetchKidObjectFunction()
      //props.getClassroomsForSchoolId(schoolId);
    }


  }, [schoolId]);


  const getAcademicYear = (year) => {
    let selectedY = new Date("" + year)
    return `${selectedY.getFullYear()}-${selectedY.getFullYear() + 1}`
  }

  const fetchKidObjectFunction = () => {
    Parse.Cloud.run("function_kidDetailsForKidId", {
      kidId: kidId,
    }).then(
      (result) => {
        if (result != null) {
          if (result.kid) {
            setKidObject(result.kid)

            if (result.kid.attributes.Photo) {
              setKidPhoto(result.kid.attributes.Photo._url);
            }
          }
          if (result.classRoom) {
            setStudentClassroom(result.classRoom.attributes.Name)
          }

        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    if (selectedYear) {
      let tempDateList = [
        new Date(selectedYear, 4, 2),
        new Date(selectedYear, 5, 2),
        new Date(selectedYear, 6, 2),
        new Date(selectedYear, 7, 2),
        new Date(selectedYear, 8, 2),
        new Date(selectedYear, 9, 2),
        new Date(selectedYear, 10, 2),
        new Date(selectedYear, 11, 2),
        new Date(selectedYear, 0, 2),
        new Date(selectedYear, 1, 2),
        new Date(selectedYear, 2, 2),
        new Date(selectedYear, 3, 2)
      ]
      let startDate = new Date(selectedYear, 4, 1)
      let endDate = new Date(selectedYear + 1, 3, 30, 23, 59, 59)
      setIsLoading(true)
      getLearningCategoriesForSchool(tempDateList, startDate, endDate)

    }
  }, [selectedYear]);

  const fetchSchoolFromServer = (schoolId) => {
    Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
      schoolId: schoolId,
    }).then(
      (result) => {
        if (result != null) {
          setSchoolObject(result);
          if (result.attributes.logo) {
            setSchoolLogo(result.attributes.logo._url);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const getLearningCategoriesForSchool = (dateList, startDate, endDate) => {
    Parse.Cloud.run("function_getLearningCategories", { schoolId: schoolId }).then((results) => {

      if (results) {

        let tempList = []
        let categoryIds = []
        let measureIds = []
        let scaleIds = []
        let tempIndividualMeasureList = []

        for (var i = 0; i < results.length; i++) {

          let categoryId = results[i].Category.id
          if (!categoryIds.includes(categoryId)) {
            categoryIds.push(categoryId)

          }

          let categoryGraphObject = {}

          categoryGraphObject.categoryName = results[i].Category.attributes.name
          categoryGraphObject.categoryParseObject = results[i].Category
          categoryGraphObject.measures = []
          categoryGraphObject.absentList = []
          if (results[i].Measures && results[i].Measures.length > 0) {
            let tempMeasureList = []
            for (var j = 0; j < results[i].Measures.length; j++) {
              if (results[i].Measures[j].attributes.groupingType === Constants.INDIVIDUAL_GRAPHS || results[i].Measures[j].attributes.graphType === Constants.GRAPHTYPE_SES) {

                let tempList = tempIndividualMeasureList.filter((measure) => (
                  measure.measureParseObject.id === results[i].Measures[j].id
                ))

                if (tempList.length > 0) {

                } else {
                  let tempIndividualMeasureObject = { measureName: results[i].Measures[j].attributes.name ?? "", measureSkillDatas: [], measureSkillLabels: [], measureParseObject: results[i].Measures[j] }
                  tempIndividualMeasureList.push(tempIndividualMeasureObject)
                }
              } else {
                let tempMeasureObj = {}
                tempMeasureObj.measureName = results[i].Measures[j].attributes.name ?? ""
                tempMeasureObj.measureDatas = []
                tempMeasureObj.measureParseObject = results[i].Measures[j]
                tempMeasureList.push(tempMeasureObj)
              }

              if (!measureIds.includes(results[i].Measures[j].id)) {
                measureIds.push(results[i].Measures[j].id)
              }

              if (!scaleIds.includes(results[i].Measures[j].attributes.scaleId)) {
                scaleIds.push(results[i].Measures[j].attributes.scaleId)
              }
            }
            categoryGraphObject.measures = tempMeasureList

          }
          categoryGraphObject.dateList = dateStringList
          categoryGraphObject.dates = []
          categoryGraphObject.images = []
          tempList.push(categoryGraphObject)
        }
        getTrackings(tempList, tempIndividualMeasureList, scaleIds, categoryIds, measureIds, dateList, startDate, endDate)
      } else {
        setIsLoading(false)
      }
    });
  }


  const getScaleNumericValueForRating = (rating, scaleValues) => {
    let selectedScaleValues = scaleValues.filter((sv) => {
      if (sv.attributes.scaleValue && sv.attributes.scaleValue.toLowerCase() === rating.toLowerCase()) {
        return true
      }
      return false
    })
    if (selectedScaleValues.length > 0) {
      if (selectedScaleValues[0].attributes.numericValue) {
        return selectedScaleValues[0].attributes.numericValue
      }
    }
    return 0
  }


  const getTrackings = (tempList, tempIndividualMeasureList, scaleIds, categoryIds, measureIds, dateList, startDate, endDate) => {
    Parse.Cloud.run("function_getTrackingValuesForMeasure", { categoryIds: categoryIds, kidId: kidId, measureIds: measureIds, startDate: startDate, endDate: endDate }).then((trackingResults) => {

      if (trackingResults) {
        Parse.Cloud.run("function_getLearningScaleType", { scaleIds: scaleIds }).then((scaleResults) => {

          let scaleTypes = []
          let albums = []
          if (scaleResults.LearningScaleType) {
            scaleTypes = scaleResults.LearningScaleType
          }
          if (scaleResults.Album) {
            albums = scaleResults.Album
          }
          Parse.Cloud.run("function_getLearningScaleValues", { scaleIds: scaleIds }).then((scaleValues) => {
            if (scaleValues != null) {
              let tempMainList = [...tempList]
              for (var i = 0; i < tempList.length; i++) {

                let categoryGraphObject = tempMainList[i]
                let tempMeasuresList = [...categoryGraphObject.measures]
                let tempAbsentList = []

                let images = []

                let mesureIds = [];
                if (tempMeasuresList && tempMeasuresList.length > 0) {
                  for (const ml of tempMeasuresList) {
                    mesureIds.push(ml.measureParseObject.id);
                  }
                }
                for (var j = 0; j < tempMeasuresList.length; j++) {
                  let tempDataList = []
                  let measurewiseScaleIds = []

                  if (tempMeasuresList[j].measureParseObject.attributes.scaleId) {
                    measurewiseScaleIds.push(tempMeasuresList[j].measureParseObject.attributes.scaleId)
                  }

                  for (var k = 0; k < dateList.length; k++) {
                    let monthwiseTrackingResults = trackingResults.filter((tr) => {
                      if (tr.attributes.activitySessionDate && tr.attributes.categoryId) {
                        if (convertDateToString(tr.attributes.activitySessionDate) === convertDateToString(dateList[k]) && tr.attributes.measure === tempMeasuresList[j].measureParseObject.id && categoryGraphObject.categoryParseObject.id === tr.attributes.categoryId) {
                          return true
                        }
                      }
                      return false
                    }
                    )

                    if (monthwiseTrackingResults.length > 0) {
                      let totalRatingValue = 0
                      let totalAbsentValue = 0
                      let daywiseAbsentDictionary = {}


                      for (var l = 0; l < monthwiseTrackingResults.length; l++) {
                        let ratingValue = getScaleNumericValueForRating(monthwiseTrackingResults[l].attributes.rating, scaleValues)
                        if (ratingValue && ratingValue !== -1) {
                          totalRatingValue += ratingValue
                        }
                        if ((ratingValue && ratingValue === -1) || (monthwiseTrackingResults[l].attributes.rating && monthwiseTrackingResults[l].attributes.rating.toLowerCase() === "absent")) {
                          // if (monthwiseTrackingResults[l].attributes.activitySessionDate) {
                          //   let dayString = convertDateToStringForDOB(monthwiseTrackingResults[l].attributes.activitySessionDate)
                          //   if (!daywiseAbsentDictionary[dayString]) {
                          //     daywiseAbsentDictionary[dayString] = 1
                          //   }
                          // }
                          totalAbsentValue += 1

                        }
                      }
                      let average = totalRatingValue / monthwiseTrackingResults.length
                      let averageRatingValue = Math.round(average)
                      tempDataList.push(averageRatingValue)
                      if (j > 0) {
                        tempAbsentList[k] += totalAbsentValue

                      } else {
                        tempAbsentList.push(totalAbsentValue)

                      }
                    } else {
                      tempDataList.push(0)
                      if (j === 0) {
                        tempAbsentList.push(0)
                      }

                    }
                  }
                  tempMeasuresList[j].measureDatas = [...tempDataList]


                  let scaleTypesBasedOnMeasures = []

                  for (var b = 0; b < measurewiseScaleIds.length; b++) {
                    let scaleTypeObject = scaleTypes.filter((scale) => (
                      scale.id === measurewiseScaleIds[b]
                    ))
                    if (scaleTypeObject.length > 0) {
                      scaleTypesBasedOnMeasures.push(scaleTypeObject[0])
                    }
                  }

                  for (var d = 0; d < scaleTypesBasedOnMeasures.length; d++) {

                    let albumsBasedOnScale = albums.filter((album) => (
                      album.attributes.albumId === scaleTypesBasedOnMeasures[d].attributes.legend
                    ))
                    if (albumsBasedOnScale.length > 0) {
                      images.push(albumsBasedOnScale[0].attributes.image._url)
                    }
                  }
                }
                let finalAbsentList = []
                for (var a = 0; a < tempAbsentList.length; a++) {

                  let monthwiseTotalTrackings = trackingResults.filter((tr) => {
                    if (tr.attributes.activitySessionDate && tr.attributes.categoryId && mesureIds.includes(tr.attributes.measure)) {
                      if (convertDateToString(tr.attributes.activitySessionDate) === convertDateToString(dateList[a]) && categoryGraphObject.categoryParseObject.id === tr.attributes.categoryId) {
                        return true
                      }
                    }
                    return false
                  }
                  )

                  if (monthwiseTotalTrackings.length > 0) {
                    let tempAns = tempAbsentList[a] / monthwiseTotalTrackings.length
                    finalAbsentList.push(Math.ceil(tempAns * 10))
                  } else {
                    finalAbsentList.push(0)
                  }
                }
                categoryGraphObject.absentList = [...finalAbsentList]
                categoryGraphObject.measures = [...tempMeasuresList]
                categoryGraphObject.images = images
                let index = tempList.indexOf(categoryGraphObject)
                tempMainList[index] = categoryGraphObject
              }
              setCategoryGraphList(getSortedListForObjects(tempMainList, "categoryName"))

              if (tempIndividualMeasureList.length > 0) {
                let tempIndividualMeasureListForState = [...tempIndividualMeasureList]
                for (var i = 0; i < tempIndividualMeasureList.length; i++) {
                  let scaleValuesForMeasure = scaleValues.filter((sv) => (sv.attributes.scale === tempIndividualMeasureList[i].measureParseObject.attributes.scaleId))
                  if (scaleValuesForMeasure.length > 0) {
                    let tempMeasureSkillDatas = []
                    let tempMeasureSkillLabels = []
                    let skillDictionary = {}

                    for (var a = 0; a < scaleValuesForMeasure.length; a++) {

                      skillDictionary[scaleValuesForMeasure[a].attributes.scaleValue] = 0

                      let scalewiseTrackingResults = trackingResults.filter((tr) =>
                        (tr.attributes.rating.toLowerCase() === scaleValuesForMeasure[a].attributes.scaleValue.toLowerCase() && tr.attributes.measure === tempIndividualMeasureList[i].measureParseObject.id))

                      if (scalewiseTrackingResults.length > 0) {
                        skillDictionary[scaleValuesForMeasure[a].attributes.scaleValue] += scalewiseTrackingResults.length
                      }
                    }
                    if (tempIndividualMeasureList[i].measureParseObject.attributes.graphType === Constants.GRAPHTYPE_PIE) {
                      let sortedSkillArray = getSortedSkillList(skillDictionary) ?? []
                      for (const index in sortedSkillArray) {
                        tempMeasureSkillDatas.push(sortedSkillArray[index].value)
                        tempMeasureSkillLabels.push(sortedSkillArray[index].label)
                      }
                    } else {
                      for (let key in skillDictionary) {
                        tempMeasureSkillDatas.push(skillDictionary[key])
                        tempMeasureSkillLabels.push(key)
                      }
                    }
                    if (tempMeasureSkillLabels.length > 0) {
                      tempIndividualMeasureListForState[i].measureSkillLabels = tempMeasureSkillLabels
                      tempIndividualMeasureListForState[i].measureSkillDatas = tempMeasureSkillDatas
                      tempIndividualMeasureListForState[i].graphType = tempIndividualMeasureList[i].measureParseObject.attributes.graphType
                    }
                  }
                }
                let listWithoutSES = tempIndividualMeasureListForState.filter((item) => item.graphType !== Constants.GRAPHTYPE_SES)
                setIndividualMeasureList(getSortedListForObjects(listWithoutSES, "measureName"))

                let listWithSES = tempIndividualMeasureListForState.filter((item) => item.graphType === Constants.GRAPHTYPE_SES)
                setSesMeasureList(getSortedListForObjects(listWithSES, "measureName"))
              }
              setIsLoading(false)
            }
          });
        });
      } else {
        setIsLoading(false)
      }
    })
  }

  const getSortedListForObjects = (list, objprop) => {
    let sortedList = []
    if (list && list.length > 0) {
      sortedList = list.sort(function (a, b) {
        var nameA = a[objprop].toLowerCase(),
          nameB = b[objprop].toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
    }
    return sortedList
  }

  const getSortedSkillList = (skillDictionary) => {
    let sortedSkillArray = []
    let skillArray = []
    for (let key in skillDictionary) {
      skillArray.push({ label: key, value: skillDictionary[key] })
    }
    if (skillArray.length > 0) {
      sortedSkillArray = skillArray.sort(function (a, b) {
        var nameA = a.label.toLowerCase(),
          nameB = b.label.toLowerCase();
        if (nameA > nameB)
          //sort string ascending
          return -1;
        if (nameA < nameB) return 1;
        return 0; //default return value (no sorting)
      });
    }
    return sortedSkillArray
  }
  function convertDateToString(date) {
    return Moment(date).format('MM/YYYY')
  }
  function convertDateToStringForDOB(date) {
    return Moment(date).format('DD MMM YYYY')
  }


  function printPoster() {
    var css = "@page { size: A3 ;margin:10mm 0 10mm 0; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    setTimeout(() => {
      window.print();
    }, 300);
  }

  useEffect(() => {
    const savedValue = localStorage.getItem('dontShowAgain');
    if (savedValue === 'true') {
        setDontShowAgain(true);
    }
    const userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.includes('mac')) {
        setMachineType('Mac');
    } else if (userAgent.includes('windows')) {
        setMachineType('Windows');
    } else {
        setMachineType('Unknown');
    }
  }, []);

  const handleNextClick = () => {
    setShowSaveAsPdfModal(false);
    setTimeout(() => {
        printPoster();
    }, 300);
  }

  const getUIForGraph = (isForPrint, isPlainSheet) => {
    let paddingForGraphForScreenOnly = "40px"
    return (
      <React.Fragment>{categoryGraphList.map((categoryGraph, key) => (
        <React.Fragment key={key}>{key % 2 === 0 && <Row lg={12} md={12} sm={12} key={key} style={{ breakInside: "avoid" }} className="mt-5">
          <Col lg={6} md={6} sm={isForPrint ? 6 : 12}>
            <div className="mt-5">
              {isPlainSheet && <h5>.</h5>}
              {!isPlainSheet && <div><h5 className="mt-3" style={{ display: "inline-block" }}>{categoryGraph.categoryName}</h5>
                <div
                  className="float-right"
                  style={{ cursor: "pointer", fontSize: "30px" }}
                  onClick={() => {
                    setShowLegendModal(true)
                    setSelectedCategoryForLegend(categoryGraph)
                  }}
                >
                  <i className="bx bx-info-circle text-primary d-print-none"></i>
                </div>
              </div>}
              <div style={isForPrint || isPlainSheet ? { width: "475px", height: "300px" } : { paddingLeft: paddingForGraphForScreenOnly, paddingRight: paddingForGraphForScreenOnly, paddingBottom: paddingForGraphForScreenOnly }}>
                {!isPlainSheet && <LineChart measures={categoryGraph.measures} dateList={categoryGraph.dateList} absentList={categoryGraph.absentList} />}
              </div>
            </div>
          </Col>
          {categoryGraphList.length > (key + 1) && <Col lg={6} md={6} sm={isForPrint ? 6 : 12}>
            <div className="mt-5">
              {!isPlainSheet && <div><h5 className="mt-3" style={{ display: "inline-block" }}>{categoryGraphList[key + 1].categoryName}</h5>
                <div
                  className="float-right"
                  style={{ cursor: "pointer", fontSize: "30px" }}
                  onClick={() => {
                    setShowLegendModal(true)
                    setSelectedCategoryForLegend(categoryGraph)
                  }}
                >
                  <i className="bx bx-info-circle text-primary d-print-none"></i>
                </div>
              </div>}
              <div style={isForPrint || isPlainSheet ? { width: "475px", height: "300px" } : { paddingLeft: paddingForGraphForScreenOnly, paddingRight: paddingForGraphForScreenOnly, paddingBottom: paddingForGraphForScreenOnly }}>
                {!isPlainSheet && <LineChart measures={categoryGraphList[key + 1].measures} dateList={categoryGraphList[key + 1].dateList} absentList={categoryGraphList[key + 1].absentList} />}
              </div>
            </div>

          </Col>}
        </Row>}</React.Fragment>
      ))}</React.Fragment>
    )
  }

  const getTableUIGraph = (measureObject) => {
    return (
      <div className="dont-cut-element-when-printing">
        <div className="table-responsive-sm">
          <Table className="table table-bordered table-sm">
            <thead>
              <tr>
                <th style={{ width: "80%" }}>LEGEND</th>
                <th style={{ width: "20%" }}>VALUES</th>
              </tr>
            </thead>
            <tbody>
              {measureObject.measureSkillLabels.map((label, key) => (
                <tr><td>{label}</td><td>{(measureObject.measureSkillDatas && measureObject.measureSkillDatas.length > key) ? measureObject.measureSkillDatas[key] : "0"}</td></tr>
              ))
              }
            </tbody>
          </Table>
        </div>
      </div>
    )
  }

  const getUIForGraphForDifferentIndividualGraph = (isForPrint, isPlainSheet) => {
    let paddingForGraphForScreenOnly = "40px"
    return (
      <React.Fragment>{individualMeasureList.map((measureObject, key) => {
        return (
          <React.Fragment key={key}>{key % 2 === 0 && <Row lg={12} md={12} sm={12} key={key} style={{ breakInside: "avoid" }}>
            <Col lg={6} md={6} sm={isForPrint ? 6 : 12} className="mt-5">
              <div >
                {!isPlainSheet && <h5>{measureObject.measureName}</h5>}
                <div style={isForPrint || isPlainSheet ? { width: "475px", height: "300px" } : { paddingBottom: paddingForGraphForScreenOnly }}>
                  {!isPlainSheet && getChartBasedOnGraphType(measureObject)}
                </div>
              </div>
            </Col>
            {individualMeasureList.length > (key + 1) && <Col lg={6} md={6} sm={isForPrint ? 6 : 12} className="mt-5">
              <div>
                {isPlainSheet && <h5>.</h5>}
                {!isPlainSheet && <h5 >{individualMeasureList[key + 1].measureName}</h5>}
                <div style={isForPrint || isPlainSheet ? { width: "475px", height: "300px" } : { paddingBottom: paddingForGraphForScreenOnly }}>
                  {!isPlainSheet && getChartBasedOnGraphType(individualMeasureList[key + 1])}
                </div>
              </div>
            </Col>}
          </Row>}</React.Fragment>
        )
      })}</React.Fragment>
    )
  }
  const getUIForSESGraphType = (isForPrint, isPlainSheet) => {
    //SocioEmotionalSkillsType - SES
    let paddingForGraphForScreenOnly = "30px"
    return (
      <React.Fragment>
        <div style={{ height: "40px" }}></div>
        <div className="dont-cut-element-when-printing">
          <h4 className="mb-3">Socio Emotional Skills</h4>
          <React.Fragment>
            <Row lg={12} md={12} sm={12} style={{ breakInside: "avoid" }}>
              {sesMeasureList.map((measureObject, key) => {
                return (
                  <Col key={key} lg={4} md={4} sm={isForPrint ? 4 : 12} className="mt-0">
                    <div >
                      {!isPlainSheet && <h5>{measureObject.measureName}</h5>}
                      <div style={isForPrint ? {} : { paddingBottom: paddingForGraphForScreenOnly }}>
                        {!isPlainSheet && getTableUIGraph(measureObject)}
                      </div>
                    </div>
                  </Col>
                )
              })}
            </Row>
          </React.Fragment>
        </div>
      </React.Fragment>
    )
  }

  const getChartBasedOnGraphType = (measure) => {
    //PieChart
    if (measure.graphType === Constants.GRAPHTYPE_PIE) {
      return (<PieChart measureName={measure.measureName} measureSkillDatas={measure.measureSkillDatas} measureSkillLabels={measure.measureSkillLabels} />)
    }
    return (
      <RadarChart measureName={measure.measureName} measureSkillDatas={measure.measureSkillDatas} measureSkillLabels={measure.measureSkillLabels} />
    )
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={showLegendModal}
        toggle={() => setShowLegendModal(!showLegendModal)}
        scrollable={true}
        className='d-print-none'
      >
        {selectedCategoryForLegend && <ModalBody>
          {selectedCategoryForLegend.images.map((image, key) => (
            <img src={image} width="100%px" height="" alt="image" />
          ))}
        </ModalBody>
        }
        <ModalFooter>
          <Button color="secondary" onClick={() => { setShowLegendModal(false) }}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
      <div>
        {!isLoading && <center>
          <div className="d-print-none mt-2">
            <Button
              onClick={() => {
                if (['Windows', 'Mac'].includes(machineType)) {
                  if(!dontShowAgain){
                    setShowSaveAsPdfModal(true);
                  }else{
                    printPoster();
                  }
                }else{
                  printPoster();
                }
              }}
              className="btn btn-success waves-effect waves-light m-2"
            >
              <i className="fa fa-print mr-2"></i>Print
            </Button>
            <SaveAsPdf
                showModal={showSaveAsPdfModal}
                toggleModal={() => setShowSaveAsPdfModal(false)}
                dontShowAgain={dontShowAgain}
                setDontShowAgain={setDontShowAgain}
                onNextClick={handleNextClick}
                machineType={machineType}
            />
            <div style={{ width: "200px", position: "absolute", right: 0, top: 10 }} className="pr-4">
              Select Academic Year :
              <select
                className="custom-select mb-2"
                id={"selectYear"}
                value={selectedYear}
                onChange={(e) => {
                  setSelectedYear(parseInt(e.target.value, 10));
                }}
              >
                {
                  pastYearsList.map((year, key) => (
                    <option key={key}
                      value={"" + year}
                    >
                      {pastYearDisplayList[key]}
                    </option>
                  ))
                }
              </select></div>
          </div>
          <Row className="mt-2">
            <div
              className="d-flex mt-0 mx-auto pb-2"
              style={{
                maxWidth: "60%",
                borderBottom: `2px solid LightGray`,
              }}
            >
              <div className="mt-0 py-4">
                <img
                  src={schoolLogo}
                  width="70px"
                  height="70px"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
              <div style={{ width: "1450px", position: "absolute", right: 0, top:60 }} className="pr-4">
              <h1
                className="float-right my-auto ml-3"
                style={{
                  fontSize: "18px",
                }}
              >
                {schoolObject && schoolObject.attributes.Name}
              </h1>
              </div>
            </div>
          </Row>

          <Row className="mt-3">

            <div
              className="d-flex mt-0 mx-auto pb-2"
              style={{
                maxWidth: "60%",
                borderBottom: `2px solid LightGray`,
              }}
            >
              <div className="mt-0">
                <img
                  src={kidPhoto}
                  width="70px"
                  height="70px"
                  alt=""
                  style={{ objectFit: "contain" }}
                />
              </div>
              <Col className="ml-3">
                <Row><h5 className="">
                  {kidObject && kidObject.attributes.Name}
                </h5></Row>
                <Row><h5 >
                  {studentClassroom}
                </h5></Row>
                <Row><h6 >
                  Date of birth : {kidObject && kidObject.attributes.DateOfBirth ? convertDateToStringForDOB(kidObject.attributes.DateOfBirth) : ""}
                </h6></Row>
              </Col>
            </div>
          </Row>
          <h4 className="mt-2 mb-3">Learning Analytics</h4>


          <Alert style={{ backgroundColor: "rgba(52, 195, 143, 0.2)", textAlign: "left", fontSize: "16px" }} className="m-4" >
            {`A walkthrough of what developmental milestones our ${kidObject.attributes.Name} has achieved in the AY ${getAcademicYear(selectedYear)}. This is a documentation of observations made by our facilitators at Diversity.  The observations include, but not limited to, a wide variety of approaches, methods, and tools that our facilitators use to discover, distinguish, measure, and document his/her growing concepts including academic readiness, social & emotional well-being, learning progression, skills development & acquisition, awareness & connection building, and all such education and learning needs of your child. This transcript must NOT be confused with evaluations or tests or final judgment, or any exam results from the educational institution or teaching professionals, instead our parents must celebrate it as an on-going process of finding the next level of learning milestones and exploring newer possibilities in their child’s life. This transcript should be treated as a personalized tracker that follows and records the growth of developmental milestones and skills in your child in different subjects and skills covered by the transcript on a time scale.`}
          </Alert>

          <div style={{ position: "relative" }}>

            <div
              style={{
                width: "100%",
                minHeight: "1403px",
                backgroundColor: "#fff",
                // border: `10px solid LightGray`,
              }}
              className="text-center pl-2"
            >
              {getUIForGraphForDifferentIndividualGraph(true)}
              {getUIForGraph(true)}
              {getUIForSESGraphType(true)}
            </div>
            <div
              style={{
                width: "100%",
                minHeight: "1403px",
                backgroundColor: "#fff",
                position: "absolute",
                top: 0,
                left: 0
                // border: `10px solid LightGray`,
              }}
              className="text-center d-print-none"
            >

              {getUIForGraphForDifferentIndividualGraph(true, true)}
              {getUIForGraph(true, true)}
              {getUIForSESGraphType(true, true)}
            </div>
            <div
              style={{
                width: "100%",
                minHeight: "1403px",
                backgroundColor: "#fff",
                position: "absolute",
                top: 0,
                left: 0

                // border: `10px solid LightGray`,
              }}
              className="text-center d-print-none pl-3 pr-3"
            >
              {getUIForGraphForDifferentIndividualGraph(false)}
              {getUIForGraph(false)}
              {getUIForSESGraphType(false)}
            </div>
          </div>
        </center>}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Analytics...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment >
  );
};


const mapStatetoProps = (state) => {
  const { selectedSchool, schools } = state.School;
  const { classrooms } = state.Classroom;
  const { deletedTeachersForMonth } = state.Teacher;
  return { selectedSchool, classrooms, schools, deletedTeachersForMonth };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getClassroomsForSchoolId,
      getDeletedTeachersForMonth,
      updateDeletedTeachersForMonth

    })(LearningAnalytics)
  )
);
