import { takeEvery, fork, put, all, call } from 'redux-saga/effects';

//Account Redux states
import { REGISTER_USER } from './actionTypes';
import { registerUserSuccessful, registerUserFailed,setRegisterLoading } from './actions';

import { registerUserParse } from '../../../helpers/ParseHelpers/login_helper';



// Is user register successfull then direct plot user in redux.
function* registerUser({ payload: { user ,appName} }) {
    try {
    	if(process.env.REACT_APP_DEFAULTAUTH === "parse")
        {
            yield put(setRegisterLoading(true))
            const response = yield call(registerUserParse, user.email, user.password,appName);
            if (response.responce === "success") {
                yield put(registerUserSuccessful(response.user));
            } else {
                yield put(registerUserFailed(response));
            }
           
    	} 
        
    } catch (error) {
        yield put(registerUserFailed(error));
    }
}

export function* watchUserRegister() {
    yield takeEvery(REGISTER_USER, registerUser);
}

function* accountSaga() {
    yield all([fork(watchUserRegister)]);
}

export default accountSaga;