import * as actions from "./actionTypes"


export const getInventoryWarehouseForOwnerId = (ownerId, ownerType) => {
    return {
      type: actions.GET_INVENTORY_WAREHOUSE_FOR_OWNER_ID,
      payload: { ownerId, ownerType },
    };
  };

  export const updateInventoryWarehouses = (inventoryWarehouses) => {
    return {
      type: actions.UPDATE_INVENTORY_WAREHOUSES,
      payload: { inventoryWarehouses },
    };
  };

  export const getInventoryStockForWarehouse = (warehouseId) => {
    return {
      type: actions.GET_INVENTORY_STOCK_FOR_WAREHOUSES,
      payload: { warehouseId },
    };
  };

  export const updateInventoryStockForWarehouse = (inventoryItemStockMap) => {
    return {
      type: actions.UPDATE_INVENTORY_STOCK_FOR_WAREHOUSES,
      payload: { inventoryItemStockMap },
    };
  };