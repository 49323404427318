import * as actions from "./actionTypes";




export const updateFeeTemplateItems = (feeTemplateItems) => {
  return {
    type: actions.UPDATE_FEE_TEMPLATE_ITEMS,
    payload: { feeTemplateItems },
  };
};


export const getFeeTemplateItemsForTemplateId = (templateId) => {
  return {
    type: actions.GET_FEE_TEMPLATE_ITEMS,
    payload: { templateId },
  };
};

export const getFeeTemplateItemsForInvoiceIds = templateIds =>{
  return{
    type : actions.GET_FEETEMPLATE_ITEMS_FOR_TEMPLATE_IDS,
    payload : { templateIds }
  }
}

