export const LOGIN_USER = 'LOGIN_USER';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const UPDATE_USER = 'UPDATE_USER';
export const SET_LOGIN_LOADING = 'SET_LOGIN_LOADING';

export const LOGOUT_USER = 'LOGOUT_USER';
export const LOGOUT_USER_SUCCESS = 'LOGOUT_USER_SUCCESS';
export const API_ERROR = 'LOGIN_API_ERROR';
export const UPDATE_USER_TEACHER = "UPDATE_USER_TEACHER";
export const GET_USER_TEACHER = "GET_USER_TEACHER";

export const VERIFY_USER_ERROR = "VERIFY_USER_ERROR";

