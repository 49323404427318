import * as actions from "./actionTypes";

export const getCorporatePartners = (id,type) => {
  return {
    type: actions.GET_CORPORATE_PARTNERS,
    payload: { id,type }
  }
}

export const updateCorporatePartners = (corporatePartners) => {
  return {
    type: actions.UPDATE_CORPORATE_PARTNERS,
    payload: { corporatePartners }
  }
}

export const addCorporatePartner = (corporatePartner,type) => {
  return {
    type: actions.ADD_CORPORATE_PARTNERS,
    payload: { corporatePartner,type}
  }
}
export const deleteCorporatePartner = (corporatePartner) => {
  return {
    type: actions.DELETE_CORPORATE_PARTNERS,
    payload: { corporatePartner}
  }
}


export const setCorporatePartnersLoading = (state) => {
  return {
    type: actions.ADD_CORPORATE_PARTNERS_LOADING,
    payload: { state }
  }
}

export const getKidsForCorporatePartners = (corporateId) => {
  return {
    type: actions.GET_KIDS_FOR_CORPORATE_PARTNERS,
    payload: { corporateId }
  }
}

export const updateKidsForCorporatePartners = (kidObj) => {
  return {
    type: actions.UPDATE_KIDS_FOR_CORPORATE_PARTNERS,
    payload: { kidObj }
  }
}

export const setSelectedCorporatePartner = (corporatePartnerId) => {
  return {
      type: actions.SET_SELECTED_CORPORATE_PARTNER,
      payload: { corporatePartnerId }
  }
}

export const updateSelectedCorporatePartner  = (corporatePartner) => {
  return {
    type: actions.UPDATE_SELECTED_CORPORATE_PARTNER,
    payload: { corporatePartner },
  };
};