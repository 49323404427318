import { takeEvery, takeLeading, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as ExpensesHelper from "../../../helpers/ParseHelpers/expenseManagement_helper";
import * as actionTypes from "./actionTypes";
import * as ExpensesActions from "./actions";
import * as Constants from '../../../Constents';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import actions from "redux-form/lib/actions";

function* GetExpensesForSchoolIdSaga({ payload: { schoolId, isInstitute } }) {
  try {
    var key = Constants.EXPENSE_LIST_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(ExpensesHelper.getExpensesForSchoolId, schoolId, isInstitute, null, true);
    if (localResponse && localResponse.length > 0) {
      yield put(ExpensesActions.updateExpenses(localResponse));
    }

    const serverResponse = yield call(ExpensesHelper.getExpensesForSchoolId, schoolId, isInstitute, updatedTime, false);

    localStorage.setItem(key, newTime)
    yield put(ExpensesActions.updateExpenses(serverResponse));
    if (serverResponse && serverResponse.length > 0) {
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
  }
}


function* editExpense({ payload: { expense, isInstitute } }) {
  try {
    const response = yield call(
      ExpensesHelper.editExpense,
      expense, isInstitute
    );

    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    } else
      if (response) {
        yield put(ExpensesActions.updateExpense(response));
        yield put(ExpensesActions.updateExpenseResult(response));
        response.pin();
        //toastr.success("Category successfully updated", { timeOut: 3000 });
      } else {
      }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getExpensesForDate({ payload: { schoolId, fromDate, toDate } }) {
  try {
    const response = yield call(ExpensesHelper.getExpensesForDate, schoolId, fromDate, toDate);
    if (response) {
      yield put(ExpensesActions.clearExpenses([]));
      yield put(ExpensesActions.updateExpenses(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getExpenseWalletForSchoolId({ payload: { schoolId } }) {
  try {
    const response = yield call(ExpensesHelper.getExpenseWalletForSchoolId, schoolId);
    if (response) {
      yield put(ExpensesActions.updateExpenseWallet(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getExpenseWalletLogForSchoolId({ payload: { schoolId } }) {
  try {
    const response = yield call(ExpensesHelper.getExpenseWalletLogForSchoolId, schoolId);
    if (response) {
      yield put(ExpensesActions.updateExpenseWalletLog(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* addExpenseWallet({ payload: { schoolId, amount, teacherId } }) {
  try {
    const response = yield call(ExpensesHelper.addExpenseWallet, schoolId, amount, teacherId);
    if (response) {
      yield put(ExpensesActions.updateExpenseWallet(response.ExpenseWallet))
      toastr.info("Current wallet balance: " + response.ExpenseWallet.attributes.balance, { timeOut: 5000 });
      toastr.success("Amounr added to wallet successfully ", { timeOut: 2000 });
      yield put(ExpensesActions.updateExpenseWalletLog([response.ExpenseWalletLog]))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getExpenseWalletLogForDate({ payload: { schoolId, fromDate, toDate } }) {
  try {
    const response = yield call(ExpensesHelper.getExpenseWalletLogForDate, schoolId, fromDate, toDate );
    if (response) {
      yield put(ExpensesActions.clearExpenseWalletLog([]));
      yield put(ExpensesActions.updateExpenseWalletLog(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetExpensesForSchoolId() {
  yield takeEvery(actionTypes.GET_EXPENSES_FOR_SCHOOL_ID, GetExpensesForSchoolIdSaga);
}


export function* watchEditExpense() {
  yield takeEvery(actionTypes.EDIT_EXPENSE, editExpense);
}

export function* watchGetExpensesForDate() {
  yield takeEvery(actionTypes.GET_EXPENSES_FOR_DATE, getExpensesForDate);
}


export function* watchGetExpenseWalletForSchoolId() {
  yield takeEvery(actionTypes.GET_EXPENSE_WALLET_FOR_SCHOOL_ID, getExpenseWalletForSchoolId);
}

export function* watchAddExpenceBalance() {
  yield takeEvery(actionTypes.ADD_EXPENSE_WALLET, addExpenseWallet);
}

export function* watchGetExpenseWalletLogForSchoolId() {
  yield takeEvery(actionTypes.GET_EXPENSE_WALLET_LOG_FOR_SCHOOL_ID, getExpenseWalletLogForSchoolId);
}

export function* watchGetExpenseWalletLogForDate() {
  yield takeEvery(actionTypes.GET_EXPENSE_WALLET_LOG_FOR_DATE, getExpenseWalletLogForDate);
}


function* ExpensesSaga() {
  yield all([

    fork(watchGetExpensesForSchoolId),
    fork(watchEditExpense),
    fork(watchGetExpensesForDate),
    fork(watchGetExpenseWalletForSchoolId),
    fork(watchAddExpenceBalance),
    fork(watchGetExpenseWalletLogForSchoolId),
    fork(watchGetExpenseWalletLogForDate),
  ]);
}
export default ExpensesSaga;
