export const UPDATE_INVOICE_ITEM = "UPDATE_INVOICE_ITEM";
export const DELETE_INVOICE_ITEM = "DELETE_INVOICE_ITEM";

export const UPDATE_INVOICE_ITEMS = "UPDATE_INVOICE_ITEMS";
export const GET_INVOICE_ITEMS = "GET_INVOICE_ITEMS";
export const GET_INVOICE_ITEM = "GET_INVOICE_ITEM";

export const INVOICE_ITEM_SUCCESS = "INVOICE_ITEM_SUCCESS";
export const INVOICE_ITEM_ERROR = "INVOICE_ITEM_ERROR";

export const GET_ITEMS_FOR_INVOICE_IDS = "GET_ITEMS_FOR_INVOICE_IDS";
export const SET_ITEMS_FOR_INVOICE_IDS = "SET_ITEMS_FOR_INVOICE_IDS";
