import React, { useState, useEffect } from "react";
import { matchPath } from "react-router";
import { Container, Row, Col, Label, Card, Form, CardBody, Table } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { withRouter, Link } from "react-router-dom";
import SweetAlert from "react-bootstrap-sweetalert";
import { Parse } from 'parse';
import {
  getTeachersForSchool,
  getClassroomsForSchoolId,
  getAllKidsForGroup, saveGroup,
  saveGroupMaps,
  getGroupMapsForGroupId,
  updateResultGroupMaps,
  getGroupForId,
  setSelecetdSchool,
  getParentsForFamilyIds,
  getGroupsForSchoolId,
  deleteGroup,
  setDeletedSuccessfully,
} from '../../store/actions';
import * as Constants from '../../Constents';

const GroupDetails = (props) => {


  const [newGroupName, setNewGroupName] = useState("");
  const [mainList, setMainList] = useState([]);
  const [displayList, setDisplayList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [teacherList, setTeacher] = useState([]);
  const [schoolType, setSchoolType] = useState(1);

  const match = matchPath(props.history.location.pathname, {
    path: "/group-details/:schoolId/:groupId",
    exact: true,
    strict: false,
  });

  const schoolId = match && match.params.schoolId;
  const groupId = match && match.params.groupId;




  useEffect(() => {

    if (props.singleGroupHolder) {
      props.getGroupMapsForGroupId(props.singleGroupHolder.id);
      setNewGroupName(props.singleGroupHolder.attributes.name)
    }


  }, [props.singleGroupHolder])


  useEffect(() => {

    console.log("groups", props.groups)
    console.log("groupMap", props.groupmaps)

    props.getTeachersForSchool(schoolId);
    props.getClassroomsForSchoolId(schoolId)
    props.getAllKidsForGroup(schoolId);

    if (match && schoolId && groupId) {
      props.getGroupForId(groupId)
    }
    else if (match) {
      setIsLoading(false)

    }

    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }

  }, [schoolId, groupId]);

  useEffect(() => {
    props.getTeachersForSchool(schoolId);
  }, [schoolId]);


  useEffect(() => {
    if (props.groupmaps && props.groupmaps.length > 0 && props.kids && props.kids.length > 0) {
      let familyIds = []


      for (var i = 0; i < props.groupmaps.length; i++) {
        if (props.groupmaps[i].attributes.groupId === groupId) {
          let kidObjList = props.kids.filter((kid) =>
            kid.id === props.groupmaps[i].attributes.userId
          )
          if (kidObjList.length > 0) {
            if (kidObjList[0].attributes.FamilyID && kidObjList[0].attributes.FamilyID.length > 0) {
              familyIds.push(kidObjList[0].attributes.FamilyID)
            }
          }
        }
      }
      if (familyIds.length > 0) {
        props.getParentsForFamilyIds(familyIds);
      }
    }
  }, [props.groupmaps, props.kids])

  useEffect(() => {
    if (props.groupmaps && props.groupmaps.length > 0 && props.teachers && props.teachers.length > 0) {
      let teacherList = []


      for (var i = 0; i < props.groupmaps.length; i++) {
        if (props.groupmaps[i].attributes.groupId === groupId && props.groupmaps[i].attributes.userType == 2) {
          let teacherObjList = props.teachers.filter((teacher) =>
            teacher.id === props.groupmaps[i].attributes.userId
          )
          if (teacherObjList.length > 0) {
            teacherList.push(teacherObjList[0])
          }
        }
      }
      setTeacher(teacherList)
    }
  }, [props.groupmaps, props.teachers])

  useEffect(() => {
    if (props.groupmaps && props.groupmaps.length > 0 && props.kids) {
      let tempList = []
      let tempSelectedKidList = []

      let tempKidList = []
      let familyIds = []

      tempKidList.push.apply(tempKidList, props.kids)
      for (var i = 0; i < props.groupmaps.length; i++) {
        if (props.groupmaps[i].attributes.groupId === groupId && props.groupmaps[i].attributes.userType == 1) {
          let kidObjList = tempKidList.filter((kid) =>
            kid.id === props.groupmaps[i].attributes.userId
          )
          if (kidObjList.length > 0) {
            let tempObject = {}
            tempObject.kidName = kidObjList[0].attributes.Name
            tempObject.kidId = kidObjList[0].id
            tempObject.kidImage = kidObjList[0].attributes.Photo ? kidObjList[0].attributes.Photo._url : undefined
            tempObject.kidParseObject = kidObjList[0]
            tempObject.groupmapId = props.groupmaps[i].id
            if (kidObjList[0].attributes.FamilyID && kidObjList[0].attributes.FamilyID.length > 0) {
              familyIds.push(kidObjList[0].attributes.FamilyID)
            }


            let classDec = []
            for (let i in props.classrooms) {
              classDec[props.classrooms[i].id] = props.classrooms[i]
            }
            let primaryClass = classDec[kidObjList[0].attributes.ClassRoomID]
            tempObject.className = primaryClass ? primaryClass.attributes.Name : ""

            tempList.push(tempObject)
            tempSelectedKidList.push(kidObjList[0])
          }
        }
      }
      setMainList(tempList)

    }
  }, [props.groupmaps, props.kids, props.classrooms]);

  useEffect(() => {
    if (props.parents) {
      let tempList = [...mainList]

      for (const tempObject of tempList) {
        let kidObjList = [tempObject.kidParseObject]
        for (var i = 0; i < props.parents.length; i++) {
          if (
            props.parents[i].attributes.FamilyID ===
            kidObjList[0].attributes.FamilyID
          ) {
            if (props.parents[i].attributes.Relation &&
              props.parents[i].attributes.Relation.toLowerCase() ===
              "father"
            ) {
              tempObject.fatherName =
                props.parents[i].attributes.Name;
              tempObject.fatherMobile =
                props.parents[i].attributes.PhoneNumber;
            } else {
              tempObject.motherName =
                props.parents[i].attributes.Name;
              tempObject.motherMobile =
                props.parents[i].attributes.PhoneNumber;
            }
          }
        }
      }
      setMainList(tempList)
    }
  }, [props.parents])

  function getDesignation(teacher) {
    if (teacher.designation) {
      return teacher.designation;
    } else if (teacher.Role === 3) {
      return props.t('Director');
    } else if (teacher.Role === 2) {
      return props.t('Center Head');
    } else {
      return props.t('Teacher');
    }
  }

  // useEffect(() => {
  //   props.getGroupsForSchoolId(schoolId)
  // }, [schoolId]);

  useEffect(() => {
    if (props.deletedSuccessfully) {
      props.setDeletedSuccessfully(false)
      window.history.back()
    }
  }, [props.deletedSuccessfully]);

  const handleDeleteGroup = () => {
    if (props.singleGroupHolder != null) {
      var temp = props.singleGroupHolder
      temp.set("deleted", true)
      props.deleteGroup(temp)
      // setShowDeleteAlert(false)
    }
  }

  const getTableView = (listToDisplay) => {
    return (
      <div className="table-responsive mt-4" >
        <table className="table table-centered table-nowrap mb-0">
          <thead className="thead-light">
            <tr>
              <th>{props.t("Sl.No")}</th>
              <th>{props.t("Student's Name")}</th>
              <th>{props.t("Father's Name")}</th>
              <th>{props.t("Father's Contact")}</th>
              <th>{props.t("Mother's Name")}</th>
              <th>{props.t("Mother's Contact")}</th>
            </tr>
          </thead>
          <tbody>
            {listToDisplay && listToDisplay.map((detail, key) => (
              <tr key={key}>
                <td>{key+1}</td>
                <td>
                  <h5 className="font-size-14 mb-1">
                    <Label className="text-dark">
                      {" "}
                      {detail.kidName}
                    </Label>
                  </h5>
                </td>
                <td>
                  <h5 className="font-size-14 mb-1">
                    <Label className="text-dark">
                      {" "}
                      {detail.fatherName}
                    </Label>
                  </h5>
                </td>
                <td>
                  <h5 className="font-size-14 mb-1">
                    <Label className="text-dark">
                      {" "}
                      {detail.fatherMobile}
                    </Label>
                  </h5>
                </td>
                <td>
                  <h5 className="font-size-14 mb-1">
                    <Label className="text-dark">
                      {" "}
                      {detail.motherName}
                    </Label>
                  </h5>
                </td>
                <td>
                  <h5 className="font-size-14 mb-1">
                    <Label className="text-dark">
                      {" "}
                      {detail.motherMobile}
                    </Label>
                  </h5>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div >
    )
  }



  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* <Row>
            <Col lg={8} md={8} xs={12}>
              <Row >
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}
                >
                  <ul className="ml-2 list-unstyled">
                    <div >
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <h4 className="mt-2">{newGroupName}</h4>
              </Row>
            </Col>
            <Col lg={4} md={4} xs={12}>
              {<div className="mb-3  float-sm-right form-inline">
                <button
                  className="btn btn-primary waves-effect waves-light mr-2"
                  onClick={() => {
                    props.history.push("/editgroup/" + schoolId + "/" + groupId)
                  }}
                >
                  Edit Group
                </button>
                <button
                  onClick={() => {

                    setShowDeleteAlert(true)
                  }}
                  className="btn btn-danger waves-effect waves-light"
                >
                  {props.t("Delete")}
                </button>

              </div>}
            </Col>
          </Row> */}
          <Row className='d-flex align-items-start'>
            <Col className="d-flex align-items-start">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => { window.history.back() }}>
                <ul className=" list-unstyled">
                  <div className=''>
                    <i className="bx bx-chevron-left h1 text-primary"></i>
                  </div>
                </ul>
              </div>
              <div className=' m-2'>
                <h4>{newGroupName}</h4>
              </div>
            </Col>
            <Col className='d-flex justify-content-end'>
              <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                <button
                  className="btn btn-primary waves-effect waves-light m-1"
                  onClick={() => {
                    props.history.push("/editgroup/" + schoolId + "/" + groupId)
                  }}
                >
                  Edit Group
                </button>
                <button
                  onClick={() => {

                    setShowDeleteAlert(true)
                  }}
                  className="btn btn-danger waves-effect waves-light m-1"
                >
                  {props.t("Delete")}
                </button>
              </div>
            </Col>
          </Row>
          <Card>
            <CardBody>
              <div className="table-responsive mt-4" >

                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>
                      <th>{props.t("Teacher's Name")}</th>
                      <th>{props.t("Teacher's Designation")}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      teacherList.map((detail) => {
                        return (
                          <tr key={detail.id}>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Label className="text-dark">
                                  {" "}
                                  {detail.attributes.Name}
                                </Label>
                              </h5>
                            </td>
                            <td>
                              <h5 className="font-size-14 mb-1">
                                <Label className="text-dark">
                                  {" "}
                                  {getDesignation(detail.attributes)}
                                </Label>
                              </h5>
                            </td>
                          </tr>
                        )
                      })}
                  </tbody>
                </table>
              </div>
            </CardBody>
          </Card>
          <Card>
            {showDeleteAlert && <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes"
              cancelBtnText="No"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="success"
              title="Alert!"
              onCancel={() => {
                setShowDeleteAlert(false);
              }}
              onConfirm={() => handleDeleteGroup()}
            >
              Are you sure to delete this group?<br></br>
            </SweetAlert>}
            <CardBody>
              {mainList && mainList.length > 0 ? getTableView(mainList) :
                <div className="text-center mt-0">
                  <h5 className="p-5">No Students in this Group !!</h5>
                </div>}
            </CardBody>
          </Card>
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = state => {
  const { teachers } = state.Teacher;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  const { resultGroup, groups, singleGroupHolder, deletedSuccessfully } = state.Group;
  const { groupmaps, resultGroupMapsAfterSave } = state.GroupMap;
  const { selectedSchool } = state.School;
  const { parents } = state.Parent;

  return { groupmaps, groups, teachers, classrooms, kids, resultGroup, deletedSuccessfully, resultGroupMapsAfterSave, singleGroupHolder, selectedSchool, parents };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getTeachersForSchool, getClassroomsForSchoolId, getAllKidsForGroup, saveGroup, saveGroupMaps, getGroupMapsForGroupId, getGroupForId, setSelecetdSchool, getParentsForFamilyIds, updateResultGroupMaps, getGroupsForSchoolId, deleteGroup, setDeletedSuccessfully, })(GroupDetails)));
