import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Table,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  Card,
  CardBody,
  CardTitle,
} from "reactstrap";
import Moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
import {
  getInvoicesForFilters,
  getVerifiedStatusForInvoices,
  deleteSelectedInvoice,
  getUserTeacher,
  setDeleteSuccess,
  notifyPaymentToParent
} from "../../store/actions";
import { Link } from "react-router-dom";
import ViewTriansaction from "../payment/viewTriansaction";
import ReceivePayment from "../payment/receivePayment";
import ViewInvoice from "../payment/viewInvoice";
import Parse from "parse";

const FeeTab = (props) => {
  const [showLoader, showLoaderOps] = useState(false);
  const [invoices, invoicesOps] = useState([]);
  const [activeTriansaction, activeTriansactionOps] = useState("");
  const [togTriansaction, togTriansactionOps] = useState(false);
  const [activeInvoiceView, activeInvoiceViewOps] = useState("");
  const [activeInvoiceId, activeInvoiceIdOps] = useState("");
  const [togInvoice, togInvoiceOps] = useState(false);
  const [receivePaymentForObj, receivePaymentForObjOps] = useState({});
  const [receivePaymentModal, receivePaymentModalOps] = useState(false);
  const [deleteInvoiceId, deleteInvoiceIdOps] = useState("");
  const [showConfirmDelete, showConfirmDeleteOps] = useState(false);
  const list = ["2022-23", "2021-22", "2020-21", "2019-20", "2018-19", "2017-18"];
  const [teacherPaymenrWrite, teacherPaymenrWriteOps] = useState(false);
  const [teacherPaymenrRead, teacherPaymenrReadOps] = useState(false);
  const [paymentAdmin, paymentAdminOps] = useState(false);
  useEffect(() => {
    if (props.kid) {
      let filters = {};
      filters.containedIn = false;
      filters.attributes = "recipientId";
      filters.value = props.kid.id;
      filters.limit = 1000;
      filters.skipValue = 1;
      props.getInvoicesForFilters(filters, props.schoolId);
      props.getUserTeacher(Parse.User.current().attributes.teacherId);
    }
  }, [props.kid, props.schoolId]);
  useEffect(() => {
    if (props.userTeacher) {
      if (props.userTeacher.attributes &&
        props.userTeacher.attributes.featurePermissions.length > 3 &&
        props.userTeacher.attributes.featurePermissions[3] === 2) {
        teacherPaymenrWriteOps(true);
        teacherPaymenrReadOps(true);
      }
      if (props.userTeacher.attributes &&
        props.userTeacher.attributes.featurePermissions.length > 3 &&
        props.userTeacher.attributes.featurePermissions[3] === 1) {
        teacherPaymenrWriteOps(false);
        teacherPaymenrReadOps(true);
      }
      if (props.userTeacher.attributes &&
        props.userTeacher.attributes.featurePermissions.length > 11 &&
        props.userTeacher.attributes.featurePermissions[11] === 1) {
        paymentAdminOps(true);
      }
      if (Parse.User.current().attributes.isSuperUser) {
        paymentAdminOps(true);
        teacherPaymenrReadOps(true);
        teacherPaymenrWriteOps(true);
      }
    }
  }, [props.userTeacher]);
  useEffect(() => {
    let invoicesIds = [];
    let tempInvoice = [];
    for (const val of props.invoices) {
      let temp = {};
      temp.Obj = val;
      temp.id = val.id;
      invoicesIds.push(val.id);
      temp.invoiceNumber = val.attributes.invoiceNumber;
      temp.date = val.attributes.createdAt
        ? Moment(val.attributes.createdAt).format("DD-MMM-YYYY")
        : "--";
      temp.invoiceTitle = val.attributes.title;
      temp.status = {};
      temp.paidStatus = val.attributes.paidStatus
        ? val.attributes.paidStatus
        : 0;
      if (val.attributes.paidStatus === 1) {
        temp.status.stat = "Paid";
        temp.status.color = "success m-0 p-0";
      } else if (val.attributes.paidStatus === 3) {
        temp.status.stat = "Partially Paid";
        temp.status.color = "warning m-0 p-0";
      } else {
        temp.status.stat = "Pending";
        temp.status.color = "danger m-0 p-0";
      }
      if (val.attributes.tags) {
        temp.tag = val.attributes.tags[0] ? val.attributes.tags[0] : "";
      }
      temp.totalAmount = val.attributes.amount ? val.attributes.amount : "0";
      temp.paidAmount = val.attributes.paidAmount
        ? val.attributes.paidAmount
        : 0;
      temp.balanceAmount = +temp.totalAmount - temp.paidAmount;
      temp.isActionActive = false;
      temp.showPendingVerify = false;
      tempInvoice.push(temp);
    }
    invoicesOps(tempInvoice);
    if (invoicesIds.length > 0) {
      props.getVerifiedStatusForInvoices(invoicesIds);
    }
  }, [props.invoices]);
  useEffect(() => {
    showLoaderOps(props.showLoader);
  }, [props.showLoader]);
  useEffect(() => {
    if (props.pendingVerifyMT) {
      let tempInvoice = Object.assign([], invoices);
      for (const val of tempInvoice) {
        for (const val1 of props.pendingVerifyMT) {
          if (val1.attributes.invoiceId === val.id) {
            val.showPendingVerify = true;
          }
        }
      }
      invoicesOps(tempInvoice);
    }
  }, [props.pendingVerifyMT]);
  const tog_triansaction = (id) => {
    if (id) {
      activeTriansactionOps(id);
    } else {
      activeTriansactionOps("");
    }
    if (togTriansaction) {
      getAllInvoices();
    }
    togTriansactionOps(!togTriansaction);
  };
  const tog_invoice = (id) => {
    if (id) {
      activeInvoiceViewOps(id);
      activeInvoiceIdOps(id);
    } else {
      activeInvoiceViewOps("");
      activeInvoiceIdOps("");
    }
    if (!togInvoice) {
      getAllInvoices();
    }
    togInvoiceOps(!togInvoice);
  };
  const getAllInvoices = () => {
    let filters = {};
    filters.containedIn = false;
    filters.attributes = "recipientId";
    filters.value = props.kid.id;
    filters.limit = 1000;
    filters.skipValue = 1;
    props.getInvoicesForFilters(filters, props.schoolId);
  };
  const activeDropDown = (id) => {
    let tempVals = Object.assign([], invoices);
    for (let val of tempVals) {
      if (val.id === id) {
        val.isActionActive = !val.isActionActive;
      } else {
        val.isActionActive = false;
      }
    }
    invoicesOps(tempVals);
  };
  const tog_receivePayment = (invoice, setEmpty) => {
    if (invoice) {
      receivePaymentForObjOps(invoice);
    } else {
      if (setEmpty) {
        receivePaymentForObjOps({});
      }
    }
    receivePaymentModalOps(!receivePaymentModal);
  };
  const confirmDelete = (id) => {
    deleteInvoiceIdOps(id);
    showConfirmDeleteOps(true);
  };
  const getInvoice = (id) => {
    for (const val of invoices) {
      if (val.id === id) {
        return val;
      }
    }
    return {};
  };
  useEffect(() => {
    if (props.deleteSuccess) {
      showConfirmDeleteOps(false);
      deleteInvoiceIdOps("");
      props.setDeleteSuccess(false)
    }
  }, [props.deleteSuccess]);




  const handelDeleteInvoice = () => {
    props.deleteSelectedInvoice(deleteInvoiceId, false, true);
    showConfirmDeleteOps(false)
  };
  return (
    <React.Fragment>
      {showConfirmDelete && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            showConfirmDeleteOps(false);
          }}
          onConfirm={() => handelDeleteInvoice()}
        >
          {props.t("Are you sure you want to delete invoice")} <br></br>
        </SweetAlert>
      )}
      <ReceivePayment
        receivePaymentModal={receivePaymentModal}
        tog_receivePayment={tog_receivePayment}
        activeInvoice={receivePaymentForObj}
        currency={props.currency}
        activeInvoiceId={receivePaymentForObj.id}
        getAllInvoices={getAllInvoices}
        teacherPaymenrWrite={teacherPaymenrWrite}
        paymentAdmin={paymentAdmin}
        schoolId={props.schoolId}
        school={props.school}
      />
      <ViewInvoice
        invoice={getInvoice(activeInvoiceId)}
        togInvoice={togInvoice}
        tog_invoice={tog_invoice}
        activeInvoiceView={activeInvoiceView}
        yearList={list}
        getAllInvoices={getAllInvoices}
        teacherPaymenrWrite={teacherPaymenrWrite}
        currency={props.currency}
        showRoyalty={props.showRoyalty}
        completeDisable={props.completeDisable}
      />
      <ViewTriansaction
        invoice={getInvoice(activeTriansaction)}
        currency={props.currency}
        activeInvoiceId={activeTriansaction}
        togTriansaction={togTriansaction}
        teacherPaymenrWrite={teacherPaymenrWrite}
        tog_triansaction={tog_triansaction}
        paymentAdmin={paymentAdmin}
      />

      {teacherPaymenrRead?<Table className="table mb-0 table-bordered">
        <thead>
          <tr>
            <th>{props.t("Invoice Detail")}</th>
            <th>{props.t("Date")}</th>
            <th>{props.t("Status")}</th>
            <th>
              {props.t("Payable")}({" "}
              <span style={{ fontFamily: "Arial, sans-serif" }}>
                {props.currency}
              </span>
              )
            </th>
            <th>
              {props.t("Received")}({" "}
              <span style={{ fontFamily: "Arial, sans-serif" }}>
                {props.currency}
              </span>
              )
            </th>
            <th>
              {props.t("Balance")}({" "}
              <span style={{ fontFamily: "Arial, sans-serif" }}>
                {props.currency}
              </span>
              )
            </th>
            <th>{props.t("Action")}</th>
          </tr>
        </thead>
        <tbody>
          {showLoader && (
            <tr>
              <td colSpan="10" className="pt-4 text-center">
                <Link to="#" className="text-success">
                  <i className="bx bx-hourglass bx-spin mr-2"></i> Loading...{" "}
                </Link>
              </td>
            </tr>
          )}
          {invoices.length === 0 && !showLoader && (
            <tr>
              <td colSpan="10" className="pt-4">
                <h3 className="text-center text-info">
                  {props.t("No invoices found.")}
                </h3>
              </td>
            </tr>
          )}
          {invoices.map((val, key) => (
            <tr key={key}>
              {/* <td></td> */}
              <td>
                {val.invoiceTitle}
                <br />
                {val.invoiceNumber}
              </td>
              <td>{val.date}</td>
              <td>
                <p className={`text-${val.status.color}`}>{val.status.stat}</p>
                {val.showPendingVerify && (
                  <p
                    className="text-danger m-o p-0"
                    style={{ fontSize: "10px", cursor: "pointer" }}
                    onClick={() => tog_triansaction(val.id)}
                  >
                    {props.t("Not Verified")}
                  </p>
                )}
              </td>
              <td>
                <a className="float-right" onClick={() => tog_invoice(val.id)}>
                  {parseFloat(val.totalAmount).toFixed(2)}
                </a>
              </td>
              <td>
                <a
                  className="float-right"
                  onClick={() => val.paidAmount > 0 && tog_triansaction(val.id)}
                >
                  {parseFloat(val.paidAmount).toFixed(2)}
                </a>
              </td>
              <td>
                <a className="float-right">
                  {parseFloat(val.balanceAmount).toFixed(2)}
                </a>
              </td>
              <td>
                <Dropdown
                  isOpen={val.isActionActive}
                  toggle={() => activeDropDown(val.id)}
                >
                  <DropdownToggle tag="button" className="btn btn-secondary">
                    {val.paidStatus === 0 && props.t("Receive Payment")}
                    {val.paidStatus > 0 && props.t("Print Receipt")}{" "}
                    <i className="mdi mdi-chevron-down"></i>
                  </DropdownToggle>
                  <DropdownMenu>
                    {val.paidStatus !== 1 && (
                      <DropdownItem onClick={() => tog_receivePayment(val)}>
                        <a className="text-dark">
                          {props.t("Receive Payment")}
                        </a>
                      </DropdownItem>
                    )}
                    {(
                      <DropdownItem>
                        <a className="text-dark" target="_blank" href={`/invoice/${val.id}`}>
                          {props.t("Print Receipt")}
                        </a>
                      </DropdownItem>
                    )}

                    {val.paidStatus !== 0 && (
                      <DropdownItem onClick={() => tog_triansaction(val.id)}>
                        <a className="text-dark">
                          {props.t("View Transactions")}
                        </a>
                      </DropdownItem>
                    )}
                    {val.paidStatus !== 1 && (
                      <DropdownItem
                        onClick={() =>
                          props.notifyPaymentToParent(val.id, val.Obj.attributes.recipientId)
                        }
                      >
                        <a className="text-dark">{props.t("Notify Parents")}</a>
                      </DropdownItem>
                    )}
                    {val.paidAmount === 0 && (
                      <DropdownItem onClick={() => confirmDelete(val.id)}>
                        <a className="text-dark">{props.t("Delete")}</a>
                      </DropdownItem>
                    )}
                  </DropdownMenu>
                </Dropdown>{" "}
              </td>
            </tr>
          ))}
          {invoices.length === 1 && (
            <tr>
              <td colSpan="8"></td>
            </tr>
          )}
        </tbody>
      </Table> : <Card>
          <CardBody>
            <CardTitle> You don't have permission to access fee </CardTitle>
          </CardBody>
      </Card>}
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const {
    invoices,
    showLoader,
    deleteInProgress,
    deleteSuccess,
  } = state.Invoice;
  const { userTeacher } = state.Login;
  const { pendingVerifyMT } = state.MoneyTransactions;
  return {
    invoices,
    showLoader,
    deleteInProgress,
    deleteSuccess,
    pendingVerifyMT,
    userTeacher,
  };
};
export default connect(mapStatetoProps, {
  getInvoicesForFilters,
  getVerifiedStatusForInvoices,
  deleteSelectedInvoice,
  getUserTeacher,
  setDeleteSuccess,
  notifyPaymentToParent
})(withRouter(withNamespaces()(FeeTab)));
