import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardTitle,
    Container,
    Table,
    Progress,
    Media,
    UncontrolledTooltip,
} from "reactstrap";
import Parse from "parse";
import toastr from "toastr";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { setSelecetdSchool } from "../../store/actions";
import Breadcrumbs from "./Breadcrumbs"
import ListView from "./listView"

const VisitorManagement = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/visitor-management/:schoolId",
        exact: true,
        strict: false,
    });

    let schoolId = match && match.params.schoolId

    const [mainList, setMainList] = useState([]);
    const [schoolObject, setSchoolObject] = useState(undefined);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        if (schoolId) {
            fetchSchoolFromServer(schoolId);
            fetchVisitorsFromServer()
        }
    }, [schoolId]);

    useEffect(() => {
        if (
            match &&
            (!props.selectedSchool || props.selectedSchool.id !== schoolId)
        ) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const fetchSchoolFromServer = (schoolId) => {
        Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
            schoolId: schoolId,
        }).then(
            (result) => {
                if (result != null) {
                    setSchoolObject(result);
                } else {

                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const fetchVisitorsFromServer = () => {
        var query = new Parse.Query('Visitor');
        query.equalTo('schoolId', schoolId);
        query.notEqualTo('isDeleted', true);
        query.limit(1000);
        query.descending("createdAt")
        query.find().then((results) => {
            setIsLoading(false)
            if (results != null && results.length > 0) {
                setMainList(results)
            }
        })
    }

    return (
        <React.Fragment>
            <Container fluid className="page-content">
                {!isLoading && <div >
                    <Breadcrumbs title="Visitor Management" addVisitorLink={"/visitor-form/" + schoolId} visitorPosterLink={"/visitor-poster/" + schoolId} />
                    {mainList != null && mainList.length > 0 && <ListView mainList={mainList} />}
                    {(mainList == null || mainList.length === 0) &&
                        <div>
                            <Card>
                                <CardBody>
                                    <center>
                                        <Label>
                                            No Visitors to Show !!
                                        </Label>
                                    </center>
                                </CardBody>
                            </Card>
                        </div>
                    }
                </div>}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Visitor Form...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}

            </Container>


        </React.Fragment >
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
        })(VisitorManagement)
    )
);