import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip,
  Row,
  Col
} from "reactstrap";
import React, { useEffect, useState } from "react";

//Image Cropper
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
//Import Image
import src from "../../assets/images/product/img-7.png";

import Parse from "parse";

const CustomTextBoxWithButtons = (props) => {
  const [textBoxValue, setTextBoxValue] = useState(
    props.initialTextValue ?? ""
  );

  // useEffect(() => {
  //   if (props.initialImage) {
  //     setPreview(props.initialImage);
  //     setShouldShowDoneButton(true);
  //   } else {
  //     setPreview("");
  //     setShouldShowDoneButton(false);
  //   }
  // }, [props.initialImage]);

  return (
    <Row>
      <Col lg="12"  md="12" className="mt-2">
        <input
          type="text"
          id={"CTB" + props.componentId}
          className="form-control mr-1"
          style={{ maxWidth: "100px !important" }}
          value={textBoxValue}
          placeholder="Type here..."
          onChange={(e) => {
            // if (e.target.value.length > 0) {
            //   setTextBoxValue(e.target.value);
            // }
            setTextBoxValue(e.target.value);
          }}
        />
      </Col>
      <Col lg={12} md={12} className="mt-2 d-flex">
        <Button
          className="  btn-success mr-1"
          onClick={() => {
            props.doneButtonClicked(textBoxValue);
          }}
        >
          <i style={{ fontSize: "13px" }} className="fa fa-check"></i>
        </Button>
        <Button
          className="float-right btn-danger  "
          onClick={() => {
            props.closeButtonClicked(textBoxValue);
          }}
        >
          <i style={{ fontSize: "15px" }} className="fa fa-times"></i>
        </Button>
      </Col>
    </Row>
  );
};

export default CustomTextBoxWithButtons;
