import React, { useState, useEffect } from "react";
import {
  Button,
  Table,
  Modal,
  ModalHeader,
  ModalBody,
  Row,
  Col,
  ModalFooter,
  Label,
  Alert,
  Input,
  Form,
  FormGroup,
  UncontrolledTooltip
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import toastr from "toastr";
import { withNamespaces } from "react-i18next";
import Map from "../../components/Common/Map";
import Switch from "react-switch";
import * as Constants from "../../Constents";
import Editable from "react-bootstrap-editable";
import Parse from "parse";
import { Link, withRouter } from "react-router-dom";
import { connect } from "react-redux";
import BuyCredits from "./buyCredits";
import SweetAlert from "react-bootstrap-sweetalert";
import { getAllCreditsForAllUser, getPricingForMsg, checkForDaycare, addAcademicCycle } from "../../store/actions";
import verificationImg from "../../assets/images/verification-img.png";
import * as WhiteLable from "../../components/Common/WhiteLable";
import { setSelecetdSchool } from "../../store/actions";

import idCard from "../../assets/images/featurePopup/id-card.png";
import cctv from "../../assets/images/featurePopup/cctv-camera.png";
import trips from "../../assets/images/featurePopup/transportation.png";

const SchoolPermissions = (props) => {
  const isSuperUser =
    Parse.User.current() && Parse.User.current().attributes.isSuperUser;
  useEffect(
    () => {
      let currentUserObj = Parse.User.current();
      let schoolId = props.schoolId;
      let instituteId;
      let franchiseId;
      // if (currentUserObj.attributes.instituteId) {
      //   schoolId = props.schoolId;
      //   instituteId = currentUserObj.attributes.instituteId;
      // } else if (currentUserObj.attributes.franchiseId) {
      //   franchiseId = currentUserObj.attributes.franchiseId;
      // }

      //Need to uncomment when we give buy now button
      // props.getAllCreditsForAllUser(schoolId, instituteId, franchiseId);
      // props.getPricingForMsg();
    },
    [Parse.User.current()],
    props.schoolId
  );

  const getToolTip = (name, isNew) => {
    const tooltipId = name.replace(/\s+/g, '-').toLowerCase();
    return (
      <div>
        <span id={tooltipId}>{name}&nbsp;&nbsp;</span>
        {isNew && (<span><i className="fas fa-question-circle" id={tooltipId}></i>
          <UncontrolledTooltip placement="top" target={tooltipId}>
            On enabling this feature, parents can only access the CCTV when their child is checked-in.
          </UncontrolledTooltip>
        </span>)}
      </div>
    );
  }

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const messaging = ["Send Video",
    // "SMS On Alert"
  ];

  const attendance = ["Attendance", "QR Checkin and Checkout", "Self Checkin", "Attendance with temperature"];

  const childDevelopment = [
    "Assessment",
    "For Parents",
    "Home Learning",
    // "Live Classes",
  ];

  const feeManagement = [
    "Fee Management",
    // "Penalty",
    "Payment Gateway",
    "Partial Payment",
    "Program",
  ];

  const otherSettings = [
    getToolTip("Transport", false),
    getToolTip("Day Care", false),
    getToolTip("Charge For Full Hour", false),
    getToolTip("Pick And Drop", false),
    getToolTip("Medical Details", false),
    getToolTip("Expense Management", false),
    getToolTip("CCTV Camera", false),
    getToolTip("Camera Access with Attendance", true)
  ];

  const [settingsState, setSettingsState] = useState([
    { messaging: [false, false] },
    { attendance: [false, false, false, false] },
    { childDevelopment: [false, false, false, false] },
    { feeManagement: [false, false, false, false, false] },
    { otherSettings: [false, false, false, false, false, false, false, false] },
  ]);

  const [latitude, setLatitude] = useState(props.latitude ?? 12.9675873);
  const [longitude, setLongitude] = useState(props.longitude ?? 77.6446674);
  const [newlatitude, setNewLatitude] = useState();
  const [newlongitude, setNewLongitude] = useState();
  const [shouldShowPenalty, setShouldShowPenalty] = useState(false);
  const [isPenaltyTextEditOn, setIsPenaltyTextEditOn] = useState(false);
  const [smsCredits, setSmsCredits] = useState(0);
  const [whatsAppCredits, setWhatsAppCredits] = useState(0);
  const [isBuyOpen, setIsBuyOpen] = useState(false);
  const [addCreditDetails, setAddCreditDetails] = useState([]);
  const [isPaymentGatewayModelOpen, setIsPaymentGatewayModelOpen] = useState(
    false
  );
  const [isPaymentGatewayState, setIsPaymentGatewayState] = useState(
    false
  );
  const [showPopup, setShowPopup] = useState(false);
  const [showLocationPickerPopup, setShowLocationPickerPopup] = useState(false);
  const [isFeatureSettingsModalOpen, setIsFeatureSettingsModalOpen] = useState(false);
  const [selectedFeatureForModal, setSelectedFeatureForModal] = useState([]);
  const [showFeatureDisableAlert, setShowFeatureDisableAlert] = useState(false);
  const [pgDisableAlert, setShowPGDisableAlert] = useState(false);
  const [isDisable, setIsDisable] = useState(true);
  const [showFeatureName, setShowFeatureName] = useState();
  const [showDisabledPopup, setShowDisabledPopup] = useState(false);
  const schoolId = localStorage.getItem("SELECTED_SCHOOL_ID");
  const reset = () => {
    setShowPopup(false)
  }

  const featureSettings = [
    {
      title: 'Trips',
      featureImage: trips,
      icon1: "dripicons-location",
      icon2: "bx bxs-bell-ring",
      icon3: "bx bxs-devices",
      feature1: "In App Live Tracking to Parents",
      feature2: "Real Time Notification",
      feature3: "App or Device Based Tracking"
    }, {
      title: 'CCTV',
      featureImage: cctv,
      icon1: "bx bxs-lock-open",
      icon2: "bx bxs-data",
      icon3: "bx bxs-slideshow",
      feature1: "Attendance and Time based Access",
      feature2: "Centralized Control",
      feature3: "Seamless and Secured Streaming"
    },

  ];
  useEffect(() => {
    if (Parse.User.current() && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    if (props.franchise || props.selectedSchool) {
      if (props.selectedSchool && !props.selectedSchool.attributes.lockFeatures) {
        if (props.selectedSchool.attributes.franchiseId) {
          if (props.franchise && props.franchise.attributes.features &&
            props.franchise.attributes.features.length > Constants.FRANCHISE_LOCK_FEATURE_EDIT &&
            props.franchise.attributes.features[Constants.FRANCHISE_LOCK_FEATURE_EDIT] > 0) {
            setIsDisable(true)
          } else {
            setIsDisable(false)
          }
        } else {
          setIsDisable(false)
        }

      }
    }
  }, [props.franchise, props.selectedSchool]);

  const handelEnquireNow = (title) => {
    setIsFeatureSettingsModalOpen(false);
    if (Parse.User.current() && props.selectedSchool) {
      // setShowLoading(title);
      const lilPackageLeads = Parse.Object.extend("lilPackageLeads");
      const packageLeads = new lilPackageLeads();
      packageLeads.set("schoolName", props.selectedSchool.attributes.Name);
      packageLeads.set("email", props.selectedSchool.attributes.EMail);
      packageLeads.set("phoneNumber", props.selectedSchool.attributes.primaryPhoneNumber);
      packageLeads.set("location", props.selectedSchool.attributes.city);
      packageLeads.set("schoolId", props.selectedSchool.id);

      packageLeads.set("packageSelected", title);


      if (Parse.User.current().attributes.teacherId) {
        packageLeads.set("userId", Parse.User.current().attributes.teacherId);
      } else {
        packageLeads.set("userId", Parse.User.current().id);
      }

      packageLeads.save().then(
        (result) => {
          toastr.success("Thank you for showing interest, One of our executive will  be in touch with you soon.", { timeOut: 4000 });
        },
        (error) => {

        }
      );

    } else {
      //show popup
      // setShowUserInfoPopup(pricingObject);

    }
  }

  const initialStateSettings = () => {
    var temp = [...settingsState];

    //Messaging
    temp[0].messaging[0] =
      props.schoolObject.attributes.videoEnabled > 0 ? true : false;
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_SMS_ON_ALERT_INDEX
    ) {
      temp[0].messaging[1] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_SMS_ON_ALERT_INDEX
        ] > 0
          ? true
          : false;
    }

    //Attendance
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE
    ) {
      switch (
      props.schoolObject.attributes.featuresList[
      Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE
      ]
      ) {
        case 2:

          temp[1].attendance[3] = true;
          break;
        default:
          break;
      }
    }
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_ATTENDANCE_INDEX
    ) {
      switch (
      props.schoolObject.attributes.featuresList[
      Constants.FEATURE_ATTENDANCE_INDEX
      ]
      ) {
        case 1:
          temp[1].attendance[0] = true;
          break;
        case 2:
          temp[1].attendance[0] = true;
          temp[1].attendance[1] = true;
          break;
        case 4:
          temp[1].attendance[0] = true;
          temp[1].attendance[1] = true;
          temp[1].attendance[2] = true;
          break;
        default:
          break;
      }
    }


    //Child Development
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_ACTIVITY_TRACKING_INDEX
    ) {
      temp[2].childDevelopment[0] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_ACTIVITY_TRACKING_INDEX
        ] > 0
          ? true
          : false;
    }
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_ACTIVITY_TRACKING_PARENT_INDEX
    ) {
      temp[2].childDevelopment[1] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_ACTIVITY_TRACKING_PARENT_INDEX
        ] > 0
          ? true
          : false;
    }
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_E_LEARNING
    ) {
      temp[2].childDevelopment[2] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_E_LEARNING
        ] > 0
          ? true
          : false;
    }
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_GO_LIVE
    ) {
      temp[2].childDevelopment[3] =
        props.schoolObject.attributes.featuresList[Constants.FEATURE_GO_LIVE] >
          0
          ? true
          : false;
    }

    //Fee Management
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_PAYMENTS_INDEX
    ) {
      temp[3].feeManagement[0] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_PAYMENTS_INDEX
        ] > 0
          ? true
          : false;
    }

    // if (
    //   props.schoolObject.attributes.additionalSettings &&
    //   props.schoolObject.attributes.additionalSettings.length >
    //   Constants.ADDITIONAL_SETTING_PENALTY
    // ) {
    //   temp[3].feeManagement[1] =
    //     props.schoolObject.attributes.additionalSettings[
    //       Constants.ADDITIONAL_SETTING_PENALTY
    //     ] > 0
    //       ? true
    //       : false;
    //   if (temp[3].feeManagement[1]) {
    //     setShouldShowPenalty(true);
    //   }
    // }

    if (
      props.paymentOptionsObject != null &&
      props.paymentOptionsObject.attributes != null
    ) {
      temp[3].feeManagement[1] =
        props.paymentOptionsObject.attributes.paymentGateway === true
          ? true
          : false;

      setIsPaymentGatewayState(temp[3].feeManagement[1])
    }

    if (
      props.schoolObject.attributes.additionalSettings &&
      props.schoolObject.attributes.additionalSettings.length >
      Constants.ADDITIONAL_SETTING_PAYMENT_EDIT_DISABLED
    ) {
      temp[3].feeManagement[2] =
        props.schoolObject.attributes.additionalSettings[
          Constants.ADDITIONAL_SETTING_PAYMENT_EDIT_DISABLED
        ] > 0
          ? true
          : false;

    }

    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_PROGRAM_INDEX
    ) {
      temp[3].feeManagement[3] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_PROGRAM_INDEX
        ] > 0
          ? true
          : false;
    }

    //Other Settings
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_TRANSPORT_INDEX
    ) {
      temp[4].otherSettings[0] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_TRANSPORT_INDEX
        ] > 0
          ? true
          : false;
    }
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_CUSTOM_DIARY_INDEX
    ) {
      temp[4].otherSettings[1] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_CUSTOM_DIARY_INDEX
        ] > 0
          ? true
          : false;
    }

    if (
      props.schoolObject.attributes.additionalSettings != null &&
      props.schoolObject.attributes.additionalSettings.length >
      Constants.ADDITIONAL_SETTING_DAYCARE_FULL_HOUR_CHARGES
    ) {
      temp[4].otherSettings[2] =
        props.schoolObject.attributes.additionalSettings[
          Constants.ADDITIONAL_SETTING_DAYCARE_FULL_HOUR_CHARGES
        ] > 0
          ? true
          : false;
    }

    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_PICKNDROP_INDEX
    ) {
      temp[4].otherSettings[3] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_PICKNDROP_INDEX
        ] > 0
          ? true
          : false;
    }
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_MEDICAL_INDEX
    ) {
      temp[4].otherSettings[4] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_MEDICAL_INDEX
        ] > 0
          ? true
          : false;
    }
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_EXPENSE_INDEX
    ) {
      temp[4].otherSettings[5] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_EXPENSE_INDEX
        ] > 0
          ? true
          : false;
    }
    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_CCTV_INDEX
    ) {
      temp[4].otherSettings[6] =
        props.schoolObject.attributes.featuresList[
          Constants.FEATURE_CCTV_INDEX
        ] > 0
          ? true
          : false;
    }

    if (
      props.schoolObject.attributes.featuresList &&
      props.schoolObject.attributes.featuresList.length >
      Constants.FEATURE_CCTV_INDEX
    ) {
      if (
        props.schoolObject.attributes.featuresList[
        Constants.FEATURE_CCTV_INDEX
        ] === 1
      ) {
        temp[4].otherSettings[7] = true;
      } else {
        temp[4].otherSettings[7] = false;
      }
    }

    setSettingsState(temp);
  };

  const changeStateSettings = (index1, index2) => {
    var schoolFeaturesList = [];
    var additionalSettings = [];
    if (props.schoolObject.attributes.featuresList) {
      schoolFeaturesList = [...props.schoolObject.attributes.featuresList];
    }

    if (props.schoolObject.attributes.additionalSettings) {
      additionalSettings = [...props.schoolObject.attributes.additionalSettings];
    }

    var schoolObj = props.schoolObject;

    if (index1 === 0) {
      if (index2 === 0) {
        schoolObj.set(
          "videoEnabled",
          props.schoolObject.attributes.videoEnabled > 0 ? 0 : 1
        );
      } else if (index2 === 1) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].messaging[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_SMS_ON_ALERT_INDEX,
            true
          ),
        ];
        //schoolFeaturesList[Constants.FEATURE_SMS_ON_ALERT_INDEX] = !settingsState[index1].messaging[index2] === true ? 1 : 0
      }

      var temp = [...settingsState];
      temp[index1].messaging[index2] = !settingsState[index1].messaging[index2];
      setSettingsState(temp);
    } else if (index1 === 1) {
      if (index2 === 0) {

        if (!settingsState[index1].attendance[index2] === true) {
          schoolFeaturesList = [
            ...handleListLength(
              !settingsState[index1].attendance[index2] === true ? 1 : 0,
              schoolFeaturesList,
              Constants.FEATURE_ATTENDANCE_INDEX,
              true
            ),
          ];
        } else {
          schoolFeaturesList = [
            ...handleListLength(
              !settingsState[index1].attendance[index2] === true ? 1 : 0,
              schoolFeaturesList,
              Constants.FEATURE_ATTENDANCE_INDEX,
              true
            ),
          ];
          schoolFeaturesList = [
            ...handleListLength(
              0,
              schoolFeaturesList,
              Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE,
              true
            ),
          ];

        }





        //schoolFeaturesList[Constants.FEATURE_ATTENDANCE_INDEX] = !settingsState[index1].attendance[index2] === true ? 1 : 0
      } else if (index2 === 1) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].attendance[index2] === true ? 2 : 1,
            schoolFeaturesList,
            Constants.FEATURE_ATTENDANCE_INDEX,
            true
          ),
        ];

        //schoolFeaturesList[Constants.FEATURE_ATTENDANCE_INDEX] = !settingsState[index1].attendance[index2] === true ? 2 : 1
      } else if (index2 === 2) {
        if (!settingsState[index1].attendance[index2] === true) {
          showLocationPickerAndReturnIndexForSelfCheckin();
        } else {
          schoolFeaturesList = [
            ...handleListLength(
              !settingsState[index1].attendance[index2] === true
                ? showLocationPickerAndReturnIndexForSelfCheckin()
                : 2,
              schoolFeaturesList,
              Constants.FEATURE_ATTENDANCE_INDEX,
              true
            ),
          ];
        }

        //schoolFeaturesList[Constants.FEATURE_ATTENDANCE_INDEX] = !settingsState[index1].attendance[index2] === true ? 4 : 2
      } else if (index2 === 3) {
        if (!settingsState[index1].attendance[index2] === true) {
          //On
          schoolFeaturesList = [
            ...handleListLength(
              !settingsState[index1].attendance[index2] === true ? 2 : 0,
              schoolFeaturesList,
              Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE,
              true
            ),
          ];


          if (!settingsState[index1].attendance[0] === true) {
            schoolFeaturesList = [
              ...handleListLength(
                1,
                schoolFeaturesList,
                Constants.FEATURE_ATTENDANCE_INDEX,
                true
              ),
            ];
          }



        } else {
          //Off
          schoolFeaturesList = [
            ...handleListLength(
              !settingsState[index1].attendance[index2] === true ? 2 : 0,
              schoolFeaturesList,
              Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE,
              true
            ),
          ];

        }

      }

      var temp = [...settingsState];

      temp[index1].attendance[0] = false;
      temp[index1].attendance[1] = false;
      temp[index1].attendance[2] = false;
      temp[index1].attendance[3] = false;



      switch (schoolFeaturesList[Constants.FEATURE_ATTENDANCE_WITH_TEMPERATURE]) {

        case 2:
          temp[index1].attendance[0] = true;
          temp[index1].attendance[3] = true;
          break;


        default:
          break;
      }

      switch (schoolFeaturesList[Constants.FEATURE_ATTENDANCE_INDEX]) {
        case 1:
          temp[index1].attendance[0] = true;
          break;
        case 2:
          temp[index1].attendance[0] = true;
          temp[index1].attendance[1] = true;
          break;
        case 4:
          temp[index1].attendance[0] = true;
          temp[index1].attendance[1] = true;
          temp[index1].attendance[2] = true;
          break;
        default:
          break;
      }

      setSettingsState(temp);
    } else if (index1 === 2) {
      if (index2 === 0) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].childDevelopment[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_ACTIVITY_TRACKING_INDEX,
            true
          ),
        ];

        if (
          schoolFeaturesList[Constants.FEATURE_ACTIVITY_TRACKING_INDEX] === 0
        ) {
          schoolFeaturesList = [
            ...handleListLength(
              0,
              schoolFeaturesList,
              Constants.FEATURE_ACTIVITY_TRACKING_PARENT_INDEX,
              true
            ),
          ];

        }
      } else if (index2 === 1) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].childDevelopment[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_ACTIVITY_TRACKING_PARENT_INDEX,
            true
          ),
        ];

        if (
          schoolFeaturesList[Constants.FEATURE_ACTIVITY_TRACKING_INDEX] === 0
        ) {
          schoolFeaturesList = [
            ...handleListLength(
              0,
              schoolFeaturesList,
              Constants.FEATURE_ACTIVITY_TRACKING_PARENT_INDEX,
              true
            ),
          ];

        }
      } else if (index2 === 2) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].childDevelopment[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_E_LEARNING,
            true
          ),
        ];

      } else if (index2 === 3) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].childDevelopment[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_GO_LIVE,
            true
          ),
        ];

      }

      var temp = [...settingsState];
      temp[index1].childDevelopment[index2] = !settingsState[index1]
        .childDevelopment[index2];

      if (
        schoolFeaturesList.length > 0 &&
        schoolFeaturesList[Constants.FEATURE_ACTIVITY_TRACKING_INDEX] === 0
      ) {
        temp[index1].childDevelopment[1] = false;
      }

      setSettingsState(temp);
    } else if (index1 === 3) {
      //Fee Management
      if (index2 === 0) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].feeManagement[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_PAYMENTS_INDEX,
            true
          ),
        ];

        //Penalty
      } else if (index2 === 1 && false) {
        if (!settingsState[index1].feeManagement[index2] === true) {
          setIsPenaltyTextEditOn(true);
        } else {
          setIsPenaltyTextEditOn(false);
        }
        setShouldShowPenalty(!settingsState[index1].feeManagement[index2]);


        //Payment Gateway
      } else if (index2 === 1) {
        if (props.paymentOptionsObject != null) {
          togglePaymentGatewayPopup();
        }


      } else if (index2 === 2) {
        additionalSettings = [
          ...handleListLength(
            !settingsState[index1].feeManagement[index2] === true ? 1 : 0,
            additionalSettings,
            Constants.ADDITIONAL_SETTING_PAYMENT_EDIT_DISABLED,
            true
          ),
        ];


      }
      else if (index2 === 3) {
        //Program
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].feeManagement[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_PROGRAM_INDEX,
            true
          ),
        ];
      }

      var temp = [...settingsState];
      temp[index1].feeManagement[index2] = !settingsState[index1].feeManagement[
        index2
      ];
      setSettingsState(temp);
    } else if (index1 === 4) {
      if (index2 === 0) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].otherSettings[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_TRANSPORT_INDEX,
            true
          ),
        ];

      } else if (index2 === 1) {

        if (!settingsState[index1].otherSettings[index2] === true) {
          handleDaycareOn(props.schoolId)
        }

        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].otherSettings[index2] === true ? 2 : 0,
            schoolFeaturesList,
            Constants.FEATURE_CUSTOM_DIARY_INDEX,
            true
          ),
        ];

      } else if (index2 === 2) {
        var schoolObj = props.schoolObject;
        var additionalSettingsList = [];
        if (
          props.schoolObject.attributes.additionalSettings == null ||
          props.schoolObject.attributes.additionalSettings.length === 0
        ) {
          additionalSettingsList = [0, 1];
        } else if (
          props.schoolObject.attributes.additionalSettings.length === 1
        ) {
          additionalSettingsList =
            props.schoolObject.attributes.additionalSettings;

          additionalSettingsList.push(1);
        } else {
          additionalSettingsList[
            Constants.ADDITIONAL_SETTING_DAYCARE_FULL_HOUR_CHARGES
          ] = !settingsState[index1].otherSettings[index2] === true ? 1 : 0;
        }
        schoolObj.set("additionalSettings", additionalSettingsList);
        props.onChangeSchoolObject(schoolObj);

        var temp = [...settingsState];
        temp[index1].otherSettings[index2] = !settingsState[index1]
          .otherSettings[index2];
        setSettingsState(temp);

        return;
      } else if (index2 === 3) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].otherSettings[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_PICKNDROP_INDEX,
            true
          ),
        ];

      } else if (index2 === 4) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].otherSettings[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_MEDICAL_INDEX,
            true
          ),
        ];

      } else if (index2 === 5) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].otherSettings[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_EXPENSE_INDEX,
            true
          ),
        ];

      } else if (index2 === 6) {
        schoolFeaturesList = [
          ...handleListLength(
            !settingsState[index1].otherSettings[index2] === true ? 1 : 0,
            schoolFeaturesList,
            Constants.FEATURE_CCTV_INDEX,
            true
          ),
        ];

      } else if (index2 === 7) {
        if (
          props.schoolObject.attributes.featuresList &&
          props.schoolObject.attributes.featuresList.length >
          Constants.FEATURE_CCTV_INDEX &&
          props.schoolObject.attributes.featuresList[
          Constants.FEATURE_CCTV_INDEX
          ] === 0
        ) {
        } else {
          schoolFeaturesList = [
            ...handleListLength(
              !settingsState[index1].otherSettings[index2] === true ? 1 : 2,
              schoolFeaturesList,
              Constants.FEATURE_CCTV_INDEX,
              true
            ),
          ];
        }
      }

      var temp = [...settingsState];
      temp[index1].otherSettings[index2] = !settingsState[index1].otherSettings[
        index2
      ];

      if (
        index2 === 7 &&
        schoolFeaturesList.length > Constants.FEATURE_CCTV_INDEX &&
        schoolFeaturesList[Constants.FEATURE_CCTV_INDEX] === 0
      ) {
        temp[index1].otherSettings[7] = false;
      }
      // setIsFeatureSettingsModalOpen(true);
      setSettingsState(temp);

    }

    schoolObj.set('additionalSettings', additionalSettings)
    schoolObj.set("featuresList", schoolFeaturesList);
    props.onChangeSchoolObject(schoolObj);
  };

  const handleDaycareOn = (schoolId) => {
    let classLevel
    if (props.academicCycles) {
      let tempList = props.academicCycles.filter((cycle) =>
        cycle.attributes.level === "Daycare"
      )

      if (tempList.length > 0) {
        classLevel = tempList[0].attributes.schoolCycle ? tempList[0].attributes.schoolCycle : tempList[0].attributes.defaultCycle
      }
    }
    if (classLevel) {
      props.checkForDaycare(schoolId, classLevel)
    } else {
      handelAddCycle()
      props.checkForDaycare(schoolId, "Infant 0-18 Months")
    }
  }

  function handelAddCycle() {
    let message = props.t('Added successfully!');

    let AcademicCycles = Parse.Object.extend("AcademicCycles");
    let academicCycles = new AcademicCycles();

    academicCycles.set("defaultCycle", "Infant 0-18 Months");
    academicCycles.set("schoolId", props.schoolId);
    academicCycles.set("level", "Daycare");
    academicCycles.set("order", props.academicCycles ? props.academicCycles.length : 0);

    props.addAcademicCycle([academicCycles], message);

  }

  const showLocationPickerAndReturnIndexForSelfCheckin = () => {
    setShowLocationPickerPopup(true);
    return 4;
  };

  const togglePaymentGatewayPopup = () => {
    // if (isPaymentGatewayModelOpen === true) {
    //   var temp = [...settingsState];
    //   temp[3].feeManagement[1] = false;
    //   setSettingsState(temp);
    // }
    setIsPaymentGatewayModelOpen(!isPaymentGatewayModelOpen);
  };

  const togglefeatureSettingsPopup = () => {
    setIsFeatureSettingsModalOpen(!isFeatureSettingsModalOpen)
  }

  const handleListLength = (value, list, indexNumber, directValue) => {
    var additionalSettingsList = [...list];

    if (list == null) {
      //additionalSettingsList = [0, 0, 0, 0, 0, parseInt(value, 10)]
    } else {
      if (additionalSettingsList.length > indexNumber) {
        additionalSettingsList[indexNumber] = directValue
          ? value
          : parseInt(value, 10);
      } else {
        //Manage if length is less than indexNumber
        var i = additionalSettingsList.length;
        for (i = additionalSettingsList.length; i < indexNumber + 1; i++) {
          if (i === indexNumber) {
            additionalSettingsList[i] = directValue
              ? value
              : parseInt(value, 10);
          } else {
            additionalSettingsList.push(0);
          }
        }
      }
    }
    return additionalSettingsList;
  };

  const handlePenaltyChange = (value) => {
    if (value > 0) {
      var schoolObj = props.schoolObject;
      var additionalSettingsList = [];
      if (props.schoolObject.attributes.additionalSettings == null) {
        additionalSettingsList = [0, 0, 0, 0, 0, parseInt(value, 10)];
      } else {
        additionalSettingsList =
          props.schoolObject.attributes.additionalSettings;

        if (additionalSettingsList.length > 5) {
          additionalSettingsList[
            Constants.ADDITIONAL_SETTING_PENALTY
          ] = parseInt(value, 10);
        } else {
          //Manage if length is less than 6
          var i = additionalSettingsList.length;
          for (i = additionalSettingsList.length; i < 6; i++) {
            if (i === Constants.ADDITIONAL_SETTING_PENALTY) {
              additionalSettingsList[i] = parseInt(value, 10);
            } else {
              additionalSettingsList.push(0);
            }
          }
        }
      }
      setIsPenaltyTextEditOn(false);
      schoolObj.set("additionalSettings", additionalSettingsList);
      props.onChangeSchoolObject(schoolObj);
    } else {
      //Please enter value greater than 0.
    }
  };

  useEffect(() => {
    initialStateSettings();
  }, [props.paymentOptionsObject]);

  useEffect(() => {
    if (props.messageCredits) {
      for (const val of props.messageCredits) {
        if (val.attributes.type === "sms") {
          let tempValue = smsCredits;
          setSmsCredits(tempValue + val.attributes.balance);
        } else if (val.attributes.type === "whatsapp") {
          let tempValue = whatsAppCredits;
          setWhatsAppCredits(tempValue + val.attributes.balance);
        }
      }
    }
  }, [props.messageCredits]);

  useEffect(() => {
    setAddCreditDetails(props.pricingList);
  }, [props.pricingList]);

  const handleSelectLatitudeLongitude = (latitude, longitude) => {
    console.log(latitude, longitude);
    setNewLatitude(latitude);
    setNewLongitude(longitude);

    setLatitude(latitude);
    setLongitude(longitude);
  };

  const handelLocationSaveButton = (newlatitude, newlongitude, manual) => {
    if (newlatitude && newlongitude) {
      let locationPoint = new Parse.GeoPoint(newlatitude, newlongitude);
      let schoolObj = props.schoolObject;
      schoolObj.set("location", locationPoint);

      var schoolFeaturesList = [];
      if (props.schoolObject.attributes.featuresList) {
        schoolFeaturesList = [...props.schoolObject.attributes.featuresList];
      }
      schoolFeaturesList = [
        ...handleListLength(
          4,
          schoolFeaturesList,
          Constants.FEATURE_ATTENDANCE_INDEX,
          true
        ),
      ];
      schoolObj.set("featuresList", schoolFeaturesList);
      props.onChangeSchoolObject(schoolObj);
      var temp = [...settingsState];
      let index1 = 1;
      temp[index1].attendance[0] = false;
      temp[index1].attendance[1] = false;
      temp[index1].attendance[2] = false;

      switch (schoolFeaturesList[Constants.FEATURE_ATTENDANCE_INDEX]) {
        case 1:
          temp[index1].attendance[0] = true;
          break;
        case 2:
          temp[index1].attendance[0] = true;
          temp[index1].attendance[1] = true;
          break;
        case 4:
          temp[index1].attendance[0] = true;
          temp[index1].attendance[1] = true;
          temp[index1].attendance[2] = true;
          break;
        default:
          break;
      }

      setNewLatitude();
      setNewLongitude();

      if (manual) {
        setLatitude(newlatitude);
        setLongitude(newlongitude);
      }

      setSettingsState(temp);
      toastr.success("successfully saved the location !!");
      setShowLocationPickerPopup(false);
    }
  };



  return (
    <React.Fragment>
      {/* <p className="card-title-desc">{props.t('Based on your selection features are shown to your staff in lilTriangle mobile application')}</p> */}
      {showFeatureDisableAlert && (
        <SweetAlert
          warning
          confirmBtnText="Close"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            // setShowFeatureDisableAlert(false);
          }}
          onConfirm={() => setShowFeatureDisableAlert(false)}
        >
          <div>
            {" "}
            {props.t("This is a premium feature. Please contact lilTriangle Support for any changes! ")}
            <br /> <p className="text-primary mt-2">{"7676548853 or hello@liltriangle.com"}</p>
          </div>
        </SweetAlert>
      )}

      {pgDisableAlert && (
        <SweetAlert
          warning
          confirmBtnText="Close"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            // setShowFeatureDisableAlert(false);
          }}
          onConfirm={() => setShowPGDisableAlert(false)}
        >
          <div>
            {" "}
            {props.t("Please contact lilTriangle Support to disable Payment Gateway")}
            <br /> <p className="text-primary mt-2">{"7676548853 or hello@liltriangle.com"}</p>
          </div>
        </SweetAlert>
      )}
      {showDisabledPopup && (
        <SweetAlert
          warning
          confirmBtnText="Enquiry now"
          confirmBtnBsStyle="success"
          title="Alert!"
          onConfirm={() => {
            handelEnquireNow(showFeatureName && showFeatureName.name);
            setShowDisabledPopup(false);
          }}
        >
          <div>
            {" "}
            {`Please contact lilTriangle Support to `}{showFeatureName && showFeatureName.checked == true ? `unsubscribe` : `subscribe`}&nbsp;{""}<b>{showFeatureName && showFeatureName.name}</b>{` feature`}
            <br /> <p className="text-primary mt-2">{"7676548853 or hello@liltriangle.com"}</p>
            <p className="text-danger" style={{ cursor: "pointer" }} onClick={(() => { setShowDisabledPopup(false) })}><u>Close</u></p>
          </div>
        </SweetAlert>
      )}
      <Modal isOpen={isPaymentGatewayModelOpen} fade={false}>
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div className="text-center">
              <Row className="justify-content-center">
                <Col lg="10">
                  <h4 className="mt-4 font-weight-semibold">
                    KYC Verification
                  </h4>
                  <p className="text-muted mt-3">
                    KYC Verification is needed to turn on payment gateway for
                    your school, please upload all necessary documents for
                    verification.
                  </p>
                  {/* <p className="text-muted">
                    , if information is not available or applicable
                    please fill NA.
                  </p> */}
                  <p className="text-muted">
                    Once verified it will be automatically turned on.
                  </p>

                  <div className="mt-4">
                    {props.schoolObject ? (
                      <Link
                        to={"/paymentgatewaykyc" + "/" + props.schoolObject.id}
                      >
                        <Button
                          type="button"
                          color="primary"
                          className="btn waves-effect waves-light  mb-2"
                        >
                          Upload Documnents
                        </Button>
                      </Link>
                    ) : (
                      <Button
                        type="button"
                        color="primary"
                        className="btn waves-effect waves-light  mb-2"
                      >
                        Click here for Verification
                      </Button>
                    )}
                  </div>
                </Col>
              </Row>

              <Row className="justify-content-center mt-5 mb-2">
                <Col sm="6" xs="8">
                  <div>
                    <img src={verificationImg} alt="" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglePaymentGatewayPopup}>
            Close
          </Button>
        </ModalFooter>
      </Modal>

      <Modal
        isOpen={isFeatureSettingsModalOpen}
        fade={false}
      >
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {selectedFeatureForModal.map((data, index) => (
              <div className="text-center" key={index}>
                <Row className="justify-content-center" >
                  <Col lg="10" >
                    <h3 className="mt-4 font-weight-semibold">
                      {data.title}
                    </h3>
                    <p className="text-muted mt-3">
                      To Enable this feature. Please contact to our lilTriangle support number 7676548853 or hello@liltriangle.com
                    </p>
                    <div className="mt-3">
                      <i className={data.icon1}>&nbsp;</i>
                      <span className="text-muted ">
                        {data.feature1}
                      </span>
                    </div>
                    <div className="mt-2">
                      <i className={data.icon2}>&nbsp;</i>
                      <span className="text-muted ">
                        {data.feature2}
                      </span>
                    </div>
                    <div className="mt-2">
                      <i className={data.icon3}>&nbsp;</i>
                      <span className="text-muted ">
                        {data.feature3}
                      </span>
                    </div>



                    <div className="mt-4">
                      <Button
                        type="button"
                        color="primary"
                        className="btn waves-effect waves-light  mb-2"
                        onClick={() => {
                          handelEnquireNow(data.title)
                        }}
                      >
                        Enquire Now
                      </Button>
                    </div>
                  </Col>
                </Row>
                <Row className="justify-content-center mt-5 mb-2">
                  <Col sm="6" xs="8">
                    <div>
                      <img src={data.featureImage} alt="" className="img-fluid" height="130" width="130" />
                    </div>
                  </Col>
                </Row>
              </div>
            ))}
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={togglefeatureSettingsPopup} >
            Close
          </Button>
        </ModalFooter>
      </Modal>

      {!WhiteLable.isAdigurkulApp() && <BuyCredits
        isOpen={isBuyOpen}
        setIsOpen={setIsBuyOpen}
        buyCreditDetails={addCreditDetails}
        smsAvalableCredits={smsCredits}
        whatsAppAvalableCredits={whatsAppCredits}
        schoolId={props.schoolId}
        schoolObject={props.schoolObject}
      />}
      <Table className="table-centered table-nowrap table-hover">
        <thead className="thead-light" style={{ height: "0" }}>
          <tr>
            <th scope="col" colSpan="2">
              <span>
                {props.t("Messaging")}
                {/* ({props.t("Available Credit SMS")}:{"  "}
                {smsCredits}) */}
                {/* {props.t("WhatsApp")}: {whatsAppCredits}) */}
              </span>
              {/* {settingsState[0].messaging[1] && (
                <div className="float-right">
                  <Button className="btn btn-info">Buy Credits</Button>
                </div>
              )} */}
            </th>

            <th scope="col">
              {/* <Button
                className="btn btn-info"
                onClick={() => setIsBuyOpen(true)}
              >
                Buy Credits
              </Button> */}
            </th>
          </tr>
        </thead>
        <tbody>
          {messaging.map((item, i) => (
            <tr key={"_user_" + i}>
              <td> {item}</td>
              <td>
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {
                    if (isDisable) {
                      setShowDisabledPopup(true)
                    } else {
                      changeStateSettings(0, i);
                    }
                    setShowFeatureName({ name: item, checked: settingsState[0].messaging[i] })
                  }}
                  checked={settingsState[0].messaging[i]}
                  disabled={!isSuperUser}
                />
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Table className="table-centered table-nowrap table-hover">
        <thead className="thead-light" style={{ height: "0" }}>
          <tr>
            <th scope="col" style={{ width: "450px" }}>
              {props.t("Attendance")}
            </th>
            <th scope="col">{props.t("")}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {attendance.map((item, i) => (
            <tr key={"_user_" + i}>
              <td> {item}</td>
              <td>
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {
                    if (isDisable) {
                      setShowDisabledPopup(true)
                    } else {
                      changeStateSettings(1, i);
                    }
                    setShowFeatureName({ name: item, checked: settingsState[1].attendance[i] })
                  }}
                  checked={settingsState[1].attendance[i]}
                  disabled={!isSuperUser}
                />
              </td>

              <td>
                <span>
                  {settingsState[1].attendance[i] && i === 2 && (
                    <Button
                      className="btn-sm btn-primary"
                      color="primary"
                      disabled={showLocationPickerPopup}
                      onClick={() => {
                        setShowLocationPickerPopup(true);
                      }}
                    >
                      Update Location
                    </Button>
                  )}
                </span>{" "}
                <span>
                  {settingsState[1].attendance[i] && i === 2 && (
                    <Link
                      to={"/checkinposter/" + props.schoolId}
                      target="_blank"
                    >
                      <Button className="btn-sm btn-primary" color="primary">
                        Download Poster
                      </Button>
                    </Link>
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      {showLocationPickerPopup && (
        <div className="text-center border ">
          <Button
            color="primary"
            className="btn-sm m-1"
            disabled={newlatitude && newlongitude ? false : true}
            onClick={() => {
              handelLocationSaveButton(newlatitude, newlongitude);
            }}
          >
            save
          </Button>

          <Button
            color="secondary"
            className="btn-sm m-1"
            onClick={() => {
              if (props.schoolObject) {
                if (props.schoolObject.attributes.location) {
                  setLatitude(props.schoolObject.attributes.location.latitude);
                  setLongitude(
                    props.schoolObject.attributes.location.longitude
                  );
                }
              }

              setShowLocationPickerPopup(false);
            }}
          >
            Close
          </Button>
          <Button
            color="primary"
            className="btn-sm m-1"
            onClick={() => {
              setShowPopup(true);
            }}
          >
            Enter Manually
          </Button>

          <Row>
            <Col lg={3}></Col>
            <Col lg={6}>
              <Map
                className="justify-content-center mb-10"
                google={props.google}
                center={{ lat: latitude, lng: longitude }}
                height="400px"
                onSelectLatLng={(latitude, longitude) => {
                  handleSelectLatitudeLongitude(latitude, longitude);
                }}
                zoom={15}
              />
            </Col>
            <Col lg={3}></Col>
          </Row>
          <div className="m-5"></div>
        </div>
      )}
      <Table className="table-centered table-nowrap table-hover">
        <thead className="thead-light" style={{ height: "0" }}>
          <tr>
            <th scope="col" style={{ width: "450px" }}>
              {props.t("Learning management system")}
            </th>
            <th scope="col">{props.t("")}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {childDevelopment.map((item, i) => (
            <tr key={"_user_" + i}>
              <td> {item}</td>
              <td>
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {
                    if (isDisable) {
                      setShowDisabledPopup(true)
                    } else {
                      changeStateSettings(2, i);
                    }
                    setShowFeatureName({ name: item, checked: settingsState[2].childDevelopment[i] })


                  }}
                  checked={settingsState[2].childDevelopment[i]}
                  disabled={!isSuperUser}
                />
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Table className="table-centered table-nowrap table-hover">
        <thead className="thead-light" style={{ height: "0" }}>
          <tr>
            <th scope="col" style={{ width: "450px" }}>
              {props.t("Fee Management")}
            </th>
            <th scope="col">{props.t("")}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {feeManagement.map((item, i) => (
            <tr key={"_user_" + i}>
              <td> {item}</td>
              <td>
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {

                    if (i == 1) {
                      if (settingsState[3].feeManagement[i]) {
                        setShowPGDisableAlert(true);
                      } else {
                        togglePaymentGatewayPopup()
                      }
                    } else if (isDisable) {
                      setShowDisabledPopup(true)
                    } else {
                      changeStateSettings(3, i);
                    }
                    setShowFeatureName({ name: item, checked: settingsState[3].feeManagement[i] })
                  }}
                  checked={settingsState[3].feeManagement[i]}
                  disabled={i != 2 ? !isSuperUser : isSuperUser ? isPaymentGatewayState ? false : true : true}
                />
              </td>
              <td>
                {shouldShowPenalty && i === 1 && false && (
                  <span>
                    Penalty Amount Per Day :
                    <Editable
                      alwaysEditing={isPenaltyTextEditOn}
                      onSubmit={handlePenaltyChange}
                      disabled={false}
                      editText=" ✎"
                      id={null}
                      isValueClickable={false}
                      mode="inline"
                      placement="top"
                      initialValue={
                        props.schoolObject != null &&
                          props.schoolObject.attributes.additionalSettings &&
                          props.schoolObject.attributes.additionalSettings
                            .length > Constants.ADDITIONAL_SETTING_PENALTY &&
                          props.schoolObject.attributes.additionalSettings[
                          Constants.ADDITIONAL_SETTING_PENALTY
                          ] > 0
                          ? props.schoolObject.attributes.additionalSettings[
                            Constants.ADDITIONAL_SETTING_PENALTY
                          ].toString()
                          : "0"
                      }
                      showText
                      type="textfield"
                    />
                  </span>
                )}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
      <Table className="table-centered table-nowrap table-hover">
        <thead className="thead-light" style={{ height: "0" }}>
          <tr>
            <th scope="col" style={{ width: "450px" }}>
              {props.t("Other Settings")}
            </th>
            <th scope="col">{props.t("")}</th>
            <th scope="col"></th>
          </tr>
        </thead>
        <tbody>
          {otherSettings.map((item, i) => (
            <tr key={"_user_" + i}>
              <td> {item}</td>
              <td>
                <Switch
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {

                    if (i == 0) {
                      if (settingsState[4].otherSettings[i]) {
                        setShowFeatureDisableAlert(true);
                      } else {
                        setIsFeatureSettingsModalOpen(true)
                        setSelectedFeatureForModal([featureSettings[0]]);
                      }
                    } else if (i == 6) {
                      if (settingsState[4].otherSettings[i]) {
                        setShowFeatureDisableAlert(true);
                      } else {
                        setIsFeatureSettingsModalOpen(true)
                        setSelectedFeatureForModal([featureSettings[1]]);
                      }
                    } else if (isDisable) {
                      setShowDisabledPopup(true)
                    } else {
                      changeStateSettings(4, i);
                    }
                    setShowFeatureName({ name: item, checked: settingsState[4].otherSettings[i] })
                  }}
                  checked={settingsState[4].otherSettings[i]}
                  disabled={!isSuperUser}
                />
              </td>
              <td></td>
            </tr>
          ))}
        </tbody>
      </Table>

      <Modal
        size="sm"
        isOpen={showPopup}
        toggle={() => {
          reset();
        }}
      >
        <ModalHeader
          toggle={() => {
            reset();
          }}
        >
          {" "}
          {props.t("Enter latitude and longitude")}
        </ModalHeader>
        <ModalBody>
          <AvForm
            onValidSubmit={(e, v) => {
              //e.preventDefault()
              let lat = parseFloat(v.latitude + "")
              let long = parseFloat(v.longitude + "")

              console.log("lat:", lat, ", lang: ", long)

              handelLocationSaveButton(lat, long, true)
              reset();
            }}
          >
            <Row>
              <Col>
                <AvField
                  name="latitude"
                  label="Latitude "
                  placeholder="Latitude"
                  type="number"
                  errorMessage="Invalid values."
                  value={latitude}
                  validate={{
                    required: { value: true },
                    min: { value: -90, errorMessage: "Invalid values." },
                    max: { value: +90, errorMessage: "Invalid values." }
                  }}
                />

              </Col>
            </Row>

            <Row>
              <Col>
                <AvField
                  name="longitude"
                  label="Longitude "
                  placeholder="Logitude"
                  type="number"
                  errorMessage="Invalid values."
                  value={longitude}
                  validate={{
                    required: { value: true },
                    min: { value: -180, errorMessage: "Invalid values." },
                    max: { value: +180, errorMessage: "Invalid values." }

                  }}
                />
              </Col>
            </Row>

            <button
              className="btn btn-primary float-right"
              type='submit'
              disabled={false}
            >
              <i className="align-middle"></i>
              Save
            </button>
          </AvForm>
        </ModalBody>

      </Modal>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { messageCredits, pricingList } = state.MessageCredits;
  const { academicCycles, loadState } = state.AcademicCycles;
  const { selectedSchool } = state.School;
  return { messageCredits, pricingList, academicCycles, loadState, selectedSchool, };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getAllCreditsForAllUser,
      getPricingForMsg,
      checkForDaycare,
      addAcademicCycle,
      setSelecetdSchool,
    })(SchoolPermissions)
  )
);
