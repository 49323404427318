import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Card, CardBody, CardTitle, Col, Media, Row } from "reactstrap";
import ReactApexChart from "react-apexcharts";
import { getInvoiceCountForSchoolId } from "../../store/actions";

const PaymentAnalytics = (props) => {
  const [completeCount, completeCountOps] = useState(0);
  const [partiallyPaidCount, partiallyPaidCountOps] = useState(0);
  const [pendingCount, pendingCountOps] = useState(0);
  const [series, seriesOps] = useState([]);
  const [options, optionsOps] = useState({});
  const [acamadicYear, acamadicYearOps] = useState("2024 - 25");
  useEffect(() => {
    let tempTags = [];
    tempTags.push("2024 - 25");
    tempTags.push("2024-25");
    tempTags.push("2024- 25");
    tempTags.push("2024 -25");
    props.getInvoiceCountForSchoolId(props.schoolId, tempTags);
  }, [props.schoolId]);
  useEffect(() => {
    if (props.invoiceCountList) {
      const complate = props.invoiceCountList.successCount
        ? props.invoiceCountList.successCount
        : 0;
      const partiallyPaid = props.invoiceCountList.partiallyPaid
        ? props.invoiceCountList.partiallyPaid
        : 0;
      const pending = props.invoiceCountList.pending
        ? props.invoiceCountList.pending
        : 0;
      seriesOps([complate, partiallyPaid, pending]);
      completeCountOps(complate);
      partiallyPaidCountOps(partiallyPaid);
      pendingCountOps(pending);
      optionsOps({
        labels: ["Complete", "Partially Paid", "Pending"],
        colors: ["#34c38f", "#f1b44c", "#f46a6a"],
        legend: {
          show: true,
          position: "bottom",
          horizontalAlign: "center",
          verticalAlign: "middle",
          floating: false,
          fontSize: "14px",
          offsetX: 0,
          offsetY: -10,
        },
        dataLabels: {
          enabled: false,
        },
        responsive: [
          {
            breakpoint: 600,
            options: {
              chart: {
                height: 240,
              },
              legend: {
                show: false,
              },
            },
          },
        ],
        legend: {
          show: false,
        },
        // dataLabels: {
        //   enabled: true,
        //   formatter: function (val) {
        //     return val + "%";
        //   },
        // },
      });
    }
  }, [props.invoiceCountList]);
  const gotoFee = () => {
    window.location = `/payments/${props.schoolId}`;
  };
  return (
    <React.Fragment>
      {" "}
      <Card>
        <CardBody>
          <Row className="d-flex justify-content-between">
            <Col xs={8} className="d-flex justify-content-start">
              <CardTitle className="mb-4">
                {props.t("Fee Analytics")} ({acamadicYear})
              </CardTitle>
            </Col>
            <Col xs={4} className="d-flex justify-content-end">
              <Link to={`/payments/${props.schoolId}`} className='text-primary'><u>Details</u></Link>
            </Col>
          </Row>
          <Row>
            <Col sm="6">
              <ul className="list-unstyled">
                <li
                  className="event-list mb-0 pb-2"
                  style={{ cursor: "pointer" }}
                >
                  {/* <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle mt-2"></i>
                  </div> */}
                  <Media>
                    {/* <div className="mr-3">
                      <i className="mdi mdi-credit-card-outline h3 text-danger"></i>
                    </div> */}
                    <Media body>
                      <div className="mt-1">
                        <span className="badge badge-pill badge-soft-danger font-size-13">
                          Pending ({pendingCount})
                        </span>
                      </div>
                    </Media>
                  </Media>
                </li>
                <li
                  className="event-list mb-0 pb-2"
                  style={{ cursor: "pointer" }}
                >
                  {/* <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle mt-2"></i>
                  </div> */}
                  <Media>
                    {/* <div className="mr-3">
                      <i className="mdi mdi-account-cash-outline h3 text-warning"></i>
                    </div> */}
                    <Media body>
                      <div className="mt-1">
                        <span className="badge badge-pill badge-soft-warning font-size-13">
                          Partially Paid ({partiallyPaidCount})
                        </span>
                      </div>
                    </Media>
                  </Media>
                </li>

                <li className="event-list" style={{ cursor: "pointer" }}>
                  {/* <div className="event-timeline-dot">
                    <i className="bx bx-right-arrow-circle mt-2"></i>
                  </div> */}
                  <Media>
                    {/* <div className="mr-3">
                      <i className="mdi mdi-bank h3 text-success"></i>
                    </div> */}
                    <Media body>
                      <div className="mt-1">
                        <span className="badge badge-pill badge-soft-success font-size-13">
                          Complete ({completeCount})
                        </span>
                      </div>
                    </Media>
                  </Media>
                </li>
              </ul>
            </Col>
            <Col sm="6">
              <div className="mt-4 mt-sm-0">
                <ReactApexChart
                  options={options}
                  series={series}
                  type="donut"
                // height="150"
                />
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { invoiceCountList } = state.Invoice;
  return { invoiceCountList };
};

export default connect(mapStatetoProps, {
  getInvoiceCountForSchoolId,
})(withRouter(withNamespaces()(PaymentAnalytics)));
