import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Alert,
  Button,
  CardTitle,
  Container,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  ModalHeader,
  ModalBody,
  Progress,
  CustomInput,
} from "reactstrap";
import Dropzone from "react-dropzone";
import Parse from "parse";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import moment from "moment";
import * as Scroll from "react-scroll";
import toastr from "toastr";
import UploadFiles from "../../components/Common/UploadFiles";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import classnames from "classnames";
import UpdatePhoto from "../../components/Common/UpdatePhoto";
import TopBar from "./Topbar";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";

import * as Constants from "../../Constents";

import { setSelecetdSchool } from "../../store/actions";
import { change } from "redux-form";

const PaymentGateway = (props) => {
  const next = "NEXT";
  const previous = "PREVIOUS";

  const match = matchPath(props.history.location.pathname, {
    path: "/paymentgatewaykyc/:param",
    exact: true,
    strict: false,
  });

  //var schoolId = matchInsideDashboard && matchInsideDashboard.params.schoolId;
  //var schoolId = localStorage.getItem(Constants.SELECTED_SCHOOL_ID);

  const schoolId = match.params.param;
  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    getSavedForm();
  }, [schoolId]);
  const [activeTab, setactiveTab] = useState(0);
  const [progressValue, setprogressValue] = useState(33.33);
  const [resultFormObj, setResultFormObj] = useState(undefined);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [cardMessage, setCardMessage] = useState("");
  const [cardIcon, setCardIcon] = useState("fa fa-check text-success mr-2");
  const [formIsSubmites, formIsSubmitesOps] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  //Contact Person Info

  const [contactPersonName, setContactPersonName] = useState(undefined);
  const [contactPersonPhone, setContactPersonPhone] = useState(undefined);
  const [contactPersonEmail, setContactPersonEmail] = useState(undefined);

  const [contactPersonNameError, setContactPersonNameError] =
    useState(undefined);
  const [contactPersonPhoneError, setContactPersonPhoneError] =
    useState(undefined);
  const [contactPersonEmailError, setContactPersonEmailError] =
    useState(undefined);

  //Owner/Partners/directors Info
  const [ownerName, setOwnerName] = useState(undefined);
  const [ownerPancardNumber, setOwnerPancardNumber] = useState(undefined);
  const [ownerDOB, setOwnerDOB] = useState(undefined);
  const [ownerResidentialAddress, setOwnerResidentialAddress] =
    useState(undefined);
  const [ownerPancard, setOwnerPancard] = useState(undefined);
  const [ownerAddressProofName, setOwnerAddressProofName] =
    useState("Aadhar Card");
  const [ownerAddressProofDoc, setOwnerAddressProofDoc] = useState(undefined);

  const [ownerNameError, setOwnerNameError] = useState(false);
  const [ownerPancardNumberError, setOwnerPancardNumberError] = useState(false);
  const [ownerDOBError, setOwnerDOBError] = useState(false);
  const [ownerResidentialAddressError, setOwnerResidentialAddressError] =
    useState(false);
  const [ownerPancardError, setOwnerPancardError] = useState(false);
  const [ownerAddressProofNameError, setOwnerAddressProofNameError] =
    useState("Aadhar Card");
  const [ownerAddressProofDocError, setOwnerAddressProofDocError] =
    useState(false);

  //Business Info
  const [businessLegalNameOfCompany, setBusinessLegalNameOfCompany] =
    useState(undefined);
  const [
    businessCompanyRegistrationNumber,
    setBusinessCompanyRegistrationNumber,
  ] = useState(undefined);

  const [businessCompanyPanNumber, setBusinessCompanyPanNumber] =
    useState(undefined);
  const [businessGSTRegistrationNumber, setBusinessGSTRegistrationNumber] =
    useState(undefined);
  const [businessGSTRegistrationState, setBusinessGSTRegistrationState] =
    useState(undefined);

  const [businessLegalStatusOfMerchant, setBusinessLegalStatusOfMerchant] =
    useState("Company");
  const [businessNatureOfBusiness, setBusinessNatureOfBusiness] =
    useState(undefined);
  const [businessMerchantWebsite, setBusinessMerchantWebsite] =
    useState(undefined);
  const [
    businessOperationalOfficeOfMerchant,
    setBusinessOperationalOfficeOfMerchant,
  ] = useState(undefined);

  const [businessPancardDoc, setBusinessPancardDoc] = useState(undefined);
  const [businessProofName, setBusinessProofName] = useState(
    "Manipal Registration"
  );
  const [businessProofDoc, setBusinessProofDoc] = useState(undefined);
  const [businessLLPProofName, setBusinessLLPProofName] = useState(
    "LLP Incorporation Letter"
  );
  const [businessLLPProofDoc, setBusinessLLPProofDoc] = useState(undefined);

  const [businessGSTRegistrationName, setBusinessGSTRegistrationName] =
    useState("GST Certificate");

  const [businessGSTRegistrationDoc, setBusinessGSTRegistrationDoc] =
    useState(undefined);

  const [businessLegalNameOfCompanyError, setBusinessLegalNameOfCompanyError] =
    useState(undefined);
  const [
    businessCompanyRegistrationNumberError,
    setBusinessCompanyRegistrationNumberError,
  ] = useState(undefined);

  const [businessCompanyPanNumberError, setBusinessCompanyPanNumberError] =
    useState(undefined);
  const [
    businessGSTRegistrationNumberError,
    setBusinessGSTRegistrationNumberError,
  ] = useState(undefined);
  const [
    businessGSTRegistrationStateError,
    setBusinessGSTRegistrationStateError,
  ] = useState(undefined);

  const [
    businessLegalStatusOfMerchantError,
    setBusinessLegalStatusOfMerchantError,
  ] = useState(undefined);
  const [businessNatureOfBusinessError, setBusinessNatureOfBusinessError] =
    useState(undefined);
  const [businessMerchantWebsiteError, setBusinessMerchantWebsiteError] =
    useState(undefined);
  const [
    businessOperationalOfficeOfMerchantError,
    setBusinessOperationalOfficeOfMerchantError,
  ] = useState(undefined);

  const [businessPancardDocError, setBusinessPancardDocError] =
    useState(undefined);

  const [businessProofDocError, setBusinessProofDocError] = useState(undefined);

  const [businessLLPProofDocError, setBusinessLLPProofDocError] =
    useState(undefined);

  const [businessGSTRegistrationDocError, setBusinessGSTRegistrationDocError] =
    useState(undefined);

  //Bank Info
  const [bankAccountName, setBankAccountName] = useState(undefined);
  const [bankAccountNumber, setBankAccountNumber] = useState(undefined);
  const [bankIFSCCode, setBankIFSCCode] = useState(undefined);
  const [bankName, setBankName] = useState(undefined);
  const [bankAccountType, setBankAccountType] = useState(undefined);
  const [
    bankCurrentAccountNumberCertificationName,
    setBankCurrentAccountNumberCertificationName,
  ] = useState("Copy of Cancelled Cheque");
  const [
    bankCurrentAccountNumberCertificationDoc,
    setBankCurrentAccountNumberCertificationDoc,
  ] = useState(undefined);
  const [bankCACertifiedIncomeProofName, setBankCACertifiedIncomeProofName] =
    useState("Last year of ITR Copy");
  const [bankCACertifiedIncomeProofDoc, setBankCACertifiedIncomeProofDoc] =
    useState(undefined);

  const [bankAccountNameError, setBankAccountNameError] = useState(undefined);
  const [bankAccountNumberError, setBankAccountNumberError] =
    useState(undefined);
  const [bankIFSCCodeError, setBankIFSCCodeError] = useState(undefined);
  const [bankNameError, setBankNameError] = useState(undefined);
  const [bankAccountTypeError, setBankAccountTypeError] = useState(undefined);
  const [
    bankCurrentAccountNumberCertificationNameError,
    setBankCurrentAccountNumberCertificationNameError,
  ] = useState(undefined);
  const [
    bankCurrentAccountNumberCertificationDocError,
    setBankCurrentAccountNumberCertificationDocError,
  ] = useState(undefined);
  const [
    bankCACertifiedIncomeProofNameError,
    setBankCACertifiedIncomeProofNameError,
  ] = useState(undefined);
  const [
    bankCACertifiedIncomeProofDocError,
    setBankCACertifiedIncomeProofDocError,
  ] = useState(undefined);

  const [
    isBusinessGSTRegistrationNumberIsMandatory,
    setIsBusinessGSTRegistrationNumberIsMandatory,
  ] = useState(true);
  const [
    isBusinessGSTRegistrationStateIsMandatory,
    setIsBusinessGSTRegistrationStateIsMandatory,
  ] = useState(true);
  const [
    isBusinessGSTRegistrationDownloadHidden,
    setIsBusinessGSTRegistrationDownloadHidden,
  ] = useState(true);

  function formatDate(d) {
    if (d == null || d === "" || typeof d === "string" || d instanceof String) {
      return "";
    } else {
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      return `${ye}-${mo}-${da}`;
    }
  }

  const getSavedForm = () => {
    var query = new Parse.Query("GatewayKYC");
    query.equalTo("schoolId", schoolId);

    query.first().then((result) => {
      //setIsLoading(false);

      if (result != null) {
        setResultFormObj(result);
        setContactPersonName(result.attributes.contactPersonName);
        setContactPersonEmail(result.attributes.contactPersonEmail);
        setContactPersonPhone(result.attributes.contactPersonPhone);
        setOwnerName(result.attributes.ownerName);
        setOwnerPancardNumber(result.attributes.ownerPancardNumber);
        setOwnerDOB(result.attributes.ownerDOB);
        setOwnerResidentialAddress(result.attributes.ownerResidentialAddress);
        setOwnerAddressProofName(result.attributes.ownerAddressProofName);
        setOwnerPancard(result.attributes.ownerPancard);
        setOwnerAddressProofDoc(result.attributes.ownerAddressProofDocument);
        setBusinessLegalNameOfCompany(
          result.attributes.businessLegalNameOfCompany
        );
        setBusinessCompanyRegistrationNumber(
          result.attributes.businessCompanyRegistrationNumber
        );
        setBusinessCompanyPanNumber(result.attributes.businessCompanyPanNumber);
        setBusinessGSTRegistrationNumber(
          result.attributes.businessGSTRegistrationNumber
        );
        setBusinessGSTRegistrationState(
          result.attributes.businessGSTRegistrationState
        );
        setBusinessLegalStatusOfMerchant(
          result.attributes.businessLegalStatusOfMerchant
        );
        setBusinessNatureOfBusiness(result.attributes.businessNatureOfBusiness);
        setBusinessMerchantWebsite(result.attributes.businessMerchantWebsite);
        setBusinessOperationalOfficeOfMerchant(
          result.attributes.businessOperationalOfficeOfMerchant
        );
        setBusinessPancardDoc(result.attributes.businessPanCardDoc);
        setBusinessProofName(result.attributes.businessProofName);
        setBusinessProofDoc(result.attributes.businessProofDoc);
        setBusinessLLPProofName(result.attributes.businessLLPProofName);
        setBusinessLLPProofDoc(result.attributes.businessLLPProofDoc);

        if (
          result.attributes.businessGSTRegistrationName === "GST Certificate"
        ) {
          setIsBusinessGSTRegistrationDownloadHidden(true);
          setIsBusinessGSTRegistrationNumberIsMandatory(true);
          setIsBusinessGSTRegistrationStateIsMandatory(true);
        } else {
          setIsBusinessGSTRegistrationDownloadHidden(false);
          setIsBusinessGSTRegistrationNumberIsMandatory(false);
          setIsBusinessGSTRegistrationStateIsMandatory(false);
        }
        setBusinessGSTRegistrationName(
          result.attributes.businessGSTRegistrationName
        );
        setBusinessGSTRegistrationDoc(
          result.attributes.businessGSTRegistrationDoc
        );
        setBankAccountName(result.attributes.bankAccountName);
        setBankAccountNumber(result.attributes.bankAccountNumber);
        setBankIFSCCode(result.attributes.bankIFSCCode);
        setBankName(result.attributes.bankName);
        setBankAccountType(result.attributes.bankAccountType);
        setBankCurrentAccountNumberCertificationName(
          result.attributes.bankCurrentAccountNumberCertificationName
        );
        setBankCurrentAccountNumberCertificationDoc(
          result.attributes.bankCurrentAccountNumberCertificationDoc
        );
        setBankCACertifiedIncomeProofName(
          result.attributes.bankCACertifiedIncomeProofName
        );
        setBankCACertifiedIncomeProofDoc(
          result.attributes.bankCACertifiedIncomeProofDoc
        );
      }
      setIsLoading(false);
    });
  };

  const toggleTab = (tab, choice) => {
    let tempTab = activeTab;
    if (tab !== activeTab) {
      // if (tab - activeTab === 1 || tab - activeTab === -1) {
      // } else {
      //   if (choice === next) {
      //     tab = activeTab + 1;
      //   } else if (choice === previous) {
      //     tab = activeTab - 1;
      //   }
      // }

      if (choice === next) {
        var canProceedToNextScreen = true;

        // if (tempTab === 0) {
        //   canProceedToNextScreen = verifyPersonalInfo();
        // } else if (tempTab === 1) {
        //   canProceedToNextScreen = verifyBusinessInfo();
        // } else if (tempTab === 2) {
        //   canProceedToNextScreen = verifyBankInfo();
        // }

        if (canProceedToNextScreen) {
          if (activeTab !== tab) {
            if (tab >= 0 && tab <= 2) {
              scroller.scrollTo("topScrollArea", {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page
              });
              setactiveTab(tab);

              var tempRatio = 100 / 3;
              var tempMultiplier = tab + 1;
              var tempProgress = tempMultiplier * tempRatio;
              setprogressValue(tempProgress);
            }
          }
        } else {
        }
      } else {
        if (activeTab !== tab) {
          if (tab >= 0 && tab <= 2) {
            scroller.scrollTo("topScrollArea", {
              duration: 500,
              delay: 100,
              smooth: true,
              offset: -100, // Scrolls to element + 50 pixels down the page
            });
            setactiveTab(tab);
            var tempRatio = 100 / 3;
            var tempMultiplier = tab + 1;
            var tempProgress = tempMultiplier * tempRatio;
            setprogressValue(tempProgress);
          }
        }
      }
    }
  };

  // const handleAcceptedFiles = (files) => {
  //   files.map((file) =>
  //     Object.assign(file, {
  //       preview: URL.createObjectURL(file),
  //       formattedSize: formatBytes(file.size),
  //     })
  //   );

  //   setSelectedFiles(files);
  // };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };

  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  const verifyBankInfo = () => {
    var result = true;
    if (!bankAccountName || bankAccountName.trim().length === 0) {
      setBankAccountNameError(true);
      result = false;
    }
    if (!bankAccountNumber || bankAccountNumber.trim().length === 0) {
      setBankAccountNumberError(true);
      result = false;
    }
    if (!bankIFSCCode || bankIFSCCode.trim().length === 0) {
      setBankIFSCCodeError(true);
      result = false;
    }
    if (!bankName || bankName.trim().length === 0) {
      setBankNameError(true);
      result = false;
    }
    if (!bankAccountType || bankAccountType.trim().length === 0) {
      setBankAccountTypeError(true);
      result = false;
    }

    if (!bankCurrentAccountNumberCertificationDoc) {
      setBankCurrentAccountNumberCertificationDocError(true);
      result = false;
    }

    return result;
  };

  const verifyBusinessInfo = () => {
    var result = true;
    if (
      !businessLegalNameOfCompany ||
      businessLegalNameOfCompany.trim().length === 0
    ) {
      setBusinessLegalNameOfCompanyError(true);
      result = false;
    }
    if (
      !businessCompanyRegistrationNumber ||
      businessCompanyRegistrationNumber.trim().length === 0
    ) {
      setBusinessCompanyRegistrationNumberError(true);
      result = false;
    }
    if (
      !businessCompanyPanNumber ||
      businessCompanyPanNumber.trim().length === 0
    ) {
      setBusinessCompanyPanNumberError(true);
      result = false;
    }

    if (
      (!businessGSTRegistrationNumber ||
        businessGSTRegistrationNumber.trim().length === 0) &&
      isBusinessGSTRegistrationNumberIsMandatory
    ) {
      setBusinessGSTRegistrationNumberError(true);
      result = false;
    }
    if (
      (!businessGSTRegistrationState ||
        businessGSTRegistrationState.trim().length === 0) &&
      isBusinessGSTRegistrationStateIsMandatory
    ) {
      setBusinessGSTRegistrationStateError(true);
      result = false;
    }

    if (
      !businessLegalStatusOfMerchant ||
      businessLegalStatusOfMerchant.trim().length === 0
    ) {
      setBusinessLegalStatusOfMerchantError(true);
      result = false;
    }
    if (
      !businessNatureOfBusiness ||
      businessNatureOfBusiness.trim().length === 0
    ) {
      setBusinessNatureOfBusinessError(true);
      result = false;
    }
    if (
      !businessMerchantWebsite ||
      businessMerchantWebsite.trim().length === 0
    ) {
      setBusinessMerchantWebsiteError(true);
      result = false;
    }
    if (
      !businessOperationalOfficeOfMerchant ||
      businessOperationalOfficeOfMerchant.trim().length === 0
    ) {
      setBusinessOperationalOfficeOfMerchantError(true);
      result = false;
    }

    if (!businessPancardDoc) {
      setBusinessPancardDocError(true);
      result = false;
    }
    if (!businessProofDoc) {
      setBusinessProofDocError(true);
      result = false;
    }

    if (!businessGSTRegistrationDoc) {
      setBusinessGSTRegistrationDocError(true);
      result = false;
    }

    return result;
  };
  const verifyPersonalInfo = () => {
    var result = true;
    if (!contactPersonName || contactPersonName.trim().length === 0) {
      setContactPersonNameError(true);
      result = false;
    }
    if (!contactPersonEmail || contactPersonEmail.trim().length === 0) {
      setContactPersonEmailError(true);
      result = false;
    }
    if (!contactPersonPhone || contactPersonPhone.trim().length === 0) {
      setContactPersonPhoneError(true);
      result = false;
    }
    if (!ownerName || ownerName.trim().length === 0) {
      setOwnerNameError(true);
      result = false;
    }
    if (!ownerPancardNumber || ownerPancardNumber.trim().length === 0) {
      setOwnerPancardNumberError(true);
      result = false;
    }

    if (!ownerDOB) {
      setOwnerDOBError(true);
      result = false;
    }
    if (
      !ownerResidentialAddress ||
      ownerResidentialAddress.trim().length === 0
    ) {
      setOwnerResidentialAddressError(true);
      result = false;
    }
    if (!ownerPancard) {
      setOwnerPancardError(true);
      result = false;
    }
    if (!ownerAddressProofName || ownerAddressProofName.trim().length === 0) {
      setOwnerAddressProofNameError(true);
      result = false;
    }
    if (!ownerAddressProofDoc) {
      setOwnerAddressProofDocError(true);
      result = false;
    }

    return result;
  };

  const submitKYC = (shouldSave) => {
    let canProceed = true;

    canProceed = verifyPersonalInfo();
    if (!canProceed && !shouldSave) {
      toastr.warning(
        "Mandatory fields needs to be filled in Personal Info before submitting the form",
        { timeOut: 2000 }
      );
      return;
    }
    canProceed = verifyBusinessInfo();

    if (!canProceed && !shouldSave) {
      toastr.warning(
        "Mandatory fields needs to be filled in Business Info before submitting the form",
        { timeOut: 2000 }
      );
      return;
    }

    canProceed = verifyBankInfo();
    if (!canProceed && !shouldSave) {
      toastr.warning(
        "Mandatory fields needs to be filled in Bank Info before submitting the form",
        { timeOut: 2000 }
      );
      return;
    }

    if (canProceed || shouldSave) {
      let kyc = undefined;
      if (resultFormObj) {
        kyc = resultFormObj;
      } else {
        var GatewayKYC = Parse.Object.extend("GatewayKYC");
        kyc = new GatewayKYC();
      }
      formIsSubmitesOps(true);

      kyc.set("contactPersonName", contactPersonName);
      kyc.set("contactPersonPhone", contactPersonPhone);
      kyc.set("contactPersonEmail", contactPersonEmail);

      kyc.set("ownerName", ownerName);
      kyc.set("ownerPancardNumber", ownerPancardNumber);
      if (ownerDOB) {
        kyc.set("ownerDOB", new Date(ownerDOB));
      } else {
        kyc.set("ownerDOB", undefined);
      }
      kyc.set("ownerResidentialAddress", ownerResidentialAddress);
      kyc.set("ownerAddressProofName", ownerAddressProofName);
      kyc.set("ownerPancard", ownerPancard);
      kyc.set("ownerAddressProofDocument", ownerAddressProofDoc);

      kyc.set("businessLegalNameOfCompany", businessLegalNameOfCompany);
      kyc.set(
        "businessCompanyRegistrationNumber",
        businessCompanyRegistrationNumber
      );
      kyc.set("businessCompanyPanNumber", businessCompanyPanNumber);

      kyc.set("businessGSTRegistrationNumber", businessGSTRegistrationNumber);
      kyc.set("businessGSTRegistrationState", businessGSTRegistrationState);
      kyc.set("businessLegalStatusOfMerchant", businessLegalStatusOfMerchant);
      kyc.set("businessNatureOfBusiness", businessNatureOfBusiness);
      kyc.set("businessMerchantWebsite", businessMerchantWebsite);
      kyc.set(
        "businessOperationalOfficeOfMerchant",
        businessOperationalOfficeOfMerchant
      );
      kyc.set("businessPanCardDoc", businessPancardDoc);

      kyc.set("businessProofName", businessProofName);
      kyc.set("businessProofDoc", businessProofDoc);
      kyc.set("businessLLPProofName", businessLLPProofName);
      kyc.set("businessLLPProofDoc", businessLLPProofDoc);

      kyc.set("businessGSTRegistrationName", businessGSTRegistrationName);
      kyc.set("businessGSTRegistrationDoc", businessGSTRegistrationDoc);

      kyc.set("bankAccountName", bankAccountName);
      kyc.set("bankAccountNumber", bankAccountNumber);
      kyc.set("bankIFSCCode", bankIFSCCode);
      kyc.set("bankName", bankName);
      kyc.set("bankAccountType", bankAccountType);
      kyc.set(
        "bankCurrentAccountNumberCertificationName",
        bankCurrentAccountNumberCertificationName
      );
      kyc.set(
        "bankCurrentAccountNumberCertificationDoc",
        bankCurrentAccountNumberCertificationDoc
      );
      kyc.set("bankCACertifiedIncomeProofName", bankCACertifiedIncomeProofName);
      kyc.set("bankCACertifiedIncomeProofDoc", bankCACertifiedIncomeProofDoc);

      kyc.set("schoolId", schoolId);

      if (shouldSave === true) {
        kyc.set("status", 0);
      } else {
        kyc.set("status", 1);
        if (Parse.User.current().attributes.teacherId) {
          kyc.set("submittedBy", Parse.User.current().attributes.teacherId);
        } else {
          kyc.set("submittedBy", Parse.User.current().id);
        }
      }
      let groupACL = new Parse.ACL();
      groupACL.setRoleWriteAccess(schoolId + "_Admin", true);
      groupACL.setRoleReadAccess(schoolId + "_Admin", true);

      kyc.setACL(groupACL);

      kyc.save().then(
        (result) => {
          if (shouldSave) {
            toastr.success("Successfully saved the form !!", { timeOut: 2000 });
            formIsSubmitesOps(false);
          } else {
            setCardMessage("Successfully submitted the form !!");
            setIsFormSubmitted(true);
          }
        },
        (error) => {
          if (shouldSave) {
            toastr.success("Successfully saved the form !!", { timeOut: 2000 });
            formIsSubmitesOps(false);
          }
        }
      );
    }
  };

  return (
    <React.Fragment>
      <Container fluid={true} className={"page-content"}>
        {/* {
          !isFormSubmitted && !isLoading && (
            <TopBar
          title={"Exam Setup"}
          buttonName={""}
          backButton={true}
          hideButton={true}
          onClickAdd={() => {}}
          onClickBackButton={() => {
            window.history.back();
          }}
        />
          )
        } */}
        {!isFormSubmitted && !isLoading && (
          <Card>
            <CardBody>
              <Element name="topScrollArea">
                <TopBar
                  title={"KYC"}
                  buttonName={""}
                  backButton={true}
                  hideButton={true}
                  onClickAdd={() => {}}
                  onClickBackButton={() => {
                    window.history.back();
                  }}
                />
              </Element>
              <div>
                <div id="kyc-verify-wizard" className="twitter-bs-wizard">
                  <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 0,
                        })}
                        onClick={() => {
                          0 > activeTab
                            ? toggleTab(0, next)
                            : toggleTab(0, previous);
                        }}
                      >
                        <span className="step-number mr-2">01</span>
                        Contact Person Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 1,
                        })}
                        onClick={() => {
                          1 > activeTab
                            ? toggleTab(1, next)
                            : toggleTab(1, previous);
                        }}
                      >
                        <span className="step-number mr-2">02</span>
                        Business Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        className={classnames({
                          active: activeTab === 2,
                        })}
                        onClick={() => {
                          2 > activeTab
                            ? toggleTab(2, next)
                            : toggleTab(2, previous);
                        }}
                      >
                        <span className="step-number mr-2">03</span>
                        Bank Details
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === 4,
                      })}
                      onClick={() => {
                        toggleTab(4);
                      }}
                    >
                      <span className="step-number mr-2">04</span>
                      Document Verification
                    </NavLink>
                  </NavItem> */}
                  </ul>
                  <div id="bar" className="mt-4">
                    <Progress
                      color="success"
                      striped
                      animated
                      value={progressValue}
                    />
                    <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                  </div>
                  <TabContent
                    activeTab={activeTab}
                    className="twitter-bs-wizard-tab-content"
                  >
                    <TabPane tabId={0} id="personal-info">
                      <AvForm>
                        <Row>
                          <Col lg="12" md="12" sm="12" xs="12">
                            <FormGroup>
                              <Label style={{ fontSize: "16px" }}>
                                Contact Person Info :
                              </Label>
                              <hr />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-contactPersonName">Name</Label>

                              <Label
                                htmlFor="kyc-contactPersonName"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {contactPersonNameError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-contactPersonName"
                                placeholder="Enter Name"
                                value={contactPersonName}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setContactPersonNameError(true);
                                  } else {
                                    setContactPersonNameError(false);
                                  }
                                  setContactPersonName(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-contactPersonPhone">Phone</Label>
                              <Label
                                htmlFor="kyc-contactPersonPhone"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {contactPersonPhoneError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-contactPersonPhone"
                                placeholder="Enter Phone number"
                                value={contactPersonPhone}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setContactPersonPhoneError(true);
                                  } else {
                                    setContactPersonPhoneError(false);
                                  }
                                  setContactPersonPhone(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-contactPersonEmail">Email</Label>
                              <Label
                                htmlFor="kyc-contactPersonEmail"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {contactPersonEmailError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-contactPersonEmail"
                                placeholder="Enter Email Id"
                                value={contactPersonEmail}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setContactPersonEmailError(true);
                                  } else {
                                    setContactPersonEmailError(false);
                                  }
                                  setContactPersonEmail(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row className="mt-3">
                          <Col lg="12" md="12" sm="12" xs="12">
                            <FormGroup>
                              <Label style={{ fontSize: "16px" }}>
                                Detail of the Partners / Proprietor / Directors:
                                (Trustee)
                              </Label>
                              <hr />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-ownerName">Name</Label>
                              <Label
                                htmlFor="kyc-ownerName"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {ownerNameError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-ownerName"
                                placeholder="Enter Name"
                                value={ownerName}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setOwnerNameError(true);
                                  } else {
                                    setOwnerNameError(false);
                                  }
                                  setOwnerName(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-ownerPancardNumber">
                                Pan Card No.
                              </Label>
                              <Label
                                htmlFor="kyc-ownerPancardNumber"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {ownerPancardNumberError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-ownerPancardNumber"
                                placeholder="Enter Pan Card Number"
                                value={ownerPancardNumber}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setOwnerPancardNumberError(true);
                                  } else {
                                    setOwnerPancardNumberError(false);
                                  }
                                  setOwnerPancardNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label>Date of Birth</Label>
                              <Label
                                htmlFor="kyc-ownerDOB"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {ownerDOBError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}

                              {/* <AvField
                                type="date"
                                name={"kyc-ownerDOB"}
                                className="inner form-control"
                                placeholder="dd/mm/yyyy"
                                value={
                                  ownerDOB ? formatDate(ownerDOB) : undefined
                                }
                                onChange={(e) => {
                                  if (e.target.value == undefined) {
                                    setOwnerDOBError(true);
                                  } else {
                                    setOwnerDOBError(false);
                                  }
                                  setOwnerDOB(new Date(e.target.value));
                                }}
                              /> */}
                              <DatePicker
                                id={"kyc-ownerDOB"}
                                selected={
                                  ownerDOB ? new Date(ownerDOB) : undefined
                                }
                                onChange={(date) => {
                                  if (date == undefined) {
                                    setOwnerDOBError(true);
                                  } else {
                                    setOwnerDOBError(false);
                                  }
                                  setOwnerDOB(new Date(date));
                                }}
                                className="form-control"
                                placeholderText="dd/mm/yyyy"
                                //maxDate={endDate}
                                dateFormat="dd/MM/yyyy"
                                showDayMonthYearPicker
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-ownerResidentialAddress">
                                Residential Address
                              </Label>
                              <Label
                                htmlFor="kyc-ownerResidentialAddress"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {ownerResidentialAddressError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-ownerResidentialAddress"
                                placeholder="Enter Address"
                                value={ownerResidentialAddress}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setOwnerResidentialAddressError(true);
                                  } else {
                                    setOwnerResidentialAddressError(false);
                                  }
                                  setOwnerResidentialAddress(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="12">
                            <FormGroup>
                              <Label for="kyc-ownerPancard">
                                Pan card of owner
                              </Label>
                              <Label
                                htmlFor="kyc-ownerPancard"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {ownerPancardError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <br />
                              {/* <Label style={{color:"red"}}>This document requires school seal and signature</Label> */}
                              <Alert
                                color="danger"
                                role="alert"
                                className="col-6"
                              >
                                This document requires school seal and signature
                              </Alert>
                              <UploadFiles
                                fileUploadKey={"kyc-ownerPancard"}
                                initialFile={
                                  ownerPancard ? ownerPancard : undefined
                                }
                                onFileChange={(file) => {
                                  if (file == undefined) {
                                    setOwnerPancardError(true);
                                  } else {
                                    setOwnerPancardError(false);
                                  }
                                  setOwnerPancard(file ? file.file : undefined);
                                }}
                              ></UploadFiles>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="kyc-ownerAddressProofName">
                                Address proof
                              </Label>
                              <Label
                                htmlFor="kyc-ownerAddressProofName"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {ownerAddressProofDocError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Alert
                                color="danger"
                                role="alert"
                                className="col-6"
                              >
                                This document requires school seal and signature
                              </Alert>

                              <select
                                className="custom-select col-lg-6 mb-2"
                                id="kyc-ownerAddressProofName"
                                value={ownerAddressProofName}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setOwnerAddressProofNameError(true);
                                  } else {
                                    setOwnerAddressProofNameError(false);
                                  }
                                  setOwnerAddressProofName(e.target.value);
                                }}
                              >
                                <option value="Aadhar Card" defaultValue>
                                  Aadhar Card
                                </option>
                                <option value="Passport">Passport</option>
                                <option value="Phone bill">Phone bill</option>
                                <option value="Electricity bill in name of owner">
                                  Electricity bill in name of owner
                                </option>
                                <option value="Permanent Driving License">
                                  Permanent Driving License
                                </option>
                                <option value="Voter Id Card">
                                  Voter Id Card
                                </option>
                              </select>

                              <UploadFiles
                                fileUploadKey={"kyc-ownerAddressProof"}
                                initialFile={
                                  ownerAddressProofDoc
                                    ? ownerAddressProofDoc
                                    : undefined
                                }
                                onFileChange={(file) => {
                                  if (file == undefined) {
                                    setOwnerAddressProofDocError(true);
                                  } else {
                                    setOwnerAddressProofDocError(false);
                                  }
                                  setOwnerAddressProofDoc(
                                    file ? file.file : undefined
                                  );
                                }}
                              ></UploadFiles>
                            </FormGroup>
                          </Col>
                        </Row>
                      </AvForm>
                    </TabPane>
                    <TabPane tabId={1} id="business-info">
                      <AvForm>
                        <Row>
                          <Col lg="12" md="12" sm="12" xs="12">
                            <FormGroup>
                              <Label style={{ fontSize: "16px" }}>
                                Business Info :
                              </Label>
                              <u><p> If information is not applicable or not available please fill NA</p></u>
                              <hr />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-legalNameOfCompany">
                                Legal Name of Company
                              </Label>
                              <Label
                                htmlFor="kyc-legalNameOfCompany"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessLegalNameOfCompanyError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-legalNameOfCompany"
                                placeholder="Enter Legal Name of Company"
                                value={businessLegalNameOfCompany}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessLegalNameOfCompanyError(true);
                                  } else {
                                    setBusinessLegalNameOfCompanyError(false);
                                  }
                                  setBusinessLegalNameOfCompany(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-companyRegistrationNumber">
                                Company Registration Number
                              </Label>
                              <Label
                                htmlFor="kyc-companyRegistrationNumber"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessCompanyRegistrationNumberError ===
                                true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-companyRegistrationNumber"
                                placeholder="Enter company registration number"
                                value={businessCompanyRegistrationNumber}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessCompanyRegistrationNumberError(
                                      true
                                    );
                                  } else {
                                    setBusinessCompanyRegistrationNumberError(
                                      false
                                    );
                                  }
                                  setBusinessCompanyRegistrationNumber(
                                    e.target.value
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-companyPanNumber">
                                Company Pan Number
                              </Label>
                              <Label
                                htmlFor="kyc-companyPanNumber"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessCompanyPanNumberError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-companyPanNumber"
                                placeholder="Enter company pan number"
                                value={businessCompanyPanNumber}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessCompanyPanNumberError(true);
                                  } else {
                                    setBusinessCompanyPanNumberError(false);
                                  }
                                  setBusinessCompanyPanNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-gstRegistrationNumber">
                                GST Registration Number
                              </Label>
                              {isBusinessGSTRegistrationNumberIsMandatory && (
                                <Label
                                  htmlFor="kyc-gstRegistrationNumber"
                                  className="text-danger ml-1"
                                >
                                  *
                                </Label>
                              )}

                              {businessGSTRegistrationNumberError === true &&
                                isBusinessGSTRegistrationNumberIsMandatory && (
                                  <Label className="label-xsmall text-danger ml-2">
                                    (this field is mandatory)
                                  </Label>
                                )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-gstRegistrationNumber"
                                placeholder="Enter GST Registration Number"
                                value={businessGSTRegistrationNumber}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessGSTRegistrationNumberError(true);
                                  } else {
                                    setBusinessGSTRegistrationNumberError(
                                      false
                                    );
                                  }
                                  setBusinessGSTRegistrationNumber(
                                    e.target.value
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-gstRegistrationState">
                                GST Registration State
                              </Label>
                              {isBusinessGSTRegistrationStateIsMandatory && (
                                <Label
                                  htmlFor="kyc-gstRegistrationState"
                                  className="text-danger ml-1"
                                >
                                  *
                                </Label>
                              )}

                              {businessGSTRegistrationStateError === true &&
                                isBusinessGSTRegistrationStateIsMandatory && (
                                  <Label className="label-xsmall text-danger ml-2">
                                    (this field is mandatory)
                                  </Label>
                                )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-gstRegistrationState"
                                placeholder="Enter GST Registration State"
                                value={businessGSTRegistrationState}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessGSTRegistrationStateError(true);
                                  } else {
                                    setBusinessGSTRegistrationStateError(false);
                                  }
                                  setBusinessGSTRegistrationState(
                                    e.target.value
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-legalStatusOfMerchant">
                                Legal Status of Merchant
                              </Label>
                              <Label
                                htmlFor="kyc-legalStatusOfMerchant"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessLegalStatusOfMerchantError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}

                              <select
                                className="custom-select"
                                id="kyc-legalStatusOfMerchant"
                                value={businessLegalStatusOfMerchant}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessLegalStatusOfMerchantError(true);
                                  } else {
                                    setBusinessLegalStatusOfMerchantError(
                                      false
                                    );
                                  }
                                  setBusinessLegalStatusOfMerchant(
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="Company" defaultValue>
                                  Company
                                </option>
                                <option value="Partnership Firm">
                                  Partnership Firm
                                </option>
                                <option value="Proprietorship">
                                  Proprietorship
                                </option>
                                <option value="Trust">Trust</option>
                              </select>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-natureOfBusiness">
                                Nature of Business
                              </Label>
                              <Label
                                htmlFor="kyc-natureOfBusiness"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessNatureOfBusinessError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-natureOfBusiness"
                                placeholder="Enter Nature of Business"
                                value={businessNatureOfBusiness}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessNatureOfBusinessError(true);
                                  } else {
                                    setBusinessNatureOfBusinessError(false);
                                  }
                                  setBusinessNatureOfBusiness(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-merchantWebsite">
                                Merchant Website
                              </Label>
                              <Label
                                htmlFor="kyc-merchantWebsite"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessMerchantWebsiteError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-merchantWebsite"
                                placeholder="Enter Merchant Website"
                                value={businessMerchantWebsite}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessMerchantWebsiteError(true);
                                  } else {
                                    setBusinessMerchantWebsiteError(false);
                                  }
                                  setBusinessMerchantWebsite(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-operationalOfficeOfMerchant">
                                Operational Office of Merchant
                              </Label>
                              <Label
                                htmlFor="kyc-operationalOfficeOfMerchant"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessOperationalOfficeOfMerchantError ===
                                true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-operationalOfficeOfMerchant"
                                placeholder="Enter Operational Office of Merchant"
                                value={businessOperationalOfficeOfMerchant}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBusinessOperationalOfficeOfMerchantError(
                                      true
                                    );
                                  } else {
                                    setBusinessOperationalOfficeOfMerchantError(
                                      false
                                    );
                                  }
                                  setBusinessOperationalOfficeOfMerchant(
                                    e.target.value
                                  );
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="kyc-businessPancard">
                                Business Pan Card
                              </Label>
                              <Label
                                htmlFor="kyc-businessPancard"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessPancardDocError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Alert
                                color="danger"
                                role="alert"
                                className="col-6"
                              >
                                This document requires school seal and signature
                              </Alert>
                              <UploadFiles
                                fileUploadKey={"kyc-businessPancard"}
                                initialFile={businessPancardDoc}
                                onFileChange={(file) => {
                                  if (file == undefined) {
                                    setBusinessPancardDocError(true);
                                  } else {
                                    setBusinessPancardDocError(false);
                                  }
                                  setBusinessPancardDoc(
                                    file ? file.file : undefined
                                  );
                                }}
                              ></UploadFiles>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="kyc-businessProof">
                                Business proof
                              </Label>
                              <Label
                                htmlFor="kyc-businessProof"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessProofDocError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Alert
                                color="danger"
                                role="alert"
                                className="col-6"
                              >
                                This document requires school seal and signature
                              </Alert>
                              <select
                                className="custom-select col-lg-6 mb-2"
                                id="kyc-businessProof"
                                value={businessProofName}
                                onChange={(e) => {
                                  setBusinessProofName(e.target.value);
                                }}
                              >
                                <option
                                  value="Manipal Registration"
                                  defaultValue
                                >
                                  Manipal Registration
                                </option>
                                <option value="Shop establishment certificate">
                                  Shop establishment certificate
                                </option>
                                <option value="Rent Agreement of Business">
                                  Rent Agreement of Business
                                </option>
                              </select>

                              <UploadFiles
                                fileUploadKey={"kyc-businessProof"}
                                initialFile={businessProofDoc}
                                onFileChange={(file) => {
                                  if (file == undefined) {
                                    setBusinessProofDocError(true);
                                  } else {
                                    setBusinessProofDocError(false);
                                  }
                                  setBusinessProofDoc(
                                    file ? file.file : undefined
                                  );
                                }}
                              ></UploadFiles>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="kyc-llpProof">LLP proof</Label>

                              <Alert
                                color="danger"
                                role="alert"
                                className="col-6"
                              >
                                This document requires school seal and signature
                              </Alert>
                              <select
                                className="custom-select col-lg-6 mb-2"
                                id="kyc-llpProof"
                                value={businessLLPProofName}
                                onChange={(e) => {
                                  setBusinessLLPProofName(e.target.value);
                                }}
                              >
                                <option
                                  value="LLP Incorporation Letter"
                                  defaultValue
                                >
                                  LLP Incorporation Letter
                                </option>
                                <option value="Partnership deed">
                                  Partnership deed
                                </option>
                              </select>

                              <UploadFiles
                                fileUploadKey={"kyc-llpProof"}
                                initialFile={businessLLPProofDoc}
                                onFileChange={(file) => {
                                  setBusinessLLPProofDoc(
                                    file ? file.file : undefined
                                  );
                                }}
                              ></UploadFiles>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="kyc-gstRegistration">
                                GST Registration
                              </Label>
                              <Label
                                htmlFor="kyc-gstRegistration"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {businessGSTRegistrationDocError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Alert
                                color="danger"
                                role="alert"
                                className="col-6"
                              >
                                This document requires school seal and signature
                              </Alert>
                              <select
                                className="custom-select col-lg-6 mb-2"
                                id="kyc-gstRegistration"
                                value={businessGSTRegistrationName}
                                onChange={(e) => {
                                  if (e.target.value === "GST Certificate") {
                                    setIsBusinessGSTRegistrationDownloadHidden(
                                      true
                                    );
                                    setIsBusinessGSTRegistrationNumberIsMandatory(
                                      true
                                    );
                                    setIsBusinessGSTRegistrationStateIsMandatory(
                                      true
                                    );
                                  } else {
                                    setIsBusinessGSTRegistrationDownloadHidden(
                                      false
                                    );
                                    setIsBusinessGSTRegistrationNumberIsMandatory(
                                      false
                                    );
                                    setIsBusinessGSTRegistrationStateIsMandatory(
                                      false
                                    );
                                  }
                                  setBusinessGSTRegistrationName(
                                    e.target.value
                                  );
                                }}
                              >
                                <option value="GST Certificate" defaultValue>
                                  GST Certificate
                                </option>
                                <option value="No GST Declaration Letter">
                                  No GST Declaration Letter
                                </option>
                              </select>

                              <div className="">
                                {!isBusinessGSTRegistrationDownloadHidden && (
                                  // <Button
                                  //   className="btn btn-primary mb-2 "
                                  //   color="primary"
                                  //   onClick={() => {
                                  //     window.open("https://parsefiles.back4app.com/0ZRsOIsU32VP5AhOrDR7nMqrGR7PFkzaSYEPBsNN/20c352b622117874614a2d913185da8c_GST_Declaration_Letter.docx");
                                  //   }}
                                  // >
                                  //   Download
                                  // </Button>

                                  <div>
                                    <div className="mt-3">
                                      <ul className="verti-timeline list-unstyled">
                                        <li className="event-list">
                                          <div className="event-timeline-dot">
                                            <i
                                              className={
                                                "bx bx-right-arrow-circle"
                                              }
                                            ></i>
                                          </div>
                                          <div className="media">
                                            <div className="mr-3">
                                              <i
                                                className={
                                                  "bx bx-download h4 text-primary"
                                                }
                                              ></i>
                                            </div>
                                            <div className="media-body">
                                              <div>
                                                <h6>
                                                  {props.t(
                                                    "Download No GST Declaration Form"
                                                  )}
                                                </h6>
                                                <p className="text-muted">
                                                  {
                                                    "Please download the form, Print and fill all the details (school seal and signature is mandatory)"
                                                  }
                                                </p>
                                              </div>
                                              <button
                                                onClick={() => {
                                                  window.open(
                                                    "https://parsefiles.back4app.com/0ZRsOIsU32VP5AhOrDR7nMqrGR7PFkzaSYEPBsNN/20c352b622117874614a2d913185da8c_GST_Declaration_Letter.docx"
                                                  );
                                                }}
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light "
                                              >
                                                {props.t("Download Template")}
                                              </button>
                                            </div>
                                          </div>
                                        </li>
                                        <li className="event-list">
                                          <div className="event-timeline-dot">
                                            <i
                                              className={
                                                "bx bx-right-arrow-circle"
                                              }
                                            ></i>
                                          </div>
                                          <div className="media">
                                            <div className="mr-3">
                                              <i
                                                className={
                                                  "bx bx-upload h4 text-primary"
                                                }
                                              ></i>
                                            </div>
                                            <div className="media-body">
                                              <div>
                                                <h6>
                                                  {props.t("Scan and Upload")}
                                                </h6>
                                                <p className="text-muted">
                                                  {
                                                    "Please upload the form by clicking on below button"
                                                  }
                                                </p>
                                              </div>
                                              <UploadFiles
                                                fileUploadKey={
                                                  "kyc-gstRegistration"
                                                }
                                                initialFile={
                                                  businessGSTRegistrationDoc
                                                }
                                                onFileChange={(file) => {
                                                  if (file == undefined) {
                                                    setBusinessGSTRegistrationDocError(
                                                      true
                                                    );
                                                  } else {
                                                    setBusinessGSTRegistrationDocError(
                                                      false
                                                    );
                                                  }
                                                  setBusinessGSTRegistrationDoc(
                                                    file ? file.file : undefined
                                                  );
                                                }}
                                              ></UploadFiles>
                                            </div>
                                          </div>
                                        </li>
                                      </ul>
                                    </div>
                                  </div>
                                )}
                                {isBusinessGSTRegistrationDownloadHidden && (
                                  <UploadFiles
                                    fileUploadKey={"kyc-gstRegistration"}
                                    initialFile={businessGSTRegistrationDoc}
                                    onFileChange={(file) => {
                                      if (file == undefined) {
                                        setBusinessGSTRegistrationDocError(
                                          true
                                        );
                                      } else {
                                        setBusinessGSTRegistrationDocError(
                                          false
                                        );
                                      }
                                      setBusinessGSTRegistrationDoc(
                                        file ? file.file : undefined
                                      );
                                    }}
                                  ></UploadFiles>
                                )}
                              </div>
                            </FormGroup>
                          </Col>
                        </Row>
                      </AvForm>
                    </TabPane>
                    <TabPane tabId={2}>
                      <AvForm
                        onSubmit={() => {
                          submitKYC(false);
                        }}
                      >
                        <Row>
                          <Col lg="12" md="12" sm="12" xs="12">
                            <FormGroup>
                              <Label style={{ fontSize: "16px" }}>
                                Bank Account details of Merchant :
                              </Label>
                              <hr />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-bankAccountName">
                                Beneficiary Account Name
                              </Label>
                              <Label
                                htmlFor="kyc-bankAccountName"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {bankAccountNameError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-bankAccountName"
                                placeholder="Enter Account Name"
                                value={bankAccountName}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBankAccountNameError(true);
                                  } else {
                                    setBankAccountNameError(false);
                                  }
                                  setBankAccountName(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-bankAccountNumber">
                                Bank Account No.
                              </Label>
                              <Label
                                htmlFor="kyc-bankAccountNumber"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {bankAccountNumberError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-bankAccountNumber"
                                value={bankAccountNumber}
                                placeholder="Enter Account number"
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBankAccountNumberError(true);
                                  } else {
                                    setBankAccountNumberError(false);
                                  }
                                  setBankAccountNumber(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>

                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-bankIFSCCode">
                                Bank IFSC Code
                              </Label>
                              <Label
                                htmlFor="kyc-bankIFSCCode"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {bankIFSCCodeError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-bankIFSCCode"
                                placeholder="Enter Bank IFSC code"
                                value={bankIFSCCode}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBankIFSCCodeError(true);
                                  } else {
                                    setBankIFSCCodeError(false);
                                  }
                                  setBankIFSCCode(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-bankName">
                                Beneficiary Bank Name
                              </Label>
                              <Label
                                htmlFor="kyc-bankName"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {bankNameError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-bankName"
                                placeholder="Beneficiary Bank Name"
                                value={bankName}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBankNameError(true);
                                  } else {
                                    setBankNameError(false);
                                  }
                                  setBankName(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col lg="6">
                            <FormGroup>
                              <Label for="kyc-bankAccountType">
                                Account Type
                              </Label>
                              <Label
                                htmlFor="kyc-bankAccountType"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {bankAccountTypeError === true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Input
                                type="text"
                                className="form-control"
                                id="kyc-bankAccountType"
                                placeholder="Enter Account type"
                                value={bankAccountType}
                                onChange={(e) => {
                                  if (
                                    e.target.value == undefined ||
                                    e.target.value.length === 0
                                  ) {
                                    setBankAccountTypeError(true);
                                  } else {
                                    setBankAccountTypeError(false);
                                  }
                                  setBankAccountType(e.target.value);
                                }}
                              />
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="kyc-currentAccountNumberCertification">
                                Current Account Number Certification
                              </Label>
                              <Label
                                htmlFor="kyc-currentAccountNumberCertification"
                                className="text-danger ml-1"
                              >
                                *
                              </Label>

                              {bankCurrentAccountNumberCertificationDocError ===
                                true && (
                                <Label className="label-xsmall text-danger ml-2">
                                  (this field is mandatory)
                                </Label>
                              )}
                              <Alert
                                color="danger"
                                role="alert"
                                className="col-6"
                              >
                                This document requires school seal and signature
                              </Alert>
                              <select
                                className="custom-select col-lg-6 mb-2"
                                id="kyc-currentAccountNumberCertification"
                                value={
                                  bankCurrentAccountNumberCertificationName
                                }
                                onChange={(e) => {
                                  setBankCurrentAccountNumberCertificationName(
                                    e.target.value
                                  );
                                }}
                              >
                                <option
                                  value="Copy of Cancelled Cheque"
                                  defaultValue
                                >
                                  Copy of Cancelled Cheque
                                </option>
                                <option value="Bank statement of last month signed and stamped">
                                  Bank statement of last month signed and
                                  stamped
                                </option>
                                <option value="Account confirmation letter, certified by bank(format)">
                                  Account confirmation letter, certified by
                                  bank(format)
                                </option>
                              </select>

                              <UploadFiles
                                fileUploadKey={
                                  "kyc-currentAccountNumberCertification"
                                }
                                initialFile={
                                  bankCurrentAccountNumberCertificationDoc
                                }
                                onFileChange={(file) => {
                                  if (file == undefined) {
                                    setBankCurrentAccountNumberCertificationDocError(
                                      true
                                    );
                                  } else {
                                    setBankCurrentAccountNumberCertificationDocError(
                                      false
                                    );
                                  }
                                  setBankCurrentAccountNumberCertificationDoc(
                                    file ? file.file : undefined
                                  );
                                }}
                              ></UploadFiles>
                            </FormGroup>
                          </Col>
                        </Row>
                        <Row>
                          <Col>
                            <FormGroup>
                              <Label for="kyc-caCertifiedIncomeProof">
                                CA certified income proof
                              </Label>
                              <Alert
                                color="danger"
                                role="alert"
                                className="col-6"
                              >
                                This document requires school seal and signature
                              </Alert>
                              <select
                                value={bankCACertifiedIncomeProofName}
                                className="custom-select col-lg-6 mb-2"
                                id="kyc-caCertifiedIncomeProof"
                                onChange={(e) => {
                                  setBankCACertifiedIncomeProofName(
                                    e.target.value
                                  );
                                }}
                              >
                                <option
                                  value="Last year of ITR Copy"
                                  defaultValue
                                >
                                  Last year of ITR Copy
                                </option>
                                <option value="Net worth certificate">
                                  Net worth certificate
                                </option>
                              </select>

                              <UploadFiles
                                fileUploadKey={"kyc-caCertifiedIncomeProof"}
                                initialFile={bankCACertifiedIncomeProofDoc}
                                onFileChange={(file) => {
                                  setBankCACertifiedIncomeProofDoc(
                                    file ? file.file : undefined
                                  );
                                }}
                              ></UploadFiles>
                            </FormGroup>
                          </Col>
                        </Row>
                        <hr></hr>
                        <div style={{ display: "flex" }}>
                          <Button
                            style={{
                              marginRight: "auto",
                              marginLeft: "auto",
                              width: "180px",
                            }}
                            type="submit"
                            color="primary"
                            disabled={formIsSubmites}
                          >
                            {formIsSubmites && (
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                            )}
                            {props.t("SUBMIT")}
                          </Button>
                        </div>
                        {/* <div style={{ display: "flex" }}>
                          <Button
                            type="submit"
                            color="primary"
                            disabled={formIsSubmites}
                            style={{
                              marginLeft: "auto",
                              marginRight: "auto",
                              width: "180px",
                            }}
                          >
                            {formIsSubmites && (
                              <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                            )}
                            {props.t("SUBMIT")}
                          </Button>
                        </div> */}
                      </AvForm>
                    </TabPane>
                    {/* <TabPane tabId={4} id="doc-verification">
                    <Row>
                      <div className="table-responsive mt-3 ml-2 mr-3">
                        <table className="table table-nowrap table-centered mb-0">
                          <thead className="thead-light">
                            <tr>
                              <th scope="col">{props.t("Document")}</th>
                              <th scope="col">
                                <div style={{ textAlign: "center" }}>
                                  {props.t("")}
                                </div>
                              </th>
                              <th scope="col">
                                <div style={{ textAlign: "center" }}>
                                  {props.t("")}
                                </div>
                              </th>
                              <th scope="col">
                                <div style={{ textAlign: "center" }}>
                                  {props.t("Remarks")}
                                </div>
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {tableList.map((val, key) => (
                              <tr key={key}>
                                <td>{val.docName}</td>
                                <td>
                                  <div style={{ textAlign: "center" }}>
                                    <Button
                                      className="btn btn-primary"
                                      color="primary"
                                    >
                                      Download
                                    </Button>
                                  </div>
                                </td>
                                <td>
                                  <div style={{ textAlign: "center" }}>
                                    <Button
                                      className="btn btn-primary"
                                      color="primary"
                                    >
                                      Upload
                                    </Button>
                                  </div>
                                </td>
                                <td>
                                  <div style={{ textAlign: "center" }}>
                                    {val.remarks}
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </Row>
                  </TabPane> */}
                  </TabContent>
                  <hr />
                  <ul className="pager wizard twitter-bs-wizard-pager-link">
                    <li
                      className={
                        activeTab === 0 ? "previous disabled" : "previous"
                      }
                    >
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab - 1, previous);
                        }}
                      >
                        Previous
                      </Link>
                    </li>
                    <li className={activeTab === 2 ? "next disabled" : "next"}>
                      <Button
                        disabled={formIsSubmites}
                        className="mr-3"
                        onClick={() => {
                          submitKYC(true);
                        }}
                        color="primary"
                      >
                        {formIsSubmites && (
                          <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                        )}
                        {props.t("Save")}
                      </Button>
                      <Link
                        to="#"
                        onClick={() => {
                          toggleTab(activeTab + 1, next);
                        }}
                      >
                        Next
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </CardBody>
          </Card>
        )}
        {isFormSubmitted && !isLoading && (
          <Card className="p-5 mt-2">
            <CardBody>
              {/* <div className="outer-repeater" style={{ border: '1px' }} >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer"> 
                    <h5 ><i className="fa fa-check text-success text-center mr-2

" aria-hidden="true"></i>
                    {cardMessage}</h5>
                  {/* </div>
                </div>
              </div> */}
              <CardTitle className="text-center">
                <i className={cardIcon} aria-hidden="true"></i>
                {cardMessage}
              </CardTitle>
            </CardBody>
          </Card>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading KYC Form...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;

  return { selectedSchool };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
    })(PaymentGateway)
  )
);
