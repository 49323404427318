
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from 'classnames';
import * as Constants from '../../Constents';
import { Link } from "react-router-dom";

const Topbar = (props) => {
    const [searchString, setSearchString] = useState("");
    const [selectedTab, setSelectedTab] = useState(0);
    return (

        <Row>
            <Col lg={4} md={4} xs={12}>
                <h4 className="mb-0 font-size-18">{props.title}</h4>
            </Col>
            <Col lg={8} md={8} xs={12}>
                <div className="  mb-2 mt-sm-0 d-flex justify-content-end form-inline">
                    {props.search && <div className="search-box m-1">
                        <div className="position-relative">
                            <Input type="text" className="form-control border-0" placeholder="Search..."
                                value={searchString}
                                onChange={(e) => {
                                    setSearchString(e.target.value)
                                    props.onSearch(e.target.value)
                                }}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>}

                    {props.addButton && <button
                        className="btn btn-primary waves-effect waves-light m-1"
                        onClick={() => { props.onClickAdd() }}
                        role="button"
                    >
                        {props.addButtonTitle}
                    </button>}

                    {props.navBar && <Nav className="navtab-bg nav-justified  border-1 m-1" pills>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: selectedTab === 0 })}
                                onClick={() => {
                                    setSelectedTab(0)
                                    props.onSelctTab(0)
                                }}
                            >
                                Branchs
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                className={classnames({ active: selectedTab === 1 })}
                                onClick={() => {
                                    setSelectedTab(1)
                                    props.onSelctTab(1)
                                }}
                            >
                                Analytics
                            </NavLink>
                        </NavItem>
                    </Nav>}

                </div>
            </Col>
        </Row>
    );
}
export default Topbar;
