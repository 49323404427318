import React, { useEffect, useState } from 'react';
import { Modal, Container, FormGroup, Label, Button,} from 'reactstrap';

import { connect } from 'react-redux';
import { withRouter,Link } from 'react-router-dom/cjs/react-router-dom.min';
import { withNamespaces } from 'react-i18next';
import Breadcrumbs from './Breadcrumbs';

import AvField from "availity-reactstrap-validation/lib/AvField";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { getArchiveGroupsForSchoolId,saveArchiveGroup,setSelecetdSchool } from '../../store/actions'
import { matchPath } from "react-router";
import ListView from './listView';
import Parse from 'parse'

const Index = props =>{
    const [archiveGroups, setArchiveGroups] = useState(["All Students", "Staff"]);
    const [editArchiveGroupName, setEditArchiveGroupName] = useState("");
    const [modalAddArchiveGroup, setModalAddArchiveGroup] = useState(false);


    const match = matchPath(props.history.location.pathname, {
        path: "/archives/:id",
        exact: true,
        strict: false,
      });
    
    const schoolId = match.params.id;
    useEffect(() => {
        if (! props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if(props.selectedSchool && props.selectedSchool.attributes.schoolType == 10){
            setArchiveGroups(["Participants", "Coordinators"])
        }

    }, [props.selectedSchool,schoolId]);

    useEffect(() => {
        props.getArchiveGroupsForSchoolId(schoolId);
    }, [schoolId]);



    useEffect(() => {
        if (props.archiveGroups) {
            let tempList = []
            let allStudent = {
                name:props.selectedSchool && props.selectedSchool.attributes.schoolType == 10?"All Participants":"All Students",
                link:"archivedetailstudent/"+schoolId
            }
            let allStaff = {
                name: props.selectedSchool && props.selectedSchool.attributes.schoolType == 10?"Coordinators": "All Staff",
                link:"archivedetailstaff/"+schoolId
            }
            tempList.push(allStudent)
            tempList.push(allStaff)
            for (var i = 0;i<props.archiveGroups.length;i++) {
                let tempObject = {
                    name: props.archiveGroups[i].attributes.name,
                    link:"archivedetail/"+schoolId,
                    groupObject: props.archiveGroups[i]

                }
                tempList.push(tempObject)
            }
            setArchiveGroups(tempList)
        }
    },[props.archiveGroups])

    function addArchiveGroup_tog_standard() {
        setModalAddArchiveGroup(!modalAddArchiveGroup);
        
    }

    const submitForCreatingArchiveGroup = () => {
        const ArchiveGroup = Parse.Object.extend("Group");
        let groupObject = new ArchiveGroup();
        groupObject.set("name", editArchiveGroupName)
        groupObject.set("type", 4)
        groupObject.set("ownerId", Parse.User.current().attributes.teacherId)
        groupObject.set("schoolId", schoolId  )
        props.saveArchiveGroup(groupObject);
        addArchiveGroup_tog_standard();
    };
            
    
    return(
        <React.Fragment>
            <Modal
                isOpen={modalAddArchiveGroup}
                toggle={() => { addArchiveGroup_tog_standard() }}
            >
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myModalLabel">
                        {props.t("Add Archive Group")}
                    </h5>
                    <button
                        type="button"
                        onClick={() => { addArchiveGroup_tog_standard() }}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <AvForm className="outer-repeater" onSubmit={submitForCreatingArchiveGroup}>
                    <div className="modal-body">
                        <FormGroup >
                            <Label htmlFor="formname">{props.t('Group Name')}:</Label>
                            <AvField
                                type="text"
                                name="name"
                                placeholder={props.t('Enter Group Name...')}
                                className="form-control"
                                value={editArchiveGroupName}
                                validate={{
                                    required: {
                                        value: true,
                                        errorMessage: props.t('Please enter group name...'),
                                    },
                                }}
                                onChange={(e) => {
                                    setEditArchiveGroupName(e.target.value);
                                }}
                            />
                        </FormGroup>
                        
                    </div>
                    <div className="modal-footer">
                        <button
                            type="button"
                            onClick={() => { addArchiveGroup_tog_standard() }}
                            className="btn btn-secondary waves-effect"
                            data-dismiss="modal"
                        >
                            Close
                            </button>
                        <Button type="submit"
                            color="primary">
                            {props.t('Save Group')}
                        </Button>
                    </div>
                </AvForm>
            </Modal>
            <Container fluid>

                <div className="page-content">
                    <Breadcrumbs title="Archives" tog_standard={addArchiveGroup_tog_standard} hideActionButtion={(props.selectedSchool && props.selectedSchool.attributes.schoolType==10) ?? false} />
                    {archiveGroups != null && archiveGroups.length > 0 && <ListView archiveList = {archiveGroups} />}
                    
                </div>

            </Container>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { kids } = state.Kid;
    const { archiveGroups } = state.Group;
    const { selectedSchool } = state.School;

    return{archiveGroups, kids,selectedSchool}
}

export default connect(mapStatetoProps, { saveArchiveGroup,
    getArchiveGroupsForSchoolId,setSelecetdSchool})(withRouter(withNamespaces()(Index)));
