import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as MessageHelper from "../../../helpers/ParseHelpers/message_helper";
import * as actions from "./actionTypes";
import * as Constants from '../../../Constents';
import * as MessageActions from './actions';
import Parse from 'parse';



function* getMessagesForClassId({ payload: { id } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    var isNoMessagesFromLocal = false;
    const localResponse = yield call(MessageHelper.getMessagesForClassId, { id: id, isLocal: true });
    if (localResponse == undefined || localResponse.length === 0) {
      isNoMessagesFromLocal = true
    } else {
      yield put(MessageActions.updateMessages(localResponse));
    }
    let serverResponse
    if (isNoMessagesFromLocal) {
      //classId, isLocal, limit, latestMessageDate
      serverResponse = yield call(MessageHelper.getMessagesForClassId, { id: id, isLocal: false, limit: 10 });
    } else {
      let latestMessageDate = getLatestMessageToCurrentDate(localResponse)
      serverResponse = yield call(MessageHelper.getMessagesForClassId, { id: id, isLocal: false, updatedDate: latestMessageDate });
    }
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));
    if ((localResponse == undefined || localResponse.length === 0) && (serverResponse == undefined || serverResponse.length === 0)) {
      yield put(MessageActions.setNoMessagesToShow(true));
    } else {
      yield put(MessageActions.setNoMessagesToShow(false));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getLatestMessagesForClassId({ payload: { id, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let latestMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForClassId, { id: id, isLocal: false, updatedDate: latestMessageDate });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getOldMessagesForClassId({ payload: { id, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let lastMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForClassId, { id: id, isLocal: false, limit: 10, updatedDate: lastMessageDate, isOld: true });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}



function* getMessagesForGroupId({ payload: { id } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    var isNoMessagesFromLocal = false;
    const localResponse = yield call(MessageHelper.getMessagesForGroupId, { id: id, isLocal: true });
    if (localResponse == undefined || localResponse.length === 0) {
      isNoMessagesFromLocal = true
    } else {
      yield put(MessageActions.updateMessages(localResponse));
    }
    let serverResponse
    if (isNoMessagesFromLocal) {
      //classId, isLocal, limit, latestMessageDate
      serverResponse = yield call(MessageHelper.getMessagesForGroupId, { id: id, isLocal: false, limit: 10 });

    } else {
      let latestMessageDate = getLatestMessageToCurrentDate(localResponse)
      serverResponse = yield call(MessageHelper.getMessagesForGroupId, { id: id, isLocal: false, updatedDate: latestMessageDate });
    }
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));
    if ((localResponse == undefined || localResponse.length === 0) && (serverResponse == undefined || serverResponse.length === 0)) {
      yield put(MessageActions.setNoMessagesToShow(true));
    } else {
      yield put(MessageActions.setNoMessagesToShow(false));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getLatestMessagesForGroupId({ payload: { id, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let latestMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForGroupId, { id: id, isLocal: false, updatedDate: latestMessageDate });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getOldMessagesForGroupId({ payload: { id, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let lastMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForGroupId, { id: id, isLocal: false, limit: 10, updatedDate: lastMessageDate, isOld: true });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getMessagesForSchoolId({ payload: { id } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    var isNoMessagesFromLocal = false;
    const localResponse = yield call(MessageHelper.getMessagesForSchoolId, { id: id, isLocal: true });
    if (localResponse == undefined || localResponse.length === 0) {
      isNoMessagesFromLocal = true
    } else {
      yield put(MessageActions.updateMessages(localResponse));
    }
    let serverResponse
    if (isNoMessagesFromLocal) {
      //classId, isLocal, limit, latestMessageDate
      serverResponse = yield call(MessageHelper.getMessagesForSchoolId, { id: id, isLocal: false, limit: 10 });

    } else {
      let latestMessageDate = getLatestMessageToCurrentDate(localResponse)
      serverResponse = yield call(MessageHelper.getMessagesForSchoolId, { id: id, isLocal: false, updatedDate: latestMessageDate });
    }
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));
    if ((localResponse == undefined || localResponse.length === 0) && (serverResponse == undefined || serverResponse.length === 0)) {
      yield put(MessageActions.setNoMessagesToShow(true));
    } else {
      yield put(MessageActions.setNoMessagesToShow(false));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getLatestMessagesForSchoolId({ payload: { id, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let latestMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForSchoolId, { id: id, isLocal: false, updatedDate: latestMessageDate });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getOldMessagesForSchoolId({ payload: { id, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let lastMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForSchoolId, { id: id, isLocal: false, limit: 10, updatedDate: lastMessageDate, isOld: true });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}



function* getMessagesForAllTeachers({ payload: { id } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    var isNoMessagesFromLocal = false;
    const localResponse = yield call(MessageHelper.getMessagesForAllTeachers, { id: id, isLocal: true });
    if (localResponse == undefined || localResponse.length === 0) {
      isNoMessagesFromLocal = true
    } else {
      yield put(MessageActions.updateMessages(localResponse));
    }
    let serverResponse
    if (isNoMessagesFromLocal) {
      //classId, isLocal, limit, latestMessageDate
      serverResponse = yield call(MessageHelper.getMessagesForAllTeachers, { id: id, isLocal: false, limit: 10 });

    } else {
      let latestMessageDate = getLatestMessageToCurrentDate(localResponse)
      serverResponse = yield call(MessageHelper.getMessagesForAllTeachers, { id: id, isLocal: false, updatedDate: latestMessageDate });
    }
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));
    if ((localResponse == undefined || localResponse.length === 0) && (serverResponse == undefined || serverResponse.length === 0)) {
      yield put(MessageActions.setNoMessagesToShow(true));
    } else {
      yield put(MessageActions.setNoMessagesToShow(false));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getLatestMessagesForAllTeachers({ payload: { id, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let latestMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForAllTeachers, { id: id, isLocal: false, updatedDate: latestMessageDate });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getOldMessagesForAllTeachers({ payload: { id, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let lastMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForAllTeachers, { id: id, isLocal: false, limit: 10, updatedDate: lastMessageDate, isOld: true });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}



function* getAlbumsForAlbumIdsSaga({ payload: { albumIds } }) {
  try {
    const localResponse = yield call(MessageHelper.getAlbumsForAlbumIds, { id: albumIds, isLocal: true });
    if (localResponse == undefined || localResponse.length === 0) {

    } else {
      yield put(MessageActions.updateAlbums(localResponse));
    }
    let serverResponse
    serverResponse = yield call(MessageHelper.getAlbumsForAlbumIds, { id: albumIds, isLocal: false });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateAlbums(serverResponse));
      Parse.Object.pinAll(serverResponse);
    } else {
    }

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deleteAlbum({ payload: { album } }) {
  try {
    const response = yield call(
      MessageHelper.deleteAlbumFromParse,
        album
    );
    if (response) {
      yield put(MessageActions.deleteAlbum(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getMessageQueuesSaga({ payload: { schoolId, franchiseId } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    serverResponse = yield call(MessageHelper.getMessageQueues, { schoolId: schoolId, franchiseId: franchiseId });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessageQueues(serverResponse));
      yield put(MessageActions.setNoMessagesToShow(false));
      //Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getMessagesForCourseKid({ payload: { courseId,kidId,clear } }) {
  try {
    
    yield put(MessageActions.directAssignMessages([]));
    yield put(MessageActions.setMessagesLoading(true));
    var isNoMessagesFromLocal = false;
    const localResponse = yield call(MessageHelper.getMessagesForCourseKid, { courseId: courseId,kidId:kidId, isLocal: true });
    if (localResponse == undefined || localResponse.length === 0) {
      isNoMessagesFromLocal = true
    } else {
      yield put(MessageActions.updateMessages(localResponse));
    }
    let serverResponse
    if (isNoMessagesFromLocal) {
      //classId, isLocal, limit, latestMessageDate
      serverResponse = yield call(MessageHelper.getMessagesForCourseKid, { courseId: courseId,kidId:kidId, isLocal: false, limit: 10 });

    } else {
      let latestMessageDate = getLatestMessageToCurrentDate(localResponse)
      serverResponse = yield call(MessageHelper.getMessagesForCourseKid, { courseId: courseId,kidId:kidId, isLocal: false, updatedDate: latestMessageDate });
    }
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));
    if ((localResponse == undefined || localResponse.length === 0) && (serverResponse == undefined || serverResponse.length === 0)) {
      yield put(MessageActions.setNoMessagesToShow(true));
    } else {
      yield put(MessageActions.setNoMessagesToShow(false));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getLatestMessagesForCourseKid({ payload: { courseId,kidId, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let latestMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForCourseKid, { courseId: courseId,kidId:kidId, isLocal: false, updatedDate: latestMessageDate });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getOldMessagesForAllCourseKid({ payload: { courseId,kidId, updatedAt } }) {
  try {
    yield put(MessageActions.setMessagesLoading(true));
    let serverResponse
    let lastMessageDate = updatedAt
    serverResponse = yield call(MessageHelper.getMessagesForCourseKid, { courseId: courseId,kidId:kidId, isLocal: false, limit: 10, updatedDate: lastMessageDate, isOld: true });
    if (serverResponse && serverResponse.length > 0) {
      yield put(MessageActions.updateMessages(serverResponse, true));
      yield put(MessageActions.setNoMessagesToShow(false));
      Parse.Object.pinAll(serverResponse);
    } else {
    }
    yield put(MessageActions.setMessagesLoading(false));

  } catch (error) {
    // yield put(apiError(error));
  }
}

const getLatestMessageToCurrentDate = (list) => {
  return list[list.length - 1].updatedAt
}

//******************* FORK *******************//




export function* watchGetMessageQueuesSaga() {
  yield takeEvery(actions.GET_MESSAGE_QUEUES, getMessageQueuesSaga)
}

export function* watchGetAlbumsForAlbumIdsSaga() {
  yield takeEvery(actions.GET_ALBUMS_FOR_ALBUM_IDS, getAlbumsForAlbumIdsSaga)
}

export function* watchGetDeleteAlbumSaga() {
  yield takeEvery(actions.DELETE_ALBUM, deleteAlbum)
}

export function* watchGetMessagesForClassIdSaga() {
  yield takeEvery(actions.GET_MESSAGES_FOR_CLASSID, getMessagesForClassId)
}
export function* watchGetLatestMessagesForClassIdSaga() {
  yield takeEvery(actions.GET_LATEST_MESSAGES_FOR_CLASSID, getLatestMessagesForClassId)
}
export function* watchGetOldMessagesForClassIdSaga() {
  yield takeEvery(actions.GET_OLD_MESSAGES_FOR_CLASSID, getOldMessagesForClassId)
}



export function* watchGetMessagesForGroupIdSaga() {
  yield takeEvery(actions.GET_MESSAGES_FOR_GROUPID, getMessagesForGroupId)
}
export function* watchGetLatestMessagesForGroupIdSaga() {
  yield takeEvery(actions.GET_LATEST_MESSAGES_FOR_GROUPID, getLatestMessagesForGroupId)
}
export function* watchGetOldMessagesForGroupIdSaga() {
  yield takeEvery(actions.GET_OLD_MESSAGES_FOR_GROUPID, getOldMessagesForGroupId)
}








export function* watchGetMessagesForSchoolIdSaga() {
  yield takeEvery(actions.GET_MESSAGES_FOR_SCHOOLID, getMessagesForSchoolId)
}
export function* watchGetLatestMessagesForSchoolIdSaga() {
  yield takeEvery(actions.GET__LATEST_MESSAGES_FOR_SCHOOLID, getLatestMessagesForSchoolId)
}
export function* watchGetOldMessagesForSchoolIdSaga() {
  yield takeEvery(actions.GET_OLD_MESSAGES_FOR_SCHOOLID, getOldMessagesForSchoolId)
}





export function* watchGetMessagesForAllTeachersSaga() {
  yield takeEvery(actions.GET_MESSAGES_FOR_ALL_TEACHERS, getMessagesForAllTeachers)
}
export function* watchGetLatestMessagesForAllTeachersSaga() {
  yield takeEvery(actions.GET_LATEST_MESSAGES_FOR_ALL_TEACHERS, getLatestMessagesForAllTeachers)
}
export function* watchGetOldMessagesForAllTeachersSaga() {
  yield takeEvery(actions.GET_OLD_MESSAGES_FOR_ALL_TEACHERS, getOldMessagesForAllTeachers)
}


export function* watchGetMessagesForCourseKid() {
  yield takeEvery(actions.GET_MESSAGES_FOR_COURSE_KID, getMessagesForCourseKid)
}
export function* watchGetLatestMessagesForCourseKid() {
  yield takeEvery(actions.GET_LATEST_MESSAGES_FOR_COURSE_KID, getLatestMessagesForCourseKid)
}
export function* watchGetOldMessagesForAllCourseKid() {
  yield takeEvery(actions.GET_OLD_MESSAGES_FOR_COURSE_KID, getOldMessagesForAllCourseKid)
}





function* programSaga() {
  yield all([
    fork(watchGetMessagesForClassIdSaga),
    fork(watchGetLatestMessagesForClassIdSaga),
    fork(watchGetOldMessagesForClassIdSaga),



    fork(watchGetMessagesForGroupIdSaga),
    fork(watchGetLatestMessagesForGroupIdSaga),
    fork(watchGetOldMessagesForGroupIdSaga),


    fork(watchGetMessagesForSchoolIdSaga),
    fork(watchGetLatestMessagesForSchoolIdSaga),
    fork(watchGetOldMessagesForSchoolIdSaga),

    fork(watchGetMessagesForAllTeachersSaga),
    fork(watchGetLatestMessagesForAllTeachersSaga),
    fork(watchGetOldMessagesForAllTeachersSaga),

    fork(watchGetMessagesForCourseKid),
    fork(watchGetLatestMessagesForCourseKid),
    fork(watchGetOldMessagesForAllCourseKid),

    fork(watchGetAlbumsForAlbumIdsSaga),
    fork(watchGetDeleteAlbumSaga),
    fork(watchGetMessageQueuesSaga),
  ]);
}
export default programSaga;
