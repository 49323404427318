import React, { useState, useEffect } from 'react';;
import { Row, Col, Button, Input, Container, Label, Card, CardBody, FormGroup, } from "reactstrap";
import CertificateSymbol from '../../../assets/images/certificate/medal.png';
import Certificate4 from '../../../assets/images/certificate/certificate4.png';
import Certificate5 from '../../../assets/images/certificate/Certificate5.jpg';
import Certificate6 from '../../../assets/images/certificate/certificate6.jpg';
import Certificate7 from '../../../assets/images/certificate/certificate7.jpg';
import moment from 'moment';
import * as WhiteLable from "../../../components/Common/WhiteLable";

const bonafide = (props) => {

    return (
        <div className="certificate-card"
            style={{
                width: '90%',
                height: '90%',
                padding: '20px',
                borderRadius: '10px',
            }}>
            <div className="certificate-container">
                <style>
                    {`.certificate-container {
                       margin: 0 auto;
                       padding: 20px;
                       width: 820px;
                       height: 500px;
                       display: flex;
                       justify-content: center;
                       align-items: center;
                       background-color: ${props.styleType === "1" ? `white` : undefined};
                       background-image: ${props.styleType === '2'
                            ? `url(${Certificate5})`
                            : props.styleType === '3'
                                ? `url(${Certificate6})`
                                : props.styleType === '4'
                                    ? `url(${Certificate7})`
                                    : 'none'
                        };
                        background-size: cover;
                        background-position: center;
                        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
                        border: ${props.styleType === '1' ? '30px solid' : 'none'};
                        border-image: linear-gradient(135deg, #B8E994, #C5E7F0, #B8E994);
                        border-image-slice: 1;
                        }


                       .no-print {
                        display: none;
                        }

                        @media print {
                        body {
                        margin: 0;
                        padding: 0;
                        }

                         .certificate-container {
                          position: fixed;
                          left: 0;
                         top: 0;
                         width: 100vw;
                         height: 100vh;
                         overflow: hidden;
                         z-index: 9999;
                          }

                             @page {
                                size: A4 landscape;
                             margin: 0;
                              }
                             }`}
                </style>


                <div>
                    <Row className='text-center'>
                        {props.selectedSchool && props.selectedSchool.attributes.logo && (
                            <Col className='text-center'>
                                {" "}
                                <img className='text-center'
                                    src={props.selectedSchool && props.selectedSchool.attributes.logo._url}
                                    style={{ height: "6rem" }}
                                />{" "}
                            </Col>
                        )}
                    </Row>
                    <Row className=''>
                        <Col lg="12">
                            <Row>
                                <Col lg={12} className="d-flex align-items-center justify-content-center">
                                    <div className="maintenance-img m-1">
                                        <img src={CertificateSymbol} alt="" className="rounded-circle avatar-xs" />
                                    </div>
                                    <h2 className='text-center m-1' style={{ borderBottom: '1px solid black', fontSize: '2rem', letterSpacing: '0.1rem', color: 'black', fontFamily: 'Georgia, serif' }}>
                                        <strong>Bonafide Certificate</strong>
                                    </h2>
                                </Col>
                            </Row>
                            <div className='text-center' style={{ paddingTop: '40px' }}>
                                <h5 className="text-center">
                                    <strong>To Whomsoever it may concern</strong>
                                </h5>
                                <p className='text-center font-size-16'>
                                    This is to certify that&nbsp;
                                    <b>{props.name},</b>&nbsp;
                                    {props.gender === "2" ? "S/O" : "D/O"}&nbsp;<b>{props.father}</b> & <b>{props.mother}</b>,
                                    is a bonafide Student of&nbsp;
                                    <b>{props.selectedSchool && props.selectedSchool.attributes.Name}</b>&nbsp;.
                                    {props.gender === "2" ? "He" : "She"} has completed {props.gender === "2" ? "his" : "her"}&nbsp;<b>{""}{props.classRoom}{""}</b>&nbsp;
                                    for the academic year&nbsp;
                                    <b>{props.academicYear}</b>.&nbsp;
                                    {props.gender === "2" ? "His Date of Birth is" : "Her Date of Birth is"}&nbsp;
                                    <b>{moment(props.dob).format("DD/MM/YYYY")}</b>&nbsp;
                                    as per our records.
                                </p>
                                <p className="text-center font-size-16">{props.gender === "2" ? "He" : "She"} is reliable, sincere, hardworking and bears a good moral character.  </p>
                            </div>
                            <div className='' style={{ paddingTop: '50px' }}>
                                <Row className='align-items-center'>
                                    <Col className='d-flex flex-column align-items-center'>
                                        <h6 style={{ margin: '0', fontSize: '0' }}>{""}</h6>
                                        <span style={{ borderTop: '1px solid black', width: '3cm', textAlign: 'center', lineHeight: '1.2' }}>Signature</span>
                                    </Col>
                                    <Col className='d-flex flex-column align-items-center'>
                                        <h6 style={{ margin: '0' }}>{moment(props.date).format("DD/MM/YYYY")}</h6>
                                        <span style={{ borderTop: '1px solid black', width: '3cm', textAlign: 'center' }}>Date</span>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    {false && <Row className='float-right'>
                        <Col>
                            <Label className="logo logo-dark float-right">
                                <img src={WhiteLable.getAppLogoWithName()} alt="" className="avatar " height={'15'} />
                            </Label>
                        </Col>
                    </Row>}
                </div>
            </div>
        </div>
    )
}


export default bonafide

