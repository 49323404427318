import React, { useEffect, useState } from 'react';
import {
    UncontrolledTooltip
} from "reactstrap";

import { withNamespaces } from "react-i18next";
import Editable from "react-bootstrap-editable";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller, } from "react-scroll";


const StudentImportCell = (props) => {

    const [mSpaceError, setMSpaceError] = useState(null)
    const [fSpaceError, setFSpaceError] = useState(null)

    useEffect(() => {
        if (props.rewievId != null) {
            scroller.scrollTo(props.rewievId, {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100,
            });
        }
    }, [props.rewievId]);

    return (
        <React.Fragment>
            {
                props.kid &&
                <tr>
                    {/* <Card>
                        <CardBody> */}

                    <td>
                        <Element name={props.kid.familyId} >
                            <div>

                            </div>
                        </Element>
                        {props.slno}
                    </td>

                    <td>
                        <Editable
                            className={'d-flex'}
                            alwaysEditing={false}
                            disabled={false}
                            editText={props.kid.studentName ? "✎" : "Student Name ✎"}
                            initialValue={props.kid.studentName}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            required
                            onSubmit={(value) => {
                                let temp = props.kid;
                                temp.studentName = value;
                                if (value && value.length > 0) {
                                    temp.nameError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);
                            }}

                        />
                        {props.kid.nameError && <p className="text-danger">Sudent Name </p>}
                        <Editable
                            className={'d-flex'}
                            alwaysEditing={false}
                            disabled={false}
                            editText={props.kid.admissionNumber ? "✎" : "Admission Number ✎"}
                            initialValue={props.kid.admissionNumber}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            onSubmit={(value) => {
                                let temp = props.kid;
                                temp.admissionNumber = value;
                                props.handelUpdateKid(props.kid, temp);
                            }}

                        />



                    </td>

                    <td>

                        <Editable
                            className={'d-flex'}
                            ajax={null}
                            alwaysEditing={false}
                            disabled={false}
                            editText={props.kid.admissionClass ? "✎" : "Classroom ✎"}
                            initialValue={props.kid.admissionClass}
                            id={null}
                            isValueClickable={false}
                            label={null}
                            mode="inline"
                            onValidated={null}
                            options={props.classNamesList}
                            placement="top"
                            showText
                            type="select"
                            validate={null}
                            onSubmit={(value) => {
                                let temp = props.kid;
                                temp.admissionClass = value;
                                let index = props.classNamesList.indexOf(value);
                                temp.classroomId = props.classroomIds[index];
                                if (value && value.length > 0) {
                                    temp.classError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);
                                console.log("c", temp);
                            }}
                        />

                        {props.kid.classError && <p className="text-danger">Pick the class</p>}
                    </td>


                    <td>
                        <Editable
                            ajax={null}
                            alwaysEditing={false}
                            className={'d-flex'}
                            disabled={false}
                            initialValue={props.kid.gender}
                            editText={props.kid.gender ? "✎" : "Gender ✎"}
                            id={null}
                            isValueClickable={false}
                            label={null}
                            mode="inline"
                            onValidated={null}
                            options={["Pick Gender", "Male", "Female"]}
                            placement="top"
                            showText
                            type="select"
                            validate={null}
                            onSubmit={(value) => {

                                let temp = props.kid;
                                temp.gender = value;
                                if (value && value.length > 0) {
                                    temp.genderError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);

                            }}
                        />
                        {props.kid.genderError && <p className="text-danger">Pick the gender</p>}

                        <Editable
                            ajax={null}
                            alwaysEditing={false}
                            className={'d-flex'}
                            disabled={false}
                            editText={props.kid.dateOfBirth ? "✎" : "Date of Birth ✎"}
                            initialValue={props.kid.dateOfBirth}
                            id={null}
                            isValueClickable={false}
                            label={null}
                            mode="inline"
                            onValidated={null}
                            placement="top"
                            showText
                            type="date"
                            validate={null}

                            onSubmit={(value) => {
                                let temp = props.kid;
                                temp.dateOfBirth = value;
                                props.handelUpdateKid(props.kid, temp);
                                console.log("d", temp);
                            }}
                        />


                    </td>


                    <td>
                        <Editable
                            alwaysEditing={false}
                            className={'d-flex'}
                            disabled={false}
                            editText={props.kid.motherName ? "✎" : "Name ✎"}
                            initialValue={props.kid.motherName}
                            id={props.kid.familyId + "mn"}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            onSubmit={(value) => {
                                let temp = props.kid;
                                temp.motherName = value;
                                if (value && value.length > 0) {
                                    temp.oneParentError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);
                            }}


                        />
                        <Editable
                            alwaysEditing={false}
                            className={'d-flex'}
                            disabled={false}
                            editText={props.kid.motherPhone ? "✎" : "Phone Number ✎"}
                            initialValue={props.kid.motherPhone}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            onSubmit={(value) => {
                                if (value.includes(' ')) {
                                    setMSpaceError("Phone number cannot contain spaces!");
                                } else {
                                    setMSpaceError(null);
                                }
                                let temp = props.kid;
                                temp.motherPhone = value;
                                if (value && value.length > 0) {
                                    temp.oneParentError = false;
                                    temp.motherError = false;
                                    temp.fatherError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);
                            }}
                        />
                        {mSpaceError && <small className="text-danger">{mSpaceError}</small>}
                        <Editable
                            alwaysEditing={false}
                            disabled={false}
                            editText={props.kid.motherEmail ? "✎" : "Email ✎"}
                            initialValue={props.kid.motherEmail}
                            id={null}
                            className={'d-flex'}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            onSubmit={(value) => {
                                let temp = props.kid;
                                temp.motherEmail = value;
                                if (value && value.length > 0) {
                                    temp.motherError = false;
                                    temp.fatherError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);
                            }}

                        />


                        {props.kid.oneParentError && <p className="text-danger">Any one parent details are mandatory</p>}
                        {!props.kid.oneParentError && props.kid.motherError && <p className="text-danger">Phone No. or Email is mandatory</p>}

                    </td>

                    <td>
                        <Editable
                            alwaysEditing={false}
                            className={'d-flex'}
                            disabled={false}
                            initialValue={props.kid.fatherName}
                            id={props.kid.familyId + "fn"}
                            editText={props.kid.fatherName ? "✎" : "Name ✎"}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            onSubmit={(value) => {
                                let temp = props.kid;
                                temp.fatherName = value;
                                if (value && value.length > 0) {
                                    temp.oneParentError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);
                            }}

                        />
                        <Editable
                            alwaysEditing={false}
                            className={'d-flex'}
                            disabled={false}
                            editText={props.kid.fatherPhone ? "✎" : "Phone Number ✎"}
                            initialValue={props.kid.fatherPhone}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            onSubmit={(value) => {
                                if (value.includes(' ')) {
                                    setFSpaceError("Phone number cannot contain spaces!");
                                } else {
                                    setFSpaceError(null);
                                }
                                let temp = props.kid;
                                temp.fatherPhone = value;
                                if (props.kid.fatherName && props.kid.fatherName.length > 0 && value && value.length > 0) {
                                    temp.fatherError = false;
                                    temp.motherError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);
                            }}
                        />
                        {fSpaceError && <small className="text-danger">{fSpaceError}</small>}
                        <Editable
                            alwaysEditing={false}
                            className={'d-flex'}
                            disabled={false}
                            editText={props.kid.fatherEmail ? "✎" : "Email ✎"}
                            initialValue={props.kid.fatherEmail}
                            id={null}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            showText
                            type="textfield"
                            onSubmit={(value) => {
                                let temp = props.kid;
                                temp.fatherEmail = value;
                                if (value && value.length > 0) {
                                    temp.fatherError = false;
                                    temp.motherError = false;
                                }
                                props.handelUpdateKid(props.kid, temp);
                            }}

                        />
                        {!props.kid.oneParentError && props.kid.fatherError && <p className="text-danger">Phone No. or Email is mandatory</p>}
                    </td>


                    <td>
                        <ul className="list-inline font-size-20 contact-links mb-0">
                            <li className="list-inline-item px-2" id={"profile" + props.kid.familyId}
                                onClick={() => {
                                    props.handelRemoveKid(props.kid);
                                }}
                            >
                                <i className="bx bx-message-square-x text-danger"></i>
                                <UncontrolledTooltip placement="top" target={"profile" + props.kid.familyId}>
                                    {props.t('Remove')}
                                </UncontrolledTooltip>
                            </li>
                        </ul>
                    </td>







                    {/* </CardBody>
                    </Card> */}
                </tr>
            }
        </React.Fragment>
    )
}
export default withNamespaces()(StudentImportCell);