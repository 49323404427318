import * as actions from "./actionTypes";

export const updateExpenses = (expenses) => {
  return {
    type: actions.UPDATE_EXPENSES,
    payload: { expenses },
  };
};

export const getExpensesForSchoolId = (schoolId, isInstitute = false) => {
  return {
    type: actions.GET_EXPENSES_FOR_SCHOOL_ID,
    payload: { schoolId, isInstitute },
  };
};


export const updateExpense = (expense) => {
  return {
    type: actions.UPDATE_EXPENSE,
    payload: { expense },
  };
};

export const editExpense = (expense, isInstitute = false) => {
  return {
    type: actions.EDIT_EXPENSE,
    payload: { expense, isInstitute },
  };
};
export const updateExpenseResult = (expense) => {
  return {
    type: actions.UPDATE_EXPENSE_RESULT,
    payload: { expense },
  };
};
export const clearExpenseResult = () => {
  return {
    type: actions.CLEAR_EXPENSE_RESULT,
    payload: {},
  };
};

export const getExpensesForDate = (schoolId, fromDate, toDate) => {
  return {
    type: actions.GET_EXPENSES_FOR_DATE,
    payload: { schoolId, fromDate, toDate },
  };
};

export const clearExpenses = () => {
  return {
    type: actions.CLEAR_EXPENSES,
    payload: {}
  }
}

export const addExpenseWallet = (schoolId, amount, teacherId) => {
  return {
    type: actions.ADD_EXPENSE_WALLET,
    payload: { schoolId, amount, teacherId }
  }
}

export const updateExpenseWallet = (expenseWallet) => {
  return {
    type: actions.UPDATE_EXPENSE_WALLET,
    payload: { expenseWallet }
  }
}

export const updateExpenseWalletLog = (expenseWalletLog) => {
  return {
    type: actions.UPDATE_EXPENSE_WALLET_LOG,
    payload: { expenseWalletLog }
  }
}

export const getExpenseWalletForSchoolId = (schoolId) => {
  return {
    type: actions.GET_EXPENSE_WALLET_FOR_SCHOOL_ID,
    payload: { schoolId }
  }
}

export const getExpenseWalletLogForSchoolId = (schoolId) => {
  return {
    type: actions.GET_EXPENSE_WALLET_LOG_FOR_SCHOOL_ID,
    payload: { schoolId }
  }
}

export const getExpenseWalletLogForDate = (schoolId, fromDate, toDate) => {
  return {
    type: actions.GET_EXPENSE_WALLET_LOG_FOR_DATE,
    payload: { schoolId, fromDate, toDate }
  }
}

export const clearExpenseWalletLog = () => {
  return {
    type: actions.CLEAR_EXPENSE_WALLET_LOG,
    payload: {}
  }
}