import Parse from "parse";
import { printLog } from "./loghelper";

const getEnquiryQuestionFromParse = (enquiryQuestionId) => {
  printLog("HL: enquiryQuestions_helper : getEnquiryQuestionFromParse")

  try {
    return new Promise((resolve, reject) => {
      var EnquiryQuestion = Parse.Object.extend("EnquiryQuestions");
      var query = new Parse.Query(EnquiryQuestion);
      query.get(enquiryQuestionId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const getEnquiryQuestionFromLocal = (enquiryQuestionId) => {
  printLog("HL: enquiryQuestions_helper : getEnquiryQuestionFromLocal")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryQuestion = Parse.Object.extend("EnquiryQuestions");
      var query = new Parse.Query(EnquiryQuestion);
      query.fromLocalDatastore();
      query.get(enquiryQuestionId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const deleteEnquiryQuestionFromParse = (enquiryQuestion) => {
  printLog("HL: enquiryQuestions_helper : deleteEnquiryQuestionFromParse")
  try {
    return new Promise((resolve, reject) => {
      enquiryQuestion.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        enquiryQuestion.set(
          "deletedBy",
          Parse.User.current().attributes.teacherId
        );
      } else {
        enquiryQuestion.set("deletedBy", Parse.User.current().id);
      }

      enquiryQuestion.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("error");
        }
      );
    });
  } catch (error) {}
};

const getEnquiryQuestionForSchoolId = (id,updatedDate,isLocal) => {
  printLog("HL: enquiryQuestions_helper : getEnquiryQuestionForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryQuestion = Parse.Object.extend("EnquiryQuestions");
      var query = new Parse.Query(EnquiryQuestion);
      query.equalTo("schoolId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
     } else {
       query.notEqualTo("isDeleted", true);
     }
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.addAscending("order");
      query.find().then(
        (result) => {
          
          if (!isLocal && !updatedDate && (!result || result.length === 0)) {
            Parse.Cloud.run("function_enquiryQuestionValidateAndCreate", {
              schoolIds: [id],
            }).then((newresults) => {
              resolve(newresults);
            });
          } else {
            resolve(result);
          }  
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const editEnquiryQuestion = (enquiry) => {
  printLog("HL: enquiryQuestions_helper : editEnquiryQuestion")
  try {
    return new Promise((resolve, reject) => {
      enquiry.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) {}
};
const saveCustomObjects = (object) => {
  printLog("HL: enquiryQuestions_helper : saveCustomObjects")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) {}
};


export {
  getEnquiryQuestionFromParse,
  deleteEnquiryQuestionFromParse,
  getEnquiryQuestionForSchoolId,
  editEnquiryQuestion,
  getEnquiryQuestionFromLocal,
  saveCustomObjects,
};
