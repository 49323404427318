import React, {useState,useEffect} from 'react';
import { Media ,Card,CardBody, Input,FormGroup,Label,Row,Col,InputGroup, InputGroupText, Button,UncontrolledTooltip} from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from 'react-i18next';
import SweetAlert from "react-bootstrap-sweetalert";
import { getEnquiryFollowupForSchoolId,editEnquiryFollowup,deleteEnquiryFollowup,saveEnquiryFollowups} from '../../store/actions';
import * as Constants from '../../Constents';
import Parse from 'parse';

const EnquiryFollowup = (props) => {

    const [wlcomeMessage, setWelcomeMessage] = useState("");
    const [wlcomeMessageError, setWelcomeMessageError] = useState(false);
    
    const [admissionMessage, setAdmissionMessage] = useState("");
    const [admissionMessageError, setAdmissionMessageError] = useState(false);

    const [followpsList, setFollowupsList] = useState([]);

    const [showDeleteAlert, setShowDeleteAlert] = useState(false);

    const [selectedFollowup, setSelectedFollowup] = useState(undefined);
    
    var schoolId = props.schoolId;

    
    useEffect(() => {
        props.getEnquiryFollowUpForSchoolId(schoolId); 
    }, [schoolId]);

    useEffect(() => {
        var list = [];
        setAdmissionMessage("");
        for (var i in props.enquiryFollowups) {
            var followup = props.enquiryFollowups[i];
            var obj = {
                id:followup.id,
                title: "Follow-Up Message " + followup.attributes.followupNumber,
                message: followup.attributes.message,
                followupNumber: followup.attributes.followupNumber,
                noofDaysFromEnquiry: followup.attributes.noofDaysFromEnquiry,
                parseObject: followup,
                isDefaultMessage: followup.attributes.isDefaultMessage,
                error: false, error2: false,
            }

            if (followup.attributes.isDefaultMessage) {
                setWelcomeMessage(followup.attributes.message);
            } else if (followup.attributes.followupNumber === -1) {
                setAdmissionMessage(followup.attributes.message);
            } else {
                list.push(obj);
            }
            
        }
        setFollowupsList(list);
    },[props.enquiryFollowups]);
    
    
    function onClickSave() {


        var saveObjects = [];

        var welcomeObject=undefined;
        var admissionObject=undefined;
        if (props.enquiryFollowups && props.enquiryFollowups.length > 0) {

            var list = props.enquiryFollowups.filter(
                (item) => item.attributes.isDefaultMessage === true
            );

            if (list && list.length > 0) {
                welcomeObject = list[0];
            }

            var list1 = props.enquiryFollowups.filter(
                (item) => item.attributes.followupNumber === -1
            );
            if (list1 && list1.length > 0) {
                admissionObject = list1[0];
            }
        }
        if (welcomeObject && wlcomeMessage.trim().length===0) {
            setWelcomeMessageError(true);
            return;
        } else {
            if (welcomeObject) {
                if (welcomeObject.attributes.message !== wlcomeMessage) {
                    welcomeObject.set("message", wlcomeMessage);
                    saveObjects.push(welcomeObject);
                }
            } else {
                const EnquiryFollowup = Parse.Object.extend("EnquiryFollowup");
                var enquiryFollowUp = new EnquiryFollowup();
                enquiryFollowUp.set("message", wlcomeMessage);
                enquiryFollowUp.set("isDefaultMessage", true);
                enquiryFollowUp.set("noofDaysFromEnquiry", 0);
                enquiryFollowUp.set("followupNumber",0)
                enquiryFollowUp.set("schoolId", schoolId);
                saveObjects.push(enquiryFollowUp);
            }
           
            
        }
        if (admissionObject && admissionMessage.trim().length===0) {
            setAdmissionMessageError(true);
            return;
        } else {
            if (admissionObject) {
                if (admissionObject.attributes.message !== admissionMessage) {
                    admissionObject.set("message", admissionMessage);
                    saveObjects.push(admissionObject);
                }
            } else if (admissionMessage.trim().length !== 0) {
                const EnquiryFollowup = Parse.Object.extend("EnquiryFollowup");
                var enquiryFollowUp = new EnquiryFollowup();
                enquiryFollowUp.set("message", admissionMessage);
                enquiryFollowUp.set("isDefaultMessage", false);
                enquiryFollowUp.set("followupNumber",-1)
                enquiryFollowUp.set("schoolId", schoolId);
                saveObjects.push(enquiryFollowUp);
            }
        }

        if (followpsList && followpsList.length > 0) {
            for (const i in followpsList) {

                if (followpsList[i].message.trim().length===0) {
                    var list = [...followpsList];
                    list[i].error = false;
                    list[i].error2 = true;
                    setFollowupsList(list);
                    return;
                    
                }
                if (i != 0) {
                    if ( parseInt(followpsList[i - 1].noofDaysFromEnquiry )>= parseInt(followpsList[i].noofDaysFromEnquiry)) {
                        var list = [...followpsList];
                        list[i].error = true;
                        list[i].error2 = false;
                        setFollowupsList(list);
                        return;
                    }
                }

                if (followpsList[i].parseObject) {

                    var list = props.enquiryFollowups.filter(
                        (item) => item.id === followpsList[i].id
                    );

                    if (list.length > 0 && (followpsList[i].message != list[0].attributes.message ||followpsList[i].noofDaysFromEnquiry != list[0].attributes.noofDaysFromEnquiry )) {
                        list[0].set("message", followpsList[i].message);
                        list[0].set("noofDaysFromEnquiry", parseInt( followpsList[i].noofDaysFromEnquiry));
                        saveObjects.push(list[0]);
                    }

                } else {
                    const EnquiryFollowup = Parse.Object.extend("EnquiryFollowup");
                    var enquiryFollowUp = new EnquiryFollowup();
                    enquiryFollowUp.set("message",followpsList[i].message);
                    enquiryFollowUp.set("isDefaultMessage", false);
                    enquiryFollowUp.set("noofDaysFromEnquiry",parseInt( followpsList[i].noofDaysFromEnquiry));
                    enquiryFollowUp.set("followupNumber", parseInt(followpsList[i].followupNumber));
                    enquiryFollowUp.set("schoolId", schoolId);
                    saveObjects.push(enquiryFollowUp);
                }
            }
        }

        if (saveObjects.length > 0) {
            props.saveEnquiryFollowups(saveObjects)
        } else {
            
        }
        
    }

    function removeFollowup(followup) {
        if (followup.parseObject) {
            setShowDeleteAlert(true);
            setSelectedFollowup(followup);
        } else {
            var list = followpsList.filter(
                (item) => item.id !== followup.id
            );
            setFollowupsList(list);
        }
        
    }

    function handelDeleteFollowup() {
        setShowDeleteAlert(false);
        var list = followpsList.filter(
            (item) => item.id !== selectedFollowup.id
        );
        setFollowupsList(list);
        props.deleteEnquiryFollowup(selectedFollowup.id);
        setSelectedFollowup(undefined);
    };

    function onClickAddFollowup() {
        var list = [...followpsList];
        var days = 1;
        if (list && list.length > 0) {
            days =parseInt( list[list.length - 1].noofDaysFromEnquiry);
            days = days + 2;
        }
        var obj = {
            id:list.length+1,
            title:  "Follow-Up Message " + (list.length+1),
            message: "",
            followupNumber: list.length+1,
            noofDaysFromEnquiry: days ,
            parseObject: undefined,
            isDefaultMessage: false,
            error: false, error2: false,
        }
        list.push(obj);
        setFollowupsList(list);
    }
    

    function getDaysList(followup) {
        var list = [...followpsList];
        var index = list.indexOf(followup);

        if (index == 0) {
            return [1, 2, 3, 4, 5, 6, 7];
        } else {
            if (list[index - 1]) {
                var preVal = (parseInt(list[index - 1].noofDaysFromEnquiry) + 1);
                var endVal = preVal + 7;
                // if (list.length > i + 1 && parseInt(list[index + 1].noofDaysFromEnquiry) > endVal) {
                //     endVal = parseInt(list[index + 1].noofDaysFromEnquiry);
                // }
                var newValues = [];
                for (var i = preVal; i < endVal; i++){
                    newValues.push(i);
                }
                if (newValues.length == 0) {
                    return [1, 2, 3, 4, 5, 6, 7];
                } else {
                    if (!newValues.includes(parseInt(list[index].noofDaysFromEnquiry))){
                        var newList = [parseInt(list[index].noofDaysFromEnquiry)];
                        return newList.concat(newValues)
                    }
                }
                return newValues
            }
            
        }
        return [1, 2, 3, 4, 5, 6, 7];
    }

        
    return (
        <React.Fragment>
        
            <Card>
                <CardBody>

                    <Row>
                        <Col lg={10}>
                            <FormGroup>
                                                
                                <Label htmlFor="formmessage">{props.t('Welcome Message')} :</Label>
                                <textarea
                                    id={"WelcomeMessage"}
                                    placeholder={props.t('Welcome Message')} 
                                    rows="5"
                                    name="review"
                                    className="form-control"
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            setWelcomeMessageError(false);  
                                        }
                                        setWelcomeMessage(e.target.value)
                                    }}
                                    value={wlcomeMessage}
                                    required
                                    
                                ></textarea>
                                {wlcomeMessageError && <p className="text-danger"> {props.t('Welcome Message can not be empty')}</p>}
                            </FormGroup> 
                        </Col>
                        <Col lg={2}>
                            {props.saveLoading ?
                                 <button type="button" className="btn btn-dark waves-effect waves-light float-right m-2">
                                 <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}   
                             </button> 
                                :
                                <button
                                    onClick={()=>{onClickSave()}}
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light float-right m-2">
                                    <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save')}            
                                </button>}
                        </Col>
                    </Row>
                    
                    <Row>
                    <Col lg={10}>
                
                        {
                            followpsList.map((followup, key) => (
                                

                                <li className="list-group-item " key={followup.id}>

                                    <Row className="text-center">
                                    
                                        <Col lg={3}>
                                        <h4 className="font-size-14  mt-2">{followup.title}</h4>
                                        </Col>
                                        <Col lg={8}>
                                            <div className=" ml-4 mb-1" style={{ width: "200px" }} >
                                                <InputGroup>
                                                    <InputGroupText addonType="prepend">
                                                        <Button color="primary" >{props.t('After')}</Button>
                                                    </InputGroupText>
                                                 
                                                    <select
                                                        className="form-control"
                                                        
                                                        onChange={(e) => {
                                                            var list = [...followpsList];
                                                            var index = list.indexOf(followup);
                                                            list[index].noofDaysFromEnquiry = e.target.value;
                                                            list[index].error=false;
                                                            setFollowupsList(list);
                                                    
                                                        }}
                                                        value={followup.noofDaysFromEnquiry}
                                                    >
                                                        {getDaysList(followup).map((item, key) => (
                                                            <option key={key}>{item}</option>
                                                        ))}
                                                    </select>
                                                    <InputGroupText addonType="append">
                                                        <Button color="primary" >{props.t('Days')}</Button>
                                                    </InputGroupText>

                                                    {followup.error && <p className="text-danger">{props.t('Followp day should be greater then previous Followp day ')}</p>}
                                                </InputGroup>
                                            </div>
                                        </Col>
                                        
                                    

                                        {
                                            followup.id === followpsList[followpsList.length - 1].id &&
                                            <Col className="flex-fill " lg={1}>
                                                <Label
                                                    style={{cursor:"pointer"}}
                                                        onClick={() => {
                                                            removeFollowup(followup);
                                                        }}
                                                        id="followup-delete"
                                                        className="float-right mt-2"
                                                    >
                                                        <i className="bx bxs-trash text-danger"></i>
                                                        <UncontrolledTooltip
                                                        placement="top"
                                                        target={"followup-delete"}
                                                        >
                                                        {props.t("Delete")}
                                                        </UncontrolledTooltip>
                                                </Label>
                                            </Col>
                                        }
                                    </Row>

                                    <FormGroup>
                                        
                                        {/* <Label htmlFor="formmessage">{followup.title } :</Label> */}
                                        <textarea
                                            id={followup.id}
                                            placeholder={followup.title}
                                            rows="5"
                                            name="review"
                                            className="form-control"
                                            onChange={(e)=> {
                                                var list = [...followpsList];
                                                var index = list.indexOf(followup);
                                                list[index].message = e.target.value;
                                                if (e.target.value.length > 0) {
                                                    list[index].error2 = false;
                                                }
                                                setFollowupsList(list);
                                            }}
                                            value={followup.message}
                                            required
                                            
                                        ></textarea>
                                         {followup.error2 && <p className="text-danger">{props.t('Followp up message should not be empty ')}</p>}
                                    </FormGroup> 
                            
                                    </li>

                            ))
                        }
                    
                        { followpsList.length < 6 &&  <Row>
                            <Col>
                                <button
                                    onClick={()=>{onClickAddFollowup()}}
                                    type="button"
                                    className="btn btn-primary waves-effect waves-light float-right m-2">
                                    <i className="bx bx-plus font-size-16 align-middle mr-2"></i> {props.t('Add Followup message')}             
                                </button>
                            </Col>
                        </Row>}
                    

                    </Col>

                    </Row>
                
                    

                    <Row>
                        <Col lg={10}>
                            <FormGroup>
                                                
                                <Label htmlFor="formmessage">{props.t('Admission Acknowledgement Message')} :</Label>
                                <textarea
                                    id={"AdmissionMessage"}
                                    placeholder={props.t('Admission Message')}
                                    rows="5"
                                    name="review"
                                    className="form-control"
                                    onChange={(e) => {
                                        if (e.target.value.length > 0) {
                                            setAdmissionMessageError(false);  
                                        }
                                        setAdmissionMessage(e.target.value)
                                    }}
                                    value={admissionMessage}
                                    required
                                    
                                ></textarea>
                                {admissionMessageError && <p className="text-danger"> {props.t('Admission Acknowledgement Message can not be empty')}</p>}
                            </FormGroup> 
                        </Col>
                        <Col lg={2}>
                            <div style={{ width: "100px", height:"100px"}}></div>
                               { props.saveLoading ?<button
                                    
                                    type="button"
                                    className="btn btn-dark waves-effect waves-light float-right m-2">
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}              
                            </button> :
                                <button
                                onClick={()=>{onClickSave()}}
                                type="button"
                                className="btn btn-primary waves-effect waves-light float-right mr-2 mt-10">
                                <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save')}            
                            </button>}
                        </Col>

                    </Row>
                    
                    
                </CardBody>
            </Card>

            {showDeleteAlert && <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setSelectedFollowup(undefined);
                        setShowDeleteAlert(false);
                    }}
                    onConfirm={() => handelDeleteFollowup()}
            >
                <div> {props.t('Are you sure you want to delete')}<br /> <p className="text-primary"> {selectedFollowup.title} </p></div>
            </SweetAlert>}
        
        </React.Fragment>
    );
       
}

const mapStatetoProps = state => {
    const { enquiryFollowups ,saveLoading} = state.EnquiryFollowup;
    return { enquiryFollowups,saveLoading};
}
            
export default withNamespaces()(withRouter(connect(mapStatetoProps, { getEnquiryFollowUpForSchoolId: getEnquiryFollowupForSchoolId,editEnquiryFollowUp: editEnquiryFollowup ,deleteEnquiryFollowup,saveEnquiryFollowups})(EnquiryFollowup)));