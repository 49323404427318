import React from 'react';
import { Radar } from 'react-chartjs-2';

const RadarChart = (props) => {


    const colors = ["#2fc32f", "#b0dc0b", "#eab404", "#de672c", "#ec2e2e", "#d5429b", "#6f52b8", "#1c7cd5", "#56b9f7", "#0ae8eb"]


    const data = {
        labels: props.measureSkillLabels ?? [],
        datasets: [
            {
                label: props.measureName,
                backgroundColor: "rgba(52, 195, 143, 0.2)",
                borderColor: "#34c38f",
                pointBackgroundColor: "#34c38f",
                pointBorderColor: "#fff",
                pointHoverBackgroundColor: "#fff",
                pointHoverBorderColor: "#34c38f",
                data: props.measureSkillDatas ?? []
            },

        ]
    };

    return (
        <Radar width={474} height={300} data={data} />
    );
}

export default RadarChart;