import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";

import Parse from "parse";
import { AvForm } from "availity-reactstrap-validation";

import { Container, Row, Col, Button, Label, FormGroup } from "reactstrap";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Iframe from "react-iframe";
import TopBar from "./Topbar";

import { setSelecetdSchool } from "../../store/actions";
import * as Constants from "../../Constents";
import toastr from "toastr";

const ExamResultComponent = (props) => {
  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  // const match = matchPath(props.history.location.pathname, {
  //   path: "/printexamview/:schoolId/:examId/:sessionId",
  //   exact: true,
  //   strict: false,
  // });

  // var schoolId = "";
  // var examId = "";
  // var sessionId = "";

  // if (match) {
  //   schoolId = match.params.schoolId;
  //   examId = match.params.examId;
  //   sessionId = match.params.sessionId;
  // }

  const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
  const [examSession, setExamSession] = useState(undefined);

  const [isLoading, setIsLoading] = useState(true);
  const [isQuestionsAvailable, setIsQuestionsAvailable] = useState(true);
  const [examSectionList, setExamSectionList] = useState([]);
  const [examData, setExamData] = useState(undefined);
  const [examName, setExamName] = useState("");
  const [showMarksUpdatingPopupForQuestionId, setShowMarksUpdatingPopupForQuestionId] = useState("");
  const [teacherFeedback, setTeacherFeedback] = useState(undefined);
  const [schoolId, setSchoolId] = useState(
    props.schoolId ? props.schoolId : ""
  );
  const [examId, setExamId] = useState(props.examId ? props.examId : "");
  const [sessionId, setSessionId] = useState(
    props.sessionId ? props.sessionId : ""
  );
  const [passPercentage, setPassPercentage] = useState(0);

  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }

  useEffect(() => {
    fetchExamFromServer();
  }, [schoolId, examId, sessionId]);

  const fetchExamFromServer = () => {
    var query = new Parse.Query("Quiz");
    query.equalTo("objectId", examId);

    query.limit(1000);
    query.first().then((result) => {
      if (result) {
        if (result.attributes.name) {
          setExamName(result.attributes.name);
        }
        if (result.attributes.passPercentage) {
          setPassPercentage(result.attributes.passPercentage);
        }
        fetchExamSession();
      }
    });
  };
  const fetchExamSession = () => {
    var query = new Parse.Query("QuizSession");

    query.equalTo("objectId", sessionId);

    query.limit(1000);
    query.first().then((result) => {
      if (result != null) {
        setExamSession(result);
        setTeacherFeedback(result.attributes.feedback)
        fetchExamSections();
      }
    });
  };

  const fetchExamSections = () => {
    var query = new Parse.Query("QuizSection");
    query.equalTo("quizId", examId);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      if (results != null) {
        if (results.length > 0) {
          var examSectionParseList = results;
          var sectionIds = [];
          var i = 0;
          for (i = 0; i < examSectionParseList.length; i++) {
            sectionIds.push(examSectionParseList[i].id);
          }
          fetchExamDataForSessionId(examSectionParseList, sectionIds);
        } else {
          setIsQuestionsAvailable(false);
          setIsLoading(false)
        }
      }
    });
  };

  const fetchExamDataForSessionId = (examSectionParseList, sectionIds) => {
    var query = new Parse.Query("QuizResults");
    query.equalTo("sessionId", sessionId);
    query.equalTo("quizId", examId);
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((examDataResults) => {
      if (examDataResults) {
        if (examDataResults.length > 0) {
          setExamData(examDataResults);
        }
        fetchExamQuestionsForExamIds(
          examSectionParseList,
          sectionIds,
          examDataResults
        );
      }
    });
  };

  const fetchExamQuestionsForExamIds = (
    examSectionParseList,
    sectionIds,
    examDataResults
  ) => {
    var query = new Parse.Query("QuizQuestion");
    query.containedIn("sectionId", sectionIds);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      var tempSectionList = [];
      setIsLoading(false)
      if (results != null) {
        var i = 0;

        for (i = 0; i < examSectionParseList.length; i++) {
          var j = 0;
          var tempQuestionList = [];

          var customExamSectionObject = new CustomExamSectionObject();
          customExamSectionObject.title =
            examSectionParseList[i].attributes.sectionName ?? "";
          customExamSectionObject.examSectionParseObject =
            examSectionParseList[i];

          for (j = 0; j < results.length; j++) {
            if (
              examSectionParseList[i].id === results[j].attributes.sectionId
            ) {
              var exmObject = results[j];

              var newExamObject = new CustomExamObject();
              newExamObject.question = exmObject.attributes.question ?? "";
              newExamObject.questionType = exmObject.attributes.type ?? "";
              newExamObject.questionNumber = i + 1;
              if (exmObject.attributes.dataType) {
                newExamObject.dataType = exmObject.attributes.dataType;
              }
              if (exmObject.attributes.options) {
                newExamObject.choices = [...exmObject.attributes.options];
              } else {
                newExamObject.choices = [];
              }

              if (
                exmObject.attributes.isDefaultQuestion &&
                exmObject.attributes.isDefaultQuestion === true
              ) {
                newExamObject.isDefaultQuestion = true;
              } else {
                newExamObject.isDefaultQuestion = false;
              }

              newExamObject.dataType = exmObject.attributes.dataType;
              if (
                exmObject.attributes.questionHint != null &&
                exmObject.attributes.questionHint !== ""
              ) {
                newExamObject.questionHint = exmObject.attributes.questionHint;
              } else {
                newExamObject.questionHint = "Type here";
              }
              if (exmObject.attributes.defaultMarks) {
                newExamObject.defaultMarks = exmObject.attributes.defaultMarks;
              }
              newExamObject.answerChoices = [];
              if (exmObject.attributes.options2) {
                newExamObject.choices2 = [...exmObject.attributes.options2];
              } else {
                newExamObject.choices2 = [];
              }
              if (exmObject.attributes.optionImgs) {
                newExamObject.optionImages = [
                  ...exmObject.attributes.optionImgs,
                ];
              } else {
                newExamObject.optionImages = [];
              }
              if (exmObject.attributes.option2Imgs) {
                newExamObject.optionImages2 = [
                  ...exmObject.attributes.option2Imgs,
                ];
              } else {
                newExamObject.optionImages2 = [];
              }

              if (
                exmObject.attributes.questionImage &&
                exmObject.attributes.questionImage.length > 0
              ) {
                newExamObject.questionImageURL =
                  exmObject.attributes.questionImage[0];
              } else {
                newExamObject.questionImageURL = undefined;
              }
              if (
                exmObject.attributes.quizVideo &&
                exmObject.attributes.quizVideo.length > 0
              ) {
                newExamObject.questionVideoURL =
                  exmObject.attributes.quizVideo;
              } else {
                newExamObject.questionVideoURL = undefined;
              }

              if (
                exmObject.attributes.attachments &&
                exmObject.attributes.attachments.length > 0
              ) {
                newExamObject.attachment =
                  exmObject.attributes.attachments[0];
              } else {
                newExamObject.attachment = undefined;
              }
              newExamObject.actualAnswer = exmObject.attributes.answer;
              newExamObject.selectedOptions = [];
              newExamObject.answerChoices = [];
              newExamObject.matchTheFollowingOptionsToDisplay = [];
              if (exmObject.attributes.options) {
                for (var z = 0; z < exmObject.attributes.options.length; z++) {
                  newExamObject.matchTheFollowingOptionsToDisplay.push(z + 1);
                  newExamObject.selectedOptions.push("");
                  if (
                    exmObject.attributes.type ===
                    Constants.EXAM_MATCH_THE_FOLLOWING
                  ) {
                    newExamObject.answerChoices.push("");
                  }
                }
              }

              newExamObject.answerError = false;

              newExamObject.examQuestionParseObject = exmObject;

              tempQuestionList.push(
                fillInitialValues(newExamObject, examDataResults)
              );
            }
          }

          customExamSectionObject.examQuestionsList = tempQuestionList;

          tempSectionList.push(customExamSectionObject);
        }
        //setprogressValue(100 / tempSectionList.length);
        setExamSectionList(tempSectionList);
      }
    });
  };

  function formatDate(d) {
    if (d == null || d === "" || typeof d === "string" || d instanceof String) {
      return "";
    } else {
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      return `${ye}-${mo}-${da}`;
    }
  }

  const fillInitialValues = (examQuestionObject, examDataResults) => {
    var objectToReturn = examQuestionObject;
    if (examDataResults && examDataResults.length > 0) {
      let tempAnswer;
      let tempExamDataAnswer = examDataResults.filter(
        (fd) =>
          fd.attributes.questionId === objectToReturn.examQuestionParseObject.id
      );
      if (tempExamDataAnswer && tempExamDataAnswer.length > 0) {
        objectToReturn.resultParseObject = tempExamDataAnswer[0];
        tempAnswer = tempExamDataAnswer[0].attributes.answer;
      }
      if (objectToReturn.questionType === Constants.EXAM_LONG_ANSWER) {
        var result = false;
        var resultImage = false;
        if (tempAnswer && tempAnswer.length > 0) {
          result = tempAnswer[0];
        }
        if (tempAnswer && tempAnswer.length > 1) {
          resultImage = tempAnswer[1]
        }

        if (result) {
          objectToReturn.answerText = result;
        }


        if (resultImage) {
          let name = resultImage._name.split(/_(.+)/)[1];
          let fileExtension = name.split(".");
          let tempFile = {
            actualImageName: name,
            fileExtension: fileExtension[1].toLowerCase(),
            imageName: fileExtension[0],
            image: resultImage,
            imageURL: resultImage._url,
          };
          objectToReturn.answerImageUploadProgress = 100;
          objectToReturn.answerImage = tempFile;
        }

      }

      else if (
        objectToReturn.questionType === Constants.EXAM_FILL_IN_THE_BLANKS
      ) {
        var result = false;
        if (tempAnswer && tempAnswer.length > 0) {
          result = tempAnswer[0];
        }


        if (result) {
          objectToReturn.answerText = result;
        }




      } else if (
        objectToReturn.questionType ===
        Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      } else if (
        objectToReturn.questionType ===
        Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST ||
        objectToReturn.questionType === Constants.EXAM_MATCH_THE_FOLLOWING ||
        objectToReturn.questionType === Constants.EXAM_ARRANGE_THE_FOLLOWING
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      }
    }

    return objectToReturn;
  };

  const tickMarkUI = () => {
    return (
      <div className="avatar-xs mr-4 ml-auto">
        <span className="avatar-title rounded-circle bg-success text-white font-size-5">
          <div style={{ fontSize: "15px" }}>
            <i className={"fa fa-check"}></i>
          </div>
        </span>
      </div>
    );
  };
  const wrongMarkUI = () => {
    return (
      <div className="avatar-xs mr-4 ml-auto">
        <span className="avatar-title rounded-circle bg-danger text-white font-size-5">
          <div style={{ fontSize: "15px" }}>
            <i className={"fa fa-times"}></i>
          </div>
        </span>
      </div>
    );
  };

  const marksUI = (marksObtained, max = 0) => {
    let color = "warning";
    if (marksObtained === 0) {
      color = "danger";
    } else if (marksObtained === max) {
      color = "success";
    }

    let classNameString = `avatar-title rounded-circle bg-white text-white font-size-5 border border-${color}`;

    let textClassString = `mt-2 text-${color}`;

    if (props.showResultUnderReview) {
      return (
        <>

        </>
      );
    } else {
      return (
        <div className="avatar-sm mr-4 ml-auto">
          <span className={classNameString}>
            <div style={{ fontSize: "15px" }}>
              {/* <i className={"fa fa-check"}></i> */}
              <Label className={textClassString}>
                {marksObtained}/{max}
              </Label>
            </div>
          </span>
        </div>
      );
    }

  };
  const checkWhetherCorrectAnswer = (type, examQuestionObject) => {

    let defaultMarks = examQuestionObject.defaultMarks ?? 1

    if (type === Constants.EXAM_FILL_IN_THE_BLANKS) {
      if (
        examQuestionObject.actualAnswer &&
        examQuestionObject.actualAnswer.length > 0 &&
        examQuestionObject.answerText &&
        examQuestionObject.answerText.length > 0 &&
        examQuestionObject.answerText.toLowerCase() ===
        examQuestionObject.actualAnswer[0].toLowerCase()
      ) {
        return (defaultMarks)
      }

    } else if (Constants.EXAM_LONG_ANSWER) {

    }

    return 0

  }

  const getTeacherName = (teacherId) => {
    if (props.teachers && props.teachers.length > 0) {
      let tempUser = props.teachers.filter((teacher) => {
        return teacher.id === teacherId;
      });
      if (tempUser && tempUser.length > 0) {
        return tempUser[0].attributes.Name;
      }
    }
    return "";
  };

  const UIForMarksForMatchTheFollowing = (examQuestionObject, keyInside) => {


    let numberOfOptions = examQuestionObject.actualAnswer.length
    let questionType = examQuestionObject.questionType
    let answerChoice = examQuestionObject.answerChoices[keyInside]
    let actualAnswer = examQuestionObject.actualAnswer[keyInside]
    let isCorrect = true
    if (answerChoice !== actualAnswer) {
      isCorrect = false
    }
    let min = 0
    let defaultMarks = examQuestionObject.defaultMarks ?? 1
    let max = Math.round((defaultMarks / numberOfOptions) * 100) / 100

    if (isCorrect) {
      min = max
    }

    return (<div className="float-right">
      {marksUI(
        min, max
      )}
      <div className="ml-auto">


        {((questionType === Constants.EXAM_MATCH_THE_FOLLOWING || questionType === Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST || questionType === Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST || questionType === Constants.EXAM_ARRANGE_THE_FOLLOWING) && (examQuestionObject.answerChoices == undefined ||
          examQuestionObject.answerChoices.length === 0)) && (
            <p className="ml-auto text-right mt- 3">Not answered</p>
          )}



      </div>

    </div>)
  }

  const UIForMarkAndUpdation = (examQuestionObject, examSectionObject) => {
    let questionType = examQuestionObject.questionType
    return (<div className="float-right">
      {marksUI(
        (examQuestionObject.resultParseObject && examQuestionObject.resultParseObject.attributes.marks != undefined) ? examQuestionObject.resultParseObject.attributes.marks : checkWhetherCorrectAnswer(Constants.EXAM_FILL_IN_THE_BLANKS, examQuestionObject),
        examQuestionObject.defaultMarks ?? 1
      )}
      <div className="ml-auto">


        {((questionType === Constants.EXAM_FILL_IN_THE_BLANKS || questionType === Constants.EXAM_LONG_ANSWER) && (examQuestionObject.answerText == undefined ||
          examQuestionObject.answerText.length === 0)) && (
            <p className="ml-auto text-right mt- 3">Not answered</p>
          )}

        {((questionType === Constants.EXAM_MATCH_THE_FOLLOWING || questionType === Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST || questionType === Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST || questionType === Constants.EXAM_ARRANGE_THE_FOLLOWING) && (examQuestionObject.answerChoices == undefined ||
          examQuestionObject.answerChoices.length === 0)) && (
            <p className="ml-auto text-right mt- 3">Not answered</p>
          )}



      </div>
      <div>

        {questionType === Constants.EXAM_LONG_ANSWER && examQuestionObject.resultParseObject &&
          examQuestionObject.resultParseObject.attributes.evaluatedBy ==
          undefined && <p>Not yet evaluated </p>}
      </div>
    </div>)
  }
  const getActualFileName = (result) => {
    if (typeof result === "object") {
      let name = result._name.split(/_(.+)/)[1];
      return name

    } else {
      return result
    }
  }

  const getFileExtension = (result) => {
    if (typeof result === "object") {
      let name = result._name.split(/_(.+)/)[1];
      let fileExtension = name.split(".");
      return fileExtension[1].toLowerCase()
    } else {
      return result
    }
  }

  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }

    return "bx bxs-file-blank";
  }
  function getVimeoUrl(link) {
    var splits = link.split("/")
    var videoId = splits[splits.length - 2];
    var videokey = splits[splits.length - 1];
    return "https://player.vimeo.com/video/" + videoId + "?h=" + videokey;
  }

  const getQuestionAdditionalUI = (examQuestionObject) => {
    if (examQuestionObject) {
      return (
        <>
          {(examQuestionObject.questionImageURL || examQuestionObject.attachment || examQuestionObject.questionVideoURL) && <div className="d-flex">
            {examQuestionObject.questionImageURL && (
              <div className="mb-3 mt-2 ml-3 mr-3">
                <img
                  src={examQuestionObject.questionImageURL}
                  style={{
                    width: "7rem",
                    height: "7rem",
                  }}
                  alt="Question Image"
                ></img>
              </div>
            )}

            {examQuestionObject.attachment && (
              <div>
                <div className="border float-right p-2">
                  <Row>
                    <div
                      className="ml-3 mt-1 text-center"
                      style={{ width: "2rem", height: "2rem" }}
                    >
                      <span
                        className="bg-white ml-0 text-primary h1 align-middle"
                        style={{ fontSize: "45px" }}
                      >
                        <i
                          className={getFileTypeImage(
                            getFileExtension(examQuestionObject.attachment)
                          )}
                        ></i>
                      </span>
                    </div>

                    <div className="float-right ml-4">
                      <div
                        className="text-muted font-weight-bold text-truncate"
                        style={{ width: "200px" }}
                      >
                        {getActualFileName(examQuestionObject.attachment)}
                      </div>
                      <div className="mt-2">
                        <Button
                          className="btn btn-sm btn-primary "
                          color="primary"
                          target="_blank"
                          rel="noopener noreferrer"
                          onClick={() => {
                            if (examQuestionObject.attachment && examQuestionObject.attachment._url) {
                              window.open(examQuestionObject.attachment._url, "_blank")
                            } else {
                              toastr.info("Please wait and try again...", { timeOut: 1000 })
                            }
                          }}
                        >
                          View
                        </Button>
                      </div>
                    </div>
                  </Row>
                </div>
              </div>
            )}
            {examQuestionObject.questionVideoURL && (
              <div className="mb-3 mt-2 mr-3">
                <div style={{ width: "10rem", height: "10rem" }}>
                  <Iframe
                    url={getVimeoUrl(examQuestionObject.questionVideoURL)}
                    style={{ cursor: "pointer" }}
                    display="initial"
                    scrolling={"no"}
                    allowFullScreen={true}
                    position="relative"
                    frameBorder={0}
                    className="mt-2"
                  />
                </div>
              </div>
            )}
          </div>}
        </>
      )
    }
  }

  const componentForQuestionType = (
    examQuestionObject,
    examSectionObject,
    key
  ) => {
    var questionType = examQuestionObject.questionType;

    switch (questionType) {
      case Constants.EXAM_FILL_IN_THE_BLANKS:
        return (


          <FormGroup key={key}>
            <Row>
              <Col lg="8" md="8">
                <Label style={{ fontWeight: "bold" }} className="mr-1">
                  {key + 1 + "."}
                </Label>
                <Label style={{ fontWeight: "bold" }} htmlFor="formSingleLine">
                  {examQuestionObject.question}
                </Label>
                <br />
                {getQuestionAdditionalUI(examQuestionObject)}

                <Label className="" style={{ minHeight: "100px", color: "gray" }}>
                  Ans. {examQuestionObject.answerText}
                </Label>
                <br />
                <Label className="mt-2" style={{ color: "gray" }}>
                  Correct Ans. {examQuestionObject.actualAnswer}
                </Label>
              </Col>
              <Col lg="4" md="4">
                {UIForMarkAndUpdation(examQuestionObject, examSectionObject)}

              </Col>

            </Row>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );

        break;

      case Constants.EXAM_LONG_ANSWER:

        let minimumHeightForLabel = "140px"
        if (examQuestionObject.answerImage) {
          minimumHeightForLabel = ""
        }

        return (
          <FormGroup key={key}>
            <Row>
              <Col lg="8" md="8">
                <Label style={{ fontWeight: "bold" }} className="mr-1">
                  {key + 1 + "."}
                </Label>
                <Label style={{ fontWeight: "bold" }} htmlFor="formMultiLine">
                  {examQuestionObject.question}
                </Label>

                <br />
                {getQuestionAdditionalUI(examQuestionObject)}

                <Label className="" style={{ minHeight: minimumHeightForLabel, color: "gray" }}>
                  Ans. {examQuestionObject.answerText}
                </Label>
                {examQuestionObject.answerImage &&
                  (

                    <div className="border p-3">
                      <Row>
                        <div className="ml-3">
                          <img
                            style={{ width: "5rem", height: "5rem" }}
                            className="rounded-circle avatar"
                            src={examQuestionObject.answerImage.imageURL}
                            alt=""
                          />
                        </div>
                        <div
                          className="float-right ml-4"
                          style={{ width: "200px" }}
                        >
                          <div className="text-muted font-weight-bold text-truncate">
                            {examQuestionObject.answerImage.actualImageName}
                          </div>
                          <div className="d-flex mt-3">
                            <Button
                              className="btn btn-primary float-right ml-2"
                              color="primary"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                if (examQuestionObject.answerImage && examQuestionObject.answerImage.imageURL) {
                                  window.open(examQuestionObject.answerImage.imageURL, "_blank")

                                } else {
                                  toastr.info("Please refresh and try again...", { timeOut: 1000 })
                                }
                              }}
                            >
                              View
                            </Button></div>

                        </div>
                      </Row>
                    </div>

                  )}
              </Col>
              <Col>
                {UIForMarkAndUpdation(examQuestionObject, examSectionObject)}
              </Col>
            </Row>

            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST:
        var choices = examQuestionObject.choices;

        return (
          <FormGroup key={key}>
            <Row>
              <Col lg="8" md="8">
                <Label style={{ fontWeight: "bold" }} className="mr-1">
                  {key + 1 + "."}
                </Label>
                <Label style={{ fontWeight: "bold" }}>
                  {examQuestionObject.question}
                </Label>
                <br />
                {getQuestionAdditionalUI(examQuestionObject)}


                {choices.map((choice, keyInside) => (
                  <div className="form-check  ml-3" key={keyInside}>
                    <div className="d-flex">
                      <input
                        className="form-check-input"
                        type="radio"
                        id={"radioButton" + key + keyInside}
                        value={choice}
                        checked={examQuestionObject.answerChoices.includes(
                          keyInside
                        )}
                      />

                      <div className="float-right">
                        <label
                          className="form-check-label"
                          htmlFor={"radioButton" + key + keyInside}
                        >
                          {choice}
                        </label>
                        {examQuestionObject.optionImages[keyInside] && (
                          <div className="mt-2 mb-2">
                            <img
                              src={examQuestionObject.optionImages[keyInside]}
                              style={{
                                width: "7rem",
                                height: "7rem",
                              }}
                              alt="Option image"
                            ></img>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <br />

                <Label className="mt-2" style={{ color: "gray" }}>
                  Correct Ans.
                </Label>
                {choices.map((choice, keyInside) => (
                  <div className="form-check  ml-3" key={keyInside}>
                    {examQuestionObject.actualAnswer && examQuestionObject.actualAnswer.includes(keyInside) && (
                      <div className="d-flex">
                        <input
                          className="form-check-input"
                          type="radio"
                          id={"radioButtonAnswer" + key + keyInside}
                          value={choice}
                          checked={true}
                        />

                        <div className="float-right">
                          <label
                            className="form-check-label"
                            htmlFor={"radioButton" + key + keyInside}
                          >
                            {choice}
                          </label>
                          {examQuestionObject.optionImages[keyInside] && (
                            <div className="mt-2 mb-2">
                              <img
                                src={examQuestionObject.optionImages[keyInside]}
                                style={{
                                  width: "7rem",
                                  height: "7rem",
                                }}
                                alt="Option image"
                              ></img>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </Col>
              <Col>
                {UIForMarkAndUpdation(examQuestionObject, examSectionObject)}

              </Col>
            </Row>

            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST:
        var choices = examQuestionObject.choices;

        return (
          <FormGroup key={key}>
            <Row>
              <Col lg="8" md="8">
                <Label style={{ fontWeight: "bold" }} className="mr-1">
                  {key + 1 + "."}
                </Label>
                <Label style={{ fontWeight: "bold" }}>
                  {examQuestionObject.question}
                </Label>
                <br />
                {getQuestionAdditionalUI(examQuestionObject)}
                {choices.map((choice, keyInside) => (
                  <div className="form-check  ml-3" key={keyInside}>
                    <div className="d-flex">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id={"checkboxButton" + key + keyInside}
                        value={choice}
                        checked={examQuestionObject.answerChoices.includes(
                          keyInside
                        )}
                      />

                      <div className="float-right">
                        <label
                          className="form-check-label"
                          htmlFor={"checkboxButton" + key + keyInside}
                        >
                          {choice}
                        </label>
                        {examQuestionObject.optionImages[keyInside] && (
                          <div className="mt-2 mb-2">
                            <img
                              src={examQuestionObject.optionImages[keyInside]}
                              style={{
                                width: "7rem",
                                height: "7rem",
                              }}
                              alt="Option image"
                            ></img>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}

                <br />

                <Label className="mt-2" style={{ color: "gray" }}>
                  Correct Ans.
                </Label>
                {choices.map((choice, keyInside) => (
                  <div className="form-check  ml-3" key={keyInside}>
                    {examQuestionObject.actualAnswer && examQuestionObject.actualAnswer.includes(keyInside) && (
                      <div className="d-flex">
                        <input
                          className="form-check-input"
                          type="checkbox"
                          id={"checkboxButtonAnswer" + key + keyInside}
                          value={choice}
                          checked={true}
                        />

                        <div className="float-right">
                          <label
                            className="form-check-label"
                            htmlFor={"checkboxButton" + key + keyInside}
                          >
                            {choice}
                          </label>
                          {examQuestionObject.optionImages[keyInside] && (
                            <div className="mt-2 mb-2">
                              <img
                                src={examQuestionObject.optionImages[keyInside]}
                                style={{
                                  width: "7rem",
                                  height: "7rem",
                                }}
                                alt="Option image"
                              ></img>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                ))}
              </Col>
              <Col>
                {UIForMarkAndUpdation(examQuestionObject, examSectionObject)}

              </Col>
            </Row>
            <hr
              style={{
                height: "1%",

                color: "#fffffc",
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.EXAM_MATCH_THE_FOLLOWING:
        return (
          <div className="widget mb-2 pb-2" key={key}>
            <div>
              <FormGroup key={key}>
                <Label style={{ fontWeight: "bold" }} className="mr-1">
                  {key + 1 + "."}
                </Label>
                <Label style={{ fontWeight: "bold" }}>
                  {examQuestionObject.question}
                </Label>
                <br />
                {getQuestionAdditionalUI(examQuestionObject)}

                <div className="table-responsive mb-0">
                  <table
                    id={"matchTheFollowing" + key}
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th>Column A</th>
                        <th>Column B</th>
                        <th>Student Answered</th>
                      </tr>
                    </thead>
                    <tbody>
                      {examQuestionObject.choices.map((choice, keyInside) => (
                        <tr>
                          <td>
                            <div key={keyInside} className="d-flex">
                              {/* <label className="mr-2">{keyInside + 1}.</label> */}
                              <div className="float-right">
                                <label className="form-check-label">
                                  {choice}
                                </label>
                                {examQuestionObject.optionImages[keyInside] && (
                                  <div className="mt-2">
                                    <img
                                      src={
                                        examQuestionObject.optionImages[
                                        keyInside
                                        ]
                                      }
                                      style={{
                                        width: "7rem",
                                        height: "7rem",
                                      }}
                                      alt="Option image"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div key={keyInside} className="d-flex">
                              <label className="mr-2">{keyInside + 1}.</label>
                              <div className="float-right">
                                <label className="form-check-label">
                                  {examQuestionObject.choices2[keyInside]}
                                </label>
                                {examQuestionObject.optionImages2[
                                  keyInside
                                ] && (
                                    <div className="mt-2">
                                      <img
                                        src={
                                          examQuestionObject.optionImages2[
                                          keyInside
                                          ]
                                        }
                                        style={{
                                          width: "7rem",
                                          height: "7rem",
                                        }}
                                        alt="Option image"
                                      ></img>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                position: "relative",
                              }}
                            >
                              <Label>
                                {examQuestionObject.answerChoices[keyInside] !=
                                  undefined &&
                                  examQuestionObject.answerChoices[keyInside] !==
                                  ""
                                  ? examQuestionObject.answerChoices[
                                  keyInside
                                  ] + 1
                                  : "-"}
                              </Label>
                              {UIForMarksForMatchTheFollowing(examQuestionObject, keyInside)}
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>

                <Label className="mt-2" style={{ color: "gray" }}>
                  Correct Ans.
                </Label>

                <div className="table-responsive mb-0">
                  <table
                    id={"matchTheFollowingAnswer" + key}
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th>Column A</th>
                        <th>Column B</th>
                        <th>Correct Answers</th>
                      </tr>
                    </thead>
                    <tbody>
                      {examQuestionObject.choices.map((choice, keyInside) => (
                        <tr>
                          <td>
                            <div key={keyInside} className="d-flex">
                              {/* <label className="mr-2">{keyInside + 1}.</label> */}
                              <div className="float-right">
                                <label className="form-check-label">
                                  {choice}
                                </label>
                                {examQuestionObject.optionImages[keyInside] && (
                                  <div className="mt-2">
                                    <img
                                      src={
                                        examQuestionObject.optionImages[
                                        keyInside
                                        ]
                                      }
                                      style={{
                                        width: "7rem",
                                        height: "7rem",
                                      }}
                                      alt="Option image"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div key={keyInside} className="d-flex">
                              <label className="mr-2">{keyInside + 1}.</label>
                              <div className="float-right">
                                <label className="form-check-label">
                                  {examQuestionObject.choices2[keyInside]}
                                </label>
                                {examQuestionObject.optionImages2[
                                  keyInside
                                ] && (
                                    <div className="mt-2">
                                      <img
                                        src={
                                          examQuestionObject.optionImages2[
                                          keyInside
                                          ]
                                        }
                                        style={{
                                          width: "7rem",
                                          height: "7rem",
                                        }}
                                        alt="Option image"
                                      ></img>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <Label>
                              {examQuestionObject.actualAnswer[keyInside] + 1}
                            </Label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </FormGroup>
            </div>
          </div>
        );

        break;
      case Constants.EXAM_ARRANGE_THE_FOLLOWING:
        return (
          <div className="widget mb-2 pb-2" key={key}>
            <div>
              <FormGroup key={key}>
                <Row>
                  <Col lg="10" md="10">
                    <Label style={{ fontWeight: "bold" }} className="mr-1">
                      {key + 1 + "."}
                    </Label>
                    <Label style={{ fontWeight: "bold" }}>
                      {examQuestionObject.question}
                    </Label>

                    <br />
                    {getQuestionAdditionalUI(examQuestionObject)}

                  </Col>
                  <Col>
                    {UIForMarkAndUpdation(examQuestionObject, examSectionObject)}

                  </Col></Row>
                <div className="table-responsive mb-0 mt-3">
                  <table
                    id={"arrangeTheFollowing" + key}
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th>Jumbled Order</th>

                        <th>Student Answered</th>
                      </tr>
                    </thead>
                    <tbody>
                      {examQuestionObject.choices.map((choice, keyInside) => (
                        <tr>
                          <td>
                            <div key={keyInside} className="d-flex">
                              <label className="mr-2">{keyInside + 1}.</label>
                              <div className="float-right">
                                <label className="form-check-label">
                                  {choice}
                                </label>
                                {examQuestionObject.optionImages[keyInside] && (
                                  <div className="mt-2">
                                    <img
                                      src={
                                        examQuestionObject.optionImages[
                                        keyInside
                                        ]
                                      }
                                      style={{
                                        width: "7rem",
                                        height: "7rem",
                                      }}
                                      alt="Option image"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div
                              style={{
                                height: "100%",
                                width: "100%",
                                position: "relative",
                              }}
                            >
                              <Label>
                                {examQuestionObject.answerChoices[keyInside] !=
                                  undefined &&
                                  examQuestionObject.answerChoices[keyInside] !==
                                  ""
                                  ? examQuestionObject.answerChoices[
                                  keyInside
                                  ] + 1
                                  : "-"}
                              </Label>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <Label className="mt-2" style={{ color: "gray" }}>
                  Correct Ans.
                </Label>
                <div className="table-responsive mb-0">
                  <table
                    id={"arrangeTheFollowingAnswer" + key}
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th>Jumbled Order</th>

                        <th>Correct Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      {examQuestionObject.choices.map((choice, keyInside) => (
                        <tr>
                          <td>
                            <div key={keyInside} className="d-flex">
                              <label className="mr-2">{keyInside + 1}.</label>
                              <div className="float-right">
                                <label className="form-check-label">
                                  {choice}
                                </label>
                                {examQuestionObject.optionImages[keyInside] && (
                                  <div className="mt-2">
                                    <img
                                      src={
                                        examQuestionObject.optionImages[
                                        keyInside
                                        ]
                                      }
                                      style={{
                                        width: "7rem",
                                        height: "7rem",
                                      }}
                                      alt="Option image"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td>
                            <Label>
                              {examQuestionObject.actualAnswer[keyInside] + 1}
                            </Label>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </FormGroup>
            </div>
          </div>
        );

        break;
    }
  };

  const getTotalMarks = () => {
    let returnValue = 0;
    let totalMarksObtained = 0;
    let totalMarks = 0;

    for (var i = 0; i < examSectionList.length; i++) {
      if (examSectionList[i].examQuestionsList) {
        for (var j = 0; j < examSectionList[i].examQuestionsList.length; j++) {
          let examQuestionObject = examSectionList[i].examQuestionsList[j];
          if (examQuestionObject.questionType === Constants.EXAM_LONG_ANSWER) {
            totalMarks += examQuestionObject.defaultMarks ?? 1;
            if (
              examQuestionObject.resultParseObject &&
              examQuestionObject.resultParseObject.attributes.marks != undefined
            ) {
              if (examQuestionObject.resultParseObject.attributes.marks >= 1) {
                totalMarksObtained +=
                  examQuestionObject.resultParseObject.attributes.marks;
              }
            }
          } else if (
            examQuestionObject.questionType ===
            Constants.EXAM_FILL_IN_THE_BLANKS
          ) {
            totalMarks += examQuestionObject.defaultMarks ?? 1;
            if (
              examQuestionObject.resultParseObject &&
              examQuestionObject.resultParseObject.attributes.marks != undefined
            ) {
              if (examQuestionObject.resultParseObject.attributes.marks >= 1) {
                totalMarksObtained +=
                  examQuestionObject.resultParseObject.attributes.marks;
              }
            } else {
              if (
                examQuestionObject.actualAnswer &&
                examQuestionObject.actualAnswer.length > 0 &&
                examQuestionObject.answerText &&
                examQuestionObject.answerText.length > 0 &&
                examQuestionObject.answerText.toLowerCase() ===
                examQuestionObject.actualAnswer[0].toLowerCase()
              ) {
                totalMarksObtained += examQuestionObject.defaultMarks ?? 1;
              }
            }
          } else if (
            examQuestionObject.questionType ===
            Constants.EXAM_MATCH_THE_FOLLOWING
          ) {
            totalMarks += examQuestionObject.defaultMarks ?? 1;
            for (var k = 0; k < examQuestionObject.actualAnswer.length; k++) {
              if (
                examQuestionObject.answerChoices[k] ===
                examQuestionObject.actualAnswer[k]
              ) {
                if (examQuestionObject.defaultMarks) {
                  totalMarksObtained +=
                    examQuestionObject.defaultMarks /
                    examQuestionObject.actualAnswer.length;
                } else {
                  totalMarksObtained += 1;
                }
              }
            }
          } else if (
            examQuestionObject.questionType ===
            Constants.EXAM_ARRANGE_THE_FOLLOWING
          ) {
            totalMarks += examQuestionObject.defaultMarks ?? 1;
            let isCorrect = true;

            for (var k = 0; k < examQuestionObject.actualAnswer.length; k++) {
              if (
                examQuestionObject.answerChoices[k] !==
                examQuestionObject.actualAnswer[k]
              ) {
                isCorrect = false;
              }
            }
            if (isCorrect) {
              totalMarksObtained += examQuestionObject.defaultMarks ?? 1;
            }
          } else if (
            examQuestionObject.questionType ===
            Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST ||
            examQuestionObject.questionType ===
            Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
          ) {
            totalMarks += examQuestionObject.defaultMarks ?? 1;
            if (
              JSON.stringify(examQuestionObject.answerChoices) ===
              JSON.stringify(examQuestionObject.actualAnswer)
            ) {
              totalMarksObtained += examQuestionObject.defaultMarks ?? 1;
            }
          }
        }
      }
    }

    returnValue = (totalMarksObtained / totalMarks) * 100;

    return {
      percentString: returnValue.toFixed(2),
      totalMarksObtained: totalMarksObtained,
      totalMarks: totalMarks,
    };
  };

  // const getFinalScore = () => {
  //   let returnValue = 0;

  //   let totalCorrectAnswers = 0;
  //   let totalQuestions = 0;

  //   for (var i = 0; i < examSectionList.length; i++) {
  //     if (examSectionList[i].examQuestionsList) {
  //       for (var j = 0; j < examSectionList[i].examQuestionsList.length; j++) {
  //         let examQuestionObject = examSectionList[i].examQuestionsList[j];
  //         if (examQuestionObject.questionType === Constants.EXAM_LONG_ANSWER) {
  //           totalQuestions++;
  //           if (
  //             examQuestionObject.resultParseObject &&
  //             examQuestionObject.resultParseObject.attributes.marks != undefined
  //           ) {
  //             if (examQuestionObject.resultParseObject.attributes.marks >= 1) {
  //               totalCorrectAnswers++;
  //             }
  //           }
  //         } else if (
  //           examQuestionObject.questionType ===
  //           Constants.EXAM_FILL_IN_THE_BLANKS
  //         ) {
  //           totalQuestions++;
  //           if (
  //             examQuestionObject.resultParseObject &&
  //             examQuestionObject.resultParseObject.attributes.marks != undefined
  //           ) {
  //             if (examQuestionObject.resultParseObject.attributes.marks >= 1) {
  //               totalCorrectAnswers++;
  //             }
  //           } else {
  //             if (
  //               examQuestionObject.actualAnswer &&
  //               examQuestionObject.actualAnswer.length > 0 &&
  //               examQuestionObject.answerText &&
  //               examQuestionObject.answerText.length > 0 &&
  //               examQuestionObject.answerText.toLowerCase() ===
  //                 examQuestionObject.actualAnswer[0].toLowerCase()
  //             ) {
  //               totalCorrectAnswers++;
  //             }
  //           }
  //         } else if (
  //           examQuestionObject.questionType ===
  //           Constants.EXAM_MATCH_THE_FOLLOWING
  //         ) {
  //           for (var k = 0; k < examQuestionObject.actualAnswer.length; k++) {
  //             totalQuestions++;
  //             if (
  //               examQuestionObject.answerChoices[k] ===
  //               examQuestionObject.actualAnswer[k]
  //             ) {
  //               totalCorrectAnswers++;
  //             }
  //           }
  //         } else if (
  //           examQuestionObject.questionType ===
  //           Constants.EXAM_ARRANGE_THE_FOLLOWING
  //         ) {
  //           totalQuestions++;
  //           let isCorrect = true;

  //           for (var k = 0; k < examQuestionObject.actualAnswer.length; k++) {
  //             if (
  //               examQuestionObject.answerChoices[k] !==
  //               examQuestionObject.actualAnswer[k]
  //             ) {
  //               isCorrect = false;
  //             }
  //           }
  //           if (isCorrect) {
  //             totalCorrectAnswers++;
  //           }
  //         } else if (
  //           examQuestionObject.questionType ===
  //             Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST ||
  //           examQuestionObject.questionType ===
  //             Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
  //         ) {
  //           totalQuestions++;

  //           if (
  //             JSON.stringify(examQuestionObject.answerChoices) ===
  //             JSON.stringify(examQuestionObject.actualAnswer)
  //           ) {
  //             totalCorrectAnswers++;
  //           }
  //         }
  //       }
  //     }
  //   }

  //   returnValue = (totalCorrectAnswers / totalQuestions) * 100;

  //   return returnValue.toFixed(2);
  // };

  return (
    <React.Fragment>
      <div className="">
        <Container>
          {isLoading ? (
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  {props.t("Loading Results...")}{" "}
                </Link>
              </div>
            </Col>
          ) : (
            <Row className="text-center">
              <Col lg={12} md={12} sm={12} className="pl-0 pb-0">
                <center>
                  <div className="text-center">
                    <div className="text-left">
                      {examSectionList.map((examSection, keyTitle) => (
                        <Col lg={12} className="mx-auto p-0 " key={keyTitle}>
                          <div>
                            <AvForm
                              className="outer-repeater"
                              style={{ border: "1px" }}
                              onValidSubmit={() => { }}
                            >
                              <div
                                data-repeater-list="outer-group"
                                className="outer"
                              >
                                <div data-repeater-item className="outer">
                                  <h5>{props.t(examSection.title) + " :"}</h5>
                                  <hr />

                                  {examSection.examQuestionsList.map(
                                    (examQuestionObject, keyQuestion) =>
                                      componentForQuestionType(
                                        examQuestionObject,
                                        examSection,
                                        keyQuestion
                                      )
                                  )}
                                  {examSection.examQuestionsList.length ===
                                    0 && (
                                      <h6 className="mb-3">
                                        No Questions in this section.
                                      </h6>
                                    )}
                                </div>
                              </div>
                            </AvForm>
                          </div>
                        </Col>
                      ))}
                      {examSectionList.length > 0 && !props.showResultUnderReview && (
                        <div>
                          <hr />
                          <div

                            className={
                              getTotalMarks().percentString > passPercentage
                                ? "bg-success p-3"
                                : "bg-danger p-3"
                            }
                          >
                            <h4 className="text-white ">
                              {getTotalMarks().percentString > passPercentage
                                ? "Passed"
                                : "Failed"}
                            </h4>
                            {
                              teacherFeedback && <h5 className="text-white ">{`Teacher feedback : ${teacherFeedback}`}</h5>
                            }
                            {
                              <h5 className="text-white">{`Marks Scored : ${getTotalMarks().totalMarksObtained
                                }`}</h5>
                            }
                            {
                              <h5 className="text-white">{`Total Marks : ${getTotalMarks().totalMarks
                                }`}</h5>
                            }
                            <h5 className="text-white">{`Final Score is ${getTotalMarks().percentString
                              }%`}</h5>
                          </div>
                          <hr />
                        </div>
                      )}
                      {
                        props.showResultUnderReview && <div>
                          <hr />
                          <div

                            className={
                              "bg-warning p-3"
                            }
                          >
                            <h4 className="text-white ">
                              {"Status - Under Review"}
                            </h4>
                            {/* {
                            teacherFeedback && <h5 className="text-white ">{`Teacher feedback : ${teacherFeedback}`}</h5>
                          }
                          {
                            <h5 className="text-white">{`Marks Scored : ${getTotalMarks().totalMarksObtained
                              }`}</h5>
                          }
                          {
                            <h5 className="text-white">{`Total Marks : ${getTotalMarks().totalMarks
                              }`}</h5>
                          }
                          <h5 className="text-white">{`Final Score is ${getTotalMarks().percentString
                            }%`}</h5> */}
                          </div>
                          <hr />
                        </div>
                      }
                    </div>
                  </div>
                </center>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;

  return { selectedSchool };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
    })(ExamResultComponent)
  )
);
class CustomExamObject {
  question = "";
  questionType = null;
  examQuestionParseObject = null;
  answerText = null;
  answerChoices = [];
  choices = [];
  choices2 = [];
  questionHint = "Type here";
  matchTheFollowingOptionsToDisplay = [];
  selectedOptions = [];
  questionImageURL = null;
  questionVideoURL = null;
  attachment = null;
  actualAnswer = [];
  optionImages = [];
  optionImages2 = [];
  isDefaultQuestion = false;
  resultParseObject = null;
  answerImage = null;
  answerImageUploadProgress = 0;

  constructor(
    question,
    questionType,
    examQuestionParseObject,
    answerText,
    answerChoices,
    choices,
    choices2,
    questionHint,
    selectedOptions,
    matchTheFollowingOptionsToDisplay,
    questionImageURL,
    questionVideoURL,
    attachment,
    actualAnswer = [],
    optionImages = [],
    optionImages2 = [],
    resultParseObject,
    answerImage = null,
    answerImageUploadProgress = 0,
    isDefaultQuestion = false
  ) {
    this.question = question;
    this.questionType = questionType;
    this.examQuestionParseObject = examQuestionParseObject;
    this.answerText = answerText;
    this.answerChoices = answerChoices;
    this.choices = choices;
    this.choices2 = choices2;
    this.questionHint = questionHint;
    this.selectedOptions = selectedOptions;
    this.matchTheFollowingOptionsToDisplay = matchTheFollowingOptionsToDisplay;
    this.questionImageURL = questionImageURL;
    this.questionVideoURL = questionVideoURL;
    this.attachment = attachment;
    this.actualAnswer = actualAnswer;
    this.isDefaultQuestion = isDefaultQuestion;
    this.optionImages = optionImages;
    this.optionImages2 = optionImages2;
    this.resultParseObject = resultParseObject;
    this.answerImage = answerImage;
    this.answerImageUploadProgress = answerImageUploadProgress;
  }
}
class CustomExamSectionObject {
  examQuestionsList = [];
  title = "";
  examSectionParseObject = null;

  constructor(examQuestionsList, examSectionParseObject, title) {
    this.examQuestionsList = examQuestionsList;
    this.title = title;
    this.examSectionParseObject = examSectionParseObject;
  }
}
