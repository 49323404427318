import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Button,
    Container,
} from "reactstrap";
import Parse from "parse";
import toastr from "toastr";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {  } from "../../store/actions";
import * as WhiteLable from "../../components/Common/WhiteLable";

const VisitorForm = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/lilTriangleStall/:eventName",
        exact: true,
        strict: false,
    });

    const eventName = match && match.params.eventName

    const [isLoading, setIsLoading] = useState(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);

    const [name, setName] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [instituteName, setinstituteName] = useState(undefined);
  
    const [cityName, setCityName] = useState(undefined);
    const [cityError, setCityError] = useState(undefined);
    
    const [emailId, setEmailId] = useState(undefined);
    const [emailError, setEmailError] = useState(undefined);
    const [invalidEmailError, setInvalidEmailError] = useState(false);

    const [nameError, setNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState(false);
    const [invalidPhoneError, setInvalidPhoneError] = useState(false);
    const [instituteNameError, setinstituteNameError] = useState(false);
    const [submittingForm, setSubmittingForm] = useState(false);
    const [alldetails, setAlldetails] = useState(
        []
    );


    

    useEffect(() => {
        if (isFormSubmitted) {
            setAlldetails([
                { left: "Name", right: name },
                { left: "Institute/School: ", right: instituteName },
                { left: "City", right: cityName },
                { left: "Phone Number", right: phoneNumber },
                { left: "Email", right: emailId },
               
            ])
        }
    }, [isFormSubmitted])

    

    

   

    const isPhoneInvalid = (b) => {
        var re = new RegExp("^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$");
        if (re.test(b)) {
            return false
        }
        else {
            return true
        }
    }

    const isValidateEmail = (value) => {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (value && value.match(mailformat)) {
            return true;
        } else {
            return false;
        }
    }

    const isFormValid = () => {

        let canProceedToSave = true

        if (name == undefined || name.trim().length === 0) {
            canProceedToSave = false
            setNameError(true)
        } else {
            setNameError(false)
        }

        if (cityName == undefined || cityName.length === 0) {
            canProceedToSave = false
            setCityError(true)
        } else {
            setCityError(false)
        }

        if (instituteName == undefined || instituteName.length === 0) {
            canProceedToSave = false
            setinstituteNameError(true)
        } else {
            setinstituteNameError(false)
        }

        if (phoneNumber == undefined || phoneNumber.trim().length === 0) {
            canProceedToSave = false
            setPhoneNumberError(true)
        } else {
            setPhoneNumberError(false)
        }

        if (isPhoneInvalid(phoneNumber)) {
            canProceedToSave = false
            setInvalidPhoneError(true)
        } else {
            setInvalidPhoneError(false)
        }

        if (isValidateEmail(emailId)) {
            setInvalidEmailError(false)
        } else {
            canProceedToSave = false
            setInvalidEmailError(true)         
        }


        

        if (instituteName == undefined || instituteName.trim().length === 0) {
            canProceedToSave = false
            setinstituteNameError(true)
        } else {
            setinstituteNameError(false)
        }

        


        if (canProceedToSave) {
            saveForm()
        }
    }

    const saveForm = () => {
        setSubmittingForm(true)
        const Visitor = Parse.Object.extend('lilEnquiries');
        var visitor = new Visitor();

        visitor.set("name", name)
        visitor.set("schoolDetails", instituteName)
        visitor.set("city", cityName)
        visitor.set("phone", phoneNumber)
        visitor.set("email", emailId)
        visitor.set("eventName", eventName)
        visitor.save().then(
            (result) => {
                setSubmittingForm(false)
                setIsFormSubmitted(true)
            },
            (error) => {
                setSubmittingForm(false)
            }
        );
    }

    
    

    const borderStyle = {
        border: "2px solid lightGray",
        borderRadius: "50px 50px",
        minHeight: `calc(100vh - 170px)`,
        position: "relative"
    }

    const getTextUI = (leftLabel, rightLabel, key) => {
        return (<React.Fragment key={key}><Row lg={5} md={8} sm={12} xs={12} className="justify-content-center mt-1"><div className="text-left col-6 "> {leftLabel} < Label className="float-right" >:</Label ></div > <div className="text-left col-6">{rightLabel}</div></Row></React.Fragment>)
    }


    return (
        <React.Fragment>
            <Container
                fluid={true}
                className={match ? "" : "page-content"}
            >
                {!isLoading && !isFormSubmitted && (
                    <Row>
                        <Col lg={!match ? 12 : 8} className="mx-auto">
                            {(match) && (
                                <div className="text-center mt-3">
                                    <img
                                            src={WhiteLable.getAppLogoWithName()}
                                            width="200"
                                            alt=""
                                            className="img-thumbnail rounded-circle"
                                        />
                                    <h3>
                                        {props.t(`Welcome to lilTriangle Stall`)}
                                    </h3>

                                    <h6 className="mt-3">
                                        {props.t("Please provide the details")}
                                    </h6>
                                </div>
                            )}
                            <Card>
                                <CardBody>
                                    <AvForm
                                        className="outer-repeater"
                                        style={{ border: "1px" }}
                                        onValidSubmit={isFormValid}
                                    >
                                        <div data-repeater-list="outer-group" className="outer">
                                            <div data-repeater-item className="outer">
                                              
                                                <FormGroup>
                                                    <Label htmlFor="vf-name">
                                                        {"Name of the visitor"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-name" className="text-danger">
                                                        *
                                                    </Label>
                                                    {nameError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <AvField
                                                        type="text"
                                                        name={"vf-name-f"}
                                                        placeholder={"Type here"}
                                                        className="form-control"
                                                        value={name}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setNameError(true)
                                                            } else {
                                                                setNameError(false)
                                                            }
                                                            setName(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>

                                               


                                                <FormGroup>
                                                    <Label htmlFor="vf-instituteName">
                                                        {"Name of the Institute/School"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-instituteName" className="text-danger">
                                                        *
                                                    </Label>
                                                    {instituteNameError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}


                                                    <AvField
                                                        type="text"
                                                        name={"vf-instituteName-f"}
                                                        placeholder={"Type here"}
                                                        className="form-control"
                                                        value={instituteName}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setinstituteNameError(true)
                                                            } else {
                                                                setinstituteNameError(false)
                                                            }
                                                            setinstituteName(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>


                                                 <FormGroup>
                                                    <Label htmlFor="vf-city">
                                                        {"City"}{" "}
                                                    </Label>
                                                    <Label htmlFor="vf-city" className="text-danger">
                                                        *
                                                    </Label>
                                                    {cityError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}


                                                    <AvField
                                                        type="text"
                                                        name={"vf-meetingPerson-f"}
                                                        placeholder={"Type here"}
                                                        className="form-control"
                                                        value={cityName}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setCityError(true)
                                                            } else {
                                                                setCityError(false)
                                                            }
                                                            setCityName(e.target.value)
                                                        }}
                                                    />
                                                </FormGroup>

                                                <FormGroup>
                                                    <Label htmlFor="vf-phone"> {"Phone number"}</Label>
                                                    <Label htmlFor="vf-phone" className="text-danger">*</Label>
                                                    {phoneNumberError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    {
                                                        invalidPhoneError && <Label className="label-xsmall text-danger ml-2">
                                                            (Please enter a valid phone number)
                                                        </Label>
                                                    }
                                                    <AvField
                                                        name={"vf-phone-f"}
                                                        className="inner form-control noscroll"
                                                        placeholder={props.t("Enter phone no")}
                                                        value={phoneNumber}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setPhoneNumberError(true)
                                                            } else {
                                                                setPhoneNumberError(false)
                                                            }
                                                            setPhoneNumber(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>
                                               <FormGroup>
                                                    <Label htmlFor="vf-phone"> {"Email Id"}</Label>
                                                    <Label htmlFor="vf-phone" className="text-danger">*</Label>
                                                    {emailError && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    {
                                                        invalidEmailError && <Label className="label-xsmall text-danger ml-2">
                                                            (Please enter a valid email)
                                                        </Label>
                                                    }
                                                    <AvField
                                                        name={"vf-phone-f"}
                                                        className="inner form-control noscroll"
                                                        placeholder={props.t("Enter email id")}
                                                        value={emailId}
                                                        onChange={(e) => {
                                                            if (e.target.value == undefined || e.target.value.length === 0) {
                                                                setEmailError(true)
                                                            } else {
                                                                setEmailError(false)
                                                            }
                                                            setEmailId(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>


                                                <div style={{ display: "flex" }}>
                                                    <Button
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            width: "180px",
                                                        }}
                                                        type="submit"
                                                        color="primary"
                                                        disabled={submittingForm}
                                                    >
                                                        {submittingForm && < i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>}
                                                        {props.t("SUBMIT")}
                                                    </Button>
                                                </div>

                                            </div>
                                        </div>
                                    </AvForm>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}

                {isFormSubmitted && (

                    <Card style={{ height: `calc(100vh)` }}>
                        <CardBody>
                            <center>
                                <div style={borderStyle} className="mt-2 pt-5 mb-5 pb-5">
                                    <h1 className="mt-1 pt-1">
                                        <i style={{ fontSize: "50px" }}
                                            className="fa fa-check-circle align-middle bx-flashing text-success ml-2 pb-2"
                                            aria-hidden="true"
                                        ></i>
                                    </h1>
                                    <h1 className="mt-1 pt-1">
                                        Dear Delegate,Thank you for visiting us !
                                    </h1>

                                    <div className="p-1 pl-4">
                                        {alldetails.map((item, key) => (
                                            getTextUI(item.left, item.right, key)
                                        ))}

                                    </div>
                                    
                                     <p className="text-success">Perfect technology partner for your institute/school.</p>
                                    <div >
                                        
                                        <div>
                                            <button className="btn btn-success mb-2" onClick={() => {
                                                // window.location.reload()
                                                setEmailId(undefined)
                                                setPhoneNumber(undefined)
                                                setCityName(undefined)
                                                setName(undefined)
                                                setinstituteName(undefined)
                                                setIsFormSubmitted(false);
                                            }}>
                                                Done
                                            </button>
                                        </div>
                                        
                                    
                                       
                                    </div>
                                </div>
                            </center>
                        </CardBody>
                    </Card>
                )}



                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Visitor Form...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment >
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            
        })(VisitorForm)
    )
);