import React,{useEffect} from 'react';
import { Container,Row ,Col,Card,CardBody} from "reactstrap";
import {
    setSelecetdSchool,
    showPricingPopup
} from "../../store/actions";
import { withRouter} from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withNamespaces } from "react-i18next";


//Import images
import feature1 from "../../assets/images/undraw_subscription.svg";




const SubscriptionExpiry = (props) => {

    var match = matchPath(props.history.location.pathname, {
        path: "/school-subscription/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        } else {
            
        }
    }, [props.selectedSchool, schoolId]);

    const features = [
        { id: 1, desc: "Please write to sales@liltriangle.com " },
        { id: 2, desc: "Call on +91 7676548853" },
    ];
         

    return (
        <React.Fragment>
            <section className="section" id="features">
                <Container>
                    <Card>
                        <CardBody>
                            <Row>
                                <Col lg="12">
                                    <div className="text-center mb-5">
                                        <h4 className='text-danger'>Subscription Expired !</h4>

                                        <div className=" m-4">We hope you had a great time managing your institute with lilTriangle. To continue enjoying professional solutions from lilTriangle, please renew your subscription now</div>
                                    
                                    </div>
                                </Col>
                            </Row>
                        
                            <Row className="align-items-center pt-4">
                                <Col md="2" sm="2">
                                   
                                </Col>
                                <Col md="5" sm="7">
                                    <div>
                                        <img src={feature1} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>
                                <Col md="5" className="">
                                    {/* FeatureBox */}
                                
                                    <div className="mt-4 mt-md-auto">
                                        <div className="d-flex align-items-center mb-2">
                                            <div className="features-number font-weight-semibold display-4 mr-3">{"1"}</div>
                                            <h4 className="mb-0 text-success">{"Renew Your Subscription"}</h4>
                                        </div>
                                        <p className="">{"To renew, talk to lilTriangle customer care now"}</p>
                                        <div className="text-muted mt-4">
                                            {
                                                features.map((feature, key) =>
                                                    <p key={key} className={feature.id === 1 ? "mb-2" : ""}><i className="mdi mdi-circle-medium text-success mr-1"></i>{feature.desc}</p>
                                                )
                                            }
                                        </div>
                                        <button
                                            className='btn btn-sm btn-primary'
                                            onClick={() => {
                                                localStorage.setItem("SHOW_PRICING_POPUP", "show");
                                                props.showPricingPopup(true);
                                        }}
                                        >

                                            Explore Subscription Plans
                                        </button>
                                    </div>
                                </Col>
                            </Row>
                        
                        </CardBody>
                    </Card>
                </Container>
            </section>
        </React.Fragment>
    );
}
            
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool};
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool,showPricingPopup })(SubscriptionExpiry)));