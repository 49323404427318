import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { getSchoolForInstitute, setSelecetdSchool, getCorporatePartners } from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Select from "react-select";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Container,
    Modal,
    ModalBody, ModalHeader, ModalFooter,

} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';


const NewAdmissionReport = (props) => {


    const [loadingState, setloadingState] = useState(undefined);

    const [schoolData, setSchoolData] = useState([]);
    const [kidData, setKidData] = useState([]);

    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();



    const [totalInTime, setTotalInTime] = useState();
    const [displayData, setDisplayData] = useState([]);
    const [totalCheckins, setTotalCheckins] = useState([]);
    const [displayTitles, setDisplayTitles] = useState([])
    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });
    const [activeKids, setActiveKids] = useState();
    const [selectedReport, setSelectedReport] = useState({ value: 'basic', label: 'Basic Report' });
    const [showQuistionModal, setShowQuistionModal] = useState(false)
    const [selectedQuestions, setSelectedQuestions] = useState([]);
    const [admissionDate, setAdmissionDate] = useState(undefined);

    const allReports = [
        { value: 'basic', label: 'Basic Report' },
        { value: 'detail', label: 'Detail Report' }
    ];

    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);


    useEffect(() => {
        if (Parse.User.current().attributes.franchiseId) {

            props.getSchoolForInstitute(Parse.User.current().attributes.franchiseId, "franchiseId");
            if (props.showCorporatePartners) {
                props.getCorporatePartners(Parse.User.current().attributes.franchiseId, "franchiseId")
            }
        }
    }, [Parse.User.current().attributes.franchiseId]);



    const getDateInDDMMYYYY = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };


    const getStudentReport = () => {

        setloadingState(0);

        let startDate = new Date(
            selectedStartDate.getFullYear(),
            selectedStartDate.getMonth(),
            selectedStartDate.getDate()
        );
        let endDate = new Date(
            selectedEndDate.getFullYear(),
            selectedEndDate.getMonth(),
            selectedEndDate.getDate(),
            23,
            59,
            59,
            999
        );

        let schoolIds = [];
        let query;
        let query1 = new Parse.Query("Kid");
        let query2 = new Parse.Query("Kid");

        if (props.isFranchise) {
            if (selectedSchool && selectedSchool.value != 'allSchools') {
                schoolIds.push(selectedSchool.value)
            } else {
                for (const school of props.schools) {
                    schoolIds.push(school.id)
                }
            }

            query1.containedIn('SchoolID', schoolIds)
            query2.containedIn('schoolAdmitted', schoolIds)



        } else {
            query1.equalTo('SchoolID', props.schoolId)
            query2.equalTo('schoolAdmitted', props.schoolId)

        }

        query = Parse.Query.or(query1, query2)
        query.notEqualTo('Deleted', true);

        query.greaterThanOrEqualTo("createdAt", startDate);
        query.lessThanOrEqualTo("createdAt", endDate);

        query.limit(10000)

        query.find().then((kids) => {
            // console.log('kids', kids)
            if (props.isFranchise) {
                getClassroomData(schoolIds, kids);
            } else {
                getClassroomData([props.schoolId], kids);
            }

        });
    }

    const getClassroomData = (schoolIds, kids) => {
        const query1 = new Parse.Query("ClassRoom")
        query1.containedIn('SchoolID', schoolIds)
        query1.notEqualTo('isDeleted', true)
        query1.limit(1000);

        query1.find().then((classrooms) => {
            // console.log('classRoom', classrooms);
            getParents(kids, classrooms);
        });
    };


    const getParents = (kids, classrooms,) => {
        let fIds = [];
        let kidIds = [];
        let enquiryIds = [];
        for (const k of kids) {
            fIds.push(k.attributes.FamilyID)
            kidIds.push(k.id)
            enquiryIds.push(k.attributes.enquiryId)
        }
        const query4 = new Parse.Query("Parent")
        query4.containedIn('FamilyID', fIds)
        query4.notEqualTo('isDeleted', true)
        query4.limit(fIds.length * 2 + 100);

        query4.find().then((parents) => {
            // console.log('parents', parents);
            if (selectedReport.value == 'basic' || props.isFranchise) {
                setloadingState(2);
                prepareData(kids, classrooms, parents);
            } else {
                getChildAdditionalDetails(kids, kidIds, enquiryIds, classrooms, parents);
            }

        });
    };

    const getChildAdditionalDetails = (kids, kidIds, enquiryIds, classrooms, parents) => {

        const query5 = new Parse.Query("ChildAdditionalDetails")
        query5.containedIn('kidId', kidIds)
        query5.limit(kidIds.length * 2 + 100);

        query5.find().then((childAdditionalDetails) => {
            // console.log('childAdditionalDetails', childAdditionalDetails);
            getChildAdmissionData(kids, kidIds, enquiryIds, classrooms, parents, childAdditionalDetails);
        });
    };


    const getChildAdmissionData = (kids, kidIds, enquiryIds, classrooms, parents, childAdditionalDetails) => {
        const query6 = new Parse.Query("AdmissionData")
        query6.containedIn('kidId', kidIds)
        query6.limit(kidIds.length * 2 + 100);

        query6.find().then((childAdmissionData) => {
            // console.log('childAdmissionData', childAdmissionData);
            // setloadingState(2);
            // if (selectedReport.value == 'basic') {
            //     prepareData(kids, classRoom, admissionTitle, admissionQuestion, parents);
            // } else {
            //     prepareDetailData(kids, classRoom, admissionTitle, admissionQuestion, parents, childAdditionalDetails, childAdmissionData);
            // }

            getAdmissionTitleAndQuestionsData(kids, classrooms, enquiryIds, parents, childAdditionalDetails, childAdmissionData)
        });
    };



    const getAdmissionTitleAndQuestionsData = (kids, classrooms, enquiryIds, parents, childAdditionalDetails, childAdmissionData) => {
        const query1 = new Parse.Query("AdmissionTitle")
        query1.equalTo('schoolId', props.schoolId)
        query1.notEqualTo('isDeleted', true)
        query1.ascending('pageNumber')
        query1.limit(1000);

        query1.find().then((admissionTitle) => {
            // console.log('admissionTitle', admissionTitle);

            const query2 = new Parse.Query("AdmissionQuestion")
            query2.equalTo('schoolId', props.schoolId)
            query2.notEqualTo('isDeleted', true)
            query2.limit(1000);
            query2.ascending('order')
            query2.find().then((admissionQuestion) => {
                // console.log("admissionQuestion", admissionQuestion)


                let allQuestions = []
                let allQuestionIds = []
                let allQuestionsTitles = []
                let getEnquiry = false

                for (const title of admissionTitle) {
                    for (const question of admissionQuestion) {
                        if (title.id == question.attributes.admissionTitleId) {
                            if ((question.attributes.databaseTableName == "Kid" && question.attributes.databaseFieldName == "Name")) {

                            } else if (question.attributes.dataType != "FILE") {
                                allQuestions.push(question)
                                allQuestionsTitles.push(question.attributes.question)
                                allQuestionIds.push(question.id)
                            }

                            if (question.attributes.databaseTableName == "Enquiry") {
                                getEnquiry = true;
                            }

                        }
                    }

                }
                setSelectedQuestions(allQuestionIds)

                if (getEnquiry && enquiryIds && enquiryIds.length > 0) {

                    const query5 = new Parse.Query("Enquiry")
                    query5.containedIn('objectId', enquiryIds)
                    query5.limit(enquiryIds.length * 2 + 100);

                    query5.find().then((enquiries) => {
                        // console.log('enquiries', enquiries);
                        prepareDetailData(kids, classrooms, parents, childAdditionalDetails, childAdmissionData, allQuestions, allQuestionsTitles, enquiries)
                        setAdmissionDate({
                            kids: kids,
                            classrooms: classrooms,
                            parents: parents,
                            childAdditionalDetails: childAdditionalDetails,
                            childAdmissionData: childAdmissionData,
                            allQuestions: allQuestions,
                            allQuestionsTitles: allQuestionsTitles,
                            enquiries: enquiries
                        });
                    });
                } else {
                    prepareDetailData(kids, classrooms, parents, childAdditionalDetails, childAdmissionData, allQuestions, allQuestionsTitles)
                    setAdmissionDate({
                        kids: kids,
                        classrooms: classrooms,
                        parents: parents,
                        childAdditionalDetails: childAdditionalDetails,
                        childAdmissionData: childAdmissionData,
                        allQuestions: allQuestions,
                        allQuestionsTitles: allQuestionsTitles,
                    });
                }


            });

        });
    };

    const prepareDetailData = (kids, classrooms, parents, childAdditionalDetails, childAdmissionData, allQuestions, allQuestionsTitles, enquiries) => {


        let list = [];
        let list2 = [];



        let finalData = [];
        let titles = ["#", "Student Name", "Classroom", "School Name", ...allQuestionsTitles];

        finalData.push(titles);

        let schoolObjects = props.schools;
        if (props.isFranchise && selectedSchool && selectedSchool.value !== 'allSchools') {
            schoolObjects = props.schools.filter((s) => s.id == selectedSchool.value);
        }

        let lKids = [...kids];
        let count = 0;
        for (const school of schoolObjects) {
            let kidList = [];
            let nlKids = [];
            let sKids = [];

            for (const k of lKids) {
                if (k.attributes.SchoolID == school.id) {
                    sKids.push(k);
                } else {
                    nlKids.push(k);
                }
            }

            lKids = [...nlKids];
            let sClass = classrooms.filter((ao) => ao.attributes.SchoolID == school.id);

            for (const kid of sKids) {
                count++
                let kidData = [];
                let tempKid = {};
                tempKid.id = kid.id;
                kidData.push(count);
                kidData.push(kid.attributes.Name);

                let kClass = sClass.find((ao) => ao.id == kid.attributes.ClassRoomID);
                let corporate = kid.attributes.corporateId && props.showCorporatePartners ? props.corporatePartners.find((p) => p.id == kid.attributes.corporateId) : null;
                let cads = childAdditionalDetails && childAdditionalDetails.length > 0 && childAdditionalDetails.filter((c) => c.attributes.kidId == kid.id);
                let kidAdmissionData = childAdmissionData && childAdmissionData.length > 0 && childAdmissionData.filter((a) => a.attributes.kidId == kid.id);
                let kidEnquiry = enquiries && enquiries.length > 0 && enquiries.filter((e) => kid.attributes.enquiryId == e.id);

                tempKid.schoolName = school.attributes.Name;
                tempKid.className = kClass ? kClass.attributes.Name : "";
                tempKid.corporateName = corporate ? corporate.attributes.name : "";

                kidData.push(tempKid.className)
                kidData.push(tempKid.schoolName)

                let fatherObject = parents.find((p) => p.attributes.FamilyID == kid.attributes.FamilyID && p.attributes.Relation == "Father");
                let motherObject = parents.find((p) => p.attributes.FamilyID == kid.attributes.FamilyID && p.attributes.Relation == "Mother");

                for (const question of allQuestions) {
                    let answer = getAnswerForQuestion(question, kid, school, fatherObject, motherObject, cads, kidAdmissionData, kidEnquiry);
                    kidData.push(answer);
                }

                finalData.push(kidData);

                let tKid = {
                    id: kid.id,
                    school: school,
                    kid: kid,
                    kidData: tempKid,
                };

                kidList.push(tKid);
                list.push(tKid);
            }

            let schoolObject = {
                id: school.id,
                school: school,
                kidList: kidList,
            };

            list2.push(schoolObject);
        }

        // console.log("finalData", finalData);
        setDisplayData(finalData);
        setloadingState(3);
        setKidData(list);
        setSchoolData(list2);
    };

    const getAnswerForQuestion = (question, kid, school, fatherObject, motherObject, cads, kidAdmissionData, kidEnquiry) => {
        const databaseFieldName = question.attributes.databaseFieldName;
        const databaseTableName = question.attributes.databaseTableName;

        let answer;

        if (databaseTableName === "Enquiry" && kidEnquiry[0]?.attributes[databaseFieldName] != null) {
            answer = kidEnquiry[0].attributes[databaseFieldName];
        } else if (databaseTableName === "Kid" && kid?.attributes[databaseFieldName] != null) {
            answer = kid.attributes[databaseFieldName];
        } else if (databaseTableName === "ChildAdditionalDetails" && cads[0]?.attributes[databaseFieldName] != null) {
            answer = cads[0].attributes[databaseFieldName];
        } else if (databaseTableName === "Parent" && question.attributes.objectType === 1 && motherObject?.attributes[databaseFieldName] != null) {
            answer = motherObject.attributes[databaseFieldName];
        } else if (databaseTableName === "Parent") {
            if (question.attributes.objectType === 2 && fatherObject?.attributes[databaseFieldName] != null) {
                answer = fatherObject.attributes[databaseFieldName];
            } else if (fatherObject?.attributes[databaseFieldName] != null) {
                answer = fatherObject.attributes[databaseFieldName];
            }
        }

        if (answer instanceof Date) {
            return Moment(answer).format("DD/MM/YYYY");
        } else if (answer == 1 || answer == true) {
            return "YES";
        } else if (answer == 0 || answer == false) {
            return "NO";
        }

        return answer;
    };


    const prepareData = (kids, classrooms, parents) => {
        let list = [];
        let list2 = [];
        let finalData = [];
        let title = [];

        title.push("#");
        title.push("Student Name");
        title.push("Class Room");
        title.push("School Name");
        title.push("Gender");
        title.push("Date of joining");
        title.push("Admission Number");
        title.push("Date of Birth");
        if (props.showCorporatePartners) {
            title.push("Corporate Name");
            title.push("Program Type");
            title.push("Fees Type");
            title.push("Program Fee Paid");
            title.push("Date of Payment");
        }
        title.push("Father Name");
        title.push("Father Phone Number");
        title.push("Father Email");
        title.push("Mother Name");
        title.push("Mother Phone Number");
        title.push("Mother Email");

        // setDisplayTitles(title);
        finalData.push(title)

        let schoolObjects = props.schools;
        if (props.isFranchise && selectedSchool && selectedSchool.value != 'allSchools') {
            schoolObjects = props.schools.filter((s) => s.id == selectedSchool.value);
        }

        let count = 0;
        let lKids = [...kids];
        for (const school of schoolObjects) {
            let kidList = [];
            let nlKids = [];
            let sKids = [];
            for (const k of lKids) {
                if (k.attributes.SchoolID == school.id) {
                    sKids.push(k);
                } else {
                    nlKids.push(k);
                }
            }

            lKids = [...nlKids];

            let sClass = classrooms.filter(
                (ao) => ao.attributes.SchoolID == school.id
            );

            for (const kid of sKids) {
                count++;
                let tempKid = {};
                tempKid.id = kid.id;
                tempKid.name = kid.attributes.Name;

                let kClass = sClass.filter(
                    (ao) => ao.id == kid.attributes.ClassRoomID
                );

                let corporate = kid.attributes.corporateId && props.showCorporatePartners && props.corporatePartners.filter(
                    (p) => p.id == kid.attributes.corporateId
                );

                tempKid.schoolName = school.attributes.Name;
                tempKid.className = kClass.length > 0 ? kClass[0].attributes.Name : "";
                tempKid.corporateName = (corporate && corporate.length > 0) ? corporate[0].attributes.name : "";

                tempKid.gender = kid.attributes.Gender;
                tempKid.programType = kid.attributes.programType;
                tempKid.feeType = kid.attributes.feeType;
                tempKid.dob = getDateInDDMMYYYY(kid.attributes.DateOfBirth);
                tempKid.doa = getDateInDDMMYYYY(kid.attributes.createdAt);
                tempKid.dod = getDateInDDMMYYYY(kid.attributes.deletedOn);
                tempKid.admissionNumber = kid.attributes.admissionNumber;

                for (const p of parents) {
                    if (p.attributes.FamilyID == kid.attributes.FamilyID) {
                        if (p.attributes.Relation == "Father") {
                            tempKid.fatherName = p.attributes.Name;
                            tempKid.fatherMobile = p.attributes.PhoneNumber;
                            tempKid.fatherEmail = p.attributes.EMail;
                        } else {
                            tempKid.motherName = p.attributes.Name;
                            tempKid.motherMobile = p.attributes.PhoneNumber;
                            tempKid.motherEmail = p.attributes.EMail;
                        }
                    }
                }

                let temp = [];
                temp.push(count);
                temp.push(tempKid.name);
                temp.push(tempKid.className);
                temp.push(tempKid.schoolName);
                temp.push(tempKid.gender);
                temp.push(tempKid.doa);
                temp.push(tempKid.admissionNumber);
                temp.push(tempKid.dob);
                if (props.showCorporatePartners) {
                    temp.push(tempKid.corporateName);
                    temp.push(tempKid.programType);
                    temp.push(tempKid.feeType);
                    temp.push("");
                    temp.push(""); 
                }
                temp.push(tempKid.fatherName);
                temp.push(tempKid.fatherMobile);
                temp.push(tempKid.fatherEmail);
                temp.push(tempKid.motherName);
                temp.push(tempKid.motherMobile);
                temp.push(tempKid.motherEmail);

                finalData.push(temp);

                let tKid = {
                    id: kid.id,
                    school: school,
                    kid: kid,
                    kidData: tempKid
                };

                kidList.push(tKid);
                list.push(tKid);
            }

            let schoolObject = {
                id: school.id,
                school: school,
                kidList: kidList
            };

            list2.push(schoolObject);
        }
        // console.log(finalData)
        setDisplayData(finalData);
        setloadingState(3);
        setKidData(list);
        setSchoolData(list2);
    };


    const onClickDownload = () => {

        let finalData3 = []
        let fileName = `new_admission_report.xlsx`;

        finalData3.push(["New Admission Report"]);
        finalData3.push([`Report for: ${props.isFranchise ? selectedSchool.label : props.selectedSchool && props.selectedSchool.attributes.Name}`]);
        finalData3.push(["Total Students:" + displayData.length])
        finalData3.push([]);
        finalData3.push(displayTitles);
        finalData3 = finalData3.concat(displayData)

        let wb = XLSX.utils.book_new();
        let ws3 = XLSX.utils.aoa_to_sheet(finalData3);
        XLSX.utils.book_append_sheet(wb, ws3, "New Admission Report");
        XLSX.writeFile(wb, fileName);

    }

    const truncateText = (text, maxLength = 30) => {
        if (typeof text !== 'string') {
            return text;
        }
        if (text.length <= maxLength) {
            return text;
        }
        return text.substring(0, maxLength) + '...';
    };

    return (
        <React.Fragment>
            <Modal isOpen={showQuistionModal} scrollable={true}>
                <ModalHeader toggle={() => setShowQuistionModal(false)}>
                    <div className="modal-title mt-0">Select Admission Question</div>
                </ModalHeader>
                <ModalBody className="modal-body">
                    {admissionDate && admissionDate.allQuestions.map((question, key) => (
                        <div className="custom-control custom-checkbox mb-2 ml-3" key={key}>
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id={`question-${key}`}
                                checked={selectedQuestions.includes(question.id)}
                                onChange={() => {
                                    let newSelectedQuestions;
                                    if (selectedQuestions.includes(question.id)) {
                                        newSelectedQuestions = selectedQuestions.filter(q => q !== question.id);
                                    } else {
                                        newSelectedQuestions = [...selectedQuestions, question.id];
                                    }
                                    setSelectedQuestions(newSelectedQuestions);

                                }}
                            />
                            <label className="custom-control-label" htmlFor={`question-${key}`}>
                                {question.attributes.question}
                            </label>
                        </div>
                    ))}
                </ModalBody>
                <ModalFooter>

                    <button
                        className='btn btn-primary'
                        onClick={() => {
                            let allQuestions = [];
                            let allQuestionsTitles = []
                            for (const questionId of selectedQuestions) {
                                for (const question of admissionDate.allQuestions) {
                                    if (question.id == questionId) {
                                        allQuestions.push(question)
                                        allQuestionsTitles.push(question.attributes.question)

                                    }
                                }
                            }

                            prepareDetailData(
                                admissionDate.kids,
                                admissionDate.classrooms,
                                admissionDate.parents,
                                admissionDate.childAdditionalDetails,
                                admissionDate.childAdmissionData,
                                allQuestions,
                                allQuestionsTitles,
                                admissionDate.enquiries,
                            );

                            setShowQuistionModal(false)

                        }}
                    >
                        Done
                    </button>
                </ModalFooter>
            </Modal>
            <Row className="bg-light ">

                {props.isFranchise && <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Selcted school")} :</Label>

                        <Select
                            value={selectedSchool}
                            onChange={(v) => {
                                setSelctedSchool(v)
                                setloadingState(undefined);
                            }}
                            options={allSchools}
                            className="selectPaymentType"
                            classNamePrefix="selectPayType"

                        />
                    </FormGroup>
                </Col>}
                <Col>
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Start Date :</Label>
                        <DatePicker
                            selected={selectedStartDate}
                            onChange={(date) => {
                                setSelectedStartDate(date);
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            // startDate={fromDate}
                            // endDate={toDate}
                            maxDate={selectedEndDate}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                <Col >
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>End Date :</Label>
                        <DatePicker
                            selected={selectedEndDate}
                            onChange={(date) => {
                                setSelectedEndDate(date)
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            // startDate={fromDate}
                            // endDate={toDate}
                            minDate={selectedStartDate}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                <Col>
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Report Type")} :</Label>
                        <Select
                            className="basic-single"
                            classNamePrefix="select"
                            value={selectedReport}
                            onChange={(v) => {
                                setSelectedReport(v);
                                setloadingState(undefined);
                            }}
                            options={allReports}
                        />
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <div className="mt-2">
                        <div className="mt-3">
                            <button
                                className="btn btn-primary mt-4 mr-2"
                                onClick={getStudentReport}
                            >
                                {props.t("Generate")}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

            {(loadingState == 0 || loadingState == 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState == 0 ? "getting report data..." : "Preparing Report"}</Label>
                    </div>
                </Col>
            }


            {loadingState == 3 && schoolData && displayData && <Card>
                <CardBody>

                    <CardTitle>{"New Admission Data"}</CardTitle>
                    <p className='mb-0'>{"Report For: " + selectedSchool.label}</p>

                    <p>{"Total Students:" + displayData.length}</p>

                    <Col lg={12}>
                        <div className="mt-2 mb-2 p-1 d-flex d-flex justify-content-end">
                            {selectedReport.value == "detail" && <button
                                onClick={() => {
                                    setShowQuistionModal(true)
                                }}
                                className="w-md  btn btn-primary m-1"
                            >
                                {props.t("Add/ Remove Question")}
                            </button>}
                            <button
                                onClick={() => {
                                    if (displayData && displayData.length > 0) {
                                        onClickDownload();
                                    }

                                }}
                                className="w-md  btn btn-primary m-1"
                            >
                                <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                {props.t("Download Report")}
                            </button>
                        </div>
                    </Col>

                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table className="table table-striped table-bordered">

                            <Thead>
                                <Tr>
                                    {displayTitles.map((val2, key) => (
                                        <Th key={key}>{val2}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {displayData && displayData.map((cData, key) => (
                                    <Tr key={key}>
                                        {cData.map((val2, key2) => (
                                            <Td key={key2}>{truncateText(val2)}</Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </div>

                </CardBody>
            </Card>}
        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners } = state.CorporatePartners;
    return {
        schools, selectedSchool,
        franchise,
        corporatePartners,
    };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getSchoolForInstitute, setSelecetdSchool, getCorporatePartners
        })(NewAdmissionReport)
    )
);