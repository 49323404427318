import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Label, TabContent, TabPane,
  Card, Form, Button, CardText, Nav, NavItem, NavLink,
  CardBody, FormGroup, Table, CardHeader, Modal, ModalBody,
  ModalFooter, ModalHeader, UncontrolledTooltip, Input
} from "reactstrap";
import Compressor from 'compressorjs';

import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';
import Switch from "react-switch";
import SchoolPicker from './SchoolPicker';
import Parse from 'parse';
import ReactDatePicker from 'react-datepicker';
import { v4 as uuidv4 } from "uuid";
import Select from "react-select";
import toastr from "toastr";
import Lightbox from 'react-image-lightbox';
import DocumentViewerModal from './DocumentViewerModal';
import Iframe from "react-iframe";
import SweetAlert from 'react-bootstrap-sweetalert';

import {
  getMessageQueues,
  updateAlbums,
  getFranchiseTeachers,
  updateMessageQueues,
  getClassroomsForSchoolId,
  getAllKids,
  getAlbumsForAlbumIds,
  deleteAlbum,
} from '../../store/actions';
import { IMAGE_COMPRESSION_RATE } from '../../Constents';

const MessageQueueAddMessage = (props) => {

  const SendTo = {
    allCenterheadAndDirector: "@All Directors/CenterHead (app message)",
    allTeachers: "@All Teachers (app message)",
    allChildren: "@All Children (app message)",
    Classroom: "@Classroom (app message)",
    allEnquiries: "@All Enquiries (Email)",
    allAdmissions: "@All Admissions (Email)",
    allAlumni: "@All Alumni (Email)",
  };

  Object.freeze(SendTo);
  const [messageText, setMessageText] = useState();
  const [selectedSendTo, setSelectedSendTo] = useState(undefined);
  const [isSendLater, setSendNow] = useState(false);
  const [selectedSchoolListForPicker, setSelectedSchoolListForPicker] = useState([]);
  const [selectedDate, setSelectedDate] = useState();
  const [progressText, setProgressText] = useState();
  const [albumImageList, setAlbumImageList] = useState([]);
  const [albumVideoList, setAlbumVideoList] = useState([]);
  const [documentParseFile, setDocumentParseFile] = useState();
  const [albumUUIDId, setAlbumUUIDId] = useState();
  const [videosList, setVideosList] = useState();
  const [imagesList, setImagesList] = useState();
  const [currentIndexForUpload, setCurrentIndexForUpload] = useState(0);
  const [showSchoolPickerModal, setShowSchoolPickerModal] = useState(false);
  const [selectedSchoolListForPickerError, setSelectedSchoolListForPickerError] = useState(false);
  const [selectedSendToError, setSelectedSendToError] = useState(false);
  const [messageTextError, setMessageTextError] = useState(false);
  const [selectedDateError, setSelectedDateError] = useState(false);
  const [allSchools, setAllSchools] = useState([]);

  const [selectedClass, setSelectedClass] = useState();
  const [editMessageAlbums, setEditMessageAlbums] = useState([]);

  const [selectedClassroomError, setSelectedClassroomError] = useState(undefined);

  const [messagePostLoading, setMessagePostLoading] = useState(false)

  const [studentList, setStudentList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState({ value: '', label: '' });

  const [imagePopup, setImagePopup] = useState(false);
  const [photoIndex, setphotoIndex] = useState(0);
  const [clickedFile, setClickedFile] = useState(false);
  const [showVideoModel, setShowVideoModel] = useState(false);
  const [showDeleteAlbum, setShowDeleteAlbum] = useState(false);
  const [deleteObject, setDeleteObject] = useState(undefined);
  const [showDeleteAttachment, setShowDeleteAttachment] = useState(false);
  const [attachment, setAttachment] = useState()

  useEffect(() => {
    if (props.schoolId && props.selectedSchool) {
      setSelectedSchoolListForPicker([props.selectedSchool])
    }
    if (props.schoolId) {
      props.getAllKids(props.schoolId)
    }
  }, [props.schoolId, props.selectedSchool]);

  const [sendToList, setSendToList] = useState([
    { value: 2, label: SendTo.allCenterheadAndDirector },
    { value: 1, label: SendTo.allTeachers },
    { value: 3, label: SendTo.allChildren },
    { value: props.schoolId ? 6 : 10, label: SendTo.Classroom },
    { value: 4, label: SendTo.allEnquiries },
    { value: 5, label: SendTo.allAdmissions },
    { value: 9, label: SendTo.allAlumni },
  ]);

  const [sendToClassLevel, setSendToClassLevel] = useState([
    { value: "Daycare", label: "Daycare" },
    { value: "PlayGroup", label: "PlayGroup" },
    { value: "Nursery", label: "Nursery (Mont 1)" },
    { value: "LKG", label: "LKG( Mont 2, Jr KG)" },
    { value: "UKG", label: "UKG ( Mont 3, Sr KG)" },
    { value: "Class1", label: "Class1 (Gread 1, 1st Standerd)" },
    { value: "Class2", label: "Class2 (Gread 2, 2nd Standerd)" },
    { value: "Class3", label: "Class3 (Gread 3, 3rd Standerd)" },
    { value: "Class4", label: "Class4 (Gread 4, 4th Standerd)" },
    { value: "Class5", label: "Class5 (Gread 5, 5th Standerd)" },
    { value: "Class6", label: "Class6 (Gread 6, 6th Standerd)" },
    { value: "Class7", label: "Class7 (Gread 7, 7th Standerd)" },
    { value: "Class8", label: "Class8 (Gread 8, 8th Standerd)" },
    { value: "Class9", label: "Class9 (Gread 9, 9th Standerd)" },
    { value: "Class10", label: "Class10 (Gread 10, 10th Standerd)" },
  ]);

  function handleImageUploadChange(e) {
    let tempFileList = [];
    console.log(e.target.files.length);
    for (const i in e.target.files) {

      if (e.target.files.length > i) {
        let tempFile = e.target.files[i];
        if (tempFile && tempFile.name) {
          let tempfileExtension = tempFile.name.split(".");
          let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
          let newFile = {
            id: "fiele_" + i,
            file: tempFile,
            type: "Image",
            downloadeble: true,
            actualFileName: tempFile.name,
            name: tempfileExtension[0],
            extention: tempExt,
            extentionIcon: getFileTypeImage(tempExt),
            preview: URL.createObjectURL(tempFile)
          }
          tempFileList.push(newFile);
        }
      }
    }
    setImagesList(tempFileList);
  }

  useEffect(() => {
    if (currentIndexForUpload >= 0 && imagesList) {
      if (imagesList.length > currentIndexForUpload) {
        onImageListUpload(imagesList[currentIndexForUpload], currentIndexForUpload);
      } else {
        setCurrentIndexForUpload(-1);
        setProgressText()
        setImagesList([]);
      }
    }
  }, [imagesList, currentIndexForUpload]);

  function onImageListUpload(file, count) {
    setProgressText(props.t('Uploading ') + (count + 1) + " of " + imagesList.length)
    new Compressor(file.file, {
      quality: IMAGE_COMPRESSION_RATE, // 0.6 can also be used, but its not recommended to go below.
      success: (compressedResult) => {
        convertImageToBase64(file, count, compressedResult)
      },
    });
  }

  function convertImageToBase64(file, count, compressedResult) {
    var albumId = albumUUIDId ?? uuidv4()
    setAlbumUUIDId(albumId)
    var name = `file`;
    if (file.type === "Image") {
      name = name + "." + file.extention;
    }
    getBase64(compressedResult, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result, });
      parseFile.save({
        progress: (value) => {
          if (value && value > 0.1) {
            setProgressText(`${props.t('Uploading ') + (count + 1) + " of " + imagesList.length + " Images "} ( ${(value * 100).toFixed(2)}% )`)
          }
        }
      }).then(
        result => {
          let parseImagesList = [...albumImageList]
          var Album = Parse.Object.extend("Album");
          var albumObject = new Album();
          albumObject.set("albumId", albumId);
          albumObject.set("image", result);
          // albumObject.set("schoolId", schoolId)

          if (props.showEditMessage) {
            albumObject.save().then((so) => {
              let list = [...editMessageAlbums]
              list.push(so)
              console.log(list)
              setEditMessageAlbums(list)
              if (imagesList.length > currentIndexForUpload) {
                setCurrentIndexForUpload((currentIndexForUpload + 1))
              } else {
                setCurrentIndexForUpload(-1);
                setProgressText()
                setImagesList([]);
              }

            })

          } else {
            parseImagesList.push(albumObject)
            setAlbumImageList(parseImagesList)
            if (imagesList.length > currentIndexForUpload) {
              setCurrentIndexForUpload((currentIndexForUpload + 1))
            } else {
              setCurrentIndexForUpload(-1);
              setProgressText()
              setImagesList([]);
            }
          }

        },
        error => {
          setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
        }
      );
    });
  }


  function handleVideoUploadChange(e) {
    let tempFileList = [];
    if (!validateSize(e.target, 50)) {

      return
    }
    for (const i in e.target.files) {
      if (e.target.files.length > i) {
        let tempFile = e.target.files[i];
        if (tempFile && tempFile.name) {
          let tempfileExtension = tempFile.name.split(".");
          let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
          let newFile = {
            id: "fiele_" + i,
            file: tempFile,
            type: "Video",
            downloadeble: true,
            actualFileName: tempFile.name,
            name: tempfileExtension[0],
            extention: tempExt,
            extentionIcon: getFileTypeImage(tempExt),
            preview: URL.createObjectURL(tempFile)
          }
          tempFileList.push(newFile);
        }
      }
    }
    setVideosList(tempFileList);
  }

  useEffect(() => {
    if (currentIndexForUpload >= 0 && videosList) {
      if (videosList.length > currentIndexForUpload) {
        onVideoListUpload(videosList[currentIndexForUpload], currentIndexForUpload);
      } else {
        setCurrentIndexForUpload(-1);
        setProgressText()
        setVideosList([]);
      }
    }
  }, [videosList, currentIndexForUpload]);

  function onVideoListUpload(file, count) {
    var albumId = albumUUIDId ?? uuidv4()
    setAlbumUUIDId(albumId)

    var name = file.name.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
    if (file.type === "Image") {
      name = name + "." + file.extention;
    }
    setProgressText(props.t('Uploading ') + (count + 1) + " of " + videosList.length)
    let oneFileWhite = 100 / videosList.length;
    let percentOffSet = oneFileWhite * count;
    getBase64(file.file, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result, });
      parseFile.save({
        progress: (value) => {
          if (value && value > 0.1) {
            setProgressText(`${props.t('Uploading ') + (count + 1) + " of " + videosList.length + " Video "} ( ${(value * 100).toFixed(2)}% )`)
          }
        }
      }).then(
        result => {
          let parseVideosList = [...albumVideoList]
          var Files = Parse.Object.extend("Album");
          var fileObject = new Files();
          fileObject.set("albumId", albumId);
          fileObject.set("video", result);
          //fileObject.set("schoolId", schoolId)
          parseVideosList.push(fileObject)
          setAlbumVideoList(parseVideosList)
          setEditMessageAlbums(parseVideosList)
          if (videosList.length > currentIndexForUpload) {
            setCurrentIndexForUpload((currentIndexForUpload + 1))
          } else {
            setCurrentIndexForUpload(-1);
            setProgressText()
            setVideosList([]);
          }
        },
        error => {
          setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
        }
      );
    });
  }

  function validateSize(input, limit) {
    const fileSize = input.files[0].size / 1024 / 1024; // in MiB
    if (fileSize > limit) {
      toastr.warning(`File size exceeds ${limit} MB, Please use a smaller document`)
      return false
    }
    return true
  }

  const handleFileUploadChange = (e) => {
    if (e.target.files && e.target.files[0] && validateSize(e.target, 30)) {
      setDocumentParseFile(undefined);
      setProgressText("Uploading 1 of 1 file")

      let fileExtension = e.target.files[0].name.split(".");
      let tempFile = {
        actualFileName: e.target.files[0].name,
        fileExtension: fileExtension[1].toLowerCase(),
        fileName: fileExtension[0],
        file: e.target.files[0],
      };

      var name = tempFile.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

      getBase64(tempFile.file, (resultWithName) => {
        var parseFile = new Parse.File((name??"attachment").replace(/[^a-zA-Z0-9._]/g, '_'), { base64: resultWithName.result });
        //setUploadProgress(1);
        parseFile
          .save({
            progress: (value) => {
              if (value) {
                if (value && value > 0.1)
                  setProgressText(`Uploading 1 of 1 file ( ${(value * 100).toFixed(2)}% )`)
                //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")
              }
            },
          })
          .then(
            (result) => {
              setProgressText()
              tempFile.file = result;
              setDocumentParseFile(tempFile);
              setAttachment(tempFile.file)
            },
            (error) => {
              setProgressText()
              //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
            }
          );
      });

      setDocumentParseFile({ actualFileName: tempFile.actualFileName })

    }
  }


  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var resultWithName = { name: file.name, result: reader.result };
      cb(resultWithName);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const clearAllAttachments = () => {
    setCurrentIndexForUpload(0)
    setAlbumImageList([])
    setAlbumVideoList([])
    setDocumentParseFile()
  }



  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }
    // else if(fileType === "Video"){
    //     return "bx bx-play-circle";
    // }else if (_audioExtensions.includes(fileExtension)) {
    //     return "bx bxs-music";
    // }
    return "bx bxs-file-blank";
  }


  const verifyForMandatory = () => {
    var returnValue = true

    if (!props.showEditMessage) {
      if (selectedSchoolListForPicker == undefined || selectedSchoolListForPicker.length === 0) {
        setSelectedSchoolListForPickerError(true)
        returnValue = false
      }
    }

    if (selectedSendTo == undefined) {
      setSelectedSendToError(true)
      returnValue = false
    }

    if (messageText == undefined || messageText.length === 0) {
      setMessageTextError(true)
      returnValue = false
    }

    if (isSendLater === true && selectedDate == undefined) {
      setSelectedDateError(true)
      returnValue = false
    }

    if ((selectedSendTo == 6 || selectedSendTo == 10) && !selectedClass) {
      setSelectedClassroomError(true)
      returnValue = false
    }

    return returnValue
  }

  const onPostMessage = () => {
    if (!verifyForMandatory()) {
      return
    }
    setMessagePostLoading(true);
    let schoolIds = []
    for (const school of selectedSchoolListForPicker) {
      if (!schoolIds.includes(school.id)) {
        schoolIds.push(school.id)
      }
    }

    const MessageQueue = Parse.Object.extend('MessageQueue');
    var messageQueue = new MessageQueue();

    if (props.showEditMessage && props.selectedMessageForEditing.parseObject) {
      messageQueue.id = props.selectedMessageForEditing.parseObject.id;
    } else {
      messageQueue.set("recipientType", parseInt(selectedSendTo, 10))
      messageQueue.set("sender", Parse.User.current().attributes.teacherId)
      messageQueue.set("messageType", schoolIds.length > 1 ? 110 : 1)
      messageQueue.set("audience", (selectedSchoolListForPicker.length === 1 ? selectedSchoolListForPicker[0].attributes.Name : "All"))
      messageQueue.set("triggerStatus", 0)

      if (props.schoolId) {
        messageQueue.set("allSchools", false);
      } else {
        messageQueue.set("allSchools", selectedSchoolListForPicker.length === allSchools.length ? true : false)
      }

      if (props.schoolId) {
        messageQueue.set("schoolId", schoolIds[0])
      } if (props.instituteId) {
        if (schoolIds.length === 1) {
          messageQueue.set("schoolId", schoolIds[0])
          messageQueue.set("instituteId", props.instituteId)

        } else {
          messageQueue.set("instituteId", props.instituteId)
        }
      } if (props.franchiseId) {
        if (schoolIds.length === 1) {
          messageQueue.set("schoolId", schoolIds[0])
          messageQueue.set("franchiseId", props.franchiseId)

        } else {
          messageQueue.set("franchiseId", props.franchiseId)
        }
      }

      if (selectedSendTo == 6 && props.schoolId) {

        // Student

        if (selectedStudent) {
          messageQueue.set("audience", selectedStudent.object.attributes.Name);
          messageQueue.set("recipientIds", [{
            id: selectedStudent.object.id,
            familyId: selectedStudent.object.attributes.FamilyID,
            classId: selectedClass
          }]);
          messageQueue.set("recipientType", 8)
        } else {
          let classobject = props.classrooms.filter((c) => (c.id == selectedClass));
          messageQueue.set("audience", classobject[0].attributes.Name);
          messageQueue.set("recipientIds", [selectedClass]);
        }


      } else if (selectedSendTo == 10 && (props.instituteId || props.franchiseId)) {
        messageQueue.set("audience", selectedClass);
        messageQueue.set("classLevel", selectedClass)
        messageQueue.set("recipientIds", schoolIds)
      } else {
        messageQueue.set("recipientIds", schoolIds)
      }
    }

    messageQueue.set("messageTriggerTime", isSendLater ? selectedDate : new Date())
    messageQueue.set("message", messageText)
    if (albumImageList && albumImageList.length > 0) {
      messageQueue.set("albumId", albumImageList[0].attributes.albumId)
    } else if (documentParseFile && documentParseFile.file) {
      messageQueue.set("attachment", documentParseFile.file)
    } else if (albumVideoList && albumVideoList.length > 0) {
      messageQueue.set("videoAlbumId", albumVideoList[0].attributes.albumId)
    }

    console.log("messageQueue", messageQueue)
    messageQueue.save().then(
      (result) => {
        props.updateMessageQueues([result])
        props.toggleView()

        saveAlbums()
        setAlbumUUIDId()
        setMessageText("")
        clearAllAttachments()
        setMessagePostLoading(false)
      },
      (error) => {
        setAlbumUUIDId()
        setMessageText("")
        props.toggleView()
      }
    );
  }

  useEffect(() => {
    if (props.showEditMessage && props.selectedMessageForEditing) {

      let schools = [];
      for (const school of props.schools) {
        for (const selectedSchool of props.selectedMessageForEditing.parseObject.attributes.recipientIds) {
          if (school.id == selectedSchool) {
            schools.push(school)
          }
        }
      }
      setSelectedSchoolListForPicker(schools)

      if (props.selectedMessageForEditing.parseObject.attributes.albumId && props.selectedMessageForEditing.parseObject.attributes.albumId.length > 0) {
        // props.getAlbumsForAlbumIds({ id: [props.selectedMessageForEditing.parseObject.attributes.albumId] })
        setAlbumUUIDId(props.selectedMessageForEditing.parseObject.attributes.albumId)
      } else if (props.selectedMessageForEditing.parseObject.attributes.videoAlbumId && props.selectedMessageForEditing.parseObject.attributes.videoAlbumId.length > 0) {
        // props.getAlbumsForAlbumIds({ id: [props.selectedMessageForEditing.parseObject.attributes.videoAlbumId] })
      }

      if (props.albums && props.albums.length > 0) {
        let getImageForMessageId = []
        for (const img of props.albums) {

          if (props.selectedMessageForEditing.albumId) {
            if (props.selectedMessageForEditing.parseObject.attributes.albumId == img.attributes.albumId) {
              getImageForMessageId.push(img)
            }
          } else if (props.selectedMessageForEditing.videoAlbumId) {
            if (props.selectedMessageForEditing.parseObject.attributes.videoAlbumId == img.attributes.albumId) {
              getImageForMessageId.push(img)
            }
          }
        }
        // console.log(getImageForMessageId)
        setEditMessageAlbums(getImageForMessageId)
        // console.log(editMessageAlbums)
      }

      if (props.selectedMessageForEditing.parseObject.attributes.recipientType == 8) {
        setSelectedSendTo(6)


        let kids = props.kids.filter((k) => k.id == props.selectedMessageForEditing.parseObject.attributes.recipientIds[0].id)
        if (kids.length > 0) {
          setSelectedClass(kids[0].attributes.ClassRoomID)
          setSelectedStudent({ value: kids[0].id, label: kids[0].attributes.Name })
          console.log(selectedStudent)
        }

      } else if (props.selectedMessageForEditing.parseObject.attributes.recipientType == 6) {
        setSelectedSendTo(6)
        setSelectedClass(props.selectedMessageForEditing.parseObject.attributes.recipientIds[0].classId)
      } else if (props.selectedMessageForEditing.parseObject.attributes.recipientType == 10) {
        setSelectedSendTo(10)
        setSelectedClass(props.selectedMessageForEditing.parseObject.attributes.classLevel)
      }
      else {
        setSelectedSendTo(props.selectedMessageForEditing.parseObject.attributes.recipientType)
      }
      setMessageText(props.selectedMessageForEditing.message)
      setSelectedDate(props.selectedMessageForEditing.parseObject.attributes.messageTriggerTime)
      setSendNow(props.selectedMessageForEditing.parseObject.attributes.triggerStatus == 0 ? true : false)
      setAttachment(props.selectedMessageForEditing.parseObject.attributes.attachment)

    }

  }, [props.showEditMessage, props.selectedMessageForEditing, props.albums])


  const saveAlbums = () => {
    if (albumImageList && albumImageList.length > 0) {
      Parse.Object.saveAll(albumImageList).then(
        (results) => {
          props.updateAlbums(results)
        },
        (error) => {
          console.log("Error", error);
        }
      );
    }
    if (albumVideoList && albumVideoList.length > 0) {
      Parse.Object.saveAll(albumVideoList).then(
        (results) => {
          props.updateAlbums(results)
        },
        (error) => {
          console.log("Error", error);
        }
      );
    }
  }

  useEffect(() => {

    if (props.kids.length > 0 && props.classrooms.length > 0) {
      let kidList = [];
      for (const val of props.kids) {

        if (selectedClass && selectedClass == val.attributes.ClassRoomID) {
          let kidObject = {
            value: val.id,
            label: val.attributes.Name,
            object: val,
          }
          kidList.push(kidObject);
        }
      }
      setStudentList(kidList)
    }
  }, [props.kids, selectedClass]);

  const handelDeleteFile = () => {
    props.deleteAlbum(deleteObject)
    setShowDeleteAlbum(false);
  };

  const deleteAttachment = () => {
    deleteObject.unset("attachment")
    deleteObject.save().then(
      (result) => {
        props.updateMessageQueues([result])
        setShowDeleteAttachment(false);
      },
      (error) => {
      }
    );
  }

  const getAddNewMessageUI = () => {
    return (<Card>
      <CardBody>
        <AvForm>
          <Row lg={12}>
            <Col lg={12}>
              <FormGroup>
                {(props.franchiseId || props.instituteId) && <button className='btn btn-primary btn-rounded' onClick={() => {
                  setShowSchoolPickerModal(true)
                }}>{selectedSchoolListForPicker && selectedSchoolListForPicker.length > 0 ? `${selectedSchoolListForPicker.length} ${selectedSchoolListForPicker.length === 1 ? "School Selected" : "Schools Selected"}` : "Select Schools"}
                </button>}
              </FormGroup>
              {selectedSchoolListForPickerError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}
            </Col>

          </Row>
          <Row lg={12}>

            <Col lg={6}>
              <FormGroup>
                <Label className="">Select Send to</Label>
                <Label className="text-danger">*</Label>
                {selectedSendToError == true && (
                  <Label className="label-xsmall text-danger ml-2">
                    (this field is mandatory)
                  </Label>
                )}
                <select
                  id="sendToMessages"
                  className="form-control"
                  value={selectedSendTo}
                  disabled={props.showEditMessage}
                  onChange={(e) => {
                    setSelectedClass(undefined)
                    setSelectedStudent(undefined)
                    setSelectedSendTo(e.target.value);
                  }}
                >
                  <option disabled selected value>Select Send To</option>
                  {sendToList.map((object, key) => (
                    <option key={key} value={object.value}>{object.label}</option>
                  ))}
                </select>
              </FormGroup>

              {selectedSendTo == 6 &&
                <Row>
                  <Col>
                    <FormGroup>
                      <Label className="">Select Class</Label>
                      <Label className="text-danger">*</Label>
                      {selectedClassroomError === true && (
                        <Label className="label-xsmall text-danger ml-2">
                          (this field is mandatory)
                        </Label>
                      )}
                      <select
                        id="sendToMessages"
                        className="form-control"
                        value={selectedClass}
                        disabled={props.showEditMessage}
                        onChange={(e) => {
                          setSelectedStudent(undefined)
                          setSelectedClass(e.target.value);
                        }}

                      >
                        <option disabled selected value>Select class</option>
                        {props.classrooms && props.classrooms.map((object, key) => (
                          <option key={key} value={object.id}>{object.attributes.Name}</option>
                        ))}
                      </select>
                    </FormGroup>
                  </Col>
                  {selectedClass && props.schoolId && <Col>
                    <FormGroup>
                      <Label className="">Select Student</Label>
                      <Select
                        onChange={(v) => {
                          setSelectedStudent(v)
                        }}
                        value={selectedStudent}
                        options={studentList}
                        isDisabled={props.showEditMessage}
                        classNamePrefix="SelectStudent"
                      />
                    </FormGroup>
                  </Col>}
                </Row>}
              {selectedSendTo == 10 && <FormGroup>
                <Label className="">Select Class Level</Label>
                <Label className="text-danger">*</Label>
                {selectedClassroomError === true && (
                  <Label className="label-xsmall text-danger ml-2">
                    (this field is mandatory)
                  </Label>
                )}
                <select
                  id="sendToMessages"
                  className="form-control"
                  value={selectedClass}
                  disabled={props.showEditMessage}
                  onChange={(e) => {
                    setSelectedClass(e.target.value);
                  }}

                >
                  <option disabled selected value>Select class</option>
                  {sendToClassLevel.map((object, key) => (
                    <option key={key} value={object.value}>{object.label}</option>
                  ))}
                </select>
              </FormGroup>}
            </Col>
          </Row>
          <Row lg={12}>
            <Col lg={12}>
              <Label>Message Text</Label>
              <Label className="text-danger">*</Label>
              {messageTextError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}

              <AvField
                id="messageTextArea"
                type="textarea"
                name="messageTextArea"
                rows="4"
                placeholder={props.t('Type here')}
                className="form-control"
                value={messageText}
                onChange={(e) => {
                  setMessageText(e.target.value);
                }}
              />
            </Col>
          </Row>
          <div>
            <div className='d-flex'>
              <div>
                <label className='float-left m-2'>Send Now</label>
                <Switch
                  height={15}
                  width={25}
                  className="float-left "
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor={"#6add7d"}
                  offColor={"#6add7d"}
                  onChange={() => {
                    setSendNow(!isSendLater)
                  }}
                  checked={isSendLater}
                />
                <label className='float-right m-2'>Send Later</label>

              </div>
              <br />

            </div>
            {isSendLater && <><Row lg={4} className="mt-3">
              <Col lg={4}>
                <Label>Select Date</Label>

                <ReactDatePicker
                  selected={selectedDate}
                  onChange={(date) =>
                    setSelectedDate(new Date(date))
                  }
                  className="form-control mb-3"
                  placeholderText={props.t(
                    "Select Date"
                  )}
                  timeInputLabel="Time:"

                  dateFormat="MM/dd/yyyy h:mm aa"
                  showTimeSelect
                />
              </Col>

            </Row>
              {isSendLater && selectedDateError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}
            </>}
          </div>
          <Row lg={12}>
            <Col lg={4}>
              <Row>
                <Col>
                  {(props.selectedMessageForEditing.albumId && props.showEditMessage) &&
                    editMessageAlbums && editMessageAlbums.map((file, key) => (
                      <Card key={key}>
                        <CardBody>
                          <Row>
                            <Col>
                              <div className="popup-gallery">
                                <div className="img-fluid float-left">
                                  <img style={{ cursor: 'pointer' }}
                                    src={file.attributes.image._url}
                                    onClick={() => {
                                      setImagePopup(true);
                                      setphotoIndex(file);
                                    }}
                                    alt=""
                                    width="40"
                                  />
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                className="flex-fill text-center"
                                style={{ cursor: "pointer", float: "right" }}
                                onClick={() => {
                                  setDeleteObject(file);
                                  setShowDeleteAlbum(true);
                                }}
                              >
                                <i className="bx bxs-trash text-danger"></i>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                  {(props.selectedMessageForEditing.videoAlbumId && props.showEditMessage) &&
                    editMessageAlbums.map((file, key) => (
                      <Card key={key}>
                        <CardBody>
                          <Row>
                            <Col>
                              <div className="popup-gallery">
                                <div className="img-fluid float-left">
                                  <p style={{ cursor: 'pointer' }} onClick={() => {
                                    setShowVideoModel(file)
                                  }}
                                  >{file.attributes.video._name.split('_').slice(1)}</p> { }
                                </div>
                              </div>
                            </Col>
                            <Col>
                              <div
                                className="flex-fill text-center"
                                style={{ cursor: "pointer", float: "right" }}
                                onClick={() => {
                                  setDeleteObject(file);
                                  setShowDeleteAlbum(true);
                                }}
                              >
                                <i className="bx bxs-trash text-danger"></i>
                              </div>
                            </Col>
                          </Row>
                        </CardBody>
                      </Card>
                    ))}
                  {(props.showEditMessage && props.selectedMessageForEditing.parseObject.attributes.attachment) &&
                    (<Card>
                      <CardBody>
                        <Row>
                          <Col>
                            <div className="popup-gallery">
                              <div className="img-fluid float-left">
                                <p style={{ cursor: 'pointer' }} onClick={() => {
                                  setClickedFile(props.selectedMessageForEditing.attachment)
                                }}>{attachment && attachment._name.split('_').slice(1)}</p>
                              </div>
                            </div>
                          </Col>
                          <Col>
                            <div
                              className="flex-fill text-center"
                              style={{ cursor: "pointer", float: "right" }}
                              onClick={() => {
                                setDeleteObject(props.selectedMessageForEditing.parseObject);
                                setShowDeleteAttachment(true);
                              }}
                            >
                              <i className="bx bxs-trash text-danger"></i>
                            </div>
                          </Col>
                        </Row>
                      </CardBody>
                    </Card>)
                  }
                </Col>
              </Row>
              <Row>
                <Col>
                  {getAttachmentsUI()}
                </Col>
              </Row>
            </Col>
          </Row>
          <Row>
            <Col lg={8}>
              {!props.showEditMessage && <div className='d-flex justify-content-strat mt-2'>
                {(selectedSendTo < 4 || selectedSendTo == 10 || selectedSendTo == 6) && (
                  <label className='btn btn-info m-2' htmlFor="upload-images-messagesfra">
                    Upload Photos
                  </label>
                )}
                {(selectedSendTo > 0) && (
                  <label className='btn btn-info m-2' htmlFor='upload-files-messagesfra'>
                    Upload File
                  </label>
                )}
                {(selectedSendTo < 4 || selectedSendTo == 10 || selectedSendTo == 6) && (
                  <label className='btn btn-info m-2' htmlFor='upload-video-messagesfra'>
                    Upload Video
                  </label>
                )}
                <input type="file"
                  name="upload-images-messagesfra"
                  className="sr-only"
                  id="upload-images-messagesfra"
                  accept="image/*"
                  onChange={(e) => {
                    clearAllAttachments()
                    handleImageUploadChange(e)
                    // let tempInput = document.getElementById("upload-images-messagesfra").value = null
                  }}
                  multiple
                />

                <input type="file"
                  className="sr-only"
                  name="upload-files-messagesfra"
                  id="upload-files-messagesfra"
                  accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,/*"
                  onChange={(e) => {
                    clearAllAttachments()
                    handleFileUploadChange(e)
                    // let tempInput = document.getElementById("upload-files-messagesfra").value = null
                  }}
                />

                <input type="file"
                  className="sr-only"
                  name="upload-video-messagesfra"
                  id="upload-video-messagesfra"
                  accept="video/*"
                  onChange={(e) => {
                    clearAllAttachments()
                    handleVideoUploadChange(e)
                  }}
                />
              </div>}
              <Row>
                <Col className='d-flex justify-content-start'>
                  {props.showEditMessage && <div className='d-flex justify-content-start mt-2'>
                    {(props.showEditMessage && props.selectedMessageForEditing.albumId && (selectedSendTo < 4 || selectedSendTo == 10 || selectedSendTo == 6)) && (
                      <label className='btn btn-info m-2' htmlFor="upload-images-messagesfra-edit">
                        Add Images
                      </label>
                    )}
                    {(props.showEditMessage && props.selectedMessageForEditing.parseObject.attributes.attachment && (selectedSendTo > 0)) && (
                      <label className='btn btn-info m-2' htmlFor='upload-files-messagesfra-edit'>
                        Replace File
                      </label>
                    )}
                    {(props.showEditMessage && props.selectedMessageForEditing.videoAlbumId && (selectedSendTo < 4 || selectedSendTo == 10 || selectedSendTo == 6)) && (
                      <label className='btn btn-info m-2' htmlFor='upload-video-messagesfra-edit'>
                        Replace Video
                      </label>
                    )}
                    <Input type="image"
                      name="upload-images-messagesfra-edit"
                      className="sr-only"
                      id="upload-images-messagesfra-edit"
                      accept="image/*"
                      onChange={(e) => {
                        clearAllAttachments()
                        handleImageUploadChange(e)
                        // let tempInput = document.getElementById("upload-images-messagesfra").value = null
                      }}
                      multiple
                    />

                    <input type="file"
                      className="sr-only"
                      name="upload-files-messagesfra-edit"
                      id="upload-files-messagesfra-edit"
                      accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,/*"
                      onChange={(e) => {
                        clearAllAttachments()
                        handleFileUploadChange(e)
                        // let tempInput = document.getElementById("upload-files-messagesfra").value = null
                      }}
                    />

                    <input type="file"
                      className="sr-only"
                      name="upload-video-messagesfra-edit"
                      id="upload-video-messagesfra-edit"
                      accept="video/*"
                      onChange={(e) => {
                        clearAllAttachments()
                        handleVideoUploadChange(e)
                      }}
                    />
                  </div>}
                </Col>
              </Row>
            </Col>
            <Col lg={4}>
              <div className='d-flex justify-content-end mt-2'>
                {messagePostLoading ? (
                  <button id="sendMessageQueueBtn" className='btn btn-primary m-2'>
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                    Sending...
                  </button>
                ) : (
                  <button
                    id="sendMessageQueueBtn"
                    className='btn btn-primary m-2'
                    onClick={() => {
                      if (progressText && progressText.length > 0) {
                        // Handle your logic when progressText has content
                      } else {
                        onPostMessage();
                      }
                    }}
                  >
                    Send Message
                  </button>
                )}
                {progressText && <UncontrolledTooltip placement="top" target="sendMessageQueueBtn">Upload is in progress</UncontrolledTooltip>}
              </div>
            </Col>
          </Row>
        </AvForm>
      </CardBody>
    </Card >)
  }

  const getAttachmentsUI = () => {
    return (
      <>
        {progressText ? <label style={{ fontSize: "4mm" }} className=" mb-1 text-center mt-2">{progressText}<i className='align-middle font-size-22 ml-2 text-dark bx bx-loader bx-spin'></i></label> :
          <>
            {albumImageList && albumImageList.length > 0 &&
              <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                {`${albumImageList.length} images attached`}
                <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                  setAlbumImageList([])
                }}></i>
              </div>
            }
            {albumVideoList && albumVideoList.length > 0 &&
              <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                {`${albumVideoList.length} video attached`}
                <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                  setAlbumVideoList([])
                }}></i>
              </div>
            }
            {documentParseFile &&
              <div className='mt-2 p-2' style={{ border: "1px solid lightGrey", borderRadius: "10px" }}>
                {`${1} document attached`}
                <i className='bx bx-x-circle font-size-20 ml-3 align-middle' onClick={() => {
                  setDocumentParseFile()
                }}></i>
              </div>
            }
          </>}</>
    )
  }

  const closeSelectSchoolModal = () => {
    setShowSchoolPickerModal(!showSchoolPickerModal)
  }

  const getLightBoxImagesUI = (mainSrc, onCloseRequest) => (
    <Lightbox
      mainSrc={mainSrc}
      onCloseRequest={onCloseRequest}
    />
  );

  const getVideoUI = (message) => {
    if (message.attributes.video) {
      return (
        <div className="embed-responsive embed-responsive-16by9 text-center" style={{ minWidth: "120mm", maxWidth: "120mm", minHeight: "70mm", maxHeight: "70mm" }}>
          <Iframe allowFullScreen={true} title="test" className="embed-responsive-item" src={message.attributes.video._url}></Iframe>
        </div>
      )
    }
    return
  }

  return (
    <React.Fragment>
      {imagePopup ? getLightBoxImagesUI(photoIndex.attributes.image._url, () => setImagePopup(!imagePopup)) : null}
      <DocumentViewerModal
        clickedFile={clickedFile}
        closeModal={() => {
          setClickedFile()
        }}
      ></DocumentViewerModal>
      <Modal size='md' isOpen={showVideoModel}>
        <ModalHeader toggle={() => {
          setShowVideoModel()
        }}></ModalHeader>
        {showVideoModel && <ModalBody>
          {getVideoUI(showVideoModel)}
        </ModalBody>}
      </Modal>
      {showDeleteAlbum && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setShowDeleteAlbum(false);
          }}
          onConfirm={() => {
            handelDeleteFile()
          }}
        >
          {"Are you sure you want to delete"}
          <br></br>
        </SweetAlert>
      )}
      {showDeleteAttachment && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setShowDeleteAttachment(false);
          }}
          onConfirm={() => {
            deleteAttachment()
          }}
        >
          {"Are you sure you want to delete"}
          <br></br>
        </SweetAlert>
      )}
      <div className="">

        <Container fluid>


          <Row className="d-flex align-items-start">
            <Col className="d-flex align-items-start">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => { props.toggleView() }}>
                <ul className=" list-unstyled">
                  <div className=''>
                    <i className="bx bx-chevron-left h1 text-primary"></i>
                  </div>
                </ul>
              </div>
              <div className=' m-2'>
                <h4 >{props.showEditMessage ? 'Edit Message' : 'Send New Message'}</h4>
              </div>
            </Col>
          </Row>
          {getAddNewMessageUI()}


          <Modal isOpen={showSchoolPickerModal} className={"modal-dialog modal-xl"} scrollable={true} backdrop={true}>
            <ModalHeader toggle={() => {
              closeSelectSchoolModal()
            }}>Select Schools</ModalHeader>
            <ModalBody>
              <SchoolPicker franchiseId={props.franchiseId} selectedSchools={selectedSchoolListForPicker ?? []}
                onButtonClick={(selectedSchools, allSchools) => {
                  if (selectedSchools && selectedSchools.length > 0) {
                    console.log(selectedSchools)
                    setSelectedSchoolListForPicker(selectedSchools)
                    setAllSchools(allSchools)
                    closeSelectSchoolModal()
                  } else {
                    toastr.warning("Please select atleast one school to proceed")
                  }
                }}>
              </SchoolPicker>
            </ModalBody>
          </Modal>
        </Container>


      </div>

    </React.Fragment>
  );
}

const mapStatetoProps = state => {

  const { messageQueues, noMessagesToShow, isLoadingMessages, albums } = state.Message;
  const { franchiseTeachers } = state.Teacher;
  const { selectedSchool, schools } = state.School;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;
  return {
    messageQueues, noMessagesToShow, isLoadingMessages, albums,
    franchiseTeachers,
    selectedSchool,
    classrooms,
    kids, schools
  }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
  getMessageQueues,
  getFranchiseTeachers,
  updateMessageQueues,
  updateAlbums,
  getClassroomsForSchoolId,
  getAllKids,
  getAlbumsForAlbumIds,
  deleteAlbum
})(MessageQueueAddMessage)));
