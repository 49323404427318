import * as actions from "./actionTypes"

export const updateInventoryVendor = (inventoryVendor) => {
    return {
        type: actions.UPDATE_INVENTORY_VENDOR,
        payload: { inventoryVendor },
    }
}

export const deleteInventoryVendor = (inventoryVendor) =>{
    return{
        type:actions.DELETE_INVENTORY_VENDOR,
        payload: {inventoryVendor},
    }
}

export const updateDeleteInventoryVendor = (inventoryVendor) =>{
    return{
        type:actions.UPDATE_DELETE_INVENTORY_VENDOR,
        payload: {inventoryVendor},
    }
}

export const updateInventoryVendors = (inventoryVendors) =>{
    return{
        type:actions.UPDATE_INVENTORY_VENDORS,
        payload: {inventoryVendors},
    }
}

export const getInventoryVendorForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_VENDORS,
    payload: {ownerId, ownerType},
    }
}

export const editInventoryVendor = (inventoryVendor) =>{
    return{
        type: actions.EDIT_INVENTORY_VENDOR,
        payload: {inventoryVendor}
    }
}

export const setInventoryVendor = (state) =>{
    return{
        type: actions.SET_INVENTORY_VENDOR,
        payload: {state}
    }
}

export const addInventoryVendor = (inventoryVendorId) =>{
    return{
        type: actions.ADD_INVENTORY_VENDOR,
        payload: {inventoryVendorId}
    }
}

export const setSelectedVendor = (inventoryVendorId) => {
    return {
        type: actions.SET_SELECTED_VENDOR,
        payload: { inventoryVendorId }
    }
}

export const updateSelectedVendor = (inventoryVendor) => {
    return {
      type: actions.UPDATE_SELECTED_VENDOR,
      payload: { inventoryVendor },
    };
  };
