import { useState, useEffect } from "react";
import { Card, CardBody, Label,Badge } from "reactstrap";
import Topbar from '../Topbar';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { setSelecetdSchool, getPurchaseOrdersForOwnerId, getInventoryPurchaseOrderItemsForOwnerId, getInventoryVendorForOwnerId, updateDeletePurchaseOrder, deletePurchaseOrder } from '../../../store/actions';

function PurchaseOrder(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/purchase-order/:id",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-purchase-order/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [showDelete, setShowDelete] = useState(false);
    const [deleteObject, setDeleteObject] = useState(undefined);

    useEffect(() => {
        if (schoolId&&(!props.selectedSchool || props.selectedSchool.id !== schoolId) ){
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getInventoryVendorForOwnerId(schoolId, 'school')
            props.getPurchaseOrdersForOwnerId(schoolId, 'school')
            props.getInventoryPurchaseOrderItemsForOwnerId(schoolId, 'school')
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryVendorForOwnerId(franchiseId, 'franchise')
            props.getPurchaseOrdersForOwnerId(franchiseId, 'franchise')
            props.getInventoryPurchaseOrderItemsForOwnerId(franchiseId, 'franchise')
        }
    }, [franchiseId]);


    const handleDeleteItem = () => {
        props.deletePurchaseOrder(deleteObject)
        setShowDelete(false);
    };

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }

    const getVendorName = (vendorId) => {
        if (props.inventoryVendors && props.inventoryVendors.length > 0) {
            let temp = props.inventoryVendors.filter(
                (vendor) => vendor.id === vendorId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.companyName ?? "-")
            }
        }
    }


    return (
        <div className='page-content'>
            <Topbar
                title={"Purchase Order"}
                buttonName={"Add Stock"}
                link={schoolId ? `/new-purchase/${schoolId} ` : `/franchise-new-purchase/${franchiseId}`}
                hideButton={false} />
            {showDelete && deleteObject.attributes.status == 1 && (
                <SweetAlert
                    warning
                    showCancel={false}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title="Alert!"
                    onConfirm={() => {
                        setShowDelete(false);
                        setDeleteObject(undefined);
                        
                    }}
                    
                >
                    {"This purchese habeen approved and it can not be deleted " }
                    <br></br>
                </SweetAlert>
            )}

            {showDelete && deleteObject.attributes.status != 1 && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDelete(false);
                        setDeleteObject(undefined)
                    }}
                    onConfirm={() => {
                        handleDeleteItem();
                        
                    }}
                >
                    { "Are you sure you want to delete"}
                    <br></br>
                </SweetAlert>
            )}

            <div>
                {props.inventoryPurchaseOrders && props.inventoryPurchaseOrders.length > 0 ? (<Card>
                    <CardBody>
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th>Vendors</th>
                                        <th>Date</th>
                                        <th>Order value</th>
                                        <th>Status</th>
                                        <th style={{ width: "20px" }}>
                                            Print
                                        </th>
                                        <th style={{ width: "20px" }}>
                                            Edit
                                        </th>
                                        <th style={{ width: "20px" }}>
                                            Delete
                                        </th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.inventoryPurchaseOrders.map((buyer, key) =>
                                            <tr key={key}>
                                                <td>

                                                    <Link to={match ? `/edit-inventory-purchase-order/${schoolId}/${buyer.id}` : `/franchise-edit-purchase-order/${franchiseId}/${buyer.id}`} >
                                                        {getVendorName(buyer.attributes.vendorId, props.inventoryVendors)}
                                                    </Link><br/>
                                                    <Label><b>#{buyer.attributes.purchaseOrderNumber}</b></Label>
                                                </td>
                                                <td>
                                                    <Label className="font-size-12 mb-1">{getTimeStringForDate(buyer.attributes.createdAt)}</Label>
                                                </td>
                                                <td>
                                                    <Label className="font-size-12 mb-1">{buyer.attributes.costPrice}</Label>
                                                </td>
                                                <td>
                                                    <div className="flex-fill  ">
                                                       {buyer.attributes.status==1? <Badge color='primary' className=
                                                            "font-size-12 badge-soft-success"  >
                                                            Recived
                                                        </Badge> :
                                                            <Badge color='primary' className=
                                                            "font-size-12 badge-soft-danger"  >
                                                            Not Recived
                                                        </Badge>
                                                            
                                                        }
                                                    </div>
                                                </td>

                                                <td>

                                                    <div className="flex-fill text-center ">
                                                        <Link to={match ? `/print-purchase-order/${schoolId}/${buyer.id}` : `/franchise-print-purchase-order/${franchiseId}/${buyer.id}`} >
                                                            <i className="bx bx-printer"></i>
                                                        </Link>
                                                    </div>

                                                </td>

                                                <td>

                                                    <div className="flex-fill text-center ">
                                                        <Link to={match ? `/edit-inventory-purchase-order/${schoolId}/${buyer.id}` : `/franchise-edit-purchase-order/${franchiseId}/${buyer.id}`} >
                                                            <i className="bx bx-edit-alt"></i>
                                                        </Link>
                                                    </div>

                                                </td>

                                                <td>
                                                    <div
                                                        className="flex-fill text-center"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setDeleteObject(buyer)
                                                            setShowDelete(true);
                                                        }}
                                                    >
                                                        <i className="bx bxs-trash text-danger"></i>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </CardBody>
                </Card>) :
                (<div>
                    {props.inventoryPurchaseOrders && props.inventoryPurchaseOrders.length <= 0 && <Card style={{ height: "400px" }}>
                        <CardBody>
                            <h4 style={{ marginTop: "150px" }} className='text-info text-center'>No Purchase orders found .</h4>
                        </CardBody>
                    </Card>}
                </div>)}
                <div style={{ height: "400px" }}></div>
            </div>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryVendors } = state.InventoryVendor;
    const { inventoryPurchaseOrders } = state.InventoryPurchaseOrder;
    const { inventoryPurchaseOrderItems } = state.InventoryPurchaseOrderItem;
    return { selectedSchool, inventoryPurchaseOrders, inventoryPurchaseOrderItems, inventoryVendors };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getPurchaseOrdersForOwnerId, getInventoryPurchaseOrderItemsForOwnerId, getInventoryVendorForOwnerId, updateDeletePurchaseOrder, deletePurchaseOrder, })(PurchaseOrder)));