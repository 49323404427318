import React, { useState, useEffect } from 'react'
import Topbar from '../Inventory/Topbar'
import {
    Container, Row, Col, Table, Button, Label, Dropdown, Badge,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Card,
    CardBody, Nav, NavItem, NavLink
} from "reactstrap";
import Moment from 'moment';
import Parse from 'parse';
import SweetAlert from "react-bootstrap-sweetalert";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import * as Constants from "../../Constents";
import {
    setSelecetdSchool,
    getTicketsForRecipientId, getTicketsForAssignedFor,
    deleteTicket, updateDeleteTicket, clearTickets,
    editTicket,
    getFranchiseTeachers,
    getFranchise,
    getAllKids,
    setSelecetdTicketTab,
} from '../../store/actions';

function AllTickets(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/all-tickets/:id",
        exact: true,
        strict: false,
    });

    const match2 = matchPath(props.history.location.pathname, {
        path: "/franchise-all-tickets/:franchiseId",
        exact: true,
        strict: false,
    });


    const schoolId = match && match.params.id;
    const franchiseId = match2 && match2.params.franchiseId;

    const [deleteObject, setDeleteObject] = useState(undefined);
    const [showDelete, setShowDelete] = useState(false);
    const [toggleId, setToggleId] = useState(undefined);
    const [newState, setNewState] = useState(undefined);
    const [customActiveTab, setcustomActiveTab] = useState("1");

    const [franchisePermisson, setFranchisePermission] = useState(false);
    const [schoolPermission, setSchoolPermission] = useState(false);


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if( props.selectedSchool && props.selectedSchool.id == schoolId){
            if (props.selectedSchool.attributes.featuresList.length > Constants.FEATURE_TICKETS &&
              props.selectedSchool.attributes.featuresList[Constants.FEATURE_TICKETS] > 0) {
                setSchoolPermission(true)
            }
      
            if(props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length>0){
              props.getFranchise(props.selectedSchool.attributes.franchiseId);
            }
          }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.clearTickets();  
            if(props.seletedTicketTab && franchisePermisson && schoolPermission){

                if(props.seletedTicketTab === "school"){
                    setcustomActiveTab("1");
                    props.getTicketsForRecipientId(schoolId, 'school');
                }
                if(props.seletedTicketTab === "student"){
                    setcustomActiveTab("2");
                    props.getTicketsForAssignedFor(schoolId, 'school');
                }
            }else if(franchisePermisson){
                props.getTicketsForRecipientId(schoolId, 'school');
            }else if(schoolPermission){
                props.getTicketsForAssignedFor(schoolId, 'school');
            }
            
        }
    }, [schoolId,franchisePermisson,schoolPermission,props.seletedTicketTab]);

    
    useEffect(() => {
        if (franchiseId) {
            props.getTicketsForAssignedFor(franchiseId, 'franchise');
            props.getFranchiseTeachers();
        }
    }, [franchiseId])
    
    useEffect(()=>{
        if(schoolId){
            props.getAllKids(schoolId);
        }
    },[schoolId])

    useEffect(() => {

        if (props.franchise && props.franchise.attributes.features) {
    
          if (props.franchise.attributes.features.length > Constants.FRANCHISE_TICKETS_INDEX &&
              props.franchise.attributes.features[Constants.FRANCHISE_TICKETS_INDEX] > 0) {
                setFranchisePermission(true)
          }
        }
    }, [props.franchise]);

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }
    

    const getSchoolName = (schoolId) => {
        if (props.schools && props.schools.length > 0) {
            let temp = props.schools.filter(
                (school) => school.id === schoolId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }
    const getStudentName = (kidId) => {
        if (props.kids && props.kids.length > 0) {
            let temp = props.kids.filter(
                (kid) => kid.id === kidId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name + " " ?? "-")
            }
        }
    }

    function toggleCustom(tab) {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);

            props.clearTickets();
            if(tab=="1"){
                props.getTicketsForRecipientId(schoolId, 'school');
            }else if(tab == "2"){
                props.getTicketsForAssignedFor(schoolId, 'school');
            }
        }
    }

    useEffect(() => {
       console.log("props.tickets: ",props.tickets)
    }, [props.tickets]);

    const handelDeleteTicket = () => {

        let logObject = {
            ticketId: deleteObject.id,
            message: "Deleted",
            teacherId: Parse.User.current().attributes.teacherId
        }
        props.deleteTicket(deleteObject, logObject)
        setShowDelete(false);
    };

    function getStatus(status) {
        if (status == 0) {
            return "Open";
        } else if (status == 1) {
            return "Pending";
        } else if (status == 2) {
            return "Resolved";
        } else if (status == 3) {
            return "Closed";
        }
        return "Open"
    }

    function getstatusFromState(state) {
        if (state == "Open") {
            return 0;
        } else if (state == "Pending") {
            return 1;
        } else if (state == "Resolved") {
            return 2;
        } else if (state == "Closed") {
            return 3;
        }
        return 0
    }

    function getStatusColor(status) {

        if (status == 0) {
            return "primary";
        } else if (status == 1) {
            return "warning ";
        } else if (status == 2) {
            return "success";
        } else if (status == 3) {
            return "success";
        }
        return "primary"
    }

    function getDropdwn(ticket) {

        var currentState = getStatus(ticket.attributes.status);
        var list = [
            "Open",
            "Pending",
            "Resolved",
            "Closed",
        ];

        return (
            <Dropdown
                isOpen={toggleId == ticket.id}
                toggle={() => setToggleId(toggleId ? undefined : ticket.id)}
            >
                <DropdownToggle className="bg-white border-0" caret>
                    <Badge
                        className={
                            "font-size-12 badge-soft-" +
                            getStatusColor(ticket.attributes.status)
                        }
                        color="success"
                        pill
                    >
                        {currentState}
                        {(match2 || customActiveTab == "2") && <i className="mdi mdi-chevron-down"></i>}
                    </Badge>
                </DropdownToggle>
                {(match2 || customActiveTab=="2") && <DropdownMenu>
                    {list.map((item, key) => (
                        <DropdownItem
                            key={key}
                            onClick={() => {
                                let fromStatus = ticket.attributes.status ?? 0;
                                let fromState = getStatus(fromStatus);
                                let toStatus = getstatusFromState(item);
                                let toState = item;
                                if (fromStatus != toStatus) {
                                    //create ticket log and save in db
                                    let message = fromState + " to " + toState;
                                    let logObject = {
                                        ticketId: ticket.id,
                                        fromStatus: fromStatus,
                                        toStatus: toStatus,
                                        message: message,
                                        teacherId: Parse.User.current().attributes.teacherId
                                    }

                                    ticket.set("status", toStatus);
                                    props.editTicket(ticket, logObject);
                                }
                            }}
                        >
                            {item}
                        </DropdownItem>
                    ))}
                </DropdownMenu>}
            </Dropdown>
        );
    }

    const getTeacher = (teacherId) => {
        if (match2) {
            var ct = props.franchiseTeachers && props.franchiseTeachers.filter((teacher) => teacherId == teacher.id)
            let currentTeacher = ct && ct.length > 0 && ct[0];
            if (currentTeacher) {
                return (
                    <p>{"Assigned to: " + currentTeacher.attributes.Name}</p>
                );
            }
        }
    }


    return (
        <div className='page-content'>
            <Topbar
                title={"All tickets"}
                buttonName={"New ticket"}
                hideButton={false}
                link={schoolId ? `/new-ticket/${schoolId}` : `/franchise-new-ticket/${franchiseId}`}
            />
            {showDelete && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                    onConfirm={() => handelDeleteTicket()}
                >
                    {"Are you sure you want to delete"}
                    <br></br>
                </SweetAlert>
            )}
            { match && franchisePermisson && schoolPermission && <div className='mt-2 mb-3'>
                <Nav pills className="navtab-bg nav-justified ">
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={{
                                active: customActiveTab === "1",
                            }}
                            onClick={() => {
                                toggleCustom("1");
                            }}
                        >
                            {('School').toUpperCase()}
                        </NavLink>
                    </NavItem>
                    <NavItem>
                        <NavLink
                            style={{ cursor: "pointer" }}
                            className={{
                                active: customActiveTab === "2",
                            }}
                            onClick={() => {
                                toggleCustom("2");
                            }}
                        >
                            {('Student').toUpperCase()}
                        </NavLink>
                    </NavItem>
                </Nav>
            </div>}

            <Row>
                <Col lg="12">
                    <div className="">
                        <div className="table-responsive">
                            <Card>
                                <CardBody>
                                    <Table className="table table-centered table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th scope="col" style={{ width: "100px" }}>#</th>
                                                <th scope="col">Tickets</th>
                                                <th scope="col">Date</th>
                                                <th scope="col">Status</th>
                                                <th style={{ width: "20px" }}>
                                                    View details
                                                </th>
                                                <th style={{ width: "20px" }}>
                                                    Delete
                                                </th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {props.tickets && props.tickets.length==0 && <Label className='m-2'>No Tickets</Label>}
                                            {
                                                props.tickets.map((ticket, key) => 
                                                    <tr key={key}>
                                                        <td>
                                                            {(key + 1)}
                                                        </td>
                                                        <td>
                                                            <p className='mb-0'>#{ticket.attributes.ticketNumber}</p>
                                                            <h6>{ticket.attributes.title}</h6>
                                                            {match2 && <small>{getSchoolName(ticket.attributes.recipientId)}</small>}
                                                            {ticket.attributes.recipientType === 'kid' && (
                                                                <h6>{getStudentName(ticket.attributes.recipientId)}</h6>
                                                            )}
                                                        </td>
                                                        <td>
                                                            {getTimeStringForDate(ticket.attributes.ticketDate)}                                                        </td>
                                                        <td>
                                                            {getDropdwn(ticket)}
                                                            {getTeacher(ticket.attributes.assignedTo)}
                                                        </td>
                                                        <td>
                                                            {/* <Link to={match ? `/ticket-details/${schoolId}/${ticket.id}` : `/franchise-ticket-details/${ticket.attributes.recipientId}/${ticket.id}`} >*/}
                                                            

                                                                <Button 
                                                                    type="button" 
                                                                    color="primary" 
                                                                    size="sm" 
                                                                    className="btn-rounded waves-effect waves-light"
                                                                    onClick={()=>{
                                                                        if(schoolPermission&& franchisePermisson){
                                                                            if(customActiveTab=="1"){
                                                                                props.setSelecetdTicketTab("school")
                                                                            }else if(customActiveTab=="2"){
                                                                                props.setSelecetdTicketTab("student")
                                                                            }
                                                                        }
        
                                                                        props.history.push(match ? `/ticket-details/${schoolId}/${ticket.id}` : `/franchise-ticket-details/${ticket.attributes.recipientId}/${ticket.id}`);
                                                                    }}
                                                                    >
                                                                        View Details
                                                                </Button>
                                                            {/* </Link> */}
                                                        </td>
                                                        <td>
                                                            <div
                                                                className="flex-fill text-center"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setDeleteObject(ticket)
                                                                    setShowDelete(true);
                                                                }}
                                                            >
                                                                <i className="bx bxs-trash text-danger"></i>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                        </tbody>
                                    </Table>
                                </CardBody>
                            </Card>
                        </div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}
const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { tickets,seletedTicketTab } = state.Ticket;
    const { franchiseTeachers } = state.Teacher;
    const { franchise } = state.Franchise;
    const { kids } = state.Kid;

    return { selectedSchool, tickets, schools, franchiseTeachers ,franchise, kids,seletedTicketTab};
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getTicketsForRecipientId, getTicketsForAssignedFor,
    deleteTicket, updateDeleteTicket,clearTickets,
    editTicket,
    getFranchiseTeachers,
    getFranchise,
    getAllKids,
    setSelecetdTicketTab,
})(AllTickets)));