import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as EnquiryNoteHelper from "../../../helpers/ParseHelpers/enquirynotes_helper";
import * as actionTypes from "./actionTypes";
import * as EnquiryNoteActions from "./actions";
import * as Constants from "../../../Constents";

function* getAEnquiryNote({ payload: { id } }) {
  try {
    const response = yield call( EnquiryNoteHelper.getEnquiryNoteFromParse, id,false);
    yield put(EnquiryNoteActions.updateEnquiryNote(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAEnquiryNote({ payload: { enquiryNote } }) {
  try {
    const response = yield call(
      EnquiryNoteHelper.deleteEnquiryNoteFromParse,
      enquiryNote
    );
    if (response) {
      yield put(EnquiryNoteActions.updateEnquiryNote(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getEnquiryNotesForEnquiryId({ payload: { enquiryId } }) {
  try {
    const localResponse = yield call( EnquiryNoteHelper.getEnquiryNotesForEnquiryId,enquiryId,true);
    if (localResponse) {
      yield put(EnquiryNoteActions.updateEnquirynotes(localResponse,true));
      
    }

    const serverResponse = yield call( EnquiryNoteHelper.getEnquiryNotesForEnquiryId,enquiryId,false);
  
    if (serverResponse) {
      yield put(EnquiryNoteActions.updateEnquirynotes(serverResponse,false));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getEnquiryNotesForEnquiryIds({ payload: { enquiryIds } }) {
  try {
    

    const serverResponse = yield call( EnquiryNoteHelper.getEnquiryNotesForEnquiryIds,enquiryIds);
  
    if (serverResponse) {
      yield put(EnquiryNoteActions.updateEnquiryNotesForEnquiryIds(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editEnquiryNote({ payload: {enquiryNote} }) {
  try {
      const response = yield call(EnquiryNoteHelper.editEnquiryNote , enquiryNote);
      if (response) {
        yield put(EnquiryNoteActions.updateEnquiryNote(response));
        response.pin();
      } else {
          
      }
  } catch (error) {
      // yield put(apiError(error));
  }
}

export function* watchGetEnquiryNote() {
  yield takeEvery(actionTypes.GET_ENQUIRY_NOTE, getAEnquiryNote);
}

export function* watchDeleteEnquiryNote() {
  yield takeEvery(actionTypes.DELETE_ENQUIRY_NOTE, deleteAEnquiryNote);
}


export function* watchGetEnquiryNotesForEnquiryId() {
  yield takeEvery(actionTypes.GET_ENQUIRY_NOTES, getEnquiryNotesForEnquiryId);
}
export function* watchEditEquiryNote() {
  yield takeEvery(actionTypes.EDIT_ENQUIRY_NOTE, editEnquiryNote);
}

export function* watchGetEnquiryNotesForEnquiryIds() {
  yield takeEvery(actionTypes.GET_ENQUIRY_NOTES_EQUIRY_IDS, getEnquiryNotesForEnquiryIds);
}

function* EnquiryNoteSaga() {
  yield all([
    fork(watchGetEnquiryNote),
    fork(watchDeleteEnquiryNote),
    fork(watchGetEnquiryNotesForEnquiryId),
    fork(watchEditEquiryNote),
    fork(watchGetEnquiryNotesForEnquiryIds),
  ]);
}
export default EnquiryNoteSaga;
