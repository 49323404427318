import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil"

const initialState = {
  programs: [], selectedProgram: undefined, isLoading: true, deletedSuccessfullyProgram: undefined, noProgramsToDisplay: false
  , programTerms: [], forwardToProgramView: undefined
};

const Program = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_PROGRAM:

      var programs = [...state.programs]
      var temp = state.programs.filter(program => program.id === action.payload.program.id)

      if (temp.length > 0) {

        if (action.payload.program.attributes.deleted) {

          var others = state.programs.filter(
            (program) =>
              program.id !== action.payload.program.id
          );
          state = { ...state, programs: others };

        } else {

          var index = programs.indexOf(temp[0])
          programs[index] = action.payload.program
          state = { ...state, programs: programs, noProgramsToDisplay: programs.length === 0 ? true : false };
        }
      } else {
        programs.push(action.payload.program)
        state = { ...state, programs: programs, noProgramsToDisplay: programs.length === 0 ? true : false };
      }
      break;


    case actions.UPDATE_PROGRAMS:


      var programs = [...state.programs];
      if (programs.length > 0) {
        for (const latest in action.payload.programs) {
          var temp = programs.filter(
            (program) =>
              program.id === action.payload.programs[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.programs[latest].attributes.deleted) {

              var others = programs.filter(
                (program) =>
                  program.id !== action.payload.programs[latest].id
              );

              programs = others;
            } else {
              var index = programs.lastIndexOf(temp[0]);
              programs[index] = action.payload.programs[latest];
            }

          } else {
            if (action.payload.programs[latest].attributes.deleted !== true) {
              programs.push(action.payload.programs[latest]);
            }

          }
        }
        state = { ...state, programs: programs, noProgramsToDisplay: programs.length === 0 ? true : false };
      } else {

        var i = 0
        var programsToSave = []
        for (i = 0; i < action.payload.programs.length; i++) {
          if (action.payload.programs[i].attributes.deleted !== true) {
            programsToSave.push(action.payload.programs[i])
          }
        }


        state = { ...state, programs: programsToSave, noProgramsToDisplay: programsToSave.length === 0 ? true : false };
      }
      break
    case actions.DELETE_PROGRAM:
      var programs = state.programs.filter(
        (program) => program.id !== action.payload.id
      );
      state = { ...state, programs: programs, noProgramsToDisplay: programs.length === 0 ? true : false };
      break;
    case actions.SET_LOADING:

      state = { ...state, isLoading: action.payload.boolValue };
      break;
    case actions.UPDATE_SELECTED_PROGRAM:

      state = { ...state, selectedProgram: action.payload.program };
      break;
    case actions.DELETED_SUCCESSFULLY_PROGRAM:
      state = { ...state, deletedSuccessfullyProgram: action.payload.boolValue }
      break;
    case actions.SET_NO_PROGRAMS:
      state = { ...state, noProgramsToDisplay: action.payload.boolValue }
      break;

    case actions.SET_FORWARD_TO_PROGRAM_VIEW:
      state = { ...state, forwardToProgramView: action.payload.program }


    //************* PROGRAM TERMS *************//


    case actions.UPDATE_PROGRAM_TERMS_FOR_PROGRAM_ID:

      let updatedList = ReduxUtil.updateParseObjects([...state.programTerms], action.payload.terms, "isDeleted", true)
      state = { ...state, programTerms: updatedList };

      break
    case actions.GET_PROGRAM_TERMS_FOR_PROGRAM_ID:
      state = { ...state, programTerms: [] };
      break
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Program;
