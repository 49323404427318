import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
  Table,
  Progress,
  Media,
  UncontrolledTooltip,
} from "reactstrap";
import toastr from "toastr";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import Moment from "moment";
import UpdatePhoto from "../../components/Common/UpdatePhoto";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";
import MetaTags from 'react-meta-tags';
import * as Constants from "../../Constents";
import { setSelecetdSchool } from "../../store/actions";
import SweetAlert from "react-bootstrap-sweetalert";

//Initial Setup
//UI
//Validations
//Saving Part
//Handler Functions
//Utility Functions.

const EditEnquiryForm = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/enquiry-form/:param",
    exact: true,
    strict: false,
  });
  const matchInsideDashboard = matchPath(props.history.location.pathname, {
    path: "/editenquiryinside/:param",
    exact: true,
    strict: false,
  });
  const matchEnquiryIOS = matchPath(props.history.location.pathname, {
    path: "/enq-form/:client/:schoolId/:teacherId",
    exact: true,
    strict: false,
  })
  const franchiseForm = matchPath(props.history.location.pathname, {
    path: "/franchise-enquiry-form/:schoolId/:franchiseId",
    exact: true,
    strict: false,
  });
  const instituteForm = matchPath(props.history.location.pathname, {
    path: "/institute-enquiry-form/:schoolId/:instituteId",
    exact: true,
    strict: false,
  });

  const EnquiryRefillmatch = matchPath(props.history.location.pathname, {
    path: "/enquiry-refill/:type/:typeId/:id",
    exact: true,
    strict: false,
  });

  var parameter = match && match.params.param;
  let franchiseId;
  let instituteId;
  var teacherId;
  var exEnquiryId;
  if (match) {
    parameter = match && match.params.param;
  } else if (matchInsideDashboard) {
    parameter = matchInsideDashboard && matchInsideDashboard.params.param;
  } else if (franchiseForm) {
    parameter = franchiseForm && franchiseForm.params.schoolId;
    franchiseId = franchiseForm && franchiseForm.params.franchiseId;
  } else if (instituteForm) {
    parameter = instituteForm && instituteForm.params.schoolId;
    instituteId = instituteForm && instituteForm.params.instituteId;
  } else if (matchEnquiryIOS) {
    parameter = matchEnquiryIOS && matchEnquiryIOS.params.schoolId;
    teacherId = matchEnquiryIOS && matchEnquiryIOS.params.teacherId;
  } else if (EnquiryRefillmatch) {
    parameter = EnquiryRefillmatch.params.typeId;
    exEnquiryId = EnquiryRefillmatch.params.id;
  }

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [formSubmitState, setFormSubmitState] = useState("NOT_SUBMITTED");
  const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
  const _pdfExtensions = [".pdf", "pdf"];
  const _audioExtensions = [
    ".mp3",
    "mp3",
    ".wma",
    "wma",
    ".acc",
    "acc",
    ".wav",
    "wav",
    ".flac",
    "flac",
  ];

  const [oneParentError, setOneParentError] = useState(false);

  const [schoolObject, setSchoolObject] = useState(undefined);
  const [enquiryQuestionsList, setEnquiryQuestionsList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isQuestionsAvailable, setIsQuestionsAvailable] = useState(true);

  const [selectedEnquiry, setSelectedEnquiry] = useState();
  const [reshareAlertBoxMessage, setReshareAlertBoxMessage] = useState(undefined);
  const [saveEnquiryDetails, setSaveEnquiryDetails] = useState();

  var abortSubmitForm = false;

  useEffect(() => {
    if (parameter) {
      fetchSchoolFromServer(parameter);
    }
  }, [parameter]);

  useEffect(() => {
    if (matchInsideDashboard && (!props.selectedSchool || props.selectedSchool.id !== parameter)) {
      props.setSelecetdSchool(parameter);
    }
  }, [props.selectedSchool, parameter]);


  const fetchSchoolFromServer = (schoolId) => {
    Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
      schoolId: schoolId,
    }).then(
      (result) => {
        if (result != null) {
          setSchoolObject(result);
          fetchEnquiryQuestionsForSchoolId(result.id,result.attributes.franchiseId);
        } else {
          setIsLoading(false);
          setIsQuestionsAvailable(false);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fetchEnquiryQuestionsForSchoolId = (schoolId,schoolFranchiseId) => {
    var query = new Parse.Query("EnquiryQuestions");
    if (franchiseId) {
      query.equalTo("franchiseId", franchiseId);
    } else if (instituteId) {
      query.equalTo("instituteId", instituteId);
    } else {
      query.equalTo("schoolId", schoolId);
    }
    query.ascending("order");
    query.notEqualTo("isDeleted", true);

    // query.find()
      
    Parse.Cloud.run("function_getEnquiryQuestions", {
      franchiseId: franchiseId,
      instituteId: instituteId,
      schoolId:schoolId,
      schoolFranchiseId:schoolFranchiseId
    })
    .then((results) => {
      setIsLoading(false);
      if (results != null) {
        if (results.length > 0) {
          // console.log("enquiry quistion", results)
          if (exEnquiryId) {
            getEnquiryForEnquiryId(results)
          } else {
            processQuestions(results);
          }
        } else if (!franchiseId && !instituteId && schoolId) {
          Parse.Cloud.run("function_enquiryQuestionValidateAndCreate", {
            schoolIds: [schoolId],
          }).then((newresults) => {
            processQuestions(newresults);
          });
        }
      }
    });
  };

  const checkAndSave=()=>{
    //checking enquiry status before saving the form
    getEnquiryForEnquiryId(undefined,true)
  }
  const getEnquiryForEnquiryId = (questions,isCheck) => {
    
    Parse.Cloud.run("function_getEnquiryDetails", {
      enquiryId: exEnquiryId
    }).then((newresults) => {
      if (newresults && newresults != -1) {
        setSelectedEnquiry(newresults);
        if (newresults.Enquiry.attributes.isDeleted) {
          setReshareAlertBoxMessage("Enquiry No longer exist")
        } else if (newresults.Enquiry.attributes.status == 1) {
          setReshareAlertBoxMessage("Enquiry have been moved to admission")
        } else if (newresults.Enquiry.attributes.enquiryFormResharedOn) {
          let difference = calculateDaysBetweenDates(newresults.Enquiry.attributes.enquiryFormResharedOn, new Date())
          if (newresults.Enquiry.attributes.enquiryFormResubmittedOn) {
            if (newresults.Enquiry.attributes.enquiryFormResubmittedOn > newresults.Enquiry.attributes.enquiryFormResharedOn) {
              setReshareAlertBoxMessage("Enquiry responce is already submitted")
            } else if (difference > 7) {
              setReshareAlertBoxMessage("Enquiry link is expired")
            } else {
              if(isCheck){
                //save Function
                console.log('resave log')
                reSaveForm();
              }else{
                processQuestions(questions, newresults);
              }
              
            }
          } else {
            if (difference > 7) {
              setReshareAlertBoxMessage("Enquiry link is expired")
            } else {
              if(isCheck){
                //save Function
                console.log('resave log')
                reSaveForm();
              }else{
                processQuestions(questions, newresults);
              }
            }
          }
        } else {
          setReshareAlertBoxMessage("Please contact school to Reshare the form")
        }

      } else {
        setReshareAlertBoxMessage("Enquiry no longer exist")
      }
    });
  }
  function calculateDaysBetweenDates(date1, date2) {
    const oneDayInMilliseconds = 24 * 60 * 60 * 1000;
    const startDate = new Date(date1);
    const endDate = new Date(date2);
    const timeDifference = Math.abs(endDate.getTime() - startDate.getTime());
    const daysDifference = Math.ceil(timeDifference / oneDayInMilliseconds);
    return daysDifference;
  }


  const processQuestions = (results, enquiryDetailsObject) => {
    if (results.length > 0) {
      var tempList = [];
      for (var i = 0; i < results.length; i++) {
        var enqQuestion = results[i];

        var newEnquiryObject = new CustomEnquiryObject();
        newEnquiryObject.id = enqQuestion.id;
        newEnquiryObject.question = enqQuestion.attributes.question ?? "";
        newEnquiryObject.questionType = enqQuestion.attributes.questionType ?? "";
        newEnquiryObject.questionNumber = i + 1;
        if (enqQuestion.attributes.dataType) {
          newEnquiryObject.dataType = enqQuestion.attributes.dataType;
        }
        if (enqQuestion.attributes.choice) {
          newEnquiryObject.choices = [...enqQuestion.attributes.choice];
        }
        if (
          enqQuestion.attributes.isMandatory &&
          enqQuestion.attributes.isMandatory === true
        ) {
          newEnquiryObject.isMandatory = true;
        } else {
          newEnquiryObject.isMandatory = false;
        }
        if (
          enqQuestion.attributes.questionHint != null &&
          enqQuestion.attributes.questionHint !== ""
        ) {
          newEnquiryObject.questionHint = enqQuestion.attributes.questionHint;
        } else {
          newEnquiryObject.questionHint = "Type here";
        }
        newEnquiryObject.answerChoices = [];

        if (
          enqQuestion.attributes.questionType &&
          (enqQuestion.attributes.questionType ===
            Constants.QESTION_YES_NO_COMMENT_ON_NO ||
            enqQuestion.attributes.questionType ===
            Constants.QESTION_YES_NO_COMMENT_ON_YES ||
            enqQuestion.attributes.questionType ===
            Constants.QESTION_YES_NO_NO_COMMENTS ||
            enqQuestion.attributes.questionType ===
            Constants.QESTION_YES_NO_WITH_COMMENT)
        ) {
          if (
            enqQuestion.attributes.choice == null ||
            !enqQuestion.attributes.choice.length > 0
          ) {
            newEnquiryObject.choices = ["Yes", "No"];
          }
        }
        newEnquiryObject.answerError = false;

        if (
          enqQuestion.attributes.questionType &&
          enqQuestion.attributes.questionType === Constants.QESTION_DATE &&
          enqQuestion.attributes.databaseTableName == "Enquiry" &&
          enqQuestion.attributes.databaseFieldName == "enquiryDate"
        ) {
          newEnquiryObject.answerDate = new Date();
          if (EnquiryRefillmatch) {
            newEnquiryObject.isDisabled = true;
          }
        }

        newEnquiryObject.enquiryQuestionParseObject = enqQuestion;


        //NEW Code

        if (EnquiryRefillmatch) {
          // console.log(enquiryDetailsObject)
          let enquiryObject = enquiryDetailsObject.Enquiry;
          let childAdditionalDetails = enquiryDetailsObject.ChildAdditionalDetails;
          let parents = enquiryDetailsObject.Parents;
          let fatherObject = undefined;
          let motherObject = undefined;

          for (const parent of parents) {
            if (parent.attributes.Relation == "Mother") {
              motherObject = parent;
            } else {
              fatherObject = parent;
            }
          }

          if (enqQuestion.attributes.questionType == Constants.QESTION_TEXT_SINGLE_LINE ||
            enqQuestion.attributes.questionType == Constants.QESTION_TEXT_MULTI_LINE ||
            enqQuestion.attributes.questionType == Constants.QUESTION_EMAIL ||
            enqQuestion.attributes.questionType == Constants.QUESTION_PHONE_NUMBER
          ) {

            if (enqQuestion.attributes.databaseTableName == 'Enquiry') {
              newEnquiryObject.answerText = enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]
            }
            if (parents && enqQuestion.attributes.databaseTableName == 'Parent') {
              if (fatherObject && enqQuestion.attributes.objectType == 2) {
                newEnquiryObject.answerText = fatherObject.attributes[enqQuestion.attributes.databaseFieldName]
              }

              if (motherObject && enqQuestion.attributes.objectType == 3) {
                newEnquiryObject.answerText = motherObject.attributes[enqQuestion.attributes.databaseFieldName]
              }

            }
            if (childAdditionalDetails && enqQuestion.attributes.databaseTableName == 'ChildAdditionalDetails') {
              newEnquiryObject.answerText = childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]
            }
          }

          if (enqQuestion.attributes.questionType == Constants.QESTION_NUMBER) {

            if (enqQuestion.attributes.databaseTableName == 'Enquiry') {
              newEnquiryObject.answerNumber = enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]
            }
            if (parents && enqQuestion.attributes.databaseTableName == 'Parent') {
              if (fatherObject && enqQuestion.attributes.objectType == 2) {
                newEnquiryObject.answerNumber = fatherObject.attributes[enqQuestion.attributes.databaseFieldName]
              }

              if (motherObject && enqQuestion.attributes.objectType == 3) {
                newEnquiryObject.answerNumber = motherObject.attributes[enqQuestion.attributes.databaseFieldName]
              }

            }
            if (childAdditionalDetails && enqQuestion.attributes.databaseTableName == 'ChildAdditionalDetails') {
              newEnquiryObject.answerNumber = childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]
            }
          }

          if (enqQuestion.attributes.questionType == Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST) {

            if (enqQuestion.attributes.databaseTableName == 'Enquiry') {
              newEnquiryObject.answerChoices = enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]??[]
            }

            if (parents && enqQuestion.attributes.databaseTableName == 'Parent') {
              if (fatherObject && enqQuestion.attributes.objectType == 2) {
                newEnquiryObject.answerChoices = fatherObject.attributes[enqQuestion.attributes.databaseFieldName]??[]
              }

              if (motherObject && enqQuestion.attributes.objectType == 3) {
                newEnquiryObject.answerChoices = motherObject.attributes[enqQuestion.attributes.databaseFieldName]??[]
              }

            }
            if (childAdditionalDetails && enqQuestion.attributes.databaseTableName == 'ChildAdditionalDetails') {
              newEnquiryObject.answerChoices = childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]??[]
            }

          }

          if (enqQuestion.attributes.questionType == Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST) {

            newEnquiryObject.answerChoices = [];
            if (enqQuestion.attributes.databaseTableName == 'Enquiry') {
              if (enqQuestion.attributes.dataType == 'ARRAY') {
                newEnquiryObject.answerChoices = enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]??[]
              } else {
                if (enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]) {
                  newEnquiryObject.answerChoices = [enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]]??[]
                }
              }
            }

            if (parents && enqQuestion.attributes.databaseTableName == 'Parent') {
              if (fatherObject && enqQuestion.attributes.objectType == 2) {
                if (enqQuestion.attributes.dataType == 'ARRAY') {
                  newEnquiryObject.answerChoices = fatherObject.attributes[enqQuestion.attributes.databaseFieldName]??[]
                } else {
                  if (fatherObject.attributes[enqQuestion.attributes.databaseFieldName]) {
                    newEnquiryObject.answerChoices = [fatherObject.attributes[enqQuestion.attributes.databaseFieldName]]??[]
                  }
                }
              }

              if (motherObject && enqQuestion.attributes.objectType == 3) {
                if (enqQuestion.attributes.dataType == 'ARRAY') {
                  newEnquiryObject.answerChoices = motherObject.attributes[enqQuestion.attributes.databaseFieldName]??[]
                } else {
                  if (motherObject.attributes[enqQuestion.attributes.databaseFieldName]) {
                    newEnquiryObject.answerChoices = [motherObject.attributes[enqQuestion.attributes.databaseFieldName]]??[]
                  }
                }

              }
            }

            if (childAdditionalDetails && enqQuestion.attributes.databaseTableName == 'ChildAdditionalDetails') {
              if (enqQuestion.attributes.dataType == 'ARRAY') {
                newEnquiryObject.answerChoices = childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]??[]
              } else {
                if (childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]) {
                  newEnquiryObject.answerChoices = [childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]]??[]
                }
              }

            }
          }

          if (enqQuestion.attributes.questionType == Constants.QESTION_DATE) {
            if (enqQuestion.attributes.databaseTableName == 'Enquiry') {
              newEnquiryObject.answerDate = enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]
            }
            if (parents && enqQuestion.attributes.databaseTableName == 'Parent') {
              if (fatherObject && enqQuestion.attributes.objectType == 2) {
                newEnquiryObject.answerDate = fatherObject.attributes[enqQuestion.attributes.databaseFieldName]
              }

              if (motherObject && enqQuestion.attributes.objectType == 3) {
                newEnquiryObject.answerDate = motherObject.attributes[enqQuestion.attributes.databaseFieldName]
              }

            }
            if (childAdditionalDetails && enqQuestion.attributes.databaseTableName == 'ChildAdditionalDetails') {
              newEnquiryObject.answerDate = childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]
            }
          }

          if (enqQuestion.attributes.questionType == Constants.QESTION_YES_NO_COMMENT_ON_NO ||
            enqQuestion.attributes.questionType == Constants.QESTION_YES_NO_COMMENT_ON_YES ||
            enqQuestion.attributes.questionType == Constants.QESTION_YES_NO_NO_COMMENTS ||
            enqQuestion.attributes.questionType == Constants.QESTION_YES_NO_WITH_COMMENT) {
            if (enqQuestion.attributes.databaseTableName == 'Enquiry') {
              if (enqQuestion.attributes.dataType == "BOOLEAN") {
                let ans = enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]
                let choice = enquiryObject.attributes.choices ?? ["Yes", "No"];
                if (ans == undefined) {

                } else if (ans == true) {
                  newEnquiryObject.answerChoices = [choice[0]]
                }
                else if (ans == false) {
                  newEnquiryObject.answerChoices = [choice[1]]
                }

              } else {
                if (enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]) {
                  newEnquiryObject.answerChoices = [enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]]
                }
              }
            }

            if (parents && enqQuestion.attributes.databaseTableName == 'Parent') {
              if (fatherObject && enqQuestion.attributes.objectType == 2) {
                if (enqQuestion.attributes.dataType == "BOOLEAN") {
                  let ans = fatherObject.attributes[enqQuestion.attributes.databaseFieldName]
                  let choice = fatherObject.attributes.choices ?? ["Yes", "No"];
                  if (ans == undefined) {

                  } else if (ans == true) {
                    newEnquiryObject.answerChoices = [choice[0]]
                  }
                  else if (ans == false) {
                    newEnquiryObject.answerChoices = [choice[1]]
                  }

                } else {
                  if (fatherObject.attributes[enqQuestion.attributes.databaseFieldName]) {
                    newEnquiryObject.answerChoices = [fatherObject.attributes[enqQuestion.attributes.databaseFieldName]]
                  }
                }
              }

              if (motherObject && enqQuestion.attributes.objectType == 3) {
                if (enqQuestion.attributes.dataType == "BOOLEAN") {
                  let ans = motherObject.attributes[enqQuestion.attributes.databaseFieldName]
                  let choice = motherObject.attributes.choices ?? ["Yes", "No"];
                  if (ans == undefined) {

                  } else if (ans == true) {
                    newEnquiryObject.answerChoices = [choice[0]]
                  }
                  else if (ans == false) {
                    newEnquiryObject.answerChoices = [choice[1]]
                  }

                } else {
                  if (motherObject.attributes[enqQuestion.attributes.databaseFieldName]) {
                    newEnquiryObject.answerChoices = [motherObject.attributes[enqQuestion.attributes.databaseFieldName]]
                  }
                }
              }

            }
            if (childAdditionalDetails && enqQuestion.attributes.databaseTableName == 'ChildAdditionalDetails') {
              if (enqQuestion.attributes.dataType == "BOOLEAN") {
                let ans = childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]
                let choice = childAdditionalDetails.attributes.choices ?? ["Yes", "No"];
                if (ans == undefined) {

                } else if (ans == true) {
                  newEnquiryObject.answerChoices = [choice[0]]
                }
                else if (ans == false) {
                  newEnquiryObject.answerChoices = [choice[1]]
                }

              } else {
                if (childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]) {
                  newEnquiryObject.answerChoices = [childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]]
                }
              }
            }
          }

          if (enqQuestion.attributes.questionType == Constants.QESTION_UPLOAD_IMAGE) {

            if (enqQuestion.attributes.databaseTableName == 'Enquiry') {

              let pi = enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]

              if (pi) {
                let fileExtension = pi._url.split(".");

                let tempFile = {
                  actualImageName: pi._name,
                  fileExtension: fileExtension[1].toLowerCase(),
                  imageName: "profile image",
                  image: pi,
                  imageURL: pi._url,
                };
                newEnquiryObject.answerImage = tempFile;
              }

            }
            if (parents && enqQuestion.attributes.databaseTableName == 'Parent') {
              if (fatherObject && enqQuestion.attributes.objectType == 2) {
                let pi = fatherObject.attributes[enqQuestion.attributes.databaseFieldName]

                if (pi) {
                  let fileExtension = pi._url.split(".");

                  let tempFile = {
                    actualImageName: pi._name,
                    fileExtension: fileExtension[1].toLowerCase(),
                    imageName: "profile image",
                    image: pi,
                    imageURL: pi._url,
                  };
                  newEnquiryObject.answerImage = tempFile;
                }
              }

              if (motherObject && enqQuestion.attributes.objectType == 3) {
                let pi = motherObject.attributes[enqQuestion.attributes.databaseFieldName]

                if (pi) {
                  let fileExtension = pi._url.split(".");

                  let tempFile = {
                    actualImageName: pi._name,
                    fileExtension: fileExtension[1].toLowerCase(),
                    imageName: "profile image",
                    image: pi,
                    imageURL: pi._url,
                  };
                  newEnquiryObject.answerImage = tempFile;
                }
              }

            }
            if (childAdditionalDetails && enqQuestion.attributes.databaseTableName == 'ChildAdditionalDetails') {
              let pi = childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]

              if (pi) {
                let fileExtension = pi._url.split(".");

                let tempFile = {
                  actualImageName: pi._name,
                  fileExtension: fileExtension[1].toLowerCase(),
                  imageName: "profile image",
                  image: pi,
                  imageURL: pi._url,
                };
                newEnquiryObject.answerImage = tempFile;
              }
            }
          }

          if (enqQuestion.attributes.questionType == Constants.QESTION_UPLOAD_DOCUMENT) {
            if (enqQuestion.attributes.databaseTableName == 'Enquiry') {
              let docs = enquiryObject.attributes[enqQuestion.attributes.databaseFieldName]

              if (docs) {
                let fileExtension = docs._url.split(".");
                let fName= docs._name;
                let tempFile = {
                  actualFileName:fName.slice(fName.split("_")[0].length+1),
                  fileExtension: fileExtension[1].toLowerCase(),
                  fileName: fileExtension[0],
                  file: docs._url,
                };
                newEnquiryObject.answerFile = tempFile;
                newEnquiryObject.answerFileUploadProgress = 100;
              }
            }
            if (parents && enqQuestion.attributes.databaseTableName == 'Parent') {
              if (fatherObject && enqQuestion.attributes.objectType == 2) {
                let docs = fatherObject.attributes[enqQuestion.attributes.databaseFieldName]

                if (docs) {
                  let fileExtension = docs._url.split(".");
                  let fName= docs._name;
                  let tempFile = {
                    actualFileName: fName.slice(fName.split("_")[0].length+1),
                    fileExtension: fileExtension[1].toLowerCase(),
                    fileName: fileExtension[0],
                    file: docs._url,
                  };
                  newEnquiryObject.answerFile = tempFile;
                  newEnquiryObject.answerFileUploadProgress = 100;
                }
              }

              if (motherObject && enqQuestion.attributes.objectType == 3) {
                let docs = motherObject.attributes[enqQuestion.attributes.databaseFieldName]

                if (docs) {
                  let fileExtension = docs._url.split(".");
                  let fName= docs._name;
                  let tempFile = {
                    actualFileName: fName.slice(fName.split("_")[0].length+1),
                    fileExtension: fileExtension[1].toLowerCase(),
                    fileName: fileExtension[0],
                    file: docs._url,
                  };
                  newEnquiryObject.answerFile = tempFile;
                  newEnquiryObject.answerFileUploadProgress = 100;
                }
              }

            }
            if (childAdditionalDetails && enqQuestion.attributes.databaseTableName == 'ChildAdditionalDetails') {

              let docs = childAdditionalDetails.attributes[enqQuestion.attributes.databaseFieldName]

              if (docs) {
                let fileExtension = docs._url.split(".");
                let fName= docs._name;
                let tempFile = {
                  actualFileName: fName.slice(fName.split("_")[0].length+1),
                  fileExtension: fileExtension[1].toLowerCase(),
                  fileName: fileExtension[0],
                  file: docs._url,
                };
                newEnquiryObject.answerFile = tempFile;
                newEnquiryObject.answerFileUploadProgress = 100;
              }

            }
          }

        }

        tempList.push(newEnquiryObject);
      }

      setEnquiryQuestionsList(tempList);
    } else {
      setIsQuestionsAvailable(false);
    }
  };
  //console.log(selectedEnquiry)

  const optinForWhatsApp = (number) => {
   
    let phoneNumber = number;
    let countryPhoneCode = "91";
    if (phoneNumber.length > 0) {
      if (phoneNumber.indexOf("+") == 0 && phoneNumber.length > 12) {
        phoneNumber = phoneNumber.substring(1);
      } else if (phoneNumber.indexOf("+") == 0) {
        phoneNumber = countryPhoneCode + phoneNumber.substring(1);
      } else if (phoneNumber.indexOf("0") == 0) {
        phoneNumber = countryPhoneCode + phoneNumber.substring(1);
      } else if (phoneNumber.indexOf("+" + countryPhoneCode) == -1) {
        phoneNumber = countryPhoneCode + phoneNumber;
      }
    }

    fetch(
      "https://api.gupshup.io/sm/api/v1/app/opt/in/lilTriangle",
      {
        method: "POST",
        headers: {
          "apikey": "lo0yweanxnn1hmcsnmw75rz1cuk3m2sj",
          "Content-type": "application/x-www-form-urlencoded",
        },
        body: new URLSearchParams({
          'user': phoneNumber,
        })

      }
    ).then((response) => {
      console.log("response", response)
      if (response.status !== 200) {

      }
    }, (error) => {
      console.log("error" + error.message)
    })
  }

  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  //  UI Functions

  const radioButtonForSingleSelection = (
    enqQuestionObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="">{enqQuestionObject.question}</Label>
        {enqQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {enqQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyInside}>
              <input
                className="form-check-input"
                type="radio"
                id={"radioButton" + enqQuestionObject.id + "_" + keyInside}
                value={choice}
                checked={enqQuestionObject.answerChoices.includes(choice)}
                onChange={() => {
                  handlerFunction(choice, enqQuestionObject);
                }}
              />
              <label
                className="form-check-label"
                htmlFor={"radioButton" + enqQuestionObject.id + "_" + keyInside}
              >
                {choice}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const checkboxButtonForMultiSelection = (
    enqQuestionObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="">{enqQuestionObject.question}</Label>
        {enqQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {enqQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div>
          {choices.map((choice, keyInside) => (
            <div
              className="form-check  ml-3"
              key={enqQuestionObject.id + "_" + keyInside}
            >
              <input
                className="form-check-input"
                type="checkbox"
                id={"checkbox" + enqQuestionObject.id + "_" + keyInside}
                value={choice}
                checked={enqQuestionObject.answerChoices?enqQuestionObject.answerChoices.includes(choice):false}
                onChange={() => {
                  handlerFunction(choice, enqQuestionObject);
                }}
              />
              <label
                className="form-check-label"
                htmlFor={"checkbox" + enqQuestionObject.id + "_" + keyInside}
              >
                {choice}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const boolTagsWithoutComment = (
    enqQuestionObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="">{enqQuestionObject.question}</Label>
        {enqQuestionObject.isMandatory && (
          <Label className="text-danger">*</Label>
        )}
        {enqQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div className="tagcloud mt-1">
          {choices.map((choice, keyInside) => (
            <Button
              key={keyInside}
              id={choice}
              color={
                enqQuestionObject.answerChoices.includes(choice)
                  ? "primary"
                  : "secondary"
              }
              className="btn rounded mr-2 mb-2"
              onClick={() => handlerFunction(choice, enqQuestionObject)}
            >
              {choice}
            </Button>
          ))}
        </div>
      </div>
    );
  };

  const boolTagsWithComment = (
    enqQuestionObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="">{enqQuestionObject.question}</Label>
        {enqQuestionObject.isMandatory && (
          <Label className="text-danger">*</Label>
        )}
        {enqQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div className="tagcloud mt-1">
          {choices.map((choice, keyInside) => (
            <Button
              key={keyInside}
              id={choice}
              color={
                enqQuestionObject.answerChoices.includes(choice)
                  ? "primary"
                  : "secondary"
              }
              className="btn rounded mr-2"
              onClick={() => handlerFunction(choice, enqQuestionObject)}
            >
              {choice}
            </Button>
          ))}
        </div>
        <AvField
          type="textarea"
          name="multiLine"
          className="form-control mt-3"
          rows="3"
          placeholder={enqQuestionObject.questionHint}
          onChange={(e) => {
            handleInputOnChange(key, e.target.value);
          }}
        />
      </div>
    );
  };

  const componentForQuestionType = (enqQuestionObject, key) => {
    var questionType = enqQuestionObject.questionType;

    switch (questionType) {
      case Constants.QESTION_UPLOAD_DOCUMENT:
        return (
          <FormGroup key={key}>
            <Label htmlFor={"formFileUpload" + key}>
              {enqQuestionObject.question}{" "}
            </Label>
            {enqQuestionObject.isMandatory && (
              <Label htmlFor={"formFileUpload" + key} className="text-danger">
                *
              </Label>
            )}
            {enqQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}

            {enqQuestionObject.answerFile == undefined && (
              <div className="d-flex">
                <Label
                  htmlFor={"input-file-enquiry" + key}
                  color="primary"
                  className="btn btn-primary"
                >
                  Upload
                  <input
                    id={"input-file-enquiry" + key}
                    style={inputStyle}
                    type="file"
                    onChange={(e) => {
                      handleFileUploadChange(e, key, enqQuestionObject);
                    }}
                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                  />
                </Label>
              </div>
            )}

            {enqQuestionObject.answerFileUploadProgress === 100 && (
              <Col xl="6" md="12" sm="12" xs="12">
                <div className="border p-3">
                  <Row>
                    <div
                      className="avatar ml-3"
                      style={{ width: "5rem", height: "5rem" }}
                    >
                      <span
                        className="avatar-title bg-white ml-0 text-primary h1"
                        style={{ fontSize: "60px" }}
                      >
                        <i
                          className={getFileTypeImage(
                            enqQuestionObject.answerFile.fileExtension
                          )}
                        ></i>
                      </span>
                    </div>

                    <div className="float-right ml-4">
                      <div
                        className="text-muted font-weight-bold"
                        style={{ width: "200px" }}
                      >
                        {enqQuestionObject.answerFile.actualFileName}
                      </div>
                      <div className="d-flex mt-3">
                        <div>
                          <Label
                            htmlFor={"input-file-enquiry-Update" + key}
                            color="primary"
                            className="btn btn-primary mb-0"
                          >
                            Update
                            <input
                              id={"input-file-enquiry-Update" + key}
                              style={inputStyle}
                              type="file"
                              onChange={(e) => {
                                handleFileUploadChange(
                                  e,
                                  key,
                                  enqQuestionObject
                                );
                              }}
                              accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                            />
                          </Label>
                        </div>

                        <Button
                          className="btn-danger float-right ml-2"
                          onClick={() => {
                            let enqObject = enquiryQuestionsList[key];
                            enqObject.answerFile = undefined;
                            enqObject.answerFileUploadProgress = 0;
                            enquiryQuestionsList[key] = enqObject;

                            var temp = [...enquiryQuestionsList];
                            temp[key] = enqObject;
                            setEnquiryQuestionsList(temp);
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            )}

            {enqQuestionObject.answerFile &&
              enqQuestionObject.answerFileUploadProgress < 100 && (
                <div className="d-flex">
                  <Col xl="6" md="12" sm="12" xs="12" className="ml-0">
                    <div className="border p-3">
                      <Row>
                        <div
                          className="avatar ml-3"
                          style={{ width: "5rem", height: "5rem" }}
                        >
                          <span className="avatar-title bg-white ml-0 text-primary h1">
                            <i
                              className={getFileTypeImage(
                                enqQuestionObject.answerFile.fileExtension
                              )}
                            ></i>
                          </span>
                        </div>
                        <div
                          className="float-right ml-4"
                          style={{ width: "200px" }}
                        >
                          <div className="text-muted font-weight-bold">
                            {enqQuestionObject.answerFile.actualFileName}
                          </div>
                          <div className=" mt-3">
                            <Progress
                              color="primary"
                              value={enqQuestionObject.answerFileUploadProgress}
                            ></Progress>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                </div>
              )}
          </FormGroup>
        );
        break;

      case Constants.QESTION_UPLOAD_IMAGE:
        return (
          <FormGroup key={key}>
            <Label htmlFor={"formImageUpload" + key}>
              {enqQuestionObject.question}{" "}
            </Label>
            {enqQuestionObject.isMandatory && (
              <Label htmlFor={"formImageUpload" + key} className="text-danger">
                *
              </Label>
            )}
            {enqQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            {enqQuestionObject.answerImage &&
              enqQuestionObject.answerImage.imageURL && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div className="ml-3">
                        <img
                          style={{ width: "5rem", height: "5rem" }}
                          className="rounded-circle avatar"
                          src={enqQuestionObject.answerImage.imageURL}
                          alt=""
                        />
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {/* {enqQuestionObject.answerImage.actualImageName} */}
                        </div>
                        <div className="d-flex mt-3">
                          <UpdatePhoto
                            id={"imageUpload" + key}
                            tempId={"imageUpload" + key}
                            className="p-1"
                            buttonTitle="Change"
                            isPopupOn={false}
                            onPicChange={(imageFile, url) => {
                              handleImageUploadChange(
                                enqQuestionObject,
                                key,
                                url,
                                imageFile
                              );
                            }}
                            initialImage={
                              enqQuestionObject.answerImage.imageURL
                            }
                          ></UpdatePhoto>
                          <Button
                            className="btn-danger float-right ml-2"
                            onClick={() => {
                              let enqObject = enquiryQuestionsList[key];
                              enqObject.answerImage = undefined;
                              enqObject.answerImageUploadProgress = 0;
                              enquiryQuestionsList[key] = enqObject;

                              var temp = [...enquiryQuestionsList];
                              temp[key] = enqObject;
                              setEnquiryQuestionsList(temp);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {enqQuestionObject.answerImage &&
              enqQuestionObject.answerImage.imageURL == undefined && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div
                        className="avatar ml-3"
                        style={{ width: "5rem", height: "5rem" }}
                      >
                        <span
                          className={
                            "avatar-title rounded-circle bg-soft-primary text-primary"
                          }
                          style={{ fontSize: "60px" }}
                        ></span>
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {enqQuestionObject.answerImage.actualImageName}
                        </div>
                        <div className=" mt-3">
                          <Progress
                            color="primary"
                            value={enqQuestionObject.answerImageUploadProgress}
                          ></Progress>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {enqQuestionObject.answerImage == undefined && (
              <div className="d-flex">
                <div>
                  {" "}
                  <UpdatePhoto
                    id={"imageUploadMain" + key}
                    tempId={"imageUploadMain" + key}
                    className="p-1"
                    isPopupOn={false}
                    onPicChange={(imageFile, url) => {
                      handleImageUploadChange(
                        enqQuestionObject,
                        key,
                        url,
                        imageFile
                      );
                    }}
                    initialImage={""}
                  ></UpdatePhoto>
                </div>
              </div>
            )}
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_SINGLE_LINE:
        if (enqQuestionObject.isMandatory) {
          return (
            <FormGroup key={key}>
              <Label htmlFor="formSingleLine">
                {enqQuestionObject.question}{" "}
              </Label>
              {enqQuestionObject.isMandatory && (
                <Label htmlFor="formSingleLine" className="text-danger">
                  *
                </Label>
              )}
              <AvField
                type="text"
                name={"singleLine" + key}
                placeholder={enqQuestionObject.questionHint}
                className="form-control"
                value={enqQuestionObject.answerText}
                onChange={(e) => {
                  handleInputOnChange(key, e.target.value);
                }}
                validate={{
                  required: {
                    // value: !enqQuestionObject.answerText || enqQuestionObject.answerText.length === 0 ? true : false,
                    errorMessage: props.t(
                      "Enter " + enqQuestionObject.question + "..."
                    ),
                  },
                }}
              />
            </FormGroup>
          );
        }
        return (
          <FormGroup key={key}>
            <Label htmlFor="formSingleLine">
              {enqQuestionObject.question}{" "}
            </Label>
            {enqQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            <AvField
              type="text"
              name={"singleLine" + key}
              placeholder={enqQuestionObject.questionHint}
              className="form-control"
              value={enqQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(key, e.target.value);
              }}
            />
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_MULTI_LINE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formMultiLine">{enqQuestionObject.question}</Label>
            {enqQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            <AvField
              type="textarea"
              name={"multiLine" + key}
              className="form-control"
              rows="3"
              value={enqQuestionObject.answerText}
              placeholder={enqQuestionObject.questionHint}
              onChange={(e) => {
                handleInputOnChange(key, e.target.value);
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QESTION_NUMBER:
        return (
          <FormGroup key={key}>
            <Label> enqQuestionObject.question</Label>
            {enqQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            <AvField
              type="number"
              name={"numberInput" + key}
              className="inner form-control noscroll"
              placeholder={props.t(enqQuestionObject.questionHint)}
              onChange={(e) => {
                handleInputOnChange(key, e.target.value);
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QESTION_YES_NO_NO_COMMENTS:
        return boolTagsWithoutComment(
          enqQuestionObject,
          enqQuestionObject.choices,
          handleYesNoButtonClick,
          key
        );

        break;
      case Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST:
        return radioButtonForSingleSelection(
          enqQuestionObject,
          enqQuestionObject.choices,
          handleChoiceButtonClick,
          key
        );
        break;
      case Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST:
        return checkboxButtonForMultiSelection(
          enqQuestionObject,
          enqQuestionObject.choices,
          handleChoiceButtonClick,
          key
        );

        break;
      case Constants.QESTION_DATE:
        
        return (
          <FormGroup key={key}>
            <Label> {enqQuestionObject.question}</Label>
            {enqQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}

            
              <AvField
                type="date"
                name={"questionDate" + key}
                className="inner form-control"
                placeholder="dd/mm/yyyy"
                disabled={enqQuestionObject.isDisabled}
                onChange={(e) => {
                  handleDateChange(key, e.target.value);
                }}
                value={enqQuestionObject.answerDate && Moment(enqQuestionObject.answerDate).format("YYYY-MM-DD")}

              />
            

           
          </FormGroup>
        );
        break;
      case Constants.QESTION_RANGE_TIME:
        break;
      case Constants.QESTION_GEO_POINT:
        break;
      case Constants.QESTION_YES_NO_COMMENT_ON_YES:
        if (!enqQuestionObject.answerBool === true) {
          return boolTagsWithoutComment(
            enqQuestionObject,
            enqQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        } else {
          return boolTagsWithComment(
            enqQuestionObject,
            enqQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        }

        break;
      case Constants.QESTION_YES_NO_COMMENT_ON_NO:
        if (enqQuestionObject.answerBool === false) {
          return boolTagsWithComment(
            enqQuestionObject,
            enqQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        } else {
          return boolTagsWithoutComment(
            enqQuestionObject,
            enqQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        }
        break;
      case Constants.QESTION_YES_NO_WITH_COMMENT:
        boolTagsWithComment(
          enqQuestionObject,
          enqQuestionObject.choices,
          handleYesNoButtonClick,
          key
        );
        break;
      case Constants.QUESTION_EMAIL:
        if (enqQuestionObject.isMandatory) {
          return (
            <FormGroup key={key}>
              <Label htmlFor="formemail">
                {" "}
                {props.t(enqQuestionObject.question)}
              </Label>
              <Label className="text-danger">*</Label>
              <AvField
                type="email"
                name={"email" + key}
                placeholder={props.t("Enter Email")}
                className="form-control"
                value={enqQuestionObject.answerText}
                onChange={(e) => {
                  handleInputOnChange(key, e.target.value);
                }}
                validate={{
                  required: {
                    //value: !phone || phone.length === 0 ? true:false,
                    errorMessage: props.t("Enter Email..."),
                  },
                  pattern: {
                    value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                    errorMessage: props.t(
                      "You have entered an invalid email address!"
                    ),
                  },
                }}
              />
            </FormGroup>
          );
        }
        return (
          <FormGroup key={key}>
            <Label htmlFor="formemail">
              {" "}
              {props.t(enqQuestionObject.question)}
            </Label>
            <AvField
              type="email"
              name={"email" + key}
              placeholder={props.t("Enter Email")}
              className="form-control"
              value={enqQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(key, e.target.value);
              }}
              validate={{
                pattern: {
                  value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                  errorMessage: props.t(
                    "You have entered an invalid email address!"
                  ),
                },
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QUESTION_PHONE_NUMBER:
        if (enqQuestionObject.isMandatory) {
          return (
            <FormGroup key={key}>
              <Label> {props.t(enqQuestionObject.question)}</Label>
              <Label className="text-danger">*</Label>
              <AvField
                name={"phone" + key}
                className="inner form-control noscroll"
                placeholder={props.t("Enter phone no")}
                value={enqQuestionObject.answerText}
                onChange={(e) => {
                  let number = e.target.value.replace(/\D/g,'')
                  handleInputOnChange(key, number);
                }}
                type="number"
                onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 || charCode > 57) && charCode !== 43) {
                    e.preventDefault();
                  }
                }} 
                validate={{
                  required: { value: true, errorMessage: props.t('Phone number is required') },
                  pattern: {
                    value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                    errorMessage: props.t(
                      "You have entered an invalid phone no!"
                    ),
                  },
                  minLength: { value: 10,errorMessage: 'Phone number must be at least 10 digits'  },
                  maxLength: { value: (enqQuestionObject.answerText && enqQuestionObject.answerText.startsWith('+') ? 13 : 10) },
                }}
              />
            </FormGroup>
          );
        }
        return (
          <FormGroup key={key}>
            <Label> {props.t(enqQuestionObject.question)}</Label>
            <AvField
              name={"phone" + key}
              className="inner form-control noscroll"
              placeholder={props.t("Enter phone no")}
              value={enqQuestionObject.answerText}
              onChange={(e) => {
                let number = e.target.value.replace(/\D/g,'')
                handleInputOnChange(key, number);
              }}
              type="tel"
              onKeyPress={(e) => {
                const charCode = e.which ? e.which : e.keyCode;
                if ((charCode < 48 || charCode > 57) && charCode !== 43) {
                  e.preventDefault();
                }
              }}
              
              validate={{
                pattern: {
                  value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                  errorMessage: props.t(
                    "You have entered an invalid phone no!"
                  ),
                },
                minLength: { value: 10,errorMessage: 'Phone number must be at least 10 digits'  },
                maxLength: { value: (enqQuestionObject.answerText && enqQuestionObject.answerText.startsWith('+') ? 13 : 10) },
              }}
            />
          </FormGroup>
        );

        break;
    }
  };

  //Validation Before Save
  const isFormValid = () => {
    var resultForValidation = {
      canProceedToSave: false,
      isParentDetailsValid: false,
      scroolTo: null,
    };

    resultForValidation = checkForDifferentQuestionsValidations();

    if (resultForValidation.isParentDetailsValid) {
      if (resultForValidation.canProceedToSave) {
        if(EnquiryRefillmatch){
          checkAndSave();
        }else{
          saveForm();
        }
        
      } else if (resultForValidation.scroolTo) {
        scroller.scrollTo(resultForValidation.scroolTo, {
          duration: 500,
          delay: 100,
          smooth: true,
          offset: -100,
        });
      }
    } else {
    }
  };

  const setAnswerErrorTrue = (i) => {
    var enqObject = enquiryQuestionsList[i];

    enqObject.answerError = true;

    var temp = [...enquiryQuestionsList];

    temp[i] = enqObject;

    setEnquiryQuestionsList(temp);
  };

  const checkForDifferentQuestionsValidations = () => {
    var canProceedToNextScreen = true;
    var isParentDetailsValid = false;
    var scroolTo = null;
    var i = 0;
    if (oneParentError) {
      setOneParentError(false);
    }

    for (i = 0; i < enquiryQuestionsList.length; i++) {
      isParentDetailsValid = true;

      if (enquiryQuestionsList[i].isMandatory === true) {
        if (
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_TEXT_SINGLE_LINE ||
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_TEXT_MULTI_LINE ||
          enquiryQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
          enquiryQuestionsList[i].questionType ===
          Constants.QUESTION_PHONE_NUMBER
        ) {
          if (
            enquiryQuestionsList[i].answerText == null ||
            enquiryQuestionsList[i].answerText === ""
          ) {
            canProceedToNextScreen = false;
          }
        } else if (
          enquiryQuestionsList[i].questionType === Constants.QESTION_NUMBER
        ) {
          if (enquiryQuestionsList[i].answerNumber == null) {
            canProceedToNextScreen = false;
          }
        } else if (
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_NO_COMMENTS
        ) {
          if (enquiryQuestionsList[i].answerBool == null) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(i);
          }
        } else if (
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
        ) {
          if (
            enquiryQuestionsList[i].answerChoices == null ||
            enquiryQuestionsList[i].answerChoices.length === 0
          ) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(i);
          }
        } else if (
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
        ) {
          if (
            enquiryQuestionsList[i].answerChoices == null ||
            enquiryQuestionsList[i].answerChoices.length === 0
          ) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(i);
          }
        } else if (
          enquiryQuestionsList[i].questionType === Constants.QESTION_DATE
        ) {
          if (enquiryQuestionsList[i].answerDate == null) {
            canProceedToNextScreen = false;
          }
        } else if (
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_COMMENT_ON_YES ||
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_COMMENT_ON_NO
        ) {
          if (
            enquiryQuestionsList[i].answerText == null ||
            enquiryQuestionsList[i].answerText === ""
          ) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(i);
          }
        } else if (
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_WITH_COMMENT
        ) {
        } else if (
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_UPLOAD_IMAGE
        ) {
          if (
            enquiryQuestionsList[i].answerImage == null ||
            enquiryQuestionsList[i].answerImageUploadProgress === 0
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(i);
          }
        } else if (
          enquiryQuestionsList[i].questionType ===
          Constants.QESTION_UPLOAD_DOCUMENT
        ) {
          if (
            enquiryQuestionsList[i].answerFile == null ||
            enquiryQuestionsList[i].answerFileUploadProgress === 0
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(i);
          }
        }

        if (!canProceedToNextScreen && !scroolTo) {
          scroolTo = enquiryQuestionsList[i].id;
        }
      }
    }

    let oneparent = oneParent();
    if (canProceedToNextScreen && !oneparent.shouldSave) {
      canProceedToNextScreen = false;
      setOneParentError(true);
      toastr.warning(props.t('One parent name and contact details are mandatory'), { timeOut: 3000 });
    }
    return {
      canProceedToSave: canProceedToNextScreen,
      isParentDetailsValid: isParentDetailsValid,
      scroolTo: scroolTo,
    };
  };


  const oneParent = () => {
    let faterQuestions = [];
    let motherQuestions = [];

    let scroolTo = null;

    for (const i in enquiryQuestionsList) {
      let tableName = enquiryQuestionsList[i].enquiryQuestionParseObject.attributes.databaseTableName;
      let fieldName = enquiryQuestionsList[i].enquiryQuestionParseObject.attributes.databaseFieldName;
      let objectType = enquiryQuestionsList[i].enquiryQuestionParseObject.attributes.objectType;
      if (tableName === "Parent" && ["EMail", "PhoneNumber", "Name"].includes(fieldName)) {

        if (scroolTo == null) {
          scroolTo = enquiryQuestionsList[i].id;
        }
        if (objectType === 3) {
          motherQuestions.push(enquiryQuestionsList[i])
        } else {
          faterQuestions.push(enquiryQuestionsList[i])
        }
      }
    }

    if (faterQuestions.length > 1 || motherQuestions.length > 1) {


      let father = false;
      let mother = false;
      if (faterQuestions.length > 0) {
        let fn = false;
        let fc = false;
        for (const i in faterQuestions) {
          if (faterQuestions[i].enquiryQuestionParseObject.attributes.databaseFieldName === "Name") {
            if (faterQuestions[i].answerText && faterQuestions[i].answerText.length > 0) {
              fn = true;
            }
          }
          if (faterQuestions[i].enquiryQuestionParseObject.attributes.databaseFieldName === "EMail") {
            if (faterQuestions[i].answerText && faterQuestions[i].answerText.length > 0) {
              fc = true;
            }
          }
          if (faterQuestions[i].enquiryQuestionParseObject.attributes.databaseFieldName === "PhoneNumber") {
            if (faterQuestions[i].answerText && faterQuestions[i].answerText.length > 0) {
              fc = true;
            }
          }
        }

        father = fn && fc;

      }

      if (motherQuestions.length > 0 && !father) {
        let mn = false;
        let mc = false;
        for (const i in motherQuestions) {
          if (motherQuestions[i].enquiryQuestionParseObject.attributes.databaseFieldName === "Name") {
            if (motherQuestions[i].answerText && motherQuestions[i].answerText.length > 0) {
              mn = true;
            }
          }
          if (motherQuestions[i].enquiryQuestionParseObject.attributes.databaseFieldName === "EMail") {
            if (motherQuestions[i].answerText && motherQuestions[i].answerText.length > 0) {
              mc = true;
            }
          }
          if (motherQuestions[i].enquiryQuestionParseObject.attributes.databaseFieldName === "PhoneNumber") {
            if (motherQuestions[i].answerText && motherQuestions[i].answerText.length > 0) {
              mc = true;
            }
          }
        }
        mother = mn && mc;
      }

      if (father || mother) {
        return { scroolTo: "", shouldSave: true };
      } else {
        return { scroolTo: scroolTo, shouldSave: false };
      }

    } else {
      return { scroolTo: "", shouldSave: true };
    }

  }

  //Saving Form

  const saveForm = () => {
    //Creating Objects
    setFormSubmitState("SUBMIT_REQUST");
    const ParseEnquiryObject = Parse.Object.extend("Enquiry");
    var enquiryObjectToSave = new ParseEnquiryObject();

    const ParentObject = Parse.Object.extend("Parent");
    var motherObjectToSave = new ParentObject();

    var fatherObjectToSave = new ParentObject();

    var shouldSaveEnquiryObject = false;
    var shouldSaveFatherObject = false;
    var shouldSaveMotherObject = false;
    var shouldSaveChildAdditionalDetailsObject = false;

    const ChildAdditionalDetailObject = Parse.Object.extend(
      "ChildAdditionalDetails"
    );
    var childAdditionalDetailObjectToSave = new ChildAdditionalDetailObject();

    /////////

    enquiryObjectToSave = fillingObjectsWithAnswers(
      enquiryObjectToSave,
      "Enquiry"
    );
    if (enquiryObjectToSave === false) {
      shouldSaveEnquiryObject = false;
    } else {
      shouldSaveEnquiryObject = true;
    }

    fatherObjectToSave = fillingObjectsWithAnswers(
      fatherObjectToSave,
      "Parent",
      2
    );
    if (fatherObjectToSave === false) {
      shouldSaveFatherObject = false;
    } else {
      shouldSaveFatherObject = true;
    }

    motherObjectToSave = fillingObjectsWithAnswers(
      motherObjectToSave,
      "Parent",
      3
    );
    if (motherObjectToSave === false) {
      shouldSaveMotherObject = false;
    } else {
      shouldSaveMotherObject = true;
    }
    childAdditionalDetailObjectToSave = fillingObjectsWithAnswers(
      childAdditionalDetailObjectToSave,
      "ChildAdditionalDetails"
    );
    if (childAdditionalDetailObjectToSave === false) {
      shouldSaveChildAdditionalDetailsObject = false;
    } else {
      shouldSaveChildAdditionalDetailsObject = true;
    }
    var familyId = uuidv4();
    var groupACL = new Parse.ACL();
    groupACL.setRoleWriteAccess(parameter + "_Admin", true);
    groupACL.setRoleReadAccess(parameter + "_Admin", true);
    groupACL.setRoleWriteAccess(parameter + "_Teacher", false);
    groupACL.setRoleReadAccess(parameter + "_Teacher", true);
    groupACL.setRoleWriteAccess(familyId + "_Role", true);
    groupACL.setRoleReadAccess(familyId + "_Role", true);

    let parentList = [];
    if (fatherObjectToSave === false) {
      let ParentObject = Parse.Object.extend("Parent");
      fatherObjectToSave = new ParentObject();
    }
    fatherObjectToSave.set("Relation", "Father");
    fatherObjectToSave.set("FamilyID", familyId);
    fatherObjectToSave.set("NotificationChoice", 16127);
    fatherObjectToSave.setACL(groupACL);
    parentList.push(fatherObjectToSave);

    if (motherObjectToSave === false) {
      let ParentObject = Parse.Object.extend("Parent");
      motherObjectToSave = new ParentObject();
    }
    motherObjectToSave.set("Relation", "Mother");
    motherObjectToSave.set("FamilyID", familyId);
    motherObjectToSave.set("NotificationChoice", 16127);
    motherObjectToSave.setACL(groupACL);
    parentList.push(motherObjectToSave)

    if (shouldSaveEnquiryObject) {
      console.log(enquiryObjectToSave);
      enquiryObjectToSave.set("schoolId", parameter);
      enquiryObjectToSave.set("familyId", familyId);

      if (shouldSaveFatherObject) {
        enquiryObjectToSave.set(
          "fatherName",
          fatherObjectToSave.attributes.Name
        );
        enquiryObjectToSave.set(
          "fatherMobile",
          fatherObjectToSave.attributes.PhoneNumber
        );
        enquiryObjectToSave.set(
          "fatherEmail",
          fatherObjectToSave.attributes.EMail
        );
      }

      if (shouldSaveMotherObject) {
        enquiryObjectToSave.set(
          "motherName",
          motherObjectToSave.attributes.Name
        );
        enquiryObjectToSave.set(
          "motherMobile",
          motherObjectToSave.attributes.PhoneNumber
        );
        enquiryObjectToSave.set(
          "motherEmail",
          motherObjectToSave.attributes.EMail
        );
      }
      enquiryObjectToSave.setACL(groupACL);
      if (abortSubmitForm) {
        return;
      }
      if (match) {
        enquiryObjectToSave.set("createdBy", "QRCode");
      } else if (franchiseForm) {
        enquiryObjectToSave.set("createdBy", "QRCode");
        enquiryObjectToSave.set("franchiseId", franchiseId);
      } else if (matchEnquiryIOS) {
        enquiryObjectToSave.set("createdBy", teacherId);
      }
      else if (instituteForm) {
        enquiryObjectToSave.set("createdBy", "QRCode");
        enquiryObjectToSave.set("instituteId", instituteId);
      } else if (matchInsideDashboard && Parse.User && Parse.User.current()) {
        if (Parse.User.current().attributes.teacherId) {
          enquiryObjectToSave.set("createdBy",
            Parse.User.current().attributes.teacherId
          );
        } else {
          enquiryObjectToSave.set("createdBy", Parse.User.current().id);
        }
      }

      if (!enquiryObjectToSave.attributes.enquiryDate) {
        enquiryObjectToSave.set("enquiryDate", new Date());
      }
      Parse.Object.saveAll(parentList).then((results) => {
        enquiryObjectToSave.save().then(
          (result) => {
            setFormSubmitState("SUBMIT_SUCCESS");
            setIsFormSubmitted(true);
            console.log("saving success");
            for (const parent of results) {
              if (parent.attributes.PhoneNumber) {
                optinForWhatsApp(parent.attributes.PhoneNumber)
              }
            }
            result.pin();
            if (shouldSaveChildAdditionalDetailsObject) {
              console.log(childAdditionalDetailObjectToSave);
              var publicACL = new Parse.ACL();
              publicACL.setPublicReadAccess(true);
              publicACL.setPublicWriteAccess(true);
              childAdditionalDetailObjectToSave.setACL(publicACL);
              childAdditionalDetailObjectToSave.set("enquiryId", result.id);
              childAdditionalDetailObjectToSave.save().then(
                (result) => {
                  console.log("saving success");
                },
                (error) => {
                  console.log("saving error");
                }
              );
            }
          },
          (error) => {
            console.log("saving error");
            setFormSubmitState("SUBMIT_FAILED_ENQUIRY");
          }
        );
      },(error) => {
          console.log("saving error");
          setFormSubmitState("SUBMIT_FAILED_PARENT");
        });
    }
    //setIsFormSubmitted(true);
  };



  const reSaveForm = () => {
    //Updating  Objects
    setFormSubmitState("SUBMIT_REQUST");

    const ParseEnquiryObject = Parse.Object.extend("Enquiry");
    var enquiryObjectToSave = new ParseEnquiryObject();

    const ParentObject = Parse.Object.extend("Parent");
    var motherObjectToSave = new ParentObject();
    var fatherObjectToSave = new ParentObject();

    const ChildAdditionalDetailObject = Parse.Object.extend( "ChildAdditionalDetails");
    var childAdditionalDetailObjectToSave = new ChildAdditionalDetailObject();

    var shouldSaveEnquiryObject = false;
    var shouldSaveFatherObject = false;
    var shouldSaveMotherObject = false;
    var shouldSaveChildAdditionalDetailsObject = false;

    enquiryObjectToSave = fillingObjectsWithAnswers( enquiryObjectToSave,"Enquiry");
    if (enquiryObjectToSave === false) {
      shouldSaveEnquiryObject = false;
    } else {
      shouldSaveEnquiryObject = true;
    }

    fatherObjectToSave = fillingObjectsWithAnswers( fatherObjectToSave,"Parent",2);
    if (fatherObjectToSave === false) {
      shouldSaveFatherObject = false;
    } else {
      shouldSaveFatherObject = true;
    }

    motherObjectToSave = fillingObjectsWithAnswers( motherObjectToSave,"Parent",3);
    if (motherObjectToSave === false) {
      shouldSaveMotherObject = false;
    } else {
      shouldSaveMotherObject = true;
    }

    childAdditionalDetailObjectToSave = fillingObjectsWithAnswers(childAdditionalDetailObjectToSave,"ChildAdditionalDetails");
    if (childAdditionalDetailObjectToSave === false) {
      shouldSaveChildAdditionalDetailsObject = false;
    } else {
      shouldSaveChildAdditionalDetailsObject = true;
    }

    var familyId = selectedEnquiry.Enquiry.attributes.familyId?? uuidv4();
    var groupACL = new Parse.ACL();
    groupACL.setRoleWriteAccess(parameter + "_Admin", true);
    groupACL.setRoleReadAccess(parameter + "_Admin", true);
    groupACL.setRoleWriteAccess(parameter + "_Teacher", false);
    groupACL.setRoleReadAccess(parameter + "_Teacher", true);
    groupACL.setRoleWriteAccess(familyId + "_Role", true);
    groupACL.setRoleReadAccess(familyId + "_Role", true);

    let parentList = [];
    if (fatherObjectToSave === false) {
      let ParentObject = Parse.Object.extend("Parent");
      fatherObjectToSave = new ParentObject();
    }
    fatherObjectToSave.set("Relation", "Father");
    fatherObjectToSave.set("FamilyID", familyId);
    fatherObjectToSave.set("NotificationChoice", 16127);
    // fatherObjectToSave.setACL(groupACL);
    parentList.push(fatherObjectToSave);

    if (motherObjectToSave === false) {
      let ParentObject = Parse.Object.extend("Parent");
      motherObjectToSave = new ParentObject();
    }
    motherObjectToSave.set("Relation", "Mother");
    motherObjectToSave.set("FamilyID", familyId);
    motherObjectToSave.set("NotificationChoice", 16127);
    // motherObjectToSave.setACL(groupACL);
    parentList.push(motherObjectToSave)

    if (shouldSaveEnquiryObject) {
      console.log(enquiryObjectToSave);
      enquiryObjectToSave.set("schoolId", parameter);
      enquiryObjectToSave.set("familyId", familyId);

      if (shouldSaveFatherObject) {
        enquiryObjectToSave.set("fatherName",fatherObjectToSave.attributes.Name);
        enquiryObjectToSave.set("fatherMobile",fatherObjectToSave.attributes.PhoneNumber);
        enquiryObjectToSave.set("fatherEmail",fatherObjectToSave.attributes.EMail);
      }

      if (shouldSaveMotherObject) {
        enquiryObjectToSave.set("motherName",motherObjectToSave.attributes.Name);
        enquiryObjectToSave.set("motherMobile",motherObjectToSave.attributes.PhoneNumber);
        enquiryObjectToSave.set("motherEmail",motherObjectToSave.attributes.EMail);
      }
     
      if (abortSubmitForm) {
        return;
      }
      enquiryObjectToSave.set("enquiryFormResubmittedOn", new Date());
      //save process
      // console.log("selectedEnquiry",selectedEnquiry)
      let oldEnquiryObject = selectedEnquiry.Enquiry;
      let oldChildAdditionalDetails = selectedEnquiry.ChildAdditionalDetails;
      let parents = selectedEnquiry.Parents;
      let oldFatherObject = undefined;
      let oldMotherObject = undefined;

      for (const parent of parents) {
        if (parent.attributes.Relation == "Mother") {
          oldMotherObject = parent;
        } else {
          oldFatherObject = parent;
        }
      }
      Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", {
        tableName: "Enquiry",
        id: exEnquiryId,
        obj: enquiryObjectToSave.attributes,
        
      }).then(
        (eResult) => {
          // console.log(eResult);
          Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", {
            tableName: "Parent",
            id: oldFatherObject?oldFatherObject.id:undefined,
            obj: fatherObjectToSave.attributes,
          }).then(
            (fResult) => {
              // console.log("fResult", fResult);
              console.log(eResult);
              Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", {
                tableName: "Parent",
                id: oldMotherObject?oldMotherObject.id:undefined,
                obj: motherObjectToSave.attributes,
              }).then(
                (mResult) => {
                  // console.log("mResult", mResult);
                  if (shouldSaveChildAdditionalDetailsObject) {
                    childAdditionalDetailObjectToSave.set("enquiryId", exEnquiryId);
                    Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", {
                      tableName: "ChildAdditionalDetails",
                      id: oldChildAdditionalDetails?oldChildAdditionalDetails.id:undefined,
                      obj: childAdditionalDetailObjectToSave.attributes,
                    }).then(
                      (cadResult) => {
                        // console.log("cadResult", cadResult);
                        setFormSubmitState("SUBMIT_SUCCESS");
                        setIsFormSubmitted(true);
                      },
                      (error) => {
                        console.log("ChildAdditionalDetails saving error:",error);
                      }
                    );
                  } else {
                    setFormSubmitState("SUBMIT_SUCCESS");
                    setIsFormSubmitted(true);
                  }
                },
                (error) => {
                  console.log("Mother saving error:",error);
                }
              );
            },
            (error) => {
              console.log("Father saving error:",error);
            }
          );
        },
        (error) => {
          console.log("Enquiry saving error:",error);
        }
      );
    }
  };
  
  //Saving related Functions
  const comparingForFillingObjectsWithAnswers = (
    i,
    objectToReturn,
    shouldSaveObject
  ) => {
    if (
      enquiryQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_SINGLE_LINE ||
      enquiryQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_MULTI_LINE ||
      enquiryQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
      enquiryQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER
    ) {
      if (enquiryQuestionsList[i].answerText != null) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerText
        );
        shouldSaveObject = true;
      }
    } else if (
      enquiryQuestionsList[i].questionType === Constants.QESTION_NUMBER
    ) {
      if (enquiryQuestionsList[i].answerNumber != null) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerNumber
        );
        shouldSaveObject = true;
      }
    } else if (
      enquiryQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      if (enquiryQuestionsList[i].answerBool != null) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerBool
        );
        shouldSaveObject = true;
      }
    } else if (
      enquiryQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      if (
        enquiryQuestionsList[i].answerChoices != null &&
        enquiryQuestionsList[i].answerChoices.length > 0
      ) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerChoices[0]
        );
        shouldSaveObject = true;
      }
    } else if (
      enquiryQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      if (
        enquiryQuestionsList[i].answerChoices != null &&
        enquiryQuestionsList[i].answerChoices.length > 0
      ) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerChoices
        );
        shouldSaveObject = true;
      }
    } else if (
      enquiryQuestionsList[i].questionType === Constants.QESTION_DATE
    ) {
      if (enquiryQuestionsList[i].answerDate != null) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerDate
        );
        shouldSaveObject = true;
      }
    } else if (
      enquiryQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_YES ||
      enquiryQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      if (enquiryQuestionsList[i].answerText != null) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerText
        );
        shouldSaveObject = true;
      }
    } else if (
      enquiryQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (
      enquiryQuestionsList[i].questionType === Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      if (
        enquiryQuestionsList[i].answerFile &&
        enquiryQuestionsList[i].answerFileUploadProgress === 100
      ) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerFile.file
        );
        shouldSaveObject = true;
      } else if (
        enquiryQuestionsList[i].answerFileUploadProgress >= 1 ||
        enquiryQuestionsList[i].answerFileUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for file upload to complete, then try again",
          { timeOut: 1000 }
        );
      }
    } else if (
      enquiryQuestionsList[i].questionType === Constants.QESTION_UPLOAD_IMAGE
    ) {
      if (
        enquiryQuestionsList[i].answerImage &&
        enquiryQuestionsList[i].answerImage.image != null
      ) {
        objectToReturn.set(
          enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
            .databaseFieldName,
          enquiryQuestionsList[i].answerImage.image
        );
        shouldSaveObject = true;
      } else if (
        enquiryQuestionsList[i].answerImageUploadProgress >= 1 ||
        enquiryQuestionsList[i].answerImageUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for Image upload to complete, then try again",
          { timeOut: 1000 }
        );
      }
    }

    return {
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };

  const fillingObjectsWithAnswers = (
    objectToSave,
    tableName,
    objectType = 0
  ) => {
    var objectToReturn = objectToSave;
    var shouldSaveObject = false;
    let result = undefined;
    var i = 0;
    for (i = 0; i < enquiryQuestionsList.length; i++) {
      if (
        enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
          .databaseTableName === tableName
      ) {
        if (tableName === "Parent") {
          if (
            enquiryQuestionsList[i].enquiryQuestionParseObject.attributes
              .objectType === objectType
          ) {
            result = comparingForFillingObjectsWithAnswers(
              i,
              objectToReturn,
              shouldSaveObject
            );
          }
        } else {
          result = comparingForFillingObjectsWithAnswers(
            i,
            objectToReturn,
            shouldSaveObject
          );
        }
      }
      if (result) {
        shouldSaveObject = result.shouldSaveObject;
        objectToReturn = result.objectToReturn;
      }
    }

    if (shouldSaveObject) {
      return objectToReturn;
    }
    return shouldSaveObject;
  };

  //on Doc Upload
  function handleFileUploadChange(e, index, enqQuestionObject) {
    var enqObject = enquiryQuestionsList[index];

    enqObject.answerFile = undefined;
    enqObject.answerFileUploadProgress = 1;
    enquiryQuestionsList[index] = enqObject;

    var temp = [...enquiryQuestionsList];
    temp[index] = enqObject;
    setEnquiryQuestionsList(temp);

    let fileExtension = e.target.files[0].name.split(".");
    let tempFile = {
      actualFileName: e.target.files[0].name,
      fileExtension: fileExtension[1].toLowerCase(),
      fileName: fileExtension[0],
      file: e.target.files[0],
    };

    var name =
      tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')

    getBase64(tempFile.file, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result });
      //setUploadProgress(1);
      parseFile
        .save({
          progress: (value) => {
            if (value && value > 0.1) {
              //setUploadProgress(value * 100);
              //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

              if (value == 1) {
                enqObject.answerFileUploadProgress = 99;
              } else {
                enqObject.answerFileUploadProgress = value * 100;
              }

              enquiryQuestionsList[index] = enqObject;

              var temp = [...enquiryQuestionsList];
              temp[index] = enqObject;
              setEnquiryQuestionsList(temp);
            }
          },
        })
        .then(
          (result) => {
            tempFile.file = result;
            enqObject.answerFile = tempFile;
            enqObject.answerError = false;
            enqObject.answerFileUploadProgress = 100;
            enquiryQuestionsList[index] = enqObject;

            var temp = [...enquiryQuestionsList];
            temp[index] = enqObject;
            setEnquiryQuestionsList(temp);
          },
          (error) => {
            //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
          }
        );
    });

    enqObject.answerFile = { actualFileName: tempFile.actualFileName };
    enquiryQuestionsList[index] = enqObject;

    var temp = [...enquiryQuestionsList];
    temp[index] = enqObject;
    setEnquiryQuestionsList(temp);
  }

  // On image upload
  function handleImageUploadChange(enq, index, changedImageURL, imageFile) {
    let enqObject = enq;
    enqObject.answerImage = undefined;
    enqObject.answerImageUploadProgress = 1;
    enquiryQuestionsList[index] = enqObject;

    var temp = [...enquiryQuestionsList];
    temp[index] = enqObject;
    setEnquiryQuestionsList(temp);

    let fileExtension = imageFile._name.split(".");
    let tempFile = {
      actualImageName: imageFile._name,
      fileExtension: fileExtension[1].toLowerCase(),
      imageName: fileExtension[0],
      image: imageFile,
      imageURL: undefined,
    };

    //var name = tempFile.imageName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

    tempFile.image
      .save({
        progress: (value) => {
          if (value && value > 0.1) {
            //setUploadProgress(value * 100);
            //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")
            if (value == 1) {
              enqObject.answerImageUploadProgress = 99;
            } else {
              enqObject.answerImageUploadProgress = value * 100;
            }

            enquiryQuestionsList[index] = enqObject;

            var temp = [...enquiryQuestionsList];
            temp[index] = enqObject;
            setEnquiryQuestionsList(temp);
          }
        },
      })
      .then(
        (result) => {
          tempFile.imageURL = result._url;
          tempFile.image = result;
          enqObject.answerImage = tempFile;
          enqObject.answerError = false;
          enqObject.answerImageUploadProgress = 100;

          enquiryQuestionsList[index] = enqObject;

          var temp = [...enquiryQuestionsList];
          temp[index] = enqObject;
          setEnquiryQuestionsList(temp);
        },
        (error) => {
          //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
        }
      );

    enqObject.answerImage = { actualImageName: tempFile.actualImageName };
    enquiryQuestionsList[index] = enqObject;

    var temp = [...enquiryQuestionsList];
    temp[index] = enqObject;
    setEnquiryQuestionsList(temp);
  }

  //  Click/Changes Handlers
  const handleYesNoButtonClick = (choice, enqQuestionObject) => {
    var enqObject = enqQuestionObject;

    var index = enquiryQuestionsList.indexOf(enqQuestionObject);

    var choices = [...enqQuestionObject.choices];

    var tempArray = [...enqObject.answerChoices];

    if (enqObject.answerChoices.includes(choice)) {
      //remove
      removeElement(tempArray, choice);
    } else {
      tempArray = [choice];
    }

    enqObject.answerChoices = [...tempArray];

    if (enqObject.isMandatory) {
      if (tempArray != null && tempArray.length > 0) {
        enqObject.answerError = false;
      } else {
        enqObject.answerError = true;
      }
    }

    if (enqObject.answerChoices.includes(choices[0])) {
      enqObject.answerBool = true;
    } else if (enqObject.answerChoices.includes(choices[1])) {
      enqObject.answerBool = false;
    } else {
      enqObject.answerBool = null;
    }

    var temp = [...enquiryQuestionsList];

    temp[index] = enqObject;

    setEnquiryQuestionsList(temp);
  };

  const handleChoiceButtonClick = (choice, enqQuestionObject) => {
    var enqObject = enqQuestionObject;

    var index = enquiryQuestionsList.indexOf(enqQuestionObject);

    if (
      enqObject.questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      var tempArray = [...enqObject.answerChoices];

      if (enqObject.answerChoices.includes(choice)) {
        //remove
        removeElement(tempArray, choice);
      } else {
        tempArray.push(choice);
      }

      enqObject.answerChoices = tempArray;

      if (enqObject.isMandatory) {
        if (tempArray != null && tempArray.length > 0) {
          enqObject.answerError = false;
        } else {
          enqObject.answerError = true;
        }
      }

      var temp = [...enquiryQuestionsList];

      temp[index] = enqObject;

      setEnquiryQuestionsList(temp);
    } else if (
      enqObject.questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      var tempArray = [...enqObject.answerChoices];

      if (enqObject.answerChoices.includes(choice)) {
        //remove
        removeElement(tempArray, choice);
      } else {
        tempArray = [choice];
      }

      enqObject.answerChoices = [...tempArray];

      if (enqObject.isMandatory) {
        if (tempArray != null && tempArray.length > 0) {
          enqObject.answerError = false;
        } else {
          enqObject.answerError = true;
        }
      }

      var temp = [...enquiryQuestionsList];

      temp[index] = enqObject;

      setEnquiryQuestionsList(temp);
    }
  };

  const handleInputOnChange = (index, changedValue) => {
    var enqObject = enquiryQuestionsList[index];

    if (enqObject.dataType === "STRING") {
      enqObject.answerText = changedValue;
      enquiryQuestionsList[index] = enqObject;
    }
  };

  const handleDateChange = (index, changedValue) => {
    var enqObject = enquiryQuestionsList[index];
    enqObject.answerDate = new Date(changedValue);
    enquiryQuestionsList[index] = enqObject;
  };

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  
  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var resultWithName = { name: file.name, result: reader.result };
      cb(resultWithName);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }
    // else if(fileType === "Video"){
    //     return "bx bx-play-circle";
    // }else if (_audioExtensions.includes(fileExtension)) {
    //     return "bx bxs-music";
    // }
    return "bx bxs-file-blank";
  }

  return (
    <React.Fragment>
      <MetaTags>
        <title> {schoolObject ? schoolObject.attributes.Name : "Enquiry Form"} </title>
      </MetaTags>
      <Container
        fluid={true}
        className={(match || franchiseForm || instituteForm || matchEnquiryIOS || EnquiryRefillmatch) ? "" : "page-content"}
      >
        {!isLoading && !isFormSubmitted && isQuestionsAvailable && (
          <Row>
            <Col lg={matchInsideDashboard ? 12 : 8} className="mx-auto">
              {(match || franchiseForm || instituteForm || matchEnquiryIOS || EnquiryRefillmatch) && (
                <div className="text-center mt-3">
                  {schoolObject && schoolObject.attributes.logo && (
                    <img
                      src={schoolObject.attributes.logo._url}
                      width="100"
                      alt=""
                      className="img-thumbnail rounded-circle"
                    />
                  )}
                  <h3>
                    {schoolObject
                      ? props.t(`Welcome to ${schoolObject.attributes.Name}`)
                      : props.t("Welcome")}{" "}
                  </h3>
                  {/* {schoolObject && schoolObject.id === "HBFvfLJTZY" &&

                    <Card><CardBody>
                      <h6>School About us :</h6><p> {schoolObject.attributes.Name} is a preschool that specialises in early childhood education. It emphasised preschoolers' personal, social, emotional, language, and cognitive Improvement.
                      </p>
                      <h6>School Address :</h6><p>{schoolObject.attributes.Address}
                      </p>
                      <Row>

                        <Col>
                          <h6> Contact No :</h6><p>{schoolObject.attributes.primaryPhoneNumber}
                          </p>
                          <h6 > E-mail ID :</h6> <p>{schoolObject.attributes.EMail}
                          </p></Col>


                      </Row>
                    </CardBody>
                    </Card>
                  } */}
                  <h6 className="mt-3">
                    {parameter
                      ? props.t("Please provide the details")
                      : props.t("Please provide the details")}
                  </h6>
                </div>
              )}
              <Card>
                <CardBody>
                  <AvForm
                    className="outer-repeater"
                    style={{ border: "1px" }}
                    onSubmit={isFormValid}
                  >
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <h5>{props.t("Enquiry Questions")}: </h5>
                        <hr />

                        {enquiryQuestionsList.map((enquiryObject, key) => (
                          <Element name={enquiryObject.id} key={key}>
                            {componentForQuestionType(enquiryObject, key)}
                          </Element>
                        ))}
                        {enquiryQuestionsList.length > 0 && <hr></hr>}
                        {oneParentError && <p className="text-danger text-center">{props.t('One parent name and contact details are mandatory')}</p>}
                        {enquiryQuestionsList.length > 0 && <p className="text-danger text-center">{props.t('* By submitting, you agree to receive communication messages (Example Email/Whatsapp/SMS)')}</p>}
                        {enquiryQuestionsList.length > 0  &&   formSubmitState != "SUBMIT_REQUST"&& (
                          <div style={{ display: "flex" }}>
                            <Button
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "180px",
                              }}
                              type="submit"
                              color="primary"
                            >
                              {props.t("SUBMIT")}
                            </Button>
                          </div>
                        )}
                        {enquiryQuestionsList.length > 0  &&   formSubmitState == "SUBMIT_REQUST"&& (
                          <div style={{ display: "flex" }}>
                             <Button
                              style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                width: "180px",
                              }}
                              color="info"
                            >
                              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Submitting...')}
                            </Button>
                           
                          </div>
                        )}

                        {enquiryQuestionsList.length > 0  &&  ( formSubmitState == "SUBMIT_FAILED_ENQUIRY" || formSubmitState == "SUBMIT_FAILED_PARENT")&& (
                          <div style={{ display: "flex" }}>
                            <p className="text-danger"
                               style={{
                                marginLeft: "auto",
                                marginRight: "auto",
                                
                              }}
                            >
                              {"Failed to submit the form, Please try after some time or refresh and resubmit the form"}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {isFormSubmitted && (
          <Row>
            <Col lg={12}>

              {matchInsideDashboard && (
                <div
                  onClick={() => {
                    window.history.back();
                  }}
                >
                  <ul className=" list-unstyled mb-3">
                    <div>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
              )}

              <Card>
                <CardBody>
                  <AvForm
                    className="outer-repeater"
                    style={{ border: "1px" }}
                  >
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <h5>
                          <i
                            className="fa fa-check text-success mr-2"
                            aria-hidden="true"
                          ></i>
                          Successfully submitted the form !!
                        </h5>
                      </div>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>

            </Col>
          </Row>
        )}

        {!isLoading && !isQuestionsAvailable && (
          <Card>
            <CardBody>
              <AvForm
                className="outer-repeater"
                style={{ border: "1px" }}
              >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    <h5>Enquiry form not configured for this branch !</h5>
                  </div>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        )}

        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Enquiry Form...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Container>
      {reshareAlertBoxMessage && <SweetAlert
            title="Alert"
            danger
            showConfirm={false}
          >
            {reshareAlertBoxMessage}
          </SweetAlert>}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  return { selectedSchool };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
    })(EditEnquiryForm)
  )
);

class CustomEnquiryObject {
  id = "";
  question = "";
  questionType = null;
  questionNumber = null;
  enquiryQuestionParseObject = null;
  answerText = null;
  answerNumber = null;
  answerBool = null;
  answerDate = null;
  answerChoices = [];
  choices = [];
  dataType = null;
  answerFile = null;
  questionHint = "Type here";
  isMandatory = false;
  answerError = false;
  answerImage = null;
  answerFileUploadProgress = 0;
  answerImageUploadProgress = 0;

  constructor(
    id,
    question,
    questionType,
    questionNumber,
    enquiryQuestionParseObject,
    answerText,
    answerNumber,
    answerBool,
    dataType,
    answerDate,
    answerChoices,
    choices,
    questionHint,
    isMandatory = false,
    answerError = false,
    answerFile,
    answerImage,
    answerFileUploadProgress,
    answerImageUploadProgress
  ) {
    this.id = id;
    this.question = question;
    this.questionType = questionType;
    this.questionNumber = questionNumber;
    this.enquiryQuestionParseObject = enquiryQuestionParseObject;
    this.answerText = answerText;
    this.answerNumber = answerNumber;
    this.answerBool = answerBool;
    this.dataType = dataType;
    this.answerDate = answerDate;
    this.answerChoices = answerChoices;
    this.choices = choices;
    this.questionHint = questionHint;
    this.isMandatory = isMandatory;
    this.answerError = answerError;
    this.answerFile = answerFile;
    this.answerImage = answerImage;
    this.answerFileUploadProgress = answerFileUploadProgress;
    this.answerImageUploadProgress = answerImageUploadProgress;
  }
}

//Temp Code:-

// if (enquiryQuestionsList[i].enquiryQuestionParseObject.attributes.databaseTableName === "Parent" && enquiryQuestionsList[i].enquiryQuestionParseObject.attributes.objectType === 2) {
//   if ((enquiryQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER || enquiryQuestionsList[i].questionType === Constants.QUESTION_EMAIL) && (enquiryQuestionsList[i].answerText != null && enquiryQuestionsList[i].answerText.length > 0)) {
//     isParentDetailsValid = true
//   }
// }
// if (enquiryQuestionsList[i].enquiryQuestionParseObject.attributes.databaseTableName === "Parent" && enquiryQuestionsList[i].enquiryQuestionParseObject.attributes.objectType === 3) {
//   if ((enquiryQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER || enquiryQuestionsList[i].questionType === Constants.QUESTION_EMAIL) && (enquiryQuestionsList[i].answerText != null && enquiryQuestionsList[i].answerText.length > 0)) {
//     isParentDetailsValid = true
//   }
// }
