import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { withNamespaces } from 'react-i18next';
import { Button, Card, CardBody, CardTitle, CardSubtitle, Table, Input, TabContent, TabPane, Container, Row, Col, Nav, NavItem, NavLink, Label, Modal, ModalBody, ModalHeader, ModalFooter, } from 'reactstrap';
import Topbar from '../Inventory/Topbar'
import { setSelecetdSchool, getAllKids, getAttendanceForDaycareId, getAttendanceForClassId, getClassroomsForSchoolId,updateClassAttendance,updateDaycareAttendance,setAttendanceQueueTimer } from "../../store/actions";
import { matchPath } from "react-router";
import ReactDatePicker from 'react-datepicker';
import Switch from "react-switch";
import Parse from "parse";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import SweetAlert from "react-bootstrap-sweetalert";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';

const Attendence = props => {
    const match = matchPath(props.history.location.pathname, {
        path: "/attendence/:type/:schoolId/:classId",
        exact: true,
        strict: false,
    });

    const classId = match && match.params.classId;
    const classType = match && match.params.type;
    const schoolId = match && match.params.schoolId;

    const [classroomKids, setClassroomKids] = useState([]);
    const [absentKids, setAbsentKids] = useState([]);
    const [checkInKids, setCheckInKids] = useState([]);
    const [checkOutKids, setCheckOutKids] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [activeTab, setactiveTab] = useState(classType == 2 ? "2" : "1");

    const [startDate, setStartDate] = useState(new Date())
    const [endDate, setEndDate] = useState(new Date())

    const [classModal, toggleClassModal] = useState(false);

    const [selectedType, setSelectedType] = useState(0);
    const [selectedTypeTitle, setSelectedTypeTitle] = useState("Slected Students");
    const [selectedTime, setSelectedTime] = useState(undefined)

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.getAllKids(schoolId);
        if (props.classrooms == null || props.classrooms.length == 0) {
            props.getClassroomsForSchoolId(schoolId)
        }
    }, [schoolId]);

    useEffect(() => {
        featchAttendance()
        autoUpdate()
    }, [classId]);

    const featchAttendance=()=>{
        if (classId && startDate && endDate) {
            var fromDate = new Date(startDate.getFullYear(), startDate.getMonth(), startDate.getDate(), 0, 0, 0);
            var toDate = new Date(endDate.getFullYear(), endDate.getMonth(), endDate.getDate(), 23, 59);

            setStartDate(fromDate);
            setEndDate(toDate);

            if (classType == 2) {
                props.getAttendanceForDaycareId(classId, fromDate, toDate)
            } else {
                props.getAttendanceForClassId(classId, fromDate, toDate)
            }
        }
    }

    const autoUpdate = () => {
        if (props.attendanceQueueTimer == null) {
            let timerId = setInterval(() => {
                setStartDate(new Date())
                setEndDate(new Date())
                featchAttendance()
                setSelectedUsers([])
                toggleClassModal()
                
            }, 1 * 60 * 1000)
            props.setAttendanceQueueTimer(timerId)
        }
    }
    useEffect(() => {
       let d = new Date();
       console.log("props.attendanceQueueTimer:::",d,":::",props.attendanceQueueTimer)
    }, [props.attendanceQueueTimer]);

    useEffect(() => {
        let list = []
        if (props.kids && props.kids.length > 0) {
            for (const k of props.kids) {
                if (k.attributes.ClassRoomID === classId) {
                    list.push(k);
                } else if (classType == 2) {
                    if (k.attributes.daycareClassId === classId) {
                        list.push(k);
                    }
                }
            }
        }

        setClassroomKids(list);
    }, [props.kids, classId]);

    useEffect(() => {

        let abKids = [];
        let cInKids = [];
        let cOutKids = [];
        if (classroomKids && classroomKids.length > 0) {
            for (const k of classroomKids) {
                if (classType == 2) {
                    if (props.daycareAttendance) {
                        let ka = undefined;
                        for (const attendance of props.daycareAttendance) {
                            if (attendance.attributes.kidId == k.id) {
                                if (!attendance.attributes.checkOutTime) {
                                    ka = attendance;
                                }
                            }
                        }
                        if (ka) {
                            cInKids.push({ id: k.id, kid: k, attendance: ka })
                        } else {
                            cOutKids.push({ id: k.id, kid: k, attendance: ka })
                        }
                    } else {
                        cOutKids.push({ id: k.id, kid: k, attendance: undefined })
                    }
                } else {
                    if (props.classAttendance) {
                        let ka = undefined
                        for (const attendance of props.classAttendance) {
                            if (attendance.attributes.Kid == k.id) {
                                ka = attendance
                            }
                        }

                        if (ka && ka.attributes.LeftSchoolAt) {
                            cOutKids.push({ id: k.id, kid: k, attendance: ka })
                        } else if (ka && ka.attributes.ReachedSchoolAt) {
                            cInKids.push({ id: k.id, kid: k, attendance: ka })
                        } else {
                            // console.log("111")
                            abKids.push({ id: k.id, kid: k, attendance: ka })
                        }

                    } else {
                        // console.log("222")
                        abKids.push({ id: k.id, kid: k, attendance: undefined });
                        continue


                    }
                }
            }
        }


        setAbsentKids(abKids)
        setCheckInKids(cInKids)
        setCheckOutKids(cOutKids)
        // console.log(props.daycareAttendance)
        // console.log(props.classAttendance)
        // console.log("Absent:", abKids)
        // console.log("CheckIn:", cInKids)
        // console.log("CheckOut:", cOutKids)
    }, [classroomKids, props.classAttendance, props.daycareAttendance])



    const handleSelection = (kidId) => {
        let list = [...selectedUsers]
        if (list.includes(kidId)) {
            list = list.filter(id => id != kidId);
        } else {
            list.push(kidId)
        }
        setSelectedUsers(list)
    }

    const handleSelectAll = (list) => {
        if (selectedUsers.length == list.length) {
            setSelectedUsers([])
        } else {
            let selectAll = [];
            list.map(kidObject => {
                if (!selectAll.includes(kidObject.id)) {
                    selectAll.push(kidObject.id);
                }
            });
            setSelectedUsers(selectAll)
        }
    };

    const getClassName = (classId) => {
        for (const c of props.classrooms) {
            if (c.id == classId) {
                return c.attributes.Name
            }
        }
        return "-";
    };

    

    //Marking Attendance

    const markAttendance = (type) => {
        if (selectedUsers && selectedUsers.length > 0) {
            setSelectedType(type)
            toggleClassModal(true)

            if (type == 1 || type == 3) {
                setSelectedTypeTitle("Selected Students to Mark Absent")
            }
            if (type == 2) {
                setSelectedTypeTitle("Selected Students for Check-In")
            }
            if (type == 4) {
                setSelectedTypeTitle("Selected Students for Check-Out")
            }
            if (type == 5) {
                setSelectedTypeTitle("Selected Students to move to Check-In")
            }
            if (type == 6) {
                setSelectedTypeTitle("Selected Students for daycare Check-In")
            }
            if (type == 7) {
                setSelectedTypeTitle("Selected Students for daycare Check-Out")
            }

        }
    }

    //  Student Absent 
    const moveToAbsent = () => {
        if (selectedUsers && selectedUsers.length > 0) {
            let absentList = [];
            let kidsList =activeTab==1?[...absentKids]:[...checkInKids]
            for (const kidId of selectedUsers) {
                for (const k of kidsList) {
                    if (k.id == kidId) {
                        let attendanceObject;
                        if (k.attendance) {
                            attendanceObject = k.attendance;
                            attendanceObject.unset("ReachedSchoolAt")
                        } else {
                            // new parse object
                            attendanceObject = new Parse.Object("Attendance");
                            attendanceObject.set("Class", classId);
                            attendanceObject.set("Kid", kidId);
                            attendanceObject.set("School", schoolId);  
                        }

                        attendanceObject.set("remarks", "absent");
                        attendanceObject.set("remarkBy", Parse.User.current().attributes.teacherId);
                        absentList.push(attendanceObject);
                    }
                }
            }

            Parse.Object.saveAll(absentList).then(
                (result) => {

                    props.updateClassAttendance(result)
                    setSelectedUsers([])
                    toggleClassModal(false)

                    toastr.success("Successfully Marked Absent", { timeOut: 1000 });
                },
                (error) => {
                  console.log("Error", error);
                  toastr.danger("Error! Please try again", { timeOut: 1000 });
                }
            );
            
        }
    }
    

    // Move Student to (Tab1) to checkIn(Tab2) 


    const validateDaycareAttendanceAndMarkCheckin=()=>{

        if (selectedUsers && selectedUsers.length > 0) {
            let daycareKidIds=[]
            for (const kidId of selectedUsers) {
                for (const k of absentKids) {
                    if (k.id == kidId) {

                        if(k.kid.attributes.isDaycareChild ==1 && k.kid.attributes.daycareClassId){
                            daycareKidIds.push(k.id)
                        }
                    }
                }
            }
            if(daycareKidIds.length>0){
                
                var Attendance = Parse.Object.extend("DaycareTimeSheet");
                var query = new Parse.Query(Attendance);
                query.equalTo("schoolId", schoolId);
                query.greaterThanOrEqualTo("createdAt", startDate);
                query.lessThanOrEqualTo("createdAt", endDate);
                query.containedIn("kidId",daycareKidIds)
                query.doesNotExist("checkOutTime")
          
                query.find().then((results) => {
                  if(results && results.length>0){

                    let daycarecheckOutList=[]
                    for(const da  of results){
                        da.set("checkOutTime", selectedTime??new Date());
                        da.set("checkOutType", "Web");
                        da.set("checkedOutBy", Parse.User.current().attributes.teacherId);
                        daycarecheckOutList.push(da);
                    }

                    Parse.Object.saveAll(daycarecheckOutList).then(
                        (result) => {
                            handleMarkCheckIn()
                        },
                        (error) => {
                          console.log("Error", error);
                        }
                    );

                  }else{
                    handleMarkCheckIn()
                  }
                });
            }else{
                handleMarkCheckIn()
            }
        }

    }

    const handleMarkCheckIn = () => {
        if (selectedUsers && selectedUsers.length > 0) {
            let checkInList = []
            for (const kidId of selectedUsers) {
                for (const k of absentKids) {
                    if (k.id == kidId) {

                        let attendanceObject
                        if (k.attendance) {
                            attendanceObject = k.attendance;
                        } else {
                            // new parse object
                            attendanceObject = new Parse.Object("Attendance");
                            attendanceObject.set("Class", classId);
                            attendanceObject.set("Kid", kidId);
                            attendanceObject.set("School", schoolId);                            
                        }
                        attendanceObject.set("ReachedSchoolAt",selectedTime?? new Date())
                        attendanceObject.set("checkInType", "Web");
                        attendanceObject.set("checkedInBy", Parse.User.current().attributes.teacherId);
                        checkInList.push(attendanceObject);

                    }
                }

            }

            Parse.Object.saveAll(checkInList).then(
                (result) => {

                    props.updateClassAttendance(result)
                    setSelectedUsers([])
                    toggleClassModal(false)
                    setSelectedTime(undefined)
                    toastr.success("Successfully Marked Attendance", { timeOut: 1000 });
                },
                (error) => {
                  console.log("Error", error);
                  toastr.danger("Error! Please try again", { timeOut: 1000 });
                }
            );

            

        }

    }

    const handleMoveToCheckIn = () => {
        if (selectedUsers && selectedUsers.length > 0) {
            let checkInList = []
            for (const kidId of selectedUsers) {
                for (const k of checkOutKids) {
                    if (k.id == kidId) {

                        let teacher = props.userTeacher ? props.userTeacher.attributes.Name : " ";

                        let attendanceObject
                        if (k.attendance) {
                            attendanceObject = k.attendance;
                            attendanceObject.unset("LeftSchoolAt")
                            attendanceObject.set("remarks", "out to in - by " + teacher + Parse.User.current().attributes.teacherId);
                            checkInList.push(attendanceObject)
                        }
                    }
                }

            }

            Parse.Object.saveAll(checkInList).then(
                (result) => {

                    props.updateClassAttendance(result)
                    setSelectedUsers([])
                    toggleClassModal(false)
                    setSelectedTime(undefined)
                    toastr.success("Successfully Moved to check-in ", { timeOut: 1000 });
                },
                (error) => {
                  console.log("Error", error);
                  toastr.danger("Error! Please try again", { timeOut: 1000 });
                }
            );

        }
    }

    const handleCheckOutButtonClick = () => {
        if (selectedUsers && selectedUsers.length > 0) {
            let checkOutList = []
            for (const kidId of selectedUsers) {
                for (const k of checkInKids) {
                    if (k.id == kidId) {

                        let attendanceObject
                        if (k.attendance) {
                            attendanceObject = k.attendance;
                            attendanceObject.set("LeftSchoolAt", selectedTime??new Date())
                            attendanceObject.set("checkOutType", "Web");
                            attendanceObject.set("checkedOutBy", Parse.User.current().attributes.teacherId);

                        }
                        checkOutList.push(attendanceObject)
                    }
                }

            }

            Parse.Object.saveAll(checkOutList).then(
                (result) => {

                    props.updateClassAttendance(result)
                    setSelectedUsers([])
                    toggleClassModal(false)
                    setSelectedTime(undefined)
                    toastr.success("Successfully Marked Check-Out ", { timeOut: 1000 });
                },
                (error) => {
                  console.log("Error", error);
                  toastr.danger("Error! Please try again", { timeOut: 1000 });
                }
            );

        }

    }

    //daycare 
    const validateClassroomAttendanceAndMarkCheckIn=()=>{
        if (selectedUsers && selectedUsers.length > 0) {
            let classKidIds=[]
            for (const kidId of selectedUsers) {
                for (const k of checkOutKids) {
                    if (k.id == kidId) {
                        if(k.kid.attributes.isDaycareChild ==1 && k.kid.attributes.daycareClassId == classId){
                            classKidIds.push(k.id)
                        }
                    }
                }
            }
            if(classKidIds.length>0){

                var Attendance = Parse.Object.extend("Attendance");
                var query = new Parse.Query(Attendance);
                query.equalTo("School", schoolId);
                query.greaterThanOrEqualTo("createdAt", startDate);
                query.lessThanOrEqualTo("createdAt", endDate);
                query.containedIn("Kid", classKidIds);
                query.exists("ReachedSchoolAt")
                query.doesNotExist("LeftSchoolAt")
          
                query.find().then((results) => {
                  if(results && results.length>0){

                    let checkOutList=[]
                    for(const da  of results){
                        da.set("LeftSchoolAt", selectedTime??new Date());
                        da.set("checkOutType", "Web");
                        da.set("checkedOutBy", Parse.User.current().attributes.teacherId);
                        checkOutList.push(da);
                    }

                    Parse.Object.saveAll(checkOutList).then(
                        (result) => {
                            handleDaycareCheckInButtonClick()
                        },
                        (error) => {
                          console.log("Error", error);
                        }
                    );

                  }else{
                    handleDaycareCheckInButtonClick()
                  }
                });
            }else{
                handleDaycareCheckInButtonClick()
            }
        }
    }

    const handleDaycareCheckInButtonClick = () => {
        if (selectedUsers && selectedUsers.length > 0) {
            let daycarecheckInList = []
            for (const kidId of selectedUsers) {
                for (const k of checkOutKids) {
                    if (k.id == kidId) {

                        let attendanceObject
                        
                        attendanceObject = new Parse.Object("DaycareTimeSheet");
                        attendanceObject.set("daycareClassId", classId);
                        attendanceObject.set("kidId", kidId);
                        attendanceObject.set("schoolId", schoolId);
                        attendanceObject.set("checkInTime", selectedTime??new Date());
                        attendanceObject.set("checkInType", "Web");
                        attendanceObject.set("checkedInBy", Parse.User.current().attributes.teacherId);
                        
                        daycarecheckInList.push(attendanceObject)
                    }
                   
                }

            }

            Parse.Object.saveAll(daycarecheckInList).then(
                (result) => {

                    props.updateDaycareAttendance(result)
                    setSelectedUsers([])
                    toggleClassModal(false)
                    setSelectedTime(undefined)
                    toastr.success("Successfully Marked Check-In", { timeOut: 1000 });
                },
                (error) => {
                  console.log("Error", error);
                  toastr.danger("Error! Please try again", { timeOut: 1000 });
                }
            );
            
        }

    }
    const handleDaycareCheckOutButtonClick = () => {
        if (selectedUsers && selectedUsers.length > 0) {
            let daycarecheckOutList = []
            for (const kidId of selectedUsers) {
                for (const k of checkInKids) {
                    if (k.id == kidId) {

                        let attendanceObject
                        if (k.attendance) {
                            attendanceObject = k.attendance;
                            attendanceObject.set("checkOutTime",selectedTime ?? new Date());
                            attendanceObject.set("checkOutType", "Web");
                            attendanceObject.set("checkedOutBy", Parse.User.current().attributes.teacherId);
                            daycarecheckOutList.push(attendanceObject)

                        } 
                        

                    }
                    
                }

            }


            Parse.Object.saveAll(daycarecheckOutList).then(
                (result) => {

                    props.updateDaycareAttendance(result)
                    setSelectedUsers([])
                    toggleClassModal(false)
                    setSelectedTime(undefined)

                    toastr.success("Successfully Marked check-out", { timeOut: 1000 });
                },
                (error) => {
                  console.log("Error", error);
                  toastr.danger("Error! Please try again", { timeOut: 1000 });
                }
            );

        }

    }

    const getKidListUI = (list, isAbsentList) => {
        return (
            <Card className='p-2'>
                <div className="row" style={{ display: 'flex', whiteSpace: "pre-wrap" }}>

                    {list.length > 0 && (
                        <Col xl="2" sm="6" onClick={() => handleSelectAll(list)}>
                            <div className='p-0'>
                                <Card className={`shadow-sm text-center bg-light ${selectedUsers.length == list.length ? 'border-2 border-success' : ''}`}>
                                    <CardBody>
                                        <div className="custom-control-input input-mini text-center">
                                            <div className="avatar-sm mx-auto m-1" style={{ display: 'flex' }}>
                                                <span className={selectedUsers.length == list.length ? "avatar-title rounded text-success font-size-16 bg-soft-success" : "avatar-title rounded text-dark font-size-16 bg-soft-primary"}>
                                                    <i className='bx bx-badge-check'></i>
                                                </span>
                                            </div>
                                            {selectedUsers.length == list.length ? <p className="m-0 text-sucess">Select All</p> : <p className="m-0 text-dark">Select All</p>}
                                        </div>
                                    </CardBody>
                                </Card>
                            </div>
                        </Col>
                    )}


                    {/* classId(ClassRoomID == classId) (daycareClassId == classId)*/}
                    {list.length > 0 ? list.map((kidObject, i) => (
                        <Col xl="2" sm="6" key={kidObject.id} onClick={() => handleSelection(kidObject.id)}>

                            <Card className={`shadow-sm text-center bg-light ${selectedUsers.includes(kidObject.id) ? 'border-2 border-dark' : ''}`}>
                                <CardBody>

                                    {isAbsentList && kidObject.attendance && (
                                        <div className='position-absolute top-0 right-0 end-0 bg-danger m-1  rounded-circle'>
                                            <p className='text-white m-0 font-size-6'>{" A "}</p>
                                        </div>
                                    )}

                                    {selectedUsers.includes(kidObject.id) && (
                                        <div className={`position-absolute top-0 right-0 end-0 m-1 rounded-circle  ${activeTab == 1 ? 'bg-danger' : ' '}${activeTab == 2 ? 'bg-info' : ' '}${activeTab == 3 ? 'bg-success' : ' '}`}>
                                            <p className='text-white m-0 font-size-6'>{" ✔ "}</p>
                                        </div>
                                    )}
                                    <div className="custom-control-input input-mini text-center">
                                        {!kidObject.kid.attributes.Photo ?
                                            <div className="avatar-sm mx-auto m-1" style={{ display: 'flex' }}>
                                                <span className={"avatar-title rounded bg-soft-primary  text-dark font-size-16"}>
                                                    {kidObject.kid.attributes.Name ? kidObject.kid.attributes.Name.charAt(0) : ''}
                                                </span>
                                            </div>
                                            :
                                            <div className='m-1'>
                                                <img className="rounded avatar-sm" src={kidObject.kid.attributes.Photo._url} alt="" />
                                            </div>
                                        }
                                    </div>
                                    <p className="m-0 text-dark">{kidObject.kid.attributes.Name}</p>
                                </CardBody>
                            </Card>
                        </Col>
                    )) : (
                        <Col className='text-center'>
                            <h3>No students to display</h3>
                        </Col>
                    )}

                </div>
            </Card>


        )
    }

    const getKidRow = (kidId) => {
        for (const k of classroomKids) {
            if (k.id == kidId) {
                return (
                    <tr key={kidId}>


                        <td>
                            <div className="d-flex mt-0">
                                <div className="my-auto align-middle">
                                    {k.attributes.Photo ? (
                                        <img
                                            src={k.attributes.Photo._url}
                                            className="avatar-xs rounded "
                                            alt=""
                                        />
                                    ) : (
                                        <div className="avatar-xs mx-auto m-1 rounded ">

                                            <span className={"avatar-title bg-soft-primary text-dark font-size-16"}>
                                                {k.attributes.Name ? k.attributes.Name.charAt(0) : ''}
                                            </span>
                                        </div>
                                    )}
                                </div>
                                <div className="float-right m-3 my-auto">
                                    <div>
                                        <Label className="text-dark mt-0 mb-0">
                                            {k.attributes.Name}
                                        </Label><br />

                                    </div>
                                </div>
                            </div>
                        </td>

                    </tr>
                )
            }
        }
        return "-";
    };


    return (
        <React.Fragment>
            <Container>
                <div className='page-content'>
                    <Row>
                        <Topbar
                            // title={"Attendance"}
                            title={`Attendance - ${getClassName(classId)}`}
                            buttonName={"New "}
                            hideButton={true}
                        />
                        <div>
                            <Row>
                                <Col lg={8} md={8} xs={12}>
                                </Col>
                            </Row>
                            <Row className="checkout-tabs">
                                <Col lg="2">
                                    <Nav className="flex-column" pills>
                                        {classType != 2 && <NavItem>
                                            <NavLink
                                                className={activeTab === "1" ? "active" : ""}
                                                onClick={() => {
                                                    setSelectedUsers([])
                                                    setactiveTab("1");
                                                }}
                                            >
                                                <p className="font-weight-bold mb-4">
                                                    {"Absent (" + absentKids.length + ")"}
                                                </p>
                                            </NavLink>
                                        </NavItem>}
                                        <NavItem >
                                            <NavLink
                                                className={activeTab === "2" ? "active" : ""}
                                                onClick={() => {
                                                    setSelectedUsers([])
                                                    setactiveTab("2");
                                                }}
                                            >
                                                {classType == 2 ?
                                                    <p className="font-weight-bold mb-4">{"Check-out (" + checkOutKids.length + ")"}</p> :
                                                    <p className="font-weight-bold mb-4">{"Check-In (" + checkInKids.length + ")"}</p>}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                className={activeTab === "3" ? "active" : ""}
                                                onClick={() => {
                                                    setSelectedUsers([])
                                                    setactiveTab("3");
                                                }}
                                            >
                                                {classType == 2 ?
                                                    <p className="font-weight-bold mb-4">{"Check-In (" + checkInKids.length + ")"}</p> :
                                                    <p className="font-weight-bold mb-4">{"Check-out (" + checkOutKids.length + ")"}</p>
                                                }

                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                </Col>
                                <Col lg="10">

                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1">
                                            <Row>
                                                <Col className lg={10}>
                                                    {getKidListUI(absentKids, true)}
                                                </Col>
                                                <Col className lg={2}>

                                                    <Card>
                                                        <CardBody className='text-center'>
                                                            <Button
                                                                type="button"
                                                                color="danger"
                                                                className="btn btn-sm waves-effect waves-light  m-2"
                                                                onClick={() => { markAttendance(1) }}
                                                            >
                                                                {props.t('Absent')}

                                                            </Button>

                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="btn waves-effect waves-light  btn-sm m-2"
                                                                onClick={() => {
                                                                    markAttendance(2);

                                                                }}
                                                            >
                                                                {props.t('Check-In')}
                                                            </Button>


                                                        </CardBody>
                                                    </Card>
                                                </Col>

                                            </Row>
                                        </TabPane>
                                        <TabPane tabId="2">
                                            <Row>
                                                <Col className lg={10}>
                                                    {classType == 2 ? getKidListUI(checkOutKids) : getKidListUI(checkInKids)}
                                                </Col>
                                                <Col className lg={2}>

                                                    <Card>
                                                        {classType == 2 ? <CardBody className='text-center'>


                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="btn waves-effect waves-light  btn-sm m-2"
                                                                onClick={() => { markAttendance(6) }}
                                                            >
                                                                {props.t('Check-in')}
                                                            </Button>


                                                        </CardBody>
                                                            :
                                                            <CardBody className='text-center'>
                                                                <Button
                                                                    type="button"
                                                                    color="danger"
                                                                    className="btn btn-sm waves-effect waves-light  m-2"
                                                                    onClick={() => { markAttendance(3) }}
                                                                >
                                                                    {props.t('Absent')}

                                                                </Button>

                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    className="btn waves-effect waves-light  btn-sm m-2"
                                                                    onClick={() => { markAttendance(4) }}
                                                                >
                                                                    {props.t('Check-Out')}
                                                                </Button>


                                                            </CardBody>}
                                                    </Card>
                                                </Col>
                                            </Row>
                                        </TabPane>

                                        <TabPane tabId="3">
                                            <Row>
                                                <Col className lg={10}>
                                                    {classType == 2 ? getKidListUI(checkInKids) : getKidListUI(checkOutKids)}
                                                </Col>
                                                <Col className lg={2}>

                                                    <Card>
                                                        {classType == 2 ? <CardBody className='text-center'>


                                                            <Button
                                                                type="button"
                                                                color="primary"
                                                                className="btn waves-effect waves-light  btn-sm m-2"
                                                                onClick={() => { markAttendance(7) }}
                                                            >
                                                                {props.t('Check-Out')}
                                                            </Button>


                                                        </CardBody>
                                                            :
                                                            <CardBody className='text-center'>
                                                                <Button
                                                                    type="button"
                                                                    color="primary"
                                                                    className="btn waves-effect waves-light  btn-sm m-2"
                                                                    onClick={() => { markAttendance(5) }}
                                                                >
                                                                    {props.t('Check-In')}
                                                                </Button>
                                                            </CardBody>
                                                        }
                                                    </Card>
                                                </Col>
                                            </Row>

                                        </TabPane>
                                    </TabContent>

                                </Col>
                            </Row>
                        </div>
                    </Row>
                </div>
            </Container>


            <Modal isOpen={classModal} scrollable={true}>
                <ModalHeader>
                    <div className="modal-title mt-0">{selectedTypeTitle + " (" + selectedUsers.length + ")"}</div>
                </ModalHeader>
                <ModalBody>

                    {selectedType==5 && <p>Attention <br></br> Selected students already been checked out. By confirming you are moving the child back to the check-in status, and a notification will be sent</p>}

                    {selectedUsers.map(kidId => (

                        <table key={kidId}>
                            <tbody>
                                {getKidRow(kidId)}
                            </tbody>
                        </table>
                    ))}

                    {(selectedType == 2 || selectedType == 4 || selectedType == 6 || selectedType == 7)&&<div className='d-flex'>
                    <Col>
                        <div>
                            <label className='float-left m-2'>{(selectedType == 2 || selectedType == 6 ) ?"Check-In Now":"Check-Out Now"}</label>
                            <Switch
                            height={15}
                            width={25}
                            className="float-left "
                            uncheckedIcon={<Offsymbol />}
                            checkedIcon={<OnSymbol />}
                            onColor={"#6add7d"}
                            offColor={"#6add7d"}
                            onChange={() => {
                                setSelectedTime(selectedTime?undefined:new Date())
                            }}
                            checked={selectedTime?true:false}
                            />
                            <label className='float-right m-2'>Or With Time</label>

                        </div>

                        {selectedTime &&
                        <ReactDatePicker
                            selected={selectedTime}
                            className="form-control mb-3"
                            onChange={(date) => {
                            setSelectedTime(date)
                            }}
                            showTimeSelect
                            showTimeSelectOnly
                            timeIntervals={10}
                            timeCaption="Time"

                            dateFormat="h:mm aa"
                            minTime={startDate}
                            maxTime={new Date()}
                        />
                        }

                          {selectedTime &&<div style={{ height: "200px" }}></div>}
                     </Col>

                    </div>}



                   
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => toggleClassModal(!classModal)}>Cancel</Button>

                    {(selectedType == 1 || selectedType == 3) &&
                        <Button color="success" onClick={() => { moveToAbsent() }}>Mark Absent</Button>
                    }

                    {(selectedType == 2 || selectedType == 5 || selectedType == 6) &&
                        <Button color="success" onClick={() => { 
                            if(selectedType == 2){
                                validateDaycareAttendanceAndMarkCheckin()
                            }else if(selectedType == 5){
                                handleMoveToCheckIn()
                            }else if(selectedType == 6){
                                validateClassroomAttendanceAndMarkCheckIn()
                            }
                        }
                        }>Check-In</Button>
                    }
                    

                    {(selectedType == 4 || selectedType == 7) &&
                        <Button color="success" onClick={() => {
                            if(selectedType == 4){
                                handleCheckOutButtonClick() 
                            }else if(selectedType == 7){
                                handleDaycareCheckOutButtonClick()
                            }
                         }}>Check-Out</Button>
                    }
                    


                </ModalFooter>
            </Modal>

        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { kids } = state.Kid;
    const { classAttendance, daycareAttendance, attendanceQueueTimer } = state.Attendance
    const { classrooms } = state.Classroom;
    const { userTeacher } = state.Login;
    return { selectedSchool, kids, classAttendance, daycareAttendance, classrooms, userTeacher, attendanceQueueTimer };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getAllKids,
    getAttendanceForDaycareId,
    getAttendanceForClassId,
    getClassroomsForSchoolId,
    updateClassAttendance,
    updateDaycareAttendance,
    setAttendanceQueueTimer,
})(Attendence)));