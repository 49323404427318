import React, { useEffect,useState} from 'react';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool} from '../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { matchPath } from "react-router";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  CardHeader,
  CardTitle
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import Image from "../../assets/images/growing_chart.svg";
import TopBar from "./Topbar";
import ConsolidatedReportChart from './Charts/ConsolidatedReportChart';
import RateOfIncreaseChart from './Charts/RateOfIncreaseChart';
import CummulativeChart from './Charts/CumulativeChart';
import ConversionTrend from './Charts/ConversionTrend';

const ConsolidatedMonthlyReport = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/franchise-consolidated-reports/:id",
        exact: true,
        strict: false,
    });

    const schoolMatch = matchPath(props.history.location.pathname, {
        path: "/monthly-consolidated-reports/:schoolId/:id",
        exact: true,
        strict: false,
    });


    const reportId = match ? match.params.id : schoolMatch.params.id;
    const schoolId = schoolMatch ? schoolMatch.params.schoolId : null;

    const [questionIds , setQuestionIds]=useState([]);
    const [admissionsQuestion,setAdmissionQuestion] = useState('');
    const [enquiryQuestion,setEnquiryQestion] = useState('');
    

    const [reportObject, setReportObject] = useState(undefined);
    const [reportQuestions, setReportQuestions] = useState(undefined);

    const [reportSectionObjects, setReportSectionObjects] = useState(undefined);

    const [reportSessionObjects, setReportSessionObjects] = useState(undefined);

    const [reportData, setReportData] = useState(undefined);
    
    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    
    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    

    const [consolidatedReportData, setConsolidatedReportData] = useState(undefined);

    const [selectedMonths, setSelectedMonths] = useState([]);
    const [enquiryCount, setEnquiryCount] = useState(0);
    const [admissionsCount, setAdmissionsCount] = useState(0);
    const [monthSchoolData, setMonthSchoolData] = useState([]);

    const [loadingState, setLoadingState] = useState(0);
    const [reportName, setReportName] = useState('Consolidated Report');

    useEffect(() => {
        if (Parse.User.current()) {
            if (match && Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.franchiseId.length > 0) {

            } else if (schoolMatch && schoolId) {
                if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
                    props.setSelecetdSchool(schoolId);
                }
            }
            else {
                props.history.push("/pages-404");
            }
        }else{
            props.history.push("/pages-404");
        }
        
        getReortObject();
    }, [Parse.User.current()]);


    const handelDateChange = () => {
        setLoadingState(2);
        if (reportObject) {
            if (reportQuestions && reportQuestions.length > 0) {
                let ids = [];
                for (const obj of reportQuestions) {
                    if (!ids.includes(obj.attributes.sectionId)) {
                        ids.push(obj.attributes.sectionId)
                    }
                }
                getReortReportSections(ids);
            } else {
                getReortReportSessions();
            }
        } else {
            getReortObject();
        }
    }
    
    

    const getReortObject = () => {

        let query = new Parse.Query("CustomReportTemplate");
        query.equalTo("objectId", reportId);
        query.first().then((customReportTemplate) => {
            setReportObject(customReportTemplate);
            let list = customReportTemplate.attributes.consolidatedReferenceIds
            setQuestionIds(list)
            setEnquiryQestion(list[0]);
            setAdmissionQuestion(list[1]);

            setReportName('Consolidated Report For '+customReportTemplate.attributes.reportName)
            getReportReportQuestions();
        });
        
    }

    const getReportReportQuestions = () => {
        let query = new Parse.Query("ReportQuestions");
        query.containedIn("objectId", questionIds);
        query.find().then((questions) => {
            setReportQuestions(questions);
            let ids = [];
            for (const obj of questions) {
                if (!ids.includes(obj.attributes.sectionId)) {
                    ids.push(obj.attributes.sectionId);
                }
            }
            setLoadingState(1)

        });
    }

    //Quiry when user reselect the date
    const getReortReportSections = (ids) => {
        let query = new Parse.Query("ReportSection");
        query.containedIn("objectId", ids);
        query.find().then((sections) => {
            setReportSectionObjects(sections);

            getReortReportSessions()
        });
    }

    const getReortReportSessions = () => {
        
        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0, 23, 59);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth() , 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {
              
                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('MMM YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }
        setSelectedMonths(dateList);

        let query = new Parse.Query("CustomReportSession");
        query.equalTo('reportId', reportId);
        query.greaterThanOrEqualTo('reportMonth', startDate);
        query.lessThanOrEqualTo('reportMonth', endDate);
        query.equalTo('status', 4);
        query.notEqualTo('isDeleted', true);
        if (schoolMatch && schoolId) {
            query.equalTo('schoolId', schoolId);
        }
        query.limit(4000)
        query.find().then((sessions) => {
            setReportSessionObjects(sessions);
            let ids = [];
            for (const obj of sessions) {
                ids.push(obj.id)
            }
            if (ids.length > 0) {
                getReortReportData(ids);
            } else {
               setLoadingState(5);
            }
            
        });
    }


    const getReortReportData = (ids) => {
        let limit = ids.length * questionIds.length + 1000;
        let query = new Parse.Query("ReportData");
        query.equalTo('reportId', reportId);
        query.containedIn("sessionId", ids);
        query.containedIn("questionId", questionIds);
        query.limit(limit);
        query.find().then((reportData) => {
            if (reportData) {
                if (!reportData || reportData.length === 0) {
                    setLoadingState(5);
                }
                setReportData(reportData)
            } else {
                // no data recorded 
            }
        });
    }


    useEffect(() => {
        if (props.schools && props.schools.length > 0 && reportData && reportData.length > 0) {
            

            let schoolDetailObjects = [];
            let totalEnquiries = 0;
            let totalAdmissions = 0;

            // for (const i in props.schools) {
            //     let school = props.schools[i];


            //     let schoolDetailObject = {};

            //     schoolDetailObject.id = school.id;
            //     schoolDetailObject.school = school;

            //     let sessions = reportSessionObjects.filter(
            //         (session) => session.attributes.schoolId === school.id
            //     );

            //     let sessionIds = [];
            //     let sessionObjects = []
            //     for (const t in sessions) {
            //         sessionIds.push(sessions[t].id);

            //         let sobj = {}
            //         sobj.id = sessions[t].id;
            //         sobj.session = sessions[t];
            //         sobj.date = sessions[t].attributes.reportMonth;
            //         sobj.dateSting = sessions[t].attributes.createdMonth;
                    

            //         let rData = [];
            //         let sessionEnquiryCount = 0;
            //         let sessionAdmissionCount = 0;
            //         for (const rd of reportData) {
            //             if (rd.attributes.sessionId === sessions[t].id) {
            //                 rData.push(rd);

            //                 if (rd.attributes.questionId === enquiryQuestion) {
                                

            //                     for (const key in rd.attributes.dimensionResults) {
            //                         sessionEnquiryCount = sessionEnquiryCount + parseInt(rd.attributes.dimensionResults[key]);
            //                     }
            //                 } else if (rd.attributes.questionId === admissionsQuestion) {
                                
            //                     for (const key in rd.attributes.dimensionResults) {
            //                         sessionAdmissionCount = sessionAdmissionCount + parseInt(rd.attributes.dimensionResults[key]);
            //                     }
            //                 }
            //             }
            //         }


                    
            //         sobj.sessionEnquiryCount = sessionEnquiryCount;
            //         sobj.sessionAdmissionCount = sessionAdmissionCount;
                
            //         sobj.sessionReportData = rData;
            //         sessionObjects.push(sobj);
                    
            //     }

            //     schoolDetailObject.sessionObjects = sessionObjects;
                
            //     let schoolData = [];
            //     let enquiryData = [];
            //     let admissionDate = [];
            //     let enquiryCount = 0;
            //     let admissionCount = 0;


            //     for (const rd of reportData) {
            //         if (sessionIds.includes(rd.attributes.sessionId)) {
            //             schoolData.push(rd);
            //             if (rd.attributes.questionId === enquiryQuestion) {
            //                 enquiryData.push(rd);

            //                 for (const key in rd.attributes.dimensionResults) {
            //                     enquiryCount = enquiryCount + parseInt(rd.attributes.dimensionResults[key]);
            //                 }
            //             } else if (rd.attributes.questionId === admissionsQuestion) {
            //                 admissionDate.push(rd);
            //                 for (const key in rd.attributes.dimensionResults) {
            //                     admissionCount = admissionCount + parseInt(rd.attributes.dimensionResults[key]);
            //                 }
            //             }


            //         }
                    
            //     }

            //     schoolDetailObject.enquiryData = enquiryData;
            //     schoolDetailObject.admissionDate = admissionDate;
            //     schoolDetailObject.enquiryCount = enquiryCount;
            //     schoolDetailObject.admissionCount = admissionCount;

                
            //     schoolDetailObject.allRecords = schoolData;
            //     schoolDetailObjects.push(schoolDetailObject);
            // }

            // setConsolidatedReportData(schoolDetailObjects);


            for (const rd of reportData) {
                if (rd.attributes.questionId === enquiryQuestion) {
                    for (const key in rd.attributes.dimensionResults) {
                        totalEnquiries = totalEnquiries + parseInt(rd.attributes.dimensionResults[key]);
                    }
                } else if (rd.attributes.questionId === admissionsQuestion) {
                    for (const key in rd.attributes.dimensionResults) {
                        totalAdmissions = totalAdmissions + parseInt(rd.attributes.dimensionResults[key]);
                    }
                }
                    
            }
            setEnquiryCount(totalEnquiries);
            setAdmissionsCount(totalAdmissions);


            
            let monthsData = [];
            for (const i in selectedMonths) {
                let tempMonth = selectedMonths[i];
                let monthSessions = reportSessionObjects.filter(
                    (session) => sameMonth(session.attributes.reportMonth, selectedMonths[i].date)
                );
                    let eCount = 0;
                    let aCount = 0;
                for (const t in monthSessions) {

                    for (const rd of reportData) {
                        if (rd.attributes.sessionId === monthSessions[t].id) {
                            

                            if (rd.attributes.questionId === enquiryQuestion) {
                                for (const key in rd.attributes.dimensionResults) {
                                    eCount = eCount + parseInt(rd.attributes.dimensionResults[key]);
                                }
                            } else if (rd.attributes.questionId === admissionsQuestion) {
                                
                                for (const key in rd.attributes.dimensionResults) {
                                    aCount = aCount + parseInt(rd.attributes.dimensionResults[key]);
                                }
                            }
                        }
                    }   
                   
                }
                 
                tempMonth.enquiryCount = eCount;
                tempMonth.admissionCount = aCount;
                monthsData.push(tempMonth);

            }
            setSelectedMonths(monthsData);
            let schoolMonth = [];
            let schools = match? props.schools:[props.selectedSchool]
            for (const i in schools) {
                
                let school = schools[i];
                let schoolObj = { id: school.id, schoolName: school.attributes.Name }
                let admissionCount = 0;
                let enquiryCount = 0;

                let preAdCount = 0;
                let preEqCount = 0;

                for (const i in selectedMonths) {
                    let tempMonth = { date: selectedMonths[i].date, dateString: selectedMonths[i].dateString };
                    let monthSessions = reportSessionObjects.filter(
                        (session) => sameMonth(session.attributes.reportMonth, selectedMonths[i].date) && session.attributes.schoolId === school.id
                    );

                    for (const t in monthSessions) {

                        let rData = [];
                        let eCount = 0;
                        let aCount = 0;
                        for (const rd of reportData) {
                            if (rd.attributes.sessionId === monthSessions[t].id) {
                                rData.push(rd);

                                if (rd.attributes.questionId === enquiryQuestion) {
                                    for (const key in rd.attributes.dimensionResults) {
                                        eCount = eCount + parseInt(rd.attributes.dimensionResults[key]);
                                    }
                                } else if (rd.attributes.questionId === admissionsQuestion) {
                                
                                    for (const key in rd.attributes.dimensionResults) {
                                        aCount = aCount + parseInt(rd.attributes.dimensionResults[key]);
                                    }
                                }
                            }
                        }
                        tempMonth.enquiryCount = eCount;
                        tempMonth.admissionCount = aCount;
                        admissionCount = admissionCount + aCount;
                        enquiryCount = enquiryCount + eCount;

                        tempMonth.rateOfAdmissions = getRateOfIncrice(preAdCount, aCount);
                        tempMonth.rateOfEnquiries =getRateOfIncrice(preEqCount,eCount) 


                        preAdCount = aCount;
                        preEqCount = eCount;
                    }

                    

                    schoolObj.admissionCount = admissionCount;
                    schoolObj.enquiryCount = enquiryCount;
                    schoolObj[selectedMonths[i].dateString] = tempMonth;
                }
                schoolMonth.push(schoolObj);
            }
            setMonthSchoolData(schoolMonth);

            setLoadingState(3);

            scroller.scrollTo('scrollPossition', {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100,
            });
        }
    }, [props.schools, reportData]);


    const sameMonth = (d1, d2) => {
        if (d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) {
            return true;
        }
        return false;
    }

    const getConversionRate = (t1, t2) => {
        if (t1 === 0 || t2 === 0) {
            return "-- ";
        }
        let rate = t2 * 100 / t1;
        return rate.toFixed(2) + "%";
    }

    const getRateOfIncrice = (oldNumber, newNumber) => {
        if (oldNumber > 0) {
            var decreaseValue = oldNumber >newNumber? oldNumber -newNumber:newNumber -oldNumber;
            let rate = (decreaseValue / oldNumber) * 100;
            return oldNumber >newNumber ? -1*rate.toFixed(2):rate.toFixed(2);
        } else {
            return undefined;
        } 
    }

    const onClickDownload = () => {
        let sDate = selectedMonths[0].dateString;
        let eDate = selectedMonths[selectedMonths.length - 1].dateString;
            
        let finalData = [];
        let titles =["School Name"]
        let fileName = `consolidated_report_${sDate}_${eDate}.xlsx`;
        let sheetName =  sDate+"-"+eDate;
        
        finalData.push([`Report Type : Enquiry and Admission consolidated report`]);
        finalData.push([`Selected Month : ${sDate} to ${eDate}`,]);

        finalData.push([]);

        finalData.push([`Enquiry Count : ${enquiryCount} `,]);
        finalData.push([`Admission Count : ${admissionsCount} `,]);
        finalData.push([`Conversion Rate : ${getConversionRate(enquiryCount, admissionsCount)}`,]);


        finalData.push([]);
        finalData.push([]);
        finalData.push(["Month wise summary"]);
        finalData.push(["Month"	,"Enquiries",	"Admissions"	,"Conversion"]);
        for (const month of selectedMonths) {
            finalData.push([month.dateString, month.enquiryCount, month.admissionCount, getConversionRate(month.enquiryCount, month.admissionCount)]);
            titles.push(month.dateString);
        }
        finalData.push([]);
        finalData.push([]);

        let finalData2 = [];
        finalData2.push(["School wise summary"]);
        finalData2.push(["School Name",	"Enquiries"	,"Admissions",	"Conversion"]);
        
        for (const school of monthSchoolData) {
            finalData2.push([school.schoolName	,school.enquiryCount,school.admissionCount, getConversionRate(school.enquiryCount, school.admissionCount)]);
        }
        finalData2.push([]);
        finalData2.push([]);

        let enquiryRateList = [];
        let admissionRateList = [];
        for (const school of monthSchoolData) {
            let eRowData = [];
            let aRowData = [];
            eRowData.push(school.schoolName);
            aRowData.push(school.schoolName);
            for (const month of selectedMonths) {

                eRowData.push(rateOfChangeReoprt(school[month.dateString].enquiryCount, school[month.dateString].rateOfEnquiries));
                aRowData.push(rateOfChangeReoprt(school[month.dateString].admissionCount,school[month.dateString].rateOfAdmissions))
            }
            enquiryRateList.push(eRowData);
            admissionRateList.push(aRowData);
        }

        
        let finalData3 = [];
        finalData3.push(["New Enquiries Trend From "+sDate +" to "+ eDate]);
        finalData3.push(titles);
        finalData3 = finalData3.concat(enquiryRateList);
        finalData3.push([]);
        finalData3.push([]);

        let finalData4 = [];
        finalData4.push(["Cumulative Admissions Trend From  "+sDate +" to "+ eDate]);
        finalData4.push(titles);
        finalData4 = finalData4.concat(admissionRateList);

        if (match) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            let ws2 = XLSX.utils.aoa_to_sheet(finalData2);
            let ws3 = XLSX.utils.aoa_to_sheet(finalData3);
            let ws4 = XLSX.utils.aoa_to_sheet(finalData4);
            XLSX.utils.book_append_sheet(wb, ws, "Month wise summary");
            XLSX.utils.book_append_sheet(wb, ws2, "School wise summary");
            XLSX.utils.book_append_sheet(wb, ws3, "New Enquiries Trend");
            XLSX.utils.book_append_sheet(wb, ws4, "Cumulative Admissions Trend");
            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;
            schoolData = schoolData.concat(finalData2);
            schoolData = schoolData.concat(finalData3);
            schoolData = schoolData.concat(finalData4);

            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
            XLSX.writeFile(wb, fileName);
        }

       
    }
    const rateOfChangeReoprt = (aVal,val) => {
        if (val > 0) {
            return aVal + " (" + val + "%↑)";
            
        } else if (val < 0) {
            return aVal +" (" + Math.abs(val) + "%↓)";
        } else {
            return "-";
        }
    }

    const rateOfChangeUI = (aVal,val) => {
        if (val > 0) {
            return (<p>{aVal}<span className="text-success">{ " ("+val +"%↑)"}</span></p>)
        } else if (val < 0) {
            return(<p>{aVal}<span className="text-danger">{" ("+Math.abs(val) +"%↓)"}</span></p>)
            
        } else {
            return(<p>{aVal?aVal:"--"}</p>)
        }
    }
    const onClickBackButton = () => {
        window.history.back();
    }

    return (
        <React.Fragment>

            <div className="page-content">

                {loadingState>0 &&<TopBar
                    title={reportName}
                    buttonName={""}
                    backButton={true}
                    hideButton={true}
                    onClickBackButton={onClickBackButton}
                />}
               {loadingState>0 && <Card>
                    <CardBody>
                        <Col >
                            <Row className="justify-content-center bg-light ml-2">
               
                                <Row>
                                    <Col lg={4} xs={6}>
                                        <FormGroup className="mt-2 mb-2 p-1">
                                            <Label className="mb-0">
                                                {props.t("From")} :
                                            </Label>
                                            <DatePicker
                                                selected={fromDate}
                                                onChange={(date) => {
                                                    setFromDateError(false)
                                                    setFromDate(date)
                                                }}
                                                className="form-control"
                                                placeholderText={props.t("Select Date")}
                                                startDate={fromDate}
                                                endDate={toDate}
                                                maxDate={toDate}
                                                dateFormat="MMM-yyyy"
                                                required
                                                errorMessage="Please select"
                                                showMonthYearPicker
                                            />
                                        </FormGroup>
                                        {fromDateError && <p className="text-danger ml-2">Please select</p>}
                                    </Col>
                                    <Col lg={4} xs={6}>
                                        <FormGroup className="mt-2 mb-2 p-1">
                                            <Label className="mb-0">
                                                {props.t("To")} :
                                            </Label>
                                            <DatePicker
                                                selected={toDate}
                                                onChange={(date) => {
                                                    setToDateError(false);
                                                    setToDate(date)
                                                }}
                                                className="form-control"
                                                placeholderText={props.t("Select Date")}
                                                startDate={fromDate}
                                                endDate={toDate}
                                                minDate={fromDate}
                                                dateFormat="MMM-yyyy"
                                                required
                                                errorMessage="Please select"
                                                showMonthYearPicker
                                            />
                                        </FormGroup>
                                        {toDateError && <p className="text-danger ml-2">Please select</p>}
                                    </Col>
                                    <Col lg={3} >
                                        <div className="mt-4 mb-2 p-1">
                                            <button
                                                disabled ={loadingState===2}
                                                onClick={(e, v) => {
                                                    let next = true;
                                                    if (!toDate) {
                                                        setToDateError(true);
                                                        next = false;
                                                    }
                                                    if (!fromDate) {
                                                        setFromDateError(true);
                                                        next = false;
                                                    }
                                                    if (next) {
                                                        handelDateChange();
                                                    }
                            
                                                }}
                                                className="w-md  btn btn-primary"
                                            >
                                                {props.t("Apply")}
                                            </button>
                      
                                        </div>
                                    </Col>
                                </Row>
                                {/* </Form> */}
                            </Row>
                        </Col>
                    </CardBody>
                </Card>}
                {(loadingState === 0  || loadingState === 2)&&
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState===0?"Setting up...":"Preparing Report"}</Label>
                        </div>
                    </Col>
                }

                 <Element name={"scrollPossition" } >
                            <div></div> 
                </Element>

                {loadingState === 5  &&<Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img mt-8">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{'No data avilable between '+selectedMonths[0].dateString + " and " + selectedMonths[selectedMonths.length - 1].dateString}</h3>
                    <p>{'Plese select other dates '}.</p>
                    
                    
                </CardBody>
            </Card>}

                {
                    loadingState == 3 && selectedMonths && selectedMonths.length > 0 &&
                    <div>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Enquiries vs Admissions  </CardTitle>
                                    <ConsolidatedReportChart selectedMonths={selectedMonths} />
                                </CardBody>
                            </Card>
                           
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Enquiry to Admissions conversion rate </CardTitle>
                                    <RateOfIncreaseChart selectedMonths={selectedMonths} />
                                </CardBody>
                            </Card>
                           
                        </Col>
                        
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Cumulative Enquiries and Admissions </CardTitle>
                                    <CummulativeChart selectedMonths={selectedMonths} />
                                </CardBody>
                            </Card>
                           
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Admissions growth rate </CardTitle>
                                    <ConversionTrend selectedMonths={selectedMonths} />
                                </CardBody>
                            </Card>
                           
                        </Col>
                        
                    </Row>
                   </div>
                }

                {loadingState==3&&selectedMonths && selectedMonths.length > 0 && <Card>
                    <CardBody>
                        <CardHeader>Report 1</CardHeader>
                         <Col lg={12}>
                            <div className="mt-2 mb-2 p-1 d-flex float-sm-right">
                                <button
                                    onClick={() => {
                                        if (selectedMonths && selectedMonths.length > 0) {
                                            onClickDownload();
                                        }
                                                            
                                    }}
                                    className="w-md  btn btn-primary"
                                >
                                    <i className='bx bx-download font-size-16 align-middle mr-2'></i>
                                    {props.t("Download Report")}
                                </button>
                            </div>
                        </Col>
                        <CardTitle>Report Summary </CardTitle>
                        <div className='m-3'>
                            <h6 className="ml-2">
                                {"Selected Monts:" + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}
                            </h6>
                            <h6 className="ml-2">{"Enquiry Count:" + enquiryCount}</h6>
                            <h6 className="ml-2">{"Admission Count:" + admissionsCount}</h6>
                            <h6 className="ml-2">{"Conversion Rate:" + getConversionRate(enquiryCount, admissionsCount)}</h6>    
                        </div>

                        <CardTitle>Month wise summary </CardTitle>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                    <Thead>
                                        <Tr>
                                            <Th>Month</Th>
                                            <Th data-priority="1">Enquiries</Th>
                                            <Th data-priority="3">Admissions</Th>
                                            <Th data-priority="1">Conversion</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                       
                                        {
                                            selectedMonths.map((month, key) => (
                                                <Tr key={key}>
                                                    <Th>{month.dateString}</Th>
                                                    <Td>{month.enquiryCount}</Td>
                                                    <Td>{month.admissionCount}</Td>
                                                    <Td>{getConversionRate(month.enquiryCount, month.admissionCount)}</Td>
                                                    
                                                </Tr>
                                            ))
                                        }
                                                      
                                    </Tbody>
                                </Table>
                            </div>
                        </div>


                        <CardTitle>School wise summary </CardTitle>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                    <Thead>
                                        <Tr>
                                            <Th>School Name</Th>
                                            <Th data-priority="1">Enquiries</Th>
                                            <Th data-priority="3">Admissions</Th>
                                            <Th data-priority="1">Conversion</Th>
                                                           
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                       
                                        {
                                            monthSchoolData.map((school) => (
                                                <Tr key={school.id}>
                                                    <Th>{school.schoolName}</Th>
                                                    <Td>{school.enquiryCount}</Td>
                                                    <Td>{school.admissionCount}</Td>
                                                    <Td>{getConversionRate(school.enquiryCount, school.admissionCount)}</Td>
                                                    
                                                </Tr>
                                            ))
                                        }
                                                      
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
               
                        
                    </CardBody>
                
                </Card>}

                {loadingState==3&&selectedMonths && selectedMonths.length > 0 && <Card>
                    <CardBody>
                        <CardHeader>Report 2</CardHeader>
                        <CardTitle>{"New Enquiries Trend From " + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}</CardTitle>
                        
                         <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                    <Thead>
                                        <Tr>
                                            <Th>School Name</Th>
                                            {
                                                selectedMonths.map((month, key) => (
                                                    <Th data-priority="1" key={key}> {month.dateString} </Th>
                                                ))
                                            }                   
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                       
                                        {
                                            monthSchoolData.map((school) => (
                                                <Tr key={school.id}>
                                                    <Th>{school.schoolName}</Th>

                                                    {selectedMonths.map((month) => (
                                                        <Td key={school.id}>{rateOfChangeUI(school[month.dateString].enquiryCount,school[month.dateString].rateOfEnquiries)}</Td>
                                               
                                                    ))} 
                                                </Tr>
                                            ))
                                        }
                                                      
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>}

                {loadingState==3&&selectedMonths && selectedMonths.length > 0 && <Card>
                    <CardBody>
                        <CardHeader>Report 3</CardHeader>
                        <CardTitle>{"Cumulative Admissions  Trend From " + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}</CardTitle>
                        
                         <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                    <Thead>
                                        <Tr>
                                            <Th>School Name</Th>
                                            {
                                                selectedMonths.map((month, key) => (
                                                    <Th data-priority="1" key={key}> {month.dateString} </Th>
                                                ))
                                            }                   
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                       
                                        {
                                            monthSchoolData.map((school) => (
                                                <Tr key={school.id}>
                                                    <Th>{school.schoolName}</Th>

                                                    {selectedMonths.map((month) => (
                                                        <Td key={school.id}>{rateOfChangeUI(school[month.dateString].admissionCount,school[month.dateString].rateOfAdmissions)}</Td>
                                               
                                                    ))}    
                                                </Tr>
                                            ))
                                        }
                                                      
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>}

                {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && 
                    <div className="mt-2 mb-2 p-1 float-right">
                        <button
                            onClick={() => {
                                if (selectedMonths && selectedMonths.length > 0) {
                                    onClickDownload();
                                }
                                                    
                            }}
                            className="w-md  btn btn-primary"
                    >
                        <i className='bx bx-download font-size-16 align-middle mr-2'></i>
                            {props.t("Download Report")}
                        </button>
                    </div>
                }

                

                <div style={{ height: "400px" }}></div>
            </div>
            
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { schools,selectedSchool } = state.School;
    return { schools, selectedSchool};
}
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
     setSelecetdSchool,
    })(ConsolidatedMonthlyReport)
  )
);
