import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventoryTagsHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryTags_helper";
import * as actionType from "./actionTypes";
import * as InventoryTagsAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getInventoryTagsForOwnerId({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_TAGS_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventoryTagsHelper.getInventoryTagsForOwnerId, ownerId, ownerType, null, true);
        yield put(InventoryTagsAction.updateInventoryTags(localResponse));


        const serverResponse = yield call(InventoryTagsHelper.getInventoryTagsForOwnerId, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventoryTagsAction.updateInventoryTags(serverResponse));


            var nonDeleted = serverResponse.filter(
                (inventoryTag) =>
                inventoryTag.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventoryTag) =>
                inventoryTag.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* deleteInventoryTag({ payload: { inventoryTag } }) {
    try {
        yield put(InventoryTagsAction.updateInventoryTags(inventoryTag));
        const response = yield call(InventoryTagsHelper.deleteInventoryTagFromParse, inventoryTag);
        if (response === "error") {
            inventoryPurchaseOrder.set("isDeleted", undefined);
            yield put(InventoryTagsAction.updateInventoryTags(inventoryTag));
        } else
            if (response) {
                toastr.success("Tag successfully removed", { timeOut: 3000 });
                response.unPin();
            } else {

            }

    } catch (error) {

    }
}

function* addInventoryTag({ payload: { inventoryTag } }) {
    try {
    
      yield put(InventoryTagsAction.updateInventoryTags(inventoryTag));
      const response = yield call(
        InventoryTagsHelper.saveCustomObjects,inventoryTag
      );
      if (response === "Error") {
  
        toastr.error("Please try again", { timeOut: 3000 });
        const local = yield call(
            InventoryTagsHelper.saveCustomObjects,inventoryTag.id
        );
  
        if (local) {
          yield put(InventoryTagsAction.updateInventoryTags(local));
        }
    
      }else 
      if (response) {
        yield put(InventoryTagsAction.updateInventoryTags(response));
        toastr.success("Tags successfully added", { timeOut: 3000 });
        response.pin();
      } else {

      }
    } catch (error) {
 
    }
  }

  export function* watchGetInventoryTagsForOwnerId() {
    yield takeEvery(actionType.GET_INVENTORY_TAGS, getInventoryTagsForOwnerId);
}

export function* watchDeleteInventoryTag() {
    yield takeEvery(actionType.DELETE_INVENTORY_TAG, deleteInventoryTag);
}

export function* watchAddInventoryTag(){
    yield takeEvery(actionType.ADD_INVENTORY_TAG, addInventoryTag);
}

function* InventoryTagSaga() {
    yield all([
        fork(watchGetInventoryTagsForOwnerId),
        fork(watchDeleteInventoryTag),
        fork(watchAddInventoryTag),
    ]);
}
export default InventoryTagSaga;