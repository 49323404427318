import * as actions from "./actionTypes";

const initialState = {
  admissionTitles: [],
};

const AdmissionTitle = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_ADMISSION_TITLES:
      state = { ...state, admissionTitles: [] };
      break
    case actions.UPDATE_ADMISSION_TITLE:
      var admissionTitles = [...state.admissionTitles];
      var temp = state.admissionTitles.filter(
        (admissionTitle) =>
          admissionTitle.id === action.payload.admissionTitle.id
      );
      if (temp.length > 0) {

        if (action.payload.admissionTitle.attributes.isDeleted) {

          var others = state.admissionTitles.filter(
            (admissionTitle) =>
              admissionTitle.id !== action.payload.admissionTitle.id
          );
          state = { ...state, admissionTitles: others };
          
        } else {
          var index = admissionTitles.indexOf(temp[0]);
          admissionTitles[index] = action.payload.admissionTitle;
          state = { ...state, admissionTitles: admissionTitles };
        }

       
      } else {
        if (!action.payload.admissionTitle.attributes.isDeleted) {
          admissionTitles.push(action.payload.admissionTitle);
          state = { ...state, admissionTitles: admissionTitles };
        }
        
      }
      break;
    case actions.UPDATE_ADMISSION_TITLES:
      var admissionTitles = [...state.admissionTitles];
      if (admissionTitles.length > 0) {
        for (const latest in action.payload.admissionTitles) {
          var temp = admissionTitles.filter(
            (admissionTitle) =>
              admissionTitle.id === action.payload.admissionTitles[latest].id
          );

          
          if (temp.length > 0) {
            if (action.payload.admissionTitles[latest].attributes.isDeleted) {

              var others = admissionTitles.filter(
                (admissionTitle) =>
                  admissionTitle.id !== action.payload.admissionTitles[latest].id
              );
             
              admissionTitles = others;
            } else {
              var index = admissionTitles.lastIndexOf(temp[0]);
              admissionTitles[index] = action.payload.admissionTitles[latest];
            }
           
          } else if (!action.payload.admissionTitles[latest].attributes.isDeleted){
            admissionTitles.push(action.payload.admissionTitles[latest]);
          }
        }
        state = { ...state, admissionTitles: admissionTitles };
      } else {

        var others = action.payload.admissionTitles.filter(
          (admissionTitle) =>! admissionTitle.attributes.isDeleted
        );
        state = { ...state, admissionTitles: others };
      }
      break;
    case actions.DELETE_ADMISSION_TITLE:
      var admissionTitles = state.admissionTitles.filter(
        (admissiontitle) => admissiontitle.id !== action.payload.id
      );
      state = { ...state, admissionTitles: admissionTitles };
      break;
      case actions.RELOAD_ADMISSION_TITLES:
        state = { ...state, admissionTitles: action.payload.admissionTitles };
        break
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AdmissionTitle;
