import React, { useState, useEffect } from 'react';
import { Row, Col, CardImg, Container, Label } from "reactstrap";
import { withNamespaces } from 'react-i18next';

// actions


// Redux
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

//Import Images
import avatar2 from "../../assets/images/users/user.png";

import QRCode from 'qrcode'

const IdCardView = (props) => {

    const [idQrCode, setIdQrCode] = useState(undefined);

    useEffect(() => {
        if (props.teacher) {
            idQr(props.teacher.id);
        }
    }, [props.teacher]);



    function idQr(teacherId) {
        QRCode.toDataURL(teacherId, { errorCorrectionLevel: 'H' })
            .then(url => {
                setIdQrCode(url)
            })
            .catch(err => {
                console.error(err)
            })
    }

    function getDesignation(teacher) {
        if (teacher.attributes.designation) {
            return teacher.attributes.designation;
        } else if (teacher.attributes.Role === 3) {
            return props.t('Director');
        } else if (teacher.attributes.Role === 2) {
            return props.t('Center Head');
        } else {
            return props.t('Teacher');
        }
    }

    function formatDate(d) {
        if (!d) {
            return "";
        } else {
            const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
            const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
            const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

            return `${da}/${mo}/${ye}`;
        }
    }


    const getIdCardUi = (teacher) => {
        return (
            <Col className="mt-4">
                <div className="text-center">
                    <center>
                        <div
                            style={{
                                // height: "8.56cm",
                                // width: "5.397cm",
                                height: "920px",
                                width: "630px",

                            }}
                        >

                            <div className='m-2'>
                                {
                                    !props.selectedSchool.attributes.logo
                                        ?
                                        <div>
                                            <CardImg src={avatar2} alt="Skote" className="rounded avatar-lg" height="100" width="100" />
                                        </div>
                                        :
                                        <div>
                                            {props.selectedSchool.attributes.logo && (
                                                <img src={props.selectedSchool.attributes.logo._url}
                                                    className="center-block" alt="logo" height="100" width="100" />)}
                                        </div>
                                }
                            </div>

                            <div className="m-2">

                                {
                                    !teacher.attributes.Photo
                                        ? <div>
                                            <CardImg src={avatar2} alt="Skote" className="rounded avatar-lg center-block" height="100" width="100" />
                                        </div>
                                        : <div>
                                            <CardImg src={teacher.attributes.Photo._url} alt="Skote" className="rounded avatar-lg center-block" height="100" width="100" />
                                        </div>
                                }
                            </div>

                            <div>
                                {
                                    !teacher.attributes.Name
                                        ? <h3></h3>
                                        : <h3 className="p-0">
                                            {teacher.attributes.Name}
                                        </h3>
                                }
                            </div>

                            <div>
                                {
                                    !teacher
                                        ? <h5></h5>
                                        : <h5 className="p-0">
                                            {getDesignation(teacher)}
                                        </h5>
                                }
                            </div>

                            <div>
                                {
                                    !teacher.attributes.empCode
                                        ? <h6></h6>
                                        : <h6 className="p-0">
                                            <span>Employee Code: </span>{(teacher.attributes.empCode)}
                                        </h6>
                                }
                            </div>

                            <div className="p-0">
                                {
                                    !teacher.attributes.doj
                                        ?
                                        <h6></h6>
                                        :
                                        <h6> <span>DOJ: </span>{formatDate(teacher.attributes.doj)}</h6>
                                }
                            </div>

                            <img className="p-0" src={idQrCode} alt="" width="150px" height="150px" />
                            <hr></hr>
                            <h6 className="p-0">If found please return to</h6>

                            <div>
                                {
                                    !props.selectedSchool.attributes.Name
                                        ? <p></p>
                                        : <p className="mb-0">{props.selectedSchool.attributes.Name} <br></br>  {" "} {props.selectedSchool.attributes.Address},
                                            {" "} {props.selectedSchool.attributes.primaryPhoneNumber} {"  "} {props.selectedSchool.attributes.secondaryPhoneNumber},
                                            {" "} {props.selectedSchool.attributes.EMail}</p>
                                }
                            </div>
                        </div>
                    </center>

                </div>

                <div className='bg-white mb-100'>
                </div>
            </Col>


        )
    }

    return (
        <React.Fragment >

            {getIdCardUi(props.teacher)}

        </React.Fragment>
    );
}

const mapStatetoProps = (state) => { return {}; };

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {

        })(IdCardView)
    )
);