export const GET_EXAMS_FOR_SCHOOL_IDS = "GET_EXAMS_FOR_SCHOOL_IDS";

export const UPDATE_EXAMS_FOR_SCHOOL_IDS = "UPDATE_EXAMS_FOR_SCHOOL_IDS";

export const UPDATE_EXAM = "UPDATE_EXAM";

export const SET_LOADING = "SET_LOADING";

export const UPDATE_EXAM_AFTERSAVE = "UPDATE_EXAM_AFTERSAVE";

export const SAVE_EXAM = "SAVE_EXAM";

export const CLEAR_EXAM_AFTERSAVE = "CLEAR_EXAM_AFTERSAVE";
