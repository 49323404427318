import * as actions from './actionTypes';


export const getTripsForSchoolId = (schoolId) => {
    return {
        type: actions.GET_TRIPS_FOR_SCHOOL_ID,
        payload: { schoolId }
    }
}

export const updateTrips = (trips) => {
    return {
        type: actions.UPDATE_TRIPS,
        payload: { trips }
    }
}

export const resetTrip = (trip) => {
    return {
        type: actions.RESET_TRIP,
        payload: { trip }
    }
}


