import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { getSchoolForInstitute, setSelecetdSchool, getCorporatePartners } from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Select from "react-select";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Container

} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';



const StudentExitReport = (props) => {

    const [loadingState, setloadingState] = useState(undefined);

    const [schoolData, setSchoolData] = useState([]);
    const [kidData, setKidData] = useState([]);

    const [selectedStartDate, setSelectedStartDate] = useState();
    const [selectedEndDate, setSelectedEndDate] = useState();

    const [displayTitles, setDisplayTitles] = useState([]);
    const [displayData, setDisplayData] = useState([]);
    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });

    useEffect(() => {
        if (props.schools && props.isFranchise) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);


    useEffect(() => {
        if (Parse.User.current().attributes.franchiseId && props.isFranchise) {

            props.getSchoolForInstitute(Parse.User.current().attributes.franchiseId, "franchiseId");
            if (props.showCorporatePartners) {
                props.getCorporatePartners(Parse.User.current().attributes.franchiseId, "franchiseId")
            }
        }
    }, [Parse.User.current().attributes.franchiseId]);



    const getDateInDDMMYYYY = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };

    const getStudentExitReport = () => {

        setloadingState(0);

        let startDate = new Date(
            selectedStartDate.getFullYear(),
            selectedStartDate.getMonth(),
            selectedStartDate.getDate()
        );
        let endDate = new Date(
            selectedEndDate.getFullYear(),
            selectedEndDate.getMonth(),
            selectedEndDate.getDate(),
            23,
            59,
            59,
            999
        );

        let schoolIds = [];
        let query = new Parse.Query("Kid");
        query.equalTo('Deleted', true);

        if (props.isFranchise) {
            if (selectedSchool && selectedSchool.value != 'allSchools') {
                schoolIds.push(selectedSchool.value)
            } else {
                for (const school of props.schools) {
                    schoolIds.push(school.id)
                }
            }

            query.containedIn('SchoolID', schoolIds)
            query.limit(schoolIds.length * 200);


        } else {
            query.equalTo('SchoolID', props.schoolId)
            query.limit(1000);
        }
        query.greaterThanOrEqualTo("deletedOn", startDate);
        query.lessThanOrEqualTo("deletedOn", endDate);
        query.ascending('Name')
        query.find().then((kids) => {
            // console.log('kids', kids)
            getClassroomData(schoolIds, kids);
        });
    }

    const getClassroomData = (schoolIds, kids) => {
        let query2 = new Parse.Query("ClassRoom");
        if (props.isFranchise) {
            query2.containedIn('SchoolID', schoolIds);
        }else{
            query2.equalTo('SchoolID', props.schoolId)
        }
        
        query2.limit(1000)
        query2.notEqualTo('isDeleted', true);

        query2.find().then((classRoom) => {
            setloadingState(2);
            getExitFeedbackData(kids, classRoom);
        });
    }

    const getExitFeedbackData = (kids, classRoom) => {
        
        let kidIds = []
        let familyIds =[]
        for (const kid of kids) {
            if(kid.attributes.exitFeedbackSubmittedOn){
                kidIds.push(kid.id)

                
            }
            if (!familyIds.includes(kid.attributes.FamilyID)) {
              familyIds.push(kid.attributes.FamilyID);
            }
        }
            // console.log(kidIds)
        let query3 = new Parse.Query("KidExitFeedback");
        query3.containedIn('kidId', kidIds);

        query3.find()
            .then((feedback) => {
                // console.log(feedback)
                // prepareData(kids, classRoom, feedback);

                getParentData(kids, classRoom, feedback,familyIds);
            })
    }

    const getParentData = (kids, classRoom, feedback, familyIds) => {
      let query2 = new Parse.Query("Parent");
      query2.containedIn("FamilyID", familyIds);
      query2.limit(familyIds.length*2+500);
    //   query2.notEqualTo("isDeleted", true);

      query2.find().then((parents) => {
        prepareData(kids, classRoom, feedback,parents);
      });
    };

    const prepareData = (kids, classromms, feedback,parents) => {


        let list = [];
        let list2 = [];



        let finalData = [];
        let title = [];

        title.push("#");
        title.push("School Name");
        title.push("Student Name");
        title.push("Gender");
        title.push("Date of Birth");
        title.push("Admission Number");
        title.push("Class Room");
        { props.showCorporatePartners && title.push("Corporate Name") }
        {props.showCorporatePartners &&title.push("Program Type");}
        {props.showCorporatePartners &&title.push("Fees Type");}
        title.push("Date of joining");
        title.push("Exit Triggered");
        title.push("Exit Reason")
        title.push("Satisfaction with Service");
        title.push("Will Recommend");
        title.push("Feedback")
        title.push("Feedback submitted On")
        title.push("Father Name");
        title.push("Father Email");
        title.push("Father Phone");
        {props.showCorporatePartners&&title.push("Father Corporate EmailId");}
        {props.showCorporatePartners&&title.push("Father EmployeeId");}
        title.push("Mother Name");
        title.push("Mother Email");
        title.push("Mother Phone");
        {props.showCorporatePartners&&title.push("Mother Corporate EmailId");}
        {props.showCorporatePartners&&title.push("Mother EmployeeId");}

        setDisplayTitles(title);

        let schoolObjects = props.schools;
        if (props.isFranchise && selectedSchool && selectedSchool.value != 'allSchools') {
            schoolObjects = props.schools.filter((s) => s.id == selectedSchool.value);
        }

        if(!props.isFranchise && props.schoolId){
            schoolObjects=[props.selectedSchool]
        }

        let count = 0;
        let lKids = [...kids];
        let lParents = [...parents];
        for (const school of schoolObjects) {

            let kidList = [];
            let nlKids = []
            let sKids = [];
            for (const k of lKids) {
                if (k.attributes.SchoolID == school.id) {
                    sKids.push(k);
                } else {
                    nlKids.push(k);
                }
            }

            lKids = [...nlKids];


            let sClass = classromms.filter(
                (ao) => ao.attributes.SchoolID == school.id
            );


            for (const kid of sKids) {
                count++
                let tempKid = {};
                tempKid.id = kid.id;
                tempKid.name = kid.attributes.Name;

                let kClass = sClass.filter(
                    (ao) => ao.id == kid.attributes.ClassRoomID
                );

                let corporate = kid.attributes.corporateId && props.showCorporatePartners && props.corporatePartners.filter(
                    (p) => p.id == kid.attributes.corporateId
                );


                let rfeedback
                for (const fb of feedback) {
                    // console.log(fb.attributes.kidId, ":", kid.id)
                    if (fb.attributes.kidId == kid.id) {
                        rfeedback = fb;
                    }
                }
                // console.log(rfeedback)
                tempKid.schoolName = school.attributes.Name;
                tempKid.className = kClass.length > 0 ? kClass[0].attributes.Name : "";
                tempKid.corporateName = (corporate && corporate.length > 0) ? corporate[0].attributes.name : "";
                tempKid.exitReason = rfeedback && rfeedback.attributes.exitReason;
                tempKid.recommend = rfeedback && rfeedback.attributes.recommend == true ? "Yes" : rfeedback && rfeedback.attributes.recommend == false ? "No" : "";
                tempKid.serviceSatisfaction = rfeedback && rfeedback.attributes.rating  + "/10" ;
                tempKid.feedback = rfeedback && rfeedback.attributes.feedback;

                tempKid.gender = kid.attributes.Gender;
                tempKid.programType = kid.attributes.programType;
                tempKid.feeType = kid.attributes.feeType;
                tempKid.dob = kid.attributes.DateOfBirth && getDateInDDMMYYYY(kid.attributes.DateOfBirth);
                tempKid.doa = getDateInDDMMYYYY(kid.attributes.createdAt);
                tempKid.dod = kid.attributes.deletedOn && getDateInDDMMYYYY(kid.attributes.deletedOn);
                tempKid.admissionNumber = kid.attributes.admissionNumber;
                tempKid.exitFeedbackSubmittedOn = kid.attributes.exitFeedbackSubmittedOn && getDateInDDMMYYYY(kid.attributes.exitFeedbackSubmittedOn)

                let kParents = [];
                let nlParents = [];
                for (const p of parents) {
                  if (p.attributes.FamilyID == kid.attributes.FamilyID) {
                    kParents.push(p);
                  } else {
                    // nlParents.push(p);
                  }
                }

                // lParents = [...nlParents];

                for (const pa of kParents) {
                  if (pa.attributes.Relation == "Father") {
                    tempKid.fatherName = pa.attributes.Name;
                    tempKid.fatherEmail = pa.attributes.EMail;
                    tempKid.fatherPhone = pa.attributes.PhoneNumber;
                    tempKid.fathercorporateEmployeeId =
                      pa.attributes.corporateEmployeeId;
                    tempKid.fatherCorporateEmailId =
                      pa.attributes.corporateEmailId;
                  } else if (pa.attributes.Relation == "Mother") {
                    tempKid.motherName = pa.attributes.Name;
                    tempKid.motherEmail = pa.attributes.EMail;
                    tempKid.motherPhone = pa.attributes.PhoneNumber;
                    tempKid.mothercorporateEmployeeId =
                      pa.attributes.corporateEmployeeId;
                    tempKid.motherCorporateEmailId =
                      pa.attributes.corporateEmailId;
                  }
                }

                let temp = [];
                temp.push(count);
                temp.push(tempKid.schoolName);
                temp.push(tempKid.name);
                temp.push(tempKid.gender);
                temp.push(tempKid.dob);
                temp.push(tempKid.admissionNumber);
                temp.push(tempKid.className);
                {props.showCorporatePartners && temp.push(tempKid.corporateName) }
                {props.showCorporatePartners && temp.push(tempKid.programType);}
                {props.showCorporatePartners && temp.push(tempKid.feeType);}
                temp.push(tempKid.doa);
                temp.push(tempKid.dod);
                temp.push(tempKid.exitReason);
                temp.push(tempKid.serviceSatisfaction);
                temp.push(tempKid.recommend);
                temp.push(tempKid.feedback);
                temp.push(tempKid.exitFeedbackSubmittedOn);
                temp.push(tempKid.fatherName);
                temp.push(tempKid.fatherEmail);
                temp.push(tempKid.fatherPhone);
                { props.showCorporatePartners && temp.push(tempKid.fatherCorporateEmailId); }
                { props.showCorporatePartners && temp.push(tempKid.fathercorporateEmployeeId); }
                temp.push(tempKid.motherName);
                temp.push(tempKid.motherEmail);
                temp.push(tempKid.motherPhone );
                { props.showCorporatePartners && temp.push(tempKid.motherCorporateEmailId); }
                { props.showCorporatePartners && temp.push(tempKid.mothercorporateEmployeeId); }

                finalData.push(temp);

                let tKid = {
                    id: kid.id,
                    school: school,
                    kid: kid,
                    kidData: tempKid
                }

                kidList.push(tKid);
                list.push(tKid);

            }

            let schoolObject = {
                id: school.id,
                school: school,
                kidList: kidList,
            }

            list2.push(schoolObject)

        }

        setDisplayData(finalData);
        setloadingState(3);
        setKidData(list);
        setSchoolData(list2);

    }

    const onClickDownload = () => {

        let finalData3 = []
        let fileName = `student_exit_report.xlsx`;

        finalData3.push(["Student Exit Report"]);                    

        finalData3.push([`Report for: ${props.isFranchise ? selectedSchool.label : props.selectedSchool && props.selectedSchool.attributes.Name}`]);
        finalData3.push(["Total Students:" + displayData.length])
        finalData3.push([]);
        finalData3.push(displayTitles);
        finalData3 = finalData3.concat(displayData)

        let wb = XLSX.utils.book_new();
        let ws3 = XLSX.utils.aoa_to_sheet(finalData3);
        XLSX.utils.book_append_sheet(wb, ws3, "Student Exit Report");
        XLSX.writeFile(wb, fileName);


    }


    return (
        <React.Fragment>

            <Row className="bg-light ">

                {props.isFranchise && <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Selcted school")} :</Label>

                        <Select
                            value={selectedSchool}
                            onChange={(v) => {
                                setSelctedSchool(v)
                                setloadingState(undefined);
                            }}
                            options={allSchools}
                            className="selectPaymentType"
                            classNamePrefix="selectPayType"

                        />
                    </FormGroup>
                </Col>}
                <Col>
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Start Date :</Label>
                        <DatePicker
                            selected={selectedStartDate}
                            onChange={(date) => {
                                setSelectedStartDate(date);
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            // startDate={fromDate}
                            // endDate={toDate}
                            minDate={new Date(2023, 7, 1)}
                            maxDate={selectedEndDate}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                <Col >
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>End Date :</Label>
                        <DatePicker
                            selected={selectedEndDate}
                            onChange={(date) => {
                                setSelectedEndDate(date)
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            // startDate={fromDate}
                            // endDate={toDate}
                            minDate={selectedStartDate}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <div className="mt-2">
                        <div className="mt-3">
                            <button
                                className="btn btn-primary mt-4 mr-2"
                                onClick={getStudentExitReport}
                            >
                                {props.t("Generate")}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "getting report data..." : "Preparing Report"}</Label>
                    </div>
                </Col>
            }


            {loadingState == 3 && schoolData && displayData && <Card>
                <CardBody>

                    <CardTitle>{"Student Exit Data"}</CardTitle>
                    <p>{`Report for: ${props.isFranchise ? selectedSchool.label : props.selectedSchool && props.selectedSchool.attributes.Name}`}</p>

                    <p>{"Total Students:" + displayData.length}</p>

                    <Col lg={12}>
                        <div className="d-flex justify-content-end mt-2 mb-2 p-1">
                            <button
                                onClick={() => {
                                    if (displayData && displayData.length > 0) {
                                        onClickDownload();
                                    }

                                }}
                                className="w-md  btn btn-primary"
                            >
                                <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                {props.t("Download Report")}
                            </button>
                        </div>
                    </Col>

                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table className="table table-striped table-bordered">

                            <Thead>
                                <Tr>
                                    {displayTitles.map((val2, key) => (
                                        <Th key={key}>{val2}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {displayData && displayData.map((cData, key) => (
                                    <Tr key={key}>
                                        {cData.map((val2, key2) => (
                                            <Td key={key2}>{val2}</Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </div>

                </CardBody>
            </Card>}
        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners } = state.CorporatePartners;
    return {
        schools, selectedSchool,
        franchise,
        corporatePartners,
    };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getSchoolForInstitute, setSelecetdSchool, getCorporatePartners
        })(StudentExitReport)
    )
);