import React, { useState, useEffect } from "react";
import {
    getSchool,
    setSelecetdSchool,
} from "../../../store/actions";
import Switch from "react-switch";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";

import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
} from "reactstrap";


//Import Images
import avatar2 from "../../../assets/images/users/user.png";

import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";

const StudentSelectionModal = (props) => {

    var schoolId = props.schoolId;

    useEffect(() => {
        if (props.clickNextButtonProgramatically) {
            props.onButtonClick(selectedKids)
        }
    }, [props.clickNextButtonProgramatically])

    useEffect(() => {
        if (
            schoolId &&
            (!props.selectedSchool || props.selectedSchool.id !== schoolId)
        ) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    //SchoolId is schoolId
    const [classroomList, setClassroomList] = useState([]);

    const [selectedKids, setSelectedKids] = useState(props.selectedKids ?? []);
    const [searchBarString, setSeachBarString] = useState("");
    const [selectedClassroomFilter, setSelectedClassroomFilter] = useState(
        undefined
    );
    const [isPickAllSelected, setIsPickAllSelected] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [masterKidList, setMasterKidList] = useState([]);

    useEffect(() => {
        if (props.kids) {
            let tempList = []
            tempList.push.apply(tempList, props.kids)
            setMasterKidList(tempList)
        }
    }, [props.kids])

    useEffect(() => {
        if (props.classrooms && props.classrooms.length > 0) {
            var tempArray = [...props.classrooms];
            const ClassroomObject = Parse.Object.extend("ClassRoom");
            var tempClassroomObject = new ClassroomObject();
            tempClassroomObject.set("id", "All Classrooms");
            tempClassroomObject.set("Name", "All Classrooms");
            tempArray.unshift(tempClassroomObject);
            setSelectedClassroomFilter(tempClassroomObject);


            const AdmissionClass = Parse.Object.extend("ClassRoom");
            var tempClassroomObject = new AdmissionClass();
            tempClassroomObject.set("id", "Admission");
            tempClassroomObject.set("Name", "Admission");
            tempArray.push(tempClassroomObject);



            setClassroomList(tempArray);
        }
    }, [props.classrooms]);


    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }


    function handleSelectAll() {
        let tempList = [...getAllKidsFilteredList()];
        if (tempList.length > 0) {
            tempList.shift();
            if (tempList.length > 0) {
                let oldList = [...selectedKids];
                let temp = oldList.concat(tempList);
                setSelectedKids(temp);
            }
        }
    }
    const handleCheckboxChangeForSelected = (kObject) => {
        var tempSelectedList = [...selectedKids];
        removeElement(tempSelectedList, kObject);
        setSelectedKids(tempSelectedList);
    };




    const handleClassroomFilterChange = (value) => {
        var classrooms = classroomList.filter(
            (classroom) => classroom.id === value
        );

        if (classrooms.length > 0) {
            setSelectedClassroomFilter(classrooms[0]);
        }
    };

    const getAllKidsFilteredList = () => {
        var listToReturn = [];
        if (selectedClassroomFilter != undefined) {
            if (selectedClassroomFilter.id === "All Classrooms") {
                listToReturn = [...masterKidList];
            } else if (selectedClassroomFilter.id === "Admission") {
                var i = 0;
                for (i = 0; i < masterKidList.length; i++) {
                    if (
                        masterKidList[i].attributes.SchoolID == undefined || masterKidList[i].attributes.SchoolID.length === 0
                    ) {
                        listToReturn.push(masterKidList[i]);
                    }
                }
            }
            else {
                var i = 0;
                for (i = 0; i < masterKidList.length; i++) {
                    if (masterKidList[i].attributes.ClassRoomID === selectedClassroomFilter.id) {
                        listToReturn.push(masterKidList[i]);
                    } else if (selectedClassroomFilter.attributes.classroomType == 2 &&
                        masterKidList[i].attributes.daycareClassId === selectedClassroomFilter.id
                    ) {
                        listToReturn.push(masterKidList[i]);
                    }
                }
            }
        }
        if (searchBarString === "") {
        } else {
            listToReturn = listToReturn.filter((object) =>
                object.attributes.Name.toLowerCase().includes(
                    searchBarString.toLowerCase()
                )
            );
        }
        if (
            listToReturn != null &&
            listToReturn.length > 0 &&
            listToReturn[0].attributes.Name !== "Pick All"
        ) {
            const KidObjectParse = Parse.Object.extend("Kid");
            var kidObjectParse = new KidObjectParse();
            kidObjectParse.set("Name", "Pick All");
            listToReturn.unshift(kidObjectParse);
        }
        return listToReturn;
    };



    const handleSearchBarTextChange = (value) => {
        setSeachBarString(value);
    };

    const getKidPhoto = (kObject) => {
        var kidPhoto = "";
        kidPhoto =
            kObject.attributes.Photo != undefined
                ? kObject.attributes.Photo._url
                : avatar2;
        return kidPhoto;
    };

    const handleCheckboxChange = (kObject) => {
        if (kObject.attributes.Name === "Pick All") {
            if (isPickAllSelected) {
                setSelectedKids([])
            } else {
                handleSelectAll();
            }
            setIsPickAllSelected(!isPickAllSelected)
        } else {
            var tempSelectedList = [...selectedKids];
            tempSelectedList.push(kObject);
            setSelectedKids(tempSelectedList);
        }
    };

    const getTermsForKidId = (termsList, kidId) => {
        let returnValue = []
        for (const i in termsList) {
            if (termsList[i].attributes.kidIds && termsList[i].attributes.kidIds.includes(kidId)) {
                returnValue.push(termsList[i].attributes.termName)
            }
        }
        return returnValue.length === 0 ? "" : returnValue.join(", ")
    }

    const getClassroom = (kid) => {
        let returnValue = ""
        if (kid && kid.attributes) {

            if ((kid.attributes.SchoolID == undefined || kid.attributes.SchoolID.length === 0) && kid.attributes.Name !== "Pick All") {
                returnValue = "(Admission)"
            } else {
                let classDec = []
                for (let i in props.classrooms) {
                    classDec[props.classrooms[i].id] = props.classrooms[i]
                }
                let primaryClass = classDec[kid.attributes.ClassRoomID]
                if (primaryClass && primaryClass.attributes) {
                    returnValue = `(${primaryClass.attributes.Name})` ?? ""
                }
            }



            return returnValue

        }
    }

    return (
        <React.Fragment>
            <Container fluid={true}>

                {(!isLoading) && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Label className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading List...{" "}
                                </Label>
                            </div>
                        </Col>
                    </Row>
                )}

                {(isLoading) && (
                    <Row>
                        <Col lg={12} className="mx-auto">
                            <Card>
                                <CardBody>


                                    <Row lg={12}>
                                        <Col lg={12}>
                                            <Row>
                                                <Col lg={6}>
                                                    <FormGroup>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                handleClassroomFilterChange(e.target.value);
                                                            }}
                                                        >
                                                            {classroomList.map((classroomObject, key) => (
                                                                <option key={key} value={classroomObject.id}>
                                                                    {classroomObject.attributes.Name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={5}>
                                                    <Input
                                                        type="text"
                                                        name="searchAG"
                                                        className="inner form-control mb-3"
                                                        placeholder="Search"
                                                        onChange={(e) => {
                                                            handleSearchBarTextChange(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                                <Col lg={1}>
                                                <div className="d-flex justify-content-end">
                                                    <button className="btn btn-primary" onClick={() => {
                                                        props.onButtonClick(selectedKids)
                                                    }}>
                                                        Next
                                                    </button>
                                                </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <div className="table-responsive">
                                                    <table className="table table-nowrap table-centered mb-0">
                                                        <tbody>
                                                            {getAllKidsFilteredList().length === 0 && (
                                                                <div className="ml-3 mt-3">
                                                                    <p>No Student found</p>
                                                                    <hr />
                                                                </div>
                                                            )}

                                                            {getAllKidsFilteredList().map(
                                                                (kObject, keyInside) => (
                                                                    <tr key={keyInside}>
                                                                        <td style={{ width: "60px" }}>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={"customCheck12_" + keyInside}
                                                                                onChange={() => {
                                                                                    if (selectedKids.includes(kObject)) {
                                                                                        handleCheckboxChangeForSelected(kObject)
                                                                                    } else {
                                                                                        handleCheckboxChange(kObject);
                                                                                    }
                                                                                }}
                                                                                checked={selectedKids.includes(kObject) || (kObject.attributes.Name === "Pick All" && isPickAllSelected)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <div className="d-flex mt-0">
                                                                                {kObject.attributes.Name !==
                                                                                    "Pick All" && (
                                                                                        <div className="my-auto align-middle">
                                                                                            <img
                                                                                                src={getKidPhoto(kObject)}
                                                                                                className="rounded-circle avatar-xs "
                                                                                                alt=""
                                                                                            />
                                                                                        </div>
                                                                                    )}
                                                                                <div className="float-right m-2 my-auto">
                                                                                    <div>
                                                                                        <Label className="text-dark mt-0 mb-0">
                                                                                            {kObject.attributes.Name} {getClassroom(kObject)}
                                                                                        </Label><br />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </td>
                                                                        <td></td>
                                                                        <td></td>
                                                                    </tr>
                                                                )
                                                            )}

                                                        </tbody>
                                                    </table>
                                                    <hr />


                                                </div>
                                            </Row>
                                        </Col>

                                    </Row>
                                    <div className="d-flex justify-content-end">
                                        <button className="btn btn-primary float-right" onClick={() => {
                                            props.onButtonClick(selectedKids)
                                        }}>
                                            Next
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {

    const { selectedSchool } = state.School;

    return {
        selectedSchool,
    };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            getSchool,
        })(StudentSelectionModal)
    )
);
