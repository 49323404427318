import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Label, Table, Card, CardBody } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Switch from "react-switch";
import Parse from 'parse';
import {
    getLearningCurriculumSchoolMapForCurriculumId,
    saveLearningCurriculumSchoolMap,
    setSelecetdSchool,
    getAcademicCycleForCurriculumId,
    setSelectedAcademicCycle
} from '../../../store/actions';

const franchiseCurriculumAccess = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/syllabus-access/:franchiseId/:curriculumId/:cycleId",
        exact: true,
        strict: false,
    });

    const curriculumId = match.params.curriculumId;
    const franchiseId = match.params.franchiseId;
    const cycleId = match.params.cycleId;

    const [upadteSwitchData, setUpdateSwitchdata] = useState()

    useEffect(() => {
        if (curriculumId) {
            props.getLearningCurriculumSchoolMapForCurriculumId(curriculumId);
        }
        props.getAcademicCycleForCurriculumId(curriculumId);
        props.setSelectedAcademicCycle(cycleId);
    }, [curriculumId, cycleId]);


    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                <small>No</small>
            </div>
        );
    };

    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                <small>Yes</small>
            </div>
        );
    };

    const getSchoolName = (schoolId) => {
        if (props.schools && props.schools.length > 0) {
            let temp = props.schools.filter(
                (school) => school.id === schoolId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    // console.log(props.selectedAcademicCycle)
    return (
        <div className='page-content'>
            <Row>
                <Col className="d-flex align-items-start">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => { window.history.back() }}>
                        <ul className=" list-unstyled">
                            <div className=''>
                                <i className="bx bx-chevron-left h1 text-primary"></i>
                            </div>
                        </ul>
                    </div>
                    <div className=''>
                        <h4 className="mt-2">{props.selectedAcademicCycle && props.selectedAcademicCycle.attributes.schoolCycle} Syllabus Access</h4>
                    </div>
                </Col>
            </Row>
            <Card>
                <CardBody>
                    {props.learningCurriculumsSchoolMapForCurriculumId.length > 0 ? <Table className="table-centered table-nowrap table-hover ">
                        <thead className="thead-light" style={{ height: "0" }}>
                            <tr>
                                <th scope="col">Schools</th>
                                <th scope="col">Permission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {props.learningCurriculumsSchoolMapForCurriculumId.map((lc, key) => (
                                <tr key={key}>
                                    <td>
                                        <Label>{getSchoolName(lc.attributes.schoolId)}</Label>
                                    </td>
                                    <td>
                                        <Switch
                                            height={25}
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={(isChecked) => {
                                                console.log(isChecked);
                                                const updatedData = props.learningCurriculumsSchoolMapForCurriculumId.map((item) => {
                                                    if (item.attributes.schoolId === lc.attributes.schoolId && item.attributes.curriculumId === lc.attributes.curriculumId) {
                                                        const academicCycleIds = item.attributes.academicCycleIds || [];
                                                        const updatedAcademicCycleIds = [...academicCycleIds];

                                                        if (isChecked && !updatedAcademicCycleIds.includes(cycleId)) {
                                                            updatedAcademicCycleIds.push(cycleId);
                                                        } else if (!isChecked && updatedAcademicCycleIds.includes(cycleId)) {
                                                            const index = updatedAcademicCycleIds.indexOf(cycleId);
                                                            updatedAcademicCycleIds.splice(index, 1);
                                                        }

                                                        item.set('academicCycleIds', updatedAcademicCycleIds);

                                                        return item;
                                                    }

                                                    return item;
                                                });

                                                console.log(updatedData);
                                                setUpdateSwitchdata(updatedData)
                                                Parse.Object.saveAll(updatedData)
                                                    .then((savedObjects) => {
                                                        console.log('Updated data:', savedObjects);
                                                    })
                                                    .catch((error) => {
                                                        console.error('Error saving updated data:', error);
                                                    });
                                            }}
                                            checked={upadteSwitchData && upadteSwitchData.includes(cycleId) || lc.attributes.academicCycleIds && lc.attributes.academicCycleIds.includes(cycleId)}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>

                    </Table> : <h4 className='text-center'>Please provide curriculum access to school!</h4>}
                </CardBody>
            </Card>
        </div>
    )
}

const mapStatetoProps = state => {
    const { learningCurriculumsSchoolMapForCurriculumId } = state.LearningCurriculumsSchoolMap;
    const { selectedSchool, schools } = state.School;
    const { curriculumAcademicCycles, selectedAcademicCycle } = state.AcademicCycles;

    return { learningCurriculumsSchoolMapForCurriculumId, selectedSchool, schools, curriculumAcademicCycles, selectedAcademicCycle };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    getLearningCurriculumSchoolMapForCurriculumId,
    saveLearningCurriculumSchoolMap,
    setSelecetdSchool,
    getAcademicCycleForCurriculumId,
    setSelectedAcademicCycle
})(franchiseCurriculumAccess)));