export const ADD_OR_UPDATE_PARENT = 'ADD_OR_UPDATE_PARENT';
export const UPDATE_PARENTS = "UPDATE_PARENTS";

export const GET_PARENTS = "GET_PARENTS";

export const DELETE_PARENT = 'DELETE_PARENT';

export const PARENT_SUCCESS = 'PARENT_SUCCESS';
export const PARENT_ERROR = 'PARENT_ERROR';
export const UPDATE_A_PARENT = 'UPDATE_A_PARENT';

export const GET_PARENTS_FOR_FAMILY_IDS = "GET_PARENTS_FOR_FAMILY_IDS";

export const ADD_PARENTS_TO_DB = "ADD_PARENTS_TO_DB";

export const GET_PARENTS_FOR_FAMILY_ID = "GET_PARENTS_FOR_FAMILY_ID";
export const SET_SELECTED_PARENTS = "SET_SELECTED_PARENTS";



