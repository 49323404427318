import Parse from "parse";

export const getExamsForSchoolId = (schoolId) => {

  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("Examination");

      query.equalTo("schoolId", schoolId);
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (results) => {
          resolve(results);
          //   console.log(results)
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {

  }
};

export const getExamForExamId = (examId) => {
  try {
    return new Promise((resolve, reject) => {
      var Examination = Parse.Object.extend("Examination");
      var query = new Parse.Query(Examination);
      query.equalTo("objectId", examId);
      query.first().then(
        (result) => {
          resolve(result);
          // console.log(result)
        },
        (error) => {
          reject(null);
          console.log(error)
        }
      );
    });
  } catch (error) { }
};

export const deleteExamFromParse = (exam) => {
  try {
    return new Promise((resolve, reject) => {
      exam.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        exam.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        exam.set("deletedBy", Parse.User.current().id);
      }

      exam.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("error");
        }
      );
    });
  } catch (error) { }
};

export const saveMarksCard = (exam) => {
  try {
    return new Promise((resolve, reject) => {
      exam.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};