import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as ExamHelper from "../../../../helpers/ParseHelpers/MarksCard/examination_helper";
import * as actionTypes from "./actionTypes";
import * as ExamAction from "./actions";

function* getExamsForSchoolId({ payload: { schoolId } }) {
  try {
    const response = yield call(ExamHelper.getExamsForSchoolId, schoolId);

    if (response) {
      yield put(ExamAction.updateExamsForSchoolId(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getExamForExamId({ payload: { examId } }) {
  try {
    const response = yield call(ExamHelper.getExamForExamId, examId);

    if (response) {
      yield put(ExamAction.updateSelectedExam(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deleteExam({ payload: { exam } }) {
  try {
    yield put(ExamAction.updateDeleteExam(exam));
    const response = yield call(ExamHelper.deleteExamFromParse, exam);
    if (response === "error") {
      exam.set("isDeleted", undefined);
      yield put(ExamAction.updateDeleteExam(exam));
    } else
      if (response) {
        toastr.success("Exam Removed successfully", { timeOut: 3000 });
        response.unPin();
      } else {

      }

  } catch (error) {

  }
}

function* saveMarksCard({ payload: { exam } }) {
  try {
      const response = yield call(ExamHelper.saveMarksCard, exam);
      if (response === "Error") {
          toastr.error("Please try again", { timeOut: 2000 });
      } else
          if (response) {
              yield put(ExamAction.saveMarksCard(response));
              response.pin();
              toastr.success("Result Published successfully", { timeOut: 2000 });
          } else {
          }
  } catch (error) {

  }
}

export function* watchGetExamsForSchoolId() {
  yield takeEvery(
    actionTypes.GET_EXAMS_FOR_SCHOOL_ID,
    getExamsForSchoolId
  );
}

export function* watchGetExamForExamId() {
  yield takeEvery(
    actionTypes.GET_EXAM_FOR_EXAM_ID,
    getExamForExamId
  );
}

export function* watchDeleteExam() {
  yield takeEvery(
    actionTypes.DELETE_EXAM,
    deleteExam
  );
}

export function* watchSaveMarksCard() {
  yield takeEvery(
    actionTypes.SAVE_MARKS_CARD,
    saveMarksCard
  );
}


function* examSaga() {
  yield all([
    fork(watchGetExamsForSchoolId),
    fork(watchGetExamForExamId),
    fork(watchDeleteExam),
    fork(watchSaveMarksCard),
  ]);

}
export default examSaga;
