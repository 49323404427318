import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";


const initialState = {
  inventoryPurchaseOrders: [], inventoryPurchaseOrderAddLoading: false,
  selectedInventoryPurchaseOrder: undefined,
};

const InventoryPurchaseOrders = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_INVENTORY_PURCHASE_ORDERS:

      var inventoryPurchaseOrders = [...state.inventoryPurchaseOrders];
      if (inventoryPurchaseOrders.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.inventoryPurchaseOrders], action.payload.inventoryPurchaseOrders, "isDeleted")

        state = { ...state, inventoryPurchaseOrders: updatedList };
      } else {
        var i = 0
        var inventoryPurchaseOrdersToSave = []
        for (i = 0; i < action.payload.inventoryPurchaseOrders.length; i++) {
          if (action.payload.inventoryPurchaseOrders[i].attributes.isDeleted !== true) {
            inventoryPurchaseOrdersToSave.push(action.payload.inventoryPurchaseOrders[i])
          }
        }
        state = { ...state, inventoryPurchaseOrders: inventoryPurchaseOrdersToSave };
      }
      break;

     case actions.SET_SELECTED_PURCHASE_ORDER:
            state = { ...state, selectedInventoryPurchaseOrder: undefined };
            break;

        case actions.UPDATE_SELECTED_PURCHASE_ORDER:
            state = { ...state, selectedInventoryPurchaseOrder: action.payload.inventoryPurchaseOrder };
            break;

    case actions.UPDATE_DELETE_PURCHASE_ORDER:
      var inventoryPurchaseOrders = state.inventoryPurchaseOrders.filter(
        (inventoryPurchaseOrder) => inventoryPurchaseOrder.id !== action.payload.inventoryPurchaseOrder.id
      );
      state = { ...state, inventoryPurchaseOrders: inventoryPurchaseOrders };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default InventoryPurchaseOrders;
