
import React, { useState, useEffect } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Card, CardBody, Table, Col, Button, Dropdown, DropdownMenu, DropdownToggle, DropdownItem, Badge } from "reactstrap";
import { getFranchise, getFranchiseEnquiryForFranchiseId, saveFranchiseEnquiry, editEnquiryNote } from '../../../store/actions';
import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Moment from "moment";

const FranchiseBranchEnquiryList = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/franchise-enquiry/:franchiseId",
        exact: true,
        strict: false,
    });

    const franchiseId = match.params.franchiseId;
    const [toggleId, setToggleId] = useState(undefined);
    const [nameOrder, setNameOrder] = useState(undefined);
    const [dateOrder, setDateOrder] = useState(undefined);
    const [search, setsearch] = useState(false);
    const [searchString, setSearchString] = useState("");


    const EnquiryStates = [
        { value: 0, label: "Enquired" },
        { value: 2, label: "Evaluated" },
        { value: 3, label: "Consulted" },
        { value: 4, label: "Waitlisted" },
        { value: 5, label: "Rejected" },
        { value: 1, label: "Converted" },
        { value: -1, label: "Lost" },
    ];

    useEffect(() => {
        if (Parse.User && Parse.User.current() && Parse.User.current().attributes.franchiseId) {
            props.getFranchise(Parse.User.current().attributes.franchiseId);
        } else {
            props.history.push("/pages-404");
        }
        props.getFranchiseEnquiryForFranchiseId(franchiseId);
    }, [franchiseId]);

    // console.log(props.franchiseEnquiry);

    const getStatusLabel = (status) => {
        return EnquiryStates.find(state => state.value === status)?.label || "Select Status";
    };

    const getStatueColor = (status) => {
        switch (status) {
            case 0: return "primary";
            case 1: return "info";
            case 2: return "warning";
            case 3: return "success";
            case 4: return "light";
            case 5: return "danger";
            case -1: return "dark";
            default: return "secondary";
        }
    };

    const getStatusState = (status) => {
        switch (status) {
            case 0: return "Enquired";
            case 1: return "Converted";
            case 2: return "Evaluated";
            case 3: return "Consulted";
            case 4: return "Waitlisted";
            case 5: return "Rejected";
            case -1: return "Lost";
            default: return "Enquired";
        }
    };

    const saveStatusChange = (enquiry, status, note, lostReasons) => {
        enquiry.set("status", status);
        if (status === -1 && lostReasons && lostReasons.length > 0) {
            enquiry.set("lostReasons", lostReasons);
        }

        props.saveFranchiseEnquiry(enquiry);

        const EnquiryNote = Parse.Object.extend("EnquiryNotes");
        const noteObject = new EnquiryNote();

        noteObject.set("enquiryId", enquiry.id);
        noteObject.set("franchiseId", franchiseId);
        noteObject.set("noteMessage", note);
        noteObject.set("systemLog", true);

        props.editEnquiryNote(noteObject);
    };

    const handleStatusChange = (status, enquiry) => {
        var note = getStatusState(enquiry.attributes.status) + " to " + getStatusState(status);
        saveStatusChange(enquiry, status, note);
    };

    const DropdownItemWithCheckmark = ({ state, isSelected, onClick }) => {
        return (
            <DropdownItem key={state.value} onClick={onClick}>
                {state.label}
                {isSelected && <span> ✓</span>}
            </DropdownItem>
        );
    };

    const handelSortBy = (type) => {
        if (type === "name") {
            setNameOrder(nameOrder === "ascending" ? "descending" : "ascending");
        } else if (type === "date") {
            setDateOrder(dateOrder === "ascending" ? "descending" : "ascending");
        }
    };

    function formatDate(d) {
        if (d === "") {
            return "";
        } else {
            const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
            const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
            const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

            // return `${da}/${mo}/${ye}`;

            return Moment(d).format("DD/MM/YYYY");
        }
    }

    function getDays(d) {
        if (d === "") {
            return "";
        } else {
            let today = new Date();
            let diffTime = today - d;
            let diffDays = diffTime / (1000 * 3600 * 24);
            let diffDaysInt = parseInt(diffDays + "")

            if (diffDaysInt == 0) {
                if (today.getDate() == d.getDate()) {
                    return "Today"
                }
                return "1 day old"
            } else if (diffDaysInt == 1) {
                return "1 day old "
            } else if (diffDaysInt > 0) {
                return diffDaysInt + " day's old "
            } else {
                return "";
            }

        }
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }

    function getTimeStringForTime(time) {
        return Moment(time).format('h:mm A');
    }

    const sortBy = (type) => {
        let sortedEnquiry = [...props.franchiseEnquiry];
        if (type === "name") {
            sortedEnquiry.sort((a, b) => {
                const nameA = a.attributes.name.toUpperCase();
                const nameB = b.attributes.name.toUpperCase();

                if (nameA < nameB) {
                    return nameOrder === "ascending" ? -1 : 1;
                }
                if (nameA > nameB) {
                    return nameOrder === "ascending" ? 1 : -1;
                }
                return 0;
            });
        } else if (type === "date") {
            sortedEnquiry.sort((a, b) => {
                const dateA = new Date(a.attributes.createdAt);
                const dateB = new Date(b.attributes.createdAt);

                if (dateA < dateB) {
                    return dateOrder === "ascending" ? -1 : 1;
                }
                if (dateA > dateB) {
                    return dateOrder === "ascending" ? 1 : -1;
                }
                return 0;
            });
        }

        return sortedEnquiry;
    };

    const filteredEnquiries = sortBy(nameOrder ? "name" : "date").filter((enquiry) => {
        const query = searchString.toLowerCase();
        const name = enquiry.attributes.name.toLowerCase();
        const email = enquiry.attributes.email.toLowerCase();
        const phoneNumber = enquiry.attributes.phoneNumber.toLowerCase();
        const city = enquiry.attributes.city.toLowerCase();
        const status = getStatusLabel(enquiry.attributes.status).toLowerCase();
        const date = getTimeStringForDate(enquiry.attributes.createdAt).toLowerCase();

        return name.includes(query) || email.includes(query) || phoneNumber.includes(query) || city.includes(query) || status.includes(query) || date.includes(query);
    });


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={6}>
                            <div>
                                <h2 className="font-size-18">ENQUIRY MANAGEMENT</h2>
                            </div>
                        </Col>

                        <Col lg={6}>
                            <div className="d-flex justify-content-end align-items-center">
                                <div className="app-search me-3 mt-0 mb-0">
                                    <div className="position-relative">
                                        <input
                                            type="text"
                                            className="form-control"
                                            placeholder={props.t("Search") + "..."}
                                            onChange={(e) => {
                                                setSearchString(e.target.value)
                                            }}
                                        />
                                        <span className="bx bx-search-alt position-absolute" style={{ marginRight: '20px', marginTop: '20px', transform: 'translateY(-50%)' }}></span>
                                    </div>
                                </div>
                                <Link className="btn btn-primary me-2" target="_blank" rel="noopener noreferrer" to={"/franchiseposter/" + franchiseId}>
                                    Poster
                                </Link>
                                <Link to={"/franchise-add-enquiry/" + franchiseId}>
                                    <Button type="button" color="primary">
                                        {props.t('Add Enquiry')}
                                    </Button>
                                </Link>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col>
                            <Card>
                                <CardBody>
                                    <div className="table-responsive">
                                        <Table className="table-centered table-nowrap table-hover">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th scope="col" style={{ width: "0.5rem", cursor: "pointer" }} onClick={() => {
                                                        const newOrder = nameOrder === "ascending" ? "descending" : "ascending";
                                                        handelSortBy("name", newOrder);
                                                    }}>
                                                        {props.t('Name')}
                                                        <i className={"mdi mdi-18px mdi-sort-" + (nameOrder === "ascending" ? "alphabetical-ascending" : "alphabetical-descending") + " mr-1"}></i>
                                                    </th>
                                                    <th scope="col" style={{ width: "0.5rem", cursor: "pointer" }} onClick={() => {
                                                        const newOrder = dateOrder === "ascending" ? "descending" : "ascending";
                                                        handelSortBy("date", newOrder);
                                                    }}>
                                                        {props.t('Date')}
                                                        <i className={"mdi mdi-18px mdi-sort-" + (dateOrder === "ascending" ? "numeric-ascending" : "numeric-descending") + " mr-1"}></i>
                                                    </th>
                                                    <th scope="col"><div>{props.t('Email')}</div></th>
                                                    <th scope="col"><div>{props.t('Phone Number')}</div></th>
                                                    <th scope="col"><div>{props.t('City')}</div></th>
                                                    <th scope="col"><div>{props.t('Status')}</div></th>
                                                    <th scope="col"><div>{props.t('Expected date of start')}</div></th>
                                                    <th scope="col"><div>{props.t('View Details')}</div></th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {filteredEnquiries.length === 0 && (
                                                    <tr>
                                                        <td colSpan="10" className="pt-4">
                                                            <h3 className="text-center text-info">
                                                                {props.t("No Enquiries found .")}
                                                            </h3>
                                                        </td>
                                                    </tr>
                                                )}
                                                {filteredEnquiries.map((enquiry, index) => (
                                                    <tr key={index}>
                                                        <td>{enquiry.attributes.name}</td>
                                                        <td>{getTimeStringForDate(enquiry.attributes.createdAt)} <br />
                                                            {getTimeStringForTime(enquiry.attributes.createdAt)} <br />
                                                            {getDays(enquiry.attributes.createdAt)}
                                                        </td>
                                                        <td>{enquiry.attributes.email}</td>
                                                        <td>{enquiry.attributes.phoneNumber}</td>
                                                        <td>{enquiry.attributes.city}</td>
                                                        <td>
                                                            <Dropdown isOpen={toggleId === enquiry.id} toggle={() => setToggleId(toggleId === enquiry.id ? undefined : enquiry.id)}>
                                                                <DropdownToggle className="bg-white border-0" caret={false}>
                                                                    <Badge className={"font-size-12 badge-soft-" + getStatueColor(enquiry.attributes.status)} color="success" pill>
                                                                        {getStatusLabel(enquiry.attributes.status)}
                                                                        <i className="mdi mdi-chevron-down"></i>
                                                                    </Badge>
                                                                </DropdownToggle>
                                                                <DropdownMenu>
                                                                    {EnquiryStates.map((state, i) => (
                                                                        <DropdownItemWithCheckmark key={i} state={state} isSelected={state.value === enquiry.attributes.status} onClick={() => handleStatusChange(state.value, enquiry)} />
                                                                    ))}
                                                                </DropdownMenu>
                                                            </Dropdown>
                                                        </td>
                                                        <td>{enquiry.attributes.expectedDateOfStart ? formatDate(enquiry.attributes.expectedDateOfStart.iso || enquiry.attributes.expectedDateOfStart) : ""}</td>
                                                        {/* <td>{formatDate(enquiry.attributes.expectedDateOfStart)}</td> */}

                                                        <td>
                                                            <Link to={"/franchiseprofile/" + enquiry.attributes.franchiseId + "/" + enquiry.id}>
                                                                <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">
                                                                    {props.t("View Details")}
                                                                </Button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </Table>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { franchise } = state.Franchise;
    const { franchiseEnquiry, } = state.Enquiry;
    return { franchise, franchiseEnquiry };
};



export default withNamespaces()(withRouter(connect(mapStatetoProps, { getFranchise, getFranchiseEnquiryForFranchiseId, saveFranchiseEnquiry, editEnquiryNote })(FranchiseBranchEnquiryList)));

