import * as actions from "./actionTypes";

const initialState = {
  exams: undefined,
  isLoadingExam: true,
  examAfterSave: undefined,
};

const Exam = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_LOADING:
      state = { ...state, isLoadingExam: action.payload.boolValue };
      break;

    case actions.UPDATE_EXAM_AFTERSAVE:
      state = { ...state, examAfterSave: action.payload.examAfterSave };
      break;

    case actions.UPDATE_EXAMS_FOR_SCHOOL_IDS:
      var exams = [];
      if (state.exams) {
        exams = [...state.exams];
      }

      if (exams.length > 0) {
        for (const latest in action.payload.exams) {
          var temp = exams.filter(
            (exam) => exam.id === action.payload.exams[latest].id
          );

          if (temp.length > 0) {
            if (action.payload.exams[latest].attributes.isDeleted) {
              var others = exams.filter(
                (exam) => exam.id !== action.payload.exams[latest].id
              );

              exams = others;
            } else {
              var index = exams.lastIndexOf(temp[0]);
              exams[index] = action.payload.exams[latest];
            }
          } else {
            if (!action.payload.exams[latest].attributes.isDeleted) {
              exams.push(action.payload.exams[latest]);
            }
          }
        }
        state = { ...state, exams: exams };
      } else {
        var others = action.payload.exams.filter(
          (exam) => exam.attributes.isDeleted !== true
        );
        state = { ...state, exams: others };
      }

      break;

    case actions.UPDATE_EXAM:
      var exams = [];
      if (state.exams) {
        exams = [...state.exams];
      }
      var temp = [];
      if (state.exams) {
        temp = state.exams.filter((exam) => exam.id === action.payload.exam.id);
      }

      if (temp.length > 0) {
        var index = exams.indexOf(temp[0]);
        exams[index] = action.payload.exam;

        state = { ...state, exams: exams };
      } else {
        exams.unshift(action.payload.exam);

        state = { ...state, exams: exams };
      }
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Exam;
