import React, { useState, useEffect } from 'react'
import Topbar from '../Inventory/Topbar'
import Dropzone from 'react-dropzone';
import { Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Button, Progress, Nav, NavItem, NavLink } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Select from "react-select";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import Parse from 'parse';
import * as Constants from "../../Constents";
import { setSelecetdSchool, addTicket, editTicket, getClassroomsForSchoolId, getAllKids,getFranchise ,setSelecetdTicketTab} from '../../store/actions';

const NewTicket = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/new-ticket/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseAddMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-new-ticket/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const franchiseId = franchiseAddMatch && franchiseAddMatch.params.franchiseId;

    const imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const [startDate, setstartDate] = useState(new Date());
    const [ticketTitle, setTicketTitle] = useState();
    const [ticketDiscription, setTicketDiscription] = useState();
    const [selectedSchool, setSelectedSchool] = useState();
    const [ticketNameError, setTicketNameError] = useState();
    const [selectedSchoolError, setSelectedSchoolError] = useState();
    const [ticketDiscriptionError, setTicketDiscriptionError] = useState();
    const [selectedFiles, setselectedFiles] = useState([]);
    const [attachment, setAttachment] = useState();
    const [attachmentIsImage, setAttachmentIsImage] = useState(false);
    const [attachmentUploadProgress, setAttachmentUploadProgress] = useState(0);
    const [schoolList, setSchoolList] = useState([]);

    const [classroomList, setClassroomList] = useState([]);


    const [selectRecipentType, setSelectRecipentType] = useState();
    const [selectRecipentTypeError, setSelectRecipentTypeError] = useState(false);
    const [selectedStudent, setSelectedStudent] = useState();
    const [selectedStudentError, setSelectedStudentError] = useState(false);

    const [uploadedFiles, setUploadedFiles] = useState([]);

    const [fileUploadLoading, setFileUploadLoading] = useState(false)
    const [franchisePermisson, setFranchisePermission] = useState(false);
    const [schoolPermission, setSchoolPermission] = useState(false);

    const startDateChange = (date) => { setstartDate(date); }

    const allClassObject = () => {
        let tempSelectObj = {}
        tempSelectObj.id = "all"
        tempSelectObj.value = "all"
        tempSelectObj.label = "All Classrooms"
        return tempSelectObj;
    }
    const [selectedClassroom, setSelectedClassroom] = useState(allClassObject());
    const [studentList, setStudentList] = useState([]);

    useEffect(() => {
        if (schoolId) {
            props.getClassroomsForSchoolId(schoolId);
            props.getAllKids(schoolId);
        }
    }, [schoolId])

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if( props.selectedSchool && props.selectedSchool.id == schoolId){
            if (props.selectedSchool.attributes.featuresList.length > Constants.FEATURE_TICKETS &&
              props.selectedSchool.attributes.featuresList[Constants.FEATURE_TICKETS] > 0) {
                setSchoolPermission(true)
            }
      
            if(props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length>0){
              props.getFranchise(props.selectedSchool.attributes.franchiseId);
            }
          }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {

        if (props.franchise && props.franchise.attributes.features) {
    
          if (props.franchise.attributes.features.length > Constants.FRANCHISE_TICKETS_INDEX &&
              props.franchise.attributes.features[Constants.FRANCHISE_TICKETS_INDEX] > 0) {
                setFranchisePermission(true)
          }
        }
    }, [props.franchise]);

    useEffect(() => {

        if(franchisePermisson && schoolPermission){
            setSelectRecipentType(undefined)
        }else if(franchisePermisson){
            setSelectRecipentType("school")
        }else if(schoolPermission){
            setSelectRecipentType("student")
        }
        
    }, [franchisePermisson,schoolPermission]);



    useEffect(() => {
        if (props.schools && props.schools.length > 0) {
            let tempSelectList = []
            for (const school of props.schools) {
                tempSelectList.push(getSelectionSchool(school))
            }
            setSchoolList(tempSelectList)
        }
    }, [props.schools])


    const getSelectionSchool = (school) => {
        let tempSelectObj = {}
        let obj = school.attributes
        tempSelectObj.id = school.id
        tempSelectObj.name = obj.Name ?? ""
        tempSelectObj.value = school.id
        tempSelectObj.label = obj.Name ?? ""
        tempSelectObj.parseObject = school
        return tempSelectObj;
    }

    useEffect(() => {
        if (props.classrooms && props.classrooms.length > 0) {
            let tempSelectList = []
            tempSelectList.push(allClassObject())
            for (const classRoom of props.classrooms) {
                tempSelectList.push(getSelectedClass(classRoom))
            }
            setClassroomList(tempSelectList)
        }
    }, [props.classrooms]);
    
    const getSelectedClass = (classRoom) => {
        let tempSelectObj = {}
        let obj = classRoom.attributes
        tempSelectObj.id = classRoom.id
        tempSelectObj.name = obj.Name ?? ""
        tempSelectObj.value = classRoom.id
        tempSelectObj.label = obj.Name ?? ""
        tempSelectObj.classroomType = obj.classroomType;
        tempSelectObj.parseObject = classRoom
        return tempSelectObj;
    }
    useEffect(() => {
        if (props.kids && props.kids.length > 0 && classroomList && classroomList.length > 0 && selectedClassroom) {
            let kidList = [];
            for (const val of props.kids) {
                if (selectedClassroom.id === 'all' || selectedClassroom.id === val.attributes.ClassRoomID || selectedClassroom.id === val.attributes.daycareClassId) {
                    kidList.push(getKidObject(val));
                }
            }
            setStudentList(kidList);
        }
    }, [props.kids, classroomList, selectedClassroom]);
    
    const getKidObject = (val) => {
        let temp = {};
        temp.id = val.id;
        temp.label = val.attributes.Name;
        temp.value = val.id;
        temp.object = val;

        return temp
    }

    function handleAcceptedFiles(files) {

        files.map(file => Object.assign(file, {
            id: uuidv4(),
            preview: URL.createObjectURL(file),
            formattedSize: formatBytes(file.size),
            parseFile: undefined,
            progressState: "Pending"

        }));

        setselectedFiles(files);

        setFileUploadLoading(true);
        handleFileUploadChange(files, 0, [])
    }

    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return '0 Bytes';
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
        };
    }

    function handleFileUploadChange(files, i, exFiles) {
        // setAttachment(undefined)
        // setAttachmentUploadProgress(1)
        let fileExtension = files[i].name.split(".").pop();

        let oldFiles = [...selectedFiles]

        // let temp = selectedFiles[i]
        // temp.progressState="Up loading";
        // oldFiles[i]=temp
        // setselectedFiles(oldFiles);

        let tempFile = {
            actualFileName: files[i].name,
            fileExtension: fileExtension.toLowerCase(),
            fileName: files[i].name.split('.').slice(0, -1).join('.'),
            file: files[i],
        };
        if (imgExtensions.includes(tempFile.fileExtension.toLowerCase())) {
            //setAttachmentIsImage(true)
        } else {
            //setAttachmentIsImage(false)
        }
        var name = tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + tempFile.fileExtension;

        console.log(name)
        if (true) {
            getBase64(tempFile.file, (resultWithName) => {
                var parseFile = new Parse.File("name.jpeg", { base64: resultWithName.result });
                parseFile
                    .save({
                        progress: (value) => {
                            console.log("progress:", value);
                            if (value && value > 0.1) {
                                // setAttachmentUploadProgress(value * 100)
                            }
                        },
                    })
                    .then(
                        (result) => {

                            exFiles.push(result)

                            console.log(exFiles)
                            setUploadedFiles(exFiles)


                            let next = i + 1;
                            if (files.length > next) {
                                handleFileUploadChange(files, next, exFiles)
                            } else {
                                console.log("DONE UPLOADING");
                                setFileUploadLoading(false)
                            }

                        },
                        (error) => {
                            console.log(error)
                        }
                    );
            });
        }
        setAttachment(tempFile.actualFileName)
    }


    function handleSubmit() {

        let canSave = true;
        if (match && !selectRecipentType) {
            setSelectRecipentTypeError(true);
            canSave = false;
        }
        if(match && selectRecipentType == "student"){
            if(!selectedStudent){
                setSelectedStudentError(true);
                canSave = false;
            }
        }
        if (!ticketTitle) {
            setTicketNameError(true);
            canSave = false;
        }
        if (!selectedSchool && franchiseAddMatch) {
            setSelectedSchoolError(true);
            canSave = false;
        }

        if (!ticketDiscription) {
            setTicketDiscriptionError(true);
            canSave = false;
        }

        if (!canSave) {
            return
        }

        const Tickets = Parse.Object.extend("Tickets");
        var ticket = new Tickets();

        ticket.set("title", ticketTitle);
        ticket.set("description", ticketDiscription);
        ticket.set("ticketDate", startDate);
        if (franchiseAddMatch) {
            ticket.set('recipientId', selectedSchool.id);
            ticket.set('assignedFor', franchiseId);
            ticket.set('recipientType', "school");
            ticket.set('assignedForType', "franchise");
        } else {

            if(selectRecipentType == "student"){

                props.setSelecetdTicketTab("student")
                ticket.set('recipientId', selectedStudent.id);
                ticket.set('assignedFor', schoolId);
                ticket.set('recipientType', "kid");
                ticket.set('assignedForType', "school");

            }else if(selectRecipentType == "school"){
                props.setSelecetdTicketTab("school")
                ticket.set('recipientId', schoolId);
                ticket.set('assignedFor', props.selectedSchool && props.selectedSchool.attributes.franchiseId);
                ticket.set('recipientType', "school");
                ticket.set('assignedForType', "franchise");
            }
        }
        
        
        // ticket.set('assignedTo', "teacherId");

        ticket.set('createdBy', Parse.User.current().attributes.teacherId)

        let folderId = uuidv4();


        let filesForDb = []
        for (const i in uploadedFiles) {
            let file = uploadedFiles[i];
            var Files = Parse.Object.extend("Files");
            var fileObject = new Files();
            fileObject.set("folderId", folderId);
            fileObject.set("orderBy", parseInt(i));

            fileObject.set("fileType", "image");

            fileObject.set("file", file);
            fileObject.set("name", file._name);

            filesForDb.push(fileObject)
        }


        if (filesForDb.length > 0) {
            ticket.set('folderId', folderId);
        }



        console.log(ticket);

        let logObject = {
            ticketId: undefined,
            fromStatus: 0,
            toStatus: 0,
            message: 'Ticket Created',
            teacherId: Parse.User.current().attributes.teacherId
        }
        console.log(logObject);
        ///props.addTicket(ticket, logObject)
        ticket.save().then(
            (result) => {

                logObject.ticketId = result.id
                result.pin()

                if (filesForDb.length > 0) {
                    Parse.Object.saveAll(filesForDb).then(
                        (result) => {
                            props.editTicket(result, logObject)
                            console.log(result)
                            window.history.back();
                        },
                        (error) => {
                            console.log(error)
                        }
                    );
                } else {
                    props.editTicket(result, logObject)
                    console.log(result)
                    window.history.back();
                }

            },
            (error) => {
                console.log(error);
            }
        );

    }

    // const showUploadProgress = () => {
    //     if (selectedFiles && imageUploadProgress >= 1) {
    //         return true
    //     } else if (selectedFiles && !selectedFiles._url) {
    //         return true
    //     } else {
    //         return false
    //     }
    // }

    return (
        <div className='page-content'>
            <Topbar
                title={"New tickets"}
                buttonName={"New ticket"}
                hideButton={true}
            />
            <Container fluid>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <CardTitle className="mb-4">Create New Ticket</CardTitle>
                                <Form>
                                    <FormGroup className="mb-4" row>
                                        <Label className="col-form-label col-lg-2">Ticket Date</Label>
                                        <Col lg="10">
                                            <Row>
                                                <Col md={6} className="pr-0">
                                                    <DatePicker className="form-control"
                                                        selected={startDate}
                                                        onChange={startDateChange}
                                                        disabled={true}
                                                    />
                                                </Col>
                                            </Row>

                                        </Col>
                                    </FormGroup>
                                    {franchiseAddMatch && <FormGroup className="mb-4" row>
                                        <Label className="col-form-label col-lg-2">Select School</Label>
                                        <Col lg="6">
                                            <Row>
                                                <Col md={6} className="pr-0">
                                                    <Select
                                                        onChange={(e) => {
                                                            setSelectedSchool(e);
                                                            setTicketNameError(false);
                                                        }}
                                                        //value={}
                                                        options={schoolList}
                                                        className="schoolSelection"
                                                        classNamePrefix="school"
                                                    />
                                                    {selectedSchoolError && <small className='text-danger'>Please select school</small>}
                                                </Col>
                                            </Row>
                                        </Col>
                                    </FormGroup>}
                                    {match && schoolPermission && <FormGroup className="mb-4" row> 
                                        <Label htmlFor="SelectRecipentType" className="col-form-label col-lg-2">Create to:</Label>
                                       {franchisePermisson && <Col lg="5">
                                            <Input
                                                type="select"
                                                value={selectRecipentType}
                                                onChange={(e) => {
                                                    setSelectRecipentType(e.target.value);
                                                    setSelectRecipentTypeError(false);
                                                }}
                                            >
                                                <option value="" disabled selected>Select</option>
                                                <option value="school">School</option>
                                                {franchisePermisson &&  
                                                    <option value="student">Student</option>}
                                            </Input>
                                            {selectRecipentTypeError && <small className='text-danger'>Please select..</small>}    
                                        </Col> }
                                        <Col lg="2">
                                            {selectRecipentType === 'student' &&  (
                                                <FormGroup >
                                                    {/* <Label htmlFor="formname">Select Class Room </Label> */}
                                                    <Select
                                                        onChange={(e) => {
                                                            setSelectedClassroom(e);
                                                            setSelectedStudent()
                                                        }}
                                                        value={selectedClassroom}
                                                        options={classroomList}
                                                        className="BuyerTypeSelection"
                                                        classNamePrefix="BuyerType"
                                                    />
                                                </FormGroup>
                                            )}
                                        </Col>
                                        <Col lg="2">
                                         {selectRecipentType === 'student' && (
                                            <FormGroup >
                                                {/* <Label htmlFor="formname">Select Student </Label> */}
                                                <Select
                                                    onChange={(v) => {
                                                        setSelectedStudent(v)
                                                        if(selectedStudentError){
                                                            setSelectedStudentError(false)
                                                        }
                                                        
                                                    }}
                                                   
                                                    value={selectedStudent}
                                                    options={studentList}
                                                    className="BuyerTypeSelection"
                                                    classNamePrefix="BuyerType"
                                                />
                                                {selectedStudentError && <small className='text-danger'>Please select student</small>}
                                            </FormGroup>
                                        )}
                                    </Col>
                                    </FormGroup>}
                                   
                                    <FormGroup className="mb-4" row>
                                        <Label htmlFor="Ticketname" className="col-form-label col-lg-2">Ticket title</Label>
                                        <Col lg="10">
                                            <Input id="Ticketname" name="Ticketname" type="text" className="form-control" placeholder="Enter Ticket title..."
                                                onChange={(e) => {
                                                    setTicketTitle(e.target.value);
                                                    setTicketNameError(false);
                                                }} />
                                            {ticketNameError && <small className='text-danger'>Enter ticket name</small>}

                                        </Col>
                                    </FormGroup>
                                    <FormGroup className="mb-4" row>
                                        <Label htmlFor="Ticketdesc" className="col-form-label col-lg-2">Ticket Description</Label>
                                        <Col lg="10">
                                            <textarea className="form-control" id="Ticketdesc" rows="3" placeholder="Enter Ticket Description..."
                                                onChange={(e) => {
                                                    setTicketDiscription(e.target.value);
                                                    setTicketDiscriptionError(false);
                                                }}></textarea>
                                            {ticketDiscriptionError && <small className='text-danger'>Enter the discription</small>}

                                        </Col>
                                    </FormGroup>
                                </Form>
                                <FormGroup className="mb-4" row>
                                    <Label className="col-form-label col-lg-2">Attached Files</Label>
                                    <Col lg="10">
                                        <Form>
                                            <Dropzone accept="image/*"
                                                onDrop={(acceptedFiles) => {
                                                    handleAcceptedFiles(acceptedFiles)
                                                }}
                                            >
                                                {({ getRootProps, getInputProps }) => (
                                                    <div className="dropzone">
                                                        <div
                                                            className="dz-message needsclick"
                                                            {...getRootProps()}
                                                        >
                                                            <input {...getInputProps()} />
                                                            <div className="dz-message needsclick">
                                                                <div className="mb-3">
                                                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                                </div>
                                                                <h4>Drop images here or click to upload.</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </Dropzone>
                                            <div
                                                className="dropzone-previews mt-3"
                                                id="file-previews"
                                            >
                                                {selectedFiles.map((f, i) => {
                                                    return (
                                                        <Card
                                                            className="mt-1 mb-0 shadow-none border dz-processing dz-image-preview dz-success dz-complete"
                                                            key={i + "-file"}
                                                        >
                                                            <div className="p-2">
                                                                <Row className="align-items-center">
                                                                    <Col className="col-auto">
                                                                        <img
                                                                            data-dz-thumbnail=""
                                                                            height="80"
                                                                            className="avatar-sm rounded bg-light"
                                                                            alt={f.name}
                                                                            src={f.preview}
                                                                        />
                                                                    </Col>
                                                                    <Col>
                                                                        <Link
                                                                            to="#"
                                                                            className="text-muted font-weight-bold"
                                                                        >
                                                                            {f.name}
                                                                        </Link>
                                                                        <p className="mb-0">
                                                                            <strong>{f.formattedSize}</strong>
                                                                        </p>
                                                                    </Col>
                                                                    <p>{f.progressState}</p>
                                                                </Row>
                                                            </div>
                                                        </Card>
                                                    );
                                                })}
                                            </div>
                                        </Form>
                                    </Col>
                                </FormGroup>
                                <Row className="justify-content-end">
                                    <Col lg="10">
                                        {fileUploadLoading ? <h5 className='text-success'>Files are uploading plaese wait...</h5> : <Button type="submit" color="primary"
                                            disabled={fileUploadLoading}
                                            onClick={(e) => {
                                                handleSubmit(e.target.value);
                                            }}>Create Ticket</Button>}
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}
const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { classrooms } = state.Classroom;
    const { kids } = state.Kid;
    const { franchise } = state.Franchise;
    return { selectedSchool, schools, classrooms,kids,franchise };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, addTicket, editTicket, getClassroomsForSchoolId, getAllKids,getFranchise,setSelecetdTicketTab})(NewTicket)));
