import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as AttendanceHelper from "../../../helpers/ParseHelpers/attendance_helper";
import attendanceReport from "../../../pages/reports/attendanceReport";
import * as actions from "./actions";
import * as actionsTypes from "./actionTypes";
import * as Constants from "../../../Constents";

function* getAttendanceForTeacherIds({ payload: { params } }) {
  const response = yield call(
    AttendanceHelper.getTeacherAttendanceForIds,
    params
  );
  yield put(actions.setAttendanceForTeacherIds(response));
}
function* getAttendanceForKidIds({ payload: { params } }) {
  const response = yield call(AttendanceHelper.getAttendanceForKidIds, params);
  yield put(actions.setAttendanceForKidIds(response));
}

function* getDaycareAttendanceForKidIds({ payload: { params } }) {
  const response = yield call(
    AttendanceHelper.getDaycareAttendanceForKidIds,
    params
  );
  yield put(actions.setDaycareAttendanceForKidIds(response));
}

function* getEnquiryKidsForFilter({ payload: { filters } }) {
  const response = yield call(
    AttendanceHelper.getEnquiryKidsForFilter,
    filters
  );
  yield put(actions.setEnquiryReportForFilter(response));
}

function* getAttendanceForClassId({ payload: { classId, startDate, endDate } }) {
  try {
    const response = yield call(AttendanceHelper.getAttendanceForClassId, classId, startDate, endDate);
    if (response) {
      yield put(actions.updateClassAttendance(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getAttendanceForDaycareId({ payload: { daycareId, startDate, endDate } }) {
  try {
    const response = yield call(AttendanceHelper.getAttendanceForDaycareId, daycareId, startDate, endDate);
    if (response) {
      yield put(actions.updateDaycareAttendance(response))
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetAttendanceForTeacherIds() {
  yield takeEvery(
    actionsTypes.GET_ATTENDANCE_FOR_TEACHER_IDS,
    getAttendanceForTeacherIds
  );
}

export function* watchGetAttendanceForKidIds() {
  yield takeEvery(
    actionsTypes.GET_ATTENDANCE_FOR_KID_IDS,
    getAttendanceForKidIds
  );
}
export function* watchGetDaycareAttendanceForKidIds() {
  yield takeEvery(
    actionsTypes.GET_DAYCARE_ATTENDANCE_FOR_KID_IDS,
    getDaycareAttendanceForKidIds
  );
}

export function* watchGetEnquiryKidsForFilter() {
  yield takeEvery(
    actionsTypes.GET_ENQUIRY_KIDS_FOR_FILTERS,
    getEnquiryKidsForFilter
  );
}

export function* watchGetAttendanceForClassId() {
  yield takeEvery(
    actionsTypes.GET_ATTENDANCE_FOR_CLASS_ID,
    getAttendanceForClassId
  );
}

export function* watchGetAttendanceForDaycareId() {
  yield takeEvery(
    actionsTypes.GET_ATTENDANCE_FOR_DAYCARE_ID,
    getAttendanceForDaycareId
  );
}

function* teacherAttendanceSaga() {
  yield all([
    fork(watchGetAttendanceForTeacherIds),
    fork(watchGetAttendanceForKidIds),
    fork(watchGetDaycareAttendanceForKidIds),
    fork(watchGetEnquiryKidsForFilter),
    fork(watchGetAttendanceForClassId),
    fork(watchGetAttendanceForDaycareId),
  ]);
}
export default teacherAttendanceSaga;
