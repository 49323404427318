import React, {useState} from 'react';
import { withNamespaces } from 'react-i18next';
import { withRouter, } from "react-router-dom";
import { connect } from 'react-redux';
import CorporatePartnerReport from './CorporatePartnerReport';
import StudentAttendance from './StudentAttendance'

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Container
  
} from "reactstrap";


const AttendanceReportDropdown = (props) => {


    // const allReports = ['Student Attendance', "Teacher Attendance", "Corporate Attendance"]
    
    const allReports=["Corporate Attendance"]

    const [selctedReport, setSelctedReport] = useState('Corporate Attendance');  

    return (
        <React.Fragment>
            
            <CardTitle>Attendance Reports</CardTitle>
            <Row className="bg-light">
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Select Report")} :</Label>
                        <select
                            className="form-control "
                            value={selctedReport}
                            onChange={(e) => {
                                setSelctedReport(e.target.value);
                                
                            }}
                        >
                            <option disabled selected value>Select Report </option>
                            {allReports.map(
                                (val, key) =>
                                    <option key={key} value={val}>{val}</option>
                                    
                            )}
                        </select>
                       
                    </FormGroup>
                </Col>

                <Col md="12" xs="12" xl="12" lg="12">
                     { selctedReport == allReports[1] && <StudentAttendance isFranchise={ true}/>}
                     { selctedReport == allReports[0] && <CorporatePartnerReport isFranchise={ true}/>}
                </Col>
               
            </Row>

            <Row>
               
            </Row>

           
        </React.Fragment>
    );
}
            

const mapStatetoProps = state => {
    
    return {

    };
}
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
    })(AttendanceReportDropdown)
  )
);