import React, { useEffect, useState } from 'react';
import {
  Container, Row, Col, Label, Modal,
  ModalBody, ModalHeader, ModalFooter, Card
} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import DocViewer, {
  DocViewerRenderers, BMPRenderer, HTMLRenderer, ImageProxyRenderer,
  JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer,
  TIFFRenderer, TXTRenderer,
} from "react-doc-viewer";
import Iframe from "react-iframe";

const DocumentViewerModal = (props) => {

  const [clickedFile, setClickedFile] = useState();

  useEffect(() => {
    if (props.clickedFile) {
      setClickedFile(props.clickedFile)
    }
  }, [props.clickedFile])

  function getFileName(file) {
    if (file) {
      let res = file._name.split("_")
      return res[res.length - 1];
    }
  }

  function getURL() {
    if (clickedFile) {
      return clickedFile._url;
    }
  }
  const getFR = (url) => {
    const docs = [
      { uri: url },
    ];
    return docs;
  }

  return (
    <React.Fragment>
      {clickedFile && <Modal
        size="xl"
        isOpen={clickedFile}
        toggle={() => {
          setClickedFile()
          props.closeModal()
        }}
        scrollable={true}
      >
        <div className="modal-header">
          <h5
            className="modal-title mt-0"
            id="myLargeModalLabel"
          >
            {clickedFile && getFileName(clickedFile)}
          </h5>
          {
            clickedFile && props.hideDownload === false &&
            <a
              href={getURL()}
              download={getFileName(clickedFile)}
              target="_blank"
              type="button"
              className="btn btn-sm btn-primary waves-effect waves-light ml-4">
              <i className="bx bx-download font-size-14 align-middle"></i> {props.t('Download ')}
            </a>
          }
          <button
            onClick={() => {
              setClickedFile()
              props.closeModal()
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <ModalBody >
          {
            <div>
              {getURL() && getURL().endsWith(".pdf") ? <Iframe
                url={getURL() + "#toolbar=0"}
                height="600"
                width="100%"
                display="initial"
                allowFullScreen={true}
                position="relative"
                frameBorder={0}
              >
              </Iframe> :
                <DocViewer
                  pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer,]}
                  style={{ height: "500px" }}
                  documents={getFR(getURL())}
                  config={{
                    header: {
                      disableHeader: true,
                      disableFileName: false,
                      retainURLParams: false
                    }
                  }}
                />
              }
            </div>
          }
          <Card style={{ backgroundColor: "#fff", marginTop: "-7mm", width: "100%", height: " 7mm" }} />
        </ModalBody>
      </Modal>
      }
    </React.Fragment>
  );
}

const mapStatetoProps = state => {




  return {

  }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {

})(DocumentViewerModal)));
