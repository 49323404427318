import * as actions from './actionTypes';


const initialState = {
    parents: [], enquiryParents: [],selectedParents:undefined,
}

const parent = (state = initialState, action) => {
    switch (action.type) {

        case actions.ADD_OR_UPDATE_PARENT:
            var parents = [...state.parents]
            var temp = state.parents.filter(parent => parent.id === action.payload.id)
            if (temp.length > 0) {
                var index = parents.indexOf(temp[0])
                parents[index] = action.payload
                state = { ...state, parents: parents };
            } else {
                parents.push(action.payload)
                state = { ...state, parents: parents };
            }
            break;
        case actions.DELETE_PARENT:
            var parents = state.parents.filter(parent => parent.id !== action.payload.id)
            state = { ...state, parents: parents };
            break;
        case actions.SET_SELECTED_PARENTS:
            
            state = { ...state, selectedParents: action.payload.parents };
            break;

        case actions.UPDATE_PARENTS:
            var parents = [...state.parents]
            if (parents.length > 0) {
                for (const latest in action.payload.parents) {
                    var temp = parents.filter(parent => parent.id === action.payload.parents[latest].id)
                    if (temp.length > 0) {
                        var index = parents.lastIndexOf(temp[0]);
                        parents[index] = action.payload.parents[latest];
                    } else {
                        parents.push(action.payload.parents[latest]);
                    }
                }
                state = { ...state, parents: parents };
            } else {
                state = { ...state, parents: action.payload.parents };
            }

            break;


        default:
            state = { ...state };
            break;
    }
    return state;
}

export default parent