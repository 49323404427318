import React, { useEffect, useState } from "react";
import {
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import Moment from "moment";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import {
  getEnquiryKidsForFilter,
  getEnquiryQuestionForSchoolId,
  getChildAdditionalDetailsForEnquiryAndKidIds,
  getAllKids,
  getParentsForFamilyIds,
  getEnquiryAnalytics,
  getEnquiryNoteForEnquiryIds,
  getEnquiryRemindersForEnquiryIds
} from "../../../store/actions";
import EnquiryAnalytics from "./EnquiryAnalytics";

const EnquiryReport = (props) => {
  const [reportType, setReportType] = useState("0");
  const [selectedDate, selectedDateOps] = useState(new Date());
  const [selectedEndDate, selectedEndDateOps] = useState(new Date());
  const [gotReport, gotReportOps] = useState(false);
  const [reportList, reportListOps] = useState([]);
  //objs
  const [enquiryObj, enquiryObjOps] = useState([]);
  const [cadObj, cadObjOps] = useState([]);
  const [kidObj, kidObjOps] = useState([]);
  const [parentObj, parentObjOps] = useState([]);

  const [fromDate, setFromDate] = useState(undefined);
  const [toDate, setToDate] = useState(undefined);
  const [toDateError, setToDateError] = useState(false);
  const [fromDateError, setFromDateError] = useState(false);
  const [onClickApply, setOnClickApply] = useState(false);
  const handelDateChange = (bool) => {
    setOnClickApply(bool);
  };

  const [CADKidIds, setCADKidIds] = useState([]);
  useEffect(() => {
    if (props.schoolId) {
      props.getEnquiryQuestionForSchoolId(props.schoolId);
      props.getAllKids(props.schoolId);

    }
  }, [props.schoolId]);
  const ReportAnalytics = 11;
  const enquiryStates = [
    "All", //0
    "Admitted", //1
    "Enquired", //2
    "Rejected", //3
    "Waitlisted", //4
    "Cold", //5
    "Warm", //6
    "Hot", //7
    "Lost", //8
    "Consulted",//9
    "Evaluated",//10
   
    "Analytics",
    "QR code",
    "Bulk import",
    "School Website",
    "Deleted",
  ];

  const [enquiryAnalyticsByCount, setEnquiryAnalyticsByCount] = useState([]);
  const [enquiryAnalyticsBySource, setEnquiryAnalyticsBySource] = useState([])
  const [enquiryAnalyticsByClass, setEnquiryAnalyticsByClass] = useState([])

  const generateReport = () => {
    let filter = {};
    filter.schoolId = props.schoolId;
    filter.status = [];
    if (reportType === "1") {
      filter.status.push(1);
    } else if (reportType === "2") {
      filter.status.push(undefined);
      filter.status.push(0);
    } else if (reportType === "3") {
      filter.status.push(2);
    } else if (reportType === "4") {
      filter.status.push(3);
    } else if (reportType === "5") {
      filter.status.push(4);
    } else if (reportType === "6") {
      filter.status.push(5);
    } else if (reportType === "7") {
      filter.status.push(6);
    } else if (reportType === "8") {
      filter.status.push(7);
    }else if (reportType === "9") {
      filter.status.push(9);
    } else if (reportType === "10") {
      filter.status.push(10);
    }else if (reportType === "12") {
      filter.QRcode = true
    } else if (reportType === "13") {
      filter.isBulkUpdate = true
    } else if (reportType === "14") {
      filter.schoolWebsite = true
    } else if (reportType === "15") {
      filter.deleted = true
    }
    filter.startDate = new Date(
      selectedDate.getFullYear(),
      selectedDate.getMonth(),
      selectedDate.getDate()
    );
    filter.endDate = new Date(
      selectedEndDate.getFullYear(),
      selectedEndDate.getMonth(),
      selectedEndDate.getDate(),
      23,
      59,
      59,
      999
    );
    if (reportType == ReportAnalytics) {
      //props.getEnquiryAnalytics(filter);
    } else {
      props.getEnquiryKidsForFilter(filter);
    }
  };
  useEffect(() => {
    gotReportOps(props.gotEnquiryReport);
  }, [props.gotEnquiryReport]);
  useEffect(() => {
    let tempList = [];
    // let kidIds = []
    let enquiryids = [];
    enquiryObjOps(props.enquiryReport);
    for (const val of props.enquiryReport) {
      let temp = {};
      enquiryids.push(val.id);
      temp.id = val.id;
      temp.obj = val;
      temp.name = val.attributes.kidName;
      temp.date = getDateInDDMMYYYY(val.attributes.enquiryDate??val.attributes.createdAt);
      
      temp.enquiryFor = val.attributes.enquiryFor
        ? val.attributes.enquiryFor.toString()
        : "--";
      temp.source = val.attributes.source ? val.attributes.source : "--";
      temp.statusNum = val.attributes.status;


      if (val.attributes.createdBy === "QRCode") {
        temp.createdBy= "QRCode"
      } else if (val.attributes.createdBy === "API") {
        temp.createdBy= "School website"
      } else if (val.attributes.isBulkUpdate) {
        temp.createdBy= "Bulk Import"
      }else  {
        temp.createdBy= "App/Dashboard"
      }
      switch (val.attributes.status) {
        case 0:
          temp.status = "Enquired";
          break;
        case 1:
          temp.status = "Admitted";
          break;
        case 2:
          temp.status = "Rejected";
          break;
        case 3:
          temp.status = "Waitlisted";
          break;
        case 4:
          temp.status = "Cold";
          break;
        case 5:
          temp.status = "Warm";
          break;
        case 6:
          temp.status = "Hot";
          break;
        case 7:
          temp.status = "Lost";
          break;
        case 8:
          temp.status = "Drop";
          break;
        case 9:
          temp.status = "Consulted";
          break;
        case 10:
          temp.status = "Evaluated";
          break;
        default:
          temp.status = "Enquired";
          break;
      }

      if (val.attributes.isDeleted) {
        temp.status = "Deleted";
      }
      tempList.push(temp);
    }
    if (enquiryids.length > 0) {
      props.getChildAdditionalDetailsForEnquiryAndKidIds(enquiryids, []);

      props.getEnquiryNoteForEnquiryIds(enquiryids);
      props.getEnquiryRemindersForEnquiryIds(enquiryids);
    }
    reportListOps(tempList);
  }, [props.enquiryReport]);



  const getDateInDDMMYYYY = (date) => {
    return Moment(date).format("DD/MM/YYYY");
  };
  
  useEffect(() => {
    cadObjOps(props.childadditionaldetails);
    let kidIds = [];
    for (const val of props.childadditionaldetails) {
      if (val.attributes.kidId) {
        kidIds.push(val.attributes.kidId);
      }
    }
    if (kidIds.length > 0) {
      setCADKidIds(kidIds);
    }
  }, [props.childadditionaldetails]);
  useEffect(() => {
    kidObjOps(props.kids);
    if (props.kids && CADKidIds && CADKidIds.length > 0) {
      let familyIds = [];
      for (const val of props.kids) {
        if (CADKidIds.includes(val.id)) {
          familyIds.push(val.attributes.FamilyID);
        }

      }
      if (familyIds.length > 0) {
        props.getParentsForFamilyIds(familyIds);
      }
    }

  }, [props.kids, CADKidIds]);
  useEffect(() => {
    let familyIds = [];
    for (const val of enquiryObj) {
      if (val.attributes.familyId) {
        familyIds.push(val.attributes.familyId);
      }
    }
    if (familyIds.length > 0) {
      props.getParentsForFamilyIds(familyIds);
    }
  }, [enquiryObj]);
  useEffect(() => {
    if (parentObj.length > 0) {
      let tempParent = Object.assign([], parentObj);
      let tempAllList = [];
      for (const val of tempParent) {
        tempAllList.push(val.id);
      }
      for (const val of props.parents) {
        if (!tempAllList.includes(val.id)) {
          tempParent.push(val);
          tempAllList.push(val.id);
        }
      }
      parentObjOps(tempParent);
    } else {
      parentObjOps(props.parents);
    }
  }, [props.parents]);
  const downloadTheReport = () => {
    if (!props.enquiryQuestions || props.enquiryQuestions.length == 0) {
      return;
    }

    let rawData = reportList;
    let finalData = [];
    let titles = [];
    let fileName = "";
    let sheetName = "";
    fileName = `Enquiry_Report_${getDateInDDMMYYYY(selectedDate)}-${getDateInDDMMYYYY(selectedEndDate)}.xlsx`;
    sheetName = "Enquiry_Report";
    finalData.push([`Report Type : ${enquiryStates[reportType]}`]);
    finalData.push([
      `Selected Month : ${getDateInDDMMYYYY(selectedDate)}-${getDateInDDMMYYYY(selectedEndDate)}`,
    ]);
    titles.push("Enquiry Date");
    titles.push('Status')
    titles.push('Added From')
    let questions = []
    for (const val of props.enquiryQuestions) {

      if ((val.attributes.databaseTableName === "Enquiry"
        && val.attributes.databaseFieldName === "enquiryDate") ||
        val.attributes.questionType === 16 ||
        val.attributes.questionType === 17) {

      } else {
        questions.push(val)
        titles.push(val.attributes.question);
      }
    }

    titles.push("Tasks");
    titles.push("Notes");
    finalData.push(titles);
    for (const val of rawData) {
      let temp = [];
      temp.push(
        getDateInDDMMYYYY(
          val.obj.attributes.enquiryDate ?? val.obj.attributes.createdAt
        )
      );
      temp.push(val.status)
      temp.push(val.createdBy)
      for (const eQuestion of questions) {
        if (eQuestion.attributes.databaseTableName === "Enquiry") {
          if (val.obj.attributes[eQuestion.attributes.databaseFieldName]) {
            if (eQuestion.attributes.dataType === "DATE") {
              temp.push(
                getDateInDDMMYYYY(
                  val.obj.attributes[eQuestion.attributes.databaseFieldName]
                )
              );
            } else if (
              eQuestion.attributes.dataType === "STRING" ||
              eQuestion.attributes.dataType === "NUMBER"
            ) {
              temp.push(
                val.obj.attributes[eQuestion.attributes.databaseFieldName]
              );
            } else if (eQuestion.attributes.dataType === "FILE") {
              temp.push(
                val.obj.attributes[eQuestion.attributes.databaseFieldName]._url
              );
            } else if (eQuestion.attributes.dataType === "BOOL") {
              temp.push("TRUE");
            } else if (eQuestion.attributes.dataType === "ARRAY") {
              temp.push(
                val.obj.attributes[
                  eQuestion.attributes.databaseFieldName
                ].toString()
              );
            } else {
              temp.push(null);
            }
          } else {
            if (eQuestion.attributes.dataType === "BOOL") {
              temp.push("FALSE");
            } else {
              temp.push(null);
            }
          }
        } else if (
          eQuestion.attributes.databaseTableName === "ChildAdditionalDetails"
        ) {
          if (cadObj.length > 0) {
            let gotValue = false;
            for (const cAns of cadObj) {
              if (cAns.attributes.enquiryId === val.id) {
                gotValue = true;
                if (cAns.attributes[eQuestion.attributes.databaseFieldName]) {
                  if (eQuestion.attributes.dataType === "DATE") {
                    temp.push(
                      getDateInDDMMYYYY(
                        cAns.attributes[eQuestion.attributes.databaseFieldName]
                      )
                    );
                  } else if (
                    eQuestion.attributes.dataType === "STRING" ||
                    eQuestion.attributes.dataType === "NUMBER"
                  ) {
                    temp.push(
                      cAns.attributes[eQuestion.attributes.databaseFieldName]
                    );
                  } else if (eQuestion.attributes.dataType === "FILE") {
                    temp.push(
                      cAns.attributes[eQuestion.attributes.databaseFieldName]
                        ._url
                    );
                  } else if (eQuestion.attributes.dataType === "BOOL") {
                    temp.push("TRUE");
                  } else if (eQuestion.attributes.dataType === "ARRAY") {
                    temp.push(
                      cAns.attributes[
                        eQuestion.attributes.databaseFieldName
                      ].toString()
                    );
                  } else {
                    temp.push(null);
                  }
                } else {
                  if (eQuestion.attributes.dataType === "BOOL") {
                    temp.push("FALSE");
                  } else {
                    temp.push(null);
                  }
                }
                break;
              }
            }
            if (!gotValue) {
              temp.push(null);
            }
          } else {
            temp.push(null);
          }
        } else if (eQuestion.attributes.databaseTableName === "Kid") {
          if (kidObj.length > 0) {
            let gotKid = false;
            for (const kAns of kidObj) {
              if (kAns.attributes.enquiryId === val.id) {
                gotKid = true;
                if (kAns.attributes[eQuestion.attributes.databaseFieldName]) {
                  if (eQuestion.attributes.dataType === "DATE") {
                    temp.push(
                      getDateInDDMMYYYY(
                        kAns.attributes[eQuestion.attributes.databaseFieldName]
                      )
                    );
                  } else if (
                    eQuestion.attributes.dataType === "STRING" ||
                    eQuestion.attributes.dataType === "NUMBER"
                  ) {
                    temp.push(
                      kAns.attributes[eQuestion.attributes.databaseFieldName]
                    );
                  } else if (eQuestion.attributes.dataType === "FILE") {
                    temp.push(
                      kAns.attributes[eQuestion.attributes.databaseFieldName]
                        ._url
                    );
                  } else if (eQuestion.attributes.dataType === "BOOL") {
                    temp.push("TRUE");
                  } else if (eQuestion.attributes.dataType === "ARRAY") {
                    temp.push(
                      kAns.attributes[
                        eQuestion.attributes.databaseFieldName
                      ].toString()
                    );
                  } else {
                    temp.push(null);
                  }
                } else {
                  if (eQuestion.attributes.dataType === "BOOL") {
                    temp.push("FALSE");
                  } else {
                    temp.push(null);
                  }
                }
                break;
              }
            }
            if (!gotKid) {
              temp.push(null);
            }
          } else {
            temp.push(null);
          }
        } else if (eQuestion.attributes.databaseTableName === "Parent") {
          if (parentObj.length > 0) {
            let gotParent = false;
            for (const pAns of parentObj) {
              if (pAns.attributes.FamilyID === val.obj.attributes.familyId) {
                if (
                  eQuestion.attributes.question
                    .toLocaleLowerCase()
                    .includes(pAns.attributes.Relation.toLocaleLowerCase())
                ) {
                  gotParent = true;
                  if (pAns.attributes[eQuestion.attributes.databaseFieldName]) {
                    if (eQuestion.attributes.dataType === "DATE") {
                      temp.push(
                        getDateInDDMMYYYY(
                          pAns.attributes[
                          eQuestion.attributes.databaseFieldName
                          ]
                        )
                      );
                    } else if (
                      eQuestion.attributes.dataType === "STRING" ||
                      eQuestion.attributes.dataType === "NUMBER"
                    ) {
                      temp.push(
                        pAns.attributes[eQuestion.attributes.databaseFieldName]
                      );
                    } else if (eQuestion.attributes.dataType === "FILE") {
                      temp.push(
                        pAns.attributes[eQuestion.attributes.databaseFieldName]
                          ._url
                      );
                    } else if (eQuestion.attributes.dataType === "BOOL") {
                      temp.push("TRUE");
                    } else if (eQuestion.attributes.dataType === "ARRAY") {
                      temp.push(
                        pAns.attributes[
                          eQuestion.attributes.databaseFieldName
                        ].toString()
                      );
                    } else {
                      temp.push(null);
                    }
                  } else {
                    if (eQuestion.attributes.dataType === "BOOL") {
                      temp.push("FALSE");
                    } else {
                      temp.push(null);
                    }
                  }
                  break;
                }
              }
            }
            if (!gotParent) {
              temp.push(null);
            }
          } else {
            temp.push(null);
          }
        }
      }
      temp.push(getTasksForEnquiryId(val.obj.id));
      temp.push(getNotesForEnquiryId(val.obj.id));
      finalData.push(temp);
    }
    let wb = XLSX.utils.book_new();
    let ws = XLSX.utils.aoa_to_sheet(finalData);
    XLSX.utils.book_append_sheet(wb, ws, sheetName);
    XLSX.writeFile(wb, fileName);
  };

  const getTasksForEnquiryId = (id) => {
    let count = 0
    let message = "";
    for (const task of props.enquiryRemindersForIds) {
      if (task.attributes.enquiryId === id) {
        count = count + 1;

        let title = task.attributes.title ? task.attributes.title : 'Note';
        let body = task.attributes.body;
        let time = getTimeStringForDate(task.attributes.reminderDateTime);

        let newMessage = "TASK: " + count + "\n"
          + "Title: " + title + "\n"
          + "Task Message: " + body + "\n"
          + "Task Added: " + time + "\n";

        if (message) {
          message = message + newMessage;
        } else {
          message = newMessage;
        }
      }
    }
    return message;
  }



  const getNotesForEnquiryId = (id) => {
    let count = 0
    let message = "";
    for (const note of props.enquiryNotesForIds) {
      if (note.attributes.enquiryId === id) {
        count = count + 1;

        let title = note.attributes.title ? note.attributes.title : 'Note';
        let noteMessage = note.attributes.noteMessage;
        let time = getTimeStringForDate(note.attributes.createdAt);
        let systemLog = note.attributes.systemLog ? "YES" : "NO";

        let newMessage = "NOTE: " + count + "\n"
          + "Auto Generated :" + systemLog + "\n"
          + "Title: " + title + "\n"
          + "Note Message: " + noteMessage + "\n"
          + "Note Added: " + time + "\n";
        if (message) {
          message = message + newMessage;
        } else {
          message = newMessage;
        }

      }
    }
    return message;
  }

  const getTimeStringForDate = (date) => {
    return Moment(date).format('DD-MMM-YYYY,  h:mm A');
  }


  const UIForDisplayTable = () => {

    return (
      <React.Fragment>
        <Row>
          <div className="table-responsive">
            <table className="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>{props.t("School Name")}</th>
                  <th style={{ width: "25%", textAlign: "center" }}>{props.t("Number of Enquiries")}</th>
                  <th style={{ width: "25%", textAlign: "center" }}>{props.t("Admissions through Enquiries")}</th>

                </tr>
              </thead>
              <tbody>
                {enquiryAnalyticsByCount.map((val, key) => (
                  <tr key={key}>
                    <td>{val.name}</td>
                    <td style={{ textAlign: "center" }}>{val.enquiryCount}</td>
                    <td style={{ textAlign: "center" }}>{val.admissionCount}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Row>
        <Row className="mt-5">
          <div className="table-responsive">
            <table className="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>{props.t("Source Name")}</th>
                  <th style={{ width: "25%", textAlign: "center" }}>{props.t("Number of Enquiries")}</th>
                  <th style={{ width: "25%", textAlign: "center" }}>{props.t("")}</th>

                </tr>
              </thead>
              <tbody>
                {enquiryAnalyticsBySource.map((val, key) => (
                  <tr key={key}>
                    <td>{val.name}</td>
                    <td style={{ textAlign: "center" }}>{val.enquiryCount}</td>
                    <td style={{ textAlign: "center" }}></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Row>
        <Row className="mt-5">
          <div className="table-responsive">
            <table className="table table-centered table-nowrap">
              <thead>
                <tr>
                  <th style={{ width: "50%" }}>{props.t("Class Name")}</th>
                  <th style={{ width: "25%", textAlign: "center" }}>{props.t("Number of Enquiries")}</th>
                  <th style={{ width: "25%", textAlign: "center" }}>{props.t("")}</th>
                </tr>
              </thead>
              <tbody>
                {enquiryAnalyticsByClass.map((val, key) => (
                  <tr key={key}>
                    <td>{val.name}</td>
                    <td style={{ textAlign: "center" }}>{val.enquiryCount}</td>
                    <td style={{ textAlign: "center" }}></td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Row></React.Fragment>

    )



  }



  return (
    <React.Fragment>
      <CardTitle>Enquiry Report</CardTitle>
      <CardSubtitle className="mb-3">
        Select filter to generate the report
      </CardSubtitle>
      <Row className="bg-light">
        <Col md="3" xs="12" xl="3" lg="3">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>Report Type :</Label>
            <select
              className="form-control "
              value={reportType}
              onChange={(e) => {
                setReportType(e.target.value);
                if (e.target.value == ReportAnalytics) {
                  if (fromDate && toDate) {
                    setOnClickApply(false);
                  }
                }
              }}
            >
              {enquiryStates.map((val, key) => (
                <option value={key} key={key}>
                  {val}
                </option>
              ))}
            </select>
          </FormGroup>
        </Col>
        {reportType == ReportAnalytics &&
          <Col >
            <Row className=" bg-light ml-2">

              <Row>
                <Col lg={4} xs={6}>
                  <FormGroup className="mt-2 mb-2 p-1">
                    <Label >
                      {props.t("From")} :
                    </Label>
                    <DatePicker
                      selected={fromDate}
                      onChange={(date) => {
                        onClickApply && handelDateChange(false);
                        setFromDateError(false)
                        setFromDate(date)
                      }}
                      className="form-control"
                      placeholderText={props.t("Select Date")}
                      startDate={fromDate}
                      endDate={toDate}
                      maxDate={toDate}
                      dateFormat="MMM-yyyy"
                      required
                      errorMessage="Please select"
                      showMonthYearPicker
                    />
                  </FormGroup>
                  {fromDateError && <p className="text-danger ml-2">Please select</p>}
                </Col>
                <Col lg={4} xs={6}>
                  <FormGroup className="mt-2 mb-2 p-1">
                    <Label >
                      {props.t("To")} :
                    </Label>
                    <DatePicker
                      selected={toDate}
                      onChange={(date) => {
                        onClickApply && handelDateChange(false);
                        setToDateError(false);
                        setToDate(date)
                      }}
                      className="form-control"
                      placeholderText={props.t("Select Date")}
                      startDate={fromDate}
                      endDate={toDate}
                      minDate={fromDate}
                      dateFormat="MMM-yyyy"
                      required
                      errorMessage="Please select"
                      showMonthYearPicker
                    />
                  </FormGroup>
                  {toDateError && <p className="text-danger ml-2">Please select</p>}
                </Col>
                <Col lg={3} >
                  <div className="mt-4 mb-1 p-1">
                    <button
                      // disabled={loadingState === 2}
                      onClick={(e, v) => {
                        let next = true;
                        if (!toDate) {
                          setToDateError(true);
                          next = false;
                        }
                        if (!fromDate) {
                          setFromDateError(true);
                          next = false;
                        }
                        if (next) {
                          handelDateChange(true);
                        }

                      }}
                      className="w-md mt-2  btn btn-primary"
                    >
                      {props.t("Apply")}
                    </button>

                  </div>
                </Col>
              </Row>

            </Row>
          </Col>
        }
        {reportType != ReportAnalytics && <Col md="3" xs="12" xl="3" lg="3">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>From Date :</Label>
            <DatePicker
              selected={selectedDate}
              onChange={(date) => {
                date ? selectedDateOps(date) : selectedDateOps(new Date());
              }}
              className="form-control"
              placeholderText={"Select Date"}
              // startDate={fromDate}
              // endDate={toDate}
              maxDate={selectedEndDate}
              dateFormat="dd/MM/yyyy"
              showFullMonthYearPicker
            />
          </FormGroup>
        </Col>}
        {reportType != ReportAnalytics && <Col md="3" xs="12" xl="3" lg="3">
          <FormGroup className="mt-2 mb-2 p-1">
            <Label>To Date :</Label>
            <DatePicker
              selected={selectedEndDate}
              onChange={(date) => {
                date
                  ? selectedEndDateOps(date)
                  : selectedEndDateOps(new Date());
              }}
              className="form-control"
              placeholderText={"Select Date"}
              // startDate={fromDate}
              // endDate={toDate}
              minDate={selectedDate}
              maxDate={new Date()}
              dateFormat="dd/MM/yyyy"
              showFullMonthYearPicker
            />
          </FormGroup>
        </Col>}
        {reportType != ReportAnalytics && <Col md="3" xs="12" xl="3" lg="3">
          <div className="mt-2">
            <div className="mt-3">
              <button
                className="btn btn-primary mt-4 mr-2"
                onClick={generateReport}
              >
                Generate
              </button>
            </div>
          </div>
        </Col>}
      </Row>
      <Row>
        {reportType != ReportAnalytics && gotReport && reportList.length > 0 && (
          <Col md="12" xs="12" xl="12" lg="12" className="d-flex justify-content-end mt-1 mb-1">
            <button
              className="btn btn-info float-right"
              onClick={downloadTheReport}
            >
              <i className="bx bxs-download mr-1" />
              Download
            </button>
          </Col>
        )}
      </Row>
      {gotReport && reportList.length > 0 && reportType != ReportAnalytics && (
        <Row>
          <div className="table-responsive m-3">
            <table className="table table-centered table-nowrap table-sm">
              <thead>
                <tr>
                  <th>{props.t("Name")}</th>
                  <th>{props.t("Date")}</th>
                  <th>{props.t("Enquiry For")}</th>
                  <th>{props.t("Source")}</th>
                  <th>{props.t("Status")}</th>
                  <th>{props.t("Added From")}</th>
                </tr>
              </thead>
              <tbody>
                {reportList.map((val, key) => (
                  <tr key={val.id}>
                    <td>{val.name}</td>
                    <td>{val.date}</td>
                    <td>{val.enquiryFor}</td>
                    <td>{val.source}</td>
                    <td>{val.status}</td>
                    <td>{val.createdBy}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </Row>
      )}
      

      {reportType == ReportAnalytics && <EnquiryAnalytics
        isFranchise={false}
        schoolId={props.schoolId}
        onClickApply={onClickApply}
        fromDate={fromDate}
        toDate={toDate}

      />}

      {gotReport && reportList.length === 0 && (
        <Col>
          <h3 className="text-center text-info mt-3">
            {props.t("No Report Found For The Filter")}
          </h3>
        </Col>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { gotEnquiryReport, enquiryReport } = state.Attendance;
  const { enquiryQuestions } = state.EnquiryQuestions;
  const { childadditionaldetails } = state.ChildAdditionalDetails;
  const { enquiryAnalyticsForSchool } = state.EnquiryAnalytics;
  const { kids } = state.Kid;
  const { parents } = state.Parent;
  const { selectedSchool } = state.School;
  const { enquiryNotesForIds } = state.EnquiryNotes;
  const { enquiryRemindersForIds } = state.EnquiryReminder;

  return {
    gotEnquiryReport,
    enquiryReport,
    enquiryQuestions,
    childadditionaldetails,
    kids,
    parents,
    enquiryAnalyticsForSchool,
    selectedSchool,
    enquiryNotesForIds,
    enquiryRemindersForIds
  };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getEnquiryKidsForFilter,
      getEnquiryQuestionForSchoolId,
      getChildAdditionalDetailsForEnquiryAndKidIds,
      getAllKids,
      getParentsForFamilyIds,
      getEnquiryAnalytics,
      getEnquiryNoteForEnquiryIds,
      getEnquiryRemindersForEnquiryIds,

    })(EnquiryReport)
  )
);
