import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, Container, Label, Card, CardBody } from "reactstrap";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import {
    getAKid, setSelecetdSchool, getClassroomsForSchoolId, getSubjectsForExamId, getExamForExamId, getExamResultsForExamId,
    getGroupForId, getGroupMapsForGroupId, getTeachersForSchool, getExamResultsForExamIdAndKidId,
    getAllKidsForGroup, saveGroup, saveGroupMaps, getAllKids
} from "../../store/actions";
import Header from './Header'

const ExamCertificate = (props) => {

    const matchKid = matchPath(props.history.location.pathname, {
        path: "/exam-certificate/:schoolId/:examId/:kidId",
        exact: true,
        strict: false,
    });

    const matchGroup = matchPath(props.history.location.pathname, {
        path: "/exam-certificates/:schoolId/:examId/:groupId",
        exact: true,
        strict: false,
    });

    const match = matchKid ?? matchGroup

    const kidId = matchKid && matchKid.params.kidId;

    const schoolId = match && match.params.schoolId;
    const examId = match && match.params.examId;

    const groupId = matchGroup && matchGroup.params.groupId;

    const [tableData, setTableData] = useState([]);

    const [selectedExamKids, setSelectedExamKids] = useState([])
    const [kidSubjectList, setKidSubjectList] = useState([])
    const [duplicate, setDuplicate] = useState(false);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId])

    useEffect(() => {
        if (kidId) {
            props.getAKid(kidId);
        }
    }, [kidId]);


    useEffect(() => {
        if (schoolId) {
            props.getAllKids(schoolId);
        }
    }, [schoolId])

    useEffect(() => {
        if (examId) {
            props.getSubjectsForExamId(examId);
            props.getExamForExamId(examId);
            if (kidId) {
                props.getExamResultsForExamIdAndKidId(examId, kidId)
            } else {
                props.getExamResultsForExamId(examId)
            }

        }
    }, [examId]);

    useEffect(() => {
        if (groupId) {
            props.getGroupForId(groupId);
            props.getGroupMapsForGroupId(groupId);
        }
    }, [groupId])

    useEffect(() => {

        if (props.groupmaps && props.groupmaps.length > 0 && props.kids) {
            let tempSelectedKidList = []


            for (var i = 0; i < props.groupmaps.length; i++) {
                if (props.groupmaps[i].attributes.groupId === groupId) {
                    let kidObjList = props.kids.filter((kid) =>
                        kid.id === props.groupmaps[i].attributes.userId
                    )
                    if (kidObjList.length > 0) {
                        tempSelectedKidList.push(kidObjList[0]);
                    }
                }
            }
            setSelectedExamKids(tempSelectedKidList)
        }
    }, [props.groupmaps, props.kids]);

    useEffect(() => {
        if (props.subjects && props.subjects.length > 0) {
            let theorySubjects = []
            let practicalSubjects = []
            let subjects = [];
            for (const sub of props.subjects) {
                if (sub.attributes.examId == examId) {
                    if (sub.attributes.theoryMarks) {
                        theorySubjects.push(sub)
                    } else {
                        practicalSubjects.push(sub)
                    }
                }
            }
            for (const s of theorySubjects) {

                let newSubject = {
                    subjectId: uuidv4(),
                    id: s.id,
                    parseObject: s,
                    subjectName: s.attributes.name,
                    examStartDate: new Date(s.attributes.examDate),
                    theory: s.attributes.theoryMarks,
                    passing: s.attributes.passMarks,
                    totalMarks: s.attributes.maxMarks,

                    practicalData: [
                    ]
                }


                let pList = []
                for (const p of practicalSubjects) {

                    if (p.attributes.subjectId == s.id) {
                        let pObj = {
                            practicalId: uuidv4(),
                            id: p.id,
                            parseObject: p,
                            practicalName: p.attributes.name,
                            practicalStartDate: new Date(p.attributes.examDate),
                            practicalMarks: p.attributes.practicalMarks,
                            passingMarks: p.attributes.passMarks,
                            totalMarks: p.attributes.maxMarks,
                        }
                        pList.push(pObj)
                    }
                }

                newSubject.practicalData = pList;
                subjects.push(newSubject)


            }
            setTableData(subjects)
        }

    }, [props.subjects])

    useEffect(() => {

        if (tableData && tableData.length > 0) {
            let kidList = [];

            if (kidId && props.selectedKid) {
                kidList.push(props.selectedKid);
            } else {
                kidList = kidList.concat(selectedExamKids);
            }

            let mainList = [];
            for (const kid of kidList) {

                let kidTableDate = [...tableData];
                let kidSubjects = [];
                let totalMarks = 0;
                let totalMaxMarks = 0;
                let totalMinMarks = 0;
                let totalPracticalMinMarks = 0;
                let totalPracticalMarks = 0;
                let totalPracticalMaxMarks = 0;
                let allSubjectsPassed = false;

                for (const i in kidTableDate) {
                    let subject = { ...kidTableDate[i] };
                    subject.result = getExamSubjectResult(kid.id, subject.id);
                    subject.theoryMarks = subject.result && subject.result.attributes.theoryMarks;

                    let practicalData = [];


                    if (subject.practicalData && subject.practicalData.length > 0) {
                        for (const j in subject.practicalData) {
                            let ps = { ...subject.practicalData[j] };
                            ps.result = getExamSubjectResult(kid.id, subject.id, ps.id);
                            ps.practicalMarks = ps.result && ps.result.attributes.practicalMarks;

                            // Calculate total practical marks and max marks
                            totalPracticalMarks += ps.practicalMarks || 0;
                            totalPracticalMaxMarks += ps.totalMarks || 0;
                            totalPracticalMinMarks += ps.passingMarks || 0;

                            practicalData.push(ps);
                        }
                    }

                    subject.practicalData = practicalData;

                    // Calculate total theory marks and max marks
                    totalMarks += subject.theoryMarks || 0;
                    totalMaxMarks += subject.theory || 0;
                    totalMinMarks += subject.passing || 0;

                    // Check if subject is passed based on obtained marks and passing marks
                    if (subject.theoryMarks > subject.passing || totalPracticalMarks > subject.practicalData[0].passingMarks) {
                        allSubjectsPassed = true;
                    }
                    kidSubjects.push(subject);
                }

                // Calculate total percentage
                let pntMarks = totalPracticalMarks + totalMarks;
                let pntMaxMarks =  totalPracticalMaxMarks + totalMaxMarks;
                const percentage = (pntMarks / pntMaxMarks) * 100;

                mainList.push({
                    id: kid.id,
                    kid: kid,
                    subjects: kidSubjects,
                    result: {
                        totalMarks: totalMarks + totalPracticalMarks,
                        totalMaxMarks: totalMaxMarks + totalPracticalMaxMarks,
                        totalMinMarks: totalMinMarks + totalPracticalMinMarks,

                        totalTheoryMarks: totalMarks,
                        totalMaxTheoryMarks: totalMaxMarks,
                        totalMinTheoryMarks: totalMinMarks,

                        totalPracticalMarks: totalPracticalMarks,
                        totalMaxPracticalMarks: totalPracticalMaxMarks,
                        totalMinPracticalMarks: totalPracticalMinMarks,


                        percentage: percentage.toFixed(2),
                        passed: allSubjectsPassed,
                    },
                });
            }
            setKidSubjectList(mainList);
        }
    }, [props.selectedKid, selectedExamKids, tableData, props.examResults]);

    const getExamSubjectResult = (kidId, subjectId, practicalId) => {
        for (let sr of props.examResults) {
            if (sr.attributes.kidId === kidId && sr.attributes.subjectId === subjectId && sr.attributes.practicalId === practicalId) {
                return sr;
            }
        }
    };

    const getClass = (totalPer) => {

        if (totalPer < 40) {
            return 'Fail'
        } else if (totalPer >= 40 && totalPer <= 49.99) {
            return 'Pass Marks'
        } else if (totalPer >= 50 && totalPer <= 59.99) {
            return 'Second Class'
        } else if (totalPer >= 60 && totalPer <= 79.99) {
            return 'First Class'
        } else if (totalPer >= 80) {
            return 'Distinction'
        }
    }


    const containerStyle = {
        width: '21cm',
        minHeight: '27cm',
        padding: '2cm',
        margin: '1cm auto',
        background: 'white',
        pageBreakBefore: 'always'
    };

    const getUI = (kid, kidSubjects) => {
        return (
            <div >
                {
                    !(props.selectedSchool) ? <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading.....</Label>
                        </div>

                    </Col> :
                        <div className="page font-size-15" style={containerStyle}>
                            {duplicate && <div className='d-none d-print-block' style={{
                                borderWidth: '4px',
                                borderStyle: 'solid',
                                borderColor: '#495057',
                                borderRadius: '8px',
                                color: '#495057',
                                opacity: 0.5,
                                position: 'absolute',
                                zIndex: 3,
                                //left: '40%',
                                marginTop: '-10%',
                                left: '78%',
                                fontSize: '25pt',
                                // transform: 'rotate(45deg)',
                                fontFamily: 'Helvetica Neue, Helvetica, Arial, sans-serif'
                            }}>
                                Duplicate
                            </div>}
                            <div style={{ marginTop: '3.7cm' }}>
                                <b style={{ marginLeft: '1.8cm' }}>{kid && kid.attributes.admissionNumber}</b>
                            </div>
                            <div style={{ height: '11cm', marginTop: '5.7cm' }}>
                                <div style={{ width: '15cm', height: '4cm', textAlign: 'center', marginTop: '-0.7cm' }}>
                                    <span className="d-print-none" style={{ width: '5cm' }}>This is to certify that</span>
                                    <span style={{ width: '11cm', marginLeft: '2.3cm' }}><b>{kid && kid.attributes.Name}</b></span>
                                </div>
                                <div className="d-print-none" style={{ width: '15cm', textAlign: 'center', height: '1.7cm' }}>
                                    {/* has succesfully completed the */}
                                </div>
                                <div style={{ width: '15cm', textAlign: 'center', height: '1cm', marginTop: '-1cm' }}>
                                    <b>{props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.name}</b>
                                </div>
                                <div style={{ width: '15cm', height: '1cm', textAlign: 'center', marginTop: '0.7cm' }}>
                                    <span style={{ width: '5cm' }} className="d-print-none">conducted in the year</span>
                                    <span style={{ width: '11cm', textAlign: 'center', marginLeft: '3.3cm' }}><b>{props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.tag ? props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.tag[0] : ''}</b></span>
                                </div>
                                <div style={{ width: '15cm', textAlign: 'center', height: '1cm', marginTop: '0.6cm', marginLeft: '0cm' }}>
                                    <b>{props.selectedSchool.attributes.Name}</b>
                                </div>
                                <div style={{ width: '15cm', textAlign: 'center', height: '1cm', marginTop: '0.1cm', marginLeft: '0cm' }}>
                                    <b>{props.selectedSchool.attributes.Address}</b>
                                </div>
                                {kidSubjects && <div style={{ width: '15cm', height: '1cm', textAlign: 'center', marginLeft: '1.3cm', marginTop: '0.8cm' }}>
                                    <span style={{ width: '5cm' }} className="d-print-none">and has been placed in</span>
                                    <b>{getClass(kidSubjects.result.percentage)}</b>
                                    <span style={{ width: '1cm' }} className="d-print-none">Division</span>
                                </div>}
                                <div style={{ width: '15cm', height: '1cm', marginTop: '2.1cm', marginLeft: '0.9cm' }}>
                                    <span style={{ width: '1cm' }} className="d-print-none">Date:</span>
                                    <b>{moment(props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.certificateDate).format("DD/MM/YYYY")}</b>
                                </div>
                            </div>
                        </div>
                }
            </div>
        )
    }

    return (
        <React.Fragment>
            <Header certificate={kidId ? 'Exam Certificate' : 'Exam Certificate'}
                matchGroup={matchGroup}
                exam={props.selectedExam && props.selectedExam}
                selectedKid={kidId ? props.selectedKid : ''}
                onClickSwitch={() => {
                    setDuplicate(!duplicate)
                }}
                duplicate={duplicate} />
            <div className="account-pages">
                {kidSubjectList && kidSubjectList.length > 0 ? <div className="account-pages">
                    {kidId && props.selectedKid && <Container fluid='md'>{getUI(props.selectedKid, kidSubjectList[0])}</Container>}

                    {
                        groupId && kidSubjectList.map((ksl) =>

                            <Container fluid='md' key={ksl.id}>{getUI(ksl.kid, ksl)}</Container>
                        )
                    }
                </div> :
                    <div className='text-center'>
                        {/* Loading.... */}
                    </div>

                }
            </div>
        </React.Fragment >

    )
}

const mapStatetoProps = (state) => {

    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { subjects } = state.Subjects;
    const { selectedExam } = state.Examination;
    const { examResults } = state.ExamResults;
    const { kids, admissionKids, selectedKid } = state.Kid;
    const { resultGroup, singleGroupHolder } = state.Group;
    const { groupmaps, resultGroupMapsAfterSave } = state.GroupMap;


    return { selectedKid, selectedSchool, classrooms, subjects, selectedExam, examResults, kids, groupmaps };
};

export default (withRouter(connect(mapStatetoProps, {
    getAKid, setSelecetdSchool, getClassroomsForSchoolId, getSubjectsForExamId, getExamForExamId, getExamResultsForExamId,
    getGroupForId, getGroupMapsForGroupId, getTeachersForSchool, getAKid, getExamResultsForExamIdAndKidId,
    getAllKidsForGroup, saveGroup, saveGroupMaps, getAllKids
})(ExamCertificate)));