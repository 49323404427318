import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil"

const initialState = {
  trips: undefined,
};

const trip = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_TRIPS:

      let updatedList = ReduxUtil.updateParseObjects(state.trips ? [...state.trips] : undefined, action.payload.trips, "isDeleted")
      state = { ...state, trips: updatedList };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default trip;
