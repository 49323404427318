import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Row, Label, Col, Input, Button, Container, Card, CardBody } from "reactstrap";
import { getFranchise } from '../../../store/actions';
import Parse from 'parse';
import { withNamespaces } from "react-i18next";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { matchPath } from "react-router";
import COUNTRY_JSON from "../../../assets/other/countries.json";
import Select from "react-select";
import logo from "../../../assets/images/lilLogo.png"
import toastr from 'toastr';
import Moment from 'moment';



const FranchiseAddEnquiry = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/franchise-add-enquiry/:franchiseId",
        exact: true,
        strict: false,
    });

    const match2 = matchPath(props.history.location.pathname, {
        path: "/franchise-branch-enquiry/:franchiseId",
        exact: true,
        strict: false,
    });

    const franchiseId = (match ?? match2).params.franchiseId;

    const [ownerName, setOwnerName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [country, setCountry] = useState({ value: "IN", label: "INDIA" });
    const [countryCodeList, setCountryCodeList] = useState([]);
    const [city, setCity] = useState('');
    const [area, setArea] = useState('');
    const [address, setAddress] = useState('');
    const [residentialAddress, setResidentialAddress] = useState('');
    const [expectedDate, setExpectedDate] = useState(undefined);
    const [notes, setNotes] = useState('');
    const [status, setStatus] = useState('');
    const [schoolName, setSchoolName] = useState('');

    const [ownerNameError, setOwnerNameError] = useState(false);
    const [phoneNumberError, setPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [countryError, setCountryError] = useState(false);
    const [cityError, setCityError] = useState(false);
    const [areaError, setAreaError] = useState(false);

    useEffect(() => {
        if (match) {
            if (Parse.User && Parse.User.current() && Parse.User.current().attributes.franchiseId) {
                props.getFranchise(Parse.User.current().attributes.franchiseId);
            } else {
                props.history.push("/pages-404");
            }
        } else {
            props.getFranchise(franchiseId)
        }

        // props.getFranchiseEnquiryForFranchiseId(franchiseId);
    }, [franchiseId]);

    useEffect(() => {
        const countries = COUNTRY_JSON.map(country => ({
            value: country.code,
            label: country.name
        }));
        setCountryCodeList(countries);
    }, []);

    const handleDateChange = (date) => {
        setExpectedDate(date);
    };

    const match2Ui = () => {
        return (
            match2 ? <Card style={{ marginLeft: "50px", marginRight: "50px" }}></Card> : <Card></Card>
        );
    }
    
    

    const validatePhoneNumber = (number) => {
        const phonePattern = /^([0-9]{10,16})$/;
        if (!number) {
            return "Phone number is required";
        } else if (!phonePattern.test(number)) {
            return "You have entered an invalid phone number!";
        }
        return "";
    };

    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email) {
            return "Email is required";
        } else if (!emailPattern.test(email)) {
            return "You have entered an invalid email address!";
        }
        return "";
    };

    const handleSubmit = () => {
        let isValid = true;

        if (!ownerName) {
            setOwnerNameError(true);
            isValid = false;
        }

        const phoneError = validatePhoneNumber(phoneNumber);
        if (phoneError) {
            setPhoneNumberError(phoneError);
            isValid = false;
        } else {
            setPhoneNumberError('');
        }

        const emailError = validateEmail(email);
        if (emailError) {
            setEmailError(emailError);
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!country) {
            setCountryError(true);
            isValid = false;
        }

        if (!city) {
            setCityError(true);
            isValid = false;
        }

        if (!area) {
            setAreaError(true);
            isValid = false;
        }

        if (isValid) {
            const FranchiseEnquiry = Parse.Object.extend('FranchiseEnquiry');
            const enquiry = new FranchiseEnquiry();

            enquiry.set("name", ownerName);
            enquiry.set("email", email);
            enquiry.set("phoneNumber", phoneNumber);
            enquiry.set("country", country.value);
            enquiry.set("city", city);
            enquiry.set("area", area);
            enquiry.set("address", address);
            enquiry.set("residentialAddress", residentialAddress);
            if (expectedDate) {
                enquiry.set("expectedDateOfStart", expectedDate);
            }
            enquiry.set("notes", notes);
            enquiry.set("franchiseId", franchiseId);

            enquiry.save().then(
                (result) => {
                    console.log(result);
                    toastr.success("Enquiry added successfully")
                    window.history.back();
                },
                (error) => {
                    console.error('Error saving Enquiry:', error);
                }
            );
        }
    };

    return (
        <React.Fragment>
            <div className={`page-content ${match2 ? 'p-0' : ''}`}>

                {match2 ? (
                    <Row className="m-0">
                        <Col className="p-0">
                            <div className="text-center">
                                <img
                                    src={(props.franchise && props.franchise.attributes.logo) ? props.franchise.attributes.logo._url : logo}
                                    alt=""
                                    className="rounded avatar-lg border"
                                />
                                <h4 className="text-primary">
                                    {props.franchise?.attributes?.name}
                                </h4>
                                <p>{props.franchise?.attributes?.city}</p>
                                {props.t("Welcome")}
                            </div>
                        </Col>
                    </Row>
                ) : (
                    <h3 className="text-center">{props.t("")}</h3>
                )}

                <h4 className="text-center">
                    {match2 ? props.t("Please provide the details") : props.t("")}
                </h4>

                <Row className="m-0">
                    <Col className="p-0">
                        <Card>{match2Ui()}
                            <CardBody>
                                <h5>{props.t("Enquiry Questions")}: </h5>
                                <hr />
                                <Row>
                                    <Label className="col-form-label col-lg-2 m-1">Name: <span className="text-danger">*</span></Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Enter Owner Name"
                                            value={ownerName}
                                            onChange={(e) => {
                                                setOwnerName(e.target.value);
                                                setOwnerNameError(false);
                                            }}
                                        />
                                        {ownerNameError && <small className="text-danger">Owner name is required</small>}
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">Email: <span className="text-danger">*</span></Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Enter Email"
                                            value={email}
                                            onChange={(e) => {
                                                setEmail(e.target.value);
                                                setEmailError(validateEmail(e.target.value));
                                            }}
                                        />
                                        {emailError && <div className="text-danger">{emailError}</div>}
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">Phone Number: <span className="text-danger">*</span></Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Enter Phone Number"
                                            value={phoneNumber}
                                            onChange={(e) => {
                                                setPhoneNumber(e.target.value);
                                                setPhoneNumberError(validatePhoneNumber(e.target.value));
                                            }}
                                        />
                                        {phoneNumberError && <div className="text-danger">{phoneNumberError}</div>}
                                    </Col>
                                    <Col lg="12">
                                        <Label className="col-form-label col-lg-2 m-1">Country <span className="text-danger">*</span></Label>
                                        <Select
                                            value={country}
                                            onChange={(v) => {
                                                setCountry(v);
                                                setCountryError(false);
                                            }}
                                            options={countryCodeList}
                                        />
                                        {countryError && <div className="text-danger">Country is required</div>}
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">City: <span className="text-danger">*</span></Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Enter City"
                                            value={city}
                                            onChange={(e) => {
                                                setCity(e.target.value);
                                                setCityError(false);
                                            }}
                                        />
                                        {cityError && <div className="text-danger">City name is required</div>}
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">Area: <span className="text-danger">*</span></Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Enter Area"
                                            value={area}
                                            onChange={(e) => {
                                                setArea(e.target.value);
                                                setAreaError(false);
                                            }}
                                        />
                                        {areaError && <div className="text-danger">Area is required</div>}
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">Address:</Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Type here"
                                            value={address}
                                            onChange={(e) => {
                                                setAddress(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">Residential Address:</Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Type here"
                                            value={residentialAddress}
                                            onChange={(e) => {
                                                setResidentialAddress(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">Expected Date of Start:</Label>
                                    <Col lg="12">
                                        <DatePicker
                                            selected={expectedDate}
                                            onChange={handleDateChange}
                                            className="form-control"
                                            dateFormat="dd/MM/yyyy"
                                            placeholderText="dd/mm/yyyy"
                                        />
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">Notes:</Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Type here"
                                            value={notes}
                                            onChange={(e) => {
                                                setNotes(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Label className="col-form-label col-lg-2 m-1">School Name:</Label>
                                    <Col lg="12">
                                        <Input
                                            placeholder="Type here"
                                            value={schoolName}
                                            onChange={(e) => {
                                                setSchoolName(e.target.value);
                                            }}
                                        />
                                    </Col>
                                    <Col className="btn waves-effect waves-light m-2">
                                        <Button
                                            type="button"
                                            color="primary"
                                            onClick={handleSubmit}
                                        >
                                            {props.t('Submit')}
                                        </Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>



    );
};

const mapStatetoProps = state => {
    const { franchise, franchiseEnquiry } = state.Franchise;
    return { franchise, franchiseEnquiry };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getFranchise,})(FranchiseAddEnquiry)));

