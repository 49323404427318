import * as actions from './actionTypes';

export const getEnquiryAnalytics = (dateFilter, schoolIds) => {
    return {
        type: actions.GET_ENQUIRY_ANALYTICS,
        payload: { dateFilter, schoolIds }
    }
}
export const updateEnquiryAnalytics = (enquiryAnalytics) => {
    return {
        type: actions.UPDATE_ENQUIRY_ANALYTICS,
        payload: { enquiryAnalytics }
    }
}

export const updateEnquiryAnalyticsForSchool = (enquiryAnalytics) => {
    return {
        type: actions.UPDATE_ENQUIRY_ANALYTICS_FOR_SCHOOL,
        payload: { enquiryAnalytics }
    }
}

export const getEnquiryAnalyticsFor10Days = (id,type) => {
    return {
      type: actions.GET_ENQUIRY_ANALYTICS_FOR_TEN_DAYS,
      payload: { id,type },
    };
  };
  
  export const updateEnquiryAnalyticsFor10Days = (enquiryAnalytics) => {
    return {
      type: actions.UPDATE_ENQUIRY_ANALYTICS_FOR_TEN_DAYS,
      payload: { enquiryAnalytics },
    };
  };