import React, {useState, useEffect } from 'react';
import { Row, Col, Form, Button, FormGroup, Label, UncontrolledTooltip, Progress} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {Element, animateScroll as scroll, scrollSpy,scroller,} from "react-scroll";

const FileList = (props) => {

    const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
    const _pdfExtensions = [".pdf", "pdf"];
    const _audioExtensions = [".mp3", "mp3", ".wma", "wma", ".acc", "acc", ".wav", "wav", ".flac", "flac",];

    const [filesList, setFilesList] = useState([]);

    const [hideRemoveButton, setHideRemoveButton] = useState(false);

    const [errorId, setErrorId] = useState(undefined);

    useEffect(() => {
        setFilesList(props.fileList)
    }, [props.fileList]);
    

    const handelRemoveFile = (file) => {
        let oList = [...filesList];
        let nList = oList.filter(
            (f) => f.id !== file.id
        );
        setFilesList(nList);
    }


    const setFileDownloadeble = (file, downloadeble) => {
        let temp = file;
        temp.downloadeble = downloadeble;
        setUpdateFile(file, temp)
    }

    const setUpdateFile = (oldFile,newFile) => {
        let oList = [...filesList];
        let index = oList.indexOf(oldFile);
        oList[index] = newFile;

        setFilesList(oList);
    }


    const onClickSaveAndUpload = () => {
        let errorId = undefined;
        let nList=[]
        for (const i in filesList) {
            let temp = filesList[i];
            if (!filesList[i].name  || filesList[i].name.length === 0) {
                if (!errorId) {
                    errorId = filesList[i].id;
                    
                }

                temp.nameError = true;
            }

            nList.push(temp)
        }

        if (errorId) {
            scroller.scrollTo(errorId, {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100,
            });
            setErrorId(errorId);
            setFilesList(nList);
        } else {
            setHideRemoveButton(true);
            props.handelFilesUpload(filesList);
        }
    }


    return (
        <React.Fragment >
            {
                filesList.map((file, i) => <Element name={file.id} key={file.id}><Row className="border mt-2">
                    
                    <Col sm={7} lg={7} xs={12} className="mt-2">
                        <AvForm className="form-horizontal" >
                            <Label htmlFor="formSingleLine">{ (i+1+". ")}Name:</Label>
                            <FormGroup >
                                
                                <AvField
                                    type="text"
                                    name="name" 
                                    required
                                    label={props.t('Name:')}
                                    placeholder={props.t('File Name')}
                                    className="form-control"
                                    value={file.name}

                                    onChange={(e) => {
                                        let temp = file;
                                        temp.name = e.target.value;
                                        if (e.target.value && e.target.value.length > 0) {
                                            temp.nameError = false;
                                        }
                                        setUpdateFile(file, temp)
                                    }}
                                />
                                {file.nameError && <p className="text-danger"> {props.t('File  name can not be empty')}</p>}
                            </FormGroup>

                            <Label  >Description:</Label>
                            <FormGroup>
                               
                                <AvField
                                    type="textarea"
                                    name="fileDescription"
                                    label={props.t('Description:')}
                                    rows="3"
                                    placeholder={props.t('Description')}
                                    className="form-control"
                                    value={file.description}
                                    onChange={(e) => {
                                        let temp = file;
                                        temp.description = e.target.value;
                                        setUpdateFile(file,temp)
                                    }}
                                />
                            </FormGroup>


                            {file.type === "File" && !_audioExtensions.includes(file.extention) && false&&<FormGroup>
                                <div className="custom-control custom-checkbox mb-2 ml-2" >
                                    <input type="checkbox" className="custom-control-input" id="downloadableCheck" onChange={() => { setFileDownloadeble(file,!file.downloadeble)}} checked={file.downloadeble} />
                                    <label className="custom-control-label" onClick={() => { setFileDownloadeble(file,!file.downloadeble); }} >{props.t('Downloadable')}</label>
                                </div>
                            </FormGroup>}
                            
                            
                        </AvForm>
                    </Col>
                    <Col sm={4} lg={4}>

                        <Row className="align-items-center">
                            {file.type === "Image" && <Col className="col-auto">
                                <img
                                    data-dz-thumbnail=""

                                    className="rounded m-3"
                                    width="260"
                                    height="260"
                                    alt={file.name}
                                    src={file.preview}
                                />
                            </Col>}
                            {
                                file.type !== "Image" &&
                                <div className="avatar ml-3 mt-3 border p-3">
                                    <span className="avatar-title bg-white text-primary h1">
                                        <i className={file.extentionIcon}></i>
                                    </span>
                                    <p>{file.actualFileName}</p>
                                </div>
                            }
                        </Row>
                    </Col>
                    <Col sm={1} lg={1}>
                        {!hideRemoveButton&& <ul className="list-inline font-size-20 contact-links mb-0 mt-2 float-right">
                            <li className="list-inline-item px-2" id={"file_"+file.id}
                                onClick={() => {
                                    handelRemoveFile(file);
                                }}
                            >
                                <i className="bx bx-message-square-x text-danger"></i>
                                <UncontrolledTooltip placement="top" target={"file_" + file.id}>
                                    {props.t('Remove')}
                                </UncontrolledTooltip>
                            </li>
                        </ul>}
                        </Col>
                   
                </Row> 
                </Element>)
            }

            {filesList && filesList.length > 0 && <div>{props.newFiles && props.newFiles.length > 0
                ?
                <div className="mb-30 mt-2">


                    <p className="text-info mb-2"> {props.t('Please do not close or refresh the page while uploading pdf/photo or any other files')}</p>
                    <Progress striped color="primary" value={props.uploadPogress}></Progress>
                    <h6 className="float-right mt-4 text-primary">{props.progressText}</h6>
                </div> :
                <button
                    className="btn btn-primary mt-2"
                    onClick={() => {
                        onClickSaveAndUpload();

                    }}
                >
                    {props.t('Upload & Save')}
                </button>}
            </div>
            }
        </React.Fragment>
        
    );
}
export default withNamespaces()(FileList);