import * as actions from "./actionTypes";

const initialState = {
  learningFilters: [],
};

const LearningFilter = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_LEARNING_FILTER:
      var learningFilters = [...state.learningFilters];
      var temp = state.learningFilters.filter(
        (learningFilter) =>
          learningFilter.id === action.payload.learningFilter.id
      );
      if (temp.length > 0) {
        if (action.payload.learningFilter.attributes.isDeleted) {
          var others = state.learningFilters.filter(
            (learningFilter) =>
              learningFilter.id !== action.payload.learningFilter.id
          );
          state = { ...state, learningFilters: others };
        } else {
          var index = learningFilters.indexOf(temp[0]);
          learningFilters[index] = action.payload.learningFilter;
          state = { ...state, learningFilters: learningFilters };
        }
      } else {
        if (!action.payload.learningFilter.attributes.isDeleted) {
          learningFilters.push(action.payload.learningFilter);
          state = { ...state, learningFilters: learningFilters };
        }
      }
      break;
    case actions.UPDATE_LEARNING_FILTERS:
      var learningFilters = [...state.learningFilters];
      if (learningFilters.length > 0) {
        for (const latest in action.payload.learningFilters) {
          var temp = learningFilters.filter(
            (learningFilter) =>
              learningFilter.id === action.payload.learningFilters[latest].id
          );

          if (temp.length > 0) {
            if (action.payload.learningFilters[latest].attributes.isDeleted) {
              var others = learningFilters.filter(
                (learningFilter) =>
                  learningFilter.id !==
                  action.payload.learningFilters[latest].id
              );

              learningFilters = others;
            } else {
              var index = learningFilters.lastIndexOf(temp[0]);
              learningFilters[index] = action.payload.learningFilters[latest];
            }
          } else {
            learningFilters.push(action.payload.learningFilters[latest]);
          }
        }
        state = { ...state, learningFilters: learningFilters };
      } else {
        state = { ...state, learningFilters: action.payload.learningFilters };
      }
      break;
    case actions.DELETE_LEARNING_FILTER:
      var learningFilters = state.learningFilters.filter(
        (learningfilter) => learningfilter.id !== action.payload.id
      );
      state = { ...state, learningFilters: learningFilters };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LearningFilter;
