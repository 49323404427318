import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Table, Pagination, PaginationItem, PaginationLink, } from "reactstrap";

const PaginationView = (props) => {

    const [currentPage, setCurrentPage] = useState('1');
    const [paginationList, setPaginationList] = useState([])

    useEffect(() => {
        if (props.listSize === 0) {
            setPaginationList(["1"]);
            setCurrentPage("1")
        } else {
            const countPages = props.listSize / + props.pageSize;
            const pages = Math.ceil(countPages);
            let pagination = [];
            for (let i = 1; i <= pages; i++) {
                pagination.push("" + i);
            }
            setCurrentPage("1")
            setPaginationList(pagination);
        }
    }, [props.listSize]);

    useEffect(() => {
       props.handelCurrentPage(currentPage)
    }, [currentPage]);
    

    const getPaginationList = () => {
        if (paginationList.length <= props.initialInterval) {
            return paginationList
        } else {

            let is = 0;

            let cp = parseInt(currentPage)
            if (cp - props.intervalSize > 0) {
                is = cp - props.intervalSize
            }

            let ie = (paginationList.length > cp + props.intervalSize) ? cp + props.intervalSize : (paginationList.length - 1);

            let tempPages = [];
            for (let i = is + 1; i <= ie + 1; i++) {
                tempPages.push(i + "")
            }
            return tempPages
        }
    }

    return (
        <div>
            <Pagination
                aria-label="Page navigation example"
                listClassName="justify-content-end"
            >
                <PaginationItem disabled={currentPage === "1"}>
                    <PaginationLink
                        onClick={() => setCurrentPage(+currentPage - 1 + "")}
                        href="#"
                        tabIndex="-1"
                    >
                        Previous
                    </PaginationLink>
                </PaginationItem>
                {getPaginationList().map((val, key) => (
                    <PaginationItem active={currentPage === val} key={key}>
                        <PaginationLink
                            onClick={() => setCurrentPage(val)}
                            href="#"
                        >
                            {val}{" "}
                            {currentPage === val && (
                                <span className="sr-only">(current)</span>
                            )}
                        </PaginationLink>
                    </PaginationItem>
                ))}
                <PaginationItem
                    disabled={currentPage === paginationList.length + ""}
                >
                    <PaginationLink
                        href="#"
                        onClick={() => setCurrentPage(+currentPage + 1 + "")}
                    >
                        Next
                    </PaginationLink>
                </PaginationItem>
            </Pagination>
        </div>
    )
}

export default PaginationView