import React, { useState, useEffect, useRef } from 'react';
import { withRouter, Link } from 'react-router-dom';
import Parse from 'parse';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { Card, Row, Col, CardBody, CardTitle, CardSubtitle, Container, Table, Form, Progress, FormGroup, Label, Media, Modal, ModalBody, UncontrolledTooltip, CardText, ModalHeader, Nav, NavItem, NavLink } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import VimeoUpload from "vimeo-upload-me";
import Iframe from "react-iframe";
import Lightbox from "react-image-lightbox";
import SweetAlert from "react-bootstrap-sweetalert";
import "react-image-lightbox/style.css";
import { v4 as uuidv4 } from "uuid";
import $ from "jquery";
import { EditorState } from 'draft-js';
import TopBar from './Topbar';
import uploadImage from '../../assets/images/undraw_uploading.svg';
import content_creating from '../../assets/images/lms/content_creating.svg'
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import * as CommonFunctions from "../../components/Common/CommonFunctions";
import { setSelecetdLearningActivity, getFilesForFolderId, AddFile, deleteFile, setSelecetdSchool, addLearningActivity, deleteLearningActivity, setSelectedCurriculum } from '../../store/actions';
import DocViewer, { DocViewerRenderers, BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";
import ReactAudioPlayer from 'react-audio-player';
import classnames from 'classnames';
import * as WhiteLabel from "../../components/Common/WhiteLable"
// import html2canvas from 'html2canvas';


import FileListComponent from './FileList';

const ActivityContentList = (props) => {

    const UploadfilesTypes = [
        // { id:"input-video", name: "Video", icon:"bx bxs-video ",size: "mp4,mov..", link: "#" },
        { id: "input-image", name: "Image/Photo", icon: "bx bxs-file-image ", size: "jpg,png..", link: "#" },
        { id: "input-file", name: "Document", icon: "bx bxs-file-doc ", size: "pdf,doc,xlsx,zip..", link: "#" },
        { id: "input-audio", name: "Audio", icon: "bx bxs-music ", size: "mp3,wav,wma,flac..", link: "#" },

    ];
    const NonUploadfilesTypes = [

        // { id:"create-blog",name: "Blog",icon:"bx bxs-dashboard", size: "Free text", link: "#" },
        { id: "weblink", name: "Weblink", icon: "bx bx-link-alt ", size: "Links,URL", link: "#" },
        // { id: "assignment", name: "Assignment", icon: "bx bx-link-alt ", size: "Links,URL", link: "#" },
        // { id:"slip-test",name: "Slip Test",icon:"bx bx-link-alt ", size: "Links,URL", link: "#" },
    ];
    const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 27, 29, 30, 31];
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,];


    const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
    const _pdfExtensions = [".pdf", "pdf"];
    const _audioExtensions = [".mp3", "mp3", ".wma", "wma", ".acc", "acc", ".wav", "wav", ".flac", "flac",];


    const [hideButton, setHideButton] = useState(false);
    const [folderId, setFolderId] = useState(undefined);
    const [progressText, setProgressText] = useState(false);
    const [uploadPogress, setUploadProgress] = useState(undefined);
    const [nameError, setNameError] = useState(false);
    const [actualFileName, setActualFileName] = useState(undefined);
    const [fileName, setFileName] = useState(undefined);
    const [fileType, setFileType] = useState(undefined);
    const [preview, setPreview] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [fileExtention, setFileExtention] = useState(undefined);
    const [fileDownloadeble, setFileDownloadeble] = useState(true);

    const [weblink, setWeblink] = useState(undefined);
    const [weblinkError, setWeblinkError] = useState(undefined);

    const [fileDescription, setFileDescription] = useState(undefined);
    const [isUploadProgress, setIsUploadProgress] = useState(false);
    const [lightBox, setLightBox] = useState(undefined);

    const [showModel, setShowModel] = useState(false);
    const [clickedFile, setClickFile] = useState(undefined);
    const [loadingState, setLoadingState] = useState(1);
    const [deleteFileObject, setDeleteFile] = useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);

    const [editorState, setEditorState] = useState(EditorState.createEmpty())

    const [editFile, setEditFile] = useState(undefined);
    const [editFileDownloadeble, setEditFileDownloadeble] = useState(false);
    const imageRef = useRef(null);

    const [customActiveTab, setcustomActiveTab] = useState("1");
    function toggleCustom(tab) {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    }

    var match = matchPath(props.history.location.pathname, {
        path: "/lms-chapter-content/:schoolId/:id",
        exact: true,
        strict: false,
    });
    if (!match) {
        match = matchPath(props.history.location.pathname, {
            path: "/lms-chapter-content/:schoolId/:curriculumId/:cycleId/:id",
            exact: true,
            strict: false,
        });
    }

    const curriculumId = match.params.curriculumId;
    const cycleId = match.params.cycleId;

    const parameter = match.params.id;
    const schoolId = match.params.schoolId;


    useEffect(() => {
        props.getFilesForFolderId(1);
        props.setSelecetdLearningActivity(parameter);
    }, [parameter]);
    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (!props.selectedCurriculum || props.selectedCurriculum.id !== curriculumId) {
            props.setSelectedCurriculum(curriculumId);
        }
    }, [curriculumId]);

    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setCanEdit(CommonFunctions.canEdit(props.selectedCurriculum, props.userTeacher));
    }, [props.selectedCurriculum, props.userTeacher]);



    useEffect(() => {
        if (props.selcetedActivity && props.selcetedActivity.id === parameter) {
            if (!folderId) {
                if (props.selcetedActivity.attributes.resourceFolderId && props.selcetedActivity.attributes.resourceFolderId.length > 0) {
                    setFolderId(props.selcetedActivity.attributes.resourceFolderId)
                    props.getFilesForFolderId(props.selcetedActivity.attributes.resourceFolderId);
                } else {
                    props.getFilesForFolderId(3);
                    setFolderId(uuidv4());
                }
            }
        }
    }, [props.selcetedActivity]);

    useEffect(() => {
        setLoadingState(props.loadingFileState);
        if (props.selcetedActivity && props.selcetedActivity.id === parameter && props.loadingFileState !== 1 && props.loadingFileState != 2) {
            handelAddContent()
        }
    }, [props.loadingFileState]);

    function videoUploadPreviev(e) {
        setFileType("Video");
        setSelectedFile(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
        var fileExtension = e.target.files[0].name.split(".");
        setActualFileName(e.target.files[0].name);
        setFileName(fileExtension[0]);
        setFileExtention(fileExtension[(fileExtension.length - 1)].toLowerCase());
        scrollToContent();
    }


    function onVideoUpload() {
        if (selectedFile) {
            setProgressText(props.t('Preparing For upload'));
            const accessToken = "bc7a5ee29880658b6f3689a45e63c5ec";
            setUploadProgress(1);

            var uploader = new VimeoUpload({
                name: fileName,
                description: fileDescription,
                file: selectedFile,
                token: accessToken,
                upgrade_to_1080: true,
                privacy: { view: "unlisted" },
                onError: (data) => {
                    console.log(data);
                    setProgressText(props.t('Error while uploading video, Please refresh the page and try again'))
                },
                onProgress: (data) => {
                    let value = Math.floor((data.loaded / data.total) * 100);
                    setUploadProgress(value);
                    setProgressText(props.t('Uploading....') + ": " + Math.round(value) + "%")
                },
                onComplete: (videoId, index) => {

                    setProgressText(props.t('Adding video to topic'))
                    var settings = {
                        url: "https://api.vimeo.com/videos/" + videoId,
                        method: "PATCH",
                        timeout: 0,
                        headers: {
                            Authorization: "Bearer bc7a5ee29880658b6f3689a45e63c5ec",
                            "Content-Type": "application/json",
                        },
                        data: JSON.stringify({ privacy: { view: "unlisted" } }),
                    };
                    console.log(settings);
                    var res = videoId.split(":")
                    var vimeoUrl = "https://vimeo.com/" + res[0] + "/" + res[1];
                    var Files = Parse.Object.extend("Files");
                    var fileObject = new Files();
                    fileObject.set("folderId", folderId);
                    fileObject.set("orderBy", props.files.length);
                    fileObject.set("fileType", "vimeo");
                    fileObject.set("url", vimeoUrl);
                    fileObject.set("name", fileName);
                    if (fileDescription) {
                        fileObject.set("fileDescription", fileDescription);
                    }


                    $.ajax(settings).done((response) => {
                        fileObject.set("introThumbnail", response.pictures.sizes[4].link);
                        updateActivityWithFolderId(fileObject);
                    });
                },
            });
            uploader.upload();
        }
    }

    function ImageUploadPreviev(e) {
        setFileType("Image");
        // setSelectedFile(e.target.files[0]);
        // var fileExtension = e.target.files[0].name.split(".");
        // setActualFileName(e.target.files[0].name);
        // setFileName(fileExtension[0]);
        // setFileExtention(fileExtension[fileExtension.length-1].toLowerCase()? fileExtension[fileExtension.length-1].toLowerCase() : "jpeg" );
        // setPreview(URL.createObjectURL(e.target.files[0]));
        scrollToContent();


        let tempFileList = [];
        console.log(e.target.files.length);
        for (const i in e.target.files) {

            if (e.target.files.length > i) {
                let tempFile = e.target.files[i];
                if (tempFile && tempFile.name) {
                    let tempfileExtension = tempFile.name.split(".");
                    let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
                    let newFile = {
                        id: "fiele_" + i,
                        file: tempFile,
                        type: "Image",
                        downloadeble: true,
                        actualFileName: tempFile.name,
                        name: tempfileExtension[0],
                        extention: tempExt,
                        extentionIcon: getFileTypeImage(tempExt),
                        preview: URL.createObjectURL(tempFile)
                    }
                    tempFileList.push(newFile);
                }
            }
        }

        setFileList(tempFileList);
    }

    function onImageUpload() {

        var name = fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') + "." + fileExtention;

        getBase64(selectedFile, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            setProgressText(props.t('Preparing For upload'));
            setUploadProgress(1);
            parseFile.save({

                progress: (value) => {
                    if (value && value > 0.1) {
                        setUploadProgress(value * 100);
                        setProgressText(props.t('Uploading....') + ": " + Math.round(value * 100) + "%");
                    }
                }

            }).then(
                result => {
                    var Files = Parse.Object.extend("Files");
                    var fileObject = new Files();
                    fileObject.set("folderId", folderId);
                    fileObject.set("orderBy", props.files.length);
                    fileObject.set("fileType", "image");
                    fileObject.set("file", result);
                    fileObject.set("name", fileName);
                    if (fileDescription) {
                        fileObject.set("fileDescription", fileDescription);
                    }
                    setProgressText(props.t('Adding Image to topic'))
                    updateActivityWithFolderId(fileObject);
                },
                error => {
                    setProgressText(props.t('Error while uploading Image/Photo, Please refresh the page and try again.') + '\n' + props.t('Please make sure no special characters in file/image name'))
                }

            );
        });
    }
    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };

    }


    const [fileList, setFileList] = useState([]);
    function fileUploadPreviev(e) {



        if (fileExtention && _imgExtensions.includes(fileExtention.toLowerCase())) {
            ImageUploadPreviev(e);
        } else {
            setFileType("File");
            // setFileDownloadeble(true);
            // setSelectedFile(e.target.files[0]);
            // setActualFileName(e.target.files[0].name);
            // var fileExtension = e.target.files[0].name.split(".");
            // setFileName(fileExtension[0]);
            // setFileExtention(fileExtension[1].toLowerCase());
            scrollToContent();

            let tempFileList = [];
            console.log(e.target.files.length);
            for (const i in e.target.files) {

                if (e.target.files.length > i) {
                    let tempFile = e.target.files[i];
                    if (tempFile && tempFile.name) {
                        let tempfileExtension = tempFile.name.split(".");
                        let tempExt = tempfileExtension[(tempfileExtension.length - 1)];
                        let newFile = {
                            id: "fiele_" + i,
                            file: tempFile,
                            type: "File",
                            downloadeble: true,
                            actualFileName: tempFile.name,
                            name: tempfileExtension[0],
                            extention: tempExt,
                            extentionIcon: getFileTypeImage(tempExt)
                        }
                        tempFileList.push(newFile);
                    }
                }
            }

            setFileList(tempFileList);


        }


    }

    function onFileUpload() {

        var name = fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');

        getBase64(selectedFile, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            setUploadProgress(1);
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setUploadProgress(value * 100);
                        setProgressText(props.t('Uploading....') + ": " + Math.round(value * 100) + "%")
                    }
                }
            }).then(
                result => {
                    var Files = Parse.Object.extend("Files");
                    var fileObject = new Files();
                    fileObject.set("folderId", folderId);
                    fileObject.set("orderBy", props.files.length);

                    if (_imgExtensions.includes(fileExtention.toLowerCase())) {
                        fileObject.set("fileType", "image");
                    } else if (_pdfExtensions.includes(fileExtention.toLowerCase())) {
                        fileObject.set("fileType", "file");
                        fileObject.set("downloadable", fileDownloadeble);
                    } else if (_audioExtensions.includes(fileExtention.toLowerCase())) {
                        fileObject.set("fileType", "audio");
                    }
                    if (fileDescription) {
                        fileObject.set("fileDescription", fileDescription);
                    }
                    fileObject.set("file", result);
                    fileObject.set("name", fileName);

                    setProgressText(props.t('Adding document to topic'))
                    updateActivityWithFolderId(fileObject);
                },
                error => {
                    setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                }
            );
        });
    }


    const [uploadofFiles, setUploadofFiles] = useState(-1);
    const [newFiles, setNewFiles] = useState([]);
    const handelFilesUpload = (filesList) => {
        console.log(filesList);
        setNewFiles(filesList);
        setUploadofFiles(0);

        console.log("P:-1")
    }

    useEffect(() => {

        console.log("P:1: " + uploadofFiles);
        if (uploadofFiles >= 0) {
            console.log("P:2=" + uploadofFiles);
            if (newFiles.length > uploadofFiles) {
                onFileListUpload(newFiles[uploadofFiles], uploadofFiles);
            } else {
                setUploadofFiles(-1);
                setNewFiles([]);
                setHideButton(false);
                scrollToButtom();
                setLoadingState(2);
                setProgressText("")

            }

        }

    }, [uploadofFiles, newFiles]);

    function onFileListUpload(file, count) {

        console.log("P:3" + count)
        var name = file.name.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');
        if (file.type === "Image") {
            name = name + "." + file.extention;
        }

        if (file.file.name.includes(".zip") || file.file.name.includes(".rar")) {
            name = file.file.name;
        }

        setProgressText(props.t('Uploading ') + (count + 1) + " of " + newFiles.length)
        let oneFileWhite = 100 / newFiles.length;
        let percentOffSet = oneFileWhite * count;
        getBase64(file.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            setUploadProgress(1);
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        let newProgress = percentOffSet + (value * oneFileWhite);
                        setUploadProgress(newProgress);

                    }
                }
            }).then(
                result => {
                    var Files = Parse.Object.extend("Files");
                    var fileObject = new Files();
                    fileObject.set("folderId", folderId);
                    fileObject.set("orderBy", (props.files.length + count));

                    if (_imgExtensions.includes(file.extention.toLowerCase())) {
                        fileObject.set("fileType", "image");
                    } else if (_pdfExtensions.includes(file.extention.toLowerCase())) {
                        fileObject.set("fileType", "file");
                        fileObject.set("downloadable", file.downloadeble);
                    } else if (_audioExtensions.includes(file.extention.toLowerCase())) {
                        fileObject.set("fileType", "audio");
                    }
                    if (file.description) {
                        fileObject.set("fileDescription", file.description);
                    }
                    fileObject.set("file", result);
                    fileObject.set("name", file.name);

                    //setProgressText(props.t('Adding document to topic'))


                    if (props.selcetedActivity.attributes.resourceFolderId && props.selcetedActivity.attributes.resourceFolderId.length > 0) {
                        props.AddFile(fileObject, props.t('Content added successfully'));
                    } else {
                        var activity = props.selcetedActivity;
                        activity.set("resourceFolderId", folderId);
                        activity.save().then(
                            (result) => {
                                result.pin();
                                props.AddFile(fileObject, props.t('Content added successfully'));
                            },
                            (error) => { }
                        );
                    }

                    if (newFiles.length > uploadofFiles) {
                        setUploadofFiles((uploadofFiles + 1))
                    } else {
                        setUploadofFiles(-1);
                        setNewFiles([]);
                        setHideButton(false);
                        scrollToButtom();
                        setLoadingState(2);
                        setUploadProgress(0);
                    }

                },
                error => {
                    setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                }
            );
        });
    }

    function crateWeblink() {
        var Files = Parse.Object.extend("Files");
        var fileObject = new Files();
        fileObject.set("folderId", folderId);
        fileObject.set("orderBy", props.files.length);

        fileObject.set("fileType", "url");

        fileObject.set("url", weblink);
        fileObject.set("name", fileName);
        if (fileDescription) {
            fileObject.set("fileDescription", fileDescription);
        }
        setProgressText(props.t('Adding weblink to topic'))
        updateActivityWithFolderId(fileObject);
        scrollToContent();
    }

    function updateActivityWithFolderId(fileObject) {


        if (props.selcetedActivity.attributes.resourceFolderId && props.selcetedActivity.attributes.resourceFolderId.length > 0) {
            props.AddFile(fileObject, props.t('Content added successfully'));
            setHideButton(false);
            scrollToButtom();
            setLoadingState(2);
        } else {
            var activity = props.selcetedActivity;
            activity.set("resourceFolderId", folderId);
            activity.save().then(
                (result) => {
                    result.pin();
                    props.AddFile(fileObject, props.t('Content added successfully'));
                    setHideButton(false);
                    scrollToButtom();
                    setLoadingState(2);
                },
                (error) => { }
            );
        }

    }

    function handelAddActivity(name, activityDescription, activity) {

        var learningActivity = activity;
        learningActivity.set("name", name);
        learningActivity.set("description", activityDescription);
        if (activityDay) {
            learningActivity.set("day", parseInt(activityDay));
        }
        if (activityMonth) {
            learningActivity.set("month", parseInt(activityMonth));
        }

        props.addLearningActivity(learningActivity);

        setEditActivity(undefined);
        setActivityDay(undefined);
        setActivityMonth(undefined);

    }

    function scrollToButtom() {
        scroller.scrollTo('ScreenBottom', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 0,
        });
    }
    function scrollToContent() {
        scroller.scrollTo('contentBlock', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 0,
        });
    }



    function handelAddContent() {

        if (!hideButton) {
            setFileType(undefined);
            setSelectedFile(undefined);
            setFileName(undefined);
            setFileExtention(undefined);
            setHideButton(true);
            setNameError(false);
            setWeblink(undefined);
            setActualFileName(undefined);
            setIsUploadProgress(false);
            setUploadProgress(0);
            setFileDescription(undefined);
        }
    }
    function handelOnClickBack() {
        window.history.back();
    }

    function isValidURL(string) {

        // var res = string.match(/(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&/=]*)/g);
        // return (res !== null)

        if (string.startsWith("https://")) {
            return true;
        }
        if (string.startsWith("http://")) {
            return true;
        }
        if (string.startsWith("www.")) {
            return true;
        }
        return false;

    };


    function getFileTypeImage(fileExtention) {

        if (fileExtention === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtention === "ppt" || fileExtention === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtention === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtention === "doc" || fileExtention === "docx") {
            return "bx bxs-file-doc";
        } else if (fileType === "Video") {
            return "bx bx-play-circle";
        } else if (_audioExtensions.includes(fileExtention)) {
            return "bx bxs-music";
        }
        return "bx bxs-file-blank";

    }

    function getFileTypeFromUrl(url) {
        var res = url.split(".");
        var res = res[res.length - 1];
        if (_imgExtensions.includes(res)) {
            return { type: "image", ext: res };
        } else if (_audioExtensions.includes(res)) {
            return { type: "audio", ext: res };
        } else if (_pdfExtensions.includes(res)) {
            return { type: "pdf", ext: res };
        } else {
            return { type: "file", ext: res };
        }
    }

    function getURL() {

        if (clickedFile) {
            if (clickedFile.attributes.fileType === "url") {
                return clickedFile.attributes.url
            } else {
                return clickedFile.attributes.file._url;
            }
        }
    }

    function getFileName() {

        if (clickedFile) {
            if (clickedFile.attributes.fileType === "url") {
                return clickedFile.attributes.name
            } else {
                let res = clickedFile.attributes.file._name.split("_")
                return res[res.length - 1];
            }
        }
    }

    const getFR = (url) => {
        const docs = [
            { uri: url },
        ];
        return docs;
    }

    function onEditorStateChange(editorState) {
        setEditorState(editorState);

    };

    function getVimeoUrl(link) {
        var splits = link.split("/")
        var videoId = splits[splits.length - 2];
        var videokey = splits[splits.length - 1];
        return "https://player.vimeo.com/video/" + videoId + "?h=" + videokey;
    }

    useEffect(() => {
        if (!props.loadingState) {
            setEditActivity(undefined);
            setActivityName("");
            setActivityDescription("")
        }

    }, [props.loadingState]);
    const [editActivity, setEditActivity] = useState(undefined);
    const [deleteActivity, setDeleteActivity] = useState(undefined)
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [activityName, setActivityName] = useState(undefined);
    const [activityDescription, setActivityDescription] = useState(undefined);
    const [activityDay, setActivityDay] = useState(1);
    const [activityMonth, setActivityMonth] = useState(1);

    const youtubeParser = (url) => {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
    }

    const editActivityUI = (activity) => {
        return (<Col className="border-top  " style={{ borderTopWidth: 3 }}>
            <Card className="mt-2">
                <CardBody>

                    <AvForm className="outer-repeater"
                        onValidSubmit={(e, v) => {
                            handelAddActivity(activityName, activityDescription, activity);
                        }}
                    >
                        <div data-repeater-item className="outer">
                            <FormGroup >
                                <Label htmlFor="formname">{props.t('Edit New Topic')}: </Label>
                                <AvField
                                    type="text"
                                    name="name"
                                    placeholder={props.t('Topic name')}
                                    className="form-control"
                                    value={activityName}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: props.t('Topic name can not be empty'),
                                        },
                                    }}
                                    onChange={(e) => {
                                        setActivityName(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup >
                                <Label htmlFor="formname">{props.t('Description')}: </Label>
                                <textarea
                                    type="text"
                                    name="name"
                                    placeholder={props.t('Description')}
                                    className="form-control"
                                    value={activityDescription}

                                    onChange={(e) => {
                                        setActivityDescription(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <Row>
                                <Col lg={4}>

                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('Month')}: </Label>
                                        <select className="form-control"
                                            onChange={(e) => {
                                                setActivityMonth(e.target.value)
                                            }}
                                            value={activityMonth}
                                        >

                                            {months.map((item, key) => (
                                                <option key={key}>{item}</option>
                                            ))}
                                        </select>

                                    </FormGroup>
                                    <p className="m-2 text-info font-size-8">{props.t('Month-1 is starting month of your academic cycle, it may be April, June or July based on your location/region')}</p>

                                </Col>
                                <Col lg={4}>
                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('Day')}: </Label>
                                        <select className="form-control"
                                            onChange={(e) => {
                                                setActivityDay(e.target.value)
                                            }}
                                            value={activityDay}
                                        >

                                            {days.map((item, key) => (
                                                <option key={key}>{item}</option>
                                            ))}

                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>

                            <button type="button"
                                className="btn btn-secondary m-1"
                                onClick={() => {

                                    setActivityName("");
                                    setActivityDescription("")
                                    setEditActivity(undefined);
                                    setActivityDay(undefined);
                                    setActivityMonth(undefined);
                                }}  >
                                {props.t('Close')}
                            </button>
                            {
                                props.loadingState ? <button type="button" className="btn btn-dark waves-effect waves-light float-right m-1">
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                                </button> :
                                    <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light float-right m-1">
                                        <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save Topic')}
                                    </button>
                            }
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid={true}>
                    <TopBar title={"TOPIC CONTENT"}
                        buttonName={"Add Content"}
                        backButton={true}
                        hideButton={canEdit ? hideButton : true}
                        onClickAdd={handelAddContent}
                        onClickBackButton={handelOnClickBack}
                    />
                    <Card>
                        <CardBody>
                            {props.selcetedActivity && props.selcetedActivity.id === parameter ?

                                <div>

                                    {editActivity ?
                                        <div>
                                            {editActivityUI(editActivity)}
                                        </div> :

                                        <Row>
                                            <Col lg={10}>
                                                <CardTitle>{props.t('TOPIC') + ": " + props.selcetedActivity && props.selcetedActivity.attributes.name}</CardTitle>
                                                {props.selcetedActivity.attributes.description && <CardText>{"Description: " + props.selcetedActivity.attributes.description}</CardText>}
                                                <CardSubtitle>{
                                                    props.selcetedActivity.attributes.month && props.selcetedActivity.attributes.day ?
                                                        "Month: " + props.selcetedActivity.attributes.month + ", " + "Day: " + props.selcetedActivity.attributes.day
                                                        : ""}
                                                </CardSubtitle>
                                            </Col>
                                            <Col lg={2} className='d-flex justify-content-end'>
                                                {canEdit && <ul className="list-inline font-size-18 contact-links mb-0  float-right">

                                                    <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setEditActivity(props.selcetedActivity);
                                                            setActivityName(props.selcetedActivity.attributes.name);
                                                            setActivityDescription(props.selcetedActivity.attributes.description)
                                                            setActivityDay(props.selcetedActivity.attributes.day ? props.selcetedActivity.attributes.day + "" : "1");
                                                            setActivityMonth(props.selcetedActivity.attributes.month ? props.selcetedActivity.attributes.month + "" : "1");
                                                        }}
                                                        id={"activity-edit"}>
                                                        <i className="bx bx-edit-alt text-dark"></i>
                                                        <UncontrolledTooltip placement="top" target={"activity-edit"}>
                                                            {props.t('Edit')}
                                                        </UncontrolledTooltip>

                                                    </li>
                                                    <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (props.files && props.files.length > 0) {
                                                                setDeleteWarning(true);
                                                            } else {
                                                                setDeleteActivity(props.selcetedActivity);
                                                            }

                                                        }}
                                                        id={"activity-delete"}>
                                                        <i className="bx bx-trash text-danger"></i>
                                                        <UncontrolledTooltip placement="top" target={"activity-delete"}>
                                                            {props.t('Delete')}
                                                        </UncontrolledTooltip>

                                                    </li>


                                                </ul>}

                                            </Col>
                                        </Row>}
                                </div>
                                :
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading </Label>
                                    </div>
                                </Col>

                            }

                        </CardBody>
                    </Card>

                    {/* <Card className="mb-4">
                        <CardBody>
                            <Nav pills className="navtab-bg nav-justified ">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1");
                                        }}
                                    >
                                        {props.t('Content').toUpperCase()}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2");
                                        }}
                                    >
                                        {props.t('Assigments').toUpperCase()}
                                    </NavLink>
                                </NavItem>

                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleCustom("3");
                                        }}
                                    >
                                        {props.t('Slip Tests').toUpperCase()}
                                    </NavLink>

                                </NavItem>


                            </Nav>


                        </CardBody>
                    </Card> */}

                    {
                        !canEdit && loadingState !== 2 && loadingState !== 1 && <Card>
                            <CardBody className="text-center">
                                <CardTitle className="mb-4"> {props.t('No content Found')} </CardTitle>
                            </CardBody>
                        </Card>
                    }

                    {props.selcetedActivity && props.selcetedActivity.id === parameter && loadingState !== 2 && !hideButton && <Card>
                        <CardBody className="text-center">
                            {loadingState === 1 ?
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Content </Label>
                                    </div>
                                </Col>
                                :
                                <Col xs="12">
                                    <Row className="justify-content-center">
                                        <Col sm={4}>
                                            <div className="maintenance-img">
                                                <img src={content_creating} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row>

                                    {canEdit ? <div>
                                        <p className="mt-5">{props.t('No Content found please click on Add button to add')}</p>

                                        <button type="button"
                                            className="btn btn-primary "
                                            onClick={() => {
                                                handelAddContent();
                                            }}  >
                                            {props.t('Add')}
                                        </button>

                                    </div> :
                                        <CardTitle className="mb-4"> {props.t('No content Found')} </CardTitle>
                                    }

                                </Col>
                            }

                        </CardBody>
                    </Card>}

                    {canEdit && hideButton && <Card>
                        <CardBody>
                            <CardBody>
                                <Row>
                                    <Col sm={7}>
                                        <CardTitle className="mb-4"> {props.t('What type content you want to upload?')} </CardTitle>
                                        <div className="table-responsive">
                                            <Table className="table table-nowrap table-centered table-hover mb-0 bordered">
                                                <tbody>
                                                    {UploadfilesTypes.map((file, i) => (
                                                        <tr key={"_file_" + i}>
                                                            <Label htmlFor={file.id} className='d-flex'>
                                                                <td style={{ width: "45px" }}>
                                                                    <div className="avatar-sm mb-2">
                                                                        <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24 mb-2">
                                                                            <i className={file.icon}></i>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td style={{ width: "140px", marginLeft: '40px' }}>
                                                                    <h5 className="font-size-14 mb-2 text-dark">{file.name}</h5>
                                                                    <small> {file.size}</small>
                                                                </td>
                                                                <td>
                                                                    <div className="text-center">
                                                                        <div className="text-dark">
                                                                            <i className="bx bx-upload h3 m-0"></i>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </Label>
                                                        </tr>
                                                    ))}
                                                    {NonUploadfilesTypes.map((file, i) => (
                                                        <tr key={"_file_" + i}
                                                            onClick={() => {
                                                                if (file.id === "create-blog") {
                                                                    setFileType("Blog")
                                                                } else if (file.id === "weblink") {
                                                                    setFileType("Weblink")
                                                                } else if (FileList.id = "assignment") {
                                                                    setFileType("Assignment")
                                                                }
                                                            }}>
                                                            <Label htmlFor={file.id} className='d-flex'>
                                                                <td style={{ width: "45px" }}>
                                                                    <div className="avatar-sm mb-2">
                                                                        <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24 mb-2">
                                                                            <i className={file.icon}></i>
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                                <td style={{ width: "140px", marginLeft: '40px' }}>
                                                                    <h5 className="font-size-14 mb-2 text-dark">{file.name}</h5>
                                                                    <small> {file.size}</small>
                                                                </td>
                                                                <td>
                                                                    <div className="text-center">
                                                                        <div className="text-dark">
                                                                            <i className="bx bx-upload h3 m-0"></i>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </Label>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>

                                        </div>
                                    </Col>
                                    <Col sm={5}>
                                        <button
                                            style={{
                                                fontSize: '1.5rem',
                                                position: 'absolute',
                                                top: '0',
                                                right: '0',
                                                background: 'none',
                                                border: 'none',
                                                padding: '5px',
                                                cursor: 'pointer',
                                                zIndex: 999 // No need for quotes around numbers
                                            }}
                                            onClick={() => {
                                                setHideButton(!hideButton);
                                            }}
                                            type="button"
                                            className="close float-right btn btn-sm"
                                        >
                                            <i className="bx bx-x-circle text-danger"></i>
                                        </button>

                                        <div className="maintenance-img" style={{ marginTop: '50px' }}>
                                            <img src={content_creating} alt="" className="img-fluid mx-auto d-block" />
                                        </div>
                                    </Col>
                                </Row>

                                <input type="file"
                                    className="sr-only"
                                    id="input-video"
                                    name="file"
                                    accept={`video/mp4,video/x-m4v,video/*`}
                                    onChange={videoUploadPreviev}
                                />
                                <input type="file"
                                    className="sr-only"
                                    id="input-image"
                                    name="file"
                                    accept="image/*"
                                    onChange={ImageUploadPreviev}
                                    multiple
                                />
                                <input type="file"
                                    className="sr-only"
                                    id="input-file"
                                    name="file"
                                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,.zip,.rar,/*"
                                    onChange={fileUploadPreviev}
                                    multiple
                                />
                                <input type="file"
                                    className="sr-only"
                                    id="input-audio"
                                    name="file"
                                    accept=".mp3,.wma,.acc,.wav,.flac/*"
                                    onChange={fileUploadPreviev}
                                    multiple
                                />
                            </CardBody>

                        </CardBody>

                        <Element name="contentBlock"><div> </div></Element>

                        {fileType && <CardBody>

                            <CardTitle>{fileType}</CardTitle>

                            {fileType === "Blog" &&

                                <AvForm method="post" className="ml-4 mr-4">
                                    <FormGroup >
                                        <Label className="text-dark">{props.t('Title:')}</Label>
                                        <AvField
                                            type="text"
                                            name="name"
                                            placeholder={props.t('Title')}
                                            className="form-control"
                                            value={fileName}

                                            onChange={(e) => {
                                                if (e.target.value.length > 0 && nameError) {
                                                    setNameError(false);
                                                }
                                                setFileName(e.target.value);
                                            }}
                                        />

                                    </FormGroup>

                                    <FormGroup>

                                        <Editor
                                            editorState={editorState}

                                            onEditorStateChange={onEditorStateChange}

                                        />

                                    </FormGroup>

                                    {
                                        isUploadProgress ?
                                            <button type="button" className="btn btn-light waves-effect">
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Saving ')}
                                            </button>
                                            :
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    {
                                                        // setIsUploadProgress(true);
                                                        console.log(editorState)
                                                    }
                                                }}>{props.t('Upload & Save')}
                                            </button>
                                    }

                                </AvForm>
                            }
                            {fileType === "Assignment" && <Col >

                                <AvForm className="outer-repeater" >

                                    <div data-repeater-item className="outer">


                                        <FormGroup >
                                            <Label className="text-dark">{props.t('Assignment Title:')}</Label>
                                            <AvField
                                                type="text"
                                                name="name"
                                                placeholder={props.t('Title')}
                                                className="form-control"
                                                value={fileName}

                                                onChange={(e) => {

                                                    setFileName(e.target.value);
                                                }}
                                            />

                                        </FormGroup>
                                        <FormGroup>
                                            <Label className="text-dark" >{props.t('Description:')}</Label>
                                            <AvField
                                                type="textarea"
                                                name="fileDescription"
                                                rows="3"
                                                placeholder={props.t('Description')}
                                                className="form-control"
                                                value={fileDescription}
                                                onChange={(e) => {
                                                    setFileDescription(e.target.value);
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup>

                                            <Label className="text-dark" >{props.t('Due Date:')}</Label>
                                            <input
                                                className="form-control"
                                                type="datetime-local"
                                                defaultValue=""
                                                id="example-datetime-local-input"
                                            />

                                        </FormGroup>

                                    </div>


                                    <div className="mt-4 col-6">
                                        <Card className="border shadow-none mb-2">
                                            <Link to="#" className="text-body">
                                                <div className="p-2">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs align-self-center me-2">
                                                            <div className="avatar-title rounded bg-transparent text-success font-size-20">
                                                                <i className="mdi mdi-image"></i>
                                                            </div>
                                                        </div>

                                                        <div className="overflow-hidden me-auto">
                                                            <h5 className="font-size-13 text-truncate mb-1">
                                                                Images
                                                            </h5>
                                                            <p className="text-muted text-truncate mb-0">176 Files</p>
                                                        </div>

                                                        <div className="ml-2">
                                                            <p className="text-muted">6 GB</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </Card>

                                        <div className="card border shadow-none mb-2">
                                            <Link to="#" className="text-body">
                                                <div className="p-2">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs align-self-center me-2">
                                                            <div className="avatar-title rounded bg-transparent text-danger font-size-20">
                                                                <i className="mdi mdi-play-circle-outline"></i>
                                                            </div>
                                                        </div>

                                                        <div className="overflow-hidden me-auto">
                                                            <h5 className="font-size-13 text-truncate mb-1">Video</h5>
                                                            <p className="text-muted text-truncate mb-0">45 Files</p>
                                                        </div>

                                                        <div className="ml-2">
                                                            <p className="text-muted">4.1 GB</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="card border shadow-none mb-2">
                                            <Link to="#" className="text-body">
                                                <div className="p-2">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs align-self-center me-2">
                                                            <div className="avatar-title rounded bg-transparent text-info font-size-20">
                                                                <i className="mdi mdi-music"></i>
                                                            </div>
                                                        </div>

                                                        <div className="overflow-hidden me-auto">
                                                            <h5 className="font-size-13 text-truncate mb-1">Music</h5>
                                                            <p className="text-muted text-truncate mb-0">21 Files</p>
                                                        </div>

                                                        <div className="ml-2">
                                                            <p className="text-muted">3.2 GB</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="card border shadow-none mb-2">
                                            <Link to="#" className="text-body">
                                                <div className="p-2">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs align-self-center me-2">
                                                            <div className="avatar-title rounded bg-transparent text-primary font-size-20">
                                                                <i className="mdi mdi-file-document"></i>
                                                            </div>
                                                        </div>

                                                        <div className="overflow-hidden me-auto">
                                                            <h5 className="font-size-13 text-truncate mb-1">
                                                                Document
                                                            </h5>
                                                            <p className="text-muted text-truncate mb-0">21 Files</p>
                                                        </div>

                                                        <div className="ml-2">
                                                            <p className="text-muted">2 GB</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>

                                        <div className="card border shadow-none">
                                            <Link to="#" className="text-body">
                                                <div className="p-2">
                                                    <div className="d-flex">
                                                        <div className="avatar-xs align-self-center me-2">
                                                            <div className="avatar-title rounded bg-transparent text-warning font-size-20">
                                                                <i className="mdi mdi-folder"></i>
                                                            </div>
                                                        </div>

                                                        <div className="overflow-hidden me-auto">
                                                            <h5 className="font-size-13 text-truncate mb-1">
                                                                Others
                                                            </h5>
                                                            <p className="text-muted text-truncate mb-0">20 Files</p>
                                                        </div>

                                                        <div className="ml-2">
                                                            <p className="text-muted">1.4 GB</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>






                                    {
                                        isUploadProgress ?
                                            <button type="button" className="btn btn-light waves-effect">
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Saving ')}
                                            </button>
                                            :

                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    if (!weblink || weblink.length == 0) {
                                                        setWeblinkError(props.t('Link/URL can not be empty'));
                                                    } else if (!isValidURL(weblink)) {
                                                        setWeblinkError(props.t('Invalid Link/URL'));
                                                    } else {
                                                        setIsUploadProgress(true)
                                                        setWeblinkError(undefined);
                                                        crateWeblink();
                                                    }


                                                }}>{props.t('Save')}
                                            </button>
                                    }



                                </AvForm>

                            </Col>

                            }
                            {fileType === "Weblink" &&

                                <Col >
                                    <AvForm className="outer-repeater" >
                                        <div data-repeater-item className="outer">


                                            <FormGroup >
                                                <Label className="text-dark">{props.t('Link/URL:')}</Label>
                                                <AvField
                                                    type="text"
                                                    name="name"
                                                    placeholder={props.t('Link/URL')}
                                                    className="form-control"
                                                    value={weblink}

                                                    onChange={(e) => {
                                                        if (e.target.value.length > 0 && nameError) {
                                                            setNameError(false);
                                                        }
                                                        setWeblink(e.target.value);
                                                    }}
                                                />

                                            </FormGroup>
                                            {weblinkError && <p className="text-danger"> {weblinkError}</p>}
                                            <FormGroup >
                                                <Label className="text-dark">{props.t('Name:')}</Label>
                                                <AvField
                                                    type="text"
                                                    name="name"
                                                    placeholder={props.t('Title')}
                                                    className="form-control"
                                                    value={fileName}

                                                    onChange={(e) => {

                                                        setFileName(e.target.value);
                                                    }}
                                                />

                                            </FormGroup>
                                            <FormGroup>
                                                <Label className="text-dark" >{props.t('Description:')}</Label>
                                                <AvField
                                                    type="textarea"
                                                    name="fileDescription"
                                                    rows="3"
                                                    placeholder={props.t('Description')}
                                                    className="form-control"
                                                    value={fileDescription}
                                                    onChange={(e) => {
                                                        setFileDescription(e.target.value);
                                                    }}
                                                />
                                            </FormGroup>
                                            <small className='text-danger'>*Disclaimer: {WhiteLabel.getAppName()} is not liable for the ads or content found on above websites/Link.</small>
                                        </div>


                                        {
                                            isUploadProgress ?
                                                <button type="button" className="btn btn-light waves-effect">
                                                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Saving ')}
                                                </button>
                                                :

                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        if (!weblink || weblink.length == 0) {
                                                            setWeblinkError(props.t('Link/URL can not be empty'));
                                                        } else if (!isValidURL(weblink)) {
                                                            setWeblinkError(props.t('Invalid Link/URL'));
                                                        } else {
                                                            setIsUploadProgress(true)
                                                            setWeblinkError(undefined);
                                                            crateWeblink();
                                                        }


                                                    }}>{props.t('Save')}</button>}



                                    </AvForm>

                                </Col>

                            }
                            {(fileType === "File" || fileType == "Image") &&
                                <div>
                                    <FileListComponent
                                        fileList={fileList}
                                        handelFilesUpload={handelFilesUpload}
                                        uploadofFiles={uploadofFiles}
                                        newFiles={newFiles}
                                        uploadPogress={uploadPogress}
                                        progressText={progressText}
                                    ></FileListComponent>

                                </div>

                            }
                            {fileType === "Video" &&
                                <Row>

                                    <Col sm={7} lg={7} xs={12}>
                                        <AvForm className="outer-repeater" >
                                            <div data-repeater-item className="outer">
                                                <FormGroup >
                                                    <Label >{props.t('Name:')}</Label>
                                                    <AvField
                                                        type="text"
                                                        name="name"
                                                        placeholder={props.t('video/file Name')}
                                                        className="form-control"
                                                        value={fileName}

                                                        onChange={(e) => {
                                                            if (e.target.value.length > 0 && nameError) {
                                                                setNameError(false);
                                                            }
                                                            setFileName(e.target.value);
                                                        }}
                                                    />
                                                    {nameError && <p className="text-danger"> {props.t('File  name can not be empty')}</p>}
                                                </FormGroup>
                                                <FormGroup>
                                                    <Label  >{props.t('Description:')}</Label>
                                                    <AvField
                                                        type="textarea"
                                                        name="fileDescription"
                                                        rows="3"
                                                        placeholder={props.t('Description')}
                                                        className="form-control"
                                                        value={fileDescription}
                                                        onChange={(e) => {
                                                            setFileDescription(e.target.value);
                                                        }}
                                                    />
                                                </FormGroup>


                                                {fileType === "File" && !_audioExtensions.includes(fileExtention) && false && <FormGroup>
                                                    <div className="custom-control custom-checkbox mb-2 ml-3" >
                                                        <input type="checkbox" className="custom-control-input" id="downloadableCheck" onChange={() => false} checked={fileDownloadeble} />
                                                        <label className="custom-control-label" onClick={() => { setFileDownloadeble(!fileDownloadeble); }} >{props.t('Downloadable')}</label>
                                                    </div>
                                                </FormGroup>}
                                            </div>
                                            {isUploadProgress ?
                                                <div className="mb-30">


                                                    <p className="text-info mb-2"> {props.t('Please do not close or refresh the page while uploading video/pdf or any other files')}</p>
                                                    <Progress striped color="primary" value={uploadPogress}></Progress>
                                                    <h6 className="float-right mt-4 text-primary">{progressText}</h6>
                                                </div> :
                                                <button
                                                    className="btn btn-primary"
                                                    onClick={() => {
                                                        if (fileType === "Video") {
                                                            onVideoUpload();
                                                            setIsUploadProgress(true);
                                                        } else if (fileType === "Image") {
                                                            onImageUpload();
                                                            setIsUploadProgress(true);
                                                        } else if (fileType === "File") {
                                                            onFileUpload();
                                                            setIsUploadProgress(true);
                                                        }

                                                    }}
                                                >
                                                    {props.t('Save')}
                                                </button>}
                                        </AvForm>
                                    </Col>
                                    <Col sm={5} lg={5}>

                                        <Row className="align-items-center">
                                            {fileType === "Image" && <Col className="col-auto">
                                                <img
                                                    data-dz-thumbnail=""

                                                    className="rounded mr-2 ml-3"
                                                    width="300"
                                                    alt={fileName}
                                                    src={preview}
                                                />
                                            </Col>}
                                            {
                                                fileType !== "Image" &&
                                                <div className="avatar ml-3 mt-3 border p-3">
                                                    <span className="avatar-title bg-white text-primary h1">
                                                        <i className={getFileTypeImage(fileExtention)}></i>
                                                    </span>
                                                    <p>{actualFileName}</p>
                                                </div>
                                            }
                                        </Row>
                                    </Col>


                                </Row>}

                        </CardBody>}

                    </Card>}

                    {loadingState == 2 && props.selcetedActivity && props.selcetedActivity.id === parameter &&
                        props.files.map((file, key) => (

                            <Row className="justify-content-center" key={key}>
                                <Col  >
                                    <Card>
                                        <CardBody>
                                            <Row>
                                                <Col lg={10}>
                                                    <h5>{file.attributes.name}</h5>
                                                    {/* {(file.attributes.fileType === "vimeo" || 
                                                    (file.attributes.file&&getFileTypeFromUrl(file.attributes.file._url).type === "image"))?
                                                    <p className="text-muted">{file.attributes.fileDescription}</p>:""
                                                    
                                                    } */}
                                                </Col>
                                                <Col lg={2} className='d-flex justify-content-end'>

                                                    {canEdit && <ul className="list-inline font-size-20 contact-links mb-0 float-right">
                                                        <li className="list-inline-item px-2"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setEditFile(file)
                                                                if (file.attributes.fileType === "file") {
                                                                    setEditFileDownloadeble(file.attributes.downloadable)
                                                                }
                                                            }}
                                                            id={"file-edit" + key}>
                                                            <i className="bx bx-edit-alt text-dark"></i>
                                                            <UncontrolledTooltip placement="top" target={"file-edit" + key}>
                                                                {props.t('Edit')}
                                                            </UncontrolledTooltip>

                                                        </li>


                                                        <li className="list-inline-item px-2"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setDeleteFile(file);
                                                            }}
                                                            id={"sub-delete" + key}>
                                                            <i className="bx bx-trash text-danger"></i>
                                                            <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                {props.t('Delete')}
                                                            </UncontrolledTooltip>

                                                        </li>
                                                    </ul>}

                                                </Col>
                                            </Row>


                                            {file.attributes.fileType === "vimeo" &&
                                                <Iframe
                                                    url={getVimeoUrl(file.attributes.url)}
                                                    height="300"
                                                    style={{ cursor: "pointer" }}
                                                    display="initial"
                                                    scrolling={"no"}
                                                    allowFullScreen={true}
                                                    position="relative"
                                                    frameBorder={0}
                                                    className="mt-2"
                                                />

                                            }
                                            {file.attributes.fileType === "url" && !youtubeParser(file.attributes.url) &&

                                                <Media className="border mt-2 p-2" style={{ cursor: "pointer" }}>
                                                    <Row>
                                                        <Col lg={1}>
                                                            <div className="avatar-md mr-4">
                                                                <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                                                    <div style={{ fontSize: "48px" }}><i className="bx bx-link-alt"></i></div>
                                                                </span>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <Media className="overflow-hidden" body >


                                                                <div >
                                                                    <h5 className="text-truncate font-size-15  mt-2 text-dark">{props.t('Link/URL') + ": "}</h5>
                                                                    <span
                                                                        onClick={() => {
                                                                            setClickFile(file);
                                                                            // setShowModel(true);
                                                                            window.open(file.attributes.url, '_blank');
                                                                        }}

                                                                        style={{ cursor: "pointer" }} >
                                                                        <p className="text-primary "><u>{file.attributes.url} </u></p>
                                                                    </span>
                                                                    <p className="text-muted">{file.attributes.fileDescription}</p>
                                                                </div>
                                                            </Media>
                                                        </Col>
                                                    </Row>
                                                </Media>



                                            }

                                            {
                                                (file.attributes.fileType === "url" || file.attributes.fileType === "youtube") && file.attributes.url && youtubeParser(file.attributes.url) &&
                                                <div className="embed-responsive embed-responsive-16by9 ratio ratio-8x4 col-6" >
                                                    <iframe
                                                        title={file.attributes.name}
                                                        className="embed-responsive-item"
                                                        src={"https://www.youtube.com/embed/" + youtubeParser(file.attributes.url)}
                                                    />
                                                </div>   
                                            }
                                            {
                                                (file.attributes.fileType === "url" || file.attributes.fileType === "youtube") && file.attributes.url && youtubeParser(file.attributes.url) &&
                                                <div className=" col-6" >
                                                   
                                                    {file.attributes.name && <p className='m-0'>Name: {file.attributes.name}</p>}
                                                    {file.attributes.fileDescription && <p className='m-0'>Description: {file.attributes.fileDescription}</p>}
                                                    {file.attributes.url && (
                                                        <a href={file.attributes.url} target="_blank">
                                                            Link: {file.attributes.url}
                                                        </a>
                                                    )}
                                                </div>    
                                            }

                                            {
                                                file.attributes.file && file.attributes.file._url && getFileTypeFromUrl(file.attributes.file._url).type === "image" &&
                                                <img
                                                    style={{ cursor: "pointer" }}
                                                    data-dz-thumbnail=""
                                                    onClick={() => {
                                                        setLightBox({ show: true, images: [file.attributes.file._url], name: file.attributes.name });
                                                        // setClickFile(file);
                                                        //     setShowModel(true);
                                                        // html2canvas(imageRef.current).then(canvas => {
                                                        //     const image = canvas.toDataURL('image/png');
                                                        //     const link = document.createElement('a');
                                                        //     link.href = image;
                                                        //     link.download = 'downloaded-image.png';
                                                        //     link.click();
                                                        // });


                                                    }
                                                    }
                                                    className="rounded mr-2 ml-5"
                                                    height="300"

                                                    src={file.attributes.file._url}
                                                />
                                            }

                                            {file.attributes.file && file.attributes.file._url && getFileTypeFromUrl(file.attributes.file._url).type === "audio" &&
                                                <Media className="border mt-2 p-2">
                                                    < ReactAudioPlayer
                                                        src={file.attributes.file._url}
                                                        autoPlay={false}
                                                        controls
                                                    />
                                                </Media>

                                            }



                                            {
                                                file.attributes.file && file.attributes.file._url
                                                    && getFileTypeFromUrl(file.attributes.file._url).type !== "image"
                                                    && getFileTypeFromUrl(file.attributes.file._url).type !== "audio" ?


                                                    <Media className="border mt-2 p-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setClickFile(file);
                                                            setShowModel(true);
                                                        }}
                                                    >
                                                        <Row>
                                                            <Col lg={1}>
                                                                <div className="avatar-md mr-4" >
                                                                    <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                                                        <div style={{ fontSize: "48px", cursor: "pointer" }}><i className={getFileTypeImage(getFileTypeFromUrl(file.attributes.file._url).ext)}></i></div>
                                                                    </span>
                                                                </div>
                                                            </Col>
                                                            <Col>
                                                                <Media className="overflow-hidden" body >

                                                                    <div style={{ cursor: "pointer" }}>
                                                                        <h5 className="text-truncate font-size-15  mt-2 text-dark">{file.attributes.name ? file.attributes.name : file.attributes.file._name}</h5>
                                                                        <p className="text-muted ">{file.attributes.fileDescription}</p>
                                                                    </div>

                                                                    <Label className="text-primary" style={{ cursor: "pointer" }}><u>{props.t('Preview')}</u></Label>
                                                                </Media>
                                                            </Col>
                                                        </Row>
                                                    </Media> :
                                                    <p className="text-muted"></p>
                                            }
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>

                        ))
                    }
                    <Element name="ScreenBottom"> <div></div></Element>
                </Container>
            </div>


            {lightBox && lightBox.show ? (
                <Lightbox
                    mainSrc={lightBox.images}
                    enableZoom={false}
                    imageCaption={
                        lightBox.name
                    }
                    onCloseRequest={() => { setLightBox({ show: false, images: [] }) }}
                />
            ) : null}


            <Modal
                size="xl"
                isOpen={showModel}
                toggle={() => { setShowModel(!showModel) }}
                scrollable={true}
            >
                <div className="modal-header">



                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        {clickedFile && clickedFile.attributes.name}
                    </h5>


                    {
                        clickedFile && clickedFile.attributes.fileType !== "url" && (clickedFile.attributes.downloadable === undefined ||
                            clickedFile.attributes.downloadable === null ||
                            clickedFile.attributes.downloadable === true) && <a

                                href={getURL()}
                                download={getFileName()}

                                target="_blank"
                                type="button"
                                className="btn btn-sm btn-primary waves-effect waves-light ml-4">
                            <i className="bx bx-download font-size-14 align-middle"></i> {props.t('Download ')}
                        </a>
                    }
                    <button
                        onClick={() => {
                            setShowModel(false)
                            setClickFile(undefined);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody >
                    {
                        clickedFile && clickedFile.attributes.fileType === "url" ?
                            <Iframe
                                url={getURL()}
                                height="600"
                                width="100%"
                                display="initial"
                                allowFullScreen={true}
                                position="relative"
                                frameBorder={0}
                            >
                            </Iframe> :
                            <div>
                                {getURL() && getURL().endsWith(".pdf") ? <Iframe
                                    url={getURL() + "#toolbar=0"}
                                    height="600"
                                    width="100%"
                                    display="initial"
                                    allowFullScreen={true}
                                    position="relative"
                                    frameBorder={0}

                                >
                                </Iframe> :

                                    <DocViewer
                                        pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer,]}
                                        style={{ height: "500px" }}
                                        documents={getFR(getURL())}
                                        config={{
                                            header: {
                                                disableHeader: true,
                                                disableFileName: false,
                                                retainURLParams: false
                                            }
                                        }}

                                    />
                                }
                            </div>

                    }

                    <Card style={{ backgroundColor: "#fff", marginTop: "-7mm", width: "100%", height: " 7mm" }} />




                </ModalBody>
            </Modal>


            {editFile && <Modal

                isOpen={editFile}
                toggle={() => { setEditFile(undefined) }}
                scrollable={true}
            >
                <div className="modal-header">



                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Edit content
                    </h5>



                </div>
                <ModalBody >


                    <AvForm className="form-horizontal"
                        onValidSubmit={(e, v) => {



                            let temp = editFile;
                            temp.set("name", v.editFileName);
                            temp.set("fileDescription", v.editFileDescription);

                            if (editFile.attributes.fileType === "file") {
                                temp.set("downloadable", editFileDownloadeble ? true : false);
                            }

                            props.AddFile(temp, props.t('Content updated successfully'));
                            setEditFile(undefined);
                        }}

                    >
                        <Label htmlFor="formSingleLine">Name:</Label>
                        <FormGroup >

                            <AvField
                                type="text"
                                name="editFileName"
                                required
                                label={props.t('Name:')}
                                placeholder={props.t('File Name')}
                                className="form-control"
                                value={editFile.attributes.name}
                            />
                        </FormGroup>

                        <Label  >Description:</Label>
                        <FormGroup>

                            <AvField
                                type="textarea"
                                name="editFileDescription"
                                label={props.t('Description:')}
                                rows="3"
                                placeholder={props.t('Description')}
                                className="form-control"
                                value={editFile.attributes.fileDescription}

                            />
                        </FormGroup>


                        {editFile.attributes.fileType === "file" && false && <FormGroup>
                            <div className="custom-control custom-checkbox mb-2 ml-2" >
                                <input type="checkbox" className="custom-control-input" id="downloadableCheck"
                                    onChange={() => false} defaultChecked={editFile.attributes.downloadable} />
                                <label className="custom-control-label" htmlFor="downloadableCheck"
                                    onClick={() => { setEditFileDownloadeble(!editFileDownloadeble); }}
                                >{props.t('Downloadable')}</label>

                            </div>
                        </FormGroup>}



                        <Row>
                            <Col>
                                <div className="text-right mt-2">
                                    <button type="button" className="btn btn-light m-1 "
                                        onClick={() => {
                                            setEditFile(undefined);
                                        }}>
                                        {props.t('Close')}
                                    </button>
                                    <button
                                        className="btn btn-success save-event m-1"
                                        type="submit" >
                                        {props.t('Save')}
                                    </button>
                                </div>
                            </Col>
                        </Row>


                    </AvForm>



                </ModalBody>
            </Modal>}

            {deleteFileObject || deleteActivity ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        let obj = deleteFileObject ? deleteFileObject : deleteActivity;
                        if (obj.className === "Files") {
                            props.deleteFile(deleteFileObject)
                            setDeleteFile(undefined);
                            setsuccess_dlg("file");
                        } else {
                            props.deleteLearningActivity(deleteActivity)
                            setDeleteActivity(undefined);
                            setsuccess_dlg("activity");
                        }


                    }}
                    onCancel={() => {
                        setDeleteFile(undefined);
                        setDeleteActivity(undefined);
                        setsuccess_dlg(false);
                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        if (success_dlg === "activity") {
                            window.history.back();
                        }
                        setsuccess_dlg(false)
                    }}
                >
                    {success_dlg === "activity" ? props.t('Selected chapter has been deleted.') : props.t('Selected content has been deleted.')}
                </SweetAlert>
            ) : null}
            {
                deleteWarning && <SweetAlert
                    info
                    title={props.t('Alert')}
                    onConfirm={() => {
                        setDeleteWarning(false)
                    }}
                >
                    {props.t('Please delete all the content to delete this topic')}
                </SweetAlert>
            }
        </React.Fragment>
    );


}



const mapStatetoProps = state => {

    const { learningActivities, selcetedActivity, loadingState } = state.LearningActivity;
    const { files, loadingFileState } = state.Files;
    const { selectedSchool } = state.School;
    const { curriculums, initialLoding, selectedCurriculum } = state.Curriculum;
    const { userTeacher } = state.Login;
    return { learningActivities, selcetedActivity, files, loadingFileState, loadingState, selectedSchool, selectedCurriculum, userTeacher };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdLearningActivity, getFilesForFolderId, AddFile, deleteFile, setSelecetdSchool, addLearningActivity, deleteLearningActivity, setSelectedCurriculum })(ActivityContentList))); 
