import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Table,
  Input,
  UncontrolledTooltip,
  Label,
  FormGroup,
} from "reactstrap";
import {
  setSelecetdSchool,
  getPaymentOptionsForSchool,
  getRatingParams,
  getSchoolRatings,
  editSchool,
  editPaymentOptions,
  getAcademicCycleForSchoolId,
  getFranchise
} from "../../store/actions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import Editable from "react-bootstrap-editable";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'

//components
import SchoolPermissions from "./SchoolPermissions";
import UpdatePhoto from "../../components/Common/UpdatePhoto";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import AcademicCycles from "./AcademicCycles";
import OtherInfo from "./OtherInfo";
import SchoolTimings from "./SchoolTimings";
import * as Constants from "../../Constents";
import COUNTRY_JSON from '../../assets/other/countries.json'
import Select from "react-select";

const Index = (props) => {
  var match = matchPath(props.history.location.pathname, {
    path: "/school/:id",
    exact: true,
    strict: false,
  });

  if (!match) {
    match = matchPath(props.history.location.pathname, {
      path: "/school/:id/:cycles",
      exact: true,
      strict: false,
    });
  }

  const schoolId = match.params.id;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [address, setAddress] = useState("");
  const [secondaryPhone, setSecondaryPhone] = useState("");
  const [schoolCode, setSchoolCode] = useState("");
  const [schoolwebsite, setSchoolWebsite] = useState("");
  const [city, setCity] = useState("");
  const [locality, setlocality] = useState("");
  const [image, setImage] = useState(undefined);
  const [schoolStateName, setSchoolStateName] = useState(undefined)
  const [schoolState, setSchoolState] = useState({
    value: "",
    label: ""
  });
  const [country, setCountry] =  useState({
    value: "",
    label: ""
  });
  const [isStateEditOn, setIsStateEditOn] = useState(false);
  const [activeTab, setactiveTab] = useState(match.params.cycles ? "3" : "1");
  const [countryCodeList, setCountryCodeList] = useState(undefined)

  useEffect(() => {
    props.getAcademicCycleForSchoolId(schoolId)
    setAllCountryCodeList()
}, [schoolId]);

  useEffect(() => {
    if (match.params.cycles && activeTab === "3") {
      scroller.scrollTo('AcademicCycles', {
        duration: 500,
        delay: 100,
        smooth: true,
        offset: -200, 
    });
    }
  }, [activeTab]);

  const setAllCountryCodeList = () => {
    let allCountries = []
    for (const country of COUNTRY_JSON) {
        let countryObject = {
            value: country.code,
            label: country.name,
        }
        allCountries.push(countryObject)
    }
    setCountryCodeList(allCountries)
}

function getNameFromCode(code) {
  const country = COUNTRY_JSON.find(country => country.code === code);
  return  country.name 
}

  const handleEmailChange = (value) => {
    setEmail(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("EMail", value);
      props.editSchool(temp);
    }
  };
  const handleSchoolCodeChange = (value) => {
    setSchoolCode(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("schoolCode", value);
      props.editSchool(temp);
    }
  };
  const handleSchoolWebsiteChange = (value) => {
    setSchoolWebsite(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("url", value);
      props.editSchool(temp);
    }
  };
  const handleSecPhoneChange = (value) => {
    setSecondaryPhone(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("secondaryPhoneNumber", value);
      props.editSchool(temp);
    }
  };

  const handleCityChange = (value) => {
    setCity(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("city", value);
      props.editSchool(temp);
    }
  };

  const handleLocalityChange = (value) => {
    setlocality(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("locality", value);
      props.editSchool(temp);
    }
  };
  

  const handlePhoneChange = (value) => {
    setPhone(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("primaryPhoneNumber", value);
      props.editSchool(temp);
    }
  };

  const handleAddressChange = (value) => {
    setAddress(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("Address", value);
      props.editSchool(temp);
    }
  };

  const handleStateNameChange = (value) => {
    setSchoolStateName(value);
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("state",value);
      props.editSchool(temp);
      setIsStateEditOn(false);
    }
  };

  const handleStateCodeChange = () => {
    
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("state",schoolState.label);
      temp.set("areaCode",schoolState.value);
      props.editSchool(temp);
      setIsStateEditOn(false);
    }
  };

  const handleCountryChange = () => {
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("country", country.label);
      temp.set("countryCode", country.value);
      if(country.value == "IN"){
        temp.unset("countryCode")
      }
      props.editSchool(temp);
    }
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const handleSchoolEdit = (schoolObject) => {
    props.editSchool(schoolObject);
  };

  const handlePaymentOptionsEdit = (paymentOptionsForSchool) => {
    props.editPaymentOptions(paymentOptionsForSchool);
  };

  const handlePicChange = (imageFile) => {
    if (props.selectedSchool != null) {
      imageFile.save().then(() => {
        setImage(imageFile._url);
        let school = props.selectedSchool;
        school.set("logo", imageFile);
        props.editSchool(school);
      });
    }
  };

  function ValidateEmail(value) {
    var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    if (value.match(mailformat)) {
      return true;
    } else {
      return false;
    }
  }
  function ValidatePhonenumber(value) {
    var phoneno = /^([0|\+[0-9]{1,5})?([0-9][0-9]{9})$/;
    if (value.match(phoneno)) {
      return true;
    } else {
      return false;
    }
  }

  useEffect(() => {
    props.setSelecetdSchool(schoolId);
    // props.getSchool(schoolId);
  }, [schoolId]);

  useEffect(() => {
    props.getPaymentOptionsForSchool(schoolId);
  }, [schoolId]);

  useEffect(() => {
    props.getSchoolRatings(schoolId);
  }, [schoolId]);

  useEffect(() => {
    if (props.selectedSchool != null && props.selectedSchool.id){
      props.getRatingParams(props.selectedSchool);
      if(props.selectedSchool.attributes.franchiseId){
        props.getFranchise(props.selectedSchool.attributes.franchiseId)
      }
    }
  }, [props.selectedSchool]);

  var school = new Parse.Object();

  useEffect(() => {
    school = props.selectedSchool;
    if (school && school.id) {
      setName(school.attributes.Name);
      setEmail(school.attributes.EMail);
      setPhone(school.attributes.primaryPhoneNumber);
      setSecondaryPhone(school.attributes.secondaryPhoneNumber);
      setSchoolCode(school.attributes.schoolCode);
      setSchoolWebsite(school.attributes.url);
      setAddress(school.attributes.Address);
      setCity(school.attributes.city);
      if(school.attributes.countryCode != "IN"){
        setSchoolStateName(school.attributes.state ?? Constants.stateNameCode[school.attributes.areaCode]);
     }else if (school.attributes.areaCode) {
       setSchoolState({
         value: school.attributes.areaCode, label: Constants.stateNameCode[school.attributes.areaCode]
       });
       setSchoolStateName(school.attributes.state ?? Constants.stateNameCode[school.attributes.areaCode])
     }

      setCountry({
        value: school.attributes.countryCode,
        label: getNameFromCode(school.attributes.countryCode)
    })
      setlocality(school.attributes.locality);
      if (school.attributes.logo) {
        setImage(school.attributes.logo._url);
      } else {
        setImage(undefined);
      }
    }
  }, [props.selectedSchool]);

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const handleDeleteSchool = () => {
    if (props.selectedSchool != null) {
      var temp = props.selectedSchool;
      temp.set("deleted", true);
      temp.set("deletedBy", Parse.User.current().attributes.teacherId??Parse.User.current().id);
      props.editSchool(temp);
    }
    setShowDeleteAlert(false);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className='d-flex align-items-start'>
            <Col className="d-flex align-items-start">
              <div
                style={{ cursor: "pointer" }}
                onClick={() => { window.history.back() }}>
                <ul className=" list-unstyled">
                  <div className=''>
                    <i className="bx bx-chevron-left h1 text-primary"></i>
                  </div>
                </ul>
              </div>
              <div className=' m-2'>
                <Breadcrumbs
                  title={props.selectedSchool && props.selectedSchool.attributes.schoolType === 10 ? "Institute Details" : "School Details"}
                  breadcrumbItem="School Details"
                />
              </div>
            </Col>
          </Row>
          {!props.loading ? (
            <Row>
              <Col>
                <Card>
                  {showDeleteAlert && (
                    <SweetAlert
                      warning
                      showCancel
                      confirmBtnText="Delete"
                      cancelBtnText="Cancel"
                      confirmBtnBsStyle="danger"
                      cancelBtnBsStyle="success"
                      title="Alert!"
                      onCancel={() => {
                        setShowDeleteAlert(false);
                      }}
                      onConfirm={() => handleDeleteSchool()}
                    >
                      {props.selectedSchool != null && props.selectedSchool.id
                        ? props.t("Are you sure you want to delete") +
                          " " +
                          props.selectedSchool.attributes.Name
                        : props.t("Are you sure you want to delete")}{" "}
                      <br></br>
                    </SweetAlert>
                  )}
                  <CardBody>
                    <Row>
                      <Col lg={3} md={4} xs={12}></Col>
                      <Col lg={9} md={8} xs={12}>
                        <div className=" d-flex align-items-center justify-content-between">
                          <h3 className="center">{name}</h3>

                          <div className="page-title-right mr-2 contact-links d-flex font-size-20">
                            {/* <div className="flex-fill mr-2 ">
                                        
                                        {props.selectedSchool != null && props.selectedSchool.id && <Link
                                            to={"editenquiryinside/"+props.selectedSchool.id}
                                            id="profile-edit-enquiry"
                                        >
                                            <i className="bx bx-edit-alt"></i>
                                            <UncontrolledTooltip
                                            placement="top"
                                            target={"profile-edit-enquiry"}
                                            >
                                            {props.t("Edit")}
                                            </UncontrolledTooltip>
                                        </Link>}
                                    </div> */}
                            <div className="d-flex m-2 ">
                              <Link
                                to={"/editschool/" + schoolId}
                                id="profile-edit-school"
                              >
                                <i className="bx bx-edit-alt"></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"profile-edit-school"}
                                >
                                  {props.t("Edit")}
                                </UncontrolledTooltip>
                              </Link>
                            </div>
                            <div className="d-flex m-2">
                              <Label
                                onClick={() => {
                                  setShowDeleteAlert(true);
                                }}
                              >
                                <i className="bx bxs-trash text-danger"></i>
                              </Label>
                            </div>
                          </div>
                        </div>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={3} md={4} xs={12} className="text-center  ">
                        <div className="border p-2">
                          {!image ? (
                            <div
                              className="avatar-lg mx-auto mb-2"
                              style={{ width: "10rem", height: "10rem" }}
                            >
                              <span
                                className={
                                  "avatar-title rounded-circle bg-soft-primary text-primary"
                                }
                                style={{ fontSize: "60px" }}
                              ></span>
                            </div>
                          ) : (
                            <div>
                              <img
                                style={{ width: "10rem", height: "10rem" }}
                                className="rounded-circle avatar mb-2"
                                src={image}
                                alt=""
                              />
                            </div>
                          )}

                          <UpdatePhoto
                            className="p-1"
                            isPopupOn={false}
                            onPicChange={(imageFile) => {
                              handlePicChange(imageFile);
                            }}
                            initialImage={image == undefined ? "" : image}
                          ></UpdatePhoto>
                        </div>
                      </Col>
                      <Col lg={9} md={8} xs={12}>
                        <div className="">
                          <div className="table-responsive">
                            <Table className="table mb-0 table-bordered">
                              <tbody>
                                <tr>
                                  <th scope="row" style={{ width: "200px" }}>
                                    Email
                                  </th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onValidated={handleEmailChange}
                                      disabled={false}
                                      editText={email?" ✎":"No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={email}
                                      validate={(value) => {
                                        if (!ValidateEmail(value)) {
                                          return props.t(
                                            "You have entered an invalid email address!"
                                          );
                                        }
                                      }}
                                      showText
                                      type="textfield"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Phone</th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onValidated={handlePhoneChange}
                                      disabled={false}
                                      editText={phone?" ✎":"No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={phone}
                                      showText
                                      validate={(value) => {
                                        if (!ValidatePhonenumber(value)) {
                                          return props.t(
                                            "You have entered an invalid phone number!"
                                          );
                                        }
                                      }}
                                      type="textfield"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">Secondary Phone</th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onValidated={handleSecPhoneChange}
                                      disabled={false}
                                      editText={secondaryPhone?" ✎":"No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={secondaryPhone}
                                      showText
                                      validate={(value) => {
                                        if (!ValidatePhonenumber(value)) {
                                          return props.t(
                                            "You have entered an invalid phone number!"
                                          );
                                        }
                                      }}
                                      type="textfield"
                                    />
                                  </td>
                                </tr>

                                <tr>
                                  <th scope="row">Address</th>

                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      disabled={false}
                                      editText={address?" ✎":"No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={address}
                                      showText
                                      onSubmit={handleAddressChange}
                                      type="textfield"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">City</th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onSubmit={handleCityChange}
                                      disabled={false}
                                      editText={city?" ✎":"No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={city}
                                      showText
                                      type="textfield"
                                    />
                                  </td>
                                </tr>

                                 <tr>
                                  <th scope="row">Area</th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onSubmit={handleLocalityChange}
                                      disabled={false}
                                      editText={locality?" ✎":"No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={locality}
                                      showText
                                      type="textfield"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("Country")}</th>
                                    <td>
                                      {country.label}
                                      <button
                                        onClick={()=>{
                                          props.history.push("/editschool/" + schoolId)
                                        }}
                                        className="bg-white btn btn-sm b-0"
                                      >
                                        <i className="font-size-13 m-1 text-primary  cursors move" >
                                          ✎
                                        </i>
                                      </button>
                                    </td>
                                  {/* {isCountryEditOn && (
                                    <td>
                                      <Label> {props.t("Country")}:</Label>
                                      <FormGroup>
                                        <Select
                                          value={country}
                                          onChange={(v) => {
                                            setCountry(v)
                                          }}
                                          options={countryCodeList}
                                          classNamePrefix="select2-selection"
                                        />
                                      </FormGroup>
                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm"
                                        style={{ width: "34px" }}
                                        onClick={handleCountryChange}
                                      >
                                        <i className="fa fa-check"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm ml-2"
                                        style={{ width: "34px" }}
                                        onClick={() => {
                                          setCountry(props.selectedSchool && props.selectedSchool.attributes.country);
                                          setIsCountryEditOn(false);
                                        }}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </td>
                                  )} */}
                                </tr>
                                <tr>
                                  <th scope="row">{props.t("State")}</th>
                                  {!isStateEditOn &&country.value=="IN" && (
                                    <td>
                                      {schoolStateName}
                                      <button
                                        onClick={() => {
                                          setIsStateEditOn(true);
                                        }}
                                        className="bg-white btn btn-sm b-0"
                                      >
                                        <i className="font-size-13 m-1 text-primary  cursors move">
                                          ✎
                                        </i>
                                      </button>
                                    </td>
                                  )}
                                  {isStateEditOn && country.value=="IN"&& (
                                    <td>
                                      <Label> {props.t("State")}:</Label>
                                      <FormGroup>
                                      <Select
                                         value={schoolState}
                                         onChange={(v) => { setSchoolState(v) }}
                                         options={Constants.stateName}
                                         classNamePrefix="select2-selection"
                                       /> 
                                          
                                      </FormGroup>
                                      <button
                                        type="button"
                                        className="btn btn-success btn-sm"
                                        style={{ width: "34px" }}
                                        onClick={handleStateCodeChange}
                                      >
                                        <i className="fa fa-check"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-danger btn-sm ml-2"
                                        style={{ width: "34px" }}
                                        onClick={() => {
                                          setSchoolState({
                                            value:props.selectedSchool.attributes.areaCode,
                                            label :Constants.stateNameCode[props.selectedSchool.attributes.areaCode]
                                          });
                                          setIsStateEditOn(false);
                                        }}
                                      >
                                        <i className="fa fa-times"></i>
                                      </button>
                                    </td>
                                  )}
                                   {country.value!="IN"&& (
                                     <td>
                                    
                                     <Editable
                                       className={'d-flex'}
                                       alwaysEditing={false}
                                       onSubmit={handleStateNameChange}
                                       disabled={false}
                                       editText={schoolStateName?" ✎":"No value ✎"}
                                       id={null}
                                       isValueClickable={false}
                                       mode="inline"
                                       placement="top"
                                       initialValue={schoolStateName}
                                       showText
                                       type="textfield"
                                     />
                                   </td>
                                   )}
                                </tr>
                                <tr>
                                  <th scope="row">{props.selectedSchool&&props.selectedSchool.attributes.schoolType === 10?"Institute Code":"School Code"}</th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onSubmit={handleSchoolCodeChange}
                                      disabled={false}
                                      editText={schoolCode?" ✎":"No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={schoolCode}
                                      showText
                                      type="textfield"
                                    />
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row">{props.selectedSchool&&props.selectedSchool.attributes.url === 10?"Institute Website":"School Website"}</th>
                                  <td>
                                    <Editable
                                      className={'d-flex'}
                                      alwaysEditing={false}
                                      onSubmit={handleSchoolWebsiteChange}
                                      disabled={false}
                                      editText={schoolwebsite?" ✎":"No value ✎"}
                                      id={null}
                                      isValueClickable={false}
                                      mode="inline"
                                      placement="top"
                                      initialValue={schoolwebsite}
                                      showText
                                      type="textfield"
                                    />
                                  </td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              {props.selectedSchool != null && props.selectedSchool.id && props.selectedSchool.attributes.schoolType !== 10 && (
                <Col lg={12}>
                  <Card>
                    <CardBody>
                      <Nav pills className="navtab-bg nav-justified">
                        <NavItem>
                          <NavLink
                            style={{ cursor: "pointer" }}
                            className={classnames({
                              active: activeTab === "1",
                            })}
                            onClick={() => {
                              toggleTab("1");
                            }}
                          >
                            FEATURE PERMISSIONS
                          </NavLink>
                        </NavItem>
                       
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab === "3"
                                })}
                                onClick={() => {
                                    toggleTab("3");
                                }}
                            >
                                ACADEMIC CYCLES 
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab === "2"
                                })}
                                onClick={() => {
                                    toggleTab("2");
                                }}
                            >
                                SCHOOL TIMINGS
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: activeTab === "4"
                                })}
                                onClick={() => {
                                    toggleTab("4");
                                }}
                            >
                                OTHER DETAILS 
                            </NavLink>
                        </NavItem>
                      </Nav>

                      <TabContent activeTab={activeTab}>
                        {/* <TabPane tabId="2" className="p-3">
                          <SchoolRatings
                            ratingParams={props.ratingParams}
                            schoolRatings={props.schoolRatings}
                          />
                        </TabPane> */}
                        <TabPane tabId="1" className="p-3">
                          <SchoolPermissions
                            schoolId={schoolId}
                            latitude={props.selectedSchool && props.selectedSchool.attributes.location && props.selectedSchool.attributes.location.latitude}
                            longitude={props.selectedSchool && props.selectedSchool.attributes.location && props.selectedSchool.attributes.location.longitude}
                            schoolObject={props.selectedSchool}
                            paymentOptionsObject={props.paymentOptionsForSchool && props.paymentOptionsForSchool.length>0 && props.paymentOptionsForSchool[0]}
                            franchise={props.franchise}
                            onChangeSchoolObject={(schoolObject) => {
                              handleSchoolEdit(schoolObject);
                            }}
                            onChangePaymentOptionsObject={(
                              paymentOptionsForSchool
                            ) => {
                              handlePaymentOptionsEdit(paymentOptionsForSchool);
                            }}
                          />
                        </TabPane>
                        <TabPane tabId="3" className="p-3">
                          <Element name={"AcademicCycles" } >
                            <AcademicCycles
                              schoolId={schoolId}                          
                            />
                          </Element>
                        </TabPane>
                        <TabPane tabId="2" className="p-3">
                          <Element name={"SchoolTimings" } >
                            <SchoolTimings
                            selectedSchool={props.selectedSchool}  
                            handleUpdateSchoolTime={handleSchoolEdit}                
                            />
                          </Element>
                        </TabPane>

                        <TabPane tabId="4" className="p-3">
                          <Element name={"OtherInfo" } >
                            <OtherInfo
                              schoolId={schoolId}                          
                            />
                          </Element>
                          

                        </TabPane>
                      </TabContent>
                    </CardBody>
                  </Card>
                </Col>
              )}
            </Row>
          ) : (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                    {props.t("Loading")}{" "}
                  </Link>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    selectedSchool,
    paymentOptionsForSchool,
    ratingParams,
    schoolRatings,
  } = state.School;
  const { academicCycles, loadState } = state.AcademicCycles;
  const { franchise } = state.Franchise;
  return {
    selectedSchool,
    paymentOptionsForSchool,
    ratingParams,
    schoolRatings,
    academicCycles, loadState,
    franchise
    
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      editSchool,
      getPaymentOptionsForSchool,
      editPaymentOptions,
      getRatingParams,
      getSchoolRatings,
      getAcademicCycleForSchoolId,
      getFranchise
    })(Index)
  )
);
