import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as GroupHelper from "../../../helpers/ParseHelpers/group_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as GroupActions from './actions';
import Parse from 'parse';
import toastr, { clear } from "toastr";
import "toastr/build/toastr.min.css";


function* getAGroup({ payload: { groupId } }) {
  try {
    const response = yield call(GroupHelper.getGroupFromParse, groupId);
    if (response) {

      yield put(GroupActions.updateSingleGroup(response));

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getGroupsForIdsParse({ payload: { groupIds } }) {
  try {
    const response = yield call(GroupHelper.getGroupsForGroupIds, groupIds);
    yield put(GroupActions.setGroupLoading(false));

    if (response) {

      yield put(GroupActions.updateGroupsForIds(response));

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAGroup({ payload: { group } }) {
  try {
    yield put(GroupActions.setDeletedSuccessfully(false));
    const response = yield call(GroupHelper.deleteGroupFromParse, group);
    if (response) {
      yield put(GroupActions.updateGroup(response));
      yield put(GroupActions.setDeletedSuccessfully(true));
      toastr.success("Successfully deleted", { timeOut: 1000 });
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getGroupsForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.GROUP_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(GroupHelper.getGroupsForSchoolId, schoolId, null, true);
    yield put(GroupActions.updategroups(localResponse));


    const serverResponse = yield call(GroupHelper.getGroupsForSchoolId, schoolId, updatedTime, false);
    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(GroupActions.updategroups(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }


  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getArchiveGroupsForSchoolIdSaga({ payload: { schoolId } }) {
  try {
    var key = Constants.ARCHIVE_GROUP_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(GroupHelper.getArchiveGroupsForSchoolId, schoolId, null, true);
    yield put(GroupActions.updateArchiveGroups(localResponse));


    const serverResponse = yield call(GroupHelper.getArchiveGroupsForSchoolId, schoolId, updatedTime, false);
    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(GroupActions.updateArchiveGroups(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }


  } catch (error) {
    // yield put(apiError(error));
  }
}



function* saveGroup({ payload: { group } }) {
  try {
    const response = yield call(GroupHelper.saveGroup, group);
    if (response) {
      yield put(GroupActions.updateResultGroup(response));
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* saveArchiveGroupSaga({ payload: { group } }) {
  try {
    const response = yield call(GroupHelper.saveArchiveGroup, group);
    if (response) {
      yield put(GroupActions.updateArchiveGroup(response));
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}



export function* watchGetArchiveGroupsForSchoolIdSaga() {
  yield takeEvery(actionTypes.GET_ALL_ARCHIVE_GROUPS_FOR_SCHOOL_ID, getArchiveGroupsForSchoolIdSaga)
}


export function* watchSaveArchiveGroupSaga() {
  yield takeEvery(actionTypes.SAVE_ARCHIVE_GROUP, saveArchiveGroupSaga)
}

export function* watchSaveGroup() {
  yield takeEvery(actionTypes.SAVE_GROUP, saveGroup)
}

export function* watchGetGroupsForIdsParse() {
  yield takeEvery(actionTypes.GET_GROUPS_FOR_IDS, getGroupsForIdsParse)
}



export function* watchGetGroup() {
  yield takeEvery(actionTypes.UPDATE_GROUP, getAGroup);
}

export function* watchDeleteGroup() {
  yield takeEvery(actionTypes.DELETE_GROUP, deleteAGroup);
}
export function* watchGetGroupsForSchoolId() {
  yield takeEvery(actionTypes.GET_ALL_GROUPS_FOR_SCHOOL_ID, getGroupsForSchoolId);
}
export function* watchGetAGroup() {
  yield takeEvery(actionTypes.GET_GROUP, getAGroup);
}



function* groupSaga() {
  yield all([
    fork(watchGetGroup),
    fork(watchDeleteGroup),
    fork(watchGetGroupsForSchoolId), fork(watchSaveGroup), fork(watchGetAGroup),
    fork(watchGetGroupsForIdsParse),
    fork(watchSaveArchiveGroupSaga),
    fork(watchGetArchiveGroupsForSchoolIdSaga),
  ]);
}
export default groupSaga;
