import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as LiveMeetingHelper from "../../../helpers/ParseHelpers/liveMeeting_helper";
import * as actions from "./actionTypes";
import * as Constants from '../../../Constents';
import * as LiveMeetingActions from './actions';
import Parse from 'parse';




function* getLiveMeetingForBatch({ payload: { courseId, batchId } }) {
  try {
    // console.log(courseId, batchId )
    const response = yield call(LiveMeetingHelper.getLiveMeetingForBatch, courseId, batchId);
    yield put(LiveMeetingActions.updateLiveMeetingForBatchId(response));
    Parse.Object.pinAll(response);
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* addLiveMeeting({ payload: { liveMeeting,loadingState=true ,remainderObject} }) {
  try {
    if(loadingState){
      yield put(LiveMeetingActions.setLiveMeetingLoading(true));
    }
    const response = yield call(LiveMeetingHelper.saveLiveMeeting, liveMeeting);
    yield put(LiveMeetingActions.updateLiveMeetingForBatchId([response]));
    Parse.Object.pinAll(response);
    yield put(LiveMeetingActions.setLiveMeetingLoading(false));

    if(response && remainderObject){
      remainderObject.set("liveMeetingId",response.id)
      const remRes = yield call(LiveMeetingHelper.saveLiveMeeting, remainderObject);
      if(!response.attributes.reminderId){
        let res = response;
        res.set(reminderId,remRes.id)
        const lmRes = yield call(LiveMeetingHelper.saveLiveMeeting, res);
        if(lmRes){
          yield put(LiveMeetingActions.updateLiveMeetingForBatchId([lmRes]));
        }
      }
    }else if(response && response.attributes.isDeleted && response.attributes.reminderId){

      const Reminder = Parse.Object.extend("Reminder");
      let reminder = new Reminder();
      reminder.id = response.attributes.reminderId
      reminder.set("isDeleted", true);
      reminder.set("status", false);
      yield call(LiveMeetingHelper.saveLiveMeeting, reminder);

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}



export function* watchGetLiveMeetingForBatch() {
  yield takeEvery(actions.GET_LIVE_MEETING_FOR_BATCH, getLiveMeetingForBatch);
}
export function* watchAddLiveMeeting() {
  yield takeEvery(actions.ADD_LIVE_MEETING, addLiveMeeting);
}

function* LiveMeetingsSaga() {
  yield all([fork(watchGetLiveMeetingForBatch),fork(watchAddLiveMeeting),]);
}
export default LiveMeetingsSaga;