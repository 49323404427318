import * as actions from "./actionTypes";

const initialState = {
  enquiryFollowups: [], saveLoading: false,
};

const EnquiryFollowup = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_ENQUIRY_FOLLOWUP:
      var enquiryFollowups = [...state.enquiryFollowups];
      var temp = state.enquiryFollowups.filter(
        (enquirynote) => enquirynote.id === action.payload.enquirynote.id
      );
      if (temp.length > 0) {
        var index = enquiryFollowups.indexOf(temp[0]);
        enquiryFollowups[index] = action.payload.enquirynote;
        state = { ...state, enquiryFollowups: enquiryFollowups };
      } else {
        enquiryFollowups.push(action.payload.enquirynote);
        state = { ...state, enquiryFollowups: enquiryFollowups };
      }
      break;
    case actions.UPDATE_ENQUIRY_FOLLOWUPS:
      if (action.payload.replace) {
        state = { ...state, enquiryFollowups: action.payload.enquiryFollowups ,saveLoading:false};
      } else {
        var enquiryFollowups = [...state.enquiryFollowups];
        if (enquiryFollowups.length > 0) {
          for (const latest in action.payload.enquiryFollowups) {
            var temp = enquiryFollowups.filter(
              (enquiryFollowup) =>
              enquiryFollowup.id === action.payload.enquiryFollowups[latest].id
            );
            if (temp.length > 0) {
              if (action.payload.enquiryFollowups[latest].attributes.isDeleted) {

                var others = enquiryFollowups.filter(
                  (enquiryFollowup) =>
                  enquiryFollowup.id !== action.payload.enquiryFollowups[latest].id
                );
               
                enquiryFollowups = others;
              } else {
                var index = enquiryFollowups.lastIndexOf(temp[0]);
                enquiryFollowups[index] = action.payload.enquiryFollowups[latest];
              }
            } else {
              enquiryFollowups.push(action.payload.enquiryFollowups[latest]);
            }
          }
          state = { ...state, enquiryFollowups: enquiryFollowups,saveLoading:false };
        } else {
          state = { ...state, enquiryFollowups: action.payload.enquiryFollowups,saveLoading:false };
        }
      }
      break;
    case actions.UPDATE_DELETE_ENQUIRY_FOLLOWUP:
      var enquiryFollowups = state.enquiryFollowups.filter(
        (enquirynote) => enquirynote.id !== action.payload.id
      );
      state = { ...state, enquiryFollowups: enquiryFollowups };
      break;
    case actions.SAVE_LODING:
      state = { ...state, saveLoading: action.payload.state };
      break;
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EnquiryFollowup;
