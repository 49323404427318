import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
  expenses: undefined, expenseResult: undefined,
  expenseWallet: undefined, expensesWalletLog: undefined,
};

const Expenses = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_EXPENSES:
      var expenses = []
      if (state.expenses) {
        expenses = [...state.expenses];
      }

      if (expenses.length > 0) {
        for (const latest in action.payload.expenses) {
          var temp = expenses.filter(
            (expense) =>
              expense.id === action.payload.expenses[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.expenses[latest].attributes.isDeleted) {

              var others = expenses.filter(
                (expense) =>
                  expense.id !== action.payload.expenses[latest].id
              );

              expenses = others;
            } else {
              var index = expenses.lastIndexOf(temp[0]);
              expenses[index] = action.payload.expenses[latest];
            }

          } else {
            if (!action.payload.expenses[latest].attributes.isDeleted) {
              expenses.push(action.payload.expenses[latest]);
            }

          }
        }
        state = { ...state, expenses: expenses };
      } else {

        var others = action.payload.expenses.filter(
          (expense) =>
            expense.attributes.isDeleted !== true
        );
        state = { ...state, expenses: others };
      }

      break;



    case actions.UPDATE_EXPENSE:

      var expenses = [];
      if (state.expenses) {
        expenses = [...state.expenses];
      }

      var temp = state.expenses.filter(
        (expense) =>
          expense.id === action.payload.expense.id
      );
      if (temp.length > 0) {

        if (action.payload.expense.attributes.isDeleted) {

          var others = state.expenses.filter(
            (expense) =>
              expense.id !== action.payload.expense.id
          );
          state = { ...state, expenses: others };

        } else {
          var index = expenses.indexOf(temp[0]);
          expenses[index] = action.payload.expense;
          state = { ...state, expenses: expenses };
        }


      } else {
        if (!action.payload.expense.attributes.isDeleted) {
          expenses.unshift(action.payload.expense);
          state = { ...state, expenses: expenses };
        }

      }
      break;

    case actions.UPDATE_EXPENSE_RESULT:
      state = { ...state, expenseResult: action.payload.expense };
      break;

    case actions.CLEAR_EXPENSE_RESULT:
      state = { ...state, expenseResult: undefined };
      break;

    case actions.CLEAR_EXPENSES:
      state = { ...state, expenses: undefined };
      break;

    case actions.UPDATE_EXPENSE_WALLET:
      state = { ...state, expenseWallet: action.payload.expenseWallet };
      break;

    case actions.UPDATE_EXPENSE_WALLET_LOG:
      var updatedList = ReduxUtil.updateParseObjects(state.expensesWalletLog ? [...state.expensesWalletLog] : [], action.payload.expenseWalletLog, "isDeleted");
      state = { ...state, expensesWalletLog: updatedList };
      break;

    case actions.CLEAR_EXPENSE_WALLET_LOG:
      state = { ...state, expensesWalletLog: undefined };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Expenses;
