import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Label,
  FormGroup,
  Button,
  Badge,
  UncontrolledTooltip,
  Progress,
  Table,
  CardBody,
  Card
} from "reactstrap";
import $ from "jquery";

import Iframe from "react-iframe";

import VimeoUpload from "vimeo-upload-me";

import { AvForm, AvField } from "availity-reactstrap-validation";

import UpdatePhoto from "../../components/Common/UpdatePhoto";
import CustomTextBoxWithButtons from "../../components/Common/CustomTextBoxWithButtons";

import "react-super-responsive-table/dist/SuperResponsiveTableStyle.css";
import toastr from "toastr";
import Parse from "parse";
import Switch from "react-switch";

import { Link } from "react-router-dom";
import { setDefaults, withNamespaces } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Constants from "../../Constents";

const ExamQuestion = (props) => {
  const imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];


  const [addQuestionView, setAddQuestionView] = useState(false);
  const [questionForDuplicating, setQuestionForDuplicating] = useState(undefined)
  const [selectedQuestionToRemove, setSelectedQuestionToRemove] = useState(
    undefined
  );
  const [creatingQuestion, setCreatingQuestion] = useState(false);

  const [attachment, setAttachment] = useState();
  const [attachmentUploadProgress, setAttachmentUploadProgress] = useState(0);

  const [editQuestionObject, setEditQuestionObject] = useState(undefined);
  const [questionDefaultMark, setQuestionDefaultMark] = useState(
    props.defaultMarks ?? 1
  );
  const [questionTitle, setQuestionTitle] = useState(undefined);
  const [questionMarksError, setQuestionMarksError] = useState(false);
  const [questionType, setQuestionType] = useState(
    Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST
  );
  const [questionErrorHint, setQuestionErrorHint] = useState("");
  const [showWarningSign, setShowWarningSign] = useState(false);
  const [questionImageUploadInProgress, setQuestionImageUploadInProgress] =
    useState(false);

  const [questionImageURL, setQuestionImageURL] = useState(undefined);
  const [questionVideoURL, setQuestionVideoURL] = useState(undefined);
  const [questionVideoUploadInProgress, setQuestionVideoUploadInProgress] = useState(false);
  const [questionVideoUploadProgressValue, setQuestionVideoUploadProgressValue] = useState(0);
  const [selectedVideoFile, setSelectedVideoFile] = useState(undefined);
  const [questionVideoThumbnail, setQuestionVideoThumbnail] = useState(undefined);

  const [listOfEntriesToDisplay, setListOfEntriesToDisplay] = useState([
    {
      id: "newOption",
      optionText: undefined,
      optionImage: undefined,
      optionText2: undefined,
      optionImage2: undefined,
      addNewTagsView: false,
      addNewTagsView2: false,
    },
  ]);

  const [questionError, setQuestionError] = useState(false);

  const [answerTextError, setAnswerTextError] = useState(false);
  const [answerText, setAnswerText] = useState(undefined);

  const [answerArray, setAnswerArray] = useState([]);
  const [
    matchTheFollowingOptionsToDisplay,
    setMatchTheFollowingOptionsToDisplay,
  ] = useState([]);

  const getQuestionAcceptType = (questionType) => {
    if (
      questionType === Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST ||
      questionType === Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      return props.t("Accepts Choice");
    } else if (questionType === Constants.EXAM_FILL_IN_THE_BLANKS) {
      return props.t("Accepts one word answers");
    } else if (questionType === Constants.EXAM_LONG_ANSWER) {
      return props.t("Accepts Long Answers");
    }
  };



  const getQuestionUI = (question, questionKey) => {
    return (
      <Row key={question.id} className="border-bottom ml-4">

        <Col lg="8" md="8" sm="12" className="mt-3 mb-2">
          {editQuestionObject && question.id === editQuestionObject.id ? (
            <div>{getQuestionEditView(question, questionKey)}</div>
          ) : (
            <div>
              <h5
                className=" font-size-14 m-0"
                style={{ whiteSpace: "pre-wrap" }}
              >
                <Label className="text-dark">{question.questionTitle}</Label>
              </h5>
              <div className="d-flex mb-3">{question.questionImageURL && (
                <div className=" mt-2 mr-3">
                  <img
                    src={question.questionImageURL}
                    style={{ width: "7rem", height: "7rem" }}
                  ></img>
                </div>
              )}
                {question.attachment && (
                  <div>
                    <div className="border float-right p-2">
                      <Row>
                        <div
                          className="ml-3 mt-1 text-center"
                          style={{ width: "2rem", height: "2rem" }}
                        >
                          <span
                            className="bg-white ml-0 text-primary h1 align-middle"
                            style={{ fontSize: "45px" }}
                          >
                            <i
                              className={getFileTypeImage(
                                getFileExtension(question.attachment)
                              )}
                            ></i>
                          </span>
                        </div>

                        <div className="float-right ml-4">
                          <div
                            className="text-muted font-weight-bold text-truncate"
                            style={{ width: "200px" }}
                          >
                            {getActualFileName(question.attachment)}
                          </div>
                          <div className="mt-2">
                            <Button
                              className="btn btn-sm btn-primary "
                              color="primary"
                              target="_blank"
                              rel="noopener noreferrer"
                              onClick={() => {
                                if (question.attachment && question.attachment._url) {
                                  window.open(question.attachment._url, "_blank")
                                } else {
                                  toastr.info("Please wait and try again...", { timeOut: 1000 })
                                }
                              }}
                            >
                              View
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </div>
                )}
                {question.questionVideoURL && (
                  <div className="mb-3 mt-2 mr-3">
                    <div style={{ width: "10rem", height: "10rem" }}>
                      <Iframe
                        url={getVimeoUrl(question.questionVideoURL)}
                        style={{ cursor: "pointer" }}
                        display="initial"
                        scrolling={"no"}
                        allowFullScreen={true}
                        position="relative"
                        frameBorder={0}
                        className="mt-2"
                      />
                    </div>
                  </div>
                )}
              </div>
              {(question.questionType ===
                Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST ||
                question.questionType ===
                Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST) && (
                  <FormGroup>
                    <div className="table-responsive mb-0">
                      <table
                        id={"arrangeTheFollowing"}
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Options</th>
                            <th>
                              {question.questionType ===
                                Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
                                ? "Correct Options"
                                : "Correct Option"}
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          {question.choices &&
                            question.choices.map((option, keyInside) => (
                              <tr>
                                <td>
                                  <Badge
                                    key={keyInside}
                                    className={
                                      "badge badge-soft-primary font-size-12  mr-1 mt-1 mb-1"
                                    }
                                  >
                                    {option && option.length > 20
                                      ? option.slice(0, 19) + "..."
                                      : option}{" "}
                                  </Badge>
                                  {question.optionImages &&
                                    question.optionImages.length > keyInside &&
                                    question.optionImages[keyInside] && (
                                      <div className="mt-3">
                                        <img
                                          src={question.optionImages[keyInside]}
                                          style={{
                                            maxHeight: "100px",
                                            maxWidth: "100px",
                                          }}
                                        ></img>
                                      </div>
                                    )}
                                </td>

                                <td align="center">
                                  <input
                                    className="form-check-input"
                                    type={
                                      question.questionType ===
                                        Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
                                        ? "checkbox"
                                        : "radio"
                                    }
                                    id={"radioButton" + questionKey + keyInside}
                                    value={option}
                                    checked={
                                      question.answer &&
                                      question.answer.length > 0 &&
                                      question.answer.includes(keyInside)
                                    }
                                    onChange={() => { }}
                                  />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </FormGroup>
                )}
              {question.questionType === Constants.EXAM_FILL_IN_THE_BLANKS && (
                <FormGroup>
                  <Label className="mr-2">{props.t("Answer")} : </Label>
                  {question.answer && question.answer.length > 0 && (
                    <Label className="text-secondary">
                      {question.answer[0]}
                    </Label>
                  )}
                </FormGroup>
              )}
              {question.questionType === Constants.EXAM_MATCH_THE_FOLLOWING && (
                <FormGroup>
                  <div className="table-responsive mb-0">
                    <table
                      id={"matchTheFollowing"}
                      className="table table-striped table-bordered"
                    >
                      <thead>
                        <tr>
                          <th>1st Column</th>
                          <th>2nd Column</th>
                          <th>Correct order of 2nd Column</th>
                        </tr>
                      </thead>
                      <tbody>
                        {question.choices &&
                          question.choices2 &&
                          question.choices.map((option, keyInside) => (
                            <tr>
                              <td>
                                <Badge
                                  key={keyInside}
                                  className={
                                    "badge badge-soft-primary font-size-12 m-1"
                                  }
                                >
                                  {option && option.length > 20
                                    ? option.slice(0, 19) + "..."
                                    : option}{" "}
                                </Badge>
                                {question.optionImages[keyInside] && (
                                  <div className="mt-3">
                                    <img
                                      src={question.optionImages[keyInside]}
                                      style={{
                                        maxHeight: "100px",
                                        maxWidth: "100px",
                                      }}
                                    ></img>
                                  </div>
                                )}
                              </td>
                              <td>
                                {question.choices2 &&
                                  question.choices2.length > keyInside && (
                                    <Badge
                                      key={keyInside}
                                      className={
                                        "badge badge-soft-primary font-size-12 m-1"
                                      }
                                    >
                                      {question.choices2[keyInside] &&
                                        question.choices2[keyInside].length > 20
                                        ? question.choices2[keyInside].slice(
                                          0,
                                          19
                                        ) + "..."
                                        : question.choices2[keyInside]}{" "}
                                    </Badge>
                                  )}
                                {question.optionImages2[keyInside] && (
                                  <div className="mt-3">
                                    <img
                                      src={question.optionImages2[keyInside]}
                                      style={{
                                        maxHeight: "100px",
                                        maxWidth: "100px",
                                      }}
                                    ></img>
                                  </div>
                                )}
                              </td>
                              <td>
                                {question.answer &&
                                  question.answer.length > keyInside &&
                                  question.choices2 &&
                                  question.choices2.length >
                                  question.answer[keyInside] && (
                                    <Badge
                                      key={keyInside}
                                      className={
                                        "badge badge-soft-primary font-size-12 m-1"
                                      }
                                    >
                                      {question.choices2[
                                        question.answer[keyInside]
                                      ] &&
                                        question.choices2[
                                          question.answer[keyInside]
                                        ].length > 20
                                        ? question.choices2[
                                          question.answer[keyInside]
                                        ].slice(0, 19) + "..."
                                        : question.choices2[
                                        question.answer[keyInside]
                                        ]}{" "}
                                    </Badge>
                                  )}

                                {question.answer &&
                                  question.answer.length > keyInside &&
                                  question.optionImages2 &&
                                  question.optionImages2.length >
                                  question.answer[keyInside] &&
                                  question.optionImages2[
                                  question.answer[keyInside]
                                  ] && (
                                    <div className="mt-3">
                                      <img
                                        src={
                                          question.optionImages2[
                                          question.answer[keyInside]
                                          ]
                                        }
                                        style={{
                                          maxHeight: "100px",
                                          maxWidth: "100px",
                                        }}
                                      ></img>
                                    </div>
                                  )}
                              </td>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </FormGroup>
              )}

              {question.questionType ===
                Constants.EXAM_ARRANGE_THE_FOLLOWING && (
                  <FormGroup>
                    <div className="table-responsive mb-0">
                      <table
                        id={"arrangeTheFollowing"}
                        className="table table-striped table-bordered"
                      >
                        <thead>
                          <tr>
                            <th>Jumbled Order</th>
                            <th>Correct Order</th>
                          </tr>
                        </thead>
                        <tbody>
                          {question.choices &&
                            question.choices.map((option, keyInside) => (
                              <tr>
                                <td>
                                  <Badge
                                    key={keyInside}
                                    className={
                                      "badge badge-soft-primary font-size-12  mr-1 mt-1 mb-1"
                                    }
                                  >
                                    {option && option.length > 20
                                      ? option.slice(0, 19) + "..."
                                      : option}{" "}
                                  </Badge>
                                  {question.optionImages[keyInside] && (
                                    <div className="mt-3">
                                      <img
                                        src={question.optionImages[keyInside]}
                                        style={{
                                          maxHeight: "100px",
                                          maxWidth: "100px",
                                        }}
                                      ></img>
                                    </div>
                                  )}
                                </td>

                                <td>
                                  <Badge
                                    key={keyInside}
                                    className={
                                      "badge badge-soft-primary font-size-12 mr-1 mt-1 mb-1"
                                    }
                                  >
                                    {question.choices[
                                      question.answer[keyInside]
                                    ] &&
                                      question.choices[question.answer[keyInside]]
                                        .length > 20
                                      ? question.choices[
                                        question.answer[keyInside]
                                      ].slice(0, 19) + "..."
                                      : question.choices[
                                      question.answer[keyInside]
                                      ]}{" "}
                                  </Badge>
                                  {question.optionImages[
                                    question.answer[keyInside]
                                  ] && (
                                      <div className="mt-3">
                                        <img
                                          src={
                                            question.optionImages[
                                            question.answer[keyInside]
                                            ]
                                          }
                                          style={{
                                            maxHeight: "100px",
                                            maxWidth: "100px",
                                          }}
                                        ></img>
                                      </div>
                                    )}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                    </div>
                  </FormGroup>
                )}

              <Label className="mr-2">{props.t("Marks")} : </Label>

              <Label className="text-secondary">
                {question.defaultMarks ?? "1"}
              </Label>

              <p className="text-muted mt-1">
                {getQuestionAcceptType(question.questionType)}
              </p>
            </div>
          )}
        </Col>

        <Col lg="4" md="4" sm="12" className="mt-2 mb-2">
          <Row>


            <Col>
              {showWarningSign &&
                editQuestionObject &&
                question.id === editQuestionObject.id && (
                  <Label className="text-danger" style={{ fontSize: "20px" }}>
                    <i class="fa fa-exclamation-circle mr-2 mt-2 pt-2"></i>
                  </Label>
                )}
              {editQuestionObject && question.id === editQuestionObject.id ? (
                <Button
                  type="button"
                  color="danger"
                  size="sm"

                  onClick={() => {
                    setEditQuestionObject(undefined);
                    resetVariables();
                  }}
                  className="btn-rounded waves-effect waves-light m-2 mr-3  float-right"
                >
                  {props.t("Close")}
                </Button>
              ) : null}



              {!(editQuestionObject && question.id === editQuestionObject.id) && <ul className="list-inline font-size-18 contact-links float-right m-2 mr-3">
                <li
                  className="list-inline-item px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!creatingQuestion) {
                      setQuestionForDuplicating(question)
                      handleDuplicateQuesiton(question)
                    } else {
                      toastr.warning("updating question, please try again !!")
                    }
                  }}
                  disabled={creatingQuestion}
                  id={"duplicate" + question.id}
                >
                  {!(creatingQuestion && questionForDuplicating && question.id === questionForDuplicating.id) && <i className="bx bx-copy"></i>}

                  {creatingQuestion && questionForDuplicating && question.id === questionForDuplicating.id && (
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                  )}

                  <UncontrolledTooltip
                    placement="top"
                    target={"duplicate" + question.id}
                  >
                    {props.t("Duplicate")}
                  </UncontrolledTooltip>
                </li>

                <li
                  className="list-inline-item px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    resetVariables();

                    if (addQuestionView === true) {
                      resetVariables();
                      setAddQuestionView(false);
                    }
                    let tempOptions = [];
                    if (question.choices) {
                      let tempList = [];
                      for (var i = 0; i < question.choices.length; i++) {
                        tempOptions.push(i + 1);
                        let tempObj = getNewListObj("");
                        if (question.choices && question.choices.length > i) {
                          tempObj.optionText = question.choices[i];
                        }
                        if (question.choices2 && question.choices2.length > i) {
                          tempObj.optionText2 = question.choices2[i];
                        }
                        if (
                          question.optionImages &&
                          question.optionImages.length > i
                        ) {
                          tempObj.optionImage = question.optionImages[i];
                        }
                        if (
                          question.optionImages2 &&
                          question.optionImages2.length > i
                        ) {
                          tempObj.optionImage2 = question.optionImages2[i];
                        }

                        tempList.push(tempObj);
                      }
                      tempList.push(getNewListObj());
                      setListOfEntriesToDisplay(tempList);
                    }

                    setQuestionImageURL(question.questionImageURL);
                    setQuestionVideoURL(question.questionVideoURL);
                    setQuestionVideoThumbnail(question.questionVideoThumbnail);

                    setAttachment(question.attachment)
                    setAttachmentUploadProgress(0)

                    if (question.answer && question.answer.length > 0) {
                      setAnswerText(question.answer[0]);
                    }
                    if (question.answer && question.answer.length > 0) {
                      setAnswerArray(question.answer);
                    }

                    setMatchTheFollowingOptionsToDisplay(tempOptions);

                    setEditQuestionObject(question);
                    setQuestionTitle(question.questionTitle);
                    setQuestionDefaultMark(question.defaultMarks ?? 1);
                    setQuestionType(question.questionType);

                  }}
                  id={"edit" + question.id}
                >
                  <i className="bx bx-edit-alt"></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={"edit" + question.id}
                  >
                    {props.t("Edit")}
                  </UncontrolledTooltip>
                </li>
                <li
                  className="list-inline-item px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!creatingQuestion) {
                      setSelectedQuestionToRemove(question);

                    } else {
                      toastr.warning("updating question, please try again !!")
                    }
                  }}
                  disabled={creatingQuestion}
                  id={"delete1" + question.id}
                >
                  {!(creatingQuestion && selectedQuestionToRemove && question.id === selectedQuestionToRemove.id) && <i className="bx bx-trash text-danger"></i>}
                  {creatingQuestion && selectedQuestionToRemove && question.id === selectedQuestionToRemove.id && (
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1 text-danger"></i>
                  )}
                  <UncontrolledTooltip
                    placement="top"
                    target={"delete1" + question.id}
                  >
                    {props.t("Delete")}
                  </UncontrolledTooltip>
                </li>
              </ul>}

              {(editQuestionObject && question.id === editQuestionObject.id) && <Button
                onClick={(e) => {
                  let canProceed = true;

                  if (
                    editQuestionObject &&
                    question.id === editQuestionObject.id
                  ) {
                    canProceed = checkForMandatoryFields(editQuestionObject);

                    if (questionImageUploadInProgress) {
                      canProceed = false;
                      toastr.warning(
                        "Please wait while we finish uploading image...",
                        { timeOut: 1000 }
                      );
                    }

                    else if ((attachmentUploadProgress >= 1 &&
                      attachmentUploadProgress < 100) || (attachmentUploadProgress === 100 && attachment && !attachment._url)
                    ) {
                      canProceed = false;
                      toastr.warning(
                        "Please wait while we finish uploading document...",
                        { timeOut: 1000 }
                      );
                    } else if (questionVideoUploadInProgress) {
                      canProceed = false;
                      toastr.warning(
                        "Please wait while we finish uploading video...",
                        { timeOut: 1000 }
                      );
                    }



                    let imageUploading = false;
                    for (var i = 0; i < listOfEntriesToDisplay.length; i++) {
                      if (
                        listOfEntriesToDisplay[i].imageUploadInProgress ===
                        true ||
                        listOfEntriesToDisplay[i].imageUploadInProgress2 ===
                        true
                      ) {
                        canProceed = false;
                        imageUploading = true;
                      }
                    }
                    if (imageUploading) {
                      toastr.warning(
                        "Please wait while we finish uploading images...",
                        { timeOut: 1000 }
                      );
                    }

                    if (canProceed) {
                      handleEditQuestion(e);
                    }
                  }
                }}
                type="button"
                color="primary"
                size="sm"
                disabled={creatingQuestion}
                className="btn-rounded waves-effect waves-light m-2 float-right"
              >
                {creatingQuestion && editQuestionObject && question.id === editQuestionObject.id && (
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                )}
                {props.t("Save")}

              </Button>
              }



            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const checkForMandatoryFields = (questionObject) => {
    let tempQuestionType = questionObject.questionType ?? questionType;
    let canProceed = true;

    if (questionTitle == undefined || questionTitle.length === 0) {
      canProceed = false;
      setQuestionError(true);
      setShowWarningSign(true);
    }
    if (questionDefaultMark == undefined || questionDefaultMark.length === 0) {
      canProceed = false;
      setQuestionMarksError(true);
      setShowWarningSign(true);
    }

    if (tempQuestionType === Constants.EXAM_LONG_ANSWER) {
    } else if (tempQuestionType === Constants.EXAM_FILL_IN_THE_BLANKS) {
      if (answerText == undefined || answerText.trim().length === 0) {
        setAnswerTextError(true);

        canProceed = false;
      }
    } else if (
      tempQuestionType === Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST ||
      tempQuestionType === Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      if (answerArray == undefined || answerArray.length === 0) {
        setQuestionErrorHint("Please select the correct options");
        canProceed = false;
      } else {
        let allUndefined = true;
        for (var m = 0; m < answerArray.length; m++) {
          if (answerArray[m] != undefined) {
            allUndefined = false;
          }
        }
        if (allUndefined) {
          setQuestionErrorHint("Please select the correct options");
          canProceed = false;
        }
      }

      let tempCheckForOption = true;
      if (listOfEntriesToDisplay.length > 1) {
        for (var i = 0; i < listOfEntriesToDisplay.length; i++) {
          if (listOfEntriesToDisplay[i].id !== "newOption") {
            if (
              (listOfEntriesToDisplay[i].optionText == undefined ||
                listOfEntriesToDisplay[i].optionText.trim().length === 0) &&
              (listOfEntriesToDisplay[i].optionImage == undefined ||
                listOfEntriesToDisplay[i].optionImage.length === 0)
            ) {
              tempCheckForOption = false;
            }
          }
        }
      } else {
        setQuestionErrorHint("Please add atleast one option");
        canProceed = false;
      }

      if (tempCheckForOption === false) {
        canProceed = tempCheckForOption;
        setQuestionErrorHint(
          "Options must have either text or image or both, please fill options."
        );
      }
    } else if (tempQuestionType === Constants.EXAM_MATCH_THE_FOLLOWING) {
      if (answerArray == undefined || answerArray.length === 0) {
        setQuestionErrorHint("Please select the correct order of options");
        canProceed = false;
      } else {
        let anyUndefined = false;
        for (var m = 0; m < answerArray.length; m++) {
          if (answerArray[m] == undefined) {
            anyUndefined = true;
          }
        }
        if (anyUndefined) {
          setQuestionErrorHint("Please select all the correct options");
          canProceed = false;
        }
      }

      let tempCheckForOption = true;
      for (var i = 0; i < listOfEntriesToDisplay.length; i++) {
        if (listOfEntriesToDisplay[i].id !== "newOption") {
          if (
            (listOfEntriesToDisplay[i].optionText == undefined ||
              listOfEntriesToDisplay[i].optionText.trim().length === 0) &&
            (listOfEntriesToDisplay[i].optionImage == undefined ||
              listOfEntriesToDisplay[i].optionImage.length === 0)
          ) {
            tempCheckForOption = false;
          }
          if (
            (listOfEntriesToDisplay[i].optionText2 == undefined ||
              listOfEntriesToDisplay[i].optionText2.trim().length === 0) &&
            (listOfEntriesToDisplay[i].optionImage2 == undefined ||
              listOfEntriesToDisplay[i].optionImage2.length === 0)
          ) {
            tempCheckForOption = false;
          }
        }
      }
      if (tempCheckForOption === false) {
        canProceed = tempCheckForOption;
        setQuestionErrorHint(
          "Options must have either text or image or both, please fill options."
        );
      }
    } else if (tempQuestionType === Constants.EXAM_ARRANGE_THE_FOLLOWING) {
      if (answerArray == undefined || answerArray.length === 0) {
        setQuestionErrorHint("Please select the correct order of options");
        canProceed = false;
      } else {
        let anyUndefined = false;
        for (var m = 0; m < answerArray.length; m++) {
          if (answerArray[m] == undefined) {
            anyUndefined = true;
          }
        }
        if (anyUndefined) {
          setQuestionErrorHint("Please select all the correct options");
          canProceed = false;
        }
      }
      let tempCheckForOption = true;
      for (var i = 0; i < listOfEntriesToDisplay.length; i++) {
        if (listOfEntriesToDisplay[i].id !== "newOption") {
          if (
            (listOfEntriesToDisplay[i].optionText == undefined ||
              listOfEntriesToDisplay[i].optionText.trim().length === 0) &&
            (listOfEntriesToDisplay[i].optionImage == undefined ||
              listOfEntriesToDisplay[i].optionImage.length === 0)
          ) {
            tempCheckForOption = false;
          }
        }
      }
      if (tempCheckForOption === false) {
        canProceed = tempCheckForOption;
        setQuestionErrorHint(
          "Options must have either text or image or both, please fill options."
        );
      }
    }

    if (canProceed === false) {
      setShowWarningSign(true);
    } else {
      setShowWarningSign(false);
    }

    return canProceed;
  };

  const handleCloseButtonTextboxClicked = (
    value,
    keyInside,
    addNewTagsViewVariable = "addNewTagsView"
  ) => {
    let tempList = [...listOfEntriesToDisplay];

    tempList[keyInside][addNewTagsViewVariable] = false;
    setListOfEntriesToDisplay(tempList);
  };
  const handleDoneButtonTextboxClicked = (
    value,
    keyInside,
    optionTextVariable = "optionText",
    addNewTagsViewVariable = "addNewTagsView"
  ) => {
    if (value === "" || value.length === 0) {
      let tempList = [...listOfEntriesToDisplay];

      tempList[keyInside][addNewTagsViewVariable] = false;
      setListOfEntriesToDisplay(tempList);
    } else {
      let tempList = [...listOfEntriesToDisplay];
      tempList[keyInside][optionTextVariable] = value;
      tempList[keyInside][addNewTagsViewVariable] = false;
      if (keyInside === tempList.length - 1) {
        tempList[tempList.length - 1].id = "";
        tempList.push(getNewListObj());
      }

      setListOfEntriesToDisplay(tempList);
    }
  };

  const getNewListObj = (tempId = "newOption") => {
    let tempObj = {
      id: tempId,
      optionText: undefined,
      optionImage: undefined,
      optionText2: undefined,
      optionImage2: undefined,
      addNewTagsView: false,
      addNewTagsView2: false,
    };
    if (
      matchTheFollowingOptionsToDisplay == null ||
      matchTheFollowingOptionsToDisplay.length === 0
    ) {
      setMatchTheFollowingOptionsToDisplay([1]);
    } else {
      let tempListToDisplay = [...matchTheFollowingOptionsToDisplay];
      tempListToDisplay.push(
        tempListToDisplay[tempListToDisplay.length - 1] + 1
      );
      setMatchTheFollowingOptionsToDisplay(tempListToDisplay);
    }
    if (!questionType === Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST) {
      let tempAnswerArray = [...answerArray];
      tempAnswerArray.push(undefined);
      setAnswerArray(tempAnswerArray);
    }

    return tempObj;
  };

  const addNewTagsViewFunction = (
    questionKey,
    keyInside,
    optionTextVariable = "optionText",
    addNewTagsViewVariable = "addNewTagsView"
  ) => {
    return (
      <FormGroup>
        <CustomTextBoxWithButtons
          id={addNewTagsViewVariable + questionKey + keyInside}
          componentId={addNewTagsViewVariable + questionKey + keyInside}
          closeButtonClicked={(value) => {
            handleCloseButtonTextboxClicked(
              value,
              keyInside,
              addNewTagsViewVariable
            );
          }}
          doneButtonClicked={(value) => {
            handleDoneButtonTextboxClicked(
              value,
              keyInside,
              optionTextVariable,
              addNewTagsViewVariable
            );
          }}
        ></CustomTextBoxWithButtons>
      </FormGroup>
    );
  };
  const transparentButton = {
    cursor: "pointer",
    backgroundColor: "transparent",
    fontSize: "1.5em",
    border: "5px solid transparent",
  };

  // On image upload
  function handleQuestionImageUploadChange(changedImageURL, imageFile) {
    let fileExtension = imageFile._name.split(".");
    let tempFile = {
      actualImageName: imageFile._name,
      fileExtension: fileExtension[1].toLowerCase(),
      imageName: fileExtension[0],
      image: imageFile,
      imageURL: undefined,
    };

    //var name = tempFile.imageName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

    tempFile.image
      .save({
        progress: (value) => {
          if (value && value > 0.1) {
            setQuestionImageUploadInProgress(true);
          }
        },
      })
      .then(
        (result) => {
          // tempFile.imageURL = result._url;
          // tempFile.image = result;
          setQuestionImageURL(result._url);
          setQuestionImageUploadInProgress(false);
        },
        (error) => { }
      );
  }

  const getUIBasedOnQuestionType = (question, questionType, questionKey) => {
    if (questionType === Constants.EXAM_FILL_IN_THE_BLANKS) {
      return (
        <FormGroup>
          <Button
            className="mb-2"
            color="primary"
            onClick={() => {
              if (questionTitle != undefined) {
                let tempText = questionTitle;
                tempText = tempText.concat(" ________ ");
                setQuestionTitle(tempText);
              } else {
                setQuestionTitle(" ________ ");
              }
            }}
          >
            Add Blank
          </Button>
          <br />

          <Label>{props.t("Answer")}: </Label>
          <input
            type="text"
            name="editAnswerName"
            placeholder={props.t("Enter Answer...")}
            className="form-control"
            value={answerText}
            onChange={(e) => {
              if (e.target.value.length > 0) {
                setAnswerTextError(false);
                setShowWarningSign(false);
              }
              setAnswerText(e.target.value);
            }}
          />
          {answerTextError && (
            <p className="text-danger">{props.t("Answer can not be empty")}</p>
          )}
        </FormGroup>
      );
    } else if (
      questionType === Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST ||
      questionType === Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      return (
        <FormGroup>
          <div className="table-responsive mb-0">
            <table
              id={"choices" + questionKey}
              className="table table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>Options</th>
                  <th>
                    {questionType ===
                      Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST
                      ? "Correct Option"
                      : "Correct Options"}
                  </th>
                  <th>Remove Row</th>
                </tr>
              </thead>
              <tbody>
                {listOfEntriesToDisplay.map((entryObj, keyInside) => {
                  return (
                    <tr key={question + keyInside}>
                      <td>
                        <div>
                          {entryObj.id === "newOption" && (
                            <div>
                              <Label>Add New Option</Label>
                              <br />
                            </div>
                          )}
                          {entryObj.optionText == undefined &&
                            entryObj.addNewTagsView &&
                            addNewTagsViewFunction(questionKey, keyInside)}

                          {entryObj.optionText && entryObj.id !== "newOption" && (
                            <Badge
                              className={
                                "badge badge-soft-primary font-size-12 mt-2 mr-2 mb-2"
                              }
                            >
                              {entryObj.optionText &&
                                entryObj.optionText.length > 20
                                ? entryObj.optionText.slice(0, 19) + "..."
                                : entryObj.optionText}

                              <i
                                className="mdi mdi-window-close ml-1"
                                id={entryObj.optionText}
                                onClick={(e) => {
                                  let tempList = [...listOfEntriesToDisplay];
                                  tempList[keyInside].optionText = undefined;
                                  setListOfEntriesToDisplay(tempList);
                                }}
                              >
                                {" "}
                              </i>
                            </Badge>
                          )}
                          {entryObj.optionText == undefined &&
                            !entryObj.addNewTagsView && (
                              <Badge
                                className={
                                  "badge badge-soft-success font-size-12 mt-2 mr-2 mb-2"
                                }
                                onClick={() => {
                                  // setNewTag("");
                                  // setAddNewTagsView(true);
                                  let tempList = [...listOfEntriesToDisplay];
                                  entryObj.optionText = undefined;
                                  entryObj.addNewTagsView = true;
                                  tempList[keyInside] = entryObj;
                                  setListOfEntriesToDisplay(tempList);
                                }}
                              >
                                {props.t("Add Option")}{" "}
                                <i className="bx bx-plus ml-1"> </i>
                              </Badge>
                            )}
                          {entryObj.optionImage == undefined &&
                            !entryObj.imageUploadInProgress && (
                              <div className="d-flex">
                                <UpdatePhoto
                                  id={
                                    "imageUploadMainArrange" +
                                    questionKey +
                                    keyInside
                                  }
                                  tempId={
                                    "imageUploadMainArrange" +
                                    questionKey +
                                    keyInside
                                  }
                                  className="p-1 ml-2"
                                  buttonTitle="Upload Image"
                                  buttonTitleInitial="Upload Image"
                                  isPopupOn={false}
                                  onPicChange={(imageFile, url) => {
                                    handleImageUploadChange(
                                      url,
                                      imageFile,
                                      keyInside,
                                      "optionImage"
                                    );
                                  }}
                                  initialImage={""}
                                ></UpdatePhoto>
                              </div>
                            )}
                          {entryObj.imageUploadInProgress && (
                            <div className="mb-2">
                              <Label>
                                Image upload in progress please wait
                                <i className="bx bx-hourglass bx-spin ml-2"></i>
                                {entryObj.imageUploadProgressValue
                                  ? `${Math.floor(
                                    entryObj.imageUploadProgressValue
                                  )}%`
                                  : ""}
                              </Label>
                            </div>
                          )}
                          {/* {entryObj.optionImage && (
                            <div>
                              <img
                                src={entryObj.optionImage}
                                style={{
                                  maxHeight: "100px",
                                  maxWidth: "100px",
                                }}
                              ></img>
                            </div>
                          )} */}
                          {entryObj.optionImage && (
                            <Col
                              xl="12"
                              md="12"
                              sm="12"
                              xs="12"
                              className="pl-0"
                            >
                              <div className="border p-3 mb-3">
                                <Row>
                                  <Col lg={5}>
                                    <div className="ml-3">
                                      <img
                                        style={{ width: "7rem", height: "7rem" }}
                                        className=""
                                        src={entryObj.optionImage}
                                        alt=""
                                      />
                                    </div>
                                  </Col>
                                  <Col lg={7}>
                                    <div className="d-flex mt-3">
                                      <UpdatePhoto
                                        id={
                                          "editImage" + questionKey + keyInside
                                        }
                                        tempId={
                                          "editImage" + questionKey + keyInside
                                        }
                                        className="p-1"
                                        buttonTitle="Change"
                                        buttonTitleInitial="Change"
                                        isPopupOn={false}
                                        onPicChange={(imageFile, url) => {
                                          handleImageUploadChange(
                                            url,
                                            imageFile,
                                            keyInside,
                                            "optionImage"
                                          );
                                        }}
                                        imageFileName={"pic.jpg"}
                                        initialImage={entryObj.optionImage}
                                      ></UpdatePhoto>
                                      <Button
                                        style={{ marginLeft: '10px'}}
                                        className="btn-danger float-right ml-2"
                                        onClick={() => {
                                          let tempList = [
                                            ...listOfEntriesToDisplay,
                                          ];
                                          tempList[keyInside].optionImage =
                                            undefined;
                                          setListOfEntriesToDisplay(tempList);
                                        }}
                                      >
                                        Clear
                                      </Button>
                                    </div>
                                  </Col>

                                </Row>
                              </div>
                            </Col>
                          )}
                        </div>
                      </td>
                      <td align="center">
                        {entryObj.id !== "newOption" && (
                          <input
                            className="form-check-input mt-3"
                            type={
                              (question.questionType ?? questionType) ===
                                Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
                                ? "checkbox"
                                : "radio"
                            }
                            id={"radioButton" + questionKey + keyInside}
                            value={keyInside}
                            checked={
                              answerArray &&
                              answerArray.length > 0 &&
                              answerArray.includes(keyInside)
                            }
                            onChange={() => {
                              if (
                                (question.questionType ?? questionType) ===
                                Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST
                              ) {
                                handlerFunctionForRadio(keyInside);
                              } else {
                                handlerFunctionForCheckbox(keyInside);
                              }
                            }}
                          />
                        )}
                      </td>
                      <td>
                        {entryObj.id !== "newOption" && (
                          <div className="flex-fill text-center">
                            <Button
                              onClick={() => {
                                handleDeleteRow(keyInside);
                              }}
                              style={transparentButton}
                            >
                              <i className="bx bxs-trash text-danger"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div >
        </FormGroup >
      );
    } else if (questionType === Constants.EXAM_MATCH_THE_FOLLOWING) {
      return (
        <FormGroup>
          <div className="table-responsive mb-0">
            <table
              id={"matchTheFollowing"}
              className="table table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>1st Column</th>
                  <th>2nd Column</th>
                  <th>Correct Index of 2nd Column</th>
                  <th>Remove Row</th>
                </tr>
              </thead>
              <tbody>
                {listOfEntriesToDisplay.map((entryObj, keyInside) => {
                  return (
                    <tr key={question + keyInside}>
                      <td>
                        <div key={keyInside} className="d-flex">
                          {/* {listOfEntriesToDisplay.length !== keyInside + 1 && (
                            <label className="mr-2">{keyInside + 1}.</label>
                          )} */}
                          <div className="float-right">
                            {entryObj.id === "newOption" && (
                              <div>
                                <Label>Add New Option</Label>
                                <br />
                              </div>
                            )}
                            {entryObj.optionText == undefined &&
                              entryObj.addNewTagsView &&
                              addNewTagsViewFunction(questionKey, keyInside)}

                            {entryObj.optionText &&
                              entryObj.id !== "newOption" && (
                                <Badge
                                  className={
                                    "badge badge-soft-primary font-size-12 mr-2 mb-2"
                                  }
                                >
                                  {entryObj.optionText &&
                                    entryObj.optionText.length > 20
                                    ? entryObj.optionText.slice(0, 19) + "..."
                                    : entryObj.optionText}

                                  <i
                                    className="mdi mdi-window-close ml-1"
                                    id={entryObj.optionText}
                                    onClick={(e) => {
                                      let tempList = [
                                        ...listOfEntriesToDisplay,
                                      ];
                                      tempList[keyInside].optionText =
                                        undefined;
                                      setListOfEntriesToDisplay(tempList);
                                    }}
                                  >
                                    {" "}
                                  </i>
                                </Badge>
                              )}
                            {entryObj.optionText == undefined &&
                              !entryObj.addNewTagsView && (
                                <Badge
                                  className={
                                    "badge badge-soft-success font-size-12 mt-2 mr-2 mb-2"
                                  }
                                  onClick={() => {
                                    // setNewTag("");
                                    // setAddNewTagsView(true);
                                    let tempList = [...listOfEntriesToDisplay];
                                    entryObj.optionText = undefined;
                                    entryObj.addNewTagsView = true;
                                    tempList[keyInside] = entryObj;
                                    setListOfEntriesToDisplay(tempList);
                                  }}
                                >
                                  {props.t("Add Option")}{" "}
                                  <i className="bx bx-plus ml-1"> </i>
                                </Badge>
                              )}
                            {entryObj.optionImage == undefined &&
                              !entryObj.imageUploadInProgress && (
                                <div className="d-flex">
                                  <UpdatePhoto
                                    id={
                                      "imageUploadMainMatch" +
                                      questionKey +
                                      keyInside
                                    }
                                    tempId={
                                      "imageUploadMainMatch" +
                                      questionKey +
                                      keyInside
                                    }
                                    className="p-1 ml-2"
                                    buttonTitle="Upload Image"
                                    buttonTitleInitial="Upload Image"
                                    isPopupOn={false}
                                    onPicChange={(imageFile, url) => {
                                      handleImageUploadChange(
                                        url,
                                        imageFile,
                                        keyInside,
                                        "optionImage"
                                      );
                                    }}
                                    initialImage={""}
                                  ></UpdatePhoto>
                                </div>
                              )}
                            {entryObj.imageUploadInProgress && (
                              <div className="mb-2">
                                <Label>
                                  Image upload in progress please wait
                                  <i className="bx bx-hourglass bx-spin ml-2"></i>
                                  {entryObj.imageUploadProgressValue
                                    ? `${Math.floor(
                                      entryObj.imageUploadProgressValue
                                    )}%`
                                    : ""}
                                </Label>
                              </div>
                            )}

                            {entryObj.optionImage && (
                              <Col
                                xl="12"
                                md="12"
                                sm="12"
                                xs="12"
                                className="pl-0"
                              >
                                <div className="border p-3 mb-3">
                                  <Row>
                                    <div className="ml-3">
                                      <img
                                        style={{
                                          width: "7rem",
                                          height: "7rem",
                                        }}
                                        className=""
                                        src={entryObj.optionImage}
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      className="float-right ml-4 mb-2"
                                      style={{ width: "200px" }}
                                    >
                                      {/* <div className="text-muted font-weight-bold">{"Name"}</div> */}
                                      <div className="d-flex mt-3">
                                        <UpdatePhoto
                                          id={
                                            "editImage" +
                                            questionKey +
                                            keyInside
                                          }
                                          tempId={
                                            "editImage" +
                                            questionKey +
                                            keyInside
                                          }
                                          className="p-1"
                                          buttonTitle="Change"
                                          buttonTitleInitial="Change"
                                          isPopupOn={false}
                                          onPicChange={(imageFile, url) => {
                                            handleImageUploadChange(
                                              url,
                                              imageFile,
                                              keyInside,
                                              "optionImage"
                                            );
                                          }}
                                          imageFileName={"pic.jpg"}
                                          initialImage={entryObj.optionImage}
                                        ></UpdatePhoto>
                                        <Button
                                          className="btn-danger float-right ml-2"
                                          onClick={() => {
                                            let tempList = [
                                              ...listOfEntriesToDisplay,
                                            ];
                                            tempList[keyInside].optionImage =
                                              undefined;
                                            setListOfEntriesToDisplay(tempList);
                                          }}
                                        >
                                          Clear
                                        </Button>
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        <div key={keyInside} className="d-flex">
                          {listOfEntriesToDisplay.length !== keyInside + 1 && (
                            <label className="mr-2">{keyInside + 1}.</label>
                          )}
                          <div className="float-right">
                            {entryObj.id === "newOption" && (
                              <div>
                                <Label className="text-white">
                                  Add New Option
                                </Label>
                                <br />
                              </div>
                            )}

                            {entryObj.optionText2 == undefined &&
                              entryObj.addNewTagsView2 &&
                              addNewTagsViewFunction(
                                questionKey,
                                keyInside,
                                "optionText2",
                                "addNewTagsView2"
                              )}

                            {entryObj.optionText2 &&
                              entryObj.id !== "newOption" && (
                                <Badge
                                  className={
                                    "badge badge-soft-primary font-size-12  mr-2 mb-2"
                                  }
                                >
                                  {entryObj.optionText2 &&
                                    entryObj.optionText2.length > 20
                                    ? entryObj.optionText2.slice(0, 19) + "..."
                                    : entryObj.optionText2}

                                  <i
                                    className="mdi mdi-window-close ml-1"
                                    id={entryObj.optionText2}
                                    onClick={(e) => {
                                      let tempList = [
                                        ...listOfEntriesToDisplay,
                                      ];
                                      tempList[keyInside].optionText2 =
                                        undefined;
                                      setListOfEntriesToDisplay(tempList);
                                    }}
                                  >
                                    {" "}
                                  </i>
                                </Badge>
                              )}
                            {entryObj.optionText2 == undefined &&
                              !entryObj.addNewTagsView2 && (
                                <Badge
                                  className={
                                    "badge badge-soft-success font-size-12 mt-2 mr-2 mb-2"
                                  }
                                  onClick={() => {
                                    // setNewTag("");
                                    // setAddNewTagsView(true);
                                    let tempList = [...listOfEntriesToDisplay];
                                    entryObj.optionText2 = undefined;
                                    entryObj.addNewTagsView2 = true;
                                    tempList[keyInside] = entryObj;
                                    setListOfEntriesToDisplay(tempList);
                                  }}
                                >
                                  {props.t("Add Option")}{" "}
                                  <i className="bx bx-plus ml-1"> </i>
                                </Badge>
                              )}
                            {entryObj.optionImage2 == undefined &&
                              !entryObj.imageUploadInProgress2 && (
                                <div className="d-flex">
                                  <UpdatePhoto
                                    id={
                                      "imageUploadMainMatch2" +
                                      questionKey +
                                      keyInside
                                    }
                                    tempId={
                                      "imageUploadMainMatch2" +
                                      questionKey +
                                      keyInside
                                    }
                                    className="p-1 ml-2"
                                    buttonTitle="Upload Image"
                                    buttonTitleInitial="Upload Image"
                                    isPopupOn={false}
                                    onPicChange={(imageFile, url) => {
                                      handleImageUploadChange(
                                        url,
                                        imageFile,
                                        keyInside,
                                        "optionImage2"
                                      );
                                    }}
                                    initialImage={""}
                                  ></UpdatePhoto>
                                </div>
                              )}
                            {entryObj.imageUploadInProgress2 && (
                              <div className="mb-2">
                                <Label>
                                  Image upload in progress please wait
                                  <i className="bx bx-hourglass bx-spin ml-2"></i>
                                  {entryObj.imageUploadProgressValue2
                                    ? `${Math.floor(
                                      entryObj.imageUploadProgressValue2
                                    )}%`
                                    : ""}
                                </Label>
                              </div>
                            )}

                            {entryObj.optionImage2 && (
                              <Col
                                xl="12"
                                md="12"
                                sm="12"
                                xs="12"
                                className="pl-0"
                              >
                                <div className="border p-3 mb-3">
                                  <Row>
                                    <div className="ml-3">
                                      <img
                                        style={{
                                          width: "7rem",
                                          height: "7rem",
                                        }}
                                        className=""
                                        src={entryObj.optionImage2}
                                        alt=""
                                      />
                                    </div>
                                    <div
                                      className="float-right ml-4 mb-2"
                                      style={{ width: "200px" }}
                                    >
                                      {/* <div className="text-muted font-weight-bold">{"Name"}</div> */}
                                      <div className="d-flex mt-3">
                                        <UpdatePhoto
                                          id={
                                            "editImage2" +
                                            questionKey +
                                            keyInside
                                          }
                                          tempId={
                                            "editImage2" +
                                            questionKey +
                                            keyInside
                                          }
                                          className="p-1"
                                          buttonTitle="Change"
                                          buttonTitleInitial="Change"
                                          isPopupOn={false}
                                          onPicChange={(imageFile, url) => {
                                            handleImageUploadChange(
                                              url,
                                              imageFile,
                                              keyInside,
                                              "optionImage2"
                                            );
                                          }}
                                          imageFileName={"pic.jpg"}
                                          initialImage={entryObj.optionImage2}
                                        ></UpdatePhoto>
                                        <Button
                                          className="btn-danger float-right ml-2"
                                          onClick={() => {
                                            let tempList = [
                                              ...listOfEntriesToDisplay,
                                            ];
                                            tempList[keyInside].optionImage2 =
                                              undefined;
                                            setListOfEntriesToDisplay(tempList);
                                          }}
                                        >
                                          Clear
                                        </Button>
                                      </div>
                                    </div>
                                  </Row>
                                </div>
                              </Col>
                            )}
                          </div>
                        </div>
                      </td>
                      <td>
                        {entryObj.id !== "newOption" && (
                          <div key={keyInside} style={{ maxWidth: "200px" }}>
                            {answerArray[keyInside] != undefined ? (
                              <select
                                value={answerArray[keyInside] + 1}
                                className="form-select col-lg-6 mb-2"
                                id={"matchTheFollowing" + keyInside}
                                onChange={(e) => {
                                  let tempIntValue = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  let tempIntId = parseInt(keyInside, 10);
                                  handlerForMatchTheFollowing(
                                    tempIntId,
                                    tempIntValue
                                  );
                                }}
                              >
                                <option disabled selected value>
                                  {" "}
                                  -- select an option --{" "}
                                </option>
                                {matchTheFollowingOptionsToDisplay.map(
                                  (option, keyInside) => (
                                    <option
                                      id={keyInside}
                                      key={keyInside}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  )
                                )}
                              </select>
                            ) : (
                              <select
                                className="custom-select col-lg-6 mb-2"
                                id={"matchTheFollowing" + keyInside}
                                onChange={(e) => {
                                  let tempIntValue = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  let tempIntId = parseInt(keyInside, 10);
                                  handlerForMatchTheFollowing(
                                    tempIntId,
                                    tempIntValue
                                  );
                                }}
                              >
                                <option disabled selected value>
                                  {" "}
                                  -- select an option --{" "}
                                </option>
                                {matchTheFollowingOptionsToDisplay.map(
                                  (option, keyInside) => (
                                    <option
                                      id={keyInside}
                                      key={keyInside}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  )
                                )}
                              </select>
                            )}
                          </div>
                        )}
                      </td>
                      <td>
                        {entryObj.id !== "newOption" && (
                          <div className="flex-fill text-center">
                            <Button
                              onClick={() => {
                                handleDeleteRow(keyInside);
                              }}
                              style={transparentButton}
                            >
                              <i className="bx bxs-trash text-danger"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </FormGroup>
      );
    } else if (questionType === Constants.EXAM_ARRANGE_THE_FOLLOWING) {
      return (
        <FormGroup>
          <div className="table-responsive mb-0">
            <table
              id={"arrangeTheFollowing"}
              className="table table-striped table-bordered"
            >
              <thead>
                <tr>
                  <th>Jumbled Order</th>
                  <th>Correct Order</th>
                  <th>Remove Row</th>
                </tr>
              </thead>
              <tbody>
                {listOfEntriesToDisplay.map((entryObj, keyInside) => {
                  return (
                    <tr key={question + keyInside}>
                      <td>
                        <div>
                          {entryObj.optionText == undefined &&
                            entryObj.addNewTagsView &&
                            addNewTagsViewFunction(questionKey, keyInside)}
                          {entryObj.id === "newOption" && (
                            <div>
                              <Label>Add New Option</Label>
                              <br />
                            </div>
                          )}

                          {entryObj.optionText && entryObj.id !== "newOption" && (
                            <Badge
                              className={
                                "badge badge-soft-primary font-size-12 mt-2 mr-2 mb-2"
                              }
                            >
                              {entryObj.optionText &&
                                entryObj.optionText.length > 20
                                ? entryObj.optionText.slice(0, 19) + "..."
                                : entryObj.optionText}

                              <i
                                className="mdi mdi-window-close ml-1"
                                id={entryObj.optionText}
                                onClick={(e) => {
                                  let tempList = [...listOfEntriesToDisplay];
                                  tempList[keyInside].optionText = undefined;
                                  setListOfEntriesToDisplay(tempList);
                                }}
                              >
                                {" "}
                              </i>
                            </Badge>
                          )}
                          {entryObj.optionText == undefined &&
                            !entryObj.addNewTagsView && (
                              <Badge
                                className={
                                  "badge badge-soft-success font-size-12 mt-2 mr-2 mb-2"
                                }
                                onClick={() => {
                                  // setNewTag("");
                                  // setAddNewTagsView(true);
                                  let tempList = [...listOfEntriesToDisplay];
                                  entryObj.optionText = undefined;
                                  entryObj.addNewTagsView = true;
                                  tempList[keyInside] = entryObj;
                                  setListOfEntriesToDisplay(tempList);
                                }}
                              >
                                {props.t("Add Option")}{" "}
                                <i className="bx bx-plus ml-1"> </i>
                              </Badge>
                            )}
                          {entryObj.optionImage == undefined &&
                            !entryObj.imageUploadInProgress && (
                              <div className="d-flex">
                                <UpdatePhoto
                                  id={
                                    "imageUploadMainArrange" +
                                    questionKey +
                                    keyInside
                                  }
                                  tempId={
                                    "imageUploadMainArrange" +
                                    questionKey +
                                    keyInside
                                  }
                                  className="p-1 ml-2"
                                  buttonTitle="Upload Image"
                                  buttonTitleInitial="Upload Image"
                                  isPopupOn={false}
                                  onPicChange={(imageFile, url) => {
                                    handleImageUploadChange(
                                      url,
                                      imageFile,
                                      keyInside,
                                      "optionImage"
                                    );
                                  }}
                                  initialImage={""}
                                ></UpdatePhoto>
                              </div>
                            )}
                          {entryObj.imageUploadInProgress && (
                            <div className="mb-2">
                              <Label>
                                Image upload in progress please wait
                                <i className="bx bx-hourglass bx-spin ml-2"></i>
                                {entryObj.imageUploadProgressValue
                                  ? `${Math.floor(
                                    entryObj.imageUploadProgressValue
                                  )}%`
                                  : ""}
                              </Label>
                            </div>
                          )}
                          {/* {entryObj.optionImage && (
                            <div>
                              <img
                                src={entryObj.optionImage}
                                style={{
                                  maxHeight: "100px",
                                  maxWidth: "100px",
                                }}
                              ></img>
                            </div>
                          )} */}
                          {entryObj.optionImage && (
                            <Col
                              xl="12"
                              md="12"
                              sm="12"
                              xs="12"
                              className="pl-0"
                            >
                              <div className="border p-3 mb-3">
                                <Row>
                                  <div className="ml-3">
                                    <img
                                      style={{ width: "7rem", height: "7rem" }}
                                      className=""
                                      src={entryObj.optionImage}
                                      alt=""
                                    />
                                  </div>
                                  <div
                                    className="float-right ml-4 mb-2"
                                    style={{ width: "200px" }}
                                  >
                                    {/* <div className="text-muted font-weight-bold">{"Name"}</div> */}
                                    <div className="d-flex mt-3">
                                      <UpdatePhoto
                                        id={
                                          "editImage" + questionKey + keyInside
                                        }
                                        tempId={
                                          "editImage" + questionKey + keyInside
                                        }
                                        className="p-1"
                                        buttonTitle="Change"
                                        buttonTitleInitial="Change"
                                        isPopupOn={false}
                                        onPicChange={(imageFile, url) => {
                                          handleImageUploadChange(
                                            url,
                                            imageFile,
                                            keyInside,
                                            "optionImage"
                                          );
                                        }}
                                        imageFileName={"pic.jpg"}
                                        initialImage={entryObj.optionImage}
                                      ></UpdatePhoto>
                                      <Button
                                        className="btn-danger float-right ml-2"
                                        onClick={() => {
                                          let tempList = [
                                            ...listOfEntriesToDisplay,
                                          ];
                                          tempList[keyInside].optionImage =
                                            undefined;
                                          setListOfEntriesToDisplay(tempList);
                                        }}
                                      >
                                        Clear
                                      </Button>
                                    </div>
                                  </div>
                                </Row>
                              </div>
                            </Col>
                          )}
                        </div>
                      </td>
                      <td>
                        {entryObj.id !== "newOption" && (
                          <div key={keyInside} style={{ maxWidth: "100px" }}>
                            {answerArray[keyInside] != undefined ? (
                              <select
                                value={answerArray[keyInside] + 1}
                                className="custom-select col-lg-6 mb-2"
                                id={"arrangeTheFollowing" + keyInside}
                                onChange={(e) => {
                                  let tempIntValue = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  let tempIntId = parseInt(keyInside, 10);
                                  handlerForMatchTheFollowing(
                                    tempIntId,
                                    tempIntValue
                                  );
                                }}
                              >
                                <option disabled selected value>
                                  {" "}
                                  -- select an option --{" "}
                                </option>
                                {matchTheFollowingOptionsToDisplay.map(
                                  (option, keyInside) => (
                                    <option
                                      id={keyInside}
                                      key={keyInside}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  )
                                )}
                              </select>
                            ) : (
                              <select
                                className="custom-select col-lg-6 mb-2"
                                id={"arrangeTheFollowing" + keyInside}
                                onChange={(e) => {
                                  let tempIntValue = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  let tempIntId = parseInt(keyInside, 10);
                                  handlerForMatchTheFollowing(
                                    tempIntId,
                                    tempIntValue
                                  );
                                }}
                              >
                                <option disabled selected value>
                                  {" "}
                                  -- select an option --{" "}
                                </option>
                                {matchTheFollowingOptionsToDisplay.map(
                                  (option, keyInside) => (
                                    <option
                                      id={keyInside}
                                      key={keyInside}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  )
                                )}
                              </select>
                            )}
                          </div>
                        )}
                      </td>
                      <td>
                        {entryObj.id !== "newOption" && (
                          <div className="flex-fill text-center">
                            <Button
                              onClick={() => {
                                handleDeleteRow(keyInside);
                              }}
                              style={transparentButton}
                            >
                              <i className="bx bxs-trash text-danger"></i>
                            </Button>
                          </div>
                        )}
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </FormGroup>
      );
    }
  };
  const handleDeleteRow = (keyInside) => {
    let tempList = [...listOfEntriesToDisplay];

    removeElement(tempList, tempList[keyInside]);

    if (answerArray && answerArray.length > keyInside) {
      let tempAnswerArray = [...answerArray];
      let tempValue = tempAnswerArray[keyInside];
      removeElement(tempAnswerArray, tempAnswerArray[keyInside]);
      for (var i = 0; i < tempAnswerArray.length; i++) {
        if (tempAnswerArray[i] > tempValue) {
          --tempAnswerArray[i];
        }
      }
      setAnswerArray(tempAnswerArray);
    }

    let tempListToDisplay = [...matchTheFollowingOptionsToDisplay];
    tempListToDisplay.pop();
    setMatchTheFollowingOptionsToDisplay(tempListToDisplay);

    setListOfEntriesToDisplay(tempList);
  };

  const handlerForMatchTheFollowing = (index, value) => {
    let tempArray = [...answerArray];
    tempArray[index] = value - 1;
    setAnswerArray(tempArray);
  };

  const handlerFunctionForRadio = (keyInside) => {
    // let tempAnswerList = [...answerArray];
    // for (var i = 0; i < answerArray.length; i++) {
    //   if (i === keyInside) {
    //     tempAnswerList[i] = keyInside;
    //   } else {
    //     tempAnswerList[i] = undefined;
    //   }
    // }
    setAnswerArray([keyInside]);
    setQuestionErrorHint("");
    setShowWarningSign(false);
  };

  const handlerFunctionForCheckbox = (keyInside) => {
    let tempList = [...answerArray];

    if (tempList.includes(keyInside)) {
      removeElement(tempList, keyInside);
    } else {
      tempList.push(keyInside);
    }
    if (tempList.length === 0) {
      setQuestionErrorHint("Please select the correct option");
      setShowWarningSign(true);
    } else {
      setQuestionErrorHint("");
      setShowWarningSign(false);
    }

    setAnswerArray(tempList);
  };

  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  // On image upload
  function handleImageUploadChange(
    changedImageURL,
    imageFile,
    index,
    optionImageVariable
  ) {
    let fileExtension = imageFile._name.split(".");
    let tempFile = {
      actualImageName: imageFile._name,
      fileExtension: fileExtension[1].toLowerCase(),
      imageName: fileExtension[0],
      image: imageFile,
      imageURL: undefined,
    };

    //var name = tempFile.imageName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

    tempFile.image
      .save({
        progress: (value) => {
          if (value && value > 0.1) {
            let tempList = [...listOfEntriesToDisplay];

            if (optionImageVariable === "optionImage2") {
              tempList[index].imageUploadInProgress2 = true;
              tempList[index].imageUploadProgressValue2 = value * 100;
              setListOfEntriesToDisplay(tempList);
            } else {
              tempList[index].imageUploadInProgress = true;
              tempList[index].imageUploadProgressValue = value * 100;
              setListOfEntriesToDisplay(tempList);
            }
          }
        },
      })
      .then(
        (result) => {
          // tempFile.imageURL = result._url;
          // tempFile.image = result;

          // let tempList1 = [...optionImgs];
          // tempList1[index] = result._url;
          // setOptionImgs(tempList1);

          let tempList = [...listOfEntriesToDisplay];

          if (optionImageVariable === "optionImage2") {
            tempList[index].imageUploadProgressValue2 = undefined;
            tempList[index][optionImageVariable] = result._url;
            tempList[index].imageUploadInProgress2 = false;
            if (index === tempList.length - 1) {
              tempList[tempList.length - 1].id = "";
              tempList.push(getNewListObj());
            }
            setListOfEntriesToDisplay(tempList);
          } else {
            tempList[index].imageUploadProgressValue = undefined;
            tempList[index][optionImageVariable] = result._url;
            tempList[index].imageUploadInProgress = false;
            if (index === tempList.length - 1) {
              tempList[tempList.length - 1].id = "";
              tempList.push(getNewListObj());
            }
            setListOfEntriesToDisplay(tempList);
          }
        },
        (error) => { }
      );
  }

  const resetVariables = () => {
    setQuestionType(Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST);
    setQuestionTitle(undefined);
    setQuestionError(false);
    setShowWarningSign(false);
    setAddQuestionView(false);
    setMatchTheFollowingOptionsToDisplay([]);
    setQuestionErrorHint("");
    setAnswerTextError(false);
    setAnswerText(undefined);
    setAnswerArray([]);
    setQuestionImageURL(undefined);
    setQuestionVideoURL(undefined);
    setQuestionVideoThumbnail(undefined);
    setAttachment(undefined)
    setAttachmentUploadProgress(0)
    setListOfEntriesToDisplay([
      {
        id: "newOption",
        optionText: undefined,
        optionImage: undefined,
        addNewTagsView: false,
        addNewTagsView2: false,
        optionText2: undefined,
        optionImage2: undefined,
      },
    ]);
  };

  function getQuestionEditView(question, questionKey) {
    return (
      <div>
        <AvForm className="outer-repeater">
          <div data-repeater-item className="outer">
            <FormGroup>
              <Label style={{ fontSize: "15px" }}>
                {!editQuestionObject
                  ? props.t("Add New Question")
                  : props.t("Edit Question")}
              </Label>
              <br />
              {!question.isDefaultQuestion && (
                <FormGroup>
                  <Label>{props.t("Question Answer Type")}: </Label>
                  <br />
                  <select
                    className="form-select col-lg-6 mb-2"
                    id={"editQuestionType" + props.keyForQuestion}
                    value={questionType}
                    onChange={(e) => {
                      setQuestionType(parseInt(e.target.value, 10));
                    }}
                  >
                    <option
                      value={Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST}
                      defaultValue
                    >
                      Single choice from list of options
                    </option>
                    <option value={Constants.EXAM_FILL_IN_THE_BLANKS}>
                      Fill in the blanks
                    </option>

                    <option value={Constants.EXAM_ARRANGE_THE_FOLLOWING}>
                      Arrange the following
                    </option>
                    <option value={Constants.EXAM_MATCH_THE_FOLLOWING}>
                      Match the following
                    </option>

                    <option
                      value={Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST}
                    >
                      Multi selection from list of options
                    </option>
                    <option value={Constants.EXAM_LONG_ANSWER}>
                      Long answer
                    </option>
                  </select>
                </FormGroup>
              )}

              <Label>{props.t("Question Title")}: </Label>
              <textarea
                type="text"
                name="editQuestionName"
                placeholder={props.t("Enter Question Title...")}
                className="form-control"
                value={questionTitle}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setQuestionError(false);
                    setShowWarningSign(false);
                  }
                  setQuestionTitle(e.target.value);
                }}
              />
              {questionError && (
                <p className="text-danger">
                  {props.t("Question Title can not be empty")}
                </p>
              )}
              <Label className="mt-4">
                {props.t("Question Default Marks")}:{" "}
              </Label>
              <AvField
                type="number"
                name="editQuestionMarks"
                placeholder={props.t("Enter Question default marks...")}
                className="form-control"
                value={questionDefaultMark}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setQuestionMarksError(false);
                    setShowWarningSign(false);
                  }
                  setQuestionDefaultMark(e.target.value);
                }}
              />

              {questionMarksError && (
                <p className="text-danger">
                  {props.t("Question marks can not be empty")}
                </p>
              )}
            </FormGroup>
            <Row><Col lg={6} md={12} sm={12} xs={12}>
              <FormGroup className="position-relative">
                <Label>{props.t("Question Attachment")}: </Label>
                <div>{attachment == undefined && (
                  <div className="d-flex">
                    <Label
                      htmlFor={"input-file-expense"}
                      color="primary"
                      className="btn btn-primary"
                    >
                      Upload Attachment
                      <input
                        id={"input-file-expense"}
                        style={inputStyle}
                        type="file"
                        onChange={(e) => {
                          handleFileUploadChange(e);
                        }}
                        accept=".doc,.docx,.pdf,.xlsx,image,.mp3,.wma,.acc,.wav,.flac,.ppt,.pptx/*"
                      />
                    </Label>
                  </div>
                )}

                  {getUploadStatus() && (
                    <div className="border p-3">
                      <Row>
                        <Col lg={5}>
                          <div
                            className="avatar ml-3"
                            style={{ width: "5rem", height: "5rem" }}
                          >
                            <span
                              className="avatar-title bg-white ml-0 text-primary h1"
                              style={{ fontSize: "60px" }}
                            >
                              <i
                                className={getFileTypeImage(
                                  getFileExtension(attachment)
                                )}
                              ></i>
                            </span>
                          </div>
                          <div
                            className="text-muted font-weight-bold text-truncate"
                            style={{ width: "200px" }}
                          >
                            {getActualFileName(attachment)}
                          </div>
                        </Col>
                        <Col lg={7}>
                          <div className="d-flex justify-content-end">

                            <div className="d-flex mt-3">
                              <div>
                                <Label
                                  htmlFor={"input-file-expense-Update"}
                                  className="btn btn-warning m-1"
                                >
                                  Update
                                  <input
                                    id={"input-file-expense-Update"}
                                    style={inputStyle}
                                    type="file"
                                    onChange={(e) => {
                                      handleFileUploadChange(
                                        e
                                      );
                                    }}
                                    accept=".doc,.docx,.pdf,.xlsx,image,.mp3,.wma,.acc,.wav,.flac,.ppt,.pptx/*"
                                  />
                                </Label>
                              </div>

                              <Button
                                className="btn btn-danger float-right m-1"
                                color="danger"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  setAttachment(undefined)
                                  setAttachmentUploadProgress(0)
                                }}
                              >
                                Clear
                              </Button>

                              <Button
                                className="btn btn-primary float-right m-1"
                                color="primary"
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  if (attachment && attachment._url) {
                                    window.open(attachment._url, "_blank")
                                  } else {
                                    toastr.info("Please wait and try again...", { timeOut: 1000 })
                                  }
                                }}
                              >
                                Open
                              </Button>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  )}

                  {
                    showUploadProgress() && (
                      <div class="d-flex">
                        <div className="border p-3">
                          <Row>
                            <div
                              className="avatar ml-3"
                              style={{ width: "5rem", height: "5rem" }}
                            >
                              <span className="avatar-title bg-white ml-0 text-primary h1">
                                <i
                                  className={getFileTypeImage(attachment)}
                                ></i>
                              </span>
                            </div>
                            <div
                              className="float-right ml-4"
                              style={{ width: "200px" }}
                            >
                              <div className="text-muted font-weight-bold">
                                {attachment}
                              </div>
                              <div className=" mt-3">
                                <Progress
                                  color="primary"
                                  value={attachmentUploadProgress}
                                ></Progress>
                              </div>
                            </div>
                          </Row>
                        </div>
                      </div>
                    )}</div>
              </FormGroup>
            </Col>
              <Col lg={6} md={12} sm={12} xs={12}><Label>{props.t("Question Image")}: </Label>
                {!questionImageURL && !questionImageUploadInProgress && (
                  <div className="d-flex mt-0 mb-2">
                    <UpdatePhoto
                      id={"imageUploadMain"}
                      tempId={"imageUploadMain"}
                      className="p-1"
                      buttonTitle="Upload Image"
                      buttonTitleInitial="Upload Image"
                      isPopupOn={false}
                      onPicChange={(imageFile, url) => {
                        handleQuestionImageUploadChange(url, imageFile);
                      }}
                      initialImage={""}
                    ></UpdatePhoto>
                  </div>
                )}
                <br />
                {questionImageUploadInProgress && (
                  <div className="mb-2">
                    <Label>
                      Image upload in progress please wait
                      <i className="bx bx-hourglass bx-spin ml-2"></i>
                    </Label>
                  </div>
                )}

                {questionImageURL && (
                  <div className="border p-3 mb-3">
                    <Row>
                      <Col lg={5}>
                        <div className="ml-3">
                          <img
                            style={{ width: "7rem", height: "7rem" }}
                            className=""
                            src={questionImageURL}
                            alt=""
                          />
                        </div>
                      </Col>
                      <Col lg={7}>
                        <div className="d-flex">
                          <UpdatePhoto
                            id={"imageUpload"}
                            tempId={"imageUpload"}
                            className=""
                            buttonTitle="Change"
                            isPopupOn={false}
                            onPicChange={(imageFile, url) => {
                              handleQuestionImageUploadChange(url, imageFile);
                            }}
                            imageFileName={"pic.jpg"}
                            initialImage={questionImageURL}
                          ></UpdatePhoto>
                          <Button
                            style={{ marginLeft: '10px' }}
                            className="btn-danger"
                            onClick={() => {
                              setQuestionImageURL(undefined);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </Col>
                    </Row>
                  </div>
                )}
              </Col>
            </Row>
            <Row>
              <Col lg={6} md={12} sm={12} xs={12}><Label>{props.t("Question Video")}: </Label>
                {!questionVideoURL && !questionVideoUploadInProgress && (
                  <div className="table-responsive">
                    <Table className="table table-nowrap table-centered table-hover mb-0">
                      <Label htmlFor={"input-video"}>
                        <tr htmlFor={"input-video"} style={{ cursor: 'pointer' }} >
                          <td style={{ width: "45px" }}>
                            <div className="avatar-sm">
                              <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-24">
                                <i className={"bx bxs-video"}></i>
                              </span>
                            </div>
                          </td>
                          <td style={{ width: "140px" }}>
                            <h5 className="font-size-14 mb-1 text-dark">{"Video"}</h5>
                            <small> {"mp4,mov.."}</small>
                          </td>
                          <td>
                            <div className="text-center">
                              <div className="text-dark"><i className="bx bx-upload h3 m-0"></i></div>
                            </div>
                          </td>
                        </tr>
                        <input type="file"
                          className="sr-only"
                          id="input-video"
                          name="file"
                          accept={`video/mp4,video/x-m4v,video/*`}
                          onChange={(e) => {
                            onVideoUpload(e)
                          }}
                        />
                      </Label>
                    </Table>
                  </div>
                )}
                <br />
                {questionVideoUploadInProgress &&
                  <div className="mb-30">
                    <p className="text-info mb-2"> {props.t('Please do not close or refresh the page while uploading video/pdf or any other files')}</p>
                    <Progress striped color="primary" value={questionVideoUploadProgressValue}></Progress>
                    <h6 className="float-right mt-4 text-primary">{"Video Upload is in progress - " + questionVideoUploadProgressValue + "%"}</h6>
                  </div>}
                {questionVideoURL && (
                  <Col xl="12" md="12" sm="12" xs="12" className="pl-0">
                    <div className="border p-3 mb-3">
                      <Row>
                        <div className="ml-2 pb-3" style={{ width: "10rem", height: "10rem" }}>
                          <Iframe
                            url={getVimeoUrl(questionVideoURL)}
                            style={{ cursor: "pointer" }}
                            display="initial"
                            scrolling={"no"}
                            allowFullScreen={true}
                            position="relative"
                            frameBorder={0}
                            className="mt-2"
                          />
                        </div>
                        <div
                          className="ml-2 mb-1"
                          style={{ width: "200px" }}
                        >
                          {selectedVideoFile && <Label>{selectedVideoFile.name.split(".")}</Label>}
                          <div className="d-flex mt-1">
                            <Button
                              className="btn-danger"
                              onClick={() => {
                                setQuestionVideoURL(undefined);
                                setQuestionVideoThumbnail(undefined);
                              }}
                            >
                              Clear
                            </Button>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                )}
              </Col>
            </Row>



            {questionErrorHint.length > 0 && (
              <Label className="mb-4 text-danger">
                <i class="fa fa-exclamation-circle mr-2"></i>
                {questionErrorHint}
              </Label>
            )}

            {getUIBasedOnQuestionType(question, questionType, questionKey)}
          </div>
        </AvForm>
      </div>
    );
  }

  function getVimeoUrl(link) {
    var splits = link.split("/")
    var videoId = splits[splits.length - 2];
    var videokey = splits[splits.length - 1];
    return "https://player.vimeo.com/video/" + videoId + "?h=" + videokey;
  }

  function onVideoUpload(e) {

    let selectedFile = e.target.files[0]
    setSelectedVideoFile(e.target.files[0])

    let fileExtension = e.target.files[0].name.split(".")
    let fileName = fileExtension[0]



    // function videoUploadPreviev(e) {
    //   setFileType("Video");
    //   setSelectedFile(e.target.files[0]);
    //   setPreview(URL.createObjectURL(e.target.files[0]));
    //   var fileExtension = e.target.files[0].name.split(".");
    //   setActualFileName(e.target.files[0].name);
    //   setFileName(fileExtension[0]);
    //   setFileExtention(fileExtension[(fileExtension.length - 1)].toLowerCase());
    //   scrollToContent();
    // }



    if (selectedFile) {
      setQuestionVideoUploadInProgress(true)
      const accessToken = "bc7a5ee29880658b6f3689a45e63c5ec";
      setQuestionVideoUploadProgressValue(1);

      var uploader = new VimeoUpload({
        name: fileName,
        description: "",
        file: selectedFile,
        token: accessToken,
        upgrade_to_1080: true,
        privacy: { view: "unlisted" },
        onError: (data) => {
        },
        onProgress: (data) => {
          let value = Math.floor((data.loaded / data.total) * 100);
          setQuestionVideoUploadProgressValue(value);
        },
        onComplete: (videoId, index) => {

          var settings = {
            url: "https://api.vimeo.com/videos/" + videoId,
            method: "PATCH",
            timeout: 0,
            headers: {
              Authorization: "Bearer bc7a5ee29880658b6f3689a45e63c5ec",
              "Content-Type": "application/json",
            },
            data: JSON.stringify({ privacy: { view: "unlisted" } }),
          };

          let res = videoId.split(":")
          let vimeoUrl = "https://vimeo.com/" + res[0] + "/" + res[1];

          setQuestionVideoUploadInProgress(false)
          setQuestionVideoURL(vimeoUrl)

          var Files = Parse.Object.extend("Files");
          var fileObject = new Files();
          fileObject.set("folderId", `vimeoVideo`);
          fileObject.set("orderBy", 0);
          fileObject.set("fileType", "vimeo");
          fileObject.set("url", vimeoUrl);
          fileObject.set("name", fileName);

          $.ajax(settings).done((response) => {
            setQuestionVideoThumbnail(response.pictures.sizes[4].link)
            fileObject.set("introThumbnail", response.pictures.sizes[4].link);
            fileObject.save().then((result) => {
            });
          });
        },
      });
      uploader.upload();
    }
  }


  const handleDuplicateQuesiton = (question) => {

    if (question.parseObject) {
      setCreatingQuestion(true);

      let ExamQuestions = Parse.Object.extend("QuizQuestion");
      let examQuestion = new ExamQuestions();
      examQuestion.set("sectionId", question.parseObject.attributes.sectionId)
      examQuestion.set("defaultMarks", question.parseObject.attributes.defaultMarks ?? 1)
      examQuestion.set("optionImgs", question.parseObject.attributes.optionImgs)
      examQuestion.set("option2Imgs", question.parseObject.attributes.option2Imgs)
      examQuestion.set("question", question.parseObject.attributes.question)
      examQuestion.set("order", question.parseObject.attributes.order)
      examQuestion.set("isDeleted", question.parseObject.attributes.isDeleted)
      examQuestion.set("quizId", question.parseObject.attributes.quizId)
      examQuestion.set("options2", question.parseObject.attributes.options2)
      examQuestion.set("questionImage", question.parseObject.attributes.questionImage);
      examQuestion.set("quizVideo", question.parseObject.attributes.questionVideoURL);
      examQuestion.set("quizVideoThumbnail", question.parseObject.attributes.quizVideoThumbnail)
      examQuestion.set("attachments", question.parseObject.attributes.attachments);
      examQuestion.set("type", question.parseObject.attributes.type)
      examQuestion.set("isDefaultQuestion", question.parseObject.attributes.isDefaultQuestion)
      examQuestion.set("options", question.parseObject.attributes.options)
      examQuestion.set("answer", question.parseObject.attributes.answer)
      examQuestion.save().then((result) => {
        if (props.examObject) {
          let totalMarks = 0
          let totalQuestions = 0
          if (props.examObject.attributes.totalQuestions) {
            totalQuestions = props.examObject.attributes.totalQuestions
          } else {

            props.sectionList.forEach((section) => {
              section.questions.forEach((question) => {
                if (question.id !== "addNewQuestion") {
                  totalQuestions += 1
                }
              })
            })
          }
          totalQuestions += 1

          if (props.examObject.attributes.totalMarks) {
            totalMarks = props.examObject.attributes.totalMarks
          } else {

            props.sectionList.forEach((section) => {
              section.questions.forEach((question) => {
                if (question.id !== "addNewQuestion") {
                  totalMarks += question.defaultMarks ?? 1

                }
              })
            })

          }
          totalMarks += parseInt(result.attributes.defaultMarks ?? 1, 10)
          props.examObject.set("totalMarks", parseInt(totalMarks, 10))
          props.examObject.set("totalQuestions", parseInt(totalQuestions, 10))
          props.examObject.save().then((result1) => {
            let tempQuestionObj = {
              id: result.id,
              questionTitle: result.attributes.question,
              questionType: result.attributes.type,
              defaultMarks: result.attributes.defaultMarks ?? 1,
              parseObject: result,
            };

            tempQuestionObj.choices = result.attributes.options;
            tempQuestionObj.choices2 = result.attributes.options2;
            tempQuestionObj.optionImages2 = result.attributes.option2Imgs;

            tempQuestionObj.optionImages = result.attributes.optionImgs;
            tempQuestionObj.answer = result.attributes.answer;

            if (result.attributes.questionImage) {
              tempQuestionObj.questionImageURL = result.attributes.questionImage;
            }
            if (result.attributes.attachments && result.attributes.attachments.length > 0) {
              tempQuestionObj.attachment = result.attributes.attachments[0]
            }
            if (result.attributes.quizVideo) {
              tempQuestionObj.questionVideoURL = result.attributes.quizVideo;
            }
            if (result.attributes.quizVideoThumbnail) {
              tempQuestionObj.questionVideoThumbnail = result.attributes.quizVideoThumbnail;
            }
            setCreatingQuestion(false);
            props.handleAddNewQuestion(tempQuestionObj);
          })
        } else {
          let tempQuestionObj = {
            id: result.id,
            questionTitle: result.attributes.question,
            questionType: result.attributes.type,
            defaultMarks: result.attributes.defaultMarks ?? 1,
            parseObject: result,
          };

          tempQuestionObj.choices = result.attributes.options;
          tempQuestionObj.choices2 = result.attributes.options2;
          tempQuestionObj.optionImages2 = result.attributes.option2Imgs;

          tempQuestionObj.optionImages = result.attributes.optionImgs;
          tempQuestionObj.answer = result.attributes.answer;

          if (result.attributes.questionImage) {
            tempQuestionObj.questionImageURL = result.attributes.questionImage;
          }
          if (result.attributes.attachments && result.attributes.attachments.length > 0) {
            tempQuestionObj.attachment = result.attributes.attachments[0]
          }
          if (result.attributes.quizVideo) {
            tempQuestionObj.questionVideoURL = result.attributes.quizVideo;
          }
          if (result.attributes.quizVideoThumbnail) {
            tempQuestionObj.questionVideoThumbnail = result.attributes.quizVideoThumbnail;
          }


          setCreatingQuestion(false);
          props.handleAddNewQuestion(tempQuestionObj);

        }

      })






    }





  }

  const handleAddNewQuestion = () => {
    setCreatingQuestion(true);
    let ExamQuestions = Parse.Object.extend("QuizQuestion");
    let examQuestion = new ExamQuestions();

    examQuestion.set("quizId", props.examId);
    examQuestion.set("question", questionTitle);
    examQuestion.set("type", questionType);

    examQuestion.set("defaultMarks", parseInt(questionDefaultMark, 10));

    let optionTextList1 = [];
    let optionTextList2 = [];
    let optionImages1 = [];
    let optionImages2 = [];

    for (var i = 0; i < listOfEntriesToDisplay.length; i++) {
      if (listOfEntriesToDisplay[i].id != "newOption") {
        optionTextList1.push(listOfEntriesToDisplay[i].optionText);
        optionTextList2.push(listOfEntriesToDisplay[i].optionText2);
        optionImages1.push(listOfEntriesToDisplay[i].optionImage);
        optionImages2.push(listOfEntriesToDisplay[i].optionImage2);
      }
    }
    examQuestion.set("options", optionTextList1);
    examQuestion.set("options2", optionTextList2);
    examQuestion.set("optionImgs", optionImages1);
    examQuestion.set("option2Imgs", optionImages2);

    examQuestion.set("answer", answerArray);
    if (questionType === Constants.EXAM_FILL_IN_THE_BLANKS) {
      examQuestion.set("answer", [answerText]);
    }

    examQuestion.set("sectionId", props.sectionObject.parseObject.id);
    if (questionImageURL) {
      examQuestion.set("questionImage", [questionImageURL]);
    }
    if (attachment && attachment._url) {
      examQuestion.set("attachments", [attachment]);
    }
    if (questionVideoURL) {
      examQuestion.set("quizVideo", questionVideoURL);
    }
    if (questionVideoThumbnail) {
      examQuestion.set("quizVideoThumbnail", questionVideoThumbnail);
    }

    let greatestOrder = -1;
    for (var i = 0; i < props.questions.length; i++) {
      if (props.questions[i].parseObject) {
        if (props.questions[i].parseObject.attributes.order) {
          if (props.questions[i].parseObject.attributes.order > greatestOrder) {
            greatestOrder = props.questions[i].parseObject.attributes.order;
          }
        }
      }
    }
    examQuestion.set("order", greatestOrder + 1);
    examQuestion.save().then((result) => {

      if (props.examObject) {
        let totalMarks = 0
        let totalQuestions = 0
        if (props.examObject.attributes.totalQuestions) {
          totalQuestions = props.examObject.attributes.totalQuestions
        } else {

          props.sectionList.forEach((section) => {
            section.questions.forEach((question) => {
              if (question.id !== "addNewQuestion") {
                totalQuestions += 1
              }
            })
          })
        }
        totalQuestions += 1

        if (props.examObject.attributes.totalMarks) {
          totalMarks = props.examObject.attributes.totalMarks
        } else {

          props.sectionList.forEach((section) => {
            section.questions.forEach((question) => {
              if (question.id !== "addNewQuestion") {
                totalMarks += question.defaultMarks ?? 1

              }
            })
          })

        }
        totalMarks += parseInt(questionDefaultMark, 10)
        props.examObject.set("totalMarks", parseInt(totalMarks, 10))
        props.examObject.set("totalQuestions", parseInt(totalQuestions, 10))
        props.examObject.save().then((result1) => {

          let tempQuestionObj = {
            id: result.id,
            questionTitle: questionTitle,
            questionType: questionType,
            defaultMarks: questionDefaultMark,
            parseObject: result,
          };

          tempQuestionObj.choices = result.attributes.options;
          tempQuestionObj.choices2 = result.attributes.options2;
          tempQuestionObj.optionImages2 = result.attributes.option2Imgs;

          tempQuestionObj.optionImages = result.attributes.optionImgs;
          tempQuestionObj.answer = result.attributes.answer;

          if (questionImageURL) {
            tempQuestionObj.questionImageURL = questionImageURL;
          }
          if (attachment && attachment._url) {
            tempQuestionObj.attachment = attachment
          }
          if (questionVideoURL) {
            tempQuestionObj.questionVideoURL = questionVideoURL;
          }
          if (questionVideoThumbnail) {
            tempQuestionObj.questionVideoThumbnail = questionVideoThumbnail;
          }

          setAddQuestionView(false);
          setCreatingQuestion(false);
          props.handleAddNewQuestion(tempQuestionObj);

        })
      } else {
        let tempQuestionObj = {
          id: result.id,
          questionTitle: questionTitle,
          questionType: questionType,

          parseObject: result,
        };

        tempQuestionObj.choices = result.attributes.options;
        tempQuestionObj.choices2 = result.attributes.options2;
        tempQuestionObj.optionImages2 = result.attributes.option2Imgs;

        tempQuestionObj.optionImages = result.attributes.optionImgs;
        tempQuestionObj.answer = result.attributes.answer;

        if (questionImageURL) {
          tempQuestionObj.questionImageURL = questionImageURL;
        }
        if (attachment && attachment._url) {
          tempQuestionObj.attachment = attachment
        }
        if (questionVideoURL) {
          tempQuestionObj.questionVideoURL = questionVideoURL;
        }
        if (questionVideoThumbnail) {
          tempQuestionObj.questionVideoThumbnail = questionVideoThumbnail;
        }

        setAddQuestionView(false);
        setCreatingQuestion(false);
        props.handleAddNewQuestion(tempQuestionObj);
      }

    });
  };

  function handleEditQuestion(e) {
    setCreatingQuestion(true);
    let oldMarks = editQuestionObject.parseObject.attributes.defaultMarks
    let questionObject = editQuestionObject.parseObject;
    questionObject.set("question", questionTitle);
    questionObject.set("type", questionType);
    questionObject.set("defaultMarks", parseInt(questionDefaultMark, 10));
    let optionTextList1 = [];
    let optionTextList2 = [];
    let optionImages1 = [];
    let optionImages2 = [];

    for (var i = 0; i < listOfEntriesToDisplay.length; i++) {
      if (listOfEntriesToDisplay[i].id != "newOption") {
        optionTextList1.push(listOfEntriesToDisplay[i].optionText);
        optionTextList2.push(listOfEntriesToDisplay[i].optionText2);
        optionImages1.push(listOfEntriesToDisplay[i].optionImage);
        optionImages2.push(listOfEntriesToDisplay[i].optionImage2);
      }
    }
    questionObject.set("options", optionTextList1);
    questionObject.set("options2", optionTextList2);
    questionObject.set("optionImgs", optionImages1);
    questionObject.set("option2Imgs", optionImages2);

    if (questionImageURL) {
      questionObject.set("questionImage", [questionImageURL]);
    } else {
      questionObject.unset("questionImage");
    }
    if (attachment && attachment._url) {
      questionObject.set("attachments", [attachment]);
    } else {
      questionObject.unset("attachments");
    }
    if (questionVideoURL) {
      questionObject.set("quizVideo", questionVideoURL);
    } else {
      questionObject.unset("quizVideo");
    }
    if (questionVideoThumbnail) {
      questionObject.set("quizVideoThumbnail", questionVideoThumbnail);
    } else {
      questionObject.unset("quizVideoThumbnail");
    }

    questionObject.set("answer", answerArray);

    if (questionType === Constants.EXAM_FILL_IN_THE_BLANKS) {
      questionObject.set("answer", [answerText]);
    }
    questionObject.save().then((result) => {

      if (props.examObject) {
        let totalMarks = 0

        if (props.examObject.attributes.totalMarks) {
          totalMarks = props.examObject.attributes.totalMarks
        } else {

          props.sectionList.forEach((section) => {
            section.questions.forEach((question) => {
              if (question.defaultMarks && question.id !== "addNewQuestion") {
                totalMarks += question.defaultMarks
              }
            })
          })
        }



        if (oldMarks) {
          let defMarks = oldMarks
          let absDiff = Math.abs(defMarks - parseInt(questionDefaultMark, 10))

          if (defMarks > parseInt(questionDefaultMark, 10)) {
            totalMarks -= absDiff
          } else if (defMarks < parseInt(questionDefaultMark, 10)) {
            totalMarks += absDiff
          }
        } else {
          totalMarks += parseInt(questionDefaultMark, 10)
        }

        props.examObject.set("totalMarks", parseInt(totalMarks, 10))
        props.examObject.save().then((result1) => {
          editQuestionObject.questionTitle = questionTitle;
          editQuestionObject.questionType = questionType;
          editQuestionObject.defaultMarks = questionDefaultMark;

          editQuestionObject.questionImageURL = questionImageURL;
          editQuestionObject.questionVideoURL = questionVideoURL;
          editQuestionObject.questionVideoThumbnail = questionVideoThumbnail;
          editQuestionObject.attachment = attachment;
          editQuestionObject.parseObject = result;
          editQuestionObject.choices = result.attributes.options;
          editQuestionObject.choices2 = result.attributes.options2;
          editQuestionObject.optionImages2 = result.attributes.option2Imgs;

          editQuestionObject.optionImages = result.attributes.optionImgs;
          editQuestionObject.answer = result.attributes.answer;

          props.handleEditQuestion(editQuestionObject);

          setEditQuestionObject(undefined);

          resetVariables();
          setCreatingQuestion(false);
        })

      } else {
        editQuestionObject.questionTitle = questionTitle;
        editQuestionObject.questionType = questionType;
        editQuestionObject.defaultMarks = questionDefaultMark;

        editQuestionObject.questionImageURL = questionImageURL;
        editQuestionObject.questionVideoURL = questionVideoURL;
        editQuestionObject.questionVideoThumbnail = questionVideoThumbnail;
        editQuestionObject.attachment = attachment;

        editQuestionObject.parseObject = result;
        editQuestionObject.choices = result.attributes.options;
        editQuestionObject.choices2 = result.attributes.options2;
        editQuestionObject.optionImages2 = result.attributes.option2Imgs;

        editQuestionObject.optionImages = result.attributes.optionImgs;
        editQuestionObject.answer = result.attributes.answer;

        props.handleEditQuestion(editQuestionObject);

        setEditQuestionObject(undefined);

        resetVariables();
        setCreatingQuestion(false);
      }


    });
  }

  const handleRemoveQuestion = () => {
    setCreatingQuestion(true)
    let questionObject = selectedQuestionToRemove.parseObject;
    questionObject.set("isDeleted", true);
    let tempQues = selectedQuestionToRemove;
    questionObject.save().then(
      (result) => {


        if (props.examObject) {
          let totalMarks = 0
          let totalQuestions = 0
          if (props.examObject.attributes.totalQuestions) {
            totalQuestions = props.examObject.attributes.totalQuestions
          } else {

            props.sectionList.forEach((section) => {
              section.questions.forEach((question) => {
                if (question.id !== "addNewQuestion") {
                  totalQuestions += 1
                }
              })
            })
          }
          totalQuestions -= 1


          if (props.examObject.attributes.totalMarks) {
            totalMarks = props.examObject.attributes.totalMarks
          } else {

            props.sectionList.forEach((section) => {
              section.questions.forEach((question) => {
                if (question.id !== "addNewQuestion") {
                  totalMarks += question.defaultMarks ?? 1
                }
              })
            })
          }
          totalMarks -= questionObject.attributes.defaultMarks ?? 1
          props.examObject.set("totalMarks", parseInt(totalMarks, 10))
          props.examObject.set("totalQuestions", parseInt(totalQuestions, 10))
          props.examObject.save().then((result1) => {
            setCreatingQuestion(false)
            setSelectedQuestionToRemove(undefined);

            props.handleRemoveQuestion(tempQues);
            toastr.success("Question removed successfully !!", { timeOut: 1000 });

          })
        } else {
          setCreatingQuestion(false)
          setSelectedQuestionToRemove(undefined);

          props.handleRemoveQuestion(tempQues);
          toastr.success("Question removed successfully !!", { timeOut: 1000 });
        }


      },
      (error) => {
        setCreatingQuestion(false)
        setSelectedQuestionToRemove(undefined);

        toastr.success("Something went wrong, please try again !!", {
          timeOut: 1000,
        });
      }
    );
  };

  function getAddNewQuestionUI(question, questionKey) {
    if (addQuestionView) {
      return (
        <Row key={question.id} className="border-bottom">
          <Col lg={1} className="mt-3 mb-2">
            <div className="custom-control custom-checkbox mb-2 ml-3"></div>
          </Col>
          <Col lg={9} className="mt-3 mb-2">
            <div>{getQuestionEditView(question, questionKey)}</div>
          </Col>

          <Col lg={2} className="mt-2 mb-2">
            <Row>
              <Col>
                {showWarningSign && (
                  <Label className="text-danger" style={{ fontSize: "20px" }}>
                    <i class="fa fa-exclamation-circle mr-2 mt-2 pt-2"></i>
                  </Label>
                )}
                <Button
                  onClick={(e) => {
                    let canProceed = true;

                    canProceed = checkForMandatoryFields(question);
                    if (questionImageUploadInProgress) {
                      canProceed = false;
                      toastr.warning(
                        "Please wait while we finish uploading image...",
                        { timeOut: 1000 }
                      );
                    }
                    else if ((attachmentUploadProgress >= 1 &&
                      attachmentUploadProgress < 100) || (attachmentUploadProgress === 100 && attachment && !attachment._url)
                    ) {
                      canProceed = false;
                      toastr.warning(
                        "Please wait while we finish uploading document...",
                        { timeOut: 1000 }
                      );
                    }
                    else if (questionVideoUploadInProgress) {
                      canProceed = false;
                      toastr.warning(
                        "Please wait while we finish uploading video...",
                        { timeOut: 1000 }
                      );
                    }

                    let imageUploading = false;
                    for (var i = 0; i < listOfEntriesToDisplay.length; i++) {
                      if (
                        listOfEntriesToDisplay[i].imageUploadInProgress ===
                        true ||
                        listOfEntriesToDisplay[i].imageUploadInProgress2 ===
                        true
                      ) {
                        canProceed = false;
                        imageUploading = true;
                      }
                    }
                    if (imageUploading) {
                      toastr.warning(
                        "Please wait while we finish uploading images...",
                        { timeOut: 1000 }
                      );
                    }
                    if (canProceed) {
                      handleAddNewQuestion();
                    }
                  }}
                  type="button"
                  color="primary"
                  size="sm"
                  disabled={creatingQuestion}
                  className="btn-rounded waves-effect waves-light m-2"
                >
                  {creatingQuestion && (
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                  )}
                  {props.t("Save")}
                </Button>

                <Button
                  type="button"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    setEditQuestionObject(undefined);
                    resetVariables();
                  }}
                  className="btn-rounded waves-effect waves-light  "
                >
                  {props.t("Cancel")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col lg={12}>
            <div className="d-flex justify-content-end">
              <button
                onClick={() => {
                  if (addQuestionView === false) {
                    resetVariables();
                    setEditQuestionObject(undefined);
                  }
                  setAddQuestionView(!addQuestionView);
                }}
                type="button"
                className="btn btn-primary waves-effect float-right waves-light m-2 mt-4 mb-4"
              >
                <i className="bx bx-plus font-size-16 align-middle mr-2"></i>{" "}
                {props.t("Add Question")}
              </button>
            </div>
          </Col>
        </Row>
      );
    }
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var resultWithName = { name: file.name, result: reader.result };
      cb(resultWithName);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  const getFileExtension = (result) => {
    if (typeof result === "object") {
      let name = result._name.split(/_(.+)/)[1];
      let fileExtension = name.split(".");
      return fileExtension[1].toLowerCase()
    } else {
      return result
    }
  }

  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }

    return "bx bxs-file-blank";
  }

  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  function handleFileUploadChange(e) {
    setAttachment(undefined)
    setAttachmentUploadProgress(1)
    let fileExtension = e.target.files[0].name.split(".").pop();

    let tempFile = {
      actualFileName: e.target.files[0].name,
      fileExtension: fileExtension.toLowerCase(),
      fileName: e.target.files[0].name.split('.').slice(0, -1).join('.'),
      file: e.target.files[0],
    };
    // if (imgExtensions.includes(tempFile.fileExtension.toLowerCase())) {
    //   setAttachmentIsImage(true)
    // } else {
    //   setAttachmentIsImage(false)
    // }
    var name = tempFile.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "") + "." + tempFile.fileExtension;
    getBase64(tempFile.file, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result });
      parseFile
        .save({
          progress: (value) => {
            if (value && value > 0.1) {
              setAttachmentUploadProgress(value * 100)
            }
          },
        })
        .then(
          (result) => {
            setAttachment(result)
            setAttachmentUploadProgress(false)
          },
          (error) => {
          }
        );
    });
    setAttachment(tempFile.actualFileName)
  }

  const getUploadStatus = () => {

    if (attachment && attachment._url) {
      return true
    }
    return false
  }

  const showUploadProgress = () => {
    if (attachment && attachmentUploadProgress >= 1 && attachmentUploadProgress < 100) {
      return true
    } else if (attachment && !attachment._url) {
      return true
    } else {
      return false
    }
  }

  const getActualFileName = (result) => {
    if (typeof result === "object") {
      let name = result._name.split(/_(.+)/)[1];
      return name

    } else {
      return result
    }
  }

  return (
    <React.Fragment>
      {selectedQuestionToRemove && !creatingQuestion && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Remove"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setSelectedQuestionToRemove(undefined);
          }}
          onConfirm={() => handleRemoveQuestion()}
        >
          {props.t("Are you sure you want to remove the question")} <br></br>
        </SweetAlert>
      )}
      <div className="justify-content-center cursors move">
        {props.questions &&
          props.questions.map((question, questionKey) =>
            question.id === "addNewQuestion"
              ? getAddNewQuestionUI(question, questionKey)
              : getQuestionUI(question, questionKey)
          )}
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(ExamQuestion);
