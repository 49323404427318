
import React, { useState, useEffect } from 'react';
import { Row, Col, Form, Input, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from 'classnames';
import * as Constants from '../../Constents';
import { Link } from "react-router-dom";

const Topbar = (props) => {
    const activeTab = props.selected;
    const [searchString, setSearchString] = useState("");
    return (

        // <Row>

        //     <Col lg={4} md={4} xs={12}>
        //         <h4 className="mb-0 font-size-18">{props.title.toUpperCase()}</h4>
        //     </Col>
        //     <Col lg={8} md={8} xs={12}>
        //         <div className="mt-4 mt-sm-0 float-sm-right form-inline">

        //             <Link
        //                 className="btn btn-primary waves-effect waves-light mr-2"
        //                 to={props.schoolType==10? "/addCoordinator/"+props.schoolId:"/addstaff/"+props.schoolId}
        //                 role="button"                       
        //               >
        //                 {props.schoolType==10?"Add Coordinator":"Add staff"}
        //             </Link>
        //             <div className="search-box mr-2">
        //                 <div className="position-relative">
        //                     <Input type="text" className="form-control border-0" placeholder="Search..."
        //                         value={searchString}
        //                         onChange={(e) => {
        //                             setSearchString(e.target.value)
        //                             props.onSearch(e.target.value)
        //                         }}
        //                     />
        //                     <i className="bx bx-search-alt search-icon"></i>
        //                 </div>
        //             </div>
        //             <Nav className="product-view-nav" pills>
        //                 <NavItem>
        //                     <NavLink
        //                         className={classnames({ active: activeTab ===  Constants.DISPLAY_TYPE_GRID})}
        //                         onClick={() => { props.onSelect(Constants.DISPLAY_TYPE_GRID) }}
        //                     >
        //                         <i className="bx bx-grid-alt"></i>
        //                     </NavLink>
        //                 </NavItem>
        //                 <NavItem>
        //                     <NavLink
        //                         className={classnames({ active: activeTab === Constants.DISPLAY_TYPE_LIST })}
        //                         onClick={() => { props.onSelect(Constants.DISPLAY_TYPE_LIST) }}
        //                     >
        //                         <i className="bx bx-list-ul"></i>
        //                     </NavLink>
        //                 </NavItem>
        //             </Nav>
        //         </div>
        //     </Col>
        // </Row>
        <Row className="">
            <Col lg={3} sm={6}>
                <div className="">
                    <h4 className="mb-0 font-size-18">{props.title.toUpperCase()}</h4>
                </div>
            </Col>

            <Col lg={9} sm={6}>

                <Form className='mt-sm-0 float-sm-end d-sm-flex align-items-center'>
                    <Col lg={4} sm={4}>
                        <div className='justify-content-end m-1'>
                            <Link
                                className="btn btn-primary waves-effect waves-light mr-2"
                                to={props.schoolType == 10 ? "/addCoordinator/" + props.schoolId : "/addstaff/" + props.schoolId}
                                role="button"
                            >
                                {props.schoolType == 10 ? "Add Coordinator" : "Add staff"}
                            </Link>
                        </div>
                    </Col>
                    <Col lg={5} sm={5}>
                        <div className="search-box mr-2">
                            <div className="position-relative">
                                <Input type="text" className="form-control border-0" placeholder="Search..."
                                    value={searchString}
                                    onChange={(e) => {
                                        setSearchString(e.target.value)
                                        props.onSearch(e.target.value)
                                    }}
                                />
                                <i className="bx bx-search-alt search-icon"></i>
                            </div>
                        </div>
                    </Col>
                    <Col lg={3} sm={3}>
                        <Nav className="product-view-nav justify-content-end mt-3 mt-sm-0" pills>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === Constants.DISPLAY_TYPE_GRID })}
                                    onClick={() => { props.onSelect(Constants.DISPLAY_TYPE_GRID) }}
                                >
                                    <i className="bx bx-grid-alt"></i>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === Constants.DISPLAY_TYPE_LIST })}
                                    onClick={() => { props.onSelect(Constants.DISPLAY_TYPE_LIST) }}
                                >
                                    <i className="bx bx-list-ul"></i>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                </Form>

            </Col>
        </Row>
    );
}
export default Topbar;
