import * as actions from "./actionTypes";

const initialState = {
  admissionQuestions: [],
  defaultQuestions:[],
};

const AdmissionQuestion = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_ADMISSION_QUESTION:
      var admissionQuestions = [...state.admissionQuestions];
      var temp = state.admissionQuestions.filter(
        (admissionQuestion) =>
          admissionQuestion.id === action.payload.admissionQuestion.id
      );
      if (temp.length > 0) {

        if (action.payload.admissionQuestion.attributes.isDeleted) {

          var others = state.admissionQuestions.filter(
            (admissionQuestion) =>
              admissionQuestion.id !== action.payload.admissionQuestion.id
          );
          state = { ...state, admissionQuestions: others };
          
        } else {
          var index = admissionQuestions.indexOf(temp[0]);
          admissionQuestions[index] = action.payload.admissionQuestion;
          state = { ...state, admissionQuestions: admissionQuestions };
        }

       
      } else {
        if (!action.payload.admissionQuestion.attributes.isDeleted) {
          admissionQuestions.push(action.payload.admissionQuestion);
          state = { ...state, admissionQuestions: admissionQuestions };
        }
        
      }
      break;
    case actions.UPDATE_ADMISSION_QUESTIONS:
      var admissionQuestions = [...state.admissionQuestions];
      if (admissionQuestions.length > 0) {
        for (const latest in action.payload.admissionQuestions) {
          var temp = admissionQuestions.filter(
            (admissionQuestion) =>
              admissionQuestion.id === action.payload.admissionQuestions[latest].id
          );

          
          if (temp.length > 0) {
            if (action.payload.admissionQuestions[latest].attributes.isDeleted) {

              var others = admissionQuestions.filter(
                (admissionQuestion) =>
                  admissionQuestion.id !== action.payload.admissionQuestions[latest].id
              );
             
              admissionQuestions = others;
            } else {
              var index = admissionQuestions.lastIndexOf(temp[0]);
              admissionQuestions[index] = action.payload.admissionQuestions[latest];
            }
           
          } else {
            if (!action.payload.admissionQuestions[latest].attributes.isDeleted) {
              admissionQuestions.push(action.payload.admissionQuestions[latest]);
            }
            
          }
        }
        state = { ...state, admissionQuestions: admissionQuestions };
      } else {

        var others = action.payload.admissionQuestions.filter(
          (admissionQuestion) =>
            admissionQuestion.attributes.isDeleted !== true
        );
        state = { ...state, admissionQuestions: others };
      }

      break;
    case actions.DELETE_ADMISSION_QUESTION:
      var admissionQuestions = state.admissionQuestions.filter(
        (admissionquestion) => admissionquestion.id !== action.payload.id
      );
      state = { ...state, admissionQuestions: admissionQuestions };
      break;
    case actions.RELOAD_ADMISSION_QUESTION:
      state = { ...state, admissionQuestions: action.payload.admissionQuestions };
      break;
    
    case actions.UPDATE_DEFAULT_ADMISSION_QUESTIONS:
      state = { ...state, defaultQuestions: action.payload.admissionQuestions };
      break;
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default AdmissionQuestion;
