import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";


const initialState = {
  tickets: [], ticketAddLoading: false,
  selectedTicket: null,
  selectedTicketLog: null,
  seletedTicketTab:undefined,
};

const Tickets = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_TICKETS:

      var tickets = [...state.tickets];
      if (action.payload.tickets && action.payload.tickets.length == 1) {
        if (state.selectedTicket && state.selectedTicket.id == action.payload.tickets[0].id) {
          state = { ...state, selectedTicket: action.payload.tickets[0] }
        }
      }
      if (tickets.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.tickets], action.payload.tickets, "isDeleted")

        state = { ...state, tickets: updatedList };
      } else {
        var i = 0
        var ticketsToSave = []
        for (i = 0; i < action.payload.tickets.length; i++) {
          if (action.payload.tickets[i].attributes.isDeleted !== true) {
            ticketsToSave.push(action.payload.tickets[i])
          }
        }
        state = { ...state, tickets: ticketsToSave };
      }
      break;

    case actions.UPDATE_SELECTED_TICKET_LOG:

      if (action.payload.update) {
        var updatedList = ReduxUtil.updateParseObjects(state.selectedTicketLog?[...state.selectedTicketLog]:[], [action.payload.ticketLog[0]], "isDeleted")
        state = { ...state, selectedTicketLog: updatedList };
      }else if (action.payload.unshift) {
        let updatedList = [...state.selectedTicketLog]
        updatedList.unshift(action.payload.ticketLog[0]);
        state = { ...state, selectedTicketLog: updatedList };
      } else {
        state = { ...state, selectedTicketLog: action.payload.ticketLog };
      }
        
      break;

    case actions.UPDATE_SELECTED_TICKET:
      state = { ...state, selectedTicket: action.payload.ticket };
      break;
    case actions.UPDATE_DELETE_TICKET:
      var tickets = state.tickets.filter(
        (ticket) => ticket.id !== action.payload.ticket.id
      );
      state = { ...state, tickets: tickets };
      break;

    case actions.CLEAR_TICKETS:
    state = { ...state, tickets: [] };
    break

    case actions.SET_SELECTED_TAB:
    state = { ...state, seletedTicketTab: action.payload.seletedTab };
    break

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Tickets;
