import * as actions from "./actionTypes";

export const updateCurriculum = (curriculum) => {
  return {
    type: actions.UPDATE_CURRICULUM,
    payload: { curriculum },
  };
};

export const deleteCurriculum = (curriculum) => {
  return {
    type: actions.DELETE_CURRICULUM,
    payload: { curriculum },
  };
};

export const updateCurriculums = (curriculums,replace) => {
  return {
    type: actions.UPDATE_CURRICULUMS,
    payload: { curriculums,replace },
  };
};

export const getCurriculumForownerId = (ownerId) => {
  return {
    type: actions.GET_CURRICULUM_FOR_OWNER_ID,
    payload: { ownerId },
  };
};

export const getCurriculumsForOwnerIds = (ownerIds,curriculumId) => {
  return {
    type: actions.GET_CURRICULUMS,
    payload: { ownerIds,curriculumId },
  };
};

export const setCurriculumInitialLoading = (state) => {
  return {
    type: actions.SET_CURRICULUM_INITIAL_LOADING,
    payload: { state },
  };
};

export const clearCuriculams = () => {
  return {
    type: actions.CLEAR_CURRICULUM,
    payload: {  },
  };
};

export const updateCurriculumInitialLoading = (state) => {
  return {
    type: actions.UPDATE_CURRICULUM_INITIAL_LOADING,
    payload: { state },
  };
};

export const addNewCurriculum = (curriculum,cycles,schoolId,history) => {
  return {
    type: actions.ADD_NEW_CURRICULUM,
    payload: { curriculum,cycles,schoolId,history },
  };
};

export const setSelectedCurriculum = (id) => {
  return {
    type: actions.SET_SELECTED_CURRICULUM,
    payload: { id },
  };
};

export const updateSelectedCurriculum = (curriculum) => {
  return {
    type: actions.UPDATE_SELECTED_CURRICULUM,
    payload: { curriculum },
  };
};
