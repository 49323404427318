import React, { useState, useEffect } from "react";
import { setSelecetdSchool, getEnquiryTemplate, updateEnquiryTemplate } from "../../store/actions";

import toastr from "toastr";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Input,
    CardTitle,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Button, Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    UncontrolledTooltip
} from "reactstrap";
import SimpleBar from "simplebar-react";
//Import Images
import avatar2 from "../../assets/images/users/user.png";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import classnames from "classnames";


const TempletModal = (props) => {


    const [templateString, setTemplateString] = useState()
    const [addTemplateView, setAddTemplateView] = useState(false)
    const [message, setMessage] = useState()
    const [title, setTitle] = useState()
    const [messageFor, setMessageFor] = useState("School")
    const [selectedTemplate, setSelectedTemplate] = useState()
    const [selectedCardIndex, setSelectedCardIndex] = useState(-1);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [templateStringError, setTemplateStringError] = useState()
    const [activeTab, setActiveTab] = useState("1");

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== props.schoolId) {
            props.setSelecetdSchool(props.schoolId);
        }
    }, [props.selectedSchool, props.schoolId]);

    useEffect(() => {
        if (props.selectedSchool) {
            props.getEnquiryTemplate(Parse.User.current().attributes.teacherId, props.selectedSchool.id, props.selectedSchool.attributes.instituteId)
        }
    }, [props.selectedSchool])

    // console.log(props.enquiryTemplates)
    // console.log(props.userTeacher)

    useEffect(() => {
        if (selectedTemplate) {
            setTitle(selectedTemplate.attributes.templateTitle);
            setMessage(selectedTemplate.attributes.templateSting);
            setMessageFor(selectedTemplate.attributes.schoolId ? "School" : "Institute");
        } else {
            setTitle();
            setMessage();
            setMessageFor("School");
        }
    }, [selectedTemplate])

    console.log(selectedTemplate)

    const saveTemplate = () => {
        const Template = Parse.Object.extend('Template');
        let template = new Template();

        if (selectedTemplate) {
            template = selectedTemplate;
        } else {
            if (messageFor === "School") {
                template.set("schoolId", props.selectedSchool.id);
            } else if (messageFor === "Institute") {
                template.set("instituteId", props.selectedSchool.attributes.instituteId);
            }
            template.set("createdBy", Parse.User.current().attributes.teacherId);
        }
        template.set("templateTitle", title);
        template.set("templateSting", message);

        template.save().then(
            (result) => {
                console.log(result);
                setAddTemplateView(false);
                props.updateEnquiryTemplate([template])
            },
            (error) => {
                console.error('Error saving template:', error);
            }
        );
    };

    const handleDeleteTemplate = () => {

        selectedTemplate.set("isDeleted", true);

        selectedTemplate.save().then(
            (result) => {
                setShowDeleteAlert(false);
                props.updateEnquiryTemplate([selectedTemplate])
            },);
    }

    const sendEnquiryEmail = () => {
        Parse.Cloud.run("function_sendSMStoLeads", {
            schoolId: props.selectedSchool.id,
            message: templateString,
            enquiryIds: [props.enquiryId]
        }).then((response) => {
            console.log(response)
            toastr.success("Email sent successfully", { timeOut: 1000 });
            props.closeTemplateModal()
        }, (error) => {
            console.log("error" + error.message)
        });
    }

    const replaceWithSchoolName = (message, isDefault = false) => {
        if (isDefault) {
            return message.replace("[SchoolName]", props.selectedSchool.attributes.Name);
        }
        return message;
    }
    const toggleTab = (tab) => {
        if (activeTab !== tab) setActiveTab(tab);
    };

    const renderTemplates = (templates, isDefault) => {
        return (
            <div className="mt-2">
                <SimpleBar style={{ maxHeight: "400px" }} autoHide={false}>
                    <div className="table-responsive">
                        <Table className="table table-nowrap table-centered table-hover mb-0">
                            <tbody>
                                {templates.map((template, index) => (
                                    <tr key={index}>
                                        <Card className={`shadow-sm bg-light ${selectedCardIndex === index ? 'border border-dark' : ''}`}
                                            onClick={() => {
                                                setTemplateString(replaceWithSchoolName(template.attributes.templateSting, template.attributes.defaultTemplate))
                                                setTemplateStringError(false)
                                                setSelectedCardIndex(index);
                                            }}>
                                            <div className="p-0">
                                                <div className="d-flex justify-content-between align-items-center">
                                                    <h5 className="text-truncate font-size-15 mb-1">
                                                        <Link to="#" className="text-dark">{template.attributes.templateTitle}</Link>
                                                    </h5>
                                                    {!template.attributes.defaultTemplate && (
                                                        <div>
                                                            <span className="border rounded px-2 d-inline-block mr-2" style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedTemplate(template);
                                                                    setAddTemplateView(true);
                                                                }}>
                                                                <i className="bx bx-edit-alt"></i>
                                                            </span>
                                                            <span className="border rounded px-2 d-inline-block text-danger" style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setSelectedTemplate(template);
                                                                    setShowDeleteAlert(true);
                                                                }}>
                                                                <i className="bx bx-trash"></i>
                                                            </span>
                                                        </div>
                                                    )}
                                                </div>
                                                <p className="mb-0">{replaceWithSchoolName(template.attributes.templateSting, template.attributes.defaultTemplate)}</p>
                                                <p className="text-warning">{template.attributes.defaultTemplate && "From lilTriangle"}</p>
                                            </div>
                                        </Card>
                                    </tr>
                                ))}
                                {templates.length === 0 && (
                                    <tr>
                                        <td colSpan="3" className="text-center">No templates available.</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                    </div>
                </SimpleBar>
            </div>
        );
    };

    return (
        <React.Fragment>
            {showDeleteAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDeleteAlert(false);
                    }}
                    onConfirm={() => handleDeleteTemplate()}
                >
                    {"Are you sure you want to delete?"}
                    <br />
                </SweetAlert>
            )}
            <Modal size="lg" isOpen={props.openTempateModal}>
                <ModalHeader toggle={() => {
                    props.closeTemplateModal()
                }}>
                    {selectedTemplate ? "Edit Template" : addTemplateView ? "Add Template" : "Select Template"}
                </ModalHeader>
                <ModalBody>
                    {addTemplateView ? (
                        <Row>
                            <Col></Col>
                            <Col lg={8}>
                                <FormGroup>
                                    <Label>Title</Label>
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="description"
                                        placeholder="Add Title"
                                        value={title}
                                        onChange={(e) => setTitle(e.target.value)}
                                    />
                                </FormGroup>
                                {props.userTeacher.attributes.Role === 3 && (
                                    <FormGroup className="d-flex justify-content-between align-items-center">
                                        <div className="d-flex justify-content-between">
                                            <FormGroup className="mb-0">
                                                <Label >
                                                    <Input
                                                        type="radio"
                                                        name="purposeRadio"
                                                        checked={messageFor === "School"}
                                                        disabled={selectedTemplate}
                                                        onClick={() => {
                                                            setMessageFor("School")
                                                        }} />{' '}
                                                    For Branch
                                                </Label>
                                            </FormGroup>
                                            <FormGroup className="mb-0" style={{ marginLeft: '1cm' }}>
                                                <Label>
                                                    <Input
                                                        type="radio"
                                                        name="purposeRadio"
                                                        checked={messageFor === "Institute"}
                                                        disabled={selectedTemplate}
                                                        onClick={() => {
                                                            setMessageFor("Institute")
                                                        }} />{' '}
                                                    All Branch
                                                </Label>
                                            </FormGroup>
                                        </div>
                                    </FormGroup>
                                )}

                                <FormGroup>
                                    <Label>Message</Label>
                                    <Input
                                        className="form-control"
                                        type="textarea"
                                        id="description"
                                        placeholder=""
                                        rows="15"
                                        value={message}
                                        onChange={(e) => setMessage(e.target.value)}
                                    />
                                </FormGroup>
                            </Col>

                            <Col></Col>
                        </Row>
                    ) : (
                        <Row>
                            <Col>
                                <Card>
                                    <CardBody>
                                        <Row>
                                            <Col lg={7} className="mb-2">
                                                <CardTitle><h4>Enquiry Templates</h4></CardTitle>
                                            </Col>
                                            <Col>
                                                <Button
                                                    color='primary'
                                                    className='btn btn-sm'
                                                    onClick={() => {
                                                        setAddTemplateView(true)
                                                    }}>
                                                    Add Template
                                                </Button>
                                            </Col>
                                        </Row>
                                        <Nav pills className="navtab-bg nav-justified">
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "1",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("1");
                                                    }}
                                                >
                                                    {props.t("School Templates")}
                                                </NavLink>
                                            </NavItem>
                                            <NavItem>
                                                <NavLink
                                                    style={{ cursor: "pointer" }}
                                                    className={classnames({
                                                        active: activeTab === "2",
                                                    })}
                                                    onClick={() => {
                                                        toggleTab("2");
                                                    }}
                                                >
                                                    {props.t("Default Templates")}
                                                </NavLink>
                                            </NavItem>
                                        </Nav>
                                        <TabContent activeTab={activeTab}>
                                            <TabPane tabId="1">
                                                {renderTemplates(
                                                    props.enquiryTemplates.filter(
                                                        (template) =>
                                                            !template.attributes.defaultTemplate
                                                    )
                                                )}
                                            </TabPane>
                                            <TabPane tabId="2">
                                                {renderTemplates(
                                                    props.enquiryTemplates.filter(
                                                        (template) =>
                                                            template.attributes.defaultTemplate
                                                    ),
                                                    true
                                                )}
                                            </TabPane>
                                        </TabContent>
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col>
                                <div className="table-responsive">
                                    <Table className="table table-nowrap table-centered table-hover mb-0">
                                        <tbody>
                                            <tr>
                                                <Card className="">
                                                    <CardTitle>Selected Template or Type Message</CardTitle>
                                                    <div className="p-0">
                                                        <Input className="form-control"
                                                            type="textarea"
                                                            id="description"
                                                            placeholder=""
                                                            rows={templateStringError ? "18" : "21"}
                                                            value={templateString}
                                                            onChange={(e) => {
                                                                setTemplateString(e.target.value);
                                                                setTemplateStringError(false)
                                                            }}
                                                        />
                                                        {templateStringError && <p className="text-danger">Please select the template or type message to send enquiry message.</p>}
                                                    </div>
                                                </Card>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </div>
                            </Col>
                        </Row>
                    )}
                </ModalBody>
                <ModalFooter>
                    <Row>
                        <Col>
                            {addTemplateView ? (
                                <>
                                    <Button
                                        color="primary"
                                        className='btn float-end m-1'
                                        onClick={saveTemplate}>
                                        Save
                                    </Button>
                                    <Button
                                        color="secondary"
                                        className='btn float-end m-1'
                                        onClick={() => setAddTemplateView(false)}>
                                        Cancel
                                    </Button>
                                </>
                            ) : (
                                <>
                                    <Button
                                        color="primary"
                                        className='btn float-end m-1'
                                        onClick={() => {
                                            if (templateString) {
                                                sendEnquiryEmail()
                                            } else {
                                                setTemplateStringError(true)
                                            }
                                        }}>
                                        Send Email
                                    </Button>
                                    <Button
                                        color="success"
                                        className='btn float-end m-1'
                                        onClick={() => {
                                            if (templateString) {
                                                let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name;
                                                let parentName = "Parent";
                                                // const message = `Dear ${parentName},\n\n<Your message>\n\nRegards,\n${schoolName}`;
                                                const whatsappUrl = `https://api.whatsapp.com/send?phone=${props.phoneNumber}&text=${encodeURIComponent(templateString)}`;
                                                window.open(whatsappUrl, '_blank');
                                                // web.whatsapp.com
                                            } else {
                                                setTemplateStringError(true)
                                            }

                                        }}>
                                        Send Whatsapp Message
                                    </Button>
                                    <Button
                                        color="secondary"
                                        className='btn float-end m-1'
                                        onClick={props.closeTemplateModal}>
                                        Cancel
                                    </Button>
                                </>
                            )}
                        </Col>
                    </Row>
                </ModalFooter>
            </Modal>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {

    const { schools, selectedSchool } = state.School;
    const { enquiryTemplates } = state.Enquiry;
    const { userTeacher } = state.Login;

    return {
        schools, selectedSchool,
        enquiryTemplates,
        userTeacher,
    };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, { setSelecetdSchool, getEnquiryTemplate, updateEnquiryTemplate })(TempletModal)
    )
);
