import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as FeeTemplateHelper from "../../../helpers/ParseHelpers/feeTemplate_helper";
import * as actionTypes from "./actionTypes";
import * as FeeTemplateActions from "./actions";
import * as Constants from "../../../Constents";
import actions from "redux-form/lib/actions";
import toastr from "toastr";

function* getAFeeTemplate({ payload: { id } }) {
  try {
    const response = yield call(
      FeeTemplateHelper.getFeeTemplateFromParse,
      id,
      false
    );
    yield put(FeeTemplateActions.updateFeeTemplate(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAFeeTemplate({ payload: { FeeTemplateId } }) {
  try {
    const response = yield call(
      FeeTemplateHelper.deleteFeeTemplateFromParse,
      FeeTemplateId
    );
    if (response) {
      yield put(FeeTemplateActions.deleteFeeTemplate(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getFeeTemplateForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.FEE_TEMPLATE_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      FeeTemplateHelper.getFeeTemplateForSchoolId,
      schoolId,
      null,
      true
    );
    if (localResponse && localResponse.length > 0) {
      yield put(FeeTemplateActions.updateFeeTemplates(localResponse));
    }

    const serverResponse = yield call(
      FeeTemplateHelper.getFeeTemplateForSchoolId,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);
    if (serverResponse && serverResponse.length > 0) {
      yield put(FeeTemplateActions.updateFeeTemplates(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* createFeeTemplateSchoolId({ payload: { pram } }) {
  try {
    let response = yield call(FeeTemplateHelper.createTemplateForSchool, pram);
    response.pin();
    yield put(FeeTemplateActions.setOffLoader(response));
  } catch (err) {
    console.log(err);
  }
}
function* deleteATemplateForSchool({ payload: { id } }) {
  try {
    let response = yield call(FeeTemplateHelper.deleteTemplateForSchool, id);
    response.pin();
    yield put(FeeTemplateActions.updateAfterDeleteFeeTemplates(response));
    toastr.success("Template Deleted Successfully", { timeOut: 1500 });
  } catch (err) {
    console.log(err);
  }
}
function* getFeeType({ payload: {} }) {
  try {
    let response = yield call(FeeTemplateHelper.getFeeTypes);
    yield put(FeeTemplateActions.setFeeType(response));
  } catch (err) {
    console.error(err);
  }
}

function* editFeeTemplate({ payload: { feeTemplate } }) {
  try {
    const response = yield call(FeeTemplateHelper.editFeeTemplateForSchool, feeTemplate);
    if (response) {
      yield put(FeeTemplateActions.updateFeeTemplate(response));
      toastr.success('Updated successfully', { timeOut: 1500 })
      response.pin();
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetFeeTemplate() {
  yield takeEvery(actionTypes.GET_FEE_TEMPLATE, getAFeeTemplate);
}

export function* watchDeleteFeeTemplate() {
  yield takeEvery(actionTypes.DELETE_FEE_TEMPLATE, deleteAFeeTemplate);
}

export function* watchGetFeeTemplateForSchoolId() {
  yield takeEvery(actionTypes.GET_FEE_TEMPLATES, getFeeTemplateForSchoolId);
}

export function* watchcreateFeeTemplateForSchoolId() {
  yield takeEvery(
    actionTypes.CREATE_FEETEMPLATE_FOR_SCHOOLID,
    createFeeTemplateSchoolId
  );
}
export function* wathchDeleteTemplateForSchool() {
  yield takeEvery(
    actionTypes.DELETE_A_TEMPLATE_FOR_SCHOOL,
    deleteATemplateForSchool
  );
}
export function* watchGetFeeTypes() {
  yield takeEvery(actionTypes.GET_FEETYPE, getFeeType);
}
export function* watchEditFeeTemplate() {
  yield takeEvery(actionTypes.EDIT_FEE_TEMPLATE, editFeeTemplate);
}

function* FeeTemplateSaga() {
  yield all([
    fork(watchGetFeeTemplate),
    fork(watchDeleteFeeTemplate),
    fork(watchGetFeeTemplateForSchoolId),
    fork(watchcreateFeeTemplateForSchoolId),
    fork(wathchDeleteTemplateForSchool),
    fork(watchGetFeeTypes),
    fork(watchEditFeeTemplate),
  ]);
}
export default FeeTemplateSaga;
