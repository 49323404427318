import * as actions from "./actionTypes";

export const getQABMapForBatchId = (courseId,batchId) => {
  return {
    type: actions.GET_QAB_MAP_FOR_BATCH,
    payload: { courseId,batchId }
  }
}

export const updateQABMapForBatchId = (qabMaps) => {
  return {
    type: actions.UPDATE_QAB_MAP_FOR_BATCH,
    payload: { qabMaps }
  }
}

export const addQABMap = (qabMap,schoolId,sendNotofication) => {
  return {
    type: actions.ADD_QAB,
    payload: { qabMap ,schoolId,sendNotofication}
  }
}

export const setQABMapLoading = (state) => {
  return {
    type: actions.ADD_QAB_LOADING,
    payload: { state }
  }
}