import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardTitle,
    Container,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Progress,
    CustomInput,
    UncontrolledTooltip,
    Table

} from "reactstrap";

import * as WhiteLable from "../../../components/Common/WhiteLable";

import SweetAlert from "react-bootstrap-sweetalert";

import Moment from "moment";
import * as Scroll from "react-scroll";
import toastr from "toastr";

import {
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from "react-scroll";
import classnames from "classnames";
import UpdatePhoto from "../../../components/Common/UpdatePhoto";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";

import * as Constants from "../../../Constents";
import CourseRequests from "./CourseRequests";
import {
    getFranchise,
    sendAdmissionLinkToParent,
    getParentsForFamilyIds,
    getCourseSubscriptionForUserId,
    getCoursesForOwnerIds,
    getAllCourseRequestsForKidId,
    addSubscriptionsToCourse,
    getCourseBatchesForCourseIds,

} from "../../../store/actions";
import CoursesSubscribed from "./CoursesSubscribed";

const CoursesComponent = (props) => {

    const [isLoading, setIsLoading] = useState(false);
    const [subscribedCourse, subscribedCourseList] = useState([]);
    const SELECT_SUBSCRIPTION_MODE = "Select Enrollment Mode"
    const modeOfRequest = [SELECT_SUBSCRIPTION_MODE, "No-Fee User", "Sponsored User", "Prepaid User"]
    const [subscriptionModeForNewSubs, setSubscriptionModeForNewSubs] = useState({})
    const [nonSubscribers, setNonSubscribers] = useState([]);
    const [subscriberModel, setSubscriberModel] = useState(false);
    const [searchBarString, setSearchBarString] = useState("");
    const [newSubscribers, setNewSubscribers] = useState([]);
    const [mandatoryCheckErrorListForMode, setMandatoryCheckErrorListForMode] = useState([]);
    const [ownerIds, setOwnerIds] = useState([]);

    const [courseIds, setCourseIds] = useState([]);
    const [subscriptionBatchForNewSubs, setSubscriptionBatchForNewSubs] = useState({});
    const [mandatoryCheckErrorListForBatch, setMandatoryCheckErrorListForBatch] = useState([]);


    useEffect(() => {
        //props.getCourseForownerId(props.selectedSchool.id);
        //props.getAllKids(props.selectedSchool)
        //props.setSelectedCourse(courseId);


        var ids = [];
        if (props.selectedSchool) {
            ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }


            if (ownerIds.length === ids.length) {

                var call = false;
                for (const i in ownerIds) {
                    if (ownerIds[i] !== ids[i]) {
                        call = true;
                    }
                }
                if (call) {
                    props.getCoursesForOwnerIds(ids);
                }

            } else {
                setOwnerIds(ids);
                props.getCoursesForOwnerIds(ids);
            }
        }

    }, [props.selectedSchool]);

    useEffect(() => {
        props.getCourseSubscriptionForUserId(props.kidId);
        props.getAllCourseRequestsForKidId(props.kidId)
    }, [props.kidId])

    useEffect(() => {
        let allIds = [];
        let newIds = [];

        if (props.courses) {
            for (const cr of props.courses) {
                allIds.push(cr.id);
                if (!courseIds.includes(cr.id)) {
                    newIds.push(cr.id)
                }
            }
        }
        if (newIds.length > 0) {
            props.getCourseBatchesForCourseIds(allIds)
        }
        setCourseIds(allIds);

    }, [props.courses])

    const getBatchesForCourseId = (courseId) => {
        let batches = [];
        if (props.allCourseBatches) {
            for (const cb of props.allCourseBatches) {
                if (cb.attributes.courseId===courseId) {
                    batches.push(cb);
                }
            }
        }
        return batches;
    }
    function getDateStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }

    const handelAddSubscribersToCourse = () => {

        let newSubs = [];
        let tempMandatoryCheckErrorListForMode = [];
        let tempMandatoryCheckErrorListForBatch = []
        for (const courseId of newSubscribers) {
            let newsub = {};
            newsub.kidId = props.kidId;
            newsub.kidIds = [props.kidId];
            newsub.courseId = courseId;
            newsub.subscribedDate = new Date();
            if (subscriptionModeForNewSubs[courseId] && subscriptionModeForNewSubs[courseId] !== SELECT_SUBSCRIPTION_MODE) {
                newsub.mode = subscriptionModeForNewSubs[courseId]
            } else {
                tempMandatoryCheckErrorListForMode.push(courseId)
            }

            if (subscriptionBatchForNewSubs[courseId]) {
                newsub.batch = subscriptionBatchForNewSubs[courseId]
            } else {
                tempMandatoryCheckErrorListForBatch.push(courseId)
            }

            newSubs.push(newsub);
        }

        if (tempMandatoryCheckErrorListForMode.length > 0 || tempMandatoryCheckErrorListForBatch.length>0) {
            setMandatoryCheckErrorListForMode(tempMandatoryCheckErrorListForMode);
            setMandatoryCheckErrorListForBatch(tempMandatoryCheckErrorListForBatch);
        } else {
            if (newSubs.length > 0) {
                setSubscriberModel(false)
                props.addSubscriptionsToCourse(newSubs, true);
            } else {
                toastr.warning("Please select atleast one course")
            }
        }



    }
    const handleAddCourseButtonClick = () => {

        let subs = [];
        let nonSubs = [];
        let objectForModeOfSub = {}
        if (props.courseSubscriptionsForUser) {
            for (const k of props.courseSubscriptionsForUser) {
                subs.push(k.attributes.courseId)
            }
        }

        let courseIdsToExclude = []
        for (const cr of props.courseRequests) {
            if (cr.attributes.status === Constants.CSR_SUBMITTED || cr.attributes.status === Constants.CSR_RESUBMITTED) {
                courseIdsToExclude.push(cr.attributes.courseId)
            }
        }

        if (props.courses) {
            for (const k of props.courses) {
                if (k.attributes.state === "Published") {
                    if (!subs.includes(k.id)) {
                        if (!courseIdsToExclude.includes(k.id)) {
                            nonSubs.push(k)
                            objectForModeOfSub[k.id] = SELECT_SUBSCRIPTION_MODE
                        }
                    }
                }
            }
        }

        setSubscriptionModeForNewSubs(objectForModeOfSub)
        setNonSubscribers(nonSubs);
        setSubscriberModel(true);
    }

    const handleSearchBarTextChange = (value) => {
        setSearchBarString(value);
    };

    const getNonSubList = (list) => {
        var listToReturn = [...list];
        if (searchBarString === "") {
        } else {
            listToReturn = listToReturn.filter((object) =>
                object.attributes.Name.toLowerCase().includes(
                    searchBarString.toLowerCase()
                )
            );
        }
        return listToReturn;
    }

    //// Utilities functions
    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    const getCheckBoxValue = (kidId) => {
        if (newSubscribers.includes(kidId)) {
            return true
        }
        return false
    }

    //SchoolId is parameter
    return (
        <React.Fragment>
            <Container>
                {!isLoading && (
                    <Card>

                        <CardBody>
                            <Row className="mb-3">
                                <Col lg={10}><h4 className="mt-1 ml-1">Courses </h4></Col>
                                <Col lg={2} className="text-right">
                                    <Button onClick={() => {
                                        handleAddCourseButtonClick()
                                    }}
                                        type="button"
                                        color="success"
                                        className="btn waves-effect waves-light">
                                        <i className="mdi mdi-plus mr-1"></i>
                                        {"Add Course"}
                                    </Button>
                                </Col>

                            </Row>


                            <CourseRequests
                                schoolId={props.selectedSchool.id}
                                kidId={props.kidId}
                                allCourses={props.courses}
                                courseRequests={props.courseRequests}
                            />
                            <CoursesSubscribed
                                allCourses={props.courses}
                                courseSubscriptionsForUser={props.courseSubscriptionsForUser}
                                courseRequests={props.courseRequests}
                                kidId={props.kidId}
                            />
                        </CardBody>
                    </Card>
                )}
                {!isLoading && false && (
                    <Card>
                        <CardBody>
                            <AvForm
                                className="outer-repeater"
                                style={{ border: "1px" }}
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">
                                        <h5>No Courses to show...</h5>
                                    </div>
                                </div>
                            </AvForm>
                        </CardBody>
                    </Card>
                )}
                {isLoading && false && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Courses...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>
            <Modal
                isOpen={subscriberModel}
                fade={false}
                className="modal-dialog"
                size='lg'
            >



                <ModalHeader className="bg-light" toggle={() => {
                    setSubscriberModel(!subscriberModel);
                }}>
                    <Row lg={12} md={12} sm={12} style={{ width: "100%" }} >
                        <Col lg={6} md={6} sm={6} xs={12}><Label className='mt-1'>Add Course</Label></Col>
                        <Col lg={6} md={6} sm={6} xs={12} className='p-0'>

                            <div className="search-box p-0 float-right ">
                                <div className="position-relative">
                                    <Input type="text" className="form-control border-0" value={props.searchValue} placeholder="Search..." onChange={(e) => handleSearchBarTextChange(e.target.value)} />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody
                    style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
                >




                    <div className="table-responsive mt-2">
                        {nonSubscribers.length > 0 ? <Table className="table-centered table-nowrap table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope='col' className="col-1">
                                        <div></div>
                                    </th>

                                    <th className="col-5" scope="col">Name</th>

                                    <th className="col-3" scope="col" style={{ textAlign: "center" }}>
                                        {props.t("Batch")}<Label className='text-danger'>*</Label>
                                    </th>

                                    <th className="col-3" scope="col" style={{ textAlign: "center" }}>
                                        {props.t("Enrollment Mode")}<Label className='text-danger'>*</Label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {getNonSubList(nonSubscribers).map((course, key) => (
                                    <tr key={"Nonsubs_" + key}>
                                        <td>
                                            <div className="custom-control custom-checkbox">
                                                <>
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input input-mini"
                                                        id={`checkbox${course.id}`}
                                                        checked={
                                                            getCheckBoxValue(course.id)
                                                        }
                                                        onClick={(e) => {


                                                            let newList = [...newSubscribers];

                                                            if (newList.includes(course.id)) {
                                                                removeElement(newList, course.id)
                                                                setNewSubscribers(newList);
                                                            } else {
                                                                newList.push(course.id);
                                                                setNewSubscribers(newList);
                                                            }

                                                        }}
                                                    />
                                                    <Label
                                                        className="custom-control-label"
                                                        for={`checkbox${course.id}`}
                                                    ></Label></>
                                            </div>
                                        </td>

                                        <td>
                                            <>
                                                <Label className='mt-2'>{course.attributes.name}</Label>
                                            </>
                                        </td>
                                         <td>
                                            {<select className="form-control"
                                                id={"select" + key}
                                                value={subscriptionBatchForNewSubs[course.id]}
                                                onChange={(e) => {
                                                    let tempObj = { ...subscriptionBatchForNewSubs }
                                                    tempObj[course.id] = e.target.value
                                                    if (mandatoryCheckErrorListForBatch.includes(course.id)) {
                                                        let tempMandatoryErrorListForBatch = [...mandatoryCheckErrorListForBatch]
                                                        removeElement(tempMandatoryErrorListForBatch, course.id)
                                                        setMandatoryCheckErrorListForBatch(tempMandatoryErrorListForBatch)
                                                    }
                                                    setSubscriptionBatchForNewSubs(tempObj)
                                                }}
                                            >
                                                <option disabled selected value>
                                                    Pick Batch
                                                </option>
                                                {getBatchesForCourseId(course.id,props.allCourseBatches).map((cb, keyInside) => (
                                                    <option key={keyInside} value={cb.id}>{getDateStringForDate(cb.attributes.startDate) + " to " + getDateStringForDate(cb.attributes.endDate)}</option>
                                                ))}
                                            </select>}
                                            {mandatoryCheckErrorListForBatch.includes(course.id) && (
                                                <p className="label-xsmall text-danger ml-1 mt-2">
                                                    This field is mandatory
                                                </p>
                                            )}

                                        </td>
                                        <td >
                                            < >
                                                {<select className="form-control"
                                                    id={"select" + key}
                                                    value={subscriptionModeForNewSubs[course.id]}
                                                    onChange={(e) => {
                                                        let tempObj = { ...subscriptionModeForNewSubs }
                                                        tempObj[course.id] = e.target.value
                                                        if (mandatoryCheckErrorListForMode.includes(course.id)) {
                                                            let tempMandatoryErrorListForMode = [...mandatoryCheckErrorListForMode]
                                                            removeElement(tempMandatoryErrorListForMode, course.id)
                                                            setMandatoryCheckErrorListForMode(tempMandatoryErrorListForMode)
                                                        }
                                                        setSubscriptionModeForNewSubs(tempObj)
                                                    }}
                                                >
                                                    {modeOfRequest.map((choice, keyInside) => (
                                                        <option key={keyInside} value={choice}>{choice}</option>
                                                    ))}
                                                </select>}
                                                {mandatoryCheckErrorListForMode.includes(course.id) && (
                                                    <p className="label-xsmall text-danger ml-1 mt-2">
                                                        This field is mandatory
                                                    </p>
                                                )}

                                            </>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table> : <Card>
                            <CardBody className="text-center" >

                                <h3 className="mt-5">{
                                    props.kids && props.kids.length === 0 ?
                                        props.t('There are no courses in the accademy') :
                                        props.t('No courses to show')
                                }</h3>
                                <p>{props.t('All available courses are subscribed by student')}.</p>


                            </CardBody>
                        </Card>}
                    </div>
                </ModalBody>
                <ModalFooter className="bg-light">
                    {<Button color="secondary" onClick={() => {
                        setSubscriberModel();
                    }}>
                        Cancel
                    </Button>}{" "}

                    {nonSubscribers.length > 0
                        && <Button color="primary" onClick={handelAddSubscribersToCourse}>
                            {props.t("Add selected courses")}
                        </Button>}


                </ModalFooter>
            </Modal>

        </React.Fragment>
    );
};


const mapStatetoProps = (state) => {
    const { courseSubscriptionsForUser } = state.CourseSubscription;
    const { selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { parents } = state.Parent;
    const { courses } = state.Course;
    const { courseRequests, initialLoding } = state.CourseRequests;
    const { allCourseBatches } = state.CourseBatch;


    return {
        selectedSchool,
        franchise,
        parents,
        courseSubscriptionsForUser,
        courses,
        courseRequests,
        initialLoding,
        allCourseBatches
    };
};
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getFranchise,
            sendAdmissionLinkToParent,
            getParentsForFamilyIds,
            getCourseSubscriptionForUserId,
            getCoursesForOwnerIds,
            getAllCourseRequestsForKidId,
            addSubscriptionsToCourse,
            getCourseBatchesForCourseIds

        })(CoursesComponent)
    )
);
