import React, { useEffect, useState } from 'react';
import { Modal, Container, FormGroup, Label, Button, Row, Col, Form, Card, CardBody, CardTitle, Nav, NavItem, NavLink, TabContent, TabPane, Media, ModalBody, ModalHeader, UncontrolledTooltip } from 'reactstrap';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom/cjs/react-router-dom.min';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool, getClassroomsForSchoolId, getTeachersForSchool, getNoticeBoardFilesForSchoolId, deleteNoticeBoardFile, } from '../../store/actions';
import { matchPath } from "react-router";
import Parse from 'parse';
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";
import SimpleBar from "simplebar-react";
import Iframe from "react-iframe";
import Moment from 'moment';
import DocViewer, { DocViewerRenderers, BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";
import classnames from "classnames";

const Index = props => {
    const match = matchPath(props.history.location.pathname, {
        path: "/noticeboard/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;

    const [classroomList, setClassroomList] = useState([]);
    const [activeTab, setactiveTab] = useState("school");
    const [selectedTabFiles, setSelectedTabFiles] = useState([]);
    const [fileType, setFileType] = useState(undefined);
    const [showModel, setShowModel] = useState(false);
    const [clickedFile, setClickFile] = useState(undefined);
    const [deleteFile, setDeleteFile] = useState(undefined);

    const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
    const _pdfExtensions = [".pdf", "pdf"];
    const _audioExtensions = [".mp3", "mp3", ".wma", "wma", ".acc", "acc", ".wav", "wav", ".flac", "flac"];


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool]);

    useEffect(() => {
        if (schoolId) {
            if (props.classrooms != null || props.classrooms.length === 0) {
                props.getClassroomsForSchoolId(schoolId);
            }
            props.getNoticeBoardFilesForSchoolId(schoolId);
        }
    }, [schoolId]);

    useEffect(() => {
        props.getTeachersForSchool(schoolId);
    }, [schoolId]);

    useEffect(() => {
        let list = [];
        for (const file of props.noticeboardfiles) {
            if (activeTab === "school") {
                if (file.attributes.recipientId === schoolId) {
                    list.push(file);
                }
            } else if (activeTab === "teachers") {
                if (file.attributes.recipientId === "T") {
                    list.push(file);
                }
            } else {
                if (file.attributes.recipientId === activeTab) {
                    list.push(file);
                }
            }
        }
        setSelectedTabFiles(list);
        console.log(props.noticeboardfiles)
    }, [activeTab, props.noticeboardfiles]);

    useEffect(() => {
        if (props.classrooms && props.classrooms.length > 0) {
            let tempSelectList = [];
            for (const classRoom of props.classrooms) {
                tempSelectList.push(classRoom);
            }
            setClassroomList(tempSelectList);
        }
    }, [props.classrooms]);

    const getClassName = (classId) => {
        for (const c of props.classrooms) {
            if (c.id == classId) {
                return c.attributes.Name
            }
        }
        return "-";
    };

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ? "Added By: " + temp[0].attributes.Name : "")
            }
        }
    }



    function getFileTypeImage(fileExtention) {
        if (fileExtention === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtention === "ppt" || fileExtention === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtention === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtention === "doc" || fileExtention === "docx") {
            return "bx bxs-file-doc";
        } else if (fileType === "Video") {
            return "bx bx-play-circle";
        } else if (_audioExtensions.includes(fileExtention)) {
            return "bx bxs-music";
        }
        return "bx bxs-file-blank";
    }

    function getFileTypeFromUrl(url) {
        var res = url.split(".");
        var res = res[res.length - 1];
        if (_imgExtensions.includes(res)) {
            return { type: "image", ext: res };
        } else if (_audioExtensions.includes(res)) {
            return { type: "audio", ext: res };
        } else if (_pdfExtensions.includes(res)) {
            return { type: "pdf", ext: res };
        } else {
            return { type: "file", ext: res };
        }
    }



    function getURL() {
        if (clickedFile) {
            if (clickedFile.attributes.fileType === "url") {
                return clickedFile.attributes.url
            } else {
                return clickedFile.attributes.file._url;
            }
        }
    }

    const getFR = (url) => {
        const docs = [
            { uri: url },
        ];
        return docs;
    }

    function getFileName() {

        if (clickedFile) {
            if (clickedFile.attributes.fileType === "url") {
                return clickedFile.attributes.name
            } else {
                let res = clickedFile.attributes.file._name.split("_")
                return res[res.length - 1];
            }
        }
    }

    function getTimeStringForDate1(date, format = "DD-MMM-YYYY hh:mm a") {
        return Moment(date).format(format);
    }

    return (
        <React.Fragment>
            {
                deleteFile && (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            props.deleteNoticeBoardFile(deleteFile)
                            setDeleteFile(undefined);
                        }}
                        onCancel={() => {
                            setDeleteFile(undefined);
                        }}
                    >
                        {props.t("You won't be able to revert this!")}
                    </SweetAlert>
                )
            }
            <div className="page-content">
                <Row>
                    <Col>
                        <Row className="justify-content-between">
                            <Col lg={3} sm={6}>
                                <div className='m-2'>
                                    <h4 className="font-size-18">Notice Board</h4>
                                </div>
                            </Col>
                            <Col lg={9} sm={6}>
                                <Form className="d-flex justify-content-end mb-3">
                                    <div className="m-1">
                                        <Link
                                            className="btn btn-primary waves-effect waves-light"
                                            to={"/add-notice/" + schoolId + "/" + activeTab}
                                            role="button"
                                        >
                                            Add Notice
                                        </Link>
                                    </div>
                                </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row className="checkout-tabs">
                    <Col lg="2" style={{ overflowY: 'auto' }}>
                        <Nav className="flex-column" pills>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "school" }, "mb-2")}
                                    onClick={() => {
                                        setactiveTab("school");
                                    }}
                                >
                                    <p className="font-weight-bold mb-0">{props.t("School")}</p>
                                </NavLink>
                            </NavItem>
                            {classroomList.map((classroomObject, key) => (
                                <NavItem key={key}>
                                    <NavLink
                                        className={classnames({ active: activeTab === classroomObject.id }, "mb-2")}
                                        onClick={() => {
                                            setactiveTab(classroomObject.id);
                                        }}
                                    >
                                        <p className="font-weight-bold mb-0">{classroomObject.attributes.Name}</p>
                                    </NavLink>
                                </NavItem>
                            ))}
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "teachers" }, "mb-2")}
                                    onClick={() => {
                                        setactiveTab("teachers");
                                    }}
                                >
                                    <p className="font-weight-bold mb-0">{props.t("Teacher")}</p>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col lg="10">
                        <Card>
                            <CardBody>
                                {activeTab === 'school' && <CardTitle><Label>School</Label></CardTitle>}
                                {activeTab === 'teachers' && <CardTitle><Label>Teachers</Label></CardTitle>}
                                {activeTab !== 'school' && activeTab !== 'teachers' && <CardTitle><Label>{getClassName(activeTab)}</Label></CardTitle>}
                                <SimpleBar style={{ maxHeight: "600px" }}>
                                    {selectedTabFiles.length === 0 ? (
                                       
                                        <div className="text-center">
                                            <h2><b>No documents found</b></h2>
                                        </div>
                                       
                                    ) : (
                                        selectedTabFiles.map((file, key) => (
                                            <Card className="shadow-sm bg-light" key={file.id}>
                                                <CardBody>
                                                    <Row>
                                                        <Col>
                                                            <div className="border" style={{ cursor: "pointer" }}>
                                                                <Row>
                                                                    <Col>
                                                                        <Media className="overflow-hidden" body>
                                                                            <Col style={{ display: "flex", alignItems: "center" }}>
                                                                                <div className="avatar-md mr-2">
                                                                                    <span className="avatar-title rounded-circle bg-white text-primary font-size-16">
                                                                                        <div style={{ fontSize: "48px", cursor: "pointer" }}>
                                                                                            <i className={getFileTypeImage(getFileTypeFromUrl(file.attributes.file._url).ext)}></i>
                                                                                        </div>
                                                                                    </span>
                                                                                </div>
                                                                                <div style={{ cursor: "pointer", marginLeft: "10px" }}>
                                                                                    <h5 className="font-size-15 m-0 text-dark"><b>{file.attributes.name}</b></h5>
                                                                                    <p className="font-size-14 m-0 text-dark">{file.attributes.fileDescription}</p>

                                                                                    <p className="font-size-14 m-0 text-dark"> Added On: {getTimeStringForDate1(file.attributes.createdAt, "DD/MM/yyyy")} </p>
                                                                                    <p className="font-size-14 m-0 text-dark">{getTeacherName(file.attributes.createdBy)}</p>
                                                                                </div>
                                                                            </Col>
                                                                        </Media>
                                                                    </Col>
                                                                </Row>
                                                            </div>
                                                        </Col>
                                                        <Col>
                                                            <Label className="text-primary float-end" style={{ cursor: "pointer" }}onClick={() => {
                                                                    setClickFile(file);
                                                                    setShowModel(true);
                                                                }}><u>{props.t('Preview')}</u>
                                                            </Label>
                                                            <li className="list-inline-item px-2 float-end"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setDeleteFile(file);
                                                                }}
                                                                id={"sub-delete" + key}>
                                                                <i className="bx bx-trash text-danger"></i>
                                                                <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                    {props.t('Delete')}
                                                                </UncontrolledTooltip>
                                                            </li>
                                                        </Col>
                                                    </Row>
                                                </CardBody>
                                            </Card>
                                        ))
                                    )}
                                </SimpleBar>
                            </CardBody>
                        </Card>
                    </Col>

                </Row>
            </div>

            <Modal size="xl" isOpen={showModel} toggle={() => { setShowModel(!showModel) }} scrollable={true}>
                <div className="modal-header">
                    <h5 className="modal-title mt-0" id="myLargeModalLabel">
                        {clickedFile && clickedFile.attributes.name}
                    </h5>
                    <Col className='d-flex flex-row-reverse' style={{ marginRight: "1cm" }}>
                        {
                            clickedFile && clickedFile.attributes.fileType !== "url" && (clickedFile.attributes.downloadable === undefined ||
                                clickedFile.attributes.downloadable === null ||
                                clickedFile.attributes.downloadable === true) && <a
                                    href={getURL()}
                                    download={getFileName()}
                                    target="_blank"
                                    type="button"
                                    className="btn btn-sm btn-primary waves-effect waves-light">
                                <i className="bx bx-download font-size-14"></i> {props.t('Download ')}
                            </a>
                        }
                    </Col>
                    <button
                        onClick={() => {
                            setShowModel(false)
                            setClickFile(undefined);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                        style={{ marginLeft: "1cm" }}
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody>
                    {
                        clickedFile && clickedFile.attributes.fileType === "url" ?
                            <Iframe
                                url={getURL()}
                                height="600"
                                width="100%"
                                display="initial"
                                allowFullScreen={true}
                                position="relative"
                                frameBorder={0}
                            >
                            </Iframe> :
                            <div>
                                {getURL() && getURL().endsWith(".pdf") ? <Iframe
                                    url={getURL() + "#toolbar=0"}
                                    height="600"
                                    width="100%"
                                    display="initial"
                                    allowFullScreen={true}
                                    position="relative"
                                    frameBorder={0}
                                >
                                </Iframe> :
                                    <DocViewer
                                        pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer,]}
                                        style={{ height: "500px" }}
                                        documents={getFR(getURL())}
                                        config={{
                                            header: {
                                                disableHeader: true,
                                                disableFileName: false,
                                                retainURLParams: false
                                            }
                                        }}
                                    />
                                }
                            </div>
                    }
                    <Card style={{ backgroundColor: "#fff", marginTop: "-7mm", width: "100%", height: " 7mm" }} />
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { kids } = state.Kid;
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { noticeboardfiles } = state.Files;
    const { userTeacher } = state.Login;
    const { teachers } = state.Teacher;

    return { kids, selectedSchool, classrooms, noticeboardfiles, userTeacher, teachers };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool, getClassroomsForSchoolId, getNoticeBoardFilesForSchoolId, deleteNoticeBoardFile, getTeachersForSchool,
})(withRouter(withNamespaces()(Index)));
