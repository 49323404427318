import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Table,
  UncontrolledTooltip,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label, Input
} from "reactstrap";
import {
  getAdmissionKidsForSchool,
  getParentsForFamilyIds,
  getClassroomsForSchoolId,
  removeKidFromAdmissions,
  sendAdmissionLinkToParent,
  editEnquiryNote,
} from "../../store/actions";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from 'moment';
import * as WhiteLable from "../../components/Common/WhiteLable";
import Licence from '../../components/Common/Licence'
const AdmissionList = (props) => {
  useEffect(() => {
    props.getAdmissionKidsForSchool(props.schoolId);
    props.getClassroomsForSchoolId(props.schoolId);
  }, [props.schoolId]);

  const [familyIds, setFamilyIds] = useState([]);
  const [classModal, toggleClassModal] = useState(false);
  const [classroomId, onSelectClass] = useState(undefined);
  const [selectedKid, setSelectedKid] = useState(undefined);
  const [classSelcetWarning, setClassSelcetWarning] = useState(false);
  const [loding, setLoading] = useState(false);
  const [showAdmissionLink, setshowAdmissionLink] = useState(false);

  const [success_dlg, setsuccess_dlg] = useState(false);
  const [admissionDeleteObject, setAdmissionDeleteObject] = useState(undefined);

  const [displayList, setDisplayList] = useState([]);
  const [submittedDateOrder, setsubmittedDateOrder] = useState("ascending");
  const [showLimitModal, setShowLimitModal] = useState(false);

  useEffect(() => {
    if (props.admissionKids && props.admissionKids.length > 0) {
      setDisplayList(props.admissionKids);
      var ids = [];
      for (const i in props.admissionKids) {
        if (!familyIds.includes(props.admissionKids[i].attributes.FamilyID)) {
          ids.push(props.admissionKids[i].attributes.FamilyID);
        }
      }

      if (ids.length > 0) {
        var newIds = familyIds.concat(ids);
        setFamilyIds(newIds);
        props.getParentsForFamilyIds(ids);
      }
    }
  }, [props.admissionKids]);

  function getParent(familyId) {
    if (props.parents && props.parents.length > 0) {
      var parents = props.parents.filter(
        (parent) => parent.attributes.FamilyID === familyId
      );
      if (parents && parents.length > 0) {
        var motherObj = null;
        var fatherObj = null;
        for (const i in parents) {
          if (parents[i].attributes.Relation === "Mother") {
            motherObj = parents[i];
          } else {
            fatherObj = parents[i];
          }
        }

        return (
          <div>
            {motherObj && motherObj.attributes.Name && (
              <p className="text-muted mb-0">
                <i className="bx bx-female" />
                {motherObj.attributes.Name.length > 20
                  ? motherObj.attributes.Name.slice(0, 19)
                  : motherObj.attributes.Name}{" "}
                : <br /> <i className="bx bx-mobile" />
                {motherObj.attributes.PhoneNumber}
              </p>
            )}
            {fatherObj && fatherObj.attributes.Name && (
              <p className="text-muted mb-0">
                <i className="bx bx-male" />
                {fatherObj.attributes.Name > 20
                  ? fatherObj.attributes.Name.slice(0, 19)
                  : fatherObj.attributes.Name}{" "}
                : <br /> <i className="bx bx-mobile" />
                {fatherObj.attributes.PhoneNumber}
              </p>
            )}
          </div>
        );
      }
    }
  }

  useEffect(() => {
    if(props.searchString && props.searchString.length>0){
      const filteredList = props.admissionKids.filter((kid) =>
        kid.attributes && kid.attributes.Name &&  kid.attributes.Name.toLowerCase().includes(props.searchString.toLowerCase())
      );
      setDisplayList(filteredList);

    }else{
      setDisplayList(props.admissionKids);
    }
    
  }, [props.searchString]);
  

  function getParentWhatsppButtons(familyId) {
    if (props.parents && props.parents.length > 0) {
      var parents = props.parents.filter(
        (parent) => parent.attributes.FamilyID === familyId
      );
      if (parents && parents.length > 0) {
        var motherObj = null;
        var fatherObj = null;
        for (const i in parents) {
          if (parents[i].attributes.Relation === "Mother") {
            motherObj = parents[i];
          } else {
            fatherObj = parents[i];
          }
        }

        return (
          <div>
            {motherObj && motherObj.attributes.PhoneNumber && <button
              type="button"
              className="btn btn-success btn-sm mr-2"
              onClick={() => {
                let phoneNumber = motherObj.attributes.PhoneNumber;
                let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name
                let parentName = motherObj.attributes.Name;
                let link = (selectedKid && !selectedKid.attributes.admissionFormSubmittedOn && !selectedKid.attributes.admissionFormResharedOn) ? getAdmissionLink(true) : getAdmissionLink();
                const message = `Dear ${parentName},\n\nWe extend a very warm welcome to you into our ${schoolName} Family.\n\nThank you for sharing your most precious treasure with us. Please fill the admission form:\n${link}\n\nWe look forward to having your little one at ${schoolName}.\n\nPowered by lilTriangle`;
                const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                window.open(whatsappUrl);
                // web.whatsapp.com
              }}
            ><a className="bx bxl-whatsapp text-white"></a>Mother</button>}
            {fatherObj && fatherObj.attributes.PhoneNumber && <button
              type="button"
              className="btn btn-success btn-sm"
              onClick={() => {
                let phoneNumber = fatherObj.attributes.PhoneNumber;
                let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name
                let parentName = fatherObj.attributes.Name;
                let link = (selectedKid && !selectedKid.attributes.admissionFormSubmittedOn && !selectedKid.attributes.admissionFormResharedOn) ? getAdmissionLink(true) : getAdmissionLink();
                const message = `Dear ${parentName},\n\nWe extend a very warm welcome to you into our ${schoolName} Family.\n\nThank you for sharing your most precious treasure with us. Please fill the admission form:\n${link}\n\nWe look forward to having your little one at ${schoolName}.\n\nPowered by lilTriangle`;
                const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                window.open(whatsappUrl);
                // web.whatsapp.com
              }}
            ><a className="bx bxl-whatsapp text-white"></a>Father</button>}
          </div>
        );
      }
    }
  }

  const handelMovetoClass = () => {
    var kid = selectedKid;
    kid.set("ClassRoomID", classroomId);
    kid.set("SchoolID", props.schoolId);
    kid.set("schoolAdmitted", "");
    kid.save().then(
      (result) => {
        result.pin();
        setClassSelcetWarning(false);
        setSelectedKid(undefined);
        onSelectClass(undefined);
        toggleClassModal(!classModal);
        props.removeKidFromAdmissions(selectedKid);
      },
      (error) => { }
    );
  };

  const removeFromAdmission = (kid) => {
    props.removeKidFromAdmissions(kid);
    kid.set("Deleted", true);
    kid.set("deletedOn", new Date());
    kid.set("deletedBy", Parse.User.current().attributes.teacherId);
    kid.save().then(
      (resKid) => {
        if (resKid) {
          resKid.pin();
          try {
            const Enquiry = Parse.Object.extend("Enquiry");
            var enquiry = new Enquiry();
            enquiry.id = kid.attributes.enquiryId;
            enquiry.set("status", 0);
            enquiry.set("deleted", false);
            enquiry.save().then((resEnq) => {
              resEnq.pin();
              const EnquiryNote = Parse.Object.extend("EnquiryNotes");
              const noteObject = new EnquiryNote();

              let note = "Admitted to Enquired";
              if (Parse.User.current().attributes.teacherId) {
                noteObject.set("createdBy", Parse.User.current().attributes.teacherId);
              } else {
                noteObject.set("createdBy", Parse.User.current().id);
              }
              noteObject.set("enquiryId", kid.attributes.enquiryId);
              noteObject.set("schoolId", props.schoolId);
              noteObject.set("noteMessage", note);
              noteObject.set("systemLog", true);

              props.editEnquiryNote(noteObject);
            }, (error) => {
              console.log(error);
            })

          } catch (error) {
            console.log(error);
          }
        }
      },
      (error) => {
        console.log(error);
      }
    )
  }
  const getAdmissionLink = (withEnquiryId) => {
    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[1] > 0) {

      if (withEnquiryId) {
        return WhiteLable.getHost() + "/franchise-admission-form/" + props.franchise.id + "/" + selectedKid.attributes.schoolAdmitted + "/" + selectedKid.attributes.enquiryId;
      } else {
        return WhiteLable.getHost() + "/franchise-admission-form/" + props.franchise.id + "/" + selectedKid.attributes.schoolAdmitted + "/" + selectedKid.id + "/" + 2;
      }

    } else {
      if (withEnquiryId) {
        return WhiteLable.getHost() + "/admission-form/" + selectedKid.attributes.schoolAdmitted + "/" + selectedKid.attributes.enquiryId;

      } else {
        return WhiteLable.getHost() + "/admission-form/" + selectedKid.attributes.schoolAdmitted + "/" + selectedKid.id + "/" + 2;
      }

    }
  }
  const getparentEmailsAndPhoneNubers = (familyId) => {
    if (props.parents && props.parents.length > 0) {
      var parents = props.parents.filter(
        (parent) => parent.attributes.FamilyID === familyId
      );
      if (parents && parents.length > 0) {
        let emails = [];
        let phoneNumbers = [];
        for (const i in parents) {
          if (parents[i].attributes.EMail) {
            emails.push(parents[i].attributes.EMail);
          }
          if (parents[i].attributes.PhoneNumber) {
            phoneNumbers.push(parents[i].attributes.PhoneNumber);
          }
        }

        return { emails: emails, phoneNumbers: phoneNumbers }
      }
    }
    return { emails: [], phoneNumbers: [] }
  }
  function copyAdmissionLink(str, event) {
    document.oncopy = function (event) {
      event.clipboardData.setData("Text", str);
      event.preventDefault();
    };
    document.execCommand("Copy");
    document.oncopy = undefined;
    toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
  }

  function convertDateToString(date) {
    return Moment(date).format('DD/MM/YYYY hh:mm A')
  }

  const handelSortBy = (type, order) => {
    if (type == "createdAt") {

    } else if (type == "submittedDate") {

      var temp = props.admissionKids.filter(
        (k) => k.attributes.admissionFormSubmittedOn
      );
      var others = props.admissionKids.filter(
        (k) => !k.attributes.admissionFormSubmittedOn
      );
      if (temp.length > 0) {
        let tempList = getSortList(temp, { order: order, sortingFor: "admissionFormSubmittedOn" });
        console.log(tempList);
        let l = tempList.concat(others ?? [])
        setDisplayList(l);
      } else {
        setDisplayList(props.admissionKids);
      }
    } else {
      setDisplayList(props.admissionKids);
    }
  }

  const getSortList = (kidlist, sortBy) => {
    let sortingFor = sortBy.sortingFor;
    let sortFor = sortBy.order === "ascending" ? false : true;
    let sortedList = kidlist.sort(function (a, b) {

      let aVal = a.attributes[sortingFor];
      let bVal = b.attributes[sortingFor];
      if (sortFor) {
        if (aVal < bVal) {
          return -1;
        }
        if (aVal > bVal) {
          return 1;
        }
        return 0;
      } else {
        if (aVal < bVal) {
          return 1;
        }
        if (aVal > bVal) {
          return -1;
        }
        return 0;
      }
    });

    return sortedList;
  }


  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <div className="table-responsive">
            <Table className="table-centered table-nowrap table-hover">
              <thead className="thead-light">
                <tr>
                  <th scope="col" style={{ width: "70px" }}>
                    #
                  </th>
                  <th scope="col">{props.t("Name")}</th>
                  <th scope="col">{props.t("Family")}</th>
                  <th scope="col"
                    onClick={() => {
                      submittedDateOrder === "ascending"
                        ? setsubmittedDateOrder("descending")
                        : setsubmittedDateOrder("ascending");
                      handelSortBy("submittedDate", submittedDateOrder);
                    }}
                  >
                    <i
                      className={submittedDateOrder ? `mdi mdi-18px mdi-sort-numeric-${submittedDateOrder} mr-1` : 'mdi mdi-18px mdi-sort mr-1'}
                    ></i>
                    {props.t("Admission Form")}</th>
                  <th scope="col">{props.t("Enquired For")}</th>
                  <th scope="col">{props.t("Class")}</th>
                  <th scope="col">{props.t("Action")}</th>
                </tr>
              </thead>
              <tbody>
                {displayList.map((kid, i) => (
                  <tr key={"_user_" + i}>
                    <td>
                      {!kid.attributes.Photo ? (
                        <div className="avatar-xs">
                          <span className="avatar-title rounded-circle">
                            {kid.attributes.Name.toUpperCase().charAt(0)}
                          </span>
                        </div>
                      ) : (
                        <div>
                          <img
                            className="rounded-circle avatar-xs"
                            src={kid.attributes.Photo._url}
                            alt=""
                          />
                        </div>
                      )}
                    </td>
                    <td>
                      <h5 className="font-size-14 mb-1">
                        <Link to={
                          "/studentProfile/" +
                          kid.attributes.schoolAdmitted +
                          "/" +
                          kid.id
                        } className="text-dark">
                          {kid.attributes.Name}
                        </Link>
                      </h5>
                      <p>{kid.attributes.admissionNumber}</p>
                    </td>
                    <td>{getParent(kid.attributes.FamilyID)}</td>
                    <td >
                      {kid.attributes.admissionFormSubmittedOn ?
                        <div
                        //  onClick={() => {
                        //     setshowAdmissionLink(true);
                        //     setSelectedKid(kid);
                        //   }}
                        >
                          <p className="text-success mb-0" >Form submitted on:</p>
                          <p className="text-muted font-size-12 mb-0">{convertDateToString(kid.attributes.admissionFormSubmittedOn)}</p>

                        </div> :
                        <ul className="list-inline font-size-20 contact-links mb-0 justify-content-center">
                          <li className="list-inline-item px-2">
                            <Label
                              id={"link" + kid.id}
                              onClick={() => {
                                setshowAdmissionLink(true);
                                setSelectedKid(kid);
                              }}
                            >
                              <i className="bx bx-copy"></i>
                              <UncontrolledTooltip
                                placement="top"
                                target={"link" + kid.id}
                              >
                                {props.t("Admission Form")}
                              </UncontrolledTooltip>
                            </Label>
                          </li>
                        </ul>}
                    </td>
                    <td>
                      {/* {kid.attributes.enquiryFor && kid.attributes.enquiryFor.length > 0 && <p>{getClassrooms(kid.attributes.enquiryFor)}</p>} */}
                      {
                        kid.attributes.enquiryFor && kid.attributes.enquiryFor.length > 0 && kid.attributes.enquiryFor.map((skill, key) =>
                          <Label className="badge badge-soft-primary font-size-11 m-1" key={"_skill_" + key}>{skill.length > 15 ? skill.slice(0, 14) : skill}</Label>
                        )
                      }
                    </td>
                    <td>
                      <div
                        onClick={() => {
                          setSelectedKid(kid);
                          toggleClassModal(true);
                          setLoading(false);
                        }}
                        style={{ cursor: "pointer" }}
                        className={`badge font-size-11 m-1 badge-soft-primary`}
                      >
                        {props.t("Move to")}
                        <i className="mdi mdi-chevron-down"></i>
                      </div>
                    </td>

                    <td>
                      <ul className="list-inline font-size-20 contact-links mb-0">
                        <li className="list-inline-item px-2" style={{ cursor: "pointer" }}>
                          <Label onClick={() => { setAdmissionDeleteObject(kid) }} id={"delete" + kid.id}>
                            <i className="bx bx-trash text-danger"></i>
                            <UncontrolledTooltip placement="top" target={"delete" + kid.id}>
                              Delete
                            </UncontrolledTooltip>
                          </Label>
                        </li>
                        <li className="list-inline-item px-2">
                          <Link
                            to={
                              "/studentProfile/" +
                              kid.attributes.schoolAdmitted +
                              "/" +
                              kid.id
                            }
                            id={"profile" + kid.id}
                          >
                            <i className="bx bx-user-circle"></i>
                            <UncontrolledTooltip
                              placement="top"
                              target={"profile" + kid.id}
                            >
                              Profile
                            </UncontrolledTooltip>
                          </Link>
                        </li>
                      </ul>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>


          {
            props.admissionKidsLoasState === 1 && <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Label to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
                </div>
              </Col>
            </Row>
          }
          {
            props.admissionKidsLoasState === 2 && props.admissionKids && props.admissionKids.length === 0 && <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <h3 className="text-center text-info">{props.t('No Student found in admissions .')}</h3>
                </div>
              </Col>
            </Row>
          }

        </CardBody>
      </Card>

      <Modal isOpen={classModal} scrollable={true}>
        <ModalHeader isOpen={classModal}>
          <div className="modal-title mt-0">Assign Classroom</div>
        </ModalHeader>
        <ModalBody className="modal-body">
          <p>
            {props.t(
              "Please select the classroom to which student has been taken admission, On saving student will moved to selected classroom"
            )}
          </p>
          {props.classrooms &&
            props.classrooms.map((item, i) => (
              <div
                className="custom-control custom-checkbox d-flex"
                key={item.id}
              >
                <Input
                  type="checkbox"
                  className="custom-control-input m-1"
                  id={item.id}
                  onChange={() => {
                    onSelectClass(item.id);
                  }}
                  checked={item.id === classroomId}
                />
                <label
                  className="custom-control-label "
                  onClick={() => {
                    onSelectClass(item.id);
                  }}
                >
                  {item.attributes.Name}
                </label>
              </div>
            ))}
          {classSelcetWarning && (
            <p className="text-danger ml-2">
              {props.t("Please selecte the classroom")}
            </p>
          )}
        </ModalBody>

        <ModalFooter>
          {!loding ? (
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setClassSelcetWarning(false);
                  setSelectedKid(undefined);
                  onSelectClass(undefined);
                  toggleClassModal(!classModal);
                }}
              >
                {props.t("Close")}
              </button>
              <button
                type="button"
                className="btn btn-primary m-2"
                onClick={() => {
                  if (classroomId) {
                    setClassSelcetWarning(false);
                    setLoading(true);
                    setShowLimitModal(true);
                  } else {
                    setClassSelcetWarning(true);
                  }
                }}
              >
                {props.t("Save")}
              </button>
            </div>
          ) : (
            <button type="button" className="btn btn-light waves-effect">
              <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
              {props.t(" Moving to Classroom")}
            </button>
          )}
        </ModalFooter>
      </Modal>

      {showAdmissionLink && (
        <Modal
          isOpen={showAdmissionLink}
          toggle={() => {
            setshowAdmissionLink(false);
            setSelectedKid(undefined);
          }}
        >
          <ModalHeader
            toggle={() => {
              setshowAdmissionLink(false);
              setSelectedKid(undefined);
            }}
          >
            {props.t("Admission form link for ") + selectedKid.attributes.Name}
            {/* <button 
                        type="button"
                        onClick={() => {
                            setshowAdmissionLink(false);
                            setSelectedKid(undefined);
                        }}
                        className="close float-right"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button> */}
          </ModalHeader>
          <ModalBody>
            <Card className="border-1">
              <Row>
                <Col lg={10}>
                  <p
                    disabled
                    type="text"
                    className=" m-2 text-primary"
                    id="linkarea"
                  >
                    {(selectedKid && !selectedKid.attributes.admissionFormSubmittedOn && !selectedKid.attributes.admissionFormResharedOn) ? getAdmissionLink(true) : getAdmissionLink()}
                  </p>
                </Col>
                <Col
                  lg={2}
                  id="admissionlink"
                  onClick={(e) => {
                    copyAdmissionLink((selectedKid && !selectedKid.attributes.admissionFormSubmittedOn && !selectedKid.attributes.admissionFormResharedOn) ? getAdmissionLink(true) : getAdmissionLink());
                  }}
                >
                  <li className="list-inline-item px-2">
                    <h1 style={{ cursor: "pointer" }} className="text-primary">
                      <i className="bx bxs-copy"></i>
                    </h1>
                    <p style={{ cursor: "pointer" }}>{props.t("copy")}</p>
                    <UncontrolledTooltip placement="top" target="admissionlink">
                      {props.t("copy to clipboard")}
                    </UncontrolledTooltip>
                  </li>
                </Col>
              </Row>
            </Card>
          </ModalBody>
          <ModalFooter>
            {getParentWhatsppButtons(selectedKid.attributes.FamilyID)}
            <button
              type="button"
              className="btn btn-info btn-sm"
              //here
              onClick={() => {

                let details = getparentEmailsAndPhoneNubers(selectedKid.attributes.FamilyID);
                let schoolName = (props.selectedSchool && props.selectedSchool.attributes.Name) ? props.selectedSchool.attributes.Name : "School"
                props.sendAdmissionLinkToParent(
                  selectedKid.attributes.enquiryId, details.emails, details.phoneNumbers, (selectedKid && !selectedKid.attributes.admissionFormSubmittedOn && !selectedKid.attributes.admissionFormResharedOn) ? getAdmissionLink(true) : getAdmissionLink(), schoolName, props.schoolId
                );
                setshowAdmissionLink(false);
                setSelectedKid(undefined);
              }}
            >
              {props.t("Send Link To Parent")}
            </button>
            {/* <button
              type="button"
              className="btn btn-secondary"
              onClick={() => {
                setshowAdmissionLink(false);
                setSelectedKid(undefined);
              }}
            >
              {props.t("Close")}
            </button> */}
          </ModalFooter>
        </Modal>
      )}

      {admissionDeleteObject && (
        <SweetAlert
          title="Are you sure?"
          warning
          showCancel
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger"
          onConfirm={() => {
            removeFromAdmission(admissionDeleteObject);
            setAdmissionDeleteObject(undefined);
            setsuccess_dlg(true);
          }}
          onCancel={() => {

            setAdmissionDeleteObject(undefined);
          }}
        >
          {
            props.t("This Admission (" + admissionDeleteObject.attributes.Name + ") will be cancelled and it will move to enquiry  ")}
        </SweetAlert>
      )}

      {success_dlg ? (
        <SweetAlert
          success

          onConfirm={() => {
            setsuccess_dlg(false);
            setAdmissionDeleteObject(undefined);
          }}
        >

          {props.t('Selected Admission has been cancelled and moved to Enquiry')}
        </SweetAlert>
      ) : null}
      <Licence  
        showopup ={showLimitModal}
        handelAdd={()=>{
          setShowLimitModal(false);
          setLoading(false);
          handelMovetoClass();
      }}
        handelClose={()=>{
          setShowLimitModal(false);
          setLoading(false);
        }}
      >
      </Licence>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { admissionKids, admissionKidsLoasState } = state.Kid;
  const { parents } = state.Parent;
  const { classrooms } = state.Classroom;
  const { franchise } = state.Franchise;
  const { selectedSchool } = state.School;
  return { admissionKids, admissionKidsLoasState, parents, classrooms, franchise, selectedSchool };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getAdmissionKidsForSchool,
      getParentsForFamilyIds,
      getClassroomsForSchoolId,
      removeKidFromAdmissions,
      sendAdmissionLinkToParent,
      editEnquiryNote
    })(AdmissionList)
  )
);
