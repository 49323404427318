import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventorySaleOrderItemsHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventorySaleOrderItems_helper";
import * as actionType from "./actionTypes";
import * as InventorySaleOrderItemsAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getInventorySaleOrderItemsForOwnerId({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_SALE_ORDER_ITEMS_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventorySaleOrderItemsHelper.getInventorySaleOredrItemsForOwnerId, ownerId, ownerType, null, true);
        yield put(InventorySaleOrderItemsAction.updateInventorySaleOrderItems(localResponse));


        const serverResponse = yield call(InventorySaleOrderItemsHelper.getInventorySaleOredrItemsForOwnerId, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventorySaleOrderItemsAction.updateInventorySaleOrderItems(serverResponse));


            var nonDeleted = serverResponse.filter(
                (inventorySaleOrderItem) =>
                inventorySaleOrderItem.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventorySaleOrderItem) =>
                inventorySaleOrderItem.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* deleteInventorySaleOrderItem({ payload: { inventorySaleOrderItem } }) {
    try {
        yield put(InventorySaleOrderItemsAction.updateInventorySaleOrderItems(inventorySaleOrderItem));
        const response = yield call(InventorySaleOrderItemsHelper.deleteInventorySaleOredrItemFromParse, inventorySaleOrderItem);
        if (response === "error") {
            inventorySaleOrderItem.set("isDeleted", undefined);
            yield put(InventorySaleOrderItemsAction.updateInventorySaleOrderItems(inventorySaleOrderItem));
        } else
            if (response) {
                toastr.success("Sale order item successfully removed", { timeOut: 3000 });
                response.unPin();
            } else {

            }

    } catch (error) {

    }
}

function* addInventorySaleOrderItem({ payload: { inventorySaleOrderItem } }) {
    try {
    
      yield put(InventorySaleOrderItemsAction.updateInventorySaleOrderItems(inventorySaleOrderItem));
      const response = yield call(
        InventorySaleOrderItemsHelper.saveCustomObjects,inventorySaleOrderItem
      );
      if (response === "Error") {
  
        toastr.error("Please try again", { timeOut: 3000 });
        const local = yield call(
            InventorySaleOrderItemsHelper.saveCustomObjects,inventorySaleOrderItem.id
        );
  
        if (local) {
          yield put(InventorySaleOrderItemsAction.updateInventorySaleOrderItems(local));
        }
    
      }else 
      if (response) {
        yield put(InventorySaleOrderItemsAction.updateInventorySaleOrderItems(response));
        toastr.success("Sale order item successfully added", { timeOut: 3000 });
        response.pin();
      } else {

      }
    } catch (error) {
 
    }
  }

  function* setSelectedSaleOrderItems({ payload: { inventorySaleOrderItemId } }) {
    try {
      const response = yield call(InventorySaleOrderItemsHelper.getInventorySaleOredrItemFromParse, inventorySaleOrderItemId, true);
      if (response) {
        Parse.Object.pinAll(response);
        yield put(InventorySaleOrderItemsAction.updateSelectedSaleOrdersItems(response));
      } 
    } catch (error) {

    }
  }


  export function* watchGetInventorySaleOrderItemsForOwnerId() {
    yield takeEvery(actionType.GET_INVENTORY_SALE_ORDER_ITEMS, getInventorySaleOrderItemsForOwnerId);
}

export function* watchDeleteInventorySaleOrderItem() {
    yield takeEvery(actionType.DELETE_INVENTORY_SALE_ORDER_ITEM, deleteInventorySaleOrderItem);
}

export function* watchAddInventorySaleOrderItem(){
    yield takeEvery(actionType.ADD_INVENTORY_SALE_ORDER_ITEM, addInventorySaleOrderItem);
}

export function* watchSetSelectedSaleOrderItems() {
    yield takeEvery(actionType.SET_SELECTED_SALE_ORDER_ITEMS, setSelectedSaleOrderItems);
}


function* InventorySaleOrderItemsSaga() {
    yield all([
        fork(watchGetInventorySaleOrderItemsForOwnerId),
        fork(watchDeleteInventorySaleOrderItem),
        fork(watchAddInventorySaleOrderItem),
        fork(watchSetSelectedSaleOrderItems),
    ]);
}
export default InventorySaleOrderItemsSaga;