import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";


const initialState = {
  inventoryItems: [], inventoryItemAddLoading: false,
  selectedInventoryItem: null,
  inventoryItemsOfItemIds: [],
  inventoryItemMatrixs: [],
  inventoryMatrixs: [],
  inventorySubItems: [],
};

const InventoryItems = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_INVENTORY_ITEMS:

      var inventoryItems = [...state.inventoryItems];
      if (inventoryItems.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.inventoryItems], action.payload.inventoryItems, "isDeleted")

        state = { ...state, inventoryItems: updatedList };
      } else {
        var i = 0
        var inventoryItemsToSave = []
        for (i = 0; i < action.payload.inventoryItems.length; i++) {
          if (action.payload.inventoryItems[i].attributes.isDeleted !== true) {
            inventoryItemsToSave.push(action.payload.inventoryItems[i])
          }
        }
        state = { ...state, inventoryItems: inventoryItemsToSave };
      }
      break;
    case actions.UPDATE_SELECTED_INVENTORY_ITEM:
      state = { ...state, selectedInventoryItem: action.payload.inventoryItem };
      break;
    case actions.UPDATE_DELETE_INVENTORY_ITEM:
      var inventoryItems = state.inventoryItems.filter(
        (inventoryItem) => inventoryItem.id !== action.payload.inventoryItem.id
      );
      state = { ...state, inventoryItems: inventoryItems };
      break;
    case actions.UPDATE_INVENTORY_ITEMS_FOR_ITEM_IDS:
      state = { ...state, inventoryItemsOfItemIds: action.payload.inventoryItems };
      break;
    case actions.UPDATE_INVENTORY_ITEM_MATRIX_FOR_ITEM_ID:
      state = { ...state, inventoryItemMatrixs: action.payload.inventoryItemMatrixs };
      break;
    case actions.UPDATE_INVENTORY_ITEM_MATRIX_FOR_ITEM_IDS:
      state = { ...state, inventoryMatrixs: action.payload.inventoryMatrixs };
      break;
    // case actions.UPDATE_INVENTORY_SUB_ITEM_FOR_ITEM_IDS:
    //   state = { ...state, inventorySubItems: action.payload.inventorySubItems };
    //   break;

    // case actions.UPDATE_INVENTORY_SUB_ITEM_FOR_ITEM_IDS:

    //   var inventorySubItems = [...state.inventorySubItems];
    //   if (inventorySubItems.length > 0) {
    //     var updatedList = ReduxUtil.updateParseObjects([...state.inventorySubItems], action.payload.inventorySubItems, "isDeleted")

    //     state = { ...state, inventorySubItems: updatedList };
    //   } else {
    //     var i = 0
    //     var inventoryItemsToSave = []
    //     for (i = 0; i < action.payload.inventorySubItems.length; i++) {
    //       if (action.payload.inventorySubItems[i].attributes.isDeleted !== true) {
    //         inventoryItemsToSave.push(action.payload.inventorySubItems[i])
    //       }
    //     }
    //     state = { ...state, inventorySubItems: inventoryItemsToSave };
    //   }
    //   break;
    case actions.UPDATE_INVENTORY_SUB_ITEM_FOR_ITEM_IDS:
      var updatedList = ReduxUtil.updateParseObjects(state.inventorySubItems ? [...state.inventorySubItems] : [], action.payload.inventorySubItems, "isDeleted");
      state = { ...state, inventorySubItems: updatedList };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default InventoryItems;
