import React from 'react';
import { Col, Modal, ModalHeader, ModalBody, FormGroup, Label, Input, ModalFooter, Button } from 'reactstrap';

const SaveAsPdf = (props) => {
    
    const handleDontShowAgainChange = () => {
        const newValue = !props.dontShowAgain;
        props.setDontShowAgain(newValue);
        localStorage.setItem('dontShowAgain', newValue);
    };

    const renderInstructions = () => {
        switch (props.machineType) {
            case 'Windows':
                return (
                    <>
                        <h6>For Windows:</h6>
                        <ul>
                            <li>Open the document you want to print.</li>
                            <li>Go to <em>File</em> on the top left and <i className="fa fa-print m-1"></i> select <em>Print</em> to open the print dialog.</li>
                            <li>In the printer selection dropdown, choose "<strong>Microsoft Print to PDF</strong>".</li>
                            <li>Click <em>Print</em>.</li>
                            <li>A dialog will appear asking where to save the file. Choose the location and enter a file name.</li>
                            <li>Click <em>Save</em>.</li>
                        </ul>
                    </>
                );
            case 'Mac':
                return (
                    <>
                        <h6>For Mac (macOS):</h6>
                        <ul>
                            <li>Open the document you want to print.</li>
                            <li>Go to <em>File</em> on the top left <i className="fa fa-print m-1"></i> select <em>Print</em> to open the print dialog.</li>
                            <li>In the bottom-left corner of the print window, click the <strong>PDF</strong> button.</li>
                            <li>From the dropdown, choose "<strong>Save as PDF</strong>".</li>
                            <li>A dialog will appear asking where to save the file. Choose the location and enter a file name.</li>
                            <li>Click <em>Save</em>.</li>
                        </ul>
                    </>
                );
            default:
                return <p>Machine type not recognized.</p>;
        }
    };

    return (
        <Modal isOpen={props.showModal} toggle={props.toggleModal}>
            <ModalHeader toggle={props.toggleModal}>
                Save As PDF Instructions
            </ModalHeader>
            <ModalBody>
                <Col>
                    <h5>Save As PDF Instructions:</h5>
                    <p>Steps to Save as PDF While Printing:</p>

                    {renderInstructions()}

                    <FormGroup check>
                        <Label check>
                            <Input 
                                type="checkbox" 
                                checked={props.dontShowAgain} 
                                onChange={handleDontShowAgainChange} 
                            />{' '}
                            Don't show me again
                        </Label>
                    </FormGroup>
                </Col>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={props.onNextClick}>Next</Button>
            </ModalFooter>
        </Modal>
    );
};

export default SaveAsPdf;
