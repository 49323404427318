import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, UncontrolledAlert, CardBody, Media, Button, Table } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Parse from "parse";
// Redux
import { connect } from 'react-redux';
import { withRouter, useHistory } from 'react-router-dom'; 
import Breadcrumb from '../../components/Common/Breadcrumb';
import { editProfile, userForgetPassword, setSelecetdSchool, logoutUser} from '../../store/actions';
import * as Constants from "../../Constents";
import { Link } from "react-router-dom";
import { matchPath } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";

const UserProfile = (props) => {

    const [email, setemail] = useState("");
    const [name, setname] = useState("");
    const [idx, setidx] = useState(1);
    const [image, setimage] = useState(undefined);
    const [showLogoutAlert, setShowLogoutAlert] = useState(false);
    const history = useHistory();
    const [loding, setLoading] = useState(false);
    const [error, setError] = useState(undefined);

    const match = matchPath(props.history.location.pathname, {
        path: "/profile/:id",
        exact: true,
        strict: false,
    });
    const schoolId = match ? match.params.id : "";

    useEffect(() => {
        setemail(localStorage.getItem(Constants.USER_PROFILE_EMAIL));
        setname(localStorage.getItem(Constants.USER_PROFILE_NAME));
        setimage(localStorage.getItem(Constants.USER_PROFILE_PHOTO));

    }, [props.success]);

    function handleValidSubmit(event, values) {
        props.editProfile(values);
    }

    function resetPassword() {
        setShowLogoutAlert(true);
    }

    const handleConfirm = () => {
        props.logoutUser();
        history.push('/reset-password-verification/' + email);
      };


    return (
    <React.Fragment>
            {showLogoutAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes, Reset"
                    cancelBtnText="No, cancel"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    title="Are you sure you want to reset your password?"
                    onCancel={() => {
                        setShowLogoutAlert(false);
                    }}
                    onConfirm={() => {
                        handleConfirm(true);
                    }}
                >
                    When you select 'Reset,' your profile will automatically log out, and you'll be directed to the password reset screen.
                </SweetAlert>
            )}
            <div className="page-content">
                <Container fluid>

                    {/* <Row className="navbar-header p-0">
                        <div className="d-flex" >
                            {
                                <div className="navbar-header p-0">

                                    <div onClick={() => { window.history.back(); }}>
                                        <ul className="verti-timeline list-unstyled mb-3">
                                            <div >
                                                <i className="bx bx-chevron-left h1 text-primary"></i>
                                            </div>
                                        </ul>
                                    </div>

                                    <Breadcrumb title="USER PROFILE" breadcrumbItem="Profile" />

                                </div>

                            }

                        </div>

                    </Row> */}
                    <Row className='d-flex align-items-start'>
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <Breadcrumb title="USER PROFILE" breadcrumbItem="Profile" />
                            </div>
                        </Col>
                    </Row>


                    {/* <Row>
                        <Col lg="12">
                            {props.error && props.error ? <Alert color="danger">{props.error}</Alert> : null}
                            {props.success && props.success ? <Alert color="success">{props.success}</Alert> : null}

                            <Card>
                                <CardBody>
                                    <Media>
                                        <div className="mr-3">
                                            <img src={image} alt="" className="avatar-md rounded-circle img-thumbnail"/>
                                        </div>
                                        <Media body className="align-self-center">
                                            <div className="text-muted">
                                                <h5>{name}</h5>
                                                <p className="mb-1">{email}</p>
                                                
                                            </div>
                                        </Media>
                                    </Media>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <h4 className="card-title mb-4">Change UserName</h4> 
                     <Card>
                        <CardBody>
                            <AvForm className="form-horizontal" onValidSubmit={(e,v) => { handleValidSubmit(e,v) }}>
                                <div className="form-group">
                                        <AvField name="username" label="UserName" value={name} className="form-control" placeholder="Enter UserName" type="text" required />
                                        <AvField name="idx"  value={idx}  type="hidden"  />
                                </div>
                                    
                                <div className="text-center mt-4">
                                        
                                    <Button type="submit" color="danger">Edit UserName</Button>
                               
                                </div>
                            </AvForm>
                        
                        </CardBody>
                    </Card> */}





                    <Col lg="12">
                        <Card>
                            <CardBody>

                                <Row>
                                    <Col lg={3} md={4} xs={12} className="text-center  ">
                                        <div className="border p-2">
                                            {!image ? (
                                                <div className="avatar-lg mx-auto mb-2" style={{ width: '10rem', height: '10rem' }}>
                                                    <span
                                                        className={
                                                            "avatar-title rounded-circle bg-soft-primary text-primary"
                                                        }
                                                        style={{ fontSize: '60px' }}
                                                    >
                                                        {email.charAt(0).toUpperCase()}
                                                    </span>
                                                </div>
                                            ) : (
                                                <div>
                                                    <img
                                                        style={{ width: '10rem', height: '10rem' }}
                                                        className="rounded-circle avatar mb-2"
                                                        src={image}
                                                        alt=""
                                                    />
                                                </div>
                                            )}



                                        </div>


                                    </Col>
                                    <Col lg={9} md={8} xs={12}>
                                        <div className="">


                                            <div className="table-responsive">
                                                <Table className="table mb-0 table-bordered">
                                                    <tbody>
                                                        <tr>
                                                            <th scope="row" style={{ width: "200px" }}>
                                                                {props.t("Name")}
                                                            </th>
                                                            <td>
                                                                {name}
                                                            </td>
                                                        </tr>

                                                        <tr>
                                                            <th scope="row" style={{ width: "200px" }}>
                                                                {props.t("Email")}
                                                            </th>
                                                            <td>
                                                                {email}
                                                            </td>
                                                        </tr>
                                                    </tbody>
                                                </Table>
                                                <Row className="m-1">
                                                    <Col>

                                                    {
                                                        props.forgetLoading ?

                                                            <Button
                                                                color="primary"
                                                                className=" w-md waves-effect waves-light m-1"
                                                            >
                                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                            </Button>
                                                            :
                                                            <Button onClick={() => { resetPassword() }} color="primary">{props.t('Reset password')}</Button>
                                                    }


                                                    {props.userTeacher && props.userTeacher.id && Parse.User.current().attributes.instituteId && Parse.User.current().attributes.instituteId.length > 0 &&
                                                        <Link className="m-1 btn btn-warning  " color="warning"

                                                                to={props.userTeacher.attributes.Role === 3 ? "/staffprofile/d-" + schoolId + "/" + props.userTeacher.id : "/staffprofile/" + props.userTeacher.attributes.SchoolID + "/" + props.userTeacher.id}
                                                            >
                                                                {props.t('Staff Profile')}

                                                        </Link>
                                                    }


                                                    {props.forgetError && props.forgetError ? (
                                                        <UncontrolledAlert color="danger" style={{ marginTop: "13px" }}>
                                                            {props.forgetError}
                                                        </UncontrolledAlert>
                                                    ) : null}
                                                    {props.forgetSuccessMsg ? (
                                                        <UncontrolledAlert color="success" style={{ marginTop: "13px" }}>
                                                            {props.forgetSuccessMsg}
                                                        </UncontrolledAlert>
                                                    ) : null}
                                                    </Col>
                                                </Row>
                                            </div>




                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>

                        </Card>

                    </Col>

                </Container>
            </div>
        </React.Fragment>

    );

}

const mapStatetoProps = state => {
    const { error, success } = state.Profile;
    const { userTeacher } = state.Login;
    const { forgetSuccessMsg, forgetError, forgetLoading, } = state.ForgetPassword;
    return { error, success, userTeacher, forgetSuccessMsg, forgetError, forgetLoading };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { editProfile, userForgetPassword,logoutUser })(UserProfile)));

