
import React, { useState } from 'react';
import { Row, Button, Col, Form } from "reactstrap";


const Topbar = (props) => {
    return (
        <Row className='d-flex align-items-start'>
            <Col className="d-flex align-items-start">
                <div
                    style={{ cursor: "pointer" }}
                    onClick={() => { window.history.back() }}>
                    <ul className=" list-unstyled">
                        <div className=''>
                            <i className="bx bx-chevron-left h1 text-primary"></i>
                        </div>
                    </ul>
                </div>
                <div className=' m-2'>
                    <h4>{props.title ? props.title : "STUDENT IMPORT"}</h4>
                </div>
            </Col>
            <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                    {props.addbutton && <Button
                        onClick={() => props.onClickAdd()}
                        type="button"
                        color="success"
                        className="btn waves-effect waves-light  mb-2"
                    ><i className="mdi mdi-plus mr-1"></i>
                        {props.buttonName ? props.buttonName : "Import Students"}
                    </Button>
                    }
                </div>
            </Col>
        </Row>

    );
}
export default Topbar;
