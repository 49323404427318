import Parse from "parse";
import { printLog } from "./loghelper";

const getEnquiryFollowupFromParse = (enquiryFollowupId) => {
  printLog("HL: enquiryfollowup_helper : getEnquiryFollowupFromParse")

  try {
    return new Promise((resolve, reject) => {
      var EnquiryFollowup = Parse.Object.extend("EnquiryFollowup");
      var query = new Parse.Query(EnquiryFollowup);
      query.get(enquiryFollowupId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const deleteEnquiryFollowupFromParse = (enquiryFollowupId) => {
  printLog("HL: enquiryfollowup_helper : deleteEnquiryFollowupFromParse")
  try {
    return new Promise((resolve, reject) => {
      const EnquiryFollowup = Parse.Object.extend("EnquiryFollowup");
      var enquiryFollowUp = new EnquiryFollowup();
      enquiryFollowUp.id = enquiryFollowupId;
      enquiryFollowUp.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        enquiryFollowUp.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        enquiryFollowUp.set("deletedBy", Parse.User.current().id);
      }

      enquiryFollowUp.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const getEnquiryFollowupForSchoolId = (id,isLoacal) => {
  printLog("HL: enquiryfollowup_helper : getEnquiryFollowupForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryFollowup = Parse.Object.extend("EnquiryFollowup");
      var query = new Parse.Query(EnquiryFollowup);
      query.equalTo("schoolId", id);

      

      if (isLoacal) {
        query.fromLocalDatastore();
        
      }
      query.notEqualTo("isDeleted", true);
      query.addAscending("followupNumber");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};



const editEnquiryFollowup = (enquiry) => {
  printLog("HL: enquiryfollowup_helper : editEnquiryFollowup")
  try {
    return new Promise((resolve, reject) => {
      enquiry.save().then(
          (result) => {
            resolve(result);
          },
            (error) => {
              resolve(error);
          }
        );
        
      });	
  } catch (error) { 
    
}
}

const saveEnquiryFollowups = (followups) => {
  printLog("HL: enquiryfollowup_helper : saveEnquiryFollowups")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(followups).then(
          (result) => {
            resolve(result);
          },
            (error) => {
              resolve(error);
              console.log("Error", error);
          }
        );
        
      });	

  } catch (error) { 
    console.log("Error", error);
  }
}

export {
  getEnquiryFollowupFromParse,
  deleteEnquiryFollowupFromParse,
  getEnquiryFollowupForSchoolId,
  editEnquiryFollowup,
  saveEnquiryFollowups,
};
