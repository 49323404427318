import { takeEvery, takeLeading, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as ExpensesHelper from "../../../helpers/ParseHelpers/expenseCategory_helper";
import * as actionTypes from "./actionTypes";
import * as ExpensesActions from "./actions";
import * as Constants from '../../../Constents';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getExpenseCategoryForSchoolIdSaga({ payload: { schoolId, instituteId } }) {
  try {
    var key = Constants.EXPENSE_CATEGORY_LIST_UPDATED_TIME + "__" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(ExpensesHelper.getExpenseCategoryForSchoolId, schoolId, instituteId, null, true);
    if (localResponse && localResponse.length > 0) {
      yield put(ExpensesActions.updateExpenseCategories(localResponse));
    }

    const serverResponse = yield call(ExpensesHelper.getExpenseCategoryForSchoolId, schoolId, instituteId, updatedTime, false);

    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(ExpensesActions.updateExpenseCategories(serverResponse));

      Parse.Object.pinAll(serverResponse);

    }
  } catch (error) {
  }
}

function* editExpenseCategory({ payload: { expenseCategory, isInstitute } }) {
  try {

    yield put(ExpensesActions.updateLoadingExpenseCategory(true));
    const response = yield call(
      ExpensesHelper.editExpenseCategory,
      expenseCategory, isInstitute
    );
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    } else
      if (response) {
        yield put(ExpensesActions.updateExpenseCategory(response));
        yield put(ExpensesActions.updateLoadingExpenseCategory(false));
        response.pin();
      }
  } catch (error) {
    yield put(ExpensesActions.updateLoadingExpenseCategory(false));
  }
}



export function* watchGetExpenseCategoryForSchoolIdSaga() {
  yield takeEvery(actionTypes.GET_EXPENSE_CATEGORY_FOR_SCHOOL_ID, getExpenseCategoryForSchoolIdSaga);
}

export function* watchEditExpenseCategory() {
  yield takeEvery(actionTypes.EDIT_EXPENSE_CATEGORY, editExpenseCategory);
}


function* ExpenseCategorySaga() {
  yield all([

    fork(watchGetExpenseCategoryForSchoolIdSaga),
    fork(watchEditExpenseCategory),

  ]);
}
export default ExpenseCategorySaga;
