import React, { useEffect, useState } from "react";
import Iframe from 'react-iframe';
import { Card, CardBody, CardTitle, Badge, Row, Col, Container, Input, UncontrolledTooltip, CardHeader, CardText, Label, Table } from "reactstrap";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Parse from "parse";
import { Link, withRouter } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import { setSelecetdSchool } from "../../store/actions";
import Topbar from '../Inventory/Topbar'
import Moment from 'moment';
import SimpleBar from "simplebar-react";
import Image from "../../assets/images/undraw_new_notifications_re_xpcv.svg";
import { Button } from "reactstrap/lib";


const JobApplications = (props) => {

    const match1 = matchPath(props.history.location.pathname, {
        path: "/job-applications-all/:schoolId",
        exact: true,
        strict: false,
    });

    const match2 = matchPath(props.history.location.pathname, {
        path: "/job-applications/:schoolId/:jobId",
        exact: true,
        strict: false,
    });


    const match = match1 ?? match2


    const schoolId = match && match.params.schoolId;
    const jobId =  match2 && match2.params.jobId;


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const [jobList, setJobList] = useState([]);
    const [jobApplicationList, setJobApplicationList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        setIsLoading(true)
        console.log(schoolId)
        var JobApplications = Parse.Object.extend("JobApplications");
        var query = new Parse.Query(JobApplications);
        query.equalTo("schoolId", schoolId);
        if (jobId) {
            query.equalTo("jobId", jobId);
        }
        query.descending("createdAt");
        query.notEqualTo("isDeleted", true);
        query.find().then(
            (results) => {
                console.log(results)
                setJobApplicationList(results);
                setIsLoading(false)
            },
            (error) => {

            }
        );
    }, [schoolId])

    useEffect(() => {
        setIsLoading(true)
        var JobNotifications = Parse.Object.extend("JobNotifications");
        var query = new Parse.Query(JobNotifications);
        query.equalTo("schoolId", schoolId);
        query.descending("createdAt");
        query.notEqualTo("isDeleted", true);
        query.find().then(
            (results) => {
                console.log(results)
                setJobList(results);
                setIsLoading(false)
            },
            (error) => {

            }
        );
    }, [schoolId])

    const getJobNameForId = (jobId) => {
        if (jobList && jobList.length > 0) {
            let temp = jobList.filter(
                (job) => job.id === jobId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.designation + " " ?? "-")
            }
        }
    }

    const getTimeStringForDate1 = (date) => {
        return Moment(date).format('DD-MMM-YYYY, hh:mm a');
    }

    function getTimeStringForDate(date) {
        return Moment(date).format("MMM Do YY");
    }


    const getReviewStatus = (status) => {
        if (status == '0') {
            return "Under Review"
        } else if (status == '-1') {
            return " Review Failed"
        } else if (status == '1') {
            return " Review Success"
        }

    }

    const getBadgeColor = (status) => {
        if (status == '0') {
            return "warning"
        } else if (status == '-1') {
            return "danger"
        } else if (status == '1') {
            return "success"
        }

    }


    return (

        <React.Fragment>

            <div className="page-content">
                <Topbar
                    title={"Job Applications"}
                    buttonName={"Create Job"}
                    hideButton={true}
                />
                <Container>
                    <Row>

                        {isLoading && (
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                        Loading....{" "}
                                    </Link>
                                </div>
                            </Col>
                        )}
                        {jobApplicationList.length == 0 && !isLoading &&<Row>
                            <Card>
                                <CardBody>
                                    <h4 className="text-info text-center">No Responses!!</h4>
                                </CardBody>
                            </Card>
                        </Row>}
                        {jobApplicationList.length > 0 && !isLoading && <Col lg={12}>
                            <Row>
                                <Card>
                                    <CardBody>
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr>
                                                    <th>Name</th>
                                                    <th>Date of the Application</th>
                                                    <th>Job Position</th>
                                                    <th>Email</th>
                                                    <th>Phone Number</th>
                                                    <th>Resume Link</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {jobApplicationList.map((application, index) => (
                                                    <tr key={index}>
                                                        <td>{application.attributes.name}</td>
                                                        <td>{getTimeStringForDate1(application.attributes.createdAt)}</td>
                                                        <td>{getJobNameForId(application.attributes.jobId)}</td>
                                                        <td>{application.attributes.emailId}</td>
                                                        <td>{application.attributes.phoneNumber}</td>
                                                        <td>
                                                            <a href={application.attributes.resume.url()} target="_blank" rel="noopener noreferrer">
                                                                Resume
                                                            </a>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </CardBody>
                                </Card>
                            </Row >
                        </Col >}
                    </Row >
                </Container >
            </div >
        </React.Fragment >
    )
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool })(JobApplications)));
