import * as actions from "./actionTypes";

export const updateEnquiry = (enquiry) => {
  return {
    type: actions.UPDATE_ENQUIRY,
    payload: { enquiry },
  };
};

export const deleteEnquiry = (enquiry) => {
  return {
    type: actions.DELETE_ENQUIRY,
    payload: enquiry,
  };
};

export const updateEnquiries = (enquiries) => {
  return {
    type: actions.UPDATE_ENQUIRIES,
    payload: { enquiries },
  };
};

export const getEnquiryForSchoolId = (schoolId) => {
  return {
    type: actions.GET_ENQUIRIES,
    payload: { schoolId },
  };
};

export const getKidObjFromEnquiry = (enqId) => {
  return {
    type: actions.GET_KIDOBJ_FROM_ENQUIRY,
    payload: { enqId },
  };
};
export const setKidObjForEnquiry = (kidObj) => {
  return {
    type: actions.SET_KIDOBJ_FOR_ENQUIRY,
    payload: { kidObj },
  };
};

export const setSelecetdEnquiry = (id) => {
  return {
    type: actions.SET_SELECTED_ENQUIRY,
    payload: { id },
  };
};
export const updateSelecetdEnquiry = (enquiry) => {
  return {
    type: actions.UPDATE_SELECTED_ENQUIRY,
    payload: { enquiry },
  };
};

export const editEnquiry = (enquiry) => {
  return {
    type: actions.EDIT_ENQUIRY,
    payload: { enquiry },
  };
};

export const clearEnquiry = () => {
  return {
    type: actions.CLEAR_ENQUIRY,
  };
};

export const getEnquirisForGivenDates = (schoolId, startDate, endDate) => {
  return {
    type: actions.ENQUIRIES_BETWEEN_DATES,
    payload: { schoolId, startDate, endDate },
  };
};

export const moveToAdmissions = (enquiry) => {
  return {
    type: actions.MOVE_TO_ADMISSIONS,
    payload: { enquiry },
  };
};

export const setEnquiryLoading = (state) => {
  return {
    type: actions.ENQUIRY_LODING,
    payload: { state },
  };
};
export const getAdmissionQuestions = (schoolId, instituteId, franchiseId) => {
  return {
    type: actions.GET_ADMISSION_QUESTIONS,
    payload: { schoolId, instituteId, franchiseId },
  };
};
export const setAdmissionQuestions = (adQuestionObj) => {
  return {
    type: actions.SET_ADMISSION_QUESTIONS,
    payload: { adQuestionObj },
  };
};

export const saveEnquiryObjects = (
  enquiryObjects,
  schoolId,
  parentObjects,
  successMessage
) => {
  return {
    type: actions.SAVE_ENQUIRY_OBJECTS,
    payload: { enquiryObjects, parentObjects, successMessage },
  };
};

export const setEnquiryBulkUploadLoading = (state) => {
  return {
    type: actions.ENQUIRY_BULK_UPLOAD_LODING,
    payload: { state },
  };
};

export const setEnquiryBulkDeleteLoading = (state) => {
  return {
    type: actions.ENQUIRY_BULK_DELETE_LODING,
    payload: { state },
  };
};

export const enquiryBulkDelete = (enquiries) => {
  return {
    type: actions.BULK_DELETE_REQUEST,
    payload: { enquiries },
  };
};

export const bulkDeleteUpdate = (enquiries) => {
  return {
    type: actions.BULK_DELETE_RESPONSE,
    payload: { enquiries },
  };
};

export const updateDeletedEnquiries = (enquiries) => {
  return {
    type: actions.UPDATE_DELETED_ENQUIRIES,
    payload: { enquiries },
  };
};

export const getDeletedEnquiryForSchoolId = (schoolId) => {
  return {
    type: actions.GET_DELETED_ENQUIRIES,
    payload: { schoolId },
  };
};

export const enquiryBulkRestore = (enquiries) => {
   return {
    type: actions.ENQUIRY_BULK_RESTORE_REQUEST,
    payload: { enquiries },
  };
}

export const setEnquiryBulkRestoreLoading = (state) => {
  return {
    type: actions.ENQUIRY_BULK_RESTORE_LODING,
    payload: { state },
  };
};

export const getEnquiryTemplate = (teacherId, schoolId, instituteId) => {
  return {
    type: actions.GET_ENQUIRY_TEMPLATE,
    payload: { teacherId, schoolId, instituteId },
  };
};

export const updateEnquiryTemplate = (enquiryTemplates) => {
  return {
    type: actions.UPDATE_ENQUIRY_TEMPLATE,
    payload: { enquiryTemplates },
  };
};

export const getFranchiseEnquiryForFranchiseId = (franchiseId,) => {
  return {
      type: actions.GET_FRANCHISE_ENQUIRY_FOR_FRANCHISE_ID,
      payload: { franchiseId }
  }
}

export const updateFranchiseEnquiry = (franchiseEnquiries) => {
  return {
      type: actions.UPDATE_FRANCHISE_ENQUIRY,
      payload: { franchiseEnquiries }
  }
}

export const saveFranchiseEnquiry = (franchiseEnquiry,isSelcted) => {
  return {
      type: actions.SAVE_FRANCHISE_ENQUIRY,
      payload: { franchiseEnquiry ,isSelcted}
  }
}

export const setSelectedFranchiseEnquiry = (id) => {
  return {
    type: actions.SET_SELECTED_FRANCHISE_ENQUIRY,
    payload: { id },
  };
};
export const updateSelectedFranchiseEnquiry = (enquiry) => {
  return {
    type: actions.UPDATE_SELECTED_FRANCHISE_ENQUIRY,
    payload: { enquiry },
  };
};