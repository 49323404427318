import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import ReactDragListView from "react-drag-listview/lib/index.js";
import { Row, Col, Card, CardBody, CardTitle, Table } from "reactstrap";



function DragDropTables(props) {
  
  const [data, SetData] = useState([]);
  useEffect(() => {
    let list = [...props.rearrangeObjects];
    SetData(list);
  }, [props.rearrangeObjects]);

  const dragProps = {
    onDragEnd(fromIndex, toIndex) {
      const modifiedData = [...data];

      const item = modifiedData.splice(fromIndex, 1)[0];
      modifiedData.splice(toIndex, 0, item);
      SetData(modifiedData);
    },
    nodeSelector: "tr",
    handleSelector: "tr",
  };


  const getAppName = (parseObject) => {
    if (parseObject.className === "AdmissionQuestion") {
      return parseObject.attributes.question 
    }
    if (parseObject.className === "AdmissionTitle") {
      return parseObject.titleObject.attributes.title 
    }else if (parseObject.className === "AcademicCycles") {
      return parseObject.attributes.schoolCycle + " syllabus"
    }else if (parseObject.className === "FormSections") {
      return parseObject.sectionName
    }else if (parseObject.className === "DiaryFeatures") {
      return parseObject.attributes.routine
    } else {
      return parseObject.attributes.name
    }
  }

  const handelOnClickBack = () => {
    
  }

  
  return (
    <React.Fragment>
      <div >
       
        <div className="container-fluid">
          {/* <Topbar title="Tables"
                backButton={true}
                hideButton={true}
                onClickBackButton={handelOnClickBack} /> */}

          <Row>
            <Col md={12}>
              <Card>
                <CardBody>
                  <CardTitle className="h4">Drag & Drop </CardTitle>

                  <button className="btn btn-sm btn-primary float-right m-2"
                    onClick={() => {
                      props.updatedOrderList(data);
                    }}
                  >
                    Done
                  </button>
                  {!props.hideClose&&<button className="btn btn-sm btn-danger float-right m-2"
                    onClick={() => {
                      props.onClickClose();
                    }}
                  >
                    Close
                  </button>}
                  <p className="card-title-desc">
                    For altering order/positions please darg & drop in respective positions
                  </p>

                  

                  <div className="table-responsive">
                    <ReactDragListView {...dragProps}>
                      <Table className="table mb-1">
                        <thead>
                          <tr>
                            <th style={{ width: "100px" }} >#</th>
                            <th> Name</th>
                           
                          </tr>
                        </thead>
                        <tbody>
                          {(data || []).map((item, index) => (
                            <tr key={index}>
                              <th scope="row" className="drag-pointer">
                                {(index + 1)}
                              </th>
                              <td>{getAppName(item)}</td>
                             
                            </tr>
                          ))}
                        </tbody>
                      </Table>
                    </ReactDragListView>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>

         
        </div>
      </div>
    </React.Fragment>
  );
}

export default DragDropTables;
