import React, {useEffect,useState } from 'react';

import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from "reactstrap";
import { matchPath } from "react-router";
//i18n
import { withNamespaces } from 'react-i18next';

import * as Constants from '../../Constents';
import DocViewer, { DocViewerRenderers,BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";

const Index = (props) => { 
    
    const match = matchPath(props.history.location.pathname, {
        path: "/docview/:id/:name",
        exact: true,
        strict: false,
    });
    
    const id = match.params.id;
    const name=match.params.name;
    const url='https://parsefiles.back4app.com/'+id+'/'+name;

  


    return (
            
        <React.Fragment>
              <DocViewer
                    pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer,]}
                    style={{ height: "500px" }}
                    documents={url}
                    config={{
                        header: {
                            disableHeader: true,
                            disableFileName: false,
                            retainURLParams: false
                        }
                    }}
    
                />
           
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    return { };
}
 
export default withNamespaces()(withRouter(connect(mapStatetoProps, { })(Index)));