import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
  enquiryReminders: [],
  selectedenquiryReminder: null,
  enquiryRemindersForIds: [],
  enquiryRemindersForSchool: undefined,
};

const enquiryReminder = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_ENQUIRY_REMINDER:
      var enquiryReminders = [...state.enquiryReminders];
      var temp = state.enquiryReminders.filter(
        (enquiryreminder) =>
          enquiryreminder.id === action.payload.enquiryReminder.id
      );
      if (temp.length > 0) {
        if (action.payload.enquiryReminder.attributes.isDeleted) {
          var others = state.enquiryReminders.filter(
            (enquiry) => enquiry.id !== action.payload.enquiryReminder.id
          );
          state = { ...state, enquiryReminders: others };
        } else {
          var index = enquiryReminders.indexOf(temp[0]);
          enquiryReminders[index] = action.payload.enquiryReminder;
          state = { ...state, enquiryReminders: enquiryReminders };
        }

      } else {
        var list = [action.payload.enquiryReminder];
        state = { ...state, enquiryReminders: list.concat(enquiryReminders) };
      }
      break;
    case actions.UPDATE_ENQUIRY_REMINDERS:
      var enquiryReminders = [...state.enquiryReminders];
      if (enquiryReminders.length > 0) {
        for (const latest in action.payload.enquiryReminders) {
          var temp = enquiryReminders.filter(
            (enquiryReminder) =>
              enquiryReminder.id === action.payload.enquiryReminders[latest].id
          );
          if (temp.length > 0) {
            if (action.payload.enquiryReminders[latest].attributes.isDeleted) {
              var others = enquiryReminders.filter(
                (enquiry) => enquiry.id !== action.payload.enquiryReminders[latest].id
              );
              enquiryReminders = [...others];
            } else {
              var index = enquiryReminders.lastIndexOf(temp[0]);
              enquiryReminders[index] = action.payload.enquiryReminders[latest];
            }

          } else {
            if (!action.payload.enquiryReminders[latest].attributes.isDeleted) {
              var list = [action.payload.enquiryReminders[latest]];
              enquiryReminders = list.concat(enquiryReminders);

            }
          }

        }
        state = { ...state, enquiryReminders: enquiryReminders };
      } else {
        var others = action.payload.enquiryReminders.filter(
          (enquiry) => enquiry.attributes.isDeleted !== true
        );
        state = { ...state, enquiryReminders: others };
      }

      break;

    case actions.DELETE_ENQUIRY_REMINDER:
      var enquiryReminders = state.enquiryReminders.filter(
        (enquiryReminder) => enquiryReminder.id !== action.payload.id
      );
      state = { ...state, enquiryReminders: enquiryReminders };
      break;
    case actions.REPLACE_ENQUIRY_REMINDERS:

      state = { ...state, enquiryReminders: action.payload.enquiryReminders };
      break;
    case actions.UPDATE_ENQUIRY_REMINDERS_EQUIRY_IDS:
      state = { ...state, enquiryRemindersForIds: action.payload.enquiryReminders };
      break;


    case actions.GET_ENQUIRY_REMINDERS_SCHOOL:
      state = { ...state, enquiryRemindersForSchool: undefined };
      break;

    case actions.UPDATE_ENQUIRY_REMINDERS_SCHOOL:

      if (state.enquiryRemindersForSchool && state.enquiryRemindersForSchool.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.enquiryRemindersForSchool], action.payload.enquiryReminders, "isDeleted")
        state = { ...state, enquiryRemindersForSchool: updatedList };
      } else {
        var others = action.payload.enquiryReminders.filter(
          (enquiryReminders) => enquiryReminders.attributes.isDeleted !== true
        );
        console.log(updatedList)
        state = { ...state, enquiryRemindersForSchool: others };
      }
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default enquiryReminder;
