import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as FeeTemplateItemHelper from "../../../helpers/ParseHelpers/feeTemplateItem_helper";
import * as actionTypes from "./actionTypes";
import * as FeeTemplateActions from "./actions";
import * as Constants from '../../../Constents';

function* getFeeTemplateItemsForTemplateIdParse({ payload: { templateId } }) {
  try {
    const response = yield call(FeeTemplateItemHelper.getFeeTemplateItemsForTeacherId, templateId, false);
    if (response && response.length > 0) {
      yield put(FeeTemplateActions.updateFeeTemplateItems(response));
    }

  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getFeeTemplateItemsForTemplates({ payload : { templateIds }}){
  try{
    const response = yield call(FeeTemplateItemHelper.gettemplateItemsForIds, templateIds)
    yield put(FeeTemplateActions.updateFeeTemplateItems(response))
  }catch(err){
    console.log(err)
  }
}




export function* watchGetFeeTemplateItemsForTemplateIdParse() {
  yield takeEvery(actionTypes.GET_FEE_TEMPLATE_ITEMS, getFeeTemplateItemsForTemplateIdParse);
}

export function* watchGetFeeTemplateItemsForTemplateIds(){
  yield takeEvery(actionTypes.GET_FEETEMPLATE_ITEMS_FOR_TEMPLATE_IDS, getFeeTemplateItemsForTemplates)
}

function* FeeTemplateSaga() {
  yield all([
    fork(watchGetFeeTemplateItemsForTemplateIdParse),
    fork(watchGetFeeTemplateItemsForTemplateIds)
  ]);
}
export default FeeTemplateSaga;
