import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, UncontrolledTooltip, ModalFooter, Form, FormGroup, Input, Container, Row, TabContent, TabPane, Card, CardText, Nav, NavItem, NavLink, CardBody, Label, CardHeader, Button, Col, Table } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from "moment";
import DatePicker from 'react-datepicker';
import { setSelecetdSchool } from '../../store/actions';

const ExpenseDetailModal = (props) => {

    const CASH = "Cash"
    const CHEQUE = "Cheque"
    const ONLINE = "Online"

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }


    return (
        <React.Fragment>
            <Modal size='md' isOpen={props.expenseDetailsPopup} toggle={() => { props.closeExpenseDetailsPopup() }} scrollable={true}>
                <ModalHeader toggle={() => {
                    props.closeExpenseDetailsPopup()
                }}>
                    {(props.expenseDetails && props.expenseDetails.expenseNumber) ? <>{"Expense Details (#:" + props.expenseDetails.expenseNumber + ")"}</> : "Expense Details"}
                </ModalHeader>
                <ModalBody>

                    <Table className=" table mb-0 table-bordered">
                        <tbody>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Amount</th>
                                <td>{props.expenseDetails && props.expenseDetails.amount}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Method</th>
                                <td>{(props.expenseDetails && props.expenseDetails.paymentType === 1) ? CASH : (props.expenseDetails && props.expenseDetails.paymentType === 2) ? CHEQUE : ONLINE}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Payment Date</th>
                                <td>{getTimeStringForDate(props.expenseDetails && props.expenseDetails.paymentDate)}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Payee</th>
                                <td>{(props.expenseDetails && props.expenseDetails.supplierName) ?? "-"}</td>
                            </tr>

                            <tr>
                                <th scope="row" style={{ width: "200px" }}>category</th>
                                <td>{(props.expenseDetails && props.expenseDetails.categoryName) ?? "-"}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Notes</th>
                                <td>{(props.expenseDetails && props.expenseDetails.note) ?? ""}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Reference number</th>
                                <td>{props.expenseDetails && props.expenseDetails.referenceNumber}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Tax</th>
                                <td>{props.expenseDetails && props.expenseDetails.tax}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>TDS</th>
                                <td>{props.expenseDetails && props.expenseDetails.tds}</td>
                            </tr>
                            <tr>
                                <th scope="row" style={{ width: "200px" }}>Images</th>
                                <td>
                                    {props.expenseDetails && props.expenseDetails.albumId ? <Label className="text-primary justify-content-center text-truncate" style={{ cursor: "pointer" }} onClick={() => {
                                        props.onClickAlbum(props.expenseDetails && props.expenseDetails.albumId)
                                    }}>
                                        <u>{"View Attachment"}</u>
                                    </Label> :
                                        (props.expenseDetails && props.expenseDetails.attachmentDoc ? <Label className="text-primary justify-content-center text-truncate" style={{ cursor: "pointer" }} onClick={() => {
                                            props.onClickFile(props.expenseDetails && props.expenseDetails.attachmentDoc)
                                        }}>
                                            <u>{"View Attachment"}</u>
                                        </Label> : "")
                                    }
                                </td>
                            </tr>
                        </tbody>
                    </Table>

                </ModalBody>
            </Modal>
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool })(ExpenseDetailModal)));
