import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import * as WhiteLable from "../../components/Common/WhiteLable";
const RefundPolicy = (props) => {

    return (
        <React.Fragment>


            <div className="text-center m-4">
                <div className=" mb-4">
                    <img src={WhiteLable.getAppLogoWithName()} alt="" height="36" />
                </div>

                <h3 className="text-dark"> REFUND POLICY</h3>

                <Card className="justify-content-center">

                    <CardBody>
                        <div>
                            <CardTitle className="text-left">Refund Policy</CardTitle>
                            <CardSubtitle className="mb-3 text-left">We will refund your amount, but if someone tries to misuse, they’re legally bound. </CardSubtitle>

                            <CardSubtitle className="mb-3 text-left">
                                Account closing <br />
                                1. The user may close his account at any time. Account closing means that the further use of services with the use of current password and login shall no longer be possible.<br />
                                2. The user is responsible for closing his account.<br />
                                3. Closing the account by the user without using lilTriangle services for a long time does not obligate lilTriangle to refund the amount for the unused period.<br />
                                4. lilTriangle reserves the right to close user’s account due to gross infringement of the provisions of these terms and conditions or in the event of illegal use of the offered services.<br />
                                5. lilTriangle shall not be liable for damage suffered by the user arisen due to the suspension or closing the account by the user or for other reasons arising from the user’s fault.<br />
                                6. There will be no refunds after 6 months of purchase under any circumstances.
                            </CardSubtitle>

                            <CardSubtitle className="mb-3 text-left">
                                Guarantee and limited liability.<br />
                                1. lilTriangle guarantees the highest quality of its operations to ensure accessibility and continuity of offered services and products in accordance with their use and purpose. No long distance data transmission, however, guarantees 100% security, continuity and accessibility of the provided services.<br />
                                2. Although lilTriangle tries to, but does not guarantee compatibility of offered services and products with other producers’ software. The user shall bear responsibility for the choice and consequences following from the use of other software including its applicability to user’s objectives.<br />
                                3. lilTriangle stipulates that opinions given by users do not reflect in any possible manner its views and opinions. lilTriangle does not monitor or control users’ opinions on a continual basis; if, however, any opinions in breach with these terms and conditions should be revealed, efforts shall be immediately made to remove them as soon as possible.<br />
                            </CardSubtitle>

                            <CardSubtitle className="mb-3 text-left">
                                4. lilTriangle shall bear no liability in particular for:
                                <p></p>
                                <li>Phrases and entries added to the network in connection with the use of offered services and products,</li>
                                <li> Unlawful and inconsistent with these terms and conditions users’ operations while using offered services and products,</li>
                                <li>Disturbances in accessibility of offered products and services not caused by lilTriangle,</li>
                                <li>Damage suffered by the user arisen due to the suspension or closing the account by the user or for other reasons arising from the user’s fault,</li>
                                <li>Damage suffered by the user as a result of a third party using his data that enable him to access the provided services and products,</li>
                                <li>Damage following from the use or the impossibility to use offered products and services including damage actually suffered, the loss of expected benefits, data loss, damage or computer crash, the costs of substitute equipment and software, shut-down, company reputation infringement,</li>
                                <li>Consequences of failure to perform or improper performance of obligations undertaken by other users even though such an obligation has been undertaken using offered products and services</li>
                                <li>lilTriangle is not responsible for the damage suffered by the user as a result of a bot attack or phishing due to third-party.</li>
                            </CardSubtitle>
                        </div>

                        <CardSubtitle className="mb-3 text-left">
                            5. In case of refund, refund shell be made in 30-60days from the day of refund request<br />
                        </CardSubtitle>

                        <div>
                            <CardTitle className="text-left">Privacy Policy</CardTitle>
                            <CardSubtitle className="mb-3 text-left">All disputes arising in connection with these terms and conditions shall be primarily resolved amicably </CardSubtitle>
                        </div>

                        <div>
                            <CardTitle className="text-left">Final provisions</CardTitle>
                            <CardSubtitle className="mb-2 text-left"> All disputes that cannot be amicably resolved shall be submitted for resolution by Common Court of Law at the location of lilTriangle registered office. </CardSubtitle>
                            <CardSubtitle className="mb-3 text-left">The provisions of Civil Code, the act on copyright and neighbouring rights, an act on electronic services providing and other provisions of effective law shall be applicable in issues not governed by these terms and conditions. </CardSubtitle>
                            <CardSubtitle className="mb-2 text-left"> Our terms are aligned and subject to modification according to laws and guidelines by local government bodies.</CardSubtitle>
                           
                        </div>
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}

export default RefundPolicy;