import React from 'react';

import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withNamespaces } from 'react-i18next';
import { Button, Card, CardBody,Table } from 'reactstrap';
import { Link } from "react-router-dom";

const ListView = props => {
    
    const getLink = (val) => {
        return val.groupObject ? `/${val.link}/${val.groupObject.id}/${val.groupObject.attributes.name}` : `/${val.link}`;
    }
    
    return(
        <React.Fragment>
            <Card>
                <CardBody>
                    <div className="table-responsive">
                        <Table className="table-centered table-nowrap table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th style={{ width: "50px",textAlign: "center" }}>#</th>
                                    <th scope="col">{props.t('Name')}</th>
                                    
                                    <th scope="col"><div style={{ textAlign: "center" }}></div></th>
                                    <th scope="col"><div style={{ textAlign: "center" }}>{props.t('Action')}</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {props.archiveList.map((val, key)=>(
                                    <tr key={key}>
                                        <td>
                                            <div className="avatar-xs">
                                                <span className={`avatar-title rounded-circle ${false ? 'bg-danger' : 'bg-primary'}`}>
                                                    {val.name ? val.name.charAt(0).toUpperCase() : ""}
                                                </span>
                                            </div>
                                        </td>
                                        <td className={val.classCss}>
                                            {val.groupObject == undefined && <Link to={getLink(val)}>
                                            {val.name}
                                            </Link>}
                                            {val.groupObject && <Link to={getLink(val)} className="text-dark">
                                            {val.name}
                                            </Link>}
                                        </td>
                                        
                                        <td><div style={{ textAlign: "center" }}>{  }</div></td>
                                        
                                        <td>
                                            <div style={{ textAlign: "center" }}>
                                                <Link to={getLink(val)} >
                                                    <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">
                                                        {props.t('View Details')}
                                                    </Button>
                                                </Link>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
const mapStatetoProps = state => {
    return{}
}

export default connect(mapStatetoProps, {})(withRouter(withNamespaces()(ListView)));
