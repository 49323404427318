import * as actions from './actionTypes';

export const addOrupdateParent = (parent) => {
    return {
        type: actions.ADD_OR_UPDATE_PARENT,
        payload: { parent }
    }
}

export const updateAParent = (parentObj) =>{
    return{
        type : actions.UPDATE_A_PARENT,
        payload : { parentObj }
    }
}

export const deleteParent = (parent) => {
    return {
        type: actions.DELETE_PARENT,
        payload: parent
    }
}

export const getParentsForIds = (parentIds) => {
    return {
        type: actions.GET_PARENTS,
        payload: { parentIds }
    }
}
export const getParentsForFamilyIds = (familyIds,fromLocal) => {
    return {
        type: actions.GET_PARENTS_FOR_FAMILY_IDS,
        payload: { familyIds,fromLocal }
    }
}

export const getParentsForFamilyId = (familyId) => {
    return {
        type: actions.GET_PARENTS_FOR_FAMILY_ID,
        payload: { familyId }
    }
}
export const setSelectedParents = (parents) => {
    return {
        type: actions.SET_SELECTED_PARENTS,
        payload: { parents }
    }
}
export const updateParents = (parents) => {
    return {
        type: actions.UPDATE_PARENTS,
        payload: { parents }
    }
}
export const addParentsToDB = (parents)=>{
    return{
        type : actions.ADD_PARENTS_TO_DB,
        payload : { parents }
    }
}