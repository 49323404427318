import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { getSchoolForInstitute, setSelecetdSchool } from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { MDBDataTable } from "mdbreact"
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import Image from "../../../assets/images/growing_chart.svg";


const EnquiryAnalytics = (props) => {

    const schoolId = props.schoolId

    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    const [reportDownload, setReportDownload] = useState(undefined);

    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);

    const [selectedMonths, setSelectedMonths] = useState([]);
    const [loadingState, setLoadingState] = useState(1);

    const titles = ["School Name", "Student Name", "Enquired On", "Added On", "Status ", "Fathe Name ", "Father Mobile", "Father Email", "Mother Name", "Mother Mobile", "Mother Email", "Source", "Admission year", "Admission class", "Fee Type"];

    const columns = [
        {
            label: "Sl No",
            field: "slno",
        },
        {
            label: "School Name",
            field: "schoolName",
        },
        {
            label: "Student Name",
            field: "studentName",
        },
        {
            label: "Enquired On",
            field: "enquiredOn",
        },
        {
            label: "Added On",
            field: "addedOn",
        },
        {
            label: "Status",
            field: "status",
        },
        {
            label: "Fathe Name",
            field: "fatherName",
        },
        {
            label: "Father Mobile",
            field: "fatherMobile",
        },
        {
            label: "Father Email",
            field: "fatheremail",
        },
        {
            label: "Mother Name",
            field: "mothername",
        },
        {
            label: "Mother Mobile",
            field: "motherMobile",
        },
        {
            label: "Mother Email",
            field: "motherEmail",
        },
        {
            label: "Source",
            field: "source",
        },
        {
            label: "Admission year",
            field: "admissionYear",
        },
        {
            label: "Admission class",
            field: "admissionClass",
        },
        {
            label: "Fee Type",
            field: "feeType",
        },
        {
            label: "Program Type",
            field: "programType",
        },
    ]

    const [reportData, setReportData] = useState([{
        columns: columns,
        rows: [],
    }]);

    useEffect(() => {
        setFromDate(props.fromDate)
    }, [props.fromDate]);

    useEffect(() => {
        setToDate(props.toDate);
    }, [props.toDate]);

    useEffect(() => {
        if (props.onClickApply) {
            handelDateChange();
        }
    }, [props.onClickApply]);



    const handelDateChange = () => {
        setLoadingState(2);
        getReortReportSessions();
    }



    const getDayBetweenDates = (d1, d2) => {
        var diff = d2 - d1;
        return Math.floor(diff / 86400000);

    }


    const getReortReportSessions = () => {

        if (!fromDate || !toDate) {
            return;
        }


        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {

                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('MMM YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }
        setSelectedMonths(dateList);

        let days = getDayBetweenDates(startDate, endDate,);

        let query = new Parse.Query("Enquiry");

        query.greaterThanOrEqualTo('createdAt', startDate);
        query.lessThanOrEqualTo('createdAt', endDate);
        query.notEqualTo('isDeleted', true);

        query.limit(10000)


        let schoolIds = [];
        if (props.isFranchise) {
            for (const school of props.schools) {
                schoolIds.push(school.id)
            }
            query.containedIn('schoolId', schoolIds)
        } else {
            query.equalTo('schoolId', schoolId)
        }
        query.ascending("createdAt");
        query.addAscending("schoolId");
        query.find().then((enquiries) => {

            if (!enquiries || enquiries.length === 0) {
                setLoadingState(5)
            } else {
                prepareDate(enquiries);
            }
        });
    }

    const prepareDate = (enquiries) => {


        let dataList = []
        let dataList2 = [];
        let count = 0;
        for (const school of props.schools) {
            for (const enq of enquiries) {
                if (school.id === enq.attributes.schoolId) {
                    let da = getEnquiryRow(school, enq);
                    dataList.push(da)
                    count++
                    dataList2.push(getEnquiryObjectFromRow(da, count))
                }
            }
        }
        setReportDownload(dataList)
        setReportData({
            columns: columns, rows: dataList2,
        });
        setLoadingState(3)
    }

    const getEnquiryObjectFromRow = (da, count) => {

        return {
            slno: count,
            schoolName: da[0],
            studentName: da[1],
            enquiredOn: da[2],
            addedOn: da[3],
            status: da[4],
            fatherName: da[5],
            fatherMobile: da[6],
            fatherEmail: da[7],
            mothername: da[8],
            motherMobile: da[9],
            motherEmail: da[10],
            source: da[11],
            admissionYear: da[12],
            admissionClass: da[13],
            feeType: da[14],
            programType: da[15]
        }
    }

    const getEnquiryRow = (school, enquiry) => {
        let aRow = [];

        aRow.push(school.attributes.Name);

        aRow.push(enquiry.attributes.kidName);
        aRow.push(Moment(enquiry.attributes.enquiryDate ?? enquiry.attributes.createdAt).format('DD-MM-YYYY hh:mm a'));
        aRow.push(Moment(enquiry.attributes.createdAt).format('DD-MM-YYYY hh:mm a'));

        aRow.push(getEnquiryStatus(enquiry.attributes.status))

        aRow.push(enquiry.attributes.fatherName);
        aRow.push(enquiry.attributes.fatherMobile);
        aRow.push(enquiry.attributes.fatherEmail);
        aRow.push(enquiry.attributes.motherName);
        aRow.push(enquiry.attributes.motherMobile);
        aRow.push(enquiry.attributes.motherEmail);

        aRow.push(enquiry.attributes.sourse);
        aRow.push(enquiry.attributes.year);
        aRow.push(getStringFromList(enquiry.attributes.enquiryFor));
        aRow.push(enquiry.attributes.feeType);
        aRow.push(enquiry.attributes.programType);


        return aRow;
    }

    const getEnquiryStatus = (status) => {
        let rVal
        switch (status) {
            case 0:
                rVal = "Enquired";
                break;
            case 1:
                rVal = "Admitted";
                break;
            case 2:
                rVal = "Rejected";
                break;
            case 3:
                rVal = "Waitlisted";
                break;
            case 4:
                rVal = "Cold";
                break;
            case 5:
                rVal = "Warm";
                break;
            case 6:
                rVal = "Hot";
                break;
            case 7:
                rVal = "Lost";
                break;
            case 8:
                rVal = "Drop";
                break;
            case 9:
                rVal = "Consulted";
                break;
            case 10:
                rVal = "Evaluated";
                break;
            default:
                rVal = "Enquired";
                break;
        }
        return rVal;
    }



    const getStringFromList = (list) => {
        let str = "";
        if (list) {
            for (const s of list) {
                str = s + ",";
            }
        }
        return str;

    }






    const onClickDownload = () => {
        let sDate = Moment(fromDate).format('DD-MM-YYYY')
        let eDate = Moment(fromDate).format('DD-MM-YYYY')

        let finalData = [];
        let fileName = `consolidated_enqiry_report_${sDate}_${eDate}.xlsx`;
        let sheetName = sDate + "-" + eDate;


        finalData.push([`consolidated enqiry report for all branches`]);
        finalData.push([`Selected Dates : ${sDate} to ${eDate}`,]);

        finalData.push([]);
        finalData.push(titles);
        finalData = finalData.concat(reportDownload);



        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);

            XLSX.utils.book_append_sheet(wb, ws, "all");

            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;


            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            XLSX.utils.book_append_sheet(wb, ws, "all");
            XLSX.writeFile(wb, fileName);
        }


    }



    const onClickBackButton = () => {
        window.history.back();
    }

    const getIndexList = () => {
        let list = [];
        for (const i in reportData) {
            list.push(i);
        }
        return list;
    }

    return (
        <React.Fragment>
            {props.showTitle && <CardTitle>Enquiry Analytics Report</CardTitle>}
            {loadingState > 0 && props.showDatePickers &&
                <Col >
                    <Row className="justify-content-center bg-light ml-2">

                        <Row>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("From")} :
                                    </Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {
                                            setFromDateError(false)
                                            setFromDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        maxDate={toDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {fromDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("To")} :
                                    </Label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(date) => {
                                            setToDateError(false);
                                            setToDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        minDate={fromDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {toDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={3} >
                                <div className="mt-4 mb-2 p-1">
                                    <button
                                        disabled={loadingState === 2}
                                        onClick={(e, v) => {
                                            let next = true;
                                            if (!toDate) {
                                                setToDateError(true);
                                                next = false;
                                            }
                                            if (!fromDate) {
                                                setFromDateError(true);
                                                next = false;
                                            }
                                            if (next) {
                                                handelDateChange();
                                            }

                                        }}
                                        className="w-md  btn btn-primary"
                                    >
                                        {props.t("Apply")}
                                    </button>

                                </div>
                            </Col>
                        </Row>
                        {/* </Form> */}
                    </Row>
                </Col>
            }
            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "Setting up..." : "Preparing report, it may take more time than anticipated"}</Label>
                    </div>
                </Col>
            }


            <Element name={"scrollPossition"} >
                <div></div>
            </Element>


            {loadingState === 5 && <Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img mt-8">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{'No data avilable between ' + selectedMonths[0].dateString + " and " + selectedMonths[selectedMonths.length - 1].dateString}</h3>
                    <p>{'Plese select other dates '}.</p>


                </CardBody>
            </Card>}



            {loadingState === 3 && selectedMonths && selectedMonths.length > 0 && (
                <Col xs="12">
                    <div className="mt-2 mb-2 p-1 d-flex justify-content-end">
                        <button
                            onClick={() => {
                                if (selectedMonths && selectedMonths.length > 0) {
                                    onClickDownload();
                                }
                            }}
                            className="w-md btn btn-primary"
                        >
                            <i className='bx bx-download font-size-16 align-middle m-1'></i>
                            {props.t("Download Report")}
                        </button>
                    </div>
                </Col>
            )}


            {reportData.rows && reportData.rows.length > 0 && (
                <MDBDataTable
                    striped
                    hover
                    responsive
                    bordered
                    data={reportData}
                />)}

            {/* {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && 
            <Col xs="12">
                <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" className="table table-striped table-bordered">
                            <Thead>
                                <Tr>


                                    <Th>#</Th>
                                    {titles.map((t, k) => (
                                        <Th key={k} data-priority={k}>{t}</Th>
                                    ))}

                                </Tr>
                            </Thead>
                            <Tbody>

                                {
                                    reportData && getIndexList().map((i, key) => (
                                        <Tr key={key}>
                                            <Th >{parseInt(i) + 1}</Th>
                                            {reportData[i].map((e, key2) => (
                                                <Td key={key2}>{e}</Td>
                                            ))}

                                        </Tr>
                                    ))
                                }

                            </Tbody>
                        </Table>
                    </div>
                </div>

            </Col>
            } */}

            {/* <div style={{ height: "400px" }}></div> */}
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    return { schools, selectedSchool };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getSchoolForInstitute, setSelecetdSchool,
        })(EnquiryAnalytics)
    )
);
