import * as actions from "./actionTypes";

const initState = {
  messageCredits: [],
  pricingList: [],
};

const MessageCredits = (state = initState, action) => {
  switch (action.type) {
    case actions.SET_ALL_CREDITS_FOR_USER:
      state = { ...state, messageCredits: action.payload.creditObj };
      break;
    case actions.SET_ALLPRICING_FOR_MESSAGES:
      state = { ...state, pricingList: action.payload.pricingObj };
      break;
    case actions.PAYMENT_CREATION_SUCCESS:
      state = { ...state, paymentProcessSuccess: action.payload.mtObj };
      break;
    default:
      state = { ...state };
  }
  return state;
};
export default MessageCredits;
