import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import Editable from 'react-bootstrap-editable';
import { matchPath } from "react-router";
import Moment from 'moment';

import toastr from "toastr";
import contentImage from "../../assets/images/getStartedImages/customFormInitial.svg";

import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";
import QRCode from 'qrcode'
import html2canvas from 'html2canvas';

import {
  setSelecetdSchool, getTeachersForSchool
} from "../../store/actions";
import TopBar from "./Topbar";

import * as Constants from "../../Constents";
import * as WhiteLable from "../../components/Common/WhiteLable";
const FormDetailPage = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/forms/:schoolId",
    exact: true,
    strict: false,
  });

  const franchiseMatch = matchPath(props.history.location.pathname, {
    path: "/franchise-forms/:franchiseId",
    exact: true,
    strict: false,
  });

  const schoolId = match && match.params.schoolId;
  const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);

    }
    fetchForms(schoolId)
    props.getTeachersForSchool(schoolId);

  }, [props.selectedSchool, schoolId]);

  //var schoolId = localStorage.getItem(Constants.SELECTED_SCHOOL_ID);

  const [nameError, setNameError] = useState(false);

  const [createNewFormView, setCreateNewFormView] = useState(false);
  const [displayList, setDisplayList] = useState([]);
  const [showFormLinkModal, setShowFormLinkModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [formName, setFormName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [formToDelete, setFormToDelete] = useState(undefined);
  const [selectedForm, setSelectedForm] = useState(undefined);
  const [forAllSchools, setForAllSchools] = useState(false);
  const [createNewFormInProgress, setCreateNewFormInProgress] = useState(false);
  const [showDuplicateAlert, setShowDuplicateAlert] = useState(undefined);

  const HOST_NAME = WhiteLable.getHost();
  
  const FORM_LINK = HOST_NAME + "/fill-form/" + (franchiseMatch ? `f/${franchiseId}` : `s/${schoolId}`) + `/${selectedForm && selectedForm.id}`
  const [formLinkCode, setFormLinkCode] = useState(undefined)

  useEffect(() => {
      formLinkQrCode()
      console.log(props.invoiceObject)
  }, [props.invoiceObject]);

  function formLinkQrCode() {
      QRCode.toDataURL(FORM_LINK, { errorCorrectionLevel: 'H' })
          .then(url => {
            setFormLinkCode(url)
          })
          .catch(err => {
              console.error(err)
          })
  }

  const fetchForms = (schoolId) => {
    let ids = [];

    if (props.selectedSchool) {
      //ids.push(props.selectedSchool.id);
      ids.push(props.selectedSchool.attributes.instituteId);
      if (props.selectedSchool.attributes.franchiseId) {
        ids.push(props.selectedSchool.attributes.franchiseId);
      }
    }
    ids.push(schoolId)


    if (ids.length > 0) {
      var query = new Parse.Query("Forms");

      if (franchiseMatch) {
        query.equalTo("ownerId", franchiseId);
      } else {
        query.containedIn("ownerId", ids);
        query.equalTo("createdFor", "School");
      }
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.descending("createdAt");

      query.find().then((results) => {
        setIsLoading(false);
        var tempList = [];

        if (results != null) {
          if (results.length > 0) {
            setDisplayList(results);
          } else {
            //setIsQuestionsAvailable(false);
          }
        }
      });
    }
  }
  useEffect(() => {
    let ids = [];

    if (props.selectedSchool) {
      //ids.push(props.selectedSchool.id);
      ids.push(props.selectedSchool.attributes.instituteId);
      if (props.selectedSchool.attributes.franchiseId) {
        ids.push(props.selectedSchool.attributes.franchiseId);
      }
    }
    ids.push(schoolId)

    if (ids.length > 0) {
      var query = new Parse.Query("Forms");

      query.containedIn("ownerId", ids);
      query.descending("createdAt");

      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.find().then((results) => {
        setIsLoading(false);
        var tempList = [];

        if (results != null) {
          if (results.length > 0) {
            setDisplayList(results);
          } else {
            //setIsQuestionsAvailable(false);
          }
        }
      });
    }
  }, [props.selectedSchool]);

  // useEffect(() => {
  //   if (props.cameras) {
  //     var temp = [...props.cameras];
  //     setDisplayList(temp);
  //     setIsLoading(false);
  //   }
  // }, [props.cameras]);

  // useEffect(() => {
  //   if (props.resultCameraAfterSave) {
  //     setIsLoading(false);
  //   }
  // }, [props.resultCameraAfterSave]);

  // const handleDeleteCamera = () => {
  //   var camera = cameraToDelete;
  //   camera.set("isDeleted", true);
  //   // setIsLoading(true);

  //   props.deleteCamera(camera, props.t("CCTV deleted successfully..!"));
  //   setShowDeleteAlert(false);
  // };

  const [showCreateFormModal, setShowCreateFormModal] = useState(false);

  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
  function copyFormLink(str, event) {
    document.oncopy = function (event) {
      event.clipboardData.setData("Text", str);
      event.preventDefault();
    };
    document.execCommand("Copy");
    document.oncopy = undefined;
    toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
  }

  const handleOnClickBackButton = () => {
    setCreateNewFormView(false);
  };

  const createNewForm = () => {
    let Form = Parse.Object.extend("Forms");
    let forms = new Form();

    forms.set("name", formName);

    if (Parse.User.current().attributes.teacherId) {
      forms.set("createdBy", Parse.User.current().attributes.teacherId);
    } else {
      forms.set("createdBy", Parse.User.current().id);
    }

    if (Parse.User.current().attributes.franchiseId && franchiseMatch) {


      if (forAllSchools) {
        forms.set("ownerId", Parse.User.current().attributes.franchiseId);
        forms.set("type", "Franchise");
        forms.set("createdFor", "School");
      } else {
        forms.set("ownerId", Parse.User.current().attributes.franchiseId);
        forms.set("type", "Franchise");
        forms.set("createdFor", "Franchise");
      }

    } else if (match) {
      if (forAllSchools && props.userTeacher && props.userTeacher.attributes.instituteId && props.userTeacher.attributes.instituteId.length > 0) {
        forms.set("ownerId", props.selectedSchool.attributes.instituteId);
        forms.set("type", "Institute");
        forms.set("createdFor", "School");
      } else {
        forms.set("ownerId", props.selectedSchool.id);
        forms.set("type", "School");
        forms.set("createdFor", "School");
      }
    }

    forms.save().then(
      (formResult) => {
        let FormSections = Parse.Object.extend("FormSections");
        let formSection = new FormSections();

        formSection.set("formId", formResult.id);
        formSection.set("sectionName", "Section 1");
        formSection.set("order", 1);

        formSection.save().then(
          (formSectionResult) => {
            let FormQuestions = Parse.Object.extend("FormQuestions");
            let formQuestion = new FormQuestions();

            formQuestion.set("formId", formResult.id);
            formQuestion.set("question", "Filling for");
            formQuestion.set("isDefaultQuestion", true);
            formQuestion.set(
              "questionType",
              Constants.QESTION_TEXT_SINGLE_LINE
            );
            formQuestion.set("choices", []);
            formQuestion.set("sectionId", formSectionResult.id);
            formQuestion.set("isMandatory", true);

            formQuestion.set("order", 1);
            formQuestion.save().then(
              (result) => {
                if (match) {
                  props.history.push("/addForm/" + schoolId + "/" + formResult.id);
                } else {
                  props.history.push("/franchise-addForm/" + franchiseId + "/" + formResult.id);
                }
              },
              (error) => { }
            );
          },
          (error) => { }
        );
      },
      (error) => { }
    );
  };

  const canShowCheckboxForAllSchool = () => {
    let returnValue = false

    if (Parse.User.current()) {

      if (Parse.User.current().attributes.teacherId) {
        if (props.userTeacher && props.userTeacher.attributes.instituteId && props.userTeacher.attributes.instituteId.length > 0) {
          returnValue = true
        }
      } else {
        returnValue = true
      }

    }


    return returnValue
  }

  const getTeacherName = (teacherId) => {

    let returnValue = "-"
    if (props.teachers && props.teachers.length > 0) {
      let tempUser = props.teachers.filter((teacher) => {
        return teacher.id === teacherId;
      });
      if (tempUser && tempUser.length > 0) {
        returnValue = tempUser[0].attributes.Name;
      }
    }


    return returnValue

  }

  const handleFormNameChange = (value, object) => {
    if (value.length > 0 && object) {
      object.set("name", value)
      object.save().then((result) => {
        toastr.success("Successfully changed form name", { timeOut: 1000 });
        let index = displayList.indexOf(object)
        let tempList = [...displayList]
        tempList[index] = result
        setDisplayList(tempList)

      }, (error) => {
        toastr.success("Something went wrong, please try again", { timeOut: 1000 });


      })
    }
  }

  const getNewFormObject = (form) => {
    let Form = Parse.Object.extend("Forms");
    let forms = new Form();
    let name = "copy-" + form.attributes.name
    forms.set("name", name);
    forms.set("createdFor", form.attributes.createdFor);
    forms.set("ownerId", form.attributes.ownerId);
    forms.set("isDeleted", form.attributes.isDeleted);
    forms.set("type", form.attributes.type);
    forms.set("createdBy", form.attributes.createdBy);

    return forms
  }
  const replicateSection = (newSection, oldSection) => {
    let tempObject = newSection
    tempObject.set("sectionName", oldSection.attributes.sectionName)
    tempObject.set("order", oldSection.attributes.order)
    tempObject.set("isDeleted", oldSection.attributes.isDeleted)

    return tempObject

  }

  const replicateQestions = (oldQuestions, quizId, newSectionId) => {

    let questionsToSave = []
    for (var i = 0; i < oldQuestions.length; i++) {
      let FormQuestions = Parse.Object.extend("FormQuestions");
      let formQuestion = new FormQuestions();
      formQuestion.set("formId", quizId);
      formQuestion.set("question", oldQuestions[i].attributes.question);
      formQuestion.set("questionType", oldQuestions[i].attributes.questionType);
      formQuestion.set("choices", oldQuestions[i].attributes.choices);
      formQuestion.set("sectionId", newSectionId);
      formQuestion.set("isMandatory", oldQuestions[i].attributes.isMandatory);
      formQuestion.set("isDefaultQuestion", oldQuestions[i].attributes.isDefaultQuestion)
      formQuestion.set("order", oldQuestions[i].attributes.order);
      formQuestion.set("isDeleted", oldQuestions[i].attributes.isDeleted);
      formQuestion.set("dataType", oldQuestions[i].attributes.dataType);

      questionsToSave.push(formQuestion)
    }






    return questionsToSave
  }


  const handleDuplicateForm = (form) => {
    setIsLoading(true)
    let query = new Parse.Query('FormSections');
    query.equalTo('formId', form.id);
    query.notEqualTo('isDeleted', true);
    query.find().then((oldFormSections) => {
      let sectionIds = [];
      let newSectionObjectsToSave = []
      for (const i in oldFormSections) {
        sectionIds.push(oldFormSections[i].id);
      }

      let queryQ = new Parse.Query('FormQuestions');
      queryQ.equalTo('formId', form.id);
      queryQ.notEqualTo('isDeleted', true);
      queryQ.containedIn("sectionId", sectionIds);
      queryQ.find().then((oldFormQuestions) => {
        let newFormObjectTosave = getNewFormObject(form); // getNewFormObject(form) Function u need to write
        newFormObjectTosave.save().then((newFormObject) => {
          for (const i in oldFormSections) {
            let Section = Parse.Object.extend("FormSections");
            let sections = new Section();
            sections.set("formId", newFormObject.id)
            newSectionObjectsToSave.push(sections)
          }
          if (newSectionObjectsToSave.length > 0) {
            Parse.Object.saveAll(newSectionObjectsToSave).then((newSectionObjects) => {
              let newQuestionObjects = [];
              let newSectionswithData = [];
              for (const i in oldFormSections) {
                let tempOldQuestions = oldFormQuestions.filter(
                  (q) =>
                    q.attributes.sectionId ===
                    oldFormSections[i].id
                );
                let tempSection = replicateSection(newSectionObjects[i], oldFormSections[i]);
                // replicateSection(newSectionObjects[i], oldFormSections[i]) Function u need to write
                let tempQuestions = replicateQestions(tempOldQuestions, newFormObject.id, newSectionObjects[i].id);
                // replicateQestions(tempOldQuestions, form.id, oldFormSections[i].id)Function u need to write
                newSectionswithData.push(tempSection);
                newQuestionObjects = newQuestionObjects.concat(tempQuestions);
              }
              Parse.Object.saveAll(newSectionswithData).then((rqs) => {
                if (newQuestionObjects.length > 0) {
                  Parse.Object.saveAll(newQuestionObjects).then((rqq) => {
                    props.history.push("/addForm/" + schoolId + "/" + newFormObject.id);
                  });
                } else {
                  props.history.push("/addForm/" + schoolId + "/" + newFormObject.id);
                }

              });
            });
          } else {
            props.history.push("/addForm/" + schoolId + "/" + newFormObject.id);
          }

        })
      })


    })
  }

  const canEdit = (form) => {

    if (franchiseMatch) {
      if (form.attributes.ownerId == Parse.User.current().attributes.franchiseId) {
        return true;
      }
    } else {
      if (props.selectedSchool) {
        if (form.attributes.ownerId == schoolId) {
          return true;
        }
        if (form.attributes.ownerId == props.selectedSchool.attributes.instituteId) {

          if (props.userTeacher && props.userTeacher.attributes.Role >= 3) {
            return true;
          }
        }
      }
    }
    return false;
  }

  const printDocument = () => {
    const input = document.getElementById('qrCodePrint');
    html2canvas(input, { allowTaint: true, useCORS: true })
        .then((canvas) => {

            // Convert canvas to data URL
            const imgData = canvas.toDataURL('image/png');

            // Create a link element and trigger a download
            const downloadLink = document.createElement('a');
            downloadLink.href = imgData;
            downloadLink.download = `${selectedForm && selectedForm.attributes.name}  QR Code`;
            downloadLink.click();
        });
};

  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading && !createNewFormView && (
          <Container fluid>
            {showFormLinkModal && (
              <Modal
                isOpen={showFormLinkModal}
                toggle={() => {
                  setShowFormLinkModal(false);
                }}
              >
                <ModalHeader>{props.t("Form Link")}</ModalHeader>
                <ModalBody>
                  <Card className="border-1">
                    <Row>
                      <Col lg={10}>
                        <p
                          disabled
                          type="text"
                          className=" m-2 text-primary"
                          id="linkarea"
                        >
                          {WhiteLable.getHost() + "/fill-form/" + (franchiseMatch ? `f/${franchiseId}` : `s/${schoolId}`) + `/${selectedForm.id}`}
                        </p>
                      </Col>

                      <Col
                        lg={2}
                        id="formLink"
                        onClick={(e) => {
                          if (franchiseMatch) {
                            copyFormLink(WhiteLable.getHost() + "/fill-form/" + "f" + "/" + franchiseId + "/" + selectedForm.id, e);
                          } else {
                            copyFormLink(WhiteLable.getHost() + "/fill-form/" + "s" + "/" + schoolId + "/" + selectedForm.id, e);
                          }
                        }}
                      >
                        <li className="list-inline-item px-2">
                          <h1
                            style={{ cursor: "pointer" }}
                            className="text-primary"
                          >
                            <i className="bx bxs-copy"></i>
                          </h1>
                          <p style={{ cursor: "pointer" }}>{props.t("copy")}</p>
                          <UncontrolledTooltip
                            placement="top"
                            target="formLink"
                          >
                            {props.t("copy to clipboard")}
                          </UncontrolledTooltip>
                        </li>
                      </Col>
                    </Row>
                  </Card>
                  <Row>
                        <div className="d-flex justify-content-center align-items-center" >
                            <img className="qrCodePrint img-responsive" id="qrCodePrint" src={formLinkCode} style={{ width: "100px" }} />
                        </div>
                    </Row>
                </ModalBody>
                <ModalFooter>
                <button
                    type="button"
                    className="btn btn-success"
                    onClick={() => {
                      printDocument()
                    }}
                  >
                    {props.t("Download QR code")}
                  </button>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setShowFormLinkModal(false);
                    }}
                  >
                    {props.t("Close")}
                  </button>
                </ModalFooter>
              </Modal>
            )}

            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div className=' mb-3'>
                  <h4>{props.selectedSchool && props.selectedSchool.attributes.schoolType == 100 ? "Feedback Forms" : "Forms"}</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end mb-2" style={{ marginTop: '30px' }}>
                  <Button
                    className="btn btn-primary waves-effect waves-light "
                    //to={"/addForm/" + schoolId}
                    //role="button"
                    color={"primary"}
                    onClick={() => {
                      setCreateNewFormView(true);
                    }}
                  >
                    Create Form
                  </Button>
                </div>
              </Col>
            </Row>

            <Card>
              {showDeleteAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowDeleteAlert(false);
                  }}
                  onConfirm={() => {
                    setIsLoading(true);
                    setShowDeleteAlert(false);
                    formToDelete.set("isDeleted", true);
                    formToDelete.save().then(
                      (result) => {
                        setIsLoading(false);
                        let tempList = [...displayList];
                        let tempIndex = tempList.indexOf(formToDelete);

                        removeElement(tempList, displayList[tempIndex]);
                        setDisplayList(tempList);
                        toastr.success("Form deleted successfully !!", {
                          timeOut: 2000,
                        });
                      },
                      (error) => {
                        setIsLoading(false);
                        toastr.warning(
                          "Something went wrong, please try again !!",
                          { timeOut: 2000 }
                        );
                      }
                    );
                  }}
                >
                  Are you sure to delete this Form?<br></br>
                </SweetAlert>
              )}
              <CardBody>
                {displayList.length > 0 && (
                  <div className="table-responsive">
                    <table className="table table-centered  mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "60% !important" }}>{props.t("Name")}</th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Created On")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Created By")}
                          </th>
                          <th style={{ textAlign: "center", width: "120px" }}>
                            {props.t("View Details")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Form Link")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Duplicate")}
                          </th>
                          <th style={{ textAlign: "center" }}>{props.t("Form Setup")}</th>
                          <th style={{ textAlign: "center" }}>{props.t("Delete")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayList.map((object, key) => (
                          <tr key={key}>
                            <td >
                              {/* <Label className="font-size-12 mb-1">
                                <Link
                                  to={
                                    "/formview/" +
                                    schoolId +
                                    "/" +
                                    object.id +
                                    "/" +
                                    object.attributes.name
                                  }
                                  className="text-dark"
                                >
                                  {object.attributes.name}
                                </Link>
                              </Label> */}



                              {canEdit(object) ? <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onValidated={(value) => { handleFormNameChange(value, object) }}
                                disabled={false}
                                editText={object.attributes.name ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={object.attributes.name}
                                validate={(value) => {
                                  if (value.length === 0) {
                                    return props.t(
                                      "Form name cannot be empty !!"
                                    );
                                  }
                                }}
                                showText
                                type="textfield"
                              /> : <p>{object.attributes.name}</p>}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {Moment(object.createdAt).format('DD-MM-YYYY, h:mm a')}
                              </Label>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {getTeacherName(object.attributes.createdBy)}
                              </Label>
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <Link
                                to={match ? `/formview/${schoolId}/${object.id}` : `/franchise-formview/${franchiseId}/${object.id}`}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  size="sm"
                                  className="btn-rounded waves-effect waves-light"
                                >
                                  {props.t("View Details")}
                                </Button>
                              </Link>
                            </td>

                            <td>
                              <div className="flex-fill text-center ">
                                <Link
                                  to={"#"}
                                  id={"form-link" + key}
                                  onClick={() => {
                                    setSelectedForm(object);
                                    setShowFormLinkModal(!showFormLinkModal);
                                  }}
                                >
                                  <i className="font-size-20 bx bx-link"></i>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <div className="flex-fill text-center ">

                                {canEdit(object) ? <Link
                                  id={"duplicate-form" + key}
                                  onClick={() => {
                                    setShowDuplicateAlert(object)
                                  }}
                                >
                                  <i className=" font-size-18  bx bx-copy"></i>
                                </Link> : "-"}

                              </div>
                            </td>



                            <td>
                              <div className="flex-fill text-center ">
                                {canEdit(object) ?<Link
                                  to={match ? `/addForm/${schoolId}/${object.id}` : `/franchise-addForm/${franchiseId}/${object.id}`}
                                  id={"edit-form" + key}
                                >

                                  <i className="font-size-18  bx bx-right-arrow"></i>
                                </Link> : "-"}
                              </div>
                            </td>
                            <td>
                              <div className="flex-fill text-center">
                                {canEdit(object) ? <Link
                                  to={"#"}
                                  onClick={() => {
                                    setFormToDelete(object);
                                    setShowDeleteAlert(true);
                                  }}
                                >
                                  <i className="font-size-18 bx bxs-trash text-danger"></i>
                                </Link> : "-"}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {displayList.length === 0 && (
                  <p className="text-center">No Forms to display !!</p>
                )}
              </CardBody>
            </Card>
          </Container>
        )}

        {createNewFormView && (
          <React.Fragment>
            {/* <TopBar
              title={"Create New Form"}
              buttonName={""}
              backButton={true}
              hideButton={true}
              onClickAdd={() => { }}
              onClickBackButton={handleOnClickBackButton}
            /> */}
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { handleOnClickBackButton() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=' m-2'>
                  <h4>Create New Form</h4>
                </div>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row>
                  <Col sm={7}>
                    <AvForm className="outer-repeater">
                      <div data-repeater-item className="outer">
                        <FormGroup>
                          <Label>
                            <h3 className="mt-5">
                              {props.t("Give this Form a name !!")}
                            </h3>{" "}
                          </Label>
                          <AvField
                            type="text"
                            name="name"
                            placeholder={props.t("Form Name")}
                            className="form-control"
                            value={formName}
                            onChange={(e) => {
                              if (e.target.value.length > 0 && nameError) {
                                setNameError(false);
                              }
                              setFormName(e.target.value);
                            }}
                          />
                          {nameError && (
                            <p className="text-danger">
                              {" "}
                              {props.t("Form name can not be empty")}
                            </p>
                          )}
                        </FormGroup>
                        {canShowCheckboxForAllSchool() && match && (
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-2 ml-3">
                              <input
                                type="checkbox"
                                className="custom-control-input ms-1"
                                id="instituteCheck"
                                onChange={() =>
                                  setForAllSchools(!forAllSchools)
                                }
                                checked={forAllSchools}
                              />
                              <label
                                className="custom-control-label ms-1"
                                onClick={() => {
                                  setForAllSchools(!forAllSchools);
                                }}
                              >
                                {props.t("Create this Form for all schools")}
                              </label>
                            </div>
                          </FormGroup>
                        )}
                        {franchiseMatch && (
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-2 ml-3">
                              <input
                                type="checkbox"
                                className="custom-control-input ms-1"
                                id="instituteCheck"
                                onChange={() => setForAllSchools(true)}
                                checked={forAllSchools}
                              />
                              <label
                                className="custom-control-label ms-1"
                                onClick={() => setForAllSchools(true)}
                              >
                                {props.t("Create this Form for all schools")}
                              </label>
                            </div>
                          </FormGroup>
                        )}
                        {franchiseMatch && (
                          <FormGroup>
                            <div className="custom-control custom-checkbox">
                              <input
                                type="checkbox"
                                className="custom-control-input ms-1"
                                id="forYourUse"
                                onChange={() => setForAllSchools(false)}
                                checked={!forAllSchools}
                              />
                              <label
                                className="custom-control-label ms-1"
                                onClick={() => setForAllSchools(false)}
                              >
                                {props.t("Create this Form for your use")}
                              </label>
                            </div>
                          </FormGroup>
                        )}

                      </div>
                    </AvForm>

                    <Button
                      onClick={() => {
                        setCreateNewFormInProgress(true);
                        createNewForm();
                      }}
                      color="primary"
                      disabled={createNewFormInProgress}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "180px",
                      }}
                    >
                      {createNewFormInProgress && (
                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                      )}
                      {props.t("Next")}
                    </Button>
                  </Col>
                  <Col sm={5}>
                    <div className="maintenance-img">
                      <img
                        src={contentImage}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
        )
        }

        {
          isLoading && (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                    Loading Forms...{" "}
                  </Link>
                </div>
              </Col>
            </Row>
          )
        }
      </div >
      {showDuplicateAlert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger "
          title="Alert!"
          onCancel={() => {
            setShowDuplicateAlert(undefined);
          }}
          onConfirm={() => {
            handleDuplicateForm(showDuplicateAlert);
            setShowDuplicateAlert(undefined);
          }}
        >
          {" Are you sure you want to duplicate " + showDuplicateAlert.attributes.name + "?"}<br></br>
        </SweetAlert>
      )}
    </React.Fragment >
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { teachers } = state.Teacher;
  const { userTeacher } = state.Login;


  return { selectedSchool, teachers, userTeacher };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getTeachersForSchool,


    })(FormDetailPage)
  )
);
