import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as SubjectHelper from "../../../../helpers/ParseHelpers/MarksCard/subject_helper";
import * as actionTypes from "./actionTypes";
import * as SubjectAction from "./actions";

  function* getSubjectsForExamId({ payload: { examId } }) {
    try {
      const response = yield call(SubjectHelper.getSubjectsForExamId, examId);
  
      if (response) {
        yield put(SubjectAction.updateSubjects(response));
      }
    } catch (error) {
      // yield put(apiError(error));
    } 
  }

export function* watchGetSubjectForExamId() {
  yield takeEvery(
    actionTypes.GET_SUBJECTS_FOR_EXAM_ID,
    getSubjectsForExamId
  );
}


function* subjectSaga() {
  yield all([fork(watchGetSubjectForExamId)]);
}
export default subjectSaga;
