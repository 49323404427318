import * as actions from "./actionTypes";

const initialState = {
  expenseCategories: [], isLoadingExpenseCategory: false
};

const ExpenseCategory = (state = initialState, action) => {
  switch (action.type) {


    case actions.UPDATE_EXPENSE_CATEGORIES:
      var expenseCategories = [...state.expenseCategories];
      if (expenseCategories.length > 0) {
        for (const latest in action.payload.expenseCategories) {
          var temp = expenseCategories.filter(
            (expenseCategory) =>
              expenseCategory.id === action.payload.expenseCategories[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.expenseCategories[latest].attributes.isDeleted) {

              var others = expenseCategories.filter(
                (expenseCategory) =>
                  expenseCategory.id !== action.payload.expenseCategories[latest].id
              );

              expenseCategories = others;
            } else {
              var index = expenseCategories.lastIndexOf(temp[0]);
              expenseCategories[index] = action.payload.expenseCategories[latest];
            }

          } else {
            if (!action.payload.expenseCategories[latest].attributes.isDeleted) {
              expenseCategories.push(action.payload.expenseCategories[latest]);
            }

          }
        }
        state = { ...state, expenseCategories: expenseCategories };
      } else {

        var others = action.payload.expenseCategories.filter(
          (expenseCategory) =>
            expenseCategory.attributes.isDeleted !== true
        );
        state = { ...state, expenseCategories: others };
      }

      break;



    case actions.UPDATE_EXPENSE_CATEGORY:
      var expenseCategories = [...state.expenseCategories];
      var temp = state.expenseCategories.filter(
        (expenseCategory) =>
          expenseCategory.id === action.payload.expenseCategory.id
      );
      if (temp.length > 0) {

        if (action.payload.expenseCategory.attributes.isDeleted) {

          var others = state.expenseCategories.filter(
            (expenseCategory) =>
              expenseCategory.id !== action.payload.expenseCategory.id
          );
          state = { ...state, expenseCategories: others };

        } else {
          var index = expenseCategories.indexOf(temp[0]);
          expenseCategories[index] = action.payload.expenseCategory;
          state = { ...state, expenseCategories: expenseCategories };
        }


      } else {
        if (!action.payload.expenseCategory.attributes.isDeleted) {
          expenseCategories.unshift(action.payload.expenseCategory);
          state = { ...state, expenseCategories: expenseCategories };
        }

      }
      break;

    case actions.UPDATE_LOADING_EXPENSE_CATEGORY:
      state = { ...state, isLoadingExpenseCategory: action.payload.boolValue };
      break

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ExpenseCategory;
