import React, { useEffect, useState } from 'react';

import { getTeachersForSchool, getClassroomsForSchoolId, sendInvite, setSelecetdSchool } from '../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Container, Row, Col } from "reactstrap";
import { matchPath } from "react-router";
//i18n
import { withNamespaces } from 'react-i18next';
import TeacherCard from './TeacherCard';
import TecaherList from './TecaherList';
import Topbar from './Topbar';
import error from "../../assets/images/error-img.png";
import * as Constants from '../../Constents';
import Parse from "parse";

const Index = (props) => {

    const [displayType, setType] = useState(Constants.DISPLAY_TYPE_LIST);
    const handleDisplay = (type) => {
        localStorage.setItem(Constants.TEACHER_DISPLAY_TYPE, type);
        setType(type);
    }

    useEffect(() => {
        if (localStorage.getItem(Constants.TEACHER_DISPLAY_TYPE)) {
            setType(localStorage.getItem(Constants.TEACHER_DISPLAY_TYPE));
        }
    });

    const match = matchPath(props.history.location.pathname, {
        path: "/covidvaccine/:schoolId/:covidsopid",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.schoolId;
    const covidsopId = match.params.covidsopid

    useEffect(() => {
        props.getTeachersForSchool(schoolId);
        props.getClassroomsForSchoolId(schoolId)
    }, [schoolId]);


    useEffect(() => {

        if (covidsopId) {
            fetchCovidSopObject(covidsopId)
        }

    }, [covidsopId])

    const fetchCovidSopObject = () => {
        if (covidsopId && covidsopId.length > 0) {
            var query = new Parse.Query("CovidSOPs");
            query.equalTo("objectId", covidsopId)
            query.limit(1000);
            query.first().then((result) => {
                if (result) {
                    setCovidSOPObject(result)
                }
            })
        }

    }

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const [isLoading, setIsLoading] = useState(true)
    const [directorList, setDirector] = useState([]);
    const [centerHeadList, setCenterHead] = useState([]);
    const [teacherList, setTeacher] = useState([]);
    const [otherStaffList, setOtherStaff] = useState([]);
    const [covidSOPObject, setCovidSOPObject] = useState(undefined)
    const firstDose = "firstDose"
    const secondDose = "secondDose"
    const totalTeacherCount = "totalTeacherCount"
    const totalTeacherVaccinatedCount = "totalTeacherVaccinatedCount"

    useEffect(() => {
        var tempList = [...props.teachers];

        let teacherIds = []
        for (const i in tempList) {
            teacherIds.push(tempList[i].id)
        }
        updateLists(tempList, [], teacherIds);
    }, [props.teachers]);


    const getDoseCounts = (dose, teacher, isForFirstTime) => {

        let isAddition = false

        if (dose === firstDose) {
            isAddition = !teacher.firstDose
        } else if (dose === secondDose) {
            isAddition = !teacher.secondDose
        }



        let vaccineCountObject = {
            firstDose: 0,
            secondDose: 0,
            totalTeacherCount: 0,
            totalTeacherVaccinatedCount: 0
        }





        for (var i = 0; i < directorList.length; i++) {
            var shouldAdd = false
            if (directorList[i].firstDose) {
                vaccineCountObject[firstDose] += 1
                shouldAdd = true
            }
            if (directorList[i].secondDose) {
                vaccineCountObject[secondDose] += 1
                shouldAdd = true

            }
            if (shouldAdd) {
                vaccineCountObject[totalTeacherVaccinatedCount] += 1
            }
            vaccineCountObject[totalTeacherCount] += 1
        }
        for (var i = 0; i < centerHeadList.length; i++) {
            var shouldAdd = false
            if (centerHeadList[i].firstDose) {
                vaccineCountObject[firstDose] += 1
                shouldAdd = true
            }
            if (centerHeadList[i].secondDose) {
                vaccineCountObject[secondDose] += 1
                shouldAdd = true
            }
            if (shouldAdd) {
                vaccineCountObject[totalTeacherVaccinatedCount] += 1
            }
            vaccineCountObject[totalTeacherCount] += 1
        }
        for (var i = 0; i < otherStaffList.length; i++) {
            var shouldAdd = false
            if (otherStaffList[i].firstDose) {
                vaccineCountObject[firstDose] += 1
                shouldAdd = true
            }
            if (otherStaffList[i].secondDose) {
                vaccineCountObject[secondDose] += 1
                shouldAdd = true
            }
            if (shouldAdd) {
                vaccineCountObject[totalTeacherVaccinatedCount] += 1
            }
            vaccineCountObject[totalTeacherCount] += 1
        }
        for (var i = 0; i < teacherList.length; i++) {
            var shouldAdd = false
            if (teacherList[i].firstDose) {
                vaccineCountObject[firstDose] += 1
                shouldAdd = true
            }
            if (teacherList[i].secondDose) {
                vaccineCountObject[secondDose] += 1
                shouldAdd = true
            }
            if (shouldAdd) {
                vaccineCountObject[totalTeacherVaccinatedCount] += 1
            }
            vaccineCountObject[totalTeacherCount] += 1
        }

        if (dose === firstDose) {
            if (isAddition) {
                vaccineCountObject[firstDose] += 1
                vaccineCountObject[totalTeacherVaccinatedCount] += 1
            } else {
                vaccineCountObject[firstDose] -= 1
                vaccineCountObject[totalTeacherVaccinatedCount] -= 1
                if (teacher.secondDose) {
                    vaccineCountObject[secondDose] -= 1
                }

            }
        }
        else if (dose === secondDose) {
            if (isAddition) {
                vaccineCountObject[secondDose] += 1
                if (isForFirstTime) {
                    vaccineCountObject[firstDose] += 1
                    vaccineCountObject[totalTeacherVaccinatedCount] += 1
                }

            } else {
                vaccineCountObject[secondDose] -= 1
            }
        }
        return vaccineCountObject
    }

    const setToCorrectList = (teacher, list) => {

        if (teacher.role === 3) {
            setDirector(list)
        } else if (teacher.role === 2) {
            setCenterHead(list)
        } else if (teacher.role === 1) {
            setTeacher(list)
        } else {
            setOtherStaff(list)
        }

    }
    const getCorrectList = (teacher) => {
        let returnValue = []
        if (teacher.role === 3) {
            returnValue = [...directorList]
        } else if (teacher.role === 2) {
            returnValue = [...centerHeadList]

        } else if (teacher.role === 1) {
            returnValue = [...teacherList]

        } else {
            returnValue = [...otherStaffList]

        }
        return returnValue
    }


    const handleEnableDisableDose = (teacherList, teacher, dose) => {

        if (!teacher.covidRecordObject) {
            enableForFirstTime(teacherList, teacher, dose, getDoseCounts(dose, teacher, true))
        } else if (teacher.covidRecordObject) {
            enableDisbleRecordObject(teacherList, teacher, dose, getDoseCounts(dose, teacher, false))
        }
    }

    const enableForFirstTime = (teacherList, teacher, dose, vaccineCountObject) => {

        let mainList = []
        let index = teacherList.indexOf(teacher)

        mainList = getCorrectList(teacher)
        if (dose === firstDose) {
            teacher.firstDose = true
        } else if (dose === secondDose) {
            teacher.secondDose = true
            teacher.firstDose = true
        }
        mainList[index] = teacher
        setToCorrectList(teacher, mainList)

        let CovidVaccineRecord = Parse.Object.extend("CovidVaccineRecord");
        let covidVaccineRecord = new CovidVaccineRecord();

        if (dose === firstDose) {
            covidVaccineRecord.set("firstDose", true);
        }
        else if (dose === secondDose) {

            covidVaccineRecord.set("firstDose", true);
            covidVaccineRecord.set("secondDose", true);
        }
        covidVaccineRecord.set("userType", "teacher")
        covidVaccineRecord.set("userId", teacher.id)
        covidVaccineRecord.save().then((result) => {
            teacher.covidRecordObject = result
            setToCorrectList(teacher, mainList)

            if (covidSOPObject && covidsopId && covidSOPObject.id === covidsopId) {

                covidSOPObject.set("CustomData", vaccineCountObject)
                covidSOPObject.save().then((result1) => {

                })
            }



        })
    }


    const enableDisbleRecordObject = (teacherList, teacher, dose, vaccineCountObject) => {

        let mainList = []
        let index = teacherList.indexOf(teacher)

        mainList = getCorrectList(teacher)
        if (dose === firstDose) {
            if (teacher.firstDose && teacher.secondDose) {
                teacher.secondDose = false

            }
            teacher.firstDose = !teacher.firstDose

        } else if (dose === secondDose) {
            teacher.secondDose = !teacher.secondDose
        }
        mainList[index] = teacher
        setToCorrectList(teacher, mainList)

        if (dose === firstDose) {

            if (teacher.covidRecordObject.attributes.firstDose && teacher.covidRecordObject.attributes.secondDose) {
                teacher.covidRecordObject.set(secondDose, false)
            }
            teacher.covidRecordObject.set(firstDose, !teacher.covidRecordObject.attributes.firstDose)



            teacher.covidRecordObject.save().then((result) => {

                if (covidSOPObject && covidsopId && covidSOPObject.id === covidsopId) {

                    covidSOPObject.set("CustomData", vaccineCountObject)
                    covidSOPObject.save().then((result) => {

                    })
                }
                teacher.covidRecordObject = result
                mainList[index] = teacher
                setToCorrectList(teacher, mainList)


            })

        } else if (dose === secondDose) {
            teacher.covidRecordObject.set(secondDose, !teacher.covidRecordObject.attributes.secondDose)
            teacher.covidRecordObject.save().then((result) => {
                if (covidSOPObject && covidsopId && covidSOPObject.id === covidsopId) {

                    covidSOPObject.set("CustomData", vaccineCountObject)
                    covidSOPObject.save().then((result) => {

                    })
                }
                teacher.covidRecordObject = result
                teacher.firstDose = result.attributes.firstDose
                teacher.secondDose = result.attributes.secondDose
                mainList[index] = teacher
                setToCorrectList(teacher, mainList)
            })
        }
    }


    function updateLists(tempList, covidRecordList, teacherIds) {
        var DList = [];
        var CList = [];
        var TList = [];
        var OList = [];

        for (const i in tempList) {
            let tempObject = {
                firstDose: false,
                secondDose: false,
                teacherObject: tempList[i],
                covidRecordObject: undefined,
                role: tempList[i].attributes.Role,
                id: tempList[i].id
            }
            if (covidRecordList && covidRecordList.length > 0) {
                for (const j in covidRecordList) {
                    if (covidRecordList[j].attributes.userId === tempList[i].id && covidRecordList[j].attributes.userType === "teacher") {
                        tempObject.covidRecordObject = covidRecordList[j]
                        tempObject.firstDose = covidRecordList[j].attributes.firstDose
                        tempObject.secondDose = covidRecordList[j].attributes.secondDose
                    }
                }
            }


            if (tempList[i].attributes.Role === 3) {
                DList.push(tempObject);
            } else if (tempList[i].attributes.Role === 2) {
                CList.push(tempObject);
            } else if (tempList[i].attributes.Role === 1) {
                TList.push(tempObject);
            } else {
                OList.push(tempObject);
            }

        }
        setDirector(DList);
        setCenterHead(CList);
        setTeacher(TList);
        setOtherStaff(OList);

        if (teacherIds && teacherIds.length > 0) {
            getCovidRecordsForTeacherIds(teacherIds)
        }





    }


    const getCovidRecordsForTeacherIds = (teacherIds) => {
        var query = new Parse.Query("CovidVaccineRecord");
        query.containedIn("userId", teacherIds)
        query.limit(1000);
        query.find().then((results) => {
            if (results && results.length > 0) {
                updateLists([...props.teachers], results)
            }
            setIsLoading(false)
        })
    }


    return (

        <React.Fragment>

            <div className="page-content">
                {!isLoading && <Container>
                    <Topbar title={props.t('Vaccine Overview')} schoolId={schoolId} hideButton={true} backButton={true} onClickBackButton={() => { window.history.back(); }} />
                    <Row>
                        <Col>
                            {directorList && directorList.length > 0 && <TecaherList teachers={directorList} classrooms={props.classrooms} schoolId={schoolId} role={props.t('Directors')} handleEnableDisableDose={(teacherList, teacherObject, dose) => { handleEnableDisableDose(teacherList, teacherObject, dose) }} />}
                            {centerHeadList && centerHeadList.length > 0 && <TecaherList teachers={centerHeadList} classrooms={props.classrooms} schoolId={schoolId} role={props.t('Center Heads')} handleEnableDisableDose={(teacherList, teacherObject, dose) => { handleEnableDisableDose(teacherList, teacherObject, dose) }} />}
                            {teacherList && teacherList.length > 0 && <TecaherList teachers={teacherList} classrooms={props.classrooms} schoolId={schoolId} role={props.t('Teachers')} handleEnableDisableDose={(teacherList, teacherObject, dose) => { handleEnableDisableDose(teacherList, teacherObject, dose) }} />}
                            {otherStaffList && otherStaffList.length > 0 && <TecaherList teachers={otherStaffList} classrooms={props.classrooms} schoolId={schoolId} role={props.t('Other Staff')} handleEnableDisableDose={(teacherList, teacherObject, dose) => { handleEnableDisableDose(teacherList, teacherObject, dose) }} />}
                        </Col>
                    </Row>
                </Container>}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Teacher Vaccine Status...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>

        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { teachers } = state.Teacher;
    const { schools, selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    return { teachers, schools, classrooms, selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getTeachersForSchool, getClassroomsForSchoolId, setSelecetdSchool, sendInvite })(Index)));