import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Badge,
  CardTitle,
  Container,
  Table,
  Progress,
  Media,
  UncontrolledTooltip,
  CardImg,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Alert
} from "reactstrap";
import toastr from "toastr";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import UpdatePhoto from "../../components/Common/UpdatePhoto";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";

import { Parse } from "parse";

import disImg from "../../assets/images/undraw_medical_care_movn.svg"



//Initial Setup
//UI
//Validations
//Saving Part
//Handler Functions
//Utility Functions.

const Index = (props) => {
  const matchForWebsite = matchPath(props.history.location.pathname, {
    path: "/letsfighttogether",
    exact: true,
    strict: false,
  });
  const matchForApp = matchPath(props.history.location.pathname, {
    path: "/letsfighttogether/app",
    exact: true,
    strict: false,
  });

  const [loading, setLoading] = useState(false);
  const [showFromAutometically, setShowFromAutometically] = useState(true);
  const [name, setName] = useState(undefined);
  const [phoneNumber, setPhoneNumber] = useState(undefined);
  const [email, setEmail] = useState(undefined);
  const [ageGroup, setAgegroup] = useState([]);
  const [ageGroupError, setAgegroupError] = useState(false);

  const [title, setTitle] = useState("Enter your area pincode in search bar to check vaccine availability");

  const [oldPincode, setOldPincode] = useState(undefined);
  const [pincode, setPincode] = useState(undefined);
  const [pincodeError, setPincodeError] = useState(false);
  const [currentPage ,setCurrentPage] = useState("search");
  const [pincodeRes, setPincodeRes] = useState(undefined);
  const [showModal, setShowModal] = useState(false);
  const [success_dlg, setsuccess_dlg] = useState(false);
  const AGE_GROUP=["0-18","18-45","45-60","60+"]
  
  //Validation Before Save
  const isFormValid = () => {
    saveForm();
  };

  //Saving Form

  const saveForm = () => {
    //Creating Objects
    // const ParseCowinObject = Parse.Object.extend("Cowin");
    // var parseCowinObj = new ParseCowinObject();
    // parseCowinObj.set("name", name);
    // parseCowinObj.set("pincode", pincode);
    // parseCowinObj.set("phoneNumber", phoneNumber);
    // parseCowinObj.set("email", email);
    // parseCowinObj.set("ageGroup", ageGroup);
    // parseCowinObj.set("source", "website");
    // if (matchForApp) {
    //   parseCowinObj.set("source", "app");
    // }
    // parseCowinObj.save().then(
    //   (result) => {},
    //   (error) => {
    //     console.log("saving error");
    //   }
    // );
    setLoading(true);
    setsuccess_dlg(false);
    
    Parse.Cloud.run("function_cowinRegistraion", {
      name: name,
      phoneNumber: phoneNumber,
      email: email,
      pincode: pincode,
      ageGroup: ageGroup,
      source: matchForApp?"app":"website",
    }).then((res) => {
      if (res !== "error") {
        setLoading(false);
        setShowModal(false);
        setShowFromAutometically(false);
        setsuccess_dlg(res);
      }
      
    },(error) => {
        console.log("saving error");
    });    
  };

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const handelPinCodeSearch = (pincode) => {
    

    setTitle("Searching for vacination centers...." )
    let date = new Date();
    let mYear = date.getFullYear();
    let mMonth = date.getMonth() + 1;
    let mDay = date.getDate();

    var url = "https://cdn-api.co-vin.in/api/v2/appointment/sessions/public/calendarByPin?pincode=" + pincode + "&date=" + mDay + "-" + mMonth + "-" + mYear;


    fetch(url).then((response) => {
      if (response.status !== 200) {
        console.log('Looks like there was a problem. Status Code: ' + response.status);
        setTitle('Looks like there was a problem. Status Code: ' + response.status)
        return;
      }
      // Examine the text in the response
      response.json().then((data) => {
        setPincodeRes(data.centers);
        if (data.centers.length===0) {
          setTitle("No vacination centers found for pincode "+pincode)
        } else {
          setTitle("Vacination Centers:");
          if (showFromAutometically && !showModal) {
             setTimeout(() => {
             setLoading(false);
             setShowModal(true);
             }, 5000)
          }
         
        }
      });
    }
    )
      .catch((err) => {
        console.log('Fetch Error :-S', err);
      });

  }


  return (
    <React.Fragment>
      <Container fluid={true}>


        {

          currentPage === "search" && <div>

            <Card className="m-2" >

              <CardBody >
                <Row>
                
                  <Col lg={12}>
                    <Row className="" >
                      {/* <CardImg top className="img-fluid avatar-md" src={disImg}  /> */}
                      <h3> Search for vaccine availability in  your area</h3>
                    </Row>
                  {/* </Col> */}
                  
                  {/* <Col lg={6} md={6} xs={12}> */}
                    <div className=" form-inline" >
                      <div className="search-box mr-2 ">
                        <div className="position-relative">
                          <Input
                            type="number"
                            className="form-control "
                            placeholder="Pincode..."
                            name="pincode"
                            value={pincode}
                            error={pincodeError }
                            errorMessage="Enter pincode"
                            onChange={(e) => {
                              setPincode(e.target.value)
                              if (pincodeError && e.target.value && e.target.value.length>0) {
                                setPincodeError(false);
                              }
                            }}     
                          />
                          <i className="bx bx-search-alt search-icon"></i>
                        </div>
                      </div>
                      
                      <Button
                        color="primary"
                        onClick={() => {
                          if (!pincode || pincode.length === 0) {
                            setPincodeError(true);
                          }else if (pincode !== oldPincode) {
                            setOldPincode(pincode);
                            handelPinCodeSearch(pincode);
                          }
                          
                        }}
                        className="btn btn-primary"
                      >
                        Search
                      </Button>
                    </div>

                    {/* {<p className="text-danger float-right ">{ pincodeError}</p>} */}
                    
                  </Col>
                </Row>
              </CardBody>

            </Card>

            
            <Card className="m-2">
              <CardBody>

                {pincodeRes && pincodeRes.length > 0 && <Alert color="primary">
                  Do you want to receive notification on vaccine availability  <Label onClick={() => {
                            setLoading(false);
                            setShowModal(true);
                          }}className="alert-link"> click here</Label>
                </Alert>}
                
                <CardTitle> {title}</CardTitle>
                {/* {pincodeRes && pincodeRes.length > 0 && <Row className="float-right">
                  <span><h6 className="">{"Do you want to receive notification on vaccine availability "}</h6></span>
                      <span><h6 className="text-primary ml-2 mr-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setLoading(false);
                            setShowModal(true);
                          }}>
                          {" click here"}
                      </h6></span>
                </Row>
                } */}
                {pincodeRes && pincodeRes.map((center, key) => (
                  <div key={center.center_id}>
                    <h5 className="mt-3">  {(key+1)+". " + center.name}</h5>

                    <div className="table-responsive">
                      <Table className="table mb-0 table-bordered">
                        <tbody>
                          <tr>
                            <th scope="row">Center Details:</th>
                            <td>

                              <h6 >Center ID : <span className="text-muted mr-2"></span> <b>{center.center_id}</b></h6>
                              <h6 >Center Address : <span className="text-muted mr-2"></span> <b>{center.address}</b></h6>
                              <h6>Timings : <span className="text-muted mr-2"></span> <b>{center.from + " to " + center.to}</b></h6>
                              <h6>Fee Type : <span className="text-muted mr-2"></span> <b>{center.fee_type}</b></h6>

                            </td>
                          </tr>
                          <tr>
                            <th scope="row">Next Seeions :</th>
                            <td></td>
                          </tr>
                          {
                            center.sessions.map((session) => (
                              <tr key={session.session_id}>
    
                                <th scope="row">
                                  {"Date: " + session.date}
                                </th>
                                <td >
                                  <h6 >Available Capacity : <span className="text-muted mr-2"></span> <b>{session.available_capacity}</b></h6>
                                  <h6 >Min Age Limit : <span className="text-muted mr-2"></span> <b>{session.min_age_limit}</b></h6>
                                  <h6>Vaccine : <span className="text-muted mr-2"></span> <b>{session.vaccine}</b></h6>
                                  <h6 >Slots : <span className="text-muted mr-2"></span> <b>{session.slots &&
                                    session.slots.map((slot, key) => (
                                      <Badge
                                        key={key}
                                        className={
                                          "badge badge-soft-primary font-size-12 ml-1"
                                        }
                                      >
                                        {slot}
                                      </Badge>
                                    ))}
                                  </b></h6>
                                </td>
                              </tr>
                            ))
                          }
                        </tbody>
                      </Table>
                    </div>
                  </div>
                ))
                }
              </CardBody>
            </Card>            
          </div>
 
        }

        {/* {showFromAutometically && (
          <div>
            <Card>
              <CardBody>
                <AvForm className="outer-repeater" style={{ border: "1px" }}>
                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      <h5>
                        <i
                          className="fa fa-check text-success mr-2"
                          aria-hidden="true"
                        ></i>
                        Successfully submitted the form !!
                      </h5>
                    </div>
                  </div>
                </AvForm>
              </CardBody>
            </Card>
          </div>
        )} */}


        <Modal isOpen={showModal} scrollable={true}>
          <ModalHeader isOpen={showModal}>
            <div className="modal-title mt-0">Registration</div>
          </ModalHeader>
          <ModalBody>
            <Row>
            
              <h6 className="ml-2">{"Do you want to receive notification on vaccine  availability please register "}</h6>
              <Col lg={12} className="mx-auto">
                <AvForm
                  className="outer-repeater"
                  style={{ border: "1px" }}
                  onValidSubmit={(e, v) => {
          
            
                    if (ageGroup.length > 0) {
                      isFormValid();
                    } else {
                      setAgegroupError(true);
                    }
                    
         
       
                  }}
                  
                >
                  <div data-repeater-list="outer-group" className="outer">
                    <div data-repeater-item className="outer">
                      

                      <FormGroup>
                        <Label htmlFor="cowinformSingleLine">Name</Label>

                        <AvField
                          type="text"
                          id={"cowinformSingleLineName"}
                          name={"cowinformSingleLineName"}
                          placeholder={"Type Name here"}
                          className="form-control"
                          required
                          errorMessage="Please enter your name"
                          onChange={(e) => {
                            setName(e.target.value);
                          }}
                        />
                      </FormGroup>
                      <FormGroup>
                        <Label htmlFor="cowinformSingleLinePhone">
                          Phone Number
                        </Label>

                        <AvField
                          type="text"
                          id={"cowinformSingleLinePhone"}
                          name={"cowinformSingleLinePhone"}
                          placeholder={"Type number here"}
                          className="inner form-control noscroll"
                          onChange={(e) => {
                            setPhoneNumber(e.target.value);
                          }}
                          required={(phoneNumber && phoneNumber.length > 0 || email && email.length > 0) ? false : true}
                          errorMessage="Please enter your phone number"
                          validate={{
                            pattern: {
                              value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                              errorMessage: props.t(
                                "You have entered an invalid phone no!"
                              ),
                            },
                            minLength: { value: 10 },
                            maxLength: { value: 16 },
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="cowinformSingleLineEmail">
                          Email
                        </Label>

                        <AvField
                          type="email"
                          id={"cowinformSingleLineEmail"}
                          name={"cowinformSingleLineEmail"}
                          placeholder={"Type email here"}
                          className=" form-control "
                          required={(phoneNumber && phoneNumber.length > 0 || email && email.length > 0) ? false : true}
                          errorMessage="Please enter your email"
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                          validate={{
                            pattern: {
                              value:
                                "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                              errorMessage: props.t(
                                "You have entered an invalid email address!"
                              ),
                            },
                          }}
                        />
                      </FormGroup>

                      <FormGroup>
                        <div className="widget mb-2 pb-2">
                          <Label className="">Age Group</Label>
                          {
                              AGE_GROUP.map((age, key) => (
                                <div className="form-check ml-2 ">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    id={"cowinCheckbox1" + age}
                                    value={age}
                                    required
                                    checked={
                                      ageGroup && ageGroup.includes(age)
                                    }
                                    onChange={() => {
                                      if (ageGroup.includes(age)) {
                                        let tempList = [...ageGroup];
                                        removeElement(tempList, age);
                                        setAgegroup(tempList);
                                      } else {
                                        let tempList = [...ageGroup];
                                        tempList.push(age);
                                        setAgegroup(tempList);
                                        if (ageGroupError) {
                                          setAgegroupError(false);
                                        }
                                        
                                      }
                                    }}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor={"cowinCheckbox1" + age}
                                  >
                                    {age}
                                  </label>
                                </div>
                              ))
                            }
                            
                            
                        </div>
                        {ageGroupError&&<p className="text-danger">Please choose your age group</p>}
                      </FormGroup>

                      <FormGroup>
                        <Label htmlFor="cowinformSingleLinePincode">
                          Pincode
                        </Label>

                        <AvField
                          type="number"
                          id={"cowinformSingleLinePincode"}
                          name={"cowinformSingleLinePincode"}
                          placeholder={"Type pincode here"}
                          className="form-control"
                          required
                          value={pincode}
                          onChange={(e) => {
                            setPincode(e.target.value);
                          }}
                        />
                      </FormGroup>

                      <div style={{ display: "flex" } } className="float-right">

                        {!loading ? (
                            <div >
                              <button
                                type="button"
                                className="btn btn-secondary mr-2"
                                onClick={() => {
                                  setShowFromAutometically(false);
                                  setShowModal(false);
                                }}
                              >
                                {props.t("Close")}
                              </button>
                              <Button
                                style={{
                                  marginLeft: "auto",
                                  marginRight: "auto",
                                  width: "180px",
                                }}
                                type="submit"
                                color="primary"
                              >
                                {props.t("SUBMIT")}
                              </Button>
                            </div>
                          ) : (
                              <button type="button" className="btn btn-light waves-effect">
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                                {props.t(" Saving....")}
                              </button>
                            )}
                        
                      </div>
                    </div>
                  </div>
                </AvForm>
            </Col>
          </Row>

          </ModalBody>

          
        </Modal>

        {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('OK')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    
                    {success_dlg === "Updated"?props.t('Data updated successfully'):props.t('Data saved successfully ')}
                </SweetAlert>
            ) : null}

        
      </Container>
    </React.Fragment>
  );
};



export default withNamespaces()(Index);
