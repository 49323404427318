export const UPDATE_FEE_TEMPLATE = "UPDATE_FEE_TEMPLATE";
export const DELETE_FEE_TEMPLATE = "DELETE_FEE_TEMPLATE";
export const EDIT_FEE_TEMPLATE = "EDIT_FEE_TEMPLATE";

export const UPDATE_FEE_TEMPLATES = "UPDATE_FEE_TEMPLATES";   
export const GET_FEE_TEMPLATES = "GET_FEE_TEMPLATES";
export const GET_FEE_TEMPLATE = "GET_FEE_TEMPLATE";
export const CREATE_FEETEMPLATE_FOR_SCHOOLID = "CREATE_FEETEMPLATE_FOR_SCHOOLID";
export const SET_LOADER_OFF = "SET_LOADER_OFF";
export const DELETE_A_TEMPLATE_FOR_SCHOOL = "DELETE_A_TEMPLATE_FOR_SCHOOL";
export const UPDATE_AFTER_DELETE = "UPDATE_AFTER_DELETE";
export const GET_FEETYPE = "GET_FEETYPE"
export const SET_FEETYPE = "SET_FEETYPE"
