

import React, { useState, useEffect } from "react";
import {
    setSelecetdSchool,
    getTeachersForSchool
} from "../../store/actions";
import Map from "../../components/Common/Map";
import toastr from "toastr"

import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    Container,
    Button,
    Alert,
} from "reactstrap";

import DatePicker from "react-datepicker";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Images
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";
import { el } from "date-fns/locale";

const AddPrograms = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/tripAdd/:schoolId",
        exact: true,
        strict: false,
    });
    const matchEditTrip = matchPath(props.history.location.pathname, {
        path: "/tripAdd/:schoolId/:tripId/:groupId",
        exact: true,
        strict: false,
    });


    const nm = match ?? matchEditTrip;
    const schoolId = nm && nm.params.schoolId;
    const tripId = matchEditTrip && matchEditTrip.params.tripId
    const groupId = matchEditTrip && matchEditTrip.params.groupId;


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }

    }, [props.selectedSchool]);


    var defaultStartTime = new Date();
    defaultStartTime.setHours(9, 0, 0, 0);

    var defaultEndTime = new Date();
    defaultEndTime.setHours(19, 0, 0, 0);

    var defaultMinTime = new Date();
    defaultMinTime.setHours(0, 0, 0, 0);
    var defaultMaxTime = new Date();
    defaultMaxTime.setHours(23, 59, 0, 0);


    const [newTripName, setNewTripName] = useState("");
    const [newTripNameError, setNewTripNameError] = useState(false);

    const [tripType, setTripType] = useState();
    const [tripTypeError, setTripTypeError] = useState(false);

    const [tripFrequency, setTripFrequency] = useState();
    const [tripFrequencyError, setTripFrequencyError] = useState();

    const [vehicleInfo, setVehicleInfo] = useState("")
    const [vehicleInfoError, setVehicleInfoError] = useState(false);

    const [vehicleNumber, setVehicleNumber] = useState("");
    const [vehicleNumberError, setVehicleNumberError] = useState(false);

    const [driver, setDriver] = useState();
    const [driverError, setDriverError] = useState(false);

    const [helper, setHelper] = useState();
    const [helperError, setHelperError] = useState(false);

    const [tripStartTime, setTripStartTime] = useState(defaultStartTime);
    const [tripStartTimeError, setTripStartTimeError] = useState(false);

    const [tripEndTime, setTripEndTime] = useState(defaultEndTime);
    const [tripEndTimeError, setTripEndTimeError] = useState(false);

    const [isDeviceBased, setIsDeviceBased] = useState(false);
    const [deviceNumber, setDeviceNumber] = useState("");
    const [deviceNumberError, setDeviceNumberError] = useState(false);

    const [isMapsVisible, setIsMapsVisible] = useState(false);
    const defaultLatitude = 12.9675873;
    const defaultLongitude = 77.6446674;


    const [pickupLocationError, setPickupLocationError] = useState(false);


    const [dropLocationError, setDropLocationError] = useState(false);

    const [isLoading, setIsLoading] = useState(false);
    const [tripObject, setTripObject] = useState();
    const [tripName, setTripName] = useState();
    const [groupObject, setGroupObject] = useState();

    const [driverList, setDriverList] = useState([]);
    const [helperList, setHelperList] = useState([]);

    const [existingDropLocation, setExistingDropLocation] = useState({ latitude: undefined, longitude: undefined });
    const [existingPickupLocation, setExistingPickupLocation] = useState({ latitude: undefined, longitude: undefined });

    const PICKUP = "Pick Up"
    const DROP = "Drop"
    const tripTypeList = [PICKUP, DROP]
    const tripFrequencyList = [
        { value: "1", name: "Weekdays" },
        { value: "2", name: "All week" },
        { value: "3", name: "Weekends" },
        { value: "4", name: "Weekdays+Sat" },
        { value: "5", name: "Weekdays+Sun" },
    ]

    const onBack = () => {
        window.history.back()
    }

    useEffect(() => {
        if (
            schoolId &&
            (!props.selectedSchool || props.selectedSchool.id !== schoolId)
        ) {
            props.setSelecetdSchool(schoolId);
        }
        props.getTeachersForSchool(schoolId);

        if (groupId && groupId.length > 0) {
            getGroupObject()
        }
        if (tripId && tripId.length > 0) {
            getTripObject()
        }
    }, [props.selectedSchool, schoolId, groupId, tripId]);

    const getGroupObject = () => {
        var query = new Parse.Query('Group');
        query.equalTo('objectId', groupId);
        query.limit(1000);
        query.find().then((results) => {
            if (results && results.length > 0) {
                setGroupObject(results[0])
            }
        })
    }
    const getTripObject = () => {
        var query = new Parse.Query('Trip');
        query.equalTo('objectId', tripId);
        query.limit(1000);
        query.find().then((results) => {
            if (results && results.length > 0) {
                setTripObject(results[0])
                fillInitialDetails(results[0])
            }
        })
    }

    const fillInitialDetails = (tripObject) => {

        if (tripObject) {
            let ta = tripObject.attributes
            setNewTripName(ta.name)
            setTripFrequency(ta.frequency ?? undefined)
            setTripType(ta.tripType === 1 ? PICKUP : DROP)

            if (ta.tripType === 1) {
                setVehicleInfo(ta.pickupVehicleInfo)
                setVehicleNumber(ta.pickupVehicleNo)
                // setDriver(ta.pickupDriverId)
                // setHelper(ta.pickupHelperId)
            } else {
                setVehicleInfo(ta.dropVehicleInfo)
                setVehicleNumber(ta.dropVehicleNo)
                // setDriver(ta.dropDriverId)
                // setHelper(ta.dropHelperId)
            }

            setTripStartTime(ta.startTime)
            setTripEndTime(ta.endTime)
            setIsDeviceBased(ta.isDeviceBased)
            if (ta.isDeviceBased) {
                setDeviceNumber(ta.deviceId)
                setIsMapsVisible(true)


                setExistingPickupLocation({
                    latitude: ta.startLocation.latitude,
                    longitude: ta.startLocation.longitude
                })
                setExistingDropLocation(
                    {
                        latitude: ta.finishPoint[0].latitude,
                        longitude: ta.finishPoint[0].longitude
                    }
                )
            }

        }
    }

    useEffect(() => {
        if (props.teachers) {
            let helpers = []
            let drivers = []

            for (const teacher of props.teachers) {
                if (teacher.attributes.Role && teacher.attributes.Role === 5) {
                    drivers.push(teacher)
                }
                else if (teacher.attributes.Role && teacher.attributes.Role === 6) {
                    helpers.push(teacher)
                }
            }
            drivers.push({ id: "addDriver", name: "Add Driver" })
            helpers.push({ id: "addHelper", name: "Add Helper" })
            setDriverList(drivers)
            setHelperList(helpers)

            if (tripObject && tripObject.attributes.tripType === 1) {

                let driverObj = drivers.filter((d) =>
                    d.id == tripObject.attributes.pickupDriverId
                )
                if (driverObj.length == 0) {
                    setDriver(undefined)
                } else {
                    setDriver(tripObject.attributes.pickupDriverId)
                }

                //helper
                let helperObj = helpers.filter((d) =>
                    d.id == tripObject.attributes.pickupHelperId
                )
                if (helperObj.length == 0) {
                    setHelper(undefined)
                } else {
                    setHelper(tripObject.attributes.pickupHelperId)
                }

            } else if (tripObject && tripObject.attributes.tripType != 1) {

                let driverObj = drivers.filter((d) =>
                    d.id == tripObject.attributes.dropDriverId
                )
                if (driverObj.length == 0) {
                    setDriver(undefined)
                } else {
                    setDriver(tripObject.attributes.dropDriverId)
                }

                //helper
                let helperObj = helperList.filter((d) =>
                    d.id == tripObject.attributes.dropHelperId
                )
                if (helperObj.length == 0) {
                    setHelper(undefined)
                } else {
                    setHelper(tripObject.attributes.dropHelperId)
                }
            }
        }
    }, [props.teachers, tripObject])

    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }


    const verifyValues = () => {

        let canProceed = true

        if (newTripName === "" || newTripName.trim().length === 0) {
            setIsLoading(false);
            canProceed = false
            setNewTripNameError(true)
        } else {
            setNewTripNameError(false)
        }
        if (!tripType) {
            setTripTypeError(true)
            canProceed = false
        } else {
            setTripTypeError(false)
        }
        if (!tripFrequency) {
            setTripFrequencyError(true)
            canProceed = false
        } else {
            setTripFrequencyError(false)
        }
        if (vehicleInfo === "" || vehicleInfo.trim().length === 0) {
            setVehicleInfoError(true)
            canProceed = false
        } else {
            setVehicleInfoError(false)
        }
        if (vehicleNumber === "" || vehicleNumber.trim().length === 0) {
            setVehicleNumberError(true)
            canProceed = false;
        } else {
            setVehicleNumberError(false)
        }
        if ((!driver || driver.length === 0) && (!helper || helper.length === 0)) {
            setDriverError(true)
            setHelperError(true)
            toastr.warning("Please select driver/helper !!", { timeOut: 2000 });
            canProceed = false
        } else {
            setDriverError(false)
            setHelperError(false)

        }
        if (tripStartTime == null) {
            setTripStartTimeError(true)
            canProceed = false
        } else {
            setTripStartTimeError(false)
        }
        if (tripEndTime == null) {
            setTripEndTimeError(true)
            canProceed = false
        } else {
            setTripEndTimeError(false)
        }
        if (isDeviceBased) {
            if ((deviceNumber === "" || deviceNumber.trim().length === 0)) {
                setDeviceNumberError(true)
                canProceed = false
            } else {
                setDeviceNumberError(false)
            }


            // if ((existingPickupLocation.latitude)) {
            //     setPickupLocationError(true)
            //     canProceed = false
            // } else {
            //     setPickupLocationError(false)
            // }
        }

        return canProceed
    };

    const saveGroup = (group) => {
        setIsLoading(true)

        if (tripName !== newTripName) {
            const Group = Parse.Object.extend('Group');
            let groupObjectToSave = group ?? new Group()
            if (!matchEditTrip) {
                groupObjectToSave.set("schoolId", schoolId);
                groupObjectToSave.set("ownerId", Parse.User.current().id);
                groupObjectToSave.set("name", `trip:${newTripName}`);
                groupObjectToSave.set("participantsCount", 0);
                groupObjectToSave.set("type", 3);
            }
            groupObjectToSave.set("name", `trip:${newTripName}`);
            groupObjectToSave.save().then(
                (result) => {
                    saveTrip(tripObject, result.id)
                },
                (error) => {
                    setIsLoading(false)
                    window.history.back()
                    toastr.warning("Error, Please try again !!")
                }
            );
        } else {
            saveTrip(tripObject)
        }

    }

    const saveTrip = (trip, groupId) => {
        const Trip = Parse.Object.extend('Trip');
        let tripObjectToSave = trip ?? new Trip()
        tripObjectToSave.set("name", newTripName)
        tripObjectToSave.set("tripType", tripType === PICKUP ? 1 : 2)

        if (tripType === PICKUP) {
            tripObjectToSave.set("pickupVehicleInfo", vehicleInfo)
            tripObjectToSave.set("pickupVehicleNo", vehicleNumber)
            tripObjectToSave.set("pickupDriverId", driver)
            tripObjectToSave.set("pickupHelperId", helper)


            tripObjectToSave.set("dropVehicleInfo", undefined)
            tripObjectToSave.set("dropVehicleNo", undefined)
            tripObjectToSave.set("dropDriverId", undefined)
            tripObjectToSave.set("dropHelperId", undefined)
        } else if (tripType === DROP) {
            tripObjectToSave.set("pickupVehicleInfo", undefined)
            tripObjectToSave.set("pickupVehicleNo", undefined)
            tripObjectToSave.set("pickupDriverId", undefined)
            tripObjectToSave.set("pickupHelperId", undefined)


            tripObjectToSave.set("dropVehicleInfo", vehicleInfo)
            tripObjectToSave.set("dropVehicleNo", vehicleNumber)
            tripObjectToSave.set("dropDriverId", driver)
            tripObjectToSave.set("dropHelperId", helper)

        }





        tripObjectToSave.set("frequency", tripFrequency)

        tripObjectToSave.set("startTime", tripStartTime)
        tripObjectToSave.set("endTime", tripEndTime)
        tripObjectToSave.set("isDeviceBased", isDeviceBased)
        if (match) {
            tripObjectToSave.set("participantsCount", 0);
        }
        if (isDeviceBased) {
            tripObjectToSave.set("deviceId", deviceNumber)
            // let startLocation = new Parse.GeoPoint(existingPickupLocation.latitude, existingPickupLocation.longitude);
            // tripObjectToSave.set("startLocation", startLocation)
            // let finishPoint = new Parse.GeoPoint(existingDropLocation.latitude, existingDropLocation.longitude);
            // tripObjectToSave.set("finishPoint", [finishPoint])
        }

        tripObjectToSave.set("schoolId", schoolId)
        if (groupId && groupId.length > 0) {
            tripObjectToSave.set("groupId", groupId)
        }
        tripObjectToSave.save().then(
            (result) => {
                setIsLoading(false)
                window.history.back()
            },
            (error) => {
                setIsLoading(false)
                window.history.back()
                toastr.warning("Error, Please try again !!")
            }
        );

    }



    return (
        <React.Fragment>
            <Container fluid={true} className={"page-content"}>
                {(isLoading) && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Label className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Trips...{" "}
                                </Label>
                            </div>
                        </Col>
                    </Row>
                )}

                {!(isLoading) && (
                    <Row>
                        <Col lg={12} className="mx-auto">
                            <Card>
                                <CardBody>
                                    <Row className='d-flex align-items-start'>
                                        <Col className="d-flex align-items-start">
                                            <div
                                                style={{ cursor: "pointer" }}
                                                onClick={() => { window.history.back() }}>
                                                <ul className=" list-unstyled">
                                                    <div className=''>
                                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                                    </div>
                                                </ul>
                                            </div>
                                            <div className=' m-2'>
                                                <h4>  {(matchEditTrip) ? "Edit Trip" : "Add Trip"}</h4>
                                            </div>
                                        </Col>
                                        <Col className='d-flex justify-content-end'>
                                            <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                                <button
                                                    className="btn btm-sm btn-primary waves-effect waves-light mt-0 mr-2"
                                                    onClick={() => {
                                                        if (verifyValues()) {
                                                            saveGroup(groupObject)
                                                        }
                                                    }}
                                                    style={{ float: "right" }}
                                                >
                                                    Save
                                                </button>
                                            </div>
                                        </Col>
                                    </Row>
                                    {((driverList.length == 1 && helperList.length == 1)) && <Alert
                                        color="danger"
                                        role="alert"
                                    >
                                        <p className="lead mb-1"><strong><u>Alert</u></strong></p>
                                        <p className="mb-0">
                                            There are no Drivers or Helpers in your school staff, Please add one and proceed.
                                        </p>
                                        <Link to={"/addstaff/" + schoolId}><u>Add Driver/Helper</u></Link>


                                    </Alert>
                                    }

                                    <hr />
                                    <AvForm>
                                        <Row lg={12}>
                                            <Col lg={12}>
                                                <Label>Trip Name</Label>
                                                <Label className="text-danger">*</Label>
                                                {newTripNameError === true && (
                                                    <Label className="label-xsmall text-danger ml-2">
                                                        (this field is mandatory)
                                                    </Label>
                                                )}
                                                <AvField
                                                    type="text"
                                                    name="tripNameAv"
                                                    id="tripNameAv"
                                                    placeholder="Trip Name"
                                                    className="form-control"
                                                    value={newTripName}
                                                    onChange={(e) => {
                                                        setNewTripName(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row lg={12}>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label className="">Trip Type</Label>
                                                    <Label className="text-danger">*</Label>
                                                    {tripTypeError === true && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <select
                                                        className="form-control"
                                                        value={tripType}
                                                        onChange={(e) => {
                                                            setTripType(e.target.value);
                                                        }}
                                                    >
                                                        <option disabled selected value>Select Trip Type</option>
                                                        {tripTypeList.map((object, key) => (
                                                            <option key={key} value={object}>{object}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label className="">Trip Frequency</Label>
                                                    <Label className="text-danger">*</Label>
                                                    {tripFrequencyError === true && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <select
                                                        className="form-control"
                                                        value={tripFrequency}
                                                        onChange={(e) => {
                                                            setTripFrequency(e.target.value);
                                                        }}
                                                    >
                                                        <option disabled selected value>Select Trip Frequency</option>
                                                        {tripFrequencyList.map((object, key) => (
                                                            <option key={key} value={object.value}>{object.name}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                        </Row>
                                        <Row lg={12}>
                                            <Col lg={6}>
                                                <Label>Vehicle Details</Label>
                                                <Label className="text-danger">*</Label>
                                                {vehicleInfoError === true && (
                                                    <Label className="label-xsmall text-danger ml-2">
                                                        (this field is mandatory)
                                                    </Label>
                                                )}
                                                <AvField
                                                    type="text"
                                                    name="vehicleDetailsAv"
                                                    id="vehicleDetailsAv"
                                                    placeholder="Vehicle Details"
                                                    className="form-control"
                                                    value={vehicleInfo}
                                                    onChange={(e) => {
                                                        setVehicleInfo(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                            <Col lg={6}>
                                                <Label>Vehicle Number</Label>
                                                <Label className="text-danger">*</Label>
                                                {vehicleNumberError === true && (
                                                    <Label className="label-xsmall text-danger ml-2">
                                                        (this field is mandatory)
                                                    </Label>
                                                )}
                                                <AvField
                                                    type="text"
                                                    name="vehicleNumberAv"
                                                    id="vehicleNumberAv"
                                                    placeholder="Vehicle Number"
                                                    className="form-control"
                                                    value={vehicleNumber}
                                                    onChange={(e) => {
                                                        setVehicleNumber(e.target.value);
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg={12}> <p className="text-info"><u>{props.t('Any one Transport Staff (Driver/Helper) details are mandatory')}</u></p></Col>

                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label className="">Select Driver</Label>
                                                    <Label className="text-info">*</Label>
                                                    {driverError === true && (
                                                        <Label className="label-xsmall text-info ml-2">
                                                            (Driver or Helper mandatory)
                                                        </Label>
                                                    )}
                                                    <select
                                                        className="form-control"
                                                        value={driver}
                                                        onChange={(e) => {
                                                            if (e.target.value === "addDriver") {
                                                                props.history.push("/addstaff/" + schoolId);

                                                            } else {
                                                                setDriver(e.target.value);
                                                            }
                                                        }}
                                                    >
                                                        <option disabled selected value>Select Driver</option>
                                                        {driverList.map((object, key) => (
                                                            <option key={key} value={object.id}>{object.attributes ? object.attributes.Name : object.name}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6}>
                                                <FormGroup>
                                                    <Label className="">Select Helper</Label>
                                                    <Label className="text-info">*</Label>
                                                    {helperError === true && (
                                                        <Label className="label-xsmall text-info ml-2">
                                                            (Driver or Helhper mandatory)
                                                        </Label>
                                                    )}
                                                    <select
                                                        className="form-control"
                                                        value={helper}
                                                        onChange={(e) => {
                                                            if (e.target.value === "addHelper") {
                                                                props.history.push("/addstaff/" + schoolId);
                                                            } else {
                                                                setHelper(e.target.value);
                                                            }
                                                        }}
                                                    >
                                                        <option disabled selected value>Select helper</option>
                                                        {helperList.map((object, key) => (
                                                            <option key={key} value={object.id}>{object.attributes ? object.attributes.Name : object.name}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>

                                        </Row>

                                        <Row lg={12}>
                                            <Col lg={6}>

                                                <div>
                                                    <Label>Start Time</Label>
                                                    <Label className="text-danger">*</Label>
                                                    {tripStartTimeError === true && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <div className="">
                                                        <DatePicker
                                                            selected={tripStartTime}
                                                            className="form-control"
                                                            onChange={(date) => {
                                                                setTripStartTime(date)
                                                            }}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={5}
                                                            timeCaption="Time"
                                                            minTime={defaultMinTime}
                                                            maxTime={tripEndTime ?? defaultMaxTime}
                                                            dateFormat="h:mm aa"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>

                                            <Col lg={6}>

                                                <div>
                                                    <Label>End Time</Label>
                                                    <Label className="text-danger">*</Label>
                                                    {tripEndTimeError === true && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <div className="">
                                                        <DatePicker
                                                            selected={tripEndTime}
                                                            className="form-control"
                                                            onChange={(date) => {
                                                                setTripEndTime(date)
                                                            }}
                                                            showTimeSelect
                                                            showTimeSelectOnly
                                                            timeIntervals={5}
                                                            timeCaption="Time"
                                                            minTime={tripStartTime ?? defaultMinTime}
                                                            maxTime={defaultMaxTime}
                                                            dateFormat="h:mm aa"
                                                        />
                                                    </div>
                                                </div>
                                            </Col>
                                        </Row>

                                        <Row lg={12} className="">
                                            <Col lg={6} className="">
                                                <div
                                                    className="form-check mt-4"
                                                >

                                                    <input
                                                        className="form-check-input"
                                                        type="checkbox"
                                                        id={"checkbox" + "trip"}
                                                        checked={isDeviceBased}
                                                        onChange={() => {
                                                            setIsDeviceBased(!isDeviceBased)
                                                        }}
                                                    />
                                                    <label
                                                        className="form-check-label"
                                                        htmlFor={"checkbox" + "trip"}
                                                    >
                                                        {"Is Device Based Trip"}
                                                    </label>
                                                </div>
                                            </Col>

                                            <Col lg={6} className="mt-3">
                                                {isDeviceBased && <><Label>Device Id/Number</Label>
                                                    <Label className="text-danger">*</Label>
                                                    {deviceNumberError === true && (
                                                        <Label className="label-xsmall text-danger ml-2">
                                                            (this field is mandatory)
                                                        </Label>
                                                    )}
                                                    <AvField
                                                        type="text"
                                                        name="deviceNumberAv"
                                                        id="deviceNumberAv"
                                                        placeholder="Device Id/Number"
                                                        className="form-control"
                                                        value={deviceNumber}
                                                        onChange={(e) => {
                                                            setDeviceNumber(e.target.value);
                                                        }}
                                                    />
                                                </>}
                                            </Col>
                                        </Row>

                                        {false && isMapsVisible && isDeviceBased ? <Row lg={12} className="mt-3">
                                            <Col lg={6} className="mt-4">
                                                <h5>Select Trip Start Location<Label className="text-danger">*</Label></h5>

                                                {pickupLocationError === true && (
                                                    <Label className="label-xsmall text-danger ml-2">
                                                        (this field is mandatory)
                                                    </Label>
                                                )}
                                                <div className="ml-3 mr-3">

                                                    {(existingPickupLocation.latitude || match) && <Map
                                                        id="startMap"
                                                        className="justify-content-center mb-10"
                                                        google={props.google}
                                                        center={{ lat: existingPickupLocation.latitude ?? defaultLatitude, lng: existingPickupLocation.longitude ?? defaultLongitude }}
                                                        height="400px"
                                                        onSelectLatLng={(latitude, longitude) => {
                                                            let temp = {}
                                                            temp.latitude = latitude
                                                            temp.longitude = longitude
                                                            setExistingPickupLocation(temp)
                                                        }}
                                                        zoom={15}
                                                    />}
                                                </div>

                                            </Col>

                                            <Col lg={6} className="mt-4">
                                                <h5>Select Trip End Location<Label className="text-danger">*</Label></h5>

                                                {dropLocationError === true && (
                                                    <Label className="label-xsmall text-danger ml-2">
                                                        (this field is mandatory)
                                                    </Label>
                                                )}
                                                <div className="ml-3 mr-3">
                                                    {(existingDropLocation.latitude || match) && <Map
                                                        id="endMap"
                                                        className="justify-content-center mb-10"
                                                        google={props.google}
                                                        center={{ lat: existingDropLocation.latitude ?? defaultLatitude, lng: existingDropLocation.longitude ?? defaultLongitude }}
                                                        height="400px"
                                                        onSelectLatLng={(latitude, longitude) => {
                                                            let temp = {}
                                                            temp.latitude = latitude
                                                            temp.longitude = longitude
                                                            setExistingDropLocation(temp)
                                                        }}
                                                        zoom={15}
                                                    />}
                                                </div>

                                            </Col>
                                        </Row> : (false && isDeviceBased && <Button color="primary" className="mt-4 btn btn-primary" onClick={() => {
                                            setIsMapsVisible(!isMapsVisible)
                                        }}>
                                            Select Trip Start/End Location
                                        </Button>)}
                                    </AvForm>
                                    <hr></hr>
                                    <Row>
                                        <Col lg={10}>

                                        </Col>
                                        <Col lg={2}>
                                            <button
                                                className="btn btm-sm btn-primary waves-effect waves-light mt-0 mr-2"
                                                onClick={() => {
                                                    if (verifyValues()) {
                                                        saveGroup(groupObject)
                                                    }
                                                }}
                                                style={{ float: "right" }}
                                                role="button"
                                            >
                                                Save
                                            </button>
                                        </Col>
                                    </Row>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment >
    );
}

const mapStatetoProps = (state) => {
    const { singleGroupHolder } = state.Group;
    const { selectedProgram, programTerms } = state.Program;
    const { feeTemplates } = state.FeeTemplate;
    const { selectedSchool } = state.School;
    const { teachers } = state.Teacher;


    return {
        singleGroupHolder,
        selectedProgram, programTerms,
        feeTemplates,
        selectedSchool,
        teachers
    };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool,
    getTeachersForSchool
})(withRouter(withNamespaces()(AddPrograms)));
