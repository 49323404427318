export const GET_EXAMS_FOR_SCHOOL_ID = "GET_EXAMS_FOR_SCHOOL_ID";

export const UPDATE_EXAMS_FOR_SCHOOL_ID = "UPDATE_EXAMS_FOR_SCHOOL_ID";

export const GET_EXAM_FOR_EXAM_ID = "GET_EXAM_FOR_EXAM_ID"; 

export const UPDATE_EXAM = "UPDATE_EXAM";

export const UPDATE_DELETE_EXAM = "UPDATE_DELETE_EXAM";

export const DELETE_EXAM = "DELETE_EXAM";

export const SAVE_MARKS_CARD = "SAVE_MARKS_CARD";
