import React, { useState, useEffect } from 'react'
import Topbar from '../Inventory/Topbar'
import Dropzone from 'react-dropzone';
import { Container, Row, Col, Card, CardBody, CardTitle, Form, FormGroup, Input, Label, Button, InputGroup, InputGroupText } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CreatableSelect from 'react-select/creatable';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from "uuid";
import Parse from 'parse';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import Switch from "react-switch";
import { setSelecetdSchool, addTicket, editTicket } from '../../store/actions';

const NewJob = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: "/new-job/:schoolId",
        exact: true,
        strict: false,
    });

    const matchEdit = matchPath(props.history.location.pathname, {
        path: "/edit-job/:schoolId/:jobId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const jobId = matchEdit && matchEdit.params.jobId;


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const getChR = () => {
        return " <ul>" +
            "<li>Provide strong leadership and management to the preschool center staff.</li> " +
            "<li>Develop and implement strategies to achieve the center's goals and objectives.</li> " +
            "<li>Ensure that the curriculum aligns with relevant educational standards and guidelines.</li>  " +
            "<li>Plan and organize educational programs, activities, and events for preschoolers.</li> " +
            "<li>Manage the student enrollment process, including admissions, registration, and orientation.</li>" +
            "<li>Recruit, hire, and train qualified teaching and administrative staff.</li>" +
            "<li>Ensure compliance with health, safety, and sanitation regulations and standards.</li>  " +
            "<li>Monitor and evaluate the overall effectiveness of the preschool center's programs and services.</li>" +
            "</ul>"
    }

    const getChD = () => {
        return " <ul>" +
            "The Preschool Center Head is responsible for overseeing the day-to-day operations of the preschool center, including program development, staff management, student enrollment, parent engagement, and regulatory compliance.  " +
            "The ideal candidate will demonstrate strong leadership, communication, and organizational skills to ensure the delivery of high-quality early childhood education in a safe and nurturing environment. " +
            "</ul>"
    }

    const getTD = () => {
        return "<ul>" +
            "Teaching certification or license (if required by state or country). " +
            "Strong communication and interpersonal skills. " +
            "Ability to effectively manage a classroom and work with diverse student populations. " +
            "Proficiency in educational technology and software. " +
            "</ul>"
    }

    const getTR = () => {

        return "<ul>" +
            "<li>Plan and deliver lessons according to the curriculum.</li>" +
            "<li>Adapt teaching methods and materials to meet the needs of individual students.</li>" +
            "<li>Use a variety of instructional strategies to engage students in learning.</li>" +
            "<li>Assess student progress and provide feedback on assignments, projects, and assessments.</li>" +
            "<li>Establish and enforce classroom rules and procedures to maintain a positive learning environment.</li>" +
            "<li>Participate in ongoing professional development activities to enhance teaching skills and knowledge.</li>" +
            "<li>Establish and maintain open communication with parents and guardians regarding student progress and academic goals.</li>" +
            "<li>Maintain confidentiality and respect the privacy of students and families.</li>" +
            "</ul>"

    }

    const getES = (text) => {
        const contentBlock = htmlToDraft(text);
        const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
        const editorState = EditorState.createWithContent(contentState);
        return editorState;
    }

    const [designation, setDesignation] = useState();
    const [jobTitle, setJobTitle] = useState();
    const [experience, setExperience] = useState()
    const [qualification, setQualification] = useState([])
    const [publish, setPublish] = useState(false)
    const [city, setCity] = useState(props.selectedSchool && props.selectedSchool.attributes.city);
    const [area, setArea] = useState(props.selectedSchool && props.selectedSchool.attributes.locality);

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(() => {
        const today = new Date();
        const next30Days = new Date(today);
        next30Days.setDate(today.getDate() + 30);
        return next30Days;
    });

    const [roles, setRoles] = useState();
    const [description, setDescription] = useState();


    const [editorStateRole, setEditorStateRole] = useState();
    const [editorStateDescription, setEditorStateDescription] = useState();

    const [designationError, setDesignationError] = useState(false);
    const [jobTitleError, setJobTitleError] = useState(false);
    const [experienceError, setExperienceError] = useState(false)
    const [qualificationError, setQualificationError] = useState(false)
    const [cityError, setCityError] = useState(false);
    const [areaError, setAreaError] = useState(false);
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false)
    const [descriptionError, setDescriptionError] = useState(false);
    const [rolesError, setRolesError] = useState(false);

    const [jobDetails, setJobDetails] = useState()

    useEffect(() => {
        if (startDate > endDate) {
            setStartDate(endDate);
        }
    }, [endDate]);

    useEffect(() => {
        var JobNotifications = Parse.Object.extend("JobNotifications");
        var query = new Parse.Query(JobNotifications);
        // query.equalTo("schoolId", schoolId);
        query.equalTo("objectId", jobId);
        query.first().then(
            (results) => {
                console.log(results)
                setJobDetails(results);
            },
            (error) => {

            }
        );
    }, [jobId])

    useEffect(() => {
        if (jobDetails && jobDetails.id == jobId) {
            setDesignation(jobDetails.attributes.designation);
            setExperience(jobDetails.attributes.experience);
            setCity(jobDetails.attributes.location);
            setArea(jobDetails.attributes.area);
            setQualification(jobDetails.attributes.qualifications);
            setDescription(jobDetails.attributes.description);
            if (jobDetails.attributes.description) {
                setEditorStateDescription(getES(jobDetails.attributes.description))
            }
            setRoles(jobDetails.attributes.roles);
            if (jobDetails.attributes.roles) {
                setEditorStateRole(getES(jobDetails.attributes.roles))
            }
            setStartDate(new Date(jobDetails.attributes.startDate));
            setEndDate(new Date(jobDetails.attributes.endDate));
            setPublish(jobDetails.attributes.isActive)
        }
        console.log(props.selectedSchool)
    }, [jobDetails])

    function handleSubmit() {

        let canSave = true;

        if (!designation) {
            setDesignationError(true);
            canSave = false;
        }
        // if (!jobTitle) {
        //     setJobTitleError(true);
        //     canSave = false;
        // }

        if (!experience) {
            setExperienceError(true);
            canSave = false;
        }

        if (!qualification) {
            setQualificationError(true);
            canSave = false;
        }
        if (!city) {
            setCityError(true);
            canSave = false;
        }
        if (!area) {
            setAreaError(true);
            canSave = false;
        }
        if (!startDate) {
            setStartDateError(true);
            canSave = false;
        }
        if (!endDate) {
            setEndDateError(true);
            canSave = false;
        }

        if (!canSave) {
            return
        }


        const JobNotifications = Parse.Object.extend("JobNotifications");
        var job = new JobNotifications();
        if (jobId) {
            job.id = jobId;
            let isEdit = false;

            if (designation != jobDetails.attributes.designation) {
                job.set("designation", designation);
                isEdit = true;
            }
            // if (jobTitle != jobDetails.attributes.designation) {
            //     job.set("title", jobTitle);
            //     isEdit = true;
            // }
            if (experience != jobDetails.attributes.experience) {
                job.set("experience", experience);
                isEdit = true;
            }
            if (qualification != jobDetails.attributes.qualifications) {
                job.set("qualifications", qualification);
                isEdit = true;
            }
            if (city != jobDetails.attributes.location) {
                job.set("location", city);
                isEdit = true;
            }
            if (area != jobDetails.attributes.area) {
                job.set("area", area);
                isEdit = true;
            }
            // if ("Full Time" != jobDetails.attributes.employeeType) {
            //     job.set("employeeType", "Full Time");
            //     isEdit = true;
            // }
            if (description != jobDetails.attributes.description) {
                job.set("description", description);
                isEdit = true;
            }
            if (roles != jobDetails.attributes.roles) {
                job.set("roles", roles);
                isEdit = true;
            }

            if (isEdit && job.attributes.reviewStatus == "1") {
                job.set("reviewStatus", 0);
            }
        } else {
            job.set("schoolId", schoolId);
            job.set('createdBy', Parse.User.current().attributes.teacherId)
            job.set("instituteId", props.selectedSchool && props.selectedSchool.attributes.instituteId);

            job.set("designation", designation);
            job.set("title", jobTitle);
            job.set("experience", experience);
            job.set("qualifications", qualification);
            job.set("location", city);
            job.set("area", area);
            job.set("employeeType", "Full Time");
            job.set("description", description);
            job.set("roles", roles);
        }
        job.set("startDate", startDate);
        job.set("endDate", endDate);
        job.set("isActive", publish);
        job.set("schoolName", props.selectedSchool && props.selectedSchool.attributes.Name);
        job.set("schoolDetails", {
            name: props.selectedSchool.attributes.Name,
            email: props.selectedSchool.attributes.EMail,
            phone: props.selectedSchool.attributes.primaryPhoneNumber,
            address: props.selectedSchool.attributes.Address,
            geoLocation: props.selectedSchool.attributes.location && props.selectedSchool.attributes.location,
            city: props.selectedSchool.attributes.city,
            locality: props.selectedSchool.attributes.locality,
            pincode:props.selectedSchool.attributes.pinCode,
            stateCode:props.selectedSchool.attributes.areaCode,
            state:props.selectedSchool.attributes.state,
        });



        job.save().then(
            (result) => {
                window.history.back()
                console.log(result)
            },
            (error) => {
                console.log(error);
            }
        );

    }

    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };
    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };

    const qualificationOptions = [
        { value: '10th', label: '10th' },
        { value: '12th', label: '12th' },
        { value: 'Graduation', label: 'Any Graduation'},
        { value: 'Pre primary teacher training certificate', label: 'Pre primary teacher training certificate' },
        { value: 'Nursery teacher training certificate', label: 'Nursery teacher training certificate' },
    ];




    return (
        <div className='page-content'>
            <Topbar
                title={"Create Job Post"}
                buttonName={"Create Job Post"}
                hideButton={true}
            />
            <Container fluid>

                <Row>
                    <Col lg="12">
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="example-radio-input" className="col-md-2 col-form-label ">Designation:</Label>
                                            <Row>
                                                <Col lg={2}>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <Input
                                                            type="radio"
                                                            id="customRadioInline1"
                                                            name="customRadioInline1"
                                                            checked={designation == "Center Head"}
                                                            className="custom-control-input m-1"
                                                            onChange={(e) => {
                                                                setDesignation("Center Head");
                                                                setJobTitle("Center Head");

                                                                if (match) {
                                                                    if (roles != getChR()) {
                                                                        setRoles(getChR())
                                                                        setEditorStateRole(getES(getChR()));
                                                                    }
                                                                    if (description != getChD()) {
                                                                        setDescription(getChD())
                                                                        setEditorStateDescription(getES(getChD()));
                                                                    }
                                                                }

                                                                setDesignationError(false)
                                                            }}
                                                            required
                                                        />
                                                        <Label
                                                            className="custom-control-label"
                                                            htmlFor="customRadioInline1"
                                                        >
                                                            Center Head
                                                        </Label>
                                                    </div>
                                                </Col>
                                                <Col lg={2}>
                                                    <div className="custom-control custom-radio custom-control-inline">
                                                        <Input
                                                            type="radio"
                                                            id="customRadioInline2"
                                                            name="customRadioInline1"
                                                            checked={designation === "Teacher"}
                                                            className="custom-control-input m-1"
                                                            onChange={(e) => {
                                                                setDesignation("Teacher");
                                                                setJobTitle("Teacher");

                                                                if (match) {
                                                                    if (roles != getTR()) {
                                                                        setRoles(getTR())
                                                                        setEditorStateRole(getES(getTR()));
                                                                    }
                                                                    if (description != getTD()) {
                                                                        setDescription(getTD())
                                                                        setEditorStateDescription(getES(getTD()));
                                                                    }
                                                                }

                                                                setDesignationError(false)
                                                            }}
                                                        />
                                                        <Label
                                                            className="custom-control-label"
                                                            htmlFor="customRadioInline2"
                                                        >
                                                            Teacher
                                                        </Label>
                                                    </div>
                                                </Col>
                                            </Row>
                                            {designationError && <small className='text-danger'>* Select Teacher or Center Head</small>}
                                        </FormGroup>

                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label  " >Job Title:</Label>
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="Job Title"
                                                disabled={true}
                                                value={jobTitle}
                                                onChange={(e) => {
                                                    // setJobTitle(jobTitle);
                                                    // setJobTitleError(false);
                                                }}
                                            />
                                            {jobTitleError && <small className='text-danger'>* Enter Job Title </small>}
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label  " >Experience:</Label>
                                            <InputGroup>
                                                <select
                                                    className="form-control "
                                                    value={experience}
                                                    onChange={(e) => {
                                                        setExperience(e.target.value)
                                                        setExperienceError(false)
                                                    }}
                                                    placeholder="Select Program"
                                                >
                                                    <option value="Select" disabled selected>Select Experience</option>
                                                    <option value={"0-1"}>0 - 1</option>
                                                    <option value={"1-2"}>1 - 2</option>
                                                    <option value={"2-4"}>2 - 4</option>
                                                    <option value={"4-6"}>4 - 6</option>
                                                    <option value={"8-6"}>6 - 8</option>
                                                    <option value={"8+"}>8 +</option>
                                                </select>
                                                <InputGroupText>Years</InputGroupText>
                                            </InputGroup>
                                            {experienceError && <small className='text-danger'>*Please select experience</small>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label  " >City:</Label>
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="City"
                                                value={city}
                                                onChange={(e) => {
                                                    setCity(e.target.value);
                                                    setCityError(false);
                                                }}
                                            />
                                            {cityError && <small className='text-danger'>*Enter city</small>}
                                        </FormGroup>
                                    </Col>
                                    <Col>
                                        <FormGroup>
                                            <Label htmlFor="example-text-input" className="col-md-2 col-form-label  " >Area:</Label>
                                            <Input className="form-control"
                                                type="text"
                                                id="displayName"
                                                placeholder="Area"
                                                value={area}
                                                onChange={(e) => {
                                                    setArea(e.target.value);
                                                    setAreaError(false);
                                                }}
                                            />
                                            {areaError && <small className='text-danger'>*Enter area </small>}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={6}>
                                        <FormGroup>
                                            <Label className="mb-0">Qualification:</Label>
                                            <CreatableSelect
                                                placeholder="Select or type qualifications"
                                                value={qualification && qualification.map(q => ({ value: q, label: q }))}
                                                onChange={(selectedOptions) => {
                                                    const selectedQualifications = selectedOptions ? selectedOptions.map(option => option.value) : [];
                                                    setQualification(selectedQualifications);
                                                    setQualificationError(false);
                                                }}
                                                options={qualificationOptions}
                                                isClearable
                                                isMulti={true}
                                                isSearchable
                                                classNamePrefix="select2-selection"
                                                onCreateOption={(inputValue) => {
                                                    setQualification(prevQualifications => [...prevQualifications, inputValue]);
                                                }}
                                            />
                                            {qualificationError && <small className='text-danger'>*Enter Qualifications</small>}
                                        </FormGroup>


                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label htmlFor="example-text-input" className="col-md-4 col-form-label  " >description:</Label>
                                        <FormGroup>
                                            <Editor
                                                editorState={editorStateDescription}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={newState => {
                                                    setEditorStateDescription(newState);
                                                    const con = draftToHtml(convertToRaw(newState.getCurrentContent()));
                                                    setDescription(con);
                                                }}
                                            />
                                            {/* {descriptionError && <small className='text-danger'>*Enter Job description</small>} */}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col>
                                        <Label htmlFor="example-text-input" className="col-md-4 col-form-label  " >Roles and Responsibilty:</Label>
                                        <FormGroup>
                                            <Editor
                                                editorState={editorStateRole}
                                                toolbarClassName="toolbarClassName"
                                                wrapperClassName="wrapperClassName"
                                                editorClassName="editorClassName"
                                                onEditorStateChange={newState => {
                                                    setEditorStateRole(newState);
                                                    const con = draftToHtml(convertToRaw(newState.getCurrentContent()));
                                                    setRoles(con);
                                                }}
                                            />
                                            {/* {rolesError && <small className='text-danger'>*Enter Job Roles and Responsibilty</small>} */}
                                        </FormGroup>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col lg={4}>
                                        <FormGroup className="mt-2 mb-2 p-1">
                                            <Label>
                                                Start Date
                                            </Label>
                                            <DatePicker
                                                selected={startDate}
                                                onChange={(date) => {
                                                    setStartDate(date)
                                                    setStartDateError(false)
                                                }}
                                                className="form-control"
                                                placeholderText={"Select Start Date"}
                                                startDate={startDate}
                                                endDate={endDate}
                                                maxDate={endDate}
                                                dateFormat="dd/MM/yyyy"
                                                required
                                                errorMessage="Please select"
                                                showFullMonthYearPicker
                                            />
                                            {startDateError && <small className='text-danger'>*Enter Start Date</small>}
                                        </FormGroup>
                                    </Col>
                                    <Col lg={4}>
                                        <FormGroup className="mt-2 mb-2 p-1">
                                            <Label>
                                                End Date
                                            </Label>
                                            <DatePicker
                                                selected={endDate}
                                                onChange={(date) => {
                                                    setEndDate(date)
                                                    setEndDateError(false)
                                                }}
                                                className="form-control"
                                                placeholderText={"Select End Date"}
                                                startDate={startDate}
                                                endDate={endDate}
                                                // maxDate={endDate}
                                                dateFormat="dd/MM/yyyy"
                                                required
                                                errorMessage="Please select"
                                                showFullMonthYearPicker
                                            />
                                            {endDateError && <small className='text-danger'>*Enter End Date</small>}
                                        </FormGroup>
                                    </Col>
                                </Row>

                                <Row>
                                    <Col>
                                        <Label htmlFor="example-text-input" className="col-md-4 col-form-label  ">Publish:</Label><br></br>
                                        <Switch
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={() => {
                                                setPublish(true)
                                            }}
                                            checked={publish}
                                        />
                                    </Col>
                                    <Col className=''>
                                        <br />
                                        <Button
                                            className='float-end'
                                            color="primary"
                                            onClick={() => {
                                                handleSubmit()

                                            }}
                                            checked={true}
                                        >Submit</Button>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>

            </Container>
        </div>
    )
}
const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    return { selectedSchool, schools };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, addTicket, editTicket })(NewJob)));
