import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as CourseSubscriptionHelper from "../../../helpers/ParseHelpers/courseSubscription_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as CourseSubscriptionActions from "./actions";


function* removeCourseSubscription({ payload: { courseSubscription } }) {
    try {
        console.log("courseSubscription",courseSubscription)
        const response = yield call(CourseSubscriptionHelper.deleteCourseFromParse, courseSubscription);
        if (response) {
            yield put(CourseSubscriptionActions.updateCoureseSubscriptions([response]));
            response.unPin();
        } else {
            //not deleted
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}



function* getCourseSubscriptions({ payload: { courseId,batchId } }) {
    try {
        yield put(CourseSubscriptionActions.updateCoureseSubscriptionsInitialLoading(true));
        var key = batchId ? Constants.COURSE_UPDATED_TIME_FOR_OWNER_ID + "_" + courseId + "_" + batchId : Constants.COURSE_UPDATED_TIME_FOR_OWNER_ID + "_" + courseId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(CourseSubscriptionHelper.getCourseSubscriptions, courseId, batchId,null, true);
        yield put(CourseSubscriptionActions.updateCoureseSubscriptions(localResponse));

        if (localResponse && localResponse.length > 0) {
            yield put(CourseSubscriptionActions.updateCoureseSubscriptionsInitialLoading(false));
        }

        const serverResponse = yield call(CourseSubscriptionHelper.getCourseSubscriptions, courseId,batchId, updatedTime, false);
        localStorage.setItem(key, newTime)
        if (!localResponse || localResponse.length === 0) {
            yield put(CourseSubscriptionActions.updateCoureseSubscriptionsInitialLoading(false));
        }
        if (serverResponse && serverResponse.length > 0) {
            yield put(CourseSubscriptionActions.updateCoureseSubscriptions(serverResponse));
            Parse.Object.pinAll(serverResponse);
        }
    } catch (error) {
        // yield put(apiError(error));

    }
}

function* getCourseSubscriptionForUserIdSage({ payload: { userId } }) {
    try {
        //yield put(CourseSubscriptionActions.updateCoureseSubscriptionsInitialLoading(true));



        const serverResponse = yield call(CourseSubscriptionHelper.getCourseSubscriptionForUserId, userId);
        //yield put(CourseSubscriptionActions.updateCoureseSubscriptionsInitialLoading(false));

        if (serverResponse && serverResponse.length > 0) {
            yield put(CourseSubscriptionActions.updateCourseSubscriptionForUserId(serverResponse));
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}


function* addCourseSubscription({ payload: { users, isUpdateUserSubs } }) {
    try {
        yield put(CourseSubscriptionActions.updateAddState(true));
        let newsubs = []
        for (const kid of users) {
            const CourseSubscription = Parse.Object.extend("CourseSubscription");
            var newsub = new CourseSubscription();
            newsub.set("kidId", kid.kidId);
            newsub.set("kidIds", kid.kidIds);
            newsub.set("courseId", kid.courseId);
            newsub.set("subscribedDate", kid.subscribedDate);
            if (kid.requestId) {
                newsub.set("requestId", kid.requestId);
            }
            if (kid.mode) {
                newsub.set("mode", kid.mode)
            }
            if (kid.batch) {
                newsub.set("batchId", kid.batch)
            }
            if (Parse.User.current().attributes.teacherId) {
                newsub.set("addedBy", Parse.User.current().attributes.teacherId);
            } else {
                newsub.set("addedBy", Parse.User.current().id);
            }
            newsubs.push(newsub);
        }
        const serverResponse = yield call(CourseSubscriptionHelper.saveParseObjects, newsubs);
        if (serverResponse && serverResponse.length > 0) {

            if (isUpdateUserSubs) {
                yield put(CourseSubscriptionActions.updateCourseSubscriptionForUserId(serverResponse));
            } else {
                yield put(CourseSubscriptionActions.updateCoureseSubscriptions(serverResponse));
            }
            Parse.Object.pinAll(serverResponse);
        }
        yield put(CourseSubscriptionActions.updateAddState(false));
    } catch (error) {
        // yield put(apiError(error));

    }
}


function* getCourseSubscriptionForId({ payload: { subscriberId } }) {
    try {
        const serverResponse = yield call(CourseSubscriptionHelper.getCourseSubscriptionId, subscriberId);

        if (serverResponse) {
            yield put(CourseSubscriptionActions.updadteCoureseSubscriptionForId(serverResponse));
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}



export function* watchGetCourseSubscriptions() {
    yield takeEvery(actionTypes.GET_COURSE_SUBSCRIPTIONS, getCourseSubscriptions);
}

export function* watchAddCourseSubscription() {
    yield takeEvery(actionTypes.ADD_SUBSCRIPTION_TO_COURSE, addCourseSubscription);
}

export function* watchRemoveCourseSubscription() {
    yield takeEvery(actionTypes.REMOVE_SUBSCRIPTION_FROM_COURSE, removeCourseSubscription);
}

export function* watchGetCourseSubscriptionForUserIdSage() {
    yield takeEvery(actionTypes.GET_COURSE_SUBSCRIPTIONS_FOR_USER_ID, getCourseSubscriptionForUserIdSage);
}

export function* watchGetCourseSubscriptionForID() {
    yield takeEvery(actionTypes.GET_SUBSCRIPTION_FOR_ID, getCourseSubscriptionForId);
}



function* CourseSunscriptionSaga() {
    yield all([
        fork(watchGetCourseSubscriptions),
        fork(watchAddCourseSubscription),
        fork(watchGetCourseSubscriptionForUserIdSage),
        fork(watchRemoveCourseSubscription),
        fork(watchGetCourseSubscriptionForID),
    ]);
}
export default CourseSunscriptionSaga;