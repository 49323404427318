import React, { useState, useEffect } from 'react'
import { Card, CardBody, FormGroup, Row, Col, Label, Input, Table, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, Collapse } from 'reactstrap';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from 'moment';
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import ProductDeatails from "./../E-commerce/ProductDetails";
import {
    setSelecetdSchool, getInventoryItemForOwnerId, getAllKids,
    getTeachersForSchool, getTeacher, setSelectedSaleOrder,
    setSelectedSaleOrderItems, getInventoryWarehouseForOwnerId,
    getInventoryKitsForOwnerId, getInventoryItemMatrixForItemIds
} from '../../../store/actions';
import { matrix } from 'echarts';

const saleOrderSummary = (props) => {

    const schoolMatch = matchPath(props.history.location.pathname, {
        path: "/sale-order-summary/:schoolId/:saleOrderId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-sale-order-summary/:franchiseId/:saleOrderId",
        exact: true,
        strict: false,
    });

    const match = schoolMatch || franchiseMatch
    const saleOrderId = match && match.params.saleOrderId;
    const schoolId = schoolMatch && schoolMatch.params.schoolId;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [items, setItems] = useState([]);
    const [loading, setLoading] = useState(false)
    const [schoolFranchiseId, setSchoolFranchiseId] = useState()
    const [approveAlert, setApproveAlert] = useState(false);
    const [saleOrder, setSaleOrder] = useState()
    const [approveState, setApproveState] = useState(false)
    const [showDueItems, setShowDueItems] = useState(false)

    const [showItemMatrix, setShowItemMatrix] = useState(false)

    console.log("items", items)
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        setSchoolFranchiseId(props.selectedSchool && props.selectedSchool.attributes.franchiseId);
    }, [props.selectedSchool, schoolId]);


    useEffect(() => {
        if (schoolId) {
            // props.getTeachersForSchool(schoolId);
            // props.getAllKids(schoolId);
            // props.getInventoryItemForOwnerId(schoolId, 'school')
        }
    }, [schoolId]);

    useEffect(() => {
        if (schoolFranchiseId) {
            props.getInventoryItemForOwnerId(schoolFranchiseId, 'franchise')
            props.getInventoryWarehouseForOwnerId(schoolFranchiseId, 'franchise')

        }
    }, [schoolFranchiseId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryItemForOwnerId(franchiseId, 'franchise')
            props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise')
            props.getInventoryKitsForOwnerId(franchiseId, 'franchise')
        }
    }, [franchiseId]);

    useEffect(() => {
        setLoading(true);

        let InventorySaleOrder = Parse.Object.extend("InventorySaleOrders");
        let query = new Parse.Query(InventorySaleOrder);

        query.equalTo("objectId", saleOrderId);
        query.notEqualTo("isDeleted", true);

        query.first().then((saleOrder) => {
            if (saleOrder) {
                setSaleOrder(saleOrder)
            }
            let saleOrderItemsQuery = new Parse.Query('InventorySaleOrderItems');
            saleOrderItemsQuery.equalTo('saleOrderId', saleOrderId);
            saleOrderItemsQuery.notEqualTo('isDeleted', true);

            return saleOrderItemsQuery.find();
        }).then((saleOrderItems) => {
            let itemIds = [];
            let kitIds = [];

            for (const pi of saleOrderItems) {
                if (pi.attributes.productType === "inventoryItem") {
                    itemIds.push(pi.attributes.itemId);
                } else if (pi.attributes.productType === "inventoryKit") {
                    kitIds.push(pi.attributes.itemId);
                }
            }

            return getInventoryKitsAndKitItems(kitIds).then((inventoryKitsData) => {
                if (inventoryKitsData && inventoryKitsData.inventoryKitMap.length > 0) {
                    for (const kitMap of inventoryKitsData.inventoryKitMap) {
                        if (!itemIds.includes(kitMap.attributes.itemId)) {
                            itemIds.push(kitMap.attributes.itemId);
                        }
                    }
                }

                if (itemIds.length > 0) {
                    props.getInventoryItemMatrixForItemIds(itemIds);
                }

                let itemMatrixQuery = new Parse.Query('InventoryItemMatrix');
                itemMatrixQuery.containedIn('itemId', itemIds);
                itemMatrixQuery.notEqualTo('isDeleted', true);
                itemMatrixQuery.limit(1000)
                itemMatrixQuery.find().then((itemMatrix) => {
                    // console.log("itemMatrix", itemMatrix)
                    let itemsQuery = new Parse.Query('InventoryItems');
                    itemsQuery.containedIn('objectId', itemIds);

                    itemsQuery.find().then((inventoryItems) => {
                        let itemsWithQuantities = {};
                        for (const item of inventoryItems) {
                            itemsWithQuantities[item.id] = {
                                id: item.id,
                                itemName: item.attributes.name,
                                quantity: 0,
                                stock: 0,
                                combinations: undefined
                            };
                        }

                        for (const soi of saleOrderItems) {
                            if (soi.attributes.productType === "inventoryItem") {
                                itemsWithQuantities[soi.attributes.itemId].quantity += soi.attributes.quantity;
                                if (soi.attributes.kitItemMatrix && soi.attributes.kitItemMatrix.length > 0) {
                                    itemsWithQuantities[soi.attributes.itemId].matrix = soi.attributes.kitItemMatrix[0];
                                    if (itemsWithQuantities[soi.attributes.itemId].combinations) {
                                        itemsWithQuantities[soi.attributes.itemId].combinations = combineQuantities(
                                            itemsWithQuantities[soi.attributes.itemId].combinations,
                                            soi.attributes.kitItemMatrix[0].combinations
                                        );
                                    } else {
                                        itemsWithQuantities[soi.attributes.itemId].combinations = soi.attributes.kitItemMatrix[0].combinations;
                                    }
                                }
                            } else if (soi.attributes.productType === "inventoryKit") {
                                for (const km of inventoryKitsData.inventoryKitMap) {
                                    if (km.attributes.kitId === soi.attributes.itemId) {
                                        let kitItemId = km.attributes.itemId;
                                        itemsWithQuantities[kitItemId].quantity += soi.attributes.quantity * km.attributes.quantity;

                                        if (soi.attributes.kitItemMatrix) {
                                            let newKitItemMatrix = soi.attributes.kitItemMatrix.filter((kt) => kt.itemId === kitItemId);

                                            if (newKitItemMatrix && newKitItemMatrix.length > 0) {
                                                itemsWithQuantities[kitItemId].matrix = newKitItemMatrix[0];

                                                if (itemsWithQuantities[kitItemId].combinations) {
                                                    itemsWithQuantities[kitItemId].combinations = combineQuantities(
                                                        itemsWithQuantities[kitItemId].combinations,
                                                        newKitItemMatrix[0].combinations
                                                    );
                                                } else {
                                                    itemsWithQuantities[kitItemId].combinations = newKitItemMatrix[0].combinations;
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }

                        let promises = [];
                        let subItemIds = [];

                        for (const itemId in itemsWithQuantities) {
                            let itemC = itemsWithQuantities[itemId].combinations;
                            if (itemC) {
                                for (const co of itemC) {
                                    let { sizeValue, colorValue } = getMatrixName(co.id, itemMatrix);
                                    co.sizeValue = sizeValue;
                                    co.isSize = !!sizeValue;
                                    co.colorValue = colorValue;
                                    co.isColor = !!colorValue;

                                    co.deliveringQuantity = co.selectedQuntity;
                                    co.dueQuantity = 0;

                                    if (co.subItemId) {
                                        if (!subItemIds.includes(co.subItemId)) {
                                            subItemIds.push(co.subItemId);
                                        }
                                    }
                                }
                                itemsWithQuantities[itemId].combinations = itemC;
                            }
                        }

                        if (subItemIds.length > 0) {
                            let subItemStockQuery = new Parse.Query('InventoryItemStockMap');
                            subItemStockQuery.containedIn('itemId', subItemIds);

                            promises.push(
                                subItemStockQuery.find().then((stockItems) => {
                                    let stockMap = {};
                                    stockItems.forEach((stockItem) => {
                                        stockMap[stockItem.attributes.itemId] = stockItem.attributes.stock;
                                    });

                                    for (const itemId in itemsWithQuantities) {
                                        let itemC = itemsWithQuantities[itemId].combinations;
                                        if (itemC) {
                                            for (const co of itemC) {
                                                if (co.subItemId) {
                                                    let subItemStock = stockMap[co.subItemId] || 0;
                                                    co.stock = subItemStock;
                                                    co.deliveringQuantity = co.selectedQuntity > subItemStock ? subItemStock : co.selectedQuntity;
                                                    co.dueQuantity = co.selectedQuntity - co.deliveringQuantity;
                                                }
                                            }
                                        }
                                    }
                                })
                            );
                        }

                        for (const itemId in itemsWithQuantities) {
                            let itemStockQuery = new Parse.Query('InventoryItemStockMap');
                            itemStockQuery.equalTo('itemId', itemId);
                            promises.push(
                                itemStockQuery.first().then((stockItem) => {
                                    let stock = 0;
                                    if (stockItem) {
                                        stock = stockItem.attributes.stock;
                                        itemsWithQuantities[itemId].stock = stock;
                                    }
                                    itemsWithQuantities[itemId].deliveringQuantity = itemsWithQuantities[itemId].quantity > stock ? stock : itemsWithQuantities[itemId].quantity;
                                    itemsWithQuantities[itemId].dueQuantity = itemsWithQuantities[itemId].quantity - itemsWithQuantities[itemId].deliveringQuantity;
                                })
                            );
                        }

                        return Promise.all(promises).then(() => {
                            let finalItems = Object.values(itemsWithQuantities);
                            setItems(finalItems);
                            setLoading(false);
                        }).catch((error) => {
                            console.error("Error in stock:", error);
                            setLoading(false);
                        });

                    });
                })
            });
        }).catch((error) => {
            console.error("Error: ", error);
            setLoading(false);
        });
    }, [saleOrderId]);

    function combineQuantities(combinations1, combinations2) {
        const quantityMap = new Map();

        function addQuantities(combinations) {
            for (const item of combinations) {
                let selectedQuntity = item.selectedQuntity ?? 0;
                let key = item.id;

                if (quantityMap.has(key)) {
                    let existing = quantityMap.get(key);
                    existing.selectedQuntity += selectedQuntity;
                    quantityMap.set(key, existing);
                } else {
                    quantityMap.set(key, { ...item, selectedQuntity });
                }
            }
        }

        addQuantities(combinations1);
        addQuantities(combinations2);

        const resultCombinations = Array.from(quantityMap.entries()).map(([id, item]) => ({
            ...item,
            id,
        }));

        return resultCombinations;
    }


    const getMatrixName = (matrixId, itemMatrix) => {

        itemMatrix = itemMatrix ?? props.inventoryMatrixs;

        function findMatrixItemById(id) {


            for (const matrixItem of itemMatrix) {
                if (matrixItem.id === id) {
                    return matrixItem;
                }
            }
            return null;
        }

        function findMatrixItemsByIds(ids) {
            const result = { colorValue: '', sizeValue: '' };


            for (const matrixItem of itemMatrix) {
                if (ids.includes(matrixItem.id)) {
                    if (matrixItem.attributes.type === "COLOR") {
                        result.colorValue = matrixItem.attributes.value;
                    } else if (matrixItem.attributes.type === "SIZE") {
                        result.sizeValue = matrixItem.attributes.value;
                    }
                }
            }
            return result;
        }

        if (matrixId.length === 20) {
            const sizeId = matrixId.slice(0, 10);
            const colorId = matrixId.slice(10, 20);

            const ids = [sizeId, colorId];
            return findMatrixItemsByIds(ids);
        } else {
            const matrixItem = findMatrixItemById(matrixId);
            if (matrixItem) {
                if (matrixItem.attributes.type === "COLOR") {
                    return { colorValue: matrixItem.attributes.value, sizeValue: '' };
                } else if (matrixItem.attributes.type === "SIZE") {
                    return { colorValue: '', sizeValue: matrixItem.attributes.value };
                }
            }
            return { colorValue: '', sizeValue: '' };
        }
    }




    const getInventoryKitsAndKitItems = (kitIds) => {
        return new Promise((resolve, reject) => {
            if (kitIds.length > 0) {
                let kitQuery = new Parse.Query('InventoryKits');
                kitQuery.containedIn('objectId', kitIds);

                kitQuery.find()
                    .then((inventoryKits) => {
                        let kitItemsQuery = new Parse.Query('InventoryKitItemMap');
                        kitItemsQuery.containedIn('kitId', kitIds);
                        kitItemsQuery.notEqualTo('isDeleted', true);

                        return kitItemsQuery.find().then((inventoryKitMap) => ({
                            inventoryKits,
                            inventoryKitMap
                        }));
                    })
                    .then((data) => resolve(data))
                    .catch((err) => reject(err));
            } else {
                resolve({ inventoryKits: [], inventoryKitMap: [] });
            }
        });
    };

    const handleApproveOrder = () => {

        Parse.Cloud.run("function_AcceptSaleOrder", {
            saleItems: items,
            saleOrderId: saleOrderId,
            teacherId: Parse.User.current().attributes.teacherId,
        }).then((response) => {
            if (response) {
                setApproveAlert(false);
                if (response.state == 1) {
                    if (response.dueList && response.dueList.length && response.dueList.length > 0) {
                        setShowDueItems(response.dueList)
                    } else {
                        setApproveState("Order accepted successfully, now you can make or  receive the payment.")
                    }
                } else if (response.state == 2) {
                    setApproveState("Some of the items are out please refresh the page and check.")
                } else if (response.state == 5) {
                    setApproveState("This order is deleted.")
                } else if (response.state == 6) {
                    setApproveState("This order has already accepted.")
                } else if (response.state == -1) {
                    setApproveState("Something went wrong, please try again!")
                }
            }
        }, (error) => {
            console.log("error")
        });
    }

    const getSchoolName = (id) => {

        for (const school of props.schools) {
            if (school.id == id) {
                return school.attributes.Name
            } else {
                return ""
            }
        }
    }

    const getItemName = (id) => {
        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return item.attributes.name;
            }
        }
        return "_";
    }

    console.log(items)

    return (
        <div className='page-content'>
            <Modal isOpen={showDueItems} size="lg" toggle={() => setShowDueItems(false)}>
                <ModalBody>
                    <div>
                        <b>Order accepted successfully with due:</b>
                    </div><br />
                    <div className="table-responsive">
                        <table className="table table-centered table-bordered mb-0 table-sm">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    <th>Items Name</th>
                                    <th>Ordered Quantity</th>
                                    <th>Delivering Quantity</th>
                                    <th>Due Quantity</th>
                                </tr>
                            </thead>
                            <tbody>
                                {showDueItems && showDueItems.map((dueItem, key) => (
                                    <React.Fragment key={key}>
                                        <tr>
                                            <td>
                                                <Label><b>{key + 1}</b></Label>
                                            </td>
                                            <td>
                                                <Label>{getItemName(dueItem.attributes.itemId)}</Label>
                                            </td>
                                            <td>
                                                <Label className="font-size-12 mb-1">{dueItem.attributes.orderdQuantity}</Label>
                                            </td>
                                            <td>
                                                <Label className="font-size-12 mb-1">{dueItem.attributes.deliveringQuantity}</Label>
                                            </td>
                                            <td>
                                                <Label className="font-size-12 mb-1"><b>{dueItem.attributes.dueQuantity}</b></Label>
                                            </td>
                                        </tr>
                                        {dueItem.attributes.matrix && dueItem.attributes.matrix.length > 0 && (
                                            <tr>
                                                <td colSpan="5">
                                                    <div className="table-responsive text-center" style={{ width: '100%' }}>
                                                        <table className="table table-centered table-bordered mb-0 table-sm" style={{ width: '90%', margin: 'auto' }}>
                                                            <thead className="thead-light">
                                                                <tr>
                                                                    {dueItem.attributes.matrix.some(matrixItem => matrixItem.isColor) && <th>Color Value</th>}
                                                                    {dueItem.attributes.matrix.some(matrixItem => matrixItem.isSize) && <th>Size Value</th>}
                                                                    <th>Ordered Quantity</th>
                                                                    <th>Delivered Quantity</th>
                                                                    <th>Due Quantity</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {dueItem.attributes.matrix.map((matrixItem, matrixKey) => (
                                                                    <tr key={matrixKey}>
                                                                        {matrixItem.isColor && <td>{matrixItem.colorValue}</td>}
                                                                        {matrixItem.isSize && <td>{matrixItem.sizeValue}</td>}
                                                                        <td>{matrixItem.selectedQuntity}</td>
                                                                        <td>{matrixItem.deliveringQuantity}</td>
                                                                        <td><b>{matrixItem.dueQuantity}</b></td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </ModalBody>
                <ModalFooter>
                    <Button
                        className='btn'
                        color='primary'
                        onClick={() => {
                            window.history.back()
                            setShowDueItems(false)
                        }}>Done</Button>
                </ModalFooter>
            </Modal>
            {approveAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Accept"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    title="Alert!"
                    onCancel={() => {
                        setApproveAlert(false);
                    }}
                    onConfirm={() => handleApproveOrder()}
                >
                    <div>
                        {"Are you sure you want to Accept,"}
                        <br />
                        {"On accepting this sale order, stock will be deducted and the sale order will be frozen."}

                    </div>
                </SweetAlert>
            )}
            {approveState && (
                <SweetAlert
                    warning
                    showCancel={false}
                    confirmBtnText="OK"
                    confirmBtnBsStyle="success"
                    title="Alert!"
                    onConfirm={() => {
                        setApproveState(false);
                        window.history.back()
                    }}
                >

                    {approveState}
                    <br></br>
                </SweetAlert>
            )}
            <div>
                <Row>
                    <Row className='d-flex align-items-start'>
                        <Col lg={10} className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <h4>{`Sale Order Summary  (#${saleOrder && saleOrder.attributes.saleOrderNumber}) - ${getSchoolName(saleOrder && saleOrder.attributes.buyerId)}`}</h4>
                            </div>
                        </Col>
                        <Col lg={2} className='d-flex justify-content-end'>
                            <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                {false && <div className="mb-3  float-sm-right form-inline">
                                    <Link to={schoolId ? `/print-sale-order/${schoolId}/${saleOrderId}` : `/franchise-print-sale-order/${franchiseId}/${saleOrderId}`}>
                                        <Button
                                            color="success"
                                            style={{ float: "right" }}
                                            className="btn waves-effect waves-light float-right  m-2"
                                        >
                                            Print
                                        </Button>
                                    </Link>
                                </div>}
                            </div>
                        </Col>
                    </Row>
                    <Col>
                        <Card>
                            <CardBody>
                                {loading && (
                                    <Col xs="12">
                                        <div className="text-center my-3">
                                            <Link to="#" className="text-success">
                                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                                Loading....{" "}
                                            </Link>
                                        </div>
                                    </Col>
                                )}
                                {!loading && <div className="mt-1">
                                    <div className="border rounded p-3 mb-1" style={{ backgroundColor: "white" }}>
                                        <Row style={{ height: "8px", paddingBottom: "20px" }}>
                                            <Col lg={1}>
                                                <div>
                                                    <Label className="mb-1 font-size-14">#</Label>
                                                </div>
                                            </Col>
                                            <Col lg={3}>
                                                <div>
                                                    <Label className="mb-1 font-size-14">Item Name</Label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <Label className="mb-1 font-size-14">Quantity</Label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <Label className="mb-1 font-size-14">Stock</Label>
                                                </div>
                                            </Col>
                                            <Col>
                                                <div>
                                                    <Label className="mb-1 font-size-14">Matrix</Label>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div>
                                                    <Label className="mb-1 font-size-14">Delivering Quantity</Label>
                                                </div>
                                            </Col>
                                            <Col lg={2}>
                                                <div>
                                                    <Label className="mb-1 font-size-14">Due Quantity</Label>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                    {items.map((itemData, index) => (
                                        <Card className="mb-1 bg-whites" key={itemData.id}>
                                            <CardBody>
                                                <Row>
                                                    <Col lg={1}>
                                                        <div className="d-flex">{index + 1}</div>
                                                    </Col>
                                                    <Col lg={3}>
                                                        <div className="pt-1">{itemData.itemName}</div>
                                                    </Col>
                                                    <Col>
                                                        <div>{itemData.quantity}</div>
                                                    </Col>
                                                    <Col>
                                                        <div>{itemData.stock}</div>
                                                    </Col>
                                                    <Col>
                                                        {itemData.combinations && itemData.combinations.length > 0 && <div>
                                                            <Label
                                                                onClick={() => {
                                                                    setShowItemMatrix(showItemMatrix === itemData.id ? '' : itemData.id);
                                                                }}
                                                            >
                                                                <u className='text-primary'>{showItemMatrix === itemData.id ? 'Close' : 'Matrix'}</u>
                                                            </Label>
                                                        </div>}
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Input
                                                            name="Delivering Quantity"
                                                            placeholder="0.00"
                                                            type="number"
                                                            className="form-control"
                                                            value={itemData.deliveringQuantity}
                                                            min={0}
                                                            max={itemData.quantity > itemData.stock ? itemData.stock : itemData.quantity}
                                                            disabled={(itemData.combinations && itemData.combinations.length > 0) ? true : false}
                                                            // onChange={(e) => {
                                                            //     let tempItems = [...items]
                                                            //     tempItems[index].deliveringQuantity = parseInt(e.target.value);
                                                            //     tempItems[index].dueQuantity = tempItems[index].quantity - tempItems[index].deliveringQuantity;
                                                            //     setItems(tempItems)
                                                            // }}
                                                            onChange={(e) => {
                                                                let tempItems = [...items];
                                                                let inputValue = parseInt(e.target.value);
                                                            
                                                                let maxValue = Math.min(itemData.quantity, itemData.stock);
                                                                if (inputValue > maxValue) {
                                                                    inputValue = maxValue;
                                                                }
                                                            
                                                                tempItems[index].deliveringQuantity = inputValue;
                                                                tempItems[index].dueQuantity = tempItems[index].quantity - inputValue;
                                                                setItems(tempItems);
                                                            }}
                                                            
                                                        />
                                                        {itemData.combinations && itemData.combinations.length > 0 && <div>
                                                            <Label
                                                                onClick={() => {
                                                                    setShowItemMatrix(showItemMatrix === itemData.id ? '' : itemData.id);
                                                                }}
                                                            >
                                                                <u className='text-primary badge bage-success'>{showItemMatrix === itemData.id ? 'Done' : 'Edit quantity'}</u>
                                                            </Label>
                                                        </div>}
                                                    </Col>
                                                    <Col lg={2}>
                                                        <Input
                                                            name="Due Quantity"
                                                            placeholder="0.00"
                                                            type="number"
                                                            className="form-control"
                                                            value={itemData.dueQuantity}
                                                            disabled={true}
                                                        />
                                                    </Col>
                                                </Row>
                                                {itemData.combinations && itemData.combinations.length > 0 && <Collapse isOpen={showItemMatrix === itemData.id}>
                                                    <div className="m-3">
                                                        <Table className="table mb-0 table-bordered">
                                                            <thead>
                                                                <tr>
                                                                    {itemData.combinations.some(comb => comb.isColor) && <th style={{ width: '15%', textAlign: 'center' }}>Color</th>}
                                                                    {itemData.combinations.some(comb => comb.isSize) && <th style={{ width: '15%', textAlign: 'center' }}>Size</th>}
                                                                    <th style={{ width: '15%', textAlign: 'center' }}>Required Quantity</th>
                                                                    <th style={{ width: '15%', textAlign: 'center' }}>Stock</th>
                                                                    <th style={{ width: '20%', textAlign: 'center' }}>Delivering Quantity</th>
                                                                    <th style={{ width: '20%', textAlign: 'center' }}>Due Quantity</th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {itemData && itemData.combinations && itemData.combinations.map((matrixData, matrixIndex) => (
                                                                    <tr key={matrixIndex}>
                                                                        {matrixData.isColor && <td style={{ width: '15%', textAlign: 'center' }}>{matrixData.colorValue}</td>}
                                                                        {matrixData.isSize && <td style={{ width: '15%', textAlign: 'center' }}>{matrixData.sizeValue}</td>}
                                                                        <td style={{ width: '15%', textAlign: 'center' }}>{matrixData.selectedQuntity}</td>
                                                                        <td style={{ width: '15%', textAlign: 'center' }}>{matrixData.stock}</td>
                                                                        <td style={{ width: '20%' }}>
                                                                            <Input
                                                                                name="Delivaring Stock"
                                                                                placeholder="0.00"
                                                                                type="number"
                                                                                className="form-control"
                                                                                value={matrixData.deliveringQuantity}
                                                                                min={0}
                                                                                max={matrixData.selectedQuntity > matrixData.stock ? matrixData.stock : matrixData.selectedQuntity}
                                                                                // onChange={(e) => {
                                                                                //     let tempItems = [...items]
                                                                                //     let tempItem = tempItems[index]
                                                                                //     let tempComb = tempItem.combinations[matrixIndex]

                                                                                //     tempComb.deliveringQuantity = parseInt(e.target.value)
                                                                                //     tempComb.dueQuantity = tempComb.selectedQuntity - tempComb.deliveringQuantity


                                                                                //     let tDue = 0
                                                                                //     for (const c of tempItem.combinations) {
                                                                                //         if (c.id != matrixData.id) {
                                                                                //             tDue = tDue + c.dueQuantity
                                                                                //         }
                                                                                //     }

                                                                                //     tDue = tDue + tempComb.dueQuantity;
                                                                                //     tempItems[index].dueQuantity = tDue;
                                                                                //     tempItems[index].deliveringQuantity = tempItems[index].quantity - tempItems[index].dueQuantity;
                                                                                //     tempItems[index].combinations[matrixIndex] = tempComb;
                                                                                //     setItems(tempItems)
                                                                                // }}
                                                                                onChange={(e) => {
                                                                                    let tempItems = [...items];
                                                                                    let tempItem = tempItems[index];
                                                                                    let tempComb = tempItem.combinations[matrixIndex];
                                                                                    let inputValue = parseInt(e.target.value);
                                                                                
                                                                                    let maxValue = Math.min(tempComb.selectedQuntity, tempComb.stock);
                                                                                    if (inputValue > maxValue) {
                                                                                        inputValue = maxValue;
                                                                                    }
                                                                                
                                                                                    tempComb.deliveringQuantity = inputValue;
                                                                                    tempComb.dueQuantity = tempComb.selectedQuntity - inputValue;
                                                                                
                                                                                    let tDue = 0;
                                                                                    for (const c of tempItem.combinations) {
                                                                                        if (c.id !== matrixData.id) {
                                                                                            tDue += c.dueQuantity;
                                                                                        }
                                                                                    }
                                                                                    tDue += tempComb.dueQuantity;
                                                                                    tempItems[index].dueQuantity = tDue;
                                                                                    tempItems[index].deliveringQuantity = tempItems[index].quantity - tDue;
                                                                                    tempItems[index].combinations[matrixIndex] = tempComb;
                                                                                
                                                                                    setItems(tempItems);
                                                                                }}
                                                                                
                                                                            />
                                                                        </td>
                                                                        <td style={{ width: '20%' }}>
                                                                            <Input
                                                                                name="Required Stock"
                                                                                placeholder="0.00"
                                                                                type="number"
                                                                                className="form-control"
                                                                                value={matrixData.dueQuantity}
                                                                                disabled={true}
                                                                            />
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Collapse>}
                                            </CardBody>
                                        </Card>
                                    ))}
                                </div>}
                                <div className='m-2'>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn waves-effect waves-light  float-end"
                                        onClick={() => {
                                            setApproveAlert(true);
                                        }}
                                    >
                                        Accept
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div >
    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { inventoryItems, inventoryMatrixs } = state.InventoryItems;
    const { selectedInventorySaleOrder } = state.InventorySaleOrder;
    const { selectedInventorySaleOrderItems } = state.InventorySaleOrderItem;
    const { inventoryKits } = state.InventoryKits;
    const { kids } = state.Kid;
    const { teachers } = state.Teacher;
    const { inventoryWarehouses, inventoryItemStockMap } = state.InventoryWarehouse;

    return {
        selectedSchool, schools, inventoryItems, inventoryMatrixs,
        kids, teachers, selectedInventorySaleOrder,
        selectedInventorySaleOrderItems, inventoryWarehouses, inventoryItemStockMap,
        inventoryKits,
    };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getInventoryItemForOwnerId, getTeachersForSchool,
    getTeacher, getAllKids, setSelectedSaleOrder,
    setSelectedSaleOrderItems, getInventoryWarehouseForOwnerId,
    getInventoryKitsForOwnerId, getInventoryItemMatrixForItemIds,
})(saleOrderSummary)));

