import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row, Table, UncontrolledTooltip, Modal, ModalBody, ModalFooter, ModalHeader, Badge, FormGroup, Label, Input } from 'reactstrap';
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import UpdatePhoto from "../../components/Common/UpdatePhoto";
import Moment from 'moment';
import Editable from "react-bootstrap-editable";
import { updateAKid, updateAParent, deleteKidsInList, updateKidTosterSuccess, updateAdditionalDetailsForAKid, disableKid } from '../../store/actions';
import { connect } from 'react-redux';
import Parse from 'parse'
import toastr from 'toastr'
import * as Constants from "../../Constents";
import Select from "react-select";
import SweetAlert from "react-bootstrap-sweetalert";


const DetailView = (props) => {
    const [image, imgOps] = useState('')
    const [name, nameOps] = useState('')
    const [admissionNumber, admissionOps] = useState('')
    const [gender, genderOps] = useState('')
    const [DOB, DOBOps] = useState(undefined)
    const [deleteReasonError, setDeleteReasonError] = useState(false)
    const [reasonForDelete, setReasonForDelete] = useState(undefined)
    const [mother, motherOps] = useState({ name: '', number: '', email: '', corporateEmployeeId: '', corporateEmailId: '' })
    const [father, fatherOps] = useState({ name: '', number: '', email: '', corporateEmployeeId: '', corporateEmailId: '' })
    const [emergencyContact, setEmergencyContact] = useState({ name: '', number: '' })
    const [classRooms, classIdsOps] = useState([])
    const [showDelete, showDeleteOps] = useState(false)
    const [isProfileDeleted, setIsProfileDeleted] = useState(false)

    const [email, setEmail] = useState('')
    const [phoneNumber, setPhoneNumber] = useState('')
    const [workplace, setWorkplace] = useState('');
    const [designation, setDesignation] = useState('');
    const [qualification, setQualification] = useState('');
    const [showIDCard, setShowIdCard] = useState(false)

    const feeType = [{ value: "Monthly", label: "Monthly" }, { value: "Term", label: "Term" }, { value: "Yearly", label: "Yearly" }];
    const programType = [{ value: "Half Day", label: "Half Day" }, { value: "Full Day", label: "Full Day" }, { value: "AFS-A", label: "AFS-A" }, { value: "AFS-B", label: "AFS-B" }];
    const [selectedFeeType, setSelectedFeeType] = useState('');
    const [selectedProgramType, setSelectedProgramType] = useState('');
    const [selectedCorporatePartners, setSelectedCorporatePartners] = useState('');
    const [allCorporatePartners, setAllCorporatePartners] = useState([]);


    const [showEditfeeType, setShowEditfeeType] = useState(false);
    const [showEditProgramType, setShowEditProgramType] = useState(false)
    const [showEditCorporatePartners, setShowEditCorporatePartners] = useState(false)
    const [showCorporateRemove, setShowCorporateRemove] = useState(false)
    const [showKidDisableAlert, setShowKidDisableAlert] = useState(false)
    const [showKidEnableAlert, setShowKidEnableAlert] = useState(false)

    useEffect(() => {

        if (props.kidCAD && props.kidCAD.attributes.kidId == props.kidId) {
            setEmergencyContact({
                name: props.kidCAD.attributes.emergencyContactName,
                number: props.kidCAD.attributes.emergencyContactNumber,
            });
        }
    }, [props.kidCAD])
    useEffect(() => {
        imgOps(props.kid.attributes.Photo ? props.kid.attributes.Photo._url : '')
        nameOps(props.kid.attributes.Name ? props.kid.attributes.Name : '----')
        admissionOps(props.kid.attributes.admissionNumber ? props.kid.attributes.admissionNumber : '')
        genderOps(props.kid.attributes.Gender ? props.kid.attributes.Gender : '-----')
        if (props.kid.attributes.DateOfBirth) {
            DOBOps(props.kid.attributes.DateOfBirth)
        } else {
            DOBOps(undefined)
        }
        setEmail(props.kid.attributes.email ? props.kid.attributes.email : '----')
        setPhoneNumber(props.kid.attributes.phoneNumber ? props.kid.attributes.phoneNumber : '----')
        setWorkplace(props.kid.attributes.workplace ?? '----')
        setDesignation(props.kid.attributes.designation ?? '----')
        setQualification(props.kid.attributes.qualification ?? '----')
        setIsProfileDeleted(props.kid.attributes.Deleted ? true : false)

        if (props.kid.attributes.feeType) {
            setSelectedFeeType({ value: props.kid.attributes.feeType, label: props.kid.attributes.feeType })
        }
        if (props.kid.attributes.programType) {
            setSelectedProgramType({ value: props.kid.attributes.programType, label: props.kid.attributes.programType })
        }
        if (props.kid.attributes.corporateId) {
            setSelectedCorporatePartners({ value: props.kid.attributes.corporateId, label: getCorporatePartnerName(props.kid.attributes.corporateId) })
        }

        if (props.kid.parents.length > 0) {
            for (var i in props.kid.parents) {
                if (props.kid.parents[i].attributes.Relation === 'Mother') {
                    let temp = {}
                    temp.name = props.kid.parents[i].attributes.Name
                    temp.number = props.kid.parents[i].attributes.PhoneNumber
                    temp.email = props.kid.parents[i].attributes.EMail
                    temp.familyId = props.kid.parents[i].attributes.FamilyID
                    temp.corporateEmployeeId = props.kid.parents[i].attributes.corporateEmployeeId
                    temp.corporateEmailId = props.kid.parents[i].attributes.corporateEmailId;
                    motherOps(temp)
                } else if (props.kid.parents[i].attributes.Relation === 'Father') {
                    let temp = {}
                    temp.name = props.kid.parents[i].attributes.Name
                    temp.number = props.kid.parents[i].attributes.PhoneNumber
                    temp.email = props.kid.parents[i].attributes.EMail
                    temp.familyId = props.kid.parents[i].attributes.FamilyID
                    temp.corporateEmployeeId = props.kid.parents[i].attributes.corporateEmployeeId
                    temp.corporateEmailId = props.kid.parents[i].attributes.corporateEmailId;
                    fatherOps(temp)
                }
            }
        }
        let classDec = []
        for (let i in props.classRooms) {
            classDec[props.classRooms[i].id] = props.classRooms[i]
        }
        let classRoomList = []
        let primaryClass = classDec[props.kid.attributes.ClassRoomID] ? classDec[props.kid.attributes.ClassRoomID].attributes.Name : ''
        if (primaryClass) {
            let setColor = 'primary'
            if (classDec[props.kid.attributes.ClassRoomID].attributes.classroomType === 2) {
                setColor = 'danger'
            }
            classRoomList.push({
                name: primaryClass,
                color: setColor
            })
        }
        if (props.kid.attributes.daycareClassId) {
            classRoomList.push({
                name: classDec[props.kid.attributes.daycareClassId] ? classDec[props.kid.attributes.daycareClassId].attributes.Name : '',
                color: 'danger'
            })
        }
        classIdsOps(classRoomList)
    }, [props.kid])

    useEffect(() => {
        if (props.franchise) {
            if (props.franchise.attributes.features &&
                props.franchise.attributes.features.length > Constants.FRANCHISE_ID_CARD_DISABLE_INDEX &&
                props.franchise.attributes.features[Constants.FRANCHISE_ID_CARD_DISABLE_INDEX] > 0) {
                setShowIdCard(false)
            }
            else if (!showIDCard) {
                setShowIdCard(true)
            }
        }

    }, [props.franchise]);

    useEffect(() => {
        if (props.corporatePartners) {
            let list = [];
            for (const corporate of props.corporatePartners) {
                list.push({ value: corporate.id, label: corporate.attributes.name })
            }
            setAllCorporatePartners(list);
        }

    }, [props.corporatePartners])

    const admissionNumberUpdate = (value, relation) => {
        admissionOps(value)
        let kid = props.kid
        kid.set('admissionNumber', value)
        props.updateAKid(kid)
    }
    const dobUpdate = value => {
        DOBOps(value)
        let kid = props.kid
        kid.set('DateOfBirth', value)
        props.updateAKid(kid)
    }
    const emailUpdate = (value, relation) => {
        setEmail(value)
        let kid = props.kid
        kid.set('email', value)
        props.updateAKid(kid)
    }
    const phoneNumberUpdate = (value, relation) => {
        setPhoneNumber(value)
        let kid = props.kid
        kid.set('phoneNumber', value)
        props.updateAKid(kid)
    }
    const handleDeleteKid = () => {
        let kid = props.kid;
        kid.set('Deleted', true);
        kid.set('deletedOn', new Date())
        kid.set('deletedStatus', reasonForDelete);
        props.deleteKidsInList([kid], props.t('Student deleted successfully'));
    }

    const handleDisableKid = () => {
        let kid = props.kid;
        props.disableKid(kid.id, Parse.User.current().attributes.teacherId, true)
    }

    const handleEnableKid = () => {
        let kid = props.kid;
        props.disableKid(kid.id, Parse.User.current().attributes.teacherId, false)
    }

    useEffect(() => {
        if (props.updatedKidSuccess) {
            toastr.success('Student Updated Successfully.', { timeOut: 1500 })
            props.updateKidTosterSuccess();
        }
    }, [props.updatedKidSuccess])
    const parentNameUpdate = (value, relation) => {
        if (relation === "Mother") {
            let tempMother = mother
            tempMother.name = value
            motherOps(tempMother)
        } else if (relation === "Father") {
            let tempFather = father
            tempFather.name = value
            fatherOps(tempFather)
        }
        let tempParentObj
        for (var i in props.kid.parents) {
            if (props.kid.parents[i].attributes.Relation === relation) {
                tempParentObj = props.kid.parents[i]
            }
        }
        let parentObj = tempParentObj
        parentObj.set('Name', value)

        props.updateAParent(parentObj)
    }

    const parentsCorporateEmployeeUpdate = (value, relation, key) => {
        if (relation === "Mother") {
            let tempMother = mother
            if (key == "corporateEmailId") {
                tempMother.corporateEmailId = value
            }
            if (key == "corporateEmployeeId") {
                tempMother.corporateEmployeeId = value
            }

            motherOps(tempMother)
        } else if (relation === "Father") {
            let tempFather = father
            if (key == "corporateEmailId") {
                tempFather.corporateEmailId = value
            }
            if (key == "corporateEmployeeId") {
                tempFather.corporateEmployeeId = value
            }
            fatherOps(tempFather)
        }
        let tempParentObj
        for (var i in props.kid.parents) {
            if (props.kid.parents[i].attributes.Relation === relation) {
                tempParentObj = props.kid.parents[i]
            }
        }
        let parentObj = tempParentObj
        parentObj.set(key, value)

        props.updateAParent(parentObj)
    }
    const parentNumberUpdate = (value, relation) => {
        if (relation === "Mother") {
            let tempMother = mother
            tempMother.number = value
            motherOps(tempMother)
        } else if (relation === "Father") {
            let tempFather = father
            tempFather.number = value
            fatherOps(tempFather)
        }
        let tempParentObj
        for (var i in props.kid.parents) {
            if (props.kid.parents[i].attributes.Relation === relation) {
                tempParentObj = props.kid.parents[i]
            }
        }
        let parentObj = tempParentObj
        parentObj.set('PhoneNumber', value + "")
        props.updateAParent(parentObj)
    }
    const parentEmailUpdate = (value, relation) => {
        if (relation === "Mother") {
            let tempMother = mother
            tempMother.email = value
            motherOps(tempMother)
        } else if (relation === "Father") {
            let tempFather = father
            tempFather.email = value
            fatherOps(tempFather)
        }
        let tempParentObj
        for (var i in props.kid.parents) {
            if (props.kid.parents[i].attributes.Relation === relation) {
                tempParentObj = props.kid.parents[i]
            }
        }
        let parentObj = undefined
        if (tempParentObj != undefined) {
            parentObj = tempParentObj

        } else {
            const ParentObject = Parse.Object.extend("Parent");
            parentObj = new ParentObject();
            let familyId = undefined
            if (relation == "Mother") {
                parentObj.set("Relation", "Mother");
                parentObj.set("FamilyID", father.familyId);
                familyId = father.familyId

            } else {
                parentObj.set("Relation", "Father");
                parentObj.set("FamilyID", mother.familyId);
                familyId = mother.familyId
            }

            parentObj.set("NotificationChoice", 16127);
            let groupACL = new Parse.ACL();
            groupACL.setRoleWriteAccess(props.schoolId + "_Admin", true);
            groupACL.setRoleReadAccess(props.schoolId + "_Admin", true);

            groupACL.setRoleWriteAccess(props.schoolId + "_Teacher", false);
            groupACL.setRoleReadAccess(props.schoolId + "_Teacher", true);

            groupACL.setRoleWriteAccess(familyId + "_Role", true);
            groupACL.setRoleReadAccess(familyId + "_Role", true);
            parentObj.setACL(groupACL);
        }

        parentObj.set('EMail', value)
        props.updateAParent(parentObj)
    }

    const emergencyContactUpdate = (value, type) => {
        let cadObject = props.kidCAD
        if (!cadObject) {
            const ChildAdditionalDetails = Parse.Object.extend("ChildAdditionalDetails");
            cadObject = new ChildAdditionalDetails();
            cadObject.set("kidId", props.kid.id);
        }
        cadObject.set(type, value)
        props.updateAdditionalDetailsForAKid(cadObject)
    }

    const handlePicChange = (imageFile) => {
        imageFile.save().then(() => {
            imgOps(imageFile._url)
            let kid = props.kid
            kid.set('Photo', imageFile)
            props.updateAKid(kid)
        })
    }

    const getCorporatePartnerName = (id) => {
        if (id && props.corporatePartners) {
            for (const cp of props.corporatePartners) {
                if (cp.id == id) {
                    return cp.attributes.name;
                }
            }
        }
        return "--"
    }

    const corporatePartnersUpdate = (value) => {

        let kid = props.kid
        kid.set('corporateId', value)
        props.updateAKid(kid)
        setShowEditfeeType(false);
    }

    const RemoveCorporatePartner = () => {

        let kid = props.kid
        kid.unset('corporateId')
        props.updateAKid(kid)
        setSelectedCorporatePartners(undefined)
        setShowCorporateRemove(false);
    }

    const feeTypeUpdate = (value) => {

        let kid = props.kid
        kid.set('feeType', value)
        props.updateAKid(kid)
        setShowEditfeeType(false);
    }
    console.log(props.kid)
    const programTypeUpdate = (value) => {

        let kid = props.kid
        kid.set('programType', value)
        props.updateAKid(kid)
        setShowEditProgramType(false);
    }

    function calculateAge(birthDate) {
        const birthDateTimeStamp = new Date(birthDate).getTime();
        const now = new Date().getTime();
  
        const ageMilliseconds = now - birthDateTimeStamp;
        const years = Math.floor(ageMilliseconds / (1000 * 60 * 60 * 24 * 365.25));
        const remainingMilliseconds = ageMilliseconds % (1000 * 60 * 60 * 24 * 365.25);
        const months = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24 * (365.25 / 12)));
  
        let ageString = `${years} years`;
        if (months > 0) {
          ageString += ` ${months} months`;
        }
  
        return ageString;
    }

    return (
        <Card className='d-print-none'>
            {showCorporateRemove && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowCorporateRemove(false);
                    }}
                    onConfirm={() => RemoveCorporatePartner()}
                >
                    {"Are you sure you want to delete"}
                    <br></br>
                </SweetAlert>
            )}
            {showKidDisableAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowKidDisableAlert(false);
                    }}
                    onConfirm={() => {
                        handleDisableKid()
                        setShowKidDisableAlert(false);
                    }}
                >
                    {"Once disabled, parents will have restricted access, limited to functionalities related to payments and communication."}
                    <br></br>
                </SweetAlert>
            )}
            {showKidEnableAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowKidEnableAlert(false);
                    }}
                    onConfirm={() => {
                        handleEnableKid()
                        setShowKidEnableAlert(false);
                    }}
                >
                    {"Upon enabling, all restrictions on feature usage for parents within their app will be lifted."}
                    <br></br>
                </SweetAlert>
            )}
            <Modal
                isOpen={showDelete}
                toggle={() => {
                    if (showDelete) {
                        setReasonForDelete(undefined)
                        setDeleteReasonError(false)
                    }
                    showDeleteOps(!showDelete)

                }}
            >
                <ModalHeader toggle={() => {
                    showDeleteOps(false);
                    setReasonForDelete(undefined)
                    setDeleteReasonError(false)
                }}>
                    <h5 className="modal-title mt-0" id="deleteKidModel">
                        {props.t('Please select a reason for delete')}
                    </h5>
                </ModalHeader>

                <ModalBody >
                    <div>
                        <Badge onClick={(e) => {
                            setReasonForDelete(1);
                            setDeleteReasonError(false);
                        }}
                            style={{ cursor: "pointer" }}
                            className={reasonForDelete && reasonForDelete === 1 ? "badge badge-secondary font-size-14 m-2" : "badge badge-soft-primary font-size-14 m-2"}>{props.t('Passed Out')}
                        </Badge>
                        <Badge onClick={(e) => {
                            setReasonForDelete(2);
                            setDeleteReasonError(false);
                        }}
                            style={{ cursor: "pointer" }}
                            className={reasonForDelete && reasonForDelete === 2 ? "badge badge-secondary font-size-14 m-2" : "badge badge-soft-primary font-size-14 m-2"}>{props.t('Drop Out')}</Badge>

                        <Badge onClick={(e) => {
                            setReasonForDelete(3)
                            setDeleteReasonError(false);
                        }}
                            style={{ cursor: "pointer" }}
                            className={reasonForDelete && reasonForDelete === 3 ? "badge badge-secondary font-size-14 m-2" : "badge badge-soft-primary font-size-14 m-2"}>{props.t('Improper Data')}
                        </Badge>
                        <Badge onClick={(e) => {
                            setReasonForDelete(4)
                            setDeleteReasonError(false);
                        }}
                            style={{ cursor: "pointer" }}
                            className={reasonForDelete && reasonForDelete === 4 ? "badge badge-secondary font-size-14 m-2" : "badge badge-soft-primary font-size-14 m-2"}>{props.t('Incomplete Documents')}
                        </Badge>
                    </div>
                    {deleteReasonError && <p className="text-danger ml-2">{props.t('Please select a reason before proceeding')}</p>}


                </ModalBody>
                <ModalFooter >
                    <button
                        type="button"
                        onClick={() => {
                            showDeleteOps(false);
                            setReasonForDelete(undefined)
                            setDeleteReasonError(false)
                        }}
                        className="btn btn-secondary waves-effect"
                        data-dismiss="modal"
                    >
                        {props.t('Close')}
                    </button>
                    <button
                        type="submit"
                        className="btn btn-danger waves-effect waves-light"
                        onClick={() => {
                            if (reasonForDelete === undefined) {
                                setDeleteReasonError(true);
                            } else {
                                handleDeleteKid()
                            }
                        }}
                    >
                        {props.t('Delete')}
                    </button>
                </ModalFooter>

            </Modal>

            <CardBody className='d-print-none'>
                <Row>
                    <Col lg={3} md={4} xs={12} className="text-center "></Col>
                    <Col lg={9} md={8} xs={12}>
                        <div className=" d-flex align-items-center justify-content-between">
                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                <h3 className="center" style={{ marginRight: '10px' }}>{name.toUpperCase()}</h3>
                                {!isProfileDeleted  && props.kid.attributes.SchoolID && <h4 className='text-secondary' onClick={() => {
                                    if (props.kid.attributes.isDisabled) {
                                        setShowKidEnableAlert(true)
                                    } else {
                                        setShowKidDisableAlert(true);
                                    }
                                }}>{props.kid.attributes.isDisabled == true ? "(Disabled)" : ""}</h4>}

                                {isProfileDeleted && <h4 className='text-danger'>{"(Deleted)"}</h4>}
                                
                            </div>

                            {!isProfileDeleted && <div className="page-title-right contact-links d-flex font-size-20">
                                {props.kid.attributes.SchoolID && <div className="flex-fill m-1 ">
                                    <Link className="text-danger"
                                        onClick={() => {
                                            if (props.kid.attributes.isDisabled) {
                                                setShowKidEnableAlert(true)
                                            } else {
                                                setShowKidDisableAlert(true);
                                            }
                                        }}
                                        to={"#"} id={"kidDisable" + props.kid.id}>
                                        <i className="bx bx-block" ></i>
                                        <UncontrolledTooltip placement="top" target={"kidDisable" + props.kid.id}>
                                            Disabled
                                        </UncontrolledTooltip>
                                    </Link>
                                </div>}
                                {showIDCard && <div className="flex-fill m-1 ">
                                    <Link
                                        to={"/studentIdcard/" + props.schoolId + "/" + props.kid.id} target="_blank" id={"idCard" + props.kid.id} >
                                        <i className="bx bx-id-card" ></i>
                                        <UncontrolledTooltip placement="top" target={"idCard" + props.kid.id}>
                                            ID Card
                                        </UncontrolledTooltip>
                                    </Link>
                                </div>}
                                {(props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) && <div className="flex-fill m-1 ">
                                    <Link
                                        to={`/studentEdit/${props.schoolId}/${props.kid.id}`}
                                        id="profile-edit"
                                    >
                                        <i className="bx bx-edit-alt"></i>
                                        <UncontrolledTooltip
                                            placement="top"
                                            target={"profile-edit"}
                                        >
                                            {props.t("Edit")}
                                        </UncontrolledTooltip>
                                    </Link>
                                </div>}

                                <div
                                    className="flex-fill m-1"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                        showDeleteOps(true);
                                    }}
                                    id="profile-delete"
                                >
                                    <i className="bx bxs-trash text-danger"></i>
                                    <UncontrolledTooltip
                                        placement="top"
                                        target={"profile-delete"}
                                    >
                                        {props.t("Delete")}
                                    </UncontrolledTooltip>
                                </div>
                            </div>}
                        </div>
                    </Col>
                    {/* </Col> */}
                </Row>
                <Row>
                    <Col lg={3} md={4} xs={12} className="text-center ">
                        <div className="py-4">
                            {!image ? (
                                <div className="avatar-lg mx-auto" style={{ width: '10rem', height: '10rem' }}>
                                    <span
                                        className={
                                            "avatar-title rounded-circle bg-soft-primary text-primary"
                                        }
                                        style={{ fontSize: '60px' }}
                                    >
                                        {name.charAt(0).toUpperCase()}
                                    </span>
                                </div>
                            ) : (
                                <div>
                                    <img
                                        className="rounded-circle img-fluid mx-auto d-block"
                                        style={{ width: '10rem', height: '10rem' }}
                                        src={image}
                                        alt=""
                                    />
                                </div>
                            )}
                        </div>

                        {!isProfileDeleted &&<UpdatePhoto
                            isPopupOn={false}
                            onPicChange={(imageFile) => {
                                handlePicChange(imageFile);
                            }}
                            initialImage={image}
                        ></UpdatePhoto>}
                    </Col>
                    <Col lg={9} md={8} xs={12}>
                        <div className="">
                            <div className="table-responsive">
                                <Table className="table mb-0 table-bordered">
                                    <tbody>
                                        <tr>
                                            <th scope="row" style={{ width: "26%" }}>
                                                {props.t("Admission Number")}
                                            </th>
                                            <td>
                                                <Editable
                                                    className={'d-flex'}
                                                    onValidated={(value) => admissionNumberUpdate(value)}
                                                    disabled={isProfileDeleted}
                                                    editText={admissionNumber ? " ✎" : "No value ✎"}
                                                    id={null}
                                                    isValueClickable={false}
                                                    mode="inline"
                                                    placement="top"
                                                    initialValue={admissionNumber ? admissionNumber : ""}
                                                    showText
                                                    type="textfield"
                                                    validate={(value) => {
                                                        if (!value) {
                                                            return props.t("You have entered an invalid admission number!")
                                                        }
                                                    }}
                                                />
                                            </td>
                                        </tr>
                                        <tr>
                                            <th scope="row">
                                                {props.t("Gender")}
                                            </th>
                                            <td>{gender.toUpperCase()}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row">{props.t("Date Of Birth")}</th>
                                            <td>
                                                {(props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) ? <Editable
                                                    className={'d-flex'}
                                                    onValidated={(value) => dobUpdate(value)}
                                                    disabled={isProfileDeleted}
                                                    editText={DOB ? " ✎" : "No value ✎"}
                                                    id={null}
                                                    isValueClickable={false}
                                                    mode="inline"
                                                    placement="top"
                                                    initialValue={DOB ? DOB : undefined}
                                                    showText
                                                    type="date"
                                                    validate={(value) => {
                                                        if (!value) {
                                                            return props.t("You have entered an invalid DOB!")
                                                        }
                                                    }}
                                                /> : <p className='p-0 m-0'>{DOB}</p>
                                                }
                                                {calculateAge(DOB)}

                                            </td>
                                        </tr>
                                        {(props.selectedSchool && (props.selectedSchool.attributes.schoolType !== 10 && props.selectedSchool.attributes.schoolType !== 11)) && <tr>
                                            <th scope="row">
                                                {props.t("Mother")} {props.t("Name")}
                                                <br /> {props.t("Mother")} {props.t("Phone Number")}
                                                <br /> {props.t("Mother")} {props.t("Email")}
                                                {props.showCorporatePartners && <br />} {props.showCorporatePartners && props.t("Corporate EmployeeId")}
                                                {props.showCorporatePartners && <br />} {props.showCorporatePartners && props.t("Corporate EmailId")}
                                            </th>
                                            <td className="d-flex align-items-center justify-content-between">
                                                <Col>
                                                    <Editable
                                                        className={'d-flex'}
                                                        onValidated={(value) => parentNameUpdate(value, "Mother")}
                                                        disabled={isProfileDeleted}
                                                        editText={mother.name ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={mother.name ? mother.name : ""}
                                                        showText
                                                        type="textfield"
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return props.t("You have entered an invalid Name!")
                                                            }
                                                        }}
                                                    />
                                                    <Editable
                                                        className={'d-flex'}
                                                        onValidated={(value) => parentNumberUpdate(value, "Mother")}
                                                        disabled={isProfileDeleted}
                                                        editText={mother.number ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={mother.number ? mother.number : ""}
                                                        showText
                                                        type="textfield"
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return props.t("You have entered an invalid number!")
                                                            }
                                                            if (value.includes(' ')) {
                                                                return props.t("Phone number cannot contain spaces!");
                                                            }
                                                        }}
                                                    />
                                                    <Editable
                                                        className={'d-flex'}
                                                        onValidated={(value) => parentEmailUpdate(value, 'Mother')}
                                                        disabled={isProfileDeleted}
                                                        editText={mother.email ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={mother.email ? mother.email : ""}
                                                        showText
                                                        type="textfield"
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return props.t("You have entered an invalid email!")
                                                            }
                                                        }}
                                                    />

                                                    {props.showCorporatePartners && <Editable
                                                        className={'d-flex'}
                                                        onSubmit={(value) => { parentsCorporateEmployeeUpdate(value, 'Mother', "corporateEmployeeId") }}
                                                        disabled={isProfileDeleted}
                                                        editText={mother.corporateEmployeeId ? " ✎" : "No value ✎"}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={mother.corporateEmployeeId ? mother.corporateEmployeeId : ""}
                                                        showText
                                                        type="textfield"

                                                    />}
                                                    {props.showCorporatePartners && <Editable
                                                        className={'d-flex'}
                                                        onSubmit={(value) => { parentsCorporateEmployeeUpdate(value, 'Mother', "corporateEmailId") }}
                                                        disabled={isProfileDeleted}
                                                        editText={mother.corporateEmailId ? " ✎" : "No value ✎"}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={mother.corporateEmailId ? mother.corporateEmailId : ""}
                                                        showText
                                                        type="textfield"

                                                    />}
                                                </Col>
                                                {props.kid && !props.kid.attributes.SchoolID && <div className='ml-auto'>
                                                    <a
                                                        className="bx bxl-whatsapp text-success d-inline-block p-1 font-size-16"
                                                        onClick={() => {
                                                            let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name;
                                                            let phoneNumber = mother.number;
                                                            let parentName = mother.name;
                                                            const message = `Dear ${parentName},\n\n<Your message>\n\nRegards,\n${schoolName}`;
                                                            const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                                                            window.open(whatsappUrl, '_blank');
                                                            // web.whatsapp.com
                                                        }}
                                                    ></a>

                                                </div>}
                                            </td>
                                        </tr>}
                                        {(props.selectedSchool && (props.selectedSchool.attributes.schoolType !== 10 && props.selectedSchool.attributes.schoolType !== 11)) && <tr>
                                            <th scope="row">
                                                {props.t("Father")} {props.t("Name")}
                                                <br /> {props.t("Father")} {props.t("Phone Number")}
                                                <br /> {props.t("Father")} {props.t("Email")}
                                                {props.showCorporatePartners && <br />} {props.showCorporatePartners && props.t("Corporate EmployeeId")}
                                                {props.showCorporatePartners && <br />} {props.showCorporatePartners && props.t("Corporate EmailId")}
                                            </th>
                                            <td className="d-flex align-items-center justify-content-between">
                                                <Col>
                                                    <Editable
                                                        className={'d-flex'}
                                                        onValidated={(value) => parentNameUpdate(value, "Father")}
                                                        disabled={isProfileDeleted}
                                                        editText={father.name ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={father.name ? father.name : ""}
                                                        showText
                                                        type="textfield"
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return props.t("You have entered an invalid name!")
                                                            }
                                                        }}
                                                    />
                                                    <Editable
                                                        className={'d-flex'}
                                                        onValidated={(value) => parentNumberUpdate(value, "Father")}
                                                        disabled={isProfileDeleted}
                                                        editText={father.number ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={father.number ? father.number : ""}
                                                        showText
                                                        type="textfield"
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return props.t("You have entered an invalid number!")
                                                            }
                                                        }}
                                                    />
                                                    <Editable
                                                        className={'d-flex'}
                                                        onValidated={(value) => parentEmailUpdate(value, 'Father')}
                                                        disabled={isProfileDeleted}
                                                        editText={father.email ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={father.email ? father.email : ""}
                                                        showText
                                                        type="textfield"
                                                        validate={(value) => {
                                                            if (!value) {
                                                                return props.t("You have entered an invalid email!")
                                                            }
                                                        }}
                                                    />

                                                    {props.showCorporatePartners && <Editable
                                                        className={'d-flex'}
                                                        onSubmit={(value) => { parentsCorporateEmployeeUpdate(value, 'Father', "corporateEmployeeId") }}
                                                        disabled={isProfileDeleted}
                                                        editText={father.corporateEmployeeId ? " ✎" : "No value ✎"}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={father.corporateEmployeeId ? father.corporateEmployeeId : ""}
                                                        showText
                                                        type="textfield"

                                                    />}
                                                    {props.showCorporatePartners && <Editable
                                                        className={'d-flex'}
                                                        onSubmit={(value) => { parentsCorporateEmployeeUpdate(value, 'Father', "corporateEmailId") }}
                                                        disabled={isProfileDeleted}
                                                        editText={father.corporateEmailId ? " ✎" : "No value ✎"}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={father.corporateEmailId ? father.corporateEmailId : ""}
                                                        showText
                                                        type="textfield"

                                                    />}
                                                </Col>
                                                {props.kid && !props.kid.attributes.SchoolID && <div className='ml-auto'>
                                                    <a
                                                        className="bx bxl-whatsapp text-success d-inline-block p-1 font-size-16"
                                                        onClick={() => {
                                                            let schoolName = props.selectedSchool && props.selectedSchool.attributes.Name;
                                                            let phoneNumber = father.number;
                                                            let parentName = father.name;
                                                            const message = `Dear ${parentName},\n\n<Your message>\n\nRegards,\n${schoolName}`;
                                                            const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(message)}`;
                                                            window.open(whatsappUrl, '_blank');
                                                            // web.whatsapp.com
                                                        }}
                                                    ></a>

                                                </div>}
                                            </td>
                                        </tr>}

                                        {(props.selectedSchool && (props.selectedSchool.attributes.schoolType !== 10 && props.selectedSchool.attributes.schoolType !== 11)) && <tr>
                                            <th scope="row">{props.t("Emergency Contact")} {props.t("Name")} <br /> {props.t("Emergency")} {props.t("Phone Number")} </th>
                                            <td>
                                                {/* <p className='p-0 m-0'>{props.kidCDA &&  props.kidCDA.attributes.emergencyContactName?props.kidCDA.attributes.emergencyContactName:"--"}</p>
                                                <p className='p-0 m-0'>{props.kidCDA && props.kidCDA.attributes.emergencyContactNumber ? props.kidCDA.attributes.emergencyContactNumber : "--"}</p> */}



                                                <Editable
                                                    className={'d-flex'}
                                                    onValidated={(value) => emergencyContactUpdate(value, "emergencyContactName")}
                                                    disabled={isProfileDeleted}
                                                    editText={emergencyContact.name ? " ✎" : "No value ✎"}
                                                    id={null}
                                                    isValueClickable={false}
                                                    mode="inline"
                                                    placement="top"
                                                    initialValue={emergencyContact.name ? emergencyContact.name : ""}
                                                    showText
                                                    type="textfield"
                                                    validate={(value) => {
                                                        if (!value) {
                                                            return props.t("You have entered an invalid name!")
                                                        }
                                                    }}
                                                />
                                                <Editable
                                                    className={'d-flex'}
                                                    onValidated={(value) => emergencyContactUpdate(value, "emergencyContactNumber")}
                                                    disabled={isProfileDeleted}
                                                    editText={emergencyContact.number ? " ✎" : "No value ✎"}
                                                    id={null}
                                                    isValueClickable={false}
                                                    mode="inline"
                                                    placement="top"
                                                    initialValue={emergencyContact.number ? emergencyContact.number : ""}
                                                    showText
                                                    type="textfield"
                                                    validate={(value) => {
                                                        if (!value) {
                                                            return props.t("You have entered an invalid number!")
                                                        }
                                                    }}
                                                />
                                            </td>
                                        </tr>}

                                        {(props.selectedSchool && (props.selectedSchool.attributes.schoolType === 10 || props.selectedSchool.attributes.schoolType == 11)) && <tr>
                                            <th scope="row" style={{ width: "26%" }}>
                                                {props.t("Email")}
                                            </th>
                                            <td>
                                                <p className='p-0 m-0'>{email}</p>
                                            </td>
                                        </tr>}

                                        {(props.selectedSchool && (props.selectedSchool.attributes.schoolType === 10 || props.selectedSchool.attributes.schoolType == 11)) && <tr>
                                            <th scope="row">{props.t("Phone Number")}</th>
                                            <td>
                                                <p className='p-0 m-0'>{phoneNumber}</p>
                                            </td>
                                        </tr>}
                                        {(props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) && <tr>
                                            <th scope="row">{props.t("Organization Name")}</th>
                                            <td>
                                                <p className='p-0 m-0'>{workplace}</p>
                                            </td>
                                        </tr>}
                                        {(props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) && <tr>
                                            <th scope="row">{props.t("Qualification")}</th>
                                            <td>
                                                <p className='p-0 m-0'>{qualification}</p>
                                            </td>
                                        </tr>}
                                        {(props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) && <tr>
                                            <th scope="row">{props.t("Designation")}</th>
                                            <td>
                                                <p className='p-0 m-0'>{designation}</p>
                                            </td>
                                        </tr>}



                                        {(props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) && <tr>
                                            <th scope="row">
                                                {props.t("Class")}
                                            </th>
                                            <td>
                                                {
                                                    classRooms.map((skill, key) => (
                                                        <Badge
                                                            key={"_skill_" + key}
                                                            className={`badge font-size-11 m-1`}
                                                            color={skill.color}
                                                        >
                                                            {skill.name}
                                                        </Badge>
                                                    ))
                                                }
                                            </td>
                                        </tr>}

                                        {props.showCorporatePartners && (props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) && <tr>
                                            <th scope="row">
                                                {props.t("Corporate Partners")}
                                            </th>
                                            {!showEditCorporatePartners && (
                                                <td>

                                                    <Badge className={`badge font-size-11 m-1`} color='warning' key={"_skill_CorporatePartners"}>
                                                        {selectedCorporatePartners ? selectedCorporatePartners.label : "No Value"}
                                                    </Badge>
                                                    <button
                                                        onClick={() => {
                                                            setShowEditCorporatePartners(true);
                                                        }}
                                                        className="bg-white btn btn-sm b-0"
                                                    >
                                                        <i className="font-size-13 m-1 text-primary  cursors move">
                                                            ✎
                                                        </i>
                                                    </button>
                                                    {
                                                        selectedCorporatePartners && (
                                                            <div className='d-flex justify-content-end'
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setShowCorporateRemove(true);
                                                                }}

                                                            >
                                                                <i className="bx bxs-trash text-danger" id="corp-delete" style={{ marginTop: "-20px" }}></i>
                                                                <UncontrolledTooltip target={"corp-delete"}>
                                                                    {props.t("Unset corporate Partner")}
                                                                </UncontrolledTooltip>
                                                            </div>
                                                        )
                                                    }
                                                </td>
                                            )}
                                            {showEditCorporatePartners && (
                                                <td>
                                                    <Label> {props.t("Corporate Partners")}:</Label>
                                                    <FormGroup>
                                                        <Select
                                                            value={selectedCorporatePartners}
                                                            onChange={(v) => { setSelectedCorporatePartners(v) }}
                                                            options={allCorporatePartners}
                                                            classNamePrefix="select2-selection"
                                                        />

                                                    </FormGroup>
                                                    <button
                                                        type="button"
                                                        className="btn btn-success btn-sm"
                                                        style={{ width: "34px" }}
                                                        onClick={() => {
                                                            corporatePartnersUpdate(selectedCorporatePartners.value);
                                                            setShowEditCorporatePartners(false);
                                                        }}
                                                    >
                                                        <i className="fa fa-check"></i>
                                                    </button>
                                                    <button
                                                        type="button"
                                                        className="btn btn-danger btn-sm ml-2"
                                                        style={{ width: "34px" }}
                                                        onClick={() => {
                                                            if (props.kid.attributes.corporateId) {
                                                                setSelectedCorporatePartners({ value: props.kid.attributes.corporateId, label: getCorporatePartnerName(props.kid.attributes.corporateId) });
                                                            } else {
                                                                setSelectedCorporatePartners(undefined)
                                                            }
                                                            setShowEditCorporatePartners(false);
                                                        }}
                                                    >
                                                        <i className="fa fa-times"></i>
                                                    </button>
                                                </td>
                                            )}
                                        </tr>}


                                        {
                                            ((props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) &&
                                                (props.selectedSchool && (props.selectedSchool.attributes.franchiseId == "sP8TeR7BSG"
                                                    || props.selectedSchool.attributes.franchiseId == "AwiwOAmsEf1")))
                                            && <tr>
                                                <th scope="row">{props.t("Fee Type")}</th>
                                                {!showEditfeeType && (
                                                    <td>
                                                        {selectedFeeType ? selectedFeeType.label : "No Value"}
                                                        <button
                                                            onClick={() => {
                                                                setShowEditfeeType(true);
                                                            }}
                                                            className="bg-white btn btn-sm b-0"
                                                        >
                                                            <i className="font-size-13 m-1 text-primary  cursors move">
                                                                ✎
                                                            </i>
                                                        </button>
                                                    </td>
                                                )}
                                                {showEditfeeType && (
                                                    <td>
                                                        <Label> {props.t("Fee Type")}:</Label>
                                                        <FormGroup>
                                                            <Select
                                                                value={selectedFeeType}
                                                                onChange={(v) => { setSelectedFeeType(v) }}
                                                                options={feeType}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </FormGroup>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-sm"
                                                            style={{ width: "34px" }}
                                                            onClick={() => { feeTypeUpdate(selectedFeeType.value) }}
                                                        >
                                                            <i className="fa fa-check"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-sm ml-2"
                                                            style={{ width: "34px" }}
                                                            onClick={() => {
                                                                if (props.kid.attributes.feeType) {
                                                                    setSelectedFeeType({ value: props.kid.attributes.feeType, label: props.kid.attributes.feeType });
                                                                } else {
                                                                    setSelectedFeeType(undefined)
                                                                }
                                                                setShowEditfeeType(false);
                                                            }}
                                                        >
                                                            <i className="fa fa-times"></i>
                                                        </button>
                                                    </td>
                                                )}
                                            </tr>
                                        }

                                        {
                                            ((props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) &&
                                                (props.selectedSchool && (props.selectedSchool.attributes.franchiseId == "sP8TeR7BSG"
                                                    || props.selectedSchool.attributes.franchiseId == "AwiwOAmsEf1")))
                                            && <tr>
                                                <th scope="row">{props.t("Program Type")}</th>
                                                {!showEditProgramType && (
                                                    <td>
                                                        {selectedProgramType ? selectedProgramType.label : "No Value"}
                                                        <button
                                                            onClick={() => {
                                                                setShowEditProgramType(true);
                                                            }}
                                                            className="bg-white btn btn-sm b-0"
                                                        >
                                                            <i className="font-size-13 m-1 text-primary  cursors move">
                                                                ✎
                                                            </i>
                                                        </button>
                                                    </td>
                                                )}
                                                {showEditProgramType && (
                                                    <td>
                                                        <Label> {props.t("Fee Type")}:</Label>
                                                        <FormGroup>
                                                            <Select
                                                                value={selectedProgramType}
                                                                onChange={(v) => { setSelectedProgramType(v) }}
                                                                options={programType}
                                                                classNamePrefix="select2-selection"
                                                            />

                                                        </FormGroup>
                                                        <button
                                                            type="button"
                                                            className="btn btn-success btn-sm"
                                                            style={{ width: "34px" }}
                                                            onClick={() => { programTypeUpdate(selectedProgramType && selectedProgramType.value) }}
                                                        >
                                                            <i className="fa fa-check"></i>
                                                        </button>
                                                        <button
                                                            type="button"
                                                            className="btn btn-danger btn-sm ml-2"
                                                            style={{ width: "34px" }}
                                                            onClick={() => {
                                                                if (props.kid.attributes.programType) {
                                                                    setSelectedProgramType({ value: props.kid.attributes.programType, label: props.kid.attributes.programType })
                                                                } else {
                                                                    setSelectedProgramType(undefined)
                                                                }
                                                                setShowEditProgramType(false);
                                                            }}
                                                        >
                                                            <i className="fa fa-times"></i>
                                                        </button>
                                                    </td>
                                                )}
                                            </tr>
                                        }




                                        {props.selectedSchool &&
                                            props.selectedSchool.attributes.featuresList &&
                                            props.selectedSchool.attributes.featuresList.length > 0 &&
                                            props.selectedSchool.attributes.featuresList[Constants.FEATURE_ACTIVITY_TRACKING_PARENT_INDEX] > 0 &&
                                            props.kid &&
                                            props.kid.attributes.ClassRoomID &&
                                            props.kid.attributes.ClassRoomID.length > 0 && props.selectedSchool.id === "uJu0oz0Hbo" &&
                                            < tr >
                                                <th scope="row">
                                                    {props.t("Learning Analytics")}
                                                </th>
                                                <td>

                                                    <Link to={`/learning-analytics/${props.schoolId}/${props.kid && props.kid.id}`} target="_blank" rel="noopener noreferrer">
                                                        <Label className={`badge font-size-11 m-1 badge-primary`} style={{ cursor: "pointer" }} >
                                                            View
                                                        </Label>
                                                    </Link>
                                                </td>
                                            </tr>}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            </CardBody>
        </Card >
    )
}

const mapStatetoProps = state => {
    const { error, updatedKidSuccess, kidsDeletedSuccessfully, kidCAD } = state.Kid
    const { franchise } = state.Franchise;
    return { error, updatedKidSuccess, kidsDeletedSuccessfully, kidCAD, franchise };
};
export default connect(mapStatetoProps, { updateAKid, updateAParent, deleteKidsInList, updateKidTosterSuccess, updateAdditionalDetailsForAKid, disableKid })(withRouter(withNamespaces()(DetailView)));
