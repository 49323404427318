export const UPDATE_INVOICE = "UPDATE_INVOICE";
export const DELETE_INVOICE = "DELETE_INVOICE";

export const SET_INVOICES = "SET_INVOICES";
export const SET_INVOICE_UPDATE = "SET_INVOICE_UPDATE";
export const GET_INVOICES = "GET_INVOICES";
export const GET_INVOICE = "GET_INVOICE";

export const SAVE_INVOICE = "SAVE_INVOICE";

export const INVOICE_SUCCESS = "INVOICE_SUCCESS";
export const INVOICE_ERROR = "INVOICE_ERROR";

export const GET_INVOICES_FOR_FILTERS = "GET_INVOICES_FOR_FILTERS";
export const CREATE_INVOICE_FOR_KIDS = "CREATE_INVOICE_FOR_KIDS";
export const CLEAR_RESPONSE_FOR_CREATE_INVOICE_FOR_KIDS =
  "CLEAR_RESPONSE_FOR_CREATE_INVOICE_FOR_KIDS";
export const RESPONSE_FOR_CREATE_INVOICE_FOR_KIDS =
  "RESPONSE_FOR_CREATE_INVOICE_FOR_KIDS";
export const CREATE_INVOICE_WITH_FUNCTION = "CREATE_INVOICE_WITH_FUNCTION";
export const UPDATE_INVOICE_AND_ITEMS = "UPDATE_INVOICE_AND_ITEMS";

export const GET_INVOICE_FOR_IDS = "GET_INVOICE_FOR_IDS";
export const GET_INVOICE_DETAILS_BY_FUNCTION =
  "GET_INVOICE_DETAILS_BY_FUNCTION";
export const SET_INVOICE = "SET_INVOICE";
export const SET_UPDATE_INVOICE_UPDATE = "SET_UPDATE_INVOICE_UPDATE";
export const DELETE_SELECTED_INVOICE = "DELETE_SELECTED_INVOICE";
export const SET_DELETE_INVOICE = "SET_DELETE_INVOICE";
export const NOTIFY_PAYMENT_TO_PARENTS = "NOTIFY_PAYMENT_TO_PARENTS";
export const NOTIFY_SENT_SUCCESS = "NOTIFY_SENT_SUCCESS";
export const SET_INVOICE_SUCCESS_COMPLETE = "SET_INVOICE_SUCCESS_COMPLETE";
export const SUCCESS_UPDATE_INVOICE_VALUE = "SUCCESS_UPDATE_INVOICE_VALUE";
export const NOTIFY_SENT_DONE = "NOTIFY_SENT_DONE";
export const GET_INVOICES_COUNT_FOR_FILTERS = "GET_INVOICES_COUNT_FOR_FILTERS";
export const SET_INVOICE_COUNT = "SET_INVOICE_COUNT";
export const GET_INVOICE_COUNT_FOR_SCHOOLID = "GET_INVOICE_COUNT_FOR_SCHOOLID";
export const SET_INVOICE_COUNT_FOR_SCHOOLID = "SET_INVOICE_COUNT_FOR_SCHOOLID";


export const GET_INVOICES_FOR_PROGRAM_ID = "GET_INVOICES_FOR_PROGRAM_ID";
export const UPDATE_INVOICES_FOR_PROGRAM_ID = "UPDATE_INVOICES_FOR_PROGRAM_ID"
export const CREATE_INVOICE_FOR_PROGRAM_KID = "CREATE_INVOICE_FOR_PROGRAM_KID";
export const SET_PROGRAM_INVOICE_CREATION_LOADER = "SET_PROGRAM_INVOICE_CREATION_LOADER";


export const GET_RECURRING_MASTER_INVOICES_FOR_SCHOOLID = "GET_RECURRING_MASTER_INVOICES_FOR_SCHOOLID";
export const UPDATE_RECURRING_MASTER_INVOICES_FOR_SCHOOLID = "UPDATE_RECURRING_MASTER_INVOICES_FOR_SCHOOLID";
export const EDIT_RECURRING_MASTER_INVOICES_FOR_SCHOOLID = "EDIT_RECURRING_MASTER_INVOICES_FOR_SCHOOLID";
export const STOP_RECURRING_MASTER_INVOICES_FOR_SCHOOLID = "STOP_RECURRING_MASTER_INVOICES_FOR_SCHOOLID";

export const GET_RECURRING_INVOICES_FOR_SCHOOLID = "GET_RECURRING_INVOICES_FOR_SCHOOLID";
export const UPDATE_RECURRING_INVOICES_FOR_SCHOOLID = "UPDATE_RECURRING_INVOICES_FOR_SCHOOLID";
export const REMOVE_RECURRING_MASTER_INVOICE = "REMOVE_RECURRING_MASTER_INVOICE";
