import Parse from "parse";
import { printLog } from "./loghelper";
const getchildAdditionalDetailFromParse = (childAdditionalDetailId) => {
  printLog("HL: childAdditionalDetails_helper : getchildAdditionalDetailFromParse")
  try {
    return new Promise((resolve, reject) => {
      var ChildAdditionalDetail = Parse.Object.extend("ChildAdditionalDetails");
      var query = new Parse.Query(ChildAdditionalDetail);
      query.get(childAdditionalDetailId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const deleteChildAdditionalDetailFromParse = (childAdditionalDetailId) => {
  printLog("HL: childAdditionalDetails_helper : deleteChildAdditionalDetailFromParse")
  try {
    return new Promise((resolve, reject) => {
      const ChildAdditionalDetail = Parse.Object.extend(
        "ChildAdditionalDetails"
      );
      var childAdditionalDetail = new ChildAdditionalDetail();
      childAdditionalDetail.id = childAdditionalDetailId;
      childAdditionalDetail.set("deleted", true);
      if (Parse.User.current().attributes.teacherId) {
        childAdditionalDetail.set(
          "deletedBy",
          Parse.User.current().attributes.teacherId
        );
      } else {
        childAdditionalDetail.set("deletedBy", Parse.User.current().id);
      }

      childAdditionalDetail.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const getAChildAdditionalDetailForEnquiryId = (enquiryId) => {
  printLog("HL: childAdditionalDetails_helper : getAChildAdditionalDetailForEnquiryId")
  try {
    return new Promise((resolve, reject) => {
      var ChildAdditionalDetail = Parse.Object.extend("ChildAdditionalDetails");
      var query = new Parse.Query(ChildAdditionalDetail);
      query.equalTo("enquiryId", enquiryId);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const getChildAdditionalDetailForKidId = (id, isLocal) => {
  printLog("HL: childAdditionalDetails_helper : getChildAdditionalDetailForKidId")
  try {
    return new Promise((resolve, reject) => {
      var ChildAdditionalDetail = Parse.Object.extend("ChildAdditionalDetails");
      var query = new Parse.Query(ChildAdditionalDetail);
      query.equalTo("kidId", id);

      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const editChildAdditionalDetail = (childAdditionalDetail) => {
  printLog("HL: childAdditionalDetails_helper : editChildAdditionalDetail")
  try {
    return new Promise((resolve, reject) => {
      childAdditionalDetail.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const getChildAdditionalDetailsForEnquiryAndKidIds = (
  enqIds,
  kidIds
) => {
  printLog("HL: childAdditionalDetails_helper : getChildAdditionalDetailsForEnquiryAndKidIds")
  try {
    return new Promise((resolve, reject) => {
      let query;
      let query1 = new Parse.Query("ChildAdditionalDetails");
      if (kidIds.length > 0) {
        query1.containedIn("kidId", kidIds);
      }
      let query2 = new Parse.Query("ChildAdditionalDetails");
      if (enqIds.length > 0) {
        query2.containedIn("enquiryId", enqIds);
      }
      if (enqIds.length > 0 && kidIds.length > 0) {
        query = Parse.Query.or(query1, query2);
      } else if (enqIds.length > 0) {
        query = query2;
      } else if (kidIds.length > 0) {
        query = query1;
      }
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export {
  getchildAdditionalDetailFromParse,
  deleteChildAdditionalDetailFromParse,
  getChildAdditionalDetailForKidId,
  editChildAdditionalDetail,
  getAChildAdditionalDetailForEnquiryId,
};
