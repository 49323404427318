import * as actions from "./actionTypes";

export const getInventoryTagsForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_TAGS,
    payload: {ownerId, ownerType},
    }
}

export const updateInventoryTags = (inventoryTags) =>{
    return{
        type:actions.UPDATE_INVENTORY_TAGS,
        payload: {inventoryTags},
    }
}

export const addInventoryTag = (inventoryTag) =>{
    return{
        type: actions.ADD_INVENTORY_TAG,
        payload: {inventoryTag}
    }
}

export const deleteInventoryTag = (inventoryTag) =>{
    return{
        type:actions.DELETE_INVENTORY_TAG,
        payload: {inventoryTag},
    }
}

export const updateDeleteInventoryTag = (inventoryTag) =>{
    return{
        type:actions.UPDATE_DELETE_INVENTORY_TAG,
        payload: {inventoryTag},
    }
}