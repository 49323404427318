import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";
const initialState = {
  enquiries: [],
  selectedEnquiry: null,
  loding: false,
  bulkUploadLoading: false,
  bulkDeleteLoading: false,
  deletedEnquiries: null,
  bulkRestoreLoading: false,
  enquiryTemplates: [],
  selectedTemplate: undefined,
  franchiseEnquiry: [],
  selectedFranchiseEnquiry: null,
  franchiseDetails : undefined

};

const Enquiry = (state = initialState, action) => {
  switch (action.type) {
    case actions.CLEAR_ENQUIRY:
      state = { ...state, enquiries: [], selectedEnquiry: null };
      break;
    case actions.UPDATE_ENQUIRY:
      var enquiries = [...state.enquiries];
      var temp = state.enquiries.filter(
        (enquiry) => enquiry.id === action.payload.enquiry.id
      );
      if (temp.length > 0) {
        if (action.payload.enquiry.attributes.deleted) {
          var others = state.enquiries.filter(
            (enquiry) => enquiry.id !== action.payload.enquiry.id
          );
          state = { ...state, enquiries: others };
        } else {
          var index = enquiries.indexOf(temp[0]);
          enquiries[index] = action.payload.enquiry;
          state = { ...state, enquiries: enquiries };
        }
      } else {
        if (action.payload.enquiry.attributes.deleted) {
          var list = [action.payload.enquiry];
          state = { ...state, enquiries: list.concat(enquiries) };
        }
      }
      break;
    case actions.UPDATE_ENQUIRIES:
      var enquiries = [...state.enquiries];
      if (enquiries.length > 0) {
        for (const latest in action.payload.enquiries) {
          var temp = enquiries.filter(
            (enquiry) => enquiry.id === action.payload.enquiries[latest].id
          );
          if (temp.length > 0) {
            if (action.payload.enquiries[latest].attributes.deleted) {
              var others = enquiries.filter(
                (enquiry) => enquiry.id !== action.payload.enquiries[latest].id
              );
              enquiries = [...others];
            } else {
              var index = enquiries.lastIndexOf(temp[0]);
              enquiries[index] = action.payload.enquiries[latest];
            }
          } else {
            if (!action.payload.enquiries[latest].attributes.deleted) {
              var list = [action.payload.enquiries[latest]];
              enquiries = list.concat(enquiries);
            }
          }
        }
        state = { ...state, enquiries: enquiries };
      } else {
        var others = action.payload.enquiries.filter(
          (enquiry) => enquiry.attributes.deleted !== true
        );

        state = { ...state, enquiries: others };
      }

      break;
    case actions.DELETE_ENQUIRY:
      var enquiries = state.enquiries.filter(
        (enquiry) => enquiry.id !== action.payload.id
      );
      state = { ...state, enquiries: enquiries };
      break;
    case actions.UPDATE_SELECTED_ENQUIRY:
      state = { ...state, selectedEnquiry: action.payload.enquiry };
      break;
    case actions.ENQUIRY_LODING:
      state = { ...state, loding: action.payload.state };
      break;
    case actions.ENQUIRY_BULK_UPLOAD_LODING:
      state = { ...state, bulkUploadLoading: action.payload.state };
      break;
    case actions.ENQUIRY_BULK_DELETE_LODING:
      state = { ...state, bulkDeleteLoading: action.payload.state };
      break;
    case actions.ENQUIRY_BULK_RESTORE_LODING:
      state = { ...state, bulkRestoreLoading: action.payload.state };
      break;
    case actions.SET_ADMISSION_QUESTIONS:
      state = { ...state, adQuestionObj: action.payload.adQuestionObj };
      break;
    case actions.SET_KIDOBJ_FOR_ENQUIRY:
      state = { ...state, kidObj: action.payload.kidObj };
      break;
    case actions.UPDATE_ENQUIRY_TEMPLATE:
      var enquiryTemplates = [...state.enquiryTemplates];

      if (enquiryTemplates.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.enquiryTemplates], action.payload.enquiryTemplates, "isDeleted", false)
        state = { ...state, enquiryTemplates: updatedList };
      } else {
        var others = action.payload.enquiryTemplates.filter(
          (enquiryTemplates) => enquiryTemplates.attributes.isDeleted !== true
        );
        state = { ...state, enquiryTemplates: others };
      }
      break;
    case actions.UPDATE_DELETED_ENQUIRIES:

      if (state.deletedEnquiries) {
        var deletedEnquiries = [...state.deletedEnquiries];
        if (deletedEnquiries.length > 0) {
          for (const latest in action.payload.enquiries) {
            var temp =[] 
            for(const enquiry of  deletedEnquiries){
              if(enquiry.id === action.payload.enquiries[latest].id){
                temp.push(enquiry)
                break
              }
            }
            if (temp.length > 0) {
              if (action.payload.enquiries[latest].attributes.deleted) {
                var index = deletedEnquiries.indexOf(temp[0]);
                deletedEnquiries[index] = action.payload.enquiries[latest];
              } else {
                var others = deletedEnquiries.filter(
                  (enquiry) => enquiry.id !== action.payload.enquiries[latest].id
                );
                deletedEnquiries = [...others];
              }
            } else {
              if (action.payload.enquiries[latest].attributes.deleted) {
                if(deletedEnquiries){
                  deletedEnquiries.push(action.payload.enquiries[latest]);
                }else{
                  deletedEnquiries = [action.payload.enquiries[latest]];
                }
                
              }
            }
          }
          state = { ...state, deletedEnquiries: deletedEnquiries };
        } else {
          state = { ...state, deletedEnquiries: action.payload.enquiries };
        }

      } else {
        state = { ...state, deletedEnquiries: action.payload.enquiries };
      }



      break;

    case actions.UPDATE_FRANCHISE_ENQUIRY:
      if (state.franchiseEnquiry && state.franchiseEnquiry.length > 0) {
        const updatedList = ReduxUtil.updateParseObjects([...state.franchiseEnquiry], action.payload.franchiseEnquiries, "isDeleted");
        state = { ...state, franchiseEnquiry: updatedList };
      } else {
        const filteredEnquiries = action.payload.franchiseEnquiries.filter(
          (enquiry) => enquiry.attributes.isDeleted !== true
        );
        state = { ...state, franchiseEnquiry: filteredEnquiries };
      }

      break;

    case actions.UPDATE_SELECTED_FRANCHISE_ENQUIRY:
      state = { ...state, selectedFranchiseEnquiry: action.payload.enquiry };
      break;


    default:
      state = { ...state };
      break;
  }



  return state;
};

export default Enquiry;
