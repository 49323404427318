import React, { useEffect, useState } from "react";
import { Label, Modal, Table ,Alert, Col, Row} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import { processToPaySMSAndWhatsApp } from "../../store/actions";

const BuyCredits = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [buyCreditDetailsMissing, setBuyCreditDetailsMissing] = useState(false);
  const [canBuyCreditList, setCanBuyCreditList] = useState([]);
  const [isEnableToPay, setIsEnableToPay] = useState(false);
  const [totalAmount, setTotalAmount] = useState(0);

  const [gatewayState, setGatewayState] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);
  useEffect(() => {
    if (props.buyCreditDetails.length > 0) {
      setBuyCreditDetailsMissing(false);
      let outTemp = [];
      for (const val of props.buyCreditDetails) {
        let inTemp = {};
        inTemp.id = val.id;
        inTemp.Obj = val;
        inTemp.itemName = val.attributes.itemName;
        inTemp.minQuantity = val.attributes.minQuantity;
        inTemp.orderQuantity = val.attributes.minQuantity;
        inTemp.pricePerUnit = val.attributes.pricePerUnit;
        inTemp.gst = val.attributes.gst;
        inTemp.total = 0;
        inTemp.isEnable = false;
        outTemp.push(inTemp);
      }
      setCanBuyCreditList(outTemp);
    } else {
      setBuyCreditDetailsMissing(true);
    }
  }, [props.buyCreditDetails]);
  useEffect(() => {
    if (props.paymentProcessSuccess) {
      console.log(props.schoolObject);
      //send this to server or generate on server
      

      let buyerEmail = props.schoolObject.attributes.EMail;
      let buyerPhone = props.schoolObject.attributes.primaryPhoneNumber;
      let buyerFirstName = props.schoolObject.attributes.Name.replace(
        /[^a-zA-Z ]/g,
        ""
      ).replaceAll(" ", "");
      let buyerLastName = props.schoolObject.attributes.Name.replace(
        /[^a-zA-Z ]/g,
        ""
      ).replaceAll(" ", "");
      const submitAmount = Number(totalAmount).toFixed(2);
      const orderId = props.paymentProcessSuccess.id;
      fetch(
        // "https://development.back4app.io/airpay/sendtoairpay", //development
        "https://liltriangle.b4a.app/airpay/sendtoairpay", //production
        // "http://localhost:1337/parse/airpay/sendtoairpay", //local
        {
          method: "POST",
          body: JSON.stringify({
            buyerFirstName: buyerFirstName,
            buyerLastName: " ",
            buyerAddress: "bangalore",
            buyerCity: "bangalore",
            buyerState: "Karnataka",
            buyerCountry: "IN",
            buyerEmail: buyerEmail,
            buyerPhone:buyerPhone,
            amount: submitAmount,
            orderid: orderId,
            popId: "fgCOKHPU0O",
            popAttribut: "objectId",
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      )
        .then((res) => {
          if (res.status !== 200) {
            return "error";
          }
          return res.text();
        })
        .then(function (text) {
          setGatewayState(false);
          let response = text;
          console.log(response);
          window.open().document.write(response);
          console.log("Request successful", text);
        });
    }
  }, [props.paymentProcessSuccess]);
  const itemChange = (id, value) => {
    let tempCreditsList = Object.assign([], canBuyCreditList);
    let isPaying = false;
    let temp = 0;
    for (const val of tempCreditsList) {
      if (val.id === id) {
        val.isEnable = value;
        if (value) {
          let tempTotal = val.minQuantity * val.pricePerUnit;
          val.total = (tempTotal + (tempTotal / 100) * val.gst).toFixed(2);
          isPaying = true;
        } else {
          val.orderQuantity = val.minQuantity;
          val.total = 0;
        }
      } else {
        if (val.isEnable) {
          isPaying = true;
        }
      }
      temp += parseFloat(val.total);
    }
    setTotalAmount(parseFloat(temp).toFixed(2));
    setCanBuyCreditList(tempCreditsList);
    setIsEnableToPay(isPaying);
  };
  const updateOrderQuantityOnBlur = (key, value) => {
    let tempCreditsList = Object.assign([], canBuyCreditList);
    if (parseFloat(value ? value : 0) < tempCreditsList[key].minQuantity) {
      updateOrderQuantity(
        tempCreditsList[key].id,
        tempCreditsList[key].minQuantity
      );
    }
  };
  const updateOrderQuantity = (id, value) => {
    let tempCreditsList = Object.assign([], canBuyCreditList);
    let temp = 0;
    for (const val of tempCreditsList) {
      if (val.id === id) {
        // if (parseFloat(value) >= val.minQuantity) {
        val.orderQuantity = parseFloat(value);
        // } else {
        //   val.orderQuantity = val.minQuantity;
        // }
        let tempTotal =
          parseFloat(val.orderQuantity) * parseFloat(val.pricePerUnit);
        val.total = (tempTotal + (tempTotal / 100) * val.gst).toFixed(2);
        // temp += parseFloat(val.total);
      }
      temp += parseFloat(val.total);
    }
    setTotalAmount(parseFloat(temp).toFixed(2));
    setCanBuyCreditList(tempCreditsList);
  };
  const procideToPay = () => {
    setGatewayState(true);
    let amountToPay = parseFloat(totalAmount);
    let invoiceItems = [];
    for (const val of canBuyCreditList) {
      if (val.isEnable) {
        let temp = {};
        temp.type = val.itemName;
        temp.quantity = val.orderQuantity;
        temp.gst = val.gst;
        temp.total = val.total;
        invoiceItems.push(temp);
      }
    }
    props.processToPaySMSAndWhatsApp(amountToPay, invoiceItems, props.schoolId);
  };
  return (
    <React.Fragment>
      <Modal
        size="lg"
        isOpen={isOpen}
        toggle={() => {
          props.setIsOpen(false);
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Buy Message Credits
          </h5>
          <button
            onClick={() => {
              props.setIsOpen(false);
            }}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <p>
            Credits Available SMS: {props.smsAvalableCredits}
            {/* And WhatsApp:{" "} */}
            {/* {props.whatsAppAvalableCredits} */}
          </p>
          <div className="table-responsive">
            <Table className="table table-nowrap mb-0">
              <thead>
                <tr>
                  <td>Item Name</td>
                  <td style={{ width: "20%" }}>Quantity</td>
                  <td>Price Per Unit</td>
                  <td>GST</td>
                  <td>Total</td>
                </tr>
              </thead>
              <tbody>
                {canBuyCreditList.map((val, key) => (
                  <tr key={val.id}>
                    <td>
                      <div className="custom-control custom-checkbox pt-2">
                        <input
                          type="checkbox"
                          className="custom-control-input input-mini"
                          id={`check_box_${val.id}`}
                          value={val.isEnable}
                          onChange={(e) => {
                            itemChange(val.id, e.target.checked);
                          }}
                        />
                        <Label
                          className="custom-control-label"
                          for={`check_box_${val.id}`}
                        >
                          {val.itemName}
                        </Label>
                      </div>
                    </td>
                    <td>
                      <input
                        type="number"
                        className="form-control"
                        // min={val.minQuantity}
                        value={val.orderQuantity}
                        disabled={!val.isEnable}
                        onChange={(e) =>
                          updateOrderQuantity(val.id, e.target.value)
                        }
                        onBlur={(e) =>
                          updateOrderQuantityOnBlur(key, e.target.value)
                        }
                      />
                      {val.minQuantity === val.orderQuantity && val.isEnable && (
                        <p
                          className="text-warning mb-0"
                          style={{ fontSize: "11px" }}
                        >
                          Minimum quantity
                        </p>
                      )}
                    </td>
                    <td>
                      <p className="pt-2">{val.pricePerUnit}</p>
                    </td>
                    <td>
                      <p className="pt-2">{val.gst}%</p>
                    </td>
                    <td>
                      <p className="pt-2">{val.total}</p>
                    </td>
                  </tr>
                ))}
                <tr>
                  <td></td>
                  <td></td>
                  <td></td>
                  <td>Total Amount</td>
                  <td>{totalAmount}</td>
                </tr>
                
              </tbody>
            </Table>
          </div>

          { window.location.host !== 'dashboard.liltriangle.com' && <div>

            <Alert
              color="danger"
              role="alert"
            >
              <p className="lead mb-1"><strong><u>Alert</u></strong></p>
              <p className="mb-3">To buy message credits please login to <code>https://dashboard.liltriangle.com</code> with your lilTriangle credentials </p>
              
              <Row>
                <Col lg={10}>
                </Col>
                <Col lg={2}>
                <button
                  className="btn btn-success mr-2 "
                  onClick={() => {
                    window.location.replace('https://dashboard.liltriangle.com/login');
                  }}
                
                >
                  Login
                </button>
              </Col> 


              </Row>

                  
            </Alert>
          </div>}
        </div>

       { window.location.host === 'dashboard.liltriangle.com' &&  <div className="modal-footer">
          {
            gatewayState && <button type="button" className="btn btn-primary  waves-effect waves-light float-right m-2">
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Redirecting to payment gateway ')}
            </button>
          }
          {!gatewayState && <button
            className="btn btn-success mr-2"
            onClick={procideToPay}
            disabled={!isEnableToPay}
          >
            Pay
          </button>}

        </div>}
      </Modal>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { paymentProcessSuccess } = state.MessageCredits;
  return { paymentProcessSuccess };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      processToPaySMSAndWhatsApp,
    })(BuyCredits)
  )
);
