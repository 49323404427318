import * as actions from "./actionTypes";

export const addCourseBatch = (courseBatch,selectedBatch) => {
  return {
    type: actions.ADD_COURSE_BATCH,
    payload: { courseBatch,selectedBatch },
  };
};

export const deleteCourseBatch = (courseBatch) => {
  return {
    type: actions.DELETE_COURSE_BATCH,
    payload: { courseBatch },
  };
};

export const updateCourseBatch = (courseBatch, replace) => {
  return {
    type: actions.UPDATE_COURSES_BATCH,
    payload: { courseBatch, replace },
  };
};

export const getCourseBatchesForCourseId = (courseId) => {
  return {
    type: actions.GET_COURSE_BATCH_FOR_COURSE_ID,
    payload: { courseId },
  };
};

export const getCourseBatch = (courseBatchId) => {
  return {
    type: actions.GET_COURSE_BATCH,
    payload: { courseBatchId },
  };
};

export const setSlectedCourseBatch = (courseBatch) => {
  return {
    type: actions.SELECTED_COURSE_BATCH,
    payload: { courseBatch },
  };
};

export const setCourseBatchLoading = (state) => {
  return {
    type: actions.UPDATE_COURSE_BATCH_INITIAL_LOADING,
    payload: { state },
  };
};

export const setAddCourseBatchLoading = (state) => {
  return {
    type: actions.ADD_COURSE_BATCH_LOADING,
    payload: { state },
  };
};

export const getCourseBatchesForCourseIds = (courseIds) => {
  return {
    type: actions.GET_COURSE_BATCH_FOR_COURSE_IDS,
    payload: { courseIds },
  };
}; 

export const updateCourseBatchForCourseIds = (courseBatch) => {
  return {
    type: actions.UPDATE_COURSE_BATCH_FOR_COURSE_IDS,
    payload: { courseBatch },
  };
};

