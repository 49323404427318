import { takeEvery,takeLeading, fork, put, all, call, } from "redux-saga/effects";
import Parse from "parse";

import * as LearningCategoryHelper from "../../../helpers/ParseHelpers/learningCategory_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as LearningCategoryActions from "./actions";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';
import actions from "redux-form/lib/actions";


function* deleteALearningCategory({ payload: { learningCategory } }) {
  try {
    const response = yield call(
        LearningCategoryHelper.deleteLearningCategoryFromParse,
        learningCategory
    );
    if (response) {
      yield put(LearningCategoryActions.deleteLearningCategory(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getLearningCategoryForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.LEARNING_CATEGORY_UPDATED_TIME_FOR_SCHOOL_ID + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(LearningCategoryHelper.getLearningCategoryForSchoolId, schoolId, null, true);
    yield put(LearningCategoryActions.updateLearningCategorys(localResponse));

    const serverResponse = yield call(LearningCategoryHelper.getLearningCategoryForSchoolId, schoolId, updatedTime, false);
    localStorage.setItem(key,newTime)

    if (serverResponse && serverResponse.length > 0) {
        yield put(LearningCategoryActions.updateLearningCategorys(serverResponse));
        Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getLearningCategoryForCurriculumId({ payload: { curriculumId } }) {
    try {
      var key = Constants.LEARNING_CATEGORY_UPDATED_TIME_FOR_CURRICULUM_ID + "_" + curriculumId;
      var updatedTime = localStorage.getItem(key)
      var newTime = new Date();
      yield put(LearningCategoryActions.clearLearningCategory());
      yield put(LearningCategoryActions.setLearningCategoryLoading(true));
      const localResponse = yield call(LearningCategoryHelper.getLearningCategoryForCurriculumId, curriculumId, null, true);
      if (localResponse && localResponse.length > 0) {
        yield put(LearningCategoryActions.setLearningCategoryLoading(false));
      }
      yield put(LearningCategoryActions.updateLearningCategorys(localResponse));
  
      const serverResponse = yield call(LearningCategoryHelper.getLearningCategoryForCurriculumId, curriculumId, updatedTime, false);
      localStorage.setItem(key,newTime)
      yield put(LearningCategoryActions.setLearningCategoryLoading(false));
      if (serverResponse && serverResponse.length > 0) {
          yield put(LearningCategoryActions.updateLearningCategorys(serverResponse));
          Parse.Object.pinAll(serverResponse);
      }
    } catch (error) {
      // yield put(apiError(error));
  
    }
}
  
function* getLearningCategoryForCycleId({ payload: { cycleId } }) {
  try {
    var key = Constants.LEARNING_CATEGORY_UPDATED_TIME_FOR_CURRICULUM_ID + "_" + cycleId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    yield put(LearningCategoryActions.clearLearningCategory());
    yield put(LearningCategoryActions.setLearningCategoryLoading(true));
    const localResponse = yield call(LearningCategoryHelper.getLearningCategoryForCycleId, cycleId, null, true);
    if (localResponse && localResponse.length > 0) {
      yield put(LearningCategoryActions.setLearningCategoryLoading(false));
    }
    yield put(LearningCategoryActions.updateLearningCategorys(localResponse));

    const serverResponse = yield call(LearningCategoryHelper.getLearningCategoryForCycleId, cycleId, updatedTime, false);
    localStorage.setItem(key, newTime);
    yield put(LearningCategoryActions.setLearningCategoryLoading(false));
    if (serverResponse && serverResponse.length > 0) {
        yield put(LearningCategoryActions.updateLearningCategorys(serverResponse));
        Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getLearningCategoryForCourseId({ payload: { courseId } }) {
  try {
    var key = Constants.LEARNING_CATEGORY_UPDATED_TIME_FOR_COURSE_ID + "_" + courseId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    yield put(LearningCategoryActions.clearLearningCategory());
    yield put(LearningCategoryActions.setLearningCategoryLoading(true));
    const localResponse = yield call(LearningCategoryHelper.getLearningCategoryForCourseId, courseId, null, true);
    if (localResponse && localResponse.length > 0) {
      yield put(LearningCategoryActions.setLearningCategoryLoading(false));
    }
    yield put(LearningCategoryActions.updateLearningCategorys(localResponse));

    const serverResponse = yield call(LearningCategoryHelper.getLearningCategoryForCourseId, courseId, updatedTime, false);
    localStorage.setItem(key, newTime);
    yield put(LearningCategoryActions.setLearningCategoryLoading(false));
    if (serverResponse && serverResponse.length > 0) {
        yield put(LearningCategoryActions.updateLearningCategorys(serverResponse));
        Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* addLearningCategory({ payload: { learningCategory } }) {
  try {
    const response = yield call( LearningCategoryHelper.saveLearningCategory, learningCategory);
    if (response) {
      yield put(LearningCategoryActions.updateLearningCategory(response));
      response.pin();
    } else {
      
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getALearningCategory({ payload: { id } }) {
  try {
    const response = yield call( LearningCategoryHelper.getLearningCategoryFromParse, id,true);
    
    if (response) {
      yield put(LearningCategoryActions.setSelectedLearningCategory(response));
    } else {
      const sresponse = yield call( LearningCategoryHelper.getLearningCategoryFromParse, id,false);
      if (sresponse) {
        yield put(LearningCategoryActions.setSelectedLearningCategory(sresponse));
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* reorderLrarningCategory({ payload: { learningCategories,message,courseId } }) {
  try {
    const response = yield call(LearningCategoryHelper.saveLearningCategoryObjects, learningCategories);
    if (response) {
      if (message) {
        toastr.success(message, { timeOut: 3000 });
      }

      if (courseId) {
        const localResponse = yield call(LearningCategoryHelper.getLearningCategoryForCourseId, courseId, null, true);
        if (localResponse && localResponse.length > 0) {
          yield put(LearningCategoryActions.updateLearningCategorys(localResponse, "replace"));
        }
      } else {
        yield put(LearningCategoryActions.updateLearningCategorys(response, "replace"));
      }
      
      Parse.Object.pinAll(response);
    } else {
      
    }
  } catch (error) {
   console.log(error);
  }
}

function* getLearningCategoriesForIds({ payload: { ids } }) {
  try {
    const localResponse = yield call(LearningCategoryHelper.getLearningCategoriesForIds, ids);
    yield put(LearningCategoryActions.updateLearningCategorys(localResponse));

    const serverResponse = yield call(LearningCategoryHelper.getLearningCategoriesForIds, ids);

    if (serverResponse && serverResponse.length > 0) {
        yield put(LearningCategoryActions.updateLearningCategorys(serverResponse));
        Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

export function* watchgetSelectedLearningCategory() {  
  yield takeEvery(actionTypes.GET_SELECTED_LEARNING_CATEGORY, getALearningCategory);
}
  
export function* watchDeleteLearningCategory() {
  yield takeEvery(actionTypes.DELETE_LEARNING_CATEGORY, deleteALearningCategory);
}


export function* watchGetLearningCategoryForSchoolId() {
  yield takeEvery(actionTypes.GET_LEARNING_CATEGORYS, getLearningCategoryForSchoolId);
}

export function* watchGetLearningCategoryForcurriculumId() {
  yield takeEvery(actionTypes.GET_LEARNING_CATEGORY_FOR_CURRICULUM_ID, getLearningCategoryForCurriculumId);
}

export function* watchGetLearningCategoryForCycleId() {
  yield takeEvery(actionTypes.GET_LEARNING_CATEGORYS_FOR_CYCLE, getLearningCategoryForCycleId);
}

export function* watchAddLearningCategory() {
  yield takeEvery(actionTypes.ADD_LEARNING_CATEGORY, addLearningCategory);
}

export function* watchReorderLrarningCategory() {
  yield takeLeading(actionTypes.LEARNING_CATEGORY_REORDER, reorderLrarningCategory);
}

export function* watchGetLearningCategoryForCourseId() {
  yield takeEvery(actionTypes.GET_LEARNING_CATEGORYS_FOR_COURSE, getLearningCategoryForCourseId);
}

export function* watchGetLearningCategoriesForIds(){
  yield takeEvery(actionTypes.GET_LEARNING_CATEGORIES_FOR_IDS, getLearningCategoriesForIds);
}
  
function* LearningCategorySaga() {
  yield all([
    fork(watchDeleteLearningCategory),
    fork(watchGetLearningCategoryForSchoolId),
    fork(watchGetLearningCategoryForcurriculumId),
    fork(watchGetLearningCategoryForCycleId),
    fork(watchAddLearningCategory),
    fork(watchgetSelectedLearningCategory),
    fork(watchReorderLrarningCategory),
    fork(watchGetLearningCategoryForCourseId),
    fork(watchGetLearningCategoriesForIds),
    ]);
  }
  export default LearningCategorySaga;
  