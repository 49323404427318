export const UPDATE_ENQUIRY = "UPDATE_ENQUIRY";
export const DELETE_ENQUIRY = "DELETE_ENQUIRY";

export const UPDATE_ENQUIRIES = "UPDATE_ENQUIRIES";
export const GET_ENQUIRIES = "GET_ENQUIRIES";
export const GET_ENQUIRY = "GET_ENQUIRY";

export const SET_SELECTED_ENQUIRY = "SET_SELECTED_ENQUIRY";
export const UPDATE_SELECTED_ENQUIRY = "UPDATE_SELECTED_ENQUIRY";

export const ENQUIRY_SUCCESS = "ENQUIRY_SUCCESS";
export const ENQUIRY_ERROR = "ENQUIRY_ERROR";

export const EDIT_ENQUIRY = "EDIT_ENQUIRY";
export const CLEAR_ENQUIRY = "CLEAR_ENQUIRY";

export const ENQUIRIES_BETWEEN_DATES = "ENQUIRIES_BETWEEN_DATES";

export const MOVE_TO_ADMISSIONS = "MOVE_TO_ADMISSIONS";
export const ENQUIRY_LODING = "ENQUIRY_LODING";
export const ENQUIRY_BULK_UPLOAD_LODING = "ENQUIRY_BULK_UPLOAD_LODING";

export const GET_ADMISSION_QUESTIONS = "GET_ADMISSION_QUESTIONS";
export const SET_ADMISSION_QUESTIONS = "SET_ADMISSION_QUESTIONS";

export const SAVE_ENQUIRY_OBJECTS = "SAVE_ENQUIRY_OBJECTS";

export const BULK_DELETE_REQUEST = "BULK_DELETE_REQUEST";
export const BULK_DELETE_RESPONSE = "BULK_DELETE_RESPONSE";
export const ENQUIRY_BULK_DELETE_LODING = "ENQUIRY_BULK_DELETE_LODING";
export const GET_KIDOBJ_FROM_ENQUIRY = "GET_KIDOBJ_FROM_ENQUIRY";
export const SET_KIDOBJ_FOR_ENQUIRY = "SET_KIDOBJ_FOR_ENQUIRY";

export const GET_DELETED_ENQUIRIES = "GET_DELETED_ENQUIRIES";
export const UPDATE_DELETED_ENQUIRIES = "UPDATE_DELETED_ENQUIRIES";

export const ENQUIRY_BULK_RESTORE_REQUEST = "ENQUIRY_BULK_RESTORE_REQUEST";
export const ENQUIRY_BULK_RESTORE_LODING = "ENQUIRY_BULK_RESTORE_LODING";
export const GET_ENQUIRY_TEMPLATE = "GET_ENQUIRY_TEMPLATE";
export const UPDATE_ENQUIRY_TEMPLATE ="UPDATE_ENQUIRY_TEMPLATE";


//FRANCHISE ENQUIRY
export const GET_FRANCHISE_ENQUIRY_FOR_FRANCHISE_ID = "GET_FRANCHISE_ENQUIRY_FOR_FRANCHISE_ID";
export const UPDATE_FRANCHISE_ENQUIRY = "UPDATE_FRANCHISE_ENQUIRY";
export const SAVE_FRANCHISE_ENQUIRY = "SAVE_FRANCHISE_ENQUIRY";
export const SET_SELECTED_FRANCHISE_ENQUIRY = "SET_SELECTED_FRANCHISE_ENQUIRY";
export const UPDATE_SELECTED_FRANCHISE_ENQUIRY = "UPDATE_SELECTED_FRANCHISE_ENQUIRY";