import * as actions from "./actionTypes";

export const updateLearningSubcategory = (learningSubcategory) => {
  return {
    type: actions.UPDATE_LEARNING_SUBCATEGORY,
    payload: { learningSubcategory },
  };
};

export const deleteLearningSubcategory = (learningSubcategory) => {
  return {
    type: actions.DELETE_LEARNING_SUBCATEGORY,
    payload: { learningSubcategory },
  };
};

export const addLearningSubcategory = (learningSubcategory) => {
  return {
    type: actions.ADD_LEARNING_SUBCATEGOERY,
    payload: { learningSubcategory },
  };
};

export const updateLearningSubcategories = (learningSubcategories,type) => {
  return {
    type: actions.UPDATE_LEARNING_SUBCATEGORYS,
    payload: { learningSubcategories ,type},
  };
};

export const getLearningSubcategoryForCategoryId = (categoryId) => {
  return {
    type: actions.GET_LEARNING_SUBCATEGORY_FOR_CATEGORY_ID,
    payload: { categoryId },
  };
};

export const clearLearningSubcategory = () => {
  return {
    type: actions.CLEAR_LEARNING_SUBCATEGOERY,
    payload: {  },
  };
};
export const setLearningSubcategoryState = (state) => {
  return {
    type: actions.SET_LEARNING_SUBCATEGOERY_STATE,
    payload: {state},
  };
};
export const reorderSubCatrgories = (learningSubcategories,message) => {
  return {
    type: actions.LEARNING_SUBCATEGORY_REORDER,
    payload: { learningSubcategories,message },
  };
};
