import React, { Component } from "react";
import ReactApexChart from "react-apexcharts";

class StackedColumnChart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      options: {
        chart: {
          height: 359,
          type: "bar",
          stacked: !0,
          toolbar: {
            show: 1,
          },
          zoom: {
            enabled: !0,
          },
        },
        plotOptions: {
          bar: {
            horizontal: !1,
            columnWidth: "18%",
            // endingShape: "rounded"
          },
        },
        dataLabels: {
          enabled: !1,
        },
        xaxis: {
          categories: this.props.categories,
        },
        yaxis: {
          min: 0,
          labels: {
            formatter: function (val) {
              return val.toFixed(0);
            },
          },
        },
        colors: ["#74788d", "#f1b44c","#34c38f", "#f46a6a"],
        legend: {
          position: "bottom",
        },
        fill: {
          opacity: 1,
        },
      },
      series: [
        {
          name: "Total",
          data: this.props.totalStrengthList,
        },
        {
          name: "At School",
          data: this.props.atSchoolCountList,
        },
        {
          name: "Checkin",
          data: this.props.inCountList,
        },
        {
          name: "Absent",
          data: this.props.absentCountList,
        },
      ],
    };
  }

  render() {
    return (
      <React.Fragment>
        <ReactApexChart
          options={this.state.options}
          series={this.state.series}
          type="bar"
          height="359"
        />
      </React.Fragment>
    );
  }
}

export default StackedColumnChart;
