import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as CameraHelper from "../../../helpers/ParseHelpers/camera_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from "../../../Constents";
import * as CameraActions from "./actions";

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getCameraForIdSaga({ payload: { cameraId } }) {
  try {
    const response = yield call(CameraHelper.getCameraForId, cameraId);
    if (response) {
      yield put(CameraActions.updateSingleCamera(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getCamerasForSchoolIdParse({ payload: { schoolId } }) {
  try {
    var key = Constants.CAMERA_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      CameraHelper.getCamerasForSchoolId,
      schoolId,
      null,
      true
    );
    if (localResponse && localResponse.length > 0) {
      yield put(CameraActions.updateCameras(localResponse));
    }

    const serverResponse = yield call(
      CameraHelper.getCamerasForSchoolId,
      schoolId,
      updatedTime,
      false
    );

    if (serverResponse) {
      localStorage.setItem(key, newTime);
      yield put(CameraActions.updateCameras(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getCameraLiveStreamsForSchoolIdParse({ payload: { schoolId } }) {
  try {
    var key = Constants.CAMERA_LIVE_STREAM_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      CameraHelper.getCameraLiveStreamsForSchoolId,
      schoolId,
      null,
      true
    );
    if (localResponse && localResponse.length > 0) {
      yield put(CameraActions.updateCameraLiveStreamsForSchoolId(localResponse));
    }

    const serverResponse = yield call(
      CameraHelper.getCameraLiveStreamsForSchoolId,
      schoolId,
      updatedTime,
      false
    );

    // console.log("CameraLiveStreamsForSchoolId", serverResponse)

    if (serverResponse) {
      yield put(CameraActions.updateCameraLiveStreamsForSchoolId(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
    localStorage.setItem(key, newTime);
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* resetCameraObject({ payload: {  } }) {
  try {
    yield put(CameraActions.resultCameraAfterSave(null));
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* saveCameraSaga({ payload: { cameraObject } }) {
  try {
    const response = yield call(CameraHelper.saveCameraToParse, cameraObject);
    if (response) {
      response.pin();
      yield put(CameraActions.resultCameraAfterSave(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deleteCameraSaga({ payload: { cameraObject ,successMessage,moveBack} }) {
  try {

    if (Parse.User.current().attributes.teacherId) {
      cameraObject.set("deletedBy", Parse.User.current().attributes.teacherId);
    } else {
      cameraObject.set("deletedBy", Parse.User.current().id);
    }
    const response = yield call(CameraHelper.saveCameraToParse, cameraObject);
    if (response) {
      response.unPin();
      yield put(CameraActions.updateCamera(response))
      yield put(CameraActions.resultCameraAfterSave(response));
      toastr.success(successMessage, { timeOut: 2000 });
      if (moveBack) {
        window.history.back();
      }
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* saveCameraAccessObjectsSaga({ payload: { cameraAccessObjects } }) {
  try {
    yield put(CameraActions.resultCameraAccessAfterSave(false));
    const response = yield call(
      CameraHelper.saveCameraAccessObjects,
      cameraAccessObjects
    );
    if (response) {
      yield put(CameraActions.resultCameraAccessAfterSave(true));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getCameraAccessObjectsForCameraIdSaga({ payload: { cameraId } }) {
  try {
    const response = yield call(
      CameraHelper.getCameraAccessObjectsForCameraId,
      cameraId
    );
    if (response) {
      yield put(CameraActions.updateCameraAccessObjects(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchSaveCameraAccessObjectsSaga() {
  yield takeEvery(
    actionTypes.SAVE_CAMERA_ACCESS_OBJECTS,
    saveCameraAccessObjectsSaga
  );
}
export function* watchGetCameraAccessObjectsForCameraIdSaga() {
  yield takeEvery(
    actionTypes.GET_CAMERA_ACCESS_OBJECTS_FOR_CAMERA_ID,
    getCameraAccessObjectsForCameraIdSaga
  );
}
export function* watchGetCameraForIdSaga() {
  yield takeEvery(actionTypes.GET_CAMERA_FOR_ID, getCameraForIdSaga);
}

export function* watchGetCamerasForSchoolIdParse() {
  yield takeEvery(
    actionTypes.GET_CAMERAS_FOR_SCHOOL_ID,
    getCamerasForSchoolIdParse
  );
}
export function* watchSaveCameraSaga() {
  yield takeEvery(actionTypes.SAVE_CAMERA, saveCameraSaga);
}
export function* watchResetCameraObject() {
  yield takeEvery(actionTypes.RESULT_CAMERA_RESET, resetCameraObject);
}

export function* watchDeleteCameraSaga() {
  yield takeEvery(actionTypes.DELETE_CAMERA, deleteCameraSaga);
}

export function* watchgetCameraLiveStreamsForSchoolIdParse() {
  yield takeEvery(
    actionTypes.GET_CAMERA_LIVESTREAMS_FOR_SCHOOL_ID,
    getCameraLiveStreamsForSchoolIdParse
  );
}

function* CameraSaga() {
  yield all([
    fork(watchGetCamerasForSchoolIdParse),
    fork(watchSaveCameraSaga),
    fork(watchGetCameraForIdSaga),
    fork(watchSaveCameraAccessObjectsSaga),
    fork(watchGetCameraAccessObjectsForCameraIdSaga),
    fork(watchDeleteCameraSaga),
    fork(watchgetCameraLiveStreamsForSchoolIdParse),
  ]);
}
export default CameraSaga;
