import React, { useEffect, useState } from "react";
import Parse from "parse";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Reactstrap
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  Card,
  CardBody,
  Media,
  Label,
  Row,
  Col
} from "reactstrap";
import * as WhiteLable from "../Common/WhiteLable";

// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import SupportDropdown from "../CommonForBoth/TopbarDropdown/SupportDropdown";


import logo from "../../assets/images/lilLogo.png";
import logoLightPng from "../../assets/images/logo-light.png";
import logoLightSvg from "../../assets/images/logo-light.svg";
// import logoDark from "../../assets/images/logo-dark.png";
// import github from "../../assets/images/brands/github.png";
// import bitbucket from "../../assets/images/brands/bitbucket.png";
// import dribbble from "../../assets/images/brands/dribbble.png";
// import dropbox from "../../assets/images/brands/dropbox.png";
// import mail_chimp from "../../assets/images/brands/mail_chimp.png";
// import slack from "../../assets/images/brands/slack.png";
// import megamenuImg from "../../assets/images/megamenu-img.png";


//i18n
import { withNamespaces } from "react-i18next";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  getSchoolForInstitute,
  setSelecetdSchool,
  showPricingPopup,
  setFetchStatus,
  showAdPopup,
  getFranchise,
  getUserTeacher,
  setMessageQueueTimer,
  setAttendanceQueueTimer
} from "../../store/actions";

import * as Constants from "../../Constents";

import PricingPopup from '../../pages/lilTrianglePricing/PricingPopup'
import HeaderApps from "./HeaderApps";
import AdPopup from '../../pages/AdPopups/IdCardCctvTrips'
import * as CommonFunctions from "../Common/CommonFunctions";
import * as Analytics from "../Common/Analytics";


const Header = (props) => {
  const [search, setsearch] = useState(false);
  const [schoolSearchString, setSchoolSearchString] = useState("");
  const [megaMenu, setmegaMenu] = useState(false);
  const [socialDrp, setsocialDrp] = useState(false);
  const [school, setSchool] = useState(undefined);
  //const [schoolList, setSchoolList] = useState(undefined);
  const isMobile = /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);

  useEffect(() => {
    if (Parse.User.current() && props.userTeacher && props.featchStatus === false) {
      props.setFetchStatus(true);
      if (Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.franchiseId.length > 0) {
        props.getSchoolForInstitute(Parse.User.current().attributes.franchiseId, "franchiseId", props.userTeacher);
      } else {
        props.getSchoolForInstitute(Parse.User.current().attributes.instituteId, "instituteId", props.userTeacher);
      }
    }

    if(props.messageQueueTimer != null && !CommonFunctions.isMessageQueue() ){
      clearInterval(props.messageQueueTimer);
      props.setMessageQueueTimer(null);
    }
    if(props.attendanceQueueTimer != null && !window.location.pathname.toLowerCase().startsWith("/attendence") ){
      clearInterval(props.attendanceQueueTimer);
      props.setAttendanceQueueTimer(null);
    }
  }, [Parse.User.current(), props.userTeacher]);

  useEffect(() => {
    if (!props.userTeacher) {
      if (Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.teacherId) {
        props.getUserTeacher(Parse.User.current().attributes.teacherId)
      }
    }
  }, [props.userTeacher]);

  useEffect(() => {
    if (Parse.User.current()) {
      if (Parse.User.current().attributes.instituteId && Parse.User.current().attributes.instituteId.length > 0) {
        if (props.franchise == null) {
          if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
            props.getFranchise(props.selectedSchool.attributes.franchiseId);
          }
        }
      }
    }
  }, [Parse.User.current(), props.franchise]);

  const [autoPopup, setAutoPopup] = useState(undefined);
  const SHOWPOPUP = localStorage.getItem("DONT_SHOW_PRICING_POPUP_AGAIN");

  const DONT_SHOW_AD_POPUP = localStorage.getItem("DONT_SHOW_AD_POPUP");

  useEffect(() => {
    if (SHOWPOPUP !== "true" && false) {
      let lastDate = localStorage.getItem("PRICING_POPUP_LAST_SHOWN");
      if (!lastDate || !isToday(lastDate)) {
        localStorage.setItem("SHOW_PRICING_POPUP", "show");
        setAutoPopup(true);
        props.showPricingPopup(true);
      }

    }
  }, [SHOWPOPUP]);

  useEffect(() => {
    if (DONT_SHOW_AD_POPUP !== "true") {
      let lastDate = localStorage.getItem("AD_POPUP_LAST_SHOWN");
      if (!lastDate || isOneWeekOld(lastDate)) {
        localStorage.setItem("AD_POPUP_LAST_SHOWN", new Date());
        props.showAdPopup(true);
      }

    } else {
      if (props.adPopup) {
        props.showAdPopup(false)
      }
    }
  }, [DONT_SHOW_AD_POPUP]);


  const isToday = (lastDate) => {
    let date = new Date(lastDate);
    const today = new Date()
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  }

  const isOneWeekOld = (lastDate) => {
    let date = new Date(lastDate);
    const today = new Date()
    let day = 1000 * 60 * 60 * 24;
    let sevenDays = new Date(today.getTime() - 7 * day);

    if (sevenDays >= lastDate) {
      return true;
    }

    return true;
  }

  const handelSwitchSchool = (school) => {
    var schoolId = "xyz";
    if (props.selectedSchool) {
      schoolId = props.selectedSchool.id;
    }
    console.log("- SchoolID", schoolId)
    if (school.id != schoolId) {
      setSchool(school);
      localStorage.setItem(Constants.SELECTED_SCHOOL_ID, school.id);
      props.setSelecetdSchool(school.id);
      setmegaMenu(!megaMenu);
      window.location = "/switching-school/" + school.id;
    }
  };

  function getSchoolName() {
    if (props.selectedSchool && props.selectedSchool.attributes) {
      return props.selectedSchool.attributes.Name;
    } else if (school && school.attributes) {
      return school.attributes.Name;
    }
    return "...";
  }

  useEffect(() => {

    if (props.selectedSchool && props.selectedSchool.attributes) {
      let gracePeriod = props.selectedSchool.attributes.gracePeriod ?? 0;
      let expDate = props.selectedSchool.attributes.subscriptionExpiryDate
      if (expDate) {
        let newDate = expDate.setDate(expDate.getDate() + gracePeriod);
        let today = new Date();
        if (today > newDate) {
          if (window.location.pathname.toLowerCase().startsWith("/school-subscription")) {

          } else {
            window.location = ("/school-subscription/" + props.selectedSchool.id);
          }
        } else if (window.location.pathname.toLowerCase().startsWith("/school-subscription")) {
          window.location = ("/");
        }

      }
    }

  }, [props.selectedSchool, window.location.pathname]);

  useEffect(() => {

    
    if ((Parse.User.current().attributes.franchiseId || CommonFunctions.isFranchise()) && props.franchise && props.franchise.attributes) {
      let gracePeriod = props.franchise.attributes.gracePeriod ?? 0;
      let expDate = props.franchise.attributes.subscriptionExpiryDate
      if (expDate) {
        let newDate = expDate.setDate(expDate.getDate() + gracePeriod);
        let today = new Date();
        if (today > newDate) {
          if (window.location.pathname.toLowerCase().startsWith("/application-subscription")) {

          } else {
            window.location = ("/application-subscription/" + props.franchise.id);
          }
        } else if (window.location.pathname.toLowerCase().startsWith("/application-subscription")) {
          window.location = ("/");
        }

      }
    }

  }, [props.franchise, window.location.pathname]);

  function toggleFullscreen() {
    if (
      !document.fullscreenElement &&
      /* alternative standard method */ !document.mozFullScreenElement &&
      !document.webkitFullscreenElement
    ) {
      // current working methods
      if (document.documentElement.requestFullscreen) {
        document.documentElement.requestFullscreen();
      } else if (document.documentElement.mozRequestFullScreen) {
        document.documentElement.mozRequestFullScreen();
      } else if (document.documentElement.webkitRequestFullscreen) {
        document.documentElement.webkitRequestFullscreen(
          Element.ALLOW_KEYBOARD_INPUT
        );
      }
    } else {
      if (document.cancelFullScreen) {
        document.cancelFullScreen();
      } else if (document.mozCancelFullScreen) {
        document.mozCancelFullScreen();
      } else if (document.webkitCancelFullScreen) {
        document.webkitCancelFullScreen();
      }
    }
  }
  const canAddSchool = () => {

    if (props.userTeacher && props.userTeacher.attributes.Role === 3 &&
      Parse.User.current() && Parse.User.current().attributes.instituteId &&
      Parse.User.current().attributes.instituteId.length > 0 &&
      Parse.User.current().attributes.isSuperUser) {

      if (props.franchise &&
        props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_SCHOOL_ADDING_DISABLE_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_SCHOOL_ADDING_DISABLE_INDEX] > 0) {

        return false;
      }
      return true;
    } else {
      return false;
    }

  }

  function tToggle() {
    props.toggleLeftmenu(!props.leftMenu);
    if (props.leftSideBarType === "default") {
      props.changeSidebarType("condensed", isMobile);
    } else if (props.leftSideBarType === "condensed") {
      props.changeSidebarType("default", isMobile);
    }
  }
  // console.log(props.schools);




  const getSchoolsFilteredList = (schoolList) => {
    var listToReturn = [...schoolList];
    if (schoolSearchString !== "") {
      listToReturn = listToReturn.filter((object) =>
        object.attributes.Name.toLowerCase().includes(schoolSearchString.toLowerCase()) ||
        (object.attributes.schoolCode && object.attributes.schoolCode.toLowerCase().includes(schoolSearchString.toLowerCase())) ||
        (object.attributes.city && object.attributes.city.toLowerCase().includes(schoolSearchString.toLowerCase()))
      );
    }
    return listToReturn;
  };


  return (
    <React.Fragment>
      <header id="page-topbar">
        <div className="navbar-header">
          <div className="d-flex">

            {CommonFunctions.isFranchise() ?
              <div className="navbar-brand-box">
                <Label className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={WhiteLable.getAppLogo()} alt="" height={WhiteLable.getAppLogoHeight()} />
                  </span>
                  <span className="logo-lg">
                    <img src={WhiteLable.getAppLogoWithName()} alt="" height={WhiteLable.getAppLogoWithNameHeight()} />
                  </span>
                </Label>
                <Label className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="35" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="19" />
                  </span>
                </Label>
              </div>
              :
              <div className="navbar-brand-box">
                <Link to="/" className="logo logo-dark">
                  <span className="logo-sm">
                    <img src={WhiteLable.getAppLogo()} alt="" height={WhiteLable.getAppLogoHeight()} />
                  </span>
                  <span className="logo-lg">
                    <img src={WhiteLable.getAppLogoWithName()} alt="" height={WhiteLable.getAppLogoWithNameHeight()} />
                  </span>
                </Link>
                <Link to="/" className="logo logo-light">
                  <span className="logo-sm">
                    <img src={logoLightSvg} alt="" height="35" />
                  </span>
                  <span className="logo-lg">
                    <img src={logoLightPng} alt="" height="19" />
                  </span>
                </Link>
              </div>
            }


            <button
              type="button"
              onClick={() => {
                tToggle();
              }}
              className="btn btn-sm px-3 font-size-16 header-item waves-effect"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input
                  type="text"
                  className="form-control"
                  placeholder={props.t("Search") + "..."}
                />
                <span className="bx bx-search-alt"></span>
              </div>
            </form> */}
            {CommonFunctions.isTools() ? <div className="dropdown-mega  d-lg-block ml-2 py-4"><Label>lilTriangle support tools</Label></div> :
              <div>
                {CommonFunctions.isFranchise() ?

                  <div></div> :
                  <Dropdown
                    className="dropdown-mega  d-lg-block ml-2"
                    isOpen={megaMenu}
                    toggle={() => {
                      if (props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10) {
                        setmegaMenu(!megaMenu);
                      }

                    }}
                  >
                    <DropdownToggle
                      className="btn header-item waves-effect"
                      caret
                      tag="button"
                    >
                      {" "}
                      {getSchoolName()}{props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <i className="mdi mdi-chevron-down"></i>}
                    </DropdownToggle>
                    <DropdownMenu className="dropdown-megamenu" style={{ maxHeight: `calc(100vh - 150px)`, overflowY: "auto" }}>
                      <Row>
                        <Col xl={12}>
                          <Row>
                            <Col lg={8}>
                              <h5 className="font-size-14 mt-3 ml-3">
                                {props.t("ALL SCHOOLS")}
                              </h5>
                            </Col>

                            <Col lg={4}>
                              <div className="app-search">
                                <div className="position-relative">
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder={props.t("Search") + "..."}
                                    onChange={(e) => {
                                      setSchoolSearchString(e.target.value)
                                    }}
                                  />
                                  <span className="bx bx-search-alt"></span>
                                </div>
                              </div>

                            </Col>
                          </Row>

                        </Col>
                      </Row>
                      <Row className="justfy-content-start">
                        {canAddSchool(props.franchise) && (
                          <Col xl="3" sm="6">
                            <Link to="/new-branch">
                              <Card>
                                <CardBody>
                                  <div className="d-flex justify-content-start">
                                    <div className="avatar-sm mr-4">
                                      <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                        <div style={{ fontSize: "26px" }}>
                                          <i className="bx bx-buildings"></i>
                                        </div>
                                      </span>
                                    </div>

                                    <Media className="overflow-hidden" body style={{ marginLeft: '20px' }}>
                                      <h5 className="text-truncate font-size-15 mt-3 text-primary">
                                        {props.t("Add New Branch")}
                                      </h5>
                                      <p className="text-muted mb-4">{ }</p>
                                    </Media>
                                  </div>
                                </CardBody>
                              </Card>
                            </Link>
                          </Col>
                        )}

                        {props.schools != null &&
                          props.schools.length > 0 &&
                          getSchoolsFilteredList(props.schools).map((school, key) => (
                            <Col xl="3" sm="6" key={"_school_" + key}>
                              <Card
                                onClick={() => {
                                  handelSwitchSchool(school);
                                  let user = Parse.User.current()
                                  let type ;
                                  let id;
                                  if(user.attributes.instituteId){
                                    type = "institute"
                                    id = user.attributes.instituteId
                                  }else if(user.attributes.franchiseId){
                                    type = "franchise"
                                    id = user.attributes.franchiseId
                                  }
                                  Analytics.track_SchoolSwitch(user.id, user.attributes.email, props.userTeacher.attributes.Name, type, id,props.selectedSchool.id, school.id, props.selectedSchool.attributes.Name, school.attributes.Name )
                                }}
                              >
                                <CardBody>
                                  <div className="d-flex justify-content-start">
                                    {school.attributes.logo != undefined ? (
                                      <div className="avatar-sm  mr-4 mt-0">
                                        <img
                                          src={
                                            school.attributes.logo == undefined
                                              ? logo
                                              : school.attributes.logo._url
                                          }
                                          alt=""
                                          className="img-thumbnail rounded-circle"
                                        />
                                      </div>
                                    ) : (
                                      <div className="avatar-sm mr-4">
                                        <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                          <div style={{ fontSize: "26px" }}>
                                            <i className="bx bx-buildings"></i>
                                          </div>
                                        </span>
                                      </div>
                                    )}

                                    <div style={{ marginLeft: '30px' }}>
                                      <h5 className="text-truncate font-size-14 mt-0">
                                        <Link
                                          to="#"
                                          className={
                                            school.id ===
                                              (props.selectedSchool &&
                                                props.selectedSchool.id)
                                              ? "text-primary"
                                              : "text-dark"
                                          }
                                        >
                                          {school.attributes.Name}
                                        </Link>
                                      </h5>
                                      <p className="text-muted ">
                                        {school.attributes.locality ? school.attributes.locality : ""} {school.attributes.city}
                                      </p>

                                      {/*{project.scaleType === 1 && <h5><StarRatings
                                            rating={parseFloat(project.ratingValue.toFixed(1))}
                                            starRatedColor="#F1B44C"
                                            starEmptyColor="#2D363F"
                                            numberOfStars={5}
                                            name='rating'
                                            starDimension="14px"
                                            starSpacing="3px"
                                        />      {parseFloat(project.ratingValue.toFixed(1))}</h5>}
                                        {project.scaleType === 3 && <span><h5><i className="fas fa-thumbs-up text-warning"></i>{" " + project.upCount}
                                            <i className="ml-2 fas fa-thumbs-down text-warning"></i>{" " + project.downCount}</h5></span>
                                        } */}
                                    </div>
                                  </div>
                                </CardBody>
                              </Card>
                            </Col>
                          ))}

                        {/* <Col md={4}>
                          <h5 className="font-size-14 mt-0">{props.t('UI Components')}</h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{props.t('Lightbox')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Range Slider')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Sweet Alert')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Rating')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Forms')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Tables')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Charts')}</Link>
                            </li>
                          </ul>
                        </Col>

                        <Col md={4}>
                          <h5 className="font-size-14 mt-0">{props.t('Applications')}</h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{props.t('Ecommerce')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Calendar')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Email')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Projects')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Tasks')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Contacts')}</Link>
                            </li>
                          </ul>
                        </Col>

                        <Col md={4}>
                          <h5 className="font-size-14 mt-0">{props.t('Extra Pages')}</h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{props.t('Light Sidebar')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Compact Sidebar')}</Link>
                            </li>
                            <li>
                              <Link to="#">
                                {props.t('Horizontal layout')}
                              </Link>
                            </li>
                            <li>
                              <Link to="#">  {props.t('Maintenance')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Coming Soon')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Timeline')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('FAQs')}</Link>
                            </li>
                          </ul>
                        </Col> */}
                      </Row>

                      {/* <Col sm={4}>
                      <Row>
                        <Col sm={6}>
                          <h5 className="font-size-14 mt-0">{props.t('UI Components')}</h5>
                          <ul className="list-unstyled megamenu-list">
                            <li>
                              <Link to="#">{props.t('Lightbox')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Range Slider')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Sweet Alert')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Rating')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Forms')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Tables')}</Link>
                            </li>
                            <li>
                              <Link to="#">{props.t('Charts')}</Link>
                            </li>
                          </ul>
                        </Col>

                        <Col sm={5}>
                          <div>
                            <img
                              src={megamenuImg}
                              alt=""
                              className="img-fluid mx-auto d-block"
                            />
                          </div>
                        </Col>
                      </Row>
                    </Col>  */}

                    </DropdownMenu>
                  </Dropdown>}
              </div>}
          </div>
          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ml-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon waves-effect"
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify"></i>
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-right p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify"></i>
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}


            {/* <Dropdown
              className="d-none d-lg-inline-block ml-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon waves-effect"
                tag="button"
              >
                <i className="bx bx-customize"></i>
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg" right>
                <div className="px-lg-2">
                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="no-gutters">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            {props.selectedSchool &&
              props.selectedSchool.attributes.schoolType !== 10 && !CommonFunctions.isFranchise() &&

              <HeaderApps></HeaderApps>
            }



            {/* <LanguageDropdown /> */}


            <div className="dropdown d-none d-lg-inline-block ml-1">
              <button
                type="button"
                onClick={() => {
                  toggleFullscreen();
                }}
                className="btn header-item noti-icon waves-effect"
                data-toggle="fullscreen"
              >
                <i className="bx bx-fullscreen"></i>
              </button>
            </div>

                <SupportDropdown/>
            {/* <NotificationDropdown /> */}
            <ProfileMenu schoolId={props.selectedSchool ? props.selectedSchool.id : ""} />

            {props.selectedSchool && props.selectedSchool.attributes.schoolType !== 10 && <div
              onClick={() => {
                props.showRightSidebarAction(!props.showRightSidebar);
                //props.showAdPopup(true);

              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle waves-effect"
              >
                <i className="bx bx-mobile text-lilpink "></i>
              </button>

            </div>}

            {props.selectedSchool &&
              props.selectedSchool.attributes.schoolType !== 10 && false && <div
                onClick={() => {
                  localStorage.setItem("SHOW_PRICING_POPUP", "show");
                  setAutoPopup(false);
                  props.showPricingPopup(true);
                }}
                className="dropdown d-inline-block"
                style={{ cursor: "pointer" }}
              >
                <button
                  style={{ cursor: "pointer" }}
                  type="button"
                  className="btn header-item noti-icon right-bar-toggle waves-effect"
                >
                  <i className="bx bx-rupee text-lilpink"  ></i>
                </button>
              </div>}
          </div>
        </div>
      </header >
      {
        localStorage.getItem("SHOW_PRICING_POPUP") === "show"
        && props.pricingPopup && props.selectedSchool &&
        props.selectedSchool.attributes.schoolType !== 10 &&
        <PricingPopup autoPopup={autoPopup}></PricingPopup>
      }

      {
        props.adPopup && props.selectedSchool &&
        props.selectedSchool.attributes.schoolType !== 10 &&
        <AdPopup />
      }


    </React.Fragment >
  );
};
const mapStatetoProps = (state) => {
  const {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    pricingPopup,
  } = state.Layout;
  const { schools, selectedSchool, featchStatus } = state.School;
  const { userTeacher } = state.Login;
  const { adPopup } = state.Layout;
  const { franchise } = state.Franchise;
  const { messageQueueTimer } = state.Message;
  const { attendanceQueueTimer} = state.Attendance
  return {
    layoutType,
    showRightSidebar,
    leftMenu,
    leftSideBarType,
    schools,
    selectedSchool,
    featchStatus,
    pricingPopup,
    userTeacher,
    adPopup,
    franchise,
    messageQueueTimer,
    attendanceQueueTimer
  };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  getSchoolForInstitute,
  setSelecetdSchool,
  showPricingPopup,
  setFetchStatus,
  showAdPopup,
  getFranchise,
  getUserTeacher,
  setMessageQueueTimer,
  setAttendanceQueueTimer
})(withNamespaces()(Header));
