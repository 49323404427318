export const UPDATE_ADMISSION_TITLE = "UPDATE_ADMISSION_TITLE";
export const DELETE_ADMISSION_TITLE = "DELETE_ADMISSION_TITLE";

export const UPDATE_ADMISSION_TITLES = "UPDATE_ADMISSION_TITLES";   
export const GET_ADMISSION_TITLES = "GET_ADMISSION_TITLES";
export const GET_ADMISSION_TITLE = "GET_ADMISSION_TITLE";

export const ADD_ADMISSION_TITLE = "ADD_ADMISSION_TITLE";
export const EDIT_ADMISSION_TITLE = "EDIT_ADMISSION_TITLE";

export const UPDATE_ADMISSION_TITLE_SORT_ORDER = "UPDATE_ADMISSION_TITLE_SORT_ORDER";
export const RELOAD_ADMISSION_TITLES = "RELOAD_ADMISSION_TITLES";