
import React, { useState } from 'react';
import { Row, Col, Form, Button, UncontrolledTooltip, Input, Dropdown, DropdownMenu, DropdownItem, DropdownToggle, FormGroup } from "reactstrap";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import toastr from "toastr";
import "toastr/build/toastr.min.css";
import * as WhiteLable from "../../components/Common/WhiteLable";

const Topbar = (props) => {

    const [openDropDown, setOpenDropDown] = useState(false);
    const [phoneNumber, setPhoneNumber] = useState();


    const handleNewWhatsapp = (e, phoneNumber) => {
        e.preventDefault();
        const formattedNumber = phoneNumber.replace('+91', '').slice(-10);
        const whatsappUrl = `https://api.whatsapp.com/send?phone=+91${formattedNumber}&text=${encodeURIComponent(copyEnquiryLink(event, true))}`;
        window.open(whatsappUrl);
    };

    const HOST_NAME = WhiteLable.getAppName() === "lilTriangle" ? "https://forms.liltriangle.com" : WhiteLable.getHost();
    const ENQUIRY_FORM = HOST_NAME + "/enquiry-form/" + props.schoolId;

    function copyEnquiryLink(event, onlyLink) {
        let str = `Dear Parent,\n\We extend a very warm welcome to you into our ${props.schoolName}.\n\nPlease submit your enquiry by clicking on below link.\n${ENQUIRY_FORM}\n\nRegards,\n${props.schoolName}.\n\nPowered by lilTriangle`;


        if (onlyLink) {
            return str
        }
        document.oncopy = function (event) {
            event.clipboardData.setData("Text", str);
            event.preventDefault();
        };
        document.execCommand("Copy");
        document.oncopy = undefined;
        toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
    }

    const handleSendEmail = (event) => {
        const subject = encodeURIComponent('Hello');
        const body = encodeURIComponent(copyEnquiryLink(event, true));
        window.location.href = `mailto:?subject=${subject}&body=${body}`;
    }

    const handleSendExistingWhatsApp = (event) => {
        const whatsappUrl = `https://api.whatsapp.com/send?&text=${encodeURIComponent(copyEnquiryLink(event, true))}`;
        window.open(whatsappUrl);
    }

    return (

        <Row className='d-flex justify-content-between '>

            <Col lg={4} md={6} xs={12}>
                <h4>{props.title.toUpperCase()}</h4>
            </Col>
            <Col lg={6} md={6} xs={12}>
                <div className="d-flex justify-content-end mb-2">
                    {props.showSearchBar && <div className="search-box mr-2 mb-2">
                        <div className="position-relative">
                            <Input type="text" className="form-control border-0" placeholder="Search..."

                                onChange={(e) => {

                                    props.onSearch(e.target.value)
                                }}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>}
                    {props.hideSharebutton === false && <Dropdown
                        isOpen={openDropDown}
                        toggle={(e) => {
                            if (e.target.id == "wa-phoneNumber" ||
                                e.target.id == "wa-newWhatsApp" ||
                                e.target.id == "wa-submitButton") {
                            } else {
                                setOpenDropDown(!openDropDown)
                            }
                        }}
                        direction="left"
                    >
                        <DropdownToggle tag="button" className="btn btn-success mr-2" style={{ marginLeft: '10px'}}>
                            <i className="bx bx-share-alt"></i>
                        </DropdownToggle>
                        <DropdownMenu>
                            <DropdownItem style={{ width: '250px' }} onClick={(event) => { copyEnquiryLink(event) }}>Copy link <i className=" bx bx-copy float-right"></i></DropdownItem><div className='dropdown-divider'></div>
                            <DropdownItem style={{ width: '250px' }} target="_blank" href={  "/enquiryposter/" + props.schoolId }>Enquiry Poster<i className="bx bx-barcode float-right"></i></DropdownItem><div className='dropdown-divider'></div>
                            <DropdownItem style={{ width: '250px' }} onClick={(e) => { handleSendEmail(e) }}>Send link via Email<i className="bx bx-mail-send float-right"></i></DropdownItem><div className='dropdown-divider'></div>
                            <DropdownItem style={{ width: '250px' }} onClick={(e) => { handleSendExistingWhatsApp(e) }}>Existing whatsapp<i className="bx bxl-whatsapp float-right"></i></DropdownItem><div className='dropdown-divider'></div>
                            <DropdownItem id="wa-newWhatsApp" style={{ width: '250px', margin: '10px' }} onClick={() => { }}>New whatsapp<i className="bx bxl-whatsapp float-right"></i>
                                <Form onSubmit={handleNewWhatsapp} style={{ width: '200px' }}>
                                    <FormGroup>
                                        <Input
                                            id="wa-phoneNumber"
                                            style={{ margin: '10px' }}
                                            type="tel"
                                            placeholder="10 digit Phone Number"
                                            className='form-control'

                                        /></FormGroup>
                                    <Button color="primary" id="wa-submitButton"
                                        onClick={(e) => {
                                            let ph = document.getElementById('wa-phoneNumber').value
                                            if (ph) {
                                                handleNewWhatsapp(e, ph)
                                                setOpenDropDown(!openDropDown)
                                            }
                                        }}

                                        className='btn-sm mb-2 mr-2' >Send</Button>
                                </Form>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    {props.hideButton === false&&<Link to={"/enquiry-tasks/" + props.schoolId}>
                        <Button
                            type="button"
                            color="primary"
                            className="btn waves-effect waves-light  mb-2" style={{ marginLeft: '10px' }}>
                            {props.t('Tasks')}
                        </Button>
                    </Link>}
                    {props.hideButton === false && <Link to={"/editenquiryinside/" + props.schoolId}>
                        <Button
                            type="button"
                            color="primary"
                            className="btn waves-effect waves-light  mb-2" style={{ marginLeft: '10px'}}>
                            {props.t('Add Enquiry')}
                        </Button>
                    </Link>}
                </div>
            </Col>
        </Row>
    );
}
export default withNamespaces()(Topbar);
