import { takeEvery, fork, put, all, call, } from "redux-saga/effects";
import Parse from "parse";

import * as FileHelper from "../../../helpers/ParseHelpers/files_helper";
import * as actionTypes from "./actionTypes";
import * as FileActions from "./actions";
import * as Constants from "../../../Constents";

import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getAFile({ payload: { id } }) {
  try {
    const response = yield call( FileHelper.getFileFromParse, id,false);
    yield put(FileActions.updateFile(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAFile({ payload: { file } }) {
  try {
    const response = yield call(
        FileHelper.deleteFileFromParse,
        file
    );
    if (response) {
      yield put(FileActions.deleteFile(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}



  
function* getFileForFolderId({ payload: { folderId } }) {
  try {

    if(folderId === 1){
      yield put(FileActions.clearFiles(1));
    }else if(folderId === 3){
      yield put(FileActions.clearFiles(3));
    }else if (folderId) {
      yield put(FileActions.clearFiles(1));
      const localResponse = yield call(FileHelper.getFileForFolderId, folderId, true);
      yield put(FileActions.updateFiles(localResponse));

      const serverResponse = yield call(FileHelper.getFileForFolderId, folderId, false);
      
      if (serverResponse && serverResponse.length > 0) {
        yield put(FileActions.updateFiles(serverResponse));
        yield put(FileActions.setFileLoadingState(2));
        Parse.Object.pinAll(serverResponse);
      } else {
        yield put(FileActions.setFileLoadingState(3));
      }
    }else {
      yield put(FileActions.clearFiles(3));
      // yield put(FileActions.setFileLoadingState(3));
    }
    
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getFileForCourseId({ payload: { courseId } }) {
  try {

    if (courseId) {
      yield put(FileActions.clearFiles(1));
     

      var key = Constants.FILE_UPDATED_TIME + "_" + courseId;
      var updatedTime = localStorage.getItem(key);
      var newTime = new Date();
      const localResponse = yield call(
        FileHelper.getFilesForCourseId,
        courseId,
        null,
        true
      );
      if (localResponse && localResponse.length > 0) {
        yield put(FileActions.updateFiles(localResponse));
      }
    

      const serverResponse = yield call(
        FileHelper.getFilesForCourseId,
        courseId,
        updatedTime,
        false
      );
      localStorage.setItem(key, newTime);

      if (serverResponse && serverResponse.length > 0) {
        yield put(FileActions.updateFiles(localResponse));
        Parse.Object.pinAll(serverResponse);
      }
    }
    
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* addFile({ payload: { file ,successMessage} }) {
  try {
    yield put(FileActions.setFileAddState(true));
    const response = yield call( FileHelper.saveFile, file);
    if (response) {
      yield put(FileActions.updateFile(response));
        response.pin();
        toastr.success(successMessage, { timeOut: 2000 });
    } else {
      
    }
    yield put(FileActions.setFileAddState(false));
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getNoticeBoardFilesForSchoolId({ payload: { schoolId,} }) {
  try {
    
    
    var key = Constants.FILE_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      FileHelper.getNoticeBoardFilesForSchoolId,
      schoolId,
      null,
      true
    );
    if (localResponse && localResponse.length > 0) {
      yield put(FileActions.updateNoticeBoardFiles(localResponse));
    }
  
    const serverResponse = yield call(
      FileHelper.getNoticeBoardFilesForSchoolId,
      schoolId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(FileActions.updateNoticeBoardFiles(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deleteNoticeBoardFile({ payload: { file } }) {
  try {
    const response = yield call(
        FileHelper.deleteFileFromParse,
        file
    );
    if (response) {
      yield put(FileActions.updateNoticeBoardFiles([response]));
      response.unPin();
      toastr.success("Successfully Deleted", { timeOut: 2000 });
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


export function* watchGetFile() {
    yield takeEvery(actionTypes.GET_FILE, getAFile);
  }
  
export function* watchDeleteFile() {
  yield takeEvery(actionTypes.DELETE_FILE, deleteAFile);
}

export function* watchGetFileForFolderId() {
  yield takeEvery(actionTypes.GET_FILES, getFileForFolderId);
}

export function* watchAddFile() {
  yield takeEvery(actionTypes.ADD_FILE, addFile);
}

export function* watchGetFileForCourseId() {
  yield takeEvery(actionTypes.GET_FILES_FOR_COURSE_ID, getFileForCourseId);
}

export function* watchgetNoticeBoardFilesForSchoolId() {
  yield takeEvery(actionTypes.GET_NOTICE_BOARD_FILES_FOR_SCHOOL_ID, getNoticeBoardFilesForSchoolId);
}

export function* watchdeleteNoticeBoardFile() {
  yield takeEvery(actionTypes.DELETE_NOTICE_BOARD_FILE, deleteNoticeBoardFile);
}

function* FilesSaga() {
  yield all([
    fork(watchGetFile),
    fork(watchDeleteFile),
    fork(watchGetFileForFolderId),
    fork(watchAddFile),
    fork(watchGetFileForCourseId),
    fork(watchgetNoticeBoardFilesForSchoolId),
    fork(watchdeleteNoticeBoardFile),
  ]);
}
  
export default FilesSaga;