import * as actions from "./actionTypes";

const initialState = {
  enquiryQuestions: [], loding: undefined, 
};

const EnquiryQuestion = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_ENQUIRY_QUESTION:
      var enquiryQuestions = [...state.enquiryQuestions];
      var temp = state.enquiryQuestions.filter(
        (enquiryQuestion) =>
          enquiryQuestion.id === action.payload.enquiryQuestion.id
      );
      if (temp.length > 0) {

        if (action.payload.enquiryQuestion.attributes.isDeleted) {

          var others = state.enquiryQuestions.filter(
            (enquiryQuestion) =>
              enquiryQuestion.id !== action.payload.enquiryQuestion.id
          );
          state = { ...state, enquiryQuestions: others };
          
        } else {
          var index = enquiryQuestions.indexOf(temp[0]);
          enquiryQuestions[index] = action.payload.enquiryQuestion;
          state = { ...state, enquiryQuestions: enquiryQuestions };
        }

       
      } else {
        enquiryQuestions.push(action.payload.enquiryQuestion);
        state = { ...state, enquiryQuestions: enquiryQuestions };
      }
      break;
    case actions.UPDATE_ENQUIRY_QUESTIONS:
      var enquiryQuestions = [...state.enquiryQuestions];
      if (enquiryQuestions.length > 0) {
        for (const latest in action.payload.enquiryQuestions) {
          var temp = enquiryQuestions.filter(
            (enquiryQuestion) =>
              enquiryQuestion.id === action.payload.enquiryQuestions[latest].id
          );

          
          if (temp.length > 0) {
            if (action.payload.enquiryQuestions[latest].attributes.isDeleted) {

              var others = enquiryQuestions.filter(
                (enquiryQuestion) =>
                  enquiryQuestion.id !== action.payload.enquiryQuestions[latest].id
              );
             
              enquiryQuestions = others;
            } else {
              var index = enquiryQuestions.lastIndexOf(temp[0]);
              enquiryQuestions[index] = action.payload.enquiryQuestions[latest];
            }
           
          } else {
            if(! action.payload.enquiryQuestions[latest].attributes.isDeleted){
              enquiryQuestions.push(action.payload.enquiryQuestions[latest]);
            }
          }
        }
        state = { ...state, enquiryQuestions: enquiryQuestions };
      } else {
        // state = { ...state, enquiryQuestions: action.payload.enquiryQuestions };
        var others = action.payload.enquiryQuestions.filter(
          (enquiryQuestion) => enquiryQuestion.attributes.isDeleted !== true
        );
          
        state = { ...state, enquiryQuestions: others };
      }

      break;
    case actions.UPDATE_DELETE_ENQUIRY_QUESTION:
      var enquiryQuestions = state.enquiryQuestions.filter(
        (enquiryQuestion) => enquiryQuestion.id !== action.payload.enquiryQuestion.id
      );
      state = { ...state, enquiryQuestions: enquiryQuestions };
      break;
    
      case actions.ENQUIRY_QUESTION_LODING:
      state = { ...state, loding: action.payload.state };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EnquiryQuestion;
