import React, { useEffect, useState } from 'react';
import {
    Container, Row, Col, Label, Card, DropdownItem, ButtonDropdown,
    CardBody, UncontrolledTooltip, DropdownMenu, DropdownToggle, Modal, ModalHeader, ModalBody,
} from "reactstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link, matchPath } from 'react-router-dom';
import { connect } from 'react-redux';
import DocumentViewerModal from './DocumentViewerModal';
import Iframe from "react-iframe";
import SweetAlert from "react-bootstrap-sweetalert";

import {
    getMessageQueues,
    getFranchiseTeachers,
    updateMessageQueues,
    getAlbumsForAlbumIds,
    setSelecetdSchool,
    getTeachersForSchool,
    getClassroomsForSchoolId,
    setMessageQueueTimer,

} from '../../store/actions';
import moment from 'moment';
import MessageQueueAddMessage from './MessageQueueAddMessage';

const MessageQueueList = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: '/message-franchise/:franchiseId',
        exact: true,
        strict: false,

    });
    const match2 = matchPath(props.history.location.pathname, {
        path: '/message-school/:schoolId',
        exact: true,
        strict: false,

    });
    const franchiseId = match && match.params.franchiseId;
    const schoolId = match2 && match2.params.schoolId

    const SendTo = {
        allCenterheadAndDirector: "@All Directors/CenterHead",
        allTeachers: "@All Teachers",
        allChildren: "@All Children",
        Classroom: "@Classroom",
        allEnquiries: "@All Enquiries",
        allAdmissions: "@All Admissions",
        allAlumni: "@All Alumni",
    };

    Object.freeze(SendTo);
    const [isLoading, setIsLoading] = useState(false);
    const [mainList, setMainList] = useState([]);
    const [showAddNewMessage, setShowAddNewMessage] = useState(false);
    const [selectedIdActionMenuOpen, setSelectedIdActionMenuOpen] = useState();
    const [isShowGallery, setIsShowGallery] = useState(false);
    const [photoIndex, setphotoIndex] = useState(0);
    const [showVideoModel, setShowVideoModel] = useState(false);
    const [clickedFile, setClickedFile] = useState(false);
    const [showEnquiryDeleteAlert, setShowEnquiryDeleteAlert] = useState(false);
    const [showOneDayDeleteAlert, setShowOneDayDeleteAlert] = useState(false);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false)
    const [deleteObject, setDeleteObject] = useState(undefined)
    const [showEditMessage, setShowEditMessage] = useState(false);
    const [selectedMessageForEditing, setSelectedMessageForEditing] = useState(false);

    const [sendToList, setSendToList] = useState([
        { value: 2, label: SendTo.allCenterheadAndDirector },
        { value: 1, label: SendTo.allTeachers },
        { value: 3, label: SendTo.allChildren },
        { value: schoolId ? 6 : 10, label: SendTo.Classroom },
        { value: 4, label: SendTo.allEnquiries },
        { value: 5, label: SendTo.allAdmissions },
        { value: 9, label: SendTo.allAlumni },
    ]);

    useEffect(() => {
        if (franchiseId) {
            props.getMessageQueues(undefined, franchiseId)
            props.getFranchiseTeachers()
            autoUpdate()
        }
    }, [franchiseId])
    useEffect(() => {
        if (schoolId) {
            props.getMessageQueues(schoolId, undefined)
            props.getTeachersForSchool(schoolId);
            props.getClassroomsForSchoolId(schoolId);
            autoUpdate()
        }
    }, [schoolId]);

    const autoUpdate = () => {
        if (props.messageQueueTimer == null) {
            let timerId = setInterval(() => {
                console.log("autoUpdate called")
                if (franchiseId) {
                    props.getMessageQueues(undefined, franchiseId)
                } else if (schoolId) {
                    props.getMessageQueues(schoolId, undefined)
                }
            }, 1 * 60 * 1000)
            props.setMessageQueueTimer(timerId)
        }
    }
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }

    }, [props.selectedSchool, schoolId]);

    const getTeacherNameForId = (teacherId) => {
        if (props.franchiseTeachers && props.franchiseTeachers.length > 0) {
            let filter = props.franchiseTeachers.filter((teacher) =>
                teacher.id === teacherId
            )
            if (filter.length > 0) {
                return (filter[0].attributes.Name)
            }
        }
        return
    }

    const getBasedOnStatus = (status, msg) => {
        switch (status) {
            case 0:
                return {
                    label: "Pending",
                    labelColor: "text-warning",
                    date: msg.attributes.messageTriggerTime
                }
            case 1:
                return {
                    label: "Sent",
                    labelColor: "text-success",
                    date: msg.attributes.triggeredTime
                }
            case 2:
                return {
                    label: "Cancelled",
                    labelColor: "text-danger",
                    date: msg.updatedAt
                }
        }
    }

    useEffect(() => {
        if (props.messageQueues && props.messageQueues.length > 0) {

            let albumIds = []
            for (const message of props.messageQueues) {
                if (message.attributes.albumId && !albumIds.includes(message.attributes.albumId)) {
                    albumIds.push(message.attributes.albumId)
                }
                if (message.attributes.videoAlbumId && !albumIds.includes(message.attributes.videoAlbumId)) {
                    albumIds.push(message.attributes.videoAlbumId)
                }

            }
            if (albumIds.length > 0) {
                props.getAlbumsForAlbumIds(albumIds)
            }

            let tempList = []
            for (const msg of props.messageQueues) {
                let getStatus = getBasedOnStatus(msg.attributes.triggerStatus ?? 0, msg)
                let tempObj = {
                    message: msg.attributes.message ?? "",
                    audience: msg.attributes.audience ?? "",
                    id: msg.id,
                    recipientType: getRecipientTypeLabel(msg.attributes.recipientType),
                    senderId: msg.attributes.sender,
                    createdAt: msg.createdAt,
                    statusLabel: getStatus.label,
                    statusDate: getStatus.date,
                    parseObject: msg,
                    statusLabelColor: getStatus.labelColor,
                    albumId: msg.attributes.albumId && msg.attributes.albumId.length > 0 ? msg.attributes.albumId : undefined,
                    videoAlbumId: msg.attributes.videoAlbumId && msg.attributes.videoAlbumId.length > 0 ? msg.attributes.videoAlbumId : undefined,
                    attachment: msg.attributes.attachment,
                }
                tempList.push(tempObj)
            }
            setMainList(tempList)
        }
    }, [props.messageQueues])

    const getRecipientTypeLabel = (value) => {

        let filter = sendToList.filter((obj) =>
            obj.value === value
        )
        if (filter.length > 0) {
            return filter[0].label
        }
        return ""
    }

    const getAlbumsForAlbumId = (albumId) => {
        if (props.albums && props.albums.length > 0) {
            let albums = props.albums.filter((album) =>
                album.attributes.albumId === albumId
            )
            if (albums.length > 0) {
                return albums
            }
        }
        return undefined
    }

    const toggleView = () => {
        setShowAddNewMessage(!showAddNewMessage)
    }

    const getStringFromDate = (date, format = "DD/MM/YYYY") => {
        let time = moment(date).format(format);
        return time;
    };

    const cancelMessageTrigger = (msg) => {
        msg.parseObject.set("triggerStatus", 2)
        msg.parseObject.save().then(
            (result) => {
                props.updateMessageQueues([result])
            },
            (error) => {

            }
        );
    }

    const deleteMessage = (msg) => {
        msg.parseObject.set("isDeleted", true)
        msg.parseObject.save().then(
            (result) => {
                props.updateMessageQueues([result])
            },
            (error) => {

            }
        );
    }

    const checkForAttachmentType = (msg, performFunction = false) => {
        var returnValue = false

        if (msg.albumId && msg.albumId.length > 0) {
            returnValue = true
            if (performFunction) {
                setIsShowGallery(msg.albumId)
                setphotoIndex(0)
            }
        } else if (msg.videoAlbumId && msg.videoAlbumId.length > 0) {
            returnValue = true
            if (performFunction) {
                setShowVideoModel(msg)
            }

        } else if (msg.attachment) {
            returnValue = true
            if (performFunction) {
                setClickedFile(msg.attachment)
            }

        }

        return returnValue
    }

    const getVideoUI = (message) => {
        let videoAlbum
        if (message.videoAlbumId && message.videoAlbumId.length > 0) {
            videoAlbum = getAlbumsForAlbumId(message.videoAlbumId)
        }
        if (videoAlbum && videoAlbum.length > 0) {
            if (videoAlbum[0].attributes.video) {
                return (
                    <div className="embed-responsive embed-responsive-16by9" style={{ minWidth: "120mm", maxWidth: "120mm", minHeight: "70mm", maxHeight: "70mm" }}>
                        <Iframe allowFullScreen={true} title="test" className="embed-responsive-item" src={videoAlbum[0].attributes.video._url}></Iframe>
                    </div>
                )
            }
        }
        return
    }

    const getMessageListUI = () => {
        return (<Card>
            <CardBody>
                {mainList.length > 0 && (
                    <div className="table-responsive">
                        <table className="table table-centered  mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th style={{ textAlign: "center", width: "40px" }}>{props.t("#")}</th>
                                    <th>{props.t("Message")}</th>
                                    <th>{props.t("Recipient")}</th>
                                    <th>{props.t("Recipient Type")}</th>
                                    <th>{props.t("Sent By")}</th>
                                    <th>{props.t("Status")}</th>
                                    <th style={{ textAlign: "center" }}>{props.t("Attachment")}</th>
                                    <th>{props.t("Action")}</th>
                                </tr>
                            </thead>
                            <tbody>
                                {mainList.map((object, key) => (
                                    <tr key={key}>
                                        <td style={{ textAlign: "center", width: "40px" }}>
                                            <Label>{key + 1}</Label>
                                        </td>
                                        <td style={{ width: "30%" }}>
                                            <Label>{object.message}</Label>
                                        </td>
                                        <td >
                                            <Label>{object.audience}</Label>
                                        </td>
                                        <td >
                                            <Label>{object.recipientType}</Label>
                                        </td>
                                        <td >
                                            <>
                                                <h6>{getTeacherNameForId(object.senderId) ?? ""}</h6>
                                                <p className="text-muted mb-0">{getStringFromDate(object.createdAt)}</p>
                                                <p className="text-muted mb-0">{getStringFromDate(object.createdAt, "h:mm a")}</p>
                                            </>
                                        </td>
                                        <td >
                                            <h6 className={object.statusLabelColor}>{object.statusLabel}</h6>
                                            <p className="text-muted mb-0">{getStringFromDate(object.statusDate)}</p>
                                            <p className="text-muted mb-0">{getStringFromDate(object.statusDate, "h:mm a")}</p>
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            {checkForAttachmentType(object) && <><i id={"msgAttachment" + key} className="align-middle fa fa-paperclip font-size-24"
                                                style={{ cursor: 'pointer' }}
                                                onClick={() => {
                                                    checkForAttachmentType(object, true)
                                                }}></i>
                                                <UncontrolledTooltip placement="top" target={"msgAttachment" + key}>
                                                    {props.t("Attachments")}
                                                </UncontrolledTooltip></>}
                                        </td>
                                        <td >
                                            <div className="btn-group mb-2">
                                                <ButtonDropdown
                                                    isOpen={selectedIdActionMenuOpen === object.id}
                                                    toggle={() => {
                                                        if (selectedIdActionMenuOpen && selectedIdActionMenuOpen.length > 0) {
                                                            setSelectedIdActionMenuOpen()
                                                        } else {
                                                            setSelectedIdActionMenuOpen(object.id)
                                                        }
                                                    }
                                                    }
                                                >
                                                    <DropdownToggle
                                                        caret
                                                        color="primary"
                                                        className="btn btn-primary btn-sm"
                                                    >
                                                        Action &nbsp;{" "}
                                                        <i className="mdi mdi-chevron-down"></i>
                                                    </DropdownToggle>
                                                    <DropdownMenu>
                                                        <DropdownItem onClick={() => {
                                                            cancelMessageTrigger(object)
                                                        }}>Cancel</DropdownItem>
                                                        {object.parseObject.attributes.triggerStatus == 0 && <DropdownItem onClick={() => {
                                                            toggleView()
                                                            setShowEditMessage(true)
                                                            setSelectedMessageForEditing(object)
                                                        }} >Edit</DropdownItem>}
                                                        <DropdownItem onClick={() => {
                                                            if (object.recipientType == "@All Enquiries" || object.recipientType == "@All Admissions" || object.recipientType == "@All Alumni") {
                                                                setShowEnquiryDeleteAlert(true)
                                                            } else if (object.statusDate) {
                                                                const twentyFourHoursInMilliseconds = 24 * 60 * 60 * 1000; // 24 hours in milliseconds
                                                                const currentTime = new Date().getTime();
                                                                const statusDateTime = new Date(object.statusDate).getTime();
                                                                const timeDifference = currentTime - statusDateTime;

                                                                if (timeDifference > twentyFourHoursInMilliseconds) {
                                                                    setShowOneDayDeleteAlert(true);
                                                                } else {
                                                                    setDeleteObject(object)
                                                                    setShowDeleteAlert(true);
                                                                }
                                                            } else {
                                                                setDeleteObject(object)
                                                                setShowDeleteAlert(true);
                                                            }
                                                        }} className='text-danger'>Delete</DropdownItem>
                                                    </DropdownMenu>
                                                </ButtonDropdown>
                                            </div>{" "}
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                {mainList.length === 0 && (
                    <p className="text-center">No Messages to display !!</p>
                )}
            </CardBody>
        </Card >)
    }

    const getLightBoxImagesUI = (albumId) => {
        var albums = getAlbumsForAlbumId(albumId)
        var images = []
        if (albums && albums.length > 0) {
            images = []
            for (const album of albums) {
                let image = album.attributes.image ? album.attributes.image._url : ""
                images.push(image)
            }
        }
        return (<Lightbox
            mainSrc={images[photoIndex]}
            nextSrc={images[(photoIndex + 1) % images.length]}
            prevSrc={
                images[(photoIndex + images.length - 1) % images.length]
            }
            enableZoom={true}
            onCloseRequest={() => { setIsShowGallery(false) }}
            onMovePrevRequest={() => { setphotoIndex((photoIndex + images.length - 1) % images.length) }}
            onMoveNextRequest={() => { setphotoIndex((photoIndex + 1) % images.length) }}
            imageCaption={"Image " + parseFloat(photoIndex + 1)}
        />)
    }

    return (
        <React.Fragment>
            <div className="page-content">
                {showEnquiryDeleteAlert && (
                    <SweetAlert
                        warning
                        confirmBtnText="Close"
                        title="Alert!"
                        onConfirm={() => setShowEnquiryDeleteAlert(false)}
                    >
                        <div>
                            {" "}
                            The message cannot be deleted as it has already been sent to the recipient via email.
                        </div>
                    </SweetAlert>
                )}
                {showOneDayDeleteAlert && (
                    <SweetAlert
                        warning
                        confirmBtnText="Close"
                        title="Alert!"
                        onConfirm={() => setShowOneDayDeleteAlert(false)}
                    >
                        <div>
                            {" "}
                            The message cannot be deleted as it has already been sent over 24 hours ago.                        </div>
                    </SweetAlert>
                )}
                {showDeleteAlert && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Delete"
                        cancelBtnText="Cancel"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        title="Alert!"
                        onCancel={() => {
                            setShowDeleteAlert(false);
                        }}
                        onConfirm={() => {
                            deleteMessage(deleteObject);
                            setShowDeleteAlert(false);
                        }}
                    >
                        {"Are you sure you want to delete"}
                        <br></br>
                    </SweetAlert>
                )}

                {!isLoading && (
                    <Container fluid>

                        {isShowGallery && isShowGallery.length > 0 ? (
                            getLightBoxImagesUI(isShowGallery)
                        ) : null}
                        <DocumentViewerModal
                            clickedFile={clickedFile}
                            closeModal={() => {
                                setClickedFile()
                            }}
                        ></DocumentViewerModal>
                        <Modal isOpen={showVideoModel}>
                            <ModalHeader toggle={() => {
                                setShowVideoModel()
                            }}></ModalHeader>
                            {showVideoModel && <ModalBody>
                                {getVideoUI(showVideoModel)}
                            </ModalBody>}
                        </Modal>

                        {showAddNewMessage ? <MessageQueueAddMessage
                            showAddNewMessage={showAddNewMessage}
                            toggleView={() => {
                                toggleView()
                            }}
                            showEditMessage={showEditMessage}
                            selectedMessageForEditing={selectedMessageForEditing}
                            franchiseId={franchiseId}
                            schoolId={schoolId}
                        >
                        </MessageQueueAddMessage> : <><Row>
                            <Col lg={8} md={8} xs={12}>
                                <h4>{"Messaging"}</h4>
                            </Col>

                            <Col lg={4} md={4} xs={12}>
                                <div className="mb-3  d-flex justify-content-end form-inline">
                                    <button className='btn btn-primary' onClick={() => {
                                        toggleView()
                                        setShowEditMessage(false)
                                    }}>{"Add New Message"}</button>
                                </div>
                            </Col>
                        </Row>
                            {getMessageListUI()}
                        </>}
                    </Container>
                )}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Messages...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>

        </React.Fragment>
    );
}

const mapStatetoProps = state => {

    const { messageQueues, noMessagesToShow, isLoadingMessages, albums, messageQueueTimer } = state.Message;
    const { franchiseTeachers, teachers } = state.Teacher;
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    return {
        messageQueues, noMessagesToShow, isLoadingMessages, albums, messageQueueTimer,
        franchiseTeachers, teachers,
        selectedSchool,
        classrooms,
    }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    getMessageQueues,
    getFranchiseTeachers,
    updateMessageQueues,
    getAlbumsForAlbumIds,
    setSelecetdSchool,
    getTeachersForSchool,
    getClassroomsForSchoolId,
    setMessageQueueTimer,
})(MessageQueueList)));

class SentMessage {
    message = ""
    recipient = "";
    recipientType = "";
    sentBy = "";
    sentDate = null;
    status = "";
    actualSentDate = null;
    images = [];
    document = null;
    videoFile = null;

    constructor(message, recipient, recipientType, sentBy, sentDate, status, actualSentDate,
        images, document, videoFile) {
        this.message = message;
        this.recipient = recipient;
        this.recipientType = recipientType;
        this.sentBy = sentBy;
        this.sentDate = sentDate;
        this.status = status;
        this.actualSentDate = actualSentDate;
        this.images = images;
        this.document = document;
        this.videoFile = videoFile;
    }
}