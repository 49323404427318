import * as actions from "./actionTypes";

export const getExamResultsForExamId = (examId) => {
  return {
    type: actions.GET_EXAM_RESULTS_FOR_EXAM_ID,
    payload: { examId }
  }
}

export const getExamResultsForExamIdAndKidId = (examId, kidId) => {
  return {
    type: actions.GET_EXAM_RESULTS_FOR_EXAM_ID_AND_KID_ID,
    payload: { examId, kidId }
  }
}

export const updateExamResults = (examResults,isUpdate) => {
  return {
    type: actions.UPDATE_EXAM_RESULTS,
    payload: { examResults,isUpdate },
  };
};