import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  CardTitle,
  Container,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Progress,
} from "reactstrap";

import toastr from "toastr";

import { Element, animateScroll as scroll, scroller } from "react-scroll";
import classnames from "classnames";
import UpdatePhoto from "../../components/Common/UpdatePhoto";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import { Parse } from "parse";

import * as Constants from "../../Constents";

const EditCustomForm = (props) => {
  const next = "NEXT";
  const previous = "PREVIOUS";

  const match = matchPath(props.history.location.pathname, {
    path: "/editcustomform/:schoolId/:formId",
    exact: true,
    strict: false,
  });

  const matchForIframe = matchPath(props.history.location.pathname, {
    path: "/editforminiframe/:schoolId/:formId",
    exact: true,
    strict: false,
  });


  const matchForStudent = matchPath(props.history.location.pathname, {
    path: "/editcustomformstudent/:schoolId/:formId/:kidId",
    exact: true,
    strict: false,
  });

  const matchForCourse = matchPath(props.history.location.pathname, {
    path: "/editcoursefeedbackform/:schoolId/:formId/:courseId/:kidId",
    exact: true,
    strict: false,
  })


  const matchForBackwardCompatibility = matchPath(props.history.location.pathname, {
    path: "/editcustomform/:schoolId/:formName/:formId",
    exact: true,
    strict: false,
  });

  const matchInsideDashboard = matchPath(props.history.location.pathname, {
    path: "/editcustomforminside/:schoolId/:formId",
    exact: true,
    strict: false,
  });

  const publicMatch = matchPath(props.history.location.pathname, {
    path: "/fill-form/:type/:ownerId/:formId",
    exact: true,
    strict: false,
  });

  var franchiseId = undefined;
  var schoolId = match && match.params.schoolId;
  var formId = match && match.params.formId;
  var kidId = matchForStudent && matchForStudent.params.kidId;
  var courseId = matchForCourse && matchForCourse.params.courseId;


  if (match) {
    schoolId = match && match.params.schoolId;
    formId = match && match.params.formId;
  } else if (matchInsideDashboard) {
    schoolId = matchInsideDashboard && matchInsideDashboard.params.schoolId;
    formId = matchInsideDashboard && matchInsideDashboard.params.formId;
  } else if (matchForIframe) {

    schoolId = matchForIframe && matchForIframe.params.schoolId;
    formId = matchForIframe && matchForIframe.params.formId;


  } else if (matchForBackwardCompatibility) {
    schoolId = matchForBackwardCompatibility.params.schoolId
    formId = matchForBackwardCompatibility.params.formId
  } else if (matchForStudent) {
    schoolId = matchForStudent.params.schoolId
    formId = matchForStudent.params.formId
  } else if (matchForCourse) {
    schoolId = matchForCourse.params.schoolId
    formId = matchForCourse.params.formId
    kidId = matchForCourse.params.kidId
  } else if (publicMatch) {

    let type = publicMatch.params.type
    if (type == "s") {
      schoolId = publicMatch.params.ownerId
    }
    if (type == "f") {
      franchiseId = publicMatch.params.ownerId
    }

    console.log(franchiseId)


    formId = publicMatch.params.formId
  }

  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }
  const [formName, setFormName] = useState("")
  const [stateSchoolObject, setStateSchoolObject] = useState(undefined);
  const [formSectionList, setFormSectionList] = useState([]);
  var fillingFor = undefined;

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isQuestionsAvailable, setIsQuestionsAvailable] = useState(true);

  const [cardMessage, setCardMessage] = useState("");
  const [cardIcon, setCardIcon] = useState("fa fa-check text-success mr-2");

  const [isLoading, setIsLoading] = useState(false);
  const [formIsSubmites, formIsSubmitesOps] = useState(false);
  const [kidObject, setKidObject] = useState(undefined);
  const [formIsDeleted, setFormIsDeleted] = useState(false);

  var childAdditionalDetailsObject = null;
  var fatherObject = null;
  var motherObject = null;
  var enquiryObject = null;

  var abortSubmitForm = false;

  const [activeTab, setactiveTab] = useState(0);
  const [progressValue, setprogressValue] = useState(0);

  useEffect(() => {
    if ((matchForStudent || matchForCourse) && kidId && kidId.length > 0) {
      getKid()
    } else {
      fetchFormSections(formId);
      fetchFormFromServer();
    }
  }, []);


  useEffect(() => {
    Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
      schoolId: schoolId,
    }).then(
      (result) => {
        if (result != null) {
          setStateSchoolObject(result);
        }
      },
      (error) => {
        console.log(error);
      }
    );

  }, [schoolId])

  const getKid = () => {
    Parse.Cloud.run("function_kidDetailsForKidId", {
      kidId: kidId,
    }).then(
      (result) => {
        if (result != null) {
          if (result.kid) {
            setKidObject(result.kid)
            fetchFormSections(formId, result.kid);
            fetchFormFromServer();
          }
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }

  const fetchFormFromServer = () => {
    var query = new Parse.Query("Forms");
    query.equalTo("objectId", formId);
    query.first().then((result) => {
      if (result != null) {
        if (result.attributes.isDeleted) {
          setFormIsDeleted(true)
        } else {
          setFormIsDeleted(false)
        }
        if (result.attributes.name) {
          setFormName(result.attributes.name)
        }
      }
    });
  }

  const fetchFormSections = (formId, kidObject) => {
    var query = new Parse.Query("FormSections");
    query.equalTo("formId", formId);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      if (results != null) {
        if (results.length > 0) {
          var formSectionParseList = results;
          var sectionIds = [];
          var i = 0;
          for (i = 0; i < formSectionParseList.length; i++) {
            sectionIds.push(formSectionParseList[i].id);
          }
          fetchFormQuestionsForFormIds(formSectionParseList, sectionIds, kidObject);
        } else {
          setIsQuestionsAvailable(false);
        }
      }
    });
  };

  const fetchFormQuestionsForFormIds = (formSectionParseList, sectionIds, kidObject) => {
    var query = new Parse.Query("FormQuestions");
    query.containedIn("sectionId", sectionIds);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      var tempSectionList = [];

      if (results != null) {
        var i = 0;

        for (i = 0; i < formSectionParseList.length; i++) {
          var j = 0;
          var tempQuestionList = [];

          var customFormSectionObject = new CustomFormSectionObject();
          customFormSectionObject.title =
            formSectionParseList[i].attributes.sectionName ?? "";
          customFormSectionObject.formSectionParseObject =
            formSectionParseList[i];

          for (j = 0; j < results.length; j++) {
            if (
              formSectionParseList[i].id === results[j].attributes.sectionId
            ) {
              var enqObject = results[j];

              var newEnquiryObject = new CustomFormObject();
              newEnquiryObject.question = enqObject.attributes.question ?? "";
              newEnquiryObject.questionType =
                enqObject.attributes.questionType ?? "";
              newEnquiryObject.questionNumber = i + 1;
              if (enqObject.attributes.dataType) {
                newEnquiryObject.dataType = enqObject.attributes.dataType;
              }
              if (enqObject.attributes.choices) {
                newEnquiryObject.choices = [...enqObject.attributes.choices];
              }
              if (
                enqObject.attributes.isMandatory &&
                enqObject.attributes.isMandatory === true
              ) {
                newEnquiryObject.isMandatory = true;
              } else {
                newEnquiryObject.isMandatory = false;
              }
              if (enqObject.attributes.isDefaultQuestion && enqObject.attributes.isDefaultQuestion === true) {
                newEnquiryObject.isDefaultQuestion = true;
              } else {
                newEnquiryObject.isDefaultQuestion = false;
              }


              newEnquiryObject.dataType = enqObject.attributes.dataType;
              if (
                enqObject.attributes.questionHint != null &&
                enqObject.attributes.questionHint !== ""
              ) {
                newEnquiryObject.questionHint =
                  enqObject.attributes.questionHint;
              } else {
                newEnquiryObject.questionHint = "Type here";
              }
              newEnquiryObject.answerChoices = [];

              if (
                enqObject.attributes.questionType &&
                (enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_COMMENT_ON_NO ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_COMMENT_ON_YES ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_NO_COMMENTS ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_WITH_COMMENT)
              ) {
                if (
                  enqObject.attributes.choice == null ||
                  !enqObject.attributes.choice.length > 0
                ) {
                  newEnquiryObject.choices = ["Yes", "No"];
                }
              }
              newEnquiryObject.answerError = false;

              newEnquiryObject.formQuestionParseObject = enqObject;

              tempQuestionList.push(fillInitialValues(newEnquiryObject, kidObject));
            }
          }


          customFormSectionObject.formQuestionsList = tempQuestionList;

          tempSectionList.push(customFormSectionObject);
        }
        setprogressValue(100 / tempSectionList.length);
        setFormSectionList(tempSectionList);
      }
    });
  };

  const checkForObjectTypeAndReturnAnswer = (questionObject) => {
    var databaseFieldName =
      questionObject.formQuestionParseObject.attributes.databaseFieldName;

    var databaseTableName =
      questionObject.formQuestionParseObject.attributes.databaseTableName;

    if (databaseTableName === "Enquiry") {
      if (
        enquiryObject &&
        enquiryObject.attributes[databaseFieldName] != null
      ) {
        return enquiryObject.attributes[databaseFieldName];
      }
    } else if (databaseTableName === "ChildAdditionalDetails") {
      if (
        childAdditionalDetailsObject &&
        childAdditionalDetailsObject.attributes[databaseFieldName] != null
      ) {
        return childAdditionalDetailsObject.attributes[databaseFieldName];
      }
    } else if (
      databaseTableName === "Parent" &&
      questionObject.formQuestionParseObject.attributes.objectType != null &&
      questionObject.formQuestionParseObject.attributes.objectType === 1
    ) {
      if (motherObject && motherObject.attributes[databaseFieldName] != null) {
        return motherObject.attributes[databaseFieldName];
      }
    } else if (databaseTableName === "Parent") {
      if (
        questionObject.formQuestionParseObject.attributes.objectType != null &&
        questionObject.formQuestionParseObject.attributes.objectType === 2
      ) {
        if (
          fatherObject &&
          fatherObject.attributes[databaseFieldName] != null
        ) {
          return fatherObject.attributes[databaseFieldName];
        }
      } else {
        if (
          fatherObject &&
          fatherObject.attributes[databaseFieldName] != null
        ) {
          return fatherObject.attributes[databaseFieldName];
        }
      }
    }

    return false;
  };

  const fillInitialValues = (formQuestionObject, kidObject) => {
    var objectToReturn = formQuestionObject;

    if (
      objectToReturn.questionType === Constants.QESTION_TEXT_SINGLE_LINE ||
      objectToReturn.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
      objectToReturn.questionType === Constants.QUESTION_EMAIL ||
      objectToReturn.questionType === Constants.QUESTION_PHONE_NUMBER
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerText = result;
      }
      if (objectToReturn.isDefaultQuestion === true && (matchForStudent || matchForCourse) && kidObject) {
        objectToReturn.answerText = kidObject.attributes.Name ?? "";
      }

    } else if (objectToReturn.questionType === Constants.QESTION_NUMBER) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerText = result;
      }
    } else if (objectToReturn.questionType === Constants.QESTION_CHECKBOX) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerBool = result;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerBool = result;
      }
    } else if (
      objectToReturn.questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        if (Array.isArray(result) && result.length > 1) {
          objectToReturn.answerChoices = [result[0]];
        } else {
          if (Array.isArray(result)) {
            objectToReturn.answerChoices = [...result];
          } else {
            objectToReturn.answerChoices = [result];
          }
        }
      }
    } else if (
      objectToReturn.questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerChoices = [...result];
      }
    } else if (objectToReturn.questionType === Constants.QESTION_DATE) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerDate = result;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerBool = true;
        objectToReturn.answerText = result;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerBool = false;
        objectToReturn.answerText = result;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (objectToReturn.questionType === Constants.QESTION_UPLOAD_IMAGE) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        let name = result._name.split(/_(.+)/)[1];
        let fileExtension = name.split(".");
        let tempFile = {
          actualImageName: name,
          fileExtension: fileExtension[1].toLowerCase(),
          imageName: fileExtension[0],
          image: result,
          imageURL: result._url,
        };
        objectToReturn.answerImageUploadProgress = 100;
        objectToReturn.answerImage = tempFile;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        let name = result._name.split(/_(.+)/)[1];
        let fileExtension = name.split(".");

        let tempFile = {
          actualFileName: name,
          fileExtension: fileExtension[1].toLowerCase(),
          fileName: fileExtension[0],
          file: result,
        };

        objectToReturn.answerFileUploadProgress = 100;
        objectToReturn.answerFile = tempFile;
      }
    }

    return objectToReturn;
  };

  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  //on Doc Upload
  function handleFileUploadChange(
    e,
    index,
    formQuestionObject,
    formSectionObject
  ) {
    let questionIndex = formSectionObject.formQuestionsList.indexOf(
      formQuestionObject
    );

    let titleIndex = formSectionList.indexOf(formSectionObject);

    let tempQuestionObject = formQuestionObject;

    let tempTitleObject = formSectionList[titleIndex];
    tempQuestionObject.answerFile = undefined;
    tempQuestionObject.answerFileUploadProgress = 1;

    let tempQuestionList = [...tempTitleObject.formQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.formQuestionsList = [...tempQuestionList];

    let tempTitleList = [...formSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setFormSectionList(tempTitleList);

    let fileExtension = e.target.files[0].name.split(".");
    let tempFile = {
      actualFileName: e.target.files[0].name,
      fileExtension: fileExtension[1].toLowerCase(),
      fileName: fileExtension[0],
      file: e.target.files[0],
    };

    var name =
      tempFile.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "") +
      tempFile.fileExtension;

    getBase64(tempFile.file, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result });
      //setUploadProgress(1);
      parseFile
        .save({
          progress: (value) => {
            if (value && value > 0.1) {
              //setUploadProgress(value * 100);
              //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

              let tempQuestionObject = formQuestionObject;

              let tempTitleObject = formSectionObject;

              tempQuestionObject.answerFileUploadProgress = (value * 100);

              let tempQuestionList = [...tempTitleObject.formQuestionsList];

              tempQuestionList[questionIndex] = tempQuestionObject;

              tempTitleObject.formQuestionsList = [...tempQuestionList];

              let tempTitleList = [...formSectionList];

              tempTitleList[titleIndex] = tempTitleObject;

              setFormSectionList(tempTitleList);
            }
          },
        })
        .then(
          (result) => {
            tempFile.file = result;

            let tempQuestionObject = formQuestionObject;

            let tempTitleObject = formSectionObject;

            tempQuestionObject.answerFile = tempFile;
            tempQuestionObject.answerError = false;

            let tempQuestionList = [...tempTitleObject.formQuestionsList];

            tempQuestionList[questionIndex] = tempQuestionObject;

            tempTitleObject.formQuestionsList = [...tempQuestionList];

            let tempTitleList = [...formSectionList];

            tempTitleList[titleIndex] = tempTitleObject;

            setFormSectionList(tempTitleList);

          },
          (error) => {
            //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
          }
        );
    });

    let tempQuestionObject1 = formQuestionObject;

    let tempTitleObject1 = formSectionObject;

    tempQuestionObject1.answerFile = {
      actualFileName: tempFile.actualFileName,
    };

    let tempQuestionList1 = [...tempTitleObject1.formQuestionsList];

    tempQuestionList1[questionIndex] = tempQuestionObject1;

    tempTitleObject.formQuestionsList = [...tempQuestionList1];

    let tempTitleList1 = [...formSectionList];

    tempTitleList1[titleIndex] = tempTitleObject1;

    setFormSectionList(tempTitleList1);

    // enqObject.answerFile = { actualFileName: tempFile.actualFileName };
    // enquiryQuestionsList[index] = enqObject;

    // var temp = [...enquiryQuestionsList];
    // temp[index] = enqObject;
    // setEnquiryQuestionsList(temp);
  }

  // On image upload
  function handleImageUploadChange(
    index,
    changedImageURL,
    imageFile,
    formQuestionObject,
    formSectionObject
  ) {
    let questionIndex = formSectionObject.formQuestionsList.indexOf(
      formQuestionObject
    );

    let titleIndex = formSectionList.indexOf(formSectionObject);

    let tempQuestionObject = formQuestionObject;

    let tempTitleObject = formSectionList[titleIndex];
    tempQuestionObject.answerImage = undefined;
    tempQuestionObject.answerImageUploadProgress = 1;

    let tempQuestionList = [...tempTitleObject.formQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.formQuestionsList = [...tempQuestionList];

    let tempTitleList = [...formSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setFormSectionList(tempTitleList);

    let fileExtension = imageFile._name.split(".");
    let tempFile = {
      actualImageName: imageFile._name,
      fileExtension: fileExtension[1].toLowerCase(),
      imageName: fileExtension[0],
      image: imageFile,
      imageURL: undefined,
    };

    //var name = tempFile.imageName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

    tempFile.image
      .save({
        progress: (value) => {
          if (value && value > 0.1) {
            //setUploadProgress(value * 100);
            //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

            let tempQuestionObject = formQuestionObject;

            let tempTitleObject = formSectionObject;

            tempQuestionObject.answerImageUploadProgress = value * 100;

            let tempQuestionList = [...tempTitleObject.formQuestionsList];

            tempQuestionList[questionIndex] = tempQuestionObject;

            tempTitleObject.formQuestionsList = [...tempQuestionList];

            let tempTitleList = [...formSectionList];

            tempTitleList[titleIndex] = tempTitleObject;

            setFormSectionList(tempTitleList);
          }
        },
      })
      .then(
        (result) => {
          tempFile.imageURL = result._url;
          tempFile.image = result;

          let tempQuestionObject = formQuestionObject;

          let tempTitleObject = formSectionObject;

          tempQuestionObject.answerImage = tempFile;
          tempQuestionObject.answerError = false;

          let tempQuestionList = [...tempTitleObject.formQuestionsList];

          tempQuestionList[questionIndex] = tempQuestionObject;

          tempTitleObject.formQuestionsList = [...tempQuestionList];

          let tempTitleList = [...formSectionList];

          tempTitleList[titleIndex] = tempTitleObject;

          setFormSectionList(tempTitleList);
        },
        (error) => {
          //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
        }
      );

    // enqObject.answerImage = { actualImageName: tempFile.actualImageName };
    // enquiryQuestionsList[index] = enqObject;

    // var temp = [...enquiryQuestionsList];
    // temp[index] = enqObject;
    // setEnquiryQuestionsList(temp);

    let tempQuestionObject1 = formQuestionObject;

    let tempTitleObject1 = formSectionObject;

    tempQuestionObject1.answerImage = {
      actualImageName: tempFile.actualImageName,
    };
    tempQuestionObject1.answerImage.imageURL = changedImageURL;

    let tempQuestionList1 = [...tempTitleObject1.formQuestionsList];

    tempQuestionList1[questionIndex] = tempQuestionObject1;

    tempTitleObject.formQuestionsList = [...tempQuestionList1];

    let tempTitleList1 = [...formSectionList];

    tempTitleList1[titleIndex] = tempTitleObject1;

    setFormSectionList(tempTitleList1);
  }

  const componentForQuestionType = (
    formQuestionObject,
    formSectionObject,
    key
  ) => {
    var questionType = formQuestionObject.questionType;

    switch (questionType) {
      case Constants.QESTION_UPLOAD_DOCUMENT:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formFileUpload" style={{ "white-space": "pre-wrap" }}>
              {formQuestionObject.question}{" "}
            </Label>
            {formQuestionObject.isMandatory && (
              <Label htmlFor="formFileUpload" className="text-danger">
                *
              </Label>
            )}
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}

            {formQuestionObject.answerFile == undefined && (
              <div className="d-flex">
                <Label
                  htmlFor={"input-file-custom-form" + key}
                  color="primary"
                  className="btn btn-primary"
                >
                  Upload
                  <input
                    id={"input-file-custom-form" + key}
                    style={inputStyle}
                    type="file"
                    onChange={(e) => {
                      handleFileUploadChange(
                        e,
                        key,
                        formQuestionObject,
                        formSectionObject
                      );
                    }}
                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                  />
                </Label>
              </div>
            )}

            {formQuestionObject.answerFileUploadProgress === 100 && (
              <Col xl="6" md="12" sm="12" xs="12">
                <div className="border p-3">
                  <Row>
                    <div
                      className="avatar ml-3"
                      style={{ width: "5rem", height: "5rem" }}
                    >
                      <span
                        className="avatar-title bg-white ml-0 text-primary h1"
                        style={{ fontSize: "60px" }}
                      >
                        <i
                          className={getFileTypeImage(
                            formQuestionObject.answerFile.fileExtension
                          )}
                        ></i>
                      </span>
                    </div>

                    <div
                      className="float-right ml-4"
                      style={{ width: "200px" }}
                    >
                      <div className="text-muted font-weight-bold">
                        {formQuestionObject.answerFile.actualFileName}
                      </div>
                      <div className="d-flex mt-3">
                        <div>
                          <Label
                            htmlFor={"input-file-enquiry-Update" + key}
                            color="primary"
                            className="btn btn-primary mb-0"
                          >
                            Update
                            <input
                              id={"input-file-enquiry-Update" + key}
                              style={inputStyle}
                              type="file"
                              onChange={(e) => {
                                handleFileUploadChange(
                                  e,
                                  key,
                                  formQuestionObject,
                                  formSectionObject
                                );
                              }}
                              accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                            />
                          </Label>
                        </div>

                        <Button
                          className="btn-danger float-right ml-2"
                          onClick={() => {
                            var questionIndex = formSectionObject.formQuestionsList.indexOf(
                              formQuestionObject
                            );

                            var titleIndex = formSectionList.indexOf(
                              formSectionObject
                            );

                            var tempQuestionObject = formQuestionObject;

                            var tempTitleObject = formSectionList[titleIndex];

                            tempQuestionObject.answerFile = undefined;
                            tempQuestionObject.answerFileUploadProgress = 0;

                            var tempQuestionList = [
                              ...tempTitleObject.formQuestionsList,
                            ];

                            tempQuestionList[
                              questionIndex
                            ] = tempQuestionObject;

                            tempTitleObject.formQuestionsList = [
                              ...tempQuestionList,
                            ];

                            var tempTitleList = [...formSectionList];

                            tempTitleList[titleIndex] = tempTitleObject;

                            setFormSectionList(tempTitleList);
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            )}

            {formQuestionObject.answerFile &&
              formQuestionObject.answerFileUploadProgress >= 1 &&
              formQuestionObject.answerFileUploadProgress < 100 && (
                <div className="d-flex">
                  <Col xl="6" md="12" sm="12" xs="12" className="ml-0">
                    <div className="border p-3">
                      <Row>
                        <div
                          className="avatar ml-3"
                          style={{ width: "5rem", height: "5rem" }}
                        >
                          <span className="avatar-title bg-white ml-0 text-primary h1">
                            <i
                              className={getFileTypeImage(
                                formQuestionObject.answerFile.fileExtension
                              )}
                            ></i>
                          </span>
                        </div>
                        <div
                          className="float-right ml-4"
                          style={{ width: "200px" }}
                        >
                          <div className="text-muted font-weight-bold">
                            {formQuestionObject.answerFile.actualFileName}
                          </div>
                          <div className=" mt-3">

                            <Progress
                              color="primary"
                              value={
                                formQuestionObject.answerFileUploadProgress
                              }
                            ></Progress>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                </div>
              )}
          </FormGroup>
        );
        break;

      case Constants.QESTION_UPLOAD_IMAGE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formImageUpload" style={{ "white-space": "pre-wrap" }}
            >
              {formQuestionObject.question}{" "}
            </Label>
            {formQuestionObject.isMandatory && (
              <Label htmlFor="formImageUpload" className="text-danger">
                *
              </Label>
            )}
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            {formQuestionObject.answerImage &&
              formQuestionObject.answerImageUploadProgress === 100 && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div className="ml-3">
                        <img
                          style={{ width: "5rem", height: "5rem" }}
                          className="rounded-circle avatar"
                          src={formQuestionObject.answerImage.imageURL}
                          alt=""
                        />
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {formQuestionObject.answerImage.actualImageName}
                        </div>
                        <div className="d-flex mt-3">
                          <UpdatePhoto
                            id={"imageUpload" + key}
                            tempId={"imageUpload" + key}
                            className="p-1"
                            buttonTitle="Change"
                            isPopupOn={false}
                            onPicChange={(imageFile, url) => {
                              handleImageUploadChange(
                                key,
                                url,
                                imageFile,
                                formQuestionObject,
                                formSectionObject
                              );
                            }}
                            imageFileName={
                              formQuestionObject.answerImage
                                ? formQuestionObject.answerImage.actualImageName
                                : ""
                            }
                            initialImage={
                              formQuestionObject.answerImage.imageURL
                            }
                          ></UpdatePhoto>
                          <Button
                            className="btn-danger float-right ml-2"
                            onClick={() => {
                              var questionIndex = formSectionObject.formQuestionsList.indexOf(
                                formQuestionObject
                              );

                              var titleIndex = formSectionList.indexOf(
                                formSectionObject
                              );

                              var tempQuestionObject = formQuestionObject;

                              var tempTitleObject = formSectionList[titleIndex];

                              tempQuestionObject.answerImage = undefined;
                              tempQuestionObject.answerImageUploadProgress = 0;

                              var tempQuestionList = [
                                ...tempTitleObject.formQuestionsList,
                              ];

                              tempQuestionList[
                                questionIndex
                              ] = tempQuestionObject;

                              tempTitleObject.formQuestionsList = [
                                ...tempQuestionList,
                              ];

                              var tempTitleList = [...formSectionList];

                              tempTitleList[titleIndex] = tempTitleObject;

                              setFormSectionList(tempTitleList);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {formQuestionObject.answerImage &&
              formQuestionObject.answerImageUploadProgress >= 1 &&
              formQuestionObject.answerImageUploadProgress < 100 && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div
                        className="avatar ml-3"
                        style={{ width: "5rem", height: "5rem" }}
                      >
                        <span
                          className={
                            "avatar-title rounded-circle bg-soft-primary text-primary"
                          }
                          style={{ fontSize: "60px" }}
                        ></span>
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {formQuestionObject.answerImage.actualImageName}
                        </div>
                        <div className=" mt-3">
                          <Progress

                            color="primary"
                            value={formQuestionObject.answerImageUploadProgress}
                          ></Progress>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {formQuestionObject.answerImage == undefined && (
              <div className="d-flex">
                <div>
                  {" "}
                  <UpdatePhoto
                    id={"imageUploadMain" + key}
                    tempId={"imageUploadMain" + key}
                    className="p-1"
                    isPopupOn={false}
                    onPicChange={(imageFile, url) => {
                      handleImageUploadChange(
                        key,
                        url,
                        imageFile,
                        formQuestionObject,
                        formSectionObject
                      );
                    }}
                    initialImage={""}
                  ></UpdatePhoto>
                </div>
              </div>
            )}
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_SINGLE_LINE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formSingleLine" style={{ "white-space": "pre-wrap" }}>
              {formQuestionObject.question}{" "}
            </Label>
            {formQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}


            <AvField
              type="text"
              name={"singleLine" + key}
              placeholder={formQuestionObject.questionHint}
              className="form-control"
              value={formQuestionObject.answerText}
              disabled={(formQuestionObject.isDefaultQuestion === true && (matchForStudent || matchForCourse)) ? true : false}
              onChange={(e) => {
                handleInputOnChange(
                  formQuestionObject,
                  formSectionObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_MULTI_LINE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formMultiLine" style={{ "white-space": "pre-wrap" }}>{formQuestionObject.question}</Label>
            {formQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="textarea"
              name={"multiLine" + key}
              className="form-control"
              rows="3"
              value={formQuestionObject.answerText}
              placeholder={formQuestionObject.questionHint}
              onChange={(e) => {
                handleInputOnChange(
                  formQuestionObject,
                  formSectionObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QESTION_NUMBER:
        return (
          <FormGroup key={key}>
            <Label style={{ "white-space": "pre-wrap" }}>{formQuestionObject.question}</Label>
            {formQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="number"
              name={"numberInput" + key}
              className="inner form-control noscroll"
              placeholder={props.t(formQuestionObject.questionHint)}
              value={formQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(
                  formQuestionObject,
                  formSectionObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_CHECKBOX:
        return (
          <FormGroup key={key}>
            <Label style={{ "white-space": "pre-wrap" }}>
              {formQuestionObject.question}
              {formQuestionObject.isMandatory && (
                <Label htmlFor="formSingleLine" className="text-danger">
                  *
                </Label>
              )}
            </Label>
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <div>
              <label
                className=""
                onClick={() => {
                  handleCheckBoxChange(formQuestionObject, formSectionObject);
                }}
              >
                {formQuestionObject.choices &&
                  formQuestionObject.choices.length > 0
                  ? formQuestionObject.choices[0]
                  : ""}
              </label>
              <input
                type="checkbox"
                className="ml-2"
                id={"customFormCheckBox" + key}
                onChange={() =>
                  handleCheckBoxChange(formQuestionObject, formSectionObject)
                }
                checked={formQuestionObject.answerBool}
              />
            </div>
          </FormGroup>
        );
        break;

      case Constants.QESTION_YES_NO_NO_COMMENTS:
        return boolTagsWithoutComment(
          formQuestionObject,
          formSectionObject,
          formQuestionObject.choices,
          handleYesNoButtonClick,
          key
        );

        break;
      case Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST:
        return radioButtonForSingleSelection(
          formQuestionObject,
          formSectionObject,
          formQuestionObject.choices,
          handleChoiceButtonClick,
          key
        );
        break;

      case Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST:
        return checkboxButtonForMultiSelection(
          formQuestionObject,
          formSectionObject,
          formQuestionObject.choices,
          handleChoiceButtonClick,
          key
        );

        break;
      case Constants.QESTION_DATE:
        // const maxDate = moment();
        // const maxDateString = maxDate.format("YYYY-MM-DD");

        return (
          <FormGroup key={key}>
            <Label style={{ "white-space": "pre-wrap" }}> {formQuestionObject.question}</Label>
            {formQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}

            {formQuestionObject.answerDate != null ? (
              <AvField
                type="date"
                name={"questionDate" + key}
                className="inner form-control"
                placeholder="dd/mm/yyyy"
                value={formatDate(formQuestionObject.answerDate)}
                onChange={(e) => {
                  handleDateChange(
                    formQuestionObject,
                    formSectionObject,
                    e.target.value
                  );
                }}
              />
            ) : (
              <AvField
                type="date"
                name={"questionDate" + key}
                className="inner form-control"
                placeholder="dd/mm/yyyy"
                onChange={(e) => {
                  handleDateChange(
                    formQuestionObject,
                    formSectionObject,
                    e.target.value
                  );
                }}
              />
            )}
          </FormGroup>
        );
        break;
      case Constants.QESTION_RANGE_TIME:
        break;
      case Constants.QESTION_GEO_POINT:
        break;
      case Constants.QESTION_YES_NO_COMMENT_ON_YES:
        if (!formQuestionObject.answerBool === true) {
          return boolTagsWithoutComment(
            formQuestionObject,
            formSectionObject,
            formQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        } else {
          return boolTagsWithComment(
            formQuestionObject,
            formSectionObject,
            formQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        }

        break;
      case Constants.QESTION_YES_NO_COMMENT_ON_NO:
        if (formQuestionObject.answerBool === false) {
          return boolTagsWithComment(
            formQuestionObject,
            formSectionObject,
            formQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        } else {
          return boolTagsWithoutComment(
            formQuestionObject,
            formSectionObject,
            formQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        }
        break;
      case Constants.QESTION_YES_NO_WITH_COMMENT:
        boolTagsWithComment(
          formQuestionObject,
          formSectionObject,
          formQuestionObject.choices,
          handleYesNoButtonClick,
          key
        );
        break;
      case Constants.QUESTION_EMAIL:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formemail" style={{ "white-space": "pre-wrap" }}> {formQuestionObject.question}</Label>
            {formQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="email"
              name={"email" + key}
              placeholder={props.t("Enter Email")}
              className="form-control"
              value={formQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(
                  formQuestionObject,
                  formSectionObject,
                  e.target.value
                );
              }}
              validate={{
                pattern: {
                  value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                  errorMessage: props.t(
                    "You have entered an invalid email address!"
                  ),
                },
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QUESTION_PHONE_NUMBER:
        return (
          <FormGroup key={key}>
            <Label style={{ "white-space": "pre-wrap" }}> {formQuestionObject.question}</Label>
            {formQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {formQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="text"
              name={"phone" + key}
              className="inner form-control noscroll"
              placeholder={props.t("Enter phone no")}
              value={formQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(
                  formQuestionObject,
                  formSectionObject,
                  e.target.value
                );
              }}
              validate={{
                pattern: {
                  value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                  errorMessage: props.t(
                    "You have entered an invalid phone no!"
                  ),
                },
                minLength: { value: 10 },
                maxLength: { value: 16 },
              }}
            />
          </FormGroup>
        );

        break;
    }
  };

  // //  UI Functions

  const radioButtonForSingleSelection = (
    formQuestionObject,
    formSectionObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="" style={{ "white-space": "pre-wrap" }}>{formQuestionObject.question}</Label>
        {formQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {formQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyInside}>
              <input
                className="form-check-input"
                type="radio"
                id={"radioButton" + key + keyInside}
                value={choice}
                checked={formQuestionObject.answerChoices.includes(choice)}
                onChange={() => {
                  handlerFunction(
                    choice,
                    formQuestionObject,
                    formSectionObject
                  );
                }}
              />
              <label className="form-check-label" htmlFor={"radioButton" + key + keyInside}>
                {choice}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const checkboxButtonForMultiSelection = (
    formQuestionObject,
    formSectionObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="" style={{ "white-space": "pre-wrap" }}>{formQuestionObject.question}</Label>
        {formQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {formQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyInside}>
              <input
                className="form-check-input"
                type="checkbox"
                id={"checkbox" + key + keyInside}
                value={choice}
                checked={formQuestionObject.answerChoices.includes(choice)}
                onChange={() => {
                  handlerFunction(
                    choice,
                    formQuestionObject,
                    formSectionObject
                  );
                }}
              />
              <label className="form-check-label" htmlFor={"checkbox" + key + keyInside}>
                {choice}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const boolTagsWithoutComment = (
    formQuestionObject,
    formSectionObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="" style={{ "white-space": "pre-wrap" }}>{formQuestionObject.question}</Label>
        {formQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {formQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div className="tagcloud mt-1">
          {choices.map((choice, keyInside) => (
            <Button
              key={keyInside}
              id={choice}
              color={
                formQuestionObject.answerChoices.includes(choice)
                  ? "primary"
                  : "secondary"
              }
              className="btn rounded mr-2 mb-2"
              onClick={() =>
                handlerFunction(choice, formQuestionObject, formSectionObject)
              }
            >
              {choice}
            </Button>
          ))}
        </div>
      </div>
    );
  };
  const boolTagsWithComment = (
    formQuestionObject,
    formSectionObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="" style={{ "white-space": "pre-wrap" }}>{formQuestionObject.question}</Label>
        {formQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {formQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div className="tagcloud mt-1">
          {choices.map((choice, keyInside) => (
            <Button
              key={keyInside}
              id={choice}
              color={
                formQuestionObject.answerChoices.includes(choice)
                  ? "primary"
                  : "secondary"
              }
              className="btn rounded mr-2"
              onClick={() =>
                handlerFunction(choice, formQuestionObject, formSectionObject)
              }
            >
              {choice}
            </Button>
          ))}
        </div>
        <AvField
          type="textarea"
          name="multiLine1"
          className="form-control mt-3"
          rows="3"
          placeholder={formQuestionObject.questionHint}
          onChange={(e) => {
            handleInputOnChange(
              formQuestionObject,
              formSectionObject,
              e.target.value
            );
          }}
        />
      </div>
    );
  };

  const checkForDifferentQuestionsValidations = (
    formQuestionsList,
    formSectionObject
  ) => {
    var canProceedToNextScreen = true;
    var i = 0;

    for (i = 0; i < formQuestionsList.length; i++) {
      if (formQuestionsList[i].isMandatory === true) {
        if (
          formQuestionsList[i].questionType ===
          Constants.QESTION_TEXT_SINGLE_LINE ||
          formQuestionsList[i].questionType ===
          Constants.QESTION_TEXT_MULTI_LINE ||
          formQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
          formQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER
        ) {
          if (
            formQuestionsList[i].answerText == null ||
            formQuestionsList[i].answerText === ""
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType === Constants.QESTION_NUMBER
        ) {
          if (formQuestionsList[i].answerText == null) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType === Constants.QESTION_CHECKBOX
        ) {
          if (
            formQuestionsList[i].answerBool == null ||
            formQuestionsList[i].answerBool === false
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_NO_COMMENTS
        ) {
          if (formQuestionsList[i].answerBool == null) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType ===
          Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
        ) {
          if (
            formQuestionsList[i].answerChoices == null ||
            formQuestionsList[i].answerChoices.length === 0
          ) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType ===
          Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
        ) {
          if (
            formQuestionsList[i].answerChoices == null ||
            formQuestionsList[i].answerChoices.length === 0
          ) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType === Constants.QESTION_DATE
        ) {
          if (formQuestionsList[i].answerDate == null) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_COMMENT_ON_YES ||
          formQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_COMMENT_ON_NO
        ) {

          if (
            formQuestionsList[i].answerBool == null ||
            formQuestionsList[i].answerBool === ""
          ) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_WITH_COMMENT
        ) {
        } else if (
          formQuestionsList[i].questionType === Constants.QESTION_UPLOAD_IMAGE
        ) {
          if (
            formQuestionsList[i].answerImage == null ||
            formQuestionsList[i].answerImageUploadProgress === 0
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        } else if (
          formQuestionsList[i].questionType ===
          Constants.QESTION_UPLOAD_DOCUMENT
        ) {
          if (
            formQuestionsList[i].answerFile == null ||
            formQuestionsList[i].answerFileUploadProgress === 0
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(formQuestionsList, formSectionObject, i);
          }
        }
      }
    }
    return canProceedToNextScreen;
  };

  const isFormValid = () => {
    var canProceedToSave = true;

    var formSectionObject = formSectionList[formSectionList.length - 1];
    var formQuestionsList = formSectionObject.formQuestionsList;
    canProceedToSave = checkForDifferentQuestionsValidations(
      formQuestionsList,
      formSectionObject
    );
    if (canProceedToSave) {
      saveForm();
    }
  };

  const checkForDataType = (dataType, answer) => {
    if (dataType === "ARRAY") {
      if (!Array.isArray(answer)) {
        return [answer];
      }
    } else if (dataType === "STRING") {
      if (!(typeof answer === "string" || answer instanceof String)) {
        return answer.toString();
      }
    } else if (dataType === "NUMBER") {
      if (typeof answer === "string" || answer instanceof String) {
        return parseInt(answer, 10);
      }
    }
    return answer;
  };

  const comparingForFillingObjectsWithAnswers = (
    i,
    objectToReturn,
    shouldSaveObject,
    formQuestionsList
  ) => {
    if (
      formQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_SINGLE_LINE ||
      formQuestionsList[i].questionType === Constants.QESTION_TEXT_MULTI_LINE ||
      formQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
      formQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER
    ) {
      if (
        formQuestionsList[i].isDefaultQuestion === true &&
        formQuestionsList[i].answerText != undefined &&
        formQuestionsList[i].answerText.length > 0
      ) {
        fillingFor = formQuestionsList[i].answerText;
      }
      if (formQuestionsList[i].answerText != null) {
        let tempAnswer = checkForDataType(
          formQuestionsList[i].dataType,
          formQuestionsList[i].answerText
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }

        shouldSaveObject = true;
      }
    } else if (formQuestionsList[i].questionType === Constants.QESTION_NUMBER) {
      if (formQuestionsList[i].answerText != null) {
        let tempAnswer = checkForDataType(
          formQuestionsList[i].dataType,
          formQuestionsList[i].answerText
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (
      formQuestionsList[i].questionType === Constants.QESTION_CHECKBOX
    ) {
      if (formQuestionsList[i].answerBool != null) {
        objectToReturn.set("answer", [formQuestionsList[i].answerBool]);
        shouldSaveObject = true;
      }
    } else if (
      formQuestionsList[i].questionType === Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      if (formQuestionsList[i].answerBool != null) {
        objectToReturn.set("answer", [formQuestionsList[i].answerBool]);
        shouldSaveObject = true;
      }
    } else if (
      formQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      if (
        formQuestionsList[i].answerChoices != null &&
        formQuestionsList[i].answerChoices.length > 0
      ) {
        let tempAnswer = checkForDataType(
          formQuestionsList[i].dataType,
          formQuestionsList[i].answerChoices[0]
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (
      formQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      if (
        formQuestionsList[i].answerChoices != null &&
        formQuestionsList[i].answerChoices.length > 0
      ) {
        let tempAnswer = checkForDataType(
          formQuestionsList[i].dataType,
          formQuestionsList[i].answerChoices
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (formQuestionsList[i].questionType === Constants.QESTION_DATE) {
      if (formQuestionsList[i].answerDate != null) {
        objectToReturn.set("answer", [formQuestionsList[i].answerDate]);
        shouldSaveObject = true;
      }
    } else if (
      formQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_YES ||
      formQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      let answerList = []

      if (formQuestionsList[i].answerBool != null) {
        answerList.push(formQuestionsList[i].answerBool)
      }
      if (formQuestionsList[i].answerText != null) {
        answerList.push(formQuestionsList[i].answerText)
      }
      if (answerList.length > 0) {
        shouldSaveObject = true;
        objectToReturn.set("answer", answerList);
      }
    } else if (
      formQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (
      formQuestionsList[i].questionType === Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      if (
        formQuestionsList[i].answerFile &&
        formQuestionsList[i].answerFileUploadProgress === 100
      ) {
        objectToReturn.set("answer", [formQuestionsList[i].answerFile.file]);
        shouldSaveObject = true;
      } else if (
        formQuestionsList[i].answerFileUploadProgress >= 1 ||
        formQuestionsList[i].answerFileUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for file upload to complete, then try again",
          { timeOut: 1000 }
        );
      }
    } else if (
      formQuestionsList[i].questionType === Constants.QESTION_UPLOAD_IMAGE
    ) {
      if (
        formQuestionsList[i].answerImage &&
        formQuestionsList[i].answerImage.image != null
      ) {
        objectToReturn.set("answer", [formQuestionsList[i].answerImage.image]);
        shouldSaveObject = true;
      } else if (
        formQuestionsList[i].answerImageUploadProgress >= 1 ||
        formQuestionsList[i].answerImageUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for Image upload to complete, then try again",
          { timeOut: 1000 }
        );
      }
    }
    return {
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };

  const collectingObjectsWithAnswers = () => {
    let listOfObjectsToSave = [];

    var j = 0;
    for (j = 0; j < formSectionList.length; j++) {
      var i = 0;
      var formQuestionsList = formSectionList[j].formQuestionsList;
      for (i = 0; i < formQuestionsList.length; i++) {
        let FormData = Parse.Object.extend("FormData");
        let objectToSave = new FormData();
        objectToSave.set("formId", formId);
        var shouldSaveObject = false;
        var result = undefined;
        objectToSave.set(
          "sectionId",
          formSectionList[j].formSectionParseObject.id
        );
        objectToSave.set(
          "questionId",
          formQuestionsList[i].formQuestionParseObject.id
        );

        result = comparingForFillingObjectsWithAnswers(
          i,
          objectToSave,
          shouldSaveObject,
          formQuestionsList
        );
        if (result) {
          shouldSaveObject = result.shouldSaveObject;
          objectToSave = result.objectToReturn;
        }
        if (shouldSaveObject) {
          listOfObjectsToSave.push(objectToSave);
        }
      }
    }

    return listOfObjectsToSave;
  };

  const saveForm = () => {
    if (abortSubmitForm) {
      return;
    }

    formIsSubmitesOps(true);

    let FormSession = Parse.Object.extend("FormSessions");
    let formSessions = new FormSession();
    formSessions.set("formId", formId);
    formSessions.set("formName", formName)

    if (publicMatch && publicMatch.params.type == "f" && franchiseId) {
      formSessions.set("franchiseId", franchiseId);
    } else {
      formSessions.set("schoolId", schoolId);
    }
    let objectsToSave = collectingObjectsWithAnswers();

    if (fillingFor) {
      formSessions.set("fillingFor", fillingFor);
    }
    if (matchForStudent) {
      formSessions.set("userId", kidId)
      formSessions.set("userType", "student");
    } else if (matchForCourse) {
      formSessions.set("courseId", courseId);
      formSessions.set("kidId", kidId);
      formSessions.set("userId", kidId)
      formSessions.set("userType", "student");
    }

    formSessions.save().then(
      (result) => {
        for (var i = 0; i < objectsToSave.length; i++) {
          objectsToSave[i].set("sessionId", result.id);
        }
        Parse.Object.saveAll(objectsToSave).then(
          (result) => {
            setCardMessage("Successfully submitted the form !!");
            setIsFormSubmitted(true);
          },
          (error) => {
            formIsSubmites(false);
            toastr.warning("Something went wrong, please try again !!");
          }
        );
      },
      (error) => {
        formIsSubmites(false);
        toastr.warning("Something went wrong, please try again !!");
      }
    );

    //Parse.saveAll
  };

  //Handler functions
  //  Click/Changes Handlers
  const handleYesNoButtonClick = (
    choice,
    formQuestionObject,
    formSectionObject
  ) => {
    var questionIndex = formSectionObject.formQuestionsList.indexOf(
      formQuestionObject
    );

    var titleIndex = formSectionList.indexOf(formSectionObject);

    var tempQuestionObject = formQuestionObject;

    var tempTitleObject = formSectionList[titleIndex];

    var choices = [...formQuestionObject.choices];

    var tempArray = [...tempQuestionObject.answerChoices];

    if (tempQuestionObject.answerChoices.includes(choice)) {
      //remove
      removeElement(tempArray, choice);
    } else {
      tempArray = [choice];
    }

    tempQuestionObject.answerChoices = [...tempArray];

    if (tempQuestionObject.isMandatory) {
      if (tempArray != null && tempArray.length > 0) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    if (tempQuestionObject.answerChoices.includes(choices[0])) {
      tempQuestionObject.answerBool = true;
    } else if (tempQuestionObject.answerChoices.includes(choices[1])) {
      tempQuestionObject.answerBool = false;
    } else {
      tempQuestionObject.answerBool = null;
    }

    var tempQuestionList = [...tempTitleObject.formQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.formQuestionsList = [...tempQuestionList];

    var tempTitleList = [...formSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setFormSectionList(tempTitleList);
  };

  const handleChoiceButtonClick = (
    choice,
    formQuestionObject,
    formSectionObject
  ) => {
    var questionIndex = formSectionObject.formQuestionsList.indexOf(
      formQuestionObject
    );

    var titleIndex = formSectionList.indexOf(formSectionObject);

    var tempQuestionObject = formQuestionObject;

    var tempTitleObject = formSectionList[titleIndex];

    if (
      tempQuestionObject.questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      var tempArray = [...tempQuestionObject.answerChoices];

      if (tempQuestionObject.answerChoices.includes(choice)) {
        //remove
        removeElement(tempArray, choice);
      } else {
        tempArray.push(choice);
      }

      tempQuestionObject.answerChoices = tempArray;

      if (tempQuestionObject.isMandatory) {
        if (tempArray != null && tempArray.length > 0) {
          tempQuestionObject.answerError = false;
        } else {
          tempQuestionObject.answerError = true;
        }
      }

      var tempQuestionList = [...tempTitleObject.formQuestionsList];

      tempQuestionList[questionIndex] = tempQuestionObject;

      tempTitleObject.formQuestionsList = [...tempQuestionList];

      var tempTitleList = [...formSectionList];

      tempTitleList[titleIndex] = tempTitleObject;

      setFormSectionList(tempTitleList);
    } else if (
      tempQuestionObject.questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      var tempArray = [...tempQuestionObject.answerChoices];

      if (tempQuestionObject.answerChoices.includes(choice)) {
        //remove
        removeElement(tempArray, choice);
      } else {
        tempArray = [choice];
      }

      tempQuestionObject.answerChoices = [...tempArray];

      if (tempQuestionObject.isMandatory) {
        if (tempArray != null && tempArray.length > 0) {
          tempQuestionObject.answerError = false;
        } else {
          tempQuestionObject.answerError = true;
        }
      }

      var tempQuestionList = [...tempTitleObject.formQuestionsList];

      tempQuestionList[questionIndex] = tempQuestionObject;

      tempTitleObject.formQuestionsList = [...tempQuestionList];

      var tempTitleList = [...formSectionList];

      tempTitleList[titleIndex] = tempTitleObject;

      setFormSectionList(tempTitleList);
    }
  };

  const handleCheckBoxChange = (formQuestionObject, formSectionObject) => {
    var questionIndex = formSectionObject.formQuestionsList.indexOf(
      formQuestionObject
    );

    var titleIndex = formSectionList.indexOf(formSectionObject);

    var tempQuestionObject = formQuestionObject;

    var tempTitleObject = formSectionList[titleIndex];

    tempQuestionObject.answerBool = !formSectionObject.formQuestionsList[
      questionIndex
    ].answerBool;

    if (tempQuestionObject.isMandatory) {
      if (tempQuestionObject.answerBool === true) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    var tempQuestionList = [...tempTitleObject.formQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.formQuestionsList = [...tempQuestionList];

    var tempTitleList = [...formSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setFormSectionList(tempTitleList);
  };

  const handleInputOnChange = (
    formQuestionObject,
    formSectionObject,
    changedValue
  ) => {
    var questionIndex = formSectionObject.formQuestionsList.indexOf(
      formQuestionObject
    );

    var titleIndex = formSectionList.indexOf(formSectionObject);

    var tempQuestionObject = formQuestionObject;

    var tempTitleObject = formSectionList[titleIndex];

    tempQuestionObject.answerText = changedValue;

    if (tempQuestionObject.isMandatory) {
      if (changedValue.length > 0) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    var tempQuestionList = [...tempTitleObject.formQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.formQuestionsList = [...tempQuestionList];

    var tempTitleList = [...formSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setFormSectionList(tempTitleList);
  };

  const handleDateChange = (
    formQuestionObject,
    formSectionObject,
    changedValue
  ) => {
    var questionIndex = formSectionObject.formQuestionsList.indexOf(
      formQuestionObject
    );

    var titleIndex = formSectionList.indexOf(formSectionObject);

    var tempQuestionObject = formQuestionObject;

    var tempTitleObject = formSectionList[titleIndex];

    tempQuestionObject.answerDate = new Date(changedValue);
    if (tempQuestionObject.isMandatory) {
      if (changedValue != undefined) {
        tempQuestionObject.answerError = false;
      }
    }

    var tempQuestionList = [...tempTitleObject.formQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.formQuestionsList = [...tempQuestionList];

    formSectionList[titleIndex] = tempTitleObject;
  };

  const setAnswerErrorTrue = (formQuestionsList, formSectionObject, i) => {
    var tempObject = formQuestionsList[i];

    tempObject.answerError = true;

    var temp = [...formQuestionsList];

    temp[i] = tempObject;

    var tempTitleList = [...formSectionList];

    var titleIndex = formSectionList.indexOf(formSectionObject);

    var tempTitleObject = formSectionList[titleIndex];

    tempTitleObject.formQuestionsList = temp;

    tempTitleList[titleIndex] = tempTitleObject;

    setFormSectionList(tempTitleList);
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var resultWithName = { name: file.name, result: reader.result };
      cb(resultWithName);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }
    // else if(fileType === "Video"){
    //     return "bx bx-play-circle";
    // }else if (_audioExtensions.includes(fileExtension)) {
    //     return "bx bxs-music";
    // }
    return "bx bxs-file-blank";
  }

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  function formatDate(d) {
    if (d == null || d === "" || typeof d === "string" || d instanceof String) {
      return "";
    } else {
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      return `${ye}-${mo}-${da}`;
    }
  }
  function toggleTab(tab, formQuestionsList, formSectionObject, choice) {
    if (tab !== activeTab) {
      if (tab - activeTab === 1 || tab - activeTab === -1) {
      } else {
        if (choice === next) {
          tab = activeTab + 1;
        } else if (choice === previous) {
          tab = activeTab - 1;
        }
      }

      if (choice === next) {
        var canProceedToNextScreen = true;

        canProceedToNextScreen = checkForDifferentQuestionsValidations(
          formQuestionsList,
          formSectionObject
        );

        if (canProceedToNextScreen) {
          if (activeTab !== tab) {
            if (tab >= 0 && tab <= formSectionList.length - 1) {
              scroller.scrollTo("topScrollArea", {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page
              });
              setactiveTab(tab);

              var tempRatio = 100 / formSectionList.length;
              var tempMultiplier = tab + 1;
              var tempProgress = tempMultiplier * tempRatio;
              setprogressValue(tempProgress);
            }
          }
        } else {
        }
      } else {
        if (activeTab !== tab) {
          if (tab >= 0 && tab <= formSectionList.length - 1) {
            scroller.scrollTo("topScrollArea", {
              duration: 500,
              delay: 100,
              smooth: true,
              offset: -100, // Scrolls to element + 50 pixels down the page
            });
            setactiveTab(tab);
            var tempRatio = 100 / formSectionList.length;
            var tempMultiplier = tab + 1;
            var tempProgress = tempMultiplier * tempRatio;
            setprogressValue(tempProgress);
          }
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Container fluid={true} className={(match || matchForBackwardCompatibility) ? "" : (matchForCourse ? "mt-3" : "page-content")}>
        {!isLoading && !isFormSubmitted && isQuestionsAvailable && (
          <Row>
            <Col lg={(matchForIframe || matchInsideDashboard || matchForCourse) ? 12 : 8} className="mx-auto">
              {(match || matchForBackwardCompatibility) && (
                <div className="text-center">
                  <h3 className="mt-3">
                    {stateSchoolObject
                      ? props.t(
                        `Welcome to ${stateSchoolObject.attributes.Name}`
                      )
                      : props.t("Welcome")}{" "}
                  </h3>
                  {match && stateSchoolObject && stateSchoolObject.attributes.logo && (
                    <img
                      src={stateSchoolObject.attributes.logo._url}
                      width="100"
                      alt=""
                      className="img-thumbnail rounded-circle"
                    />
                  )}
                  <h6 className="mt-3">
                    {schoolId
                      ? props.t("Please provide the details")
                      : props.t("Please provide the details")}
                  </h6>
                </div>
              )}
              <Card>
                <CardBody>
                  <Element name="topScrollArea">
                    <h4 className="card-title mb-4">{formName}</h4>
                  </Element>

                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      {formSectionList.map((formSection, keyTitle) => (
                        <NavItem key={keyTitle}>
                          <NavLink
                            className={classnames({
                              active: activeTab === keyTitle,
                            })}
                            onClick={() => {
                              keyTitle > activeTab
                                ? toggleTab(
                                  keyTitle,
                                  formSectionList[activeTab]
                                    .formQuestionsList,
                                  formSectionList[activeTab],
                                  next
                                )
                                : toggleTab(
                                  keyTitle,
                                  formSectionList[activeTab]
                                    .formQuestionsList,
                                  formSectionList[activeTab],
                                  previous
                                );
                            }}
                          >
                            <Col>
                              <Row className="justify-content-center">
                                <span className="step-number">
                                  {keyTitle + 1}
                                </span>
                              </Row>
                              <Row className="justify-content-center mt-2">
                                <div style={{ textAlign: "center" }}>
                                  {formSection.title}
                                </div>
                              </Row>
                            </Col>
                          </NavLink>
                        </NavItem>
                      ))}
                    </ul>
                    <div id="bar" className="mt-4">
                      <Progress
                        color="success"
                        striped
                        animated
                        value={progressValue}
                      />
                      <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                    </div>
                    <TabContent
                      activeTab={activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      {formSectionList.map((formSection, keyTitle) => (
                        <TabPane key={keyTitle} tabId={keyTitle}>
                          <Col lg={12} className="mx-auto">
                            <Card>
                              <CardBody>
                                <AvForm
                                  className="outer-repeater"
                                  style={{ border: "1px" }}
                                  onValidSubmit={isFormValid}
                                >
                                  <div
                                    data-repeater-list="outer-group"
                                    className="outer"
                                  >
                                    <div data-repeater-item className="outer">
                                      <h5>{props.t(formSection.title)}: </h5>
                                      <hr />

                                      {formSection.formQuestionsList.map(
                                        (formQuestionObject, keyQuestion) =>
                                          componentForQuestionType(
                                            formQuestionObject,
                                            formSection,
                                            keyQuestion
                                          )
                                      )}
                                      {formSection.formQuestionsList.length ===
                                        0 && (
                                          <h6 className="mb-3">
                                            No Questions in this section.
                                          </h6>
                                        )}

                                      {formSectionList.length - 1 ===
                                        activeTab && <hr></hr>}
                                      {formSectionList.length - 1 ===
                                        activeTab && (
                                          <div style={{ display: "flex" }}>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              disabled={formIsSubmites}
                                              style={{
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                width: "180px",
                                              }}
                                            >
                                              {formIsSubmites && (
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                                              )}
                                              {props.t("SUBMIT")}
                                            </Button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </AvForm>
                              </CardBody>
                            </Card>
                          </Col>
                        </TabPane>
                      ))}
                    </TabContent>

                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTab === 0 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(
                              activeTab - 1,
                              formSectionList[activeTab].formQuestionsList,
                              formSectionList[activeTab],
                              previous
                            );
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      <li
                        className={
                          activeTab === formSectionList.length - 1
                            ? "next disabled"
                            : "next"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(
                              activeTab + 1,
                              formSectionList[activeTab].formQuestionsList,
                              formSectionList[activeTab],
                              next
                            );
                          }}
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {isFormSubmitted && (
          <Card className="p-5 mt-2">
            <CardBody>
              {/* <div className="outer-repeater" style={{ border: '1px' }} >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer"> 
                    <h5 ><i className="fa fa-check text-success text-center mr-2

" aria-hidden="true"></i>
                    {cardMessage}</h5>
                  {/* </div>
                </div>
              </div> */}
              <CardTitle className="text-center">
                <i className={cardIcon} aria-hidden="true"></i>
                {cardMessage}
              </CardTitle>
            </CardBody>
          </Card>
        )}

        {!isLoading && (!isQuestionsAvailable || formIsDeleted) && (
          <Card>
            <CardBody>
              <AvForm
                className="outer-repeater"
                style={{ border: "1px" }}
                onValidSubmit={saveForm}
              >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    {formIsDeleted ? <h5>This form is no longer available !!</h5> : <h5>Form not yet configured !!</h5>}
                  </div>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Form...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};

export default withNamespaces()(EditCustomForm);

class CustomFormObject {
  question = "";
  questionType = null;
  questionNumber = null;
  formQuestionParseObject = null;
  answerText = null;
  answerNumber = null;
  answerBool = null;
  answerDate = null;
  answerChoices = [];
  choices = [];
  dataType = null;
  questionHint = "Type here";
  isMandatory = false;
  answerError = false;
  answerFile = null;
  answerImage = null;
  answerFileUploadProgress = 0;
  answerImageUploadProgress = 0;
  isDefaultQuestion = false;

  constructor(
    question,
    questionType,
    questionNumber,
    formQuestionParseObject,
    answerText,
    answerNumber,
    answerBool,
    dataType,
    answerDate,
    answerChoices,
    choices,
    questionHint,
    isMandatory = false,
    answerError = false,
    answerFile,
    answerImage,
    answerFileUploadProgress,
    answerImageUploadProgress,
    isDefaultQuestion = false,
  ) {
    this.question = question;
    this.questionType = questionType;
    this.questionNumber = questionNumber;
    this.formQuestionParseObject = formQuestionParseObject;
    this.answerText = answerText;
    this.answerNumber = answerNumber;
    this.answerBool = answerBool;
    this.dataType = dataType;
    this.answerDate = answerDate;
    this.answerChoices = answerChoices;
    this.choices = choices;
    this.questionHint = questionHint;
    this.isMandatory = isMandatory;
    this.answerError = answerError;
    this.answerFile = answerFile;
    this.answerImage = answerImage;
    this.answerFileUploadProgress = answerFileUploadProgress;
    this.answerImageUploadProgress = answerImageUploadProgress;
    this.isDefaultQuestion = isDefaultQuestion;
  }
}
class CustomFormSectionObject {
  formQuestionsList = [];
  title = "";
  formSectionParseObject = null;

  constructor(formQuestionsList, formSectionParseObject, title) {
    this.formQuestionsList = formQuestionsList;
    this.title = title;
    this.formSectionParseObject = formSectionParseObject;
  }
}
