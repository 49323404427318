import * as actions from "./actionTypes";

const initialState = {
  courseQuizSessions: [], allCourseQuizSessions:[],
};

const QuizSession = (state = initialState, action) => {
  switch (action.type) {
    


    case actions.UPDATE_COURSE_SESSIONS_FOR_KID:


      var courseQuizSessions = [...state.courseQuizSessions];
      if (courseQuizSessions.length > 0) {
        for (const latest in action.payload.sessions) {
          var temp = courseQuizSessions.filter(
            (cqs) =>
              cqs.id === action.payload.sessions[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.sessions[latest].attributes.deleted) {

              var others = courseQuizSessions.filter(
                (cqs) =>
                  cqs.id !== action.payload.sessions[latest].id
              );

              courseQuizSessions = others;
            } else {
              var index = courseQuizSessions.lastIndexOf(temp[0]);
              courseQuizSessions[index] = action.payload.sessions[latest];
            }

          } else {
            if (action.payload.sessions[latest].attributes.deleted !== true) {
              courseQuizSessions.push(action.payload.sessions[latest]);
            }

          }
        }
        state = { ...state, courseQuizSessions: courseQuizSessions };
      } else {

        var i = 0
        var courseQuizSessionsToSave = []
        for (i = 0; i < action.payload.sessions.length; i++) {
          if (action.payload.sessions[i].attributes.isDeleted !== true) {
            courseQuizSessionsToSave.push(action.payload.sessions[i])
          }
        }
        state = { ...state, courseQuizSessions: courseQuizSessionsToSave };
      }
      break;
    
    case actions.UPDATE_COURSE_SESSIONS_FOR_COURSE_ID:
      state = { ...state, allCourseQuizSessions: action.payload.sessions };
      break;
      
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default QuizSession;
