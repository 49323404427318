import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as CourseHelper from "../../../helpers/ParseHelpers/course_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as CourseActions from "./actions";

function* getACourse({ payload: { id } }) {
  try {
    const response = yield call(CourseHelper.getCourseFromParse, id, false);
    yield put(CourseActions.updateCourse(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteACourse({ payload: { course } }) {
  try {
    const response = yield call(
      CourseHelper.deleteCourseFromParse,
      course
    );
    if (response) {
      yield put(CourseActions.updateCourse(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* setSelectedCourse({ payload: { id } }) {
  try {
    const response = yield call(CourseHelper.getCourseFromParse, id, true);
    yield put(CourseActions.updateSelectedCourse(response));
    if (response) {
      response.pin();
    } else {
      const sResponse = yield call(CourseHelper.getCourseFromParse, id, false);
      if (sResponse) {
        sResponse.pin();
        yield put(CourseActions.updateSelectedCourse(sResponse));
      }

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getAllCoursesForSchoolIdSaga({ payload: { schoolId } }) {
  try {
    const serverResponse = yield call(CourseHelper.getAllCoursesForSchoolId, schoolId);

    if (serverResponse && serverResponse.length > 0) {
      yield put(CourseActions.updateCourses(serverResponse));
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getCourseForownerId({ payload: { ownerId } }) {
  try {
    var key = Constants.COURSE_UPDATED_TIME_FOR_OWNER_ID + "_" + ownerId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(CourseHelper.getCourseForownerId, ownerId, null, true);
    yield put(CourseActions.updateCourses(localResponse));

    const serverResponse = yield call(CourseHelper.getCourseForownerId, ownerId, updatedTime, false);
    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(CourseActions.updateCourses(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getCoursesForOwnerIds({ payload: { ownerIds } }) {
  try {

    yield put(CourseActions.clearCourses());
    const localResponse = yield call(CourseHelper.getCoursesForOwnerIds, ownerIds, true);
    yield put(CourseActions.updateCourses(localResponse, true));

    const serverResponse = yield call(CourseHelper.getCoursesForOwnerIds, ownerIds, false);

    yield put(CourseActions.updateInitialLoading(false));
    if (serverResponse) {
      yield put(CourseActions.updateCourses(serverResponse, true));


      var nonDeleted = serverResponse.filter(
        (enquiryquestion) =>
          enquiryquestion.attributes.isDeleted !== true
      );

      var deleted = serverResponse.filter(
        (enquiryquestion) =>
          enquiryquestion.attributes.isDeleted
      );

      if (nonDeleted) {
        Parse.Object.pinAll(nonDeleted);
      }

      if (deleted) {
        Parse.Object.unPinAll(deleted);
      }

    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* setInitialLoading({ payload: { state } }) {
  try {
    yield put(CourseActions.updateInitialLoading(state));
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* addNewCourse({ payload: { course, cycles, schoolId, history } }) {
  try {
    const response = yield call(CourseHelper.saveCourseObject, course);
    if (response) {


      var list = [];
      if (cycles && cycles.length > 0) {
        for (const i in cycles) {
          var AcademicCycles = Parse.Object.extend("AcademicCycles");
          var academicCycles = new AcademicCycles();

          academicCycles.set("schoolCycle", cycles[i].school);
          academicCycles.set("defaultCycle", cycles[i].lil);
          academicCycles.set("courseId", response.id);
          academicCycles.set("order", parseInt(i));
          list.push(academicCycles);
        }
        const cycleResponse = yield call(CourseHelper.saveObjects, list);
        if (cycleResponse) {
          Parse.Object.pinAll(cycleResponse);
        }
        history.push("/lms-syllabus/" + schoolId + "/" + response.id);
      }
      yield put(CourseActions.updateCourse(response));
      response.pin();
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetCourse() {
  yield takeEvery(actionTypes.GET_COURSE, getACourse);
}

export function* watchDeleteCourse() {
  yield takeEvery(actionTypes.DELETE_COURSE, deleteACourse);
}

export function* watchGetCourseForownerId() {
  yield takeEvery(actionTypes.GET_COURSE_FOR_OWNER_ID, getCourseForownerId);
}
export function* watchgetCoursesForOwnerIds() {
  yield takeEvery(actionTypes.GET_COURSES, getCoursesForOwnerIds);
}

export function* watchSetInitialLoading() {
  yield takeEvery(actionTypes.SET_COURSE_INITIAL_LOADING, setInitialLoading);
}

export function* watchAddNewCourse() {
  yield takeEvery(actionTypes.ADD_NEW_COURSE, addNewCourse);
}

export function* watchSetSelectedCourse() {
  yield takeEvery(actionTypes.SET_SELECTED_COURSE, setSelectedCourse);
}

export function* watchGetAllCoursesForSchoolIdSaga() {
  yield takeEvery(actionTypes.GET_ALL_COURSES_FOR_SCHOOL_ID, getAllCoursesForSchoolIdSaga);
}



function* CourseSaga() {
  yield all([
    fork(watchGetCourse),
    fork(watchDeleteCourse),
    fork(watchGetCourseForownerId),
    fork(watchgetCoursesForOwnerIds),
    fork(watchSetInitialLoading),
    fork(watchAddNewCourse),
    fork(watchSetSelectedCourse),
    fork(watchGetAllCoursesForSchoolIdSaga),
  ]);
}
export default CourseSaga;