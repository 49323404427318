
import React, { useState,useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import Parse from 'parse';
import { Label,FormGroup, Row, Col, Card,CardBody,Media,Input,UncontrolledTooltip,Table } from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Editable from "react-bootstrap-editable";
import SweetAlert from "react-bootstrap-sweetalert";

import img1 from "../../assets/images/companies/img-1.png";
import classimge from '../../assets/images/lms/classroom.png';

import {
    setSelecetdSchool,
    getAcademicCycleForSchoolId,
    addAcademicCycle, deleteAcademicCycle,
} from '../../store/actions';


const CycleYears = (props) => {
    

   
       
    const schoolId = props.schoolId;
    // useEffect(() => {
    //     if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
    //         props.setSelecetdSchool(schoolId);
    //     }
    // }, [props.selectedSchool, schoolId]);

    

    // useEffect(() => {
    //     props.getAcademicCycleForSchoolId(schoolId)
    // }, [schoolId]);



    const [newCycleYear, setNewCycleYear] = useState(undefined);
    const [nameError, setNameError] = useState(false);
    const [selectedTab, setSelectedTab] = useState("Loading");
    

    const [selectedCycles, setSelectedCycles] = useState([]);
    const [selectedId, setSelectedId] = useState([]);
    const [cycleYearError, setCycleYearError] = useState(false);

    const [isCustomClass, setIsCustomClass] = useState(false);

    const [cyclicYears, setCyclicYears] = useState([
        {
            id: "Daycare",
            i: 0,
            title: "Daycare",
            actualCycles: ["Infant 0-18 Months", "Toddler 13-36 Months "],
            cycles: ["Infant	0-18 Months", "Toddler 	13-36 Months "],
            c: [{ id: "1.1", i: 0, year: "Infant 0-18 Months", renamed: undefined }, { id: "1.2", i: 1, year: "Toddler 13-36 Months", renamed: undefined }]
        },
        {
            id: "Foundational",
            i: 1,
            title: "Foundational Stage - (5 Years)",
            actualCycles: ["PlayGroup","Nursery", "LKG", "UKG", "Class1", "Class2"],
            cycles: ["PlayGroup","Nursery", "LKG", "UKG", "Class1", "Class2"],
            c: [{ id: "2.0", i: 0, year: "PlayGroup", renamed: undefined },{ id: "2.1", i: 1, year: "Nursery", renamed: undefined }, { id: "2.2", i: 2, year: "LKG", renamed: undefined }, { id: "2.3", i: 3, year: "UKG", renamed: undefined }, { id: "2.4", i: 4, year: "Class1", renamed: undefined }, { id: "2.5", i: 5, year: "Class2", renamed: undefined }]
        },
        {
            id: "Preparatory",
            i: 2,
            title: "Preparatory Stage - (3 Years)",
            actualCycles: ["Class3", "Class4", "Class5 "],
            cycles: ["Class3", "Class4", "Class5"],
            c: [{ id: "3.1", i: 0, year: "Class3", renamed: undefined }, { id: "3.2", i: 1, year: "Class4", renamed: undefined }, { id: "3.3", i: 2, year: "Class5", renamed: undefined },]
        },
        {
            id: "Middle",
            i: 3,
            title: "Middle Stage - (3 Years)",
            actualCycles: ["Class6", "Class7", "Class8"],
            cycles: ["Class6", "Class7", "Class8"],
            c: [{ id: "4.1", i: 0, year: "Class6", renamed: undefined }, { id: "4.2", i: 1, year: "Class7", renamed: undefined }, { id: "4.3", i: 2, year: "Class8", renamed: undefined },]
        },
        {
            id: "Secondary",
            i: 4,
            title: "Secondary Stage - (4 Years)",
            actualCycles: ["Class9", "Class10", "Class11", "Class12 "],
            cycles: ["Class9", "Class10", "Class11", "Class12"],
            c: [{ id: "5.1", i: 0, year: "Class9", renamed: undefined }, { id: "5.2", i: 2, year: "Class10", renamed: undefined }, { id: "5.3", i: 2, year: "Class11", renamed: undefined }, { id: "5.4", i: 3, year: "Class12", renamed: undefined }]
        }
        
    ])


    const [editCycle, setEditCycle] = useState(undefined);
    const [deleteCycle, setDeleteCycle] = useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);
    

    useEffect(() => {
        if (props.loadState === "request" && selectedTab !== "Loading") {
            setSelectedTab("Loading");
        } else if (props.loadState === "responce-local") {
            if (props.academicCycles && props.academicCycles.length > 0) {
                setSelectedTab("CycleList");
            }
        } else if (props.loadState === "responce-server") {
            setSelectedTab("CycleList");
        }
    }, [props.loadState]);

    

    useEffect(() => {
        if (props.academicCycles && props.academicCycles.length > 0) {
            var list = [];
            var ids = [];
            for (const i in cyclicYears) {
                var obj = cyclicYears[i];
                for (const j in cyclicYears[i].c) {
                    var selected = props.academicCycles.filter((cycle) => (cyclicYears[i].c[j].year === cycle.attributes.defaultCycle));
                    if (selected && selected.length > 0) {
                        obj.c[j].parseObject = selected[0];
                        obj.c[j].renamed = selected[0].attributes.schoolCycle;
                        ids.push(obj.c[j].id);
                    }
                }
                list.push(obj);
            
            }
            setCyclicYears(list);
            setSelectedCycles(ids);
        }
        
    }, [props.academicCycles, props.loadState]);

    

    function getNamesForIds() {
        var cycles = [];
        

        for (const i in cyclicYears) {
            for (const j in cyclicYears[i].c) {
                if (selectedId.includes(cyclicYears[i].c[j].id)) {
                    var lil = cyclicYears[i].c[j].year;
                    var soc = cyclicYears[i].c[j].year;
                    if (cyclicYears[i].c[j].renamed && cyclicYears[i].c[j].renamed.length > 0) {
                        soc = cyclicYears[i].c[j].renamed;
                    }
                    cycles.push({ lil: lil, school: soc });

                }
            }
        }

        return cycles
    }

    function handelAddCycleYearFromList(objects) {
        var list = []
        for (const i in objects) {
            var object = objects[i]
            var AcademicCycles = Parse.Object.extend("AcademicCycles");
            var academicCycles = new AcademicCycles();
            academicCycles.set("schoolCycle", object.school);
            academicCycles.set("defaultCycle", object.lil);
            
            
            list.push(academicCycles)
        }

        if (list && list.length > 0) {
            props.addAcademicCycle(list);
        }
        setSelectedTab("CycleList");
        setSelectedId([]);
    }

    function handelAddCycleYearFromInput(newCycleYear) {
        var AcademicCycles = Parse.Object.extend("AcademicCycles");
        var academicCycles = new AcademicCycles();
        academicCycles.set("schoolCycle", newCycleYear);
    
        props.addAcademicCycle([academicCycles]);
    }

    function handelShowAddCycleYear() {
        setNewCycleYear("")
        setNameError(false);
        setSelectedTab("AddCycleYear")
        setIsCustomClass(false);
        setSelectedId([]);
    }

    
    function handelWholeCycle(cycle) {

        if (selectedAll(cycle)) {
            var temp = [];
            var ids = [];
            for (const i in cycle.c) {
                ids.push(cycle.c[i].id)
            }
            for (const i in selectedCycles) {
                if (!ids.includes(selectedCycles[i])) {
                    temp.push(selectedCycles[i])
                }
            }
            setSelectedCycles(temp);
        } else {
            var temp = [...selectedCycles];

            for (const i in cycle.c) {
                if (!selectedCycles.includes(cycle.c[i].id)) {
                    temp.push(cycle.c[i].id)
                }
            }
            setSelectedCycles(temp);
        }
    }


    function handelCycle(year) {
       
        if (selectedCycles.includes(year.id)) {


            if (year.parseObject) {
                setDeleteCycle(year)
            } else {
                var temp = selectedCycles.filter(cycle => cycle !== year.id);
                setSelectedCycles(temp)
            }
               
        } else {
            var temp = [...selectedCycles];
            temp.push(year.id);
            handelAddCycle(year);
            setSelectedCycles(temp);
        }
    }

    function handelAddCycle(year) {
        
        var message = props.t('Added successfully!');
        var AcademicCycles = Parse.Object.extend("AcademicCycles");
        var academicCycles = new AcademicCycles();
        if (year.parseObject) {
            academicCycles = year.parseObject;
            if (year.parseObject.attributes.isDeleted) {
                academicCycles.set("isDeleted", false);
                academicCycles.set("deletedBy", '');
            }
            message = props.t('Updated successfully!');
        }
        
        academicCycles.set("schoolCycle", year.renamed);
        academicCycles.set("defaultCycle", year.year);
        academicCycles.set("schoolId", schoolId);


        academicCycles.set("order", props.academicCycles ? props.academicCycles.length : 0);
       
        
        if (year.id.startsWith("1")) {
            academicCycles.set("level", cyclicYears[0].id);
        } else if (year.id.startsWith("2")) {
            academicCycles.set("level", cyclicYears[1].id);
        } else if (year.id.startsWith("3")) {
            academicCycles.set("level", cyclicYears[2].id);
        } else if (year.id.startsWith("4")) {
            academicCycles.set("level", cyclicYears[3].id);
        } else if (year.id.startsWith("5")) {
            academicCycles.set("level", cyclicYears[4].id);
        }

        
        
        props.addAcademicCycle([academicCycles], message);
        
        setSelectedId([]);
    }

    function selectedAll(cycle) {
        var responcse = true;
        for (const i in cycle.c) {
            if (!selectedCycles.includes(cycle.c[i].id)) {
                responcse = false;
            }
        }
        return responcse
    }
    
    return (
        <React.Fragment>
            <div >
                {selectedTab == "Loading" ? <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
                        </div>
                    </Col>
                </Row>

                    :
                    <div className="row ">
                        <Col >
                    
                            <div>
                                <p className="text-muted mr-7">{props.t('Please click on Each Box next to each academic cycle  that is represented at your school. If you use different terminology for each academic cycle you may edit them.')}</p>
                                <u><p className="text-dark mr-7">{props.t('Academic cycle years are based on National Education Policy (NEP) 2020')} </p></u>
                            </div>

                            <Row className="justify-content-center">
                        
                                {cyclicYears.map((cycle) => (
                        
                                    <Col xl="4" sm="4" key={cycle.id}>
                                
                                        <Card
                                            outline
                                            color="primary"
                                            className="border  mt-2" style={{ 'height': '6cm' }}
                                        
                                        >
                                
                                            <CardBody>
                                        
                                                {/* <div className="custom-control custom-checkbox mt-2 ml-3">
                                            <Input type="checkbox" value="2" className="custom-control-input" onChange= {()=>(handelWholeCycle(cycle))} id={cycle.id} checked={selectedAll(cycle)}/>
                                            <Label className="custom-control-label" htmlFor={cycle.id}>{cycle.title}</Label>
                                        </div> */}

                                                <Label className="ml-3">{cycle.title}</Label>
                                        
                                                <div className="ml-3">
                                                    {cycle.c.map((year) => (
                                                        <div className="custom-control custom-checkbox mt-2 ml-3" key={year.id}>

                                                            <Row>
                                                                <div className='d-flex'>
                                                                <Input type="checkbox" value="2" className="custom-control-input" style={{ marginRight: '5px'}} id={year.id} onChange={() => (handelCycle(year))} checked={selectedCycles.includes(year.id) ? true : false} />
                                                                <Label className="custom-control-label mr-2"htmlFor={year.id}></Label>
                                                                <Editable
                                                                    className='d-flex'
                                                                    alwaysEditing={false}
                                                                    onValidated={(value) => {
                                                                        var list = [...cyclicYears];
                                                                        list[cycle.i].c[year.i].renamed = value;
                                                                        setCyclicYears(list);
                                                                        if (selectedCycles.includes(year.id)) {
                                                                            handelAddCycle(list[cycle.i].c[year.i]);
                                                                        } else {
                                                                            handelCycle(list[cycle.i].c[year.i]);
                                                                        }
                                                                
                                                                    }}
                                                                    onCancel={(v) => {
                                                                        var list = [...cyclicYears];
                                                                        list[cycle.i].c[year.i].renamed = undefined;
                                                                        setCyclicYears(list);
                                                                        console.log(list);
                                                                    }}
                                                                    disabled={false}
                                                                    editText={selectedCycles.includes(year.id) ? " ✎" : " ✎"}
                                                                    id={null}
                                                                    isValueClickable={false}
                                                                    mode="inline"
                                                                    placement="top"
                                                                    initialValue={year.renamed && year.renamed.length > 0 ? year.renamed : year.year}
                                                                    showText
                                                                    type="textfield"
                                                                    validate={(value) => {
                                                                        if (!value) {
                                                                            return props.t("can not be empty")
                                                                        }
                                                                    }}
                                                    
                                                                />
                                                                    </div>
                                                            </Row>
                                                
                                                        </div>))
                                                    }
                                                </div>
                                            </CardBody>
                                    
                                        </Card>
                                    </Col>
                        
                                ))}
                            </Row>
                        </Col>
                    </div>
            
                }



                {deleteCycle ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            var temp = selectedCycles.filter(cycle => cycle !== deleteCycle.id);
                            setSelectedCycles(temp)
                            props.deleteAcademicCycle(deleteCycle.parseObject);
                            setDeleteCycle(undefined);
                            setsuccess_dlg(true);
                        }}
                        onCancel={() => {
                            setDeleteCycle(undefined);
                            setsuccess_dlg(false);
                            
                        }}
                    >
                        {props.t("You won't be able to revert this!")}
                    </SweetAlert>
                ) : null}
                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t('Deleted')}
                        onConfirm={() => {
                            setsuccess_dlg(false)
                        }}
                    >
                        {props.t('Your cycle year (class) has been deleted.')}
                    </SweetAlert>
                ) : null}
                
                 
            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { academicCycles, loadState } = state.AcademicCycles;
    
    return { schools,selectedSchool,academicCycles,loadState};
}
            
export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getAcademicCycleForSchoolId,
    addAcademicCycle, deleteAcademicCycle,
})(CycleYears)));
