import React, { useState, useEffect } from 'react';
import {
    Col,
    Nav,
    NavItem,
    Row,
    NavLink,
    Card,
    CardTitle,
    CardSubtitle,
    CardBody,
    TabContent,
    TabPane,
    Form,
    FormGroup,
    Label,
    Alert,
    Button
} from "reactstrap";
import classnames from "classnames";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import {
    setSelecetdSchool,
    getClassroomsForSchoolId,
    getAllKids,
} from "../../store/actions";
import Parse from 'parse';
import Select from "react-select";


const Certificates = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/certificates/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [activeTab, setactiveTab] = useState("1");
    const [classroomList, setClassroomList] = useState([]);

    const [studentList, setStudentList] = useState([]);
    const [selectedStudent, setSelectedStudent] = useState();
    const [selectedStudentError, setSelectedStudentError] = useState(false);

    const allClassObject = () => {
        let tempSelectObj = {}

        tempSelectObj.id = "all"
        tempSelectObj.value = "all"
        tempSelectObj.label = "All Classrooms"
        return tempSelectObj;
    }
    const [selectedClassroom, setSelectedClassroom] = useState(allClassObject());




    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId])

    useEffect(() => {
        if (schoolId) {
            props.getClassroomsForSchoolId(schoolId);
            props.getAllKids(schoolId);
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.classrooms && props.classrooms.length > 0) {
            let tempSelectList = []
            tempSelectList.push(allClassObject())
            for (const classRoom of props.classrooms) {
                tempSelectList.push(getSelectedClass(classRoom))
            }
            setClassroomList(tempSelectList)
        }
    }, [props.classrooms]);


    const getSelectedClass = (classRoom) => {
        let tempSelectObj = {}
        let obj = classRoom.attributes
        tempSelectObj.id = classRoom.id
        tempSelectObj.name = obj.Name ?? ""
        tempSelectObj.value = classRoom.id
        tempSelectObj.label = obj.Name ?? ""
        tempSelectObj.classroomType = obj.classroomType;
        tempSelectObj.parseObject = classRoom
        return tempSelectObj;
    }

    useEffect(() => {

        if (props.kids.length > 0 && classroomList.length > 0) {
            let kidList = [];
            for (const val of props.kids) {

                if (selectedClassroom.id == 'all') {
                    kidList.push(getKidObject(val));
                } else if (selectedClassroom.classroomType == 2) {
                    if (selectedClassroom.id == val.attributes.ClassRoomID || selectedClassroom.id == val.attributes.daycareClassId) {
                        kidList.push(getKidObject(val));
                    }
                } else if (selectedClassroom.id == val.attributes.ClassRoomID) {
                    kidList.push(getKidObject(val));
                }
            }
            setStudentList(kidList)
        }

    }, [props.kids, selectedClassroom]);

    const getKidObject = (val) => {
        let temp = {};
        temp.id = val.id;
        temp.label = val.attributes.Name;
        temp.value = val.id;
        temp.object = val;

        return temp
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col lg={8} md={8} xs={12}>
                        <h4>Certificates</h4>
                    </Col>
                </Row>
                <Row className="checkout-tabs">
                    <Col lg="2">
                        <Nav className="flex-column" pills>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        setactiveTab("1");
                                    }}
                                >
                                    <i className="bx bxs-calendar-week d-block check-nav-icon mt-4 mb-2"></i>
                                    <p className="font-weight-bold mb-4">
                                        Certificates /Letter header
                                    </p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        setactiveTab("2");
                                    }}
                                >
                                    <i className="bx bx-clipboard d-block check-nav-icon mt-4 mb-2"></i>
                                    <p className="font-weight-bold mb-4">Bonafide Certificate</p>
                                </NavLink>
                            </NavItem>
                        </Nav>
                    </Col>
                    <Col lg="10">
                        <Card>
                            <CardBody>
                                <TabContent activeTab={activeTab}>
                                    <TabPane tabId="1"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                        <h4>Certificates</h4>
                                        <Alert
                                            color="danger"
                                            role="alert"
                                        >
                                            <p className="lead mb-1"><strong><u>Highlights</u></strong></p>
                                            <p className="mb-0">1. School can create the certificates. </p>
                                            <p className="mb-0">2. School can create the Bonafide, Preschool Graduation, participating and Prize Certificates </p>
                                        </Alert>
                                        <Link to={`/new-certificates/${schoolId}`}
                                            className="btn btn-primary">
                                            Create Certificate
                                        </Link>
                                    </TabPane>
                                    <hr />
                                    <TabPane tabId="1"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                        <h4>Letter header</h4>
                                        <Alert
                                            color="danger"
                                            role="alert"
                                        >
                                            <p className="lead mb-1"><strong><u>Highlights</u></strong></p>
                                            <p className="mb-0">1. School can create and print custom letter with school letter head. </p>
                                            <p className="mb-0">2. Letter header can be removed or added by clicking on customise button  </p>
                                            <p className="mb-0">3. Name, title and body of the letter can be given as per your requirement</p>
                                            <p className="mb-0">4. Letter body is integrated with editing software that allows users to see and edit content in a form that appears as it would when displayed on printed document or an interface, webpage, slide presentation</p>



                                        </Alert>
                                        <Link target={"_blank"} to={`/custom-certificate/${schoolId}`}
                                            className="btn btn-primary">
                                            Create Letter
                                        </Link>
                                    </TabPane>
                                    <TabPane
                                        tabId="2"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                        <CardTitle>Bonafide Certificate</CardTitle>
                                        <CardSubtitle className="mb-3">
                                            Select a classroom and student to generate the bonafide certificate
                                        </CardSubtitle>
                                        <Row className="bg-light">
                                            <Col>
                                                <FormGroup >
                                                    <Label htmlFor="formname">Select Class Room </Label>
                                                    <Select
                                                        onChange={(e) => {
                                                            setSelectedClassroom(e)
                                                        }}
                                                        value={selectedClassroom}
                                                        options={classroomList}
                                                        className="BuyerTypeSelection"
                                                        classNamePrefix="BuyerType"
                                                    />
                                                </FormGroup>
                                            </Col>
                                            <Col >
                                                <FormGroup >
                                                    <Label htmlFor="formname">Select Student </Label>
                                                    <Select
                                                        onChange={(v) => {
                                                            setSelectedStudent(v)
                                                            setSelectedStudentError(false)
                                                        }}
                                                        value={selectedStudent}
                                                        options={studentList}
                                                        className="BuyerTypeSelection"
                                                        classNamePrefix="BuyerType"
                                                    />
                                                    {selectedStudentError && <small className='text-danger'>Please select student</small>}
                                                </FormGroup>
                                            </Col>
                                            <Col >
                                                <div className="mt-4">
                                                    {selectedStudent ? <Link target={"_blank"} to={`/bonafide-certificate/${schoolId}/${selectedStudent.id}`}
                                                        className="btn btn-primary "
                                                    >Generate
                                                    </Link>
                                                        :
                                                        <Button
                                                            color="primary"
                                                            onClick={() => {
                                                                setSelectedStudentError(true)
                                                            }}
                                                            className=" ">
                                                            Generate
                                                        </Button>}
                                                </div>
                                            </Col>



                                        </Row>
                                    </TabPane>
                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment >
    );
}

const mapStatetoProps = (state) => {

    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { kids } = state.Kid;

    return { selectedSchool, classrooms, kids };
};

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getClassroomsForSchoolId,
    getAllKids,
})(Certificates)));