import React, { useState, useEffect } from 'react';
import * as WhiteLabel from "../../components/Common/WhiteLable";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';
import Switch from "react-switch";
import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Container, Label, Card, CardBody, FormGroup } from "reactstrap";

const header = (props) => {

    function print() {
        window.print();
    }

    const getHeader = () => {
        return (
            <div className='d-print-none'>
                <Row className="text-center bg-white">
                    <Col xs="12" className="d-print-none mt-3 mb-3">
                        <div className="navbar-header p-0" style={{ borderBottom: "1px solid gray" }}>
                            <h5 className="text-center m-4 "><img src={WhiteLabel.getAppLogoWithName()} className="m-5" height="50px" style={{ border: "1px solid lightGray", borderRadius: "10px", padding: "10px" }}></img>{`${props.certificate} / ${props.exam && props.exam.attributes && props.exam.attributes.name} ${!props.matchGroup ? "/" : ''} ${!props.matchGroup ? props.selectedKid && props.selectedKid.attributes && props.selectedKid.attributes.Name : ''}`}</h5>
                            <div className="d-flex m-4">
                                <Row className="d-flex  m-4">
                                    <Col className="d-flex justify-content-end">
                                        <div className="d-print-none m-2">
                                            <label><b>Duplicate</b></label>
                                        </div>
                                        <div className="m-2 d-print-none">
                                            <Switch
                                                height={15}
                                                width={30}
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                onColor={"#6add7d"}
                                                checked={props.duplicate}
                                                onChange={props.onClickSwitch}
                                            />
                                        </div>
                                    </Col>
                                    <Col>
                                        <div>
                                            <Button
                                                onClick={() => {
                                                    print();
                                                }}
                                                className="float-right btn btn-success waves-effect waves-light "
                                            >
                                                <i className="fa fa-print m-1"></i>Print
                                            </Button>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }

    return (
        <React.Fragment>
            {getHeader()}
        </React.Fragment>
    )
}

export default header
