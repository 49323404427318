import React, { useState, useEffect } from 'react'
import Topbar from '../Topbar'
import { Card, CardBody, Row, Col, Label, Table, UncontrolledTooltip, Tooltip, CardTitle, CardHeader, Button, Badge, Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap'
import Editable from "react-bootstrap-editable";
import Tags from './Tags';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import Switch from "react-switch";
import { connect } from 'react-redux';
import SweetAlert from "react-bootstrap-sweetalert";
import * as Constants from "../../../Constents";
import ItemImage from "../../../assets/images/stationery.png";
import {
    setSelecetdSchool, getInventoryItemForOwnerId, deleteInventoryItem, updateDeleteInventoryItem,
    getInventoryWarehouseForOwnerId, getInventoryStockForWarehouse, editInventoryItem, getFranchise,
    getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds,
} from '../../../store/actions';

function AllItems(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/inventory-items/:id",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-inventory-items/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [showDelete, setShowDelete] = useState(false);
    const [deleteObject, setDeleteObject] = useState(undefined);
    const [showCategory, setShowCategory] = useState(false);
    const [showInStoreCheck, setShowInStoreCheck] = useState([])
    const [showMatrixModal, setShowMatrixModal] = useState(false)
    const [subItemForPopup, setSubItemForPopUp] = useState([])


    useEffect(() => {
        if (schoolId && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            // props.getInventoryItemForOwnerId(schoolId, 'school');
            // props.getInventoryWarehouseForOwnerId(schoolId, 'school');
        }
    }, [schoolId]);

    useEffect(() => {
        if (props.franchise && props.franchise.attributes.features &&
            props.franchise.attributes.features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
            props.franchise.attributes.features[Constants.FRANCHISE_INVENTORY_INDEX] > 1) {
            setShowCategory(true)
        }

    }, [props.franchise])

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryItemForOwnerId(franchiseId, 'franchise');
            props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise');
            props.getFranchise(franchiseId)
        }
    }, [franchiseId])

    useEffect(() => {
        if (props.inventoryWarehouses && props.inventoryWarehouses.length > 0) {
            // console.log("props.inventoryWarehouses", props.inventoryWarehouses)
            props.getInventoryStockForWarehouse(props.inventoryWarehouses[0].id);
        }
    }, [props.inventoryWarehouses]);

    const handleDeleteItem = () => {

        props.deleteInventoryItem(deleteObject)

        setShowDelete(false);
    };

    const getStock = (itemId) => {
        if (props.inventoryItemStockMap && props.inventoryItemStockMap.length > 0) {
            let temp = props.inventoryItemStockMap.filter(
                (vendor) => vendor.attributes.itemId === itemId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.stock ?? "-")
            }
        }

        return '-'
    }


    // const getShowInStore = (isChecked, item) => {
    //     setShowInStoreCheck(item.attributes.showInStore)
    //     item.set("showInStore", isChecked);
    //     props.editInventoryItem(item);
    // }

    useEffect(() => {
        const initialStatus = props.inventoryItems.map(item => item.attributes.showInStore);
        setShowInStoreCheck(initialStatus);
    }, [props.inventoryItems]);

    useEffect(() => {
        if (props.inventoryItems && props.inventoryItems.length > 0) {
            let itemIds = []
            for (const si of props.inventoryItems) {
                itemIds.push(si.id)
            }
            props.getInventorySubItemForItemIds(itemIds)
            props.getInventoryItemMatrixForItemIds(itemIds)
        }
    }, [props.inventoryItems])

    const getShowInStore = (isChecked, index, item) => {
        setShowInStoreCheck(prevState => {
            const newState = [...prevState];
            newState[index] = isChecked;
            return newState;
        });
        item.set("showInStore", isChecked);
        props.editInventoryItem(item);
    };

    const Offsymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >
            </div>
        );
    };

    const OnSymbol = () => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    const getSubItemForPopup = (itemId) => {
        if (props.inventorySubItems && props.inventorySubItems.length > 0) {
            const filteredSubItems = props.inventorySubItems.filter(
                (si) => si.attributes.itemId === itemId
            );
            setSubItemForPopUp(filteredSubItems);
        } else {
            setSubItemForPopUp([]);
        }
    };

    const getMatrixName = (matrixId) => {
        if (props.inventoryMatrixs && props.inventoryMatrixs.length > 0) {
            let temp = props.inventoryMatrixs.filter(
                (matrix) => matrix.id == matrixId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.value ?? "-")
            }
        }
    }

    return (
        <div className='page-content'>
            <Topbar
                title={"All Items"}
                buttonName={"New Items"}
                hideButton={false}
                link={schoolId ? `/add-inventory-items/${schoolId}` : `/franchise-add-items/${franchiseId}`}
            />
            {showDelete && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDelete(false);
                    }}
                    onConfirm={() => handleDeleteItem()}
                >
                    {"Are you sure you want to delete"}
                    <br></br>
                </SweetAlert>
            )}
            <Modal isOpen={showMatrixModal} size='lg'>
                <ModalHeader toggle={() => {
                    setShowMatrixModal(false)
                }}>
                    {`${showMatrixModal && showMatrixModal.attributes.name} Sub Items`}
                </ModalHeader>
                <ModalBody>
                    <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                            <thead className="thead-light">
                                <tr>
                                    <th>#</th>
                                    {subItemForPopup.some(item => item.attributes.matrixColorId) && <th>Color</th>}
                                    {subItemForPopup.some(item => item.attributes.matrixSizeId) && <th>Size</th>}
                                    <th>Cost</th>
                                    <th>Stock</th>
                                </tr>
                            </thead>
                            <tbody>
                                {subItemForPopup?.length > 0 && (
                                    subItemForPopup.map((item, key) => (
                                        <tr key={key}>
                                            <td>{key + 1}</td>
                                            {subItemForPopup.some(i => i.attributes.matrixColorId) && (
                                                <td>
                                                    <h6>{item.attributes.matrixColorId ? getMatrixName(item.attributes.matrixColorId) : "-"}</h6>
                                                </td>
                                            )}
                                            {subItemForPopup.some(i => i.attributes.matrixSizeId) && (
                                                <td>
                                                    <h6>{item.attributes.matrixSizeId ? getMatrixName(item.attributes.matrixSizeId) : "-"}</h6>
                                                </td>
                                            )}
                                            <td>
                                                <h6>{item.attributes.sellingPrice ? `₹${item.attributes.sellingPrice.toFixed(2)}` : "-"}</h6>
                                            </td>
                                            <td>
                                                <h6>{getStock(item.id)}</h6>
                                            </td>
                                        </tr>
                                    ))
                                )}
                            </tbody>
                        </table>

                    </div>
                </ModalBody>
                <ModalFooter>
                    {franchiseId && (
                        <Button
                            className='btn btn'
                            color='secondary'
                            onClick={() => setShowMatrixModal(false)}
                        >
                            Close
                        </Button>
                    )}
                </ModalFooter>
            </Modal>
            <Row>
                <Col lg="12">
                    {props.inventoryItems && props.inventoryItems.length > 0 ? (<Card>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>#</th>
                                            <th>Name</th>
                                            <th>Stock</th>
                                            <th>Cost</th>
                                            {showCategory && <th>Categories</th>}
                                            <th>Show in store</th>
                                            <th style={{ width: "20px" }}>
                                                Edit
                                            </th>
                                            <th style={{ width: "20px" }}>
                                                Delete
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            props.inventoryItems.map((item, key) =>
                                                <tr key={key}>
                                                    <th>
                                                        {item.attributes.displayImage ?
                                                            <div>
                                                                <img className="rounded-circle avatar-xs" src={item.attributes.displayImage._url} alt="" />
                                                            </div> :
                                                            <div className="maintenance-img">
                                                                <img src={ItemImage} alt="" className="rounded-circle avatar-xs" />
                                                            </div>

                                                        }
                                                    </th>
                                                    <td>
                                                        <h6>{item.attributes.name}</h6>
                                                        <small>{item.attributes.type}</small>
                                                    </td>

                                                    <td>
                                                        <h6 className={getStock(item.id) <= item.attributes.minimumStock ? "text-danger" : ""}>{getStock(item.id)}</h6>
                                                        {item.attributes.isMatrix == true && <p className='text-primary' style={{ cursor: 'pointer' }}
                                                            onClick={() => {
                                                                getSubItemForPopup(item.id);
                                                                setShowMatrixModal(item);
                                                            }}><u>Matrix</u></p>}
                                                    </td>

                                                    <td>
                                                        <h6>{item.attributes.sellingPrice ? item.attributes.sellingPrice : "-"}</h6>
                                                    </td>
                                                    {showCategory && <td>
                                                        <Label className='text-center'>{item.attributes.categories ? item.attributes.categories : "-"}</Label>
                                                    </td>}

                                                    <td>
                                                        <Switch
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            height={15}
                                                            width={30}
                                                            onColor="#626ed4"
                                                            onChange={(e) => {
                                                                getShowInStore(!showInStoreCheck[key], key, item);
                                                            }}
                                                            checked={showInStoreCheck[key]}
                                                        />
                                                    </td>

                                                    <td>
                                                        <div className="flex-fill text-center ">
                                                            <Link to={match ? `/edit-inventory-items/"${schoolId}/${item.id}` : `/franchise-edit-inventory-items/${franchiseId}/${item.id}`}>
                                                                <i className="bx bx-edit-alt"></i>
                                                            </Link>
                                                        </div>

                                                    </td>

                                                    <td>
                                                        <div
                                                            className="flex-fill text-center"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setDeleteObject(item)
                                                                setShowDelete(true);
                                                            }}
                                                        >
                                                            <i className="bx bxs-trash text-danger"></i>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>) :
                    (<div>
                        {props.inventoryItems && props.inventoryItems.length <= 0 && <Card style={{ height: "400px" }}>
                            <CardBody>
                                <h4 style={{ marginTop: "150px" }} className='text-info text-center'>No Items found .</h4>
                            </CardBody>
                        </Card>}
                    </div>)}
                </Col>
                {/* <Col>
                    <Tags
                        schoolId={schoolId}
                        franchiseId={franchiseId} />
                </Col> */}
            </Row>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryItems, inventorySubItems, inventoryMatrixs } = state.InventoryItems;
    const { inventoryWarehouses, inventoryItemStockMap } = state.InventoryWarehouse;
    const { franchise } = state.Franchise;
    return { selectedSchool, inventoryItems, inventorySubItems, inventoryMatrixs, inventoryWarehouses, inventoryItemStockMap, franchise };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getInventoryItemForOwnerId, deleteInventoryItem, updateDeleteInventoryItem,
    getInventoryWarehouseForOwnerId, getInventoryStockForWarehouse, editInventoryItem, getFranchise,
    getInventorySubItemForItemIds, getInventoryItemMatrixForItemIds
})(AllItems)));
