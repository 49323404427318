import React from 'react';
import { Container } from "reactstrap";

import PricingPage from './PricingPage'
const PagesStarter = (props) => {

        return (
            <React.Fragment>
                <div className="">
                    <Container fluid>

                        <PricingPage isPopup={ false}/>

                    </Container>
                </div>
            </React.Fragment>
              );
        }
            
export default PagesStarter;