import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as CorporatePartnerHelper from "../../../helpers/ParseHelpers/corporatePartners_helper";
import * as actions from "./actionTypes";
import * as CorporatePartnerActions from './actions';
import Parse from 'parse';

function* getCorporatePartner({ payload: { id, type } }) {
  try {
    const response = yield call(CorporatePartnerHelper.getCorporatePartners, id, type);
    yield put(CorporatePartnerActions.updateCorporatePartners(response));
    Parse.Object.pinAll(response);
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* addCorporatePartner({ payload: { corporatePartner } }) {
  try {
    yield put(CorporatePartnerActions.setCorporatePartnersLoading(true));
    const response = yield call(CorporatePartnerHelper.saveCorporatePartner, corporatePartner);
    yield put(CorporatePartnerActions.updateCorporatePartners([response]));
    Parse.Object.pinAll(response);
    yield put(CorporatePartnerActions.setCorporatePartnersLoading(false));
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deleteCorporatePartner({ payload: { corporatePartner } }) {
  try {
    const response = yield call(CorporatePartnerHelper.saveCorporatePartner, corporatePartner);
    yield put(CorporatePartnerActions.updateCorporatePartners([response]));
    Parse.Object.unPinAll(response);
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getKidsForCorporatePartners({ payload: { corporateId } }) {
  try {
    const response = yield call(
      CorporatePartnerHelper.getKidsForCorporatePartners,
      corporateId,
      true
    );
    if (response) {
      yield put(CorporatePartnerActions.updateKidsForCorporatePartners(response));
    }  
      const sResponse = yield call(CorporatePartnerHelper.getKidsForCorporatePartners, corporateId, false);
      yield put(CorporatePartnerActions.updateKidsForCorporatePartners(sResponse??[]));
    
  } catch (err) {
    console.log(err);
  }
}

function* setSelectedCorporatePartner({ payload: {corporatePartnerId } }) {
  try {
    if (corporatePartnerId) {
      const localResponse = yield call(
        CorporatePartnerHelper.getCorporatePartnerFromParse,
        corporatePartnerId,
        true
      );
      if (localResponse) {
        yield put(CorporatePartnerActions.updateSelectedCorporatePartner(localResponse));
      }

      const serverResponse = yield call(
        CorporatePartnerHelper.getCorporatePartnerFromParse,
        corporatePartnerId,
        false
      );
      if (serverResponse) {
        yield put(CorporatePartnerActions.updateSelectedCorporatePartner(serverResponse));
        serverResponse.pin();
      }
    }
  } catch (error) {
    
  }
}

export function* watchGetCorporatePartners() {
  yield takeEvery(actions.GET_CORPORATE_PARTNERS, getCorporatePartner);
}
export function* watchAddCorporatePartner() {
  yield takeEvery(actions.ADD_CORPORATE_PARTNERS, addCorporatePartner);
}
export function* watchDeleteCorporatePartner() {
  yield takeEvery(actions.DELETE_CORPORATE_PARTNERS, deleteCorporatePartner);
}
export function* watchGetKidsForCorporatePartners() {
  yield takeEvery(actions.GET_KIDS_FOR_CORPORATE_PARTNERS, getKidsForCorporatePartners);
}
export function* watchSetSelectedCorporatePartner(){
  yield takeEvery(actions.SET_SELECTED_CORPORATE_PARTNER, setSelectedCorporatePartner)
}

function* CorporatePartnerSaga() {
  yield all([
    fork(watchGetCorporatePartners),
    fork(watchAddCorporatePartner),
    fork(watchDeleteCorporatePartner),
    fork(watchGetKidsForCorporatePartners),
    fork(watchSetSelectedCorporatePartner),
  ]);
}
export default CorporatePartnerSaga;