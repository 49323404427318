import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import {  Row, Col, Card, CardBody, Table, UncontrolledTooltip, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
const ListTeacherView = (props) =>{
    const [teachers, teachersOps] = useState([]);
    useEffect(()=>{
        teachersOps(props.teachers)
    },[props.teachers])
    return (
        <React.Fragment>
            <Col lg="12">
                
                        <div className="table-responsive mt-2">
                            <Table className="table-centered table-nowrap table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                        <th scope="col">{props.t("Name")}</th>
                                        <th scope="col">{props.t("Designation")}</th>
                                        <th scope="col">{props.t("Action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {/* add tacher */}
                                {/* <tr>
                                    <td>
                                        <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">
                                                +
                                            </span>
                                        </div>
                                    </td>
                                    <td>
                                        <h5 className="font-size-14 mb-1"><Link to="#" className="text-dark">{props.t("Add Teacher")}</Link></h5>
                                    </td>
                                    <td></td>
                                    <td>
                                        <ul className="list-inline font-size-20 contact-links mb-0">
                                            <li className="list-inline-item px-2">
                                                <Link to="#" id={"message"}>
                                                     <i className="bx bx-message-square-dots"></i>
                                                    <UncontrolledTooltip placement="top" target={"message"}>
                                                        Message
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                            <li className="list-inline-item px-2">
                                                <Link to={`#`} id={"edit_class_teachers"} onClick={() => props.popUpAction()}>
                                                    <i class='bx bx-plus-circle'></i>
                                                    <UncontrolledTooltip placement="top" target={"edit_class_teachers"}>
                                                    {props.t("Add Teacher")}
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                        </ul>
                                    </td>
                                </tr> */}
                                {
                                    teachers.map((user, i) =>
                                        <tr key={"_user_" + i} >
                                            <td>
                                                {
                                                    !user.attributes.Photo 
                                                        ? <div className="avatar-xs">
                                                            <Link to={`/staffprofile/${user.attributes.SchoolID}/${user.id}`} id={"profile" + user.id}><span className="avatar-title rounded-circle">
                                                                {user.attributes.Name.charAt(0).toUpperCase()}
                                                            </span></Link>
                                                        </div>
                                                        : <div>
                                                            <Link to={`/staffprofile/${user.attributes.SchoolID}/${user.id}`} id={"profile" + user.id}><img className="rounded-circle avatar-xs" src={user.attributes.Photo._url} alt="" /></Link>
                                                        </div>
                                                }

                                            </td>
                                            <td>
                                                <h5 className="font-size-14 mb-1"><Link to={`/staffprofile/${user.attributes.SchoolID}/${user.id}`} id={"profile" + user.id}className="text-dark">{user.attributes.Name}</Link></h5>
                                            </td>
                                            <td>
                                                {user.attributes.Role === 1 && <p className="text-muted mb-0">Teacher</p>}
                                                {user.attributes.Role === 2 && <p className="text-muted mb-0">Centerhead</p>}
                                            </td>
                                            {/* <td>
                                                {user.projects}
                                            </td> */}
                                            <td>
                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                    {/* <li className="list-inline-item px-2">
                                                        <Link to="#" id={"message" + user.id}>
                                                            <i className="bx bx-message-square-dots"></i>
                                                            <UncontrolledTooltip placement="top" target={"message" + user.id}>
                                                                Message
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </li> */}
                                                    <li className="list-inline-item px-2">
                                                        <Link to={`/staffprofile/${user.attributes.SchoolID}/${user.id}`} id={"profile" + user.id}>
                                                            <i className="bx bx-user-circle"></i>
                                                            <UncontrolledTooltip placement="top" target={"profile" + user.id}>
                                                                Profile
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    )
                                }
                                </tbody>
                            </Table>
                        </div>
                    
            </Col>
       </React.Fragment>
     );
}
export default (withRouter(withNamespaces()(ListTeacherView)));