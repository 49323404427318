import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Row, Col, Table, Button, UncontrolledTooltip, Label, FormGroup, ModalBody, Modal, ModalHeader, ModalFooter } from "reactstrap";
import Image from "../../assets/images/book_lover.svg";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import { Link } from "react-router-dom";
import Moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import Parse from 'parse';

import * as CommonFunctions from "../../components/Common/CommonFunctions";
import {
    getCorporatePartners,
    addCorporatePartner,
    deleteCorporatePartner
} from "../../store/actions";

import TopBar from './TopBar';


const Index = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/corporate-partners/:id',
        exact: true,
        strict: false,

    });

    const franchiseId = match ? match.params.id : "";
    useEffect(() => {
        props.getCorporatePartners(franchiseId, "franchiseId")
    }, [franchiseId]);

    const [deletecorporatePartner, setDeleteCorporatePartner] = useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);

    const [addCorporatePartnerModel, setAddCorporatePartnerModel] = useState(false);
    const [editParnerObject, setEditParnerObject] = useState(undefined);

    const [name, setName] = useState(undefined);
    const [contactperson, setContactPerson] = useState(undefined);
    const [phoneNumber, setPhoneNumber] = useState(undefined);
    const [email, setEmail] = useState(undefined);
    const [website, setWebsite] = useState(undefined);


    const handelAddTeacher = (name, contactperson, phoneNumber, email, website) => {
        var corporatePartnerObject;


        let parnerPhoneNumber = phoneNumber;
        let countryPhoneCode = "91";
        if (parnerPhoneNumber && parnerPhoneNumber.length > 0) {
            if (parnerPhoneNumber.indexOf('+') == 0) {
            } else if (parnerPhoneNumber.indexOf('0') == 0) {
                parnerPhoneNumber =
                    '+' + parnerPhoneNumber + parnerPhoneNumber.substring(1);
            } else if (parnerPhoneNumber.indexOf('+' + countryPhoneCode) == -1) {
                parnerPhoneNumber = '+' + countryPhoneCode + parnerPhoneNumber;
            } else if (parnerPhoneNumber.indexOf(countryPhoneCode) == 0) {
                parnerPhoneNumber = '+' + parnerPhoneNumber;
            }

        }
        if (editParnerObject) {

            var object = editParnerObject;
            object.set("name", name);
            object.set("email", email);
            object.set("phoneNumber", parnerPhoneNumber);
            object.set("website", website);
            object.set("contactPerson", contactperson);



            corporatePartnerObject = object;

        } else {
            const CorporatePartners = Parse.Object.extend("CorporatePartners");
            let ncp = new CorporatePartners();
            ncp.set("name", name);
            ncp.set("email", email);
            ncp.set("phoneNumber", parnerPhoneNumber);
            ncp.set("website", website);
            ncp.set("franchiseId", Parse.User.current().attributes.franchiseId);
            ncp.set("createdBy", Parse.User.current().attributes.teacherId);

            corporatePartnerObject = ncp;

        }

        props.addCorporatePartner(corporatePartnerObject);
        setAddCorporatePartnerModel(false);
        //    setEditParnerObject(undefined);
        //    setName(undefined);
        //    setPhoneNumber(undefined);
        //    setEmail(undefined);
        //    setWebsite(undefined);
        //    setContactPerson(undefined);
    }

    const handelAddPartners = () => {
        setAddCorporatePartnerModel(true);
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <TopBar title={"Corporate Partner"}
                        buttonName={"Add Partner"}
                        backButton={false}
                        hideButton={false}
                        onClickAdd={handelAddPartners}
                        reorderButton={false}
                    />
                    {(!props.corporatePartners || props.corporatePartners.length === 0) ? <Card>
                        <CardBody className="text-center" >
                            <Row className="justify-content-center">
                                <Col sm={4}>
                                    <div className="maintenance-img">
                                        <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>
                            </Row>
                            <h3 className="mt-5">{props.t('Add Corporate Partners')}</h3>

                            <button type="button"
                                className="btn btn-primary "
                                onClick={() => {
                                    handelAddPartners()
                                }}  >
                                {props.t('Add')}
                            </button>
                        </CardBody>
                    </Card>
                        :
                        <Row >
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th className="col-1" scope="col" style={{ width: "100px" }}>#</th>
                                                    <th className="col-5" scope="col">Name</th>
                                                    <th className="col-5" scope="col">Details</th>
                                                    <th className="col-1" scope="col" align="right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.corporatePartners.map((corporatePartner, key) =>
                                                    <tr key={corporatePartner.id} >
                                                        <td>{key + 1}</td>
                                                        <td >
                                                            <h5 className="text-truncate font-size-14">{corporatePartner.attributes.name}</h5>
                                                        </td>
                                                        <td>
                                                            {corporatePartner.attributes.contactPerson && <p className='mb-0'>{"Contact Person: " + corporatePartner.attributes.contactPerson}</p>}
                                                            {corporatePartner.attributes.phoneNumber && <p className='mb-0'>{"Phone Number: " + corporatePartner.attributes.phoneNumber}</p>}
                                                            {corporatePartner.attributes.email && <p className='mb-0'>{"Email: " + corporatePartner.attributes.email}</p>}
                                                            {corporatePartner.attributes.website && <p className='mb-0'>{"Website: " + corporatePartner.attributes.website}</p>}
                                                        </td>

                                                        <td>
                                                            <div style={{ textAlign: "center" }}>

                                                                <ul className="list-inline font-size-20 contact-links mb-0 float-right">
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setDeleteCorporatePartner(corporatePartner);
                                                                        }}
                                                                        id={"sub-delete" + key}>
                                                                        <i className="bx bx-trash text-danger"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                            {props.t('Delete')}
                                                                        </UncontrolledTooltip>

                                                                    </li>

                                                                    
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setName(corporatePartner.attributes.name);
                                                                            setContactPerson(corporatePartner.attributes.contactPerson);
                                                                            setPhoneNumber(corporatePartner.attributes.phoneNumber);
                                                                            setEmail(corporatePartner.attributes.email);
                                                                            setWebsite(corporatePartner.attributes.website);
                                                                            setEditParnerObject(corporatePartner)
                                                                            setAddCorporatePartnerModel(true);
                                                                        }}
                                                                        id={"sub-cyc" + key}>
                                                                        <i className="bx bx-edit-alt"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                            {props.t('Edit')}
                                                                        </UncontrolledTooltip>
                                                                    </li>
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            props.history.push("/corporate-partners-details/" + corporatePartner.id);
                                                                        }}
                                                                        id={"sub-cyc" + key}>
                                                                        <i className="bx bx-right-arrow-circle"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                            {props.t('Details')}
                                                                        </UncontrolledTooltip>
                                                                    </li>
                                                                </ul>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    }

                </Container>
            </div>

            <Modal
                isOpen={addCorporatePartnerModel || props.corporatePartnerAddLoading}
                toggle={() => {
                    setAddCorporatePartnerModel(false);
                }}
            >

                <ModalHeader>
                    {editParnerObject ? props.t("Edit Corporate Partner") : props.t("Add Corporate Partner")}
                </ModalHeader>
                <ModalBody>

                    <AvForm className="needs-validation"
                        onValidSubmit={(e, v) => {
                            // setName(v.name);
                            // setPhoneNumber( v.phoneNumber)
                            // setEmail(v.email)
                            // setWebsite(v.website)
                            // setContactPerson(v.contactperson)

                            handelAddTeacher(v.name, v.contactperson, v.phoneNumber, v.email, v.website);
                        }}
                    >


                        <FormGroup>
                            <Label htmlFor="validationCustom01">Corporate Partner Name</Label>
                            <AvField
                                name="name"
                                value={name}
                                placeholder="Partner name"
                                type="text"
                                errorMessage="Enter Partner Name"
                                className="form-control"
                                validate={{ required: { value: true } }}
                                id="validationCustom01"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="validationCustom02">Contact Person</Label>
                            <AvField
                                name="contactperson"
                                placeholder="Contact Person"
                                value={contactperson}
                                errorMessage="Enter Contact Person name"
                                className="form-control"

                                id="validationCustom05"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="validationCustom02">Phone Number</Label>
                            <AvField
                                name="phoneNumber"
                                placeholder="Phone Number"
                                value={phoneNumber}
                                errorMessage="Enter Phone Number"
                                className="form-control"
                                validate={{
                                    required: {
                                        value: false,
                                        errorMessage: props.t('Enter phone number'),
                                    },
                                    pattern: {
                                        value:
                                            "^([0|+[0-9]{1,5})?([1-9][0-9]{9,14})$",
                                        errorMessage: props.t("You have entered an invalid phone no!"),
                                    },
                                    minLength: { value: 10 },
                                    maxLength: { value: 16 },

                                }}
                                id="validationCustom02"
                            />
                        </FormGroup>

                        <FormGroup>
                            <Label htmlFor="validationCustom03">Email</Label>
                            <AvField
                                name="email"
                                placeholder="Email"
                                value={email}
                                type="email"
                                errorMessage=" Enter Email."
                                className="form-control"
                                validate={{
                                    required: {
                                        value: false,
                                        errorMessage: props.t('Enter Email.'),
                                    },
                                    pattern: {
                                        value:
                                            "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                        errorMessage: props.t("You have entered an invalid email address!"),
                                    },
                                }}
                                id="validationCustom03"
                            />
                        </FormGroup>


                        <FormGroup>
                            <Label htmlFor="validationCustom01">Website</Label>
                            <AvField
                                name="website"
                                value={website}
                                placeholder="Website"
                                type="text"
                                className="form-control"
                                id="validationCustom04"
                            />
                        </FormGroup>


                        {props.corporatePartnerAddLoading ? <ModalFooter>

                            <button type="button" className="btn btn-light waves-effect">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                {props.t(" Saving...")}
                            </button>
                        </ModalFooter> :


                            <ModalFooter>

                                <button
                                    type="button"
                                    className="btn btn-secondary"
                                    onClick={() => {
                                        setAddCorporatePartnerModel(false);
                                        setName(undefined);
                                        setPhoneNumber(undefined);
                                        setEmail(undefined);
                                        setWebsite(undefined);

                                    }}
                                >
                                    {props.t("Close")}

                                </button>

                                <button
                                    className="btn btn-primary"
                                    type="submit"
                                >
                                    {props.t('Save')}
                                </button>




                            </ModalFooter>}

                    </AvForm>

                </ModalBody>

            </Modal>
            {deletecorporatePartner ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        let temp = deletecorporatePartner;
                        temp.set("isDeleted", true)
                        temp.set("deletedBy", Parse.User.current().attributes.teacherId);
                        props.deleteCorporatePartner(deletecorporatePartner);
                        setDeleteCorporatePartner(undefined);
                        setsuccess_dlg(true);
                    }}
                    onCancel={() => {
                        setDeleteCorporatePartner(undefined);
                        setsuccess_dlg(false);

                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {props.t('Your Partner has been deleted.')}
                </SweetAlert>
            ) : null}
        </React.Fragment>
    );
}


const mapStatetoProps = (state) => {
    const { franchise } = state.Franchise;
    const { corporatePartners, corporatePartnerAddLoading } = state.CorporatePartners;


    return { franchise, corporatePartners, corporatePartnerAddLoading };
};


export default connect(mapStatetoProps, {
    getCorporatePartners,
    addCorporatePartner,
    deleteCorporatePartner
})(withNamespaces()(Index));
