import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
    setSelecetdSchool, getFranchise, getCorporatePartners, setLodingState
} from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Select from "react-select";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Button
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import Image from "../../../assets/images/growing_chart.svg";

import * as Constants from "../../../Constents";
import { number } from 'echarts';


const RevenueReport = (props) => {

    const schoolId = props.schoolId

    const [selectedMonth, setSelectedMonth] = useState(new Date());

    const [revenueReportData, SetRevenueReportData] = useState(undefined);

    const [invoice, setInvoice] = useState(undefined);
    const [kid, setKid] = useState(undefined);
    const [classRoom, setClassRoom] = useState(undefined);

    const [loadingState, setLoadingState] = useState(1);

    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });
    const [schoolSelect, setSchoolSelect] = useState();

    const [revenueStats, setRevenueStats] = useState({});


    const revenueReportTitles = ["School Name", "Kid Name", "Class Room", "Invoice Title", "Invoice Number", "Invoice Amount", "Revenue Per Month", "Paid Revenue", "Pending Revenue", "Status", "Fee Type", " Program Type", "Revenue Start Date", "Revenue End Date"];


    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);



    const getPaidStatus = (status) => {
        if (status === 1) {
            return "Outstanding";
        } else if (status === 2) {
            return "Verified";
        } else {
            return "Success";
        }
    };

    const getInvoicePaidStatus = (status) => {
        if (status === 1) {
            return "Paid";
        } else if (status === 3) {
            return "Partially Paid";
        } else {
            return "Pending";
        }
    };


    const getRevenueReport = () => {

        setLodingState(1)
        var startDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth(), 1);
        var endDate = new Date(selectedMonth.getFullYear(), selectedMonth.getMonth() + 1, 0, 23, 59);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {

                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('MMM YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }

        let query;

        let query1 = new Parse.Query("Invoice");
        let query2 = new Parse.Query("Invoice");

        query1.lessThanOrEqualTo('revenueStartDate', startDate);
        query1.greaterThanOrEqualTo("revenueEndDate", endDate);
        // query2.greaterThanOrEqualTo('revenueEndDate', startDate)
        // query = Parse.Query.or(query1, query2)
        query = query1;
        query.notEqualTo('isDeleted', true);

        query.limit(10000)
        let schoolIds = [];
        if (props.isFranchise) {

            if (selectedSchool && selectedSchool.value != 'allSchools') {
                schoolIds.push(selectedSchool.value)
                query.equalTo('schoolId', schoolIds[0])
            } else {
                for (const school of props.schools) {
                    schoolIds.push(school.id)
                }
                query.containedIn('schoolId', schoolIds)
            }

        } else {
            query.equalTo('schoolId', schoolId)
        }
        query.ascending("createdAt");
        query.find().then((invoiceList) => {
            // console.log(invoiceList)
            if (!invoiceList || invoiceList.length === 0) {
                setLoadingState(5)
            } else {
                setInvoice(invoiceList);

                let kidIds = [];
                for (const kid of invoiceList) {
                    if (!kidIds.includes(kid.attributes.recipientId)) {
                        kidIds.push(kid.attributes.recipientId);
                    }
                }

                let queryKid = new Parse.Query("Kid");
                queryKid.containedIn("objectId", kidIds);
                queryKid.limit(10000);

                queryKid.find().then((kids) => {
                    // console.log(kids);
                    setKid(kids);

                    let classRoomIds = [];
                    for (const kid of kids) {
                        classRoomIds.push(kid.attributes.ClassRoomID)
                    }

                    let queryClassRoom = new Parse.Query("ClassRoom");
                    queryClassRoom.containedIn("objectId", classRoomIds);
                    queryClassRoom.limit(10000);

                    queryClassRoom.find().then((classRooms) => {
                        // console.log(classRooms);

                        prepareRevenueReport(invoiceList, kids, classRooms);
                    });
                });
            }
        });
    }

    const prepareRevenueReport = (invoiceList, kids, classRooms) => {
        let dataList = [];

        let totalRevenue = 0
        let totalPaidRevenue = 0;

        for (const school of props.schools) {

            let schoolsInvoice = invoiceList.filter(
                (invoice) => invoice.attributes.schoolId === school.id
            );

            for (const invoice of schoolsInvoice) {

                let kid = kids.filter(
                    (i) => i.id === invoice.attributes.recipientId);


                let classRoom = classRooms.filter(
                    (c) => c.id === kid[0].attributes.ClassRoomID);


                let rsd = invoice.attributes.revenueStartDate;
                let red = invoice.attributes.revenueEndDate;


                let loop = true
                let newDate = new Date(rsd.getFullYear(), rsd.getMonth() - 1, 1);
                var newEndDate = new Date(red.getFullYear(), red.getMonth(), 0, 23, 59);
                let dateList = [];
                while (loop) {
                    if (newDate < newEndDate) {

                        newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                        let dateString = Moment(newDate).format('MMM YYYY')
                        dateList.push({ date: newDate, dateString: dateString })
                    } else {
                        loop = false;
                    }
                }

                let month = Moment(rsd).format('MMM YYYY')
                let selectedMonthData = undefined
                let numberOfMonths = dateList.length;
                let revenuePerMonth = parseFloat(invoice.attributes.amount) / numberOfMonths;
                revenuePerMonth = revenuePerMonth.toFixed(2);
                totalRevenue += parseFloat(revenuePerMonth)

                let paidAmount = invoice.attributes.paidAmount ?? 0;

                for (let i = 0; i < numberOfMonths; i++) {

                    let paid = (revenuePerMonth <= paidAmount) ? revenuePerMonth : paidAmount;
                    let unpaid = revenuePerMonth - paid;

                    paidAmount -= paid;

                    // paidnUnpaid.push({
                    //     paid: paid.toFixed(2),
                    //     unpaid: unpaid.toFixed(2)
                    // });
                    dateList[i].paid = paid;
                    dateList[i].unpaid = unpaid;
                    if (month == dateList[i].dateString) {
                        selectedMonthData = {
                            paid: paid,
                            unpaid: unpaid,
                            revenue: revenuePerMonth,
                            status: unpaid == 0 ? "Paid" : (unpaid == revenuePerMonth ? "Pending" : "Partially Paid")
                        }
                        totalPaidRevenue += parseFloat(paid);
                    }
                }

                dataList.push({
                  invoice: invoice,
                  kid: kid[0],
                  classRoom: classRoom[0],
                  selectedMonthData: selectedMonthData,

                  invoiceData: [
                    school.attributes.Name,
                    kid && kid.length>0 &&kid[0].attributes.Name,
                    classRoom && classRoom.length>0 && classRoom[0].attributes.Name,
                    invoice.attributes.title,
                    invoice.attributes.invoiceNumber,
                    invoice.attributes.amount,
                    revenuePerMonth,
                    selectedMonthData.paid,
                    selectedMonthData.unpaid,
                    selectedMonthData.status,
                    kid[0].attributes.feeType,
                    kid[0].attributes.programType,
                    Moment(rsd).format("MMM YYYY"),
                    Moment(red).format("MMM YYYY"),
                  ],
                });
                // console.log(dataList)

            }
        }
        setRevenueStats({
            totalRevenue: totalRevenue,
            totalPaidRevenue: totalPaidRevenue,
            totalPendingRevenue: totalRevenue - totalPaidRevenue,
        })
        SetRevenueReportData(dataList);
        setLoadingState(3)
    }

    const revenueReportDownload = () => {
        let month = Moment(selectedMonth).format('MMM-YYYY')

        let finalData = [];
        let fileName = `Revenue_report_for${month}.xlsx`;

        finalData.push([`Revenue report for ${schoolSelect}`]);
        finalData.push([`Selected Month : ${month}`,]);
        finalData.push([`Total Invoices : ${revenueReportData.length}`,]);
        finalData.push([`Total Revenue : ${revenueStats.totalRevenue}`,]);
        finalData.push([`Total Paid Revenue : ${revenueStats.totalPaidRevenue}`,]);
        finalData.push([`Total unpaid Revenue : ${revenueStats.totalPendingRevenue}`,]);

        finalData.push([]);
        finalData.push(revenueReportTitles);

        for (const rd of revenueReportData) {
            let temp = rd.invoiceData;
            finalData.push(temp)
        }
        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);

            XLSX.utils.book_append_sheet(wb, ws, "Revenue Report");

            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;


            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            XLSX.utils.book_append_sheet(wb, ws, "Revenue Report");
            XLSX.writeFile(wb, fileName);
        }
    }


    const getIndexForInvoiceList = () => {
        let list = [];
        for (const i in revenueReportData) {
            list.push(i);
        }
        return list;
    }

    function getDateTimeStringForDate(date) {
        return Moment(date).format('MMM-YYYY');
    }

    return (
        <React.Fragment>
            <Row className="bg-light">
                <Col lg="5">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("school")} :</Label>

                        <Select
                            value={selectedSchool}
                            onChange={(v) => {
                                setSelctedSchool(v)
                            }}
                            options={allSchools}
                            className="selectPaymentType"
                            classNamePrefix="selectPayType"

                        />
                    </FormGroup>
                </Col>
                <Col lg={5}>
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label >
                            Select Month:
                        </Label>
                        <DatePicker
                            selected={selectedMonth}
                            onChange={(date) => {
                                setSelectedMonth(date);
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            dateFormat="MMM-yyyy"
                            showMonthYearPicker
                            selectsStart
                        />
                    </FormGroup>
                </Col>
                <Col lg={2} >
                    <div className="mt-4 p-3">
                        <button
                            disabled={loadingState === 2}
                            onClick={(e, v) => {
                                getRevenueReport()
                                setSchoolSelect(selectedSchool.label)
                            }}
                            className="w-md  btn btn-primary"
                        >
                            {props.t("Apply")}
                        </button>

                    </div>
                </Col>
            </Row>
            {(loadingState == 0 || loadingState == 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "Setting up..." : "Preparing report, it may take more time than anticipated"}</Label>
                    </div>
                </Col>
            }

            <Element name={"scrollPossition"} >
                <div></div>
            </Element>

            {loadingState === 5 && <Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img mt-8">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{'No data avilable for ' + getDateTimeStringForDate(selectedMonth) + " month "}</h3>
                    <p>{'Plese select other month'}.</p>
                </CardBody>
            </Card>}

            {loadingState == 3 && revenueReportData && <Card>
                <CardBody>
                    <CardHeader><CardTitle> Revenue Report Summary </CardTitle> </CardHeader>

                    <Row className='ml-1'>
                        <Col lg={9}>
                            <div className='py-2'>
                                <Table className=" table mb-0 table-bordered table-sm" style={{ width: '500px' }}>
                                    <tbody>
                                        <tr>
                                            <th scope="row" style={{ width: "200px" }}>Report For </th>
                                            <td scope="row" style={{ width: "300px" }}>{schoolSelect}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "200px" }}>Total Invoices </th>
                                            <td scope="row" style={{ width: "300px" }}>{revenueReportData.length}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "200px" }}>Total Revenue </th>
                                            <td scope="row" style={{ width: "300px" }}>{revenueStats.totalRevenue}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "200px" }}>Total Paid Revenue </th>
                                            <td scope="row" style={{ width: "300px" }}>{revenueStats.totalPaidRevenue}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "200px" }}>Total Pending Revenue</th>
                                            <td scope="row" style={{ width: "300px" }}>{revenueStats.totalPendingRevenue.toFixed(2)}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </div>
                        </Col>
                        <Col lg={3}>
                            <div className="mt-2 mb-2 p-1 text-center d-flex justify-content-end ">
                                <Button
                                    className='btn btn-primary float-right mb-3'
                                    color='primary'
                                    onClick={() => {
                                        if (revenueReportData && revenueReportData.length > 0) {
                                            revenueReportDownload();
                                        }
                                    }}>
                                    <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                    Download Report
                                </Button>
                            </div>
                        </Col>
                    </Row>


                    {loadingState == 3 && <Col xs="12">
                        <CardHeader><CardTitle> Revenue Report </CardTitle> </CardHeader>
                        <div className="table-rep-plugin py-3">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                                    <Thead>
                                        <Tr>


                                            <Th>#</Th>
                                            {revenueReportTitles.map((t, k) => (
                                                <Th key={k} data-priority={k}>{t}</Th>
                                            ))}

                                        </Tr>
                                    </Thead>
                                    <Tbody>

                                        {
                                            revenueReportData && getIndexForInvoiceList().map((i, key) => (
                                                <Tr key={key}>
                                                    <Th >{parseInt(i) + 1}</Th>
                                                    {revenueReportData[i].invoiceData.map((e, key2) => (
                                                        <Td key={key2}>{e}</Td>
                                                    ))}

                                                </Tr>
                                            ))
                                        }

                                    </Tbody>
                                </Table>
                            </div>
                        </div>

                    </Col>
                    }

                </CardBody>
            </Card>}

        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners } = state.CorporatePartners;
    return { schools, selectedSchool, franchise, corporatePartners };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool, getFranchise, getCorporatePartners
        })(RevenueReport)
    )
);
