import Parse from "parse";
import { printLog } from "./loghelper";

export const getCorporatePartners = (id, type) => {
  printLog("HL: corporatePartners_helper : getCorporatePartners")

  try {
    return new Promise((resolve, reject) => {
      var query = new Parse.Query("CorporatePartners");
      query.equalTo(type, id);
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    })
  } catch (error) { }
}

export const getCorporatePartnerFromParse = (corporatePartnerId) => {
  try {
    return new Promise((resolve, reject) => {
      var CorporatePartner = Parse.Object.extend("CorporatePartners");
      var query = new Parse.Query(CorporatePartner);
      query.get(corporatePartnerId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const getKidsForCorporatePartners = (corporateId, isLoacal) => {
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Kid");
      query.equalTo("corporateId", corporateId);
      query.notEqualTo("Deleted", true);
      if (isLoacal) {
        query.fromLocalDatastore();
      }
      query.find().then((result) => {
        resolve(result);
      },
      (error) => {
        reject(null);
      }
      );
    });
  } catch (err) {
  }
};

export const saveCorporatePartner = (object) => {
  printLog("HL: corporatePartners_helper : saveCorporatePartner")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log("saveCorporatePartner", error)
          reject(error);
        }
      );

    });
  } catch (error) { }
}