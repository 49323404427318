import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, Form, CardBody, Button } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import classnames from 'classnames';
import SweetAlert from "react-bootstrap-sweetalert";
import Image from "../../assets/images/getStartedImages/groupInitial.svg";



import { getGroupsForSchoolId, deleteGroup, setSelecetdSchool } from '../../store/actions';
import * as Constants from '../../Constents';

const GroupDetailPage = (props) => {



  const [displayList, setDisplayList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [groupToDelete, setGroupToDelete] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const match = matchPath(props.history.location.pathname, {
    path: "/groupdetail/:id",
    exact: true,
    strict: false,
  });

  const schoolId = match.params.id;

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    props.getGroupsForSchoolId(schoolId)
  }, [schoolId]);

  useEffect(() => {
    if (props.groups) {
      var temp = [...props.groups]
      setDisplayList(temp)
      setIsLoading(false)
    }
  }, [props.groups, props.deletedSuccessfully]);


  const handleDeleteGroup = () => {
    if (groupToDelete != null) {
      var temp = groupToDelete
      temp.set("deleted", true)
      props.deleteGroup(temp)
      setShowDeleteAlert(false)
    }
  }


  return (

    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Row className='d-flex align-items-start'>
            <Col className="d-flex align-items-start">
              <div className=' mb-3'>
                <h4 className="m-0">GROUPS</h4>
              </div>
            </Col>
            <Col className='d-flex justify-content-end'>
              <div className="mt-4 mt-sm-0 d-flex justify-content-end mb-2" style={{ marginTop: '30px'}}>
                <Link
                  className="btn btn-primary waves-effect waves-light "
                  to={"/addGroup/" + schoolId}
                  role="button"
                >
                  Add Group
                </Link>
              </div>
            </Col>
          </Row>
          <Card>
            {showDeleteAlert && <SweetAlert
              warning
              showCancel
              confirmBtnText="Yes"
              cancelBtnText="No"
              confirmBtnBsStyle="danger"
              cancelBtnBsStyle="success"
              title="Alert!"
              onCancel={() => {
                setShowDeleteAlert(false);
              }}
              onConfirm={() => handleDeleteGroup()}
            >
              Are you sure to delete this group?<br></br>
            </SweetAlert>}
            <CardBody>



              {displayList.length > 0 && <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="thead-light">
                    <tr>


                      <th>{props.t('Name')}</th>

                      <th style={{ width: "20px" }}>
                        {props.t('View Details')}
                      </th>
                      <th style={{ width: "20px" }}>
                        {props.t('Edit')}
                      </th>
                      <th style={{ width: "20px" }}>
                        {props.t('Delete')}
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {
                      displayList.map((group, key) =>
                        <tr key={key}>
                          <td>
                            <Label className="font-size-12 mb-1"><Link to={"/group-details/" + schoolId + "/" + group.id} className="text-dark"> {group.attributes.name}</Link></Label>
                          </td>
                          <td>
                            <Link to={"/group-details/" + schoolId + "/" + group.id}>
                              <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light">{props.t('View Details')}</Button>
                            </Link>
                          </td>


                          <td>

                            <div className="flex-fill text-center ">
                              <Link
                                to={"/editgroup/" + schoolId + "/" + group.id}
                                id="profile-edit-school"
                              >
                                <i className="bx bx-edit-alt"></i>

                              </Link>
                            </div>

                          </td>

                          <td>
                            <div
                              className="flex-fill text-center"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setGroupToDelete(group)
                                setShowDeleteAlert(true)
                              }}

                            >
                              <i className="bx bxs-trash text-danger"></i>
                            </div>
                          </td>

                        </tr>
                      )
                    }
                  </tbody>
                </table>
              </div>}
              {displayList.length === 0 &&
                // <p className="text-center">No groups to display !!</p>
                <div className="text-center" >


                  <Row className="justify-content-center">
                    <Col sm={4}>
                      <div className="maintenance-img">
                        <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                      </div>
                    </Col>


                  </Row>
                  <h3 className="mt-5">{props.t('Create groups for your school')}</h3>
                  <p>{props.t('create a new group just in few steps')}.</p>

                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to={"/addGroup/" + schoolId}
                    role="button"
                  >
                    Add Group
                  </Link>
                </div>

              }

            </CardBody >
          </Card >

        </Container >
        {
          isLoading && <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Group details... </Label>
              </div>
            </Col>
          </Row>
        }
      </div >
    </React.Fragment >

  );
}

const mapStatetoProps = state => {
  const { groups, deletedSuccessfully } = state.Group;
  const { selectedSchool } = state.School;
  return { groups, deletedSuccessfully, selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getGroupsForSchoolId, deleteGroup, setSelecetdSchool })(GroupDetailPage)));
