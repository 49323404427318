import React, { useEffect, useState } from "react";
import {
  Modal,
  Label,
  Button,
  Row,
  Col,
  Card,
  ModalBody,
  Alert,
} from "reactstrap";
import Parse from "parse";

import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { showAdPopup, setSelecetdSchool } from "../../store/actions";

import idCard from "../../assets/images/featurePopup/id-card.png";
import cctv from "../../assets/images/featurePopup/cctv-camera.png";
import trips from "../../assets/images/featurePopup/transportation.png";
import socialMedia from "../../assets/images/featurePopup/social-media.png";

const PopupView = (props) => {

  const ICTFeature = [

    
    {
      id: 2, title: "CCTV",
      description: "Get customised live CCTV streaming camera in a single click!",
      img: cctv,
      link: "",
    },
    {
      id: 3, title: "Trips",
      description: "Get your vehicle tracker with ease and secured in a single click!",
      img: trips,
      link: "",
    },
    {
      id: 4, title: "Digital Marketing",
      description: "Exclusive Digital marketing services for schools and institutes!",
      img: socialMedia,
      link: "",
    },
    // {
    //   id: 1, title: "Id Card",
    //   description: "Get your School/Institute Id cards hassle free in a single click!",
    //   img: idCard,
    //   link: "",
    // },
  ]
  const [successAlert, setSuccessAlert] = useState(false);
  const [showLoading, setShowLoading] = useState(0);
  const schoolId = localStorage.getItem("SELECTED_SCHOOL_ID");
  useEffect(() => {
    if (Parse.User.current() && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  const handelEnquireNow = (title) => {

    if (Parse.User.current() && props.selectedSchool) {
      // setShowLoading(title);
      const lilPackageLeads = Parse.Object.extend("lilPackageLeads");
      const packageLeads = new lilPackageLeads();
      packageLeads.set("schoolName", props.selectedSchool.attributes.Name);
      packageLeads.set("email", props.selectedSchool.attributes.EMail);
      packageLeads.set("phoneNumber", props.selectedSchool.attributes.primaryPhoneNumber);
      packageLeads.set("location", props.selectedSchool.attributes.city);
      packageLeads.set("schoolId", props.selectedSchool.id);

      packageLeads.set("packageSelected", title);


      if (Parse.User.current().attributes.teacherId) {
        packageLeads.set("userId", Parse.User.current().attributes.teacherId);
      } else {
        packageLeads.set("userId", Parse.User.current().id);
      }

      packageLeads.save().then(
        (result) => {
          setSuccessAlert(true)
          // setShowLoading(0)
          
          // localStorage.setItem("DONT_SHOW_PRICING_POPUP_AGAIN", "true");
          // localStorage.setItem("SHOW_PRICING_POPUP", "nono");
          // props.showPricingPopup(false);
        },
        (error) => {

        }
      );

    } else {
      //show popup
      // setShowUserInfoPopup(pricingObject);

    }
  }

  

  return (
    <Row>
      <Modal
        isOpen={props.adPopup}
        toggle={() => {
          props.showAdPopup(!props.adPopup);
        }}
        size="xl"
      >
        {/* <div className="modal-header">
        <h5 className="modal-title mt-0">
          New Features
        </h5>
        <button
          type="button"
          onClick={() => {
            props.tog_standard();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div> */}
        
        <ModalBody>
          <div className="modal-body" style={{ background: "#f7f7fa" }}>
            <div className="text-center ">
              <button
                type="button"
                onClick={() => {
                  localStorage.setItem("DONT_SHOW_AD_POPUP", "true");
                  localStorage.setItem("AD_POPUP_LAST_SHOWN", "nono");
                  props.showAdPopup(false);
                }}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>

              <h4>New Features</h4>
              <p className="text-muted">Along with current features, After thorough study and analysis lilTriangle offering new and exciting features to make your school to stand out and become choice of parents. Please select feature or connect to our experts at lilTriangle for more details.</p>
              
              {successAlert && <Alert colr="success" > Thank you for showing interest, One of our executive will  be in touch with you soon.
                <Label
                  className="ml-5"
                  onClick={() => {
                    localStorage.setItem("DONT_SHOW_AD_POPUP", "true");
                    props.showAdPopup(false);
                  }}
                >Close</Label> </Alert>
              }

              <Row>
                {ICTFeature.map((feature, key) => (
                  <Col lg={4} md={4} key={key}>
                    <Card className="shadow p-2"  >
                    <div className="text-center mt-2 ">
                      <img src={feature.img} alt="" height="90" />
                    </div>
                    <div className="text-center mt-2">
                          <h5 className="text-primary mt-2">{feature.title}</h5>
                        <p className="text-muted mt-2 ">{feature.description}</p>
                    </div>
              
                    <div className="">
                        <button className="btn-rounded btn btn-sm btn-primary mb-2"
                          onClick={() => {
                            handelEnquireNow(feature.title)
                            // showLoading = { showLoading }
                          }}
                          >
                            Enquire Now
                          </button>
                     </div>
                   </Card>
                </Col>
            
                ))}
              </Row>
            </div>
            
          </div>
        </ModalBody>
      </Modal>
    </Row>
  );

};

const mapStatetoProps = (state) => {
  const { adPopup } = state.Layout;
  const { selectedSchool } = state.School;
  return {
    adPopup,
    selectedSchool,
  };
};

export default connect(mapStatetoProps, {
  showAdPopup,
  setSelecetdSchool,
})(withNamespaces()(PopupView));
