import React from "react";
import { Container, Row, Col, Button } from "reactstrap";
import * as WhiteLable from "../Common/WhiteLable";

const Footer = (props) => {
  return (
    <React.Fragment>
      <footer className="footer">
        <Container fluid={true}>
          <Row>
            <Col md={4}>{new Date().getFullYear()} © {WhiteLable.getFooterName()}.</Col>
            <Col md={4}>
              {WhiteLable.getFooterName()  == 'lilTriangle' && <div className="text-center">
                <p className="mb-0 mt-0">Follow us </p>
                <a href=" https://www.linkedin.com/company/lil-triangle/" target="_blank"><i className="mdi mdi-linkedin m-1 font-size-16"></i></a>
                <a href=" https://www.facebook.com/lilTriangle/" target="_blank"><i className="mdi mdi-facebook m-1 font-size-16"></i></a>
                <a href=" https://www.instagram.com/liltriangleapp/" target="_blank"><i className="mdi mdi-instagram m-1 font-size-16"></i></a>
                <a href=" https://mobile.twitter.com/triangle_lil" target="_blank"><i className="mdi mdi-twitter m-1 font-size-16"></i></a>
              </div>}
            </Col>
            <Col md={4}>
              <div className="text-sm-end d-none d-sm-block float-end">
                Designed & Developed by lilTriangle
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </React.Fragment>
  );
};

export default Footer;
