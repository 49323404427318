import * as actions from "./actionTypes";

const initialState = {
    feeTemplates: [],
    showCreateLoader : false,
    showDeleteLoader : false,
    feeTypes: [],
};

const FeeTemplate = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_FEE_TEMPLATE:
      var feeTemplates = [...state.feeTemplates];
      var temp = state.feeTemplates.filter(
        (feetemplate) => feetemplate.id === action.payload.feeTemplate.id
      );
      if (temp.length > 0) {
        var index = feeTemplates.indexOf(temp[0]);
        feeTemplates[index] = action.payload.feeTemplate;
        state = { ...state, feeTemplates: feeTemplates };
      } else {
        var list = [action.payload.feeTemplate];
        state = { ...state, feeTemplates: list.concat(feeTemplates) };
      }
      break;
    case actions.UPDATE_FEE_TEMPLATES:
      var feeTemplates = [...state.feeTemplates];
      if (feeTemplates.length > 0) {
        for (const latest in action.payload.feeTemplates) {
          let temp = feeTemplates.filter(
            (learningCategory) =>
              learningCategory.id === action.payload.feeTemplates[latest].id
          );
          if (temp.length > 0) {
            if (action.payload.feeTemplates[latest].attributes.deleted) {
              var others = feeTemplates.filter(
                (ft) =>
                  ft.id !== action.payload.feeTemplates[latest].id
              );
              feeTemplates = others;
            } else {
              var index = feeTemplates.lastIndexOf(temp[0]);
              feeTemplates[index] = action.payload.feeTemplates[latest];
            }
          } else {
            if (!action.payload.feeTemplates[latest].attributes.deleted) {
              feeTemplates.unshift(action.payload.feeTemplates[latest]);
            }
          }
        }
        state = { ...state, feeTemplates: feeTemplates };
      } else {
        var others = action.payload.feeTemplates.filter(
          (ft) => (!ft.attributes.deleted)
        );
        state = { ...state, feeTemplates: others };
      }
      break;
    case actions.DELETE_FEE_TEMPLATE:
      var feeTemplates = state.feeTemplates.filter(
        (feetemplate) => feetemplate.id !== action.payload.id
      );
      state = { ...state, feeTemplates: feeTemplates };
      break;
    case actions.CREATE_FEETEMPLATE_FOR_SCHOOLID:
      state = {...state, showCreateLoader : true}
      break;
    case actions.SET_LOADER_OFF :
      state = { ...state, showCreateLoader : false, }
      break;
    case actions.DELETE_A_TEMPLATE_FOR_SCHOOL :
      state = { ...state, showDeleteLoader : true}
      break;
    case actions.UPDATE_AFTER_DELETE :
      let tempTemplate = Object.assign([], state.feeTemplates)
      for(const i in tempTemplate){
        if(tempTemplate[i].id === action.payload.Obj.id){
          tempTemplate.splice(i, 1)
          break;
        }
      }
      state = {...state, feeTemplates : tempTemplate, showDeleteLoader : true}
      break;
    case actions.SET_FEETYPE :
      state = {...state, feeTypes: action.payload.feeTypeObjs}
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default FeeTemplate;
