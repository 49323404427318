import React, { useState,} from "react";
import { Row, Col, Card, CardBody, } from "reactstrap";
import { Link } from "react-router-dom";
import profileImg from "../../assets/images/profile-img.png";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import ReferSchool from './ReferSchool'
import * as WhiteLable from "../../components/Common/WhiteLable"


const WelcomeComp = (props) => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  return (
    <React.Fragment>
      <Card className="overflow-hidden">
        <div className="bg-soft-primary">
          <Row>
            <Col xs="7">
              <div className="text-primary p-3">
                <h5 className="text-primary">
                  {props.selectedSchool && props.selectedSchool.attributes
                    ? props.selectedSchool.attributes.Name
                    : ""}
                </h5>
                <p className="mb-0">
                  {props.selectedSchool && props.selectedSchool.attributes
                    ? props.selectedSchool.attributes.city
                    : ""}
                </p>
              </div>
            </Col>
            <Col xs="5" className="align-self-end">
              <img src={profileImg} alt="" className="img-fluid" />
            </Col>
          </Row>
        </div>
        <CardBody className="pt-0">
          
          <Row>
            <Col>
              <div className="pt-4">
                <Row>
                  <Col xs="6">
                    <h5 className="font-size-15">{props.selectedSchool && props.selectedSchool.attributes.schoolType==10 ?"Course Coordinator": "Staff"}</h5>
                    <p className="text-muted mb-0">
                      {props.teachers != null ? props.teachers.length : "-"}
                    </p>
                  </Col>
                  <Col xs="6">
                    <h5 className="font-size-15">{props.selectedSchool && props.selectedSchool.attributes.schoolType==10 ?"Participants":"Students"}</h5>
                    <p className="text-muted mb-0">
                      {props.kids != null ? props.kids.length : "-"}
                    </p>
                  </Col>
                </Row>
                <div className="mt-4">
                  <Link
                    to={"/school/" + props.schoolId}
                    className="btn btn-primary waves-effect waves-light btn-sm"
                  >
                    View Profile <i className="mdi mdi-arrow-right ml-1"></i>
                  </Link>
                  {!WhiteLable.isAdigurkulApp() && !WhiteLable.isSanfortApp() && !WhiteLable.isJumpStartApp() ? (<button className="text-center btn btn-primary waves-effect waves-light btn-sm px-2 d-flex float-end" onClick={toggleModal}>Refer School</button>) : null}
                </div>
              </div>
            </Col>
          </Row>
        </CardBody>
      </Card>
      <ReferSchool isOpen={modalOpen} toggleModal={toggleModal} />
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { kids } = state.Kid;
  const { teachers } = state.Teacher;

  return {
    teachers,
    selectedSchool,
    kids,
  };
};

export default withRouter(connect(mapStatetoProps, {})(withNamespaces()(WelcomeComp)));
