import React, { useEffect, useState } from 'react'
import { Col, Row, Button, Card, CardBody, Label, CardHeader, CardText, CardTitle } from 'reactstrap'
import Topbar from '../Topbar';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Parse from 'parse';
import SweetAlert from "react-bootstrap-sweetalert";
import { setSelecetdSchool, getInventoryVendorForOwnerId, deleteInventoryVendor, updateDeleteInventoryVendor } from '../../../store/actions';


const AllVendor = (props) => {

  const match = matchPath(props.history.location.pathname, {
    path: "/inventory-vendor/:id",
    exact: true,
    strict: false,
  });

  const franchiseMatch = matchPath(props.history.location.pathname, {
    path: "/franchise-inventory-vendor/:franchiseId",
    exact: true,
    strict: false,
  });

  const schoolId = match && match.params.id;
  const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId

  const [showDelete, setShowDelete] = useState(false);
  const [deleteObject, setDeleteObject] = useState(undefined);


  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    if (schoolId) {
      props.getInventoryVendorForOwnerId(schoolId, 'school')
    }
  }, [schoolId]);

  useEffect(() => {
    if (franchiseId) {
      props.getInventoryVendorForOwnerId(franchiseId, 'franchise')
    }
  }, [franchiseId]);

  console.log(props.inventoryVendors);


  const handelDeleteVendor = () => {

    props.deleteInventoryVendor(deleteObject)

    setShowDelete(false);
  };


  return (
    <div className="page-content">
      <Topbar
        title={"All Vendor"}
        buttonName={"Add Vendor"}
        link={schoolId ? `/add-inventory-vendor/${schoolId} ` : `/franchise-add-vendor/${franchiseId}`}
        hideButton={false}
      />
      {showDelete && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Delete"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setShowDelete(false);
          }}
          onConfirm={() => handelDeleteVendor()}
        >
          {"Are you sure you want to delete"}
          <br></br>
        </SweetAlert>
      )}
      <div>
        {props.inventoryVendors && props.inventoryVendors.length > 0 ? (<Card>
          <CardBody>
            <div className="table-responsive">
              <table className="table table-centered table-nowrap mb-0">
                <thead className="thead-light">
                  <tr>
                    <th>#</th>
                    <th>Name</th>
                    <th>Contact details</th>
                    <th style={{ width: "20px" }}>
                      Edit
                    </th>
                    <th style={{ width: "20px" }}>
                      Delete
                    </th>
                  </tr>
                </thead>

                {console.log(props.inventoryVendors)}

                <tbody>
                  {
                    props.inventoryVendors.map((vendor, key) =>
                      <tr key={key}>
                        <td>{key + 1}</td>
                        <td>
                          <Label className="font-size-12 mb-1">{vendor.attributes.companyName}</Label>
                        </td>
                        <td>
                          <p className="text-muted mb-0">{vendor.attributes.vendorEmail}</p>
                          <p className="text-muted mb-0">{vendor.attributes.vendorPhone}</p>
                        </td>
                        <td>
                          <div className="flex-fill text-center ">
                            <Link to={match ? `/edit-inventory-vendor/${schoolId}/${vendor.id}` : `/franchise-edit-inventory-vendor/${franchiseId}/${vendor.id}`}>
                              <i className="bx bx-edit-alt"></i>
                            </Link>
                          </div>

                        </td>

                        <td>
                          <div
                            className="flex-fill text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              setDeleteObject(vendor)
                              setShowDelete(true);
                            }}
                          >
                            <i className="bx bxs-trash text-danger"></i>
                          </div>
                        </td>
                      </tr>
                    )
                  }
                </tbody>
              </table>
            </div>
          </CardBody>
        </Card>) :
          (props.inventoryVendors && props.inventoryVendors.length <= 0 && <Card className='text-center'>
            <CardBody>
              <CardHeader className='m-3'><b><h4>Add Vendor</h4></b></CardHeader>
              {/* <CardTitle>Bussiness is no fun without people</CardTitle> */}
              <CardText>
                Create and manage your contact, all in one place
              </CardText>
              <Link
                className="btn btn-primary waves-effect waves-light "
                to={schoolId ? `/add-inventory-vendor/${schoolId} ` : `/franchise-add-vendor/${franchiseId}`}
                role="button"
              >
                New vendor
              </Link>
            </CardBody>
          </Card>
          )}
      </div>
    </div>
  )
}
const mapStatetoProps = state => {
  const { selectedSchool } = state.School;
  const { inventoryVendors } = state.InventoryVendor;
  return { selectedSchool, inventoryVendors };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryVendorForOwnerId, deleteInventoryVendor, updateDeleteInventoryVendor })(AllVendor)));
