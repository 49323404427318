import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool } from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import Image from "../../../assets/images/growing_chart.svg";
import ConsolidatedReportChart from '../../MonthlyReports/Charts/ConsolidatedReportChart';
import RateOfIncreaseChart from '../../MonthlyReports/Charts/RateOfIncreaseChart';
import CummulativeChart from '../../MonthlyReports/Charts/CumulativeChart';
import ConversionTrend from '../../MonthlyReports/Charts/ConversionTrend';

const EnquiryAnalytics = (props) => {

    const schoolId = props.schoolId


    const [reportSummary, setReportSummary] = useState({
        enquiryCount: 0,
        admissionCount: 0,
    });

    const [reportData, setReportData] = useState(undefined);

    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);

    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);



    const [selectedMonths, setSelectedMonths] = useState([]);

    const [monthSchoolData, setMonthSchoolData] = useState([]);

    const [loadingState, setLoadingState] = useState(1);



    useEffect(() => {
        setFromDate(props.fromDate)
    }, [props.fromDate]);

    useEffect(() => {
        setToDate(props.toDate);
    }, [props.toDate]);

    useEffect(() => {
        if (props.onClickApply) {
            handelDateChange();
        }
    }, [props.onClickApply]);


    const handelDateChange = () => {
        setLoadingState(2);
        getReortReportSessions();
    }



    const getDayBetweenDates = (d1, d2) => {
        var diff = d2 - d1;
        return Math.floor(diff / 86400000);

    }


    const getReortReportSessions = () => {

        if (!fromDate || !toDate) {
            return;
        }

        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0, 23, 59);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {

                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('MMM YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }
        setSelectedMonths(dateList);

        let days = getDayBetweenDates(startDate, endDate);
        let query = new Parse.Query("EnquiryAnalytics");

        query.greaterThanOrEqualTo('enquiryDate', startDate);
        query.lessThanOrEqualTo('enquiryDate', endDate);
        query.notEqualTo('isDeleted', true);

        //query.limit(1000)


        let schoolIds = [];
        if (props.isFranchise) {
            for (const school of props.schools) {
                schoolIds.push(school.id)
            }
            query.containedIn('schoolId', schoolIds)
            query.limit(schoolIds.length * days + 1000);
        } else {
            query.equalTo('schoolId', schoolId)
            query.limit(days + 1000);
        }
        query.find().then((enquiryAnalyticsObjects) => {
            setReportData(enquiryAnalyticsObjects)
            if (!enquiryAnalyticsObjects || enquiryAnalyticsObjects.length === 0) {
                setLoadingState(5)
            }
        });
    }





    useEffect(() => {
        if (props.schools && props.schools.length > 0 && reportData && reportData.length > 0) {

            let enquiryCount = 0;
            let admissionCount = 0;

            let tBySource = {};
            let tByEnquiryFor = {};

            let allSourse = [];
            let allClass = [];

            for (const rd of reportData) {
                enquiryCount = enquiryCount + rd.attributes.newEnquiries;
                admissionCount = admissionCount + rd.attributes.newAdmissions;

                let objSource = rd.attributes.bySource
                for (const key in objSource) {
                    if (tBySource[key]) {
                        tBySource[key] = tBySource[key] + objSource[key];
                    } else {
                        tBySource[key] = objSource[key];
                    }
                }

                let objFor = rd.attributes.byEnquiredFor

                for (const tk in objFor) {
                    const key = tk === "undefined" ? "No info" : tk
                    if (tByEnquiryFor[key]) {
                        tByEnquiryFor[key] = tByEnquiryFor[key] + parseInt(objFor[tk] ? objFor[tk] : "0");
                    } else {
                        tByEnquiryFor[key] = parseInt(objFor[tk] ? objFor[tk] : "0");
                    }
                }
            }

            let tempSourceList = []
            for (const tk in tBySource) {
                const key = tk === "undefined" ? "No info" : tk
                let tempSourceObject = {}
                tempSourceObject.name = key
                tempSourceObject.enquiryCount = tBySource[tk]
                allSourse.push({ name: key });
                tempSourceList.push(tempSourceObject)
            }

            let tempEnquiredForList = []
            for (const key in tByEnquiryFor) {
                let tempEnquiredForObject = {}
                tempEnquiredForObject.name = key
                tempEnquiredForObject.enquiryCount = tByEnquiryFor[key]
                allClass.push({ name: key });
                tempEnquiredForList.push(tempEnquiredForObject)
            }

            setReportSummary({
                enquiryCount: enquiryCount,
                admissionCount: admissionCount,
                allSourse: allSourse,
                allClass: allClass,
                BySource: tempSourceList,
                ByEnquiryFor: tempEnquiredForList,

            })


            let monthsData = [];
            for (const i in selectedMonths) {
                let tempMonth = selectedMonths[i];
                let monthData = reportData.filter(
                    (session) => sameMonth(session.attributes.enquiryDate, selectedMonths[i].date)
                );


                let eCount = 0;
                let aCount = 0;

                let mBySource = {};
                let mByEnquiryFor = {};

                for (const t of monthData) {
                    eCount = eCount + t.attributes.newEnquiries;
                    aCount = aCount + t.attributes.newAdmissions;

                    let objSource = t.attributes.bySource
                    for (const tk in objSource) {
                        const key = tk === "undefined" ? "No info" : tk
                        if (mBySource[key]) {
                            mBySource[key] = mBySource[key] + parseInt(objSource[tk] ? objSource[tk] : "0");
                        } else {
                            mBySource[key] = parseInt(objSource[tk] ? objSource[tk] : "0");
                        }
                    }

                    let objFor = t.attributes.byEnquiredFor

                    for (const key in objFor) {
                        if (mByEnquiryFor[key]) {
                            mByEnquiryFor[key] = mByEnquiryFor[key] + parseInt(objFor[key] ? objFor[key] : "0");
                        } else {
                            mByEnquiryFor[key] = parseInt(objFor[key] ? objFor[key] : "0");
                        }
                    }

                }

                tempMonth.enquiryCount = eCount;
                tempMonth.admissionCount = aCount;
                tempMonth.mBySource = mBySource;
                tempMonth.mByEnquiryFor = mByEnquiryFor;

                monthsData.push(tempMonth);

            }
            setSelectedMonths(monthsData);


            // console.log(monthsData);

            let schoolMonth = [];
            let schools = props.isFranchise ? props.schools : [props.selectedSchool]
            for (const s in schools) {
                
                let school = schools[s];
                let schoolObj = { id: school.id, schoolName: school.attributes.Name }


                let sECount = 0;
                let sACount = 0;


                let preIAmount = 0;
                let preTAmount = 0;

                for (const i in selectedMonths) {
                    let tempMonth = { date: selectedMonths[i].date, dateString: selectedMonths[i].dateString };
                    let monthData = reportData.filter(
                        (session) => sameMonth(session.attributes.enquiryDate, selectedMonths[i].date) && session.attributes.schoolId === school.id
                    );

                    let eCount = 0;
                    let aCount = 0;

                    for (const t of monthData) {
                        eCount = eCount + t.attributes.newEnquiries;
                        aCount = aCount + t.attributes.newAdmissions;
                    }


                    sECount = sECount + eCount;
                    sACount = sACount + aCount;

                    tempMonth.rateOfInvoiceAmount = getRateOfIncrice(preIAmount, eCount);
                    tempMonth.rateOfTransactionAmount = getRateOfIncrice(preTAmount, aCount)

                    preIAmount = eCount;
                    preTAmount = aCount;

                    tempMonth.enquiryCount = eCount;
                    tempMonth.admissionCount = aCount;


                    schoolObj[selectedMonths[i].dateString] = tempMonth;
                }

                schoolObj.enquiryCount = sECount;
                schoolObj.admissionCount = sACount;
                schoolMonth.push(schoolObj);
            }
            setMonthSchoolData(schoolMonth);

            setLoadingState(3);

            scroller.scrollTo('scrollPossition', {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100,
            });
        }
    }, [props.schools, reportData]);


    const sameMonth = (d1, d2) => {
        if (d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) {
            return true;
        }
        return false;
    }

    const getConversionRate = (t1, t2) => {
        if (t1 === 0 || t2 === 0) {
            return "-- ";
        }
        let rate = t2 * 100 / t1;
        return rate.toFixed(2) + "%";
    }

    const getRateOfIncrice = (oldNumber, newNumber) => {
        if (oldNumber > 0) {
            var decreaseValue = oldNumber > newNumber ? oldNumber - newNumber : newNumber - oldNumber;
            let rate = (decreaseValue / oldNumber) * 100;
            return oldNumber > newNumber ? -1 * rate.toFixed(2) : rate.toFixed(2);
        } else {
            return undefined;
        }
    }

    const onClickDownload = () => {
        let sDate = selectedMonths[0].dateString;
        let eDate = selectedMonths[selectedMonths.length - 1].dateString;

        let finalData = [];
        let titles = ["School Name"]
        let fileName = `consolidated_enqiry_report_${sDate}_${eDate}.xlsx`;
        let sheetName = sDate + "-" + eDate;

        finalData.push([`Report Type : Enquiry Count and Admission count consolidated report`]);
        finalData.push([`Selected Month : ${sDate} to ${eDate}`,]);

        finalData.push([]);

        finalData.push([`Enquiry Count : ${reportSummary.enquiryCount} `,]);
        finalData.push([`Admission count : ${reportSummary.admissionCount} `,]);
        finalData.push([`Conversion Rate : ${getConversionRate(reportSummary.enquiryCount, reportSummary.admissionCount)}`,]);


        finalData.push([]);
        finalData.push([]);
        finalData.push(["Month wise summary"]);
        finalData.push(["Month", "Enquiry Count", "Admission count", "Conversion"]);
        for (const month of selectedMonths) {
            finalData.push([month.dateString, month.enquiryCount, month.admissionCount, getConversionRate(month.enquiryCount, month.admissionCount)]);
            titles.push(month.dateString);
        }
        finalData.push([]);
        finalData.push([]);

        let finalData2 = [];
        finalData2.push(["School wise summary"]);
        finalData2.push(["School Name", "Enquiry Count", "Admission count", "Conversion"]);

        for (const school of monthSchoolData) {
            finalData2.push([school.schoolName, school.enquiryCount, school.admissionCount, getConversionRate(school.enquiryCount, school.admissionCount)]);
        }
        finalData2.push([]);
        finalData2.push([]);

        let enquiryRateList = [];
        let admissionRateList = [];
        for (const school of monthSchoolData) {
            let eRowData = [];
            let aRowData = [];
            eRowData.push(school.schoolName);
            aRowData.push(school.schoolName);
            for (const month of selectedMonths) {

                eRowData.push(rateOfChangeReoprt(school[month.dateString].enquiryCount, school[month.dateString].rateOfInvoiceAmount));
                aRowData.push(rateOfChangeReoprt(school[month.dateString].admissionCount, school[month.dateString].rateOfTransactionAmount))
            }
            enquiryRateList.push(eRowData);
            admissionRateList.push(aRowData);
        }


        let finalData3 = [];
        finalData3.push(["New Enquiry Count Trend From " + sDate + " to " + eDate]);
        finalData3.push(titles);
        finalData3 = finalData3.concat(enquiryRateList);
        finalData3.push([]);
        finalData3.push([]);

        let finalData4 = [];
        finalData4.push(["Cumulative Admission count Trend From  " + sDate + " to " + eDate]);
        finalData4.push(titles);
        finalData4 = finalData4.concat(admissionRateList);

        let finalData5 = [];
        finalData5.push(["Enquiry Sourse Trend From " + sDate + " to " + eDate]);

        let finalData6 = [];
        finalData5.push(["Class Wise Trend From " + sDate + " to " + eDate]);


        let soursetitles = [...titles];
        soursetitles[0] = "Sourse";
        soursetitles.push("Total")
        finalData5.push(soursetitles);

        let calsstitles = [...titles];
        calsstitles[0] = "Class Name";
        calsstitles.push("Total");
        finalData6.push(calsstitles)


        for (const rsbs of reportSummary.BySource) {
            let sourseList = [];
            sourseList.push(rsbs.name);

            for (const sm of selectedMonths) {
                sourseList.push(sm.mBySource[rsbs.name] ? sm.mBySource[rsbs.name] : 0);
            }
            sourseList.push(rsbs.enquiryCount);
            finalData5.push(sourseList);
        }

        for (const rsbef of reportSummary.ByEnquiryFor) {
            let classlist = [];
            classlist.push(rsbef.name);

            for (const sm of selectedMonths) {
                classlist.push(sm.mByEnquiryFor[rsbef.name] ? sm.mByEnquiryFor[rsbef.name] : 0);
            }
            classlist.push(rsbef.enquiryCount);
            finalData6.push(classlist);
        }



        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            let ws2 = XLSX.utils.aoa_to_sheet(finalData2);
            let ws3 = XLSX.utils.aoa_to_sheet(finalData3);
            let ws4 = XLSX.utils.aoa_to_sheet(finalData4);
            let ws5 = XLSX.utils.aoa_to_sheet(finalData5);
            let ws6 = XLSX.utils.aoa_to_sheet(finalData6);
            XLSX.utils.book_append_sheet(wb, ws, "Month wise summary");
            XLSX.utils.book_append_sheet(wb, ws2, "School wise summary");
            XLSX.utils.book_append_sheet(wb, ws3, "Enquiry Count Trend");
            XLSX.utils.book_append_sheet(wb, ws4, "Admission count Trend");
            XLSX.utils.book_append_sheet(wb, ws5, "Enquiry Sourse Trend");
            XLSX.utils.book_append_sheet(wb, ws6, "Class Wise Trend");
            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;
            schoolData = schoolData.concat(finalData2);
            schoolData = schoolData.concat(finalData3);
            schoolData = schoolData.concat(finalData4);
            schoolData = schoolData.concat(finalData5);
            schoolData = schoolData.concat(finalData6);

            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
            XLSX.writeFile(wb, fileName);
        }


    }
    const rateOfChangeReoprt = (aVal, val) => {
        if (val > 0) {
            return aVal + " (" + val + "%↑)";

        } else if (val < 0) {
            return aVal + " (" + Math.abs(val) + "%↓)";
        } else {
            return "-";
        }
    }

    const rateOfChangeUI = (aVal, val) => {
        if (val > 0) {
            return (<p>{aVal}<span className="text-success">{" (" + val + "%↑)"}</span></p>)
        } else if (val < 0) {
            return (<p>{aVal}<span className="text-danger">{" (" + Math.abs(val) + "%↓)"}</span></p>)

        } else {
            return (<p>{aVal ? aVal : "--"}</p>)
        }
    }
    const onClickBackButton = () => {
        window.history.back();
    }

    return (
        <React.Fragment>
            {props.showTitle && <CardTitle>Enquiry Analytics Report</CardTitle>}
            {loadingState > 0 && props.showDatePickers &&
                <Col >
                    <Row className="justify-content-center bg-light ml-2">

                        <Row>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("From")} :
                                    </Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {
                                            setFromDateError(false)
                                            setFromDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        maxDate={toDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {fromDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("To")} :
                                    </Label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(date) => {
                                            setToDateError(false);
                                            setToDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        minDate={fromDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {toDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={3} >
                                <div className="mt-4 mb-2 p-1">
                                    <button
                                        disabled={loadingState === 2}
                                        onClick={(e, v) => {
                                            let next = true;
                                            if (!toDate) {
                                                setToDateError(true);
                                                next = false;
                                            }
                                            if (!fromDate) {
                                                setFromDateError(true);
                                                next = false;
                                            }
                                            if (next) {
                                                handelDateChange();
                                            }

                                        }}
                                        className="w-md  btn btn-primary"
                                    >
                                        {props.t("Apply")}
                                    </button>

                                </div>
                            </Col>
                        </Row>
                        {/* </Form> */}
                    </Row>
                </Col>
            }
            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "Setting up..." : "Preparing Report"}</Label>
                    </div>
                </Col>
            }


            <Element name={"scrollPossition"} >
                <div></div>
            </Element>


            {loadingState === 5 && <Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img mt-8">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{'No data avilable between ' + selectedMonths[0].dateString + " and " + selectedMonths[selectedMonths.length - 1].dateString}</h3>
                    <p>{'Plese select other dates '}.</p>


                </CardBody>
            </Card>}

            {
                loadingState == 3 && selectedMonths && selectedMonths.length > 0 &&
                <div>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Enquiries vs Admissions  </CardTitle>
                                    <ConsolidatedReportChart selectedMonths={selectedMonths} />
                                </CardBody>
                            </Card>

                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Enquiry to Admissions conversion rate </CardTitle>
                                    <RateOfIncreaseChart selectedMonths={selectedMonths} />
                                </CardBody>
                            </Card>

                        </Col>

                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Cumulative Enquiries and Admissions </CardTitle>
                                    <CummulativeChart selectedMonths={selectedMonths} />
                                </CardBody>
                            </Card>

                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Admissions growth rate </CardTitle>
                                    <ConversionTrend selectedMonths={selectedMonths} />
                                </CardBody>
                            </Card>

                        </Col>

                    </Row>
                </div>
            }

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && <Card>
                <CardBody>
                    <CardHeader>  <CardTitle> Report Summary </CardTitle> </CardHeader>
                    <Col lg={12}>
                        <div className="m-2 d-flex d-flex justify-content-end">
                            <button
                                onClick={() => {
                                    if (selectedMonths && selectedMonths.length > 0) {
                                        onClickDownload();
                                    }

                                }}
                                className="w-md  btn btn-primary"
                            >
                                <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                {props.t("Download Report")}
                            </button>
                        </div>
                    </Col>

                    <div className=''>
                        <h6 className="ml-2">
                            {"Selected Monts:" + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}
                        </h6>
                        <h6 className="ml-2">{"Total Enquiry Count:" + reportSummary.enquiryCount}</h6>
                        <h6 className="ml-2">{"Total Admission count:" + reportSummary.admissionCount}</h6>
                        <h6 className="ml-2">{"Conversion Rate:" + getConversionRate(reportSummary.enquiryCount, reportSummary.admissionCount)}</h6>
                    </div>

                    <CardTitle>Month wise summary </CardTitle>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th>Month</Th>
                                        <Th data-priority="1">Enquiry Count</Th>
                                        <Th data-priority="3">Admission count</Th>
                                        <Th data-priority="1">Conversion Rate</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>

                                    {
                                        selectedMonths.map((month, key) => (
                                            <Tr key={key}>
                                                <Th>{month.dateString}</Th>
                                                <Td>{month.enquiryCount}</Td>
                                                <Td>{month.admissionCount}</Td>
                                                <Td>{getConversionRate(month.enquiryCount, month.admissionCount)}</Td>

                                            </Tr>
                                        ))
                                    }
                                </Tbody>
                            </Table>
                        </div>
                    </div>


                    <CardTitle>School wise summary </CardTitle>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th>School Name</Th>
                                        <Th data-priority="1">Enquiry Count</Th>
                                        <Th data-priority="3">Admission count</Th>
                                        <Th data-priority="1">Conversion Rate</Th>

                                    </Tr>
                                </Thead>
                                <Tbody>

                                    {
                                        monthSchoolData.map((school) => (
                                            <Tr key={school.id}>
                                                <Th>{school.schoolName}</Th>
                                                <Td>{school.enquiryCount}</Td>
                                                <Td>{school.admissionCount}</Td>
                                                <Td>{getConversionRate(school.enquiryCount, school.admissionCount)}</Td>

                                            </Tr>
                                        ))
                                    }

                                </Tbody>
                            </Table>
                        </div>
                    </div>


                </CardBody>

            </Card>}

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && <Card>
                <CardBody>
                    <CardHeader><CardTitle>{"Enquiry Count Trend From " + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}</CardTitle></CardHeader>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th>School Name</Th>
                                        {
                                            selectedMonths.map((month, key) => (
                                                <Th data-priority="1" key={key}> {month.dateString} </Th>
                                            ))
                                        }
                                    </Tr>
                                </Thead>
                                <Tbody>

                                    {
                                        monthSchoolData.map((school) => (
                                            <Tr key={school.id}>
                                                <Th>{school.schoolName}</Th>

                                                {selectedMonths.map((month) => (
                                                    <Td key={school.id}>{rateOfChangeUI(school[month.dateString].enquiryCount, school[month.dateString].rateOfInvoiceAmount)}</Td>

                                                ))}
                                            </Tr>
                                        ))
                                    }

                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </CardBody>
            </Card>}

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && <Card>
                <CardBody>
                    <CardHeader><CardTitle>{"Admission Count Trend From " + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}</CardTitle></CardHeader>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th>School Name</Th>
                                        {
                                            selectedMonths.map((month, key) => (
                                                <Th data-priority="1" key={key}> {month.dateString} </Th>
                                            ))
                                        }
                                    </Tr>
                                </Thead>
                                <Tbody>

                                    {
                                        monthSchoolData.map((school) => (
                                            <Tr key={school.id}>
                                                <Th>{school.schoolName}</Th>

                                                {selectedMonths.map((month) => (
                                                    <Td key={school.id}>{rateOfChangeUI(school[month.dateString].admissionCount, school[month.dateString].rateOfTransactionAmount)}</Td>

                                                ))}
                                            </Tr>
                                        ))
                                    }

                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </CardBody>
            </Card>}

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && <Card>
                <CardBody>
                    <CardHeader><CardTitle>{"Enquiry by sourse from " + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}</CardTitle></CardHeader>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th>Sourse</Th>
                                        {
                                            selectedMonths.map((month, key) => (
                                                <Th data-priority="1" key={key}> {month.dateString} </Th>
                                            ))
                                        }
                                        <Th>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>



                                    {reportSummary.BySource.map((val, key) => (
                                        <Tr key={key}>
                                            <Th>{val.name}</Th>

                                            {selectedMonths.map((month, key) => (
                                                <Td key={key}>{month.mBySource[val.name] ? month.mBySource[val.name] : 0}</Td>

                                            ))}
                                            <Td>{val.enquiryCount}</Td>

                                        </Tr>
                                    ))}



                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </CardBody>
            </Card>}

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && <Card>
                <CardBody>
                    <CardHeader><CardTitle>{"Class Wise Enquiry count from " + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}</CardTitle></CardHeader>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th>Sourse</Th>
                                        {
                                            selectedMonths.map((month, key) => (
                                                <Th data-priority="1" key={key}> {month.dateString} </Th>
                                            ))
                                        }
                                        <Th>Total</Th>
                                    </Tr>
                                </Thead>
                                <Tbody>



                                    {reportSummary.ByEnquiryFor.map((val, key) => (
                                        <Tr key={key}>
                                            <Th>{val.name}</Th>

                                            {selectedMonths.map((month, key) => (
                                                <Td key={key}>{month.mByEnquiryFor[val.name] ? month.mByEnquiryFor[val.name] : 0}</Td>

                                            ))}
                                            <Td>{val.enquiryCount}</Td>

                                        </Tr>
                                    ))}



                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </CardBody>
            </Card>}



            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 &&
                <div className="mt-2 mb-2 p-1 d-flex justify-content-end">
                    <button
                        onClick={() => {
                            if (selectedMonths && selectedMonths.length > 0) {
                                onClickDownload();
                            }

                        }}
                        className="w-md  btn btn-primary"
                    >
                        <i className='bx bx-download font-size-16 align-middle m-1'></i>
                        {props.t("Download Report")}
                    </button>
                </div>
            }

            <div style={{ height: "400px" }}></div>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    return { schools, selectedSchool };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
        })(EnquiryAnalytics)
    )
);
