import React, { useState,useEffect } from "react";
import { Form, FormGroup, Card, CardBody, Col, Row, CardTitle, CardSubtitle, Container, Label, Button, Input } from "reactstrap";
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';


const PostSubscription = (props) => {
    const [content, setContent] = useState('');
    const [editorState, setEditorState] = useState();

    const [courseDescription, setCourseDescription] = useState();
    const [courseDescriptionError, setCourseDescriptionError] = useState();
    const [once, setOnce] = useState(true);

    useEffect(() => {
        if (props.courseObject && once) {
            setOnce(false)
            setCourseDescription(props.courseObject.attributes.postDescription)
            setContent(props.courseObject.attributes.postHighlights)

            if (props.courseObject.attributes.postHighlights) {
                const contentBlock = htmlToDraft(props.courseObject.attributes.postHighlights);
                const contentState = ContentState.createFromBlockArray(contentBlock.contentBlocks);
                const editorState = EditorState.createWithContent(contentState);
                setEditorState(editorState);
            }
        }
    }, [props.courseObject]);


    const onClickSave = (isNext) => {

        let save = true;
        
        // if (!courseDescription) {
        //     save = false;
        //     setCourseDescriptionError(true)
        // }
       
        
        if (save) {
            let tempObject = props.courseObject;
            tempObject.set("postDescription", courseDescription);

            tempObject.set("postHighlights", content);
            //tempObject.set("postHighlightsObject", editorState);
            props.onSaveCourseObject(tempObject);
        }

    }

    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Form method="post">
                        <FormGroup>
                            <Label>Description :</Label>
                            <Input
                                type="textarea"
                                rows="5"
                                name="notes"
                                placeholder="Write here..."
                                value={courseDescription}
                                onChange={(e) => {
                                    if (courseDescriptionError) {
                                        setCourseDescriptionError(false);
                                    }
                                    setCourseDescription(e.target.value);
                                }}
                            />
                          
                        </FormGroup>
                        <FormGroup>
                            <Label>Highlights:</Label>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={newState => {
                                    setEditorState(newState);
                                    let con = draftToHtml(convertToRaw(newState.getCurrentContent()));
                                    // console.log(con);
                                    setContent(con);
                                }}
                            />
                        </FormGroup>
                       
                    </Form>

                    {props.saveInprogress ? <div>
                        <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>
                    </div>
                        :
                        <div>
                            <button className="btn btn-primary m-1" onClick={() => { onClickSave() }}> Save </button>
                        </div>}

                    {/* <div className="border ql-container p-2">
                        <div
                            dangerouslySetInnerHTML={{
                                __html: content
                            }}
                        />
                    </div> */}

                </CardBody>
            </Card>

        </React.Fragment>
    );
}

export default PostSubscription

