import * as actions from "./actionTypes";

export const updateLearningActivity = (learningActivity) => {
  return {
    type: actions.UPDATE_LEARNING_ACTIVITY,
    payload: { learningActivity },
  };
};

export const deleteLearningActivity = (learningActivity) => {
  return {
    type: actions.DELETE_LEARNING_ACTIVITY,
    payload: {learningActivity},
  };
};
export const addLearningActivity = (learningActivity) => {
  return {
    type: actions.ADD_LEARNING_ACTIVITY,
    payload: {learningActivity},
  };
};
export const updateLearningActivities = (learningActivities) => {
  return {
    type: actions.UPDATE_LEARNING_ACTIVITYS,
    payload: { learningActivities },
  };
};

export const replaceLearningActivities = (learningActivities) => {
  return {
    type: actions.REPLACE_LEARNING_ACTIVITIES,
    payload: { learningActivities },
  };
};

export const getLearningActivityForcurriculumId = (curriculumId) => {
  return {
    type: actions.GET_LEARNING_ACTIVITY_FOR_CURRICULUM_ID,
    payload: { curriculumId },
  };
};

export const getLearningActivitiesForSubcategoryIds = (subcategoryIds,categoryId) => {
  return {
    type: actions.GET_ACTIVITIES_FOR_SUBCATEGORY_IDS,
    payload: { subcategoryIds,categoryId},
  };
};
export const getLearningActivitiesForCategoryId = (categoryId) => {
  return {
    type: actions.GET_ACTIVITIES_FOR_CATEGORY_ID,
    payload: { categoryId },
  };
};

export const setSelecetdLearningActivity = (id) => {
  return {
      type: actions.SET_SELECTED_LEARNING_ACTIVITY,
      payload: { id }
  }
}
export const updateSelecetdLearningActivity = (learningActivity) => {
  return {
      type: actions.UPDATE_SELECTED_LEARNING_ACTIVITY,
      payload: { learningActivity }
  }
}

export const setLearningActivityLoadingState = (state) => {
  return {
      type: actions.LEARNING_ACTIVITY_ADD_LOADING_STATE,
      payload: { state }
  }
}

export const reorderActivities = (learningActivities,categoryId,subcategoryIds,message,courseId) => {
  return {
    type: actions.LEARNING_ACTIVITY_REORDER,
    payload: { learningActivities,categoryId,subcategoryIds,message ,courseId},
  };
};

export const getLearningActivitiesForCourseId = (courseId) => {
  return {
    type: actions.GET_ACTIVITIES_FOR_COURSE_ID,
    payload: { courseId },
  };
};

export const getQuizLearningActivitiesForCourseId = (courseId) => {
  return {
    type: actions.GET_QUIZ_ACTIVITIES_FOR_COURSE_ID,
    payload: { courseId },
  };
};

export const updateQuizLearningActivitiesForCourseId = (learningActivities) => {
  return {
    type: actions.UPDATE_QUIZ_ACTIVITIES_FOR_COURSE_ID,
    payload: { learningActivities },
  };
};

export const getLiveMeetingLearningActivitiesForCourseId = (courseId) => {
  return {
    type: actions.GET_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID,
    payload: { courseId },
  };
};

export const updateLiveMeetingLearningActivitiesForCourseId = (learningActivities) => {
  return {
    type: actions.UPDATE_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID,
    payload: { learningActivities },
  };
};

export const getLearningActivitiesForIds = (ids) => {
  return {
    type: actions.GET_LEARNING_ACTIVITIES_FOR_IDS,
    payload: { ids },
  }
}