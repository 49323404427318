import React from "react";
import { Row, Col, Form } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const Breadcrumb = (props) => {
  return (
    <React.Fragment>
      <Row className="">
        <Col>
          <div className="mt-2">
            <h4 className="mb-0 font-size-18">{props.t(props.title)}</h4>
          </div>
        </Col>

        <Col>
          <div className="d-flex justify-content-end align-items-center" style={{ marginTop: '-10px'}}>
            {props.isButtonHidden !== true && props.getRatingsButton}
            {props.isButtonHidden !== true && props.rightButton}
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

export default withRouter(withNamespaces()(Breadcrumb));
