import React from 'react';
import { Line, Scatter } from 'react-chartjs-2';

const LineChart = (props) => {

    const colors = ["#2fc32f", "#b0dc0b", "#eab404", "#de672c", "#ec2e2e", "#d5429b", "#6f52b8", "#1c7cd5", "#56b9f7", "#0ae8eb"]

    let data = {
        labels: props.dateList ?? [],

    };
    let tempDatasets = []
    if (props.measures) {
        for (var i = 0; i < props.measures.length; i++) {
            let colorIndex = i % 10
            let tempDatasetObj = {
                label: props.measures[i].measureName ?? "",
                fill: true,
                type: "line",
                lineTension: 0.3,
                backgroundColor: "rgb(255, 255, 255, 0)",
                borderColor: colors[colorIndex],
                borderCapStyle: 'butt',
                borderDash: [],
                borderDashOffset: 0.0,
                borderJoinStyle: 'miter',
                pointBorderColor: "#556ee6",
                pointBackgroundColor: "#fff",
                pointBorderWidth: 1,
                pointHoverRadius: 5,
                pointHoverBackgroundColor: "#556ee6",
                pointHoverBorderColor: "#fff",
                pointHoverBorderWidth: 2,
                pointRadius: 1,
                pointHitRadius: 10,
                data: props.measures[i].measureDatas ?? []
            }
            tempDatasets.push(tempDatasetObj)
        }
    }

    if (props.absentList && props.absentList.length > 0) {
        tempDatasets.push(
            {
                type: 'bar',
                label: 'Absent rate per 10 observations',
                data: props.absentList ?? []
            }
        )
    }







    data.datasets = tempDatasets


    var option = {
        scales: {
            yAxes: [{
                ticks: {
                    beginAtZero: true
                }
            }]
        }
    }

    return (
        <Line width={474} height={300} data={data} options={option} />
    );
}

export default LineChart;