import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";

const initialState = {
    inventoryPurchaseOrderItems: [], loding: undefined,
    selectedInventoryPurchaseOrderItems: undefined,
};

const InventoryPurchaseOrderItem = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_INVENTORY_PURCHASE_ORDER_ITEMS:
            var inventoryPurchaseOrderItems = [...state.inventoryPurchaseOrderItems];
            if (inventoryPurchaseOrderItems.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.inventoryPurchaseOrderItems], action.payload.inventoryPurchaseOrderItems, "isDeleted")

                state = { ...state, inventoryPurchaseOrderItems: updatedList };
            } else {
                var i = 0
                var inventoryPurchaseOrderItemsToSave = []
                for (i = 0; i < action.payload.inventoryPurchaseOrderItems.length; i++) {
                    if (action.payload.inventoryPurchaseOrderItems[i].attributes.isDeleted !== true) {
                        inventoryPurchaseOrderItemsToSave.push(action.payload.inventoryPurchaseOrderItems[i])
                    }
                }
                state = { ...state, inventoryPurchaseOrderItems: inventoryPurchaseOrderItemsToSave };
            }
            break;
            case actions.SET_SELECTED_PURCHASE_ORDER_ITEMS:
                state = { ...state, selectedInventoryPurchaseOrderItems: undefined };
                break;
    
            case actions.UPDATE_SELECTED_PURCHASE_ORDER_ITEMS:
                state = { ...state, selectedInventoryPurchaseOrderItems: action.payload.inventoryPurchaseOrderItems };
                break;

        case actions.UPDATE_DELETE_INVENTORY_PURCHASE_ORDER_ITEM:
            var inventoryPurchaseOrderItems = state.inventoryPurchaseOrderItems.filter(
                (inventoryPurchaseOrderItems) => inventoryPurchaseOrderItems.id !== action.payload.inventoryPurchaseOrderItems.id
            );
            state = { ...state, inventoryPurchaseOrderItems: inventoryPurchaseOrderItems };
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
};

export default InventoryPurchaseOrderItem;
