import Parse from 'parse';
import * as Constants from '../../Constents';
import { printLog } from './loghelper';
export const getEnquiryAnalyticsFromParse = (filter, schoolIds, isLocal) => { 
   printLog("HL: enquiryAnalytics_helper : getEnquiryAnalyticsFromParse")
  try {

    return new Promise((resolve, reject) => {
      var EnquiryAnalytics = Parse.Object.extend("EnquiryAnalytics");
      var query = new Parse.Query(EnquiryAnalytics);
      query.greaterThanOrEqualTo("enquiryDate", filter.startDate);
      query.lessThanOrEqualTo("enquiryDate", filter.endDate);
      query.ascending("enquiryDate")
      if (filter.schoolId) {
        query.equalTo("schoolId", filter.schoolId)
      }
      if (schoolIds && schoolIds.length > 0) {
        query.containedIn("schoolId", schoolIds)
      }
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}

export const getEnquiryAnalyticsFor10Days=(id,type,isLocal)=>{
  printLog("HL: enquiryAnalytics_helper : getEnquiryAnalyticsFor10Days")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryAnalytics = Parse.Object.extend("EnquiryAnalytics");
      var query = new Parse.Query(EnquiryAnalytics);
      if(type === "school"){
        query.equalTo("schoolId", id);
      }else if(type === "institute"){
        query.equalTo("instituteId", id);
        query.doesNotExist("schoolId");
      }else if(type === "franchise"){
        query.equalTo("franchiseId", id);
        query.doesNotExist("schoolId");
      }
      query.limit(10);
      query.descending("createdAt");

      if(isLocal){
        query.fromLocalDatastore();
      }
      
      query.find().then(
        (result) => {
         // console.log("Query for 10 days", result);
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}


