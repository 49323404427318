import * as actions from "./actionTypes";

export const getSaleOrdersForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_SALE_ORDERS,
    payload: {ownerId, ownerType},
    }
}

export const getSaleOrdersForBuyer = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_SALE_ORDERS_FOR_BUYER,
    payload: {ownerId, ownerType},
    }
}

export const updateSaleOrders = (inventorySaleOrders) =>{
    return{
        type:actions.UPDATE_INVENTORY_SALE_ORDERS,
        payload: {inventorySaleOrders},
    }
}

export const updateDeleteSaleOrder = (inventorySaleOrder) =>{
    return{
        type:actions.UPDATE_DELETE_SALE_ORDER,
        payload: {inventorySaleOrder},
    }
}

export const deleteSaleOrder = (inventorySaleOrder) =>{
    return{
        type:actions.DELETE_INVENTORY_SALE_ORDER,
        payload: {inventorySaleOrder},
    }
}

export const getSaleOrderItemsForSaleOrderId = (inventorySaleOrder) =>{
    return{
        type:actions.GET_INVENTORY_SALE_ITEM,
        payload: {inventorySaleOrder},
    }
}

export const addSaleOrder = (inventorySaleOrderId) =>{
    return{
        type: actions.ADD_INVENTORY_SALE_ORDER,
        payload: {inventorySaleOrderId}
    }
}

export const setSelectedSaleOrder = (inventorySaleOrderId) => {
    return {
        type: actions.SET_SELECTED_SALE_ORDER,
        payload: {inventorySaleOrderId }
    }
}

export const updateSelectedSaleOrder = (inventorySaleOrder) => {
    return {
      type: actions.UPDATE_SELECTED_SALE_ORDER,
      payload: { inventorySaleOrder },
    };
  };

  export const editSaleOrder = (inventorySaleOrder) =>{
    return{
        type: actions.EDIT_INVENTORY_SALE_ORDER,
        payload: {inventorySaleOrder}
    }
}

export const getCartForForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_CART_FOR_OWNER_ID,
    payload: {ownerId, ownerType},
    }
}

export const updateCartForOwnerId = (cartObjects) =>{
    return{
        type:actions.UPDATE_CART_FOR_OWNER_ID,
        payload: {cartObjects},
    }
}

export const addInventoryCart = (cartObject,type) =>{
    return{
        type:actions.ADD_INVENTORY_CART,
        payload: {cartObject,type},
    }
}