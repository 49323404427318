import React, { useState, useEffect } from "react";

import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  CardTitle,
  CardHeader,
  Collapse,
  FormGroup,
  Label,
  Form,
  UncontrolledTooltip,
} from "reactstrap";
import TopBar from "./Topbar";
import FormQuestion from "./FormQuestion";
import SweetAlert from "react-bootstrap-sweetalert";
import { setSelecetdSchool } from "../../store/actions";
import { AvForm, AvField } from "availity-reactstrap-validation";

//Import Images

import toastr from "toastr";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";
import DragDropTables from '../LMS/DragDropTables';

const AddForm = (props) => {
  const schoolMatch = matchPath(props.history.location.pathname, {
    path: "/addForm/:schoolId/:formId",
    exact: true,
    strict: false,
  });

  const franchiseMatch = matchPath(props.history.location.pathname, {
    path: "/franchise-addForm/:franchiseId/:formId",
    exact: true,
    strict: false,
  });


  const match = schoolMatch ?? franchiseMatch
  const schoolId = schoolMatch && schoolMatch.params.schoolId;
  const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;
  const formId = match && match.params.formId;

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
    fetchForm(formId)

  }, [props.selectedSchool, schoolId]);

  const fetchForm = (formId) => {

    var query = new Parse.Query("Forms");

    query.equalTo("objectId", formId);

    query.notEqualTo("isDeleted", true);
    query.limit(1000);

    query.find().then((results) => {

      if (results != null) {
        if (results.length > 0) {
          setFormName(results[0].attributes.name)
        }
      }
    });

  }


  useEffect(() => {
    var query = new Parse.Query("FormSections");
    query.equalTo("formId", formId);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then(
      (sectionResults) => {
        if (sectionResults != null) {
          if (sectionResults.length > 0) {
            let sectionIds = [];
            let tempSectionList = [];
            for (var i = 0; i < sectionResults.length; i++) {
              let tempSectionObj = {
                sectionName: sectionResults[i].attributes.sectionName,
                order: sectionResults[i].attributes.order,
                parseObject: sectionResults[i],
                id: `section${sectionResults[i].id}`,
                className: sectionResults[i].className,
                questions: [{ id: "addNewQuestion" }],
              };

              sectionIds.push(sectionResults[i].id);
              tempSectionList.push(tempSectionObj);
            }
            setFormSectionsList(tempSectionList);

            var query = new Parse.Query("FormQuestions");
            query.containedIn("sectionId", sectionIds);
            query.equalTo("formId", formId);
            query.ascending("order");
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.find().then(
              (questionResults) => {
                //setIsLoading(false);

                if (questionResults != null) {
                  if (questionResults.length > 0) {
                    let tempSectionList2 = [...tempSectionList];

                    for (var i = 0; i < tempSectionList2.length; i++) {
                      if (tempSectionList2[i].id) {
                        setSectionCurrentlySelected(tempSectionList2[i].id);
                      }
                      let questionList = [];
                      for (var j = 0; j < questionResults.length; j++) {
                        if (
                          tempSectionList2[i].parseObject.id ===
                          questionResults[j].attributes.sectionId
                        ) {
                          let tempQuestionObj = {
                            id: questionResults[j].id,
                            questionTitle:
                              questionResults[j].attributes.question,
                            questionType:
                              questionResults[j].attributes.questionType,
                            isMandatory:
                              questionResults[j].attributes.isMandatory ??
                              false,
                            parseObject: questionResults[j],
                            isDefaultQuestion: questionResults[j].attributes.isDefaultQuestion ??
                              false,

                            choices: questionResults[j].attributes.choices,
                          };
                          questionList.push(tempQuestionObj);
                        }
                      }

                      let tempSecObj = tempSectionList2[i];
                      questionList.push({ id: "addNewQuestion" });
                      tempSecObj.questions = questionList;

                      tempSectionList2[i] = tempSecObj;
                    }

                    setFormSectionsList(tempSectionList2);

                    setIsLoading(false);
                  } else {
                    setIsLoading(false);
                  }
                } else {
                  setIsLoading(false);
                }
              },
              (error) => {
                setIsLoading(false);
              }
            );
          } else {
            setIsLoading(false);
          }
        } else {
          setIsLoading(false);
        }
      },
      (error) => {
        setIsLoading(false);
      }
    );
  }, [formId]);

  const [creatingSection, setCreatingSection] = useState(false);
  const [showDeleteSectionAlert, setShowDeleteSectionAlert] = useState(false);
  const [sectionCurrentlySelected, setSectionCurrentlySelected] = useState("");
  const [addSectionView, setAddSectionView] = useState(false);
  const [newSectionName, setNewSectionName] = useState(undefined);

  const [sectionName, setSectionName] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [formSectionsList, setFormSectionsList] = useState([]);
  const [sectionNameError, setSectionNameError] = useState(false);
  const [editSectionObject, setEditSectionObject] = useState(undefined);
  const [sectionToDelete, setSectionToDelete] = useState(undefined);
  const [formName, setFormName] = useState("Form");
  const [reorder, setReorder] = useState(false);

  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }

  function handleAddSection() {
    setCreatingSection(true);
    let FormSections = Parse.Object.extend("FormSections");
    let formSection = new FormSections();

    formSection.set("formId", formId);
    formSection.set("sectionName", sectionName);
    formSection.set("order", formSectionsList.length + 1);

    formSection.save().then(
      (result) => {
        setSectionName("");
        setAddSectionView(!addSectionView);

        setCreatingSection(false);
        let formSectionObject = {
          id: "section" + result.id,
          sectionName: sectionName,
          order: formSectionsList.length + 1,
          questions: [{ id: "addNewQuestion" }],
          parseObject: result,
          className: result.className,
        };

        let tempList = [...formSectionsList];
        tempList.push(formSectionObject);
        if (tempList.length === 0) {
          setAddSectionView(true);
        }
        setFormSectionsList(tempList);
      },
      (error) => {
        setSectionName("");
        setAddSectionView(!addSectionView);
      }
    );
  }
  function handleRemoveSection() {
    let sectionObject = sectionToDelete.parseObject;
    sectionObject.set("isDeleted", true);
    sectionObject.save().then(
      (result) => {
        let tempList = [...formSectionsList];
        let index = tempList.indexOf(sectionToDelete);
        setSectionToDelete(undefined);
        removeElement(tempList, tempList[index]);
        setFormSectionsList(tempList);
        toastr.success("Section removed successfully !!", { timeOut: 1000 });
      },
      (error) => {
        toastr.success("Something went wrong, please try again !!", {
          timeOut: 1000,
        });
      }
    );

    setShowDeleteSectionAlert(false);
  }
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const handleAddNewQuestion = (sectionObject, question) => {
    let tempSectionObj = sectionObject;
    let tempQuestions = [...tempSectionObj.questions];

    let tempLastQuestion = tempQuestions[tempQuestions.length - 1];
    removeElement(tempQuestions, tempQuestions[tempQuestions.length - 1]);

    tempQuestions.push(question);

    tempQuestions.push(tempLastQuestion);

    tempSectionObj.questions = tempQuestions;

    let tempList = [...formSectionsList];
    let index = tempList.indexOf(sectionObject);
    tempList[index] = tempSectionObj;
    setFormSectionsList(tempList);
  };

  const handleRemoveQuestion = (sectionObject, question) => {
    let tempSectionObj = sectionObject;
    let tempList = [...formSectionsList];

    let tempSectionIndex = tempList.indexOf(sectionObject);
    let tempQuestions = [...tempSectionObj.questions];
    let tempQuestionIndex = tempQuestions.indexOf(question);
    removeElement(tempQuestions, tempQuestions[tempQuestionIndex]);
    tempSectionObj.questions = tempQuestions;

    tempList[tempSectionIndex] = tempSectionObj;

    setFormSectionsList(tempList);
  };

  const handleEditQuestion = (sectionObject, question) => {
    let tempSectionObj = sectionObject;
    let tempList = [...formSectionsList];

    let tempSectionIndex = tempList.indexOf(sectionObject);
    let tempQuestions = [...tempSectionObj.questions];

    let tempQuestionIndex = tempQuestions.indexOf(question);
    tempQuestions[tempQuestionIndex] = question;

    tempSectionObj.questions = tempQuestions;

    tempList[tempSectionIndex] = tempSectionObj;

    setFormSectionsList(tempList);
  };

  const handelSort = (newOrderList) => {

    setFormSectionsList(newOrderList);
    setReorder(false)
    let list = [];
    let i = 0;
    for (const fsl of newOrderList) {
      let po = fsl.parseObject;
      po.set('order', i);
      i++
      list.push(po);
    }

    Parse.Object.saveAll(list).then(
      (result) => {
        toastr.success("Reorderd successfully !!", { timeOut: 1000 });
      },
      (error) => {

      }
    );
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading && (
          <Container fluid>
            {showDeleteSectionAlert && (
              <SweetAlert
                warning
                showCancel
                confirmBtnText="Delete"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Alert!"
                onCancel={() => {
                  setShowDeleteSectionAlert(false);
                }}
                onConfirm={() => handleRemoveSection()}
              >
                {props.t("Are you sure you want to remove this section")}
              </SweetAlert>
            )}

            {/* <Row>
              <Col lg={8} md={8} xs={12}>
                <h4>Form Setup</h4>
              </Col>

              <Col lg={4} md={4} xs={12}>
                <Form className="mb-3  float-sm-right form-inline">
                   <Button
                    className="btn btn-primary waves-effect waves-light "
                    color="primary"
                    onClick={() => {
                      console.log(formSectionsList);
                    }}
                  >
                    Save
                  </Button> 
                </Form>
              </Col>
            </Row> */}
            {/* <TopBar
              title={formName + " Setup"}
              buttonName={""}
              backButton={true}
              hideButton={true}
              onClickAdd={() => { }}
              onClickBackButton={() => {
                window.history.back()
              }}
            /> */}
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=' m-2'>
                  <h4>{formName + " Setup"}</h4>
                </div>
              </Col>
            </Row>
            <Card>
              <CardBody>
                <Row>
                  <Col lg={11}>
                    <CardTitle> {props.t("Form Sections")}</CardTitle>
                  </Col>
                  <Col lg={1}>
                    {formSectionsList && formSectionsList.length > 1 &&
                      <ul className="list-inline font-size-20 contact-links mb-0 float-right">
                        <li className="list-inline-item px-2"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            setReorder(!reorder)
                          }}
                          id={"sub-reorder"}>
                          <i className="bx bx-analyse "></i>
                          <UncontrolledTooltip placement="top" target={"sub-reorder"}>
                            {props.t('Reorder')}
                          </UncontrolledTooltip>

                        </li>
                      </ul>
                    }
                  </Col>
                </Row>



                {reorder && formSectionsList && formSectionsList.length > 1 &&
                  <DragDropTables
                    rearrangeObjects={formSectionsList}
                    updatedOrderList={handelSort}
                    onClickClose={() => { setReorder(false) }}>

                  </DragDropTables>
                }
                <div className="cursors move">
                  {!reorder && formSectionsList.map((sectionObject, sectionKey) => (
                    <Card className="mb-1 bg-white" key={sectionKey}>
                      <CardHeader className="p-3" id="headingOne">
                        <div>
                          {editSectionObject &&
                            editSectionObject.id ===
                            formSectionsList[sectionKey].id ? (
                            <Col>
                              <div className="list-inline contact-links mb-0">
                                <AvForm
                                  className="outer-repeater mt-3"
                                  onValidSubmit={(e, v) => {
                                    if (v.edittitle && v.edittitle.length > 0) {
                                      var sectionParseObject =
                                        sectionObject.parseObject;
                                      sectionObject.sectionName = v.edittitle;
                                      sectionParseObject.set(
                                        "sectionName",
                                        v.edittitle
                                      );
                                      sectionParseObject
                                        .save()
                                        .then((result) => { });
                                      setEditSectionObject(undefined);

                                      setNewSectionName("");
                                      setSectionNameError(false);
                                    } else {
                                      setSectionNameError(true);
                                    }
                                  }}
                                >
                                  <div data-repeater-item className="outer">
                                    <FormGroup>
                                      <AvField
                                        type="text"
                                        name="edittitle"
                                        placeholder={props.t("Section Name")}
                                        className="form-control"
                                        value={newSectionName}
                                      />
                                      {sectionNameError && (
                                        <p className="text-danger">
                                          {" "}
                                          {props.t(
                                            "Section name can not be empty"
                                          )}
                                        </p>
                                      )}
                                    </FormGroup>
                                  </div>

                                  <button
                                    type="submit"
                                    className="btn btn-primary mr-1 "
                                  >
                                    {props.t("Save")}
                                  </button>
                                  <button
                                    type="button"
                                    className="btn btn-danger "
                                    onClick={() => {
                                      setEditSectionObject(undefined);
                                      setNewSectionName("");
                                      setSectionNameError(false);
                                    }}
                                  >
                                    {props.t("Close")}
                                  </button>
                                </AvForm>
                              </div>
                            </Col>
                          ) : (
                            <Row>
                              <Col
                                lg={10}
                                xs={10}
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (
                                    sectionCurrentlySelected ===
                                    sectionObject.id
                                  ) {
                                    setSectionCurrentlySelected("");
                                  } else {
                                    setSectionCurrentlySelected(
                                      sectionObject.id
                                    );
                                  }
                                }}
                              >
                                <h6 className="m-0 font-14">
                                  <span
                                    style={{ cursor: "pointer" }}
                                    className="text-dark"
                                  >
                                    {sectionObject.sectionName}{" "}
                                  </span>
                                  <span className="text-secondary">
                                    {" ("}
                                    {sectionObject.questions &&
                                      sectionObject.questions.length &&
                                      sectionObject.questions.length - 1}
                                    {" " + props.t("Questions")}
                                    {")  "}
                                  </span>

                                  <i
                                    style={{ cursor: "pointer" }}
                                    className={
                                      sectionCurrentlySelected ===
                                        sectionObject.id
                                        ? "bx bxs-chevron-up  ml-2"
                                        : "bx bxs-chevron-down  ml-2"
                                    }
                                  />
                                </h6>
                              </Col>

                              <Col className="d-flex justify-content-end">
                                <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                  <li
                                    className="list-inline-item px-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setEditSectionObject(sectionObject);
                                      setNewSectionName(
                                        sectionObject.sectionName
                                      );
                                    }}
                                    id={"edit" + sectionObject.id}
                                  >
                                    <i className="bx bx-edit-alt"></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={"edit" + sectionObject.id}
                                    >
                                      {props.t("Edit")}
                                    </UncontrolledTooltip>
                                  </li>

                                  <li
                                    className="list-inline-item px-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => {
                                      setSectionToDelete(sectionObject);
                                      setShowDeleteSectionAlert(true);
                                    }}
                                    id={"delete" + sectionObject.id}
                                  >
                                    <i className="bx bx-trash text-danger"></i>
                                    <UncontrolledTooltip
                                      placement="top"
                                      target={"delete" + sectionObject.id}
                                    >
                                      {props.t("Delete")}
                                    </UncontrolledTooltip>
                                  </li>
                                </ul>
                              </Col>
                            </Row>
                          )}
                        </div>
                      </CardHeader>

                      <Collapse
                        isOpen={sectionCurrentlySelected === sectionObject.id}
                      >
                        <FormQuestion
                          questions={sectionObject.questions}
                          sectionObject={sectionObject}
                          formId={formId}
                          handleAddNewQuestion={(question) => {
                            handleAddNewQuestion(sectionObject, question);
                          }}
                          handleEditQuestion={(question) => {
                            handleEditQuestion(sectionObject, question);
                          }}
                          handleRemoveQuestion={(question) => {
                            handleRemoveQuestion(sectionObject, question);
                          }}
                        ></FormQuestion>
                      </Collapse>
                    </Card>
                  ))}

                  <Col lg={12}>
                    {addSectionView && (
                      <AvForm className="outer-repeater">
                        <div data-repeater-item className="outer">
                          <FormGroup>
                            <Label className="mt-2">
                              {props.t("Section title")}:{" "}
                            </Label>
                            <AvField
                              type="text"
                              name="name"
                              placeholder={props.t("Enter section title...")}
                              className="form-control"
                              value={sectionName}

                              onChange={(e) => {
                                if (
                                  e.target.value.length > 0 &&
                                  sectionNameError
                                ) {
                                  setSectionNameError(false);
                                }
                                setSectionName(e.target.value);
                              }}
                            />
                            {sectionNameError && <p className="text-danger"> {props.t('Section name can not be empty')}</p>}

                          </FormGroup>
                        </div>
                      </AvForm>
                    )}
                    {!addSectionView ? (
                      <div className="d-flex justify-content-end">
                        <button
                          onClick={() => {
                            setAddSectionView(!addSectionView);
                          }}
                          type="button"
                          className="btn btn-primary waves-effect waves-light float-right m-1"
                        >
                          <i className="bx bx-plus font-size-16 align-middle mr-1"></i>{" "}
                          {props.t("Add Section")}
                        </button>
                      </div>
                    ) : (
                      <div className="d-flex justify-content-between">
                        <button
                          type="button"
                          className="btn btn-secondary m-1"
                          onClick={() => {
                            setAddSectionView(!addSectionView);

                            setSectionName("");
                            setSectionNameError(false);
                          }}
                        >
                          {props.t("Close")}
                        </button>

                        <button
                          onClick={() => {
                            if (sectionName && sectionName.length > 0) {
                              handleAddSection();

                              setSectionNameError(false);
                            } else {
                              setSectionNameError(true);
                            }
                          }}
                          type="button"
                          className="btn btn-primary waves-effect waves-light float-right m-1"
                          disabled={creatingSection}
                        >
                          {creatingSection && (
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                          )}
                          {!creatingSection && (
                            <i className="bx bx-save font-size-16 align-middle mr-1"></i>
                          )}
                          {props.t("Save Section")}
                        </button>
                      </div>
                    )}
                  </Col>
                </div>
              </CardBody>
            </Card>
          </Container>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Form details...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
    // <React.Fragment>
    //   <Container fluid={true} className={"page-content"}>
    //     {!isLoading && (
    //       <div></div>
    //     )}

    //     {false && (
    //       <Row>
    //         <Col xs="12">
    //           <div className="text-center my-3">
    //             <Link to="#" className="text-success">
    //               <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
    //               Loading Form...{" "}
    //             </Link>
    //           </div>
    //         </Col>
    //       </Row>
    //     )}
    //   </Container>
    // </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;



  return { selectedSchool };
};

export default withNamespaces()(
  withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,

  })(AddForm))
);
/*
const FormSections = Parse.Object.extend("FormSections");
    let formSection = new FormSections();
    let order = 0;
    if (formSectionsList && formSectionsList.length > 0) {
      order =
        1 + formSectionsList[formSectionsList.length - 1].attributes.order;
    }
    formSection.set("sectionName", sectionName);
    formSection.set("order", order);
    formSection.set("schoolId", schoolId);
    formSection.save().then(
      (result) => {
        let tempList = [...formSectionsList];
        tempList.push(result);
        setFormSectionsList(tempList);
      },
      (error) => {
        console.log("saving error");
      }
    );
*/
