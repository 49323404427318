import * as actions from "./actionTypes";

export const updateLearningSession = (learningSession,isReplace) => {
    return {
      type: actions.UPDATE_LEARNING_SESSION,
      payload: { learningSession,isReplace },
    };
  };
  
  export const getLearningSessionForSchoolId = (schoolId, filter) => {
    return {
      type: actions.GET_LEARNING_SESSION_FOR_SCHOOL_ID,
      payload: { schoolId, filter },
    };
  };

  export const clearLearningSession = () => {
    return {
      type: actions.CLEAR_LEARNING_SESSION,
      payload: {  },
    };
  };
  
  export const updateOnlineLearingSession = (onlineLearningSession) => {
    return {
      type: actions.UPDATE_ONLINE_LEARNING_SESSION,
      payload: { onlineLearningSession },
    };
  };
  
  export const getOnlineLearingSessionForSchoolId = (schoolId, filter) => {
    return {
      type: actions.GET_ONLINE_LEARNING_SESSION_FOR_SCHOOL_ID,
      payload: { schoolId , filter},
    };
  };

  export const setSelectedLearingSession = (id) => {
    return {
        type: actions.SET_SELECTED_LEARNING_SESSION,
        payload: { id }
    }
  }

  export const setSelectedOnlineLearingSession = (id) => {
    return {
        type: actions.SET_SELECTED_ONLINE_LEARNING_SESSION,
        payload: { id }
    }
  }

  export const updateSelectedLearningSession = (session) => {
    return {
        type: actions.UPDATE_SELECTED_LEARNING_SESSION,
        payload: { session }
    }
  }

  export const updateSelectedOnlineLearningSession = (onlineSession) => {
    return {
        type: actions.UPDATE_SELECTED_ONLINE_LEARNING_SESSION,
        payload: { onlineSession }
    }
  }

  export const getOnlineLearningTrackForSessionId = (sessionId) =>{
    return {
      type: actions.GET_ONLINE_LEARNING_TRACK_FOR_SESSION_ID,
      payload: {sessionId}
    }
  } 

  export const updateOnlineLearningTrackForSessionId = (sessionId) =>{
    return {
      type: actions.UPDATE_ONLINE_LEARNING_TRACK_FOR_SESSION_ID,
      payload: {sessionId}
    }
  } 

  export const getLearningTrackingForSessionId = (sessionId) =>{
    return {
      type: actions.GET_LEARNING_TRACKING_FOR_SESSION_ID,
      payload: {sessionId}
    }
  } 
  
  export const updateLearningTrackingForSessionId = (sessionId) =>{
    return {
      type: actions.UPDATE_LEARNING_TRACKING_FOR_SESSION_ID,
      payload: {sessionId}
    }
  } 

