import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Label,
    Card,
    Form,
    Button,
    CardBody,
    Modal,
    ModalHeader,
    ModalBody,
    UncontrolledTooltip, Nav, NavItem, NavLink, FormGroup, Table
} from "reactstrap";
import classnames from 'classnames';
import { Parse } from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import { getSchoolsForTools} from "../../store/actions";
import * as Constants from "../../Constents";
import toastr from "toastr";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import * as XLSX from 'xlsx/xlsx.mjs';


const SchoolCameraDetails = (props) => {

    const [schoolObject, setSchoolObject] = useState();
    const [cameraList, setCameraList] = useState([])
    const [displayList, setDisplayList] = useState([]);
    const [showDeleteAlert, setShowDeleteAlert] = useState(false);
    const [cameraToDelete, setCameraToDelete] = useState([]);
    const [cameraToRestore, setCameraToRestore] = useState([]);
    const [isLoading, setIsLoading] = useState(true);

    const [showAddCCTVModel, setShowAddCCTVModel] = useState(false);
    const [showCameraName, setShowCameraName] = useState(undefined);
    const [showRtspLink, setShowRtspLink] = useState(undefined);
    const [showHlsLink, setShowHlsLink] = useState(undefined);
    const [showHTMLLink, setShowHTMLLink] = useState(undefined);
    const [addedDate, setAddedDate] = useState(undefined);
    const [cameraNumber, setCameraNumber] = useState(undefined);
    const [note, setNote] = useState(undefined);
    const [selectedCameraLiveStreamForEdit, setCameraLiveStreamForEdit] = useState(undefined);
    const [customActiveTab, setcustomActiveTab] = useState("1");
    const [cctvAnalytics, setCctvAnalytics] = useState({});
    const [schoolNames, setSchoolNames] = useState([]);
    const [schoolCameraDetails, setSchoolCameraDetails] = useState([]);
    const [schoolCamsCount, setSchoolCamsCount] = useState({});
    
    const match = matchPath(props.history.location.pathname, {
        path: "/tools-school-settings-cameraDetails/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;

    useEffect(() => {
        if(schoolId==="streams"){
            getCCTVCamerasList()
            setcustomActiveTab("3")
        }else{
            Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
                schoolId: schoolId,
            }).then((result) => {
                // console.log("SchoolDetailsResult",result)
                setSchoolObject(result);
                getCCTVCamerasList()
            },
                (error) => {
                    console.log(error);
                }
            );
        }
        
    }, [schoolId]);
    // console.log("Schools", props.toolsSchools);
    useEffect(() => {
        if(schoolId=="streams"){
            if (!props.toolsSchools || props.toolsSchools.length == 0) {
                props.getSchoolsForTools();
            }else{
                let allSchools={}
                if (props.toolsSchools && props.toolsSchools.length > 0) {
                    for(const school of props.toolsSchools){
                        if(school.attributes.featuresList && school.attributes.featuresList[14] > 0){
                            allSchools[school.id] = `${school.attributes.Name}  ${school.id}`;
                        }else{
                            allSchools[school.id] = `${school.attributes.Name}  ${school.id} DEACTIVATED`;
                        }
                    }
                }
                setSchoolNames(allSchools);
            }
        }
      }, [props.toolsSchools]);

    const isFeatureEnabled = (school) => {
        return school.attributes.featuresList && school.attributes.featuresList[14] > 0;
    };
      
    useEffect(() => {
        if (props.toolsSchools && props.toolsSchools.length > 0 && cameraList && cameraList.length > 0) {
            let enabledSchools = 0
            let cctvUsingSchools = 0;
            let activatedCams = 0
            let deactivatedCams = 0;
            let totalCamsInUse = 0
            let schoolCameraDetails = [];
            let schoolCamsCount = {};
            // console.log("CameraList", cameraList);
            for (let i = 0; i < cameraList.length; i++) {
                if (!cameraList[i].attributes.isDeleted) {
                    activatedCams++;
                } else {
                    deactivatedCams++;
                }
            }
            for (const school of props.toolsSchools) {
                if (isFeatureEnabled(school)) {
                    enabledSchools++;
                    const schoolCams = cameraList.filter(cam => cam.attributes.schoolId === school.id);
                    // console.log("SchoolCams", schoolCams)
                    if (schoolCams.length > 0) {
                        cctvUsingSchools++;
                        totalCamsInUse += schoolCams.length;

                        schoolCamsCount[school.id] = schoolCams.length;
                        
                        schoolCameraDetails.push({
                            schoolName: school.attributes.Name,
                            schoolId: school.id,
                            activatedCameraDetails: schoolCams.filter(cam => !cam.attributes.isDeleted).length,
                            deactivatedCameraDetails: schoolCams.filter(cam => cam.attributes.isDeleted).length,
                        });
                    }
                }
            }
            let avg = Math.ceil(totalCamsInUse / (cctvUsingSchools || 1));
            let analytics = {
                enabledSchools: enabledSchools,
                totalCamsInUse: cctvUsingSchools,
                avgCams: avg,
                activatedCams: activatedCams,
                deactivatedCams: deactivatedCams 
            }
            setCctvAnalytics(analytics)
            setSchoolCameraDetails(schoolCameraDetails);
            setSchoolCamsCount(schoolCamsCount);
            // console.log("SchoolCameraDetails", schoolCameraDetails)
        }
    }, [props.toolsSchools, cameraList]);
    
    const getCCTVCamerasList = () => {
        var CameraLiveStreams = Parse.Object.extend("CameraLiveStreams");
        var query = new Parse.Query(CameraLiveStreams);

        query.descending("createdAt");
        if(schoolId!="streams"){
            query.equalTo("schoolId", schoolId)
        }
        query.limit(1000);
        query.find().then(
            (result) => {
                setIsLoading(false);
                setCameraList(result);
                setDisplayList(result.filter(camera => !camera.attributes.isDeleted)); 
                // console.log("getCameraList", result);
            },
            (error) => {

            }
        );

    }

    const handleDeleteCamera = () => {
        let cl = cameraToDelete;
        cl.set("isDeleted", true);
        cl.set("cameraNumber", cameraNumber);
        cl.set("addedDate", addedDate)
        cl.set("note", note);
        cl.save().then((result) => {
            const updatedCameraList = cameraList.map((camera) =>
                camera.id === result.id
                    ? { ...camera, attributes: { ...camera.attributes, isDeleted: true } }
                    : camera
            );
    
            setCameraList(updatedCameraList);
            setShowDeleteAlert(false);
            toastr.success("Camera Deleted Successfully", { timeOut: 1500 });
            toggleCustom("2",cameraList); 
            // console.log("CameraList", cameraList)
            // console.log("UpdatedCameraList", updatedCameraList);
            // console.log("DeleteCameraList", result);
        }).catch((error) => {
            toastr.error("Failed to delete camera", { timeOut: 1500 });
        });
    };
    

    const handleRestoreCamera = () => {
        let camera = cameraToRestore;
        // console.log("RestoreButtonTest", camera);
        if(camera){
            camera.set("isDeleted", false)
            camera.set("deletedOn", false);
            camera.set("cameraNumber", cameraNumber);
            camera.set("addedDate", addedDate)
            camera.set("note", note);
            camera.save().then(
                (result) => {
                    let updatedList = cameraList.filter(
                        (po) => po.id !== result.id 
                    );
                    updatedList.unshift(result);
                    setCameraList(updatedList);
                    toastr.success("Camera Restored Successfully", { timeOut: 1500 });
                    toggleCustom("1", updatedList);
                    setShowAddCCTVModel(false);
                    setIsLoading(false);
                },
                (error) => {
                    setIsLoading(false);
                }
            );
        }
    };
    
    const handleAddCCTVSubmit = () => {
        var CameraLiveStreams = Parse.Object.extend("CameraLiveStreams");
        let cameraLiveStreamsObject = new CameraLiveStreams();
        if (selectedCameraLiveStreamForEdit) {
            cameraLiveStreamsObject = selectedCameraLiveStreamForEdit;
        }

        cameraLiveStreamsObject.set("schoolId", schoolId);
        cameraLiveStreamsObject.set("name", showCameraName);
        cameraLiveStreamsObject.set("rtsp", showRtspLink);
        cameraLiveStreamsObject.set("htmlUrl", showHTMLLink);
        cameraLiveStreamsObject.set("hls", showHlsLink);        
        cameraLiveStreamsObject.set("cameraNumber", cameraNumber);
        cameraLiveStreamsObject.set("addedDate", addedDate);        
        cameraLiveStreamsObject.set("note", note);
        cameraLiveStreamsObject.save().then((camera) => {
            let list = [...displayList];

            if (selectedCameraLiveStreamForEdit) {
                let i = list.indexOf(selectedCameraLiveStreamForEdit);
                list[i] = camera;
            } else {
                list.unshift(camera);
            }
            setDisplayList(list);

            setCameraLiveStreamForEdit(undefined)
            setShowCameraName(undefined);
            setShowRtspLink(undefined);
            setShowHlsLink(undefined);
            setShowHTMLLink(undefined)
            setCameraNumber(undefined);
            setNote(undefined);
            setAddedDate(undefined)
            setShowAddCCTVModel(false);
            // console.log("HandleAddCamera", camera)

        })
    }

    function toggleCustom(tab,cameraList) {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
            if (tab === "1") {
            let activeCameras = cameraList.filter(camera => !camera.attributes.isDeleted);
            setDisplayList(activeCameras);
        } else if (tab === "2") {
            let deletedCameras = cameraList.filter(camera => camera.attributes.isDeleted);
            setDisplayList(deletedCameras);
        }else if (tab === "3") {
        }
        }
    }

    const downloadReport = () => {
        const finalData = [];
        finalData.push(["Sl.no", "School Name", "Activated Cams", "Deactivated Cams"]);
    
        for (let i = 0; i < schoolCameraDetails.length; i++) {
            const detail = schoolCameraDetails[i];
            finalData.push([
                i + 1, 
                detail.schoolName,
                detail.activatedCameraDetails,
                detail.deactivatedCameraDetails,
            ]);
        }
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.aoa_to_sheet(finalData);
        ws['!cols'] = [{ wpx: 30 }, { wpx: 350 }, { wpx: 100 }, { wpx: 100 },];
        XLSX.utils.book_append_sheet(wb, ws, "CameraDetails Report");
        const fileName = `CameraDetails_Report_${new Date().toLocaleDateString()}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    return (
        <React.Fragment>
            <div className="page-content">
                {!isLoading && (
                    <Container fluid>
                        <Row className='d-flex align-items-start'>
                            <Col className="d-flex align-items-start">
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { window.history.back() }}>
                                    <ul className=" list-unstyled">
                                        <div className=''>
                                            <i className="bx bx-chevron-left h1 text-primary"></i>
                                        </div>
                                    </ul>
                                </div>
                                <Row>
                                
                                <Col className="m-0">
                                {customActiveTab === "3" ? (<h4 className="mt-2">Number of Schools with Cameras ({Object.keys(schoolCamsCount).length})</h4>
                                     ) : (<h4 className="mt-2 mb-0">{schoolId === "streams" ? `All CCTV Cameras (${displayList.length})` 
                                     : `${schoolObject && schoolObject.attributes.Name} - CCTV Camera (${displayList.length})`}</h4>)}
                                </Col>
                                </Row>
                                { schoolId!="streams" && <div className='ms-auto'>{customActiveTab === "1" ? <button onClick={() => setShowAddCCTVModel(true)} className="btn btn-primary float-right mb-2">
                                        {props.t("Add CCTV")}
                                    </button> : null}
                                </div>}
                            </Col>
                        </Row>
                        <Card className="m-0">
                            {showDeleteAlert && (
                                <SweetAlert
                                    warning
                                    showCancel
                                    confirmBtnText="Yes"
                                    cancelBtnText="No"
                                    confirmBtnBsStyle="danger"
                                    cancelBtnBsStyle="success"
                                    title="Alert!"
                                    onCancel={() => {
                                        setShowDeleteAlert(false);
                                    }}
                                    onConfirm={() => handleDeleteCamera()}
                                >
                                    Are you sure to delete this DVR/NVR Camera?<br></br>
                                </SweetAlert>
                            )}
                            <CardBody>
                                <Row>
                                <Col lg='10'>
                                {schoolId =="streams" && cctvAnalytics && (
                                <Table className="table-bordered table-sm" style={{ width: '500px' }}>
                                    <tbody>
                                        <tr>
                                            <th scope="row" style={{ width: "300px" }}>Number of Schools Enabled CCTV</th>
                                            <td style={{ width: "300px" }}>{cctvAnalytics.enabledSchools}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "300px" }}>Number of Schools with Cameras</th>
                                             <td style={{ width: "300px" }}>{cctvAnalytics.totalCamsInUse}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "300px" }}>Activated Cameras</th>
                                            <td style={{ width: "300px" }}>{cctvAnalytics.activatedCams}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "300px" }}>Deactivated Cameras </th>
                                            <td style={{ width: "300px" }}>{cctvAnalytics.deactivatedCams}</td>
                                        </tr>
                                        <tr>
                                            <th scope="row" style={{ width: "300px" }}>Average Camera per school</th>
                                            <td style={{ width: "300px" }}>{cctvAnalytics.avgCams}</td>
                                        </tr>
                                    </tbody>
                                </Table>
                                )}
                                </Col>
                                <Col lg='2' className="float-end ml-auto" style={{marginRight:'0px'}}>
                                    {customActiveTab === '3' && (<button className="btn btn-primary" onClick={downloadReport}>Download Report</button>)}
                                </Col>
                                </Row>
                                <Nav pills className="navtab-bg nav-justified mb-2">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1",cameraList);
                                        }}
                                    >
                                        {props.t('Activated').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2",cameraList);
                                        }}
                                    >
                                        {props.t('Deactivated').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                                {schoolId == "streams" && <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "3",
                                        })}
                                        onClick={() => {
                                            toggleCustom("3");
                                        }}
                                    >
                                        {props.t('Details').toUpperCase()}
                                    </NavLink>
                                </NavItem>}
                            </Nav>

                            
                            {customActiveTab === "3" && (
                                <div className="table-responsive mt-4">
                                    {schoolCameraDetails.length === 0 ? (
                                        <h6 className="text-center mt-3" style={{ color: "blue" }}>No CCTV Camera to display!!</h6>
                                    ) : (
                                        <table className="table table-centered mb-0 mt-4">
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>#</th>
                                                    <th>School Name</th>
                                                    {/* <span className="text-primary" onClick={() => downloadReport`() }> download</span> */}
                                                    <th style={{maxWidth: "200px"}}>Activated Cameras</th>
                                                    <th style={{maxWidth:"180px"}}>Deactivated Cameras</th>
                                                    <th>Details</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {schoolCameraDetails.map((detail, index) => (
                                                    <tr key={schoolId}>
                                                        <td>{index + 1}</td>
                                                        <td>
                                                            {detail.schoolName}<br/>
                                                            {detail.schoolId}
                                                        </td>
                                                        <td className="text-center">{detail.activatedCameraDetails}</td>
                                                        <td className="text-center">{detail.deactivatedCameraDetails}</td>
                                                        <td><Link to={"/tools-school-settings-cameraDetails/" + detail.schoolId}><u>Details</u></Link></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    )}
                                </div>
                            )}
                            {(displayList.length === 0  && customActiveTab != "3" )&& (
                                <h6 className="text-center mt-3" style={{ color: "blue" }}>No CCTV Camera to display!!</h6>
                            )}
                                {(displayList.length > 0 && customActiveTab != "3") && (
                                    <div className="table-responsive">
                                        <table className="table table-centered mb-0 mt-4" >
                                            <thead className="thead-light">
                                                <tr>
                                                    <th>{props.t("#")}</th>
                                                    <th>{props.t("DVR/NVR Camera")}</th>
                                                    <th>{props.t("RTSP Link")}</th>
                                                    <th>{props.t("HLS Link")}</th>
                                                    {customActiveTab === "1" ? (<th>{props.t("Edit")}</th>) : ( null)}
                                                    {customActiveTab === "1" ? (<th>{props.t("Delete")}</th>) : (<th>{props.t("Restore")}</th>)}
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {displayList.map((camera, key) => (
                                                    <tr key={key}>
                                                        <td >
                                                            {key + 1}
                                                        </td>
                                                        <td style={{ maxWidth: "250px"}}>
                                                            {camera.attributes.name}<br/>
                                                            {(schoolId=="streams" && camera.attributes.schoolId && schoolNames[camera.attributes.schoolId])??camera.attributes.schoolId}
                                                        </td>
                                                        <td style={{ maxWidth: "200px" }}>
                                                            {camera.attributes.rtsp}
                                                        </td>
                                                        <td style={{ maxWidth: "200px" }}>
                                                            {camera.attributes.hls}
                                                        </td>
                                                        {customActiveTab === "1" ?
                                                        <td>
                                                            <div className="flex-fill text-center ">
                                                                <Label
                                                                    onClick={() => {
                                                                        setShowAddCCTVModel(true);
                                                                        setCameraLiveStreamForEdit(camera);
                                                                        setShowCameraName(camera.attributes.name);
                                                                        setShowRtspLink(camera.attributes.rtsp);
                                                                        setShowHlsLink(camera.attributes.hls);
                                                                        setShowHTMLLink(camera.attributes.htmlUrl);
                                                                        setAddedDate(camera.attributes.addedDate);
                                                                        setNote(camera.attributes.note);
                                                                        setCameraNumber(camera.attributes.cameraNumber)

                                                                    }}
                                                                    id={"edit-cctv" + key}>
                                                                    <i className="bx bx-edit-alt"></i>
                                                                    <UncontrolledTooltip placement="top" target={"edit-cctv" + key}>
                                                                        {props.t('Edit')}
                                                                    </UncontrolledTooltip>
                                                                </Label>
                                                            </div>
                                                        </td> : null}
                                                        <td>
                                                            <div className="flex-fill text-center">
                                                                {customActiveTab === "1" ? (
                                                                    <Label
                                                                        onClick={() => {
                                                                            setCameraToDelete(camera);
                                                                            setShowDeleteAlert(true);
                                                                        }}
                                                                        id={"delete-cctv" + key}
                                                                    >
                                                                        <i className="bx bxs-trash text-danger"></i>
                                                                        <UncontrolledTooltip
                                                                            placement="top"
                                                                            target={"delete-cctv" + key} 
                                                                        >
                                                                            {props.t('Delete')}
                                                                        </UncontrolledTooltip>
                                                                    </Label>
                                                                ) : (
                                                                    <div
                                                                        onClick={() => {
                                                                            setShowAddCCTVModel(true);
                                                                            setCameraToRestore(camera);
                                                                            setShowCameraName(camera.attributes.name);
                                                                            setShowRtspLink(camera.attributes.rtsp);
                                                                            setShowHlsLink(camera.attributes.hls);
                                                                            setShowHTMLLink(camera.attributes.htmlUrl);
                                                                            setNote(camera.attributes.note)
                                                                            setAddedDate(camera.attributes.addedDate);
                                                                            setCameraNumber(camera.attributes.cameraNumber)
                                                                        }}
                                                                        id={"restore-cctv" + key}
                                                                        >
                                                                        <i className="fas fa-trash-restore text-primary"></i>
                                                                        <UncontrolledTooltip placement="top" target={"restore-cctv" + key}>
                                                                            {props.t('Restore')}
                                                                        </UncontrolledTooltip>
                                                                    </div>
                                                                )}
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                )}

                            </CardBody>
                        </Card>
                    </Container>
                )}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading CCTV details...{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>

            <Modal
                isOpen={showAddCCTVModel}
                size="lg"
                toggle={() => {
                    setShowAddCCTVModel(false);
                    setShowCameraName(undefined);
                    setShowRtspLink(undefined);
                    setShowHTMLLink(undefined)
                    setShowHlsLink(undefined);
                    setAddedDate(undefined);
                    setCameraNumber(undefined);
                    setNote(undefined);
                    setCameraLiveStreamForEdit(undefined);
                }} className="">
                <ModalHeader toggle={() => {
                    setShowAddCCTVModel(false);
                    setShowCameraName(undefined);
                    setShowRtspLink(undefined);
                    setShowHTMLLink(undefined)
                    setShowHlsLink(undefined);
                    setAddedDate(undefined);
                    setCameraNumber(undefined);
                    setNote(undefined);
                    setCameraLiveStreamForEdit(undefined);
                }} tag="h4">
                    {selectedCameraLiveStreamForEdit ? "Edit CCTV" : 
                    (customActiveTab === "1" && !selectedCameraLiveStreamForEdit) ? "Add CCTV" : 
                    customActiveTab === "2" ? "Restore CCTV" : null}
                </ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleAddCCTVSubmit}>
                        <Row form>

                            <Col className="col-12">

                                <AvField name="DVR/NVR Camera"
                                    label={props.t('DVR/NVR Camera')}
                                    type="text"
                                    placeholder={props.t('DVR/NVR Camera')}
                                    errorMessage={props.t('DVR/NVR Camera can not be empty')}
                                    validate={{
                                        required: { value: true }
                                    }}
                                    value={showCameraName}
                                    onChange={(e) => {
                                        setShowCameraName(e.target.value);
                                    }}

                                />
                            </Col>
                            <Col className="col-12">
                                <label >{props.t('Added Date')}</label>
                                <DatePicker
                                    selected={addedDate}
                                    onChange={(date) => {
                                        setAddedDate(date); 
                                    }}
                                    placeholderText={props.t('Select a date')}
                                    dateFormat="yyyy-MM-dd"
                                    className="form-control mt-0 mb-2"
                                />
                            </Col>
                            <Col className="col-12">
                                <AvField
                                    name="RTSP Link"
                                    label={props.t('RTSP Link')}
                                    type="text"
                                    placeholder={props.t('RTSP Link')}
                                    value={showRtspLink}
                                    onChange={(e) => {
                                        setShowRtspLink(e.target.value);
                                    }}
                                />
                            </Col>
                            <Col className="col-12">

                                <AvField name="HLS Link"
                                    label={props.t('HLS Link')}
                                    type="text"
                                    placeholder={props.t('HLS Link')}
                                    errorMessage={props.t('HLS Link can not be empty')}
                                    validate={{
                                        required: { value: true },
                                        pattern: {
                                            value:
                                                "^((http|https)://)[-a-zA-Z0-9@:%._\\+~#?&//=]{2,256}\\.[a-z]{2,6}\\b([-a-zA-Z0-9@:%._\\+~#?&//=]*)$",
                                            errorMessage: props.t("You have entered an invalid HLS Link!"),
                                        },
                                    }}
                                    value={showHlsLink}
                                    onChange={(e) => {
                                        setShowHlsLink(e.target.value);
                                    }}

                                />

                            </Col>
                            <Col className="col-12">
                                <AvField
                                    name="Camera Number"
                                    label={props.t('Camera Number')}
                                    type="text"
                                    placeholder={props.t('Camera Number')}
                                    value={cameraNumber}
                                    onChange={(e) => {
                                        setCameraNumber(e.target.value);
                                    }}
                                />
                            </Col>
                            <Col className="col-12">
                                <AvField
                                    name="Camera Note"
                                    label={props.t('Remarks')}
                                    type="textarea"
                                    placeholder={props.t('Note')}
                                    value={note}
                                    rows={3}
                                    onChange={(e) => {
                                        setNote(e.target.value);
                                    }}
                                />
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="float-end">
                                    <button type="button" className="btn btn-light m-1" onClick={() => {
                                        setShowAddCCTVModel(false);
                                        setShowCameraName(undefined);
                                        setShowRtspLink(undefined);
                                        setShowHlsLink(undefined);
                                        setShowHTMLLink(undefined);
                                        setCameraNumber(undefined);
                                        setAddedDate(undefined);
                                        setNote(undefined);
                                        setCameraLiveStreamForEdit(undefined);
                                    }}>{props.t('Close')}</button>
                                    {customActiveTab === "1" ? (
                                        <button type="submit" className="btn btn-success save-event m-1">
                                            {props.t('Save')}
                                        </button>
                                    ) : customActiveTab === "2" ? (
                                        <button type="button" className="btn btn-primary restore-event m-1" onClick={handleRestoreCamera}
                                        >{props.t('Restore')}
                                        </button>
                                    ) : null}

                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>

        </React.Fragment>
    );


}

const mapStatetoProps = (state) => {
    const { toolsSchools } = state.School;
    return {toolsSchools};
};
export default withNamespaces()(withRouter(connect(mapStatetoProps, {getSchoolsForTools})(SchoolCameraDetails)));