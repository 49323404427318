import React, { useEffect, useState } from 'react';
import {
    Container, Row, Col,
    Card,
    CardBody,
} from "reactstrap";
import { matchPath } from "react-router";
//i18n

import Parse from "parse";

import img1 from "../../assets/images/covidDashboardIcons/img1.svg";
import img2 from "../../assets/images/covidDashboardIcons/img2.svg";
import img3 from "../../assets/images/covidDashboardIcons/img3.svg";
import img4 from "../../assets/images/covidDashboardIcons/img4.svg";
import img5 from "../../assets/images/covidDashboardIcons/img5.svg";


const Index = (props) => {

    const vaccination = "vaccination"
    const socialDistance = "socialDistance"
    const mask = "mask"
    const temperatureChecks = "temperatureChecks"
    const sanitisation = "sanitisation"


    const match = matchPath(props.history.location.pathname, {
        path: "/covidsops/:id",
        exact: true,
        strict: false,
    });

    var schoolId = match.params.id;



    const [mainList, setMainList] = useState([])
    const [isLoading, setIsLoading] = useState(true)
    const [schoolObject, setSchoolObject] = useState(undefined)

    const firstDose = "firstDose"
    const secondDose = "secondDose"

    useEffect(() => {
        if (schoolId && schoolId.length > 0) {
            fetchSchoolFromServer(schoolId)
        }
    }, [schoolId])

    const fetchSchoolFromServer = (schoolId) => {
        Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
            schoolId: schoolId,
        }).then(
            (result) => {

                if (result != null) {
                    setSchoolObject(result);
                    getCovidSOPsForSchoolId(schoolId)
                } else {
                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const getCovidSOPsForSchoolId = (schoolId) => {
        var query = new Parse.Query("CovidSOPs");
        query.equalTo("schoolId", schoolId)
        query.limit(1000);
        query.find().then((results) => {
            if (results && results.length > 0) {
                let tempList = []
                for (const i in results) {
                    let tempObject = {
                        title: results[i].attributes.type,
                        detailText: results[i].attributes.detailText,
                        parseObject: results[i],
                        type: results[i].attributes.type,
                        isEnabled: false,
                    }
                    if (results[i].attributes.type === vaccination) {
                        tempObject.img = img1
                    } else if (results[i].attributes.type === sanitisation) {
                        tempObject.img = img2
                    } else if (results[i].attributes.type === mask) {
                        tempObject.img = img3
                    } else if (results[i].attributes.type === temperatureChecks) {
                        tempObject.img = img4
                    } else if (results[i].attributes.type === socialDistance) {
                        tempObject.img = img5
                    }
                    tempList.push(tempObject)
                }
                setMainList(tempList)
            }
            setIsLoading(false)

        })

    }


    return (

        <React.Fragment>

            <div className="mt-4">
                {!isLoading && <Container>
                    <center>
                        {schoolObject && schoolObject.attributes.logo && (
                            <img
                                src={schoolObject.attributes.logo._url}
                                width="100"
                                alt=""
                                className="img-thumbnail rounded-circle"
                            />
                        )}
                        <h4 className="mb-0 font-size-18">{"Covid SOPS"}</h4>
                    </center>

                    <Row>
                        <Col>

                            <div className="list-group list-group-flush mt-4">

                                {
                                    mainList && mainList.map((record, key) => (
                                        <Card key={key} className="border" style={{
                                            minHeight: "200px"
                                        }}>
                                            <CardBody>
                                                <Row className="m-2">
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <center><img
                                                            src={record.img}
                                                            alt=""
                                                        /></center>
                                                    </Col>
                                                    <Col lg={6} md={6} sm={12} xs={12}>
                                                        <div className="">
                                                            <h1 className="font-size-18 mt-2">
                                                                {record.title}
                                                            </h1>
                                                            <p className="">{record.detailText ?? "Description..."}</p>
                                                        </div>
                                                    </Col>
                                                </Row>
                                            </CardBody>
                                        </Card>
                                    ))
                                }
                            </div>
                        </Col>
                    </Row>
                </Container>}
                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <div className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading Covid SOPS...{" "}
                                </div>
                            </div>
                        </Col>
                    </Row>
                )}
            </div>
        </React.Fragment >
    );
}


export default (Index);