import Parse from "parse";

export const getInventoryKitForOwnerId = (ownerId, ownerType, showInStore, updatedDate,isLocal,) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryKit = Parse.Object.extend("InventoryKits");
        var query = new Parse.Query(InventoryKit);
        query.equalTo("ownerId", ownerId);
        query.equalTo("ownerType", ownerType);
        if(showInStore){
          query.equalTo("showInStore", showInStore);
        }
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
       } else {
         query.notEqualTo("isDeleted", true);
       }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
  
        query.descending("createdAt");
        query.find().then(
          (result) => {
           resolve(result); 
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };

  export const editInventoryKit = (inventoryKit) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryKit.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) {}
  };

  export const deleteInventoryKitFromParse = (inventoryKit) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryKit.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            inventoryKit.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            inventoryKit.set("deletedBy", Parse.User.current().id);
        }
  
        inventoryKit.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("error");
          }
        );
      });
    } catch (error) {}
  };
  
  export const getInventoryKitFromLocal = (inventoryKitId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryKit = Parse.Object.extend("InventoryKits");
        var query = new Parse.Query(InventoryKit);
        query.fromLocalDatastore();
        query.get(inventoryKitId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

 export const getInventoryKitFromParse = (inventoryKitId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryKit = Parse.Object.extend("InventoryKits");
        var query = new Parse.Query(InventoryKit);
        query.get(inventoryKitId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };
  
