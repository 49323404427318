import Parse from "parse";
import { printLog } from "./loghelper";

export const getAcademicCycleFromParse = (academicCycleId) => {
  printLog("HL: academicCycles_helper : getAcademicCycleFromParse")
  try {
    return new Promise((resolve, reject) => {
      var AcademicCycle = Parse.Object.extend("AcademicCycles");
      var query = new Parse.Query(AcademicCycle);
      query.get(academicCycleId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}

export const deleteAcademicCycleFromParse = (academicCycle) => {
  printLog("HL: academicCycles_helper : deleteAcademicCycleFromParse")

  try {
    return new Promise((resolve, reject) => {
      academicCycle.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        academicCycle.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        academicCycle.set("deletedBy", Parse.User.current().id);
      }
      academicCycle.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};
   
export const getAcademicCycleForSchoolId = (id, updatedDate, isLoacal) => {
  printLog("HL: academicCycles_helper : getAcademicCycleForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var AcademicCycle = Parse.Object.extend("AcademicCycles");
      var query = new Parse.Query(AcademicCycle);
      query.equalTo("schoolId", id);
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.addAscending("order");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getAcademicCycleForCurriculumId = (id, updatedDate, isLoacal) => {
  printLog("HL: academicCycles_helper : getAcademicCycleForCurriculumId")
  try {
    return new Promise((resolve, reject) => {
      var AcademicCycle = Parse.Object.extend("AcademicCycles");
      var query = new Parse.Query(AcademicCycle);
      query.equalTo("curriculumId", id);
      if (updatedDate && false) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.addAscending("order");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
  
export const saveCycleObjects = (objects) => {
  printLog("HL: academicCycles_helper : saveCycleObjectse")
  try {	
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
}
const saveCycleObject = (object) => {
  printLog("HL: academicCycles_helper : saveCycleObject")

  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) {
    console.log(error)
  }
};