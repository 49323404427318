import React, { useEffect, useState } from 'react';
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  CardHeader,
  CardTitle
} from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { getAllKids,
    getClassroomsForSchoolId,
    getAdmissionKidsForSchool,
    getAllDeletedKidForSchool,
    getProgramsForSchoolId,
    getGroupMapsForProgramGroupIds,
    setAcademicCycleLodingState,
} from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import DatePicker from "react-datepicker";
import Moment from 'moment';

const ProgramClassReport = (props) => {


    const [called, setCalles] = useState(0)
    const [allInvoices, setAllInvoices] = useState(undefined);
    const [allPrograms, setAllPrograms] = useState([]);

    const [reportData, setReportData] = useState([]);

    useEffect(() => {
        if (props.onClickApply) {
            console.log("CALLED")
            getAllPrograms();
        }
    }, [props.onClickApply]);
    const getAllPrograms = () => {
        // props.getAllKids(props.schoolId)
        // props.getClassroomsForSchoolId(props.schoolId)
        // props.getAdmissionKidsForSchool(props.schoolId)
        // props.getAllDeletedKidForSchool(props.schoolId)
        setCalles(1)
        getProgramsForSchoolId(props.schoolId)

    }

    const getProgramsForSchoolId = (schoolId) => {
        var Invoice = Parse.Object.extend("Program");
        var query = new Parse.Query(Invoice);
        query.equalTo("schoolId", schoolId);
        query.greaterThanOrEqualTo('endDate', new Date())
        query.notEqualTo("deleted", true);
        query.find().then(
            (result) => {
                setAllPrograms(result)
            },
            (error) => {
                console.log("p", error);
            }
        );
    }

    useEffect(() => {

        if (allPrograms) {
            if (allPrograms.length > 0) {
                let groupIds = [];
                let programIds = [];
                let programs = [];
                let now = new Date();
                for (var i = 0; i < allPrograms.length; i++) {
                    if (allPrograms[i].attributes.endDate > now) {
                        groupIds.push(allPrograms[i].attributes.groupId);
                        programIds.push(allPrograms[i].id)
                        programs.push(allPrograms[i])
                    }
                }

                
                if (groupIds.length > 0) {
                    props.getGroupMapsForProgramGroupIds(groupIds);
                }
                if (programIds.length > 0) {
                    getInvoicesForProgrmIds(programIds);
                }
            }
        }

    }, [allPrograms]);
    
    
    const getInvoicesForProgrmIds = (programIds) => {
        var Invoice = Parse.Object.extend("Invoice");
        var query = new Parse.Query(Invoice);
        query.containedIn("programId", programIds);
        query.notEqualTo("isDeleted", true);
        query.find().then(
            (result) => {
                setAllInvoices(result)
            },
            (error) => {
                
            }
        );
    }
    
    useEffect(() => {

        let programList = [];

        if (allPrograms && allPrograms.length > 0 && props.programGroupmaps && props.kids && props.classrooms && allInvoices) {
            for (const program of allPrograms) {
                let allMaps = [];
                let kidIds = []
                let entry = [];

                for (const pgm of props.programGroupmaps) {
                    if (pgm.attributes.groupId == program.attributes.groupId) {
                        allMaps.push(pgm);
                        kidIds.push(pgm.attributes.userId);
                    }
                }

                if (kidIds.length > 0) {
                    for (const kidId of kidIds) {

                        let ko = getKid(kidId)

                        if (ko == null) {
                            continue;
                        }
                        let kid = ko.kid;
                        let inv = [];
                        let invAmount = 0;
                        let invPaidAmount = 0;
                        let pKid = false;
                        if (kid && kidIds.includes(kid.id)) {
                            inv = getInvoiceForKidAndProgram(kid.id, program.id);
                            pKid = true;


                            if (inv.length > 0) {
                                for (const i of inv) {
                                    invAmount += parseFloat(i.attributes.amount);
                                    invPaidAmount += i.attributes.paidAmount;
                                }
                            }
                        }
                    
                        let en = {
                            kid: kid,
                            kidName: kid.attributes.Name,
                            className: getClassForId(kid.attributes.ClassRoomID),
                            invoices: inv,
                            invoiceCount: inv.length,
                            invoiceAmount: invAmount,
                            invoicePaidAmount: invPaidAmount,
                            invoiceBalence: invAmount - invPaidAmount,
                            programKid: pKid,
                            kidState: ko.state,
                        }

                        entry.push(en);
                    }

                    for (const kid of props.kids) {
                        if (!kidIds.includes(kid.id)) {
                            let en = {
                                kid: kid,
                                kidName: kid.attributes.Name,
                                className: getClassForId(kid.attributes.ClassRoomID),
                                invoices: [],
                                invoiceCount: 0,
                                invoiceAmount: 0,
                                invoicePaidAmount: 0,
                                invoiceBalence: 0,
                                programKid: false,
                                kidState: "not in program",
                            }

                            entry.push(en);
                        }
                    }
                }

                let programEntry = {
                    program: program,
                    programEntries: entry
                }
            
                programList.push(programEntry);
    
            }

            setReportData(programList);
            setCalles(2)
        }

       
        

    }, [allPrograms, props.programGroupmaps, props.kids, props.classrooms, allInvoices,]);
    
    const getKid = (kidId) => {
        let sk = props.kids && props.kids.filter(
            (k) => k.id === kidId);
        
        if (sk.length > 0) {
            return {
                kid: sk[0],
                state: "in program and in school"
            }
        }

        let ak = props.admissionKids && props.admissionKids.filter(
            (k) => k.id === kidId);
        
        if (ak.length > 0) {
            return {
                kid: ak[0],
                state: "in program and in admissions"
            }
        }

        let dk = props.allDeletedKids && props.allDeletedKids.filter(
            (k) => k.id === kidId);
        
        if (dk.length > 0) {
            return {
                kid: dk[0],
                state: "in program and deleted"
            }
        }

        return null;
    }
    
    const getInvoiceForKidAndProgram = (kidId, programId) => {
        let temp = allInvoices && allInvoices.filter(
            (invoice) => invoice.attributes.recipientId === kidId && invoice.attributes.programId === programId);
        
        return temp;
    }

    const getClassForId = (classId) => {
        let temp = props.classrooms && props.classrooms.filter(
            (c) => c.id === classId);
        
        return temp.length > 0 ? temp[0].attributes.Name : "";
    }


    const onClickDownload = () => {
       
            
        let finalData = [];
        let fileName = `programClassConsolidatedReport.xlsx`;

        finalData.push(["program &b class consolidated report"]);
        finalData.push([

            "Student Name",
            "Class Name",
            "Program Name",
            "Student status",
            "Number of invoices",
            "Invoice Amount",
            "Invoice Paid Amount",
            "Invoice Paid balence"
        ]);

        

        for (const pl of reportData) {
            let program = pl.program;
            let entries = pl.programEntries;

            let programName = program.attributes.name;

            for (const et of entries) {
                finalData.push([
                    et.kidName,
                    et.className,
                    programName,
                    et.kidState,
                    et.invoiceCount + "",
                    et.invoiceAmount + "",
                    et.invoicePaidAmount + "",
                    et.invoiceBalence + "",
                ])
            }
            
        }
        

        // console.log(finalData)
         

        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, "data");
        XLSX.writeFile(wb, fileName);

       
    }

    return (
        <React.Fragment>

            <Card>
                <CardBody>
                    {called == 2 && reportData && reportData.length > 0 &&
                        <div className="mt-2 mb-2 p-1 text-center">
                            <button
                                onClick={() => {
                                    onClickDownload();
                                                    
                                }}
                                className="w-md  btn btn-primary"
                            >
                                <i className='bx bx-download font-size-16 align-middle mr-2'></i>
                                {props.t("Download Report")}
                            </button>
                        </div>
                    }
            
                    {
                        called == 1 && <Col xs="12">
                            <div className="text-center my-3">
                                <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>  Preparing Report</Label>
                            </div>
                        </Col>
                    }
                    
                </CardBody>
            </Card>
            
        </React.Fragment>
    );
}
  
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { programs } = state.Program;
    const { programGroupmaps } = state.GroupMap;
    
    const { kids, admissionKids, allDeletedKids } = state.Kid;
    const { classrooms } = state.Classroom;

    return { schools, selectedSchool, programs, programGroupmaps,kids, admissionKids, allDeletedKids,classrooms };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getAllKids,
            getClassroomsForSchoolId,
            getAdmissionKidsForSchool,
            getAllDeletedKidForSchool,
            getProgramsForSchoolId,
            getGroupMapsForProgramGroupIds,
        })(ProgramClassReport)
    )
);
