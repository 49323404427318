import React, { Component } from 'react';
import {
    Button,

    FormGroup,
    Label,

    Row,
    Container,
    CardBody,
    Card,
    Col,
} from "reactstrap";
import { withNamespaces } from 'react-i18next';

import profile from "../../assets/images/profile-img.png";
import * as WhiteLable from "../../components/Common/WhiteLable";

import { matchPath } from "react-router";

import { AvForm, AvField } from "availity-reactstrap-validation";
import Parse from 'parse'
import { Link } from "react-router-dom";
import logo from '../../assets/images/lilLogo.png'
import { v4 as uuidv4 } from "uuid";
import { useState, useEffect } from "react";

const EmailVerification = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/email-verification/:emailId",
        exact: true,
        strict: false,
    });


    const [validErrorMessage, setValidErrorMessage] = useState(undefined);
    const [email, setEmail] = useState(match ? match.params.emailId : "");
    const [requiredErrorMessage, setRequiredErrorMessage] = useState(undefined);
    const [selectedStage, setSelectedStage] = useState(1);
    const [modal, setModal] = useState(true);
    const [runningCloudFunction, setRunningCloudFunction] = useState(false);
    const [uuid, setUUID] = useState(uuidv4());
    const [otp, setOTP] = useState("");
    const [initilaLoad, setInitilaLoad] = useState(true);
    const [isVerified, setIsVerified] = useState(false);
    const [serverMessage, setServerMessage] = useState(undefined);
    const [count, setCount] = useState(30);
    const [showResendButton, setShowResendButton] = useState(false);
    const [startTimer, setStartTimer] = useState(false);


    useEffect(() => {
        if (startTimer) {
            const timer = setTimeout(() => {
                setCount(count - 1);
            }, 1000);

            if (count > 0) {
                if (showResendButton) {
                    setShowResendButton(false)
                }
            } else {
                setShowResendButton(true)
                return () => clearTimeout(timer);

            }
        }

    }, [startTimer, count])



    const validateEmail = (email) => {
        return String(email)
            .toLowerCase()
            .match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
    };

    const handleSendOTP = (resend) => {

        let parameters = {}
        parameters.email = email
        parameters.uuid = uuid
        if (resend) {
            setCount(59)
            parameters.resend = resend
        }
        setRunningCloudFunction(true)
        Parse.Cloud.run("function_RequestVerifyUserOTP", parameters).then(
            (result) => {
                setInitilaLoad(false)
                setStartTimer(true)
                setRunningCloudFunction(false)

                if (result === 1) {
                    setServerMessage(undefined)
                    setSelectedStage(2)
                } else if (result === 2) {
                    setServerMessage("User does not exists")
                } else if (result === 3) {
                    setServerMessage("Invalid email address")
                } else if (result === 4) {
                    setServerMessage("Not logged in")
                } else if (result === 5) {
                    setServerMessage("User does not match !!")
                }
            },
            (error) => {
                setStartTimer(true)
                setInitilaLoad(false)
                setRunningCloudFunction(false)
            }
        );
    }

    const handleVerifyOTP = () => {
        let parameters = {}
        parameters.email = email
        parameters.uuid = uuid
        parameters.otp = otp

        setRunningCloudFunction(true)

        Parse.Cloud.run("function_VerifyUserBasedOnOTP", parameters).then(
            (result) => {
                setRunningCloudFunction(false)


                if (result === 1) {
                    setIsVerified(true)
                    setServerMessage(undefined)
                } else if (result === 2) {
                    setServerMessage("User does not exists")
                } else if (result === 3) {
                    setServerMessage("Wrong OTP entered")
                } else if (result === 4) {
                    setServerMessage("User not logged in !!")
                } else if (result === 5) {
                    setServerMessage("User does not match !!")
                }
            },
            (error) => {
                setRunningCloudFunction(false)
                setServerMessage("Please try after some time.")

            }
        );
    }

    const getUIBasedOnSelectedStage = () => {

        let headerText = ""
        let textFieldHeader = ""
        let placeholder = ""
        let tagName = ""
        let buttonName = ""
        let value = ""
        let validationErrorMessage = ""
        let validationRegex = ""
        let featherIconText = ""
        let avFieldType = "text"

        if (selectedStage === 1) {
            headerText = "Please enter your email address to recieve OTP"
            textFieldHeader = "Email Address"
            placeholder = "Enter Email"
            tagName = "email"
            buttonName = "Send"
            value = email
            validationErrorMessage = "E-Mail is not valid!"
            validationRegex = "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$"
            featherIconText = "mail"
        } else if (selectedStage === 2) {
            headerText = "Please enter OTP. which you received via E-mail"
            textFieldHeader = "OTP"
            placeholder = "Enter OTP"
            tagName = "otp"
            buttonName = "Verify"
            value = otp
            validationErrorMessage = "OTP is not valid"
            validationRegex = ""

            featherIconText = "lock"

        }
        return (
            <>
                <div className="home-btn d-none d-sm-block">
                    <Link to="/" className="text-dark">
                        <i className="fas fa-home h2"></i>
                    </Link>
                </div>
                <div className="account-pages my-5 pt-sm-5">
                    <Container>
                        <Row className="justify-content-center">
                            <Col md={8} lg={6} xl={5}>
                                {


                                    <Card className="overflow-hidden">
                                        <div className="bg-soft-primary">
                                            <Row>
                                                <Col className="col-8">
                                                    <div className="text-primary p-4">
                                                        <h5 className="text-primary">{isVerified ? "Verification Done" : props.t('Verify Email !!')}</h5>
                                                        <p>{isVerified ? "" : headerText}</p>
                                                    </div>
                                                </Col>
                                                <Col className="col-4 align-self-end">
                                                    <img src={profile} alt="" className="img-fluid" />
                                                </Col>
                                            </Row>
                                        </div>
                                        <CardBody className="pt-0">
                                            <div>
                                                <Link to="/">
                                                    <div className="avatar-md profile-user-wid mb-4">
                                                        <span className="avatar-title rounded-circle bg-light">
                                                            <img
                                                                src={WhiteLable.getAppLogo()}
                                                                alt=""
                                                                className="rounded-circle"
                                                                height="34"
                                                            />
                                                        </span>
                                                    </div>
                                                </Link>
                                            </div>
                                            <div className="p-2">

                                                {isVerified ? <div className="bg-white p-3 rounded box-shadow justify-content-center">
                                                    <Col >
                                                        <div className="features text-center">
                                                            <div className="image position-relative d-inline-block">
                                                                <i style={{ fontSize: 50 }} className="mdi mdi-checkbox-marked-circle-outline mdi-4px text-success"></i>
                                                            </div>

                                                            <div className="content ">
                                                                <h4 className="title-2 m-2 ">Successfully verified</h4>
                                                                <Link className="m-2 btn btn-success" to="/login" color="success">Continue</Link>
                                                            </div>
                                                        </div>
                                                    </Col>

                                                </div> :
                                                    <AvForm className="login-form mt-4">
                                                        <Row>
                                                            <Col lg="12">

                                                                <FormGroup className="position-relative">
                                                                    <Label for={tagName}>
                                                                        {textFieldHeader}{" "}
                                                                        <span className="text-danger">*</span>
                                                                    </Label>
                                                                    {serverMessage && <h6 className="text-danger p-0 m-0">{serverMessage}</h6>}


                                                                    <AvField
                                                                        type={avFieldType}
                                                                        className="form-control "
                                                                        name={tagName}
                                                                        id={tagName}
                                                                        placeholder={placeholder}
                                                                        value={value}
                                                                        onChange={(e) => {
                                                                            if (e.target.value.length > 0) {
                                                                                setRequiredErrorMessage(undefined)
                                                                            }
                                                                            if (selectedStage === 1) {
                                                                                if (!validateEmail(e.target.value)) {
                                                                                } else {
                                                                                    setValidErrorMessage(undefined)
                                                                                }
                                                                                setEmail(e.target.value)
                                                                            } else if (selectedStage === 2) {
                                                                                if (e.target.value.length === 6) {
                                                                                    setValidErrorMessage(undefined)
                                                                                }
                                                                                setOTP(e.target.value)
                                                                            }
                                                                        }}

                                                                    />


                                                                    {requiredErrorMessage && <p className="text-danger p-0 m-0 font-weight-light"><small>{requiredErrorMessage}</small></p>}
                                                                    {validErrorMessage && <p className="text-danger p-0 m-0 font-weight-light"><small>{validErrorMessage}</small></p>}
                                                                    {serverMessage && <p className="text-danger p-0 m-0 font-weight-light"><small>{serverMessage}</small></p>}
                                                                </FormGroup>
                                                            </Col>
                                                            <Col lg="12">
                                                                <Button
                                                                    onClick={() => {
                                                                        if (selectedStage === 1) {
                                                                            handleSendOTP()
                                                                        } else if (selectedStage === 2) {
                                                                            handleVerifyOTP()
                                                                        }
                                                                    }}
                                                                    color="primary"
                                                                    block
                                                                    disabled={runningCloudFunction}
                                                                >
                                                                    {runningCloudFunction && <i className="bx bx-loader bx-spin"></i>}
                                                                    {runningCloudFunction ? "" : buttonName}
                                                                </Button>
                                                                {
                                                                    selectedStage === 2 &&

                                                                    <div className="mt-2">
                                                                        {
                                                                            showResendButton ? <Label className="border-bottom text-primary" style={{ cursor: 'pointer' }} onClick={() => { handleSendOTP(true) }}> Resend OTP</Label> :
                                                                                <Label>Resend OTP in 00:{count}</Label>
                                                                        }


                                                                    </div>
                                                                }
                                                            </Col>

                                                        </Row>
                                                    </AvForm>}
                                            </div>
                                        </CardBody>
                                    </Card>}
                                <div className="mt-5 text-center">
                                    <p>
                                        {props.t('Go back to ')}
                                        <Link to="/login" className="font-weight-medium text-primary">
                                            {props.t('Login')}
                                        </Link>
                                    </p>
                                    <p>
                                        © {new Date().getFullYear()} {WhiteLable.getFooterName()}. {WhiteLable.getDesignTag()}
                                    </p>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </div>
            </>
        );


    }


    return (

        <React.Fragment>

            {getUIBasedOnSelectedStage()}

        </React.Fragment>


    );
}

export default (withNamespaces()(EmailVerification));

