import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";

const initialState = {
    exams: [], loding: undefined, selectedExam: []
};

const Exam = (state = initialState, action) => {
    switch (action.type) {

        case actions.UPDATE_EXAMS_FOR_SCHOOL_ID:
            var exams = [...state.exams];
            if (exams.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.exams], action.payload.exams, "isDeleted")
                state = { ...state, exams: updatedList };
            } else {
                var others = action.payload.exams.filter(
                    (exams) => exams.attributes.isDeleted !== true
                );

                state = { ...state, exams: others };
            }

            break;

        case actions.UPDATE_EXAM:
            state = { ...state, selectedExam: action.payload.exam };
            break;

        case actions.UPDATE_DELETE_EXAM:
            var exams = state.exams.filter(
                (exam) => exam.id !== action.payload.exam.id
            );
            state = { ...state, exams: exams };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Exam;
