import * as actions from "./actionTypes";

const initialState = {
  courseBatch: [], courseBatchInitialLoding: true, selectedCourseBatch: undefined, addCourseBatchLoading: false, allCourseBatches: undefined,
};

const CourseBatch = (state = initialState, action) => {
  // console.log(action.type)

  switch (action.type) {
  
    case actions.UPDATE_COURSES_BATCH:
      if (action.payload.replace) {
        state = { ...state, courseBatch: action.payload.courseBatch };
      } else {
        var courseBatch = [...state.courseBatch];
        if (courseBatch.length > 0) {
          for (const latest in action.payload.courseBatch) {
            var temp = courseBatch.filter(
              (courseBatch) =>
                courseBatch.id === action.payload.courseBatch[latest].id
            );
            if (temp.length > 0) {
              if (action.payload.courseBatch[latest].attributes.isDeleted) {

                var others = courseBatch.filter(
                  (courseBatch) =>
                    courseBatch.id !== action.payload.courseBatch[latest].id
                );
               
                courseBatch = others;
              } else {
                var index = courseBatch.lastIndexOf(temp[0]);
                courseBatch[index] = action.payload.courseBatch[latest];
              }
            } else {
              courseBatch.unshift(action.payload.courseBatch[latest]);
            }
          }
          state = { ...state, courseBatch: courseBatch, };
        } else {
          var others = action.payload.courseBatch.filter(
            (courseBatch) => courseBatch.attributes.isDeleted !== true
          );
            
          state = { ...state, courseBatch: others, };
        }
      }
      break;
    case actions.DELETE_COURSE_BATCH:
      var courseBatch = state.courseBatch.filter(
        (courseBatch) => courseBatch.id !== action.payload.id
      );
      state = { ...state, courseBatch: courseBatch };
      
      break;
    
    case actions.GET_COURSE_BATCH_FOR_COURSE_ID:
      state = { ...state, courseBatch: [] };
      break;
    
      
    case actions.SELECTED_COURSE_BATCH:
      state = { ...state, selectedCourseBatch: action.payload.courseBatch };
      break;
    case actions.ADD_COURSE_BATCH_LOADING:
      state = { ...state, addCourseBatchLoading: action.payload.state };
      break;
    case actions.UPDATE_COURSE_BATCH_INITIAL_LOADING:
      state = { ...state, courseBatchInitialLoding: action.payload.state };
      break;
    
     case actions.GET_COURSE_BATCH_FOR_COURSE_IDS:
      state = { ...state, allCourseBatches: undefined };
      break;
     case actions.UPDATE_COURSE_BATCH_FOR_COURSE_IDS:
      state = { ...state, allCourseBatches: action.payload.courseBatch };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default CourseBatch;
