import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import Parse from 'parse';
import * as TeacherHelper from '../../../helpers/ParseHelpers/teacher_helper'
import * as TeacherActions from './actions';
import * as actionsTypes from './actionTypes';
import * as Constants from '../../../Constents';
import { actionTypes } from 'redux-form';
import toastr from 'toastr';
import school from '../school/reducer';

import { updateUserTeacher } from '../../auth/login/actions';

function* getATeacher({ payload: { id, isUpdate } }) {
    try {
        const response = yield call(TeacherHelper.getTeacherFromParse, id);
        if (response) {
            yield put(TeacherActions.updateTeacher(response));
            if (Parse.User.current() && Parse.User.current().attributes.teacherId === response.id) {
                yield put(updateUserTeacher(response))
            }
            response.pin();
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}





function* getWebAccess({ payload: { email, teacherRole, teacherId, levelId, level, teacher, instituteId } }) {
    try {
        const response = yield call(TeacherHelper.requestForWebAccess, email, teacherRole, teacherId, levelId, level, instituteId);
        if (response) {

            yield put(TeacherActions.responseForWebAccess(response));

            if (teacher && response.UserName) {
                teacher.set("isWebAccess", true);
                const response2 = yield call(TeacherHelper.editTeacher, teacher);
                if (response2) {
                    yield put(TeacherActions.updateTeacher(response2));
                }
            }


        }
    } catch (error) {
        // yield put(apiError(error));
    }
}

function* removeWebAccess({ payload: { email, teacherRole, teacherId, levelId, level, teacher } }) {
    try {
        const response = yield call(TeacherHelper.requestForWebAccessRemoval, email, teacherRole, teacherId, levelId, level, teacher);
        yield put(TeacherActions.responseForWebAccessRemoval(response));
        if (response) {
            if (teacher && response) {
                teacher.set("isWebAccess", false);
                const response2 = yield call(TeacherHelper.editTeacher, teacher);
                if (response2) {
                    yield put(TeacherActions.updateTeacher(response2));
                }
            }
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}

function* deleteATeacher({ payload: { id, successMessage } }) {
    try {
        const response = yield call(TeacherHelper.deleteTeacherFromParse, id);
        if (response) {
            response.pin();
            yield put(TeacherActions.deleteTeacherSuccess(response));
            window.history.back();

            toastr.success(successMessage, { timeOut: 2000 });

        } else {
            //not deleted
        }


    } catch (error) {
        // yield put(apiError(error));
    }
}

function* saveTeachersSaga({ payload: { teachers } }) {
    try {
        const response = yield call(TeacherHelper.saveTeachers, teachers);
    } catch (error) {
        // yield put(apiError(error));
    }
}


function* editTeacher({ payload: { teacher } }) {
    try {
        const response = yield call(TeacherHelper.editTeacher, teacher);
        if (response) {
            yield put(TeacherActions.updateTeacher(response));
            if (Parse.User.current() && Parse.User.current().attributes.teacherId === response.id) {
                yield put(updateUserTeacher(response))
            }
        } else {

        }
    } catch (error) {
        // yield put(apiError(error));
    }
}

function* getTeachersForSchoolId({ payload: { schoolId } }) {
    try {
        if (schoolId) {
            var key = Constants.TEACHER_UPDATED_TIME + "_" + schoolId;
            var updatedTime = localStorage.getItem(key)
            var newTime = new Date();
            var instituteId = null;
            const school = yield call(TeacherHelper.getObject, "School", schoolId, true);
            if (school) {
                instituteId = school.attributes.instituteId;
            }

            const localResponse = yield call(TeacherHelper.getTeachersForSchoolId, schoolId, instituteId, null, true);
            yield put(TeacherActions.updateTeachers(localResponse,schoolId));


            const serverResponse = yield call(TeacherHelper.getTeachersForSchoolId, schoolId, instituteId, updatedTime, false);
            localStorage.setItem(key, newTime)

            if (serverResponse && serverResponse.length > 0) {
                for (const o of serverResponse) {
                    if (Parse.User.current() && Parse.User.current().attributes.teacherId === o.id) {
                        yield put(updateUserTeacher(o))
                    }
                }
                yield put(TeacherActions.updateTeachers(serverResponse,schoolId));
                Parse.Object.pinAll(serverResponse);
            }
        }



    } catch (error) {
        // yield put(apiError(error));
    }
}

function* getDeletedTeachersForSchoolIdForMonthSaga({ payload: { schoolId, date } }) {

    try {
        const response = yield call(TeacherHelper.getDeletedTeachersForSchoolIdForMonth, schoolId, date);

        yield put(TeacherActions.updateDeletedTeachersForMonth(response));
    } catch (error) {
        // yield put(apiError(error));
    }
}

function* getDeletedTeachersForSchoolIdSaga({ payload: { schoolId } }) {

    try {
        const response = yield call(TeacherHelper.getDeletedTeachersForSchoolId, schoolId);
        yield put(TeacherActions.updateDeletedTeachers(response));
    } catch (error) {
        // yield put(apiError(error));
    }
}
function* getTeacherAttendanceForDaySaga({ payload: { schoolId } }) {

    try {
        const response = yield call(TeacherHelper.getTeacherAttendanceForDay, schoolId);
        yield put(TeacherActions.updateTeacherAttendanceForDay(response));
    } catch (error) {
        // yield put(apiError(error));
    }
}
function* getSchoolAttendanceSaga({ payload: { schoolId } }) {

    try {
        const response = yield call(TeacherHelper.getSchoolAttendance, schoolId);
        yield put(TeacherActions.updateSchoolAttendance(response));
    } catch (error) {
        // yield put(apiError(error));
    }
}


function* getDaycareAttendanceForDaySaga({ payload: { schoolId } }) {

    try {
        const response = yield call(TeacherHelper.getDaycareAttendanceForDay, schoolId);
        yield put(TeacherActions.updateDaycareAttendanceForDay(response));
    } catch (error) {
        // yield put(apiError(error));
    }
}







function* setSelectedTeacher({ payload: { id } }) {
    try {
        yield put(TeacherActions.setLoading(true));
        const localResponse = yield call(TeacherHelper.getTeacherFromParse, id, true);
        if (localResponse) {
            yield put(TeacherActions.updateSelecetdTeacher(localResponse));
        }

        const serverResponse = yield call(TeacherHelper.getTeacherFromParse, id, false);
        if (serverResponse) {
            yield put(TeacherActions.updateSelecetdTeacher(serverResponse));
            serverResponse.pin();
        }

    } catch (error) {
        // yield put(apiError(error));
    }
}

function* getTeacherForClass({ payload: { classId } }) {
    try {
        const response = yield call(TeacherHelper.getTeacherForClassParse, classId)
        yield put(TeacherActions.setTeacherForClassroom(response))
    } catch (error) { }
}

function* updateTeachersObj({ payload: { teachersObjs, classId } }) {
    try {
        const response = yield call(TeacherHelper.updateTeachers, teachersObjs)
        Parse.Object.pinAll(response)
        yield put(TeacherActions.getTeacherForClassroom(classId))
    } catch (error) { }
}

function* getFranchiseTeacher({ payload: { } }) {
    try {
        var id = Parse.User.current().attributes.franchiseId;
        if (id && id.length > 0) {
            const response = yield call(TeacherHelper.getTeachersForFranchiseId, id, null, false);
            yield put(TeacherActions.updateFranchiseTeachers(response));
        }
    } catch (error) { }
}

function* addFranchiseTeacher({ payload: { teacher } }) {
    try {


        if (teacher) {
            yield put(TeacherActions.updateFranchiseTeachers([teacher]));
        } else {

        }
    } catch (error) {
        // yield put(apiError(error));
    }
}


function* getTeacherForIdsSaga({ payload: { ids } }) {

    try {
        const response = yield call(TeacherHelper.getTeacherForIds, ids, true);

        if (response && response.length > 0) {
            yield put(TeacherActions.updateTeacherForIds(response));
            let newIds = []
            for (var i = 0; i < ids.length; i++) {

                let idsTemp = response.filter(
                    (res) => res.id === ids[i]
                );
                if (idsTemp && idsTemp.length > 0) {

                } else {
                    newIds.push(ids[i].id)

                }

            }



            if (newIds.length > 0) {
                const response3 = yield call(TeacherHelper.getTeacherForIds, ids, false);
                if (response3 && response3.length > 0) {

                    yield put(TeacherActions.updateTeacherForIds(response3, true));
                    Parse.Object.pinAll(response3)
                }
            }
        } else {
            const response2 = yield call(TeacherHelper.getTeacherForIds, ids, false);
            if (response2 && response2.length > 0) {
                yield put(TeacherActions.updateTeacherForIds(response2));
                Parse.Object.pinAll(response2)

            }
        }

    } catch (error) {
        // yield put(apiError(error));
    }
}


export function* watchGetTeacherForIdsSaga() {
    yield takeEvery(actionsTypes.GET_TEACHERS_FOR_IDS, getTeacherForIdsSaga)
}


export function* watchGetDeletedTeachersForSchoolIdSaga() {
    yield takeEvery(actionsTypes.GET_DELETED_TEACHERS, getDeletedTeachersForSchoolIdSaga)
}

export function* watchRequestForWebAccess() {
    yield takeEvery(actionsTypes.REQUEST_FOR_WEB_ACCESS, getWebAccess)
}


export function* watchGetTeacher() {
    yield takeEvery(actionsTypes.GET_TEACHER, getATeacher)
}
export function* watchSaveTeachersSaga() {
    yield takeEvery(actionsTypes.SAVE_TEACHERS, saveTeachersSaga)
}
export function* watchGetTeacherAttendanceForDaySaga() {
    yield takeEvery(actionsTypes.GET_TEACHER_ATTENDANCE_FOR_DAY, getTeacherAttendanceForDaySaga)
}
export function* watchGetDaycareAttendanceForDaySaga() {
    yield takeEvery(actionsTypes.GET_DAYCARE_ATTENDANCE_FOR_DAY, getDaycareAttendanceForDaySaga)
}
export function* watchGetSchoolAttendanceSaga() {
    yield takeEvery(actionsTypes.GET_SCHOOL_ATTENDANCE, getSchoolAttendanceSaga)
}
export function* watchGetTeachersForSchoolId() {
    yield takeEvery(actionsTypes.GET_TEACHERS, getTeachersForSchoolId)
}

export function* watchDeleteTeacher() {
    yield takeEvery(actionsTypes.DELETE_TEACHER, deleteATeacher)
}

export function* watchSetSelectedTeacher() {
    yield takeEvery(actionsTypes.SET_SELECTED_TEACHER, setSelectedTeacher)
}
export function* watchEditTeacher() {
    yield takeEvery(actionsTypes.EDIT_TEACHER, editTeacher)
}
export function* watchGetTeacherForClass() {
    yield takeEvery(actionsTypes.GET_TEACHER_FOR_CLASSROOM, getTeacherForClass)
}
export function* watchUpdateTeacherForClass() {
    yield takeEvery(actionsTypes.UPDATE_TEACHERS_OBJS, updateTeachersObj)
}
export function* watchRemoveWebAccess() {
    yield takeEvery(actionsTypes.REQUEST_FOR_WEB_ACCESS_REMOVAL, removeWebAccess)
}

export function* watchGetFranchiseAdmins() {
    yield takeEvery(actionsTypes.GET_FRANCHISE_TEACHERS, getFranchiseTeacher)
}

export function* watchAddFranchiseAdmin() {
    yield takeEvery(actionsTypes.ADD_FRANCHISE_TEACHER, addFranchiseTeacher)
}

export function* watchGetDeletedTeachersForSchoolIdForMonthSaga() {
    yield takeEvery(actionsTypes.GET_DELETED_TEACHERS_FOR_MONTH, getDeletedTeachersForSchoolIdForMonthSaga)
}


function* teacherSaga() {
    yield all([
        fork(watchRemoveWebAccess),
        fork(watchRequestForWebAccess),
        fork(watchGetTeacher),
        fork(watchGetTeachersForSchoolId),
        fork(watchDeleteTeacher),
        fork(watchSetSelectedTeacher),
        fork(watchEditTeacher),
        fork(watchGetTeacherForClass),
        fork(watchUpdateTeacherForClass),
        fork(watchGetDeletedTeachersForSchoolIdSaga),
        fork(watchSaveTeachersSaga),
        fork(watchGetTeacherAttendanceForDaySaga),
        fork(watchGetDaycareAttendanceForDaySaga),
        fork(watchGetSchoolAttendanceSaga),
        fork(watchGetFranchiseAdmins),
        fork(watchAddFranchiseAdmin),
        fork(watchGetDeletedTeachersForSchoolIdForMonthSaga),
        fork(watchGetTeacherForIdsSaga)
    ]);
}
export default teacherSaga;