export const UPDATE_LEARNING_CATEGORY = "UPDATE_LEARNING_CATEGORY";
export const DELETE_LEARNING_CATEGORY = "DELETE_LEARNING_CATEGORY";

export const UPDATE_LEARNING_CATEGORYS = "UPDATE_LEARNING_CATEGORYS";   
export const GET_LEARNING_CATEGORYS = "GET_LEARNING_CATEGORYS";
export const GET_LEARNING_CATEGORY = "GET_LEARNING_CATEGORY";
export const GET_LEARNING_CATEGORY_FOR_CURRICULUM_ID = "GET_LEARNING_CATEGORY_FOR_CURRICULUM_ID"

export const GET_LEARNING_CATEGORYS_FOR_CYCLE = "GET_LEARNING_CATEGORYS_FOR_CYCLE";

export const ADD_LEARNING_CATEGORY = "ADD_LEARNING_CATEGORY";
export const CLEAR_LEARNING_CATEORIES = "CLEAR_LEARNING_CATEORIES";

export const GET_SELECTED_LEARNING_CATEGORY = "GET_SELECTED_LEARNING_CATEGORY";
export const SET_SELECTED_LEARNING_CATEGORY = "SET_SELECTED_LEARNING_CATEGORY";
export const SET_LEARNING_CATEGORY_LOADING_STATE = "SET_LEARNING_CATEGORY_LOADING_STATE"; 

export const LEARNING_CATEGORY_REORDER = "LEARNING_CATEGORY_REORDER";

export const GET_LEARNING_CATEGORYS_FOR_COURSE = "GET_LEARNING_CATEGORYS_FOR_COURSE";

export const GET_LEARNING_CATEGORIES_FOR_IDS = "GET_LEARNING_CATEGORIES_FOR_IDS"