import React, { useState, useEffect } from "react";
import { Row, Col, Card, CardBody, Modal, ModalHeader, ModalBody, ModalFooter, Label,Alert } from "reactstrap";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Parse from 'parse';
import ReferSchoolImage2 from '../../assets/images/referschoolimage2.svg';
import ReferSchoolImage from '../../assets/images/referschool.jpeg';
import toastr from "toastr";
import { getUserTeacher } from '../../store/actions';

const ReferSchool = (props) => {
    const [instituteName, setInstituteName] = useState('');
    const [contactName, setContactName] = useState('');
    const [contactPhoneNumber, setContactPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [location, setLocation] = useState('');
    const [instituteNameError, setInstituteNameError] = useState('');
    const [contactNameError, setContactNameError] = useState(false);
    const [contactPhoneNumberError, setContactPhoneNumberError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [locationError, setLocationError] = useState('');
    const [offerDetails, setOfferDetails] = useState('');
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!props.isOpen) {
            setInstituteName('');
            setContactName('');
            setContactPhoneNumber('');
            setEmail('');
            setInstituteNameError('');
            setContactNameError('');
            setContactPhoneNumberError('');
            setEmailError('');
        }
    }, [props.isOpen]);

    useEffect(() => {
        if (props.isOpen) {
            var AcademicCycle = Parse.Object.extend("EnquiryPopupSettings");
            var query = new Parse.Query(AcademicCycle);
            query.equalTo("schoolId", "lilTriangle");
            query.first().then(
                (result) => {
                    if (result) {
                        let now = new Date();
                        if (now > result.attributes.startDate && now < result.attributes.endDate) {
                            setOfferDetails(result.attributes.offerDetails);
                        }
                    }
                },
                (error) => {
                    console.error("Error fetching offer details:", error);
                }
            );
        }
    }, [props.isOpen]);

    const getDesignation = (role) => {
        if (role === 3) {
            return props.t("Director");
        } else if (role === 2) {
            return props.t("Center Head");
        } else {
            return props.t("Teacher");
        }
    };

    const validatePhoneNumber = (phoneNumber) => {
        const phonePattern = /^([0-9]{10,16})$/;
        if (!phoneNumber) {
            return "Phone number is required";
        } else if (!phonePattern.test(phoneNumber)) {
            return "You have entered an invalid phone number!";
        }
        return "";
    };

    const validateEmail = (email) => {
        const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        if (!email) {
            return "Email is required";
        } else if (!emailPattern.test(email)) {
            return "You have entered an invalid email address!";
        }
        return "";
    };

    const handleSubmit = () => {
        let isValid = true;

        if (!instituteName) {
            setInstituteNameError(true);
            isValid = false;
        }

        if (!contactName) {
            setContactNameError(true);
            isValid = false;
        }

        const contactPhoneNumberError = validatePhoneNumber(contactPhoneNumber);
        if (contactPhoneNumberError) {
            setContactPhoneNumberError(contactPhoneNumberError);
            isValid = false;
        } else {
            setContactPhoneNumberError('');
        }

        const emailError = validateEmail(email);
        if (emailError) {
            setEmailError(emailError);
            isValid = false;
        } else {
            setEmailError('');
        }

        if (!location) {
            setLocationError(true);
            isValid = false;
        } else {
            setLocationError(false);
        }

        if (isValid) {
            setLoading(true); 

            const lilEnquiries = Parse.Object.extend('lilEnquiries');
            const enquiry = new lilEnquiries();

            enquiry.set("schoolDetails", instituteName);
            enquiry.set("name", contactName);
            enquiry.set("phone", contactPhoneNumber);
            enquiry.set("email", email);
            enquiry.set("city", location);

            if (props.userTeacher && props.selectedSchool) {
                enquiry.set("referredBy", {
                    name: props.userTeacher.attributes.Name,
                    designation: getDesignation(props.userTeacher.attributes.Role),
                    role: props.userTeacher.attributes.Role,
                    phoneNumber: props.userTeacher.attributes.PhoneNumber,
                    email: props.userTeacher.attributes.EMail,
                    schoolId: props.selectedSchool.id,
                    teacherId: Parse.User.current().attributes.teacherId,
                    schoolName: props.selectedSchool.attributes.Name
                });
            } else {
                enquiry.set("referredBy", {
                    teacherId: Parse.User.current().attributes.teacherId,
                });
            }

            enquiry.set("clientType", "School Refer From lilTriangle dashboard");

            enquiry.save().then(
                (result) => {
                    console.log(result);
                    toastr.options.positionClass = "toast-top-center";
                    toastr.success("Enquiry added successfully");
                    setLoading(false);
                    props.toggleModal();
                },
                (error) => {
                    console.error('Error saving Enquiry:', error);
                    setLoading(false);
                }
            );
        }
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.isOpen} toggle={props.toggleModal} size="lg">
                <ModalBody>
                    <Row>
                        <Col lg={6} className="text-center">
                            <h2 className="m-4">Refer A School</h2>
                            <img src={ReferSchoolImage}  alt="School Icon" style={{ width: '9.5cm', height: '9.3cm' }} />
                            <h5 className='m-4'>{offerDetails}</h5>
                        </Col>
                        <Col lg={6}>
                            <Card className="border-1 p-2">
                                <CardBody>
                                    <h4>Fill Referal School Details</h4>
                                    <Label className="m-2">School Name: <span className="text-danger">*</span></Label>
                                    <input
                                        type="text"
                                        placeholder="Enter School name"
                                        className="form-control"
                                        value={instituteName}
                                        onChange={(e) => {
                                            setInstituteName(e.target.value);
                                            setInstituteNameError(false);
                                        }} />
                                    {instituteNameError && <div className="text-danger">Institute name is required</div>}
                                    <Label className="m-2">Contact Name: (Whom can we contact) <span className="text-danger">*</span></Label>
                                    <input
                                        type="text"
                                        placeholder="Enter Contact name"
                                        className="form-control"
                                        value={contactName}
                                        onChange={(e) => {
                                            setContactName(e.target.value);
                                            setContactNameError(false);
                                        }} />
                                    {contactNameError && <div className="text-danger">Contact name is required</div>}
                                    <Label className='m-2'>Contact Phone Number: <span className="text-danger">*</span></Label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter Phone Number"
                                        value={contactPhoneNumber}
                                        onChange={(e) => {
                                            setContactPhoneNumber(e.target.value);
                                            setContactPhoneNumberError(validatePhoneNumber(e.target.value));
                                        }}
                                    />
                                    {contactPhoneNumberError && <div className="text-danger">{contactPhoneNumberError}</div>}
                                    <Label className='m-2'>Email: <span className="text-danger">*</span></Label>
                                    <input
                                        type="email"
                                        className="form-control"
                                        placeholder="Enter Email"
                                        value={email}
                                        onChange={(e) => {
                                            setEmail(e.target.value);
                                            setEmailError(validateEmail(e.target.value))
                                        }} />
                                    {emailError && <div className="text-danger">{emailError}</div>}

                                    <Label className='m-2'>City/Area/Location: <span className="text-danger">*</span></Label>
                                    <input
                                        type="text"
                                        className="form-control"
                                        placeholder="Enter school Location"
                                        value={location}
                                        onChange={(e) => {
                                            setLocation(e.target.value);
                                            setLocationError(validateEmail(e.target.value))
                                        }} />
                                    {locationError && <div className="text-danger">{"Please fill school location"}</div>}

                                    <Alert color="info" role="alert" className="m-2">Kindly fill details of the reference </Alert>
                                    <div className='text-center'>
                                        <button className="btn btn-primary mt-3 form-control" onClick={handleSubmit}>
                                            {loading ? (
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                            ) : null}
                                            {loading ? props.t('Saving...') : props.t('Refer Now')}
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                    <Row>
                        <p style={{ textDecoration: 'underline', cursor: 'pointer', color: 'red', textAlign: 'end' }} onClick={props.toggleModal}>Close</p>
                    </Row>
                </ModalBody>
            </Modal>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { userTeacher } = state.Login;

    return {
        selectedSchool,
        userTeacher
    };
};

export default withRouter(connect(mapStatetoProps, { getUserTeacher })(withNamespaces()(ReferSchool)));
