import React, { useEffect, useState } from "react";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table, Form, Button,
    Label, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader,
} from "reactstrap";
import { Editor } from 'react-draft-wysiwyg';
import { EditorState, convertToRaw, ContentState } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';
import { connect } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import Topbar from "../Topbar";
import {
    setSelecetdSchool, getInventoryItemForOwnerId, setSelectedPurchaseOrderItems, setSelectedVendor,
    setSelectedPurchaseOrder, getFranchise, getInventoryWarehouseForOwnerId, getInventoryStockForWarehouse,
} from '../../../store/actions';


const PrintPurchaseOrder = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/print-purchase-order/:schoolId/:purchaseOrderId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-print-purchase-order/:franchiseId/:purchaseOrderId",
        exact: true,
        strict: false,
    });

    const tempMatch = match ?? franchiseMatch
    const purchaseOrderId = tempMatch && tempMatch.params.purchaseOrderId;

    const schoolId = match && match.params.schoolId;

    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [tableData, setTableData] = useState([{

        itemId: uuidv4(),
        item: undefined,
        units: undefined,
        quantity: undefined,
    }]);
    const [isLoading, setIsLoading] = useState(false);
    const [purchaseOrderCost, setPurchaseOrderCost] = useState(0);
    const [modal3, setModal3] = useState(false);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const [content, setContent] = useState()

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getFranchise(franchiseId);
            props.getInventoryWarehouseForOwnerId(franchiseId, 'franchise');
        }
    }, [franchiseId]);

    useEffect(() => {
        props.setSelectedPurchaseOrder(purchaseOrderId);
        props.setSelectedPurchaseOrderItems(purchaseOrderId);
    }, [purchaseOrderId]);

    useEffect(() => {
        if (props.inventoryWarehouses && props.inventoryWarehouses.length > 0) {
            console.log("props.inventoryWarehouses", props.inventoryWarehouses)
            props.getInventoryStockForWarehouse(props.inventoryWarehouses[0].id);
        }
    }, [props.inventoryWarehouses]);

    useEffect(() => {
        if (props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.id == purchaseOrderId) {
            let vendorId = props.selectedInventoryPurchaseOrder.attributes.vendorId;
            setContent(props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.attributes.purchaseOrderTerms)
            props.setSelectedVendor(vendorId);
        }
    }, [props.selectedInventoryPurchaseOrder])

    useEffect(() => {
        if (content) {
            const blocksFromHtml = htmlToDraft(content);
            const { contentBlocks, entityMap } = blocksFromHtml;
            const contentState = ContentState.createFromBlockArray(contentBlocks, entityMap);
            setEditorState(EditorState.createWithContent(contentState));
            setContent(content);
        }
    }, [content]);


    useEffect(() => {
        console.log("props.selectedInventoryPurchaseOrderItems", props.selectedInventoryPurchaseOrderItems)
        if (purchaseOrderId && props.selectedInventoryPurchaseOrderItems && props.selectedInventoryPurchaseOrderItems.length > 0) {
            let items = []
            for (const si of props.selectedInventoryPurchaseOrderItems) {
                let newItem = {
                    itemId: uuidv4(),
                    item: si,
                    itemName: si.attributes.itemName,
                    price: (si.attributes.costPrice / si.attributes.quantity).toFixed(2),
                    tax: si.attributes.tax,
                    finalPrice: si.attributes.costPrice.toFixed(2) ?? 0,
                    units: si.attributes.unit,
                    quantity: si.attributes.quantity,
                    subItems: si.attributes.subItemsMatrix,
                }
                items.push(newItem)
            }
            updatePurchaseOrderCost(items)
            setTableData(items);
        }
    }, [props.selectedInventoryPurchaseOrderItems])


    useEffect(() => {
        if (props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder.id == purchaseOrderId) {
            if (props.selectedInventoryPurchaseOrderItems) {
                if (props.selectedVendor) {
                    if (props.selectedSchool || props.franchise) {
                        setIsLoading(false);
                    }
                }
            }
        }
    }, [props.selectedInventoryPurchaseOrder, props.selectedInventoryPurchaseOrderItems, props.selectedVendor, props.selectedSchool, props.franchise])

    const printOrder = () => {
        var css = "@page { size: A3;margin:10mm 0 10mm 0; }",
            head = document.head || document.getElementsByTagName("head")[0],
            style = document.createElement("style");

        style.type = "text/css";
        style.media = "print";

        if (style.styleSheet) {
            style.styleSheet.cssText = css;
        } else {
            style.appendChild(document.createTextNode(css));
        }

        head.appendChild(style);
        window.print();
    }

    console.log("tableDate", tableData)
    const getStock = (itemId) => {
        // console.log("props.inventoryItemStockMap", props.inventoryItemStockMap)
        if (props.inventoryItemStockMap && props.inventoryItemStockMap.length > 0) {
            let temp = props.inventoryItemStockMap.filter(
                (vendor) => vendor.attributes.itemId === itemId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.stock ?? "-")
            }
        }

        return '-'
    }

    function updatePurchaseOrderCost(newList) {
        console.log(newList)
        let purchaseOrderCost = 0;
        for (let i of newList) {
            if (i.finalPrice) {
                purchaseOrderCost += parseFloat(i.finalPrice);
            }
        }
        setPurchaseOrderCost(purchaseOrderCost.toFixed(2));
    }

    function toggle3() {
        setModal3(!modal3)
    }
    const saveTermsForPurchaseOrder = () => {
        if (props.selectedInventoryPurchaseOrder && props.selectedInventoryPurchaseOrder) {
            const inventoryPurchaseOrders = props.selectedInventoryPurchaseOrder;
            inventoryPurchaseOrders.set("purchaseOrderTerms", content);

            inventoryPurchaseOrders.save().then(
                (result) => {
                    console.log(result);
                },
                (error) => {
                    console.error("Error saving purchase order terms:", error);
                }
            );
        }
    };

    const getUI = () => {
        return (<div className="page-content">
            <div className="d-print-none">
                <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                        <div
                            style={{ cursor: "pointer" }}
                            onClick={() => { window.history.back() }}>
                            <ul className=" list-unstyled">
                                <div className=''>
                                    <i className="bx bx-chevron-left h1 text-primary"></i>
                                </div>
                            </ul>
                        </div>
                        <div className=' m-2'>
                            <h4>{"Purchase order Receipt"}</h4>
                        </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                        <div className="d-flex justify-content-end d-print-none m-2">
                            <Label
                                onClick={() => {
                                    printOrder()
                                }}
                                className="btn btn-success waves-effect waves-light mr-2"
                            >
                                <i className="fa fa-print"></i>
                            </Label>
                        </div>
                    </Col>
                </Row>
            </div>
            {!isLoading && <Row>
                <Col lg="12">
                    <Card>
                        <CardBody>
                            <div className="Reciept-title">
                                <Row className="mb-4">
                                    <Col>
                                        <img
                                            src={match ? (props.selectedSchool && props.selectedSchool.attributes.logo ? props.selectedSchool && props.selectedSchool.attributes.logo._url : "") : (props.franchise && props.franchise.attributes.logo ? props.franchise && props.franchise.attributes.logo._url : "")}
                                            style={{ height: "6rem" }}
                                        />
                                    </Col>
                                    {match && <Col className="d-flex justify-content-end">
                                        <address className="float-right">
                                            <strong>School Details</strong>
                                            <br />
                                            <span><strong>{props.selectedSchool.attributes.Name}</strong></span>
                                            <br />
                                            <span>{props.selectedSchool.attributes.Address}</span>
                                            <br />
                                            <span>{props.selectedSchool.attributes.primaryPhoneNumber}, {props.selectedSchool.attributes.secondaryPhoneNumber}</span>
                                            <br />
                                            <span>{props.selectedSchool.attributes.EMail}</span>
                                        </address>
                                    </Col>}
                                    {
                                        franchiseMatch && props.franchise && <Col className="d-flex justify-content-end">
                                            <address className="float-right">
                                                <strong>Franchise Details</strong>
                                                <br />
                                                <span><strong>{props.franchise.attributes.name}</strong></span>
                                                <br />
                                                <span>{props.franchise.attributes.address}</span>
                                                <br />
                                                <span>{props.franchise.attributes.primaryPhone}, {props.franchise.attributes.secondaryPhone}</span>
                                                <br />
                                                <span>{props.franchise.attributes.email}</span>
                                            </address>
                                        </Col>
                                    }
                                </Row>
                            </div>
                            <hr />
                            <Row>
                                <Col xs="6">
                                    <address>
                                        <strong>Vendor Details:</strong>
                                        <br />
                                        <strong>
                                            {props.selectedVendor && props.selectedVendor.attributes.companyName}
                                        </strong>
                                        <br />
                                        <span>Contact : {(props.selectedVendor && props.selectedVendor.attributes.vendorEmail) ?? (props.selectedVendor && props.selectedVendor.attributes.vendorPhone)}</span>
                                        <br />
                                        <span>Vendor name:{props.selectedVendor && props.selectedVendor.attributes.displayName}</span>
                                        <br />
                                    </address>
                                </Col>
                                <Col xs="6">
                                    <h4 className="d-flex justify-content-end font-size-16">
                                        {props.selectedInventoryPurchaseOrder && " Purchase order number #" + props.selectedInventoryPurchaseOrder.attributes.purchaseOrderNumber}
                                    </h4>
                                </Col>
                            </Row>
                            <hr />
                            <div className="py-2 mt-3">
                                <h3 className="font-size-15 font-weight-bold">
                                    Purchase order summary
                                </h3>
                            </div>
                            <div className="table-responsive">
                                <table className="table table-centered table-bordered mb-0 table-sm">
                                    <thead className="thead-light">
                                        <tr>
                                            <th style={{ width: "70px", textAlign: 'center' }}>#</th>
                                            <th style={{ textAlign: 'center' }}>Items</th>
                                            <th style={{ textAlign: 'center' }}>Unit</th>
                                            <th style={{ textAlign: 'center' }}>quantity</th>
                                            <th style={{ textAlign: 'center' }}>Tax</th>
                                            <th style={{ textAlign: 'center' }}>Avg Price</th>
                                            <th style={{ textAlign: 'center' }}>Final Price</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {tableData.map((item, key) => (
                                            <React.Fragment key={key}>
                                                <tr>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <Label><b>{key + 1}</b></Label>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <Label>{item.itemName}</Label>
                                                    </td>
                                                    <td style={{ textAlign: 'center' }}>
                                                        <Label className="font-size-12 mb-1">{item.units}</Label>
                                                    </td>
                                                    {<td style={{ textAlign: 'center' }}>
                                                        <Label className="font-size-12 mb-1">{item.quantity}</Label>
                                                    </td>}
                                                    {<td style={{ textAlign: 'center' }}>
                                                        <Label className="font-size-12 mb-1">{item.tax}</Label>
                                                    </td>}
                                                    {<td style={{ textAlign: 'center' }}>
                                                        <Label className="font-size-12 mb-1">{item.price}</Label>
                                                    </td>}
                                                    {<td style={{ textAlign: 'center' }}>
                                                        <Label className="font-size-12 mb-1">{item.finalPrice}</Label>
                                                    </td>}
                                                </tr>
                                                {item.subItems && (
                                                    <tr>
                                                        <td colSpan="7">
                                                            <div className="table-responsive text-center" style={{ width: '100%' }}>
                                                                <table className="table table-centered table-bordered mb-0 table-sm" style={{ width: '90%', margin: 'auto' }}>
                                                                    <thead className="thead-light">
                                                                        <tr>
                                                                            <th style={{ width: '8%', textAlign: 'center' }}>Sl No</th>
                                                                            {item.subItems.some(sub => sub.matrixColorId) && (
                                                                                <th style={{ width: '21%', textAlign: 'center' }}>Color</th>
                                                                            )}
                                                                            {item.subItems.some(sub => sub.matrixSizeId) && (
                                                                                <th style={{ width: '21%', textAlign: 'center' }}>Size</th>
                                                                            )}
                                                                            <th className='text-center'>Quantity</th>
                                                                            <th className='text-center'>Price Before tax</th>
                                                                            <th className='text-center'>Price After tax</th>
                                                                            <th className='text-center'>Final Price</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody>
                                                                        {item.subItems.map((subItem, subItemKey) => (
                                                                            <tr key={subItemKey}>
                                                                                <td style={{ width: '8%', textAlign: 'center' }}>{subItemKey + 1}</td>
                                                                                {subItem.matrixColorId && (
                                                                                    <td style={{ width: '21%', textAlign: 'center' }}>
                                                                                        <h6>{subItem.colorName}</h6>
                                                                                    </td>
                                                                                )}
                                                                                {subItem.matrixSizeId && (
                                                                                    <td style={{ width: '21%', textAlign: 'center' }}>
                                                                                        <h6>{subItem.sizeName}</h6>
                                                                                    </td>
                                                                                )}
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <h6>{subItem.quantity}</h6>
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <h6>{subItem.priceBeforeTax}</h6>
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <h6>{subItem.priceAfterTax}</h6>
                                                                                </td>
                                                                                <td style={{ textAlign: 'center' }}>
                                                                                    <h6>{subItem.finalPrice}</h6>
                                                                                </td>
                                                                            </tr>
                                                                        ))}
                                                                    </tbody>
                                                                </table>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )}
                                            </React.Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                            <Row className="align-items-center m-2">
                                <Col className="d-flex justify-content-end">
                                    <FormGroup className="mb-0 d-flex align-items-center">
                                        <Label className="mb-0 me-2">Total Purchase Order Amount:</Label>
                                        <input
                                            placeholder="0.00"
                                            type="number"
                                            className="form-control"
                                            value={purchaseOrderCost}
                                            readOnly
                                            style={{ width: "150px" }}
                                        />
                                    </FormGroup>
                                </Col>
                            </Row>
                            <p className="m-2"><b>Terms:</b></p>
                            <Row>
                                {content ? <div className="text-left font-size-16">
                                    <div
                                        dangerouslySetInnerHTML={{
                                            __html: content
                                        }} />
                                </div>
                                    :
                                    <h4 className='text-center font-size-16 d-print-none' ><b>

                                    </b>
                                    </h4>}
                                <div style={{ height: "50mm", }} className='d-flex justify-content-end'>
                                    <div className=" d-print-none font-size-20 ">
                                        <i className='bx bx-edit-alt' onClick={() => { toggle3() }}></i>
                                    </div>
                                </div>

                            </Row>
                        </CardBody>
                    </Card>
                </Col>
            </Row>}
            {
                isLoading && <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <button className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Purchase order... </button>
                        </div>
                    </Col>
                </Row>
            }
        </div>
        )
    }
    return (<React.Fragment>
        <div className="account-pages" >
            <Modal size='lg' isOpen={modal3} toggle={() => toggle3()} className="">
                <ModalHeader toggle={() => toggle3()} tag="h4">
                    Certificate content
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={(newState) => {
                                    setEditorState(newState);
                                    const contentHtml = draftToHtml(convertToRaw(newState.getCurrentContent()));
                                    setContent(contentHtml);
                                }}
                            />
                        </FormGroup>
                        <Row>
                            <Col>
                                <div className="text-right">
                                    <Button color='primary' className="btn mr-2" onClick={() => {
                                        saveTermsForPurchaseOrder()
                                        toggle3()
                                    }
                                    }>Done</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
            <Container fluid='md'>{getUI()}</Container>
        </div>
        <h5></h5>
        <br />
    </React.Fragment>

    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryItems } = state.InventoryItems;
    const { selectedInventoryPurchaseOrderItems } = state.InventoryPurchaseOrderItem;
    const { selectedVendor } = state.InventoryVendor;
    const { selectedInventoryPurchaseOrder } = state.InventoryPurchaseOrder;
    const { franchise } = state.Franchise;
    const { inventoryWarehouses, inventoryItemStockMap } = state.InventoryWarehouse;

    return {
        selectedSchool, inventoryItems, selectedInventoryPurchaseOrderItems, selectedVendor,
        selectedInventoryPurchaseOrder, franchise, inventoryWarehouses, inventoryItemStockMap,
    };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getInventoryItemForOwnerId, setSelectedPurchaseOrderItems, setSelectedVendor,
    setSelectedPurchaseOrder, getFranchise, getInventoryWarehouseForOwnerId, getInventoryStockForWarehouse,
})(PrintPurchaseOrder)));