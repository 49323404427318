import Parse from "parse";
import { printLog } from "./loghelper";

export const getFileFromParse = (id) => {
  printLog("HL: files_helper : getFileFromParse")

    try {
        return new Promise((resolve, reject) => {
            var File = Parse.Object.extend("Files");
            var query = new Parse.Query(File);
            query.get(id).then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(null);
                }
            )
        }
        )
    } catch (error) {}
}

export const deleteFileFromParse = (file) => {
  printLog("HL: files_helper : deleteFileFromParse")
    try {
      return new Promise((resolve, reject) => {
       
        file.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
          file.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
          file.set("deletedBy", Parse.User.current().id);
        }
  
        file.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  
   
  
  export const getFileForFolderId = (id, isLoacal) => {
    printLog("HL: files_helper : getFileForFolderId")
    try {
      return new Promise((resolve, reject) => {
        var File = Parse.Object.extend("Files");
        var query = new Parse.Query(File);
        query.equalTo("folderId", id);
        if (isLoacal) {
          query.fromLocalDatastore();
          
        }
        query.notEqualTo("isDeleted", true);
        query.ascending("orderBy")
        query.limit(1000);
        query.find().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };

  export const saveFile = (object) => {
    printLog("HL: files_helper : saveFile")
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) {}
  };
  
  export const getFilesForCourseId = (id, updatedDate, isLoacal) => {
    printLog("HL: files_helper : getFilesForCourseId")
  try {
    return new Promise((resolve, reject) => {
      var File = Parse.Object.extend("Files");
        var query = new Parse.Query(File);
      query.equalTo("courseId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("order");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const getNoticeBoardFilesForSchoolId = (id, updatedDate,isLoacal) => {
  printLog("HL: files_helper : getNoticeBoardForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var File = Parse.Object.extend("Files");
      var query = new Parse.Query(File);
      query.equalTo("schoolId", id);
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt")
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};