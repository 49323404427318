import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
  classrooms: [],
  classDeleted : false
};

const Classroom = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_CLASSROOM:
      var classrooms = [...state.classrooms];
      var temp = state.classrooms.filter(
        (classRoom) => classRoom.id === action.payload.classRoom.id
      );
      if (temp.length > 0) {
        var index = classrooms.indexOf(temp[0]);
        classrooms[index] = action.payload.classRoom;
        state = { ...state, classrooms: classrooms };
      } else {

        var list = [action.payload.classRoom];
        state = { ...state, classrooms: list.concat(classrooms) };
        
      }
      break;
      case actions.UPDATE_CLASSROOMS:
        var classrooms = [...state.classrooms]
        if (classrooms.length > 0) {
            var updatedList = ReduxUtil.updateParseObjects([...state.classrooms], action.payload.classrooms, "isDeleted", false)
            state = { ...state, classrooms:updatedList};
        } else {

          var others = action.payload.classrooms.filter(
            (classroom) => classroom.attributes.isDeleted !== true
          );
            
          state = { ...state, classrooms: others };
        }
        
        break;
    
      
    case actions.SET_DELETED_CLASS:
      if (action.payload.classRoom) {
        var classrooms = state.classrooms.filter(
          (classRoom) => classRoom.id !== action.payload.classRoom.id
        );
        state = { ...state, classrooms: classrooms,classDeleted : true };
      } else {
        state = { ...state,classDeleted : false };
      }
     
     
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Classroom;
