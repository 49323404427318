import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as ExamHelper from "../../../helpers/ParseHelpers/exam_helper";
import * as actionTypes from "./actionTypes";
import * as ExamAction from "./actions";

function* getExamsForSchoolIdsSaga({ payload: { schoolIds } }) {
  try {
    const response = yield call(ExamHelper.getExamsForSchoolIds, schoolIds);
    yield put(ExamAction.setExamLoading(false));

    if (response) {
      yield put(ExamAction.updateExamsForSchoolIds(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* saveExamSaga({ payload: { exam } }) {
  try {
    const response = yield call(ExamHelper.saveExam, exam);
    if (response) {
      yield put(ExamAction.updateExamAfterSave(response));
      yield put(ExamAction.updateExam(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* clearExamAftersaveSaga({ payload: {} }) {
  try {
    yield put(ExamAction.updateExamAfterSave(null));
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetExamsForSchoolIdsSaga() {
  yield takeEvery(
    actionTypes.GET_EXAMS_FOR_SCHOOL_IDS,
    getExamsForSchoolIdsSaga
  );
}

export function* watchSaveExamSaga() {
  yield takeEvery(actionTypes.SAVE_EXAM, saveExamSaga);
}
export function* watchClearExamAftersaveSaga() {
  yield takeEvery(actionTypes.CLEAR_EXAM_AFTERSAVE, clearExamAftersaveSaga);
}

function* examSaga() {
  yield all([fork(watchGetExamsForSchoolIdsSaga)]);
  yield all([fork(watchSaveExamSaga)]);
  yield all([fork(watchClearExamAftersaveSaga)]);
}
export default examSaga;
