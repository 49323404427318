import React, { useEffect, useState } from 'react';
import {
    Container, Card, CardBody, Row, Col, Table,
    UncontrolledTooltip, Label, Button, Modal, FormGroup, ModalHeader, ModalBody, ModalFooter, Input
} from "reactstrap";
import Image from "../../assets/images/book_lover.svg";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import { Link } from "react-router-dom";
import Moment from 'moment';
import toastr from 'toastr';
import { AvForm, AvField, } from "availity-reactstrap-validation";
import Parse from 'parse';
import SweetAlert from "react-bootstrap-sweetalert";
import * as CommonFunctions from "../../components/Common/CommonFunctions";
import {
    setSelecetdSchool,
    setSelectedCourse,
    getCoureseSubscriptions,
    getAllKids,
    addSubscriptionsToCourse,
    getCourseBatchesForCourseId,
    removeSubscriptionFromCourse,
} from "../../store/actions";

import TopBar from './TopBar';


const CourseSubscribers = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/course-participants/:schoolId/:courseId',
        exact: true,
        strict: false,
    });
    const matchWithBatch = matchPath(props.history.location.pathname, {
        path: '/course-participants/:schoolId/:courseId/:batchId',
        exact: true,
        strict: false,
    });
    const batchId = matchWithBatch ? matchWithBatch.params.batchId : "";
    const schoolId = match ? match.params.schoolId :matchWithBatch.params.schoolId;
    const courseId = match ? match.params.courseId : matchWithBatch.params.courseId;

    const [searchBarString, setSearchBarString] = useState("");

    const [subscriberModel, setSubscriberModel] = useState(false);
    const [mandatoryCheckErrorListMode, setMandatoryCheckErrorListMode] = useState([]);
    const [nonSubscribers, setNonSubscribers] = useState([]);
    const [newSubscribers, setNewSubscribers] = useState([]);

    const [subscriptionModeForNewSubs, setSubscriptionModeForNewSubs] = useState({})

    const [allSubscribers, setAllSubscribers] = useState([]);
    const SELECT_SUBSCRIPTION_MODE = "Select Enrollment Mode"
    const modeOfRequest = [SELECT_SUBSCRIPTION_MODE, "No-Fee User", "Sponsored User", "Prepaid User"];

    const [mandatoryCheckErrorListBatch, setMandatoryCheckErrorListBatch] = useState([]);
    const [subscriptionBatchForNewSubs, setSubscriptionBatchForNewSubs] = useState({})

    const [selectedBatch, setSelectedBatch] = useState(undefined);

    const [deleteCourseSubscription,setdeleteCourseSubscription]=useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.setSelectedCourse(courseId);
        props.getCoureseSubscriptions(courseId);
        props.getCourseBatchesForCourseId(courseId);
        props.getAllKids(schoolId)
    }, [courseId]);

    useEffect(() => {
        let list = []

        for (const kid of props.kids) {
            let others
            if (matchWithBatch) {
                 others = props.courseSubscriptions.filter(
                (k) => (k.attributes.kidId == kid.id && k.attributes.batchId == batchId)
            );
            } else {
                 others = props.courseSubscriptions.filter(
                (k) => k.attributes.kidId == kid.id
            );
            }
            

            if (others.length > 0) {
                list.push({ id: others[0].id, kid: kid, courseSubscription: others[0] })
            }
        }
        setAllSubscribers(list);
    }, [props.courseSubscriptions, props.kids]);

    useEffect(() => {
        if (props.addState === false) {
            setNonSubscribers([]);
            setSubscriberModel(false);
        }

    }, [props.addState]);

    const[allCourseBatches,setAllCourseBatches]=useState([])
    useEffect(() => {

        if (props.courseBatch && props.courseBatch.length > 0) {
            let list = [];
            let sb;
            for (const cb of props.courseBatch) {
                if (cb.attributes.courseId === courseId && cb.attributes.endDate > new Date()) {
                    list.push(cb)
                }
                if (cb.id == batchId) {
                    sb = cb;
                    setSelectedBatch(cb)
                }
            }
            if(matchWithBatch && sb){
                setAllCourseBatches([sb]);
            } else {
                setAllCourseBatches(list);
            }
            
        }

    }, [props.courseBatch]);

    

    const handelAddSubsctiber = () => {

        let subs = [];
        let nonSubs = [];
        let objectForModeOfSub = {}
        for (const k of props.courseSubscriptions) {
            subs.push(k.attributes.kidId)
        }

        for (const k of props.kids) {
            if (!subs.includes(k.id)) {
                nonSubs.push(k)
                objectForModeOfSub[k.id] = SELECT_SUBSCRIPTION_MODE
            }
        }
        setSubscriptionModeForNewSubs(objectForModeOfSub)
        setNonSubscribers(nonSubs);
        setSubscriberModel(true);
    }

    const handelOnClickBack = () => {
        window.history.back();
    }


    const handelAddSubscribersToCourse = () => {



        let newSubs = [];
        let tempMandatoryCheckErrorListMode = []
        let tempMandatoryCheckErrorListBatch = []
        for (const kidId of newSubscribers) {
            let newsub = {};
            newsub.kidId = kidId;
            newsub.kidIds = [kidId];
            newsub.courseId = courseId;
            newsub.subscribedDate = new Date();
            if (subscriptionModeForNewSubs[kidId] && subscriptionModeForNewSubs[kidId] !== SELECT_SUBSCRIPTION_MODE) {
                newsub.mode = subscriptionModeForNewSubs[kidId]
            } else {
                tempMandatoryCheckErrorListMode.push(kidId)
            }
            if (matchWithBatch) {
                newsub.batch =batchId
            }else if (subscriptionBatchForNewSubs[kidId] !=undefined || subscriptionBatchForNewSubs[kidId] !=null) {
                newsub.batch = subscriptionBatchForNewSubs[kidId]
            } else {
                tempMandatoryCheckErrorListBatch.push(kidId)
            }
            newSubs.push(newsub);
        }

        if (tempMandatoryCheckErrorListMode.length > 0 || tempMandatoryCheckErrorListBatch.length > 0){
            if (tempMandatoryCheckErrorListMode.length > 0) {
                setMandatoryCheckErrorListMode(tempMandatoryCheckErrorListMode)
            }
             if (tempMandatoryCheckErrorListBatch.length > 0 && match) {
                setMandatoryCheckErrorListBatch(tempMandatoryCheckErrorListBatch)
            }
        } else {
            if (newSubs.length > 0) {
                props.addSubscriptionsToCourse(newSubs);
            } else {
                toastr.warning("Please select participants")
            }
        }



    }

    function getDateTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY, hh:mm a');
    }

    function getDateStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }

    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    const handleSearchBarTextChange = (value) => {
        setSearchBarString(value);
    };

    const getNonSubList = (list) => {
        var listToReturn = [...list];
        if (searchBarString === "") {
        } else {
            listToReturn = listToReturn.filter((object) =>
                object.attributes.Name.toLowerCase().includes(
                    searchBarString.toLowerCase()
                )
            );
        }
        return listToReturn;
    }

    //// Utilities functions
    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    const getCheckBoxValue = (kidId) => {
        if (newSubscribers.includes(kidId)) {
            return true
        }
        return false
    }

    const getBatchForBatchId = (batchId) => {
        let batch = " ";
        if (batchId && props.courseBatch && props.courseBatch.length>0) {
            var temp = props.courseBatch.filter(
            (cb) =>
            cb.id === batchId
            );
            if (temp && temp.length > 0) {
                return getDateStringForDate(temp[0].attributes.startDate) + " to " + getDateStringForDate(temp[0].attributes.endDate)
            }
        }
        return batch;
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>


                    <TopBar
                        title={props.selectedCourse ? "Participants for " + props.selectedCourse.attributes.name : "Participants"}
                        buttonName={"Add Participants"}
                        backButton={true}
                        hideButton={false}
                        onClickBackButton={handelOnClickBack}
                        onClickAdd={handelAddSubsctiber}
                        reorderButton={false}
                    />

                    { matchWithBatch && 
                        <Card>
                            <CardBody>
                                 <h5 className="text-truncate font-size-14  mb-1 text-dark">{selectedBatch ? "Batch : " + getBatchForBatchId(batchId) : "Batch: "}</h5>
                            </CardBody>
                        </Card>
                    }
                    <Row >
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    {allSubscribers.length > 0 && <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="col" scope="col" align="center" style={{ width: "70px" }}>#</th>
                                                <th className="col-5" scope="col">Name</th>
                                                 <th className="col-4" scope="col">Batch</th>
                                                <th className="col-2" scope="col" align="right">Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allSubscribers.map((cso, key) =>
                                                <tr key={cso.id} >
                                                    <td>
                                                        {
                                                            !cso.kid.attributes.Photo
                                                                ? <div className="avatar-xs">
                                                                    <span className="avatar-title rounded-circle">
                                                                        {cso.kid.attributes.Name ? cso.kid.attributes.Name.toUpperCase().charAt(0) : " "}
                                                                    </span>
                                                                </div>
                                                                : <div>
                                                                    <img className="rounded-circle avatar-xs" src={cso.kid.attributes.Photo._url} alt="" />
                                                                </div>
                                                        }
                                                    </td>

                                                    <td
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {

                                                        }}>
                                                        <h5 className="text-truncate font-size-14  mb-1 text-dark">{cso.kid.attributes.Name}</h5>
                                                        <p className="text-muted mb-0">{"Enrolled on: " + getDateTimeStringForDate(cso.courseSubscription.attributes.subscribedDate)}</p>
                                                        <p className="text-success">{""}</p>


                                                    </td>

                                                    <td>
                                                        <p className="text-muted mb-0">{getBatchForBatchId(cso.courseSubscription.attributes.batchId)}</p>
                                                        
                                                    </td>

                                                    <td>
                                                        {
                                                            <ul className="list-inline font-size-20 contact-links mb-0 float-right">
                                                            
                                                                {<li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setdeleteCourseSubscription(cso)
                                                                    }}
                                                                    id={"sub-delete" + key}>
                                                                    <i className="bx bx-trash text-danger"></i>
                                                                    <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                        {props.t('Remove')}
                                                                    </UncontrolledTooltip>
                                                            
                                                                </li>
                                                                }
                                                                   
                                                            </ul>
                                                        }
                                                        {cso.courseSubscription.attributes.batchId ? <button
                                                            className='btn btn-sm btn-primary'
                                                            onClick={() => {
                                                                props.history.push("/course-assessments/" + schoolId + "/" + courseId+"/" + cso.courseSubscription.id + "/" + cso.courseSubscription.attributes.batchId + "/" + cso.kid.id);
                                                            }}
                                                        >
                                                            Assessments
                                                            
                                                        </button> :
                                                            <button className='btn btn-sm btn-primary' disabled={true}>
                                                                Assessments
                                                            </button>
                                                        }
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>}

                                    {
                                        allSubscribers.length === 0 && !props.initialLoding && props.selectedCourse &&
                                        <Card>
                                            <CardBody className="text-center" >

                                                <h3 className="mt-5">{

                                                    props.t('There are no participants for ') + props.selectedCourse.attributes.name

                                                }</h3>
                                                <p>{props.t('Add new participants in few steps')}.</p>

                                                <button type="button"
                                                    className="btn btn-primary "
                                                    onClick={() => {
                                                        handelAddSubsctiber();
                                                    }}  >
                                                    {props.t('Add participant')}
                                                </button>
                                            </CardBody>
                                        </Card>
                                    }

                                    {
                                        props.initialLoding && <Col xs="12">
                                            <div className="text-center my-3">
                                                <Label className="text-success">
                                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                                                </Label>
                                            </div>

                                        </Col>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                isOpen={subscriberModel}
                fade={false}
                className="modal-dialog"
                size='lg'
            >



                <ModalHeader className="bg-light" toggle={() => {
                    setSubscriberModel(!subscriberModel);
                }}>
                    <Row lg={12} md={12} sm={12} style={{ width: "100%" }} >
                        <Col lg={6} md={6} sm={6} xs={12}><Label className='mt-1'>Add Participants</Label></Col>
                        <Col lg={6} md={6} sm={6} xs={12} className='p-0'>
                            {/* <Input
                                type="text"
                                name="searchBarInAddCourseSubs"
                                className="inner form-control float-right"
                                placeholder="Search Student"
                                onChange={(e) => {
                                    handleSearchBarTextChange(e.target.value);
                                }}
                            /> */}
                            <div className="search-box p-0 float-right ">
                                <div className="position-relative">
                                    <Input type="text" className="form-control border-0" value={props.searchValue} placeholder="Search..." onChange={(e) => handleSearchBarTextChange(e.target.value)} />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody
                    style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
                >




                    <div className="table-responsive mt-2">
                        {nonSubscribers.length > 0 ? <Table className="table-centered table-nowrap table-hover">
                            <thead className="thead-light">
                                <tr>
                                    <th scope='col' className="col-1">
                                        <div></div>
                                    </th>

                                    <th className="col-4" scope="col">Name</th>

                                     <th className="col-4" scope="col">Batch</th>

                                    <th className="col-3" scope="col" style={{ textAlign: "center" }}>
                                        {props.t("Enrollment Mode")}<Label className='text-danger'>*</Label>
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {getNonSubList(nonSubscribers).map((kid, key) => (
                                    <tr key={"Nonsubs_" + key}>
                                        <td>
                                            <div className="custom-control custom-checkbox">
                                                <>
                                                    <input
                                                        type="checkbox"
                                                        className="custom-control-input input-mini"
                                                        id={`checkbox${kid.id}`}
                                                        checked={
                                                            getCheckBoxValue(kid.id)
                                                        }
                                                        onClick={(e) => {


                                                            let newList = [...newSubscribers];

                                                            if (newList.includes(kid.id)) {
                                                                removeElement(newList, kid.id)
                                                                setNewSubscribers(newList);
                                                            } else {
                                                                newList.push(kid.id);
                                                                setNewSubscribers(newList);
                                                            }

                                                        }}
                                                    />
                                                    <Label
                                                        className="custom-control-label"
                                                        for={`checkbox${kid.id}`}
                                                    ></Label></>
                                            </div>
                                        </td>

                                        <td>
                                            <>
                                                <Label className='mt-2'>{kid.attributes.Name}</Label>
                                            </>
                                        </td>
                                        <td>

                                            <select className="form-control"
                                                id={"select" + key}
                                                value={subscriptionBatchForNewSubs[kid.id]}
                                                onChange={(e) => {
                                                    let tempObj = { ...subscriptionBatchForNewSubs }
                                                    tempObj[kid.id] = e.target.value
                                                    if (mandatoryCheckErrorListBatch.includes(kid.id)) {
                                                        let tempMandatoryErrorList = [...mandatoryCheckErrorListBatch]
                                                        removeElement(tempMandatoryErrorList, kid.id)
                                                        setMandatoryCheckErrorListBatch(tempMandatoryErrorList)
                                                    }
                                                    setSubscriptionBatchForNewSubs(tempObj)
                                                }}
                                            >
                                                {match&&<option disabled selected value>
                                                    Pick Batch
                                                </option>}
                                                {allCourseBatches.map((batch, keyInside) => (
                                                    <option key={keyInside} value={batch.id}>{getDateStringForDate(batch.attributes.startDate) + " to " + getDateStringForDate(batch.attributes.endDate)}</option>
                                                ))}
                                            </select>
                                            {mandatoryCheckErrorListBatch.includes(kid.id) && (
                                                <p className="label-xsmall text-danger ml-1 mt-2">
                                                    This field is mandatory
                                                </p>
                                            )}
                                            

                                        </td>
                                        <td >
                                            < >



                                                <select className="form-control"
                                                    id={"select" + key}
                                                    value={subscriptionModeForNewSubs[kid.id]}
                                                    onChange={(e) => {
                                                        let tempObj = { ...subscriptionModeForNewSubs }
                                                        tempObj[kid.id] = e.target.value
                                                        if (mandatoryCheckErrorListMode.includes(kid.id)) {
                                                            let tempMandatoryErrorList = [...mandatoryCheckErrorListMode]
                                                            removeElement(tempMandatoryErrorList, kid.id)
                                                            setMandatoryCheckErrorListMode(tempMandatoryErrorList)
                                                        }
                                                        setSubscriptionModeForNewSubs(tempObj)
                                                    }}
                                                >
                                                    {modeOfRequest.map((choice, keyInside) => (
                                                        <option key={keyInside} value={choice}>{choice}</option>
                                                    ))}
                                                </select>
                                                {mandatoryCheckErrorListMode.includes(kid.id) && (
                                                    <p className="label-xsmall text-danger ml-1 mt-2">
                                                        This field is mandatory
                                                    </p>
                                                )}

                                            </>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table> : <Card>
                            <CardBody className="text-center" >

                                <h3 className="mt-5">{
                                    props.kids && props.kids.length === 0 ?
                                        props.t('There are no students in the accademy') :
                                        props.t('All students in the accademy are subscribed to this course')
                                }</h3>
                                <p>{props.t('Add new student in few steps')}.</p>

                                <button type="button"
                                    className="btn btn-primary "
                                    onClick={() => {

                                    }}  >
                                    {props.t('Add new student')}
                                </button>
                            </CardBody>
                        </Card>}
                    </div>
                </ModalBody>
                <ModalFooter className="bg-light">
                    {<Button color="secondary" onClick={() => {
                        setSubscriberModel();

                    }}>
                        Cancel
                    </Button>}{" "}

                    <Button color="primary" onClick={handelAddSubscribersToCourse}>
                        {props.t("Add to course")}
                    </Button>


                </ModalFooter>
            </Modal>



            { deleteCourseSubscription ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        props.removeSubscriptionFromCourse(deleteCourseSubscription.courseSubscription);
                        setdeleteCourseSubscription(undefined);
                        setsuccess_dlg(true); 
                    }}
                    onCancel={() =>{
                        setdeleteCourseSubscription(undefined);
                        setsuccess_dlg(false);
                        
                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {props.t('Enrollment has been deleted.')}
                </SweetAlert>
            ) : null}




            


        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { userTeacher } = state.Login;
    const { selectedSchool } = state.School;
    const { courses, selectedCourse } = state.Course;
    const { teachers } = state.Teacher;
    const { courseSubscriptions, initialLoding, addState } = state.CourseSubscription;
    const { kids } = state.Kid;
    const { courseBatch,selectedCourseBatch } = state.CourseBatch;
    return { userTeacher, selectedSchool, courses, teachers, selectedCourse, courseSubscriptions, addState, initialLoding, kids,courseBatch };
};


export default connect(mapStatetoProps, {
    setSelecetdSchool,
    setSelectedCourse,
    getCoureseSubscriptions,
    getAllKids,
    addSubscriptionsToCourse,
    getCourseBatchesForCourseId,
    removeSubscriptionFromCourse,
})(withNamespaces()(CourseSubscribers));
