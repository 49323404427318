import React from "react";
import { Row, Col, Form, Input, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { Link } from "react-scroll";

const Breadcrumb = (props) => {
  return (
    <Row className="mb-3">
      <Col xl="4" sm="6">
        <div className="mt-2">
          <h4 className="mb-0 font-size-18">
            {props.t(props.title.toUpperCase())}
          </h4>
        </div>
      </Col>
      <Col lg="8" sm="6">
        <div className="d-flex justify-content-end">
        <button
          onClick={props.popUpmodel}
          className="btn btn-primary float-right"
          disabled={!props.teacherPaymenrWrite}
        >
          {props.t("New Invoice")}
        </button>
        </div>
      </Col>
    </Row>

    // <Row>
    //     <Col xs="12">
    //         <div className="page-title-box d-flex align-items-center justify-content-between">
    //             <h4 className="mb-0 font-size-18">{props.title}</h4>
    //             <div className="page-title-right">
    //                 <ol className="breadcrumb m-0">
    //                     <BreadcrumbItem>
    //                         <Link to="#">{props.title}</Link>
    //                     </BreadcrumbItem>
    //                     <BreadcrumbItem active>
    //                         <Link to="#">{props.breadcrumbItem}</Link>
    //                     </BreadcrumbItem>
    //                 </ol>
    //             </div>
    //         </div>
    //     </Col>
    // </Row>
  );
};

export default withRouter(withNamespaces()(Breadcrumb));
