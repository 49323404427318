import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, CardTitle, Row, Col, Media, Label } from "reactstrap";
import Topbar from './Topbar';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import {
    getSchoolAnalytics,
    getEnquiryAnalyticsFor10Days,
    getFranchise,
    getUserTeacher
} from '../../store/actions';
import logo from "../../assets/images/lilLogo.png";
import Parse from 'parse';
import EnquiryTrendChart from "./EnquiryTrendChart";
import school from '../../store/model/school/reducer';
import SubscriptionInfoComp from '../DashboardNew/SubscriptionInfoComp';
import * as Constants from "../../Constents";


const Franchise = (props) => {


    const [schoolAnalyticsObject, setSchoolAnalyticsObject] = useState(undefined);
    const [subscriptionExpiryDate, setSubscriptionExpiryDate] = useState(undefined);
    const [showAllBranch, setShowAllBranch] = useState(false);

    useEffect(() => {
        if (Parse.User && Parse.User.current() && Parse.User.current().attributes.franchiseId) {
            props.getSchoolAnalytics(Parse.User.current().attributes.franchiseId, "franchise");
            props.getEnquiryAnalyticsFor10Days(Parse.User.current().attributes.franchiseId, "franchise");
            props.getFranchise(Parse.User.current().attributes.franchiseId);
        } else {
            props.history.push("/pages-404")
        }

    }, []);

    useEffect(() => {

        if (props.franchise && props.franchise.attributes) {
            let gracePeriod = props.franchise.attributes.gracePeriod ?? 0;
            let expDate = props.franchise.attributes.subscriptionExpiryDate;

            // console.log(gracePeriod, "---expDate: ", expDate, props.franchise)
            if (expDate) {
                let newDate = expDate.setDate(expDate.getDate() + gracePeriod);
                // console.log("newDate:" , newDate)
                let today = new Date();
                let diffTime = newDate - today;
                // console.log("T:" + diffTime)
                let diffDays = diffTime / (1000 * 3600 * 24);
                // console.log("D:" + diffDays)
                if (diffDays <= 15) {
                    setSubscriptionExpiryDate(newDate);
                }

            }
        }
    }, [props.franchise])
    // useEffect(() => {

    //     if(schoolAnalyticsObject && props.schools){
    //         let list=[];
    //         for(const sid of schoolAnalyticsObject.schoolIds){
    //             let s;
    //             for(const school of props.schools){
    //                 if(sid==school.id){
    //                     s=sid;
    //                 }
    //             }
    //             if(!s){
    //                 list.push(sid) 
    //             }
    //         }

    //         console.log("ACL" , list);
    //     }

    // }, [schoolAnalyticsObject,props.schools]);

    useEffect(() => {
        if (Parse.User.current()) {
            if (Parse.User.current().attributes.teacherId) {
                props.getUserTeacher(Parse.User.current().attributes.teacherId);
            }
            if (!props.franchise) {
                props.getFranchise(Parse.User.current().attributes.franchiseId);
            }
        }

    }, [props.success]);

    useEffect(() => {
        if (props.userTeacher?.attributes?.franchiseFeaturesList) {
            if (props.userTeacher.attributes.franchiseFeaturesList.length > 0) {
                if (props.userTeacher.attributes.franchiseFeaturesList[0] == 1)
                    setShowAllBranch(true);
            }
        }
    }, [props.userTeacher]);

    useEffect(() => {
        // console.log("schoolAnalytics",props.schoolAnalytics);
        if (props.schoolAnalytics && props.schoolAnalytics.length > 0) {

            let instituteIds = [];
            let schoolIds = [];
            let directorCount = 0;
            let tecahersCount = 0;
            let kidCount = 0;
            let dayCareKidCount = 0;
            for (const sa of props.schoolAnalytics) {

                if (!schoolIds.includes(sa.attributes.schoolId)) {
                    schoolIds.push(sa.attributes.schoolId)
                    tecahersCount = tecahersCount + sa.attributes.teacherCount;
                    kidCount = kidCount + sa.attributes.studentCount;
                    dayCareKidCount = dayCareKidCount + sa.attributes.daycareStudentCount;
                }

                if (!instituteIds.includes(sa.attributes.instituteId)) {
                    instituteIds.push(sa.attributes.instituteId)
                    directorCount = directorCount + sa.attributes.directorCount;
                }
            }

            let temp = {
                instituteIds: instituteIds,
                schoolIds: schoolIds,
                directorCount: directorCount,
                tecahersCount: tecahersCount,
                kidCount: kidCount,
                dayCareKidCount: dayCareKidCount
            }
            // console.log("schoolAnalyticsObject",temp)
            setSchoolAnalyticsObject(temp)
        }
    }, [props.schoolAnalytics]);


    const getCardUI = (title, value, icon, col) => {
        return (
            <Col lg={col}>
                <Card >
                    <CardBody>

                        {/* <Media>
                            <Media body> */}
                        <Row className='d-flex justify-content-between'>
                            <Col className='d-flex justify-content-start'>
                                <div className="me-3">
                                    <p className="text-muted mb-2">{title}</p>

                                    <div>
                                        <h5 className="mb-0"> {value}
                                            {(col == 12) && (showAllBranch) && <Link
                                                className='m-2'
                                                to={"/all-branches/" + Parse.User.current().attributes.franchiseId}
                                            >
                                                <u>View All</u>
                                            </Link>
                                            }

                                        </h5>
                                    </div>
                                </div>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <div className="avatar-sm  d-flex justify-content-end ">
                                    <div className="avatar-title bg-light rounded-circle text-primary font-size-20 float-right">
                                        <i className={icon}></i>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        {/* </Media>


                            
                        </Media> */}
                    </CardBody>
                </Card>
            </Col>
        )
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Topbar title="DASHBOARD" search={true} />

                    <Row>
                        <Col>
                            {subscriptionExpiryDate && <Col xl="12">
                                <SubscriptionInfoComp subscriptionExpiryDate={subscriptionExpiryDate} />
                            </Col>}
                        </Col>
                    </Row>

                    <Card className='bg-soft-primary'>
                        <CardBody>
                            <div >
                                <Row>
                                    <Col xs="10">
                                        <div className="text-primary p-2">
                                            <h4 className="text-primary">{props.franchise && props.franchise.attributes.name}</h4>
                                            <p>{props.franchise && props.franchise.attributes.city}</p>
                                            {/* <ul className="pl-3 mb-0">
                                                <li className="py-1">{props.franchise && props.franchise.attributes.address}</li>
                                            </ul> */}
                                        </div>
                                    </Col>
                                    <Col xs="2" className=" float-right">
                                        <img
                                            src={(props.franchise && props.franchise.attributes.logo) ? props.franchise.attributes.logo._url : logo} alt=""
                                            className="rounded avatar-lg border"
                                        />
                                    </Col>
                                </Row>
                            </div>

                        </CardBody>

                    </Card>
                    <Row>

                        <Col lg={5}>
                            <Label></Label>
                            <Row>
                                {getCardUI("Total Schools & PreSchools", props.schools ? props.schools.length : "0", "bx bxs-buildings", 12)}

                                {getCardUI("Students", schoolAnalyticsObject ? schoolAnalyticsObject.kidCount : "0", "bx bxs-user", 6)}

                                {getCardUI("Daycare Students", schoolAnalyticsObject ? schoolAnalyticsObject.dayCareKidCount : "0", "bx bxs-user", 6)}

                                {getCardUI("Owners/Directors", schoolAnalyticsObject ? schoolAnalyticsObject.directorCount : "0", "bx bxs-user-check", 6)}

                                {getCardUI("Teachers", schoolAnalyticsObject ? schoolAnalyticsObject.tecahersCount : "0", "bx bxs-user-check", 6)}
                            </Row>

                        </Col>

                        <Col lg={7}>

                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4">  Enquiry Trend </CardTitle>
                                    <EnquiryTrendChart enquiryAnalytics={props.enquiryAnalyticsForTenDays} />
                                </CardBody>
                            </Card>
                        </Col>





                    </Row>

                </Container>
            </div>
        </React.Fragment>
    );


}

const mapStatetoProps = state => {
    const { schools, schoolAnalytics } = state.School;
    const { franchise } = state.Franchise;
    const { enquiryAnalyticsForTenDays } = state.EnquiryAnalytics;
    const { userTeacher } = state.Login;

    return {
        schools, schoolAnalytics,
        franchise, userTeacher,
        enquiryAnalyticsForTenDays,
    };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps,
    {
        getSchoolAnalytics,
        getEnquiryAnalyticsFor10Days,
        getFranchise,
        getUserTeacher
    }
)(Franchise))); 