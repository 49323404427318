import * as actions from "./actionTypes";

//attendance
export const getAttendanceForTeacherIds = (params) => {
  return {
    type: actions.GET_ATTENDANCE_FOR_TEACHER_IDS,
    payload: { params },
  };
};

export const setAttendanceForTeacherIds = (attendanceObjs) => {
  return {
    type: actions.SET_ATTENDANCE_FOR_TEACHER_IDS,
    payload: { attendanceObjs },
  };
};

export const getAttendanceForKidIds = (params) => {
  return {
    type: actions.GET_ATTENDANCE_FOR_KID_IDS,
    payload: { params },
  };
};

export const setAttendanceForKidIds = (attendanceObjs) => {
  return {
    type: actions.SET_ATTENDANCE_FOR_KID_IDS,
    payload: { attendanceObjs },
  };
};

export const getDaycareAttendanceForKidIds = (params) => {
  return {
    type: actions.GET_DAYCARE_ATTENDANCE_FOR_KID_IDS,
    payload: { params },
  };
};

export const setDaycareAttendanceForKidIds = (attendanceObjs) => {
  return {
    type: actions.SET_DAYCARE_ATTENDANCE_FOR_KID_IDS,
    payload: { attendanceObjs },
  };
};

//enquiry

export const getEnquiryKidsForFilter = (filters) => {
  return {
    type: actions.GET_ENQUIRY_KIDS_FOR_FILTERS,
    payload: { filters },
  };
};

export const setEnquiryReportForFilter = (enquiryObjs) => {
  return {
    type: actions.SET_ENQUIRY_REPORT_FOR_FILTER,
    payload: { enquiryObjs },
  };
};

export const getAttendanceForClassId = (classId, startDate, endDate) => {
  return {
    type: actions.GET_ATTENDANCE_FOR_CLASS_ID,
    payload: { classId, startDate, endDate },
  };
};

export const updateClassAttendance = (classAttendance) => {
  return {
    type: actions.UPDATE_CLASS_ATTENDANCE,
    payload: { classAttendance },
  };
};

export const getAttendanceForDaycareId = (daycareId, startDate, endDate) => {
  return {
    type: actions.GET_ATTENDANCE_FOR_DAYCARE_ID,
    payload: { daycareId, startDate, endDate },
  };
};

export const updateDaycareAttendance = (daycareAttendance) => {
  return {
    type: actions.UPDATE_DAYCARE_ATTENDANCE,
    payload: { daycareAttendance },
  };
};

export const setAttendanceQueueTimer =(value) => {
  return {
    type: actions.ATTENDANCE_QUEUE_TIMER,
    payload: { value }
  }
}