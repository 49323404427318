import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as EnquiryReminderHelper from "../../../helpers/ParseHelpers/enquiryreminder_helper";
import * as actionTypes from "./actionTypes";
import * as EnquiryReminderActions from "./actions";
import * as Constants from "../../../Constents";

function* getAEnquiryReminder({ payload: { id } }) {
  try {
    const response = yield call(
      EnquiryReminderHelper.getEnquiryReminderFromParse,
      id,
      false
    );
    yield put(EnquiryReminderActions.updateEnquiryReminder(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAEnquiryReminder({ payload: { enquiryReminder } }) {
  try {
    const response = yield call(
      EnquiryReminderHelper.deleteEnquiryReminderFromParse,
      enquiryReminder
    );
    if (response) {
      yield put(EnquiryReminderActions.updateEnquiryReminder(response));
      response.unPin();
    } else {
      // not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* setSelectedEnquiryReminder({ payload: { id } }) {
  try {
    const localResponse = yield call(
      EnquiryReminderHelper.getEnquiryReminderFromParse,
      id,
      true
    );
    if (localResponse) {
      yield put(
        EnquiryReminderActions.updateSelectedEnquiryReminder(localResponse)
      );
    }

    const serverResponse = yield call(
      EnquiryReminderHelper.getEnquiryReminderFromParse,
      id,
      false
    );
    if (serverResponse) {
      yield put(
        EnquiryReminderActions.updateSelectedEnquiryReminder(serverResponse)
      );
      serverResponse.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getEnquiryReminderForEnquiryId({ payload: { enquiryId } }) {
  try {
    yield put(EnquiryReminderActions.relplaceEnquiryReminders([]));
    const localResponse = yield call(EnquiryReminderHelper.getEnquiryRemindersForEnquiryId, enquiryId, true);
    if (localResponse && localResponse.length > 0) {
      yield put(EnquiryReminderActions.relplaceEnquiryReminders(localResponse));

    }

    const serverResponse = yield call(EnquiryReminderHelper.getEnquiryRemindersForEnquiryId, enquiryId, false);

    if (serverResponse && serverResponse.length > 0) {
      yield put(EnquiryReminderActions.updateEnquiryReminders(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getEnquiryRemindersForEnquiryIds({ payload: { enquiryIds } }) {
  try {


    const serverResponse = yield call(EnquiryReminderHelper.getEnquiryRemindersForEnquiryIds, enquiryIds);

    if (serverResponse && serverResponse.length > 0) {
      yield put(EnquiryReminderActions.updateEnquiryRemindersForEnquiryIds(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* editEnquiryReminder({ payload: { enquiryReminder } }) {
  try {
    const response = yield call(EnquiryReminderHelper.editEnquiryReminder, enquiryReminder);
    if (response) {
      yield put(EnquiryReminderActions.updateEnquiryReminder(response));
      response.pin();
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getEnquiryRemindersForSchoolId({ payload: { schoolId, startDate, endDate } }) {
  try {
    const response = yield call(EnquiryReminderHelper.getEnquiryRemindersForSchoolIdWithDates, schoolId, startDate, endDate, false);
    if (response) {
      yield put(EnquiryReminderActions.updateEnquiryRemindersForSchoolId(response));
      response.pin();
    } else {

    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* saveEnquiryReminder({ payload: { enquiryReminder } }) {
  try {
    const response = yield call(EnquiryReminderHelper.saveEnquiryReminder, enquiryReminder);
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    } else
      if (response && response.length > 0) {
        yield put(EnquiryReminderActions.updateEnquiryRemindersForSchoolId([response]));
        response.pin();
        toastr.success("Enquiry Reminder successfully updated", { timeOut: 3000 });
      } else {
      }
  } catch (error) {

  }
}

export function* watchGetEnquiryReminder() {
  yield takeEvery(actionTypes.GET_ENQUIRY_REMINDER, getAEnquiryReminder);
}

export function* watchDeleteEnquiryReminder() {
  yield takeEvery(actionTypes.DELETE_ENQUIRY_REMINDER, deleteAEnquiryReminder);
}

export function* watchSetSelectedEnquiryReminder() {
  yield takeEvery(
    actionTypes.SET_SELECTED_ENQUIRY_REMINDER,
    setSelectedEnquiryReminder
  );
}

export function* watchGetEnquiryRemindersForEnquiryId() {
  yield takeEvery(actionTypes.GET_ENQUIRY_REMINDERS, getEnquiryReminderForEnquiryId);
}

export function* watchEditEnquiryReminder() {
  yield takeEvery(actionTypes.EDIT_ENQUIRY_REMINDER, editEnquiryReminder);
}

export function* watchgetEnquiryRemindersForSchoolId() {
  yield takeEvery(actionTypes.GET_ENQUIRY_REMINDERS_SCHOOL, getEnquiryRemindersForSchoolId);
}

export function* watchSaveEnquiryReminder() {
  yield takeEvery(actionTypes.SAVE_ENQUIRY_REMINDER, saveEnquiryReminder);
}

export function* watchGetEnquiryRmindersForEnquiryIds() {
  yield takeEvery(actionTypes.GET_ENQUIRY_REMINDERS_EQUIRY_IDS, getEnquiryRemindersForEnquiryIds);
}


function* enquiryreminderSaga() {
  yield all([
    fork(watchGetEnquiryReminder),
    fork(watchDeleteEnquiryReminder),
    fork(watchSetSelectedEnquiryReminder),
    fork(watchGetEnquiryRemindersForEnquiryId),
    fork(watchEditEnquiryReminder),
    fork(watchGetEnquiryRmindersForEnquiryIds),
    fork(watchgetEnquiryRemindersForSchoolId),
    fork(watchSaveEnquiryReminder),
  ]);
}
export default enquiryreminderSaga;
