import * as actions from "./actionTypes";

export const getInventorySaleOrderItemsForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_SALE_ORDER_ITEMS,
    payload: {ownerId, ownerType},
    }
}

export const updateInventorySaleOrderItems = (inventorySaleOrderItems) =>{
    return{
        type:actions.UPDATE_INVENTORY_SALE_ORDER_ITEMS,
        payload: {inventorySaleOrderItems},
    }
}

export const addInventorySaleOrderItem = (inventorySaleOrderItem) =>{
    return{
        type: actions.ADD_INVENTORY_SALE_ORDER_ITEM,
        payload: {inventorySaleOrderItem}
    }
}

export const deleteInventorySaleOrderItem = (inventorySaleOrderItem) =>{
    return{
        type:actions.DELETE_INVENTORY_SALE_ORDER_ITEM,
        payload: {inventorySaleOrderItem},
    }
}

export const updateDeleteInventorySaleOrderItem = (inventorySaleOrderItem) =>{
    return{
        type:actions.UPDATE_DELETE_INVENTORY_SALE_ORDER_ITEM,
        payload: {inventorySaleOrderItem},
    }
}

export const setSelectedSaleOrderItems = (inventorySaleOrderItemId) => {
    return {
        type: actions.SET_SELECTED_SALE_ORDER_ITEMS,
        payload: {inventorySaleOrderItemId }
    }
}

export const updateSelectedSaleOrdersItems = (inventorySaleOrderItems) => {
    return {
      type: actions.UPDATE_SELECTED_SALE_ORDER_ITEMS,
      payload: { inventorySaleOrderItems },
    };
  };

  export const editSaleOrderItem = (inventorySaleOrderItem) =>{
    return{
        type: actions.EDIT_INVENTORY_SALE_ORDER_ITEM,
        payload: {inventorySaleOrderItem}
    }
}