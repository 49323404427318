import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Card, CardBody, UncontrolledTooltip, Media, Badge } from "reactstrap";


const CardProgram = (props) => {

  const isDeleteButtonHidden = (program) => {
    if (program.status && program.status.toLowerCase() === "upcoming") {
      return true
    }
    return false
  }

  return (
    <React.Fragment>
      <Row>
        {
          props.programs.map((program, key) =>
            <Col xl="4" sm="6" key={"_program_" + key} >
              <Link to={"/programview/" + program.parseObjectGroup.attributes.schoolId + "/" + program.parseObjectProgram.id + "/" + program.parseObjectGroup.id} className="text-dark">
                <Card>
                  <CardBody>
                    <Media>
                      <div className='d-flex'>
                        <div className="avatar-md mr-4 text-center">
                          <span className="avatar-title rounded-circle bg-light text-primary font-size-16" style={{ width: '60px', height: '60px', overflow: 'hidden' }}>
                            <div style={{ fontSize: "25px", whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                              <i className="mr-2 bx bx-user"></i>{program.childrenCount}
                            </div>
                          </span>
                        </div>
                        <div className='d-flex m-2'>
                          <Media className="overflow-hidden" body>
                            <h5 className="text-truncate font-size-15" style={{ maxWidth: '200px' }}>{program.programName}</h5>
                            <p className="text-muted mb-0">{program.programBatch}</p>
                            <p className="text-muted mb-0">{program.programType}</p>
                            {program.parseObjectProgram.attributes.hasTerm && <p className='text-muted mt-0'>Includes terms</p>}
                          </Media>
                        </div>
                      </div>
                    </Media>
                  </CardBody>
                  <div className="px-4 py-3 border-top">
                    <ul className="list-inline mb-0">
                      <li className="list-inline-item mr-3">
                        <Badge color={program.color}>{program.status}</Badge>
                      </li>
                      <li className="list-inline-item mr-3" id={"startDate" + key}>
                        <i className="bx bx-calendar mr-0"></i> {program.startDate}
                        <UncontrolledTooltip placement="top" target={"startDate" + key}>
                          Start Date
                        </UncontrolledTooltip>
                      </li>


                      <li className="list-inline-item mr-3" id={"endDate" + key}>
                        <i className="bx bx-calendar mr-1"></i> {program.endDate}
                        <UncontrolledTooltip placement="top" target={"endDate" + key}>
                          End Date
                        </UncontrolledTooltip>
                      </li>
                      {/* <li className="list-inline-item mr-3" id="comments">
                      <i className="bx bx-user mr-1"></i> {program.childrenCount}
                      <UncontrolledTooltip placement="top" target="comments">
                        Comments
                                    </UncontrolledTooltip>
                    </li> */}

                    </ul>
                  </div>
                </Card>
              </Link>
            </Col>
          )

        }
      </Row>
    </React.Fragment>
  );
}


export default CardProgram;