import React, { useState, useEffect } from 'react';
import { Container, Row, Col, Card, CardBody, CardTitle, Form, Label, Input, Button, FormGroup, ButtonDropdown, DropdownMenu, DropdownToggle, DropdownItem } from 'reactstrap';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import KitImage from "../../../assets/images/school-bag.png";
import ItemImage from "../../../assets/images/stationery.png";
import Parse from 'parse';
import toastr from 'toastr'
import * as Constants from "../../../Constents";
import { setSelecetdSchool, getInventoryKitsForOwnerId, getInventoryItemForOwnerId, getCartForForOwnerId, updateCartForOwnerId, addInventoryCart, getFranchise, getInventoryStockForWarehouse, getInventoryWarehouseForOwnerId } from '../../../store/actions';


const Products = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/inventory-products/:schoolId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;

    const [franchiseId, setFranchiseId] = useState(undefined);
    const [categories, setCategories] = useState(undefined);
    const [categorySetting, setCategoriesSetting] = useState(0);
    const [dropDownOpen, setDropDownOpen] = useState(false)

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }

        if (!franchiseId) {
            setFranchiseId(props.selectedSchool && props.selectedSchool.attributes.franchiseId)
        }
        if (props.franchise && props.franchise.attributes.features) {

            if (props.franchise.attributes.features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
                props.franchise.attributes.features[Constants.FRANCHISE_INVENTORY_INDEX] > 1) {
                setCategoriesSetting(1)
            } else {
                setCategoriesSetting(-1)
            }

        }

        if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
            props.getInventoryWarehouseForOwnerId(props.selectedSchool.attributes.franchiseId, 'franchise');
        }

        setCategories(props.selectedSchool && props.selectedSchool.attributes.inventoryCategories)

    }, [props.selectedSchool, props.franchise, schoolId]);

    useEffect(() => {
        if (franchiseId && categorySetting != 0) {
            props.getInventoryKitsForOwnerId(franchiseId, 'franchise', true);
            // console.log(categories)
            // console.log(categorySetting)
            props.getInventoryItemForOwnerId(franchiseId, 'franchise', true, categories, categorySetting == 1 ? true : false, props.selectedSchool.id)
        }

    }, [franchiseId, categories, categorySetting]);

    useEffect(() => {
        if (props.inventoryWarehouses && props.inventoryWarehouses.length > 0) {
            props.getInventoryStockForWarehouse(props.inventoryWarehouses[0].id);
        }
    }, [props.inventoryWarehouses]);

    useEffect(() => {
        props.getCartForForOwnerId(schoolId, "school");
    }, [schoolId]);


    function searchKit(search) {
        // var kitList = props.inventoryKits;
        // var tempList = [...props.inventoryKits];
        // if (search === undefined || search === null || search.length === 0) {
        //     kitList(tempList);
        // } else if (tempList) {
        //     let filter = tempList.filter((item) => {
        //         return (item.attributes.name.toLowerCase()).includes(search.toLowerCase())
        //     });
        //     kitList(filter);
        // }

    }

    const [cartProductIds, setCartProductIds] = useState([]);

    useEffect(() => {
        let list = [];
        for (const i of props.inventoryCart) {
            list.push(i.attributes.productId)
        }
        setCartProductIds(list);
    }, [props.inventoryCart]);


    const addToCart = (product) => {

        const ShoppingCart = Parse.Object.extend("ShoppingCart");
        var cart = new ShoppingCart();

        cart.set('schoolId', schoolId);
        cart.set('productId', product.id);
        cart.set('userId', Parse.User.current().attributes.teacherId);
        cart.set('productType', product.className == 'InventoryKits' ? "inventoryKit" : "inventoryItem");
        cart.set('quantity', 1);

        cart.save().then((rCart) => {
            props.updateCartForOwnerId([rCart]);
            toastr.success("Added to cart", { timeOut: 3000 });
        }, (error) => {
            console.log(error)
        })

    }

    const removeFromCart = (product) => {
        var cart = props.inventoryCart.filter(
            (productInCart) => productInCart.attributes.productId == product.id
        );
        if (cart && cart.length > 0) {
            cart[0].set('isDeleted', true);
            cart[0].set('deletedBy', Parse.User.current().attributes.teacherId);
            props.addInventoryCart(cart[0], "removed");
        }
    }

    const getStock = (itemId) => {
        if (props.inventoryItemStockMap && props.inventoryItemStockMap.length > 0) {
            let temp = props.inventoryItemStockMap.filter(
                (stock) => stock.attributes.itemId == itemId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.stock ?? 0)
            }
        }

        return 0
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className='d-flex align-items-start'>
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <h4>Products</h4>
                            </div>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                <Link
                                    className="btn  btn-success m-1"
                                    to={"/school-cart/" + schoolId}
                                >
                                    Cart <i className="bx bxs-cart"></i>
                                </Link>
                                <ButtonDropdown
                                    className="custom-button-dropdown m-1"
                                    isOpen={dropDownOpen}
                                    toggle={() => {
                                        setDropDownOpen(!dropDownOpen)
                                    }}
                                >
                                    <DropdownToggle
                                        caret
                                        color="success"
                                        className="btn btn-success"
                                    >
                                        Menu List &nbsp;{" "}
                                        <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                        <Link to={"/sale-order/" + schoolId}>
                                            <DropdownItem>Orders <i className="bx bxs-shopping-bag"></i></DropdownItem>
                                        </Link>
                                        <Link to={"/inventory-report/" + schoolId}>
                                            <DropdownItem>Reports<i className="bx bxs-report"></i></DropdownItem>
                                        </Link>
                                    </DropdownMenu>
                                </ButtonDropdown>
                            </div>
                        </Col>
                    </Row>
                    {(props.inventoryItems && props.inventoryItems.length > 0) || (props.inventoryKits && props.inventoryKits.length > 0) ? (<Row>
                        <Col>
                            <Row>
                                {true &&
                                    props.inventoryKits.map((product, key) =>
                                        <Col xl="3" sm="6" key={"_col_" + product.id}>
                                            <Card>
                                                <CardBody>
                                                    {product.attributes.displayImage ?
                                                        <div>
                                                            <img src={product.attributes.displayImage._url} alt="" className="img-fluid mx-auto d-block" style={{ width: "10rem", height: "10rem" }} />
                                                        </div> :
                                                        <div className="maintenance-img">
                                                            <img src={KitImage} alt="" className="img-fluid mx-auto d-block" style={{ width: "10rem", height: "10rem" }} />
                                                        </div>

                                                    }
                                                    <div className="mt-4 text-center">
                                                        <h5 className="mb-3 text-truncate"><Link to={"/inventory-product-details/" + schoolId + "/kit/" + product.id} className="text-dark">{product.attributes.name} </Link></h5>
                                                        <h5 className="my-0"><Link to={"/inventory-product-details/" + schoolId + "/kit/" + product.id} className="text-dark">Price:<b>₹{product.attributes.sellingPrice}</b> </Link></h5>
                                                        <div className='text-center'>
                                                            {cartProductIds.includes(product.id) ?
                                                                <Button type="button" color="danger" className="btn-sm waves-effect waves-light mt-2 mr-1"
                                                                    onClick={() => {
                                                                        removeFromCart(product)
                                                                    }}
                                                                >
                                                                    <i className="bx bx-cart mr-2"></i>Remove From Cart
                                                                </Button> :
                                                                <Link className="btn btn-sm btn-primary waves-effect waves-light mt-2 mr-1"
                                                                    to={"/inventory-product-details/" + schoolId + "/kit/" + product.id} >
                                                                    <i className="bx bx-cart mr-2"></i> Add to cart
                                                                </Link>
                                                            }
                                                        </div>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                }
                                {
                                    props.inventoryItems.map((product, key) =>
                                        <Col xl="3" sm="6" key={"_col_" + product.id}>
                                            <Card>
                                                <CardBody>
                                                    {product.attributes.displayImage ?
                                                        <div>
                                                            <img src={product.attributes.displayImage._url} alt="" className="img-fluid mx-auto d-block" style={{ width: "10rem", height: "10rem" }} />
                                                        </div> :
                                                        <div className="maintenance-img">
                                                            <img src={ItemImage} alt="" className="img-fluid mx-auto d-block" style={{ width: "10rem", height: "10rem" }} />
                                                        </div>
                                                    }
                                                    <div className="mt-4 text-center">
                                                        <h5 className="mb-3 text-truncate"><Link to={"/inventory-product-details/" + schoolId + "/item/" + product.id} className="text-dark">{product.attributes.name} </Link></h5>
                                                        <h5 className="my-0"><Link to={"/inventory-product-details/" + schoolId + "/item/" + product.id} className="text-dark">Price:<b>₹{product.attributes.sellingPrice}</b> </Link></h5>
                                                        <div className='text-center'>
                                                            {cartProductIds.includes(product.id) ?
                                                                <Button type="button" color="danger" className="btn-sm waves-effect waves-light mt-2 mr-1"
                                                                    onClick={() => {
                                                                        removeFromCart(product)
                                                                    }}
                                                                >
                                                                    <i className="bx bx-cart mr-2"></i>Remove From Cart
                                                                </Button> :
                                                                <Link className="btn btn-sm btn-primary waves-effect waves-light mt-2 mr-1"
                                                                    to={"/inventory-product-details/" + schoolId + "/item/" + product.id} >
                                                                    <i className="bx bx-cart mr-2"></i> Add to cart
                                                                </Link>}
                                                        </div>
                                                        {/* {getStock(product.id) <= 0 && <div className="bg-light text-danger badge text-center m-2">
                                                            Out of Stock
                                                        </div>} */}
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    )
                                }
                            </Row>
                        </Col>
                    </Row>) : 
                     (<div>
                        {<Card style={{ height: "400px" }}>
                            <CardBody>
                                <h4 style={{ marginTop: "150px" }} className='text-info text-center'>No Products found .</h4>
                            </CardBody>
                        </Card>}
                    </div>)}
                </Container>
            </div >
        </React.Fragment >
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryKits } = state.InventoryKits;
    const { inventoryItems } = state.InventoryItems;
    const { inventoryCart } = state.InventorySaleOrder;
    const { franchise } = state.Franchise;
    const { inventoryWarehouses, inventoryItemStockMap } = state.InventoryWarehouse;
    return { selectedSchool, inventoryKits, inventoryItems, inventoryCart, franchise, inventoryWarehouses, inventoryItemStockMap };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryKitsForOwnerId, getInventoryItemForOwnerId, getCartForForOwnerId, updateCartForOwnerId, addInventoryCart, getFranchise, getInventoryStockForWarehouse, getInventoryWarehouseForOwnerId })(Products)));