import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import Parse from 'parse';
import * as TripHelper from '../../../helpers/ParseHelpers/trips_helper'
import * as TripActions from './actions';
import * as actionsTypes from './actionTypes';
import toastr from 'toastr';


function* getTripsForSchoolIdSaga({ payload: { schoolId } }) {
    try {
        const response = yield call(TripHelper.getTripsForSchoolIdParse, schoolId);
        if (response) {
            yield put(TripActions.updateTrips(response));
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}

function* resetTrip({ payload: { trip } }) {
    try {
        const response = yield call(TripHelper.resetTrip, trip);
        if (response) {
            yield put(TripActions.updateTrips([response]));
            toastr.success('Trip reset successfull..! ', { timeOut: 2000 });
        }
    } catch (error) {
        console.log(error);
        // yield put(apiError(error));
    }
}



export function* watchGetTripsForSchoolIdSaga() {
    yield takeEvery(actionsTypes.GET_TRIPS_FOR_SCHOOL_ID, getTripsForSchoolIdSaga)
}
export function* watchresetTripSaga() {
    yield takeEvery(actionsTypes.RESET_TRIP, resetTrip)
}


function* tripSaga() {
    yield all([
        fork(watchGetTripsForSchoolIdSaga),
        fork(watchresetTripSaga),

    ]);
}
export default tripSaga;