import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  TabContent,
  Table,
  Card,
  CardBody,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  UncontrolledTooltip,
  CardFooter,
  Label,
  FormGroup,
  Button,
  Nav,
  NavItem,
  NavLink,
  TabPane,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  Badge,
} from "reactstrap";
import classnames from "classnames";

import Alert from "reactstrap/lib/Alert";
import { matchPath } from "react-router";
import Breadcrumbs from "./Breadcrumbs";
import ProcessView from "./processView";
import CardTeacherView from "./cardTeacherView";
import Parse from "parse";
import ListTeacherView from "./listTeacherView";
import { withNamespaces } from "react-i18next";
import {
  getAllKids,
  getClassroomsForSchoolId,

  getTeacherForClassroom,
  getTeachersForSchool,
  updateTeachersObjs,
  setSelecetdSchool,
  checkAndAddGroupMapForKids,
  createClassForASchool,
  deleteClassRoom,
  updateAClassroomObj,
  updateAKid,
  getAdmissionKidsForSchool,
  getParentsForFamilyIds,
  deleteKidsInList,
  updateKidTosterSuccess,
  getGroupsForSchoolId,
  getFranchise,
  getCorporatePartners,
  getProgramsForSchoolId,
  getGroupMapsForProgramGroupIds,
} from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import * as Constants from "../../Constents";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
import AddClass from "../classes/addClass";
import Licence from '../../components/Common/Licence'
import SimpleBar from "simplebar-react";

const Student = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/classroom/:schoolId/:classId",
    exact: true,
    strict: false,
  });
  const matchAll = matchPath(props.history.location.pathname, {
    path: "/student/:id",
    exact: true,
    strict: false,
  });
  const classroomId = match && match.params.classId
  const [schoolId, setSchoolId] = useState("");
  const [showFor, showforOps] = useState("All");



  const [showDeleteKids, showDeleteKidsOps] = useState(false);
  const [deleteReasonError, setDeleteReasonError] = useState(false);
  const [reasonForDelete, setReasonForDelete] = useState(undefined);
  const [isClassDaycare, setIsClassDaycare] = useState(false);

  const [navActiveTab, setNavActiveTab] = useState("1");

  const [displayType, setDisplayType] = useState(Constants.DISPLAY_TYPE_LIST);

  const [kids, kidsOps] = useState([]);
  const [classActiveTab, classActiveTabOps] = useState();
  const [showClassView, showClassViewOps] = useState(false);
  const [activeClassRoom, activeClassRoomOps] = useState({});
  const [btnInfo1, setBtnInfo1] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_add_class, modal_add_classOps] = useState(false);
  const [teachersList, teacherListOps] = useState([]);
  const [editClassteachersList, editClassteachersListOps] = useState([]);
  const [classteachers, classteachersOps] = useState([]);
  const [editClassDetailsName, editClassDetailsNameOps] = useState("");
  const [editClassDetailstype, editClassDetailstypeOps] = useState();
  const [editClassType, editClassTypeOps] = useState(0);
  const [showDelete, showDeleteOps] = useState(false);
  const [editClassEnable, editClassEnableOps] = useState(false);
  const [classDropDown, classDropDownOps] = useState(false);
  const [groupDropDown, groupDropDownOps] = useState(false);
  const [daycareDropDown, daycareDropDownOps] = useState(false);
  const [showMoveToOptions, showMoveToOptionsOps] = useState(false);
  const [moveToChildList, moveToChildListOps] = useState([]);
  const [kidDeleteModalType, kidDeleteModalTypeOps] = useState([]);
  const [isDaycareEnableForSchool, isDaycareEnableForSchoolOps] = useState(
    false
  );
  const [corporatePartnerModal, setCorporatePartnerModal] = useState(false);
  const [corporatePartnerId, setCorporatePartnerId] = useState();

  const [modal_add_kid, modal_add_kidOps] = useState(false);
  const [admissionKids, admissionKidsOps] = useState([]);
  const [searchValue, searchValueOps] = useState("");
  const [modal_standard1, modal_standard1Ops] = useState(false);
  const [deleteClass, deleteClassOps] = useState(false);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [loding, setLoading] = useState(false);
  const [selectedAddmisionKids, setSelectedAddmisionKids] = useState(0);


  useEffect(() => {
    if (localStorage.getItem(Constants.STUDENT_DISPLAY_TYPE)) {
      setDisplayType(localStorage.getItem(Constants.STUDENT_DISPLAY_TYPE));
    }
  });

  useEffect(() => {
    if (match && match.params) {
      setSchoolId(match.params.schoolId);
      if (showFor !== match.params.classId) {
        showforOps(match.params.classId);
      }
    }
  }, [match]);

  useEffect(() => {
    if (matchAll && matchAll.params) {
      setSchoolId(matchAll.params.id);
      if (showFor !== "All") {
        showforOps("All");
      }
    }
  }, [matchAll]);

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
    if (props.selectedSchool) {
      if (
        props.selectedSchool.attributes.featuresList &&
        props.selectedSchool.attributes.featuresList[7] === 2
      ) {
        isDaycareEnableForSchoolOps(true);
      } else {
        isDaycareEnableForSchoolOps(false);
      }
    }
    if (props.selectedSchool && props.selectedSchool.attributes.franchiseId && props.selectedSchool.attributes.franchiseId.length > 0) {
      props.getFranchise(props.selectedSchool.attributes.franchiseId);
    }
  }, [props.selectedSchool, schoolId]);

  const [showCorporatePartners, setShowCorporatePartners] = useState(false);
  useEffect(() => {

    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[4] > 0) {
      setShowCorporatePartners(true);
      props.getCorporatePartners(props.franchise.id, "franchiseId")

    } else {
      setShowCorporatePartners(false);
    }
  }, [props.franchise]);


  useEffect(() => {
    if (schoolId && schoolId.length > 0) {
      props.getClassroomsForSchoolId(schoolId);
      props.getTeachersForSchool(schoolId);
      props.getGroupsForSchoolId(schoolId);
      props.getAllKids(schoolId);
      if (showFor != "All") {
        props.getProgramsForSchoolId(schoolId)
      }
    }
  }, [schoolId]);

  useEffect(() => {

    if (props.programs) {
      if (props.programs.length > 0) {
        var groupIds = []
        let now = new Date();
        for (var i = 0; i < props.programs.length; i++) {
          if (props.programs[i].attributes.endDate > now) {
            groupIds.push(props.programs[i].attributes.groupId)
          }
        }

        if (groupIds.length > 0) {
          props.getGroupMapsForProgramGroupIds(groupIds);
        }
      }
    }

  }, [props.programs]);


  useEffect(() => {
    classteachersOps(props.classTeachers);
  }, [props.classTeachers]);
  useEffect(() => {
    if (props.classrooms.length > 0) {
      if (showFor === "All") {
        let temp = [];
        for (let i in props.classrooms) {
          temp.push(props.classrooms[i].id);
        }

        showClassViewOps(false);
      } else {
        let temp = [];
        temp.push(showFor);

        let isDaycare = false;
        for (let i in props.classrooms) {
          if (props.classrooms[i].id === showFor) {
            activeClassRoomOps(props.classrooms[i]);
            if (props.classrooms[i].attributes.classroomType === 2) {
              setIsClassDaycare(true);
              isDaycare = true;
            }
            break;
          }
        }

        if (isDaycare) {
          let classKids = props.kids && props.kids.filter(
            (kid) => kid.attributes.ClassRoomID === showFor || kid.attributes.daycareClassId === showFor);
          kidsOps(classKids);
        } else {
          let classKids = props.kids && props.kids.filter(
            (kid) => kid.attributes.ClassRoomID === showFor);
          kidsOps(classKids);
        }

        props.getTeacherForClassroom(showFor);
        showClassViewOps(true);
        classActiveTabOps("1");

      }
    }

  }, [showFor, props.classrooms]);
  useEffect(() => {
    if (showFor === "All") {
      kidsOps(props.kids);
    } else {

      let isDaycare = false;
      for (let i in props.classrooms) {
        if (props.classrooms[i].id === showFor) {
          activeClassRoomOps(props.classrooms[i]);
          if (props.classrooms[i].attributes.classroomType === 2) {
            setIsClassDaycare(true);
            isDaycare = true;
          }
          break;
        }
      }
      if (isDaycare) {
        let classKids = props.kids && props.kids.filter(
          (kid) => kid.attributes.ClassRoomID === showFor || kid.attributes.daycareClassId === showFor);
        kidsOps(classKids);
      } else {
        let classKids = props.kids && props.kids.filter(
          (kid) => kid.attributes.ClassRoomID === showFor);
        kidsOps(classKids);
      }
    }

  }, [props.kids]);

  useEffect(() => {
    if (props.teachers.length > 0) {
      let temp = [];
      for (let i in props.teachers) {
        if (props.teachers[i].attributes.Role === 1) {
          let tempLF = {};
          tempLF.id = props.teachers[i].id;
          tempLF.name = props.teachers[i].attributes.Name;
          tempLF.designation = "Teacher";
          tempLF.imgUrl = props.teachers[i].attributes.Photo
            ? props.teachers[i].attributes.Photo._url
            : "";
          tempLF.classRooms = props.teachers[i].attributes.ClassRooms
            ? props.teachers[i].attributes.ClassRooms
            : [];
          tempLF.isTeacherOfthisClass = false;
          if (showFor !== "All") {
            tempLF.isTeacherOfthisClass = tempLF.classRooms.includes(showFor);
          }
          temp.push(tempLF);
        }
      }
      if (temp.length > 0) {
        teacherListOps(temp);
      }
    }
  }, [props.teachers]);
  useEffect(() => {
    if (props.updatedKidSuccess) {
      toastr.success(props.t("Student Updated Successfully"), {
        timeOut: 1500,
      });
      props.updateKidTosterSuccess();
    }
  }, [props.updatedKidSuccess]);
  const toggleDisplay = (tab) => {
    if (displayType !== tab) {
      localStorage.setItem(Constants.TEACHER_DISPLAY_TYPE, tab);
      setDisplayType(tab);
    }
  }
  const updateView = (value) => {
    showforOps(value);
    if (value === "All") {
      props.history.push(`/student/${schoolId}`);
    } else {
      props.history.push(`/classroom/${schoolId}/${value}`);
    }
  };
  function tog_standard() {
    setmodal_standard(!modal_standard);
    if (!modal_standard) {
      let temp = JSON.stringify(teachersList);
      editClassteachersListOps(JSON.parse(temp));
      removeBodyCss();
    }
  }
  function add_class_tog_standard() {
    modal_add_classOps(!modal_add_class);
    removeBodyCss();
    if (!modal_add_class) {
      editClassDetailsNameOps("");
      editClassDetailstypeOps();
      editClassEnableOps(false);
    }
  }
  function removeBodyCss() {
    document.body.classList.add("no_padding");
  }
  const updateTeacherCheck = (id, value) => {
    let tempL = JSON.parse(JSON.stringify(editClassteachersList));
    for (let i in tempL) {
      if (tempL[i].id === id) {
        tempL[i].isTeacherOfthisClass = value;
        break;
      }
    }
    editClassteachersListOps(tempL);
  };
  const updateSelectedTeacherToClass = () => {
    let tempT = Object.assign([], props.teachers);
    let objToSave = [];
    for (let i in editClassteachersList) {
      for (let j in tempT) {
        if (editClassteachersList[i].id === tempT[j].id) {
          if (editClassteachersList[i].isTeacherOfthisClass) {
            if (tempT[j].attributes.ClassRooms) {
              if (!tempT[j].attributes.ClassRooms.includes(showFor)) {
                let tempC = tempT[j].attributes.ClassRooms;
                tempC.push(showFor);
                tempT[j].set("ClassRooms", tempC);
                objToSave.push(tempT[j]);
              }
            } else {
              let tempC = [];
              tempC.push(showFor);
              tempT[j].set("ClassRooms", tempC);
              objToSave.push(tempT[j]);
            }
          } else {
            if (tempT[j].attributes.ClassRooms) {
              if (tempT[j].attributes.ClassRooms.includes(showFor)) {
                let tempC = tempT[j].attributes.ClassRooms;
                let pos = tempC.indexOf(showFor);
                tempC = tempC.splice(1, pos);
                tempT[j].set("ClassRooms", tempC);
                objToSave.push(tempT[j]);
              }
            }
          }
          break;
        }
      }
    }
    if (objToSave.length > 0) {
      props.updateTeachersObjs(objToSave, showFor);
    }
    tog_standard();
    // props.getTeachersForSchool(schoolId);
    props.getTeacherForClassroom(showFor);
  };
  const submitForcreatingclass = () => {
    if (editClassEnable) {
      let classDetails = activeClassRoom;
      classDetails.set("Name", editClassDetailsName);
      classDetails.set("classLevel", editClassDetailstype);
      if (editClassType === 0 || editClassType === "0") {
        classDetails.unset('classroomType')
      } else {
        classDetails.set('classroomType', editClassType);
      }

      props.updateAClassroomObj(classDetails);
      add_class_tog_standard();
    } else {
      let classDetails = {};
      classDetails.name = editClassDetailsName;
      classDetails.level = editClassDetailstype;
      classDetails.schoolId = schoolId;
      classDetails.classroomType = editClassType;
      props.createClassForASchool(classDetails);
      add_class_tog_standard();
    }
  };
  const handelDeleteClass = () => {
    deleteAllKidForclass();
    // props.deleteClassRoom(showFor); //this will delete the class
    showDeleteOps(false);
  };
  const deleteAllKidForclass = () => {
    //loop for deleting
    let deleteKidsList = [];
    for (const val of kids) {
      if (val.attributes.ClassRoomID === showFor) {
        deleteKidsList.push(val.id);
      }
    }
    if (deleteKidsList.length === 0) {
      props.deleteClassRoom(showFor);
    } else {
      moveToChildListOps(deleteKidsList);
      kidDeleteModalTypeOps(1);
      showDeleteKidsOps(true);
      deleteClassOps(true);
    }
  };
  useEffect(() => {
    if (props.classDeleted) {
      toastr.success(props.t("Class Deleted Successfully"), { timeOut: 1500 });
      props.deleteClassRoom(undefined);
      setTimeout(() => {
        window.history.back();
      }, 1000);
    }
  }, [props.classDeleted]);
  const setCurrentClassEdit = () => {
    editClassDetailsNameOps(activeClassRoom.attributes.Name);

    if (!props.academicCycles || props.academicCycles.length === 0) {
      let tempList = ["SrKG", "JrKG", "Nursery"]

      if (tempList.includes(activeClassRoom.attributes.classLevel)) {
        editClassDetailstypeOps(activeClassRoom.attributes.classLevel);
      } else {
        editClassDetailstypeOps();
      }
    } else if (props.academicCycles) {
      let foundCycle = false
      for (const cyc of props.academicCycles) {
        if (cyc.attributes.schoolCycle === activeClassRoom.attributes.classLevel || cyc.attributes.defaultCycle === activeClassRoom.attributes.classLevel) {
          foundCycle = true
        }
      }
      if (foundCycle) {
        editClassDetailstypeOps(activeClassRoom.attributes.classLevel);
      } else {
        editClassDetailstypeOps();
      }
    }
    editClassTypeOps(activeClassRoom.attributes.classroomType);
    editClassEnableOps(true);
  };

  const showDeletePopup = () => {
    let deleteKidsList = [];
    for (let i in moveToChildList) {
      for (let j in kids) {
        if (kids[j].id === moveToChildList[i]) {
          deleteKidsList.push(kids[j]);
          break;
        }
      }
    }
    if (deleteKidsList.length > 0) {
      let disassociateKidsList = [];
      let multiKidsSelected = false;
      let onlyDaycareKidsSelected = false;
      let onlyClassKidsSelected = false;

      for (let j in deleteKidsList) {
        if (
          deleteKidsList[j].attributes.daycareClassId != undefined &&
          deleteKidsList[j].attributes.daycareClassId.length > 0
        ) {
          onlyClassKidsSelected = true;
        } else {
          onlyDaycareKidsSelected = true;
        }
      }
      if (isClassDaycare) {
        if (onlyClassKidsSelected && onlyDaycareKidsSelected) {
          kidDeleteModalTypeOps(3);
        } else if (onlyDaycareKidsSelected && !onlyClassKidsSelected) {
          kidDeleteModalTypeOps(1);
        } else if (!onlyDaycareKidsSelected && onlyClassKidsSelected) {
          kidDeleteModalTypeOps(2);
        }
      } else {
        kidDeleteModalTypeOps(1);
      }

      showDeleteKidsOps(true);
    } else {
      toastr.error(props.t("No student selected to Delete"), { timeOut: 1500 });
    }
  };
  const handleDeleteKids = () => {
    let deleteKidsList = [];
    for (let i in moveToChildList) {
      for (let j in kids) {
        if (kids[j].id === moveToChildList[i]) {
          if (
            kids[j].attributes.daycareClassId != undefined &&
            kids[j].attributes.daycareClassId.length > 0 &&
            kids[j].attributes.daycareClassId !== "" &&
            isClassDaycare
          ) {
            kids[j].set("daycareClassId", "");
            kids[j].set("isDaycareChild", 0);

            // kids[j].remove('daycareClassId')
            // kids[j].remove('isDaycareChild')
          } else {
            kids[j].set("Deleted", true);
            kids[j].set("deletedOn", new Date());
            kids[j].set("deletedStatus", reasonForDelete);
            if (Parse.User.current().attributes.teacherId) {
              kids[j].set(
                "deletedBy",
                Parse.User.current().attributes.teacherId
              );
            } else {
              kids[j].set("deletedBy", Parse.User.current().id);
            }
          }

          deleteKidsList.push(kids[j]);
          break;
        }
      }
    }
    if (deleteKidsList.length > 0) {
      props.deleteKidsInList(deleteKidsList);
    }
    showDeleteKidsOps(false);
    if (deleteClass) {
      props.deleteClassRoom(showFor);
      deleteClassOps(false);
    }
  };

  useEffect(() => {
    if (props.kidsDeletedSuccessfully) {
      showDeleteKidsOps(false);
      if (kidDeleteModalType === 3) {
        toastr.success(
          props.t(
            "Student in daycare got deleted successfully, and student in multiple class got disassociated from daycare"
          ),
          { timeOut: 1500 }
        );
      } else if (kidDeleteModalType === 2) {
        toastr.success(
          props.t("Student disassociated from daycare successfully"),
          { timeOut: 1500 }
        );
      } else if (kidDeleteModalType === 1) {
        toastr.success(props.t("Student deleted successfully"), {
          timeOut: 1500,
        });
      }
    }
  }, [props.kidsDeletedSuccessfully]);

  const moveToGroup = (group) => {


    if (moveToChildList.length > 0) {
      props.checkAndAddGroupMapForKids(group.id, moveToChildList, `Successfully added selected students to ${group.attributes.name ?? "group"} !!`, `Selected students are already in ${group.attributes.name ?? "group"} !!`)
    } else {
      toastr.error(props.t("No student selected to update"), { timeOut: 1500 });
    }
  };

  const moveToClass = (classId,classroomType) => {
    let moveChildList = [];
    for (let i in moveToChildList) {
      for (let j in kids) {
        if (kids[j].id === moveToChildList[i]) {
          kids[j].set("ClassRoomID", classId);
          if(classroomType==2){
            kids[j].unset("daycareClassId");
            kids[j].unset("isDaycareChild");
          }
          moveChildList.push(kids[j]);
          break;
        }
      }
    }
    if (moveChildList.length > 0) {
      for (let i in moveChildList) {
        props.updateAKid(moveChildList[i], showFor);
      }
    } else {
      toastr.error(props.t("No student selected to update"), { timeOut: 1500 });
    }
  };
  function navToggleTab(tab) {
    if (navActiveTab !== tab) {
      setNavActiveTab(tab);
    }
  }
  const addToDaycare = (daycareId) => {
    let moveChildList = [];
    for (let i in moveToChildList) {
      for (let j in kids) {
        if (kids[j].id === moveToChildList[i]) {
          kids[j].set("daycareClassId", daycareId);
          kids[j].set("isDaycareChild", 1);
          moveChildList.push(kids[j]);
          break;
        }
      }
    }
    if (moveChildList.length > 0) {
      for (let i in moveChildList) {
        props.updateAKid(moveChildList[i]);
      }
    } else {
      toastr.error(props.t("No student selected to update"), { timeOut: 1500 });
    }
  };

  const addCarporatePartner = (corporateId) => {
    let moveChildList = [];
    for (let i in moveToChildList) {
      for (let j in kids) {
        if (kids[j].id === moveToChildList[i]) {
          kids[j].set("corporateId", corporateId);
          moveChildList.push(kids[j]);
          break;
        }
      }
    }
    if (moveChildList.length > 0) {
      for (let i in moveChildList) {
        props.updateAKid(moveChildList[i]);
      }
    } else {
      toastr.error(props.t("No student selected to update"), { timeOut: 1500 });
    }
    setCorporatePartnerModal(false)
  };
  function add_Kid_tog_standard() {
    modal_add_kidOps(!modal_add_kid);
    removeBodyCss();
    if (!modal_add_kid) {
      props.getAdmissionKidsForSchool(schoolId);
    }
  }
  useEffect(() => {
    let ak = [];
    let familyIds = [];
    for (let i in props.admissionKids) {
      let temp = {};
      temp.kidObj = props.admissionKids[i];
      temp.id = props.admissionKids[i].id;
      temp.name = props.admissionKids[i].attributes.Name;
      temp.familyId = props.admissionKids[i].attributes.FamilyID;
      temp.email = props.admissionKids[i].attributes.email;
      temp.phoneNumber = props.admissionKids[i].attributes.phoneNumber;
      temp.moveToClass = false;
      temp.enquiryFor = props.admissionKids[i].attributes.enquiryFor;
      familyIds.push(props.admissionKids[i].attributes.FamilyID);
      ak.push(temp);
    }
    if (familyIds.length > 0) {
      admissionKidsOps(ak);
      props.getParentsForFamilyIds(familyIds);
    }
  }, [props.admissionKids]);
  useEffect(() => {
    let ak = admissionKids;
    for (let i in ak) {
      for (let j in props.parents) {
        if (props.parents[j].attributes.FamilyID === ak[i].familyId) {
          ak[i].parentObj = props.parents[j];
          if (props.parents[j].attributes.Relation === "Mother") {
            ak[i].motherName = props.parents[j].attributes.Name;
            ak[i].motherPhone = props.parents[j].attributes.PhoneNumber;
          } else {
            ak[i].fatherName = props.parents[j].attributes.Name;
            ak[i].fatherPhone = props.parents[j].attributes.PhoneNumber;
          }
        }
      }
    }
    if (ak) {
      admissionKidsOps(ak);
    }
  }, [props.parents]);

  const handleAddStudent = () => {
    if (props.classrooms.length === 0) {
      modal_standard1Ops(true);
    } else {
      add_Kid_tog_standard();
    }
  };
  const tog_standard_1 = () => {
    modal_standard1Ops(!modal_standard1);
  };

  const getKidCountForClass = () => {
    let count = 0;
    let activeClassId = activeClassRoom.id;
    for (let i in kids) {
      if (activeClassId) {
        if (
          activeClassId === kids[i].attributes.ClassRoomID ||
          activeClassId === kids[i].attributes.daycareClassId
        ) {
          count++;
        }
      }
    }
    return count;
  };

  const addAdmissionKidToCalss = () => {
    let moveToKidsCount = 0;
    for (let i in admissionKids) {
      if (admissionKids[i].moveToClass) {
        let kidObj = admissionKids[i].kidObj;
        kidObj.set("ClassRoomID", showFor);
        kidObj.set("SchoolID", schoolId);
        let set;
        kidObj.set("schoolAdmitted", set);
        props.updateAKid(kidObj);
        moveToKidsCount += 1;
      }
    }
    if (moveToKidsCount > 0) {
      add_Kid_tog_standard();
      let temp = [];
      temp.push(showFor);
      toastr.success(props.t("successfully added to the class"), {
        timeOut: 1000,
      });

    }
  };

  const getMoveToClassKidsLeangth=(admissionKids)=>{
    let list =[];
    if(admissionKids && admissionKids.length>0){
      for(const k of admissionKids){
        if(k.moveToClass){
          list.push(k);
        }
      }
    }
    return list.length;
  }
  const handleSearch = (search) => {
    searchValueOps(search);
  };

  return (
    <React.Fragment>
      <AddClass
        modal_standard={modal_standard1}
        tog_standard={tog_standard_1}
      />

      <Modal
        isOpen={showDeleteKids || props.deletekidLoading}
        toggle={() => {
          if (showDeleteKids) {
            setReasonForDelete(undefined);
            setDeleteReasonError(false);
          }
          showDeleteKidsOps(!showDeleteKids);
        }}
      >
        <ModalHeader
          toggle={() => {
            showDeleteKidsOps(false);
            setReasonForDelete(undefined);
            setDeleteReasonError(false);
          }}
        >
          {kidDeleteModalType !== 2 && kidDeleteModalType !== 3 && (
            <h5 className="modal-title mt-0">
              {props.t("Please select a reason for delete")}
            </h5>
          )}
          {kidDeleteModalType === 3 && (
            <h5 className="modal-title mt-0">{props.t("Confirmation")}</h5>
          )}
          {kidDeleteModalType === 2 && (
            <h5 className="modal-title mt-0">{props.t("Confirmation")}</h5>
          )}
        </ModalHeader>
        <ModalBody>
          <div>
            {kidDeleteModalType === 3 && (
              <h5 className="modal-title mt-0 mb-5">
                {props.t(
                  "Students exclusively in daycare will be deleted, and students who are enrolled in regular classes will be disassociated from daycare."
                )}
              </h5>
            )}

            {kidDeleteModalType === 2 && (
              <h5 className="modal-title mt-0 mb-5">
                {props.t("Students will be disassociated from daycare !!")}
              </h5>
            )}

            {kidDeleteModalType === 3 && (
              <h5 className="modal-title mt-0">
                {props.t("Please select a reason for delete")}
              </h5>
            )}

            {kidDeleteModalType !== 2 && (
              <div>
                <Badge
                  onClick={(e) => {
                    setReasonForDelete(1);
                    setDeleteReasonError(false);
                  }}
                  style={{ cursor: "pointer" }}
                  className={
                    reasonForDelete && reasonForDelete === 1
                      ? "badge badge-secondary font-size-14 m-2"
                      : "badge badge-soft-primary font-size-14 m-2"
                  }
                >
                  Passed Out
                </Badge>
                <Badge
                  onClick={(e) => {
                    setReasonForDelete(2);
                    setDeleteReasonError(false);
                  }}
                  style={{ cursor: "pointer" }}
                  className={
                    reasonForDelete && reasonForDelete === 2
                      ? "badge badge-secondary font-size-14 m-2"
                      : "badge badge-soft-primary font-size-14 m-2"
                  }
                >
                  Drop Out
                </Badge>
                <Badge
                  onClick={(e) => {
                    setReasonForDelete(3);
                    setDeleteReasonError(false);
                  }}
                  style={{ cursor: "pointer" }}
                  className={
                    reasonForDelete && reasonForDelete === 3
                      ? "badge badge-secondary font-size-14 m-2"
                      : "badge badge-soft-primary font-size-14 m-2"
                  }
                >
                  Improper Data
                </Badge>
                <Badge
                  onClick={(e) => {
                    setReasonForDelete(4);
                    setDeleteReasonError(false);
                  }}
                  style={{ cursor: "pointer" }}
                  className={
                    reasonForDelete && reasonForDelete === 4
                      ? "badge badge-secondary font-size-14 m-2"
                      : "badge badge-soft-primary font-size-14 m-2"
                  }
                >
                  Incomplete Documents
                </Badge>
              </div>
            )}
          </div>

          {deleteReasonError && kidDeleteModalType !== 2 && (
            <p className="text-danger ml-2">
              {props.t("Please select a reason before proceeding")}
            </p>
          )}
        </ModalBody>

        <ModalFooter>
          {!props.deletekidLoading ? (
            <Row className="text-center">
              <Col>
              <button
                type="button"
                onClick={() => {
                  showDeleteKidsOps(false);
                  setReasonForDelete(undefined);
                  setDeleteReasonError(false);
                }}
                className="btn btn-secondary waves-effect m-1"
                data-dismiss="modal"
              >
                {props.t("Close")}
              </button>
              <button
                type="submit"
                className="btn btn-danger waves-effect waves-light m-1"
                onClick={() => {
                  if (
                    reasonForDelete === undefined &&
                    kidDeleteModalType !== 2
                  ) {
                    setDeleteReasonError(true);
                  } else {
                    handleDeleteKids();
                  }
                }}
              >
                {kidDeleteModalType !== 2 && props.t("Delete")}
                {kidDeleteModalType === 2 &&
                  props.t("Disassociate from daycare")}
              </button>{" "}
              </Col>
            </Row>
          ) : (
            <button type="button" className="btn btn-light waves-effect">
              <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
              {kidDeleteModalType !== 2 && props.t(" Deleting student")}
              {kidDeleteModalType == 2 &&
                props.t(" Disassociating from daycare")}
            </button>
          )}
        </ModalFooter>
      </Modal>

      <Modal
        size="lg"
        isOpen={modal_add_kid}
        toggle={() => {
          add_Kid_tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {props.t("Add Students to the class")}
          </h5>
          <button
            type="button"
            onClick={() => {
              add_Kid_tog_standard();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm className="outer-repeater" onSubmit={(e) => {
            e.preventDefault(); // Prevent the default form submission
            setShowLimitModal(true)
            // addAdmissionKidToCalss();
            setLoading(true)}}>
          <div className="modal-body">
            <h5>
              {props.t("Add from Admission list or    ")}
              <Link to={props.selectedSchool && props.selectedSchool.attributes.schoolType == 11 ? "/add-import/" + schoolId : "/addNewKid/" + schoolId + "/" + classroomId} className="btn btn-primary btn-sm">
                {props.t("Add New")}
              </Link>
            </h5>
            <SimpleBar style={{ maxHeight: "400px" }}>
            <Table>
              <thead>
                <tr>
                  <th>#</th>
                  <th>{props.t("Name")}</th>
                  <th>{props.selectedSchool && props.selectedSchool.attributes.schoolType == 11 ? props.t("Details") : props.t("Family")}</th>
                  <th>{props.t("Tag")}</th>
                </tr>
              </thead>
              <tbody>
                {admissionKids.map((val, key) => (
                  <tr key={key}>
                    <td>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input input-mini"
                          id={`checkbox${val.id}`}
                          value={val.moveToClass}
                          onClick={(e) => {
                            let ak = admissionKids;
                            for (let i in ak) {
                              if (ak[i].id === val.id) {
                                ak[i].moveToClass = !val.moveToClass;
                                break;
                              }
                            }
                            setSelectedAddmisionKids(getMoveToClassKidsLeangth(ak))
                            admissionKidsOps(ak);
                          }}
                        />
                        <Label
                          className="custom-control-label"
                          for={`checkbox${val.id}`}
                        ></Label>
                      </div>
                    </td>
                    <td>{val.name}</td>
                    {
                      props.selectedSchool && props.selectedSchool.attributes.schoolType == 11 ? <td>
                        <p> {val.phoneNumber}</p>
                        <br></br>
                        <p>{val.email}</p>
                      </td> :
                        <td>
                          {val.motherName && (
                            <p>
                              {val.motherName} : {val.motherPhone}
                              <br></br>
                            </p>
                          )}
                          {val.fatherName && (
                            <p>
                              {val.fatherName} : {val.fatherPhone}
                            </p>
                          )}
                        </td>}
                    <td>
                      {val.enquiryFor &&
                        val.enquiryFor.map((valef, keyef) => (
                          <span
                            className="badge badge-pill badge-soft-primary font-size-11"
                            key={keyef}
                          >
                            {valef}
                          </span>
                        ))}
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
            </SimpleBar>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                add_Kid_tog_standard();
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            <Button type="submit" color="primary" disabled={selectedAddmisionKids>0 ?false:true}>
              {props.t("Add to Class")}
            </Button>
          </div>
        </AvForm>
      </Modal>
      <div className="page-content">
        {showDelete && (
          <SweetAlert
            warning
            showCancel
            confirmBtnText="Delete"
            cancelBtnText="Cancel"
            confirmBtnBsStyle="danger"
            cancelBtnBsStyle="success"
            title="Alert!"
            onCancel={() => {
              showDeleteOps(false);
            }}
            onConfirm={() => handelDeleteClass()}
          >
            {props.t("Are you sure you want to delete")} <br></br>
          </SweetAlert>
        )}
        <Container fluid>
          {props.error && props.error ? (
            <Alert color="danger">{props.error}</Alert>
          ) : null}
          {props.success && props.success ? (
            <Alert color="success">{props.success}</Alert>
          ) : null}
          <Breadcrumbs
            title={
              showFor === "All"
                ? (props.selectedSchool && props.selectedSchool.attributes.schoolType === 10) ? `ALL PATICIPANTS (${kids.length ? kids.length : 0})` : `ALL STUDENTS (${kids.length ? kids.length : 0})`
                : `${activeClassRoom != undefined && activeClassRoom.attributes
                  ? activeClassRoom.attributes.Name
                  : ""
                } `
            }
            schoolId={schoolId}
            toggleDisplay={toggleDisplay}
            displayType={displayType}
            selectedSchool={props.selectedSchool}
            addclass={add_class_tog_standard}
            classrooms={props.classrooms}
            activeSchool={showFor}
            showforOps={updateView}
            showClassView={showClassView}
            handleAddStudentButtonClick={() => {
              handleAddStudent();
            }}
            handleSearch={handleSearch}
            searchValue={searchValue}
          />
          {showClassView && (
            <div>

              <Col>
                <Card>
                  <CardBody>
                    <Row>
                      <Col lg={12} md={12} xs={12}>
                        {/* <h3 className="center">{ activeClassRoom ? activeClassRoom.attributes.Name : '' }</h3> */}
                        <h5>
                          {""}
                          <div className="d-flex justify-content-end">
                            <Dropdown
                              isOpen={btnInfo1}
                              toggle={() => setBtnInfo1(!btnInfo1)}

                            >
                              <DropdownToggle
                                tag="button"
                                className="btn btn-info"
                              >
                                {props.t("Action")}{" "}
                                <i className="mdi mdi-chevron-down"></i>
                              </DropdownToggle>
                              <DropdownMenu>
                                <DropdownItem
                                  onClick={() => {
                                    tog_standard();
                                  }}
                                >
                                  {props.t("Assign Teacher")}
                                </DropdownItem>
                                <DropdownItem onClick={add_Kid_tog_standard}>
                                  {props.t("Add Student")}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => {
                                    add_class_tog_standard();
                                    setCurrentClassEdit();
                                  }}
                                >
                                  {props.t("Edit Class")}
                                </DropdownItem>
                                <DropdownItem
                                  onClick={() => showDeleteOps(true)}
                                >
                                  {props.t("Delete Class")}
                                </DropdownItem>
                              </DropdownMenu>
                            </Dropdown>
                          </div>
                        </h5>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={12} md={12} xs={12}>
                        <div className="">
                          <div className="table-responsive mt-3">
                            <Table className="table mb-0 table-bordered">
                              <tbody>
                                <tr>
                                  <th scope="row" width="20%">
                                    {props.t("Academic Cycle ")}:
                                  </th>
                                  <td>
                                    {activeClassRoom
                                      ? activeClassRoom.attributes.classLevel
                                      : ""}
                                  </td>
                                </tr>
                                <tr>
                                  <th scope="row" width="20%">
                                    {props.t("Number of Students")} :
                                  </th>
                                  <td>{getKidCountForClass()}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </CardBody>
                </Card>
              </Col>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Nav pills className="navtab-bg nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: navActiveTab === "1",
                          })}
                          onClick={() => {
                            navToggleTab("1");
                          }}
                        >
                          {props.t("Students")}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: navActiveTab === "2",
                          })}
                          onClick={() => {
                            navToggleTab("2");
                          }}
                        >
                          {props.t("Teachers")}
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={navActiveTab}>
                      <TabPane tabId="2" className="p-3">
                        <Row className="mb-1">
                          <Col lg={12}>
                            <div className="d-flex justify-content-end">
                              {/* {displayType === 2 && <span><Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light" onClick={tog_standard}>+ {props.t('Assign Teacher')}</Button></span>} */}
                              {displayType === Constants.DISPLAY_TYPE_LIST && (
                                <Button
                                  className="m-1"
                                  color="info"
                                  onClick={tog_standard}
                                >
                                  {props.t("Assign Teacher")}
                                </Button>
                              )}
                            </div>
                          </Col>
                        </Row>
                        <Row>
                          {displayType === Constants.DISPLAY_TYPE_GRID && (
                            <Col xl="3" sm="6">
                              <Card className="text-center">
                                <CardBody>
                                  <div
                                    className="avatar-xl mx-auto"
                                    onClick={() => {
                                      tog_standard();
                                    }}
                                    style={{ cursor: "pointer" }}
                                  >
                                    <span
                                      className={
                                        "avatar-title rounded-circle bg-soft-primary text-primary h1"
                                      }
                                    >
                                      +
                                    </span>
                                  </div>
                                </CardBody>
                                <CardFooter className="bg-transparent border-top">
                                  <div className="contact-links d-flex font-size-20">
                                    <div className="flex-fill">
                                      <Link
                                        to="#"
                                        id={"edit_class_teachers"}
                                        onClick={() => {
                                          tog_standard();
                                        }}
                                        data-toggle="modal"
                                        data-target="#myModal"
                                      >
                                        <h5>{props.t("Assign Teacher")}</h5>
                                        <UncontrolledTooltip
                                          placement="top"
                                          target={"edit_class_teachers"}
                                        >
                                          {props.t("Assign Teacher")}
                                        </UncontrolledTooltip>
                                      </Link>
                                    </div>
                                  </div>
                                </CardFooter>
                              </Card>
                            </Col>
                          )}
                          {displayType === Constants.DISPLAY_TYPE_GRID &&
                            classteachers.map((val, key) => (
                              <CardTeacherView key={key} teacher={val} />
                            ))}

                          {displayType === Constants.DISPLAY_TYPE_LIST && (
                            <ListTeacherView
                              teachers={classteachers}
                              popUpAction={tog_standard}
                            />
                          )}
                          <Modal
                            isOpen={modal_standard}
                            toggle={() => {
                              tog_standard();
                            }}
                          >
                            <div className="modal-header">
                              <h5
                                className="modal-title mt-0"
                                id="myModalLabel"
                              >
                                {props.t("Choose Teachers")}
                              </h5>
                              <button
                                type="button"
                                onClick={() => {
                                  setmodal_standard(false);
                                }}
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                              >
                                <span aria-hidden="true">&times;</span>
                              </button>
                            </div>
                            <div className="modal-body">
                              <Table className="table-centered table-nowrap table-hover">
                                <thead className="thead-light">
                                  <tr>
                                    <th scope="col" style={{ width: "45px" }}>
                                      #
                                    </th>
                                    <th></th>
                                    <th scope="col">{props.t("Name")}</th>
                                    <th scope="col">
                                      {props.t("Designation")}
                                    </th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {editClassteachersList.map((val, key) => (
                                    <tr key={key}>
                                      <td>
                                        <div className="custom-control custom-checkbox">
                                          <input
                                            type="checkbox"
                                            className="custom-control-input m-1"
                                            id={`CustomCheck_${val.id}`}
                                            onChange={() =>
                                              updateTeacherCheck(
                                                val.id,
                                                !val.isTeacherOfthisClass
                                              )}
                                            checked={val.isTeacherOfthisClass}
                                          />
                                          <label
                                            className="custom-control-label m-0 p-0"
                                            onClick={() =>
                                              updateTeacherCheck(
                                                val.id,
                                                !val.isTeacherOfthisClass
                                              )
                                            }
                                          ></label>
                                        </div>
                                      </td>
                                      <td>
                                        {!val.imgUrl ? (
                                          <div className="avatar-xs">
                                            <span className="avatar-title rounded-circle">
                                              {val.name.charAt(0).toUpperCase()}
                                            </span>
                                          </div>
                                        ) : (
                                          <div>
                                            <img
                                              className="rounded-circle avatar-xs"
                                              src={val.imgUrl}
                                              alt=""
                                            />
                                          </div>
                                        )}
                                      </td>
                                      <td>{val.name}</td>
                                      <td>{val.designation}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </Table>
                            </div>
                            <div className="modal-footer">
                              <button
                                type="button"
                                onClick={() => {
                                  tog_standard();
                                }}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                              >
                                Close
                              </button>
                              <button
                                type="button"
                                className="btn btn-primary waves-effect waves-light"
                                onClick={updateSelectedTeacherToClass}
                              >
                                Save changes
                              </button>
                            </div>
                          </Modal>
                        </Row>
                      </TabPane>
                      <TabPane tabId="1" className="p-3">
                        <TabContent activeTab={classActiveTab}>
                          <div className="row">
                            <div className="col-3">
                              {displayType === Constants.DISPLAY_TYPE_LIST && (
                                <Button
                                  className="m-1"
                                  color="info"
                                  onClick={add_Kid_tog_standard}
                                >
                                  Add Student
                                </Button>
                              )}
                            </div>
                            <div className="col-9 mb-2">
                              {displayType === Constants.DISPLAY_TYPE_LIST && (
                                <div className="d-flex justify-content-end">
                                  <Button
                                    className="m-1"
                                    color="danger"
                                    onClick={() => {
                                      showDeletePopup();
                                    }}
                                  >
                                    Delete
                                  </Button>
                                  <Dropdown
                                    isOpen={groupDropDown}
                                    toggle={() => groupDropDownOps(!groupDropDown)}
                                    className="m-1"
                                  >
                                    <DropdownToggle tag="button" className="btn btn-info">
                                      Add To Groups <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {props.groups && props.groups.length > 0 && props.groups.map((group, key) => (
                                        <DropdownItem key={key} onClick={() => moveToGroup(group)}>
                                          {group.attributes.name}
                                        </DropdownItem>
                                      ))}
                                    </DropdownMenu>
                                  </Dropdown>
                                  {/* Add other buttons here as needed */}
                                  {isDaycareEnableForSchool && (
                                    <Dropdown
                                      isOpen={daycareDropDown}
                                      toggle={() => daycareDropDownOps(!daycareDropDown)}
                                      className="m-1"
                                    >
                                      <DropdownToggle tag="button" className="btn btn-info">
                                        Add To Daycare <i className="mdi mdi-chevron-down"></i>
                                      </DropdownToggle>
                                      <DropdownMenu>
                                        {props.classrooms.map((val, key) =>
                                          showFor !== val.id &&
                                          val.attributes.classroomType === 2 && (
                                            <DropdownItem
                                              className="text-danger"
                                              key={key}
                                              onClick={() => addToDaycare(val.id)}
                                            >
                                              {val.attributes.Name}
                                            </DropdownItem>
                                          )
                                        )}
                                      </DropdownMenu>
                                    </Dropdown>
                                  )}
                                  <Dropdown
                                    isOpen={classDropDown}
                                    toggle={() => classDropDownOps(!classDropDown)}
                                    className="m-1"
                                  >
                                    <DropdownToggle tag="button" className="btn btn-info">
                                      Move To Class <i className="mdi mdi-chevron-down"></i>
                                    </DropdownToggle>
                                    <DropdownMenu>
                                      {props.classrooms.map((val, key) =>
                                        showFor !== val.id && (
                                          <DropdownItem
                                            className={val.attributes.classroomType === 2 ? "text-danger" : ""}
                                            key={key}
                                            onClick={() => moveToClass(val.id,val.attributes.classroomType)}
                                          >
                                            {val.attributes.Name}
                                          </DropdownItem>
                                        )
                                      )}
                                    </DropdownMenu>
                                  </Dropdown>
                                  {showCorporatePartners && (
                                    <Dropdown>
                                      <DropdownToggle
                                        tag="button"
                                        className="btn btn-info m-1"
                                        onClick={() => setCorporatePartnerModal(true)}
                                      >
                                        Corporate Partner
                                      </DropdownToggle>
                                    </Dropdown>
                                  )}
                                </div>
                              )}
                            </div>
                          </div>
                          <AvForm className="outer-repeater">
                            <Row>
                              <ProcessView
                                showClassView={showClassView}
                                moveToChildListOps={moveToChildListOps}
                                showMoveToOptionsOps={showMoveToOptionsOps}
                                schoolId={schoolId}
                                activeClassId={showFor}
                                kids={kids}
                                classList={props.classrooms}
                                displayType={displayType}
                                handleAddStudentButtonClick={() => {
                                  handleAddStudent();
                                }}
                                offShowLoader={props.offShowLoader}
                                searchValue={searchValue}
                                programs={props.programs}
                                programGroupmaps={props.programGroupmaps}
                                showFor={showFor}
                              />
                            </Row>
                          </AvForm>
                        </TabContent>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </div>
          )}

          {!showClassView && (
            <Row>
              <ProcessView
                showClassView={showClassView}
                kids={kids}
                moveToChildListOps={moveToChildListOps}
                showMoveToOptionsOps={showMoveToOptionsOps}
                classList={props.classrooms}
                displayType={displayType}
                handleAddStudentButtonClick={() => {
                  handleAddStudent();
                }}
                offShowLoader={props.offShowLoader}
                searchValue={searchValue}
              />
            </Row>
          )}

          {!props.offShowLoader && (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                    {props.t("Loading")}{" "}
                  </Link>
                </div>
              </Col>
            </Row>
          )}
        </Container>
      </div>
      <Modal
        isOpen={modal_add_class}
        toggle={() => {
          add_class_tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {editClassEnable && props.t("Edit Class")}
            {!editClassEnable && props.t("Add Class")}
          </h5>
          <button
            type="button"
            onClick={() => {
              add_class_tog_standard();
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <AvForm className="outer-repeater" onValidSubmit={submitForcreatingclass}>
          <div className="modal-body">
            <FormGroup>
              <Label htmlFor="formname">{props.t("Class Name")}:</Label>
              <AvField
                type="text"
                name="name"
                placeholder={props.t("Enter Class Name...")}
                className="form-control"
                value={editClassDetailsName}
                validate={{
                  required: {
                    value: true,
                    errorMessage: props.t("Please enter class name..."),
                  },
                }}
                onChange={(e) => {
                  editClassDetailsNameOps(e.target.value);
                }}
              />
            </FormGroup>
            <Label htmlFor="formname">{props.t("Academic Cycle")}: </Label>
            <FormGroup>
              <select
                className="form-control"
                onChange={(e) => {
                  if (e.target.value === "addOrEdit") {
                    props.history.push("/school/" + schoolId + "/cycles");
                  } else {
                    let tempCyc = props.academicCycles.filter(cyc => cyc.attributes.schoolCycle === e.target.value || cyc.attributes.defaultCycle === e.target.value);
                    if (tempCyc && tempCyc.length > 0) {
                      editClassDetailstypeOps(tempCyc[0].attributes.schoolCycle ? tempCyc[0].attributes.schoolCycle : tempCyc[0].attributes.defaultCycle);
                      if (tempCyc[0].attributes.level === "Daycare") {
                        editClassTypeOps(2)
                      } else {
                        editClassTypeOps(0)
                      }
                    } else {
                      editClassDetailstypeOps(e.target.value);
                    }
                  }
                }}
                value={editClassDetailstype}
              >
                <option disabled selected value>Select Academic Cycle</option>

                {props.academicCycles &&
                  props.academicCycles.length > 0 &&
                  props.academicCycles.map((cycle) => (
                    <option
                      key={cycle.id}
                      value={
                        cycle.attributes.schoolCycle
                          ? cycle.attributes.schoolCycle
                          : cycle.attributes.defaultCycle
                      }
                    >
                      {cycle.attributes.schoolCycle
                        ? cycle.attributes.schoolCycle
                        : cycle.attributes.defaultCycle}
                    </option>
                  ))}
                {(!props.academicCycles ||
                  props.academicCycles.length === 0) && (
                    <div>
                      <option key={1} value="SrKG">
                        SrKG
                      </option>
                      <option key={2} value="JrKG">
                        JrKG
                      </option>
                      <option key={3} value="Nursery">
                        Nursery
                      </option>
                    </div>
                  )}
                <option key={""} value="addOrEdit">
                  *Add or Edit Academic cycle *
                </option>
              </select>
            </FormGroup>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                add_class_tog_standard();
              }}
              className="btn btn-secondary waves-effect"
              data-dismiss="modal"
            >
              Close
            </button>
            <Button type="submit" color="primary">
              {props.t("Save Class")}
            </Button>
          </div>
        </AvForm>
      </Modal>
      <Modal isOpen={corporatePartnerModal} scrollable={true}>
        <ModalHeader isOpen={corporatePartnerModal} toggle={() => {
          setCorporatePartnerId(undefined)
          setCorporatePartnerModal(!corporatePartnerModal)
        }}>
          <div className="modal-title mt-0">Assign Corporate Partners</div>
        </ModalHeader>
        <ModalBody className="modal-body">
          <p>
            {props.t(
              "Please select the Corporate Partners to assign the students"
            )}
          </p>
          {props.corporatePartners && props.corporatePartners.map((val, i) => (
            <div className="custom-control custom-checkbox" key={val.id}>
              <input
                type="checkbox"
                className="custom-control-input"
                id={val.id}
                onChange={() => {
                  setCorporatePartnerId(val.id);
                }}
                checked={val.id === corporatePartnerId}
              />
              <label className="custom-control-label m-2" htmlFor={val.id}>
                {val.attributes.name}
              </label>
            </div>
          ))}
        </ModalBody>

        <ModalFooter>
          <div>
            <button
              type="button"
              className="btn btn-secondary m-1"
              onClick={() => {
                setCorporatePartnerId(undefined)
                setCorporatePartnerModal(!corporatePartnerModal)
              }}
            >
              {props.t("Close")}
            </button>
            <button
              type="button"
              className="btn btn-primary m-1"
              onClick={() => {
                addCarporatePartner(corporatePartnerId)
              }}
            >
              {props.t("Save")}
            </button>
          </div>
        </ModalFooter>
      </Modal>
      <Licence  
        showopup ={showLimitModal}
        handelAdd={()=>{
          addAdmissionKidToCalss();
          setShowLimitModal(false);
          setLoading(false);  
        }}
        addCount={selectedAddmisionKids}
        handelClose={()=>{
          setShowLimitModal(false);
          setLoading(false);    
        }}
       >
      </Licence>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    kids,
    updatedKidSuccess,
    admissionKids,
    offShowLoader,
    kidsDeletedSuccessfully,
    deletekidLoading,
    isLoading,
  } = state.Kid;
  const { selectedSchool } = state.School;
  const { layout } = state.Layout;
  const { classrooms, classDeleted } = state.Classroom;
  const { classTeachers } = state.Teacher;
  const { teachers } = state.Teacher;
  const { groups } = state.Group;
  const { parents, selectedParents } = state.Parent;
  const { academicCycles, loadState } = state.AcademicCycles;
  const { franchise } = state.Franchise;
  const { corporatePartners } = state.CorporatePartners;
  const { programs } = state.Program;
  const { programGroupmaps } = state.GroupMap;
  return {
    kids,
    layout,
    classrooms,
    classTeachers,
    offShowLoader,
    teachers,
    updatedKidSuccess,
    admissionKids,
    parents,
    classDeleted,
    selectedParents,
    kidsDeletedSuccessfully,
    deletekidLoading,
    isLoading,
    selectedSchool,
    academicCycles,
    loadState,
    groups,
    franchise,
    corporatePartners,
    programs,
    programGroupmaps,
  };
};

export default connect(mapStatetoProps, {
  getAllKids,
  getClassroomsForSchoolId,
  getTeacherForClassroom,
  getTeachersForSchool,
  updateTeachersObjs,
  createClassForASchool,
  deleteClassRoom,
  updateAClassroomObj,
  updateAKid,
  getAdmissionKidsForSchool,
  getParentsForFamilyIds,
  deleteKidsInList,
  setSelecetdSchool,
  checkAndAddGroupMapForKids,
  updateKidTosterSuccess,
  getGroupsForSchoolId,
  getFranchise,
  getCorporatePartners,
  getProgramsForSchoolId,
  getGroupMapsForProgramGroupIds,
})(withRouter(withNamespaces()(Student)));
