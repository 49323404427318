import React, { useState, useEffect } from 'react';
import Parse from 'parse';
import Iframe from 'react-iframe';
import { Card, Row, Col, CardBody, CardTitle, CardSubtitle, Container, ModalFooter, Form, Progress, FormGroup, Label, Input, Modal, ModalBody, UncontrolledTooltip, CardText, ModalHeader } from "reactstrap";
import { withRouter, Link } from 'react-router-dom';
import { setSelecetdLearningActivity, getFilesForFolderId, AddFile, deleteFile, setSelecetdSchool, addLearningActivity, deleteLearningActivity, setSelectedCurriculum } from '../../store/actions';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import * as CommonFunctions from "../../components/Common/CommonFunctions";
import TopBar from './Topbar';

const ActivityContentList = (props) => {


    var match = matchPath(props.history.location.pathname, {
        path: "/lms-chapter-content2/:schoolId/:id",
        exact: true,
        strict: false,
    });
    if (!match) {
        match = matchPath(props.history.location.pathname, {
            path: "/lms-chapter-content2/:schoolId/:curriculumId/:cycleId/:id",
            exact: true,
            strict: false,
        });
    }
    const days = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 27, 29, 30, 31];
    const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12,];
    const curriculumId = match.params.curriculumId;
    const cycleId = match.params.cycleId;
    const parameter = match.params.id;
    const schoolId = match.params.schoolId;

    const [canEdit, setCanEdit] = useState(false);

    const [editActivity, setEditActivity] = useState(undefined);
    const [deleteActivity, setDeleteActivity] = useState(undefined)
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [activityName, setActivityName] = useState(undefined);
    const [activityDescription, setActivityDescription] = useState(undefined);
    const [activityDay, setActivityDay] = useState(1);
    const [activityMonth, setActivityMonth] = useState(1);
    const [success_dlg, setsuccess_dlg] = useState(false);

    const [selectedItemPopup, setSelectedItemPopup] = useState(false);
    const [selectedItem, setSelectedItem] = useState(undefined);
    const [selectedItemContent, setSelectedItemContent] = useState(undefined);


    const [videoUrl, setVideoUrl] = useState(undefined);
    const [allContent, setAllContent] = useState([]);


    useEffect(() => {
        props.getFilesForFolderId(1);
        props.setSelecetdLearningActivity(parameter);
    }, [parameter]);
    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (!props.selectedCurriculum || props.selectedCurriculum.id !== curriculumId) {
            props.setSelectedCurriculum(curriculumId);
        }
    }, [curriculumId]);

    useEffect(() => {
        setCanEdit(CommonFunctions.canEdit(props.selectedCurriculum, props.userTeacher));
    }, [props.selectedCurriculum, props.userTeacher]);


    useEffect(() => {

        console.log("update 79")
        let helpObjectList = [];

        if (true || props.selcetedActivity.attributes.videoLink && props.selcetedActivity.attributes.videoLink.length > 0) {
            let helpObject = {};
            helpObject.index = 0;
            helpObject.key = "videoLink";
            helpObject.title = "YouTube Video";
            helpObject.placeholder = "YouTube Video URL";
            helpObject.content = props.selcetedActivity && props.selcetedActivity.attributes.videoLink
            helpObjectList.push(helpObject);
        }

        if (true || props.selcetedActivity.attributes.invitation && props.selcetedActivity.attributes.invitation.length > 0) {
            let helpObject = {};
            helpObject.index = 1;
            helpObject.key = "invitation";
            helpObject.title = "Invitation";
            helpObject.placeholder = "Invitation";
            helpObject.content = props.selcetedActivity && props.selcetedActivity.attributes.invitation
            helpObjectList.push(helpObject);
        }
        if (true || props.selcetedActivity.attributes.preparation && props.selcetedActivity.attributes.preparation.length > 0) {
            let helpObject2 = {};
            helpObject2.index = 2;
            helpObject2.key = "preparation";
            helpObject2.title = "Preparation";
            helpObject2.placeholder = "Preparation";
            helpObject2.content = props.selcetedActivity && props.selcetedActivity.attributes.preparation
            helpObjectList.push(helpObject2);
        }
        if (true || props.selcetedActivity.attributes.statement && props.selcetedActivity.attributes.statement.length > 0) {
            let helpObject3 = {};
            helpObject3.index = 3;
            helpObject3.key = "statement";
            helpObject3.title = "Statement";
            helpObject3.placeholder = "Statement";
            helpObject3.content = props.selcetedActivity && props.selcetedActivity.attributes.statement
            helpObjectList.push(helpObject3);
        }
        if (true || props.selcetedActivity.attributes.presentation && props.selcetedActivity.attributes.preparation.length > 0) {
            let helpObject4 = {};
            helpObject4.index = 4;
            helpObject4.key = "presentation";
            helpObject4.title = "Presentation";
            helpObject4.placeholder = "Presentation";
            helpObject4.content = props.selcetedActivity && props.selcetedActivity.attributes.presentation
            helpObjectList.push(helpObject4);
        }
        if (true || props.selcetedActivity.attributes.pointOfInterest && props.selcetedActivity.attributes.pointOfInterest.length > 0) {
            let helpObject5 = {};
            helpObject5.index = 5;
            helpObject5.key = "pointOfInterest";
            helpObject5.title = "Point Of Interest";
            helpObject5.placeholder = "Point Of Interest";
            helpObject5.content = props.selcetedActivity && props.selcetedActivity.attributes.pointOfInterest
            helpObjectList.push(helpObject5);
        }
        if (true || props.selcetedActivity.attributes.controlOfError && props.selcetedActivity.attributes.controlOfError.length > 0) {
            let helpObject6 = {};
            helpObject6.index = 6;
            helpObject6.key = "controlOfError";
            helpObject6.title = "Control Of Error";
            helpObject6.placeholder = "Control Of Error";
            helpObject6.content = props.selcetedActivity && props.selcetedActivity.attributes.controlOfError
            helpObjectList.push(helpObject6);
        }
        if (true || props.selcetedActivity.attributes.ageOfPresentation && props.selcetedActivity.attributes.ageOfPresentation.length > 0) {
            let helpObject7 = {};
            helpObject7.index = 7;
            helpObject7.key = "ageOfPresentation";
            helpObject7.title = "Age Of Presentation";
            helpObject7.placeholder = "Age Of Presentation";
            helpObject7.content = props.selcetedActivity && props.selcetedActivity.attributes.ageOfPresentation
            helpObjectList.push(helpObject7);
        }

        if (true || props.selcetedActivity.attributes.description && props.selcetedActivity.attributes.description.length > 0) {
            let helpObject8 = {};
            helpObject8.index = 8;
            helpObject8.key = "description";
            helpObject8.title = "Description";
            helpObject8.placeholder = "Description";
            helpObject8.content = props.selcetedActivity && props.selcetedActivity.attributes.description
            helpObjectList.push(helpObject8);
        }

        setVideoUrl(props.selcetedActivity && props.selcetedActivity.attributes.videoLink)
        setAllContent(helpObjectList)


    }, [props.selcetedActivity]);

    const handelAddContent = () => {

    }
    function handelOnClickBack() {
        window.history.back();
    }

    function handelAddActivity(name, activityDescription, activity) {

        var learningActivity = activity;
        learningActivity.set("name", name);
        learningActivity.set("description", activityDescription);
        if (activityDay) {
            learningActivity.set("day", parseInt(activityDay));
        }
        if (activityMonth) {
            learningActivity.set("month", parseInt(activityMonth));
        }

        props.addLearningActivity(learningActivity);

        setEditActivity(undefined);
        setActivityDay(undefined);
        setActivityMonth(undefined);

    }

    function handelUpdateActivity() {

        var learningActivity = props.selcetedActivity;
        let content = selectedItemContent;
        if (selectedItem.key == 'videoLink' && selectedItemContent) {
            content = selectedItemContent.trim();
        }
        learningActivity.set(selectedItem.key, content);

        let tempList = [...allContent];
        let tempItem = Object.assign({}, selectedItem);
        tempItem.content = content;
        tempList[selectedItem.index] = tempItem;
        setAllContent(tempList);

        props.addLearningActivity(learningActivity);
    }
    useEffect(() => {
        if (!props.loadingState && selectedItemPopup) {
            setSelectedItemPopup(false);
            setSelectedItem(undefined);
            setSelectedItemContent(undefined);
        }

    }, [props.loadingState]);
    const editActivityUI = (activity) => {
        return (<Col className="border-top  " style={{ borderTopWidth: 3 }}>
            <Card className="mt-2">
                <CardBody>

                    <AvForm className="outer-repeater"
                        onValidSubmit={(e, v) => {
                            handelAddActivity(activityName, activityDescription, activity);
                        }}
                    >
                        <div data-repeater-item className="outer">
                            <FormGroup >
                                <Label htmlFor="formname">{props.t('Edit New Topic')}: </Label>
                                <AvField
                                    type="text"
                                    name="name"
                                    placeholder={props.t('Topic name')}
                                    className="form-control"
                                    value={activityName}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: props.t('Topic name can not be empty'),
                                        },
                                    }}
                                    onChange={(e) => {
                                        setActivityName(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            <FormGroup >
                                <Label htmlFor="formname">{props.t('Description')}: </Label>
                                <textarea
                                    type="text"
                                    name="name"
                                    placeholder={props.t('Description')}
                                    className="form-control"
                                    value={activityDescription}

                                    onChange={(e) => {
                                        setActivityDescription(e.target.value);
                                    }}
                                />
                            </FormGroup>
                            {false && <Row>
                                <Col lg={4}>

                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('Month')}: </Label>
                                        <select className="form-control"
                                            onChange={(e) => {
                                                setActivityMonth(e.target.value)
                                            }}
                                            value={activityMonth}
                                        >

                                            {months.map((item, key) => (
                                                <option key={key}>{item}</option>
                                            ))}
                                        </select>

                                    </FormGroup>
                                    <p className="m-2 text-info font-size-8">{props.t('Month-1 is starting month of your academic cycle, it may be April, June or July based on your location/region')}</p>

                                </Col>
                                <Col lg={4}>
                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('Day')}: </Label>
                                        <select className="form-control"
                                            onChange={(e) => {
                                                setActivityDay(e.target.value)
                                            }}
                                            value={activityDay}
                                        >

                                            {days.map((item, key) => (
                                                <option key={key}>{item}</option>
                                            ))}

                                        </select>
                                    </FormGroup>
                                </Col>
                            </Row>}

                            <button type="button"
                                className="btn btn-secondary m-1"
                                onClick={() => {

                                    setActivityName("");
                                    setActivityDescription("")
                                    setEditActivity(undefined);
                                    setActivityDay(undefined);
                                    setActivityMonth(undefined);
                                }}  >
                                {props.t('Close')}
                            </button>
                            {
                                props.loadingState ? <button type="button" className="btn btn-dark waves-effect waves-light float-right m-1">
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                                </button> :
                                    <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light float-right m-1">
                                        <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save Topic')}
                                    </button>
                            }
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>)
    }

    const getYouTubeVideoId = (url) => {
        //return 'ioGQqVvYS9c'
        let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        let m = url.match(regExp);
        if (m && m[7].length == 10) {
            if (url.includes('V')) {
                return 'V' + m[7];
            } else if (url.includes('v')) {
                return 'v' + m[7];
            }
        }
        return m && m[7].length == 11 ? m[7] : false;
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <TopBar title={"TOPIC CONTENT"}
                        buttonName={"Add Content"}
                        backButton={true}
                        hideButton={true}
                        onClickAdd={handelAddContent}
                        onClickBackButton={handelOnClickBack}
                    />


                    <Card>
                        <CardBody>
                            {props.selcetedActivity && props.selcetedActivity.id === parameter ?

                                <div>

                                    {editActivity ?
                                        <div>
                                            {editActivityUI(editActivity)}
                                        </div> :

                                        <Row>
                                            <Col lg={10}>
                                                <CardTitle>{props.t('TOPIC') + ": " + props.selcetedActivity && props.selcetedActivity.attributes.name}</CardTitle>
                                                {props.selcetedActivity.attributes.description && <CardText>{"Description: " + props.selcetedActivity.attributes.description}</CardText>}
                                                <CardSubtitle>{
                                                    props.selcetedActivity.attributes.month && props.selcetedActivity.attributes.day ?
                                                        "Month: " + props.selcetedActivity.attributes.month + ", " + "Day: " + props.selcetedActivity.attributes.day
                                                        : ""}
                                                </CardSubtitle>
                                            </Col>
                                            <Col lg={2}>
                                                {canEdit && <ul className="list-inline font-size-18 contact-links mb-0  float-right">

                                                    <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setEditActivity(props.selcetedActivity);
                                                            setActivityName(props.selcetedActivity.attributes.name);
                                                            setActivityDescription(props.selcetedActivity.attributes.description)
                                                            setActivityDay(props.selcetedActivity.attributes.day ? props.selcetedActivity.attributes.day + "" : "1");
                                                            setActivityMonth(props.selcetedActivity.attributes.month ? props.selcetedActivity.attributes.month + "" : "1");
                                                        }}
                                                        id={"activity-edit"}>
                                                        <i className="bx bx-edit-alt text-dark"></i>
                                                        <UncontrolledTooltip placement="top" target={"activity-edit"}>
                                                            {props.t('Edit')}
                                                        </UncontrolledTooltip>

                                                    </li>
                                                    {/* <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            if (props.files && props.files.length > 0) {
                                                                setDeleteWarning(true);
                                                            } else {
                                                                setDeleteActivity(props.selcetedActivity);
                                                            }
                                                            
                                                        }}
                                                        id={"activity-delete"}>
                                                        <i className="bx bx-trash text-danger"></i>
                                                        <UncontrolledTooltip placement="top" target={"activity-delete"}>
                                                            {props.t('Delete')}
                                                        </UncontrolledTooltip>
                                            
                                                    </li> */}


                                                </ul>}

                                            </Col>
                                        </Row>}



                                </div>
                                :
                                <Col xs="12">
                                    <div className="text-center my-3">
                                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading </Label>
                                    </div>
                                </Col>

                            }

                        </CardBody>
                    </Card>


                    {props.selcetedActivity && props.selcetedActivity.id === parameter &&
                        allContent.map((item) => (<Card key={item.key} >
                            <CardBody>

                                <Row >
                                    <Col lg={11} className="d-flex align-items-start">
                                        <div>
                                            <h5><i className="bx bxs-check-circle float-right  mt-2 ml-2"></i></h5>
                                        </div>

                                        <div>
                                            <h5 className="mt-2"><span>{item.title}</span></h5>
                                            {item.content && item.key != "videoLink" &&
                                                <h6
                                                    className="text-muted ml-6"
                                                    style={{ whiteSpace: "pre-wrap" }}
                                                >
                                                    {item.content}
                                                </h6>
                                            }

                                            {item.content && item.key == "videoLink" &&
                                                // <div className="embed-responsive embed-responsive-16by9">
                                                //     <Iframe
                                                //         title="test"
                                                //         className="embed-responsive-item"
                                                //         src={item.content + "?rel=0"}
                                                //         allowFullScreen>
                                                //     </Iframe>

                                                // </div>

                                                <Row>
                                                    <Col lg={6}>
                                                        <div className="embed-responsive embed-responsive-4by3">
                                                            <iframe title=""
                                                                className="embed-responsive-item"
                                                                height='100'
                                                                src={"https://www.youtube.com/embed/" + getYouTubeVideoId(item.content) + "?rel=0"}
                                                            >

                                                            </iframe>
                                                        </div>
                                                    </Col>
                                                </Row>

                                            }

                                            {!item.content &&
                                                <h6
                                                    style={{ cursor: "pointer" }}
                                                    className="text-muted ml-6"
                                                    onClick={() => {
                                                        setSelectedItemPopup(true)
                                                        setSelectedItem(item)
                                                        setSelectedItemContent(item.content)
                                                    }}
                                                >
                                                    {"Description: Tap to Add"}

                                                </h6>
                                            }


                                        </div>
                                    </Col>
                                    <Col lg={1} className='d-flex align-items-end'>
                                    <div className="float-end">
                                            {/* <h5><i className="bx bx-right-arrow-circle float-right  mt-2 ml-2"></i></h5> */}
                                            <ul className="list-inline font-size-16 contact-links mb-0 mt-2 float-right">

                                                {canEdit && <li className="list-inline-item px-2"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setSelectedItemPopup(true)
                                                        setSelectedItem(item);
                                                        setSelectedItemContent(item.content)
                                                    }}
                                                    id={"activity-edit" + item.key}>
                                                    <i className="bx bx-edit-alt text-dark"></i>
                                                    <UncontrolledTooltip placement="top" target={"activity-edit" + item.key}>
                                                        {props.t('Edit')}
                                                    </UncontrolledTooltip>

                                                </li>}

                                            </ul>
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>))}

                </Container>
            </div>
            <Modal
                isOpen={selectedItemPopup}
                scrollable={true}
                size={(selectedItem && selectedItem.key == 'videoLink') ? "" : "xl"}
            >
                <ModalHeader isOpen={selectedItemPopup}>
                    <h5 className=" mt-0">{selectedItem && selectedItem.title}</h5>
                </ModalHeader>
                <ModalBody className="modal-body">

                    <FormGroup>

                        <Input
                            type="textarea"
                            rows={(selectedItem && selectedItem.key == 'videoLink') ? "1" : "20"}
                            name="content"
                            placeholder={selectedItem && selectedItem.placeholder}
                            value={selectedItemContent}
                            onChange={(e) => {
                                setSelectedItemContent(e.target.value);
                                console.log(e.target.value)
                            }}
                        />
                    </FormGroup>
                </ModalBody>

                <ModalFooter>
                    {
                        !props.loadingState ?
                            <div>
                                <button
                                    type="button"
                                    className="btn btn-secondary m-1"
                                    onClick={() => {
                                        setSelectedItemPopup(false)
                                        setSelectedItem(undefined);
                                        setSelectedItemContent(undefined);
                                    }}
                                >
                                    {props.t("Close")}
                                </button>
                                <button
                                    type="button"
                                    className="btn btn-primary m-1"
                                    onClick={() => {
                                        handelUpdateActivity();
                                    }}
                                >
                                    {props.t("Save")}
                                </button>
                            </div>
                            :
                            <button type="button" className="btn btn-light waves-effect">
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle m-1"></i>{props.t(' Saving')}
                            </button>
                    }
                </ModalFooter>
            </Modal>

            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        if (success_dlg === "activity") {
                            window.history.back();
                        }
                        setsuccess_dlg(false)
                    }}
                >
                    {success_dlg === "activity" ? props.t('Selected chapter has been deleted.') : props.t('Selected content has been deleted.')}
                </SweetAlert>
            ) : null}
            {
                deleteWarning && <SweetAlert
                    info
                    title={props.t('Alert')}
                    onConfirm={() => {
                        setDeleteWarning(false)
                    }}
                >
                    {props.t('Please delete all the content to delete this topic')}
                </SweetAlert>
            }
        </React.Fragment>
    );
}

const mapStatetoProps = state => {

    const { learningActivities, selcetedActivity, loadingState } = state.LearningActivity;
    const { files, loadingFileState } = state.Files;
    const { selectedSchool } = state.School;
    const { curriculums, initialLoding, selectedCurriculum } = state.Curriculum;
    const { userTeacher } = state.Login;
    return { learningActivities, selcetedActivity, files, loadingFileState, loadingState, selectedSchool, selectedCurriculum, userTeacher };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdLearningActivity, getFilesForFolderId, AddFile, deleteFile, setSelecetdSchool, addLearningActivity, deleteLearningActivity, setSelectedCurriculum })(ActivityContentList))); 