import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
    setSelecetdSchool,
    getAllKidsForGroup,
    getGroupMapsForGroupId,

} from '../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import moment from 'moment';
import DocViewer, { DocViewerRenderers, BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Button,
    Modal, ModalHeader, ModalBody, UncontrolledTooltip, ModalFooter,
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

const ProgramReport = (props) => {
    const schoolId = props.schoolId
    const groupId = props.groupId

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if (Parse.User.current() && props.selectedSchool) {
            props.getGroupMapsForGroupId(groupId)
        }
    }, [props.selectedSchool, schoolId]);


    const [showReport, setShowReport] = useState(false);
    const [mainList, setMainList] = useState([]);
    const [selectedEndDate, selectedEndDateOps] = useState();
    const [selectedDate, selectedDateOps] = useState();



    useEffect(() => {
        if (props.groupmaps && props.groupmaps.length > 0 && props.kids && props.admissionKids) {

            let tempList = []
            let tempSelectedKidList = []

            let tempKidList = []
            tempKidList.push.apply(tempKidList, props.kids)
            tempKidList.push.apply(tempKidList, props.admissionKids)
            for (var i = 0; i < props.groupmaps.length; i++) {
                if (props.groupmaps[i].attributes.groupId === groupId) {
                    let kidObjList = tempKidList.filter((kid) =>
                        kid.id === props.groupmaps[i].attributes.userId
                    )
                    if (kidObjList.length > 0) {
                        let tempObject = {}

                        tempObject.kidParseObject = kidObjList[0]
                        tempObject.groupmapId = props.groupmaps[i].id
                        tempList.push(tempObject)
                    }
                }
            }
            setMainList(tempList)
            props.getInvoicesForProgramId(programId, false);
        }
    }, [props.groupmaps, props.kids, props.admissionKids])


    // const generateReport = () => {
    //     let filter = {};
    //     filter.schoolId = props.schoolId;
    //     filter.startDate = new Date(
    //         selectedDate.getFullYear(),
    //         selectedDate.getMonth(),
    //         selectedDate.getDate()
    //     );
    //     filter.endDate = new Date(
    //         selectedEndDate.getFullYear(),
    //         selectedEndDate.getMonth(),
    //         selectedEndDate.getDate(),
    //         23,
    //         59,
    //         59,
    //         999
    //     );
    //     getExpenseReports(filter);
    // };



    const getDateInDDMMYYYY = (date) => {
        return moment(date).format("DD/MM/YYYY");
    };


    function convertDateToString(date) {
        return moment(date).format('DD MMM YYYY')
    }


    return (
        <React.Fragment>
            <CardTitle>Program Report</CardTitle>
            <CardSubtitle className="mb-3">
                Select filter to generate the report
            </CardSubtitle>
            <Row className="bg-light">
                {<Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>From Date :</Label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => {
                                date ? selectedDateOps(date) : selectedDateOps(new Date());
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            // startDate={fromDate}
                            // endDate={toDate}
                            maxDate={selectedEndDate}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>}
                {<Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>To Date :</Label>
                        <DatePicker
                            selected={selectedEndDate}
                            onChange={(date) => {
                                date
                                    ? selectedEndDateOps(date)
                                    : selectedEndDateOps(new Date());
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            // startDate={fromDate}
                            // endDate={toDate}
                            minDate={selectedDate}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>}
                {<Col md="3" xs="12" xl="3" lg="3">
                    <div className="mt-2">
                        <div className="mt-3">
                            <button
                                className="btn btn-primary mt-4 mr-2"
                                onClick={() => {
                                    setShowReport(true)
                                }}
                            >
                                Generate
                            </button>
                        </div>
                    </div>
                </Col>}
            </Row>
            <Row>
                {showReport && mainList.length > 0 && (
                    <Col md="12" xs="12" xl="12" lg="12" className="mt-1 mb-1">
                        <button
                            className="btn btn-info float-right"
                            onClick={() => { }}
                        >
                            <i className="bx bxs-download mr-1" />
                            Download
                        </button>
                    </Col>
                )}
            </Row>
            {showReport && mainList.length > 0 && (
                <Row>

                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th>Si no.</Th>
                                        <Th data-priority="1">Student Name</Th>
                                        <Th data-priority="1">Total Amount</Th>
                                        <Th data-priority="2">Collected Amount</Th>
                                        <Th data-priority="2">Pending Amount</Th>


                                    </Tr>
                                </Thead>
                                <Tbody>

                                    {
                                        mainList.map((item, key) => (
                                            <Tr key={key}>
                                                <Th>{key + 1}</Th>
                                                <Td>
                                                    <div><Label>{`${item.paymentType}`}</Label></div>

                                                </Td>
                                                <Td>
                                                    <div><Label>{`${convertDateToString(item.paymentDate ?? item.createdAt)}`}</Label></div>

                                                </Td>
                                                <Td>
                                                    {item.referenceNumber && <div><Label>{`${item.referenceNumber}`}</Label></div>}
                                                </Td>
                                                <Td>
                                                    <Label className={"text-dark"}>{item.isVerified ? "Verified" : "Not Verified"}</Label>

                                                </Td>
                                                <Td style={{ textAlign: "center" }}>
                                                    <div><Label style={{ fontFamily: "Arial, sans-serif" }}></Label><Label>{`${item.amount}`}</Label></div>

                                                </Td>
                                                <Td>{item.tax ? <div>{`${item.tax}%`}</div> : ""}
                                                </Td>
                                                <Td>{item.tds ? <div>{`${item.tds}%`}</div> : ""}
                                                </Td>


                                                <Td>
                                                    <div><Label>{item.categoryName ?? "-"}</Label></div>
                                                </Td>
                                                <Td>
                                                    <div><Label>{item.supplierName ?? "-"}</Label></div>

                                                </Td>
                                                {/* <Td>
                                                    {item.albumId ? <Label className="text-primary justify-content-center text-truncate" style={{ cursor: "pointer" }} onClick={() => {
                                                        setShowImagesViewModal(true)
                                                        getAlbumsForAlbumId(item.albumId)
                                                    }}>
                                                        <u>{"View Attachment"}</u>
                                                    </Label> :
                                                        (item.attachmentDoc ? <Label className="text-primary justify-content-center text-truncate" style={{ cursor: "pointer" }} onClick={() => {
                                                            setShowFileViewModal(true)
                                                            setClickedFile(item.attachmentDoc)
                                                        }}>
                                                            <u>{"View Attachment"}</u>
                                                        </Label> : "")
                                                    }
                                                </Td> */}

                                                <Td>
                                                    <div><Label >{item.note}</Label></div>
                                                </Td>


                                            </Tr>
                                        ))
                                    }

                                </Tbody>
                            </Table>
                        </div>
                    </div>
                </Row>
            )}

            {showReport && mainList.length === 0 && (
                <Col>
                    <h3 className="text-center text-info mt-3">
                        {props.t("No Report Found For The Filter")}
                    </h3>
                </Col>
            )}



        </React.Fragment>
    )



}
const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { kids, admissionKids, admissionKidsLoasState, } = state.Kid;

    return {
        schools, selectedSchool,
        kids, admissionKids, admissionKidsLoasState,

    };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            getAllKidsForGroup,
            getGroupMapsForGroupId
        })(ProgramReport)
    )
);