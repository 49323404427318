import React, {useState} from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';
import { withNamespaces } from 'react-i18next';
import { Button, Card, CardBody, Table, Nav, NavItem, NavLink,} from 'reactstrap';
import { Link } from "react-router-dom";
import classnames from 'classnames';

const ListView = props => {

    const [customActiveTab, setCustomActiveTab] = useState("1");

    function toggleCustom(tab) {
        if (customActiveTab !== tab) {
            setCustomActiveTab(tab);
        }
    }

    const filteredClassList = () => {
        if (customActiveTab === "1") {
            return props.classList;
        } else if (customActiveTab === "2") {
            return props.classList.filter(val => val.classType === 2);
        } else if (customActiveTab === "3") {
            return props.classList.filter(val => val.classType !== 2);
        }
        return [];
    };
    
    return (
        <React.Fragment>
            <Card>
                <CardBody>
                    <Nav pills className="navtab-bg nav-justified mb-2">
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "1",
                                })}
                                onClick={() => {
                                    toggleCustom("1");
                                }}
                            >
                                {props.t('All').toUpperCase()}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "2",
                                })}
                                onClick={() => {
                                    toggleCustom("2");
                                }}
                            >
                                {props.t('DayCare').toUpperCase()}
                            </NavLink>
                        </NavItem>
                        <NavItem>
                            <NavLink
                                style={{ cursor: "pointer" }}
                                className={classnames({
                                    active: customActiveTab === "3",
                                })}
                                onClick={() => {
                                    toggleCustom("3");
                                }}
                            >
                                {props.t('Classroom').toUpperCase()}
                            </NavLink>
                        </NavItem>
                    </Nav>
                    <div className="table-responsive">
                        <Table className="table-centered table-nowrap table-hover" style={{marginTop:'20px'}}>
                            <thead className="thead-light">
                                <tr>
                                    <th style={{ width: "50px", textAlign: "center" }}>#</th>
                                    <th scope="col">{props.t('Name')}</th>
                                    <th scope="col"><div style={{ textAlign: "center" }}>{props.t('Academic Cycle')}</div></th>
                                    <th scope="col"><div style={{ textAlign: "center" }}>{props.t('Number of Students')}</div></th>
                                    <th scope="col"><div style={{ textAlign: "center" }}>{props.t('Action')}</div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {filteredClassList().map((val, key) => (
                                    <tr key={key}>
                                        <td>
                                            <div className="avatar-xs">
                                                <span className={`avatar-title rounded-circle ${val.classType == 2 ? 'bg-lilpink' : 'bg-primary'}`}>
                                                    {val.classType == 2 ? 'D' : 'C'}
                                                </span>
                                            </div>
                                        </td>
                                        <td className={val.classCss}>
                                            <Link to={`/classroom/${val.schoolId}/${val.id}`}>
                                                {val.name}
                                            </Link>
                                        </td>
                                        <td><div style={{ textAlign: "center" }}>{val.classLevel}</div></td>
                                        <td><div style={{ textAlign: "center" }}>{val.kidCount}</div></td>
                                        <td><div style={{ textAlign: "center" }}>
                                            <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light m-1"
                                                onClick={()=>{
                                                    props.history.push("/classroom/" + val.schoolId + "/" + val.id);
                                                }}
                                                >
                                                {props.t('View Details')}
                                            </Button>
                                            <Button type="button" color="primary" size="sm" className="btn-rounded waves-effect waves-light m-1"
                                                onClick={()=>{
                                                    let t= val.classType??0
                                                    props.history.push("/attendence/"+t+"/" + val.schoolId + "/" + val.id);
                                                }}
                                                >
                                                {props.t('Attendence')}
                                            </Button>
                                        </div></td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table>
                    </div>
                </CardBody>
            </Card>
        </React.Fragment>
    )
}
const mapStatetoProps = state => {
    return{}
}

export default connect(mapStatetoProps, {})(withRouter(withNamespaces()(ListView)));
