import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { Card, CardBody, Col, Container, Row } from "reactstrap";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import {
  getFranchiseDetails,
  getAllSchoolDetailsForFranchise,
  getInstituteDeatilsForId,
  getAllSchoolDetailsForInstitute,
} from "../../store/actions";
import error from "../../assets/images/error-img.png";
import * as Constents from '../../Constents'

const FranchiseEnquiry = (props) => {
  const [franchiseName, franchiseNameOps] = useState("");
  const [franchiseLogoUrl, franchiseLogoUrlOps] = useState("");
  const [allSchoolList, allSchoolListOps] = useState([]);
  const [allStateList, allStateListOps] = useState([]);
  const [allCityList, allCityListOps] = useState([]);
  const [filterAllSchoolList, filterAllSchoolListOps] = useState([]);
  const [selectedState, selectedStateOps] = useState(undefined);
  const [selectedCity, selectedCityOps] = useState(undefined);
  const [selectedSchoolId, selectedSchoolIdOps] = useState(undefined);
  const [showInvalidUrl, showInvalidUrlOps] = useState(false);
  const [loadSuccess, loadSuccessOps] = useState(false);
  const [redirectTo, redirectToOps] = useState("");
  const [id, idOps] = useState("");
  
  const franchiseEnq = matchPath(props.history.location.pathname, {
    path: "/franchise/enquiry/:id",
    exact: true,
    strict: false,
  });
  const instituteEnq = matchPath(props.history.location.pathname, {
    path: "/institute/enquiry/:id",
    exact: true,
    strict: false,
  });
  let franchiseId = franchiseEnq && franchiseEnq.params.id;
  let instituteId = instituteEnq && instituteEnq.params.id;
  useEffect(() => {
    if (franchiseId) {
      redirectToOps("franchise-enquiry-form");
      idOps(franchiseId);
      props.getFranchiseDetails(franchiseId);
      props.getAllSchoolDetailsForFranchise(franchiseId);
    } else if (instituteId) {
      redirectToOps("institute-enquiry-form");
      idOps(instituteId);
      props.getInstituteDeatilsForId(instituteId);
      props.getAllSchoolDetailsForInstitute(instituteId);
    }
  }, [franchiseId, props.loadSuccess, instituteId]);
  useEffect(() => {
    if (props.franchise) {
      loadSuccessOps(true);
      franchiseNameOps(props.franchise.attributes.name);
      if (props.franchise.attributes.logo) {
        franchiseLogoUrlOps(props.franchise.attributes.logo._url);
      }
    } else if (props.institute) {
      loadSuccessOps(true);
      franchiseNameOps(props.institute.attributes.name);
      if (props.institute.attributes.logo) {
        franchiseLogoUrlOps(props.institute.attributes.logo._url);
      }
    } else {
      if (props.loadSuccess) {
        showInvalidUrlOps(true);
      }
    }
  }, [props.franchise, props.loadSuccess, props.institute]);
  useEffect(() => {
    if (props.schools.length > 0) {
      allSchoolListOps(props.schools);
      let stateList = [];
      for (const val of props.schools) {
        if (val.attributes.areaCode) {
          if (!stateList.includes(val.attributes.areaCode.trim())) {
            stateList.push(val.attributes.areaCode.trim());
          }
        }
      }
      allStateListOps(stateList);
      if (stateList.length === 1) {
        selectedStateOps(stateList[0]);
      } else {
        selectedStateOps(undefined);
      }
      
    }
  }, [props.schools]);
  useEffect(() => {
    if (selectedState) {
      let cityList = [];
      for (const val of allSchoolList) {
        if (
          val.attributes.areaCode === selectedState &&
          val.attributes.city &&
          !cityList.includes(val.attributes.city.trim())
        ) {
          cityList.push(val.attributes.city.trim());
        }
        if (val.attributes.city == undefined && !cityList.includes("others")) {
          cityList.push("others")
        }
      }
      allCityListOps(cityList);
      if(cityList.length===1){
        selectedCityOps(cityList[0]);
      }else {
        selectedCityOps(undefined);
      }
    }
  }, [selectedState]);
  useEffect(() => {
    if (selectedCity) {
      let orderSchoolList = [];
      for (const val of allSchoolList) {

        if (
          val.attributes.areaCode === selectedState && ((
            val.attributes.city && val.attributes.city.trim() === selectedCity)
            || (!val.attributes.city && selectedCity === "others"))

        ) {
          let temp = {};
          temp.id = val.id;
          temp.name = val.attributes.Name;
          orderSchoolList.push(temp);
        }
      }
      filterAllSchoolListOps(orderSchoolList);
      if (orderSchoolList.length === 1) {
        selectedSchoolIdOps(orderSchoolList[0].id);
      }else {
        selectedSchoolIdOps(undefined);
      }
    }
  }, [selectedCity]);
  return (
    <React.Fragment>
      <Container fluid={true} className="page-content">
        <Row>
          {showInvalidUrl && (
            <Container>
              <Row>
                <Col lg="12">
                  <div className="text-center mb-5">
                    <h1 className="display-2 font-weight-medium">
                      4
                      <i className="bx bx-buoy bx-spin text-primary display-3"></i>
                      4
                    </h1>
                    <h4 className="text-uppercase">Sorry, page not found</h4>
                    {/* <div className="mt-5 text-center">
                      <Link
                        className="btn btn-primary waves-effect waves-light"
                        to="/dashboard"
                      >
                        Back to Dashboard
                      </Link>
                    </div> */}
                  </div>
                </Col>
              </Row>
              <Row className="justify-content-center">
                <Col md="8" xl="6">
                  <div>
                    <img src={error} alt="" className="img-fluid" />
                  </div>
                </Col>
              </Row>
            </Container>
          )}
          {!showInvalidUrl && loadSuccess && (
            <Col className="mx-auto" lg={8}>
              <div className="text-center mt-3">
                {franchiseLogoUrl && (
                  <img
                    src={franchiseLogoUrl}
                    width="100"
                    alt=""
                    className="img-thumbnail rounded-circle"
                  />
                )}
                <h3>Welcome to {franchiseName}</h3>
                <p className="mt-2">Select Your Nearest Center</p>
              </div>
              <Card>
                <CardBody>
                  <AvForm
                    className="outer-repeater"
                    style={{ border: "1px" }}
                  // onSubmit={isFormValid}
                  >
                    <div
                      data-repeater-list="outer-group"
                      className="outer mt-2"
                    >
                      <div data-repeater-item className="outer">
                        <p className="mt-2 mb-0">State</p>
                        <select
                          className="form-control"
                          value={selectedState}
                          onChange={(e) => {
                            selectedStateOps(e.target.value);
                          }}
                        >
                           <option disabled selected value>-- Pick State --</option>
                          {allStateList.map((val, key) => (
                            <option key={key} value={val}>
                              {Constents.stateNameCode[val]}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div data-repeater-item className="outer mt-2">
                        <p className="mt-2 mb-0">City</p>
                        <select
                          className="form-control"
                          value={selectedCity}
                          onChange={(e) => {
                            selectedCityOps(e.target.value);
                          }}
                        >
                          <option disabled selected value>-- Pick City --</option>
                          {allCityList.map((val, key) => (
                            <option value={val} key={key}>
                              {val}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div data-repeater-item className="outer mt-2">
                        <p className="mt-2 mb-0">Branches</p>
                        <select
                          className="form-control"
                          value={selectedSchoolId}
                          onChange={(e) => {
                            selectedSchoolIdOps(e.target.value);
                          }}
                        >
                          <option disabled selected value >-- Pick Branch-- </option>
                          {filterAllSchoolList.map((val, key) => (
                            <option value={val.id} key={key}>
                              {val.name}
                            </option>
                          ))}
                        </select>
                      </div>
                      <div
                        data-repeater-item
                        className="outer mt-2 text-center"
                      >
                        <Link
                          to={`/${redirectTo}/${selectedSchoolId}/${id}`}
                          className="btn btn-primary"
                          style={{ width: "40%" }}
                        >
                          {props.t("Next")}
                        </Link>
                      </div>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          )}
        </Row>
      </Container>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { franchise, schools, loadSuccess, institute } = state.School;
  return { franchise, schools, loadSuccess, institute };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getFranchiseDetails,
      getAllSchoolDetailsForFranchise,
      getInstituteDeatilsForId,
      getAllSchoolDetailsForInstitute,
    })(FranchiseEnquiry)
  )
);
