import React, { useEffect, useState } from 'react';
import {
    Container, Card, CardBody, Row, Col, Table,Media,
    UncontrolledTooltip, Label, Button, Modal, FormGroup, ModalHeader, ModalBody, ModalFooter, Input
} from "reactstrap";
import Image from "../../assets/images/book_lover.svg";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import Moment from 'moment';
import {
    setSelecetdSchool,
    setSelectedCourse,
    getLearningCategoryForCourseId,
    getQuizLearningActivitiesForCourseId,
    getAKid,
    getExamCourseSessionsForKidId,
    getExamsForSchoolIds,
    getCoureseSubscriptionForId,
    updadteCoureseSubscriptionForId,
    
} from "../../store/actions";
import toastr from "toastr"
import TopBar from './TopBar';
import Knob from '../AllCharts/knob/knob';
import Parse from 'parse';
import Switch from "react-switch";



const CourseAssessments = (props) => {

    const match1 = props.history && matchPath(props.history.location.pathname, {
        path: '/course-assessments/:schoolId/:courseId/:kidId',
        exact: true,
        strict: false,
    });

    const match2 = props.history && matchPath(props.history.location.pathname, {
        path: '/course-assessments/:schoolId/:courseId/:subId/:batchId/:kidId',
        exact: true,
        strict: false,
    });

    const match = match1 ?? match2;
    const schoolId = match ? match.params.schoolId : props.schoolId;
    const courseId = match ? match.params.courseId : props.courseId;
    const batchId = match ? match.params.batchId : props.batchId;
    const kidId = match ? match.params.kidId : props.kidId;
    const subscriptionId=match ? match.params.subId : props.subId;

    
    const [allActivities, setAllActivities] = useState([]);
    const [totalSessions, setTotalSessions] = useState(0);
    const [resutsPercent, setResutsPercent] = useState(0);
    const [certificateStatus, setCertificateStatus] = useState(true)
    

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.setSelectedCourse(courseId);
        props.getLearningCategoryForCourseId(courseId);
        props.getQuizLearningActivitiesForCourseId(courseId);
        props.getAKid(kidId)
        props.getExamCourseSessionsForKidId(courseId, kidId,batchId)
        fetchExams(schoolId);
    }, [courseId]);
    useEffect(() => {
        props.getCoureseSubscriptionForId(subscriptionId);
    }, [subscriptionId]);

    useEffect(() => {
        if (props.courseSubscriptionsForId && props.courseSubscriptionsForId.attributes.certificateStatus == false) {
            setCertificateStatus(false)
        }
    }, [props.courseSubscriptionsForId]);

    const fetchExams = (schoolId) => {
        let ids = [];

        if (props.selectedSchool) {
            //ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }
        }
        ids.push(schoolId);

        if (ids.length > 0) {
            props.getExamsForSchoolIds(ids);
        }
    };

    useEffect(() => {
        if (props.quizLearningActivities && props.quizLearningActivities.length > 0 &&
            props.learningCategories && props.learningCategories.length > 0) {

            let list = [];
            let totalSessions=0;
            for (const cat of props.learningCategories) {
                for (const act of props.quizLearningActivities) {
                    if (act.attributes.category === cat.id) {
                        let session = undefined;
                        for (const ses of props.courseQuizSessions) {
                            if (act.attributes.quizId === ses.attributes.quizId &&
                                act.id === ses.attributes.activityId) {
                                session = ses;
                                totalSessions += 1;
                            }
                        }
                        // console.log({id:act.id, category: cat, activity: act,quizSession:session })
                        list.push({ id: act.id, category: cat, activity: act, quizSession: session });
                        
                    }
                }
            }
            setTotalSessions(totalSessions)
            setAllActivities(list);
        } else {
            setAllActivities([])
        }
       
    }, [props.quizLearningActivities, props.learningCategories, props.courseQuizSessions]);

    useEffect(() => {
        if (allActivities && allActivities.length > 0 && props.exams && props.exams.length > 0) {
            let passCount = 0;
            for (const all of allActivities) {
                if (all.quizSession) {
                    let temp = props.exams.filter((e) => e.id === all.quizSession.attributes.quizId);
                    if (temp.length > 0) {
                        let totalMarks = temp[0].attributes.totalMarks;
                        let passPercentage = temp[0].attributes.passPercentage;

                        let review = true;
                        if(all.quizSession.attributes.reviewStatus==false){
                            review = false;
                        }
                        if (all.quizSession.attributes.totalMarksObtained>0 && review) {
                            let scoredPercentage = (all.quizSession.attributes.totalMarksObtained * 100) / totalMarks;
                            if (scoredPercentage >= passPercentage) {
                                passCount += 1;
                            }
                        }
                    }
                }
            }
            if (passCount > 0) {
                let p=(passCount * 100 / allActivities.length).toFixed(1)
                setResutsPercent(parseFloat(p));
            }
        }
       
    }, [props.exams,allActivities]);

   
    const handelOnClickBack = () => {
        window.history.back();
    }



    const getTimeStringForDate = (date) => {
        return Moment(date).format('DD-MMM-YYYY, hh:mm a');
    }

    const getMarks = (session) => {
        if (session) {
        
            if (props.exams && props.exams.length > 0) {
                let temp = props.exams.filter(
                    (ex) => ex.id === session.attributes.quizId
                );
                if (temp.length > 0) {
                    let totalMarks = temp[0].attributes.totalMarks;
                    let passPercentage = temp[0].attributes.passPercentage;
                    let scored = session.attributes.totalMarksObtained + "/" + totalMarks;
                    let scoredPercentage = (session.attributes.totalMarksObtained * 100) / totalMarks;
                    let isPass = false
                    if (scoredPercentage >= passPercentage) {
                        isPass = true;
                    }
                    let review = true;
                    if(session.attributes.reviewStatus==false){
                        review = false;
                    }
                
                    return (
                        <div>
                            <p className='mb-0'>Total Marks: <span>{totalMarks}</span></p>
                            <p className='mb-0'>Pass Percentage: <span>{passPercentage + " %"}</span></p>
                            <p className='mb-0'>Score: <span>{scored}</span>{ " ("+scoredPercentage.toFixed(2)+"%)"}</p>
                            {
                                (!review)? <p >Results :<span className='text-info'>Under review</span></p> : isPass ? <p >Results :<span className='text-success'>Pass</span></p> : <p> Results :<span className='text-danger'>Fail</span></p>
                            }
                        </div>
                    )
                }
            }
        } else {
            return <p >Not attempted</p>
        }
        return "";
    }

 
    const getProgress = () => {
        if (totalSessions > 0 && allActivities.length > 0) {
            let p=(totalSessions * 100 / allActivities.length).toFixed(1)
            return parseFloat(p);
        }
        return 0;
    }

    const getContentProgress = () => {
        if (props.courseSubscriptionsForId) {
            if (props.courseSubscriptionsForId.attributes.progress>0) {
                return props.courseSubscriptionsForId.attributes.progress+" % Completed"
            }
        }
        return "--"
    }

    const updateCertificateStatus = () => {
        let object = props.courseSubscriptionsForId;
        object.set("certificateStatus", !certificateStatus);
        setCertificateStatus(!certificateStatus)
        props.updadteCoureseSubscriptionForId(object)
        if (object) {
            object.save().then(
                (result) => {
                    toastr.success("Certificate status updated successfully !!");
                    props.updadteCoureseSubscriptionForId(result)
                },
                (error) => {
                    setCertificateStatus(!certificateStatus)
                    toastr.warning("Please try again !!");
                }
            );
        }
    }

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            > No
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            > Yes
            </div>
        );
    };

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>


                    {match &&<TopBar
                        title={"Assessments"}
                        backButton={true}
                        hideButton={true}
                        onClickBackButton={handelOnClickBack}
                    />}

                    <Card>
                        <Row>

                            <Col lg={6}>
                        
                                <CardBody>
                                    <h5 className="text-truncate font-size-14  mb-1 text-dark">{props.selectedKid ? "Participant Name: " + props.selectedKid.attributes.Name : "Student Name: "}</h5>
                                    <h5 className="text-truncate font-size-14  mb-1 text-dark">{props.selectedCourse ? "Course Name: " + props.selectedCourse.attributes.name : "Course Name: "}</h5>
                                    <h5 className="text-truncate font-size-14  mb-1 text-dark">{allActivities ? "Total Assessments: " + allActivities.length : "Total Assessments: "}</h5>
                                    <h5 className="text-truncate font-size-14  mb-1 text-dark">{"Attempted Assessments: " + totalSessions}</h5>
                                    <h5 className="text-truncate font-size-14  mb-1 text-dark">{"Participant Content Progress: " + getContentProgress()}</h5>
                                    
                                   {props.selectedCourse && props.selectedCourse.attributes.certificateIncluded ==1? <div>
                                        

                                        <label className="card-radio-label mb-2">

                                            <div className="card-radio">
                                                <div>
                                                    <h5 className="text-truncate font-size-14  mb-1 text-mute">{"Certificate Status: "}</h5>

                                                    <Switch
                                                        uncheckedIcon={<Offsymbol />}
                                                        checkedIcon={<OnSymbol />}
                                                        onColor="#626ed4"
                                                        onChange={() => { updateCertificateStatus() }}
                                                        checked={certificateStatus}
                                                    
                                                    />
                                                    
                                                </div>
                                            </div>
                                        </label>

                                    </div>:<p className='text-info' > Certificate not included </p>}
                                    
                                </CardBody>
                            </Col>
                            <Col lg="3">
                                <div className="text-center" >
                                    <h5 className="font-size-14 mt-2 ">Attempted</h5>
                                    <Knob
                                        value={getProgress()}
                                        width={80}
                                        height={100}
                                        thickness={0.2}
                                        fgColor="#4ac18e"
                                        cursor={false}
                                        displayInput={getProgress()+"%"}
                                        displayCustom={() => { return false }}
                                                    
                                    />
                                </div>
                            </Col>
                            <Col lg="3">
                                <div className="text-center" >
                                    <h5 className="font-size-14 mt-2 ">Results</h5>
                                    <Knob
                                        value={resutsPercent}
                                        width={80}
                                        height={100}
                                        thickness={0.2}
                                        fgColor="#4ac18e"
                                        cursor={false}
                                        displayInput={resutsPercent+"%"}
                                        displayCustom={() => { return false }}
                                                    
                                    />
                                </div>
                            </Col>
                        </Row>
                    </Card>

                    <Row >
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    {allActivities.length > 0 && <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="col-1" scope="col" align="center">#</th>
                                                <th className="col-6" scope="col">Name</th>
                                                <th className="col-3" scope="col">Results</th>
                                                <th className="col-2" scope="col" align="right"></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allActivities.map((item, key) =>
                                                <tr key={item.id} >
                                                    <td>
                                                        <h5>{(key + 1)}</h5>
                                                    </td>

                                                    <td
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {

                                                        }}>
                                                        <h5 className="text-truncate font-size-14  mb-1 text-dark">{item.activity.attributes.name}</h5>
                                                        <p className="text-muted mb-0">{"Chapter: " + item.category.attributes.name}</p>
                                                        <p className="text-success">{item.quizSession ? "Status: Completed on: " + getTimeStringForDate(item.quizSession.attributes.createdAt) : " Status: Not attempted"}</p>
                                                    </td>

                                                    <td>
                                                        {getMarks(item.quizSession)}
                                                    </td>

                                                    <td>
                                                        {item.quizSession && <button
                                                            className='btn btn-sm btn-primary'
                                                            onClick={() => {
                                                                props.history.push("/printexamview/" + schoolId + "/" + item.quizSession.attributes.quizId + "/" + item.quizSession.id);
                                                                //props.history.push("/course-quiz-view/"+schoolId+"/"+item.quizSession.attributes.quizId+"/"+courseId+"/"+item.activity.id+"/"+kidId);
                                                            }}
                                                        >
                                                            View
                                                        </button>}
                                                        
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>}

                                    {
                                        allActivities.length === 0 &&
                                        <Card>
                                            <CardBody className="text-center" >

                                                <h3 className="mt-5">{

                                                    props.selectedCourse ? props.t('There are no assessments for ') + props.selectedCourse.attributes.name : props.t('There are no assessments')

                                                }</h3>
                                              
                                            </CardBody>
                                        </Card>
                                    }

                                    {
                                        props.initialLoding && <Col xs="12">
                                            <div className="text-center my-3">
                                                <Label className="text-success">
                                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                                                </Label>
                                            </div>

                                        </Col>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

           


        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { courses, selectedCourse } = state.Course;
    const { kids,selectedKid } = state.Kid;
    const { learningCategories, categoryLoadingState } = state.LearningCategory;
    const { quizLearningActivities } = state.LearningActivity;
    const { courseQuizSessions } = state.QuizSession;
    const { exams } = state.Exam;
    const { courseSubscriptionsForId } = state.CourseSubscription;
    return {  selectedSchool, courses, selectedCourse,quizLearningActivities ,learningCategories, categoryLoadingState,selectedKid,courseQuizSessions,exams,courseSubscriptionsForId};
};


export default connect(mapStatetoProps, {
    setSelecetdSchool,
    setSelectedCourse,
    getLearningCategoryForCourseId,
    getQuizLearningActivitiesForCourseId,
    getAKid,
    getExamCourseSessionsForKidId,
    getExamsForSchoolIds,
    getCoureseSubscriptionForId,
    updadteCoureseSubscriptionForId,
})(withNamespaces()(CourseAssessments));
