import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  Input,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import toastr from "toastr";
import TopBar from "./Topbar";
import Moment from "moment";

import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import avatar2 from "../../assets/images/users/user.png";

import {
  setSelecetdSchool,
  getTeachersForSchool,
  getAllKidsForGroup,
  getClassroomsForSchoolId,
} from "../../store/actions";

const ExamView = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/examview/:schoolId/:examId",
    exact: true,
    strict: false,
  });
  var schoolId = undefined;
  var examId = undefined;

  const kid = 1;
  const teacher = 2;

  if (match) {
    schoolId = match.params.schoolId;
    examId = match.params.examId;
  }
  const [schoolType, setSchoolType] = useState(1);
  const [selectedUserId, setSelectedUserId] = useState("");
  const [selectedUserName, setSelectedUserName] = useState("");
  const [examName, setExamName] = useState("");
  const [examObject, setExamObject] = useState(undefined)

  const [selectedSessionObject, setSelectedSessionObject] = useState(undefined);
  const [selectedUserType, setSelectedUserType] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [displayList, setDisplayList] = useState([]);
  const [showMappingModal, setShowMappingModal] = useState(false);
  const [classroomList, setClassroomList] = useState([]);
  const [selectedClassroomFilter, setSelectedClassroomFilter] =
    useState(undefined);
  const [quizVisibility, setQuizVisibility] = useState(undefined);
  const [activeTab, setactiveTab] = useState("1");

  const [searchBarString, setSearchBarString] = useState("");
  const [isMappingInProgress, setIsMappingInProgress] = useState(false);
  const handleSearchBarTextChange = (value) => {
    setSearchBarString(value);
  };

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  }

  const fetchExamFromServer = () => {
    var query = new Parse.Query("Quiz");
    query.equalTo("objectId", examId);

    query.limit(1000);
    query.first().then((result) => {
      if (result) {
        setExamObject(result)
        if (result.attributes.name) {
          setExamName(result.attributes.name);
        }
        if (result.attributes.quizVisibility) {
          setQuizVisibility(result.attributes.quizVisibility);
        }
      }
    });
  };

  useEffect(() => {
    if (props.classrooms && props.classrooms.length > 0) {
      var tempArray = [...props.classrooms];
      const ClassroomObject = Parse.Object.extend("ClassRoom");
      var tempClassroomObject = new ClassroomObject();
      tempClassroomObject.id="allClassrooms";
      tempClassroomObject.set("Name", "All Classrooms");
      tempArray.unshift(tempClassroomObject);
      setClassroomList(tempArray);
      setSelectedClassroomFilter(tempClassroomObject);
    }
  }, [props.classrooms]);

  const getAllKidsFilteredList = () => {
    var listToReturn = [];
    if (selectedClassroomFilter != undefined) {
      if (selectedClassroomFilter.id === "allClassrooms") {
        listToReturn = [...props.kids];
      } else {
        var i = 0;
        for (i = 0; i < props.kids.length; i++) {
          if (
            props.kids[i].attributes.ClassRoomID === selectedClassroomFilter.id
          ) {
            listToReturn.push(props.kids[i]);
          }
        }
      }
    }

    if (searchBarString === "") {
    } else {
      listToReturn = listToReturn.filter((object) =>
        object.attributes.Name.toLowerCase().includes(
          searchBarString.toLowerCase()
        )
      );
    }

    return listToReturn;
  };
  const handleCheckboxChange = (tempObject, userType) => {
    setSelectedUserType(userType);
    setSelectedUserId(tempObject.id);
    setSelectedUserName(tempObject.attributes.Name);
  };

  const getKidPhoto = (kObject) => {
    var kidPhoto = "";

    kidPhoto =
      kObject.attributes.Photo != undefined
        ? kObject.attributes.Photo._url
        : avatar2;

    return kidPhoto;
  };

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }else{
      setSchoolType(props.selectedSchool.attributes.schoolType)
    }
    props.getTeachersForSchool(schoolId);
    props.getAllKidsForGroup(schoolId);
    props.getClassroomsForSchoolId(schoolId);
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    fetchExamFromServer();
  }, []);

  useEffect(() => {

    var query = new Parse.Query("QuizSession");

    query.equalTo("quizId", examId);

    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.descending("createdAt");
    query.find().then((results) => {
      setIsLoading(false);
      var tempList = [];

      if (results != null) {
        if (results.length > 0) {
          let tempList = [];
          for (var i = 0; i < results.length; i++) {
            let tempObj = {
              userName: undefined,
              applicantName: results[i].attributes.fillingFor,
              class: results[i].attributes.class,
              school: results[i].attributes.schoolName,
              sessionParseObject: results[i],
              userId: results[i].attributes.userId,
              userType: results[i].attributes.userType,
            };

            let tempUserName = undefined;
            let tempClassroomName = undefined;
            if (
              results[i].attributes.userId != undefined &&
              results[i].attributes.userId.length > 0
            ) {
              if (results[i].attributes.userType === teacher) {
                if (props.teachers && props.teachers.length > 0) {
                  let tempUser = props.teachers.filter((teacher) => {
                    return teacher.id === results[i].attributes.userId;
                  });
                  if (tempUser && tempUser.length > 0) {
                    tempUserName = tempUser[0].attributes.Name;
                  }
                }
              } else if (results[i].attributes.userType === kid) {
                if (props.kids && props.kids.length > 0) {
                  let tempUser = props.kids.filter((kid) => {
                    return kid.id === results[i].attributes.userId;
                  });
                  if (tempUser && tempUser.length > 0) {
                    tempUserName = tempUser[0].attributes.Name;
                    if (props.classrooms && props.classrooms.length > 0) {
                      let tempClass = props.classrooms.filter((classroom) => {
                        return (
                          classroom.id === tempUser[0].attributes.ClassRoomID
                        );
                      });
                      if (tempClass && tempClass.length > 0) {
                        tempClassroomName = tempClass[0].attributes.Name;
                      }
                    }
                  }
                }
              }
            }

            tempObj.userName = tempUserName;
            if (!tempObj.class) {
              tempObj.class = tempClassroomName;
            }

            tempList.push(tempObj);
          }
          setDisplayList(tempList);
        } else {
          //setIsQuestionsAvailable(false);
        }
      }
    });

  }, [
    props.selectedSchool,
    examId,
    props.kids,
    props.teachers,
    props.classrooms,
  ]);

  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }

  const handleClassroomFilterChange = (value) => {
    var classrooms = classroomList.filter(
      (classroom) => classroom.id === value
    );

    if (classrooms.length > 0) {
      setSelectedClassroomFilter(classrooms[0]);
    }
  };

  const getSortedTeachersList = () => {
    let sortedTeachers = [];
    if (props.teachers && props.teachers.length > 0) {
      sortedTeachers = props.teachers.sort(function (a, b) {
        var nameA = a.attributes.Name.toLowerCase(),
          nameB = b.attributes.Name.toLowerCase();
        if (nameA < nameB)
          //sort string ascending
          return -1;
        if (nameA > nameB) return 1;
        return 0; //default return value (no sorting)
      });
    }
    return sortedTeachers;
  };

  const getUserName = (object, key) => {
    let returnValue = "";
    if (quizVisibility === 1) {
      returnValue = object.userName;
    } else if (object.applicantName) {
      returnValue = object.applicantName;
    }

    if (returnValue === "") {
      returnValue = "Entry" + (key + 1);
    }

    return returnValue;
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Modal
          isOpen={showMappingModal}
          fade={false}
          className="modal-dialog modal-xl"
        >
          <ModalHeader
            toggle={() => {
              if (showMappingModal === true) {
                setSelectedSessionObject(undefined);
                setSelectedUserId("");
                setSelectedUserType("");
              }
              setShowMappingModal(!showMappingModal);
            }}
          >
            Select student/teacher to map with this exam entry
          </ModalHeader>
          <ModalBody
            style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
          >
            <div>
              <Col lg={12}>
                <Card>
                  <CardBody>
                    <Nav pills className="navtab-bg nav-justified">
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "1",
                          })}
                          onClick={() => {
                            toggleTab("1");
                          }}
                        >
                          {schoolType==10?"Participants":"Students"}
                        </NavLink>
                      </NavItem>
                      <NavItem>
                        <NavLink
                          style={{ cursor: "pointer" }}
                          className={classnames({
                            active: activeTab === "2",
                          })}
                          onClick={() => {
                            toggleTab("2");
                          }}
                        >
                          {schoolType==10?"Course Coordinators":"Teachers"}
                          
                        </NavLink>
                      </NavItem>
                    </Nav>

                    <TabContent activeTab={activeTab}>
                      <TabPane tabId="1" className="p-3">
                        <Col lg={12}>
                          <Row>
                            <Col lg={6}>
                              <FormGroup>
                                <select
                                  className="form-control"
                                  onChange={(e) => {
                                    handleClassroomFilterChange(e.target.value);
                                  }}
                                >
                                  {classroomList.map((classroomObject, key) => (
                                    <option key={key} value={classroomObject.id}>
                                      {classroomObject.attributes.Name}
                                    </option>
                                  ))}
                                </select>
                              </FormGroup>
                            </Col>
                            <Col lg={6}>
                              <Input
                                type="text"
                                name="searchAG"
                                className="inner form-control mb-3"
                                placeholder="Search"
                                onChange={(e) => {
                                  handleSearchBarTextChange(e.target.value);
                                }}
                              />
                            </Col>
                          </Row>

                          <Row>
                            <div className="table-responsive ml-3 mr-2">
                              <table className="table table-nowrap table-centered mb-0">
                                <tbody>
                                  {getAllKidsFilteredList().length === 0 && (
                                    <div className="ml-3 mt-3">
                                      <p>  {schoolType==10?"No Participants found":"No students found"} </p>
                                      <hr />
                                    </div>
                                  )}
                                  {getAllKidsFilteredList().map(
                                    (kObject, keyInside) => (
                                      <tr key={keyInside}>
                                        <td style={{ width: "60px" }}>
                                          <input
                                            type="checkbox"
                                            id={"checkbox" + keyInside}
                                            onChange={() => {
                                              handleCheckboxChange(
                                                kObject,
                                                "student"
                                              );
                                            }}
                                            checked={
                                              kObject.id === selectedUserId
                                            }
                                          />
                                        </td>

                                        <td>
                                          <Label className="text-truncate font-size-14 m-0">
                                            {kObject.attributes.Name !==
                                              "Pick All" && (
                                                <img
                                                  src={getKidPhoto(kObject)}
                                                  className="rounded-circle avatar-xs m-1"
                                                  alt=""
                                                />
                                              )}
                                            <Link
                                              to="#"
                                              className="text-dark ml-2"
                                            >
                                              {kObject.attributes.Name}
                                            </Link>
                                          </Label>
                                        </td>
                                        <td></td>
                                        <td></td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            </div>
                          </Row>
                        </Col>
                      </TabPane>
                      <TabPane tabId="2" className="p-3">
                        <Col lg={12}>
                          <Row>
                            <div className="table-responsive ml-3 mr-2">
                              <table className="table table-nowrap table-centered mb-0">
                                <tbody>
                                  {(props.teachers == undefined ||
                                    props.teachers.length === 0) && (
                                      <div className="ml-3 mt-3">
                                        <p>No Teachers found</p>
                                        <hr />
                                      </div>
                                    )}
                                  {props.teachers &&
                                    getSortedTeachersList().map(
                                      (kObject, keyInside) => (
                                        <tr key={keyInside}>
                                          <td style={{ width: "60px" }}>
                                            <input
                                              type="checkbox"
                                              id={
                                                "checkboxTeachers" + keyInside
                                              }
                                              onChange={() => {
                                                handleCheckboxChange(
                                                  kObject,
                                                  "teacher"
                                                );
                                              }}
                                              checked={
                                                kObject.id === selectedUserId
                                              }
                                            />
                                          </td>

                                          <td>
                                            <Label className="text-truncate font-size-14 m-0">
                                              {kObject.attributes.Name !==
                                                "Pick All" && (
                                                  <img
                                                    src={getKidPhoto(kObject)}
                                                    className="rounded-circle avatar-xs m-1"
                                                    alt=""
                                                  />
                                                )}
                                              <Link
                                                to="#"
                                                className="text-dark ml-2"
                                              >
                                                {kObject.attributes.Name}
                                              </Link>
                                            </Label>
                                          </td>
                                          <td></td>
                                          <td></td>
                                        </tr>
                                      )
                                    )}
                                </tbody>
                              </table>
                            </div>
                          </Row>
                        </Col>
                      </TabPane>
                    </TabContent>
                  </CardBody>
                </Card>
              </Col>
            </div>
          </ModalBody>
          <ModalFooter>
            {
              <Button
                color="primary"
                disabled={isMappingInProgress}
                onClick={() => {
                  setIsMappingInProgress(true);
                  let sessionObjectToSave =
                    selectedSessionObject.sessionParseObject;
                  sessionObjectToSave.set("userId", selectedUserId);
                  sessionObjectToSave.set("userType", selectedUserType);
                  sessionObjectToSave.save().then(
                    (result) => {
                      let index = displayList.indexOf(selectedSessionObject);
                      let tempList = [...displayList];
                      let tempObj = selectedSessionObject;
                      tempObj.mappedTo = selectedUserName;
                      tempObj.mappedToType = selectedUserType;

                      tempList[index] = tempObj;
                      setDisplayList(tempList);

                      setIsMappingInProgress(false);
                      setSelectedUserId("");
                      setSelectedUserType("");
                      setShowMappingModal(false);
                    },
                    (error) => {
                      setIsMappingInProgress(false);
                      toastr.warning("Please try again !!", { timeOut: 1000 });
                    }
                  );
                }}
              >
                {isMappingInProgress && (
                  <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                )}
                Done
              </Button>
            }{" "}
            <Button
              color="secondary"
              onClick={() => {
                setSelectedSessionObject(undefined);
                setSelectedUserId("");
                setSelectedUserType("");
                setShowMappingModal(false);
              }}
            >
              Cancel
            </Button>
          </ModalFooter>
        </Modal>

        {!isLoading && (
          <Container fluid>
            {/* <Row>
              <Col lg={8} md={8} xs={12}>
                <h4>{examName}</h4>
              </Col>

              <Col lg={4} md={4} xs={12}>
                <Form className="mb-3  float-sm-right form-inline"></Form>
              </Col>
            </Row> */}
            <TopBar
              title={examName}
              buttonName={""}
              backButton={true}
              hideButton={true}
              onClickAdd={() => { }}
              onClickBackButton={() => {
                window.history.back();
              }}
            />

            <Card>
              <CardBody>
                {displayList.length > 0 && (
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th>{props.t("Name")}</th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Class")}
                          </th>
                          {quizVisibility === 2 && (
                            <th style={{ textAlign: "center" }}>
                              {props.t("School Name")}
                            </th>
                          )}
                          <th style={{ width: "40px", textAlign: "center" }}>
                            {props.t("Submitted On")}
                          </th>

                          <th style={{ width: "40px", textAlign: "center" }}>
                            {props.t("Marks Scored")}
                          </th>
                          <th style={{ width: "40px", textAlign: "center" }}>
                            {props.t("Total Marks")}
                          </th>
                          {/* <th style={{ width: "40px", textAlign: "center" }}>
                            {props.t("Mapped to")}
                          </th>
                          <th style={{ width: "20px", textAlign: "center" }}>
                            {props.t("Map")}
                          </th> */}
                          <th style={{ width: "20px" }}>{props.t("View")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayList.map((object, key) => (
                          <tr key={key}>
                            <td>
                              <Label className="font-size-12 mb-1">
                                {getUserName(object, key)}
                              </Label>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {object.class ?? "-"}
                              </Label>
                            </td>
                            {quizVisibility === 2 && (
                              <td style={{ textAlign: "center" }}>
                                <Label className="font-size-12 mb-1">
                                  {object.school ?? "-"}
                                </Label>
                              </td>
                            )}
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {object.sessionParseObject &&
                                  Moment(
                                    object.sessionParseObject.createdAt
                                  ).format("DD-MM-YYYY, h:mm a")}
                              </Label>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {(object.sessionParseObject &&
                                  object.sessionParseObject.attributes
                                    .totalMarksObtained) ??
                                  "-"}
                              </Label>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {(examObject &&
                                  examObject.attributes.totalMarks) ??
                                  "-"}
                              </Label>
                            </td>

                            <td>
                              <Link
                                to={
                                  "/printexamview/" +
                                  schoolId +
                                  "/" +
                                  object.sessionParseObject.attributes.quizId +
                                  "/" +
                                  object.sessionParseObject.id
                                }
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  size="sm"
                                  className="btn-rounded waves-effect waves-light"
                                >
                                  {props.t("View")}
                                </Button>
                              </Link>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {displayList.length === 0 && (
                  <p className="text-center">{schoolType==10?"No Assessments to display !!":"No exam entries to display !!"}</p>
                )}
              </CardBody>
            </Card>
          </Container>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { teachers } = state.Teacher;
  const { classrooms } = state.Classroom;
  const { kids } = state.Kid;

  return { selectedSchool, teachers, kids, classrooms };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getTeachersForSchool,
      getAllKidsForGroup,
      getClassroomsForSchoolId,
    })(ExamView)
  )
);
