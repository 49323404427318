import React from 'react';
import { Row, Col } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter } from 'react-router-dom/cjs/react-router-dom.min';

const Breadcrumb = (props) => {
  return (
    <Row className="mb-3">
      <Col xl="4" sm="6">
        <div className="mt-2">
          <h4 className="mb-0 font-size-18">{props.t(props.title.toUpperCase())}</h4>
        </div>
      </Col>
      <Col lg="8" sm="6">
        <div className="mt-4 mt-sm-0 d-flex justify-content-end">
          <button onClick={props.tog_standard} className="btn btn-primary">{props.t('Add Class')}</button>
        </div>
      </Col>
    </Row>
  );
}

export default withRouter(withNamespaces()(Breadcrumb));
