import React, { useState, useEffect } from "react";
import {
  Col,
  Row,
  Label,
  FormGroup, Form,
  Button,
  Badge,
  UncontrolledTooltip,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Alert
} from "reactstrap";
import Parse from "parse";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Switch from "react-switch";
import Editable from "react-bootstrap-editable";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Constants from "../../Constents";
import * as CommonFunctions from "../../components/Common/CommonFunctions";
import DragDropTables from '../LMS/DragDropTables'

const AdmissionQuestion = (props) => {
  const [selectedQuestion, setSelectedQuestion] = useState(undefined);
  const [moveToModel, showMoveToModel] = useState(false);
  const [selectedQuestionToMove, setSelectedQuestionToMove] = useState(undefined);
  const [selcetdTitleToMove, setSelecetdTitleToMove] = useState(undefined);
  const [titleSelcetWarning, setTitleSelcetWarning] = useState(false);
  const [creatingQuestion, setCreatingQuestion] = useState(false);
  const [allQuestion, setAllQuestions] = useState(undefined);
  const [questionType, setQuestionType] = useState(
    Constants.QESTION_TEXT_SINGLE_LINE
  );

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  function handelSort(list) {
    props.onUpdateOrder(list);
    setAllQuestions(list)
  }

  function handelRemoveQuestion() {
    var obj = selectedQuestion;
    setSelectedQuestion(undefined);
    props.onRemoveQuestion(obj);

    setSelectedQuestionToMove(undefined);
    setSelecetdTitleToMove(undefined);
    showMoveToModel(false);
    setTitleSelcetWarning(false);
  }

  function handelMoveQuestion() {
    var obj = selectedQuestionToMove;
    obj.set("admissionTitleId", selcetdTitleToMove.id);
    obj.set("order", 0);

    props.onMoveQuestion(obj);
  }

  function handelEditQuestion(editQuestion) {
    var questionObject = editQuestionObject;
    questionObject.set("question", editQuestion);
    questionObject.set("isMandatory", isMandatory);
    if (
      (editQuestionObject.attributes.questionType === 5 ||
        editQuestionObject.attributes.questionType === 6) &&
      editQuestionObject.attributes.databaseFieldName !== "kidGender"
    ) {
      questionObject.set("choice", tags);
    }
    props.onEditQuestion(questionObject);

    setEditQuestionObject(undefined);
    setEditQuestion(false);
    setIsMandatory(undefined);
    setTags([]);
    setAddNewTags(false);
    setQuestionError(false);
    setTagError(false);
  }
  const [editQuestionObject, setEditQuestionObject] = useState(undefined);
  const [editQuestion, setEditQuestion] = useState("");
  const [isMandatory, setIsMandatory] = useState(undefined);
  const [tags, setTags] = useState([]);
  const [addNewTags, setAddNewTags] = useState(false);
  const [addQuestionView, setAddQuestionView] = useState(false);

  const [questionTitle, setQuestionTitle] = useState(undefined);
  const [addNewTagsView, setAddNewTagsView] = useState(false);
  const [newTag, setNewTag] = useState("");

  const [questionError, setQuestionError] = useState(false);
  const [tagError, setTagError] = useState(false);
  const [newTagError, setNewTagError] = useState("");

  function getQuestionEditView(question) {
    return (
      <div>
        <AvForm className="outer-repeater"

          onValidSubmit={(e, v) => {


            if (
              !v.editQuestionName ||
              v.editQuestionName.length === 0
            ) {
              setQuestionError(true);
            } else if (
              (question.attributes.questionType === 5 ||
                question.attributes.questionType === 6) &&
              tags.length === 0
            ) {
              setTagError(true);
            } else {

              setEditQuestion(v.editQuestionName);
              handelEditQuestion(v.editQuestionName);
            }

          }}
        >
          <div data-repeater-item className="outer">
            <FormGroup>
              <Label htmlFor="formname">{props.t("Question")}: </Label>
              <AvInput
                type="textarea"
                name={"editQuestionName"}

                placeholder={props.t("Enter Question...")}
                className="form-control"
                value={editQuestion}
                required
                validate={{
                  required: {
                    value: true,
                    errorMessage: props.t("Question can not be empty"),
                  },
                }}
              // onChange={(e) => {
              //   if (e.target.value.length > 0) {
              //     setQuestionError(false);
              //   }
              //   setEditQuestion(e.target.value);
              // }}
              />
              {questionError && (
                <p className="text-danger">
                  {props.t("Question can not be empty")}
                </p>
              )}
            </FormGroup>
            <p className="text-muted">{CommonFunctions.getQuesionDataType(question.attributes.dataType, question.attributes.questionType, question.attributes.databaseFieldName, props)}</p>
            <FormGroup>
              <Label htmlFor="formname">{props.t("Mandatory")}: </Label>
              <Switch
                className="ml-3"
                uncheckedIcon={<Offsymbol />}
                checkedIcon={<OnSymbol />}
                onColor="#626ed4"
                onChange={() => {
                  setIsMandatory(!isMandatory);
                }}
                checked={isMandatory}
              />
            </FormGroup>

            {(question.attributes.questionType === 5 ||
              question.attributes.questionType === 6) &&
              (question.attributes.databaseFieldName !== "kidGender" ||
                question.attributes.databaseFieldName !== "Gender") && (
                <FormGroup>
                  <Label htmlFor="formname">{props.t("Tags")}: </Label>
                  {tags.map((option, key) => (
                    <Badge
                      key={key}
                      className={"badge badge-soft-primary font-size-12 m-2"}
                    >
                      {option.length > 20
                        ? option.slice(0, 19) + "..."
                        : option}

                      <i
                        className="mdi mdi-window-close ml-1"
                        id={option}
                        onClick={(e) => {
                          var others = tags.filter(
                            (tag) => tag !== e.target.id
                          );
                          setTags(others);
                        }}
                      >
                        {" "}
                      </i>
                    </Badge>
                  ))}
                  {!addNewTags && <Badge
                    className={"badge badge-soft-success font-size-12 m-2"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setNewTagError(undefined);
                      setAddNewTags(true);
                    }}
                  >
                    {props.t("Add")} <i className="bx bx-plus ml-1"> </i>
                  </Badge>}
                  {addNewTags && <Badge
                    className={"badge badge-soft-danger font-size-12 m-2"}
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setNewTagError(undefined);
                      setAddNewTags(false);
                    }}
                  >
                    {props.t("Close")} <i className="mdi mdi-window-close ml-1"> </i>
                  </Badge>
                  }

                  {tagError && (
                    <p className="text-danger">
                      {props.t("Tags can not be empty")}
                    </p>
                  )}
                </FormGroup>
              )}

            {addNewTags && (
              <FormGroup className="col-12">
                <Row>
                  <Col lg={8}>
                    <AvInput
                      type="text"
                      name="newTag"
                      id="newTagTextQ"
                      className="inner form-control mb-3"
                      placeholder="Enter Tag"
                    />
                    {newTagError && (
                      <p className="text-danger">
                        {newTagError}
                      </p>
                    )}
                  </Col>


                  <button
                    type="button"
                    className="btn btn-success btn-sm"
                    style={{ width: "34px", height: "34px" }}
                    onClick={() => {
                      let tag = document.getElementById("newTagTextQ").value;

                      if (!(tag && tag.length > 0)) {
                        setNewTagError(props.t("Tag Can not be empty "));
                      } else if (tags.includes(tag)) {
                        setNewTagError(props.t("This Tag is already added"));
                      } else if (tag && tag.length > 0) {
                        setNewTagError(undefined);
                        var temp = [...tags];
                        var res = tag.split(",");
                        var newList = temp.concat(res);
                        if (newList.length > 0) {
                          setTagError(false);
                        }
                        setTags(newList);
                        setAddNewTags(false);
                      }

                    }}
                  >
                    <i className="fa fa-check"></i>
                  </button>
                  <button
                    type="button"
                    className="btn btn-danger btn-sm ml-2"
                    style={{ width: "34px", height: "34px" }}
                    onClick={() => {
                      setAddNewTags(false);
                    }}
                  >
                    <i className="fa fa-times"></i>
                  </button>

                </Row>

              </FormGroup>
            )}
          </div>
          <button
            type="submit"
            className="btn btn-primary m-1 "
          >
            {props.t('Save')}
          </button>
          <button type="button"
            className="btn btn-danger m-1"
            onClick={() => {
              setEditQuestionObject(undefined);
              setEditQuestion(false);
              setIsMandatory(undefined);
              setTags([]);
              setAddNewTags(false);
              setNewTagError(undefined);
              setQuestionError(false);
              setTagError(false);
            }}  >
            {props.t('Close')}

          </button>
        </AvForm>
      </div>
    );
  }

  const resetVariables = () => {
    setEditQuestionObject(undefined);
    setEditQuestion(false);
    setIsMandatory(undefined);
    setTags([]);
    setAddNewTags(false);
    setQuestionError(false);
    setTagError(false);
  };



  function getTitles() {
    if (props.admissionTitles && props.admissionTitles.length > 0) {
      var others = props.admissionTitles.filter(
        (title) => title.id != props.admissionTitleObject.id
      );
      return others;
    }
    return [];
  }

  function getQuestionEditView1() {
    return (
      <div>
        <AvForm className="outer-repeater">
          <div data-repeater-item className="outer">
            <FormGroup>
              <Label style={{ fontSize: "15px" }}>
                {!editQuestionObject
                  ? props.t("Add New Question")
                  : props.t("Edit Question")}
              </Label>
              <br />

              <FormGroup>
                <Label>{props.t("Question Answer Type")}: </Label>
                <br />
                <select
                  className="form-select col-lg-6 mb-2"
                  id={"editQuestionType" + props.keyForQuestion}
                  value={questionType}
                  onChange={(e) => {
                    setQuestionType(parseInt(e.target.value, 10));
                  }}
                >
                  <option
                    value={Constants.QESTION_TEXT_SINGLE_LINE}
                    defaultValue
                  >
                    Single Line Answer
                  </option>
                  <option value={Constants.QESTION_TEXT_MULTI_LINE}>
                    Multi Line Answer
                  </option>

                  <option value={Constants.QESTION_NUMBER}>Number</option>
                  <option value={Constants.QESTION_YES_NO_NO_COMMENTS}>
                    Yes No
                  </option>

                  <option
                    value={
                      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
                    }
                  >
                    Single selection from list of options
                  </option>
                  <option
                    value={Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST}
                  >
                    Multi selection from list of options
                  </option>

                  <option value={Constants.QESTION_DATE}>Date</option>

                  <option value={Constants.QESTION_YES_NO_COMMENT_ON_YES}>
                    Yes or No (with additional text if Yes)
                  </option>

                  <option value={Constants.QESTION_YES_NO_COMMENT_ON_NO}>
                    Yes or No (with additional text if No)
                  </option>

                  <option value={Constants.QUESTION_EMAIL}>Email Id</option>
                  <option value={Constants.QUESTION_PHONE_NUMBER}>
                    Phone/Mobile Number
                  </option>
                  <option value={Constants.QESTION_CHECKBOX}>
                    Simple Checkbox
                  </option>
                  <option value={Constants.QESTION_UPLOAD_IMAGE}>
                    Profile Picture Upload
                  </option>
                  <option value={Constants.QESTION_UPLOAD_DOCUMENT}>
                    Documents Upload
                  </option>
                </select>
              </FormGroup>


              <Label>{props.t("Question Title")}: </Label>
              <textarea
                type="text"
                name="editQuestionName"
                placeholder={props.t("Enter Question Title...")}
                className="form-control"
                value={questionTitle}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setQuestionError(false);
                  }
                  setQuestionTitle(e.target.value);
                }}
              />
              {questionError && (
                <p className="text-danger">
                  {props.t("Question Title can not be empty")}
                </p>
              )}
            </FormGroup>

            {(
              <FormGroup>
                <Label htmlFor="formname">{props.t("Mandatory")}: </Label>
                <Switch
                  className="ml-3"
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {
                    setIsMandatory(!isMandatory);
                  }}
                  checked={isMandatory}
                />
              </FormGroup>
            )}
            {(questionType === 5 || questionType === 6) && (
              <FormGroup>
                <Label>{props.t("Choices")}: </Label>
                {tags &&
                  tags.map((option, key) => (
                    <Badge
                      key={key}
                      className={"badge badge-soft-primary font-size-12 m-2"}
                    >
                      {option.length > 20
                        ? option.slice(0, 19) + "..."
                        : option}

                      <i
                        className="mdi mdi-window-close ml-1"
                        id={option}
                        onClick={(e) => {
                          var others = tags.filter(
                            (tag) => tag !== e.target.id
                          );
                          setTags(others);
                        }}
                      >
                        {" "}
                      </i>
                    </Badge>
                  ))}
                {!addNewTagsView && (
                  <Badge
                    className={"badge badge-soft-success font-size-12 m-2"}
                    onClick={() => {
                      setNewTag("");
                      setAddNewTagsView(true);
                    }}
                  >
                    {props.t("Add")} <i className="bx bx-plus ml-1"> </i>
                  </Badge>
                )}
                {addNewTagsView && (
                  <Badge
                    className={"badge badge-soft-danger font-size-12 m-2"}
                    onClick={() => {
                      setNewTag("");
                      setAddNewTagsView(false);
                    }}
                  >
                    {props.t("Close")}{" "}
                    <i className="mdi mdi-window-close ml-1"> </i>
                  </Badge>
                )}

                {tagError && (
                  <p className="text-danger">
                    {props.t("Please add atleast one choice")}
                  </p>
                )}
              </FormGroup>
            )}

            {addNewTagsView && (
              <FormGroup>
                <Editable
                  className="me-5 ml-5"
                  alwaysEditing={true}
                  onValidated={(value) => {
                    var temp = [...tags];
                    var res = value.split(",");
                    var newList = temp.concat(res);
                    if (newList.length > 0) {
                      setTagError(false);
                    }
                    setTags(newList);
                    setNewTag("");
                    setAddNewTagsView(false);
                  }}
                  onCancel={() => {
                    setAddNewTagsView(false);
                  }}
                  disabled={false}
                  editText="✎"
                  id={"addNewQuestion123"}
                  isValueClickable={false}
                  mode="inline"
                  placement="top"
                  initialValue={newTag}
                  showText
                  type="textfield"
                  placeholder="Type here"
                  validate={(value) => {
                    if (!(value && value.length > 0)) {
                      return props.t("Tag Can not be empty ");
                    } else if (tags.includes(value)) {
                      return props.t("This Tag is already added");
                    }
                  }}
                />
              </FormGroup>
            )}
          </div>
        </AvForm>
      </div>
    );
  }

  const handleAddNewQuestion = () => {
    setCreatingQuestion(true);
    let AdmissionQuestion = Parse.Object.extend("AdmissionQuestion");
    let admissionQuestion = new AdmissionQuestion();

    admissionQuestion.set("databaseTableName", "AdmissionData");
    admissionQuestion.set("question", questionTitle);
    admissionQuestion.set("questionType", questionType);
    admissionQuestion.set("choice", tags);
    admissionQuestion.set("isMandatory", isMandatory);
    admissionQuestion.set("schoolId", props.schoolId);


    admissionQuestion.set("admissionTitleId", props.admissionTitleObject.id);
    if (props.questions && props.questions.length > 0) {
      admissionQuestion.set("order", props.questions.length);
    } else {
      admissionQuestion.set("order", 0);

    }


    setAddQuestionView(false);
    props.onAddQuestion(admissionQuestion);
    setCreatingQuestion(false);

  };

  useEffect(() => {
    setAllQuestions(props.questions)
  }, [props.questions])

  return (
    <React.Fragment>
      {!props.questionRearrange && <div className="justify-content-center cursors move">
        {
          allQuestion &&
          allQuestion.map((question) =>
            <Row
              key={question.id}
              className="border-bottom justify-content-center bg-white"
            >
              <Col lg={1} className="mt-3 mb-2">
                <div className="custom-control custom-checkbox mb-2 ml-3">
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    id={question.id}
                    onChange={() => {setSelectedQuestion(question)}}
                    checked={true}
                  />
                  <label
                    className="custom-control-label"
                    onClick={() => {
                      setSelectedQuestion(question);
                    }}
                  ></label>
                </div>
              </Col>
              <Col lg={9} className="mt-3 mb-2">
                {editQuestionObject &&
                  question.id === editQuestionObject.id ? (
                  <div>{getQuestionEditView(question)}</div>
                ) : (
                  <div>
                    <h5
                      className=" font-size-14 m-0"
                      style={{ whiteSpace: "pre-wrap" }}
                    >
                      <Link to="#" className="text-dark">
                        {question.attributes.question}
                      </Link>
                    </h5>
                    <p className="m-0">
                      {" "}
                      {props.t("Mandatory Question") + ": "}
                      {question.attributes.isMandatory
                        ? props.t("Yes")
                        : props.t("NO")}
                    </p>
                    {(question.attributes.questionType === 5 ||
                      question.attributes.questionType === 6) && (
                        <FormGroup>
                          <Label>{props.t("Tags")}: </Label>
                          {question.attributes.choice &&
                            question.attributes.choice.map((option, key) => (
                              <Badge
                                key={key}
                                className={
                                  "badge badge-soft-primary font-size-12 m-1"
                                }
                              >
                                {option.length > 20
                                  ? option.slice(0, 19) + "..."
                                  : option}{" "}
                              </Badge>
                            ))}
                        </FormGroup>
                      )}
                    <p className="text-muted">{CommonFunctions.getQuesionDataType(question.attributes.dataType, question.attributes.questionType, question.attributes.databaseFieldName, props)}</p>
                  </div>
                )}
              </Col>

              <Col lg={2} className="mt-2 mb-2">
                <Row className="float-right mr-2 ">
                  <div className="d-flex">
                    {!editQuestionObject && (
                      <ul className="list-inline font-size-20 contact-links  mb-0 mt-2 ">
                        <li className="list-inline-item px-2">
                          <div
                            onClick={() => {
                              showMoveToModel(true);
                              setSelectedQuestionToMove(question);
                            }}
                            id={"question" + question.id}
                          >
                            <i className="bx bx-transfer"></i>
                            <UncontrolledTooltip
                              placement="top"
                              target={"question" + question.id}
                            >
                              {props.t("Move To")}
                            </UncontrolledTooltip>
                          </div>
                        </li>
                      </ul>
                    )}

                    {/* <Col> */}
                    {editQuestionObject && question.id === editQuestionObject.id ? null : <Button
                      onClick={(e) => {
                        setEditQuestionObject(question);
                        setEditQuestion(question.attributes.question);
                        setIsMandatory(question.attributes.isMandatory);
                        setTags(question.attributes.choice);
                        setQuestionError(false);
                        setTagError(false);
                        setAddNewTags(false);
                      }}
                      type="button"
                      color="primary"
                      size="sm"
                      className="btn-rounded waves-effect waves-light m-2 "
                    >
                      {props.t("Edit")}
                    </Button>}
                  </div>
                  {/* </Col> */}
                </Row>
              </Col>
            </Row>

          )
        }

        {!addQuestionView ? <Row className="border-bottom justify-content-center bg-white">
          <Col lg={12}>
            <div className="mr-2">

              <button
                onClick={() => {
                  if (addQuestionView === false) {
                    resetVariables();
                    setEditQuestionObject(undefined);
                  }
                  setQuestionTitle(undefined)
                  setAddQuestionView(true);
                }}
                type="button"
                className="btn btn-primary waves-effect float-right waves-light m-2 mt-4 "
              >
                <i className="bx bx-plus font-size-16 align-middle mr-2"></i>{" "}
                {props.t("Add Custom Question")}
              </button>

              <Alert color="danger" className="float-right mb-4" >
                {"Please search for your question in "}<Label className="text-primary" onClick={() => { props.onClickGAQ() }}>General Admission Questions</Label>  {" section, if not found add custom question"}
              </Alert>

            </div>
          </Col>
        </Row> :
          <Row className="border-bottom justify-content-center bg-white">
            <Col lg={1} className="mt-3 mb-2">
              <div className="custom-control custom-checkbox mb-2 ml-3"></div>
            </Col>
            <Col lg={9} className="mt-3 mb-2">
              <div>{getQuestionEditView1()}</div>
            </Col>

            <Col lg={2} className="mt-2 mb-2">
              <Row>
                <Col>
                  <Button
                    onClick={(e) => {
                      let canProceed = true;
                      if (
                        questionTitle === undefined ||
                        questionTitle === null ||
                        questionTitle.length === 0
                      ) {
                        canProceed = false;
                        setQuestionError(true);
                      }
                      if (
                        (questionType === 5 || questionType === 6) &&
                        tags.length === 0
                      ) {
                        canProceed = false;
                        setTagError(true);
                      }
                      if (canProceed) {
                        handleAddNewQuestion();
                      }
                    }}
                    type="button"
                    color="primary"
                    size="sm"
                    disabled={creatingQuestion}
                    className="btn-rounded waves-effect waves-light m-2"
                  >
                    {creatingQuestion && (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                    )}
                    {props.t("Save")}
                  </Button>

                  <Button
                    type="button"
                    color="danger"
                    size="sm"
                    onClick={() => {
                      setEditQuestionObject(undefined);
                      setIsMandatory(false);
                      setTags([]);
                      setAddNewTagsView(false);
                      setNewTag("");
                      setQuestionError(false);
                      setTagError(false);
                      setAddQuestionView(false);
                    }}
                    className="btn-rounded waves-effect waves-light  "
                  >
                    {props.t("Cancel")}
                  </Button>
                </Col>
              </Row>
            </Col>
          </Row>
        }
      </div>}

      {props.questionRearrange &&
        <DragDropTables
          rearrangeObjects={props.questions}
          updatedOrderList={handelSort}
          onClickClose={() => { props.closeQuestionRearrange() }}
        />}
      {selectedQuestion && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Remove"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setSelectedQuestion(undefined);
          }}
          onConfirm={() => handelRemoveQuestion()}
        >
          {props.t("Are you sure you want to remove the question")} <br></br>
        </SweetAlert>
      )}

      <Modal isOpen={moveToModel} scrollable={true}>
        <ModalHeader isOpen={moveToModel}>
          <h5 className="modal-title mt-0">{props.t("Selecte Title")}</h5>
        </ModalHeader>
        <ModalBody className="modal-body">
          <p>
            {props.t(
              "Please select the title in which question has to move , On saving Question will be moved to selected title"
            )}
          </p>
          {getTitles().map((item) => (
            <div
              className="custom-control custom-checkbox mb-2 ml-3"
              key={item.id}
            >
              <input
                type="checkbox"
                className="custom-control-input"
                id={item.id}
                onClick={() => {
                  setSelecetdTitleToMove(item);
                  setTitleSelcetWarning(false);
                }}
                checked={
                  selcetdTitleToMove && item.id === selcetdTitleToMove.id
                }
              />
              <label
                className="custom-control-label"
                // onClick={() => {
                //   setSelecetdTitleToMove(item);
                //   setTitleSelcetWarning(false);
                // }}
              >
                {item.attributes.title}
              </label>
            </div>
          ))}
          {titleSelcetWarning && (
            <p className="text-danger ml-2">
              {props.t("Please selecte a title")}
            </p>
          )}
        </ModalBody>

        <ModalFooter>
          {
            // !loding ?
            <div>
              <button
                type="button"
                className="btn btn-secondary"
                onClick={() => {
                  setSelectedQuestionToMove(undefined);
                  setSelecetdTitleToMove(undefined);
                  showMoveToModel(false);
                  setTitleSelcetWarning(false);
                }}
              >
                {props.t("Close")}
              </button>
              <button
                type="button"
                className="btn btn-primary ml-2"
                onClick={() => {
                  if (selcetdTitleToMove) {
                    handelMoveQuestion();
                  } else {
                    setTitleSelcetWarning(true);
                  }
                }}
              >
                {props.t("Move")}
              </button>
            </div>
            // :
            //     <button type="button" className="btn btn-light waves-effect">
            //         <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Moving to Classroom')}
            //     </button>
          }
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withNamespaces()(AdmissionQuestion);
