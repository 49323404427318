import * as actions from "./actionTypes";

const initialState = {
  moneyTransactions: [],
  pendingVerifyMT: [],
  moneyTransactionsForInvoiceIds: [],
  reciveTransactionStat: false,
  showLoader: false,
  showAllTransactionLoader: false,
  gotAllTransaction: false,
  transactionCount: 0,
};

const MoneyTransactions = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_MONEY_TRANSACTION:
      var moneyTransactions = [...state.moneyTransactions];
      var temp = state.moneyTransactions.filter(
        (moneytransaction) =>
          moneytransaction.id === action.payload.moneyTransaction.id
      );
      if (temp.length > 0) {
        var index = moneyTransactions.indexOf(temp[0]);
        moneyTransactions[index] = action.payload.moneyTransaction;
        state = { ...state, moneyTransactions: moneyTransactions };
      } else {
        var list = [action.payload.moneyTransaction];
        state = { ...state, moneyTransactions: list.concat(moneyTransactions) };
      }
      break;
    case actions.UPDATE_MONEY_TRANSACTIONS:
      // var moneyTransactions = [...state.moneyTransactions];
      // if (moneyTransactions.length > 0) {
      //   for (const latest in action.payload.moneyTransactions) {
      //     var temp = moneyTransactions.filter(
      //       (moneytransaction) =>
      //       moneytransaction.id === action.payload.moneyTransactions[latest].id
      //     );
      //     if (temp.length > 0) {
      //       var index = moneyTransactions.lastIndexOf(temp[0]);
      //       moneyTransactions[index] = action.payload.moneyTransactions[latest];
      //     } else {
      //       moneyTransactions.push(action.payload.moneyTransactions[latest]);
      //     }
      //   }
      //   state = { ...state, moneyTransactions: moneyTransactions };
      // } else {
      state = {
        ...state,
        moneyTransactions: action.payload.moneyTransactions,
        showAllTransactionLoader: false,
        gotAllTransaction: true,
      };
      // }
      break;
    case actions.UPDATE_MONEY_TRANSACTIONS_UPDATE:
      let tempMt = [...state.moneyTransactions];
      for (const val of action.payload.moneyTransactions) {
        let gotValue = false;
        for (const val1 of tempMt) {
          if (val1.id === val.id) {
            gotValue = true;
            break;
          }
        }
        if (!gotValue) {
          tempMt.unshift(val);
        }
      }
      state = {
        ...state,
        moneyTransactions: tempMt,
        showAllTransactionLoader: false,
      };
      break;
    case actions.DELETE_MONEY_TRANSACTION:
      var moneyTransactions = state.moneyTransactions.filter(
        (moneytransaction) => moneytransaction.id !== action.payload.id
      );
      state = { ...state, moneyTransactions: moneyTransactions };
      break;
    case actions.SET_TRIANSACTION:
      let mt = [...state.moneyTransactions];
      if (mt.length > 0) {
        for (let i in mt) {
          if (mt[i] === action.payload.tObj.id) {
            mt[i] = action.payload.tObj;
            break;
          }
        }
        state = { ...state, moneyTransactions: mt };
      }
      break;
    case actions.SET_ALL_TRIANSACTION_COUNT_FOR_FILTER:
      state = { ...state, transactionCount: action.payload.count };
      break;
    case actions.RECEIVE_PAYMENT_BY_FUNCTION:
      state = { ...state, showLoader: true, reciveTransactionStat: false };
      break;
    case actions.RECEIVE_PAYMENT_BY_FUNCTION_SUCCESS:
      if (action.payload.Obj) {
        state = { ...state, showLoader: false, reciveTransactionStat: true };
      }
      break;
    case actions.RECIVE_TRIANSACTION_DISPLAY_SUCCESS:
      state = { ...state, reciveTransactionStat: false };
      break;
    case actions.GET_ALL_TRIANSACTION_FOR_FILTER:
      state = {
        ...state,
        showAllTransactionLoader: true,
        gotAllTransaction: false,
      };
      break;
    case actions.SET_VERIFIED_FOR_PENDING_INVOICE:
      var tempTriansactionsItems = [...state.pendingVerifyMT];
      if (tempTriansactionsItems.length > 0) {
        for (const latest in action.payload.transactions) {
          var temp = tempTriansactionsItems.filter(
            (schoolRating) =>
              schoolRating.id === action.payload.transactions[latest].id
          );
          if (temp.length > 0) {
            var index = tempTriansactionsItems.lastIndexOf(temp[0]);
            tempTriansactionsItems[index] = action.payload.transactions[latest];
          } else {
            tempTriansactionsItems.push(action.payload.transactions[latest]);
          }
        }
        state = { ...state, pendingVerifyMT: tempTriansactionsItems };
      } else {
        state = { ...state, pendingVerifyMT: action.payload.transactions };
      }
      break;
    case actions.REMOVE_FROM_PENDING_TRANSACTIONS:
      var tempTriansactionsItems = [...state.pendingVerifyMT];
      if (tempTriansactionsItems.length > 0) {
        var temp = tempTriansactionsItems.filter(
          (mt) =>
            mt.id !== action.payload.mt.id
        );
        state = { ...state, pendingVerifyMT: temp };
      }
      break;
    case actions.GET_TRIANSACTION_FOR_INVOICE_IDS:
      state = { ...state, moneyTransactionsForInvoiceIds: [] };
      break;
    case actions.SET_TRIANSACTION_FOR_INVOICE_IDS:
      state = {
        ...state,
        moneyTransactionsForInvoiceIds: action.payload.mtObjs,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default MoneyTransactions;
