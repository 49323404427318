import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
  files: [], loadingFileState: 1, fileAddState: false, 
  noticeboardfiles: []
};

const Files = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_FILE:
      var files = [...state.files];
      var temp = state.files.filter(
        (file) =>
          file.id === action.payload.file.id
      );
      if (temp.length > 0) {

        if (action.payload.file.attributes.isDeleted) {

          var others = state.files.filter(
            (file) =>
              file.id !== action.payload.file.id
          );
          state = { ...state, files: others };
          
        } else {
          var index = files.indexOf(temp[0]);
          files[index] = action.payload.file;
          state = { ...state, files: files };
        }

       
      } else {
        if (!action.payload.file.attributes.isDeleted) {

          var list = [action.payload.file];
          files.push(action.payload.file)
          state = { ...state, files: files };
        }
        
      }
      break;
    case actions.UPDATE_FILES:
      var files = [...state.files];
      if (files.length > 0) {
        for (const latest in action.payload.files) {
          var temp = files.filter(
            (file) =>
              file.id === action.payload.files[latest].id
          );

          
          if (temp.length > 0) {
            if (action.payload.files[latest].attributes.isDeleted) {

              var others = files.filter(
                (file) =>
                  file.id !== action.payload.files[latest].id
              );
             
              files = others;
            } else {
              var index = files.lastIndexOf(temp[0]);
              files[index] = action.payload.files[latest];
            }
           
          } else {
            files.push(action.payload.files[latest]);
          }
        }
        state = { ...state, files: files };
      } else {

        var others = action.payload.files.filter(
          (file) => (!file.attributes.isDeleted)
        );
        state = { ...state, files: others };
      }
      break;
    case actions.DELETE_FILE:
      var files = state.files.filter(
        (file) => file.id !== action.payload.file.id
      );
      state = { ...state, files: files };
      break;
    
    case actions.CLEAR_FILES:
      state = { ...state, files: [], loadingFileState: action.payload.state };
      break;
    
    case actions.FILE_LOADING_STATE:
      state = { ...state, loadingFileState: action.payload.state };
      break;
    
    case actions.FILE_LOADING_STATE:
      state = { ...state, fileAddState: action.payload.state };
      break;
    
    case actions.UPDATE_NOTICE_BOARD_FILES:
      var noticeboardfiles = [...state.noticeboardfiles]
        if (noticeboardfiles.length > 0) {
            var updatedList = ReduxUtil.updateParseObjects([...state.noticeboardfiles], action.payload.files, "isDeleted", false)
            state = { ...state, noticeboardfiles:updatedList};
        } else {

          var others = action.payload.files.filter(
            (f) => f.attributes.isDeleted !== true
          );
            
          state = { ...state, noticeboardfiles: others };
        }
      break; 
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Files;
