import * as actions from "./actionTypes";

const initialState = {
  childadditionaldetails: [],
  selectedChildAdditionalDetail: null,
  childAdditionalDetailsObject: null,
};

const ChildAdditionalDetails = (state = initialState, action) => {
  switch (action.type) {
    case actions.SET_CHILD_ADDITIONAL_DETAIL_OBJECT:
      state = {
        ...state,
        childAdditionalDetailsObject: action.payload.childAdditionalDetail,
      };
      break;
    case actions.UPDATE_CHILD_ADDITIONAL_DETAIL:
      var childadditionaldetails = [...state.childadditionaldetails];
      var temp = state.childadditionaldetails.filter(
        (childadditionaldetail) =>
          childadditionaldetail.id === action.payload.childAdditionalDetail.id
      );
      if (temp.length > 0) {
        var index = childadditionaldetails.indexOf(temp[0]);
        childadditionaldetails[index] = action.payload.childAdditionalDetail;
        state = { ...state, childadditionaldetails: childadditionaldetails };
      } else {
        childadditionaldetails.push(action.payload.childAdditionalDetail);
        state = { ...state, childadditionaldetails: childadditionaldetails };
      }
      break;
    case actions.UPDATE_CHILD_ADDITIONAL_DETAILS:
      var childadditionaldetails = [...state.childadditionaldetails];
      if (childadditionaldetails.length > 0) {
        for (const latest in action.payload.childadditionaldetails) {
          var temp = childadditionaldetails.filter(
            (childadditionaldetail) =>
              childadditionaldetail.id ===
              action.payload.childadditionaldetails[latest].id
          );
          if (temp.length > 0) {
            var index = childadditionaldetails.lastIndexOf(temp[0]);
            childadditionaldetails[index] =
              action.payload.childadditionaldetails[latest];
          } else {
            childadditionaldetails.push(
              action.payload.childadditionaldetails[latest]
            );
          }
        }
        state = { ...state, childadditionaldetails: childadditionaldetails };
      } else {
        state = {
          ...state,
          childadditionaldetails: action.payload.childadditionaldetails,
        };
      }

      break;

    case actions.CLEAR_CHILD_ADDITIONAL_DETAILS:

      state = {
        ...state,
        childadditionaldetails: [],
      }

      break;




    case actions.DELETE_CHILD_ADDITIONAL_DETAIL:
      var childadditionaldetails = state.childadditionaldetails.filter(
        (childadditionaldetail) =>
          childadditionaldetail.id !== action.payload.id
      );
      state = { ...state, childadditionaldetails: childadditionaldetails };
      break;
    case actions.GET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS:
      state = { ...state, childadditionaldetails: [] };
      break;
    case actions.SET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS:
      state = { ...state, childadditionaldetails: action.payload.objs };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default ChildAdditionalDetails;
