import Parse from "parse";

export const getInventoryKitItemsForOwnerId = (ownerId, ownerType, updatedDate,isLocal,) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryKitItemMap = Parse.Object.extend("InventoryKitItemMap");
        var query = new Parse.Query(InventoryKitItemMap);
        query.equalTo("ownerId", ownerId);
        query.equalTo("ownerType", ownerType)
  
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
       } else {
         query.notEqualTo("isDeleted", true);
       }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
        query.addAscending("order");
        query.find().then(
          (result) => {
           resolve(result); 
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };

  export const deleteInventoryKitItemFromParse = (inventoryKitItem) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryKitItem.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            inventoryKitItem.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            inventoryKitItem.set("deletedBy", Parse.User.current().id);
        }
  
        inventoryKitItem.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("error");
          }
        );
      });
    } catch (error) {}
  };
  
  export const getInventoryKitItemFromLocal = (inventoryKitItemId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryKitItemMap = Parse.Object.extend("InventoryKitItemMap");
        var query = new Parse.Query(InventoryKitItemMap);
        query.fromLocalDatastore();
        query.get(inventoryKitItemId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  export const getInventoryKitItemFromParse = (inventoryKitItemId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryKitItemMap = Parse.Object.extend("InventoryKitItemMap");
        var query = new Parse.Query(InventoryKitItemMap);
        query.equalTo("kitId",inventoryKitItemId);
        query.notEqualTo("isDeleted", true);
        query.find().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  export const saveCustomObjects = (object) => {
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) { }
  };

