
import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import Parse from 'parse';
import { Label, FormGroup, Row, Col, Card, CardBody, Input, UncontrolledTooltip, Table, Button } from "reactstrap";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import TopBar from './Topbar';
import { withNamespaces } from "react-i18next";
import { Element, animateScroll as scroller } from 'react-scroll'
import Editable from "react-bootstrap-editable";
import SweetAlert from "react-bootstrap-sweetalert";
import classimge from '../../assets/images/lms/classroom.png';
import * as CommonFunctions from "../../components/Common/CommonFunctions";
import DragDropTables from './DragDropTables';

import {
    setSelecetdSchool,
    getAcademicCycleForCurriculumId, setSelectedCurriculum,
    addAcademicCycle, deleteAcademicCycle, reorderCycles,
    getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId
} from '../../store/actions';


const CycleYears = (props) => {


    const match = matchPath(props.history.location.pathname, {
        path: '/lms-syllabus/:schoolId/:curriculumId',
        exact: true,
        strict: false,

    });

    const curriculumId = match.params.curriculumId;
    const schoolId = match.params.schoolId;
    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);



    useEffect(() => {
        props.getAcademicCycleForCurriculumId(curriculumId);
        props.setSelectedCurriculum(curriculumId);
    }, [curriculumId]);

    const [canEdit, setCanEdit] = useState(false);
    useEffect(() => {
        setCanEdit(CommonFunctions.canEdit(props.selectedCurriculum, props.userTeacher));
    }, [props.selectedCurriculum, props.userTeacher]);


    const [newCycleYear, setNewCycleYear] = useState(undefined);
    const [nameError, setNameError] = useState(false);
    const [selectedTab, setSelectedTab] = useState("Loading");
    const [hideButton, setHideButton] = useState(false);
    const [loading, setLoading] = useState(false);
    const [loading2, setLoading2] = useState(false);

    const [selectedCycles, setSelectedCycles] = useState([]);
    const [selectedId, setSelectedId] = useState([]);
    const [cycleYearError, setCycleYearError] = useState(false);

    const [isCustomClass, setIsCustomClass] = useState(false);

    const [cyclicYears, setCyclicYears] = useState([
        {
            id: "Daycare",
            i: 0,
            title: "Daycare",
            actualCycles: ["Infant 0-18 Months", "Toddler 13-36 Months "],
            cycles: ["Infant	0-18 Months", "Toddler 	13-36 Months "],
            c: [{ id: "1.1", i: 0, year: "Infant 0-18 Months", renamed: undefined }, { id: "1.2", i: 1, year: "Toddler 13-36 Months", renamed: undefined }]
        },
        {
            id: "Foundational",
            i: 1,
            title: "Foundational Stage - (5 Years)",
            actualCycles: ["Nursery", "LKG", "UKG", "Class1", "Class2"],
            cycles: ["Nursery", "LKG", "UKG", "Class1", "Class2"],
            c: [{ id: "2.1", i: 0, year: "Nursery", renamed: undefined }, { id: "2.2", i: 1, year: "LKG", renamed: undefined }, { id: "2.3", i: 2, year: "UKG", renamed: undefined }, { id: "2.4", i: 3, year: "Class1", renamed: undefined }, { id: "2.5", i: 4, year: "Class2", renamed: undefined }]
        },
        {
            id: "Preparatory",
            i: 2,
            title: "Preparatory Stage - (3 Years)",
            actualCycles: ["Class3", "Class4", "Class5 "],
            cycles: ["Class3", "Class4", "Class5"],
            c: [{ id: "3.1", i: 0, year: "Class3", renamed: undefined }, { id: "3.2", i: 1, year: "Class4", renamed: undefined }, { id: "3.3", i: 2, year: "Class5", renamed: undefined },]
        },
        {
            id: "Middle",
            i: 3,
            title: "Middle Stage - (3 Years)",
            actualCycles: ["Class6", "Class7", "Class8"],
            cycles: ["Class6", "Class7", "Class8"],
            c: [{ id: "4.1", i: 0, year: "Class6", renamed: undefined }, { id: "4.2", i: 1, year: "Class7", renamed: undefined }, { id: "4.3", i: 2, year: "Class8", renamed: undefined },]
        },
        {
            id: "Secondary",
            i: 4,
            title: "Secondary Stage - (4 Years)",
            actualCycles: ["Class9", "Class10", "Class11", "Class12 "],
            cycles: ["Class9", "Class10", "Class11", "Class12"],
            c: [{ id: "5.1", i: 0, year: "Class9", renamed: undefined }, { id: "5.2", i: 2, year: "Class10", renamed: undefined }, { id: "5.3", i: 2, year: "Class11", renamed: undefined }, { id: "5.4", i: 3, year: "Class12", renamed: undefined }]
        }

    ])


    const [editCycle, setEditCycle] = useState(undefined);
    const [deleteCycle, setDeleteCycle] = useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [displayCurriculumAcademicCycles, setDisplayCurriculumAcademicCycles] = useState([]);


    useEffect(() => {
        if (props.loadState === "request" && selectedTab !== "Loading") {
            setSelectedTab("Loading");
        } else if (props.loadState === "responce-local") {
            if (props.curriculumAcademicCycles && props.curriculumAcademicCycles.length > 0) {
                setSelectedTab("CycleList");
            }
        } else if (props.loadState === "responce-server") {
            setSelectedTab("CycleList");
        }
        // setSelectedTab("rearrange");
    }, [props.loadState]);

    function handelCycle(year) {
        // if (selectedId === year.id) {
        //     setSelectedId(undefined);
        // } else {
        //     setSelectedId(year.id);
        // }

        if (selectedId.includes(year.id)) {
            var temp = selectedId.filter(cycleId => cycleId !== year.id);
            setSelectedId(temp)

        } else {
            var list = [...selectedId];
            list.push(year.id);
            setSelectedId(list)
        }

        if (selectedCycles && selectedCycles.length > 0) {
            setCycleYearError(false);
        }
        scroller.scrollTo('toNext', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 0,
        });
    }

    useEffect(() => {
        if (props.curriculumAcademicCycles && props.curriculumAcademicCycles.length > 0) {
            var list = [];
            var ids = [];
            for (const i in cyclicYears) {
                var obj = cyclicYears[i];
                for (const j in cyclicYears[i].c) {
                    var selected = props.curriculumAcademicCycles.filter((cycle) => (cyclicYears[i].c[j].year === cycle.attributes.defaultCycle));
                    if (selected && selected.length > 0) {
                        obj.c[j].parseObject = selected[0];
                        ids.push(obj.c[j].id);
                    }

                }
                list.push(obj);

            }
            setCyclicYears(list);
            setSelectedCycles(ids);
        }

    }, [props.curriculumAcademicCycles, props.loadState]);



    function getNamesForIds() {
        var cycles = [];


        for (const i in cyclicYears) {
            for (const j in cyclicYears[i].c) {
                if (selectedId.includes(cyclicYears[i].c[j].id)) {
                    var lil = cyclicYears[i].c[j].year;
                    var soc = cyclicYears[i].c[j].year;
                    if (cyclicYears[i].c[j].renamed && cyclicYears[i].c[j].renamed.length > 0) {
                        soc = cyclicYears[i].c[j].renamed;
                    }
                    cycles.push({ lil: lil, school: soc });

                }
            }
        }

        return cycles
    }

    function handelAddCycleYearFromList(objects) {
        var list = []
        for (const i in objects) {
            var object = objects[i]
            var AcademicCycles = Parse.Object.extend("AcademicCycles");
            var academicCycles = new AcademicCycles();
            academicCycles.set("schoolCycle", object.school);
            academicCycles.set("defaultCycle", object.lil);
            academicCycles.set("curriculumId", curriculumId);
            academicCycles.set("order", props.curriculumAcademicCycles.length);
            list.push(academicCycles)
        }

        if (list && list.length > 0) {
            props.addAcademicCycle(list);
        }
        setSelectedTab("CycleList");
        setHideButton(false);
        setSelectedId([]);
    }

    function handelAddCycleYearFromInput(newCycleYear) {
        var AcademicCycles = Parse.Object.extend("AcademicCycles");
        var academicCycles = new AcademicCycles();
        academicCycles.set("schoolCycle", newCycleYear);
        academicCycles.set("curriculumId", curriculumId);
        academicCycles.set("order", props.curriculumAcademicCycles.length);
        props.addAcademicCycle([academicCycles]);
    }

    function handelShowAddCycleYear() {
        setNewCycleYear("")
        setNameError(false);
        setSelectedTab("AddCycleYear")
        setHideButton(true);
        setIsCustomClass(false);
        setLoading(false);
        setLoading2(false);
        setSelectedId([]);
    }

    function handelOnClickBack() {
        if (selectedTab === "AddCycleYear" || selectedTab === "rearrange") {
            setSelectedTab("CycleList");
            setHideButton(false);
        } else {
            // props.onClickBack();
            window.history.back();
        }

    }
    function onClickItem(cycleId) {
        props.history.push("/lms-subject/" + schoolId + "/" + curriculumId + "/" + cycleId);
    }
    function getCycleList() {
        let list = [...displayCurriculumAcademicCycles];
        list.push({ id: "CommonSubjects" });
        return list;
    }

    const handelReorder = () => {
        setSelectedTab("rearrange");
        setHideButton(true);
    }

    const handelSort = (list) => {

        var tempList = [];
        for (var i in list) {
            // if (parseInt(i) !== list[i].attributes.order) {
            list[i].set("order", parseInt(i));
            tempList.push(list[i]);
            // }  
        }
        props.reorderCycles(tempList, "LMS", "order updated successfully");
        setSelectedTab("CycleList");
        setHideButton(false);
    }

    useEffect(() => {
        if (schoolId != "franchise" && schoolId && curriculumId) {
            props.getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId(schoolId, curriculumId);
        }
    }, [schoolId, curriculumId])

    useEffect(() => {

        if (schoolId == "franchise") {
            setDisplayCurriculumAcademicCycles(props.curriculumAcademicCycles);
        } else if (props.selectedCurriculum && props.selectedCurriculum.attributes.accessBased) {
            let tempList = []
            for (const curriculumAcademicCycle of props.curriculumAcademicCycles) {
                if (props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId &&
                    props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId.attributes.academicCycleIds &&
                    props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId.attributes.academicCycleIds.includes(curriculumAcademicCycle.id)) {
                    tempList.push(curriculumAcademicCycle)
                }
            }
            setDisplayCurriculumAcademicCycles(tempList)
        } else {
            setDisplayCurriculumAcademicCycles(props.curriculumAcademicCycles);
        }

    }, [props.curriculumAcademicCycles, props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId, props.selectedCurriculum])


    console.log(props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId)
    console.log(props.curriculumAcademicCycles)
    return (
        <React.Fragment>
            <div className="page-content">
                <TopBar
                    title="Class Wise Syllabus   "
                    buttonName="Add Class for Syllabus"
                    backButton={true}
                    hideButton={canEdit ? hideButton : true}
                    onClickAdd={handelShowAddCycleYear}
                    onClickBackButton={handelOnClickBack}
                    reorderButton={canEdit ? true : false}
                    onClickReorder={handelReorder}
                />

                {selectedTab == "Loading" && <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
                        </div>
                    </Col>
                </Row>}


                {selectedTab === "CycleList" && <div >

                    <Row >
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">

                                    <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="col-1" scope="col" style={{ width: "100px" }}>#</th>
                                                <th className="col-9" scope="col">{props.t('Name')}</th>
                                                {schoolId == "franchise" && <th className="col-2" scope="col" align="right">{props.t('Access')}</th>}
                                                <th className="col-2" scope="col" align="right">{props.t('Action')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {getCycleList().map((cycle, key) =>

                                                <tr key={cycle.id} >

                                                    <td><img src={classimge} alt="" className="avatar-sm" /></td>
                                                    {
                                                        editCycle && editCycle.id === cycle.id ?
                                                            <td>
                                                                <AvForm className="outer-repeater mt-3" >
                                                                    <div data-repeater-item className="outer">
                                                                        <FormGroup >
                                                                            <AvField
                                                                                type="text"
                                                                                name="name"
                                                                                placeholder={props.t('Cycle Name')}
                                                                                className="form-control"
                                                                                value={newCycleYear}

                                                                                onChange={(e) => {
                                                                                    if (e.target.value.length > 0 && nameError) {
                                                                                        setNameError(false);
                                                                                    }
                                                                                    setNewCycleYear(e.target.value);
                                                                                }}
                                                                            />
                                                                            {nameError && <p className="text-danger"> {props.t('Cycle name can not be empty')}</p>}
                                                                        </FormGroup>
                                                                    </div>
                                                                </AvForm>
                                                            </td>
                                                            :
                                                            <td
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    onClickItem(cycle.id);
                                                                }}>
                                                                <h5 className="text-truncate font-size-14 text-dark">{cycle.id === "CommonSubjects" ? props.t('No Academic Cycle Mapping Subjects') : cycle.attributes.schoolCycle}{" "}{props.t('Syllabus')}</h5>


                                                            </td>
                                                    }
                                                    {schoolId == "franchise" && <td>
                                                        {props.selectedCurriculum && props.selectedCurriculum.attributes.accessBased === true && cycle.id !== "CommonSubjects" && canEdit && (selectedTab === "CycleList") && <Link to={`/syllabus-access/${Parse.User.current().attributes.franchiseId}/${curriculumId}/${cycle.id}`}>
                                                            <div className="d-flex" >
                                                                <button className='btn btn-success btn-sm btn-rounded' onClick={() => {

                                                                }}>
                                                                    Access
                                                                </button>
                                                            </div>
                                                        </Link>}
                                                    </td>}
                                                    <td>
                                                        {
                                                            editCycle && editCycle.id === cycle.id ?
                                                                <div className="list-inline font-size-20 contact-links mb-0">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary m-1 "
                                                                        onClick={() => {
                                                                            if (newCycleYear && newCycleYear.length > 0) {
                                                                                var cur = editCycle;
                                                                                cur.set("schoolCycle", newCycleYear);
                                                                                props.addAcademicCycle([cur]);
                                                                                setEditCycle(undefined);
                                                                                setNewCycleYear("");
                                                                                setNameError(false);
                                                                            } else {
                                                                                setNameError(true);
                                                                            }
                                                                        }}  >
                                                                        {props.t('Save')}
                                                                    </button>
                                                                    <button type="button"
                                                                        className="btn btn-danger m-1"
                                                                        onClick={() => {
                                                                            setEditCycle(undefined);
                                                                            setNewCycleYear("");
                                                                            setNameError(false);
                                                                        }}  >
                                                                        {props.t('Close')}

                                                                    </button>
                                                                </div>
                                                                :
                                                                <ul className="list-inline font-size-20 contact-links mb-0 float-right">

                                                                    {cycle.id !== "CommonSubjects" && canEdit && <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setEditCycle(cycle);
                                                                            setNewCycleYear(cycle.attributes.schoolCycle);
                                                                        }}
                                                                        id={"sub-edit" + key}>
                                                                        <i className="bx bx-edit-alt"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-edit" + key}>
                                                                            {props.t('Edit')}
                                                                        </UncontrolledTooltip>

                                                                    </li>}
                                                                    {cycle.id !== "CommonSubjects" && canEdit && <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setDeleteCycle(cycle);
                                                                        }}
                                                                        id={"sub-delete" + key}>
                                                                        <i className="bx bx-trash text-danger"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                            {props.t('Delete')}
                                                                        </UncontrolledTooltip>

                                                                    </li>}
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            onClickItem(cycle.id);
                                                                        }}
                                                                        id={"sub-cyc" + key}>
                                                                        <i className="bx bx-right-arrow-circle"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                            {props.t('Details')}
                                                                        </UncontrolledTooltip>
                                                                    </li>

                                                                </ul>
                                                        }
                                                    </td>
                                                </tr>)}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>

                    </Row>
                </div>
                }


                {selectedTab === "AddCycleYear" &&
                    <div className="row ">
                        <Col >

                            <Col >
                                {isCustomClass ? <Card>

                                    <CardBody>
                                        <Element name="AddCycleYear">
                                            <AvForm className="outer-repeater" >
                                                <div data-repeater-item className="outer">
                                                    <FormGroup >
                                                        <Label htmlFor="formname">{props.t('Custom Class for syllabus')}: </Label>
                                                        <AvField
                                                            type="text"
                                                            name="name"
                                                            placeholder={props.t('Custom Class...')}
                                                            className="form-control"
                                                            value={newCycleYear}
                                                            validate={{
                                                                required: {
                                                                    value: true,

                                                                },
                                                            }}
                                                            onChange={(e) => {
                                                                if (e.target.value.length > 0) {
                                                                    setNameError(false);
                                                                }
                                                                setNewCycleYear(e.target.value);
                                                            }}
                                                        />
                                                        {nameError && <p className="text-danger"> {props.t('Class name can not be empty')}</p>}
                                                    </FormGroup>
                                                </div>
                                            </AvForm>



                                            <button
                                                onClick={() => {
                                                    setIsCustomClass(false);

                                                }}
                                                type="button"
                                                className="btn btn-secondary waves-effect waves-light m-2 ">
                                                {props.t('Close')}
                                            </button>


                                            {loading2 ?
                                                <button type="button" className="btn btn-dark waves-effect waves-light float-right m-2">
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                                                </button>
                                                :
                                                <button
                                                    onClick={() => {
                                                        if (!newCycleYear || newCycleYear.length === 0) {
                                                            setNameError(true);
                                                        } else {
                                                            setLoading2(true);
                                                            handelAddCycleYearFromInput(newCycleYear);
                                                            setNewCycleYear("");
                                                            setSelectedTab("CycleList");
                                                            setHideButton(false);
                                                            setSelectedId(undefined);
                                                        }

                                                    }}
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light float-right ">
                                                    <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save')}


                                                </button>}

                                        </Element>
                                    </CardBody>
                                </Card>
                                    :

                                    <button
                                        onClick={() => {
                                            setIsCustomClass(true);

                                        }}
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light m-2 ">
                                        {props.t('Add Custom Class for Syllabus')}
                                    </button>

                                }


                            </Col>

                            {!isCustomClass && <div>


                                <div>
                                    <h4 style={{ marginLeft: '20px' }}>{props.t('OR')}</h4>
                                    <p style={{ marginLeft: '10px' }} className="text-muted ml-4">{props.t('Please pick from below')}</p>
                                    <u><p style={{ marginLeft: '10px' }} className="text-dark mr-7">{props.t('Cycles are based on National Education Policy (NEP) 2020')} </p></u>
                                </div>


                                <Row className="justify-content-center m-2">

                                    {cyclicYears.map((cycle) => (

                                        <Col xl="4" sm="4" key={cycle.id}>

                                            <Card
                                                outline
                                                color="primary"
                                                className="border  mt-2" style={{ 'height': '5.5cm' }}

                                            >

                                                <CardBody>

                                                    {/* <div className="custom-control custom-checkbox mt-2 ml-3">
                                                    <Input type="checkbox" value="2" className="custom-control-input" onChange= {()=>(handelWholeCycle(cycle))} id={cycle.id} checked={selectedAll(cycle)}/>
                                                    <Label className="custom-control-label" htmlFor={cycle.id}>{cycle.title}</Label>
                                                </div> */}

                                                    <Label >{cycle.title}</Label>

                                                    <div className="pt-2">
                                                        {cycle.c.map((year) => (
                                                            <div className="custom-control custom-checkbox" style={{ marginTop: '-10px' }} key={year.id}>

                                                                <Row>
                                                                    <Col xs="1">
                                                                        <div>
                                                                            <Input type="checkbox" value="2" className="custom-control-input ml-1" id={year.id} onChange={() => (handelCycle(year))} checked={selectedCycles.includes(year.id) ? true : false || selectedId.includes(year.id) ? true : false} disabled={year.parseObject ? true : false} />
                                                                            <Label className="custom-control-label" htmlFor={year.id}></Label>
                                                                        </div>
                                                                    </Col>
                                                                    <Col>
                                                                        <Editable
                                                                            className={'d-flex'}
                                                                            alwaysEditing={false}
                                                                            onValidated={(value) => {
                                                                                var list = [...cyclicYears];
                                                                                list[cycle.i].c[year.i].renamed = value;
                                                                                setCyclicYears(list);
                                                                                console.log(list);

                                                                            }}
                                                                            onCancel={(v) => {
                                                                                var list = [...cyclicYears];
                                                                                list[cycle.i].c[year.i].renamed = undefined;
                                                                                setCyclicYears(list);
                                                                                console.log(list);
                                                                            }}
                                                                            disabled={false}
                                                                            editText={year.parseObject ? "" : " ✎"}
                                                                            id={null}
                                                                            isValueClickable={false}
                                                                            mode="inline"
                                                                            placement="top"
                                                                            initialValue={year.renamed && year.renamed.length > 0 ? year.renamed : year.year}
                                                                            showText
                                                                            type="textfield"
                                                                            validate={(value) => {
                                                                                if (!value) {
                                                                                    return props.t("can not be empty")
                                                                                }
                                                                            }}

                                                                        />
                                                                    </Col>
                                                                </Row>

                                                            </div>))
                                                        }
                                                    </div>
                                                </CardBody>

                                            </Card>
                                        </Col>

                                    ))}
                                </Row>
                                <Element name="toNext">
                                    <div className="d-flex justify-content-end mr-2 mb-2">
                                        {cycleYearError && <p className="text-danger "> {props.t('Please select Class')}</p>}
                                        {loading ? <button type="button" className="btn btn-dark waves-effect waves-light float-right m-2">
                                            <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                                        </button>
                                            :
                                            <button type="button"
                                                className="btn btn-primary float-right "
                                                onClick={() => {

                                                    if (selectedId && selectedId.length > 0) {
                                                        var list = getNamesForIds();
                                                        var newList = [];
                                                        for (const i in list) {
                                                            newList.push({ lil: list[i].lil, school: list[i].school })
                                                        }
                                                        setLoading(true);
                                                        handelAddCycleYearFromList(newList);

                                                    } else {
                                                        setCycleYearError(true);
                                                    }
                                                }}  >
                                                <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save')}
                                            </button>}
                                    </div>
                                </Element>


                            </div>}



                        </Col>




                    </div>}

                {
                    selectedTab === "rearrange" && <DragDropTables rearrangeObjects={props.curriculumAcademicCycles} updatedOrderList={handelSort} onClickClose={handelOnClickBack} />
                }

                {deleteCycle ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            props.deleteAcademicCycle(deleteCycle);
                            setDeleteCycle(undefined);
                            setsuccess_dlg(true);
                        }}
                        onCancel={() => {
                            setDeleteCycle(undefined);
                            setsuccess_dlg(false);

                        }}
                    >
                        {props.t("You won't be able to revert this!")}
                    </SweetAlert>
                ) : null}
                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t('Deleted')}
                        onConfirm={() => {
                            setsuccess_dlg(false)
                        }}
                    >
                        {props.t('Your cycle year (class) has been deleted.')}
                    </SweetAlert>
                ) : null}

            </div>
        </React.Fragment>
    )
}

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { curriculums, initialLoding, selectedCurriculum } = state.Curriculum;
    const { curriculumAcademicCycles, loadState } = state.AcademicCycles;
    const { userTeacher } = state.Login;
    const { learningCurriculumsSchoolMapForCurriculumIdAndSchoolId } = state.LearningCurriculumsSchoolMap;
    return { schools, selectedSchool, curriculums, initialLoding, selectedCurriculum, curriculumAcademicCycles, loadState, userTeacher, learningCurriculumsSchoolMapForCurriculumIdAndSchoolId };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool,
    getAcademicCycleForCurriculumId, setSelectedCurriculum,
    addAcademicCycle, deleteAcademicCycle, reorderCycles,
    getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId
})(CycleYears)));
