import { AvField, AvForm } from "availity-reactstrap-validation"
import { useEffect, useState } from "react";
import { Alert, Col, FormGroup, Label, Modal, ModalBody, ModalFooter, ModalHeader, Row } from "reactstrap"
import UpdatePhoto from "../../components/Common/UpdatePhoto";
import toastr from "toastr";
import Parse from "parse";

const AddEscortModal = (props) => {

    const [name, setname] = useState('');
    const [nameError, setnameError] = useState(false);
    const [relation, setrelation] = useState('');
    const [relationError, setrelationError] = useState(false);
    const [mobile, setmobile] = useState('');
    const [mobileError, setmobileError] = useState(false);
    const [imageParseFile, setimageParseFile] = useState();
    const [imageError, setimageError] = useState(false);
    const [image, setimage] = useState();
    const [shouldDisableTextFields, setshouldDisableTextFields] = useState(false);
    const [isSaving, setisSaving] = useState(false);
    const [isNewImageSelected, setisNewImageSelected] = useState(false);

    useEffect(() => {
        if (props.escortObject) {
            fillInitialValues(props.escortObject)
        } else {
            fillInitialValues(props.escortObject)
        }
    }, [props.escortObject])

    const fillInitialValues = (escortObject) => {
        setisSaving(false)
        if (escortObject && escortObject.className === "Parent") {
            setshouldDisableTextFields(true)
            let ea = escortObject.attributes
            setname(ea.Name)
            setrelation(ea.Relation)
            setmobile(ea.PhoneNumber)
            setimageParseFile(ea.Photo)
            setimage(ea.Photo ? ea.Photo._url : undefined)

        } else if (escortObject) {
            setshouldDisableTextFields(false)

            let ea = escortObject.attributes
            setname(ea.PersonName)
            setrelation(ea.Relation)
            setmobile(ea.MobileNumber)
            setimageParseFile(ea.Photo)
            setimage(ea.Photo ? ea.Photo._url : undefined)
            setshouldDisableTextFields(false)

        }
        else {
            setshouldDisableTextFields(false)
            setname('')
            setrelation('')
            setmobile('')
            setimageParseFile(undefined)
            setimage(undefined)
            setshouldDisableTextFields(false)

        }

    }

    const addEscortWithDetails = (escortObject) => {

        if (escortObject.parseObject && escortObject.parseObject.className === "Parent") {
            if (isNewImageSelected) {
                imageParseFile.save().then(
                    (result) => {
                        escortObject.parseObject.set("PhoneNumber", mobile)
                        escortObject.parseObject.set("Name", name)
                        escortObject.parseObject.set("Photo", result)
                        console.log(escortObject.parseObject)
                        setisSaving(true)
                        escortObject.parseObject.save().then(
                            (result) => {
                                setisSaving(false)
                                props.closeModal(result)
                            },
                            (error) => {
                                setisSaving(false)
                                toastr.warning("Error while saving, please refresh and try again")
                                props.closeModal()
                            }
                        );
                    },
                    (error) => {

                    }
                );
            } else {
                escortObject.parseObject.set("PhoneNumber", mobile)
                escortObject.parseObject.set("Name", name)
                setisSaving(true)
                escortObject.parseObject.save().then(
                    (result) => {
                        setisSaving(false)
                        props.closeModal(result)
                    },
                    (error) => {
                        setisSaving(false)
                        toastr.warning("Error while saving, please refresh and try again")
                        props.closeModal()
                    }
                );

            }

        } else {
            if (isNewImageSelected) {
                imageParseFile.save().then(
                    (result) => {
                        savePickNDropNewObject(escortObject, result)
                    },
                    (error) => {
                        setisSaving(false)
                        toastr.warning("Error while saving, please refresh and try again")
                        props.closeModal()
                    }
                );

            } else {
                savePickNDropNewObject(escortObject, imageParseFile)
            }
        }
    }

    const savePickNDropNewObject = (escortObject, imageParseFile) => {
        var pickNDrop = escortObject.parseObject
        if (!pickNDrop) {
            const PickNDrop = Parse.Object.extend('PickNDrop');
            pickNDrop = new PickNDrop();
            pickNDrop.set("KidID", props.kidId)
            pickNDrop.set("Type", 1)
        }
        pickNDrop.set("PersonName", escortObject.name)
        pickNDrop.set("MobileNumber", escortObject.mobile)
        pickNDrop.set("Relation", escortObject.relation)
        pickNDrop.set("Photo", imageParseFile)
        setisSaving(true)
        pickNDrop.save().then(
            (result) => {
                setisSaving(false)
                props.closeModal(result)
            },
            (error) => {
                setisSaving(false)
                toastr.warning("Error while saving, please refresh and try again")
                props.closeModal()
            }
        );
    }

    const verifyMandatoryFields = () => {
        let returnValue = true
        if (name && name.trim().length > 0) {
            setnameError(false)
        } else {
            setnameError(true)
            returnValue = false
        }
        if (relation && relation.trim().length > 0) {
            setrelationError(false)
        } else {
            setrelationError(true)
            returnValue = false
        }
        if (mobile && mobile.trim().length > 0) {
            setmobileError(false)
        } else {
            setmobileError(true)
            returnValue = false
        }
        if (imageParseFile) {
            setimageError(false)
        } else {
            setimageError(true)
            returnValue = false
        }
        return returnValue
    }

    return (
        <div>
            <Modal isOpen={props.isOpen}>
                <ModalHeader toggle={() => { props.closeModal() }}>{props.escortObject ? "Edit Escort" : "Add Escort"}</ModalHeader>
                <ModalBody>
                    <AvForm>
                        <Row>
                            <Col lg={12} md={12} xs={12} className="text-center">
                                <div className="border p-2">
                                    {imageError && (
                                        <Label className="label-xsmall text-danger ml-2">
                                            (Photo is mandatory)
                                        </Label>
                                    )}
                                    {!image ? (
                                        <div
                                            className="avatar-lg mx-auto mb-2"
                                            style={{ width: "10rem", height: "10rem" }}
                                        >
                                            <span
                                                className={
                                                    "avatar-title rounded-circle bg-soft-primary text-primary"
                                                }
                                                style={{ fontSize: "60px" }}
                                            >
                                                {name.charAt(0).toUpperCase()}
                                            </span>
                                        </div>
                                    ) : (
                                        <div>
                                            <img
                                                style={{ width: "10rem", height: "10rem" }}
                                                className="rounded-circle avatar mb-2"
                                                src={image}
                                                alt=""
                                            />
                                        </div>
                                    )}

                                    <UpdatePhoto
                                        className="p-1"
                                        isPopupOn={false}
                                        onPicChange={(imageFile, cr) => {
                                            setisNewImageSelected(true)
                                            setimageParseFile(imageFile)
                                            setimage(cr)
                                        }}
                                        initialImage={image}
                                    ></UpdatePhoto>
                                </div>
                            </Col>
                        </Row>
                        <FormGroup className="mt-2">
                            <Label htmlFor="studentName">{'Name'}:</Label>
                            <Label htmlFor="studentName" className="text-danger">
                                *
                            </Label>

                            {nameError && (
                                <Label className="label-xsmall text-danger ml-2">
                                    (Name is mandatory)
                                </Label>
                            )}
                            <AvField
                                type="text"
                                name="studentName"
                                placeholder={'Enter Name...'}
                                className="form-control"
                                value={name}
                                onChange={(e) => {
                                    setname(e.target.value);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="relation">{'Relation'}:</Label>
                            <Label htmlFor="relation" className="text-danger">
                                *
                            </Label>

                            {relationError && (
                                <Label className="label-xsmall text-danger ml-2">
                                    (Relation is mandatory)
                                </Label>
                            )}
                            <AvField
                                disabled={shouldDisableTextFields}

                                type="text"
                                name="relation"
                                placeholder={'Enter Relation...'}
                                className="form-control"
                                value={relation}
                                onChange={(e) => {
                                    setrelation(e.target.value);
                                }}
                            />
                        </FormGroup>
                        <FormGroup>
                            <Label htmlFor="mobile">{'Mobile'}:</Label>
                            <Label htmlFor="mobile" className="text-danger">
                                *
                            </Label>

                            {mobileError && (
                                <Label className="label-xsmall text-danger ml-2">
                                    (Phone number is mandatory)
                                </Label>
                            )}
                            <AvField

                                type="text"
                                name="mobile"
                                placeholder={'Enter Mobile Number...'}
                                className="form-control"
                                value={mobile}
                                onChange={(e) => {
                                    setmobile(e.target.value);
                                }}
                            />
                        </FormGroup>
                        {shouldDisableTextFields && <Alert color="danger">
                            When you update details here, it will reflect for parents
                        </Alert>}
                    </AvForm>
                </ModalBody>
                <ModalFooter>
                    <button
                        className="btn btn-primary"
                        disabled={isSaving}
                        onClick={() => {
                            setisSaving(true)
                            if (verifyMandatoryFields()) {
                                let temp = {
                                    name: name,
                                    relation: relation,
                                    mobile: mobile,
                                    imageParseFile: imageParseFile
                                }
                                if (props.escortObject) {
                                    temp.parseObject = props.escortObject
                                }
                                addEscortWithDetails(temp)
                            } else {
                                setisSaving(false)
                            }
                        }}>
                        {isSaving && <i className="bx bx-loader bx-spin mr-2 align-middle"></i>}
                        {props.escortObject ? (isSaving ? "Saving..." : "Save") : (isSaving ? "Adding..." : "Add")}
                    </button>
                </ModalFooter>
            </Modal>
        </div>
    )
}
export default AddEscortModal