import * as actions from "./actionTypes";

export const updateLearningCategory = (learningCategory) => {
  return {
    type: actions.UPDATE_LEARNING_CATEGORY,
    payload: { learningCategory },
  };
};

export const deleteLearningCategory = (learningCategory) => {
  return {
    type: actions.DELETE_LEARNING_CATEGORY,
    payload: {learningCategory},
  };
};

export const updateLearningCategorys = (learningCategories,type) => {
  return {
    type: actions.UPDATE_LEARNING_CATEGORYS,
    payload: { learningCategories,type },
  };
};

export const getLearningCategoryForCurriculumId = (curriculumId) => {
  return {
    type: actions.GET_LEARNING_CATEGORY_FOR_CURRICULUM_ID,
    payload: { curriculumId },
  };
};

export const getLearningCategoryForSchoolId = (schoolId) => {
    return {
      type: actions.GET_LEARNING_CATEGORYS,
      payload: { schoolId },
    };
};
export const getLearningCategoryForCycleId = (cycleId) => {
  return {
    type: actions.GET_LEARNING_CATEGORYS_FOR_CYCLE,
    payload: { cycleId },
  };
};

export const addLearningCategory = (learningCategory) => {
  return {
    type: actions.ADD_LEARNING_CATEGORY,
    payload: { learningCategory },
  };
};

export const clearLearningCategory = () => {
  return {
    type: actions.CLEAR_LEARNING_CATEORIES,
    payload: {  },
  };
};

export const getSelectedLearningCategory = (id) => {
  return {
    type: actions.GET_SELECTED_LEARNING_CATEGORY,
    payload: { id },
  };
};

export const setSelectedLearningCategory = (learningCategory) => {
  return {
    type: actions.SET_SELECTED_LEARNING_CATEGORY,
    payload: { learningCategory },
  };
};

export const setLearningCategoryLoading = (state) => {
  return {
    type: actions.SET_LEARNING_CATEGORY_LOADING_STATE,
    payload: { state },
  };
};

export const reorderCatrgories = (learningCategories,message,courseId) => {
  return {
    type: actions.LEARNING_CATEGORY_REORDER,
    payload: { learningCategories,message,courseId },
  };
};

export const getLearningCategoryForCourseId = (courseId) => {
  return {
    type: actions.GET_LEARNING_CATEGORYS_FOR_COURSE,
    payload: { courseId },
  };
};

export const getLearningCategoriesForIds = (ids) => {
  return {
    type: actions.GET_LEARNING_CATEGORIES_FOR_IDS,
    payload: { ids },
  }
}
