import React, { useEffect, useState } from "react";
import {
  Col,
  Row,
  Label,
  FormGroup,
  Button,
  Badge,
  UncontrolledTooltip,
} from "reactstrap";
import toastr from "toastr";
import Parse from "parse";
import { AvForm } from "availity-reactstrap-validation";
import Switch from "react-switch";

import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";
import SweetAlert from "react-bootstrap-sweetalert";
import * as Constants from "../../Constents";
import Editable from "react-bootstrap-editable";

const FormQuestion = (props) => {
  const [addQuestionView, setAddQuestionView] = useState(false);
  const [selectedQuestionToRemove, setSelectedQuestionToRemove] = useState(
    undefined
  );
  const [creatingQuestion, setCreatingQuestion] = useState(false);

  const [editQuestionObject, setEditQuestionObject] = useState(undefined);
  const [isMandatory, setIsMandatory] = useState(false);

  const [questionTitle, setQuestionTitle] = useState(undefined);
  const [questionType, setQuestionType] = useState(
    Constants.QESTION_TEXT_SINGLE_LINE
  );
  const [questionForDuplicating, setQuestionForDuplicating] = useState(undefined)

  const [tags, setTags] = useState([]);
  const [addNewTagsView, setAddNewTagsView] = useState(false);
  const [newTag, setNewTag] = useState("");

  const [questionError, setQuestionError] = useState(false);
  const [tagError, setTagError] = useState(false);

  const getQuestionAcceptType = (questionType) => {
    if (
      questionType === Constants.QESTION_TEXT_SINGLE_LINE ||
      questionType === Constants.QESTION_TEXT_MULTI_LINE
    ) {
      return props.t("Accepts Text");
    } else if (questionType === Constants.QESTION_NUMBER) {
      return props.t("Accepts Numbers");
    } else if (questionType === Constants.QESTION_DATE) {
      return props.t("Accepts Date");
    } else if (questionType === Constants.QESTION_UPLOAD_IMAGE) {
      return props.t("Accepts photo") + " (jpg, png etc)";
    } else if (questionType === Constants.QESTION_UPLOAD_DOCUMENT) {
      return props.t("Accepts Document") + " (pdf, doc, ppt, jpg, png, xlsx..)";
    } else if (questionType === Constants.QUESTION_EMAIL) {
      return props.t("Accepts Email");
    } else if (questionType === Constants.QUESTION_PHONE_NUMBER) {
      return props.t("Accepts Phone Number");
    } else if (
      questionType === Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST ||
      questionType === Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      return props.t("Accepts Choice");
    } else if (
      questionType === Constants.QESTION_YES_NO_WITH_COMMENT ||
      questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO ||
      questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES ||
      questionType === Constants.QESTION_YES_NO_NO_COMMENTS ||
      Constants.QESTION_CHECKBOX
    ) {
      return props.t("Accepts Yes or No");
    }
  };

  const Offsymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        No
      </div>
    );
  };

  const OnSymbol = (props) => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2,
        }}
      >
        {" "}
        Yes
      </div>
    );
  };

  const handleDuplicateQuesiton = (question) => {

    if (question.parseObject) {
      let questionParseObject = question.parseObject
      setCreatingQuestion(true);

      let FormQuestions = Parse.Object.extend("FormQuestions");
      let formQuestion = new FormQuestions();
      formQuestion.set("formId", questionParseObject.attributes.formId);
      formQuestion.set("question", questionParseObject.attributes.question);
      formQuestion.set("questionType", questionParseObject.attributes.questionType);
      formQuestion.set("choices", questionParseObject.attributes.choices);
      formQuestion.set("sectionId", questionParseObject.attributes.sectionId);
      formQuestion.set("isMandatory", questionParseObject.attributes.isMandatory);
      formQuestion.set("isDefaultQuestion", questionParseObject.attributes.isDefaultQuestion)
      formQuestion.set("order", questionParseObject.attributes.order);
      formQuestion.set("isDeleted", questionParseObject.attributes.isDeleted);
      formQuestion.set("dataType", questionParseObject.attributes.dataType);




      formQuestion.save().then((result) => {
        let tempQuestionObj = {
          id: result.id,
          questionTitle: result.attributes.question,
          questionType: result.attributes.questionType,
          isMandatory: result.attributes.isMandatory,
          choices: result.attributes.choices,
          parseObject: result,
          isDefaultQuestion: result.attributes.isDefaultQuestion
        };
        setAddQuestionView(false);
        setCreatingQuestion(false);
        props.handleAddNewQuestion(tempQuestionObj);
      })






    }





  }

  const getQuestionUI = (question) => {
    return (
      <Row key={question.id} className="border-bottom ml-4">
        {/* <Col lg="1" md="1" sm="12" className="mt-3 mb-2">
          <div className="custom-control custom-checkbox mb-2 ml-5">
            <input
              type="checkbox"
              className="custom-control-input"
              id={"checkboxToRemove" + question.id}
              onChange={() => false}
              checked={true}
            />
            <label
              className="custom-control-label"
              onClick={() => {
                setSelectedQuestionToRemove(question);
              }}
            ></label>
          </div>
        </Col> */}
        <Col lg="8" md="8" sm="12" className="mt-3 mb-2">
          {editQuestionObject && question.id === editQuestionObject.id ? (
            <div>{getQuestionEditView(question)}</div>
          ) : (
            <div>
              <h5
                className=" font-size-14 m-0"
                style={{ whiteSpace: "pre-wrap" }}
              >
                <Label className="text-dark">
                  {/* {console.log(question.questionTitle)} */}
                  {question.questionTitle}
                </Label>
              </h5>
              <p className="m-0">
                {" "}
                {props.t("Mandatory Question") + ": "}
                {question.isMandatory ? props.t("YES") : props.t("NO")}
              </p>
              {(question.questionType === 5 || question.questionType === 6) && (
                <FormGroup>
                  <Label>{props.t("Choices")}: </Label>
                  {question.choices &&
                    question.choices.map((option, key) => (
                      <Badge
                        key={key}
                        className={"badge badge-soft-primary font-size-12 m-1"}
                      >
                        {option.length > 50
                          ? option.slice(0, 49) + "..."
                          : option}{" "}
                      </Badge>
                    ))}
                </FormGroup>
              )}
              <p className="text-muted mt-1">
                {getQuestionAcceptType(question.questionType)}
              </p>
            </div>
          )}
        </Col>

        <Col lg="4" md="4" sm="12" className="mt-2 mb-2">
          <Row>
            {!editQuestionObject && (
              <ul className="list-inline font-size-20 contact-links  mb-0 m-2">
                <li className="list-inline-item px-2"></li>
              </ul>
            )}
            <Col className="d-flex justify-content-end">
              {editQuestionObject && question.id === editQuestionObject.id ? (
                <Button
                  type="button"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    setEditQuestionObject(undefined);
                    setIsMandatory(false);
                    setTags([]);
                    setAddNewTagsView(false);
                    setNewTag("");
                    setQuestionError(false);
                    setTagError(false);
                  }}
                  className="btn-rounded waves-effect waves-light m-2 float-right "
                >
                  {props.t("Close")}
                </Button>
              ) : null}
              {/* {!editQuestionObject && !question.isDefaultQuestion && (
                <Button
                  type="button"
                  color="primary"
                  size="sm"
                  disabled={creatingQuestion}
                  onClick={() => {
                    setQuestionForDuplicating(question)
                    handleDuplicateQuesiton(question)

                  }}
                  className="btn-rounded waves-effect waves-light m-2 float-right"
                >
                  {props.t("Duplicate")}
                  {creatingQuestion && questionForDuplicating && question.id === questionForDuplicating.id && (
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                  )}
                </Button>
              )} */}

              {!(editQuestionObject && question.id === editQuestionObject.id) && <ul className="list-inline font-size-18 contact-links float-right m-2 mr-3">
                {!question.isDefaultQuestion && <li
                  className="list-inline-item px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!creatingQuestion) {
                      setQuestionForDuplicating(question)
                      handleDuplicateQuesiton(question)
                    } else {
                      toastr.warning("updating question, please try again !!")
                    }
                  }}
                  disabled={creatingQuestion}
                  id={"duplicate" + question.id}
                >
                  {!(creatingQuestion && questionForDuplicating && question.id === questionForDuplicating.id) && <i className="bx bx-copy"></i>}

                  {creatingQuestion && questionForDuplicating && question.id === questionForDuplicating.id && (
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                  )}

                  <UncontrolledTooltip
                    placement="top"
                    target={"duplicate" + question.id}
                  >
                    {props.t("Duplicate")}
                  </UncontrolledTooltip>
                </li>}

                <li
                  className="list-inline-item px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (addQuestionView === true) {
                      resetVariables();
                      setAddQuestionView(false);
                    }

                    setTags(question.choices);
                    setEditQuestionObject(question);
                    setQuestionTitle(question.questionTitle);
                    setQuestionType(question.questionType);
                    setIsMandatory(question.isMandatory);
                    setQuestionError(false);
                    setTagError(false);
                    setAddNewTagsView(false);

                  }}
                  id={"edit" + question.id}
                >
                  <i className="bx bx-edit-alt"></i>
                  <UncontrolledTooltip
                    placement="top"
                    target={"edit" + question.id}
                  >
                    {props.t("Edit")}
                  </UncontrolledTooltip>
                </li>
                <li
                  className="list-inline-item px-2"
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    if (!creatingQuestion) {
                      setSelectedQuestionToRemove(question);

                    } else {
                      toastr.warning("updating question, please try again !!")
                    }
                  }}
                  disabled={creatingQuestion}
                  id={"delete1" + question.id}
                >
                  {!(creatingQuestion && selectedQuestionToRemove && question.id === selectedQuestionToRemove.id) && <i className="bx bx-trash text-danger"></i>}
                  {creatingQuestion && selectedQuestionToRemove && question.id === selectedQuestionToRemove.id && (
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1 text-danger"></i>
                  )}
                  <UncontrolledTooltip
                    placement="top"
                    target={"delete1" + question.id}
                  >
                    {props.t("Delete")}
                  </UncontrolledTooltip>
                </li>
              </ul>}


              {editQuestionObject && question.id === editQuestionObject.id &&
                <Button
                  onClick={(e) => {
                    let canProceed = true;
                    if (
                      editQuestionObject &&
                      question.id === editQuestionObject.id
                    ) {
                      if (
                        questionTitle === undefined ||
                        questionTitle === null ||
                        questionTitle.length === 0
                      ) {
                        canProceed = false;
                        setQuestionError(true);
                      }
                      if (
                        (question.questionType === 5 ||
                          question.questionType === 6) &&
                        tags.length === 0
                      ) {
                        canProceed = false;
                        setTagError(true);
                      }
                      if (canProceed) {
                        handleEditQuestion(e);
                      }
                    }
                  }}
                  type="button"
                  color="primary"
                  size="sm"
                  disabled={creatingQuestion}
                  className="btn-rounded waves-effect waves-light m-2 float-right"
                >
                  {creatingQuestion && editQuestionObject && question.id === editQuestionObject.id && (
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                  )}
                  {props.t("Save")}
                </Button>
              }



            </Col>
          </Row>
        </Col>
      </Row>
    );
  };

  const resetVariables = () => {
    setQuestionType(1);
    setQuestionTitle(undefined);
    setIsMandatory(false);
    setQuestionError(false);
    setTags([]);
    setTagError(false);
    setAddNewTagsView(false);
    setNewTag("");
  };

  function getQuestionEditView(question) {
    return (
      <div>
        <AvForm className="outer-repeater">
          <div data-repeater-item className="outer">
            <FormGroup>
              <Label style={{ fontSize: "15px" }}>
                {!editQuestionObject
                  ? props.t("Add New Question")
                  : props.t("Edit Question")}
              </Label>
              <br />
              {!question.isDefaultQuestion && (
                <FormGroup>
                  <Label>{props.t("Question Answer Type")}: </Label>
                  <br />
                  <select
                    className="form-select col-lg-6 mb-2"
                    id={"editQuestionType" + props.keyForQuestion}
                    value={questionType}
                    onChange={(e) => {
                      setQuestionType(parseInt(e.target.value, 10));
                    }}
                  >
                    <option
                      value={Constants.QESTION_TEXT_SINGLE_LINE}
                      defaultValue
                    >
                      Single Line Answer
                    </option>
                    <option value={Constants.QESTION_TEXT_MULTI_LINE}>
                      Multi Line Answer
                    </option>

                    <option value={Constants.QESTION_NUMBER}>Number</option>
                    <option value={Constants.QESTION_YES_NO_NO_COMMENTS}>
                      Yes No
                    </option>

                    <option
                      value={
                        Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
                      }
                    >
                      Single selection from list of options
                    </option>
                    <option
                      value={Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST}
                    >
                      Multi selection from list of options
                    </option>

                    <option value={Constants.QESTION_DATE}>Date</option>

                    <option value={Constants.QESTION_YES_NO_COMMENT_ON_YES}>
                      Yes or No (with additional text if Yes)
                    </option>

                    <option value={Constants.QESTION_YES_NO_COMMENT_ON_NO}>
                      Yes or No (with additional text if No)
                    </option>

                    <option value={Constants.QUESTION_EMAIL}>Email Id</option>
                    <option value={Constants.QUESTION_PHONE_NUMBER}>
                      Phone/Mobile Number
                    </option>
                    <option value={Constants.QESTION_CHECKBOX}>
                      Simple Checkbox
                    </option>
                    <option value={Constants.QESTION_UPLOAD_IMAGE}>
                      Profile Picture Upload
                    </option>
                    <option value={Constants.QESTION_UPLOAD_DOCUMENT}>
                      Documents Upload
                    </option>
                  </select>
                </FormGroup>
              )}

              <Label>{props.t("Question Title")}: </Label>
              <textarea
                type="text"
                name="editQuestionName"
                placeholder={props.t("Enter Question Title...")}
                className="form-control"
                value={questionTitle}
                onChange={(e) => {
                  if (e.target.value.length > 0) {
                    setQuestionError(false);
                  }
                  setQuestionTitle(e.target.value);
                }}
              />
              {questionError && (
                <p className="text-danger">
                  {props.t("Question Title can not be empty")}
                </p>
              )}
            </FormGroup>

            {!question.isDefaultQuestion && (
              <FormGroup>
                <Label htmlFor="formname">{props.t("Mandatory")}: </Label>
                <Switch
                  className="ml-3"
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor="#626ed4"
                  onChange={() => {
                    setIsMandatory(!isMandatory);
                  }}
                  checked={isMandatory}
                />
              </FormGroup>
            )}
            {(questionType === 5 || questionType === 6) && (
              <FormGroup>
                <Label>{props.t("Choices")}: </Label>
                {tags &&
                  tags.map((option, key) => (
                    <Badge
                      key={key}
                      className={"badge badge-soft-primary font-size-12 m-2"}
                    >
                      {option.length > 50
                        ? option.slice(0, 49) + "..."
                        : option}

                      <i
                        className="mdi mdi-window-close ml-1"
                        id={option}
                        onClick={(e) => {
                          var others = tags.filter(
                            (tag) => tag !== e.target.id
                          );
                          setTags(others);
                        }}
                      >
                        {" "}
                      </i>
                    </Badge>
                  ))}
                {!addNewTagsView && (
                  <Badge
                    className={"badge badge-soft-success font-size-12 m-2"}
                    onClick={() => {
                      setNewTag("");
                      setAddNewTagsView(true);
                    }}
                  >
                    {props.t("Add")} <i className="bx bx-plus ml-1"> </i>
                  </Badge>
                )}
                {addNewTagsView && (
                  <Badge
                    className={"badge badge-soft-danger font-size-12 m-2"}
                    onClick={() => {
                      setNewTag("");
                      setAddNewTagsView(false);
                    }}
                  >
                    {props.t("Close")}{" "}
                    <i className="mdi mdi-window-close ml-1"> </i>
                  </Badge>
                )}

                {tagError && (
                  <p className="text-danger">
                    {props.t("Please add atleast one choice")}
                  </p>
                )}
              </FormGroup>
            )}

            {addNewTagsView && (
              <FormGroup>
                <Editable
                  className="me-5 ml-5"
                  alwaysEditing={true}
                  onValidated={(value) => {
                    let temp = [...tags];
                    temp.push(value);
                    if (temp.length > 0 && tagError) {
                      setTagError(false);
                    }
                    setTags(temp);
                    setNewTag("");
                    setAddNewTagsView(false);
                  }}
                  onCancel={() => {
                    setAddNewTagsView(false);
                  }}
                  disabled={false}
                  editText={newTag ? " ✎" : "No value ✎"}
                  id={question.id}
                  isValueClickable={false}
                  mode="inline"
                  placement="top"
                  initialValue={newTag}
                  showText
                  type="textfield"
                  placeholder="Type here"
                  validate={(value) => {
                    if (!(value && value.length > 0)) {
                      return props.t("Tag Can not be empty ");
                    } else if (tags.includes(value)) {
                      return props.t("This Tag is already added");
                    }
                  }}
                />
              </FormGroup>
            )}
          </div>
        </AvForm>
      </div>
    );
  }

  const handleAddNewQuestion = () => {
    setCreatingQuestion(true);
    let FormQuestions = Parse.Object.extend("FormQuestions");
    let formQuestion = new FormQuestions();

    formQuestion.set("formId", props.formId);
    formQuestion.set("question", questionTitle);
    formQuestion.set("questionType", questionType);
    formQuestion.set("choices", tags);
    formQuestion.set("sectionId", props.sectionObject.parseObject.id);
    formQuestion.set("isMandatory", isMandatory);

    formQuestion.set("order", props.questions.length);
    formQuestion.save().then((result) => {
      let tempQuestionObj = {
        id: result.id,
        questionTitle: questionTitle,
        questionType: questionType,
        isMandatory: isMandatory,
        choices: tags,
        parseObject: result,
      };
      setAddQuestionView(false);
      setCreatingQuestion(false);
      props.handleAddNewQuestion(tempQuestionObj);
    });
  };

  function handleEditQuestion(e) {
    setCreatingQuestion(true);
    let questionObject = editQuestionObject.parseObject;
    questionObject.set("question", questionTitle);
    questionObject.set("isMandatory", isMandatory);
    questionObject.set("questionType", questionType);
    questionObject.set("choices", tags);
    questionObject.save().then((result) => {
      editQuestionObject.questionTitle = questionTitle;
      editQuestionObject.questionType = questionType;
      editQuestionObject.isMandatory = isMandatory;
      editQuestionObject.choices = tags;
      props.handleEditQuestion(editQuestionObject);

      setEditQuestionObject(undefined);
      setIsMandatory(false);
      setTags([]);
      setAddNewTagsView(false);
      setNewTag("");
      setQuestionError(false);
      setTagError(false);
      setCreatingQuestion(false);
    });
  }

  const handleRemoveQuestion = () => {
    setCreatingQuestion(true)
    let questionObject = selectedQuestionToRemove.parseObject;
    questionObject.set("isDeleted", true);
    let tempQues = selectedQuestionToRemove;
    questionObject.save().then(
      (result) => {
        setSelectedQuestionToRemove(undefined)
        setCreatingQuestion(false)
        props.handleRemoveQuestion(tempQues);
        toastr.success("Question removed successfully !!", { timeOut: 1000 });
      },
      (error) => {
        setSelectedQuestionToRemove(undefined)
        setCreatingQuestion(false)
        toastr.success("Something went wrong, please try again !!", {
          timeOut: 1000,
        });
      }
    );
  };

  function getAddNewQuestionUI(question) {
    if (addQuestionView) {
      return (
        <Row key={question.id} className="border-bottom">
          <Col lg={1} className="mt-3 mb-2">
            <div className="custom-control custom-checkbox mb-2 ml-3"></div>
          </Col>
          <Col lg={9} className="mt-3 mb-2">
            <div>{getQuestionEditView(question)}</div>
          </Col>

          <Col lg={2} className="mt-2 mb-2">
            <Row>
              <Col>
                <Button
                  onClick={(e) => {
                    let canProceed = true;
                    if (
                      questionTitle === undefined ||
                      questionTitle === null ||
                      questionTitle.length === 0
                    ) {
                      canProceed = false;
                      setQuestionError(true);
                    }
                    if (
                      (questionType === 5 || questionType === 6) &&
                      tags.length === 0
                    ) {
                      canProceed = false;
                      setTagError(true);
                    }
                    if (canProceed) {
                      handleAddNewQuestion();
                    }
                  }}
                  type="button"
                  color="primary"
                  size="sm"
                  disabled={creatingQuestion}
                  className="btn-rounded waves-effect waves-light m-2"
                >
                  {creatingQuestion && (
                    <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-1"></i>
                  )}
                  {props.t("Save")}
                </Button>

                <Button
                  type="button"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    setEditQuestionObject(undefined);
                    setIsMandatory(false);
                    setTags([]);
                    setAddNewTagsView(false);
                    setNewTag("");
                    setQuestionError(false);
                    setTagError(false);
                    setAddQuestionView(false);
                  }}
                  className="btn-rounded waves-effect waves-light  "
                >
                  {props.t("Cancel")}
                </Button>
              </Col>
            </Row>
          </Col>
        </Row>
      );
    } else {
      return (
        <Row>
          <Col lg={12}>
          <div className="d-flex justify-content-end">
              <button
                onClick={() => {
                  if (addQuestionView === false) {
                    resetVariables();
                    setEditQuestionObject(undefined);
                  }
                  setAddQuestionView(!addQuestionView);
                }}
                type="button"
                className="btn btn-primary waves-effect float-right waves-light m-1 mt-4 mb-4"
              >
                <i className="bx bx-plus font-size-16 align-middle mr-2"></i>{" "}
                {props.t("Add Question")}
              </button>
            </div>
          </Col>
        </Row>
      );
    }
  }

  useEffect(() => {
    if (editQuestionObject) {
    }
  }, [editQuestionObject]);

  return (
    <React.Fragment>
      {selectedQuestionToRemove && !creatingQuestion && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Remove"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setSelectedQuestionToRemove(undefined);
          }}
          onConfirm={() => handleRemoveQuestion()}
        >
          {props.t("Are you sure you want to remove the question")} <br></br>
        </SweetAlert>
      )}
      <div className="justify-content-center cursors move">
        {props.questions &&
          props.questions.map((question) =>
            question.id === "addNewQuestion"
              ? getAddNewQuestionUI(question)
              : getQuestionUI(question)
          )}
      </div>
    </React.Fragment>
  );
};

export default withNamespaces()(FormQuestion);
