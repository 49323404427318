import * as actions from "./actionTypes";
const initialState = {
  franchise: undefined
};

const Franchise = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_FRANCHISE:
        state = { ...state, franchise: action.payload.franchise };
        break;
    default:
        state = { ...state };
        break;
  }
  return state;
};

export default Franchise;