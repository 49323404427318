import * as actions from "./actionTypes";

export const getLearningCurriculumSchoolMapForCurriculumId = (curriculumId) => {
    return {
        type: actions.GET_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_CURRICULUM_ID,
        payload: { curriculumId },
    }
}

export const getLearningCurriculumSchoolMapForSchoolId = (schoolId) => {
    return {
        type: actions.GET_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_SCHOOL_ID,
        payload: { schoolId },
    }
}

export const getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId = ( schoolId, curriculumId) => {
    return {
        type: actions.GET_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_CURRICULUM_ID_AND_SCHOOL_ID,
        payload: { schoolId, curriculumId },
    }
}

export const updateLearningCurriculumsSchoolMapForCurriculumId = (learningCurriculumsSchoolMapForCurriculumId) => {
    return {
        type: actions.UPDATE_LEARNING_CURRICULUMS_SCHOOL_MAP_FOR_CURRICULUM_ID,
        payload: { learningCurriculumsSchoolMapForCurriculumId },
    }
}

export const updateLearningCurriculumsSchoolMapForSchoolId = (learningCurriculumsSchoolMapForSchoolId) => {
    return {
        type: actions.UPDATE_LEARNING_CURRICULUMS_SCHOOL_MAP_FOR_SCHOOL_ID,
        payload: { learningCurriculumsSchoolMapForSchoolId },
    }
}

export const updateLearningCurriculumsForCurriculumIdAndSchoolId = (learningCurriculumsSchoolMapForCurriculumIdAndSchoolId) => {
    return {
        type: actions.UPDATE_LEARNING_CURRICULUMS_SCHOOL_MAP_FOR_CURRICULUM_ID_AND_SCHOOL_ID,
        payload: { learningCurriculumsSchoolMapForCurriculumIdAndSchoolId },
    }
}


export const saveLearningCurriculumSchoolMap = (CurriculumSchoolMaps) => {
    console.log(CurriculumSchoolMaps)
    return {
        type: actions.SAVE_LEARNING_CURRICULUMS_SCHOOL_MAPS,
        payload: { CurriculumSchoolMaps },
    };
};

export const clearLearningCurriculumForId = () => {
    return {
        type: actions.CLEAR_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_CURRICULUM_ID,
        payload: {  },
    }
}