import Parse from "parse";
import { printLog } from "./loghelper";

export const getLearningActivityFromParse = (learningActivityId) => {
  printLog("HL: learningActivity_helper : getLearningActivityFromParse")

  try {
    return new Promise((resolve, reject) => {
      var LearningActivity = Parse.Object.extend("LearningActivity");
      var query = new Parse.Query(LearningActivity);
      query.get(learningActivityId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}

export const deleteLearningActivityFromParse = (learningActivity) => {
  printLog("HL: learningActivity_helper : deleteLearningActivityFromParse")
  try {
    return new Promise((resolve, reject) => {
      
      learningActivity.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        learningActivity.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        learningActivity.set("deletedBy", Parse.User.current().id);
      }

      learningActivity.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getLearningActivityForcurriculumId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningActivity_helper : getLearningActivityForcurriculumId")
  try {
    return new Promise((resolve, reject) => {
      var LearningActivity = Parse.Object.extend("LearningActivity");
      var query = new Parse.Query(LearningActivity);
      query.equalTo("curriculumId", id);

      if (updatedDate) {
        query.greaterThan("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
  
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
  
      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getLearningActivityForCourseId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningActivity_helper : getLearningActivityForCourseId")
  try {
    return new Promise((resolve, reject) => {
      var LearningActivity = Parse.Object.extend("LearningActivity");
      var query = new Parse.Query(LearningActivity);
      query.equalTo("courseId", id);

      if (updatedDate) {
        query.greaterThan("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
  
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
  
      query.ascending("orderBy");
      query.addAscending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getQuizLearningActivitiesForCourseId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningActivity_helper : getQuizLearningActivitiesForCourseId")
  try {
    return new Promise((resolve, reject) => {
      var LearningActivity = Parse.Object.extend("LearningActivity");
      var query = new Parse.Query(LearningActivity);
      query.equalTo("courseId", id);
      query.equalTo("fileType", "quiz");

      if (updatedDate) {
        query.greaterThan("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
  
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
  
      query.ascending("orderBy");
      query.addAscending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};


export const getLiveMeetingLearningActivitiesForCourseId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningActivity_helper : getLiveMeetingLearningActivitiesForCourseId")
  try {
    return new Promise((resolve, reject) => {
      var LearningActivity = Parse.Object.extend("LearningActivity");
      var query = new Parse.Query(LearningActivity);
      query.equalTo("courseId", id);
      query.equalTo("fileType", "meeting");

      if (updatedDate) {
        query.greaterThan("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
  
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
  
      query.ascending("orderBy");
      query.addAscending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};


export const getLearningActivityForSubcategoryIds = (ids, categoryId, isLoacal) => {
  printLog("HL: learningActivity_helper : getLearningActivityForSubcategoryIds")
  try {
    return new Promise((resolve, reject) => {
      var LearningActivity = Parse.Object.extend("LearningActivity");
      

      var query1 = new Parse.Query(LearningActivity);
      query1.doesNotExist("subcategoryId");
      query1.equalTo("category", categoryId);

      var mainQuery;
      if (ids && ids.length > 0) {
        var query2 = new Parse.Query(LearningActivity);
        query2.containedIn("subcategoryId", ids);
        mainQuery = Parse.Query.or(query1, query2);
      } else {
        mainQuery = query1;
      }
      if (isLoacal) {
        mainQuery.fromLocalDatastore();
      }
      mainQuery.notEqualTo("isDeleted", true);
      mainQuery.ascending("orderBy");
      mainQuery.addAscending("createdAt");
      mainQuery.limit(1000);
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};
export const saveLearningActivity = (object) => {
  printLog("HL: learningActivity_helper : saveLearningActivity")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};
export const saveLearningActivityObjects = (objects) => {
  printLog("HL: learningActivity_helper : saveLearningActivityObjects")
  try {
      
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
};

export const getLearningActivitiesForIds = (ids) => {
  try {

    return new Promise((resolve, reject) => {
      var LearningActivity = Parse.Object.extend("LearningActivity");
      var query = new Parse.Query(LearningActivity);

      query.containedIn("objectId", ids)
      query.find().then(
        (result) => {
          resolve(result);
          // console.log(result)
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}