import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";


const initialState = {
    inventoryKits: [], inventorykitAddLoading: false,
    selectedInventoryKit: undefined,
};

const InventoryKits = (state = initialState, action) => {
    switch (action.type) {

        case actions.UPDATE_INVENTORY_KITS:

            var inventoryKits = [...state.inventoryKits];
            if (inventoryKits.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.inventoryKits], action.payload.inventoryKits, "isDeleted")

                state = { ...state, inventoryKits: updatedList };
            } else {
                var i = 0
                var inventoryKitsToSave = []
                for (i = 0; i < action.payload.inventoryKits.length; i++) {
                    if (action.payload.inventoryKits[i].attributes.isDeleted !== true) {
                        inventoryKitsToSave.push(action.payload.inventoryKits[i])
                    }
                }
                state = { ...state, inventoryKits: inventoryKitsToSave };
            }
            break;

        case actions.SET_SELECTED_INVENTORY_KIT:
            state = { ...state, selectedInventoryKit: undefined };
            break;

        case actions.UPDATE_SELECTED_INVENTORY_KIT:
            state = { ...state, selectedInventoryKit: action.payload.inventoryKit };
            break;


        case actions.UPDATE_DELETE_INVENTORY_KIT:
            var inventoryKits = state.inventoryKits.filter(
                (inventoryKit) => inventoryKit.id !== action.payload.inventoryKit.id
            );
            state = { ...state, inventoryKits: inventoryKits };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default InventoryKits;
