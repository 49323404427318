import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";

const initialState = {
    examResults: [], loding: undefined,
};

const ExamResults = (state = initialState, action) => {
    switch (action.type) {

        case actions.UPDATE_EXAM_RESULTS:
            if (action.payload.isUpdate) {
                var updatedList = ReduxUtil.updateParseObjects([...state.examResults], action.payload.examResults, "isDeleted")

                state = { ...state, examResults: updatedList };
            } else {
                state = { ...state, examResults: action.payload.examResults };
            }

            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default ExamResults;
