import React, { useState, useEffect } from "react";
import { Card, CardBody, Col, Row, Label, Form, FormGroup, Input, Table, Button } from "reactstrap";
import { AvField, AvForm } from 'availity-reactstrap-validation';
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Moment from "moment";
import Parse from "parse";
import UpdatePhoto from '../../../components/Common/UpdatePhoto';
import ItemImage from "../../../assets/images/book_lover.svg";
import { setSelecetdSchool, getInventoryItemForOwnerId, setSelectedInventoryKit, setSelectedInventoryKitItems } from '../../../store/actions';
import Topbar from "../Topbar";

const NewInventoryKit = (props) => {

    const matchAdd = matchPath(props.history.location.pathname, {
        path: "/new-items-group/:schoolId",
        exact: true,
        strict: false,
    });

    const matchEditKit = matchPath(props.history.location.pathname, {
        path: "/edit-inventory-items-group/:schoolId/:kitId",
        exact: true,
        strict: false,
    });

    const franchiseMatchAdd = matchPath(props.history.location.pathname, {
        path: "/franchise-new-items-group/:franchiseId",
        exact: true,
        strict: false,
    });

    const franchiseMatchEditKit = matchPath(props.history.location.pathname, {
        path: "/franchise-edit-inventory-items-group/:franchiseId/:kitId",
        exact: true,
        strict: false,
    });

    const match = matchEditKit ?? franchiseMatchEditKit;
    const kitId = match && match.params.kitId;

    const match1 = matchAdd ?? matchEditKit
    const schoolId = match1 && match1.params.schoolId;

    const match2 = franchiseMatchAdd ?? franchiseMatchEditKit;
    const franchiseId = match2 && match2.params.franchiseId;

    const [tableData, setTableData] = useState((matchAdd || franchiseMatchAdd) ? [{
        itemId: uuidv4(),
        item: undefined,
        units: undefined,
        cost: 0,
        quantity: 1,
        itemTotal: 0,
        dropdownItem: undefined,
    }
    ] : []);
    const [itemList, setItemList] = useState([]);
    const [kitName, setKitName] = useState();
    const [kitDescription, setKitDiscription] = useState();
    const [kitNameError, setKitNameError] = useState(false);
    const [image, setImage] = useState(undefined);
    const [localImage, setLocalImage] = useState(undefined);
    const [kitCost, setKitCost] = useState(0);


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getInventoryItemForOwnerId(schoolId, 'school')
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryItemForOwnerId(franchiseId, 'franchise')
        }
    }, [franchiseId]);

    useEffect(() => {
        if (props.inventoryItems && props.inventoryItems.length > 0) {
            let tempSelectList = []
            for (const i of props.inventoryItems) {
                tempSelectList.push(getSelectionObject(i))
            }
            setItemList(tempSelectList)
        }
    }, [props.inventoryItems])

    const getSelectionObject = (inventoryItem) => {
        let tempSelectObj = {}
        let obj = inventoryItem.attributes
        tempSelectObj.id = inventoryItem.id
        tempSelectObj.name = obj.name ?? ""
        tempSelectObj.tax = obj.taxRate ?? ""
        tempSelectObj.value = inventoryItem.id
        tempSelectObj.label = obj.name ?? ""
        tempSelectObj.parseObject = inventoryItem
        return tempSelectObj
    }

    const getSelectionItemFromId = (id) => {

        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return getSelectionObject(item)
            }
        }
    }

    useEffect(() => {
        if (kitId) {
            props.setSelectedInventoryKit(kitId);
            props.setSelectedInventoryKitItems(kitId);
        }
    }, [kitId]);

    useEffect(() => {
        if ((matchEditKit || franchiseMatchEditKit) && props.selectedInventoryKit && props.selectedInventoryKit.id == kitId) {
            setKitName(props.selectedInventoryKit.attributes.name);
            setImage(props.selectedInventoryKit.attributes.displayImage ? props.selectedInventoryKit.attributes.displayImage._url : undefined)
            setKitDiscription(props.selectedInventoryKit.attributes.description);
            setKitCost(props.selectedInventoryKit.attributes.sellingPrice ? props.selectedInventoryKit.attributes.sellingPrice : kitCost);
        }
    }, [props.selectedInventoryKit])

    useEffect(() => {
        if ((matchEditKit || franchiseMatchEditKit) && props.selectedInventoryKitItems && props.selectedInventoryKitItems.length > 0) {
            let items = [];
            let kitAmount = 0;

            for (const si of props.selectedInventoryKitItems) {
                if (si.attributes.kitId == kitId) {
                    let itemTotal = si.attributes.unitCost * si.attributes.quantity;
                    kitAmount += itemTotal;

                    let newItem = {
                        itemId: uuidv4(),
                        item: getSelectionItemFromId(si.attributes.itemId),
                        itemError: false,
                        units: si.attributes.unit,
                        cost: si.attributes.unitCost,
                        tax: si.attributes.tax,
                        itemTotal: si.attributes.sellingPrice,
                        quantity: si.attributes.quantity,
                        quantityError: false,
                        parseObject: si
                    };
                    items.push(newItem);
                }
            }

            setTableData(items);
            setKitCost(kitAmount);
        }
    }, [props.selectedInventoryKitItems]);



    function handleNewItem() {
        let items = [...tableData]
        let newItem = {
            itemId: uuidv4(),
            item: undefined,
            itemError: false,
            units: undefined,
            cost: 0,
            quantity: 1,
            itemTotal: 0,
            quantityError: false,
            parseObject: undefined,
        }
        items.push(newItem)
        setTableData(items)
    }

    const handleRemoveItem = (item) => {
        let newItems = tableData.filter((k) => k.itemId !== item.itemId);
        setTableData(newItems);
    }

    const handlePicChange = (imageFile, cr) => {

        setLocalImage({ imageFile: imageFile, cr: cr })
        setImage(cr);

    };

    const handelSave = () => {

        let canSave = true;

        let newList = []
        for (let i of tableData) {
            if (!i.item) {
                i.itemError = true;
                canSave = false
            }

            if (!i.quantity || i.quantity < 0) {
                i.quantityError = true;
                canSave = false
            }
            newList.push(i)
        }
        setTableData(newList);

        if (!kitName) {
            setKitNameError(true);
            canSave = false;
        }

        if (!canSave) {
            return
        }

        const InventoryKits = Parse.Object.extend("InventoryKits");
        let inventoryKit = new InventoryKits();
        if (kitId) {
            inventoryKit.id = kitId
        }
        if ((matchEditKit || franchiseMatchEditKit)) {
            inventoryKit.id = props.selectedInventoryKit.id
        } else {
            if (franchiseId) {
                inventoryKit.set("ownerId", franchiseId);
                inventoryKit.set("ownerType", 'franchise');
            } else {
                inventoryKit.set("ownerId", schoolId);
                inventoryKit.set("ownerType", 'school');
            }
        }
        inventoryKit.set("name", kitName);
        inventoryKit.set("description", kitDescription);
        inventoryKit.set("sellingPrice", parseInt(kitCost));
        inventoryKit.set('createdBy', Parse.User.current().attributes.teacherId);

        if (localImage) {
            saveDisplayImage(inventoryKit, localImage.imageFile)
        } else {
            saveItemObject(inventoryKit);
        }
    }

    const saveDisplayImage = (inventoryKit, imageFile) => {
        imageFile.save().then(
            (result) => {
                inventoryKit.set('displayImage', result)
                saveItemObject(inventoryKit)
            },
            (error) => {
                // console.log("File:", error);
            },
            (progress) => {
                console.log(progress);
            }
        );
    }

    const saveItemObject = (inventoryKit) => {
        inventoryKit.save().then(
            (result) => {
                result.pin()
                // console.log(result)
                if ((matchEditKit || franchiseMatchEditKit)) {
                    let itemObjects = [];
                    let exItems = [];
                    for (let i = 0; i < tableData.length; i++) {
                        const InventoryKitItemMap = Parse.Object.extend("InventoryKitItemMap");
                        let inventoryKitItem = new InventoryKitItemMap();

                        if (tableData[i].parseObject) {
                            inventoryKitItem.id = tableData[i].parseObject.id
                            exItems.push(tableData[i].parseObject.id);
                        } else {
                            inventoryKitItem.set("kitId", result.id);
                            if (franchiseId) {
                                inventoryKitItem.set("ownerId", schoolId);
                                inventoryKitItem.set("ownerType", 'school');
                            } else {
                                inventoryKitItem.set("ownerId", schoolId);
                                inventoryKitItem.set("ownerType", 'school');
                            }
                        }

                        inventoryKitItem.set("itemId", tableData[i].item.id);
                        inventoryKitItem.set("itemName", tableData[i].item.name);
                        inventoryKitItem.set("tax", parseInt(tableData[i].item.tax));
                        inventoryKitItem.set("quantity", parseInt(tableData[i].quantity + ""));
                        inventoryKitItem.set("unit", tableData[i].units);
                        inventoryKitItem.set("unitCost", parseFloat(tableData[i].cost + ""));
                        inventoryKitItem.set("sellingPrice", tableData[i].itemTotal);
                        inventoryKitItem.set("order", i);
                        itemObjects.push(inventoryKitItem);
                    }
                    let deletedItems = [];
                    for (const si of props.selectedInventoryKitItems) {
                        if (!exItems.includes(si.id)) {
                            si.set("isDeleted", true);
                            si.set("deletedBy", Parse.User.current().attributes.teacherId);
                            deletedItems.push(si)
                        }
                    }
                    Parse.Object.saveAll(itemObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        // console.log(results);
                        window.history.back();
                    },(error)=>{
                        console.log(error)
                    });
                } else {
                    let itemObjects = [];
                    for (let i = 0; i < tableData.length; i++) {
                        const InventoryKitItemMap = Parse.Object.extend("InventoryKitItemMap");
                        let inventoryKitItem = new InventoryKitItemMap();

                        inventoryKitItem.set("kitId", result.id);
                        if (franchiseId) {
                            inventoryKitItem.set("ownerType", 'franchise');
                            inventoryKitItem.set("ownerId", franchiseId);
                        } else {
                            inventoryKitItem.set("ownerType", 'school');
                            inventoryKitItem.set("ownerId", schoolId);
                        }
                        inventoryKitItem.set("itemId", tableData[i].item.id);
                        inventoryKitItem.set("itemName", tableData[i].item.name);
                        inventoryKitItem.set("tax", parseInt(tableData[i].item.tax));
                        inventoryKitItem.set("quantity", parseInt(tableData[i].quantity + ""));
                        inventoryKitItem.set("unit", tableData[i].units);
                        inventoryKitItem.set("unitCost", parseFloat(tableData[i].cost + ""));
                        inventoryKitItem.set("sellingPrice", tableData[i].itemTotal);
                        inventoryKitItem.set("order", i);

                        itemObjects.push(inventoryKitItem);
                    }
                    Parse.Object.saveAll(itemObjects).then((results) => {
                        Parse.Object.pinAll(results);
                        // console.log(results);
                        window.history.back();
                    });
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }

    function updateKitCost(newList) {
        // console.log(newList)
        let kitTotal = 0;
        for (let i of newList) {
            if (i.itemTotal) {
                kitTotal = kitTotal + i.itemTotal;
            }
        }
        setKitCost(kitTotal);
    }

    return (
        <div className="page-content">
            <Topbar
                title={(matchEditKit || franchiseMatchEditKit) ? "Edit Items Group" : "New Inventory Kit"}
                buttonName={"New Kit"}
                hideButton={true}
            />
            <div>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <AvForm className="needs-validation" >
                                    <Row>
                                        <Col>
                                            <FormGroup className="position-relative">
                                                <Label>Kit name:</Label>
                                                <AvField
                                                    type="text"
                                                    name="name"
                                                    className="inner form-control"
                                                    placeholder="Enter kit name"
                                                    onChange={(e) => {
                                                        setKitName(e.target.value);
                                                        setKitNameError(false);
                                                    }}
                                                    value={kitName}
                                                />
                                                {kitNameError && <small className='text-danger'>Please enter kit name</small>}
                                            </FormGroup>
                                            <FormGroup className="position-relative">
                                                <Label>Kit Discription:</Label>
                                                <AvField
                                                    type="textarea"
                                                    name="discription"
                                                    className="inner form-control"
                                                    placeholder="Enter kit discription"
                                                    rows="4"
                                                    onChange={(e) => {
                                                        setKitDiscription(e.target.value);
                                                    }}
                                                    value={kitDescription}
                                                />
                                            </FormGroup>
                                        </Col>
                                        <Col className="text-center  ">
                                            <div >
                                                {!image ? (
                                                    <div
                                                        className="avatar-lg mx-auto mb-2"
                                                        style={{ width: "10rem", height: "10rem" }}
                                                    >
                                                        <span
                                                            className={
                                                                "avatar-title  bg-soft-primary text-primary"
                                                            }
                                                        // style={{ fontSize: "10px" }}
                                                        >
                                                            Upload image
                                                        </span>
                                                    </div>
                                                ) : (
                                                    <div>
                                                        <img
                                                            style={{ width: "10rem", height: "10rem" }}
                                                            className="avatar mb-2"
                                                            src={image}
                                                            alt=""
                                                        />
                                                    </div>
                                                )}

                                                <UpdatePhoto
                                                    className="p-1"
                                                    isPopupOn={false}
                                                    onPicChange={(imageFile, cr) => {
                                                        handlePicChange(imageFile, cr);
                                                    }}
                                                    initialImage={image}
                                                ></UpdatePhoto>
                                            </div>
                                        </Col>
                                    </Row>
                                </AvForm>
                                {tableData && tableData.length > 0 &&
                                    <Row>
                                        <Col>
                                            <Table className="table table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th style={{ width: "4%" }}>Sl.no</th>
                                                        <th style={{ width: "25%" }}>All Items</th>
                                                        <th style={{ width: "15%" }}>Unit</th>
                                                        <th style={{ width: "15%" }}>Cost</th>
                                                        <th style={{ width: "15%" }}>Quantity</th>
                                                        <th style={{ width: "15%" }}>Item total</th>
                                                        <th></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((item, key) => (
                                                        <tr id={"main" + item.itemId} key={key}>
                                                            <td><h5 className="m-2">{(key + 1)}</h5></td>
                                                            <td>
                                                                <FormGroup className="position-relative">
                                                                    <Select style={{ width: "14rem" }}
                                                                        onChange={(selectedOption) => {
                                                                            let tempItem = item;
                                                                            tempItem.units = (selectedOption.parseObject.attributes.unit);
                                                                            tempItem.cost = (selectedOption.parseObject.attributes.sellingPrice);
                                                                            tempItem.item = selectedOption;
                                                                            tempItem.itemError = false;
                                                                            if (tempItem.quantity > 0) {
                                                                                tempItem.itemTotal = item.quantity * item.cost;
                                                                            }
                                                                            let newList = []
                                                                            for (let i of tableData) {
                                                                                if (i.itemId == item.itemId) {
                                                                                    newList.push(tempItem)
                                                                                } else {
                                                                                    newList.push(i)
                                                                                }
                                                                            }
                                                                            updateKitCost(newList);
                                                                            setTableData(newList);
                                                                        }}
                                                                        value={item.item}
                                                                        id="itemList"
                                                                        options={itemList}
                                                                        className="selectItems"
                                                                        classNamePrefix="SaleItem"
                                                                    />
                                                                    {item.itemError && <small className='text-danger'>Please select item</small>}
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup>
                                                                    <div className="col-md-10">
                                                                        <Input
                                                                            name="units"
                                                                            placeholder="Units"
                                                                            type="text"
                                                                            className="form-control"
                                                                            value={item.units}
                                                                            id="units"
                                                                            onChange={(e) => {
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup>
                                                                    <div className="col-md-10">
                                                                        <Input
                                                                            name="cost"
                                                                            placeholder="0.00"
                                                                            type="number"
                                                                            className="form-control"
                                                                            value={item.cost}
                                                                            id="cost"
                                                                            onChange={(e) => {
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup>
                                                                    <div className="col-md-10">
                                                                        <Input
                                                                            name="amount"
                                                                            placeholder="No's"
                                                                            type="number"
                                                                            className="form-control"
                                                                            id="quantity"
                                                                            min={1}
                                                                            value={item.quantity}
                                                                            //defaultValue={1}
                                                                            onChange={(e) => {
                                                                                const selectedQuantity = e.target.value
                                                                                let tempItem = item;
                                                                                tempItem.quantity = selectedQuantity;
                                                                                tempItem.itemTotal = tempItem.cost * tempItem.quantity;
                                                                                tempItem.quantityError = false;
                                                                                let newList = []

                                                                                for (let i of tableData) {
                                                                                    if (i.itemId == item.itemId) {
                                                                                        newList.push(tempItem)
                                                                                    } else {
                                                                                        newList.push(i)
                                                                                    }
                                                                                }
                                                                                updateKitCost(newList)
                                                                                setTableData(newList);

                                                                            }}
                                                                        />
                                                                        {item.quantityError && <small className='text-danger'>Please enter quantity</small>}
                                                                    </div>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <FormGroup>
                                                                    <div className="col-md-10">
                                                                        <Input
                                                                            name="itemTotal"
                                                                            placeholder="0.00"
                                                                            type="number"
                                                                            //defaultValue={item.cost}
                                                                            className="form-control"
                                                                            value={item.itemTotal}
                                                                            id="itemTotal"
                                                                            onChange={(e) => {

                                                                            }}
                                                                        />
                                                                    </div>
                                                                </FormGroup>
                                                            </td>
                                                            <td>
                                                                <i
                                                                    onClick={() => handleRemoveItem(item)}
                                                                    className="bx bx-message-square-x text-danger h3 mt-1"
                                                                    style={{ cursor: "pointer" }}
                                                                ></i>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <Button color="success"
                                                className="btn waves-effect waves-light float-end  m-2"
                                                onClick={() => {
                                                    handleNewItem()
                                                }}
                                            >
                                                Add Items<i className="mdi mdi-plus mr-1" />
                                            </Button>
                                        </Col>
                                    </Row>}
                                <hr />
                                <Row>
                                    <Col>
                                        <div className="float-end ">
                                            <tr >
                                                <td className="pb-0" >
                                                    <FormGroup className="mb-0">
                                                        <Label className="mb-0">
                                                            Kit Cost:
                                                        </Label>
                                                        <input
                                                            placeholder="0.00"
                                                            type="number"
                                                            className="form-control"
                                                            disabled={true}
                                                            value={kitCost}
                                                        ></input>
                                                    </FormGroup>
                                                </td>
                                                {/* <td className="pb-0">
                                                    <FormGroup className="mb-0">
                                                        <Label className="mb-0">
                                                            Your Price
                                                        </Label>
                                                        <Input
                                                            placeholder="0.00"
                                                            type="number"
                                                            className="form-control"
                                                            value={yourPrice == "" ? "" : yourPrice ||  kitCost}
                                                            onChange={(e) => {
                                                                setYourPrice(e.target.value)
                                                            }}
                                                        ></Input>
                                                    </FormGroup>
                                                </td> */}
                                            </tr>
                                        </div>
                                    </Col>
                                </Row>
                                <hr />
                                <div className="float-end ">
                                    <Button
                                        color="primary"
                                        className="btn waves-effect waves-light float-right  m-2"
                                        onClick={(e) => {
                                            handelSave(e.target.value);
                                        }}
                                    >
                                        Save
                                    </Button>
                                </div>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { inventoryItems } = state.InventoryItems;
    const { selectedInventoryKit } = state.InventoryKits;
    const { selectedInventoryKitItems } = state.InventoryKitItems;
    return { selectedSchool, inventoryItems, selectedInventoryKit, selectedInventoryKitItems };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool, getInventoryItemForOwnerId, setSelectedInventoryKit, setSelectedInventoryKitItems })(NewInventoryKit)));