import React, { useState } from 'react';
import { Container, Card, CardBody, CardTitle, CardHeader, Form, Label, FormGroup, Col, Row, Collapse, UncontrolledTooltip } from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Dropzone from "react-dropzone";
import { withNamespaces } from "react-i18next";
import { Link } from "react-router-dom";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import TopBar from './Topbar';

const ActivityList = (props) => {
    const [showNewTopic, setShowNewTopic] = useState(false);
    const [newTopic, setNewTopic] = useState(undefined);
    const [discription, setDiscription] = useState(undefined);
    const [selectedFiles, setselectedFiles] = useState([]);
    const [tab, setTab] = useState("1");
    function handleAcceptedFiles(files) {
        files.map(file =>
            Object.assign(file, {
                preview: URL.createObjectURL(file),
                formattedSize: formatBytes(file.size)
            })
        );
        setselectedFiles(files);
    };

    const [activityList, sal] = useState(["Paper craft", "Sand craft", "mad adds", "Baba black sheep",]);

    /**
     * Formats the size
     */
    function formatBytes(bytes, decimals = 2) {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];

        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
    };
    function handelAddSubject() {
        setShowNewTopic(true);
        scroller.scrollTo('AddTopic', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 0, // Scrolls to element + 50 pixels down the page

        })
    }
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    {/* Render Breadcrumbs */}
                    <TopBar title="Chapter Name" buttonName="Add New Topic" onClickAdd={handelAddSubject} />

                </Container>


                {
                    activityList.map((object, key) => (
                        <Card key={key}>

                            <CardBody>
                                {/* <CardHeader
                                
                                
                                className=" bg-white"
                                id="headingOne"> */}


                                <Row >
                                    <Col xs={1} lg={0.5}></Col>

                                    <Col
                                        lg={11}
                                        onClick={() => {
                                            if (tab === key) {
                                                setTab("")
                                            } else {
                                                setTab(key)
                                            }
                                        }}

                                    >
                                        <Row >
                                            <h6 className="ml-1 font-14">
                                                <span style={{ cursor: "pointer" }} className="text-dark">{"Topic " + (key + 1) + " :"} </span>
                                                <span className="text-secondary" >{object}</span>
                                            </h6>
                                            <i className={tab === key ? "bx bxs-chevron-up float-right ml-2" : "bx bxs-chevron-down float-right ml-2"} ></i>
                                        </Row>


                                    </Col>

                                    <Col lg={1.5}>

                                        <ul className="list-inline font-size-18 contact-links mb-0">
                                            <li className="list-inline-item px-2">
                                                <Link to="#" id="sub-delete">
                                                    <i className="bx bx-trash text-danger"></i>
                                                    <UncontrolledTooltip placement="top" target={"sub-delete"}>
                                                        {props.t('Delete')}
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                            <li className="list-inline-item px-2">
                                                <Link onClick={() => { }} id={"sub-edit"}>
                                                    <i className="bx bx-edit-alt"></i>
                                                    <UncontrolledTooltip placement="top" target={"sub-edit"}>
                                                        {props.t('Edit')}
                                                    </UncontrolledTooltip>
                                                </Link>
                                            </li>
                                        </ul>

                                    </Col>



                                </Row>




                                {/* </CardHeader> */}


                                <Collapse isOpen={tab === key}>

                                    <h6 className="ml-1 font-14">
                                        <span style={{ cursor: "pointer" }} className="text-dark">{"Discription: "} </span>
                                        <span className="text-secondary" >{" usjgeg ewuighiuewf weiugfiuewf fbewiughfiuehf ewbgiuer "}</span>
                                    </h6>

                                </Collapse>


                            </CardBody>


                        </Card>


                    ))
                }


                <Element name="AddTopic">  {showNewTopic && <Col >
                    <Card>

                        <CardBody>

                            <AvForm className="outer-repeater" >
                                <div data-repeater-item className="outer">
                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('New Topic')}: </Label>
                                        <AvField
                                            type="text"
                                            name="name"
                                            placeholder={props.t('New Topic...')}
                                            className="form-control"
                                            value={newTopic}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: props.t('New Topic...'),
                                                },
                                            }}
                                            onChange={(e) => {
                                                if (e.target.value.length > 0) {

                                                }
                                                setNewTopic(e.target.value);
                                            }}
                                        />


                                    </FormGroup>

                                    {/* <FormGroup >
                                            <Label htmlFor="formname">{props.t('Discription')}: </Label>
                                            <AvField
                                                type="text"
                                                name="name"
                                                placeholder={props.t('discription...') }
                                                className="form-control"
                                                value={discription}
                                                validate={{ required: {
                                                    value: false,
                                                    errorMessage: props.t(''),
                                                    },}}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                    
                                                    }
                                                    setDiscription(e.target.value);
                                                }}
                                            />
                                        </FormGroup> */}
                                </div>
                            </AvForm>





                            <Row className='d-flex justify-content-between'>
                                <Col className='d-flex justify-content-start'>
                                    <button type="button"
                                        className="btn btn-secondary "
                                        onClick={() => {
                                            setShowNewTopic(false);
                                            setNewTopic("");
                                        }}  >
                                        {props.t('Close')}
                                    </button>
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                    <button
                                        onClick={() => {

                                            setShowNewTopic(false);
                                            setNewTopic("");
                                        }}
                                        type="button"
                                        className="btn btn-primary waves-effect waves-light float-end ">
                                        <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save Topic')}


                                    </button>
                                </Col>
                            </Row>


                            {/* <Row>
              
                                    <Col className="col-12">
                                    <Card>
                                    <CardBody>
                                        <CardTitle>Upload Files</CardTitle>
                                       
                                        <Form>
                                        <Dropzone
                                            onDrop={acceptedFiles =>
                                            { handleAcceptedFiles(acceptedFiles) }
                                            }
                                        >
                                            {({ getRootProps, getInputProps }) => (
                                            <div className="dropzone">
                                                <div
                                                className="dz-message needsclick mt-1"
                                                {...getRootProps()}
                                                >
                                                <input {...getInputProps()} />
                                                <div className="mb-1">
                                                    <i className="display-4 text-muted bx bxs-cloud-upload"></i>
                                                </div>
                                                <h4>Drop files here or click to upload.</h4>
                                                </div>
                                            </div>
                                            )}
                                        </Dropzone>
                                        
                                        </Form>

                                        
                                    </CardBody>
                                    </Card>
                                </Col>
            
                                </Row> */}


                        </CardBody>
                    </Card>

                </Col>
                }</Element>
            </div>
        </React.Fragment>
    );
}

export default withNamespaces()(ActivityList);