import * as actions from "./actionTypes";

const initialState = {
  invoiceItems: [], itemsLoading: true,
};

const InvoiceItem = (state = initialState, action) => {
  switch (action.type) {
    case actions.GET_INVOICE_ITEMS:
      var invoiceItems = [...state.invoiceItems];
      let newList = [];
      let invoiceId = action.payload.invoiceId;
      for (const val of invoiceItems) {
        if (val.attributes.invoiceId === invoiceId) {
          newList.push(val);
        }
      }
      state = { ...state, invoiceItems: newList,itemsLoading:true };
      break;
    case actions.UPDATE_INVOICE_ITEM:
      var invoiceItems = [...state.invoiceItems];
      var temp = state.invoiceItems.filter(
        (invoiceItem) => invoiceItem.id === action.payload.id
      );
      if (temp.length > 0) {
        var index = invoiceItems.indexOf(temp[0]);
        invoiceItems[index] = action.payload;
        state = { ...state, invoiceItems: invoiceItems };
      } else {
        invoiceItems.push(action.payload);
        state = { ...state, invoiceItems: invoiceItems };
      }
      break;
    case actions.UPDATE_INVOICE_ITEMS:
      var invoiceItems = [...state.invoiceItems];
      if (invoiceItems.length > 0) {
        for (const latest in action.payload.invoiceItems) {
          var temp = invoiceItems.filter(
            (ii) => ii.id === action.payload.invoiceItems[latest].id
          );
          
          if (temp.length > 0) {
            if (action.payload.invoiceItems[latest].attributes.deleted) {

              let others = invoiceItems.filter(
                (ii) =>
                  ii.id !== action.payload.invoiceItems[latest].id
              );
              
              invoiceItems = others;
            } else {
              let index = invoiceItems.lastIndexOf(temp[0]);
              invoiceItems[index] = action.payload.invoiceItems[latest];
            }
            
          } else {
            invoiceItems.push(action.payload.invoiceItems[latest]);
          }
        }
        state = { ...state, invoiceItems: invoiceItems, itemsLoading: false };
      } else {
        var others = action.payload.invoiceItems.filter(
          (ii) => (!ii.attributes.deleted)
        );
        state = { ...state, invoiceItems: others, itemsLoading: false };
      }
      // state = { ...state, invoiceItems: invoiceItems };

      break;
    case actions.DELETE_INVOICE_ITEM:
      var invoiceItems = state.invoiceItems.filter(
        (invoiceItem) => invoiceItem.id !== action.payload.id
      );
      state = { ...state, invoiceItems: invoiceItems };
      break;
    case actions.GET_ITEMS_FOR_INVOICE_IDS:
      state = { ...state, invoiceItems: [] };
      break;
    case actions.SET_ITEMS_FOR_INVOICE_IDS:
      state = { ...state, invoiceItems: action.payload.itemsObjs };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default InvoiceItem;
