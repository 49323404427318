import React, { useState, useEffect } from 'react';
import {
    Col, Container, Card, CardBody, CardTitle, CardText, Table, Media, Row, NavItem, UncontrolledTooltip,
    Nav, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, ModalFooter, Label, FormGroup, Button, Progress, Badge, Input
} from "reactstrap";
import Select from "react-select";

import { Link, withRouter } from "react-router-dom";
import Parse from 'parse';
import classnames from "classnames";
import UpdatePhoto from "../../components/Common/UpdatePhoto";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import * as Constants from "../../Constents";

import Moment from 'moment';
import toastr from 'toastr';
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Breadcrumbs from '../../components/Common/Breadcrumb';
import Editable from 'react-bootstrap-editable'
import SweetAlert from "react-bootstrap-sweetalert";
import {
    setSelecetdEnquiry, getEnquirynoteForEnquiryId, editEnquiryNote,
    getEnquiryReminderforEnquiryId, editEnquiryReminder, getEnquiryQuestionForSchoolId,
    getParentsForFamilyId, getChildAdditionalDetailForEnquiryId, saveCustomObjects,
    deleteEnquiryNote, deleteEnquiryReminder, setSelecetdSchool, getTeachersForSchool, getEnquiryRemindersForSchoolId, saveEnquiryReminder
} from "../../store/actions";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import { get } from 'jquery';
import b from 'react-bootstrap-editable';
import TempletModal from './TempletModal';

const EnquiryProfile = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/enquiryprofile/:schoolId/:id",
        exact: true,
        strict: false,
    });
    const parameter = match.params.id;
    const schoolIdParameter = match.params.schoolId;

    const [activeTab, setactiveTab] = useState("1");
    const [questions, setQuestions] = useState([]);

    const [timeLine, setTimeLine] = useState([]);
    const [addedNotes, setAddedNotes] = useState([]);

    const [noteModelTitle, setNoteModelTitle] = useState(undefined);
    const [noteModelMessage, setNoteModelMessage] = useState(undefined);
    const [showNoteModel, setShowNoteModel] = useState(false);
    const [selectedNoteForEdit, setSelectedNoteForEdit] = useState(undefined);
    const [selectedNoteForDelete, setSelectedNoteForDelete] = useState(undefined);

    const [reminderTime, setReminderTime] = useState(undefined);
    const [reminderTimeError, setReminderTimeError] = useState(false);
    const [reminderModelMessage, setReminderModelMessage] = useState(undefined);
    const [reminderMessageError, setReminderMessageError] = useState(false);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [selectedReminderForEdit, setSelectedReminderForEdit] = useState(undefined);
    const [selectedReminderForDelete, setSelectedReminderForDelete] = useState(undefined);

    const [success_dlg, setsuccess_dlg] = useState(false);

    const [enquiryAdditionalQuestions, setEnquiryAdditionalQuestions] = useState([]);
    const [parentAdditionalQuestions, setParentAdditionalQuestions] = useState([]);
    const [childAdditionalQuestions, setChildAdditionalQuestions] = useState([]);

    const [familyId, setFamilyId] = useState(undefined);
    const [teacherList, setTeacherList] = useState([]);
    const [teacherSelectWarning, setTeacherSelectWarning] = useState();
    const [loading, setLoading] = useState(true);
    const [selectedTeacher, setSelectedTeacher] = useState(undefined);
    const [reminderToAssign, setReminderToAssign] = useState("");
    const [showStatusAlert, setShowStatusAlert] = useState(false)
    const [enquiryReminderObj, setEnquiryReminderObj] = useState();

    const [classModal, toggleClassModal] = useState(false);

    const [openTempateModal, setOpenTemplateModal] = useState()
    const [phoneNumber, setPhoneNumber] = useState()

    useEffect(() => {
        props.setSelecetdEnquiry(parameter);
        props.getEnquirynoteForEnquiryId(parameter);
        props.getEnquiryReminderforEnquiryId(parameter);
        props.getEnquiryQuestionForSchoolId(schoolIdParameter);
        props.getTeachersForSchool(schoolIdParameter);
    }, [parameter]);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolIdParameter) {
            props.setSelecetdSchool(schoolIdParameter);
        }
    }, [props.selectedSchool, schoolIdParameter]);

    useEffect(() => {
        if (schoolIdParameter) {
            props.getTeachersForSchool(schoolIdParameter);
        }
        setLoading(false);
    }, [schoolIdParameter]);

    useEffect(() => {
        if (schoolIdParameter) {
            props.getEnquiryRemindersForSchoolId(schoolIdParameter);
        }
        setLoading(false);
    }, [schoolIdParameter]);

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY,  h:mm A');
    }

    useEffect(() => {
        var list = [];
        var list2 = [];
        for (const i in props.enquiryNotes) {

            if (props.enquiryNotes[i].attributes.systemLog) {
                var obj = { id: props.enquiryNotes[i].id, iconClass: "bx-server", noteMessage: props.enquiryNotes[i].attributes.noteMessage, noteTime: getTimeStringForDate(props.enquiryNotes[i].attributes.createdAt), createdBy: getEnquiryCreatedBy(props.enquiryNotes[i]) };
                list.push(obj);
            } else {
                var obj = { id: props.enquiryNotes[i].id, iconClass: "bx-server", object: props.enquiryNotes[i], noteTime: getTimeStringForDate(props.enquiryNotes[i].attributes.createdAt) };
                list2.push(obj);
            }

        }
        if (props.selectedEnquiry) {
            var obj2 = { id: "addedDate", iconClass: "bx-server", noteMessage: "Enquiry added to system", noteTime: getTimeStringForDate(props.selectedEnquiry.attributes.createdAt) };
            list.push(obj2);
            var obj1 = { id: "enquiryDate", iconClass: "bx-server", noteMessage: "Enquired On", noteTime: getTimeStringForDate(props.selectedEnquiry.attributes.enquiryDate ? props.selectedEnquiry.attributes.enquiryDate : props.selectedEnquiry.attributes.createdAt) };
            list.push(obj1);
        }
        setTimeLine(list);
        // var newList = list2.reverse();
        setAddedNotes(list2);
    }, [props.enquiryNotes]);


    const getEnquiryCreatedBy = (note) => {
        let createdBy = note.attributes.createdBy
        if (props.teachers && props.teachers.length > 0 && createdBy && createdBy.length > 0) {
            var temp = props.teachers.filter((teacher) => teacher.id === createdBy);
            if (temp && temp.length > 0) {
                return temp[0].attributes.Name
            }
        }

        return ''
    }

    useEffect(() => {

        var list = [];
        if (props.selectedEnquiry && props.selectedEnquiry.id === parameter) {


            var enqAttributes = props.selectedEnquiry.attributes;
            if (enqAttributes.familyId && !familyId) {
                setFamilyId(enqAttributes.familyId)
                props.getParentsForFamilyId(enqAttributes.familyId);
            }
            if (props.childAdditionalDetailsObject == null || props.childAdditionalDetailsObject.attributes.enquiryId !== parameter) {
                props.getChildAdditionalDetailForEnquiryId(props.selectedEnquiry.id)
            }

            let kidPhoto = {
                question: "Student Photo", answer: enqAttributes.kidPhoto, databaseTableName: "Enquiry", databaseFieldName: "kidPhoto", parseObject: props.selectedEnquiry, questionType: 16

            }
            list.push(kidPhoto)



            var name = { question: "Name", answer: enqAttributes.kidName, databaseTableName: "Enquiry", databaseFieldName: "kidName", parseObject: props.selectedEnquiry }
            list.push(name);

            var kidGender = { question: "Gender", answer: enqAttributes.kidGender, databaseTableName: "Enquiry", databaseFieldName: "kidGender", parseObject: props.selectedEnquiry, questionType: 5, choices: ["No Value", "Male", "Female"] }
            list.push(kidGender);

            var dateOfBirth = { question: "Date Of Birth", answer: enqAttributes.dateOfBirth ? Moment(enqAttributes.dateOfBirth).format('DD-MMM-YYYY') + "(" : "", databaseTableName: "Enquiry", databaseFieldName: "dateOfBirth", parseObject: props.selectedEnquiry, questionType: Constants.QESTION_DATE, subText: calculateAge(enqAttributes.dateOfBirth) }
            list.push(dateOfBirth);

            var FatherName = { question: "Father Name", answer: enqAttributes.fatherName, databaseTableName: "Enquiry", databaseFieldName: "fatherName", parseObject: props.selectedEnquiry, }
            list.push(FatherName);
            var FatherMobile = { question: "Father Mobile", answer: enqAttributes.fatherMobile, databaseTableName: "Enquiry", databaseFieldName: "fatherMobile", parseObject: props.selectedEnquiry, showWhatsapp: true }
            list.push(FatherMobile);
            var FatherEmail = { question: "Father Email", answer: enqAttributes.fatherEmail, databaseTableName: "Enquiry", databaseFieldName: "fatherEmail", parseObject: props.selectedEnquiry }
            list.push(FatherEmail);

            var MotherName = { question: "Mother Name", answer: enqAttributes.motherName, databaseTableName: "Enquiry", databaseFieldName: "motherName", parseObject: props.selectedEnquiry }
            list.push(MotherName);
            var MotherMobile = { question: "Mother Mobile", answer: enqAttributes.motherMobile, databaseTableName: "Enquiry", databaseFieldName: "motherMobile", parseObject: props.selectedEnquiry, showWhatsapp: true }
            list.push(MotherMobile);
            var MotheEmail = { question: "Mother Email", answer: enqAttributes.motherEmail, databaseTableName: "Enquiry", databaseFieldName: "motherEmail", parseObject: props.selectedEnquiry }
            list.push(MotheEmail);

            // if (enqAttributes.enquiryFor) {

            //     var temp = "";
            //     for (const i in enqAttributes.enquiryFor) {
            //         temp = temp + enqAttributes.enquiryFor[i] + "  "
            //     }

            //     var enquiryFor = { question: "Enquired For", answer: temp, databaseTableName: "Enquiry", databaseFieldName: "enquiryFor", dataType: "Array", parseObject: props.selectedEnquiry, questionType:props.selectedEnquiry.attributes.questionType }
            //     list.push(enquiryFor);
            // } else {
            //     var enquiryFor = { question: "Enquired For", answer: "", databaseTableName: "Enquiry", databaseFieldName: "enquiryFor", dataType: "Array", parseObject: props.selectedEnquiry,questionType:props.selectedEnquiry.attributes.questionType }
            //     list.push(enquiryFor);
            // }


            // var year = { question: "Admission Year", answer: enqAttributes.year, databaseTableName: "Enquiry", databaseFieldName: "year", parseObject: props.selectedEnquiry }
            // list.push(year);
            // var sourse = { question: "Source", answer: enqAttributes.source, databaseTableName: "Enquiry", databaseFieldName: "source", parseObject: props.selectedEnquiry }
            // list.push(sourse);

            // var additionalNote = { question: "Notes", answer: enqAttributes.additionalNote, databaseTableName: "Enquiry", databaseFieldName: "additionalNote", parseObject: props.selectedEnquiry }
            // list.push(additionalNote);



            var tl = [];
            var obj2 = { id: "addedDate", iconClass: "bx-server", noteMessage: "Enquiry added to system", noteTime: getTimeStringForDate(props.selectedEnquiry.attributes.createdAt) };
            tl.push(obj2);
            var obj1 = { id: "enquiryDate", iconClass: "bx-server", noteMessage: "Enquired On", noteTime: getTimeStringForDate(props.selectedEnquiry.attributes.enquiryDate ? props.selectedEnquiry.attributes.enquiryDate : props.selectedEnquiry.attributes.createdAt) };
            tl.push(obj1);

            if (timeLine && timeLine.length > 0) {
                var temp = [];
                for (var i in timeLine) {

                    if (!["enquiryDate", "addedDate"].includes(timeLine[i].id)) {
                        temp.push(timeLine[i]);
                    }
                }
                var nL = temp.concat(tl);
                setTimeLine(nL);
            } else {
                setTimeLine(tl);
            }
        }

        setQuestions(list);

    }, [props.selectedEnquiry]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    }
    function handleNoteSubmit() {

        const EnquiryNote = Parse.Object.extend("EnquiryNotes");
        let noteObject = new EnquiryNote();
        if (selectedNoteForEdit) {
            noteObject = selectedNoteForEdit;
        } else {
            if (Parse.User.current().attributes.teacherId) {
                noteObject.set("createdBy", Parse.User.current().attributes.teacherId);
            } else {
                noteObject.set("createdBy", Parse.User.current().id);
            }
        }


        noteObject.set("enquiryId", props.selectedEnquiry.id);
        noteObject.set("schoolId", props.selectedEnquiry.attributes.schoolId);
        noteObject.set("noteMessage", noteModelMessage);
        noteObject.set("title", noteModelTitle);

        props.editEnquiryNote(noteObject);

        setNoteModelMessage(undefined);
        setNoteModelTitle(undefined);
        setShowNoteModel(false);
        setSelectedNoteForEdit(undefined);
    }
    function handleReminderSubmit() {
        const EnquiryReminder = Parse.Object.extend("EnquiryReminder");
        let enquiryReminder = new EnquiryReminder();

        if (selectedReminderForEdit) {
            enquiryReminder = selectedReminderForEdit;
        } else {
            if (Parse.User.current().attributes.teacherId) {
                enquiryReminder.set("createdBy", Parse.User.current().attributes.teacherId);
            } else {
                enquiryReminder.set("createdBy", Parse.User.current().id);
            }
        }

        enquiryReminder.set("enquiryId", props.selectedEnquiry.id);
        enquiryReminder.set("schoolId", props.selectedEnquiry.attributes.schoolId);
        enquiryReminder.set("body", reminderModelMessage);
        enquiryReminder.set("title", "Task");
        enquiryReminder.set("reminderDateTime", reminderTime);

        props.editEnquiryReminder(enquiryReminder);

        setReminderModelMessage(undefined);
        setReminderTime(undefined);
        setReminderTimeError(false);
        setReminderMessageError(false);
        setShowReminderModel(false);
        setSelectedReminderForEdit(undefined);

    }


    useEffect(() => {
        if (props.enquiryQuestions != null && props.enquiryQuestions.length > 0 && props.selectedEnquiry != null) {

            var listToReturn = [];
            var i = 0;
            for (i = 0; i < props.enquiryQuestions.length; i++) {
                var enqQuesAttributes = props.enquiryQuestions[i].attributes
                if (enqQuesAttributes.databaseTableName === "Enquiry") {

                    var listOfPreFields = ["kidName", "kidGender", "dateOfBirth", "fatherName", "fatherMobile", "fatherEmail", "motherName", "motherMobile", "motherEmail", "createdAt", "kidPhoto"]

                    if (!listOfPreFields.includes(enqQuesAttributes.databaseFieldName)) {
                        listToReturn.push({ question: enqQuesAttributes.question, answer: props.selectedEnquiry.attributes[enqQuesAttributes.databaseFieldName], databaseTableName: enqQuesAttributes.databaseTableName, databaseFieldName: enqQuesAttributes.databaseFieldName, parseObject: props.selectedEnquiry, questionParseObject: props.enquiryQuestions[i], questionType: enqQuesAttributes.questionType })
                    }

                }

            }
            setEnquiryAdditionalQuestions(listToReturn);
        }
    }, [props.enquiryQuestions, props.selectedEnquiry])


    useEffect(() => {
        if (props.enquiryQuestions != null && props.enquiryQuestions.length > 0 && props.selectedEnquiry != null && props.selectedParents && props.selectedParents.length > 0) {
            var fatherObject = undefined
            var motherObject = undefined

            for (const parent of props.selectedParents) {
                if (parent.attributes.FamilyID && props.selectedEnquiry.attributes.familyId && parent.attributes.FamilyID === props.selectedEnquiry.attributes.familyId) {
                    if (parent.attributes.Relation.toLowerCase() === "father") {
                        fatherObject = parent
                    } else if (parent.attributes.Relation.toLowerCase() === "mother") {
                        motherObject = parent
                    }
                }
            }

            var listToReturn = [];
            for (var i = 0; i < props.enquiryQuestions.length; i++) {
                var enqQuesAttributes = props.enquiryQuestions[i].attributes
                if (enqQuesAttributes.databaseTableName === "Parent") {

                    if (enqQuesAttributes.objectType === 2 && fatherObject) {

                        var listOfPreFields = ["Name", "EMail", "PhoneNumber", ""]

                        if (!listOfPreFields.includes(enqQuesAttributes.databaseFieldName)) {
                            listToReturn.push({ question: enqQuesAttributes.question, answer: fatherObject.attributes[enqQuesAttributes.databaseFieldName], databaseTableName: "Parent", databaseFieldName: enqQuesAttributes.databaseFieldName, parseObject: fatherObject, questionParseObject: props.enquiryQuestions[i], questionType: enqQuesAttributes.questionType })
                        }

                    } else if (enqQuesAttributes.objectType === 3 && motherObject) {
                        var listOfPreFields = ["Name", "EMail", "PhoneNumber", ""]

                        if (!listOfPreFields.includes(enqQuesAttributes.databaseFieldName)) {
                            listToReturn.push({ question: enqQuesAttributes.question, answer: motherObject.attributes[enqQuesAttributes.databaseFieldName], databaseTableName: "Parent", databaseFieldName: enqQuesAttributes.databaseFieldName, parseObject: motherObject, questionParseObject: props.enquiryQuestions[i], questionType: enqQuesAttributes.questionType })
                        }
                    }
                }
            }
            setParentAdditionalQuestions(listToReturn)
        }
    }, [props.enquiryQuestions, props.selectedEnquiry, props.selectedParents])

    useEffect(() => {
        if (props.enquiryQuestions != null && props.enquiryQuestions.length > 0 && props.selectedEnquiry != null && props.childAdditionalDetailsObject && props.childAdditionalDetailsObject.attributes.enquiryId === parameter) {
            var listToReturn = []
            var childAdditionalDetailsObject = props.childAdditionalDetailsObject;
            var i = 0
            for (i = 0; i < props.enquiryQuestions.length; i++) {
                var enqQuesAttributes = props.enquiryQuestions[i].attributes
                if (enqQuesAttributes.databaseTableName === "ChildAdditionalDetails") {
                    var listOfPreFields = []

                    if (!listOfPreFields.includes(enqQuesAttributes.databaseFieldName)) {
                        listToReturn.push({ question: enqQuesAttributes.question, answer: childAdditionalDetailsObject.attributes[enqQuesAttributes.databaseFieldName], databaseTableName: enqQuesAttributes.databaseTableName, databaseFieldName: enqQuesAttributes.databaseFieldName, parseObject: childAdditionalDetailsObject, questionParseObject: props.enquiryQuestions[i], questionType: enqQuesAttributes.questionType })
                    }
                }

            }
            setChildAdditionalQuestions(listToReturn)
        }
        else if (props.enquiryQuestions != null && props.enquiryQuestions.length > 0 && props.selectedEnquiry != null) {
            var listToReturn = []
            var childAdditionalDetailsObject = props.childAdditionalDetailsObject;
            var i = 0
            for (i = 0; i < props.enquiryQuestions.length; i++) {
                var enqQuesAttributes = props.enquiryQuestions[i].attributes
                if (enqQuesAttributes.databaseTableName === "ChildAdditionalDetails") {
                    var listOfPreFields = []

                    if (!listOfPreFields.includes(enqQuesAttributes.databaseFieldName)) {
                        listToReturn.push({ question: enqQuesAttributes.question, databaseTableName: enqQuesAttributes.databaseTableName, databaseFieldName: enqQuesAttributes.databaseFieldName, questionParseObject: props.enquiryQuestions[i], questionType: enqQuesAttributes.questionType })
                    }
                }

            }
            setChildAdditionalQuestions(listToReturn)
        }

    }, [props.enquiryQuestions, props.selectedEnquiry, props.childAdditionalDetailsObject])


    const handleInlineAnswerChange = (value, question) => {

        var customObject = question.parseObject;
        if (customObject) {

        } else {
            if (question.databaseTableName === "ChildAdditionalDetails") {
                customObject = getNewChildAdditionalDetailObject()
            }
        }
        if (value && question.databaseFieldName === "enquiryFor") {
            var res = [];
            if (value.includes(",")) {
                res = value.split(",");
            } else {
                res.push(value);
            }
            customObject.set("enquiryFor", res);
        } else {
            customObject.set(question.databaseFieldName, value);
        }
        let parentField = ['fatherName', 'fatherEmail', 'fatherPhone', 'motherName', 'motherEmail', 'motherPhone']
        if (question.databaseTableName == 'Enquiry' && parentField.includes(question.databaseFieldName)) {
            let parentObject;
            if (question.databaseFieldName.startsWith('f')) {
                for (const p of props.selectedParents) {
                    if (p.attributes.Relation == "Father") {
                        parentObject = p;
                        break
                    }
                }
            } else if (question.databaseFieldName.startsWith('m')) {
                for (const p of props.selectedParents) {
                    if (p.attributes.Relation == "Mother") {
                        parentObject = p;
                        break
                    }
                }
            }

            if (parentObject) {
                if (question.databaseFieldName.includes("Name")) {
                    parentObject.set("Name", value);
                }
                if (question.databaseFieldName.includes("Email")) {
                    parentObject.set("EMail", value);
                }
                if (question.databaseFieldName.includes("Phone")) {
                    parentObject.set("PhoneNumber", value);
                }

                props.saveCustomObjects(parentObject)
            }
        }
        props.saveCustomObjects(customObject)
        if (question.databaseTableName === "ChildAdditionalDetails") {
            fillAnswerToRespectiveObject(question, [{ dataField: "answer", answer: value }, { dataField: "parseObject", answer: customObject }]);
        } else {
            fillAnswerToRespectiveObject(question, [{ dataField: "answer", answer: value }]);
        }
    }

    function handleFileUploadChange(e, index, enqQuestionObject) {

        var enqObject = enqQuestionObject;
        fillAnswerToRespectiveObject(enqObject, [{ dataField: "answer", answer: undefined }, { dataField: "answerFileUploadProgress", answer: 1 }])
        let fileExtension = e.target.files[0].name.split(".");
        let tempFile = {
            actualFileName: e.target.files[0].name,
            fileExtension: fileExtension[1].toLowerCase(),
            fileName: fileExtension[0],
            file: e.target.files[0],
        };

        var name = tempFile.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "") + "." + tempFile.fileExtension;

        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            //setUploadProgress(1);
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            //setUploadProgress(value * 100);
                            //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

                            fillAnswerToRespectiveObject(enqObject, [{ dataField: "answerFileUploadProgress", answer: value * 100 }])


                            // enqObject.answerFileUploadProgress = value * 100;
                            // enquiryQuestionsList[index] = enqObject;

                            // var temp = [...enquiryQuestionsList];
                            // temp[index] = enqObject;
                            // setEnquiryQuestionsList(temp);
                        }
                    },
                })
                .then(
                    (result) => {
                        // tempFile.file = result;

                        var customObject = enqObject.parseObject
                        if (customObject) {

                        } else {
                            if (enqObject.databaseTableName === "ChildAdditionalDetails") {
                                customObject = getNewChildAdditionalDetailObject()
                            }
                        }


                        customObject.set(enqObject.databaseFieldName, result)
                        props.saveCustomObjects(customObject)

                        if (enqObject.databaseTableName === "ChildAdditionalDetails") {
                            fillAnswerToRespectiveObject(enqObject, [{ dataField: "answer", answer: result }, { dataField: "fileUploadInProgress", answer: false }, { dataField: "parseObject", answer: customObject }])

                        } else {
                            fillAnswerToRespectiveObject(enqObject, [{ dataField: "answer", answer: result }, { dataField: "fileUploadInProgress", answer: false }])

                        }






                    },
                    (error) => {
                    }
                );
        });

        fillAnswerToRespectiveObject(enqObject, [{ dataField: "answer", answer: tempFile.actualFileName }])

        // enqObject.answerFile = { actualFileName: tempFile.actualFileName };
        // enquiryQuestionsList[index] = enqObject;

        // var temp = [...enquiryQuestionsList];
        // temp[index] = enqObject;
        // setEnquiryQuestionsList(temp);


    }

    const handleType5AnswerChange = (value, question) => {
        if (value == null || value == "No Value") {
            // var customObject = question.parseObject

            // if (question.questionParseObject && question.questionParseObject.attributes.dataType === "ARRAY") {
            //     customObject.set(question.databaseFieldName, ["Male"])
            // } else {
            //     customObject.set(question.databaseFieldName, "Male")
            // }
            // props.saveCustomObjects(customObject)
        } else {
            var customObject = question.parseObject
            if (customObject) {

            } else {
                if (question.databaseTableName === "ChildAdditionalDetails") {
                    customObject = getNewChildAdditionalDetailObject()
                }
            }
            if (question.questionParseObject && question.questionParseObject.attributes.dataType === "ARRAY") {
                customObject.set(question.databaseFieldName, [value])
            } else {
                customObject.set(question.databaseFieldName, value)
            }
            props.saveCustomObjects(customObject)
            if (question.databaseTableName === "ChildAdditionalDetails") {
                fillAnswerToRespectiveObject(question, [{ dataField: "parseObject", answer: customObject }])
            }
        }


    }

    const fillAnswerToRespectiveObject = (question, answerList) => {
        if (questions.includes(question)) {

            var questionIndex = questions.indexOf(
                question
            );
            var tempQuestionObject = questions[questionIndex];

            for (var i = 0; i < answerList.length; i++) {
                tempQuestionObject[`${answerList[i].dataField}`] = answerList[i].answer
            }
            var tempQuestionList = [
                ...questions,
            ];
            tempQuestionList[
                questionIndex
            ] = tempQuestionObject;

            setQuestions(tempQuestionList);
        } else if (enquiryAdditionalQuestions.includes(question)) {
            var questionIndex = enquiryAdditionalQuestions.indexOf(
                question
            );
            var tempQuestionObject = enquiryAdditionalQuestions[questionIndex];
            for (var i = 0; i < answerList.length; i++) {
                tempQuestionObject[`${answerList[i].dataField}`] = answerList[i].answer
            }

            var tempQuestionList = [
                ...enquiryAdditionalQuestions,
            ];

            tempQuestionList[
                questionIndex
            ] = tempQuestionObject;

            setEnquiryAdditionalQuestions(tempQuestionList);

        } else if (childAdditionalQuestions.includes(question)) {

            var questionIndex = childAdditionalQuestions.indexOf(question);
            var tempQuestionObject = childAdditionalQuestions[questionIndex];


            for (var i = 0; i < answerList.length; i++) {
                tempQuestionObject[`${answerList[i].dataField}`] = answerList[i].answer
            }

            var tempQuestionList = [
                ...childAdditionalQuestions,
            ];

            tempQuestionList[
                questionIndex
            ] = tempQuestionObject;

            setChildAdditionalQuestions(tempQuestionList)



        } else if (parentAdditionalQuestions.includes(question)) {
            var questionIndex = parentAdditionalQuestions.indexOf(
                question
            );
            var tempQuestionObject = parentAdditionalQuestions[questionIndex];
            for (var i = 0; i < answerList.length; i++) {
                tempQuestionObject[`${answerList[i].dataField}`] = answerList[i].answer
            }
            var tempQuestionList = [
                ...parentAdditionalQuestions,
            ];
            tempQuestionList[
                questionIndex
            ] = tempQuestionObject;

            setParentAdditionalQuestions(tempQuestionList);
        }
    }

    const getNewChildAdditionalDetailObject = () => {
        let ChildAdditionalDetails = Parse.Object.extend("ChildAdditionalDetails");
        let childAdditionalDetails = new ChildAdditionalDetails();
        childAdditionalDetails.set("enquiryId", parameter);
        return childAdditionalDetails
    }


    const handleMultiSelect = (list, question) => {

        var customObject = question.parseObject
        if (customObject) {

        } else {
            if (question.databaseTableName === "ChildAdditionalDetails") {
                customObject = getNewChildAdditionalDetailObject()
            }
        }

        let tempList = []
        if (list != undefined) {
            for (var i = 0; i < list.length; i++) {
                tempList.push(list[i].value)
            }
        }
        customObject.set(question.databaseFieldName, tempList)
        props.saveCustomObjects(customObject)

        if (question.databaseTableName === "ChildAdditionalDetails") {
            fillAnswerToRespectiveObject(question, [{ dataField: "answer", answer: tempList }, { dataField: "parseObject", answer: customObject }])
        } else {
            fillAnswerToRespectiveObject(question, [{ dataField: "answer", answer: tempList }])
        }
    }

    const handlePicChange = (imageFile, question) => {
        toastr.info(props.t('please wait...'), { timeOut: 1000 });
        imageFile.save().then(() => {
            var customObject = question.parseObject
            if (customObject) {

            } else {
                if (question.databaseTableName === "ChildAdditionalDetails") {
                    customObject = getNewChildAdditionalDetailObject()
                }
            }
            customObject.set(question.databaseFieldName, imageFile)
            props.saveCustomObjects(customObject)
            if (question.databaseTableName === "ChildAdditionalDetails") {
                fillAnswerToRespectiveObject(question, [{ dataField: "answer", answer: imageFile }, { dataField: "parseObject", answer: customObject }]);
            } else {
                fillAnswerToRespectiveObject(question, [{ dataField: "answer", answer: imageFile }]);
            }
        })
    }

    useEffect(() => {
        if (props.teachers && props.teachers.length > 0) {
            let list = [];
            for (const teacher of props.teachers) {
                if (teacher.attributes.featurePermissions &&
                    teacher.attributes.featurePermissions.length > Constants.TEACHER_ENQUIRY &&
                    teacher.attributes.featurePermissions[Constants.TEACHER_ENQUIRY] > 0) {
                    list.push(teacher);
                }
            }
            setTeacherList(list);
        }
    }, [props.teachers]);

    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    function getDesignation(teacher) {
        if (teacher.designation) {
            return teacher.designation;
        } else if (teacher.Role === 3) {
            return props.t('Director');
        } else if (teacher.Role === 2) {
            return props.t('Center Head');
        } else {
            return props.t('Teacher');
        }
    }

    const saveTeacher = () => {
        setTeacherSelectWarning(false);
        if (reminderToAssign) {
            reminderToAssign.set("assignedTo", selectedTeacher);
            reminderToAssign.set("assignedBy", Parse.User.current().attributes.teacherId)
            props.saveEnquiryReminder(reminderToAssign);
            setLoading(false)

            toggleClassModal(false);
        }
    };

    const changeStatus = (status, enquiryReminder) => {
        enquiryReminder.set("taskBy", Parse.User.current().attributes.teacherId)
        enquiryReminder.set("taskStatus", status)
        props.saveEnquiryReminder(enquiryReminder);
        setShowStatusAlert(false)
    }

    const inputStyle = {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    };

    const getActualFileName = (result) => {
        if (typeof result === "object") {
            let name = result._name.split(/_(.+)/)[1];
            return name

        } else {
            return result
        }
    }

    const getFileExtension = (result) => {
        if (typeof result === "object") {
            let name = result._name.split(/_(.+)/)[1];
            let fileExtension = name.split(".");
            return fileExtension[1].toLowerCase()
        } else {
            return result
        }
    }

    function getFileTypeImage(fileExtension) {
        if (fileExtension === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtension === "ppt" || fileExtension === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "doc" || fileExtension === "docx") {
            return "bx bxs-file-doc";
        }
        // else if(fileType === "Video"){
        //     return "bx bx-play-circle";
        // }else if (_audioExtensions.includes(fileExtension)) {
        //     return "bx bxs-music";
        // }
        return "bx bxs-file-blank";
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }


    const getAnswerUI = (question, key) => {
        let questionType = question.questionType

        if (questionType && questionType === Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST) {
            let choices
            if (question.questionParseObject) {
                choices = question.questionParseObject.attributes.choice
            } else {
                choices = question.choices
            }

            return (
                <Editable
                    className={'d-flex'}
                    alwaysEditing={false}
                    disabled={false}
                    editText={question.answer ? " ✎" : "No value ✎"}
                    id={"singleSelect" + key}
                    isValueClickable={false}
                    mode="inline"
                    onSubmit={(value) => { handleType5AnswerChange(value, question) }}
                    initialValue={question.answer}

                    options={choices ?? []}
                    placement="top"
                    showText
                    type="select"
                />)
        } else if (questionType && questionType === Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST) {
            let choices = []
            if (question.questionParseObject) {
                choices = question.questionParseObject.attributes.choice
            } else {
                choices = question.choices
            }
            let options = []
            for (var i = 0; i < choices.length; i++) {
                let temp = {
                    label: choices[i],
                    value: choices[i],

                }
                options.push(temp)
            }

            let tempAnswer = []
            if (question.answer != undefined && question.answer.length > 0) {
                for (var i = 0; i < question.answer.length; i++) {
                    let temp = {
                        label: question.answer[i],
                        value: question.answer[i],

                    }
                    tempAnswer.push(temp)
                }
            }



            return (<FormGroup className="select2-container">
                <Select
                    id={"multiSelect" + key}
                    value={tempAnswer}
                    isMulti={true}
                    onChange={(list) => { handleMultiSelect(list, question) }}
                    options={options}
                    classNamePrefix="select2-selection"
                />


            </FormGroup>)
        } else if (questionType && questionType === Constants.QESTION_UPLOAD_IMAGE) {
            return (<Col lg={12} md={12} xs={12} className="text-left ">
                <div className="py-4">
                    {!question.answer ? (
                        <div className="avatar-lg mx-auto" style={{ width: '10rem', height: '10rem' }}>
                            <span
                                className={
                                    "avatar-title rounded-circle bg-soft-primary text-primary"
                                }
                                style={{ fontSize: '60px' }}
                            >

                            </span>
                        </div>
                    ) : (
                        <div>
                            <img
                                className="rounded-circle img-fluid mx-auto d-block"
                                style={{ width: '10rem', height: '10rem' }}
                                src={question.answer._url}
                                alt=""
                            />
                        </div>
                    )}
                </div>

                <UpdatePhoto
                    id={"imageUploadEnquiryProfile" + key}
                    tempId={"imageUploadEnquiryProfile" + key}
                    isPopupOn={false}
                    onPicChange={(imageFile) => {
                        handlePicChange(imageFile, question);
                    }}
                    initialImage={question.answer ? question.answer._url : undefined}
                ></UpdatePhoto>
            </Col>)
        } else if (questionType && questionType === Constants.QESTION_UPLOAD_DOCUMENT) {
            return (<div>{question.answer == undefined && (
                <div className="d-flex">
                    <Label
                        htmlFor={"input-file-enquiry-profile-" + key}
                        color="primary"
                        className="btn btn-primary"
                    >
                        Upload
                        <input
                            id={"input-file-enquiry-profile-" + key}
                            style={inputStyle}
                            type="file"
                            onChange={(e) => {
                                handleFileUploadChange(e, key, question);
                            }}
                            accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                        />
                    </Label>
                </div>
            )}

                {((question.answer && typeof question.answer !== "string") || question.answerFileUploadProgress === 100) && (
                    <Col xl="12" md="12" sm="12" xs="12">
                        <div className="border p-3">
                            <Row>
                                <div
                                    className="avatar ml-3"
                                    style={{ width: "5rem", height: "5rem" }}
                                >
                                    <span
                                        className="avatar-title bg-white ml-0 text-primary h1"
                                        style={{ fontSize: "60px" }}
                                    >
                                        <i
                                            className={getFileTypeImage(
                                                getFileExtension(question.answer)
                                            )}
                                        ></i>
                                    </span>
                                </div>

                                <div className="float-right ml-4">
                                    <div
                                        className="text-muted font-weight-bold text-truncate"
                                        style={{ width: "200px" }}
                                    >
                                        {getActualFileName(question.answer)}
                                    </div>
                                    <div className="d-flex mt-3">
                                        <div>
                                            <Label
                                                htmlFor={"input-file-enquiry-profile-Update-" + key}
                                                className="btn btn-warning mb-0"
                                            >
                                                Update
                                                <input
                                                    id={"input-file-enquiry-profile-Update-" + key}
                                                    style={inputStyle}
                                                    type="file"
                                                    onChange={(e) => {
                                                        handleFileUploadChange(
                                                            e,
                                                            key,
                                                            question
                                                        );
                                                    }}
                                                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                                                />
                                            </Label>
                                        </div>

                                        <Button
                                            className="btn btn-primary float-right ml-2"
                                            color="primary"
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            onClick={() => {

                                                if (question && question.answer && question.answer._url) {
                                                    window.open(question.answer._url, "_blank")

                                                } else {
                                                    toastr.info("Please refresh and try again...", { timeOut: 1000 })
                                                }

                                            }}
                                        >
                                            Open
                                        </Button>
                                    </div>
                                </div>
                            </Row>
                        </div>
                    </Col>
                )}

                {
                    question.answer &&
                    question.answerFileUploadProgress >= 1 &&
                    question.answerFileUploadProgress < 100 && (
                        <div class="d-flex">
                            <Col xl="12" md="12" sm="12" xs="12" className="ml-0">
                                <div className="border p-3">
                                    <Row>
                                        <div
                                            className="avatar ml-3"
                                            style={{ width: "5rem", height: "5rem" }}
                                        >
                                            <span className="avatar-title bg-white ml-0 text-primary h1">
                                                <i
                                                    className={getFileTypeImage(question.answer)}
                                                ></i>
                                            </span>
                                        </div>
                                        <div
                                            className="float-right ml-4"
                                            style={{ width: "200px" }}
                                        >
                                            <div className="text-muted font-weight-bold">
                                                {question.answer}
                                            </div>
                                            <div className=" mt-3">
                                                <Progress
                                                    color="primary"
                                                    value={question.answerFileUploadProgress}
                                                ></Progress>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            </Col>
                        </div>
                    )}</div>)
        } else if (questionType && questionType === Constants.QESTION_DATE) {
            if (question.answer) {
                return (
                    <div>
                        <Editable
                            className={'d-flex'}
                            alwaysEditing={false}
                            onSubmit={(date) => handleDateChange(key, date, question)}

                            disabled={false}
                            editText={question.answer ? " ✎" : "No value ✎"}
                            id={"questionDate" + key}
                            isValueClickable={false}
                            mode="inline"
                            placement="top"
                            initialValue={question.answer ? Moment(question.answer).format('YYYY-MM-DD') : ""}
                            showText
                            type="date"
                        />
                        {question.subText && <p>{question.subText}</p>}
                    </div>
                )


                //     return(<AvForm><FormGroup>
                // <AvField
                //     type="date"
                //     name={"questionDate" + key}
                //     className="inner form-control"
                //     placeholder="dd/mm/yyyy"
                //     onChange={(e) => {
                //       handleDateChange(key, e.target.value,question);
                //     }}
                //     value={formatDate(new Date(question.answer))}
                //     // validate={{
                //     //   required: {
                //     //     // value: !enqQuestionObject.answerText || enqQuestionObject.answerText.length === 0 ? true : false,
                //     //     errorMessage: props.t("Enter " + enqQuestionObject.question + "..."),
                //     //   },

                //     // }}
                //     //max={maxDateString}
                //   /></FormGroup></AvForm>)

            } else {
                return (
                    // <AvForm><FormGroup>
                    //     <AvField
                    //         type="date"
                    //         name={"questionDate" + key}
                    //         className="inner form-control"
                    //         placeholder="dd/mm/yyyy"
                    //         onChange={(e) => {
                    //           handleDateChange(key, e.target.value,question);
                    //         }}
                    //         // validate={{
                    //         //   required: {
                    //         //     // value: !enqQuestionObject.answerText || enqQuestionObject.answerText.length === 0 ? true : false,
                    //         //     errorMessage: props.t("Enter " + enqQuestionObject.question + "..."),
                    //         //   },

                    //         // }}
                    //         //max={maxDateString}
                    //       /></FormGroup></AvForm>

                    <DatePicker
                        id={"questionDate" + key}
                        onChange={(date) => handleDateChange(key, date, question)}
                        className="form-control"
                        placeholderText="dd/mm/yyyy"

                        //maxDate={endDate}
                        dateFormat="dd/MM/yyyy"
                        showDayMonthYearPicker
                    />
                )
            }



        }
        else {
            return (<div className='d-flex align-items-center justify-content-between'><Editable
                className={'d-flex'}
                alwaysEditing={false}
                onValidated={(value) => { handleInlineAnswerChange(value, question) }}
                disabled={false}
                editText={question.answer ? " ✎" : "No value ✎"}
                id={null}
                isValueClickable={false}
                mode="inline"
                placement="top"
                initialValue={question.answer}
                validate={(value) => {
                    if (value.length === 0) {
                        return props.t("please fill this field")
                    }
                }}
                showText
                type="textfield"
            />
                {question.showWhatsapp && question.answer && <div className='ml-auto' style={{ cursor: 'pointer' }}>
                    <a className="bx bxl-whatsapp text-success d-inline-block  font-size-16"
                    ></a><span onClick={() => {
                        setPhoneNumber(question.answer)
                        setOpenTemplateModal(true)
                    }}><u >Send Message</u></span>
                </div>}
            </div>)
        }
    }

    const handleDateChange = (index, changedValue, question) => {
        var customObject = question.parseObject
        if (customObject) {

        } else {
            if (question.databaseTableName === "ChildAdditionalDetails") {
                customObject = getNewChildAdditionalDetailObject()
            }
        }


        customObject.set(question.databaseFieldName, new Date(changedValue))
        props.saveCustomObjects(customObject)
        if (question.databaseTableName === "ChildAdditionalDetails") {
            fillAnswerToRespectiveObject(question, [{ dataField: "answer", answer: new Date(changedValue) }, { dataField: "parseObject", answer: customObject }])

        } else {
            fillAnswerToRespectiveObject(question, [{ dataField: "answer", answer: new Date(changedValue) }])

        }
    };
    function calculateAge(birthDate) {
        if (!birthDate) {
            return "";
        }
        const birthDateTimeStamp = new Date(birthDate).getTime();
        const now = new Date().getTime();

        const ageMilliseconds = now - birthDateTimeStamp;

        const years = Math.floor(ageMilliseconds / (1000 * 60 * 60 * 24 * 365.25));
        const remainingMilliseconds = ageMilliseconds % (1000 * 60 * 60 * 24 * 365.25);
        const months = Math.floor(remainingMilliseconds / (1000 * 60 * 60 * 24 * (365.25 / 12)));

        let ageString = `${years} years`;
        if (months > 0) {
            ageString += ` ${months} months`;
        }

        return ageString;
    }


    return (
        <React.Fragment>
            <TempletModal
                schoolId={schoolIdParameter}
                phoneNumber={phoneNumber}
                openTempateModal={openTempateModal}
                closeTemplateModal={() => {
                    setOpenTemplateModal(!openTempateModal)
                }}
                enquiryId={parameter}
            />
            <div className="page-content">
                <Container fluid>
                    <Row className='d-flex align-items-start'>
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <Breadcrumbs title={props.t('Enquiry Details')} breadcrumbItem="Profile Page" />
                            </div>
                        </Col>
                    </Row>
                    {
                        !props.selectedEnquiry || props.selectedEnquiry.id !== parameter ? <Row>

                            <Col xs="12">
                                <Card>
                                    <CardBody>
                                        <div className="text-center my-3">
                                            <Label to="#" className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {props.t('Loading')} </Label>
                                        </div>
                                    </CardBody>
                                </Card>

                            </Col>
                        </Row>
                            :
                            <Row>
                                <Col lg={8}>
                                    <Card>
                                        <CardBody>
                                            {/* <CardTitle className="mb-4">Enquiry Details</CardTitle> */}
                                            <Table className=" table mb-0 table-bordered">
                                                <tbody>
                                                    {
                                                        questions.map((question, key) => (
                                                            <tr key={key}>
                                                                <th scope="row" style={{ width: "200px" }}>{question.question}</th>
                                                                <td>{getAnswerUI(question, key)}</td>
                                                            </tr>

                                                        ))}

                                                    {enquiryAdditionalQuestions.map((question, key) => (
                                                        <tr key={key}>
                                                            <th scope="row" style={{ width: "200px" }}>{question.question}</th>
                                                            <td>{getAnswerUI(question, key)}</td>

                                                        </tr>

                                                    ))
                                                    }
                                                    {parentAdditionalQuestions.map((question, key) => (
                                                        <tr key={key}>
                                                            <th scope="row" style={{ width: "200px" }}>{question.question}</th>
                                                            <td>{getAnswerUI(question, key)}</td>
                                                        </tr>

                                                    ))
                                                    }
                                                    {childAdditionalQuestions.map((question, key) => (
                                                        <tr key={key}>
                                                            <th scope="row" style={{ width: "200px" }}>{question.question}</th>
                                                            <td>{getAnswerUI(question, key)}</td>
                                                        </tr>

                                                    ))
                                                    }
                                                </tbody>
                                            </Table>
                                        </CardBody>
                                    </Card>

                                    {props.selectedEnquiry && parameter === props.selectedEnquiry.id && <Card>
                                        <CardBody>
                                            <Nav pills className="navtab-bg nav-justified">
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: activeTab === "1",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("1");
                                                        }}
                                                    >
                                                        {props.t("Notes")}
                                                    </NavLink>
                                                </NavItem>
                                                <NavItem>
                                                    <NavLink
                                                        style={{ cursor: "pointer" }}
                                                        className={classnames({
                                                            active: activeTab === "2",
                                                        })}
                                                        onClick={() => {
                                                            toggleTab("2");
                                                        }}
                                                    >
                                                        {props.t("Tasks")}
                                                    </NavLink>
                                                </NavItem>

                                            </Nav>

                                            <TabContent activeTab={activeTab}>
                                                <TabPane tabId="1" className="p-3">

                                                    <button
                                                        className="btn btn-info"
                                                        type="button"

                                                        onClick={() => {
                                                            setShowNoteModel(true);
                                                            setSelectedNoteForEdit(undefined);
                                                        }}
                                                    >
                                                        {props.t('Add New Note')}
                                                    </button>

                                                    {
                                                        addedNotes && addedNotes.length > 0 ?
                                                            <div>
                                                                {
                                                                    addedNotes.map(note =>

                                                                        <Card key={note.id}>
                                                                            <CardBody>

                                                                                <Row>
                                                                                    <Col lg={10}>
                                                                                        <CardTitle>{note.object.attributes.title ? note.object.attributes.title : props.t('Note')}</CardTitle>
                                                                                    </Col>
                                                                                    <Col lg={2}>

                                                                                        <ul className="list-inline font-size-18 contact-links mb-0">
                                                                                            <li className="list-inline-item px-2"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                onClick={() => {
                                                                                                    setShowNoteModel(true);
                                                                                                    setSelectedNoteForEdit(note.object);
                                                                                                    setNoteModelTitle(note.object.attributes.title);
                                                                                                    setNoteModelMessage(note.object.attributes.noteMessage);
                                                                                                }}
                                                                                                id={"note-edit" + note.id}>
                                                                                                <i className="bx bx-edit-alt"></i>
                                                                                                <UncontrolledTooltip placement="top" target={"note-edit" + note.id}>
                                                                                                    {props.t('Edit')}
                                                                                                </UncontrolledTooltip>

                                                                                            </li>
                                                                                            <li className="list-inline-item px-2"
                                                                                                style={{ cursor: "pointer" }}
                                                                                                onClick={() => {
                                                                                                    setSelectedNoteForDelete(note.object);
                                                                                                }}
                                                                                                id={"note-delete" + note.id}>
                                                                                                <i className="bx bx-trash text-danger"></i>
                                                                                                <UncontrolledTooltip placement="top" target={"note-delete" + note.id}>
                                                                                                    {props.t('Delete')}
                                                                                                </UncontrolledTooltip>

                                                                                            </li>
                                                                                        </ul>

                                                                                    </Col>
                                                                                </Row>
                                                                                <CardText style={{ "white-space": "pre-wrap" }}>{note.object.attributes.noteMessage}</CardText>
                                                                                <CardText><small className="text-muted">{note.noteTime}</small></CardText>
                                                                            </CardBody>
                                                                        </Card>
                                                                    )

                                                                }
                                                            </div> :
                                                            <h5></h5>
                                                    }

                                                </TabPane>
                                                <TabPane tabId="2" className="p-3">
                                                    <Col lg={12}>
                                                        <div className='d-flex justify-content-end'>
                                                            <button
                                                                className="btn btn-info  float-right "
                                                                type="button"

                                                                onClick={() => {
                                                                    // var tomorrow = new Date();
                                                                    // tomorrow.setDate(tomorrow.getDate() + 1);
                                                                    setReminderTime(undefined);
                                                                    setShowReminderModel(true);
                                                                    setReminderModelMessage(undefined);
                                                                    setSelectedReminderForEdit(undefined);
                                                                }}
                                                            >
                                                                {props.t('Add New Task')}
                                                            </button>
                                                        </div>
                                                    </Col>
                                                    <Col lg={12} className="mt-5">

                                                        {
                                                            props.enquiryReminders && props.enquiryReminders.length > 0 ?
                                                                <div>
                                                                    {
                                                                        props.enquiryReminders.map(reminder =>

                                                                            <Card key={reminder.id}>
                                                                                <CardBody>
                                                                                    <Row>
                                                                                        <Col lg={10}>
                                                                                            <CardTitle>{reminder.attributes.title ? reminder.attributes.title : props.t('Task')}</CardTitle>
                                                                                        </Col>
                                                                                        <Col lg={2}>

                                                                                            <ul className="list-inline font-size-18 contact-links mb-0">
                                                                                                <li className="list-inline-item px-2"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    onClick={() => {
                                                                                                        setShowReminderModel(true);
                                                                                                        setSelectedReminderForEdit(reminder);
                                                                                                        setReminderTime(reminder.attributes.reminderDateTime);
                                                                                                        setReminderModelMessage(reminder.attributes.body);
                                                                                                    }}
                                                                                                    id={"task-edit" + reminder.id}>
                                                                                                    <i className="bx bx-edit-alt"></i>
                                                                                                    <UncontrolledTooltip placement="top" target={"task-edit" + reminder.id}>
                                                                                                        {props.t('Edit')}
                                                                                                    </UncontrolledTooltip>

                                                                                                </li>
                                                                                                <li className="list-inline-item px-2"
                                                                                                    style={{ cursor: "pointer" }}
                                                                                                    onClick={() => {
                                                                                                        setSelectedReminderForDelete(reminder);
                                                                                                    }}
                                                                                                    id={"task-delete" + reminder.id}>
                                                                                                    <i className="bx bx-trash text-danger"></i>
                                                                                                    <UncontrolledTooltip placement="top" target={"task-delete" + reminder.id}>
                                                                                                        {props.t('Delete')}
                                                                                                    </UncontrolledTooltip>

                                                                                                </li>
                                                                                            </ul>

                                                                                        </Col>
                                                                                    </Row>

                                                                                    <CardText style={{ "whiteSpace": "pre-wrap" }}>{reminder.attributes.body}</CardText>
                                                                                    <CardText><small className="text-muted">{props.t('Task Time') + ":" + getTimeStringForDate(reminder.attributes.reminderDateTime)}</small></CardText>
                                                                                    <div className='m-1' style={{ display: "flex", alignItems: "center" }}>
                                                                                        <p style={{ margin: 0, marginRight: '10px' }}>
                                                                                            <b>Assign To:</b> {reminder.attributes.assignedTo ? getTeacherName(reminder.attributes.assignedTo) : ""}
                                                                                        </p>
                                                                                        <Badge
                                                                                            onClick={() => {
                                                                                                setSelectedTeacher(reminder.attributes.assignedTo);
                                                                                                setReminderToAssign(reminder);
                                                                                                toggleClassModal(true);
                                                                                            }}
                                                                                            style={{ cursor: "pointer" }}
                                                                                            className={`badge font-size-11 m-1 badge-soft-primary`}
                                                                                        >
                                                                                            {reminder.attributes.assignedTo ? "Change" : "Assign to"}
                                                                                            <i className="mdi mdi-chevron-down"></i>
                                                                                        </Badge>
                                                                                    </div>
                                                                                    <div className='m-1' style={{ display: "flex", alignItems: "center" }}>
                                                                                        <Label htmlFor="setSelectedStatus" ><b>Status:</b></Label>
                                                                                        <div
                                                                                            onClick={() => {
                                                                                                setShowStatusAlert(true);
                                                                                                setEnquiryReminderObj(reminder)
                                                                                            }}
                                                                                            style={{ cursor: "pointer" }}
                                                                                            className={reminder.attributes.taskStatus == 0 ? "badge font-size-11 m-1 badge-soft-warning" : "badge font-size-11 m-1 badge-soft-success"}
                                                                                        >
                                                                                            {reminder.attributes.taskStatus == 0 ? "Not completed" : "Completed"}
                                                                                        </div>
                                                                                    </div>
                                                                                </CardBody>
                                                                            </Card>
                                                                        )

                                                                    }
                                                                </div> :
                                                                <h5></h5>}


                                                    </Col>



                                                </TabPane>

                                            </TabContent>
                                        </CardBody>
                                    </Card>}


                                </Col>

                                <Col lg={4}>
                                    <Card>
                                        <CardBody>
                                            <CardTitle className="mb-5">Enquiry TimeLine </CardTitle>
                                            <div className="">
                                                <ul className="verti-timeline list-unstyled">
                                                    {
                                                        timeLine.map((experience, i) =>
                                                            <li key={experience.id} className={experience.id === 1 ? "event-list active" : "event-list"} >
                                                                <div className="event-timeline-dot">
                                                                    <i className={experience.id === 1 ? "bx bx-right-arrow-circle bx-fade-right" : "bx bx-right-arrow-circle"}></i>
                                                                </div>
                                                                <Media>
                                                                    <Row>
                                                                        <Col lg={2}>
                                                                            <div className="mr-3">
                                                                                <i className={"bx " + experience.iconClass + " h4 text-primary"}></i>
                                                                            </div>
                                                                        </Col>
                                                                        <Col lg={10}>
                                                                            <div>
                                                                                <h5 className="font-size-15 text-dark ">{experience.noteMessage}</h5>
                                                                                {experience.createdBy && <span> {"By " + experience.createdBy}<br /></span>}
                                                                                <span className="text-primary">{experience.noteTime}</span>

                                                                            </div>
                                                                        </Col>
                                                                    </Row>
                                                                </Media>
                                                            </li>
                                                        )
                                                    }
                                                </ul>
                                            </div>

                                        </CardBody>
                                    </Card>
                                </Col>

                            </Row>}
                </Container>
            </div>

            {showStatusAlert && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Yes"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Are You Sure"
                    onCancel={() => {
                        setShowStatusAlert(false);
                    }}
                    onConfirm={() => {
                        if (enquiryReminderObj.attributes.taskStatus == 0) {
                            changeStatus(1, enquiryReminderObj)
                        } else {
                            changeStatus(0, enquiryReminderObj)
                        }

                    }}
                >
                    {enquiryReminderObj.attributes.taskStatus == 0 ? "Would you like to change  completed state " : "Would you like to change  not completed state "}
                    <br></br>
                </SweetAlert>
            )}
            <Modal isOpen={classModal} scrollable={true}>
                <ModalHeader isOpen={classModal}>
                    <div className="modal-title mt-0">Assign Teacher</div>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <p>
                        {props.t("Please select Teacher")}
                    </p>

                    <p>
                        {props.t("Showing all the staff who have Enquiry read permission")}
                    </p>


                    {teacherList.map((teacher, i) => (
                        <div key={i}
                        >

                            <Input
                                type="checkbox"
                                className="custom-control-input"
                                id={teacher.id}
                                checked={selectedTeacher == teacher.id}
                                onChange={() => {
                                    setSelectedTeacher(teacher.id);
                                }}


                            >

                            </Input>
                            <span onClick={() => setSelectedTeacher(teacher.id)}>
                                {" " + teacher.attributes.Name + "(" + getDesignation(teacher) + ")"}
                            </span>
                        </div>
                    ))}

                    {teacherList && teacherList.length > 0}
                    {teacherSelectWarning && (
                        <p className="text-danger m-2">
                            {props.t("Please select")}
                        </p>
                    )}

                </ModalBody>
                <ModalFooter>
                    {!loading ? (
                        <div>
                            <button
                                type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setTeacherSelectWarning(false);
                                    toggleClassModal(!classModal);
                                }}
                            >
                                {props.t("Close")}
                            </button>
                            <button
                                type="button"
                                className="btn btn-primary m-2"
                                onClick={() => {
                                    if (selectedTeacher) {
                                        setTeacherSelectWarning(false);
                                        setLoading(true);
                                        saveTeacher()
                                    } else {
                                        setTeacherSelectWarning(true);
                                    }
                                }}
                            >
                                {props.t("Save")}
                            </button>
                        </div>
                    ) : (
                        <button type="button" className="btn btn-light waves-effect">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                            {props.t("")}
                        </button>
                    )}
                </ModalFooter>

            </Modal>

            <Modal
                isOpen={showNoteModel}
                toggle={() => {
                    setShowNoteModel(false);
                    setNoteModelTitle(undefined);
                    setNoteModelMessage(undefined);
                    setSelectedNoteForEdit(undefined);
                }} className="">
                <ModalHeader toggle={() => {
                    setShowNoteModel(false);
                    setNoteModelMessage(undefined);
                    setNoteModelTitle(undefined);
                    setSelectedNoteForEdit(undefined);
                }} tag="h4">
                    Add Note
                </ModalHeader>
                <ModalBody>
                    <AvForm onValidSubmit={handleNoteSubmit}>
                        <Row form>

                            <Col className="col-12">

                                <AvField name="title"
                                    label={props.t('Title')}
                                    type="text"
                                    placeholder={props.t('Title')}
                                    value={noteModelTitle}
                                    onChange={(e) => {
                                        setNoteModelTitle(e.target.value);
                                    }}

                                />

                            </Col>
                            <Col className="col-12">
                                <AvField
                                    name="note"
                                    label={props.t('Note')}
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder={props.t('your note...')}
                                    errorMessage={props.t('Note can not be empty')}
                                    validate={{
                                        required: { value: true }
                                    }}
                                    value={noteModelMessage}
                                    onChange={(e) => {
                                        setNoteModelMessage(e.target.value);
                                    }}
                                />
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end">
                                    <button type="button" className="btn btn-light m-1" onClick={() => {
                                        setShowNoteModel(false);
                                        setNoteModelMessage(undefined);
                                        setNoteModelTitle(undefined);
                                        setSelectedNoteForEdit(undefined);
                                    }}>{props.t('Close')}</button>
                                    <button type="submit" className="btn btn-success save-event m-1">{props.t('Save')}</button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>

            {/* REMINDER--TASK */}
            <Modal
                isOpen={showReminderModel}

            >
                <ModalHeader toggle={() => {
                    setShowReminderModel(false);
                    setNoteModelMessage(undefined);
                    setReminderModelMessage(undefined);
                    setReminderTime(undefined);
                    setReminderTimeError(false);
                    setReminderMessageError(false);
                    setSelectedReminderForEdit(undefined);
                }} tag="h4">
                    {props.t('Add Task')}
                </ModalHeader>
                <ModalBody>
                    <AvForm >
                        <Row form>


                            <Col className="col-12">
                                <AvField
                                    name="note"
                                    label={props.t('Task')}
                                    type="textarea"
                                    className="form-control"
                                    rows="3"
                                    placeholder={props.t('your task...')}

                                    value={reminderModelMessage}
                                    onChange={(e) => {
                                        if (e.target.value.length > 0 && reminderMessageError) {
                                            setReminderMessageError(false);
                                        }
                                        setReminderModelMessage(e.target.value);
                                    }}
                                />
                            </Col>

                            {reminderMessageError && <p className="text-danger">{props.t('Task can not be empty')}</p>}

                            <Col className="col-12">

                                <Label>{props.t('Task Time')}</Label>

                                {/* <AvField name="title"
                                    label={props.t('Task Time')}
                                    type="date"
                                    placeholder="dd/MM/yyyy hh:mm a"
                                    value={reminderTime}
                                    errorMessage={props.t('Please input time')}
                                    required
                                    onChange={(e) => {
                                        setReminderTime(e.target.value);
                                    }}
                                    
                                /> */}


                                {/* <DatePicker
                                    selected={reminderTime}
                                    onChange={(d) => {
                                        if (reminderTimeError) {
                                            setReminderTimeError(false);
                                        }
                                        setReminderTime(d)
                                    }}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy hh:mm a"
                                    showTimeInput
                                    minDate={new Date()}
                                    
                                    validate={{
                                        required: { reminderTime: true }
                                    }}
                                    errorMessage={props.t('Please input time')}
                                /> */}
                                {/* <input
                                    className="form-control"
                                    type="datetime-local"
                                    defaultValue={reminderTime ? Moment(reminderTime).format('YYYY-MM-DDTh:mm') : ""}
                                    id="example-datetime-local-input"
                                    required
                                    onChange={(e) => {
                                        if (reminderTimeError) {
                                            setReminderTimeError(false);
                                        }
                                        setReminderTime(new Date(e.target.value))
                                    }}
                                /> */}
                                <DatePicker
                                    selected={reminderTime}
                                    onChange={(d) => {
                                        if (reminderTimeError) {
                                            setReminderTimeError(false);
                                        }
                                        setReminderTime(d)
                                    }}
                                    className="form-control"
                                    dateFormat="dd/MM/yyyy hh:mm a"
                                    showTimeSelect
                                    timeIntervals={10}
                                    timeCaption="Time"
                                    validate={{
                                        required: { reminderTime: true }
                                    }}
                                />
                                {reminderTimeError && <p className="text-danger">{props.t('Please input time')}</p>}
                            </Col>

                        </Row>
                        <Row>
                            <Col>
                                <div className="d-flex justify-content-end mt-2">
                                    <button type="button" className="btn btn-light m-1 " onClick={() => {
                                        setShowReminderModel(false);
                                        setReminderModelMessage(undefined);
                                        setReminderTime(undefined);
                                        setReminderTimeError(false);
                                        setReminderMessageError(false);
                                        setSelectedReminderForEdit(undefined);
                                    }}>{props.t('Close')}</button>
                                    <button onClick={() => {
                                        if (!reminderModelMessage || reminderModelMessage.length === 0) {
                                            setReminderMessageError(true);
                                            if (!reminderTime) {
                                                setReminderTimeError(true);
                                            }
                                            return;
                                        }
                                        if (!reminderTime) {
                                            setReminderTimeError(true);
                                            return;
                                        }
                                        handleReminderSubmit();

                                    }}
                                        className="btn btn-success save-event m-1">{props.t('Save')}</button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>

            {selectedNoteForDelete || selectedReminderForDelete ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        if (selectedNoteForDelete) {
                            props.deleteEnquiryNote(selectedNoteForDelete);
                            setSelectedNoteForDelete(undefined);
                            setsuccess_dlg("Note");
                        } else if (selectedReminderForDelete) {
                            props.deleteEnquiryReminder(selectedReminderForDelete);
                            setSelectedReminderForDelete(undefined);
                            setsuccess_dlg("Task");
                        }

                    }}
                    onCancel={() => {
                        setSelectedNoteForDelete(undefined);
                        setSelectedReminderForDelete(undefined);
                        setsuccess_dlg(undefined);

                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        setsuccess_dlg(undefined)
                    }}
                >
                    {"Your " + success_dlg + " has been deleted."}
                </SweetAlert>
            ) : null}
        </React.Fragment>

    );

}
const mapStatetoProps = state => {
    const { selectedEnquiry, enquiryTemplates } = state.Enquiry;
    const { enquiryNotes } = state.EnquiryNotes;
    const { enquiryQuestions } = state.EnquiryQuestions
    const { enquiryReminders } = state.EnquiryReminder;
    const { selectedParents } = state.Parent;
    const { childAdditionalDetailsObject } = state.ChildAdditionalDetails
    const { schools, selectedSchool } = state.School;
    const { teachers } = state.Teacher;
    return { selectedEnquiry, enquiryTemplates, enquiryNotes, enquiryReminders, selectedParents, childAdditionalDetailsObject, enquiryQuestions, selectedSchool, teachers };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdEnquiry, getEnquirynoteForEnquiryId, editEnquiryNote, getEnquiryReminderforEnquiryId, editEnquiryReminder,
    getEnquiryQuestionForSchoolId, getParentsForFamilyId, getChildAdditionalDetailForEnquiryId, saveCustomObjects,
    deleteEnquiryNote, deleteEnquiryReminder, setSelecetdSchool, getTeachersForSchool, getEnquiryRemindersForSchoolId, saveEnquiryReminder
})(EnquiryProfile)));
