import * as actions from "./actionTypes";

const initialState = {
  learningCategories: [], selectedLearningCategory: undefined, categoryLoadingState:true,
};

const LearningCategory = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_LEARNING_CATEGORY:
      var learningCategories = [...state.learningCategories];
      var temp = learningCategories.filter(
        (learningCategory) =>
        learningCategory.id === action.payload.learningCategory.id
      );
      if (temp.length > 0) {

        if (action.payload.learningCategory.attributes.isDeleted) {

          var others = learningCategories.filter(
            (learningCategory) =>
            learningCategory.id !== action.payload.learningCategory.id
          );
          state = { ...state, learningCategories: others };
          
        } else {
          let index = learningCategories.indexOf(temp[0]);
          learningCategories[index] = action.payload.learningCategory;
          state = { ...state, learningCategories: learningCategories };
        }

       
      } else {
        if (!action.payload.learningCategory.attributes.isDeleted) {
          learningCategories.push(action.payload.learningCategory) 
          state = { ...state, learningCategories: learningCategories};
        }
        
      }
      break;
    case actions.UPDATE_LEARNING_CATEGORYS:
      var learningCategories = [...state.learningCategories];
      if (action.payload.type === "replace") {
        state = { ...state, learningCategories: action.payload.learningCategories };
      } else if (learningCategories.length > 0) {
        for (const latest in action.payload.learningCategories) {
          let temp = learningCategories.filter(
            (learningCategory) =>
            learningCategory.id === action.payload.learningCategories[latest].id
          );

          
          if (temp.length > 0) {
            if (action.payload.learningCategories[latest].attributes.isDeleted) {

              var others = learningCategories.filter(
                (learningCategory) =>
                learningCategory.id !== action.payload.learningCategories[latest].id
              );
             
              learningCategories = others;
            } else {
              var index = learningCategories.lastIndexOf(temp[0]);
              learningCategories[index] = action.payload.learningCategories[latest];
            }
           
          } else {
            if (!action.payload.learningCategories[latest].attributes.isDeleted) {
              learningCategories.push(action.payload.learningCategories[latest]);
            }
            
          }
        }
        state = { ...state, learningCategories: learningCategories };
      } else {

        var others = action.payload.learningCategories.filter(
          (learningcategory) => (!learningcategory.attributes.isDeleted)
        );
        state = { ...state, learningCategories: others };
      }
      break;
    case actions.DELETE_LEARNING_CATEGORY:
      var learningCategories = state.learningCategories.filter(
        (learningcategory) => learningcategory.id !== action.payload.learningCategory.id
      );
      state = { ...state, learningCategories: learningCategories };
      break;
    
    case actions.CLEAR_LEARNING_CATEORIES:
      state = { ...state, learningCategories: [] };
      break;
      
     
    case actions.SET_LEARNING_CATEGORY_LOADING_STATE:
      state = { ...state, categoryLoadingState: action.payload.state };
      break;
    
      case actions.SET_SELECTED_LEARNING_CATEGORY:
        state = { ...state, selectedLearningCategory: action.payload.learningCategory };
        break;
    
     
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LearningCategory;
