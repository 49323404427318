import React from 'react';
import { Col, Card, CardBody, Media } from "reactstrap";

const CardPricing = (props) => {

    return (
        <React.Fragment>
            <Col xl="4" md="6" sm="12">
                <Card className="plan-box border" style={{ 'minHeight': '8.5cm' }}>
                    <CardBody className="p-4">
                        <Media>
                            <Media body>
                                <h5>{props.pricing.title}</h5>
                                <p className="text-muted">{ }</p>
                            </Media>
                            <div className="ml-3">
                                <i className={"bx " + props.pricing.icon + " h4 text-primary"}></i>
                            </div>
                        </Media>
                        <div className="py-2">
                            <h3>
                                <sup>
                                    <small>
                                        <i className="bx bx-rupee"></i>
                                        <del>{props.pricing.priceBefore} </del>
                                    </small>
                                </sup>
                                
                                {props.pricing.price}/
                                <span className="font-size-13">{props.pricing.duration}</span>
                            </h3>
                        </div>
                        <div className="text-center plan-btn">
                            {props.pricing.id === props.showLoading ?
                                
                                <button
                                    className="btn btn-primary btn-sm waves-effect waves-light"
                                >
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>Processing
                                </button>
                                :
                                <button
                                    className="btn btn-primary btn-sm waves-effect waves-light"
                                    onClick={() => { props.onClickEnquireNow(props.pricing) }}
                                >
                                    Enquire Now
                                </button>}
                        </div>

                        <div className="plan-features mt-2">
                            {
                                props.pricing.features.map((feature, key) =>
                                    <p key={"_feature_" + key} className="m-1">
                                        <i className="bx bx-checkbox-square text-primary mr-2"></i> {feature.title}
                                        { feature.title2 && <br />}
                                        {feature.title2 && <i className="ml-3">{feature.title2}</i>}
                                    </p>
                                )
                            }
                        </div>
                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>
    );
}

export default CardPricing;
