import Parse from "parse";
import { printLog } from "./loghelper";
const getLearningFilterFromParse = (learningFilterId) => {
  printLog("HL: learningFilters_helper : getLearningFilterFromParse")

  try {
    return new Promise((resolve, reject) => {
      var LearningFilter = Parse.Object.extend("LearningFilter");
      var query = new Parse.Query(LearningFilter);
      query.get(learningFilterId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const deleteLearningFilterFromParse = (learningFilterId) => {
  printLog("HL: learningFilters_helper : deleteLearningFilterFromParse")

  try {
    return new Promise((resolve, reject) => {
      const LearningFilter = Parse.Object.extend("LearningFilters");
      var learningFilter = new LearningFilter();
      learningFilter.id = learningFilterId;
      learningFilter.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        learningFilter.set(
          "deletedBy",
          Parse.User.current().attributes.teacherId
        );
      } else {
        learningFilter.set("deletedBy", Parse.User.current().id);
      }

      learningFilter.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

const getLearningFilterForcurriculumId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningFilters_helper : getLearningFilterForcurriculumId")

  try {
    return new Promise((resolve, reject) => {
      var LearningFilter = Parse.Object.extend("LearningFilters");
      var query = new Parse.Query(LearningFilter);
      query.equalTo("curriculumId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("deleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("deleted", true);
      }

      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export {
  getLearningFilterFromParse,
  deleteLearningFilterFromParse,
  getLearningFilterForcurriculumId,
};
