import Parse from "parse";
import { printLog } from "./loghelper";

export const getCourseBatchFromParse = (courseId, isLocal) => {
  printLog("HL: courseBatch_helper : getCourseBatchFromParse")
  try {
    return new Promise((resolve, reject) => {
      var CourseBatch = Parse.Object.extend("CourseBatch");
      var query = new Parse.Query(CourseBatch);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.get(courseId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}

export const deleteCourseBatchFromParse = (courseBatch) => {
  printLog("HL: courseBatch_helper : deleteCourseBatchFromParse")
  try {
    return new Promise((resolve, reject) => {

      courseBatch.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        courseBatch.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        courseBatch.set("deletedBy", Parse.User.current().id);
      }

      courseBatch.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
}

export const getCourseBatchForCourseId = (id, updatedDate, isLoacal) => {
  printLog("HL: courseBatch_helper : getCourseBatchForCourseId")
  try {
    return new Promise((resolve, reject) => {
      var CourseBatch = Parse.Object.extend("CourseBatch");
      var query = new Parse.Query(CourseBatch);
      query.equalTo("courseId", id);

      // if (updatedDate) {
      //   query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      // } else {
      //   query.notEqualTo("isDeleted", true);
      // }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}

export const saveCourseBatchObject = (object) => {
  printLog("HL: courseBatch_helper : saveCourseBatchObject")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    });
  } catch (error) { }
}

export const getCourseBatchForCourseIds = (courseIds, isLocal) => {
  printLog("HL: courseBatch_helper : getCourseBatchForCourseIds")
  try {
    return new Promise((resolve, reject) => {
      var CourseBatch = Parse.Object.extend("CourseBatch");
      var query = new Parse.Query(CourseBatch);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.containedIn("courseId", courseIds);
      query.ascending("startDate");
      query.greaterThanOrEqualTo("endDate", new Date());
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}