import React, { useState,useEffect } from 'react';

import { Collapse, Card, CardBody, Label, Form, FormGroup, InputGroupText } from "reactstrap";
import Nouislider from "nouislider-react";
import "nouislider/distribute/nouislider.css";

const Target = (props) => {

    const AudienceType = [
        { name: "Children", state: false },
        { name: "Parents", state: false },
        { name: "Teachers", state: false },
    ];
     const ModeTags= [
        { name: "Online", state: false },
        { name: "In-Person", state: false },
        { name: "Blended", state: false },
      ]
    const Categorys = [
        { name: "Solid Waste Management", state: false },
        { name: "City Wide Inclusive Sanitation", state: false },
        { name: "Water Management", state: false },
        { name: "Used Water Management", state: false },
        { name: "Innovations in WASH", state: false },
        { name: "Others", state: false },
       
    ];
    const [modeTag, setModeTag] = useState();
    const [audienceTags, setAudienceTags] = useState([]);
    const [categoryTags, setcategoryTags] = useState([]);
    const [language, setLanguage] = useState();

    const [ageTagMin, setAgeTagMin] = useState(undefined);
    const [ageTagMix, setAgeTagMix] = useState(undefined);


    useEffect(() => {
        if (props.courseObject) {
            setModeTag(props.courseObject.attributes.mode)
            setAudienceTags(props.courseObject.attributes.courseType??[])
            setcategoryTags(props.courseObject.attributes.categories??[])
            setLanguage(props.courseObject.attributes.primaryLanguage)
            setAgeTagMin(props.courseObject.attributes.ageTagMin)
            setAgeTagMix(props.courseObject.attributes.ageTagMix)
           
        }
    }, [props.courseObject]);

    const handelAudienceTags = (tag) => {
        let list = [...audienceTags];
        if (list.includes(tag)) {

            var others = list.filter(
                (t) => t !== tag
            );
            setAudienceTags(others);
        } else {
            list.push(tag);
            setAudienceTags(list);
        }
        
    }
    const handelCategoryTags = (tag) => {
        let list = [...categoryTags];
        if (list.includes(tag)) {

            var others = list.filter(
                (t) => t !== tag
            );
            setcategoryTags(others);
        } else {
            list.push(tag);
            setcategoryTags(list);
        }
        
    }

    const onClickSave = (isNext) => {
        let tempObject = props.courseObject;

        tempObject.set("mode", modeTag);
        tempObject.set("courseType", audienceTags);
        tempObject.set("categories",categoryTags)
        if (language !== "Select Primary Language") {
            tempObject.set("primaryLanguage", language);
        }
        
        if (ageTagMin > 0 && ageTagMix > 0) {
            tempObject.set("ageTagMin", parseInt(ageTagMin));
            tempObject.set("ageTagMix", parseInt(ageTagMix));
        }
        
        props.onSaveCourseObject(tempObject,isNext?"3":undefined);

    }
    
    return (
        <Card>
            <CardBody>

                <Form>
                    <FormGroup className="position-relative">
                        <Label>Primary Language</Label>
                        <FormGroup className="mb-0">
                            <InputGroupText
                                name="customSelect"
                                value={language}
                                onChange={(e) => { setLanguage(e.target.value) }}
                                id="primary_language"
                                type="select"
                                className="form-control"
                            >
                                <option defaultValue>Select Primary Language</option>
                                <option value="English">English</option>
                                <option value="Kannada">Kannada</option>
                                <option value="Hindi">Hindi</option>
                            </InputGroupText>
                        </FormGroup>
                    </FormGroup>

                    <FormGroup className="position-relative">
                        <Label>Mode</Label>
                        <div className="tagcloud mt-1">
                            {ModeTags.map((tag, key) => (
                                <Label
                                    onClick={() =>
                                        setModeTag(tag.name)
                                    }
                                    key={key}
                                    id={tag.name}
                                    className={
                                        modeTag === tag.name
                                            ? "btn btn-primary mr-1 mb-1 btn-sm"
                                            : "btn btn-outline-light mr-1 mb-1 btn-sm"
                                    }
                                >
                                    {tag.name}
                                </Label>
                            ))}
                        </div>
                    </FormGroup>
                     {/* <FormGroup className="position-relative">
                      <Label>Audience Type</Label>
                      <div className="tagcloud mt-1">
                        {AudienceType.map((tag, key) => (
                          <Label
                            key={key}
                            onClick={() => handelAudienceTags( tag.name)}
                            id={tag.name}
                            className={
                              audienceTags&&audienceTags.includes(tag.name)
                                ? "btn btn-primary mr-1 mb-1 btn-sm"
                                : "btn btn-outline-light mr-1 mb-1 btn-sm"
                            }
                          >
                            {tag.name}
                          </Label>
                        ))}
                      </div>
                    </FormGroup> */}

                    {/* <FormGroup className="position-relative">
                         <Label>Age Group</Label>
                        <div className="mt-3 ml-mr-4 pt-3">
                           
                            <br />
                            <Nouislider
                                range={{ min: 1, max: 60 }}
                                step={1}
                                tooltips={true}
                                start={[ageTagMin??4, ageTagMix??50]}
                                onChange={(val) => {
                                    setAgeTagMin(parseInt(val[0]));
                                    setAgeTagMix(parseInt(val[1]));
                                }}
                                
                                connect />
                        </div>
                    </FormGroup> */}

                     <FormGroup className="position-relative">
                      <Label>Categories</Label>
                      <div className="tagcloud mt-1">
                        {Categorys.map((tag, key) => (
                          <Label
                            key={key}
                            onClick={() => handelCategoryTags( tag.name)}
                            id={tag.name}
                            className={
                              categoryTags && categoryTags.includes(tag.name)
                                ? "btn btn-primary mr-1 mb-1 btn-sm"
                                : "btn btn-outline-light mr-1 mb-1 btn-sm"
                            }
                          >
                            {tag.name}
                          </Label>
                        ))}
                      </div>
                    </FormGroup>
                </Form>

                {props.saveInprogress ? <div>
                    <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>
                    <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>
                </div>
                    :
                    <div>
                        <button className="btn btn-primary m-1" onClick={() => { onClickSave() }}> Save </button>
                        <button className="btn btn-primary m-1" onClick={() => { onClickSave(true) }}> Save & Next</button>
                    </div>
                }

            </CardBody>
        </Card>
    );

}
export default Target;