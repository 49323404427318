import Parse from "parse";

export const getTicketFromParse = (ticketId) => {
  try {
    return new Promise((resolve, reject) => {
      var Ticket = Parse.Object.extend("Tickets");
      var query = new Parse.Query(Ticket);
      query.get(ticketId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getTicketFromLocal = (ticketId) => {
  try {
    return new Promise((resolve, reject) => {
      var Ticket = Parse.Object.extend("Tickets");
      var query = new Parse.Query(Ticket);
      query.fromLocalDatastore();
      query.get(ticketId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};



export const deleteTicketFromParse = (ticket) => {
  try {
    return new Promise((resolve, reject) => {
      ticket.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        ticket.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        ticket.set("deletedBy", Parse.User.current().id);
      }
      ticket.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("error");
        }
      );
    });
  } catch (error) { }
};

export const getTicketsForRecipientId = (recipientId, recipientType, updatedDate, isLocal,) => {
  try {
    return new Promise((resolve, reject) => {
      var Ticket = Parse.Object.extend("Tickets");
      var query = new Parse.Query(Ticket);
      query.equalTo("recipientId", recipientId);
      query.equalTo("recipientType", recipientType)

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending('createdAt')
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getTicketsForAssignedFor = (assignedFor, assignedForType, updatedDate, isLocal,) => {
  try {
    return new Promise((resolve, reject) => {
      var Ticket = Parse.Object.extend("Tickets");
      var query = new Parse.Query(Ticket);
      query.equalTo("assignedFor", assignedFor);
      query.equalTo("assignedForType", assignedForType)

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending('createdAt');
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};


export const editTicket = (ticket) => {
  try {
    return new Promise((resolve, reject) => {
      ticket.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

export const saveCustomObjects = (object) => {
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log(error)
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

export const getTicketLogFromParseForTicketId = (ticketId) => {
  try {
    return new Promise((resolve, reject) => {
      var TicketLog = Parse.Object.extend("TicketLog");
      var query = new Parse.Query(TicketLog);
      query.equalTo("ticketId",ticketId);
      query.notEqualTo("isDeleted", true);
      query.descending('createdAt')
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};


