import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as quizSessionHelper from "../../../helpers/ParseHelpers/quizSession_helper";
import * as actions from "./actionTypes";
import * as Constants from '../../../Constents';
import * as ProgramActions from './actions';
import Parse from 'parse';




function* getCourseQuizSessionsForKid({ payload: { courseId, kidId,batchId } }) {
    try {
        const response = yield call(quizSessionHelper.getCourseQuizSessionsForKid, courseId, kidId,batchId);
        yield put(ProgramActions.updateExamCourseSessionsForKidId(response));
        Parse.Object.pinAll(response);
    } catch (error) {
        // yield put(apiError(error));
    }
}

function* getCourseQuizSessionsForCourse({ payload: { courseId } }) {
    try {
        const response = yield call(quizSessionHelper.getCourseQuizSessionsForCourseId, courseId);
        yield put(ProgramActions.updateExamCourseSessionsForCourseId(response));
        Parse.Object.pinAll(response);
    } catch (error) {
        // yield put(apiError(error));
    }
}



export function* watchGetCourseQuizSessionsForKid() {
  yield takeEvery(actions.GET_COURSE_SESSIONS_FOR_KID, getCourseQuizSessionsForKid);
}
export function* watchGetCourseQuizSessionsForCourse() {
  yield takeEvery(actions.GET_COURSE_SESSIONS_FOR_COURSE_ID, getCourseQuizSessionsForCourse);
}
function* QuizSessionSaga() {
  yield all([fork(watchGetCourseQuizSessionsForKid),
  fork(watchGetCourseQuizSessionsForCourse),
  ]);
}
export default QuizSessionSaga;