import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  UPDATE_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
  UPDATE_USER_TEACHER,
  GET_USER_TEACHER,
  SET_LOGIN_LOADING,
  VERIFY_USER_ERROR,
} from "./actionTypes";

export const loginUser = (user, history) => {
  return {
    type: LOGIN_USER,
    payload: { user, history },
  };
};

export const loginSuccess = (user) => {
  return {
    type: LOGIN_SUCCESS,
    payload: { user },
  };
};

export const updateUser = () => {
  return {
    type: UPDATE_USER,
    payload: {},
  };
};

export const updateUserTeacher = (teacher) => {
  return {
    type: UPDATE_USER_TEACHER,
    payload: { teacher },
  };
};
export const getUserTeacher = (teacherId) => {
  return {
    type: GET_USER_TEACHER,
    payload: { teacherId },
  };
};

export const logoutUser = (history) => {
  return {
    type: LOGOUT_USER,
    payload: { history },
  };
};

export const logoutUserSuccess = () => {
  return {
    type: LOGOUT_USER_SUCCESS,
    payload: {},
  };
};

export const apiError = (error) => {
  return {
    type: API_ERROR,
    payload: { error },
  };
};
export const setLodingState = (state) => {
  return {
    type: SET_LOGIN_LOADING,
    payload: { state },
  };
};

export const setVerifyUserError = (state) => {
  return {
    type: VERIFY_USER_ERROR,
    payload: { state },
  };
};
