import React, { useEffect, useState } from 'react';
import { Row, Col, Card, CardBody, Container, Media, Button, Label, FormGroup } from "reactstrap";
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import TopBar from './Topbar';
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import { getSchoolAnalytics, editSchool, getFranchise } from '../../store/actions';
import logo from "../../assets/images/lilLogo.png";
import * as Constants from "../../Constents";
import Moment from "moment";
import Editable from "react-bootstrap-editable";
import DatePicker from "react-datepicker";
import school from '../../store/model/school/reducer';

const SchoolStatistics = (props) => {


    const [schoolMasterList, setSchoolMasterList] = useState([]);
    const [schoolStats, setSchoolStats] = useState([]);

    const [schoolList, setSchoolList] = useState([]);
    const [selectedState, setSelectedState] = useState(undefined);
    const [selectedCity, setSelectedCity] = useState(undefined);
    const [selectedArea, setSelectedArea] = useState(undefined);
    const [allCityList, setAllCityList] = useState([]);
    const [allAreaList, setAllAreaList] = useState([]);
    const [error, setError] = useState(props.t('No branch found'));
    const [showDocumentsButton, setShowDocumentsButton] = useState(false);

    const [fromDate, setFromDate] = useState(undefined);
    const [fromDateError, setFromDateError] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    const [toDateError, setToDateError] = useState(undefined);

    const [schoolStatCall, setSchoolStatCall] = useState(false);

    const [activeState, setActiveState] = useState(10);

    const activeOptions = [

        { value: 10, label: "All " },
        { value: 11, label: "All Active " },
        { value: 12, label: "All Non Active" },
        { value: 0, label: 'Newly Created' },
        { value: 1, label: 'Newly Created And Active' },
        { value: 2, label: "Not Used" },
        // { value: 3, label: "Active & Running" },
        { value: 4, label: "Active in this week" },
        { value: 5, label: "Active One week ago" },
        { value: 6, label: "Non Active in 15 days" },
        { value: 7, label: "Non Active" },
        { value: 8, label: "Subscription Expired" },

    ];


    useEffect(() => {
        if (props.franchise) {
            if (props.franchise.attributes.features &&
                props.franchise.attributes.features.length > Constants.FRANCHISE_DOCUMENT_INDEX &&
                props.franchise.attributes.features[Constants.FRANCHISE_DOCUMENT_INDEX] > 0) {
                setShowDocumentsButton(true)
            }
        }
        getSchoolStatistics();
    }, [props.franchise]);

    useEffect(() => {
        if (Parse.User.current().attributes.franchiseId) {
            if (!props.franchise) {
                // props.getFranchise(Parse.User.current().attributes.franchiseId);
            }
            var tempList = [...props.schools];
            //setSchoolList(tempList);
            //props.getSchoolAnalytics(Parse.User.current().attributes.franchiseId, "franchise");
        } else {
            props.history.push("/pages-404")
        }

    }, [props.schools]);



    const getSchoolStatistics = () => {
        if (!schoolStatCall && Parse.User.current().attributes.franchiseId) {
            setSchoolStatCall(true);

            Parse.Cloud.run("function_getSchoolAnalyticsForFranchise", {
                franchiseId: Parse.User.current().attributes.franchiseId
            }).then((response) => {

                // console.log(response);
                setSchoolStats(response)
            }, (error) => {
                console.log("error" + error.message)
            });
        }
    }

    useEffect(() => {
        let list = []
        if (props.schools && props.schools.length > 0 && schoolStats && schoolStats.length > 0) {
            // console.log("test" , props.schools," stats",schoolStats)
            for (const s of props.schools) {
                for (const ss of schoolStats) {
                    if (s.id == ss.attributes.schoolId) {
                        list.push({
                            id: s.id,
                            school: s,
                            analytics: ss,
                        })
                        break;
                    }
                }
            }
        }
        // console.log("list" , list)
        setSchoolMasterList(list);
        setSchoolList(list)
    }, [props.schools, schoolStats]);


    useEffect(() => {
        props.handelSchoolCount(schoolList ? schoolList.length : 0);
    }, [schoolList]);
    useEffect(() => {
        handelSearch(props.searchString);
    }, [props.searchString]);
    const handelSearch = (search) => {
        var tempList = [...schoolMasterList];
        if (!tempList || tempList.length == 0) {
            return [];
        }
        if (search === undefined || search === null || search.length === 0) {
            setSchoolList(tempList);
        } else if (tempList) {
            var filter = tempList.filter(function (item) {
                return (item.school.attributes.Name.toLowerCase()).includes(search.toLowerCase());
            });
            if (!filter || filter.length == 0) {
                setError(props.t('No branch found for the search'));
            }
            setSchoolList(filter);
        }
    }
    const filterSchools = (stCode, city, area, fromDate, toDate, activeState) => {

        let displayLis = [];
        if (stCode && city && area) {
            let tempList = [...schoolMasterList];

            let filterS = tempList.filter(function (item) {
                return (item.school.attributes.areaCode === stCode);
            });

            let filterC = filterS.filter(function (item) {
                return (item.school.attributes.city === city);
            });
            let filterA = filterC.filter(function (item) {
                return (item.school.attributes.areaCode === stCode);
            });

            displayLis = [...filterA];
        } else if (stCode && city) {
            let tempList = [...schoolMasterList];

            let filter1 = tempList.filter(function (item) {
                return (item.school.attributes.areaCode === stCode);
            });

            let filter = filter1.filter(function (item) {
                return (item.school.attributes.city === city);
            });

            if (filter && filter.length > 0) {
                let arealist = [];
                for (const s of filter) {
                    if (s.school.attributes.locality && !arealist.includes(s.school.attributes.locality)) {
                        arealist.push(s.school.attributes.locality);
                    }
                }
                // console.log("arealist", arealist)
                setAllAreaList(arealist);

            }
            displayLis = [...filter]

        } else if (stCode) {
            let tempList = [...schoolMasterList];
            let filter = tempList.filter(function (item) {
                return (item.school.attributes.areaCode === stCode);
            });

            if (filter && filter.length > 0) {
                let citylist = [];
                for (const s of filter) {
                    if (!citylist.includes(s.school.attributes.city)) {
                        citylist.push(s.school.attributes.city);
                    }

                }
                setAllCityList(citylist)
            }
            displayLis = [...filter]
        } else {
            displayLis = [...schoolMasterList]
        }


        if (fromDate && displayLis) {
            let filter = displayLis.filter(function (item) {
                return (item.attributes.createdAt >= fromDate);
            });
            displayLis = [...filter]
        }

        if (toDate && displayLis) {
            let filter = displayLis.filter(function (item) {
                return (item.attributes.createdAt <= toDate);
            });
            displayLis = [...filter]
        }

        if (activeState && activeState == 8 && displayLis) {
            let acList = []
            for (const ss of displayLis) {

                let subExp = ss.analytics.attributes.subscriptionExpiryDate;
                if (subExp && subExp < new Date()) {
                    acList.push(ss);
                }
            }
            displayLis = [...acList];
        } else if (activeState && (activeState == 11 || activeState == 12) && displayLis) {
            let acList = []
            for (const ss of displayLis) {

                let activity = getDaysCount(ss.analytics.attributes.lastMessageSent);
                let created = getDaysCount(ss.analytics.attributes.schoolCreatedOn);

                if (activeState == 12) {
                    if (activity == -1 && created >= 15) {
                        acList.push(ss)
                    } else if (activity >= 15) {
                        acList.push(ss)
                    }
                } else if (activeState == 11) {
                    if (activity == -1 && created < 15) {
                        acList.push(ss)
                    } else if (activity < 15) {
                        acList.push(ss)
                    }
                }
            }
            displayLis = [...acList];
        } else if (activeState && activeState != 10 && displayLis) {
            let acList = []

            for (const ss of displayLis) {
                let activity = getDaysCount(ss.analytics.attributes.lastMessageSent);
                let created = getDaysCount(ss.analytics.attributes.schoolCreatedOn)
                // console.log("activity:- ",activity)
                // if (activeState == 3 && activity <= 30) {
                //     acList.push(ss)
                // } else if (activeState == 2 && activity <= 15) {
                //     acList.push(ss)
                // } else if (activeState == 1 && activity <= 8) {
                //     acList.push(ss)
                // } else if (activeState == 0 && activity <= 6) {
                //     acList.push(ss)
                // }

                if (activity == -1 && created < 8) {
                    if (activeState == 0) {
                        acList.push(ss);
                    }
                }
                else if (activity < 6 && created < 8) {
                    if (activeState == 1) {
                        acList.push(ss);
                    }
                } else if (activity == -1 && created >= 8) {
                    if (activeState == 2) {
                        acList.push(ss);
                    }
                } else if (activity < 4 || activity < 8) {
                    if (activeState == 3 || activeState == 4) {
                        acList.push(ss);
                    }
                } else if (activity < 8) {
                    if (activeState == 4) {
                        acList.push(ss);
                    }
                } else if (activity < 15) {
                    if (activeState == 5) {
                        acList.push(ss);
                    }
                } else if (activity < 31) {
                    if (activeState == 6) {
                        acList.push(ss);
                    }
                } else if (activity >= 31) {
                    if (activeState == 7) {
                        acList.push(ss);
                    }
                }
            }
            displayLis = [...acList];
        }
        setSchoolList(displayLis);

    }

    const getAnalyticsObject = (schoolId) => {
        if (props.schoolAnalytics && props.schoolAnalytics.length > 0) {
            for (const sa of props.schoolAnalytics) {

                if (sa.attributes.schoolId == schoolId) {
                    return sa;
                }
            }
        }

        return undefined;
    }

    const getAnalyticsUI = (school) => {
        let analyticsObject = getAnalyticsObject(school.id)
        if (analyticsObject) {
            return (
                <Media className="overflow-hidden ml-4" body>
                    <p className="text-muted mb-0">{"All Students: " + analyticsObject.attributes.studentCount}</p>
                    <p className="text-muted mb-0">{"Daycare Students: " + analyticsObject.attributes.daycareStudentCount}</p>
                    <p className="text-muted mb-0">{"Directors/Admins: " + analyticsObject.attributes.directorCount}</p>
                    <p className="text-muted mb-0">{"Teachers: " + analyticsObject.attributes.teacherCount}</p>
                </Media>
            )
        }
    }

    const getSubExp = (school) => {
        let subExp = school.analytics.attributes.subscriptionExpiryDate;
        if (subExp) {

            if (subExp > new Date) {
                return (
                    <p className="text-primary mb-0">{"Expires on: " + Moment(subExp).format("DD/MM/YYYY")}</p>
                );
            } else {
                return (
                    <p className="text-danger mb-0">{"Expired On: " + Moment(subExp).format("DD/MM/YYYY")}</p>
                );
            }


        }
    }

    const activeStat = (school) => {
        let activity = getDaysCount(school.analytics.attributes.lastMessageSent)
        let created = getDaysCount(school.analytics.attributes.schoolCreatedOn)

        // console.log(activity, created)
        let active = 'Active: Today'
        if (activity > 1) {
            active = 'Active: ' + activity + ' days Ago'
        }

        // console.log("....", (activity <= 0 ) ? "t" : "f");

        if (activity == -1 && created < 8) {
            return (<Media className="overflow-hidden ml-4" body>
                {getSubExp(school)}
                <p className="text-success mb-0">{"Newly Created"}</p>
                <p className="text-muted mb-0">{active}</p>
            </Media>);
        }
        else if (activity < 6 && created < 8) {
            return (<Media className="overflow-hidden ml-4" body>
                {getSubExp(school)}
                <p className="text-success mb-0">{"Newly Created And Active"}</p>
                <p className="text-muted mb-0">{active}</p>
            </Media>);
        } else if (activity == -1 && created >= 8) {
            return (<Media className="overflow-hidden ml-4" body>
                {getSubExp(school)}
                <p className="text-danger mb-0">{"Not used "}</p>
                <p className="text-muted mb-0">{'Active: --'}</p>
            </Media>);
        } else if (activity < 4) {
            return (<Media className="overflow-hidden ml-4" body>
                {getSubExp(school)}
                <p className="text-success mb-0">{"Activein this week"}</p>
                <p className="text-muted mb-0">{active}</p>
            </Media>);
        } else if (activity < 8) {
            return (<Media className="overflow-hidden ml-4" body>
                {getSubExp(school)}
                <p className="text-success mb-0">{"Activein this week"}</p>
                <p className="text-muted mb-0">{active}</p>
            </Media>);
        } else if (activity < 15) {
            return (<Media className="overflow-hidden ml-4" body>
                {getSubExp(school)}
                <p className="text-secondary mb-0">{"Active One week ago"}</p>
                <p className="text-muted mb-0">{active}</p>
            </Media>);
        } else if (activity < 31) {
            return (<Media className="overflow-hidden ml-4" body>
                {getSubExp(school)}
                <p className="text-danger mb-0">{"Non Active in 15 days"}</p>
                <p className="text-muted mb-0">{active}</p>
            </Media>);
        } else if (activity >= 31) {
            return (<Media className="overflow-hidden ml-4" body>
                {getSubExp(school)}
                <p className="text-danger mb-0">{"Non Active "}</p>
                <p className="text-muted mb-0">{active}</p>
            </Media>);
        }

        // return (<Media className="overflow-hidden ml-4" body>
        //     <p className="text-danger mb-0">{"Active "}</p>
        //     <p className="text-muted mb-0">{active}</p>
        // </Media>);
    }

    const featureStat = (school) => {
        let features = school.school.attributes.featuresList ?? [];

        return (<Media className="overflow-hidden ml-4" body>
            <p className="text-dark mb-0"><b><u>{"Features Enabled"}</u></b></p>

            <p className="text-muted mb-0">{"Messaging"}</p>
            <p className="text-muted mb-0">{"Enquiry"}</p>

            {features.length > Constants.FEATURE_ATTENDANCE_INDEX &&
                features[Constants.FEATURE_ATTENDANCE_INDEX] > 0 &&
                <p className="text-muted mb-0">{"Attendance"}</p>
            }

            {features.length > Constants.FEATURE_PAYMENTS_INDEX &&
                features[Constants.FEATURE_PAYMENTS_INDEX] > 0 &&
                <p className="text-muted mb-0">{"Payments"}</p>
            }

            {features.length > Constants.FEATURE_PROGRAM_INDEX &&
                features[Constants.FEATURE_PROGRAM_INDEX] > 0 &&
                <p className="text-muted mb-0">{"Programs"}</p>
            }

            {features.length > Constants.FEATURE_EXPENSE_INDEX &&
                features[Constants.FEATURE_EXPENSE_INDEX] > 0 &&
                <p className="text-muted mb-0">{"Expences"}</p>
            }

            {features.length > Constants.FEATURE_ACTIVITY_TRACKING_INDEX &&
                features[Constants.FEATURE_ACTIVITY_TRACKING_INDEX] > 0 &&
                <p className="text-muted mb-0">{"LMS: Activity Tracking & Child Development"}</p>
            }

            {features.length > Constants.FEATURE_E_LEARNING &&
                features[Constants.FEATURE_E_LEARNING] > 0 &&
                <p className="text-muted mb-0">{"LMS: Home Learning"}</p>
            }

            {features.length > Constants.FEATURE_CUSTOM_DIARY_INDEX &&
                features[Constants.FEATURE_CUSTOM_DIARY_INDEX] > 0 &&
                <p className="text-muted mb-0">{"Daycare"}</p>
            }

            {features.length > Constants.FEATURE_CCTV_INDEX &&
                features[Constants.FEATURE_CCTV_INDEX] > 0 &&
                <p className="text-muted mb-0">{"CCTV"}</p>
            }

            {features.length > Constants.FEATURE_TRANSPORT_INDEX &&
                features[Constants.FEATURE_TRANSPORT_INDEX] > 0 &&
                <p className="text-muted mb-0">{"Trips"}</p>
            }

            {features.length > Constants.FEATURE_VISITOR_INDEX &&
                features[Constants.FEATURE_VISITOR_INDEX] > 0 &&
                <p className="text-muted mb-0">{"Visitor"}</p>
            }

            {features.length > Constants.FEATURE_PICKNDROP_INDEX &&
                features[Constants.FEATURE_PICKNDROP_INDEX] > 0 &&
                <p className="text-muted mb-0">{"Pick n Drop/Escort"}</p>
            }





        </Media>);
    }

    function getDaysCount(d) {
        if (!d) {
            return -1;
        } else {
            let today = new Date();
            let diffTime = today - d;
            let diffDays = diffTime / (1000 * 3600 * 24);
            if (diffDays > 0) {
                return parseInt(diffDays + "")
            } else {
                return 0;
            }

        }
    }

    function getDays(d) {
        if (d === "") {
            return "";
        } else {
            let today = new Date();
            let diffTime = today - d;
            let diffDays = diffTime / (1000 * 3600 * 24);
            if (diffDays > 0) {
                return parseInt(diffDays + "") + " day's ago "
            } else {
                return "";
            }

        }
    }

    const subscriptionExpiryDateUpdate = (school, date) => {
        console.log("subscriptionExpiryDate", date);
        school.set("subscriptionExpiryDate", date);
        props.editSchool(temp);
    };

    return (
        <React.Fragment>



            {/* <TopBar title={schoolList != null ? "ALL BRANCHES (" + schoolList.length + ")" : "(0)"} search={true} onSearch={handelSearch} /> */}


            <Card>
                <CardBody>
                    <AvForm
                        className="outer-repeater"
                        style={{ border: "1px" }}
                    // onSubmit={isFormValid}
                    >
                        <Row
                            data-repeater-list="outer-group"
                            className="outer"
                        >
                            <Col lg={4} data-repeater-item className="outer">
                                <Label className="mt-2 mb-0">State</Label>
                                <select
                                    className="form-select"
                                    value={selectedState}
                                    onChange={(e) => {
                                        if (e.target.value === "all_state") {
                                            setSelectedState(undefined);
                                            setSelectedCity(undefined);
                                            setAllCityList([]);
                                            setSelectedArea(undefined);
                                            setAllAreaList([]);
                                            filterSchools(undefined, undefined, undefined, fromDate, toDate, activeState);
                                        } else {
                                            setSelectedState(e.target.value);
                                            setSelectedCity(undefined);
                                            setAllCityList([]);
                                            setSelectedArea(undefined);
                                            setAllAreaList([]);
                                            filterSchools(e.target.value, undefined, undefined, fromDate, toDate, activeState);
                                        }

                                    }}
                                >
                                    <option disabled selected value>-- Pick State --</option>
                                    <option value={"all_state"}>
                                        {"All"}
                                    </option>
                                    {Constants.stateName.map((val, key) => (
                                        <option key={key} value={val.value}>
                                            {val.label}
                                        </option>
                                    ))}
                                </select>

                            </Col>
                            <Col lg={4} data-repeater-item className="outer">
                                <Label className="mt-2 mb-0">City</Label>
                                <select
                                    className="form-select"
                                    value={selectedCity}
                                    onChange={(e) => {
                                        if (e.target.value === "all_city") {
                                            setSelectedCity(undefined);
                                            setSelectedArea(undefined);
                                            setAllAreaList([])
                                            filterSchools(selectedState, undefined, undefined, fromDate, toDate, activeState);

                                        } else {
                                            setSelectedCity(e.target.value);
                                            setSelectedArea(undefined);
                                            setAllAreaList([])
                                            filterSchools(selectedState, e.target.value, undefined, fromDate, toDate, activeState);

                                        }
                                    }}
                                >
                                    <option disabled selected value>-- Pick City --</option>
                                    {
                                        <option value={"all_city"}>
                                            {"All"}
                                        </option>
                                    }
                                    {allCityList.map((val, key) => (
                                        <option value={val} key={key}>
                                            {val}
                                        </option>
                                    ))}
                                </select>

                            </Col>
                            <Col lg={4} data-repeater-item className="outer">
                                <Label className="mt-2 mb-0">Area</Label>
                                <select
                                    className="form-select"
                                    value={selectedArea}
                                    onChange={(e) => {
                                        if (e.target.value === "all_area") {
                                            setSelectedArea(undefined);
                                            setSelectedArea(selectedState, selectedCity, undefined, fromDate, toDate, activeState)
                                        } else {
                                            setSelectedArea(e.target.value);
                                            filterSchools(selectedState, selectedCity, e.target.value, fromDate, toDate, activeState)
                                        }
                                    }}
                                >

                                    <option disabled selected value>-- Pick Area --</option>
                                    {
                                        <option value={"all_area"}>
                                            {"All"}
                                        </option>
                                    }
                                    {allAreaList.map((val, key) => (
                                        <option value={val} key={key}>
                                            {val}
                                        </option>
                                    ))}
                                </select>

                            </Col>




                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("From")} :
                                    </Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {
                                            setFromDateError(false);
                                            setFromDate(date)
                                            filterSchools(selectedState, selectedCity, selectedArea, date, toDate, activeState)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        maxDate={toDate}
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        errorMessage="Please select"
                                    />
                                </FormGroup>
                                {fromDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("To")} :
                                    </Label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(date) => {
                                            setToDateError(false);
                                            setToDate(date)
                                            filterSchools(selectedState, selectedCity, selectedArea, fromDate, date, activeState)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        minDate={fromDate}
                                        dateFormat="dd/MM/yyyy"
                                        required
                                        errorMessage="Please select"
                                    />
                                </FormGroup>
                                {toDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>

                            <Col lg={4} data-repeater-item className="outer">
                                <Label className="mt-2 mb-0">Active state</Label>
                                <select
                                    className="form-select"
                                    value={activeState}
                                    onChange={(e) => {
                                        setActiveState(e.target.value)
                                        filterSchools(selectedState, selectedCity, selectedArea, fromDate, toDate, e.target.value)
                                    }}
                                >
                                    <option disabled selected value>-- Active state --</option>

                                    {activeOptions.map((val, key) => (
                                        <option value={val.value} key={key}>
                                            {val.label}
                                        </option>
                                    ))}
                                </select>

                            </Col>



                        </Row>
                    </AvForm>

                </CardBody>
            </Card>

            {schoolList != null &&
                schoolList.length > 0 ?
                schoolList.map((school, key) => (
                    <Col key={"_school_" + key}>
                        <Card
                            onClick={() => {
                                // props.history.push("/dashboard/" + school.id)
                            }}
                        >
                            <CardBody>

                                <Row className='d-flex justify-content-between'>
                                    <Col lg={1}>
                                        {school.school.attributes.logo != undefined ? (
                                            <div className="avatar-md  mr-4 mt-0">
                                                <img
                                                    src={
                                                        school.school.attributes.logo == undefined
                                                            ? logo
                                                            : school.school.attributes.logo._url
                                                    }
                                                    alt=""
                                                    className="img-thumbnail rounded-circle"
                                                />
                                            </div>
                                        ) : (
                                            <div className="avatar-md mr-4">
                                                <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                                    <div style={{ fontSize: "48px" }}>
                                                        <i className="bx bx-buildings"></i>
                                                    </div>
                                                </span>
                                            </div>
                                        )}

                                    </Col>
                                    <Col lg={4}>
                                        <h5 className="text-truncate font-size-15 mt-1">

                                            <Link
                                                to={"/dashboard/" + school.id}
                                                ta rget="_blank"
                                                className="text-dark"
                                            >
                                                {school.school.attributes.Name}
                                            </Link>
                                        </h5>
                                        <p className="text-muted mb-0">
                                            <span>{school.school.attributes.locality && school.school.attributes.locality + ", "}</span>
                                            <span>{school.school.attributes.city}</span>
                                            {school.school.attributes.areaCode && <span>
                                                {", " + Constants.stateNameCode[school.school.attributes.areaCode]}
                                            </span>
                                            }
                                        </p>
                                        <small className="text-muted mb-4">
                                            <span>{"Added On: " + Moment(school.school.attributes.createdAt).format("DD/MM/YYYY") + " (" + getDays(school.school.attributes.createdAt) + ")"}</span>
                                        </small>
                                    </Col>
                                    <Col lg={2}>
                                        {getAnalyticsUI(school)}
                                    </Col>
                                    <Col lg={2}>
                                        {activeStat(school)}
                                    </Col>
                                    <Col lg={3}>
                                        {featureStat(school)}
                                    </Col>
                                    <div>

                                    </div>



                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                ))

                :
                <div> <h5 className="text-center text-info mt-4">{error}</h5></div>

            }



        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { schools, schoolAnalytics } = state.School;
    const { franchise } = state.Franchise;
    return {
        schools, schoolAnalytics,
        franchise
    };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps, { getSchoolAnalytics, editSchool, getFranchise })(SchoolStatistics))); 