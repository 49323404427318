export const UPDATE_MESSAGE = "UPDATE_MESSAGE";
export const DELETE_MESSAGE = "DELETE_MESSAGE";

export const UPDATE_MESSAGES = 'UPDATE_MESSAGES';
export const GET_MESSAGES = 'GET_MESSAGES';
export const GET_MESSAGE = 'GET_MESSAGE';

export const MESSAGE_SUCCESS = "MESSAGE_SUCCESS";
export const MESSAGE_ERROR = "MESSAGE_ERROR";


export const GET_LATEST_MESSAGES_FOR_CLASSID = "GET_LATEST_MESSAGES_FOR_CLASSID";
export const GET_OLD_MESSAGES_FOR_CLASSID = "GET_OLD_MESSAGES_FOR_CLASSID";
export const GET_MESSAGES_FOR_CLASSID = "GET_MESSAGES_FOR_CLASSID";



export const GET_LATEST_MESSAGES_FOR_GROUPID = "GET_LATEST_MESSAGES_FOR_GROUPID";
export const GET_OLD_MESSAGES_FOR_GROUPID = "GET_OLD_MESSAGES_FOR_GROUPID";
export const GET_MESSAGES_FOR_GROUPID = "GET_MESSAGES_FOR_GROUPID";

export const GET__LATEST_MESSAGES_FOR_SCHOOLID = "GET_LATEST_MESSAGES_FOR_SCHOOLID";
export const GET_OLD_MESSAGES_FOR_SCHOOLID = "GET_OLD_MESSAGES_FOR_SCHOOLID";
export const GET_MESSAGES_FOR_SCHOOLID = "GET_MESSAGES_FOR_SCHOOLID";


export const GET_LATEST_MESSAGES_FOR_ALL_TEACHERS = "GET_LATEST_MESSAGES_FOR_ALL_TEACHERS";
export const GET_OLD_MESSAGES_FOR_ALL_TEACHERS = "GET_OLD_MESSAGES_FOR_ALL_TEACHERS";
export const GET_MESSAGES_FOR_ALL_TEACHERS = "GET_MESSAGES_FOR_ALL_TEACHERS";

export const GET_MESSAGES_FOR_COURSE_KID = "GET_MESSAGES_FOR_COURSE_KID";
export const GET_LATEST_MESSAGES_FOR_COURSE_KID = "GET_LATEST_MESSAGES_FOR_COURSE_KID";
export const GET_OLD_MESSAGES_FOR_COURSE_KID = "GET_OLD_MESSAGES_FOR_COURSE_KID";

export const GET_ALBUMS_FOR_ALBUM_IDS = "GET_ALBUMS_FOR_ALBUM_IDS";
export const UPDATE_ALBUMS = "UPDATE_ALBUMS";
export const DELETE_ALBUM = "DELETE_ALBUM";
export const MESSAGE_QUEUE_TIMER = "MESSAGE_QUEUE_TIMER"





export const SET_LOADING_MESSAGES = "SET_LOADING_MESSAGES";
export const DIRECT_ASSIGN_TO_STATE_MESSAGES = "DIRECT_ASSIGN_TO_STATE_MESSAGES";
export const SET_NO_MESSAGES_TO_SHOW = "SET_NO_MESSAGES_TO_SHOW";
export const TOGGLE_IS_OLD_MESSAGES = "TOGGLE_IS_OLD_MESSAGES";


export const UPDATE_MESSAGE_QUEUES = 'UPDATE_MESSAGE_QUEUES';
export const GET_MESSAGE_QUEUES = "GET_MESSAGE_QUEUES";