import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as ResultsHelper from "../../../../helpers/ParseHelpers/MarksCard/examResults_helper";
import * as actionTypes from "./actionTypes";
import * as ResultsAction from "./actions";

function* getExamResultsForExamId({ payload: { examId } }) {
    try {
        const response = yield call(ResultsHelper.getExamResultsForExamId, examId);

        if (response) {
            yield put(ResultsAction.updateExamResults(response));
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}

function* getExamResultsForExamIdAndKidId({ payload: { examId, kidId } }) {
    try {
        const response = yield call(ResultsHelper.getExamResultsForExamIdAndKidId, examId, kidId);

        if (response) {
            yield put(ResultsAction.updateExamResults(response));
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}

export function* watchGetExamResultsForExamId() {
    yield takeEvery(
        actionTypes.GET_EXAM_RESULTS_FOR_EXAM_ID,
        getExamResultsForExamId
    );
}

export function* watchGetExamResultsForExamIdAndKidId() {
    yield takeEvery(
        actionTypes.GET_EXAM_RESULTS_FOR_EXAM_ID_AND_KID_ID,
        getExamResultsForExamIdAndKidId
    );
}

function* ExamResultsSaga() {
    yield all([fork(watchGetExamResultsForExamId)]);
    yield all([fork(watchGetExamResultsForExamIdAndKidId)]);
}
export default ExamResultsSaga;
