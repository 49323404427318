import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip,
  Col,
  Row,
  Progress,
} from "reactstrap";
import Parse from "parse";
import React, { useEffect, useState } from "react";

const UpdateFiles = (props) => {
  const [answerFile, setAnswerFile] = useState(undefined);
  const [answerFileUploadProgress, setAnswerFileUploadProgress] = useState(
    undefined
  );

  useEffect(() => {
    if (props.initialFile != undefined) {
      let name = props.initialFile._name.split(/_(.+)/)[1];
      let fileExtension = name.split(".");

      let tempFile = {
        actualFileName: name,
        fileExtension: fileExtension[1].toLowerCase(),
        fileName: fileExtension[0],
        file: props.initialFile,
      };
      setAnswerFileUploadProgress(100);
      setAnswerFile(tempFile);
    }
  }, []);

  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }
    // else if(fileType === "Video"){
    //     return "bx bx-play-circle";
    // }else if (_audioExtensions.includes(fileExtension)) {
    //     return "bx bxs-music";
    // }
    return "bx bxs-file-blank";
  }
  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var resultWithName = { name: file.name, result: reader.result };
      cb(resultWithName);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  //on Doc Upload
  function handleFileUploadChange(e) {
    setAnswerFile(undefined);
    setAnswerFileUploadProgress(1);

    let fileExtension = e.target.files[0].name.split(".");
    let tempFile = {
      actualFileName: e.target.files[0].name,
      fileExtension: fileExtension[1].toLowerCase(),
      fileName: fileExtension[0],
      file: e.target.files[0],
    };

    var name = tempFile.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

    getBase64(tempFile.file, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result });
      //setUploadProgress(1);
      parseFile
        .save({
          progress: (value) => {
            if (value && value > 0.1) {
              setAnswerFileUploadProgress(value * 100);
            }
          },
        })
        .then(
          (result) => {
            tempFile.file = result;

            setAnswerFile(tempFile);
            props.onFileChange(tempFile);
          },
          (error) => {
            //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
          }
        );
    });

    setAnswerFile({ actualFileName: tempFile.actualFileName });
  }

  return (
    <React.Fragment>
      {answerFile == undefined && (
        <div className="d-flex">
          <Label
            htmlFor={"input" + props.fileUploadKey}
            color="primary"
            className="btn btn-primary"
          >
            Upload
            <input
              id={"input" + props.fileUploadKey}
              style={inputStyle}
              type="file"
              onChange={(e) => {
                handleFileUploadChange(e);
              }}
              accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
            />
          </Label>
        </div>
      )}

      {answerFileUploadProgress === 100 && (
        <Col xl="6" md="12" sm="12" xs="12" className="pl-0 pr-0">
          <div className="border p-3">
            <Row>
              <div
                className="avatar ml-3"
                style={{ width: "5rem", height: "5rem" }}
              >
                <span
                  className="avatar-title bg-white ml-0 text-primary h1"
                  style={{ fontSize: "60px" }}
                >
                  <i className={getFileTypeImage(answerFile.fileExtension)}></i>
                </span>
              </div>

              <div className="float-right ml-4" style={{ width: "200px" }}>
                <div className="text-muted font-weight-bold">
                  {answerFile.actualFileName}
                </div>
                <div className="d-flex mt-3">
                  <div>
                    <Label
                      htmlFor={"inputUpdate" + props.fileUploadKey}
                      color="primary"
                      className="btn btn-primary mb-0"
                    >
                      Update
                      <input
                        id={"inputUpdate" + props.fileUploadKey}
                        style={inputStyle}
                        type="file"
                        onChange={(e) => {
                          handleFileUploadChange(e);
                        }}
                        accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                      />
                    </Label>
                  </div>

                  <Button
                    className="btn-danger float-right ml-2"
                    onClick={() => {
                      setAnswerFile(undefined);
                      setAnswerFileUploadProgress(0);
                      props.onFileChange(undefined);
                    }}
                  >
                    Clear
                  </Button>
                </div>
              </div>
            </Row>
          </div>
        </Col>
      )}

      {answerFile &&
        answerFileUploadProgress >= 1 &&
        answerFileUploadProgress < 100 && (
          <div className="d-flex">
            <Col xl="6" md="12" sm="12" xs="12" className="ml-0 pl-0 pr-0">
              <div className="border p-3">
                <Row>
                  <div
                    className="avatar ml-3"
                    style={{ width: "5rem", height: "5rem" }}
                  >
                    <span className="avatar-title bg-white ml-0 text-primary h1">
                      <i
                        className={getFileTypeImage(answerFile.fileExtension)}
                      ></i>
                    </span>
                  </div>
                  <div className="float-right ml-4" style={{ width: "200px" }}>
                    <div className="text-muted font-weight-bold">
                      {answerFile.actualFileName}
                    </div>
                    <div className=" mt-3">
                      <Progress
                        color="primary"
                        value={answerFileUploadProgress}
                      ></Progress>
                    </div>
                  </div>
                </Row>
              </div>
            </Col>
          </div>
        )}
    </React.Fragment>
  );
};

export default UpdateFiles;
