import React from "react";
import Mixpanel from 'mixpanel-browser';

export const mixpanelInit = () => {
  Mixpanel.init('e701e62cea90b10da6b2c1dc968c2bbf');
}
export const track_Login = (userId, userEmail, userName, loginType, loginId) => {
  try {

    Mixpanel.identify(userId);
    Mixpanel.track('Successful login', {
      userName: userName,
      userEmail: userEmail,
      loginType: loginType,
      loginId: loginId,
    });
    Mixpanel.people.set({
      $first_name: userName,
      $email: userEmail,
      $loginType: loginType
    });

    // redirect to logged in page or something
  } catch (e) {
    Mixpanel.track('Unsuccessful login');
  }
};

export const track_Logout = (userId, userEmail) => {
  try {

    Mixpanel.identify(userId);
    Mixpanel.track('Successful logout', {
      userEmail: userEmail,
    });
    Mixpanel.people.set({
      $email: userEmail,
    });

  } catch (e) {
    Mixpanel.track('Unsuccessful logout');
  }
};

export const menuFeatureClick = (userId, userEmail, userName, featureName, menuType, schoolId, schoolName) => {
  try {

    Mixpanel.identify(userId);
    Mixpanel.track('Feature Click', {
      userName: userName,
      userEmail: userEmail,
      featureName: featureName,
      menuType: menuType,
      schoolId: schoolId,
      schoolName: schoolName,
    });
    Mixpanel.people.set({
      $first_name: userName,
      $email: userEmail,
    });

    // redirect to logged in page or something
  } catch (e) {
    Mixpanel.track('Unsuccessful Feature Click');
  }
};

export const track_SchoolSwitch = (userId, userEmail, userName, loginType, loginId, schoolId, toSchoolId, fromSchoolName, toSchoolName) => {
  try {

    Mixpanel.identify(userId);
    Mixpanel.track('School Switch', {
      userName: userName,
      userEmail: userEmail,
      loginType: loginType,
      loginId: loginId,
      schoolId: schoolId,
      toSchoolId: toSchoolId,
      fromSchoolName: fromSchoolName,
      toSchoolName: toSchoolName,
    });
    Mixpanel.people.set({
      $first_name: userName,
      $email: userEmail,
      $loginType: loginType
    });

    // redirect to logged in page or something
  } catch (e) {
    Mixpanel.track('Unsuccessful login');
  }
};

export const festival_Poster_Click = (userId, userEmail, userName, createPoster, downloadPoster, schoolId, schoolName) => {
  try {
    Mixpanel.identify(userId);
    Mixpanel.track('Create_Poster', {
      userName: userName,
      userEmail: userEmail,
      createPoster: createPoster,
      downloadPoster: downloadPoster,
      schoolId: schoolId,
      schoolName: schoolName,
    });
    Mixpanel.people.set({
      $first_name: userName,
      $email: userEmail,
    });

    // redirect to logged in page or something
  } catch (e) {
    Mixpanel.track('Unsuccessful Create Poster Click');
  }
};