import * as actions from "./actionTypes";

const initialState = {
  groups: [], resultGroup: null, deletedSuccessfully: null, singleGroupHolder: null, groupsForIds: [], isLoadingForGroup: true, archiveGroups: undefined,
};

const Group = (state = initialState, action) => {
  switch (action.type) {
    case actions.DELETED_SUCCESSFULLY:
      state = { ...state, deletedSuccessfully: action.payload.boolValue }
      break;
    case actions.SET_LOADING:
      state = { ...state, isLoadingForGroup: action.payload.boolValue }
      break;
    case actions.UPDATE_ARCHIVE_GROUPS:

      if (state.archiveGroups != undefined) {
        var groups = [...state.archiveGroups];
      if (groups.length > 0) {
        for (const latest in action.payload.groups) {
          var temp = groups.filter(
            (group) =>
              group.id === action.payload.groups[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.groups[latest].attributes.deleted) {

              var others = groups.filter(
                (group) =>
                  group.id !== action.payload.groups[latest].id
              );

              groups = others;
            } else {
              var index = groups.lastIndexOf(temp[0]);
              groups[index] = action.payload.groups[latest];
            }

          } else {
            if (action.payload.groups[latest].attributes.deleted !== true) {
              groups.push(action.payload.groups[latest]);
            }

          }
        }
        state = { ...state, archiveGroups: groups };
      } else {

        var i = 0
        var groupsToSave = []
        for (i = 0; i < action.payload.groups.length; i++) {
          if (action.payload.groups[i].attributes.deleted !== true) {
            groupsToSave.push(action.payload.groups[i])
          }
        }


        state = { ...state, archiveGroups: groupsToSave };
      }
      } else {
        state = { ...state, archiveGroups: action.payload.groups };
      }
      break;

    case actions.UPDATE_ARCHIVE_GROUP:

      if (state.archiveGroups) {
        var groups = [...state.archiveGroups]
      var temp = state.groups.filter(group => group.id === action.payload.group.id)

      if (temp.length > 0) {

        if (action.payload.group.attributes.deleted) {

          var others = state.groups.filter(
            (group) =>
              group.id !== action.payload.group.id
          );
          state = { ...state, archiveGroups: others };

        } else {

          var index = groups.indexOf(temp[0])
          groups[index] = action.payload.group
          state = { ...state, archiveGroups: groups };
        }




      } else {

        groups.push(action.payload.group)
        state = { ...state, archiveGroups: groups };

      }
      

      } else {
        state = { ...state, archiveGroups: action.payload.group };


      }
      break;

      

      
    case actions.UPDATE_GROUP:

      var groups = [...state.groups]
      var temp = state.groups.filter(group => group.id === action.payload.group.id)

      if (temp.length > 0) {

        if (action.payload.group.attributes.deleted) {

          var others = state.groups.filter(
            (group) =>
              group.id !== action.payload.group.id
          );
          state = { ...state, groups: others };

        } else {

          var index = groups.indexOf(temp[0])
          groups[index] = action.payload.group
          state = { ...state, groups: groups };
        }




      } else {

        groups.push(action.payload.group)
        state = { ...state, groups: groups };

      }
      break;



    case actions.UPDATE_GROUPS:

      var groups = [...state.groups];
      if (groups.length > 0) {
        for (const latest in action.payload.groups) {
          var temp = groups.filter(
            (group) =>
              group.id === action.payload.groups[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.groups[latest].attributes.deleted) {

              var others = groups.filter(
                (group) =>
                  group.id !== action.payload.groups[latest].id
              );

              groups = others;
            } else {
              var index = groups.lastIndexOf(temp[0]);
              groups[index] = action.payload.groups[latest];
            }

          } else {
            if (action.payload.groups[latest].attributes.deleted !== true) {
              groups.push(action.payload.groups[latest]);
            }

          }
        }
        state = { ...state, groups: groups };
      } else {

        var i = 0
        var groupsToSave = []
        for (i = 0; i < action.payload.groups.length; i++) {
          if (action.payload.groups[i].attributes.deleted !== true) {
            groupsToSave.push(action.payload.groups[i])
          }
        }


        state = { ...state, groups: groupsToSave };
      }

    case actions.DELETE_GROUP:
      var groups = state.groups.filter(
        (group) => group.id !== action.payload.id
      );
      state = { ...state, groups: groups };
      break;
    case actions.UPDATE_RESULT_GROUP:
      state = { ...state, resultGroup: action.payload.resultGroup };
      break;
    case actions.SINGLE_GROUP_UPDATE:
      state = { ...state, singleGroupHolder: action.payload.singleGroupHolder };
      break;
    case actions.UPDATE_GROUPS_FOR_IDS:
      state = { ...state, groupsForIds: action.payload.groups };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Group;
