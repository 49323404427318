import * as actions from "./actionTypes";

export const getSubjectsForExamId = (examId) => {
    return {
        type: actions.GET_SUBJECTS_FOR_EXAM_ID,
        payload: {examId }
    }
}

export const updateSubjects = (subjects) => {
    return {
      type: actions.UPDATE_SUBJECTS,
      payload: { subjects },
    };
  };