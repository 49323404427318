import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  UPDATE_USER,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  UPDATE_USER_TEACHER,
  API_ERROR,
  SET_LOGIN_LOADING,
  VERIFY_USER_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  update: false,
  userTeacher: undefined,
  userTeacherPermissions: [],
  userTeacherFranchiseFeaturesList:[],
  verifyUserError:false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case UPDATE_USER_TEACHER:
      state = {
        ...state,
        userTeacher: action.payload.teacher,
      };
      if (action.payload.teacher && action.payload.teacher.attributes) {
        state = {
          ...state,
          userTeacherPermissions: action.payload.teacher.attributes.featurePermissions,
        };
        console.log("state:",state)
        state = {
          ...state,
          userTeacherFranchiseFeaturesList: action.payload.teacher.attributes.franchiseFeaturesList,
        };
      }
      break;

    case UPDATE_USER:
      state = {
        ...state,
        update: true,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload.error, loading: false };
      break;

    case SET_LOGIN_LOADING:
      state = { ...state, loading: action.payload.state };
      break;
    case VERIFY_USER_ERROR:
      state = { ...state, verifyUserError: action.payload.state };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
