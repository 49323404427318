import * as actions from "./actionTypes";

const initialState = {
  enquiryNotes: [],
  selectedEnquiryNote: null,
  enquiryNotesForIds:[]
};

const EnquiryNotes = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_ENQUIRY_NOTE:
      var enquiryNotes = [...state.enquiryNotes];
      var temp = state.enquiryNotes.filter(
        (enquirynote) => enquirynote.id === action.payload.enquiryNote.id
      );
      if (temp.length > 0) {
        if (action.payload.enquiryNote.attributes.isDeleted) {
          var others = state.enquiryNotes.filter(
            (enquiry) => enquiry.id !== action.payload.enquiryNote.id
          );
          state = { ...state, enquiryNotes: others };
        } else {
          var index = enquiryNotes.indexOf(temp[0]);
          enquiryNotes[index] = action.payload.enquiryNote;
          state = { ...state, enquiryNotes: enquiryNotes };
        }
       
      } else {
        var list = [action.payload.enquiryNote];
        state = { ...state, enquiryNotes: list.concat(enquiryNotes) };
      }
      break;
    case actions.UPDATE_ENQUIRY_NOTES:
      var enquiryNotes = [...state.enquiryNotes];
      if (!action.payload.isLocal) {
        if (enquiryNotes.length > 0) {
          for (const latest in action.payload.enquiryNotes) {
            var temp = enquiryNotes.filter(
              (enquirynote) =>
                enquirynote.id === action.payload.enquiryNotes[latest].id
            );

            if (temp.length > 0) {
              if (action.payload.enquiryNotes[latest].attributes.isDeleted) {
                var others = enquiryNotes.filter(
                  (enquiry) => enquiry.id !== action.payload.enquiryNotes[latest].id
                );
                enquiryNotes = [...others];
              } else {
                var index = enquiryNotes.lastIndexOf(temp[0]);
                enquiryNotes[index] = action.payload.enquiryNotes[latest];
              }
              
            } else {
              if (!action.payload.enquiryNotes[latest].attributes.isDeleted) {
                var list = [action.payload.enquiryNotes[latest]];
                enquiryNotes = list.concat(enquiryNotes);
              }
            }
          }
          state = { ...state, enquiryNotes: enquiryNotes };
        } else {

          var others = action.payload.enquiryNotes.filter(
            (note) => note.attributes.isDeleted !== true
          );
          state = { ...state, enquiryNotes: others };
        }
      } else {
        state = { ...state, enquiryNotes: action.payload.enquiryNotes };
      }
      

      break;
    case actions.DELETE_ENQUIRY_NOTE:
      var enquiryNotes = state.enquiryNotes.filter(
        (enquirynote) => enquirynote.id !== action.payload.id
      );
      state = { ...state, enquiryNotes: enquiryNotes };
      break;
    
    case actions.UPDATE_ENQUIRY_NOTES_EQUIRY_IDS:
      state = { ...state, enquiryNotesForIds: action.payload.enquiryNotes };
      break;
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default EnquiryNotes;
