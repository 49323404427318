import React, { useEffect } from 'react';
import {  Modal } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import PricingPage from './PricingPage'

const PricingPopup = (props) => {

    useEffect(() => {
        localStorage.setItem("PRICING_POPUP_LAST_SHOWN", new Date());
    }, [props.autoPopup]);
      
    return (
        <React.Fragment >
            
            <Modal
                size="xl"
                isOpen={true}
            >
                {/* <div className="modal-header">
                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        Extra large modal
                    </h5>
                    
                </div> */}
                <div className="modal-body">
                    <PricingPage autoPopup={props.autoPopup} isPopup={true}></PricingPage>
                </div>
            </Modal>
                      
        
        </React.Fragment>
    );
}
    
export default withNamespaces() (PricingPopup);