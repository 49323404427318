import * as actions from "./actionTypes";

const initialState = {
    subjects: [], loding: undefined,
};

const Subjects = (state = initialState, action) => {
    switch (action.type) {

        case actions.UPDATE_SUBJECTS:
            state = { ...state, subjects: action.payload.subjects };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default Subjects;
