import Parse from "parse";

export const getSubjectsForExamId = (examId) => {
    try {
        return new Promise((resolve, reject) => {
            var ExamSubjects = Parse.Object.extend("ExamSubjects");
            var query = new Parse.Query(ExamSubjects);
            query.equalTo("examId", examId);
            query.ascending("order");
            query.find().then(
                (result) => {
                    resolve(result);
                    // console.log(result)
                },
                (error) => {
                    reject(null);
                    console.log(error)
                }
            );
        });
    } catch (error) { }
};
