import React, { useState,useEffect } from 'react';
import { Link } from "react-router-dom";
import {Col,Row,Label, Button, Modal, ModalHeader, ModalBody, ModalFooter, FormGroup, Input, Card, CardTitle,Media,Container, CardBody } from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Moment from "moment";
import DatePicker from "react-datepicker";
import Parse from 'parse';
// Import Editor
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';


import { withNamespaces } from "react-i18next";
import { batch, connect } from "react-redux";
import { matchPath } from "react-router";

import * as CommonFunctions from "../../../components/Common/CommonFunctions";
import {
    setSelecetdSchool,
    getCoursesForOwnerIds,
    getCourseBatchesForCourseIds,
} from "../../../store/actions";

import TopBar from '../TopBar';

const AllAnnouncements = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/create-announcements/:schoolId',
        exact: true,
        strict: false,

    });

    const editMatch = matchPath(props.history.location.pathname, {
        path: '/announcement-view/:schoolId/:id',
        exact: true,
        strict: false,

    });

    

    const schoolId = match ? match.params.schoolId : editMatch.params.schoolId ;
    const announcementId=editMatch ? editMatch.params.id :null;

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);
   

    const [ownerIds, setOwnerIds] = useState([]);
    const [courseIds, setCourseIds] = useState([]);
    const [courseAndBatchList, setCourseAndBatchList]=useState(undefined)
    
    const [modal, setmodal] = useState(false);
    
    const [title, setTitle] = useState(undefined);
    const [message, setMessage] = useState(undefined);
    const [sessionDate, setSessionDate] = useState(undefined);
    const [sessionTimeError,setSessionTimeError]= useState(undefined);
    const [selectedParticipants,setSelectedParticipants]=useState([]);
    const [selectedParticipantsError,setSelectedParticipantsError]=useState(false);
    const [reminderId, setReminderId] = useState(undefined);

    const [onSave,setOnSave]=useState(false);

    const [model_selectedCourseId,setModelCourseId]=useState(undefined);

    const [announcementObject, setAnnouncementObject] = useState(undefined);
    const [remainderObject, setremainderObject] = useState(undefined);
    const [loading, setLoading]=useState(true);

    const [notificationStatus, setNotificationStatus] = useState(false);
    const [addToCalender, setAddToCalender] = useState(false);

    useEffect(() => {
        if(announcementId){
            getannouncementObject();
        }else{
            setLoading(false);
        }
    }, [announcementId]);

    const getannouncementObject = () => {
        var Announcement = Parse.Object.extend("Announcement");
        var query = new Parse.Query(Announcement);
        query.equalTo("objectId", announcementId);
       
        
        query.first().then(
            (result) => {
            
                setAnnouncementObject(result);
                setTitle(result.attributes.title);
                setSessionDate(result.attributes.dateAndTime);
                setMessage(result.attributes.message);
                if (result.attributes.participants && result.attributes.participants.length > 0) {
                    setSelectedParticipants(result.attributes.participants);
                } else {
                    setSelectedParticipants(["@all"])
                }
            
                if (result.attributes.reminderId) {
                    setAddToCalender(true)
                    setReminderId(result.attributes.reminderId);
                    var Announcement = Parse.Object.extend("Reminder");
                    var query2 = new Parse.Query(Announcement);
                    query2.equalTo("objectId", result.attributes.reminderId);
                    query2.first().then(
                        (result2) => {
                            setLoading(false)
                            setremainderObject(result2)
                            setSessionDate(result2.attributes.reminderTime);
                        },
                        (error) => {
                    
                        }
                    );
                } else {
                    setLoading(false)
                }
            },
            (error) => {
            
            }
        );
    }

    

    useEffect(() => {
        var ids = [];
        if (props.selectedSchool) {
            ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }


            if (ownerIds.length === ids.length) {

                var call = false;
                for (const i in ownerIds) {
                    if (ownerIds[i] !== ids[i]) {
                        call = true;
                    }
                }
                if (call) {
                    props.getCoursesForOwnerIds(ids);
                }

            } else {
                setOwnerIds(ids);
                props.getCoursesForOwnerIds(ids);
            }
        }

    }, [props.selectedSchool]);

    useEffect(() => {
        let allIds = [];
        let newIds = [];

        if (props.courses) {
            for (const cr of props.courses) {
                allIds.push(cr.id);
                if (!courseIds.includes(cr.id)) {
                    newIds.push(cr.id)
                }
            }
        }
        if (newIds.length > 0) {
            props.getCourseBatchesForCourseIds(allIds)
        }
        setCourseIds(allIds);

    }, [props.courses])


    useEffect(() => {
        let list = [];
        list.push({
            id: "@all",
            course: "To ALL Courses",
            batches: [],
        });

        if (props.courses) {
            for (const cr of props.courses) {
                if (cr.attributes.state === "Published") {
                    let batches = [];

                    if (props.allCourseBatches) {
                        for (const cb of props.allCourseBatches) {
                            if (cb.attributes.courseId === cr.id) {
                        
                                batches.push(
                                    {
                                        id: cb.id,
                                        course: cr,
                                        batch: cb,
                                        batchName: getBatchName(cb),
                                    }
                                );
                            }
                        }
                    }
                    list.push({
                        id: cr.id,
                        course: cr,
                        batches: batches,
                    })
                }
            }
        }

        console.log('list', list);
        setCourseAndBatchList(list)
       

    }, [props.courses,props.allCourseBatches])

   
    const getBatchName=(cb)=>{
        return getDateStringForDate(cb.attributes.startDate) + " to " + getDateStringForDate(cb.attributes.endDate);
    }

    const getDateStringForDate=(date) =>{
        return Moment(date).format('DD-MMM-YYYY');
    }

    const handelSelecteCourse = (course, type) => {
        if (type == 1) {
            setSelectedParticipants([course]);
        }else
        if (props.courses && props.courses.length > 0) {
          if (selectedParticipants && selectedParticipants.includes(course.id)) {
            var others = selectedParticipants.filter((item) => item !== course.id);
            setSelectedParticipants(others);
            setSelectedParticipantsError(false);
          } else {
            var list = [...selectedParticipants];
            list.push(course.id);
            setSelectedParticipants(list);
          }
        }
    }

    const getCourseForCourseId=(courseId)=>{
        if (props.courses) {
            for (const cr of props.courses) {
                if (courseId===cr.id) {
                    return cr;  
                }
            }
        }
    }
    
    const handleSubmit=()=>{

        if(!sessionDate && addToCalender){
            setSessionTimeError(true);
            return;
        }
    
        if(!selectedParticipants || selectedParticipants.length==0){
            setSelectedParticipantsError(true);
            return;
        }

        setOnSave(true);

        const Announcement = Parse.Object.extend("Announcement");
        let announcement = new Announcement();
        if(announcementObject){
            announcement=announcementObject;
            if(notificationStatus && match){
                announcement.set("notificationStatus", notificationStatus);
            }
        }
        announcement.set("title", title);
        announcement.set("message", message);
        if(addToCalender){
            announcement.set("dateAndTime", sessionDate);
        }
        announcement.set("schoolId", schoolId);
        // announcement.set("participants", selectedParticipants);
        announcement.set("createdBy", Parse.User.current().attributes.teacherId);

        
        if (match) {
            if (selectedParticipants && selectedParticipants.length > 0 && selectedParticipants[0] == "@all") {
                announcement.set("participants", []);
            } else {
                announcement.set("participants", selectedParticipants);
            }
            
        }
        let courseIds = (selectedParticipants && selectedParticipants.length > 0 && selectedParticipants[0] == "@all")?[]:selectedParticipants;
        announcement.save().then(
            (result) => {
                if(notificationStatus){
                    Parse.Cloud.run("function_sendEmail", {
                        schoolId: schoolId,
                        type:"announcement",
                        announcementId:result.id,
                        emailType: announcementObject ? "update" : "initial",
                        courseIds:courseIds
                    }).then((res) => {
                        console.log("function_sendEmail r",res)
                    }, (error) => {
                        console.log("function_sendEmail e",error)
                    });

                }
                if(remainderObject || addToCalender){

                    const ReminderParse = Parse.Object.extend("Reminder");
                    let ro = new ReminderParse();
                    if(editMatch){
                        ro=remainderObject;
                    }
                    ro.set("message", message ?? "");
                    ro.set("type", 5);
                    ro.set("status", true);
                    ro.set("title", title ?? "");
                    ro.set("defaultSchoolId", schoolId ?? "");
                    ro.set("schoolId", schoolId ?? "");
                    ro.set(
                        "senderId",
                        Parse.User.current().attributes.teacherId ?? ""
                    );
                    if (match) {
                        if (selectedParticipants && selectedParticipants.length > 0 && selectedParticipants[0] == "@all") {
                            ro.set("courseIds", []);
                        } else {
                            ro.set("courseIds", selectedParticipants);
                        }
            
                    }
                    ro.set("reminderTime", sessionDate);
                    ro.set("eventType", 1);
                    ro.save().then(
                        (result2)=>{
                            if(match){
                                result.set('reminderId',result2.id)
                                result.save()
                            }
                            window.history.back(); 
                        }
                    );
                }else{
                    window.history.back();
                }
                
            },
            (error) => {
            
            }
        );
    }
   

    const handelOnClickBack = () => {
        window.history.back();
    }
     
    return (
        <React.Fragment>
            
            <div className="page-content">
                <Container fluid>

                    <TopBar
                            title={"Create Announcements"}
                            backButton={true}
                            hideButton={true}
                            onClickBackButton={handelOnClickBack}
                        />
                    <Card >

                        {loading?<CardBody>
                        <Label  className="text-success">
                            <i className="bx bx-hourglass bx-spin mr-2"></i> Loading...{" "}
                        </Label>
                        </CardBody>:
                        <CardBody>

                            {<div className="flex-fill mr-2 ml-0 mt-2 mb-0">
                                {/* <Label className="pb-0" style={{ verticalAlign: "middle" }}>
                                Choose Participants
                                </Label> */}

                                {/* <Label
                                className="ml-2 text-primary"
                                onClick={() => {
                                    setmodal(true);
                                }}
                                id="add-term-label"
                                style={{ fontSize: "20px", cursor: "pointer" }}
                                >
                                <i className=" bx bx-plus-circle"></i>
                                </Label> */}

                                     <FormGroup >
                                        <Label >Choose Participants:</Label>
                                        <select
                                        className="form-control"
                                        value={selectedParticipants && selectedParticipants.length>0 ? selectedParticipants[0]:undefined}
                                        onChange={(e) => {
                                            // setHlsURL(e.target.value);
                                            handelSelecteCourse(e.target.value,1)
                                            if (selectedParticipantsError) {
                                                setSelectedParticipantsError(false);
                                            }
                                            
                                        }}
                                            disabled={match?false:true}
                                            required
                                        >
                                        <option disabled selected value>
                                            Choose Course 
                                        </option>
                                        {courseAndBatchList && courseAndBatchList.map((course, key) => (
                                            <option key={course.id} value={course.id}>
                                            {course.id =="@all"?course.course:course.course.attributes.name}
                                            </option>
                                        ))}
                                        </select>
                                        {selectedParticipantsError &&<p className='text-danger'>Choose Participants</p>}
                                    </FormGroup>
                            </div>}
                            {false && selectedParticipants && selectedParticipants.length>0 &&<div className="p-2">
                                 <Label> Selected Participants</Label>
                                {
                                    selectedParticipants && selectedParticipants.map((courseId)=>(
                                        <div key={courseId}>
                                            
                                            <span className="badge badge-pill badge-soft-secondary font-size-14 mt-2">
                                                {getCourseForCourseId(courseId).attributes.name}
                                               {match && <i 
                                                    className="ml-2 bx bxs-x-circle"
                                                    onClick={()=>{
                                                        handelSelecteCourse(getCourseForCourseId(courseId))
                                                    }}
                                                ></i>}
                                            </span>
                                        </div>
                                        
                                    ))
                                }
                            </div>}

                            <AvForm onValidSubmit={handleSubmit}>
                            <Row>
                                <Col className="col-12">
                                    <AvField name="title"
                                        label={props.t('Title')}
                                        type="text"
                                        placeholder={props.t('Title')}
                                        value={title}
                                        required
                                        onChange={(e) => {
                                            setTitle(e.target.value);
                                        }}
                                    />
                                </Col>
                                <Col>

                                <FormGroup>
                                    <div className="custom-control custom-checkbox mb-2" disabled ={editMatch?true:false}>
                                        <input type="checkbox" className="custom-control-input" id="calenderCheck" onChange={() => {if(match){setAddToCalender(!addToCalender)} }} checked={addToCalender} disabled ={editMatch?true:false} />
                                        <label className="custom-control-label" disabled ={editMatch?true:false} onClick={() => { if(match){setAddToCalender(!addToCalender);} }} >{props.t('Add to calender')}</label>
                                    </div>
                                </FormGroup>
                                
                                {addToCalender && <FormGroup >
                                        <Label >
                                            {props.t("Session Date&Time")} :
                                        </Label>
                                        <DatePicker
                                            selected={sessionDate}
                                            onChange={(date) => {
                                                setSessionDate(date);
                                                if(sessionTimeError){
                                                    setSessionTimeError(false);
                                                }
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Date")}
                                            minDate={new Date()}
                                            
                                            dateFormat="dd-MM-yyyy hh:mm a "
                                            required
                                            showTimeSelect
                                            timeIntervals={10}
                                            errorMessage="Please select"
                                            
                                        />
                                        {sessionTimeError && <p className="text-danger ml-2">Please select Date&Time</p>}
                                    </FormGroup>}
                                </Col>
                                <Col className="col-12">
                                    <AvField
                                        name="note"
                                        label={props.t('Message')}
                                        type="textarea"
                                        className="form-control"
                                        rows="10"
                                        placeholder={props.t('Your Message...')}
                                        errorMessage={props.t('Message can not be empty')}
                                        validate={{
                                            required: { value: true }
                                        }}
                                        value={message}
                                        onChange={(e) => {
                                            setMessage(e.target.value);
                                        }}
                                    />
                                </Col>

                                </Row>
                                <Row>
                                    <Col>
                                        <div className="text-right">
                                        
                                       { onSave?
                                            <button type="button" className="btn btn-dark waves-effect waves-light float-right m-2">
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                                            </button>
                                            :
                                            <div>

                                                { <FormGroup>
                                                    <div className="custom-control custom-checkbox mb-2 ml-3" >
                                                        <input type="checkbox" className="custom-control-input" id="instituteCheck" onChange={() => {setNotificationStatus(!notificationStatus)}} checked={notificationStatus} />
                                                        <label className="custom-control-label" onClick={() => { setNotificationStatus(!notificationStatus); }} >{match ? props.t('Send Email Notifications') : props.t('Send Email Notifications on update')}</label>
                                                    </div>
                                                </FormGroup>}
                                                <button type="submit" className="btn btn-success save-event">{props.t('Save')}</button>
                                            </div>
                                            
                                            }
                                        </div>
                                    </Col>
                                </Row>
                            </AvForm>
                        </CardBody>}
                    </Card> 
                 </Container>
            </div>

            <Modal
                isOpen={modal}
                fade={false}
                className="modal-dialog"
                size='lg'
            >

                <ModalHeader className="bg-light" toggle={() => {
                    setmodal(!modal);
                }}>
                   <Label className='mt-1'>Pick Participants</Label>
                </ModalHeader>
                <ModalBody
                    style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
                >

                                <Card>
                                    <CardBody>
                                        <CardTitle className="mb-4">Course List </CardTitle>
                                        <div className="table-responsive">
                                            <table className="table table-nowrap table-centered mb-0">
                                                <tbody>
                                                   { courseAndBatchList && courseAndBatchList.map((course)=>(
                                                       <tr key={course.id}>
                                                        <td style={{ width: "60px" }}>
                                                            <div className="custom-control custom-checkbox">
                                                                <input 
                                                                    type="checkbox" 
                                                                    className="custom-control-input" 
                                                                    id={"course_cb"+course.id} 
                                                                    checked={selectedParticipants.includes(course.id)}
                                                                    onChange={()=>{
                                                                        handelSelecteCourse(course)
                                                                    }}
                                                                />
                                                                <label 
                                                                    className="custom-control-label" 
                                                                    htmlFor={"course_cb"+course.id}
                                                                    onClick={() => {
                                                                        handelSelecteCourse(course);
                                                                    }}>
                                                                </label>
                                                            </div>
                                                        </td>
                                                       <td>
                                                            <h5 className="text-truncate font-size-14 m-0 text-dark">{course.id =="@all"?course.course:course.course.attributes.name}</h5>

                                                                {
                                                                    model_selectedCourseId == course.id &&
                                                                    <div>

                                                                        
                                                                        {
                                                                            course.batches.length>0 ?
                                                                            <div className="table-responsive">
                                                                                <table className="table table-nowrap table-centered mb-0">
                                                                                    <tbody>
                                                                                    

                                                                                       {course.batches.map((batch)=>( 
                                                                                           <tr key ={batch.id}>
                                                                                            <td>
                                                                                                <div className="custom-control custom-checkbox">
                                                                                                    <input type="checkbox" className="custom-control-input" id={"batch"+batch.id }/>
                                                                                                    <label className="custom-control-label" htmlFor={"batch"+batch.id }></label>
                                                                                                </div>
                                                                                            </td>
                                                                                            <td>
                                                                                                <h5 className="text-truncate font-size-14 m-0 text-dark">{batch.batchName}</h5>
                                                                                            </td>
                                                                                           
                                                                                        </tr>))
                                                                                        }
                                                                                    </tbody>
                                                                                </table>
                                                                            </div>:
                                                                            <Label className="mt-3 text-info">No Batches avilable</Label>
                                                                            
                                                                        }

                                                                    </div>   
                                                                }
                                                        </td>
                                                        <td>
                                                           
                                                        </td>
                                                        {false && <td>
                                                            <div className="text-center"
                                                                onClick={()=>{
                                                                    if(model_selectedCourseId == course.id){
                                                                        setModelCourseId(undefined)
                                                                    }else{
                                                                        setModelCourseId(course.id)
                                                                    }
                                                                }}
                                                            >
                                                                <span className="badge badge-pill badge-soft-secondary font-size-14">
                                                                   {
                                                                       model_selectedCourseId == course.id ?
                                                                            <i className="bx bxs-chevron-up"></i>
                                                                            : 
                                                                            <i className="bx bxs-chevron-down"></i>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </td>}
                                                    </tr>)) 
                                                    }
                                                   
                                                </tbody>
                                            </table>
                                        </div>
                                    </CardBody>
                                </Card>

                </ModalBody>
                <ModalFooter className="bg-light">
                    <Button color="secondary" onClick={() => {
                        setmodal(false);
                    }}>
                        Cancel
                    </Button>


                   {
                   
                        <Button color="primary"
                         onClick={()=>{
                            setmodal(false);
                         }}>
                            {props.t("Done")}
                    </Button>
                    }


                </ModalFooter>
            </Modal>
               

            
           
        </React.Fragment>
    );
    
}
   

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { courses } = state.Course;
    const { allCourseBatches } = state.CourseBatch;

    return { 
        selectedSchool ,
        courses,
        allCourseBatches,
    };
};
export default connect(mapStatetoProps, {
    setSelecetdSchool,
    getCoursesForOwnerIds,
    getCourseBatchesForCourseIds,
})(withNamespaces()(AllAnnouncements));
