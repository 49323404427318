export const GET_INVENTORY_SALE_ORDERS = "GET_INVENTORY_SALE_ORDERS";
export const GET_INVENTORY_SALE_ORDERS_FOR_BUYER = "GET_INVENTORY_SALE_ORDERS_FOR_BUYER";
export const UPDATE_INVENTORY_SALE_ORDERS = "UPDATE_INVENTORY_SALE_ORDERS";
export const EDIT_INVENTORY_SALE_ORDER = "EDIT_INVENTORY_SALE_ORDER";
export const DELETE_INVENTORY_SALE_ORDER = "DELETE_INVENTORY_SALE_ORDER";
export const UPDATE_DELETE_SALE_ORDER = "UPDATE_DELETE_SALE_ORDER";
export const GET_INVENTORY_SALE_ITEM = "GET_INVENTORY_SALE_ITEM";
export const ADD_INVENTORY_SALE_ORDER = "ADD_INVENTORY_SALE_ORDER";
export const SET_SELECTED_SALE_ORDER = "SET_SELECTED_SALE_ORDER";
export const UPDATE_SELECTED_SALE_ORDER = "UPDATE_SELECTED_SALE_ORDER";

export const GET_CART_FOR_OWNER_ID = "GET_CART_FOR_OWNER_ID";
export const UPDATE_CART_FOR_OWNER_ID = "UPDATE_CART_FOR_OWNER_ID";
export const ADD_INVENTORY_CART = "ADD_INVENTORY_CART";


