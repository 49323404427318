
import React from 'react';
import { Row, Col, Form, Button, UncontrolledTooltip, Input, Dropdown, DropdownItem, DropdownMenu, DropdownToggle, Label } from "reactstrap";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

const Topbar = (props) => {

    return (

        <Row>

            <Col lg={6} md={6} xs={12}>
                <h4 className='pt-2'>{props.title.toUpperCase()}</h4>

            </Col>
            <Col lg={6} md={6} xs={12}>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end form-inline">
                    {props.showSearchBar && <div className="search-box mr-2 mb-2 m-1">
                        <div className="position-relative">
                            <Input type="text" className="form-control border-0" placeholder="Search..."

                                onChange={(e) => {

                                    props.onSearch(e.target.value)
                                }}
                            />
                            <i className="bx bx-search-alt search-icon"></i>
                        </div>
                    </div>}
                    {true && <Dropdown isOpen={props.showDropDown} toggle={props.closeDropDown} className="mb-3 ml-2 m-1">
                        <DropdownToggle type="button" tag="button" className="btn btn-primary">
                            <i className="mdi mdi-wallet mr-1"></i>
                            <span className="d-none d-sm-inline-block ml-1">Wallet Balance <i className="mdi mdi-chevron-down"></i></span>
                        </DropdownToggle>
                        <DropdownMenu right className="dropdown-menu-md">

                            <div className="dropdown-item-text">
                                <div>
                                    <p className="text-muted mb-2">Available Balance</p>
                                    <Row>
                                        <Col>
                                            <h5 className="mb-0">{props.currency} {props.balance}</h5>
                                        </Col>
                                        <Col>
                                            <Label className='text-success' onClick={() => { window.location.reload(false); }}><u><small>Update</small></u></Label>
                                        </Col>
                                    </Row>
                                </div>
                            </div>

                            <DropdownItem divider />
                            <DropdownItem className="text-primary" href="#">
                                {props.showAddNewExp && <Button
                                    type="button"
                                    color="light"
                                    className="btn waves-effect waves-light  mb-3 ml-2 pb-2"
                                    onClick={() => { props.handleWalletOnClick() }}
                                ><i className="mdi mdi-wallet mr-1"></i>
                                    {props.t('Add wallet money')}
                                </Button>}
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>}
                    {props.showAddNewExp && <Button
                        type="button"
                        color="success"
                        className="btn waves-effect waves-light  mb-3 ml-2 pb-2 m-1"
                        onClick={() => { props.handleButtonOnClick() }}
                    >

                        {props.t('New Expenses')}
                    </Button>}
                </div>
            </Col>
        </Row>
    );
}
export default withNamespaces()(Topbar);
