import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Label,
  UncontrolledTooltip,
  Row,
  Col,
} from "reactstrap";
import React, { useEffect, useState } from "react";

//Image Cropper
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
//Import Image
import src from "../../assets/images/product/img-7.png";

import Parse from "parse";
import Compressor from 'compressorjs';

const UpdatePhoto = (props) => {
  const [preview, setPreview] = useState("");
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [cropResult, setCropResult] = useState("");
  const [shouldShowDoneButton, setShouldShowDoneButton] = useState(false);
  const [cropper, setCropper] = useState("");
  const [buttonTitle, setButtonTitle] = useState(props.buttonTitle ?? "Upload");
  const [isProcess, setIsProcess] = useState(false)
  const [buttonTitleInitial, setButtonTitleInitial] = useState(
    props.buttonTitleInitial ?? "Upload"
  );

  const [fileName, setFileName] = useState(
    props.imageFileName != undefined || props.imageFileName != ""
      ? props.imageFileName
      : "pic.jpg"
  );
  // constructor(props) {
  //   super(props);

  //   this.state = {
  //     preview: this.props.initialImage ?? "",
  //     shouldShowChangedImage: false,
  //     model: this.props.isPopupOn,
  //     src,
  //     cropResult: null,
  //     shouldShowDoneButton: false,

  //   };

  //   this.handleDoneButton = this.handleDoneButton.bind(this);

  // }

  // getBase64(file, cb) {
  //   let reader = new FileReader();
  //   reader.readAsDataURL(file);
  //   reader.onload = () => {
  //     var resultWithName = { name: file.name, result: reader.result };
  //     cb(resultWithName);
  //   };
  //   reader.onerror = function (error) {
  //     console.log("Error: ", error);
  //   };
  // }

  useEffect(() => {
    if (props.initialImage) {
      setPreview(props.initialImage);
      setShouldShowDoneButton(true);
    } else {
      setPreview("");
      setShouldShowDoneButton(false);
    }
  }, [props.initialImage]);

  const togglePopup = () => {
    //setState({ model: !model });
    setIsModelOpen(!isModelOpen);
  };

  const displayButton = () => {
    const inputStyle = {
      width: 0.1,
      height: 0.1,
      opacity: 0,
      overflow: "hidden",
      position: "absolute",
      zIndex: -1,
    };
    if (preview === "") {
      return (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Label
            htmlFor={`inputImageUpload ${props.tempId ?? ""}`}
            color="primary"
            className="btn btn-primary"
          >
            {buttonTitleInitial}
            <input
              id={`inputImageUpload ${props.tempId ?? ""}`}
              style={inputStyle}
              type="file"
              onChange={onChange}
              accept="image/*"
            />
          </Label>
        </div>
      );
    }
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Button
          color="primary"
          className="waves-effect waves-light center sm"
          onClick={togglePopup}
        >
          {buttonTitle}
        </Button>
      </div>
    );
  };

  const onChange = (e) => {
    e.preventDefault();
    setFileName(e.target.files[0].name);
    let files;
    if (e.dataTransfer) {
      files = e.dataTransfer.files;
    } else if (e.target) {
      files = e.target.files;
    }

    let fileSize = files[0].size;
    if (false && fileSize > 300000) {

      let cRate = 300000 / fileSize;
      new Compressor(files[0], {
        quality: 0.6,
        success: (compressedResult) => {

          console.log(compressedResult)

          const reader = new FileReader();
          reader.onload = () => {
            //setState({ src: reader.result, shouldShowDoneButton: true });
            setPreview(reader.result);
            setShouldShowDoneButton(true);
            if (!isModelOpen) {
              setIsModelOpen(true);
            }
          };
          reader.readAsDataURL(compressedResult);


        },
      });
    } else {
      const reader = new FileReader();
      reader.onload = () => {
        //setState({ src: reader.result, shouldShowDoneButton: true });
        setPreview(reader.result);
        setShouldShowDoneButton(true);
        if (!isModelOpen) {
          setIsModelOpen(true);
        }
      };
      reader.readAsDataURL(files[0]);
    }
    // console.log(files)




  };

  function base64ToFile(base64String, fileName) {
    // Split the base64 string into two parts: metadata and data
    const parts = base64String.split(';base64,');

    // Extract the data part and decode it from base64
    const contentType = parts[0].split(':')[1];
    const byteCharacters = atob(parts[1]);

    // Create a Uint8Array to hold the byte values
    const byteNumbers = new Array(byteCharacters.length);
    for (let i = 0; i < byteCharacters.length; i++) {
      byteNumbers[i] = byteCharacters.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);

    // Create a Blob object from the byte array and specify the content type
    const blob = new Blob([byteArray], { type: contentType });

    // Create a File object from the Blob with the given file name
    const file = new File([blob], fileName, { type: contentType });

    return file;
  }

  useEffect(() => {
    if (isProcess) {
      handleDoneButton();
      setIsProcess(false)
    }
  }, [isProcess]);

  const handleDoneButton = () => {
    console.log("work");

    var cr = props.disableCropper ? (preview === "" ? src : preview) : cropImage();
    let tempName = fileName ? fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '') : "photo.jpg";

    let newFile = base64ToFile(cr, tempName)

    let fileSize = newFile.size;
    if (fileSize > 300000) {

      let cRate = 300000 / fileSize;
      // console.log(cRate)

      new Compressor(newFile, {
        quality: 0.8,
        width: 500,
        success: (compressedResult) => {

          // console.log(compressedResult)
          const reader = new FileReader();
          reader.onload = () => {
            const base64String = reader.result;

            var imageFile = new Parse.File(tempName, {
              base64: base64String,
            });

            // console.log(imageFile)
            togglePopup();
            props.onPicChange(imageFile, cr);
          };
          reader.readAsDataURL(compressedResult);
        },

      });

    } else {
      var imageFile = new Parse.File(tempName, {
        base64: cr,
      });

      togglePopup();
      props.onPicChange(imageFile, cr);
    }

  };

  // const compressAgain=( newFile,count)=>{
  //   new Compressor(newFile, {
  //     quality: 0.6,
  //     success: (compressedResult) => {

  //       let fileSize = newFile.size;
  //       console.log('fileSize: ',fileSize,", count: ",count);
  //       if (fileSize > 500000) {
  //         count++
  //         compressAgain(compressedResult,count)
  //       }else{
  //         return compressedResult;
  //       }
  //     },

  //   });
  // }

  const cropImage = () => {
    if (typeof cropper.getCroppedCanvas() === "undefined") {
      return;
    }
    setCropResult(cropper.getCroppedCanvas().toDataURL());
    setShouldShowDoneButton(true);
    return cropper.getCroppedCanvas().toDataURL();
  };

  return (
    <div>
      {displayButton()}

      <Modal isOpen={isModelOpen} fade={false}>
        <ModalHeader toggle={togglePopup}>Change Picture</ModalHeader>
        <ModalBody>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {props.disableCropper ?
              <img
                style={{ height: 400, width: "100%" }}
                src={preview === "" ? src : preview}
              >
              </img> : <Cropper
                style={{ height: 400, width: "100%" }}
                aspectRatio={props.isRectangle ? (108 / 31) : (1 / 1)}
                preview=".img-preview"
                guides={true}
                src={preview === "" ? src : preview}
                ref={(cropper) => {
                  setCropper(cropper);
                }}
              />}
          </div>
        </ModalBody>
        <ModalFooter>
          <Row className="d-flex justify-content-start">
            <Col xs={1} className="">
              {isProcess && <div className="font-size-14 m-1 d-flex align-items-center">
                <i className="bx bx-loader-circle bx-spin m-1"></i>
                Processing...
              </div>}
            </Col>
            <Col xs={11} className="float-end">
              {
                <Label
                  className="btn btn-primary m-1 float-end"
                  htmlFor={`inputImage ${props.tempId ?? ""}`}
                >
                  <input
                    type="file"
                    className="sr-only"
                    id={`inputImage ${props.tempId ?? ""}`}
                    name="file"
                    accept="image/*"
                    onChange={onChange}
                  />
                  Change
                </Label>
              }
              {shouldShowDoneButton && (
                <Button color="primary" className="m-1 float-end" onClick={() => {
                  setIsProcess(true);
                }}>
                  Done
                </Button>
              )}
              <Button color="secondary" className='m-1 float-end' onClick={togglePopup}>
                Cancel
              </Button>
            </Col>
          </Row>

        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UpdatePhoto;
