import {
  takeEvery,
  fork,
  put,
  all,
  call,
  takeLeading,
} from "redux-saga/effects";
import * as SchoolHelper from "../../../helpers/ParseHelpers/school_helper";
import * as actionTypes from "./actionTypes";
import * as SchoolActions from "./actions";
import Parse from "parse";
import * as Constants from "../../../Constents";
import actions from "redux-form/lib/actions";

function* getASchool({ payload: { id, isUpdate } }) {
  try {
    if (id) {
      const response = yield call(SchoolHelper.getSchoolFromParse, id, false);
      if (response) {
        if (isUpdate) {
          yield put(SchoolActions.updateSchool(response));
        }
        response.pin();
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteASchool({ payload: { schoolId } }) {
  try {
    const response = yield call(SchoolHelper.deleteSchoolFromParse, schoolId);
    if (response) {
      yield put(SchoolActions.deleteSchool(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* setSelectedSchool({ payload: { id } }) {
  try {
    if (id) {
      const localResponse = yield call(
        SchoolHelper.getSchoolFromParse,
        id,
        true
      );
      if (localResponse) {
        yield put(SchoolActions.updateSelecetdSchool(localResponse));
      }

      const serverResponse = yield call(
        SchoolHelper.getSchoolFromParse,
        id,
        false
      );
      if (serverResponse) {
        yield put(SchoolActions.updateSelecetdSchool(serverResponse));
        serverResponse.pin();
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editSchool({ payload: { school, moveBack } }) {
  try {
    const response = yield call(SchoolHelper.editSchool, school);
    if (response) {
      yield put(SchoolActions.updateSchool(response));
      if (moveBack) {
        window.history.back();
      }
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getPaymentOptionsForSchoolId({ payload: { id } }) {
  try {
    const response = yield call(SchoolHelper.getPaymentOptionsForSchoolId, id);
    if (response) {
      yield put(SchoolActions.updatePaymentOptions(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editPaymentOptions({ payload: { paymentOptionsForSchool } }) {
  try {
    const response = yield call(
      SchoolHelper.editPaymentOptions,
      paymentOptionsForSchool
    );
    if (response) {
      yield put(SchoolActions.updatePaymentOptions(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

//School Ratings
function* getRatingParamsForSchoolId({ payload: { schoolObject } }) {
  try {
    const response = yield call(
      SchoolHelper.getRatingParamsForSchoolId,
      schoolObject
    );
    if (response) {
      yield put(SchoolActions.updateRatingParams(response));
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getSchoolRatingsForSchoolId({ payload: { id, ratingType } }) {
  try {
    //yield put(SchoolActions.clearSchoolRatings([]));
    var key = Constants.SCHOOL_RATING_UPDATED_TIME + "_" + ratingType + "_" + id;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      SchoolHelper.getSchoolRatingsForSchoolId,
      id,
      ratingType,
      null,
      true
    );
    yield put(SchoolActions.updateSchoolRatings(localResponse));

    const serverResponse = yield call(
      SchoolHelper.getSchoolRatingsForSchoolId,
      id,
      ratingType,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      //yield put(SchoolActions.clearSchoolRatings([]));
      yield put(SchoolActions.updateSchoolRatings(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

// function* getSchoolRatingsForSchoolId({ payload: { id, ratingType } }) {
//   try {
//     var key = Constants.SCHOOL_RATING_UPDATED_TIME + "_" + ratingType + "_" + id;
//     var updatedTime = localStorage.getItem(key);
//     var newTime = new Date();

//     // Execute local query
//     const localResponse = yield call(
//       SchoolHelper.getSchoolRatingsForSchoolId,
//       id,
//       ratingType,
//       null,
//       true
//     );
//     yield put(SchoolActions.updateSchoolRatings(localResponse));

//     // Execute server query with updatedDate
//     const serverResponseWithDate = yield call(
//       SchoolHelper.getSchoolRatingsForSchoolId,
//       id,
//       ratingType,
//       updatedTime,
//       false
//     );
//     if (serverResponseWithDate && serverResponseWithDate.length > 0) {
//       yield put(SchoolActions.updateSchoolRatings(serverResponseWithDate));
//       Parse.Object.pinAll(serverResponseWithDate);
//     }

//     // Execute server query date range
//     else {
//       const serverResponseFixedRange = yield call(
//         SchoolHelper.getSchoolRatingsForSchoolId,
//         id,
//         ratingType,
//         null,
//         false
//       );
//       if (serverResponseFixedRange && serverResponseFixedRange.length > 0) {
//         yield put(SchoolActions.updateSchoolRatings(serverResponseFixedRange));
//         Parse.Object.pinAll(serverResponseFixedRange);
//       }
//     }

//     localStorage.setItem(key, newTime);
//   } catch (error) {
//     // yield put(apiError(error));
//   }
// }

function* getAllScholForInstitute({ payload: { instituteId } }) {
  try {
    const response = yield call(
      SchoolHelper.getAllSchoolsForInstitute,
      instituteId
    );
    // yield put(SchoolActions.setAllSchoolsForInstitute(response))
  } catch (error) { }
}

function* getSchoolsForInstutute({ payload: { id, type, userTeacher } }) {
  try {
    let schoolId = [];
    if (userTeacher && userTeacher.attributes.schools) {
      schoolId = userTeacher.attributes.schools;
    }
    const localResponse = yield call(
      SchoolHelper.getSchoolsForInstitute,
      id,
      type,
      true,
      schoolId
    );
    yield put(SchoolActions.updateSchools(localResponse));

    const serverResponse = yield call(
      SchoolHelper.getSchoolsForInstitute,
      id,
      type,
      false,
      schoolId,
    );

    if (serverResponse && serverResponse.length > 0) {
      yield put(SchoolActions.updateSchools(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function setSechoolToLocal(newList) {
  if (newList.length > 0) {
    var schoolId = localStorage.getItem(Constants.SELECTED_SCHOOL_ID);
    if (schoolId) {
      var temp = newList.filter((school) => school.id === schoolId);
      if (temp && temp.length > 0) {
        return temp[0];
      } else {
        localStorage.setItem(Constants.SELECTED_SCHOOL_ID, newList[0].id);
        return newList[0];
      }
    } else {
      localStorage.setItem(Constants.SELECTED_SCHOOL_ID, newList[0].id);
      return newList[0];
    }
  }
}

function* switchSchool({ payload: { school } }) {
  try {
    // yield put(SchoolActions.updateSelecetdSchool(school));
    // localStorage.setItem(Constants.SELECTED_SCHOOL_ID, school.id)
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getFranchiseDetails({ payload: { franchiseId } }) {
  try {
    let response = yield call(SchoolHelper.getFranchiseDetails, franchiseId);
    yield put(SchoolActions.setFranchiseDetails(response));
  } catch (err) {
    console.log(err);
  }
}
function* getAllSchoolDetailsForFranchise({ payload: { franchiseId } }) {
  try {
    let response = yield call(
      SchoolHelper.getAllSchoolForFranchise,
      franchiseId
    );
    yield put(SchoolActions.setAllSchoolDetailsForFranchise(response));
  } catch (err) {
    console.log(err);
  }
}
function* getAllSchoolDetailsForInstitute({ payload: { instituteId } }) {
  try {
    let response = yield call(
      SchoolHelper.getAllSchoolForInstitute,
      instituteId
    );
    yield put(SchoolActions.setAllSchoolDetailsForFranchise(response));
  } catch (err) {
    console.log(err);
  }
}

function* getInstituteDeatilsForId({ payload: { instituteId } }) {
  try {
    let response = yield call(
      SchoolHelper.getInstituteDeatilsForId,
      instituteId
    );
    yield put(SchoolActions.setInstituteDetailsForId(response));
  } catch (err) {
    console.log(err);
  }
}

function* getReceiptAddressForSchoolId({ payload: { schoolId } }) {
  try {
    let response = yield call(
      SchoolHelper.getReceiptAddressForSchoolId,
      schoolId
    );
    yield put(SchoolActions.updateReceiptAddressForSchoolId(response));
  } catch (err) {
    console.log(err);
  }
}

function* getSchoolAnalytics({ payload: { id, type } }) {

  try {

    const localResponse = yield call(
      SchoolHelper.getSchoolAnalyticsForDay,
      id, type,
      null,
      true
    );
    yield put(SchoolActions.updateSchoolAnalytics(localResponse));

    const serverResponse = yield call(
      SchoolHelper.getSchoolAnalyticsForDay,
      id, type,
      null,
      false
    );

    if (serverResponse && serverResponse.length > 0) {
      yield put(SchoolActions.updateSchoolAnalytics(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


// *****************************ANALYTICS AND TOOLS**************************************//



function* getSchoolAnalyticsForSchoolObject({ payload: { id } }) {

  try {
    const response = yield call(SchoolHelper.getSchoolAnalyticsForSchoolObject, id);
    if (response) {
      yield put(SchoolActions.updateSchoolAnalyticsForSchoolObject(response));
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getInstitutesForTools() {
  try {
    let response = yield call(SchoolHelper.getInstitutesForTools);
    if(response){
      yield put(SchoolActions.updateInstituteForTools(response));
    }
  } catch (err) {
    console.log(err);
  }
}

function* getSchoolsForTools() {
  try {
    let response = yield call(SchoolHelper.getSchoolsForTools);
    if(response){
      yield put(SchoolActions.updateSchoolsForTools(response));
    }
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetSchoolRatingsForSchoolId() {
  yield takeEvery(actionTypes.GET_SCHOOL_RATINGS, getSchoolRatingsForSchoolId);
}

export function* watchGetRatingParamsForSchoolId() {
  yield takeEvery(actionTypes.GET_RATING_PARAMS, getRatingParamsForSchoolId);
}

export function* watchEditPaymentOptions() {
  yield takeEvery(actionTypes.EDIT_PAYMENT_OPTIONS, editPaymentOptions);
}

export function* watchGetPaymentOptionsForSchool() {
  yield takeEvery(
    actionTypes.GET_PAYMENT_OPTIONS_FOR_SCHOOL,
    getPaymentOptionsForSchoolId
  );
}

export function* watchGetSchool() {
  yield takeEvery(actionTypes.GET_SCHOOL, getASchool);
}

export function* watchDeleteSchool() {
  yield takeEvery(actionTypes.DELETE_SCHOOL, deleteASchool);
}

export function* watchSetSelectedSchool() {
  yield takeEvery(actionTypes.SET_SELECTED_SCHOOL, setSelectedSchool);
}

export function* watchEditSchool() {
  yield takeEvery(actionTypes.EDIT_SCHOOL, editSchool);
}
export function* watchGetAllSchools() {
  yield takeEvery(
    actionTypes.GET_ALL_SCHOOLS_FOR_INSTITUTE,
    getAllScholForInstitute
  );
}

export function* watchGetSchoolsForInstitute() {
  yield takeLeading(
    actionTypes.GET_SCHOOLS_FOR_INSTITUTE,
    getSchoolsForInstutute
  );
}

export function* watchSwitchSchool() {
  yield takeLeading(actionTypes.SWITCH_SCHOOL, switchSchool);
}
export function* watchGetFranchiseDetails() {
  yield takeEvery(actionTypes.GET_FRANCHISE_DETAILS, getFranchiseDetails);
}
export function* watchGetAllSchoolForFranchise() {
  yield takeEvery(
    actionTypes.GET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE,
    getAllSchoolDetailsForFranchise
  );
}
export function* watchGetAllSchoolForInstitute() {
  yield takeEvery(
    actionTypes.GET_ALL_SCHOOL_DETAILS_FOR_INSTITUTE,
    getAllSchoolDetailsForInstitute
  );
}
export function* watchGetInstituteDetailsForId() {
  yield takeEvery(
    actionTypes.GET_INSTITUTE_DETAILS_FOR_ID,
    getInstituteDeatilsForId
  );
}

export function* watchReceiptAddress() {
  yield takeEvery(
    actionTypes.GET_RECEIPT_ADDRESS_FOR_SCHOOL,
    getReceiptAddressForSchoolId
  );
}
export function* watchGetSchoolAnalytics() {
  yield takeEvery(actionTypes.GET_SCHOOL_ANALYTICS, getSchoolAnalytics);
}

export function* watchGetSchoolAnalyticsForSchoolObject() {
  yield takeEvery(actionTypes.GET_SCHOOL_ANALYTICS_FOR_SCHOOL_OBJECT, getSchoolAnalyticsForSchoolObject);
}

export function* watchGetInstitutesForTools(){
  yield takeEvery(actionTypes.GET_INSTITUTES_FOR_TOOLS, getInstitutesForTools);
}

export function* watchGetSchoolsForTools(){
  yield takeEvery(actionTypes.GET_SCHOOLS_FOR_TOOLS, getSchoolsForTools);
}

function* schoolSaga() {
  yield all([
    fork(watchGetSchoolRatingsForSchoolId),
    fork(watchGetRatingParamsForSchoolId),
    fork(watchEditPaymentOptions),
    fork(watchGetPaymentOptionsForSchool),
    fork(watchGetSchool),
    fork(watchDeleteSchool),
    fork(watchSetSelectedSchool),
    fork(watchEditSchool),
    fork(watchGetAllSchools),
    fork(watchGetSchoolsForInstitute),
    fork(watchSwitchSchool),
    fork(watchGetFranchiseDetails),
    fork(watchGetAllSchoolForFranchise),
    fork(watchGetInstituteDetailsForId),
    fork(watchGetAllSchoolForInstitute),
    fork(watchReceiptAddress),
    fork(watchGetSchoolAnalytics),
    fork(watchGetSchoolAnalyticsForSchoolObject),
    fork(watchGetInstitutesForTools),
    fork(watchGetSchoolsForTools),
  ]);
}
export default schoolSaga;
