import React, { useEffect, useState } from 'react';
import {
    Container, Card, CardBody, Row, Col, Table,
    UncontrolledTooltip, Label, Button, Modal, FormGroup, ModalHeader, ModalBody, ModalFooter, Input
} from "reactstrap";
import Parse from 'parse';
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import Moment from 'moment';
import {
    setSelecetdSchool,
    setSelectedCourse,
} from "../../../store/actions";
import * as CommonFunctions from "../../../components/Common/CommonFunctions";
import TopBar from '../TopBar';



const FeedbackForms = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/course-feedback-forms/:schoolId/:courseId',
        exact: true,
        strict: false,
    });

    const schoolId = match ? match.params.schoolId : "";
    const courseId = match ? match.params.courseId : "";

    
    const [allFormMap, setAllFormMap] = useState([]);
    const [loading, setLoading] = useState(true);
    const [courseFormMap, setCourseFormMap] = useState([]);
    const [mapedFormIds, setMapedFormIds] = useState([]);
    const [formObjects, setFormObjects] = useState([]);
    const [showAddFormModel, setShowAddFormModel] = useState(false);
    const [searchedFormObjects, setSearchedFormObjects] = useState([]);
    const [deleteCourseMap, setDeleteCourseMap] = useState(false);

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.setSelectedCourse(courseId);
        
        getForms();
    }, [courseId]);

    const getForms = () => {

        let ids = [];
    
        if (props.selectedSchool) {
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }
        }
        ids.push(schoolId)
       
        var query = new Parse.Query("Forms");
        query.containedIn("ownerId", ids);
        query.notEqualTo("isDeleted", true);
        query.limit(1000);
        query.descending("createdAt");

        query.find().then((results) => {
            setLoading(false);
            if (results && results.length > 0) {
                setFormObjects(results);
                setSearchedFormObjects(results)
                featchCourseFormMap(results);
                
            }
        });
        
    }

    const featchCourseFormMap = (forms) => {
        var query = new Parse.Query("CourseFormMap");
        query.equalTo("courseId", courseId);
        query.notEqualTo("isDeleted", true);
        query.limit(1000);
        query.descending("createdAt");
        query.find().then((results) => {
           
            if (results && results.length > 0) {
                setCourseFormMap(results);
                let ids = [];
                for (const cfm of results) {
                    ids.push(cfm.attributes.formId);
                }
                setMapedFormIds(ids);
                createList(results,forms);
            } else {
                setLoading(false);
            }
            
        });
    };

    

    const createList = (maps, forms) => {
        let list = [];
        for (const map of maps) {
            let temp = forms.filter(
                (f) => f.id === map.attributes.formId
            );

            if (temp.length > 0) {
                list.push({ id: map.id, courseMap: map, form: temp[0] });
            }
        }
        setAllFormMap(list);
    }
   
    

    const handelAttachForm = (formId) => {
        
        let list = [...mapedFormIds];
        list.push(formId);
        setMapedFormIds(list)
        let CourseFormMap = Parse.Object.extend("CourseFormMap");
        let cfm = new CourseFormMap();
        cfm.set("courseId", courseId);
        cfm.set("formId", formId);
        cfm.set("attachedBy", Parse.User.current().attributes.teacherId);
        cfm.save().then((r) => {
            let l = [...courseFormMap];
            l.push(r);
            setCourseFormMap(l);
            createList(l, formObjects);
        });

    }
    const handelOnClickBack = () => {
        window.history.back();
    }

    const getTimeStringForDate = (date) => {
        return Moment(date).format('DD-MMM-YYYY');
    }
 

    const handelFormList = (value) => {
        let list = [];
        if (value) {
            for (const ex of formObjects) {
                if (ex.attributes.name.toLowerCase().includes(value.toLowerCase())) {
                    list.push(ex);
                }
            }
            setSearchedFormObjects(list);
        } else {
            setSearchedFormObjects(formObjects);
        }
        
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <TopBar
                        title={props.selectedCourse ? "Feedback Forms for " + props.selectedCourse.attributes.name : "Feedback Forms"}
                        buttonName={"Attach a Form"}
                        backButton={true}
                        hideButton={loading}
                        onClickBackButton={handelOnClickBack}
                        onClickAdd={()=>{setShowAddFormModel(true)}}
                        reorderButton={false}
                    />
                    <Row >
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    {allFormMap.length > 0 && <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="col-1" scope="col" align="center">#</th>
                                                <th className="col-9" scope="col">Name</th>
                                                <th className="col-2" scope="col" align="right" style={{ textAlign: "right" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allFormMap.map((item, key) =>
                                                <tr key={item.id} >
                                                    <td>
                                                        <h5>{(key + 1)}</h5>
                                                    </td>

                                                    <td
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {

                                                        }}>
                                                        <h5 className="text-truncate font-size-14  mb-1 text-dark">{item.form.attributes.name}</h5>
                                                       
                                                        <p className="text-success">{item.formMap && " Attached on:" + getTimeStringForDate(item.formMap.attributes.createdAt)}</p>
                                                    </td>

                                                    <td>
                                                        <ul className="list-inline font-size-20 contact-links mb-0 float-right">
                                                            {CommonFunctions.canEdit(props.selectedCourse, props.userTeacher) || true && <li className="list-inline-item px-2"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    setDeleteCourseMap(item);
                                                                }}
                                                                id={"sub-delete" + key}>
                                                                <i className="bx bx-trash text-danger"></i>
                                                                <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                    {props.t('Delete')}
                                                                </UncontrolledTooltip>

                                                            </li>

                                                            }
                                                            <li className="list-inline-item px-2"
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    props.history.push("/course-form-session/" + schoolId + "/" + courseId+"/"+item.form.id);
                                                                }}
                                                                id={"sub-cyc" + key}>
                                                                <i className="bx bx-right-arrow-circle"></i>
                                                                <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                    {props.t('Details')}
                                                                </UncontrolledTooltip>
                                                            </li>
                                                        </ul>
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>}

                                    {
                                        !loading && allFormMap.length === 0 &&
                                        <Card>
                                            <CardBody className="text-center" >

                                                <h3 className="mt-5">{

                                                    props.t('There are no feedback forms')

                                                }</h3>
                                              
                                            </CardBody>
                                        </Card>
                                    }

                                    {
                                        loading && <Col xs="12">
                                            <Card>
                                                <CardBody className="text-center" >
                                                    <div className="text-center">
                                                        <Label className="text-success">
                                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                                                        </Label>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <Modal
                size="lg"
                isOpen={showAddFormModel ? true : false}
                toggle={() => {
                    setShowAddFormModel(undefined);

                }}
                scrollable={true}
            >

                <ModalHeader className="bg-light" toggle={() => {
                    setShowAddFormModel(undefined);
                }}>
                    <Row style={{ width: "100%" }} >
                        <Col lg={6} md={6} sm={6} xs={12}><Label className='mt-1'>Forms</Label></Col>
                        <Col lg={6} md={6} sm={6} xs={12} >

                            <div className="search-box p-0 float-right ">
                                <div className="position-relative">
                                    <Input type="text"
                                        className="form-control border-0"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                             handelFormList(e.target.value)
                                        }}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </ModalHeader>
                <ModalBody
                    style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
                >
                    <div className="table-responsive ">
                        {searchedFormObjects && searchedFormObjects.length > 0 ? <Table className="table-centered table-nowrap table-hover">
                            <thead >
                                <tr>
                                    <th scope='col' className="col-1">
                                        <div>#</div>
                                    </th>
                                    <th className="col-6" scope="col">Name</th>
                                    <th className="col-5" scope="col" style={{ textAlign: "center" }}>
                                        Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {searchedFormObjects.map((form, key) => (
                                    <tr key={form.id}>
                                        <td>
                                            {(key + 1)}
                                        </td>

                                        <td>
                                            <Label className='mt-2'>{form.attributes.name}</Label>                                            
                                        </td>
                                        <td style={{ textAlign: "center" }} >
                                            <button
                                                className='btn btn-sm btn-primary'
                                                disabled={mapedFormIds.includes(form.id) ? true : false}
                                                onClick={() => {
                                                    handelAttachForm(form.id);
                                                    setShowAddFormModel(undefined);
                                                }}
                                            >
                                                Attach
                                            </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table> : <Card>
                            <CardBody className="text-center" >

                                <h3 className="mt-5">{
                                    formObjects && formObjects.length === 0 &&
                                    props.t('There are no feedback forms to attach, Please add one and try attaching')
                                       
                                }</h3>
                                {searchedFormObjects && searchedFormObjects.length > 0 ?
                                    <p>{props.t('No feedback forms avilable')}.</p> : null}


                            </CardBody>
                        </Card>}
                    </div>

                </ModalBody>
            </Modal>

        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { courses, selectedCourse } = state.Course;
   
    return {  selectedSchool, courses, selectedCourse,};
};


export default connect(mapStatetoProps, {
    setSelecetdSchool,
    setSelectedCourse,
})(withNamespaces()(FeedbackForms));
