import Parse from "parse";
import { printLog } from "./loghelper";
const getAdmissionTitleFromParse = (admissionTitleId) => {
  printLog("HL: admissionTitle_helper : getAdmissionTitleFromParse")
  try {
    return new Promise((resolve, reject) => {
      var AdmissionTitle = Parse.Object.extend("AdmissionTitle");
      var query = new Parse.Query(AdmissionTitle);
      query.notEqualTo("isDeleted", true);
      query.get(admissionTitleId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
}

const deleteAdmissionTitleFromParse = (admissionTitle) => {
  printLog("HL: admissionTitle_helper : deleteAdmissionTitleFromParse")

    try {
      return new Promise((resolve, reject) => {
       
        admissionTitle.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            admissionTitle.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            admissionTitle.set("deletedBy", Parse.User.current().id);
        }
  
        admissionTitle.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  const getAdmissionTitleForSchoolId = (id,updatedDate,isLocal,setup) => {
    printLog("HL: admissionTitle_helper : getAdmissionTitleForSchoolId")
    try {
      return new Promise((resolve, reject) => {
        var AdmissionTitle = Parse.Object.extend("AdmissionTitle");
        var query = new Parse.Query(AdmissionTitle);
        query.equalTo("schoolId", id);
  
        
  
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
        } else {
          query.notEqualTo("isDeleted", true);
        }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
        query.ascending("pageNumber");
        query.find().then(
          (result) => {
            

            //function_AdmissionFormFromOneToAnother // demoSchoolId:"29ZbnOOluj"
            if (!isLocal && (!result || result.length === 0) && setup) {
              Parse.Cloud.run("function_createDefaultAdmissionForm", {
                schoolId: id,
              }).then((newresults) => {
                resolve(newresults);
              });
            } else {
              resolve(result);
            } 
          },
          (error) => {
            resolve(null);
          }
        );
      });
    } catch (error) {}
  };


  const getAdmissionTitleFromLocal = (id) => {
    printLog("HL: admissionTitle_helper : getAdmissionTitleFromLocal")
    try {
      return new Promise((resolve, reject) => {
        var AdmissionTitle = Parse.Object.extend("AdmissionTitle");
        var query = new Parse.Query(AdmissionTitle);
        query.fromLocalDatastore();
        query.get(id).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  const editTitle = (object) => {
    printLog("HL: admissionTitle_helper : editTitle")
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) {}
  };


  const saveAdmissionTitle = (objects) => {
      printLog("HL: admissionTitle_helper : saveAdmissionTitle")
    try {
      return new Promise((resolve, reject) => {
        Parse.Object.saveAll(objects).then(
            (result) => {
              resolve(result);
            },
              (error) => {
                resolve(error);
                console.log("Error", error);
            }
          );
          
        });	
  
    } catch (error) { 
      console.log("Error", error);
    }
  }
  
  
  
export {
  getAdmissionTitleFromParse,
  deleteAdmissionTitleFromParse,
  getAdmissionTitleForSchoolId,
  getAdmissionTitleFromLocal,
  editTitle,
  saveAdmissionTitle,
}