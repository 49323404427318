import Parse from "parse";
import { printLog } from "./loghelper";
const getKidFromParse = (kidId) => {
  printLog("HL: kid_helper : getKidFromParse")
  try {
    return new Promise((resolve, reject) => {
      let kQuery = new Parse.Query("Kid");
      kQuery.equalTo("objectId", kidId);
      kQuery.first().then((kid) => {
        let kidObj = kid;
        kidObj.parents = [];
        let query = new Parse.Query("Parent");
        query.equalTo("FamilyID", kid.attributes.FamilyID);
        query.ascending("Relation")
        query.notEqualTo("Deleted", true);
        query.find().then((parents) => {
          Parse.Object.pinAll(parents);
          kidObj.parents = parents;
          resolve(kidObj);
        });
      });
    });
  } catch (error) { }
};

export const getKidObjFromLocal = (id) => {
  printLog("HL: kid_helper : getKidObjFromLocal")
  try {
    return new Promise((resolve, reject) => {
      var Kid = Parse.Object.extend("Kid");
      var query = new Parse.Query(Kid);
      query.fromLocalDatastore();
      query.get(id).then(
        (kid) => {
          let kidObj = kid;
          kidObj.parents = [];
          let query = new Parse.Query("Parent");
          query.ascending("Relation")
          query.equalTo("FamilyID", kid.attributes.FamilyID);
          query.notEqualTo("Deleted", true);
          query.fromLocalDatastore()
          query.find().then((parents) => {

            //Parse.Object.pinAll(parents);
            kidObj.parents = parents;
            resolve(kidObj);
          });

        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}


const getCADForAKid = (kidId) => {
  printLog("HL: kid_helper : getCADForAKid")
  try {
    return new Promise((resolve, reject) => {
      let CADQuery = new Parse.Query("ChildAdditionalDetails");
      CADQuery.equalTo("kidId", kidId);
      CADQuery.first().then((cadObj) => {
        resolve(cadObj);
      });
    });
  } catch (error) { }
};
export const updateCADForAKidParse = (adObj) => {
  printLog("HL: kid_helper : updateCADForAKidParse")
  try {
    return new Promise((resolve, reject) => {
      adObj.save().then((cadObj) => {
        resolve(cadObj);
      });
    });
  } catch (error) { }
};
export const addKidToDBParse = (refObj) => {
  printLog("HL: kid_helper : addKidToDBParse")
  try {
    return new Promise((resolve, rejected) => {
      let Kid = Parse.Object.extend("Kid");
      let kid = new Kid();
      kid.set("Name", refObj.childName);
      if (refObj.admissionNumber) {
        kid.set("admissionNumber", refObj.admissionNumber);
      }
      kid.set("Gender", refObj.gender);
      kid.set("SchoolID", refObj.schoolId);
      kid.set("FamilyID", refObj.UUID);
      kid.set("ClassRoomID", refObj.selectedClass);
      if (refObj.dob) {
        kid.set("DateOfBirth", new Date(refObj.dob));
      }
      if (refObj.corporateId) {
        kid.set("corporateId", refObj.corporateId);
      }
      kid.save().then((result) => {
        resolve(result);
      });
    });
  } catch (error) { }
};

const getAttendanceForKid = (kidId, month) => {
  printLog("HL: kid_helper : getAttendanceForKid")
  try {
    return new Promise((resolve, reject) => {
      let sDate = new Date(
        month.getFullYear(),
        month.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      let eDate = new Date(
        month.getFullYear(),
        month.getMonth() + 1,
        0,
        23,
        59,
        59,
        59
      );
      let query = new Parse.Query("Attendance");
      query.equalTo("Kid", kidId);
      query.greaterThan("createdAt", sDate);
      query.lessThan("createdAt", eDate);
      query.limit(1000);
      query.descending("createdAt");
      query.find().then((attObj) => {
        resolve(attObj);
      });
    });
  } catch (error) { }
};

export const getDaycareAttendanceForKid = (kidId, month) => {
  printLog("HL: kid_helper : getDaycareAttendanceForKid")
  try {
    return new Promise((resolve, reject) => {
      let sDate = new Date(
        month.getFullYear(),
        month.getMonth(),
        1,
        0,
        0,
        0,
        0
      );
      let eDate = new Date(
        month.getFullYear(),
        month.getMonth() + 1,
        0,
        23,
        59,
        59,
        59
      );
      let query = new Parse.Query("DaycareTimeSheet");
      query.equalTo("kidId", kidId);
      query.greaterThan("createdAt", sDate);
      query.lessThan("createdAt", eDate);
      query.limit(1000);
      query.descending("createdAt");
      query.find().then((attObj) => {
        resolve(attObj);
      });
    });
  } catch (error) { }
};

export const getKidsfromKidsIds = (kidIds) => {
  printLog("HL: kid_helper : getKidsfromKidsIds")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Kid");
      query.containedIn("objectId", kidIds);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (error) { }
};

export const saveKids = (kids) => {
  printLog("HL: kid_helper : saveKids")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(kids).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
};

export const updateKidFromParse = (kid) => {
  printLog("HL: kid_helper : updateKidFromParse")
  try {
    return new Promise((resolve, reject) => {
      kid.save().then((kid) => {
        let kidObj = kid;
        kidObj.parents = [];
        let query = new Parse.Query("Parent");
        query.equalTo("FamilyID", kid.attributes.FamilyID);
        query.notEqualTo("Deleted", true);
        query.find().then((parents) => {
          kidObj.parents = parents;
          resolve(kidObj);
        });
      });
    });
  } catch (error) { }
};

const deleteKidsInListParse = (kidsToDelete) => {
  printLog("HL: kid_helper : deleteKidsInListParse")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(kidsToDelete).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
};

export const sendAdmissionLinkToParentServer = (
  enqId,
  emails,
  phoneNumbers,
  admissionURL,
  schoolName,
  schoolId
) => {
  printLog("HL: kid_helper : sendAdmissionLinkToParentServer")
  try {
    return new Promise((resolve, reject) => {
      Parse.Cloud.run("function_sendAdmissionFormLinkToParent", {
        enquiryId: enqId,
        emails: emails,
        phoneNumbers: phoneNumbers,
        admissionURL: admissionURL,
        schoolName: schoolName,
        schoolId: schoolId,
      }).then((result) => {
        resolve(true);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

const getescortsForKid = (kidId) => {
  printLog("HL: kid_helper : getescortsForKid")
  try {
    return new Promise((resolve, reject) => {
      let pADQuery = new Parse.Query("PickNDrop");
      pADQuery.equalTo("KidID", kidId);
      pADQuery.notEqualTo("Deleted", true);
      pADQuery.find().then((escortObj) => {
        resolve(escortObj);
      });
    });
  } catch (error) { }
};

const getVaccineDetailsForKid = (kidId) => {
  printLog("HL: kid_helper : getVaccineDetailsForKid")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("VaccinationMasterList");
      query.descending("vaccinationAgeMonths");
      query.find().then((questionsObj) => {
        let temp = {};
        temp.questions = questionsObj;
        let query = new Parse.Query("ChildVaccinationProgress");
        query.equalTo("kidId", kidId);
        query.find().then((cvaccineObj) => {
          temp.completedVaccine = cvaccineObj;
          resolve(temp);
        });
      });
    });
  } catch (error) { }
};

const getMedicalDetailsForAKid = (medicalId) => {
  printLog("HL: kid_helper : getMedicalDetailsForAKid")
  try {
    return new Promise((resolve, reject) => {
      let medQuery = new Parse.Query("MedicalDetails");
      medQuery.equalTo("KidID", medicalId);
      medQuery.first().then((medObj) => {
        resolve(medObj);
      });
    });
  } catch (error) { }
};

const getMedicalDetailsForKidIds = (kidIds) => {
  printLog("HL: kid_helper : getMedicalDetailsForKidIds")
  try {
    return new Promise((resolve, reject) => {
      let medQuery = new Parse.Query("MedicalDetails");
      medQuery.containedIn("KidID", kidIds);
      medQuery.find().then((medObj) => {
        resolve(medObj);
      });
    });
  } catch (error) { }
};

const deleteKidFromParse = (kidId) => {
  printLog("HL: kid_helper : deleteKidFromParse")
  try {
    return new Promise((resolve, reject) => {
      const Kid = Parse.Object.extend("Kid");
      var kid = new Kid();
      kid.id = kidId;
      kid.set("Deleted", true);
      // if (Parse.User.current().attributes.teacherId) {
      //   kid.set("deletedBy", Parse.User.current().attributes.teacherId);
      // } else {
      //   kid.set("deletedBy", Parse.User.current().id);
      // }

      kid.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};
export const getAllKidForClassIdsParse = (classIds) => {
  printLog("HL: kid_helper : getAllKidForClassIdsParse")
  try {
    return new Promise((resolve, reject) => {
      if (classIds.length > 0) {
        let query1 = new Parse.Query("Kid");
        query1.containedIn("ClassRoomID", classIds);
        let query2 = new Parse.Query("Kid");
        //query2.equalTo("isDaycareChild", 1);
        query2.containedIn("daycareClassId", classIds);
        let query = Parse.Query.or(query1, query2);
        query.notEqualTo("Deleted", true);
        query.ascending("Name");
        query.limit(1000);
        query.find().then((kids) => {
          let objKids = kids;
          let familyIds = [];
          for (let i in kids) {
            if (kids[i].attributes.FamilyID) {
              familyIds.push(kids[i].attributes.FamilyID);
            }
            if (kids[i].attributes.name === "test kid 1") {
              console.log("sd");
            }
          }
          if (familyIds.length > 0) {
            let query = new Parse.Query("Parent");
            query.containedIn("FamilyID", familyIds);
            query.notEqualTo("Deleted", true);
            query.limit(1000);
            query.find().then((parents) => {
              for (let i in parents) {
                for (let k in objKids) {
                  if (
                    objKids[k].attributes.FamilyID ===
                    parents[i].attributes.FamilyID
                  ) {
                    if (objKids[k].parents) {
                      objKids[k].parents.push(parents[i]);
                    } else {
                      objKids[k].parents = [];
                      objKids[k].parents.push(parents[i]);
                    }
                  }
                }
              }
              resolve(kids);
            });
          } else {
            resolve([]);
          }
        });
      }
    });
  } catch (error) { }
};

const getAllDeletedKidsForSchoolId = (schoolId) => {
  printLog("HL: kid_helper : getAllDeletedKidsForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      if (schoolId) {
        let tempKidList = [];
        let query = new Parse.Query("Kid");
        query.equalTo("SchoolID", schoolId);
        query.ascending("Name");
        query.equalTo("Deleted", true);
        query.limit(1000);
        query.find().then((kids) => {
          let Group = Parse.Object.extend("Group");
          let query = new Parse.Query(Group);
          query.limit(1000);
          query.equalTo("schoolId", schoolId);
          query.equalTo("type", 4);

          // if (updatedDate) {
          //     query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate))
          // } else {
          //     query.notEqualTo("deleted", true);
          // }

          // if (isLocal) {
          //     query.fromLocalDatastore();
          // }

          query.ascending("name");
          query.find().then(
            (result) => {
              let tempGroupIds = [];
              if (result.length > 0) {
                for (var i = 0; i < result.length; i++) {
                  tempGroupIds.push(result[i].id);
                }
                var GroupMap = Parse.Object.extend("GroupMap");
                var query = new Parse.Query(GroupMap);
                query.containedIn("groupId", tempGroupIds);
                query.notEqualTo("isDeleted", true);
                query.limit(1000);
                query.find().then((result) => {
                  if (result.length > 0) {
                    for (var j = 0; j < kids.length; j++) {
                      let canPush = true;
                      for (var i = 0; i < result.length; i++) {
                        if (result[i].attributes.userId === kids[j].id) {
                          canPush = false;
                        }
                      }
                      if (canPush) {
                        tempKidList.push(kids[j]);
                      }
                    }
                    resolve(tempKidList);
                  } else {
                    resolve(kids);
                  }
                });
              } else {
                resolve(kids);
              }
            },
            (error) => { }
          );
        });
      } else {
        resolve([]);
      }
    });
  } catch (error) { }
};

export const getAllDeletedKidsForIds = (kidIds) => {
  printLog("HL: kid_helper : getAllDeletedKidsForIds")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Kid");
      query.containedIn("objectId", kidIds);
      query.equalTo("Deleted", true);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (error) { }
};

const getAllKidForSchoolParse = (schoolId) => {
  printLog("HL: kid_helper : getAllKidForSchoolParse")
  try {
    return new Promise((resolve, reject) => {
      if (schoolId) {


        let query = new Parse.Query("Kid");
        query.equalTo("SchoolID", schoolId);
        //query.exists("SchoolID");
        query.notEqualTo("Deleted", true);
        query.ascending("Name");
        query.limit(1000);
        query.find().then((kids) => {
          let objKids = kids;
          let familyIds = [];
          for (let i in objKids) {
            if (kids[i].attributes.FamilyID) {
              familyIds.push(kids[i].attributes.FamilyID);
            }
            objKids[i].parents = [];
          }

          resolve(kids);
          // let query = new Parse.Query("Parent");
          // query.containedIn("FamilyID", familyIds);
          // query.notEqualTo("Deleted", true);
          // query.limit(1000);
          // query.find().then((parents) => {
          //   for (let i in parents) {
          //     for (let k in objKids) {
          //       if (
          //         objKids[k].attributes.FamilyID ===
          //         parents[i].attributes.FamilyID
          //       ) {
          //         if (objKids[k].parents) {
          //           objKids[k].parents.push(parents[i]);
          //         } else {
          //           objKids[k].parents = [];
          //           objKids[k].parents.push(parents[i]);
          //         }
          //       }
          //     }
          //   }
          //   resolve(kids);
          // });
        });
      } else {
        resolve([]);
      }
    });
  } catch (error) { }
};

const getAllKidsForGroups = (schoolId, updatedDate, isLocal) => {
  printLog("HL: kid_helper : getAllKidsForGroups")
  try {
    return new Promise((resolve, reject) => {
      var Teacher = Parse.Object.extend("Kid");
      var query = new Parse.Query(Teacher);
      query.equalTo("SchoolID", schoolId);

      query.ascending("Name");
      query.limit(1000);
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("Deleted", true);
      }

      if (isLocal) {
        query.fromLocalDatastore();
      }

      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};


const getAdmissionsKidsFroSchool = (schoolId, updatedDate, isLocal) => {
  printLog("HL: kid_helper : getAdmissionsKidsFroSchool")
  try {
    return new Promise((resolve, reject) => {
      if (updatedDate) {
        let query;
        let query1 = new Parse.Query("Kid");
        query1.equalTo("SchoolID", schoolId);
        let query2 = new Parse.Query("Kid");
        query2.equalTo("schoolAdmitted", schoolId);

        query = Parse.Query.or(query1, query2);
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
        query.limit(1000);
        query.exists("enquiryId");
        query.find().then(
          (results) => {
            resolve(results);
          },
          (error) => {
            reject();
          }
        );
      } else {
        var Kid = Parse.Object.extend("Kid");
        var query = new Parse.Query(Kid);
        query.equalTo("schoolAdmitted", schoolId);
        query.exists("Name");
        query.doesNotExist("SchoolID");
        query.descending("updatedAt");
        query.limit(1000);

        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("Deleted", true);
        }
        query.find().then(
          (kids) => {
            resolve(kids);
          },
          (error) => {
            reject();
          }
        );
      }
    });
  } catch (error) { }
};
export const getAllCADKidsForSchoolIdParse = (schoolId, isLocal) => {
  printLog("HL: kid_helper : getAllCADKidsForSchoolIdParse")
  try {
    return new Promise((resolve, reject) => {
      let query;
      let query1 = new Parse.Query("Kid");
      query1.equalTo("SchoolID", schoolId);
      let query2 = new Parse.Query("Kid");
      query2.equalTo("schoolAdmitted", schoolId);
      query = Parse.Query.or(query1, query2);
      query.limit(1000);
      // if(isLocal){
      //   query.fromLocalDatastore();
      // }
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.error(err);
  }
};

export const sendAppInstallInvite = (kidId, schoolName, teacherId, parentId) => {
  printLog("HL: kid_helper : sendAppInstallInvite")
  try {
    return new Promise((resolve, reject) => {
      Parse.Cloud.run("sendInvitationSMSToIndividual", {
        teacherId: teacherId,
        kidId: kidId,
        schoolName: schoolName,
        parentId: parentId,
      }).then(
        (result) => {
          resolve(true);
        },
        (error) => {
          reject();
        }
      );
    });
  } catch (err) {
    console.error(err);
  }
};

export const getAllDeltedKidsForSchool = (schoolId, updatedDate, isLocal) => {
  printLog("HL: kid_helper : getAllDeltedKidsForSchool")
  try {
    return new Promise((resolve, reject) => {
      let query1 = new Parse.Query("Kid");
      let query2 = new Parse.Query("Kid");

      query1.equalTo("SchoolID", schoolId);
      query2.equalTo("schoolAdmitted", schoolId);

      query1.equalTo("Deleted", true);
      query2.equalTo("Deleted", true);

      let query = Parse.Query.or(query1, query2);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      }

      if (isLocal) {
        query.fromLocalDatastore();
      }

      query.limit(1000);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const createStudentUser = (kid, schoolId) => {
  printLog("HL: kid_helper : createStudentUser")
  try {
    return new Promise((resolve, reject) => {
      Parse.Cloud.run("function_createUserForKid", {
        kid: kid,
        schoolId: schoolId,
        refferedBy: "asci",
      }).then((result) => {
        resolve(result);
      }, (error) => {
        reject(error);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const getEscortsForKidIds = (kidIds) => {
  try {
    return new Promise((resolve, reject) => {
      var PickNDrop = Parse.Object.extend("PickNDrop");
      var mainQuery = new Parse.Query(PickNDrop);
      mainQuery.containedIn("KidID", kidIds);
      mainQuery.limit(5000);

      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const disableKid=(kidId, disabledBy, isDisabled)=>{
  printLog("HL: kid_helper : disableKid")
  try {
    return new Promise((resolve, reject) => {
      console.log("function_disableKid")
      Parse.Cloud.run("function_disableKid", {
        kidId: kidId,
        isDisabled: isDisabled,
        disabledBy: disabledBy,
      }).then((attobjs) => {
        console.log(attobjs)
        resolve(attobjs);
      });
    });
  } catch (error) {

  }
}

export {
  getKidFromParse,
  deleteKidFromParse,
  getAllKidForSchoolParse,
  getCADForAKid,
  getMedicalDetailsForAKid,
  getescortsForKid,
  getVaccineDetailsForKid,
  getAdmissionsKidsFroSchool,
  getAttendanceForKid,
  getAllKidsForGroups,
  getAllDeletedKidsForSchoolId,
  deleteKidsInListParse,
  getMedicalDetailsForKidIds,
};
