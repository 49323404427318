import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import ColorPicker from "./colorPicker";
import { ReactComponent as VerifiedLogo } from "../../assets/images/certificate/verifiedLogo.svg";
import { ReactComponent as Divider } from "../../assets/images/certificate/separator.svg";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardImg,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
} from "reactstrap";
import * as Constants from "../../Constents";
import Moment from "moment";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import img1 from "../../assets/images/companies/img-1.png";
import {
  setSelecetdSchool,
  getPaymentOptionsForSchool,
  getRatingParams,
  getSchoolRatings,
  editSchool,
  editPaymentOptions,
} from "../../store/actions";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import Parse from "parse";
import toastr from "toastr";
import logo from "../../assets/images/logo-dark.png";
import SaveAsPdf from "../../components/Common/SaveAsPdf";

const TransferCertificatePrint = (props) => {


  function printPoster() {
    var css = `
      @page {
        size: A4;
        margin: 0; 
      }`,
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    setTimeout(() => {
      window.print();
    }, 300);
  }
  useEffect(() => {
    const savedValue = localStorage.getItem('dontShowAgain');
    if (savedValue === 'true') {
        setDontShowAgain(true);
    }
    const userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.includes('mac')) {
        setMachineType('Mac');
    } else if (userAgent.includes('windows')) {
        setMachineType('Windows');
    } else {
        setMachineType('Unknown');
    }
  }, []);

  const handleNextClick = () => {
    setShowSaveAsPdfModal(false);
    setTimeout(() => {
        printPoster();
    }, 300);
  }


  //const parameter = match.params.id;
  const [schoolId, setSchoolId] = useState(props.schoolId)

  const [loading, setLoading] = useState(false);

  const [school, setSchool] = useState(undefined);

  // const [schoolName, setSchoolName] = useState(undefined);
  // const [schoolCity, setSchoolCity] = useState(undefined);
  // const [schoolLogo, setSchoolLogo] = useState(undefined);

  const [selectedRatings, setSelectedRatings] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(undefined);
  const [ratingCardList, setRatingCardList] = useState([]);
  const [selectedRatingsTemp, setSelectedRatingsTemp] = useState([]);
  const [overAllRating, setOverAllRating] = useState(0);
  const [certificateBorderColor, setCertificateBorderColor] = useState(
    "#bfbfbf"
  );
  const [starEmptyColor, setStarEmptyColor] = useState("#cbd3e3");
  const [certificateBackgroundColor, setCertificateBackgroundColor] = useState(
    "#fff"
  );
  const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [machineType, setMachineType] = useState('');
  const fullPageLabelUI = (labelTextLeft, labelTextRight, tcObject,key) => {
    let isDate = false
    if (tcObject.questionType === Constants.QESTION_DATE) {
      isDate = true
    }

    return (
      <div className="" style={{ height: "0.65cm" }} key={key} >
        <Row className="text-left">
          <Col lg="6" md="6" sm="6" className="d-flex justify-content-between">
            <Label style={{ fontSize: "10px", marginLeft: '40px' }} >{labelTextLeft}</Label>
            <Label style={{ fontSize: "10px", }} >:</Label>
          </Col>
          <Col lg="6" md="6" sm="6">
            {!isDate && <Label style={{ fontSize: "10px" }} className="d-flex justify-content-start">{labelTextRight}</Label>}
            {isDate && <Label style={{ fontSize: "10px" }} className="d-flex justify-content-start">{Moment(labelTextRight).format('DD-MM-YYYY')}</Label>}
          </Col>
        </Row>
        <hr style={{ marginTop: '-0.2cm' }} />
      </div>
    )
  }

  return (
    <React.Fragment>



      {loading ? (
        <Col xs="12">
          <div className="text-center my-3">
            <Link to="#" className="text-success">
              <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
              {props.t("Loading")}{" "}
            </Link>
          </div>
        </Col>
      ) : (
        <Row className="page-content">
          <Col xs="12" className="d-print-none mt-2 mb-2">
            <div className="navbar-header p-0">

              <div
                style={{ cursor: "pointer" }}
                onClick={() => { props.backButtonPressedInPrintPreview() }}>
                <ul className="verti-timeline list-unstyled">
                  <div className="d-flex justify-content-start">
                    <i className="bx bx-chevron-left h1 text-primary"></i><h4 className="m-1">Transfer Certificate</h4>
                  </div>
                </ul>
              </div>


              <h4 >{props.title}</h4>

              <div className="text-center">
                <Button
                  onClick={() => {
                    if (['Windows', 'Mac'].includes(machineType)) {
                      if (!dontShowAgain) {
                        setShowSaveAsPdfModal(true); 
                        } else {
                        printPoster();
                        } 
                    }else{
                      printPoster();
                    }
                }}
                  className="btn btn-success waves-effect waves-light mr-2"
                >
                  <i className="fa fa-print m-1"></i>Print/Download Certificate
                </Button>
                <SaveAsPdf
                  showModal={showSaveAsPdfModal}
                  toggleModal={() => setShowSaveAsPdfModal(false)}
                  dontShowAgain={dontShowAgain}
                  setDontShowAgain={setDontShowAgain}
                  onNextClick={handleNextClick}
                  machineType={machineType}
                />
              </div>
            </div>
          </Col>


          <Col xs="12">
            <Container fluid='md' className="py-2">
              <div
                style={{
                  width: "100%",
                  // minHeight: "1403px",
                  backgroundColor: certificateBackgroundColor,
                  border: `5px solid ${certificateBorderColor}`,
                  // pageBreakBefore: 'auto',
                  // pageBreakAfter: 'auto',
                }}
                // className="text-center"
              >


                <div>
                  {/* <Row className="mt-2 d-flex justify-content-between align-items-center">
                    <Col xs={6} className="d-flex justify-content-center">
                      <img
                        src={props.schoolLogo}
                        alt=""
                        style={{ width: '3rem', marginLeft: '5cm', }}
                      />
                      <h1
                        style={{
                          color: 'solid black', 
                          fontSize: "18px",
                          marginLeft: '15cm',
                          marginTop: '0.3cm'
                        }}
                      >
                        {props.schoolName}
                      </h1>
                    </Col>
                  </Row> */}
                  <Row className=" d-flex align-items-center text-center">
                    <Col xs={3} className="float-end">
                      <img
                        src={props.schoolLogo}
                        alt=""
                        style={{ width: '4rem' }}
                        className="p-1"
                      />
                    </Col>
                    <Col xs={8} className="text-black">
                      <h3>{props.schoolName}</h3>
                    </Col>
                  </Row>

                  <div
                    className="d-flex mt-0 mx-auto "
                    style={{
                      maxWidth: "100%",
                      borderBottom: `2px solid ${starEmptyColor}`,
                    }}
                  >
                  </div>
                  <Row className="mt-0">
                    <div className="d-flex justify-content-center">
                      <h5 className="text-center mt-1">Transfer Certificate</h5>
                    </div>
                  </Row>
                  {
                    props.tcObjectList.map((obj, key) => (
                      fullPageLabelUI(obj.question, obj.answer, obj,key)
                    ))
                  }
                  <Row className="text-left ml-3">
                    <Col><Label className="d-flex justify-content-start mt-1" style={{ fontSize: "13px", fontWeight: "bold", marginLeft: '40px' }}>HEADMASTER / PRINCIPAL / DIRECTOR
                      <Label className="d-flex justify-content-end text-danger ml-2" style={{ fontSize: "12px", }}>(please specify)</Label></Label>
                    </Col>
                  </Row>
                  <Row className="text-left ml-3 mr-3">
                    <Col lg="6" md="6" sm="6" >
                      <Label className="d-flex justify-content-start" style={{ fontSize: "12px", marginLeft: '50px' }}>Name ____________</Label><br />
                      <Label className="d-flex justify-content-start" style={{ fontSize: "12px", marginLeft: '50px' }}>Signature ____________</Label><br />
                      <Label className="d-flex justify-content-start" style={{ fontSize: "12px", marginLeft: '50px' }}>Date ____________</Label>
                    </Col>
                    <Col lg="6" md="6" sm="6" className="text-center mt-4">
                      <Label style={{ fontSize: "15px", marginTop: '1.5cm' }}>SCHOOL STAMP</Label>
                    </Col>
                  </Row>
                </div>
              </div>

            </Container>

          </Col>

        </Row>
      )}


    </React.Fragment>
  );
};












const mapStatetoProps = (state) => {
  const {
    selectedSchool,
    paymentOptionsForSchool,
    ratingParams,
    schoolRatings,
  } = state.School;
  return {
    selectedSchool,
    paymentOptionsForSchool,
    ratingParams,
    schoolRatings,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      editSchool,
      getPaymentOptionsForSchool,
      editPaymentOptions,
      getRatingParams,
      getSchoolRatings,
    })(TransferCertificatePrint)
  )
);
