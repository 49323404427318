import * as actions from "./actionTypes";

export const updateFeeTemplate = (feeTemplate) => {
  return {
    type: actions.UPDATE_FEE_TEMPLATE,
    payload: { feeTemplate },
  };
};

export const deleteFeeTemplate = (feeTemplate) => {
  return {
    type: actions.DELETE_FEE_TEMPLATE,
    payload: {feeTemplate},
  };
};

export const updateFeeTemplates = (feeTemplates, fromDelete) => {
  return {
    type: actions.UPDATE_FEE_TEMPLATES,
    payload: { feeTemplates, fromDelete },
  };
};

export const getFeeTemplateForSchoolId = (schoolId) => {
  return {
    type: actions.GET_FEE_TEMPLATES,
    payload: { schoolId },
  };
};

export const createFeeTemplateForSchoolId = pram =>{
  return{
    type : actions.CREATE_FEETEMPLATE_FOR_SCHOOLID,
    payload : { pram }
  }
}
export const setOffLoader = response =>{
  return{
    type : actions.SET_LOADER_OFF,
    payload : { response }
  }
}

export const deleteATemplateForSchool = id =>{
  return{
    type : actions.DELETE_A_TEMPLATE_FOR_SCHOOL,
    payload : { id }
  }
}
export const updateAfterDeleteFeeTemplates = Obj =>{
  return{
    type : actions.UPDATE_AFTER_DELETE,
    payload : { Obj }
  }
}
export const getFeeType = ()=>{
  return{
    type: actions.GET_FEETYPE,
    payload : { }
  }
}
export const setFeeType = feeTypeObjs =>{
  return{
    type: actions.SET_FEETYPE,
    payload: { feeTypeObjs }
  }
}

export const editFeeTemplate = (feeTemplate) => {
  return {
    type: actions.EDIT_FEE_TEMPLATE,
    payload: { feeTemplate },
  };
};
