import * as actions from "./actionTypes";

export const updateChildAdditionalDetail = (childAdditionalDetail) => {
  return {
    type: actions.UPDATE_CHILD_ADDITIONAL_DETAIL,
    payload: { childAdditionalDetail },
  };
};

export const deleteChildAdditionalDetail = (childAdditionalDetail) => {
  return {
    type: actions.DELETE_CHILD_ADDITIONAL_DETAIL,
    payload: childAdditionalDetail,
  };
};

export const updateChildAdditionalDetails = (childadditionaldetails) => {
  return {
    type: actions.UPDATE_CHILD_ADDITIONAL_DETAILS,
    payload: { childadditionaldetails },
  };
};

export const clearChildAdditionalDetails = () => {
  return {
    type: actions.CLEAR_CHILD_ADDITIONAL_DETAILS,
    payload: {},
  };
};

export const getChildAdditionalDetailForKidId = (kidId) => {
  return {
    type: actions.GET_CHILD_ADDITIONAL_DETAILS,
    payload: { kidId },
  };
};
export const getChildAdditionalDetailForEnquiryId = (enquiryId) => {
  return {
    type: actions.GET_CHILD_ADDITIONAL_DETAIL,
    payload: { enquiryId },
  };
};

export const editChildAdditionalDetail = (childAdditionalDetail) => {
  return {
    type: actions.EDIT_CHILD_ADDITIONAL_DETAIL,
    payload: { childAdditionalDetail },
  };
};
export const setChildAdditionalObject = (childAdditionalDetail) => {
  return {
    type: actions.SET_CHILD_ADDITIONAL_DETAIL_OBJECT,
    payload: { childAdditionalDetail },
  };
};

export const getChildAdditionalDetailsForEnquiryAndKidIds = (
  enqIds,
  kidIds
) => {
  return {
    type: actions.GET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS,
    payload: { enqIds, kidIds },
  };
};

export const setChildAdditionalDetailsForEnquiryAndKidIds = (objs) => {
  return {
    type: actions.SET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS,
    payload: { objs },
  };
};
