import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";


const initialState = {
  inventorySaleOrders: [], inventorySaleOrderAddLoading: false,
  selectedInventorySaleOrder: undefined,
  inventoryCart: [],

};

const InventorySaleOrders = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_INVENTORY_SALE_ORDERS:

      var inventorySaleOrders = [...state.inventorySaleOrders];
      if (inventorySaleOrders.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.inventorySaleOrders], action.payload.inventorySaleOrders, "isDeleted")

        state = { ...state, inventorySaleOrders: updatedList };
      } else {
        var i = 0
        var inventorySaleOrdersToSave = []
        for (i = 0; i < action.payload.inventorySaleOrders.length; i++) {
          if (action.payload.inventorySaleOrders[i].attributes.isDeleted !== true) {
            inventorySaleOrdersToSave.push(action.payload.inventorySaleOrders[i])
          }
        }
        state = { ...state, inventorySaleOrders: inventorySaleOrdersToSave };
      }
      break;
    case actions.UPDATE_SELECTED_SALE_ORDER:
      state = { ...state, selectedInventorySaleOrder: action.payload.inventorySaleOrder };
      break;
    case actions.UPDATE_DELETE_SALE_ORDER:
      var inventorySaleOrders = state.inventorySaleOrders.filter(
        (inventorySaleOrder) => inventorySaleOrder.id !== action.payload.inventorySaleOrders.id
      );
      state = { ...state, inventorySaleOrders: inventorySaleOrders };
      break;

    case actions.UPDATE_CART_FOR_OWNER_ID:

      var inventoryCart = [...state.inventoryCart];
      if (inventoryCart.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.inventoryCart], action.payload.cartObjects, "isDeleted")

        state = { ...state, inventoryCart: updatedList };
      } else {
        var i = 0
        var inventoryCartToSave = []
        for (i = 0; i < action.payload.cartObjects.length; i++) {
          if (action.payload.cartObjects[i].attributes.isDeleted !== true) {
            inventoryCartToSave.push(action.payload.cartObjects[i])
          }
        }
        state = { ...state, inventoryCart: inventoryCartToSave };
      }
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default InventorySaleOrders;
