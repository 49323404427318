import React, { useState, useEffect } from "react";
import {
  getGroupForId,
  getProgramForGroupId,
  getFeeTemplateForSchoolId,
  getProgramTermsForProgramId,
  getSchool,
  setSelecetdSchool,
  saveSelectedProgram,
  updateResultGroup,
  editProgramTerms,
  setForwardToProgramView
} from "../../store/actions";
import Switch from "react-switch";
import OnOffSymbol from "../../components/Common/SwitchOnOff";

import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Container,
  Input,
  Badge,
  Button,
} from "reactstrap";

import toastr from "toastr";
import DatePicker from "react-datepicker";
import { AvForm, AvField } from "availity-reactstrap-validation";
//Import Images
import avatar2 from "../../assets/images/users/user.png";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';
import Editable from "react-bootstrap-editable";

const AddPrograms = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/addProgram/:schoolId",
    exact: true,
    strict: false,
  });
  const matchEditGroup = matchPath(props.history.location.pathname, {
    path: "/editprogram/:schoolId/:programId/:groupId",
    exact: true,
    strict: false,
  });
  const matchEditGroupForBackwardCompatibility = matchPath(props.history.location.pathname, {
    path: "/editprogram/:schoolId/:programId/:groupId/:groupName",
    exact: true,
    strict: false,
  });

  var schoolIdParam = null;
  var groupId = null;


  if (match) {
    schoolIdParam = match && match.params.schoolId;
    props.getSchool(schoolIdParam);
  } else if (matchEditGroup) {
    groupId = matchEditGroup && matchEditGroup.params.groupId;
    schoolIdParam = matchEditGroup && matchEditGroup.params.schoolId;
  } else if (matchEditGroupForBackwardCompatibility) {
    groupId = matchEditGroupForBackwardCompatibility && matchEditGroupForBackwardCompatibility.params.groupId;
    schoolIdParam = matchEditGroupForBackwardCompatibility && matchEditGroupForBackwardCompatibility.params.schoolId;
  }

  const programId = matchEditGroup ? matchEditGroup.params.programId : (matchEditGroupForBackwardCompatibility ? matchEditGroupForBackwardCompatibility.params.programId : undefined)


  useEffect(() => {
    if (
      schoolIdParam &&
      (!props.selectedSchool || props.selectedSchool.id !== schoolIdParam)
    ) {
      props.setSelecetdSchool(schoolIdParam);
    }
  }, [props.selectedSchool, schoolIdParam]);

  //SchoolId is schoolIdParam
  const programTypeList = [
    "School",
    "Daycare",
    "Camp",
    "Extra Curricular",
    "Course",
  ];
  const [groupName, setGroupName] = useState('');
  const [newGroupName, setNewGroupName] = useState("");
  const [batch, setBatch] = useState("");
  const [programType, setProgramType] = useState(undefined);
  const [templateId, setTemplateId] = useState(undefined);
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [oneTimeForTemplate, setOneTimeForTemplate] = useState(true);
  const [resultProgramAfterSave, setResultProgramAfterSave] = useState(
    undefined
  );
  const [termsList, setTermsList] = useState([
  ]);
  const [isProgramWithTerms, setIsProgramWithTerms] = useState(false)
  const [isLoading, setIsLoading] = useState(true);
  const [intialLoading, setIntialLoading] = useState(true);
  const [latestTermOrder, setLatestTermOrder] = useState(1);
  const [maxOrderTerm, setMaxOrderTerm] = useState(0);

  const [showClassRoom, setShowClassRoom] = useState(false);
  const [feeTempletsList, setFeeTempletsList] = useState([])

  const [tags, setTags] = useState([]);
  const [newTag, setNewTag] = useState('');
  const [addNewTags, setAddNewTags] = useState(false);
  const [tagError, setTagError] = useState(false);

  function clearProps() {
    props.updateResultGroup(null);
  }

  useEffect(() => {
    clearProps();
    var schoolId = schoolIdParam;
    props.getFeeTemplateForSchoolId(schoolId);
    props.getProgramTermsForProgramId(programId);

    if ((matchEditGroup || matchEditGroupForBackwardCompatibility) && schoolIdParam && groupId) {
      props.getGroupForId(groupId);
      props.getProgramForGroupId(groupId);
    }
    if (match) {
      setIsLoading(false);
    }
  }, [schoolIdParam, programId]);

  useEffect(() => {
    if (props.programTerms) {
      let tempList = []
      for (const i in props.programTerms) {
        tempList.push({
          label: props.programTerms[i].attributes.termName,
          templateId: props.programTerms[i].attributes.feeTemplateId,
          pfTermName: props.programTerms[i].attributes.termName,
          pfFeeTemplateId: props.programTerms[i].attributes.feeTemplateId,
          order: props.programTerms[i].attributes.order,
          parseObject: props.programTerms[i]
        })
      }

      let sortedList = tempList.sort(function (a, b) {
        var order1 = a.order,
          order2 = b.order;
        if (order1 < order2)
          //sort string ascending
          return -1;
        if (order1 > order2) return 1;
        return 0; //default return value (no sorting)
      });
      if (sortedList.length > 0) {
        let lstTemp = sortedList[sortedList.length - 1]
        setMaxOrderTerm(lstTemp.order)
      } else {
        sortedList.push({
          label: "term " + (sortedList.length + 1),
          templateId: undefined,
          newOrder: latestTermOrder,
        })
        setLatestTermOrder(latestTermOrder + 1)
      }
      setTermsList(sortedList)
    }
  }, [props.programTerms])

  useEffect(() => {
    if (props.selectedProgram && (matchEditGroup || matchEditGroupForBackwardCompatibility) && props.selectedProgram.id === programId) {
      setIntialLoading(false);
      setGroupName(props.selectedProgram.attributes.name ?? "")
      setNewGroupName(props.selectedProgram.attributes.name ?? "");
      if (props.selectedProgram.attributes.batch) {
        setBatch(props.selectedProgram.attributes.batch);
      }
      if (props.selectedProgram.attributes.tags) {
        setTags(props.selectedProgram.attributes.tags);
      }
      if (props.selectedProgram.attributes.startDate) {
        setStartDate(props.selectedProgram.attributes.startDate);
      }
      if (props.selectedProgram.attributes.endDate) {
        setEndDate(props.selectedProgram.attributes.endDate);
      }
      if (props.selectedProgram.attributes.type) {
        setProgramType(props.selectedProgram.attributes.type);
      }
      if (props.selectedProgram.attributes.type) {
        setProgramType(props.selectedProgram.attributes.type);
      }
      if (props.selectedProgram.attributes.hasTerm) {
        setIsProgramWithTerms(props.selectedProgram.attributes.hasTerm);
      }
      if (props.selectedProgram.attributes.showClassName) {
        setShowClassRoom(props.selectedProgram.attributes.showClassName);
      }
      if (isLoading === true) {
        setIsLoading(false);
      }
    }
  }, [props.selectedProgram]);

  useEffect(() => {

    let list = []
    for (const ft of props.feeTemplates) {
      if (match) {
        if (!ft.attributes.isDisabled) {
          list.push(ft)
        }
      } else {
        list.push(ft)
      }
    }
    setFeeTempletsList(list)

  }, [props.feeTemplates]);

  useEffect(() => {
    if (props.selectedProgram && (matchEditGroup || matchEditGroupForBackwardCompatibility) && props.selectedProgram.id === programId && oneTimeForTemplate) {
      setIntialLoading(false);
      if (
        props.selectedProgram.attributes.templateId &&
        feeTempletsList && feeTempletsList.length > 1
      ) {
        var tempList = feeTempletsList.filter(
          (feeTemplate) =>
            feeTemplate.id ===
            props.selectedProgram.attributes.templateId
        );
        if (tempList.length > 0) {
          setOneTimeForTemplate(false);
          setTemplateId(tempList[0].id);
        }
      }
    }
  }, [props.selectedProgram, feeTempletsList]);

  const onBack = () => {
    window.history.back()
  }

  const saveProgram = (resultGroup) => {
    const ProgramObject = Parse.Object.extend("Program");
    var programObject = new ProgramObject();
    programObject.set("endDate", endDate);
    programObject.set("startDate", startDate);
    programObject.set("name", newGroupName);
    programObject.set("batch", batch);
    programObject.set("tags", tags)
    programObject.set("schoolId", schoolIdParam);
    programObject.set("name", newGroupName);
    programObject.set("type", programType);
    programObject.set("groupId", resultGroup.id);
    programObject.set("showClassName", showClassRoom);
    if (isProgramWithTerms) {
      programObject.set("hasTerm", true)
    } else {
      programObject.set("templateId", templateId);
    }
    programObject.save().then(
      (result) => {
        programObject.pin();
        setResultProgramAfterSave(result);
        saveProgramTerms(result.id)
        setSaveSuccess(true, result);
      },
      (error) => { }
    );
  }

  const saveProgramTerms = (programId) => {
    let objectsToSave = []
    const ProgramTerms = Parse.Object.extend('ProgramTerms');
    for (const i in termsList) {
      let programTerm = termsList[i].parseObject ? termsList[i].parseObject : new ProgramTerms();

      programTerm.set("termName", termsList[i].label)
      programTerm.set("feeTemplateId", termsList[i].templateId)
      programTerm.set("programId", programId)
      programTerm.set("order", parseInt((termsList[i].parseObject ? termsList[i].parseObject.attributes.order : (maxOrderTerm + termsList[i].newOrder)), 10))
      if (termsList[i].parseObject) {
        if (termsList[i].pfTermName !== termsList[i].label || termsList[i].pfFeeTemplateId !== termsList[i].templateId) {
          objectsToSave.push(programTerm)
        }
      } else {
        programTerm.set("kidIds", [])
        objectsToSave.push(programTerm)
      }
    }

    for (const i in props.programTerms) {
      let filteredList = termsList.filter((term) => ((term.parseObject && term.parseObject.id) === props.programTerms[i].id))
      if (filteredList.length === 0) {
        props.programTerms[i].set("isDeleted", true)
        objectsToSave.push(props.programTerms[i])
      }
    }
    if (objectsToSave.length > 0) {
      props.editProgramTerms(objectsToSave)
    }
  }

  const setSaveSuccess = (success, programObject) => {
    if (success) {
      toastr.success("Program added successfully !!", { timeOut: 2000 });
      props.setForwardToProgramView(programObject)
      clearProps();
      window.history.back();
    } else {
      clearProps();
      window.history.back();
    }
  };

  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
  const handleSave = () => {
    if (newGroupName === "") {
      toastr.warning("Please fill program name !!", { timeOut: 2000 });
      setIsLoading(false);
      return;
    }

    // if (batch.length === 0) {
    //   toastr.warning("Please fill batch !!", { timeOut: 2000 });

    //   return;
    // }
    if (startDate == null) {
      toastr.warning("Please fill start date !!", { timeOut: 2000 });

      return;
    }
    if (endDate == null) {
      toastr.warning("Please fill end date !!", { timeOut: 2000 });

      return;
    }
    if (!programType) {
      toastr.warning("Please select program type !!", { timeOut: 2000 });
      return;
    }
    if (!templateId && !isProgramWithTerms) {
      toastr.warning("Please select template !!", { timeOut: 2000 });
      return;
    }
    if (isProgramWithTerms && (termsList == undefined || termsList.length === 0)) {
      toastr.warning("Please add atleast 1 term", { timeOut: 2000 });
      return;
    } else if (isProgramWithTerms) {
      for (const i in termsList) {
        if (termsList[i].templateId == undefined || termsList[i].templateId.length === 0) {
          toastr.warning("Please select template for each added terms !!", { timeOut: 2000 });
          return;
        }
      }
    }
    if (matchEditGroup || matchEditGroupForBackwardCompatibility) {
      handleEditSaveButtonPressed();
    } else {
      handleSaveButtonPressed();
    }
  };

  const handleSaveButtonPressed = () => {
    const GroupObject = Parse.Object.extend("Group");
    var groupObject = new GroupObject();

    groupObject.set("schoolId", schoolIdParam);
    groupObject.set("ownerId", Parse.User.current().id);
    groupObject.set("name", newGroupName);
    groupObject.set("participantsCount", 0);
    groupObject.set("type", 2);
    setIsLoading(true);
    groupObject.save().then(
      (result) => {
        if (matchEditGroup || matchEditGroupForBackwardCompatibility) {
          onBack()
        } else {
          saveProgram(result)
        }
      },
      (error) => {
        if (matchEditGroup || matchEditGroupForBackwardCompatibility) {
          onBack()
        }
      }
    );
  };

  const handleEditSaveButtonPressed = () => {
    if (props.selectedProgram != null && props.selectedProgram.id === programId) {
      let tempSelectedProgram = props.selectedProgram;
      var update = false;

      // if (tempSelectedProgram.attributes.term !== batch) {
      //   tempSelectedProgram.set("batch", batch);
      //   update = true;
      // }
      if (tempSelectedProgram.attributes.tags !== tags) {
        tempSelectedProgram.set("tags", tags);
        update = true;
      }
      if (tempSelectedProgram.attributes.startDate !== startDate) {
        tempSelectedProgram.set("startDate", startDate);
        update = true;
      }
      if (tempSelectedProgram.attributes.endDate !== endDate) {
        tempSelectedProgram.set("endDate", endDate);
        update = true;
      }
      if (tempSelectedProgram.attributes.name !== newGroupName) {
        tempSelectedProgram.set("name", newGroupName);
        update = true;
      }
      if (tempSelectedProgram.attributes.type !== programType) {
        tempSelectedProgram.set("type", programType);
        update = true;
      }
      if (tempSelectedProgram.attributes.templateId !== templateId && !isProgramWithTerms) {
        tempSelectedProgram.set("templateId", templateId);
        update = true;
      }
      if (tempSelectedProgram.attributes.showClassName !== showClassRoom) {
        tempSelectedProgram.set("showClassName", showClassRoom);
        update = true;
      }

      if (update) {
        props.saveSelectedProgram(tempSelectedProgram);
      }
    }
    saveProgramTerms(programId)
    if (props.singleGroupHolder) {
      var groupObject = props.singleGroupHolder;
      if (groupObject.attributes.name !== newGroupName) {
        groupObject.set("name", newGroupName);
        setIsLoading(true);
        groupObject.save().then(
          (result) => {
            if (matchEditGroup || matchEditGroupForBackwardCompatibility) {
              onBack()
            } else {
              saveProgram(result)
            }
          },
          (error) => {
            if (matchEditGroup || matchEditGroupForBackwardCompatibility) {
              onBack()
            }
          }
        );
      } else {
        onBack()
      }
    } else {
      toastr.warning("Please try again")
    }
  };

  const getTemplateValue = (templateId) => {
    let tempList = feeTempletsList && feeTempletsList.filter((template) => template.id === templateId)
    if (tempList.length > 0) {
      return templateId
    }
    return undefined
  }

  return (
    <React.Fragment>
      <Container fluid={true} className={"page-content"}>
        {(isLoading || props.loadingGroupMap) && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Program...{" "}
                </Label>
              </div>
            </Col>
          </Row>
        )}

        {!(isLoading || props.loadingGroupMap) && (
          <Row>
            <Col lg={12} className="mx-auto">
              <Card>
                <CardBody>
                  <Row className='d-flex align-items-start'>
                    <Col className="d-flex align-items-start">
                      <div
                        style={{ cursor: "pointer", }}
                        onClick={() => { window.history.back() }}>
                        <ul className=" list-unstyled">
                          <div className='mb-2'>
                            <i className="bx bx-chevron-left h1 text-primary"></i>
                          </div>
                        </ul>
                      </div>
                      <div className='mr-2 mt-2'>
                        <h5 className="">
                          {(matchEditGroup || matchEditGroupForBackwardCompatibility) ? "Edit Program" : "Add Program"}
                        </h5>
                      </div>
                    </Col>
                    <Col className='d-flex justify-content-end'>
                      <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                        <button
                          className="btn btm-sm btn-primary waves-effect waves-light mt-0 mr-2"
                          onClick={() => {
                            handleSave();
                          }}
                          style={{ float: "right" }}
                          role="button"
                        >
                          Save
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <hr />
                  <AvForm>
                    <Row lg={12}>
                      <Col lg={6}>
                        <div className="d-flex justify-content-between align-items-center">
                          <Label>Program Name</Label>
                          <div className="custom-control custom-switch" dir="ltr">
                            <input
                              type="checkbox"
                              className="custom-control-input m-1"
                              id="showClassInReciept"
                              checked={showClassRoom}
                              onChange={() => {
                                setShowClassRoom(!showClassRoom);
                              }}
                            />
                            <label
                              className="custom-control-label mb-2"
                              htmlFor="showClassInReciept"
                              onClick={() => {
                                setShowClassRoom(!showClassRoom);
                              }}
                            >
                              Show classroom in Receipt
                            </label>
                          </div>
                        </div>
                        <AvField
                          type="text"
                          name="singleLineAG"
                          id="singleLineAG1"
                          placeholder="Program Name"
                          className="form-control"
                          value={newGroupName}
                          onChange={(e) => {
                            setNewGroupName(e.target.value);
                          }}
                        />
                      </Col>

                      <Col lg={6}>
                        {/* <Label>Batch</Label>
                        <AvField
                          type="text"
                          name="singleLineAG"
                          id="singleLineAG3"
                          placeholder="Batch"
                          className="form-control"
                          value={batch}
                          onChange={(e) => {
                            setBatch(e.target.value);
                          }}
                        /> */}
                        <FormGroup>
                          <Label htmlFor="formname">Batch Tags: </Label>
                          <div>
                            {tags.map((option, key) => (
                              <Badge
                                key={key}
                                className="badge badge-soft-primary font-size-12 m-1"
                              >
                                {option.length > 20 ? option.slice(0, 19) + '...' : option}
                                <i
                                  className="mdi mdi-window-close ml-1"
                                  onClick={() => {
                                    const others = tags.filter((tag) => tag !== option);
                                    setTags(others);
                                  }}
                                ></i>
                              </Badge>
                            ))}
                            {!addNewTags && (
                              <Badge
                                className="badge badge-soft-success font-size-12 m-2"
                                onClick={() => {
                                  setNewTag('');
                                  setAddNewTags(true);
                                }}
                              >
                                Add <i className="bx bx-plus ml-1"></i>
                              </Badge>
                            )}
                            {addNewTags && (
                              <Badge
                                className="badge badge-soft-danger font-size-12 m-2"
                                onClick={() => {
                                  setNewTag('');
                                  setAddNewTags(false);
                                }}
                              >
                                Close <i className="mdi mdi-window-close ml-1"></i>
                              </Badge>
                            )}
                            {tagError && <p className="text-danger">Tags cannot be empty</p>}
                          </div>
                          {addNewTags && (
                            <div className="m-1">
                              <Editable
                                className="me-5 ml-5"
                                alwaysEditing={true}
                                onValidated={(value) => {
                                  if (value.trim() !== '') {
                                    const res = value.split(',');
                                    const newList = [...tags, ...res];
                                    if (newList.length > 0) {
                                      setTagError(false);
                                    }
                                    setTags(newList);
                                  }
                                  setNewTag('');
                                  setAddNewTags(false);
                                }}
                                onCancel={() => {
                                  setAddNewTags(false);
                                }}
                                disabled={false}
                                editText={newTag ? ' ✎' : 'No value ✎'}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={newTag}
                                showText
                                type="textfield"
                                placeholder="Type here"
                                validate={(value) => {
                                  if (value === '') {
                                    return;
                                  } else if (value.length === 0) {
                                    return 'Tag cannot be empty';
                                  } else if (tags.includes(value)) {
                                    return 'This tag is already added';
                                  }
                                }}
                              />
                            </div>
                          )}
                        </FormGroup>
                      </Col>
                    </Row>

                    <Row lg={12}>
                      <Col lg={6}>

                        <Label className="mt-1 ml-1">Start Date</Label>

                        <DatePicker
                          selected={startDate}
                          onChange={(date) => setStartDate(new Date(date))}
                          className="form-control"
                          placeholderText={props.t("Select Start Date")}
                          startDate={startDate}
                          endDate={endDate}
                          //maxDate={endDate}
                          dateFormat="dd/MM/yyyy"
                          showDayMonthYearPicker
                        />

                      </Col>

                      <Col lg={6}>

                        <Label className="mt-1 ml-1">End Date</Label>

                        <DatePicker
                          selected={endDate}
                          onChange={(date) => setEndDate(new Date(date))}
                          className="form-control"
                          placeholderText={props.t("Select End Date")}
                          startDate={startDate}
                          endDate={endDate}
                          minDate={startDate}
                          dateFormat="dd/MM/yyyy"
                          showDayMonthYearPicker
                        />
                      </Col>
                    </Row>
                    <Row lg={12} className="">
                      <Col lg={6}>
                        <FormGroup>
                          <Label className="ml-1 mt-3">Program Type</Label>
                          <select
                            className="form-control"
                            value={programType}
                            onChange={(e) => {
                              setProgramType(e.target.value);
                            }}
                          >
                            <option disabled selected value>Select Program Type</option>
                            {programTypeList.map((object, key) => (
                              <option key={key} value={object}>{object}</option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>
                      {!isProgramWithTerms && <Col lg={6}>
                        <FormGroup>
                          <Label className="ml-1 mt-3">Fee Template</Label>
                          <select
                            className="form-control"
                            value={templateId}
                            onChange={(e) => {
                              setTemplateId(e.target.value);
                            }}
                          >
                            <option disabled selected value>Select Template </option>
                            {feeTempletsList && feeTempletsList.map((object, key) => (
                              <option key={key} value={object.id} disabled={object.attributes.isDisabled ? true : false}>{object.attributes.name}</option>
                            ))}
                          </select>
                        </FormGroup>
                      </Col>}
                    </Row>
                    {!(matchEditGroup || matchEditGroupForBackwardCompatibility) && <Row lg={12}>
                      <Col lg={6}>
                        <Label className="mt-3 ml-1">Program with terms</Label>
                        <div className="ml-1">
                          <Switch
                            uncheckedIcon={<OnOffSymbol label="No" />}
                            checkedIcon={<OnOffSymbol label="Yes" />}
                            onColor="#626ed4"
                            onChange={() => {
                              //handleSettingChange(item, key)
                              setIsProgramWithTerms(!isProgramWithTerms)
                            }}
                            checked={isProgramWithTerms}
                          />
                        </div>
                      </Col>
                    </Row>}
                    {isProgramWithTerms && <div>
                      <div className="flex-fill mr-2 ml-0 mt-2 mb-0">
                        <Label className="pb-0" style={{ verticalAlign: "middle" }}>
                          Add Term
                        </Label>

                        <Label
                          className="ml-2 text-primary"
                          onClick={() => {
                            let tempList = [...termsList]
                            tempList.push({
                              label: "term " + (termsList.length + 1),
                              templateId: undefined,
                              newOrder: latestTermOrder,
                            })
                            setLatestTermOrder(latestTermOrder + 1)
                            setTermsList(tempList)
                          }}
                          id="add-term-label"
                          style={{ fontSize: "20px", cursor: "pointer" }}
                        >
                          <i className=" bx bx-plus-circle"></i>
                        </Label>
                      </div>
                      {termsList.map((term, key) => (
                        <Row key={key}>
                          <Col className="col-3 mt-1">
                            <FormGroup>
                              <AvField
                                type="text"
                                name="singleLineTermLabel"
                                id="singleLineTermLabel"
                                placeholder="Type here"
                                className="form-control"
                                value={term.label}
                                onChange={(e) => {
                                  let tempList = [...termsList]
                                  let index = tempList.indexOf(term)
                                  term.label = e.target.value
                                  tempList[index] = term
                                  setTermsList(tempList);
                                }}
                              />
                            </FormGroup>
                          </Col>
                          <Col className="col mt-1">
                            <div className="d-flex">
                              <div><FormGroup>
                                <select
                                  className="form-control"
                                  value={getTemplateValue(term.templateId)}
                                  onChange={(e) => {
                                    let tempList = [...termsList]
                                    let index = tempList.indexOf(term)
                                    term.templateId = e.target.value
                                    tempList[index] = term
                                    setTermsList(tempList);
                                  }}
                                >
                                  <option disabled selected value>Select Template </option>
                                  {feeTempletsList && feeTempletsList.map((object, key) => (
                                    <option key={key} value={object.id} disabled={object.attributes.isDisabled ? true : false} >{object.attributes.name}</option>
                                  ))}
                                </select>
                              </FormGroup>
                              </div>
                              {!(term.parseObject && term.parseObject.attributes.kidIds && term.parseObject.attributes.kidIds.length > 0) &&
                                <div
                                  className="float-right mt-1"
                                  style={{ verticalAlign: "middle" }}
                                >
                                  <Label
                                    className="ml-2 float-right text-danger"
                                    onClick={() => {
                                      let tempList = [...termsList];
                                      removeElement(tempList, term)
                                      setTermsList(tempList);
                                    }}
                                    id="add-time-camera"
                                    style={{ fontSize: "20px", cursor: "pointer" }}
                                  >
                                    <i className="bx bx-minus-circle"></i>
                                  </Label>
                                </div>}
                            </div>
                          </Col>
                        </Row>
                      ))}


                      <Row>
                        <Col className="col-6">

                          {
                            <div
                              className="float-right mr-3"
                              style={{ verticalAlign: "middle" }}
                            >
                              <button
                                className="btn btn-primary"
                                onClick={() => {
                                  let tempList = [...termsList]
                                  tempList.push({
                                    label: "term " + (termsList.length + 1),
                                    templateId: undefined,
                                    newOrder: latestTermOrder,
                                  })
                                  setLatestTermOrder(latestTermOrder + 1)
                                  setTermsList(tempList)
                                }}
                                id="add-term-btn"
                              >
                                Add Term
                              </button>

                            </div>}
                        </Col>
                        <Col className="col mt-1 text-right">


                        </Col>
                      </Row>



                      <br />
                    </div>}
                  </AvForm>
                  <hr></hr>
                  <Row>
                    <Col lg={10}>

                    </Col>
                    <Col lg={2}>
                      <button
                        className="btn btm-sm btn-primary waves-effect waves-light mt-0 mr-2"
                        onClick={() => {
                          handleSave();
                        }}
                        style={{ float: "right" }}
                        role="button"
                      >
                        Save
                      </button>
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment >
  );
}

const mapStatetoProps = (state) => {
  const { singleGroupHolder } = state.Group;
  const { selectedProgram, programTerms } = state.Program;
  const { feeTemplates } = state.FeeTemplate;
  const { selectedSchool } = state.School;

  return {
    singleGroupHolder,
    selectedProgram, programTerms,
    feeTemplates,
    selectedSchool
  };
};

export default connect(mapStatetoProps, {
  getGroupForId,
  getProgramForGroupId,
  getFeeTemplateForSchoolId,
  getProgramTermsForProgramId,
  getSchool,
  setSelecetdSchool,
  saveSelectedProgram,
  updateResultGroup,
  editProgramTerms,
  setForwardToProgramView
})(withRouter(withNamespaces()(AddPrograms)));
