import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as TicketHelper from "../../../helpers/ParseHelpers/helpDeskTicket_helper";
import * as actionType from "./actionTypes";
import * as TicketAction from "./actions";
import * as Constants from "../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

  

  function* addATicket({ payload: { ticket,ticketLog } }) {
    try {
      yield put(TicketAction.updateTicket(ticket));
      const response = yield call(
        TicketHelper.saveCustomObjects,ticket
      );
      if (response === "Error") {
  
        toastr.error("Please try again", { timeOut: 3000 });
        const local = yield call(
          TicketHelper.getTicketFromLocal,ticket.id
        );
  
        if (local) {
          yield put(TicketAction.updateTicket(local));
        }
    
      }else 
      if (response) {
        yield put(TicketAction.updateTicket(response));
        toastr.success("Ticket successfully added", { timeOut: 3000 });
        if(ticketLog){
         ticketLog.ticketId = response.id;
          let logParseObject = getTicketLog(ticketLog)
          const logResponse = yield call(
            TicketHelper.saveCustomObjects,logParseObject
          );
        }
        response.pin();
        window.history.back();
      } else {

      }
    } catch (error) {
      console.log(error);
    }
  }

  function* deleteTicket({ payload: { ticket, } }) {
    try {
      yield put(TicketAction.updateDeleteTicket(ticket));
      const response = yield call(
        TicketHelper.deleteTicketFromParse,ticket
      );
      if (response === "error") {
        ticket.set("isDeleted", undefined);
        yield put(TicketAction.updateDeleteTicket(ticket));
      } else if (response) {
        
        if (logObject) {
          let logParseObject = getTicketLog(logObject)
          const logResponse = yield call(
            TicketHelper.saveCustomObjects, logParseObject
          );
        }

        toastr.success("Ticket successfully removed", { timeOut: 3000 });
        response.unPin();
      }else{
  
      }
  
    } catch (error) {
     
    }
  }

 

  function* getTicketsForRecipientId({ payload: { recipientId, recipientType } }) {
    try {
      var key = Constants.TICKET_UPDATED_TIME_FOR_RECIPIENT_ID + "_" + recipientId;
      var updatedTime = localStorage.getItem(key)
      var newTime = new Date();
      const localResponse = yield call(TicketHelper.getTicketsForRecipientId, recipientId, recipientType, null, true);
      yield put(TicketAction.updateTickets(localResponse));
  
      const serverResponse = yield call(TicketHelper.getTicketsForRecipientId, recipientId, recipientType, updatedTime, false);
      localStorage.setItem(key, newTime)
  
      if (serverResponse && serverResponse.length > 0) {
        yield put(TicketAction.updateTickets(serverResponse))
        Parse.Object.pinAll(serverResponse);
      }
    } catch (error) {
      // yield put(apiError(error));
  
    }
  }

  
  function* getTicketsForAssignedFor({ payload: { assignedFor, assignedForType } }) {
    try {
      var key = Constants.TICKET_UPDATED_TIME_FOR_ASSIGNED_FOR + "_" + assignedFor;
      var updatedTime = localStorage.getItem(key)
      var newTime = new Date();
      const localResponse = yield call(TicketHelper.getTicketsForAssignedFor, assignedFor, assignedForType, null, true);
      yield put(TicketAction.updateTickets(localResponse));
  
      const serverResponse = yield call(TicketHelper.getTicketsForAssignedFor, assignedFor, assignedForType, updatedTime, false);
      localStorage.setItem(key, newTime)
  
      if (serverResponse && serverResponse.length > 0) {
        yield put(TicketAction.updateTickets(serverResponse))
        Parse.Object.pinAll(serverResponse);
        console.log(serverResponse)
      }
    } catch (error) {
      console.log(error)
      // yield put(apiError(error));
  
    }
  }
  
  function* editATicket({ payload: { ticket ,ticketLog,shouldLogUpdate} }) {
    try {
      const response = yield call(
        TicketHelper.editTicket,
        ticket
      );
      if (response === "Error") {
        toastr.error("Please try again", { timeOut: 3000 });
      }else 
      if (response) {
        yield put(TicketAction.updateTickets([response]));
        response.pin();
        toastr.success("Ticket successfully updated", { timeOut: 3000 });

        if(ticketLog){
          let logParseObject = getTicketLog(ticketLog)
          const logResponse = yield call(
            TicketHelper.saveCustomObjects,logParseObject
          );
          if(shouldLogUpdate){
            yield put(TicketAction.updateSelectedTicketLog([logResponse],true));
            logResponse.pin();
          }
        }
      } else {
      }
    } catch (error) {

    }
  }

  function* setSelectedTicket({ payload: {ticketId } }) {
    try {
      if (ticketId) {

        const serverResponse = yield call(
          TicketHelper.getTicketFromParse,
          ticketId,
        );
        if (serverResponse) {
          yield put(TicketAction.updateSelectedTicket(serverResponse));
          serverResponse.pin();
        }
      }
    } catch (error) {
      
    }
  }
  
  function* setSelectedTicketLog({ payload: {ticketId } }) {
    try {
      if (ticketId) {
        yield put(TicketAction.updateSelectedTicketLog([]));
        const localResponse = yield call(
          TicketHelper.getTicketLogFromParseForTicketId,
          ticketId,
          true
        );
        if (localResponse) {
          yield put(TicketAction.updateSelectedTicketLog(localResponse));
        }
  
        const serverResponse = yield call(
          TicketHelper.getTicketLogFromParseForTicketId,
          ticketId,
          false
        );
        if (serverResponse) {
          yield put(TicketAction.updateSelectedTicketLog(serverResponse));
          serverResponse.pin();
        }
      }
    } catch (error) {
      
    }
  }


  function* addTicketLog({ payload: { ticketLog,exObject } }) {
    try {
      const response = yield call(
        TicketHelper.saveCustomObjects,ticketLog
      );

      if (response) {
        yield put(TicketAction.updateSelectedTicketLog([response], exObject ? false : true, exObject ? true : false));
        response.pin();
      }
     
    } catch (error) {
 
    }
  }

  function* deleteTicketLog({ payload: { ticketLog } }) {
    try {
      ticketLog.set('isDeleted',true)
      yield put(TicketAction.updateSelectedTicketLog([ticketLog],false,true));
      const response = yield call(
        TicketHelper.deleteTicketFromParse,ticketLog
      );
      if (response === "error") {
        ticketLog.set("isDeleted", undefined);
        yield put(TicketAction.updateSelectedTicketLog([ticketLog], false, true));
      } else
      if (response) {
        toastr.success("Note successfully removed", { timeOut: 3000 });
        response.unPin();
      }else{
  
      }
  
    } catch (error) {
     
    }
  }


  const getTicketLog=(logObject)=>{
    const TicketLog = Parse.Object.extend("TicketLog");
    var ticketLog = new TicketLog();

    ticketLog.set("ticketId", logObject.ticketId);
    ticketLog.set("fromStatus",logObject. fromStatus );
    ticketLog.set("toStatus", logObject.toStatus);
    ticketLog.set("message", logObject.message);
    ticketLog.set("isSystemGenerated", true);
    ticketLog.set('createdBy', logObject.teacherId)

    return ticketLog;
  }

  export function* watchDeleteTicket() {
    yield takeEvery(actionType.DELETE_TICKET, deleteTicket);
  }

  export function* watchGetTicketsForRecipientId() {
    yield takeEvery(
      actionType.GET_TICKETS,
      getTicketsForRecipientId
    );
  }

  export function* watchGetTicketsForFranchiseId() {
    yield takeEvery(
      actionType.GET_TICKETS_FOR_ASSIGNED_FOR,
      getTicketsForAssignedFor
    );
  }

  export function* watchEditTicket() {
    yield takeEvery(actionType.EDIT_TICKET, editATicket);
  }

  export function* watchAddTicket() {
    yield takeLeading(actionType.ADD_TICKET, addATicket);
  }

  export function* watchAddTicketLog() {
    yield takeLeading(actionType.ADD_TICKET_LOG, addTicketLog);
  }

  export function* watchSetSelectedTicket() {
    yield takeEvery(actionType.SET_SELECTED_TICKET, setSelectedTicket);
  }

  export function* watchSetSelectedTicketLog() {
    yield takeEvery(actionType.SET_SELECTED_TICKET_LOG, setSelectedTicketLog);
  }
  
  export function* watchDeleteTicketLog() {
    yield takeEvery(actionType.DELETE_TICKET_LOG, deleteTicketLog);
  }

  function* TicketSaga() {
    yield all([
      fork(watchDeleteTicket),
      fork(watchGetTicketsForRecipientId),
      fork(watchEditTicket),
      fork(watchAddTicket),
      fork(watchAddTicketLog),
      fork(watchSetSelectedTicket),
      fork(watchSetSelectedTicketLog),
      fork(watchGetTicketsForFranchiseId),
      fork(watchDeleteTicketLog)

    ]);
  }
  export default TicketSaga;
  

