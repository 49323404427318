import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  Button,
  CardTitle,
  Container,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Progress,
  Badge,
  Media,
} from "reactstrap";
import Iframe from "react-iframe";
import ReactAudioPlayer from 'react-audio-player';

import toastr from "toastr";

import { Element, animateScroll as scroll, scroller } from "react-scroll";
import classnames from "classnames";
import UpdatePhoto from "../../components/Common/UpdatePhoto";

import { AvForm, AvField } from "availity-reactstrap-validation";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import { Parse } from "parse";

import * as Constants from "../../Constents";
import ExamResultComponent from "./ExamResultComponent";

const EditCustomExam = (props) => {
  const next = "NEXT";
  const previous = "PREVIOUS";
  const kid = 1;
  const teacher = 2;

  const match = matchPath(props.history.location.pathname, {
    path: "/editcustomexam/:schoolId/:examId",
    exact: true,
    strict: false,
  });

  const matchForPrivateExam = matchPath(props.history.location.pathname, {
    path: "/editcustomexam/:schoolId/:examId/:userId/:userType/:courseId/:activityId",
    exact: true,
    strict: false,
  });
  const matchForPrivateExamWithBatchId = matchPath(props.history.location.pathname, {
    path: "/editcustomexam/:schoolId/:examId/:userId/:userType/:courseId/:activityId/:subscriptionId/:batchId",
    exact: true,
    strict: false,
  });

  const matchForPrivateExamTemp = matchPath(props.history.location.pathname, {
    path: "/editcustomexam/:schoolId/:examId/:userId/:userType",
    exact: true,
    strict: false,
  });

  var schoolId = match && match.params.schoolId;
  var examId = match && match.params.examId;
  var userId = matchForPrivateExam ? matchForPrivateExam.params.userId : "";
  var userType = matchForPrivateExam ? matchForPrivateExam.params.userType : "";
  var courseId = matchForPrivateExam ? matchForPrivateExam.params.courseId : "";
  var activityId = matchForPrivateExam ? matchForPrivateExam.params.activityId : "";
  var batchId = matchForPrivateExamWithBatchId ? matchForPrivateExamWithBatchId.params.batchId : "";
  var subscriptionId = matchForPrivateExamWithBatchId ? matchForPrivateExamWithBatchId.params.subscriptionId : "";

  if (match) {
    schoolId = match && match.params.schoolId;
    examId = match && match.params.examId;
  } else if (matchForPrivateExam) {
    schoolId = matchForPrivateExam && matchForPrivateExam.params.schoolId;
    examId = matchForPrivateExam && matchForPrivateExam.params.examId;
  } else if (matchForPrivateExamWithBatchId) {
    schoolId = matchForPrivateExamWithBatchId && matchForPrivateExamWithBatchId.params.schoolId;
    examId = matchForPrivateExamWithBatchId && matchForPrivateExamWithBatchId.params.examId;
    userId = matchForPrivateExamWithBatchId ? matchForPrivateExamWithBatchId.params.userId : "";
    userType = matchForPrivateExamWithBatchId ? matchForPrivateExamWithBatchId.params.userType : "";
    courseId = matchForPrivateExamWithBatchId ? matchForPrivateExamWithBatchId.params.courseId : "";
    activityId = matchForPrivateExamWithBatchId ? matchForPrivateExamWithBatchId.params.activityId : "";
  }

  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }

  var abortSubmitForm = false;

  const _audioExtensions = [".mp3", "mp3", ".wma", "wma", ".acc", "acc", ".wav", "wav", ".flac", "flac",];

  const [doneButtonPressed, setDoneButtonPressed] = useState(false);
  const [doneButtonPressedAnimate, setDoneButtonPressedAnimate] =
    useState(false);

  const [sessionId, setSessionId] = useState("");
  const [sessionObject, setSessionObject] = useState(undefined);
  const [stateSchoolObject, setStateSchoolObject] = useState(undefined);
  const [examSectionList, setExamSectionList] = useState([]);
  var fillingFor = undefined;

  const [isExamSubmitted, setIsExamSubmitted] = useState(false);
  const [isQuestionsAvailable, setIsQuestionsAvailable] = useState(true);

  const [cardMessage, setCardMessage] = useState("");
  const [cardIcon, setCardIcon] = useState("fa fa-check text-success mr-2");

  const [examName, setExamName] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isExamPublic, setIsExamPublic] = useState(false);
  const [isExamDeleted, setIsExamDeleted] = useState(false);
  const [isExamPrivate, setIsExamPrivate] = useState(false);

  const [examIsSubmites, examIsSubmitesOps] = useState(false);

  const [activeTab, setactiveTab] = useState(0);
  const [progressValue, setprogressValue] = useState(0);
  const personalDetails = "personalDetails";
  const personalDetailsName = "personalDetailsName";
  const personalDetailsEmail = "personalDetailsEmail";
  const personalDetailsPhone = "personalDetailsPhone";
  const personalDetailsClass = "personalDetailsClass";
  const personalDetailsSchool = "personalDetailsSchool";

  const tempSection = {
    id: personalDetails,
    title: "Personal Details",
    examQuestionsList: [
      {
        id: personalDetailsName,
        question: "Name",
        questionType: Constants.EXAM_FILL_IN_THE_BLANKS,
        questionHint: "Type your name here...",
      },
      {
        id: personalDetailsClass,
        question: "Class",
        questionType: Constants.EXAM_FILL_IN_THE_BLANKS,
        questionHint: "Type class here...",
      },
      {
        id: personalDetailsSchool,
        question: "School",
        questionType: Constants.EXAM_FILL_IN_THE_BLANKS,
        questionHint: "Type school name here...",
      },
      {
        id: personalDetailsPhone,
        question: "Mobile Number",
        questionType: Constants.EXAM_FILL_IN_THE_BLANKS,
        questionHint: "Type number here...",
      },
      {
        id: personalDetailsEmail,
        question: "Email Id",
        questionType: Constants.EXAM_FILL_IN_THE_BLANKS,
        questionHint: "Type email here...",
      },
    ],
  };

  const tempResultSectionObject = { title: "Result", id: "resultScreen" };

  useEffect(() => {
    fetchExamFromServer(examId);
    fetchSchoolFromServer(schoolId);
  }, [schoolId, examId]);

  const fetchSchoolFromServer = (schoolId) => {
    Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
      schoolId: schoolId,
    }).then(
      (result) => {
        if (result != null) {
          setStateSchoolObject(result);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const fetchExamFromServer = () => {
    var query = new Parse.Query("Quiz");
    query.equalTo("objectId", examId);

    query.limit(1000);
    query.first().then((result) => {
      if (result) {
        if (result.attributes.name) {
          setExamName(result.attributes.name);
        }
        if (result.attributes.isDeleted === true) {
          setIsExamDeleted(true);
        } else {
          if (match) {
            setIsExamPublic(true);
          } else if (matchForPrivateExam || matchForPrivateExamWithBatchId) {
            setIsExamPrivate(true);
          }

          if (matchForPrivateExam || matchForPrivateExamWithBatchId) {
            fetchExamSessionsForMatchForPrivateExam(
              userId,
              courseId,
              activityId,
              batchId
            );
          } else {
            setExamSectionList([tempSection]);
          }
        }
      }
    });
  };

  const handleDoneButtonPressed = () => {
    // var query1 = new Parse.Query("QuizSession");
    // if (
    //   examSectionList[0].examQuestionsList[1].answerText &&
    //   examSectionList[0].examQuestionsList[1].answerText.trim().length > 0
    // ) {
    //   query1.equalTo(
    //     "emailId",
    //     examSectionList[0].examQuestionsList[1].answerText.trim()
    //   );
    // } else {
    //   query1.equalTo("emailId", "");
    // }
    // var query2 = new Parse.Query("QuizSession");
    // if (
    //   examSectionList[0].examQuestionsList[2].answerText &&
    //   examSectionList[0].examQuestionsList[2].answerText.trim().length > 0
    // ) {
    //   query2.equalTo(
    //     "phoneNumber",
    //     examSectionList[0].examQuestionsList[2].answerText.trim()
    //   );
    // } else {
    //   query2.equalTo("phoneNumber", "");
    // }

    var query = new Parse.Query("QuizSession");
    query.equalTo("quizId", examId);
    if (
      examSectionList[0].examQuestionsList[3].answerText &&
      examSectionList[0].examQuestionsList[3].answerText.trim().length > 0
    ) {
      query.equalTo(
        "phoneNumber",
        getPhoneNumber(
          examSectionList[0].examQuestionsList[3].answerText.trim()
        )
      );
    } else {
      query.equalTo("phoneNumber", "");
    }

    query.limit(1000);
    query.find().then(
      (results) => {
        if (results) {
          setDoneButtonPressed(false);
          setDoneButtonPressedAnimate(false);
          if (results.length > 0) {
            setSessionId(results[0].id);
            setSessionObject(results[0])
            let tempList = [...examSectionList];
            tempList.push(tempResultSectionObject);
            setExamSectionList(tempList);
          } else {
            fetchExamSections(examId, 2);
            //2 for public exam visibility
          }
        }
      },
      (error) => { }
    );
  };

  const fetchExamSessionsForMatchForPrivateExam = (userId, courseId, activityId, batchId) => {
    var query = new Parse.Query("QuizSession");
    query.equalTo("userId", userId);
    query.equalTo("activityId", activityId);
    query.equalTo("courseId", courseId);
    query.equalTo("quizId", examId);
    if (batchId != undefined && batchId.length > 0) {
      query.equalTo("batchId", batchId)
    }
    query.limit(1000);
    query.find().then((results) => {
      if (results) {
        if (results.length > 0) {
          setSessionId(results[0].id);
          setSessionObject(results[0])

          if (match) {
            let tempList = [...examSectionList];
            tempList.push(tempResultSectionObject);
            setExamSectionList(tempList);
          } else {
            setExamSectionList([tempResultSectionObject]);
          }
        } else {
          fetchExamSections(examId);
        }
      }
    });
  };

  const fetchExamSections = (examId) => {
    var query = new Parse.Query("QuizSection");
    query.equalTo("quizId", examId);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      if (results != null) {
        if (results.length > 0) {
          var examSectionParseList = results;
          var sectionIds = [];
          var i = 0;
          for (i = 0; i < examSectionParseList.length; i++) {
            sectionIds.push(examSectionParseList[i].id);
          }
          fetchExamQuestionsForExamIds(
            examSectionParseList,
            sectionIds
          );
        } else {
          setIsQuestionsAvailable(false);
        }
      }
    });
  };

  const getActualFileName = (result) => {
    if (typeof result === "object") {
      let name = result._name.split(/_(.+)/)[1];
      return name

    } else {
      return result
    }
  }

  const onClickActivityCompleted=()=> {
    const CourseLog = Parse.Object.extend('CourseLog');
    var courseLog = new CourseLog();
    courseLog.set('courseId', courseId);
    courseLog.set('batchId', batchId);
    courseLog.set('kidId', userId);
    // courseLog.set('userId', Parse.User.current().id);
    courseLog.set('type', "QUIZ");
    // courseLog.set('categoryId', categoryId);
    courseLog.set('activityId', activityId);
    courseLog.set('isCompleted', true);
    courseLog.set('subscriptionId', subscriptionId);
    courseLog.save();
  }

  const fetchExamQuestionsForExamIds = (
    examSectionParseList,
    sectionIds
  ) => {
    Parse.Cloud.run("function_getQuizQuestions", {
      sectionIds: sectionIds,
    }).then(
      (results) => {
        var tempSectionList = [];
        if (match) {
          tempSectionList = [...examSectionList];
        }

        if (results != null) {
          for (var i = 0; i < examSectionParseList.length; i++) {
            var j = 0;
            var tempQuestionList = [];

            var customExamSectionObject = new CustomExamSectionObject();
            customExamSectionObject.title =
              examSectionParseList[i].attributes.sectionName ?? "";
            customExamSectionObject.examSectionParseObject =
              examSectionParseList[i];

            for (j = 0; j < results.length; j++) {
              if (
                examSectionParseList[i].id === results[j].attributes.sectionId
              ) {
                var exmObject = results[j];

                var newExamObject = new CustomExamObject();
                newExamObject.question = exmObject.attributes.question ?? "";
                newExamObject.questionType = exmObject.attributes.type ?? "";
                newExamObject.questionNumber = i + 1;
                if (exmObject.attributes.dataType) {
                  newExamObject.dataType = exmObject.attributes.dataType;
                }
                if (exmObject.attributes.options) {
                  newExamObject.choices = [...exmObject.attributes.options];
                } else {
                  newExamObject.choices = [];
                }
                newExamObject.actualAnswer = exmObject.attributes.answer;
                if (
                  exmObject.attributes.isDefaultQuestion &&
                  exmObject.attributes.isDefaultQuestion === true
                ) {
                  newExamObject.isDefaultQuestion = true;
                } else {
                  newExamObject.isDefaultQuestion = false;
                }
                if (exmObject.attributes.defaultMarks) {
                  newExamObject.defaultMarks =
                    exmObject.attributes.defaultMarks;
                }

                newExamObject.dataType = exmObject.attributes.dataType;
                if (
                  exmObject.attributes.questionHint != null &&
                  exmObject.attributes.questionHint !== ""
                ) {
                  newExamObject.questionHint =
                    exmObject.attributes.questionHint;
                } else {
                  newExamObject.questionHint = "Type here";
                }
                newExamObject.answerChoices = [];
                if (exmObject.attributes.options2) {
                  newExamObject.choices2 = [...exmObject.attributes.options2];
                } else {
                  newExamObject.choices2 = [];
                }
                if (exmObject.attributes.optionImgs) {
                  newExamObject.optionImages = [
                    ...exmObject.attributes.optionImgs,
                  ];
                } else {
                  newExamObject.optionImages = [];
                }
                if (exmObject.attributes.option2Imgs) {
                  newExamObject.optionImages2 = [
                    ...exmObject.attributes.option2Imgs,
                  ];
                } else {
                  newExamObject.optionImages2 = [];
                }
                if (
                  exmObject.attributes.questionImage &&
                  exmObject.attributes.questionImage.length > 0
                ) {
                  newExamObject.questionImageURL =
                    exmObject.attributes.questionImage[0];
                } else {
                  newExamObject.questionImageURL = undefined;
                }
                if (
                  exmObject.attributes.attachments &&
                  exmObject.attributes.attachments.length > 0
                ) {
                  newExamObject.attachment =
                    exmObject.attributes.attachments[0];
                } else {
                  newExamObject.attachment = undefined;
                }
                if (
                  exmObject.attributes.quizVideo &&
                  exmObject.attributes.quizVideo.length > 0
                ) {
                  newExamObject.questionVideoURL =
                    exmObject.attributes.quizVideo;
                } else {
                  newExamObject.questionVideoURL = undefined;
                }
                newExamObject.selectedOptions = [];
                newExamObject.answerChoices = [];
                newExamObject.matchTheFollowingOptionsToDisplay = [];
                if (exmObject.attributes.options) {
                  for (
                    var z = 0;
                    z < exmObject.attributes.options.length;
                    z++
                  ) {
                    newExamObject.matchTheFollowingOptionsToDisplay.push(z + 1);
                    newExamObject.selectedOptions.push("");
                    if (
                      exmObject.attributes.type ===
                      Constants.EXAM_MATCH_THE_FOLLOWING ||
                      exmObject.attributes.type ===
                      Constants.EXAM_ARRANGE_THE_FOLLOWING
                    ) {
                      newExamObject.answerChoices.push("");
                    }
                  }
                }

                newExamObject.answerError = false;

                newExamObject.examQuestionParseObject = exmObject;

                tempQuestionList.push(newExamObject);
              }
            }

            customExamSectionObject.examQuestionsList = tempQuestionList;

            tempSectionList.push(customExamSectionObject);
          }

          setprogressValue(100 / tempSectionList.length);

          setExamSectionList(tempSectionList);
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  const componentForQuestionType = (
    examQuestionObject,
    examSectionObject,
    keyTitle,
    key
  ) => {
    var questionType = examQuestionObject.questionType;

    switch (questionType) {
      case Constants.EXAM_FILL_IN_THE_BLANKS:
        if (examQuestionObject.id === personalDetailsPhone) {
          return (
            <AvField
              type="text"
              name={"singleLinePhone" + keyTitle + key}
              placeholder={examQuestionObject.questionHint}
              className="form-control"
              value={examQuestionObject.answerText}
              disabled={examSectionList.length > 1}
              onChange={(e) => {
                handleInputOnChange(
                  examQuestionObject,
                  examSectionObject,
                  e.target.value
                );
              }}
              validate={{
                required: {
                  errorMessage: props.t("Enter phone no"),
                },
                pattern: {
                  value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                  errorMessage: props.t(
                    "You have entered an invalid phone no!"
                  ),
                },
                minLength: { value: 10 },
                maxLength: { value: 16 },
              }}
            />
          );
        } else if (
          examQuestionObject.id === personalDetailsName ||
          examQuestionObject.id === personalDetailsClass ||
          examQuestionObject.id === personalDetailsSchool
        ) {
          return (
            <AvField
              type="text"
              name={"singleLineNameClassSchool" + keyTitle + key}
              placeholder={examQuestionObject.questionHint}
              disabled={examSectionList.length > 1}
              className="form-control"
              value={examQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(
                  examQuestionObject,
                  examSectionObject,
                  e.target.value
                );
              }}
              validate={{
                required: {
                  errorMessage: props.t("Please fill this field"),
                },
              }}
            />
          );
        } else if (examQuestionObject.id === personalDetailsEmail) {
          return (
            <AvField
              type="email"
              name={"singleLineEmail" + keyTitle + key}
              placeholder={examQuestionObject.questionHint}
              className="form-control"
              value={examQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(
                  examQuestionObject,
                  examSectionObject,
                  e.target.value
                );
              }}
              validate={{
                pattern: {
                  value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                  errorMessage: props.t(
                    "You have entered an invalid email address!"
                  ),
                },
              }}
            />
          );
        }

        return (
          <AvField
            type="text"
            name={"singleLine" + keyTitle + key}
            placeholder={examQuestionObject.questionHint}
            className="form-control"
            value={examQuestionObject.answerText}
            onChange={(e) => {
              handleInputOnChange(
                examQuestionObject,
                examSectionObject,
                e.target.value
              );
            }}
          />
        );

        break;

      case Constants.EXAM_LONG_ANSWER:
        return (
          <FormGroup>
            <AvField
              type="textarea"
              name={"multiLine" + keyTitle + key}
              className="form-control"
              rows="3"
              value={examQuestionObject.answerText}
              placeholder={examQuestionObject.questionHint}
              onChange={(e) => {
                handleInputOnChange(
                  examQuestionObject,
                  examSectionObject,
                  e.target.value
                );
              }}
            />
            <Label>Can upload image if required !!</Label>
            {examQuestionObject.answerImage &&
              examQuestionObject.answerImageUploadProgress === 100 && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div className="ml-3">
                        <img
                          style={{ width: "5rem", height: "5rem" }}
                          className="rounded-circle avatar"
                          src={examQuestionObject.answerImage.imageURL}
                          alt=""
                        />
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {examQuestionObject.answerImage.actualImageName}
                        </div>
                        <div className="d-flex mt-3">
                          <UpdatePhoto
                            id={"imageUpload" + keyTitle + key}
                            tempId={"imageUpload" + keyTitle + key}
                            className="p-1"
                            buttonTitle="Change"
                            isPopupOn={false}
                            disableCropper={true}
                            onPicChange={(imageFile, url) => {
                              handleImageUploadChange(

                                key,
                                url,
                                imageFile,
                                examQuestionObject,
                                examSectionObject
                              );
                            }}
                            imageFileName={
                              examQuestionObject.answerImage
                                ? examQuestionObject.answerImage
                                  .actualImageName
                                : ""
                            }
                            initialImage={
                              examQuestionObject.answerImage.imageURL
                            }
                          ></UpdatePhoto>
                          <Button
                            className="btn-danger float-right ml-2"
                            onClick={() => {
                              var questionIndex = examSectionObject.examQuestionsList.indexOf(
                                examQuestionObject
                              );

                              var titleIndex = examSectionList.indexOf(
                                examSectionObject
                              );

                              var tempQuestionObject = examQuestionObject;

                              var tempTitleObject =
                                examSectionList[titleIndex];

                              tempQuestionObject.answerImage = undefined;
                              tempQuestionObject.answerImageUploadProgress = 0;

                              var tempQuestionList = [
                                ...tempTitleObject.examQuestionsList,
                              ];

                              tempQuestionList[
                                questionIndex
                              ] = tempQuestionObject;

                              tempTitleObject.examQuestionsList = [
                                ...tempQuestionList,
                              ];

                              var tempTitleList = [...examSectionList];

                              tempTitleList[titleIndex] = tempTitleObject;

                              setExamSectionList(tempTitleList);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {examQuestionObject.answerImage &&
              examQuestionObject.answerImageUploadProgress >= 1 &&
              examQuestionObject.answerImageUploadProgress < 100 && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div
                        className="avatar ml-3"
                        style={{ width: "5rem", height: "5rem" }}
                      >
                        <span
                          className={
                            "avatar-title rounded-circle bg-soft-primary text-primary"
                          }
                          style={{ fontSize: "60px" }}
                        ></span>
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {examQuestionObject.answerImage.actualImageName}
                        </div>
                        <div className=" mt-3">
                          <Progress
                            color="primary"
                            value={
                              examQuestionObject.answerImageUploadProgress
                            }
                          ></Progress>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {examQuestionObject.answerImage == undefined && (
              <div className="d-flex">
                <div>
                  {" "}
                  <UpdatePhoto
                    id={"imageUploadMain" + keyTitle + key}
                    tempId={"imageUploadMain" + keyTitle + key}
                    className="p-1"
                    isPopupOn={false}
                    disableCropper={true}
                    onPicChange={(imageFile, url) => {
                      handleImageUploadChange(

                        key,
                        url,
                        imageFile,
                        examQuestionObject,
                        examSectionObject
                      );
                    }}
                    initialImage={""}
                  ></UpdatePhoto>
                </div>
              </div>
            )}</FormGroup>
        );
        break;

      case Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST:
        return radioButtonForSingleSelection(
          examQuestionObject,
          examSectionObject,
          examQuestionObject.choices,
          handleChoiceButtonClick,
          keyTitle,
          key
        );
        break;

      case Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST:
        return checkboxButtonForMultiSelection(
          examQuestionObject,
          examSectionObject,
          examQuestionObject.choices,
          handleChoiceButtonClick,
          keyTitle,
          key
        );

        break;
      case Constants.EXAM_MATCH_THE_FOLLOWING:
        return (
          <div className="widget mb-2 pb-2" key={keyTitle + key}>
            <div>
              <FormGroup key={keyTitle + key}>
                <div className="table-responsive mb-0">
                  <table
                    id={"matchTheFollowing" + keyTitle + key}
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th>Column A</th>
                        <th>Column B</th>
                        <th>Correct Column B Index</th>
                      </tr>
                    </thead>
                    <tbody>
                      {examQuestionObject.choices.map((choice, keyInside) => (
                        <tr key={keyTitle + key + keyInside}>
                          <td>
                            <div key={keyTitle + key + keyInside} className="d-flex">
                              {/* <label className="mr-2">{keyInside + 1}.</label> */}
                              <div className="float-right">
                                <label className="form-check-label">
                                  {choice}
                                </label>
                                {examQuestionObject.optionImages[keyInside] && (
                                  <div className="mt-2">
                                    <img
                                      src={
                                        examQuestionObject.optionImages[
                                        keyInside
                                        ]
                                      }
                                      style={{
                                        width: "7rem",
                                        height: "7rem",
                                      }}
                                      alt="Option image"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div key={keyTitle + key + keyInside} className="d-flex">
                              <label className="mr-2">{keyInside + 1}.</label>
                              <div className="float-right">
                                <label className="form-check-label">
                                  {examQuestionObject.choices2[keyInside]}
                                </label>
                                {examQuestionObject.optionImages2[
                                  keyInside
                                ] && (
                                    <div className="mt-2">
                                      <img
                                        src={
                                          examQuestionObject.optionImages2[
                                          keyInside
                                          ]
                                        }
                                        style={{
                                          width: "7rem",
                                          height: "7rem",
                                        }}
                                        alt="Option image"
                                      ></img>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </td>
                          <td>
                            <div key={keyTitle + key + keyInside} style={{ maxWidth: "100px" }}>
                              <select
                                className="custom-select col-lg-6 mb-2"
                                id={"matchTheFollowing" + keyInside}
                                onChange={(e) => {
                                  let tempIntValue = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  let tempIntId = parseInt(keyInside, 10);
                                  handlerForMatchTheFollowing(
                                    tempIntId,
                                    tempIntValue,
                                    examQuestionObject,
                                    examSectionObject
                                  );
                                }}
                              >
                                <option disabled selected value>
                                  {" "}
                                  -- select an option --{" "}
                                </option>
                                {examQuestionObject.matchTheFollowingOptionsToDisplay.map(
                                  (option, keyInside) => (
                                    <option
                                      id={keyInside}
                                      key={keyTitle + key + keyInside}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </FormGroup>
            </div>
          </div>
        );

        break;
      case Constants.EXAM_ARRANGE_THE_FOLLOWING:
        return (
          <div className="widget mb-2 pb-2" key={keyTitle + key}>
            <div>
              <FormGroup key={keyTitle + key}>
                <div className="table-responsive mb-0">
                  <table
                    id={"matchTheFollowing" + keyTitle + key}
                    className="table table-striped table-bordered"
                  >
                    <thead>
                      <tr>
                        <th>Column A</th>

                        <th>Correct Order</th>
                      </tr>
                    </thead>
                    <tbody>
                      {examQuestionObject.choices.map((choice, keyInside) => (
                        <tr>
                          <td>
                            <div key={keyTitle + key + keyInside} className="d-flex">
                              <label className="mr-2">{keyInside + 1}.</label>
                              <div className="float-right">
                                <label className="form-check-label">
                                  {choice}
                                </label>
                                {examQuestionObject.optionImages[keyInside] && (
                                  <div className="mt-2">
                                    <img
                                      src={
                                        examQuestionObject.optionImages[
                                        keyInside
                                        ]
                                      }
                                      style={{
                                        width: "7rem",
                                        height: "7rem",
                                      }}
                                      alt="Option image"
                                    ></img>
                                  </div>
                                )}
                              </div>
                            </div>
                          </td>

                          <td>
                            <div key={keyTitle + key + keyInside} style={{ maxWidth: "100px" }}>
                              <select
                                className="custom-select col-lg-6 mb-2"
                                id={"arrangeTheFollowing" + keyTitle + key + keyInside}
                                onChange={(e) => {
                                  let tempIntValue = parseInt(
                                    e.target.value,
                                    10
                                  );
                                  let tempIntId = parseInt(keyInside, 10);
                                  handlerForArrangeTheFollowing(
                                    tempIntId,
                                    tempIntValue,
                                    examQuestionObject,
                                    examSectionObject
                                  );
                                }}
                              >
                                <option disabled selected value>
                                  {" "}
                                  -- select an option --{" "}
                                </option>
                                {examQuestionObject.matchTheFollowingOptionsToDisplay.map(
                                  (option, keyInside) => (
                                    <option
                                      id={keyInside}
                                      key={keyTitle + key + keyInside}
                                      value={option}
                                    >
                                      {option}
                                    </option>
                                  )
                                )}
                              </select>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </FormGroup>
            </div>
          </div>
        );

        break;
    }
  };

  // //  UI Functions

  const radioButtonForSingleSelection = (
    examQuestionObject,
    examSectionObject,
    choices,
    handlerFunction,
    keyTitle,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={keyTitle + key}>
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyTitle + key + keyInside}>
              <div className="d-flex">
                <input
                  className="form-check-input"
                  type="radio"
                  id={"radioButton" + keyTitle + key + keyInside}
                  value={keyInside}
                  checked={examQuestionObject.answerChoices.includes(keyInside)}
                  onChange={() => {
                    handlerFunction(
                      keyInside,
                      examQuestionObject,
                      examSectionObject
                    );
                  }}
                />
                <div className="float-right">
                  <label
                    className="form-check-label"
                    htmlFor={"radioButton" + keyTitle + key + keyInside}
                  >
                    {choice}
                  </label>
                  {examQuestionObject.optionImages[keyInside] && (
                    <div className="mt-2 mb-2">
                      <img
                        src={examQuestionObject.optionImages[keyInside]}
                        style={{
                          width: "7rem",
                          height: "7rem",
                        }}
                        alt="Option image"
                      ></img>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const checkboxButtonForMultiSelection = (
    examQuestionObject,
    examSectionObject,
    choices,
    handlerFunction,
    keyTitle,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={keyTitle + key}>
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyTitle + key + keyInside}>
              <div className="d-flex">
                <input
                  className="form-check-input"
                  type="checkbox"
                  id={"checkbox" + keyTitle + key + keyInside}
                  value={keyInside}
                  checked={examQuestionObject.answerChoices.includes(keyInside)}
                  onChange={() => {
                    handlerFunction(
                      keyInside,
                      examQuestionObject,
                      examSectionObject
                    );
                  }}
                />

                <div className="float-right">
                  <label
                    className="form-check-label"
                    htmlFor={"checkbox" + keyTitle + key + keyInside}
                  >
                    {choice}
                  </label>
                  {examQuestionObject.optionImages[keyInside] && (
                    <div className="mt-2 mb-2">
                      <img
                        src={examQuestionObject.optionImages[keyInside]}
                        style={{
                          width: "7rem",
                          height: "7rem",
                        }}
                        alt="Option image"
                      ></img>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const isExamValid = () => {
    if (doneButtonPressed) {
      setDoneButtonPressedAnimate(true);
      handleDoneButtonPressed();
    } else {
      var canProceedToSave = true;

      var examSectionObject = examSectionList[examSectionList.length - 1];
      var examQuestionsList = examSectionObject.examQuestionsList;
      if (canProceedToSave) {
        saveExam();
      }
    }
  };

  const checkForDataType = (dataType, answer) => {
    if (dataType === "ARRAY") {
      if (!Array.isArray(answer)) {
        return [answer];
      }
    } else if (dataType === "STRING") {
      if (!(typeof answer === "string" || answer instanceof String)) {
        return answer.toString();
      }
    } else if (dataType === "NUMBER") {
      if (typeof answer === "string" || answer instanceof String) {
        return parseInt(answer, 10);
      }
    }
    return answer;
  };

  const comparingForFillingObjectsWithAnswers = (
    i,
    objectToReturn,
    shouldSaveObject,
    examQuestionsList
  ) => {

    let reviewNeeded = false
    if (
      examQuestionsList[i].questionType === Constants.EXAM_LONG_ANSWER
    ) {

      reviewNeeded = true
      if (
        examQuestionsList[i].isDefaultQuestion === true &&
        examQuestionsList[i].answerText != undefined &&
        examQuestionsList[i].answerText.length > 0
      ) {
        fillingFor = examQuestionsList[i].answerText;
      }
      let tempAnswer
      if (
        examQuestionsList[i].answerText != null &&
        examQuestionsList[i].answerText != ""
      ) {
        tempAnswer = checkForDataType(
          examQuestionsList[i].dataType,
          examQuestionsList[i].answerText
        );
        if (Array.isArray(tempAnswer)) {

        } else {
          tempAnswer = [tempAnswer]
        }
        shouldSaveObject = true;
      }
      if (
        examQuestionsList[i].answerImage &&
        examQuestionsList[i].answerImage.image != null
      ) {

        if (!shouldSaveObject) {
          tempAnswer = [""]
        }
        tempAnswer.push(examQuestionsList[i].answerImage.image)

        shouldSaveObject = true;
      } else if (
        examQuestionsList[i].answerImageUploadProgress >= 1 &&
        examQuestionsList[i].answerImageUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for Image upload to complete, then try again",
          { timeOut: 1000 }
        );
      }

      if (shouldSaveObject) {
        objectToReturn.set("answer", tempAnswer);
      }
    }

    else if (
      examQuestionsList[i].questionType === Constants.EXAM_FILL_IN_THE_BLANKS
    ) {
      reviewNeeded = true

      if (
        examQuestionsList[i].isDefaultQuestion === true &&
        examQuestionsList[i].answerText != undefined &&
        examQuestionsList[i].answerText.length > 0
      ) {
        fillingFor = examQuestionsList[i].answerText;
      }
      if (
        examQuestionsList[i].answerText != null &&
        examQuestionsList[i].answerText != ""
      ) {
        let tempAnswer = checkForDataType(
          examQuestionsList[i].dataType,
          examQuestionsList[i].answerText
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }

        shouldSaveObject = true;
      }
    } else if (
      examQuestionsList[i].questionType ===
      Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      if (
        examQuestionsList[i].answerChoices != null &&
        examQuestionsList[i].answerChoices.length > 0
      ) {
        let isListEmpty = true;
        for (var l = 0; l < examQuestionsList[i].answerChoices.length; l++) {
          if (examQuestionsList[i].answerChoices[l] !== "") {
            isListEmpty = false;
          }
        }
        if (!isListEmpty) {
          let tempAnswer = checkForDataType(
            examQuestionsList[i].dataType,
            examQuestionsList[i].answerChoices[0]
          );
          if (Array.isArray(tempAnswer)) {
            objectToReturn.set("answer", tempAnswer);
          } else {
            objectToReturn.set("answer", [tempAnswer]);
          }
          shouldSaveObject = true;
        }
      }
    } else if (
      examQuestionsList[i].questionType ===
      Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST ||
      examQuestionsList[i].questionType ===
      Constants.EXAM_MATCH_THE_FOLLOWING ||
      examQuestionsList[i].questionType === Constants.EXAM_ARRANGE_THE_FOLLOWING
    ) {
      if (
        examQuestionsList[i].answerChoices != null &&
        examQuestionsList[i].answerChoices.length > 0
      ) {
        let isListEmpty = true;
        for (var l = 0; l < examQuestionsList[i].answerChoices.length; l++) {
          if (examQuestionsList[i].answerChoices[l] !== "") {
            isListEmpty = false;
          }
        }
        if (!isListEmpty) {
          let tempAnswer = checkForDataType(
            examQuestionsList[i].dataType,
            examQuestionsList[i].answerChoices
          );
          if (Array.isArray(tempAnswer)) {
            objectToReturn.set("answer", tempAnswer);
          } else {
            objectToReturn.set("answer", [tempAnswer]);
          }
          shouldSaveObject = true;
        }
      }
    }
    return {
      reviewNeeded: reviewNeeded,
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };

  const collectingObjectsWithAnswers = () => {
    let listOfObjectsToSave = [];
    let reviewNeeded = false
    let totalMarks = 0;
    let totalMarksObtained = 0;

    var j = 0;
    for (j = 0; j < examSectionList.length; j++) {
      if (examSectionList[j].id !== personalDetails) {
        var i = 0;
        var examQuestionsList = examSectionList[j].examQuestionsList;
        for (i = 0; i < examQuestionsList.length; i++) {
          let ExamData = Parse.Object.extend("QuizResults");
          let objectToSave = new ExamData();
          objectToSave.set("quizId", examId);
          var shouldSaveObject = false;
          var result = undefined;
          objectToSave.set(
            "sectionId",
            examSectionList[j].examSectionParseObject.id
          );
          objectToSave.set(
            "questionId",
            examQuestionsList[i].examQuestionParseObject.id
          );

          result = comparingForFillingObjectsWithAnswers(
            i,
            objectToSave,
            shouldSaveObject,
            examQuestionsList
          );
          if (result) {
            if (result.reviewNeeded) {
              reviewNeeded = result.reviewNeeded
            }
            shouldSaveObject = result.shouldSaveObject;
            objectToSave = result.objectToReturn;
          }
          if (shouldSaveObject) {
            let marksObtained = getMarksObtained(
              examQuestionsList[i].questionType,
              examQuestionsList[i]
            );
            totalMarksObtained += marksObtained
            objectToSave.set("marks", marksObtained);

            listOfObjectsToSave.push(objectToSave);
          }
          totalMarks +=
            examQuestionsList[i].examQuestionParseObject.attributes
              .defaultMarks ?? 1;
        }
      }
    }

    return {
      reviewNeeded: reviewNeeded,
      listOfObjectsToSave: listOfObjectsToSave,
      totalMarks: totalMarks,
      totalMarksObtained: totalMarksObtained,
    };
  };

  const getMarksObtained = (questionType, examQuestionObject) => {
    let returnValue = 0;

    if (questionType === Constants.EXAM_LONG_ANSWER) {




    } else if (questionType === Constants.EXAM_FILL_IN_THE_BLANKS) {
      if (
        examQuestionObject.actualAnswer &&
        examQuestionObject.actualAnswer.length > 0 &&
        examQuestionObject.answerText &&
        examQuestionObject.answerText.length > 0 &&
        examQuestionObject.answerText.toLowerCase() ===
        examQuestionObject.actualAnswer[0].toLowerCase()
      ) {
        returnValue = examQuestionObject.defaultMarks ?? 1;
      }
    } else if (questionType === Constants.EXAM_MATCH_THE_FOLLOWING) {
      for (var k = 0; k < examQuestionObject.actualAnswer.length; k++) {
        if (
          examQuestionObject.answerChoices[k] ===
          examQuestionObject.actualAnswer[k]
        ) {
          if (examQuestionObject.defaultMarks) {
            returnValue +=
              examQuestionObject.defaultMarks /
              examQuestionObject.actualAnswer.length;
          } else {
            returnValue += 1;
          }
        }
      }
    } else if (questionType === Constants.EXAM_ARRANGE_THE_FOLLOWING) {
      let isCorrect = true;

      for (var k = 0; k < examQuestionObject.actualAnswer.length; k++) {
        if (
          examQuestionObject.answerChoices[k] !==
          examQuestionObject.actualAnswer[k]
        ) {
          isCorrect = false;
        }
      }
      if (isCorrect) {
        returnValue = examQuestionObject.defaultMarks ?? 1;
      }
    } else if (
      questionType === Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST ||
      questionType === Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      if (
        JSON.stringify(examQuestionObject.answerChoices) ===
        JSON.stringify(examQuestionObject.actualAnswer)
      ) {
        returnValue = examQuestionObject.defaultMarks ?? 1;
      }
    }

    return returnValue;
  };

  const saveExam = () => {
    if (abortSubmitForm) {
      return;
    }
    examIsSubmitesOps(true);

    let ExamSession = Parse.Object.extend("QuizSession");
    let examSessions = new ExamSession();
    examSessions.set("quizId", examId);
    examSessions.set("quizName", examName);

    let resultFromFunction = collectingObjectsWithAnswers();
    let objectsToSave = resultFromFunction.listOfObjectsToSave;

    if (resultFromFunction.reviewNeeded) {
      examSessions.set("reviewStatus", false);
    }

    examSessions.set(
      "totalMarksObtained",
      resultFromFunction.totalMarksObtained
    );

    if (matchForPrivateExam || matchForPrivateExamWithBatchId) {
      examSessions.set("userId", userId);
      examSessions.set("userType", parseInt(userType, 10));
      examSessions.set("courseId", courseId);
      examSessions.set("activityId", activityId);
      examSessions.set("kidId", userId)
      if (matchForPrivateExamWithBatchId) {
        if (batchId != undefined && batchId.length > 0) {
          examSessions.set("batchId", batchId);
        }
      }
    }


    if (match) {
      for (var e = 0; e < examSectionList[0].examQuestionsList.length; e++) {
        let question = examSectionList[0].examQuestionsList[e];
        if (question.id === personalDetailsEmail) {
          examSessions.set("emailId", question.answerText);
        } else if (question.id === personalDetailsPhone) {
          examSessions.set(
            "phoneNumber",
            getPhoneNumber(question.answerText.trim())
          );
        } else if (question.id === personalDetailsClass) {
          examSessions.set("class", question.answerText);
        } else if (question.id === personalDetailsSchool) {
          examSessions.set("schoolName", question.answerText);
        } else if (question.id === personalDetailsName) {
          examSessions.set("fillingFor", question.answerText);
        }
      }
    }
    if (fillingFor) {
      examSessions.set("fillingFor", fillingFor);
    }
    examSessions.save().then(
      (result) => {
        for (var i = 0; i < objectsToSave.length; i++) {
          objectsToSave[i].set("sessionId", result.id);
        }
        if (result.attributes.createdAt == result.attributes.updatedAt) {
          onClickActivityCompleted()
        }
        Parse.Object.saveAll(objectsToSave).then(
          (result) => {
            setCardMessage("Successfully submitted!!");
            setIsExamSubmitted(true);
          },
          (error) => {
            console.log("Error1 : " + error);
            examIsSubmitesOps(false);
            toastr.warning("Something went wrong, please try again !!");
          }
        );
      },
      (error) => {
        console.log("Error2 : " + error);
        examIsSubmitesOps(false);
        toastr.warning("Something went wrong, please try again !!");
      }
    );

    //Parse.saveAll
  };

  const getPhoneNumber = (value) => {
    let phoneNumber = value;
    let countryPhoneCode = "91";
    if (phoneNumber && phoneNumber.length > 0) {
      if (phoneNumber.indexOf("+") == 0) {
      } else if (phoneNumber.indexOf("0") == 0) {
        phoneNumber = "+" + countryPhoneCode + phoneNumber.substring(1);
      } else if (phoneNumber.indexOf("+" + countryPhoneCode) == -1) {
        phoneNumber = "+" + countryPhoneCode + phoneNumber;
      } else if (phoneNumber.indexOf(countryPhoneCode) == 0) {
        phoneNumber = "+" + phoneNumber;
      }
    }
    return phoneNumber;
  };

  //Handler functions
  //  Click/Changes Handlers

  const handlerForArrangeTheFollowing = (
    index,
    value,
    examQuestionObject,
    examSectionObject
  ) => {
    var questionIndex =
      examSectionObject.examQuestionsList.indexOf(examQuestionObject);

    var titleIndex = examSectionList.indexOf(examSectionObject);

    var tempQuestionObject = examQuestionObject;

    var tempTitleObject = examSectionList[titleIndex];

    tempQuestionObject.selectedOptions[index] = value;

    // let tempOptionsList = [
    //   ...tempQuestionObject.matchTheFollowingOptionsToDisplay,
    // ];
    // removeElement(tempOptionsList, value);

    // tempQuestionObject.matchTheFollowingOptionsToDisplay = [...tempOptionsList];

    var tempArray = [...tempQuestionObject.answerChoices];
    tempArray[index] = value - 1;
    tempQuestionObject.answerChoices = tempArray;

    var tempQuestionList = [...tempTitleObject.examQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.examQuestionsList = [...tempQuestionList];

    var tempTitleList = [...examSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setExamSectionList(tempTitleList);
  };

  const handlerForMatchTheFollowing = (
    index,
    value,
    examQuestionObject,
    examSectionObject
  ) => {
    var questionIndex =
      examSectionObject.examQuestionsList.indexOf(examQuestionObject);

    var titleIndex = examSectionList.indexOf(examSectionObject);

    var tempQuestionObject = examQuestionObject;

    var tempTitleObject = examSectionList[titleIndex];

    tempQuestionObject.selectedOptions[index] = value;

    // let tempOptionsList = [
    //   ...tempQuestionObject.matchTheFollowingOptionsToDisplay,
    // ];
    // removeElement(tempOptionsList, value);

    // tempQuestionObject.matchTheFollowingOptionsToDisplay = [...tempOptionsList];

    var tempArray = [...tempQuestionObject.answerChoices];
    tempArray[index] = value - 1;
    tempQuestionObject.answerChoices = tempArray;

    var tempQuestionList = [...tempTitleObject.examQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.examQuestionsList = [...tempQuestionList];

    var tempTitleList = [...examSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setExamSectionList(tempTitleList);
  };

  const handleChoiceButtonClick = (
    keyInside,
    examQuestionObject,
    examSectionObject
  ) => {
    var questionIndex =
      examSectionObject.examQuestionsList.indexOf(examQuestionObject);

    var titleIndex = examSectionList.indexOf(examSectionObject);

    var tempQuestionObject = examQuestionObject;

    var tempTitleObject = examSectionList[titleIndex];

    if (
      tempQuestionObject.questionType ===
      Constants.EXAM_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      var tempArray = [...tempQuestionObject.answerChoices];

      if (tempQuestionObject.answerChoices.includes(keyInside)) {
        //remove
        removeElement(tempArray, keyInside);
      } else {
        tempArray.push(keyInside);
      }

      tempQuestionObject.answerChoices = tempArray;

      var tempQuestionList = [...tempTitleObject.examQuestionsList];

      tempQuestionList[questionIndex] = tempQuestionObject;

      tempTitleObject.examQuestionsList = [...tempQuestionList];

      var tempTitleList = [...examSectionList];

      tempTitleList[titleIndex] = tempTitleObject;

      setExamSectionList(tempTitleList);
    } else if (
      tempQuestionObject.questionType ===
      Constants.EXAM_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      var tempArray = [...tempQuestionObject.answerChoices];

      if (tempQuestionObject.answerChoices.includes(keyInside)) {
        //remove
        removeElement(tempArray, keyInside);
      } else {
        tempArray = [keyInside];
      }

      tempQuestionObject.answerChoices = [...tempArray];

      var tempQuestionList = [...tempTitleObject.examQuestionsList];

      tempQuestionList[questionIndex] = tempQuestionObject;

      tempTitleObject.examQuestionsList = [...tempQuestionList];

      var tempTitleList = [...examSectionList];

      tempTitleList[titleIndex] = tempTitleObject;

      setExamSectionList(tempTitleList);
    }
  };

  const handleInputOnChange = (
    examQuestionObject,
    examSectionObject,
    changedValue
  ) => {
    var questionIndex =
      examSectionObject.examQuestionsList.indexOf(examQuestionObject);

    var titleIndex = examSectionList.indexOf(examSectionObject);

    var tempQuestionObject = examQuestionObject;

    var tempTitleObject = examSectionList[titleIndex];

    tempQuestionObject.answerText = changedValue;

    var tempQuestionList = [...tempTitleObject.examQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.examQuestionsList = [...tempQuestionList];

    var tempTitleList = [...examSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setExamSectionList(tempTitleList);
  };

  // On image upload
  function handleImageUploadChange(
    index,
    changedImageURL,
    imageFile,
    examQuestionObject,
    examSectionObject
  ) {
    let questionIndex = examSectionObject.examQuestionsList.indexOf(
      examQuestionObject
    );

    let titleIndex = examSectionList.indexOf(examSectionObject);

    let tempQuestionObject = examQuestionObject;

    let tempTitleObject = examSectionList[titleIndex];
    tempQuestionObject.answerImage = undefined;
    tempQuestionObject.answerImageUploadProgress = 1;

    let tempQuestionList = [...tempTitleObject.examQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.examQuestionsList = [...tempQuestionList];

    let tempTitleList = [...examSectionList];

    tempTitleList[titleIndex] = tempTitleObject;

    setExamSectionList(tempTitleList);

    let fileExtension = imageFile._name.split(".");
    let tempFile = {
      actualImageName: imageFile._name,
      fileExtension: fileExtension[1].toLowerCase(),
      imageName: fileExtension[0],
      image: imageFile,
      imageURL: undefined,
    };

    //var name = tempFile.imageName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

    tempFile.image
      .save({
        progress: (value) => {
          if (value && value > 0.1) {
            //setUploadProgress(value * 100);
            //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

            let tempQuestionObject = examQuestionObject;
            let tempTitleObjectImage = examSectionList[titleIndex];

            if (value === 1) {
              tempQuestionObject.answerImageUploadProgress = 99;
            } else {
              tempQuestionObject.answerImageUploadProgress = value * 100;
            }


            let tempQuestionList = [...tempTitleObjectImage.examQuestionsList];

            tempQuestionList[questionIndex] = tempQuestionObject;

            tempTitleObjectImage.examQuestionsList = [...tempQuestionList];

            let tempTitleList = [...examSectionList];

            tempTitleList[titleIndex] = tempTitleObjectImage;

            setExamSectionList(tempTitleList);
          }
        },
      })
      .then(
        (result) => {
          tempFile.imageURL = result._url;
          tempFile.image = result;

          let tempQuestionObject = examQuestionObject;
          tempQuestionObject.answerImageUploadProgress = 100;

          let tempTitleObjectImage = examSectionList[titleIndex];

          tempQuestionObject.answerImage = tempFile;
          tempQuestionObject.answerError = false;

          let tempQuestionList = [...tempTitleObjectImage.examQuestionsList];

          tempQuestionList[questionIndex] = tempQuestionObject;

          tempTitleObjectImage.examQuestionsList = [...tempQuestionList];

          let tempTitleList = [...examSectionList];

          tempTitleList[titleIndex] = tempTitleObjectImage;

          setExamSectionList(tempTitleList);
        },
        (error) => {
          //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
        }
      );

    // enqObject.answerImage = { actualImageName: tempFile.actualImageName };
    // enquiryQuestionsList[index] = enqObject;

    // var temp = [...enquiryQuestionsList];
    // temp[index] = enqObject;
    // setEnquiryQuestionsList(temp);

    let tempQuestionObject1 = examQuestionObject;

    let tempTitleObject1 = examSectionObject;

    tempQuestionObject1.answerImage = {
      actualImageName: tempFile.actualImageName,
    };
    tempQuestionObject1.answerImage.imageURL = changedImageURL;

    let tempQuestionList1 = [...tempTitleObject1.examQuestionsList];

    tempQuestionList1[questionIndex] = tempQuestionObject1;

    tempTitleObject.examQuestionsList = [...tempQuestionList1];

    let tempTitleList1 = [...examSectionList];

    tempTitleList1[titleIndex] = tempTitleObject1;

    setExamSectionList(tempTitleList1);
  }









  const setAnswerErrorTrue = (examQuestionsList, examSectionObject, i) => {
    var tempObject = examQuestionsList[i];

    tempObject.answerError = true;

    var temp = [...examQuestionsList];

    temp[i] = tempObject;

    var tempTitleList = [...examSectionList];

    var titleIndex = examSectionList.indexOf(examSectionObject);

    var tempTitleObject = examSectionList[titleIndex];

    tempTitleObject.examQuestionsList = temp;

    tempTitleList[titleIndex] = tempTitleObject;

    setExamSectionList(tempTitleList);
  };

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  function toggleTab(tab, examQuestionsList, examSectionObject, choice) {
    if (tab !== activeTab) {
      if (tab - activeTab === 1 || tab - activeTab === -1) {
      } else {
        // if (choice === next) {
        //   tab = activeTab + 1;
        // } else if (choice === previous) {
        //   tab = activeTab - 1;
        // }
      }

      if (choice === next) {
        var canProceedToNextScreen = true;

        if (canProceedToNextScreen) {
          if (activeTab !== tab) {
            if (tab >= 0 && tab <= examSectionList.length - 1) {
              scroller.scrollTo("topScrollArea", {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page
              });
              setactiveTab(tab);

              var tempRatio = 100 / examSectionList.length;
              var tempMultiplier = tab + 1;
              var tempProgress = tempMultiplier * tempRatio;
              setprogressValue(tempProgress);
            }
          }
        } else {
        }
      } else {
        if (activeTab !== tab) {
          if (tab >= 0 && tab <= examSectionList.length - 1) {
            scroller.scrollTo("topScrollArea", {
              duration: 500,
              delay: 100,
              smooth: true,
              offset: -100, // Scrolls to element + 50 pixels down the page
            });
            setactiveTab(tab);
            var tempRatio = 100 / examSectionList.length;
            var tempMultiplier = tab + 1;
            var tempProgress = tempMultiplier * tempRatio;
            setprogressValue(tempProgress);
          }
        }
      }
    }
  }

  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }

    return "bx bxs-file-blank";
  }

  const getFileExtension = (result) => {
    if (typeof result === "object") {
      let name = result._name.split(/_(.+)/)[1];
      let fileExtension = name.split(".");
      return fileExtension[1].toLowerCase()
    } else {
      return result
    }
  }

  function getVimeoUrl(link) {
    var splits = link.split("/")
    var videoId = splits[splits.length - 2];
    var videokey = splits[splits.length - 1];
    return "https://player.vimeo.com/video/" + videoId + "?h=" + videokey;
  }

  const isAudioFile = (attachment) => {

    let fileExtension = getFileExtension(attachment)
    if (_audioExtensions.includes(fileExtension)) {
      return true
    }
    return false

  }

  const getResultScreen = () => {
    if (sessionObject && sessionObject.attributes.reviewStatus === false) {
      return (<ExamResultComponent
        schoolId={schoolId}
        examId={examId}
        sessionId={sessionId}
        showResultUnderReview={true}
      ></ExamResultComponent>)
    }
    return (<ExamResultComponent
      schoolId={schoolId}
      examId={examId}
      sessionId={sessionId}
    ></ExamResultComponent>)

  }

  return (
    <React.Fragment>
      <Container fluid={true} className={""}>
        {!isLoading && !isExamSubmitted && isQuestionsAvailable && (
          <Row>
            <Col lg={(matchForPrivateExam || matchForPrivateExamWithBatchId) ? 12 : 8} className="mx-auto">
              {
                <div className="text-center">
                  <h3 className="mt-3">
                    {stateSchoolObject
                      ? props.t(`${stateSchoolObject.attributes.Name}`)
                      : ""}{" "}
                  </h3>
                  {stateSchoolObject && <h5 className="mt-3">{stateSchoolObject.attributes.schoolType == 10 ? "Assessment" : "Exam"}</h5>}
                </div>
              }
              <Card>
                <CardBody>
                  <Element name="topScrollArea">
                    <h4 className="card-title mb-4">{examName}</h4>
                  </Element>
                  {(isExamDeleted && stateSchoolObject) && (
                    <Label>
                      {stateSchoolObject.attributes.schoolType == 10 ? "This Assessment has been deleted and is no longer valid" : "This exam has been deleted by school and is no longer valid"}
                      <Label
                        className="text-danger"
                        style={{ fontSize: "20px" }}
                      >
                        <i class="fa fa-exclamation-circle mr-2 mt-2 pt-2"></i>
                      </Label>
                    </Label>
                  )}

                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      {examSectionList.map((examSection, keyTitle) => (
                        <NavItem key={keyTitle}>
                          <NavLink
                            className={classnames({
                              active: activeTab === keyTitle,
                            })}
                            onClick={() => {
                              keyTitle > activeTab
                                ? toggleTab(
                                  keyTitle,
                                  examSectionList[activeTab]
                                    .examQuestionsList,
                                  examSectionList[activeTab],
                                  next
                                )
                                : toggleTab(
                                  keyTitle,
                                  examSectionList[activeTab]
                                    .examQuestionsList,
                                  examSectionList[activeTab],
                                  previous
                                );
                            }}
                          >
                            <Col>
                              <Row className="justify-content-center">
                                <span className="step-number">
                                  {keyTitle + 1}
                                </span>
                              </Row>
                              <Row className="justify-content-center mt-2">
                                <div style={{ textAlign: "center" }}>
                                  {examSection.title}
                                </div>
                              </Row>
                            </Col>
                          </NavLink>
                        </NavItem>
                      ))}
                    </ul>
                    <div id="bar" className="mt-4">
                      <Progress
                        color="success"
                        striped
                        animated
                        value={progressValue}
                      />
                      <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                    </div>
                    <TabContent
                      activeTab={activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      {examSectionList.map((examSection, keyTitle) => (
                        <TabPane key={keyTitle} tabId={keyTitle}>
                          {examSection.id === "resultScreen" ? (
                            getResultScreen()
                          )
                            : (
                              <Col lg={12} className="mx-auto">
                                <Card>
                                  <CardBody>
                                    <AvForm
                                      className="outer-repeater"
                                      style={{ border: "1px" }}
                                      onValidSubmit={isExamValid}
                                    >
                                      <div
                                        data-repeater-list="outer-group"
                                        className="outer"
                                      >
                                        <div data-repeater-item className="outer">
                                          <h5>{props.t(examSection.title)}: </h5>
                                          <hr />

                                          {examSection.examQuestionsList.map(
                                            (examQuestionObject, keyQuestion) => {
                                              return (
                                                <FormGroup key={keyTitle + keyQuestion}>
                                                  <Label className="mr-1">
                                                    {keyTitle + keyQuestion + 1}.
                                                  </Label>
                                                  <Label>
                                                    {examQuestionObject.question}
                                                  </Label>
                                                  {(examQuestionObject.id ===
                                                    personalDetailsName ||
                                                    examQuestionObject.id ===
                                                    personalDetailsPhone ||
                                                    examQuestionObject.id ===
                                                    personalDetailsSchool ||
                                                    examQuestionObject.id ===
                                                    personalDetailsClass) && (
                                                      <Label className="text-danger">
                                                        *
                                                      </Label>
                                                    )}


                                                  {examQuestionObject.questionImageURL && (
                                                    <div className="mb-3 mt-2 mr-3">
                                                      <img
                                                        src={
                                                          examQuestionObject.questionImageURL
                                                        }
                                                        style={{
                                                          maxWidth: "500px",
                                                          maxHeight: "500px",
                                                        }}
                                                        alt="Question Image"
                                                      ></img>
                                                    </div>
                                                  )}
                                                  {examQuestionObject.questionVideoURL && (
                                                    <div className="mb-3 mt-2 mr-3">
                                                      <div >
                                                        <Iframe
                                                          url={getVimeoUrl(examQuestionObject.questionVideoURL)}
                                                          width="500"
                                                          height="500"
                                                          style={{ cursor: "pointer" }}
                                                          display="initial"
                                                          scrolling={"no"}
                                                          allowFullScreen={true}
                                                          position="relative"
                                                          frameBorder={0}
                                                          className="mt-2"
                                                        />
                                                      </div>
                                                    </div>
                                                  )}

                                                  {(examQuestionObject.attachment && isAudioFile(examQuestionObject.attachment)) ? (<div>
                                                    {examQuestionObject.attachment._url &&
                                                      <div className="p-2" style={{ width: "500px" }}>
                                                        < ReactAudioPlayer
                                                          width={500}
                                                          src={examQuestionObject.attachment._url}
                                                          autoPlay={false}
                                                          controls
                                                        />
                                                      </div>

                                                    }

                                                  </div>) : examQuestionObject.attachment && (
                                                    <div style={{ width: "500px" }}>
                                                      <div className="border p-2">
                                                        <Row>
                                                          <div
                                                            className="ml-3 mt-1 text-center"
                                                            style={{ width: "2rem", height: "2rem" }}
                                                          >
                                                            <span
                                                              className="bg-white ml-0 text-primary h1 align-middle"
                                                              style={{ fontSize: "45px" }}
                                                            >
                                                              <i
                                                                className={getFileTypeImage(
                                                                  getFileExtension(examQuestionObject.attachment)
                                                                )}
                                                              ></i>
                                                            </span>
                                                          </div>

                                                          <div className="ml-4">
                                                            <div
                                                              className="text-muted font-weight-bold text-truncate"
                                                              style={{ width: "200px" }}
                                                            >
                                                              {getActualFileName(examQuestionObject.attachment)}
                                                            </div>
                                                            <div className="mt-2">
                                                              <Button
                                                                className="btn btn-sm btn-primary "
                                                                color="primary"
                                                                target="_blank"
                                                                rel="noopener noreferrer"
                                                                onClick={() => {
                                                                  if (examQuestionObject.attachment && examQuestionObject.attachment._url) {
                                                                    window.open(examQuestionObject.attachment._url, "_blank")
                                                                  } else {
                                                                    toastr.info("Please wait and try again...", { timeOut: 1000 })
                                                                  }
                                                                }}
                                                              >
                                                                View
                                                              </Button>
                                                            </div>
                                                          </div>
                                                        </Row>
                                                      </div>
                                                    </div>
                                                  )}

                                                  {examQuestionObject.defaultMarks && (
                                                    <div className="float-right">
                                                      <Label>
                                                        {
                                                          examQuestionObject.defaultMarks
                                                        }{" "}
                                                        Marks
                                                      </Label>
                                                    </div>
                                                  )}
                                                  {componentForQuestionType(
                                                    examQuestionObject,
                                                    examSection,
                                                    keyTitle,
                                                    keyQuestion
                                                  )}
                                                </FormGroup>
                                              );
                                            }
                                          )}

                                          {examSection.examQuestionsList
                                            .length === 0 && (
                                              <h6 className="mb-3">
                                                No Questions in this section.
                                              </h6>
                                            )}

                                          {examSectionList.length - 1 ===
                                            activeTab && <hr></hr>}
                                          {examSectionList.length - 1 ===
                                            activeTab && (
                                              <div style={{ display: "flex" }}>
                                                {examSectionList[
                                                  examSectionList.length - 1
                                                ].id !== personalDetails && (
                                                    <Button
                                                      type="submit"
                                                      color="primary"
                                                      disabled={examIsSubmites}
                                                      style={{
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        width: "180px",
                                                      }}
                                                    >
                                                      {examIsSubmites && (
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                                                      )}
                                                      {props.t("SUBMIT")}
                                                    </Button>
                                                  )}

                                                {examSectionList[
                                                  examSectionList.length - 1
                                                ].id === personalDetails && (
                                                    <Button
                                                      color="primary"
                                                      disabled={
                                                        doneButtonPressedAnimate
                                                      }
                                                      style={{
                                                        marginLeft: "auto",
                                                        marginRight: "auto",
                                                        width: "180px",
                                                      }}
                                                      type="submit"
                                                      onClick={() => {
                                                        setDoneButtonPressed(true);
                                                      }}
                                                    >
                                                      {doneButtonPressedAnimate && (
                                                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                                                      )}
                                                      {props.t("Done")}
                                                    </Button>
                                                  )}
                                              </div>
                                            )}
                                        </div>
                                      </div>
                                    </AvForm>
                                  </CardBody>
                                </Card>
                              </Col>
                            )}
                        </TabPane>
                      ))}
                    </TabContent>

                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTab === 0 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(
                              activeTab - 1,
                              examSectionList[activeTab].examQuestionsList,
                              examSectionList[activeTab],
                              previous
                            );
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      <li
                        className={
                          activeTab === examSectionList.length - 1
                            ? "next disabled"
                            : "next"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(
                              activeTab + 1,
                              examSectionList[activeTab].examQuestionsList,
                              examSectionList[activeTab],
                              next
                            );
                          }}
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {isExamSubmitted && (
          <Card className="p-5 mt-2">
            <CardBody>
              {/* <div className="outer-repeater" style={{ border: '1px' }} >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer"> 
                    <h5 ><i className="fa fa-check text-success text-center mr-2

" aria-hidden="true"></i>
                    {cardMessage}</h5>
                  {/* </div>
                </div>
              </div> */}
              <CardTitle className="text-center">
                <i className={cardIcon} aria-hidden="true"></i>
                {cardMessage}
              </CardTitle>
            </CardBody>
          </Card>
        )}

        {!isLoading && !isQuestionsAvailable && (
          <Card>
            <CardBody>
              <AvForm
                className="outer-repeater"
                style={{ border: "1px" }}
                onValidSubmit={() => { }}
              >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    <h5>{stateSchoolObject.attributes.schoolType == 10 ? "Assessments not configured" : "Exam not configured for this branch !"}</h5>
                  </div>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading ...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};

export default withNamespaces()(EditCustomExam);

class CustomExamObject {
  question = "";
  questionType = null;
  examQuestionParseObject = null;
  answerText = null;
  answerChoices = [];
  choices = [];
  choices2 = [];
  questionHint = "Type here";
  matchTheFollowingOptionsToDisplay = [];
  selectedOptions = [];
  questionImageURL = null;
  attachment = null;
  questionVideoURL = null;
  optionImages = [];
  optionImages2 = [];
  actualAnswer = [];
  answerImage = null;
  answerImageUploadProgress = 0;
  isDefaultQuestion = false;

  constructor(
    question,
    questionType,
    examQuestionParseObject,
    answerText,
    answerChoices,
    choices,
    choices2,
    questionHint,
    selectedOptions,
    matchTheFollowingOptionsToDisplay,
    questionImageURL,
    attachment,
    questionVideoURL,
    optionImages,
    optionImages2,
    actualAnswer = [],
    isDefaultQuestion = false,
    answerImage = null,
    answerImageUploadProgress = 0
  ) {
    this.question = question;
    this.questionType = questionType;
    this.examQuestionParseObject = examQuestionParseObject;
    this.answerText = answerText;
    this.answerChoices = answerChoices;
    this.choices = choices;
    this.choices2 = choices2;
    this.questionHint = questionHint;
    this.selectedOptions = selectedOptions;
    this.matchTheFollowingOptionsToDisplay = matchTheFollowingOptionsToDisplay;
    this.questionImageURL = questionImageURL;
    this.optionImages = optionImages;
    this.actualAnswer = actualAnswer;
    this.optionImages2 = optionImages2;
    this.answerImage = answerImage;
    this.answerImageUploadProgress = answerImageUploadProgress;
    this.attachment = attachment;
    this.questionVideoURL = questionVideoURL;

    this.isDefaultQuestion = isDefaultQuestion;
  }
}
class CustomExamSectionObject {
  examQuestionsList = [];
  title = "";
  examSectionParseObject = null;

  constructor(examQuestionsList, examSectionParseObject, title) {
    this.examQuestionsList = examQuestionsList;
    this.title = title;
    this.examSectionParseObject = examSectionParseObject;
  }
}
