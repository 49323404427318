import * as actions from "./actionTypes";

export const getCourseRequests = (courseId) => {
  return {
    type: actions.GET_COURSE_REQUESTS,
    payload: { courseId },
  };
};


export const getAllCourseRequestsForKidId = (kidId) => {
  return {
    type: actions.GET_ALL_COURSE_REQUESTS_FOR_KID_ID,
    payload: { kidId },
  };
};

export const updateCoureseRequests = (courseRequests) => {
  return {
    type: actions.UPDATE_COURSE_REQUESTS,
    payload: { courseRequests },
  };
};

export const updateCoureseRequestsInitialLoading = (state) => {
  return {
    type: actions.UPDATE_COURSE_REQUESTS_INITIAL_LOADING,
    payload: { state },
  };
};

export const updateCoureseRequestAddState = (state) => {
  return {
    type: actions.UPDATE_COURSE_REQUESTS_ADD_STATE,
    payload: { state },
  };
};