import React, { useState, useEffect } from 'react';
import { Card, CardBody, Row, Col, Label, Table, CardTitle, CardHeader, Button, Badge, Nav, NavItem, NavLink } from 'reactstrap'
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { setSelecetdSchool } from '../../store/actions';
import ItemImage from "../../assets/images/book_lover.svg";

const allApps = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/all-apps/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.id;

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    return (
        <div className='page-content'>
            <Col lg={8} md={8} xs={12}>
                <Row >
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => { window.history.back() }}
                    >
                        <ul className="ml-2 list-unstyled">
                            <div >
                                <i className="bx bx-chevron-left h1 text-primary"></i>
                            </div>
                        </ul>
                    </div>
                    <h4 className="mt-2">All Apps</h4>
                </Row>
            </Col>
            <Col>
                <Row>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="icon-demo-content" id="logos">
                                    <div class="col-xl-3 col-lg-4 col-sm-6"><i class="bx bx-calculator"></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                        <p><b>Payments</b></p>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="icon-demo-content" id="logos">
                                    <div class="col-xl-3 col-lg-4 col-sm-6"><i class="bx bx-female"></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                        <p><b>Staff</b></p>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="icon-demo-content" id="logos">
                                    <div class="col-xl-3 col-lg-4 col-sm-6"><i class="bx bx-face"></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                        <p><b>Students</b></p>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="icon-demo-content" id="logos">
                                    <div class="col-xl-3 col-lg-4 col-sm-6"><i class="bx bx-calendar"></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                        <p><b>Calendar</b></p>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="icon-demo-content" id="logos">
                                    <div class="col-xl-3 col-lg-4 col-sm-6"><i class="bx bx-group"></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                        <p><b>Groups</b></p>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="icon-demo-content" id="logos">
                                    <div class="col-xl-3 col-lg-4 col-sm-6"><i class="bx bx-user-plus"></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                        <p><b>Admissions</b></p>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="icon-demo-content" id="logos">
                                    <div class="col-xl-3 col-lg-4 col-sm-6"><i class="bx bx-clipboard"></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                        <p><b>Enquiry</b></p>
                    </Col>
                    <Col>
                        <Card>
                            <CardBody>
                                <Row className="icon-demo-content" id="logos">
                                    <div class="col-xl-3 col-lg-4 col-sm-6"><i class="bx bx-walk"></i></div>
                                </Row>
                            </CardBody>
                        </Card>
                        <p><b>Visitors</b></p>
                    </Col>
                </Row>
            </Col>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
}

export default (withRouter(connect(mapStatetoProps, { setSelecetdSchool })(allApps)));