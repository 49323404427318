import * as actions from "./actionTypes";

export const updateAdmissionQuestion = (admissionQuestion) => {
  return {
    type: actions.UPDATE_ADMISSION_QUESTION,
    payload: { admissionQuestion },
  };
};

export const deleteAdmissionQuestion = (admissionQuestion) => {
  return {
    type: actions.DELETE_ADMISSION_QUESTION,
    payload: {admissionQuestion},
  };
};

export const updateAdmissionquestions = (admissionQuestions) => {
  return {
    type: actions.UPDATE_ADMISSION_QUESTIONS,
    payload: { admissionQuestions },
  };
};

export const getAdmissionQuestionForAdmissionTitleId = (admissionTitleId) => {
  return {
    type: actions.GET_ADMISSION_QUESTION,
    payload: { admissionTitleId },
  };
};

export const getAdmissionQuestionForAdmissionTitleIds = (admissionTitleIds,schoolId) => {
  return {
    type: actions.GET_ADMISSION_QUESTIONS,
    payload: { admissionTitleIds ,schoolId},
  };
};

export const editAdmissionQuestion = (admissionQuestion) => {
  return {
    type: actions.EDIT_ADMISSION_QUESTION,
    payload: { admissionQuestion },
  };
};
export const addAdmissionQuestion = (admissionQuestion) => {
  return {
    type: actions.ADD_ADMISSION_QUESTION,
    payload: { admissionQuestion },
  };
};

export const updateSortOrder = (admissionQuestions,titleIds) => {
  return {
    type: actions.UPDATE_ADMISSION_QUESTION_SORT_ORDER,
    payload: { admissionQuestions,titleIds },
  };
};

export const reloadAdmissionQuestions = (admissionQuestions) => {
  return {
    type: actions.RELOAD_ADMISSION_QUESTION,
    payload: { admissionQuestions },
  };
};

export const moveAdmissionQuestion = (admissionQuestion,titleIds) => {
  return {
    type: actions.MOVE_ADMISSION_QUESTION,
    payload: { admissionQuestion ,titleIds},
  };
};


export const getDefaultAdmissionQuestions = () => {
  return {
    type: actions.GET_DEFAULT_ADMISSION_QUESTIONS,
    payload: {  },
  };
};

export const updateDefaultAdmissionQuestions = (admissionQuestions) => {
  return {
    type: actions.UPDATE_DEFAULT_ADMISSION_QUESTIONS,
    payload: { admissionQuestions },
  };
};


