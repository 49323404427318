export const UPDATE_ENQUIRY_REMINDER = "UPDATE_ENQUIRY_REMINDER";
export const DELETE_ENQUIRY_REMINDER = "DELETE_ENQUIRY_REMINDER";

export const UPDATE_ENQUIRY_REMINDERS = "UPDATE_ENQUIRY_REMINDERS";
export const REPLACE_ENQUIRY_REMINDERS = "REPLACE_ENQUIRY_REMINDERS";
export const GET_ENQUIRY_REMINDERS = "GET_ENQUIRY_REMINDERS";
export const GET_ENQUIRY_REMINDER = "GET_ENQUIRY_REMINDER";

export const SET_SELECTED_ENQUIRY_REMINDER = "SET_SELECTED_ENQUIRY_REMINDER";
export const UPDATE_SELECTED_ENQUIRY_REMINDER = "UPDATE_SELECTED_ENQUIRY_REMINDER";

export const EDIT_ENQUIRY_REMINDER ="EDIT_ENQUIRY_REMINDER";

export const ENQUIRY_REMINDER_SUCCESS = "ENQUIRY_REMINDER_SUCCESS";
export const ENQUIRY_REMINDER_ERROR = "ENQUIRY_REMINDER_ERROR";

export const GET_ENQUIRY_REMINDERS_EQUIRY_IDS = "GET_ENQUIRY_REMINDERS_EQUIRY_IDS";
export const UPDATE_ENQUIRY_REMINDERS_EQUIRY_IDS = "UPDATE_ENQUIRY_REMINDERS_EQUIRY_IDS";

export const GET_ENQUIRY_REMINDERS_SCHOOL = "GET_ENQUIRY_REMINDERS_SCHOOL";
export const UPDATE_ENQUIRY_REMINDERS_SCHOOL = "UPDATE_ENQUIRY_REMINDERS_SCHOOL";
export const SAVE_ENQUIRY_REMINDER = "SAVE_ENQUIRY_REMINDER";