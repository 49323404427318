import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
    getSchoolForInstitute,
    setSelecetdSchool,
    getCorporatePartners,
    getTeachersForSchool,
    getClassroomsForSchoolId,
    getAllKids,
    getAttendanceForTeacherIds,
    getAttendanceForKidIds,
    getDaycareAttendanceForKidIds,
} from '../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import Select from "react-select";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Container,
    Button
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import * as Constants from "../../Constents";


const DaywiseSummaryReport = (props) => {

    const [loadingState, setloadingState] = useState(undefined);

    const [classWiseAttendanceReport, setClassWiseAttendanceReport] = useState([]);
    const [diaryReport, setDiaryReport] = useState([]);
    const [msgReport, setMsgReport] = useState([]);
    const [album, setAlbum] = useState([]);


    const [selectedDay, setSelectedDay] = useState(new Date());


    const [allSchools, setAllSchools] = useState([]);

    useEffect(() => {
        if (props.schools && props.isFranchise) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);


    useEffect(() => {
        if (Parse.User.current().attributes.franchiseId && props.isFranchise) {

            props.getSchoolForInstitute(Parse.User.current().attributes.franchiseId, "franchiseId");
            if (props.showCorporatePartners) {
                props.getCorporatePartners(Parse.User.current().attributes.franchiseId, "franchiseId")
            }
        }
    }, [Parse.User.current().attributes.franchiseId]);

    const getAttendanceReport = () => {

        let params = {};
        params.kidIds = [];
        params.schoolId = props.schoolId;
        params.forSchool = true
        params.startDate = new Date(
            selectedDay.getFullYear(),
            selectedDay.getMonth(),
            selectedDay.getDate(),
            0,
            0,
            0,
            0,
        );
        params.endDate = new Date(
            selectedDay.getFullYear(),
            selectedDay.getMonth(),
            selectedDay.getDate(),
            23,
            59,
            59,
            999
        );

        for (const val of props.kids) {
            params.kidIds.push(val.id);
        }
        props.getDaycareAttendanceForKidIds(params);
        props.getAttendanceForKidIds(params);

    };



    useEffect(() => {
        if (props.classrooms && props.kidAttendanceList && props.kids && props.daycareAttendance) {
            let tempAttendance = [];

            for (const classRoom of props.classrooms) {
                let tempData = {
                    id: classRoom.id,
                    classRoomName: classRoom.attributes.Name,
                };

                let kidIds = [];
                let checkins = 0;
                let checkiinIds = [];
                let atSchool = 0;
                let atSchoolIds = [];
                let markedAbsent = 0;

                if (classRoom.attributes.classroomType == 2) {

                    for (const kid of props.kids) {

                        if (kid.attributes.ClassRoomID == classRoom.id || kid.attributes.daycareClassId == classRoom.id) {
                            kidIds.push(kid.id);

                            for (const dAttendance of props.daycareAttendance) {
                                if (dAttendance.attributes.kidId == kid.id) {
                                    if (!checkiinIds.includes(kid.id)) {
                                        checkiinIds.push(kid.id)
                                    }
                                    if (!dAttendance.attributes.checkOutTime) {
                                        if (!atSchoolIds.includes(kid.id)) {
                                            atSchoolIds.push(kid.id)
                                        }
                                    }
                                }
                            }
                        }
                    }

                    tempData.kidIds = kidIds;
                    tempData.total = kidIds.length;
                    tempData.checkIn = checkiinIds.length;
                    tempData.atSchool = atSchoolIds.length;
                    tempData.checkOut = tempData.checkIn - tempData.atSchool;
                    tempData.markedAbsent = tempData.total - tempData.checkIn;

                } else {
                    for (const kid of props.kids) {
                        if (kid.attributes.ClassRoomID == classRoom.id) {
                            kidIds.push(kid.id);
                            for (const attendance of props.kidAttendanceList) {
                                if (attendance.attributes.Kid == kid.id) {
                                    if (attendance.attributes.ReachedSchoolAt) {
                                        checkins++;
                                        if (!attendance.attributes.LeftSchoolAt) {
                                            atSchool++;
                                        }
                                    } else {
                                        markedAbsent++;
                                    }
                                }
                            }
                        }
                    }
                    tempData.kidIds = kidIds;
                    tempData.total = kidIds.length;
                    tempData.checkIn = checkins;
                    tempData.atSchool = atSchool;
                    tempData.checkOut = tempData.checkIn - tempData.atSchool;
                    tempData.markedAbsent = markedAbsent;

                }
                tempData.totalAbsents = tempData.total - tempData.checkIn;
                tempAttendance.push(tempData);
            }
            setClassWiseAttendanceReport(tempAttendance);
            getDiaryFeatures()
        }
    }, [props.kidAttendanceList, props.classrooms, props.kids, props.daycareAttendance]);


    const getDiaryFeatures = () => {

        var DiaryFeatures = Parse.Object.extend("DiaryFeatures");
        var queryFeatures = new Parse.Query(DiaryFeatures);
        queryFeatures.equalTo("schoolId", props.schoolId);
        queryFeatures.notEqualTo("isDeleted", true)
        queryFeatures.limit(1000);
        queryFeatures.find().then(
            (result) => {
                // console.log(result)
                getDiaryRecord(result)
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const getDiaryRecord = (diaryFeatures) => {

        let featureIds = []

        for (let i = 0; i < diaryFeatures.length; i++) {
            featureIds.push(diaryFeatures[i].id)
        }

        let params = {};
        params.schoolId = props.schoolId;

        params.startDate = new Date(
            selectedDay.getFullYear(),
            selectedDay.getMonth(),
            selectedDay.getDate(),
            0,
            0,
            0,
            0,
        );
        params.endDate = new Date(
            selectedDay.getFullYear(),
            selectedDay.getMonth(),
            selectedDay.getDate(),
            23,
            59,
            59,
            999
        );

        var DiaryRecord = Parse.Object.extend("DiaryRecord");
        var queryRecords = new Parse.Query(DiaryRecord);
        queryRecords.containedIn("diaryFeaturesId", featureIds);
        queryRecords.greaterThanOrEqualTo("createdAt", params.startDate);
        queryRecords.lessThanOrEqualTo("createdAt", params.endDate);
        queryRecords.ascending("createdAt");
        queryRecords.limit(5000);

        queryRecords.find().then(
            (result) => {
                // console.log(result);
                prepareDiaryData(diaryFeatures, result)
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const prepareDiaryData = (diaryFeatures, diaryRecords) => {
        // console.log("DairyFeatures", diaryFeatures);
        // console.log("DairyRecords", diaryRecords);
        let list = []
        for (let feature of diaryFeatures) {
            let records = [];
            let uniqueKidIds = [];
            for (let record of diaryRecords) {
                if (record.attributes.diaryFeaturesId == feature.id) {
                    records.push(record)
                    if (!uniqueKidIds.includes(record.attributes.receiverId)) {
                        uniqueKidIds.push(record.attributes.receiverId);
                    }
                }
            }
            let tempRecord = {
                routine: feature.attributes.routine,
                routineObject: feature,
                records: records,
                count: records.length,
                childCount: uniqueKidIds.length
            }

            list.push(tempRecord)

        }
        setDiaryReport(list)
        getMessageForShoolId()

    }

    const getMessageForShoolId = () => {

        let params = {};
        params.schoolId = props.schoolId;

        params.startDate = new Date(
            selectedDay.getFullYear(),
            selectedDay.getMonth(),
            selectedDay.getDate(),
            0,
            0,
            0,
            0,
        );
        params.endDate = new Date(
            selectedDay.getFullYear(),
            selectedDay.getMonth(),
            selectedDay.getDate(),
            23,
            59,
            59,
            999
        );

        var Messages = Parse.Object.extend("Messages");
        var query = new Parse.Query(Messages);
        query.equalTo("defaultSchoolId", params.schoolId);
        query.greaterThanOrEqualTo("createdAt", params.startDate);
        query.lessThanOrEqualTo("createdAt", params.endDate);
        query.notEqualTo("isDeleted", true)
        query.ascending("createdAt");
        query.limit(5000);

        query.find().then(
            (result) => {
                prepareMessageData(result)
            },
            (error) => {
                console.log(error);
            }
        );
    }

    const prepareMessageData = (messages) => {

        let messageList = [];

        let tempMsg = {
            message: 0,
            alert: 0,
            assignment: 0,
            notice: 0,
            reminder: 0,
            diaryMsg: 0,
            attendance: 0,
            transportMsg: 0,
            payment: 0,
            query: 0,
            LMS: 0,
            polls: 0,
            attachment: 0,
            videos: 0,
        };

        let albumIds = [];
        let videoAlbumIds = [];

        for (let msg of messages) {
            if (msg && msg.attributes) {
                if (msg.attributes.Type == Constants.MESSAGE) {
                    tempMsg.message++;
                } else if (msg.attributes.Type == Constants.ALERT) {
                    tempMsg.alert++;
                } else if (msg.attributes.Type == Constants.ASSIGNMENT) {
                    tempMsg.assignment++;
                } else if (msg.attributes.Type == Constants.NOTICE) {
                    tempMsg.notice++;
                } else if (msg.attributes.Type == Constants.REMINDER) {
                    tempMsg.reminder = tempMsg.reminder++;
                } else if (msg.attributes.Type == Constants.MEAL_IDEA || msg.attributes.Type == Constants.SWEET_TIMES || msg.attributes.Type == Constants.DIARY || msg.attributes.Type == Constants.DIARY_FOOD || msg.attributes.Type == Constants.DIARY_DIAPER || msg.attributes.Type == Constants.DIARY_NAP || msg.attributes.Type == Constants.DIARY_MEDICAL_NOTE ||
                    msg.attributes.Type == Constants.REPORT || msg.attributes.Type == Constants.ATTITUDE || msg.attributes.Type == Constants.INTELLIGENCE || msg.attributes.Type == Constants.LEARNING_STYLE || msg.attributes.Type == Constants.MEDICAL_NOTE_UPDATE || msg.attributes.Type == Constants.AIR_QUALITY || msg.attributes.Type == Constants.FEELINGS || msg.attributes.Type == Constants.ENERGY_LEVEL || msg.attributes.Type == Constants.TOYS) {
                    tempMsg.diaryMsg++;
                } else if (msg.attributes.Type == Constants.PAYMENT || msg.attributes.Type == Constants.PAYMENT_MESSAGE) {
                    tempMsg.payment++;
                } else if (msg.attributes.Type == Constants.TRANSPORT || msg.attributes.Type == Constants.TRANSPORT_FINISH || msg.attributes.Type == Constants.TRANSPORT_UPDATE) {
                    tempMsg.transportMsg++;
                } else if (msg.attributes.Type == Constants.ATTENDANCE) {
                    tempMsg.attendance++;
                } else if (msg.attributes.Type == Constants.QUERY) {
                    tempMsg.query++;
                } else if (msg.attributes.Type == Constants.ACTIVITY_CREATED || msg.attributes.Type == Constants.NEW_ACTIVITY_CREATED || msg.attributes.Type == Constants.LIVEMEETING || msg.attributes.Type == Constants.E_LEARNING) {
                    tempMsg.LMS++;
                } else if (msg.attributes.Type == Constants.POLLS) {
                    tempMsg.polls++;
                }

                if (msg.attributes.Attachment) {
                    tempMsg.attachment++;
                }

                if (msg && msg.attributes && msg.attributes.albumId) {
                    albumIds.push(msg.attributes.albumId)
                }

                if (msg && msg.attributes && msg.attributes.videoAlbumId) {
                    tempMsg.videos++;
                }
            }
        }
        getAlbumsForIds(albumIds)
        messageList.push(tempMsg)
        setMsgReport(messageList)

    }

    const getAlbumsForIds = (albumIds) => {
        if (albumIds.length > 0) {
            var Album = Parse.Object.extend("Album");
            var query = new Parse.Query(Album);
            query.containedIn('albumId', albumIds);
            query.notEqualTo("isDeleted", true)
            query.limit(1000);
            query.find().then((results) => {
                // console.log(results);
                setAlbum(results)
            })
        }
    }

    const onClickDownload = () => {


        let finalData = [];
        let titles = [];
        let fileName = "";
        let sheetName = "";
        fileName = `Daywise Summary Report.xlsx`;
        sheetName = "Daywise_Summary_Report";

        finalData.push([`Daywise Summary Report `]);

        finalData.push([]);

        finalData.push([`Attendance Report`]);

        titles.push("Classroom");
        titles.push("Total Strength");
        titles.push("Check in's");
        titles.push("Check Out");
        titles.push("Absent");

        finalData.push(titles);

        for (const item of classWiseAttendanceReport) {
            let temp = [];
            temp.push(item.classRoomName);
            temp.push(item.total)
            temp.push(item.checkIn)
            temp.push(item.checkOut)
            temp.push(item.totalAbsents)
            finalData.push(temp);
        }


        finalData.push([]);

        let finalData2 = [];
        finalData2.push(["Daycare Diary Report"]);
        finalData2.push(["SL No", "Categories","childCount", "Count"]);

        let slNo = 1;
        for (const diary of diaryReport) {
            finalData2.push([slNo++, diary.routine,diary.childCount, diary.count]);
        }
        finalData2.push([]);

        let finalData3 = [];
        finalData3.push(["Messages Report"]);

        finalData3.push(["SL No", "Categories", "Count"]);

        finalData3.push(
            [
                props.t("1"),
                props.t("Text Messages"),
                msgReport[0].message
            ],
            [
                props.t("2"),
                props.t("Alert"),
                msgReport[0].alert
            ],
            [
                props.t("3"),
                props.t("Assignment"),
                msgReport[0].assignment
            ],
            [
                props.t("4"),
                props.t("Notice"),
                msgReport[0].notice
            ],
            [
                props.t("5"),
                props.t("Diary Messages"),
                msgReport[0].diaryMsg
            ],
            [
                props.t("6"),
                props.t("Attendance(Checkin, Checkout)"),
                msgReport[0].attendance
            ],
            [
                props.t("7"),
                props.t("Payment"),
                msgReport[0].payment
            ],
            [
                props.t("8"),
                props.t("Query"),
                msgReport[0].query
            ],
            [
                props.t("9"),
                props.t("Attachments"),
                msgReport[0].attachment
            ],
            [
                props.t("10"),
                props.t("Photos"),
                album.length
            ],
            [
                props.t("11"),
                props.t("Videos"),
                msgReport[0].videos
            ]
        );

        finalData3.push([]);


        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData.concat(finalData2.concat(finalData3)));
        XLSX.utils.book_append_sheet(wb, ws, "Daywise summary Report");
        XLSX.writeFile(wb, fileName);


    }

    return (
        <React.Fragment>

            <Row className="bg-light ">
                <Col md="4" xs="12" xl="4" lg="4">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select Date :</Label>
                        <DatePicker
                            selected={selectedDay}
                            onChange={(date) => {
                                setSelectedDay(date);
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <div className="mt-2">
                        <div className="mt-3">
                            <button
                                className="btn btn-primary mt-4 mr-2"
                                onClick={getAttendanceReport}
                            >
                                {props.t("Generate")}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col>
                    <Button
                        className='btn float-end m-2'
                        color='info'
                        onClick={() => {
                            onClickDownload()
                        }}>Download <i className="bx bxs-download m-1" />
                    </Button>
                </Col>
            </Row>

            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "getting report data..." : "Preparing Report"}</Label>
                    </div>
                </Col>
            }

            {classWiseAttendanceReport.length > 0 && <div className="table-responsive m-2" data-pattern="priority-columns">
                <CardTitle>Attendance Report</CardTitle>
                <Table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th className="text-center">{props.t("Classroom")}</th>
                            <th className="text-center">{props.t("Total Strength")}</th>
                            <th className="text-center">{props.t("Check in's")}</th>
                            <th className="text-center">{props.t("Check Out")}</th>
                            <th className="text-center">{props.t("Absent")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {classWiseAttendanceReport.map((val, key) => (
                            <tr className="text-center" key={key}>
                                <td>{val.classRoomName}</td>
                                <td>{val.total}</td>
                                <td>{val.checkIn}</td>
                                <td>{val.checkOut}</td>
                                <td>{val.totalAbsents}</td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </div>}

            {diaryReport.length > 0 && <div className="table-responsive m-2" data-pattern="priority-columns">
                <CardTitle>Daycare Diary Report</CardTitle>
                <Table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th className="text-center">{props.t("SL NO")}</th>
                            <th className="text-center">{props.t("Categories")}</th>
                            <th className="text-center">{props.t("Students")}</th>
                            <th className="text-center">{props.t("Count")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {diaryReport.map((val, key) => (
                            <tr className="text-center" key={key}>
                                <td>{key + 1}</td>
                                <td style={{ textAlign: 'left' }}>{val.routine}</td>
                                <td>{val.childCount}</td>
                                <td>{val.count}</td>
                            </tr>
                        ))}
                    </tbody>

                </Table>
            </div>}

            {msgReport.length > 0 && <div className="table-responsive m-2" data-pattern="priority-columns">
                <CardTitle>Messages Report</CardTitle>
                <Table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            <th className="text-center">{props.t("SL NO")}</th>
                            <th className="text-center">{props.t("Categories")}</th>
                            <th className="text-center">{props.t("Count")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th className="text-center">{props.t("1")}</th>
                            <td className="text-left">{props.t("Text Messages")}</td>
                            <td className="text-center">{msgReport[0].message}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("2")}</th>
                            <td className="text-left">{props.t("Alert")}</td>
                            <td className="text-center">{msgReport[0].alert}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("3")}</th>
                            <td className="text-left">{props.t("Assignment")}</td>
                            <td className="text-center">{msgReport[0].assignment}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("4")}</th>
                            <td className="text-left">{props.t("Notice")}</td>
                            <td className="text-center">{msgReport[0].notice}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("5")}</th>
                            <td className="text-left">{props.t("Diary Messages")}</td>
                            <td className="text-center">{msgReport[0].diaryMsg}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("6")}</th>
                            <td className="text-left">{props.t("Attendance(Checkin, Checkout)")}</td>
                            <td className="text-center">{msgReport[0].attendance}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("7")}</th>
                            <td className="text-left">{props.t("Payment")}</td>
                            <td className="text-center">{msgReport[0].payment}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("8")}</th>
                            <td className="text-left">{props.t("Query")}</td>
                            <td className="text-center">{msgReport[0].query}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("9")}</th>
                            <td className="text-left">{props.t("Attachments")}</td>
                            <td className="text-center">{msgReport[0].attachment}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("10")}</th>
                            <td className="text-left">{props.t("Photos")}</td>
                            <td className="text-center">{album.length}</td>
                        </tr>
                        <tr>
                            <th className="text-center">{props.t("11")}</th>
                            <td className="text-left">{props.t("Videos")}</td>
                            <td className="text-center">{msgReport[0].videos}</td>
                        </tr>
                    </tbody>

                </Table>
            </div>}
        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners } = state.CorporatePartners;
    const { teachers } = state.Teacher;
    const { classrooms } = state.Classroom;
    const { kids } = state.Kid;
    const {
        teacherAttendanceList,
        gotAttendance,
        kidAttendanceList,
        daycareAttendance,
    } = state.Attendance;
    return {
        schools, selectedSchool,
        franchise,
        corporatePartners,
        teacherAttendanceList,
        gotAttendance,
        kidAttendanceList,
        daycareAttendance,
        teachers,
        classrooms,
        kids,
    };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getSchoolForInstitute,
            setSelecetdSchool,
            getCorporatePartners,
            getTeachersForSchool,
            getClassroomsForSchoolId,
            getAllKids,
            getAttendanceForTeacherIds,
            getAttendanceForKidIds,
            getDaycareAttendanceForKidIds,
        })(DaywiseSummaryReport)
    )
);