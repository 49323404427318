import { takeEvery, fork, put, all, call, takeLatest } from "redux-saga/effects";
import * as ClassroomHelper from "../../../helpers/ParseHelpers/classroom_helper";
import * as actions from "./actionTypes";
import * as Constants from '../../../Constents';
import  * as ClassroomActions from './actions';
import Parse from 'parse';
import school from "../school/reducer";

function* getAClassroom({ payload: { classRoomId } }) {
  try {
    const response = yield call(ClassroomHelper.getClassRoomFromParse, classRoomId);
    yield put(ClassroomActions.addOrupdateClassRoom(response));
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAClassroom({ payload: { classRoomId } }) {
  try {
    if (classRoomId) {
      const response = yield call(ClassroomHelper.deleteClassRoomFromParse, classRoomId);
    
      if (response) {
        response.unPin()
        yield put(ClassroomActions.setClassRoomDelete(response,true));
      } else {
        //not deleted
      }
    } else {
      yield put(ClassroomActions.setClassRoomDelete(undefined,false));
    }
    
  } catch (error) {
    console.log(error);
  }
}
function* updateAClassRoom({ payload : {classObj }}){
  try{
    const response = yield call(ClassroomHelper.updateAClassroomObj, classObj)
    response.pin()
  }catch(error){}
}

function* getClassroomsForSchoolId({ payload: { schoolId } }) {
  try {
    var key = Constants.CLASSROOM_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();   
    
    const localResponse = yield call(ClassroomHelper.getClassroomsForSchoolId, schoolId, null, true);
    yield put(ClassroomActions.updateclassrooms(localResponse));

    const serverResponse = yield call(ClassroomHelper.getClassroomsForSchoolId, schoolId, updatedTime, false);
    if (serverResponse && serverResponse.length > 0) {
      localStorage.setItem(key, newTime)
      yield put(ClassroomActions.updateclassrooms(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* checkForDaycareSaga ({ payload: {schoolId,classLevel,successMsg,errorMsg} }) {
  try {
    const response = yield call(ClassroomHelper.checkForDaycareParse, schoolId,classLevel);
    if (response) {
      //yield put(GroupMapActions.updateResultGroupMaps(response));
      if (successMsg && successMsg.length > 0) {
        //toastr.success(successMsg, { timeOut: 2000 })
      }
      
    } else {
      if (errorMsg && errorMsg.length > 0) {
        //toastr.success(errorMsg, { timeOut: 2000 })
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}



function* getClassroomsForSchoolIds({ payload : {schoolIds}}){
  try{
    const response = yield call(ClassroomHelper.getClassroomForSchoolIdsParse, schoolIds)
    yield put(ClassroomActions.updateclassrooms(response));
  }catch(error){}
}
function* createNewClassRoom({payload : {classDetails}}){
  try{
    const response = yield call(ClassroomHelper.createNewClassRoom, classDetails)
    if (response) {
      response.pin()
      yield put(ClassroomActions.addOrupdateClassRoom(response))
    }
    
  }catch(error){}
}

export function* watchCheckForDaycareSaga() {
  yield takeEvery(actions.CHECK_FOR_DAYCARE, checkForDaycareSaga);
}


export function* watchGetClassroom() {
  yield takeEvery(actions.UPDATE_CLASSROOM, getAClassroom);
}

export function* watchDeleteClassroom() {
  yield takeEvery(actions.DELETE_CLASSROOM, deleteAClassroom);
}
export function* watchGetClassroomsForSchoolId() {
  yield takeLatest(actions.GET_CLASSROOMS, getClassroomsForSchoolId);
}
export function* watchGetClassroomsForSchoolIds() {
  yield takeEvery(actions.GET_CLASSROOM_FOR_SCHOOLIDS, getClassroomsForSchoolIds);
}
export function* watchCreateNewClass(){
  yield takeEvery(actions.CREATE_NEW_CLASS, createNewClassRoom)
}
export function* watchUpdateAClassRoom(){
  yield takeEvery(actions.UPDATE_A_CLASSROOM_OBJ, updateAClassRoom)
}
function* classroomSaga() {
  yield all([
    fork(watchGetClassroom),
    fork(watchDeleteClassroom),
    fork(watchGetClassroomsForSchoolId),
    fork(watchGetClassroomsForSchoolIds),
    fork(watchCreateNewClass),
    fork(watchUpdateAClassRoom),
    fork(watchCheckForDaycareSaga),
  ]);
}
export default classroomSaga;
