import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Label,
    UncontrolledTooltip,
    Col,
    Row,
    Progress,
} from "reactstrap";
import Parse from "parse";
import React, { useEffect, useState } from "react";

const UpdateFiles = (props) => {
    const [answerFile, setAnswerFile] = useState(undefined);
    const [answerFileUploadProgress, setAnswerFileUploadProgress] = useState(
        undefined
    );
    const [deleteInProgress, setDeleteInProgress] = useState(false);

    useEffect(() => {
        if (props.initialFile != undefined) {
            let name = props.initialFile._name.split(/_(.+)/)[1];
            let fullNameList = name.split(".");

            let tempFile = {
                actualFileName: name,
                fileExtension: fullNameList[1].toLowerCase(),
                fileName: fullNameList[0],
                file: props.initialFile,
            };
            setAnswerFileUploadProgress(100);
            setAnswerFile(tempFile);
        }
    }, []);

    const inputStyle = {
        width: 0.1,
        height: 0.1,
        opacity: 0,
        overflow: "hidden",
        position: "absolute",
        zIndex: -1,
    };

    function getFileTypeImage(fileExtension) {
        if (fileExtension === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtension === "ppt" || fileExtension === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtension === "doc" || fileExtension === "docx") {
            return "bx bxs-file-doc";
        }
        return "bx bxs-file-blank";
    }
    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            var resultWithName = { name: file.name, result: reader.result };
            cb(resultWithName);
        };
        reader.onerror = function (error) {
            console.log("Error: ", error);
        };
    }

    //on Doc Upload
    function handleFileUploadChange(e) {
        setAnswerFile(undefined);
        setAnswerFileUploadProgress(1);
        let fullNameList = e.target.files[0].name.split(".");
        var name = props.fileName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");
        let tempFile = {
            actualFileName: name + "." + fullNameList[1].toLowerCase(),
            fileExtension: fullNameList[1].toLowerCase(),
            fileName: name,
            file: e.target.files[0],
        };
        getBase64(tempFile.file, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result });
            parseFile
                .save({
                    progress: (value) => {
                        if (value && value > 0.1) {
                            setAnswerFileUploadProgress(value * 100);
                        }
                    },
                })
                .then(
                    (fileResult) => {
                        if (props.documentParam) {
                            const SchoolDocuments = Parse.Object.extend('SchoolDocuments');
                            var schoolDocuments = props.schoolDocObject ?? new SchoolDocuments();
                            schoolDocuments.set("file", fileResult);
                            schoolDocuments.set("documentId", props.documentParam.id);
                            schoolDocuments.set("documentName", props.documentParam.attributes.documentName ?? "")
                            schoolDocuments.set("schoolId", props.schoolId)
                            schoolDocuments.set("franchiseId", props.franchiseId)
                            schoolDocuments.save().then(
                                (result) => {
                                    tempFile.file = fileResult;
                                    tempFile.uploadInProgress = undefined;
                                    setAnswerFile(tempFile);
                                    props.onFileChange(tempFile, result);
                                },
                                (error) => {

                                }
                            );
                        }
                    },
                    (error) => {
                    }
                );
        });
        setAnswerFile({ actualFileName: tempFile.actualFileName, uploadInProgress: true });
    }

    return (
        <React.Fragment>
            {answerFile == undefined && (
                <div className="d-flex">
                    <Label
                        htmlFor={"input" + props.fileUploadKey}
                        color="primary"
                        className="btn btn-primary"
                    >
                        Upload
                        <input
                            id={"input" + props.fileUploadKey}
                            style={inputStyle}
                            type="file"
                            onChange={(e) => {
                                handleFileUploadChange(e);
                            }}
                            accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                        />
                    </Label>
                </div>
            )}

            {answerFile && answerFile.uploadInProgress == undefined && answerFileUploadProgress === 100 && (
                <Col xl="12" md="12" sm="12" xs="12" className="pl-0 pr-0">
                    <div className="border p-3">
                        <Row>
                            {props.showIcon && <div
                                className="avatar ml-3"
                                style={{ width: "5rem", height: "5rem" }}
                            >
                                <span
                                    className="avatar-title bg-white ml-0 text-primary h1"
                                    style={{ fontSize: "60px" }}
                                >
                                    <i className={getFileTypeImage(answerFile.fileExtension)}></i>
                                </span>
                            </div>}

                            <div className="float-right ml-4" style={{ width: "200px" }}>
                                <div className="text-muted font-weight-bold">
                                    {answerFile.actualFileName}
                                </div>
                                <div className="d-flex mt-3">
                                    <Button
                                        className="btn btn-primary m-1"
                                        color="primary"
                                        onClick={() => {
                                            props.onClickViewDoc(answerFile.file)
                                        }}
                                    >
                                        View
                                    </Button>
                                    <div>
                                        <Label
                                            htmlFor={"inputUpdate" + props.fileUploadKey}
                                            color="warning"
                                            className="btn btn-warning float-right mb-0 m-1"
                                        >
                                            Update
                                            <input
                                                id={"inputUpdate" + props.fileUploadKey}
                                                style={inputStyle}
                                                type="file"
                                                onChange={(e) => {
                                                    handleFileUploadChange(e);
                                                }}
                                                accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                                            />
                                        </Label>
                                    </div>

                                    <Button
                                        className="btn-danger float-right m-1"
                                        onClick={() => {
                                            if (props.schoolDocObject) {
                                                setDeleteInProgress(true)
                                                props.schoolDocObject.set("isDeleted", true)
                                                props.schoolDocObject.save().then(
                                                    (result) => {
                                                        setDeleteInProgress(false)
                                                        setAnswerFile(undefined);
                                                        setAnswerFileUploadProgress(0);
                                                        props.onFileChange(undefined);
                                                    },
                                                    (error) => {

                                                    }
                                                );
                                            }
                                        }}
                                    >
                                        {deleteInProgress && <i className="bx bx-loader bx-spin font-size-18 align-middle"></i>}
                                        {!deleteInProgress && "Delete"}
                                    </Button>
                                </div>
                            </div>
                        </Row>
                    </div>
                </Col>
            )}

            {answerFile &&
                (answerFile.uploadInProgress || (answerFileUploadProgress >= 1 &&
                    answerFileUploadProgress < 100)) && (
                    <div className="d-flex">
                        <Col xl="12" md="12" sm="12" xs="12" className="ml-0 pl-0 pr-0">
                            <div className="border p-3">
                                <Row>
                                    <div
                                        className="avatar ml-3"
                                        style={{ width: "5rem", height: "5rem" }}
                                    >
                                        <span className="avatar-title bg-white ml-0 text-primary h1">
                                            <i
                                                className={getFileTypeImage(answerFile.fileExtension)}
                                            ></i>
                                        </span>
                                    </div>
                                    <div className="float-right ml-4" style={{ width: "200px" }}>
                                        <div className="text-muted font-weight-bold">
                                            {answerFile.actualFileName}
                                        </div>
                                        <div className=" mt-3">
                                            <Progress
                                                color="primary"
                                                value={answerFileUploadProgress}
                                            ></Progress>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    </div>
                )}
        </React.Fragment>
    );
};

export default UpdateFiles;
