import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
  TabContent,
  TabPane,
  NavItem,
  NavLink,
  Progress,
  CustomInput,
} from "reactstrap";
import moment from "moment";
import * as Scroll from "react-scroll";
import toastr from "toastr";

import {
  Element,
  Events,
  animateScroll as scroll,
  scrollSpy,
  scroller,
} from "react-scroll";
import classnames from "classnames";
import UpdatePhoto from "../../components/Common/UpdatePhoto";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";

import * as Constants from "../../Constents";

import { editSchool } from "../../store/actions";
import { change } from "redux-form";

const EditAdmissionForm = (props) => {
  const next = "NEXT";
  const previous = "PREVIOUS";

  const match = matchPath(props.history.location.pathname, {
    path: "/admission-form/:schoolId/:enquiryId",
    exact: true,
    strict: false,
  });

  const matchKidId = matchPath(props.history.location.pathname, {
    path: "/admission-form/:schoolId/:kidId/:type",
    exact: true,
    strict: false,
  });
  const franchiseMatchKidId = matchPath(props.history.location.pathname, {
    path: "/franchise-admission-form/:franchiseId/:schoolId/:kidId/:type",
    exact: true,
    strict: false,
  });

  const franchiseMatch = matchPath(props.history.location.pathname, {
    path: "/franchise-admission-form/:franchiseId/:schoolId/:enquiryId",
    exact: true,
    strict: false,
  });

  const matchInsideDashboard = matchPath(props.history.location.pathname, {
    path: "/editadmissioninside/:schoolId/:enquiryId",
    exact: true,
    strict: false,
  });

  var parameter = match && match.params.schoolId;
  var enquiryId = match && match.params.enquiryId;
  var franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;
  var kidId = matchKidId && matchKidId.params.kidId;

  if (match) {
    parameter = match && match.params.schoolId;
    enquiryId = match && match.params.enquiryId;
  } else if (matchInsideDashboard) {
    parameter = matchInsideDashboard.params.schoolId;
    enquiryId = matchInsideDashboard.params.enquiryId;
  } else if (franchiseMatch) {
    parameter = franchiseMatch.params.schoolId;
    enquiryId = franchiseMatch.params.enquiryId;
  } else if (matchKidId) {
    parameter = matchKidId.params.schoolId;
  } else if (franchiseMatchKidId) {
    parameter = franchiseMatchKidId.params.schoolId;
    kidId = franchiseMatchKidId.params.kidId
    franchiseId = franchiseMatchKidId.params.franchiseId
  }

  //SchoolId is parameter

  const [stateSchoolObject, setStateSchoolObject] = useState(undefined);
  const [admissionTitleList, setAdmissionTitleList] = useState([]);

  const [admissionDataList, setAdmissionDataList] = useState([])

  const [stateEnquiryObject, setStateEnquiryObject] = useState(undefined);
  const [stateMotherObject, setStateMotherObject] = useState(undefined);
  const [stateFatherObject, setStateFatherObject] = useState(undefined);
  const [stateKidObject, setStateKidObject] = useState(undefined);
  const [
    stateChildAdditionalDetailsObject,
    setStateChildAdditionalDetailsObject,
  ] = useState(undefined);

  const [isFormSubmitted, setIsFormSubmitted] = useState(false);
  const [isQuestionsAvailable, setIsQuestionsAvailable] = useState(true);

  const [cardMessage, setCardMessage] = useState("");
  const [cardIcon, setCardIcon] = useState("fa fa-check text-success mr-2");

  const [isLoading, setIsLoading] = useState(false);
  const [formIsSubmites, formIsSubmitesOps] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [formSubmissionDate,setFormSubmissionDate] = useState(undefined)

  var childAdditionalDetailsObject = null;
  var fatherObject = null;
  var motherObject = null;
  var enquiryObject = null;
  var kidObject = null;
  var abortSubmitForm = false;

  const [activeTab, setactiveTab] = useState(0);
  const [progressValue, setprogressValue] = useState(0);

  useEffect(() => {
    if (enquiryId) {
      fetchEnquiryObject(enquiryId);
    }
  }, [enquiryId]);

  useEffect(() => {
    if (kidId) {
      fetchKidFlow()
    }
  }, [kidId])

  const fetchKidFlow = () => {
    Parse.Cloud.run("function_getAdmissionDetailsForForm", {
      schoolId: parameter,
      kidId: kidId,
    }).then(
      (result) => {
        if (result) {
          if (result.school != null) {
            setStateSchoolObject(result.school);
          } else {
            //Change UI for invalid url
            setIsFormSubmitted(true);
            setCardMessage("Invalid URL !!");
            setCardIcon("fas fa-exclamation-triangle text-danger mr-2");
            return null;
          }

          if (result.enquiry != null) {
            enquiryObject = result.enquiry;
            setStateEnquiryObject(result.enquiry);
          }
          if (result.kid != null) {
            if (result.kid.attributes.admissionFormSubmittedOn) {
              //Change UI
              setIsFormSubmitted(true);
              setCardMessage("Form is already submitted !!");
              return
            }
            kidObject = result.kid
            setStateKidObject(result.kid);
          }

          if (result.parents != null && result.parents.length > 0) {
            for (const parent of result.parents) {
              if (parent.attributes.Relation === "Mother") {
                motherObject = parent;
                setStateMotherObject(parent);
              } else if (parent.attributes.Relation === "Father") {
                fatherObject = parent;
                setStateFatherObject(parent);
              }
            }
          }

          if (result.childAdditionalDetails) {
            childAdditionalDetailsObject = result.childAdditionalDetails;
            setStateChildAdditionalDetailsObject(result.childAdditionalDetails);
          }

          setIsLoading(false);
          if (result.kid != null) {
            if (!result.kid.attributes.admissionFormSubmittedOn) {
              fetchAdmissionTitleQuestionsForSchoolId(parameter, result.school);

            }

          }
        }
      },
      (error) => {
        setIsFormSubmitted(true);
        setCardMessage("Invalid URL !!");
        setCardIcon("fas fa-exclamation-triangle text-danger mr-2");
        return null;
      }
    );
  }

  const fetchEnquiryObject = (enquiryId) => {
    Parse.Cloud.run("function_getEnquiryObject", {
      enquiryId: enquiryId,
    }).then(
      (result) => {
        if (result != null) {
          fetchSchoolFromServer(parameter);

        } else {
          //Change UI
          setIsFormSubmitted(true);
          setCardMessage("Form is already submitted !!");
        }
      },
      (error) => {
        console.log(error)
      }
    );
  };

  const fetchSchoolFromServer = (schoolId) => {
    Parse.Cloud.run("function_getAdmissionDetailsForForm", {
      schoolId: schoolId,
      enquiryId: enquiryId,
    }).then(
      (result) => {
        if (result) {
          if (result.school != null) {
            setStateSchoolObject(result.school);
          } else {
            //Change UI for invalid url
            setIsFormSubmitted(true);
            setCardMessage("Invalid URL !!");
            setCardIcon("fas fa-exclamation-triangle text-danger mr-2");
            return null;
          }

          if (result.enquiry != null) {
            enquiryObject = result.enquiry;
            setStateEnquiryObject(result.enquiry);
          }
          if (result.kid != null) {


            kidObject = result.kid
            setStateKidObject(result.kid);

          }

          if (result.parents != null && result.parents.length > 0) {
            for (const parent of result.parents) {
              if (parent.attributes.Relation === "Mother") {
                motherObject = parent;
                setStateMotherObject(parent);
              } else if (parent.attributes.Relation === "Father") {
                fatherObject = parent;
                setStateFatherObject(parent);
              }
            }
          }

          if (result.childAdditionalDetails) {
            childAdditionalDetailsObject = result.childAdditionalDetails;
            setStateChildAdditionalDetailsObject(result.childAdditionalDetails);
          }

          setIsLoading(false);

          fetchAdmissionTitleQuestionsForSchoolId(parameter, result.school);
        }
      },
      (error) => {
        return null;
      }
    );
  };

  // const fetchKidForEnquiryId = (enquiryId) => {
  //   var query = new Parse.Query("Kid");
  //   query.equalTo("enquiryId", enquiryId);
  //   query.first().then((result) => {

  //     if (result != null) {

  //       var tempObject = result
  //       kidObject = tempObject
  //       setStateKidObject(tempObject)

  //       //fetchParentsObjectForFamilyId(tempObject.attributes.FamilyID, results[0])
  //     }
  //   });
  // }

  // const fetchParentsObjectForFamilyId = (familyId, kidObj) => {
  //   //setKidObject(kidObj)
  //   var query = new Parse.Query("Parent");
  //   query.equalTo("FamilyID", familyId);
  //   query.find().then((results) => {
  //     console.log("parent")

  //     if (results != null && results.length > 0) {

  //       var i = 0

  //       for (i = 0; i < results.length; i++) {
  //         if (results[i].attributes.Relation === "Mother") {
  //           motherObject = results[i]
  //           setStateMotherObject(results[i]);

  //         } else if (results[i].attributes.Relation === "Father") {
  //           fatherObject = results[i]
  //           setStateFatherObject(results[i]);

  //         }
  //       }

  //     }
  //     fetchChildAdditionalDetailsObject(kidObj.id)

  //   });
  // }

  // const fetchChildAdditionalDetailsObject = (enquiryId) => {
  //   var query = new Parse.Query("ChildAdditionalDetails");
  //   query.equalTo("enquiryId", enquiryId);
  //   query.find().then((results) => {
  //     console.log("childAdditionalDetails")

  //     if (results != null && results.length > 0) {

  //       var tempObject = results[0]
  //       childAdditionalDetailsObject = tempObject
  //       setStateChildAdditionalDetailsObject(tempObject)
  //     }

  //     console.log("final")
  //     console.log(enquiryObject, kidObject, motherObject, fatherObject, childAdditionalDetailsObject)
  //     setIsLoading(false)
  //     fetchAdmissionTitleQuestionsForSchoolId(parameter)

  //   });
  // }

  const fillInitialValuesForAdmissionData = (formQuestionObject, formDataResults) => {
    var objectToReturn = formQuestionObject;
    if (formDataResults && formDataResults.length > 0) {
      let tempAnswer;
      let tempFormDataAnswer = formDataResults.filter(
        (fd) =>
          fd.attributes.questionId === objectToReturn.admissionQuestionParseObject.id
      );
      if (tempFormDataAnswer && tempFormDataAnswer.length > 0) {
        tempAnswer = tempFormDataAnswer[0].attributes.answer;
      }
      if (
        objectToReturn.questionType === Constants.QESTION_TEXT_SINGLE_LINE ||
        objectToReturn.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
        objectToReturn.questionType === Constants.QUESTION_EMAIL ||
        objectToReturn.questionType === Constants.QUESTION_PHONE_NUMBER
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }

        if (result) {
          objectToReturn.answerText = result;
        }
      } else if (objectToReturn.questionType === Constants.QESTION_NUMBER) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerNumber = result;
        }
      } else if (objectToReturn.questionType === Constants.QESTION_CHECKBOX) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerBool = result;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_NO_COMMENTS
      ) {
        var result = undefined;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result != undefined) {
          objectToReturn.answerBool = result;
        }
      } else if (
        objectToReturn.questionType ===
        Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      } else if (
        objectToReturn.questionType ===
        Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      } else if (objectToReturn.questionType === Constants.QESTION_DATE) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerDate = result;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES
      ) {
        var result1 = undefined;
        var result2 = undefined;
        if (tempAnswer && tempAnswer.length > 0) {

          result1 = tempAnswer[0];
          if (tempAnswer.length > 1) {
            result2 = tempAnswer[1];
          }

        }
        if (result1 != undefined) {
          objectToReturn.answerBool = result1;

        }
        if (result2 && result2.length > 0) {
          objectToReturn.answerText = result2;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO
      ) {
        var result1 = undefined;
        var result2 = undefined;
        if (tempAnswer && tempAnswer.length > 0) {

          result1 = tempAnswer[0];
          if (tempAnswer.length > 1) {
            result2 = tempAnswer[1];
          }

        }
        if (result1 != undefined) {
          objectToReturn.answerBool = result1;

        }
        if (result2 && result2.length > 0) {
          objectToReturn.answerText = result2;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_YES_NO_WITH_COMMENT
      ) {
      } else if (
        objectToReturn.questionType === Constants.QESTION_UPLOAD_IMAGE
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }

        if (result) {
          let name = result._name.split(/_(.+)/)[1];
          let fileExtension = name.split(".");
          let tempFile = {
            actualImageName: name,
            fileExtension: fileExtension[1].toLowerCase(),
            imageName: fileExtension[0],
            image: result,
            imageURL: result._url,
          };
          objectToReturn.answerImageUploadProgress = 100;
          objectToReturn.answerImage = tempFile;
        }
      } else if (
        objectToReturn.questionType === Constants.QESTION_UPLOAD_DOCUMENT
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          let name = result._name.split(/_(.+)/)[1];
          let fileExtension = name.split(".");

          let tempFile = {
            actualFileName: name,
            fileExtension: fileExtension[1].toLowerCase(),
            fileName: fileExtension[0],
            file: result,
          };

          objectToReturn.answerFileUploadProgress = 100;
          objectToReturn.answerFile = tempFile;
        }
      }
    }

    return objectToReturn;
  };

  const getAdmissionDataResults = (admissionTitleList, questionIds) => {
    var AdmissionData = Parse.Object.extend("AdmissionData");
    var query = new Parse.Query(AdmissionData);
    if (enquiryId && enquiryId.length > 0) {
      query.equalTo("enquiryId", enquiryId);
    } else {
      query.equalTo("kidId", kidId);

    }
    query.containedIn("questionId", questionIds)





    query.limit = 1000;
    query.find().then(
      (results) => {
        if (results && results.length > 0) {
          setAdmissionDataList(results)
          let tempMasterList = [...admissionTitleList]

          for (var i = 0; i < tempMasterList.length; i++) {

            for (var j = 0; j < tempMasterList[i].admissionQuestionsList.length; j++) {
              if (tempMasterList[i].admissionQuestionsList[j].admissionQuestionParseObject.attributes.databaseTableName === "AdmissionData") {
                tempMasterList[i].admissionQuestionsList[j] = fillInitialValuesForAdmissionData(tempMasterList[i].admissionQuestionsList[j], results)
              }
            }

          }
          setAdmissionTitleList(tempMasterList)



        }


      },
      (error) => {

      }
    );
  }

  const fetchAdmissionTitleQuestionsForSchoolId = (schoolId, school) => {
    var query = new Parse.Query("AdmissionTitle");
    if (franchiseId && franchiseId.length > 0) {
      if (franchiseId !== school.attributes.franchiseId) {
        setIsFormSubmitted(true);
        setCardMessage("Invalid URL !!");
        setCardIcon("fas fa-exclamation-triangle text-danger mr-2");
        return;
      }
      query.equalTo("franchiseId", franchiseId);
    } else {
      query.equalTo("schoolId", schoolId);
    }
    query.ascending("pageNumber");
    query.notEqualTo("isDeleted", true);

    query.find().then((results) => {
     // console.log("admissionTitle");

      if (results != null) {
        if (results.length > 0) {
         // console.log(results);

          var admissionTitleParseList = results;
          var titleIds = [];
          var i = 0;
          for (i = 0; i < admissionTitleParseList.length; i++) {
            titleIds.push(admissionTitleParseList[i].id);
          }
          fetchAdmissionQuestionsForTitleIds(admissionTitleParseList, titleIds);
        } else {
          setIsQuestionsAvailable(false);
        }
      }
    });
  };

  const fetchAdmissionQuestionsForTitleIds = (
    admissionTitleParseList,
    titleIds
  ) => {
    var query = new Parse.Query("AdmissionQuestion");
    query.containedIn("admissionTitleId", titleIds);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);

    query.find().then((results) => {
      var tempTitleList = [];

      if (results != null) {
        // console.log(results);
        var i = 0;
        let admissionQuestionIdsToFetch = []

        for (i = 0; i < admissionTitleParseList.length; i++) {
          var j = 0;
          var tempQuestionList = [];

          var customAdmissionTitleObject = new CustomAdmissionTitleObject();
          customAdmissionTitleObject.title =
            admissionTitleParseList[i].attributes.title ?? "";
          customAdmissionTitleObject.admissionTitleParseObject =
            admissionTitleParseList[i];

          for (j = 0; j < results.length; j++) {
            if (
              admissionTitleParseList[i].id ===
              results[j].attributes.admissionTitleId
            ) {

              if (results[j].attributes.databaseTableName === "AdmissionData") {
                admissionQuestionIdsToFetch.push(results[j].id)
              }

              var enqObject = results[j];

              var newEnquiryObject = new CustomAdmissionObject();
              newEnquiryObject.question = enqObject.attributes.question ?? "";
              newEnquiryObject.questionType =
                enqObject.attributes.questionType ?? "";
              newEnquiryObject.questionNumber = i + 1;
              if (enqObject.attributes.dataType) {
                newEnquiryObject.dataType = enqObject.attributes.dataType;
              }
              if (enqObject.attributes.choice) {
                newEnquiryObject.choices = [...enqObject.attributes.choice];
              }
              if (
                enqObject.attributes.isMandatory &&
                enqObject.attributes.isMandatory === true
              ) {
                newEnquiryObject.isMandatory = true;
              } else {
                newEnquiryObject.isMandatory = false;
              }
              newEnquiryObject.dataType = enqObject.attributes.dataType;
              if (
                enqObject.attributes.questionHint != null &&
                enqObject.attributes.questionHint !== ""
              ) {
                newEnquiryObject.questionHint =
                  enqObject.attributes.questionHint;
              } else {
                newEnquiryObject.questionHint = "Type here";
              }
              newEnquiryObject.answerChoices = [];

              if (
                enqObject.attributes.questionType &&
                (enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_COMMENT_ON_NO ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_COMMENT_ON_YES ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_NO_COMMENTS ||
                  enqObject.attributes.questionType ===
                  Constants.QESTION_YES_NO_WITH_COMMENT)
              ) {
                if (
                  enqObject.attributes.choice == null ||
                  !enqObject.attributes.choice.length > 0
                ) {
                  newEnquiryObject.choices = ["Yes", "No"];
                }
              }
              newEnquiryObject.answerError = false;

              newEnquiryObject.admissionQuestionParseObject = enqObject;

              tempQuestionList.push(fillInitialValues(newEnquiryObject));
            }
          }

          // console.log(tempQuestionList);

          customAdmissionTitleObject.admissionQuestionsList = tempQuestionList;

          tempTitleList.push(customAdmissionTitleObject);
        }
        setprogressValue(100 / tempTitleList.length);
        setAdmissionTitleList(tempTitleList);


        if (admissionQuestionIdsToFetch.length > 0) {
          getAdmissionDataResults(tempTitleList, admissionQuestionIdsToFetch)
        }
      }
    });
  };

  const checkForObjectTypeAndReturnAnswer = (questionObject) => {
    var databaseFieldName =
      questionObject.admissionQuestionParseObject.attributes.databaseFieldName;

    var databaseTableName =
      questionObject.admissionQuestionParseObject.attributes.databaseTableName;

    if (databaseTableName === "Enquiry") {
      if (
        enquiryObject &&
        enquiryObject.attributes[databaseFieldName] != null
      ) {
        return enquiryObject.attributes[databaseFieldName];
      }
    } else if (databaseTableName === "Kid") {
      if (
        kidObject &&
        kidObject.attributes[databaseFieldName] != null
      ) {
        return kidObject.attributes[databaseFieldName];
      }
    } else if (databaseTableName === "ChildAdditionalDetails") {
      if (
        childAdditionalDetailsObject &&
        childAdditionalDetailsObject.attributes[databaseFieldName] != null
      ) {
        return childAdditionalDetailsObject.attributes[databaseFieldName];
      }
    } else if (
      databaseTableName === "Parent" &&
      questionObject.admissionQuestionParseObject.attributes.objectType !=
      null &&
      questionObject.admissionQuestionParseObject.attributes.objectType === 1
    ) {
      if (motherObject && motherObject.attributes[databaseFieldName] != null) {
        return motherObject.attributes[databaseFieldName];
      }
    } else if (databaseTableName === "Parent") {
      if (
        questionObject.admissionQuestionParseObject.attributes.objectType !=
        null &&
        questionObject.admissionQuestionParseObject.attributes.objectType === 2
      ) {
        if (
          fatherObject &&
          fatherObject.attributes[databaseFieldName] != null
        ) {
          return fatherObject.attributes[databaseFieldName];
        }
      } else {
        if (
          fatherObject &&
          fatherObject.attributes[databaseFieldName] != null
        ) {
          return fatherObject.attributes[databaseFieldName];
        }
      }
    }

    return undefined;
  };

  const fillInitialValues = (admissionQuestionObject) => {
    var objectToReturn = admissionQuestionObject;

    if (
      objectToReturn.questionType === Constants.QESTION_TEXT_SINGLE_LINE ||
      objectToReturn.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
      objectToReturn.questionType === Constants.QUESTION_EMAIL ||
      objectToReturn.questionType === Constants.QUESTION_PHONE_NUMBER
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerText = result;
      }
    } else if (objectToReturn.questionType === Constants.QESTION_NUMBER) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerNumber = result;
      }
    } else if (objectToReturn.questionType === Constants.QESTION_CHECKBOX) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerBool = result;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result === true || result === false) {
        objectToReturn.answerBool = result;
      }
    } else if (
      objectToReturn.questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        if (Array.isArray(result) && result.length > 1) {
          objectToReturn.answerChoices = [result[0]];
        } else {
          if (Array.isArray(result)) {
            objectToReturn.answerChoices = [...result];
          } else {
            objectToReturn.answerChoices = [result];
          }
        }
      }
    } else if (
      objectToReturn.questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerChoices = [...result];
      }
    } else if (objectToReturn.questionType === Constants.QESTION_DATE) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        objectToReturn.answerDate = result;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerBool = true;
        objectToReturn.answerText = result;
      } else {
        objectToReturn.answerBool = false;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result && result.length > 0) {
        objectToReturn.answerBool = false;
        objectToReturn.answerText = result;
      } else {
        objectToReturn.answerBool = true;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (objectToReturn.questionType === Constants.QESTION_UPLOAD_IMAGE) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        let name = result._name.split(/_(.+)/)[1];
        let fileExtension = name.split(".");
        let tempFile = {
          actualImageName: name,
          fileExtension: fileExtension[1].toLowerCase(),
          imageName: fileExtension[0],
          image: result,
          imageURL: result._url,
        };
        objectToReturn.answerImageUploadProgress = 100;
        objectToReturn.answerImage = tempFile;
      }
    } else if (
      objectToReturn.questionType === Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      var result = checkForObjectTypeAndReturnAnswer(objectToReturn);
      if (result) {
        let name = result._name.split(/_(.+)/)[1];
        let fileExtension = name.split(".");

        let tempFile = {
          actualFileName: name,
          fileExtension: fileExtension[1].toLowerCase(),
          fileName: fileExtension[0],
          file: result,
        };

        objectToReturn.answerFileUploadProgress = 100;
        objectToReturn.answerFile = tempFile;
      }
    }

    return objectToReturn;
  };

  const inputStyle = {
    width: 0.1,
    height: 0.1,
    opacity: 0,
    overflow: "hidden",
    position: "absolute",
    zIndex: -1,
  };

  //on Doc Upload
  function handleFileUploadChange(
    e,
    index,
    admissionQuestionObject,
    admissionTitleObject
  ) {
    let questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    let titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    let tempQuestionObject = admissionQuestionObject;

    let tempTitleObject = admissionTitleList[titleIndex];
    tempQuestionObject.answerFile = undefined;
    tempQuestionObject.answerFileUploadProgress = 1;

    let tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    let tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);

    let fileExtension = e.target.files[0].name.split(".");
    let tempFile = {
      actualFileName: e.target.files[0].name,
      fileExtension: fileExtension[1].toLowerCase(),
      fileName: fileExtension[0],
      file: e.target.files[0],
    };

    var name =
      tempFile.fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')

    getBase64(tempFile.file, (resultWithName) => {
      var parseFile = new Parse.File(name, { base64: resultWithName.result });
      //setUploadProgress(1);
      parseFile
        .save({
          progress: (value) => {
            if (value && value > 0.1) {
              //setUploadProgress(value * 100);
              //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

              let tempQuestionObject = admissionQuestionObject;

              let tempTitleObjectFile = admissionTitleList[titleIndex];

              if (value == 1) {
                tempQuestionObject.answerFileUploadProgress = 99;
              } else {
                tempQuestionObject.answerFileUploadProgress = value * 100;
              }

              let tempQuestionList = [
                ...tempTitleObjectFile.admissionQuestionsList,
              ];

              tempQuestionList[questionIndex] = tempQuestionObject;

              tempTitleObjectFile.admissionQuestionsList = [...tempQuestionList];

              let tempTitleList = [...admissionTitleList];

              tempTitleList[titleIndex] = tempTitleObjectFile;

              setAdmissionTitleList(tempTitleList);
            }
          },
        })
        .then(
          (result) => {
            tempFile.file = result;

            let tempQuestionObject = admissionQuestionObject;
            tempQuestionObject.answerFileUploadProgress = 100;
            tempQuestionObject.answerFile = tempFile;
            tempQuestionObject.answerError = false;

            let tempTitleObjectFile = admissionTitleList[titleIndex];;

            let tempQuestionList = [...tempTitleObjectFile.admissionQuestionsList];

            tempQuestionList[questionIndex] = tempQuestionObject;

            tempTitleObjectFile.admissionQuestionsList = [...tempQuestionList];

            let tempTitleList = [...admissionTitleList];

            tempTitleList[titleIndex] = tempTitleObjectFile;

            setAdmissionTitleList(tempTitleList);
          },
          (error) => {
            //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
          }
        );
    });

    let tempQuestionObject1 = admissionQuestionObject;

    let tempTitleObject1 = admissionTitleObject;

    tempQuestionObject1.answerFile = {
      actualFileName: tempFile.actualFileName,
    };

    let tempQuestionList1 = [...tempTitleObject1.admissionQuestionsList];

    tempQuestionList1[questionIndex] = tempQuestionObject1;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList1];

    let tempTitleList1 = [...admissionTitleList];

    tempTitleList1[titleIndex] = tempTitleObject1;

    setAdmissionTitleList(tempTitleList1);

    // enqObject.answerFile = { actualFileName: tempFile.actualFileName };
    // enquiryQuestionsList[index] = enqObject;

    // var temp = [...enquiryQuestionsList];
    // temp[index] = enqObject;
    // setEnquiryQuestionsList(temp);
  }

  // On image upload
  function handleImageUploadChange(
    index,
    changedImageURL,
    imageFile,
    admissionQuestionObject,
    admissionTitleObject
  ) {
    let questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    let titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    let tempQuestionObject = admissionQuestionObject;

    let tempTitleObject = admissionTitleList[titleIndex];
    tempQuestionObject.answerImage = undefined;
    tempQuestionObject.answerImageUploadProgress = 1;

    let tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    let tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);

    let fileExtension = imageFile._name.split(".");
    let tempFile = {
      actualImageName: imageFile._name,
      fileExtension: fileExtension[1].toLowerCase(),
      imageName: fileExtension[0],
      image: imageFile,
      imageURL: undefined,
    };

    //var name = tempFile.imageName.replace(/[&\/\\#,+()$~%'":*?<>{}]/, "");

    tempFile.image
      .save({
        progress: (value) => {
          if (value && value > 0.1) {
            //setUploadProgress(value * 100);
            //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")

            let tempQuestionObject = admissionQuestionObject;
            let tempTitleObjectImage = admissionTitleList[titleIndex];

            if (value === 1) {
              tempQuestionObject.answerImageUploadProgress = 99;
            } else {
              tempQuestionObject.answerImageUploadProgress = value * 100;
            }


            let tempQuestionList = [...tempTitleObjectImage.admissionQuestionsList];

            tempQuestionList[questionIndex] = tempQuestionObject;

            tempTitleObjectImage.admissionQuestionsList = [...tempQuestionList];

            let tempTitleList = [...admissionTitleList];

            tempTitleList[titleIndex] = tempTitleObjectImage;

            setAdmissionTitleList(tempTitleList);
          }
        },
      })
      .then(
        (result) => {
          tempFile.imageURL = result._url;
          tempFile.image = result;

          let tempQuestionObject = admissionQuestionObject;
          tempQuestionObject.answerImageUploadProgress = 100;

          let tempTitleObjectImage = admissionTitleList[titleIndex];

          tempQuestionObject.answerImage = tempFile;
          tempQuestionObject.answerError = false;

          let tempQuestionList = [...tempTitleObjectImage.admissionQuestionsList];

          tempQuestionList[questionIndex] = tempQuestionObject;

          tempTitleObjectImage.admissionQuestionsList = [...tempQuestionList];

          let tempTitleList = [...admissionTitleList];

          tempTitleList[titleIndex] = tempTitleObjectImage;

          setAdmissionTitleList(tempTitleList);
        },
        (error) => {
          //setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
        }
      );

    // enqObject.answerImage = { actualImageName: tempFile.actualImageName };
    // enquiryQuestionsList[index] = enqObject;

    // var temp = [...enquiryQuestionsList];
    // temp[index] = enqObject;
    // setEnquiryQuestionsList(temp);

    let tempQuestionObject1 = admissionQuestionObject;

    let tempTitleObject1 = admissionTitleObject;

    tempQuestionObject1.answerImage = {
      actualImageName: tempFile.actualImageName,
    };
    tempQuestionObject1.answerImage.imageURL = changedImageURL;

    let tempQuestionList1 = [...tempTitleObject1.admissionQuestionsList];

    tempQuestionList1[questionIndex] = tempQuestionObject1;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList1];

    let tempTitleList1 = [...admissionTitleList];

    tempTitleList1[titleIndex] = tempTitleObject1;

    setAdmissionTitleList(tempTitleList1);
  }

  const componentForQuestionType = (
    admissionQuestionObject,
    admissionTitleObject,
    key
  ) => {
    var questionType = admissionQuestionObject.questionType;

    switch (questionType) {
      case Constants.QESTION_UPLOAD_DOCUMENT:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formFileUpload">
              {admissionQuestionObject.question}{" "}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formFileUpload" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}

            {admissionQuestionObject.answerFile == undefined && (
              <div className="d-flex">
                <Label
                  htmlFor={"input-file-admission" + key}
                  color="primary"
                  className="btn btn-primary"
                >
                  Upload
                  <input
                    id={"input-file-admission" + key}
                    style={inputStyle}
                    type="file"
                    onChange={(e) => {
                      handleFileUploadChange(
                        e,
                        key,
                        admissionQuestionObject,
                        admissionTitleObject
                      );
                    }}
                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                  />
                </Label>
              </div>
            )}

            {admissionQuestionObject.answerFileUploadProgress === 100 && (
              <Col xl="6" md="12" sm="12" xs="12">
                <div className="border p-3">
                  <Row>
                    <div
                      className="avatar ml-3"
                      style={{ width: "5rem", height: "5rem" }}
                    >
                      <span
                        className="avatar-title bg-white ml-0 text-primary h1"
                        style={{ fontSize: "60px" }}
                      >
                        <i
                          className={getFileTypeImage(
                            admissionQuestionObject.answerFile.fileExtension
                          )}
                        ></i>
                      </span>
                    </div>

                    <div
                      className="float-right ml-4"
                      style={{ width: "200px" }}
                    >
                      <div className="text-muted font-weight-bold">
                        {admissionQuestionObject.answerFile.actualFileName}
                      </div>
                      <div className="d-flex mt-3">
                        <div>
                          <Label
                            htmlFor={"input-file-enquiry-Update" + key}
                            color="primary"
                            className="btn btn-primary mb-0"
                          >
                            Update
                            <input
                              id={"input-file-enquiry-Update" + key}
                              style={inputStyle}
                              type="file"
                              onChange={(e) => {
                                handleFileUploadChange(
                                  e,
                                  key,
                                  admissionQuestionObject,
                                  admissionTitleObject
                                );
                              }}
                              accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,image/*"
                            />
                          </Label>
                        </div>

                        <Button
                          className="btn-danger float-right ml-2"
                          onClick={() => {
                            var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
                              admissionQuestionObject
                            );

                            var titleIndex = admissionTitleList.indexOf(
                              admissionTitleObject
                            );

                            var tempQuestionObject = admissionQuestionObject;

                            var tempTitleObject =
                              admissionTitleList[titleIndex];

                            tempQuestionObject.answerFile = undefined;
                            tempQuestionObject.answerFileUploadProgress = 0;

                            var tempQuestionList = [
                              ...tempTitleObject.admissionQuestionsList,
                            ];

                            tempQuestionList[
                              questionIndex
                            ] = tempQuestionObject;

                            tempTitleObject.admissionQuestionsList = [
                              ...tempQuestionList,
                            ];

                            var tempTitleList = [...admissionTitleList];

                            tempTitleList[titleIndex] = tempTitleObject;

                            setAdmissionTitleList(tempTitleList);
                          }}
                        >
                          Clear
                        </Button>
                      </div>
                    </div>
                  </Row>
                </div>
              </Col>
            )}

            {admissionQuestionObject.answerFile &&
              admissionQuestionObject.answerFileUploadProgress >= 1 &&
              admissionQuestionObject.answerFileUploadProgress < 99.9 && (
                <div className="d-flex">
                  <Col xl="6" md="12" sm="12" xs="12" className="ml-0">
                    <div className="border p-3">
                      <Row>
                        <div
                          className="avatar ml-3"
                          style={{ width: "5rem", height: "5rem" }}
                        >
                          <span className="avatar-title bg-white ml-0 text-primary h1">
                            <i
                              className={getFileTypeImage(
                                admissionQuestionObject.answerFile.fileExtension
                              )}
                            ></i>
                          </span>
                        </div>
                        <div
                          className="float-right ml-4"
                          style={{ width: "200px" }}
                        >
                          <div className="text-muted font-weight-bold">
                            {admissionQuestionObject.answerFile.actualFileName}
                          </div>
                          <div className=" mt-3">
                            <Progress
                              color="primary"
                              value={
                                admissionQuestionObject.answerFileUploadProgress
                              }
                            ></Progress>
                          </div>
                        </div>
                      </Row>
                    </div>
                  </Col>
                </div>
              )}
          </FormGroup>
        );
        break;

      case Constants.QESTION_UPLOAD_IMAGE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formImageUpload">
              {admissionQuestionObject.question}{" "}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formImageUpload" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            {admissionQuestionObject.answerImage &&
              admissionQuestionObject.answerImageUploadProgress === 100 && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div className="ml-3">
                        <img
                          style={{ width: "5rem", height: "5rem" }}
                          className="rounded-circle avatar"
                          src={admissionQuestionObject.answerImage.imageURL}
                          alt=""
                        />
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {admissionQuestionObject.answerImage.actualImageName}
                        </div>
                        <div className="d-flex mt-3">
                          <UpdatePhoto
                            id={"imageUpload" + key}
                            tempId={"imageUpload" + key}
                            className="p-1"
                            buttonTitle="Change"
                            isPopupOn={false}
                            onPicChange={(imageFile, url) => {
                              handleImageUploadChange(
                                key,
                                url,
                                imageFile,
                                admissionQuestionObject,
                                admissionTitleObject
                              );
                            }}
                            imageFileName={
                              admissionQuestionObject.answerImage
                                ? admissionQuestionObject.answerImage
                                  .actualImageName
                                : ""
                            }
                            initialImage={
                              admissionQuestionObject.answerImage.imageURL
                            }
                          ></UpdatePhoto>
                          <Button
                            className="btn-danger float-right ml-2"
                            onClick={() => {
                              var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
                                admissionQuestionObject
                              );

                              var titleIndex = admissionTitleList.indexOf(
                                admissionTitleObject
                              );

                              var tempQuestionObject = admissionQuestionObject;

                              var tempTitleObject =
                                admissionTitleList[titleIndex];

                              tempQuestionObject.answerImage = undefined;
                              tempQuestionObject.answerImageUploadProgress = 0;

                              var tempQuestionList = [
                                ...tempTitleObject.admissionQuestionsList,
                              ];

                              tempQuestionList[
                                questionIndex
                              ] = tempQuestionObject;

                              tempTitleObject.admissionQuestionsList = [
                                ...tempQuestionList,
                              ];

                              var tempTitleList = [...admissionTitleList];

                              tempTitleList[titleIndex] = tempTitleObject;

                              setAdmissionTitleList(tempTitleList);
                            }}
                          >
                            Clear
                          </Button>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {admissionQuestionObject.answerImage &&
              admissionQuestionObject.answerImageUploadProgress >= 1 &&
              admissionQuestionObject.answerImageUploadProgress < 100 && (
                <Col xl="6" md="12" sm="12" xs="12">
                  <div className="border p-3">
                    <Row>
                      <div
                        className="avatar ml-3"
                        style={{ width: "5rem", height: "5rem" }}
                      >
                        <span
                          className={
                            "avatar-title rounded-circle bg-soft-primary text-primary"
                          }
                          style={{ fontSize: "60px" }}
                        ></span>
                      </div>
                      <div
                        className="float-right ml-4"
                        style={{ width: "200px" }}
                      >
                        <div className="text-muted font-weight-bold">
                          {admissionQuestionObject.answerImage.actualImageName}
                        </div>
                        <div className=" mt-3">
                          <Progress
                            color="primary"
                            value={
                              admissionQuestionObject.answerImageUploadProgress
                            }
                          ></Progress>
                        </div>
                      </div>
                    </Row>
                  </div>
                </Col>
              )}
            {admissionQuestionObject.answerImage == undefined && (
              <div className="d-flex">
                <div>
                  {" "}
                  <UpdatePhoto
                    id={"imageUploadMain" + key}
                    tempId={"imageUploadMain" + key}
                    className="p-1"
                    isPopupOn={false}
                    onPicChange={(imageFile, url) => {
                      handleImageUploadChange(
                        key,
                        url,
                        imageFile,
                        admissionQuestionObject,
                        admissionTitleObject
                      );
                    }}
                    initialImage={""}
                  ></UpdatePhoto>
                </div>
              </div>
            )}
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_SINGLE_LINE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formSingleLine">
              {admissionQuestionObject.question}{" "}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="text"
              name={"singleLine" + key}
              placeholder={admissionQuestionObject.questionHint}
              className="form-control"
              value={admissionQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );

        break;

      case Constants.QESTION_TEXT_MULTI_LINE:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formMultiLine">
              {admissionQuestionObject.question}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="textarea"
              name={"multiLine" + key}
              className="form-control"
              rows="3"
              value={admissionQuestionObject.answerText}
              placeholder={admissionQuestionObject.questionHint}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QESTION_NUMBER:
        return (
          <FormGroup key={key}>
            <Label>{admissionQuestionObject.question}</Label>
            {admissionQuestionObject.isMandatory && (
              <Label htmlFor="formSingleLine" className="text-danger">
                *
              </Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="number"
              name={"numberInput" + key}
              className="inner form-control noscroll"
              placeholder={props.t(admissionQuestionObject.questionHint)}
              value={admissionQuestionObject.answerNumber}
              onChange={(e) => {
                handleNumberOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
            />
          </FormGroup>
        );
        break;

      case Constants.QESTION_CHECKBOX:
        return (
          <FormGroup key={key}>
            <Label style={{ "white-space": "pre-wrap" }}>
              {admissionQuestionObject.question}
              {admissionQuestionObject.isMandatory && (
                <Label htmlFor="formSingleLine" className="text-danger">
                  *
                </Label>
              )}
            </Label>
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <div>
              <label
                className=""
                onClick={() => {
                  handleCheckBoxChange(
                    admissionQuestionObject,
                    admissionTitleObject
                  );
                }}
              >
                {admissionQuestionObject.choices &&
                  admissionQuestionObject.choices.length > 0
                  ? admissionQuestionObject.choices[0]
                  : ""}
              </label>
              <input
                type="checkbox"
                className="ml-2"
                id={"admissionFormCheckBox" + key}
                onChange={() =>
                  handleCheckBoxChange(
                    admissionQuestionObject,
                    admissionTitleObject
                  )
                }
                checked={admissionQuestionObject.answerBool}
              />
            </div>
          </FormGroup>
        );
        break;

      case Constants.QESTION_YES_NO_NO_COMMENTS:
        return boolTagsWithoutComment(
          admissionQuestionObject,
          admissionTitleObject,
          admissionQuestionObject.choices,
          handleYesNoButtonClick,
          key
        );

        break;
      case Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST:
        return radioButtonForSingleSelection(
          admissionQuestionObject,
          admissionTitleObject,
          admissionQuestionObject.choices,
          handleChoiceButtonClick,
          key
        );
        break;

      case Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST:
        return checkboxButtonForMultiSelection(
          admissionQuestionObject,
          admissionTitleObject,
          admissionQuestionObject.choices,
          handleChoiceButtonClick,
          key
        );

        break;
      case Constants.QESTION_DATE:
        const maxDate = moment();
        const maxDateString = maxDate.format("YYYY-MM-DD");

        return (
          <FormGroup key={key}>
            <Label> {admissionQuestionObject.question}</Label>
            {admissionQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}

            {admissionQuestionObject.answerDate != null ? (
              <AvField
                type="date"
                name={"questionDate" + key}
                className="inner form-control"
                placeholder="dd/mm/yyyy"
                value={formatDate(admissionQuestionObject.answerDate)}
                onChange={(e) => {
                  handleDateChange(
                    admissionQuestionObject,
                    admissionTitleObject,
                    e.target.value
                  );
                }}
              />
            ) : (
              <AvField
                type="date"
                name={"questionDate" + key}
                className="inner form-control"
                placeholder="dd/mm/yyyy"
                onChange={(e) => {
                  handleDateChange(
                    admissionQuestionObject,
                    admissionTitleObject,
                    e.target.value
                  );
                }}
              />
            )}
          </FormGroup>
        );
        break;
      case Constants.QESTION_RANGE_TIME:
        break;
      case Constants.QESTION_GEO_POINT:
        break;
      case Constants.QESTION_YES_NO_COMMENT_ON_YES:
        if (!admissionQuestionObject.answerBool === true) {
          return boolTagsWithoutComment(
            admissionQuestionObject,
            admissionTitleObject,
            admissionQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        } else {
          return boolTagsWithComment(
            admissionQuestionObject,
            admissionTitleObject,
            admissionQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        }

        break;
      case Constants.QESTION_YES_NO_COMMENT_ON_NO:
        if (admissionQuestionObject.answerBool === false) {
          return boolTagsWithComment(
            admissionQuestionObject,
            admissionTitleObject,
            admissionQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        } else {
          return boolTagsWithoutComment(
            admissionQuestionObject,
            admissionTitleObject,
            admissionQuestionObject.choices,
            handleYesNoButtonClick,
            key
          );
        }
        break;
      case Constants.QESTION_YES_NO_WITH_COMMENT:
        boolTagsWithComment(
          admissionQuestionObject,
          admissionTitleObject,
          admissionQuestionObject.choices,
          handleYesNoButtonClick,
          key
        );
        break;
      case Constants.QUESTION_EMAIL:
        return (
          <FormGroup key={key}>
            <Label htmlFor="formemail">
              {" "}
              {admissionQuestionObject.question}
            </Label>
            {admissionQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="email"
              name={"email" + key}
              placeholder={props.t("Enter Email")}
              className="form-control"
              value={admissionQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
              validate={{
                pattern: {
                  value: "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                  errorMessage: props.t(
                    "You have entered an invalid email address!"
                  ),
                },
              }}
            />
          </FormGroup>
        );
        break;
      case Constants.QUESTION_PHONE_NUMBER:
        return (
          <FormGroup key={key}>
            <Label> {admissionQuestionObject.question}</Label>
            {admissionQuestionObject.isMandatory && (
              <Label className="text-danger">*</Label>
            )}
            {admissionQuestionObject.answerError === true && (
              <Label className="label-xsmall text-danger ml-2">
                (this field is mandatory)
              </Label>
            )}
            <AvField
              type="number"
              name={"phone" + key}
              className="inner form-control noscroll"
              placeholder={props.t("Enter phone no")}
              value={admissionQuestionObject.answerText}
              onChange={(e) => {
                handleInputOnChange(
                  admissionQuestionObject,
                  admissionTitleObject,
                  e.target.value
                );
              }}
              
              onKeyPress={(e) => {
                  const charCode = e.which ? e.which : e.keyCode;
                  if ((charCode < 48 || charCode > 57) && charCode !== 43) {
                    e.preventDefault();
                  }
                }} 
                validate={{
                  required: { value: true, errorMessage: props.t('Phone number is required') },
                  pattern: {
                    value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                    errorMessage: props.t(
                      "You have entered an invalid phone no!"
                    ),
                  },
                  minLength: { value: 10,errorMessage: 'Phone number must be at least 10 digits'  },
                  maxLength: { value: (admissionQuestionObject.answerText && admissionQuestionObject.answerText.startsWith('+') ? 13 : 10) },
                }}
            />
          </FormGroup>
        );

        break;
    }
  };

  // //  UI Functions
  const radioButtonForSingleSelection = (
    admissionQuestionObject,
    admissionTitleObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="">{admissionQuestionObject.question}</Label>
        {admissionQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {admissionQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyInside}>
              <input
                className="form-check-input"
                type="radio"
                id={"radioButton" + admissionQuestionObject.admissionQuestionParseObject.id + "_" + keyInside}
                value={choice}
                checked={admissionQuestionObject.answerChoices.includes(choice)}
                onChange={() => {
                  handlerFunction(
                    choice,
                    admissionQuestionObject,
                    admissionTitleObject
                  );
                }}
              />
              <label className="form-check-label" htmlFor={"radioButton" + admissionQuestionObject.admissionQuestionParseObject.id + "_" + keyInside}>
                {choice}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };
  const checkboxButtonForMultiSelection = (
    admissionQuestionObject,
    admissionTitleObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="">{admissionQuestionObject.question}</Label>
        {admissionQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {admissionQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div>
          {choices.map((choice, keyInside) => (
            <div className="form-check  ml-3" key={keyInside}>
              <input
                className="form-check-input"
                type="checkbox"
                id={"checkbox" + admissionQuestionObject.admissionQuestionParseObject.id + "_" + keyInside}
                value={choice}
                checked={admissionQuestionObject.answerChoices.includes(choice)}
                onChange={() => {
                  handlerFunction(
                    choice,
                    admissionQuestionObject,
                    admissionTitleObject
                  );
                }}
              />
              <label className="form-check-label" htmlFor={"checkbox" + admissionQuestionObject.admissionQuestionParseObject.id + "_" + keyInside}>
                {choice}
              </label>
            </div>
          ))}
        </div>
      </div>
    );
  };

  const boolTagsWithoutComment = (
    admissionQuestionObject,
    admissionTitleObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="">{admissionQuestionObject.question}</Label>
        {admissionQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {admissionQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div className="tagcloud mt-1">
          {choices.map((choice, keyInside) => (
            <Button
              key={keyInside}
              id={choice}
              color={
                ((admissionQuestionObject.answerBool === true && admissionQuestionObject.choices.length > 0 && admissionQuestionObject.choices[0] === choice) || (admissionQuestionObject.answerBool === false && admissionQuestionObject.choices.length > 1 && admissionQuestionObject.choices[1] === choice))
                  ? "primary"
                  : "secondary"
              }
              className="btn rounded mr-2 mb-2"
              onClick={() =>
                handlerFunction(
                  choice,
                  admissionQuestionObject,
                  admissionTitleObject
                )
              }
            >
              {choice}
            </Button>
          ))}
        </div>
      </div>
    );
  };
  const boolTagsWithComment = (
    admissionQuestionObject,
    admissionTitleObject,
    choices,
    handlerFunction,
    key
  ) => {
    return (
      <div className="widget mb-2 pb-2" key={key}>
        <Label className="">{admissionQuestionObject.question}</Label>
        {admissionQuestionObject.isMandatory === true && (
          <Label className="text-danger">*</Label>
        )}
        {admissionQuestionObject.answerError === true && (
          <Label className="label-xsmall text-danger ml-2">
            (this field is mandatory)
          </Label>
        )}
        <div className="tagcloud mt-1">
          {choices.map((choice, keyInside) => (
            <Button
              key={keyInside}
              id={choice}
              color={
                ((admissionQuestionObject.answerBool === true && admissionQuestionObject.choices[0] === choice) || (admissionQuestionObject.answerBool === false && admissionQuestionObject.choices[1] === choice))
                  ? "primary"
                  : "secondary"
              }
              className="btn rounded mr-2"
              onClick={() =>
                handlerFunction(
                  choice,
                  admissionQuestionObject,
                  admissionTitleObject
                )
              }
            >
              {choice}
            </Button>
          ))}
        </div>
        <AvField
          type="textarea"
          name="multiLine1"
          className="form-control mt-3"
          rows="3"
          placeholder={admissionQuestionObject.questionHint}
          onChange={(e) => {
            handleInputOnChange(
              admissionQuestionObject,
              admissionTitleObject,
              e.target.value
            );
          }}
        />
      </div>
    );
  };

  const checkForDifferentQuestionsValidations = (
    admissionQuestionsList,
    admissionTitleObject
  ) => {
    var canProceedToNextScreen = true;
    var i = 0;

    for (i = 0; i < admissionQuestionsList.length; i++) {
      if (admissionQuestionsList[i].isMandatory === true) {
        if (
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_TEXT_SINGLE_LINE ||
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_TEXT_MULTI_LINE ||
          admissionQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
          admissionQuestionsList[i].questionType ===
          Constants.QUESTION_PHONE_NUMBER
        ) {
          if (
            admissionQuestionsList[i].answerText == null ||
            admissionQuestionsList[i].answerText === ""
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        } else if (
          admissionQuestionsList[i].questionType === Constants.QESTION_NUMBER
        ) {
          if (admissionQuestionsList[i].answerNumber == null) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        } else if (
          admissionQuestionsList[i].questionType === Constants.QESTION_CHECKBOX
        ) {
          if (
            admissionQuestionsList[i].answerBool == null ||
            admissionQuestionsList[i].answerBool === false
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        } else if (
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_NO_COMMENTS
        ) {
          if (admissionQuestionsList[i].answerBool == null) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        } else if (
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
        ) {
          if (
            admissionQuestionsList[i].answerChoices == null ||
            admissionQuestionsList[i].answerChoices.length === 0
          ) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        } else if (
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
        ) {
          if (
            admissionQuestionsList[i].answerChoices == null ||
            admissionQuestionsList[i].answerChoices.length === 0
          ) {
            canProceedToNextScreen = false;

            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        } else if (
          admissionQuestionsList[i].questionType === Constants.QESTION_DATE
        ) {
          if (admissionQuestionsList[i].answerDate == null) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        } else if (
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_COMMENT_ON_YES ||
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_COMMENT_ON_NO
        ) {
          if (admissionQuestionsList[i].answerBool == null) {
            if (
              admissionQuestionsList[i].answerText == null ||
              admissionQuestionsList[i].answerText === ""
            ) {
              canProceedToNextScreen = false;

              setAnswerErrorTrue(
                admissionQuestionsList,
                admissionTitleObject,
                i
              );
            }
          }




        } else if (
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_YES_NO_WITH_COMMENT
        ) {
        } else if (
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_UPLOAD_IMAGE
        ) {
          if (
            admissionQuestionsList[i].answerImage == null ||
            admissionQuestionsList[i].answerImageUploadProgress === 0
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        } else if (
          admissionQuestionsList[i].questionType ===
          Constants.QESTION_UPLOAD_DOCUMENT
        ) {
          if (
            admissionQuestionsList[i].answerFile == null ||
            admissionQuestionsList[i].answerFileUploadProgress === 0
          ) {
            canProceedToNextScreen = false;
            setAnswerErrorTrue(admissionQuestionsList, admissionTitleObject, i);
          }
        }
      }
      if (
        admissionQuestionsList[i].answerFileUploadProgress >= 1 &&
        admissionQuestionsList[i].answerFileUploadProgress < 100
      ) {
        canProceedToNextScreen = false;
        toastr.warning(
          "Please wait for file upload to complete, then try again",
          { timeOut: 1000 }
        );
      }
      else if (
        admissionQuestionsList[i].answerImageUploadProgress >= 1 &&
        admissionQuestionsList[i].answerImageUploadProgress < 100
      ) {
        canProceedToNextScreen = false;
        toastr.warning(
          "Please wait for Image upload to complete, then try again",
          { timeOut: 1000 }
        );
      }
    }
    return canProceedToNextScreen;
  };

  const isFormValid = () => {
    var canProceedToSave = true;

    var admissionTitleObject =
      admissionTitleList[admissionTitleList.length - 1];
    var admissionQuestionsList = admissionTitleObject.admissionQuestionsList;
    canProceedToSave = checkForDifferentQuestionsValidations(
      admissionQuestionsList,
      admissionTitleObject
    );
    if (canProceedToSave) {
      saveForm(
        stateEnquiryObject,
        stateFatherObject,
        stateMotherObject,
        stateChildAdditionalDetailsObject,
        true
      );
    }
  };

  const checkForDataType = (dataType, answer) => {
    if (dataType === "ARRAY") {
      if (!Array.isArray(answer)) {
        return [answer];
      }
    } else if (dataType === "STRING") {
      if (!(typeof answer === "string" || answer instanceof String)) {
        return answer.toString();
      }
    } else if (dataType === "NUMBER") {
      if (typeof answer === "string" || answer instanceof String) {
        return parseInt(answer, 10);
      }
    }
    return answer;
  };

  const comparingForFillingObjectsWithAnswers = (
    i,
    objectToReturn,
    shouldSaveObject,
    admissionQuestionsList
  ) => {
    if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_SINGLE_LINE ||
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_MULTI_LINE ||
      admissionQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
      admissionQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER
    ) {
      if (admissionQuestionsList[i].answerText != null) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerText
          )

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_NUMBER
    ) {
      if (admissionQuestionsList[i].answerNumber != null) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerNumber
          )

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_CHECKBOX
    ) {
      if (admissionQuestionsList[i].answerBool != null) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          admissionQuestionsList[i].answerBool

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      if (admissionQuestionsList[i].answerBool != null) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          admissionQuestionsList[i].answerBool

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      if (
        admissionQuestionsList[i].answerChoices != null &&
        admissionQuestionsList[i].answerChoices.length > 0
      ) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerChoices[0]
          )

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      if (
        admissionQuestionsList[i].answerChoices != null &&
        admissionQuestionsList[i].answerChoices.length > 0
      ) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerChoices
          )

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_DATE
    ) {
      if (admissionQuestionsList[i].answerDate != null) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          admissionQuestionsList[i].answerDate

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_YES ||
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      if (admissionQuestionsList[i].answerText != null) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          checkForDataType(
            admissionQuestionsList[i].dataType,
            admissionQuestionsList[i].answerText
          )

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      if (
        admissionQuestionsList[i].answerFile &&
        admissionQuestionsList[i].answerFileUploadProgress === 100
      ) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          admissionQuestionsList[i].answerFile.file

        shouldSaveObject = true;
      } else if (
        admissionQuestionsList[i].answerFileUploadProgress >= 1 &&
        admissionQuestionsList[i].answerFileUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for file upload to complete, then try again",
          { timeOut: 1000 }
        );
      } else {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          null

        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_UPLOAD_IMAGE
    ) {
      if (
        admissionQuestionsList[i].answerImage &&
        admissionQuestionsList[i].answerImage.image != null
      ) {
        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          admissionQuestionsList[i].answerImage.image

        shouldSaveObject = true;
      } else if (
        admissionQuestionsList[i].answerImageUploadProgress >= 1 &&
        admissionQuestionsList[i].answerImageUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for Image upload to complete, then try again",
          { timeOut: 1000 }
        );
      } else {

        objectToReturn[
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseFieldName] =
          null

        shouldSaveObject = true;
      }
    }
    return {
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };

  const collectAdmissionDataQuestionsToSave = (enquiryId, kidId) => {

    let listOfAdmissionObjectsToSave = []

    var j = 0;
    for (j = 0; j < admissionTitleList.length; j++) {
      var i = 0;
      var admissionQuestionsList = admissionTitleList[j].admissionQuestionsList;
      for (i = 0; i < admissionQuestionsList.length; i++) {
        let result
        let shouldSaveObject
        if (
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseTableName === "AdmissionData"
        ) {

          let resultObjectList = admissionDataList.filter(
            (fd) =>
              fd.attributes.questionId === admissionQuestionsList[i].admissionQuestionParseObject.id
          );
          let objectToSave
          objectToSave = resultObjectList && resultObjectList.length > 0 && resultObjectList[0]
          if (objectToSave) {

          } else {
            let AdmissionData = Parse.Object.extend("AdmissionData");
            objectToSave = new AdmissionData();
          }




          objectToSave.set(
            "admissionTitleId",
            admissionTitleList[j].admissionTitleParseObject.id
          );
          objectToSave.set(
            "questionId",
            admissionQuestionsList[i].admissionQuestionParseObject.id
          );
          if (enquiryId.length > 0) {
            objectToSave.set("enquiryId", enquiryId)

          }
          objectToSave.set("kidId", kidId)


          result = comparingForFillingObjectsWithAnswersForAdmissionData(
            i,
            objectToSave,
            shouldSaveObject,
            admissionQuestionsList
          );
          if (result) {
            shouldSaveObject = result.shouldSaveObject;
            objectToSave = result.objectToReturn;
            if (shouldSaveObject && objectToSave) {
              listOfAdmissionObjectsToSave.push(objectToSave)
            }
          }

        }


      }
    }
    return listOfAdmissionObjectsToSave
  }

  const comparingForFillingObjectsWithAnswersForAdmissionData = (
    i,
    objectToReturn,
    shouldSaveObject,
    admissionQuestionsList
  ) => {
    if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_TEXT_SINGLE_LINE ||
      admissionQuestionsList[i].questionType === Constants.QESTION_TEXT_MULTI_LINE ||
      admissionQuestionsList[i].questionType === Constants.QUESTION_EMAIL ||
      admissionQuestionsList[i].questionType === Constants.QUESTION_PHONE_NUMBER
    ) {

      if (admissionQuestionsList[i].answerText != null) {
        let tempAnswer = checkForDataType(
          admissionQuestionsList[i].dataType,
          admissionQuestionsList[i].answerText
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }

        shouldSaveObject = true;
      }
    } else if (admissionQuestionsList[i].questionType === Constants.QESTION_NUMBER) {
      if (admissionQuestionsList[i].answerNumber != null) {
        let tempAnswer = checkForDataType(
          admissionQuestionsList[i].dataType,
          admissionQuestionsList[i].answerNumber
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_CHECKBOX
    ) {
      if (admissionQuestionsList[i].answerBool != null) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerBool]);
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_YES_NO_NO_COMMENTS
    ) {
      if (admissionQuestionsList[i].answerBool != null) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerBool]);
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      if (
        admissionQuestionsList[i].answerChoices != null &&
        admissionQuestionsList[i].answerChoices.length > 0
      ) {
        let tempAnswer = checkForDataType(
          admissionQuestionsList[i].dataType,
          admissionQuestionsList[i].answerChoices[0]
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      if (
        admissionQuestionsList[i].answerChoices != null &&
        admissionQuestionsList[i].answerChoices.length > 0
      ) {
        let tempAnswer = checkForDataType(
          admissionQuestionsList[i].dataType,
          admissionQuestionsList[i].answerChoices
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }
        shouldSaveObject = true;
      }
    } else if (admissionQuestionsList[i].questionType === Constants.QESTION_DATE) {
      if (admissionQuestionsList[i].answerDate != null) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerDate]);
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_YES ||
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_COMMENT_ON_NO
    ) {
      let answerList = []

      if (admissionQuestionsList[i].answerBool != null) {
        answerList.push(admissionQuestionsList[i].answerBool)
      }
      if (admissionQuestionsList[i].answerText != null) {
        answerList.push(admissionQuestionsList[i].answerText)
      }
      if (answerList.length > 0) {
        shouldSaveObject = true;
        objectToReturn.set("answer", answerList);
      }
    } else if (
      admissionQuestionsList[i].questionType ===
      Constants.QESTION_YES_NO_WITH_COMMENT
    ) {
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_UPLOAD_DOCUMENT
    ) {
      if (
        admissionQuestionsList[i].answerFile &&
        admissionQuestionsList[i].answerFileUploadProgress === 100
      ) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerFile.file]);
        shouldSaveObject = true;
      } else if (
        admissionQuestionsList[i].answerFileUploadProgress >= 1 &&
        admissionQuestionsList[i].answerFileUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for file upload to complete, then try again",
          { timeOut: 1000 }
        );
      } else {
        objectToReturn.unset("answer");
        shouldSaveObject = true;
      }
    } else if (
      admissionQuestionsList[i].questionType === Constants.QESTION_UPLOAD_IMAGE
    ) {
      if (
        admissionQuestionsList[i].answerImage &&
        admissionQuestionsList[i].answerImage.image != null
      ) {
        objectToReturn.set("answer", [admissionQuestionsList[i].answerImage.image]);
        shouldSaveObject = true;
      } else if (
        admissionQuestionsList[i].answerImageUploadProgress >= 1 &&
        admissionQuestionsList[i].answerImageUploadProgress < 100
      ) {
        abortSubmitForm = true;
        toastr.warning(
          "Please wait for Image upload to complete, then try again",
          { timeOut: 1000 }
        );
      } else {
        objectToReturn.unset("answer");
        shouldSaveObject = true;
      }
    }
    return {
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };

  const fillingObjectsWithAnswers = (
    objectToSave,
    tableName,
    objectType = 0
  ) => {
    var objectToReturn = objectToSave;
    var shouldSaveObject = false;
    let result = undefined;

    var j = 0;
    for (j = 0; j < admissionTitleList.length; j++) {
      var i = 0;
      var admissionQuestionsList = admissionTitleList[j].admissionQuestionsList;
      for (i = 0; i < admissionQuestionsList.length; i++) {
        if (
          admissionQuestionsList[i].admissionQuestionParseObject.attributes
            .databaseTableName === tableName
        ) {
          if (tableName === "Parent") {
            if (
              admissionQuestionsList[i].admissionQuestionParseObject.attributes
                .objectType === objectType
            ) {
              result = comparingForFillingObjectsWithAnswers(
                i,
                objectToReturn,
                shouldSaveObject,
                admissionQuestionsList
              );
            }
          } else {
            result = comparingForFillingObjectsWithAnswers(
              i,
              objectToReturn,
              shouldSaveObject,
              admissionQuestionsList
            );
          }
        }
        if (result) {
          shouldSaveObject = result.shouldSaveObject;
          objectToReturn = result.objectToReturn;
        }
      }
    }
    if (shouldSaveObject) {
      return objectToReturn;
    }
    return shouldSaveObject;
  };

  

  const saveForm = (
    enquiryObject,
    fatherObject,
    motherObject,
    childAdditionalDetailsObject,
    isSubmit = false
  ) => {
    var enquiryObjectToSave = enquiryObject;
    var kidObjectToSave = stateKidObject;
    var enquirySimpleObject = {
      tableName: "Enquiry"
    }
    if (enquiryObjectToSave) {
      enquirySimpleObject.id = enquiryObjectToSave.id
    }
    var kidSimpleObject = {
      tableName: "Kid"
    }
    if (kidObjectToSave) {
      kidSimpleObject.id = kidObjectToSave.id
    }


    var motherObjectToSave = null;
    var motherSimpleObject = {
      tableName: "Parent"
    }


    var fatherObjectToSave = null;
    var fatherSimpleObject = {
      tableName: "Parent"
    }

    var childAdditionalDetailObjectToSave = null;

    var childAdditionalDetailSimpleObject = {
      tableName: "ChildAdditionalDetails"
    }
    var shouldSaveKidObject = true
    var shouldSaveEnquiryObject = true;
    var shouldSaveFatherObject = true;
    var shouldSaveMotherObject = true;
    var shouldSaveChildAdditionalDetailsObject = true;

    if (motherObject != null) {
      motherObjectToSave = motherObject;
      motherSimpleObject.id = motherObject.id
    } else {
      const ParentObject = Parse.Object.extend("Parent");
      motherObjectToSave = new ParentObject();
    }
    if (fatherObject != null) {
      fatherObjectToSave = fatherObject;
      fatherSimpleObject.id = fatherObject.id
    } else {
      const ParentObject = Parse.Object.extend("Parent");
      fatherObjectToSave = new ParentObject();
    }

    if (childAdditionalDetailsObject != null) {
      childAdditionalDetailObjectToSave = childAdditionalDetailsObject;
      childAdditionalDetailSimpleObject.id = childAdditionalDetailObjectToSave.id
    } else {
      const ChildAdditionalDetailObject = Parse.Object.extend(
        "ChildAdditionalDetails"
      );
      childAdditionalDetailObjectToSave = new ChildAdditionalDetailObject();
    }
    enquirySimpleObject = fillingObjectsWithAnswers(
      enquirySimpleObject,
      "Enquiry"
    );
    if (enquirySimpleObject === false) {
      shouldSaveEnquiryObject = false;
    } else {
      shouldSaveEnquiryObject = true;
    }

    kidSimpleObject = fillingObjectsWithAnswers(
      kidSimpleObject,
      "Kid"
    );
    if (kidSimpleObject === false) {
      shouldSaveKidObject = false;
    } else {
      shouldSaveKidObject = true;
    }

    fatherSimpleObject = fillingObjectsWithAnswers(
      fatherSimpleObject,
      "Parent",
      2
    );
    if (fatherSimpleObject === false) {
      shouldSaveFatherObject = false;
    } else {
      shouldSaveFatherObject = true;
    }

    motherSimpleObject = fillingObjectsWithAnswers(
      motherSimpleObject,
      "Parent",
      1
    );
    if (motherSimpleObject === false) {
      shouldSaveMotherObject = false;
    } else {
      shouldSaveMotherObject = true;
    }

    childAdditionalDetailSimpleObject = fillingObjectsWithAnswers(
      childAdditionalDetailSimpleObject,
      "ChildAdditionalDetails"
    );
    if (childAdditionalDetailSimpleObject === false) {
      shouldSaveChildAdditionalDetailsObject = false;
    } else {
      shouldSaveChildAdditionalDetailsObject = true;
    }
    var familyId = uuidv4();

    if (motherObject != null && motherObject.attributes.FamilyID != null) {
      familyId = motherObject.attributes.FamilyID;
    } else if (
      fatherObject != null &&
      fatherObject.attributes.FamilyID != null
    ) {
      familyId = fatherObject.attributes.FamilyID;
    }
    var groupACL = new Parse.ACL();
    groupACL.setRoleWriteAccess(parameter + "_Admin", true);
    groupACL.setRoleReadAccess(parameter + "_Admin", true);
    groupACL.setRoleWriteAccess(parameter + "_Teacher", false);
    groupACL.setRoleReadAccess(parameter + "_Teacher", true);
    groupACL.setRoleWriteAccess(familyId + "_Role", true);
    groupACL.setRoleReadAccess(familyId + "_Role", true);

    if (abortSubmitForm) {
      return;
    }
    formIsSubmitesOps(true);
    setFormSubmissionDate(new Date());
    if (enquirySimpleObject === false) {
      enquirySimpleObject = {
        tableName: "Enquiry"
      }
      if (enquiryObjectToSave) {
        enquirySimpleObject.id = enquiryObjectToSave.id
      }
    }
    if ((shouldSaveEnquiryObject && enquiryObjectToSave) || (enquiryObjectToSave && (!matchKidId && !franchiseMatchKidId))) {

      if (enquiryObjectToSave && enquiryObjectToSave.attributes.admissionFormSubmittedOn) {

      } else {
        if (isSubmit) {
          enquirySimpleObject["admissionFormSubmittedOn"] = formSubmissionDate?? new Date()
        }
      }
      enquirySimpleObject["schoolId"] = parameter
      let tempEnquirySimpleObject = {}
      if (enquirySimpleObject.id) {
        tempEnquirySimpleObject.id = enquirySimpleObject.id
        delete enquirySimpleObject.id
      }
      tempEnquirySimpleObject.tableName = enquirySimpleObject.tableName
      delete enquirySimpleObject.tableName
      tempEnquirySimpleObject.obj = enquirySimpleObject
      // console.log(tempEnquirySimpleObject);
      Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", tempEnquirySimpleObject).then(
        (result) => {

          // console.log(result + "saving enquiry success");
          saveKidObject(
            groupACL,
            familyId,
            shouldSaveKidObject,
            kidSimpleObject,
            shouldSaveChildAdditionalDetailsObject,
            shouldSaveMotherObject,
            shouldSaveFatherObject,
            childAdditionalDetailSimpleObject,
            motherSimpleObject,
            fatherSimpleObject,
            isSubmit
          );

        },
        (error) => {
          console.log("enquiry saving error");
        }
      );

    } else {

      saveKidObject(
        groupACL,
        familyId,
        shouldSaveKidObject,
        kidSimpleObject,
        shouldSaveChildAdditionalDetailsObject,
        shouldSaveMotherObject,
        shouldSaveFatherObject,
        childAdditionalDetailSimpleObject,
        motherSimpleObject,
        fatherSimpleObject,
        isSubmit
      );
    }
  };

  const saveKidObject = (
    groupACL,
    familyId,
    shouldSaveKidObject,
    kidSimpleObject,
    shouldSaveChildAdditionalDetailsObject,
    shouldSaveMotherObject,
    shouldSaveFatherObject,
    childAdditionalDetailSimpleObject,
    motherSimpleObject,
    fatherSimpleObject,
    isSubmit = false
  ) => {
    if (shouldSaveKidObject && stateKidObject) {
      // console.log(kidSimpleObject);
      let tempKidSimpleObject = {}
      if (kidSimpleObject.id) {
        tempKidSimpleObject.id = kidSimpleObject.id
        delete kidSimpleObject.id
      }
      tempKidSimpleObject.tableName = kidSimpleObject.tableName
      delete kidSimpleObject.tableName
      tempKidSimpleObject.obj = kidSimpleObject
      Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", tempKidSimpleObject).then(
        (result) => {
          // console.log(result + "kid saving success");

          saveChildAdditional(
            groupACL,
            familyId,
            shouldSaveChildAdditionalDetailsObject,
            shouldSaveMotherObject,
            shouldSaveFatherObject,
            childAdditionalDetailSimpleObject,
            motherSimpleObject,
            fatherSimpleObject,
            isSubmit
          );

        },
        (error) => {
          console.log("kid saving error");
        }
      );


    } else {
      saveChildAdditional(
        groupACL,
        familyId,
        shouldSaveChildAdditionalDetailsObject,
        shouldSaveMotherObject,
        shouldSaveFatherObject,
        childAdditionalDetailSimpleObject,
        motherSimpleObject,
        fatherSimpleObject,
        isSubmit
      );
    }
  };

  const saveChildAdditional = (
    groupACL,
    familyId,
    shouldSaveChildAdditionalDetailsObject,
    shouldSaveMotherObject,
    shouldSaveFatherObject,
    childAdditionalDetailSimpleObject,
    motherSimpleObject,
    fatherSimpleObject,
    isSubmit = false
  ) => {
    if (shouldSaveChildAdditionalDetailsObject) {
      // console.log(childAdditionalDetailSimpleObject);
      //childAdditionalDetailSimpleObject.setACL(groupACL);
      if (stateEnquiryObject && stateEnquiryObject.id) {
        childAdditionalDetailSimpleObject["enquiryId"] = stateEnquiryObject.id
      }
      if (stateKidObject && stateKidObject.id) {
        childAdditionalDetailSimpleObject["kidId"] = stateKidObject.id

      }
      let tempChildAdditionalDetailSimpleObject = {}
      if (childAdditionalDetailSimpleObject.id) {
        tempChildAdditionalDetailSimpleObject.id = childAdditionalDetailSimpleObject.id
        delete childAdditionalDetailSimpleObject.id
      }
      tempChildAdditionalDetailSimpleObject.tableName = childAdditionalDetailSimpleObject.tableName
      delete childAdditionalDetailSimpleObject.tableName
      tempChildAdditionalDetailSimpleObject.obj = childAdditionalDetailSimpleObject
      Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", tempChildAdditionalDetailSimpleObject).then(
        (result) => {
          // console.log(result + "child additional saving success");

          setStateChildAdditionalDetailsObject(result)
          saveFatherObj(
            groupACL,
            familyId,
            shouldSaveFatherObject,
            fatherSimpleObject,
            shouldSaveMotherObject,
            motherSimpleObject,
            isSubmit
          );

        },
        (error) => {
          console.log("child additional saving error");
        }
      );


    } else {
      saveFatherObj(
        groupACL,
        familyId,
        shouldSaveFatherObject,
        fatherSimpleObject,
        shouldSaveMotherObject,
        motherSimpleObject,
        isSubmit
      );
    }
  };

  function saveFatherObj(
    groupACL,
    familyId,
    shouldSaveFatherObject,
    fatherSimpleObject,
    shouldSaveMotherObject,
    motherSimpleObject,
    isSubmit = false
  ) {
    if (shouldSaveFatherObject) {
      // console.log(fatherSimpleObject);
      fatherSimpleObject["Relation"] = "Father"
      fatherSimpleObject["FamilyID"] = familyId
      fatherSimpleObject["NotificationChoice"] = 16127

      let tempFatherSimpleObject = {}
      if (fatherSimpleObject.id) {
        tempFatherSimpleObject.id = fatherSimpleObject.id
        delete fatherSimpleObject.id
      }
      tempFatherSimpleObject.tableName = fatherSimpleObject.tableName
      delete fatherSimpleObject.tableName
      tempFatherSimpleObject.obj = fatherSimpleObject
      Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", tempFatherSimpleObject).then(
        (result) => {
          // console.log(result + "father saving success");

          saveMotherObj(
            groupACL,
            familyId,
            shouldSaveMotherObject,
            motherSimpleObject,
            isSubmit
          );

        },
        (error) => {
          console.log("father saving error");
        }
      );


      //fatherSimpleObject.setACL(groupACL);


    } else {
      saveMotherObj(
        groupACL,
        familyId,
        shouldSaveMotherObject,
        motherSimpleObject,
        isSubmit
      );
    }
  }

  function saveMotherObj(
    groupACL,
    familyId,
    shouldSaveMotherObject,
    motherSimpleObject,
    isSubmit = false
  ) {
    if (shouldSaveMotherObject) {
      // console.log(motherSimpleObject);
      motherSimpleObject["Relation"] = "Mother"
      motherSimpleObject["FamilyID"] = familyId
      motherSimpleObject["NotificationChoice"] = 16127
      let tempMotherSimpleObject = {}
      if (motherSimpleObject.id) {
        tempMotherSimpleObject.id = motherSimpleObject.id
        delete motherSimpleObject.id
      }
      tempMotherSimpleObject.tableName = motherSimpleObject.tableName
      delete motherSimpleObject.tableName
      tempMotherSimpleObject.obj = motherSimpleObject
      Parse.Cloud.run("fuction_updateAdmissionDetailsForKid", tempMotherSimpleObject).then(
        (result) => {
          // console.log(result + "mother saving success");
          saveAdmissionDataObjects(isSubmit);
        },
        (error) => {
          console.log("mother saving error");
        }
      );

      // motherSimpleObject.setACL(groupACL);

    } else {
      saveAdmissionDataObjects(isSubmit);
    }
  }

  function saveAdmissionDataObjects(isSubmit = false) {
    let listToSave = collectAdmissionDataQuestionsToSave(stateEnquiryObject ? stateEnquiryObject.id : "", stateKidObject.id)
    if (listToSave.length > 0) {
      Parse.Object.saveAll(listToSave).then(
        (result) => {
          if (isSubmit) {
            saveKidObjectWithSubmitDate();
          } else {
            setIsSaving(false)
            formIsSubmitesOps(false);
          }
        },
        (error) => {
          console.log("saving error");
        }
      );


    } else {
      if (isSubmit) {
        saveKidObjectWithSubmitDate();
      } else {
        formIsSubmitesOps(false);
        setIsSaving(false)
      }
    }
  }

  function saveKidObjectWithSubmitDate() {
    Parse.Cloud.run("function_updateKidObject", {
      kidId: stateKidObject.id,
      admissionFormSubmittedOn:formSubmissionDate?? new Date(),
    }).then(
      (result) => {
        if (result) {
          setCardMessage("Successfully submitted the form !!");
          setIsFormSubmitted(true);
        }
      },
      (error) => {
        return null;
      }
    );
  }

  //Handler functions
  //  Click/Changes Handlers
  const handleYesNoButtonClick = (
    choice,
    admissionQuestionObject,
    admissionTitleObject
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    var choices = [...admissionQuestionObject.choices];

    var tempArray = [...tempQuestionObject.answerChoices];

    if (tempQuestionObject.answerChoices.includes(choice)) {
      //remove
      removeElement(tempArray, choice);
    } else {
      tempArray = [choice];
    }

    tempQuestionObject.answerChoices = [...tempArray];

    if (tempQuestionObject.isMandatory) {
      if (tempArray != null && tempArray.length > 0) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    if (choice === choices[0]) {
      tempQuestionObject.answerBool = true;
      if (tempQuestionObject.questionType === Constants.QESTION_YES_NO_COMMENT_ON_NO) {
        tempQuestionObject.answerText = ""
      }
    } else if (choice === choices[1]) {
      tempQuestionObject.answerBool = false;
      if (tempQuestionObject.questionType === Constants.QESTION_YES_NO_COMMENT_ON_YES) {
        tempQuestionObject.answerText = ""
      }

    } else {
      tempQuestionObject.answerBool = null;
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    var tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  const handleChoiceButtonClick = (
    choice,
    admissionQuestionObject,
    admissionTitleObject
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    if (
      tempQuestionObject.questionType ===
      Constants.QESTION_CHOICE_MULTI_SELECTION_FROM_LIST
    ) {
      var tempArray = [...tempQuestionObject.answerChoices];

      if (tempQuestionObject.answerChoices.includes(choice)) {
        //remove
        removeElement(tempArray, choice);
      } else {
        tempArray.push(choice);
      }

      tempQuestionObject.answerChoices = tempArray;

      if (tempQuestionObject.isMandatory) {
        if (tempArray != null && tempArray.length > 0) {
          tempQuestionObject.answerError = false;
        } else {
          tempQuestionObject.answerError = true;
        }
      }

      var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

      tempQuestionList[questionIndex] = tempQuestionObject;

      tempTitleObject.admissionQuestionsList = [...tempQuestionList];

      var tempTitleList = [...admissionTitleList];

      tempTitleList[titleIndex] = tempTitleObject;

      setAdmissionTitleList(tempTitleList);
    } else if (
      tempQuestionObject.questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
    ) {
      var tempArray = [...tempQuestionObject.answerChoices];

      if (tempQuestionObject.answerChoices.includes(choice)) {
        //remove
        removeElement(tempArray, choice);
      } else {
        tempArray = [choice];
      }

      tempQuestionObject.answerChoices = [...tempArray];

      if (tempQuestionObject.isMandatory) {
        if (tempArray != null && tempArray.length > 0) {
          tempQuestionObject.answerError = false;
        } else {
          tempQuestionObject.answerError = true;
        }
      }

      var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

      tempQuestionList[questionIndex] = tempQuestionObject;

      tempTitleObject.admissionQuestionsList = [...tempQuestionList];

      var tempTitleList = [...admissionTitleList];

      tempTitleList[titleIndex] = tempTitleObject;

      setAdmissionTitleList(tempTitleList);
    }
  };

  const handleCheckBoxChange = (
    admissionQuestionObject,
    admissionTitleObject
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    tempQuestionObject.answerBool = !admissionTitleObject
      .admissionQuestionsList[questionIndex].answerBool;

    if (tempQuestionObject.isMandatory) {
      if (tempQuestionObject.answerBool === true) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    var tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  const handleInputOnChange = (
    admissionQuestionObject,
    admissionTitleObject,
    changedValue
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    tempQuestionObject.answerText = changedValue;

    if (tempQuestionObject.isMandatory) {
      if (changedValue.length > 0) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    var tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  const handleNumberOnChange = (
    admissionQuestionObject,
    admissionTitleObject,
    changedValue
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    tempQuestionObject.answerNumber = changedValue;

    if (tempQuestionObject.isMandatory) {
      if (changedValue) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    var tempTitleList = [...admissionTitleList];

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  const handleDateChange = (
    admissionQuestionObject,
    admissionTitleObject,
    changedValue
  ) => {
    var questionIndex = admissionTitleObject.admissionQuestionsList.indexOf(
      admissionQuestionObject
    );

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempQuestionObject = admissionQuestionObject;

    var tempTitleObject = admissionTitleList[titleIndex];

    tempQuestionObject.answerDate = new Date(changedValue);
    if (tempQuestionObject.isMandatory) {
      if (changedValue != undefined) {
        tempQuestionObject.answerError = false;
      }
    }

    var tempQuestionList = [...tempTitleObject.admissionQuestionsList];

    tempQuestionList[questionIndex] = tempQuestionObject;

    tempTitleObject.admissionQuestionsList = [...tempQuestionList];

    admissionTitleList[titleIndex] = tempTitleObject;
  };

  const setAnswerErrorTrue = (
    admissionQuestionsList,
    admissionTitleObject,
    i
  ) => {
    var tempObject = admissionQuestionsList[i];

    tempObject.answerError = true;

    var temp = [...admissionQuestionsList];

    temp[i] = tempObject;

    var tempTitleList = [...admissionTitleList];

    var titleIndex = admissionTitleList.indexOf(admissionTitleObject);

    var tempTitleObject = admissionTitleList[titleIndex];

    tempTitleObject.admissionQuestionsList = temp;

    tempTitleList[titleIndex] = tempTitleObject;

    setAdmissionTitleList(tempTitleList);
  };

  function uuidv4() {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  }

  function getBase64(file, cb) {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      var resultWithName = { name: file.name, result: reader.result };
      cb(resultWithName);
    };
    reader.onerror = function (error) {
      console.log("Error: ", error);
    };
  }

  function getFileTypeImage(fileExtension) {
    if (fileExtension === "pdf") {
      return "bx bxs-file-pdf";
    } else if (fileExtension === "ppt" || fileExtension === "pptx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "xlsx") {
      return "bx bxs-file-doc";
    } else if (fileExtension === "doc" || fileExtension === "docx") {
      return "bx bxs-file-doc";
    }
    // else if(fileType === "Video"){
    //     return "bx bx-play-circle";
    // }else if (_audioExtensions.includes(fileExtension)) {
    //     return "bx bxs-music";
    // }
    return "bx bxs-file-blank";
  }

  //// Utilities functions
  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  function formatDate(d) {
    if (d == null || d === "" || typeof d === "string" || d instanceof String) {
      return "";
    } else {
      const ye = new Intl.DateTimeFormat("en", { year: "numeric" }).format(d);
      const mo = new Intl.DateTimeFormat("en", { month: "2-digit" }).format(d);
      const da = new Intl.DateTimeFormat("en", { day: "2-digit" }).format(d);

      return `${ye}-${mo}-${da}`;
    }
  }
  function toggleTab(
    tab,
    admissionQuestionsList,
    admissionTitleObject,
    choice
  ) {
    if (tab !== activeTab) {
      if (tab - activeTab === 1 || tab - activeTab === -1) {
      } else {
        if (choice === next) {
          tab = activeTab + 1;
        } else if (choice === previous) {
          tab = activeTab - 1;
        }
      }

      if (choice === next) {
        var canProceedToNextScreen = true;

        canProceedToNextScreen = checkForDifferentQuestionsValidations(
          admissionQuestionsList,
          admissionTitleObject
        );

        if (canProceedToNextScreen) {
          if (activeTab !== tab) {
            if (tab >= 0 && tab <= admissionTitleList.length - 1) {
              scroller.scrollTo("topScrollArea", {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100, // Scrolls to element + 50 pixels down the page
              });
              setactiveTab(tab);

              var tempRatio = 100 / admissionTitleList.length;
              var tempMultiplier = tab + 1;
              var tempProgress = tempMultiplier * tempRatio;
              setprogressValue(tempProgress);
            }
          }
        } else {
        }
      } else {
        if (activeTab !== tab) {
          if (tab >= 0 && tab <= admissionTitleList.length - 1) {
            scroller.scrollTo("topScrollArea", {
              duration: 500,
              delay: 100,
              smooth: true,
              offset: -100, // Scrolls to element + 50 pixels down the page
            });
            setactiveTab(tab);
            var tempRatio = 100 / admissionTitleList.length;
            var tempMultiplier = tab + 1;
            var tempProgress = tempMultiplier * tempRatio;
            setprogressValue(tempProgress);
          }
        }
      }
    }
  }

  return (
    <React.Fragment>
      <Container fluid={true} className={(match || franchiseMatch || matchKidId || franchiseMatchKidId) ? "" : "page-content"}>
        {!isLoading && !isFormSubmitted && isQuestionsAvailable && (
          <Row>
            <Col lg={matchInsideDashboard ? 12 : 8} className="mx-auto">
              {(match || franchiseMatch || matchKidId || franchiseMatchKidId) && (
                <div className="text-center mt-2">
                  {stateSchoolObject && stateSchoolObject.attributes.logo && (
                    <img
                      src={stateSchoolObject.attributes.logo._url}
                      width="100"
                      alt=""
                      className="img-thumbnail rounded-circle"
                    />
                  )}
                  <h3 className="mt-2">
                    {stateSchoolObject
                      ? props.t(
                        `Welcome to ${stateSchoolObject.attributes.Name}`
                      )
                      : props.t("Welcome")}{" "}
                  </h3>
                  <h6 className="mt-2">
                    {parameter
                      ? props.t("Please provide the details")
                      : props.t("Please provide the details")}
                  </h6>
                </div>
              )}
              <Card>
                <CardBody>
                  <Element name="topScrollArea">
                    <h4 className="card-title mb-4">Admission Form</h4>
                  </Element>

                  <div id="basic-pills-wizard" className="twitter-bs-wizard">
                    <ul className="twitter-bs-wizard-nav nav nav-pills nav-justified">
                      {admissionTitleList.map((admissionTitle, keyTitle) => (
                        <NavItem key={keyTitle}>
                          <NavLink
                            className={classnames({
                              active: activeTab === keyTitle,
                            })}
                            onClick={() => {
                              keyTitle > activeTab
                                ? toggleTab(
                                  keyTitle,
                                  admissionTitleList[activeTab]
                                    .admissionQuestionsList,
                                  admissionTitleList[activeTab],
                                  next
                                )
                                : toggleTab(
                                  keyTitle,
                                  admissionTitleList[activeTab]
                                    .admissionQuestionsList,
                                  admissionTitleList[activeTab],
                                  previous
                                );
                            }}
                          >
                            <Col>
                              <Row className="justify-content-center">
                                <span className="step-number">
                                  {keyTitle + 1}
                                </span>
                              </Row>
                              <Row className="justify-content-center mt-2">
                                <div style={{ textAlign: "center" }}>
                                  {admissionTitle.title}
                                </div>
                              </Row>
                            </Col>
                          </NavLink>
                        </NavItem>
                      ))}
                    </ul>
                    <div id="bar" className="mt-4">
                      <Progress
                        color="success"
                        striped
                        animated
                        value={progressValue}
                      />
                      <div className="progress-bar bg-success progress-bar-striped progress-bar-animated"></div>
                    </div>
                    <TabContent
                      activeTab={activeTab}
                      className="twitter-bs-wizard-tab-content"
                    >
                      {admissionTitleList.map((admissionTitle, keyTitle) => (
                        <TabPane key={keyTitle} tabId={keyTitle}>
                          <Col lg={12} className="mx-auto">
                            <Card>
                              <CardBody>
                                <AvForm
                                  className="outer-repeater"
                                  style={{ border: "1px" }}
                                  onValidSubmit={isFormValid}
                                >
                                  <div
                                    data-repeater-list="outer-group"
                                    className="outer"
                                  >
                                    <div data-repeater-item className="outer">
                                      <h5>{props.t(admissionTitle.title)}: </h5>
                                      <hr />

                                      {admissionTitle.admissionQuestionsList.map(
                                        (
                                          admissionQuestionObject,
                                          keyQuestion
                                        ) =>
                                          componentForQuestionType(
                                            admissionQuestionObject,
                                            admissionTitle,
                                            keyQuestion
                                          )
                                      )}
                                      {admissionTitle.admissionQuestionsList
                                        .length === 0 && (
                                          <h6 className="mb-3">
                                            No Questions in this section.
                                          </h6>
                                        )}

                                      {admissionTitleList.length - 1 ===
                                        activeTab && <hr></hr>}
                                      {admissionTitleList.length - 1 ===
                                        activeTab && (
                                          <div style={{ display: "flex" }}>
                                            <Button
                                              type="submit"
                                              color="primary"
                                              disabled={formIsSubmites}
                                              style={{
                                                marginLeft: "auto",
                                                marginRight: "auto",
                                                width: "180px",
                                              }}
                                            >
                                              {formIsSubmites && (
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                                              )}
                                              {props.t("SUBMIT")}
                                            </Button>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </AvForm>
                              </CardBody>
                            </Card>
                          </Col>
                        </TabPane>
                      ))}
                    </TabContent>

                    <ul className="pager wizard twitter-bs-wizard-pager-link">
                      <li
                        className={
                          activeTab === 0 ? "previous disabled" : "previous"
                        }
                      >
                        <Link
                          to="#"
                          onClick={() => {
                            toggleTab(
                              activeTab - 1,
                              admissionTitleList[activeTab]
                                .admissionQuestionsList,
                              admissionTitleList[activeTab],
                              previous
                            );
                          }}
                        >
                          Previous
                        </Link>
                      </li>
                      <li className="next">
                        <Link
                          to="#"
                          className={isSaving ? "mr-2 disabled" : "mr-2"}
                          onClick={() => {
                            setIsSaving(true)
                            saveForm(
                              stateEnquiryObject,
                              stateFatherObject,
                              stateMotherObject,
                              stateChildAdditionalDetailsObject,
                            );
                          }}
                        >
                          {isSaving && <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>}
                          {isSaving ? "Saving" : "Save"}
                        </Link>
                        <Link
                          to="#"
                          className={
                            activeTab === admissionTitleList.length - 1
                              ? "disabled"
                              : ""
                          }
                          onClick={() => {
                            toggleTab(
                              activeTab + 1,
                              admissionTitleList[activeTab]
                                .admissionQuestionsList,
                              admissionTitleList[activeTab],
                              next
                            );
                          }}
                        >
                          Next
                        </Link>
                      </li>
                    </ul>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        )}

        {isFormSubmitted && (
          <Card className="p-5 mt-2">
            <CardBody>
              {/* <div className="outer-repeater" style={{ border: '1px' }} >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer"> 
                    <h5 ><i className="fa fa-check text-success text-center mr-2

" aria-hidden="true"></i>
                    {cardMessage}</h5>
                  {/* </div>
                </div>
              </div> */}
              <CardTitle className="text-center">
                <i className={cardIcon} aria-hidden="true"></i>
                {cardMessage}
              </CardTitle>
            </CardBody>
          </Card>
        )}

        {!isLoading && !isQuestionsAvailable && (
          <Card>
            <CardBody>
              <AvForm
                className="outer-repeater"
                style={{ border: "1px" }}
                onValidSubmit={() => { }}
              >
                <div data-repeater-list="outer-group" className="outer">
                  <div data-repeater-item className="outer">
                    <h5>Admission form not configured for this branch !</h5>
                  </div>
                </div>
              </AvForm>
            </CardBody>
          </Card>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Admission Form...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </Container>
    </React.Fragment>
  );
};

export default withNamespaces()(EditAdmissionForm);

class CustomAdmissionObject {
  question = "";
  questionType = null;
  questionNumber = null;
  admissionQuestionParseObject = null;
  answerText = null;
  answerNumber = null;
  answerBool = null;
  answerDate = null;
  answerChoices = [];
  choices = [];
  dataType = null;
  questionHint = "Type here";
  isMandatory = false;
  answerError = false;
  answerFile = null;
  answerImage = null;
  answerFileUploadProgress = 0;
  answerImageUploadProgress = 0;

  constructor(
    question,
    questionType,
    questionNumber,
    admissionQuestionParseObject,
    answerText,
    answerNumber,
    answerBool,
    dataType,
    answerDate,
    answerChoices,
    choices,
    questionHint,
    isMandatory = false,
    answerError = false,
    answerFile,
    answerImage,
    answerFileUploadProgress,
    answerImageUploadProgress
  ) {
    this.question = question;
    this.questionType = questionType;
    this.questionNumber = questionNumber;
    this.admissionQuestionParseObject = admissionQuestionParseObject;
    this.answerText = answerText;
    this.answerNumber = answerNumber;
    this.answerBool = answerBool;
    this.dataType = dataType;
    this.answerDate = answerDate;
    this.answerChoices = answerChoices;
    this.choices = choices;
    this.questionHint = questionHint;
    this.isMandatory = isMandatory;
    this.answerError = answerError;
    this.answerFile = answerFile;
    this.answerImage = answerImage;
    this.answerFileUploadProgress = answerFileUploadProgress;
    this.answerImageUploadProgress = answerImageUploadProgress;
  }
}
class CustomAdmissionTitleObject {
  admissionQuestionsList = [];
  title = "";
  admissionTitleParseObject = null;

  constructor(admissionQuestionsList, admissionTitleParseObject, title) {
    this.admissionQuestionsList = admissionQuestionsList;
    this.title = title;
    this.admissionTitleParseObject = admissionTitleParseObject;
  }
}