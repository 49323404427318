import Parse from "parse";
import { printLog } from "./loghelper";
export const getEnquiryNoteFromParse = (enquiryNoteId) => {
  printLog("HL: enquirynotes_helper : getEnquiryNoteFromParse")

  try {
    return new Promise((resolve, reject) => {
      var EnquiryNote = Parse.Object.extend("EnquiryNotes");
      var query = new Parse.Query(EnquiryNote);
      query.get(enquiryNoteId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
}

export const deleteEnquiryNoteFromParse = (enquiryNote) => {
  printLog("HL: enquirynotes_helper : deleteEnquiryNoteFromParse")
  try {
    return new Promise((resolve, reject) => {
      
      enquiryNote.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        enquiryNote.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        enquiryNote.set("deletedBy", Parse.User.current().id);
      }

      enquiryNote.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
}

export const getEnquiryNotesForEnquiryId = (id, isLoacal) => {
  printLog("HL: enquirynotes_helper : getEnquiryNotesForEnquiryId")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryNote = Parse.Object.extend("EnquiryNotes");
      var query = new Parse.Query(EnquiryNote);
      query.equalTo("enquiryId", id);

      

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}



export const getEnquiryNotesForEnquiryIds = (ids) => {
  printLog("HL: enquirynotes_helper : getEnquiryNotesForEnquiryIds")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryNote = Parse.Object.extend("EnquiryNotes");
      var query = new Parse.Query(EnquiryNote);
      query.containedIn("enquiryId", ids);
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.limit(10000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};


export const editEnquiryNote = (enquiry) => {
  printLog("HL: enquirynotes_helper : editEnquiryNote")
  try {
    return new Promise((resolve, reject) => {
      enquiry.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
        
    });
  } catch (error) { }
}
