import Parse from "parse";
import { CSR_APPROVED } from "../../Constents";
import { printLog } from "./loghelper";

export const getAllCourseRequestsForKidId = (kidId) => {
  printLog("HL: courseRequests_helper : getAllCourseRequestsForKidId")
  try {
    return new Promise((resolve, reject) => {
      var courseReqs = Parse.Object.extend("CourseSubscriptionRequest");
      var query = new Parse.Query(courseReqs);
      query.equalTo("kidId", kidId);
      query.notEqualTo("status", CSR_APPROVED);
      query.notEqualTo("isDeleted", true);
      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}

export const getCourseRequests = (id, updatedDate, isLoacal) => {
  printLog("HL: courseRequests_helper : getCourseRequests")
  try {
    return new Promise((resolve, reject) => {


      var courseReqs = Parse.Object.extend("CourseSubscriptionRequest");
      var query = new Parse.Query(courseReqs);
      query.equalTo("courseId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const saveParseObjects = (objects) => {
  printLog("HL: courseRequests_helper : saveParseObjects")
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(undefined);
          console.log("Error", error);
        }
      );
    });
  } catch (error) {
    console.log("Error", error);
  }
};

