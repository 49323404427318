import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Parse from 'parse';
import {
    getLearningCurriculumSchoolMapForCurriculumId,
    saveLearningCurriculumSchoolMap
} from '../../../store/actions';
import TopBar from '../Topbar';
import SchoolPicker from '../../Messaging/SchoolPicker';

const franchiseCurriculumAccess = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/curriculum-access/:franchiseId/:curriculumId",
        exact: true,
        strict: false,
    });

    const curriculumId = match.params.curriculumId;
    const franchiseId = match.params.franchiseId;

    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchoolListForPicker, setSelectedSchoolListForPicker] = useState([]);

    useEffect(() => {
        if (curriculumId) {
            props.getLearningCurriculumSchoolMapForCurriculumId(curriculumId);
        }
    }, [curriculumId]);

    useEffect(() => {
        let filterData = props.learningCurriculumsSchoolMapForCurriculumId.filter((cr) => cr.attributes.curriculumId == curriculumId)
        setSelectedSchoolListForPicker(filterData.map((cr) => { return { "id": cr.attributes.schoolId } }))
    }, [props.learningCurriculumsSchoolMapForCurriculumId?.length])


    const handleSaveSchools = (selectedSchools) => {
        const LearningCurriculumSchoolMap = Parse.Object.extend("LearningCurriculumSchoolMap");

        let learningCurriculumSchoolIds = [];
        let learningCurriculumsToDelete = [];

        // Step 1: getting the IDs from props.learningCurriculumsSchoolMapForCurriculumId 

        for (let l = 0; l < props.learningCurriculumsSchoolMapForCurriculumId.length; l++) {
            learningCurriculumSchoolIds.push(props.learningCurriculumsSchoolMapForCurriculumId[l].attributes.schoolId);
        }
        // Step 2: Identify the schools to be deleted from the props.learningCurriculums 

        for (let i = 0; i < props.learningCurriculumsSchoolMapForCurriculumId.length; i++) {
            let shouldDelete = true;
            for (let j = 0; j < selectedSchools.length; j++) {
                if (props.learningCurriculumsSchoolMapForCurriculumId[i].attributes.schoolId === selectedSchools[j].id) {
                    shouldDelete = false;
                    break;
                }
            }
            if (shouldDelete) {
                learningCurriculumsToDelete.push(props.learningCurriculumsSchoolMapForCurriculumId[i]);
            }
        }

        // Step 3: Filter the same schools from the selectedSchoolListForPicke


        const schoolObjectsToSave = selectedSchools.filter((selectedSchool) =>
            !learningCurriculumSchoolIds.includes(selectedSchool.id)
        );

        // Step 4: saving to db


        let curriculumObjectList = [];

        // For loop the "SchoolObjectsToSave" array, which contains the filtered schools

        if (schoolObjectsToSave.length > 0) {
            for (let i = 0; i < schoolObjectsToSave.length; i++) {
                const learningCurriculums = new LearningCurriculumSchoolMap();
                learningCurriculums.set("curriculumId", curriculumId);

                if (franchiseId) {
                    learningCurriculums.set("ownerType", "franchise");
                    learningCurriculums.set("ownerId", franchiseId);
                }
                learningCurriculums.set("schoolId", schoolObjectsToSave[i].id);
                learningCurriculums.set("createdBy", Parse.User.current().attributes.teacherId);

                curriculumObjectList.push(learningCurriculums);
            }
        }
        // console.log([...curriculumObjectList, props.learningCurriculumsSchoolMapForCurriculumId]);
        // Step 5: To delete the schools from the curriculumObjectList


        if (learningCurriculumsToDelete.length > 0) {
            for (let j = 0; j < learningCurriculumsToDelete.length; j++) {
                const tempObject = learningCurriculumsToDelete[j];
                tempObject.set("isDeleted", true);
                curriculumObjectList.push(tempObject);
            }
        }

        // Step 6: Save to  props.saveLearningCurriculumSchoolMap

        if (curriculumObjectList.length > 0) {
            props.saveLearningCurriculumSchoolMap(curriculumObjectList)
            window.history.back()
        }
        console.log(curriculumObjectList);
    }

    console.log("databse", props.learningCurriculumsSchoolMapForCurriculumId);

    return (
        <div className='page-content'>
            <Row>
                <Col className="d-flex align-items-start">
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => { window.history.back() }}>
                        <ul className=" list-unstyled">
                            <div className=''>
                                <i className="bx bx-chevron-left h1 text-primary"></i>
                            </div>
                        </ul>
                    </div>
                    <div className=''>
                        <h4 className="mt-2">Curriculum Access</h4>
                    </div>
                </Col>
            </Row>
            <SchoolPicker
                franchiseId={franchiseId}
                selectedSchools={selectedSchoolListForPicker ?? []}
                onButtonClick={(selectedSchools) => {
                    if (selectedSchools && selectedSchools.length > 0) {
                        setSelectedSchoolListForPicker(selectedSchools);
                        handleSaveSchools(selectedSchools);
                    }
                    else {
                        toastr.warning("Please select at least one student to proceed");
                    }
                }}
            >
            </SchoolPicker>
        </div>
    )
}

const mapStatetoProps = state => {
    const { learningCurriculumsSchoolMapForCurriculumId } = state.LearningCurriculumsSchoolMap;
    const { selectedSchool } = state.School;

    return { learningCurriculumsSchoolMapForCurriculumId, selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    getLearningCurriculumSchoolMapForCurriculumId,
    saveLearningCurriculumSchoolMap
})(franchiseCurriculumAccess)));