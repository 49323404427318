import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col } from "reactstrap";
import { matchPath } from "react-router";

const transcationSuccess = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/transcation-success/:transcationId",
        exact: true,
        strict: false,
    });

    const transcationId = match && match.params.transcationId;

    return (

        <React.Fragment>
            <div className="account-pages my-5 pt-5">
                <Container>
                    <Row className='py-5'>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h1 className="display-1 font-weight-medium"><b><i className="bx bx-check-circle  text-success display-1"></i></b></h1>
                            </div>
                        </Col>
                    </Row>
                    <Row className='py-5'>
                        <Col lg="12">
                            <div className="text-center mb-5">
                                <h3 className=""><strong>Transaction Successful!!</strong></h3>
                                {transcationId &&<h5 className="">Transaction Id:{transcationId}</h5>}
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>
        </React.Fragment>
    );
}

export default transcationSuccess;

