import Parse from "parse";
import { printLog } from "./loghelper";
export const getSchoolFromParse = (schoolId, isLocal) => {
  printLog("HL: school_helper : getSchoolFromParse")

  try {
    return new Promise((resolve, reject) => {
      var School = Parse.Object.extend("School");
      var query = new Parse.Query(School);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.get(schoolId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const deleteSchoolFromParse = (schoolId) => {
  printLog("HL: school_helper : deleteSchoolFromParse")
  try {
    return new Promise((resolve, reject) => {
      const School = Parse.Object.extend("School");
      var school = new School();
      school.id = schoolId;
      school.set("deleted", true);
      if (Parse.User.current().attributes.teacherId) {
        school.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        school.set("deletedBy", Parse.User.current().id);
      }

      school.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getSchoolRatingsForSchoolId = (schoolId, ratingType, updatedDate, isLoacal) => {
  printLog("HL: school_helper : getSchoolRatingsForSchoolId")
  // try {

  //   return new Promise((resolve, reject) => {
  //     var ratingParamsQueryObj = Parse.Object.extend("SchoolRatings");
  //     var query = new Parse.Query(ratingParamsQueryObj
  //     );
  //     query.equalTo("schoolId", schoolId);
  //     query.find().then(
  //       (result) => {
  //         resolve(result);
  //       },
  //       (error) => {
  //         resolve(error);
  //       }
  //     );
  //   });
  // } catch (error) { }

  try {
    return new Promise((resolve, reject) => {
      var schoolRatingQueryObj = Parse.Object.extend("SchoolRatings");
      var mainQuery = new Parse.Query(schoolRatingQueryObj);
      mainQuery.equalTo("schoolId", schoolId);

      if (updatedDate) {
        var fromDate = new Date(updatedDate);
        mainQuery.greaterThanOrEqualTo("updatedAt", fromDate);
        if (ratingType == 2) {
          let fromDate = new Date(2023, 2, 1);
          mainQuery.greaterThanOrEqualTo("createdAt", fromDate);
        }
      } else {
        if (ratingType == 2) {
          let fromDate = new Date(2023, 2, 1); // March 1, 2023
          let toDate = new Date(2023, 4, 31); // May 31, 2023
          mainQuery.greaterThanOrEqualTo("createdAt", fromDate);
          mainQuery.lessThanOrEqualTo("createdAt", toDate);
        }
      }

      if (isLoacal) {
        mainQuery.fromLocalDatastore();
      }

      mainQuery.find().then(
        (result) => {
          resolve(result);
          console.log(result);
        },
        (error) => {
          resolve(error);
          console.log(error)
        }
      );
    });
  } catch (error) { }
};

export const getRatingParamsForSchoolId = (schoolObject) => {
  printLog("HL: school_helper : getRatingParamsForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var ratingParamsQueryObj = Parse.Object.extend("SchoolRatingParams");
      var query = new Parse.Query(ratingParamsQueryObj);
      query.equalTo("schoolType", schoolObject.attributes.schoolType);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getPaymentOptionsForSchoolId = (schoolId) => {
  printLog("HL: school_helper : getPaymentOptionsForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var paymentOptions = Parse.Object.extend("PaymentOptions");
      var query = new Parse.Query(paymentOptions);
      query.equalTo("schoolId", schoolId);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
        }
      );
    });
  } catch (error) { }
};
export const editPaymentOptions = (paymentOptionsForSchool) => {
  printLog("HL: school_helper : editPaymentOptions")
  try {
    return new Promise((resolve, reject) => {
      paymentOptionsForSchool.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const editSchool = (school) => {
  printLog("HL: school_helper : editSchool")
  try {
    return new Promise((resolve, reject) => {
      school.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getAllSchoolsForInstitute = (instituteId) => {
  printLog("HL: school_helper : getAllSchoolsForInstitute")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("School");
      query.equalTo("instituteId", instituteId);
      query.notEqualTo("deleted", true);
      query.limit(1000);
      query.find().then((schoolObjs) => {
        resolve(schoolObjs);
      });
    });
  } catch (error) { }
};

export const getSchoolsForInstitute = (id, type, isLoacal, schoolIds) => {
  printLog("HL: school_helper : getSchoolsForInstitute")
  try {
    return new Promise((resolve, reject) => {
      var School = Parse.Object.extend("School");
      var mainQuery = new Parse.Query(School);
      mainQuery.equalTo(type, id);
      mainQuery.limit(1000);
      if (isLoacal) {
        mainQuery.fromLocalDatastore();
      }
      if (schoolIds && schoolIds.length > 0) {
        mainQuery.containedIn("objectId", schoolIds);
      }
      mainQuery.notEqualTo("deleted", true);
      mainQuery.ascending("createdAt");
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getFranchiseDetails = (franchiseId) => {
  printLog("HL: school_helper : getFranchiseDetails")
  return new Promise((resolve, reject) => {
    let query = new Parse.Query("Franchise");
    query.equalTo("objectId", franchiseId);
    query.first().then((result) => {
      resolve(result);
    });
  });
};

export const getAllSchoolForFranchise = (franchiseId) => {
  printLog("HL: school_helper : getAllSchoolForFranchise")
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("getAllSchoolsForFranchise", {
      franchiseId: franchiseId,
    }).then((results) => {
      resolve(results);
    });
  });
};

export const getAllSchoolForInstitute = (instituteId) => {
  printLog("HL: school_helper : getAllSchoolForInstitute")
  return new Promise((resolve, reject) => {
    Parse.Cloud.run("function_getAllSchoolForInstitute", {
      instituteId: instituteId,
    }).then((results) => {
      resolve(results);
    });
  });
};

export const getInstituteDeatilsForId = (instituteId) => {
  printLog("HL: school_helper : getInstituteDeatilsForId")
  return new Promise((resolve, reject) => {
    let query = new Parse.Query("Institute");
    query.equalTo("objectId", instituteId);
    query.first().then((result) => {
      resolve(result);
    });
  });
};

export const getReceiptAddressForSchoolId = (schoolId) => {
  printLog("HL: school_helper : getReceiptAddressForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("ReceiptAddress");
      query.equalTo("schoolId", schoolId);
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.find().then((res) => {
        resolve(res);
      });
    });
  } catch (error) {
    console.log(error)
  }
}

export const getSchoolAnalyticsForDay = (id, type, updatedAt, isLocal) => {
  printLog("HL: school_helper : getSchoolAnalyticsForDay")
  try {
    return new Promise((resolve, reject) => {
      var SchoolAnalytics2 = Parse.Object.extend("SchoolAnalytics2");
      var query = new Parse.Query(SchoolAnalytics2);
      if (type === "school") {
        query.equalTo("schoolId", id);
      } else if (type === "institute") {
        query.equalTo("instituteId", id);
      } else if (type === "franchise") {
        query.equalTo("franchiseId", id);
      }

      let today = new Date();
      let date = new Date(today.getFullYear(), today.getMonth(), today.getDate() - 1, 5, 59);

      // query.greaterThanOrEqualTo('analyticsDate', date);

      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.descending('createdAt')
      query.limit(500);
      query.find().then(
        (result) => {
          resolve(result);
          // console.log(result)
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}


// *****************************ANALYTICS AND TOOLS**************************************//


export const getSchoolAnalyticsForSchoolObject = (id) => {

  try {
    return new Promise((resolve, reject) => {
      var SchoolAnalytics = Parse.Object.extend("SchoolAnalytics");
      var query = new Parse.Query(SchoolAnalytics);

      query.equalTo("schoolId", id);

      query.first().then(
        (result) => {
          resolve(result);
          console.log(result)
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}

export const getInstitutesForTools = () => {

  try {
    return new Promise((resolve, reject) => {

      Parse.Cloud.run("function_getInstitutes", {
      }).then(
        (result) => {
          resolve(result);
          // console.log(result)
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
};

export const getSchoolsForTools = () =>{
  try {
    return new Promise((resolve, reject) => {
      Parse.Cloud.run("function_getAllSchoolFromTable", {

    }).then(
      (result) => {
        resolve(result);
        // console.log(result)
      },
      (error) => {
        resolve(null);
      }
    );
  });
} catch (error) { }
};
