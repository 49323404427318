import * as actions from "./actionTypes";

export const updateCourse = (course) => {
  return {
    type: actions.UPDATE_COURSE,
    payload: { course },
  };
};

export const deleteCourse = (course) => {
  return {
    type: actions.DELETE_COURSE,
    payload: { course },
  };
};

export const updateCourses = (courses, replace) => {
  return {
    type: actions.UPDATE_COURSES,
    payload: { courses, replace },
  };
};

export const getCourseForownerId = (ownerId) => {
  return {
    type: actions.GET_COURSE_FOR_OWNER_ID,
    payload: { ownerId },
  };
};

export const getAllCoursesForSchoolId = (schoolId) => {
  return {
    type: actions.GET_ALL_COURSES_FOR_SCHOOL_ID,
    payload: { schoolId },
  };
};



export const getCoursesForOwnerIds = (ownerIds) => {
  return {
    type: actions.GET_COURSES,
    payload: { ownerIds },
  };
};

export const setInitialLoading = (state) => {
  return {
    type: actions.SET_COURSE_INITIAL_LOADING,
    payload: { state },
  };
};

export const clearCourses = () => {
  return {
    type: actions.CLEAR_COURSE,
    payload: {},
  };
};

export const updateInitialLoading = (state) => {
  return {
    type: actions.UPDATE_COURSE_INITIAL_LOADING,
    payload: { state },
  };
};

export const addNewCourse = (course, cycles, schoolId, history) => {
  return {
    type: actions.ADD_NEW_COURSE,
    payload: { course, cycles, schoolId, history },
  };
};

export const setSelectedCourse = (id) => {
  return {
    type: actions.SET_SELECTED_COURSE,
    payload: { id },
  };
};

export const updateSelectedCourse = (course) => {
  return {
    type: actions.UPDATE_SELECTED_COURSE,
    payload: { course },
  };
};
