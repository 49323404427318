import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, TabContent, TabPane, Card, Form, Button, CardText, Nav, NavItem, NavLink, CardBody, FormGroup,Modal,ModalHeader,ModalBody,ModalFooter } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import Parse from 'parse';
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';

import {
  getGroupsForSchoolId,
  getUserTeacher,
  deleteGroup, getProgramsForSchoolId, getGroupsForGroupIds, deleteProgram, setSelecetdSchool,
  
} from '../../../store/actions';
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import * as Constants from '../../../Constents';

const GroupDetailPage = (props) => {

    const schoolId = props.schoolId;

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if (Parse.User.current().attributes.teacherId) {
            props.getUserTeacher(Parse.User.current().attributes.teacherId)
        }
    }, [props.selectedSchool, schoolId]);

    const [displayList, setDisplayList] = useState([]);
    const filterList = ["All", "Upcoming", "In Progress", "Completed"];
    const [todayDate, setTodayDate] = useState(new Date());
    const [selectedFilter, setSelectedFilter] = useState("All");
    const [fullList, setFullList] = useState([])
    const [isLoading, setIsLoading] = useState(true);
    const [selectedPrograms, setSelectedPrograms] = useState([]);
    const [showProgarmModal, setShowProgramModal] = useState(false);
    

    const [dataList, setDataList] = useState([]);
    const [dataLoading,setLoading]=useState(-1)

    useEffect(() => {
        if (schoolId && schoolId !== "") {
            props.getProgramsForSchoolId(schoolId)
        }
    }, [schoolId]);
    useEffect(() => {
        if (props.noProgramsToDisplay === true) {
            setIsLoading(false)
        }
    }, [props.noProgramsToDisplay]);
    useEffect(() => {
        if (props.programs) {
            if (props.programs.length > 0) {
                var groupIds = []
                for (var i = 0; i < props.programs.length; i++) {
                    if(props.programs[i].attributes.hasTerm){
                        groupIds.push(props.programs[i].attributes.groupId)
                    }
                }
                props.getGroupsForGroupIds(groupIds)
            }
            else {
                if (props.isLoading === false) {
                    setIsLoading(false)
                }
            }
        }
    }, [props.programs]);

    useEffect(() => {
        if (props.groupsForIds) {
            if (props.groupsForIds.length > 0) {
                // console.log("groups checking")
                // console.log(props.groupsForIds)
                var groups = props.groupsForIds
                var programs = props.programs
                var displayList1 = []
                var i = 0
                var j = 0
                for (i = 0; i < groups.length; i++) {
                    for (j = 0; j < programs.length; j++) {
                        if ( programs[j].attributes.hasTerm && programs[j].attributes.groupId === groups[i].id) {
                            var newObject = {
                                id: programs[j].id,
                                parseObjectGroup: groups[i],
                                programName: programs[j].attributes.name,
                                childrenCount: programs[j].attributes.participantsCount ? programs[j].attributes.participantsCount : groups[i].attributes.participantsCount ?? 0,
                                parseObjectProgram: programs[j],
                                createdAt: programs[j].createdAt,
                                startDate: getTimeStringForDate(programs[j].attributes.startDate),
                                endDate: getTimeStringForDate(programs[j].attributes.endDate),
                                programBatch: programs[j].attributes.batch,
                                programType: programs[j].attributes.type,
                            }

                            if (programs[j].attributes.startDate < todayDate && programs[j].attributes.endDate > todayDate) {

                                newObject["color"] = "warning"
                                newObject["status"] = "In Progress"
                                // color: "success",
                                // status: "Completed",
                            } else if (programs[j].attributes.startDate > todayDate && programs[j].attributes.endDate > todayDate) {
                                newObject["color"] = "primary"
                                newObject["status"] = "Upcoming"
                            } else {
                                newObject["color"] = "success"
                                newObject["status"] = "Completed"
                            }

                            displayList1.push(newObject)

                        }
                    }
                }

                let tempUpcomingList = displayList1.filter((temp) =>
                    temp.status === "Upcoming"
                )
                let tempInProgressList = displayList1.filter((temp) =>
                    temp.status === "In Progress"
                )
                let tempCompletedList = displayList1.filter((temp) =>
                    temp.status === "Completed"
                )

                displayList1 = [...tempUpcomingList]
                displayList1 = displayList1.concat(tempInProgressList)
                displayList1 = displayList1.concat(tempCompletedList)
                displayList1 = displayList1.sort((a, b) => (a.createdAt < b.createdAt) ? 1 : -1)
                setFullList(displayList1)
                setDisplayList(displayList1)
                setIsLoading(false)
            } else {
                if (props.isLoadingForGroup === false) {
                    setIsLoading(false)
                }

            }


        }

    }, [props.groupsForIds]);

  

    useEffect(() => {

        var tempList = [...fullList]
        if (selectedFilter === "All") {

        } else if (selectedFilter === "Upcoming") {
            tempList = tempList.filter((object) =>
                object.parseObjectProgram.attributes.startDate > todayDate
            )
        } else if (selectedFilter === "Completed") {
            tempList = tempList.filter((object) =>
                object.parseObjectProgram.attributes.endDate < todayDate
            )
        } else if (selectedFilter === "In Progress") {
            tempList = tempList.filter((object) =>
                object.parseObjectProgram.attributes.startDate < todayDate && object.parseObjectProgram.attributes.endDate > todayDate
            )
        }

        setDisplayList(tempList)

    }, [selectedFilter])


    const getProgramsData = () => {

        // console.log(selectedPrograms);

        setLoading(0);
        setDataList([]);
        
        const ProgramTerms = Parse.Object.extend("ProgramTerms");
        const queryF = new Parse.Query(ProgramTerms);
        queryF.containedIn('programId', selectedPrograms);
        queryF.notEqualTo('isDeleted', true);
        queryF.ascending("order")
        queryF.find().then((terms) => {
        
            // console.log(terms);

            const Invoice = Parse.Object.extend("Invoice");
            const queryI = new Parse.Query(Invoice);
            queryI.containedIn('programId', selectedPrograms);
            queryI.notEqualTo('isDeleted', true);
            queryI.limit(10000);
            queryI.find().then((invoices) => {
      
                let invoiceIds = [];
                for (const iv of invoices) {
                    invoiceIds.push(iv.id)
                }

                const InvoiceItems = Parse.Object.extend("InvoiceItems");
                const queryIT = new Parse.Query(InvoiceItems);
                queryIT.containedIn('invoiceId', invoiceIds);
                queryIT.notEqualTo('isDeleted', true);
                queryIT.limit(10000);
                queryIT.find().then((invoiceItems) => {
      
                    let mainList=[]
                    for (const pid of selectedPrograms) {
                        let program = displayList.filter((p) => p.id == pid)[0];
                        let programTerms = []

                        let pAmount = 0;
                        let pPaidAmount = 0;
                        let pPendingAmount = 0;

                        for (let pt of terms) {
                            let termItems = []
                            let amount = 0;
                            let paidAmount = 0;
                            if (pt.attributes.programId == pid) {
                                for (let item of invoiceItems) {
                                    if (item.attributes.termId == pt.id) {
                                        termItems.push(item)
                                        amount += item.attributes.totalAmount;
                                        if(item.attributes.paidAmount){
                                            paidAmount += item.attributes.paidAmount;
                                        }
                                    }
                                }

                                programTerms.push({
                                    term: pt,
                                    items: termItems,
                                    amount: amount,
                                    paidAmount: paidAmount,
                                    pendingAmount:amount-paidAmount,
                                })
                                pAmount += amount;
                                pPaidAmount += paidAmount;
                                pPendingAmount += (amount - paidAmount);
                            }
                        }

                        mainList.push({
                            program: program,
                            programTerms: programTerms,
                            pAmount: pAmount,
                            pPaidAmount: pPaidAmount,
                            pPendingAmount:pPendingAmount,
                        })

                    }

                    setDataList(mainList)
                    
               
                    setLoading(1);
                });
               
            });
        });
    }

    const downloadTheReport = () => {



        let finalData = [];
        let fileName = "";
        let sheetName = "";
        fileName = `Program_Report_teem.xlsx`;
        sheetName = "Program_Report";
      
        finalData.push([
            `Report Type : Program Report`,
        ]);

        finalData.push([
            `Selected programs for Report`,
        ]);

        for (const p in dataList) {
            finalData.push([
                parseInt(p+"") +1, ` ${dataList[p].program.programName}`,
            ]);
        }
        
        finalData.push([
            `School : ${props.selectedSchool.attributes.Name}`,
        ]);
        finalData.push([
            `Date : ${getTimeStringForDate(new Date())}`,
        ]);

        finalData.push([]);
        finalData.push([]);

        finalData.push([ "Program Name","Term Name","number of students","Total Amount","Collected Amount","Pending Amount" ]);

        for (const program of dataList) {
            for (const term of program.programTerms) {
                finalData.push([
                    program.program.programName,
                    term.term.attributes.termName,
                    term.term.attributes.kidIds ? term.term.attributes.kidIds.length : 0,
                    term.amount,
                    term.paidAmount,
                    term.pendingAmount

                ]); 
            }
            finalData.push(["","","",program.pAmount,program.pPaidAmount,program.pPendingAmount])

            finalData.push([]);
            finalData.push([]);
        }

        finalData.push(["Over all"]);
        finalData.push(["Program Name","Amount","Paid Amount","Pending Amount"]);

        let pAmount = 0;
        let pPaidAmount = 0;
        let pPendingAmount = 0;
        for (const p of dataList) {
            pAmount += p.pAmount;
            pPaidAmount += p.pPaidAmount;
            pPendingAmount += p.pPendingAmount;
            finalData.push([p.program.programName, p.pAmount, p.pPaidAmount, p.pPendingAmount]);                                                  
        }
        finalData.push(["Total", pAmount, pPaidAmount, pPendingAmount]);

        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
    }


    const showNoPrograms = () => {
        if (!isLoading) {

            if (props.noProgramsToDisplay && isLoading === false) {
                return (
                    <Card>

                        <CardBody>
                            <p className="text-center">No programs to display !!</p>
                        </CardBody>
                    </Card>)
            } else if (!displayList || displayList.length === 0) {
                if (selectedFilter === "All") {
                    return (
                        <Card>
                            <CardBody>
                                <p className="text-center">{"No programs to display !!"}</p>
                            </CardBody>
                        </Card>);
                }
                return (
                    <Card>
                        <CardBody>
                            <p className="text-center">{"No " + selectedFilter + " programs to display !!"}</p>
                        </CardBody>
                    </Card>);
            }

        } else {
            return (
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Programs... </Label>
                        </div>
                    </Col>
                </Row>
            )
        }



    }

    const getProgramtotals = () => {
        let pAmount = 0;
        let pPaidAmount = 0;
        let pPendingAmount = 0;
        for (const p of dataList) {
            pAmount += p.pAmount;
            pPaidAmount += p.pPaidAmount;
            pPendingAmount += p.pPendingAmount;
        }
        return (
            <Tr key={  "program_totals"}>
                <Th></Th>
                                                                
                <Td>Total</Td>
                <Td>{pAmount}</Td>
                <Td>{pPaidAmount}</Td>
                <Td>{pPendingAmount}</Td>
            </Tr>
        )
    }

    const showProgramReportData = () => {

        if (dataLoading == 0) {
            return (
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading Programs... </Label>
                        </div>
                    </Col>
                </Row>
            )
        } else if (dataLoading == 0 && dataList.length == 0) {
            return (
                <Card>

                    <CardBody>
                        <p className="text-center">No data to display !!</p>
                    </CardBody>
                </Card>
            )
        } else {

            return (
                <Card style={{ minHeight: `calc(100vh - 150px)`, padding: "20px" }}>

                    <CardBody>
                        {dataList.length > 0 &&
                            dataList.map((program) => (
                                <div key={program.id} ><h5 className='mb-3'>{program.program.programName}</h5>

                                    <Row>
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Si no.</Th>
                                                            <Th data-priority="1">Term Name</Th>
                                                            <Th data-priority="1">Number of Students</Th>
                                                            <Th data-priority="1">Total Amount</Th>
                                                            <Th data-priority="2">Collected Amount</Th>
                                                            <Th data-priority="2">Pending Amount</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            program.programTerms.map((item, key) => (
                                                                <Tr key={item.id}>
                                                                    <Th>{item.id != "totalobject" ? (key + 1) : ""}</Th>
                                                                    <Td>
                                                                        <div><Label>{`${item.term.attributes.termName}`}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.term.attributes.kidIds?item.term.attributes.kidIds.length : ""}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.amount}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.paidAmount}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.pendingAmount}</Label></div>
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        }

                                                        {
                                                            <Tr key={program.id+"_T"}>
                                                                <Th></Th>
                                                                <Td></Td>
                                                                <Td>Total</Td>
                                                                <Td>{program.pAmount}</Td>
                                                                <Td>{program.pPaidAmount}</Td>
                                                                <Td>{program.pPendingAmount}</Td>
                                                            </Tr>
                                                        }
                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Row>
                                </div>
                            ))}  

                        {dataList.length > 0 &&
                            
                            <div><h5 className='mb-3'>{"Over all"}</h5>

                                <Row>
                                    <div className="table-rep-plugin">
                                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                <Thead>
                                                    <Tr>
                                                        <Th>Si no.</Th>
                                                        <Th data-priority="1">Program Name</Th>
                                                        
                                                        <Th data-priority="1">Total Amount</Th>
                                                        <Th data-priority="2">Collected Amount</Th>
                                                        <Th data-priority="2">Pending Amount</Th>
                                                    </Tr>
                                                </Thead>
                                                <Tbody>
                                                    {
                                                        dataList.map((item, key) => (
                                                            <Tr key={item.id}>
                                                                <Th>{item.id != "totalobject" ? (key + 1) : ""}</Th>
                                                                <Td>
                                                                    <div><Label>{`${item.program.programName}`}</Label></div>
                                                                </Td>
                                                               
                                                                <Td>
                                                                    <div><Label>{item.pAmount}</Label></div>
                                                                </Td>
                                                                <Td>
                                                                    <div><Label>{item.pPaidAmount}</Label></div>
                                                                </Td>
                                                                <Td>
                                                                    <div><Label>{item.pPendingAmount}</Label></div>
                                                                </Td>
                                                            </Tr>
                                                        ))
                                                    }

                                                    {getProgramtotals()}
                                                </Tbody>
                                            </Table>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        }
                    </CardBody>

                </Card>
            )
            
        }
    }

    function getTimeStringForDate(date) {
        return Moment(date).format("MMM Do YY");
    }

  

    const handelProgramClick = (item) => {
        if (selectedPrograms.includes(item.id)) {
            let l = selectedPrograms.filter((p) => item.id != p);
            setSelectedPrograms(l)
        } else {
            let l = [...selectedPrograms];
            l.push(item.id);
            setSelectedPrograms(l)
        }
    }

    return (

        <React.Fragment>
            
            {!isLoading && <div>
                <p className="font-size-18" >Pick programs for analytics</p>
                <button className='btn btn-primary m-1'
                    onClick={() => {
                        setShowProgramModal(true)
                    }}
                >
                    Pick Programs
                </button>

                {dataList && dataList.length>0 && <button className='btn btn-info m-1'
                    onClick={() => {
                        downloadTheReport(true)
                    }}
                >
                    Download Report
                </button>}
            </div>}

            {showNoPrograms()}

            {showProgramReportData()}
            
            <Modal isOpen={showProgarmModal} scrollable={true}>
                <ModalHeader isOpen={showProgarmModal}
                    toggle={() => {
                        setShowProgramModal(false);
                    }}
                >
                    <div className="modal-title mt-0">Pick programs for analytics</div>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <p>
                        {props.t(
                            "Please select the programs to which you wish to compare "
                        )}
                    </p>
                    <Form className="d-flex ">
                        <Row >
                            <Col lg={8}>
                             <FormGroup>
                                <select
                                    className="form-control"
                                    onChange={(e) => {
                                        setSelectedFilter(e.target.value);
                                    }}
                                    value={selectedFilter}
                                >
                                    {filterList.map((filter, key) => (
                                        <option key={key}>{filter}</option>
                                    ))}
                                </select>
                            </FormGroup>
                            </Col>
                            <Col lg={4}>
                                 <button
                                    type="button"
                                    className="btn btn-primary justify-content-end"
                                    onClick={() => {
                                        setShowProgramModal(false);
                                        getProgramsData()
                                    }}
                                >
                                    {props.t("Done")}
                                </button>
                            </Col>
                           
                        </Row>
                
                
                    </Form>
                    {displayList &&
                        displayList.map((item, i) => (
                            <div
                                className="custom-control custom-checkbox d-flex"
                                key={item.id}
                            >
                                <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    id={item.id}
                                    onChange={() => {
                                        handelProgramClick(item);
                                            
                                    }}
                                    checked={selectedPrograms.includes(item.id)}
                                />
                                <label
                                    className="custom-control-label m-2"
                                    onClick={() => {
                                        handelProgramClick(item)
                                    }}
                                >
                                    {item.programName}
                                </label>
                            </div>
                        ))}
                    {selectedPrograms.length==0 && (
                        <p className="text-danger ml-2">
                            {props.t("Please selecte programs")}
                        </p>
                    )}
                </ModalBody>
            </Modal>
           
        </React.Fragment >

    );
}

const mapStatetoProps = state => {
  const { groups, deletedSuccessfully, groupsForIds, isLoadingForGroup } = state.Group;
  const { programs, isLoading, noProgramsToDisplay, forwardToProgramView } = state.Program;
  const { selectedSchool } = state.School;
  const { userTeacher } = state.Login;



  return {
    groups, deletedSuccessfully, groupsForIds, isLoadingForGroup,
    programs, isLoading, noProgramsToDisplay, forwardToProgramView,
    selectedSchool,
    userTeacher
  }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
  getGroupsForSchoolId,
  deleteGroup,
  getProgramsForSchoolId,
  getUserTeacher,
  getGroupsForGroupIds,
  deleteProgram,
  setSelecetdSchool,
  
})(GroupDetailPage)));
