import React from "react";
import { Route,Redirect,withRouter } from "react-router-dom";
import Parse from 'parse'
import * as CommonFunctions from "../../components/Common/CommonFunctions";

const Authmiddleware = ({
	component: Component,
	layout: Layout
}) => (
		<Route
		render={props => {
			
			if (!Parse.User.current()) {
				if (props.location.pathname.startsWith("/editenquiryinside/")) {
					let res = props.location.pathname.split("/")
					if (res && res.length > 1) {
						let index = res.indexOf("editenquiryinside");
						if (res.length >= (index + 1)) {
							let schoolId = res[index + 1];
							return (<Redirect to={{ pathname: "/enquiry-form/" + schoolId, state: { from: props.location } }} />);
						}
					} 
				}
				if (props.location.pathname.startsWith("/franchise-add-enquiry/")) {
					let res = props.location.pathname.split("/")
					if (res && res.length > 1) {
						let index = res.indexOf("franchise-add-enquiry");
						if (res.length >= (index + 1)) {
							let franchiseId = res[index + 1];
							return (<Redirect to={{ pathname: "/franchise-branch-enquiry/" + franchiseId, state: { from: props.location } }} />);
						}
					} 
				}

				return (
					<Redirect to={{ pathname: "/login", state: { from: props.location } }} />
				);
			} else {
				if (CommonFunctions.isToolsHost()) {
					if (!CommonFunctions.isTools()) {
						return (
							<Redirect to={{ pathname: "/tools-schools-subscription", state: { from: props.location } }} />
						);
					}
				}
			}
				
				
			return (
		     	<Layout>
					<Component {...props} />
				</Layout>	
			);
			}}
		/>
	);

export default withRouter(Authmiddleware);

