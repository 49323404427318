import * as actions from "./actionTypes";

const initialState = {
  enquiryAnalytics: [],
  enquiryAnalyticsForSchool: [],
  enquiryAnalyticsForTenDays:[],
};

const enquiryAnalytics = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_ENQUIRY_ANALYTICS:
      state = { ...state, enquiryAnalytics: action.payload.enquiryAnalytics };
      break;
    case actions.UPDATE_ENQUIRY_ANALYTICS_FOR_SCHOOL:
      state = { ...state, enquiryAnalyticsForSchool: action.payload.enquiryAnalytics };
      break;

    case actions.GET_ENQUIRY_ANALYTICS_FOR_TEN_DAYS:
      state = { ...state, enquiryAnalyticsForTenDays: [] };
      break;
    case actions.UPDATE_ENQUIRY_ANALYTICS_FOR_TEN_DAYS:
      state = { ...state, enquiryAnalyticsForTenDays: action.payload.enquiryAnalytics };
      break;

    default:
      state = { ...state };
      break;

  }
  return state;
};

export default enquiryAnalytics;
