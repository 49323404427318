import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, TabContent, TabPane, Card, Form, Button, CardText, Nav, NavItem, NavLink, CardBody, FormGroup } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Chat from './Chat';


const Messaging = (props) => {

  return (

    <React.Fragment>

      <Chat></Chat>

    </React.Fragment >

  );
}

const mapStatetoProps = state => {




  return {

  }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {

})(Messaging)));
