
import React from 'react';
import { Row, Col, Form, Button, UncontrolledTooltip, Media } from "reactstrap";
import classnames from 'classnames';
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';

const Topbar = (props) => {


    // return(
    //     <Row className="mb-3">
    //         <Col xl="4" sm="6">
    //             <div className="mt-2">
    //                 <h4 className="mb-0 font-size-18">
    //                     <Row>
    //                         {props.backButton && <Col sm="1" xl="1" md="1" style={{marginTop : '-0.5rem'}}>
    //                             {props.backButton && <i onClick={() => { props.onClickBackButton()}} className="bx bx-chevron-left h1 text-justify text-primary"></i>}
    //                         </Col>}
    //                         <Col sm="11" xl="11" md="11">
    //                             <span>{props.t(props.title.toUpperCase()) }</span>
    //                         </Col>
    //                     </Row>
    //                 </h4>
    //             </div>
    //         </Col>
    //         {!props.hideButton && <Col lg="8" sm="6">
    //             <button onClick={() => props.onClickAdd()} className="btn btn-success float-right"><i className="mdi mdi-plus mr-1"></i>{props.t(props.buttonName)}</button>
    //         </Col>}
    //     </Row>
    // )

    return (

        <Row className="d-flex align-items-start" >
            <Col >
                <div>
                    {
                        (props.backButton) ?
                            <div className="d-flex align-items-start">

                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { props.onClickBackButton(); }}>
                                    <ul className="list-unstyled">
                                        <div >
                                            <i className="bx bx-chevron-left h1 text-primary"></i>
                                        </div>
                                    </ul>
                                </div>


                                <h4 className="mt-2">{props.title}</h4>
                            </div>
                            :
                            <h4 className="m-2">{props.title}</h4>
                    }

                </div>
            </Col>
            <Col>
                {!props.hideButton &&
                    <div className="d-flex justify-content-end" >
                        {props.hideButton2 === false && <Form className=" mt-sm-0  float-sm-right form-inline">
                            <Link
                                to={
                                    props.reportLink2
                                }
                                className="btn btn-primary waves-effect waves-light mr-2"
                            >
                                <i className=" m-1"></i>{props.t(props.buttonName2)}
                            </Link>
                        </Form>}

                        {!props.hideButton && <Form className=" mt-sm-0  float-sm-right form-inline">
                            <Link
                                to={
                                    props.reportLink
                                }
                                className="btn btn-success waves-effect waves-light mr-2"
                            >
                                <i className="fa fa-plus m-1"></i>{props.t(props.buttonName)}
                            </Link>
                        </Form>}
                    </div>
                }
            </Col>
        </Row>
    );
}
export default withNamespaces()(Topbar);
