import React from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, UncontrolledTooltip, Media, Badge } from "reactstrap";
//Import Star Ratings
import StarRatings from 'react-star-ratings';


const RatingCards = (props) => {


    const handleCardTap = (id) => {
        props.handleCardTap(id)
    }


    return (
        <React.Fragment>
            {
                props.projects.map((project, key) =>
                    <Col xl="4" sm="6" key={"_project_" + key} className="ml-0 mr-0">
                        <Card onClick={() => {
                            handleCardTap(project.id)
                        }}>
                            <CardBody>
                                <Media>
                                    <div className='d-flex'>
                                        <div className="avatar-md m-4">
                                            <span className="avatar-title rounded-circle bg-light text-primary font-size-16" style={{ width: "60px", height: "60px", display: "flex", alignItems: "center", justifyContent: "center" }}>
                                                <i className="bx bx-buildings" style={{ fontSize: "48px" }}></i>
                                            </span>
                                        </div>


                                        <div className='d-flex m-2'>
                                            <Media className="overflow-hidden" body>
                                                <h5 className="text-truncate font-size-15"><Link to="#" className="text-dark">{project.name}</Link></h5>
                                                <p className="text-muted mb-4">{project.paramDisplayName ?? project.description}</p>

                                                {project.scaleType === 1 && <h5><StarRatings
                                                    rating={parseFloat(project.ratingValue.toFixed(1))}
                                                    starRatedColor="#F1B44C"
                                                    starEmptyColor="#2D363F"
                                                    numberOfStars={5}
                                                    name='rating'
                                                    starDimension="14px"
                                                    starSpacing="3px"
                                                />      {parseFloat(project.ratingValue.toFixed(1))}</h5>}
                                                {project.scaleType === 3 && <span><h5><i className="fas fa-thumbs-up text-warning"></i>{" " + project.upCount}
                                                    <i className="ml-2 fas fa-thumbs-down text-warning"></i>{" " + project.downCount}</h5></span>
                                                }
                                            </Media>
                                        </div>
                                    </div>
                                </Media>
                            </CardBody>

                        </Card>
                    </Col>
                )
            }

        </React.Fragment>
    );
}

export default RatingCards;