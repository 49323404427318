import Parse from "parse";
import { printLog } from "./loghelper";

export const getLearningSubcategoryFromParse = (learningSubcategoryId) => {
  printLog("HL: learningSubcategory_helper : getLearningSubcategoryFromParse")

  try {
    return new Promise((resolve, reject) => {
      var LearningSubcategory = Parse.Object.extend("LearningSubcategory");
      var query = new Parse.Query(LearningSubcategory);
      query.get(learningSubcategoryId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      )
    }
    )
  } catch (error) { }
};

export const deleteLearningSubcategoryFromParse = (learningSubcategory) => {
  printLog("HL: learningSubcategory_helper : deleteLearningSubcategoryFromParse")
  try {
    return new Promise((resolve, reject) => {
        
      learningSubcategory.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        learningSubcategory.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        learningSubcategory.set("deletedBy", Parse.User.current().id);
      }
  
      learningSubcategory.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getLearningSubcategoryForcategoryId = (id, updatedDate, isLoacal) => {
  printLog("HL: learningSubcategory_helper : getLearningSubcategoryForcategoryId")

  try {
    return new Promise((resolve, reject) => {
      var LearningSubcategory = Parse.Object.extend("LearningSubcategory");
      var query = new Parse.Query(LearningSubcategory);
      query.equalTo("categoryId", id);
  
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
   
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
   
      query.ascending("order")
      query.addAscending("createdAt");
      query.limit(1000);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}
   
export const saveLearningSubcategory = (object) => {
  printLog("HL: learningSubcategory_helper : saveLearningSubcategory")

  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};

export const saveLearningSubcategoryObjects = (objects) => {
  printLog("HL: learningSubcategory_helper : saveLearningSubcategoryObjects")

  try {
      	
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(error);
          console.log("Error", error);
        }
      );
    });
  } catch (error) { }
}