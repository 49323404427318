export const UPDATE_PROGRAM = "UPDATE_PROGRAM";
export const DELETE_PROGRAM = "DELETE_PROGRAM";

export const UPDATE_PROGRAMS = 'UPDATE_PROGRAMS';
export const GET_PROGRAMS = 'GET_PROGRAMS';
export const GET_PROGRAM = 'GET_PROGRAM';

export const PROGRAM_SUCCESS = "PROGRAM_SUCCESS";
export const PROGRAM_ERROR = "PROGRAM_ERROR";

export const GET_PROGRAM_FOR_GROUPID = "GET_PROGRAM_FOR_GROUPID";
export const UPDATE_SELECTED_PROGRAM = "UPDATE_SELECTED_PROGRAM";
export const GET_SELECTED_PROGRAM = "GET_SELECTED_PROGRAM"
export const SET_SELECTED_PROGRAM = "SET_SELECTED_PROGRAM"

export const SAVE_SELECTED_PROGRAM = "SAVE_SELECTED_PROGRAM";

export const SET_LOADING = "SET_LOADING";
export const DELETED_SUCCESSFULLY_PROGRAM = "DELETED_SUCCESSFULLY_PROGRAM";
export const SET_NO_PROGRAMS = "SET_NO_PROGRAMS";
export const SET_FORWARD_TO_PROGRAM_VIEW = "SET_FORWARD_TO_PROGRAM_VIEW"

export const UPDATE_PROGRAM_TERMS_FOR_PROGRAM_ID = "UPDATE_PROGRAM_TERMS_FOR_PROGRAM_ID"
export const GET_PROGRAM_TERMS_FOR_PROGRAM_ID = "GET_PROGRAM_TERMS_FOR_PROGRAM_ID"
export const EDIT_PROGRAM_TERMS = "EDIT_PROGRAM_TERMS"


