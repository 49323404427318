
import React, { useState, useEffect } from "react";
import {

    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Col
} from "reactstrap";



import { withNamespaces } from "react-i18next";


const ImageViewer = (props) => {


    return (
        <React.Fragment >

            <Modal isOpen={props.isOpen} size="lg">
                <ModalHeader>
                    <div className="modal-title mt-0" id="modalPhotoViewer">
                        {props.t("Photos")}

                        <br />

                    </div>
                </ModalHeader>
                <ModalBody>
                    {props.images && props.images.map((image, key) => (
                        <center key={key}><img
                            key={key}
                            className="rounded m-3"
                            width="600"
                            height="600"
                            alt={image.name}
                            src={image.attributes.file._url}
                        /></center>
                    ))}
                    {
                        props.images && props.images.length === 0 && <h6>No Photos uploaded yet !!</h6>
                    }



                </ModalBody>

                <ModalFooter>

                    <button
                        className="btn btn-primary mt-2"
                        onClick={() => {

                            props.onClosePressedInImageViewer()

                        }}
                    >
                        {props.t('Close')}
                    </button>



                </ModalFooter>
            </Modal></React.Fragment >
    )
}
export default withNamespaces()(ImageViewer);