import React, { useEffect,useState} from 'react';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool} from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  CardHeader,
  CardTitle
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';

import Image from "../../../assets/images/growing_chart.svg";
import ConsolidatedReportChart from '../../MonthlyReports/Charts/ConsolidatedReportChart';
import RateOfIncreaseChart from '../../MonthlyReports/Charts/RateOfIncreaseChart';
import CummulativeChart from '../../MonthlyReports/Charts/CumulativeChart';
import ConversionTrend from '../../MonthlyReports/Charts/ConversionTrend';

const Analytics = (props) => {

    const schoolId = props.schoolId

    
    const [reportSummary, setReportSummary] = useState({
        invoiceCount: 0,
        transactionCount: 0,
        invoiceAmount: 0,
        transactionAmount: 0
    });

    const [reportData, setReportData] = useState(undefined);
    
    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    
    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    


    const [selectedMonths, setSelectedMonths] = useState([]);
    
    const [monthSchoolData, setMonthSchoolData] = useState([]);

    const [loadingState, setLoadingState] = useState(1);

    useEffect(() => {
        if (Parse.User.current()) {
            if (props.isFranchise && Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.franchiseId.length > 0) {
               
            } else
            if (schoolId) {
                if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
                    props.setSelecetdSchool(schoolId);
                }
            }
            else {
                props.history.push("/pages-404");
            }
        }else{
            props.history.push("/pages-404");
        }
        
    }, [Parse.User.current()]);

    useEffect(() => {
        setFromDate(props.fromDate)
    }, [props.fromDate]);

    useEffect(() => {
        setToDate(props.toDate);
    }, [props.toDate]);

    useEffect(() => {
        if (props.onClickApply) {
            handelDateChange();
        }
    }, [props.onClickApply]);


    const handelDateChange = () => {
        setLoadingState(2);
        getReortReportSessions();
    }
    
    

    const getDayBetweenDates=(d1, d2)=>{
        var diff = d2 - d1;
        return Math.floor(diff / 86400000);

    }
    

    const getReortReportSessions = () => {
        
        if (!fromDate || !toDate) {
            return;
        }
        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), 1);
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth() + 1, 0, 23, 59);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {
              
                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('MMM YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }
        setSelectedMonths(dateList);

        let days = getDayBetweenDates(startDate,endDate, );

        let query = new Parse.Query("FeeAnalytics");

        query.greaterThanOrEqualTo('analyticsDate', startDate);
        query.lessThanOrEqualTo('analyticsDate', endDate);
        query.notEqualTo('isDeleted', true);
        
        query.limit(1000)


        let schoolIds = [];
        if (props.isFranchise) {
            for (const school of props.schools) {
                schoolIds.push(school.id)
            }
            query.containedIn('schoolId', schoolIds)
            query.limit(schoolIds.length * days + 1000);
        } else {
            query.equalTo('schoolId', schoolId)
            query.limit(days + 1000);
        }
        query.find().then((feeAnalyticsObjects) => {
            setReportData(feeAnalyticsObjects)
            if (!feeAnalyticsObjects || feeAnalyticsObjects.length === 0) {
                setLoadingState(5)
            }           
        });
    }


    


    useEffect(() => {
        if (props.schools && props.schools.length > 0 && reportData && reportData.length > 0) {
            
            let invoiceCount = 0;
            let transactionCount = 0;
            let invoiceAmount = 0;
            let transactionAmount = 0;
            for (const rd of reportData) {
                invoiceCount = invoiceCount + rd.attributes.invoiceCount;
                transactionCount = transactionCount + rd.attributes.transactionCount;
                invoiceAmount = invoiceAmount + rd.attributes.invoiceAmount;
                transactionAmount = transactionAmount + rd.attributes.transactionAmount;
                    
            }

            setReportSummary({
                invoiceCount: invoiceCount,
                transactionCount: transactionCount,
                invoiceAmount: invoiceAmount,
                transactionAmount:transactionAmount
            })
           

            let monthsData = [];
            for (const i in selectedMonths) {
                let tempMonth = selectedMonths[i];
                let monthData = reportData.filter(
                    (session) => sameMonth(session.attributes.analyticsDate, selectedMonths[i].date)
                );
                let iCount = 0;
                let tCount = 0;

                let iAmount = 0;
                let tAmount = 0;
                for (const t of monthData) {
                    iCount = iCount + t.attributes.invoiceCount;
                    tCount = tCount + t.attributes.transactionCount;
                    iAmount = iAmount + t.attributes.invoiceAmount;
                    tAmount = tAmount + t.attributes.transactionAmount;
                    
                   
                }
                 
                tempMonth.invoiceCount = iCount;
                tempMonth.transactionCount = tCount;
                tempMonth.invoiceAmount = iAmount;
                tempMonth.transactionAmount = tAmount;
                monthsData.push(tempMonth);

            }
            setSelectedMonths(monthsData);
            let schoolMonth = [];
            let schools = props.isFranchise? props.schools:[props.selectedSchool]
            for (const s in schools) {
                
                let school = schools[s];
                let schoolObj = { id: school.id, schoolName: school.attributes.Name }
                
                


                let sICount = 0;
                let sTCount = 0;
                let sIAmount = 0;
                let sTAmount = 0;
               

                let preICount = 0;
                let preTCount = 0;
                let preIAmount = 0;
                let preTAmount = 0;

                for (const i in selectedMonths) {
                    let tempMonth = { date: selectedMonths[i].date, dateString: selectedMonths[i].dateString };
                    let monthData = reportData.filter(
                        (session) => sameMonth(session.attributes.analyticsDate, selectedMonths[i].date) && session.attributes.schoolId === school.id
                    );

                    
                    let iCount = 0;
                    let tCount = 0;
                    let iAmount = 0;
                    let tAmount = 0;

                    for (const t of monthData) {
                        iCount = iCount + t.attributes.invoiceCount;
                        tCount = tCount + t.attributes.transactionCount;
                        iAmount = iAmount + t.attributes.invoiceAmount;
                        tAmount = tAmount + t.attributes.transactionAmount;
                    }

                    sICount = sICount + iCount;
                    sTCount = sTCount + tCount;
                    sIAmount = sIAmount + iAmount;
                    sTAmount = sTAmount + tAmount;


                    tempMonth.rateOfInvoice = getRateOfIncrice(preICount, iCount);
                    tempMonth.rateOfTransaction = getRateOfIncrice(preTCount, tCount)
                    tempMonth.rateOfInvoiceAmount = getRateOfIncrice(preIAmount, iAmount);
                    tempMonth.rateOfTransactionAmount = getRateOfIncrice(preTAmount, tAmount)


                    preICount = iCount;
                    preTCount = tCount;
                    preIAmount = iAmount;
                    preTAmount = tAmount;

                    tempMonth.invoiceCount = iCount;
                    tempMonth.transactionCount = tCount;
                    tempMonth.invoiceAmount = iAmount;
                    tempMonth.transactionAmount = tAmount;

                    
                    schoolObj[selectedMonths[i].dateString] = tempMonth;
                }

                schoolObj.invoiceCount = sICount;
                schoolObj.transactionCount = sTCount;
                schoolObj.invoiceAmount = sIAmount;
                schoolObj.transactionAmount = sTAmount;
                schoolMonth.push(schoolObj);
            }
            setMonthSchoolData(schoolMonth);

            setLoadingState(3);

            scroller.scrollTo('scrollPossition', {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100,
            });
        }
    }, [props.schools, reportData]);


    const sameMonth = (d1, d2) => {
        if (d1.getMonth() === d2.getMonth() && d1.getFullYear() === d2.getFullYear()) {
            return true;
        }
        return false;
    }

    const getConversionRate = (t1, t2) => {
        if (t1 === 0 || t2 === 0) {
            return "-- ";
        }
        let rate = t2 * 100 / t1;
        return rate.toFixed(2) + "%";
    }

    const getRateOfIncrice = (oldNumber, newNumber) => {
        if (oldNumber > 0) {
            var decreaseValue = oldNumber >newNumber? oldNumber -newNumber:newNumber -oldNumber;
            let rate = (decreaseValue / oldNumber) * 100;
            return oldNumber >newNumber ? -1*rate.toFixed(2):rate.toFixed(2);
        } else {
            return undefined;
        } 
    }

    const onClickDownload = () => {
        let sDate = selectedMonths[0].dateString;
        let eDate = selectedMonths[selectedMonths.length - 1].dateString;
            
        let finalData = [];
        let titles =["School Name"]
        let fileName = `consolidated_fee_report_${sDate}_${eDate}.xlsx`;
        let sheetName =  sDate+"-"+eDate;
        
        finalData.push([`Report Type : Invoice Amount and Collected Amount consolidated report`]);
        finalData.push([`Selected Month : ${sDate} to ${eDate}`,]);

        finalData.push([]);

        finalData.push([`Invoice Amount : ${reportSummary.invoiceAmount} `,]);
        finalData.push([`Collected Amount : ${reportSummary.transactionAmount} `,]);
        finalData.push([`Collection Rate : ${getConversionRate(reportSummary.invoiceAmount, reportSummary.transactionAmount)}`,]);


        finalData.push([]);
        finalData.push([]);
        finalData.push(["Month wise summary"]);
        finalData.push(["Month"	,"Invoice Amount",	"Collected Amount"	,"Collection"]);
        for (const month of selectedMonths) {
            finalData.push([month.dateString, month.invoiceAmount, month.transactionAmount, getConversionRate(month.invoiceAmount, month.transactionAmount)]);
            titles.push(month.dateString);
        }
        finalData.push([]);
        finalData.push([]);

        let finalData2 = [];
        finalData2.push(["School wise summary"]);
        finalData2.push(["School Name",	"Invoice Amount"	,"Collected Amount",	"Collection"]);
        
        for (const school of monthSchoolData) {
            finalData2.push([school.schoolName	,school.invoiceAmount,school.transactionAmount, getConversionRate(school.invoiceAmount, school.transactionAmount)]);
        }
        finalData2.push([]);
        finalData2.push([]);

        let enquiryRateList = [];
        let admissionRateList = [];
        for (const school of monthSchoolData) {
            let eRowData = [];
            let aRowData = [];
            eRowData.push(school.schoolName);
            aRowData.push(school.schoolName);
            for (const month of selectedMonths) {

                eRowData.push(rateOfChangeReoprt(school[month.dateString].invoiceAmount, school[month.dateString].rateOfInvoiceAmount));
                aRowData.push(rateOfChangeReoprt(school[month.dateString].transactionAmount,school[month.dateString].rateOfTransactionAmount))
            }
            enquiryRateList.push(eRowData);
            admissionRateList.push(aRowData);
        }

        
        let finalData3 = [];
        finalData3.push(["New Invoice Amount Trend From "+sDate +" to "+ eDate]);
        finalData3.push(titles);
        finalData3 = finalData3.concat(enquiryRateList);
        finalData3.push([]);
        finalData3.push([]);

        let finalData4 = [];
        finalData4.push(["Cumulative Collected Amount Trend From  "+sDate +" to "+ eDate]);
        finalData4.push(titles);
        finalData4 = finalData4.concat(admissionRateList);

        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            let ws2 = XLSX.utils.aoa_to_sheet(finalData2);
            let ws3 = XLSX.utils.aoa_to_sheet(finalData3);
            let ws4 = XLSX.utils.aoa_to_sheet(finalData4);
            XLSX.utils.book_append_sheet(wb, ws, "Month wise summary");
            XLSX.utils.book_append_sheet(wb, ws2, "School wise summary");
            XLSX.utils.book_append_sheet(wb, ws3, "New Invoice Amount Trend");
            XLSX.utils.book_append_sheet(wb, ws4, "Cumulative Amount Trend");
            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;
            schoolData = schoolData.concat(finalData2);
            schoolData = schoolData.concat(finalData3);
            schoolData = schoolData.concat(finalData4);

            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            XLSX.utils.book_append_sheet(wb, ws, sheetName);
            XLSX.writeFile(wb, fileName);
        }

       
    }
    const rateOfChangeReoprt = (aVal,val) => {
        if (val > 0) {
            return aVal + " (" + val + "%↑)";
            
        } else if (val < 0) {
            return aVal +" (" + Math.abs(val) + "%↓)";
        } else {
            return "-";
        }
    }

    const rateOfChangeUI = (aVal,val) => {
        if (val > 0) {
            return (<p>{aVal}<span className="text-success">{ " ("+val +"%↑)"}</span></p>)
        } else if (val < 0) {
            return(<p>{aVal}<span className="text-danger">{" ("+Math.abs(val) +"%↓)"}</span></p>)
            
        } else {
            return(<p>{aVal?aVal:"--"}</p>)
        }
    }
    const onClickBackButton = () => {
        window.history.back();
    }

    return (
        <React.Fragment>      
            {props.showTitle && <CardTitle>Fee Analytics Report</CardTitle>}
            {loadingState > 0 && props.showDatePickers && 
                <Col >
                    <Row className="justify-content-center bg-light ml-2">
               
                        <Row>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("From")} :
                                    </Label>
                                    <DatePicker
                                        selected={fromDate}
                                        onChange={(date) => {
                                            setFromDateError(false)
                                            setFromDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        maxDate={toDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {fromDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={4} xs={6}>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <Label className="mb-0">
                                        {props.t("To")} :
                                    </Label>
                                    <DatePicker
                                        selected={toDate}
                                        onChange={(date) => {
                                            setToDateError(false);
                                            setToDate(date)
                                        }}
                                        className="form-control"
                                        placeholderText={props.t("Select Date")}
                                        startDate={fromDate}
                                        endDate={toDate}
                                        minDate={fromDate}
                                        dateFormat="MMM-yyyy"
                                        required
                                        errorMessage="Please select"
                                        showMonthYearPicker
                                    />
                                </FormGroup>
                                {toDateError && <p className="text-danger ml-2">Please select</p>}
                            </Col>
                            <Col lg={3} >
                                <div className="mt-4 mb-2 p-1">
                                    <button
                                        disabled={loadingState === 2}
                                        onClick={(e, v) => {
                                            let next = true;
                                            if (!toDate) {
                                                setToDateError(true);
                                                next = false;
                                            }
                                            if (!fromDate) {
                                                setFromDateError(true);
                                                next = false;
                                            }
                                            if (next) {
                                                handelDateChange();
                                            }
                            
                                        }}
                                        className="w-md  btn btn-primary"
                                    >
                                        {props.t("Apply")}
                                    </button>
                      
                                </div>
                            </Col>
                        </Row>
                        {/* </Form> */}
                    </Row>
                </Col>
            }
            {(loadingState === 0  || loadingState === 2)&&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState===0?"Setting up...":"Preparing Report"}</Label>
                    </div>
                </Col>
            }

                
            <Element name={"scrollPossition"} >
                        <div></div> 
            </Element>

                
            {loadingState === 5 && <Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img mt-8">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{'No data avilable between '+selectedMonths[0].dateString + " and " + selectedMonths[selectedMonths.length - 1].dateString}</h3>
                    <p>{'Plese select other dates '}.</p>
                    
                    
                </CardBody>
            </Card>}

                {
                    loadingState == 3 && selectedMonths && selectedMonths.length > 0 &&
                    <div>
                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Invoice Amount vs Collected Amount  </CardTitle>
                                    <ConsolidatedReportChart selectedMonths={selectedMonths}type={"fee"} />
                                </CardBody>
                            </Card>
                           
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Invoice Amount to Collection rate </CardTitle>
                                    <RateOfIncreaseChart selectedMonths={selectedMonths}type={"fee"}/>
                                </CardBody>
                            </Card>
                           
                        </Col>
                        
                    </Row>

                    <Row>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Cumulative Invoice Amount and Collected Amount </CardTitle>
                                    <CummulativeChart selectedMonths={selectedMonths} type={"fee"}/>
                                </CardBody>
                            </Card>
                           
                        </Col>
                        <Col lg={6}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-4"> Monthly Collection growth rate </CardTitle>
                                    <ConversionTrend selectedMonths={selectedMonths}type={"fee"}/>
                                </CardBody>
                            </Card>
                           
                        </Col>
                        
                    </Row>
                   </div>
                }

                {loadingState==3&&selectedMonths && selectedMonths.length > 0 && <Card>
                    <CardBody>
                        <CardHeader>  <CardTitle> Report Summary </CardTitle> </CardHeader>
                         <Col lg={12}>
                            <div className="mt-2 mb-2 p-1 d-flex justify-content-end">
                                <button
                                    onClick={() => {
                                        if (selectedMonths && selectedMonths.length > 0) {
                                            onClickDownload();
                                        }
                                                            
                                    }}
                                    className="w-md  btn btn-primary"
                                >
                                    <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                    {props.t("Download Report")}
                                </button>
                            </div>
                        </Col>
                       
                        <div className=''>
                            <h6 className="ml-2">
                                {"Selected Monts:" + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}
                            </h6>
                            <h6 className="ml-2">{"Total Invoice Amount:" + reportSummary.invoiceAmount}</h6>
                            <h6 className="ml-2">{"Total Collected Amount:" + reportSummary.transactionAmount}</h6>
                            <h6 className="ml-2">{"Collection Rate:" + getConversionRate(reportSummary.invoiceAmount, reportSummary.transactionAmount)}</h6>    
                        </div>

                        <CardTitle>Month wise summary </CardTitle>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                                    <Thead>
                                        <Tr>
                                            <Th>Month</Th>
                                            <Th data-priority="1">Invoice Amount</Th>
                                            <Th data-priority="3">Collected Amount</Th>
                                            <Th data-priority="1">Collection Rate</Th>
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                       
                                        {
                                            selectedMonths.map((month, key) => (
                                                <Tr key={key}>
                                                    <Th>{month.dateString}</Th>
                                                    <Td>{month.invoiceAmount}</Td>
                                                    <Td>{month.transactionAmount}</Td>
                                                    <Td>{getConversionRate(month.invoiceAmount, month.transactionAmount)}</Td>
                                                    
                                                </Tr>
                                            ))
                                        }
                                                      
                                    </Tbody>
                                </Table>
                            </div>
                        </div>


                        <CardTitle>School wise summary </CardTitle>
                        <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                                    <Thead>
                                        <Tr>
                                            <Th>School Name</Th>
                                            <Th data-priority="1">Invoice Amount</Th>
                                            <Th data-priority="3">Collected Amount</Th>
                                            <Th data-priority="1">Collection Rate</Th>
                                                           
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                       
                                        {
                                            monthSchoolData.map((school) => (
                                                <Tr key={school.id}>
                                                    <Th>{school.schoolName}</Th>
                                                    <Td>{school.invoiceAmount}</Td>
                                                    <Td>{school.transactionAmount}</Td>
                                                    <Td>{getConversionRate(school.invoiceAmount, school.transactionAmount)}</Td>
                                                    
                                                </Tr>
                                            ))
                                        }
                                                      
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
               
                        
                    </CardBody>
                
                </Card>}

                {loadingState==3&&selectedMonths && selectedMonths.length > 0 && <Card>
                    <CardBody>
                        <CardHeader><CardTitle>{"Invoice Amount Trend From " + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}</CardTitle></CardHeader>
                         <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                                    <Thead>
                                        <Tr>
                                            <Th>School Name</Th>
                                            {
                                                selectedMonths.map((month, key) => (
                                                    <Th data-priority="1" key={key}> {month.dateString} </Th>
                                                ))
                                            }                   
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                       
                                        {
                                            monthSchoolData.map((school) => (
                                                <Tr key={school.id}>
                                                    <Th>{school.schoolName}</Th>

                                                    {selectedMonths.map((month) => (
                                                        <Td key={school.id}>{rateOfChangeUI(school[month.dateString].invoiceAmount,school[month.dateString].rateOfInvoiceAmount)}</Td>
                                               
                                                    ))} 
                                                </Tr>
                                            ))
                                        }
                                                      
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>}

                {loadingState==3&&selectedMonths && selectedMonths.length > 0 && <Card>
                    <CardBody>
                        <CardHeader><CardTitle>{"Cumulative Collection  Trend From " + selectedMonths[0].dateString + " to " + selectedMonths[selectedMonths.length - 1].dateString}</CardTitle></CardHeader>
                         <div className="table-rep-plugin">
                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                <Table id="tech-companies-1" className="table table-striped table-bordered table-sm">
                                    <Thead>
                                        <Tr>
                                            <Th>School Name</Th>
                                            {
                                                selectedMonths.map((month, key) => (
                                                    <Th data-priority="1" key={key}> {month.dateString} </Th>
                                                ))
                                            }                   
                                        </Tr>
                                    </Thead>
                                    <Tbody>
                                       
                                        {
                                            monthSchoolData.map((school) => (
                                                <Tr key={school.id}>
                                                    <Th>{school.schoolName}</Th>

                                                    {selectedMonths.map((month) => (
                                                        <Td key={school.id}>{rateOfChangeUI(school[month.dateString].transactionAmount,school[month.dateString].rateOfTransactionAmount)}</Td>
                                               
                                                    ))}    
                                                </Tr>
                                            ))
                                        }
                                                      
                                    </Tbody>
                                </Table>
                            </div>
                        </div>
                    </CardBody>
                </Card>}

                {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && 
                    <div className="mt-2 mb-2 p-1  d-flex justify-content-end">
                        <button
                            onClick={() => {
                                if (selectedMonths && selectedMonths.length > 0) {
                                    onClickDownload();
                                }
                                                    
                            }}
                            className="w-md  btn btn-primary"
                    >
                        <i className='bx bx-download font-size-16 align-middle m-1'></i>
                            {props.t("Download Report")}
                        </button>
                    </div>
                }

            <div style={{ height: "400px" }}></div>
        </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { schools,selectedSchool } = state.School;
    return { schools, selectedSchool};
}
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
    setSelecetdSchool,
    })(Analytics)
  )
);
