import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardBody, CardFooter, UncontrolledTooltip } from "reactstrap";
const CardTeacherView = (props) =>{
    const [teacher, teacherOps] = useState("");
    useEffect(()=>{
        teacherOps(props.teacher)
    },[props.teacher])
    return(
        <React.Fragment>
            {
                teacher && 
                <Col xl="3" sm="6">
                        <Card className="text-center">
                            <CardBody>
                                {
                                    !teacher.attributes.Photo ?
                                        <div className="avatar-sm mx-auto mb-4">
                                            <span className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-16"}>
                                                {teacher.attributes.Name.charAt(0).toUpperCase()}
                                            </span>
                                        </div>
                                        :
                                        <div className="mb-4">
                                            <img className="rounded-circle avatar-sm" src={teacher.attributes.Photo._url} alt="" />
                                        </div>
                                }
    
                                <h5 className="font-size-15"><Link to="#" className="text-dark">{teacher.attributes.Name}</Link></h5>
                                {teacher.attributes.Role === 1 && <p className="text-muted mb-0">Teacher</p>}
                                {teacher.attributes.Role === 2 && <p className="text-muted mb-0">Centerhead</p>}
                            </CardBody>
                            <CardFooter className="bg-transparent border-top">
                                <div className="contact-links d-flex font-size-20">
                                    {/* <div className="flex-fill">
                                        <Link to="#" id={"message" + teacher.id}>
                                            <i className="bx bx-message-square-dots"></i>
                                            <UncontrolledTooltip placement="top" target={"message" + teacher.id}>
                                                Message
                                                    </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                    <div className="flex-fill">
                                        <Link to="#" id={"project" + teacher.id}>
                                            <i className="bx bx-pie-chart-alt"></i>
                                            <UncontrolledTooltip placement="top" target={"project" + teacher.id}>
                                                Projects
                                                    </UncontrolledTooltip>
                                        </Link>
                                    </div> */}
                                    <div className="flex-fill">
                                        <Link to={`/staffprofile/${teacher.attributes.SchoolID}/${teacher.id}`} id={"profile" +teacher.id}>
                                            <i className="bx bx-user-circle"></i>
                                            <UncontrolledTooltip placement="top" target={"profile" +teacher.id}>
                                                Profile
                                            </UncontrolledTooltip>
                                        </Link>
                                    </div>
                                </div>
                            </CardFooter>
                        </Card>
                    </Col>
            
            }
        </React.Fragment>
    )
}
export default CardTeacherView;