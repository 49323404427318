import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool, getAllKids, getTripsForSchoolId, getGroupMapsForTripsGroupIds } from '../../store/actions';
import { withRouter } from "react-router-dom";
import { connect } from 'react-redux';
import { Row, Col, FormGroup, Label } from "reactstrap";
import { Table } from 'react-super-responsive-table';
import * as XLSX from 'xlsx/xlsx.mjs';

const TripsReport = (props) => {
    const [displayData, setDisplayData] = useState([]);
    const [displayTitles, setDisplayTitles] = useState([]);
    const [classroomMap, setClassroomMap] = useState({});
    const [tripCounts, setTripCounts] = useState({});
    const [removeNoTripKids, setRemoveNoTripKids] = useState(false);

    useEffect(() => {
        if (props.schoolId) {
            props.getTripsForSchoolId(props.schoolId);
            props.getAllKids(props.schoolId);
        }
    }, [props.schoolId]);

    useEffect(() => {
        if (props.classrooms?.length > 0) {
            let map = {};
            for (const cl of props.classrooms) {
                map[cl.id] = cl.attributes.Name ?? "-";
            }
            setClassroomMap(map);
        }
    }, [props.classrooms]);

    const getStudentName = (kidId) => {
        if (props.kids?.length > 0) {
            let temp = props.kids.find(kid => kid.id === kidId);
            return temp ? temp.attributes.Name : "-";
        }
        return "-";
    };

    useEffect(() => {
        if (props.trips?.length > 0) {
            let list = [];
            let counts = {};
            for (const trip of props.trips) {
                list.push(trip.attributes.groupId);
                counts[trip.attributes.groupId] = 0;
            }

            if (list.length > 0) {
                props.getGroupMapsForTripsGroupIds(list);
            }

            for (const trip of props.trips) {
                for (const kid of props.kids) {
                    const matchingMaps = props.tripGroupmaps?.filter(map =>
                        map.attributes &&
                        map.attributes.groupId === trip.attributes.groupId &&
                        map.attributes.userId === kid.id
                    );

                    if (matchingMaps?.length > 0) {
                        counts[trip.attributes.groupId] += 1;
                    }
                }
            }
            setTripCounts(counts);
        }
    }, [props.trips, props.kids, props.tripGroupmaps]);

    useEffect(() => {
        if (props.kids?.length > 0 && props.trips?.length > 0 && props.tripGroupmaps) {
            let list = [];
            let titles = ["Sl No", "Student Name", "Classroom"];

            for (const trip of props.trips) {
                titles.push(trip.attributes.name);
            }
            setDisplayTitles(titles);

            for (const kid of props.kids) {
                let kidList = [];
                kidList.push(list.length + 1);
                kidList.push(getStudentName(kid.id));
                kidList.push(classroomMap[kid.attributes.ClassRoomID] ?? "-");

                let tripCount = 0;
                for (const trip of props.trips) {
                    const matchingMaps = props.tripGroupmaps?.filter(map =>
                        map.attributes &&
                        map.attributes.groupId === trip.attributes.groupId &&
                        map.attributes.userId === kid.id
                    );

                    const isPartOfTrip = matchingMaps?.length > 0;
                    if (isPartOfTrip) {
                        tripCount++;
                    }
                    kidList.push(isPartOfTrip ? '✓' : '✕');
                }

                if (!removeNoTripKids || tripCount > 1) {
                    list.push(kidList);
                }
            }
            setDisplayData(list);
        }
    }, [props.kids, props.trips, props.tripGroupmaps, removeNoTripKids]);

    const onClickDownload = () => {
        let finalData3 = [];
        let fileName = `Trips_Report.xlsx`;
        finalData3.push(["Trips Report"]);
        finalData3.push([`Report for: ${props.isFranchise ? props.selectedSchool.label : props.selectedSchool && props.selectedSchool.attributes.Name}`]);
        finalData3.push([]);
        finalData3.push(displayTitles);
        finalData3 = finalData3.concat(displayData);
        let footerRow = ["", "Total Students per Trip", ""];
            for (let i = 0; i < props.trips.length; i++) {
                let trip = props.trips[i];
                footerRow.push(tripCounts[trip.attributes.groupId] || 0);
        }
        finalData3.push(footerRow);
        let wb = XLSX.utils.book_new();
        let ws3 = XLSX.utils.aoa_to_sheet(finalData3);
        XLSX.utils.book_append_sheet(wb, ws3, "Trips Report");
        XLSX.writeFile(wb, fileName);
    };

    return (
        <React.Fragment>
            <Row>
                <Col lg={6}>
                    <FormGroup>
                        <Label className='m-1'>{props.t("Filter by")} :</Label>
                        <select
                            className="form-control"
                            value={removeNoTripKids ? 2 : 1}
                            onChange={(e) => {
                                if (e.target.value == 1 && removeNoTripKids) {
                                    setRemoveNoTripKids(false);
                                }
                                if (e.target.value == 2 && !removeNoTripKids) {
                                    setRemoveNoTripKids(true);
                                }
                            }}
                        >
                            <option value={1}>{props.t("All Students")}</option>
                            <option value={2}>{props.t("Students with more than 1 Trip Access")}</option>
                        </select>
                    </FormGroup>
                </Col>
                <Col lg={6} className='mt-4 mb-2'>
                    <div className="d-flex justify-content-end">
                        <button
                            onClick={() => {
                                if (displayData && displayData.length > 0) {
                                    onClickDownload();
                                }
                            }}
                            className="w-md btn btn-primary"
                        >
                            <i className='bx bx-download font-size-16 align-middle m-1'></i>
                            {props.t("Download Report")}
                        </button>
                    </div>
                </Col>
            </Row>
            <div className="table-responsive mb-0" data-pattern="priority-columns">
                <Table className="table table-striped table-bordered">
                    <thead>
                        <tr>
                            {displayTitles?.map((title, index) => (
                                <th key={index}>{title}</th>
                            ))}
                        </tr>
                    </thead>
                    <tbody>
                        {displayData?.map((dataRow, row) => (
                            <tr key={row}>
                                {dataRow.map((data, cell) => (
                                    <td key={cell}>{data}</td>
                                ))}
                            </tr>
                        ))}
                    </tbody>
                    <tfoot>
                        <tr>
                            <td colSpan={3} className='text-center'><strong>Total Students under each Trip</strong></td>
                            {props.trips?.map((trip, index) => (
                                <td key={index}>
                                    <strong>{tripCounts[trip.attributes.groupId] || 0}</strong>
                                </td>
                            ))}
                        </tr>
                    </tfoot>
                </Table>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { trips } = state.Trips;
    const { tripGroupmaps } = state.GroupMap;
    const { kids } = state.Kid;
    return {
        schools,
        selectedSchool,
        kids,
        trips,
        tripGroupmaps,
        classrooms
    };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            getAllKids,
            getTripsForSchoolId,
            getGroupMapsForTripsGroupIds,
        })(TripsReport)
    )
);
