import Parse from "parse";
import { printLog } from "./loghelper";

const getFeeTemplateFromParse = (feeTemplateId) => {
  printLog("HL: freeTemplate_helper : getFeeTemplateFromParse")

  try {
    return new Promise((resolve, reject) => {
      var FeeTemplate = Parse.Object.extend("FeeTemplate");
      var query = new Parse.Query(FeeTemplate);
      query.notEqualTo("deleted", true);
      query.get(feeTemplateId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const deleteFeeTemplateFromParse = (feeTemplate) => {
  printLog("HL: freeTemplate_helper : deleteFeeTemplateFromParse")
  
  try {
    return new Promise((resolve, reject) => {
      feeTemplate.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

const getFeeTemplateForSchoolId = (id, updatedDate, isLocal) => {
  printLog("HL: freeTemplate_helper : getFeeTemplateForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var FeeTemplate = Parse.Object.extend("FeeTemplate");
      var query = new Parse.Query(FeeTemplate);
      query.limit(1000)
      query.equalTo("schoolId", id);
      query.descending("createdAt");

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("deleted", true);
      }
      

      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("deleted", true);
      }

      //query.ascending("name");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const createTemplateForSchool = (pram) => {
  printLog("HL: freeTemplate_helper : createTemplateForSchool")
  try {
    return new Promise((resolve, reject) => {
      let FeeTemplate = Parse.Object.extend("FeeTemplate");
      let feeTemplate = new FeeTemplate();
      if (pram.id) {
        feeTemplate.id = pram.id;
      }
      feeTemplate.set("name", pram.title);
      feeTemplate.set("schoolId", pram.school_id);
      feeTemplate.set(
        "instituteId",
        Parse.User.current().attributes.instituteId
      );
      feeTemplate.set("type", pram.type); //for school type  2 is for franchise type
      if (pram.royaltyAmount > 0) {
        feeTemplate.set("royaltyAmount", pram.royaltyAmount);
      }
      if (pram.feeType) {
        feeTemplate.set("feeType", pram.feeType);
      }
      if (pram.programType) {
        feeTemplate.set("programType", pram.programType);
      }
      if (pram.corporateId) {
        feeTemplate.set("corporateId", pram.corporateId);
      }
      feeTemplate.save().then((result) => {
        let feeTemlateObj = result;
        let tempItemsObj = [];
        pram.items.map((val, key) => {
          let FeeTemplateItem = Parse.Object.extend("FeeTemplateItem");
          let fti = new FeeTemplateItem();
          if (val.id) {
            fti.id = val.id;
          }
          fti.set("discount", +val.discount);
          fti.set("dueDate", val.duedate);
          fti.set("itemDescription", val.itemDescription);
          fti.set("feeType", val.feeType);
          fti.set("amount", +val.amount);
          fti.set("discountType", +val.discountType);
          fti.set("feeTemplateId", feeTemlateObj.id);
          fti.set("totalAmount", +val.totalAmount);
          fti.set("discountRemark", val.discountRemark);
          fti.set("royalty", +val.royalty);
          fti.set("tax", +val.tax);
          fti.set("hsn",val.hsn);
          fti.set("slno", key);
          if (val.deleted) {
            fti.set("isDeleted", true);
          }
          tempItemsObj.push(fti);
        });
        Parse.Object.saveAll(tempItemsObj).then((results) => {
          resolve(feeTemlateObj);
        });
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const deleteTemplateForSchool = (id) => {
  printLog("HL: freeTemplate_helper : deleteTemplateForSchool")
  try {
    return new Promise((res, rej) => {
      let FeeTemplate = Parse.Object.extend("FeeTemplate");
      let feeTemplate = new FeeTemplate();
      feeTemplate.id = id;
      feeTemplate.set("deleted", true);
      feeTemplate.save().then((result) => {
        res(result);
      });
    });
  } catch (err) {
    console.log(err);
  }
};
export const getFeeTypes = () => {
  printLog("HL: freeTemplate_helper : getFeeTypes")
  try {
    return new Promise((res, rej) => {
      let query = new Parse.Query("FeeType");
      query.find().then((results) => {
        res(results);
      });
    });
  } catch (err) {
    console.error(err);
  }
};

export const editFeeTemplateForSchool = (feeTemplate) => {
  printLog("HL: freeTemplate_helper : editFeeTemplateForSchool")
  try {
    return new Promise((resolve, reject) => {
      feeTemplate.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export {
  getFeeTemplateFromParse,
  deleteFeeTemplateFromParse,
  getFeeTemplateForSchoolId,
};
