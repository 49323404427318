import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as CurriculumSchoolMapHelper from "../../../helpers/ParseHelpers/learningCurriculumSchoolaMap_helper";
import * as actionType from "./actionTypes";
import * as CurriculumSchoolMapAction from "./actions";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getLearningCurriculumSchoolMapForCurriculumId({ payload: { curriculumId } }) {
    try {

        const localResponse = yield call(CurriculumSchoolMapHelper.getLearningCurriculumSchoolMapForCurriculumId, curriculumId);
        yield put(CurriculumSchoolMapAction.clearLearningCurriculumForId([]));
        yield put(CurriculumSchoolMapAction.updateLearningCurriculumsSchoolMapForCurriculumId(localResponse));

        const serverResponse = yield call(CurriculumSchoolMapHelper.getLearningCurriculumSchoolMapForCurriculumId, curriculumId);
        if (serverResponse && serverResponse.length > 0)
        {
            yield put(CurriculumSchoolMapAction.clearLearningCurriculumForId([]));
            yield put(CurriculumSchoolMapAction.updateLearningCurriculumsSchoolMapForCurriculumId(serverResponse));


            var nonDeleted = serverResponse.filter(
                (curriculum) =>
                    curriculum.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (curriculum) =>
                    curriculum.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* getLearningCurriculumSchoolMapForSchoolId({ payload: { schoolId } }) {
    try {

        const serverResponse = yield call(CurriculumSchoolMapHelper.getLearningCurriculumSchoolMapForSchoolId, schoolId);
        if (serverResponse)
        {
            yield put(CurriculumSchoolMapAction.updateLearningCurriculumsSchoolMapForSchoolId(serverResponse));


            var nonDeleted = serverResponse.filter(
                (curriculum) =>
                    curriculum.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (curriculum) =>
                    curriculum.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId({ payload: { schoolId,curriculumId } }) {
    try {

        const serverResponse = yield call(CurriculumSchoolMapHelper.getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId,schoolId, curriculumId );
        if (serverResponse)
        {
            yield put(CurriculumSchoolMapAction.updateLearningCurriculumsForCurriculumIdAndSchoolId(serverResponse));
        }
    } catch (error) {

    }
}

function* saveLearningCurriculumSchoolMap({ payload: { CurriculumSchoolMaps } }) {
    try {
        const response = yield call(CurriculumSchoolMapHelper.saveLearningCurriculumSchoolMap, CurriculumSchoolMaps);
        if (response) {
            yield put(CurriculumSchoolMapAction.updateLearningCurriculumsSchoolMapForCurriculumId(response));
            
        } else {

        }
    } catch (error) {
        // yield put(apiError(error));
    }
}


export function* watchGetLearningCurriculumForId() {
    yield takeEvery(actionType.GET_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_CURRICULUM_ID, getLearningCurriculumSchoolMapForCurriculumId);
}

export function* watchGetLearningCurriculumForSchool() {
    yield takeEvery(actionType.GET_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_SCHOOL_ID, getLearningCurriculumSchoolMapForSchoolId);
}

export function* watchGetLearningCurriculumSchoolMapForCurriculumIdAndSchoolId() {
    yield takeEvery(actionType.GET_LEARNING_CURRICULUM_SCHOOL_MAP_FOR_CURRICULUM_ID_AND_SCHOOL_ID, getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId);
}

export function* watchSaveLearningCurriculumSchoolMap() {
    yield takeEvery(actionType.SAVE_LEARNING_CURRICULUMS_SCHOOL_MAPS, saveLearningCurriculumSchoolMap);
}

function* LearningCurriculumsSchoolMapSaga() {
    yield all([
        fork(watchGetLearningCurriculumForId),
        fork(watchSaveLearningCurriculumSchoolMap),
        fork(watchGetLearningCurriculumForSchool),
        fork(watchGetLearningCurriculumSchoolMapForCurriculumIdAndSchoolId),
    ]);
}
export default LearningCurriculumsSchoolMapSaga;