import React, {useState} from 'react';
import { Link } from "react-router-dom";
import { Col, Card, CardTitle, CardSubtitle, Table, CardBody, UncontrolledTooltip, Media, Badge } from "reactstrap";
import Moment from "moment";
import SweetAlert from "react-bootstrap-sweetalert";
const ListForProgram = (props) => {


    const tripFrequencyList = [
        { value: "1", name: "Weekdays" },
        { value: "2", name: "All week" },
        { value: "3", name: "Weekends" },
        { value: "4", name: "Weekdays+Sat" },
        { value: "5", name: "Weekdays+Sun" },
    ]

    const [resetTrip, setresetTrip] = useState(undefined);

    function getTimeStringForDate(date) {
        return Moment(date).format("HH:mm A");
    }

    const getTeacherName = (teacherId) => {
        console.log("teacherId",teacherId)
        let returnVal = "-"
        for (const teacher of props.teachers) {
            if (teacher.id === teacherId) {
                return teacher.attributes.Name
            }
        }
        return returnVal
    }

    const getTripFrequencyFromValue = (frequency) => {
        if (frequency) {
            let tempFreqList = tripFrequencyList.filter((freq) => (freq.value === frequency))
            if (tempFreqList.length > 0) {
                return tempFreqList[0].name
            }
        }
        return ""
    }

    const getStatus = (trip) => {


        let status = trip.attributes.tripStatus;
        let sColur = 'secondary'
        let currentState = "Not Started"
        if (status == 1) {
            sColur = "success";
            currentState = "In Progress";
        } else if (status == 2) {
            sColur = "danger";
            currentState = "Completed";
        }

        return (<Badge
            className={
                "font-size-12 badge-soft-" + sColur
            }
            color="success"
            pill
        >
            {currentState}
            
        </Badge>)
    }

    return (
        <React.Fragment>
            {
                <Card style={{ width: "100%" }}>
                    <CardBody>
                        <div className="table-responsive">
                            <Table className="table-centered table-nowrap table-hover">
                                <thead className="thead-light">
                                    <tr>
                                        <th scope="col">{('Trip Name')}</th>
                                        <th scope="col" style={{ textAlign: "center" }}>{'Student Count'}</th>
                                        <th scope="col">{'Vehicle Details'}</th>
                                        <th scope="col">{'Driver'}</th>
                                        <th scope="col">{'Helper'}</th>
                                        <th scope="col">{'Start Time'}</th>
                                        <th scope="col">{'End Time'}</th>
                                        <th scope="col" style={{ textAlign: "right" }}>{'Actions'}</th>
                                    </tr>
                                </thead>
                                <tbody>{
                                    props.trips.map((trip, key) =>
                                        <tr key={"trips_" + key}>
                                            <td>
                                                
                                                < h5 className="text-truncate font-size-14" > {trip.attributes.name}</h5>
                                                <div>
                                                    <h6 className="text-truncate font-size-13" > {trip.attributes.tripType === 1 ? "Pick up" : "Drop"} {trip.attributes.frequency ? `, ${getTripFrequencyFromValue(trip.attributes.frequency)}` : ""}</h6>
                                                </div>
                                            </td>
                                            <td>
                                                < h5 className="text-truncate text-center font-size-14" > {trip.attributes.participantsCount ?? "-"}</h5>
                                                <div className="text-center"> {getStatus(trip)}</div>
                                            </td>

                                            <td>
                                                < h5 className="text-truncate font-size-14" > {trip.attributes.tripType == 1 ? trip.attributes.pickupVehicleInfo : trip.attributes.dropVehicleInfo}</h5>
                                                <div>
                                                    < h5 className="text-truncate font-size-13" > {trip.attributes.tripType == 1 ? trip.attributes.pickupVehicleNo : trip.attributes.dropVehicleNo}</h5>
                                                </div>
                                            </td>
                                            <td>
                                                < h5 className="text-truncate font-size-14" > {getTeacherName(trip.attributes.tripType == 1 ? trip.attributes.pickupDriverId : trip.attributes.dropDriverId)}</h5>
                                            </td>
                                            <td>
                                                < h5 className="text-truncate font-size-14" > {getTeacherName(trip.attributes.tripType == 1 ? trip.attributes.pickupHelperId : trip.attributes.dropHelperId)}</h5>
                                            </td>
                                            <td>
                                                < h5 className="text-truncate font-size-14" > {getTimeStringForDate(trip.attributes.startTime)}</h5>
                                            </td>
                                            <td>
                                                < h5 className="text-truncate font-size-14" > {getTimeStringForDate(trip.attributes.endTime)}</h5>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <ul className="list-inline font-size-20 contact-links mb-0 float-right">

                                                    {(trip.attributes.tripStatus > 0) && <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setresetTrip(trip);
                                                        }}
                                                        id={"viewDetailReset" + key}>
                                                        <i className="bx bx-revision"></i>
                                                        <UncontrolledTooltip placement="top" target={"viewDetailReset" + key}>
                                                            {'Reset'}
                                                        </UncontrolledTooltip>
                                                    </li>}
                                                    <Link to={"/trip-view/" + trip.attributes.schoolId + "/" + trip.id + "/" + trip.attributes.groupId}>
                                                        <li className="list-inline-item px-2"
                                                            style={{ cursor: "pointer" }}
                                                            id={"viewDetailTrip" + key}>
                                                            <i className="bx bx-right-arrow-circle"></i>
                                                            <UncontrolledTooltip placement="top" target={"viewDetailTrip" + key}>
                                                                {'Details'}
                                                            </UncontrolledTooltip>
                                                        </li>
                                                    </Link>
                                                </ul>
                                            </td>

                                            {/* <td>
                                                <p className="text-muted mb-2">{program.programBatch}</p>
                                            </td>
                                            <td>
                                                <p className="text-muted mb-2">{program.programType}</p>
                                            </td>
                                            <td>
                                                <li className="list-inline-item" id={"startDate" + key}>
                                                    <i className="bx bx-calendar mr-0"></i> {program.startDate}
                                                    <UncontrolledTooltip placement="top" target={"startDate" + key}>
                                                        Start Date
                                                    </UncontrolledTooltip>
                                                </li>
                                            </td>
                                            <td>
                                                <li className="list-inline-item" id={"endDate" + key}>
                                                    <i className="bx bx-calendar mr-1"></i> {program.endDate}
                                                    <UncontrolledTooltip placement="top" target={"endDate" + key}>
                                                        End Date
                                                    </UncontrolledTooltip>
                                                </li>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <Badge color={program.color}>{program.status}</Badge>
                                            </td>
                                            <td style={{ textAlign: "center" }}>
                                                <ul className="list-inline font-size-20 contact-links mb-0 float-right">
                                                    <Link to={"/programview/" + program.parseObjectGroup.attributes.schoolId + "/" + program.parseObjectProgram.id + "/" + program.parseObjectGroup.id}>
                                                        <li className="list-inline-item px-2"
                                                            style={{ cursor: "pointer" }}

                                                            id={"viewDetailProgram" + key}>
                                                            <i className="bx bx-right-arrow-circle"></i>
                                                            <UncontrolledTooltip placement="top" target={"viewDetailProgram" + key}>
                                                                {'Details'}
                                                            </UncontrolledTooltip>
                                                        </li></Link>
                                                </ul>
                                            </td> */}
                                        </tr>
                                    )
                                }
                                </tbody >
                            </Table >
                        </div >
                    </CardBody >
                </Card >
            }

            {resetTrip && <SweetAlert
                danger
                showCancel
                confirmBtnText="Reset"
                cancelBtnText="Cancel"
                confirmBtnBsStyle="danger"
                cancelBtnBsStyle="success"
                title="Alert!"
                onCancel={() => {
                    setresetTrip(undefined);
                }}
                onConfirm={() => { props.handelResetTrip(resetTrip); setresetTrip(undefined); }}
            >
                {'Are you sure you want to reset the trip, on reset driver can start trip again.'}
                <br></br>
                <b>{' Trip Name:' + resetTrip.attributes.name}</b>
            </SweetAlert>}
        </React.Fragment >
    );
}

export default ListForProgram;