import React, { useState } from "react";
import { Card, CardBody, CardTitle } from "reactstrap"

import Chart from "react-apexcharts";

const FeesExpenses = (props) => {

    const [chartData, setChartData] = useState({
        options: {
            chart: {
                id: "",


            },
            colors: ['#556ee6', '#34c38f'],
            xaxis: {
                categories: [1991, 1992, 1993, 1994, 1995, 1996, 1997, 1998, 1999]
            },
            dataLabels: {
                enabled: false
            },
            stroke: {
                curve: "smooth",
                width: 3,

            },


        },
        series: [
            {
                name: "Fee",
                data: [30, 40, 45, 50, 49, 60, 70, 91, 82, 90]
            },
            {
                name: "Expenses",
                data: [35, 45, 55, 60, 39, 50, 75, 61]
            },

        ],


    });
    return (
    <Card>
        <CardBody>
            <CardTitle className="mb-4 float-sm-left">
                Analytics
            </CardTitle>
            <div className="app">
                <div className="row">
                    <div className="mixed-chart">
                        <Chart
                            options={chartData.options}
                            series={chartData.series}
                            type="area"
                            width="600"
                        />
                    </div>
                </div>
            </div>
        </CardBody>
    </Card>
    );
}
export default FeesExpenses;