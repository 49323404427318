import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import { FORGET_PASSWORD } from "./actionTypes";
import { userForgetPasswordSuccess, userForgetPasswordError ,userForgetPasswordLoading} from "./actions";
import { forgoPassword } from '../../../helpers/ParseHelpers/login_helper';



//If user is send successfully send mail link then dispatch redux action's are directly from here.
function* forgetUser({ payload: { user, history } }) {
  try {
  
    if (process.env.REACT_APP_DEFAULTAUTH === "parse") { 
      yield put(userForgetPasswordLoading(true));
      const response = yield call(forgoPassword, user.email);
      yield put(userForgetPasswordLoading(false));
      if (response && response.type==="success") {
        yield put(userForgetPasswordSuccess(response.message));
      } else {
        yield put(userForgetPasswordError(response.message));
      }

    }
  } catch (error) {
    yield put(userForgetPasswordError(error));
  }
}

export function* watchUserPasswordForget() {
  yield takeEvery(FORGET_PASSWORD, forgetUser);
}

function* forgetPasswordSaga() {
  yield all([fork(watchUserPasswordForget)]);
}

export default forgetPasswordSaga;
