import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import * as messageCreditHealper from "../../../helpers/ParseHelpers/messageCreditHealper";
import * as actionTypes from "./actionTypes";
import * as actions from "./actions";

function* processToGetCredit({
  payload: { schoolId, instituteId, franchiseId },
}) {
  try {
    const response = yield call(
      messageCreditHealper.getMessageCreditForPayload,
      schoolId,
      instituteId,
      franchiseId
    );
    yield put(actions.setAllCreditsForAllUser(response));
  } catch (err) {
    console.log(err);
  }
}

function* processPricing({ payload: {} }) {
  try {
    const response = yield call(messageCreditHealper.getPricingForMessages);
    yield put(actions.setPricingForMsg(response));
  } catch (err) {
    console.log(err);
  }
}

function* processToPayment({
  payload: { amountToPay, invoiceItems, schoolId },
}) {
  try {
    const response = yield call(
      messageCreditHealper.createMoneyTriansaction,
      amountToPay,
      invoiceItems,
      schoolId
    );
    yield put(actions.paymentCreationSuccess(response));
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetMessageCredits() {
  yield takeEvery(actionTypes.GET_ALL_CREDITS_FOR_ALL_USER, processToGetCredit);
}
export function* watchGetPricingForMessages() {
  yield takeEvery(actionTypes.GET_ALLPRICING_FOR_MESSAGES, processPricing);
}
export function* watchCreatePaymentRequest() {
  yield takeEvery(
    actionTypes.PROCESS_TO_PAY_SMS_AND_WHATS_APP,
    processToPayment
  );
}

export default function* MessageCredits() {
  yield all([
    fork(watchGetMessageCredits),
    fork(watchGetPricingForMessages),
    fork(watchCreatePaymentRequest),
  ]);
}
