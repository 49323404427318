import Parse from "parse";
import { printLog } from "./loghelper";

export const getEnquiryReminderFromParse = (enquiryReminderId) => {
  printLog("HL: enquiryreminder_helper : getEnquiryReminderFromParse")

  try {
    return new Promise((resolve, reject) => {
      var EnquiryReminder = Parse.Object.extend("EnquiryReminder");
      var query = new Parse.Query(EnquiryReminder);
      query.get(enquiryReminderId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const deleteEnquiryReminderFromParse = (enquiryReminder) => {
  printLog("HL: enquiryreminder_helper : deleteEnquiryReminderFromParse")
  try {
    return new Promise((resolve, reject) => {
      
      enquiryReminder.set("isDeleted", true);
      enquiryReminder.set("status", false);
      if (Parse.User.current().attributes.teacherId) {
        enquiryReminder.set(
          "deletedBy",
          Parse.User.current().attributes.teacherId
        );
      } else {
        enquiryReminder.set("deletedBy", Parse.User.current().id);
      }

      enquiryReminder.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const getEnquiryRemindersForSchoolId = (id, updatedDate, isLocal) => {
  printLog("HL: enquiryreminder_helper : getEnquiryRemindersForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryReminder = Parse.Object.extend("EnquiryReminder");
      var query = new Parse.Query(EnquiryReminder);
      query.equalTo("schoolId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const getEnquiryRemindersForEnquiryId = (id,isLocal) => {
  printLog("HL: enquiryreminder_helper : getEnquiryRemindersForEnquiryId")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryReminder = Parse.Object.extend("EnquiryReminder");
      var query = new Parse.Query(EnquiryReminder);
      query.equalTo("enquiryId", id);
      query.ascending("createdAt");
      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const getEnquiryRemindersForEnquiryIds = (ids) => {
  printLog("HL: enquiryreminder_helper : getEnquiryRemindersForEnquiryIds")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryReminder = Parse.Object.extend("EnquiryReminder");
      var query = new Parse.Query(EnquiryReminder);
      query.containedIn("enquiryId", ids);
      query.notEqualTo("isDeleted", true);
      query.limit(10000)
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};


export const editEnquiryReminder = (object) => {
  printLog("HL: enquiryreminder_helper : editEnquiryReminder")
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
          (result) => {
            resolve(result);
          },
            (error) => {
              reject(error);
          }
        );
        
      });	
} catch (error) { }
}

export const getEnquiryRemindersForSchoolIdWithDates = (id, startDate,endDate, isLocal) => {
  printLog("HL: enquiryreminder_helper : getEnquiryRemindersForSchoolIdWithDates")
  try {
    return new Promise((resolve, reject) => {
      var EnquiryReminder = Parse.Object.extend("EnquiryReminder");
      var query = new Parse.Query(EnquiryReminder);
      query.equalTo("schoolId", id);

      query.greaterThanOrEqualTo("reminderDateTime",startDate);
      query.lessThanOrEqualTo("reminderDateTime",endDate);


      if (isLocal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const saveEnquiryReminder = (enquiryReminder) => {
  try {
    return new Promise((resolve, reject) => {
      enquiryReminder.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) { }
};
