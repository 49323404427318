import * as actions from "./actionTypes";

const initialState = {
  liveMeetings: [], liveMeetingAddLoading: false
};

const LiveMeetings = (state = initialState, action) => {
  switch (action.type) {
    


    case actions.UPDATE_LIVE_MEETING_FOR_BATCH:


      var liveMeetings = [...state.liveMeetings];
      if (liveMeetings.length > 0) {
        for (const latest in action.payload.liveMeetings) {
          var temp = liveMeetings.filter(
            (cqs) =>
              cqs.id === action.payload.liveMeetings[latest].id
          );


          if (temp.length > 0) {
            if (action.payload.liveMeetings[latest].attributes.isDeleted) {

              var others = liveMeetings.filter(
                (cqs) =>
                  cqs.id !== action.payload.liveMeetings[latest].id
              );

              liveMeetings = others;
            } else {
              var index = liveMeetings.lastIndexOf(temp[0]);
              liveMeetings[index] = action.payload.liveMeetings[latest];
            }

          } else {
            if (action.payload.liveMeetings[latest].attributes.isDeleted !== true) {
              liveMeetings.push(action.payload.liveMeetings[latest]);
            }

          }
        }
        state = { ...state, liveMeetings: liveMeetings };
      } else {

        var i = 0
        var liveMeetingsToSave = []
        for (i = 0; i < action.payload.liveMeetings.length; i++) {
          if (action.payload.liveMeetings[i].attributes.isDeleted !== true) {
            liveMeetingsToSave.push(action.payload.liveMeetings[i])
          }
        }
        state = { ...state, liveMeetings: liveMeetingsToSave };
      }
      break;
      
    case actions.ADD_LIVE_MEETING_LOADING:

      state = { ...state, liveMeetingAddLoading: action.payload.state };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LiveMeetings;
