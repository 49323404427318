import Parse from "parse";

export const getInventorySaleOredrItemsForOwnerId = (ownerId, ownerType, updatedDate,isLocal,) => {
    try {
      return new Promise((resolve, reject) => {
        var InventorySaleOrderItem = Parse.Object.extend("InventorySaleOrderItems");
        var query = new Parse.Query(InventorySaleOrderItem);
        query.equalTo("ownerId", ownerId);
        query.equalTo("ownerType", ownerType)
  
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
       } else {
         query.notEqualTo("isDeleted", true);
       }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
        query.addAscending("order");
        query.find().then(
          (result) => {
           resolve(result); 
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };

  export const deleteInventorySaleOredrItemFromParse = (inventorySaleOrderItem) => {
    try {
      return new Promise((resolve, reject) => {
        inventorySaleOrderItem.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            inventorySaleOrderItem.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            inventorySaleOrderItem.set("deletedBy", Parse.User.current().id);
        }
  
        inventorySaleOrderItem.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("error");
          }
        );
      });
    } catch (error) {}
  };
  
  export const getInventorySaleOredrItemFromLocal = (inventorySaleOrderItemId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventorySaleOrderItem = Parse.Object.extend("InventorySaleOrderItems");
        var query = new Parse.Query(InventorySaleOrderItem);
        query.fromLocalDatastore();
        query.get(inventorySaleOrderItemId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  export const getInventorySaleOredrItemFromParse = (inventorySaleOrderItemId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventorySaleOrderItem = Parse.Object.extend("InventorySaleOrderItems");
        var query = new Parse.Query(InventorySaleOrderItem);
        query.equalTo("saleOrderId",inventorySaleOrderItemId);
        query.notEqualTo("isDeleted", true);
        query.find().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  export const saveCustomObjects = (object) => {
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) { }
  };

