import { takeEvery, fork, put, all, call, takeLeading } from "redux-saga/effects";
import Parse from "parse";
import * as InventoryPurchaseOrderItemsHelper from "../../../../helpers/ParseHelpers/Inventory-managment/inventoryPurchaseOrderItems_helper";
import * as actionType from "./actionTypes";
import * as InventoryPurchaseOrderItemsAction from "./actions";
import * as Constants from "../../../../Constents";
import toastr from 'toastr'
import 'toastr/build/toastr.min.css'

function* getInventoryPurchaseOrderItemsForOwnerId({ payload: { ownerId, ownerType } }) {
    try {

        var key = Constants.INVENTORY_PURCHASE_ORDER_ITEMS_UPDATED_TIME + "_" + ownerId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(InventoryPurchaseOrderItemsHelper.getInventoryPurchaseOrderItemsForOwnerId, ownerId, ownerType, null, true);
        yield put(InventoryPurchaseOrderItemsAction.updateInventoryPurchaseOrderItems(localResponse));


        const serverResponse = yield call(InventoryPurchaseOrderItemsHelper.getInventoryPurchaseOrderItemsForOwnerId, ownerId, ownerType, updatedTime, false);
        localStorage.setItem(key, newTime)

        if (serverResponse && serverResponse.length > 0) {
            yield put(InventoryPurchaseOrderItemsAction.updateInventoryPurchaseOrderItems(serverResponse));


            var nonDeleted = serverResponse.filter(
                (inventoryPurchaseOrderItem) =>
                inventoryPurchaseOrderItem.attributes.isDeleted !== true
            );

            var deleted = serverResponse.filter(
                (inventoryPurchaseOrderItem) =>
                inventoryPurchaseOrderItem.attributes.isDeleted
            );

            if (nonDeleted) {
                Parse.Object.pinAll(nonDeleted);
            }

            if (deleted) {
                Parse.Object.unPinAll(deleted);
            }

        }
    } catch (error) {

    }
}

function* deleteInventoryPurchaseOrderItem({ payload: { inventoryPurchaseOrderItem } }) {
    try {
        yield put(InventoryPurchaseOrderItemsAction.updateInventoryPurchaseOrderItems(inventoryPurchaseOrderItem));
        const response = yield call(InventoryPurchaseOrderItemsHelper.deleteInventoryPurchaseOrderItemFromParse, inventoryPurchaseOrderItem);
        if (response === "error") {
            inventoryPurchaseOrderItem.set("isDeleted", undefined);
            yield put(InventoryPurchaseOrderItemsAction.updateInventoryPurchaseOrderItems(inventoryPurchaseOrderItem));
        } else
            if (response) {
                toastr.success("Purchase order item successfully removed", { timeOut: 3000 });
                response.unPin();
            } else {

            }

    } catch (error) {

    }
}

function* addInventoryPurchaseOrderItem({ payload: { inventoryPurchaseOrderItem } }) {
    try {
    
      yield put(InventoryPurchaseOrderItemsAction.updateInventoryPurchaseOrderItems(inventoryPurchaseOrderItem));
      const response = yield call(
        InventoryPurchaseOrderItemsHelper.saveCustomObjects,inventoryPurchaseOrderItem
      );
      if (response === "Error") {
  
        toastr.error("Please try again", { timeOut: 3000 });
        const local = yield call(
            InventoryPurchaseOrderItemsHelper.saveCustomObjects,inventoryPurchaseOrderItem.id
        );
  
        if (local) {
          yield put(InventoryPurchaseOrderItemsAction.updateInventoryPurchaseOrderItems(local));
        }
    
      }else 
      if (response) {
        yield put(InventoryPurchaseOrderItemsAction.updateInventoryPurchaseOrderItems(response));
        toastr.success("Purchase order item successfully added", { timeOut: 3000 });
        response.pin();
      } else {

      }
    } catch (error) {
 
    }
  }

  
  function* setSelectedPurchaseOrderItems({ payload: { inventoryPurchaseOrderItemId } }) {
    try {
      const response = yield call(InventoryPurchaseOrderItemsHelper.getInventoryPurchaseOrderItemFromParse, inventoryPurchaseOrderItemId, true);
      if (response) {
        Parse.Object.pinAll(response);
        yield put(InventoryPurchaseOrderItemsAction.updateSelectedPurchaseOrderItems(response));
      } 
    } catch (error) {

    }
  }

  export function* watchGetInventoryPurchaseOrderItemsForOwnerId() {
    yield takeEvery(actionType.GET_INVENTORY_PURCHASE_ORDER_ITEMS, getInventoryPurchaseOrderItemsForOwnerId);
}

export function* watchDeleteInventoryPurchaseOrderItem() {
    yield takeEvery(actionType.DELETE_INVENTORY_PURCHASE_ORDER_ITEM, deleteInventoryPurchaseOrderItem);
}

export function* watchAddInventoryPurchaseOrderItem(){
    yield takeEvery(actionType.ADD_INVENTORY_PURCHASE_ORDER_ITEM, addInventoryPurchaseOrderItem);
}

export function* watchSetSelectedPurchaseOrderItem() {
    yield takeEvery(actionType.SET_SELECTED_PURCHASE_ORDER_ITEMS, setSelectedPurchaseOrderItems);
}


function* InventoryPurchaseOrderItemsSaga() {
    yield all([
        fork(watchGetInventoryPurchaseOrderItemsForOwnerId),
        fork(watchDeleteInventoryPurchaseOrderItem),
        fork(watchAddInventoryPurchaseOrderItem),
        fork(watchSetSelectedPurchaseOrderItem),
    ]);
}
export default InventoryPurchaseOrderItemsSaga;