import * as actions from "./actionTypes";

export const updateEnquiryQuestion = (enquiryQuestion) => {
  return {
    type: actions.UPDATE_ENQUIRY_QUESTION,
    payload: { enquiryQuestion },
  };
};

export const deleteEnquiryQuestion = (enquiryQuestion) => {
  return {
    type: actions.DELETE_ENQUIRY_QUESTION,
    payload: {enquiryQuestion},
  };
};
export const updateDeleteEnquiryQuestion = (enquiryQuestion) => {
  return {
    type: actions.UPDATE_DELETE_ENQUIRY_QUESTION,
    payload: {enquiryQuestion},
  };
};

export const updateEnquiryQuestions = (enquiryQuestions) => {
  return {
    type: actions.UPDATE_ENQUIRY_QUESTIONS,
    payload: { enquiryQuestions },
  };
};

export const getEnquiryQuestionForSchoolId = (schoolId) => {
  return {
    type: actions.GET_ENQUIRY_QUESTIONS,
    payload: { schoolId },
  };
};

export const editEnquiryQuestion = (enquiryQuestion) => {
  return {
    type: actions.EDIT_ENQUIRY_QUESTION,
    payload: { enquiryQuestion },
  };
};

export const setEnquiryQuestionLoading = (state) => {
  return {
    type: actions.ENQUIRY_QUESTION_LODING,
    payload:{state}
  };
};
export const addEnquiryQuestion = (enquiryQuestion) => {
  return {
    type: actions.ADD_ENQUIRY_QUESTION,
    payload: { enquiryQuestion },
  };
};

export const saveCustomObjects = (object) => {
  return {
    type: actions.SAVE_CUSTOM_OBJECTS,
    payload: { object },
  };
};

