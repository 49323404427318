import React, { } from 'react';
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { Col, Card, CardBody, Table, UncontrolledTooltip, Label, } from "reactstrap";
import Switch from "react-switch";


const TeacherList = (props) => {

    const firstDose = "firstDose"
    const secondDose = "secondDose"

    function getDesignation(teacher) {
        if (teacher.designation) {
            return teacher.designation;
        } else if (teacher.Role === 3) {
            return props.t('Director');
        } else if (teacher.Role === 2) {
            return props.t('Center Head');
        } else {
            return props.t('Teacher');
        }
    }

    const handleEnableDisableDose = (teacherList, teacher, dose) => {
        props.handleEnableDisableDose(teacherList, teacher, dose)
    }

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };



    return (

        <React.Fragment>
            <Col lg={12} className="mt-0"><h4>{props.role}</h4></Col>
            <Col lg={12} >
                <Card>
                    <CardBody>
                        <div className="table-responsive">
                            <Table className="table-centered table-nowrap table-hover">
                                <thead className="thead-light" style={{ height: '0' }}>
                                    <tr>
                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                        <th scope="col">{props.t('Name')}</th>
                                        <th scope="col" style={{ textAlign: "center", width: "200px" }}>{props.t('1st Dose')}</th>
                                        <th scope="col" style={{ textAlign: "center", width: "200px" }}>{props.t('2nd Dose')}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        props.teachers.map((teacher, i) =>
                                            <tr key={teacher.id} >
                                                <td>
                                                    {
                                                        !teacher.teacherObject.attributes.Photo
                                                            ? <div className="avatar-xs">
                                                                <span className="avatar-title rounded-circle">
                                                                    {teacher.teacherObject.attributes.Name ? teacher.teacherObject.attributes.Name.toUpperCase().charAt(0) : " "}
                                                                </span>
                                                            </div>
                                                            : <div>
                                                                <img className="rounded-circle avatar-xs" src={teacher.teacherObject.attributes.Photo._url} alt="" />
                                                            </div>
                                                    }

                                                </td>
                                                <td>
                                                    <h5 className="font-size-14 mb-1">
                                                        <Link to={teacher.teacherObject.attributes.Role === 3 ? "/staffprofile/d-" + props.schoolId + "/" + teacher.id : "/staffprofile/" + teacher.teacherObject.attributes.SchoolID + "/" + teacher.id}
                                                            className="text-dark">{teacher.teacherObject.attributes.Name}
                                                        </Link>


                                                    </h5>
                                                    <p className="text-muted mb-0">{getDesignation(teacher.teacherObject.attributes)}</p>
                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <div id={"switch" + teacher.id}>
                                                        <Switch

                                                            height={15}
                                                            width={25}
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            onColor="#556ee6"
                                                            onChange={(e) => {

                                                                handleEnableDisableDose(props.teachers, teacher, firstDose)


                                                            }}
                                                            checked={teacher.firstDose}
                                                        />
                                                        <UncontrolledTooltip placement="top" target={"switch" + teacher.id}>
                                                            {props.t('Disable/Enable')}
                                                        </UncontrolledTooltip>
                                                    </div>

                                                </td>
                                                <td style={{ textAlign: "center" }}>
                                                    <div id={"switch2" + teacher.id}>
                                                        <Switch

                                                            height={15}
                                                            width={25}
                                                            uncheckedIcon={<Offsymbol />}
                                                            checkedIcon={<OnSymbol />}
                                                            onColor="#556ee6"
                                                            onChange={() => {

                                                                handleEnableDisableDose(props.teachers, teacher, secondDose)

                                                            }}
                                                            checked={teacher.secondDose}
                                                        />
                                                        <UncontrolledTooltip placement="top" target={"switch2" + teacher.id}>
                                                            {props.t('Disable/Enable')}
                                                        </UncontrolledTooltip>
                                                    </div>


                                                </td>


                                            </tr>
                                        )
                                    }

                                </tbody>
                            </Table>
                        </div>

                    </CardBody>
                </Card>
            </Col>
        </React.Fragment>

    );

}

export default withNamespaces()(TeacherList);