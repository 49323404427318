import Parse from "parse";
import { printLog } from "./loghelper";

export const getCourseQuizSessionsForKid = (courseId, kidId,batchId) => {
    printLog("HL: quizSession_helper : getCourseQuizSessionsForKid")

    try {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("QuizSession");
            query.equalTo("courseId", courseId);
            query.equalTo("kidId", kidId);
            if (batchId) {
                query.equalTo("batchId", batchId);
            }
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.descending("createdAt");
            query.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(null);
                }
            )
        })
    } catch (error) { }
}

export const getCourseQuizSessionsForCourseId = (courseId, ) => {
    printLog("HL: quizSession_helper : getCourseQuizSessionsForCourseId")
    try {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("QuizSession");
            query.equalTo("courseId", courseId);
            query.notEqualTo("isDeleted", true);
            query.limit(10000);
            query.descending("createdAt");
            query.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(null);
                }
            )
        })
    } catch (error) { }
}