import React, { useState, useEffect }from 'react';
import {Row, Col, Form, FormGroup,Input,InputGroup,InputGroupText, CardBody, Label } from "reactstrap";
import { Card } from '@material-ui/core';
import SweetAlert from "react-bootstrap-sweetalert";

const Pricing = (props) => {

    const [courseDiscountType, setCourseDiscountType] = useState("%");
    const [courseDiscount, setCourseDiscount] = useState();
    const [coursePrice, setCoursePrice] = useState();
    const [priceErrorMessage, setPriceErrorMessage] = useState();
    const [totalAmount, setTotalAmount] = useState();

    const [saveErrorMessage, setSaveErrorMessage] = useState();
    
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [unpublish_dlg, setunpublish_dlg] = useState(false);
   
    useEffect(() => {
        if (props.courseObject) {

            if (props.courseObject.attributes.discount) {
                setCourseDiscount(props.courseObject.attributes.discount);
                setCourseDiscountType("%");
            }
            if (props.courseObject.attributes.discountPrice) {
                setCourseDiscount(props.courseObject.attributes.discountPrice);
                setCourseDiscountType("₹");
            }
            
            setCoursePrice(props.courseObject.attributes.price)
            
           
        }
    }, [props.courseObject]);

    useEffect(() => {
        setSaveErrorMessage(false)
        if (coursePrice) {
            if (courseDiscount > 0.0) {
                let disAmt = 0;
                if (courseDiscountType === "%") {
                    disAmt = coursePrice * (courseDiscount / 100);
                } else {
                    disAmt = courseDiscount;
                }
                setTotalAmount((coursePrice - disAmt).toFixed(2));
                setPriceErrorMessage(false);
            } else {
                setTotalAmount(Number(coursePrice).toFixed(2));
                setPriceErrorMessage(false);
                
            }
        } else {
            setTotalAmount(Number(coursePrice).toFixed(2));
        }
    
    }, [coursePrice, courseDiscount, courseDiscountType]);

    

    const onClickUnpublish = () => {
        let tempObject = props.courseObject;
        tempObject.set("state", "Unpublished");
        props.onSaveCourseObject(tempObject);
    }
    const onClickSave = (publish,next) => {

        let save = true;
        if (!coursePrice) {
            setPriceErrorMessage(true);
            save = false
        }

        if (courseDiscountType === "%" && courseDiscount > 100) {
            setSaveErrorMessage("Discount can not be more then 100%")
            save = false;
        }
        if (courseDiscount >= coursePrice) {
            setSaveErrorMessage("Discount can not be more then course price")
            save = false;
        }
        if (totalAmount < 0) {
            setSaveErrorMessage("Amount can not be lessthen zero")
            save = false;
        }

        if (publish && save) {
            setsuccess_dlg(true);
            return;
        }else if (save) {
            let tempObject = props.courseObject;

            if (courseDiscountType === "%") {
                tempObject.set("discount", parseFloat(courseDiscount));
                tempObject.unset("discountPrice");
            }
            if (courseDiscountType === "₹") {
                tempObject.set("discountPrice", parseFloat(courseDiscount));
                tempObject.set("discount");
            }
            
            tempObject.set("price", parseFloat(coursePrice));

            if (next) {
                tempObject.set("state", "Published");
            }
            props.onSaveCourseObject(tempObject);
        }
        

    }
   
    
    return (
        <React.Fragment>
           
            <Card>
                <CardBody>
                    <Form>
                        <FormGroup className="position-relative">
                            <Label>Price</Label>

                            <InputGroup>
                            
                                <InputGroupText addonType="append">
                                    <Input
                                        style={{ width: "1cm" }}
                                        name="r"
                                        className="form-control bg-light"
                                        value={"₹"}
                                        disabled
                                    >
                                   
                                    </Input>
                                </InputGroupText>
                                <Input
                                    name="coursePrice"
                                    id="coursePrice"
                                    type="number"
                                    className="form-control noscroll"
                                    placeholder="Course price"
                                    value={coursePrice}
                                    onChange={(e) => {
                                        setCoursePrice(e.target.value)
                                    }}
                                    min={0}
                                />
                            </InputGroup>
                       
                        
                            {priceErrorMessage && (
                                <Label className="text-danger ">
                                    Please fill the course price
                                </Label>
                            )}
                        </FormGroup>
                        <FormGroup className="position-relative">
                            <Label>Discount</Label>
                            <InputGroup>
                                <Input
                                    name="courseDiscount"
                                    type="number"
                                    id="courseDiscount"
                                    className="form-control noscroll"
                                    placeholder="courseDiscount"
                                    value={courseDiscount}
                                    required
                                    aria-describedby="newssubscribebtn"
                                    min={0}
                                    onChange={(e) => {
                                        setCourseDiscount(e.target.value)
                                    }}
                                />
                                <InputGroupText addonType="append">
                                    <InputGroupText
                                        name="courseDiscountType"
                                        id="courseDiscountType"
                                        type="select"
                                        className="form-control"
                                        value={courseDiscountType}
                                        onChange={(e) => {
                                            setCourseDiscountType(e.target.value)
                                        }}
                                    >
                                        <option value="%">%</option>
                                        <option value="rs">₹</option>
                                    </InputGroupText>
                                </InputGroupText>
                            </InputGroup>
                        </FormGroup>
                    </Form>

                    {saveErrorMessage && (
                        <Label className="text-danger ">
                            {saveErrorMessage}
                        </Label>
                    )}

                    <h4 className="pl-3">
                        Total Course Price :{totalAmount}
                    </h4>

                    {props.saveInprogress ? <div>
                        <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>
                        {props.courseObject.attributes.state !== "Published" &&
                            <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>}
                    </div>
                        :
                        <div>
                            <button className="btn btn-primary m-1" onClick={() => { onClickSave() }}> Save </button>
                            {props.courseObject.attributes.state === "Published" ?
                                 <button className="btn btn-danger m-1" onClick={() => { setunpublish_dlg(true)}}>  Unpublish</button>:
                                <button className="btn btn-primary m-1" onClick={() => { onClickSave(true) }}> Save & Publish</button>}
                        </div>
                    }
                </CardBody>
            </Card>
            {success_dlg ? (
                <SweetAlert
                    success
                    title={"Submit & Publish"}
                    cancelBtnBsStyle="danger"
                    showCancel
                    onConfirm={() => {
                        onClickSave(false,true) 
                        setsuccess_dlg(false);
                    }}
                    onCancel={() => {
                        setsuccess_dlg(false);
                    }}
                >
                    
                    {"On Submitting, course will be published to market and will be avilable for subscription"}
                </SweetAlert>
            ) : null}
            {unpublish_dlg ? (
                <SweetAlert
                    success
                    title={"Unpublish"}
                    cancelBtnBsStyle="danger"
                    showCancel
                    onConfirm={() => {
                        onClickUnpublish(false,true) 
                        setunpublish_dlg(false);
                    }}
                    onCancel={() => {
                        setunpublish_dlg(false);
                    }}
                >
                    
                    {"On unpblishing, course will be removed from listing "}
                </SweetAlert>
            ) : null}
        </React.Fragment>
    )
}
export default Pricing