import * as actions from "./actionTypes";

const initialState = {
  learningActivities: [], selcetedActivity: undefined, loadingState: false, quizLearningActivities: [], liveMeetingLearningActivities: []
};

const LearningActivity = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_LEARNING_ACTIVITY:
      {
        let learningActivities = [...state.learningActivities];
        let temp = state.learningActivities.filter(
          (learningActivity) =>
          learningActivity.id === action.payload.learningActivity.id
        );
        if (temp.length > 0) {

          if (action.payload.learningActivity.attributes.isDeleted) {

            let others = state.learningActivities.filter(
              (learningActivity) =>
              learningActivity.id !== action.payload.learningActivity.id
            );
            state = { ...state, learningActivities: others };
            
          } else {
            let index = learningActivities.indexOf(temp[0]);
            learningActivities[index] = action.payload.learningActivity;

            if (state.selcetedActivity && state.selcetedActivity.id === action.payload.learningActivity.id) {
              state = { ...state, learningActivities: learningActivities, selcetedActivity: action.payload.learningActivity };
            } else {
                state = { ...state, learningActivities: learningActivities };
            }
            
          }

        
        } else {
          if (!action.payload.learningActivity.attributes.isDeleted) {
              learningActivities.push(action.payload.learningActivity);
            state = { ...state, learningActivities: learningActivities };
          }
        }
      }
      break;
    case actions.UPDATE_LEARNING_ACTIVITYS:
      {
        let learningActivities = [...state.learningActivities];
        if (learningActivities.length > 0) {
          for (const latest in action.payload.learningActivities) {
            let temp = learningActivities.filter(
              (learningActivity) =>
              learningActivity.id === action.payload.learningActivities[latest].id
            );

            
            if (temp.length > 0) {
              if (action.payload.learningActivities[latest].attributes.isDeleted) {

                let others = learningActivities.filter(
                  (learningActivity) =>
                  learningActivity.id !== action.payload.learningActivities[latest].id
                );
              
                learningActivities = others;
              } else {
                let index = learningActivities.lastIndexOf(temp[0]);
                learningActivities[index] = action.payload.learningActivities[latest];
              }
            
            } else {
              learningActivities.push(action.payload.learningActivities[latest]);
            }
          }
          state = { ...state, learningActivities: learningActivities };
        } else {
          var others = action.payload.learningActivities.filter(
            (learningActivity) => (!learningActivity.attributes.isDeleted)
          );
          state = { ...state, learningActivities: others };
          
        }
      }
      break;
    case actions.DELETE_LEARNING_ACTIVITY:
      let learningActivities = state.learningActivities.filter(
        (learningactivity) => learningactivity.id !== action.payload.id
      );
      state = { ...state, learningActivities: learningActivities };
      break;
    case actions.REPLACE_LEARNING_ACTIVITIES:
      state = { ...state, learningActivities: action.payload.learningActivities };
      break;
    case actions.UPDATE_SELECTED_LEARNING_ACTIVITY:
      state = { ...state, selcetedActivity: action.payload.learningActivity };
      break;
      
    case actions.LEARNING_ACTIVITY_ADD_LOADING_STATE:
      state = { ...state, loadingState: action.payload.state };
      break;
    case actions.GET_QUIZ_ACTIVITIES_FOR_COURSE_ID:
      state = { ...state, quizLearningActivities: [] };
      break;
    case actions.UPDATE_QUIZ_ACTIVITIES_FOR_COURSE_ID:
      {
        let learningActivities = [...state.quizLearningActivities];
        if (learningActivities.length > 0) {
          for (const latest in action.payload.learningActivities) {
            let temp = learningActivities.filter(
              (learningActivity) =>
              learningActivity.id === action.payload.learningActivities[latest].id
            );

            
            if (temp.length > 0) {
              if (action.payload.learningActivities[latest].attributes.isDeleted) {

                let others = learningActivities.filter(
                  (learningActivity) =>
                  learningActivity.id !== action.payload.learningActivities[latest].id
                );
              
                learningActivities = others;
              } else {
                let index = learningActivities.lastIndexOf(temp[0]);
                learningActivities[index] = action.payload.learningActivities[latest];
              }
            
            } else {
              learningActivities.push(action.payload.learningActivities[latest]);
            }
          }
          state = { ...state, quizLearningActivities: learningActivities };
        } else {
          var others = action.payload.learningActivities.filter(
            (learningActivity) => (!learningActivity.attributes.isDeleted)
          );
          state = { ...state, quizLearningActivities: others };
          
        }
      }
      break;
    case actions.GET_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID:
      state = { ...state, liveMeetingLearningActivities: [] };
      break;
    case actions.UPDATE_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID:
      {
        let learningActivities = [...state.liveMeetingLearningActivities];
        if (learningActivities.length > 0) {
          for (const latest in action.payload.learningActivities) {
            let temp = learningActivities.filter(
              (learningActivity) =>
              learningActivity.id === action.payload.learningActivities[latest].id
            );

            
            if (temp.length > 0) {
              if (action.payload.learningActivities[latest].attributes.isDeleted) {

                let others = learningActivities.filter(
                  (learningActivity) =>
                  learningActivity.id !== action.payload.learningActivities[latest].id
                );
              
                learningActivities = others;
              } else {
                let index = learningActivities.lastIndexOf(temp[0]);
                learningActivities[index] = action.payload.learningActivities[latest];
              }
            
            } else {
              learningActivities.push(action.payload.learningActivities[latest]);
            }
          }
          state = { ...state, liveMeetingLearningActivities: learningActivities };
        } else {
          var others = action.payload.learningActivities.filter(
            (learningActivity) => (!learningActivity.attributes.isDeleted)
          );
          state = { ...state, liveMeetingLearningActivities: others };
          
        }
      }
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LearningActivity;
