import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  CardTitle,
  Container,
} from "reactstrap";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";

import Breadcrumbs from "../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from 'parse';
import Select from "react-select";
import * as Constents from '../../Constents'
import COUNTRY_JSON from '../../assets/other/countries.json'
import { editSchool, setSelecetdSchool } from "../../store/actions";

const EditSchool = (props) => {

  const match = matchPath(props.history.location.pathname, {
    path: "/editschool/:param",
    exact: true,
    strict: false,
  });

  const parameter = match && match.params.param;

  const [name, setName] = useState("")
  const [email, setEmail] = useState("")
  const [phone, setPhone] = useState("")
  const [address, setAddress] = useState("")
  const [secondaryPhone, setSecondaryPhone] = useState("")
  const [schoolCode, setSchoolCode] = useState("")
  const [city, setCity] = useState("")
  const [schoolStateName, setSchoolStateName] =useState(undefined)
  const [schoolState, setSchoolState] = useState({
    value: "",
    label: ""
  });
  const [schoolCountry, setSchoolCountry] = useState({
    value: "",
    label: ""
  });
  const [countryCodeList, setCountryCodeList] = useState(undefined)
  const [locality, setlocality] = useState("")
  const [schoolwebsite, setSchoolWebsite] = useState("");
  const [isSaving, setIsSaving]= useState(false);
  const [schoolType, setSchoolType] = useState(1)


  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== parameter) {
      props.setSelecetdSchool(parameter);
    }
    if (props.selectedSchool) {
      setSchoolType(props.selectedSchool.attributes.schoolType);
    }
    setAllCountryCodeList()
  }, [props.selectedSchool, parameter]);


  const setAllCountryCodeList = () => {
    let allCountries = []
    for (const country of COUNTRY_JSON) {
        let countryObject = {
            value: country.code,
            label: country.name,
        }
        allCountries.push(countryObject)
    }
    setCountryCodeList(allCountries)
}

function getNameFromCode(code) {
  const country = COUNTRY_JSON.find(country => country.code == code);
  return  country.name 
}


  useEffect(() => {
    var school = props.selectedSchool;
    if (school != null) {
      setName(school.attributes.Name);
      setEmail(school.attributes.EMail);
      setPhone(school.attributes.primaryPhoneNumber);
      setSecondaryPhone(school.attributes.secondaryPhoneNumber);
      setSchoolCode(school.attributes.schoolCode);
      setSchoolWebsite(school.attributes.url);
      
      setSchoolCountry({
        value: school.attributes.countryCode,
        label: getNameFromCode(school.attributes.countryCode)
      })
      if(school.attributes.countryCode != "IN"){
        setSchoolStateName(school.attributes.state ?? Constents.stateNameCode[school.attributes.areaCode]);
     }else if (school.attributes.areaCode) {
       setSchoolState({
         value: school.attributes.areaCode, label: Constents.stateNameCode[school.attributes.areaCode]
       });
       setSchoolStateName(school.attributes.state ?? Constents.stateNameCode[school.attributes.areaCode])
     }

      setAddress(school.attributes.Address);
      setCity(school.attributes.city);
      setlocality(school.attributes.locality);




    }
  }, [props.selectedSchool]);

  


  const saveSchool = () => {

    if (props.selectedSchool != null && !isSaving) {
      
      setIsSaving(true)

      var school = props.selectedSchool
      school.set("Name", name);
      school.set("EMail", email);
      school.set("primaryPhoneNumber", phone);
      school.set("schoolCode", schoolCode);
      school.set("secondaryPhoneNumber", secondaryPhone);
      school.set("city", city);
      school.set("Address", address);
      school.set("locality", locality);
      school.set("url", schoolwebsite);
      school.set("country", schoolCountry.label);
      school.set("countryCode", schoolCountry.value);
      if (schoolState && schoolCountry.value == "IN") {
        school.set("state", schoolState.label);
        school.set("areaCode", schoolState.value);
      } else {
        school.set("state", schoolStateName);
        school.unset("areaCode")
      }

      props.editSchool(school, true);
    }
  }

  function formatDate(d) {
    if (d === "") {
      return ""
    } else {

      const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
      const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
      const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)

      return (`${ye}-${mo}-${da}`);

    }

  }

  return (

    <React.Fragment>
      <div className="page-content">
        <Container fluid={true}>
          <Breadcrumbs title={parameter ? props.t('Edit School') : props.t('Add Staff')} breadcrumbItem="Form Repeater" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm className="outer-repeater" onValidSubmit={saveSchool}>
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">

                        <FormGroup >
                          <Label htmlFor="formname">{props.t('Name')}: </Label>
                          <AvField
                            type="text"
                            name="name"
                            placeholder={props.t('Enter Name...')}
                            className="form-control"
                            value={name}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t('Enter Name...'),
                              },
                            }}
                            onChange={(e) => {
                              setName(e.target.value);
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label htmlFor="formemail"> {props.t('Email')}:</Label>
                          <AvField
                            type="email"
                            name="email"
                            placeholder={props.t('Enter Email...')}
                            className="form-control"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                            validate={{
                              required: {
                                value: !email || email.length === 0 ? true : false,
                                errorMessage: props.t('Enter Email...'),
                              },
                              pattern: {
                                value:
                                  "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                errorMessage: props.t("You have entered an invalid email address!"),
                              },
                            }}
                          />
                        </FormGroup>

                        <FormGroup >
                          <Label> {props.t('Phone no')}:</Label>
                          <AvField
                            type="text"
                            name="phone"
                            className="inner form-control noscroll"
                            placeholder={props.t('Enter phone no')}
                            value={phone}
                            onChange={(e) => {
                              setPhone(e.target.value);
                            }}
                            validate={{
                              required: {
                                value: !phone || phone.length === 0 ? true : false,
                                errorMessage: props.t('Enter phone no'),
                              }, pattern: {
                                value:
                                  "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                                errorMessage: props.t("You have entered an invalid phone no!"),
                              },
                              minLength: { value: 10 },
                              maxLength: { value: 16 },

                            }}

                          />


                        </FormGroup>
                        <FormGroup >
                          <Label> {props.t('Secondary Phone no')}:</Label>
                          <AvField
                            type="text"
                            name="Secondary Phone"
                            className="inner form-control noscroll"
                            placeholder={props.t('Enter secondary phone no')}
                            value={secondaryPhone}
                            onChange={(e) => {
                              setSecondaryPhone(e.target.value);
                            }}
                            validate={{
                              pattern: {
                                value:
                                  "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                                errorMessage: props.t("You have entered an invalid phone no!"),
                              },
                              minLength: { value: 10 },
                              maxLength: { value: 16 },

                            }}

                          />
                        </FormGroup>

                        <FormGroup >
                          <Label>{schoolType != 10 ? props.t('School Code') : props.t('institute Code')} :</Label>
                          <Input
                            type="text"
                            name="School Code"
                            className="inner form-control"
                            placeholder={schoolType != 10 ? props.t('School Code') : props.t('institute Code')}
                            value={schoolCode}
                            onChange={(e) => {
                              setSchoolCode(e.target.value);
                            }}
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label>{props.t('Area')} :</Label>
                          <Input
                            type="text"
                            name="City"
                            className="inner form-control"
                            placeholder="Area"
                            value={locality}
                            onChange={(e) => {
                              setlocality(e.target.value);
                            }}
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label>{props.t('City')} :</Label>
                          <Input
                            type="text"
                            name="City"
                            className="inner form-control"
                            placeholder="City"
                            value={city}
                            onChange={(e) => {
                              setCity(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label >{props.t('Country')}</Label>
                          <Select
                            value={schoolCountry}
                            onChange={(v) => {
                              if (schoolCountry.value != "IN") {
                                setSchoolState({ value: props.selectedSchool.attributes.areaCode, label: Constents.stateNameCode[props.selectedSchool.attributes.areaCode] })
                              } else {
                                setSchoolStateName(props.selectedSchool.attributes.state)
                              }

                              setSchoolCountry(v)
                            }}
                            options={countryCodeList}
                            classNamePrefix="select2-selection"
                          />
                        </FormGroup>

                        <FormGroup>
                          <Label >{props.t('State')}</Label>

                          {schoolCountry.value == "IN" ? <Select
                            value={schoolState}
                            onChange={(v) => { setSchoolState(v) }}
                            options={Constents.stateName}
                            classNamePrefix="select2-selection"
                          /> 
                        :
                            <Input 
                              type="text"
                              id="state"
                              placeholder="State"
                              value={schoolStateName}
                              onChange={(e) => {
                                setSchoolStateName(e.target.value);
                              }}
                            />}
                        </FormGroup>



                        <FormGroup>
                          <Label htmlFor="formmessage">{props.t('Address')} :</Label>
                          <AvField
                            type="textarea"
                            name="Address"
                            className="form-control"
                            rows="3"
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup >
                          <Label>{schoolwebsite != 10 ? props.t('School Website') : props.t('institute Website')} :</Label>
                          <Input
                            type="text"
                            name="School Code"
                            className="inner form-control"
                            placeholder={schoolwebsite != 10 ? props.t('School Code') : props.t('institute Code')}
                            value={schoolwebsite}
                            onChange={(e) => {
                              setSchoolWebsite(e.target.value);
                            }}
                            validate={{ required: { value: true } }}
                          />
                        </FormGroup>
                      
                        <Button type="submit"
                          color="primary"
                          >
                         {isSaving && <i className="bx bx-loader bx-spin mr-2 align-middle"></i>}
                        {isSaving? "Saving..." : "Save"}
                        </Button>
                      </div>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>

        </Container>
      </div>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  return {
    selectedSchool,
  };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      editSchool,

    })(EditSchool)
  )
);

