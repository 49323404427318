import React from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody } from "reactstrap";

//Import Cards
import CardMaintenance from "../pages/Utility/card-maintenance";


//Import Images
import logo from "../assets/images/logo-dark.png";
import iStore from "../assets/images/appStore/iStore.png";
import gPlayStore from "../assets/images/appStore/gPlayStore.png";
import webLogo from "../assets/images/appStore/logo-websites.png";

    const PagesMaintenance = (props) => {

        return (
              <React.Fragment>
                <section className="my-5 pt-sm-5">
                    <Container>
                        <Row>
                            <Col xs="12" className="text-center">
                                <div className="home-wrapper">
                                    <div className="mb-5">
                                        <img src={logo} alt="logo" height="24" />
                                    </div>

                                    {/* <Row className="justify-content-center">
                                        <Col sm={4}>
                                            <div className="maintenance-img">
                                                <img src={maintenance} alt="" className="img-fluid mx-auto d-block" />
                                            </div>
                                        </Col>
                                    </Row> */}
                                    <h3 className="">Download the lilTriangle</h3>
                                    <p>Please choose the platform</p>

                                    <Row>
                                    <Col md="4">
                                        <Card className="mt-4 maintenance-box">
                                            <CardBody>
                                            <a href ="https://apps.apple.com/in/app/liltriangle/id956406796" target=" ">
                                            <img src={iStore} alt="" className="" />
                                            </a>
                                            <h5 className="font-size-15 text-uppercase">Download from AppStore</h5>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                       
                                    <Col md="4">
                                        <Card className="mt-4 maintenance-box">
                                            <CardBody>
                                            <a href="https://play.google.com/store/apps/details?id=com.junior" target=" ">
                                            <img src={gPlayStore} alt="" className="" />
                                            </a>
                                            <h5 className="font-size-15 text-uppercase">
                                                Download from PlayStore</h5>
                                            </CardBody>
                                        </Card>
                                    </Col>

                                    <Col md="4">
                                        <Card className="mt-4 maintenance-box">
                                            <CardBody>
                                            <a href="https://liltriangleweb.web.app/" target=" " >
                                            <img src={webLogo} alt="" width="250px" height="250px" className="" />
                                            </a>
                                            <h5 className="font-size-15 text-uppercase">
                                                redirect to website</h5>
                                            </CardBody>
                                        </Card>
                                    </Col>

                    
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                    </Container>
                </section>
            </React.Fragment>
              );
        }
            
export default PagesMaintenance;