import React, { useEffect, useState } from "react";
import {
  CardSubtitle,
  CardTitle,
  Col,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import DatePicker from "react-datepicker";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import Parse from "parse";


import {
    getFranchise,
    getCorporatePartners,
} from "../../../store/actions";
import FeeAnalytics from "./FeeAnalytics";
import RoyaltyReoprt from "./RoyaltyReoprt";
import RawDataReport from "./RawDataReport";
import RevenueReport from "./RevenueReport";

const EnquiryReport = (props) => {

    const [allReports, setAllReport] = useState(["Analytics"]);

    const [reportType, setReportType] = useState("Analytics");
    const [selectedStartDate, setSelectedStartDate] = useState(new Date());
    const [selectedEndDate, setSelectedEndDate] = useState(new Date());
    const [startDateError, setStartDateError] = useState(false);
    const [endDateError, setEndDateError] = useState(false);
    const [onClickGenerate, setOnClickGenerate] = useState(false);
    const handelGenerate = (bool) => {
        setOnClickGenerate(bool);
    };

    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    const [onClickApply, setOnClickApply] = useState(false);
    const handelDateChange = (bool) => {
        setOnClickApply(bool);
    };
    const [showRoyalty, setShowRoyalty] = useState(false);

    const [selectedPartner, setSelctedPartner] = useState(undefined);

    useEffect(() => {
        if (Parse.User.current()) {
            if (Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.franchiseId.length > 0) {
                props.getFranchise(Parse.User.current().attributes.franchiseId,true);
            }else {
                props.history.push("/pages-404");
            }
        } else {
            props.history.push("/pages-404");
        }
        
    }, [Parse.User.current()]);
  
  
    useEffect(() => {

        let list = ["Analytics"];
        
        if (props.franchise &&
            props.franchise.attributes.features &&
            props.franchise.attributes.features.length > 2 &&
            props.franchise.attributes.features[2] > 0) {
            setShowRoyalty(true);

            list.push("Royalty")
        }else{
            setShowRoyalty(false);
        }
        if (  props.franchise &&
            props.franchise.attributes.features &&
            props.franchise.attributes.features.length > 2 &&
            props.franchise.attributes.features[4] > 0) {
            // list.push('Corporate report')
            props.getCorporatePartners(props.franchise, "franchiseId")
            list.push("Revenue Report")
        }
        list.push("Data Reports")
        
        setAllReport(list)
    }, [props.franchise]);

    

    return (
        <React.Fragment>
            <CardTitle>Fee Report</CardTitle>
            <CardSubtitle className="mb-3">
                Select filter to generate the report
            </CardSubtitle>
            <Row className="bg-light">
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Report Type :</Label>
                        <select
                            className="form-control "
                            value={reportType}
                            onChange={(e) => {
                                setReportType(e.target.value);
                                if (e.target.value == "Analytics") {
                                    if (fromDate && toDate) {
                                        setOnClickApply(false);
                                    }
                                } else {
                                    handelGenerate(false);  
                                }
                            }}
                        >
                            {allReports.map((val) => (
                                <option value={val} key={val}>
                                    {val}
                                </option>
                            ))}
                        </select>
                    </FormGroup>
                </Col>
                {reportType === "Analytics" &&
                    <Col >
                        <Row className=" bg-light ml-2">
               
                            <Row>
                                <Col lg={4} xs={6}>
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label >
                                            {props.t("From")} :
                                        </Label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => {
                                                onClickApply && handelDateChange(false);
                                                setFromDateError(false)
                                                setFromDate(date)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Date")}
                                            startDate={fromDate}
                                            endDate={toDate}
                                            maxDate={toDate}
                                            dateFormat="MMM-yyyy"
                                            required
                                            errorMessage="Please select"
                                            showMonthYearPicker
                                        />
                                    </FormGroup>
                                    {fromDateError && <p className="text-danger ml-2">Please select</p>}
                                </Col>
                                <Col lg={4} xs={6}>
                                    <FormGroup className="mt-2 mb-2 p-1">
                                        <Label >
                                            {props.t("To")} :
                                        </Label>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => {
                                                onClickApply && handelDateChange(false);
                                                setToDateError(false);
                                                setToDate(date)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Date")}
                                            startDate={fromDate}
                                            endDate={toDate}
                                            minDate={fromDate}
                                            dateFormat="MMM-yyyy"
                                            required
                                            errorMessage="Please select"
                                            showMonthYearPicker
                                        />
                                    </FormGroup>
                                    {toDateError && <p className="text-danger ml-2">Please select</p>}
                                </Col>
                                <Col lg={3} >
                                    <div className="mt-4 mb-1 p-1">
                                        <button
                                            // disabled={loadingState === 2}
                                            onClick={(e, v) => {
                                                let next = true;
                                                if (!toDate) {
                                                    setToDateError(true);
                                                    next = false;
                                                }
                                                if (!fromDate) {
                                                    setFromDateError(true);
                                                    next = false;
                                                }
                                                if (next) {
                                                    handelDateChange(true);
                                                }
                            
                                            }}
                                            className="w-md mt-2  btn btn-primary"
                                        >
                                            {props.t("Apply")}
                                        </button>
                      
                                    </div>
                                </Col>
                            </Row>
             
                        </Row>
                    </Col>
                }
                {reportType === "Royalty" && <Row>
                    <Col md="3" xs="12" xl="3" lg="3">
                        <FormGroup className="mt-2 mb-2 p-1">
                            <Label>From Date :</Label>
                            <DatePicker
                                selected={selectedStartDate}
                                onChange={(date) => {
                                    date ? setSelectedStartDate(date) : setSelectedStartDate(new Date());
                                }}
                                className="form-control"
                                placeholderText={"Select Date"}
                                
                                maxDate={selectedEndDate}
                                dateFormat="dd/MM/yyyy"
                                showFullMonthYearPicker
                            />
                        </FormGroup>
                        {startDateError && <p className="text-danger ml-2">Please select</p>}
                    </Col>
                    <Col md="3" xs="12" xl="3" lg="3">
                        <FormGroup className="mt-2 mb-2 p-1">
                            <Label>To Date :</Label>
                            <DatePicker
                                selected={selectedEndDate}
                                onChange={(date) => {
                                    date
                                        ? setSelectedEndDate(date)
                                        : setSelectedEndDate(new Date());
                                }}
                                className="form-control"
                                placeholderText={"Select Date"}
                                minDate={selectedStartDate}
                                maxDate={new Date()}
                                dateFormat="dd/MM/yyyy"
                                showFullMonthYearPicker
                            />
                        </FormGroup>
                        {endDateError && <p className="text-danger ml-2">Please select</p>}
                    </Col><Col md="3" xs="12" xl="3" lg="3">
                        <div className="mt-2">
                            <div className="mt-3">
                                <button
                                    className="btn btn-primary mt-4 mr-2"
                                    onClick={() => {
                                        let next = true;
                                        if (!selectedEndDate) {
                                            setEndDateError(true);
                                            next = false;
                                        }
                                        if (!selectedStartDate) {
                                            setStartDateError(true);
                                            next = false;
                                        }
                                        if (next) {
                                            handelGenerate(true);   
                                        }
                                    }}
                                >
                                    Generate
                                </button>
                            </div>
                        </div>
                    </Col>
                </Row>}

                {reportType === "Consolidated** " && <Col >
                       
                    <Row>
                        <Col md="3" xs="12" xl="3" lg="3">
                            <FormGroup className="mt-2 mb-2 p-1">
                                <Label>From  </Label>
                                <DatePicker
                                    selected={selectedStartDate}
                                    onChange={(date) => {
                                        date ? setSelectedStartDate(date) : setSelectedStartDate(new Date());
                                    }}
                                    className="form-control"
                                    placeholderText={"Select Date"}
                                
                                    maxDate={selectedStartDate}
                                    dateFormat="dd/MM/yyyy"
                                    showFullMonthYearPicker
                                />
                            </FormGroup>
                            {startDateError && <p className="text-danger ml-2">Please select</p>}
                        </Col>
                        <Col md="3" xs="12" xl="3" lg="3">
                            <FormGroup className="mt-2 mb-2 p-1">
                                <Label>To </Label>
                                <DatePicker
                                    selected={selectedEndDate}
                                    onChange={(date) => {
                                        date
                                            ? setSelectedEndDate(date)
                                            : setSelectedEndDate(new Date());
                                    }}
                                    className="form-control"
                                    placeholderText={"Select Date"}
                                    minDate={selectedEndDate}
                                    maxDate={new Date()}
                                    dateFormat="dd/MM/yyyy"
                                    showFullMonthYearPicker
                                />
                            </FormGroup>
                            {endDateError && <p className="text-danger ml-2">Please select</p>}
                        </Col>
                        <Col md="2" xs="12" xl="2" lg="2">
                            <div className="mt-2">
                                <div className="mt-3">
                                    <button
                                        className="btn btn-primary mt-4 mr-2"
                                        onClick={() => {
                                            let next = true;
                                            if (!selectedEndDate) {
                                                setEndDateError(true);
                                                next = false;
                                            }
                                            if (!selectedStartDate) {
                                                setStartDateError(true);
                                                next = false;
                                            }
                                            if (next) {
                                                handelGenerate(true);
                                            }
                                        }}
                                    >
                                        Generate
                                    </button>
                                </div>
                            </div>
                        </Col>
                    </Row>

                </Col>
                }
            </Row>
            {reportType === "Revenue Report" && <RevenueReport
                isFranchise={true}
                franchise={props.franchise}
            />}
      
            {/* {reportType === "9" && UIForDisplayTable(reportType)} */}

            {reportType === "Analytics" && <FeeAnalytics
                isFranchise={true}
                onClickApply={onClickApply}
                fromDate={fromDate}
                toDate={toDate}
      
            />}

            {reportType === "Royalty" && <RoyaltyReoprt
                isFranchise={true}
                onClickApply={onClickGenerate}
                fromDate={selectedStartDate}
                toDate={selectedEndDate}
                franchise={props.franchise}
      
            />}

            {reportType === "Data Reports" && <RawDataReport
                isFranchise={true}
                // onClickApply={onClickApply}
                // fromDate={selectedStartDate}
                // toDate={selectedEndDate}
                showDatePickers={true}
                reportType={reportType}
      
            />}
      
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
  
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners, corporatePartnerAddLoading } = state.CorporatePartners;
  return {
      schools, selectedSchool,
      franchise,
      corporatePartners, corporatePartnerAddLoading,
  };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
        getFranchise,
        getCorporatePartners,
    })(EnquiryReport)
  )
);
