import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  Button,
  Input,
  FormGroup,
  CardBody,
} from "reactstrap";
import ReactDatePicker from "react-datepicker";
import { matchPath } from "react-router";
import Moment from "moment";
import toastr from "toastr";
import TransferCertificatePrint from "./TransferCertificatePrint"
import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { AvForm, AvField } from "availity-reactstrap-validation";
import * as Constants from "../../Constents";
import {
  setSelecetdSchool,
  getAllDeletedKidsForIds,
  getChildAdditionalDetailForKidId,
  editChildAdditionalDetail,
  getClassroomsForSchoolId,
  updateAKid,
  updateAParent,
  clearChildAdditionalDetails, getParentsForFamilyId
} from "../../store/actions";
import TopBar from "./Topbar";

const TransferCertificateForm = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/transfer-certificate/:schoolId/:kidId",
    exact: true,
    strict: false,
  });

  var schoolId = undefined;
  var kidId = undefined;

  if (match) {
    schoolId = match.params.schoolId;
    kidId = match.params.kidId;
  }



  const KID_NAME_FOR_TC = "KID_NAME_FOR_TC"
  const SCHOOL_NAME_FOR_TC = "SCHOOL_NAME_FOR_TC"
  const ADMISSION_NUMBER_FOR_TC = "admissionNumber"
  const CUMULATIVE_RECORD_NUMBER_FOR_TC = "cumulativeRecordNumber"
  const BIRTH_PLACE_FOR_TC = "birthPlace"
  const TALUK_FOR_TC = "taluk"
  const DISTRICT_FOR_TC = "district"
  const STATE_FOR_TC = "state"
  const GENDER_FOR_TC = "gender"
  const NATIONALITY_FOR_TC = "nationality"
  const RELIGION_FOR_TC = "religion"
  const CASTE_FOR_TC = "caste"
  const SUBCASTE_FOR_TC = "subCaste"
  const FATHER_NAME_FOR_TC = "fatherName"
  const MOTHER_NAME_FOR_TC = "motherName"
  const SCHEDULED_CASTE_FOR_TC = "scheduledCaste"
  const QUALIFIED_FOR_PROMOTION_FOR_TC = "qualifiedForPromotion"
  const DATE_OF_BIRTH_FOR_TC = "dob"
  const CLASS_WHILE_LEAVING = "classWhileLeaving"
  const LANGUAGE_STUDIED_FOR_TC = "languageStudied"
  const ELECTIVES_STUDIES_FOR_TC = "electivesStudies"
  const INSTRUCTION_MEDIUM_FOR_TC = "instructionMedium"
  const DATE_OF_ADMISSION_FOR_TC = "dateOfAdmission"
  const FEES_PAID_FOR_TC = "feesPaid"
  const FEE_CONCESSIONS_FOR_TC = "feeConcessions"
  const SCHOLARSHIP_FOR_TC = "scholarship"
  const MEDICAL_EXAMINATION_FOR_TC = "medicalExamination"
  const LAST_ATTENDANCE_FOR_TC = "lastAttendance"
  const DATE_TRANSFER_CERTIFICATE_RECEIVED = "dateTransferCertificateReceived"
  const DATE_TC_ISSUED = "dateTCIssued"
  const TOTAL_SCHOOL_DAYS = "totalSchoolDays"
  const ATTENDED_SCHOOL_DAYS = "attendedSchoolDays"
  const CHARATER_CONDUCT = "characterConduct"






  const [tcObjectList, setTCObjectList] = useState([
    { question: "Name of the Student", answer: "", key: KID_NAME_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false, isMandatory: true },
    { question: "Name of the School", answer: "", key: SCHOOL_NAME_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false, isMandatory: true },
    { question: "Admission Number", answer: "", key: ADMISSION_NUMBER_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Cumulative Record Number", answer: "", key: CUMULATIVE_RECORD_NUMBER_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Place of Birth", answer: "", key: BIRTH_PLACE_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Taluk", answer: "", key: TALUK_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "District", answer: "", key: DISTRICT_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "State", answer: "", key: STATE_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Gender", answer: "", key: GENDER_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Nationality", answer: "", key: NATIONALITY_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Religion", answer: "", key: RELIGION_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Caste", answer: "", key: CASTE_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Sub Caste", answer: "", key: SUBCASTE_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Name of the Father", answer: "", key: FATHER_NAME_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Name of the Mother", answer: "", key: MOTHER_NAME_FOR_TC, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Candidate belongs to scheduled castes or scheduled tribes?", answer: "", key: SCHEDULED_CASTE_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Whether qualified for promotion to a higher Standard ?", answer: "", key: QUALIFIED_FOR_PROMOTION_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Student's Date of Birth", answer: undefined, key: DATE_OF_BIRTH_FOR_TC, questionType: Constants.QESTION_DATE, isValueChanged: false },
    { question: "Student's present Class", answer: "", key: CLASS_WHILE_LEAVING, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "In the case of student of Higher Standards-Language Studied", answer: "", key: LANGUAGE_STUDIED_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "In the case of student of Higher Standards-Electives Studies", answer: "", key: ELECTIVES_STUDIES_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Medium of Instruction", answer: "", key: INSTRUCTION_MEDIUM_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Date of Admission or Promotion to that Class or Standard", answer: "", key: DATE_OF_ADMISSION_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Whether all Balance of fees paid or not ?", answer: "", key: FEES_PAID_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Fee concessions, If any (nature and period to be specified)", answer: "", key: FEE_CONCESSIONS_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Scholarship if any (nature & period to be specified", answer: "", key: SCHOLARSHIP_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Whether Medical Examined or not ?", answer: "", key: MEDICAL_EXAMINATION_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Date of Student's last attendance at School", answer: "", key: LAST_ATTENDANCE_FOR_TC, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Date of receiving the application for Transfer Certificate", answer: "", key: DATE_TRANSFER_CERTIFICATE_RECEIVED, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Date of issue of the Transfer Certificate", answer: "", key: DATE_TC_ISSUED, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Number of School days up to the Date of leaving", answer: "", key: TOTAL_SCHOOL_DAYS, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Number of School days the student attended", answer: "", key: ATTENDED_SCHOOL_DAYS, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },
    { question: "Character and Conduct", answer: "", key: CHARATER_CONDUCT, shouldSaveInTCObject: true, questionType: Constants.QESTION_TEXT_SINGLE_LINE, isValueChanged: false },


  ])



  const [schoolName, setSchoolName] = useState(undefined);
  const [schoolLogo, setSchoolLogo] = useState(undefined);
  const [childAdditionalDetailsObject, setChildAdditionalDetailsObject] = useState(undefined)
  const [showPrintPreview, setShowPrintPreview] = useState(false)
  const [motherObject, setMotherObject] = useState(undefined);
  const [fatherObject, setFatherObject] = useState(undefined);
  const [isLoading, setIsLoading] = useState(true);
  const [kidObject, setKidObject] = useState(undefined)

  const backButtonPressedInPrintPreview = () => {
    setShowPrintPreview(false)
  }


  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
    props.getAllDeletedKidsForIds([kidId])
    props.getChildAdditionalDetailForKidId(kidId)
    props.getClassroomsForSchoolId(schoolId);
    getSchool()
  }, [props.selectedSchool, schoolId]);


  useEffect(() => {

    if (props.deletedKids && props.deletedKids.length > 0) {
      if (props.deletedKids[0].id === kidId) {
        setKidObject(props.deletedKids[0])
        setIsLoading(false)
        if (props.deletedKids[0].attributes.FamilyID) {
          props.getParentsForFamilyId(props.deletedKids[0].attributes.FamilyID);

        }


        pushToList(KID_NAME_FOR_TC, props.deletedKids[0].attributes.Name)
        pushToList(GENDER_FOR_TC, props.deletedKids[0].attributes.Gender)
        pushToList(DATE_OF_BIRTH_FOR_TC, props.deletedKids[0].attributes.DateOfBirth)
        pushToList(ADMISSION_NUMBER_FOR_TC, props.deletedKids[0].attributes.admissionNumber)

        let classroomId = props.deletedKids[0].attributes.ClassRoomID
        let tempClassrooms = props.classrooms.filter((classroom) => {
          return classroom.id === classroomId;
        });
        if (tempClassrooms && tempClassrooms.length > 0) {
          pushToList(CLASS_WHILE_LEAVING, tempClassrooms[0].attributes.Name)
        }

      } else {

      }
    }

  }, [props.deletedKids])

  useEffect(() => {
    if (props.selectedParents != null && props.selectedParents.length > 0) {


      for (var i = 0; i < props.selectedParents.length; i++) {
        let parent = props.selectedParents[i]

        if (parent.attributes.Relation.toLowerCase() === "father") {
          setFatherObject(parent)
          pushToList(FATHER_NAME_FOR_TC, parent.attributes.Name)
        } else if (parent.attributes.Relation.toLowerCase() === "mother") {
          setMotherObject(parent)
          pushToList(MOTHER_NAME_FOR_TC, parent.attributes.Name)

        }

      }





    }
  }, [props.selectedParents])

  const pushToList = (key, value) => {
    let tempObjects = tcObjectList.filter((obj) =>
      obj.key === key

    )
    if (tempObjects.length > 0) {
      let index = tcObjectList.indexOf(tempObjects[0])
      tempObjects[0].answer = value
      let tempList = [...tcObjectList]
      tempList[index] = tempObjects[0]
      setTCObjectList(tempList)
    }
  }


  const getAnswerFromListForKey = (key) => {
    let tempObjects = tcObjectList.filter((obj) =>
      obj.key === key

    )
    if (tempObjects.length > 0 && tempObjects[0].isValueChanged) {

      return tempObjects[0].answer

    }
    return false

  }




  useEffect(() => {
    if (props.childadditionaldetails && props.childadditionaldetails.length > 0) {


      if (props.childadditionaldetails[0].attributes.kidId === kidId) {
        setChildAdditionalDetailsObject(props.childadditionaldetails[0])



        if (props.childadditionaldetails[0].attributes.nationality) {
          pushToList(NATIONALITY_FOR_TC, props.childadditionaldetails[0].attributes.nationality)
        }
        if (props.childadditionaldetails[0].attributes.caste) {
          pushToList(CASTE_FOR_TC, props.childadditionaldetails[0].attributes.caste)
        }
        if (props.childadditionaldetails[0].attributes.subCaste) {
          pushToList(SUBCASTE_FOR_TC, props.childadditionaldetails[0].attributes.subCaste)
        }
        if (props.childadditionaldetails[0].attributes.religion) {
          pushToList(RELIGION_FOR_TC, props.childadditionaldetails[0].attributes.religion)
        }


        let tcTempObject = props.childadditionaldetails[0].attributes.transferCertificateObject




        if (tcTempObject) {
          let tempList = [...tcObjectList]
          for (const key in tcTempObject) {
            let tempObjects = tcObjectList.filter((obj) =>
              obj.key === key && obj.shouldSaveInTCObject
            )
            if (tempObjects.length > 0) {
              let index = tcObjectList.indexOf(tempObjects[0])
              tempObjects[0].answer = tcTempObject[key]
              tempList[index] = tempObjects[0]
            }
          }
          setTCObjectList(tempList)
        }
      } else {
        props.clearChildAdditionalDetails()
      }






    }
  }, [props.childadditionaldetails])




  function getSchool() {
    var School = Parse.Object.extend("School");
    var query = new Parse.Query(School);

    query.get(schoolId).then(
      (result) => {
        //setLoading(false);
        pushToList(SCHOOL_NAME_FOR_TC, result.attributes.Name)

        setSchoolName(result.attributes.Name);
        //setSchoolCity(result.attributes.city);
        if (result.attributes.logo) {
          setSchoolLogo(result.attributes.logo._url);
        }
        //setSchool(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }


  const handlePrintPreviewClick = () => {

    if (true) {

      setShowPrintPreview(true)
    } else {
      toastr.warning("Please fill mandatory fields")
    }
  }

  const handleSaveDetails = (onlySave) => {


    if (childAdditionalDetailsObject) {

      let tcObject = new TransferCertificateFields()
      for (var i = 0; i < tcObjectList.length; i++) {
        if (tcObjectList[i].shouldSaveInTCObject) {
          tcObject[tcObjectList[i].key] = tcObjectList[i].answer
        }
      }
      childAdditionalDetailsObject.set("transferCertificateObject", tcObject)

      let nationalityText = getAnswerFromListForKey(NATIONALITY_FOR_TC)
      if (nationalityText && nationalityText.length > 0) {
        childAdditionalDetailsObject.set("nationality", nationalityText)
      }
      let casteText = getAnswerFromListForKey(CASTE_FOR_TC)
      if (casteText && casteText.length > 0) {
        childAdditionalDetailsObject.set("caste", casteText)
      }
      let subCasteText = getAnswerFromListForKey(SUBCASTE_FOR_TC)
      if (subCasteText && subCasteText.length > 0) {
        childAdditionalDetailsObject.set("subCaste", subCasteText)
      }
      let religionText = getAnswerFromListForKey(RELIGION_FOR_TC)

      if (religionText && religionText.length > 0) {
        childAdditionalDetailsObject.set("religion", religionText)
      }

      props.editChildAdditionalDetail(childAdditionalDetailsObject)



      if (kidObject && kidObject.id === kidId) {
        let canUpdateObject = false
        let tempStudentName = getAnswerFromListForKey(KID_NAME_FOR_TC)
        if (tempStudentName && tempStudentName.length > 0) {
          kidObject.set("Name", tempStudentName)
          canUpdateObject = true
        }
        let tempStudentDOB = getAnswerFromListForKey(DATE_OF_BIRTH_FOR_TC)

        if (tempStudentDOB) {
          kidObject.set('DateOfBirth', tempStudentDOB)
          canUpdateObject = true

        }
        let tempAdmissionNumber = getAnswerFromListForKey(ADMISSION_NUMBER_FOR_TC)

        if (tempAdmissionNumber && tempAdmissionNumber.length > 0) {
          kidObject.set('admissionNumber', tempAdmissionNumber)
          canUpdateObject = true

        }
        if (canUpdateObject) {
          props.updateAKid(kidObject)

        }


      }





      if (fatherObject && kidObject.id === kidId && fatherObject.attributes.FamilyID === kidObject.attributes.FamilyID) {
        let canUpdateObject = false
        let tempFatherName = getAnswerFromListForKey(FATHER_NAME_FOR_TC)
        if (tempFatherName && tempFatherName.length > 0) {
          fatherObject.set("Name", tempFatherName)
          canUpdateObject = true
        }

        if (canUpdateObject) {
          props.updateAParent(fatherObject)

        }


      }
      if (motherObject && kidObject.id === kidId && motherObject.attributes.FamilyID === kidObject.attributes.FamilyID) {
        let canUpdateObject = false
        let tempMotherName = getAnswerFromListForKey(MOTHER_NAME_FOR_TC)
        if (tempMotherName && tempMotherName.length > 0) {
          motherObject.set("Name", tempMotherName)
          canUpdateObject = true
        }

        if (canUpdateObject) {
          props.updateAParent(motherObject)

        }


      }





      if (onlySave) {
        toastr.success("Details saved successfully !!")
      }


    } else {

      if (kidId && kidId.length > 0) {
        let NewCADObject = Parse.Object.extend("ChildAdditionalDetails")
        let newCADObject = new NewCADObject()
        newCADObject.set("kidId", kidId)
        let nationalityText = getAnswerFromListForKey(NATIONALITY_FOR_TC)
        if (nationalityText && nationalityText.length > 0) {
          childAdditionalDetailsObject.set("nationality", nationalityText)
        }
        let casteText = getAnswerFromListForKey(CASTE_FOR_TC)
        if (casteText && casteText.length > 0) {
          childAdditionalDetailsObject.set("caste", casteText)
        }
        let subCasteText = getAnswerFromListForKey(SUBCASTE_FOR_TC)
        if (subCasteText && subCasteText.length > 0) {
          childAdditionalDetailsObject.set("subCaste", subCasteText)
        }
        let religionText = getAnswerFromListForKey(RELIGION_FOR_TC)

        if (religionText && religionText.length > 0) {
          childAdditionalDetailsObject.set("religion", religionText)
        }
        let tcObject = new TransferCertificateFields()
        for (var i = 0; i < tcObjectList.length; i++) {
          if (tcObjectList[i].shouldSaveInTCObject) {
            tcObject[tcObjectList[i].key] = tcObjectList[i].answer
          }
        }
        newCADObject.set("transferCertificateObject", tcObject)


        props.editChildAdditionalDetail(newCADObject)
        if (kidObject && kidObject.id === kidId) {
          let canUpdateObject = false
          let tempStudentName = getAnswerFromListForKey(KID_NAME_FOR_TC)
          if (tempStudentName && tempStudentName.length > 0) {
            kidObject.set("Name", tempStudentName)
            canUpdateObject = true
          }
          let tempStudentDOB = getAnswerFromListForKey(DATE_OF_BIRTH_FOR_TC)

          if (tempStudentDOB) {
            kidObject.set('DateOfBirth', tempStudentDOB)
            canUpdateObject = true

          }
          let tempAdmissionNumber = getAnswerFromListForKey(ADMISSION_NUMBER_FOR_TC)

          if (tempAdmissionNumber && tempAdmissionNumber.length > 0) {
            kidObject.set('admissionNumber', tempAdmissionNumber)
            canUpdateObject = true

          }
          if (canUpdateObject) {
            props.updateAKid(kidObject)

          }


        }
        if (fatherObject && kidObject.id === kidId && fatherObject.attributes.FamilyID === kidObject.attributes.FamilyID) {
          let canUpdateObject = false
          let tempFatherName = getAnswerFromListForKey(FATHER_NAME_FOR_TC)
          if (tempFatherName && tempFatherName.length > 0) {
            fatherObject.set("Name", tempFatherName)
            canUpdateObject = true
          }

          if (canUpdateObject) {
            props.updateAParent(fatherObject)

          }


        }
        if (motherObject && kidObject.id === kidId && motherObject.attributes.FamilyID === kidObject.attributes.FamilyID) {
          let canUpdateObject = false
          let tempMotherName = getAnswerFromListForKey(MOTHER_NAME_FOR_TC)
          if (tempMotherName && tempMotherName.length > 0) {
            motherObject.set("Name", tempMotherName)
            canUpdateObject = true
          }

          if (canUpdateObject) {
            props.updateAParent(motherObject)

          }


        }
        toastr.success("Details saved successfully !!")
      } else {
        toastr.success("Something went wrong please try again !!")
      }

    }







  }


  const componentForQuestionType = (
    tcObject,
    key
  ) => {
    var questionType = tcObject.questionType;

    switch (questionType) {


      case Constants.QESTION_TEXT_SINGLE_LINE:
        return (
          <Col lg="6">
            <FormGroup>
              <Label for={tcObject.question}>{tcObject.question}</Label>
              {tcObject.isMandatory && (
                <Label className="text-danger">*</Label>
              )}
              {tcObject.answerError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}
              <Input
                type="text"
                className="form-control"
                id={tcObject.question}
                placeholder="Type here"
                value={tcObject.answer}
                onChange={(e) => {
                  let tempList = [...tcObjectList]
                  let index = tempList.indexOf(tcObject)
                  let tempObject = { ...tempList[index] }
                  tempObject.answer = e.target.value
                  if (e.target.value === "") {
                    tempObject.answerError = true
                  } else {
                    tempObject.answerError = false

                  }
                  tempObject.isValueChanged = true

                  tempList[index] = tempObject
                  setTCObjectList(tempList)
                }}
              />
            </FormGroup>
          </Col>

        );

        break;





      case Constants.QESTION_DATE:

        return (
          <Col lg="6"  >



            <FormGroup>
              <Label for={tcObject.question}>{tcObject.question}</Label>



              <ReactDatePicker

                id={"questionDate" + key}
                selected={
                  tcObject.answer ? new Date(tcObject.answer) : undefined
                }
                onChange={(date) => {
                  let tempList = [...tcObjectList]
                  let index = tempList.indexOf(tcObject)
                  let tempObject = { ...tempList[index] }
                  if (date) {
                    tempObject.answer = new Date(date)
                    tempObject.isValueChanged = true

                  }

                  tempList[index] = tempObject
                  setTCObjectList(tempList)
                }}
                className="form-control"
                placeholderText="dd/mm/yyyy"
                dateFormat="dd/MM/yyyy"
                showDayMonthYearPicker
              /></FormGroup>
          </Col>
        );
        break;

    }
  };

  const checkForMandatory = () => {
    let returnValue = true
    for (var i = 0; i < tcObjectList.length; i++) {
      if (tcObjectList[i].isMandatory && (tcObjectList[i].answer == undefined || tcObjectList[i].answer.length === 0)) {
        returnValue = false
      }
    }
    return returnValue
  }

  return (
    <React.Fragment>
      {!showPrintPreview ? <div className="page-content">
        {!isLoading && (

          <Container fluid>
            <TopBar
              title={"Transfer Certificate Form"}
              buttonName={""}
              backButton={true}
              hideButton={true}
              onClickAdd={() => { }}
              onClickBackButton={() => {
                window.history.back();
              }}
            />
            <Card>
              <CardBody>
                <AvForm>
                  {/* <Row>
                    <div className="d-flex justify-content-start">
                      <Label style={{ fontSize: "16px" }}>
                        Transfer Certificate :
                      </Label>
                      <Button

                        className="btn btn-success waves-effect waves-light justify-content-end"
                        onClick={() => {

                          if (checkForMandatory()) {
                            handleSaveDetails()
                            handlePrintPreviewClick()
                          }


                        }}
                      >
                        <i className="fa fa-print"></i>Print Preview
                      </Button>
                      <Button
                        className="btn btn-success waves-effect waves-light justify-content-end"
                        onClick={() => {
                          handleSaveDetails(true)
                        }}
                      >
                        <i className="fa fa-save mr-2"></i>Save
                      </Button>
                    </div>
                    <hr />
                  </Row> */}
                  <Row>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <Label style={{ fontSize: "16px" }}>Transfer Certificate:</Label>
                      <div>
                        <Button
                          className="btn btn-success waves-effect waves-light mb-2"
                          style={{ marginRight: '10px' }}
                          onClick={() => {
                            handleSaveDetails(true);
                          }}
                        >
                          <i className="fa fa-save mr-2"></i> Save
                        </Button>
                        <Button
                          className="btn btn-success waves-effect waves-light mb-2"
                          onClick={() => {
                            if (checkForMandatory()) {
                              handleSaveDetails();
                              handlePrintPreviewClick();
                            }
                          }}
                        >
                          <i className="fa fa-print"></i> Print Preview
                        </Button>
                      </div>
                    </div>
                    <hr />
                  </Row>






                  {tcObjectList.map((obj, key) => (
                    <Row key={key}>
                      {componentForQuestionType(obj, key)}
                    </Row>
                  ))}





                </AvForm>
              </CardBody>
            </Card>


          </Container>

        )}



        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Transfer Certificate Form...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div> :
        <TransferCertificatePrint
          backButtonPressedInPrintPreview={backButtonPressedInPrintPreview}
          tcObjectList={tcObjectList}
          schoolName={schoolName}
          schoolLogo={schoolLogo}
        ></TransferCertificatePrint>}


    </React.Fragment>
  );
};





const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { deletedKids } = state.Kid;
  const { childadditionaldetails } = state.ChildAdditionalDetails
  const { classrooms } = state.Classroom;
  const { selectedParents } = state.Parent;



  return { selectedSchool, deletedKids, childadditionaldetails, classrooms, selectedParents };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getAllDeletedKidsForIds,
      getChildAdditionalDetailForKidId,
      editChildAdditionalDetail,
      getClassroomsForSchoolId,
      updateAKid,
      updateAParent,
      clearChildAdditionalDetails, getParentsForFamilyId

    })(TransferCertificateForm)
  )
);


class TransferCertificateFields {


  admissionNumber = ""
  cumulativeRecordNumber = ""
  birthPlace = ""
  taluk = ""
  district = ""
  state = ""
  scheduledCaste = ""
  qualifiedForPromotion = ""
  classWhileLeaving = ""
  languageStudied = ""
  electivesStudies = ""
  instructionMedium = ""
  dateOfAdmission = ""
  feesPaid = ""
  feeConcessions = ""
  scholarship = ""
  medicalExamination = ""
  lastAttendance = ""
  dateTransferCertificateReceived = ""
  dateTCIssued = ""
  totalSchoolDays = ""
  attendedSchoolDays = ""
  characterConduct = ""

  constructor(
    admissionNumber = "",
    cumulativeRecordNumber = "",
    birthPlace = "",
    taluk = "",
    district = "",
    state = "",
    scheduledCaste = "",
    qualifiedForPromotion = "",
    classWhileLeaving = "",
    languageStudied = "",
    electivesStudies = "",
    instructionMedium = "",
    dateOfAdmission = "",
    feesPaid = "",
    feeConcessions = "",
    scholarship = "",
    medicalExamination = "",
    lastAttendance = "",
    dateTransferCertificateReceived = "",
    dateTCIssued = "",
    totalSchoolDays = "",
    attendedSchoolDays = "",
    characterConduct = ""
  ) {


    this.admissionNumber = admissionNumber;
    this.cumulativeRecordNumber = cumulativeRecordNumber;
    this.birthPlace = birthPlace
    this.taluk = taluk
    this.district = district
    this.state = state

    this.scheduledCaste = scheduledCaste
    this.qualifiedForPromotion = qualifiedForPromotion
    this.classWhileLeaving = classWhileLeaving
    this.languageStudied = languageStudied
    this.electivesStudies = electivesStudies
    this.instructionMedium = instructionMedium
    this.dateOfAdmission = dateOfAdmission
    this.feesPaid = feesPaid
    this.feeConcessions = feeConcessions
    this.scholarship = scholarship
    this.medicalExamination = medicalExamination
    this.lastAttendance = lastAttendance
    this.dateTransferCertificateReceived = dateTransferCertificateReceived
    this.dateTCIssued = dateTCIssued
    this.totalSchoolDays = totalSchoolDays
    this.attendedSchoolDays = attendedSchoolDays
    this.characterConduct = characterConduct

  }
}

