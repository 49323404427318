import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, Button, Col, Row, Label, Form, FormGroup, Input, Card, CardBody, CardTitle } from "reactstrap";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import toastr from 'toastr';
import Parse from "parse";
import { withNamespaces } from "react-i18next";
import SimpleBar from "simplebar-react";
import Moment from "moment";

const LicenceSubscriptionUpdateModal = (props) => {
    const [licenseSubscription, setLicenseSubscription] = useState(null);
    const [isAdding, setIsAdding] = useState(false);
    const [isRemoving, setIsRemoving] = useState(false);
    const [licenseCountAdd, setLicenseCountAdd] = useState('');
    const [licenseCountRemove, setLicenseCountRemove] = useState('');
    const [loading, setLoading] = useState(false);
    const [licenseHistory, setLicenseHistory] = useState([]);

    const [canEdit, setCanEdit] = useState(false);

    const users = ["kartheek.nagasuri@liltriangle.com", "vinay.anna@liltriangle.com", "vinay.g@liltriangle.com", "mobin.eb@liltriangle.com", "sachin.pal@liltriangle.com", "mahesh.naik@liltriangle.com"];

    useEffect(() => {
        if (props.showPopup && props.institute) {
            resetForm();
            handleGetLicense();
            const userEmail = Parse.User.current().attributes.username;
            if (users.includes(userEmail)) {
                setCanEdit(true);
            }
        }
    }, [props.showPopup]);

    const resetForm = () => {
        setLicenseCountAdd('');
        setLicenseCountRemove('');
        setIsAdding(false);
        setIsRemoving(false);
        setLicenseHistory([]);
    };

    const handleGetLicense = () => {
        Parse.Cloud.run("function_getLicenseSubscription", {
            instituteId: props.institute.id,
            schoolId: "-",
            franchiseId: "-",
            hostName: "Web Tools"
        }).then((result) => {
            // console.log("Result:", result);
            setLicenseSubscription(result);
            getHistory(result.licenseSubscriptionId);
        }).catch((error) => {
            // console.error("Error fetching license subscription:", error);
        });
    };

    const handleUpdateLicense = (type, count) => {
        setLoading(true);
        Parse.Cloud.run("function_updatelicenseSubscription", {
            instituteId: props.institute.id,
            licenseSubscriptionId: licenseSubscription ? licenseSubscription.licenseSubscriptionId : undefined,
            type: type,
            count: parseInt(count),
            hostName: "Web Tools"
        }).then((result) => {
            // console.log("UpdateResult:", result);
            setLicenseSubscription(result);
            setLoading(false);

            if (result.LicenseSubscriptionLog) {
                let updatedHistory = [result.LicenseSubscriptionLog, ...licenseHistory];
                setLicenseHistory(updatedHistory);
            }
            if (type === 1) {
                setIsAdding(false);
                setLicenseCountAdd('');
            } else if (type === 2) {
                setIsRemoving(false);
                setLicenseCountRemove('');
            }
        }).catch((error) => {
            // console.error("Error updating license subscription:", error);
            setLoading(false);
        });
    };

    const getHistory = (licenseSubscriptionId) => {
        Parse.Cloud.run("function_getLicenseSubscriptionHistory", {
            licenseSubscriptionId: licenseSubscriptionId,
        }).then((result) => {
            // console.log("HistoryResult:", result);
            setLicenseHistory(result);
        }).catch((error) => {
            // console.error("Error fetching license subscription history:", error);
        });
    };

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY, h:mm A');
    }

    return (
        <Modal isOpen={props.showPopup} size="lg" toggle={props.handelClose}>
            <ModalHeader toggle={props.handelClose}>
                {props.institute ? <p className='m-0'>{"Subscription Details for  " + (props.institute.name)}</p> :
                    <p className='m-0'>{"Subscription Details "}</p>
                }
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col lg={6} className='text-center'>
                        <Form>
                            <FormGroup row className=''>
                                <Label for="subscriptionCount" lg={6} className='text-center'>No of Licence:</Label>
                                <Col lg={6}>
                                    <Input
                                        type="text"
                                        id="subscriptionCount"
                                        value={licenseSubscription ? licenseSubscription.numberOfSubscriptions : ''}
                                        disabled
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="usedSubscriptions" lg={6} className='text-center'>No of Used Licence:</Label>
                                <Col lg={6}>
                                    <Input
                                        type="text"
                                        id="usedSubscriptions"
                                        value={licenseSubscription ? licenseSubscription.numberOfUsedSubscriptions : ''}
                                        disabled
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="usedSubscriptions" lg={6} className='text-center'>Grace Licences:</Label>
                                <Col lg={6}>
                                    <Input
                                        type="text"
                                        id="usedSubscriptions"
                                        value={licenseSubscription ? licenseSubscription.grace : ''}
                                        disabled
                                    />
                                </Col>
                            </FormGroup>
                            <FormGroup row>
                                <Label for="usedSubscriptions" lg={6} className='text-center'>Available Licences:</Label>
                                <Col lg={6}>
                                    <Input
                                        type="text"
                                        id="usedSubscriptions"
                                        value={licenseSubscription ? licenseSubscription.grace - (licenseSubscription.numberOfUsedSubscriptions - licenseSubscription.numberOfSubscriptions) : ''}
                                        disabled
                                    />
                                </Col>
                            </FormGroup>

                            {canEdit && !isRemoving && <Button color="primary" className='m-2' onClick={() => setIsAdding(true)} disabled={isRemoving}>
                                Add Licence
                            </Button>}
                            {canEdit && !isAdding && <Button color="danger" className='m-2' style={{ marginLeft: '10px' }} onClick={() => setIsRemoving(true)} disabled={isAdding}>
                                Remove Licence
                            </Button>}

                        </Form>
                        {isAdding && (
                            <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <Input
                                    type="number"
                                    placeholder="Enter the number of licenses to add"
                                    value={licenseCountAdd}
                                    onChange={(e) => setLicenseCountAdd(e.target.value)}
                                    style={{ width: '300px' }}
                                />
                                <Button color="primary" onClick={() => handleUpdateLicense(1, licenseCountAdd)} style={{ marginLeft: '10px' }} disabled={loading}>
                                    {loading ? (
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    ) : null}
                                    {loading ? props.t('Adding...') : props.t('Add')}
                                </Button>
                                <Button color="secondary" onClick={() => setIsAdding(false)} className='m-2' style={{ marginLeft: '10px' }}>
                                    Cancel
                                </Button>
                            </div>
                        )}

                        {isRemoving && (
                            <div style={{ marginTop: '20px', marginLeft: '20px' }}>
                                <Input
                                    type="number"
                                    placeholder="Enter the number of licenses to remove"
                                    value={licenseCountRemove}
                                    onChange={(e) => setLicenseCountRemove(e.target.value)}
                                    style={{ width: '300px' }}
                                />
                                <Button color="danger" onClick={() => handleUpdateLicense(2, licenseCountRemove)} style={{ marginLeft: '10px' }} disabled={loading}>
                                    {loading ? (
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                    ) : null}
                                    {loading ? props.t('Removing...') : props.t('Remove')}
                                </Button>
                                <Button color="secondary" onClick={() => setIsRemoving(false)} className='m-2' style={{ marginLeft: '10px' }}>
                                    Cancel
                                </Button>
                            </div>
                        )}
                    </Col>
                    <Col lg={6}>
                        <Card>
                            <CardBody>
                                <CardTitle><h4>History</h4></CardTitle>
                                <SimpleBar style={{ maxHeight: "400px" }}>
                                    <ul>
                                        {licenseHistory.map((entry, index) => (
                                            <div key={index}>
                                                <li>
                                                    <p>
                                                        <b style={{ color: entry.attributes.type === 1 ? 'green' : 'red' }}>
                                                            {entry.attributes.type === 1 ? 'Added' : 'Removed'}
                                                            {' '}
                                                            {entry.attributes.licenses} licenses
                                                        </b>
                                                        {' '}
                                                        on {getTimeStringForDate(entry.attributes.createdAt)}
                                                        <h6 className='m-2'>Added by: {entry.attributes.parseUserName}</h6>
                                                    </p>
                                                </li>

                                                <hr />
                                            </div>
                                        ))}
                                    </ul>
                                </SimpleBar>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    );
};

const mapStatetoProps = state => {
    return {};
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, {})(LicenceSubscriptionUpdateModal)));
