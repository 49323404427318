import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";


const initialState = {
  inventoryWarehouses: [], inventoryWarehouseAddLoading: false,inventoryItemStockMap:[],
};

const InventoryWarehouse = (state = initialState, action) => {
  switch (action.type) {

    case actions.UPDATE_INVENTORY_WAREHOUSES:

      var inventoryWarehouses = [...state.inventoryWarehouses];
      if (inventoryWarehouses.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.inventoryWarehouses], action.payload.inventoryWarehouses, "isDeleted")

        state = { ...state, inventoryWarehouses: updatedList };
      } else {
        var i = 0
        var inventoryWarehousesToSave = []
        for (i = 0; i < action.payload.inventoryWarehouses.length; i++) {
          if (action.payload.inventoryWarehouses[i].attributes.isDeleted !== true) {
            inventoryWarehousesToSave.push(action.payload.inventoryWarehouses[i])
          }
        }
        state = { ...state, inventoryWarehouses: inventoryWarehousesToSave };
      }
      break;
    
    case actions.UPDATE_INVENTORY_STOCK_FOR_WAREHOUSES:

      var inventoryItemStockMap = [...state.inventoryItemStockMap];
      if (inventoryItemStockMap.length > 0) {
        var updatedList = ReduxUtil.updateParseObjects([...state.inventoryItemStockMap], action.payload.inventoryItemStockMap, "isDeleted")

        state = { ...state, inventoryItemStockMap: updatedList };
      } else {
        var i = 0
        var inventoryItemStockMapToSave = []
        for (i = 0; i < action.payload.inventoryItemStockMap.length; i++) {
          if (action.payload.inventoryItemStockMap[i].attributes.isDeleted !== true) {
            inventoryItemStockMapToSave.push(action.payload.inventoryItemStockMap[i])
          }
        }
        state = { ...state, inventoryItemStockMap: inventoryItemStockMapToSave };
      }
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default InventoryWarehouse;