import React, { useEffect, useState } from 'react';

import { setSelecetdSchool, getPaymentOptionsForSchool, getRatingParams, getSchoolRatings, clearSchoolRatings } from '../../store/actions';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import error from "../../assets/images/error-img.png";
import UpdatePhoto from "../../components/Common/UpdatePhoto";
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import SweetAlert from "react-bootstrap-sweetalert";
import Image from "../../assets/images/getStartedImages/ratingInitial.svg";
import TopHeader from "../../components/Common/topHeader"



//components
import SchoolRatings from './SchoolRatings';


import { editSchool, editPaymentOptions } from "../../store/actions";


import { Container, Row, Col, Card, CardBody, Label, FormGroup, Button, Nav, NavItem, NavLink, TabContent, TabPane, Table, Media, UncontrolledTooltip, } from "reactstrap";
import classnames from 'classnames';

//Import Star Ratings
import StarRatings from 'react-star-ratings';

//Import Product Images
import img7 from "../../assets/images/product/img-7.png";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';
import * as Constants from '../../Constents';
import { matchPath } from "react-router";

const Index = (props) => {


    const matchInsideRating = matchPath(props.history.location.pathname, {
        path: "/schoolratinginside/:schoolId",
        exact: true,
        strict: false,
    });
    const match = matchPath(props.history.location.pathname, {
        path: "/schoolrating/:schoolId",
        exact: true,
        strict: false,
    });

    const [selectedSchool, setSelecetdSchool] = useState(undefined);
    const [ratingType, setRatingType] = useState(2);

    var schoolId = matchInsideRating && matchInsideRating.params.schoolId;

    if (match) {
        schoolId = match && match.params.schoolId;
    } else if (matchInsideRating) {
        schoolId = matchInsideRating && matchInsideRating.params.schoolId;
    }


    useEffect(() => {
        props.setSelecetdSchool(schoolId);

        //Call function to get school then setSelectedSchool.
    }, [schoolId]);

    useEffect(() => {
        //props.clearSchoolRatings()
        props.getSchoolRatings(schoolId, ratingType);
    }, [schoolId, ratingType]);

    useEffect(() => {
        if (props.selectedSchool != null && props.selectedSchool.id)
            props.getRatingParams(props.selectedSchool);

    }, [props.selectedSchool]);


    const certificateButton = () => {
        return (<Link to={props.selectedSchool && props.selectedSchool.attributes ? `/schoolratingcertificate/${props.selectedSchool.id}` : "/schoolratingcertificate"} style={{ marginTop: '-9px'}} className="btn btn-primary float-right" target='_blank'>{props.t('Certificate')}</Link>)
    }

    const getSchoolRatings = () => {
        return (
            <FormGroup className="mt-2 mb-2 p-1">
                <select
                    className="form-control "
                    value={ratingType}
                    onChange={(e) => {
                        if(ratingType){
                            props.clearSchoolRatings()
                        }
                        setRatingType(e.target.value);
                    }}
                >
                    <option value={1}>All time Ratings</option>
                    <option value={2}>Recent Ratings( 1 Year)</option>
                </select>
            </FormGroup>
        )
    }

    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    {/* <Breadcrumbs title="School Ratings" breadcrumbItem="School Details" /> */}
                    {/* <Row className="mb-3">
                        <Col xl="4" sm="6">
                            <div className="mt-2">
                            <h4 className="mb-0 font-size-18">SCHOOL RATINGS</h4>
                            </div>
                        </Col>
                        <Col lg="8" sm="6">
                            {props.schoolRatings && props.schoolRatings.length > 0 && <div className="mt-4 mt-sm-0 float-sm-right form-inline">
                                <Link to={props.selectedSchool && props.selectedSchool.attributes ? `/schoolratingcertificate/${props.selectedSchool.id}` : "/schoolratingcertificate"}  className="btn btn-primary float-right">{props.t('Certificate')}</Link>
                            </div>}
                        </Col>
                    </Row> */}
                    <TopHeader
                        title="SCHOOL RATINGS"
                        rightButton={certificateButton()}
                        getRatingsButton={getSchoolRatings()}
                        isButtonHidden={(props.schoolRatings && props.schoolRatings.length === 0) ? true : false}
                    />

                    {
                        !props.loading && props.schoolRatings && props.schoolRatings.length === 0 && (
                            <Card className="mt-3"><CardBody><div className="text-center" >
                                <Row className="justify-content-center">
                                    <Col sm={4}>
                                        <div className="maintenance-img">
                                            <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                                        </div>
                                    </Col>
                                </Row>
                                <h5 className="mt-5">{'No reviews yet'}</h5>
                            </div></CardBody></Card>
                        )
                    }
                    {!props.loading ? (
                        ratingType === 2 ? (
                            <Row className="mt-2 ml-0 mr-0">
                                {props.selectedSchool != null && props.selectedSchool.id && (
                                    <Col className="mt-2 ml-0 mr-0">
                                        <SchoolRatings
                                            ratingParams={props.ratingParams}
                                            schoolRatings={props.schoolRatings}
                                        />
                                    </Col>
                                )}
                            </Row>
                        ) : (
                            <Row className="mt-2 ml-0 mr-0">
                                {props.selectedSchool != null && props.selectedSchool.id && (
                                    <Col className="mt-2 ml-0 mr-0">
                                        <SchoolRatings
                                            ratingParams={props.ratingParams}
                                            schoolRatings={props.schoolRatings}
                                        />
                                    </Col>
                                )}
                            </Row>
                        )
                    ) : (
                        <Row>
                            <Col xs="12">
                                <div className="text-center my-3">
                                    <Link to="#" className="text-success">
                                        <i className="bx bx-hourglass bx-spin mr-2"></i>{" "}
                                        {props.t("Loading")}{" "}
                                    </Link>
                                </div>
                            </Col>
                        </Row>
                    )}

                </Container>
            </div>
        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const { selectedSchool, paymentOptionsForSchool, ratingParams, schoolRatings } = state.School;
    return { selectedSchool, paymentOptionsForSchool, ratingParams, schoolRatings };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, editSchool, getPaymentOptionsForSchool, editPaymentOptions, getRatingParams, getSchoolRatings, clearSchoolRatings })(Index)
));