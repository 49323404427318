import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Container, Row, Card, CardBody, CardTitle, CardText, Col, Table, Media, Nav, NavItem, NavLink, TabContent, TabPane, Modal, ModalHeader, ModalBody, UncontrolledTooltip, Label } from "reactstrap";
import { getFranchise, setSelecetdEnquiry, getFranchiseEnquiryForFranchiseId, getEnquirynoteForEnquiryId, getEnquiryReminderforEnquiryId, saveFranchiseEnquiry, editEnquiryNote, editEnquiryReminder, deleteEnquiryNote, deleteEnquiryReminder, setSelectedFranchiseEnquiry } from '../../../store/actions';
import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Moment from "moment";
import classnames from "classnames";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SweetAlert from "react-bootstrap-sweetalert";
import Editable from "react-bootstrap-editable";




const FranchiseProfile = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/franchiseprofile/:franchiseId/:id",
        exact: true,
        strict: false,
    });

    const id = match.params.id;
    const franchiseId = match.params.franchiseId;

    const [note, setNote] = useState('');
    const [activeTab, setactiveTab] = useState("1");
    const [showNoteModel, setShowNoteModel] = useState(false);
    const [noteModelTitle, setNoteModelTitle] = useState(undefined);
    const [noteModelMessage, setNoteModelMessage] = useState(undefined);
    const [selectedNoteForEdit, setSelectedNoteForEdit] = useState(undefined);
    const [addedNotes, setAddedNotes] = useState([]);
    const [reminderTime, setReminderTime] = useState(undefined);
    const [reminderModelMessage, setReminderModelMessage] = useState(undefined);
    const [selectedReminderForEdit, setSelectedReminderForEdit] = useState(undefined);
    const [showReminderModel, setShowReminderModel] = useState(false);
    const [reminderMessageError, setReminderMessageError] = useState(false);
    const [reminderTimeError, setReminderTimeError] = useState(false);
    const [selectedNoteForDelete, setSelectedNoteForDelete] = useState(undefined);
    const [classModal, toggleClassModal] = useState(false);
    const [selectedReminderForDelete, setSelectedReminderForDelete] = useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [canEdit, setCanEdit] = useState(false);
    const [name, setName] = useState("");
    const [phoneNumber, setPhoneNumber] = useState("");
    const [city, setCity] = useState("");
    const [area, setArea] = useState("");
    const [email, setEmail] = useState("");
    const [timeLine, setTimeLine] = useState([]);
    const [expectedDateOfStart, setExpectedDateOfStart] = useState(undefined);
    const [selectedDate, setSelectedDate] = useState("");







    useEffect(() => {
        if (Parse.User && Parse.User.current() && Parse.User.current().attributes.franchiseId) {
            props.getFranchise(Parse.User.current().attributes.franchiseId);
        } else {
            props.history.push("/pages-404");
        }

        // props.getEnquiryReminderforEnquiryId(franchiseId)
    }, [franchiseId]);


    useEffect(() => {
        props.setSelecetdEnquiry(id)
        props.getEnquirynoteForEnquiryId(id)
        props.getEnquiryReminderforEnquiryId(id)
        props.setSelectedFranchiseEnquiry(id);

    }, [id]);

    const enquiry = props.franchiseEnquiry.filter(enq => enq.id === id) || {};

    const getEnquiryCreatedBy = (note) => {
        let createdBy = note.attributes.createdBy
        if (props.teachers && props.teachers.length > 0 && createdBy && createdBy.length > 0) {
            var temp = props.teachers.filter((teacher) => teacher.id === createdBy);
            if (temp && temp.length > 0) {
                return temp[0].attributes.Name
            }
        }

        return ''
    }

    useEffect(() => {
        var list = [];
        var list2 = [];
        for (const i in props.enquiryNotes) {

            if (props.enquiryNotes[i].attributes.systemLog) {
                var obj = { id: props.enquiryNotes[i].id, iconClass: "bx-server", noteMessage: props.enquiryNotes[i].attributes.noteMessage, noteTime: getTimeStringForDate(props.enquiryNotes[i].attributes.createdAt), createdBy: getEnquiryCreatedBy(props.enquiryNotes[i]) };
                list.push(obj);
            } else {
                list2.push(props.enquiryNotes[i])
            }

        }
        if (props.selectedFranchiseEnquiry) {
            var obj2 = { id: "addedDate", iconClass: "bx-server", noteMessage: "Enquiry added to system", noteTime: getTimeStringForDate(props.selectedFranchiseEnquiry.attributes.createdAt) };
            list.push(obj2);
            var obj1 = { id: "enquiryDate", iconClass: "bx-server", noteMessage: "Enquired On", noteTime: getTimeStringForDate(props.selectedFranchiseEnquiry.attributes.enquiryDate ? props.selectedFranchiseEnquiry.attributes.enquiryDate : props.selectedFranchiseEnquiry.attributes.createdAt) };
            list.push(obj1);
        }
        setTimeLine(list);
        setAddedNotes(list2);



    }, [props.enquiryNotes]);

    function toggleTab(tab) {
        if (activeTab !== tab) {
            setactiveTab(tab);
        }
    }

    const handleNameChange = (value) => {
        setName(value);
        let temp = props.selectedFranchiseEnquiry;
        temp.set('name', value)
        props.saveFranchiseEnquiry(temp);

    };

    const handleEmailChange = (value) => {
        setEmail(value);
        var temp = props.selectedFranchiseEnquiry;
        temp.set('email', value)
        props.saveFranchiseEnquiry(temp);

    };



    const handlePhoneNumberChange = (value) => {
        setPhoneNumber(value);
        var temp = props.selectedFranchiseEnquiry;
        temp.set('phoneNumber', value)
        props.saveFranchiseEnquiry(temp);

    };

    const handleCityChange = (value) => {
        setCity(value);
        var temp = props.selectedFranchiseEnquiry;
        temp.set('city', value)
        props.saveFranchiseEnquiry(temp);

    };

    const handleAreaChange = (value) => {
        setArea(value);
        let temp = props.selectedFranchiseEnquiry;
        temp.set('area', value)
        props.saveFranchiseEnquiry(temp);


    };
    
    useEffect(() => {
        if (props.selectedFranchiseEnquiry != null) {
            let enquiry = props.selectedFranchiseEnquiry;

            setExpectedDateOfStart(enquiry.attributes.expectedDateOfStart);
        } 
    }, [props.selectedFranchiseEnquiry]);
    

    const handleDateChange = (value) => {
        setExpectedDateOfStart(value);
        let temp = props.selectedFranchiseEnquiry;
        temp.set("expectedDateOfStart", value);
        props.saveFranchiseEnquiry(temp);
    };
    
    function ValidateEmail(value) {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (value.match(mailformat)) {
            return true;
        } else {
            return false;
        }
    }

    const validatePhoneNumber = (number) => {
        const phonePattern = /^\d{11,17}$/;
        if (!number) {
            return "Phone number is required";
        } else if (!phonePattern.test(number)) {
            return "You have entered an invalid phone number!";
        }
        return "";
    };

    function formatDate(d) {
        if (!d) {
            return "";
        } else {
            return Moment(d).format("DD/MM/YYYY");
        }
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY,  h:mm A');
    }

    console.log(props.enquiryNotes)
    console.log(props.selectedFranchiseEnquiry, "selectedFranchiseEnquiry")



    function handleNoteSubmit() {

        const EnquiryNote = Parse.Object.extend("EnquiryNotes");
        const noteObject = new EnquiryNote();

        if (selectedNoteForEdit) {
            noteObject = selectedNoteForEdit;
        }



        noteObject.set("franchiseId", franchiseId);
        noteObject.set("enquiryId", enquiry.id);

        noteObject.set("noteMessage", noteModelMessage);
        noteObject.set("title", noteModelTitle);

        props.editEnquiryNote(noteObject);

        setNoteModelMessage(undefined);
        setNoteModelTitle(undefined);
        setShowNoteModel(false);
        setSelectedNoteForEdit(undefined);
    }

    function handleReminderSubmit() {
        const EnquiryReminder = Parse.Object.extend("EnquiryReminder");
        let enquiryReminder = new EnquiryReminder();

        if (selectedReminderForEdit) {
            enquiryReminder = selectedReminderForEdit;
        }



        enquiryReminder.set("franchiseId", franchiseId);
        enquiryReminder.set("enquiryId", id);

        enquiryReminder.set("body", reminderModelMessage);
        enquiryReminder.set("title", "Task");
        enquiryReminder.set("reminderDateTime", reminderTime);

        props.editEnquiryReminder(enquiryReminder);

        setReminderModelMessage(undefined);
        setReminderTime(undefined);
        setReminderTimeError(false);
        setReminderMessageError(false);
        setShowReminderModel(false);
        setSelectedReminderForEdit(undefined);

    }


    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row className="d-flex align-items-start">
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className="list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className='m-2'>
                                <h4>Enquiry Details</h4>
                            </div>
                        </Col>
                    </Row>

                    <Row>
                        <Col lg={8}>
                            <Card>
                                <CardBody>
                                    {<Table className="table mb-0 table-bordered">
                                        <tbody>
                                            <tr>
                                                <td><b>{props.t('Name')}</b></td>
                                                <td>
                                                    <Editable
                                                        className={'d-flex'}
                                                        alwaysEditing={false}
                                                        onSubmit={handleNameChange}
                                                        disabled={false}
                                                        editText={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.name ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.name}
                                                        showText
                                                        type="textfield"
                                                    />
                                                </td>
                                            </tr>


                                            <tr>
                                                <td><b>{props.t('Email')}</b></td>
                                                <td>
                                                    <Editable
                                                        className={'d-flex'}
                                                        alwaysEditing={false}
                                                        onValidated={handleEmailChange}
                                                        disabled={false}
                                                        editText={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.email ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.email}
                                                        validate={(value) => {
                                                            if (!ValidateEmail(value)) {
                                                                return props.t(
                                                                    "You have entered an invalid email address!"
                                                                );
                                                            }
                                                        }}
                                                        showText
                                                        type="textfield"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>{props.t('Phone Number')}</b></td>
                                                <td>
                                                    <Editable
                                                        className={'d-flex'}
                                                        alwaysEditing={false}
                                                        onValidated={handlePhoneNumberChange}
                                                        disabled={false}
                                                        editText={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.phoneNumber ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.phoneNumber}
                                                        validate={(value) => {
                                                            if (!validatePhoneNumber(value)) {
                                                                return props.t(
                                                                    "You have entered an invalid Phone no."
                                                                );
                                                            }
                                                        }}
                                                        showText
                                                        type="textfield"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>{props.t('City')}</b></td>
                                                <td>
                                                    <Editable
                                                        className={'d-flex'}
                                                        alwaysEditing={false}
                                                        onSubmit={handleCityChange}
                                                        disabled={false}
                                                        editText={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.city ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.city}
                                                        showText
                                                        type="textfield"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>{props.t('Area')}</b></td>
                                                <td>
                                                    <Editable
                                                        className={'d-flex'}
                                                        alwaysEditing={false}
                                                        onSubmit={handleAreaChange}
                                                        disabled={false}
                                                        editText={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.area ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={props.selectedFranchiseEnquiry && props.selectedFranchiseEnquiry.attributes.area}
                                                        showText
                                                        type="textfield"
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td><b>{props.t('Expected date of Start')}</b></td>
                                                <td>
                                                    <Editable
                                                        className={'d-flex'}
                                                        alwaysEditing={false}
                                                        onSubmit={handleDateChange}
                                                        onCancel={() => {
                                                            if (props.selectedFranchiseEnquiry) {
                                                                setExpectedDateOfStart(enquiry.attributes.expectedDateOfStart);
                                                            }
                                                        }}
                                                        disabled={false}
                                                        editText={expectedDateOfStart ? " ✎" : "No value ✎"}
                                                        id={null}
                                                        isValueClickable={false}
                                                        mode="inline"
                                                        placement="top"
                                                        initialValue={expectedDateOfStart ? expectedDateOfStart : ""}
                                                        showText
                                                        type="date"
                                                    />
                                                </td>

                                            </tr>

                                        </tbody>
                                    </Table>}


                                </CardBody>
                            </Card>
                            <Card>
                                <CardBody>
                                    <Nav pills className="navtab-bg nav-justified">
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab === "1",
                                                })}
                                                onClick={() => {
                                                    toggleTab("1");
                                                }}
                                            >
                                                {props.t("Notes")}
                                            </NavLink>
                                        </NavItem>
                                        <NavItem>
                                            <NavLink
                                                style={{ cursor: "pointer" }}
                                                className={classnames({
                                                    active: activeTab === "2",
                                                })}
                                                onClick={() => {
                                                    toggleTab("2");
                                                }}
                                            >
                                                {props.t("Tasks")}
                                            </NavLink>
                                        </NavItem>
                                    </Nav>
                                    <TabContent activeTab={activeTab}>
                                        <TabPane tabId="1" className="p-3">

                                            <button
                                                className="btn btn-info"
                                                type="button"

                                                onClick={() => {
                                                    setShowNoteModel(true);
                                                    setSelectedNoteForEdit(undefined);
                                                }}
                                            >
                                                {props.t('Add New Note')}
                                            </button>

                                            {
                                                props.enquiryNotes && props.enquiryNotes.length > 0 ?
                                                    <div>
                                                        {
                                                            addedNotes.map((note) =>

                                                            (<Card key={note.id}>
                                                                <CardBody>
                                                                    <Row>
                                                                        <Col lg={10}>
                                                                            <CardTitle>{note.attributes.title ? note.attributes.title : props.t('Note')}</CardTitle>
                                                                        </Col>
                                                                        <Col lg={2}>

                                                                            <ul className="list-inline font-size-18 contact-links mb-0">
                                                                                <li className="list-inline-item px-2"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() => {
                                                                                        setShowNoteModel(true);
                                                                                        setSelectedNoteForEdit(note);
                                                                                        setNoteModelTitle(note.attributes.title);
                                                                                        setNoteModelMessage(note.attributes.noteMessage);
                                                                                    }}
                                                                                    id={"note-edit" + note.id}>
                                                                                    <i className="bx bx-edit-alt"></i>
                                                                                    <UncontrolledTooltip placement="top" target={"note-edit" + note.id}>
                                                                                        {props.t('Edit')}
                                                                                    </UncontrolledTooltip>

                                                                                </li>
                                                                                <li className="list-inline-item px-2"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() => {
                                                                                        setSelectedNoteForDelete(note);
                                                                                    }}
                                                                                    id={"note-delete" + note.id}>
                                                                                    <i className="bx bx-trash text-danger"></i>
                                                                                    <UncontrolledTooltip placement="top" target={"note-delete" + note.id}>
                                                                                        {props.t('Delete')}
                                                                                    </UncontrolledTooltip>

                                                                                </li>
                                                                            </ul>

                                                                        </Col>
                                                                    </Row>
                                                                    <CardText style={{ "white-space": "pre-wrap" }}>{note.attributes.noteMessage}</CardText>
                                                                    {/* <CardText><small className="text-muted">{note.noteTime}</small></CardText> */}
                                                                </CardBody>
                                                            </Card>)
                                                            )

                                                        }
                                                    </div> :
                                                    <h5></h5>
                                            }

                                        </TabPane>
                                        <TabPane tabId="2" className="p-3">
                                            <Col lg={12}>
                                                <div className='d-flex justify-content-end'>
                                                    <button
                                                        className="btn btn-info  float-right "
                                                        type="button"

                                                        onClick={() => {
                                                            // var tomorrow = new Date();
                                                            // tomorrow.setDate(tomorrow.getDate() + 1);
                                                            setReminderTime(undefined);
                                                            setShowReminderModel(true);
                                                            setReminderModelMessage(undefined);
                                                            setSelectedReminderForEdit(undefined);
                                                        }}
                                                    >
                                                        {props.t('Add New Task')}
                                                    </button>
                                                </div>
                                            </Col>
                                            <Col lg={12} className="mt-5">

                                                {
                                                    props.enquiryReminders && props.enquiryReminders.length > 0 ?
                                                        <div>
                                                            {
                                                                props.enquiryReminders.map(reminder =>

                                                                    <Card key={reminder.id}>
                                                                        <CardBody>
                                                                            <Row>
                                                                                <Col lg={10}>
                                                                                    <CardTitle>{reminder.attributes.title ? reminder.attributes.title : props.t('Task')}</CardTitle>
                                                                                </Col>
                                                                                <Col lg={2}>

                                                                                    <ul className="list-inline font-size-18 contact-links mb-0">
                                                                                        <li className="list-inline-item px-2"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={() => {
                                                                                                setShowReminderModel(true);
                                                                                                setSelectedReminderForEdit(reminder);
                                                                                                setReminderTime(reminder.attributes.reminderDateTime);
                                                                                                setReminderModelMessage(reminder.attributes.body);
                                                                                            }}
                                                                                            id={"task-edit" + reminder.id}>
                                                                                            <i className="bx bx-edit-alt"></i>
                                                                                            <UncontrolledTooltip placement="top" target={"task-edit" + reminder.id}>
                                                                                                {props.t('Edit')}
                                                                                            </UncontrolledTooltip>

                                                                                        </li>
                                                                                        <li className="list-inline-item px-2"
                                                                                            style={{ cursor: "pointer" }}
                                                                                            onClick={() => {
                                                                                                setSelectedReminderForDelete(reminder);
                                                                                            }}
                                                                                            id={"task-delete" + reminder.id}>
                                                                                            <i className="bx bx-trash text-danger"></i>
                                                                                            <UncontrolledTooltip placement="top" target={"task-delete" + reminder.id}>
                                                                                                {props.t('Delete')}
                                                                                            </UncontrolledTooltip>

                                                                                        </li>
                                                                                    </ul>

                                                                                </Col>
                                                                            </Row>

                                                                            <CardText style={{ "whiteSpace": "pre-wrap" }}>{reminder.attributes.body}</CardText>
                                                                            <CardText><small className="text-muted">{props.t('Task Time') + ":" + getTimeStringForDate(reminder.attributes.reminderDateTime)}</small></CardText>

                                                                        </CardBody>
                                                                    </Card>
                                                                )

                                                            }
                                                        </div> :
                                                        <h5></h5>}


                                            </Col>



                                        </TabPane>

                                    </TabContent>
                                </CardBody>
                            </Card>
                        </Col>
                        <Col lg={4}>
                            <Card>
                                <CardBody>
                                    <CardTitle className="mb-5">Enquiry Timeline</CardTitle>
                                    <div className="">
                                        <ul className="verti-timeline list-unstyled">
                                            {
                                                timeLine.map((experience, i) =>
                                                    <li key={experience.id} className={experience.id === 1 ? "event-list active" : "event-list"} >
                                                        <div className="event-timeline-dot">
                                                            <i className={experience.id === 1 ? "bx bx-right-arrow-circle bx-fade-right" : "bx bx-right-arrow-circle"}></i>
                                                        </div>
                                                        <Media>
                                                            <Row>
                                                                <Col lg={2}>
                                                                    <div className="mr-3">
                                                                        <i className={"bx " + experience.iconClass + " h4 text-primary"}></i>
                                                                    </div>
                                                                </Col>
                                                                <Col lg={10}>
                                                                    <div>
                                                                        <h5 className="font-size-15 text-dark ">{experience.noteMessage}</h5>
                                                                        {experience.createdBy && <span> {"By " + experience.createdBy}<br /></span>}
                                                                        <span className="text-primary">{experience.noteTime}</span>
                                                                    </div>
                                                                </Col>
                                                            </Row>
                                                        </Media>
                                                    </li>
                                                )
                                            }
                                        </ul>
                                    </div>

                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                </Container>

                {selectedNoteForDelete || selectedReminderForDelete ? (
                    <SweetAlert
                        title="Are you sure?"
                        warning
                        showCancel
                        confirmBtnBsStyle="success"
                        cancelBtnBsStyle="danger"
                        onConfirm={() => {
                            if (selectedNoteForDelete) {
                                props.deleteEnquiryNote(selectedNoteForDelete);
                                setSelectedNoteForDelete(undefined);
                                setsuccess_dlg("Note");
                            } else if (selectedReminderForDelete) {
                                props.deleteEnquiryReminder(selectedReminderForDelete);
                                setSelectedReminderForDelete(undefined);
                                setsuccess_dlg("Task");
                            }

                        }}
                        onCancel={() => {
                            setSelectedNoteForDelete(undefined);
                            setSelectedReminderForDelete(undefined);
                            setsuccess_dlg(undefined);

                        }}
                    >
                        {props.t("You won't be able to revert this!")}
                    </SweetAlert>
                ) : null}
                {success_dlg ? (
                    <SweetAlert
                        success
                        title={props.t('Deleted')}
                        onConfirm={() => {
                            setsuccess_dlg(undefined)
                        }}
                    >
                        {"Your " + success_dlg + " has been deleted."}
                    </SweetAlert>
                ) : null}

                {/* Add Note */}
                <Modal
                    isOpen={showNoteModel}
                    toggle={() => {
                        setShowNoteModel(false);
                        setNoteModelTitle(undefined);
                        setNoteModelMessage(undefined);
                        setSelectedNoteForEdit(undefined);
                    }} className="">
                    <ModalHeader toggle={() => {
                        setShowNoteModel(false);
                        setNoteModelMessage(undefined);
                        setNoteModelTitle(undefined);
                        setSelectedNoteForEdit(undefined);
                    }} tag="h4">
                        Add Note
                    </ModalHeader>
                    <ModalBody>
                        <AvForm onValidSubmit={handleNoteSubmit}>
                            <Row form>

                                <Col className="col-12">

                                    <AvField name="title"
                                        label={props.t('Title')}
                                        type="text"
                                        placeholder={props.t('Title')}
                                        value={noteModelTitle}
                                        onChange={(e) => {
                                            setNoteModelTitle(e.target.value);
                                        }}

                                    />

                                </Col>
                                <Col className="col-12">
                                    <AvField
                                        name="note"
                                        label={props.t('Note')}
                                        type="textarea"
                                        className="form-control"
                                        rows="3"
                                        placeholder={props.t('your note...')}
                                        errorMessage={props.t('Note can not be empty')}
                                        validate={{
                                            required: { value: true }
                                        }}
                                        value={noteModelMessage}
                                        onChange={(e) => {
                                            setNoteModelMessage(e.target.value);
                                        }}
                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <div className="d-flex justify-content-end">
                                        <button type="button" className="btn btn-light m-1" onClick={() => {
                                            setShowNoteModel(false);
                                            setNoteModelMessage(undefined);
                                            setNoteModelTitle(undefined);
                                            setSelectedNoteForEdit(undefined);
                                        }}>{props.t('Close')}</button>
                                        <button type="submit" className="btn btn-success save-event m-1">{props.t('Save')}</button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>
                {/* RemainderModal */}
                <Modal
                    isOpen={showReminderModel}

                >
                    <ModalHeader toggle={() => {
                        setShowReminderModel(false);
                        setNoteModelMessage(undefined);
                        setReminderModelMessage(undefined);
                        setReminderTime(undefined);
                        setReminderTimeError(false);
                        setReminderMessageError(false);
                        setSelectedReminderForEdit(undefined);
                    }} tag="h4">
                        {props.t('Add Task')}
                    </ModalHeader>
                    <ModalBody>
                        <AvForm >
                            <Row form>


                                <Col className="col-12">
                                    <AvField
                                        name="note"
                                        label={props.t('Task')}
                                        type="textarea"
                                        className="form-control"
                                        rows="3"
                                        placeholder={props.t('your task...')}

                                        value={reminderModelMessage}
                                        onChange={(e) => {
                                            if (e.target.value.length > 0 && reminderMessageError) {
                                                setReminderMessageError(false);
                                            }
                                            setReminderModelMessage(e.target.value);
                                        }}
                                    />
                                </Col>

                                {reminderMessageError && <p className="text-danger">{props.t('Task can not be empty')}</p>}

                                <Col className="col-12">

                                    <Label>{props.t('Task Time')}</Label>
                                    <DatePicker
                                        selected={reminderTime}
                                        onChange={(d) => {
                                            if (reminderTimeError) {
                                                setReminderTimeError(false);
                                            }
                                            setReminderTime(d)
                                        }}
                                        className="form-control"
                                        dateFormat="dd/MM/yyyy hh:mm a"
                                        showTimeSelect
                                        timeIntervals={10}
                                        timeCaption="Time"
                                        validate={{
                                            required: { reminderTime: true }
                                        }}
                                    />
                                    {reminderTimeError && <p className="text-danger">{props.t('Please input time')}</p>}
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <div className="d-flex justify-content-end mt-2">
                                        <button type="button" className="btn btn-light m-1 " onClick={() => {
                                            setShowReminderModel(false);
                                            setReminderModelMessage(undefined);
                                            setReminderTime(undefined);
                                            setReminderTimeError(false);
                                            setReminderMessageError(false);
                                            setSelectedReminderForEdit(undefined);
                                        }}>{props.t('Close')}</button>
                                        <button onClick={() => {
                                            if (!reminderModelMessage || reminderModelMessage.length === 0) {
                                                setReminderMessageError(true);
                                                if (!reminderTime) {
                                                    setReminderTimeError(true);
                                                }
                                                return;
                                            }
                                            if (!reminderTime) {
                                                setReminderTimeError(true);
                                                return;
                                            }
                                            handleReminderSubmit();

                                        }}
                                            className="btn btn-success save-event m-1">{props.t('Save')}</button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>

            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = state => {

    const { franchise } = state.Franchise;
    const { franchiseEnquiry, selectedFranchiseEnquiry, } = state.Enquiry;
    const { enquiryNotes } = state.EnquiryNotes;
    const { enquiryReminders } = state.EnquiryReminder;

    return { franchise, franchiseEnquiry, enquiryNotes, enquiryReminders, selectedFranchiseEnquiry, };
};

export default withNamespaces()(withRouter(connect(mapStatetoProps, { getFranchise, getEnquirynoteForEnquiryId, setSelecetdEnquiry, getFranchiseEnquiryForFranchiseId, saveFranchiseEnquiry, editEnquiryNote, getEnquiryReminderforEnquiryId, editEnquiryReminder, deleteEnquiryNote, deleteEnquiryReminder, setSelectedFranchiseEnquiry, })(FranchiseProfile)));
