import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import { setSelecetdSchool } from "../../../store/actions";
import {
  Col,
  Nav,
  NavItem,
  Row,
  NavLink,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";


import AssessmentReport from "./AssessmentReport";
import AnalyticsReport from './AnalyticsReport';


const ReportList = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/course-reports/:schoolId",
        exact: true,
        strict: false,
    });
    const schoolId = match.params.schoolId

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const [activeTab, setactiveTab] = useState("1");
    return (
        <React.Fragment>
            <div className="page-content">
                <Row>
                    <Col lg={8} md={8} xs={12}>
                        <h4>{props.t("Reports")}</h4>
                    </Col>
                </Row>
                <Row className="checkout-tabs">
                    <Col lg="2">
                        <Nav className="flex-column" pills>
              
                            <NavItem>
                                <NavLink
                                    className={classnames({ active: activeTab === "1" })}
                                    onClick={() => {
                                        setactiveTab("1");
                                    }}
                                >
                                    <i className="bx bx-clipboard d-block check-nav-icon mt-4 mb-2"></i>
                                    <p className="font-weight-bold mb-4">{props.t("ASSESSMENT")}</p>
                                </NavLink>
                            </NavItem>
                            <NavItem>
                                    <NavLink
                                    className={classnames({ active: activeTab === "2" })}
                                    onClick={() => {
                                        setactiveTab("2");
                                    }}
                                    >
                                    <i className="bx bx-pulse d-block check-nav-icon mt-4 mb-2"></i>
                                    <p className="font-weight-bold mb-4">{props.t("ACTIVITY")}</p>
                                    </NavLink>
                                </NavItem>

                        </Nav>
                    </Col>
                    <Col lg="10">
                        <Card>
                            <CardBody>
                                <TabContent activeTab={activeTab}>

                                    <TabPane
                                        tabId="1"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                                      
                                        <AssessmentReport schoolId={schoolId} />

                                    </TabPane>
                                    <TabPane
                                        tabId="2"
                                        id="v-pills-payment"
                                        role="tabpanel"
                                        aria-labelledby="v-pills-payment-tab"
                                    >
                    
                                        <AnalyticsReport schoolId={schoolId} />
                                    </TabPane>

                                </TabContent>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </React.Fragment>
    );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  return { selectedSchool };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
    })(ReportList)
  )
);
