export const UPDATE_CAMERA = "UPDATE_CAMERA";

export const UPDATE_CAMERAS = "UPDATE_CAMERAS";
export const GET_CAMERAS_FOR_SCHOOL_ID = "GET_CAMERAS_FOR_SCHOOL_ID";
export const SAVE_CAMERA = "SAVE_CAMERA";
export const GET_CAMERA_FOR_ID = "GET_CAMERA_FOR_ID";
export const SINGLE_CAMERA_UPDATE = "SINGLE_CAMERA_UPDATE";
export const GET_CAMERA_LIVESTREAMS_FOR_SCHOOL_ID = "GET_CAMERA_LIVESTREAMS_FOR_SCHOOL_ID";
export const UPDATE_CAMERA_LIVESTREAMS_FOR_SCHOOL_ID = "UPDATE_CAMERA_LIVESTREAMS_FOR_SCHOOL_ID";

export const RESULT_CAMERA_RESET = "RESULT_CAMERA_RESET";
export const RESULT_CAMERA_AFTER_SAVE = "RESULT_CAMERA_AFTER_SAVE";

export const SAVE_CAMERA_ACCESS_OBJECTS = "SAVE_CAMERA_ACCESS_OBJECTS";
export const RESULT_CAMERA_ACCESS_AFTER_SAVE =
  "RESULT_CAMERA_ACCESS_AFTER_SAVE";

export const GET_CAMERA_ACCESS_OBJECTS_FOR_CAMERA_ID =
  "GET_CAMERA_ACCESS_OBJECTS_FOR_CAMERA_ID";
export const UPDATE_CAMERA_ACCESS_OBJECTS = "UPDATE_CAMERA_ACCESS_OBJECTS";

export const CLEAR_PROPS_CAMERA_AND_ACCESS = "CLEAR_PROPS_CAMERA_AND_ACCESS";

export const DELETE_CAMERA = "DELETE_CAMERA";
