import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Row, Col, Table, Alert, Label,Modal,ModalBody, ModalFooter,FormGroup,Form,Nav,NavItem ,NavLink,TabContent ,UncontrolledTooltip,TabPane,Input} from "reactstrap";
import Image from "../../../assets/images/book_lover.svg";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import { Link } from "react-router-dom";
import Moment from 'moment';
import classnames from 'classnames';
import { AvForm, AvField, } from "availity-reactstrap-validation";
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Editable from "react-bootstrap-editable";
import toastr from "toastr"
import {
    setSelecetdSchool,
    setSelectedCourse,
    getCourseBatch,
    addCourseBatch,
    getLearningCategoryForCourseId,
    getQuizLearningActivitiesForCourseId,
    getLiveMeetingLearningActivitiesForCourseId,
    getQABMapForBatchId,
    addQABMap,
    getLiveMeetingForBatchId,
    addLiveMeeting,
} from "../../../store/actions";

import TopBar from '../TopBar';
import * as CommonFunctions from "../../../components/Common/CommonFunctions";
import { get } from 'jquery';


const BatchDetails = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/course-batch-details/:schoolId/:courseId/:batchId',
        exact: true,
        strict: false,

    });

    const schoolId = match ? match.params.schoolId : "";
    const courseId = match ? match.params.courseId : "";
    const batchId = match ? match.params.batchId : "";

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.setSelectedCourse(courseId);
        props.getLearningCategoryForCourseId(courseId);
        props.getQuizLearningActivitiesForCourseId(courseId);
        props.getLiveMeetingLearningActivitiesForCourseId(courseId);
    }, [courseId]);

    useEffect(() => {
        props.getCourseBatch(batchId);
        props.getQABMapForBatchId(courseId, batchId);
        props.getLiveMeetingForBatchId(courseId, batchId);
    }, [batchId]);

    
    const [customActiveTab, setcustomActiveTab] = useState("1");
    function toggleCustom(tab) {
        if (customActiveTab !== tab) {
            setcustomActiveTab(tab);
        }
    }
   
                                                                
    const [showModel, setShowModel] = useState(false);
    const [editCourseBatchObject, setEditCourseBatchObject] = useState(undefined);

    const [fromDate, setFromDate] = useState(undefined);
    const [toDate, setToDate] = useState(undefined);
    
    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    const [quizActivities, setQuizActivities] = useState([]);

    

    const [liveMeetingActivities, setLiveMeetingActivities] = useState([])
    const [editLiveMeetingObject, setEditLiveMeetingObject] = useState(undefined);
    const [showAddMeetingPopup, setShowAddMeetingPopup] = useState(false);

    const [showDeleteMeetingPopup, setShowDeleteMeetingPopup] = useState(false);
    const [deleteZoomMeetingLoading, setDeleteZoomMeetingLoading] = useState(false)
    const [deleteLiveMeetingObject, setDeleteLiveMeetingObject] = useState(undefined);

    const [meetingTime, setMeetingTime] = useState(undefined);
    const [meetingMedium, setMeetingMedium] = useState("Zoom");
    const [meetingId, setMeetingId] = useState(undefined);
    const [meetingPassword, setMeetingPassword] = useState(undefined);
    const [meetingOtherInfo, setMeetingOtherInfo] = useState(undefined);

    const [meetingTimeError, setMeetingTimeError] = useState(false);
    const [meetingMediumError, setMeetingMediumError] = useState(undefined);
    const [meetingIdError, setMeetingIdError] = useState(undefined);
    const [meetingPasswordError, setMeetingPasswordError] = useState(undefined);
    const [meetingOtherInfoError, setMeetingOtherInfoError] = useState(undefined);

    const [meetingTitle, setMeetingTitle] = useState(undefined);
    const [meetingTitleError, setMeetingTitleError] = useState(undefined);
    const [meetingUrl, setMeetingUrl] = useState(undefined);

    const [scheduleZoomMeetingLoading, setScheduleZoomMeetingLoading] = useState(false)
    const [scheduleZoomMeetingObject, setScheduleZoomMeetingObject] = useState(undefined);

    const [zoomAccessToken, setZoomAccessToken] = useState(undefined);
    const [zoomAccessTokenLoading, setZoomAccessTokenLoading] = useState(0);


    useEffect(() => {
        if (props.quizLearningActivities && props.quizLearningActivities.length > 0 &&
            props.learningCategories && props.learningCategories.length > 0) {

            let list = []
            for (const cat of props.learningCategories) {
                for (const act of props.quizLearningActivities) {
                    if (act.attributes.category === cat.id) {
                        let qabMap = undefined;
                        for (const qab of props.qabMaps) {
                            if (act.id === qab.attributes.activityId && batchId === qab.attributes.batchId) {
                                qabMap = qab;
                            }
                        }
                        
                        list.push({ id: act.id, category: cat, activity: act, qabMap: qabMap });
                        
                    }
                }
            }
            setQuizActivities(list);
        } else {
            setQuizActivities([])
        }
       
    }, [props.quizLearningActivities, props.learningCategories, props.qabMaps]);
    

    useEffect(() => {
        if (props.liveMeetingLearningActivities && props.liveMeetingLearningActivities.length > 0 &&
            props.learningCategories && props.learningCategories.length > 0) {

            let list = []
            for (const cat of props.learningCategories) {
                for (const act of props.liveMeetingLearningActivities) {
                    if (act.attributes.category === cat.id) {
                        let liveMeeting = undefined;
                        
                        for (const lm of props.liveMeetings) {
                            if (act.id === lm.attributes.activityId && batchId === lm.attributes.batchId) {
                                liveMeeting = lm;
                            }
                        }
                        list.push({ id: act.id, category: cat, activity: act, liveMeeting: liveMeeting });
                        
                    }
                }
            }
            setLiveMeetingActivities(list);
        } else {
            setLiveMeetingActivities([])
        }
       
    }, [props.liveMeetingLearningActivities, props.learningCategories, props.liveMeetings]);


    const handelAddLiveMeeting = (zoomMeeting ,isUpdate) => {
         let LiveMeeting = Parse.Object.extend("LiveMeeting");
        let lm = new LiveMeeting();
        if (editLiveMeetingObject.liveMeeting) {
            lm = editLiveMeetingObject.liveMeeting;
        } else {
            lm.set("activityId", editLiveMeetingObject.activity.id);
            lm.set("batchId", batchId);
            lm.set("courseId", courseId);
        }
        lm.set("meetingDate", meetingTime);
        lm.set("medium", meetingMedium);
        lm.set("meetingId", meetingId+"");
        lm.set("password", meetingPassword);
        lm.set("otherInfo", meetingOtherInfo);

        let zmo = zoomMeeting.attributes.zoomMeetingObject
        
        lm.set("zoomMeetingLogId", zoomMeeting.id);
        if (zmo) {
            lm.set("zoomMeetingObject", zmo);
            lm.set("zoomStartURL", zmo['start_url']);
            lm.set("zoomJoinURL", zmo['join_url']);
            lm.set("meetingId",zmo['id']?zmo['id']+"": meetingId+"");
            lm.set("password", zmo['password'] ?? meetingPassword);
        }
        lm.set("zoomUserObject", zoomAccessToken.attributes.userInfo);
        lm.set("title", meetingTitle);

        let temp = [...liveMeetingActivities];
        let index = liveMeetingActivities.lastIndexOf(editLiveMeetingObject);

        let lmo=editLiveMeetingObject
        lmo.liveMeeting = lm;
        temp[index] = lmo;
        setLiveMeetingActivities(temp);
        let remainderObject= createReminderOnUpdate(liveMeetingActivities?liveMeetingActivities.attributes.reminderId:null);
        props.addLiveMeeting(lm, true,remainderObject);
        setEditLiveMeetingObject(undefined);
        setShowAddMeetingPopup(false)
        
        if (isUpdate) {
            toastr.success("Live session updated successfully", { timeOut: 1500 });
        } else {
            toastr.success("Live session added successfully", { timeOut: 1500 });
        }
    }

    const createReminderOnUpdate=(id)=>{

      
        let courseName=props.selectedCourse ?  props.selectedCourse.attributes.name : ""
        let batch=props.selectedCourseBatch ?  getTimeStringForDate(props.selectedCourseBatch.attributes.startDate) + " to " + getTimeStringForDate(props.selectedCourseBatch.attributes.endDate) : ""
        let title="";
        let message=""
        let finalMessage= "Course Name: "+courseName+
        '\n'+" Batch Name: "+batch
        '\n'+message;

        const ReminderParse = Parse.Object.extend("Reminder");
        let ro = new ReminderParse();
        if(id){
            ro.id=id;
        }
        ro.set("message", finalMessage);
        ro.set("type", 5);
        ro.set("status", true);
        ro.set("title", title ?? "");
        ro.set("defaultSchoolId", schoolId ?? "");
        ro.set("schoolId", schoolId ?? "");
        ro.set(
            "senderId",
            Parse.User.current().attributes.teacherId ?? ""
        );
        ro.set("reminderTime", sessionDate);
        ro.set("eventType", 1);
        
        return ro;

    }
    


    const handelAddQabMap = (date, editQabMapObject) => {


        let QuizActivityBatchMap = Parse.Object.extend("QuizActivityBatchMap");
        let qabMap = new QuizActivityBatchMap();
        
        if (editQabMapObject.qabMap) {
            qabMap = editQabMapObject.qabMap;
        } else {
            qabMap.set("activityId", editQabMapObject.activity.id);
            qabMap.set("batchId", batchId);
            qabMap.set("courseId", courseId);
        }
        qabMap.set("submissionDate", date);
        let temp = [...quizActivities];
        let index = quizActivities.lastIndexOf(editQabMapObject);
        editQabMapObject.qabMa = qabMap;
        temp[index] = editQabMapObject;
        setQuizActivities(temp);
        let sendNotofication = false;
        if (props.selectedCourseBatch.attributes.startDate < new Date() &&
            props.selectedCourseBatch.attributes.endDate > new Date()) {
            sendNotofication = true;
        }
        props.addQABMap(qabMap, schoolId,sendNotofication);
        setEditLiveMeetingObject(undefined)
    }

    useEffect(() => {
        if (!props.addCourseBatchLoading) {
            setEditCourseBatchObject(undefined);
            setFromDateError(false);
            setFromDate(undefined);
            setToDateError(false);
            setToDate(undefined);
        }
    }, [props.addCourseBatchLoading]);


    const handelAddCouseBatch = () => {

        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate(), 0, 0, 0);
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59);
        
        console.log(startDate);
        console.log(endDate);

        let CourseBatch = Parse.Object.extend("CourseBatch");
        let courseBatch = new CourseBatch();
        if (editCourseBatchObject) {
            courseBatch = editCourseBatchObject;
        }
        courseBatch.set("startDate", startDate);
        courseBatch.set("endDate", endDate);
        courseBatch.set("courseId", courseId);
        
        props.addCourseBatch(courseBatch, true);
        setShowModel(false);
        
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }
    function getDateTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }
    const handelOnClickBack = () => {
        window.history.back();
    }

    const getZoomTocken = () => {
        let zoom_auth_code = localStorage.getItem("ZOOM_AUTH_CODE") ??"hNYgEKkp5c_pvvDAttcQ5KSW6DLRWYVWA"
        if (zoom_auth_code) {
            
            if (zoomAccessToken) {
                setZoomAccessTokenLoading(2)
            } else {
                setZoomAccessTokenLoading(1)
                

                Parse.Cloud.run("function_ZoomAccessToken", {
                    schoolId: schoolId,
                    authCode:zoom_auth_code,
                }).then((response) => {
                    console.log(response)
                    setZoomAccessToken(response);
                    setZoomAccessTokenLoading(2);
                }, (error) => {
                    console.log("error" + error.message)
                    setZoomAccessToken(undefined);
                    localStorage.removeItem("ZOOM_AUTH_CODE");
                    setZoomAccessTokenLoading(0);
                });



            }
        } else {
            setZoomAccessTokenLoading(0)
            //show link zoom 
        }
    }

    const handelScheduleZoomMeeting = (meetingId) => {

        setScheduleZoomMeetingLoading(true);
        setScheduleZoomMeetingObject(undefined);
        let zoom_auth_code = localStorage.getItem("ZOOM_AUTH_CODE") ??"hNYgEKkp5c_pvvDAttcQ5KSW6DLRWYVWA"

        //2022-04-25T07:32:55Z
        let time = Moment(meetingTime).format('YYYY-MM-DD') + "T" + Moment(meetingTime).format('HH:mm:ss') + ""
        // console.log("time: ", time);

        if (meetingId) {
            Parse.Cloud.run("function_UpdateZoomMeeting", {
                schoolId: schoolId,
                authCode: zoom_auth_code,
                time: time,
                title: meetingTitle,
                meetingId:meetingId,
            }).then((response) => {
                console.log(response)
                setScheduleZoomMeetingLoading(false);
                setScheduleZoomMeetingObject(response.zoomMeeting);
                setZoomAccessToken(response.zoomAuthentication);

                let meetingObject = response.zoomMeeting.attributes.zoomMeetingObject
                setMeetingId(meetingObject["id"]);
                setMeetingPassword(meetingObject["password"]);
                setMeetingUrl(meetingObject['join_url']);
            
                handelAddLiveMeeting(response.zoomMeeting,true);
            }, (error) => {
                console.log("error" + error.message)
            });
        } else {
            Parse.Cloud.run("function_ScheduleZoomMeeting", {
                schoolId: schoolId,
                authCode: zoom_auth_code,
                time: time,
                title: meetingTitle,
            }).then((response) => {
                console.log(response)
                setScheduleZoomMeetingLoading(false);
                setScheduleZoomMeetingObject(response.zoomMeeting);
                setZoomAccessToken(response.zoomAuthentication);

                let meetingObject = response.zoomMeeting.attributes.zoomMeetingObject
                setMeetingId(meetingObject["id"]);
                setMeetingPassword(meetingObject["password"]);
                setMeetingUrl(meetingObject['join_url']);
            
                handelAddLiveMeeting(response.zoomMeeting);
            }, (error) => {
                console.log("error" + error.message)
            });
        }
        

    }


    const handelDeleteZoomMeeting = (meetingObject) => {

        setDeleteZoomMeetingLoading(true);
        
        let zoom_auth_code = localStorage.getItem("ZOOM_AUTH_CODE") ??"hNYgEKkp5c_pvvDAttcQ5KSW6DLRWYVWA"

       
        Parse.Cloud.run("function_DeleteZoomMeeting", {
            schoolId: schoolId,
            authCode: zoom_auth_code,
            meetingId:meetingObject.attributes.meetingId
        }).then((response) => {
            console.log(response)
            meetingObject.set("isDeleted",true)
            props.addLiveMeeting(meetingObject, false);

            setDeleteZoomMeetingLoading(false);
            setDeleteLiveMeetingObject(undefined);
            setShowDeleteMeetingPopup(false);
            toastr.success("Live session deleted successfully", { timeOut: 1500 });
        }, (error) => {
            console.log("error" + error.message)
        });

    }

   
    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <TopBar title={"COURSE BATCHES DETAILS"}
                        buttonName={"Edit Batch"}
                        backButton={true}
                        hideButton={false}
                        onClickAdd={() => {
                            setEditCourseBatchObject(props.selectedCourseBatch);
                            setFromDate(props.selectedCourseBatch.attributes.startDate);
                            setToDate(props.selectedCourseBatch.attributes.endDate);
                            setFromDateError(false);
                            setToDateError(false);
                            setShowModel(true);
                        }}
                        reorderButton={false}
                        onClickBackButton={handelOnClickBack}
                    />
                    <Card>
                        <CardBody>
                            <h5 className="text-truncate font-size-14  mb-1 text-dark">{props.selectedCourse ? "Course Name: " + props.selectedCourse.attributes.name : "Course Name: "}</h5>
                            <h5 className="text-truncate font-size-14  mb-1 text-dark">{props.selectedCourseBatch ? "Batch: " + getTimeStringForDate(props.selectedCourseBatch.attributes.startDate) + " to " + getTimeStringForDate(props.selectedCourseBatch.attributes.endDate) : "Batch: "}</h5>
                        </CardBody>
                    </Card>

                    <Card className="mb-0">
                        <CardBody>
                            <Nav pills className="navtab-bg nav-justified ">
                                <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "1",
                                        })}
                                        onClick={() => {
                                            toggleCustom("1");
                                        }}
                                    >
                                        {props.t('ASSESSMENTS').toUpperCase()}
                                    </NavLink>
                                </NavItem>
                                
                                   
                                    
                                {/* <NavItem>
                                    <NavLink
                                        style={{ cursor: "pointer" }}
                                        className={classnames({
                                            active: customActiveTab === "2",
                                        })}
                                        onClick={() => {
                                            toggleCustom("2");
                                        }}
                                    >
                                        {props.t('LIVE SESSIONS').toUpperCase()}
                                    </NavLink>
                                        
                                </NavItem> */}
                                       
                                   
                            </Nav>

                        
                        </CardBody>
                    </Card>
                        
                    <TabContent activeTab={customActiveTab} className="mt-1">
                        <TabPane tabId="1" >
                            
                            <Row >
                                <Col lg="12">
                                    <div className="">
                                        <div className="table-responsive">
                                            {quizActivities.length > 0 && <Table className="project-list-table table-nowrap table-centered table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th className="col-1" scope="col" align="center">#</th>
                                                        <th className="col-8" scope="col">Name</th>
                                                        <th className="col-3" scope="col" align="right">Last submission date</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {quizActivities.map((item, key) =>
                                                        <tr key={item.id} >
                                                            <td>
                                                                <h5>{(key + 1)}</h5>
                                                            </td>

                                                            <td
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {

                                                                }}>
                                                                <h5 className="text-truncate font-size-14  mb-1 text-dark">{item.activity.attributes.name}</h5>
                                                                <p className="text-muted mb-0">{"Chapter: " + item.category.attributes.name}</p>
                                                            </td>

                                                            <td>
                                                                <Editable
                                                                    alwaysEditing={false}
                                                                    onSubmit={(d) => handelAddQabMap(d, item)}
                                                                    onCancel={() => {
                                                                            
                                                                    }}
                                                                    // disabled={canEdit ? false : true}
                                                                    editText={item.qabMap ? " ✎" : "Set Date ✎"}
                                                                    id={null}
                                                                    isValueClickable={false}
                                                                    mode="inline"
                                                                    placement="top"
                                                                    initialValue={item.qabMap ? item.qabMap.attributes.submissionDate : ""}
                                                                    showText
                                                                    type="date"
                                                                />
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>}

                                            {
                                                quizActivities.length === 0 &&
                                                <Card>
                                                    <CardBody className="text-center" >

                                                        <h3 className="mt-5">{

                                                            props.selectedCourse ? props.t('There are no assessments for ') + props.selectedCourse.attributes.name : props.t('There are no assessments')

                                                        }</h3>
                                              
                                                    </CardBody>
                                                </Card>
                                            }

                                            {
                                                props.initialLoding && <Col xs="12">
                                                    <div className="text-center my-3">
                                                        <Label className="text-success">
                                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                                                        </Label>
                                                    </div>

                                                </Col>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            
                        </TabPane>
                    
                        <TabPane tabId="2" >

                            <Row >
                                <Col lg="12">
                                    <div className="">
                                        <div className="table-responsive">
                                            {liveMeetingActivities.length > 0 && <Table className="project-list-table table-nowrap table-centered table-borderless">
                                                <thead>
                                                    <tr>
                                                        <th className="col-1" scope="col" align="center">#</th>
                                                        <th className="col-4" scope="col">Name</th>
                                                        <th className="col-4" scope="col">Live Session Details</th>
                                                        <th className="col-2" scope="col" align="right">Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {liveMeetingActivities.map((item, key) =>
                                                        <tr key={item.id} >
                                                            <td>
                                                                <h5>{(key + 1)}</h5>
                                                            </td>

                                                            <td
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {

                                                                }}>
                                                                <h5 className="text-truncate font-size-14  mb-1 text-dark">{item.activity.attributes.name}</h5>
                                                                <p className="text-muted mb-0">{"Chapter: " + item.category.attributes.name}</p>
                                                            </td>

                                                            {item.liveMeeting ? <td>
                                                                <p className="text-muted mb-0">{"Time: " + getDateTimeStringForDate(item.liveMeeting.attributes.meetingDate)}</p>
                                                                <p className="text-muted mb-0">{"Medium: " + item.liveMeeting.attributes.medium}</p>
                                                                {item.liveMeeting.attributes.meetingId && item.liveMeeting.attributes.meetingId && <>
                                                                    <p className="text-muted mb-0">{"Meeting Id: " + item.liveMeeting.attributes.meetingId}</p>
                                                                    <p className="text-muted mb-0">{"Password: " + item.liveMeeting.attributes.password}</p>
                                                                </>
                                                                }
                                                                {item.liveMeeting.attributes.zoomStartURL && <>
                                                                    <p className="text-muted mb-0">{"Link: "}
                                                                        <a
                                                                            href={item.liveMeeting.attributes.zoomStartURL}
                                                                            target="_blank"
                                                                        >
                                                                            <u>open</u>
                                                                        </a>
                                                                    </p>
                                                                </>}

                                                            </td> : <td>
                                                            </td>}

                                                            <td>
                                                                <ul className="list-inline font-size-20 contact-links mb-0 float-right">
                                                                    <li className='list-inline-item px-2'>
                                                                        <button
                                                                            className='btn btn-sm btn-primary'
                                                                            onClick={() => {
                                                                                setMeetingMedium("zoom");
                                                                                setScheduleZoomMeetingObject(undefined);
                                                                                setScheduleZoomMeetingLoading(false);
                                                                                if (item.liveMeeting) {
                                                                            
                                                                                    setMeetingTime(item.liveMeeting.attributes.meetingDate)
                                                                                    setMeetingId(item.liveMeeting.attributes.meetingId)
                                                                                    setMeetingPassword(item.liveMeeting.attributes.password)
                                                                                    setMeetingOtherInfo(item.liveMeeting.attributes.otherInfo)
                                                                                    setMeetingTitle(item.liveMeeting.attributes.title)
                                                                                } else {
                                                                                    setMeetingTime(undefined)
                                                                                    setMeetingId(undefined)
                                                                                    setMeetingPassword(undefined)
                                                                                    setMeetingOtherInfo(undefined)
                                                                                    setMeetingTitle(undefined)
                                                                                }
                                                                                setEditLiveMeetingObject(item);
                                                                                setShowAddMeetingPopup(true);
                                                                                getZoomTocken()

                                                                       
                                                                            }}
                                                                        >
                                                                            {
                                                                                item.liveMeeting ? "Edit Live Sessions " : "Add Live Sessions"
                                                                            }
                                                                        </button>
                                                                    </li>
                                                                    {<li className="list-inline-item px-2 "
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setDeleteLiveMeetingObject(item);
                                                                            setShowDeleteMeetingPopup(true);
                                                                            getZoomTocken()
                                                                        }}
                                                                        id={"sub-delete" + key}>
                                                                        <i className="bx bx-trash text-danger"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                            {props.t('Delete')}
                                                                        </UncontrolledTooltip>

                                                                    </li>

                                                                    }
                                                                   
                                                                </ul>
                                                            </td>
                                                        </tr>
                                                    )}
                                                </tbody>
                                            </Table>}

                                            {
                                                liveMeetingActivities.length === 0 &&
                                                <Card>
                                                    <CardBody className="text-center" >

                                                        <h3 className="mt-5">{

                                                            props.selectedCourse ? props.t('There are no live sessions for ') + props.selectedCourse.attributes.name : props.t('There are no live sessions')

                                                        }</h3>
                                              
                                                    </CardBody>
                                                </Card>
                                            }

                                            {
                                                props.initialLoding && <Col xs="12">
                                                    <div className="text-center my-3">
                                                        <Label className="text-success">
                                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                                                        </Label>
                                                    </div>

                                                </Col>
                                            }
                                        </div>
                                    </div>
                                </Col>
                            </Row>
                        
                        
                        </TabPane>
                    </TabContent>
                   
                       
                    
                    

                    {/* {ADD BATCH POPUP} */}

                   
                    <Modal
                        isOpen={showModel || props.addCourseBatchLoading}
                        toggle={() => { setShowModel(!showModel) }}
                    >
                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myLargeModalLabel"
                            >
                                {editCourseBatchObject ? "Edit Batch" : " Add Batch"}
                            </h5>

                            <button
                                onClick={() => {
                                    setShowModel(false)
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <ModalBody >
                            <Form>
                                <Col className="col-12">
                                    <FormGroup className="mt-2 mb-2">
                                        <Label >
                                            {props.t("Btach starts on")} :
                                        </Label>
                                        <DatePicker
                                            selected={fromDate}
                                            onChange={(date) => {
                                                setFromDateError(false)
                                                setFromDate(date)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Date")}
                                            startDate={fromDate}
                                            endDate={toDate}
                                            maxDate={toDate}
                                            dateFormat="dd-MM-yyyy"
                                            required
                                            errorMessage="Please select"
                                        />
                                    </FormGroup>
                                    {fromDateError && <p className="text-danger ml-2">Please select</p>}
                                </Col>
                                <Col className="col-12" >
                                    <FormGroup className="mt-2 mb-2 ">
                                        <Label >
                                            {props.t("Batch ends on")} :
                                        </Label>
                                        <DatePicker
                                            selected={toDate}
                                            onChange={(date) => {
                                                setToDateError(false);
                                                setToDate(date)
                                            }}
                                            className="form-control"
                                            placeholderText={props.t("Select Date")}
                                            startDate={fromDate}
                                            endDate={toDate}
                                            minDate={fromDate}
                                            dateFormat="dd-MM-yyyy"
                                            required
                                            errorMessage="Please select"
                                        />
                                    </FormGroup>
                                    {toDateError && <p className="text-danger ml-2">Please select</p>}
                                </Col>
                            
                            </Form>
                        </ModalBody>
                        <ModalFooter>

                            {props.addCourseBatchLoading ?
                                <button type="button" className="btn btn-light waves-effect">
                                    <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>
                                    {props.t(" Saving....")}
                                </button>

                                :
                                <button
                                    onClick={(e, v) => {
                                        let next = true;
                                        if (!toDate) {
                                            setToDateError(true);
                                            next = false;
                                        }
                                        if (!fromDate) {
                                            setFromDateError(true);
                                            next = false;
                                        }
                                        if (next) {
                                            handelAddCouseBatch()
                                                
                                        }
                            
                                    }}
                                    className="w-md  btn btn-primary float-right"
                                >
                                    {editCourseBatchObject ? props.t("Save") : props.t("Add")}
                                </button>
                            }

                        </ModalFooter>
                    </Modal>

                    {/* {ADD SESSION} */}


                    <Modal
                        isOpen={showAddMeetingPopup || props.liveMeetingAddLoading}
                        toggle={() => {
                            setEditLiveMeetingObject(undefined)
                            setShowAddMeetingPopup(false);
                        }}
                    >

                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myLargeModalLabel"
                            >
                                {(editLiveMeetingObject && editLiveMeetingObject.liveMeeting )? "Edit Live Seeion" : "Add Live Seeion"}
                            </h5>

                            <button
                                onClick={() => {
                                    setEditLiveMeetingObject(undefined);
                                    setShowAddMeetingPopup(false);
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <ModalBody>

                            {zoomAccessTokenLoading == 0 &&
                                <div className='text-center' >
                                    <p>Link/Autenticate your Zoom Account</p>
                                        
                                    <a
                                        href="https://zoom.us/oauth/authorize?client_id=q4LFhnySShCJSMCX8_Fvvw&response_type=code&redirect_uri=https%3A%2F%2Fdashboard.liltriangle.com%2Fzooom-authentication"
                                        target="_blank"
                                        className='btn btn-primary'
                                        onClick={() => {
                                            setEditLiveMeetingObject(undefined);
                                            setShowAddMeetingPopup(false);
                                        }}
                                    >
                                        Autenticate Zoom
                                    </a>

                                </div>

                            }
                            {zoomAccessTokenLoading == 1 &&
                                <div className='text-center' >
                                    <p>Zoom autentication status</p>
                                    <div className='text-success'>
                                        <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i> {props.t(" Checking status....")}
                                    </div>

                                </div>

                            }

                            {zoomAccessTokenLoading == 2 && zoomAccessToken && 
                               
                                        
                                       
                                <Alert color="success" role="alert">
                                               
                                           
                                    <p className='text-success text-center' >
                                        {/* <i className="bx bx-check-circle align-middle font-size-16 mr-1 "></i> */}
                                       
                                        <span className='mt-1'> Your Zoom account Linked/Autenticated</span>
                                        <span className='mt-1'> {"with " + zoomAccessToken.attributes.userInfo['email']}</span>
                                    </p>
                                        
                                </Alert>
                                

                            }

                            {zoomAccessTokenLoading == 2 &&
                                <Form>
                                    <Label >
                                        {props.t("Live session Mode")} : Zoom
                                    </Label>
                                    <Row>
                                        <Col className="col-12">
                                            <FormGroup className="mt-2 mb-2">
                                                <Label >
                                                    {props.t("Session Date&Time")} :
                                                </Label>
                                                <DatePicker
                                                    selected={meetingTime}
                                                    onChange={(date) => {
                                                        setMeetingTime(date)
                                                        setMeetingTimeError(false);
                                                    }}
                                                    className="form-control"
                                                    placeholderText={props.t("Select Date")}
                                                    minDate={props.selectedCourseBatch && props.selectedCourseBatch.attributes.startDate}
                                                    maxDate={props.selectedCourseBatch && props.selectedCourseBatch.attributes.endDate}
                                                    dateFormat="dd-MM-yyyy hh:mm a "
                                                    required
                                                    showTimeSelect
                                                    timeIntervals={10}
                                                    errorMessage="Please select"
                                                    disabled={scheduleZoomMeetingObject?true:false}
                                                />
                                                {meetingTimeError && <p className="text-danger ml-2">Please select Date&Time</p>}
                                            </FormGroup>
                                        </Col>
                                    
                                    </Row>

                                    <Row>
                                        <Col className="col-12">
                                            <FormGroup className="mt-2 mb-2">
                                                <Label >
                                                    {props.t("Meeting title")} :
                                                </Label>

                                                <Input
                                                
                                                    type="text"
                                                    className="form-control"
                                                    placeholder="Meeting title"
                                                    value={meetingTitle}
                                                    onChange={(e) => {
                                                        if (e.target.value.length > 0 && meetingTitleError) {
                                                            setMeetingTitleError(false)
                                                        } 
                                                        setMeetingTitle(e.target.value)
                                                    }}
                                                    disabled={scheduleZoomMeetingObject?true:false}
                                                />
                                                {meetingTitleError && <p className="text-danger ml-2">Title is mandatory</p>}
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </Form>

                            }

                            {
                                zoomAccessTokenLoading == 2 && scheduleZoomMeetingObject && editLiveMeetingObject && !editLiveMeetingObject.liveMeeting &&<div>
                                    <p className='text-success' >Zoom meeting successfully sceduled </p>
                                    <br />
                                    <p className='mb-0'>{props.t("Meeting MeetingId")} : {meetingId}</p>
                                    <p >{props.t("Meeting Password")} : {meetingPassword}</p>
                                    <p >{props.t("Meeting Link")} : {meetingUrl}</p>
                                    <p className='mb-0'>{props.t("Meeting created by")} :{zoomAccessToken && zoomAccessToken.attributes.userInfo["first_name"]}</p>
                                    <p >{props.t("Zoom Email id")} :{zoomAccessToken && zoomAccessToken.attributes.userInfo["email"]}</p>
                                    
                                    <br />
                                    
                                    <p className='text-primarry' >Click on Add to add Meeting to LMS  </p>

                                </div>
                            }

                             {
                                zoomAccessTokenLoading == 2 && editLiveMeetingObject&&  editLiveMeetingObject.liveMeeting && <div>
                                    <p className='text-success' >Zoom meeting details </p>
                                    <br />
                                    <p className='mb-0'>{props.t("Meeting MeetingId")} : {editLiveMeetingObject.liveMeeting.attributes.meetingId}</p>
                                    <p >{props.t("Meeting Password")} : {editLiveMeetingObject.liveMeeting.attributes.password}</p>
                                    <p >{props.t("Meeting Link")} : {editLiveMeetingObject.liveMeeting.attributes.zoomJoinURL}</p>
                                    <p className='mb-0'>{props.t("Meeting created by")} :{editLiveMeetingObject.liveMeeting.attributes.zoomUserObject&&editLiveMeetingObject.liveMeeting.attributes.zoomUserObject["first_name"]}</p>
                                    <p >{props.t("Zoom Email id")} :{editLiveMeetingObject.liveMeeting.attributes.zoomUserObject && editLiveMeetingObject.liveMeeting.attributes.zoomUserObject["email"]}</p>
                                    
                                    <br />
                                    
                                    <p className='text-primarry' >Click on Update to update Meeting details in LMS  </p>

                                </div>
                            }
                        </ModalBody>

                        {scheduleZoomMeetingObject && zoomAccessToken ?
                            <ModalFooter>

                                {props.liveMeetingAddLoading ?
                                    <button type="button" className="btn btn-light waves-effect">
                                        <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>
                                        {props.t(" Saving....")}
                                    </button>

                                    :
                                    <button
                                        onClick={(e, v) => {
                                            // handelAddLiveMeeting();
                                            setShowAddMeetingPopup(false);
                                        }}
                                        className="w-md  btn btn-primary float-right"
                                    >
                                        {/* {(editLiveMeetingObject && editLiveMeetingObject.liveMeeting) ? props.t("Update") : props.t("Add")} */}
                                        Close
                                    </button>
                                }

                            </ModalFooter>
                            :
                            <>
                                {zoomAccessToken && <ModalFooter>

                                    {scheduleZoomMeetingLoading ?
                                        <button type="button" className="btn btn-light waves-effect">
                                            <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>
                                            {editLiveMeetingObject && editLiveMeetingObject.liveMeeting ? props.t(" Updating Zoom Meeting...."):props.t(" Scheduling Zoom Meeting....")}
                                        </button>
                                        :
                                
                                        <div>

                                            {
                                                editLiveMeetingObject && editLiveMeetingObject.liveMeeting &&
                                                    zoomAccessToken.attributes.userInfo["email"] != editLiveMeetingObject.liveMeeting.attributes.zoomUserObject["email"] ?
                                                    
                                                    <div>

                                                        <p>Autenticated user and meeting created user are matching</p>
                                                        <button
                                                       
                                                            disabled={true}
                                                            className="w-md  btn btn-primary float-right"
                                                        >
                                                            {(editLiveMeetingObject && editLiveMeetingObject.liveMeeting) ? props.t("Update") : props.t("Schedule")}
                                                        </button>

                                                    </div>
                                             
                                                    :
                                                    <button
                                                        onClick={(e, v) => {
                                                            let next = true;
                                                            if (!meetingTime) {
                                                                setMeetingTimeError(true);
                                                                next = false;
                                                            }
                                                            if (!meetingTitle) {
                                                                setMeetingTitleError(true);
                                                                next = false;
                                                            }
                                      
                                                            if (next) {
                                                                handelScheduleZoomMeeting(editLiveMeetingObject && editLiveMeetingObject.liveMeeting?editLiveMeetingObject.liveMeeting.attributes.meetingId:false)
                                                            }
                            
                                                        }}
                                                        className="w-md  btn btn-primary float-right"
                                                    >
                                                        {(editLiveMeetingObject && editLiveMeetingObject.liveMeeting) ? props.t("Update") : props.t("Schedule")}
                                                    </button>
                                                     
                                            }
                                        </div>
                                    }

                                </ModalFooter>}
                            </>
                        }

                    </Modal>

                    
                    {/* {Delete meeting} */}


                       <Modal
                        isOpen={showDeleteMeetingPopup}
                        toggle={() => {
                            setShowDeleteMeetingPopup(undefined)
                            
                        }}
                    >

                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myLargeModalLabel"
                            >
                              Delete Live Seeion
                            </h5>

                            <button
                                onClick={() => {
                                    setShowDeleteMeetingPopup(false);
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <ModalBody>

                            {zoomAccessTokenLoading == 0 &&
                                <div className='text-center' >
                                    <p>Link/Autenticate your Zoom Account</p>
                                        
                                    <a
                                        href="https://zoom.us/oauth/authorize?client_id=XeIVimVtT0i1qpdeksc48g&response_type=code&redirect_uri=https%3A%2F%2Fdashboard.liltriangle.com%2Fzooom-authentication"
                                        target="_blank"
                                        className='btn btn-primary'
                                        onClick={() => {
                                            setEditLiveMeetingObject(undefined);
                                            setShowAddMeetingPopup(false);
                                        }}
                                    >
                                        Autenticate Zoom
                                    </a>

                                </div>

                            }
                            {zoomAccessTokenLoading == 1 &&
                                <div className='text-center' >
                                    <p>Zoom autentication status</p>
                                    <div className='text-success'>
                                        <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i> {props.t(" Checking status....")}
                                    </div>

                                </div>

                            }

                            {zoomAccessTokenLoading == 2 && zoomAccessToken && 
                               
                                        
                                       
                                <Alert color="success" role="alert">
                                               
                                           
                                    <p className='text-success text-center' >
                                        {/* <i className="bx bx-check-circle align-middle font-size-16 mr-1 "></i> */}
                                       
                                        <span className='mt-1'> Your Zoom account Linked/Autenticated</span>
                                        <span className='mt-1'> {"with " + zoomAccessToken.attributes.userInfo['email']}</span>
                                    </p>
                                        
                                </Alert>
                                

                            }
                            {
                                zoomAccessTokenLoading == 2 && deleteLiveMeetingObject&&  deleteLiveMeetingObject.liveMeeting && <div>
                                    <p className='text-success' >Zoom meeting details </p>
                                    <br />
                                    <p className='mb-0'></p>
                                    <p className='mb-0'>{props.t("title")} :{deleteLiveMeetingObject.liveMeeting.attributes.title}</p>
                                    <p className='mb-0'>{props.t("Time")} :{ getDateTimeStringForDate(deleteLiveMeetingObject.liveMeeting.attributes.meetingTime)}</p>
                                    <p className='mb-0'>{props.t("Meeting MeetingId")} : {deleteLiveMeetingObject.liveMeeting.attributes.meetingId}</p>
                                    <p >{props.t("Meeting Password")} : {deleteLiveMeetingObject.liveMeeting.attributes.password}</p>
                                    <p >{props.t("Meeting Link")} : {deleteLiveMeetingObject.liveMeeting.attributes.zoomJoinURL}</p>
                                    <p className='mb-0'>{props.t("Meeting created by")} :{deleteLiveMeetingObject.liveMeeting.attributes.zoomUserObject&&deleteLiveMeetingObject.liveMeeting.attributes.zoomUserObject["first_name"]}</p>
                                    <p >{props.t("Zoom Email id")} :{deleteLiveMeetingObject.liveMeeting.attributes.zoomUserObject && deleteLiveMeetingObject.liveMeeting.attributes.zoomUserObject["email"]}</p>
                                    
                                    <br />
                                    
                                    <p className='text-primarry' >Click on Delete to delete Meeting in LMS  </p>

                                </div>
                            }
                        </ModalBody>

                        {
                            <>
                                {zoomAccessToken && <ModalFooter>

                                    {deleteZoomMeetingLoading ?
                                        <button type="button" className="btn btn-light waves-effect">
                                            <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>
                                            {props.t(" Deleting Zoom Meeting....")}
                                        </button>
                                        :
                                
                                        <div>

                                            {
                                                deleteLiveMeetingObject && deleteLiveMeetingObject.liveMeeting &&
                                                    zoomAccessToken.attributes.userInfo["email"] != deleteLiveMeetingObject.liveMeeting.attributes.zoomUserObject["email"] ?
                                                    
                                                    <div>

                                                        <p>Autenticated user and meeting created user are matching</p>
                                                        <button
                                                       
                                                            disabled={true}
                                                            className="w-md  btn btn-danger float-right"
                                                        >
                                                            {props.t("Delete")}
                                                        </button>

                                                    </div>
                                             
                                                    :
                                                    <button
                                                        onClick={(e, v) => {
                                                           handelDeleteZoomMeeting(deleteLiveMeetingObject.liveMeeting)
                            
                                                        }}
                                                        className="w-md  btn btn-danger float-right"
                                                    >
                                                        {props.t("Delete")}
                                                    </button>
                                                     
                                            }
                                        </div>
                                    }

                                </ModalFooter>}
                            </>
                        }

                    </Modal>
                    
                    



                    <Modal
                        isOpen={false && (editLiveMeetingObject || props.liveMeetingAddLoading)}
                        toggle={() => { setEditLiveMeetingObject(undefined) }}
                    >
                        <div className="modal-header">
                            <h5
                                className="modal-title mt-0"
                                id="myLargeModalLabel"
                            >
                                {(editLiveMeetingObject && editLiveMeetingObject.liveMeeting ) ? "Edit Live Seeion" : "Add Live Seeion"}
                            </h5>

                            <button
                                onClick={() => {
                                    setEditLiveMeetingObject(undefined)
                                }}
                                type="button"
                                className="close"
                                data-dismiss="modal"
                                aria-label="Close"
                            >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <ModalBody>

                            <Form>
                                <Row>
                                    <Col className="col-12">
                                        <FormGroup className="mt-2 mb-2">
                                            <Label >
                                                {props.t("Session Date&Time")} :
                                            </Label>
                                            <DatePicker
                                                selected={meetingTime}
                                                onChange={(date) => {
                                                    setMeetingTime(date)
                                                    setMeetingTimeError(false);
                                                }}
                                                className="form-control"
                                                placeholderText={props.t("Select Date")}
                                                minDate={props.selectedCourseBatch && props.selectedCourseBatch.attributes.startDate}
                                                maxDate={props.selectedCourseBatch && props.selectedCourseBatch.attributes.endDate}
                                                dateFormat="dd-MM-yyyy hh:mm a "
                                                required
                                                showTimeSelect
                                                errorMessage="Please select"
                                            />
                                            {meetingTimeError && <p className="text-danger ml-2">Please select Date&Time</p>}
                                        </FormGroup>                                    

                    
                                    </Col>
                                    
                                </Row>
                                <Row>

                                    <Col className="col-6">
                                        <FormGroup className="mt-2 mb-2">
                                            <Label >
                                                {props.t("Session Medium")} :
                                            </Label>

                                            <select
                                                className="form-control"
                                                required
                                         
                                                onChange={(e) => {
                                                    if (meetingMediumError) {
                                                        setMeetingMediumError(false);
                                                    }
                                                    setMeetingMedium(e.target.value);
                                                }}
                                                value={meetingMedium}
                                            >
                                                <option disabled selected value>
                                                    Pick medium
                                                </option>
                                                <option value={"Zoom"}>{"Zoom"}</option>
                                                {/* <option value={"Teams"}>{"Teams"}</option> */}
                                                <option value={"Google Meet"}>{"Google Meet"}</option>
                                                {/* <option value={"Skype"}>{"Skype"}</option> */}
                                                <option value={"Others"}>{"Others"}</option>
                                            </select>
                                    
                                            {meetingMediumError && <p className="text-danger ml-2">Please select Medium</p>}
                                        </FormGroup>
                                    </Col>

                                    {meetingMedium == "Zoom" && <Col className="col-6" >

                                       
                                        <FormGroup className="mt-2 mb-2">
                                            <Label >
                                                {props.t("Zoom Session status")} :
                                            </Label>

                                            {zoomAccessTokenLoading == 1 ?
                                                <div className='text-success'>
                                                    <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i> {props.t(" Getting status....")}
                                                </div>
                                                :
                                                <div>
                                                    {
                                                        zoomAccessToken ? <div>
                                                            <Label className='text-success'>Autenticated</Label>
                                                        </div>
                                                            : <div>
                                                                <a
                                                                    href="https://zoom.us/oauth/authorize?client_id=XeIVimVtT0i1qpdeksc48g&response_type=code&redirect_uri=https%3A%2F%2Fdashboard.liltriangle.com%2Fzooom-authentication"
                                                                    target="_blank"
                                                                    onClick={() => {
                                                                        setEditLiveMeetingObject(undefined)
                                                                    }}
                                                                >
                                                                    Autenticate Zoom
                                                                </a>
                                                            </div>
                                                    }
                                                
                                                </div>
                                            }
                                        </FormGroup>
                                        
                                       
                                    </Col>}
                                </Row>

                                <Row>

                                    <Col className="col-6">
                                        <FormGroup className="mt-2 mb-2">
                                            <Label >
                                                {props.t("Meeting Id")} :
                                            </Label>

                                            <Input
                                               
                                                type="text"
                                                className="form-control"
                                                placeholder="Meeting Id"
                                                value={meetingId}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setMeetingIdError(false)
                                                    } else {
                                                        setMeetingIdError(true)
                                                    }
                                                    setMeetingId(e.target.value)
                                                }}
                                            />
                                       {meetingIdError && <p className="text-danger ml-2">Meeting Id is mandatory</p>}
                                    
                                        </FormGroup>
                                    </Col>
                                    <Col className="col-6">
                                        <FormGroup className="mt-2 mb-2">
                                            <Label >
                                                {props.t("Password")} :
                                            </Label>

                                            <Input
                                                
                                                type="text"
                                                className="form-control"
                                                placeholder="Password"
                                                value={meetingPassword}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setMeetingPasswordError(false)
                                                    } else {
                                                        setMeetingPasswordError(true)
                                                    }
                                                    setMeetingPassword(e.target.value)
                                                }}
                                            />
                                        </FormGroup>
                                        {meetingPasswordError && <p className="text-danger ml-2">Meeting password is mandatory</p>}
                                    </Col>

                                </Row>

                                <Row>
                                    <Col className="col-12">
                                        <FormGroup className="mt-2 mb-2">
                                            <Label >
                                                {props.t("Meeting link")} :
                                            </Label>

                                            <Input
                                                
                                                type="text"
                                                className="form-control"
                                                placeholder="Meeting link"
                                                value={meetingOtherInfo}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {
                                                        setMeetingOtherInfoError(false)
                                                    } else {
                                                        setMeetingOtherInfoError(true)
                                                    }
                                                    setMeetingOtherInfo(e.target.value)
                                                }}
                                            />
                                            {meetingOtherInfoError && <p className="text-danger ml-2">Please select Date&Time</p>}
                                        </FormGroup>
                                    </Col>
                                
                                </Row>
                            </Form>

                           
                        </ModalBody>
                        <ModalFooter>

                            {props.liveMeetingAddLoading ?
                                <button type="button" className="btn btn-light waves-effect">
                                    <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>
                                    {props.t(" Saving....")}
                                </button>

                                :
                                <button
                                    onClick={(e, v) => {
                                        let next = true;
                                        if (!meetingTime) {
                                            setMeetingTimeError(true);
                                            next = false;
                                        }
                                        if (!meetingMedium) {
                                            setMeetingMediumError(true);
                                            next = false;
                                        }

                                        if ((meetingId && meetingPassword) || meetingOtherInfo) {

                                            
                                            
                                            
                                        } else {
                                            if (meetingOtherInfo) {
                                                setMeetingIdError(true);
                                                next = false;
                                            } 

                                            if (!meetingId) {
                                                setMeetingIdError(true);
                                                next = false;
                                            }
                                            if (!meetingPassword) {
                                                setMeetingPasswordError(true);
                                                next = false;
                                            }
                                        }
                                        if (next) {
                                            // handelAddLiveMeeting()
                                                
                                        }
                            
                                    }}
                                    className="w-md  btn btn-primary float-right"
                                >
                                    {editLiveMeetingObject ? props.t("Save") : props.t("Add")}
                                </button>
                            }

                        </ModalFooter>
                                            
                    </Modal>



                    

                </Container>
            </div>
        </React.Fragment>
    );
}


const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { selectedCourse } = state.Course;
    const { courseBatch, courseBatchInitialLoding, selectedCourseBatch, addCourseBatchLoading } = state.CourseBatch;
    
    const { learningCategories, categoryLoadingState } = state.LearningCategory;
    const { quizLearningActivities, liveMeetingLearningActivities} = state.LearningActivity;
    const { qabMaps, mapAddLoading } = state.QuizActivityBatchMap;
    const { liveMeetings, liveMeetingAddLoading } = state.LiveMeeting;

    return {
        selectedSchool,
        selectedCourse,
        courseBatch, selectedCourseBatch, courseBatchInitialLoding, addCourseBatchLoading,
        learningCategories, categoryLoadingState,
        quizLearningActivities,liveMeetingLearningActivities,
        qabMaps, mapAddLoading,
        liveMeetings, liveMeetingAddLoading
    };
};


export default connect(mapStatetoProps, {
    setSelecetdSchool,
    setSelectedCourse,
    getCourseBatch,
    addCourseBatch,
    getLearningCategoryForCourseId,
    getQuizLearningActivitiesForCourseId,
    getLiveMeetingLearningActivitiesForCourseId,
    getQABMapForBatchId,
    addQABMap,
    getLiveMeetingForBatchId,
    addLiveMeeting,
})(withNamespaces()(BatchDetails));
