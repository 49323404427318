import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardImg, CardText, CardTitle, CardSubtitle, Table, Nav, NavItem, NavLink, TabContent, TabPane, Button, Form, FormGroup, Label, Input, Progress, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from 'classnames';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import Moment from 'moment';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import TopBar from '../Inventory/Topbar';
import DocumentViewerModal from '../Messaging/DocumentViewerModal';
import { setSelecetdSchool, getTeachersForSchool } from '../../store/actions';
import * as WhiteLabel from "../../components/Common/WhiteLable";

const blogList = (props) => {
    const match = matchPath(props.history.location.pathname, {
        path: "/blog-list/:schoolId/:id",
        exact: true,
        strict: false,
    });

    const frachiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-blog-list/:franchiseId/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const franchiseId = frachiseMatch && frachiseMatch.params.franchiseId
    const id = (match ?? frachiseMatch).params.id;

    const [mediaLink, setMediaLink] = useState([])
    const [mediaCategory, setMediaCategory] = useState([])
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        props.getTeachersForSchool(schoolId);
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        setLoading(true)
        let MediaCategories = Parse.Object.extend("MediaCategories");
        let query = new Parse.Query(MediaCategories);

        query.equalTo('objectId', id);
        query.first().then(
            (mediaCategory) => {
                setMediaCategory(mediaCategory)

                let MediaLink = Parse.Object.extend("MediaLink");
                let mediaLinkQuery = new Parse.Query(MediaLink);

                let ids = [];
                if (props.selectedSchool) {
                    ids.push(props.selectedSchool.id)
                    ids.push(props.selectedSchool.attributes.instituteId)
                    if (props.selectedSchool.attributes.franchiseId) {
                        ids.push(props.selectedSchool.attributes.franchiseId)
                    }
                    ids.push(WhiteLabel.getAppName())
                    if (mediaCategory.attributes.targetAudienceId) {
                        ids.push(mediaCategory.attributes.targetAudienceId)
                    }

                    mediaLinkQuery.containedIn('targetAudienceId', ids);
                } else if (frachiseMatch) {
                    // console.log(franchiseId);
                    mediaLinkQuery.equalTo('targetAudienceId', franchiseId);
                }

                mediaLinkQuery.containedIn('tags', mediaCategory.attributes.filters);
                mediaLinkQuery.descending('createdAt');
                mediaLinkQuery.notEqualTo("isDeleted", true)
                mediaLinkQuery.find().then(
                    (result) => {
                        setLoading(false)
                        // console.log(result);
                        setMediaLink(result)
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            },
            (error) => {
                console.log(error);
            }
        );
    }, [])



    const getRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };


    const getTeacherName = (teacherId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (teacher) => teacher.id === teacherId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getThumbnail = (id) => {

        const videoId = new URLSearchParams(new URL(id).search).get('v');

        return videoId


    }

    return (
        <div className='page-content'>
            <TopBar
                title={mediaCategory && mediaCategory.attributes && mediaCategory.attributes.title}
                buttonName={"Add Blog"}
                link={frachiseMatch ? `/franchise-add-blog/${franchiseId}/${id}` : `/add-blog/${schoolId}/${id}`}
                hideButton={false}
            />
            <div className='ml-4'>
                <Row>
                    {loading && (
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading....{" "}
                                </Link>
                            </div>
                        </Col>
                    )}
                    {mediaLink.map((link, key) => (
                        <Col xl="6" sm="12" key={key}>
                            <Link to={frachiseMatch ? `/franchise-blog/${franchiseId}/${link.id}` : `/blog/${schoolId}/${link.id}`}>
                                <Card>
                                    <CardBody>
                                        <div className="embed-responsive embed-responsive-16by9">
                                            {link.attributes.previewImage ? (
                                                <img
                                                    src={link.attributes.previewImage._url}
                                                    className="img-fluid mx-auto d-block"
                                                    style={{ height: "20rem" }}
                                                    alt=""
                                                    width="500"
                                                />
                                            ) : (
                                                link.attributes.url && link.attributes.url.includes('youtube.com') ? (
                                                    // If it's a YouTube link, construct the thumbnail URL
                                                    <img
                                                        src={`https://img.youtube.com/vi/${getThumbnail(link.attributes.url)}/hqdefault.jpg`}
                                                        className="img-fluid mx-auto d-block"
                                                        style={{ height: "20rem" }}
                                                        alt=""
                                                        width="500"
                                                    />
                                                ) : (
                                                    <div
                                                        className="d-flex align-items-center justify-content-center"
                                                        style={{
                                                            height: "20rem",
                                                            backgroundColor: getRandomColor(),
                                                            color: "white",
                                                        }}
                                                    >
                                                        <CardTitle className='text-white'>{link.attributes.title}</CardTitle>
                                                    </div>
                                                )
                                            )}
                                        </div>
                                        <div className='py-3'>
                                            <CardTitle>{link.attributes.title}</CardTitle>
                                            <CardTitle style={{ color: 'black' }}>{link.attributes.description && link.attributes.description.slice(0, 150)}....</CardTitle>
                                            <p className='text-muted'>{link.attributes.createdBy ? `Created by: ${getTeacherName(link.attributes.createdBy)}` : ''}</p>
                                        </div>
                                    </CardBody>
                                </Card>
                            </Link>
                        </Col>
                    ))}

                    {!loading && mediaLink.length == 0 && (
                        <Col xs="12">
                            <div className="text-center my-3">
                                <h2 to="#" className="text-info">
                                    No Blogs found
                                </h2>
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </div>
    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { teachers } = state.Teacher;
    return { selectedSchool, schools, teachers };
}

export default withRouter(connect(mapStatetoProps, { setSelecetdSchool, getTeachersForSchool })(withNamespaces()(blogList)));
