import React, { } from 'react';
import { Link } from "react-router-dom";
import { withNamespaces } from 'react-i18next';
import { Col, Card, CardBody, Table, UncontrolledTooltip, Label, } from "reactstrap";
import Switch from "react-switch";
import Parse from 'parse';


const TeacherList = (props) => {

    function getDesignation(teacher) {
        if (!props.isStudent) {
            if (teacher.attributes.designation) {
                return teacher.attributes.designation;
            } else if (teacher.attributes.Role === 3) {
                return props.t('Director');
            } else if (teacher.attributes.Role === 2) {
                return props.t('Center Head');
            } else {
                return props.t('Teacher');
            }
        } else {
            return (props.t(
                "Student"
            ))
        }
    }


    const getTextColorBasedOnTemperature = (temp) => {

        if (temp == undefined || temp === "-" || temp === "Not Recorded") {
            return "text-black"
        } else {
            if (temp.toLowerCase() === "normal") {
                return "text-success"
            }
            return "text-danger"
        }



    }

    return (

        <React.Fragment>
            {
                props.teachers.map((listObject, outerKey) => (
                    <div key={outerKey} >
                        {listObject.list && <>
                            <Col lg={12} className="mt-2"><h4>{listObject.role}</h4></Col>
                            <Col lg={12} >
                                <Card>
                                    <CardBody>
                                        <div className="table-responsive">
                                            <Table className="table-centered table-nowrap table-hover">
                                                <thead className="thead-light" style={{ height: '0' }}>
                                                    <tr>
                                                        <th scope="col" style={{ width: "70px" }}>#</th>
                                                        <th scope="col">{props.t('Name')}</th>
                                                        <th scope="col" style={{ textAlign: "center", width: "200px" }}>{props.t('Temperature')}</th>
                                                        <th scope="col" style={{ textAlign: "center", width: "200px" }}>{props.t('Check In Time')}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        listObject.list.map((teacher, i) =>
                                                            <tr key={teacher.id} >
                                                                <td>
                                                                    {
                                                                        !teacher.parseObject.attributes.Photo
                                                                            ? <div className="avatar-xs">
                                                                                <span className="avatar-title rounded-circle">
                                                                                    {teacher.parseObject.attributes.Name ? teacher.parseObject.attributes.Name.toUpperCase().charAt(0) : " "}
                                                                                </span>
                                                                            </div>
                                                                            : <div>
                                                                                <img className="rounded-circle avatar-xs" src={teacher.parseObject.attributes.Photo._url} alt="" />
                                                                            </div>
                                                                    }

                                                                </td>
                                                                <td>
                                                                    <h5 className="font-size-14 mb-1">
                                                                        <Link to={teacher.parseObject.attributes.Role === 3 ? "/staffprofile/d-" + props.schoolId + "/" + teacher.id : "/staffprofile/" + teacher.parseObject.attributes.SchoolID + "/" + teacher.id}
                                                                            className="text-dark">{teacher.parseObject.attributes.Name}
                                                                        </Link>


                                                                    </h5>
                                                                    <p className="text-muted mb-0">{getDesignation(teacher.parseObject)}</p>
                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <h6 className={getTextColorBasedOnTemperature(teacher.temperature)}>{teacher.temperature}</h6>

                                                                </td>
                                                                <td style={{ textAlign: "center" }}>
                                                                    <h6>{teacher.checkInTime}</h6>



                                                                </td>


                                                            </tr>
                                                        )
                                                    }

                                                </tbody>
                                            </Table>
                                        </div>

                                    </CardBody>
                                </Card>
                            </Col></>}
                    </div >
                ))
            }

        </React.Fragment >

    );

}

export default withNamespaces()(TeacherList);