import React, { useEffect}from 'react';
import { matchPath } from "react-router";

const DownloadReceipt = (props) => {

    const onlyPrint = matchPath(props.history.location.pathname, {
        path: "/download-receipt/:invoiceId",
        exact: true,
        strict: false,
    });

    const invoiceId = onlyPrint.params.invoiceId;

    useEffect(() => {
    
       let url =" https://us-central1-liltriangleweb.cloudfunctions.net/html2pdf?url=https://dashboard.liltriangle.com/invoice/"+invoiceId+"/2"
        window.location.replace(url);
    }, [invoiceId]);

    return (
        <React.Fragment>
                
        </React.Fragment>
    );
}
            
export default DownloadReceipt;