import Parse from "parse";

const getInventoryVendorFromParse = (inventoryVendorId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryVendor = Parse.Object.extend("InventoryVendors");
        var query = new Parse.Query(InventoryVendor);
        query.get(inventoryVendorId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };
  
  const getInventoryVendorFromLocal = (inventoryVendorId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryVendor = Parse.Object.extend("InventoryVendors");
        var query = new Parse.Query(InventoryVendor);
        query.fromLocalDatastore();
        query.get(inventoryVendorId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };
  
  const deleteInventoryVendorFromParse = (inventoryVendor) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryVendor.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            inventoryVendor.set(
            "deletedBy",
            Parse.User.current().attributes.teacherId);
        } else {
            inventoryVendor.set("deletedBy", Parse.User.current().id);
        }
  
        inventoryVendor.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("error");
          }
        );
      });
    } catch (error) {}
  };
  
  const getInventoryVendorForOwnerId = (id, ownerType, updatedDate,isLocal,) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryVendor = Parse.Object.extend("InventoryVendors");
        var query = new Parse.Query(InventoryVendor);
        query.equalTo("ownerId", id);
        query.equalTo("ownerType", ownerType)
  
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
        } else {
          query.notEqualTo("isDeleted", true);
        }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
  
        query.addAscending("order");
        query.find().then(
          (result) => {
           resolve(result); 
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };
  
  const editInventoryVendor = (inventoryVendor) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryVendor.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) {}
  };
  const saveCustomObjects = (object) => {
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) {}
  };
  
  
  export {
    getInventoryVendorFromParse,
    getInventoryVendorFromLocal,
    deleteInventoryVendorFromParse,
    getInventoryVendorForOwnerId,
    editInventoryVendor,
    saveCustomObjects,
  };
  