import React, { useEffect, useState } from 'react';
import Topbar from '../Topbar';
import Parse from 'parse';
import { Card, CardBody, Label, Button, Badge, Row, Col, Input, Form, Nav, NavItem, NavLink, FormGroup, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { matchPath } from "react-router";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import SweetAlert from 'react-bootstrap-sweetalert';
import {
    setSelecetdSchool, getSaleOrdersForOwnerId, getInventorySaleOrderItemsForOwnerId,
    getTeachersForSchool, getTeacher, getAllKids, getInventoryItemForOwnerId,
    updateDeleteSaleOrder, deleteSaleOrder, getSaleOrdersForBuyer, setSelectedSaleOrder
} from '../../../store/actions';
import Moment from 'moment';
import OrderStatus from './OrderStatus';
import Select from "react-select";
import ReceivePaymentModal from './ReceivePaymentModal';
import { compose } from 'redux';

const saleOrder = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/sale-order/:schoolId",
        exact: true,
        strict: false,
    });

    const franchiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-sale-order/:franchiseId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const franchiseId = franchiseMatch && franchiseMatch.params.franchiseId;

    const [showDelete, setShowDelete] = useState(false);
    const [deleteObject, setDeleteObject] = useState(undefined);
    const [showOrderStatus, setShowOrderStatus] = useState(false);
    const [orderStatusObject, setOrderStatusObject] = useState(undefined);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });
    const [statusType, setStatusType] = useState(0);
    const [allSchools, setAllSchools] = useState([]);
    const [displaySaleOrders, setDisplaySaleOrders] = useState([])

    const [showIncompleteOrderPopup, setShowIncompleteOrderPopup] = useState(false);
    const [saleOrder, setSaleOrder] = useState();
    const [showPaymentReceivedModal, setShowPaymentReceivedModal] = useState(false)
    const [dueItemsPopup, setDueItemsPopup] = useState(false);
    const [dueItems, setDueItems] = useState([]);
    const [fulfilState, setFulfilState] = useState(false)
    useEffect(() => {
        if (schoolId && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
        if (schoolId && selectedSchool.value != schoolId) {
            setSelctedSchool({ value: schoolId, label: 'school' });
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getSaleOrdersForBuyer(schoolId, 'School')
            props.getTeachersForSchool(schoolId);
            props.getAllKids(schoolId);
            props.setSelecetdSchool(schoolId);
        }
    }, [schoolId]);

    useEffect(() => {
        if (franchiseId) {
            props.getSaleOrdersForOwnerId(franchiseId, 'franchise')
        }
    }, [franchiseId]);

    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);

    useEffect(() => {
        if (franchiseId) {
            props.getInventoryItemForOwnerId(franchiseId, 'franchise');
        }
    }, [franchiseId])

    const handleDeleteItem = () => {
        props.deleteSaleOrder(deleteObject)
        setShowDelete(false);
    };


    const getStaffName = (staffId) => {
        if (props.teachers && props.teachers.length > 0) {
            let temp = props.teachers.filter(
                (staff) => staff.id === staffId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getStudentName = (studentId) => {
        if (props.kids && props.kids.length > 0) {
            let temp = props.kids.filter(
                (student) => student.id === studentId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    const getSchoolName = (schoolId) => {
        if (props.schools && props.schools.length > 0) {
            let temp = props.schools.filter(
                (school) => school.id === schoolId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }

    const getButtonText = (status) => {
        switch (status) {
            case 0:
                return "Order placed";
            case 1:
                return "Order delivered";
            case 2:
                return "Order accepted";
            case 3:
                return "Order shipped";
            case 4:
                return "Order Placed";
            case -1:
                return "Incompleted Order ";
            default:
                return "Order placed";
        }
    }

    function getStatusColor(status) {

        if (status == 0) {
            return "primary";
        } else if (status == 2) {
            return "warning ";
        } else if (status == 3) {
            return "danger";
        } else if (status == 1) {
            return "success";
        } else if (status == -1) {
            return "secondary";
        }
        return "primary"
    }

    useEffect(() => {
        if (props.inventorySaleOrders) {

            if (selectedSchool.value == 'allSchools' && statusType == 0) {
                setDisplaySaleOrders(props.inventorySaleOrders)
            } else {

                let list = []
                for (const so of props.inventorySaleOrders) {

                    if (selectedSchool.value == 'allSchools' || selectedSchool.value == so.attributes.buyerId) {
                        if (statusType == 0) {
                            list.push(so)
                        } else if (statusType == 1 && so.attributes.status == 0) {
                            list.push(so)
                        } else if (statusType == 2 && so.attributes.status == 2) {
                            list.push(so)
                        } else if (statusType == 3 && so.attributes.status == 3) {
                            list.push(so)
                        } else if (statusType == 4 && so.attributes.status == 1) {
                            list.push(so)
                        } else if (statusType == 5 && so.attributes.status == -1) {
                            list.push(so)
                        }
                    }

                }
                setDisplaySaleOrders(list)
            }


        }
    }, [selectedSchool, statusType, props.inventorySaleOrders]);

    const getSaleOrderDueItems = (saleOrderId) => {
        let InventorySaleOrderDueItems = Parse.Object.extend("InventorySaleOrderDueItems");
        let query = new Parse.Query(InventorySaleOrderDueItems);

        query.equalTo("saleOrderId", saleOrderId);

        query.find().then(
            (dueItems) => {
                console.log("dueItems", dueItems)
                setDueItems(dueItems)
            },
            (error) => {
                console.log("Error dueItems:", error);
            }
        );
    }

    const getItemName = (id) => {
        for (const item of props.inventoryItems) {
            if (item.id == id) {
                return item.attributes.name;
            }
        }
        return "_";
    }

    const handleFullfilOrder = () => {

        console.log("saleOrder.id", saleOrder.id)
        Parse.Cloud.run("function_fulfilDueOrder", {
            saleOrderId: saleOrder.id,
            teacherId: Parse.User.current().attributes.teacherId,
        }).then((response) => {
            console.log("response", response)
            if (response) {
                if (response.state == 1) {
                    setFulfilState("Due order fulfiled successfully.")
                } else if (response.state == 2) {
                    setFulfilState("Some of the items are out please check the item stock and retry.")
                } else if (response.state == 3) {
                    setFulfilState("Items are deleted.")
                } else if (response.state == 4) {
                    setFulfilState("This order doesn't have due.")
                } else if (response.state == 5) {
                    setFulfilState("This order is deleted.")
                } else if (response.state == 6) {
                    setFulfilState("This due order already fulfiled.")
                } else if (response.state == -1) {
                    setFulfilState("Something went wrong, please try again!")
                }
            }
        }, (error) => {
            console.log("error" + error.message)
        });
    }


    return (
        <React.Fragment>
            <div className='page-content'>
                {fulfilState && (
                    <SweetAlert
                        warning
                        showCancel={false}
                        confirmBtnText="OK"
                        confirmBtnBsStyle="success"
                        title="Alert!"
                        onConfirm={() => {
                            setFulfilState(false)
                            setDueItemsPopup(false)
                        }}
                    >

                        {fulfilState}
                        <br></br>
                    </SweetAlert>
                )}
                <Modal isOpen={dueItemsPopup} size='lg'>
                    <ModalHeader toggle={() => {
                        setDueItemsPopup(false)
                    }}>
                        {`Due Items (#${saleOrder && saleOrder.attributes.saleOrderNumber}) - ${getSchoolName(saleOrder && saleOrder.attributes.buyerId)}`}
                    </ModalHeader>
                    <ModalBody>
                        <div className="table-responsive">
                            <table className="table table-centered table-bordered mb-0 table-sm">
                                <thead className="thead-light">
                                    <tr>
                                        <th>#</th>
                                        <th>Items Name</th>
                                        <th>Ordered Quantity</th>
                                        <th>Delivered Quantity</th>
                                        <th>Due Quantity</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {dueItems.map((dueItem, key) => (
                                        <React.Fragment key={key}>
                                            <tr>
                                                <td>
                                                    <Label><b>{key + 1}</b></Label>
                                                </td>
                                                <td>
                                                    <Label>{getItemName(dueItem.attributes.itemId)}</Label>
                                                </td>
                                                <td>
                                                    <Label className="font-size-12 mb-1">{dueItem.attributes.orderdQuantity}</Label>
                                                </td>
                                                <td>
                                                    <Label className="font-size-12 mb-1">{dueItem.attributes.deliveringQuantity}</Label>
                                                </td>
                                                <td>
                                                    <Label className="font-size-12 mb-1"><b>{dueItem.attributes.dueQuantity}</b></Label>
                                                </td>
                                            </tr>
                                            {dueItem.attributes.matrix && dueItem.attributes.matrix.length > 0 && (
                                                <tr>
                                                    <td colSpan="5">
                                                        <div className="table-responsive text-center" style={{ width: '100%' }}>
                                                            <table className="table table-centered table-bordered mb-0 table-sm" style={{ width: '90%', margin: 'auto' }}>
                                                                <thead className="thead-light">
                                                                    <tr>
                                                                        {dueItem.attributes.matrix.some(matrixItem => matrixItem.isColor) && <th>Color Value</th>}
                                                                        {dueItem.attributes.matrix.some(matrixItem => matrixItem.isSize) && <th>Size Value</th>}
                                                                        <th>Ordered Quantity</th>
                                                                        <th>Delivered Quantity</th>
                                                                        <th>Due Quantity</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {dueItem.attributes.matrix.map((matrixItem, matrixKey) => (
                                                                        <tr key={matrixKey}>
                                                                            {matrixItem.isColor && <td>{matrixItem.colorValue}</td>}
                                                                            {matrixItem.isSize && <td>{matrixItem.sizeValue}</td>}
                                                                            <td>{matrixItem.selectedQuntity}</td>
                                                                            <td>{matrixItem.deliveringQuantity}</td>
                                                                            <td><b>{matrixItem.dueQuantity}</b></td>
                                                                        </tr>
                                                                    ))}
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </td>
                                                </tr>
                                            )}
                                        </React.Fragment>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        {franchiseId && <Button
                            className='btn btn'
                            color='success'
                            onClick={() => {
                                handleFullfilOrder()
                            }}>
                            Fulfil Due
                        </Button>}
                    </ModalFooter>
                </Modal>
                <Row>
                    <Col>
                        <Topbar
                            title={schoolId ? "Orders" : "Sale Orders"}
                            buttonName={"New Sale"}
                            link={schoolId ? `/new-sale/${schoolId} ` : `/franchise-new-sale/${franchiseId}`}
                            hideButton={true}
                        />
                        {showOrderStatus && <OrderStatus
                            showOrderStatus={showOrderStatus}
                            orderStatusObject={orderStatusObject && orderStatusObject}
                            saleOrderId={orderStatusObject && orderStatusObject.id}
                            onCloseOrderStatus={() => { setShowOrderStatus(false); setOrderStatusObject(undefined) }}
                            schoolMatch={match}
                            franchiseMatch={franchiseId}
                        />}
                    </Col>
                    <Col className='float-right'>
                        <Row>
                            {<Col className='float-right' >
                                {franchiseMatch && <FormGroup className="mt-2 mb-2 p-1">
                                    <Select
                                        value={selectedSchool}
                                        onChange={(v) => {
                                            setSelctedSchool(v)
                                        }}
                                        options={allSchools}
                                        className="selectPaymentType"
                                        classNamePrefix="selectPayType"

                                    />
                                </FormGroup>}
                            </Col>}
                            <Col className='float-right'>
                                <FormGroup className="mt-2 mb-2 p-1">
                                    <select
                                        className="form-control "
                                        value={statusType}
                                        onChange={(e) => {
                                            setStatusType(e.target.value);
                                        }}
                                    >
                                        <option value={0}>All Orders</option>
                                        <option value={1}>Order placed</option>
                                        <option value={2}>Order Accepted</option>
                                        <option value={3}>Order Shipped</option>
                                        <option value={4}>Order Delivered</option>
                                        <option value={5}>Incomplete Order</option>
                                    </select>
                                </FormGroup>
                            </Col>
                            {false && <Col className='mt-2 mb-2 p-1'>
                                <Link to={`/franchise-new-sale/${franchiseId}`}>
                                    <Button
                                        type="button"
                                        color="primary"
                                        className="btn waves-effect waves-light  mb-2"
                                    >
                                        New Sale Order
                                    </Button>
                                </Link>
                            </Col>}
                        </Row>
                    </Col>
                </Row>


                {showDelete && deleteObject.attributes.status && deleteObject.attributes.status >= 1 && (
                    <SweetAlert
                        warning
                        showCancel={false}
                        confirmBtnText="OK"
                        confirmBtnBsStyle="success"
                        title="Alert!"
                        onConfirm={() => {
                            setShowDelete(false);
                            setDeleteObject(undefined);
                        }}
                    >
                        {deleteObject.attributes.status == 1 &&
                            "This sale have been deleverd and it can not be deleted"
                        }
                        {deleteObject.attributes.status == 2 && "This sale has been accepted and it can not be deleted"}
                        {deleteObject.attributes.status == 3 && "This sale is in transit and it can not be deleted"}
                        <br></br>
                    </SweetAlert>
                )}

                {(showDelete && (!deleteObject.attributes.status || deleteObject.attributes.status == 0)) && (
                    <SweetAlert
                        warning
                        showCancel
                        confirmBtnText="Delete"
                        cancelBtnText="Cancel"
                        confirmBtnBsStyle="danger"
                        cancelBtnBsStyle="success"
                        title="Alert!"
                        onCancel={() => {
                            setShowDelete(false);
                        }}
                        onConfirm={() => {
                            handleDeleteItem()
                        }}
                    >
                        {"Are you sure you want to delete"}
                        <br></br>
                    </SweetAlert>
                )}
                <div>
                    {displaySaleOrders && displaySaleOrders.length > 0 && <Card>
                        <CardBody>
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>Buyers Name</th>
                                            {false && match && <th>Buyer Type</th>}
                                            <th>Date</th>
                                            <th>Order Value</th>
                                            <th className="flex-fill text-center ">Order Status</th>
                                            <th style={{ width: "20px" }}>
                                                Due
                                            </th>
                                            <th style={{ width: "20px" }}>
                                                Print
                                            </th>
                                            <th style={{ width: "20px" }}>
                                                Details
                                            </th>
                                            {franchiseMatch && <th style={{ width: "20px" }}>
                                                Delete
                                            </th>}
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            displaySaleOrders.map((saleOrder, key) =>
                                                <tr key={key}>
                                                    <td>
                                                        <Link to={match ? `/inventory-sale-order-details/${schoolId}/${saleOrder.id}` : `/franchise-sale-order-details/${franchiseId}/${saleOrder.id}`}  >
                                                            <Label className="font-size-12 mb-1">{getStudentName(saleOrder.attributes.buyerId) ?? getStaffName(saleOrder.attributes.buyerId) ?? getSchoolName(saleOrder.attributes.buyerId)}</Label>
                                                        </Link><br />
                                                        <Label><b>#{saleOrder.attributes.saleOrderNumber}</b></Label>
                                                    </td>
                                                    {false && match && <td>
                                                        <Label className="font-size-12 mb-1">{saleOrder.attributes.buyerType}</Label>
                                                    </td>}
                                                    <td>
                                                        <Label className="font-size-12 mb-1">{getTimeStringForDate(saleOrder.attributes.createdAt)}</Label><br />
                                                        <small className='font-size-8 mb-1'>{"School"}</small>
                                                    </td>

                                                    <td>
                                                        <div>
                                                            <strong>
                                                                {saleOrder.attributes.freightCharges ? saleOrder.attributes.orderValue + saleOrder.attributes.freightCharges : saleOrder.attributes.orderValue}
                                                            </strong>
                                                        </div>
                                                        {saleOrder.attributes.freightCharges && (
                                                            <div>
                                                                <small className='text-primary'>
                                                                    {saleOrder.attributes.freightCharges + " Freight Charges included"}
                                                                </small>
                                                            </div>
                                                        )}
                                                    </td>

                                                    <td>
                                                        <div className="flex-fill text-center ">
                                                            {saleOrder.attributes.status == -1 ? <Badge color='primary' className={
                                                                "font-size-12 badge-soft-" +
                                                                getStatusColor(saleOrder.attributes.status ?? 0)
                                                            } style={{ cursor: "pointer", fontSize: '0.5rm' }}
                                                            >{getButtonText(saleOrder.attributes.status ?? 0)}</Badge> :
                                                                <Badge color='primary' className={
                                                                    "font-size-12 badge-soft-" +
                                                                    getStatusColor(saleOrder.attributes.status ?? 0)
                                                                } style={{ cursor: "pointer", fontSize: '0.5rm' }}
                                                                    disabled={saleOrder.attributes.status == -1 ? true : false}
                                                                    onClick={(e) => {
                                                                        setShowOrderStatus(true);
                                                                        setOrderStatusObject(saleOrder)
                                                                    }}>{getButtonText(saleOrder.attributes.status ?? 0)}</Badge>}
                                                        </div>
                                                        {saleOrder.attributes.paidStatus == 3 &&
                                                            <div className='py-2 text-center'>
                                                                <Badge color='warning' className={"font-size-12 badge-soft-warning text-center"}
                                                                    style={{ cursor: "pointer", fontSize: '0.5rm' }}
                                                                    onClick={(e) => {
                                                                        setSaleOrder(saleOrder);
                                                                        setShowIncompleteOrderPopup(true);
                                                                    }}>Partially Paid
                                                                </Badge>
                                                            </div>}
                                                        {(saleOrder.attributes.status == 2 || saleOrder.attributes.status == 3) &&
                                                            saleOrder.attributes.paidStatus != 1 &&
                                                            franchiseMatch &&
                                                            (
                                                                <div className='py-1 text-center'>
                                                                    <Button
                                                                        type="button"
                                                                        color="secondary"
                                                                        className="btn btn-sm waves-effect waves-light"
                                                                        onClick={() => {
                                                                            setSaleOrder(saleOrder);
                                                                            setShowIncompleteOrderPopup(true);
                                                                        }}
                                                                    >
                                                                        Receive Payment
                                                                    </Button>

                                                                </div>
                                                            )}
                                                        {saleOrder.attributes.paidStatus == 1 &&
                                                            <div className='py-2 text-center'>
                                                                <Badge color='success' className={"font-size-12 badge-soft-success text-center"}
                                                                    style={{ cursor: "pointer", fontSize: '0.5rm' }}
                                                                    onClick={(e) => {
                                                                        setSaleOrder(saleOrder);
                                                                        setShowPaymentReceivedModal(true)
                                                                        setShowIncompleteOrderPopup(true);
                                                                    }}>Paid
                                                                </Badge>
                                                            </div>}
                                                    </td>
                                                    <td>
                                                        {saleOrder.attributes.dueState && <div className="flex-fill text-center ">
                                                            <Badge color={saleOrder.attributes.dueState == 2 ? 'primary' : 'success'} className={saleOrder.attributes.dueState == 2 ? "font-size-12 badge-soft-primary text-center" : "font-size-12 badge-soft-success text-center"}
                                                                style={{ cursor: "pointer", fontSize: '0.5rm' }}
                                                                onClick={() => {
                                                                    getSaleOrderDueItems(saleOrder.id)
                                                                    setSaleOrder(saleOrder);
                                                                    setDueItemsPopup(true);
                                                                }}>{saleOrder.attributes.dueState == 2 ? "due Items" : "fulfiled Due"}
                                                            </Badge>
                                                        </div>}
                                                    </td>
                                                    <td>
                                                        <div className="flex-fill text-center ">
                                                            <Link to={schoolId ? `/print-sale-order/${schoolId}/${saleOrder.id}` : `/franchise-print-sale-order/${franchiseId}/${saleOrder.id}`} >
                                                                <i className="bx bx-printer"></i>
                                                            </Link>
                                                        </div>
                                                    </td>
                                                    <td>
                                                        <div className="flex-fill text-center ">
                                                            <Link to={match ? `/inventory-sale-order-details/${schoolId}/${saleOrder.id}` : `/franchise-sale-order-details/${franchiseId}/${saleOrder.id}`} >
                                                                <i className="bx bx-right-arrow-circle"></i>
                                                            </Link>
                                                        </div>

                                                    </td>

                                                    {franchiseMatch && <td>
                                                        <div
                                                            className="flex-fill text-center"
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                setDeleteObject(saleOrder)
                                                                setShowDelete(true);
                                                            }}
                                                        >
                                                            <i className="bx bxs-trash text-danger"></i>
                                                        </div>
                                                    </td>}
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </CardBody>
                    </Card>}
                </div>
                <div>
                    {displaySaleOrders && displaySaleOrders.length <= 0 && <Card style={{ height: "400px" }}>
                        <CardBody>
                            <h4 style={{ marginTop: "150px" }} className='text-info text-center'>No sale orders found for this filter.</h4>
                        </CardBody>
                    </Card>}
                </div>
                <div style={{ height: "500px" }}></div>
            </div>
            <ReceivePaymentModal
                showIncompleteOrderPopup={showIncompleteOrderPopup}
                OnCloseIncompleteOrderPopup={() => {
                    setShowIncompleteOrderPopup(!showIncompleteOrderPopup)
                }}
                saleOrder={saleOrder}
                franchiseId={franchiseId}
                schoolId={schoolId}
                showPaymentReceivedModal={showPaymentReceivedModal}
                handelClose={() => {
                    setShowPaymentReceivedModal(false);
                }}
            />
        </React.Fragment>

    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { inventorySaleOrders } = state.InventorySaleOrder;
    const { inventorySaleOrderItems } = state.InventorySaleOrderItem;
    const { kids } = state.Kid;
    const { teachers } = state.Teacher;
    const { inventoryItems } = state.InventoryItems;
    return { selectedSchool, inventorySaleOrders, inventorySaleOrderItems, kids, teachers, schools, inventoryItems };
}

export default (withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getSaleOrdersForOwnerId, getInventorySaleOrderItemsForOwnerId,
    getTeachersForSchool, getTeacher, getAllKids, getInventoryItemForOwnerId,
    deleteSaleOrder, updateDeleteSaleOrder, getSaleOrdersForBuyer,
})(saleOrder)));
