import * as actions from "./actionTypes";

export const getInventoryKitItemsForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_KIT_ITEMS,
    payload: {ownerId, ownerType},
    }
}

export const updateInventoryKitItems = (inventoryKitItems) =>{
    return{
        type:actions.UPDATE_INVENTORY_KIT_ITEMS,
        payload: {inventoryKitItems},
    }
}

export const addInventoryKitItem = (inventoryKitItem) =>{
    return{
        type: actions.ADD_INVENTORY_KIT_ITEM,
        payload: {inventoryKitItem}
    }
}

export const deleteInventoryKitItem = (inventoryKitItem) =>{
    return{
        type:actions.DELETE_INVENTORY_KIT_ITEM,
        payload: {inventoryKitItem},
    }
}

export const updateDeleteInventoryKitItem = (inventoryKitItem) =>{
    return{
        type:actions.UPDATE_DELETE_INVENTORY_KIT_ITEM,
        payload: {inventoryKitItem},
    }
}

export const setSelectedInventoryKitItems = (inventoryKitItemId) => {
    return {
        type: actions.SET_SELECTED_INVENTORY_KIT_ITEMS,
        payload: {inventoryKitItemId }
    }
}

export const updateSelectedKitItems = (inventoryKitItems) => {
    return {
      type: actions.UPDATE_SELECTED_INVENTORY_KIT_ITEMS,
      payload: { inventoryKitItems },
    };
  };

  export const editKitItem = (inventoryKitItems) =>{
    return{
        type: actions.EDIT_INVENTORY_KIT_ITEM,
        payload: {inventoryKitItems}
    }
}