import Parse from "parse";
import { printLog } from "./loghelper";

const getFeeTemplateItemsForTeacherId = (feeTemplateId) => {
  printLog("HL: freeTemplateItem_helper : getFeeTemplateItemsForTeacherId")

  try {
    return new Promise((resolve, reject) => {
      var FeeTemplateItem = Parse.Object.extend("FeeTemplateItem");
      var query = new Parse.Query(FeeTemplateItem);
      query.equalTo("feeTemplateId", feeTemplateId);
      query.notEqualTo("isDeleted", true);
      query.ascending("slno");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) {}
};

export const gettemplateItemsForIds = (ids) => {
  printLog("HL: freeTemplateItem_helper : gettemplateItemsForIds")

  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("FeeTemplateItem");
      query.containedIn("feeTemplateId", ids);
      query.limit(1000)
      query.ascending("slno");
      query.notEqualTo("isDeleted", true);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export { getFeeTemplateItemsForTeacherId };
