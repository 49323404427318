import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil"

const initialState = {
  groupmaps: [],
  resultGroupMapsAfterSave: null,
  loadingGroupMap: undefined,
  programGroupmaps: [],
  tripGroupmaps:[],
};

const GroupMap = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_GROUP_MAP:
      var groupmaps = [...state.groupmaps];
      var temp = state.groupmaps.filter(
        (groupmap) => groupmap.id === action.payload.id
      );
      if (temp.length > 0) {
        var index = groupmaps.indexOf(temp[0]);
        groupmaps[index] = action.payload;
        state = { ...state, groupmaps: groupmaps };
      } else {
        groupmaps.push(action.payload);
        state = { ...state, groupmaps: groupmaps };
      }
      break;
    case actions.GET_GROUP_MAPS_FOR_GROUP_ID:
      state = { ...state, groupmaps: [] };
      break;
    // case actions.UPDATE_GROUP_MAPS:
    //   state = { ...state, groupmaps: action.payload.groupmaps };
    //   break;
    case actions.UPDATE_GROUP_MAPS:
      let updatedList = ReduxUtil.updateParseObjects([...state.groupmaps], action.payload.groupmaps, "isDeleted", false)
      state = { ...state, groupmaps: updatedList };

      break

    case actions.DELETE_GROUP_MAP:
      var groupmaps = state.groupmaps.filter(
        (groupmap) => groupmap.id !== action.payload.id
      );
      state = { ...state, groupmaps: groupmaps };
      break;
    case actions.UPDATE_RESULT_GROUP_MAPS:
      state = {
        ...state,
        resultGroupMapsAfterSave: action.payload.resultGroupMapsAfterSave,
      };
      break;
    
    case actions.UPDATE_GROUP_MAP_FOR_PROGRAM_GROUPIDS:
      state = {
        ...state,
        programGroupmaps: action.payload.programGroupmaps,
      };
      break;

      case actions.UPDATE_GROUP_MAP_FOR_TRIP_GROUPIDS:
        state = {
          ...state,
          tripGroupmaps: action.payload.tripGroupmaps,
        };
        break;

    case actions.SET_LOADING_GROUPMAPS:
      state = {
        ...state,
        loadingGroupMap: action.payload.boolValue,
      };
      break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default GroupMap;
