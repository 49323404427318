import React, { useEffect,useState} from 'react';
import { withNamespaces } from 'react-i18next';

import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Modal, ModalHeader, ModalBody, ModalFooter
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';


import Image from "../../../assets/images/growing_chart.svg";

import {
    setSelecetdSchool,
    getAllKids,
    getExamsForSchoolIds,
    getCoursesForOwnerIds,
    getCourseBatchesForCourseIds,
    getLearningCategoryForCourseId,
    getQuizLearningActivitiesForCourseId,
    getCoureseSubscriptions,
    getExamCourseSessionsForCourseId,
    getCourseBatchesForCourseId,
    updateSelectedCourse,
    
} from '../../../store/actions';

import CourseAssessments from '../CourseAssessments';


const RoyaltyReport = (props) => {

    const schoolId = props.schoolId

    
    const [reportData, setReportData] = useState(undefined);
    
    const [fromDate, setFromDate] = useState(new Date());
    const [toDate, setToDate] = useState(new Date());
    
    const [toDateError, setToDateError] = useState(false);
    const [fromDateError, setFromDateError] = useState(false);
    
    const [selectedMonths, setSelectedMonths] = useState([]);
    const [loadingState, setLoadingState] = useState(1);

    const [ownerIds, setOwnerIds] = useState([]);

    const titles = ["Student Name","Enrolled on", "Batch", "Number of assessments", "Attempted Assessments", "Cleared Assessments ","Details"];

    const [selectedCourse, setSelectedCourse] = useState(undefined);
    const [courseError, setCourseError] = useState(false);
    const [selectedCourseBatches, setSelectedCourseBatches] = useState([]);

    const [assessmentModelObject, setAssessmentModelObject] = useState(undefined);

    useEffect(() => {
        props.getAllKids(schoolId)
        props.getExamsForSchoolIds(schoolId)
    }, [schoolId]);
    
     useEffect(() => {

        var ids = [];
        if (schoolId === "franchise") {
            props.getCoursesForOwnerIds([Parse.User.current().attributes.franchiseId]);
        } else if (props.selectedSchool) {
            ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }


            if (ownerIds.length === ids.length) {

                var call = false;
                for (const i in ownerIds) {
                    if (ownerIds[i] !== ids[i]) {
                        call = true;
                    }
                }
                if (call) {
                    props.getCoursesForOwnerIds(ids);
                }

            } else {
                setOwnerIds(ids);
                props.getCoursesForOwnerIds(ids);
            }
        }
    }, [props.selectedSchool]);
    
    
    useEffect(() => {
        // setFromDate(props.fromDate)
    }, [props.fromDate]);

    useEffect(() => {
        // setToDate(props.toDate);
    }, [props.toDate]);

    useEffect(() => {
        if (props.onClickApply) {
            handelDateChange();
        }
    }, [props.onClickApply]);
   


    const handelDateChange = () => {
        setLoadingState(2);
        getReortReportSessions();
        
    }
    
    

    const getDayBetweenDates = (d1, d2) => {
        var diff = d2 - d1;
        return Math.floor(diff / 86400000);

    }
   

    const getReortReportSessions = () => {
        // setSelectedCourseBatches([]);

        if (!fromDate || !toDate) {
            return;
        }

        
        var startDate = new Date(fromDate.getFullYear(), fromDate.getMonth(), fromDate.getDate());
        var endDate = new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999);

        let loop = true
        let newDate = new Date(startDate.getFullYear(), startDate.getMonth() - 1, 1);
        var newEndDate = new Date(endDate.getFullYear(), endDate.getMonth(), 0, 23, 59);
        let dateList = [];
        while (loop) {
            if (newDate < newEndDate) {
              
                newDate = new Date(newDate.getFullYear(), newDate.getMonth() + 1, 1);
                let dateString = Moment(newDate).format('MMM YYYY')
                dateList.push({ date: newDate, dateString: dateString })
            } else {
                loop = false;
            }
        }
        setSelectedMonths(dateList);

        let days = getDayBetweenDates(startDate,endDate, );

        props.getLearningCategoryForCourseId(selectedCourse.id);
        props.getQuizLearningActivitiesForCourseId(selectedCourse.id);
        props.getCoureseSubscriptions(selectedCourse.id);
        props.getExamCourseSessionsForCourseId(selectedCourse.id);
        //props.getCourseBatchesForCourseId(selectedCourse.id)

        let query = new Parse.Query("CourseBatch");
        query.notEqualTo('isDeleted', true);
        query.equalTo('courseId', selectedCourse.id);
        query.ascending("startDate");
        query.limit(10000)
        query.find().then((cbs) => {
            
            // console.log(cbs)
            if (!cbs || cbs.length === 0) {
                setLoadingState(5)
            } else {
                setSelectedCourseBatches(cbs)
                prepareDate(cbs)
            }
        });    
    }


    useEffect(() => {

        // console.log("props.initialLoding", props.initialLoding);
        // console.log("props.courseSubscriptions", props.courseSubscriptions);
        // console.log("props.kids.length", props.kids.length);
        
        if (props.initialLoding || !props.kids || props.kids.length === 0) {
            //loading
            // console.log("props use effect", "loading");
        } else {
            // stop loading

            // console.log("props use effect", "loading done");
            let list = [];

            if (props.courseSubscriptions && props.courseSubscriptions.length > 0) {
                for (const cs of props.courseSubscriptions) {
                    let kids = props.kids.filter((k) => cs.attributes.kidId === k.id)
                    // console.log(cs.attributes.batchId);
                    let courseBatch = selectedCourseBatches &&  selectedCourseBatches.filter((cb) => cs.attributes.batchId === cb.id)
                    // console.log(courseBatch);
                    let kidSessions = []
                    if (props.allCourseQuizSessions && props.allCourseQuizSessions.length > 0) {
                        kidSessions = props.allCourseQuizSessions.filter((k) => cs.attributes.kidId === k.attributes.kidId);
                    }

                    list.push({
                        id:cs.id,
                        courseSubscriptions: cs,
                        kid:kids.length > 0 ? kids[0] : undefined,
                        courseBatch: courseBatch.length > 0 ? courseBatch[0] : undefined,
                        quizSessions: kidSessions,
                        sessionresults: getAssessmentResults(kidSessions),
                        

                    })

                }

                // console.log(list);
                setReportData(list);
                setLoadingState(3);

            } else {
                // no subscriptions
            }
        }
    }, [props.courseSubscriptions, props.initialLoding,props.kids,selectedCourseBatches,props.allCourseQuizSessions]);
    
    

    const getAssessmentResults = (quizSessions) => {

        let cleardCount = 0;
        let resultList=[]
        if (quizSessions && props.exams) {
            
            for (const qs of quizSessions) {
                let exams = props.exams.filter((ex) => qs.attributes.quizId === ex.id)
                if (exams.length > 0) {
                    
                    let passPercentage = exams[0].attributes.passPercentage;
                    let scored = session.attributes.totalMarksObtained + "/" + totalMarks;
                    let scoredPercentage = (session.attributes.totalMarksObtained * 100) / totalMarks;
                    let isPass = true;
                    if (scoredPercentage >= passPercentage) {
                        cleardCount = cleardCount + 1;
                        isPass = true;
                    }

                    resultList.push({
                        passPercentage: passPercentage,
                        scored: scored,
                        scoredPercentage: scoredPercentage,
                        isPass:isPass
                    })


                }
            }
        }
        let res = { cleardCount: cleardCount, resultList: resultList };
        return res;
    }

    const prepareDate = (schools) => {
        let dataList = [];
        
        for (const school of schools) {
            
        }
        setReportData(dataList);
        setLoadingState(3)
    }

   

    const getStringFromList = (list) => {
        let str = "";
        if (list) {
            for (const s of list) {
                str = s + ",";
            }
        }
        return str;
            
    }

  


   

    const onClickDownload = () => {
        let sDate = Moment(fromDate).format('DD-MM-YYYY')
        let eDate = Moment(fromDate).format('DD-MM-YYYY')
            
        let finalData = [];
        let fileName = `Assessment_report_${sDate}_${eDate}.xlsx`;


        finalData.push([`Assessment report ${selectedCourse.attributes.name}`]);
        finalData.push([`Selected Dates : ${sDate} to ${eDate}`,]);

        finalData.push([]);
        finalData.push(titles);


        // finalData = finalData.concat(reportData);

        for (const rd of reportData) {
            finalData.push([
                rd.kid ? rd.kid.attributes.Name : "",
                getDateTimeStringForDate(rd.courseSubscriptions.attributes.createdAt),
                getNameFromBatch(rd.courseBatch),
                props.quizLearningActivities ? props.quizLearningActivities.length : 0,
                rd.quizSessions ? rd.quizSessions.length : 0,
                rd.sessionresults.cleardCount,
            ])
        }

         

        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            
            XLSX.utils.book_append_sheet(wb, ws, "all");
            
            XLSX.writeFile(wb, fileName);
        } else {
            let wb = XLSX.utils.book_new();
            let schoolData = [];
            schoolData = finalData;
            

            let ws = XLSX.utils.aoa_to_sheet(schoolData);
            XLSX.utils.book_append_sheet(wb, ws, "all");
            XLSX.writeFile(wb, "HK.xlsx");
        }

       
    }
    

    
    const onClickBackButton = () => {
        window.history.back();
    }

    const getIndexList = () => {
        let list = [];
        for (const i in reportData) {
            list.push(i);
        }
        return list;
    }

    const getNameFromBatch = (batch) => {
        if (batch) {
           return getTimeStringForDate(batch.attributes.startDate) + " to " + getTimeStringForDate(batch.attributes.endDate)
        }
        return "";
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY');
    }
    function getDateTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }

    

    return (
        <React.Fragment>
            { <CardTitle>Course Assessment Analytics </CardTitle>}
            {loadingState > 0 &&
               
                <Row className=" bg-light ml-2">
            
                   

                        <Col lg={6}>

                            <div className="mt-4 mb-2 p-1">
                                <select className="form-control"
                                
                                    value={selectedCourse && selectedCourse.id}
                                    onChange={(e) => {
                                        e.target.value;
                                        let temp = props.courses && props.courses.filter((c) => 
                                            c.id === e.target.value
                                        )
                                        setSelectedCourse(temp[0])
                                        if (courseError) {
                                            setCourseError(false);
                                        }
                                    }}
                                >
                                    <option disabled selected value>
                                        Pick Course
                                    </option>
                                    {props.courses && props.courses.map((c) => (
                                        <option key={c.id} value={c.id}>{c.attributes.name}</option>
                                    ))}
                                </select>

                            {courseError&&<p className='text-danger'>Pick Course</p>}
                            </div>
                        </Col>
                        
                        <Col lg={3} >
                            <div className="mt-4 mb-2 p-1">
                                <button
                                    disabled={loadingState === 2}
                                    onClick={(e, v) => {
                                        let next = true;
                                        if (!selectedCourse) {
                                            setCourseError(true);
                                            next = false;
                                        }
                                       
                                        if (next) {
                                            handelDateChange();
                                        }
                        
                                    }}
                                    className="w-md  btn btn-primary"
                                >
                                    {props.t("Apply")}
                                </button>
                    
                            </div>
                        </Col>
                    
                </Row>
                
            }
            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "Setting up..." : "Preparing report, it may take more time than anticipated"}</Label>
                    </div>
                </Col>
            }

                
            <Element name={"scrollPossition"} >
                <div></div>
            </Element>

                
            {loadingState === 5 && <Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img mt-8">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{'No data avilable between ' + selectedMonths[0].dateString + " and " + selectedMonths[selectedMonths.length - 1].dateString}</h3>
                    <p>{'Plese select other dates '}.</p>
                    
                    
                </CardBody>
            </Card>}

           
           
            {loadingState == 3 && selectedMonths && selectedMonths.length > 0  && <Col xs="12">
                <div className="mt-2 mb-2 p-1 text-center ">
                    <button
                        onClick={() => {
                            if (selectedMonths && selectedMonths.length > 0) {
                                onClickDownload();
                            }
                                                    
                        }}
                        className="w-md  btn btn-primary"
                    >
                        <i className='bx bx-download font-size-16 align-middle mr-2'></i>
                        {props.t("Download Report")}
                    </button>   

                </div>
             
            </Col>
            }
            

            {loadingState == 3 && selectedMonths && selectedMonths.length > 0 && <Col xs="12">
                <div className="table-rep-plugin">
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" className="table table-striped table-bordered">
                            <Thead>
                                <Tr>
                                        

                                    <Th>#</Th>
                                    {titles.map((t, k) => (
                                        <Th key={k} data-priority={k}>{t}</Th>
                                    ))}
                                                           
                                </Tr>
                            </Thead>
                            <Tbody>
                                       
                                {
                                    reportData && getIndexList().map((i, key) => (
                                        <Tr key={key}>
                                            <Th >{parseInt(i) + 1}</Th>

                                            <Th >{reportData[i].kid ? reportData[i].kid.attributes.Name : ""}</Th>
                                            <Th>{getDateTimeStringForDate(reportData[i].courseSubscriptions.attributes.createdAt)}</Th>

                                            <Th>{getNameFromBatch(reportData[i].courseBatch)}</Th>
                                            <Th>{props.quizLearningActivities ? props.quizLearningActivities.length : 0}</Th>
                                            <Th>{reportData[i].quizSessions ? reportData[i].quizSessions.length : 0}</Th>
                                            <Th>{ reportData[i].sessionresults.cleardCount}</Th>
                                            {/* <Th></Th> */}
                                            <Th>
                                                {reportData[i].courseSubscriptions.attributes.batchId ? <Link
                                                    className='btn btn-sm btn-primary'
                                                    to={"/course-assessments/"+schoolId+"/"+selectedCourse.id+"/"+reportData[i].courseSubscriptions.id+"/"+reportData[i].courseSubscriptions.attributes.batchId+"/"+reportData[i].kid.id}
                                                >
                                                    View
                                                </Link>
                                                :
                                                <button className='btn btn-sm btn-primary' disabled={true}>
                                                       View
                                                </button>
                                                }
                                                
                                                {/* <button
                                                    className='btn btn-sm btn-primary'
                                                    onClick={() => {
                                                        setAssessmentModelObject({
                                                            courseId: selectedCourse.id,
                                                            kidId:reportData[i].kid.id
                                                        })
                                                }}
                                                >
                                                    View 
                                                </button> */}
                                                
                                            </Th>
                                            {/* {reportData[i].map((e, key2) => (
                                                <Td key={key2}>{e}</Td>
                                            ))} */}
                                                    
                                        </Tr>
                                    ))
                                }
                                                      
                            </Tbody>
                        </Table>
                    </div>
                </div>
                
            </Col>
            }

            {assessmentModelObject && <Modal
                isOpen={assessmentModelObject?true:false}
                fade={false}
                className="modal-dialog"
                size="xl"
            >



                <ModalHeader className="bg-light" toggle={() => {
                    setAssessmentModelObject(undefined);
                }}>
                    
                </ModalHeader>
                <ModalBody
                    style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
                >



                    <CourseAssessments
                        courseId={assessmentModelObject.courseId}
                        kidId={assessmentModelObject.kidId}
                        schoolId={props.schoolId}
                    ></CourseAssessments>

                   
                </ModalBody>
                
            </Modal>}

            <div style={{ height: "400px" }}></div>
        </React.Fragment>
    );
}
const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { courses,selectedCourse } = state.Course;
    const { courseBatch } = state.CourseBatch;

    const { learningCategories, categoryLoadingState } = state.LearningCategory;
    const { quizLearningActivities } = state.LearningActivity;
    const { courseSubscriptions, initialLoding, addState } = state.CourseSubscription;
    const { kids } = state.Kid;
    const { allCourseQuizSessions } = state.QuizSession;

    return {
        selectedSchool, courses,selectedCourse,
        courseBatch,
        learningCategories, categoryLoadingState,
        quizLearningActivities,
        courseSubscriptions, initialLoding, addState,
        kids,
        allCourseQuizSessions,
    };
};

export default withNamespaces()(
  withRouter(
      connect(mapStatetoProps, {
          setSelecetdSchool,
          getAllKids,
          getExamsForSchoolIds,
          getCoursesForOwnerIds,
          getCourseBatchesForCourseIds,
          getLearningCategoryForCourseId,
          getQuizLearningActivitiesForCourseId,
          getCoureseSubscriptions,
          getExamCourseSessionsForCourseId,
          getCourseBatchesForCourseId,
          updateSelectedCourse,
      })(RoyaltyReport)
  )
);
