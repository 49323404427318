import Parse from "parse";
import { printLog } from "./loghelper";

export const getAllMTDForIds = (ids) => {
  printLog("HL: moneyTransactionDetails_helper : getAllMTDForIds")

  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("MoneyTransactionDetails");
      query.containedIn("itemId", ids);
      query.limit(1000);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};
