import React, { useState } from 'react';
import {
    Dropdown,
    DropdownToggle,
    DropdownMenu,
    DropdownItem
} from "reactstrap";

//i18n
import i18n from '../../../i18n';
import { withNamespaces } from 'react-i18next';

// falgs
import usFlag from "../../../assets/images/flags/us.jpg";
import spain from "../../../assets/images/flags/spain.jpg";
import germany from "../../../assets/images/flags/germany.jpg";
import italy from "../../../assets/images/flags/italy.jpg";
import russia from "../../../assets/images/flags/russia.jpg";
import toastr from "toastr";

const LanguageDropdown = (props) => {
    // Declare a new state variable, which we'll call "menu"
    const [menu, setMenu] = useState(false);


    const handleCopy = (text) => {
        navigator.clipboard.writeText(text);
        toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
    };

    return (
        <React.Fragment>
            <Dropdown
                isOpen={menu}
                toggle={() => setMenu(!menu)}
                className="d-inline-block"
            >
                <DropdownToggle
                    className="btn header-item noti-icon waves-effect"
                    tag="button"
                    id="page-header-notifications-dropdown"
                >
                    <i className="bx bx-support  text-danger"></i>
                </DropdownToggle>

                <DropdownMenu
                    className="dropdown-menu-center" // Custom class to center align the dropdown
                    style={{ margin: 0 }} // Ensure the menu opens directly below
                >
                    <DropdownItem
                        tag="a"
                        href="#"
                        className="notify-item d-flex align-items-center"
                    >
                        <i className="bx bx-phone bx-sm me-2"></i>
                        <span className="align-middle">+91 7676548853</span>
                    </DropdownItem>
                    <DropdownItem
                        tag="a"
                        href="mailto:hello@liltriangle.com?subject=Support Inquiry"
                        className="notify-item d-flex align-items-center"
                    >
                        <i className="bx bx-envelope bx-sm me-2"></i>
                        <span className="align-middle">hello@liltriangle.com</span>
                    </DropdownItem>
                    <DropdownItem
                        tag="a"
                        href="#"
                        onClick={() => {
                            window.open("/support/", "_blank");
                        }}
                        className="notify-item d-flex align-items-center"
                    >
                        <i className="bx bx-support bx-sm me-2"></i>
                        <span className="align-middle">Support Query</span>
                    </DropdownItem>
                </DropdownMenu>
            </Dropdown>

        </React.Fragment>
    );
}

export default withNamespaces()(LanguageDropdown);