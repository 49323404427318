import React, { useEffect, useState } from 'react';
import { Container, Card, CardBody, Row, Col, Table, UncontrolledTooltip } from "reactstrap";
import Image from "../../assets/images/book_lover.svg";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import { Link } from "react-router-dom";
import Moment from 'moment';
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import Parse from 'parse';

import * as CommonFunctions from "../../components/Common/CommonFunctions";
import {
    setSelecetdSchool,
    getCoursesForOwnerIds,
    deleteCourse,
} from "../../store/actions";

import TopBar from './TopBar';


const Index = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/allcourses/:schoolId',
        exact: true,
        strict: false,

    });

    const schoolId = match ? match.params.schoolId : "";
    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const [ownerIds, setOwnerIds] = useState([]);
    const [deleteCourse,setDeleteCourse]= useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);

    useEffect(() => {

        var ids = [];
        if (schoolId === "franchise") {
            props.getCoursesForOwnerIds([Parse.User.current().attributes.franchiseId]);
        } else if (props.selectedSchool) {
            ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }


            if (ownerIds.length === ids.length) {

                var call = false;
                for (const i in ownerIds) {
                    if (ownerIds[i] !== ids[i]) {
                        call = true;
                    }
                }
                if (call) {
                    props.getCoursesForOwnerIds(ids);
                }

            } else {
                setOwnerIds(ids);
                props.getCoursesForOwnerIds(ids);
            }
        }
    }, [props.selectedSchool]);

    function getOwnerName(course) {

        var teacherName = ""
        if (props.teachers && props.teachers.length > 0 && course.attributes.createdBy) {
            var others = props.teachers.filter(
                (teacher) =>
                    teacher.id === course.attributes.createdBy
            );
            if (others && others.length > 0) {
                teacherName = others[0].attributes.Name
            }
        }

        var on = getTimeStringForDate(course.attributes.createdAt);

        var createdFor = course.attributes.type;
        var ownerId = course.attributes.ownerId;

        if (props.selectedSchool && (!createdFor || createdFor.length === 0)) {
            if (ownerId === props.selectedSchool.id) {
                createdFor = props.t('School');
            } else if (ownerId === props.selectedSchool.attributes.instituteId) {
                createdFor = props.t('Institute');
            } else if (ownerId === props.selectedSchool.attributes.franchiseId) {
                createdFor = props.t('Franchise');
            }
        }

        if (teacherName && teacherName.length > 0) {
            return "Created By " + teacherName + " on " + on;
        } else {
            return "Created on " + on;
        }
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY.');
    }

    const handelAddCouse = () => {
        props.history.push("/course-content/" + schoolId + "/addCourse");
    }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>

                    <TopBar title={"COURSE"}
                        buttonName={"Add Course"}
                        backButton={false}
                        hideButton={false}
                        onClickAdd={handelAddCouse}
                        reorderButton={false}
                    />
                    {(!props.courses || props.courses.length === 0) ? <Card>
                        <CardBody className="text-center" >
                            <Row className="justify-content-center">
                                <Col sm={4}>
                                    <div className="maintenance-img">
                                        <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                                    </div>
                                </Col>
                            </Row>
                            <h3 className="mt-5">{props.t('Set up Course ')}</h3>
                            <p>{props.t('Take your learning online just in few steps')}.</p>

                            <button type="button"
                                className="btn btn-primary "
                                onClick={() => {
                                    props.history.push("/course-content/" + schoolId + "/addCourse");
                                }}  >
                                {props.t('Start')}
                            </button>
                        </CardBody>
                    </Card>
                        :
                        <Row >
                            <Col lg="12">
                                <div className="">
                                    <div className="table-responsive">
                                        <Table className="project-list-table table-nowrap table-centered table-borderless">
                                            <thead>
                                                <tr>
                                                    <th className="col-1" scope="col" style={{ width: "100px" }}>#</th>
                                                    <th className="col-9" scope="col">Name</th>
                                                    <th className="col-2" scope="col" align="right">Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {props.courses.map((course, key) =>
                                                    <tr key={course.id} >
                                                        <td><img src={Image} alt="" className="avatar-sm" /></td>
                                                        {

                                                            <td 
                                                                style={{ cursor: "pointer" }}
                                                                // onClick={() => {
                                                                //     props.onSelectCourse(course.id);
                                                                //     props.history.push("/course-participants/" + schoolId + "/"+course.id);
                                                                //     props.history.push("/lms-syllabus/" + schoolId + "/" + course.id);
                                                                // }}
                                                            >
                                                                <h5 className="text-truncate font-size-14"><Link to={"/course-content/" + schoolId + "/" + course.id} className="text-dark">{course.attributes.name}</Link></h5>
                                                                <p className="text-muted mb-0">{getOwnerName(course)}</p>
                                                                <p className="text-success">{course.attributes.state}</p>

                                                                {course.attributes.state === "Published" && <button
                                                                    className="btn btn-primary btn-sm"
                                                                    onClick={() => {

                                                                        props.history.push("/course-batch/" + schoolId + "/" + course.id);

                                                                    }}>

                                                                    Batches

                                                                </button>}

                                                                {course.attributes.state === "Published" && <button
                                                                    className="btn btn-primary btn-sm ml-2"
                                                                    onClick={() => {

                                                                        props.history.push("/course-participants/" + schoolId + "/" + course.id);

                                                                    }}>

                                                                    Participants

                                                                </button>}
                                                                {course.attributes.state === "Published" && <button
                                                                    className="btn btn-primary btn-sm ml-2"
                                                                    onClick={() => {

                                                                        props.history.push("/course-requests/" + schoolId + "/" + course.id);

                                                                    }}>

                                                                    Course Requests

                                                                </button>}

                                                                {course.attributes.state === "Published" && <button
                                                                    className="btn btn-primary btn-sm ml-2"
                                                                    onClick={() => {

                                                                        props.history.push("/course-feedback-forms/" + schoolId + "/" + course.id);

                                                                    }}>

                                                                    Feedback Forms

                                                                </button>}
                                                            </td>
                                                        }
                                                        <td>
                                                            {

                                                                <ul className="list-inline font-size-20 contact-links mb-0 float-right">

                                                                    {/* {CommonFunctions.canEdit(course, props.userTeacher) &&
                                                                        <li className="list-inline-item px-2"
                                                                            style={{ cursor: "pointer" }}
                                                                            onClick={() => {
                                                                                props.history.push("/course-content/" + schoolId + "/"+course.id);
                                                                            }}
                                                                            id={"sub-edit" + key}>
                                                                            <i className="bx bx-edit-alt"></i>
                                                                            <UncontrolledTooltip placement="top" target={"sub-edit" + key}>
                                                                                {props.t('Edit')}
                                                                            </UncontrolledTooltip>
                                                                
                                                                        </li>} */}

                                                                    {CommonFunctions.canEdit(course, props.userTeacher) && <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setDeleteCourse(course);
                                                                        }}
                                                                        id={"sub-delete" + key}>
                                                                        <i className="bx bx-trash text-danger"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                            {props.t('Delete')}
                                                                        </UncontrolledTooltip>

                                                                    </li>

                                                                    }
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            // props.onSelectCourse(course.id);
                                                                            props.history.push("/course-content/" + schoolId + "/" + course.id);
                                                                        }}
                                                                        id={"sub-cyc" + key}>
                                                                        <i className="bx bx-right-arrow-circle"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                            {props.t('Details')}
                                                                        </UncontrolledTooltip>
                                                                    </li>
                                                                </ul>
                                                            }
                                                        </td>
                                                    </tr>
                                                )}
                                            </tbody>
                                        </Table>
                                    </div>
                                </div>
                            </Col>
                        </Row>

                    }

                </Container>
            </div>
            { deleteCourse ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        props.deleteCourse(deleteCourse);
                        setDeleteCourse(undefined);
                        setsuccess_dlg(true); 
                    }}
                    onCancel={() =>{
                        setDeleteCourse(undefined);
                        setsuccess_dlg(false);
                        
                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {props.t('Your Course has been deleted.')}
                </SweetAlert>
            ) : null}
        </React.Fragment>
    );
}


const mapStatetoProps = (state) => {
    const { userTeacher } = state.Login;
    const { selectedSchool } = state.School;
    const { courses } = state.Course;
    const { teachers } = state.Teacher;

    return { userTeacher, selectedSchool, courses, teachers };
};


export default connect(mapStatetoProps, {
    setSelecetdSchool,
    getCoursesForOwnerIds,
    deleteCourse,
})(withNamespaces()(Index));
