import Parse from "parse";

const getPurchaseOrderForOwnerId = (ownerId, ownerType, updatedDate,isLocal,) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryPurchaseOrder = Parse.Object.extend("InventoryPurchaseOrders");
        var query = new Parse.Query(InventoryPurchaseOrder);
        query.equalTo("ownerId", ownerId);
        query.equalTo("ownerType", ownerType)
  
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
       } else {
         query.notEqualTo("isDeleted", true);
       }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
  
        query.descending("createdAt");
        query.find().then(
          (result) => {
           resolve(result); 
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };

  const editInventoryPurchaseOrder = (inventoryPurchaseOrder) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryPurchaseOrder.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) {}
  };

  const deleteInventoryPurchaseOrderFromParse = (inventoryPurchaseOrder) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryPurchaseOrder.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            inventoryPurchaseOrder.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            inventoryPurchaseOrder.set("deletedBy", Parse.User.current().id);
        }
  
        inventoryPurchaseOrder.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("error");
          }
        );
      });
    } catch (error) {}
  };
  
  const getInventoryPurchaseOrderFromLocal = (inventoryPurchaseOrderId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryPurchaseOrder = Parse.Object.extend("InventoryPurchaseOrders");
        var query = new Parse.Query(InventoryPurchaseOrder);
        query.fromLocalDatastore();
        query.get(inventoryPurchaseOrderId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  const getInventoryPurchaseOrderFromParse = (inventoryPurchaseOrderId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryPurchaseOrder = Parse.Object.extend("InventoryPurchaseOrders");
        var query = new Parse.Query(InventoryPurchaseOrder);
        query.get(inventoryPurchaseOrderId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };
  
  export { 
    getPurchaseOrderForOwnerId,
    editInventoryPurchaseOrder,
    deleteInventoryPurchaseOrderFromParse,
    getInventoryPurchaseOrderFromLocal,
    getInventoryPurchaseOrderFromParse
};