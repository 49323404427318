import React, { useEffect, useState } from "react";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import {
  Col,
  Row,
  Card,
  CardBody,
  CardFooter,
  UncontrolledTooltip,
  Label,
} from "reactstrap";

//import kidsView
import CardKidView from "./cardKidView";
import ListKidView from "./listKidView";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import * as Constants from "../../Constents";

import {
  getParentsForFamilyIds,
  sendInvite,
} from "../../store/actions";
import Image from "../../assets/images/getStartedImages/studentInitial.svg";

const ProcessView = (props) => {
  const [users, usersOps] = useState([]);
  const [kidsList, kidsListOps] = useState([]);
  const [familyIds, setFamilyIds] = useState([]);
  const [showIDCard, setShowIdCard] = useState(false); 
  const [showCertificate, setShowCertificate] = useState(false);

  useEffect(() => { 
    if (props.selectedSchool && (!props.selectedSchool.attributes.franchiseId || props.selectedSchool.attributes.franchiseId.length == 0)) {
      setShowIdCard(true)
    }
    let ids=['H3yHL6LlAa']
    if (props.selectedSchool && ids.includes( props.selectedSchool.attributes.instituteId)) {
      setShowCertificate(true);
    }
  }, [props.selectedSchool]);
  
  useEffect(() => {
    if (props.franchise) {
      if (props.franchise.attributes.features &&
        props.franchise.attributes.features.length > Constants.FRANCHISE_ID_CARD_DISABLE_INDEX &&
        props.franchise.attributes.features[Constants.FRANCHISE_ID_CARD_DISABLE_INDEX] > 0) {
        setShowIdCard(false)
      }
      else if (!showIDCard) {
        setShowIdCard(true)
      }
    }
   
  }, [props.franchise]);


  useEffect(() => {
    usersOps([]);
    let kidsList = [];
    let classDec = [];
    let ids = [];
    for (let i in props.classList) {
      classDec[props.classList[i].id] = props.classList[i];
    }
    for (let i in props.kids) {
      if (props.kids[i].attributes.Deleted) {
        continue;
      }
      let kid = {};
      kid.id = props.kids[i].id;
      kid.name = props.kids[i].attributes.Name;
      kid.tagId = props.kids[i].attributes.tagId;
      kid.schoolId = props.kids[i].attributes.SchoolID;
      kid.moveTo = false;
      kid.admissionNumber = props.kids[i].attributes.admissionNumber;
      kid.isDisabled = props.kids[i].attributes.isDisabled;

      kid.email = props.kids[i].attributes.email;
      kid.phoneNumber = props.kids[i].attributes.phoneNumber;

      kid.corporateId = props.kids[i].attributes.corporateId;


      let parents = props.parents ? props.parents.filter(
        (p) => p.attributes.FamilyID === props.kids[i].attributes.FamilyID) : [];

      for (let k in parents) {
        if (parents[k].attributes.Relation === "Mother") {
          kid.motherName = parents[k].attributes.Name;
          kid.motherNumber = parents[k].attributes.PhoneNumber;
          kid.motherEmail = parents[k].attributes.Email;
          kid.motherUsingApp =
            parents[k].attributes.isUsingApp;
          kid.motherId = parents[k].id;
        } else {
          kid.fatherName = parents[k].attributes.Name;
          kid.fatherNumber = parents[k].attributes.PhoneNumber;
          kid.fatherEmail = parents[k].attributes.EMail;
          kid.fatherUsingApp =
            parents[k].attributes.isUsingApp;
          kid.fatherId = parents[k].id;
        }
      }
      
      if (props.kids[i].attributes.Photo) {
        kid.img = props.kids[i].attributes.Photo._url;
      }
      
      kid.className = [];
      let className = classDec[props.kids[i].attributes.ClassRoomID]
        ? classDec[props.kids[i].attributes.ClassRoomID].attributes.Name
        : "";
      if (className) {
        let setColor = "primary";
        if (
          classDec[props.kids[i].attributes.ClassRoomID].attributes
            .classroomType === 2
        ) {
          setColor = "lilpink";
        }
        kid.className.push({
          name: className,
          color: setColor,
          id:props.kids[i].attributes.ClassRoomID
        });
      }
      if (props.kids[i].attributes.daycareClassId) {
        kid.className.push({
          name: classDec[props.kids[i].attributes.daycareClassId]
            ? classDec[props.kids[i].attributes.daycareClassId].attributes.Name
            : "",
          color: "lilpink",
          id:props.kids[i].attributes.daycareClassId
        });
      }

      kid.programs = getProgramsForKidIds(kid.id);
      
      if (props.activeClassId) {
        if (
          props.activeClassId === props.kids[i].attributes.ClassRoomID ||
          props.activeClassId === props.kids[i].attributes.daycareClassId
        ) {
          kidsList.push(kid);
        }
      } else {
        kidsList.push(kid);
      }

      if (props.kids[i].attributes.FamilyID &&
        props.kids[i].attributes.FamilyID.length > 0 &&
        !familyIds.includes(props.kids[i].attributes.FamilyID)) {
        if (!ids.includes(props.kids[i].attributes.FamilyID)) {
          ids.push(props.kids[i].attributes.FamilyID);
        }
      }
    }

    if (ids.length > 0) {
        let newIds = familyIds.concat(ids);
        setFamilyIds(newIds);
        props.getParentsForFamilyIds(ids,true);
      }
    usersOps(kidsList);
    kidsListOps(kidsList);

    
  }, [props.kids, props.parents, props.programs, props.programGroupmaps]);
  
  const getProgramsForKidIds = (kidId) => {
    // console.log("props.programGroupmaps",props.programGroupmaps)
    let temp = props.programGroupmaps && props.programGroupmaps.filter(
      (groupmap) => groupmap.attributes.userId === kidId
    );
    let programs = [];
    if (temp) {
      for (const gm of temp ){
        for (const pg of props.programs){
          if (gm.attributes.groupId == pg.attributes.groupId) {
            programs.push(pg);
          }
        }
      }
    }
    return programs;
  }

  const handelIDCard = (kid) => {
    setIdCardKid(kid)
  }

  const handelSendInvite = (kidId,parentId) => {
    var schoolName = "";
    if (props.selectedSchool) {
      schoolName = props.selectedSchool.attributes.Name;
    }
    props.sendInvite(
      kidId,
      schoolName,
      props.t("Invite sent successfully!"),
      "",
      parentId
    );
  };
  useEffect(() => {
    let tempValue = Object.assign([], kidsList);
    if (
      props.searchValue === undefined ||
      props.searchValue === null ||
      props.searchValue.length === 0
    ) {
      usersOps(tempValue);
    } else {
      let filter = tempValue.filter(function (item) {
        return item.name.toLowerCase().includes(props.searchValue.toLowerCase()) ||
          item.email && item.email.toLowerCase().includes(props.searchValue.toLowerCase()) ||
          item.phoneNumber && item.phoneNumber.toLowerCase().includes(props.searchValue.toLowerCase())
          ;
      });
      usersOps(filter);
    }
  }, [props.searchValue]);
  return (
    <React.Fragment>
      {props.displayType === Constants.DISPLAY_TYPE_GRID && props.showClassView && (
        <Col xl="3" sm="6">
          <Card className="text-center">
            <CardBody>
              <div
                className="avatar-xl mx-auto mb-4 mt-3"
                style={{ cursor: "pointer" }}
                onClick={() => {
                  props.handleAddStudentButtonClick()
                }}
              >
                <span
                  className={
                    "avatar-title rounded-circle bg-soft-primary text-primary h1"
                  }
                >
                  +
                </span>
              </div>
            </CardBody>
            <CardFooter className="bg-transparent border-top">
              <div className="contact-links d-flex font-size-20">
                <div className="flex-fill">
                  <Label  id="add_kid" onClick={() => {
                  props.handleAddStudentButtonClick()
                }}>
                    <h5> {props.t("Add Student")}</h5>
                    <UncontrolledTooltip placement="top" target={"add_kid"}>
                      {props.t("Add Student")}
                    </UncontrolledTooltip>
                  </Label>
                </div>
              </div>
            </CardFooter>
          </Card>
        </Col>
      )}

      {props.displayType == Constants.DISPLAY_TYPE_GRID && users.length === 0 && (
        <div className="text-center">
          <Row className="justify-content-center">
            <Col sm={4}>
              <div className="maintenance-img">
                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
              </div>
            </Col>
          </Row>
          <h3 className="mt-5">{"Start adding students for your School"}</h3>
          <p>{"Add a student just in few steps"}.</p>

          <button
            onClick={() => {
              props.handleAddStudentButtonClick();
            }}
            className="btn btn-primary"
          >
            Add Student
          </button>
        </div>
      )}
      {props.displayType == Constants.DISPLAY_TYPE_GRID &&
        <CardKidView
          onClickIDCard={handelIDCard}
          kids={users}
          showIDCard={showIDCard}
          showCertificate={showCertificate}
          corporatePartners={props.corporatePartners}
          activeClassId={props.activeClassId}
        />}
      {props.displayType == Constants.DISPLAY_TYPE_LIST && (
        <ListKidView
          showClassView={props.showClassView}
          sendInvite={handelSendInvite}
          moveToChildListOps={props.moveToChildListOps}
          showMoveToOptionsOps={props.showMoveToOptionsOps}
          kids={users}
          handleAddStudentButtonClick={() => {
            props.handleAddStudentButtonClick();
          }}
          offShowLoader={props.offShowLoader}
          selectedSchool={props.selectedSchool}
          showIDCard={showIDCard}
          showCertificate={showCertificate}
          corporatePartners={props.corporatePartners}
          activeClassId={props.activeClassId}
        />
      )}

     
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { parents } = state.Parent;
  const { selectedSchool } = state.School;
  const { kids } = state.Kid;
  const { franchise } = state.Franchise;
  const { corporatePartners } = state.CorporatePartners;
  return { parents, selectedSchool, kids,franchise ,corporatePartners};
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getParentsForFamilyIds,
      sendInvite,
    })(ProcessView)
  )
);
