import * as actions from "./actionTypes";

export const updateSchool = (school) => {
  return {
    type: actions.UPDATE_SCHOOL,
    payload: { school },
  };
};
export const getSchool = (id, isUpdate) => {
  return {
    type: actions.GET_SCHOOL,
    payload: { id, isUpdate },
  };
};

export const getRatingParams = (schoolObject) => {
  return {
    type: actions.GET_RATING_PARAMS,
    payload: { schoolObject },
  };
};

export const updateRatingParams = (ratingParams) => {
  return {
    type: actions.UPDATE_RATING_PARAMS,
    payload: { ratingParams },
  };
};

export const getSchoolRatings = (id, ratingType) => {
  return {
    type: actions.GET_SCHOOL_RATINGS,
    payload: { id, ratingType },
  };
};

export const updateSchoolRatings = (schoolRatings) => {
  return {
    type: actions.UPDATE_SCHOOL_RATINGS,
    payload: { schoolRatings },
  };
};

export const getPaymentOptionsForSchool = (id) => {
  return {
    type: actions.GET_PAYMENT_OPTIONS_FOR_SCHOOL,
    payload: { id },
  };
};
export const updatePaymentOptions = (paymentOptionsForSchool) => {
  return {
    type: actions.UPDATE_PAYMENT_OPTIONS,
    payload: { paymentOptionsForSchool },
  };
};

export const deleteSchool = (school) => {
  return {
    type: actions.DELETE_SCHOOL,
    payload: school,
  };
};
export const editSchool = (school, moveBack) => {
  return {
    type: actions.EDIT_SCHOOL,
    payload: { school, moveBack },
  };
};
export const editPaymentOptions = (paymentOptionsForSchool) => {
  return {
    type: actions.EDIT_PAYMENT_OPTIONS,
    payload: { paymentOptionsForSchool },
  };
};

export const setSelecetdSchool = (id) => {
  return {
    type: actions.SET_SELECTED_SCHOOL,
    payload: { id },
  };
};
export const updateSelecetdSchool = (school) => {
  return {
    type: actions.UPDATE_SELECTED_SCHOOL,
    payload: { school },
  };
};

export const getSchoolForInstitute = (id, type,userTeacher) => {
  return {
    type: actions.GET_SCHOOLS_FOR_INSTITUTE,
    payload: { id, type ,userTeacher},
  };
};

export const updateSchools = (schools) => {
  return {
    type: actions.UPDATE_SCHOOLS,
    payload: { schools },
  };
};

export const switchSchool = (school) => {
  return {
    type: actions.SWITCH_SCHOOL,
    payload: { school },
  };
};

export const getFranchiseDetails = (franchiseId) => {
  return {
    type: actions.GET_FRANCHISE_DETAILS,
    payload: { franchiseId },
  };
};
export const setFranchiseDetails = (obj) => {
  return {
    type: actions.SET_FRANCHISE_DETAILS,
    payload: { obj },
  };
};
export const getAllSchoolDetailsForFranchise = (franchiseId) => {
  return {
    type: actions.GET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE,
    payload: { franchiseId },
  };
};
export const setAllSchoolDetailsForFranchise = (schoolObjs) => {
  return {
    type: actions.SET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE,
    payload: { schoolObjs },
  };
};

export const getInstituteDeatilsForId = (instituteId) => {
  return {
    type: actions.GET_INSTITUTE_DETAILS_FOR_ID,
    payload: { instituteId },
  };
};

export const setInstituteDetailsForId = (instituteObj) => {
  return {
    type: actions.SET_INSTITUTE_DETAILS_FOR_ID,
    payload: { instituteObj },
  };
};
export const getAllSchoolDetailsForInstitute = (instituteId) => {
  return {
    type: actions.GET_ALL_SCHOOL_DETAILS_FOR_INSTITUTE,
    payload: { instituteId },
  };
};

export const getReceiptAddressForSchoolId = (schoolId) => {
  return {
    type: actions.GET_RECEIPT_ADDRESS_FOR_SCHOOL,
    payload: { schoolId },
  };
};

export const updateReceiptAddressForSchoolId = (receiptAddres) => {
  return {
    type: actions.UPDATE_RECEIPT_ADDRESS_FOR_SCHOOL,
    payload: { receiptAddres },
  };
};

export const setFetchStatus = (state) => {
  return {
    type: actions.SCHOOLS_FEATCH_STATUS,
    payload: { state },
  };
};

export const getSchoolAnalytics = (id,type) => {
  return {
    type: actions.GET_SCHOOL_ANALYTICS,
    payload: { id,type },
  };
};

export const updateSchoolAnalytics = (schoolAnalytics) => {
  return {
    type: actions.UPDATE_SCHOOL_ANALYTICS,
    payload: { schoolAnalytics },
  };
};

export const clearSchoolRatings = () => {
  return {
    type: actions.CLEAR_SCHOOL_RATINGS,
    payload: {}
  }
}

// *****************************ANALYTICS AND TOOLS**************************************//

export const getSchoolAnalyticsForSchoolObject = (id) => {
  return {
    type: actions.GET_SCHOOL_ANALYTICS_FOR_SCHOOL_OBJECT,
    payload: { id },
  };
};

export const updateSchoolAnalyticsForSchoolObject = (schoolAnalytic) => {
  return {
    type: actions.UPDATE_SCHOOL_ANALYTICS_FOR_SCHOOL_OBJECT,
    payload: { schoolAnalytic },
  };
};

export const getInstitutesForTools = () =>{
  return {
    type: actions.GET_INSTITUTES_FOR_TOOLS,
    payload: {},
  }
}

export const getSchoolsForTools = () =>{
  return {
    type: actions.GET_SCHOOLS_FOR_TOOLS,
    payload: {},
  }
}

export const updateInstituteForTools = (institutes) =>{
  return {
    type: actions.UPDATE_INSTITUTES_FOR_TOOLS,
    payload: {institutes}
  }
}

export const updateSchoolsForTools = (schools) =>{
  return {
    type: actions.UPDATE_SCHOOLS_FOR_TOOLS,
    payload: {schools}
  }
}