import * as actions from './actionTypes';

export const updateFile = (file) => {
    return {
        type: actions.UPDATE_FILE,
        payload: { file }
    }
}

export const deleteFile = (file) => {
    return {
        type: actions.DELETE_FILE,
        payload: { file }
    }
}

export const updateFiles = (files) => {
    return {
        type: actions.UPDATE_FILES,
        payload: { files }
    }
}

export const deleteFiles = (files) => {
    return {
        type: actions.DELETE_FILES,
        payload: { files }
    }
}


export const AddFile = (file,successMessage) => {
    return {
        type: actions.ADD_FILE,
        payload: { file,successMessage }
    }
}

export const getFile = (id) => {
    return {
        type: actions.GET_FILE,
        payload: { id }
    }
}

export const getFilesForFolderId = (folderId) => {
    return {
        type: actions.GET_FILES,
        payload: { folderId }
    }
}

export const clearFiles = (state) => {
    return {
      type: actions.CLEAR_FILES,
      payload: { state },
    };
};
export const setFileLoadingState = (state) => {
    return {
      type: actions.FILE_LOADING_STATE,
      payload: {  state},
    };
};
export const getFilesForCourseId = (courseId) => {
    return {
        type: actions.GET_FILES_FOR_COURSE_ID,
        payload: { courseId }
    }
}

export const setFileAddState = (state) => {
    return {
      type: actions.FILE_ADD_STATE,
      payload: {  state},
    };
};

export const getNoticeBoardFilesForSchoolId = (schoolId) => {
    return {
        type: actions.GET_NOTICE_BOARD_FILES_FOR_SCHOOL_ID,
        payload: { schoolId }
    }
}
 
export const updateNoticeBoardFiles = (files) => {
    return {
        type: actions.UPDATE_NOTICE_BOARD_FILES,
        payload: { files }
    }
}

export const deleteNoticeBoardFile = (file) => {
    return {
        type: actions.DELETE_NOTICE_BOARD_FILE,
        payload: { file }
    }
}