import { takeEvery,takeLeading, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as LearningSubcategoryHelper from "../../../helpers/ParseHelpers/learningSubcategory_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as LearningSubcategoryActions from "./actions";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getALearningSubcategory({ payload: { id } }) {
  try {
    const response = yield call( LearningSubcategoryHelper.getLearningSubcategoryFromParse, id,false);
    yield put(LearningSubcategoryActions.updateLearningSubcategory(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteALearningSubcategory({ payload: { learningSubcategory } }) {
  try {
    const response = yield call(
        LearningSubcategoryHelper.deleteLearningSubcategoryFromParse,
        learningSubcategory
    );
    if (response) {
      yield put(LearningSubcategoryActions.updateLearningSubcategory(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getLearningSubcategoryForcategoryId({ payload: { categoryId } }) {
    try {
      var key = Constants.LEARNING_SUBCATEGORY_UPDATED_TIME_FOR_CATEGORY_ID + "_" + categoryId;
      var updatedTime = localStorage.getItem(key)
      var newTime = new Date();
      yield put(LearningSubcategoryActions.setLearningSubcategoryState("request"))
      yield put(LearningSubcategoryActions.clearLearningSubcategory())
      const localResponse = yield call(LearningSubcategoryHelper.getLearningSubcategoryForcategoryId, categoryId, null, true);

      yield put(LearningSubcategoryActions.updateLearningSubcategories(localResponse));
      if (localResponse && localResponse.length > 0) {
        yield put(LearningSubcategoryActions.setLearningSubcategoryState("responce"))
      }
      

      const serverResponse = yield call(LearningSubcategoryHelper.getLearningSubcategoryForcategoryId, categoryId, updatedTime, false);
      localStorage.setItem(key,newTime)
      
      if (serverResponse && serverResponse.length > 0) {
        yield put(LearningSubcategoryActions.updateLearningSubcategories(serverResponse));
        yield put(LearningSubcategoryActions.setLearningSubcategoryState("responce"));
        Parse.Object.pinAll(serverResponse);
      } else if (!localResponse || localResponse.length === 0) {
        yield put(LearningSubcategoryActions.setLearningSubcategoryState("responce"));
      }
    } catch (error) {
      // yield put(apiError(error));
  
    }
}
  
function* addLearningSubcategory({ payload: { learningSubcategory } }) {
  try {
    
    //yield put(LearningSubcategoryActions.setLearningSubcategoryState("initial"));
    const response = yield call( LearningSubcategoryHelper.saveLearningSubcategory, learningSubcategory);
    if (response) {
      yield put(LearningSubcategoryActions.updateLearningSubcategory(response));
      response.pin();
    } else {
      
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* reorderLrarningSubcategory({ payload: { learningSubcategories,message} }) {
  try {
    const response = yield call(LearningSubcategoryHelper.saveLearningSubcategoryObjects, learningSubcategories);
    if (response) {
      if (message) {
        toastr.success(message, { timeOut: 3000 });
      }
      yield put(LearningSubcategoryActions.updateLearningSubcategories(response, "replace"));
      Parse.Object.pinAll(response);
    } else {
      
    }
  } catch (error) {
   console.log(error);
  }
}

export function* watchGetLearningSubcategory() {
  yield takeEvery(actionTypes.GET_LEARNING_SUBCATEGORY, getALearningSubcategory);
}
  
export function* watchDeleteLearningSubcategory() {
  yield takeEvery(actionTypes.DELETE_LEARNING_SUBCATEGORY, deleteALearningSubcategory);
}
  
export function* watchGetLearningSubcategoryForcategoryId() {
  yield takeEvery(actionTypes.GET_LEARNING_SUBCATEGORY_FOR_CATEGORY_ID, getLearningSubcategoryForcategoryId);
}
export function* watchAddLearningSubcategory() {
  yield takeEvery(actionTypes.ADD_LEARNING_SUBCATEGOERY, addLearningSubcategory);
}
  export function* watchReorderLrarningSubcategory() {
  yield takeLeading(actionTypes.LEARNING_SUBCATEGORY_REORDER, reorderLrarningSubcategory);
}
  
function* LearningSubcategorySaga() {
  yield all([
    fork(watchGetLearningSubcategory),
    fork(watchDeleteLearningSubcategory),
    fork(watchGetLearningSubcategoryForcategoryId),
    fork(watchAddLearningSubcategory),
    fork(watchReorderLrarningSubcategory),
  ]);
}
  export default LearningSubcategorySaga;
  