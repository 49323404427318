import * as actions from "./actionTypes";

const initialState = {
    courseSubscriptions: [], courseSubscriptionsForUser: [], initialLoding: true, addState: false, courseSubscriptionsForId:undefined
};

const CourseSubscription = (state = initialState, action) => {
    switch (action.type) {

        case actions.UPDATE_COURSE_SUBSCRIPTIONS:
            if (action.payload.replace) {
                state = { ...state, courseSubscriptions: action.payload.courseSubscriptions };
            } else {
                var courseSubscriptions = [...state.courseSubscriptions];
                if (courseSubscriptions.length > 0) {
                    for (const latest in action.payload.courseSubscriptions) {
                        var temp = courseSubscriptions.filter(
                            (course) =>
                                course.id === action.payload.courseSubscriptions[latest].id
                        );
                        if (temp.length > 0) {
                            if (action.payload.courseSubscriptions[latest].attributes.isDeleted) {

                                var others = courseSubscriptions.filter(
                                    (course) =>
                                        course.id !== action.payload.courseSubscriptions[latest].id
                                );

                                courseSubscriptions = others;
                            } else {
                                var index = courseSubscriptions.lastIndexOf(temp[0]);
                                courseSubscriptions[index] = action.payload.courseSubscriptions[latest];
                            }
                        } else {
                            courseSubscriptions.push(action.payload.courseSubscriptions[latest]);
                        }
                    }
                    state = { ...state, courseSubscriptions: courseSubscriptions, };
                } else {
                    var others = action.payload.courseSubscriptions.filter(
                        (course) => course.attributes.isDeleted !== true
                    );

                    state = { ...state, courseSubscriptions: others, };
                }
            }
            break;

        case actions.UPDATE_COURSE_SUBSCRIPTIONS_FOR_USER_ID:
            if (action.payload.replace) {
                state = { ...state, courseSubscriptionsForUser: action.payload.courseSubscriptionsForUser };
            } else {
                var courseSubscriptions = [...state.courseSubscriptionsForUser];
                if (courseSubscriptions.length > 0) {
                    for (const latest in action.payload.courseSubscriptionsForUser) {
                        var temp = courseSubscriptions.filter(
                            (course) =>
                                course.id === action.payload.courseSubscriptionsForUser[latest].id
                        );
                        if (temp.length > 0) {
                            if (action.payload.courseSubscriptionsForUser[latest].attributes.isDeleted) {

                                var others = courseSubscriptions.filter(
                                    (course) =>
                                        course.id !== action.payload.courseSubscriptionsForUser[latest].id
                                );

                                courseSubscriptions = others;
                            } else {
                                var index = courseSubscriptions.lastIndexOf(temp[0]);
                                courseSubscriptions[index] = action.payload.courseSubscriptionsForUser[latest];
                            }
                        } else {
                            courseSubscriptions.push(action.payload.courseSubscriptionsForUser[latest]);
                        }
                    }
                    state = { ...state, courseSubscriptionsForUser: courseSubscriptions, };
                } else {
                    var others = action.payload.courseSubscriptionsForUser.filter(
                        (course) => course.attributes.isDeleted !== true
                    );

                    state = { ...state, courseSubscriptionsForUser: others, };
                }
            }
            break;
        case actions.REMOVE_SUBSCRIPTION_FROM_COURSE:
            var courseSubscriptions = state.courseSubscriptions.filter(
                (course) => course.id !== action.payload.id
            );
            state = { ...state, courseSubscriptions: courseSubscriptions };
            break;


        case actions.UPDATE_COURSE_SUBSCRIPTION_INITIAL_LOADING:
            if (action.payload.state) {
                state = { ...state, courseSubscriptions: [], initialLoding: action.payload.state };
            } else {
                state = { ...state, initialLoding: action.payload.state };
            }
            break;
        case actions.UPDATE_COURSE_SUBSCRIPTION_ADD_STATE:
            state = { ...state, addState: action.payload.state };
            break;
        
        case actions.GET_COURSE_SUBSCRIPTIONS_FOR_USER_ID:
            state = { ...state, courseSubscriptionsForUser: [] };
            break;
        
        case actions.GET_SUBSCRIPTION_FOR_ID:
            state = { ...state, courseSubscriptionsForId:undefined };
            break;
        case actions.UPDATE_SUBSCRIPTION_FOR_ID:
            state = { ...state, courseSubscriptionsForId: action.payload.courseSubscriptionObject };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default CourseSubscription;
