

import React, { useState, useEffect } from "react";
import {
    Container,
} from "reactstrap";
import { matchPath } from "react-router";
import Parse from 'parse'
import { withNamespaces } from "react-i18next";
import AdmissionFormComponent from "../studentView/AdmissionFormComponent";

const AdmissionFormViewOnly = (props) => {
    const next = "NEXT";
    const previous = "PREVIOUS";


    const match = matchPath(props.history.location.pathname, {
        path: "/admission-form-view/:schoolId/:kidId",
        exact: true,
        strict: false,
    });


    const kidId = match.params.kidId;
    const schoolId = match.params.schoolId;

    const [schoolObject, setSchoolObject] = useState(undefined);
    const [kidObject, setKidObject] = useState(undefined);

    useEffect(() => {
        fetchSchoolFromServer(schoolId)
        fetchKidObjectFunction(kidId)
    }, [kidId, schoolId]);

    const fetchSchoolFromServer = (schoolId) => {
        Parse.Cloud.run("function_getSchoolDetailsForSchoolId", {
            schoolId: schoolId,
        }).then(
            (result) => {
                if (result != null) {
                    setSchoolObject(result);

                }
            },
            (error) => {
                console.log(error);
            }
        );
    };

    const fetchKidObjectFunction = (kidId) => {
        Parse.Cloud.run("function_kidDetailsForKidId", {
            kidId: kidId,
        }).then(
            (result) => {
                if (result != null) {
                    if (result.kid) {
                        setKidObject(result.kid)
                    }
                }
            },
            (error) => {
                console.log(error);
            }
        );
    }
    return (
        <React.Fragment>
            <Container fluid={true} className={""}>
                {schoolObject && kidObject && <AdmissionFormComponent schoolId={schoolObject.id} enquiryId={(kidObject && kidObject.attributes.enquiryId) ? kidObject.attributes.enquiryId : ""} kidId={kidObject ? kidObject.id : ""} hideButtons={true} hideEditButton={true}></AdmissionFormComponent>}
            </Container>
        </React.Fragment>
    );
};
export default withNamespaces()(AdmissionFormViewOnly);
