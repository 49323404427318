import * as actions from "./actionTypes";

export const getExamCourseSessionsForKidId = (courseId,kidId,batchId) => {
  return {
    type: actions.GET_COURSE_SESSIONS_FOR_KID,
    payload: { courseId,kidId ,batchId}
  }
}

export const updateExamCourseSessionsForKidId = (sessions) => {
  return {
    type: actions.UPDATE_COURSE_SESSIONS_FOR_KID,
    payload: { sessions }
  }
}

export const getExamCourseSessionsForCourseId = (courseId) => {
  return {
    type: actions.GET_COURSE_SESSIONS_FOR_COURSE_ID,
    payload: { courseId }
  }
}

export const updateExamCourseSessionsForCourseId = (sessions) => {
  return {
    type: actions.UPDATE_COURSE_SESSIONS_FOR_COURSE_ID,
    payload: { sessions }
  }
}