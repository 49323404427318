import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { ReactComponent as VerifiedLogo } from "../../assets/images/certificate/verifiedLogo.svg";
import { ReactComponent as Divider } from "../../assets/images/certificate/separator.svg";

import profileImg from "../../assets/images/users/userXYZ.png";
import * as WhiteLabel from "../../components/Common/WhiteLable";
import {
    Container,
    Row,
    Col,
    Card,
    CardBody,
    Table,
    Button,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Label,
} from "reactstrap";
import * as Constants from "../../Constents";
import Moment from "moment";

import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Parse from "parse";
import toastr from "toastr";
import logo from "../../assets/images/logo-dark.png";
import QRCode from 'qrcode'


const StudentQrCodeView = (props) => {

    const [QrCode, setQrCode] = useState(undefined);

    useEffect(() => {
        if (props.kid) {
            getQRCode(props.kid.id)
        }
    }, [props.kid])




    function getQRCode(kidId) {
        QRCode.toDataURL(kidId, { errorCorrectionLevel: 'H' }).then(url => {
            setQrCode(url)
        }).catch(err => {
            console.error(err)
        })
    }

    const getClassroomName = () => {
        if (props.classrooms && props.classrooms.length > 0 && props.kid) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === props.kid.attributes.ClassRoomID
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    return (

        <div style={{
            width: props.selectedPrintSetting.cardWidth,
            minHeight: props.selectedPrintSetting.cardHeight,

        }}
            className="ml-3 dont-cut-element-when-printing"
        >
            <div className="text-center" style={{ position: "relative", textAlign: "center", minHeight: props.selectedPrintSetting.qrCodeDivHeight, overflow: "hidden" }}>
                <div className="" style={{
                    position: "absolute", top: "50%"
                    , left: "50%", margin: "0",
                    transform: "translate(-50%, -50%)"
                }}>
                    <img className="align-middle" src={QrCode} alt="img" style={{ minHeight: props.selectedPrintSetting.qrCodeDivHeight }} />
                </div>

            </div>
            <hr></hr>
            <div className="mt-2 ml-2 mb-0 ml-5">
                <div className="m-0 p-0 text-left ml-3" lg={props.selectedPrintSetting.rowForKidDetails} md={props.selectedPrintSetting.rowForKidDetails} sm={props.selectedPrintSetting.rowForKidDetails} xs={props.selectedPrintSetting.rowForKidDetails}>
                    <div><label className="mb-0">{`Student - ${props.kid.attributes.Name}, ${getClassroomName()}`}</label></div>
                </div>
            </div>
        </div>

    );
};

const mapStatetoProps = (state) => { return {}; };

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {

        })(StudentQrCodeView)
    )
);