import * as actions from "./actionTypes";

export const updateLearningFilter = (learningFilter) => {
  return {
    type: actions.UPDATE_LEARNING_FILTER,
    payload: { learningFilter },
  };
};

export const deleteLearningFilter = (learningFilter) => {
  return {
    type: actions.DELETE_LEARNING_FILTER,
    payload: { learningFilter },
  };
};

export const updateLearningFilters = (learningFilters) => {
  return {
    type: actions.UPDATE_LEARNING_FILTERS,
    payload: { learningFilters },
  };
};

export const getLearningFilterForcurriculumId = (curriculumId) => {
  return {
    type: actions.GET_LEARNING_FILTER_FOR_CURRICULUM_ID,
    payload: { curriculumId },
  };
};
