import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as ChildAdditionalDetailHelper from "../../../helpers/ParseHelpers/childAdditionalDetails_helper";
import * as actionTypes from "./actionTypes";
import * as ChildAdditionalDetailActions from "./actions";
import * as Constants from "../../../Constents";

function* getAChildAdditionalDetail({ payload: { id } }) {
  try {
    const response = yield call(
      ChildAdditionalDetailHelper.getchildAdditionalDetailFromParse,
      id,
      false
    );
    yield put(
      ChildAdditionalDetailActions.updateChildAdditionalDetail(response)
    );
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getAChildAdditionalDetailForEnquiryIdParse({
  payload: { enquiryId },
}) {
  try {
    const serverResponse = yield call(
      ChildAdditionalDetailHelper.getAChildAdditionalDetailForEnquiryId,
      enquiryId
    );

    console.log("childAdditional Details saga got");
    console.log(serverResponse);
    if (serverResponse && serverResponse.length > 0) {
      yield put(
        ChildAdditionalDetailActions.setChildAdditionalObject(serverResponse[0])
      );
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* deleteAChildAdditionalDetail({
  payload: { childadditionaldetailId },
}) {
  try {
    const response = yield call(
      ChildAdditionalDetailHelper.deleteChildAdditionalDetailFromParse,
      childadditionaldetailId
    );
    if (response) {
      yield put(
        ChildAdditionalDetailActions.deleteChildAdditionalDetail(response)
      );
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getChildAdditionalDetailForKidId({ payload: { kidId } }) {
  try {
    const localResponse = yield call(
      ChildAdditionalDetailHelper.getChildAdditionalDetailForKidId,
      kidId,
      true
    );
    if (localResponse && localResponse.length > 0) {
      yield put(
        ChildAdditionalDetailActions.updateChildAdditionalDetails(localResponse)
      );
    }

    const serverResponse = yield call(
      ChildAdditionalDetailHelper.getChildAdditionalDetailForKidId,
      kidId,
      false
    );

    if (serverResponse && serverResponse.length > 0) {
      yield put(
        ChildAdditionalDetailActions.updateChildAdditionalDetails(
          serverResponse
        )
      );
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editChildAdditionalDetail({ payload: { childAdditionalDetail } }) {
  try {
    const response = yield call(
      ChildAdditionalDetailHelper.editChildAdditionalDetail,
      childAdditionalDetail
    );
    if (response) {
      yield put(
        ChildAdditionalDetailActions.updateChildAdditionalDetail(response)
      );
      response.pin();
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getChildAdditionalDetailsForEnquiryAndKidIds({
  payload: { enqIds, kidIds },
}) {
  try {
    const response = yield call(
      ChildAdditionalDetailHelper.getChildAdditionalDetailsForEnquiryAndKidIds,
      enqIds,
      kidIds
    );
    yield put(
      ChildAdditionalDetailActions.setChildAdditionalDetailsForEnquiryAndKidIds(
        response
      )
    );
  } catch (err) {
    console.log(err);
  }
}

export function* watchGetChildAdditionalDetail() {
  yield takeEvery(
    actionTypes.GET_CHILD_ADDITIONAL_DETAIL,
    getAChildAdditionalDetail
  );
}
export function* watchGetAChildAdditionalDetailForEnquiryIdParse() {
  yield takeEvery(
    actionTypes.GET_CHILD_ADDITIONAL_DETAIL,
    getAChildAdditionalDetailForEnquiryIdParse
  );
}

export function* watchDeleteChildAdditionalDetail() {
  yield takeEvery(
    actionTypes.DELETE_CHILD_ADDITIONAL_DETAIL,
    deleteAChildAdditionalDetail
  );
}

export function* watchGetChildAdditionalDetailForKidId() {
  yield takeEvery(
    actionTypes.GET_CHILD_ADDITIONAL_DETAILS,
    getChildAdditionalDetailForKidId
  );
}
export function* watchEditChildAdditionalDetail() {
  yield takeEvery(
    actionTypes.EDIT_CHILD_ADDITIONAL_DETAIL,
    editChildAdditionalDetail
  );
}

export function* watchGetChildAdditionalDetailsForEnquiryAndKidIds() {
  yield takeEvery(
    actionTypes.GET_CHILD_ADDITIONAL_DETAILS_FOR_ENQUIRY_AND_KID_IDS,
    getChildAdditionalDetailsForEnquiryAndKidIds
  );
}

function* ChildAdditionalDetailSaga() {
  yield all([
    fork(watchGetChildAdditionalDetail),
    fork(watchDeleteChildAdditionalDetail),
    fork(watchGetChildAdditionalDetailForKidId),
    fork(watchEditChildAdditionalDetail),
    fork(watchGetAChildAdditionalDetailForEnquiryIdParse),
    fork(watchGetChildAdditionalDetailsForEnquiryAndKidIds),
  ]);
}
export default ChildAdditionalDetailSaga;
