import * as actions from "./actionTypes"
import * as ReduxUtil from "../../../ReduxUtil";

const initialState = {
    inventoryVendors: [], loding: undefined,
    selectedVendor: null,
};

const InventoryVendor = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_INVENTORY_VENDOR:
            var inventoryVendors = [...state.inventoryVendors];
            var temp = state.inventoryVendors.filter(
                (inventoryVendor) =>
                    inventoryVendor.id === action.payload.inventoryVendor.id
            );
            if (temp.length > 0) {

                if (action.payload.inventoryVendor.attributes.isDeleted) {

                    var others = state.inventoryVendors.filter(
                        (inventoryVendor) =>
                            inventoryVendor.id !== action.payload.inventoryVendor.id
                    );
                    state = { ...state, inventoryVendors: others };

                } else {
                    var index = inventoryVendors.indexOf(temp[0]);
                    inventoryVendors[index] = action.payload.inventoryVendor;
                    state = { ...state, inventoryVendors: inventoryVendors };
                }


            } else {
                inventoryVendors.push(action.payload.inventoryVendor);
                state = { ...state, inventoryVendors: inventoryVendors };
            }
            break;
        case actions.UPDATE_INVENTORY_VENDORS:
            var inventoryVendors = [...state.inventoryVendors];
            if (inventoryVendors.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.inventoryVendors], action.payload.inventoryVendors, "isDeleted")
                state = { ...state, inventoryVendors: updatedList };
            } else {
                var others = action.payload.inventoryVendors.filter(
                    (inventoryVendors) => inventoryVendors.attributes.isDeleted !== true
                );

                state = { ...state, inventoryVendors: others };
            }

            break;
        case actions.UPDATE_DELETE_INVENTORY_VENDOR:
            console.log(state.inventoryVendors);
            var inventoryVendors = state.inventoryVendors.filter(
                (inventoryVendor) => inventoryVendor.id !== action.payload.inventoryVendor.id
            );
            console.log(inventoryVendors)
            state = { ...state, inventoryVendors: inventoryVendors };
            break;
        case actions.UPDATE_SELECTED_VENDOR:
            state = { ...state, selectedVendor: action.payload.inventoryVendor };
            break;

        default:
            state = { ...state };
            break;

    }
    return state;
};

export default InventoryVendor;
