import React from "react";
import { Row, Col, Form } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";

const Breadcrumb = (props) => {
  return (
    <Row className='d-flex align-items-start'>
      <Col className="d-flex align-items-start">
        <div
          style={{ cursor: "pointer" }}
          onClick={() => { window.history.back() }}>
          <ul className=" list-unstyled">
            <div className=''>
              <i className="bx bx-chevron-left h1 text-primary"></i>
            </div>
          </ul>
        </div>
        <div className=' m-2'>
          <h4 className="m-0">{props.t(props.title.toUpperCase())}</h4>
        </div>
      </Col>
      {!props.hideActionButtion && <Col className='d-flex justify-content-end'>
        <div className="mt-4 mt-sm-0 float-sm-right form-inline">
          <button onClick={props.tog_standard} className="btn btn-primary float-right">{props.t('Add Archive Group')}</button>
        </div>
      </Col>}
    </Row>
  );
};

export default withRouter(withNamespaces()(Breadcrumb));
