export const UPDATE_ENQUIRY_FOLLOWUP = "UPDATE_ENQUIRY_FOLLOWUP";
export const DELETE_ENQUIRY_FOLLOWUP = "DELETE_ENQUIRY_FOLLOWUP";
export const UPDATE_DELETE_ENQUIRY_FOLLOWUP = "UPDATE_DELETE_ENQUIRY_FOLLOWUP";

export const UPDATE_ENQUIRY_FOLLOWUPS = "UPDATE_ENQUIRY_FOLLOWUPS";
export const GET_ENQUIRY_FOLLOWUPS = "GET_ENQUIRY_FOLLOWUPS";
export const GET_ENQUIRY_FOLLOWUP = "GET_ENQUIRY_FOLLOWUP";

export const ENQUIRY_FOLLOWUP_SUCCESS = "ENQUIRY_FOLLOWUP_SUCCESS";
export const ENQUIRY_FOLLOWUP_ERROR = "ENQUIRY_FOLLOWUP_ERROR";

export const EDIT_ENQUIRY_FOLLOWUP = "EDIT_ENQUIRY_FOLLOWUP";
export const SAVE_ENQUIRY_FOLLOWUPS = "SAVE_ENQUIRY_FOLLOWUPS";
export const SAVE_LODING = "SAVE_LODING";