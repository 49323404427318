import React, { useState,useEffect } from 'react';
import { Label, Row, Col, Card, CardBody, CardTitle,CardSubtitle, UncontrolledTooltip, Collapse, Badge, FormGroup,Table ,Progress,Media,Modal,ModalBody,ModalHeader,Input, Alert} from "reactstrap";
import classnames from 'classnames';
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import { v4 as uuidv4 } from "uuid";
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import SweetAlert from "react-bootstrap-sweetalert";
import $ from "jquery";
import VimeoUpload from "vimeo-upload-me";
import Iframe from "react-iframe";
import ReactAudioPlayer from 'react-audio-player';
import Lightbox from "react-image-lightbox";
import Switch from "react-switch";

import * as CommonFunctions from "../../../components/Common/CommonFunctions";

import DragDropTables from '../../LMS/DragDropTables';
import contentImage from "../../../assets/images/undraw_typewriter.svg";
import DocViewer, { DocViewerRenderers,BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";


import {
    getLearningCategoryForCourseId,
    addLearningCategory,
    getLearningActivitiesForCourseId,
    setLearningActivityLoadingState,
    addLearningActivity,
    reorderActivities,
    reorderCatrgories,
    deleteLearningActivity,
    deleteLearningCategory,
    AddFile,
    deleteFile,
    getFilesForCourseId,
    getExamsForSchoolIds,

} from "../../../store/actions";
import ContentCopyPopup from './ContentCopyPopup'

const ContentCreation = (props) => {

    const [tab, setTab] = useState(0);

    const [editCategoryObject, setEditCategory] = useState(undefined);
    const [deleteCategoryObject, setDeleteCategoryObject] = useState(undefined);
    const [deleteWarning, setDeleteWarning] = useState(false);
    const [reorderChapter, setReorderChapter] = useState(false);
    const [showNewChapter, setshowNewChapter] = useState(true);
    const [loading, setLoading] = useState(false);

    const [reorderTopic, setReorderTopic] = useState(false);
    const [editActivity, setEditActivity] = useState(undefined);
    const [deleteActivity, setDeleteActivity] = useState(undefined)
    const [showNewTopic, setShowNewTopic] = useState(undefined);
    
    const [success_dlg,setsuccess_dlg]=useState(false)
    const [canEdit, setCanEdit] = useState(true);

    const UploadfilesTypes = [
        { id:"input-video", name: "Video", icon:"bx bxs-video ",size: "mp4,mov..", link: "#" },
        { id:"input-image", name: "Image", icon:"bx bxs-file-image ",size: "jpg,png..", link: "#" },
        { id: "input-file", name: "Document", icon: "bx bxs-file-doc ", size: "pdf,doc,ppt,xlsx..", link: "#" },
        { id:"input-audio", name: "Audio", icon:"bx bxs-music ", size: "mp3,wav,wma,flac..", link: "#" },
        
    ];
    const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
    const _pdfExtensions = [".pdf", "pdf"];
    const _audioExtensions = [".mp3", "mp3", ".wma","wma", ".acc","acc",".wav","wav",".flac","flac",];


    const [activityTab, setActivityTab] = useState(undefined);
    const [selectedActivity, setSelecetedActivity] = useState(undefined);

    //

    const [isUploadProgress, setIsUploadProgress] = useState(false)
    const [progressText, setProgressText] = useState(undefined);
    const [uploadPogress, setUploadProgress] = useState(undefined);
    const [nameError, setNameError] = useState(false);
    const [fileName, setFileName] = useState(undefined);
    const [fileDownloadeble, setFileDownloadeble] = useState(true);
    const [fileType, setFileType] = useState(undefined);
    const [preview, setPreview] = useState(undefined);
    const [selectedFile, setSelectedFile] = useState(undefined);
    const [fileExtention, setFileExtention] = useState(undefined);

    //
    const [lightBox, setLightBox] = useState(undefined);
    const [showModel, setShowModel] = useState(false);
    const [clickedFile, setClickFile] = useState(undefined);

     //Exam
    const [examDisplayList, setExamDisplayList] = useState([]);
    const [showAddQuizModel, setShowAddQuizModel] = useState(false);
    const [quizActivity, setQuizActivity] = useState(undefined);

    const [secondaryResourseActivityId,setSecondaryResourseActivityId]=useState(undefined)
    const [deleteFile, setDeleteFile] = useState(undefined);

    //
    const [showCopyContentPopup, setShowCopyContentPopup] = useState(false);
    const [copyContentObject, setCopyContentObject] = useState(undefined);
    const [copyContentActivity, setCopyContentActivity] = useState(undefined);
   

    useEffect(() => {
        setCanEdit(true ?? CommonFunctions.canEdit(props.courseObject, props.userTeacher));
    }, [props.courseObject, props.userTeacher]);
    
    useEffect(() => {
        props.getLearningCategoryForCourseId(props.courseId);
        props.getLearningActivitiesForCourseId(props.courseId);
        props.getFilesForCourseId(props.courseId)
    }, [props.courseId]);
    
    useEffect(() => {
        if (props.selectedSchool) {
         fetchExams(props.selectedSchool.id)    
        }
        
    }, [props.selectedSchool]);

    const fetchExams = (schoolId) => {
        let ids = [];

        if (props.selectedSchool) {
            //ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }
        }
        ids.push(schoolId);

        if (ids.length > 0) {
            props.getExamsForSchoolIds(ids);
        }
    };
    useEffect(() => {
        setExamDisplayList(props.exams);
    }, [props.exams]);
    const handelExamList = (value) => {
        let list = [];
        for (const ex of props.exams) {
            if (ex.attributes.name.toLowerCase().includes(value.toLowerCase())) {
                list.push(ex);
            }
        }
        setExamDisplayList(list);
    }

    useEffect(() => {
        if (props.addCategory) {
           
            scroller.scrollTo('AddCategory', {
                duration: 500,
                delay: 100,
                smooth: true,
                offset: -100,
            });
        }
        setshowNewChapter(props.addCategory);
    }, [props.addCategory]);

    useEffect(() => {
        setReorderChapter(props.reorder);
    }, [props.reorder]);
    
    useEffect(() => {
        if (props.loadingState === false) {
            setEditActivity(undefined);
            setShowNewTopic(undefined);
        }
    }, [props.loadingState]);
    

    const handelCloseCateogySort = () => {
        props.onCloseReorder();
    }
    
    const handelSortCategories = (list) => {
         var tempList = [];
        for (var i in list) {
            if (parseInt(i) !== list[i].attributes.order) {
                list[i].set("order", parseInt(i));
                tempList.push(list[i]);
            }
        }
        props.reorderCatrgories(tempList,"order updated successfully",props.courseId);
        props.onCloseReorder();
    }
    
    const handelCloseActivitySort = () => {
        setReorderTopic(undefined);
    }
    
    const handelSortActivities = (list) => {
        var tempList = [];
        for (const i in list) {
            if (parseInt(i) !== list[i].attributes.orderBy) {
                list[i].set("orderBy", parseInt(i));
                tempList.push(list[i]);
            }
        }
        props.reorderActivities(tempList,null,null,"order updated successfully",props.courseId);
        setReorderTopic(undefined);
    }


    const handelAddTopic = (id) => {
        setShowNewTopic(id);
        props.setLearningActivityLoadingState(false);
        scroller.scrollTo('AddTopic' + id, {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: -100,
        });
    }
    const getActivitiesForCategoryId = (id) => {
        var list = [];
        for (const i in props.learningActivities) {
            if (props.learningActivities[i].attributes.category === id) {
                list.push(props.learningActivities[i]);
            }
        }
        return list;
    }

    const getQuizActivityForCategory = (id) => {
         var list = [];
        for (const i in props.learningActivities) {
            if (props.learningActivities[i].attributes.category === id && props.learningActivities[i].attributes.fileType==="quiz") {
                list.push(props.learningActivities[i]);
            }
        }
        return list;
    }

    const handelAddCategory = (name,description) => {
        var LearningCategory = Parse.Object.extend("LearningCategory");
        var learningCategory = new LearningCategory();
        learningCategory.set("name", name);
        learningCategory.set("description", description);
        learningCategory.set("courseId", props.courseId)
        learningCategory.set("order", props.learningCategories && props.learningCategories.length > 0 ? props.learningCategories.length : 0)
        props.addLearningCategory(learningCategory);
        props.onCloseAddCategory(false);
    }

    const handelAddActivity=(activity, name, activityDescription, category,quizId) =>{
        props.setLearningActivityLoadingState(true);
        var LearningActivity = Parse.Object.extend("LearningActivity");
        var learningActivity = new LearningActivity();
        if (activity) {
            var learningActivity=activity;
        } else {
            let list = getActivitiesForCategoryId(category.id);
            if (list && list.length > 0) {
                learningActivity.set("orderBy", parseInt(list.length));
                learningActivity.set("order", list.length + "");
            } else {
                learningActivity.set("orderBy", 0);
                learningActivity.set("order", "0");
            }
        }

        if (quizId != null) {
            learningActivity.set("quizId", quizId);
            learningActivity.set("fileType", "quiz")
            learningActivity.set("order", "100");
            learningActivity.set("orderBy", 100);
        }
       
        learningActivity.set("name", name);
        learningActivity.set("category", category.id);
        learningActivity.set("description", activityDescription);
        learningActivity.set("courseId", props.courseId)
        
        props.addLearningActivity(learningActivity);
          
    }

    

    const getFilesForActivity = (activity) => {
        if (props.files && props.files.length > 0) {
            var temp = props.files.filter(
                (file) =>
                    file.attributes.folderId === activity.attributes.resourceFolderId
            );

            return temp;
        }
        return [];
    }
    const canShowMeeting = (activity) => {
        return false;
        if (props.files && props.files.length > 0) {
            var temp = props.files.filter(
                (file) =>
                    file.attributes.folderId === activity.attributes.resourceFolderId && file.attributes.fileType ==="meeting"
            );

            if (temp.length > 0) {
                return false
            }
            
        }
        return true;
    }
    const getDownloadebleFilesForActivity = (activity) => {
        if (props.files && props.files.length > 1) {
            var temp = props.files.filter(
                (file) =>
                    file.attributes.folderId === activity.attributes.resourceFolderId
            );

            let mewtemp = temp.filter(
                (file) =>
                    temp[0].id !== file.id
            );

            
            return mewtemp;
        }
        return [];
    }

    function getFileTypeImage(fileExtention,fileType) {
        
        if(fileExtention ==="pdf" ){
            return "bx bxs-file-pdf";
        }else if(fileExtention ==="ppt" || fileExtention ==="pptx" ){
            return "bx bxs-file-doc";
        }else if(fileExtention ==="xlsx" ){
            return "bx bxs-file-doc";
        }else if(fileExtention ==="doc" || fileExtention ==="docx") {
            return "bx bxs-file-doc";
        }else if(fileType === "Video"){
            return "bx bx-play-circle";
        }else if (_audioExtensions.includes(fileExtention)) {
            return "bx bxs-music";
        }
        return "bx bxs-file-blank";
        
    }

    function getFileTypeFromUrl(url) {
        var res = url.split(".");
        var res = res[res.length - 1];
        if (_imgExtensions.includes(res)) {
            return {type: "image",ext:res};
        }else if (_audioExtensions.includes(res)) {
            return {type: "audio",ext:res};
        }else if (_pdfExtensions.includes(res)) {
            return {type: "pdf",ext:res};
        }else  {
            return {type: "file",ext:res};
        }
    }

    function getURL(){

        if (clickedFile) {
            if (clickedFile.attributes.fileType === "url") {
                return clickedFile.attributes.url
            } else {
                return clickedFile.attributes.file._url;
            }
        }
    }
    const getFR = (url) => {
        const docs = [
            { uri: url },
        ];
        return docs;
    }

    function getVimeoUrl(link) {
        var splits = link.split("/")
        var videoId = splits[splits.length - 2];
        var videokey = splits[splits.length - 1];
        return "https://player.vimeo.com/video/" + videoId +"?h="+videokey;
    }

    function getFileName(clickedFile){

        if (clickedFile) {
            if (clickedFile.attributes.fileType === "url") {
                return clickedFile.attributes.name
            } else {
                let res = clickedFile.attributes.file._name.split("_")
                return res[res.length-1];
            }
        }
    }

    const onlineSessionPreviev = () => {
        setIsUploadProgress(false);
        setCopyContentObject(undefined)
        setCopyContentActivity(undefined)
        setFileType("meeting");
        setFileName("Live Class");
    }

    const videoUploadPreviev=(e)=>{
        setIsUploadProgress(false);
        setCopyContentObject(undefined)
        setCopyContentActivity(undefined)
        setFileType("Video");
        setSecondaryResourseActivityId(undefined);
        setSelectedFile(e.target.files[0]);
        setPreview(URL.createObjectURL(e.target.files[0]));
        var fileExtension = e.target.files[0].name.split(".");
        setFileName(fileExtension[0]);
        setFileExtention(fileExtension[(fileExtension.length-1)].toLowerCase());

    }
    const ImageUploadPreviev = (e) => {
        setIsUploadProgress(false);
        setCopyContentObject(undefined)
        setCopyContentActivity(undefined)
        setFileType("Image");
        setSecondaryResourseActivityId(undefined);
        let fileExtension = e.target.files[0].name.split(".");
        setFileName(fileExtension[0]);
        setFileExtention(fileExtension[fileExtension.length-1].toLowerCase()? fileExtension[fileExtension.length-1].toLowerCase() : "jpeg" );
        setPreview(URL.createObjectURL(e.target.files[0]));

        setSelectedFile(e.target.files[0]);

    }
    const fileUploadPreviev = (e) => {
        setIsUploadProgress(false);
        setCopyContentObject(undefined)
        setCopyContentActivity(undefined)
        setFileType("File");
        setSecondaryResourseActivityId(undefined);
        setSelectedFile(e.target.files[0]);
        let fileExtension = e.target.files[0].name.split(".");
        setFileName(fileExtension[0]);
        setFileDownloadeble(true);
        setFileExtention(fileExtension[1].toLowerCase());
    }

    const checkForFolderId = (activity) => {

        if (activity.attributes.resourceFolderId && activity.attributes.resourceFolderId.length > 0) {
            if (copyContentObject && copyContentActivity) {
                cloneFileObject(copyContentObject, activity.attributes.resourceFolderId);
                setIsUploadProgress(true);
            }else if (fileType === "Video") {
                onVideoUpload(activity,activity.attributes.resourceFolderId );
                setIsUploadProgress(true);
            } else if (fileType === "Image") {
                onImageUpload(activity,activity.attributes.resourceFolderId );
                setIsUploadProgress(true);
            } else if (fileType === "File") {
                onFileUpload(activity,activity.attributes.resourceFolderId );
                setIsUploadProgress(true);
            } else if (fileType === "meeting") {
                activity.set("fileType", "meeting")
                props.addLearningActivity(activity);
                onCreateMeeting(activity,activity.attributes.resourceFolderId );
                setIsUploadProgress(true);
            }
        } else {
            let uuid = uuidv4();
            activity.set("resourceFolderId", uuid);
            if (copyContentObject && copyContentObject.file) {
                activity.set("fileType", copyContentObject.file.attributes.fileType)
                props.addLearningActivity(activity);
                cloneFileObject(copyContentObject,uuid)
            }else if (fileType === "Video") {
                activity.set("fileType", "video")
                props.addLearningActivity(activity);
                onVideoUpload(activity,uuid);
                setIsUploadProgress(true);
            } else if (fileType === "Image") {
                activity.set("fileType", "image")
                props.addLearningActivity(activity);
                onImageUpload(activity,uuid);
                setIsUploadProgress(true);
            } else if (fileType === "File") {
                activity.set("fileType", "file")
                if (_audioExtensions.includes(fileExtention)) {
                    activity.set("fileType", "audio")
                }
                props.addLearningActivity(activity);
                onFileUpload(activity,uuid);
                setIsUploadProgress(true);
            } if (fileType === "meeting") {
                setIsUploadProgress(true);
                activity.set("fileType", "meeting")
                props.addLearningActivity(activity);
                onCreateMeeting(activity,uuid);
            }  
        }   
    }

    const onCreateMeeting = (activity, folderId) => {
        setUploadProgress(5);
        var Files = Parse.Object.extend("Files");
        var fileObject = new Files();
        fileObject.set("folderId", folderId);
        fileObject.set("orderBy", props.files.length);
        fileObject.set("fileType", "meeting");
        fileObject.set("name", fileName);

        fileObject.set("courseId", props.courseId);
        handelAddFile(fileObject);
    }

    const onVideoUpload = (activity,folderId) => {
        if (selectedFile) {
            setProgressText(props.t('Preparing For upload'));
            const accessToken = "bc7a5ee29880658b6f3689a45e63c5ec";
            setUploadProgress(1);
          
            var uploader = new VimeoUpload({
                name: fileName,
                description:"",
                file: selectedFile,
                token: accessToken,
                upgrade_to_1080: true,
                privacy: { view: "unlisted" },
                onError: (data) => {
                    console.log(data);
                    setProgressText(props.t('Error while uploading video, Please refresh the page and try again'))
                },
                onProgress: (data) => {
                    let value = Math.floor((data.loaded / data.total) * 100);
                    setUploadProgress(value);
                    setProgressText(props.t('Uploading....')+": "+Math.round(value) +"%")
                },
                onComplete: (videoId, index) => {
                
                    setProgressText(props.t('Adding video to topic'))
                    var settings = {
                        url: "https://api.vimeo.com/videos/" + videoId,
                        method: "PATCH",
                        timeout: 0,
                        headers: {
                        Authorization: "Bearer bc7a5ee29880658b6f3689a45e63c5ec",
                        "Content-Type": "application/json",
                        },
                        data: JSON.stringify({ privacy: { view: "unlisted" } }),
                    };
                    console.log(settings);
                    var res = videoId.split(":")
                    var vimeoUrl = "https://vimeo.com/" + res[0]+"/"+res[1];
                    var Files = Parse.Object.extend("Files");
                    var fileObject = new Files();
                    fileObject.set("folderId", folderId);
                    fileObject.set("orderBy", props.files.length);
                    fileObject.set("fileType", "vimeo");
                    fileObject.set("url", vimeoUrl);
                    fileObject.set("name", fileName);

                    fileObject.set("courseId", props.courseId);
                    
                    
                    
                    $.ajax(settings).done((response) => {
                        fileObject.set("introThumbnail",response.pictures.sizes[4].link);
                        handelAddFile(fileObject);
                    });
                },
            });
          uploader.upload();
        }
    }

    const onImageUpload = (activity,folderId) => {
        var name = fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '')+"."+fileExtention;
        
        getBase64(selectedFile, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            setProgressText(props.t('Preparing For upload'));
            setUploadProgress(1);
            parseFile.save({
                
                progress: (value) => {
                    if (value && value > 0.1) {
                        setUploadProgress(value * 100);
                        setProgressText(props.t('Uploading....') + ": " +Math.round(value * 100) + "%");
                    }
                } 
                
            }).then(    
                result => {
                    var Files = Parse.Object.extend("Files");
                    var fileObject = new Files();
                    fileObject.set("folderId", folderId);
                    fileObject.set("orderBy", props.files.length);
                    fileObject.set("fileType", "image");
                    fileObject.set("file", result);
                    fileObject.set("name", fileName);

                    fileObject.set("courseId", props.courseId);
                    
                    setProgressText(props.t('Adding Image to topic'))
                    handelAddFile(fileObject);
                },
                error => {
                    setProgressText(props.t('Error while uploading Image/Photo, Please refresh the page and try again.')+'\n'+props.t('Please make sure no special characters in file/image name'))
                }
                
            );
        });
    }

    const onFileUpload = (activity,folderId) => {
         var name = fileName.replace(/[`~!@#$%^&*()_|+\-=?;:'",<>\{\}\[\]\\\/]/gi, '');

        getBase64(selectedFile, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            setUploadProgress(1);
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setUploadProgress(value * 100);
                         setProgressText(props.t('Uploading....')+": "+Math.round(value*100)+"%")
                    }
                } 
                }).then(
                    result => {
                        var Files = Parse.Object.extend("Files");
                        var fileObject = new Files();
                        fileObject.set("folderId", folderId);
                        fileObject.set("orderBy", props.files.length);

                        if (_imgExtensions.includes(fileExtention.toLowerCase())) {
                            fileObject.set("fileType", "image");
                        } else if (_pdfExtensions.includes(fileExtention.toLowerCase())) {
                            fileObject.set("fileType", "file");
                            fileObject.set("downloadable", fileDownloadeble);
                        } else if (_audioExtensions.includes(fileExtention.toLowerCase())) {
                            fileObject.set("fileType", "audio");
                        }
                        
                        fileObject.set("file", result);
                        fileObject.set("name", fileName);

                        fileObject.set("courseId", props.courseId);
                        
                        setProgressText(props.t('Adding document to topic'))
                        handelAddFile(fileObject);
                    },
                    error => {
                        setProgressText(props.t('Error while uploading file, Please refresh the page and try again'))
                    }
                );
          });
    }

    const cloneFileObject = (copyContentObject, folderId) => {
        var Files = Parse.Object.extend("Files");
        var fileObject = new Files();
        fileObject.set("folderId", folderId);
        fileObject.set("orderBy", props.files.length);
        fileObject.set("name", fileName);

        fileObject.set("fileType", copyContentObject.attributes.fileType);               
        fileObject.set("file", copyContentObject.attributes.file);
        fileObject.set("url", copyContentObject.attributes.url);

        fileObject.set("thumbnailUrl", copyContentObject.attributes.thumbnailUrl);
        fileObject.set("thumbnailImage", copyContentObject.attributes.thumbnailImage);
        

        fileObject.set("courseId", props.courseId);
                        
        setProgressText(props.t('Copying content to topic'))
        handelAddFile(fileObject);
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var resultWithName = { name: file.name, result: reader.result };
          cb(resultWithName);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      
    }

    const handelAddFile=(fileObject) =>{
        props.AddFile(fileObject, props.t('Content added successfully'));
        setSelectedFile(undefined);
        setFileName(undefined);
        setFileType(undefined);
        setSecondaryResourseActivityId(undefined);
        setUploadProgress(0);
    }
     useEffect(() => {
         if (!props.fileAddState) {
            setSelectedFile(undefined)
        }
    }, [props.fileAddState]);

    const youtubeParser=(url)=> {
        var regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
        var match = url.match(regExp);
        return match && match[7].length == 11 ? match[7] : false;
    }

    const handelCopyFile = (file) => {
        console.log("Copy", file);
        setShowCopyContentPopup(false);
        setCopyContentObject(file)

        setIsUploadProgress(false);
        setFileType(file.attributes.fileType);
        setSecondaryResourseActivityId(undefined);
        setSelectedFile(file);
        setFileName(file.attributes.name);

        if (file.attributes.file) {
            let fileExtension = file.attributes.file._url.split(".");
            setFileExtention(fileExtension[1].toLowerCase());
        }
    
    }
     useEffect(() => {
        console.log("ef copyContentObject",copyContentObject)
    }, [copyContentObject]);


    const getFileUploadSelectionUI = (activity,showCancel)=>{
        return (<div>
            <h6 className="mt-2"> {props.t('What type content you want to upload?')} </h6>
            <Row>
                {
                    UploadfilesTypes.map((file, i) =>
                        <Col lg={2.5} key={"_file_" + i} >
                            <Label  htmlFor={file.id}>
                                <Card className="border m-2">
                                    <CardBody className="justify-content-center text-center">
                                        <div className="avatar-sm">
                                            <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-20">
                                                <i className={file.icon}></i>
                                            </span>
                                        </div>
                                        <p className="font-size-10 mt-2 mb-2 text-dark">{file.name}</p>
                                 
                                    </CardBody>
                                </Card>
                                    
                            </Label>
                        </Col>
                    )
                }

                {!showCancel &&  canShowMeeting(activity) && <Col lg={2.5} >
                    <Label
                        onClick={() => {
                            onlineSessionPreviev();
                    }}
                    >
                        <Card className="border m-2">
                            <CardBody className="justify-content-center text-center">
                                <div className="avatar-sm">
                                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-20">
                                        <i className={"bx bx-video-recording"}></i>
                                    </span>
                                </div>
                                <p className="font-size-10 mt-2 mb-2 text-dark">{"Online Sessions"}</p>
                            
                            </CardBody>
                        </Card>
                            
                    </Label>
                </Col>}

                {<Col lg={2.5} >
                    <Label
                        onClick={() => {
                            setShowCopyContentPopup(true)
                            setCopyContentActivity(activity)
                        }}
                    >
                        <Card className="border m-2">
                            <CardBody className="justify-content-center text-center">
                                <div className="avatar-sm">
                                    <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-20">
                                        <i className={"bx bx-copy"}></i>
                                    </span>
                                </div>
                                <p className="font-size-10 mt-2 mb-2 text-dark">{"Copy"}</p>
                            
                            </CardBody>
                        </Card>
                            
                    </Label>
                </Col>}
               
                {(showCopyContentPopup && copyContentActivity && copyContentActivity.id === activity.id) &&
                    <ContentCopyPopup
                        selectedSchool={props.selectedSchool}
                        showPopup={showCopyContentPopup }
                        onClickCancel={() => {
                            setShowCopyContentPopup(false);
                        }}
                        onSelectFileToCopy={handelCopyFile}
                    >
                    </ContentCopyPopup>
                }

                <input type="file"
                    className="sr-only"
                    id="input-video"
                    name="file"
                    accept={`video/mp4,video/x-m4v,video/*`}
                    onChange={videoUploadPreviev}
                />
                <input type="file"
                    className="sr-only"
                    id="input-image"
                    name="file"
                    accept="image/*"
                    onChange={ImageUploadPreviev}
                    
                />
                <input type="file"
                    className="sr-only"
                    id="input-file"
                    name="file"
                    accept=".doc,.docx,.pdf,.ppt,.pptx,.xlsx,/*"
                    onChange={fileUploadPreviev}
                    
                />
                <input type="file"
                    className="sr-only"
                    id="input-audio"
                    name="file"
                    accept=".mp3,.wma,.acc,.wav,.flac/*"
                    onChange={fileUploadPreviev}
                    
                />

            </Row>
            {showCancel && <button className='btn btn-sm btn-danger'
                onClick={() => { setSecondaryResourseActivityId(undefined) }}
            >Close</button>}
        </div>);
    }
    const contentUI = (category, activity) => {

        return (
            <div>
                {getFilesForActivity(activity).length === 0 ? <div >
                    {(!uploadPogress || uploadPogress === 0) && fileType !=="meeting" && canEdit &&  <div > {getFileUploadSelectionUI(activity)}</div>}

                    {selectedFile && activityTab === activity.id &&
                        uploadPreview(selectedFile, activity)
                    }
                    {!selectedFile&& fileType ==="meeting" && activityTab === activity.id &&
                        meetingPreview(activity)
                    }
                </div>

                    :

                    <div>
                        {getFilesForActivity(activity).map((file, key) => (
                            <div key={file.id}>
                               
                                <Row className="justify-content-center border m-3" key={key}>
                                    <Col  >
                                   
                                        <Row>
                                        
                                            <Col lg={10}>
                                                <h5>{file.attributes.name}</h5>
                                                {/* {(file.attributes.fileType === "vimeo" || 
                                                    (file.attributes.file&&getFileTypeFromUrl(file.attributes.file._url).type === "image"))?
                                                    <p className="text-muted">{file.attributes.fileDescription}</p>:""
                                                    
                                                    } */}
                                            </Col>
                                            <Col lg={2}>
                                                
                                                {canEdit &&  <ul className="list-inline font-size-16 contact-links mb-0 float-right">
                                                    {/* <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            // setEditFile(file)
                                                            if (file.attributes.fileType === "file") {
                                                                // setEditFileDownloadeble(file.attributes.downloadable)
                                                            }
                                                        }}
                                                        id={"file-edit" + key}>
                                                        <i className="bx bx-edit-alt text-dark"></i>
                                                        <UncontrolledTooltip placement="top" target={"file-edit" + key}>
                                                            {props.t('Edit')}
                                                        </UncontrolledTooltip>

                                                    </li> */}
                                                       
                                                       
                                                   {file.attributes.fileType !== "meeting" && <li className="list-inline-item px-2"
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {
                                                            setDeleteFile(file);
                                                        }}
                                                        id={"sub-delete" + key}>
                                                        <i className="bx bx-trash text-danger"></i>
                                                        <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                            {props.t('Delete')}
                                                        </UncontrolledTooltip>
                                                        
                                                    </li>}
                                                </ul>}
                                                
                                            </Col>
                                        </Row>
                                            

                                        {file.attributes.fileType === "vimeo" &&
                                            <Iframe
                                                url={getVimeoUrl(file.attributes.url)}
                                                height="300"
                                                style={{ cursor: "pointer" }}
                                                display="initial"
                                                scrolling={"no"}
                                                allowFullScreen={true}
                                                position="relative"
                                                frameBorder={0}
                                                className="mt-2"
                                            />
                                                
                                        }
                                        {file.attributes.fileType === "url" && !youtubeParser(file.attributes.url) &&
                                                
                                            <Media className="border mt-2 p-2" style={{ cursor: "pointer" }}>
                                                <div className="avatar-md mr-4">
                                                    <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                                        <div style={{ fontSize: "48px" }}><i className="bx bx-link-alt"></i></div>
                                                    </span>
                                                </div>
                                                    
                                                <Media className="overflow-hidden" body >

                                                       
                                                    <div >
                                                        <h5 className="text-truncate font-size-15  mt-2 text-dark">{props.t('Link/URL') + ": "}</h5>
                                                        <spam
                                                            onClick={() => {
                                                                setClickFile(file);
                                                                setShowModel(true);
                                                            }}
                                                            
                                                            style={{ cursor: "pointer" }} >
                                                            <p className="text-primary "><u>{file.attributes.url} </u></p>
                                                        </spam>
                                                        <p className="text-muted">{file.attributes.fileDescription}</p>
                                                    </div>
                                                </Media>
                                            </Media>
                                            
                                                

                                        }

                                        {
                                            (file.attributes.fileType === "url" || file.attributes.fileType === "youtube") && file.attributes.url && youtubeParser(file.attributes.url) &&
                                            <div className="embed-responsive embed-responsive-16by9 ratio ratio-8x4 col-6" >

                                                <iframe
                                                    title={file.attributes.name}
                                                    className="embed-responsive-item"
                                                    src={"https://www.youtube.com/embed/" + youtubeParser(file.attributes.url)}
                                                />

                                            </div>
                                        }

                                        {
                                            file.attributes.file && file.attributes.file._url && getFileTypeFromUrl(file.attributes.file._url).type === "image" &&
                                            <img
                                                style={{ cursor: "pointer" }}
                                                data-dz-thumbnail=""
                                                onClick={() => {
                                                    setLightBox({ show: true, images: [file.attributes.file._url], name: file.attributes.name });
                                                }
                                                }
                                                className="rounded mr-2 ml-5"
                                                height="300"
                                                    
                                                src={file.attributes.file._url}
                                            />
                                        }

                                        {file.attributes.file && file.attributes.file._url && getFileTypeFromUrl(file.attributes.file._url).type === "audio" &&
                                            <Media className="border mt-2 mb-2 p-2">
                                                < ReactAudioPlayer
                                                    src={file.attributes.file._url}
                                                    autoPlay={false}
                                                    controls
                                                />
                                            </Media>
                                               
                                        }
                                        {
                                            file.attributes.file && file.attributes.file._url
                                                && getFileTypeFromUrl(file.attributes.file._url).type !== "image"
                                                && getFileTypeFromUrl(file.attributes.file._url).type !== "audio" ?
                                               

                                                <Media className="border mt-2 mb-2 p-2"
                                                    style={{ cursor: "pointer" }}
                                                    onClick={() => {
                                                        setClickFile(file);
                                                        setShowModel(true);
                                                    }}
                                                >
                                                    <div className="avatar-md mr-4" >
                                                        <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                                            <div style={{ fontSize: "48px", cursor: "pointer" }}><i className={getFileTypeImage(getFileTypeFromUrl(file.attributes.file._url).ext)}></i></div>
                                                        </span>
                                                    </div>
                                                    <Media className="overflow-hidden" body >

                                                        <div style={{ cursor: "pointer" }}>
                                                            <h5 className="text-truncate font-size-15  mt-2 text-dark">{file.attributes.name ? file.attributes.name : file.attributes.file._name}</h5>
                                                            <p className="text-muted ">{file.attributes.fileDescription}</p>
                                                        </div>

                                                        <Label className="text-primary" style={{ cursor: "pointer" }}><u>{props.t('Preview')}</u></Label>
                                                    </Media>
                                                </Media> :
                                                <p className="text-muted">{file.attributes.fileDescription}</p>
                                        }

                                        {file.attributes.fileType === "meeting"  &&
                                            <div className="border mt-2 mb-2 p-2">
                                                <div className="avatar-md mr-4">
                                                    <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                                        <div style={{ fontSize: "48px" }}><i className="bx bx-video-recording"></i></div>
                                                    </span>
                                                </div>
                                            </div>
                                               
                                        }
                                       
                                    </Col>
                                </Row>
                            </div>
                        ))}
                        <div>

                            {activity.id === secondaryResourseActivityId && !selectedFile &&
                                (!uploadPogress || uploadPogress === 0) && canEdit &&
                                <div>{getFileUploadSelectionUI(activity,true)}</div>  
                            }
                            {getFilesForActivity(activity).length > 0 &&
                                selectedFile && activityTab === activity.id &&
                                uploadPreview(selectedFile, activity)
                            }

                            {!selectedFile && !secondaryResourseActivityId &&
                                <button className='btn btb-sm btn-primary mt-2'
                                    onClick={()=>{setSecondaryResourseActivityId(activity.id)}}
                                >
                                Upload Content
                                </button>
                            }
                        </div>
                    </div>}
            </div>
        )
        
    }

    const meetingPreview = ( activity) => { 
        return (
            <Row className="border">
                <Col sm={7} lg={7} xs={12}>
                    <AvForm className="outer-repeater" >
                        <div data-repeater-item className="outer">
                            <FormGroup >
                                <Label >{props.t('Name:')}</Label>
                                <AvField
                                    type="text"
                                    name="name"
                                    placeholder={props.t('video/file Name')}
                                    className="form-control"
                                    value={fileName}
                                                    
                                    onChange={(e) => {
                                        if (e.target.value.length > 0 && nameError) {
                                            setNameError(false);
                                        }
                                        setFileName(e.target.value);
                                    }}
                                />
                                {nameError && <p className="text-danger"> {props.t('File  name can not be empty')}</p>}
                            </FormGroup>   
                        </div>
                        {isUploadProgress ?
                            <button type="button" className="btn btn-dark waves-effect waves-light float-right m-2">
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                            </button>  :
                            <button
                                className="btn btn-primary"
                                onClick={() => {
                                    checkForFolderId(activity)    
                                }}
                            >
                                {props.t('Save')}
                            </button>}
                    </AvForm>
                </Col>
                
            </Row>
        )
    }
    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 10,
                    color: "#fff",
                    paddingLeft: 2
                }}
            > No
            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 10,
                    color: "#fff",
                    paddingLeft: 2
                }}
            > Yes
            </div>
        );
    };
    const uploadPreview = (file,activity) => {

        return (
            <Row className="border">
                <Col sm={7} lg={7} xs={12}>
                    <AvForm className="outer-repeater" >
                        <div data-repeater-item className="outer">
                            <FormGroup >
                                <Label >{props.t('Name')}</Label>
                                <AvField
                                    type="text"
                                    name="name"
                                    placeholder={props.t('video/file Name')}
                                    className="form-control"
                                    value={fileName}
                                                    
                                    onChange={(e) => {
                                        if (e.target.value.length > 0 && nameError) {
                                            setNameError(false);
                                        }
                                        setFileName(e.target.value);
                                    }}
                                />
                                {nameError && <p className="text-danger"> {props.t('File  name can not be empty')}</p>}
                            </FormGroup>   
                            {(copyContentObject && copyContentActivity &&
                                copyContentObject.attributes.fileType != "vimeo" &&
                                copyContentObject.attributes.fileType != "video" &&
                                copyContentObject.attributes.fileType != "image" &&
                                copyContentObject.attributes.fileType != "meeting" && 
                                copyContentObject.attributes.fileType != "audio"
                            ) ? <FormGroup >
                                <Label >{props.t('Downloadeble') + "   "}</Label>
                                <Switch
                                    uncheckedIcon={<Offsymbol />}
                                    checkedIcon={<OnSymbol />}
                                    onColor="#626ed4"
                                    onChange={() => {setFileDownloadeble(!fileDownloadeble) }}
                                    checked={fileDownloadeble}
                                
                                />
                            </FormGroup> :
                                <div>
                                    {/* {console.log("fileType 1199:",fileType)} */}
                                    {fileType != "vimeo" &&
                                        fileType != "Video" &&
                                        fileType != "Image" &&
                                        fileType != "meeting" &&
                                        fileType != "audio" &&
                                        <FormGroup >
                                            <Label >{props.t('Downloadeble')}</Label>
                                            <Switch
                                                uncheckedIcon={<Offsymbol />}
                                                checkedIcon={<OnSymbol />}
                                                onColor="#626ed4"
                                                onChange={() => { setFileDownloadeble(!fileDownloadeble) }}
                                                checked={fileDownloadeble}
                                
                                            />
                                        </FormGroup>
    
                                    }
    
                                </div>
                            }
                        </div>
                        {isUploadProgress ?
                            <div className="mb-30">
                                            
                                        
                                <p className="text-info mb-2"> {props.t('Please do not close or refresh the page while uploading video/pdf or any other files')}</p>
                                <Progress striped color="primary" value={uploadPogress}></Progress>
                                <h6 className="float-right mt-4 text-primary">{progressText}</h6>
                            </div> :
                            <Row>
                                <button
                                    onClick={() => {
                                        setIsUploadProgress(false);
                                        setCopyContentObject(undefined)
                                        setCopyContentActivity(undefined)
                                        setFileType(undefined);
                                        setSecondaryResourseActivityId(undefined);
                                        setSelectedFile(undefined);
                                        setPreview(undefined);
                                        setFileName(undefined);
                                        setFileExtention(undefined);

                                    }}
                                    className="btn btn-danger mr-2 ml-2 mb-2"
                                >
                                    Cancel
                                </button>
                            <button
                                className="btn btn-primary mb-2"
                                onClick={() => {
                                    // if (fileType === "Video") {
                                    //     onVideoUpload(activity);
                                    //     setIsUploadProgress(true);
                                    // } else if (fileType === "Image") {
                                    //     onImageUpload(activity);
                                    //     setIsUploadProgress(true);
                                    // } else if (fileType === "File") {
                                    //     onFileUpload(activity);
                                    //     setIsUploadProgress(true);
                                    // }

                                    checkForFolderId(activity)
                                                    
                                }}
                            >
                                {props.t('Save')}
                                </button>
                                </Row>
                            }
                    </AvForm>
                </Col>
                <Col sm={5} lg={5}>
                      {/* {console.log("copyContentObject ",copyContentObject )}   
                      {console.log(" copyContentActivity",copyContentActivity)}                          */}
                    {(copyContentObject && copyContentActivity )?<Row className="align-items-center">
                        
                        {copyContentObject.attributes.fileType === "vimeo" &&
                            <Iframe
                                url={getVimeoUrl(copyContentObject.attributes.url)}
                                height="300"
                                style={{ cursor: "pointer" }}
                                display="initial"
                                scrolling={"no"}
                                allowFullScreen={true}
                                position="relative"
                                frameBorder={0}
                                className="mt-2"
                            />
                        }
                        {(copyContentObject.attributes.fileType === "image")? <Col className="col-auto p-2">
                            <img
                                data-dz-thumbnail=""
                                className="rounded mr-2 ml-3"
                                width="300"
                                alt={""}
                                src={copyContentObject.attributes.file._url}
                            />
                        </Col>
                        :
                            <div className="avatar ml-3 mt-3 border p-3">
                                <span className="avatar-title bg-white text-primary h1">
                                    <i className={getFileTypeImage(fileExtention)}></i>
                                </span>
                                <p>{copyContentObject.attributes.name}</p>
                            </div>
                        }
                    </Row>:
                    <Row className="align-items-center">
                        {fileType === "Image" && <Col className="col-auto p-2">
                            <img
                                data-dz-thumbnail=""
                                            
                                className="rounded mr-2 ml-3"
                                width="300"
                                alt={file.name}
                                src={preview}
                            />
                        </Col>}
                        {
                            fileType !== "Image" &&
                            <div className="avatar ml-3 mt-3 border p-3">
                                <span className="avatar-title bg-white text-primary h1">
                                    <i className={getFileTypeImage(fileExtention)}></i>
                                </span>
                                <p>{file.name}</p>
                            </div>
                        }
                    </Row>
                    }
                </Col>
            </Row>
        )
    }

    const addTopicUI = (category, activity) => {

        let activityName = activity && activity.attributes.name;
        let activityDescription = activity && activity.attributes.description;
        
        return (<Col className="border-top  " style={{ borderTopWidth: 3 }}>
            <Card className="mt-2">
                <CardBody>
            
                    <AvForm className="outer-repeater"
                        onValidSubmit={(e, v) => {
                            handelAddActivity(activity,v.activityName,v.activityDescription, category);
                        }}
                    >
                        <div data-repeater-item className="outer">
                            <FormGroup >
                                <Label htmlFor="formname">{activity ?props.t('Edit Topic') :props.t('Add New Topic')}: </Label>
                                <AvField
                                    type="text"
                                    name="activityName"
                                    placeholder={props.t('Topic name')}
                                    className="form-control"
                                    value={activityName}
                                    validate={{
                                        required: {
                                            value: true,
                                            errorMessage: props.t('Topic name can not be empty'),
                                        },
                                    }}
                                   
                                />
                            </FormGroup>
                            <FormGroup >
                                <Label htmlFor="formname">{props.t('Description')}: </Label>
                                <AvField
                                    type="textarea"
                                    name="activityDescription"
                                    placeholder={props.t('Description')}
                                    className="form-control"
                                    value={activityDescription}
                                    rows="4"
                                    
                                />
                            </FormGroup>
                            
                        
                            <button type="button"
                                className="btn btn-secondary m1"
                                onClick={() => {
                                    setShowNewTopic(undefined);
                                    
                                    setEditActivity(undefined);
                                    
                                }}  >
                                {props.t('Close')}
                            </button>
                            {
                                props.loadingState ? <button type="button" className="btn btn-dark waves-effect waves-light float-right m-1">
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                                </button> :
                                    <button
                                        type="submit"
                                        className="btn btn-primary waves-effect waves-light float-right m-1">
                                        <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save Topic')}
                                    </button>
                            }
                        </div>
                    </AvForm>
                </CardBody>
            </Card>
        </Col>)
    }

    return (
        <React.Fragment>

            {
                props.categoryLoadingState && <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                        </Label>
                    </div>
                
                </Col>
            }

            {reorderChapter  && <DragDropTables rearrangeObjects={props.learningCategories} updatedOrderList={ handelSortCategories} onClickClose={handelCloseCateogySort }/>}
            
            {!reorderChapter &&!props.categoryLoadingState && props.learningCategories &&  props.learningCategories.length>0 &&props.learningCategories.map((category, key) =>
                <Card key={key}>
                    <CardBody>
                        {(!editCategoryObject || editCategoryObject.id !== category.id) && <Row
                            className="px-2">
                            <Col lg={9} md={9} xs={9}
                                onClick={() => {
                                    setSecondaryResourseActivityId(undefined)
                                    if (key === tab) {
                                        setTab(undefined);
                                    } else {
                                        setTab(key);
                                    }
                                }}
                            >
                                <Row>
                                    <span><h5>{(key + 1)}. </h5></span>
                                    <span className="ml-2"><h5>{category.attributes.name} </h5></span>
                                    <span><h4 className={tab === key ? "bx bxs-chevron-up  ml-2  " : "bx bxs-chevron-down  ml-2 "} ></h4></span>
                                </Row>
                            </Col>
                            <Col lg={3} md={3} xs={3}>
                                <Row className="float-right">
                                        

                                    <ul className="list-inline font-size-20 contact-links mb-0">
                                        {canEdit && <li className="list-inline-item px-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setEditCategory(category);
                                    
                                            }}
                                            id={"sub-edit" + key}>
                                            <i className="bx bx-edit-alt"></i>
                                            <UncontrolledTooltip placement="top" target={"sub-edit" + key}>
                                                {props.t('Edit')}
                                            </UncontrolledTooltip>
                                        
                                        </li>}
                                        {canEdit && <li className="list-inline-item px-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                if (getActivitiesForCategoryId(category.id).length > 0) {
                                                    setDeleteWarning(true);
                                                } else {
                                                    setLoading(false);
                                                    setDeleteCategoryObject(category);
                                                }
                                            }}
                                            id={"sub-delete" + key}>
                                            <i className="bx bx-trash text-danger"></i>
                                            <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                {props.t('Delete')}
                                            </UncontrolledTooltip>
                                    
                                        </li>}

                                        {canEdit && getActivitiesForCategoryId(category.id).length > 0 && <li className="list-inline-item px-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                setTab(key);
                                                setReorderTopic(category.id)
                                                setShowNewTopic(undefined)
                                            }}
                                            id={"sub-reorder" + key}>
                                            <i className="bx bx-analyse "></i>
                                            <UncontrolledTooltip placement="top" target={"sub-reorder" + key}>
                                                {props.t('Reorder')}
                                            </UncontrolledTooltip>
                                    
                                        </li>}
                                            
                                        
                                        {canEdit && <li className="list-inline-item px-2"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                                handelAddTopic(category.id);
                                                setReorderTopic(undefined)
                                                setTab(key);
                                            }}
                                            id={"sub-new-topic" + key}>
                                            <i className="bx bx-plus-circle text-success"></i>
                                            <UncontrolledTooltip placement="top" target={"sub-new-topic" + key}>
                                                {props.t('New topic')}
                                            </UncontrolledTooltip>
                                    
                                        </li>}
                                            
                                    </ul>
                                    {/* {canEdit && getActivitiesForCategoryId(category.id).length > 0 && <button
                                        onClick={() => {
                                            setTab(key);
                                            setReorderTopic(category.id)
                                            setShowNewTopic(undefined)
                                        }}
                                        type="button"
                                        className="btn btn-sm btn-success waves-effect waves-light ml-2  "> <i className="bx bxs-analyse mr-1"></i>{props.t('Reorder')}
                                    </button>} */}

                                    {/* {canEdit && <button
                                        onClick={() => {
                                            handelAddTopic(category.id);
                                            setReorderTopic(undefined)
                                            setTab(key);
                                        }}
                                        type="button"
                                        className="btn btn-sm btn-success waves-effect waves-light ml-2  ">+ {props.t('New Topic')}
                                    </button>} */}
                                </Row>
                                    
                                
                            </Col>
                               
                           
                        </Row>}
                        {editCategoryObject && editCategoryObject.id === category.id && <Col>

                            <AvForm className="outer-repeater mt-3"
                                
                                onValidSubmit={(e, v) => {
                                    var name = v.name
                                    let description = v.description;
                                    let learningCategory = editCategoryObject;
                                    learningCategory.set("name", name);
                                    learningCategory.set("description", description);
                                    props.addLearningCategory(learningCategory);
                                    setEditCategory(undefined);
                                    
                                }}
                            >

                                <Row>

                                    <Col lg="10">
                                        <div data-repeater-item className="outer">
                                            <FormGroup >
                                                <AvField
                                                    type="text"
                                                    name="name"
                                                    placeholder={props.t('Chapter Name')}
                                                    className="form-control"
                                                    value={editCategoryObject.attributes.name}

                                                    validate={{
                                                        required: {
                                                            value: true,
                                                            errorMessage: props.t("Chapter name can not be empty"),
                                                        },
                                                    }}
                                                        
                                                />
                                                    
                                            </FormGroup>
                                            <FormGroup >
                                                <AvField
                                                    type="textarea"
                                                    name="description"
                                                    placeholder={props.t('Description')}
                                                    className="form-control"
                                                    value={editCategoryObject.attributes.description}
                                                    rows='5'
                                                />
                                                    
                                            </FormGroup>
                                        </div>
                                        
                                    </Col>
                                    <Col lg="2">
                                        <div className="list-inline font-size-20 contact-links mb-0 float-right">
                                            <button
                                                type="submit"
                                                className="btn btn-primary mr-1 "
                                            >
                                                {props.t('Save')}
                                            </button>
                                            <button type="button"
                                                className="btn btn-danger "
                                                onClick={() => {
                                                    setEditCategory(undefined);
                                                }}  >
                                                {props.t('Close')}
                                                    
                                            </button>
                                        </div>

                                    </Col>
                                </Row>
                                   
                                   
                                
                            </AvForm>
                                
                                

                        </Col>}
                            
                        <Collapse isOpen={tab === key}>
                            {tab === key && <Row>
                                <Col lg={12}>
                                 {category.attributes.description && <Alert style={{ WhiteSpace: "pre-wrap" }}>
                                    {category.attributes.description}
                                </Alert>}
                                </Col>
                                
                                <Col >
                                        
                                    {reorderTopic === category.id ?
                                        <DragDropTables rearrangeObjects={getActivitiesForCategoryId(category.id)} updatedOrderList={handelSortActivities} onClickClose={handelCloseActivitySort} /> :
                                        <div>
                                            {props.learningActivities && getActivitiesForCategoryId(category.id).map((activity, key) =>
                                                <Col lg={12} className="border-top mt-2 col-12" key={key}>
                                               
                                                    {editActivity && editActivity.id === activity.id ? <div>{addTopicUI(category, activity)}</div> : <Row >
                                                        <Col lg={0.5}>
                                                            {activity.attributes.fileType === "quiz" ?
                                                                <h5><i className="bx bxs-notepad float-right  mt-2 ml-2"></i></h5> :
                                                                activity.attributes.fileType === "meeting" ?
                                                                    <h5><i className="bx bxs-video-recording float-right  mt-2 ml-2"></i></h5> :
                                                                    <h5><i className="bx bxs-check-circle float-right  mt-2 ml-2"></i></h5>
                                                            }
                                                            
                                                        </Col>
                                                   
                                                        <Col lg={8}
                                                            onClick={() => {
                                                                if (activity.attributes.fileType === "quiz" ) {
                                                                    setActivityTab(undefined);
                                                                }else if (activity.id === activityTab) {
                                                                    setActivityTab(undefined);
                                                                    setSecondaryResourseActivityId(undefined)
                                                                } else {
                                                                    setActivityTab(activity.id);
                                                                    setSecondaryResourseActivityId(undefined)
                                                                }
                                                            }}
                                                        >
                                                            <h5 className="mt-1">
                                                                <span>{activity.attributes.name}</span>
                                                                {activity.attributes.fileType!=="quiz" &&<span><h4 className={activityTab === activity.id ? "bx bxs-chevron-up  ml-2  " : "bx bxs-chevron-down  ml-2 "} ></h4></span>}
                                                            
                                                            </h5>
                                                            {activity.attributes.description && <h6 className="text-muted ml-6">{"Description: " + activity.attributes.description}</h6>}
                                                            <h6 className="text-muted  ml-6">
                                                                {activity.attributes.month && activity.attributes.day ?
                                                                    "Month: " + activity.attributes.month + ", " + "Day: " + activity.attributes.day
                                                                    :
                                                                    ""}
                                                            </h6>
                                                             
                                                            {category.id === "CommonTopics" && activity.attributes.filterValue &&
                                                                activity.attributes.filterValue.map((option, key) =>
                                                                    <Badge key={key} className={"badge badge-soft-primary font-size-12 m-1"}>{option.length > 20 ? option.slice(0, 19) + "..." : option} </Badge>)

                                                            }
                                                           
                                                        </Col>
                                                        

                                                        <Col className="float-right">
                                                            {/* <h5><i className="bx bx-right-arrow-circle float-right  mt-2 ml-2"></i></h5> */}
                                                            <ul className="list-inline font-size-16 contact-links mb-0 mt-2 float-right">
                                                            
                                                                {canEdit && activity.attributes.fileType !=="quiz"&& <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setEditActivity(activity);
                                                                       
                                                                    }}
                                                                    id={"activity-edit" + key}>
                                                                    <i className="bx bx-edit-alt text-dark"></i>
                                                                    <UncontrolledTooltip placement="top" target={"activity-edit" + key}>
                                                                        {props.t('Edit')}
                                                                    </UncontrolledTooltip>
                                                        
                                                                </li>}
                                                                {canEdit && <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setDeleteActivity(activity)
                                                                    }}
                                                                    id={"activity-delete" + key}>
                                                                    <i className="bx bx-trash text-danger"></i>
                                                                    <UncontrolledTooltip placement="top" target={"activity-delete" + key}>
                                                                        {props.t('Delete')}
                                                                    </UncontrolledTooltip>
                                                    
                                                                </li>}

                                                               
                                                                
                                                            </ul>
                                                        </Col>
                                                    </Row>}
                                                    <Collapse isOpen={activityTab === activity.id}>

                                                        {/* {canEdit && <button
                                                            onClick={() => {
                                                                setActivityTab(activity.id)
                                                                setSelecetedActivity(activity);
                                                            }}
                                                            type="button"
                                                            className="btn btn-sm btn-success waves-effect waves-light ml-2  ">+ {props.t('Add Content')}
                                                        </button>} */}
                                                        { activityTab === activity.id && contentUI(category, activity)}

                                                    </Collapse>
                                                
                                                </Col>
                                            )}
                                        </div>}
                                </Col>
                            </Row>}
                            <Element name={"AddTopic" + category.id}> {showNewTopic && showNewTopic === category.id &&
                                <div>{addTopicUI(category)}</div>
                            }
                            </Element>
                        </Collapse>

                        {(tab !== key || true) && <Row className="border-top mt-2">
                               
                            {getActivitiesForCategoryId(category.id).length > 0 ? <Row className="mt-4 ml-4">
                                <h5 className="ml-3">{getActivitiesForCategoryId(category.id).length} </h5>
                                <h5 className="text-muted ml-2"
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { setTab(key) }}
                                >{"TOPICS"} </h5>

                                <Label onClick={() => {
                                    let list = getQuizActivityForCategory(category.id)
                                    if (list.length > 0) {
                                        setQuizActivity(list[0]);
                                    }
                                    setShowAddQuizModel(category)
                                }} className='ml-5 text-primary'>
                                    {getQuizActivityForCategory(category.id).length>0? "Replace Assessment to chapter": "Add Assessment to chapter"}
                                </Label>
                                    
                                {/* <h5 className="ml-3">{"8"} </h5>  <h5 className="text-muted ml-2">{"VIDEOS"} </h5> 

                                    <h5 className="ml-3">{"12"} </h5>  <h5 className="text-muted ml-2">{"DOCUMENTS/IMAGES"} </h5>  */}
                            </Row>
                                :
                                <Row className="mt-4 ml-4">
                                        
                                    <span><h5 className="ml-3">{"No topics found,"}</h5></span>
                                    {canEdit && <span><h5 className="text-primary ml-2"
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                            setTab(key);
                                            handelAddTopic(category.id)
                                        }}>
                                        {" Add topic?"}
                                    </h5></span>}
                                </Row>
                                
                            }
                        </Row>}


                        
                    </CardBody>
                </Card>
            )}

            

            <Element name="AddCategory">{
                showNewChapter&& props.learningCategories && props.learningCategories.length > 0&& <Col >
                    <Card>
                        <CardBody>
                            <AvForm className="outer-repeater"
                                    
                                onValidSubmit={(e, v) => {
                                    // handelAddSubcategory(newSubcategory);
                                    // setShowNewChapter(false);
                                    // setNewSubcategory("");

                                    handelAddCategory(v.name,v.description);
                                }}
                            >
                                <div data-repeater-item className="outer">
                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('New Chapter')}: </Label>
                                        <AvField
                                            type="text"
                                            name="name"
                                            placeholder={props.t('New Chapter...')}
                                            className="form-control"
                                            // value={"newSubcategory"}
                                            validate={{
                                                required: {
                                                    value: true,
                                                    errorMessage: props.t('Chapter name can not be empty'),
                                                },
                                            }}
                                            onChange={(e) => {
                                                // setNewSubcategory(e.target.value);
                                            }}
                                        />
                                                
                                    </FormGroup>
                                    <FormGroup >
                                        <Label htmlFor="formname">{props.t('Description')}: </Label>
                                        <AvField
                                            type="textarea"
                                            name="description"
                                            placeholder={props.t('Description...')}
                                            className="form-control"
                                            rows ='5'
                                            onChange={(e) => {
                                                // setNewSubcategory(e.target.value);
                                            }}
                                        />
                                                
                                    </FormGroup>
                                </div>

                                <button type="button"
                                    className="btn btn-secondary "
                                    onClick={() => {
                                        props.onCloseAddCategory(false);
                                        // setNewSubcategory("");
                                    }}  >
                                    {props.t('Close')}
                                </button>

                                <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light float-right ">
                                    <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save Chapter')}
                                </button>
                            </AvForm>
                        </CardBody>
                    </Card>
                </Col>
            }
            </Element>

            {(!props.learningCategories || props.learningCategories.length === 0) && !props.categoryLoadingState && <Card>
                <CardBody>
                    {canEdit ? <Row >
                        <Col sm={7}>
                            <AvForm className="outer-repeater"
                                onValidSubmit={(e, v) => {
                                    setLoading(true);
                                    handelAddCategory(v.name,v.description);
                                }}
                            >
                                <div data-repeater-item className="outer">
                                    <FormGroup >
                                        <Label ><h3 className="mt-5">{props.t('Add a chapter ?')}</h3> </Label>
                                        <AvField
                                            type="text"
                                            name="name"
                                            placeholder={props.t('Chapter Name')}
                                            className="form-control"
                                            
                                            required
                                            errorMessage={props.t('Chapter name can not be empty')}
                                                
                                            
                                        />
                                        
                                    </FormGroup>
                                     <FormGroup >
                                        <Label htmlFor="formname">{props.t('Description')}: </Label>
                                        <AvField
                                            type="textarea"
                                            name="description"
                                            placeholder={props.t('Description...')}
                                            className="form-control"
                                            rows ='5'
                                            onChange={(e) => {
                                                // setNewSubcategory(e.target.value);
                                            }}
                                        />
                                                
                                    </FormGroup>
                                </div>
                            
                                {loading ?
                                    <button type="button" className="btn btn-dark waves-effect waves-light  m-2">
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle "></i>  {props.t('Saving')}
                                    </button>
                                    :
                                    <button 
                                        className="btn btn-primary "
                                        type="submit"
                                    >
                                        {props.t('Add')}
                                    </button>}
                            </AvForm>
                        </Col>
                        <Col sm={5}>
                            <div className="maintenance-img">
                                <img src={contentImage} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>

                    </Row> :
                        <CardSubtitle>{props.t('No Subjects found')}</CardSubtitle>
                    }
                </CardBody>
        
            </Card>}

            { deleteCategoryObject || deleteActivity ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        let obj = deleteCategoryObject ? deleteCategoryObject : deleteActivity;
                        if (obj.className === "LearningCategory") {
                            props.deleteLearningCategory(deleteCategoryObject);
                            setDeleteCategoryObject(undefined);
                            setsuccess_dlg("LearningCategory"); 
                        } else {
                            props.deleteLearningActivity(deleteActivity);
                            setDeleteActivity(undefined);
                            setsuccess_dlg("LearningActivity"); 
                        }
                        
                    }}
                    onCancel={() =>{
                        setDeleteCategoryObject(undefined);
                        setDeleteActivity(undefined);
                        setsuccess_dlg(false);
                    }}
                >
                    {deleteCategoryObject ?
                        props.t("You won't be able to revert this!") :
                        props.t("On Delete of this topic, you are deleting all the content under this topic. You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            { deleteFile ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                         props.deleteFile(deleteFile)
                            setDeleteFile(undefined);
                            setsuccess_dlg("file"); 
                    }}
                    onCancel={() =>{
                        setDeleteFile(undefined);
                        setsuccess_dlg(false);
                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {success_dlg === "LearningActivity"?props.t('Your topic has been deleted.'):success_dlg==="file" ?props.t('Selected content has been deleted.') :props.t('Your chapter has been deleted.')}
                </SweetAlert>
            ) : null}
            {
                deleteWarning && <SweetAlert
                    info
                    title={props.t('Alert')}
                    onConfirm={() => {
                        setDeleteWarning(false)
                    }}
                >
                    {props.t('Please delete all the topics under chapter to delete chapter')}
                </SweetAlert>
            }

            {lightBox && lightBox.show ? (
                <Lightbox
                    mainSrc={lightBox.images}
                    enableZoom={false}
                    imageCaption={
                        lightBox.name
                    }
                    onCloseRequest={() => { setLightBox({ show: false, images: [] }) }}
                />
            ) : null}
            <Modal
                size="xl"
                isOpen={showModel}
                toggle={() => { setShowModel(!showModel) }}
                scrollable={true}
            >
                <div className="modal-header">

                    

                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        {clickedFile && clickedFile.attributes.name}
                    </h5>
            
            
                    {
                       clickedFile && clickedFile.attributes.fileType !== "url" && (clickedFile.attributes.downloadable === undefined ||
                            clickedFile.attributes.downloadable === null ||
                            clickedFile.attributes.downloadable === true) && <a
                        
                                href={getURL()}
                                download={getFileName()}
                        
                                target="_blank"
                                type="button"
                                className="btn btn-sm btn-primary waves-effect waves-light ml-4">
                            <i className="bx bx-download font-size-14 align-middle"></i> {props.t('Download ')}
                        </a>
                    }
                    <button
                        onClick={() => {
                            setShowModel(false)
                            setClickFile(undefined);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody >
                    {
                        clickedFile && clickedFile.attributes.fileType === "url" ?
                            <Iframe
                            url={getURL()}
                            height="600"
                            width="100%"
                            display="initial"
                            allowFullScreen={true}
                            position="relative"
                                frameBorder={0}
                            >
                        </Iframe> :
                        <div>
                            {getURL() && getURL().endsWith(".pdf") ? <Iframe
                                url={getURL() + "#toolbar=0"}
                                height="600"
                                width="100%"
                                display="initial"
                                allowFullScreen={true}
                                position="relative"
                                frameBorder={0}
                            
                            >
                            </Iframe> :

                                <DocViewer
                                    pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer,]}
                                    style={{ height: "500px" }}
                                    documents={getFR(getURL())}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: false,
                                            retainURLParams: false
                                        }
                                    }}
                    
                                />
                            }
                        </div>
                        
                    }

                    <Card style={{ backgroundColor: "#fff", marginTop: "-7mm", width: "100%", height: " 7mm" }} />

                    

                
                </ModalBody>
            </Modal>

            <Modal
                size="lg"
                isOpen={showAddQuizModel?true:false}
                toggle={() => {
                    setShowAddQuizModel(undefined);
                    setQuizActivity(undefined);
                }}
                scrollable={true}
            >

                 <ModalHeader className="bg-light" toggle={() => {
                    setShowAddQuizModel(undefined);
                    setQuizActivity(undefined);
                }}>
                    <Row style={{ width: "100%" }} >
                        <Col lg={6} md={6} sm={6} xs={12}><Label className='mt-1'>Assessments/Exams</Label></Col>
                        <Col lg={6} md={6} sm={6} xs={12} >

                            <div className="search-box p-0 float-right ">
                                <div className="position-relative">
                                    <Input type="text"
                                        className="form-control border-0"
                                        placeholder="Search..."
                                        onChange={(e) => {
                                            handelExamList(e.target.value)
                                        }}
                                    />
                                    <i className="bx bx-search-alt search-icon"></i>
                                </div>
                            </div>
                        </Col>
                    </Row>
                   
                </ModalHeader>

                 <ModalBody
                    style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
                >
                    <div className="table-responsive ">
                        {examDisplayList && examDisplayList.length > 0 ? <Table className="table-centered table-nowrap table-hover">
                            <thead >
                                <tr>
                                    <th scope='col' className="col-1">
                                        <div>#</div>
                                    </th>

                                    <th className="col-6" scope="col">Name</th>

                                    <th className="col-5" scope="col" style={{ textAlign: "center" }}>
                                       Action
                                    </th>
                                </tr>
                            </thead>
                            <tbody>
                                {examDisplayList.map((exam, key) => (
                                    <tr key={exam.id}>
                                        <td>
                                           {(key+1)} 
                                        </td>

                                        <td>
                                        
                                            <Label className='mt-2'>{exam.attributes.name}</Label>
                                            
                                        </td>
                                        <td style={{ textAlign: "center" }}>
                                            <button
                                                className='btn btn-sm btn-primary'
                                                disabled ={quizActivity && quizActivity.attributes.quizId===exam.id ?true:false}
                                                onClick={() => {
                                                    handelAddActivity(quizActivity, exam.attributes.name, "Assessment", showAddQuizModel, exam.id);
                                                    setShowAddQuizModel(undefined);
                                                    setQuizActivity(undefined);
                                                }}
                                            >
                                               Attach 
                                           </button>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </Table> : <Card>
                            <CardBody className="text-center" >

                                <h3 className="mt-5">{
                                    props.exams && props.exams.length === 0 &&
                                        props.t('There are no Assessments to attach, Please  add one and try attaching') 
                                       
                                }</h3>
                                    {props.exams && props.exams.length > 0 ?
                                        <p>{props.t('No Assessments avilable')}.</p>:null}


                            </CardBody>
                        </Card>}
                    </div>

                </ModalBody>

            </Modal>
                
        </React.Fragment>
    );
}
        
const mapStatetoProps = state => {
    const { learningActivities, loadingState } = state.LearningActivity;
    const { learningCategories, categoryLoadingState } = state.LearningCategory;
    const { files, loadingFileState, fileAddState } = state.Files;
    const { exams } = state.Exam;
    const { selectedSchool } = state.School;
    return {learningActivities, loadingState ,learningCategories,categoryLoadingState,files,fileAddState,exams,selectedSchool};
}
            
export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    getLearningCategoryForCourseId,
    addLearningCategory,
    getLearningActivitiesForCourseId,
    setLearningActivityLoadingState,
    addLearningActivity,
    reorderActivities,
    reorderCatrgories,
    deleteLearningActivity,
    deleteLearningCategory,
    AddFile,
    deleteFile,
    getFilesForCourseId,
    getExamsForSchoolIds,
})(ContentCreation)));