
export const SAVE_GROUP = 'SAVE_GROUP';
export const SAVE_ARCHIVE_GROUP = "SAVE_ARCHIVE_GROUP";
export const UPDATE_RESULT_GROUP = "UPDATE_RESULT_GROUP";


export const GET_ALL_ARCHIVE_GROUPS_FOR_SCHOOL_ID = "GET_ALL_ARCHIVE_GROUPS_FOR_SCHOOL_ID";


export const GET_ALL_GROUPS_FOR_SCHOOL_ID = 'GET_ALL_GROUPS_FOR_SCHOOL_ID';
export const UPDATE_GROUPS = 'UPDATE_GROUPS';
export const UPDATE_ARCHIVE_GROUPS = "UPDATE_ARCHIVE_GROUPS";
export const DELETED_SUCCESSFULLY = "DELETED_SUCCESSFULLY";


export const GET_GROUP = 'GET_GROUP';
export const UPDATE_GROUP = "UPDATE_GROUP";
export const UPDATE_ARCHIVE_GROUP = "UPDATE_ARCHIVE_GROUP";


export const SINGLE_GROUP_UPDATE = "SINGLE_GROUP_UPDATE";
export const DELETE_GROUP = "DELETE_GROUP";


export const GROUP_SUCCESS = "GROUP_SUCCESS";
export const GROUP_ERROR = "GROUP_ERROR";

export const UPDATE_GROUPS_FOR_IDS = "UPDATE_GROUPS_FOR_IDS";
export const GET_GROUPS_FOR_IDS = "GET_GROUPS_FOR_IDS";

export const SET_LOADING = "SET_LOADING";




