import ReactApexChart from 'react-apexcharts';
const Spinearea = (props) => {

    const getEnquiries = () => {
        let list = [];
        if (props.selectedMonths) {
            for (const i in props.selectedMonths) {
                let t1 = props.selectedMonths[i].enquiryCount;
                let t2 = props.selectedMonths[i].admissionCount;
                if (t1 === 0 || t2 === 0) {
                    list.push(0);
                } else {
                   let rate = t2 * 100 / t1;
                 list.push(rate.toFixed(2) ); 
                }
                
                
            }
        }
        return list;
    }

    const getInvoice = () => {
        let list = [];
        if (props.selectedMonths) {
            for (const i in props.selectedMonths) {
                let t1 = props.selectedMonths[i].invoiceAmount;
                let t2 = props.selectedMonths[i].transactionAmount;
                if (t1 === 0 || t2 === 0) {
                    list.push(0);
                } else {
                   let rate = t2 * 100 / t1;
                 list.push(rate.toFixed(2) ); 
                }
                
                
            }
        }
        return list;
    }

    const getMonths = () => {
        let list = [];
        if (props.selectedMonths) {
            for (const mon of props.selectedMonths) {
                list.push(mon.date+"")
            }
        }
        return list;
    }

    const series = [{
        name: 'Conversion Rate ',
        data: getEnquiries()
    }];

    const series2 = [{
        name: 'Collection Rate ',
        data: getInvoice()
    }];

    const options = {
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 3,
        },
                
        colors: ['#556ee6', '#34c38f'],
        xaxis: {
            type: 'datetime',
            categories: getMonths(),
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        tooltip: {
            x: {
                format: 'MMM yyyy'
            }
        },
        chart: {
          id:"rateOfIncreaseChart"
        }
    };

    return (
        <ReactApexChart options={options} series={props.type==="fee"?series2:series} type="area" height="300" />
    );
}

export default Spinearea;