import ReactApexChart from 'react-apexcharts';
import Moment from "moment";

const Spinearea = (props) => {
    const getEnquiries = () => {
        let list = [];
        if (props.enquiryAnalytics) {
            for (const ea of props.enquiryAnalytics) {
                list.push(ea.attributes.newEnquiries ); 
            }
        }
        return list;
    }

    const getDays = () => {
        let list = [];
        if (props.enquiryAnalytics) {
            for (const ea of props.enquiryAnalytics) {
                // list.push(Moment(ea.attributes.createdAt).format('DD-MMM'))
                list.push(ea.attributes.createdAt+"");
            }
        }
        return list;
    }

    const series = [{
        name: 'Enquiries',
        data: getEnquiries()
    }];

    

    const options = {
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 3,
        },
                
        colors: ['#556ee6', '#34c38f'],
        xaxis: {
            type: 'datetime',
            categories: getDays(),
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        tooltip: {
            x: {
                format: 'dd MMM yyyy'
            }
        },
        chart: {
          id:"enquiryTrend"
        }
    };

    return (
        <ReactApexChart options={options} series={series} type="area" height="300" />
    );
}

export default Spinearea;