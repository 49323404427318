
import React,{useState,useEffect} from 'react';
import { Container, Row, Col,FormGroup, Alert, Label, Modal,ModalBody,Button } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { connect } from 'react-redux';
import {
    showPricingPopup,
    setSelecetdSchool
} from "../../store/actions";
import CardPricing from "./PricingCard";
import logo from "../../assets/images/logo-dark.png";
import Parse from 'parse';
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField} from "availity-reactstrap-validation";


const PagesPricing = (props) => {


    const pricings = [
        {
            id: 1, title: "Basic",
            description: "",
            icon: "bx-run",
            price: "31,999",
            priceBefore: "39750",
            duration: "Per year",
            link: "",
            features: [
                { title: "lilTriangle basic app package", title2: "(50 Children) " },
                { title: "Basic digital marketing" },
                { title: "Basic school website"},
                
            ]
        },
        {
            id: 2, title: "Standard",
            description: "",
            icon: "bx-cycling",
            price: "49,999",
            priceBefore: "61750",
            duration: "Per year",
            link: "",
            features: [
                { title: "lilTriangle complete app package ",title2: "(50 Children) " },
                { title: "Basic digital marketing" },
                { title: "Standard school website ", title2: "(Domain, Hosting, Security)" },
                { title: "Professional photography" },
                { title: "Teacher training", title2: "(6 days, upto 7 teachers)" },
                
            ]
        },
        {
            id: 3, title: "Advanced",
            description: "",
            icon: "bx-car",
            price: "74999",
            priceBefore: "83250",
            duration: "Per year",
            link: "",
            features: [
                { title: "lilTriangle Complete App package", title2: "(75 Children)" },
                { title: "Digital Marketing" },
                { title: "Advanced school website", title2:"(Domain, Hosting, Security,SEO)" },
                { title: "Professional photography" },
                { title: "Teacher training", title2: "(6 days, upto 10 teachers)" },
                { title: "2 CCTV camera" },
                
            ]
        },
        {
            id: 4, title: "Premium",
            description: "",
            icon: "bxs-plane",
            price: "99999",
            priceBefore: "130000",
            duration: "Per year",
            link: "",
            features: [
                { title: "lilTriangle Complete App package", title2: " (100 Children)"},
                { title: "Digital Marketing" },
                { title: "Advanced school website", title2:"(Domain, Hosting, Security,SEO)" },
                { title: "Professional photography" },
                { title: "Teacher training ", title2: "(6 days, upto 15 teachers)" },
                { title: "4 CCTV camera" },
               
            ]
        },
        {
            id: 5, title: "Ultimate",
            description: "",
            icon: "bxs-rocket",
            price: "249999",
            priceBefore: "355500",
            duration: "Per year",
            link: "",
            features: [
                { title: "lilTriangle Complete App package", title2: "(250 Children) " },
                { title: "Digital Marketing" },
                { title: "Advanced school website", title2:"(Domain, Hosting, Security,SEO)" },
                { title: "Professional photography" },
                { title: "Teacher training ", title2: "(6 days, upto 30 teachers)" },
                { title: "10 CCTV camera" },
                
            ]
        },
    ];

    const [isCheked, setIsChecked] = useState(false);

    const schoolId = localStorage.getItem("SELECTED_SCHOOL_ID");
    useEffect(() => {
        if (Parse.User.current() && (!props.selectedSchool || props.selectedSchool.id !== schoolId)) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    const handelClosePopup = () => {
        if (isCheked) {
            localStorage.setItem("DONT_SHOW_PRICING_POPUP_AGAIN", "true");
        }
        localStorage.setItem("SHOW_PRICING_POPUP", "nono");
        props.showPricingPopup(false);
    }

    const [enquiried, setEnquiried] = useState(false)
    const [successAlert, setSuccessAlert] = useState(false);
    const [showLoading, setShowLoading] = useState(0);
    const [showUserInfoPopup, setShowUserInfoPopup] = useState(false);
    const [success_dlg, setsuccess_dlg] = useState(false);

    //

    const [userName, setUserName] = useState("");
    const [schoolName, setSchoolName] = useState("")
    const [email, setEmail] = useState("")
    const [phoneNumber, setPhoneNumber] = useState("")
    const [schoolLocation, setSchoolLocation]=useState("")



    const handelEnquireNow = (pricingObject) => {
        
        if (Parse.User.current() && props.selectedSchool) {
            setShowLoading(pricingObject.id);
            const lilPackageLeads = Parse.Object.extend("lilPackageLeads");
            const packageLeads = new lilPackageLeads();
            packageLeads.set("schoolName", props.selectedSchool.attributes.Name);
            packageLeads.set("email", props.selectedSchool.attributes.EMail);
            packageLeads.set("phoneNumber", props.selectedSchool.attributes.primaryPhoneNumber);
            packageLeads.set("location", props.selectedSchool.attributes.city);
            packageLeads.set("schoolId", props.selectedSchool.id);

            packageLeads.set("packageSelected", pricingObject.title);
            

            if (Parse.User.current().attributes.teacherId) {
                packageLeads.set("userId", Parse.User.current().attributes.teacherId);
            } else {
                packageLeads.set("userId", Parse.User.current().id);
            }

            packageLeads.save().then(
                (result) => {
                    setShowLoading(0)
                    setSuccessAlert(true)
                    
                    // localStorage.setItem("DONT_SHOW_PRICING_POPUP_AGAIN", "true");
                    // localStorage.setItem("SHOW_PRICING_POPUP", "nono");
                    // props.showPricingPopup(false);
                },
                (error) => {
            
                }
            );
            
        } else {
            //show popup
            setShowUserInfoPopup(pricingObject);

        }
    }
    const submitFormValid = () => {
        setShowLoading(7);
        const lilPackageLeads = Parse.Object.extend("lilPackageLeads");
        const packageLeads = new lilPackageLeads();
        packageLeads.set("name", userName);
        packageLeads.set("schoolName", schoolName);
        packageLeads.set("email", email);
        packageLeads.set("phoneNumber", phoneNumber);
        packageLeads.set("location", schoolLocation);
        packageLeads.set("packageSelected", showUserInfoPopup.title);
        packageLeads.save().then(
            (result) => {
                setShowLoading(0);
                setShowUserInfoPopup(undefined);
                setsuccess_dlg(true)
                    
            },
            (error) => {
            
            }
        );
    }
    return (
        <React.Fragment>
            <div >
                <Container fluid>

                    {/* Render Breadcrumbs */}

                    <Row className="justify-content-center">

                        {!props.isPopup && <Col lg={3}>
                            
                            <div className="text-center mb-3">
                                <img src={logo}
                                    height="70px"
                                    className="m-2"
                                />
                            </div>
                        </Col>}
                        <Col lg={10}>
                            
                            <div className="text-center mb-3">
                                <h4>Choose Your Plan</h4>
                                <p className="text-muted">Along with current features, After thorough study and analysis lilTriangle offering new and exciting features to make your school to stand out and become choice of parents. Please select plan or connect to our experts at lilTriangle for more details.</p>
                            
                            
                                {successAlert && <Alert colr="success" > Thank you for showing interest, One of our executive will  be in touch with you soon.
                                    <Label
                                        className="ml-5"
                                        onClick={() => {
                                            localStorage.setItem("DONT_SHOW_PRICING_POPUP_AGAIN", "true");
                                            localStorage.setItem("SHOW_PRICING_POPUP", "nono");
                                            props.showPricingPopup(false);
                                        }}
                                    >Close</Label> </Alert>
                                }
                            </div>
                        </Col>
                    </Row>

                    <Row className="justify-content-center">
                        

                        {
                            pricings.map((pricing, key) =>
                                <CardPricing
                                    pricing={pricing}
                                    key={"_pricing_" + key}
                                    onClickEnquireNow={handelEnquireNow}
                                    showLoading={showLoading}
                                />
                            )
                        }

                        
                    </Row>

                    {props.isPopup && <Row className="justify-content-center" >
                        <div className="text-center ">
                            {props.autoPopup && <FormGroup>
                                <div className="custom-control custom-checkbox mb-2 ml-3" >
                                    <input type="checkbox" className="custom-control-input" id="instituteCheck" onChange={() => false} checked={isCheked} />
                                    <label className="custom-control-label" onClick={() => { setIsChecked(!isCheked) }} >{"Don't show again"}</label>
                                </div>
                            </FormGroup>}
                            <button className="btn btn-secondary"
                                onClick={() => { handelClosePopup() }}
                            >
                                Close
                            </button>
                        </div>
                    </Row>}
                </Container>
            </div>

            <Modal isOpen={showUserInfoPopup} scrollable={true}>
                {/* <ModalHeader isOpen={showUserInfoPopup}>
                    <div className="modal-title mt-0">Registration</div>
                </ModalHeader> */}
                <ModalBody>
                    <Row>
            
                        <Col lg={12} className="mx-auto">
                            <AvForm
                                className="outer-repeater"
                                style={{ border: "1px" }}
                                onValidSubmit={(e, v) => {
          
            
                                   submitFormValid();
         
       
                                }}
                  
                            >
                                <div data-repeater-list="outer-group" className="outer">
                                    <div data-repeater-item className="outer">
                      

                                        <FormGroup>
                                            <Label htmlFor="cowinformSingleLine">Name  <span className="text-danger">*</span></Label>

                                            <AvField
                                                type="text"
                                                id={"cowinformSingleLineName"}
                                                name={"cowinformSingleLineName"}
                                                placeholder={"Type your Name here"}
                                                className="form-control"
                                                required
                                                errorMessage="Please enter your name"
                                                onChange={(e) => {
                                                    setUserName(e.target.value);
                                                }}
                                            />
                                        </FormGroup>
                                        
                                        <FormGroup>
                                            <Label htmlFor="cowinformSingleLine">School Name  <span className="text-danger">*</span></Label>

                                            <AvField
                                                type="text"
                                                id={"cowinformSingleLineName"}
                                                name={"cowinformSingleLineName"}
                                                placeholder={"Type School Name here"}
                                                className="form-control"
                                                required
                                                errorMessage="Please enter school name"
                                                onChange={(e) => {
                                                    setSchoolName(e.target.value);
                                                }}
                                            />
                                        </FormGroup>
                                        <FormGroup>
                                            <Label htmlFor="cowinformSingleLinePhone">
                                                Phone Number  <span className="text-danger">*</span>
                                            </Label>

                                            <AvField
                                                type="text"
                                                id={"cowinformSingleLinePhone"}
                                                name={"cowinformSingleLinePhone"}
                                                placeholder={"Type number here"}
                                                className="inner form-control noscroll"
                                                onChange={(e) => {
                                                    setPhoneNumber(e.target.value);
                                                }}
                                                required={(phoneNumber && phoneNumber.length > 0 || email && email.length > 0) ? false : true}
                                                errorMessage="Please enter your phone number"
                                                validate={{
                                                    pattern: {
                                                        value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                                                        errorMessage: props.t(
                                                            "You have entered an invalid phone no!"
                                                        ),
                                                    },
                                                    minLength: { value: 10 },
                                                    maxLength: { value: 16 },
                                                }}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="cowinformSingleLineEmail">
                                                Email  <span className="text-danger">*</span>
                                            </Label>

                                            <AvField
                                                type="email"
                                                id={"cowinformSingleLineEmail"}
                                                name={"cowinformSingleLineEmail"}
                                                placeholder={"Type email here"}
                                                className=" form-control "
                                                required={(phoneNumber && phoneNumber.length > 0 || email && email.length > 0) ? false : true}
                                                errorMessage="Please enter your email"
                                                onChange={(e) => {
                                                    setEmail(e.target.value);
                                                }}
                                                validate={{
                                                    pattern: {
                                                        value:
                                                            "^[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$",
                                                        errorMessage: props.t(
                                                            "You have entered an invalid email address!"
                                                        ),
                                                    },
                                                }}
                                            />
                                        </FormGroup>

                                        <FormGroup>
                                            <Label htmlFor="cowinformSingleLine">School Location  <span className="text-danger">*</span></Label>

                                            <AvField
                                                type="text"
                                                id={"cowinformSingleLineName"}
                                                name={"cowinformSingleLineName"}
                                                placeholder={"City/Area"}
                                                className="form-control"
                                                required
                                                errorMessage="Please enter school location"
                                                onChange={(e) => {
                                                    setSchoolLocation(e.target.value);
                                                }}
                                            />
                                        </FormGroup>

                      

                                        <div style={{ display: "flex" }} className="float-right">

                                            {showLoading===0 ? (
                                                <div >
                                                    <button
                                                        type="button"
                                                        className="btn btn-secondary mr-2"
                                                        onClick={() => {
                                                            setShowUserInfoPopup(false);
                                                        }}
                                                    >
                                                        {props.t("Close")}
                                                    </button>
                                                    <Button
                                                        style={{
                                                            marginLeft: "auto",
                                                            marginRight: "auto",
                                                            width: "180px",
                                                        }}
                                                        type="submit"
                                                        color="primary"
                                                    >
                                                        {props.t("SUBMIT")}
                                                    </Button>
                                                </div>
                                            ) : (
                                                <button type="button" className="btn btn-dark waves-effect">
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>
                                                    {props.t(" Saving")}
                                                </button>
                                            )}
                        
                                        </div>
                                    </div>
                                </div>
                            </AvForm>
                        </Col>
                    </Row>

                </ModalBody>

          
            </Modal>

            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('OK')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    
                    {"Thank you for showing interest, One of our executive will  be in touch with you soon."}
                </SweetAlert>
            ) : null}
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { pricingPopup } = state.Layout;
    const { selectedSchool } = state.School;
    return {
        selectedSchool,
        pricingPopup,
    };
};

export default connect(mapStatetoProps, {
    setSelecetdSchool,
  showPricingPopup,
})(withNamespaces()(PagesPricing));
