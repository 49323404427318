import * as actions from "./actionTypes";

const initialState = {
  learningSubcategories: [], subcategoryState: "initial",lscReplace:false
};

const LearningSubcategory = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_LEARNING_SUBCATEGORY:
      {
        let learningSubcategories = [...state.learningSubcategories];
        let temp = state.learningSubcategories.filter(
          (learningSubcategory) =>
          learningSubcategory.id === action.payload.learningSubcategory.id
        );
        if (temp.length > 0) {

          if (action.payload.learningSubcategory.attributes.isDeleted) {

            let others = state.learningSubcategories.filter(
              (learningSubcategory) =>
              learningSubcategory.id !== action.payload.learningSubcategory.id
            );
            state = { ...state, learningSubcategories: others ,lscReplace:false};
            
          } else {
            let index = learningSubcategories.indexOf(temp[0]);
            learningSubcategories[index] = action.payload.learningSubcategory;
            state = { ...state, learningSubcategories: learningSubcategories ,lscReplace:false};
          }

        
        } else {
          if (!action.payload.learningSubcategory.attributes.isDeleted) {
              learningSubcategories.push(action.payload.learningSubcategory);
            state = { ...state, learningSubcategories: learningSubcategories ,lscReplace:false};
          }
        }
      }
      break;
    case actions.UPDATE_LEARNING_SUBCATEGORYS:
      {
        let learningSubcategories = [...state.learningSubcategories];
        if (action.payload.type === "replace") {
          state = { ...state, learningSubcategories: action.payload.learningSubcategories,lscReplace:true };
        } else if (learningSubcategories.length > 0) {
          for (const latest in action.payload.learningSubcategories) {
            let temp = learningSubcategories.filter(
              (learningSubcategory) =>
                learningSubcategory.id === action.payload.learningSubcategories[latest].id
            );

            
            if (temp.length > 0) {
              if (action.payload.learningSubcategories[latest].attributes.isDeleted) {

                let others = learningSubcategories.filter(
                  (learningSubcategory) =>
                    learningSubcategory.id !== action.payload.learningSubcategories[latest].id
                );
              
                learningSubcategories = others;
              } else {
                let index = learningSubcategories.lastIndexOf(temp[0]);
                learningSubcategories[index] = action.payload.learningSubcategories[latest];
              }
            
            } else {
              if (!action.payload.learningSubcategories[latest].attributes.isDeleted) {
                learningSubcategories.push(action.payload.learningSubcategories[latest]);
              }
            }
          }
          state = { ...state, learningSubcategories: learningSubcategories ,lscReplace:false};
        } else if (action.payload.learningSubcategories) {
          let list = action.payload.learningSubcategories.filter(
            (learningSubcategory) =>
              !learningSubcategory.attributes.isDeleted
          );
          state = { ...state, learningSubcategories: list ,lscReplace:false};
          
        }
      }
      break;
    case actions.DELETE_LEARNING_SUBCATEGORY:
      let learningSubcategories = state.learningSubcategories.filter(
        (learningSubcategory) => learningSubcategory.id !== action.payload.id
      );
      state = { ...state, learningSubcategories: learningSubcategories };
      break;
    case actions.CLEAR_LEARNING_SUBCATEGOERY:
      state = { ...state, learningSubcategories: [] };
      break;
    case actions.SET_LEARNING_SUBCATEGOERY_STATE:
      state = { ...state, subcategoryState: action.payload.state };
      break;
     
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default LearningSubcategory;
