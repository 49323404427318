import React from "react";
import { Row, Col, Form, Button, UncontrolledTooltip, Media } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";
import { withNamespaces } from "react-i18next";

const Topbar = (props) => {
  // return(
  //     <Row className="mb-3">
  //         <Col xl="4" sm="6">
  //             <div className="mt-2">
  //                 <h4 className="mb-0 font-size-18">
  //                     <Row>
  //                         {props.backButton && <Col sm="1" xl="1" md="1" style={{marginTop : '-0.5rem'}}>
  //                             {props.backButton && <i onClick={() => { props.onClickBackButton()}} className="bx bx-chevron-left h1 text-justify text-primary"></i>}
  //                         </Col>}
  //                         <Col sm="11" xl="11" md="11">
  //                             <span>{props.t(props.title.toUpperCase()) }</span>
  //                         </Col>
  //                     </Row>
  //                 </h4>
  //             </div>
  //         </Col>
  //         {!props.hideButton && <Col lg="8" sm="6">
  //             <button onClick={() => props.onClickAdd()} className="btn btn-success float-right"><i className="mdi mdi-plus mr-1"></i>{props.t(props.buttonName)}</button>
  //         </Col>}
  //     </Row>
  // )

  return (
    <Row className="navbar-header p-0">
      <div className="d-flex">
        {props.backButton ? (
          <div className="navbar-header p-0">
            <div
              style={{ cursor: "pointer" }}
              onClick={() => {
                props.onClickBackButton();
              }}
            >
              <ul className="m-2 list-unstyled">
                <div>
                  <i className="bx bx-chevron-left h1 text-primary"></i>
                </div>
              </ul>
            </div>

            <h4>{props.title}</h4>
          </div>
        ) : (
          <h4 className="ml-2">{props.title}</h4>
        )}
      </div>
      {!props.hideButton && (
        <div className="d-flex">
          <Form className=" mt-sm-0 float-sm-right form-inline">
            <Button
              onClick={() => props.onClickAdd()}
              type="button"
              color="success"
              className="btn waves-effect waves-light  mb-2"
            >
              <i className="mdi mdi-plus mr-1"></i> {props.buttonName}
            </Button>
          </Form>
        </div>
      )}
    </Row>
  );
};
export default withNamespaces()(Topbar);
