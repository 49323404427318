export const UPDATE_TICKET = "UPDATE_TICKET";
export const DELETE_TICKET = "DELETE_TICKET";
export const UPDATE_DELETE_TICKET = "UPDATE_DELETE_TICKET";
export const UPDATE_TICKETS = "UPDATE_TICKETS";
export const GET_TICKET = "GET_TICKET";
export const GET_TICKETS = "GET_TICKETS";
export const EDIT_TICKET = "EDIT_TICKET";
export const SET_TICKET = "SET_TICKET";
export const ADD_TICKET = "ADD_TICKET";
export const SET_SELECTED_TICKET = "SET_SELECTED_TICKET";
export const UPDATE_SELECTED_TICKET = "UPDATE_SELECTED_TICKET";
export const CLEAR_TICKETS="CLEAR_TICKETS";
export const SET_SELECTED_TAB="SET_SELECTED_TAB";


//TICKET LOG
export const ADD_TICKET_LOG = "ADD_TICKET_LOG";
export const UPDATE_TICKET_LOG = "UPDATE_TICKET_LOG";
export const SET_SELECTED_TICKET_LOG = "SET_SELECTED_TICKET_LOG";
export const UPDATE_SELECTED_TICKET_LOG = "UPDATE_SELECTED_TICKET_LOG";
export const GET_TICKETS_FOR_ASSIGNED_FOR = "GET_TICKETS_FOR_ASSIGNED_FOR";
export const DELETE_TICKET_LOG = 'DELETE_TICKET_LOG';