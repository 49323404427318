import { takeEvery, takeLeading,fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as AdmissionQuestionHelper from "../../../helpers/ParseHelpers/admissionQuestion_helper";
import * as actionTypes from "./actionTypes";
import * as AdmissionQuestionActions from "./actions";
import * as Constants from '../../../Constents';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getAAdmissionQuestion({ payload: { id } }) {
  try {
    const response = yield call( AdmissionQuestionHelper.getAdmissionQuestionFromParse, id,false);
    yield put(AdmissionQuestionActions.updateAdmissionQuestion(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteAdmissionQuestion({ payload: { admissionQuestion } }) {
  try {
    admissionQuestion.set("isDeleted", true);
    yield put(AdmissionQuestionActions.updateAdmissionQuestion(admissionQuestion));
    const response = yield call(
        AdmissionQuestionHelper.deleteAdmissionQuestionFromParse,
        admissionQuestion
    );
    if (response === "error") {
      toastr.error("Please try again", { timeOut: 3000 });
      admissionQuestion.set("isDeleted", undefined);
      yield put(AdmissionQuestionActions.updateAdmissionQuestion(admissionQuestion));
    }
    else 
      if (response) {
        toastr.success("Question removed successfully", { timeOut: 3000 });
      //yield put(AdmissionQuestionActions.updateAdmissionQuestion(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}





function* getAdmissionQuestionForTitleIds({ payload: { admissionTitleIds ,schoolId} }) {
  try {
    var key = Constants.ADMISSION_QUESTIONS_UPDATED_TIME + "_school_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call( AdmissionQuestionHelper.getAdmissionQuestionForSchoolId, admissionTitleIds,schoolId,null,true);
    if (localResponse && localResponse.length > 0) {
      yield put(AdmissionQuestionActions.updateAdmissionquestions(localResponse));
    }

    const serverResponse = yield call( AdmissionQuestionHelper.getAdmissionQuestionForSchoolId, admissionTitleIds,schoolId,updatedTime,false);
  
    localStorage.setItem(key,newTime)
    if (serverResponse && serverResponse.length > 0) {
      yield put(AdmissionQuestionActions.updateAdmissionquestions(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* addAdmissionQuestion({ payload: { admissionQuestion } }) {
  try {
  
    yield put(AdmissionQuestionActions.updateAdmissionQuestion(admissionQuestion));
    const response = yield call(
      AdmissionQuestionHelper.editQuestion,admissionQuestion
    );
    if (response === "Error") {

      toastr.error("Please try again", { timeOut: 3000 });
      const local = yield call(
        AdmissionQuestionHelper.getAdmissionQuestionFromLocal,admissionQuestion.id
      );

      if (local) {
        yield put(AdmissionQuestionActions.updateAdmissionQuestion(local));
      }
  
    }else 
    if (response) {
      yield put(AdmissionQuestionActions.updateAdmissionQuestion(response));
      toastr.success("Question successfully added", { timeOut: 3000 });
      response.pin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* editAdmissionQuestion({ payload: { admissionQuestion } }) {
  try {
    const response = yield call(
      AdmissionQuestionHelper.editQuestion,
      admissionQuestion
    );
   
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    }else 
    if (response) {
      yield put(AdmissionQuestionActions.updateAdmissionQuestion(response));
      response.pin();
      toastr.success("Question successfully updated", { timeOut: 3000 });
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* updateAdmissionQuestionsOrder({ payload: { admissionQuestions,titleIds } }) {
  try {
    const response = yield call(AdmissionQuestionHelper.saveAdmissionQuestions,admissionQuestions );
   
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    }else 
    if (response) {
      
      Parse.Object.pinAll(response);


      const localResponse = yield call( AdmissionQuestionHelper.getAdmissionQuestionForAdmissionTitleIds, titleIds,null,true);
      if (localResponse && localResponse.length > 0) {
        yield put(AdmissionQuestionActions.reloadAdmissionQuestions(localResponse));
      }
      toastr.success("Questions successfully updated", { timeOut: 3000 });
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* moveAdmissionQuestion({ payload: { admissionQuestion,titleIds } }) {
  try {
    const response = yield call(AdmissionQuestionHelper.editQuestion,admissionQuestion );
   
    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    }else 
    if (response) {
      
      response.pin();


      const localResponse = yield call( AdmissionQuestionHelper.getAdmissionQuestionForAdmissionTitleIds, titleIds,null,true);
      if (localResponse && localResponse.length > 0) {
        yield put(AdmissionQuestionActions.reloadAdmissionQuestions(localResponse));
      }
      toastr.success("Questions moved successfully ", { timeOut: 3000 });
    } else {
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}


function* getDefaultAdmissionQuestions({ payload: { } }) {
  try {
    var key = Constants.DEFAULT_ADMISSION_QUESTION_UPDATED_TIME;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call( AdmissionQuestionHelper.getDefaultAdmissionQuestions,null,true);
    if (localResponse && localResponse.length > 0) {
      yield put(AdmissionQuestionActions.updateDefaultAdmissionQuestions(localResponse));
    }

    const serverResponse = yield call( AdmissionQuestionHelper.getDefaultAdmissionQuestions,updatedTime,false);
  
    localStorage.setItem(key,newTime)
    if (serverResponse && serverResponse.length > 0) {
      
      Parse.Object.pinAll(serverResponse);

      const localResponse = yield call( AdmissionQuestionHelper.getDefaultAdmissionQuestions,null,true);
      if (localResponse && localResponse.length > 0) {
        yield put(AdmissionQuestionActions.updateDefaultAdmissionQuestions(localResponse));
      }
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetAdmissionQuestion() {
  yield takeEvery(actionTypes.GET_ADMISSION_QUESTION, getAAdmissionQuestion);
}

export function* watchDeleteAdmissionQuestion() {
  yield takeEvery(actionTypes.DELETE_ADMISSION_QUESTION, deleteAdmissionQuestion);
}

export function* watchGetAdmissionQuestionForAdmissionTitleIds() {
  yield takeEvery(actionTypes.GET_ADMISSION_QUESTIONS, getAdmissionQuestionForTitleIds);
}

export function* watchEditAdmissionQuestion() {
  yield takeEvery(actionTypes.EDIT_ADMISSION_QUESTION, editAdmissionQuestion);
}

export function* watchAddAdmissionQuestion() {
  yield takeLeading(actionTypes.ADD_ADMISSION_QUESTION, addAdmissionQuestion);
}

export function* watchUpdateAdmissionSortOrder() {
  yield takeLeading(actionTypes.UPDATE_ADMISSION_QUESTION_SORT_ORDER,updateAdmissionQuestionsOrder );
}

export function* watchMoveAdmissionQuestion() {
  yield takeLeading(actionTypes.MOVE_ADMISSION_QUESTION,moveAdmissionQuestion );
}

export function* watchGetDefaultAdmissionQuestions() {
  yield takeLeading(actionTypes.GET_DEFAULT_ADMISSION_QUESTIONS,getDefaultAdmissionQuestions );
}

function* AdmissionQuestionSaga() {
  yield all([
    fork(watchGetAdmissionQuestion),
    fork(watchDeleteAdmissionQuestion),
    fork(watchGetAdmissionQuestionForAdmissionTitleIds),
    fork(watchEditAdmissionQuestion),
    fork(watchAddAdmissionQuestion),
    fork(watchUpdateAdmissionSortOrder),
    fork(watchMoveAdmissionQuestion),
    fork(watchGetDefaultAdmissionQuestions),
  ]);
}
export default AdmissionQuestionSaga;
