export const UPDATE_LEARNING_SUBCATEGORY = "UPDATE_LEARNING_SUBCATEGORY";
export const DELETE_LEARNING_SUBCATEGORY = "DELETE_LEARNING_SUBCATEGORY";

export const UPDATE_LEARNING_SUBCATEGORYS = "UPDATE_LEARNING_SUBCATEGORYS";   
export const GET_LEARNING_SUBCATEGORYS = "GET_LEARNING_SUBCATEGORYS";
export const GET_LEARNING_SUBCATEGORY = "GET_LEARNING_SUBCATEGORY";
export const GET_LEARNING_SUBCATEGORY_FOR_CATEGORY_ID = "GET_LEARNING_SUBCATEGORY_FOR_CATEGORY_ID"

export const ADD_LEARNING_SUBCATEGOERY = "ADD_LEARNING_SUBCATEGOERY";
export const CLEAR_LEARNING_SUBCATEGOERY = "CLEAR_LEARNING_SUBCATEGOERY";

export const SET_LEARNING_SUBCATEGOERY_STATE = "SET_LEARNING_SUBCATEGOERY_STATE";
export const LEARNING_SUBCATEGORY_REORDER = "LEARNING_SUBCATEGORY_REORDER";