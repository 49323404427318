import React, { useState, useEffect } from "react";
import {
  Row,
  Col,
  Card,
  CardBody,
  FormGroup,
  Label,
  CardHeader,
  Button,
  Collapse,
  Container,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  UncontrolledTooltip,
  Alert
} from "reactstrap";
import { setSelecetdSchool, getClassroomsForSchoolId, getSchool, getDeletedTeachersForMonth, getTeacherForIds, getUserTeacher, updateDeletedTeachersForMonth } from "../../store/actions";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import toastr from "toastr";
import { AvForm, AvField } from "availity-reactstrap-validation";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { Link } from "react-router-dom";
import { Parse, Session } from "parse";
import * as Constants from "../../Constents";
import { v4 as uuidv4 } from "uuid";
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import moment from "moment";
import ReactDatePicker from "react-datepicker";
import ImageUploadComponent from "./ImageUploadComponent";
import ImageViewer from "./ImageViewer";

const MonthlyReport = (props) => {
  const next = "NEXT";
  const previous = "PREVIOUS";

  const match = matchPath(props.history.location.pathname, {
    path: "/monthly-reports/:schoolId/:reportId",
    exact: true,
    strict: false,
  });
  const [showImageViewer, setShowImageViewer] = useState(false)
  const matchEditing = matchPath(props.history.location.pathname, {
    path: "/monthly-reports/:schoolId/:reportId/:sessionId",
    exact: true,
    strict: false,
  });
  const UploadfilesTypes = [
    { id: "input-image", name: "Image/Photo", icon: "bx bxs-file-image ", size: "jpg,png..", link: "#" },

  ];
  const matchFranchise = matchPath(props.history.location.pathname, {
    path: "/franchise-monthly-reports/:reportId/:sessionId",
    exact: true,
    strict: false,
  });
  const matchFranchiseCreateReport = matchPath(props.history.location.pathname, {
    path: "/franchise-monthly-reports/:reportId",
    exact: true,
    strict: false,
  });

  var schoolId = match && match.params.schoolId;
  var sessionId = match && match.params.sessionId;
  var reportId = match && match.params.reportId;

  if (match) {
    schoolId = match && match.params.schoolId;
    sessionId = match && match.params.sessionId;
    reportId = match && match.params.reportId;

  } else if (matchEditing) {
    schoolId = matchEditing && matchEditing.params.schoolId;
    sessionId = matchEditing && matchEditing.params.sessionId;
    reportId = matchEditing && matchEditing.params.reportId;

  } else if (matchFranchise) {
    sessionId = matchFranchise && matchFranchise.params.sessionId;
    reportId = matchFranchise && matchFranchise.params.reportId;


  } else if (matchFranchiseCreateReport) {
    reportId = matchFranchiseCreateReport && matchFranchiseCreateReport.params.reportId;
  }


  const [remarksText, setRemarksText] = useState("")
  const [isSuperAdmin, setIsSuperAdmin] = useState(undefined)
  const [selectedQuestionForViewImage, setSelectedQuestionForViewImage] = useState(undefined)
  const [formName, setFormName] = useState("")
  const [stateSchoolObject, setStateSchoolObject] = useState(undefined);
  const [reportSectionList, setReportSectionList] = useState([]);
  const [sectionCurrentlySelected, setSectionCurrentlySelected] = useState(undefined)
  const [editSectionObject, setEditSectionObject] = useState(undefined);
  const [newSectionName, setNewSectionName] = useState(undefined);
  const [deletingQuestion, setDeletingQuestion] = useState(false)
  const [dataIsReadyToDisplay, setDataIsReadyToDisplay] = useState(false)
  const [sessionObjectReady, setSessionObjectReady] = useState(false)
  const [isReportSubmitted, setIsReportSubmitted] = useState(false);
  const [disableApproveButton, setDisableApproveButton] = useState(false)
  const [isQuestionsAvailable, setIsQuestionsAvailable] = useState(true);
  const [cardMessage, setCardMessage] = useState("");
  const [cardIcon, setCardIcon] = useState("fa fa-check text-success mr-2");
  const [isLoading, setIsLoading] = useState(true);
  const [reportIsSubmitted, setReportIsSubmitted] = useState(false);
  const [templateForQuestions, setTemplateForQuestions] = useState([])
  const [sessionObject, setSessionObject] = useState()
  const [classChoices, setClassChoices] = useState(["Select"])
  const [selectedMonthString, setSelectedMonthString] = useState(undefined)
  const [selectedMonthDate, setSelectedMonthDate] = useState(undefined)
  const [selectedMonthDateError, setSelectedMonthDateError] = useState(false)
  const [reportTemplateName, setReportTemplateName] = useState("Monthly Report")
  const [reportType, setReportType] = useState(undefined)
  const [showModal, setShowModal] = useState(0)
  const [selectedDateForSupervisionReport, setSelectedDateForSupervisionReport] = useState(new Date())
  const [centerIdForSupervisionReport, setCenterIdForSupervisionReport] = useState("Select")
  const staffQuestionChoices = ["Select", "Personal Reason", "Health Problem", "Terminated", "Others"]
  const [gradeSelectionChoices, setGradeSelectionChoices] = useState([])
  const [academicGrade, setAcademicGrade] = useState("")
  const [fileList, setFileList] = useState([]);
  const [selectedQuestion, setSelectedQuestion] = useState(undefined)
  const [selectedSection, setSelectedSection] = useState(undefined)

  var abortSubmitForm = false;

  useEffect(() => {

    if (props.deletedTeachersForMonth) {

      if (!matchFranchise) {
        let tempReportSectionArray = [...reportSectionList]
        for (var i = 0; i < tempReportSectionArray.length; i++) {
          if (tempReportSectionArray[i].type === Constants.MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH) {


            let qList = []
            for (var k = 0; k < props.deletedTeachersForMonth.length; k++) {
              let teacherObj = props.deletedTeachersForMonth[k]
              let qObject = new CustomReportObject()
              qObject.question = teacherObj.attributes.Name
              qObject.choices = staffQuestionChoices
              qObject.answerChoices = [];
              qObject.isMandatory = true
              qObject.questionType = Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
              qList.push(qObject)
            }
            let tempTitleObject = { ...tempReportSectionArray[i] }
            if (tempTitleObject.reportQuestionGroupsList[0]) {
              tempTitleObject.reportQuestionGroupsList[0].questionList = [...qList];
            } else {
              tempTitleObject.reportQuestionGroupsList.push(createNewGroupObject(qList))
            }
            tempReportSectionArray[i] = tempTitleObject
          }
        }



        setReportSectionList(tempReportSectionArray)
        props.updateDeletedTeachersForMonth(undefined)
      }




    }

  }, [props.deletedTeachersForMonth])


  useEffect(() => {
    if (isSuperAdmin != undefined && dataIsReadyToDisplay && sessionObjectReady) {
      if (isLoading) {
        setIsLoading(false)
      }
    }
  }, [isSuperAdmin, dataIsReadyToDisplay, sessionObjectReady]);


  useEffect(() => {
    fetchReportTemplate(sessionId);
  }, [sessionId]);

  const fetchReportSession = (sessionId) => {
    var query = new Parse.Query("CustomReportSession");
    query.equalTo("objectId", sessionId);
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      if (results != null) {
        if (results.length > 0) {

          setSessionObject(results[0])
          if (results[0].attributes.reportMonth) {
            setSelectedMonthDate(results[0].attributes.reportMonth)
            //props.getDeletedTeachersForMonth(schoolId, results[0].attributes.reportMonth)
          }

          if (results[0].attributes.schoolId) {
            setCenterIdForSupervisionReport(results[0].attributes.schoolId)
          }
          if (results[0].attributes.reportMonth) {
            setSelectedDateForSupervisionReport(results[0].attributes.reportMonth)
          }
          if (results[0].attributes.createdBy) {
            props.getTeacherForIds([results[0].attributes.createdBy])
          }
        }

      }
      setSessionObjectReady(true)
    });
  };
  const fetchReportTemplate = (sessionId) => {
    var query = new Parse.Query("CustomReportTemplate");
    query.equalTo("objectId", reportId);
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {
      if (results != null) {
        if (results.length > 0) {
          setReportTemplateName(results[0].attributes.reportName)
          let list = []
          if (results[0].attributes.gradeSelectionChoices && results[0].attributes.gradeSelectionChoices.length > 0) {
            list = results[0].attributes.gradeSelectionChoices;
            list.unshift('Select')
            setGradeSelectionChoices(list)
          } else {
            setGradeSelectionChoices(["Select", "A", "B", "C", "D"])
          }
          if (results[0].attributes.reportType) {
            setReportType(results[0].attributes.reportType)
          }
          fetchReportSections(sessionId, list);
          fetchReportSession(sessionId);
        }
      }
    });
  };

  useEffect(() => {
    if (selectedMonthDate) {
      setSelectedMonthDateError(false)

      let monthString = moment(selectedMonthDate).format("MMM YYYY")

      if (monthString === selectedMonthString) {

      } else {
        setSelectedMonthString(monthString)
        props.getDeletedTeachersForMonth(schoolId, selectedMonthDate)
      }
    }
  }, [selectedMonthDate])

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
    props.getClassroomsForSchoolId(schoolId);

  }, [props.selectedSchool, schoolId]);

  useEffect(() => {

    if (props.classrooms && props.classrooms.length > 0) {
      let tempClassList = [...classChoices]
      for (var i = 0; i < props.classrooms.length; i++) {
        let className = ""
        className = props.classrooms[i].attributes.Name
        tempClassList.push(className)
      }
      setClassChoices(tempClassList)
    }

  }, [props.classrooms])

  useEffect(() => {
    if (props.userTeacher) {
      if (props.userTeacher.attributes.Role === 11) {
        setIsSuperAdmin(true)
      } else {
        setIsSuperAdmin(false)
      }
    }
  }, [props.userTeacher]);

  useEffect(() => {
    if (Parse.User.current()) {

      if (Parse.User.current().attributes.teacherId) {
        props.getUserTeacher(Parse.User.current().attributes.teacherId);
      }
    }
  }, []);



  const fetchReportSections = (sessionId, gradeSelectionChoices) => {
    var query = new Parse.Query("ReportSection");
    query.equalTo("reportId", reportId);

    query.ascending("order");
    query.notEqualTo("isDeleted", true);

    query.limit(1000);
    query.find().then((results) => {
      if (results != null) {
        if (results.length > 0) {
          var reportSectionParseList = results;
          var sectionIds = [];
          var tempSectionList = [];
          for (var i = 0; i < reportSectionParseList.length; i++) {
            sectionIds.push(reportSectionParseList[i].id);

            var tempQuestionList = [];
            var customReportSectionObject = new CustomReportSectionObject();
            customReportSectionObject.title =
              reportSectionParseList[i].attributes.name ?? "";
            customReportSectionObject.reportSectionParseObject =
              reportSectionParseList[i];
            customReportSectionObject.id = reportSectionParseList[i].id
            customReportSectionObject.type = reportSectionParseList[i].attributes.type
            customReportSectionObject.dimensions = reportSectionParseList[i].attributes.dimension && reportSectionParseList[i].attributes.dimension.dimensions
            let tempReportGroupList = []

            customReportSectionObject.reportQuestionGroupsList = tempReportGroupList;

            tempSectionList.push(customReportSectionObject);


          }
          setSectionCurrentlySelected(tempSectionList[0].reportSectionParseObject.id)
          setReportSectionList(tempSectionList);
          fetchReportDataForSessionId(tempSectionList, sectionIds, gradeSelectionChoices);
        } else {
          setIsQuestionsAvailable(false);
        }
      }
    });
  };

  const fetchReportDataForSessionId = (reportSectionList, sectionIds, gradeSelectionChoices) => {

    var query = new Parse.Query("ReportData");
    query.containedIn("sectionId", sectionIds);
    query.equalTo("reportId", reportId);

    query.equalTo("sessionId", sessionId)
    query.notEqualTo("isDeleted", true);
    query.limit(1000);

    query.ascending("createdAt")


    query.find().then((reportDataResults) => {
      if (reportDataResults) {
        let groupedList = []
        let listOfUniqueGroupIds = []
        let albumIds = []

        for (var i = 0; i < reportDataResults.length; i++) {
          if (!listOfUniqueGroupIds.includes(reportDataResults[i].attributes.groupId)) {
            listOfUniqueGroupIds.push(reportDataResults[i].attributes.groupId)
          }
          if (reportDataResults[i].attributes.albumId && reportDataResults[i].attributes.albumId.length > 0) {
            if (!albumIds.includes(reportDataResults[i].attributes.albumId)) {
              albumIds.push(reportDataResults[i].attributes.albumId)
            }
          }

        }

        let currentGroupId = ""
        for (var j = 0; j < listOfUniqueGroupIds.length; j++) {
          currentGroupId = listOfUniqueGroupIds[j]
          for (var i = 0; i < reportDataResults.length; i++) {
            if (currentGroupId === reportDataResults[i].attributes.groupId) {
              groupedList.push(reportDataResults[i])
            }
          }
        }

        fetchAlbumsForAlbumIds(sectionIds,
          reportSectionList,
          groupedList, albumIds, gradeSelectionChoices)

      }
    });
  };

  const includesInList = (list, name) => {
    let found = false
    let x = 0
    while (x <= list.length) {
      if (name === list[x].attributes.groupId) {
        return true
      }
      x++;
    }

    return found
  }

  const fetchAlbumsForAlbumIds = (sectionIds,
    reportSectionList,
    groupedList, albumIds, gradeSelectionChoices) => {

    if (albumIds.length > 0) {
      var query = new Parse.Query("Album");
      query.containedIn("albumId", albumIds);
      query.notEqualTo("isDeleted", true);
      query.limit(1000);
      query.find().then((results) => {
        fetchReportQuestionsForFormIds(
          sectionIds,
          reportSectionList,
          groupedList,
          results,
          gradeSelectionChoices
        );
      })
    } else {
      fetchReportQuestionsForFormIds(
        sectionIds,
        reportSectionList,
        groupedList,
        undefined,
        gradeSelectionChoices
      );
    }

  }

  const fetchReportQuestionsForFormIds = (
    sectionIds,
    reportSectionList,
    reportDataResults,
    albums,
    gradeSelectionChoices

  ) => {
    var query = new Parse.Query("ReportQuestions");
    query.containedIn("sectionId", sectionIds);
    query.ascending("order");
    query.notEqualTo("isDeleted", true);
    query.limit(1000);
    query.find().then((results) => {

      if (results != null) {
        if (reportSectionList.length > 0) {
          let tempSectionTemplateList = [...reportSectionList]
          let tempSectionList = [...reportSectionList]

          for (var i = 0; i < tempSectionList.length; i++) {

            if (tempSectionList[i].reportSectionParseObject.attributes.type === Constants.MR_SECTION_TYPE_REPEATER_BY_4_COL) {
              let groupList = []
              let answerDataList = []
              let currentGroupId = ""

              if (reportDataResults.length > 0) {

                for (var j = 0; j < reportDataResults.length; j++) {
                  if (reportDataResults[j].attributes.sectionId === tempSectionList[i].id) {

                    if (currentGroupId !== reportDataResults[j].attributes.groupId) {
                      currentGroupId = reportDataResults[j].attributes.groupId
                      answerDataList = [reportDataResults[j]]

                    } else {
                      answerDataList.push(reportDataResults[j])

                    }
                    if ((reportDataResults.length - 1) !== j) {
                      if (currentGroupId !== reportDataResults[j + 1].attributes.groupId) {
                        groupList.push({ groupId: currentGroupId, answerData: answerDataList })
                      }
                    }
                    if ((reportDataResults.length - 1) === j) {
                      groupList.push({ groupId: currentGroupId, answerData: answerDataList })
                    }

                  }
                }
              } else {
                let questionResults = getQuestionListForSection(results, tempSectionList[i], reportDataResults, undefined, undefined, gradeSelectionChoices)
                let questionList = questionResults.questionWithAnswer
                let questionTemplateList = questionResults.templateQuestions
                let tempGroupList = []
                tempGroupList.push(createNewGroupObject(questionList))
                tempSectionList[i].reportQuestionGroupsList = tempGroupList
                tempSectionTemplateList[i].reportQuestionGroupsList = [createNewGroupObject(questionTemplateList)]
              }
              if (groupList.length > 0) {
                let tempGroupList = []
                let tempGroupTemplateList = []
                for (var k = 0; k < groupList.length; k++) {
                  let questionResults = getQuestionListForSection(results, tempSectionList[i], groupList[k].answerData, groupList[k].groupId, undefined, gradeSelectionChoices)
                  let questionList = questionResults.questionWithAnswer
                  let questionTemplateList = [...questionResults.templateQuestions]

                  tempGroupTemplateList.push(createNewGroupObject(questionTemplateList, groupList[k].groupId))
                  tempGroupList.push(createNewGroupObject(questionList, groupList[k].groupId))
                }
                tempSectionTemplateList[i].reportQuestionGroupsList = [...tempGroupTemplateList]
                tempSectionList[i].reportQuestionGroupsList = tempGroupList


              } else {
                let questionResults = getQuestionListForSection(results, tempSectionList[i], reportDataResults, undefined, undefined, gradeSelectionChoices)
                let questionList = questionResults.questionWithAnswer
                let questionTemplateList = questionResults.templateQuestions
                let tempGroupList = []
                tempGroupList.push(createNewGroupObject(questionList))
                tempSectionList[i].reportQuestionGroupsList = tempGroupList
                tempSectionTemplateList[i].reportQuestionGroupsList = [createNewGroupObject(questionTemplateList)]
              }

            } else if (tempSectionList[i].reportSectionParseObject.attributes.type === Constants.MR_SECTION_TYPE_DIMENSIONS) {


              let tempGroupList = []
              for (var k = 0; k < tempSectionList[i].dimensions.length; k++) {
                let questionResults = getQuestionListForSection(results, tempSectionList[i], reportDataResults, tempSectionList[i].dimensions[k], undefined, gradeSelectionChoices)
                let questionList = questionResults.questionWithAnswer
                let questionTemplateList = [...questionResults.templateQuestions]
                tempGroupList.push(createNewGroupObjectForType3(questionList, tempSectionList[i].dimensions[k]))
              }
              tempSectionList[i].reportQuestionGroupsList = tempGroupList


            }
            else if (tempSectionList[i].reportSectionParseObject.attributes.type === Constants.MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH) {

              let qList = []
              if (tempSectionList[i].reportQuestionGroupsList[0]) {
                qList = [...tempSectionList[i].reportQuestionGroupsList[0].questionList]
              }

              let tempReportDataResults = reportDataResults.filter(
                (rd) =>
                  rd.attributes.sectionId === tempSectionList[i].id

              );

              for (var k = 0; k < tempReportDataResults.length; k++) {




                let answerList = tempReportDataResults[k].attributes.answer
                let teacherName = answerList[0]
                let answer = answerList[1]



                let tempQuestionObjects = qList.filter(
                  (questionObj) =>
                    questionObj.question === teacherName
                );
                let qObject
                if (tempQuestionObjects.length > 0) {
                  qObject = tempQuestionObjects[0]
                } else {
                  qObject = new CustomReportObject()
                }

                let qObjectIndex = qList.indexOf(qObject)



                qObject.question = teacherName
                qObject.choices = staffQuestionChoices
                qObject.answerChoices = [answer];
                qObject.isMandatory = true
                qObject.resultParseObject = tempReportDataResults[k]
                qObject.questionType = Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST
                if (tempSectionList[i].reportQuestionGroupsList[0]) {
                  qList[qObjectIndex] = qObject

                } else {
                  qList.push(qObject)

                }
              }

              if (tempSectionList[i].reportQuestionGroupsList[0]) {
                tempSectionList[i].reportQuestionGroupsList[0].questionList = [...qList]

              } else {
                tempSectionList[i].reportQuestionGroupsList = [createNewGroupObject(qList)]

              }

            } else {
              let questionResults = getQuestionListForSection(results, tempSectionList[i], reportDataResults, undefined, albums, gradeSelectionChoices)
              let questionList = questionResults.questionWithAnswer
              let questionTemplateList = questionResults.templateQuestions

              let tempGroupList = []
              tempGroupList.push(createNewGroupObject(questionList))
              tempSectionList[i].reportQuestionGroupsList = tempGroupList
              tempSectionTemplateList[i] = [createNewGroupObject(questionTemplateList)]
            }
          }

          setDataIsReadyToDisplay(true)
          setReportSectionList(tempSectionList)
          setTemplateForQuestions(tempSectionTemplateList)

        }
      }
    });
  };

  const getQuestionListForSection = (reportQuestions, section, reportDataResults, groupId, albums, gradeSelectionChoices) => {
    let tempQuestionList = []
    let tempTemplateQuestionsList = []
    for (var j = 0; j < reportQuestions.length; j++) {
      if (
        section.id === reportQuestions[j].attributes.sectionId
      ) {
        var repObject = reportQuestions[j];

        var newReportObject = new CustomReportObject();
        newReportObject.question = repObject.attributes.question ?? "";

        newReportObject.questionType =
          repObject.attributes.type ?? "";
        newReportObject.questionNumber = j + 1;
        if (repObject.attributes.dataType) {
          newReportObject.dataType = repObject.attributes.dataType;
        }
        if (repObject.attributes.choices) {
          newReportObject.choices = [...repObject.attributes.choices];
          newReportObject.choices.unshift("Select")
        }
        if (
          repObject.attributes.isMandatory &&
          repObject.attributes.isMandatory === true
        ) {
          newReportObject.isMandatory = true;
        } else {
          newReportObject.isMandatory = false;
        }
        newReportObject.dataType = repObject.attributes.dataType;
        if (
          repObject.attributes.questionHint != null &&
          repObject.attributes.questionHint !== ""
        ) {
          newReportObject.questionHint =
            repObject.attributes.questionHint;
        } else {
          newReportObject.questionHint = "Type here";
        }
        newReportObject.answerChoices = [];
        newReportObject.dimensions = repObject.attributes.dimensions

        if (
          repObject.attributes.questionType &&
          (repObject.attributes.questionType ===
            Constants.QESTION_YES_NO_COMMENT_ON_NO ||
            repObject.attributes.questionType ===
            Constants.QESTION_YES_NO_COMMENT_ON_YES ||
            repObject.attributes.questionType ===
            Constants.QESTION_YES_NO_NO_COMMENTS ||
            repObject.attributes.questionType ===
            Constants.QESTION_YES_NO_WITH_COMMENT)
        ) {
          if (
            repObject.attributes.choice == null ||
            !repObject.attributes.choice.length > 0
          ) {
            newReportObject.choices = ["Yes", "No"];
          }
        }
        if (section.type === Constants.MR_SECTION_TYPE_SUPERVISION) {
          newReportObject.choices = gradeSelectionChoices
        }
        newReportObject.answerError = false;

        newReportObject.reportQuestionParseObject = repObject;
        let templateQuestion = Object.assign({}, newReportObject)
        if (section.type === Constants.MR_SECTION_TYPE_DIMENSIONS) {
          tempQuestionList.push(
            fillInitialValuesForTypeDimension(newReportObject, reportDataResults, groupId)
          );
        } else {
          tempQuestionList.push(
            fillInitialValues(newReportObject, reportDataResults, groupId, albums)
          );
        }

        tempTemplateQuestionsList.push(fillInitialValues(templateQuestion, [], groupId))
      }
    }

    return { questionWithAnswer: tempQuestionList, templateQuestions: tempTemplateQuestionsList }
  }

  const createNewGroupObject = (questionList, uuid) => {
    let tempObject = {}
    tempObject.questionList = questionList
    if (uuid) {
      tempObject.groupId = uuid
    } else {
      tempObject.groupId = uuidv4()
    }

    return tempObject
  }

  const createNewGroupObjectForType3 = (questionList, dimension) => {
    let tempObject = {}
    tempObject.questionList = questionList
    if (dimension) {
      tempObject.dimension = dimension
    }

    return tempObject
  }

  const fillInitialValuesForTypeDimension = (reportQuestionObject, reportDataResults, groupDimension) => {

    var objectToReturn = reportQuestionObject;
    let reportDatas = reportDataResults.filter((data) =>
      data.attributes.questionId === reportQuestionObject.reportQuestionParseObject.id)
    let reportData
    if (reportDatas.length > 0) {
      reportData = reportDatas[0]
    }

    if (reportData) {

      let dimensionResult = reportData.attributes.dimensionResults
      if (dimensionResult[groupDimension]) {
        let answer = dimensionResult[groupDimension]
        objectToReturn.answerText = answer
      }
      objectToReturn.resultParseObject = reportData
    }
    return objectToReturn
  }

  const fillInitialValues = (reportQuestionObject, reportDataResults, groupId, albums) => {
    var objectToReturn = reportQuestionObject;
    if (reportDataResults && reportDataResults.length > 0) {
      let tempAnswer;
      let tempReportDataAnswer = []
      if (groupId) {
        tempReportDataAnswer = reportDataResults.filter(
          (fd) =>
            (fd.attributes.questionId === objectToReturn.reportQuestionParseObject.id) && (fd.attributes.groupId === groupId)
        );
      } else {
        tempReportDataAnswer = reportDataResults.filter(
          (fd) =>
            fd.attributes.questionId === objectToReturn.reportQuestionParseObject.id
        );
      }

      if (tempReportDataAnswer && tempReportDataAnswer.length > 0) {

        objectToReturn.resultParseObject = tempReportDataAnswer[0];

        if (albums && albums.length > 0) {
          objectToReturn.uploadedImages = albums.filter((album) => album.attributes.albumId === tempReportDataAnswer[0].attributes.albumId)
          objectToReturn.albumId = tempReportDataAnswer[0].attributes.albumId
        }
        tempAnswer = tempReportDataAnswer[0].attributes.answer;
      }
      if (
        objectToReturn.questionType === Constants.QESTION_TEXT_SINGLE_LINE ||
        objectToReturn.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
        objectToReturn.questionType === Constants.QUESTION_EMAIL ||
        objectToReturn.questionType === Constants.QUESTION_PHONE_NUMBER
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }

        if (result) {
          objectToReturn.answerText = result;
        }
      } else if (objectToReturn.questionType === Constants.QESTION_DATE || objectToReturn.questionType === Constants.QESTION_MONTH_SELECTION) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerDate = result;
        }
      } else if (objectToReturn.questionType === Constants.QESTION_NUMBER) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer[0];
        }
        if (result) {
          objectToReturn.answerText = result;
        }
      } else if (
        objectToReturn.questionType ===
        Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST || objectToReturn.questionType ===
        Constants.QESTION_CLASS_SELECTION
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 0) {
          objectToReturn.answerChoices = [...result];
        }
      }
      else if (
        objectToReturn.questionType ===
        Constants.QESTION_SUPERVISION_REPORT
      ) {
        var result = false;
        if (tempAnswer) {
          result = tempAnswer;
        }
        if (result && result.length > 1) {
          objectToReturn.answerChoices = [result[1]];
        }
        if (result && result.length > 0) {
          objectToReturn.answerText = result[0] ?? ""
        }
        if (result && result.length > 2) {
          objectToReturn.adminComments = result[2] ?? ""
        }
      }

    }

    return objectToReturn;
  };

  const shouldDisableEditing = (sessionObject) => {

    //!(matchFranchise || (sessionObject && shouldDisableEditing(sessionObject.attributes.status))

    let shouldDisable = true

    if (matchFranchiseCreateReport) {
      return false
    } else if (matchFranchise) {
      if (reportType === Constants.CR_SUPERVISION) {
        shouldDisable = false
        if (sessionObject) {
          let status = sessionObject.attributes.status
          if (status === Constants.MR_SESSION_STATUS_RESUBMITTED || status === Constants.MR_SESSION_STATUS_SUBMITTED || status === Constants.MR_SESSION_STATUS_APPROVED) {
            shouldDisable = true
          } else {
            shouldDisable = false
          }
        }
      } else {
        shouldDisable = true
      }
    } else {
      if (reportType === Constants.CR_SUPERVISION) {
        shouldDisable = true
      } else {

        if (sessionObject && sessionObject.attributes) {
          let status = sessionObject.attributes.status ?? ""
          if (status === Constants.MR_SESSION_STATUS_RESUBMITTED || status === Constants.MR_SESSION_STATUS_SUBMITTED || status === Constants.MR_SESSION_STATUS_APPROVED) {
            shouldDisable = true
          } else {
            shouldDisable = false
          }
        } else {
          shouldDisable = false
        }

      }
    }

    if (reportType === Constants.CR_SUPERVISION) {
      shouldDisable = true
    }



    return shouldDisable
  }






  const componentForQuestionType = (
    reportQuestionObject,
    reportQuestionGroup,
    reportSectionObject,
    key
  ) => {
    var questionType = reportQuestionObject.questionType;
    let colSize = 3
    if (reportSectionObject.type === Constants.MR_SECTION_TYPE_FULL_ROW) {
      colSize = 12
    }
    let disableEditing = false
    if (sessionObject) {
      if (shouldDisableEditing(sessionObject.attributes.status)) {
        disableEditing = true
      }
    }
    switch (questionType) {


      case Constants.QESTION_TEXT_SINGLE_LINE:
        return (
          <Col lg={colSize} md={colSize} sm={colSize} className="mt-3 mb-2" key={key}>
            <FormGroup key={key}>
              <Label htmlFor="formSingleLine">
                {reportQuestionObject.question}{" "}
              </Label>
              {reportQuestionObject.isMandatory && (
                <Label htmlFor="formSingleLine" className="text-danger">
                  *
                </Label>
              )}
              {reportQuestionObject.answerError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}
              <AvField
                type="text"
                name={"singleLine" + key}
                placeholder={reportQuestionObject.questionHint}
                className="form-control"
                disabled={matchFranchise || disableEditing}
                value={reportQuestionObject.answerText ?? ""}
                onChange={(e) => {
                  handleInputOnChange(
                    reportQuestionObject,
                    reportQuestionGroup,
                    reportSectionObject,
                    e.target.value
                  );
                }}
              />
            </FormGroup></Col>
        );

        break;

      case Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST:
        var choices = [...reportQuestionObject.choices];
        return (
          <Col lg={colSize} md={colSize} sm={colSize} className="mt-3 mb-2" key={key}>
            <FormGroup key={key}>
              <Label htmlFor="formsingleselect">
                {reportQuestionObject.question}{" "}
              </Label>
              {reportQuestionObject.isMandatory && (
                <Label htmlFor="formsingleselect" className="text-danger">
                  *
                </Label>
              )}
              {reportQuestionObject.answerError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}
              {<select className="form-control"
                id={"select" + key}
                disabled={matchFranchise || disableEditing}
                value={reportQuestionObject.answerChoices && reportQuestionObject.answerChoices[0]}
                onChange={(e) => {
                  handleChoiceButtonClick(
                    reportQuestionObject,
                    reportQuestionGroup,
                    reportSectionObject,
                    e.target.value
                  );
                }}
              >
                {choices.map((choice, keyInside) => (
                  <option value={choice}>{choice}</option>
                ))}
              </select>}

            </FormGroup></Col>
        );

        break;

      case Constants.QESTION_TEXT_MULTI_LINE:
        return (
          <Col lg={colSize} md={colSize} sm={colSize} className="mt-3 mb-2" key={key}>
            <FormGroup key={key}>
              <Label htmlFor="formMultiLine">{reportQuestionObject.question}</Label>
              {reportQuestionObject.isMandatory && (
                <Label htmlFor="formSingleLine" className="text-danger">
                  *
                </Label>
              )}
              {reportQuestionObject.answerError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}
              <AvField
                type="textarea"
                name={"multiLine" + key}
                className="form-control"
                rows="3"
                disabled={matchFranchise || disableEditing}
                value={reportQuestionObject.answerText ?? ""}
                placeholder={reportQuestionObject.questionHint}
                onChange={(e) => {
                  handleInputOnChange(
                    reportQuestionObject,
                    reportQuestionGroup,
                    reportSectionObject,
                    e.target.value
                  );
                }}
              />
            </FormGroup></Col>
        );
        break;

      case Constants.QESTION_NUMBER:
        return (
          <Col lg={colSize} md={colSize} sm={colSize} className="mt-3 mb-2" key={key}>
            <FormGroup key={key}>
              <Label htmlFor="formNumber">
                {reportQuestionObject.question}{" "}
              </Label>
              {reportQuestionObject.isMandatory && (
                <Label htmlFor="formNumber" className="text-danger">
                  *
                </Label>
              )}
              {reportQuestionObject.answerError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}
              <AvField
                type="number"
                name={"numberInput" + key}
                disabled={matchFranchise || disableEditing}
                className="inner form-control noscroll"
                placeholder={props.t(reportQuestionObject.questionHint)}
                value={reportQuestionObject.answerText ?? ""}
                onChange={(e) => {
                  handleInputOnChange(
                    reportQuestionObject,
                    reportQuestionGroup,
                    reportSectionObject,
                    e.target.value
                  );
                }}
              />
            </FormGroup></Col>
        );

        break;

      case Constants.QESTION_DATE:
        return (
          <Col lg={colSize} md={colSize} sm={colSize} className="mt-3 mb-2" key={key}>
            <FormGroup key={key}>
              <Label htmlFor="formDate">
                {reportQuestionObject.question}{" "}
              </Label>



              <ReactDatePicker

                id={"questionDate" + key}
                disabled={matchFranchise || disableEditing}
                selected={
                  reportQuestionObject.answerDate ? new Date(reportQuestionObject.answerDate) : undefined
                }
                onChange={(date) => {
                  handleDateChange(
                    reportQuestionObject,
                    reportQuestionGroup,
                    reportSectionObject,
                    date
                  );
                }}
                className="form-control"
                placeholderText="dd/mm/yyyy"
                dateFormat="dd/MM/yyyy"
                showDayMonthYearPicker
              />
            </FormGroup></Col>
        );

        break;

      case Constants.QESTION_MONTH_SELECTION:
        return (
          <Col lg={colSize} md={colSize} sm={colSize} className="mt-3 mb-2" key={key}>
            <FormGroup key={key}>
              <Label htmlFor="formDateMonth">
                {reportQuestionObject.question}
              </Label>

              {reportQuestionObject.isMandatory && (
                <Label htmlFor="formDateMonth" className="text-danger">
                  *
                </Label>
              )}
              {reportQuestionObject.answerError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}


              <ReactDatePicker

                id={"questionDate" + key}
                disabled={matchFranchise || disableEditing}
                selected={
                  reportQuestionObject.answerDate ? new Date(reportQuestionObject.answerDate) : undefined
                }
                onChange={(date) => {
                  handleDateChange(
                    reportQuestionObject,
                    reportQuestionGroup,
                    reportSectionObject,
                    date
                  );
                }}
                className="form-control"
                placeholderText="mm/yyyy"
                dateFormat="MM/yyyy"
                showMonthYearPicker
                showFullMonthYearPicker
              />






            </FormGroup></Col>
        );

        break;

      case Constants.QESTION_CLASS_SELECTION:
        var choices = [];
        if (classChoices && classChoices.length > 0) {
          choices = classChoices
        }

        return (
          <Col lg={colSize} md={colSize} sm={colSize} className="mt-3 mb-2" key={key}>
            <FormGroup key={key}>
              <Label htmlFor="formsingleselect">
                {reportQuestionObject.question}{" "}
              </Label>
              {reportQuestionObject.isMandatory && (
                <Label htmlFor="formsingleselect" className="text-danger">
                  *
                </Label>
              )}
              {reportQuestionObject.answerError === true && (
                <Label className="label-xsmall text-danger ml-2">
                  (this field is mandatory)
                </Label>
              )}
              {<select className="form-control"
                id={"classChoice" + key}
                disabled={matchFranchise || disableEditing}
                value={reportQuestionObject.answerChoices && reportQuestionObject.answerChoices[0]}
                onChange={(e) => {
                  handleChoiceButtonClick(
                    reportQuestionObject,
                    reportQuestionGroup,
                    reportSectionObject,
                    e.target.value
                  );
                }}
              >
                {choices.map((choice, keyInside) => (
                  <option value={choice}>{choice}</option>
                ))}
              </select>}

            </FormGroup></Col>
        );

        break;

      case Constants.QESTION_UPLOAD_IMAGE:
        return (
          <Col lg={colSize} md={colSize} sm={colSize} className="mt-3 mb-2" key={key}>
            <FormGroup key={key}>
              <Label htmlFor="formDate">
                {reportQuestionObject.question}{" "}
              </Label>


              <input type="file"
                className="sr-only"
                id="input-image-all"
                name="file"
                accept="image/*"
                onChange={ImageUploadPreviev}
                multiple
              />
              {!shouldDisableEditing(sessionObject) && <div><Label htmlFor={"input-image-all"} onClick={() => {
                setSelectedQuestion(reportQuestionObject)
                setSelectedSection(reportSectionObject)

              }}
                className="text-primary"
                style={{ cursor: "pointer" }}
              >

                Upload Photos
              </Label></div>}

              <Label htmlFor={"view-image"} onClick={() => {
                setSelectedQuestionForViewImage(reportQuestionObject)
              }}
                className="text-primary"
                style={{ cursor: "pointer" }}
              >

                View Photos
              </Label>


            </FormGroup></Col>
        );
        break;
    }
  };

  // //  UI Functions





  const handleDateChange = (
    reportQuestionObject,
    reportQuestionGroup,
    reportSectionObject,
    changedValue
  ) => {

    var groupIndex = reportSectionObject.reportQuestionGroupsList.indexOf(
      reportQuestionGroup
    );
    var questionIndex = reportSectionObject.reportQuestionGroupsList[groupIndex].questionList.indexOf(
      reportQuestionObject
    );
    var sectionIndex = reportSectionList.indexOf(reportSectionObject);



    var tempQuestionObject = { ...reportQuestionObject };
    var tempTitleObject = reportSectionList[sectionIndex];
    tempQuestionObject.answerDate = new Date(changedValue);
    if (tempQuestionObject.isMandatory) {
      if (changedValue) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }
    var tempQuestionList = [...tempTitleObject.reportQuestionGroupsList[groupIndex].questionList];
    tempQuestionList[questionIndex] = tempQuestionObject;
    tempTitleObject.reportQuestionGroupsList[groupIndex].questionList = [...tempQuestionList];
    var tempTitleList = [...reportSectionList];
    tempTitleList[sectionIndex] = tempTitleObject;
    setReportSectionList(tempTitleList);

  };

  const handleChoiceButtonClick = (
    reportQuestionObject,
    reportQuestionGroup,
    reportSectionObject,
    choice
  ) => {

    var groupIndex = reportSectionObject.reportQuestionGroupsList.indexOf(
      reportQuestionGroup
    );
    var questionIndex = reportSectionObject.reportQuestionGroupsList[groupIndex].questionList.indexOf(
      reportQuestionObject
    );
    var sectionIndex = reportSectionList.indexOf(reportSectionObject);



    var tempQuestionObject = { ...reportQuestionObject };
    var tempTitleObject = reportSectionList[sectionIndex];

    var tempArray = [...tempQuestionObject.answerChoices];



    if (tempQuestionObject.answerChoices.includes(choice)) {
      //remove
      removeElement(tempArray, choice);
    } else {
      tempArray = [choice];
    }

    tempQuestionObject.answerChoices = [...tempArray];

    if (tempQuestionObject.isMandatory) {
      if (tempArray == null || tempArray.length === 0 || tempArray[0] === "Select") {
        tempQuestionObject.answerError = true;
      } else {
        tempQuestionObject.answerError = false;
      }
    }


    var tempQuestionList = [...tempTitleObject.reportQuestionGroupsList[groupIndex].questionList];
    tempQuestionList[questionIndex] = tempQuestionObject;
    tempTitleObject.reportQuestionGroupsList[groupIndex].questionList = [...tempQuestionList];
    var tempTitleList = [...reportSectionList];
    tempTitleList[sectionIndex] = tempTitleObject;
    setReportSectionList(tempTitleList);
  };


  const handleInputOnChange = (
    reportQuestionObject,
    reportQuestionGroup,
    reportSectionObject,
    changedValue
  ) => {

    var groupIndex = reportSectionObject.reportQuestionGroupsList.indexOf(
      reportQuestionGroup
    );
    var questionIndex = reportSectionObject.reportQuestionGroupsList[groupIndex].questionList.indexOf(
      reportQuestionObject
    );
    var sectionIndex = reportSectionList.indexOf(reportSectionObject);



    var tempQuestionObject = { ...reportQuestionObject };
    var tempTitleObject = reportSectionList[sectionIndex];
    tempQuestionObject.answerText = changedValue;
    if (tempQuestionObject.isMandatory) {
      if (changedValue.length > 0) {
        tempQuestionObject.answerError = false;
      } else {
        tempQuestionObject.answerError = true;
      }
    }
    var tempQuestionList = [...tempTitleObject.reportQuestionGroupsList[groupIndex].questionList];
    tempQuestionList[questionIndex] = tempQuestionObject;
    tempTitleObject.reportQuestionGroupsList[groupIndex].questionList = [...tempQuestionList];
    var tempTitleList = [...reportSectionList];
    tempTitleList[sectionIndex] = tempTitleObject;
    setReportSectionList(tempTitleList);
  };

  const handleChangeResultObject = (reportQuestionObject,
    reportQuestionGroup,
    reportSectionObject,
    resultObject, albums, albumId) => {

    var groupIndex = reportSectionObject.reportQuestionGroupsList.indexOf(
      reportQuestionGroup
    );
    var questionIndex = reportSectionObject.reportQuestionGroupsList[groupIndex].questionList.indexOf(
      reportQuestionObject
    );
    var sectionIndex = reportSectionList.indexOf(reportSectionObject);

    var tempQuestionObject = { ...reportQuestionObject };
    var tempTitleObject = reportSectionList[sectionIndex];
    if (resultObject) {
      tempQuestionObject.resultParseObject = resultObject;
    }
    tempQuestionObject.uploadedImages = albums
    if (albumId && albumId.length > 0) {
      tempQuestionObject.albumId = albumId
    }

    var tempQuestionList = [...tempTitleObject.reportQuestionGroupsList[groupIndex].questionList];
    tempQuestionList[questionIndex] = tempQuestionObject;
    tempTitleObject.reportQuestionGroupsList[groupIndex].questionList = [...tempQuestionList];
    var tempTitleList = [...reportSectionList];
    tempTitleList[sectionIndex] = tempTitleObject;
    setReportSectionList(tempTitleList);


  }

  const handleOnChangeAdminComments = (
    reportQuestionObject,
    reportQuestionGroup,
    reportSectionObject,
    changedValue
  ) => {

    var groupIndex = reportSectionObject.reportQuestionGroupsList.indexOf(
      reportQuestionGroup
    );
    var questionIndex = reportSectionObject.reportQuestionGroupsList[groupIndex].questionList.indexOf(
      reportQuestionObject
    );
    var sectionIndex = reportSectionList.indexOf(reportSectionObject);

    var tempQuestionObject = { ...reportQuestionObject };
    var tempTitleObject = reportSectionList[sectionIndex];
    tempQuestionObject.adminComments = changedValue;

    var tempQuestionList = [...tempTitleObject.reportQuestionGroupsList[groupIndex].questionList];
    tempQuestionList[questionIndex] = tempQuestionObject;
    tempTitleObject.reportQuestionGroupsList[groupIndex].questionList = [...tempQuestionList];
    var tempTitleList = [...reportSectionList];
    tempTitleList[sectionIndex] = tempTitleObject;
    setReportSectionList(tempTitleList);
  };



  //Save functions

  const isReportValid = (onlySave = false) => {
    var canProceedToSave = true;

    if (sessionObject) {
      if (sessionObject.attributes.status === Constants.MR_SESSION_STATUS_SUBMITTED || sessionObject.attributes.status === Constants.MR_SESSION_STATUS_RESUBMITTED) {
        toastr.warning("Report is already submitted !!")
        return
      }
    }
    if (sessionObject && onlySave) {
      if (sessionObject.attributes.status === Constants.MR_SESSION_STATUS_REJECTED) {
        toastr.warning("Can't save when the report is rejected, please click on submit to re-submit the report!!")
        return
      }
    }

    if (reportType === Constants.CR_SUPERVISION) {

      let dateError = false
      let centerSelectionError = false
      if (!selectedDateForSupervisionReport) {
        dateError = true
        canProceedToSave = false
      }
      if (centerIdForSupervisionReport == undefined || centerIdForSupervisionReport.length === 0 || centerIdForSupervisionReport === "Select") {
        centerSelectionError = true
        canProceedToSave = false
      }

      if (centerSelectionError) {
        toastr.warning("Please select Center !!")
      }

      if (dateError) {
        toastr.warning("Please select Date !!")
      }

      if (canProceedToSave) {
        saveReport(onlySave);
      }



    } else {
      let staffLeftValidationPassed = true

      if (!onlySave) {
        staffLeftValidationPassed = checkForDifferentQuestionsValidations();
      }

      if (selectedMonthDate) {

      } else {
        setSelectedMonthDateError(true)
        canProceedToSave = false
      }

      if (!staffLeftValidationPassed) {
        toastr.warning("Please select reason for staff left this month")
      }
      if (!canProceedToSave) {
        toastr.warning("Please select Month")
      }

      if (canProceedToSave && staffLeftValidationPassed) {
        saveReport(onlySave);
      }
    }

  };

  const checkForDataType = (dataType, answer) => {
    if (dataType === "ARRAY") {
      if (!Array.isArray(answer)) {
        return [answer];
      }
    } else if (dataType === "STRING") {
      if (!(typeof answer === "string" || answer instanceof String)) {
        return answer.toString();
      }
    } else if (dataType === "NUMBER") {
      if (typeof answer === "string" || answer instanceof String) {
        return parseInt(answer, 10);
      }
    }
    return answer;
  };

  const comparingForFillingObjectsWithAnswers = (
    i,
    objectToReturn,
    shouldSaveObject,
    questionList,
    sectionType
  ) => {
    if (
      questionList[i].questionType ===
      Constants.QESTION_TEXT_SINGLE_LINE ||
      questionList[i].questionType === Constants.QESTION_TEXT_MULTI_LINE ||
      questionList[i].questionType === Constants.QUESTION_EMAIL ||
      questionList[i].questionType === Constants.QUESTION_PHONE_NUMBER
    ) {

      if (questionList[i].answerText != null) {
        let tempAnswer = checkForDataType(
          questionList[i].dataType,
          questionList[i].answerText
        );
        if (Array.isArray(tempAnswer)) {
          objectToReturn.set("answer", tempAnswer);
        } else {
          objectToReturn.set("answer", [tempAnswer]);
        }

        shouldSaveObject = true;
      }
    } else if (questionList[i].questionType === Constants.QESTION_DATE || questionList[i].questionType === Constants.QESTION_MONTH_SELECTION) {
      if (questionList[i].answerDate != null) {
        objectToReturn.set("answer", [questionList[i].answerDate]);
        shouldSaveObject = true;
      }
    }

    else if (questionList[i].questionType === Constants.QESTION_NUMBER) {
      if (questionList[i].answerText != null) {
        objectToReturn.set("answer", [questionList[i].answerText]);
        shouldSaveObject = true;
      }
    }
    else if (
      questionList[i].questionType ===
      Constants.QESTION_CHOICE_SINGLE_SELECTION_FROM_LIST || questionList[i].questionType ===
      Constants.QESTION_CLASS_SELECTION
    ) {

      if (
        questionList[i].answerChoices != null &&
        questionList[i].answerChoices.length > 0
      ) {
        if (sectionType === 4) {
          if (questionList[i].answerChoices[0] !== "Select") {

            let tempAnswer = [questionList[i].question]
            let tempAnswerChoice = questionList[i].answerChoices[0]

            if (Array.isArray(tempAnswerChoice)) {
              tempAnswer = tempAnswer.concat(questionList[i].answerChoices[0])
            } else {
              tempAnswer.push(tempAnswerChoice)
            }
            objectToReturn.set("answer", tempAnswer);
            shouldSaveObject = true;
          }
        } else {

          let tempAnswer = questionList[i].answerChoices[0]
          if (Array.isArray(tempAnswer)) {
            objectToReturn.set("answer", tempAnswer);
          } else {
            objectToReturn.set("answer", [tempAnswer]);
          }
          shouldSaveObject = true;
        }
      }

    } else if (
      questionList[i].questionType ===
      Constants.QESTION_SUPERVISION_REPORT
    ) {

      let tempAnswer = [questionList[i].answerText ?? ""]
      let tempAdminComments = questionList[i].adminComments ?? ""

      let tempAnswerChoice = questionList[i].answerChoices && questionList[i].answerChoices.length > 0 && questionList[i].answerChoices[0]

      if (tempAnswerChoice) {
        if (Array.isArray(tempAnswerChoice)) {
          tempAnswer = tempAnswer.concat(tempAnswerChoice)
        } else {
          tempAnswer.push(tempAnswerChoice)
        }
      }
      tempAnswer.push(tempAdminComments)
      objectToReturn.set("answer", tempAnswer);
      shouldSaveObject = true;

    } else if (questionList[i].questionType == Constants.QESTION_UPLOAD_IMAGE) {
      if (objectToReturn.attributes.albumId) {
        shouldSaveObject = true;
      }

    }





    return {
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };



  const collectingObjectsWithAnswers = () => {
    let listOfObjectsToSave = [];

    var j = 0;
    for (j = 0; j < reportSectionList.length; j++) {
      var i = 0;
      var reportQuestionGroupsList = reportSectionList[j].reportQuestionGroupsList;

      if (reportSectionList[j].type === Constants.MR_SECTION_TYPE_DIMENSIONS) {
        let type3List = []
        for (i = 0; i < reportQuestionGroupsList.length; i++) {
          let dimension = reportQuestionGroupsList[i].dimension
          for (var k = 0; k < reportQuestionGroupsList[i].questionList.length; k++) {

            let value = "0"
            let questionObject = reportQuestionGroupsList[i].questionList[k]

            if (i === 0) {


              let ReportData = Parse.Object.extend("ReportData");
              let objectToSave
              if (questionObject.resultParseObject) {
                objectToSave = questionObject.resultParseObject
              } else {
                objectToSave = new ReportData();
                objectToSave.set(
                  "sectionId",
                  reportSectionList[j].reportSectionParseObject.id
                );
                objectToSave.set(
                  "questionId",
                  questionObject.reportQuestionParseObject.id
                );
                objectToSave.set(
                  "reportId",
                  reportId
                );
                if (matchEditing) {
                  objectToSave.set(
                    "sessionId",
                    sessionId
                  );
                }
              }
              if (
                questionObject.questionType ===
                Constants.QESTION_TEXT_SINGLE_LINE ||
                questionObject.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
                questionObject.questionType === Constants.QUESTION_EMAIL ||
                questionObject.questionType === Constants.QUESTION_PHONE_NUMBER || questionObject.questionType === Constants.QESTION_NUMBER
              ) {

                if (questionObject.answerText != null) {
                  value = questionObject.answerText
                }
              }

              let dimensionObject = new Object()
              dimensionObject[dimension] = value

              objectToSave.set("dimensionResults", dimensionObject)

              type3List.push(objectToSave)

            } else {

              let questionParseObjects = type3List.filter((pfObject) =>
                pfObject.attributes.questionId === questionObject.reportQuestionParseObject.id
              )


              let questionParseObject
              if (questionParseObjects.length > 0) {
                questionParseObject = questionParseObjects[0]

              }
              let index = type3List.indexOf(questionParseObject)

              if (
                questionObject.questionType ===
                Constants.QESTION_TEXT_SINGLE_LINE ||
                questionObject.questionType === Constants.QESTION_TEXT_MULTI_LINE ||
                questionObject.questionType === Constants.QUESTION_EMAIL ||
                questionObject.questionType === Constants.QUESTION_PHONE_NUMBER || questionObject.questionType === Constants.QESTION_NUMBER
              ) {

                if (questionObject.answerText != null) {
                  value = questionObject.answerText
                }
              }
              let dimensionObject = questionParseObject.attributes.dimensionResults
              dimensionObject[dimension] = value


              questionParseObject.set("dimensionResults", dimensionObject)


              type3List[index] = questionParseObject

            }
          }
        }

        listOfObjectsToSave = listOfObjectsToSave.concat(type3List);

      } else {
        for (i = 0; i < reportQuestionGroupsList.length; i++) {
          for (var k = 0; k < reportQuestionGroupsList[i].questionList.length; k++) {

            let questionObject = reportQuestionGroupsList[i].questionList[k]

            let ReportData = Parse.Object.extend("ReportData");
            let objectToSave
            if (questionObject.resultParseObject) {
              objectToSave = questionObject.resultParseObject
            } else {
              objectToSave = new ReportData();
              if (questionObject.albumId && questionObject.albumId.length > 0) {
                objectToSave.set("albumId", questionObject.albumId)
              }
              objectToSave.set(
                "sectionId",
                reportSectionList[j].reportSectionParseObject.id
              );
              if (reportSectionList[j].type !== Constants.MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH) {
                objectToSave.set(
                  "questionId",
                  questionObject.reportQuestionParseObject.id
                );
              }

              objectToSave.set(
                "reportId",
                reportId
              );
              if (matchEditing) {
                objectToSave.set(
                  "sessionId",
                  sessionId
                );
              }
              if (reportSectionList[j].type !== Constants.MR_SECTION_TYPE_DIMENSIONS && reportSectionList[j].type !== Constants.MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH) {
                objectToSave.set("groupId", reportQuestionGroupsList[i].groupId)

              }

            }



            var shouldSaveObject = false;
            var result = undefined;


            if (reportQuestionGroupsList[i].questionList) {
              result = comparingForFillingObjectsWithAnswers(
                k,
                objectToSave,
                shouldSaveObject,
                reportQuestionGroupsList[i].questionList,
                reportSectionList[j].type
              );
            }

            if (result) {
              shouldSaveObject = result.shouldSaveObject;
              objectToSave = result.objectToReturn;
            }
            if (shouldSaveObject) {
              listOfObjectsToSave.push(objectToSave);
            }
          }
        }
      }
    }

    return listOfObjectsToSave;
  };

  const collectingObjectsWithAnswersForAdminComments = () => {
    let listOfObjectsToSave = [];

    var j = 0;
    for (j = 0; j < reportSectionList.length; j++) {
      var i = 0;
      var reportQuestionGroupsList = reportSectionList[j].reportQuestionGroupsList;
      for (i = 0; i < reportQuestionGroupsList.length; i++) {
        for (var k = 0; k < reportQuestionGroupsList[i].questionList.length; k++) {

          let questionObject = reportQuestionGroupsList[i].questionList[k]

          let ReportData = Parse.Object.extend("ReportData");
          let objectToSave
          if (questionObject.resultParseObject) {
            objectToSave = questionObject.resultParseObject
          }

          var shouldSaveObject = false;
          var result = undefined;

          if (reportQuestionGroupsList[i].questionList) {
            result = comparingForFillingObjectsWithAnswersForAdminCommentsOnly(
              k,
              objectToSave,
              shouldSaveObject,
              reportQuestionGroupsList[i].questionList,
            );
          }

          if (result) {
            shouldSaveObject = result.shouldSaveObject;
            objectToSave = result.objectToReturn;
          }
          if (shouldSaveObject) {
            listOfObjectsToSave.push(objectToSave);
          }
        }
      }
    }
    return listOfObjectsToSave;
  };


  const comparingForFillingObjectsWithAnswersForAdminCommentsOnly = (
    i,
    objectToReturn,
    shouldSaveObject,
    questionList
  ) => {
    if (
      questionList[i].questionType ===
      Constants.QESTION_SUPERVISION_REPORT
    ) {

      let tempAnswer = [questionList[i].answerText ?? ""]
      let tempAdminComments = questionList[i].adminComments ?? ""

      let tempAnswerChoice = questionList[i].answerChoices && questionList[i].answerChoices.length > 0 && questionList[i].answerChoices[0]

      if (tempAnswerChoice) {
        if (Array.isArray(tempAnswerChoice)) {
          tempAnswer = tempAnswer.concat(tempAnswerChoice)
        } else {
          tempAnswer.push(tempAnswerChoice)
        }
      }
      tempAnswer.push(tempAdminComments)
      objectToReturn.set("answer", tempAnswer);
      shouldSaveObject = true;

    }
    return {
      shouldSaveObject: shouldSaveObject,
      objectToReturn: objectToReturn,
    };
  };


  const saveAdminComments = (toBeApproved) => {

    let objectsToSave = collectingObjectsWithAnswersForAdminComments();

    Parse.Object.saveAll(objectsToSave).then(
      (result) => {
        window.history.back()
        if (toBeApproved) {
          toastr.success("Report Approved");
        } else {
          toastr.success("Report Rejected");
        }
      },
      (error) => {
        setIsReportSubmitted(false);
        toastr.warning("Something went wrong, please try again !!");
      }
    );

  }


  const saveReport = (onlySave) => {
    if (abortSubmitForm) {
      return;
    }

    setReportIsSubmitted(true);

    let objectsToSave = collectingObjectsWithAnswers();
    console.log(objectsToSave)

    if (matchEditing || (matchFranchise && reportType === Constants.CR_SUPERVISION)) {

      if (onlySave) {
        if (sessionObject && reportType === Constants.CR_SUPERVISION) {

          sessionObject.set(
            "grade",
            academicGrade
          );
          sessionObject.save().then((sessionResult) => {

            Parse.Object.saveAll(objectsToSave).then(
              (result) => {
                // setCardMessage("Successfully submitted the Report !!");
                // setIsReportSubmitted(true);
                window.history.back()
                toastr.success("Successfully Saved the Report !!");

              },
              (error) => {
                console.log(error)

                setIsReportSubmitted(false);
                toastr.warning("Something went wrong, please try again !!");
              }
            );
          })


        } else {
          Parse.Object.saveAll(objectsToSave).then(
            (result) => {
              // setCardMessage("Successfully submitted the Report !!");
              // setIsReportSubmitted(true);
              window.history.back()
              toastr.success("Successfully Saved the Report !!");

            },
            (error) => {
              console.log(error)

              setIsReportSubmitted(false);
              toastr.warning("Something went wrong, please try again !!");
            }
          );
        }


      } else {
        if (sessionObject) {
          let reportSessions = sessionObject




          if (reportSessions.attributes.status === Constants.MR_SESSION_STATUS_REJECTED) {
            reportSessions.set(
              "status",
              2
            );
          } else {
            reportSessions.set(
              "status",
              1
            );
          }

          if (reportType === Constants.CR_SUPERVISION) {
            reportSessions.set(
              "grade",
              academicGrade
            );
          }

          if (selectedMonthString) {
            reportSessions.set("createdMonth", selectedMonthString)
          }


          reportSessions.save().then(
            (result) => {
              for (var i = 0; i < objectsToSave.length; i++) {
                objectsToSave[i].set("sessionId", result.id);
              }
              Parse.Object.saveAll(objectsToSave).then(
                (result) => {
                  // setCardMessage("Successfully submitted the Report !!");
                  // setIsReportSubmitted(true);
                  window.history.back()
                  toastr.success("Successfully submitted the Report !!");
                },
                (error) => {
                  console.log(error)

                  setIsReportSubmitted(false);
                  toastr.warning("Something went wrong, please try again !!");
                }
              );
            },
            (error) => {
              console.log(error)

              setIsReportSubmitted(false);
              toastr.warning("Something went wrong, please try again !!");
            }
          );

        } else {
          toastr.warning("please try again later")
        }
      }
    } else {
      let CustomReportSession = Parse.Object.extend("CustomReportSession");
      let reportSessions = new CustomReportSession();

      if (reportType === Constants.CR_SUPERVISION) {
        reportSessions.set(
          "sessionName",
          reportTemplateName + " " + convertDateToString(selectedDateForSupervisionReport)
        );

      } else {
        reportSessions.set(
          "sessionName",
          reportTemplateName + " " + selectedMonthString
        );
      }

      reportSessions.set(
        "reportId",
        reportId
      );
      if (onlySave) {
        reportSessions.set(
          "status",
          0
        );
      } else {
        reportSessions.set(
          "status",
          1
        );
      }

      if (reportType === Constants.CR_SUPERVISION) {

        reportSessions.set("reportMonth", selectedDateForSupervisionReport)


        reportSessions.set(
          "schoolId",
          centerIdForSupervisionReport
        );
        reportSessions.set(
          "grade",
          academicGrade
        );

      } else {
        reportSessions.set(
          "schoolId",
          schoolId
        );

      }


      // reportSessions.set(
      //   "franchiseId",
      //   props.selectedSchool.attributes.franchiseId
      // );

      // reportSessions.set(
      //   "instituteId",
      //   props.selectedSchool.attributes.instituteId
      // );

      if (Parse.User.current().attributes.teacherId) {
        reportSessions.set("createdBy", Parse.User.current().attributes.teacherId)
      } else {
        reportSessions.set("createdBy", Parse.User.current().id)
      }

      if (selectedMonthString) {
        reportSessions.set("createdMonth", selectedMonthString)
      }
      if (selectedMonthDate) {
        reportSessions.set("reportMonth", selectedMonthDate)
      }


      reportSessions.save().then(
        (result) => {
          for (var i = 0; i < objectsToSave.length; i++) {
            objectsToSave[i].set("sessionId", result.id);
          }
          Parse.Object.saveAll(objectsToSave).then(
            (result) => {
              window.history.back()
              if (onlySave) {
                toastr.success("Successfully saved the Report !!");

              } else {
                toastr.success("Successfully submitted the Report !!");

              }
              // setCardMessage("Successfully submitted the Report !!");
              // setIsReportSubmitted(true);
            },
            (error) => {
              console.log(error)
              setIsReportSubmitted(false);
              toastr.warning("Something went wrong, please try again !!");
            }
          );
        },
        (error) => {
          console.log(error)

          setIsReportSubmitted(false);
          toastr.warning("Something went wrong, please try again !!");
        }
      );
    }

  };


  function convertDateToString(date) {
    return moment(date).format('DD MMM YYYY')
  }

  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }


  const handleReject = () => {

    if (sessionObject) {
      let reportSessions = sessionObject

      reportSessions.set(
        "status",
        Constants.MR_SESSION_STATUS_REJECTED
      );
      reportSessions.set(
        "remarks",
        remarksText
      );

      reportSessions.set("rejectedOn", new Date())

      if (Parse.User.current().attributes.teacherId) {
        reportSessions.set("rejectedBy", Parse.User.current().attributes.teacherId)
      } else {
        reportSessions.set("rejectedBy", Parse.User.current().id)
      }



      reportSessions.save().then(
        (result) => {
          if (isSuperAdmin && reportType === Constants.CR_SUPERVISION) {
            saveAdminComments(false)
          } else {
            window.history.back()
            toastr.warning("Report Rejected");
          }

        },
        (error) => {
          setIsReportSubmitted(false);
          toastr.warning("Something went wrong, please try again !!");
        }
      );

    } else {
      toastr.warning("please try again later")
    }


  }

  const handleApprove = () => {

    if (sessionObject) {
      let reportSessions = sessionObject

      reportSessions.set(
        "status",
        4
      );
      reportSessions.set(
        "remarks",
        remarksText
      );

      reportSessions.set("approvedOn", new Date())

      if (Parse.User.current().attributes.teacherId) {
        reportSessions.set("approvedBy", Parse.User.current().attributes.teacherId)
      } else {
        reportSessions.set("approvedBy", Parse.User.current().id)
      }

      reportSessions.save().then(
        (result) => {
          if (isSuperAdmin && reportType === Constants.CR_SUPERVISION) {
            saveAdminComments(true)
          } else {
            window.history.back()
            toastr.success("Report Approved");
          }
        },
        (error) => {
          setIsReportSubmitted(false);
          toastr.warning("Something went wrong, please try again !!");
        }
      );

    } else {
      toastr.warning("please try again later")
    }

  }

  const getSchoolName = (objectId) => {
    let schoolName = ""
    if (objectId && objectId.length > 0) {

      if (props.schools && props.schools.length > 0) {
        let tempUser = props.schools.filter((school) => {
          return school.id === objectId;
        });
        if (tempUser && tempUser.length > 0) {
          schoolName = tempUser[0].attributes.Name;
        }
      }
    }



    return (" " + schoolName)


  }


  function getStatusTextColor(status) {
    let textColor = ""

    switch (status) {
      case 0:
        // code block
        textColor = ""
        break;
      case 1:
        // code block

        textColor = "text-warning"
        if (reportType === Constants.CR_SUPERVISION) {
          textColor = "text-success "
        }
        break;
      case 2:
        // code block
        textColor = "text-warning "
        break;

      case 3:
        // code block
        textColor = "text-danger "
        break;
      case 4:
        // code block
        textColor = "text-success "
        break;
      default:
        textColor = ""
        break;
    }

    return textColor;
  }


  function getStatus(status) {
    let statusString = "-"

    switch (status) {
      case 0:
        // code block
        statusString = "Not Submitted"
        break;
      case 1:
        // code block
        if (matchFranchise) {
          statusString = "Submitted, waiting for approval"
        } else {
          statusString = "Submitted, waiting for approval. Editing this Report is not allowed"
        }
        if (reportType === Constants.CR_SUPERVISION) {
          statusString = "Submitted"
        }
        break;
      case 2:
        // code block
        if (matchFranchise) {
          statusString = "Re-submitted, waiting for approval"
        } else {
          statusString = "Re-submitted, waiting for approval. Editing this Report is not allowed"

        }
        break;

      case 3:
        // code block
        statusString = "Rejected"
        break;
      case 4:
        // code block
        statusString = "Approved"
        break;
      default:
        statusString = "-"
        break;
    }

    return statusString;
  }

  const handleRemoveQuestion = (sectionObject, groupObject) => {




    let reportDataListToDelete = []

    if (groupObject.questionList && groupObject.questionList.length > 0) {
      for (var i = 0; i < groupObject.questionList.length; i++) {
        let reportData = groupObject.questionList[i].resultParseObject
        if (reportData) {
          reportData.set("isDeleted", true)
          reportDataListToDelete.push(reportData)
        }

      }

    }

    if (reportDataListToDelete.length > 0) {
      setDeletingQuestion(true)

      Parse.Object.saveAll(reportDataListToDelete).then(
        (result) => {
          setDeletingQuestion(false)
          let tempSectionObj = sectionObject;
          let tempList = [...reportSectionList];

          let tempSectionIndex = tempList.indexOf(sectionObject);
          let tempGroupList = [...tempSectionObj.reportQuestionGroupsList];
          let tempGroupIndex = tempGroupList.indexOf(groupObject);
          removeElement(tempGroupList, tempGroupList[tempGroupIndex]);
          tempSectionObj.reportQuestionGroupsList = [...tempGroupList];

          tempList[tempSectionIndex] = tempSectionObj;

          setReportSectionList(tempList);


          toastr.success("Removed successfully !!", { timeOut: 1000 });
        },
        (error) => {
          setDeletingQuestion(false)
          toastr.success("Something went wrong, please try again !!", {
            timeOut: 1000,
          });
        }
      );
    } else {
      let tempSectionObj = sectionObject;
      let tempList = [...reportSectionList];

      let tempSectionIndex = tempList.indexOf(sectionObject);
      let tempGroupList = [...tempSectionObj.reportQuestionGroupsList];
      let tempGroupIndex = tempGroupList.indexOf(groupObject);
      removeElement(tempGroupList, tempGroupList[tempGroupIndex]);
      tempSectionObj.reportQuestionGroupsList = [...tempGroupList];

      tempList[tempSectionIndex] = tempSectionObj;

      setReportSectionList(tempList);


    }









  };
  const checkForDifferentQuestionsValidations = (

  ) => {
    var canProceedToNextScreen = true;
    var i = 0;

    for (i = 0; i < reportSectionList.length; i++) {
      if (reportSectionList[i].type === Constants.MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH) {

        if (reportSectionList[i].reportQuestionGroupsList && reportSectionList[i].reportQuestionGroupsList.length > 0) {
          for (var j = 0; j < reportSectionList[i].reportQuestionGroupsList[0].questionList.length; j++) {
            let questionObj = reportSectionList[i].reportQuestionGroupsList[0].questionList[j]
            if (questionObj.isMandatory) {
              if (!questionObj.answerChoices || questionObj.answerChoices.length === 0 || questionObj.answerChoices[0] === "Select") {
                canProceedToNextScreen = false;
                setSectionCurrentlySelected(reportSectionList[i].reportSectionParseObject.id)
                setAnswerErrorTrueFalse(questionObj, reportSectionList[i].reportQuestionGroupsList[0], reportSectionList[i], true);

              }
            }

          }
        }
      }
    }

    return canProceedToNextScreen;
  };



  const setAnswerErrorTrueFalse = (
    reportQuestionObject,
    reportQuestionGroup,
    reportSectionObject,
    changedValue
  ) => {

    var groupIndex = reportSectionObject.reportQuestionGroupsList.indexOf(
      reportQuestionGroup
    );
    var questionIndex = reportSectionObject.reportQuestionGroupsList[groupIndex].questionList.indexOf(
      reportQuestionObject
    );
    var sectionIndex = reportSectionList.indexOf(reportSectionObject);



    var tempQuestionObject = { ...reportQuestionObject };
    var tempTitleObject = reportSectionList[sectionIndex];

    tempQuestionObject.answerError = changedValue;


    var tempQuestionList = [...tempTitleObject.reportQuestionGroupsList[groupIndex].questionList];
    tempQuestionList[questionIndex] = tempQuestionObject;
    tempTitleObject.reportQuestionGroupsList[groupIndex].questionList = [...tempQuestionList];
    var tempTitleList = [...reportSectionList];
    tempTitleList[sectionIndex] = tempTitleObject;
    setReportSectionList(tempTitleList);

  }

  const getTeacherName = (objectId) => {
    let teacherName = ""

    if (objectId && objectId.length > 0) {
      if (props.teachersForIds && props.teachersForIds.length > 0) {
        let tempUser = props.teachersForIds.filter((teacher) => {
          return teacher.id === objectId;
        });
        if (tempUser && tempUser.length > 0) {
          teacherName = tempUser[0].attributes.Name;
        }
      }
    }

    return teacherName


  }

  useEffect(() => {

    if (reportSectionList && reportSectionList.length > 0) {
      let tempAcademicGrade = ""
      let tempACount = 0
      for (var i = 0; i < reportSectionList.length; i++) {
        if (reportSectionList[i].type === Constants.MR_SECTION_TYPE_SUPERVISION) {
          let groupList = [...reportSectionList[i].reportQuestionGroupsList]
          if (groupList && groupList.length > 0) {
            let questionList = groupList[0].questionList

            for (var j = 0; j < questionList.length; j++) {
              let tempAnswerChoice = questionList[j].answerChoices
              if (tempAnswerChoice && tempAnswerChoice.length > 0) {
                if (tempAnswerChoice[0] === "A") {
                  tempACount += 1
                }
              }
            }
          }
        }
      }
      if (tempACount === 19) {
        tempAcademicGrade = "A+"
      } else if (tempACount > 15) {
        tempAcademicGrade = "A"
      } else if (tempACount > 12) {
        tempAcademicGrade = "B"
      } else if (tempACount < 13) {
        tempAcademicGrade = "C"
      }
      if (tempAcademicGrade.length > 0) {
        setAcademicGrade(tempAcademicGrade)
      }
    }


  }, [reportSectionList])


  const showSaveAndSubmitButtons = () => {

    let isVisible = false

    if (matchFranchiseCreateReport) {
      return true
    } else if (matchFranchise) {
      if (reportType === Constants.CR_SUPERVISION && sessionObject) {
        if (sessionObject.attributes.status !== Constants.MR_SESSION_STATUS_SUBMITTED && sessionObject.attributes.status !== Constants.MR_SESSION_STATUS_APPROVED &&
          sessionObject.attributes.status !== Constants.MR_SESSION_STATUS_RESUBMITTED) {
          isVisible = true
        }
      }
    } else if (matchEditing && reportType !== Constants.CR_SUPERVISION) {
      if (sessionObject) {

        if (sessionObject.attributes.status !== Constants.MR_SESSION_STATUS_SUBMITTED && sessionObject.attributes.status !== Constants.MR_SESSION_STATUS_APPROVED) {
          isVisible = true
        }

      } else {
        isVisible = false
      }
    } else if (match) {
      isVisible = true
    }

    if (isSuperAdmin && reportType === Constants.CR_SUPERVISION) {
      isVisible = false
    }


    return isVisible
  }

  const showApproveAndRejectButtons = () => {

    let isVisible = false


    if (matchFranchise && sessionObject) {
      if (reportType !== Constants.CR_SUPERVISION && sessionObject.attributes.status !== Constants.MR_SESSION_STATUS_APPROVED) {
        isVisible = true
      }
    }

    if (isSuperAdmin && reportType === Constants.CR_SUPERVISION && sessionObject && sessionObject.attributes.status !== Constants.MR_SESSION_STATUS_APPROVED
      && sessionObject.attributes.status !== Constants.MR_SESSION_STATUS_REJECTED) {
      isVisible = true
    }

    return isVisible
  }

  const shouldDisableEditingAdminComments = () => {

    let shouldDisable = true

    if (isSuperAdmin && reportType === Constants.CR_SUPERVISION && sessionObject) {
      if (sessionObject.attributes.status === Constants.MR_SESSION_STATUS_SUBMITTED
        || sessionObject.attributes.status === Constants.MR_SESSION_STATUS_RESUBMITTED) {
        shouldDisable = false
      }
    }

    return shouldDisable
  }

  const shouldShowAdminComments = () => {
    let isVisible = false

    if (isSuperAdmin || matchFranchise) {
      if (reportType === Constants.CR_SUPERVISION) {
        isVisible = true
      }
    }
    return isVisible
  }



  function ImageUploadPreviev(e) {

    let tempFileList = [];
    console.log(e.target.files.length);
    for (const i in e.target.files) {

      if (e.target.files.length > i) {
        let tempFile = e.target.files[i];
        if (tempFile && tempFile.name) {
          let tempfileExtension = tempFile.name.split(".");
          let tempExt = tempfileExtension[tempfileExtension.length - 1].toLowerCase() ? tempfileExtension[tempfileExtension.length - 1].toLowerCase() : "jpeg"
          let newFile = {
            id: "fiele_" + i,
            file: tempFile,
            type: "Image",
            downloadeble: true,
            actualFileName: tempFile.name,
            name: tempfileExtension[0],
            extention: tempExt,
            extentionIcon: "",
            preview: URL.createObjectURL(tempFile)
          }
          tempFileList.push(newFile);
        }
      }
    }

    setFileList(tempFileList);
  }

  const handleOnImageUpload = (albums, albumId) => {

    //Append Albums to Existing Album List.
    toastr.success("Photos uploaded Successfully");

    let tempAlbums = []
    if (albums && albums.length > 0) {
      tempAlbums = albums
    }
    setFileList([])
    if (selectedQuestion.resultParseObject) {

      if (selectedQuestion.albumId && selectedQuestion.albumId.length > 0) {
        tempAlbums = tempAlbums.concat(selectedQuestion.uploadedImages ?? [])
        handleChangeResultObject(selectedQuestion, selectedSection.reportQuestionGroupsList[0], selectedSection, undefined, tempAlbums ?? [])
        // setFileList([])
      } else {

        selectedQuestion.resultParseObject.set("albumId", albumId)
        selectedQuestion.resultParseObject.save().then((result) => {
          // setFileList([])
          handleChangeResultObject(selectedQuestion, selectedSection.reportQuestionGroupsList[0], selectedSection, result, tempAlbums ?? [])
        })
        //Save to report Data albumId
      }

    } else {
      setFileList([])
      handleChangeResultObject(selectedQuestion, selectedSection.reportQuestionGroupsList[0], selectedSection, undefined, tempAlbums ?? [], albumId)
    }

  }


  return (
    <React.Fragment>

      {fileList && fileList.length > 0 && <ImageUploadComponent
        fileList={fileList}
        albumId={(selectedQuestion.albumId && selectedQuestion.albumId) ?? uuidv4()}
        onComplete={handleOnImageUpload}
        onCancelClickedOnImageUpload={() => {
          setFileList([])
        }}
      />}

      <ImageViewer
        isOpen={selectedQuestionForViewImage ? true : false}
        images={(selectedQuestionForViewImage && selectedQuestionForViewImage.uploadedImages) ?? []}
        onClosePressedInImageViewer={() => {
          setSelectedQuestionForViewImage(undefined)
        }}
      />

      {
        <Modal isOpen={showModal}>


          <ModalHeader>

            <Label className="modal-title mt-0" id="myModalLabel">
              Remarks
            </Label>
          </ModalHeader>
          <ModalBody >

            <input
              type="text"
              className="form-control"
              onChange={(e) => {
                setRemarksText(e.target.value)
              }}
              value={remarksText}
              placeholder="Enter remarks"
            >

            </input>


          </ModalBody>

          <ModalFooter>
            <Row className="text-center">
              <button
                type="button"
                onClick={() => {
                  setShowModal(0)
                }}
                className="btn btn-secondary waves-effect"
                data-dismiss="modal"
              >
                {props.t('Cancel')}
              </button>
              <button
                className={showModal === 1 ? "btn btn-success waves-effect waves-light ml-2" : "btn btn-danger waves-effect waves-light ml-2"}
                onClick={() => {
                  setDisableApproveButton(true)
                  if (!disableApproveButton) {
                    if (showModal === 1) {
                      handleApprove()
                    } else if (showModal === 2) {
                      handleReject()
                    }
                  }
                }}
              >
                {(showModal !== 0 && showModal === 1) ? "Approve" : ""}
                {(showModal !== 0 && showModal === 2) ? "Reject" : ""}
              </button>

            </Row>




          </ModalFooter>
        </Modal>
      }
      <div className="page-content">
        {!isLoading && <Container fluid>

          <AvForm
            className="outer-repeater"
            style={{ border: "1px" }}
            onValidSubmit={() => {
              isReportValid(false)
            }}
          >

            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=' m-2'>
                  <h4 >{sessionObject ? sessionObject.attributes.sessionName : reportTemplateName}</h4> <h4 className="ml-2">{sessionObject ? getSchoolName(sessionObject.attributes.schoolId) : ""}</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                  {

                    showSaveAndSubmitButtons()


                    &&
                    <div className="float-right">
                      <Button
                        color="primary"
                        disabled={reportIsSubmitted}
                        className="m-1"
                        onClick={() => {
                          isReportValid(true)
                        }}
                      >
                        {reportIsSubmitted && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle m-1"></i>
                        )}
                        {props.t("Save")}
                      </Button>
                      <Button
                        type="submit"
                        color="primary"
                        disabled={reportIsSubmitted}

                      >
                        {reportIsSubmitted && (
                          <i className="bx bx-loader bx-spin font-size-16 align-middle m-1"></i>
                        )}
                        {props.t("Submit")}
                      </Button>

                    </div>}
                  {showApproveAndRejectButtons() && <div className="float-right">
                    <Button
                      color="danger"

                      className="m-1"
                      onClick={() => {
                        setRemarksText("")
                        setShowModal(2)
                      }}
                    >
                      {/* {reportIsSubmitted && (
                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                  )} */}
                      {props.t("Reject")}
                    </Button>
                    <Button

                      color="success"

                      onClick={() => {
                        setRemarksText("")
                        setShowModal(1)
                      }}
                    >
                      {/* {reportIsSubmitted && (
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                        )} */}
                      {props.t("Approve")}
                    </Button>

                  </div>}
                </div>
              </Col>
            </Row>

            {
              reportType === Constants.CR_SUPERVISION &&
              <Card style={!selectedDateForSupervisionReport ? { height: "200px" } : {}}>
                <CardBody>
                  <FormGroup >
                    {sessionObject && <Col lg={4} md={4} sm={4} className="mt-3 mb-2">
                      <Label>{"Report Status - "}</Label><Label className={getStatusTextColor(sessionObject.attributes.status)}>{getStatus(sessionObject.attributes.status)}</Label></Col>}

                    <Col lg={4} md={4} sm={12} xs={12} className="mt-3 mb-2">





                      <FormGroup >
                        <Label htmlFor="centerSelection">
                          Name of the Center
                        </Label>

                        {<select className="form-control"
                          id={"centerSelection"}
                          disabled={sessionObject ? true : false}
                          value={centerIdForSupervisionReport}
                          onChange={(e) => {
                            setCenterIdForSupervisionReport(e.target.value)
                          }}
                        >
                          <option value="Select">{"Select"}</option>
                          {props.schools && props.schools.map((school, keyInside) => (
                            <option value={school.id}>{school.attributes.Name}</option>
                          ))}
                        </select>}

                      </FormGroup>





                      <Label htmlFor="SelectDateForSupervisionReport">
                        {sessionObject ? "Date Selected " : "Select Date "}
                      </Label>
                      <ReactDatePicker
                        disabled={sessionObject}
                        id={"questionDatePickerForSupervision"}
                        selected={
                          selectedDateForSupervisionReport ? new Date(selectedDateForSupervisionReport) : undefined
                        }
                        onChange={(date) => {

                          setSelectedDateForSupervisionReport(new Date(date))


                        }}
                        className="form-control"
                        placeholderText="dd/mm/yyyy"
                        dateFormat="dd/MM/yyyy"
                        showDayMonthYearPicker
                      />






                      {sessionObject && sessionObject.attributes.createdBy && <div className="mt-3"><h5>Supervised by Mentor - {getTeacherName(sessionObject.attributes.createdBy)}</h5></div>}


                      {false && <div className="mt-2"><h5 className="">Academic Grade - {academicGrade}</h5></div>}
                      {/* <Label>ACADEMIC STATUS OF CENTRE</Label> */}




                    </Col>
                    {false && <Alert
                      color="danger"
                      role="alert"
                    >
                      <p className="lead mb-1"><strong><u>Grading used</u></strong></p>
                      <p className="mb-0">(A+) - The centre has received A’s in all 19 parameters.</p>
                      <p className="mb-0">(A) – The centre has received A’s in 16-18 parameters. </p>
                      <p className="mb-0">(B) - The center has received A’s in 15-13 parameters.</p>
                      <p className="mb-0">(C) - The centre has received A’s in below 13 parameters.</p>
                    </Alert>}
                    {sessionObject && sessionObject.attributes.remarks && <Col lg={4} md={4} sm={4} className="mt-3 mb-2">
                      <Label>Remarks - </Label><Label>{sessionObject.attributes.remarks}</Label></Col>}
                  </FormGroup>
                </CardBody>
              </Card>
            }
            {reportType !== Constants.CR_SUPERVISION && <Card style={!selectedMonthDate ? { height: "200px" } : {}}>
              <CardBody>
                <FormGroup >
                  {sessionObject && <Col lg={4} md={4} sm={4} className="mt-3 mb-2">
                    <Label>{"Report Status - "}</Label><Label className={getStatusTextColor(sessionObject.attributes.status)}>{getStatus(sessionObject.attributes.status)}</Label></Col>}

                  <Col lg={4} md={4} sm={4} className="mt-3 mb-2">
                    <Label htmlFor="PickMonth">
                      {sessionObject ? "Month Selected" : "Select Month"}
                    </Label>
                    {
                      <Label htmlFor="PickMonth" className="text-danger">
                        *
                      </Label>
                    }
                    {selectedMonthDateError === true && (
                      <Label className="label-xsmall text-danger ml-2">
                        (this field is mandatory)
                      </Label>
                    )}


                    <ReactDatePicker
                      disabled={matchFranchise || sessionObject}
                      id={"questionDatePickMonth"}
                      selected={
                        selectedMonthDate ? new Date(selectedMonthDate) : undefined
                      }
                      onChange={(date) => {

                        setSelectedMonthDate(new Date(date))


                      }}
                      className="form-control"
                      placeholderText="mm/yyyy"
                      dateFormat="MM/yyyy"
                      showMonthYearPicker
                      showFullMonthYearPicker
                    />
                  </Col>
                  {sessionObject && sessionObject.attributes.remarks && <Col lg={4} md={4} sm={4} className="mt-3 mb-2">
                    <Label>Remarks - </Label><Label>{sessionObject.attributes.remarks}</Label></Col>}
                </FormGroup>
              </CardBody>
            </Card>}



            {((reportType === Constants.CR_MONTHLY_REPORTS && selectedMonthDate) || (reportType === Constants.CR_SUPERVISION)) && <Card>
              <CardBody>
                {reportSectionList.map((sectionObject, sectionKey) => (
                  <Card className="mb-1 bg-white" key={sectionKey}>
                    <CardHeader className="p-3" id="headingOne">
                      <div>

                        <Row>
                          <Col
                            lg={10}
                            xs={10}
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                sectionCurrentlySelected ===
                                sectionObject.reportSectionParseObject.id
                              ) {
                                setSectionCurrentlySelected("");
                              } else {
                                setSectionCurrentlySelected(
                                  sectionObject.reportSectionParseObject.id
                                );
                              }
                            }}
                          >
                            <h6 className="m-0 font-14">
                              <span
                                style={{ cursor: "pointer" }}
                                className="text-dark"
                              >
                                {sectionObject.title}{" "}
                              </span>
                              <i
                                style={{ cursor: "pointer" }}
                                className={
                                  sectionCurrentlySelected ===
                                    sectionObject.reportSectionParseObject.id
                                    ? "bx bxs-chevron-up  ml-2"
                                    : "bx bxs-chevron-down  ml-2"
                                }
                              />
                            </h6>
                          </Col>
                          <Col>
                          </Col>
                        </Row>
                      </div>
                    </CardHeader>
                    <Collapse
                      isOpen={sectionCurrentlySelected === sectionObject.reportSectionParseObject.id}
                    >
                      {sectionObject.type !== Constants.MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH && sectionObject.type !== Constants.MR_SECTION_TYPE_DIMENSIONS && sectionObject.reportQuestionGroupsList.length > 0 && sectionObject.reportQuestionGroupsList[0].questionList.length === 0 && (
                        <h6 className="ml-4 mb-3 mt-3">
                          No Questions in this section.
                        </h6>
                      )}

                      {sectionObject.type !== Constants.MR_SECTION_TYPE_SUPERVISION && sectionObject.type !== Constants.MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH && sectionObject.type !== Constants.MR_SECTION_TYPE_DIMENSIONS && sectionObject.reportQuestionGroupsList.map((reportQuestionGroup, groupKey) => (

                        <Row key={sectionKey + groupKey}>
                          <Col lg="11" md="11" sm="11">
                            <Row className="border-bottom ml-2 mt-2">
                              {reportQuestionGroup.questionList.map(
                                (reportQuestionObject, keyQuestion) => (


                                  componentForQuestionType(
                                    reportQuestionObject,
                                    reportQuestionGroup,
                                    sectionObject,
                                    "" + keyQuestion + groupKey + sectionKey
                                  )
                                )
                              )}

                            </Row></Col>
                          <Col lg="1" md="1" sm="1">
                            {groupKey !== 0 && shouldDisableEditing(sessionObject) && <ul className="list-inline font-size-24 contact-links float-right mt-5 mr-3">



                              <li
                                className="list-inline-item px-2"
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  if (!deletingQuestion) {
                                    handleRemoveQuestion(sectionObject, reportQuestionGroup)

                                  } else {
                                    toastr.warning("Please try again, updating question !!")
                                  }

                                }}
                                disableEditing={deletingQuestion}
                                id={"delete1" + reportQuestionGroup.id + groupKey + sectionKey}
                              >

                                <i className="bx bx-message-square-x text-danger"></i>
                                <UncontrolledTooltip
                                  placement="top"
                                  target={"delete1" + reportQuestionGroup.id + groupKey + sectionKey}
                                >
                                  {props.t("Remove")}
                                </UncontrolledTooltip>
                              </li>
                            </ul>}
                          </Col></Row>
                      ))}


                      {(sectionObject.type === Constants.MR_SECTION_TYPE_SUPERVISION)
                        &&
                        <div className="table-responsive pl-1 pt-1">
                          <table className="table table-centered table-bordered">
                            <thead className="thead-light">
                              <tr>
                                <th style={{ width: "5%" }}>{props.t("Sr. No.")}</th>

                                <th style={{ maxWidth: "40%" }}>{props.t("Parameters")}</th>
                                <th style={{ minWidth: "80px", width: "15%" }}>{props.t('Grade')}</th>
                                <th style={{ width: "20%" }}>{props.t("Comments based on Observation")}</th>
                                {shouldShowAdminComments() && <th style={{ width: "20%" }}>{props.t("Admin Comments")}</th>}
                              </tr>
                            </thead>
                            <tbody>
                              {sectionObject.reportQuestionGroupsList && sectionObject.reportQuestionGroupsList.length > 0 && sectionObject.reportQuestionGroupsList[0].questionList.map((reportQuestionObject, questionKey) => (
                                <tr key={questionKey + sectionKey}>
                                  <td>{questionKey + 1}</td>
                                  <td ><Label>{reportQuestionObject.question}</Label></td>
                                  <td>


                                    <FormGroup>

                                      {<select className="form-control"
                                        id={"GradeSelect" + questionKey + sectionKey}
                                        disabled={shouldDisableEditing(sessionObject)}
                                        value={reportQuestionObject.answerChoices && reportQuestionObject.answerChoices[0]}
                                        onChange={(e) => {
                                          handleChoiceButtonClick(
                                            reportQuestionObject,
                                            sectionObject.reportQuestionGroupsList[0],
                                            sectionObject,
                                            e.target.value
                                          );
                                        }}
                                      >
                                        {[...reportQuestionObject.choices].map((choice, keyInside) => (
                                          <option value={choice}>{choice}</option>
                                        ))}
                                      </select>}

                                    </FormGroup>

                                  </td>
                                  <td >
                                    <FormGroup>
                                      <AvField
                                        type="textarea"
                                        name={"multiLineComments" + questionKey + sectionKey}
                                        className="form-control"
                                        rows="3"
                                        disabled={shouldDisableEditing(sessionObject)}
                                        value={reportQuestionObject.answerText ?? ""}
                                        placeholder={reportQuestionObject.questionHint}
                                        onChange={(e) => {
                                          handleInputOnChange(
                                            reportQuestionObject,
                                            sectionObject.reportQuestionGroupsList[0],
                                            sectionObject,
                                            e.target.value
                                          );
                                        }}
                                      /></FormGroup>
                                    <input type="file"
                                      className="sr-only"
                                      id="input-image"
                                      name="file"
                                      accept="image/*"
                                      onChange={ImageUploadPreviev}
                                      multiple
                                    />
                                    {!shouldDisableEditing(sessionObject) && <div><Label htmlFor={"input-image"} onClick={() => {
                                      setSelectedQuestion(reportQuestionObject)
                                      setSelectedSection(sectionObject)

                                    }}
                                      className="text-primary"
                                      style={{ cursor: "pointer" }}
                                    >

                                      Upload Photos
                                    </Label></div>}

                                    <Label htmlFor={"view-image"} onClick={() => {
                                      setSelectedQuestionForViewImage(reportQuestionObject)
                                    }}
                                      className="text-primary"
                                      style={{ cursor: "pointer" }}
                                    >

                                      View Photos
                                    </Label>
                                  </td>

                                  {shouldShowAdminComments() &&
                                    <td>
                                      <FormGroup className={!shouldDisableEditing(sessionObject) ? "mb-5 pb-1" : "mb-4 pb-1"}>
                                        <AvField
                                          type="textarea"
                                          name={"multiLineAdminComments" + questionKey + sectionKey}
                                          className="form-control"
                                          rows="3"
                                          disabled={shouldDisableEditingAdminComments()}
                                          value={reportQuestionObject.adminComments ?? ""}
                                          placeholder={reportQuestionObject.questionHint}
                                          onChange={(e) => {
                                            handleOnChangeAdminComments(
                                              reportQuestionObject,
                                              sectionObject.reportQuestionGroupsList[0],
                                              sectionObject,
                                              e.target.value
                                            );
                                          }}
                                        /></FormGroup>

                                    </td>
                                  }

                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      }





                      {
                        sectionObject.type === Constants.MR_SECTION_TYPE_STAFF_LEFT_THIS_MONTH && sectionObject.reportQuestionGroupsList.length > 0 &&
                        <div>
                          <Label className="m-4">Note: delete teachers from staff details to appear here</Label><br />
                          {sectionObject.reportQuestionGroupsList[0].questionList.map((reportQuestionObject, questionKey) => (
                            <div key={questionKey + sectionKey}>

                              <Row className="border-bottom m-4 ">
                                <Col lg="4" md="6" sm="6" xs="6">
                                  <FormGroup>
                                    <Label className="mt-2">{reportQuestionObject.question}</Label>{
                                      <Label className="text-danger">
                                        *
                                      </Label>
                                    }
                                    {reportQuestionObject.answerError === true && (
                                      <Label className="label-xsmall text-danger ml-2">
                                        (this field is mandatory)
                                      </Label>
                                    )}
                                  </FormGroup>
                                </Col>
                                <Col lg="4" md="6" sm="6" xs="6">
                                  <FormGroup>
                                    <select className="form-control"
                                      id={"select" + questionKey + sectionKey}
                                      disabled={shouldDisableEditing(sessionObject)}
                                      value={reportQuestionObject.answerChoices && reportQuestionObject.answerChoices[0]}
                                      onChange={(e) => {
                                        handleChoiceButtonClick(
                                          reportQuestionObject,
                                          sectionObject.reportQuestionGroupsList[0],
                                          sectionObject,
                                          e.target.value
                                        );
                                      }}
                                    >
                                      {reportQuestionObject.choices.map((choice, keyInside) => (
                                        <option value={choice}>{choice}</option>
                                      ))}
                                    </select>
                                  </FormGroup>
                                </Col>
                              </Row></div>
                          ))}

                          {sectionObject.reportQuestionGroupsList && sectionObject.reportQuestionGroupsList[0].questionList.length === 0 &&
                            <Label className="m-4">No deleted teachers to display</Label>}


                        </div>

                      }



                      {
                        sectionObject.type === Constants.MR_SECTION_TYPE_DIMENSIONS &&
                        <div className="table-rep-plugin m-3">
                          <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                              <Thead>
                                <Tr>
                                  <Th>Class</Th>

                                  {
                                    sectionObject.reportQuestionGroupsList.length > 0 && sectionObject.reportQuestionGroupsList[0].questionList.map((question, key) => (
                                      <Th data-priority="1">{question.question}</Th>
                                    ))
                                  }

                                </Tr>
                              </Thead>
                              <Tbody>
                                {
                                  sectionObject.reportQuestionGroupsList.map((reportQuestionGroup, groupKey) => (
                                    <Tr key={groupKey + sectionKey}>
                                      <Th><span className="co-name">{sectionObject.dimensions[groupKey]}</span></Th>
                                      {reportQuestionGroup.questionList.map((reportQuestionObject, questionKey) => (

                                        <Td key={questionKey + groupKey + sectionKey}>


                                          <AvField
                                            type="number"
                                            disabled={shouldDisableEditing(sessionObject)}
                                            name={"numberInput" + questionKey + groupKey}
                                            className="inner form-control noscroll"
                                            placeholder={props.t(reportQuestionObject.questionHint)}
                                            value={reportQuestionObject.answerText ?? ""}
                                            onChange={(e) => {
                                              handleInputOnChange(
                                                reportQuestionObject,
                                                reportQuestionGroup,
                                                sectionObject,
                                                e.target.value
                                              );
                                            }}
                                          />
                                        </Td>
                                      ))}
                                    </Tr>
                                  ))
                                }


                              </Tbody>
                            </Table>
                          </div>
                        </div>

                      }






                      {sectionObject.type === Constants.MR_SECTION_TYPE_REPEATER_BY_4_COL && !shouldDisableEditing(sessionObject) && <div><button
                        onClick={() => {
                          let sectionIndex = reportSectionList.indexOf(sectionObject)
                          let templateSectionObject = { ...templateForQuestions[sectionIndex] }
                          let tempQuestionList = [...templateSectionObject.reportQuestionGroupsList[0].questionList]
                          let tempSectionList = [...reportSectionList]
                          let tempSectionObject = { ...sectionObject }
                          let tempGroupList = [...tempSectionObject.reportQuestionGroupsList]

                          let withoutAnswerList = []
                          for (var i = 0; i < tempQuestionList.length; i++) {
                            let withoutAnswerQuestionObject = Object.assign({}, tempQuestionList[i])
                            withoutAnswerQuestionObject.answerText = undefined
                            withoutAnswerQuestionObject.answerChoices = []
                            withoutAnswerQuestionObject.answerDate = undefined
                            withoutAnswerQuestionObject.resultParseObject = null

                            withoutAnswerList.push(withoutAnswerQuestionObject)
                          }

                          tempGroupList.push(createNewGroupObject(withoutAnswerList))
                          tempSectionObject.reportQuestionGroupsList = tempGroupList
                          tempSectionList[sectionIndex] = tempSectionObject
                          setReportSectionList(tempSectionList)
                        }}
                        type="button"
                        className="btn btn-primary waves-effect float-right waves-light m-2 mt-4 mb-4"
                      >
                        <i className="bx bx-plus font-size-16 align-middle mr-2"></i>{" "}
                        {props.t("Add")}
                      </button></div>}






                    </Collapse>
                  </Card>
                ))}
              </CardBody>
            </Card>}
          </AvForm>
        </Container>}


        {
          isLoading && (
            <Row>
              <Col xs="12">
                <div className="text-center my-3">
                  <Link to="#" className="text-success">
                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                    Loading Reports...{" "}
                  </Link>
                </div>
              </Col>
            </Row>
          )
        }
      </div >
    </React.Fragment >
  );
};


const mapStatetoProps = (state) => {
  const { selectedSchool, schools } = state.School;
  const { classrooms } = state.Classroom;
  const { deletedTeachersForMonth, teachersForIds } = state.Teacher;
  const { userTeacher } = state.Login;


  return { userTeacher, selectedSchool, classrooms, schools, deletedTeachersForMonth, teachersForIds };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getClassroomsForSchoolId,
      getDeletedTeachersForMonth,
      updateDeletedTeachersForMonth, getTeacherForIds
      , getUserTeacher

    })(MonthlyReport)
  )
);

class CustomReportObject {
  question = "";
  questionType = null;
  questionNumber = null;
  reportQuestionParseObject = null;
  answerText = null;
  answerNumber = null;
  answerBool = null;
  answerDate = null;
  answerChoices = [];
  choices = [];
  dataType = null;
  questionHint = "Type here";
  isMandatory = false;
  answerError = false;
  answerFile = null;
  answerImage = null;
  answerFileUploadProgress = 0;
  answerImageUploadProgress = 0;
  isDefaultQuestion = false;
  resultParseObject = null;
  dimensions = [];
  adminComments = null;
  uploadedImages = [];
  albumId = null;

  constructor(
    question,
    questionType,
    questionNumber,
    reportQuestionParseObject,
    answerText,
    answerNumber,
    answerBool,
    dataType,
    answerDate,
    answerChoices,
    choices,
    questionHint,
    isMandatory = false,
    answerError = false,
    answerFile,
    answerImage,
    answerFileUploadProgress,
    answerImageUploadProgress,
    isDefaultQuestion = false,
    resultParseObject,
    dimensions = [],
    adminComments,
    uploadedImages = [],
    albumId = null
  ) {
    this.question = question;
    this.questionType = questionType;
    this.questionNumber = questionNumber;
    this.reportQuestionParseObject = reportQuestionParseObject;
    this.answerText = answerText;
    this.adminComments = adminComments;
    this.answerNumber = answerNumber;
    this.answerBool = answerBool;
    this.dataType = dataType;
    this.answerDate = answerDate;
    this.answerChoices = answerChoices;
    this.choices = choices;
    this.questionHint = questionHint;
    this.isMandatory = isMandatory;
    this.answerError = answerError;
    this.answerFile = answerFile;
    this.answerImage = answerImage;
    this.answerFileUploadProgress = answerFileUploadProgress;
    this.answerImageUploadProgress = answerImageUploadProgress;
    this.isDefaultQuestion = isDefaultQuestion;
    this.resultParseObject = resultParseObject;
    this.dimensions = dimensions;
    this.uploadedImages = uploadedImages;
    this.albumId = albumId;
  }
}
class CustomReportSectionObject {
  reportQuestionGroupsList = [];
  title = "";
  reportSectionParseObject = null;
  type = 0
  id = null
  dimensions = []

  constructor(reportQuestionGroupsList, reportSectionParseObject, title, type, id, dimensions) {
    this.reportQuestionGroupsList = reportQuestionGroupsList;
    this.title = title;
    this.reportSectionParseObject = reportSectionParseObject;
    this.type = type
    this.id = id
    this.dimensions = dimensions
  }
}

//              NOTES

// function uuidv4() {
//   return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
//     /[xy]/g,
//     function (c) {
//       var r = (Math.random() * 16) | 0,
//         v = c == "x" ? r : (r & 0x3) | 0x8;
//       return v.toString(16);
//     }
//   );
// }

// function strReplace(stringValue) {
//   var newStr = stringValue.replace(/%20/g, " ");

//   return newStr;
// }




