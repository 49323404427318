export const UPDATE_LEARNING_SESSION = "UPDATE_LEARNING_SESSION";   
export const CLEAR_LEARNING_SESSION = "CLEAR_LEARNING_SESSION";   

export const GET_LEARNING_SESSION_FOR_SCHOOL_ID = "GET_ASSESSMENTS_FOR_SCHOOL_ID"

export const GET_ONLINE_LEARNING_SESSION_FOR_SCHOOL_ID = "GET_ONLINE_LEARNING_SESSION_FOR_SCHOOL_ID";
export const UPDATE_ONLINE_LEARNING_SESSION = "UPDATE_ONLINE_LEARNING_SESSION";

export const SET_SELECTED_LEARNING_SESSION = "SET_SELECTED_LEARNING_SESSION";
export const SET_SELECTED_ONLINE_LEARNING_SESSION = "SET_SELECTED_ONLINE_LEARNING_SESSION";

export const UPDATE_SELECTED_LEARNING_SESSION = "UPDATE_SELECTED_LEARNING_SESSION";
export const UPDATE_SELECTED_ONLINE_LEARNING_SESSION = "UPDATE_SELECTED_ONLINE_LEARNING_SESSION";

export const GET_ONLINE_LEARNING_TRACK_FOR_SESSION_ID = "GET_ONLINE_LEARNING_TRACK_FOR_SESSION_ID";
export const UPDATE_ONLINE_LEARNING_TRACK_FOR_SESSION_ID = "UPDATE_ONLINE_LEARNING_TRACK_FOR_SESSION_ID";

export const GET_LEARNING_TRACKING_FOR_SESSION_ID = "GET_LEARNING_TRACKING_FOR_SESSION_ID";
export const UPDATE_LEARNING_TRACKING_FOR_SESSION_ID = "UPDATE_LEARNING_TRACKING_FOR_SESSION_ID";
