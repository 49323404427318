import Parse from "parse";
import { printLog } from "./loghelper";

export const getMessageCreditForPayload = (
  schoolId,
  instituteId,
  franchiseId
) => {
  printLog("HL: messageCreditHealper_helper : getMessageCreditForPayload")

  try {
    return new Promise((resolve, reject) => {
      let query;
      query = new Parse.Query("MessageCredits");
      if (schoolId || instituteId) {
        if (schoolId && instituteId) {
          let query1 = new Parse.Query("MessageCredits");
          query1.equalTo("instituteType", "school");
          query1.equalTo("schoolId", schoolId);

          let query2 = new Parse.Query("MessageCredits");
          query2.equalTo("instituteType", "institute");
          query2.equalTo("instituteId", instituteId);

          query = Parse.Query.or(query1, query2);
        } else if (schoolId) {
          query.equalTo("instituteType", "school");
          query.equalTo("schoolId", schoolId);
        } else {
          query.equalTo("instituteType", "institute");
          query.equalTo("instituteId", instituteId);
        }
        query.find().then((result) => {
          resolve(result);
        });
      } else if (franchiseId) {
        query.equalTo("instituteType", "franchise");
        query.equalTo("franchiseId", franchiseId);
        query.find().then((result) => {
          resolve(result);
        });
      }
    });
  } catch (err) {
    console.log(err);
  }
};

export const getPricingForMessages = () => {
  printLog("HL: messageCreditHealper_helper : getPricingForMessages")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Pricing");
      //add whatsapp for whatsapp credit activate
      query.containedIn("itemName", ["sms"]);
      query.find().then((result) => {
        resolve(result);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const createMoneyTriansaction = (
  amountToPay,
  invoiceItems,
  schoolId
) => {
  printLog("HL: messageCreditHealper_helper : createMoneyTriansaction")
  try {
    return new Promise((resolve, reject) => {
      let currentUser = Parse.User.current();
      if (currentUser.teacherId) {
        let query = new Parse.Query("Teacher");
        query.equalTo("objectId", currentUser.teacherId);
        query.first().then((result) => {
          if (result && false) {
            if (result.attributes.Role === 3) {
              createMtObj(
                amountToPay,
                invoiceItems,
                result.attributes.instituteId,
                "institute",
                result.id
              ).then((result) => {
                resolve(result);
              });
            } else if (result.attributes.Role >= 10) {
              createMtObj(
                amountToPay,
                invoiceItems,
                result.attributes.franchiseId,
                "franchise",
                result.id
              ).then((result) => {
                resolve(result);
              });
            } else {
              createMtObj(
                amountToPay,
                invoiceItems,
                result.attributes.SchoolID,
                "school",
                result.id
              ).then((result) => {
                resolve(result);
              });
            }
          } else {
            createMtObj(
              amountToPay,
              invoiceItems,
              schoolId,
              "school",
              currentUser.id
            ).then((result) => {
              resolve(result);
            });
          }
        });
      } else {
        createMtObj(
          amountToPay,
          invoiceItems,
          schoolId,
          "school",
          currentUser.id
        ).then((result) => {
          resolve(result);
        });
      }
    });
  } catch (err) {
    console.log(err);
  }
};
const createMtObj = (
  amountToPay,
  invoiceItems,
  instituteId,
  instituteType,
  userId
) => {
  printLog("HL: messageCreditHealper_helper : createMtObj")
  try {
    return new Promise((resolve, reject) => {
      let MoneyTransactions = Parse.Object.extend("MoneyTransactions");
      let mt = new MoneyTransactions();
      mt.set("schoolId", "sms/whatsapp");
      mt.set("instituteType", instituteType);
      mt.set("instituteId", instituteId);
      mt.set("invoiceId", "lilTriangleCredits");
      mt.set("amount", amountToPay);
      mt.set("transactionType", "AirPay");
      mt.set("deleted", true);
      mt.set("invoiceItems", invoiceItems);
      mt.set("userId", userId);
      mt.save().then(
        (result) => {
          resolve(result);
        },
        (err) => {
          console.log(err);
        }
      );
    });
  } catch (err) {
    console.log(err);
  }
};
