import React, { useEffect, useState } from 'react';
import { withNamespaces } from 'react-i18next';
import {
    setSelecetdSchool,
    getAllKidsForGroup,
    getGroupMapsForGroupId,
    getInvoicesForProgramId,
    getItemsForInvoiceIds,
    getProgramTermsForProgramId,
    getAllDeletedKidsForSchoolIdOnlyForArchives,
} from '../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import moment from 'moment';
import DocViewer, { DocViewerRenderers, BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";

import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    CardSubtitle,
    Button,
    Modal, ModalHeader, ModalBody, UncontrolledTooltip, ModalFooter, Container,
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';
import { matchPath } from 'react-router-dom/cjs/react-router-dom.min';
import { identity } from 'lodash';

const ProgramAnalytics = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/programAnalytics/:schoolId/:programId/:groupId",
        exact: true,
        strict: false,
    });


    const schoolId = match && match.params.schoolId;
    const programId = match && match.params.programId;
    const groupId = match && match.params.groupId;


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        if (Parse.User.current() && props.selectedSchool) {
            props.getGroupMapsForGroupId(groupId);
            props.getAllKidsForGroup(schoolId);
            props.getProgramTermsForProgramId(programId);
            props.getAllDeletedKidsForSchoolIdOnlyForArchives(schoolId)
            getProgramForProgramId()
        }
    }, [props.selectedSchool, schoolId]);

    const getProgramForProgramId = () => {
        var query = new Parse.Query('Program');
        query.equalTo('objectId', programId);
        query.limit(1);
        query.find().then((results) => {
            if (results && results.length > 0) {
                setHasTerms(results[0].attributes.hasTerm ?? false)
                setProgramObject(results[0])
            }
        })
    }

    const [programObject, setProgramObject] = useState();
    const [showReport, setShowReport] = useState(true);
    const [listForReport23, setListForReport23] = useState([]);
    const [listForReport23Batch, setListForReport23Batch] = useState([]);
    const [listForReport1, setListForReport1] = useState([]);
    const [listForReport1Batch, setListForReport1Batch] = useState([]);
    const [selectedEndDate, selectedEndDateOps] = useState();
    const [selectedDate, selectedDateOps] = useState();
    const [hasTerms, setHasTerms] = useState(false);
    const [selectedBatch, setSelectedBatch] = useState("All Batches")

    useEffect(() => {
        if (props.groupmaps && props.groupmaps.length > 0 && props.kids && props.admissionKids && props.programTerms) {
            let tempList = []
            let tempDeletedList = []
            let tempKidList = []
            tempKidList.push.apply(tempKidList, props.kids)
            tempKidList.push.apply(tempKidList, props.admissionKids)
            tempKidList.push.apply(tempKidList, props.deletedKids)
            for (var i = 0; i < props.groupmaps.length; i++) {
                if (props.groupmaps[i].attributes.groupId === groupId) {
                    let kidObjList = tempKidList.filter((kid) =>
                        kid.id === props.groupmaps[i].attributes.userId
                    )
                    if (kidObjList.length > 0) {
                        let tempObject = {}

                        tempObject.kidParseObject = kidObjList[0]
                        tempObject.kidId=kidObjList[0].id
                        tempObject.groupmapId = props.groupmaps[i].id
                        tempObject.groupMapTags = props.groupmaps[i].attributes.tags
                        let tempTerms = []
                        for (const term of props.programTerms) {
                            let tempTermObj = {}
                            tempTermObj.id = term.id,
                                tempTermObj.termParseObject = term
                            tempTerms.push(tempTermObj)
                        }
                        tempObject.terms = tempTerms
                        tempList.push(tempObject)
                    }
                }
            }
            setListForReport23(tempList)
            props.getInvoicesForProgramId(programId, false);
        }
    }, [props.groupmaps, props.kids, props.admissionKids, props.programTerms])

    useEffect(() => {
        if (props.programTerms && props.programTerms.length > 0) {
            let tempTerms = []
            for (const term of props.programTerms) {
                let tempObj = {}
                tempObj.id = term.id,
                    tempObj.termName = term.attributes.termName ?? "-"
                tempObj.termParseObject = term
                tempObj.participantsCount = (term.attributes.kidIds && term.attributes.kidIds.length) ?? 0

                tempTerms.push(tempObj)
            }
            let totalobject = {
                id: "totalobject",
                termName: "TOTAL",
                totalAmount: 0,
                collectedAmount: 0,
                pendingAmount: 0
            }
            tempTerms.push(totalobject)
            setListForReport1(tempTerms)
        }
    }, [props.programTerms])

    useEffect(() => {
        if (props.invoicesForProgram) {
            let invoiceIds = []
            for (const inv of props.invoicesForProgram) {
                invoiceIds.push(inv.id)
            }
            props.getItemsForInvoiceIds(invoiceIds);
        }
    }, [props.invoicesForProgram]);

    useEffect(() => {
        if (props.invoiceItems && props.invoicesForProgram) {
            let tempSubList=[]
            let tempMainList = [...listForReport23]
            for (const m of tempMainList) {

                if (selectedBatch != "All Batches") {
                   
                    if ( m.groupMapTags&&  m.groupMapTags.length>0 &&  m.groupMapTags[0] == selectedBatch) {
          
                    } else {
                      continue;
                    }
                }
                

                let mtotalAmount = 0
                let mcollectedAmount = 0

                let listOfInvoices = []
                let listOfInvoiceItems = []
                let tempTerms = [...m.terms]
                for (const inv of props.invoicesForProgram) {

                    if (m.kidParseObject.id === inv.attributes.recipientId) {
                        listOfInvoices.push(inv)
                        mtotalAmount += +inv.attributes.amount;
                        mcollectedAmount += (inv.attributes.paidAmount ? inv.attributes.paidAmount : 0);

                        for (const val of props.invoiceItems) {
                            if (inv.id === val.attributes.invoiceId) {
                                listOfInvoiceItems.push(val)
                            }
                        }

                    }
                }

                m.totalAmount = mtotalAmount
                m.collectedAmount = mcollectedAmount
                m.pendingAmount = (mtotalAmount - mcollectedAmount);

                for (const term of tempTerms) {
                    term.totalAmount = 0;
                    term.collectedAmount = 0
                    term.pendingAmount = 0
                    term.termInvoices = []
                    term.termItems = []

                    for (const iti of listOfInvoiceItems) {

                        if (term.id === iti.attributes.termId) {
                            // term.termInvoices.push(inv)
                            term.termItems.push(iti);
                            term.totalAmount += +iti.attributes.totalAmount;
                            if (iti.attributes.paidStatus == 1) {
                                term.collectedAmount += (iti.attributes.totalAmount ?? 0);
                            } else {
                                term.collectedAmount += (iti.attributes.paidAmount ?? 0);
                            }


                        }
                    }
                    term.pendingAmount += (term.totalAmount - term.collectedAmount);

                }
                m.terms = tempTerms
                m.invoices = listOfInvoices
                m.invoiceItems = listOfInvoiceItems

                tempSubList.push(m)
            }
            if(selectedBatch=="All Batches"){
                setListForReport23(tempMainList)
                setListForReport23Batch(tempMainList)
            }else{
                setListForReport23Batch(tempSubList)
            }
            



            let tempList = [...listForReport1]
            let tAmount = 0;
            let tCollectedAmount = 0;
            let tPendingAmount = 0;
            for (const m of tempList) {

                let participantsList=[];
                if (m.id == "totalobject") {
                    continue;
                }
                m.totalAmount = 0
                m.collectedAmount = 0
                m.pendingAmount = 0


                let listOfInvoices = []
                let listOfInvoiceItems = []

                for (const inv of props.invoicesForProgram) {
                    if (inv.attributes.programTermIds && inv.attributes.programTermIds.includes(m.termParseObject.id)) {

                        if (selectedBatch != "All Batches") {
                            let tKidId = inv.attributes.recipientId;
    
                            let groupMap = props.groupmaps.filter((gm) =>
                                tKidId == gm.attributes.userId
                            );
    
                            if (groupMap && groupMap.length > 0 &&
                                groupMap[0].attributes.tags && groupMap[0].attributes.tags[0] == selectedBatch) {
                                    if(!participantsList.includes(tKidId)){
                                        participantsList.push(tKidId)
                                    }
                            } else {
                                continue;
                            }
    
                        }
                        listOfInvoices.push(inv)
                       
                        for (const val of props.invoiceItems) {
                            if (inv.id === val.attributes.invoiceId && val.attributes.termId == m.id) {
                                listOfInvoiceItems.push(val)
                                m.totalAmount += val.attributes.totalAmount;

                                if (val.attributes.paidStatus == 1) {
                                    m.collectedAmount += (val.attributes.totalAmount);
                                } else {
                                    m.collectedAmount += (val.attributes.paidAmount ? val.attributes.paidAmount : 0);
                                }
                               
                            }
                        }
                    }
                }
                m.pendingAmount += (m.totalAmount - m.collectedAmount);
                m.invoices = listOfInvoices
                m.invoiceItems = listOfInvoiceItems;
                if (selectedBatch != "All Batches") {
                    m.participantsCount= participantsList.length;
                }
                
                tAmount += m.totalAmount
                tCollectedAmount += m.collectedAmount
                tPendingAmount += m.pendingAmount
            }



            let totalobject = {
                id: "totalobject",
                termName: "TOTAL",
                totalAmount: tAmount,
                collectedAmount: tCollectedAmount,
                pendingAmount: tPendingAmount
            }
            tempList[listForReport1.length - 1] = totalobject

            if(selectedBatch=="All Batches"){
                setListForReport1(tempList)
                setListForReport1Batch(tempList)
            }else{
                setListForReport1Batch(tempList)
            }
            
        }
    }, [props.invoiceItems, props.invoicesForProgram,selectedBatch]);

    const getDateInDDMMYYYY = (date) => {
        return moment(date).format("DD/MM/YYYY");
    };
    function convertDateToString(date) {
        return moment(date).format('DD MMM YYYY')
    }

    const getReport1Titles = () => {
        let titles = []
        titles.push("Si no.");
        titles.push("Term Name");
        titles.push("Number of Students");
        titles.push("Total Amount");
        titles.push("Collected Amount");
        titles.push("Pending Amount");
        return titles
    }

    const getReport2Titles = () => {
        let titles = []
        titles.push("Si no.");
        titles.push("Student Name");
        titles.push("Total Amount");
        titles.push("Collected Amount");
        titles.push("Pending Amount");
        return titles
    }

    const getReport3Titles = () => {
        let titles = []
        titles.push("Si no.");
        titles.push("Student Name");
        if (listForReport23 && listForReport23.length > 0 && listForReport23[0].terms)
            for (const term of listForReport23[0].terms) {
                titles.push(term.termName)
            }
        titles.push("Total Amount");
        titles.push("Collected Amount");
        titles.push("Pending Amount");
        return titles
    }

    const getProgramStatusString = () => {
        let statusString = ""

        if (programObject) {
            let todayDate = new Date()
            if (programObject.attributes.startDate < todayDate && programObject.attributes.endDate > todayDate) {
                statusString = "In Progress"
            } else if (programObject.attributes.startDate > todayDate && programObject.attributes.endDate > todayDate) {
                statusString = "Upcoming"
            } else {
                statusString = "Completed"
            }
        }

        return statusString
    }

    const downloadTheReport = () => {
        let finalData = [];
        let fileName = "";
        let sheetName = "";
    
        fileName = `Program_Report_${getDateInDDMMYYYY(new Date())}.xlsx`;
        sheetName = "Program_Report";
    
        finalData.push(["Report Type:", "Program Report"]);
        finalData.push(["Program Name:", programObject.attributes.name]);
        finalData.push(["Program Start Date:", getDateInDDMMYYYY(programObject.attributes.startDate)]);
        finalData.push(["Program End Date:", getDateInDDMMYYYY(programObject.attributes.endDate)]);
        finalData.push(["Program Status:", getProgramStatusString()]);
        finalData.push(["School:", props.selectedSchool.attributes.Name]);
        finalData.push(["Date:", getDateInDDMMYYYY(new Date())]);
        finalData.push(["TA:", "Total Amount"]);
        finalData.push(["CA:", "Collecting Amount"]);
        finalData.push(["PA:", "Pending Amount"]);
    
        if (hasTerms) {
            finalData.push(["Report 1:", "Total Fee consolidation term-wise"]);
            finalData.push(["Selected Batch:", selectedBatch]);
            finalData.push(getReport1Titles());
            let f = 0;
            for (const item of listForReport1Batch) {
                let temp = [];
                if (item.id !== "totalobject") {
                    temp.push(++f);
                    temp.push(item.termName);
                    temp.push(item.participantsCount);
                    temp.push(item.totalAmount);
                    temp.push(item.collectedAmount);
                    temp.push(item.pendingAmount);
                    finalData.push(temp);
                }
            }
        }
    
        finalData.push([`Report ${hasTerms ? "2" : "1"}:`, "Total Fee consolidation student-wise"]);
        finalData.push(["Selected Batch:", selectedBatch]);
        finalData.push(getReport2Titles());
        let f2 = 0;
        for (const item of listForReport23Batch) {
            let temp = [];
            temp.push(++f2);
            temp.push(item.kidParseObject.attributes.Name);
            temp.push(item.totalAmount);
            temp.push(item.collectedAmount);
            temp.push(item.pendingAmount);
            finalData.push(temp);
        }
    
        if (hasTerms) {
            finalData.push(["Report 3:", "Total Fee consolidation student & term wise"]);
            finalData.push(["Selected Batch:", selectedBatch]);
            finalData.push(getReport3Titles());
            let f3 = 0;
            for (const item of listForReport23Batch) {
                let temp = [];
                temp.push(++f3);
                temp.push(item.kidParseObject.attributes.Name);
                for (const term of item.terms) {
                    temp.push(`TA - ${term.totalAmount} CA - ${term.collectedAmount} PA - ${term.pendingAmount}`);
                }
                temp.push(item.totalAmount);
                temp.push(item.collectedAmount);
                temp.push(item.pendingAmount);
                finalData.push(temp);
            }
        }
    
        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(finalData);
        XLSX.utils.book_append_sheet(wb, ws, sheetName);
        XLSX.writeFile(wb, fileName);
    }

    return (
        <React.Fragment>
            <div className='page-content'>

                <Container fluid>
                    {/* <Row>

                        <Col lg={8} md={8} xs={12}>
                            <Row >
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { window.history.back() }}>
                                    <ul className="ml-2 list-unstyled">
                                        <div >
                                            <i className="bx bx-chevron-left h1 text-primary"></i>
                                        </div>
                                    </ul>
                                </div>
                                <h4 className="mt-2">{`${(programObject && programObject.attributes.name) ?? "Program"} Analytics`}</h4>
                            </Row>
                        </Col>
                        <Col lg={4} md={4} xs={12}>
                            {programObject && <div className="mb-3  float-sm-right form-inline">
                                <button
                                    className="btn btn-primary mr-2"
                                    onClick={() => { downloadTheReport() }}
                                >
                                    <i className="bx bxs-download mr-1" />
                                    Download
                                </button>

                            </div>}
                        </Col>
                    </Row> */}
                    <Row className='d-flex align-items-start'>
                        <Col className="d-flex align-items-start">
                            <div
                                style={{ cursor: "pointer" }}
                                onClick={() => { window.history.back() }}>
                                <ul className=" list-unstyled">
                                    <div className=''>
                                        <i className="bx bx-chevron-left h1 text-primary"></i>
                                    </div>
                                </ul>
                            </div>
                            <div className=' m-2'>
                                <h4 className="mb-0">{`${(programObject && programObject.attributes.name) ?? "Program"} Analytics`}</h4>
                            </div>
                        </Col>
                        <Col className='d-flex justify-content-end'>
                            <div className="mt-0 d-flex justify-content-end">
                                <div style={{ marginRight: '0.3cm' }}>
                                    <select
                                        className="form-select"
                                        value={selectedBatch}
                                        onChange={(e) => { setSelectedBatch(e.target.value) }}
                                    >
                                        <option value="All Batches">All Batchs</option>
                                        {programObject && programObject.attributes && programObject.attributes.tags
                                            .map((batch) => (
                                                <option key={batch} value={batch}>
                                                    {batch}
                                                </option>
                                            ))}
                                    </select>
                                </div>
                                {programObject && <div className="mb-3  float-sm-right form-inline">
                                    <button
                                        className="btn btn-primary "
                                        onClick={() => { downloadTheReport() }}
                                    >
                                        <i className="bx bxs-download mr-1" />
                                        Download
                                    </button>
                                </div>}
                            </div>
                        </Col>

                    </Row>
                    <Card style={{ minHeight: `calc(100vh - 150px)`, padding: "20px" }}>
                    <CardHeader><CardTitle> {`Selected Batch: ${selectedBatch}`} </CardTitle> </CardHeader>

                        <CardBody>
                            {showReport && hasTerms && listForReport23.length > 0 && (
                                <><h5 className='mb-3'>Report 1 (Total Fee consolidation term-wise)</h5>

                                    <Row>
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Si no.</Th>
                                                            <Th data-priority="1">Term Name</Th>
                                                            <Th data-priority="1">Number of Students</Th>
                                                            <Th data-priority="1">Total Amount</Th>
                                                            <Th data-priority="2">Collected Amount</Th>
                                                            <Th data-priority="2">Pending Amount</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            listForReport1Batch.map((item, key) => (
                                                                <Tr key={item.id}>
                                                                    <Th>{item.id != "totalobject" ? (key + 1) : ""}</Th>
                                                                    <Td>
                                                                        <div><Label>{`${item.termName}`}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.participantsCount ?? ""}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.totalAmount}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.collectedAmount}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.pendingAmount}</Label></div>
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        }

                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Row></>
                            )}
                            {showReport && listForReport23.length > 0 && (
                                <><h5 className={hasTerms ? "mb-3 mt-5" : "mb-3"}>{`Report ${hasTerms ? "2" : ""} (Total Fee consolidation student-wise)`}</h5>
                                    <Row>
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Si no.</Th>
                                                            <Th data-priority="1">Student Name</Th>
                                                            <Th data-priority="1">Total Amount</Th>
                                                            <Th data-priority="2">Collected Amount</Th>
                                                            <Th data-priority="2">Pending Amount</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            listForReport23Batch.map((item, key) => (
                                                                <Tr key={key}>
                                                                    <Th>{key + 1}</Th>
                                                                    <Td>
                                                                        <div><Label>{`${item.kidParseObject.attributes.Name}${item.kidParseObject.attributes.Deleted ? "(Deleted)" : ""}`}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.totalAmount}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.collectedAmount}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.pendingAmount}</Label></div>
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        }

                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Row></>
                            )}
                            {showReport && hasTerms && listForReport23.length > 0 && (
                                <><h5 className='mb-3 mt-5'>Report 3 (Total Fee consolidation student & term wise)</h5>

                                    <Row>
                                        <div className="table-rep-plugin">
                                            <div className="table-responsive mb-0" data-pattern="priority-columns">
                                                <Table id="tech-companies-1" className="table table-striped table-bordered">
                                                    <Thead>
                                                        <Tr>
                                                            <Th>Si no.</Th>
                                                            <Th data-priority="1">Student Name</Th>
                                                            {listForReport23[0].terms && listForReport23[0].terms.map((term, key) => (
                                                                <Th key={key} data-priority="2">{(term.termParseObject && term.termParseObject.attributes.termName) ?? "-"}</Th>
                                                            ))}
                                                            <Th data-priority="3">Total Amount</Th>
                                                            <Th data-priority="3">Collected Amount</Th>
                                                            <Th data-priority="3">Pending Amount</Th>
                                                        </Tr>
                                                    </Thead>
                                                    <Tbody>
                                                        {
                                                            listForReport23Batch.map((item, key) => (
                                                                <Tr key={key}>
                                                                    <Th>{key + 1}</Th>
                                                                    <Td>
                                                                        <div><Label>{`${item.kidParseObject.attributes.Name}${item.kidParseObject.attributes.Deleted ? "(Deleted)" : ""}`}</Label></div>
                                                                    </Td>
                                                                    {item.terms && item.terms.map((term, key) => (
                                                                        <Td key={key}>
                                                                            <div>
                                                                                <Label>{`Total Amount : ${term.totalAmount}`}</Label>
                                                                            </div>
                                                                            <div>
                                                                                <Label>{`Collected Amount : ${term.collectedAmount}`}</Label>
                                                                            </div>
                                                                            <div>
                                                                                <Label>{`Pending Amount : ${term.pendingAmount}`}</Label>
                                                                            </div>
                                                                        </Td>
                                                                    ))}
                                                                    <Td>
                                                                        <div><Label>{item.totalAmount}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.collectedAmount}</Label></div>
                                                                    </Td>
                                                                    <Td>
                                                                        <div><Label>{item.pendingAmount}</Label></div>
                                                                    </Td>
                                                                </Tr>
                                                            ))
                                                        }

                                                    </Tbody>
                                                </Table>
                                            </div>
                                        </div>
                                    </Row></>
                            )}



                            {/* {listForReport23.length === 0 && (
                                <Col>
                                    <h3 className="text-center text-info mt-3">
                                        {props.t("No Report Found For The Filter")}
                                    </h3>
                                </Col>
                            )} */}
                        </CardBody>

                    </Card>
                </Container>
            </div>
        </React.Fragment>
    )



}
const mapStatetoProps = state => {

    const { schools, selectedSchool } = state.School;
    const { kids, admissionKids, admissionKidsLoasState, deletedKids } = state.Kid;
    const { groupmaps, loadingGroupMap, resultGroupMapsAfterSave } = state.GroupMap;
    const { invoiceItems } = state.InvoiceItems;
    const { invoicesForProgram } = state.Invoice;
    const { programTerms } = state.Program;

    return {
        schools, selectedSchool,
        kids, admissionKids, admissionKidsLoasState, deletedKids,
        groupmaps, loadingGroupMap, resultGroupMapsAfterSave,
        invoiceItems,
        invoicesForProgram,
        programTerms
    };
}
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            setSelecetdSchool,
            getAllKidsForGroup,
            getGroupMapsForGroupId,
            getInvoicesForProgramId,
            getItemsForInvoiceIds,
            getProgramTermsForProgramId,
            getAllDeletedKidsForSchoolIdOnlyForArchives
        })(ProgramAnalytics)
    )
);