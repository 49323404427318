import * as actions from "./actionTypes";
import * as ReduxUtil from "../../ReduxUtil";

const initialState = {
    learningSession: [],
    onlineLearningSession: [],
    selectedLearingSession: undefined,
    selectedOnlineLearningSession: undefined,
    learningTracking: [],
    onlineLearningTracking: [],
};

const LearningSession = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_LEARNING_SESSION:
            if(action.payload.isReplace){
                state = { ...state, learningSession: action.payload.learningSession };
            }else{
                var updatedList = ReduxUtil.updateParseObjects(state.learningSession?[...state.learningSession]:[], action.payload.learningSession, "isDeleted")
                state = { ...state, learningSession: updatedList };
            }
            
            break;
        case actions.UPDATE_ONLINE_LEARNING_SESSION:
            state = { ...state, onlineLearningSession: action.payload.onlineLearningSession };
            break;
            case actions.CLEAR_LEARNING_SESSION:
                state = { ...state, onlineLearningSession: [] };
                break;
        case actions.UPDATE_SELECTED_LEARNING_SESSION:
            state = { ...state, selectedLearingSession: action.payload.session };
            break;
        case actions.UPDATE_SELECTED_ONLINE_LEARNING_SESSION:
            state = { ...state, selectedOnlineLearningSession: action.payload.onlineSession };
            break;
        case actions.UPDATE_LEARNING_TRACKING_FOR_SESSION_ID:
            state = { ...state, learningTracking: action.payload.sessionId };
            break;
        case actions.UPDATE_ONLINE_LEARNING_TRACK_FOR_SESSION_ID:
            state = { ...state, onlineLearningTracking: action.payload.sessionId };
            break;

        default:
            state = { ...state };
            break;
    }
    return state;
};

export default LearningSession;