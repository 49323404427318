import Parse from "parse";

export const getExamResultsForExamId = (examId) => {
    try {
        return new Promise((resolve, reject) => {
            var ExamResults = Parse.Object.extend("ExamResults");
            var query = new Parse.Query(ExamResults);
            query.equalTo("examId", examId);
            query.limit(10000)
            query.find().then(
                (result) => {
                    resolve(result);
                    // console.log(result)
                },
                (error) => {
                    reject(null);
                    console.log(error)
                }
            );
        });
    } catch (error) { }
};

export const getExamResultsForExamIdAndKidId = (examId, kidId) => {
    try {
        return new Promise((resolve, reject) => {
            var ExamResults = Parse.Object.extend("ExamResults");
            var query = new Parse.Query(ExamResults);
            query.equalTo("examId", examId);
            query.equalTo("kidId", kidId);
            query.limit(10000)
            query.find().then(
                (result) => {
                    resolve(result);
                    // console.log(result)
                },
                (error) => {
                    reject(null);
                    console.log(error)
                }
            );
        });
    } catch (error) { }
}