import * as actions from "./actionTypes";

export const setExamLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue },
  };
};

export const getExamsForSchoolIds = (schoolIds) => {
  return {
    type: actions.GET_EXAMS_FOR_SCHOOL_IDS,
    payload: { schoolIds },
  };
};

export const saveExam = (exam) => {
  return {
    type: actions.SAVE_EXAM,
    payload: { exam },
  };
};
export const updateExamAfterSave = (examAfterSave) => {
  return {
    type: actions.UPDATE_EXAM_AFTERSAVE,
    payload: { examAfterSave },
  };
};
export const clearExamAfterSave = () => {
  return {
    type: actions.CLEAR_EXAM_AFTERSAVE,
    payload: {},
  };
};

export const updateExamsForSchoolIds = (exams) => {
  return {
    type: actions.UPDATE_EXAMS_FOR_SCHOOL_IDS,
    payload: { exams },
  };
};

export const updateExam = (exam) => {
  return {
    type: actions.UPDATE_EXAM,
    payload: { exam },
  };
};
