import React, { useEffect, useState } from 'react';
import {
    Container, Card, CardBody, Row, Col, Table,
    UncontrolledTooltip, Label, Button, Modal, FormGroup, ModalHeader, ModalBody, ModalFooter, Input
} from "reactstrap";
import Parse from 'parse';
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import Moment from 'moment';
import {
    setSelecetdSchool,
    setSelectedCourse,
    getAllKids,
    
} from "../../../store/actions";
import * as CommonFunctions from "../../../components/Common/CommonFunctions";
import TopBar from '../TopBar';



const CourseFormSession = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/course-form-session/:schoolId/:courseId/:formId',
        exact: true,
        strict: false,
    });

    const schoolId = match ? match.params.schoolId : "";
    const courseId = match ? match.params.courseId : "";
    const formId = match ? match.params.formId : "";

    
    const [allForms, setAllForms] = useState([]);

    const [loading, setLoading] = useState(true);
    const [formsSessions, setFormsSessions] = useState([]);
    const [formObject, setFormObject] = useState(undefined);

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        props.setSelectedCourse(courseId);
        props.getAllKids(schoolId);
        featchCourseFormSessions();
    }, [courseId]);

    const featchCourseFormSessions = () => {
        var query = new Parse.Query("FormSessions");
        query.equalTo("courseId", courseId);
        query.notEqualTo("isDeleted", true);
        query.limit(10000);
        query.descending("createdAt");
        query.find().then((results) => {
            getForm();
            if (results && results.length > 0) {
                setFormsSessions(results);
            } else {
                setLoading(false);
            }
            
        });
    };

    const getForm = () => {
       
        let Forms = Parse.Object.extend("Forms");
        let query = new Parse.Query(Forms);
        query.get(formId).then((r) => {
            setFormObject(r);
        });
        
    }


     

    const createList = (sessions, form) => {
        let list = [];
        
        setAllForms(list);
    }
   
    useEffect(() => {

        if (formsSessions && formsSessions.length > 0 && props.kids && props.kids.length > 0) {
            setLoading(false);
            let list = [];
            for (const fs of formsSessions){
                var kids = props.kids.filter(
                (k) =>
                  k.id === fs.attributes.kidId
                );
                if (kids && kids.length > 0) {
                    list.push({id:fs.id, formsSession: fs, kid: kids[0] })
                }
            }
            setAllForms(list);
        }
        
    }, [formsSessions,props.kids]);
    

    
    const handelOnClickBack = () => {
        window.history.back();
    }

    const getTimeStringForDate = (date) => {
        return Moment(date).format('DD-MMM-YYYY');
    }
 
    function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }

    return (
        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <TopBar
                        title={"Feedback Forms"}
                        backButton={true}
                        hideButton={true}
                        onClickBackButton={handelOnClickBack}
                    />
                    <Card>
                        <CardBody>
                            <h5 className="text-truncate font-size-14  mb-1 text-dark">{formObject ?  "Form Name: "+formObject.attributes.name : "Form Name: "}</h5>
                            <h5 className="text-truncate font-size-14  mb-1 text-dark">{props.selectedCourse ?  "Course Name: "+ props.selectedCourse.attributes.name : "Course Name: "}</h5>
                        </CardBody>
                    </Card>
                    <Row >
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">
                                    {allForms.length > 0 && <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="col-1" scope="col" align="center">#</th>
                                                <th className="col-9" scope="col">Name</th>
                                                <th className="col-2" scope="col" align="right" style={{ textAlign: "right" }}>Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allForms.map((item, key) =>
                                                <tr key={item.id} >
                                                    <td>
                                                        <h5>{(key + 1)}</h5>
                                                    </td>

                                                    <td
                                                        style={{ cursor: "pointer" }}
                                                        onClick={() => {

                                                        }}>
                                                        <h5 className="text-truncate font-size-14  mb-1 text-dark">{item.kid.attributes.Name}</h5>
                                                       
                                                        <p className="text-success">{item.formsSession && " Filled on:" + getTimeStringForDate(item.formsSession.attributes.createdAt)}</p>
                                                    </td>

                                                    <td style={{ textAlign: "right" }}>
                                                        <button
                                                            className='btn btn-sm btn-primary'
                                                            onClick={() => {
                                                                 props.history.push("/printformview/"+schoolId+"/"+formId+"/"+strReplace(formObject ?formObject.attributes.name:"name")+"/"+item.formsSession.id);
                                                            }}
                                                        >
                                                            View
                                                            
                                                        </button>
                                                       
                                                    </td>
                                                </tr>
                                            )}
                                        </tbody>
                                    </Table>}

                                    {
                                        !loading && allForms.length === 0 &&
                                        <Card>
                                            <CardBody className="text-center" >

                                                <h3 className="mt-5">{

                                                    props.t('There are no feedback forms')

                                                }</h3>
                                              
                                            </CardBody>
                                        </Card>
                                    }

                                    {
                                        loading && <Col xs="12">
                                            <Card>
                                                <CardBody className="text-center" >
                                                    <div className="text-center">
                                                        <Label className="text-success">
                                                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                                                        </Label>
                                                    </div>
                                                </CardBody>
                                            </Card>
                                        </Col>
                                    }
                                </div>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

           

        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    const { courses, selectedCourse } = state.Course;
    const { kids } = state.Kid;

    return {  selectedSchool, courses, selectedCourse,kids};
};


export default connect(mapStatetoProps, {
    setSelecetdSchool,
    setSelectedCourse,
    getAllKids,
})(withNamespaces()(CourseFormSession));
