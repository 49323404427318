import { takeEvery,takeLeading, fork, put, all, call, } from "redux-saga/effects";
import Parse from "parse";

import * as LearningActivityHelper from "../../../helpers/ParseHelpers/learningActivity_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as LearningActivityActions from "./actions";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getALearningActivity({ payload: { id } }) {
  try {
    const response = yield call(LearningActivityHelper.getLearningActivityFromParse, id, false);
    yield put(LearningActivityActions.updateLearningActivity(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteALearningActivity({ payload: { learningActivity } }) {
  try {
    const response = yield call(
      LearningActivityHelper.deleteLearningActivityFromParse,
      learningActivity
    );
    if (response) {
      yield put(LearningActivityActions.updateLearningActivity(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getLearningActivityForcurriculumId({ payload: { curriculumId } }) {
  try {
    var key = Constants.LEARNING_ACTIVITY_UPDATED_TIME_FOR_CURRICULUM_ID + "_" + curriculumId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(LearningActivityHelper.getLearningActivityForcurriculumId, curriculumId, null, true);
    yield put(LearningActivityActions.updateLearningActivities(localResponse));

    const serverResponse = yield call(LearningActivityHelper.getLearningActivityForcurriculumId, curriculumId, updatedTime, false);
    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(LearningActivityActions.updateLearningActivities(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}



function* getLearningActivityForCourseId({ payload: { courseId } }) {
  try {
    yield put(LearningActivityActions.replaceLearningActivities([]));
    var key = Constants.LEARNING_ACTIVITY_UPDATED_TIME_FOR_COURSE_ID + "_" + courseId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(LearningActivityHelper.getLearningActivityForCourseId, courseId, null, true);
    yield put(LearningActivityActions.updateLearningActivities(localResponse));

    const serverResponse = yield call(LearningActivityHelper.getLearningActivityForCourseId, courseId, updatedTime, false);
    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(LearningActivityActions.updateLearningActivities(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getQuizLearningActivitiesForCourseId({ payload: { courseId } }) {
  try {
    
    const localResponse = yield call(LearningActivityHelper.getQuizLearningActivitiesForCourseId, courseId, null, true);
    yield put(LearningActivityActions.updateQuizLearningActivitiesForCourseId(localResponse));

    const serverResponse = yield call(LearningActivityHelper.getQuizLearningActivitiesForCourseId, courseId, null, false);

    if (serverResponse && serverResponse.length > 0) {
      yield put(LearningActivityActions.updateQuizLearningActivitiesForCourseId(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getLiveMeetingLearningActivitiesForCourseId({ payload: { courseId } }) {
  try {
    
    const localResponse = yield call(LearningActivityHelper.getLiveMeetingLearningActivitiesForCourseId, courseId, null, true);
    yield put(LearningActivityActions.updateLiveMeetingLearningActivitiesForCourseId(localResponse));

    const serverResponse = yield call(LearningActivityHelper.getLiveMeetingLearningActivitiesForCourseId, courseId, null, false);

    if (serverResponse && serverResponse.length > 0) {
      yield put(LearningActivityActions.updateLiveMeetingLearningActivitiesForCourseId(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}


function* addLearningActivity({ payload: { learningActivity } }) {
  try {
    yield put(LearningActivityActions.setLearningActivityLoadingState(true));
    const response = yield call(LearningActivityHelper.saveLearningActivity, learningActivity);
    yield put(LearningActivityActions.setLearningActivityLoadingState(false));
    if (response) {
      yield put(LearningActivityActions.updateLearningActivity(response));
      response.pin();
    } else {
      
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* getLearningActivityForSubcategoeyIds({ payload: { subcategoryIds, categoryId } }) {
  try {
    yield put(LearningActivityActions.replaceLearningActivities([]));
    // const localResponse = yield call(LearningActivityHelper.getLearningActivityForSubcategoryIds, subcategoryIds,categoryId, true);
    // yield put(LearningActivityActions.replaceLearningActivities(localResponse));

    const serverResponse = yield call(LearningActivityHelper.getLearningActivityForSubcategoryIds, subcategoryIds, categoryId, false);
    
    // if (localResponse && localResponse.length > 0) {
    //   Parse.Object.unPinAll(localResponse);
    // }
    if (serverResponse && serverResponse.length > 0) {
      yield put(LearningActivityActions.replaceLearningActivities(serverResponse));
      Parse.Object.pinAll(serverResponse);

    } else {
      yield put(LearningActivityActions.replaceLearningActivities([]));
    }
    
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* getSelcetedLearningActivity({ payload: { id } }) {
  try {
    // const response = yield call( LearningActivityHelper.getLearningActivityFromParse, id,false);
    // yield put(LearningActivityActions.updateSelecetdLearningActivity(response));
    const sresponse = yield call(LearningActivityHelper.getLearningActivityFromParse, id, true);
    yield put(LearningActivityActions.updateSelecetdLearningActivity(sresponse));
    if (sresponse) {
      sresponse.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* reorderLrarningActivity({ payload: { learningActivities, categoryId,subcategoryIds,message,courseId } }) {
  try {
    const response = yield call(LearningActivityHelper.saveLearningActivityObjects, learningActivities);
    if (response) {
      if (message) {
        toastr.success(message, { timeOut: 3000 });
      }
      Parse.Object.pinAll(response);

      if (courseId && courseId.length > 0) {
        const localResponse = yield call(LearningActivityHelper.getLearningActivityForCourseId, courseId, null, true);
        yield put(LearningActivityActions.replaceLearningActivities(localResponse));
      } else {
        const localResponse = yield call(LearningActivityHelper.getLearningActivityForSubcategoryIds, subcategoryIds, categoryId, false);
      yield put(LearningActivityActions.replaceLearningActivities(localResponse));
      }
    } else {
    
    }
  } catch (error) {
    console.log(error);
  }
}

function* getLearningActivitiesForIds({ payload: { ids } }) {
  try {
    const localResponse = yield call(LearningActivityHelper.getLearningActivitiesForIds, ids);
    yield put(LearningActivityActions.updateLearningActivities(localResponse));

    const serverResponse = yield call(LearningActivityHelper.getLearningActivitiesForIds, ids);

    if (serverResponse && serverResponse.length > 0) {
        yield put(LearningActivityActions.updateLearningActivities(serverResponse));
        Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

export function* watchGetLearningActivity() {
  yield takeEvery(actionTypes.GET_LEARNING_ACTIVITY, getALearningActivity);
}

export function* watchDeleteLearningActivity() {
  yield takeEvery(actionTypes.DELETE_LEARNING_ACTIVITY, deleteALearningActivity);
}

export function* watchGetLearningActivityForcurriculumId() {
  yield takeEvery(actionTypes.GET_LEARNING_ACTIVITY_FOR_CURRICULUM_ID, getLearningActivityForcurriculumId);
}
export function* watchAddLearningActivity() {
  yield takeEvery(actionTypes.ADD_LEARNING_ACTIVITY, addLearningActivity);
}

export function* watchGetLearningActivityForSubcategoryIds() {
  yield takeEvery(actionTypes.GET_ACTIVITIES_FOR_SUBCATEGORY_IDS, getLearningActivityForSubcategoeyIds);
}

export function* watchSetSelectedLearningActivity() {
  yield takeEvery(actionTypes.SET_SELECTED_LEARNING_ACTIVITY, getSelcetedLearningActivity);
}

export function* watchReorderLrarningActivity() {
  yield takeLeading(actionTypes.LEARNING_ACTIVITY_REORDER, reorderLrarningActivity);
}
export function* watchGetLearningActivityForCourseId() {
  yield takeEvery(actionTypes.GET_ACTIVITIES_FOR_COURSE_ID, getLearningActivityForCourseId);
}
export function* watchGetQuizLearningActivityForCourseId() {
  yield takeEvery(actionTypes.GET_QUIZ_ACTIVITIES_FOR_COURSE_ID, getQuizLearningActivitiesForCourseId);
}
export function* watchGetLiveMeetingLearningActivityForCourseId() {
  yield takeEvery(actionTypes.GET_LIVE_MEETING_ACTIVITIES_FOR_COURSE_ID, getLiveMeetingLearningActivitiesForCourseId);
}

export function* watchGetLearningActivitiesForIds(){
  yield takeEvery(actionTypes.GET_LEARNING_ACTIVITIES_FOR_IDS, getLearningActivitiesForIds);
}

function* LearningActivitySaga() {
  yield all([
    fork(watchGetLearningActivity),
    fork(watchDeleteLearningActivity),
    fork(watchGetLearningActivityForcurriculumId),
    fork(watchAddLearningActivity),
    fork(watchGetLearningActivityForSubcategoryIds),
    fork(watchSetSelectedLearningActivity),
    fork(watchReorderLrarningActivity),
    fork(watchGetLearningActivityForCourseId),
    fork(watchGetQuizLearningActivityForCourseId),
    fork(watchGetLiveMeetingLearningActivityForCourseId),
    fork(watchGetLearningActivitiesForIds),
  ]);
}
export default LearningActivitySaga;
