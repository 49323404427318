import * as actions from "./actionTypes";

const initialState = {
  schools: [],
  selectedSchool: null,
  paymentOptionsForSchool: [],
  ratingParams: [],
  schoolRatings: [],
  loadSuccess: false,
  receiptAddres: [],
  featchStatus:false,
  schoolAnalytics: [],
  enquiryAnalytics: [],
  schoolAnalyticsForSchoolObject: [],
  toolsInstitutes: [],
  toolsSchools: [],
};

const school = (state = initialState, action) => {
  switch (action.type) {
   
    case actions.UPDATE_SCHOOL:
      var schools = [...state.schools];
      var temp = state.schools.filter(
        (school) => school.id === action.payload.school.id
      );
      if (temp.length > 0) {
        var index = schools.indexOf(temp[0]);
        schools[index] = action.payload.school;
        if (
          state.selectedSchool &&
          state.selectedSchool.id === action.payload.school.id
        ) {
          state = {
            ...state,
            schools: schools,
            selectedSchool: action.payload.school,
          };
        } else {
          state = { ...state, schools: schools };
        }
      } else {
        schools.push(action.payload.school);
        if (
          state.selectedSchool &&
          state.selectedSchool.id === action.payload.school.id
        ) {
          state = {
            ...state,
            schools: schools,
            selectedSchool: action.payload.school,
          };
        } else {
          state = { ...state, schools: schools };
        }
      }
      break;
    case actions.UPDATE_SCHOOLS:
      state = { ...state, schools: action.payload.schools };
      break;

    case actions.UPDATE_PAYMENT_OPTIONS:
      state = {
        ...state,
        paymentOptionsForSchool: action.payload.paymentOptionsForSchool,
      };
      break;
    case actions.DELETE_SCHOOL:
      var schools = state.schools.filter(
        (school) => school.id !== action.payload.id
      );
      state = { ...state, schools: schools };
      break;
    case actions.UPDATE_SELECTED_SCHOOL:
      state = { ...state, selectedSchool: action.payload.school };
      break;
    
    case actions.SCHOOLS_FEATCH_STATUS:
      state = { ...state, featchStatus: action.payload.state };
      break;

    ////School Ratings
    case actions.UPDATE_RATING_PARAMS:
      state = { ...state, ratingParams: action.payload.ratingParams };
      break;

    case actions.UPDATE_SCHOOL_RATINGS:
      var schoolRatings = [...state.schoolRatings];
      if (schoolRatings.length > 0) {
        for (const latest in action.payload.schoolRatings) {
          var temp = schoolRatings.filter(
            (schoolRating) =>
              schoolRating.id === action.payload.schoolRatings[latest].id
          );
          if (temp.length > 0) {
            var index = schoolRatings.lastIndexOf(temp[0]);
            schoolRatings[index] = action.payload.schoolRatings[latest];
          } else {
            schoolRatings.push(action.payload.schoolRatings[latest]);
          }
        }
        state = { ...state, schoolRatings: schoolRatings };
      } else {
        state = { ...state, schoolRatings: action.payload.schoolRatings };
      }
      break;

      case actions.CLEAR_SCHOOL_RATINGS:
        state = { ...state, schoolRatings: [] };
        break;
  
    case actions.SET_ALL_SCHOOLS_FOR_INSTITUTE:
      state = { ...state, schools: action.payload.schoolObjs };
      break;
    case actions.SET_FRANCHISE_DETAILS:
      state = { ...state, franchise: action.payload.obj, loadSuccess: true };
      break;
    case actions.SET_ALL_SCHOOL_DETAILS_FOR_FRANCHISE:
      state = { ...state, schools: action.payload.schoolObjs };
      break;
    case actions.SET_INSTITUTE_DETAILS_FOR_ID:
      state = { ...state, institute: action.payload.instituteObj };
      break;
    case actions.UPDATE_RECEIPT_ADDRESS_FOR_SCHOOL:
      state = { ...state, receiptAddres: action.payload.receiptAddres };
      break;
    case actions.GET_SCHOOL_ANALYTICS:
      state = { ...state, schoolAnalytics: [] };
      break;
    case actions.UPDATE_SCHOOL_ANALYTICS:
      var schoolAnalytics = [...state.schoolAnalytics];
      if (schoolAnalytics.length > 0) {
        for (const latest in action.payload.schoolAnalytics) {
          var temp = schoolAnalytics.filter(
            (schoolAnalytics) =>
            schoolAnalytics.id === action.payload.schoolAnalytics[latest].id
          );
          if (temp.length > 0) {
            var index = schoolAnalytics.lastIndexOf(temp[0]);
            schoolAnalytics[index] = action.payload.schoolAnalytics[latest];
          } else {
            schoolAnalytics.push(action.payload.schoolAnalytics[latest]);
          }
        }
        state = { ...state, schoolAnalytics: schoolAnalytics };
      } else {
        state = { ...state, schoolAnalytics: action.payload.schoolAnalytics };
      }
      break;

 // *****************************ANALYTICS AND TOOLS**************************************//

      case actions.UPDATE_SCHOOL_ANALYTICS_FOR_SCHOOL_OBJECT:
        state = { ...state, schoolAnalyticsForSchoolObject: action.payload.schoolAnalytic };
        break;
      case actions.UPDATE_INSTITUTES_FOR_TOOLS:
        state = { ...state, toolsInstitutes: action.payload.institutes };
        break;
      case actions.UPDATE_SCHOOLS_FOR_TOOLS:
        state = { ...state, toolsSchools: action.payload.schools };
        break;

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default school;
