import React, { useState, useEffect } from 'react'
import Parse from "parse";
import { Link } from "react-router-dom";
import { Button, Input, Label, FormGroup, Container, Card, CardBody, Row, Col, CardText, CardTitle, Alert, Table } from 'reactstrap'

const Result = () => {

    const [registerNo, setRegisterNo] = useState('')
    const [registerNoError, setRegisterNoError] = useState(false)
    const [getKidData, setGetKidData] = useState()

    const [showExamTable, setShowExamTable] = useState(false)

    const [selectedResult, setSelectedResult] = useState([]);
    const [selectedExamName, setSelectedExamName] = useState(null);

    const handleSubmitRegNo = () => {
        Parse.Cloud.run("function_verifyResgiterUser", {
            regNo: registerNo
        }).then((response) => {
            if (response.result) {
                setGetKidData(response.result);
                setShowExamTable(true)
            } else {
                setRegisterNoError(true)
            }
            console.log('response', response.result);
        }, (error) => {
            console.log("error" + error.message);
        });
    };

    const handleViewResult = (examId) => {

        Parse.Cloud.run("function_getExamResults", {
            kidId: getKidData.kid.id,
            examId: examId
        }).then((response) => {
            console.log('response', response);
            setSelectedResult(response.marksCard);
        }, (error) => {
            console.log("error" + error.message);
        });
    };

    const getClassForResult = (tMarks, tMaxMarks) => {

        let perc = 0;
        perc = tMarks / tMaxMarks * 100;
        if (perc < 40) {
            return '-'
        } else if (perc >= 40 && perc <= 49.99) {
            return 'Pass Marks'
        } else if (perc >= 50 && perc <= 59.99) {
            return 'Second Class'
        } else if (perc >= 60 && perc <= 79.99) {
            return 'First Class'
        } else if (perc >= 80) {
            return 'Distinction'
        }

    }
    return (
        <React.Fragment>
            <div className=''>
                <div className="col-md-12 col-sm-12 col-xs-12 p-3">
                    <center>
                        <h4 style={{ fontWeight: 'bolder', textDecoration: 'underline' }}>
                            Exam Results
                        </h4>
                    </center>
                </div>
                <Row>
                    <Col lg={4}>
                    </Col>
                    <Col lg={4}>
                        {!getKidData && <Card body style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.3)' }}>
                            <FormGroup className="mb-4">
                                <Label htmlFor="example-text-input" className=" col-form-label mx-auto">Register Number:</Label>
                                <div className=" mx-auto">
                                    <Input
                                        className="form-control"
                                        type="text"
                                        id="regNo"
                                        placeholder="Enter Register Number"
                                        value={registerNo}
                                        onChange={(e) => {
                                            setRegisterNo(e.target.value);
                                            setRegisterNoError(false)
                                        }}
                                    />
                                </div>
                            </FormGroup>
                            {registerNoError && <Alert color='danger'>{"Register number Doesn't exists"}</Alert>}
                            <div className="d-flex justify-content-end">
                                <Button
                                    className='btn m-1'
                                    color='danger'
                                    onClick={() => {
                                        setRegisterNo('');
                                        setGetKidData()
                                        setRegisterNoError(false)
                                    }}
                                >
                                    Reset
                                </Button>
                                <Button
                                    className='btn m-1'
                                    color='primary'
                                    onClick={() => {
                                        handleSubmitRegNo();
                                    }}
                                >
                                    Search
                                </Button>
                            </div>
                        </Card>}
                    </Col>
                    <Col lg={4}>
                    </Col>
                </Row>
                <center>
                    <Row>
                        <Col lg={2}>
                        </Col>
                        <Col lg={8}>
                            {getKidData && (
                                <Card body style={{ boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.2)' }}>
                                    {getKidData && getKidData.kid && (<div className='m-1'>
                                        <div className='d-flex justify-content-center p-0 border'>
                                            {getKidData.kid.profileUrl && getKidData.kid.profileUrl ? (
                                                <div className='m-1'>
                                                    <Link to={'#'} >
                                                        <img className="rounded-circle avatar-md" src={getKidData.kid.profileUrl} alt="" />
                                                    </Link>
                                                </div>
                                            ) : (
                                                <div className="avatar-md m-1">
                                                    
                                                </div>
                                            )}
                                            <div className='m-2' style={{ textAlign: 'start' }}>
                                                <h4 className="m-0">
                                                    {getKidData.kid.name && getKidData.kid.name}
                                                </h4>
                                                <p className="m-0">
                                                    {registerNo}
                                                </p>
                                                <p className="m-0">
                                                    {getKidData.school.name}
                                                </p>
                                            </div>

                                        </div>
                                    </div>)}

                                    {showExamTable && getKidData.exams && (
                                        <div>
                                            <Table className="table mb-0 table-bordered m-1">
                                                <tbody>
                                                    {getKidData.exams.map((result, index) => (
                                                        <tr key={index}>
                                                            <th>{index + 1}</th>
                                                            <th scope="row">{result.name}</th>
                                                            <td className='float-end' style={{ cursor: 'pointer' }}>
                                                                <p onClick={() => {
                                                                    handleViewResult(result.id)
                                                                    setSelectedExamName(result.name)
                                                                    setShowExamTable(false)
                                                                }}>
                                                                    <u>View Result</u>
                                                                </p>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </Table>
                                            <div className='d-flex justify-content-center'>
                                                <Button
                                                    color='danger'
                                                    className='btn m-2'
                                                    onClick={() => {
                                                        setGetKidData()
                                                        setSelectedResult([])
                                                        setRegisterNo('')
                                                    }}
                                                >
                                                    Back
                                                </Button>
                                            </div>
                                        </div>
                                    )}


                                    {selectedResult && selectedResult.result && (
                                        <div>
                                            <h4 className="card-title m-2">{`${selectedExamName} Result`}</h4>
                                            <hr />
                                            <Table className="table mb-0 table-bordered">
                                                <tbody>
                                                    <tr>
                                                        <th>Maximum Marks</th>
                                                        <th scope="row">{selectedResult.result.totalMaxMarks}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Obtained Marks</th>
                                                        <th scope="row">{selectedResult.result.totalMarks}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Percentage</th>
                                                        <th scope="row">{((selectedResult.result.totalMarks / selectedResult.result.totalMaxMarks) * 100).toFixed(2)}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Class</th>
                                                        <th scope="row">{getClassForResult(selectedResult.result.totalMarks, selectedResult.result.totalMaxMarks)}</th>
                                                    </tr>
                                                    <tr>
                                                        <th>Result</th>
                                                        <th scope="row">{selectedResult.result.passed ? "Pass" : "Fail"}</th>
                                                    </tr>
                                                </tbody>
                                            </Table>

                                            {selectedResult.result.passed ? (
                                                <Alert color='success'>{`Congratulations! 🎉`}</Alert>
                                            ) : (
                                                <Alert color='danger'>{`Better luck next Time`}</Alert>
                                            )}

                                            <div className='d-flex justify-content-center'>
                                                <Button
                                                    color='primary'
                                                    className='btn m-2'
                                                    onClick={() => {
                                                        setSelectedResult([])
                                                        setShowExamTable(true)
                                                    }}>
                                                    All Exams
                                                </Button>
                                                <Button
                                                    color='danger'
                                                    className='btn m-2'
                                                    onClick={() => {
                                                        setGetKidData()
                                                        setSelectedResult([])
                                                        setRegisterNo('')
                                                    }}>
                                                    Back
                                                </Button>

                                            </div>
                                        </div>
                                    )}

                                </Card>
                            )}

                        </Col>
                        <Col lg={2}>

                        </Col>

                    </Row>
                </center>

                {/* </div> */}

            </div >
        </React.Fragment>
    );
}

export default Result