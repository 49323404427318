export const UPDATE_ENQUIRY_QUESTION = "UPDATE_ENQUIRY_QUESTION";
export const DELETE_ENQUIRY_QUESTION = "DELETE_ENQUIRY_QUESTION";
export const UPDATE_DELETE_ENQUIRY_QUESTION = "UPDATE_DELETE_ENQUIRY_QUESTION";

export const UPDATE_ENQUIRY_QUESTIONS = "UPDATE_ENQUIRY_QUESTIONS";
export const GET_ENQUIRY_QUESTIONS = "GET_ENQUIRY_QUESTIONS";
export const GET_ENQUIRY_QUESTION = "GET_ENQUIRY_QUESTION";

export const SAVE_CUSTOM_OBJECTS = "SAVE_CUSTOM_OBJECTS";


export const ENQUIRY_QUESTION_SUCCESS = "ENQUIRY_QUESTION_SUCCESS";
export const ENQUIRY_QUESTION_ERROR = "ENQUIRY_QUESTION_ERROR";

export const EDIT_ENQUIRY_QUESTION = "EDIT_ENQUIRY_QUESTION";

export const ENQUIRY_QUESTION_LODING = "ENQUIRY_QUESTION_LODING";

export const ADD_ENQUIRY_QUESTION = "ADD_ENQUIRY_QUESTION";