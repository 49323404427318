import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as FranchiseHelper from "../../../helpers/ParseHelpers/franchise_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as FranchiseActions from "./actions";
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getFranchise({ payload: { id, fromServer } }) {
    try {
        if (!fromServer) {
            const response = yield call(FranchiseHelper.getFranchiseFromParse, id, true);
            yield put(FranchiseActions.updateFranchise(response));
        }
        const sResponse = yield call(FranchiseHelper.getFranchiseFromParse, id, false);
        yield put(FranchiseActions.updateFranchise(sResponse));
        if (sResponse) {
            sResponse.pin();
        }
    } catch (error) {
        // yield put(apiError(error));
    }
}

export function* watchGetFranchise() {
    yield takeEvery(actionTypes.GET_FRANCHISE, getFranchise);
}
  
function* FranchiseSaga() {
    yield all([
        fork(watchGetFranchise),
    ]);
}
  
export default FranchiseSaga;