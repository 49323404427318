import * as actions from "./actionTypes";

export const getMessagesForClassId = (id) => {
  return {
    type: actions.GET_MESSAGES_FOR_CLASSID,
    payload: { id }
  }
}
export const getLatestMessagesForClassId = (id, updatedAt) => {
  return {
    type: actions.GET_LATEST_MESSAGES_FOR_CLASSID,
    payload: { id, updatedAt }
  }
}
export const getOldMessagesForClassId = (id, updatedAt) => {
  return {
    type: actions.GET_OLD_MESSAGES_FOR_CLASSID,
    payload: { id, updatedAt }
  }
}

export const setNoMessagesToShow = (value) => {
  return {
    type: actions.SET_NO_MESSAGES_TO_SHOW,
    payload: { value }
  }
}








export const getMessagesForGroupId = (id) => {
  return {
    type: actions.GET_MESSAGES_FOR_GROUPID,
    payload: { id }
  }
}
export const getLatestMessagesForGroupId = (id, updatedAt) => {
  return {
    type: actions.GET_LATEST_MESSAGES_FOR_GROUPID,
    payload: { id, updatedAt }
  }
}
export const getOldMessagesForGroupId = (id, updatedAt) => {
  return {
    type: actions.GET_OLD_MESSAGES_FOR_GROUPID,
    payload: { id, updatedAt }
  }
}




export const getMessagesForSchoolId = (id) => {
  return {
    type: actions.GET_MESSAGES_FOR_SCHOOLID,
    payload: { id }
  }
}
export const getLatestMessagesForSchoolId = (id, updatedAt,clear) => {
  return {
    type: actions.GET__LATEST_MESSAGES_FOR_SCHOOLID,
    payload: { id, updatedAt,clear }
  }
}
export const getOldMessagesForSchoolId = (id, updatedAt) => {
  return {
    type: actions.GET_OLD_MESSAGES_FOR_SCHOOLID,
    payload: { id, updatedAt }
  }
}


export const getMessagesForAllTeachers = (id) => {
  return {
    type: actions.GET_MESSAGES_FOR_ALL_TEACHERS,
    payload: { id }
  }
}
export const getLatestMessagesForAllTeachers = (id, updatedAt) => {
  return {
    type: actions.GET_LATEST_MESSAGES_FOR_ALL_TEACHERS,
    payload: { id, updatedAt }
  }
}
export const getOldMessagesForAllTeachers = (id, updatedAt) => {
  return {
    type: actions.GET_OLD_MESSAGES_FOR_ALL_TEACHERS,
    payload: { id, updatedAt }
  }
}




// export const saveSelectedMessage = (message) => {
//   return {
//     type: actions.SAVE_SELECTED_MESSAGE,
//     payload: { message }
//   }
// }


export const updateMessages = (messages, isPush) => {
  return {
    type: actions.UPDATE_MESSAGES,
    payload: { messages, isPush }
  }
}

export const updateMessageQueues = (messages, isPush) => {
  return {
    type: actions.UPDATE_MESSAGE_QUEUES,
    payload: { messages, isPush }
  }
}



export const getMessageQueues = (schoolId, franchiseId) => {
  return {
    type: actions.GET_MESSAGE_QUEUES,
    payload: { schoolId, franchiseId }
  }
}

export const setMessageQueueTimer =(value) => {
  return {
    type: actions.MESSAGE_QUEUE_TIMER,
    payload: { value }
  }
}


export const directAssignMessages = (messages) => {
  return {
    type: actions.DIRECT_ASSIGN_TO_STATE_MESSAGES,
    payload: { messages }
  }
}

export const toggleIsOldMessages = (boolValue) => {
  return {
    type: actions.TOGGLE_IS_OLD_MESSAGES,
    payload: { boolValue }
  }
}

export const setMessagesLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING_MESSAGES,
    payload: { boolValue }
  }
}


export const getAlbumsForAlbumIds = (albumIds) => {
  return {
    type: actions.GET_ALBUMS_FOR_ALBUM_IDS,
    payload: { albumIds }
  }
}

export const updateAlbums = (albums) => {
  return {
    type: actions.UPDATE_ALBUMS,
    payload: { albums }
  }
}

export const deleteAlbum = (album) => {
  return {
      type: actions.DELETE_ALBUM,
      payload: { album }
  }
}



// export const setDeletedSuccessfullyMessages = (boolValue) => {
//   return {
//     type: actions.DELETED_SUCCESSFULLY_MESSAGES,
//     payload: { boolValue }
//   }
// }


export const getMessagesForCourseKid = (courseId,kidId,clear) => {
  return {
    type: actions.GET_MESSAGES_FOR_COURSE_KID,
    payload: { courseId,kidId,clear }
  }
}
export const getLatestMessagesForCourseKid = (courseId,kidId, updatedAt) => {
  return {
    type: actions.GET_LATEST_MESSAGES_FOR_COURSE_KID,
    payload: { courseId,kidId, updatedAt }
  }
}
export const getOldMessagesForCourseKid = (courseId,kidId, updatedAt) => {
  return {
    type: actions.GET_OLD_MESSAGES_FOR_COURSE_KID,
    payload: { courseId,kidId, updatedAt }
  }
}

