import React from 'react';
import { Card, CardBody, CardTitle, CardSubtitle } from "reactstrap";
import * as WhiteLable from "../../components/Common/WhiteLable";
const PrivacyPolicy = (props) => {

    return (
        <React.Fragment>


            <div className=" m-4">
                <div className=" text-center mb-4">
                    <img src={WhiteLable.getAppLogoWithName()} alt="" height="36" />
                </div>

                <h3 className="text-dark text-center"> PRIVACY POLICY</h3>

                <Card className="text-left">

                    <CardBody>
                        <div className="privacyDesc" >
                            <p>SLATE AND CHALK INNOVATIONS PVT LTD (“<strong>Company</strong>” or “<strong>lilTriangle</strong>” or “<strong>We</strong>” or “<strong>Us</strong>” or “Our” and their connotations) operates a website and a mobile application ( “together referred to as Platform”) which is engaged in the service of providing in software solutions to manage preschools, schools and any eduction institutes and to facilitate the sale, purchase, distribution, etc., of products and/or services related to and resulting from software applications (“<strong>Services</strong>”).</p>
                            <p>Our Platform is primarily for the preschools/schools/education institutes who are enlisted on our Platform and the end users who want to avail the updates provided by the preschools/schools/education institutes. The Users below the age of 18 years shall be deemed to have obtained parental consent to use the Platform.</p>
                            <p>The Platform gathers certain information with prior consent from the Users. Any capitalized term used but not defined in this Privacy Policy shall have the meaning attributed to it in our Terms.</p>
                            <p>Our User's privacy is extremely important to us. We recognize the privacy concerns of the Users and hence as a commitment to provide safe and secure experience on our Platform, we have formulated this privacy policy to help Users make an informed decision.</p>
                            <p><b>LEGAL BASIS:</b></p>
                            <p>This Privacy Policy ("Privacy Policy") is meant to help the Users understand what information we collect, why we collect it, what information we disclose and how the Users can update, manage, export, and delete their information. It shall be read with the terms and conditions available on [insert the link to the terms and conditions) (“Terms”) and in compliance with the following:</p>
                            <ol type="i ">
                                <li>The Information Technology Act, 2000</li>
                                <li>The Information Technology (Reasonable Security Practices and Procedures and Personal Information) Rules, 2011 (the “<strong>IT Rules</strong>”)</li>
                                <li>The Information Technology (Intermediaries Guidelines) Rules, 2011</li>
                            </ol>
                            <p>This Privacy Policy and the Terms shall be governed by and construed in accordance with the laws of India. Any dispute arising hereunder shall be referred to arbitration by a sole arbitrator, in accordance with the Arbitration and Conciliation Act, 1996. Subject to the foregoing, the Privacy Policy shall be subject to the exclusive jurisdiction of the competent courts of Bengaluru , India.</p>
                            <p><b>CONSENT:</b></p>
                            <p>You can visit the Platform without providing personal data about yourself, but by interacting with Us through the Platform, submitting information to Us or signing up for any promotions or Services offered by Us, You agree and consent to Us collecting, using, disclosing and sharing Your data, with Us, it’s authorised service providers and relevant third parties in the manner set forth in this Privacy Policy.</p>
                            <p>The Users will be deemed to have read, understood and agreed to the terms as provided in this Privacy Policy. By granting their assent to this Privacy Policy, Users’ provide consent to such use, collection and disclosure of Personal Information as prescribed in this Privacy Policy. We may periodically change this Policy to keep pace with new technologies, industry practices, and regulatory requirements, among other reasons. We expect most such changes to be minor. When We will need to make material change to Privacy Policy, We will post a prominent notice on the home page of the website or other conspicuous place on the Platform prior to the change becoming effective.</p>
                            <p>In the event the Services are used on behalf of any other individual (such as a child/ parent etc.), or on behalf of any entity, the Users represent that they are authorised to accept this Privacy Policy and share such data as required on behalf of such person or entity.</p>
                            <p><b>INFORMATION COLLECTED:</b></p>
                            <p>All information collected by the Company is with the Users’ consent. The Company is however free to use, collect, and disclose information of the Users which is in the public domain.</p>
                            <p>When Users use / register on the Platform, the Users may provide the Company with certain information voluntarily specific to the nature of the service being availed on the Platform and/or certain information may be collected by the Company. This information may include sensitive personal information as defined under the IT rules (“Personal Information”).</p>
                            <p>The Users hereby consent to the collection of such information by the Company.</p>
                            <p>Information collected by the Company shall include but not be limited to the following</p>
                            <ul type="circle ">
                                <li>Name;</li>
                                <li>Contact Details (Permanent Address, Correspondence Address, Email ID, Mobile No etc.);</li>
                                <li>Date of Birth, Nationality;</li>
                                <li>Age, Gender and other Demographics;</li>
                                <li>financial information (like credit card, banking details), if applicable.</li>
                            </ul>
                            <p>We may collect and upload users images that you choose to share. This data is used to display your profile picture on your user profile and make it visible to other users within the platform/app, We do not share your images with third parties except as necessary to provide the app's services, comply with legal obligations, or with your explicit consent.</p>
                            <p>We also collect certain information either when the Users provide it using the Platform or Services and / or automatically as you navigate through the Platform (which may include usage details, IP address, device ID and type, your browser type and language, the operating system used by your device, access times, and information collected through cookies, web beacons and other tracking technologies).</p>
                            <p>We may also collect information associated with any other account (such as your Facebook, Whatsapp, Yahoo, Google accounts) that You use to sign in to or in any other manner connected with the Platform. This includes, without limitation, any information that You have made public through such an account, information that such account service-provider shares with us or information that is disclosed during the sign-up and sign-in processes.</p>
                            <p>If You email Us, We may store copies of such email or similar communications between You and Us. This can result in the collection of Your email address or other personally identifiable information You may have in Your email text, addresses, or other fields, as well as the collection of your IP address, the internet or other routing of your communication, and the date and time.</p>
                            <p>Usage Information may be collected using a cookie. If You do not want information to be collected through cookies, Your browser allows You to deny or accept the use of cookies. Cookies can be disabled or controlled by setting a preference within Your web browser or on Your device. If You choose to disable cookies or flash cookies on Your device, some features of the Services may not function properly or may not be able to customize the delivery of information to You. We cannot control the use of cookies (or the resulting information) by third parties and use of third-party cookies is not covered by this Privacy Policy.</p>
                            <p>If the Company has reasons to suspect that the information provided by any User is misleading, inaccurate, obsolete or incomplete, the Company reserves the right to refuse provision of the Services to the User, at its sole discretion.</p>
                            <p><b>USE OF THE INFORMATION COLLECTED:</b></p>
                            <p>We collect Personal Information only necessary for the following purposes: </p>
                                
                            <ul type="disc ">
                                <li>To create and maintain your account;</li>
                                <li>To enable you to avail of our Services, and ensure efficient customer care experience and develop new features;</li>
                                <li>To send surveys or marketing communications to inform you of new products or services or other information that may be of interest;</li>
                                <li>To improve marketing and promotional efforts, to analyze Platform usage, improve the Platform’s content and Service offerings, and customize the App's content, layout, and Services.</li>
                                <li>To dispatch transaction-related communications such as welcome letters and billing reminders;</li>
                                <li>To notify the necessary information related to the Platform and your account on the Platform from time to time;</li>
                                <li>To maintain records and provide you with an efficient, safe and customized experience while using the Platform;</li>
                                <li>To analyze overall trends to helps us improve the Platform and Services;</li>
                                <li>Verify your identity and prevent fraud or other unauthorized or illegal activity</li>
                                <li>To comply with applicable laws, rules, and regulations;</li>
                                <li>To transfer the data and the rights associated therewith to a third party as part of reorganization or a sale of the assets of any division of the Company or the entire Company itsel.</li>
                                <li>And in any other way consistent with this Privacy Policy and to support the above activities.</li>
                            </ul>
                            <p>Additionally,</p>
                            <ul type="disc ">
                                <li>We reserve the right to use or disclose your Personal Information in response to any statutory or legal requirements in order to protect our rights, the rights of our other Users, data processors and any other person or property.</li>
                                <li>The Platform offers publicly accessible blogs and/or community forums. You should be aware that any information you provide in these areas may be read, collected, and used by others who access them. The Company may or may not be able to remove your personal information posted here by You.</li>
                                <li>Finally, we will use or disclose your Personal Information if we believe it is necessary to share information in order to investigate, prevent, or take action regarding illegal activities, suspected fraud, situations involving potential threats to the physical safety of any person or property, violations of the Platform’s other policies, or as otherwise required by law when responding to subpoenas, court orders and other legal processes.</li>
                            </ul>
                            <p><b>DURATION OF DATA STORED:</b></p>
                            <p>Your data will be retained only for as long as is necessary. After a reasonable time, when We no longer require the data, it is destroyed appropriately and securely or anonymized in accordance with the law. We retain Your data for as long as needed: (i) in order to comply with Our legal and contractual obligations; (ii) as long as We have a continued relationship with You; and, (iii) as long as needed to provide Services to You.</p>
                            <p><b>DATA PROTECTION:</b></p>
                            <p>The security of Personal Information is important and We have implemented commercially acceptable means to protect the same. However, remember that no method of transmission over the internet, or method of electronic storage, is 100% secure and We cannot guarantee its absolute security. We have implemented best industry practices and security policies, rules and technical measures to protect the data including Personal Information that is under the Company’s control from unauthorised access, improper use or disclosure, unauthorised modification and unlawful destruction or accidental loss. However, for any data loss or theft due to unauthorized access to the User’s electronic devices through which the User avails the Services, We shall not be held liable for any loss whatsoever incurred by the User. We urge not to share Your password with any third party, and if You believe Your password or account has been compromised, You should change it immediately and contact Our support team for any concerns. If Your communication will include sensitive personal information like Your financial information such as bank account or credit card or debit card or other payment instrument details, and You prefer not to transmit it electronically, please contact Us by postal mail or telephone rather than email.</p>
                            <p>In the event, our partners and third parties distribute Personal Information outside the scope of our agreement with such partners or third parties, the Company cannot be held liable for the confidentiality. Further, in case breach of security or actions including without limitation, computer hacking, unauthorized access to computer data and storage device, computer crashes etc., you agree that the Company will not be held responsible in any manner.</p>
                            <p><b>Sharing of Personal Information </b></p>
                            <p>The Company may share your Personal Information with third party service providers (called ‘data processors’) to perform certain processing activities on our behalf, such as Parties involved in hosting and enabling our Services. Please note that we will not share your Personal Information with third parties allowing them to use this information for their own purposes unless you have given explicit consent thereto. Our third party processors include:</p><table width="98% " border="1 " cellpadding="6px "><tbody><tr><th width="25% ">Name</th><th width="75% ">Requirement</th></tr><tr><td>Usage Analytics</td><td>We may use third-party service providers to monitor and analyze the use of Our Service</td></tr><tr><td>Online and Behavioural Marketing</td><td>We use remarketing services to advertise on third party websites to You after You visit Our Platform. We and our third-party vendors use cookies to inform, optimise and serve ads based on your past visits to Our Platform</td></tr><tr><td>Payment Processing and Discounting</td><td>We may provide paid products and/or services within the Service. In that case, We use third-party services for payment processing (e.g. payment processors).</td></tr></tbody></table>
                            <p>&nbsp;</p>
                            <p>We may also be required by law, legal process, litigation, and/or requests from public and governmental authorities; to disclose Your data. We may also disclose Your data if we determine that for purposes of national security, law enforcement, or other issues of public importance, disclosure is necessary or appropriate. We may also disclose data if we determine in good faith that disclosure is reasonably necessary to protect Our rights and to protect against the misuse or unauthorized use of the Services and pursue available remedies, enforce our terms and conditions, investigate fraud, or protect our operations or users.</p>
                            <p>If We become involved in a merger, acquisition, or any form of sale of some or all of our assets, We will provide notice if such a transaction results in Your information being subject to a different privacy policy.</p>
                            <p><b>THIRD PARTY DISCLAIMER: </b></p>
                            <p>The Platform may contain links to other websites. Please note that when Users click on one of these links, they are entering another website over which We have no control and for which We will bear no responsibility. Often these websites require the User to enter their Personal Information. We encourage the Users to read the privacy policies of all such websites as their policies may differ from our Privacy Policy. Users agree that We shall not be liable for any breach of your privacy of Personal Information or loss incurred by their use of such websites or services. The inclusions or exclusions are not suggestive of any endorsement by the Company of the website or contents of the website. The Users may visit any third party website linked to the Platform at their risk. We strongly recommend the Users to read the privacy policies of third party websites</p>
                            <p>Additionally, the Platform may allow for certain content generated by the User, which can be accessed by other Users. Such Users, including any moderators or administrators, are not authorized representatives or agents of the Company and their opinions or statements do not necessarily reflect those of the Company and We are not bound thereby to any contract to that effect. The Company expressly disclaims any liability for any reliance or misuse of such information that is made available by the Users</p>
                            <p><b>RIGHTS OF THE USERS: </b></p>
                            <p>As a User, the Company grants complete control of the information submitted. The rights pertaining thereto are enumerated below</p><ul type="disc "><li><strong>The right to access, update or delete the information We have on the User.</strong>This can be done through the User’s account settings available on the Platform.</li><li><strong>The right of rectification.</strong> Users have the right to have their information rectified if that information is inaccurate or incomplete.</li><li><strong>The right to object and restrict.</strong> Users can object and restrict to the processing of their Personal Information. This may however, interfere with the provision of Services to its optimum</li><li><strong>The right to data portability.</strong> Users have the right to be provided with a copy of the information We have on you in a structured, machine-readable and commonly used format.</li><li><strong>The right to withdraw consent.</strong> Users have the right to withdraw their consent at any time where consent is given pertaining to Personal Information.</li></ul>
                            <p>Please note that Users may be asked to verify identity before responding to such requests. If the Users are unable to perform these actions themselves, please get in touch with the Grievance Officer for assistance.</p>
                        </div>
                        
                       
                    </CardBody>
                </Card>
            </div>
        </React.Fragment>
    );
}
export default PrivacyPolicy;