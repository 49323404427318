import Parse from "parse";
import { printLog } from "./loghelper";
export const getAdmissionQuestionFromParse = (admissionQuestionId) => {
  printLog("HL: admissionQuestion_helper : getAdmissionQuestionFromParse");
  try {
    return new Promise((resolve, reject) => {
      var AdmissionQuestion = Parse.Object.extend("AdmissionQuestion");
      var query = new Parse.Query(AdmissionQuestion);
      query.get(admissionQuestionId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const getAdmissionQuestionFromLocal = (enquiryQuestionId) => {
  printLog("HL: admissionQuestion_helper : getAdmissionQuestionFromLocal");
  try {
    return new Promise((resolve, reject) => {
      var EnquiryQuestion = Parse.Object.extend("AdmissionQuestion");
      var query = new Parse.Query(EnquiryQuestion);
      query.fromLocalDatastore();
      query.get(enquiryQuestionId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) {}
};

export const deleteAdmissionQuestionFromParse = (admissionQuestion) => {
  printLog("HL: admissionQuestion_helper : deleteAdmissionQuestionFromParse");

  try {
    return new Promise((resolve, reject) => {
      admissionQuestion.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        admissionQuestion.set(
          "deletedBy",
          Parse.User.current().attributes.teacherId
        );
      } else {
        admissionQuestion.set("deletedBy", Parse.User.current().id);
      }

      admissionQuestion.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("error");
        }
      );
    });
  } catch (error) {}
};

export const getAdmissionQuestionForAdmissionTitleId = (id, isLoacal) => {
  printLog(
    "HL: admissionQuestion_helper : getAdmissionQuestionForAdmissionTitleId"
  );
  try {
    return new Promise((resolve, reject) => {
      var AdmissionQuestion = Parse.Object.extend("AdmissionQuestion");
      var query = new Parse.Query(AdmissionQuestion);
      query.equalTo("admissionTitleId", id);

      if (isLoacal) {
        query.fromLocalDatastore();
      }
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const getAdmissionQuestionForAdmissionTitleIds = (
  ids,
  updatedDate,
  isLoacal
) => {
  printLog(
    "HL: admissionQuestion_helper : getAdmissionQuestionForAdmissionTitleIds"
  );
  try {
    return new Promise((resolve, reject) => {
      var AdmissionQuestion = Parse.Object.extend("AdmissionQuestion");
      var query = new Parse.Query(AdmissionQuestion);
      query.containedIn("admissionTitleId", ids);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.ascending("order");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const getAdmissionQuestionForSchoolId = (
  ids,
  schoolId,
  updatedDate,
  isLoacal
) => {
  printLog(
    "HL: admissionQuestion_helper : getAdmissionQuestionForAdmissionTitleIds"
  );
  try {
    return new Promise((resolve, reject) => {
      var AdmissionQuestion = Parse.Object.extend("AdmissionQuestion");
      var query = new Parse.Query(AdmissionQuestion);
      query.equalTo("schoolId", schoolId);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.ascending("order");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};

export const editQuestion = (object) => {
  printLog("HL: admissionQuestion_helper : editQuestion");
  try {
    return new Promise((resolve, reject) => {
      object.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve("Error");
        }
      );
    });
  } catch (error) {}
};

export const saveAdmissionQuestions = (objects) => {
  printLog("HL: admissionQuestion_helper : saveAdmissionQuestions");
  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(objects).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
          console.log("Error", error);
        }
      );
    });
  } catch (error) {
    console.log("Error", error);
  }
};

export const getDefaultAdmissionQuestions = (updatedDate, isLoacal) => {
  printLog("HL: admissionQuestion_helper : getDefaultAdmissionQuestions");
  try {
    return new Promise((resolve, reject) => {
      var AdmissionQuestion = Parse.Object.extend("AdmissionQuestion");
      var query = new Parse.Query(AdmissionQuestion);
      query.equalTo("admissionTitleId", "defaultQuestions");

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }
      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }
      query.ascending("order");

      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) {}
};
