import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import Moment from "moment";
import DatePicker from "react-datepicker";
import * as Constants from "../../Constents"
import { Row, Col, Card, CardBody, Table, Label, Button, Input, Media, Modal, ModalBody, ModalFooter, ModalHeader, Form, FormGroup } from "reactstrap";
import { setSelecetdSchool, toggleLeftmenu, getFranchise } from "../../store/actions";

const franchiseList = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/tools-franchise-view/",
        exact: true,
        strict: false,
    });

    const [displayFranchise, setDisplayFranchise] = useState([]);
    const [searchFranchise, setSearchFranchise] = useState([])
    const [loading, setLoading] = useState(false);



    useEffect(() => {
        setLoading(true)
        let query = new Parse.Query('Franchise');
        query.notEqualTo("deleted", true)
        query.find().then((result) => {
            console.log(result)
            setSearchFranchise(result)
            setDisplayFranchise(result)
            setLoading(false)
        })

    }, [])

    const featuresEnabled = (franchise) => {
        let features = franchise.attributes.features ?? [];

        return (<div>

            {features.length > Constants.FRANCHISE_REPORTS_INDEX &&
                features[Constants.FRANCHISE_REPORTS_INDEX] >= 0 &&
                <p className=" mb-0">{"Reports"}</p>
            }

            {features.length > Constants.FRANCHISE_ADMISSION_FORM_INDEX &&
                features[Constants.FRANCHISE_ADMISSION_FORM_INDEX] > 0 &&
                <p className=" mb-0">{"Admission Form"}</p>
            }

            {features.length > Constants.FRANCHISE_ROYALTY_SETTING_INDEX &&
                features[Constants.FRANCHISE_ROYALTY_SETTING_INDEX] > 0 &&
                <p className=" mb-0">{"Royalty Setting"}</p>
            }

            {features.length > Constants.FRANCHISE_ID_CARD_DISABLE_INDEX &&
                features[Constants.FRANCHISE_ID_CARD_DISABLE_INDEX] > 0 &&
                <p className=" mb-0">{"Id Card Disable"}</p>
            }

            {features.length > Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX &&
                features[Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX] > 0 &&
                <p className=" mb-0">{"Carporate Partners"}</p>
            }

            {features.length > Constants.FRANCHISE_DOCUMENT_INDEX &&
                features[Constants.FRANCHISE_DOCUMENT_INDEX] > 0 &&
                <p className=" mb-0">{"Document"}</p>
            }

            {features.length > Constants.FRANCHISE_SCHOOL_ADDING_DISABLE_INDEX &&
                features[Constants.FRANCHISE_SCHOOL_ADDING_DISABLE_INDEX] > 0 &&
                <p className=" mb-0">{"School Adding"}</p>
            }

            {features.length > Constants.FRANCHISE_TICKETS_INDEX &&
                features[Constants.FRANCHISE_TICKETS_INDEX] > 0 &&
                <p className=" mb-0">{"Help Desk Tickets"}</p>
            }

            {features.length > Constants.FRANCHISE_INVENTORY_INDEX &&
                features[Constants.FRANCHISE_INVENTORY_INDEX] > 0 &&
                <p className=" mb-0">{"Inventory"}</p>
            }

            {features.length > Constants.FRANCHISE_LOCK_FEATURE_EDIT &&
                features[Constants.FRANCHISE_LOCK_FEATURE_EDIT] > 0 &&
                <p className=" mb-0">{"Lock feature permission"}</p>
            }

        </div>);
    }

    function searchData(search) {
        let tempList = [...searchFranchise]
        if (search === undefined || search === null || search.length === 0) {
            setDisplayFranchise(tempList);
        } else if (tempList) {
            let filter = tempList.filter((item) =>
                (item.attributes.name.toLowerCase().includes(search.toLowerCase()) || search === item.id)
            );
            setDisplayFranchise(filter);
        }
    }


    return (
        <div className='page-content  w-100 '  >

            <div>
                <Row>
                    <Col lg={5} md={6} xs={12}>
                        <div className="search-box position-relative" style={{ width: "81%" }} >
                            <Input
                                type="text"
                                className="form-control border-1 mb-3 search-input"
                                placeholder="Search..."
                                onChange={(e) => {
                                    searchData(e.target.value);
                                }}
                            />
                            <i
                                className="bx bx-search-alt search-icon"
                                style={{
                                    fontSize: "20px",
                                    color: "blue"
                                }}
                            ></i>
                        </div>
                    </Col>
                </Row>
            </div>
            {/* <div className="">
                <Card style={{ maxHeight: '80vh', overflowY: 'auto' }}>
                    <CardBody>
                        <div className="mt-1 ">
                            <div className={"border rounded p-3 mb-1 "} style={{ backgroundColor: "white" }}>
                                <Row style={{ height: "8px", paddingBottom: "20px" }}>
                                    <Col lg={1}>
                                        <div >
                                            <div className="">
                                                <Label className=" font-size-14">
                                                    #
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col lg={4} >
                                        <div >
                                            <div className="">
                                                <Label className="font-size-14">
                                                    Franchises
                                                </Label>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="float-right">
                                            <Label className=" font-size-14">
                                                Features Enabled
                                            </Label>
                                        </div>
                                    </Col>
                                    <Col>
                                        <div className="float-right">
                                            <Label className=" font-size-14">
                                                Schools
                                            </Label>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            {displayFranchise.map((franchise, key) => (
                                <Card className="mb-1 bg-whites" key={franchise.id}>
                                    <CardBody>
                                        <div >
                                            <Row>
                                                <Col lg={1}>
                                                    <div className="d-flex">
                                                        {key + 1}
                                                    </div>
                                                </Col>
                                                <Col lg={4}>
                                                    <div className="pt-1">
                                                        <Link target='_blank' to={'/tools-schools-subscription/' + franchise.id}>
                                                            {franchise.attributes.name}
                                                        </Link>
                                                        <br />
                                                        <small>{franchise.attributes.city}</small>
                                                    </div>
                                                </Col>
                                                <Col>
                                                    <div className="float-right">
                                                            {featuresEnabled(franchise)}
                                                    </div>
                                                </Col>
                                                <Col className='float-right'>
                                                    <div className='float-right'>
                                                        <Link target='_blank' to={'/tools-schools-subscription/' + franchise.id}>
                                                            <p><u>Schools</u></p>
                                                        </Link>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </div>
                                    </CardBody>
                                </Card>
                            ))
                            }
                        </div>
                    </CardBody>
                </Card>
            </div> */}

            <Row>
                <Col>
                <Card>
                    <CardBody>
                    <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>#</th>
                                            <th>Franchises</th>
                                            <th>Contact details</th>
                                            <th>Features Enabled</th>
                                            <th>Schools</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            displayFranchise.map((franchise, key) =>
                                                <tr key={key}>
                                                    <th>
                                                    {key + 1}
                                                    </th>
                                                    <td>
                                                    <Link target='_blank' to={'/tools-schools-subscription/' + franchise.id}>
                                                            {franchise.attributes.name}
                                                        </Link>
                                                        <br />
                                                        <small>{franchise.attributes.city}</small>
                                                        <br />
                                                        <small>{franchise.id}</small>
                                                    </td>
                                                    <td>
                                                        <p>{franchise.attributes.email ? franchise.attributes.email : ""}<br/>
                                                        {franchise.attributes.primaryPhone ? franchise.attributes.primaryPhone : ""}</p>
                                                    </td>

                                                    <td>
                                                    {featuresEnabled(franchise)}
                                                    </td>

                                                    <td>
                                                    <Link target='_blank' to={'/tools-schools-subscription/' + franchise.id}>
                                                            <p><u>Schools</u></p>
                                                        </Link>                                                    </td>
                                                </tr>
                                            )
                                        }
                                    </tbody>
                                </table>
                    </CardBody>
                </Card>
                </Col>
            </Row>

            {loading && (
                <Col xs="12">
                    <div className="text-center py-4">
                        <Link to="#" className="text-success">
                            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                            Loading....{" "}
                        </Link>
                    </div>
                </Col>
            )}
            {/* {displayPageSchools.length === 0 && !loading && (
                <Col>
                    <h3 className="text-center text-info mt-3">
                        {props.t("No Data Found For The Filter")}
                    </h3>
                </Col>
            )} */}
        </div>
    )
}

const mapStatetoProps = (state) => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
};
export default connect(mapStatetoProps, {
    setSelecetdSchool, toggleLeftmenu, getFranchise
})(withRouter(withNamespaces()(franchiseList)));


