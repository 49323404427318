import React, { useEffect,useState} from 'react';
import { withNamespaces } from 'react-i18next';
import { setSelecetdSchool,getCorporatePartners, getParentsForFamilyId} from '../../../store/actions';
import { withRouter, Link } from "react-router-dom";
import { connect } from 'react-redux';
import Parse from 'parse';
import DatePicker from "react-datepicker";
import Moment from 'moment';
import { Table, Thead, Tbody, Tr, Th, Td } from 'react-super-responsive-table';
import { Element, Events, animateScroll as scroll, scrollSpy, scroller } from 'react-scroll'
import Select from "react-select";
import {
    Row,
    Col,
    Card,
    CardBody,
    FormGroup,
    Label,
    CardHeader,
    CardTitle,
    Container
  
} from "reactstrap";
// import XLSX from "xlsx";
import * as XLSX from 'xlsx/xlsx.mjs';


const CorporatePartnerReport = (props) => {



    const [allKids, setAllKids] = useState([]);
    const [selctedCorporate, setSelctedCorporate] = useState(undefined);
    const [selctedCorporateObject, setSelctedCorporateObject] = useState(undefined);
    const [attendanceObjects, setAttendanceObjects] = useState([]);
    const [attendanceDaycareObjects, setAttendanceDaycareObjects] = useState([]);

    const [selectedDate, setSelectedDate] = useState(undefined);
    const [selectedEndDate, setSelectedEndDate] = useState(undefined);

    const [loadingState, setloadingState] = useState(undefined);

    const [schoolData, setSchoolData] = useState([]);
    const [kidData, setKidData] = useState([]);


    const [selctedCorporateError, setSelctedCorporateError] = useState(false);
    const [selectedDateError, setSelectedDateError] = useState(false);
    const [selectedEndDateError, setSelectedEndDateError] = useState(false);

    const [totalInTime, setTotalInTime] = useState();
    const [calenderTitles, setCalenderTitles] = useState([]);
    const [calenderData, setCalenderData] = useState([]);
    const [totalCheckins, setTotalCheckins] = useState([]);
    const [allSchools, setAllSchools] = useState([]);
    const [selectedSchool, setSelctedSchool] = useState({ value: "allSchools", label: 'All Schools' });
    const [activeKids, setActiveKids] = useState();

    useEffect(() => {
        if (props.schools) {
            let list = [{ value: "allSchools", label: 'All Schools' }];
            for (const school of props.schools) {
                list.push({ value: school.id, label: school.attributes.Name })
            }
            setAllSchools(list);
        }
    }, [props.schools]);
    

    useEffect(() => {
        if (Parse.User.current().attributes.franchiseId) {
            props.getCorporatePartners(Parse.User.current().attributes.franchiseId, "franchiseId")
        }
    }, [Parse.User.current().attributes.franchiseId]);

   
    const getSelctedCorporate = (selctedCorporate) => {
        for (const c of props.corporatePartners) {
            if (selctedCorporate === c.id) {
                setSelctedCorporateObject(c);
            }
        }
    }

    const getAttendanceReport = () => {

        let shouldGo = true
        if (!selctedCorporate) {
            setSelctedCorporateError(true)
            shouldGo = false
        }
        if (!selectedEndDate) {
            setSelectedDateError(true)
            shouldGo = false
        }
        if (!selectedEndDate) {
            setSelectedEndDateError(true)
            shouldGo = false
        }

        getSelctedCorporate(selctedCorporate)

        if (!shouldGo) {
            return;
        } else {
            setSelctedCorporateError(false)
            setSelectedDateError(false)
            setSelectedEndDateError(false)
        }

        setloadingState(0);
        let startDate = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate()
        );
        let endDate = new Date(
            selectedEndDate.getFullYear(),
            selectedEndDate.getMonth(),
            selectedEndDate.getDate(),
            23,
            59,
            59,
            999
        );
        
        let schoolIds = [];


        let query = new Parse.Query("Kid");
        query.notEqualTo('Deleted', true);

        if (props.isFranchise) {
            if (selectedSchool && selectedSchool.value != 'allSchools') {
                schoolIds.push(selectedSchool.value)
            } else {
                for (const school of props.schools) {
                    schoolIds.push(school.id)
                }
            }
            
            if (selctedCorporate) {
                query.equalTo('corporateId', selctedCorporate)
                
                if (selectedSchool && selectedSchool.value != 'allSchools') {
                    query.equalTo('SchoolID', selectedSchool.value);
                } else {
                    query.containedIn('SchoolID', schoolIds)
                    query.limit(schoolIds.length * 150);
                }

            } else {
                query.containedIn('SchoolID', schoolIds)
                query.limit(schoolIds.length * 150);
            }
        } else {
            query.equalTo('SchoolID', props.schoolId)
            query.limit(1000);
        }
        
        query.ascending('Name')
        query.find().then((kids) => {
            // console.log('kids',kids)
            setAllKids(kids);

            let schoolIdsNew = [];
            for (const k of kids) {
                if (!schoolIdsNew.includes(k.attributes.SchoolID)) {
                    schoolIdsNew.push(k.attributes.SchoolID)
                }
            }
            getDataFromAttendanceTable(schoolIdsNew, kids, 0, startDate, endDate, [])
        });
    }
    const getDataFromAttendanceTable = (schoolIds, kids, skip, startDate, endDate, exList) => {
        let query2 = new Parse.Query("Attendance");
        query2.notEqualTo('Deleted', true);
        query2.containedIn('School', schoolIds);
        query2.limit(1000)
        query2.skip(skip);
        query2.greaterThanOrEqualTo('createdAt', startDate);
        query2.lessThanOrEqualTo('createdAt', endDate);

        query2.find().then((aObjects) => {
            let oldList = [...exList];
            if (aObjects) {
                oldList = oldList.concat(aObjects)
            }
            if (aObjects && aObjects.length == 1000) {
                getDataFromAttendanceTable(schoolIds, kids, skip + 1000, startDate, endDate, oldList)
            } else {
                setAttendanceObjects(oldList);
                //setloadingState(2);
                // setloadingState(undefined);

                getDataFromDaycareAttendanceTable(schoolIds, kids, 0, startDate, endDate, oldList,[])
                //prepareData(oldList, kids);
            }
        });
    }

    const getDataFromDaycareAttendanceTable = (schoolIds, kids, skip, startDate, endDate, classAttendance, exList) => {
        let query2 = new Parse.Query("DaycareTimeSheet");
        query2.containedIn('schoolId', schoolIds);
        query2.limit(1000)
        query2.skip(skip);
        query2.greaterThanOrEqualTo('createdAt', startDate);
        query2.lessThanOrEqualTo('createdAt', endDate);

        query2.find().then((aObjects) => {
            let dList = [...exList];
            if (aObjects) {
                dList = dList.concat(aObjects)
            }
            if (aObjects && aObjects.length == 1000) {
                getDataFromDaycareAttendanceTable(schoolIds, kids, skip + 1000, startDate, endDate,classAttendance, dList)
            } else {
                setAttendanceDaycareObjects(dList);
                //setloadingState(2);
                getDeletedKidsAndParents(classAttendance,dList, kids);
            }
        });
    }

    const getDeletedKidsAndParents = (classAttendance,daycareAttendance, kids) => {
        let exKidsIds = [];
        let dKidsIds = [];
        let familyIds = [];
        for (const k of kids) {
            exKidsIds.push(k.id);
            if (!familyIds.includes(k.attributes.FamilyID)) {
                familyIds.push(k.attributes.FamilyID);
            }
        }

        for (const ca of classAttendance) {
            if (!exKidsIds.includes(ca.attributes.Kid)) {
                if (!dKidsIds.includes(ca.attributes.Kid)) {
                    dKidsIds.push(ca.attributes.Kid);
                }
            }
        }

        for (const da of daycareAttendance) {
            if (!exKidsIds.includes(da.attributes.kidId)) {
                if (!dKidsIds.includes(da.attributes.kidId)) {
                    dKidsIds.push(da.attributes.kidId);
                }
            }
        }

        if (dKidsIds && dKidsIds.length > 0) {
            let query2 = new Parse.Query("Kid");
            query2.containedIn('onbjectId', dKidsIds);
            query2.limit(dKidsIds.length);
            query2.ascending('Name');
            query2.find().then((dKids) => {
                getParentsForFamilyId(classAttendance,daycareAttendance, kids.concat(dKids),familyIds);
            });
        } else {
            getParentsForFamilyId(classAttendance,daycareAttendance, kids,familyIds);
        }
    }

    const getParentsForFamilyId = (classAttendance, daycareAttendance, kids, familyIds) => {
        let query2 = new Parse.Query("Parent");
        query2.containedIn('FamilyID', familyIds);
        query2.limit(familyIds.length*2)
        query2.find().then((parents) => {
            setloadingState(2);
            prepareData(classAttendance, daycareAttendance, kids, parents);
        });
    }

    const getDateInDDMMYYYY = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };

    const getDaysDifference = (inTime, outTime) => {
        let startTime = Moment(inTime);
        let endTime = Moment(outTime);
        let duration = Moment.duration(endTime.diff(startTime));
   
        let days = parseInt(duration.asDays());
    
        return days;
    };

    const prepareData = (attendanceObjects,daycareList, allKids,parents) => {
        // console.log("attendanceObjects", attendanceObjects);

       

        let list = [];
        let list2 = [];
        let InTime = 0;
        let allCheckins = 0;
        let allActiveKids = 0;


        let finalData = [];
        let title = [];
        
        // finalData.push([
        //   `Duration: ${getDateInDDMMYYYY(selectedDate)} - ${getDateInDDMMYYYY(selectedEndDate)}`,
        // ]);
        title.push("Student Name");
        title.push("School Name");
        title.push("Corporate Name");
        title.push("Father Name");
        title.push("Father EmployeeId");
        title.push("Mother Name");
        title.push("Mother EmployeeId");


        const noOfDays = getDaysDifference(selectedDate, selectedEndDate) + 1;

        let startDate = selectedDate;
        for (let i = 1; i <= noOfDays; i++) {
          title.push(getDateInDDMMYYYY(startDate));
          startDate= Moment(startDate).add(1, 'days');
        }
        title.push('Total')

        setCalenderTitles(title);
        // finalData.push(title);

        let schoolObjects = props.schools;
        if (props.isFranchise && selectedSchool && selectedSchool.value != 'allSchools') {
            schoolObjects = props.schools.filter( (s) => s.id == selectedSchool.value);
        }

        for (const school of schoolObjects ) {

            let kidList = [];
            let schoolTime = 0
            let schoolCheckins = 0;
            let schoolActiveKids = 0;
            
            // console.log(school.id)
            // console.log("**** allKids",allKids.length)

            let sKids = allKids.filter(
                (k) => k.attributes.SchoolID == school.id
            );

            let sAttendance = attendanceObjects.filter(
                (k) => k.attributes.School == school.id
            );

            // console.log("sKids",sKids)
            // console.log("sAttendance",sAttendance)
            for (const kid of sKids) {
                
                // console.log(kid.attributes.SchoolID)

                let aList = sAttendance.filter(
                    (ao) => ao.attributes.Kid == kid.id
                );

                let daList = daycareList.filter(
                    (ao) => ao.attributes.kidId == kid.id
                );

                let kParents = parents.filter(
                    (p) => p.attributes.FamilyID == kid.attributes.FamilyID
                );

                let parentDetails={}
                for (const pa of kParents) {
                    if (pa.attributes.Relation=='Father') {
                        parentDetails.fatherName = pa.attributes.Name;
                        parentDetails.fathercorporateEmployeeId = pa.attributes.corporateEmployeeId;
                    }else if (pa.attributes.Relation=='Mother') {
                        parentDetails.motherName = pa.attributes.Name;
                        parentDetails.mothercorporateEmployeeId = pa.attributes.corporateEmployeeId;
                    }
                }

                let tempAttFor = [];
                let temp = [];
                // console.log(aList);
                for (const attVal of aList) {
                    // console.log(getDateInDDMMYYYY(attVal.attributes.ReachedSchoolAt));
                    if (attVal.attributes.ReachedSchoolAt && attVal.attributes.Kid === kid.id) {
                        tempAttFor.push(getDateInDDMMYYYY(attVal.attributes.ReachedSchoolAt));
                    }
                }

                for (const attVal of daList) {
                    // console.log(getDateInDDMMYYYY(attVal.attributes.ReachedSchoolAt));
                    if (attVal.attributes.kidId === kid.id) {
                        let ds = getDateInDDMMYYYY(attVal.attributes.checkInTime);
                        if(!tempAttFor.includes(ds) && attVal.attributes.checkInTime){
                            tempAttFor.push(ds);
                        }
                    }
                }

                let pCount = 0;
                for (let i = 0; i < title.length - 1; i++) {
                    if (i == 0) {
                        temp.push(kid.attributes.Name);
                    } else if (i == 1) {
                        temp.push(school.attributes.Name);
                    } else if (i == 2) {
                        temp.push(selctedCorporateObject.attributes.name)
                    }

                    else if (i == 3) {
                        temp.push(parentDetails.fatherName);
                    } else if (i == 4) {
                        temp.push(parentDetails.fathercorporateEmployeeId);
                    } else if (i == 5) {
                        temp.push(parentDetails.motherName)
                    } else if (i == 6) {
                        temp.push(parentDetails.mothercorporateEmployeeId)
                    }
                    
                    else if (tempAttFor.includes(title[i])) {
                        temp.push("P");
                        pCount++;
                    } else {
                        temp.push(null);
                    }
                }
                if (pCount > 0) {
                    schoolActiveKids++
                    allActiveKids++
                }
                temp.push(pCount);
                finalData.push(temp);


                let time = getInTime(aList)

                let tKid = {
                    id: kid.id,
                    school: school,
                    kid: kid,
                    attendanceObjects: aList,
                    time: time,
                    kidCheckins:pCount
                }

                schoolCheckins = schoolCheckins + pCount
                schoolTime = schoolTime + time;
                
                kidList.push(tKid);
                list.push(tKid);
            
            }
            // console.log("*************")
            InTime = InTime + schoolTime;
            allCheckins = allCheckins + schoolCheckins;
            let schoolObject = {
                id: school.id,
                school: school,
                schoolTime: schoolTime,
                schoolCheckins:schoolCheckins,
                kidList: kidList,
                schoolActiveKids:schoolActiveKids,
            }

            // console.log(schoolObject,schoolObject.schoolTime)
            list2.push(schoolObject)
            
        }
        // console.log("list2", list2);
        setCalenderData(finalData);
        setTotalCheckins(allCheckins);
        setActiveKids(allActiveKids);
        setTotalInTime(InTime)
        setloadingState(3);
        setKidData(list);
        setSchoolData(list2);

        

            
    }

    const getInTime = (attendanceObjects) => {
        let tTime = 0.00;
        for (const ao of attendanceObjects) {
            if (ao.attributes.ReachedSchoolAt) {
                if (ao.attributes.LeftSchoolAt) {

                    let newDate = ao.attributes.ReachedSchoolAt;
                    let today = ao.attributes.LeftSchoolAt;
                    let diffTime = today - newDate;
                    // console.log("T:" + diffTime)
                    let time = diffTime / (1000 * 60);
                
                    tTime = tTime + time;
                } else {
                    tTime = tTime + 9 * 60;
                }
            }
        }

        return parseInt(tTime.toFixed(0));
    }

    const getHours = (mins) => {
        if (mins == 0) {
            return '0 hrs'
        }

        let h = Math.floor(mins / 60);
        let mi = (mins % 60);

        let m = mi == 0 ? 0 : mi.toFixed(0);
        return `${h} hrs ${m} mins`;

    }

     

    

    const onClickDownload = () => {
       
            
        let finalData = [];
        let fileName = `corporate_report.xlsx`;
        
        
        finalData.push(["School wise summary"]);
        finalData.push(["Attendance Report:" + Moment(selectedDate).format('DD MMM YYYY') + " to " + Moment(selectedEndDate).format('DD MMM YYYY')]);

        finalData.push(["Corporate partner", selctedCorporateObject.attributes.name])
        finalData.push(["Total Time", getHours(totalInTime),]);
        finalData.push(["Total Time in mins", totalInTime,]);

      

        finalData.push([]);
        finalData.push([]);
        
        finalData.push(["School Name", "Corporate partner ", "Total Time", "Total Time in mins"]);
        for (const s of schoolData) {
            finalData.push([s.school.attributes.Name, selctedCorporateObject.attributes.name, getHours(s.schoolTime), s.schoolTime]);
        }
        finalData.push([]);
        finalData.push([]);

        let finalData2 = [];
        finalData2.push(["Student wise summary"]);
        finalData2.push(["Student", "School Name", "Corporate partner ", "Total Time", "Total Time in mins"]);
        
        for (const k of kidData) {
            finalData2.push([k.kid.attributes.Name, k.school.attributes.Name, selctedCorporateObject.attributes.name, getHours(k.time), k.time]);
        }
        finalData2.push([]);
        finalData2.push([]);

        let finalData3 =[]
        finalData3.push(["Attendance Calendar Report"]);
        finalData3.push(["Report duration:" + Moment(selectedDate).format('DD MMM YYYY') + " to " + Moment(selectedEndDate).format('DD MMM YYYY')]); 
        finalData3.push(["Report For: " + selectedSchool.label]);
        finalData3.push(["Total Checkins:" + totalCheckins])
        finalData3.push(["Active Students:" + activeKids])
        finalData3.push([]);
        finalData3.push(calenderTitles);


        if (props.isFranchise) {
            let wb = XLSX.utils.book_new();
            let ws = XLSX.utils.aoa_to_sheet(finalData);
            let ws2 = XLSX.utils.aoa_to_sheet(finalData2);
            let ws3 = XLSX.utils.aoa_to_sheet(finalData3.concat(calenderData));
           

            // XLSX.utils.book_append_sheet(wb, ws, "School wise summary");
            // XLSX.utils.book_append_sheet(wb, ws2, "Student wise summary");
            XLSX.utils.book_append_sheet(wb, ws3, "Calendar Report");
            
         
            XLSX.writeFile(wb, fileName);
        }

       
    }
    

    return (
        <React.Fragment>
            
            {/* <CardTitle>Attendance Report</CardTitle> */}
            <Row className="bg-light ">
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Selcted Corporate")} :</Label>
                        <select
                            className="form-control "
                            value={selctedCorporate}
                            onChange={(e) => {
                                setSelctedCorporate(e.target.value);
                                getSelctedCorporate(e.target.value)
                                setloadingState(undefined)
                                
                            }}
                        >
                            <option disabled selected value>Pick Corporate Partners</option>
                            {props.corporatePartners && props.corporatePartners.map(
                                (val, key) =>
                                    <option key={val.id} value={val.id}>{val.attributes.name}</option>
                                    
                            )}
                        </select>
                        {selctedCorporateError && <p className='text-danger'>Mandatory</p>}
                    </FormGroup>
                </Col>
                <Col md="3" xs="12" xl="3" lg="3">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>{props.t("Selcted school")} :</Label>
                        
                         <Select
                            value={selectedSchool}
                            onChange={(v) => {
                                setSelctedSchool(v)
                                setloadingState(undefined);
                            }}
                            options={allSchools}
                            className="selectPaymentType"
                            classNamePrefix="selectPayType"
                            
                        />
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select Start Date</Label>
                        <DatePicker
                            selected={selectedDate}
                            onChange={(date) => {
                                date ? setSelectedDate(date) : setSelectedDate(new Date());
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
        
                            maxDate={selectedEndDate}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                        {selectedDateError && <p className='text-danger'>Mandatory</p>}
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <FormGroup className="mt-2 mb-2 p-1">
                        <Label>Select End Date</Label>
                        <DatePicker
                            selected={selectedEndDate}
                            onChange={(date) => {
                                date
                                    ? setSelectedEndDate(date)
                                    : setSelectedEndDate(new Date());
                            }}
                            className="form-control"
                            placeholderText={"Select Date"}
        
                            minDate={selectedDate}
                            maxDate={new Date()}
                            dateFormat="dd/MM/yyyy"
                            showFullMonthYearPicker
                        />
                        {selectedEndDateError && <p className='text-danger'>Mandatory</p>}
                    </FormGroup>
                </Col>
                <Col md="2" xs="12" xl="2" lg="2">
                    <div className="mt-2">
                        <div className="mt-3">
                            <button
                                className="btn btn-primary mt-4 mr-2"
                                onClick={getAttendanceReport}
                            >
                                {props.t("Generate")}
                            </button>
                        </div>
                    </div>
                </Col>
            </Row>

            {(loadingState === 0 || loadingState === 2) &&
                <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> {loadingState === 0 ? "getting report data..." : "Preparing Report"}</Label>
                    </div>
                </Col>
            }
            {loadingState == 3 && schoolData && schoolData.length > 0 &&  false &&<Card>
                <CardBody>
                    <CardHeader><CardTitle>{"Title: Corporate Attendance Report:"}</CardTitle></CardHeader>
                    <p>{"Report Period: " + Moment(selectedDate).format('DD MMM YYYY') + " to " + Moment(selectedEndDate).format('DD MMM YYYY')}</p>
                    <p>{"Corporate Partner: " + selctedCorporateObject.attributes.name}</p>

                    <p>{"Total Time: " + getHours(totalInTime)}</p>
                    <Col lg={12}>
                        <div className="mt-2 mb-2 p-1 d-flex float-sm-right">
                            <button
                                onClick={() => {
                                    if (schoolData && schoolData.length > 0) {
                                        onClickDownload();
                                    }
                                                            
                                }}
                                className="w-md  btn btn-primary"
                            >
                                <i className='bx bx-download font-size-16 align-middle mr-2'></i>
                                {props.t("Download Report")}
                            </button>
                        </div>
                    </Col>
                    <div className="table-rep-plugin">
                        <div className="table-responsive mb-0" data-pattern="priority-columns">
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th>School Name </Th>
                                        <Th>Corporate Partner</Th>
                                        <Th>Total students</Th>
                                        <Th>Total time  </Th>
                                       
                                    </Tr>
                                </Thead>
                                <Tbody>   
                                    {schoolData.map((val, key) => (
                                        <Tr key={val.id}>
                                            <Th>{val.school.attributes.Name}</Th>
                                                
                                           
                                            <Td>{selctedCorporateObject && selctedCorporateObject.attributes.name}</Td>
                                            <Td>{val.kidList.length}</Td>
                                            <Td>{getHours(val.schoolTime)}</Td>
                                        </Tr>
                                    ))}                     
                                </Tbody>
                            </Table>
                        </div>
                    </div>
                    {schoolData.map((val) => (
                        <div key={val.id} >
                            <CardTitle>{val.school.attributes.Name}</CardTitle>
                            <Table id="tech-companies-1" className="table table-striped table-bordered">
                                <Thead>
                                    <Tr>
                                        <Th> # </Th>
                                        <Th>Student Name</Th>
                                        <Th>Days</Th>
                                        <Th>Total time  </Th>
                                    </Tr>
                                </Thead>
                                <Tbody>
                                    {val.kidList && val.kidList.map((val2, key) => (
                                        <Tr key={val2.id}>
                                            <Th>{(key + 1)}</Th>
                                            <Td>{val2.kid && val2.kid.attributes.Name}</Td>
                                            <Td>{val2.attendanceObjects.length}</Td>
                                            <Td>{getHours(val2.time)}</Td>
                                        </Tr>
                                    ))}
                                </Tbody>
                            </Table>
                        </div>
                    ))}
                    
                </CardBody>
            </Card>}

            {loadingState == 3 && schoolData && calenderData && <Card>
                <CardBody>
                   
                    <CardTitle>{"Calender Data"}</CardTitle>
                    <p className='mb-0'>{"Report duration: " + Moment(selectedDate).format('DD MMM YYYY') + " to " + Moment(selectedEndDate).format('DD MMM YYYY')}</p>
                    <p className='mb-0'>{"Report For: " + selectedSchool.label}</p>
                    <p>{"Active Students:" + activeKids}</p>
                    <p>{"Total Checkins:" + totalCheckins}</p>

                     <Col lg={12}>
                        <div className="mt-2 mb-2 p-1 d-flex justify-content-end">
                            <button
                                onClick={() => {
                                    if (calenderData && calenderData.length > 0) {
                                        onClickDownload();
                                    }
                                                            
                                }}
                                className="w-md  btn btn-primary"
                            >
                                <i className='bx bx-download font-size-16 align-middle m-1'></i>
                                {props.t("Download Report")}
                            </button>
                        </div>
                    </Col>
                        
                    <div className="table-responsive mb-0" data-pattern="priority-columns">
                        <Table id="tech-companies-1" className="table table-striped table-bordered">

                            <Thead>
                                <Tr>
                                    {calenderTitles.map((val2, key) => (
                                        <Th key={key}>{val2}</Th>
                                    ))}
                                </Tr>
                            </Thead>
                            <Tbody>
                                {calenderData && calenderData.map((cData, key) => (
                                    <Tr key={key}>
                                        {cData.map((val2, key2) => (
                                            <Td key={key2}>{val2}</Td>
                                        ))}
                                    </Tr>
                                ))}
                            </Tbody>
                        </Table>
                    </div>
                   
                </CardBody>
            </Card>}
        </React.Fragment>
    );
}
            

const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { corporatePartners, corporatePartnerAddLoading } = state.CorporatePartners;
    return {
        schools, selectedSchool,
        franchise,
        corporatePartners, corporatePartnerAddLoading,
    };
}
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
     setSelecetdSchool,getCorporatePartners
    })(CorporatePartnerReport)
  )
);