import Parse from 'parse';
import { printLog } from './loghelper';
import * as Constants from '../../Constents';
export const getTripsForSchoolIdParse = (schoolId) => {
  printLog("HL: trips_helper : getTripsForSchoolIdParse")

  try {

    return new Promise((resolve, reject) => {

      var query = new Parse.Query('Trip');
      query.equalTo('schoolId', schoolId);
      query.exists('groupId');
      query.limit(2000);
      query.addAscending("createdAt")
      query.notEqualTo("isDeleted", true)
      query.find().then(
        (results) => {
          resolve(results)
        }
        , (error) => {
          resolve(null)
        }
      )

    });
  } catch (error) { }
}

export const resetTrip = (trip) => {
  printLog("HL: trips_helper : resetTrip")
  try {

    return new Promise((resolve, reject) => {

      var query = new Parse.Query('TripLog');
      query.equalTo('tripId', trip.id);
      let date = new Date
      var startDate = new Date(date.getFullYear(), date.getMonth(), date.getDate());
      var endDate = new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59, 59, 999);
      query.greaterThan('createdAt', startDate)
      query.lessThanOrEqualTo('createdAt', endDate)
      query.find().then(
        (results) => {
          
          if (results && results.length > 0) {
            let ul = []
            for (let tl of results) {
              tl.set('tripId', trip.id + "1")
              tl.set('isDeleted', true);
              ul.push(tl);
            }

            Parse.Object.saveAll(ul).then(
              (tls) => {

                trip.set('tripStatus', 0);
                trip.save();

                const TripTechnicalLog = Parse.Object.extend("TripTechnicalLog");
                const ttlObject = new TripTechnicalLog();
                ttlObject.set("tripId", trip.id);
                ttlObject.set("time", date);
                ttlObject.set("log", "Trip reset");
                ttlObject.save();
                
                resolve(trip)

              },
              (error) => {
              
              }
            );
          }
        }
        , (error) => {
          resolve(null)
        }
      )

    });
  } catch (error) { }
}

