import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import DatePicker from "react-datepicker";
import {
  Card,
  CardBody,
  Col,
  FormGroup,
  InputGroup,
  Label,
  Modal,
  Row,
  Table,
  Input,
  InputGroupText
} from "reactstrap";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import { createFeeTemplateForSchoolId } from "../../store/actions";
import toastr from "toastr";
// import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import Parse from "parse";
import Moment from "moment";
import Select from "react-select";

const NewTemplate = (props) => {
  const [newTemplateTog, newTemplateTogOps] = useState(false);
  const [templateItemList, templateItemListOps] = useState([]);
  const [newTemplateTotal, newTemplateTotalOps] = useState(0);
  const [templateTitle, templateTitleOps] = useState("");
  const [showCreateLoader, showCreateLoaderOps] = useState(false);
  const [templateId, templateIdOps] = useState();
  const [feeTemplateTypes, feeTemplateTypesOps] = useState([]);
  const [templateTitleError, templateTitleErrorOps] = useState("");
  // const [systemOptions, systemOptionsOps] = useState([])
  const [showRoyalty, setShowRoyalty] = useState(props.showRoyalty);
  const [royaltyAmount, setRoyaltyAmount] = useState(0);
  const [showTax, setShowTax] = useState(false);
  const [taxAmount, setTaxAmount] = useState(0);

  const feeType = [{ value: "Monthly", label: "Monthly" }, { value: "Term", label: "Term" }, { value: "Yearly", label: "Yearly" }];
  const programType = [{ value: "Half Day", label: "Half Day" }, { value: "Full Day", label: "Full Day" }, { value: "AFS-A", label: "AFS-A" }, { value: "AFS-B", label: "AFS-B" }];
  const [selectedFeeType, setSelectedFeeType] = useState('');
  const [selectedProgramType, setSelectedProgramType] = useState('');
  const [selectedCorporatePartner, setSelectedCorporatePartner] = useState(undefined);
  const [corporatePartnerList, setCorporatePartnerList] = useState([]);

  const isFranchiseTemplet = () => {
    if (
      Parse.User.current() && Parse.User.current().attributes.franchiseId &&
      Parse.User.current().attributes.franchiseId.length > 0 &&
      Parse.User.current().attributes.isSuperUser) {
      return true;
    } else {
      return false;
    }
  }
  useEffect(() => {
    setShowRoyalty(props.showRoyalty)
  }, [props.showRoyalty]);
  useEffect(() => {
    let temp = {
      label: "Fee Type",
      options: [],
    };
    let temp2 = {
      label: "Other Type",
      options: [],
    };
    let tempOtions = [];
    for (const val of props.feeTypes) {
      let tempIn = {};
      tempIn.label = val.attributes.name;
      tempIn.value = val.attributes.name;
      tempIn.id = val.id;
      temp.options.push(tempIn);
      tempOtions.push(val.attributes.name);
    }
    // systemOptionsOps(tempOtions)
    feeTemplateTypesOps([temp, temp2]);
  }, [props.feeTypes]);
  useEffect(() => {
    newTemplateTogOps(props.newTemplateTog);
  }, [props.newTemplateTog]);
  useEffect(() => {
    let list = [];
    for (const cor of props.corporatePartners) {
      list.push({
        value: cor.id,
        label: cor.attributes.name,
        object: cor,
      });
    }
    setCorporatePartnerList(list)
  }, [props.corporatePartners]);
  useEffect(() => {
    if (props.templateObj ? props.templateObj.items : false) {
      templateIdOps(props.templateObj.id);
      templateTitleOps(props.templateObj.name);
      let tempItems = [];
      let totalAmount = 0;
      let totalRoyalty = 0;
      let totalTax = 0;
      if (props.templateObj.Obj.attributes.feeType) {
        setSelectedFeeType({ value: props.templateObj.Obj.attributes.feeType, label: props.templateObj.Obj.attributes.feeType })
      }
      if (props.templateObj.Obj.attributes.programType) {
        setSelectedProgramType({ value: props.templateObj.Obj.attributes.programType, label: props.templateObj.Obj.attributes.programType })
      }
      if (props.templateObj.Obj.attributes.corporateId) {
        for (const cor of props.corporatePartners) {
          if (cor.id == props.templateObj.Obj.attributes.corporateId) {
            setSelectedCorporatePartner({
              value: cor.id,
              label: cor.attributes.name,
              object: cor,
            });
          }
        }
      }
      for (const val of props.templateObj.items) {
        let temp = {};
        temp.id = val.id;
        if (val.attributes.feeType) {
          if (feeTemplateTypes && feeTemplateTypes.length > 0) {
            for (const val1 of feeTemplateTypes[0].options) {
              if (val.attributes.feeType === val1.id) {
                temp.itemDescription = val1;
              }
            }
          }
        } else {
          let tempIn = {};
          tempIn.label = val.attributes.itemDescription;
          tempIn.value = val.attributes.itemDescription;
          temp.itemDescription = tempIn;
          feeTemplateTypes && feeTemplateTypes.length > 1 && feeTemplateTypes[1].options.push(tempIn);
        }
        temp.duedate = val.attributes.dueDate;
        temp.amount = val.attributes.amount;
        temp.discount = +val.attributes.discount;
        temp.discountType = +val.attributes.discountType;
        temp.discountRemark = val.attributes.discountRemark;
        if (temp.discount > 0) {
          temp.discountDisabled = true;
        } else {
          temp.discountDisabled = false;
        }
        temp.totalAmount =
          (temp.discountType === 1 || temp.discountType === "1")
            ? temp.amount - temp.discount
            : temp.amount - (temp.amount * temp.discount) / 100;

        temp.royalty = val.attributes.royalty;
        if (temp.royalty > 0) {
          temp.royaltyAmount = (temp.totalAmount * temp.royalty) / 100
          totalRoyalty += temp.royaltyAmount;
        } else {
          temp.royaltyAmount = 0;
        }
        temp.hsn = val.attributes.hsn;
        temp.tax = val.attributes.tax ?? 0;
        if (temp.tax > 0) {
          temp.taxAmount = (temp.totalAmount * temp.tax) / 100;
          totalTax = totalTax + temp.taxAmount;
          setShowTax(true);
        } else {
          temp.taxAmount = 0;
        }
        totalAmount += temp.totalAmount;
        tempItems.push(temp);
      }
      setRoyaltyAmount(totalRoyalty);
      setTaxAmount(totalTax);
      newTemplateTotalOps(totalAmount);
      templateItemListOps(tempItems);
    } else {
      templateIdOps("");
      templateTitleOps("");
      newTemplateTotalOps(0);
      templateItemListOps([]);
    }
  }, [props.templateObj]);
  useEffect(() => {
    if (showCreateLoader) {
      if (!props.showCreateLoader) {
        props.loadAllTemplates();
        if (newTemplateTog) {
          props.tog_newTemplate();
        }
        let title =
          props.title === "New Template"
            ? "Template Created Successfully"
            : "Template Updated Successfully";
        toastr.success(title, { timeOut: 2000 });
      }
    }
    showCreateLoaderOps(props.showCreateLoader);
  }, [props.showCreateLoader]);
  const addAnItemToInvoice = () => {
    let tempItems = Object.assign([], templateItemList);
    let temp = {};
    temp.itemDescription = "";
    temp.duedate = new Date();
    temp.slno = tempItems.length;
    temp.amount = 0;
    temp.discount = 0;
    temp.discountType = 1;
    temp.discountRemark = "";
    temp.discountDisabled = true;
    temp.totalAmount = 0;
    tempItems.push(temp);
    templateItemListOps(tempItems);
  };
  const updateInvoiceData = (key, val, updatedValue) => {
    let tempItems = Object.assign([], templateItemList);
    let totalAmount = 0;
    let totalRoyalty = 0;
    let totalTax = 0;
    tempItems[key][val] = updatedValue;
    for (let i in tempItems) {
      if (!tempItems[i].deleted) {
        tempItems[i].totalAmount =
          tempItems[i].discountType == 1
            ? tempItems[i].amount - tempItems[i].discount
            : tempItems[i].amount -
            (tempItems[i].amount * tempItems[i].discount) / 100;
        totalAmount += tempItems[i].totalAmount;
        tempItems[i].discountDisabled =
          tempItems[i].discount > 0 ? true : false;
        tempItems[i].discountRemark = tempItems[i].discountDisabled
          ? tempItems[i].discountRemark
          : "";
        if (tempItems[i].royalty > 0) {
          tempItems[i].royaltyAmount = (tempItems[i].totalAmount * tempItems[i].royalty) / 100

          totalRoyalty += tempItems[i].royaltyAmount;
        } else {
          tempItems[i].royaltyAmount = 0;
        }
        if (tempItems[i].tax > 0) {
          tempItems[i].taxAmount = (tempItems[i].totalAmount * tempItems[i].tax) / 100;
          totalTax = totalTax + tempItems[i].taxAmount;
        } else {
          tempItems[i].taxAmount = 0;
        }

      }
    }
    setRoyaltyAmount(totalRoyalty);
    setTaxAmount(totalTax);
    newTemplateTotalOps(totalAmount);
    templateItemListOps(tempItems);
  };
  const removeItemFromNewInvoice = (key) => {
    let tempItems = Object.assign([], templateItemList);
    let totalAmount = 0;
    let totalRoyalty = 0;
    let totalTax = 0;
    if (tempItems[key].id) {
      tempItems[key].deleted = true;
    } else {
      tempItems.splice(key, 1);
    }
    for (let i in tempItems) {
      if (!tempItems[i].deleted) {
        tempItems[i].totalAmount =
          tempItems[i].discountType == 1
            ? tempItems[i].amount - tempItems[i].discount
            : tempItems[i].amount -
            (tempItems[i].amount * tempItems[i].discount) / 100;
        totalAmount += tempItems[i].totalAmount;
        if (tempItems[i].royalty > 0) {
          tempItems[i].royaltyAmount = (tempItems[i].totalAmount * tempItems[i].royalty) / 100

          totalRoyalty += tempItems[i].royaltyAmount;
        } else {
          tempItems[i].royaltyAmount = 0;
        }

        if (tempItems[i].tax > 0) {
          tempItems[i].taxAmount = (tempItems[i].totalAmount * temp.tax) / 100;
          totalTax = totalTax + temp.taxAmount;
        } else {
          tempItems[i].taxAmount = 0;
        }
      }
    }
    setRoyaltyAmount(totalRoyalty);
    newTemplateTotalOps(totalAmount);
    templateItemListOps(tempItems);
  };
  const handleInputError = () => {
    let tempItems = Object.assign([], templateItemList);
    for (const val of templateItemList) {
      if (!val.itemDescription) {
        val.error = "Enter Particular";
      } else {
        val.error = "";
      }
    }
    templateItemListOps(tempItems);
  };
  const createTemplate = () => {
    let errorCount = 0;
    let tempItems = Object.assign([], templateItemList);
    if (templateTitle.trim().length === 0) {
      templateTitleErrorOps("Please enter the template title");
      errorCount++;
    } else {
      templateTitleErrorOps("");
    }
    for (const val of tempItems) {
      if (!val.itemDescription) {
        val.errorTitle = "Enter Particular";
        errorCount++;
      } else {
        val.errorTitle = "";
      }
      if (val.amount <= 0) {
        val.errorAmount = "Enter the amount";
        errorCount++;
      } else {
        val.errorAmount = "";
      }
    }
    templateItemListOps(tempItems);
    if (errorCount === 0) {
      let tempItem = [];
      for (const val of templateItemList) {
        let temp = {};
        if (typeof val.itemDescription === "object") {
          temp.itemDescription = val.itemDescription.label;
          temp.feeType = val.itemDescription.id;
        } else {
          temp.itemDescription = val.itemDescription;
        }
        if (val.id) {
          temp.id = val.id;
          temp.deleted = val.deleted;
        }
        temp.slno = val.slno;
        temp.amount = val.amount;
        temp.discount = val.discount;
        temp.discountRemark = val.discountRemark;
        temp.discountType = val.discountType;
        temp.duedate = val.duedate;
        temp.royalty = val.royalty;
        temp.totalAmount = val.totalAmount;
        temp.hsn = val.hsn;
        if (showTax) {
          temp.tax = val.tax;
        } else {
          temp.tax = 0;
        }
        tempItem.push(temp);
      }
      let pram = {};
      pram.id = templateId;
      pram.title = templateTitle.trim();
      pram.school_id = props.schoolId;
      pram.type = isFranchiseTemplet() && showRoyalty ? 2 : 1;
      pram.items = tempItem;
      pram.royaltyAmount = royaltyAmount;
      if (props.showCorporatePartners) {
        pram.feeType = selectedFeeType && selectedFeeType.value;
        pram.programType = selectedProgramType && selectedProgramType.value
        pram.corporateId = selectedCorporatePartner && selectedCorporatePartner.value
      }
      props.createFeeTemplateForSchoolId(pram);
    }
  };
  const getCorporatePartnerName = (id) => {
    if (id && props.corporatePartners) {
      for (const cp of props.corporatePartners) {
        if (cp.id == id) {
          return cp.attributes.name;
        }
      }
    }
    return "--"
  }
  return (
    <Modal
      isOpen={newTemplateTog}
      toggle={() => {
        props.tog_newTemplate();
      }}
      scrollable={false}
      size="xl"
    >
      <div className="modal-header">
        <h5 className="modal-title mt-0" id="myModalLabel">
          {props.t(props.title)}
        </h5>
        <button
          type="button"
          onClick={() => {
            props.tog_newTemplate();
          }}
          className="close"
          data-dismiss="modal"
          aria-label="Close"
          disabled={showCreateLoader}
        >
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
        {/* <Card>
          <CardBody> */}
        <AvForm className="outer-repeater">
          <Row>
            <Col lg={10}>
              <FormGroup className="mt-2 mb-2 p-1">
                <Label className="mb-0">
                  {props.t("Template Title")} :
                </Label>
                <input
                  type="text"
                  className="form-control"
                  value={templateTitle}
                  onChange={(e) => {
                    templateTitleOps(e.target.value);
                    e.target.value
                      ? templateTitleErrorOps("")
                      : templateTitleErrorOps(
                        "Please enter the template title"
                      );
                  }}
                  disabled={props.actionType == 'view' ? true : false}
                  placeholder={props.t("Daycare fee / Admission fee")}
                />
                <small className="text-danger">{templateTitleError}</small>
              </FormGroup>
            </Col>
            {props.actionType != 'view' && false && <Col lg={2}>
              <FormGroup className="mt-2 mb-2 p-1">
                <div className="custom-control custom-switch mb-2" dir="ltr">
                  <input type="checkbox" className="custom-control-input" id="reccuringModal" checked={showTax} />
                  <label className="custom-control-label" htmlFor="reccuringModal" onClick={(e) => { setShowTax(!showTax); }}>GST</label>
                </div>


              </FormGroup>
            </Col>}
          </Row>
          {(props.selectedSchool && (props.selectedSchool.attributes.franchiseId == "sP8TeR7BSG"
            || props.selectedSchool.attributes.franchiseId == "AwiwOAmsEf1")) && <Row>
              <Col lg={4}>
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">
                    {props.t("Fee Type")} :
                  </Label>
                  <Select
                    value={selectedFeeType}
                    onChange={(v) => { setSelectedFeeType(v) }}
                    options={feeType}
                    disabled={props.actionType == 'view' ? true : false}
                  />

                </FormGroup>
              </Col>
              <Col lg={4}>
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">
                    {props.t("Program Type")} :
                  </Label>

                  <Select
                    value={selectedProgramType}
                    onChange={(v) => { setSelectedProgramType(v) }}
                    options={programType}
                    disabled={props.actionType == 'view' ? true : false}
                  />


                </FormGroup>
              </Col>

              <Col lg={4}>
                <FormGroup className="mt-2 mb-2 p-1">
                  <Label className="mb-0">
                    {props.t("Corporate")} :
                  </Label>

                  <Select
                    value={selectedCorporatePartner}
                    onChange={(v) => { setSelectedCorporatePartner(v) }}
                    options={corporatePartnerList}
                    disabled={props.actionType == 'view' ? true : false}
                  />


                </FormGroup>
              </Col>

            </Row>}
          <Row>
            <Col className="table-responsive">
              <Table className="table table-nowrap mb-0">
                <thead>
                  <tr>
                    <th>{props.t("Slno")}</th>
                    <th style={{ width: "12rem" }}>
                      {props.t("Particular")}
                    </th>
                    <th>{props.t("Due Date")}</th>
                    <th>{props.t("Amount")}</th>
                    <th>{props.t("Discount")}</th>
                    <th>{props.t("Discount Remark")}</th>
                    {showRoyalty && <th>{props.t("Royalty")}</th>}
                    {showTax && <th>{props.t("GST")}</th>}
                    <th>{props.t("Item Total")}</th>
                  </tr>
                </thead>
                {props.actionType == 'view' ? <tbody>
                  {templateItemList.map(
                    (val, key) =>
                      !val.deleted && (
                        <tr key={key}>
                          <td>{key + 1}</td>
                          <td>
                            {val.itemDescription.label}
                            {val.hsn && <p className="text-size-12">{"HSN: " + val.hsn}</p>}
                          </td>
                          <td>
                            {val.duedate && Moment(val.duedate).format("DD/MM/YYYY")}
                          </td>
                          <td>
                            {val.amount}
                          </td>
                          <td style={{ minWidth: "7rem" }}>
                            {val.discountType == 2 && val.discount + " %"}
                            {val.discountType == 1 && props.currency + " " + val.discount}
                          </td>
                          <td>
                            {val.discountRemark}
                          </td>
                          {
                            showRoyalty && <td style={{ minWidth: "7rem" }}>

                              {val.royalty + '%'}
                              {val.royalty > 0 && <p className="text-size-12">{props.currency}{val.royaltyAmount ?? 0}</p>}
                            </td>
                          }

                          {
                            showTax && <td style={{ minWidth: "7rem" }}>

                              {val.tax + '%'}
                              {val.tax > 0 && <p className="text-size-12">{props.currency}{val.taxAmount ?? 0}</p>}
                            </td>
                          }
                          <td>
                            {parseFloat(val.totalAmount).toFixed(2)}
                          </td>

                        </tr>
                      )
                  )}
                  {templateItemList.length > 0 ? <tr>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td><b>Total</b></td>
                    {showRoyalty && <td><b>{parseFloat(royaltyAmount).toFixed(2)}</b></td>}
                    {showTax && <td><b>{parseFloat(taxAmount).toFixed(2)}</b></td>}
                    <td>
                      <b>{parseFloat(newTemplateTotal).toFixed(2)}</b>
                    </td>
                  </tr> :

                    <tr>
                      <td
                        className="text-center text-Secondary h5 pt-5"
                        colSpan="7"
                      >
                        {props.t("No items in this template ")}{" "}

                      </td>
                    </tr>
                  }
                </tbody> :
                  <tbody>
                    {templateItemList.map(
                      (val, key) =>
                        !val.deleted && (
                          <tr key={key}>
                            <td>{key + 1}</td>
                            <td>
                              {/* <FormGroup> */}
                              <CreatableSelect
                                isClearable
                                value={val.itemDescription}
                                onChange={(e) => {
                                  updateInvoiceData(
                                    key,
                                    "itemDescription",
                                    e
                                  );
                                  handleInputError();
                                }}
                                options={feeTemplateTypes}
                              // onInputChange={handleInputChange}
                              />
                              <small className="text-danger">
                                {val.errorTitle}
                              </small>
                              {(showTax || true) && <FormGroup>
                                <AvField
                                  type="text"
                                  name="name"
                                  placeholder={props.t("HSN")}
                                  className="form-control mt-2"
                                  value={val.hsn}

                                  onChange={(e) => {
                                    updateInvoiceData(
                                      key,
                                      "hsn",
                                      e.target.value
                                    );
                                  }}
                                />
                              </FormGroup>}

                            </td>
                            <td>
                              <FormGroup>
                                <DatePicker
                                  selected={val.duedate}
                                  onChange={(date) => {
                                    updateInvoiceData(key, "duedate", date);
                                  }}
                                  className="form-control"
                                  placeholderText={props.t("Selecte Date")}
                                  startDate={val.duedate}
                                  minDate={new Date()}
                                  dateFormat="dd/MM/yyyy"
                                />
                              </FormGroup>
                            </td>
                            <td style={{ minWidth: "6rem" }}>
                              <input
                                type="00"
                                name="name"
                                placeholder={props.t("Item amount...")}
                                className="form-control mb-0"
                                value={val.amount}
                                min={0}

                                onChange={(e) => {
                                  updateInvoiceData(
                                    key,
                                    "amount",
                                    e.target.value
                                  );
                                }}
                              />
                              {/* </FormGroup> */}
                              <small className="text-danger mt-0">
                                {val.errorAmount}
                              </small>
                            </td>
                            <td style={{ minWidth: "7rem" }}>
                              <div className="input-group">
                                <input
                                  type="number"
                                  className="form-control"
                                  value={val.discount}
                                  placeholder=""
                                  min={0}
                                  onChange={(e) => {
                                    updateInvoiceData(key, "discount", e.target.value);
                                  }}
                                />
                                <select
                                  value={val.discountType}
                                  onChange={(e) => {
                                    updateInvoiceData(key, "discountType", e.target.value);
                                  }}
                                  className="form-select"
                                >
                                  <option value="2">%</option>
                                  <option value="1">{props.currency}</option>
                                </select>
                              </div>
                            </td>
                            <td>
                              <FormGroup>
                                <AvField
                                  type="text"
                                  name="name"
                                  placeholder={props.t("Remark...")}
                                  className="form-control"
                                  disabled={val.discountEnabled}
                                  value={val.discountRemark}

                                  onChange={(e) => {
                                    updateInvoiceData(
                                      key,
                                      "discountRemark",
                                      e.target.value
                                    );
                                  }}
                                />
                              </FormGroup>
                            </td>
                            {
                              showRoyalty && (
                                <td style={{ minWidth: "6rem" }}>
                                  <div className="input-group">
                                    <input
                                      type="number"
                                      className="form-control"
                                      value={val.royalty}
                                      placeholder="00"
                                      min={0}
                                      onChange={(e) => {
                                        updateInvoiceData(key, "royalty", e.target.value);
                                      }}
                                    />
                                    <div className="input-group-append">
                                      <select
                                        value={val.royaltyType}
                                        onChange={(e) => {
                                          updateInvoiceData(key, "royaltyType", e.target.value);
                                        }}
                                        className="form-select" // Add this class for better styling
                                      >
                                        <option value="2">%</option>
                                      </select>
                                    </div>
                                  </div>
                                  {val.royalty > 0 && (
                                    <p className="text-center text-size-12">
                                      {props.currency}
                                      {val.royaltyAmount ?? 0}
                                    </p>
                                  )}
                                </td>
                              )
                            }

                            {showTax && <td>
                              <InputGroup>


                                <select
                                  value={val.tax}
                                  className='form-control'
                                  onChange={(e) => {
                                    updateInvoiceData(
                                      key,
                                      "tax",
                                      e.target.value === "0" ? undefined : parseInt(e.target.value)
                                    );
                                  }}
                                  style={{ fontFamily: "Arial, sans-serif" }}
                                >
                                  <option value="0">0%</option>
                                  <option value="5">5%</option>
                                  <option value="12">12%</option>
                                  <option value="18">18%</option>
                                  <option value="28">28%</option>

                                </select>


                              </InputGroup>
                              {val.tax > 0 && <p className="text-center text-size-12">{props.currency}{val.taxAmount ?? 0}</p>}
                            </td>}
                            <td>
                              <FormGroup>
                                <AvField
                                  type="number"
                                  name="name"
                                  placeholder={props.t("Total...")}
                                  className="form-control"
                                  disabled={true}
                                  value={parseFloat(val.totalAmount).toFixed(
                                    2
                                  )}

                                />
                              </FormGroup>
                            </td>
                            <td>
                              <i
                                onClick={() => removeItemFromNewInvoice(key)}
                                className="bx bx-message-square-x text-danger h3 mt-1"
                                style={{ cursor: "pointer" }}
                              ></i>
                            </td>
                          </tr>
                        )
                    )}
                    {templateItemList.length === 0 && (
                      <tr>
                        <td
                          className="text-center text-Secondary h5 pt-5"
                          colSpan="7"
                        >
                          {props.t("To create template start")}{" "}
                          <span
                            className="text-info"
                            onClick={addAnItemToInvoice}
                            style={{ cursor: "pointer" }}
                          >
                            <button className="btn btn-info">
                              {" "}
                              {props.t("Adding items")}{" "}
                            </button>
                          </span>
                        </td>
                      </tr>
                    )}
                    {templateItemList.length > 0 && (
                      <tr>
                        <td colSpan={(showRoyalty && showTax) ? "8" : ((showRoyalty || showTax) ? "7" : "6")}></td>
                        <td>
                          <button
                            className="btn btn-info"
                            onClick={addAnItemToInvoice}
                            style={{ width: "100%" }}
                          >
                            + Add Item
                          </button>
                        </td>
                      </tr>
                    )}
                    {templateItemList.length > 0 && (
                      <tr>
                        <td colSpan={showRoyalty ? "6" : "6"}></td>
                        {showRoyalty && <td className="pb-0">
                          <FormGroup className="mb-0">
                            <Label className="mb-0">
                              {props.t("Royalty Amount")} :
                            </Label>
                            <input
                              type="number"
                              className="form-control mb-0"
                              value={parseFloat(royaltyAmount).toFixed(2)}
                              disabled={true}
                            ></input>
                          </FormGroup>
                        </td>}
                        {showTax && <td className="pb-0">
                          <FormGroup className="mb-0">
                            <Label className="mb-0">
                              {props.t("Tax Amount")} :
                            </Label>
                            <input
                              type="number"
                              className="form-control mb-0"
                              value={parseFloat(taxAmount).toFixed(2)}
                              disabled={true}
                            ></input>
                          </FormGroup>
                        </td>}
                        <td className="pb-0">
                          <FormGroup className="mb-0">
                            <Label className="mb-0">
                              {props.t("Total Amount")} :
                            </Label>
                            <input
                              type="number"
                              className="form-control mb-0"
                              value={parseFloat(newTemplateTotal).toFixed(2)}
                              disabled={true}
                            ></input>
                          </FormGroup>
                        </td>
                      </tr>
                    )}
                  </tbody>}
              </Table>
            </Col>
          </Row>
        </AvForm>
        {/* </CardBody>
        </Card> */}
      </div>
      {props.actionType != 'view' && <div className="modal-footer">
        <div className="modal-footer table-responsive">
          <Table className="table table-nowrap table-borderless mb-0">
            <thead>
              <tr>
                <td style={{ minWidth: "7rem" }}></td>
                <td style={{ minWidth: "8rem" }}></td>
                <td style={{ minWidth: "8rem" }}></td>
                <td style={{ minWidth: "8rem" }}></td>
                <td style={{ minWidth: "8rem" }}></td>
                <td>
                  <button
                    type="button"
                    onClick={() => {
                      props.tog_newTemplate();
                    }}
                    className="btn btn-secondary waves-effect"
                    data-dismiss="modal"
                    style={{ width: "100%" }}
                    disabled={showCreateLoader}
                  >
                    {props.t("Close")}
                  </button>
                </td>
                <td>
                  <button
                    type="button"
                    className="btn btn-primary waves-effect waves-light"
                    onClick={createTemplate}
                    disabled={templateItemList.length === 0}
                  >
                    {showCreateLoader && (
                      <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                    )}
                    {props.t(
                      props.title === "New Template"
                        ? "Create Template"
                        : "Update Template"
                    )}
                  </button>
                </td>
                <td></td>
              </tr>
            </thead>
          </Table>
        </div>
      </div>}
    </Modal>
  );
};

const mapStatetoProps = (state) => {
  const { showCreateLoader } = state.FeeTemplate;
  return { showCreateLoader };
};
export default connect(mapStatetoProps, { createFeeTemplateForSchoolId })(
  withRouter(withNamespaces()(NewTemplate))
);
