import React, { useEffect, useState } from "react";
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
} from "reactstrap";
import Breadcrumb from "../../components/Common/Breadcrumb";
import AvForm from "availity-reactstrap-validation/lib/AvForm";
import AvField from "availity-reactstrap-validation/lib/AvField";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import {
  getClassroomsForSchoolId,
  addAKid,
  addParentsToDB,
  setSelecetdSchool,
  getCorporatePartners,
} from "../../store/actions";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import Parse from "parse";
import Select from "react-select";
import Licence from '../../components/Common/Licence'

const AddNewKid = (props) => {
  const [classList, classListOps] = useState([]);
  const [selectedClass, selectedClassOps] = useState("");
  const [childName, childNameOps] = useState("");
  const [admissionNumber, admissionNumberOps] = useState("");
  const [gender, genderOps] = useState("Female");
  const [motherName, motherNameOps] = useState("");
  const [motherNumber, motherNumberOps] = useState("");
  const [motherEmail, motherEmailOps] = useState("");
  const [fatherName, fatherNameOps] = useState("");
  const [fatherNumber, fatherNumberOps] = useState("");
  const [fatherEmail, fatherEmailOps] = useState("");
  const [dob, setDOB] = useState("");

  const [errorName, errorNameOps] = useState("");
  const [motherError, motherErrorOps] = useState("");
  const [fatherError, fatherErrorOps] = useState("");
  const [classError, classErrorOps] = useState("");

  const [showCorporatePartners, setShowCorporatePartners] = useState(false);
  const [allCorporatePartners, setAllCorporatePartners] = useState([]);
  const [selectedCorporatePartners, setSelectedCorporatePartners] = useState('');
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [loding, setLoading] = useState(false);

  const match1 = matchPath(props.history.location.pathname, {
    path: "/addNewKid/:id",
    exact: true,
    strict: false,
  });
  const match2 = matchPath(props.history.location.pathname, {
    path: "/addNewKid/:id/:classroomId",
    exact: true,
    strict: false,
  });
  const match = match1 ?? match2
  const schoolId = match.params.id;
  const classroomId = match.params.classroomId;

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool, schoolId]);

  useEffect(() => {
    props.getClassroomsForSchoolId(schoolId);
  }, [schoolId]);
  useEffect(() => {
    if (classroomId) {
      selectedClassOps(classroomId)
    }
  }, [classroomId]);
  useEffect(() => {

    if (props.franchise &&
      props.franchise.attributes.features &&
      props.franchise.attributes.features.length > 1 &&
      props.franchise.attributes.features[4] > 0) {
      setShowCorporatePartners(true);
      props.getCorporatePartners(props.franchise.id, "franchiseId")

    } else {
      setShowCorporatePartners(false);
    }
  }, [props.franchise]);
  useEffect(() => {
    if (props.corporatePartners) {
      let list = [];
      for (const corporate of props.corporatePartners) {
        list.push({ value: corporate.id, label: corporate.attributes.name })
      }
      setAllCorporatePartners(list);
    }

  }, [props.corporatePartners]);
  useEffect(() => {
    if (props.classrooms.length > 0) {
      let temp = [];
      for (let i in props.classrooms) {
        let tempC = {};
        tempC.obj = props.classrooms[i];
        tempC.id = props.classrooms[i].id;
        tempC.name = props.classrooms[i].attributes.Name;
        temp.push(tempC);
      }
      classListOps(temp);
    }
  }, [props.classrooms]);

  const verifyContent=()=>{

    let errorCount = 0;
    if (!motherName && !fatherName) {
      errorCount++;
      fatherErrorOps("Enter the parents details");
    } else {
      fatherErrorOps("");
    }
    if (motherName && motherName.trim().length > 0 || fatherName && fatherName.trim().length > 0) {
      let gotMotherDetails = false;
      let goFatherDetails = false;
      if (motherName) {
        if (!motherNumber && !motherEmail) {
          errorCount++;
          motherErrorOps("Please enter the mother details");
        } else {
          gotMotherDetails = true;
          motherErrorOps("");
        }
      }
      if (fatherName) {
        if (!gotMotherDetails) {
          if (!fatherNumber && !fatherEmail) {
            errorCount++;
            fatherErrorOps("Please enter the father details");
          } else {
            goFatherDetails = true;
            fatherErrorOps("");
          }
        }
      }
      if (!gotMotherDetails && !goFatherDetails) {
        fatherErrorOps("Please enter the parents details");
        motherErrorOps("");
      }
    }
    if (!childName) {
      errorCount++;
      errorNameOps("Please enter the name");
    } else {
      errorNameOps("");
    }
    if (!selectedClass) {
      errorCount++;
      classErrorOps("Please select class");
    } else {
      classErrorOps("");
    }

    return errorCount
  }
  const submitToSave = () => {
    let errorCount = verifyContent();
   
    if (errorCount === 0) {
      let temp = {};
      temp.selectedClass = selectedClass;
      temp.childName = childName;
      temp.admissionNumber = admissionNumber;
      temp.gender = gender;
      temp.motherName = motherName;
      temp.motherNumber = motherNumber;
      temp.motherEmail = motherEmail;
      temp.fatherName = fatherName;
      temp.fatherNumber = fatherNumber;
      temp.fatherEmail = fatherEmail;
      temp.schoolId = schoolId;
      if(showCorporatePartners){
        temp.corporateId = selectedCorporatePartners.value;
      }
      temp.UUID = generateUUID();
      temp.dob = dob;
      props.addAKid(temp);
      let parentsObjs = [];
      let Parents = Parse.Object.extend("Parent");
      var parent = new Parents();
      parent.set("Name", motherName);
      parent.set("PhoneNumber", motherNumber);
      parent.set("EMail", motherEmail);
      parent.set("Relation", "Mother");
      parent.set("FamilyID", temp.UUID);
      parentsObjs.push(parent);
      var parent = new Parents();
      parent.set("Name", fatherName);
      parent.set("PhoneNumber", fatherNumber);
      parent.set("EMail", fatherEmail);
      parent.set("Relation", "Father");
      parent.set("FamilyID", temp.UUID);
      parentsObjs.push(parent);
      props.addParentsToDB(parentsObjs);
      props.history.push(`/classroom/${schoolId}/${selectedClass}`);
    }
  };
  const generateUUID = () => {
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
      /[xy]/g,
      function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == "x" ? r : (r & 0x3) | 0x8;
        return v.toString(16);
      }
    );
  };
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumb title="Add New Student" breadcrumbItem="Edit" />
          <Row>
            <Col lg={12}>
              <Card>
                <CardBody>
                  <AvForm
                    className="outer-repeater"
                    onSubmit={(e) => {
                      e.preventDefault();

                      if(verifyContent()==0){
                        setShowLimitModal(true);
                        setLoading(true)}
                      }
                      
                    }
                  >
                    <div data-repeater-list="outer-group" className="outer">
                      <div data-repeater-item className="outer">
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Name")}
                            {/* <span className="text-danger h6">{errorName}</span> */}
                          </Label><Label className="text-danger"> {" *"}</Label>
                          <AvField
                            type="text"
                            name="name"
                            placeholder={props.t("Enter Name...")}
                            className="form-control"
                            value={childName}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t("Please enter Name..."),
                              },
                            }}
                            onChange={(e) => {
                              childNameOps(e.target.value);
                              //   errorNameOps("");
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Admission Number")}:
                            (Leave empty for autogeneration)
                          </Label>
                          <AvField
                            type="text"
                            name="admissionNumber"
                            placeholder={props.t("Enter Admission Number...")}
                            className="form-control"
                            value={admissionNumber}
                            // validate={{
                            //   required: {
                            //     value: true,
                            //     errorMessage: props.t(
                            //       "Please Enter Admission Number..."
                            //     ),
                            //   },
                            // }}
                            onChange={(e) => {
                              admissionNumberOps(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Gender")}
                          </Label>
                          <Label className="text-danger"> {" *"}</Label>
                          <FormGroup>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                genderOps(e.target.value);
                              }}
                              value={gender}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Please select gender..."
                                  ),
                                },
                              }}
                            >
                              {gender === "" && <option key={0}></option>}
                              <option key={1} value="Male">
                                MALE
                              </option>
                              <option key={2} value="Female">
                                FEMALE
                              </option>
                            </select>
                          </FormGroup>
                        </FormGroup>
                        <FormGroup>
                          <Label> {props.t("Date of birth")}</Label>
                          <Label className="text-danger"> {" *"}</Label>
                          <AvField
                            type="date"
                            name="dobForNewKid"
                            className="inner form-control"
                            placeholder="dd/mm/yyyy"
                            value={dob}
                            onChange={(e) => {
                              setDOB(e.target.value);
                            }}
                            validate={{
                              required: {
                                value: true,
                                errorMessage: props.t(
                                  "Please select Date of Birth..."
                                ),
                              },
                            }}
                          />
                        </FormGroup>
                        <p className="text-dark"><u>{props.t('Any one parent details are mandatory')}</u></p>
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Mother's Name")}:
                          </Label>
                          <AvField
                            type="text"
                            name="addNewKidMotherName"
                            placeholder={props.t("Enter Mother's Name...")}
                            className="form-control"
                            value={motherName}
                            // validate={{
                            //   required: {
                            //     value: true,
                            //     errorMessage: props.t(
                            //       "Please Enter Mother's Name..."
                            //     ),
                            //   },
                            // }}
                            onChange={(e) => {
                              motherNameOps(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Mother's Phone Number")}:
                          </Label>
                          <AvField
                            //type="number"
                            name="addNewKidMotherPhoneNumber"
                            placeholder={props.t("Enter Phone Number...")}
                            className="form-control"
                            value={motherNumber}
                            validate={{
                              // required: {
                              //   value: true,
                              //   errorMessage: props.t(
                              //     "Please Enter Phone Number..."
                              //   ),
                              // },
                              pattern: {
                                value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                                errorMessage: props.t(
                                  "You have entered an invalid phone no!"
                                ),
                              },
                              minLength: { value: 10 },
                              maxLength: { value: 16 },
                            }}
                            onChange={(e) => {
                              motherNumberOps(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Mother's Email")}:
                          </Label>
                          <AvField
                            type="text"
                            name="addNewKidMotherEmail"
                            placeholder={props.t("Enter Email...")}
                            className="form-control"
                            value={motherEmail}
                            // validate={{
                            //   required: {
                            //     value: true,
                            //     errorMessage: props.t("Please Enter Email..."),
                            //   },
                            // }}
                            onChange={(e) => {
                              motherEmailOps(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <p
                          className="text-danger mt-0 pt-0"
                          style={{ fontSize: "11px" }}
                        >
                          {motherError}
                        </p>
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Father's Name")}:
                          </Label>
                          <AvField
                            type="text"
                            name="addNewKidFatherName"
                            placeholder={props.t("Enter Father's Name...")}
                            className="form-control"
                            value={fatherName}
                            // validate={{
                            //   required: {
                            //     value: true,
                            //     errorMessage: props.t(
                            //       "Please Enter Father's Name..."
                            //     ),
                            //   },
                            // }}
                            onChange={(e) => {
                              fatherNameOps(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Father's Phone Number")}:
                          </Label>
                          <AvField
                            //type="number"
                            name="addNewKidFatherPhoneNumber"
                            placeholder={props.t("Enter Phone Number...")}
                            className="form-control"
                            value={fatherNumber}
                            validate={{
                              // required: {
                              //   value: true,
                              //   errorMessage: props.t(
                              //     "Please Enter Phone Number..."
                              //   ),
                              // },
                              pattern: {
                                value: "^([0|+[0-9]{1,5})?([1-9][0-9]{9})$",
                                errorMessage: props.t(
                                  "You have entered an invalid phone no!"
                                ),
                              },
                              minLength: { value: 10 },
                              maxLength: { value: 16 },
                            }}
                            onChange={(e) => {
                              fatherNumberOps(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <FormGroup>
                          <Label htmlFor="formname">
                            {props.t("Father's Email")}:
                          </Label>
                          <AvField
                            type="text"
                            name="addNewKidEmail"
                            placeholder={props.t("Enter Email...")}
                            className="form-control"
                            value={fatherEmail}
                            // validate={{
                            //   required: {
                            //     value: true,
                            //     errorMessage: props.t("Please Enter Email..."),
                            //   },
                            // }}
                            onChange={(e) => {
                              fatherEmailOps(e.target.value);
                            }}
                          />
                        </FormGroup>
                        <p
                          className="text-danger mt-0 pt-0"
                          style={{ fontSize: "11px" }}
                        >
                          {fatherError}
                        </p>
                        <FormGroup>
                          <Label htmlFor="formname">{props.t("Class")}: </Label>
                          <FormGroup>
                            <select
                              className="form-control"
                              onChange={(e) => {
                                selectedClassOps(e.target.value);
                                classErrorOps("");
                              }}
                              value={selectedClass}
                              validate={{
                                required: {
                                  value: true,
                                  errorMessage: props.t(
                                    "Please select class..."
                                  ),
                                },
                              }}
                            >
                              {selectedClass === "" && (
                                <option value={""}></option>
                              )}
                              {classList.map((val, key) => (
                                <option key={key} value={val.id}>
                                  {val.name}
                                </option>
                              ))}
                            </select>
                          </FormGroup>
                          <span
                            className="text-danger mt-0 pt-0"
                            style={{ fontSize: "11px" }}
                          >
                            {classError}
                          </span>
                        </FormGroup>

                       { showCorporatePartners && <FormGroup>
                          <Label htmlFor="formname">{props.t("Corporate")}: </Label>
                          <Select
                            value={selectedCorporatePartners}
                            onChange={(v) => { setSelectedCorporatePartners(v) }}
                            options={allCorporatePartners}
                            classNamePrefix="select2-selection"
                          />
                                          
                        </FormGroup>}
                        <Button type="submit" color="primary">
                          {props.t("SAVE")}
                        </Button>
                      </div>
                    </div>
                  </AvForm>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
      <Licence  
          showopup ={showLimitModal}
          handelAdd={()=>{
            submitToSave();
            setShowLimitModal(false);
            setLoading(false);
          }}
          handelClose={()=>{
            setShowLimitModal(false);
            setLoading(false);
          }}
      >
        </Licence>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { classrooms } = state.Classroom;
  const { selectedSchool } = state.School;
  const { franchise } = state.Franchise;
  const { corporatePartners } = state.CorporatePartners;
  return { classrooms,selectedSchool,franchise,corporatePartners, };
};
export default connect(mapStatetoProps, {
  getClassroomsForSchoolId,
  addAKid,
  addParentsToDB,
  setSelecetdSchool,
  getCorporatePartners,
})(withRouter(withNamespaces()(AddNewKid)));
