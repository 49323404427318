import React, { useEffect, useState } from 'react';
import { Row, Col, Container, Label, Table, UncontrolledTooltip, Button, Modal, ModalHeader, ModalBody, Progress, ModalFooter } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { matchPath } from "react-router";

// actions
import {
  setSelecetdTeacher,
  setSelecetdSchool,
  getTeachersForSchool,
} from "../../store/actions";

import StaffIdCardView from "./StaffIdCardView";
import QrCodeView from "./QrCodeView";
import { Offsymbol, OnSymbol } from '../../components/Common/CommonFunctions';
import Switch from "react-switch";

// Redux
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";


import QRCode from 'qrcode'

import IdCardView from './IdCardView';
import * as WhiteLabel from "../../components/Common/WhiteLable";
import UpdatePhoto from '../../components/Common/UpdatePhoto';
import Slider from "react-rangeslider";
import SaveAsPdf from '../../components/Common/SaveAsPdf';


const StaffIdCard = (props) => {

  const match = matchPath(props.history.location.pathname, {
    path: "/staffIdcard/:schoolId/:id",
    exact: true,
    strict: false,
  });

  const matchSchool = matchPath(props.history.location.pathname, {
    path: "/staffIdcard/:schoolId",
    exact: true,
    strict: false,
  });

  const teacherId = match && match.params.id;
  const schoolId = match ? match.params.schoolId : matchSchool.params.schoolId;
  const forSchool = matchSchool ? true : false;

  const potraitSize = {
    id: 0,

    schoolLogoHeight: "5mm",
    topHeaderDivHeight: "15.5mm",
    bottomFooterDivHeight: "15.1mm",
    kidDetailHeight: "55mm",
    kidPhotoHeight: "17mm",
    colForKidDetails: 12,
    rowForKidDetails: 12,
    qrCodeDivHeight: "21mm",

    cardHeight: "86mm",
    cardWidth: "54mm",
    nameStyle: { fontSize: "x-small" },
    marginTopForKidName: "mt-0",
    kidPhotoMarginTop: "mt-0",
  }
  const landscapeSize = {
    id: 1,

    schoolLogoHeight: "12mm",
    topHeaderDivHeight: "14mm",
    topTitleDivHeight: "5mm",
    bottomFooterDivHeight: "12mm",
    kidDetailHeight: "22mm",
    kidPhotoHeight: "21mm",
    colForKidDetails: 5,
    rowForKidDetails: 12,
    qrCodeDivHeight: "16mm",

    cardHeight: " 54mm",
    cardWidth: "86mm",
    nameStyle: { fontSize: "x-small", maxHeight: "5mm", overflow: "hidden" },
    marginTopForKidName: "",
    kidPhotoMarginTop: "",
    footerW1: "28mm",
    footerW2: "57mm"
  }

  const withoutQRCodePortrait = {
    id: 2,

    schoolLogoHeight: "5mm",
    topHeaderDivHeight: "15.5mm",
    bottomFooterDivHeight: "15.1mm",
    kidDetailHeight: "55mm",
    kidPhotoHeight: "27mm",
    colForKidDetails: 12,
    rowForKidDetails: 12,
    qrCodeDivHeight: "0mm",
    
    cardHeight: "86mm",
    cardWidth: "54mm",
    nameStyle: { fontSize: "x-small" },
    marginTopForKidName: "mt-3",
    kidPhotoMarginTop: "mt-3",

}

const withoutQRCodeLandscape = {
    id: 3,

    schoolLogoHeight: "12mm",
    topHeaderDivHeight: "14mm",
    topTitleDivHeight: "5mm",
    bottomFooterDivHeight: "12mm",
    kidDetailHeight: "22mm",
    kidPhotoHeight: "21mm",
    colForKidDetails: 4,
    rowForKidDetails: 12,
    noQRLogoDivHeight: "16mm",
    
    cardHeight: "54mm",
    cardWidth: "86mm",
    nameStyle: { fontSize: "x-small", maxHeight: "5mm", overflow: "hidden" },
    marginTopForKidName: "",
    kidPhotoMarginTop: "",
    footerW1: "28mm",
    footerW2: "57mm"

}

  const onlyQRCode = {
    id: 4,

    schoolLogoHeight: "5mm",
    topHeaderDivHeight: "20.3090275mm",
    bottomFooterDivHeight: "20.3090275mm",
    kidDetailHeight: "42.3090275mm",
    kidPhotoHeight: "25mm",
    colForKidDetails: 6,
    rowForKidDetails: 12,
    qrCodeDivHeight: "60.3090275mm",

    cardHeight: "125.23611mm",
    cardWidth: "78.636627209302mm",
    nameStyle: { fontSize: "x-small" },
    marginTopForKidName: "",
    kidPhotoMarginTop: "",
  }


  const HeaderSettingTypes = {
    logoAndName: "logoAndName",
    logoOnly: "logoOnly",
    customLogo: "customLogo",
  };

  const updateSizeScale = (scale) => {
    let ratio = 1 + scale;
    

      if (orientaion=="P" && layoutType=="QR") {
        let os = {
          id: 0,

          schoolLogoHeight: 5 * ratio + "mm",
          topHeaderDivHeight: 15.5 * ratio + "mm",
          bottomFooterDivHeight: 15.1 * ratio + "mm",
          kidDetailHeight: 55 * ratio + "mm",
          kidPhotoHeight: 17 * ratio + "mm",
          colForKidDetails: 12 * ratio,
          rowForKidDetails: 12 * ratio,
          qrCodeDivHeight: 21 * ratio + "mm",

          cardHeight: 86 * ratio + "mm",
          cardWidth: 54 * ratio + "mm",
          nameStyle: { fontSize: "x-small", },
          marginTopForKidName: "mt-0",
          kidPhotoMarginTop: "mt-0"
        }
        setSelectedPrintSetting(os)
      } else if (orientaion=="L" && layoutType=="QR" ) {
        let oh = {
          id: 1,

          schoolLogoHeight: 12 * ratio + "mm",
          topHeaderDivHeight: 14 * ratio + "mm",
          topTitleDivHeight: 5 * ratio + "mm",
          bottomFooterDivHeight: 12 * ratio + "mm",
          kidDetailHeight: 22 * ratio + "mm",
          kidPhotoHeight: 21 * ratio + "mm",
          colForKidDetails: 5 * ratio,
          rowForKidDetails: 12 * ratio,
          qrCodeDivHeight: 16 * ratio + "mm",
          
          cardHeight: 54 * ratio + "mm",
          cardWidth: 86 * ratio + "mm",
          nameStyle: { fontSize: "x-small", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
          marginTopForKidName: "",
          kidPhotoMarginTop: "",
          footerW1: 28 * ratio + "mm",
          footerW2: 57 * ratio + "mm",

        }
        setSelectedPrintSetting(oh)
      }else if (orientaion=="P" && layoutType=="NoQR" ) {
      let oq = {
        id: 2,
        
        schoolLogoHeight: 5 * ratio + "mm",
        topHeaderDivHeight: 15.5 * ratio + "mm",
        bottomFooterDivHeight: 15.1 * ratio + "mm",
        kidDetailHeight: 55 * ratio + "mm",
        kidPhotoHeight: 27 * ratio + "mm",
        colForKidDetails: 12 * ratio,
        rowForKidDetails: 12 * ratio,
        qrCodeDivHeight: "0mm",
        
        cardHeight: 86 * ratio + "mm",
        cardWidth: 54 * ratio + "mm",
        nameStyle: { fontSize: "x-small", },
        marginTopForKidName: "mt-3",
        kidPhotoMarginTop: "mt-3"
      }

      setSelectedPrintSetting(oq)
    } else if (orientaion=="L" && layoutType=="NoQR" ) {
      let oq = {
        id: 3,

        schoolLogoHeight: 12 * ratio + "mm",
        topHeaderDivHeight: 14 * ratio + "mm",
        topTitleDivHeight:5 * ratio + "mm",
        bottomFooterDivHeight: 12 * ratio + "mm",
        kidDetailHeight: 22 * ratio + "mm",
        kidPhotoHeight: 21 * ratio + "mm",
        colForKidDetails: 4 * ratio,
        rowForKidDetails: 12 * ratio,
        noQRLogoDivHeight: 16 * ratio + "mm",
        
        cardHeight: 54 * ratio + "mm",
        cardWidth: 86 * ratio + "mm",
        nameStyle: { fontSize: "x-small", maxHeight: 5 * ratio + "mm", overflow: "hidden" },
        marginTopForKidName: "",
        kidPhotoMarginTop: "",
        footerW1: 28 * ratio + "mm",
        footerW2: 57 * ratio + "mm",
      }
      setSelectedPrintSetting(oq)
    }

  }

  Object.freeze(HeaderSettingTypes);


  const [selectedPrintSetting, setSelectedPrintSetting] = useState(potraitSize)
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [headerFontSize, setHeaderFontSize] = useState(14);
  const [bodyFontSize, setBodyFontSize] = useState(11);
  const [footerFontSize, setFooterFontSize] = useState(9);
  const [selectedHeaderTypeSetting, setSelectedHeaderTypeSetting] = useState(HeaderSettingTypes.logoAndName);
  const [showLogoUploadView, setShowLogoUploadView] = useState(false);
  const [idCardLogo, setIdCardLogo] = useState();
  const [idCardLogoUploadProgress, setIdCardLogoUploadProgress] = useState(0);
  const [isBorderOff, setIsBorderOff] = useState(false);
  const [showPopup, setShowPopup] = useState(false);
  const [sliderValue, setSliderValue] = useState(0)
  const [orientaion, setOrientaion] = useState("P")
  const [layoutType, setLayoutType] = useState("QR");
  const [onlyQR, setOnlyQR] = useState(false)
  
  function getDesignation(teacher) {
    if (teacher.designation) {
      return teacher.designation;
    } else if (teacher.Role === 3) {
      return props.t('Director');
    } else if (teacher.Role === 2) {
      return props.t('Center Head');
    } else {
      return props.t('Teacher');
    }
  }

  useEffect(() => {
    if (props.selectedSchool && props.selectedSchool.id == schoolId && props.selectedSchool.attributes.idCardLogo) {
      changeCustomHeaderLogo(props.selectedSchool.attributes.idCardLogo)
    } else {
      if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
        props.setSelecetdSchool(schoolId);
      }
      changeToDefaultHeaderType()
    }
  }, [props.selectedSchool, schoolId])


  useEffect(() => {
    if (teacherId) {
      props.setSelecetdTeacher(teacherId);
    }
  }, [teacherId]);

  useEffect(() => {
    if (forSchool) {
      props.getTeachersForSchool(schoolId);
    }
  }, [schoolId]);

  const [allTeacherListMaster, setAllTeachersListMaster] = useState([])
  const [allTeacherList, setAllTeachersList] = useState([])
  const [allTeacherListIds, setAllTeachersListIds] = useState([])
  const [allTeacherMasterListIds, setAllTeachersMasterListIds] = useState([])
  const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [machineType, setMachineType] = useState('');


  useEffect(() => {
    var tempList = [...props.teachers];
    updateLists(tempList);
  }, [props.teachers]);

  function updateLists(tempList) {
    var DList = [];
    var CList = [];
    var TList = [];
    var OList = [];
    let ids = [];
    if (props.teachers) {
      for (const i in tempList) {
        ids.push(tempList[i].id)
        if (tempList[i].attributes.Role === 3) {
          DList.push(tempList[i]);
        } else if (tempList[i].attributes.Role === 2) {
          CList.push(tempList[i]);
        } else if (tempList[i].attributes.Role === 1) {
          TList.push(tempList[i]);
        } else {
          OList.push(tempList[i]);
        }
      }
    }
    setAllTeachersList(DList.concat(CList).concat(TList).concat(OList));
    setAllTeachersListMaster(DList.concat(CList).concat(TList).concat(OList))
    setAllTeachersListIds(ids)
    setAllTeachersMasterListIds(ids)
  }

  function printIdCard() {
    var css = "@page { size: A3 ;margin:0; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    setTimeout(() => {
      window.print();
    }, 300);
  }

  useEffect(() => {
    const savedValue = localStorage.getItem('dontShowAgain');
    if (savedValue === 'true') {
        setDontShowAgain(true);
    }

    const userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.includes('mac')) {
        setMachineType('Mac');
    } else if (userAgent.includes('windows')) {
        setMachineType('Windows');
    } else {
        setMachineType('Unknown');
    }
    }, []);

  const handleNextClick = () => {
    setShowSaveAsPdfModal(false);
    setTimeout(() => {
        printIdCard(); 
    }, 300); 
  };


  const getSingleCardUI = (isPrintOnly) => {

    let classNameTemp = isPrintOnly ? "d-none d-print-block ml-0 pl-0 mr-0 pr-0" : "d-print-none ml-0 pl-0 mr-0 pr-0"
    let styleTemp = { width: "100%", minHeight: "1420px" }
    if (isPrintOnly) {
      styleTemp.backgroundColor = "white"
    }

    return (
      <Col lg="12" md="12" sm="12" xs="12" className={classNameTemp}>
        <center>
          <div
            style={styleTemp}
            className="text-center"
          >
            {
            onlyQR?
              <div className='d-flex justify-content-center' style={{ pageBreakInside: 'avoid' }}>
                <QrCodeView
                  school={props.selectedSchool}
                  teacher={props.selectedTeacher}
                  selectedPrintSetting={onlyQRCode}
                ></QrCodeView></div> :
              <div className='d-flex justify-content-center'>
                {getTeacherCardUI(props.selectedTeacher)}
              </div>}
          </div>
        </center>
      </Col>
    )
  }

  const getTeacherCardUI = (teacher) => {
    return (
      <div style={{ pageBreakInside: 'avoid' }}>
        <StaffIdCardView
          idCardLogo={idCardLogo}
          bodyFontSize={bodyFontSize}
          footerFontSize={footerFontSize}
          headerFontSize={headerFontSize}
          isBorderOff={isBorderOff}
          school={props.selectedSchool}
          teacher={teacher}
          selectedPrintSetting={onlyQR?onlyQRCode:selectedPrintSetting}
          orientaion={orientaion}
          layoutType={layoutType}
          onlyQR={onlyQR}
          selectedHeaderTypeSetting={selectedHeaderTypeSetting}
        >
        </StaffIdCardView>
      </div>
    )
  }

  const getMultiCardUI = () => {
    let styleTemp = { width: "100%", minHeight: "1420px" }
    styleTemp.backgroundColor = "white"
    return (
      <div className="mx-auto">
        <div
          style={styleTemp}
        >
          <div className='ml-4 pl-3'>
            <Row>
              {allTeacherList.map((teacher, key) => (
                <Col className="mb-4" key={key}>
                  <React.Fragment >{
                    onlyQR ?
                      <div className='d-flex justify-content-center' style={{ pageBreakInside: 'avoid' }}><QrCodeView
                        school={props.selectedSchool}
                        teacher={teacher}
                        selectedPrintSetting={onlyQRCode}
                      ></QrCodeView></div> :
                      <div className='d-flex justify-content-center'>
                        {getTeacherCardUI(teacher)}
                      </div>
                  }
                  </React.Fragment>
                </Col>
              ))
              }
            </Row>
          </div>
        </div>

      </div>
    )
  }

  const getLoadingUI = () => {
    return (
      <Col xs="12">
        <div className="text-center my-3">
          <Link to="#" className="text-success" onClick={(e) => {
            e.preventDefault()
          }}>
            <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
            {forSchool ? "Loading Staff Id Cards" : "Loading Staff Id Card"}{" "}
          </Link>
        </div>
      </Col>
    )
  }

  const getParentUIWithHeader = (isSingleCardUI) => {
    return (
      <Row className="text-center bg-white">
        {
          <>
            <Col xs="12" className="d-print-none mt-3 mb-3">
              <div className="navbar-header p-0" style={{ borderBottom: "1px solid gray" }}>

                <h4 className="text-center m-4"><img src={WhiteLabel.getAppLogoWithName()} className="m-5" height="50px" style={{ border: "1px solid lightGray", borderRadius: "10px", padding: "10px" }}></img>{"STAFF ID CARD"}</h4>
                <div className="d-flex m-4">
                  <Row className="d-flex  m-4">
                    <div>
                      {!teacherId &&
                        <Button
                          color="primary"
                          className='float-right btn btn-primary waves-effect waves-light m-1'
                          onClick={() => {
                            setShowPopup(true)
                          }
                          }
                        >
                          Add/Remove
                        </Button>
                      }
                      <Button
                        color="primary"
                        className='float-right btn btn-primary waves-effect waves-light m-1'
                        onClick={() => {
                          setShowSettingsModal(true)
                        }
                        }
                      >
                        Customise
                      </Button>
                      <Button 
                        onClick={() => {
                          if (['Windows', 'Mac'].includes(machineType)) {
                            if (!dontShowAgain) {
                              setShowSaveAsPdfModal(true); 
                            } else {
                              printIdCard();
                            }
                          }else{
                            printIdCard();
                          }
                        }}
                        className="btn btn-success waves-effect waves-light m-1"
                      >
                        <i className="fa fa-print m-1"></i> Print/Download
                      </Button>
                      <SaveAsPdf
                        showModal={showSaveAsPdfModal}
                        toggleModal={() => setShowSaveAsPdfModal(false)}
                        dontShowAgain={dontShowAgain}
                        setDontShowAgain={setDontShowAgain}
                        onNextClick={handleNextClick}
                        machineType={machineType}
                      />
                    </div>
                  </Row>
                </div>

              </div>
            </Col>
            {isSingleCardUI && <>
              <Row className="d-print-none mx-auto">{getSingleCardUI()}</Row>
              {getSingleCardUI(true)}
            </>}
            {!isSingleCardUI && <>
              {getMultiCardUI()}
            </>}
          </>}
      </Row>
    )
  }

  const changeHeaderFontSize = (isLeft) => {
    if (isLeft) {
      if (headerFontSize != 0) {
        setHeaderFontSize(headerFontSize - 1)
      }
    } else {
      setHeaderFontSize(headerFontSize + 1)
    }
  }

  const changeFooterFontSize = (isLeft) => {
    if (isLeft) {
      if (footerFontSize != 0) {
        setFooterFontSize(footerFontSize - 1)
      }
    } else {
      setFooterFontSize(footerFontSize + 1)
    }
  }

  const changeBodyFontSize = (isLeft) => {
    if (isLeft) {
      if (bodyFontSize != 0) {
        setBodyFontSize(bodyFontSize - 1)
      }
    } else {
      setBodyFontSize(bodyFontSize + 1)
    }
  }

  const changeCustomHeaderLogo = (result) => {
    if (result) {
      let name = result._name.split(/_(.+)/)[1];
      let fileExtension = name.split(".");
      let tempFile = {
        actualImageName: name,
        fileExtension: fileExtension[1].toLowerCase(),
        imageName: fileExtension[0],
        image: result,
        imageURL: result._url,
      };
      setIdCardLogoUploadProgress(100);
      setIdCardLogo(tempFile)

    }
    setHeaderFontSize(0)
    setSelectedHeaderTypeSetting(HeaderSettingTypes.customLogo)
    setShowLogoUploadView(true)

  }

  const changeToDefaultHeaderType = () => {
    setIdCardLogo()
    setHeaderFontSize(14)
    setShowLogoUploadView(false)
    setSelectedHeaderTypeSetting(HeaderSettingTypes.logoAndName)
  }

  // On image upload
  function handleImageUploadChange(
    changedImageURL,
    imageFile,

  ) {

    setIdCardLogo(undefined)
    setIdCardLogoUploadProgress(1)
    let fileExtension = imageFile._name.split(".");
    let tempFile = {
      actualImageName: imageFile._name,
      fileExtension: fileExtension[1].toLowerCase(),
      imageName: fileExtension[0],
      image: imageFile,
      imageURL: undefined,
    };
    tempFile.image
      .save({
        progress: (value) => {
          if (value && value > 0.1) {
            //setUploadProgress(value * 100);
            //setProgressText(props.t('Uploading....')+": "+(value*100).toFixed(2)+"%")
            setIdCardLogoUploadProgress(value * 100)
          }
        },
      })
      .then(
        (result) => {
          if (props.selectedSchool) {
            props.selectedSchool.set("idCardLogo", result)
            props.selectedSchool.save().then(
              (schoolResult) => {
                tempFile.imageURL = result._url;
                tempFile.image = result;
                setIdCardLogo(tempFile)
                tempQuestionObject.answerImage = tempFile;
              },
              (error) => {

              }
            );
          }
        },
        (error) => {
        }
      );
    setIdCardLogo({ actualImageName: tempFile.actualImageName, imageURL: changedImageURL })
  }

  return (
    <React.Fragment>
      <Modal isOpen={showSettingsModal} toggle={() => { setShowSettingsModal(!showSettingsModal) }} scrollable={true}>
        <ModalHeader toggle={() => {
          setShowSettingsModal(!showSettingsModal)
        }}>
          Customise ID Card
        </ModalHeader>
        <ModalBody>
          <div className='mt-2'>
            <Row>
              <div className="d-flex justify-content-start">
                <button
                  onClick={() => {
                    setOrientaion("P")
                    if(onlyQR){
                      setOnlyQR(false)
                  }
                  if (layoutType == "NoQR") {
                      setSelectedPrintSetting(withoutQRCodePortrait)
                  } else {
                      setSelectedPrintSetting(potraitSize)
                  }
                  }}
                  className={
                    orientaion == "P"
                      ? "btn btn-success btn-sm waves-effect waves-light m-2"
                      : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                  }
                >
                  Portrait
                </button>
                <button
                  onClick={() => {
                    setOrientaion("L")
                    if(onlyQR){
                      setOnlyQR(false)
                  }
                    if (layoutType == "NoQR"){
                      setSelectedPrintSetting(withoutQRCodeLandscape)
                    }else{
                      setSelectedPrintSetting(landscapeSize)
                    }
                  }}
                  className={
                    orientaion == "L"
                      ? "btn btn-success btn-sm waves-effect waves-light m-2"
                      : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                  }
                >
                  Landscape
                </button>
                <button
                  onClick={() => {
                    setLayoutType("QR")
                    if(onlyQR){
                      setOnlyQR(false)
                  }
                    if (orientaion == "P") {
                      setSelectedPrintSetting(potraitSize)
                    } else {
                      setSelectedPrintSetting(landscapeSize)
                    }
                  }}
                  className={
                    layoutType === "QR"
                    ? "btn btn-success btn-sm waves-effect waves-light m-2"
                      : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                  }
                >
                  With QR
                </button>
                <button
                  onClick={() => {
                    setLayoutType("NoQR")
                    if(onlyQR){
                      setOnlyQR(false)
                  }
                    if (orientaion == "P") {
                      setSelectedPrintSetting(withoutQRCodePortrait)
                    } else {
                      setSelectedPrintSetting(withoutQRCodeLandscape)
                    }
                  }}
                  className={
                    layoutType === "NoQR"
                    ? "btn btn-success btn-sm waves-effect waves-light m-2"
                      : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                  }
                >
                  Without QR
                </button>
                <button
                  onClick={() => {
                    setOnlyQR(!onlyQR)
                  }}
                  className={
                    onlyQR
                      ? "btn btn-success btn-sm waves-effect waves-light m-2"
                      : "btn btn-secondary btn-sm waves-effect waves-light m-2"
                  }
                >
                  Only QR
                </button>
              </div>
            </Row>
            <hr></hr>
            <Row className='m-1'><Col><label>Header Settings</label></Col></Row>
            <Row>
              <div className="d-flex justify-content-start">
                <button onClick={() => {
                  setHeaderFontSize(14)
                  setSelectedHeaderTypeSetting(HeaderSettingTypes.logoAndName)
                  setShowLogoUploadView()
                  setIdCardLogo()
                  setIdCardLogoUploadProgress(0)
                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.logoAndName ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Logo & Name"}</button>
                <button onClick={() => {
                  setHeaderFontSize(0)
                  setSelectedHeaderTypeSetting(HeaderSettingTypes.logoOnly)
                  setShowLogoUploadView()
                  setIdCardLogo()
                  setIdCardLogoUploadProgress(0)

                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.logoOnly ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Logo Only"}</button>
                <button onClick={() => {
                  changeCustomHeaderLogo(props.selectedSchool && props.selectedSchool.attributes.idCardLogo)

                }} className={selectedHeaderTypeSetting === HeaderSettingTypes.customLogo ? "btn btn-success btn-sm waves-effect waves-light m-2" : "btn btn-secondary btn-sm waves-effect waves-light m-2"}>{"Custom Logo"}</button>
              </div>
            </Row>
            {showLogoUploadView && <Row>
              <Col>
                {idCardLogo &&
                  idCardLogoUploadProgress === 100 && (
                    <Col xl="12" md="12" sm="12" xs="12">
                      <div className="border p-3">
                        <Row>
                          <div className="ml-3">
                            <img
                              style={{ width: "12rem", height: "4rem" }}
                              className="rounded avatar"
                              src={idCardLogo.imageURL}
                              alt=""
                            />
                          </div>
                          <div
                            className="float-right m-2"
                            style={{ width: "200px" }}
                          >
                            <div className="text-muted font-weight-bold">
                              {idCardLogo.actualImageName}
                            </div>
                            <div className="d-flex mt-3">
                              <UpdatePhoto
                                isRectangle={true}
                                id={"idCardCustom"}
                                tempId={"idCardCustom"}
                                className="m-2"
                                buttonTitle="Change"
                                isPopupOn={false}
                                onPicChange={(imageFile, url) => {
                                  handleImageUploadChange(

                                    url,
                                    imageFile,
                                  );
                                }}
                                imageFileName={
                                  idCardLogo
                                    ? idCardLogo.actualImageName
                                    : ""
                                }
                                initialImage={
                                  idCardLogo.imageURL
                                }
                              ></UpdatePhoto>
                              <Button
                                style={{ marginLeft: "10px" }}
                                className="btn-danger float-right"
                                onClick={() => {
                                  setIdCardLogo(undefined)
                                  setIdCardLogoUploadProgress(0)
                                  if (props.selectedSchool) {
                                    props.selectedSchool.unset("idCardLogo")
                                    props.selectedSchool.save().then(
                                      (result) => {

                                      },
                                      (error) => {

                                      }
                                    );
                                  }
                                }}
                              >
                                Clear
                              </Button>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  )}
                {idCardLogo &&
                  idCardLogoUploadProgress >= 1 &&
                  idCardLogoUploadProgress < 100 && (
                    <Col xl="12" md="12" sm="12" xs="12">
                      <div className="border p-3">
                        <Row>
                          <div
                            className="avatar ml-3"
                            style={{ width: "5rem", height: "5rem" }}
                          >
                            <span
                              className={
                                "avatar-title rounded-circle bg-soft-primary text-primary"
                              }
                              style={{ fontSize: "60px" }}
                            ></span>
                          </div>
                          <div
                            className="float-right ml-4"
                            style={{ width: "200px" }}
                          >
                            <div className="text-muted font-weight-bold">
                              {idCardLogo.actualImageName}
                            </div>
                            <div className=" mt-3">
                              <Progress

                                color="primary"
                                value={idCardLogoUploadProgress}
                              ></Progress>
                            </div>
                          </div>
                        </Row>
                      </div>
                    </Col>
                  )}
                {idCardLogo == undefined && (
                  <div className="d-flex">
                    <div>
                      {" "}
                      <UpdatePhoto
                        isRectangle={true}
                        buttonTitle={"Upload Custom Logo"}
                        buttonTitleInitial={"Upload Custom Logo"}
                        id={"idCardCustomImage"}
                        tempId={"idCardCustomImage"}
                        className="p-1"
                        isPopupOn={false}
                        onPicChange={(imageFile, url) => {
                          handleImageUploadChange(
                            url,
                            imageFile,
                          );
                        }}
                        initialImage={""}
                      ></UpdatePhoto>
                    </div>
                  </div>
                )}
              </Col>
            </Row>}
            <hr></hr>

            <Row className='d-flex justify-content-between'>
              <Col>
                <label className='mb-1 mt-2'>Card Border</label>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div>

                  {<Link
                    to="#"
                    className="float-right"
                    style={{ cursor: "pointer" }}
                    onClick={(e) => {
                      e.preventDefault()
                      setIsBorderOff(false)
                      setHeaderFontSize(14)
                      setBodyFontSize(11)
                      setFooterFontSize(10)
                    }}
                    id="IdCardSettingsReloadButton"
                  >
                    <i style={{ fontSize: "30px" }} className="bx bx-refresh text-primary"></i>
                    <UncontrolledTooltip
                      placement="top"
                      target={"IdCardSettingsReloadButton"}
                    >
                      {props.t("Reset")}
                    </UncontrolledTooltip>
                  </Link>}
                </div>
              </Col>

            </Row>
            <Row>
              <div className='d-flex'>
                <div className='m-2'>
                  <label className='float-left'>Disable</label>
                </div>
                <Switch
                  height={15}
                  width={25}
                  className="m-2"
                  uncheckedIcon={<Offsymbol />}
                  checkedIcon={<OnSymbol />}
                  onColor={"#6add7d"}
                  onChange={() => {
                    setIsBorderOff(!isBorderOff)
                  }}
                  checked={!isBorderOff}
                />
                <div className='m-2'>
                  <label className='float-left'>Enable</label>
                </div>
              </div>
            </Row>
            <Row>
              <Col>
                <div className='d-flex mt-2'>
                  <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Header font size</label>
                  <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                    <div className='m-2' onClick={() => changeHeaderFontSize(true)}>
                      <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                    </div>
                    <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{headerFontSize}</div>
                    {headerFontSize < 20 && (
                      <div className='m-2' onClick={() => changeHeaderFontSize()}>
                        <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>


            <Row>
              <Col>
                <div className='d-flex mt-2'>
                  <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Body font size </label>
                  <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                    <div className='m-2' onClick={() => changeBodyFontSize(true)}>
                      <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                    </div>
                    <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{bodyFontSize}</div>
                    {bodyFontSize < 20 && (
                      <div className='m-2' onClick={() => changeBodyFontSize()}>
                        <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>

            <Row>
              <Col>
                <div className='d-flex mt-2'>
                  <label className='float-left mt-3' style={{ minWidth: "30mm", maxWidth: "30mm" }}>Footer font size </label>
                  <div className='float-right d-flex ml-3 pt-2 mt-1 pl-2 pr-2' style={{ minWidth: "25mm", maxWidth: "25mm", border: "2px solid lightGrey", borderRadius: "6px", marginLeft: '30px' }}>
                    <div className='m-2' onClick={() => changeFooterFontSize(true)}>
                      <i className='bx bxs-left-arrow font-size-16 align-middle'></i>
                    </div>
                    <div className='d-flex align-items-center m-2 font-size-16' style={{ minWidth: "3mm", maxWidth: "3mm", marginBottom: '8px' }}>{footerFontSize}</div>
                    {footerFontSize < 20 && (
                      <div className='m-2' onClick={() => changeFooterFontSize()}>
                        <i className='bx bxs-right-arrow font-size-16 align-middle'></i>
                      </div>
                    )}
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <Col>
                <div className="p-3">
                  <Label>Card Size</Label>
                  <Slider
                    value={sliderValue}
                    orientation="horizontal"
                    min={0}
                    max={1}
                    step={0.1}
                    onChange={value => {
                      updateSizeScale(value);
                      setSliderValue(value);
                    }}
                  />
                </div>

              </Col>
            </Row>
            <div>
              <div className='d-flex justify-content-end'>
                <button className='btn btn-primary' onClick={() => {
                  setShowSettingsModal(!showSettingsModal)
                }}>Done</button>
              </div>
            </div>
          </div>
        </ModalBody>
      </Modal>
      {
        forSchool ?
          (props.selectedSchool && props.selectedSchool.id === schoolId &&
            allTeacherList && allTeacherList.length > 0 ? getParentUIWithHeader() : getLoadingUI())
          :
          (props.selectedSchool && props.selectedSchool.id === schoolId &&
            props.selectedTeacher && props.selectedTeacher.id === teacherId ?
            getParentUIWithHeader(true) : getLoadingUI())
      }
      <Modal isOpen={showPopup} toggle={() => { setShowPopup(!showPopup) }} scrollable={true}>
        <ModalHeader toggle={() => {
          setShowPopup(!showPopup)
        }}>
          Add/Remove<br></br>
          <small>Please select/unselect the profile which you want to print</small>
        </ModalHeader>
        <ModalBody>
          <div className='mt-2'>
            <Table>
              <thead>
                <tr>
                  <th>
                    <div className="custom-control custom-checkbox">
                      <input
                        type='checkbox'
                        className="custom-control-input"
                        id="checkbox"
                        name="checkbox"
                        checked={allTeacherList.length === allTeacherListMaster.length}
                        onChange={() => {
                          if (allTeacherListIds.length !== 1) {

                            setAllTeachersList([allTeacherListMaster[0]])
                            setAllTeachersListIds([allTeacherListMaster[0].id])
                          } else {
                            //Add Teacher from all teacher list and update useState of all teacher list.
                            setAllTeachersListIds(allTeacherMasterListIds)
                            setAllTeachersList(allTeacherListMaster)
                          }
                        }}
                      />
                      <label className="custom-control-label" htmlFor="checkbox">
                        ({allTeacherList.length})
                      </label>
                    </div>

                  </th>
                  <th>{props.t("Profile Photos")}</th>
                  <th>{props.t("Name")}</th>
                </tr>
              </thead>
              <tbody>
                {allTeacherListMaster.map((val, key,) => (
                  <tr key={key}>
                    <td>
                      <div className="custom-control custom-checkbox mb-2">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={`checkbox${val.id}`}
                          name="checkbox"
                          checked={allTeacherListIds.includes(val.id)}
                          onChange={() => {
                            if (allTeacherListIds.includes(val.id)) {
                              // Remove the teacher from the selected list
                              const updatedTeacherList = allTeacherList.filter((teacher) => teacher.id !== val.id);
                              const updatedTeacherListIds = allTeacherListIds.filter((teacher) => teacher !== val.id);
                              setAllTeachersList(updatedTeacherList);
                              setAllTeachersListIds(updatedTeacherListIds);
                            } else {
                              // Add the teacher to the selected list
                              const tempIds = [val.id, ...allTeacherListIds];
                              setAllTeachersListIds(tempIds);
                              const tempTeacherList = allTeacherListMaster.filter((teacher) => tempIds.includes(teacher.id));
                              setAllTeachersList(tempTeacherList);
                            }
                          }}
                        />
                        <label className="custom-control-label" htmlFor={`checkbox${val.id}`}></label>
                      </div>

                    </td>
                    <td>
                      {
                        !val.attributes.Photo ?
                          <div className="avatar-sm  mb-4">
                            <span className={"avatar-title rounded-circle bg-soft-primary text-primary font-size-16"}>
                              {val.attributes.Name.charAt(0).toUpperCase()}
                            </span>
                          </div>
                          :
                          <div className="avatar-sm  mb-4">
                            <img className="rounded-circle avatar-sm" src={val.attributes.Photo._url} alt="" />
                          </div>
                      }
                    </td>
                    <td>{val.attributes.Name}
                      <p className="text-muted mb-0">{getDesignation(val.attributes)}</p></td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </ModalBody>
      </Modal>
    </React.Fragment>
  )
}



const mapStatetoProps = (state) => {
  const { selectedTeacher } = state.Teacher;
  const { selectedSchool } = state.School;
  const { teachers } = state.Teacher;

  return {
    selectedTeacher,
    selectedSchool,
    teachers,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdTeacher,
      setSelecetdSchool,
      getTeachersForSchool,
    })(StaffIdCard)
  )
);