import React, { useState, useEffect } from 'react';
import { Link, withRouter } from "react-router-dom";
import { Label, FormGroup, Row, Col, Card, CardBody, Input, Table, UncontrolledTooltip } from "reactstrap";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import TopBar from './Topbar';
import { withNamespaces } from "react-i18next";
import { connect } from 'react-redux';
import Parse from 'parse';
import SweetAlert from "react-bootstrap-sweetalert";
import Editable from "react-bootstrap-editable";
import Moment from 'moment';
import Image from "../../assets/images/book_lover.svg";
import contentImage from "../../assets/images/undraw_typewriter.svg";

import * as CommonFunctions from "../../components/Common/CommonFunctions";

const Curriculums = (props) => {


    const [selectedCycles, setSelectedCycles] = useState([]);


    const [curriculumName, setCurriculumName] = useState(props.selectedSchool ? props.selectedSchool.attributes.Name : "");
    const [nameError, setNameError] = useState(false);
    const [selectedTab, setSelectedTab] = useState("initial");

    const [cycleYearError, setCycleYearError] = useState(false);
    const [loading, setLoading] = useState(false);

    const [backButton, showBackButton] = useState(false);
    const [hideButton, setHideButton] = useState(true);
    const [buttonText, setButtonText] = useState("ADD CURRICULUM")

    const [forInstitute, setForInstitute] = useState(false);

    const [editCurriculum, setEditCurriculum] = useState(undefined);
    const [deleteCurriculum, setDeleteCurriculum] = useState(undefined);
    const [success_dlg, setsuccess_dlg] = useState(false);
    const [displayCurriculums, setDisplayCurriculums] = useState([]);

    const [accessBased, setAccessBased] = useState(false);

    useEffect(() => {
        if (displayCurriculums.length > 0) {
            setSelectedTab("curriculumList");
            setHideButton(false);
        } else {
            setSelectedTab("initial");
            setHideButton(true);
        }
    }, [displayCurriculums]);
    const initialCycle = [
        {
            id: "Daycare",
            i: 0,
            title: "Daycare",
            actualCycles: ["Infant 0-18 Months", "Toddler 13-36 Months "],
            cycles: ["Infant	0-18 Months", "Toddler 	13-36 Months "],
            c: [{ id: "1.1", i: 0, year: "Infant 0-18 Months", renamed: undefined }, { id: "1.2", i: 1, year: "Toddler 13-36 Months", renamed: undefined }]
        },
        {
            id: "Foundational",
            i: 1,
            title: "Foundational Stage - (5 Years)",
            actualCycles: ["Nursery", "LKG", "UKG", "Class1", "Class2"],
            cycles: ["Nursery", "LKG", "UKG", "Class1", "Class2"],
            c: [{ id: "2.1", i: 0, year: "Nursery", renamed: undefined }, { id: "2.2", i: 1, year: "LKG", renamed: undefined }, { id: "2.3", i: 2, year: "UKG", renamed: undefined }, { id: "2.4", i: 3, year: "Class1", renamed: undefined }, { id: "2.5", i: 4, year: "Class2", renamed: undefined }]
        },
        {
            id: "Preparatory",
            i: 2,
            title: "Preparatory Stage - (3 Years)",
            actualCycles: ["Class3", "Class4", "Class5 "],
            cycles: ["Class3", "Class4", "Class5"],
            c: [{ id: "3.1", i: 0, year: "Class3", renamed: undefined }, { id: "3.2", i: 1, year: "Class4", renamed: undefined }, { id: "3.3", i: 2, year: "Class5", renamed: undefined },]
        },
        {
            id: "Middle",
            i: 3,
            title: "Middle Stage - (3 Years)",
            actualCycles: ["Class6", "Class7", "Class8"],
            cycles: ["Class6", "Class7", "Class8"],
            c: [{ id: "4.1", i: 0, year: "Class6", renamed: undefined }, { id: "4.2", i: 1, year: "Class7", renamed: undefined }, { id: "4.3", i: 2, year: "Class8", renamed: undefined },]
        },
        {
            id: "Secondary",
            i: 4,
            title: "Secondary Stage - (4 Years)",
            actualCycles: ["Class9", "Class10", "Class11", "Class12 "],
            cycles: ["Class9", "Class10", "Class11", "Class12"],
            c: [{ id: "5.1", i: 0, year: "Class9", renamed: undefined }, { id: "5.2", i: 2, year: "Class10", renamed: undefined }, { id: "5.3", i: 2, year: "Class11", renamed: undefined }, { id: "5.4", i: 3, year: "Class12", renamed: undefined }]
        }

    ]
    const [addNewCycles, setnewCycles] = useState([]);
    const [cyclicYears, setCyclicYears] = useState(initialCycle);

    useEffect(() => {
        if (props.schoolId == "franchise") {
            setDisplayCurriculums(props.curriculums);
        } else {
            let curriculumIds = [];
            for (const lc of props.learningCurriculumsSchoolMapForSchoolId) {
                curriculumIds.push(lc.attributes.curriculumId)
            }
            let tempList = []
            for (const curriculum of props.curriculums) {
                if (curriculum.attributes.accessBased) {
                    if (curriculumIds.includes(curriculum.id)) {
                        tempList.push(curriculum)
                    }
                } else {
                    tempList.push(curriculum)
                }
            }
            setDisplayCurriculums(tempList)
        }

    }, [props.curriculums, props.learningCurriculumsSchoolMapForSchoolId])

    function handelWholeCycle(cycle) {

        if (selectedAll(cycle)) {
            var temp = [];
            var ids = [];
            for (const i in cycle.c) {
                ids.push(cycle.c[i].id)
            }
            for (const i in selectedCycles) {
                if (!ids.includes(selectedCycles[i])) {
                    setCycleYearError(false);
                    temp.push(selectedCycles[i])
                }
            }
            setSelectedCycles(temp);
        } else {
            var temp = [...selectedCycles];

            for (const i in cycle.c) {
                if (!selectedCycles.includes(cycle.c[i].id)) {
                    setCycleYearError(false);
                    temp.push(cycle.c[i].id);
                }
            }
            setSelectedCycles(temp);
        }


    }


    function handelCycle(year) {
        if (selectedCycles.includes(year.id)) {
            var temp = selectedCycles.filter(cycle => cycle !== year.id);
            setSelectedCycles(temp)
        } else {
            var temp = [...selectedCycles];
            temp.push(year.id);
            setSelectedCycles(temp);
            setCycleYearError(false);
        }

    }

    function selectedAll(cycle) {
        var responcse = true;
        for (const i in cycle.c) {
            if (!selectedCycles.includes(cycle.c[i].id)) {
                responcse = false;
            }
        }
        return responcse
    }

    function getHeaderName() {

        if (selectedTab === "initial") {
            return 'LEARNING MANAGEMENT'
        } else if (selectedTab === "nameCard") {
            return 'ADD CURRICULUM'
        } else if (selectedTab === "cycleYearCard") {
            return 'CYCLE YEARS'
        } else if (selectedTab === "curriculumList") {
            return 'CURRICULUM'
        }
    }

    function getNamesForIds() {
        var cycles = [];


        for (const i in cyclicYears) {
            for (const j in cyclicYears[i].c) {
                if (selectedCycles.includes(cyclicYears[i].c[j].id)) {
                    var lil = cyclicYears[i].c[j].year;
                    var soc = cyclicYears[i].c[j].year;
                    if (cyclicYears[i].c[j].renamed && cyclicYears[i].c[j].renamed.length > 0) {
                        soc = cyclicYears[i].c[j].renamed;
                    }
                    cycles.push({ lil: lil, school: soc });

                }
            }
        }

        return cycles
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY.');
    }

    function getOwnerName(curriculum) {

        var teacherName = ""
        if (props.teachers && props.teachers.length > 0 && curriculum.attributes.createdBy) {
            var others = props.teachers.filter(
                (teacher) =>
                    teacher.id === curriculum.attributes.createdBy
            );
            if (others && others.length > 0) {
                teacherName = others[0].attributes.Name
            }
        }

        var on = getTimeStringForDate(curriculum.attributes.createdAt);

        var createdFor = curriculum.attributes.type;
        var ownerId = curriculum.attributes.ownerId;

        if (props.selectedSchool && (!createdFor || createdFor.length === 0)) {
            if (ownerId === props.selectedSchool.id) {
                createdFor = props.t('School');
            } else if (ownerId === props.selectedSchool.attributes.instituteId) {
                createdFor = props.t('Institute');
            } else if (ownerId === props.selectedSchool.attributes.franchiseId) {
                createdFor = props.t('Franchise');
            }
        }

        if (teacherName && teacherName.length > 0) {
            return "Created By " + teacherName + " for " + createdFor + " on " + on;
        } else {
            return "Created For " + createdFor + " on " + on;
        }
    }


    function handelOnClickBack() {
        if (selectedTab === "initial") {
            setHideButton(true);
        } else if (selectedTab === "nameCard") {
            if (displayCurriculums && displayCurriculums.length > 0) {
                showBackButton(false);
                setSelectedTab("curriculumList");
            } else {
                setSelectedTab("initial");
                setHideButton(true);
            }

        } else if (selectedTab === "cycleYearCard") {
            setSelectedTab("nameCard");
            setHideButton(false);
        } else if (selectedTab === "curriculumList") {
            setHideButton(false);
        }
    }

    function handelAddCurriculum() {
        showBackButton(true);
        setSelectedTab("nameCard");
        setNameError(false);
        setCurriculumName("");
        setSelectedCycles([])
        setLoading(false);
        setCyclicYears(initialCycle);
    }

    function functionAddNewCycle(cycle, newCycle) {
        if (newCycle) {
            var exClasses = [...cycle.c];
            var tempForId = exClasses[exClasses.length - 1];

            var newId = parseFloat(tempForId.id) + 0.1;
            var newObj = { id: newId + "", i: exClasses.length, year: "", renamed: newCycle, isCustom: true }
            exClasses.push(newObj);
            var tempCycle = cycle;
            tempCycle.c = exClasses;

            var list = [...cyclicYears];
            list[cycle.i] = tempCycle;

            setCyclicYears(list);
            var temp = addNewCycles.filter(cycleId => cycleId !== cycle.id);
            setnewCycles(temp);

            handelCycle(newObj);
        }


    }

    const canShowCheckboxForAllSchool = () => {
        let returnValue = false

        if (Parse.User.current() && Parse.User.current().attributes.instituteId && Parse.User.current().attributes.isSuperUser) {
            if (Parse.User.current().attributes.teacherId) {
                if (props.userTeacher && props.userTeacher.attributes.instituteId && props.userTeacher.attributes.instituteId.length > 0) {
                    returnValue = true
                }
            } else {
                returnValue = true
            }
        }
        return returnValue
    }

    const canShowCheckboxForAccessBasedSchool = () => { 
        if (Parse.User.current() && Parse.User.current().attributes.franchiseId && props.schoolId == "franchise") { 
            return true
        }
        return false
    }

    return (
        <React.Fragment >

            <TopBar title={getHeaderName()}
                buttonName={buttonText}
                backButton={backButton}
                hideButton={selectedTab == "curriculumList"?hideButton:true}
                onClickAdd={handelAddCurriculum}
                onClickBackButton={handelOnClickBack}
                reorderButton={false}
                hidePublish={selectedTab == "curriculumList"?true:false}
                learningSession={"/learning-session/" + props.schoolId}
            />

            {selectedTab === "initial" && <Card>
                <CardBody className="text-center" >
                    <Row className="justify-content-center">
                        <Col sm={4}>
                            <div className="maintenance-img">
                                <img src={Image} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>
                    </Row>
                    <h3 className="mt-5">{props.t('Set up Curriculum for your school')}</h3>
                    <p>{props.t('Take your learning online just in few steps')}.</p>

                    <button type="button"
                        className="btn btn-primary "
                        onClick={() => {
                            showBackButton(true);
                            setSelectedTab("nameCard");
                        }}  >
                        {props.t('Start')}
                    </button>
                </CardBody>
            </Card>}

            {selectedTab === "nameCard" && <Card>
                <CardBody>
                    <Row >
                        <Col sm={7}>
                            <AvForm className="outer-repeater" >
                                <div data-repeater-item className="outer">
                                    <FormGroup >
                                        <Label ><h3 className="mt-5">{props.t('What do you want to name  your school Curriculum ?')}</h3> </Label>
                                        <AvField
                                            type="text"
                                            name="name"
                                            placeholder={props.t('Curriculum Name')}
                                            className="form-control"
                                            value={curriculumName}

                                            onChange={(e) => {
                                                if (e.target.value.length > 0 && nameError) {
                                                    setNameError(false);
                                                }
                                                setCurriculumName(e.target.value);
                                            }}

                                        />
                                        {nameError && <p className="text-danger"> {props.t('Curriculum name can not be empty')}</p>}


                                    </FormGroup>
                                    {canShowCheckboxForAllSchool() && <FormGroup>
                                        <div className="custom-control custom-checkbox mb-2 ml-3" >
                                            <input type="checkbox" className="custom-control-input" id="instituteCheck" onChange={() => { setForInstitute(!forInstitute); }} checked={forInstitute} />
                                            <label className="custom-control-label" onClick={() => { setForInstitute(!forInstitute); }} >{props.t('Apply this curriculum to all schools')}</label>
                                        </div>
                                    </FormGroup>}
                                    {canShowCheckboxForAccessBasedSchool() && <FormGroup>
                                        <div className="custom-control custom-checkbox mb-2 ml-3"  >
                                            <input type="checkbox" className="custom-control-input"  onChange={() => { setAccessBased(!accessBased); }} checked={accessBased} />
                                            <label className="custom-control-label m-1" id="access-based" onClick={() => { setAccessBased(!accessBased); }} >{props.t('Access based  ')}</label>
                                        </div>
                                    </FormGroup>}
                                    {canShowCheckboxForAccessBasedSchool() && <UncontrolledTooltip
                                        placement="right" target="access-based">
                                            Only the schools you grant permission will have access to this curriculum.
                                    </UncontrolledTooltip>}
                                </div>
                            </AvForm>
                            <button type="button"
                                className="btn btn-primary "
                                onClick={() => {
                                    if (curriculumName && curriculumName.length > 0) {
                                        setSelectedTab("cycleYearCard");
                                    } else {
                                        setNameError(true);
                                    }
                                }}  >
                                {props.t('Next')}
                            </button>
                        </Col>
                        <Col sm={5}>
                            <div className="maintenance-img">
                                <img src={contentImage} alt="" className="img-fluid mx-auto d-block" />
                            </div>
                        </Col>

                    </Row>
                </CardBody>
            </Card>}

            {selectedTab === "cycleYearCard" &&
                <div className="row ">
                    <Col >
                        <div>
                            <p className="text-muted mr-7">{props.t('Please click on Each Box next to each Cycle Year that is represented at your school. If you use different terminology for each cycle year you may edit them.')}</p>
                            <u><p className="text-dark mr-7">{props.t('Cycles are based on National Education Policy (NEP) 2020')} </p></u>
                            <div className="float-right mb-2">
                                {cycleYearError && <p className="text-danger "> {props.t('Please select Cycle Years')}</p>}
                                {loading ? <button type="button" className="btn btn-dark waves-effect waves-light float-end m-2 ">
                                    <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                                </button>
                                    :
                                    <button type="button"
                                        className="btn btn-primary float-end"
                                        onClick={() => {
                                            if (selectedCycles && selectedCycles.length > 0) {
                                                var cycles = [];
                                                var list = getNamesForIds();
                                                for (const i in list) {
                                                    cycles.push({ lil: list[i].lil, school: list[i].school });
                                                }
                                                var curriculumObject = { name: curriculumName, cycles: cycles };
                                                setLoading(true);
                                                props.onAddNew(curriculumObject, forInstitute, accessBased)
                                            } else {
                                                setCycleYearError(true);
                                            }
                                        }}  >
                                        {props.t('Next')}
                                    </button>}
                            </div>
                        </div>
                        <Row className="justify-content-center">
                            {cyclicYears.map((cycle) => (

                                <Col xl="4" sm="4" key={cycle.id}>
                                    <Card outline color="primary" className="border  mt-2" style={{ 'minHeight': '5.5cm' }}  >
                                        <CardBody>
                                            <div className="custom-control custom-checkbox">
                                                <Input type="checkbox" value="2" className="custom-control-input" onChange={() => (handelWholeCycle(cycle))} id={cycle.id} checked={selectedAll(cycle)} />
                                                <Label className="custom-control-label" htmlFor={cycle.id}>{cycle.title}</Label>
                                            </div>
                                            <div className="ml-3">
                                                {cycle.c.map((year) => (
                                                    <div className="custom-control custom-checkbox mt-2 ml-3 d-flex" key={year.id}>
                                                        <Input type="checkbox" value="2" className="custom-control-input m-1" id={year.id} onChange={() => (handelCycle(year))} checked={selectedCycles.includes(year.id) ? true : false} />
                                                        <Label className="custom-control-label m-1" htmlFor={year.id}></Label>
                                                        <Editable
                                                            className={'d-flex'}
                                                            alwaysEditing={false}
                                                            onValidated={(value) => {
                                                                var list = [...cyclicYears];
                                                                list[cycle.i].c[year.i].renamed = value;
                                                                setCyclicYears(list);
                                                            }}
                                                            onCancel={(v) => {
                                                                var list = [...cyclicYears];
                                                                list[cycle.i].c[year.i].renamed = undefined;
                                                                setCyclicYears(list);
                                                            }}
                                                            disabled={false}
                                                            editText={selectedCycles.includes(year.id) ? "" : " ✎"}
                                                            id={null}
                                                            isValueClickable={false}
                                                            mode="inline"
                                                            placement="top"
                                                            initialValue={year.renamed && year.renamed.length > 0 ? year.renamed : year.year}
                                                            showText
                                                            type="textfield"
                                                            validate={(value) => {
                                                                if (!value) {
                                                                    return props.t("can not be empty")
                                                                }
                                                            }}
                                                        />
                                                    </div>))
                                                }
                                                <div>
                                                    {!addNewCycles.includes(cycle.id) && <button
                                                        type="button"
                                                        className="btn btn-primary btn-sm m-2"
                                                        onClick={() => {
                                                            if (addNewCycles.includes(cycle.id)) {
                                                                var temp = addNewCycles.filter(cycleId => cycleId !== cycle.id);
                                                                setnewCycles(temp)
                                                            } else {
                                                                var list = [...addNewCycles];
                                                                list.push(cycle.id);
                                                                setnewCycles(list)
                                                            }

                                                        }}
                                                    >
                                                        {props.t('Add Class')}
                                                    </button>}

                                                    {
                                                        addNewCycles.includes(cycle.id) && <div>
                                                            <AvForm className="outer-repeater mt-3"

                                                                onValidSubmit={(e, v) => {
                                                                    setCycleYearError(false);
                                                                    functionAddNewCycle(cycle, v.name);
                                                                }}
                                                            >
                                                                <div data-repeater-item className="outer">
                                                                    <FormGroup >
                                                                        <AvField
                                                                            type="text"
                                                                            name={"name"}
                                                                            placeholder={props.t('Cycle Name')}
                                                                            className="form-control"
                                                                            required


                                                                        />

                                                                    </FormGroup>
                                                                </div>

                                                                <div className="list-inline font-size-20 contact-links mb-0">
                                                                    <button
                                                                        className="btn btn-primary btn-sm m-1 "
                                                                        type="submit"
                                                                    // onClick={(e) => {
                                                                    //     functionAddNewCycle(cycle,e.target.value);
                                                                    // }}  
                                                                    >
                                                                        {props.t('Add')}
                                                                    </button>
                                                                    <button type="button"
                                                                        className="btn btn-danger btn-sm m-1"
                                                                        onClick={() => {
                                                                            var temp = addNewCycles.filter(cycleId => cycleId !== cycle.id);
                                                                            setnewCycles(temp)
                                                                        }}  >
                                                                        {props.t('Close')}
                                                                    </button>
                                                                </div>
                                                            </AvForm>



                                                        </div>
                                                    }




                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </Col>
                            ))}
                        </Row>



                        <div className="float-right mb-2">

                            {cycleYearError && <p className="text-danger "> {props.t('Please select Cycle Years')}</p>}

                            {loading ? <button type="button" className="btn btn-dark waves-effect waves-light float-end m-2 ">
                                <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Saving')}
                            </button>
                                :
                                <button type="button"
                                    className="btn btn-primary float-end"
                                    onClick={() => {
                                        if (selectedCycles && selectedCycles.length > 0) {
                                            var cycles = [];
                                            var list = getNamesForIds();
                                            for (const i in list) {
                                                cycles.push({ lil: list[i].lil, school: list[i].school });
                                            }
                                            var curriculumObject = { name: curriculumName, cycles: cycles };
                                            setLoading(true);
                                            props.onAddNew(curriculumObject, forInstitute,accessBased)
                                        } else {
                                            setCycleYearError(true);
                                        }
                                    }}  >
                                    {props.t('Next')}
                                </button>}

                        </div>


                    </Col>
                </div>
            }

            {selectedTab === "curriculumList" &&
                <Row >
                    <Col lg="12">
                        <div className="">
                            <div className="table-responsive">
                                <Table className="project-list-table table-nowrap table-centered table-borderless">
                                    <thead>
                                        <tr>
                                            <th className="col-1" scope="col" style={{ width: "100px" }}>#</th>
                                            <th className="col-9" scope="col">Name</th>
                                            {props.schoolId == "franchise" && <th className="col-2" scope="col" align="right">Access</th>}
                                            <th className="col-2" scope="col" align="right">Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {console.log(displayCurriculums)}
                                        {displayCurriculums.map((curriculum, key) =>
                                            <tr key={curriculum.id + key} >
                                                <td><img src={Image} alt="" className="avatar-sm" /></td>
                                                {
                                                    editCurriculum && editCurriculum.id === curriculum.id ?
                                                        <td>
                                                            <AvForm className="outer-repeater mt-3" >
                                                                <div data-repeater-item className="outer">
                                                                    <FormGroup >
                                                                        <AvField
                                                                            type="text"
                                                                            name="name"
                                                                            placeholder={props.t('Curriculum Name')}
                                                                            className="form-control"
                                                                            value={curriculumName}

                                                                            onChange={(e) => {
                                                                                if (e.target.value.length > 0 && nameError) {
                                                                                    setNameError(false);
                                                                                }
                                                                                setCurriculumName(e.target.value);
                                                                            }}
                                                                        />
                                                                        {nameError && <p className="text-danger"> {props.t('Curriculum name can not be empty')}</p>}
                                                                    </FormGroup>
                                                                </div>
                                                            </AvForm>
                                                        </td>
                                                        :
                                                        <td to={"/cur:" + curriculum.id}
                                                            style={{ cursor: "pointer" }}
                                                            onClick={() => {
                                                                props.onSelectCurriculum(curriculum.id);
                                                                // props.onClicItem("cycleYears");
                                                                props.history.push("/lms-syllabus/" + props.schoolId + "/" + curriculum.id);
                                                                showBackButton(true);
                                                            }}>
                                                            <h5 className="text-truncate font-size-14"><Link to="#" className="text-dark">{curriculum.attributes.name}</Link></h5>
                                                            <p className="text-muted mb-0">{getOwnerName(curriculum)}</p>
                                                        </td>
                                                }
                                                {props.schoolId == "franchise" && <td>
                                                    {curriculum.attributes.accessBased === true && (selectedTab === "curriculumList") && <Link to={`/curriculum-access/${Parse.User.current().attributes.franchiseId}/${curriculum.id}`}>
                                                        <div className="d-flex" >
                                                            <button className='btn btn-success btn-sm btn-rounded' >
                                                                Access
                                                            </button>
                                                        </div>
                                                    </Link>}
                                                </td>}
                                                <td>
                                                    {
                                                        editCurriculum && editCurriculum.id === curriculum.id ?
                                                            <div className="list-inline font-size-20 contact-links mb-0">
                                                                <button
                                                                    type="button"
                                                                    className="btn btn-primary mr-1 "
                                                                    onClick={() => {
                                                                        if (curriculumName && curriculumName.length > 0) {
                                                                            var cur = editCurriculum;
                                                                            cur.set("name", curriculumName);
                                                                            props.onEditCurriculum(cur);
                                                                            setEditCurriculum(undefined);
                                                                            setCurriculumName("");
                                                                            setNameError(false);
                                                                        } else {
                                                                            setNameError(true);
                                                                        }
                                                                    }}  >
                                                                    {props.t('Save')}
                                                                </button>
                                                                <button type="button"
                                                                    className="btn btn-danger "
                                                                    onClick={() => {
                                                                        setEditCurriculum(undefined);
                                                                        setCurriculumName("");
                                                                        setNameError(false);
                                                                    }}  >
                                                                    {props.t('Close')}

                                                                </button>
                                                            </div>
                                                            :
                                                            <ul className="list-inline font-size-20 contact-links mb-0 float-right">

                                                                {CommonFunctions.canEdit(curriculum, props.userTeacher) &&
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setEditCurriculum(curriculum);
                                                                            setCurriculumName(curriculum.attributes.name);
                                                                        }}
                                                                        id={"sub-edit" + key}>
                                                                        <i className="bx bx-edit-alt"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-edit" + key}>
                                                                            {props.t('Edit')}
                                                                        </UncontrolledTooltip>

                                                                    </li>}

                                                                {CommonFunctions.canEdit(curriculum, props.userTeacher) && <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        setDeleteCurriculum(curriculum);
                                                                    }}
                                                                    id={"sub-delete" + key}>
                                                                    <i className="bx bx-trash text-danger"></i>
                                                                    <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                        {props.t('Delete')}
                                                                    </UncontrolledTooltip>

                                                                </li>

                                                                }
                                                                <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    onClick={() => {
                                                                        props.onSelectCurriculum(curriculum.id);
                                                                        // props.onClicItem("cycleYears");
                                                                        props.history.push("/lms-syllabus/" + props.schoolId + "/" + curriculum.id);
                                                                        showBackButton(true);
                                                                    }}
                                                                    id={"sub-cyc" + key}>
                                                                    <i className="bx bx-right-arrow-circle"></i>
                                                                    <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                        {props.t('Details')}
                                                                    </UncontrolledTooltip>
                                                                </li>
                                                            </ul>
                                                    }
                                                </td>
                                            </tr>
                                        )}
                                    </tbody>
                                </Table>
                            </div>
                        </div>
                    </Col>
                </Row>
            }
            {deleteCurriculum ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        props.onDeleteCurriculum(deleteCurriculum);
                        setDeleteCurriculum(undefined);
                        setsuccess_dlg(true);
                    }}
                    onCancel={() => {
                        setDeleteCurriculum(undefined);
                        setsuccess_dlg(false);

                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {props.t('Your Curriculum has been deleted.')}
                </SweetAlert>
            ) : null}
        </React.Fragment>
    );
}
export default withNamespaces()(Curriculums);