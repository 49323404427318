
import React, { useState, useEffect }from 'react';
import {Row, Col, Form, FormGroup,Input, CardBody, Label } from "reactstrap";
import { Card } from '@material-ui/core';
import Knob from '../../AllCharts/knob/knob';
import Iframe from "react-iframe";
import VimeoUpload from "vimeo-upload-me";
import Parse from 'parse';
import $ from "jquery";
import Lightbox from "react-image-lightbox";


const BasicDetails = (props) => {

    const [courseName, setCourseName] = useState();
    const [courseDescription, setCourseDescription] = useState();
    const [coursePreRequirement, setCoursePreRequirement] = useState();
    const [courseLearning, setCourseLearning] = useState();

    const [courseNameError, setCourseNameError] = useState();
    const [courseDescriptionError, setCourseDescriptionError] = useState();
    const [coursePreRequirementError, setCoursePreRequirementError] = useState();
    const [courseLearningError, setCourseLearningError] = useState();

    const [introVideo, setIntroVideo] = useState();
    const [introThumbnail, setIntroThumbnail] = useState();
    const [videoUrl, setVideoUrl] = useState();

    const [courseAuthor, setCourseAuthor] = useState();
    const [courseAuthor2, setCourseAuthor2] = useState();

    const [progressText, setProgressText] = useState(false);
    const [uploadVideoProgress, setVideoUploadProgress] = useState(1);

    const [introImage, setIntroImage] = useState();
    const [uploadImageProgress, setImageUploadProgress] = useState(1);
    const [previewImage, setPreviewImage] = useState();
    
    const [lightBox,setLightBox] = useState();

    useEffect(() => {
        if (props.courseObject) {
            setCourseName(props.courseObject.attributes.name)
            setCourseDescription(props.courseObject.attributes.description)
            setCoursePreRequirement(props.courseObject.attributes.requirements)
            if (props.courseObject.attributes.whatyoulearn) {
                setCourseLearning(props.courseObject.attributes.whatyoulearn.join("\n"))
            }
            setIntroImage(props.courseObject.attributes.introImage)
            setVideoDetails(props.courseObject);
            setCourseAuthor(props.courseObject.attributes.authorId)
            setCourseAuthor2(props.courseObject.attributes.authorId2)
        }
    }, [props.courseObject]);

    const setVideoDetails = (object) => {
        if (object.attributes.introVideo) {
            let mainUrl = object.attributes.introVideo;
            let spliturl = mainUrl.split("/");

            let videoId = spliturl[spliturl.length - 2];
            let videokey = spliturl[spliturl.length - 1];
        
            setVideoUrl("https://player.vimeo.com/video/" + videoId + "?h=" + videokey);
            setIntroVideo(mainUrl);
            setIntroThumbnail(object.attributes.introThumbnail);
        }
    }


    const imageUploadPreviev = (e) => {
        var fileExtension = e.target.files[0].name.split(".");
        setIntroImage(undefined);
        setPreviewImage(URL.createObjectURL(e.target.files[0]));
        onImageUpload(e.target.files[0], fileExtension[fileExtension.length-1])
    }

    function getBase64(file, cb) {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
          var resultWithName = { name: file.name, result: reader.result };
          cb(resultWithName);
        };
        reader.onerror = function (error) {
          console.log("Error: ", error);
        };
      
    }

    const onImageUpload=(selectedFile, fileExtention) =>{
        
        var name = "intro_image_"+props.courseObject.id + "." + fileExtention;
        
        getBase64(selectedFile, (resultWithName) => {
            var parseFile = new Parse.File(name, { base64: resultWithName.result, });
            setProgressText(true);
            setImageUploadProgress(1);
            parseFile.save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setImageUploadProgress(value * 100);
                    }
                }
            }).then(
                result => {
                    setProgressText(false)
                    let obj = props.courseObject;

                    obj.set("introImage", result._url);
                    setIntroImage(result._url)

                    props.onSaveCourseObject(obj);
                },
                error => {
                    setProgressText(false)
                }
                
            );
        });
    }
    const videoUploadPreviev = (e) => {
        setVideoUrl(undefined);
        setIntroVideo(undefined);
        setIntroThumbnail(undefined);
        onVideoUpload(e);
    }

    function onVideoUpload (e) {
        if (e.target.files[0]) {
            const accessToken = "bc7a5ee29880658b6f3689a45e63c5ec";
            setProgressText(true)
            var uploader = new VimeoUpload({
                name: e.target.files[0].name,
                description: e.target.files[0].name.replace(
                    /[&\/\\#,+()$~%'":*?<>{}]/g,
                    ""
                ),
                file: e.target.files[0],
                token: accessToken,
                upgrade_to_1080: true,
                privacy: { view: "unlisted" },
                onError: (data) => {
                    console.log(data);
                },
                onProgress: (data) => {
                    let value = Math.floor((data.loaded / data.total) * 100);
                    setVideoUploadProgress(value)
                },
                onComplete: (videoId, index) => {
                    setVideoUploadProgress(100)
                    var settings = {
                        url: "https://api.vimeo.com/videos/" + videoId,
                        method: "PATCH",
                        timeout: 0,
                        headers: {
                            Authorization: "Bearer bc7a5ee29880658b6f3689a45e63c5ec",
                            "Content-Type": "application/json",
                        },
                        data: JSON.stringify({ privacy: { view: "unlisted" } }),
                    };
                    $.ajax(settings).done((response) => {
            
                        setProgressText(false)
                        let obj = props.courseObject;

                        obj.set("introVideo", response.link);
                        obj.set("introThumbnail", response.pictures.sizes[4].link);

                        setIntroVideo(response.link)
                        setIntroThumbnail(response.pictures.sizes[4].link);

                        props.onSaveCourseObject(obj);
                        setVideoDetails(obj)
                    });
            
                },
            });
            uploader.upload();
        }
    }

    const onClickSave = (isNext) => {

        let save = true;
        if (!courseName) {
            save = false;
            setCourseNameError(true);
        }
        if (!courseDescription) {
            save = false;
            setCourseDescriptionError(true)
        }
        // if (!coursePreRequirement) {
        //     save = false;
        //     setCoursePreRequirementError(true)
        // }
        if (!courseLearning) {
            save = false;
            setCourseLearningError(true)
        }
        if (save) {
            let tempObject = props.courseObject;
            tempObject.set("name", courseName);
            tempObject.set("description", courseDescription);
            tempObject.set("requirements", coursePreRequirement);
            if (courseLearning) {
                var tags = courseLearning.split(/\r?\n/);
                tempObject.set("whatyoulearn", tags);
            } else {
                tempObject.set("tempObject", []);
            }
            tempObject.set("authorId", courseAuthor);
            tempObject.set("authorId2", courseAuthor2);
            props.onSaveCourseObject(tempObject, isNext ? "2" : undefined);
        }

    }
    
    return (
        
        <Card className="mb-4">

            <CardBody>

                <Label htmlFor="formmessage">Intro image&video: </Label>
                <Row>

                    
                    <Col sm="6">
                        <Card className="border float-right " style={{ width: "8cm" }} >
                            <CardBody className="border m-3" style={{ height: "4cm" }}>
                               

                                {(introImage) ?

                                    <img
                                        src={previewImage ?? introImage}
                                        style={{ cursor: "pointer" }}
                                        className="rounded " alt="Skote" width="200" height="150"

                                        onClick={() => {
                                            setLightBox({ show: true, images: [previewImage ?? introImage], name: "" });
                                        }}

                                    />
                                    : <div className="mb-mt-4 text-center " style={{ fontSize: "50px" }}>
                                        {progressText ?
                                            <Knob
                                                value={uploadImageProgress}
                                                fgColor="#5468da"
                                                thickness={0.12}
                                                readOnly={true}
                                                height={70}
                                                width={70}
                                            />
                                            :
                                            <i className="bx bx-image-alt"></i>}
                                    </div>
                                }
                               

                            </CardBody>
                            <div className="card-footer bg-transparent border-top text-center">
                                <Label style={{ cursor: "pointer" }} className="text-primary" htmlFor="intro_image">Upload Intro Image</Label>
                            </div>
                        </Card>
                    </Col>
                    <Col sm="6">
                        <Card className="border " style={{ width: "8cm" }} >
                            <CardBody className="border m-3" style={{ height: "4cm" }}>
                                {videoUrl ?

                                    <Iframe
                                        url={videoUrl}
                                        style={{ cursor: "pointer",height: "3.5cm" }}
                                        scrolling={"no"}
                                        allowFullScreen={true}
                                        
                                        frameBorder={0}

                                        className="text-center " 
                                    />
                                    : <div className="mb-mt-4 text-center " style={{ fontSize: "50px" }}>
                                        {progressText ?
                                            <Knob
                                                value={uploadVideoProgress}
                                                fgColor="#5468da"
                                                thickness={0.12}
                                                readOnly={true}
                                                height={70}
                                                width={70}
                                            />
                                            :
                                            <i className="bx bx-play-circle"></i>}
                                    </div>
                                }
                               

                            </CardBody>
                            <div className="card-footer bg-transparent border-top text-center" >
                                <Label style={{ cursor: "pointer" }} className="text-primary" htmlFor="intro_video" >Upload Intro Video</Label>
                            </div>
                        </Card>
                    </Col>

                    <input type="file"
                        className="sr-only"
                        id="intro_image"
                        name="file"
                        accept="image/*"
                        onChange={imageUploadPreviev}
                    />
                    <input type="file"
                        className="sr-only"
                        id="intro_video"
                        name="file"
                        accept={`video/mp4,video/x-m4v,video/*`}
                        onChange={videoUploadPreviev}
                        multiple
                    />
                </Row>
                
               


                 {/* <ModalVideo
                    channel="vimeo"
                    isOpen={videoModal}
                    videoId={videoId}
                    onClose={() => {setVideoMoal(false)}}
                    /> */}

                
                <Form className="outer-repeater mt-5">
                    <div data-repeater-list="outer-group" className="outer">
                        <div data-repeater-item className="outer">
                          

                            <FormGroup>
                                <Label htmlFor="formmessage">Course Title :*</Label>
                                <Input
                                    type="textarea"
                                    id="formmessage"
                                    className="form-control"
                                    rows="1"
                                    required
                                    placeholder="Course Title"
                                    value={courseName}
                                    onChange={(e) => {
                                        if (courseNameError) {
                                            setCourseNameError(false);
                                        }
                                        setCourseName(e.target.value);
                                    }}
                                />
                                {courseNameError&&<p className='text-danger'>Please enter course name</p>}
                            </FormGroup>
                            
                            <FormGroup>
                                <Label htmlFor="formmessage">Course Description :*</Label>
                                <Input
                                    type="textarea"
                                    id="formmessage"
                                    className="form-control"
                                    rows="4"
                                    required
                                    placeholder="Describe the course"
                                    value={courseDescription}
                                    onChange={(e) => {
                                        if (courseDescriptionError) {
                                            setCourseDescriptionError(false);
                                        }
                                        setCourseDescription(e.target.value);
                                    }}
                                />
                                {courseDescriptionError&&<p className='text-danger'>Please enter description </p>}
                            </FormGroup>
                            
                            <FormGroup>
                                <Label htmlFor="formmessage">Course Pre Requirement :</Label>
                                <Input
                                    type="textarea"
                                    id="formmessage"
                                    className="form-control"
                                    rows="4"
                                    placeholder="Pre Requirement"
                                    value={coursePreRequirement}
                                    onChange={(e) => {
                                        if (coursePreRequirementError) {
                                           setCoursePreRequirementError(false);
                                        }
                                        setCoursePreRequirement(e.target.value);
                                    }}
                                />
                                {coursePreRequirementError&&<p className='text-danger'>Please enter Pre Requirement </p>}
                            </FormGroup>
                            
                            <FormGroup>
                                <Label htmlFor="formmessage">What You Learn :*</Label>
                                <Input
                                    type="textarea"
                                    id="formmessage"
                                    className="form-control"
                                    placeholder="Describe what user will learn"
                                    rows="4"
                                    required
                                    value={courseLearning}
                                    onChange={(e) => {
                                         if (courseLearningError) {
                                           setCourseLearningError(false);
                                        }
                                        setCourseLearning(e.target.value);
                                    }}
                                />
                                {courseLearningError&&<p className='text-danger'>Please enter What You Learn </p>}
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="formmessage">Course Coordinator :*</Label>
                                <select className="form-control"
                                    id={"select" }
                                    value={courseAuthor}
                                    onChange={(e) => {
                                        setCourseAuthor(e.target.value)
                                    }}
                                >
                                   
                                    {props.teachers&&props.teachers.map((teacher) => (
                                        <option key={teacher.id} value={teacher.id}>{teacher.attributes.Name}</option>
                                    ))}
                                </select>
                                {courseLearningError&&<p className='text-danger'>Please enter What You Learn </p>}
                            </FormGroup>

                            <FormGroup>
                                <Label htmlFor="formmessage">Course Coordinator 2 :</Label>
                                <select className="form-control"
                                    id={"select" }
                                    value={courseAuthor2}
                                    onChange={(e) => {
                                        setCourseAuthor2(e.target.value)
                                    }}
                                >
                                   
                                    {props.teachers&&props.teachers.map((teacher) => (
                                        <option key={teacher.id} value={teacher.id}>{teacher.attributes.Name}</option>
                                    ))}
                                </select>
                                
                            </FormGroup>
                          
                        </div>
                    </div>
                    
                </Form>

                
               { props.saveInprogress ? <div>
                    <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>
                    <button className="btn btn-primary m-1" > <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Saving  </button>
                </div>
                    :
                <div>
                    <button className="btn btn-primary m-1" onClick={()=>{onClickSave()}}> Save </button>
                    <button className="btn btn-primary m-1" onClick={()=>{onClickSave(true)}}> Save & Next</button>
                </div>}



            </CardBody>

             {lightBox && lightBox.show ? (
                <Lightbox
                    mainSrc={lightBox.images}
                    enableZoom={false}
                    imageCaption={
                        lightBox.name
                    }
                    onCloseRequest={() => { setLightBox({ show: false, images: [] }) }}
                />
            ) : null}
           
        </Card>
    );
}
export default BasicDetails;