import Parse from 'parse';
import { printLog } from './loghelper';
import * as Constants from '../../Constents';
const getTeacherFromParse = (id, isLocal) => {
  printLog("HL: teacher_helper : getTeacherFromParse")
  try {
    return new Promise((resolve, reject) => {
      var Teacher = Parse.Object.extend("Teacher");
      var query = new Parse.Query(Teacher);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.get(id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}

export const getTeacherForIds = (ids, isLocal) => {
  printLog("HL: teacher_helper : getTeacherForIds")
  try {

    return new Promise((resolve, reject) => {
      var Teacher = Parse.Object.extend("Teacher");
      var query = new Parse.Query(Teacher);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.containedIn("objectId", ids)
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}


export const getObject = (table, id, isLocal) => {
  printLog("HL: teacher_helper : getObject")
  try {
    return new Promise((resolve, reject) => {
      var Table = Parse.Object.extend(table);
      var query = new Parse.Query(Table);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.get(id).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}



const requestForWebAccessRemoval = (email, teacherRole, teacherId, levelId, level) => {
  printLog("HL: teacher_helper : requestForWebAccessRemoval")

  var params = null
  if (level === Constants.SCHOOL_LEVEL) {
    params = { email: email, teacherId: teacherId, schoolId: levelId };
  } else if (level === Constants.INSTITUTE_LEVEL) {
    params = { email: email, teacherId: teacherId, instituteId: levelId };
  } else if (level === Constants.FRANCHISE_LEVEL) {
    params = { email: email, teacherId: teacherId, franchiseId: levelId };
  }


  console.log(params)

  try {

    return new Promise((resolve, reject) => {


      Parse.Cloud.run("removeWebAccesstoStaff", params).then(
        (result) => {
          resolve(result);
          console.log("WebAccess Response")
          console.log(result)
        },
        (error) => {
          resolve(null);
        }
      );


    });
  } catch (error) { }
}



const requestForWebAccess = (email, teacherRole, teacherId, levelId, level, instituteId) => {
  printLog("HL: teacher_helper : requestForWebAccess")


  var params = null
  let refferedBy = Parse.User.current().attributes.refferedBy;
  if (level === Constants.SCHOOL_LEVEL) {
    params = { email: email, teacherRole: teacherRole, teacherId: teacherId, schoolId: levelId, instituteId: instituteId, refferedBy: refferedBy };
  } else if (level === Constants.INSTITUTE_LEVEL) {
    params = { email: email, teacherRole: teacherRole, teacherId: teacherId, instituteId: levelId, refferedBy: refferedBy };
  } else if (level === Constants.FRANCHISE_LEVEL) {
    params = { email: email, teacherRole: teacherRole, teacherId: teacherId, franchiseId: levelId, refferedBy: refferedBy };
  }


  console.log(params)

  try {

    return new Promise((resolve, reject) => {


      Parse.Cloud.run("grantWebAccesstoStaff", params).then(
        (result) => {
          resolve(result);
          console.log("WebAccess Response")
          console.log(result)
        },
        (error) => {
          resolve(null);
        }
      );


    });
  } catch (error) { }
}

const deleteTeacherFromParse = (id) => {
  printLog("HL: teacher_helper : deleteTeacherFromParse")
  try {

    return new Promise((resolve, reject) => {
      const Teacher = Parse.Object.extend("Teacher");
      var teacher = new Teacher();
      teacher.id = id;
      teacher.set("deleted", true);
      teacher.set("deletedOn", new Date());
      if (Parse.User.current().attributes.teacherId) {
        teacher.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        teacher.set("deletedBy", Parse.User.current().id);
      }

      teacher.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    });
  } catch (error) { }
}


export const saveTeachers = teachers => {
  printLog("HL: teacher_helper : saveTeachers")

  try {
    return new Promise((resolve, reject) => {
      Parse.Object.saveAll(teachers).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
          console.log("Error", error);
        }
      );
    })
  } catch (error) { }
}


const getSchoolAttendance = (schoolId) => {
  printLog("HL: teacher_helper : getSchoolAttendance")
  try {

    return new Promise((resolve, reject) => {
      var Attendance = Parse.Object.extend("Attendance");
      var mainQuery = new Parse.Query(Attendance);
      mainQuery.equalTo("School", schoolId)
      let currentDate = new Date()
      let startTime = new Date(currentDate.setHours(0, 0, 0, 0))
      let endTime = new Date(currentDate.setHours(23, 59, 59, 999))
      mainQuery.greaterThanOrEqualTo("createdAt", startTime)
      mainQuery.lessThanOrEqualTo("createdAt", endTime)
      mainQuery.limit(1000)
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}

const getTeacherAttendanceForDay = (schoolId) => {
  printLog("HL: teacher_helper : getTeacherAttendanceForDay")
  try {

    return new Promise((resolve, reject) => {
      var Teacher = Parse.Object.extend("TeacherAttendance");
      var mainQuery = new Parse.Query(Teacher);
      mainQuery.equalTo("schoolId", schoolId)
      let currentDate = new Date()
      let startTime = new Date(currentDate.setHours(0, 0, 0, 0))
      let endTime = new Date(currentDate.setHours(23, 59, 59, 999))
      mainQuery.greaterThanOrEqualTo("createdAt", startTime)
      mainQuery.lessThanOrEqualTo("createdAt", endTime)
      mainQuery.limit(1000)
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}

const getDaycareAttendanceForDay = (schoolId) => {
  printLog("HL: teacher_helper : getDaycareAttendanceForDay")
  try {

    return new Promise((resolve, reject) => {
      var Daycare = Parse.Object.extend("DaycareTimeSheet");
      var mainQuery = new Parse.Query(Daycare);
      mainQuery.equalTo("schoolId", schoolId)
      let currentDate = new Date()
      let startTime = new Date(currentDate.setHours(0, 0, 0, 0))
      let endTime = new Date(currentDate.setHours(23, 59, 59, 999))
      mainQuery.greaterThanOrEqualTo("createdAt", startTime)
      mainQuery.lessThanOrEqualTo("createdAt", endTime)
      mainQuery.limit(1000)
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
}

const getDeletedTeachersForSchoolId = (id) => {
  printLog("HL: teacher_helper : getDeletedTeachersForSchoolId")
  try {

    return new Promise((resolve, reject) => {
      var Teacher = Parse.Object.extend("Teacher");
      var mainQuery = new Parse.Query(Teacher);
      mainQuery.equalTo("SchoolID", id)
      mainQuery.equalTo("deleted", true)



      mainQuery.ascending("Name");
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}

function startOfMonth(date) {
  printLog("HL: teacher_helper : startOfMonth")


  return new Date(date.getFullYear(), date.getMonth(), 1);

}

function endOfMonth(date) {
  printLog("HL: teacher_helper : endOfMonth")


  let lastDayOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
  return lastDayOfMonth
}

export const getDeletedTeachersForSchoolIdForMonth = (schoolId, date) => {
  printLog("HL: teacher_helper : getDeletedTeachersForSchoolIdForMonth")

  let startDate = startOfMonth(date)
  let endDate = endOfMonth(date)

  try {
    return new Promise((resolve, reject) => {
      var Teacher = Parse.Object.extend("Teacher");
      var mainQuery = new Parse.Query(Teacher);
      mainQuery.equalTo("SchoolID", schoolId)
      mainQuery.greaterThanOrEqualTo("deletedOn", startDate)
      mainQuery.lessThanOrEqualTo("deletedOn", endDate)
      mainQuery.equalTo("deleted", true)
      mainQuery.ascending("Name");
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}



const getTeachersForSchoolId = (id, instituteId, updatedDate, isLocal) => {
  printLog("HL: teacher_helper : getTeachersForSchoolId")
  try {

    return new Promise((resolve, reject) => {
      var Teacher = Parse.Object.extend("Teacher");
      var query1 = new Parse.Query(Teacher);
      query1.equalTo("SchoolID", id)
      var mainQuery;
      if (instituteId) {
        var query2 = new Parse.Query(Teacher);
        query2.equalTo("instituteId", instituteId)
        mainQuery = Parse.Query.or(query1, query2);
      } else {
        mainQuery = query1;
      }

      if (updatedDate) {
        mainQuery.greaterThanOrEqualTo("updatedAt", new Date(updatedDate))
      } else {
        mainQuery.notEqualTo("deleted", true);
      }

      if (isLocal) {
        mainQuery.fromLocalDatastore();
        mainQuery.notEqualTo("deleted", true);
      }

      mainQuery.exists("Role");
      mainQuery.ascending("Name");
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}

export const getTeachersForFranchiseId = (id, updatedDate, isLocal) => {
  printLog("HL: teacher_helper : getTeachersForFranchiseId")
  try {

    return new Promise((resolve, reject) => {
      var Teacher = Parse.Object.extend("Teacher");
      var mainQuery = new Parse.Query(Teacher);
      mainQuery.equalTo("franchiseId", id)


      if (updatedDate) {
        mainQuery.greaterThanOrEqualTo("updatedAt", new Date(updatedDate))
      } else {
        mainQuery.notEqualTo("deleted", true);
      }

      if (isLocal) {
        mainQuery.fromLocalDatastore();
        mainQuery.notEqualTo("deleted", true);
      }

      mainQuery.exists("Role");
      mainQuery.ascending("Name");
      mainQuery.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
}

export const getTeacherForClassParse = classId => {
  printLog("HL: teacher_helper : getTeacherForClassParse")
  try {
    return new Promise((resolve, rejected) => {
      let temp = []
      temp.push(classId)
      let query = new Parse.Query('Teacher')
      query.containedIn('ClassRooms', temp)
      query.notEqualTo("deleted", true)
      query.find().then((teacherObj) => {
        resolve(teacherObj)
      })
    })
  } catch (error) { }
}
export const updateTeachers = teacherObjs => {
  printLog("HL: teacher_helper : updateTeachers")
  try {
    return new Promise((resolve, rejected) => {
      Parse.Object.saveAll(teacherObjs).then((teacherObj) => {
        resolve(teacherObj)
      })
    })
  } catch (error) {
    console.log(error)
  }
}

const editTeacher = (teacher) => {
  printLog("HL: teacher_helper : editTeacher")
  try {
    return new Promise((resolve, reject) => {
      teacher.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    });
  } catch (error) { }
}


export { getTeacherFromParse, deleteTeacherFromParse, getTeachersForSchoolId, editTeacher, requestForWebAccess, requestForWebAccessRemoval, getDeletedTeachersForSchoolId, getTeacherAttendanceForDay, getDaycareAttendanceForDay, getSchoolAttendance }