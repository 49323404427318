import React, { useState } from 'react';
import { Link } from "react-router-dom";

import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledTooltip
} from "reactstrap";

//i18n
import { withNamespaces } from 'react-i18next';

import alert from "../../assets/images/messageIcons/alert.png"
import text from "../../assets/images/messageIcons/text.png"
import assignment from "../../assets/images/messageIcons/assignment.png"
import notice from "../../assets/images/messageIcons/notice.png"
import { ALERT, ASSIGNMENT, MESSAGE, NOTICE } from '../../Constents';


const MessageTypeDropdown = (props) => {
  // Declare a new state variable, which we'll call "menu"
  const [menu, setMenu] = useState(props.isOpen ?? false);
  const [selectedMessageType, setSelectedMessageType] = useState(props.messageType ?? MESSAGE)

  function changeMessageType(type) {
    props.onChangeMessageType(type)
    setSelectedMessageType(type)
  }

  function getIconAndTextForSelectedType() {
    switch (selectedMessageType) {
      case ALERT:
        return { icon: alert, name: "Alert" }
      case NOTICE:
        return { icon: notice, name: "Notice" }
      case MESSAGE:
        return { icon: text, name: "Text Message" }
      case ASSIGNMENT:
        return { icon: assignment, name: "Assignment" }

      default:
        return { icon: text, name: "Message" }
        break;
    }
  }

  return (
    <React.Fragment>

      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="chat-left-links-shivam"
      >
        <DropdownToggle
          className="btn header-item waves-effect"
          tag="button"
        >
          <div className="">
            <ul className="list-inline contact-link mb-0">
              <li className="list-inline-item">
                <Link to="#">
                  <img id={"msgTypeSel"} src={getIconAndTextForSelectedType().icon} className="rounded-circle avatar-shivam" alt="" />
                 {props.schoolType!=10 && <UncontrolledTooltip placement="top" target="msgTypeSel">
                    Message Type
                  </UncontrolledTooltip >}
                </Link>
              </li>

            </ul>
          </div>
        </DropdownToggle>
        <DropdownMenu className="language-switch">
          <DropdownItem onClick={() => changeMessageType(MESSAGE)} className="notify-item">
            <img src={text} alt="Alert" className="mr-1" height="30" />
            <span className="align-middle font-size-16 ml-2">Text Message</span>
          </DropdownItem>
         { props.schoolType!=10 &&<DropdownItem onClick={() => changeMessageType(ALERT)} className="notify-item">
            <img src={alert} alt="Skote" className="mr-1" height="30" />
            <span className="align-middle font-size-16 ml-2">Alert</span>
          </DropdownItem>}
         {props.schoolType!=10 && <DropdownItem onClick={() => changeMessageType(NOTICE)} className="notify-item">
            <img src={notice} alt="Skote" className="mr-1" height="30" />
            <span className="align-middle font-size-16 ml-2">Notice</span>
          </DropdownItem>}
          {props.schoolType!=10 &&<DropdownItem onClick={() => changeMessageType(ASSIGNMENT)} className="notify-item">
            <img src={assignment} alt="Skote" className="mr-1" height="30" />
            <span className="align-middle font-size-16 ml-2">Assignment</span>
          </DropdownItem>}


        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
}

export default withNamespaces()(MessageTypeDropdown);