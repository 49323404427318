import * as actions from "./actionTypes";

export const updateAdmissionTitle = (admissionTitle) => {
  return {
    type: actions.UPDATE_ADMISSION_TITLE,
    payload: { admissionTitle },
  };
};

export const deleteAdmissionTitle = (admissionTitle) => {
  return {
    type: actions.DELETE_ADMISSION_TITLE,
    payload: {admissionTitle},
  };
};

export const updateAdmissiontitles = (admissionTitles) => {
  return {
    type: actions.UPDATE_ADMISSION_TITLES,
    payload: { admissionTitles },
  };
};

export const getAdmissiontitlesForSchoolId = (schoolId,setup) => {
  return {
    type: actions.GET_ADMISSION_TITLES,
    payload: { schoolId,setup },
  };
};

export const addAdmissionTitle = (admissionTitle) => {
  return {
    type: actions.ADD_ADMISSION_TITLE,
    payload: {admissionTitle},
  };
};

export const editAdmissionTitle = (admissionTitle) => {
  return {
    type: actions.EDIT_ADMISSION_TITLE,
    payload: {admissionTitle},
  };
};
export const reloadAdmissionTitles = (admissionTitles) => {
  return {
    type: actions.RELOAD_ADMISSION_TITLES,
    payload: { admissionTitles },
  };
};
export const updateTitlesSortOrder = (admissionTitles,schoolId) => {
  return {
    type: actions.UPDATE_ADMISSION_TITLE_SORT_ORDER,
    payload: { admissionTitles,schoolId },
  };
};

