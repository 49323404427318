export const DELETE_KIDS_IN_LIST = "DELETE_KIDS_IN_LIST";
export const GET_ALL_KIDS = "GET_ALL_KIDS";
export const DELETE_KID_LOADING = "DELETE_KID_LOADING";
export const CLEAR_ALL_KIDS = "CLEAR_ALL_KIDS";


export const GET_KID_OBJECT_FROM_LOCAL = "GET_KID_OBJECT_FROM_LOCAL";


export const GET_ALL_KIDS_FOR_GROUP = "GET_ALL_KIDS_FOR_GROUP";

export const UPDATE_ALL_KIDS_FOR_GROUPS = "UPDATE_ALL_KIDS_FOR_GROUPS";

export const GET_ADMISSION_KIDS = "GET_ADMISSION_KIDS";
export const UPDATE_ADMISSION_KIDS = "UPDATE_ADMISSION_KIDS";
export const REMOVE_KID_FROM_ADMISSIONS = "REMOVE_KID_FROM_ADMISSIONS";
export const UPDATE_REMOVED_KID = "UPDATE_REMOVED_KID";

export const KID_SUCCESS = "KID_SUCCESS";
export const KID_ERROR = "KID_ERROR";
export const UPDATE_ALL_KIDS = "UPDATE_ALL_KIDS";
export const A_KID_DETAILS = "A_KID_DETAILS";
export const SELECTED_A_KID_DETAILS = "SELECTED_A_KID_DETAILS";
export const GET_CAD_FOR_A_KID = "GET_CAD_FOR_A_KID";
export const SET_CAD_FOR_A_KID = "SET_CAD_FOR_A_KID";
export const GET_MEDICAL_DETAILS_FOR_KID = "GET_MEDICAL_DETAILS_FOR_KID";
export const SET_MEDICAL_DETAILS_FOR_KID = "SET_MEDICAL_DETAILS_FOR_KID";
export const SAVE_MEDICAL_DETAILS_FOR_KID = "SAVE_MEDICAL_DETAILS_FOR_KID";
export const GET_MEDICAL_DETAILS_FOR_KID_IDS = "GET_MEDICAL_DETAILS_FOR_KID_IDS";
export const SET_MEDICAL_DETAILS_FOR_KID_IDS = "SET_MEDICAL_DETAILS_FOR_KID_IDS";

export const GET_PICK_AND_DROP_DETAL_FOR_KID =
  "GET_PICK_AND_DROP_DETAL_FOR_KID";
export const SET_PICK_AND_DROP_DETAL_FOR_KID =
  "SET_PICK_AND_DROP_DETAL_FOR_KID";
export const GET_VACCINE_DETAL_FOR_KID = "GET_VACCINE_DETAL_FOR_KID";
export const SET_VACCINE_DETAL_FOR_KID = "SET_VACCINE_DETAL_FOR_KID";
export const GET_ATTENDANCE_FOR_KID = "GET_ATTENDANCE_FOR_KID";
export const SET_ATTENDANCE_FOR_KID = "SET_ATTENDANCE_FOR_KID";
export const GET_ATTENDANCE_FOR_KID_DAYCARE = "GET_ATTENDANCE_FOR_KID_DAYCARE";
export const SET_ATTENDANCE_FOR_KID_DAYCARE = "SET_ATTENDANCE_FOR_KID_DAYCARE";
export const UPDATE_A_KID = "UPDATE_A_KID";
export const UPDATE_ADDITIONAL_DETAILS_FOR_A_KID =
  "UPDATE_ADDITIONAL_DETAILS_FOR_A_KID";
export const ADD_A_KID_TO_DB = "ADD_A_KID_TO_DB";

export const GET_ALL_DELETED_KIDS_SCHOOL_ID = "GET_ALL_DELETED_KIDS_SCHOOL_ID";
export const UPDATE_ALL_DELETED_KIDS = "UPDATE_ALL_DELETED_KIDS";

export const SAVE_KIDS = "SAVE_KIDS";

export const SET_UPDATED_SUCCESSFULLY = "SET_UPDATED_SUCCESSFULLY";
export const KIDS_DELETED_SUCCESSFULLY = "KIDS_DELETED_SUCCESSFULLY";
export const GET_ALL_KIDS_FOR_SCHOOL_ID = "GET_ALL_KIDS_FOR_SCHOOL_ID";
export const SET_KIDS_FOR_SCHOOL = "SET_KIDS_FOR_SCHOOL";
export const GET_ALL_DELETED_KIDS_FOR_IDS = "GET_ALL_DELETED_KIDS_FOR_IDS";
export const CLEAR_DELETED_KIDS = "CLEAR_DELETED_KIDS";
export const UPDATE_ALL_DELETED_KIDS_INITIAL =
  "UPDATE_ALL_DELETED_KIDS_INITIAL";

export const SEND_INVITE = "SEND_INVITE";
export const UPDATE_KID_TOSTER_SUCCESS = "UPDATE_KID_TOSTER_SUCCESS";

export const SEND_ADMISSION_LINK_FOR_PARENTS =
  "SEND_ADMISSION_LINK_FOR_PARENTS";

export const GET_ALL_DELETED_KIDS_FOR_SCHOOL =
  "GET_ALL_DELETED_KIDS_FOR_SCHOOL";
export const SET_ALL_DELETED_KIDS_FOR_SCHOOL =
  "SET_ALL_DELETED_KIDS_FOR_SCHOOL";

export const CREATE_STUDENT_USER = "CREATE_STUDENT_USER";
export const UPDATE_STUDENT_USER = "UPDATE_STUDENT_USER";

export const GET_ESCORTS_FOR_KID_IDS = "GET_ESCORTS_FOR_KID_IDS";
export const UPDATE_ESCORTS = "UPDATE_ESCORTS";

export const DISABLED_KID = "DISABLED_KID";

