import React, { useEffect, useState } from "react";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import ColorPicker from "./colorPicker";
import { ReactComponent as VerifiedLogo } from "../../assets/images/certificate/verifiedLogo.svg";
import { ReactComponent as Divider } from "../../assets/images/certificate/separator.svg";
import {
  Container,
  Row,
  Col,
  Card,
  CardBody,
  Table,
  CardImg,
  Button,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
  Input
} from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import img1 from "../../assets/images/companies/img-1.png";
import {
  setSelecetdSchool,
  getPaymentOptionsForSchool,
  getRatingParams,
  getSchoolRatings,
  editSchool,
  editPaymentOptions,
  clearSchoolRatings,
} from "../../store/actions";

import Breadcrumbs from "../../components/Common/Breadcrumb";

import Parse from "parse";
import StarRatings from "react-star-ratings";
import toastr from "toastr";
import logo from "../../assets/images/logo-dark.png";
import SaveAsPdf from "../../components/Common/SaveAsPdf";

const SchoolRatingCertificate = (props) => {
  function printPoster() {
    var css = "@page { size: A4 landscape;margin:0; }",
      head = document.head || document.getElementsByTagName("head")[0],
      style = document.createElement("style");

    style.type = "text/css";
    style.media = "print";

    if (style.styleSheet) {
      style.styleSheet.cssText = css;
    } else {
      style.appendChild(document.createTextNode(css));
    }

    head.appendChild(style);
    setTimeout(() => {
      window.print();
    }, 300);
  }
   
  useEffect(() => {
    const savedValue = localStorage.getItem('dontShowAgain');
    if (savedValue === 'true') {
        setDontShowAgain(true);
    }

    const userAgent = navigator.userAgent.toLowerCase();
    
    if (userAgent.includes('mac')) {
        setMachineType('Mac');
    } else if (userAgent.includes('windows')) {
        setMachineType('Windows');
    } else {
        setMachineType('Unknown');
    }
  }, []);

const handleNextClick = () => {
    setShowSaveAsPdfModal(false);
    setTimeout(() => {
        printPoster(); 
    }, 300); 
};


  const match = matchPath(props.history.location.pathname, {
    path: "/schoolratingcertificate/:id",
    exact: true,
    strict: false,
  });
  const parameter = match.params.id;

  const [loading, setLoading] = useState(true);

  const [school, setSchool] = useState(undefined);

  const [schoolName, setSchoolName] = useState(undefined);
  const [schoolCity, setSchoolCity] = useState(undefined);
  const [schoolLogo, setSchoolLogo] = useState(undefined);

  const [selectedRatings, setSelectedRatings] = useState([]);
  const [isModelOpen, setIsModelOpen] = useState(undefined);
  const [ratingCardList, setRatingCardList] = useState([]);
  const [selectedRatingsTemp, setSelectedRatingsTemp] = useState([]);
  const [overAllRating, setOverAllRating] = useState(0);
  const [certificateBorderColor, setCertificateBorderColor] = useState(
    "#f5008b"
  );
  const [starEmptyColor, setStarEmptyColor] = useState("#cbd3e3");
  const [certificateBackgroundColor, setCertificateBackgroundColor] = useState(
    "#F8EDEB"
  );
  const [ratingType, setRatingType] = useState(2);
  const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
  const [dontShowAgain, setDontShowAgain] = useState(false);
  const [machineType, setMachineType] = useState('');


  useEffect(() => {
    getSchool();
  }, [parameter]);

  function getSchool() {
    var School = Parse.Object.extend("School");
    var query = new Parse.Query(School);

    query.get(parameter).then(
      (result) => {
        setLoading(false);
        setSchoolName(result.attributes.Name);
        setSchoolCity(result.attributes.city);
        if (result.attributes.logo) {
          setSchoolLogo(result.attributes.logo._url);
        }
        setSchool(result);
      },
      (error) => {
        console.log(error);
      }
    );
  }

  useEffect(() => {
    if (school != undefined && school.attributes != undefined) {
      props.getSchoolRatings(school.id, ratingType);
      props.getRatingParams(school);
    }
  }, [school]);

  const initialSetup = () => {
    if (
      props.ratingParams != null &&
      props.ratingParams.length > 0 &&
      props.schoolRatings && props.schoolRatings.length > 0
    ) {
      console.log("1")
      var i = 0;
      var ratingCardTemp = [];
      var tempOverAll = 0;
      for (i = 0; i < props.ratingParams.length; i++) {
        var j = 0;
        var ratingObjects = [];
        var isScaleTypeStars = false;
        var isScaleThumb = false;
        if (props.ratingParams[i].attributes.scaleType === 1) {
          isScaleTypeStars = true;
        } else if (props.ratingParams[i].attributes.scaleType === 3) {
          // isScaleThumb = true;
          continue;
        } else if (props.ratingParams[i].attributes.scaleType === 2) {
          continue;
        }
        var avgRatingValue = 0.0;
        var totalRatingValues = 0.0;
        var upCount = 0;
        var downCount = 0;

        for (j = 0; j < props.schoolRatings.length; j++) {
          if (
            props.schoolRatings[j].attributes.paramId ===
            props.ratingParams[i].id
          ) {
            ratingObjects.push(props.schoolRatings[j]);
            if (isScaleTypeStars) {
              totalRatingValues =
                totalRatingValues +
                parseFloat(props.schoolRatings[j].attributes.value);
            } else if (isScaleThumb) {
              if (
                props.schoolRatings[j].attributes.value.toLowerCase() === "up"
              ) {
                upCount += 1;
              } else if (
                props.schoolRatings[j].attributes.value.toLowerCase() === "down"
              ) {
                downCount += 1;
              }
            }
          }
        }

        if (isScaleTypeStars) {
          avgRatingValue = totalRatingValues / ratingObjects.length;
          tempOverAll += avgRatingValue;
        }

        var cardObject = {
          id: i + 1,
          img: img1,
          name: "",
          description: props.ratingParams[i].attributes.parameter,
          paramDisplayName: props.ratingParams[i].attributes.paramDisplayName,
          ratingValue: avgRatingValue,
          upCount: upCount,
          downCount: downCount,
          scaleType: props.ratingParams[i].attributes.scaleType,
          ratings: ratingObjects,
        };
        ratingCardTemp.push(cardObject);
      }
      let tempAverageOverAll = tempOverAll / ratingCardTemp.length;
      setOverAllRating(tempAverageOverAll);
      let tempSelectedList = [];

      for (var i = 0; i < 6; i++) {
        tempSelectedList.push(ratingCardTemp[i]);
      }
      setSelectedRatings(tempSelectedList);
      setRatingCardList(ratingCardTemp);
      if (isModelOpen) {
        setSelectedRatingsTemp(ratingCardTemp);
      }
    }
  };

  useEffect(() => {
    console.log("exicute", props.schoolRatings && props.schoolRatings.length)
    initialSetup();
  }, [props.ratingParams, props.schoolRatings]);

  const togglePopup = () => {
    if (!isModelOpen) {
      setSelectedRatingsTemp(selectedRatings);
    }
    setIsModelOpen(!isModelOpen);
  };

  const handleDoneButton = () => {
    if (selectedRatingsTemp.length === 6) {
      setSelectedRatings(selectedRatingsTemp);
      props.getSchoolRatings(school.id, ratingType)
      togglePopup();
    } else {
      toastr.warning(props.t("Please select any six ratings from the list"), {
        timeOut: 1000,
      });
    }
  };

  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }

  const getFirstThree = () => {
    if (selectedRatings.length > 0) {
      return [selectedRatings[0], selectedRatings[1], selectedRatings[2]];
    }
    return [];
  };
  const getLastThree = () => {
    if (selectedRatings.length > 0) {
      return [selectedRatings[3], selectedRatings[4], selectedRatings[5]];
    }
    return [];
  };

  const getStarUI = (ratingObj, key) => {
    return (
      <Row key={key}>
        <Col>
          <Row>
            <Col>
              <h6
                className="text-center mr-5"
                style={{
                  color: "rgb(48,78,115)",
                  height: "25px",
                }}
              >
                {ratingObj.paramDisplayName ?? ratingObj.description}
              </h6>
            </Col>
          </Row>
          {/*starEmptyColor="#2D363F" */}
          {
            <h5 className="text-center mr-5">
              <StarRatings
                rating={parseFloat(ratingObj.ratingValue.toFixed(1))}
                starRatedColor="#F1B44C"
                starEmptyColor={starEmptyColor}
                numberOfStars={5}
                name="rating"
                starDimension="40px"
                starSpacing="3px"
              />{" "}
              {""}
            </h5>
          }
          {
            <div className="text-center mr-5 mb-2">
              <Divider
                fill="black"
                stroke={certificateBorderColor}
                style={{ width: "150px", height: "15px" }}
              ></Divider>
            </div>
          }
        </Col>
      </Row>
    );
  };

  const handleBorderColorChange = (color) => {
    setCertificateBorderColor(color);
  };

  const handleStarEmptyColorChange = (color) => {
    setStarEmptyColor(color);
  };
  const handleBackgroundColorChange = (color) => {
    setCertificateBackgroundColor(color);
  };
  const handleResetColors = () => {
    setCertificateBackgroundColor("#F8EDEB");
    setStarEmptyColor("#cbd3e3");
    setCertificateBorderColor("#f5008b");
  };

  const topRightCorner = {
    top: 60,
    right: 60,
    position: "absolute",
    webkitTransform: "rotate(25deg)",
    mozTransform: "rotate(25deg)",
    msTransform: "rotate(25deg)",
    oTransform: "rotate(25deg)",
    transform: "rotate(25deg)",
  };

  return (
    <React.Fragment>
      <div className="">
        <Container fluid>
          <Modal
            isOpen={isModelOpen}
            fade={false}
            className="modal-dialog modal-xl"
          >
            <ModalHeader toggle={togglePopup}>Settings</ModalHeader>
            <ModalBody
              style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto" }}
            >
              {/* <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              ></div> */}

              <div>
                <Row>
                  <Col lg={3} md={5} sm={5} xs={5}>
                    <h5>Background Color</h5>
                    <h5>Border Color</h5>
                    <h5>Star Background Color</h5>
                  </Col>
                  <Col lg={1} md={3} sm={3} xs={3}>
                    <ColorPicker
                      color={certificateBackgroundColor}
                      onChangeComplete={(color) => {
                        handleBackgroundColorChange(color);
                      }}
                    />
                    <ColorPicker
                      color={certificateBorderColor}
                      onChangeComplete={(color) => {
                        handleBorderColorChange(color);
                      }}
                    />
                    <ColorPicker
                      color={starEmptyColor}
                      onChangeComplete={(color) => {
                        handleStarEmptyColorChange(color);
                      }}
                    />
                  </Col>
                  <Col lg={8} md={4} sm={3} xs={3}>
                    <Row className="justify-content-start">
                      <Button
                        style={{
                          textAlign: "left",
                        }}
                        color="primary"
                        onClick={handleResetColors}
                      >
                        Reset Colors
                      </Button>
                    </Row>
                  </Col>
                </Row>
                <Row>
                  <Col>
                    <div className="form-group">
                      <div className="custom-control custom-radio ">
                        <Input
                          type="radio"
                          id="customRadioInline1"
                          name="customRadioInline1"
                          checked={ratingType == 1 ?? false}
                          className="custom-control-input"
                          onChange={(e) => {
                            //   if(ratingType){
                            //     props.clearSchoolRatings()
                            // }
                            setRatingType(1)
                            // props.getSchoolRatings(school.id, 1);
                          }}
                        />
                        <Label className="custom-control-label" htmlFor="customRadioInline1">
                          <h5>All times Ratings</h5>
                        </Label>
                      </div>
                      <div className="custom-control custom-radio py-2">
                        <Input
                          type="radio"
                          id="customRadioInline2"
                          name="customRadioInline1"
                          checked={ratingType == 2 ?? false}
                          className="custom-control-input"
                          onChange={(e) => {
                            setRatingType(2)
                            // props.getSchoolRatings(school.id, 2);
                          }}
                        />
                        <Label className="custom-control-label" htmlFor="customRadioInline2">
                          <h5>Recent Ratings</h5>
                        </Label>
                      </div>
                    </div>
                  </Col>
                </Row>

              </div>
              <hr />
              <h5>Select   to display</h5>
              <hr />
              <div className="table-responsive mt-2">
                <Table className="table-centered table-nowrap table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th style={{ width: "20px" }}>
                        <div></div>
                      </th>

                      <th scope="col">Description</th>

                      <th scope="col" style={{ textAlign: "center" }}>
                        value
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {ratingCardList.map((ratingObj, i) => (
                      <tr key={"rating_" + i}>
                        <td>
                          <div className="custom-control custom-checkbox mb-2">
                            <input
                              type="checkbox"
                              className="custom-control-input input-mini"
                              id={`checkbox${ratingObj.id}`}
                              checked={selectedRatingsTemp.includes(ratingObj)}
                              // checked={(selectedRatingsTemp.filter((rt)=>rt.id==ratingObj.id)).length>0?true:false}
                              onClick={(e) => {
                                let tempSelectedList = [...selectedRatingsTemp];
                                if (!tempSelectedList.includes(ratingObj)) {
                                  tempSelectedList.push(ratingObj);
                                } else {
                                  let index = tempSelectedList.indexOf(
                                    ratingObj
                                  );
                                  removeElement(
                                    tempSelectedList,
                                    tempSelectedList[index]
                                  );
                                }
                                setSelectedRatingsTemp(tempSelectedList);
                              }}
                            />
                            <Label
                              className="custom-control-label"
                              for={`checkbox${ratingObj.id}`}
                            ></Label>
                          </div>
                        </td>

                        <td>
                          <h5 className="font-size-14  mt-2">
                            {ratingObj.paramDisplayName ?? ratingObj.description}
                          </h5>
                        </td>
                        <td style={{ width: "20px", textAlign: "center" }}>
                          <h5 className="font-size-14  mt-2">
                            {parseFloat(ratingObj.ratingValue.toFixed(1))}
                          </h5>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </ModalBody>
            <ModalFooter>
              {
                <Button color="primary" onClick={() => {
                  if (ratingType) {
                    props.clearSchoolRatings()
                  }
                  handleDoneButton()
                }}>
                  Done
                </Button>
              }{" "}
              <Button color="secondary" onClick={togglePopup}>
                Cancel
              </Button>
            </ModalFooter>
          </Modal>
          {loading ? (
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  {props.t("Loading")}{" "}
                </Link>
              </div>
            </Col>
          ) : (
            <Row className="text-center">
              <Col xs="12" className="d-print-none mt-3 mb-3">
                <div className="text-center">
                  <Button
                    onClick={() => {
                      if (['Windows', 'Mac'].includes(machineType)) {
                        if (!dontShowAgain) {
                            setShowSaveAsPdfModal(true);
                        } else {
                            printPoster();
                        }
                      }else{
                        printPoster();
                      }
                  }}className="btn btn-success waves-effect waves-light m-1">
                    <i className="fa fa-print m-1"></i>Print Certificate
                  </Button>
                  <SaveAsPdf
                      showModal={showSaveAsPdfModal}
                      toggleModal={() => setShowSaveAsPdfModal(false)}
                      dontShowAgain={dontShowAgain}
                      setDontShowAgain={setDontShowAgain}
                      onNextClick={handleNextClick}
                      machineType={machineType}
                  /> 
                  <Button
                    onClick={() => {
                      togglePopup();
                    }}
                    color="primary"
                    className="btn waves-effect waves-light ml-2"
                  >
                    <i className="bx bx-cog mr-2"></i>
                    Settings
                  </Button>
                </div>
              </Col>

              <Col lg={12} md={12} sm={12} className="pl-0 pb-0">
                <center>
                  <div
                    style={{
                      height: "793px",
                      width: "1123px",
                      backgroundColor: certificateBackgroundColor,
                      border: `50px solid ${certificateBorderColor}`,
                    }}
                    className="text-center"
                  >
                    <div
                      style={topRightCorner}
                      className="d-none d-print-block"
                    >
                      <VerifiedLogo
                        fill={certificateBorderColor}
                        stroke={certificateBorderColor}
                        width="160px"
                        height="160px"
                      />
                    </div>
                    <div>
                      <Row className="mt-4">
                        <div
                          className="mt-0 mx-auto pb-4 align-items-center justify-content-center flex-wrap"
                          style={{
                            maxWidth: "90%",
                            borderBottom: `2px solid ${starEmptyColor}`,
                          }}
                        >
                          <div
                            className="d-flex align-items-center justify-content-center mr-3"
                            style={{
                              flexBasis: "50%",
                              textAlign: "center",
                            }}
                          >
                            <h1
                              className="my-auto"
                              style={{
                                color: certificateBorderColor,
                                fontSize: "25px",
                                margin: 0,
                              }}
                            >
                              {schoolName}
                            </h1>
                          </div>

                          <div
                            className="d-flex flex-column align-items-center justify-content-center mt-0"
                            style={{
                              flexBasis: "50%",
                              textAlign: "center",
                            }}
                          >
                            <img
                              src={schoolLogo}
                              width="160px"
                              height="160px"
                              alt=""
                              style={{ objectFit: "contain" }}
                            />
                          </div>
                        </div>
                      </Row>
                      <Row className="mt-0">
                        <div className="mt-0 mx-auto">
                          {/* <h5 className="text-right mr-5"></h5> */}
                          {/* <div className="mt-0">
                            <img
                              src={schoolLogo}
                              width="250px"
                              height="250px"
                              alt=""
                              style={{objectFit: "contain"}}
                            />
                          </div> */}

                          <h4 className="text-center mt-2 mb-2">

                            <h5 className="text-center ">
                              Rated by Parents</h5>
                            <StarRatings
                              rating={parseFloat(overAllRating.toFixed(1))}
                              starRatedColor="#F1B44C"
                              starEmptyColor={starEmptyColor}
                              numberOfStars={5}
                              name="rating"
                              starDimension="60px"
                              starSpacing="3px"
                            />

                          </h4>
                        </div>
                      </Row>

                      <Row md={12} lg={12} sm={12}>
                        {/* <Col md={2} lg={2}>
                          <div
                            className="text-left"
                            style={{
                              marginLeft: "30%",
                              marginTop: "150%",
                            }}
                          >
                            <p>{props.t("Powered By")}</p>
                            <img
                              className="img-responsive pr-3"
                              src={logo}
                              style={{ width: "160px" }}
                            />
                          </div>
                        </Col> */}
                        <Col lg={6} style={{ marginTop: "1%" }} className="ml-5">
                          {getFirstThree().map((ratingObj, key) =>
                            getStarUI(ratingObj, key)
                          )}
                        </Col>
                        <Col lg={6} style={{ marginTop: "1%" }}>
                          {getLastThree().map((ratingObj, key) =>
                            getStarUI(ratingObj, key)
                          )}
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <div
                            className="text-center"
                            style={{ marginBlock: "-3%" }}
                          >
                            <p>{props.t("Powered By")}</p>
                            <img
                              className="img-responsive pr-3"
                              src={logo}
                              style={{ width: "130px" }}
                            />
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </center>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const {
    selectedSchool,
    paymentOptionsForSchool,
    ratingParams,
    schoolRatings,
  } = state.School;
  return {
    selectedSchool,
    paymentOptionsForSchool,
    ratingParams,
    schoolRatings,
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      editSchool,
      getPaymentOptionsForSchool,
      editPaymentOptions,
      getRatingParams,
      getSchoolRatings,
      clearSchoolRatings
    })(SchoolRatingCertificate)
  )
);
