import * as actions from "./actionTypes";

const initialState = {
  reminders: undefined,
  selectedReminder: undefined,
};

const Reminder = (state = initialState, action) => {
  
  switch (action.type) {
    case actions.UPDATE_REMINDER:

      var reminders = [...state.reminders]
      var temp = state.reminders.filter(reminder => reminder.id === action.payload.reminder.id)
      if (temp.length > 0) {
        if (action.payload.reminder.attributes.isDeleted) {
          var others = state.reminders.filter(
            (reminder) =>
              reminder.id !== action.payload.reminder.id
          );
          state = { ...state, reminders: others };
        } else {
          var index = reminders.indexOf(temp[0])
          reminders[index] = action.payload.reminder
          state = { ...state, reminders: reminders };
        }
      } else {
        if (!action.payload.reminder.attributes.isDeleted) {
          reminders.push(action.payload.reminder)
          state = { ...state, reminders: reminders };
        }
      }
      break;

    case actions.UPDATE_REMINDERS:

      if (state.reminders != undefined) {
        var reminders = [...state.reminders];
        if (reminders.length > 0) {
          for (const latest in action.payload.reminders) {
            var temp = reminders.filter(
              (reminder) =>
                reminder.id === action.payload.reminders[latest].id
            );
            if (temp.length > 0) {
              if (action.payload.reminders[latest].attributes.isDeleted) {

                var others = reminders.filter(
                  (reminder) =>
                    reminder.id !== action.payload.reminders[latest].id
                );

                reminders = others;
              } else {
                var index = reminders.lastIndexOf(temp[0]);
                reminders[index] = action.payload.reminders[latest];
              }

            } else {
              if (action.payload.reminders[latest].attributes.isDeleted !== true) {
                reminders.push(action.payload.reminders[latest]);
              }

            }
          }
          state = { ...state, reminders: reminders };
        } else {

          var i = 0
          var remindersToSave = []
          for (i = 0; i < action.payload.reminders.length; i++) {
            if (action.payload.reminders[i].attributes.isDeleted !== true) {
              remindersToSave.push(action.payload.reminders[i])
            }
          }


          state = { ...state, reminders: remindersToSave };
        }
      } else {
        var i = 0
        var remindersToSave = []
        for (i = 0; i < action.payload.reminders.length; i++) {
          if (action.payload.reminders[i].attributes.isDeleted !== true) {
            remindersToSave.push(action.payload.reminders[i])
          }
        }
        state = { ...state, reminders: remindersToSave };
      }

      break;
    
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Reminder;
