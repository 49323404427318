import ReactApexChart from 'react-apexcharts';
const Spinearea = (props) => {

    const getEnquiries = () => {
        let list = [];
        if (props.selectedMonths) {
            let currentVal =0
            for (const mon of props.selectedMonths) {
                let newVal = mon.enquiryCount ? mon.enquiryCount : 0;
                currentVal = currentVal + newVal;
                list.push(currentVal);
            }
        }
        return list;
    }
    const getAdmissions = () => {
        let list = [];
        let currentVal =0
        if (props.selectedMonths) {
            for (const mon of props.selectedMonths) {
                let newVal = mon.admissionCount ? mon.admissionCount : 0;
                currentVal = currentVal + newVal;
                list.push(currentVal);
            }
        }
        return list;
    }

    const getInvoiceAmount = () => {
        let list = [];
        if (props.selectedMonths) {
            let currentVal =0
            for (const mon of props.selectedMonths) {
                let newVal = mon.invoiceAmount ? mon.invoiceAmount : 0;
                currentVal = currentVal + newVal;
                list.push(currentVal);
            }
        }
        return list;
    }
    const getTransactionAmount = () => {
        let list = [];
        let currentVal =0
        if (props.selectedMonths) {
            for (const mon of props.selectedMonths) {
                let newVal = mon.transactionAmount ? mon.transactionAmount : 0;
                currentVal = currentVal + newVal;
                list.push(currentVal);
            }
        }
        return list;
    }
    

    const getMonths = () => {
        let list = [];
        if (props.selectedMonths) {
            for (const mon of props.selectedMonths) {
                list.push(mon.date+"")
            }
        }
        // console.log(list)
        return list;
    }

    const series = [{
        name: 'Enquiries',
        data: getEnquiries()
    }, {
        name: 'Admissions',
        data: getAdmissions()    
    }];
    
    const series2 = [{
        name: 'Invoice Amount',
        data: getInvoiceAmount()
    }, {
        name: 'Collected Amount',
        data: getTransactionAmount()
    }];

    const options = {
        dataLabels: {
            enabled: false
        },
        stroke: {
            curve: 'smooth',
            width: 3,
        },
                
        colors: ['#556ee6', '#34c38f'],
        xaxis: {
            type: 'datetime',
            categories: getMonths(),
        },
        grid: {
            borderColor: '#f1f1f1',
        },
        tooltip: {
            x: {
                format: 'MMM yyyy'
            },
        },
        chart:{
          id:"cumulativeChart"
        }
    };

    return (
        <ReactApexChart options={options} series={props.type==="fee"?series2:series} type="area" height="300" />
    );
}

export default Spinearea;