import * as actions from "./actionTypes";

export const updateCameras = (cameras) => {
  return {
    type: actions.UPDATE_CAMERAS,
    payload: { cameras },
  };
};
export const updateCamera = (camera) => {
  return {
    type: actions.UPDATE_CAMERA,
    payload: { camera },
  };
};

export const getCamerasForSchoolId = (schoolId) => {
  return {
    type: actions.GET_CAMERAS_FOR_SCHOOL_ID,
    payload: { schoolId },
  };
};

export const saveCamera = (cameraObject) => {
  return {
    type: actions.SAVE_CAMERA,
    payload: { cameraObject },
  };
};

export const deleteCamera = (cameraObject,successMessage,moveBack) => {
  return {
    type: actions.DELETE_CAMERA,
    payload: { cameraObject,successMessage,moveBack },
  };
};

export const getCameraForId = (cameraId) => {
  return {
    type: actions.GET_CAMERA_FOR_ID,
    payload: { cameraId },
  };
};
export const updateSingleCamera = (camera) => {
  return {
    type: actions.SINGLE_CAMERA_UPDATE,
    payload: { camera },
  };
};
export const resultCameraAfterSave = (camera) => {
  return {
    type: actions.RESULT_CAMERA_AFTER_SAVE,
    payload: { camera },
  };
};

export const resetResultCameraAfterSave = () => {
  return {
    type: actions.RESULT_CAMERA_RESET,
    payload: {  },
  };
};

export const saveCameraAccessObjects = (cameraAccessObjects) => {
  return {
    type: actions.SAVE_CAMERA_ACCESS_OBJECTS,
    payload: { cameraAccessObjects },
  };
};
export const resultCameraAccessAfterSave = (response) => {
  return {
    type: actions.RESULT_CAMERA_ACCESS_AFTER_SAVE,
    payload: { response },
  };
};
export const getCameraAccessObjectsForCameraId = (cameraId) => {
  return {
    type: actions.GET_CAMERA_ACCESS_OBJECTS_FOR_CAMERA_ID,
    payload: { cameraId },
  };
};
export const updateCameraAccessObjects = (cameraAccessObjects) => {
  return {
    type: actions.UPDATE_CAMERA_ACCESS_OBJECTS,
    payload: { cameraAccessObjects },
  };
};
export const clearPropsCameraAndAccess = () => {
  return {
    type: actions.CLEAR_PROPS_CAMERA_AND_ACCESS,
    payload: {  },
  };
};
export const getCameraLiveStreamsForSchoolId = (schoolId) => {
  return {
    type: actions.GET_CAMERA_LIVESTREAMS_FOR_SCHOOL_ID,
    payload: { schoolId },
  };
};

export const updateCameraLiveStreamsForSchoolId = (cameraLiveStreams) => {
  return {
    type: actions.UPDATE_CAMERA_LIVESTREAMS_FOR_SCHOOL_ID,
    payload: { cameraLiveStreams },
  };
};
