import Parse from "parse";
import { printLog } from "./loghelper";
export const getInvoiceFromParse = (invoiceId) => {
  printLog("HL: invoice_helper : getInvoiceFromParse")

  try {
    return new Promise((resolve, reject) => {
      var Invoice = Parse.Object.extend("Invoice");
      var query = new Parse.Query(Invoice);
      query.get(invoiceId).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const deleteInvoiceFromParse = (invoiceId) => {
  printLog("HL: invoice_helper : deleteInvoiceFromParse")
  try {
    return new Promise((resolve, reject) => {
      const Invoice = Parse.Object.extend("Invoice");
      var invoice = new Invoice();
      invoice.id = invoiceId;
      invoice.set("deleted", true);
      if (Parse.User.current().attributes.teacherId) {
        invoice.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        invoice.set("deletedBy", Parse.User.current().id);
      }

      invoice.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getInvoiceCountForFilters = (filters) => {
  printLog("HL: invoice_helper : getInvoiceCountForFilters")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Invoice");
      if (filters.containedIn) {
        //attributeName and value for multiple
        query.containedIn(filters.attributes, filters.value);
      } else {
        //attributename and value for single
        query.equalTo(filters.attributes, filters.value);
      }
      if (filters.forRecipient) {
        query.equalTo("recipientId", filters.recipientId);
      }
      if (filters.fromDate && filters.toDate) {
        //fromdate to date
        let fromDate = new Date(
          filters.fromDate.getFullYear(),
          filters.fromDate.getMonth(),
          filters.fromDate.getDate(),
          0,
          0,
          0
        );
        let toDate = new Date(
          filters.toDate.getFullYear(),
          filters.toDate.getMonth(),
          filters.toDate.getDate(),
          24,
          59,
          59
        );
        query.greaterThan("createdAt", fromDate);
        query.lessThan("createdAt", toDate);
      }
      if (filters.tags) {
        if (filters.tags !== "All" && filters.tags.length > 0) {
          query.containedIn("tags", filters.tags);
        }
      }
      if (filters.corporateIds && filters.corporateIds.length>0 ) {
        query.containedIn('corporateId',filters.corporateIds)
      } else if(filters.corporateId ){
        query.equalTo('corporateId', filters.corporateId)
      }
      query.descending("createdAt");
      query.notEqualTo("isDeleted", true);
      query.count().then((result) => {
        resolve(result);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const getInvoicesForFilter = (filters, updatedDate, isLoacal,locakSkip) => {
  printLog("HL: invoice_helper : getInvoicesForFilter")
  try {
    return new Promise((resolve, reject) => {
      // let query = new Parse.Query('Invoice')
      // query.equalTo('schoolId', 'AJr6W6LvLz')
      // query.find().then((results)=>{
      //   resolve(results)
      // })
      let query = new Parse.Query("Invoice");
      if (filters.containedIn) {
        //attributeName and value for multiple
        query.containedIn(filters.attributes, filters.value);
      } else {
        //attributename and value for single
        query.equalTo(filters.attributes, filters.value);
      }
      if (filters.schoolId) {
        query.equalTo("schoolId", filters.schoolId);
      }
      if (filters.forRecipient) {
        query.equalTo("recipientId", filters.recipientId);
      }
      if (filters.fromDate && filters.toDate) {
        //fromdate to date
        let fromDate = new Date(
          filters.fromDate.getFullYear(),
          filters.fromDate.getMonth(),
          filters.fromDate.getDate(),
          0,
          0,
          0
        );
        let toDate = new Date(
          filters.toDate.getFullYear(),
          filters.toDate.getMonth(),
          filters.toDate.getDate(),
          23,
          59,
          59
        );
        query.greaterThan("createdAt", fromDate);
        query.lessThan("createdAt", toDate);

      }else if ( filters.toDate) {
        //fromdate to date
        
        let toDate = new Date(
          filters.toDate.getFullYear(),
          filters.toDate.getMonth(),
          filters.toDate.getDate(),
          23,
          59,
          59
        );
        
        query.lessThan("createdAt", toDate);
      }
      if (filters.tags) {
        if (filters.tags !== "All" && filters.tags.length > 0) {
          query.containedIn("tags", filters.tags);
        }
      }
      if(filters.paymentType){
        if(filters.paymentType === "paid"){
          query.equalTo('paidStatus', 1)
        }else if(filters.paymentType === "partially"){
          query.equalTo('paidStatus', 3)
        }else if(filters.paymentType === "pending"){
          query.notContainedIn('paidStatus', [1,3])
        }else if(filters.paymentType === "paid-and-partially"){
          query.containedIn('paidStatus', [1,3])
        }else if(filters.paymentType === "pending-and-partially"){
          query.notEqualTo('paidStatus', 1)
        }
      }
      if(filters.corporateId){
        query.equalTo('corporateId', filters.corporateId)
      }
      query.descending("createdAt");
      query.limit(filters.limit);
      if (isLoacal) {
        query.fromLocalDatastore();
      }
      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      }
      query.notEqualTo("isDeleted", true);
      if(locakSkip && locakSkip>=0){
        query.skip(locakSkip)
      }else{
        query.skip((+filters.skipValue - 1) * filters.limit);
      }
      if (filters.corporateIds && filters.corporateIds.length>0 ) {
        query.containedIn('corporateId',filters.corporateIds)
      } else if(filters.corporateId ){
        query.equalTo('corporateId', filters.corporateId)
      }
      
      query.find().then(
        (results) => {
          resolve(results);
        },
        (error) => {
          console.log(error);
        }
      );
    });
  } catch (error) { }
};

export const getInvoicesForSchoolId = (id, updatedDate, isLoacal) => {
  printLog("HL: invoice_helper : getInvoicesForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Invoice = Parse.Object.extend("Invoice");
      var query = new Parse.Query(Invoice);
      query.equalTo("schoolId", id);

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.ascending("Name");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getInvoiceforKidId = (id) => {
  printLog("HL: invoice_helper : getInvoiceforKidId")
  try {
    return new Promise((resolve, reject) => {
      var Invoice = Parse.Object.extend("Invoice");
      var query = new Parse.Query(Invoice);
      query.equalTo("recipientId", id);
      query.notEqualTo("isDeleted", true);
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const createInvoiceForKids = (invoiceObject) => {
  printLog("HL: invoice_helper : createInvoiceForKids")

  try {
    return new Promise((resolve, reject) => {
      Parse.Cloud.run("function_createPaymentRequest", invoiceObject).then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(null);
        }
      );
    });
  } catch (error) { }
};

export const createInvoiceWithFunction = (filters) => {
  printLog("HL: invoice_helper : createInvoiceWithFunction")
  return new Promise((resolve, reject) => {
    try {
      let tempFilter = filters;
      tempFilter.createdBy = Parse.User.current().attributes.teacherId
        ? Parse.User.current().attributes.teacherId
        : Parse.User.current().id;
      Parse.Cloud.run("function_createPaymentRequest", tempFilter).then(
        (results) => {
          resolve(results);
        }
      );
    } catch (error) {
      console.log(error);
    }
  });
};

export const gerInvoiceForIds = (ids, isLocal) => {
  printLog("HL: invoice_helper : gerInvoiceForIds")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Invoice");
      query.containedIn("objectId", ids);
      query.limit(ids.length);
      if (isLocal) {
        query.fromLocalDatastore();
      }
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};

export const updateInvoiceAndItem = (invoiceNItems) => {
  printLog("HL: invoice_helper : updateInvoiceAndItem")
  return new Promise((resolve, reject) => {
    try {
      let Invoice = Parse.Object.extend("Invoice");
      let invoice = new Invoice();
      invoice.id = invoiceNItems.id;
      invoice.set("amount", invoiceNItems.invoiceTotalAmount + "");
      invoice.set("tags", [invoiceNItems.activeYear]);
      invoice.set("title", invoiceNItems.title);
      if (invoiceNItems.isDaycareInvoiceEdit) {
        invoice.set("isDaycareInvoice", invoiceNItems.isDaycareInvoice);
      }
      
      if (invoiceNItems.paymentOption) {
        invoice.set("paymentOptionId", invoiceNItems.paymentOption);
      } else {
        let temp;
        invoice.set("paymentOptionId", temp);
      }
      if (invoiceNItems.receiptAddressId) {
        invoice.set("receiptAddressId", invoiceNItems.receiptAddressId);
      } else {
        invoice.unset("receiptAddressId");
      }

      invoice.set("royaltyAmount", invoiceNItems.royaltyAmount);
      invoice.set("isRoyaltyInvoice", invoiceNItems.isRoyaltyInvoice);

      invoice.save().then((result) => {
        let invoiceObj = result;
        let invoiceItems = [];
        for (const val of invoiceNItems.items) {
          let temp;
          if (val.Obj) {
            temp = val.Obj;
          } else {
            let FeeInvoiceItem = Parse.Object.extend("InvoiceItems");
            temp = new FeeInvoiceItem();
          }
          temp.set("itemDescription", val.itemDescription);
          temp.set("amount", +val.amount);
          temp.set("discountType", +val.discountType);
          temp.set("totalAmount", val.totalAmount);
          temp.set("discount", +val.discount);
          temp.set("dueDate", val.duedate);
          temp.set("discountRemark", val.discountRemark);
          temp.set("tax", val.tax);
          if (val.discount > 0) {
            temp.set(
              "discountGivenBy",
              Parse.User.current().attributes.teacherId
            );
          }
          if (val.isDeleted) {
            temp.set("deleted", true);
          }
          temp.set("invoiceId", invoiceObj.id);

          temp.set("royalty", val.royalty);
          temp.set("royaltyAmount", val.royaltyAmount);

          invoiceItems.push(temp);
        }
        Parse.Object.saveAll(invoiceItems).then((results) => {
          resolve({ invoice: invoiceObj, invoiceItems: results });
        });
      });
    } catch (err) {
      console.log(err);
    }
  });
};

export const getinvoiceByFunction = (id) => {
  printLog("HL: invoice_helper : getinvoiceByFunction")
  return new Promise((resolve, reject) => {
    try {
      Parse.Cloud.run("function_getAllDetailsForReceipt", {
        invoiceId: id,
      }).then((result) => {
        resolve(result);
      });
    } catch (err) {
      console.log(err);
    }
  });
};

export const getInvoicesForProgramId = (programId) => {
  printLog("HL: invoice_helper : getInvoicesForProgramId")
  try {
    return new Promise((resolve, reject) => {
      let query = new Parse.Query("Invoice");
      query.equalTo("programId", programId);
      query.limit(5000)
      query.notEqualTo("isDeleted", true);
      query.find().then((results) => {
        resolve(results);
      });
    });
  } catch (err) {
    console.log(err);
  }
};


export const createInvoiceForProgramKid = (kidId, templateId, school, programObject) => {
  printLog("HL: invoice_helper : createInvoiceForProgramKid")
  return new Promise((resolve, reject) => {
    try {

      var query = new Parse.Query('FeeTemplate');
      query.equalTo('objectId', templateId);
      query.limit(1000);
      query.find().then((feeTemplateResults) => {

        if (feeTemplateResults && feeTemplateResults.length > 0) {
          var paymentOptions = Parse.Object.extend("FeeTemplateItem");
          var query = new Parse.Query(paymentOptions);
          query.equalTo("feeTemplateId", feeTemplateResults[0].id);
          query.notEqualTo("isDeleted", true);
          query.find().then(
            (result) => {
              var totalAmount = 0;
              let totalTax = 0;
              let totalRoyalty = 0;
              for (var i = 0; i < result.length; i++) {
                totalAmount += result[i].attributes.totalAmount;
              }
              var kidIds = [kidId];
              var currentdate = programObject.attributes.startDate ?? new Date();
              let year = currentdate.getFullYear();
              if (currentdate.getMonth()<=2) {
                year = year - 1;
              }
              var invoiceObject = {
                instituteId: school.attributes.instituteId,
                schoolId: school.id,
                title: feeTemplateResults[0].attributes.name,
                createdBy: Parse.User.current().attributes.teacherId,
                amount: totalAmount,
                recipientType: 6,
                recipientIds: kidIds,
                tags: [
                  `${year}-${year + 1 - 2000}`,
                ],
              };

              if (result) {
                let tempItems = [];
                for (let val of result) {
                  let temp = {};
                  temp.slno = val.attributes.slno;
                  temp.itemDescription = val.attributes.itemDescription;
                  temp.dueDate = val.attributes.dueDate;
                  temp.amount = val.attributes.amount;
                  temp.discount = val.attributes.discount;
                  temp.discountType = val.attributes.discountType;
                  temp.discountDisabled = val.attributes.discount > 0;
                  temp.discountRemark = val.attributes.discountRemark;
                  temp.totalAmount =
                    val.attributes.discountType === 1
                      ? val.attributes.amount - val.attributes.discount
                      : val.attributes.amount -
                      (val.attributes.amount * val.attributes.discount) / 100;
                  
                  temp.tax = val.attributes.tax
                  totalAmount += temp.totalAmount
                  if (temp.tax > 0) {
                    let tTax = roundToTwo(temp.totalAmount * 0.01 * temp.tax);
                    temp.taxAmount = tTax
                    totalTax = totalTax + tTax
                    temp.totalAmount = temp.totalAmount + tTax
                  }
                  temp.royalty = val.attributes.royalty;
                  if (temp.royalty > 0) {
                    temp.royaltyAmount = (temp.totalAmount * temp.royalty) / 100
                    totalRoyalty += temp.royaltyAmount;
                  } else {
                    temp.royaltyAmount = 0;
                  }
                  
                  tempItems.push(temp);
                }

                if (totalRoyalty > 0) {
                  invoiceObject.royaltyAmount = totalRoyalty;
                  invoiceObject.isRoyaltyInvoice = true;
                }
                invoiceObject["items"] = tempItems;
              }
              invoiceObject["programId"] = programObject.id;
              Parse.Cloud.run("function_createPaymentRequest", invoiceObject).then(
                (result) => {
                  resolve(result);

                },
                (error) => {
                  resolve(null);
                }
              );

            },
            (error) => {
              resolve(null)
            }
          );
        } else {
          resolve(null)
        }
      })
    } catch (err) {
      console.log(err);
    }
  });
};


export const deleteSelectedInvoice = (id) => {
  printLog("HL: invoice_helper : deleteSelectedInvoice")
  return new Promise((resolve, reject) => {
    try {
      let Invoice = Parse.Object.extend("Invoice");
      let invoice = new Invoice();
      invoice.id = id;
      invoice.set("isDeleted", true);
      invoice.set("deletedBy", Parse.User.current().attributes.teacherId??Parse.User.current().id);
      invoice.save().then((result) => {
        resolve(result);
      });
    } catch (err) {
      console.log(err);
    }
  });
};

export const sendNotifyPaymentToParent = (invoiceId, kidId) => {
  printLog("HL: invoice_helper : sendNotifyPaymentToParent")
  return new Promise((resolve, reject) => {
    try {
      Parse.Cloud.run("sendFeeStructure", {
        invoiceId: invoiceId,
        kidId: kidId,
      }).then((result) => {
        resolve(result);
      });
    } catch (err) {
      console.log(err);
    }
  });
};

export const getInvoiceCountForSchoolId = (schoolId, tags) => {
  printLog("HL: invoice_helper : getInvoiceCountForSchoolId")
  return new Promise((resolve, reject) => {
    try {
      //success
      let query = new Parse.Query("Invoice");
      query.equalTo("schoolId", schoolId);
      query.equalTo("paidStatus", 1);
      query.containedIn("tags", tags);
      query.notEqualTo("isDeleted", true);
      query.count().then((successCount) => {
        let responseRes = {};
        responseRes.successCount = successCount;
        let query = new Parse.Query("Invoice");
        query.equalTo("schoolId", schoolId);
        query.equalTo("paidStatus", 3);
        query.containedIn("tags", tags);
        query.notEqualTo("isDeleted", true);
        query.count().then((partiallyPaid) => {
          responseRes.partiallyPaid = partiallyPaid;
          let query1 = new Parse.Query("Invoice");
          query1.equalTo("schoolId", schoolId);
          query1.equalTo("paidStatus", 0);
          query1.notEqualTo("isDeleted", true);
          let query2 = new Parse.Query("Invoice");
          query2.equalTo("schoolId", schoolId);
          query2.doesNotExist("paidStatus");
          query2.notEqualTo("isDeleted", true);
          let query = Parse.Query.or(query1, query2);
          query.containedIn("tags", tags);
          query.count().then((pending) => {
            responseRes.pending = pending;
            resolve(responseRes);
          });
        });
      });
    } catch (err) {
      console.log(err);
    }
  });
};

export const updateOrSaveInvoice = (invoice) => {
  printLog("HL: invoice_helper : updateOrSaveInvoice")
  return new Promise((resolve, reject) => {
    try {
      invoice.save().then((result) => {
        resolve(result);
      });
    } catch (err) {
      console.log(err);
    }
  });
};

export const getRecurringMasterInvoicesForSchoolId = (id, updatedDate, isLoacal) => {
  printLog("HL: invoice_helper : getRecurringMasterInvoicesForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Invoice = Parse.Object.extend("MasterInvoice");
      var query = new Parse.Query(Invoice);
      query.equalTo("schoolId", id);
      query.equalTo("isRecurringInvoice", true);
      

      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const getRecurringInvoicesForSchoolId = (id, updatedDate, isLoacal) => {
  printLog("HL: invoice_helper : getRecurringInvoicesForSchoolId")
  try {
    return new Promise((resolve, reject) => {
      var Invoice = Parse.Object.extend("Invoice");
      var query = new Parse.Query(Invoice);
      query.equalTo("schoolId", id);
      query.equalTo("isRecurringInvoice", true);


      if (updatedDate) {
        query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
      } else {
        query.notEqualTo("isDeleted", true);
      }

      if (isLoacal) {
        query.fromLocalDatastore();
        query.notEqualTo("isDeleted", true);
      }

      query.descending("createdAt");
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const editRecurringMasterInvoice = (masterInvoice) => {
  printLog("HL: invoice_helper : editRecurringMasterInvoice")
  try {
    return new Promise((resolve, reject) => {
      masterInvoice.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );
    });
  } catch (error) { }
};

export const stopRecurringMasterInvoice = (masterInvoice) => {
  printLog("HL: invoice_helper : stopRecurringMasterInvoice")
  try {
    return new Promise((resolve, reject) => {
      masterInvoice.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    });
  } catch (error) { }
};
