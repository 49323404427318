import * as actions from "./actionTypes";

export const updateReminder = (reminder) => {
  return {
    type: actions.UPDATE_REMINDER,
    payload: { reminder },
  };
};

export const deleteReminder = (reminder) => {
  return {
    type: actions.DELETE_REMINDER,
    payload: {reminder},
  };
};

export const updateReminders = (reminders) => {
  return {
    type: actions.UPDATE_REMINDERS,
    payload: { reminders },
  };
};

export const updateSelectedReminder = (reminders) => {
  return {
    type: actions.UPDATE_SELECTED_REMINDER,
    payload: { reminders },
  };
};

// export const getReminderforSchoolId = (schoolId) => {
//   return {
//     type: actions.GET_REMINDERS,
//     payload: { schoolId },
//   };
// };


export const getAllRemindersForSchool = (schoolId, classIds, groupIds, familyIds) => {
  return {
    type: actions.GET_ALL_REMINDERS_FOR_SCHOOL,
    payload: { schoolId, classIds, groupIds, familyIds }
  }
}

export const saveAllReminders = (reminders) => {
  return {
    type: actions.SAVE_ALL_REMINDERS,
    payload: { reminders }
  }
}

export const editReminder = (reminder,emailNotification) => {
  return {
    type: actions.EDIT_REMINDER,
    payload: { reminder ,emailNotification}
  }
}
export const addReminder = (reminder,emailNotification) => {
  return {
    type: actions.EDIT_REMINDER,
    payload: { reminder ,emailNotification}
  }
}


