import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    Label,
    Button,
    UncontrolledTooltip,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter
} from "reactstrap";
import toastr from "toastr";
import Moment from 'moment';
import { Link, withRouter } from "react-router-dom";
import Parse from "parse";
import { connect } from "react-redux";
import { withNamespaces } from "react-i18next";
import classnames from "classnames";
import SweetAlert from "react-bootstrap-sweetalert";

import {
    getCamerasForSchoolId,
    updateCameras,
} from "../../store/actions";

const CCTVComponent = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [displayList, setDisplayList] = useState([]);
    const [cameraToRemove, setCameraToRemove] = useState(undefined);
    const [forceUpdateList, setForceUpdateList] = useState(false);
    const [addCameraModal, setAddCameraModal] = useState(false);
    const [selectedCameraList, setSelectedCameraList] = useState([]);
    const [addingCamerasForKidLoading, setAddingCamerasForKidLoading] = useState(false);

    useEffect(() => {
        if (props.schoolId) {
            props.getCamerasForSchoolId(props.schoolId);
        }
    }, [props.schoolId])

    useEffect(() => {
        if ((props.cameras && props.selectedKid) || forceUpdateList) {
            if (forceUpdateList) {
                setForceUpdateList(false)
            }
            let tempDisplayList = []
            for (const camera of props.cameras) {
                if (camera.attributes.kids && camera.attributes.kids.includes(props.selectedKid.id)) {
                    let tempObj = {}
                    tempObj.name = camera.attributes.name
                    tempObj.parseObject = camera
                    tempObj.id = camera.id
                    tempDisplayList.push(tempObj)
                }
            }
            if (tempDisplayList) {
                setDisplayList(tempDisplayList)
            }
            setIsLoading(false)
        }
    }, [props.cameras, props.selectedKid, forceUpdateList])

    //// Utilities functions
    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    const removeCamera = () => {
        if (cameraToRemove && cameraToRemove.attributes.kids) {
            let kidIds = [...cameraToRemove.attributes.kids]
            removeElement(kidIds, props.selectedKid.id)
            cameraToRemove.set("kids", kidIds)
            cameraToRemove.save().then(
                (result) => {
                    props.updateCameras([result])
                    setForceUpdateList(true)
                    setCameraToRemove(undefined);
                },
                (error) => {
                    setCameraToRemove(undefined);
                }
            );
        }
    }

    const handleCheckboxChange = (camera) => {
        let selectedList = [...selectedCameraList]
        if (selectedList.includes(camera.id)) {
            removeElement(selectedList, camera.id)
        } else {
            selectedList.push(camera.id)
        }
        setSelectedCameraList(selectedList)
    }

    const isCameraSelectedForKid = (camera) => {
        return selectedCameraList.includes(camera.id)
    }
    const isCameraAvailableForKid = (camera) => {
        let returnValue = false
        if (camera.attributes.kids) {
            let kidIds = [...camera.attributes.kids]
            if (kidIds.includes(props.selectedKid.id)) {
                returnValue = true
            }
        }
        return returnValue
    }

    const handleAddCameras = () => {
        if (selectedCameraList && selectedCameraList.length > 0) {
            let cameraObjectsToSave = []
            let camList = props.cameras.filter((camera) =>
                selectedCameraList.includes(camera.id)
            )
            if (camList.length > 0) {
                for (const cam of camList) {
                    let kidIds = [...cam.attributes.kids]
                    if (!kidIds.includes(props.selectedKid.id)) {
                        kidIds.push(props.selectedKid.id)
                    }
                    cam.set("kids", kidIds)
                    cameraObjectsToSave.push(cam)
                }
            }
            if (cameraObjectsToSave.length > 0) {
                setAddingCamerasForKidLoading(true)
                Parse.Object.saveAll(cameraObjectsToSave).then(
                    (results) => {
                        props.updateCameras(results);
                        setSelectedCameraList([]);
                        toastr.success("Cameras added successfully !!")
                        setForceUpdateList(true);
                        setAddingCamerasForKidLoading(false);
                        setAddCameraModal(false)
                    },
                    (error) => {
                        setSelectedCameraList([]);
                        setAddingCamerasForKidLoading(false);
                        setAddCameraModal(false)
                    }
                );
            }
        }
    }

    return (
        <React.Fragment>
            {cameraToRemove && (
                <SweetAlert
                    title={props.t('Are you sure to remove?')}
                    warning
                    showCancel
                    confirmBtnText="Remove"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="secondary"
                    onConfirm={() => {
                        removeCamera();
                    }}
                    onCancel={() => {
                        setCameraToRemove(undefined);
                    }}>
                    {props.t("You won't be able to revert this!")}

                </SweetAlert>

            )}

            <Modal isOpen={addCameraModal} scrollable={true}>
                <ModalHeader isOpen={addCameraModal} toggle={() => { setAddCameraModal(!addCameraModal) }}>
                    <h5 className="modal-title mt-0">{props.t(`Select Camera to add ${props.selectedKid.attributes.Name}`)}</h5>
                </ModalHeader>
                <ModalBody className="modal-body">
                    <Row>
                        <Col>
                            <Row>
                                <div className="table-responsive mt-3 ml-2 mr-3">
                                    <table className="table table-nowrap table-centered mb-0">
                                        <tbody>
                                            {props.cameras && props.cameras.length === 0 && (
                                                <div className="ml-1">
                                                    <p className="ml-3">No cameras Assigned !!</p>
                                                    <hr />
                                                </div>
                                            )}

                                            {props.cameras && props.cameras.map((object, keyInside) => (
                                                <tr key={keyInside}>
                                                    <td style={{ width: "60px" }}>
                                                        <input
                                                            type="checkbox"
                                                            id={"CCTVStudentProfile" + keyInside}
                                                            disabled={isCameraAvailableForKid(object)}
                                                            onChange={() => {
                                                                if (!isCameraAvailableForKid(object)) {
                                                                    handleCheckboxChange(object);
                                                                }
                                                            }}
                                                            checked={isCameraAvailableForKid(object) || isCameraSelectedForKid(object)}
                                                        />
                                                    </td>
                                                    <td>
                                                        <Label className="text-truncate font-size-14 m-0">

                                                            <Label className="text-dark ml-2">
                                                                {object.attributes.name}
                                                            </Label>{" "}
                                                        </Label>
                                                    </td>

                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                            </Row>
                        </Col>
                    </Row>
                </ModalBody>
                <ModalFooter>
                    <button className="btn btn-primary" onClick={() => {
                        setAddCameraModal(false)
                        setSelectedCameraList([])
                    }}>
                        Cancel
                    </button>
                    <button className="btn btn-primary"
                        disabled={addingCamerasForKidLoading}
                        onClick={() => { handleAddCameras() }}>
                        {addingCamerasForKidLoading && <i className="bx bx-loader-circle bx-spin font-size-16 align-middle mr-2"></i>}
                        {addingCamerasForKidLoading ? "Adding Cameras" : "Add"}
                    </button>
                </ModalFooter>
            </Modal>
            {!isLoading && (
                <div>
                    <Row>
                        <div className="d-flex">
                        <button className="btn btn-primary btn-sm mb-4 ml-auto" style={{ marginLeft: 'auto'}} onClick={() => {
                            setAddCameraModal(true)
                        }}>
                            Add to Cameras
                        </button>
                        </div>
                    </Row>
                    {displayList.length > 0 && (
                        <div className="table-responsive">
                            <table className="table table-centered table-nowrap mb-0">
                                <thead className="thead-light">
                                    <tr>
                                        <th>{props.t("Camera Name")}</th>
                                        <th style={{ textAlign: 'center', width: "30px" }}>{props.t("Actions")}</th>
                                    </tr>

                                </thead>
                                <tbody>
                                    {displayList.map((object, key) => (
                                        <tr key={key}>
                                            <td>
                                                <Label className="font-size-12 mb-1">
                                                    {object.name
                                                        ? object.name
                                                        : "-"}
                                                </Label>
                                            </td>
                                            <td style={{ textAlign: 'center', width: "30px" }}>
                                                <ul className="list-inline font-size-20 contact-links mb-0">
                                                    <li className="list-inline-item px-2">
                                                        <Link
                                                            to="#" id={"deleteCameraStudentProfile" + object.id}
                                                            onClick={(e) => {
                                                                e.preventDefault()
                                                                setCameraToRemove(object.parseObject)
                                                            }}>
                                                            <i className="bx bx-trash text-danger"></i>
                                                            <UncontrolledTooltip placement="top" target={"deleteCameraStudentProfile" + object.id}>
                                                                {props.t('Remove')}
                                                            </UncontrolledTooltip>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    )}
                    {displayList.length === 0 && (
                        <div className=""><p className="text-center">No Cameras Assigned !!</p></div>
                    )}
                </div>
            )}
            {isLoading && (
                <Row>
                    <Col xs="12">
                        <div className="text-center my-3">
                            <Link to="#" className="text-success">
                                <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                Loading Cameras...{" "}
                            </Link>
                        </div>
                    </Col>
                </Row>
            )}
        </React.Fragment>
    );
};


const mapStatetoProps = (state) => {
    const { cameras } = state.Camera;
    return {
        cameras
    };
};
export default connect(mapStatetoProps, {
    getCamerasForSchoolId,
    updateCameras,
})(withRouter(withNamespaces()(CCTVComponent)));


