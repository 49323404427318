import Parse from "parse";

export const getInventoryTagsForOwnerId = (ownerId, ownerType, updatedDate,isLocal,) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryTag = Parse.Object.extend("InventoryTags");
        var query = new Parse.Query(InventoryTag);
        query.equalTo("ownerId", ownerId);
        query.equalTo("ownerType", ownerType)
  
        if (updatedDate) {
          query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
       } else {
         query.notEqualTo("isDeleted", true);
       }
        if (isLocal) {
          query.fromLocalDatastore();
          query.notEqualTo("isDeleted", true);
        }
        query.addAscending("order");
        query.find().then(
          (result) => {
           resolve(result); 
          },
          (error) => {
            resolve(error);
          }
        );
      });
    } catch (error) {}
  };

  export const deleteInventoryTagFromParse = (inventoryTag) => {
    try {
      return new Promise((resolve, reject) => {
        inventoryTag.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            inventoryTag.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            inventoryTag.set("deletedBy", Parse.User.current().id);
        }
  
        inventoryTag.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("error");
          }
        );
      });
    } catch (error) {}
  };
  
  export const getInventoryTagFromLocal = (inventoryTagId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryTag = Parse.Object.extend("InventoryTags");
        var query = new Parse.Query(InventoryTag);
        query.fromLocalDatastore();
        query.get(inventoryTagId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  export const getInventoryTagFromParse = (inventoryTagId) => {
    try {
      return new Promise((resolve, reject) => {
        var InventoryTag = Parse.Object.extend("InventoryTags");
        var query = new Parse.Query(InventoryTag);
        query.get(inventoryTagId).then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  export const saveCustomObjects = (object) => {
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            resolve("Error");
          }
        );
      });
    } catch (error) { }
  };

