import React, { useState, useEffect } from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter, ModalHeader, Input, Container, Label, Card, CardBody, FormGroup } from "reactstrap";
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import moment from 'moment';
import { Editor } from "react-draft-wysiwyg";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { EditorState, ContentState, convertToRaw } from 'draft-js';
import draftToHtml from 'draftjs-to-html';
import htmlToDraft from 'html-to-draftjs';

import { getAKid, setSelecetdSchool, getClassroomsForSchoolId } from "../../store/actions";
import { Form } from 'reactstrap/lib';
import Header from './header';
import SaveAsPdf from '../../components/Common/SaveAsPdf';


const Certificate = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/custom-certificate/:schoolId",
        exact: true,
        strict: false,
    });

    const today = new Date()
    const kidId = match && match.params.id;
    const schoolId = match && match.params.schoolId;


    const [parent, setParent] = useState();
    const [modal1, setModal1] = useState(false);
    const [modal2, setModal2] = useState(false);
    const [modal3, setModal3] = useState(false);
    const [editorState, setEditorState] = useState();
    const [content, setContent] = useState('');
    const [certificateName, setCertificateName] = useState('Certificate Name');
    const [certificateTitle, setCertificateTitle] = useState('Certificate Title');
    const [showRow, setShowRow] = useState(true);
    const [showSaveAsPdfModal, setShowSaveAsPdfModal] = useState(false);
    const [dontShowAgain, setDontShowAgain] = useState(false);
    const [machineType, setMachineType] = useState('');

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId])

    useEffect(() => {
        if (kidId) {
            props.getAKid(kidId);
        }
    }, [kidId]);


    useEffect(() => {
        props.getClassroomsForSchoolId(schoolId);
    }, [schoolId]);

    function toggle1() {
        setModal1(!modal1)
    }

    function toggle2() {
        setModal2(!modal2)
    }

    function toggle3() {
        setModal3(!modal3)
    }

    const getYear = () => {
        let year = today.getFullYear();
        if (today.getMonth() <= 2) {
            year = year - 1;
        }

        return `${year}-${year + 1 - 2000}`
    }

    const printPoster = () => {
        setTimeout(() => {
            window.print()
        }, 300);
    }

    useEffect(() => {
        const savedValue = localStorage.getItem('dontShowAgain');
        if (savedValue === 'true') {
            setDontShowAgain(true);
        }
    
        const userAgent = navigator.userAgent.toLowerCase();
        
        if (userAgent.includes('mac')) {
            setMachineType('Mac');
        } else if (userAgent.includes('windows')) {
            setMachineType('Windows');
        } else {
            setMachineType('Unknown');
        }
    }, []);

      const handleNextClick = () => {
        setShowSaveAsPdfModal(false);
        setTimeout(() => {
            printPoster();
        }, 300);
      }


    useEffect(() => {
        if (props.selectedKid) {
            let parent = {}
            if (props.selectedKid.parents) {
                for (let k in props.selectedKid.parents) {
                    if (props.selectedKid.parents[k].attributes.Relation === "Mother") {
                        parent.mother = props.selectedKid.parents[k];
                    } else {
                        parent.father = props.selectedKid.parents[k];
                    }
                }
            }
            setParent(parent)
        }
    }, [props.selectedKid])

    const getDateInDDMMYYYY = (date) => {
        return moment(date).format("DD/MM/YYYY");
    };

    const getClassRoomName = (classroomId) => {
        if (props.classrooms && props.classrooms.length > 0) {
            let temp = props.classrooms.filter(
                (classroom) => classroom.id === classroomId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name + " " ?? "-")
            }
        }
    }


    const getUI1 = () => {
        return (<div >
            {
                !(props.selectedSchool) ? <Col xs="12">
                    <div className="text-center my-3">
                        <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading.....</Label>
                    </div>

                </Col> :
                    <Row style={{ backgroundColor: "#fff" }} className='p-4'>
                        <Col lg="12">
                            <div className="invoice-certificateName" style={{ visibility: showRow ? 'visible' : 'hidden' }}>
                                <Row className="d-flex justify-content-between">
                                    {props.selectedSchool.attributes.logo && (
                                        <Col className="d-flex justify-content-start">
                                            <img src={props.selectedSchool.attributes.logo._url} style={{ height: "6rem" }} />
                                        </Col>
                                    )}

                                    <Col className="d-flex justify-content-end">
                                        <div className="d-flex justify-content-start align-items-start">
                                            <address>
                                                <strong>School Details:</strong>
                                                <br />

                                                {props.selectedSchool.attributes.Name && (
                                                    <strong>
                                                        {props.selectedSchool.attributes.Name}
                                                        <br />
                                                    </strong>
                                                )}
                                                {props.selectedSchool.attributes.Address}
                                                {props.selectedSchool.attributes.Address && <br />}
                                                {props.selectedSchool.attributes.primaryPhoneNumber && (
                                                    <span>{props.selectedSchool.attributes.primaryPhoneNumber}</span>
                                                )}
                                                {", "}
                                                {props.selectedSchool.attributes.secondaryPhoneNumber && (
                                                    <span>{props.selectedSchool.attributes.secondaryPhoneNumber}</span>
                                                )}
                                                {(props.selectedSchool.attributes.primaryPhoneNumber ||
                                                    props.selectedSchool.attributes.secondaryPhoneNumber) && <br />}
                                                {props.selectedSchool.attributes.EMail}
                                                {props.selectedSchool.attributes.EMail && <br />
                                                }
                                            </address>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                            <hr />
                            <Row className="d-flex justify-content-between">
                                <Col className='d-flex justify-content-start'>
                                    <strong>{certificateName}</strong> <i className='bx bx-edit-alt border d-print-none' onClick={() => { toggle1() }}></i>
                                </Col>
                                <Col className='d-flex justify-content-end'>
                                    <p className="text-right">Date:{getDateInDDMMYYYY(today)}</p>
                                </Col>
                            </Row>
                            <div className='text-center'></div>
                            <br></br>
                            <br></br>
                            <Row>
                                <Col lg={12}>
                                    <div className='d-flex align-items-center justify-content-center'>
                                        <h5 className='text-center mb-0 mr-3'>{certificateTitle}</h5>
                                        <span className='d-print-none'><i className='bx bx-edit-alt border' onClick={() => { toggle2() }}></i></span>
                                    </div>
                                </Col>
                            </Row>
                            <br></br>

                            {content ? <div className="text-left font-size-16 ql-container"><div
                                dangerouslySetInnerHTML={{
                                    __html: content
                                }}
                            /></div>
                                :
                                <h4 className='text-center font-size-16 d-print-none' ><b>
                                    To add the Certificate content <Button color='info' onClick={() => { toggle3() }}>Click here</Button>
                                </b></h4>}
                            {/* {props.selectedKid.attributes.address &&
                                <p className='text-left font-size-14'>
                                    {
                                        props.selectedKid.attributes.Gender == "Male " ?
                                            "He is Residing at " + props.selectedKid.attributes.address :
                                            "She is Residing at " + props.selectedKid.attributes.address
                                    }
                                </p>
                            } */}

                            <div style={{ height: "50mm", }} className='d-flex justify-content-end'>
                                <div className=" d-print-none font-size-20 ">
                                    <i className='bx bx-edit-alt' onClick={() => { toggle3() }}></i>
                                </div>
                            </div>

                            <Row className="d-flex justify-content-between">
                                <Col className='d-flex justify-content-start'>
                                    <h6><strong><i>Seal&Signature</i></strong> </h6>
                                </Col>
                                <Col className='d-flex justify-content-end d-print-none'>
                                    <Label
                                        onClick={() => {
                                            if (['Windows', 'Mac'].includes(machineType)) {
                                                if(!dontShowAgain){
                                                    setShowSaveAsPdfModal(false);
                                                }else{
                                                    printPoster();
                                                }
                                            }else{
                                                printPoster();
                                            }
                                        }}
                                        className="btn btn-success waves-effect waves-light mr-2"
                                    >
                                        <i className="fa fa-print"></i>
                                    </Label>
                                    <SaveAsPdf
                                        showModal={showSaveAsPdfModal}
                                        toggleModal={() => setShowSaveAsPdfModal(false)}
                                        dontShowAgain={dontShowAgain}
                                        setDontShowAgain={setDontShowAgain}
                                        onNextClick={handleNextClick}
                                        machineType={machineType}
                                    />
                                </Col>
                            </Row>

                        </Col>
                    </Row>
            }
            <Modal isOpen={modal1} toggle={() => toggle1()} className="">
                <ModalHeader toggle={() => toggle1()} tag="h4">
                    Certificate Name
                </ModalHeader>
                <ModalBody>
                    <AvForm >
                        <Row form>
                            <Col className="col-12">
                                <Form>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="Certificate"
                                            placeholder=""
                                            value={certificateName}
                                            onChange={(e) => {
                                                setCertificateName(e.target.value);
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-right">
                                    <Button color='primary' className="btn mr-2" onClick={() => toggle1()}>Done</Button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
            <Modal isOpen={modal2} toggle={() => toggle2()} className="">
                <ModalHeader toggle={() => toggle2()} tag="h4">
                    Certificate Title
                </ModalHeader>
                <ModalBody>
                    <AvForm >
                        <Row form>
                            <Col className="col-12">
                                <Form>
                                    <FormGroup>
                                        <Input
                                            type="text"
                                            name="Certificate"
                                            placeholder=""
                                            value={certificateTitle}
                                            onChange={(e) => {
                                                setCertificateTitle(e.target.value);
                                            }}
                                        />
                                    </FormGroup>
                                </Form>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <div className="text-right">
                                    <Button color='primary' className="btn mr-2" onClick={() => toggle2()}>Done</Button>
                                </div>
                            </Col>
                        </Row>
                    </AvForm>
                </ModalBody>
            </Modal>
            <Modal size='lg' isOpen={modal3} toggle={() => toggle3()} className="">
                <ModalHeader toggle={() => toggle3()} tag="h4">
                    Certificate content
                </ModalHeader>
                <ModalBody>
                    <Form>
                        <FormGroup>
                            <Editor
                                editorState={editorState}
                                toolbarClassName="toolbarClassName"
                                wrapperClassName="wrapperClassName"
                                editorClassName="editorClassName"
                                onEditorStateChange={newState => {
                                    setEditorState(newState);
                                    let con = draftToHtml(convertToRaw(newState.getCurrentContent()));
                                    setContent(con);
                                }}
                            />
                        </FormGroup>
                        <Row>
                            <Col>
                                <div className="text-right">
                                    <Button color='primary' className="btn mr-2" onClick={() => toggle3()}>Done</Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </ModalBody>
            </Modal>
        </div>)
    }
    return (<React.Fragment>
        <div  >
            <Header
                certificate={"Letter/Certificate"}
                hide={true}
                showRow={showRow}
                onChangeShowRow={(v) => { setShowRow(v) }} />
            <div className="account-pages pt-3">
                <Container fluid='md'>
                    {getUI1()}
                </Container>
            </div>
        </div>
    </React.Fragment>

    )
}

const mapStatetoProps = (state) => {

    const { selectedKid } = state.Kid;
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;


    return { selectedKid, selectedSchool, classrooms };
};

export default (withRouter(connect(mapStatetoProps, { getAKid, setSelecetdSchool, getClassroomsForSchoolId })(Certificate)));