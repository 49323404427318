import React, { useState, useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import {
  Card,
  CardBody,
  CardTitle,
  CardText,
  Modal,
  ModalBody,
  ModalHeader,
  ModalFooter,
  Label,
} from "reactstrap";
import * as Constants from '../../Constents';
import { withNamespaces } from "react-i18next";
import Parse from "parse";
import * as CommonFunctions from "../../components/Common/CommonFunctions";

const GeneralQuestions = (props) => {
  const [selectedQuestions, setSelectedQuestions] = useState([]);
  const [selectedQuestionToRemove, setSelectedQuestionToRemove] = useState(
    undefined
  );
  const [selectedQuestionToAdd, setSelectedQuestionToAdd] = useState(undefined);

  const [titleObjectId, setTitleObjectId] = useState(undefined);
  const [titlesModel, showTitlesModel] = useState(false);
  const [titleSelcetWarning, setTitleSelcetWarning] = useState(false);
  const [allDefaultQuestions, setAlldefaultQuestions] = useState([]);

  useEffect(() => {
    if (props.defaultQuestions && props.defaultQuestions.length > 0) {
      var list = [];
      var objectList = [];
      for (const i in props.defaultQuestions) {
        var quest = props.defaultQuestions[i];
        var temp = props.admissionQuestions.filter((question) =>
          validate(props.defaultQuestions[i], question)
        );

        var questionObject = undefined;
        if (temp && temp.length > 0) {
          questionObject = temp[0];
          list.push(quest.id);
        }
        var obj = {
          id: quest.id,
          question: quest.attributes.question,
          questionType: quest.attributes.questionType,
          choice: quest.attributes.choice,
          dataType: quest.attributes.dataType,
          databaseFieldName: quest.attributes.databaseFieldName,
          databaseTableName: quest.attributes.databaseTableName,
          objectType: quest.attributes.objectType,
          order: 0,
          questionObject: questionObject,
        };

        objectList.push(obj);
      }
      setAlldefaultQuestions(objectList);
      setSelectedQuestions(list);
    }
  }, [props.admissionQuestions, props.defaultQuestions]);

  function validate(q1, q2) {
    if (
      q1.attributes.databaseFieldName === q2.attributes.databaseFieldName &&
      q1.attributes.databaseTableName === q2.attributes.databaseTableName
    ) {
      if (q1.attributes.databaseTableName === "Parent") {
        if (q1.attributes.objectType === q2.attributes.objectType) {
          return true;
        }
      } else {
        return true;
      }
    }

    return false;
  }

  function handelRemoveQuestion() {
    var obj = selectedQuestionToRemove;
    setSelectedQuestionToRemove(undefined);
    props.onRemoveQuestion(obj);

    var others = selectedQuestions.filter(
      (enquiryquestion) => enquiryquestion.id !== selectedQuestionToRemove.id
    );

    setSelectedQuestions(others);
  }

  function onSelectQustion(question) {
    if (selectedQuestions.length > 0) {
      if (selectedQuestions.includes(question.id) > 0) {
        setSelectedQuestionToRemove(question.questionObject);
      } else {
        setSelectedQuestionToAdd(question);
        showTitlesModel(true);
      }
    } else {
      setSelectedQuestionToAdd(question);
      showTitlesModel(true);
    }
  }

  function handelAddQuestion() {
    const AdmissionQuestion = Parse.Object.extend("AdmissionQuestion");
    var admissionQuestion = new AdmissionQuestion();
    admissionQuestion.set(
      "databaseFieldName",
      selectedQuestionToAdd.databaseFieldName
    );
    admissionQuestion.set(
      "databaseTableName",
      selectedQuestionToAdd.databaseTableName
    );
    admissionQuestion.set("objectType", selectedQuestionToAdd.objectType);
    admissionQuestion.set("question", selectedQuestionToAdd.question);
    admissionQuestion.set("questionType", selectedQuestionToAdd.questionType);

    admissionQuestion.set("dataType", selectedQuestionToAdd.dataType);
    admissionQuestion.set("choice", selectedQuestionToAdd.choice);

    admissionQuestion.set("schoolId", props.schoolId);

    admissionQuestion.set("admissionTitleId", titleObjectId);

    var orderLength = props.admissionQuestions.filter(qus => qus.get("admissionTitleId") === titleObjectId);
    admissionQuestion.set("order", orderLength.length);

    props.onAddQuestion(admissionQuestion);

    setSelectedQuestionToRemove(undefined);
    setSelectedQuestionToAdd(undefined);
    showTitlesModel(false);
    setTitleObjectId(undefined);
  }

  

  return (
    <React.Fragment>
      <Card>
        <CardBody>
          <CardTitle className="mb-2">
            {props.t("General Admission Questions")}
          </CardTitle>

          <CardText className="mb-2">
            {props.t(
              "Please select the question which you want in your school Admission form"
            )}
          </CardText>
          <div className="table-responsive">
            <table className="table table-nowrap table-centered mb-0">
              <tbody>
                {allDefaultQuestions.map((question) => (
                  <tr key={question.id}>
                    <td style={{ width: "60px" }}>
                      <div className="custom-control custom-checkbox mb-2 ml-3">
                        <input
                          type="checkbox"
                          className="custom-control-input"
                          id={question.id}
                          onChange={() => onSelectQustion(question)}
                          checked={selectedQuestions.includes(question.id)}
                        />
                        <label
                          className="custom-control-label"
                          onClick={() => {
                            onSelectQustion(question);
                          }}
                        ></label>
                      </div>
                    </td>
                    <td>
                      <h5 className="text-truncate font-size-14 m-0">
                        <Label className="text-dark">
                          {question.question}
                        </Label>
                        <p className="text-muted">{CommonFunctions.getQuesionDataType(question.dataType,question.questionType,question.databaseFieldName,props)}</p>
                      </h5>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </CardBody>
      </Card>

      {selectedQuestionToRemove && (
        <SweetAlert
          danger
          showCancel
          confirmBtnText="Remove"
          cancelBtnText="Cancel"
          confirmBtnBsStyle="danger"
          cancelBtnBsStyle="success"
          title="Alert!"
          onCancel={() => {
            setSelectedQuestionToRemove(undefined);
          }}
          onConfirm={() => handelRemoveQuestion()}
        >
          {props.t("Are you sure you want to remove the question")} <br></br>
        </SweetAlert>
      )}

      <Modal isOpen={titlesModel} scrollable={true}>
        <ModalHeader isOpen={titlesModel}>
          <h5 className="modal-title mt-0">{props.t("Select Section")}</h5>
        </ModalHeader>
        <ModalBody className="modal-body">
          <p>
            {props.t(
              "Please select the section in which question has to appear , On saving Question will be added to selected section"
            )}
          </p>
          {props.admissionTitles &&
            props.admissionTitles.map((item) => (
              <div
                className="custom-control custom-checkbox mb-2 ml-3"
                key={item.id}
              >
                <input
                  type="checkbox"
                  className="custom-control-input m-1"
                  id={item.id}
                  onChange={() => setTitleObjectId(item.id)}
                  checked={item.id === titleObjectId}
                />
                <label
                  className="custom-control-label m-1"
                  onClick={() => {
                    setTitleObjectId(item.id);
                  }}
                >
                  {item.attributes.title}
                </label>
              </div>
            ))}
          {titleSelcetWarning && (
            <p className="text-danger ml-2">
              {props.t("Please select a section")}
            </p>
          )}
        </ModalBody>

        <ModalFooter>
          {
            // !loding ?
            <div>
              <button
                type="button"
                className="btn btn-secondary m-1"
                onClick={() => {
                  setSelectedQuestionToRemove(undefined);
                  setSelectedQuestionToAdd(undefined);
                  showTitlesModel(false);
                  setTitleObjectId(undefined);
                }}
              >
                {props.t("Close")}
              </button>
              <button
                type="button"
                className="btn btn-primary m-1"
                onClick={() => {
                  if (titleObjectId) {
                    handelAddQuestion();
                  } else {
                    setTitleSelcetWarning(true);
                  }
                }}
              >
                {props.t("Save")}
              </button>
            </div>
            // :
            //     <button type="button" className="btn btn-light waves-effect">
            //         <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Moving to Classroom')}
            //     </button>
          }
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default withNamespaces()(GeneralQuestions);
