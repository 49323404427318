import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";

const initialState = {
    inventorySaleOrderItems: [], loding: undefined,
    selectedInventorySaleOrderItems: undefined,
};

const InventorySaleOrderItem = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_INVENTORY_SALE_ORDER_ITEMS:
            var inventorySaleOrderItems = [...state.inventorySaleOrderItems];
            if (inventorySaleOrderItems.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.inventorySaleOrderItems], action.payload.inventorySaleOrderItems, "isDeleted")

                state = { ...state, inventorySaleOrderItems: updatedList };
            } else {
                var i = 0
                var inventorySaleOrderItemsToSave = []
                for (i = 0; i < action.payload.inventorySaleOrderItems.length; i++) {
                    if (action.payload.inventorySaleOrderItems[i].attributes.isDeleted !== true) {
                        inventorySaleOrderItemsToSave.push(action.payload.inventorySaleOrderItems[i])
                    }
                }
                state = { ...state, inventorySaleOrderItems: inventorySaleOrderItemsToSave };
            }
            break;
        case actions.SET_SELECTED_SALE_ORDER_ITEMS:
            state = { ...state, selectedInventorySaleOrderItems: undefined };
            break;

        case actions.UPDATE_SELECTED_SALE_ORDER_ITEMS:
            state = { ...state, selectedInventorySaleOrderItems: action.payload.inventorySaleOrderItems };
            break;

        case actions.UPDATE_DELETE_INVENTORY_SALE_ORDER_ITEM:
            var inventorySaleOrderItems = state.inventorySaleOrderItems.filter(
                (inventorySaleOrderItems) => inventorySaleOrderItems.id !== action.payload.inventorySaleOrderItems.id
            );
            state = { ...state, inventorySaleOrderItems: inventorySaleOrderItems };
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
};

export default InventorySaleOrderItem;
