
export const updateParseObjects = (oldParseObjects, newParseObjects, deleteVariable, isPush = false) => {
    if (!oldParseObjects) {
        return newParseObjects
    }
    let updatedParseOjects = [...oldParseObjects]
    if (updatedParseOjects.length > 0) {
        for (const latest in newParseObjects) {
            var temp = updatedParseOjects.filter(
                (po) =>
                    po.id === newParseObjects[latest].id
            );


            if (temp.length > 0) {
                if (newParseObjects[latest].attributes[deleteVariable]) {

                    var others = updatedParseOjects.filter(
                        (po) =>
                            po.id !== newParseObjects[latest].id
                    );

                    updatedParseOjects = others;
                } else {
                    var index = updatedParseOjects.lastIndexOf(temp[0]);
                    updatedParseOjects[index] = newParseObjects[latest];
                }

            } else {
                if (!newParseObjects[latest].attributes[deleteVariable]) {
                    if (isPush) {
                        updatedParseOjects.push(newParseObjects[latest]);
                    } else {
                        updatedParseOjects.unshift(newParseObjects[latest]);
                    }
                }

            }
        }
        return updatedParseOjects
    } else {

        if (newParseObjects) {
            var others = newParseObjects.filter(
                (po) =>
                    po.attributes[deleteVariable] !== true
            );
            return others;
        }
        return []
    }
}
