export const GET_COURSE_SUBSCRIPTIONS = "GET_COURSE_SUBSCRIPTIONS";
export const UPDATE_COURSE_SUBSCRIPTIONS = "UPDATE_COURSE_SUBSCRIPTIONS";

export const ADD_SUBSCRIPTION_TO_COURSE = "ADD_SUBSCRIPTION_TO_COURSE";
export const REMOVE_SUBSCRIPTION_FROM_COURSE = "REMOVE_SUBSCRIPTION_FROM_COURSE";

export const UPDATE_COURSE_SUBSCRIPTION_INITIAL_LOADING = "UPDATE_COURSE_SUBSCRIPTION_INITIAL_LOADING"
export const UPDATE_COURSE_SUBSCRIPTION_ADD_STATE = "UPDATE_COURSE_SUBSCRIPTION_ADD_STATE";


export const GET_COURSE_SUBSCRIPTIONS_FOR_USER_ID = "GET_COURSE_SUBSCRIPTIONS_FOR_USER_ID";
export const UPDATE_COURSE_SUBSCRIPTIONS_FOR_USER_ID = "UPDATE_COURSE_SUBSCRIPTIONS_FOR_USER_ID";

export const GET_SUBSCRIPTION_FOR_ID = "GET_SUBSCRIPTION_FOR_ID";
export const UPDATE_SUBSCRIPTION_FOR_ID = "UPDATE_SUBSCRIPTION_FOR_ID"
