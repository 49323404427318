import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, Card, Form, CardBody, Button, Modal, ModalHeader, ModalBody } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import Parse from 'parse';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import classnames from 'classnames';
import SweetAlert from "react-bootstrap-sweetalert";
import Image from "../../assets/images/getStartedImages/groupInitial.svg";

import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";


const AddAddress = (props) => {

    const [showAddressModel, setShowAddressModel] = useState(false);
    const [displayList, setDisplayList] = useState([]);

    // const [name, setName] = useState(undefined);
    // const [address, setAddress] = useState(undefined);
    // const [phone, setPhone] = useState(undefined);
    // const [email, setEmail] = useState(undefined);
    // const [city, setCity] = useState(undefined);
    // const [state, setState] = useState(undefined);
    // const [country, setCountry] = useState("India");
    // const [pincode, setPincode] = useState(undefined);
    // const [panNumber, setPanNumber] = useState(undefined);
    // const [gstNumber, setGstNumber] = useState(undefined);
    // const [cinNumber, setCinNumber] = useState(undefined);

    const [selectedAddress, setSelectedAddress] = useState(undefined);

    function onCreateReceiptAddress(v) {

        let ReceiptAddress = Parse.Object.extend("ReceiptAddress");
        let receiptAddress = new ReceiptAddress();
        if (selectedAddress) {
            receiptAddress = selectedAddress;
        }
        
       
        receiptAddress.set("name", v.name);
        receiptAddress.set("address", v.address);
        receiptAddress.set("phoneNumber", v.schoolphone);
        receiptAddress.set("email", v.schoolemail);
        receiptAddress.set("city", v.city);
        // receiptAddress.set("state", state.trim());
        // receiptAddress.set("country", country.trim());
        receiptAddress.set("pincode", v.pincode);
        receiptAddress.set("PAN", v.pannumber);
        receiptAddress.set("GSTIN", v.gstnumber);
        //receiptAddress.set("CIN", cinNumber);


        receiptAddress.save().then((res) => {
            console.log(res);
            setSelectedAddress(undefined);
            setShowAddressModel(false)
        })

    }

    // const match = matchPath(props.history.location.pathname, {
    //     path: "/addAddress/:id",
    //     exact: true,
    //     strict: false,
    // });

    // const schoolId = match.params.id;
    
    return (

        <React.Fragment>
            <div className="page-content">
                <Container fluid>
                    <Row>
                        <Col lg={8} md={8} xs={12}>
                            <h4>Address</h4>

                        </Col>

                        <Col lg={4} md={4} xs={12}>
                            <Form className="mb-3  float-sm-right form-inline">

                                <div className="text-sm-right">
                                    <Button type="button" color="success" className="btn waves-effect waves-light mb-2 mr-2" onClick={() => { setShowAddressModel(true); }} data-toggle="modal"><i className="mdi mdi-plus mr-1"></i> Add Address</Button>


                                </div>
                            </Form>
                        </Col>
                    </Row>

                    <Card>


                        <CardBody>
                            {displayList.length > 0 && (
                             <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="thead-light">
                                        <tr>
                                            <th>{props.t('Name')}</th>
                                            <th style={{ width: "20px" }}>
                                                {props.t('Action')}
                                            </th>
                                        </tr>
                                    </thead>
                                        <tbody>
                                            {displayList.map((object, key) => (
                                                <tr key={key}>

                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>)}
                            {displayList.length === 0 && (
                                <p className="text-center">No Address to display !!</p>
                            )}

                        </CardBody >
                    </Card >

                </Container >

                <Modal

                    size="xl"
                    isOpen={showAddressModel}
                    toggle={() => {
                        setShowAddressModel(false);
                    }} className="lg">
                    <ModalHeader toggle={() => {
                        setShowAddressModel(false);
                    }} tag="h4">
                        {selectedAddress ? "Edit Address" : "Add Address"}
                    </ModalHeader>
                    <ModalBody>
                        <AvForm
                            onValidSubmit={(e, v) => {
                                console.log(v)
                                onCreateReceiptAddress(v)
                            }}
                        >
                            <Row form>

                                <Col className="col-12">

                                    <AvField name="name"
                                        label={props.t('Name')}
                                        type="text"
                                        placeholder={props.t('Name')}
                                        value={selectedAddress ? selectedAddress.attributes.name : ""}
                                        errorMessage={props.t('City can not be empty')}
                                        validate={{
                                            required: { value: true }
                                        }}
                                    // onChange={(e) => {
                                    //     setName(e.target.value)
                                    // }}
                                    />

                                </Col>

                                <Col className="col-12">
                                    <AvField
                                        name="address"
                                        label={props.t('Address')}
                                        type="textarea"
                                        className="form-control"
                                        placeholder={props.t('Address')}
                                        value={selectedAddress ? selectedAddress.attributes.address : ""}
                                        errorMessage={props.t('Address can not be empty')}
                                        validate={{
                                            required: { value: true }
                                        }}

                                        rows="5"
                                    //onChange={(e) => {
                                    //  setAddress(e.target.value)
                                    // }}

                                    />
                                </Col>

                                <Col className="col-6">
                                    <AvField
                                        name="schoolphone"
                                        label={props.t('Phone Number')}
                                        type="text"
                                        className="form-control"
                                        placeholder={props.t(' Phone Number')}
                                        value={selectedAddress ? selectedAddress.attributes.phoneNumber : ""}
                                        errorMessage={props.t(' Phone Number can not be empty')}
                                        validate={{
                                            required: { value: true }
                                        }}



                                    />
                                </Col>

                                <Col className="col-6">
                                    <AvField
                                        name="schoolemail"
                                        label={props.t('Email')}
                                        type="text"
                                        className="form-control"
                                        placeholder={props.t('Email')}
                                        value={selectedAddress ? selectedAddress.attributes.email : ""}
                                        errorMessage={props.t('Email can not be empty')}
                                        validate={{
                                            required: { value: true }
                                        }}


                                    />
                                </Col>

                                <Col className="col-6">
                                    <AvField
                                        name="city"
                                        label={props.t('City')}
                                        type="text"
                                        className="form-control"
                                        placeholder={props.t('City')}
                                        value={selectedAddress ? selectedAddress.attributes.city : ""}
                                        errorMessage={props.t('City can not be empty')}
                                        validate={{
                                            required: { value: true }
                                        }}


                                    />
                                </Col>





                                <Col className="col-6">
                                    <AvField
                                        name="pincode"
                                        label={props.t('Pin Code')}
                                        type="text"
                                        className="form-control"
                                        placeholder={props.t('Pin Code')}
                                        value={selectedAddress ? selectedAddress.attributes.pincode : ""}
                                        errorMessage={props.t('Pin Code can not be empty')}
                                        validate={{
                                            required: { value: true }
                                        }}


                                    />
                                </Col>

                                <Col className="col-6">
                                    <AvField
                                        name="pannumber"
                                        label={props.t('Pan Number')}
                                        type="text"
                                        className="form-control"
                                        placeholder={props.t('Pan Number')}
                                        value={selectedAddress ? selectedAddress.attributes.PAN : ""}



                                    />
                                </Col>

                                <Col className="col-6">
                                    <AvField
                                        name="gstnumber"
                                        label={props.t('GST Number')}
                                        type="text"
                                        className="form-control"
                                        placeholder={props.t('GST Number')}
                                        value={selectedAddress ? selectedAddress.attributes.GSTIN : ""}



                                    />
                                </Col>

                            </Row>
                            <Row>
                                <Col>
                                    <div className="text-right">
                                        <button type="button" className="btn btn-light mr-2" onClick={() => {
                                            setShowAddressModel(false);

                                        }}>{props.t('Close')}</button>
                                        <button type="submit" className="btn btn-success save-event">{props.t('Save')}</button>
                                    </div>
                                </Col>
                            </Row>
                        </AvForm>
                    </ModalBody>
                </Modal>

            </div >
        </React.Fragment >

    );

}

const mapStatetoProps = state => {

}

export default withNamespaces()(AddAddress);
