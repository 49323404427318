import React, { useEffect, useState } from "react";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { matchPath } from "react-router";
import { setSelecetdSchool,getFranchise } from "../../../store/actions";
import {
  Col,
  Nav,
  NavItem,
  Row,
  NavLink,
  Card,
  CardBody,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import FeeMultiSchoolReport from '../FeeReports/FeeMultiSchoolReport'
import EnquiryMultiSchoolReport from '../EnquiryReoprts/EnquiryMultiSchoolReport'
import AttendanceReportDropdown from '../Attendance/AttendanceReportDropdown'
import OtherReportDropdown from './OtherReportDropdown'
import * as Constants from "../../../Constents";
import Parse from "parse";
// import FeeReport from "../feeReport";


const ReportList = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/franchise-reports/",
    exact: true,
    strict: false,
  });
  var schoolId = ""

  const [showCorporatePartners,setShowCorporatePartners]=useState(false)
  useEffect(() => {
        if (Parse.User.current()) {
            if (Parse.User.current().attributes.franchiseId && Parse.User.current().attributes.franchiseId.length > 0) {
                props.getFranchise(Parse.User.current().attributes.franchiseId,true);
            }else {
                props.history.push("/pages-404");
            }
        } else {
            props.history.push("/pages-404");
        }
        
    }, [Parse.User.current()]);

  useEffect(() => {

    if (props.franchise && props.franchise.attributes.features) {
     
      if (props.franchise.attributes.features.length > Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX&&
        props.franchise.attributes.features[Constants.FRANCHISE_CORPORATE_PARTNERS_INDEX] > 0) {
        setShowCorporatePartners(true)
      }
      else if (showCorporatePartners) {
        setShowCorporatePartners(false)
      }
    }
  }, [props.franchise]);

  const [activeTab, setactiveTab] = useState("1");
  return (
    <React.Fragment>
      <div className="page-content">
        <Row>
          <Col lg={8} md={8} xs={12}>
            <h4>{props.t("Reports")}</h4>
          </Col>
        </Row>
        <Row className="checkout-tabs">
          <Col lg="2">
            <Nav className="flex-column" pills>
             
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "1" })}
                  onClick={() => {
                    setactiveTab("1");
                  }}
                >
                  <i className="bx bx-clipboard d-block check-nav-icon mt-4 mb-2"></i>
                  <p className="font-weight-bold mb-4">{props.t("Enquiry")}</p>
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "2" })}
                  onClick={() => {
                    setactiveTab("2");
                  }}
                >
                  <i className="bx bx-calculator d-block check-nav-icon mt-4 mb-2"></i>
                  <p className="font-weight-bold mb-4">{props.t("Fee")}</p>
                </NavLink>
              </NavItem>

               <NavItem>
                {showCorporatePartners && <NavLink
                  className={classnames({ active: activeTab === "3" })}
                  onClick={() => {
                    setactiveTab("3");
                  }}
                >
                  <i className="bx bxs-calendar-week d-block check-nav-icon mt-4 mb-2"></i>
                  <p className="font-weight-bold mb-4">
                    {props.t("Attendance")}
                  </p>
                </NavLink>}
              </NavItem>

              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === "4" })}
                  onClick={() => {
                    setactiveTab("4");
                  }}
                >
                  <i className="bx bx-calculator d-block check-nav-icon mt-4 mb-2"></i>
                  <p className="font-weight-bold mb-4">{props.t("Other Reports")}</p>
                </NavLink>
              </NavItem>

            </Nav>
          </Col>
          <Col lg="10">
            <Card>
              <CardBody>
                <TabContent activeTab={activeTab}>

                  <TabPane
                    tabId="1"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <EnquiryMultiSchoolReport isFranchise={ true}/>
                  </TabPane>
                  <TabPane
                    tabId="2"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    {/* <FeeReport schoolId={schoolId} /> */}
                    {/* {"Fee Reports Coming Soon !!"} */}
                    <FeeMultiSchoolReport isFranchise={true} showCorporatePartners={showCorporatePartners} />
                  </TabPane>

                 <TabPane
                    tabId="3"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <AttendanceReportDropdown isFranchise={true} showCorporatePartners={showCorporatePartners} />
                  </TabPane>

                  <TabPane
                    tabId="4"
                    id="v-pills-payment"
                    role="tabpanel"
                    aria-labelledby="v-pills-payment-tab"
                  >
                    <OtherReportDropdown isFranchise={true} showCorporatePartners={ showCorporatePartners} />
                  </TabPane>

                </TabContent>
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { franchise } = state.Franchise;
  return { selectedSchool,franchise };
};
export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,getFranchise
    })(ReportList)
  )
);
