import React, { useState, useEffect } from "react";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Button,
    CardTitle,
    Container,
    TabContent,
    TabPane,
    NavItem,
    NavLink,
    Modal,
    ModalBody,
    ModalHeader,
    ModalFooter,
    Progress,
    CustomInput,
    UncontrolledTooltip,
    Table
} from "reactstrap";
import * as WhiteLable from "../../../components/Common/WhiteLable";
import SweetAlert from "react-bootstrap-sweetalert";
import Moment from "moment";
import * as Scroll from "react-scroll";
import toastr from "toastr";
import {
    Element,
    Events,
    animateScroll as scroll,
    scrollSpy,
    scroller,
} from "react-scroll";
import classnames from "classnames";
import UpdatePhoto from "../../../components/Common/UpdatePhoto";
import { AvForm, AvField, AvInput } from "availity-reactstrap-validation";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";
import * as Constants from "../../../Constents";
import {
    getFranchise,
    sendAdmissionLinkToParent,
    getParentsForFamilyIds,
    getCourseSubscriptionForUserId,
    getCoursesForOwnerIds,
    getAllCourseRequestsForKidId,
    addSubscriptionsToCourse
} from "../../../store/actions";

const ShopCartWishListComp = (props) => {

    const [isLoading, setIsLoading] = useState(true);
    const [ownerIds, setOwnerIds] = useState([]);
    const [wishList, setWishList] = useState([])
    const [cartList, setCartList] = useState([]);
    const [courseList, setCourseList] = useState([]);


    useEffect(() => {

        var ids = [];
        if (props.selectedSchool) {
            ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }


            if (ownerIds.length === ids.length) {

                var call = false;
                for (const i in ownerIds) {
                    if (ownerIds[i] !== ids[i]) {
                        call = true;
                    }
                }
                if (call) {
                    props.getCoursesForOwnerIds(ids);
                }

            } else {
                setOwnerIds(ids);
                props.getCoursesForOwnerIds(ids);
            }
        }

    }, [props.selectedSchool]);

    useEffect(() => {
        if (props.courses && props.courses.length > 0 && props.kid) {
            setCourseList(props.courses)
            getAllShoppingCourses(props.kid.id)
        }
    }, [props.kid, props.courses])

    const getAllShoppingCourses = (kidId) => {
        if (Parse.User.current() != null) {

            var ShoppingCart = Parse.Object.extend("ShoppingCart");
            var query = new Parse.Query(ShoppingCart);
            query.notEqualTo("isDeleted", true);
            query.equalTo("studentId", kidId)
            query.find().then(
                (results) => {

                    if (results.length > 0) {

                        let tempWishList = []
                        let tempCartList = []

                        for (const i in results) {

                            let courses = courseList.filter((course) => {
                                return course.id === results[i].attributes.productId
                            }
                            )
                            if (courses.length > 0) {

                                let tempObject = {}
                                if (courses[0].attributes.introImage != null) {
                                    tempObject.image = courses[0].attributes.introImage
                                }
                                tempObject.title = courses[0].attributes.name ?? ""
                                tempObject.createdAt = courses[0].createdAt

                                if (results[i].attributes.isSavedForWishList) {
                                    tempWishList.push(tempObject)
                                } else {
                                    tempCartList.push(tempObject)
                                }
                            }
                        }
                        setCartList(tempCartList)
                        setWishList(tempWishList)
                        setIsLoading(false)

                    } else {
                        setIsLoading(false)

                    }
                },
                (error) => {
                    setIsLoading(false)
                }
            );
        } else {
            setIsLoading(false)
        }
    }



    const getDateInDDMMYYYY = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };


    //// Utilities functions
    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }



    //SchoolId is parameter
    return (
        <React.Fragment>
            <Container>
                {!isLoading && (
                    <> <Card>
                        <CardBody>
                            <Col>
                                <Row>
                                    <Container >
                                        <h5 className="mt-3 ml-1">Added To Cart</h5>
                                        {!cartList || cartList.length === 0 &&
                                            <h6 style={{ fontSize: "14px" }} className="p-2">No Courses to show</h6>
                                        }
                                        {cartList && cartList.length > 0 && <Row>
                                            <Col>
                                                <div className="table-responsive mt-1 bg-white shadow">
                                                    <Table className="table-center">
                                                        <thead>
                                                            <tr>
                                                                <th className="" >
                                                                    Courses
                                                                </th>
                                                                <th
                                                                    className="text-center"
                                                                    style={{ width: "400px" }}
                                                                >
                                                                    Added To Cart On
                                                                </th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {cartList.map((item, key) => (
                                                                <tr key={key}>
                                                                    <td>
                                                                        <div className="d-flex align-items-center">
                                                                            <img
                                                                                src={item.image}
                                                                                className="img-fluid avatar avatar-small rounded shadow"
                                                                                style={{ height: "80px", width: "80px" }}
                                                                                alt=""
                                                                            />
                                                                            <section>
                                                                                <h6 className="mb-0 ml-3">
                                                                                    <Label
                                                                                        className="text-primary"
                                                                                    >
                                                                                        {item.title}
                                                                                    </Label>
                                                                                </h6>
                                                                            </section>
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div className="mt-4 text-center"><h6>{getDateInDDMMYYYY(item.createdAt)}</h6></div>
                                                                    </td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </Table>
                                                </div>
                                            </Col>
                                        </Row>}

                                    </Container >
                                </Row>
                            </Col>
                        </CardBody>
                    </Card>

                        <Card>
                            <CardBody>
                                <Col>
                                    <Row>
                                        <Container >
                                            <h5 className="mt-3 ml-1">Wishlisted Courses</h5>
                                            {!wishList || wishList.length === 0 &&
                                                <h6 style={{ fontSize: "14px" }} className="p-2">No Courses to show</h6>
                                            }
                                            {wishList && wishList.length > 0 && <Row>
                                                <Col>
                                                    <div className="table-responsive mt-1 bg-white shadow">
                                                        <Table className="table-center">
                                                            <thead>
                                                                <tr>
                                                                    <th className="" >
                                                                        Courses
                                                                    </th>
                                                                    <th
                                                                        className="text-center"
                                                                        style={{ width: "400px" }}

                                                                    >
                                                                        WishListed On
                                                                    </th>
                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {wishList.map((item, key) => (
                                                                    <tr key={key}>
                                                                        <td>
                                                                            <div className="d-flex align-items-center">
                                                                                <img
                                                                                    src={item.image}
                                                                                    className="img-fluid avatar avatar-small rounded shadow"
                                                                                    style={{ height: "80px", width: "80px" }}
                                                                                    alt=""
                                                                                />
                                                                                <section>
                                                                                    <h6 className="mb-0 ml-3">
                                                                                        <Label
                                                                                            className="text-primary"
                                                                                        >
                                                                                            {item.title}
                                                                                        </Label>
                                                                                    </h6>
                                                                                </section>
                                                                            </div>
                                                                        </td>
                                                                        <td>
                                                                            <div className="mt-4 text-center"><h6>{getDateInDDMMYYYY(item.createdAt)}</h6></div>
                                                                        </td>
                                                                    </tr>
                                                                ))}
                                                            </tbody>
                                                        </Table>
                                                    </div>
                                                </Col>
                                            </Row>}

                                        </Container >
                                    </Row>
                                </Col>

                            </CardBody>
                        </Card></>
                )}

                {isLoading && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Link to="#" className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    {"Loading Shop Cart and & List..."}{" "}
                                </Link>
                            </div>
                        </Col>
                    </Row>
                )}
            </Container>


        </React.Fragment>
    );
};


const mapStatetoProps = (state) => {
    const { courseSubscriptionsForUser } = state.CourseSubscription;
    const { selectedSchool } = state.School;
    const { franchise } = state.Franchise;
    const { parents } = state.Parent;
    const { courses } = state.Course;
    const { courseRequests, initialLoding } = state.CourseRequests;


    return {
        selectedSchool,
        franchise,
        parents,
        courseSubscriptionsForUser,
        courses,
        courseRequests,
        initialLoding
    };
};
export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getFranchise,
            sendAdmissionLinkToParent,
            getParentsForFamilyIds,
            getCourseSubscriptionForUserId,
            getCoursesForOwnerIds,
            getAllCourseRequestsForKidId,
            addSubscriptionsToCourse
        })(ShopCartWishListComp)
    )
);
