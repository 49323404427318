import { all } from "redux-saga/effects";

//public
import AccountSaga from "./auth/register/saga";
import AuthSaga from "./auth/login/saga";
import ForgetSaga from "./auth/forgetpwd/saga";
import ProfileSaga from "./auth/profile/saga";
import LayoutSaga from "./layout/saga";
import SchoolSaga from "./model/school/saga";
import TeacherSaga from "./model/teacher/saga";
import ParentSaga from "./model/parent/saga";
import KidSaga from "./model/kid/saga";
import ClassroomSaga from "./model/classroom/saga";
import EnquirySaga from "./model/enquiry/saga";
import EnquiryNoteSaga from "./model/enquirynotes/saga";
import EnquiryFollowupSaga from "./model/enquiryFollowup/saga";
import EnquiryReminderSaga from "./model/enquiryreminder/saga";
import ChildAdditionalDetailSaga from "./model/childAdditionalDetails/saga";
import EnquiryQuestionsSaga from "./model/enquiryQuestions/saga";
import GroupSaga from "./model/group/saga";
import GroupMapSaga from "./model/groupMap/saga";
import ProgramSaga from "./model/program/saga";
import ReminderSaga from "./model/reminder/saga";
import AdmissionTitleSaga from "./model/admissionTitle/saga";
import AdmissionQuestionSaga from "./model/admissionQuestion/saga";
import InvoiceItemsSaga from "./model/invoiceItems/saga";
import CameraSaga from "./model/camera/saga";
import MoneyTransactionsSaga from "./model/moneyTransactions/saga";
import FeeTemplateSaga from "./model/feeTemplate/saga";
import InvoiceSaga from "./model/invoice/saga";
import FeeTemplateItemSaga from "./model/feeTemplateItem/saga";
import MoneyTransactionDetails from "./model/moneyTransactionDetails/saga";
import LearningCategorySaga from "./model/learningCategory/saga";
import LearningActivitySaga from "./model/learningActivity/saga";
import LearningFiltersSaga from "./model/learningFilters/saga";
import CurriculumSaga from "./model/curriculum/saga";
import AcademicCyclesSaga from "./model/academicCycles/saga";
import LearningSubcategorySaga from "./model/learningSubcategory/saga";
import FilesSaga from "./model/files/saga";
import Notifaction from "./model/notification/saga";
import MessageCredits from "./model/messageCredits/saga";
import Franchise from "./model/franchise/saga";
import Attendance from "./model/attendance/saga";
import Exam from "./model/exam/saga";
import EnquiryAnalytics from "./model/enquiryAnalytics/saga";
import CourseSaga from "./model/course/saga";
import CourseSubscriptionSaga from "./model/courseSubscription/saga";
import CourseRequestSaga from "./model/courseRequests/saga";
import Expenses from "./model/expense/saga";
import ExpenseCategory from "./model/expenseCategory/saga";
import ExpenseSupplier from "./model/expenseSupplier/saga";
import QuizSessionSaga from "./model/quizSession/saga";
import CourseBatchSaga from "./model/courseBatch/saga";
import QuizActivityBatchSaga from "./model/quizActivityBatchMap/saga"
import LiveMeetingSaga from "./model/liveMeeting/saga"
import Trips from "./model/trip/saga"
import Message from "./model/messaging/saga"
import CorporatePartnerSaga from "./model/corporatePartners/saga"
import InventoryVendor from "./model/inventory/InventoryVendor/saga"
import InventoryItems from "./model/inventory/InventoryItems/saga";
import InventoryPurchaseOrder from "./model/inventory/InventoryPurchaseOrder/saga";
import InventoryTags from "./model/inventory/InventoryTags/saga";
import InventorySaleOrder from "./model/inventory/InventorySaleOrder/saga";
import InventorySaleOrderItem from "./model/inventory/InventorySaleOrderItems/saga";
import InventoryPurchaseOrderItem from "./model/inventory/InventoryPurchaseOrderItems/saga";
import Ticket from "./model/tickets/saga";
import InventoryWarehouses from "./model/inventory/InventoryWarehouse/saga";
import InventoryKits from "./model/inventory/InventoryKits/saga";
import InventoryKitItems from "./model/inventory/InventoryKitItemMap/saga";
import LearningCurriculumsSchoolMap from "./model/learningCurriculumSchoolMap/saga";
import LearningSession from "./model/learningSession/saga";
import Examination from "./model/marksCard/examination/saga"
import Subjects from "./model/marksCard/subjects/saga";
import ExamResults from "./model/marksCard/result/saga";

export default function* rootSaga() {
  yield all([
    //public
    AccountSaga(),
    AuthSaga(),
    ProfileSaga(),
    ForgetSaga(),
    LayoutSaga(),
    SchoolSaga(),
    TeacherSaga(),
    ParentSaga(),
    KidSaga(),
    ClassroomSaga(),
    EnquirySaga(),
    EnquiryNoteSaga(),
    EnquiryFollowupSaga(),
    EnquiryReminderSaga(),
    ChildAdditionalDetailSaga(),
    EnquiryQuestionsSaga(),
    GroupSaga(),
    GroupMapSaga(),
    ProgramSaga(),
    ReminderSaga(),
    AdmissionTitleSaga(),
    AdmissionQuestionSaga(),
    InvoiceItemsSaga(),
    CameraSaga(),
    MoneyTransactionsSaga(),
    FeeTemplateSaga(),
    InvoiceSaga(),
    FeeTemplateItemSaga(),
    MoneyTransactionDetails(),
    LearningCategorySaga(),
    LearningActivitySaga(),
    LearningFiltersSaga(),
    CurriculumSaga(),
    AcademicCyclesSaga(),
    LearningSubcategorySaga(),
    FilesSaga(),
    Notifaction(),
    MessageCredits(),
    Franchise(),
    Attendance(),
    Exam(),
    EnquiryAnalytics(),
    CourseSaga(),
    CourseSubscriptionSaga(),
    CourseRequestSaga(),
    Expenses(),
    ExpenseCategory(),
    ExpenseSupplier(),
    QuizSessionSaga(),
    CourseBatchSaga(),
    QuizActivityBatchSaga(),
    LiveMeetingSaga(),
    Trips(),
    Message(),
    CorporatePartnerSaga(),
    InventoryVendor(),
    InventoryItems(),
    InventoryPurchaseOrder(),
    InventoryTags(),
    InventorySaleOrder(),
    InventorySaleOrderItem(),
    InventoryPurchaseOrderItem(),
    Ticket(),
    InventoryWarehouses(),
    InventoryKits(),
    InventoryKitItems(),
    LearningCurriculumsSchoolMap(),
    LearningSession(),
    Examination(),
    Subjects(),
    ExamResults(),
  ]);
}
