import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  CardBody,
  Badge,
} from "reactstrap";
import { Parse } from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { matchPath } from "react-router";
import moment from "moment";
import Switch from "react-switch";

import {
  getCameraForId,
  getAllKidsForGroup,
  getCameraAccessObjectsForCameraId,
  getParentsForFamilyIds,
  saveCamera,
  resetResultCameraAfterSave,
  deleteCamera,
  clearPropsCameraAndAccess,
  setSelecetdSchool,
} from "../../store/actions";

const CameraView = (props) => {
  const matchCameraView = matchPath(props.history.location.pathname, {
    path: "/cameraview/:schoolId/:cameraId",
    exact: true,
    strict: false,
  });
  const schoolId = matchCameraView && matchCameraView.params.schoolId;
  const cameraId = matchCameraView && matchCameraView.params.cameraId;

  const [showDeleteAlert, setShowDeleteAlert] = useState(false);

  const [isLoading, setIsLoading] = useState(true);
  const [kidDetailsList, setKidDetailsList] = useState([]);
  const [canHideIsLoading, setCanHideIsLoading] = useState(false)
  const [newCameraName, setNewCameraName] = useState("");
  const [timeRangeArray, setTimeRangeArray] = useState([]);
  const [selectedKids, setSelectedKids] = useState([]);
  const [deleteClicked, setDeleteClicked] = useState(false);
  const [selectedDaysArray, setSelectedDaysArray] = useState([]);
  const [showCamDisableAlert, setShowCamDisableAlert] = useState(false);

  useEffect(() => {
    props.resetResultCameraAfterSave(null);
    props.getAllKidsForGroup(schoolId);

    if (matchCameraView && schoolId && cameraId) {
      props.getCameraForId(cameraId);
      props.getCameraAccessObjectsForCameraId(cameraId);
    }
  }, [schoolId, cameraId]);

  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
  }, [props.selectedSchool]);

  useEffect(() => {
    if (props.camera && props.kids) {

      if (props.camera.id === cameraId) {
        let kidIds = []
        if (props.camera.attributes.kids && props.camera.attributes.kids.length > 0) {
          kidIds = props.camera.attributes.kids;
          setCanHideIsLoading(false);
        } else {
          setCanHideIsLoading(true);
        }
        setNewCameraName(props.camera.attributes.name);
        let familyIds = [];
        var tempSelectedKidList = [];

        for (let i = 0; i < kidIds.length; i++) {
          for (let k = 0; k < props.kids.length; k++) {
            let tempObject = props.kids[k];
            if (tempObject.id === kidIds[i]) {
              tempSelectedKidList.push(tempObject);
            }
          }
        }
        setSelectedKids(tempSelectedKidList);
        for (let j = 0; j < tempSelectedKidList.length; j++) {
          familyIds.push(tempSelectedKidList[j].attributes.FamilyID);
        }
        if (familyIds.length > 0) {
          props.getParentsForFamilyIds(familyIds);
        } else {
          setIsLoading(false);
        }
      }

    }
  }, [props.camera, props.kids]);

  useEffect(() => {
    console.log(props.camera)
    if (
      props.camera && props.cameraAccessObjects
    ) {

      if (props.camera.id === cameraId) {
        let tempList = []
        for (var i = 0; i < props.cameraAccessObjects.length; i++) {
          let cameraAccessAttributes = props.cameraAccessObjects[i].attributes;
          let startTime = moment(cameraAccessAttributes.startTime).format(
            "h:mm a"
          );
          let endTime = moment(cameraAccessAttributes.endTime).format("h:mm a");
          let timeRangeString = `${startTime} - ${endTime}`;
          tempList.push(timeRangeString);
        }
        setTimeRangeArray(tempList);
      }

    }
  }, [props.camera, props.cameraAccessObjects]);

  useEffect(() => {


    let kidDetailList = [];

    for (var j = 0; j < selectedKids.length; j++) {
      let kidDetailObject = {};
      kidDetailObject = {
        kidName: selectedKids[j].attributes.Name,
      };

      for (var i = 0; i < props.parents.length; i++) {
        if (
          props.parents[i].attributes.FamilyID ===
          selectedKids[j].attributes.FamilyID
        ) {


          if (props.parents[i].attributes.Relation &&
            props.parents[i].attributes.Relation.toLowerCase() ===
            "father"
          ) {
            kidDetailObject.fatherName =
              props.parents[i].attributes.Name;
            kidDetailObject.fatherMobile =
              props.parents[i].attributes.PhoneNumber;
          } else {
            kidDetailObject.motherName =
              props.parents[i].attributes.Name;
            kidDetailObject.motherMobile =
              props.parents[i].attributes.PhoneNumber;
          }
        }
      }
      kidDetailList.push(kidDetailObject);
    }
    if (isLoading && (canHideIsLoading || kidDetailList.length > 0)) {
      setIsLoading(false);
    }
    setKidDetailsList(kidDetailList);
  }, [props.parents, canHideIsLoading]);

  useEffect(() => {
    if (cameraId) {
      props.clearPropsCameraAndAccess()
    }
  }, [cameraId])

  useEffect(() => {
    if (!props.resultCameraAfterSave && deleteClicked) {
      window.history.back();
    }
  }, [props.resultCameraAfterSave]);

  const handleDeleteCamera = () => {
    let cameraToDelete = props.camera;
    cameraToDelete.set("deleted", true);
    if (Parse.User.current().attributes.teacherId) {
      cameraToDelete.set("deletedBy", Parse.User.current().attributes.teacherId);
    } else {
      cameraToDelete.set("deletedBy", Parse.User.current().id);
    }
    setDeleteClicked(true)
    props.deleteCamera(cameraToDelete, props.t('CCTV deleted successfully..!'), true);
    setShowDeleteAlert(false);
    setIsLoading(true);
  };

  function strReplace(stringValue) {
    var newStr = stringValue.replace(/%20/g, " ");

    return newStr;
  }

  const getDay = (dayNumber) => {
    const dayNames = {
      1: 'Sunday',
      2: 'Monday',
      3: 'Tuesday',
      4: 'Wednesday',
      5: 'Thursday',
      6: 'Friday',
      7: 'Saturday',
    };

    return dayNames[dayNumber]
  };

  const Offsymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >
      </div>
    );
  };

  const OnSymbol = () => {
    return (
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100%",
          fontSize: 12,
          color: "#fff",
          paddingRight: 2
        }}
      >

      </div>
    );
  };



  const saveCam = (isChecked, cameraObject) => {
    cameraObject.set("isDisabled", isChecked);
    props.saveCamera(cameraObject);
    setShowCamDisableAlert(false)
  }

  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading && (
          <Container fluid>
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => { window.history.back() }}>
                  <ul className=" list-unstyled">
                    <div className=''>
                      <i className="bx bx-chevron-left h1 text-primary"></i>
                    </div>
                  </ul>
                </div>
                <div className=' m-2'>
                  <h4>CCTV</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                  <Link
                    className="btn btn-warning waves-effect waves-light m-1"
                    to={
                      "/editcamera/" +
                      schoolId +
                      "/" +
                      cameraId
                    }
                    role="button"
                  >
                    Edit
                  </Link>
                  <button
                    onClick={() => {
                      setShowDeleteAlert(true);
                    }}
                    className="btn btn-danger waves-effect waves-light m-1"
                  >
                    {props.t("Delete")}
                  </button>
                </div>
              </Col>
            </Row>
            <Card>
              {showDeleteAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowDeleteAlert(false);
                  }}
                  onConfirm={() => {
                    handleDeleteCamera();
                  }}
                >
                  Are you sure to delete this Camera?<br></br>
                </SweetAlert>
              )}
              {showCamDisableAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="Cancel"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowCamDisableAlert(false)
                  }}
                  onConfirm={() => {
                    saveCam(props.camera && props.camera.attributes && props.camera.attributes.isDisabled ? false : true, props.camera)
                  }}
                >
                  {props.camera && props.camera.attributes && props.camera.attributes.isDisabled ?
                    "Enabling it will restore parents access to the camera." :
                    "Disabling it will temporarily suspend parents' access to the camera until you choose to enable it again."}
                  <br></br>
                </SweetAlert>
              )}
              <CardBody>
                <Row>
                  <Col lg={10}>
                    <div className="d-flex">
                      <h4 className="m-1">{newCameraName}</h4>
                      {timeRangeArray.map((timeRange, key) => (
                        <Badge
                          key={key}
                          className={"badge badge-success font-size-14 pt-2 m-1"}
                          color="success"
                        >
                          {timeRange}
                        </Badge>
                      ))}
                      {(props.camera &&
                        props.camera.attributes &&
                        props.camera.attributes.availableDays &&
                        props.camera.attributes.availableDays.length > 0) ?
                        props.camera.attributes.availableDays.map((day, key) => (
                          <Badge
                            key={key}
                            className={"badge badge-warning font-size-10 pt-2 m-1"}
                            color="warning"
                          >
                            {getDay(day)}
                          </Badge>
                        )) : <Badge
                          className={"badge badge-warning font-size-10 pt-2 m-1"}
                          color="warning"
                        >
                          {"Monday to Saturday"}
                        </Badge>}
                    </div>
                  </Col>
                  {false && <Col lg={2}>
                    <div className="d-flex float-end">
                      <Label className="m-1">{"Disable"}</Label>
                      <Switch
                        className="m-2"
                        uncheckedIcon={<Offsymbol />}
                        checkedIcon={<OnSymbol />}
                        height={15}
                        width={30}
                        onColor="#626ed4"
                        onChange={(e) => {
                          setShowCamDisableAlert(true)
                        }}
                        checked={props.camera && props.camera.attributes && props.camera.attributes.isDisabled}
                      />
                    </div>
                  </Col>}
                </Row>

                <div className="table-responsive mt-4">
                  <table className="table table-centered table-nowrap mb-0">
                    <thead className="thead-light">
                      <tr>
                        <th>{props.t("Sl.No")}</th>
                        <th>{props.t("Student's Name")}</th>
                        <th>{props.t("Father's Name")}</th>
                        <th>{props.t("Father's Contact")}</th>
                        <th>{props.t("Mother's Name")}</th>

                        <th>{props.t("Mother's Contact")}</th>
                      </tr>
                    </thead>
                    <tbody>
                      {kidDetailsList && kidDetailsList.map((detail, key) => (
                        <tr key={key}>
                          <td>{key+1}</td>
                          <td>
                            <h5 className="font-size-14 mb-1">
                              <Label className="text-dark">
                                {" "}
                                {detail.kidName}
                              </Label>
                            </h5>
                          </td>
                          <td>
                            <h5 className="font-size-14 mb-1">
                              <Label className="text-dark">
                                {" "}
                                {detail.fatherName}
                              </Label>
                            </h5>
                          </td>
                          <td>
                            <h5 className="font-size-14 mb-1">
                              <Label className="text-dark">
                                {" "}
                                {detail.fatherMobile}
                              </Label>
                            </h5>
                          </td>
                          <td>
                            <h5 className="font-size-14 mb-1">
                              <Label className="text-dark">
                                {" "}
                                {detail.motherName}
                              </Label>
                            </h5>
                          </td>
                          <td>
                            <h5 className="font-size-14 mb-1">
                              <Label className="text-dark">
                                {" "}
                                {detail.motherMobile}
                              </Label>
                            </h5>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  {
                    !isLoading && (!kidDetailsList || kidDetailsList.length == 0) &&
                    <Row>
                      <Col xs="12">
                        <div className="text-center my-3">
                          <Label className="text-success">
                            No children in this camera
                          </Label>
                        </div>
                      </Col>
                    </Row>
                  }
                </div>
              </CardBody>
            </Card>
          </Container>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Label className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading CCTV details...{" "}
                </Label>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { camera, resultCameraAfterSave, cameraAccessObjects } = state.Camera;
  const { kids } = state.Kid;
  const { selectedSchool } = state.School;
  const { parents } = state.Parent;
  return {
    camera,
    kids,
    cameraAccessObjects,
    resultCameraAfterSave,
    parents,
    selectedSchool
  };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      getCameraForId,
      getAllKidsForGroup,
      getCameraAccessObjectsForCameraId,
      getParentsForFamilyIds,
      saveCamera,
      resetResultCameraAfterSave,
      deleteCamera,
      clearPropsCameraAndAccess,
      setSelecetdSchool,
    })(CameraView)
  )
);
