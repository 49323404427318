import { takeEvery, fork, put, all, call } from 'redux-saga/effects';
import Parse from 'parse';
import * as EnquiryAnalyticsHelper from '../../../helpers/ParseHelpers/enquiryAnalytics_helper'
import * as EnquiryAnalyticsActions from './actions';
import * as actionsTypes from './actionTypes';
import * as Constants from '../../../Constents';
import toastr from 'toastr';

function* getEnquiryAnalyticsSaga({ payload: { dateFilter, schoolIds } }) {
    try {
        const response = yield call(EnquiryAnalyticsHelper.getEnquiryAnalyticsFromParse, dateFilter, schoolIds);
        if (response) {
            if (dateFilter.schoolId) {
                yield put(EnquiryAnalyticsActions.updateEnquiryAnalyticsForSchool(response));

            } else {
                yield put(EnquiryAnalyticsActions.updateEnquiryAnalytics(response));

            }


        }
    } catch (error) {
        // yield put(apiError(error));
    }
}

function* getEnquiryAnalyticsFor10Days({ payload: { id,type } }) {
  
    try {
      const serverResponse = yield call(
        EnquiryAnalyticsHelper.getEnquiryAnalyticsFor10Days,
        id,type,
        null,
        false
      );
  
      if (serverResponse && serverResponse.length > 0) {
        yield put(EnquiryAnalyticsActions.updateEnquiryAnalyticsFor10Days(serverResponse));
        Parse.Object.pinAll(serverResponse);
      }
    } catch (error) {
      // yield put(apiError(error));
    }
  }


export function* watchGetEnquiryAnalyticsSaga() {
    yield takeEvery(actionsTypes.GET_ENQUIRY_ANALYTICS, getEnquiryAnalyticsSaga)
}
export function* watchGetEnquiryAnalyticsFor10Days() {
    yield takeEvery(actionsTypes.GET_ENQUIRY_ANALYTICS_FOR_TEN_DAYS, getEnquiryAnalyticsFor10Days)
}

function* enquiryAnalyticsSaga() {
    yield all([
        fork(watchGetEnquiryAnalyticsSaga),
        fork(watchGetEnquiryAnalyticsFor10Days),
    ]);
}
export default enquiryAnalyticsSaga;