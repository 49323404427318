import * as actions from "./actionTypes";

export const updateProgram = (program) => {
  return {
    type: actions.UPDATE_PROGRAM,
    payload: { program },
  };
};

export const deleteProgram = (program) => {
  return {
    type: actions.DELETE_PROGRAM,
    payload: { program },
  };
};

export const updateprograms = (programs) => {
  return {
    type: actions.UPDATE_PROGRAMS,
    payload: { programs }
  }
}



export const getProgramsForSchoolId = (schoolId) => {
  return {
    type: actions.GET_PROGRAMS,
    payload: { schoolId }
  }
}

export const getProgramForGroupId = (groupId) => {
  return {
    type: actions.GET_PROGRAM_FOR_GROUPID,
    payload: { groupId }
  }
}
export const updateSelectedProgram = (program) => {
  return {
    type: actions.UPDATE_SELECTED_PROGRAM,
    payload: { program }
  }
}
export const setNoPrograms = (boolValue) => {
  return {
    type: actions.SET_NO_PROGRAMS,
    payload: { boolValue }
  }
}

export const saveSelectedProgram = (program) => {
  return {
    type: actions.SAVE_SELECTED_PROGRAM,
    payload: { program }
  }
}
export const setProgramLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue }
  }
}

export const setDeletedSuccessfullyProgram = (boolValue) => {
  return {
    type: actions.DELETED_SUCCESSFULLY_PROGRAM,
    payload: { boolValue }
  }
}

export const setForwardToProgramView = (program) => {
  return {
    type: actions.SET_FORWARD_TO_PROGRAM_VIEW,
    payload: { program }
  }
}

//************* PROGRAM TERMS *************//


export const updateProgramTermsForProgramId = (terms) => {
  return {
    type: actions.UPDATE_PROGRAM_TERMS_FOR_PROGRAM_ID,
    payload: { terms }
  }
}

export const getProgramTermsForProgramId = (programId) => {
  return {
    type: actions.GET_PROGRAM_TERMS_FOR_PROGRAM_ID,
    payload: { programId }
  }
}

export const editProgramTerms = (terms) => {
  return {
    type: actions.EDIT_PROGRAM_TERMS,
    payload: { terms }
  }
}

