import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { Container, Row, Col, Card, CardBody, NavItem, NavLink, TabContent, TabPane, Form, FormGroup, Label, Input, Progress, Button } from "reactstrap";
import classnames from 'classnames';
import { withNamespaces } from "react-i18next";
import Parse from 'parse';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import toastr from "toastr";



function SchoolTimings(props) {


    const [schoolStartTime, setSchoolStartTime] = useState(undefined);
    const [schoolEndTime, setSchoolEndTime] = useState(undefined);
    const [schoolWorkingDays, setSchoolWorkingDays] = useState([]);
    const [isSaving, setIsSaving] = useState(false);
    const allDays = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ];

    useEffect(() => {
        if (props.selectedSchool) {
            setSchoolStartTime(props.selectedSchool.attributes.startTime);
            setSchoolEndTime(props.selectedSchool.attributes.EndTime);
            setSchoolWorkingDays(props.selectedSchool.attributes.workingDays??[]);

        }
    }, [props.selectedSchool]);

    function handleSubmit() {
         
        if (props.selectedSchool != null && !isSaving) {
            setIsSaving(true)
      
        var school = props.selectedSchool;

        var workingDays = [];
        for (const i in allDays) {
            if (schoolWorkingDays && schoolWorkingDays.includes(allDays[i])) {
                workingDays.push(allDays[i]);
            }
        }

        if (workingDays.length > 0) {
            school.set("workingDays", workingDays);
        }

        if (schoolStartTime) {
            school.set("startTime", schoolStartTime);
        }
        if (schoolEndTime) {
            school.set("EndTime", schoolEndTime);
        }
        //props.handleUpdateSchoolTime(school)
        school.save().then(
            (result) => {
                setIsSaving(false);
                toastr.success("successfully updated !!");
            },
            (error) => {
              
            }
          );
      }
    
    }
    const autoSelectWeekDays = (day) => {

        if (schoolWorkingDays.includes(day)) {
            return (true)
        } else {
            return (false)
        }
    }

    return (
        <TabPane tabId={3}>

            <div>
                {/* <Form> */}
                   
                    <Row>
                        <Col lg="6">
                            <Col >
                                <FormGroup>
                                    <Label ><b>School Day Begins</b></Label>
                                    <DatePicker
                                        selected={schoolStartTime}
                                        onChange={(date) => setSchoolStartTime(date)}
                                        className="form-control bg-white"
                                        placeholderText='Select Start Time'
                                        startDate={schoolStartTime}
                                        endDate={schoolEndTime}
                                        maxDate={schoolEndTime}
                                        dateFormat="h:mm aa"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                    />
                                </FormGroup>
                            </Col>
                            <Col >
                                <FormGroup>
                                    <Label><b>School Day Ends</b></Label>
                                    <DatePicker
                                        selected={schoolEndTime}
                                        onChange={(date) => setSchoolEndTime(date)}
                                        className="form-control bg-white"
                                        placeholderText='Select End Time'
                                        startDate={schoolStartTime}
                                        endDate={schoolEndTime}
                                        minDate={schoolStartTime}
                                        dateFormat="h:mm aa"
                                        showTimeSelect
                                        showTimeSelectOnly
                                        timeIntervals={15}
                                    />
                                </FormGroup>
                            </Col>


                        </Col>


                        <Col lg="6">
                            <div className="ml-3"><h5><b>Days Of Week</b></h5>

                                <Row>  {
                                    allDays.map((day, key) => (

                                        <Col lg="4" key={key}>
                                            <div className="custom-control custom-checkbox mt-2 ml-3 " >
                                                <Input
                                                    style={{ marginRight: '8px'}}
                                                    type="checkbox"
                                                    checked={schoolWorkingDays.includes(day)}
                                                    className="custom-control-input"
                                                    id={key}
                                                    onClick={() => {
                                                        if (schoolWorkingDays.includes(day)) {
                                                            var temp = schoolWorkingDays.filter(d => d !== day);
                                                            setSchoolWorkingDays(temp);
                                                        } else {
                                                            var temp = [...schoolWorkingDays];
                                                            temp.push(day);
                                                            setSchoolWorkingDays(temp);
                                                        }
                                                    }}
                                                />
                                                <Label className="custom-control-label" htmlFor={key}>	{day}</Label>
                                            </div>
                                        </Col>
                                    ))
                                }
                                </Row>
                            </div>
                        </Col>
                    </Row>
                
                    <button
                        className="btn btn-primary"   style={{ float: "right" , cursor: "pointer" }}
                        onClick={() => {
                            handleSubmit();
                        }}
                    >
                        {isSaving && <i className="bx bx-loader bx-spin mr-2 align-middle"></i>}
                        {isSaving? "Saving..." : "Save"}    
                    </button>

                    {/* <Button color="primary" align="right"
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                            handleSubmit();
                        }
                        }>Save</Button> */}
                {/* </Form> */}
            </div>
        </TabPane>

    )
}

export default SchoolTimings
