import { takeEvery, fork, put, all, call, } from "redux-saga/effects";
import Parse from "parse";

import * as LearningFilterHelper from "../../../helpers/ParseHelpers/learningFilters_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from "../../../Constents";
import * as LearningFilterActions from "./actions";

function* getALearningFilter({ payload: { id } }) {
  try {
    const response = yield call(
      LearningFilterHelper.getLearningFilterFromParse,
      id,
      false
    );
    yield put(LearningFilterActions.updateLearningFilter(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteALearningFilter({ payload: { learningFilterId } }) {
  try {
    const response = yield call(
      LearningFilterHelper.deleteLearningFilterFromParse,
      learningFilterId
    );
    if (response) {
      yield put(LearningFilterActions.deleteLearningFilter(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getLearningFilterForcurriculumId({ payload: { curriculumId } }) {
  try {
    var key =
      Constants.LEARNING_FILTER_UPDATED_TIME_FOR_CURRICULUM_ID +
      "_" +
      curriculumId;
    var updatedTime = localStorage.getItem(key);
    var newTime = new Date();
    const localResponse = yield call(
      LearningFilterHelper.getLearningFilterForcurriculumId,
      curriculumId,
      null,
      true
    );
    yield put(LearningFilterActions.updateLearningFilters(localResponse));

    const serverResponse = yield call(
      LearningFilterHelper.getLearningFilterForcurriculumId,
      curriculumId,
      updatedTime,
      false
    );
    localStorage.setItem(key, newTime);

    if (serverResponse && serverResponse.length > 0) {
      yield put(LearningFilterActions.updateLearningFilters(serverResponse));
      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetLearningFilter() {
  yield takeEvery(actionTypes.GET_LEARNING_FILTER, getALearningFilter);
}

export function* watchDeleteLearningFilter() {
  yield takeEvery(actionTypes.DELETE_LEARNING_FILTER, deleteALearningFilter);
}

export function* watchGetLearningFilterForcurriculumId() {
  yield takeEvery(
    actionTypes.GET_LEARNING_FILTER_FOR_CURRICULUM_ID,
    getLearningFilterForcurriculumId
  );
}

function* LearningFilterSaga() {
  yield all([
    fork(watchGetLearningFilter),
    fork(watchDeleteLearningFilter),
    fork(watchGetLearningFilterForcurriculumId),
  ]);
}
export default LearningFilterSaga;
