import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Label, TabContent, TabPane, Card, UncontrolledTooltip, Form, Modal, ModalBody, ModalFooter, Collapse, ModalHeader, Button, CardText, Nav, NavItem, NavLink, Progress, Table, Input, CardBody, CardTitle, FormGroup, Badge } from "reactstrap";
import { withNamespaces } from 'react-i18next';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import Parse from 'parse';
import Moment from 'moment';
import { v4 as uuidv4 } from "uuid";
import toastr from "toastr";
import avatar2 from "../../assets/images/users/user.png";

import {
    setSelecetdSchool, getSubjectsForExamId, getExamForExamId, getClassroomsForSchoolId, getAllKids, getExamResultsForExamId,
    getGroupForId, getGroupMapsForGroupId, getTeachersForSchool, getAllDeletedKidsForSchoolIdOnlyForArchives,
    getAllKidsForGroup, saveGroup, saveGroupMaps, updateExamResults,
} from '../../store/actions';
import moment from 'moment';

const MarksCardView = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/marks-view/:schoolId/:examId/:groupId",
        exact: true,
        strict: false,
    });

    const schoolId = match && match.params.schoolId;
    const examId = match && match.params.examId;
    const groupId = match && match.params.groupId;

    const [showStudentSelectionModal, setShowStudentSelectionModal] = useState(false);
    const [selectedKidMarks, setSelectedKidMarks] = useState()

    const getSubjectObject = () => {
        return {
            subjectId: uuidv4(),
            subjectName: undefined,
            examStartDate: undefined,
            theory: undefined,
            passing: undefined,
            totalMarks: undefined,

            practicalData: [{
                practicalId: uuidv4(),
                practicalName: undefined,
                practicalStartDate: undefined,
                practicalMarks: undefined,
                passingMarks: undefined,
                totalMarks: undefined,
            }
            ]
        }
    }

    const [tableData, setTableData] = useState([getSubjectObject()]);
    const [kidSubjectList, setKidSubjectList] = useState([])


    const [selectedKids, setSelectedKids] = useState([])
    const [classroomList, setClassroomList] = useState([])
    const [selectedClassroomFilter, setSelectedClassroomFilter] = useState(undefined)
    const [searchBarString, setSeachBarString] = useState("")

    const [isAllSaveLoading, setIsAllSaveLoading] = useState(false)
    const [isSingleSaveLoading, setIsSingleSaveLoading] = useState(false)
    const [initialSelctedKids, setInitialSelctedKids] = useState([])


    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (schoolId) {
            props.getClassroomsForSchoolId(schoolId);
            props.getAllKids(schoolId);
            props.getTeachersForSchool(schoolId);
            props.getAllKidsForGroup(schoolId);
            props.getAllDeletedKidsForSchoolIdOnlyForArchives(schoolId);
        }
    }, [schoolId])

    useEffect(() => {
        if (examId) {
            props.getSubjectsForExamId(examId);
            props.getExamForExamId(examId);
            props.getExamResultsForExamId(examId)
        }
    }, [examId]);

    useEffect(() => {
        if (groupId) {
            props.getGroupForId(groupId);
            props.getGroupMapsForGroupId(groupId);
        }
    }, [groupId])


    useEffect(() => {
        if (selectedKids && selectedKids.length > 0) {
            let mainList = [];

            for (const kid of selectedKids) {
                let kidTableDate = [...tableData];
                let kidSubjects = [];
                let totalMarks = 0;
                let totalMaxMarks = 0;
                let totalPracticalMarks = 0;
                let totalPracticalMaxMarks = 0;
                let totalPnTMarks = 0;

                let allSubjectsPassed = true;

                for (const i in kidTableDate) {
                    let subject = { ...kidTableDate[i] }; // Create a new object for immutability
                    subject.result = getExamSubjectResult(kid.id, subject.id);
                    subject.theoryMarks = subject.result && subject.result.attributes.theoryMarks;

                    if (subject.theoryMarks < subject.passing) {
                        allSubjectsPassed = false;
                    }

                    let practicalData = [];

                    if (subject.practicalData && subject.practicalData.length > 0) {
                        for (const j in subject.practicalData) {
                            let ps = { ...subject.practicalData[j] }; // Create a new object for immutability
                            ps.result = getExamSubjectResult(kid.id, subject.id, ps.id);
                            ps.practicalMarks = ps.result && ps.result.attributes.practicalMarks;

                            // Calculate total practical marks and max marks
                            totalPracticalMarks += ps.practicalMarks || 0;
                            totalPracticalMaxMarks += ps.totalMarks || 0;

                            practicalData.push(ps);

                            if (ps.practicalMarks < ps.passingMarks) {
                                allSubjectsPassed = false;
                            }
                        }
                    }

                    subject.practicalData = practicalData;

                    // Calculate total theory marks and max marks
                    totalMarks += subject.theoryMarks || 0;
                    totalMaxMarks += subject.totalMarks || 0;

                    // Check if subject is passed based on obtained marks and passing marks

                    kidSubjects.push(subject);
                }

                // Calculate total percentage
                totalPnTMarks = totalMarks + totalPracticalMarks;
                const percentage = (totalPnTMarks / totalMaxMarks) * 100;

                mainList.push({
                    id: kid.id,
                    kid: kid,
                    subjects: kidSubjects,
                    result: {
                        totalMarks: totalPnTMarks,
                        totalMaxMarks: totalMaxMarks,
                        percentage: percentage.toFixed(2),
                        passed: allSubjectsPassed,
                    },
                });
            }

            setKidSubjectList(mainList);
        }
    }, [selectedKids, tableData, props.examResults]);

    const getExamSubjectResult = (kidId, subjectId, practicalId) => {
        for (let sr of props.examResults) {
            if (sr.attributes.kidId === kidId && sr.attributes.subjectId === subjectId && sr.attributes.practicalId === practicalId) {
                return sr;
            }
        }
    };

    // console.log(kidSubjectList)

    useEffect(() => {

        if (props.subjects && props.subjects.length > 0) {
            let theorySubjects = []
            let practicalSubjects = []
            let subjects = [];
            for (const sub of props.subjects) {
                if (sub.attributes.examId == examId) {
                    if (sub.attributes.theoryMarks) {
                        theorySubjects.push(sub)
                    } else {
                        practicalSubjects.push(sub)
                    }
                }
            }
            for (const s of theorySubjects) {

                let newSubject = {
                    subjectId: uuidv4(),
                    id: s.id,
                    parseObject: s,
                    subjectName: s.attributes.name,
                    examStartDate: new Date(s.attributes.examDate),
                    theory: s.attributes.theoryMarks,
                    passing: s.attributes.passMarks,
                    totalMarks: s.attributes.maxMarks,

                    practicalData: [
                    ]
                }


                let pList = []
                for (const p of practicalSubjects) {

                    if (p.attributes.subjectId == s.id) {
                        let pObj = {
                            practicalId: uuidv4(),
                            id: p.id,
                            parseObject: p,
                            practicalName: p.attributes.name,
                            practicalStartDate: new Date(p.attributes.examDate),
                            practicalMarks: p.attributes.practicalMarks,
                            passingMarks: p.attributes.passMarks,
                            totalMarks: p.attributes.maxMarks,
                        }
                        pList.push(pObj)
                    }
                }

                newSubject.practicalData = pList;
                subjects.push(newSubject)


            }
            setTableData(subjects)
        }

    }, [props.subjects])


    function getTimeStringForDate(date) {
        return Moment(date).format("DD-MM-YYYY");
    }

    useEffect(() => {

        if (props.groupmaps && props.groupmaps.length > 0 && props.kids) {
            let tempSelectedKidList = []

            for (var i = 0; i < props.groupmaps.length; i++) {
                if (props.groupmaps[i].attributes.groupId === groupId) {
                    let kidObjList = props.kids.filter((kid) =>
                        kid.id == props.groupmaps[i].attributes.userId
                    )
                    if (kidObjList.length > 0) {
                        tempSelectedKidList.push(kidObjList[0]);
                    } else {
                        if (props.deletedKids) {
                            let aKidObjList = props.deletedKids.filter((kid) =>
                                kid.id == props.groupmaps[i].attributes.userId
                            )
                            if (aKidObjList.length > 0) {
                                tempSelectedKidList.push(aKidObjList[0]);
                            }
                        }
                    }
                }
            }
            setSelectedKids(tempSelectedKidList)
        }
    }, [props.groupmaps, props.kids, props.deletedKids]);

    useEffect(() => {

        if (props.classrooms && props.classrooms.length > 0) {
            var tempArray = [...props.classrooms]
            const ClassroomObject = Parse.Object.extend("ClassRoom");
            var tempClassroomObject = new ClassroomObject();
            tempClassroomObject.id = "allClassrooms"
            tempClassroomObject.set("Name", "All Classrooms")
            tempArray.unshift(tempClassroomObject)
            setClassroomList(tempArray)
            setSelectedClassroomFilter(tempClassroomObject)
        }

    }, [props.classrooms]);

    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    const handleCheckboxChange = (kObject) => {
        if (kObject.id === "pickAll") {
            handleSelectAll()
        } else
            if (selectedKids.includes(kObject) == false) {
                var tempSelectedList = [...selectedKids]
                tempSelectedList.push(kObject)
                setSelectedKids(tempSelectedList)
            } else {
                handleCheckboxChangeForSelected(kObject)
            }
    }
    const handleCheckboxChangeForSelected = (kObject) => {
        var tempSelectedList = [...selectedKids]
        removeElement(tempSelectedList, kObject)
        setSelectedKids(tempSelectedList)
    }

    const handleClassroomFilterChange = (value) => {
        var classrooms = classroomList.filter(
            (classroom) => classroom.id === value
        );

        if (classrooms.length > 0) {
            setSelectedClassroomFilter(classrooms[0])
        }
    }

    const getAllKidsFilteredList = () => {

        var listToReturn = []
        if (selectedClassroomFilter != undefined) {

            if (selectedClassroomFilter.id === "allClassrooms") {
                listToReturn = [...props.kids]
            } else {
                var i = 0
                for (i = 0; i < props.kids.length; i++) {

                    if (props.kids[i].attributes.ClassRoomID === selectedClassroomFilter.id) {

                        listToReturn.push(props.kids[i])

                    }
                }
            }
        }


        if (searchBarString === "") {
        } else {
            listToReturn = listToReturn.filter(object =>
                object.attributes.Name.toLowerCase().includes(searchBarString.toLowerCase())
            )
        }

        // if (selectedKids.length > 0) {
        //   listToReturn = listToReturn.filter(object =>
        //     !selectedKids.includes(object))
        // }



        if (listToReturn != null && listToReturn.length > 0 && listToReturn[0].id !== "pickAll") {
            const KidObjectParse = Parse.Object.extend("Kid");
            var kidObjectParse = new KidObjectParse();
            kidObjectParse.id = "pickAll";
            kidObjectParse.set("Name", "Pick All");
            listToReturn.unshift(kidObjectParse);
        }
        return (listToReturn)
    }

    function allPick() {

        let tempList = [...getAllKidsFilteredList()]
        if (tempList && tempList.length > 0) {
            for (const kid of tempList) {
                if (!selectedKids.includes(kid) && kid.id != "pickAll") {
                    return false

                }
            }
        }

        return true
    }

    function handleSelectAll() {
        let tempList = [...getAllKidsFilteredList()]

        if (allPick()) {
            let update = selectedKids.filter((kid) => {
                return !tempList.includes(kid)
            })
            setSelectedKids(update)
        }
        else {
            setSelectedKids(tempList)
            let oldList = [...selectedKids]

            for (const kobj of tempList) {

                if (!oldList.includes(kobj) && kobj.id != "pickAll") {
                    oldList.push(kobj)
                }

            }
            setSelectedKids(oldList)

        }

    }

    const handleSearchBarTextChange = (value) => {
        setSeachBarString(value)
    }


    const handleSaveGroupMaps = () => {

        const GroupMapObject = Parse.Object.extend("GroupMap");

        // var groupMapObjectList = []

        // var i = 0
        // for (i = 0; i < selectedKids.length; i++) {
        //     var groupMapObject = new GroupMapObject();
        //     groupMapObject.set("groupId", props.selectedExam.attributes.groupId);
        //     groupMapObject.set("userId", selectedKids[i].id)
        //     groupMapObject.set("userType", 1)
        //     groupMapObjectList.push(groupMapObject)
        // }

        // if (groupMapObjectList.length > 0) {
        //     console.log(groupMapObjectList)
        //     props.saveGroupMaps(groupMapObjectList)
        // }

        var groupMapKidIds = []
        var groupMapsToDelete = []
        var l = 0
        for (l = 0; l < props.groupmaps.length; l++) {
            if (props.groupmaps[l].attributes.userType === 1) {
                groupMapKidIds.push(props.groupmaps[l].attributes.userId)
            }
        }

        for (var i = 0; i < props.groupmaps.length; i++) {
            var shouldDelete = true

            if (props.groupmaps[i].attributes.userType === 1) {
                for (var j = 0; j < selectedKids.length; j++) {
                    if (props.groupmaps[i].attributes.userId === selectedKids[j].id) {
                        shouldDelete = false
                    }
                }
            }
            if (shouldDelete) {
                groupMapsToDelete.push(props.groupmaps[i])

            }
        }

        var kidObjectsToSave = selectedKids.filter((selectedKid) =>
            !groupMapKidIds.includes(selectedKid.id)
        )
        var groupMapObjectList = []

        if (kidObjectsToSave.length > 0) {
            var j = 0
            for (j = 0; j < kidObjectsToSave.length; j++) {
                var groupMapObject = new GroupMapObject();
                groupMapObject.set("groupId", props.selectedExam.attributes.groupId)
                groupMapObject.set("userId", kidObjectsToSave[j].id)
                groupMapObject.set("userType", 1)
                groupMapObjectList.push(groupMapObject)
            }
        }

        if (groupMapsToDelete.length > 0) {
            var i = 0
            for (i = 0; i < groupMapsToDelete.length; i++) {
                var tempObject = groupMapsToDelete[i]
                tempObject.set("isDeleted", true)
                groupMapObjectList.push(tempObject)
            }

        }
        if (groupMapObjectList.length > 0) {
            props.saveGroupMaps(groupMapObjectList)
        }
    }


    const getKidPhoto = (kObject) => {
        var kidPhoto = ""
        kidPhoto = kObject.attributes.Photo != undefined ? kObject.attributes.Photo._url : avatar2
        return kidPhoto
    }

    const saveMarks = (kidId) => {

        setIsSingleSaveLoading(true)

        let kid = kidSubjectList.filter((kid) =>
            kid.id == kidId
        )

        let allResults = [];
        let subjects = kid[0].subjects;

        for (let i = 0; i < subjects.length; i++) {


            const ExamResults = Parse.Object.extend("ExamResults");
            let examResult = new ExamResults();

            if (subjects[i].result) {
                examResult = subjects[i].result
                if (subjects[i].result.attributes.theoryMarks != parseInt(subjects[i].theoryMarks)) {
                    examResult.set("theoryMarks", parseInt(subjects[i].theoryMarks));
                    allResults.push(examResult);
                }
            } else {
                if (subjects[i].theoryMarks) {
                    examResult.set("kidId", kidId);
                    examResult.set("subjectId", subjects[i].id);
                    examResult.set("examId", examId);
                    examResult.set("theoryMarks", parseInt(subjects[i].theoryMarks));
                    allResults.push(examResult);
                }

            }

            for (let j = 0; j < subjects[i].practicalData.length; j++) {
                const practicalResult = new ExamResults();

                if (subjects[i].practicalData[j].result) {
                    examResult = subjects[i].practicalData[j].result
                    if (subjects[i].practicalData[j].result.attributes.theoryMarks != parseInt(subjects[i].practicalData[j].practicalMarks)) {
                        examResult.set("practicalMarks", parseInt(subjects[i].practicalData[j].practicalMarks));
                        allResults.push(examResult);
                    }
                } else {
                    if (subjects[i].practicalData[j].practicalMarks) {
                        practicalResult.set("kidId", kidId);
                        practicalResult.set("subjectId", subjects[i].id);
                        practicalResult.set("practicalId", subjects[i].practicalData[j].id);
                        practicalResult.set("examId", examId);
                        practicalResult.set("practicalMarks", parseInt(subjects[i].practicalData[j].practicalMarks));
                        allResults.push(practicalResult);
                    }
                }
            }
        }

        Parse.Object.saveAll(allResults).then((results) => {
            props.updateExamResults(results, true)
            setIsSingleSaveLoading(false)
            // console.log(results)
        });
    };


    const saveMarksForAllKids = () => {

        setIsAllSaveLoading(true)

        const allResults = [];

        for (let k = 0; k < kidSubjectList.length; k++) {
            const kidId = kidSubjectList[k].id;
            const subjects = kidSubjectList[k].subjects;

            for (let i = 0; i < subjects.length; i++) {
                const ExamResults = Parse.Object.extend("ExamResults");
                let examResult = new ExamResults();

                if (subjects[i].result) {
                    examResult = subjects[i].result;

                    if (subjects[i].result.attributes.theoryMarks != parseInt(subjects[i].theoryMarks)) {
                        examResult.set("theoryMarks", parseInt(subjects[i].theoryMarks));
                        allResults.push(examResult);
                    }
                } else {
                    if (subjects[i].theoryMarks) {
                        examResult.set("kidId", kidId);
                        examResult.set("subjectId", subjects[i].id);
                        examResult.set("examId", examId);
                        examResult.set("theoryMarks", parseInt(subjects[i].theoryMarks));
                        allResults.push(examResult);
                    }

                }

                for (let j = 0; j < subjects[i].practicalData.length; j++) {
                    const practicalResult = new ExamResults();

                    if (subjects[i].practicalData[j].result) {
                        examResult = subjects[i].practicalData[j].result;

                        if (subjects[i].practicalData[j].result.attributes.theoryMarks != parseInt(subjects[i].practicalData[j].practicalMarks)) {
                            examResult.set("practicalMarks", parseInt(subjects[i].practicalData[j].practicalMarks));
                            allResults.push(examResult);
                        }
                    } else {
                        if (subjects[i].practicalData[j].practicalMarks) {
                            console.log(subjects[i].practicalData[j]);
                            practicalResult.set("kidId", kidId);
                            practicalResult.set("subjectId", subjects[i].id);
                            practicalResult.set("practicalId", subjects[i].practicalData[j].id);
                            practicalResult.set("examId", examId);
                            practicalResult.set("practicalMarks", parseInt(subjects[i].practicalData[j].practicalMarks));
                            allResults.push(practicalResult);
                        }
                    }
                }
            }
        }

        Parse.Object.saveAll(allResults).then((results) => {
            // console.log(results);
            props.updateExamResults(results, true)
            setIsAllSaveLoading(false)
        });
    };


    return (
        <React.Fragment>
            <div className="page-content">
                <Modal isOpen={showStudentSelectionModal} className="modal-xl" scrollable={true} backdrop={true}>
                    <ModalHeader toggle={() => {
                        setShowStudentSelectionModal(!showStudentSelectionModal)
                    }}>
                        Select Students
                    </ModalHeader>
                    <ModalBody>
                        <div>
                            <div id="kyc-verify-wizard" className="twitter-bs-wizard">
                                <Label className="mt-3 mb-0 ml-1">
                                    Select Student
                                </Label>
                                <hr />

                                <Row lg={12}>
                                    <Col lg={6}>
                                        <Row>
                                            <Col lg={6}>
                                                <FormGroup >
                                                    <select
                                                        className="form-control"
                                                        onChange={(e) => {
                                                            handleClassroomFilterChange(e.target.value)
                                                        }}
                                                    >
                                                        {classroomList.map((classroomObject, key) => (
                                                            <option key={key} value={classroomObject.id}>{classroomObject.attributes.Name}</option>
                                                        ))}
                                                    </select>
                                                </FormGroup>
                                            </Col>
                                            <Col lg={6}>
                                                <Input
                                                    type="text"
                                                    name="searchAG"
                                                    className="inner form-control mb-3"
                                                    placeholder="Search"
                                                    onChange={(e) => {
                                                        handleSearchBarTextChange(e.target.value)
                                                    }}
                                                />
                                            </Col>
                                        </Row>
                                        {<Row><p class="ml-4">* Existing selected list</p></Row>}
                                        <Row>
                                            <div className="table-responsive ml-3 mr-2">
                                                <table className="table table-nowrap table-centered mb-0">
                                                    <tbody>
                                                        {getAllKidsFilteredList().length === 0 && <div className="ml-3 mt-3">
                                                            <p>No Student found</p>
                                                            <hr />
                                                        </div>}
                                                        {getAllKidsFilteredList().map((kObject, keyInside) => (
                                                            <tr key={keyInside}>

                                                                <td style={{ width: "60px" }}>

                                                                    <input type="checkbox" id={"customCheck12_" + keyInside} onChange={
                                                                        () => {
                                                                            handleCheckboxChange(kObject)
                                                                        }
                                                                    } checked={kObject.id == "pickAll" ? allPick() : selectedKids.includes(kObject)} />

                                                                </td>
                                                                <td>
                                                                    <Label className="text-truncate font-size-14 m-0">{kObject.id !== "pickAll" &&
                                                                        <img src={getKidPhoto(kObject)} className="rounded-circle avatar-xs m-1" alt="" />}
                                                                        <Label className="text-dark ml-2">{kObject.attributes.Name}
                                                                            {initialSelctedKids && initialSelctedKids.includes(kObject) && <span>*</span>}</Label>
                                                                    </Label>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>

                                                                </td>
                                                            </tr>
                                                        ))}



                                                    </tbody>
                                                </table>
                                            </div>

                                        </Row>


                                    </Col>
                                    <Col lg={6}>
                                        <Row lg={12}>
                                            <Col lg={10}>
                                                <div className="text-center pt-2 py-auto bg-primary text-white" style={{ borderRadius: "5px", height: "35px" }}>Already In Group</div>
                                            </Col>
                                            <Col lg={2}>
                                                <Button
                                                    color="primary"
                                                    className="btn"
                                                    onClick={() => {
                                                        handleSaveGroupMaps()
                                                        setShowStudentSelectionModal(false)
                                                    }}>
                                                    Save
                                                </Button>
                                            </Col>
                                        </Row>



                                        <Row>
                                            <div className="table-responsive mt-3 ml-2 mr-3">
                                                <table className="table table-nowrap table-centered mb-0">
                                                    <tbody>

                                                        {selectedKids.length === 0 && <div className="ml-1">
                                                            <p className="ml-3">No Student Selected</p>
                                                            <hr />
                                                        </div>}

                                                        {selectedKids.map((kObject, keyInside) => (
                                                            <tr key={keyInside}>
                                                                <td style={{ width: "60px" }}>
                                                                    <input type="checkbox" id={"customCheck1_" + keyInside}
                                                                        onChange={() => {
                                                                            handleCheckboxChangeForSelected(kObject)
                                                                        }
                                                                        } checked={selectedKids.includes(kObject)} />
                                                                </td>
                                                                <td>
                                                                    <Label className="text-truncate font-size-14 m-0">
                                                                        <img src={kObject.attributes.Photo != undefined ? kObject.attributes.Photo._url : avatar2}
                                                                            className="rounded-circle avatar-xs m-1" alt="" />
                                                                        <Label className="text-dark ml-2">{kObject.attributes.Name}{initialSelctedKids && initialSelctedKids.includes(kObject) && <span>*</span>}</Label>
                                                                    </Label>
                                                                </td>
                                                                <td>
                                                                </td>
                                                                <td>

                                                                </td>
                                                            </tr>
                                                        ))}

                                                    </tbody>
                                                </table>
                                            </div>

                                        </Row>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            className="btn float-end"
                            onClick={() => {
                                handleSaveGroupMaps()
                                setShowStudentSelectionModal(false)
                            }}>
                            Save
                        </Button>
                    </ModalFooter>
                </Modal>
                <Row>
                    <Col>
                        <Row className='d-flex align-items-start'>
                            <Col className="d-flex align-items-start">
                                <div
                                    style={{ cursor: "pointer" }}
                                    onClick={() => { window.history.back() }}>
                                    <ul className=" list-unstyled">
                                        <div className=''>
                                            <i className="bx bx-chevron-left h1 text-primary"></i>
                                        </div>
                                    </ul>
                                </div>
                                <div className=' m-2'>
                                    <h4 className="mb-0">{props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.name}</h4>
                                </div>
                            </Col>
                            <Col className='d-flex justify-content-end'>
                                <div className="mt-4 mt-sm-0 d-flex justify-content-end">
                                    {<button
                                        className="btn btn-primary waves-effect waves-light m-1"
                                        onClick={() => {
                                            // resetAddStudentPopup()
                                            setShowStudentSelectionModal(true)
                                        }}
                                    >
                                        Add Students
                                    </button>}
                                    <Link
                                        className="btn btn-warning waves-effect waves-light m-1"
                                        to={`/edit-exam/${schoolId}/${examId}`}
                                        role="button"
                                    >
                                        Edit
                                    </Link>
                                    {
                                        isAllSaveLoading ?
                                            <Button type="button" color="primary" className=" waves-effect float-right m-1">
                                                <i className="bx bx-loader bx-spin font-size-16 align-middle m-1"></i>Saving...
                                            </Button> :
                                            <Button type="submit" className='m-1'
                                                color="primary" onClick={() => {
                                                    saveMarksForAllKids()
                                                }}>
                                                Save
                                            </Button>
                                    }
                                </div>
                            </Col>
                        </Row>
                        <Card>
                            <CardBody>
                                <Row>
                                    <Col xs={6}>
                                        <CardTitle>
                                            <div className="m-3">
                                                <p className="" >Duration : <b>{moment(props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.startDate).format("DD-MM-YYYY")} - {moment(props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.endDate).format("DD-MM-YYYY")}</b></p>
                                                <p className="">Certificate Issue Date: <b>{moment(props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.certificateDate).format("DD-MM-YYYY")}</b></p>
                                                <p className="" >Year : <b>{props.selectedExam && props.selectedExam.attributes && props.selectedExam.attributes.tag ? props.selectedExam.attributes.tag[0] : ''}</b></p>
                                            </div>
                                        </CardTitle>
                                    </Col>
                                    <Col xs={6}>
                                        <CardTitle>Download Certificates</CardTitle>
                                        <div className='d-flex justify-content-start'>
                                            <Link to={`/marks-cards/${schoolId}/${examId}/${groupId}`}
                                                target='_blank'
                                                className='btn btn-primary m-1'
                                                id='marksCard'
                                            >Marks Cards<i className="bx bx-receipt m-1"></i>
                                            </Link>
                                            <Link to={`/exam-certificates/${schoolId}/${examId}/${groupId}`}
                                                target='_blank'
                                                className='btn btn-primary m-1'
                                                id='examCertificate'
                                            >Certificates<i className="bx bx-memory-card m-1"></i>
                                            </Link>
                                            <Link
                                                to={`/exam-hall-tickets/${schoolId}/${examId}/${groupId}`}
                                                target='_blank'
                                                className='btn btn-primary m-1'
                                                id='examHallTicket'
                                            >Hall Tickets<i className="bx bx-id-card m-1"></i>
                                            </Link>

                                        </div>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>



                        <Card>
                            <CardBody>
                                <div className={"border rounded p-3 mb-1"}>
                                    <Row style={{ height: "8px", paddingBottom: "20px" }}>
                                        <Col lg={4}>
                                            <div className="ml-5">
                                                <Label className="mb-1 font-size-14">
                                                    {`Student Name (${kidSubjectList.length})`}
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div>
                                                <Label className="mb-1 font-size-14">
                                                    Status
                                                </Label>
                                            </div>
                                        </Col>
                                        <Col lg={4}>
                                            <div>
                                                <Label className="mb-1 font-size-14">
                                                    Action
                                                </Label>
                                            </div>
                                        </Col>
                                    </Row>
                                </div>

                                {kidSubjectList && kidSubjectList.map((kidSubjectData, key) => (
                                    <div key={key} className={"border rounded p-3 mb-1"}>
                                        <Row style={{ paddingBottom: "20px" }}>
                                            <Col lg={4}>
                                                <div className="ml-5">
                                                    <Label onClick={() => {
                                                        setSelectedKidMarks(selectedKidMarks === kidSubjectData.id ? '' : kidSubjectData.id);
                                                    }} className="mb-1 font-size-14">
                                                        <th>{kidSubjectData.kid.attributes.Name}</th>
                                                        <small style={{ cursor: 'pointer'}}>{kidSubjectData.kid.attributes.admissionNumber}</small>
                                                    </Label>
                                                </div>
                                            </Col>
                                            <Col lg={4} className='text-center'>
                                                <div className='mb-1 font-size-12' style={{ display: 'flex', alignItems: 'center' }}>
                                                    {/* <Label className="mb-1 font-size-14"> */}
                                                    <p className='mb-0 m-2'>{kidSubjectData.result.totalMarks + '/' + kidSubjectData.result.totalMaxMarks}</p>
                                                    <Badge color={kidSubjectData.result.passed == true ? 'success' : 'danger'} className='badge badge-sm mb-0 m-2'>{kidSubjectData.result.passed == true ? 'Pass' : 'Fail'}</Badge>
                                                    <b className='mb-0 m-2'>{kidSubjectData.result.percentage} {""} %</b>
                                                    {/* </Label> */}
                                                </div>
                                            </Col>
                                            <Col className='d-flex justify-content-start' lg={4}>
                                                <Row>
                                                    <Col className='d-flex justify-content-start'>
                                                        <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                                            <Link
                                                                onClick={(e) => {
                                                                    e.preventDefault();
                                                                    setSelectedKidMarks(selectedKidMarks === kidSubjectData.id ? '' : kidSubjectData.id);
                                                                }}
                                                            >
                                                                <li className="list-inline-item px-2" style={{ cursor: "pointer" }} id={"exam-edit" + key}>
                                                                    <i className="bx bx-edit-alt"></i>
                                                                    <UncontrolledTooltip placement="top" target={"exam-edit" + key}>
                                                                        {props.t('Enter Marks')}
                                                                    </UncontrolledTooltip>
                                                                </li>
                                                            </Link>

                                                        </ul>
                                                        <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                                            <Link to={`/marks-card/${schoolId}/${examId}/${kidSubjectData.id}`} target='_blank'>
                                                                <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    id={"viewDetailPage" + key}
                                                                >
                                                                    <i className="bx bx-receipt"></i>
                                                                    <UncontrolledTooltip placement="top" target={"viewDetailPage" + key}>
                                                                        {'Marks Card'}
                                                                    </UncontrolledTooltip>
                                                                </li></Link>
                                                        </ul>
                                                        <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                                            <Link to={`/exam-certificate/${schoolId}/${examId}/${kidSubjectData.id}`} target='_blank'>
                                                                <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    id={"examCertificate" + key}
                                                                >
                                                                    <i className="bx bx-memory-card"></i>
                                                                    <UncontrolledTooltip placement="top" target={"examCertificate" + key}>
                                                                        {'Certificate'}
                                                                    </UncontrolledTooltip>
                                                                </li></Link>
                                                        </ul>
                                                        <ul className="list-inline font-size-18 contact-links mb-0 float-right">
                                                            <Link to={`/exam-hall-ticket/${schoolId}/${examId}/${kidSubjectData.id}`} target='_blank'>
                                                                <li className="list-inline-item px-2"
                                                                    style={{ cursor: "pointer" }}
                                                                    id={"examHallTicket" + key}
                                                                >
                                                                    <i className="bx bx-id-card"></i>
                                                                    <UncontrolledTooltip placement="top" target={"examHallTicket" + key}>
                                                                        {'Hall Ticket'}
                                                                    </UncontrolledTooltip>
                                                                </li></Link>
                                                        </ul>
                                                    </Col>
                                                </Row>
                                            </Col>
                                        </Row>

                                        <Collapse isOpen={selectedKidMarks === kidSubjectData.id}>
                                            <div className="m-3">
                                                {kidSubjectData.subjects && (
                                                    <div className={"border rounded p-3 mb-1"}>
                                                        <Row>
                                                            {kidSubjectData.subjects.map((sub, subKey) => (
                                                                <Col key={subKey}>
                                                                    <div className="ml-5">
                                                                        <Label className="font-size-14">
                                                                            <b>{sub.subjectName}{`(${sub.theory})`}</b>
                                                                        </Label>
                                                                        <div className={" mb-1"}>
                                                                            <Input my naime ibhb hbhdcvd
                                                                                className='m-1'
                                                                                type="number"
                                                                                id={key + '-' + subKey}
                                                                                placeholder="Marks"
                                                                                value={sub.theoryMarks}
                                                                                onChange={(e) => {
                                                                                    // const updatedKidSubjectData = [...kidSubjectList];
                                                                                    // updatedKidSubjectData[key].subjects[subKey].theoryMarks = e.target.value;
                                                                                    // setKidSubjectList(updatedKidSubjectData);

                                                                                    const updatedKidSubjectData = [...kidSubjectList];
                                                                                    const maxTheoryMarks = sub.theory;

                                                                                    if (e.target.value <= maxTheoryMarks) {
                                                                                        updatedKidSubjectData[key].subjects[subKey].theoryMarks = e.target.value;
                                                                                        setKidSubjectList(updatedKidSubjectData);
                                                                                    }
                                                                                }}
                                                                            />
                                                                        </div>
                                                                        {sub.practicalData && sub.practicalData.map((practical, practicalIndex) => (
                                                                            <div key={practicalIndex} className={"p-2 mb-1"}>
                                                                                <Label className="mb-1 font-size-8">
                                                                                    {practical.practicalName}{`(${practical.totalMarks})`}
                                                                                </Label>
                                                                                <div className={"mb-1"}>
                                                                                    <Input
                                                                                        className='m-1'
                                                                                        type="number"
                                                                                        placeholder="Marks"
                                                                                        value={practical.practicalMarks}
                                                                                        onChange={(e) => {
                                                                                            // const updatedKidSubjectData = [...kidSubjectList];
                                                                                            // updatedKidSubjectData[key].subjects[subKey].practicalData[practicalIndex].practicalMarks = e.target.value;
                                                                                            // setKidSubjectList(updatedKidSubjectData);

                                                                                            const updatedKidSubjectData = [...kidSubjectList];
                                                                                            const maxPracticalMarks = practical.totalMarks;

                                                                                            if (e.target.value <= maxPracticalMarks) {
                                                                                                updatedKidSubjectData[key].subjects[subKey].practicalData[practicalIndex].practicalMarks = e.target.value;
                                                                                                setKidSubjectList(updatedKidSubjectData);
                                                                                            }
                                                                                        }}
                                                                                    />
                                                                                </div>
                                                                            </div>
                                                                        ))}
                                                                    </div>
                                                                </Col>
                                                            ))}
                                                        </Row>
                                                        <Row>
                                                            <Col className='d-flex justify-content-end'>
                                                                {
                                                                    isSingleSaveLoading ?
                                                                        <Button type="button" color="primary" className="btn btn-sm waves-effect float-right m-1">
                                                                            <i className="bx bx-loader bx-spin font-size-16 align-middle m-1"></i>Saving...
                                                                        </Button> :
                                                                        <Button type="submit" className='btn btn-sm m-1'
                                                                            color="primary" onClick={() => {
                                                                                saveMarks(kidSubjectData.id);
                                                                            }}>
                                                                            Save
                                                                        </Button>

                                                                }
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                )}
                                            </div>
                                        </Collapse>
                                    </div>
                                ))}
                            </CardBody>

                        </Card>



                    </Col>
                </Row>

            </div >
        </React.Fragment >

    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { kids, deletedKids } = state.Kid;
    const { teachers } = state.Teacher;
    const { classrooms } = state.Classroom;
    const { subjects } = state.Subjects;
    const { selectedExam } = state.Examination;
    const { examResults } = state.ExamResults;
    const { resultGroup, singleGroupHolder } = state.Group;
    const { groupmaps, resultGroupMapsAfterSave } = state.GroupMap;


    return {
        selectedSchool,
        kids,
        classrooms,
        deletedKids,
        subjects,
        selectedExam,
        examResults,
        singleGroupHolder,
        groupmaps,
        teachers,
        resultGroup,
        resultGroupMapsAfterSave,
    }
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getSubjectsForExamId, getExamForExamId, getClassroomsForSchoolId, getAllKids, getExamResultsForExamId, getAllDeletedKidsForSchoolIdOnlyForArchives,
    getGroupForId, getGroupMapsForGroupId, getTeachersForSchool, getAllKidsForGroup, saveGroup, saveGroupMaps, updateExamResults
})(MarksCardView)));
