import Parse from "parse";
import { printLog } from "./loghelper";

export const getMessageQueues = (param) => {
  printLog("HL: message_helper : getMessageQueues")
  try {
    return new Promise((resolve, reject) => {
      var MessageQueue = Parse.Object.extend("MessageQueue");
      var query = new Parse.Query(MessageQueue);
      if (param.franchiseId) {
        query.equalTo("franchiseId", param.franchiseId)
      } else if (param.instituteId) {
        query.equalTo("instituteId", param.instituteId)
        query.doesNotExist("franchiseId")
      } else if (param.schoolId) {
        query.equalTo("schoolId", param.schoolId)
        query.doesNotExist("franchiseId")
      }

      var aprilFirst = new Date(2022, 3, 0, 23, 59);
      query.greaterThan("createdAt", aprilFirst)
      query.descending("createdAt")
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    }
    )
  } catch (error) { }
}

export const getAlbumsForAlbumIds = (param) => {
  printLog("HL: message_helper : getAlbumsForAlbumIds")

  try {
    return new Promise((resolve, reject) => {
      var Album = Parse.Object.extend("Album");
      var query1 = new Parse.Query(Album);
      query1.containedIn("albumId", param.id)
      if (param.isLocal) {
        query1.fromLocalDatastore();
        //query1.notEqualTo("isDeleted", true);
      }
      if (param.limit) {
        query1.limit(param.limit);
      }
      query1.notEqualTo("isDeleted", true);
      query1.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    }
    )
  } catch (error) { }
}


export const deleteAlbumFromParse = (album) => {
  try {
    return new Promise((resolve, reject) => {

      album.set("isDeleted", true);
      if (Parse.User.current().attributes.teacherId) {
        album.set("deletedBy", Parse.User.current().attributes.teacherId);
      } else {
        album.set("deletedBy", Parse.User.current().id);
      }

      album.save().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          reject(null);
        }
      );
    });
  } catch (error) { }
};

export const getMessagesForClassId = (param) => {
  printLog("HL: message_helper : getMessagesForClassId")

  try {
    return new Promise((resolve, reject) => {
      var Message = Parse.Object.extend("Messages");
      var query = new Parse.Query(Message);
      query.equalTo("ClassID", param.id)
      query.doesNotExist("FamilyID")
      query.doesNotExist("KidID")
      query.descending("customUpdatedTime");

      if (param.updatedDate) {
        if (param.isOld) {
          //query.descending("customUpdatedTime");
          query.lessThan("updatedAt", new Date(param.updatedDate))
        } else {
          query.greaterThan("updatedAt", new Date(param.updatedDate))
        }
      } else {
        if (param.isLocal) {
          query.notEqualTo("isDeleted", true);
        }
      }
      if (param.isLocal) {
        query.fromLocalDatastore();
      }


      if (param.limit) {
        query.limit(param.limit);
      }
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    }
    )
  } catch (error) { }
}





export const getMessagesForGroupId = (param) => {
  printLog("HL: message_helper : getMessagesForGroupId")

  try {
    return new Promise((resolve, reject) => {
      var Message = Parse.Object.extend("Messages");
      var query = new Parse.Query(Message);
      query.equalTo("groupId", param.id)
      query.doesNotExist("FamilyID")
      query.doesNotExist("KidID")
      query.descending("customUpdatedTime");

      if (param.updatedDate) {
        if (param.isOld) {
          query.lessThan("updatedAt", new Date(param.updatedDate))
        } else {
          query.greaterThan("updatedAt", new Date(param.updatedDate))
        }
      } else {
        if (param.isLocal) {
          query.notEqualTo("isDeleted", true);
        }
      }
      if (param.isLocal) {
        query.fromLocalDatastore();
      }


      if (param.limit) {
        query.limit(param.limit);
      }
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    }
    )
  } catch (error) { }
}

export const getMessagesForSchoolId = (param) => {
  printLog("HL: message_helper : getMessagesForSchoolId")

  try {
    return new Promise((resolve, reject) => {
      var Message = Parse.Object.extend("Messages");
      var query = new Parse.Query(Message);

      query.equalTo("SchoolID", param.id);
      query.doesNotExist("groupId");
      query.doesNotExist("ClassID");
      query.doesNotExist("FamilyID");
      query.doesNotExist("KidID");
      query.descending("customUpdatedTime");


      if (param.updatedDate) {
        if (param.isOld) {
          //query.descending("customUpdatedTime");
          query.lessThan("updatedAt", new Date(param.updatedDate))
        } else {
          query.greaterThan("updatedAt", new Date(param.updatedDate))
        }
      } else {
        if (param.isLocal) {
          query.notEqualTo("isDeleted", true);
        }
      }
      if (param.isLocal) {
        query.fromLocalDatastore();
      }


      if (param.limit) {
        query.limit(param.limit);
      }
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    }
    )
  } catch (error) { }
}


export const getMessagesForAllTeachers = (param) => {
  printLog("HL: message_helper : getMessagesForAllTeachers")

  try {
    return new Promise((resolve, reject) => {
      var Message = Parse.Object.extend("Messages");
      var query = new Parse.Query(Message);

      query.equalTo("SchoolID", param.id);
      query.equalTo("Receiver", "T");
      query.doesNotExist("FamilyID");
      query.doesNotExist("KidID");
      query.descending("customUpdatedTime");


      if (param.updatedDate) {
        if (param.isOld) {
          //query.descending("customUpdatedTime");
          query.lessThan("updatedAt", new Date(param.updatedDate))
        } else {
          query.greaterThan("updatedAt", new Date(param.updatedDate))
        }
      } else {
        if (param.isLocal) {
          query.notEqualTo("isDeleted", true);
        }
      }
      if (param.isLocal) {
        query.fromLocalDatastore();
      }


      if (param.limit) {
        query.limit(param.limit);
      }
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          resolve(error);
        }
      );

    }
    )
  } catch (error) { }
}

export const getMessagesForCourseKid = (param) => {
  printLog("HL: message_helper : getMessagesForCourseKid")
  try {
    return new Promise((resolve, reject) => {
      var Message = Parse.Object.extend("Messages");
      var query = new Parse.Query(Message);


      // console.log("param.courseId",param.courseId);
      query.equalTo("courseId", param.courseId);
      query.equalTo("KidID", param.kidId);

      query.descending("customUpdatedTime");


      if (param.updatedDate) {
        if (param.isOld) {
          //query.descending("customUpdatedTime");
          query.lessThan("updatedAt", new Date(param.updatedDate))
        } else {
          query.greaterThan("updatedAt", new Date(param.updatedDate))
        }
      } else {
        if (param.isLocal) {
          query.notEqualTo("isDeleted", true);
        }
      }
      if (param.isLocal) {
        query.fromLocalDatastore();
      }


      if (param.limit) {
        query.limit(param.limit);
      } else {
        query.limit(10);
      }
      query.find().then(
        (result) => {
          resolve(result);
        },
        (error) => {
          console.log("merror", error)
          reject(error);
        }
      );

    }
    )
  } catch (error) { }
}


