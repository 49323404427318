import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as CurriculumHelper from "../../../helpers/ParseHelpers/curriculum_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as CurriculumActions from "./actions";

function* getACurriculum({ payload: { id } }) {
  try {
    const response = yield call( CurriculumHelper.getCurriculumFromParse, id,false);
    yield put(CurriculumActions.updateCurriculum(response));
    if (response) {
      response.pin();
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}
function* deleteACurriculum({ payload: { curriculum } }) {
  try {
    const response = yield call(
        CurriculumHelper.deleteCurriculumFromParse,
        curriculum
    );
    if (response) {
      yield put(CurriculumActions.updateCurriculum(response));
      response.unPin();
    } else {
      //not deleted
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* setSelectedCurriculum({ payload: { id } }) {
  try {
    const response = yield call( CurriculumHelper.getCurriculumFromParse, id,true);
    if (response) {
      yield put(CurriculumActions.updateSelectedCurriculum(response));
      response.pin();
    } else {
      const sResponse = yield call(CurriculumHelper.getCurriculumFromParse, id, false);
      if (sResponse) {
        sResponse.pin();
        yield put(CurriculumActions.updateSelectedCurriculum(sResponse));
      }
    
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

function* getCurriculumForownerId({ payload: { ownerId } }) {
    try {
      var key = Constants.CURRICULUM_UPDATED_TIME_FOR_OWNER_ID + "_" + ownerId;
      var updatedTime = localStorage.getItem(key)
      var newTime = new Date();
      const localResponse = yield call(CurriculumHelper.getCurriculumForownerId, ownerId, null, true);
      yield put(CurriculumActions.updateCurriculums(localResponse));
  
      const serverResponse = yield call(CurriculumHelper.getCurriculumForownerId, ownerId, updatedTime, false);
      localStorage.setItem(key,newTime)
  
      if (serverResponse && serverResponse.length > 0) {
          yield put(CurriculumActions.updateCurriculums(serverResponse));
          Parse.Object.pinAll(serverResponse);
      }
    } catch (error) {
      // yield put(apiError(error));
  
    }
}

function* getCurriculumsForownerIds({ payload: { ownerIds,curriculumId } }) {
  try {
    
    yield put(CurriculumActions.clearCuriculams());
    let schoolCurriculum;
    if (curriculumId) {
      schoolCurriculum = yield call(CurriculumHelper.getCurriculumFromParse, curriculumId);

      if (schoolCurriculum) {
        yield put(CurriculumActions.updateCurriculums([schoolCurriculum], true));
        schoolCurriculum.pin()
      }
    }

    const localResponse = yield call(CurriculumHelper.getCurriculumsForOwnerIds, ownerIds, true);
    if (schoolCurriculum) {
        yield put(CurriculumActions.updateCurriculums([schoolCurriculum,...localResponse], true));
    } else {
      yield put(CurriculumActions.updateCurriculums(localResponse,true));
    }
    

    const serverResponse = yield call(CurriculumHelper.getCurriculumsForOwnerIds, ownerIds, false);
   
    yield put(CurriculumActions.setCurriculumInitialLoading(false));
    if (serverResponse) {
      
      if (schoolCurriculum) {
        yield put(CurriculumActions.updateCurriculums([schoolCurriculum,...serverResponse], true));
      } else {
        yield put(CurriculumActions.updateCurriculums(serverResponse, true));
      }
      
      

      var nonDeleted = serverResponse.filter(
        (enquiryquestion) =>
          enquiryquestion.attributes.isDeleted !== true
      );

      var deleted = serverResponse.filter(
        (enquiryquestion) =>
          enquiryquestion.attributes.isDeleted 
      );
     
      if (nonDeleted) {
        Parse.Object.pinAll(nonDeleted);
      }

      if (deleted) {
        Parse.Object.unPinAll(deleted);
      }
        
    }
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* setInitialLoading({ payload: { state } }) {
  try {
    yield put(CurriculumActions.updateCurriculumInitialLoading(state));
  } catch (error) {
    // yield put(apiError(error));

  }
}

function* addNewCurriculum({ payload: { curriculum,cycles ,schoolId,history} }) {
  try {
    const response = yield call( CurriculumHelper.saveCurriculumObject, curriculum);
    if (response) {
     
      
      var list = [];
      if (cycles && cycles.length > 0) {
        for (const i in cycles) {
          var AcademicCycles = Parse.Object.extend("AcademicCycles");
          var academicCycles = new AcademicCycles();
          
          academicCycles.set("schoolCycle", cycles[i].school);
          academicCycles.set("defaultCycle", cycles[i].lil);
          academicCycles.set("curriculumId", response.id);
          academicCycles.set("order", parseInt(i));
          list.push(academicCycles);
        }
        const cycleResponse = yield call(CurriculumHelper.saveObjects , list);
        if (cycleResponse) {
          Parse.Object.pinAll(cycleResponse);
        }
        history.push("/lms-syllabus/" + schoolId + "/" + response.id);
      }
      yield put(CurriculumActions.updateCurriculum(response));
      response.pin();
    } else {
      
    }
  } catch (error) {
    // yield put(apiError(error));
  }
}

export function* watchGetCurriculum() {
    yield takeEvery(actionTypes.GET_CURRICULUM, getACurriculum);
  }
  
  export function* watchDeleteCurriculum() {
    yield takeEvery(actionTypes.DELETE_CURRICULUM, deleteACurriculum);
  }
  
  export function* watchGetCurriculumForownerId() {
    yield takeEvery(actionTypes.GET_CURRICULUM_FOR_OWNER_ID, getCurriculumForownerId);
  }
  export function* watchGetCurriculumsForOwnerIds() {
    yield takeEvery(actionTypes.GET_CURRICULUMS, getCurriculumsForownerIds);
  }

  export function* watchSetInitialLoading() {
    yield takeEvery(actionTypes.SET_CURRICULUM_INITIAL_LOADING, setInitialLoading);
  }

  export function* watchAddNewCurriculum() {
    yield takeEvery(actionTypes.ADD_NEW_CURRICULUM, addNewCurriculum);
  }

  export function* watchSetSelectedCurriculum() {
    yield takeEvery(actionTypes.SET_SELECTED_CURRICULUM, setSelectedCurriculum);
  }

  
function* CurriculumSaga() {
    yield all([
      fork(watchGetCurriculum),
      fork(watchDeleteCurriculum),
      fork(watchGetCurriculumForownerId),
      fork(watchGetCurriculumsForOwnerIds),
      fork(watchSetInitialLoading),
      fork(watchAddNewCurriculum),
      fork(watchSetSelectedCurriculum),
    ]);
  }
  export default CurriculumSaga;
  