export const UPDATE_CURRICULUM = "UPDATE_CURRICULUM";
export const DELETE_CURRICULUM = "DELETE_CURRICULUM";

export const UPDATE_CURRICULUMS = "UPDATE_CURRICULUMS";   
export const GET_CURRICULUMS = "GET_CURRICULUMS";
export const GET_CURRICULUM = "GET_CURRICULUM";
export const GET_CURRICULUM_FOR_OWNER_ID = "GET_CURRICULUM_FOR_OWNER_ID"

export const SET_CURRICULUM_INITIAL_LOADING = "SET_CURRICULUM_INITIAL_LOADING";
export const UPDATE_CURRICULUM_INITIAL_LOADING = "UPDATE_CURRICULUM_INITIAL_LOADING";

export const ADD_NEW_CURRICULUM = "ADD_NEW_CURRICULUM"

export const CLEAR_CURRICULUM = "CLEAR_CURRICULUM"

export const SET_SELECTED_CURRICULUM = "SET_SELECTED_CURRICULUM";
export const UPDATE_SELECTED_CURRICULUM = "UPDATE_SELECTED_CURRICULUM";