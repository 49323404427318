import * as actions from "./actionTypes";



export const deleteGroup = (group) => {
  return {
    type: actions.DELETE_GROUP,
    payload: { group },
  };
};
export const saveGroup = (group) => {
  return {
    type: actions.SAVE_GROUP,
    payload: { group }
  }
}



export const updateResultGroup = (resultGroup) => {
  return {
    type: actions.UPDATE_RESULT_GROUP,
    payload: { resultGroup }
  }
}

export const updategroups = (groups) => {
  return {
    type: actions.UPDATE_GROUPS,
    payload: { groups }
  }
}

export const updateArchiveGroups = (groups) => {
  return {
    type: actions.UPDATE_ARCHIVE_GROUPS,
    payload: { groups }
  }
}


export const updateArchiveGroup = (group) => {
  return {
    type: actions.UPDATE_ARCHIVE_GROUP,
    payload: { group }
  }
}

export const updateGroup = (group) => {
  return {
    type: actions.UPDATE_GROUP,
    payload: { group }
  }
}
export const updateSingleGroup = (singleGroupHolder) => {
  return {
    type: actions.SINGLE_GROUP_UPDATE,
    payload: { singleGroupHolder }
  }
}



export const setDeletedSuccessfully = (boolValue) => {
  return {
    type: actions.DELETED_SUCCESSFULLY,
    payload: { boolValue }
  }
}

export const getGroupsForSchoolId = (schoolId) => {
  return {
    type: actions.GET_ALL_GROUPS_FOR_SCHOOL_ID,
    payload: { schoolId }
  }
}
export const getGroupForId = (groupId) => {
  return {
    type: actions.GET_GROUP,
    payload: { groupId }
  }
}

export const getGroupsForGroupIds = (groupIds) => {
  return {
    type: actions.GET_GROUPS_FOR_IDS,
    payload: { groupIds }
  }
}
export const setGroupLoading = (boolValue) => {
  return {
    type: actions.SET_LOADING,
    payload: { boolValue }
  }
}


export const updateGroupsForIds = (groups) => {
  return {
    type: actions.UPDATE_GROUPS_FOR_IDS,
    payload: { groups }
  }
}

export const saveArchiveGroup = (group) => {
  return {
    type: actions.SAVE_ARCHIVE_GROUP,
    payload: { group }
  }
}

export const getArchiveGroupsForSchoolId = (schoolId) => {
  return {
    type: actions.GET_ALL_ARCHIVE_GROUPS_FOR_SCHOOL_ID,
    payload: { schoolId }
  }
}
