import React, { useEffect, useState } from 'react';
import {
    Container, Card, CardBody, Row, Col, Table,
    UncontrolledTooltip, Label, Button,
    FormGroup, Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
} from "reactstrap";
import toastr from 'toastr';
import * as Constants from "../../../Constents";
import Switch from "react-switch";
import Image from "../../../assets/images/book_lover.svg";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { matchPath } from "react-router"
import { Link } from "react-router-dom";
import Moment from 'moment';
import DocViewer, { DocViewerRenderers, BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer, } from "react-doc-viewer";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import Parse from 'parse';
import Iframe from "react-iframe";
import * as CommonFunctions from "../../../components/Common/CommonFunctions";
import {
    getAllKids,
    addSubscriptionsToCourse,
} from "../../../store/actions";
import TopBar from '../Topbar';

const CourseRequests = (props) => {

    const Offsymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    const OnSymbol = props => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2
                }}
            >

            </div>
        );
    };

    const schoolId = props.schoolId ?? "";
    const kidId = props.kidId ?? "";



    const [showModel, setShowModel] = useState(false);
    const [allRequesters, setAllRequesters] = useState([]);
    const [clickedFile, setClickFile] = useState(undefined);
    const [remarksText, setRemarksText] = useState("")
    const [showModal, setShowModal] = useState(0)
    const [disableApproveButton, setDisableApproveButton] = useState(false)
    const [allowToResubmit, setAllowToResubmit] = useState(true);
    const [selectedRequestListObject, setSelectedRequestListObject] = useState(undefined);

    useEffect(() => {
        //props.getAllCourseRequestsForKidId(kidId);
        props.getAllKids(props.schoolId)
    }, [props.kidId, props.schoolId]);

    useEffect(() => {

        var tempMainList = []
        let recommendCoursesResults = props.courseRequests
        for (const index in recommendCoursesResults) {

            let kidObjects = props.kids.filter(
                (kid) => recommendCoursesResults[index].attributes.kidId == kid.id
            );
            let courseObjects = props.allCourses.filter(
                (course) => recommendCoursesResults[index].attributes.courseId == course.id
            );

            let tempObject = {
                id: recommendCoursesResults[index].id,
                status: recommendCoursesResults[index].attributes.status,
                courseId: recommendCoursesResults[index].attributes.courseId,
                referenceDocument: recommendCoursesResults[index].attributes.referenceDocument,
                note: recommendCoursesResults[index].attributes.note,
                recommendParseObject: recommendCoursesResults[index],
                remarks: recommendCoursesResults[index].attributes.remarks
            }


            if (kidObjects.length > 0) {
                tempObject.kid = kidObjects[0]
                tempObject.kidParseObject = kidObjects[0]
            }
            if (courseObjects.length > 0) {
                tempObject.courseParseObject = courseObjects[0]
                tempObject.courseName = courseObjects[0].attributes.name
                tempObject.courseImage = courseObjects[0].attributes.introImage ?? {}
            }

            tempMainList.push(tempObject)
        }
        setAllRequesters(tempMainList)

    }, [props.courseRequests, props.kids, props.allCourses]);

    const handleSaveCourseSubscription = (requestId, kidId, courseId) => {

        let newsub = {};
        newsub.kidId = kidId;
        newsub.kidIds = [kidId];
        newsub.courseId = courseId;
        newsub.requestId = requestId;
        newsub.subscribedDate = new Date();
        newsub.mode = "Sponsored User"
        props.addSubscriptionsToCourse([newsub], true);
    }

    useEffect(() => {
        if (props.addState === false) {
            setRemarksText("")
            if (showModal === 1) {
                toastr.success("Successfully Approved !!")

            } else if (showModal === 2) {
                toastr.danger("Successfully Rejected !!")

            }
            setShowModal(0)
        }
    }, [props.addState]);


    const editListObject = (index, parseObject) => {
        let tempList = [...allRequesters]
        tempList[index].recommendParseObject = parseObject
        tempList[index].status = parseObject.attributes.status
        tempList[index].remarks = parseObject.attributes.remarks

        setAllRequesters(tempList)
    };


    const handleApprove = () => {

        if (selectedRequestListObject) {
            let selectedCourseRequest = selectedRequestListObject.recommendParseObject

            selectedCourseRequest.set(
                "status",
                Constants.CSR_APPROVED
            );
            selectedCourseRequest.set(
                "remarks",
                remarksText
            );

            selectedCourseRequest.set("approvedOn", new Date())

            if (Parse.User.current().attributes.teacherId) {
                selectedCourseRequest.set("approvedBy", Parse.User.current().attributes.teacherId)
            } else {
                selectedCourseRequest.set("approvedBy", Parse.User.current().id)
            }

            selectedCourseRequest.save().then(
                (result) => {

                    handleSaveCourseSubscription(result.id, result.attributes.kidId, result.attributes.courseId)
                    let index = allRequesters.indexOf(selectedRequestListObject)
                    editListObject(index, result)
                    setShowModal(0)


                },
                (error) => {
                    console.log(error)
                    setShowModal(0)
                }
            );

        } else {
            toastr.warning("please try again later")
        }

    }

    const handleReject = () => {

        if (selectedRequestListObject) {
            let selectedCourseRequest = selectedRequestListObject.recommendParseObject

            selectedCourseRequest.set(
                "status",
                Constants.CSR_REJECTED
            );
            selectedCourseRequest.set(
                "remarks",
                remarksText
            );
            selectedCourseRequest.set(
                "resubmitAllowed",
                allowToResubmit
            );

            selectedCourseRequest.set("rejectedOn", new Date())

            if (Parse.User.current().attributes.teacherId) {
                selectedCourseRequest.set("rejectedBy", Parse.User.current().attributes.teacherId)
            } else {
                selectedCourseRequest.set("rejectedBy", Parse.User.current().id)
            }


            selectedCourseRequest.save().then(
                (result) => {

                    let index = allRequesters.indexOf(selectedRequestListObject)
                    editListObject(index, result)
                    setShowModal(0)

                },
                (error) => {
                    console.log(error)
                    setShowModal(0)
                }
            );

        } else {
            toastr.warning("please try again later")
        }


    }


    const getFR = (url) => {
        const docs = [
            { uri: url },
        ];
        return docs;
    }

    function getURL() {

        if (clickedFile) {

            return clickedFile._url;

        }
    }

    const handelOnClickBack = () => {
        window.history.back();
    }

    function getTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY, hh:mm a');
    }


    const getUIBasedOnStatus = (requester) => {
        let status = requester.status
        switch (status) {
            case Constants.CSR_SUBMITTED:
            case Constants.CSR_RESUBMITTED:
                return (<><button
                    className="btn btn-success btn-sm mt-2 float-right mr-3"
                    onClick={() => {
                        setRemarksText(requester.remarks ?? "")
                        setAllowToResubmit(true)
                        setSelectedRequestListObject(requester)
                        setShowModal(1)
                    }}>
                    Approve
                </button>
                    <button
                        className="btn btn-danger btn-sm ml-2 mt-2 float-right mr-3"
                        onClick={() => {
                            setRemarksText(requester.remarks ?? "")
                            setAllowToResubmit(true)
                            setSelectedRequestListObject(requester)
                            setShowModal(2)
                        }}>
                        Reject
                    </button></>)
            case Constants.CSR_APPROVED:

                return (
                    <>
                        <div>
                            <p className="text-success  mr-3 mt-3" style={{ textAlign: "right" }}>Approved</p>
                        </div>
                        {requester && requester.remarks && <div><p className="mr-3" style={{ textAlign: "right" }}>{requester.remarks}</p></div>}
                    </>
                )


            case Constants.CSR_REJECTED:
                return (
                    <>
                        <div>
                            <p className="text-danger  mr-3 mt-3" style={{ textAlign: "right" }}>Rejected</p>
                        </div>
                        {requester && requester.remarks && <div><p className="mr-3" style={{ textAlign: "right" }}>{requester.remarks}</p></div>}
                    </>
                )

            default:

                break;
        }
    }

    const handleEnableDisableToResubmitRequest = () => {
        setAllowToResubmit(!allowToResubmit)
    }

    return (
        <React.Fragment>
            <div className="">
                <Container>



                    <>
                        {allRequesters.length > 0 && <h5 className="mt-3 ml-1">Requested Courses</h5>}


                        <Row >
                            <Col>

                                {allRequesters.length > 0 && <div className="table-responsive bg-white shadow mt-1">
                                    <Table className="table-center">
                                        <thead>
                                            <tr>
                                                <th className="" style={{ width: "70px" }}>#</th>
                                                <th className="" >Name</th>
                                                <th className="" >Document/Comments</th>
                                                <th className="text-right">Remarks</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {allRequesters.map((requester, key) =>
                                                <tr key={key} >
                                                    {requester.kid && <td>
                                                        {
                                                            !requester.courseImage
                                                                ? <div className="avatar-xs">
                                                                    <span className="avatar-title rounded-circle">
                                                                        {requester.courseName ? requester.courseName.toUpperCase().charAt(0) : " "}
                                                                    </span>
                                                                </div>
                                                                : <div>
                                                                    <img className="rounded-circle avatar-xs" src={requester.courseImage} alt="" />
                                                                </div>
                                                        }
                                                    </td>}

                                                    {requester.kid && <td
                                                        onClick={() => {

                                                        }}>
                                                        <h5 className="text-truncate font-size-14  mb-1 text-dark">{requester.courseName}</h5>
                                                        <p className="text-muted mb-0">{"Requested on: " + getTimeStringForDate(requester.recommendParseObject.createdAt)}</p>



                                                    </td>}
                                                    {requester.referenceDocument && <td
                                                    >



                                                        <h5 className="text-truncate font-size-14  mb-1 text-primary"
                                                            style={{ cursor: "pointer" }}

                                                            onClick={() => {
                                                                setShowModel(true)
                                                                setClickFile(requester.referenceDocument)
                                                            }}><u>Recommendation Letter</u></h5>


                                                        <p className="text-muted pt-0  mt-0 mb-0">{requester.note}</p>



                                                    </td>}
                                                    {requester && requester.kid && <td>

                                                        {getUIBasedOnStatus(requester)}

                                                    </td>}

                                                </tr>
                                            )}
                                        </tbody>
                                    </Table></div>}


                            </Col>
                        </Row>

                        {
                            allRequesters.length === 0 && !props.initialLoding && props.selectedCourse &&
                            <Card>
                                <CardBody className="text-center" >

                                    <h6 className="mt-5">{

                                        props.t('There are no requests to show ')

                                    }</h6>

                                </CardBody>
                            </Card>
                        }

                        {
                            props.initialLoding && <Col xs="12">
                                <div className="text-center my-3">
                                    <Label className="text-success">
                                        <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading
                                    </Label>
                                </div>

                            </Col>
                        }
                    </>
                </Container>
            </div>

            <Modal
                size="xl"
                isOpen={showModel}
                toggle={() => { setShowModel(!showModel) }}
                scrollable={true}
            >
                <div className="modal-header">



                    <h5
                        className="modal-title mt-0"
                        id="myLargeModalLabel"
                    >
                        {/* {clickedFile && getFileName(clickedFile)} */}

                        Recommendation Letter
                    </h5>


                    {/* {
                        clickedFile && clickedFile.attributes.fileType !== "url" && (clickedFile.attributes.downloadable === undefined ||
                            clickedFile.attributes.downloadable === null ||
                            clickedFile.attributes.downloadable === true) && <a

                                href={getURL()}
                                download={getFileName()}

                                target="_blank"
                                type="button"
                                className="btn btn-sm btn-primary waves-effect waves-light ml-4">
                            <i className="bx bx-download font-size-14 align-middle"></i> {props.t('Download ')}
                        </a>
                    } */}
                    <button
                        onClick={() => {
                            setShowModel(false)
                            setClickFile(undefined);
                        }}
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                    >
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <ModalBody >
                    {

                        <div>
                            {getURL() && getURL().endsWith(".pdf") ? <Iframe
                                url={getURL() + "#toolbar=0"}
                                height="600"
                                width="100%"
                                display="initial"
                                allowFullScreen={true}
                                position="relative"
                                frameBorder={0}

                            >
                            </Iframe> :

                                <DocViewer
                                    pluginRenderers={[BMPRenderer, HTMLRenderer, ImageProxyRenderer, JPGRenderer, MSDocRenderer, MSGRenderer, PDFRenderer, PNGRenderer, TIFFRenderer, TXTRenderer,]}
                                    style={{ height: "500px" }}
                                    documents={getFR(getURL())}
                                    config={{
                                        header: {
                                            disableHeader: true,
                                            disableFileName: false,
                                            retainURLParams: false
                                        }
                                    }}

                                />
                            }
                        </div>

                    }

                    <Card style={{ backgroundColor: "#fff", marginTop: "-7mm", width: "100%", height: " 7mm" }} />




                </ModalBody>
            </Modal>
            {
                <Modal isOpen={showModal === 1 || showModal === 2 ? true : false}>


                    <ModalHeader>

                        <Label className="modal-title mt-0" id="myModalLabel">
                            Remarks
                        </Label>
                    </ModalHeader>
                    <ModalBody >



                        <FormGroup className="position-relative mt-2">
                            <textarea
                                name="comments"
                                id="comments"
                                rows="3"
                                className="form-control"
                                placeholder="Enter remarks"
                                value={remarksText}
                                onChange={(e) => { setRemarksText(e.target.value) }}
                            ></textarea>
                        </FormGroup>

                        {showModal === 2 && <div className="d-flex mr-4 mt-3 " id={"switchForAllowToResubmit"} >


                            <Label className="mb-1 align-middle">Allow student to resubmit the request </Label>
                            <Switch
                                height={15}
                                width={25}
                                className="float-right pl-2 pr-2"
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor={"#6add7d"}
                                onChange={() => {
                                    handleEnableDisableToResubmitRequest()
                                }}
                                checked={allowToResubmit}
                            />
                            <UncontrolledTooltip placement="top" target={"switchForAllowToResubmit"}>
                                {props.t('Disable/Enable')}
                            </UncontrolledTooltip>
                        </div>}

                    </ModalBody>

                    <ModalFooter>
                        <Row className="text-center">
                            <button
                                type="button"
                                onClick={() => {
                                    setShowModal(0)
                                }}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                {props.t('Cancel')}
                            </button>
                            <button
                                className={showModal === 1 ? "btn btn-success waves-effect waves-light ml-2" : "btn btn-danger waves-effect waves-light ml-2"}
                                onClick={() => {
                                    setDisableApproveButton(true)
                                    if (!disableApproveButton) {
                                        if (showModal === 1) {
                                            handleApprove()
                                        } else if (showModal === 2) {
                                            handleReject()
                                        }
                                    }
                                }}
                            >
                                {(showModal !== 0 && showModal === 1) ? "Approve" : ""}
                                {(showModal !== 0 && showModal === 2) ? "Reject" : ""}
                            </button>

                        </Row>




                    </ModalFooter>
                </Modal>
            }



        </React.Fragment >
    );
}

const mapStatetoProps = (state) => {
    const { userTeacher } = state.Login;
    const { selectedSchool } = state.School;
    const { courses, selectedCourse } = state.Course;
    const { addState } = state.CourseSubscription;

    const { teachers } = state.Teacher;
    const { kids } = state.Kid;
    return { userTeacher, selectedSchool, addState, courses, teachers, selectedCourse, kids };
};


export default connect(mapStatetoProps, {
    getAllKids,
    addSubscriptionsToCourse,
})(withNamespaces()(CourseRequests));
