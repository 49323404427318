import * as actions from './actionTypes';

const initialState = {
    notifaction: {}
}
const notifaction = (state = initialState, action) =>{
    switch (action.type) {
        case actions.SET_NOTIFACTIONS_FOR_USER :
            state = {...state, notifaction : action.payload.notifactionObj}
            break;
        default:
            state = { ...state };
            break;
    }
    return state;
}
export default notifaction