import { takeEvery, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as MTDhelper from "../../../helpers/ParseHelpers/moneyTransactionDetails_helper";
import * as actionTypes from "./actionTypes";
import * as MTDActions from "./actions";
import * as Constants from "../../../Constents";

function* getAllMTDForIds({ payload : { ids }}){
    try{
        const response = yield call(MTDhelper.getAllMTDForIds, ids)
        yield put(MTDActions.setMTDForIds(response))
    }catch(err){
        console.log(err)
    }
}

export function* watchGetAllMTDForIds(){
    yield takeEvery(actionTypes.GET_ALL_TRIANSACTION_FOR_ITEM_IDS, getAllMTDForIds)
}

export default function* moneyTransaction() {
    yield all([
        fork(watchGetAllMTDForIds)
    ])
}