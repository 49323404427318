import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { Table, Col, Row, Progress, Button, UncontrolledTooltip } from "reactstrap";
import { withNamespaces } from "react-i18next";
import Editable from "react-bootstrap-editable";
import UpdatePhoto from '../../components/Common/UpdatePhoto';
import Switch from "react-switch";

import { editSchool } from '../../store/actions';



const OtherInfo = (props) => {
    const handleChange = (type, value) => {
        var temp = props.selectedSchool;

        if (type === 1) {
            temp.set("admissionNumberFormat", value);
            setAdmissionNumberFormat(value);
        } else if (type === 2) {
            temp.set("admissionRunningNumber", parseInt(value));
            setAdmissionRunningNumber(parseInt(value));
        } else if (type === 3) {
            temp.set("employeeCodeFormat", value);
            setEmployeeCodeFormat(value);
        } else if (type === 4) {
            temp.set("employeeCodeRunningNumber", parseInt(value));
            setEmployeeCodeRunningNumber(parseInt(value));
        }
        else if (type === 5) {
            temp.set("GSTIN", value);
            setGst(value);
        }
        else if (type === 6) {
            temp.set("pan", value);
            setPan(value);
        }
        else if (type === 7) {
            temp.set("cin", value);
            setCin(value);
        }
        else if (type === 8) {
            temp.set("invoiceFooter", value);
            setFooter(value);
        } else if (type === 9) {
            temp.set("resetInvoiceNumberForAY", value);
            setResetInvoiceNumberForAY(value);
        }else if(type === 10) {
            temp.set("registrationNumber", value);
            setRegistrationNumber(value);
        }

        props.editSchool(temp);
    }


    const [admissionNumberFormat, setAdmissionNumberFormat] = useState("");
    const [admissionRunningNumber, setAdmissionRunningNumber] = useState(0);

    const [employeeCodeFormat, setEmployeeCodeFormat] = useState("");
    const [employeeCodeRunningNumber, setEmployeeCodeRunningNumber] = useState(0);

    const [gst, setGst] = useState("");
    const [pan, setPan] = useState("");
    const [cin, setCin] = useState("");

    const [footer, setFooter] = useState("");

    const [letterHeadLogo, setLetterHeadLogo] = useState();
    const [letterHeadLogoUploadProgress, setLetterHeadLogoUploadProgress] = useState(0);

    const [resetInvoiceNumberForAY, setResetInvoiceNumberForAY] = useState(false);
    const [registrationNumber, setRegistrationNumber] = useState("");


    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                No
            </div>
        );
    };

    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                Yes
            </div>
        );
    };


    useEffect(() => {
        if (props.selectedSchool && props.selectedSchool.id === props.schoolId) {
            setAdmissionNumberFormat(props.selectedSchool.attributes.admissionNumberFormat);
            setAdmissionRunningNumber(props.selectedSchool.attributes.admissionRunningNumber);

            setEmployeeCodeFormat(props.selectedSchool.attributes.employeeCodeFormat);
            setEmployeeCodeRunningNumber(props.selectedSchool.attributes.employeeCodeRunningNumber);

            setGst(props.selectedSchool.attributes.GSTIN);
            setPan(props.selectedSchool.attributes.pan);
            setCin(props.selectedSchool.attributes.cin);
            setFooter(props.selectedSchool.attributes.invoiceFooter)
            setResetInvoiceNumberForAY(props.selectedSchool.attributes.resetInvoiceNumberForAY?true:false)
            setRegistrationNumber(props.selectedSchool.attributes.registrationNumber);
            if (props.selectedSchool.attributes.letterheadLogo) {
                setLetterHeadLogo({ imageURL: props.selectedSchool.attributes.letterheadLogo._url, actualImageName: "logo.jpeg" })
            }
        }
    }, [props.selectedSchool, props.schoolId]);

    function handleImageUploadChange(changedImageURL, imageFile,) {

        setLetterHeadLogo(undefined)
        setLetterHeadLogoUploadProgress(1)
        let fileExtension = imageFile._name.split(".");
        let tempFile = {
            actualImageName: "logo.jpeg",
            fileExtension: fileExtension[1].toLowerCase(),
            imageName: fileExtension[0],
            image: imageFile,
            imageURL: undefined,
        };
        tempFile.image
            .save({
                progress: (value) => {
                    if (value && value > 0.1) {
                        setLetterHeadLogoUploadProgress(value * 100)
                    }
                },
            })
            .then(
                (result) => {
                    if (props.selectedSchool) {
                        props.selectedSchool.set("letterheadLogo", result)
                        props.selectedSchool.save().then(
                            (schoolResult) => {
                                console.log(result)
                                console.log(schoolResult)
                                tempFile.imageURL = result._url;
                                tempFile.image = result;
                                setLetterHeadLogo(tempFile)
                            },
                            (error) => {

                            }
                        );
                    }
                },
                (error) => {
                }
            );
        setLetterHeadLogo({ actualImageName: tempFile.actualImageName, imageURL: changedImageURL })
    }
    console.log(props.selectedSchool)
    // console.log(letterHeadLogo.imageURL)
    // console.log(letterHeadLogo.actualImageName)
    return (<React.Fragment>

        <h5 className="mt-3" >Admission number setup</h5>
        <div className="table-responsive m-2">
            <Table className="table mb-0 table-bordered">
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            Admission Number format
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onSubmit={(value) => { handleChange(1, value) }}
                                disabled={false}
                                editText={admissionNumberFormat ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={admissionNumberFormat}
                                showText
                                type="textfield"
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            Admission Running Number
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onValidated={(value) => { handleChange(2, value) }}
                                disabled={false}
                                editText={admissionRunningNumber ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={admissionRunningNumber}
                                validate={(value) => {
                                    if (isNaN(value)) {
                                        return props.t(
                                            "Please enter a number"
                                        );
                                    }
                                }}
                                showText
                                type="textfield"
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
        <hr />
        <h5 className="mt-3" >Employee Code setup</h5>
        <div className="table-responsive m-2">
            <Table className="table mb-0 table-bordered">
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            Employee Code format
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onSubmit={(value) => { handleChange(3, value) }}
                                disabled={false}
                                editText={employeeCodeFormat ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={employeeCodeFormat}
                                showText
                                type="textfield"
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            Employee Code Running Number
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onValidated={(value) => { handleChange(4, value) }}
                                disabled={false}
                                editText={employeeCodeRunningNumber ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={employeeCodeRunningNumber}
                                validate={(value) => {
                                    if (isNaN(value)) {
                                        return props.t(
                                            "Please enter a number"
                                        );
                                    }
                                }}
                                showText
                                type="textfield"
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
        <hr />
        <h5 className="mt-3" >Finance details</h5>
        <p className='text-danger'>Below mentioned details will be printed on FEE RECEIPT</p>
        <div className="table-responsive m-2">
            <Table className="table mb-0 table-bordered">
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            GST
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onSubmit={(value) => { handleChange(5, value) }}
                                disabled={false}
                                editText={gst ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={gst}
                                showText
                                type="textfield"
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            PAN
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onSubmit={(value) => { handleChange(6, value) }}
                                disabled={false}
                                editText={pan ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={pan}
                                showText
                                type="textfield"
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            CIN
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onSubmit={(value) => { handleChange(7, value) }}
                                disabled={false}
                                editText={cin ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={cin}
                                showText
                                type="textfield"
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            Registration Number
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onSubmit={(value) => { handleChange(10, value) }}
                                disabled={false}
                                editText={registrationNumber ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={registrationNumber}
                                showText
                                type="textfield"
                            />
                        </td>
                    </tr>
                </tbody>
                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            Invoice Receipt Footer
                        </th>
                        <td>
                            <Editable
                                className={'d-flex'}
                                alwaysEditing={false}
                                onSubmit={(value) => { handleChange(8, value) }}
                                disabled={false}
                                editText={footer ? " ✎" : "No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={footer}
                                showText
                                type="textarea"
                                rows='3'
                            />
                        </td>
                    </tr>
                </tbody>

                <tbody>
                    <tr>
                        <th scope="row" style={{ width: "300px" }}>
                            Reset invoice number every academic year.
                            <span><i className="fas fa-question-circle m-1" id={"invoice_number_setting"}></i>
                                <UncontrolledTooltip placement="top" target={"invoice_number_setting"}>
                                Once this setting is activated, creating an invoice for the academic year 2024-25 and beyond will trigger the system to automatically generate invoice numbers starting from 1.
                                </UncontrolledTooltip>
                            </span>
                        </th>
                        <td>
                            <Switch
                                uncheckedIcon={<Offsymbol />}
                                checkedIcon={<OnSymbol />}
                                onColor="#626ed4"
                                onChange={(e) => {
                                    if(resetInvoiceNumberForAY){
                                        handleChange(9, false)
                                    }else{
                                        handleChange(9, true)
                                    }
                                }}
                                checked={resetInvoiceNumberForAY}
                                
                            />
                        </td>
                    </tr>
                </tbody>
            </Table>
        </div>
     <h5 className="mt-3" >Letter head logo</h5>
        <p className='text-danger'>Below mentioned details will be printed on FEE RECEIPT</p>
        {<Row>
            <Col>
                {letterHeadLogo &&
                    letterHeadLogoUploadProgress >= 1 &&
                    letterHeadLogoUploadProgress < 100 && (
                        <Col xl="12" md="12" sm="12" xs="12">
                            <div className="border p-3">
                                <Row>
                                    <div
                                        className="avatar ml-3"
                                        style={{ width: "5rem", height: "5rem" }}
                                    >
                                        <span
                                            className={
                                                "avatar-title rounded-circle bg-soft-primary text-primary"
                                            }
                                            style={{ fontSize: "60px" }}
                                        ></span>
                                    </div>
                                    <div
                                        className="float-right ml-4"
                                        style={{ width: "200px" }}
                                    >
                                        <div className="text-muted font-weight-bold">
                                            {letterHeadLogo.actualImageName}
                                        </div>
                                        <div className=" mt-3">
                                            <Progress

                                                color="primary"
                                                value={letterHeadLogoUploadProgress}
                                            ></Progress>
                                        </div>
                                    </div>
                                </Row>
                            </div>
                        </Col>
                    )}
                {letterHeadLogo == undefined ? (
                    <div className="d-flex">
                        <div>
                            {" "}
                            <UpdatePhoto
                                isRectangle={true}
                                buttonTitle={"Upload Custom Logo"}
                                buttonTitleInitial={"Upload Custom Logo"}
                                id={"letterHederCustomImage"}
                                tempId={"letterHederCustomImage"}
                                className="p-1"
                                isPopupOn={false}
                                onPicChange={(imageFile, url) => {
                                    handleImageUploadChange(url,
                                        imageFile,
                                    );
                                }}
                            ></UpdatePhoto>
                        </div>
                    </div>
                ) :
                    (
                        <Col xl="12" md="12" sm="12" xs="12">
                            <div className="border p-3">
                                <Row>
                                    <Col>
                                        <div className="ml-3">
                                            <img
                                                style={{ width: "12rem", height: "4rem" }}
                                                className="avatar"
                                                src={letterHeadLogo.imageURL}
                                                alt=""
                                            />
                                        </div>
                                    </Col>
                                    <Col lg={9}>
                                        <div
                                            className="align-items-end ml-4"
                                            style={{ width: "200px" }}
                                        >
                                            <div className="text-muted font-weight-bold" style={{ fontWeight: 'bold' }}>
                                                {letterHeadLogo.actualImageName}
                                            </div>
                                            <div className="d-flex mt-3">
                                                <UpdatePhoto
                                                    isRectangle={true}
                                                    id={"letterHederCustom"}
                                                    tempId={"letterHederCustom"}
                                                    className="p-1"
                                                    buttonTitle="Change"
                                                    isPopupOn={false}
                                                    onPicChange={(imageFile, url) => {
                                                        handleImageUploadChange(
                                                            url,
                                                            imageFile,
                                                        );
                                                    }}
                                                    imageFileName={
                                                        letterHeadLogo
                                                            ? letterHeadLogo.actualImageName
                                                            : ""
                                                    }
                                                    initialImage={
                                                        letterHeadLogo.imageURL
                                                    }
                                                ></UpdatePhoto>
                                                <Button
                                                    style={{ marginLeft: '5px' }}
                                                    className="btn-danger ml-2"
                                                    onClick={() => {
                                                        setLetterHeadLogo(undefined)
                                                        setLetterHeadLogoUploadProgress(0)
                                                        if (props.selectedSchool) {
                                                            props.selectedSchool.unset("letterheadLogo")
                                                            props.selectedSchool.save().then(
                                                                (result) => {

                                                                },
                                                                (error) => {

                                                                }
                                                            );
                                                        }
                                                    }}
                                                >
                                                    Clear
                                                </Button>
                                            </div>
                                        </div>
                                    </Col>
                                </Row>
                            </div>
                        </Col>
                    )}
            </Col>
        </Row>}

    </React.Fragment>
    );
}
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
}
export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    editSchool
})(OtherInfo)));