import React, { useState, useEffect } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { matchPath } from "react-router";
import { Label, FormGroup, Row, Col, Card, CardBody, UncontrolledTooltip, Table, Form, Button, ModalBody, ModalFooter, ModalHeader, Modal, CardSubtitle } from "reactstrap";
import { AvForm, AvField, } from "availity-reactstrap-validation";
import Parse from 'parse';
import TopBar from './Topbar';
import { withNamespaces } from "react-i18next";
import { Element, animateScroll as scroller } from 'react-scroll'
import SweetAlert from "react-bootstrap-sweetalert";
import contentImage from "../../assets/images/undraw_typewriter.svg";
import img1 from "../../assets/images/lms/book.png";
import * as CommonFunctions from "../../components/Common/CommonFunctions";
import {
    setSelecetdSchool, getLearningCategoryForCycleId, addLearningCategory, deleteLearningCategory, getLearningCategoryForCurriculumId, setSelectedCurriculum, reorderCatrgories,
    getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId,
} from '../../store/actions';
import DragDropTables from './DragDropTables';
const CategoryList = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: '/lms-subject/:schoolId/:curriculumId/:cycleId',
        exact: true,
        strict: false,

    });

    const curriculumId = match.params.curriculumId;
    const schoolId = match.params.schoolId;
    const cycleId = match.params.cycleId;

    useEffect(() => {
        if ((!props.selectedSchool || props.selectedSchool.id !== schoolId) && schoolId !== "franchise") {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (!props.selectedCurriculum || props.selectedCurriculum.id !== curriculumId) {
            props.setSelectedCurriculum(curriculumId);
        }
    }, [curriculumId]);

    const [canEdit, setCanEdit] = useState(false);

    useEffect(() => {
        setCanEdit(CommonFunctions.canEdit(props.selectedCurriculum, props.userTeacher));
    }, [props.selectedCurriculum, props.userTeacher]);


    useEffect(() => {
        if (cycleId === "CommonSubjects") {
            props.getLearningCategoryForCurriculumId(curriculumId);
        } else {
            props.getLearningCategoryForCycleId(cycleId);
        }
    }, [cycleId]);

    const [selectedTab, setSelectedTab] = useState("categoryList");

    const [newSubject, setNewSubject] = useState(undefined);
    const [showNewSubject, setShowNewSubject] = useState(false);
    const [nameError, setNameError] = useState(false);

    const [editCategory, setEditCategory] = useState(undefined);
    const [deleteCategory, setDeleteCategory] = useState(undefined);;
    const [success_dlg, setsuccess_dlg] = useState(false);

    const [hideButton, setHideButton] = useState(true);

    const [classModal, toggleClassModal] = useState(false);
    const [selectedCycle, setSelectedCycle] = useState(undefined);
    const [classSelcetWarning, setClassSelcetWarning] = useState(false);

    const [loading, setLoading] = useState(false);

    const [displayLearningCategory, setDisplayLearningCategory] = useState([])

    function handelAddSubject() {
        setShowNewSubject(true);
        setLoading(false);
        scroller.scrollTo('AddSubject', {
            duration: 500,
            delay: 100,
            smooth: true,
            offset: 0,

        })
    }
    useEffect(() => {
        if (props.learningCategories && props.learningCategories.length > 0) {
            setHideButton(false)
        }
    }, [props.learningCategories]);

    function handelOnClickBack() {
        // props.onClickBack();
        if (selectedTab === "rearrange") {
            setSelectedTab("categoryList");
            setHideButton(false);
        } else {
            window.history.back();
        }

    }
    function handelEditCategory(learningCategory) {
        props.addLearningCategory(learningCategory);
    }
    function handelDeleteCategory(learningCategory) {
        props.deleteLearningCategory(learningCategory);
    }
    function handelAddCategory(name) {
        var LearningCategory = Parse.Object.extend("LearningCategory");
        var learningCategory = new LearningCategory();
        learningCategory.set("name", name);
        learningCategory.set("curriculumId", curriculumId)
        if (cycleId && cycleId !== "CommonSubjects") {
            learningCategory.set("cycleId", cycleId);
        }
        learningCategory.set("order", props.learningCategories && props.learningCategories.length > 0 ? props.learningCategories.length : 0)
        props.addLearningCategory(learningCategory);
        setNewSubject("");
        setLoading(false);
        setShowNewSubject(false);
    }

    function onClickItem(subjectId) {
        props.history.push("/lms-chapter/" + schoolId + "/" + curriculumId + "/" + cycleId + "/" + subjectId);
    }

    const handelSort = (list) => {

        var tempList = [];
        for (var i in list) {
            // if (parseInt(i) !== list[i].attributes.order) {
            list[i].set("order", parseInt(i));
            tempList.push(list[i]);
            // }  
        }
        props.reorderCatrgories(tempList, "order updated successfully");
        setSelectedTab("categoryList");
    }
    const handelReorder = () => {
        setSelectedTab("rearrange")
        setHideButton(true);
    }

    useEffect(() => {
        if (schoolId != "franchise" && schoolId && curriculumId) {
            props.getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId(schoolId, curriculumId);
        }
    }, [schoolId, curriculumId])

    useEffect(() => {
        // console.log(props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId)
        if (schoolId == "franchise" || true) {
            setDisplayLearningCategory(props.learningCategories);
        } else if (props.selectedCurriculum && props.selectedCurriculum.attributes.accessBased) {
            let tempList = []
            for (const learningCategory of props.learningCategories) {
                if (props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId && props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId.attributes.learningCategoryIds.includes(learningCategory.id)) {
                    //tempList.push(learningCategory)
                } else {
                    tempList.push(learningCategory)
                }
            }
            setDisplayLearningCategory(tempList)
        } else {
            setDisplayLearningCategory(props.learningCategories);
        }

    }, [props.learningCategories, props.learningCurriculumsSchoolMapForCurriculumIdAndSchoolId, props.selectedCurriculum])

    return (
        <React.Fragment >
            <div className="page-content">

                <TopBar
                    title={props.selectedCycle && props.selectedCycle.attributes ? props.selectedCycle.attributes.schoolCycle + " " + props.t('Subjects') : props.t('Subjects')}
                    buttonName={props.t("Add New Subject")}
                    hideButton={canEdit ? hideButton : true}
                    backButton={true}
                    onClickAdd={handelAddSubject}
                    onClickBackButton={handelOnClickBack}
                    reorderButton={canEdit ? true : false}
                    onClickReorder={handelReorder}
                />

                {selectedTab === "rearrange" && <DragDropTables rearrangeObjects={props.learningCategories} updatedOrderList={handelSort} onClickClose={handelOnClickBack} />}

                {selectedTab === "categoryList" && props.learningCategories && props.learningCategories.length > 0 &&
                    < Row >
                        <Col lg="12">
                            <div className="">
                                <div className="table-responsive">

                                    <Table className="project-list-table table-nowrap table-centered table-borderless">
                                        <thead>
                                            <tr>
                                                <th className="col-1" scope="col" style={{ width: "100px" }}>#</th>
                                                <th className="col-9" scope="col">{props.t('Subject')}</th>
                                                {schoolId == "franchise" && false && <th className="col-9" scope="col">{props.t('Access')}</th>}
                                                <th className="col-2" scope="col" align="right">{props.t('Action')}</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {displayLearningCategory.map((category, key) =>

                                                <tr key={category.id} >

                                                    <td><img src={img1} alt="" className="avatar-sm" /></td>



                                                    {
                                                        editCategory && editCategory.id === category.id ?
                                                            <td>
                                                                <AvForm className="outer-repeater mt-3" >
                                                                    <div data-repeater-item className="outer">
                                                                        <FormGroup >
                                                                            <AvField
                                                                                type="text"
                                                                                name="name"
                                                                                placeholder={props.t('Category Name')}
                                                                                className="form-control"
                                                                                value={newSubject}

                                                                                onChange={(e) => {
                                                                                    if (e.target.value.length > 0 && nameError) {
                                                                                        setNameError(false);
                                                                                    }
                                                                                    setNewSubject(e.target.value);
                                                                                }}
                                                                            />
                                                                            {nameError && <p className="text-danger"> {props.t('Category name can not be empty')}</p>}
                                                                        </FormGroup>
                                                                    </div>
                                                                </AvForm>
                                                            </td>
                                                            :
                                                            <td
                                                                style={{ cursor: "pointer" }}
                                                                onClick={() => {
                                                                    // props.onSelectCategory(category);
                                                                    // props.onClicItem("SubcategoryList");
                                                                    onClickItem(category.id)
                                                                }}>
                                                                <h5 className="text-truncate font-size-14 text-dark">{category.attributes.name}</h5>
                                                                <p className="text-muted mb-0">{category.description}</p>

                                                            </td>
                                                    }
                                                    {schoolId == "franchise" && false && <td>
                                                        {props.selectedCurriculum && props.selectedCurriculum.attributes.accessBased === true && (selectedTab === "categoryList") && <Link to={`/learning-category-access/${Parse.User.current().attributes.franchiseId}/${curriculumId}/${cycleId}/${category.id}`}>
                                                            <div className="d-flex" >
                                                                <button className='btn btn-success btn-sm btn-rounded' >
                                                                    Access
                                                                </button>
                                                            </div>
                                                        </Link>}
                                                    </td>}
                                                    <td>
                                                        {
                                                            editCategory && editCategory.id === category.id ?
                                                                <div className="list-inline font-size-20 contact-links mb-0">
                                                                    <button
                                                                        type="button"
                                                                        className="btn btn-primary m-1 "
                                                                        onClick={() => {
                                                                            if (newSubject && newSubject.length > 0) {
                                                                                var cur = editCategory;
                                                                                cur.set("name", newSubject);
                                                                                handelEditCategory(cur);
                                                                                setEditCategory(undefined);
                                                                                setNewSubject("");
                                                                                setNameError(false);
                                                                            } else {
                                                                                setNameError(true);
                                                                            }
                                                                        }}  >
                                                                        {props.t('Save')}
                                                                    </button>
                                                                    <button type="button"
                                                                        className="btn btn-danger m-1"
                                                                        onClick={() => {
                                                                            setEditCategory(undefined);
                                                                            setNewSubject("");
                                                                            setNameError(false);
                                                                        }}  >
                                                                        {props.t('Close')}

                                                                    </button>
                                                                </div>
                                                                :
                                                                <ul className="list-inline font-size-20 contact-links mb-0">

                                                                    {canEdit && <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setEditCategory(category)
                                                                            setNewSubject(category.attributes.name);

                                                                        }}
                                                                        id={"sub-edit" + key}>
                                                                        <i className="bx bx-edit-alt"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-edit" + key}>
                                                                            {props.t('Edit')}
                                                                        </UncontrolledTooltip>

                                                                    </li>}
                                                                    {canEdit && <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            setDeleteCategory(category);
                                                                        }}
                                                                        id={"sub-delete" + key}>
                                                                        <i className="bx bx-trash text-danger"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-delete" + key}>
                                                                            {props.t('Delete')}
                                                                        </UncontrolledTooltip>

                                                                    </li>}
                                                                    <li className="list-inline-item px-2"
                                                                        style={{ cursor: "pointer" }}
                                                                        onClick={() => {
                                                                            // props.onSelectCategory(category);
                                                                            // props.onClicItem("SubcategoryList");
                                                                            onClickItem(category.id)
                                                                            // toggleClassModal(true);
                                                                        }}
                                                                        id={"sub-cyc" + key}>
                                                                        <i className="bx bx-right-arrow-circle"></i>
                                                                        <UncontrolledTooltip placement="top" target={"sub-cyc" + key}>
                                                                            {props.t('Details')}
                                                                        </UncontrolledTooltip>

                                                                    </li>
                                                                </ul>
                                                        }
                                                    </td>
                                                </tr>)}
                                        </tbody>
                                    </Table>
                                </div>
                            </div>
                        </Col>
                        {!showNewSubject && canEdit && <Col lg={12} >
                            <div className="d-flex justify-content-end mb-4" >
                                <Form className="mt-4 mt-sm-0 float-sm-right form-inline">
                                    <Button onClick={() => handelAddSubject()} type="button" color="success" className="btn waves-effect waves-light  mb-2"><i className="mdi mdi-plus mr-1"></i> Add New Subject</Button>
                                </Form>
                            </div>
                        </Col>}
                    </Row>
                }
                {
                    props.categoryLoadingState && <Col xs="12">
                        <div className="text-center my-3">
                            <Label className="text-success"><i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i> Loading </Label>
                        </div>

                    </Col>
                }

                {(!props.learningCategories || props.learningCategories.length === 0) && !props.categoryLoadingState && <Card>
                    <CardBody>
                        {canEdit ? <Row >
                            <Col sm={7}>
                                <AvForm className="outer-repeater" >
                                    <div data-repeater-item className="outer">
                                        <FormGroup >
                                            <Label ><h3 className="mt-5">{props.t('Add A subject ?')}</h3> </Label>
                                            <AvField
                                                type="text"
                                                name="name"
                                                placeholder={props.t('Subject Name')}
                                                className="form-control"
                                                value={newSubject}
                                                // validate={{ required: {
                                                //     value: true,
                                                //     errorMessage: props.t('Category name can not be empty'),
                                                //     },}}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0 && nameError) {
                                                        setNameError(false);
                                                    }
                                                    setNewSubject(e.target.value);
                                                }}

                                            />
                                            {nameError && <p className="text-danger"> {props.t('Subject name can not be empty')}</p>}
                                        </FormGroup>
                                    </div>
                                </AvForm>
                                {loading ?
                                    <button type="button" className="btn btn-dark waves-effect waves-light  m-2">
                                        <i className="bx bx-loader bx-spin font-size-16 align-middle "></i>  {props.t('Saving')}
                                    </button>
                                    :
                                    <button type="button"
                                        className="btn btn-primary "
                                        onClick={() => {
                                            if (!newSubject || newSubject.length === 0) {
                                                setNameError(true);
                                            } else {
                                                setLoading(true);
                                                handelAddCategory(newSubject);
                                            }
                                        }}  >
                                        {props.t('Add')}
                                    </button>}
                            </Col>
                            <Col sm={5}>
                                <div className="maintenance-img">
                                    <img src={contentImage} alt="" className="img-fluid mx-auto d-block" />
                                </div>
                            </Col>

                        </Row> :
                            <CardSubtitle>{props.t('No Subjects found')}</CardSubtitle>
                        }
                    </CardBody>

                </Card>}

                <Col >
                    <Element name="AddSubject">
                        {showNewSubject && <Card>
                            <CardBody>
                                <AvForm className="outer-repeater" >
                                    <div data-repeater-item className="outer">
                                        <FormGroup >
                                            <Label htmlFor="formname">{props.t('New Subject')}: </Label>
                                            <AvField
                                                type="text"
                                                name="name"
                                                placeholder={props.t('New Subject...')}
                                                className="form-control"
                                                value={newSubject}
                                                validate={{
                                                    required: {
                                                        value: true,
                                                        errorMessage: props.t('New Subject...'),
                                                    },
                                                }}
                                                onChange={(e) => {
                                                    if (e.target.value.length > 0) {

                                                    }
                                                    setNewSubject(e.target.value);
                                                }}
                                            />
                                            {nameError && <p className="text-danger"> {props.t('Subject name can not be empty')}</p>}
                                        </FormGroup>
                                    </div>
                                </AvForm>

                                <Row>
                                    <Col>
                                        <button type="button"
                                            className="btn btn-secondary"
                                            onClick={() => {
                                                setShowNewSubject(false);
                                                setNewSubject("");
                                            }}  >
                                            {props.t('Close')}
                                        </button>
                                    </Col>
                                    <Col>
                                        <div className='d-flex justify-content-end m-0'>
                                            {loading ?
                                                <button type="button" className="btn btn-dark waves-effect waves-light float-right m-2">
                                                    <i className="bx bx-loader bx-spin font-size-16 align-middle "></i>  {props.t('Saving')}
                                                </button>
                                                :
                                                <button
                                                    onClick={() => {
                                                        if (!newSubject || newSubject.length === 0) {
                                                            setNameError(true);
                                                        } else {
                                                            setLoading(true);
                                                            handelAddCategory(newSubject);
                                                        }
                                                    }}
                                                    type="button"
                                                    className="btn btn-primary waves-effect waves-light float-right ">
                                                    <i className="bx bx-save font-size-16 align-middle mr-1"></i>  {props.t('Save Subject')}


                                                </button>}
                                        </div>
                                    </Col>
                                </Row>

                            </CardBody>
                        </Card>}
                    </Element>
                </Col>
            </div>


            {deleteCategory ? (
                <SweetAlert
                    title="Are you sure?"
                    warning
                    showCancel
                    confirmBtnBsStyle="success"
                    cancelBtnBsStyle="danger"
                    onConfirm={() => {
                        handelDeleteCategory(deleteCategory);
                        setDeleteCategory(undefined);
                        setsuccess_dlg(true);
                    }}
                    onCancel={() => {
                        setDeleteCategory(undefined);
                        setsuccess_dlg(false);
                    }}
                >
                    {props.t("You won't be able to revert this!")}
                </SweetAlert>
            ) : null}
            {success_dlg ? (
                <SweetAlert
                    success
                    title={props.t('Deleted')}
                    onConfirm={() => {
                        setsuccess_dlg(false)
                    }}
                >
                    {props.t('Your subject has been deleted.')}
                </SweetAlert>
            ) : null}


            <Modal
                isOpen={classModal}
                scrollable={true}
            >
                <ModalHeader isOpen={classModal}>
                    <h5 className="modal-title mt-0">Move to class</h5>
                </ModalHeader>
                <ModalBody className="modal-body">

                    <p>{props.t('Please select the classroom to which student has been taken admission, On saving student will moved to selected classroom')}</p>
                    {
                        props.curriculumAcademicCycles && props.curriculumAcademicCycles.map((item, i) =>

                            <div className="custom-control custom-checkbox mb-2 ml-3" key={item.id}>
                                <input type="checkbox" className="custom-control-input" id={item.id} onChange={() => {setSelectedCycle(item)}} checked={item.id === (selectedCycle && selectedCycle.id)} />
                                <label className="custom-control-label" onClick={() => { setSelectedCycle(item); }} >{item.attributes.schoolCycle}</label>
                            </div>
                        )
                    }
                    {classSelcetWarning && <p className="text-danger ml-2">{props.t('Please selecte the classroom')}</p>}

                </ModalBody>

                <ModalFooter >
                    {!false ?
                        <div>
                            <button type="button"
                                className="btn btn-secondary"
                                onClick={() => {
                                    setClassSelcetWarning(false);
                                    // setSelectedKid(undefined);
                                    // onSelectClass(undefined);
                                    toggleClassModal(!classModal)
                                }}  >
                                {props.t('Close')}
                            </button>
                            <button type="button"
                                className="btn btn-primary ml-2"
                                onClick={() => {
                                    if (selectedCycle) {
                                        // setClassSelcetWarning(false);
                                        // setLoading(true);
                                        // handelMovetoClass();
                                    } else {
                                        setClassSelcetWarning(true);
                                    }
                                }} >{props.t('Save')}
                            </button>
                        </div>
                        :
                        <button type="button" className="btn btn-light waves-effect">
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Moving to Class')}
                        </button>
                    }
                </ModalFooter>

            </Modal>

        </React.Fragment>
    );
}


const mapStatetoProps = state => {
    const { schools, selectedSchool } = state.School;
    const { curriculums, initialLoding, selectedCurriculum } = state.Curriculum;
    const { learningCategories, categoryLoadingState } = state.LearningCategory;
    const { curriculumAcademicCycles, loadState } = state.AcademicCycles;
    const { userTeacher } = state.Login;
    const { learningCurriculumsSchoolMapForCurriculumIdAndSchoolId } = state.LearningCurriculumsSchoolMap;

    return { schools, selectedSchool, curriculums, initialLoding, curriculumAcademicCycles, loadState, learningCategories, categoryLoadingState, selectedCurriculum, userTeacher, learningCurriculumsSchoolMapForCurriculumIdAndSchoolId };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    setSelecetdSchool, getLearningCategoryForCycleId,
    setSelectedCurriculum,
    addLearningCategory, deleteLearningCategory, getLearningCategoryForCurriculumId,
    reorderCatrgories,
    getLearningCurriculumSchoolMapForCurriculumIdAndSchoolId
})(CategoryList)));