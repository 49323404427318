import * as actions from "./actionTypes";


export const deleteKidsInList = (kidsToDelete, message) => {
  return {
    type: actions.DELETE_KIDS_IN_LIST,
    payload: { kidsToDelete, message },
  };
};

export const setdeletekidLoading = (state) => {
  return {
    type: actions.DELETE_KID_LOADING,
    payload: { state },
  };
};

export const updateAKid = (kid, classId) => {
  return {
    type: actions.UPDATE_A_KID,
    payload: { kid, classId },
  };
};
export const clearAllKid = () => {
  return {
    type: actions.CLEAR_ALL_KIDS,
    payload: {  },
  };
};
export const setkidUpdatedsuccessfully = (val) => {
  return {
    type: actions.SET_UPDATED_SUCCESSFULLY,
    payload: { val },
  };
};
export const kidsDeletedSuccessfully = (boolValue) => {
  return {
    type: actions.KIDS_DELETED_SUCCESSFULLY,
    payload: { boolValue },
  };
};

export const getAllKids = (schoolId) => {
  return {
    type: actions.GET_ALL_KIDS,
    payload: { schoolId },
  };
};
export const updateKidTosterSuccess = () => {
  return {
    type: actions.UPDATE_KID_TOSTER_SUCCESS,
    payload: {},
  };
};




export const getAllKidsForGroup = (schoolId) => {
  return {
    type: actions.GET_ALL_KIDS_FOR_GROUP,
    payload: { schoolId },
  };
};


export const updateAllKidsForGroups = (kids) => {
  return {
    type: actions.UPDATE_ALL_KIDS_FOR_GROUPS,
    payload: { kids },
  };
};

export const updateAllKids = (kids, requestedDeleted) => {
  return {
    type: actions.UPDATE_ALL_KIDS,
    payload: { kids, requestedDeleted },
  };
};

export const getAKid = (kidId) => {
  return {
    type: actions.A_KID_DETAILS,
    payload: { kidId },
  };
};

export const getKidObjectFromLocal = (id) => {
  return {
    type: actions.GET_KID_OBJECT_FROM_LOCAL,
    payload: { id },
  };
};



export const updateSelectedKid = (kidObj) => {
  return {
    type: actions.SELECTED_A_KID_DETAILS,
    payload: { kidObj },
  };
};

export const getChildAdditionalDetailsForKidId = (kidId) => {
  return {
    type: actions.GET_CAD_FOR_A_KID,
    payload: { kidId },
  };
};

export const setChildAdditionalDetailsForKid = (cadObj) => {
  return {
    type: actions.SET_CAD_FOR_A_KID,
    payload: { cadObj },
  };
};

export const getmedicalDetailsForAKid = (medicalId) => {
  return {
    type: actions.GET_MEDICAL_DETAILS_FOR_KID,
    payload: { medicalId },
  };
};

export const getmedicalDetailsForKidIds = (kidIds) => {
  return {
    type: actions.GET_MEDICAL_DETAILS_FOR_KID_IDS,
    payload: { kidIds },
  };
}; 

export const setMedicalDetailsForKidIds = (medObjects) => {
  return {
    type: actions.SET_MEDICAL_DETAILS_FOR_KID_IDS,
    payload: { medObjects },
  };
};

export const setMedicalDetailsForAKid = (medObj) => {
  return {
    type: actions.SET_MEDICAL_DETAILS_FOR_KID,
    payload: { medObj },
  };
};

export const saveMedicalDetailsForAKid = (medObj,kid) => {
  return {
    type: actions.SAVE_MEDICAL_DETAILS_FOR_KID,
    payload: { medObj,kid },
  };
};


export const getPickAndDropDetailForKid = (kidId) => {
  return {
    type: actions.GET_PICK_AND_DROP_DETAL_FOR_KID,
    payload: { kidId },
  };
};

export const setPickAndDropDetailForKid = (escortObjs) => {
  return {
    type: actions.SET_PICK_AND_DROP_DETAL_FOR_KID,
    payload: { escortObjs },
  };
};

export const getVaccinationList = (kidId) => {
  return {
    type: actions.GET_VACCINE_DETAL_FOR_KID,
    payload: { kidId },
  };
};

export const setgetVaccinationList = (vaccineObjs) => {
  return {
    type: actions.SET_VACCINE_DETAL_FOR_KID,
    payload: { vaccineObjs },
  };
};

export const getAdmissionKidsForSchool = (schoolId) => {
  return {
    type: actions.GET_ADMISSION_KIDS,
    payload: { schoolId },
  };
};

export const sendAdmissionLinkToParent = (
  enquiryId,
  emails,
  phoneNumbers,
  admissionURL,
  schoolName,
  schoolId
) => {
  return {
    type: actions.SEND_ADMISSION_LINK_FOR_PARENTS,
    payload: {
      enquiryId,
      emails,
      phoneNumbers,
      admissionURL,
      schoolName,
      schoolId,
    },
  };
};
export const updateAdmissionKids = (admissionKids, state) => {
  return {
    type: actions.UPDATE_ADMISSION_KIDS,
    payload: { admissionKids, state },
  };
};

export const getAttendanceForAKid = (kidId, month) => {
  return {
    type: actions.GET_ATTENDANCE_FOR_KID,
    payload: { kidId, month },
  };
};

export const setAttendanceForKid = (attObjs) => {
  return {
    type: actions.SET_ATTENDANCE_FOR_KID,
    payload: { attObjs },
  };
};

export const getAttendanceForKidDaycare = (kidId, month) => {
  return {
    type: actions.GET_ATTENDANCE_FOR_KID_DAYCARE,
    payload: { kidId, month },
  };
};

export const setAttendanceForKidDaycare = (attobjs) => {
  return {
    type: actions.SET_ATTENDANCE_FOR_KID_DAYCARE,
    payload: { attobjs },
  };
};

export const updateAdditionalDetailsForAKid = (adObj) => {
  return {
    type: actions.UPDATE_ADDITIONAL_DETAILS_FOR_A_KID,
    payload: { adObj },
  };
};

export const removeKidFromAdmissions = (kid) => {
  return {
    type: actions.REMOVE_KID_FROM_ADMISSIONS,
    payload: { kid },
  };
};
export const updateRemovedKid = (kid) => {
  return {
    type: actions.UPDATE_REMOVED_KID,
    payload: { kid },
  };
};
export const addAKid = (refObj) => {
  return {
    type: actions.ADD_A_KID_TO_DB,
    payload: { refObj },
  };
};


export const getAllDeletedKidsForSchoolIdOnlyForArchives = (schoolId) => {
  return {
    type: actions.GET_ALL_DELETED_KIDS_SCHOOL_ID,
    payload: { schoolId },
  };
};

export const getAllDeletedKidsForIds = (kidIds) => {
  return {
    type: actions.GET_ALL_DELETED_KIDS_FOR_IDS,
    payload: { kidIds },
  };
};

export const clearDeletedKids = (boolValue) => {
  return {
    type: actions.CLEAR_DELETED_KIDS,
    payload: { boolValue },
  };
};
export const updateAllDeletedKidsInitial = (deletedKids) => {
  return {
    type: actions.UPDATE_ALL_DELETED_KIDS_INITIAL,
    payload: { deletedKids },
  };
};

export const updateAllDeletedKids = (deletedKids) => {
  return {
    type: actions.UPDATE_ALL_DELETED_KIDS,
    payload: { deletedKids },
  };
};
export const saveKids = (kids) => {
  return {
    type: actions.SAVE_KIDS,
    payload: { kids },
  };
};

export const getallKidsForSchoolId = (schoolId) => {
  return {
    type: actions.GET_ALL_KIDS_FOR_SCHOOL_ID,
    payload: { schoolId },
  };
};

export const setAllKidsForSchool = (serverObj) => {
  return {
    type: actions.SET_KIDS_FOR_SCHOOL,
    payload: { serverObj },
  };
};

export const sendInvite = (kidId, schoolName, successMessage, teacherId, parentId) => {
  return {
    type: actions.SEND_INVITE,
    payload: { kidId, schoolName, successMessage, teacherId, parentId },
  };
};

export const getAllDeletedKidForSchool = (schoolId) => {
  return {
    type: actions.GET_ALL_DELETED_KIDS_FOR_SCHOOL,
    payload: { schoolId },
  };
};

export const setAllDeletedKidsForSchool = (kidObjs) => {
  return {
    type: actions.SET_ALL_DELETED_KIDS_FOR_SCHOOL,
    payload: { kidObjs },
  };
};

export const createStudentUser = (kid, schoolId) => {
  return {
    type: actions.CREATE_STUDENT_USER,
    payload: { kid, schoolId },
  };
};

export const updateStudentUser = (user, loading) => {
  return {
    type: actions.UPDATE_STUDENT_USER,
    payload: { user, loading },
  };
};

export const getEscortsForKidIds = (kidIds) => {
  return {
      type: actions.GET_ESCORTS_FOR_KID_IDS,
      payload: { kidIds }
  }
}

export const updateEscorts = (escorts) => {
  return {
      type: actions.UPDATE_ESCORTS,
      payload: { escorts }
  }
}

export const disableKid = (kidId, disabledBy, isDisabled) => {
  return {
      type: actions.DISABLED_KID,
      payload: { kidId, disabledBy, isDisabled }
  }
}