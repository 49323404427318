import Parse from "parse";
import { printLog } from "./loghelper";

const getCurriculumFromParse = (curriculumId,isLocal) => {
  printLog("HL: curriculum_helper : getCurriculumFromParse")

    try {
        return new Promise((resolve, reject) => {
            var Curriculum = Parse.Object.extend("Curriculum");
          var query = new Parse.Query(Curriculum);
          if (isLocal) {
            query.fromLocalDatastore();
          } else {
            query.notEqualTo("isDeleted", true);
          }
            query.get(curriculumId).then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(null);
                }
            )
        }
        )
    } catch (error) {}
}

const deleteCurriculumFromParse = (curriculum) => {
  printLog("HL: curriculum_helper : deleteCurriculumFromParse")
    try {
      return new Promise((resolve, reject) => {
        
        curriculum.set("isDeleted", true);
        if (Parse.User.current().attributes.teacherId) {
            curriculum.set("deletedBy", Parse.User.current().attributes.teacherId);
        } else {
            curriculum.set("deletedBy", Parse.User.current().id);
        }
  
        curriculum.save().then(
          (result) => {
            resolve(result);
          },
          (error) => {
            reject(null);
          }
        );
      });
    } catch (error) {}
  };

  const getCurriculumForownerId = (id, updatedDate,isLoacal) => {
    printLog("HL: curriculum_helper : getCurriculumForownerId")
    try {
      return new Promise((resolve, reject) => {
        var Curriculum = Parse.Object.extend("Curriculum");
        var query = new Parse.Query(Curriculum);
        query.equalTo("ownerId", id);
  
        if (updatedDate) {
            query.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
         } else {
           query.notEqualTo("isDeleted", true);
         }
   
         if (isLoacal) {
           query.fromLocalDatastore();
           query.notEqualTo("isDeleted", true);
         }
   
         query.descending("createdAt");
         query.limit(1000);
         query.find().then(
           (result) => {
             resolve(result);
           },
           (error) => {
             resolve(error);
           }
         );
       });
     } catch (error) {}
  };
   

  const getCurriculumsForOwnerIds = (ids,isLoacal) => {
    printLog("HL: curriculum_helper : getCurriculumsForOwnerIds")
    try {
      return new Promise((resolve, reject) => {
        var Curriculum = Parse.Object.extend("Curriculum");
        var query = new Parse.Query(Curriculum);
        query.containedIn("ownerId", ids);
  
        
   
         if (isLoacal) {
           query.fromLocalDatastore();
           
         }
         query.notEqualTo("isDeleted", true);
         query.descending("createdAt");
         query.limit(1000);
         query.find().then(
           (result) => {
             resolve(result);
           },
           (error) => {
             resolve(error);
           }
         );
       });
     } catch (error) {}
  };
  const saveCurriculumObject = (object) => {
    printLog("HL: curriculum_helper : saveCurriculumObject")
    try {
      return new Promise((resolve, reject) => {
        object.save().then(
            (result) => {
              resolve(result);
            },
              (error) => {
                resolve(error);
            }
          );
          
        });	
  } catch (error) { }
  }

  const saveObjects = (objects) => {
    printLog("HL: curriculum_helper : saveObjects")
    try {
      return new Promise((resolve, reject) => {
        Parse.Object.saveAll(objects).then(
          (result) => {
            resolve(result);
          },
            (error) => {
              resolve(error);
              console.log("Error", error);
          }
        );	
      });	
    } catch (error) { }
  }
  
  
export {
  getCurriculumFromParse,
  deleteCurriculumFromParse,
  getCurriculumForownerId,
  getCurriculumsForOwnerIds,
  saveCurriculumObject,
  saveObjects,
  
}