export const UPDATE_EXPENSES = "UPDATE_EXPENSES";
export const GET_EXPENSES_FOR_SCHOOL_ID = "GET_EXPENSES_FOR_SCHOOL_ID";
export const UPDATE_EXPENSE = "UPDATE_EXPENSE";
export const EDIT_EXPENSE = "EDIT_EXPENSE";
export const UPDATE_EXPENSE_RESULT = "UPDATE_EXPENSE_RESULT";
export const CLEAR_EXPENSE_RESULT = "CLEAR_EXPENSE_RESULT";
export const GET_EXPENSES_FOR_DATE = "GET_EXPENSES_FOR_DATE";
export const CLEAR_EXPENSES = "CLEAR_EXPENSES";
export const GET_EXPENSE_WALLET_FOR_SCHOOL_ID = "GET_EXPENSE_WALLET_FOR_SCHOOL_ID";
export const GET_EXPENSE_WALLET_LOG_FOR_SCHOOL_ID = "GET_EXPENSE_WALLET_LOG_FOR_SCHOOL_ID";
export const UPDATE_EXPENSE_WALLET = "UPDATE_EXPENSE_WALLET";
export const ADD_EXPENSE_WALLET = "ADD_EXPENSE_WALLET";
export const UPDATE_EXPENSE_WALLET_LOG = "UPDATE_EXPENSE_WALLET_LOG";
export const GET_EXPENSE_WALLET_LOG_FOR_DATE = "GET_EXPENSE_WALLET_LOG_FOR_DATE";
export const CLEAR_EXPENSE_WALLET_LOG = "CLEAR_EXPENSE_WALLET_LOG";