//attendance
export const GET_ATTENDANCE_FOR_TEACHER_IDS = "GET_ATTENDANCE_FOR_TEACHER_IDS";
export const SET_ATTENDANCE_FOR_TEACHER_IDS = "SET_ATTENDANCE_FOR_TEACHER_IDS";
export const GET_ATTENDANCE_FOR_KID_IDS = "GET_ATTENDANCE_FOR_KID_IDS";
export const SET_ATTENDANCE_FOR_KID_IDS = "SET_ATTENDANCE_FOR_KID_IDS";
export const GET_DAYCARE_ATTENDANCE_FOR_KID_IDS =
  "GET_DAYCARE_ATTENDANCE_FOR_KID_IDS";
export const SET_DAYCARE_ATTENDANCE_FOR_KID_IDS =
  "SET_DAYCARE_ATTENDANCE_FOR_KID_IDS";
export const ATTENDANCE_QUEUE_TIMER = "ATTENDANCE_QUEUE_TIMER"


//enquiry
export const GET_ENQUIRY_KIDS_FOR_FILTERS = "GET_ENQUIRY_KIDS_FOR_FILTERS";
export const SET_ENQUIRY_REPORT_FOR_FILTER = "SET_ENQUIRY_REPORT_FOR_FILTER";
export const GET_ATTENDANCE_FOR_CLASS_ID =  "GET_ATTENDANCE_FOR_CLASS_ID";
export const UPDATE_CLASS_ATTENDANCE  = "UPDATE_CLASS_ATTENDANCE";
export const GET_ATTENDANCE_FOR_DAYCARE_ID =  "GET_ATTENDANCE_FOR_DAYCARE_ID";
export const UPDATE_DAYCARE_ATTENDANCE  = "UPDATE_DAYCARE_ATTENDANCE";
