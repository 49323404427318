import React, { useEffect, useState } from "react";
import {
  Modal,
  InputGroup,
  InputGroupText,
  Label,
  Input,
  Row,
  Col,
} from "reactstrap";
import Parse from "parse";
import { withRouter } from "react-router-dom/cjs/react-router-dom.min";
import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { getInvoiceDetailsByFunction } from "../../store/actions";
import Moment from "moment";
import { useForm } from "react-hook-form";

const OnlinePatment = (props) => {
  const { register, handleSubmit, formState: { errors } } = useForm();

  const [itemList, itemListOps] = useState([]);
  const [reciveTotal, reciveTotalOps] = useState(0);
  const [gatewayState, setGatewayState] = useState(false);
  const [reciveAmountError, setReciveAmountError] = useState(false);
  const [airPayRequestResponce, setAirPayRequestResponce] = useState(false);
  const [razorPayRequestResponce, setRazorPayRequestResponce] = useState(false);
  const [onepayRequestResponce, setOnePayRequestResponce] = useState(false);
  const [penaltyItemObject, setPenaltyItemObject] = useState(undefined);
  useEffect(() => {
    if (props.invoiceDetails) {
      let tempVal = [];
      if (props.invoiceDetails.Items) {
        for (const val of props.invoiceDetails.Items) {
          let temp = {};
          temp.id = val.id;
          temp.object = val;
          temp.itemDescription = val.attributes.itemDescription;
          temp.amount = +val.attributes.amount;
          temp.totalAmount = +val.attributes.totalAmount;
          temp.editAmount = +val.attributes.totalAmount;
          temp.maxPay = val.attributes.totalAmount;
          temp.discount = val.attributes.discount;
          temp.discountType = val.attributes.discountType;
          temp.paidAmount = 0;
          temp.isPaid = val.attributes.paidStatus === 1 ? true : false;
          if (val.attributes.paidStatus === 3) {
            temp.editAmount = temp.editAmount - temp.paidAmount;
          }
          temp.paidStatus = val.attributes.paidStatus;
          temp.isEnableForPay = false;
          if (temp.isPaid) {
            temp.isEnableTick = true;
          } else {
            temp.isEnableTick = false;
          }
          temp.oldTriansaction = [];
          temp.transactionIds = [];
          if (val.attributes.paidStatus === 1) {
            temp.paidCSS = "text-success";
          } else if (val.attributes.paidStatus === 2) {
            temp.paidCSS = "text-warning";
          } else {
            temp.paidCSS = "text-danger";
          }
          temp.royalty = val.attributes.royalty;
          temp.collectedRoyalty = val.attributes.collectedRoyalty;
          for (const mtd of props.invoiceDetails.MoneyTransactionDetails) {
            if (mtd.attributes.itemId === val.id) {
              if (mtd.attributes.isDeleted) {
                break;
              }
              temp.oldTriansaction.push(mtd);
              temp.transactionIds.push(mtd.id);
              temp.editAmount -= mtd.attributes.amount;
              temp.maxPay -= mtd.attributes.amount;
              temp.paidAmount += mtd.attributes.amount;
            }
          }
          tempVal.push(temp);
        }
        createPanaltyObject(tempVal)
        // itemListOps(tempVal);
      }
    }
  }, [props.invoiceDetails]);
  const createPanaltyObject = (exList) => {
    if (props.invoiceDetails && props.invoiceDetails.School &&
      props.invoiceDetails.Items && props.invoiceDetails.Items.length > 0 &&
      props.invoiceDetails.School.attributes.additionalSettings &&
      props.invoiceDetails.School.attributes.additionalSettings.length >= 6 &&
      props.invoiceDetails.School.attributes.additionalSettings[5] > 0) {

      let penalty = props.invoiceDetails.School.attributes.additionalSettings[5];
      let oldestItem;

      for (const val of props.invoiceDetails.Items) {

        if (val.attributes.dueDate && (val.attributes.paidStatus != 1 ) ) {
          if (oldestItem) {
            if (oldestItem.attributes.dueDate > val.attributes.dueDate) {
              oldestItem = val;
            }
          } else {
            oldestItem = val;
          }
        }
      }

      let today = new Date();
      if (oldestItem && oldestItem.attributes.dueDate < today) {
        let dueDays = getDayBetweenDates(oldestItem.attributes.dueDate, today);

        if (dueDays > 0) {
          let temp = {};
          temp.id = 'penaltyObject';

          temp.itemDescription = 'Late payment penalty';
          temp.amount = dueDays * penalty;
          temp.totalAmount = dueDays * penalty;
          temp.editAmount = dueDays * penalty;
          temp.discount = 0;
          temp.discountType = 1;
          temp.paidAmount = 0;
          temp.isEnableForPay = true;
          temp.isPenalty = true

          temp.isEnableTick = true;
          temp.isEnableForPay = true;

          let tempItems = exList ?? itemList;
          tempItems.push(temp);
          setPenaltyItemObject(temp)
          itemListOps(tempItems);


        } else {
          itemListOps(exList)
        }
      } else {
        itemListOps(exList)
      }


    } else {
      itemListOps(exList)
    }
  }
  const getDayBetweenDates = (d1, d2) => {
    var diff = d2 - d1;
    return Math.floor(diff / 86400000);
  }
  const enableTick = (id) => {
    let tempVals = Object.assign([], itemList);
    for (let val of tempVals) {
      if (val.id === id) {
        val.isEnableTick = !val.isEnableTick;
        val.isEnableForPay = val.isEnableTick;
        if (val.royalty > 0 && val.isEnableTick) {
          let royalty = (val.editAmount * val.royalty) / 100;
          val.editAmountRoyalty = royalty;
        }
      }
    }
    itemListOps(tempVals);
    calculateTotal(tempVals);
  };
  const calculateTotal = (allValues) => {
    let tempTotal = 0;
    for (let val of allValues) {
      if (val.isEnableForPay) {
        tempTotal += val.editAmount;
      }
    }
    if (tempTotal > 0 && reciveAmountError) {
      setReciveAmountError(false);
    }
    reciveTotalOps(tempTotal);
  };
  const onAmountChange = (id, newValue) => {
    let tempVals = Object.assign([], itemList);
    for (let val of tempVals) {
      if (val.id === id) {
        if (newValue > val.maxPay) {
          val.editAmount = val.maxPay;
        } else {
          val.editAmount = +newValue;
        }
        if (val.isEnableForPay && val.royalty > 0) {
          let royalty = (val.editAmount * val.royalty) / 100;
          // console.log("royalty", royalty)
          val.editAmountRoyalty = royalty;
        }
      }
    }
    itemListOps(tempVals);
    calculateTotal(tempVals);
  };
  const recivePaymentByAirPay = () => {
    setGatewayState(true);
    setAirPayRequestResponce(undefined)
    let invoiceItem = [];
    let totaRoyalty = 0;
    let razorPay = (props.invoiceDetails.enabledOptions && props.invoiceDetails.enabledOptions.length >= 5 && props.invoiceDetails.enabledOptions[4] == 1) ? true : false;
    let onepay = (props.invoiceDetails.enabledOptions && props.invoiceDetails.enabledOptions.length >= 6 && props.invoiceDetails.enabledOptions[5] == 1) ? true : false;
    for (const val of itemList) {
      if (val.isEnableTick && val.editAmount > 0) {
        let temp = {};
        temp.amount = val.amount;
        temp.discount = val.discount;
        temp.discountType = val.discountType;
        temp.id = val.id;
        temp.isPayable = val.isEnableForPay ? false : true;
        temp.itemDescription = val.itemDescription;
        temp.payableAmout = val.isEnableForPay ? val.editAmount : 0;
        temp.tax = val.tax;
        temp.tickStat = val.isEnableTick;
        temp.totalAmount = val.totalAmount;
        temp.payableAmoutRoyalty = val.editAmountRoyalty ?? 0;
        totaRoyalty += temp.payableAmoutRoyalty;
        invoiceItem.push(temp);
      }
    }

    // onepay = true;
    // razorPay = false;

    const MT = Parse.Object.extend("MoneyTransactions");
    let mt = new MT();
    mt.set("schoolId", props.invoiceDetails.School.id);
    mt.set("invoiceId", props.invoiceDetails.Invoice.id);
    mt.set("amount", reciveTotal);
    if (onepay) {
      mt.set("transactionType", "OnePay");
    } else if (razorPay) {
      mt.set("transactionType", "RazorPay");
    } else {
      mt.set("transactionType", "AirPay");
    }

    mt.set("deleted", true);
    mt.set("invoiceItems", invoiceItem);


    if (totaRoyalty > 0) {
      mt.set("royaltyAmount", totaRoyalty);
      mt.set("schoolAmount", reciveTotal - totaRoyalty);
    }
    const submitRoyalty = totaRoyalty > 0 ? parseFloat(totaRoyalty).toFixed(2) : "0:00";

    mt.save().then((mtResult) => {
      let buyerEmail = "hello@liltriangle.com";
      let buyerPhoneNumber = "7676548853";
      let buyerFirstName = props.invoice.Kid.attributes.Name;
      let buyerLastName = " ";
      const submitAmount = parseFloat(reciveTotal).toFixed(2);
      const orderId = mtResult.id;
      let popAttribut;
      let popId;
      if (props.invoiceDetails.Invoice.attributes.paymentOptionId) {
        popAttribut = "objectId";
        popId = props.invoiceDetails.Invoice.attributes.paymentOptionId;
      } else {
        popAttribut = "schoolId";
        popId = props.invoiceDetails.School.id;
      }


      if (razorPay || onepay || true) {
        let parents = props.invoiceDetails.Parents;
        console.log("Parents", parents)
        if (parents && parents.length > 0) {

          if (parents[0].attributes.EMail) {
            buyerEmail = parents[0].attributes.EMail
          } else if (parents.length > 1 && parents[1].attributes.EMail) {
            buyerEmail = parents[1].attributes.EMail
          } else {
            buyerEmail = '';
          }

          if (parents[0].attributes.PhoneNumber) {
            buyerPhoneNumber = parents[0].attributes.PhoneNumber
          } else if (parents.length > 1 && parents[1].attributes.PhoneNumber) {
            buyerPhoneNumber = parents[1].attributes.PhoneNumber
          } else {
            buyerPhoneNumber = '';
          }
        } else {
          buyerEmail = '';
          buyerPhoneNumber = '';
        }
      }

      if (onepay) {
        const removedPart = (buyerPhoneNumber && buyerPhoneNumber.slice(3)) ?? "";
        handelOnePay({
          dateTime: Moment(new Date()).format("yyyy-MM-DD HH:mm:ss"),
          custMobile: removedPart.length == 10 ? removedPart : undefined,
          custMail: buyerEmail.length > 7 ? buyerEmail : undefined,
          amount: parseFloat(submitAmount).toFixed(2) + "",
          orderid: orderId,
          popId: popId,
          popAttribut: popAttribut,
          royaltyAmount: submitRoyalty,
          invoiceId: props.invoiceDetails.Invoice.id,
          kidName:removeSpecialCharacters(buyerFirstName),
        });

        return
      }

      if (razorPay) {

        loadscript('https://checkout.razorpay.com/v1/checkout.js')

        handelRazorPay({
          buyerFirstName: buyerFirstName,
          buyerLastName: buyerLastName,
          buyerAddress: "bangalore",
          buyerCity: "bangalore",
          buyerState: "Karnataka",
          buyerCountry: "IN",
          buyerEmail: buyerEmail,
          buyerPhone: buyerPhoneNumber,
          amount: submitAmount,
          orderid: orderId,
          popId: popId,
          popAttribut: popAttribut,
          royaltyAmount: submitRoyalty,
          invoiceId: props.invoiceDetails.Invoice.id,
        })

        return;
      }



      fetch(
        // "https://development.back4app.io/airpay/sendtoairpay", //development
        "https://liltriangle.b4a.app/airpay/sendtoairpay", //production
        // "http://localhost:1337/parse/airpay/sendtoairpay", //local
        {
          method: "POST",
          body: JSON.stringify({
            buyerFirstName: buyerFirstName,
            buyerLastName: buyerLastName,
            buyerAddress: "bangalore",
            buyerCity: "bangalore",
            buyerState: "Karnataka",
            buyerCountry: "IN",
            buyerEmail: buyerEmail,
            buyerPhone: buyerPhoneNumber,
            amount: submitAmount,
            orderid: orderId,
            popId: popId,
            popAttribut: popAttribut,
            royaltyAmount: submitRoyalty,
          }),
          headers: {
            "Content-type": "application/json; charset=UTF-8",
          },
        }
      ).then((response) => {
        if (response.status !== 200) {
          return "error";
        }
        return response.text();
      }, (error) => {
        console.log("error", error.message)
      })
        .then((text) => {
          let response = text;
          console.log(response);
          setGatewayState(false);
          setAirPayRequestResponce(response);
          // window.open().document.write(response);
          // window.location.replace(response);



          console.log("Request successful", text);
        });
    });
  };

  const handelRazorPay = (body) => {
    Parse.Cloud.run("function_getRazorpayOrder", {
      body: body,

    }).then((response) => {
      console.log("response" + response)
      setGatewayState(false);
      setRazorPayRequestResponce(response)
      openRazorPayWindow(response)
    }, (error) => {
      console.log("error" + error.message)
    });
  }

  const loadscript = (src) => {
    return new Promise((resolve) => {
      const script = document.createElement('script');
      script.src = src
      script.onload = () => {
        resolve(true);
      }
      script.onerror = () => {
        resolve(false);
      }

      document.body.appendChild(script)
    })
  }

  useEffect(() => {
    //  loadscript('https://checkout.razorpay.com/v1/checkout.js')
  },);

  const openRazorPayWindow = (sResponse) => {

    let buyerEmail = ""
    let buyerPhoneNumber = ""

    let invoiceDetails = props.invoiceDetails;
    if (invoiceDetails.Parents && invoiceDetails.Parents.length > 0) {
      buyerEmail = invoiceDetails.Parents[0].attributes.EMail ?? (invoiceDetails.Parents.length > 1 ? invoiceDetails.Parents[1].attributes.EMail : "")
      buyerPhoneNumber = invoiceDetails.Parents[0].attributes.PhoneNumber ?? (invoiceDetails.Parents.length > 1 ? invoiceDetails.Parents[1].attributes.PhoneNumber : "")
    }

    let key = sResponse.username;
    let response = JSON.parse(sResponse.response);
    var options = {
      "key": key,
      "amount": parseFloat(reciveTotal).toFixed(2) * 100,
      "currency": "INR",
      "name": props.invoiceDetails.School.attributes.Name,
      "description": "School Fee",
      "image": props.invoiceDetails.School.attributes.logo ? props.invoiceDetails.School.attributes.logo._url : '',
      "order_id": response.id,
      "callback_url": "https://dashboard.liltriangle.com/invoice/" + props.invoiceDetails.Invoice.id,
      "prefill": {
        "name": props.invoiceDetails.Kid.attributes.Name,
        "email": buyerEmail,
        "contact": buyerPhoneNumber
      },
      "notes": {
        "address": ""
      },
      "theme": {
        "color": "#3399cc"
      }
    };
    var rzp1 = new window.Razorpay(options);
    rzp1.open();
  }


  const handelOnePay = (body) => {
    Parse.Cloud.run("function_getOnepayOrder", {
      body: body,

    }).then((response) => {
      console.log("response", response)

      // setGatewayState(false);
      setOnePayRequestResponce(response);
    }, (error) => {
      console.log("error" + error.message)
    });
  }
  const removeSpecialCharacters = (str) => {
    return str.replace(/[^\w\s]/gi, '');
  };

  useEffect(() => {
    if (onepayRequestResponce) {
      setTimeout(() => {
        const pfForm = document.getElementById("payment-form");
        pfForm.submit();
      }, 1000);
    }
  }, [onepayRequestResponce]);


  return (
    <React.Fragment>

      {onepayRequestResponce && <div className="App row align-items-center justify-content-center full-height">
        <div className="col-md-4">
          <form method="POST" className="d-none" id="payment-form" action={onepayRequestResponce?.postUrl}>
            <div><input defaultValue={onepayRequestResponce?.merchantId} type="text" {...register("merchantId", { required: true })} /></div>
            <div><input defaultValue={onepayRequestResponce?.reqData} type="text" {...register("reqData", { required: true })} /></div>

            <input type="submit" />
          </form>

          <div className="text-center">
            <h4>Please wait...</h4>
            <div>Do not refresh or press any</div>
          </div>
        </div>
      </div>}

      <Modal
        size="lg"
        isOpen={props.paymentPopup}
        toggle={props.paymentPopupOps}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myLargeModalLabel">
            Select Amount
          </h5>
          {/* <button
            onClick={props.paymentPopupOps}
            type="button"
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button> */}
        </div>
        <div className="modal-body">
          <p>Select Particular :</p>
          {itemList.length &&
            itemList.map((val, key) => (
              <InputGroup className="mb-3" key={key}>
                <InputGroupText
                  addonType="prepend"
                  style={{ minWidth: "80%", maxWidth: "80%" }}
                >
                  <div className="custom-control custom-checkbox mt-2">
                    <input
                      type="checkbox"
                      checked={val.isEnableTick}
                      onChange={() => enableTick(val.id)}
                      className="custom-control-input"
                      id={`payment_${key}`}
                      disabled={val.isPenalty ?? val.isPaid}
                    />
                    <label
                      className="custom-control-label"
                      htmlFor={`payment_${key}`}
                    ></label>
                  </div>
                  <Label
                    className=" justify-content-start m-2"
                    style={{ minWidth: "100%", maxWidth: "100%", textAlign: "left" }}
                  >
                    {val.itemDescription} (
                    <span style={{ fontFamily: "Arial, sans-serif" }}>
                      {props.currency}
                    </span>{" "}
                    {parseFloat(val.totalAmount).toFixed(2)}) :
                    <br />
                    {val.paidAmount !== 0 && (
                    <div>
                      <span className={val.paidCSS}>
                        {" "}
                        ({props.t("paid")} :{" "}
                        <span style={{ fontFamily: "Arial, sans-serif" }}>
                          {props.currency}
                        </span>{" "}
                        {parseFloat(val.paidAmount).toFixed(2)})
                      </span>
                    </div>
                    )}
                    {val.object && val.object.attributes.paidStatus != 1 &&  val.object.attributes.dueDate &&<p className="m-0 text-start">Due Date:{Moment(val.object.attributes.dueDate).format("DD-MM-YYYY")}</p>}
                  </Label>
                </InputGroupText>
                <Input
                  type="number"
                  style={{ minWidth: "20%", maxWidth: "20%" }}
                  value={val.editAmount}
                  className="form-control text-end"
                  disabled={val.isPenalty ? true : (props.disableEdit ? true : !val.isEnableForPay)}
                  onChange={(e) => onAmountChange(val.id, e.target.value)}
                  min={1}
                  max={val.maxPay}
                />
              </InputGroup>
            ))}
          <hr />
          <Row>
            <Col md="12" sm="12" lg="12" className="mb-2">
              <div className="d-flex justify-content-end">
                <p className="text-danger">{reciveAmountError ? "Please select Particular" : " "} </p>
                <b className="h4 float-right">
                  {props.t("Total :")}{" "}
                  <span style={{ fontFamily: "Arial, sans-serif" }}>
                    {props.currency}
                  </span>{" "}
                  {parseFloat(reciveTotal).toFixed(2)}
                </b>
              </div>
            </Col>
          </Row>
        </div>
        <div className="modal-footer">

          {
            !airPayRequestResponce && gatewayState && <button type="button" className="btn btn-primary  waves-effect waves-light float-right m-2">
              <i className="bx bx-loader bx-spin font-size-16 align-middle mr-2"></i>  {props.t('Preparing For Payment ')}
            </button>
          }
          {!gatewayState && <button
            type="button"
            onClick={props.paymentPopupOps}
            className="btn btn-secondary waves-effect"
            data-dismiss="modal"
          >
            Close
          </button>}
          {!airPayRequestResponce && !razorPayRequestResponce && !gatewayState && <button
            type="button"
            className="btn btn-primary waves-effect waves-light"
            style={{ width: "9rem" }}
            onClick={() => {
              if (reciveTotal > 0) {
                if (penaltyItemObject) {
                  if (reciveTotal == penaltyItemObject.totalAmount) {
                    setReciveAmountError(true);
                  } else {
                    recivePaymentByAirPay();
                  }
                } else {
                  recivePaymentByAirPay();
                }
              } else {
                setReciveAmountError(true);
              }
            }}
          >
            Pay
          </button>}

          {airPayRequestResponce && <button
            type="button"
            className="btn btn-primary waves-effect waves-light"

            onClick={() => {
              window.open().document.write(airPayRequestResponce);
              setAirPayRequestResponce(undefined);
            }}
          >
            Proceed to Payment Gateway
          </button>}
          {razorPayRequestResponce && <button
            type="button"
            className="btn btn-primary waves-effect waves-light"

            onClick={() => {
              openRazorPayWindow(razorPayRequestResponce);
              setRazorPayRequestResponce(undefined);
            }}
          >
            Proceed to Payment Gateway
          </button>}
        </div>
      </Modal>
    </React.Fragment>
  );
};
const mapStatetoProps = (state) => {
  const { invoice } = state.Invoice;
  return { invoice };
};

export default connect(mapStatetoProps, { getInvoiceDetailsByFunction })(
  withRouter(withNamespaces()(OnlinePatment))
);
