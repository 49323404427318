import { takeEvery, takeLeading, fork, put, all, call } from "redux-saga/effects";
import Parse from "parse";

import * as ExpensesHelper from "../../../helpers/ParseHelpers/expenseSupplier_helper";
import * as actionTypes from "./actionTypes";
import * as ExpensesActions from "./actions";
import * as Constants from '../../../Constents';
import toastr from 'toastr';
import 'toastr/build/toastr.min.css';

function* getExpenseSupplierForSchoolIdSaga({ payload: { schoolId, instituteId } }) {
  try {
    var key = Constants.EXPENSE_SUPPLIER_LIST_UPDATED_TIME + "_" + schoolId;
    var updatedTime = localStorage.getItem(key)
    var newTime = new Date();
    const localResponse = yield call(ExpensesHelper.getExpenseSupplierForSchoolId, schoolId, instituteId, null, true);
    if (localResponse && localResponse.length > 0) {
      yield put(ExpensesActions.updateExpenseSuppliers(localResponse));
    }

    const serverResponse = yield call(ExpensesHelper.getExpenseSupplierForSchoolId, schoolId, instituteId, updatedTime, false);

    localStorage.setItem(key, newTime)

    if (serverResponse && serverResponse.length > 0) {
      yield put(ExpensesActions.updateExpenseSuppliers(serverResponse));

      Parse.Object.pinAll(serverResponse);
    }
  } catch (error) {
  }
}


function* editExpenseSupplierSaga({ payload: { expenseSupplier, isInstitute } }) {
  try {
    yield put(ExpensesActions.updateLoadingExpenseSupplier(true));

    const response = yield call(
      ExpensesHelper.editExpenseSupplier,
      expenseSupplier, isInstitute
    );

    if (response === "Error") {
      toastr.error("Please try again", { timeOut: 3000 });
    } else
      if (response) {
        yield put(ExpensesActions.updateExpenseSupplier(response));
        yield put(ExpensesActions.updateLoadingExpenseSupplier(false));
        response.pin();
      } else {
      }
  } catch (error) {
    yield put(ExpensesActions.updateLoadingExpenseSupplier(false));
  }
}


export function* watchGetExpenseSupplierForSchoolIdSaga() {
  yield takeEvery(actionTypes.GET_EXPENSE_SUPPLIER_FOR_SCHOOL_ID, getExpenseSupplierForSchoolIdSaga);
}

export function* watchEditExpenseSupplierSaga() {
  yield takeEvery(actionTypes.EDIT_EXPENSE_SUPPLIER, editExpenseSupplierSaga);
}


function* ExpenseSupplierSaga() {
  yield all([

    fork(watchGetExpenseSupplierForSchoolIdSaga),
    fork(watchEditExpenseSupplierSaga),

  ]);
}
export default ExpenseSupplierSaga;
