import { takeEvery, fork, put, all, call, take } from "redux-saga/effects";
import Parse from "parse";

import * as CourseRequestsHelper from "../../../helpers/ParseHelpers/courseRequests_helper";
import * as actionTypes from "./actionTypes";
import * as Constants from '../../../Constents';
import * as CourseRequestsActions from "./actions";

function* getCourseRequests({ payload: { courseId } }) {
    try {
        yield put(CourseRequestsActions.updateCoureseRequestsInitialLoading(true));
        var key = Constants.COURSE_REQUESTS_UPDATED_TIME_FOR_OWNER_ID + "_" + courseId;
        var updatedTime = localStorage.getItem(key)
        var newTime = new Date();
        const localResponse = yield call(CourseRequestsHelper.getCourseRequests, courseId, null, true);
        yield put(CourseRequestsActions.updateCoureseRequests(localResponse));

        if (localResponse && localResponse.length > 0) {
            yield put(CourseRequestsActions.updateCoureseRequestsInitialLoading(false));
        }

        const serverResponse = yield call(CourseRequestsHelper.getCourseRequests, courseId, updatedTime, false);
        localStorage.setItem(key, newTime)
        if (!localResponse || localResponse.length === 0) {
            yield put(CourseRequestsActions.updateCoureseRequestsInitialLoading(false));
        }
        if (serverResponse && serverResponse.length > 0) {
            yield put(CourseRequestsActions.updateCoureseRequests(serverResponse));
            Parse.Object.pinAll(serverResponse);
        }
    } catch (error) {
        // yield put(apiError(error));

    }
}

function* getAllCourseRequestsForKidIdSaga({ payload: { kidId } }) {
    try {



        const serverResponse = yield call(CourseRequestsHelper.getAllCourseRequestsForKidId, kidId);

        yield put(CourseRequestsActions.updateCoureseRequestsInitialLoading(false));

        yield put(CourseRequestsActions.updateCoureseRequests(serverResponse));

    } catch (error) {
        // yield put(apiError(error));

    }
}



export function* watchGetCourseRequests() {
    yield takeEvery(actionTypes.GET_COURSE_REQUESTS, getCourseRequests);
}
export function* watchGetAllCourseRequestsForKidIdSaga() {
    yield takeEvery(actionTypes.GET_ALL_COURSE_REQUESTS_FOR_KID_ID, getAllCourseRequestsForKidIdSaga)
}

function* CourseRequestsSaga() {
    yield all([
        fork(watchGetCourseRequests),
        fork(watchGetAllCourseRequestsForKidIdSaga),
    ]);
}
export default CourseRequestsSaga;