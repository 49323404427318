import Parse from "parse";
import { printLog } from "./loghelper";
export const getExpenseSupplierForSchoolId = (schoolId, instituteId, updatedDate, isLoacal) => {
    printLog("HL: expenseSupplier_helper : getExpenseSupplierForSchoolId")

    try {
        return new Promise((resolve, reject) => {
            var Supplier = Parse.Object.extend("Supplier");
           
            var query1 = new Parse.Query(Supplier);
            query1.equalTo("schoolId", schoolId);
           
            var query2 = new Parse.Query(Supplier);
            query2.equalTo("instituteId", instituteId);
            query2.doesNotExist("schoolId");
      
            var query3 = new Parse.Query(Supplier);
            query3.equalTo("isDefault", true);

            var mainQuery = Parse.Query.or(query1, query3);
            if (instituteId) {
                mainQuery = Parse.Query.or(query1, query2, query3);
            }
            if (isLoacal) {
                mainQuery.fromLocalDatastore();
                mainQuery.notEqualTo("isDeleted", true);
            }
            if (updatedDate) {
                mainQuery.greaterThanOrEqualTo("updatedAt", new Date(updatedDate));
            } else {
                mainQuery.notEqualTo("isDeleted", true);
            }

            mainQuery.descending("createdAt");
            mainQuery.limit(1000)
            mainQuery.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve(error);
                }
            );
        });
    } catch (error) { }
};

export const editExpenseSupplier = (object, isInstitute) => {
    printLog("HL: expenseSupplier_helper : editExpenseSupplier")
    try {
        return new Promise((resolve, reject) => {
            object.save().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    resolve("Error");
                }
            );
        });
    } catch (error) { }
};
