import * as actions from "./actionTypes";

const initialState = {
  feeTemplateItems: [],
};

const FeeTemplateItem = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_FEE_TEMPLATE_ITEMS:

      state = { ...state, feeTemplateItems: action.payload.feeTemplateItems };

      break;


    default:
      state = { ...state };
      break;
  }
  return state;
};

export default FeeTemplateItem;
