import * as actions from "./actionTypes";
import * as ReduxUtil from "../../../ReduxUtil";

const initialState = {
    inventoryKitItems: [], loding: undefined,
    selectedInventoryKitItems: undefined,
};

const InventoryKitItems = (state = initialState, action) => {
    switch (action.type) {
        case actions.UPDATE_INVENTORY_KIT_ITEMS:
            var inventoryKitItems = [...state.inventoryKitItems];
            if (inventoryKitItems.length > 0) {
                var updatedList = ReduxUtil.updateParseObjects([...state.inventoryKitItems], action.payload.inventoryKitItems, "isDeleted")

                state = { ...state, inventoryKitItems: updatedList };
            } else {
                var i = 0
                var inventoryKitItemsToSave = []
                for (i = 0; i < action.payload.inventoryKitItems.length; i++) {
                    if (action.payload.inventoryKitItems[i].attributes.isDeleted !== true) {
                        inventoryKitItemsToSave.push(action.payload.inventoryKitItems[i])
                    }
                }
                state = { ...state, inventoryKitItems: inventoryKitItemsToSave };
            }
            break;
            case actions.SET_SELECTED_INVENTORY_KIT_ITEMS:
                state = { ...state, selectedInventoryKitItems: undefined };
                break;
    
            case actions.UPDATE_SELECTED_INVENTORY_KIT_ITEMS:
                state = { ...state, selectedInventoryKitItems: action.payload.inventoryKitItems };
                break;

        case actions.UPDATE_DELETE_INVENTORY_KIT_ITEM:
            var inventoryKitItems = state.inventoryKitItems.filter(
                (inventoryKitItems) => inventoryKitItems.id !== action.payload.inventoryKitItems.id
            );
            state = { ...state, inventoryKitItems: inventoryKitItems };
            break;
        default:
            state = { ...state };
            break;

    }
    return state;
};

export default InventoryKitItems;
