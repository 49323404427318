import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  Form,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
  CardBody,
  UncontrolledTooltip,
} from "reactstrap";
import Editable from "react-bootstrap-editable";
import { matchPath } from "react-router";
import Moment from "moment";

import toastr from "toastr";
import contentImage from "../../assets/images/getStartedImages/customFormInitial.svg";

import Parse from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import SweetAlert from "react-bootstrap-sweetalert";
import { AvForm, AvField } from "availity-reactstrap-validation";

import {
  setSelecetdSchool,
  getTeachersForSchool,
  getExamsForSchoolIds,
  saveExam,
  clearExamAfterSave,
} from "../../store/actions";
import TopBar from "./Topbar";
import * as WhiteLable from "../../components/Common/WhiteLable";
import * as Constants from "../../Constents";

const ExamDetailPage = (props) => {
  const match = matchPath(props.history.location.pathname, {
    path: "/exams/:schoolId",
    exact: true,
    strict: false,
  });

  var schoolId = undefined;

  if (match) {
    schoolId = match.params.schoolId;
  }

  const [schoolType, setSchoolType] = useState(1);
  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }else{
      setSchoolType(props.selectedSchool.attributes.schoolType);
    }
    fetchExams(schoolId);
    props.getTeachersForSchool(schoolId);
  }, [props.selectedSchool, schoolId]);

  const [examVisibility, setExamVisibility] = useState(2);

  const [nameError, setNameError] = useState(false);
  const [examDefaultMarks, setExamDefaultMarks] = useState(undefined);
  const [defaultMarksError, setDefaultMarksError] = useState(undefined);

  const [examPassPercentage, setExamPassPercentage] = useState(undefined);
  const [passPercentageError, setPassPercentageError] = useState(false);
  const [createNewExamView, setCreateNewExamView] = useState(false);
  const [displayList, setDisplayList] = useState([]);
  const [showExamLinkModal, setShowExamLinkModal] = useState(false);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [examName, setExamName] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [examToDelete, setExamToDelete] = useState(undefined);
  const [selectedExam, setSelectedExam] = useState(undefined);
  const [forInstitute, setForInstitute] = useState(false);
  const [createNewExamInProgress, setCreateNewExamInProgress] = useState(false);
  const [isEditModeOn, setIsEditModeOn] = useState(false);
  const [currentEditQuizObject, setCurrentEditQuizObject] = useState(undefined);

  const [showDuplicateAlert, setAhowDuplicateAlert] = useState(undefined);

  const fetchExams = (schoolId) => {
    let ids = [];

    if (props.selectedSchool) {
      //ids.push(props.selectedSchool.id);
      ids.push(props.selectedSchool.attributes.instituteId);
      if (props.selectedSchool.attributes.franchiseId) {
        ids.push(props.selectedSchool.attributes.franchiseId);
      }
    }
    ids.push(schoolId);

    if (ids.length > 0) {
      props.getExamsForSchoolIds(ids);
    }
  };

  useEffect(() => {
    if (props.exams != null) {
      setIsLoading(false);
      if (props.exams.length > 0) {
        setDisplayList(props.exams);
      } else {
        //setIsQuestionsAvailable(false);
      }
    }
  }, [props.exams]);

  const [showCreateExamModal, setShowCreateExamModal] = useState(false);

  function removeElement(array, elem) {
    var index = array.indexOf(elem);
    if (index > -1) {
      array.splice(index, 1);
    }
  }
  function copyExamLink(str, event) {
    document.oncopy = function (event) {
      event.clipboardData.setData("Text", str);
      event.preventDefault();
    };
    document.execCommand("Copy");
    document.oncopy = undefined;
    toastr.success(props.t("Link copied to clipboard"), { timeOut: 1000 });
  }

  const handleOnClickBackButton = () => {
    setIsEditModeOn(false);
    setCreateNewExamView(false);
  };

  const resetVariables = () => {
    props.clearExamAfterSave();
    setExamName("");
    setExamPassPercentage(undefined);
    setPassPercentageError(false);
    setNameError(false);
    setExamVisibility(2);
    setForInstitute(false);
    setCreateNewExamInProgress(false);
  };

  const createNewExam = () => {
    let Exam = Parse.Object.extend("Quiz");
    let exams = new Exam();

    exams.set("name", examName);

    if (Parse.User.current().attributes.teacherId) {
      exams.set("createdBy", Parse.User.current().attributes.teacherId);
    } else {
      exams.set("createdBy", Parse.User.current().id);
    }

    exams.set("passPercentage", parseInt(examPassPercentage, 10));
    exams.set("schoolId", props.selectedSchool.id);
    exams.set("defaultMarks", parseInt(examDefaultMarks, 10));
    exams.set("quizVisibility", examVisibility);
    props.saveExam(exams);
    // exams.save().then(
    //   (examResult) => {

    //     resetVariables();
    //     props.history.push("/addExam/" + schoolId + "/" + examResult.id);
    //   },
    //   (error) => {}
    // );
  };

  useEffect(() => {
    if (props.examAfterSave) {
      resetVariables();
      props.history.push("/addExam/" + schoolId + "/" + props.examAfterSave.id);
      setIsEditModeOn(false);
    }
  }, [props.examAfterSave]);

  const handleEditDoneButton = () => {
    let exams = currentEditQuizObject;

    exams.set("name", examName);
    exams.set("passPercentage", parseInt(examPassPercentage, 10));
    exams.set("quizVisibility", examVisibility);
    exams.set("defaultMarks", parseInt(examDefaultMarks, 10));
    props.saveExam(exams);
    // exams.save().then(
    //   (examResult) => {
    //     resetVariables();
    //     props.history.push("/addExam/" + schoolId + "/" + examResult.id);
    //     setIsEditModeOn(false);
    //   },
    //   (error) => {}
    // );
  };

  const canShowCheckboxForAllSchool = () => {
    let returnValue = false;

    if (Parse.User.current() && Parse.User.current().attributes.isSuperUser) {
      if (Parse.User.current().attributes.teacherId) {
        if (
          props.userTeacher &&
          props.userTeacher.attributes.instituteId &&
          props.userTeacher.attributes.instituteId.length > 0
        ) {
          returnValue = true;
        }
      } else {
        returnValue = true;
      }
    }

    return returnValue;
  };

  const getTeacherName = (teacherId) => {
    let returnValue = "-";
    if (props.teachers && props.teachers.length > 0) {
      let tempUser = props.teachers.filter((teacher) => {
        return teacher.id === teacherId;
      });
      if (tempUser && tempUser.length > 0) {
        returnValue = tempUser[0].attributes.Name;
      }
    }

    return returnValue;
  };

  const handleExamNameChange = (value, object) => {
    if (value.length > 0 && object) {
      object.set("name", value);
      object.save().then(
        (result) => {
          toastr.success("Successfully changed exam name", { timeOut: 1000 });
          let index = displayList.indexOf(object);
          let tempList = [...displayList];
          tempList[index] = result;
          setDisplayList(tempList);
        },
        (error) => {
          toastr.success("Something went wrong, please try again", {
            timeOut: 1000,
          });
        }
      );
    }
  };

  const getNewQuizObject = (exam) => {
    let Exam = Parse.Object.extend("Quiz");
    let exams = new Exam();
    let name = "copy-" + exam.attributes.name
    exams.set("name", name);
    exams.set("defaultMarks", exam.attributes.defaultMarks);
    exams.set("quizVisibility", exam.attributes.quizVisibility);
    exams.set("activityId", exam.attributes.activityId);
    exams.set("passPercentage", exam.attributes.passPercentage);
    exams.set("isDeleted", exam.attributes.isDeleted);
    exams.set("schoolId", exam.attributes.schoolId);
    exams.set("InstituteId", exam.attributes.InstituteId);
    exams.set("franchiseId", exam.attributes.franchiseId);
    exams.set("categoryId", exam.attributes.categoryId);
    exams.set("totalMarks", exam.attributes.totalMarks);
    exams.set("totalQuestions", exam.attributes.totalQuestions);
    exams.set("createdBy", exam.attributes.createdBy);
    return exams
  }

  const replicateSection = (newSection, oldSection) => {
    let tempObject = newSection
    tempObject.set("sectionName", oldSection.attributes.sectionName)
    tempObject.set("order", oldSection.attributes.order)
    tempObject.set("isDeleted", oldSection.attributes.isDeleted)
    return tempObject

  }

  const replicateQestions = (oldQuestions, quizId, newSectionId) => {

    let questionsToSave = []
    for (var i = 0; i < oldQuestions.length; i++) {
      let ExamQuestions = Parse.Object.extend("QuizQuestion");
      let examQuestion = new ExamQuestions();

      examQuestion.set("sectionId", newSectionId)
      examQuestion.set("defaultMarks", oldQuestions[i].attributes.defaultMarks ?? 1)
      examQuestion.set("optionImgs", oldQuestions[i].attributes.optionImgs)
      examQuestion.set("option2Imgs", oldQuestions[i].attributes.option2Imgs)
      examQuestion.set("question", oldQuestions[i].attributes.question)
      examQuestion.set("order", oldQuestions[i].attributes.order)
      examQuestion.set("isDeleted", oldQuestions[i].attributes.isDeleted)
      examQuestion.set("quizId", quizId)
      examQuestion.set("options2", oldQuestions[i].attributes.options2)
      examQuestion.set("questionImage", oldQuestions[i].attributes.questionImage)
      examQuestion.set("type", oldQuestions[i].attributes.type)
      examQuestion.set("isDefaultQuestion", oldQuestions[i].attributes.isDefaultQuestion)
      examQuestion.set("options", oldQuestions[i].attributes.options)
      examQuestion.set("answer", oldQuestions[i].attributes.answer)

      questionsToSave.push(examQuestion)
    }






    return questionsToSave
  }

  const handleDuplicateExam = (quiz) => {
    setIsLoading(true)
    let query = new Parse.Query('QuizSection');
    query.equalTo('quizId', quiz.id);
    query.notEqualTo('isDeleted', true);
    query.find().then((oldQuizSections) => {
      let sectionIds = [];
      let newSectionObjectsToSave = []
      for (const i in oldQuizSections) {
        sectionIds.push(oldQuizSections[i].id);
      }

      let queryQ = new Parse.Query('QuizQuestion');
      queryQ.equalTo('quizId', quiz.id);
      queryQ.notEqualTo('isDeleted', true);
      queryQ.containedIn("sectionId", sectionIds);
      queryQ.find().then((oldQuizQuestions) => {
        let newQuizObjectTosave = getNewQuizObject(quiz); // getNewQuizObject(quiz) Function u need to write
        newQuizObjectTosave.save().then((newQuizObject) => {
          for (const i in oldQuizSections) {
            let Section = Parse.Object.extend("QuizSection");
            let sections = new Section();
            sections.set("quizId", newQuizObject.id)
            newSectionObjectsToSave.push(sections)
          }
          if (newSectionObjectsToSave.length > 0) {
            Parse.Object.saveAll(newSectionObjectsToSave).then((newSectionObjects) => {
              let newQuestionObjects = [];
              let newSectionswithData = [];
              for (const i in oldQuizSections) {
                let tempOldQuestions = oldQuizQuestions.filter(
                  (q) =>
                    q.attributes.sectionId ===
                    oldQuizSections[i].id
                );
                let tempSection = replicateSection(newSectionObjects[i], oldQuizSections[i]);
                // replicateSection(newSectionObjects[i], oldQuizSections[i]) Function u need to write
                let tempQuestions = replicateQestions(tempOldQuestions, newQuizObject.id, newSectionObjects[i].id);
                // replicateQestions(tempOldQuestions, quiz.id, oldQuizSections[i].id)Function u need to write
                newSectionswithData.push(tempSection);
                newQuestionObjects = newQuestionObjects.concat(tempQuestions);
              }
              Parse.Object.saveAll(newSectionswithData).then((rqs) => {
                if (newQuestionObjects.length > 0) {
                  Parse.Object.saveAll(newQuestionObjects).then((rqq) => {
                    setIsLoading(false);
                    resetVariables();
                    props.history.push("/addExam/" + schoolId + "/" + newQuizObject.id);
                  });
                } else {
                  setIsLoading(false);
                  resetVariables();
                  props.history.push("/addExam/" + schoolId + "/" + newQuizObject.id);
                }

              });
            });
          } else {
            setIsLoading(false);
            resetVariables();
            props.history.push("/addExam/" + schoolId + "/" + newQuizObject.id);
          }

        })
      })


    })
  }



  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading && !createNewExamView && !isEditModeOn && (
          <Container fluid>
            {showExamLinkModal && (
              <Modal
                isOpen={showExamLinkModal}
                toggle={() => {
                  setShowExamLinkModal(false);
                }}
              >
                <ModalHeader>{schoolType==10? "Assessments Link": "Exam Link"}</ModalHeader>
                <ModalBody>
                  <Card className="border-1">
                    <Row>
                      <Col lg={10}>
                        <p
                          disabled
                          type="text"
                          className=" m-2 text-primary"
                          id="linkarea"
                        >
                          {WhiteLable.getHost() +
                            "/editcustomexam/" +
                            schoolId +
                            "/" +
                            selectedExam.id}
                        </p>
                      </Col>

                      <Col
                        lg={2}
                        id="examLink"
                        onClick={(e) => {
                          copyExamLink(
                            WhiteLable.getHost() +
                            "/editcustomexam/" +
                            schoolId +
                            "/" +
                            selectedExam.id,
                            e
                          );
                        }}
                      >
                        <li className="list-inline-item px-2">
                          <h1
                            style={{ cursor: "pointer" }}
                            className="text-primary"
                          >
                            <i className="bx bxs-copy"></i>
                          </h1>
                          <p style={{ cursor: "pointer" }}>{props.t("copy")}</p>
                          <UncontrolledTooltip
                            placement="top"
                            target="examLink"
                          >
                            {props.t("copy to clipboard")}
                          </UncontrolledTooltip>
                        </li>
                      </Col>
                    </Row>
                  </Card>
                </ModalBody>
                <ModalFooter>
                  <button
                    type="button"
                    className="btn btn-secondary"
                    onClick={() => {
                      setShowExamLinkModal(false);
                    }}
                  >
                    {props.t("Close")}
                  </button>
                </ModalFooter>
              </Modal>
            )}

            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div className=' mb-3'>
                <h4> {schoolType==10? "Assessments":"Exams"}</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end mb-2" style={{ marginTop: '30px' }}>
                <Button
                    className="btn btn-primary waves-effect waves-light "
                    //to={"/addForm/" + schoolId}
                    //role="button"
                    color={"primary"}
                    onClick={() => {
                      resetVariables();

                      setCreateNewExamView(true);
                    }}
                  >
                   {schoolType===10? "Create Assessments":"Create Exams"} 
                  </Button>
                </div>
              </Col>
            </Row>
            <Card>
              {showDeleteAlert && (
                <SweetAlert
                  warning
                  showCancel
                  confirmBtnText="Yes"
                  cancelBtnText="No"
                  confirmBtnBsStyle="danger"
                  cancelBtnBsStyle="success"
                  title="Alert!"
                  onCancel={() => {
                    setShowDeleteAlert(false);
                  }}
                  onConfirm={() => {
                    setIsLoading(true);
                    setShowDeleteAlert(false);
                    examToDelete.set("isDeleted", true);
                    examToDelete.save().then(
                      (result) => {
                        setIsLoading(false);
                        let tempList = [...displayList];
                        let tempIndex = tempList.indexOf(examToDelete);

                        removeElement(tempList, displayList[tempIndex]);
                        setDisplayList(tempList);
                        toastr.success(schoolType==10?"Assessment deleted successfully":"Exam deleted successfully !!", {
                          timeOut: 2000,
                        });
                      },
                      (error) => {
                        setIsLoading(false);
                        toastr.warning(
                          "Something went wrong, please try again !!",
                          { timeOut: 2000 }
                        );
                      }
                    );
                  }}
                >
                  {schoolType==10?" Are you sure to delete this Assessment?":"Are you sure to delete this Exam?"}<br></br>
                </SweetAlert>
              )}
              <CardBody>
                {displayList.length > 0 && (
                  <div className="table-responsive">
                    <table className="table table-centered  mb-0">
                      <thead className="thead-light">
                        <tr>
                          <th style={{ width: "60% !important" }}>
                            {props.t("Name")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Type")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Created On")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Created By")}
                          </th>
                          <th style={{ textAlign: "center", width: "120px" }}>
                            {props.t("View Results")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Link")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Duplicate")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Setup")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Edit")}
                          </th>
                          <th style={{ textAlign: "center" }}>
                            {props.t("Delete")}
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {displayList.map((object, key) => (
                          <tr key={key}>
                            <td>
                              <Label className="font-size-12 mb-1">
                                {object.attributes.name}
                              </Label>

                              {/* <Editable
                                alwaysEditing={false}
                                onValidated={(value) => {
                                  handleExamNameChange(value, object);
                                }}
                                disabled={false}
                                editText={object.attributes.name?" ✎":"No value ✎"}
                                id={null}
                                isValueClickable={false}
                                mode="inline"
                                placement="top"
                                initialValue={object.attributes.name}
                                validate={(value) => {
                                  if (value.length === 0) {
                                    return props.t(
                                      "Exam name cannot be empty !!"
                                    );
                                  }
                                }}
                                showText
                                type="textfield"
                              /> */}
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {object.attributes.quizVisibility === 2 &&
                                  "Public"}
                                {object.attributes.quizVisibility === 1 &&
                                  "Private"}
                              </Label>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {Moment(object.createdAt).format(
                                  "DD-MM-YYYY, h:mm a"
                                )}
                              </Label>
                            </td>
                            <td style={{ textAlign: "center" }}>
                              <Label className="font-size-12 mb-1">
                                {getTeacherName(object.attributes.createdBy)}
                              </Label>
                            </td>

                            <td style={{ textAlign: "center" }}>
                              <Link
                                to={"/examview/" + schoolId + "/" + object.id}
                              >
                                <Button
                                  type="button"
                                  color="primary"
                                  size="sm"
                                  className="btn-rounded waves-effect waves-light"
                                >
                                  {props.t("View Results")}
                                </Button>
                              </Link>
                            </td>

                            <td>
                              <div className="flex-fill text-center ">
                                {object.attributes.quizVisibility === 2 && (
                                  <Link
                                    to={"#"}
                                    id={"form-link" + key}
                                    onClick={() => {
                                      setSelectedExam(object);
                                      setShowExamLinkModal(!showExamLinkModal);
                                    }}
                                  >
                                    <i className="font-size-22 bx bx-link"></i>
                                  </Link>
                                )}
                              </div>
                            </td>

                            <td>
                              <div className="flex-fill text-center ">

                                <Link
                                  id={"duplicate-exam" + key}
                                  onClick={() => {
                                    setAhowDuplicateAlert(object)
                                  }}
                                >
                                  <i className=" font-size-18  bx bx-copy"></i>
                                </Link>

                              </div>
                            </td>

                            <td>
                              <div className="flex-fill text-center ">
                                <Link
                                  to={"/addExam/" + schoolId + "/" + object.id}
                                  id={"examSetup" + key}
                                >
                                  {/* <i className="bx bx-edit-alt"></i> */}
                                  <i className=" font-size-18  bx bx-right-arrow"></i>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <div className="flex-fill text-center ">
                                <Link
                                  id={"editExamName" + key}
                                  to="#"
                                  onClick={() => {
                                    setCurrentEditQuizObject(object);
                                    setIsEditModeOn(true);
                                    setExamPassPercentage(
                                      object.attributes.passPercentage
                                    );
                                    setExamVisibility(
                                      object.attributes.quizVisibility
                                    );
                                    setExamDefaultMarks(
                                      object.attributes.defaultMarks
                                    );
                                    setExamName(object.attributes.name);
                                  }}
                                >
                                  <i className=" font-size-18 bx bx-edit-alt"></i>
                                </Link>
                              </div>
                            </td>
                            <td>
                              <div className="flex-fill text-center">
                                <Link
                                  to={"#"}
                                  onClick={() => {
                                    setExamToDelete(object);
                                    setShowDeleteAlert(true);
                                  }}
                                >
                                  <i className=" font-size-18  bx bxs-trash text-danger"></i>
                                </Link>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                )}
                {displayList.length === 0 && (
                  <p className="text-center">{schoolType?"No Assessments to display":"No Exams to display !!"}</p>
                )}
              </CardBody>
            </Card>
          </Container>
        )}

        {(createNewExamView || isEditModeOn) && (
          <React.Fragment>
            <TopBar
              title={isEditModeOn ? schoolType===10? "Edit Assessment":"Edit Exam" : schoolType===10?"Create New Assessment" :"Create New Exam"}
              buttonName={""}
              backButton={true}
              hideButton={true}
              onClickAdd={() => { }}
              onClickBackButton={handleOnClickBackButton}
            />
            <Card>
              <CardBody>
                <Row>
                  <Col sm={7}>
                    <AvForm className="outer-repeater">
                      <div data-repeater-item className="outer">
                        <FormGroup>
                          <Label>
                            <h3 className="mt-5">
                              {schoolType===10? props.t("Give this Assessment a name !!") : props.t("Give this Exam a name !!")}
                            </h3>{" "}
                          </Label>
                          <AvField
                            type="text"
                            name="name"
                            placeholder={props.t("Name")}
                            className="form-control"
                            value={examName}
                            onChange={(e) => {
                              if (e.target.value.length > 0 && nameError) {
                                setNameError(false);
                              }
                              setExamName(e.target.value);
                            }}
                          />
                          {nameError && (
                            <p className="text-danger">
                              {" "}
                              {props.t("Name can not be empty")}
                            </p>
                          )}
                          <h6>Pass Percentage</h6>
                          <AvField
                            type="number"
                            name="passPercentage"
                            placeholder={props.t("Pass Percentage")}
                            className="form-control"
                            value={examPassPercentage}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                passPercentageError
                              ) {
                                setPassPercentageError(false);
                              }
                              setExamPassPercentage(e.target.value);

                              if (e.target.value > 100) {
                                setExamPassPercentage(100);
                              }
                              if (e.target.value < 0) {
                                setExamPassPercentage(0);
                              }
                            }}
                          />
                          {passPercentageError && (
                            <p className="text-danger">
                              {" "}
                              {props.t("Pass percentage can not be empty")}
                            </p>
                          )}
                          <h6>Default Marks Per Question</h6>
                          <AvField
                            type="number"
                            name="defaultMarks"
                            placeholder={props.t("Default marks")}
                            className="form-control"
                            value={examDefaultMarks}
                            onChange={(e) => {
                              if (
                                e.target.value.length > 0 &&
                                defaultMarksError
                              ) {
                                setDefaultMarksError(false);
                              }
                              setExamDefaultMarks(e.target.value);
                            }}
                          />
                          {defaultMarksError && (
                            <p className="text-danger">
                              {" "}
                              {props.t("Default marks can not be empty")}
                            </p>
                          )}
                        </FormGroup>

                        {!isEditModeOn &&  schoolType != 10 &&(
                          <FormGroup className="ml-4">
                            <div className="d-flex m-1">
                              <input
                                className="form-check-input m-1"
                                type="radio"
                                id={"radioButton2"}
                                value={2}
                                checked={examVisibility === 2}
                                onChange={() => {
                                  setExamVisibility(2);
                                }}
                              />
                              <div className="float-right ">
                                <label
                                  className="form-check-label"
                                  htmlFor={"radioButton2"}
                                >
                                  {
                                    "Public Exam - Students from outside institutions can also be able to attempt exam"
                                  }
                                </label>
                              </div>
                            </div>
                            <div className="d-flex m-1">
                              <input
                                className="form-check-input m-1"
                                type="radio"
                                id={"radioButton1"}
                                value={1}
                                checked={examVisibility === 1}
                                onChange={() => {
                                  setExamVisibility(1);
                                }}
                              />
                              <div className="float-right">
                                <label
                                  className="form-check-label"
                                  htmlFor={"radioButton1"}
                                >
                                  {
                                    "Private Exam - Only for Students having liltriangle app login can attempt exam"
                                  }
                                </label>
                              </div>
                            </div>
                          </FormGroup>
                        )}

                        {/* <div>
                          <Label className="alert-link ml-2">
                            * Private Exam -{" "}
                          </Label>
                          Only for Students having liltriangle app login can
                          attempt exam.
                        </div> */}
                        {/* <div>
                          <Label className="alert-link ml-2">
                            * Public Exam -{" "}
                          </Label>
                          Students from outside institutions can also be able to
                          attempt exam
                        </div> */}

                        {/* {canShowCheckboxForAllSchool() && !isEditModeOn && (
                          <FormGroup>
                            <div className="custom-control custom-checkbox mb-2 ml-3">
                              <input
                                type="checkbox"
                                className="custom-control-input"
                                id="instituteCheck"
                                onChange={() => setForInstitute(!forInstitute)}
                                checked={forInstitute}
                              />
                              <label
                                className="custom-control-label"
                                onClick={() => {
                                  setForInstitute(!forInstitute);
                                }}
                              >
                                {props.t("Create this Exam for all schools")}
                              </label>
                            </div>
                          </FormGroup>
                        )} */}
                      </div>
                    </AvForm>

                    <Button
                      onClick={() => {
                        let canProceed = true;
                        if (
                          examName == undefined ||
                          examName === "" ||
                          examName.length === 0
                        ) {
                          setNameError(true);
                          canProceed = false;
                        }
                        if (
                          examPassPercentage == undefined ||
                          examPassPercentage === ""
                        ) {
                          setPassPercentageError(true);
                          canProceed = false;
                        }

                        if (canProceed) {
                          setCreateNewExamInProgress(true);
                          if (isEditModeOn) {
                            handleEditDoneButton();
                          } else {
                            createNewExam();
                          }
                        }
                      }}
                      color="primary"
                      disabled={createNewExamInProgress}
                      style={{
                        marginLeft: "auto",
                        marginRight: "auto",
                        width: "180px",
                      }}
                    >
                      {createNewExamInProgress && (
                        <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>
                      )}
                      {isEditModeOn ? props.t("Done") : props.t("Next")}
                    </Button>
                  </Col>
                  <Col sm={5}>
                    <div className="maintenance-img">
                      <img
                        src={contentImage}
                        alt=""
                        className="img-fluid mx-auto d-block"
                      />
                    </div>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </React.Fragment>
        )}

        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>

      {showDuplicateAlert && (
        <SweetAlert
          warning
          showCancel
          confirmBtnText="Yes"
          cancelBtnText="No"
          confirmBtnBsStyle="success"
          cancelBtnBsStyle="danger "
          title="Alert!"
          onCancel={() => {
            setAhowDuplicateAlert(undefined);
          }}
          onConfirm={() => {
            handleDuplicateExam(showDuplicateAlert);
            setAhowDuplicateAlert(undefined);
          }}
        >
          {" Are you sure you want to duplicate " + showDuplicateAlert.attributes.name + " Exam?"}<br></br>
        </SweetAlert>
      )}
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { selectedSchool } = state.School;
  const { teachers } = state.Teacher;
  const { userTeacher } = state.Login;
  const { exams, examAfterSave } = state.Exam;

  return { selectedSchool, teachers, userTeacher, exams, examAfterSave };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, {
      setSelecetdSchool,
      getTeachersForSchool,
      getExamsForSchoolIds,
      saveExam,
      clearExamAfterSave,
    })(ExamDetailPage)
  )
);
