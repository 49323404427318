import * as actions from "./actionTypes";

import * as ReduxUtil from '../../ReduxUtil'

const initialState = {
  invoices: [],
  responseForCreateInvoice: undefined,
  showLoader: true,
  successRes: false,
  showLoaderCreateInvoice: false,
  successResCreateInvoice: false,
  successUpdateInvoice: false,
  invoice: {},
  deleteInProgress: false,
  deleteSuccess: false,
  paymentOptions: [],
  invoiceCount: 0,
  invoiceCountList: {},
  invoiceEditLoader: false,
  invoicesForProgram: undefined,
  invoicesForProgramLoader: undefined,
  recurringMasterInvoices: [],
  recurringInvoices: [],
};

const Invoice = (state = initialState, action) => {
  switch (action.type) {
    case actions.UPDATE_INVOICE:
      var invoices = [...state.invoices];
      var temp = state.invoices.filter(
        (invoice) => invoice.id === action.payload.invoice.id
      );
      if (temp.length > 0) {
        var index = invoices.indexOf(temp[0]);
        invoices[index] = action.payload.invoice;
        state = { ...state, invoices: invoices, invoiceEditLoader: false };
      } else {
        invoices.push(action.payload.invoice);
        state = { ...state, invoices: invoices, invoiceEditLoader: false, };
      }
      break;

    case actions.RESPONSE_FOR_CREATE_INVOICE_FOR_KIDS:
      state = {
        ...state,
        responseForCreateInvoice: action.payload.response,
      };
      break;
    case actions.SET_INVOICE_UPDATE:
      var invoices = [...state.invoices];
      if (action.payload.serverObj) {
        if (invoices.length > 0) {
          // for (const latest in action.payload.serverObj) {
          var temp = invoices.filter(
            (invoice) => invoice.id === action.payload.serverObj.id
          );
          if (temp.length > 0) {
            var index = invoices.lastIndexOf(temp[0]);
            invoices[index] = action.payload.serverObj;
          } else {
            invoices.unshift(action.payload.serverObj);
          }
          // }
          state = { ...state, invoices: invoices };
        } else {
          invoices = [];
          if (Array.isArray(action.payload.serverObj)) {
            invoices = action.payload.serverObj;
          } else {
            invoices.push(action.payload.serverObj);
          }
          state = { ...state, invoices: invoices };
        }
      }
      state = {
        ...state,
        showLoaderCreateInvoice: false,
        successResCreateInvoice: true,
      };
      break;
    case actions.SET_INVOICE_SUCCESS_COMPLETE:
      state = { ...state, successResCreateInvoice: false };
      break;
    case actions.SET_UPDATE_INVOICE_UPDATE:
      // var invoices = [...state.invoices];
      // if (invoices.length > 0) {
      //   // for (const latest in action.payload.serverObj) {
      //   var temp = invoices.filter(
      //     (teacher) => teacher.id === action.payload.serverObj.id
      //   );
      //   if (temp.length > 0) {
      //     var index = invoices.lastIndexOf(temp[0]);
      //     invoices[index] = action.payload.serverObj;
      //   } else {
      //     invoices.push(action.payload.serverObj);
      //   }
      //   // }
      //   state = { ...state, invoices: invoices };
      // } else {
      //   state = { ...state, invoices: action.payload.invoices };
      // }
      state = {
        ...state,
        showLoaderCreateInvoice: false,
        successUpdateInvoice: true,
        showLoader: false,
      };
      break;
    case actions.SAVE_INVOICE:
      state = { ...state, invoiceEditLoader: true, };
      break;
    case actions.SUCCESS_UPDATE_INVOICE_VALUE:
      state = { ...state, successUpdateInvoice: false };
      break;
    case actions.SET_INVOICES:
      var invoices = [...state.invoices];
      if (invoices.length > 0) {
        for (const latest in action.payload.invoices) {
          var temp = invoices.filter(
            (invoice) => invoice.id === action.payload.invoices[latest].id
          );
          if (temp.length > 0) {
            var index = invoices.lastIndexOf(temp[0]);
            invoices[index] = action.payload.invoices[latest];
          } else {
            invoices.unshift(action.payload.invoices[latest]);
          }
        }
        state = { ...state, invoices: invoices };
      } else {
        state = { ...state, invoices: action.payload.invoices };
      }
      state = { ...state, showLoader: false, successUpdateInvoice: false };

      break;
    case actions.SET_INVOICE_COUNT:
      state = { ...state, invoiceCount: action.payload.invoiceCount };
      break;
    case actions.DELETE_INVOICE:
      var invoices = state.invoices.filter(
        (invoice) => invoice.id !== action.payload.id
      );
      state = { ...state, invoices: invoices };
      break;
    case actions.GET_INVOICES_FOR_FILTERS:
      state = { ...state, showLoader: true, invoices: [] };
    case actions.UPDATE_INVOICE_AND_ITEMS:
      state = { ...state, showLoader: true, successRes: false };
      break;
    case actions.CREATE_INVOICE_WITH_FUNCTION:
      state = {
        ...state,
        showLoaderCreateInvoice: true,
        successUpdateInvoice: false,
        successResCreateInvoice: false,
      };
      break;
    case actions.SET_INVOICE:
      state = { ...state, invoice: action.payload.obj };
      break;
    case actions.DELETE_SELECTED_INVOICE:
      state = { ...state, deleteInProgress: true, deleteSuccess: false };
      break;
    case actions.SET_DELETE_INVOICE:
      let tempInvoice = Object.assign([], state.invoices);
      for (const i in tempInvoice) {
        if (tempInvoice[i].id === action.payload.delObj.id) {
          tempInvoice.splice(i, 1);
          break;
        }
      }
      state = {
        ...state,
        invoices: tempInvoice,
        deleteInProgress: false,
        deleteSuccess: true,
      };
      break;
    
    case actions.GET_INVOICE_COUNT_FOR_SCHOOLID:
      state = { ...state, invoiceCountList: {} };
      break;
    case actions.SET_INVOICE_COUNT_FOR_SCHOOLID:
      state = { ...state, invoiceCountList: action.payload.counts };
      break;
    case actions.GET_INVOICES_FOR_PROGRAM_ID:
      if (!action.payload.reload) {
        state = { ...state, invoicesForProgram: undefined };
      }
      break;

    case actions.SET_PROGRAM_INVOICE_CREATION_LOADER:
      state = { ...state, invoicesForProgramLoader: action.payload.boolValue };
      break

    case actions.UPDATE_INVOICES_FOR_PROGRAM_ID:
      if (action.payload.reload) {
        let invoicesForProgram = [...state.invoicesForProgram];
        if (invoicesForProgram.length > 0) {
          for (const latest in action.payload.invoicesForProgram) {
            let temp = invoicesForProgram.filter(
              (i) =>
                i.id === action.payload.invoicesForProgram[latest].id
            );


            if (temp.length > 0) {
              if (action.payload.invoicesForProgram[latest].attributes.isDeleted) {

                let others = invoicesForProgram.filter(
                  (i) =>
                    i.id !== action.payload.invoicesForProgram[latest].id
                );

                invoicesForProgram = others;
              } else {
                let index = invoicesForProgram.lastIndexOf(temp[0]);
                invoicesForProgram[index] = action.payload.invoicesForProgram[latest];
              }

            } else {
              invoicesForProgram.push(action.payload.invoicesForProgram[latest]);
            }
          }
          state = { ...state, invoicesForProgram: invoicesForProgram };
        } else {
          var others = action.payload.invoicesForProgram.filter(
            (i) => (!i.attributes.isDeleted)
          );
          state = { ...state, invoicesForProgram: others };

        }
      } else {
        state = { ...state, invoicesForProgram: action.payload.invoicesForProgram ?? [], showLoader: false };
      }
      break;
    
    
    case actions.UPDATE_RECURRING_MASTER_INVOICES_FOR_SCHOOLID:

      var recurringMasterInvoices = [...state.recurringMasterInvoices];
      if (recurringMasterInvoices.length > 0) {
        let list = ReduxUtil.updateParseObjects(recurringMasterInvoices, action.payload.masterInvoices, "isDeleted", false)
        state = { ...state, recurringMasterInvoices: list };
      } else {
        state = { ...state, recurringMasterInvoices: action.payload.masterInvoices };
      }
      
      break;
    
    case actions.UPDATE_RECURRING_INVOICES_FOR_SCHOOLID:

      var recurringInvoices = [...state.recurringInvoices];
      if (recurringInvoices.length > 0) {
        let list = ReduxUtil.updateParseObjects(recurringInvoices, action.payload.recurringInvoices, "isDeleted", false)
        state = { ...state, recurringInvoices: list };
      } else {
        state = { ...state, recurringInvoices: action.payload.recurringInvoices };
      }

      break;
      
    case actions.REMOVE_RECURRING_MASTER_INVOICE:
      var recurringMasterInvoices = [...state.recurringMasterInvoices];
      if (recurringMasterInvoices.length > 0) {
        
        if (action.payload.masterInvoice) {
          var others = recurringMasterInvoices.filter(
            (recurringMasterInvoice) =>
              recurringMasterInvoice.id !== action.payload.masterInvoice.id
          );
          state = { ...state, recurringMasterInvoices: others };
        }
        
      } 

    default:
      state = { ...state };
      break;
  }
  return state;
};

export default Invoice;
