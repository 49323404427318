import React, { useState,useEffect } from 'react';
import Parse from 'parse';
import { withRouter,Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Container,Col,Alert,Row, Card, CardBody,CardTitle ,Label,Modal,ModalBody,ModalFooter,ModalHeader,Table} from "reactstrap";
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx/xlsx.mjs';
import Moment from 'moment';
import { matchPath } from "react-router";
import { withNamespaces } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import * as Constants from '../../Constents';
import {  setSelecetdSchool,getClassroomsForSchoolId } from '../../store/actions';
import StudentImportList from './StudentImportList';
import Topbar from './Topbar';
import * as WhiteLable from '../../components/Common/WhiteLable'
import Licence from '../../components/Common/Licence'

const Index = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/student-import/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
        props.getClassroomsForSchoolId(schoolId);
    }, [props.selectedSchool, schoolId]);

     
    const [classNamesList, setClassNamesList] = useState([])
    const [classroomIds, setClassroomIds] = useState([])
    const [rewievId, setRewievId] = useState(null)
    const [bulkUploadLoading, setbulkUploadLoading] = useState(false);
    const Male = ["male", "boy", "b", "m", "he"];
    const Female = ["female", "girl", "g", "f", "she"];
    const [showPopup, setShowPopup] = useState(false);
    const firstRow = ["AdmissionNumber", "StudentName", "Gender", "Class", "MotherName", "MotherPhone", "MotherEmail", "FatherName", "FatherPhone", "FatherEmail", "DateOfBirth",];
    const [tableData, setTableData] = useState([]);
    const [showLimitModal, setShowLimitModal] = useState(false);
    const [loding, setLoading] = useState(false);

    useEffect(() => {
        if (props.classrooms && props.classrooms.length > 0) {
            let list = [];
            let ids = [];
            list.push("Pick class");
            ids.push("");
            for (const i in props.classrooms ) {
                list.push(props.classrooms[i].attributes.Name.trim());
                ids.push(props.classrooms[i].id);
            }
            setClassNamesList(list);
            setClassroomIds(ids);
        }
        
    }, [props.classrooms]);


    const handelUpdateKid = (oldKid,newKid) => {
        let allKids = [...tableData];
        let index = allKids.indexOf(oldKid);
        allKids[index] = newKid;
        
        setTableData(allKids);

    }
    const handelRemoveKid = (kid) => {
        let allKids = [...tableData];
        let newKids = allKids.filter(
            (k) => k.familyId !== kid.familyId
        );
        setTableData(newKids);
    }


    const templateURL = "https://parsefiles.back4app.com/0ZRsOIsU32VP5AhOrDR7nMqrGR7PFkzaSYEPBsNN/8e960e7c05615ab3759c995fdb1d72f0_Students.xlsx";
    const handleUpload = (e) => {
        // e.preventDefault();
    
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            let index = readedData.SheetNames.indexOf("Students");
            if (readedData.SheetNames.length == 1) {
                index = 0;
            }
            const wsname = readedData.SheetNames[index];
            const ws = readedData.Sheets[wsname];
    
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            createJsObjects(dataParse)
            
        };
        reader.readAsBinaryString(f)
    }


    function createJsObjects(dataParse) {
        
        var IstRow = [];
        var allrows = [];
        for (const i in dataParse) {
            if (dataParse[i] && dataParse[i].length > 0) {
                if (i == 0) {
                    IstRow = dataParse[0];
                } else {
                    allrows.push(dataParse[i]);
                }
            }
        }
        if (allrows && allrows.length > 0) {
            var objectList = [];
            for (const j in allrows) {
                var obj = {
                    familyId:undefined,
                    admissionNumber: undefined,
                    studentName: undefined,
                    gender: undefined,
                    admissionClass: undefined,
                    motherName: undefined,
                    motherPhone: undefined,
                    motherEmail: undefined,
                    fatherName: undefined,
                    fatherPhone: undefined,
                    fatherEmail: undefined,
                    dateOfBirth: undefined,
                    classroomId: undefined,
                    
                }
                objectList.push(obj)
            }

        
            console.log(allrows);

            for (const i in IstRow) {
                
                
                for (const j in allrows) {
                    objectList[j].familyId = uuidv4();
                    if (IstRow[i] === firstRow[0]) {
                        objectList[j].admissionNumber = allrows[j][i];
                    } else if (IstRow[i] === firstRow[1]) {
                        objectList[j].studentName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[2]) {
                        var gen = allrows[j][i];
                        if (gen && Female.includes(gen.toLowerCase())) {
                            objectList[j].gender = "Female";
                        } else if (gen && Male.includes(gen.toLowerCase())) {
                            objectList[j].gender = "Male";
                        }
                    } else if (IstRow[i] === firstRow[3]) {
                        if (classNamesList.includes(allrows[j][i])) {
                            objectList[j].admissionClass = allrows[j][i];
                            let index = classNamesList.indexOf(allrows[j][i]);
                            objectList[j].classroomId = classroomIds[index];
                        } 
                    } else if (IstRow[i] === firstRow[4]) {
                        objectList[j].motherName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[5]) {
                        if (allrows[j][i] !== undefined) {
                            const phoneNumber = allrows[j][i] && allrows[j][i].toString().replace(/\s/g, '');
                            objectList[j].motherPhone = phoneNumber;
                        }
                    } else if (IstRow[i] === firstRow[6]) {
                        objectList[j].motherEmail = allrows[j][i];
                    } else if (IstRow[i] === firstRow[7]) {
                        objectList[j].fatherName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[8]) {
                        if (allrows[j][i] !== undefined) {
                            const phoneNumber = allrows[j][i] && allrows[j][i].toString().replace(/\s/g, '');
                            objectList[j].fatherPhone = phoneNumber;
                        }
                    } else if (IstRow[i] === firstRow[9]) {
                        objectList[j].fatherEmail = allrows[j][i];
                    } else if (IstRow[i] === firstRow[10]) {
                        
                        if (Number.isInteger(allrows[j][i])) {
                            var date = ExcelDateToJSDate(allrows[j][i])
                            objectList[j].dateOfBirthString = convertDateToString(date);
                            objectList[j].dateOfBirth = date;
                        } else {
                            objectList[j].dateOfBirthString = allrows[j][i];
                            objectList[j].dateOfBirth = convertStringTodate(allrows[j][i]);
                        }
                        
                    }
                }
            }

            setTableData(objectList);
            
        }
        
    }

    function ExcelDateToJSDate(date) {
        return new Date(Math.round((date - 25569) * 86400 * 1000));
    }
    
    function convertDateToString(date) {
        return Moment(date).format('DD/MM/YYYY')
    }

    function convertStringTodate(dateString) {
        if (dateString && dateString.length > 0) {
            var res = [];
            res = dateString.split("/");
            if (!res || res.length !== 3) {
                res = dateString.split("-");
            }
            if (res && res.length === 3) {
                var d = res[0];
                var m = res[1];
                var y = res[2];
                if(!d || !m || !y){
                    return undefined;
                }
                if (parseInt(m) > 12 && parseInt(d) > 0) {
                    return undefined;
                }
                if (parseInt(m) > 12) {
                    console.log(new Date(parseInt(y), parseInt(d), parseInt(m), 6, 0, 0, 0));
                    return new Date(parseInt(y), parseInt(d), parseInt(m), 6, 0, 0, 0);
                } else {
                    //new Date(toDate.getFullYear(), toDate.getMonth(), toDate.getDate(), 23, 59, 59, 999);
                    console.log(new Date(parseInt(y), parseInt(m)-1, parseInt(d), 6, 0, 0, 0));
                    return new Date(parseInt(y), parseInt(m)-1, parseInt(d), 6, 0, 0, 0);
                }
            }
        }
        return undefined;
    }

    function handelSave() {
        if (tableData &&  tableData.length > 0) {
            let allKids = [...tableData];
            let newRows = [];
            var goodToSave = true;
            var rewievId = null;
            for (const i in allKids) {

                let tempKid = allKids[i];

                if (tempKid.studentName && tempKid.studentName.length>0) {
                    tempKid.nameError = false;
                } else {
                    tempKid.nameError = true;
                    goodToSave = false;
                    rewievId = rewievId ? rewievId : tempKid.familyId;
                }
                if (tempKid.classroomId && tempKid.classroomId.length>0) {
                    tempKid.classError = false;
                } else {
                    tempKid.classError = true;
                    goodToSave = false;
                    rewievId = rewievId ? rewievId : tempKid.familyId;
                }

                if (tempKid.gender && tempKid.gender.length>0 && tempKid.gender!=="Pick Gender") {
                    tempKid.genderError = false;
                } else {
                    tempKid.genderError = true;
                    goodToSave = false;
                    rewievId = rewievId ? rewievId : tempKid.familyId;
                }

                
                if (tempKid.motherName && tempKid.motherName.trim().length > 0 || tempKid.fatherName && tempKid.fatherName.trim().length > 0) {                    
                    tempKid.oneParentError = false;
                    let mother = false;
                    let father = false;

                    if (tempKid.motherName) {
                        if (tempKid.motherPhone  || tempKid.motherEmail) {
                            mother = true;
                        } else {
                            tempKid.motherError = true;
                        }
                    } else {
                        tempKid.motherError = true;
                    }
                    if (tempKid.fatherName ) {
                        if (tempKid.fatherPhone || tempKid.fatherEmail) {
                            father = true;
                        } else {
                            tempKid.fatherError = true;
                        }
                    } else {
                        tempKid.fatherError = true;
                    }

                    if (father || mother) {
                        tempKid.motherError = false;
                        tempKid.fatherError = false;
                    } else {
                        goodToSave = false;
                        rewievId = rewievId ? rewievId : tempKid.familyId;
                    }
                    
                } else {
                    tempKid.oneParentError = true;
                    tempKid.fatherError = false;
                    tempKid.motherError = false;
                    goodToSave = false;
                    rewievId = rewievId ? rewievId : tempKid.familyId;
                }
                
                newRows.push(tempKid)

            }


            if (goodToSave) {
                setShowPopup(true);
            } else {
                setTableData(newRows);
                setRewievId(rewievId);
                
            }

        }

    }

    function handelDbSave() {

        if (tableData && tableData.length > 0) {
            setbulkUploadLoading(true);
            var kidsList = [];
            var parents = [];

            let kidsQuery1 = new Parse.Query("Kid");
            kidsQuery1.equalTo("SchoolID", schoolId);
            let kidsQuery2 = new Parse.Query("Kid");
            kidsQuery2.equalTo("schoolAdmitted", schoolId);
            let kidsQuery = Parse.Query.or(kidsQuery1, kidsQuery2);
            kidsQuery.count().then((kidsCount) => {

                for (const i in tableData) {

                    var kidObject = tableData[i];

                    let familyId = kidObject.familyId;
                    let groupACL = new Parse.ACL();
                    groupACL.setRoleWriteAccess(schoolId + "_Admin", true);
                    groupACL.setRoleReadAccess(schoolId + "_Admin", true);
              
                    groupACL.setRoleWriteAccess(schoolId + "_Teacher", false);
                    groupACL.setRoleReadAccess(schoolId + "_Teacher", true);
              
                    groupACL.setRoleWriteAccess(familyId + "_Role", true);
                    groupACL.setRoleReadAccess(familyId + "_Role", true);
                
                    var publicACL = new Parse.ACL();
                    publicACL.setPublicReadAccess(true);
                    publicACL.setPublicWriteAccess(true);

                    const Kid = Parse.Object.extend("Kid");
                    let kid = new Kid();
                    kid.set("Name", kidObject.studentName);
                    kid.set("Gender", kidObject.gender);
                    kid.set("DateOfBirth", kidObject.dateOfBirth);
                    kid.set("ClassRoomID", kidObject.classroomId);
                    if (kidObject.admissionNumber) {
                        kid.set("admissionNumber", kidObject.admissionNumber + "");
                    } else {

                        let count = kidsCount + parseInt(i)+1;
                        let an = getAdmiisonNumber(props.selectedSchool,count) + '';
                        kid.set("admissionNumber", an);
                    }
                    kid.set("FamilyID", familyId);
                    kid.set("SchoolID", schoolId);
                    kid.set("isBulkUpload", true);
                    kid.setACL(publicACL);

                    kidsList.push(kid);

                    const FatherObject = Parse.Object.extend("Parent");
                    var fatherObject = new FatherObject();
                    fatherObject.set("Relation", "Father");
                    fatherObject.set("FamilyID", familyId);
                    fatherObject.set("NotificationChoice", 16127);
                    fatherObject.setACL(groupACL);
                    fatherObject.set("Name", kidObject.fatherName && kidObject.fatherName.length > 0 ? kidObject.fatherName : "Father");

                    if (kidObject.fatherName && kidObject.fatherName.length > 0) {
                        if (kidObject.fatherPhone) {
                            fatherObject.set("PhoneNumber", kidObject.fatherPhone + "");
                        }
                        if (kidObject.fatherEmail) {
                            fatherObject.set("EMail", kidObject.fatherEmail);
                        }
                        parents.push(fatherObject);
                    }

                    const MotherObject = Parse.Object.extend("Parent");
                    var motherObject = new MotherObject();
                    motherObject.set("Relation", "Mother");
                    motherObject.set("FamilyID", familyId);
                    motherObject.set("NotificationChoice", 16127);
                    motherObject.setACL(groupACL);
                    motherObject.set("Name", kidObject.motherName && kidObject.motherName.length > 0 ? kidObject.motherName : "Mother");
               
                    if (kidObject.motherName && kidObject.motherName.length > 0) {
                        if (kidObject.motherPhone) {
                            motherObject.set("PhoneNumber", kidObject.motherPhone + "");
                        }

                        if (kidObject.motherEmail) {
                            motherObject.set("EMail", kidObject.motherEmail);
                        }
                        parents.push(motherObject);
                    }
            
                }


                // console.log("parents",parents)
                // console.log("kidsList",kidsList)
                
                Parse.Object.saveAll(kidsList).then(
                    (kRes) => {
                        Parse.Object.pinAll(kRes);
                        Parse.Object.saveAll(parents).then(
                            (pRes) => {
                                Parse.Object.pinAll(pRes);
                                setShowPopup(false);
                                setTableData([]);
                                window.history.back();
                            },
                            (error) => {
                                console.log("Error", error);
                            }
                        );
                    },
                    (error) => {
                        console.log("Error", error);
                    }
                );
            });   
        }   
    }

    const getAdmiisonNumber = (school, kidsCount) => {

        if (school.get('admissionNumberFormat') && school.get('admissionNumberFormat').length > 0) {
            let runningNumber = school.get('admissionRunningNumber') ? school.get('admissionRunningNumber') : 0;
            runningNumber = runningNumber + kidsCount;
            let admissionNumber = school.get('admissionNumberFormat') + runningNumber;
            return admissionNumber;
        } else {
            let schoolCode = (school.get("schoolCode") && school.get("schoolCode").length > 0) ? school.get("schoolCode") : null;
            let admissionYear = "2024-25";
            
            let admissionNumber = '';
            if (schoolCode == null) {
                admissionNumber = admissionYear + "/" + kidsCount;
            } else {
                admissionNumber = admissionYear + "/" + schoolCode + "/" + kidsCount;
            }
            return admissionNumber;
        }
    }

    const handelBack = () => {
        window.history.back();
    }
    

    return (
        <React.Fragment>
            
            <div className="page-content" >

           
                <Topbar onClickBackButton ={handelBack}></Topbar>
                <Card>
                    <CardBody>
                        <CardTitle className="mb-2"> {props.t('Student bulk upload')}</CardTitle>
                        <div className="">
                            <ul className="verti-timeline list-unstyled">
                            
                        
                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className={"bx bx-right-arrow-circle"}></i>
                                    </div>
                                    <div className="d-flex justify-content-start">
                                        <div className="mr-3">
                                            <i className={"bx bx-download h2 text-primary"}></i>
                                        </div>
                                        <div className="media-body m-2">
                                            <div>
                                                <h5>{props.t('Download Excel Template')}</h5>
                                                <p className="text-muted">{"Please download the Excel template sheet to ﬁll in all student details quickly."}</p>
                
                                            </div>
                                            <Alert
                                                color="danger"
                                                role="alert"
                                            >
                                                <p className="lead mb-1"><strong><u>Guidelines</u></strong></p>
                                                <p className="mb-0">1. Gender:	Please use male or female</p>
                                                <p className="mb-0">2. Date of Birth:	Please always use DD/MM/YYYY format for example if birthdate is january 12 2014 means, please use 12/01/2014 </p>
                                                <p className="mb-0">3. Parents Details:	Please make sure atleast one parent phone /email is filled</p>
                                                <p className="mb-0">{"4. Class:	Please make sure you are exactly matching the classroom name with what ever classrooms already available in " +WhiteLable.getAppName()+" classrooms"}</p>
                                            </Alert>
                                            <button
                                                onClick={() => { window.open(templateURL) }}
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light ">
                                                <i className="bx bx-download font-size-16 align-middle"></i> {props.t('Download Template')}
                                            </button>
                                        </div>
                                        
                                    </div>
                                </li>
                        

                        
                                <li className="event-list">
                                    
                                    <div className="event-timeline-dot">
                                        <i className={"bx bx-right-arrow-circle"}></i>
                                    </div>
                                        
                                    
                                    <div className="media d-flex justify-content-start">
                                        <div className="mr-3">
                                            <i className={"bx bx-copy-alt h2 text-primary"}></i>
                                        </div>
                                        <div className="media-body m-2">
                                            <div>
                                                <h5>{"Choose Excel"}</h5>
                                                <p className="text-muted">{"Now simply upload the Excel sheet that you just ﬁlled with all details."}</p>
            
                                                <div>
                                                    <Label className="btn btn-primary m-1" htmlFor="inputImage">
                                                        <input type="file"
                                                            className="sr-only"
                                                            id="inputImage"
                                                            name="file"
                                                            accept=".xlsx,/*"
                                                            onChange={handleUpload} /> {props.t('Choose Excel')}
                                                    </Label>

                                                    {tableData && tableData.length > 0 &&
                                                        <button
                                                            onClick={() => {
                                                                if (tableData && tableData.length > 0) {
                                                                    setTableData([]);
                                                                }
                                                            }}
                                                            type="button"
                                                            className="btn btn-light waves-effect waves-light m-1 ">
                                                            {props.t('Clear')}
                                                        </button>
                                                    }
                                                
                                                </div>
                                            </div>
                                    
                                        </div>

                                
                                    
                                    </div>
                                    
                            
                                </li>
                            </ul>
                        </div>
                        
                        
                        {!tableData || tableData.length ===0 && <div className="">
                        
                            <ul className="verti-timeline list-unstyled mt-2">

                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className={"bx bx-right-arrow-circle "}></i>
                                    </div>
                                    <div className="media d-flex justify-content-start">
                                        <div className="mr-3">
                                            <i className={"bx bx-upload h2 text-primary"}></i>
                                        </div>
                                        <div className="media-body m-2">
                                            <div>
                                                <h5>{"Upload and Save"}</h5>
                                                <p className="text-muted">{"We would magically create the student database for you"}</p>
                                                <button
                                                    onClick={() => {
                                                        // if (tableData && tableData.length > 0) {
                                                        //     setShowPopup(true);
                                                        // }
                                            
                                                    }}
                                                    type="button"
                                                    className={tableData && tableData.length > 0 ? "btn btn-primary waves-effect waves-light " : "btn btn-light waves-effect waves-light "}>
                                                    <i className="bx bx-upload font-size-16 align-middle"></i> {props.t('Upload and Save')}
                                                </button>
            
                                            </div>
                                
                                        </div>
                                
                                
                                    </div>
                            
                                </li>



                            </ul>
                        </div>}
                        
                    </CardBody>
                </Card>


                {tableData && tableData.length > 0 &&
                    <Card>
                    <CardBody>
                        <CardTitle className="mb-2"> {props.t('Preview')}</CardTitle>
                
                    
                        <div className="table-responsive">
                            <Table responsive striped className="mb-0">
                                <thead>
                                    <tr>
                                        <th style={{ width: "4%" }}># </th>
                                        <th style={{ width: "23%" }}>Name*/Admission No. </th>
                                        <th style={{ width: "18%" }}>Class* </th>
                                        <th style={{ width: "18%" }}>Gender*/DOB</th>
                                        <th style={{ width: "23%" }}>Mother Details*</th>
                                        <th style={{ width: "23%" }}>Father Details*</th>
                                        <th style={{ width: "4%" }}>Remove</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {tableData.map((kid, key) =>
                                        
                                        
                                        <StudentImportList
                                            key={kid.familyId}
                                            slno={key + 1}
                                            kid={kid}
                                            classrooms={props.classrooms}
                                            classNamesList={classNamesList}
                                            classroomIds={classroomIds}
                                            rewievId={rewievId}
                                            handelUpdateKid={handelUpdateKid}
                                            handelRemoveKid={handelRemoveKid}
                                        />
                                      
                                    )
                                    }
                                </tbody>
                        </Table>
                        </div>
                        </CardBody>
                    </Card>
                }
                
                {tableData && tableData.length > 0 && <Card>
                    <CardBody>
                        <div className="">
                        
                            <ul className="verti-timeline list-unstyled mt-2">

                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className={"bx bx-right-arrow-circle "}></i>
                                    </div>
                                    <div className="media">
                                        <div className="mr-3">
                                            <i className={"bx bx-upload h2 text-primary"}></i>
                                        </div>
                                        <div className="media-body">
                                            <div>
                                                <h5>{"Upload and Save"}</h5>
                                                <p className="text-muted">{"We would magically create the student database for you"}</p>
                                                <button
                                                    onClick={() => {
                                                        if (tableData && tableData.length > 0) {
                                                            //setShowPopup(true);
                                                            handelSave();
                                                        }
                                            
                                                    }}
                                                    type="button"
                                                    className={tableData && tableData.length > 0 ? "btn btn-primary waves-effect waves-light " : "btn btn-light waves-effect waves-light "}>
                                                    <i className="bx bx-upload font-size-16 align-middle"></i> {props.t('Upload and Save')}
                                                </button>
            
                                            </div>
                                
                                        </div>
                                
                                
                                    </div>
                            
                                </li>



                            </ul>
                        </div>
                    </CardBody>
                </Card>}

                <Modal isOpen={showPopup || bulkUploadLoading}>
                    <ModalHeader>
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            {props.t('Student bulk upload')}
                        </h5>
                    </ModalHeader>
                    <ModalBody >
                        <p className=" m-4">{props.t('Are you sure you want to upload this student data?')}</p>
                    </ModalBody>
                    <ModalFooter>
                        {!bulkUploadLoading ? <Row className="">
                            <div className='d-flex justify-content-end'>
                            <button
                                type="button"
                                onClick={() => {
                                    setShowPopup(false);
                                }}
                                className="btn btn-secondary waves-effect m-1"
                                data-dismiss="modal"
                            >
                                {props.t('Cancel')}
                            </button>
                            <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light m-1"
                                onClick={() => {
                                    // handelDbSave();
                                    setShowLimitModal(true);
                                    setLoading(true);
                                }}
                            >
                                {props.t('Yes, Upload')}
                            </button>
                            </div>
                        </Row> : <button
                            type="button" className="btn btn-light waves-effect">
                    
                            <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Uploading student data ')}
                                        
                        </button>
                        
                        }
                    </ModalFooter>
                </Modal>
            </div>
            <Licence
                showopup ={showLimitModal}
                handelAdd={()=>{
                    handelDbSave();
                    setShowLimitModal(false);
                    setLoading(false);    
                }}
                addCount={tableData.length} 
                handelClose={()=>{
                    setShowLimitModal(false);
                    setLoading(false);
                }}
                >
            </Licence>
        </React.Fragment>
            
    );
        
}
        
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    return { selectedSchool,classrooms};
}
            
export default withNamespaces()(withRouter(connect(mapStatetoProps, {  setSelecetdSchool,getClassroomsForSchoolId})(Index)));