import * as actions from "./actionTypes";

export const getCoureseSubscriptions = (courseId,batchId) => {
  return {
    type: actions.GET_COURSE_SUBSCRIPTIONS,
    payload: { courseId ,batchId},
  };
};

export const getCourseSubscriptionForUserId = (userId) => {
  return {
    type: actions.GET_COURSE_SUBSCRIPTIONS_FOR_USER_ID,
    payload: { userId },
  };
};

export const updateCourseSubscriptionForUserId = (courseSubscriptionsForUser, replace = false) => {
  return {
    type: actions.UPDATE_COURSE_SUBSCRIPTIONS_FOR_USER_ID,
    payload: { courseSubscriptionsForUser, replace },
  };
};

export const updateCoureseSubscriptions = (courseSubscriptions) => {
  return {
    type: actions.UPDATE_COURSE_SUBSCRIPTIONS,
    payload: { courseSubscriptions },
  };
};


export const addSubscriptionsToCourse = (users, isUpdateUserSubs = false) => {
  return {
    type: actions.ADD_SUBSCRIPTION_TO_COURSE,
    payload: { users, isUpdateUserSubs },
  };
};

export const removeSubscriptionFromCourse = (courseSubscription) => {
  return {
    type: actions.REMOVE_SUBSCRIPTION_FROM_COURSE,
    payload: { courseSubscription },
  };
};

export const updateCoureseSubscriptionsInitialLoading = (state) => {
  return {
    type: actions.UPDATE_COURSE_SUBSCRIPTION_INITIAL_LOADING,
    payload: { state },
  };
};


export const updateAddState = (state) => {
  return {
    type: actions.UPDATE_COURSE_SUBSCRIPTION_ADD_STATE,
    payload: { state },
  };
};

export const getCoureseSubscriptionForId = (subscriberId) => {
  return {
    type: actions.GET_SUBSCRIPTION_FOR_ID,
    payload: { subscriberId},
  };
};

export const updadteCoureseSubscriptionForId = (courseSubscriptionObject) => {
  return {
    type: actions.UPDATE_SUBSCRIPTION_FOR_ID,
    payload: { courseSubscriptionObject},
  };
};