import React, { useState,useEffect } from 'react';
import Parse from 'parse';
import { withRouter,Link } from "react-router-dom";
import { connect } from 'react-redux';
import { Container,Col,Alert,Row, Card, CardBody,CardTitle ,Label,Modal,ModalBody,ModalFooter,ModalHeader,Table,Form,Input,Button} from "reactstrap";
// import XLSX from 'xlsx';
import * as XLSX from 'xlsx/xlsx.mjs';
import Moment from 'moment';
import { matchPath } from "react-router";
import { withNamespaces } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import * as Constants from '../../Constents';
import {  setSelecetdSchool, getClassroomsForSchoolId,createStudentUser,updateStudentUser,updateAllKids} from '../../store/actions';
import Topbar from './Topbar';
import Licence from '../../components/Common/Licence'

const AcademyStudentImport = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/add-import/:id",
        exact: true,
        strict: false,
    });

    const schoolId = match.params.id;
    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }

        if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 11) {
            props.getClassroomsForSchoolId(schoolId)
        }
        
    }, [props.selectedSchool, schoolId]);

    const [importModal, setImportModal] = useState(false);

     
    const [classNamesList, setClassNamesList] = useState([])
    const [classroomIds, setClassroomIds] = useState([])
    const [rewievId, setRewievId] = useState(null)
    
    const Male = ["male", "boy", "b", "m", "he"];
    const Female = ["female", "girl", "g", "f", "she"];
    const [showPopup, setShowPopup] = useState(false);
    const firstRow = ["slno", "Name", "Gender", "Email", "PhoneNumber", "MotherName", "MotherPhone", "MotherEmail", "FatherName", "FatherPhone", "FatherEmail"];

    const [tableData, setTableData] = useState([
        {
            familyId: uuidv4(),
            admissionNumber: undefined,
            studentName: undefined,
            gender: undefined,
            admissionClass: undefined,
            phoneNumber: undefined,
            email: undefined,
            classroomId: undefined,
                    
        }
    ]);

    const [showBulkPopup, setShowBulkPopup] = useState(false);
    const [bulkUploadLoading, setbulkUploadLoading] = useState(false);
    const [usersCheck, setUserCheck] = useState(false);
    const [popupTimer, setPopupTimer] = useState(0);
    const [bulkUploadResponse, setBulkUploadResponse] = useState(undefined);
    const [showLimitModal, setShowLimitModal] = useState(false);
    const [loding, setLoading] = useState(false);

    const [studentData,setStudentData] = useState(undefined);
    


    const handelUpdateKid = (oldKid, newKid) => {
        let allKids = [...tableData];
        let index = allKids.indexOf(oldKid);
        allKids[index] = newKid;
        
        setTableData(allKids);

    }
    const handelRemoveKid = (kid) => {
        let allKids = [...tableData];
        let newKids = allKids.filter(
            (k) => k.familyId !== kid.familyId
        );
        setTableData(newKids);
    }

    const handleUpload = (e) => {
        // e.preventDefault();
    
        var files = e.target.files, f = files[0];
        var reader = new FileReader();
        reader.onload = function (e) {
            var data = e.target.result;
            let readedData = XLSX.read(data, { type: 'binary' });
            let index = readedData.SheetNames.indexOf("Students");
            if (readedData.SheetNames.length == 1) {
                index = 0;
            }
            const wsname = readedData.SheetNames[index];
            const ws = readedData.Sheets[wsname];
    
            /* Convert array to json*/
            const dataParse = XLSX.utils.sheet_to_json(ws, { header: 1 });
            createJsObjects(dataParse)
            
        };
        reader.readAsBinaryString(f)

        setImportModal(false)
    }

    const handelAddNewRow = () => {
        let newItem ={
            familyId: uuidv4(),
            admissionNumber: undefined,
            studentName: undefined,
            gender: undefined,
            admissionClass: undefined,
            phoneNumber: undefined,
            email: undefined,
            classroomId: undefined,
                    
        }
        setTableData([...tableData,newItem]);
    }


    const  createJsObjects =(dataParse) =>{
        

        let extList = [...tableData];

        if (extList.length === 1 && (!extList[0].studentName || extList[0].studentName.length === 0)) {
            extList = [];
        }

        var IstRow = ["slno","Name","Gender","Email","PhoneNumber"];
        var allrows = [];
        for (const i in dataParse) {
            if (dataParse[i] && dataParse[i].length > 0) {
                if (i == 0) {
                    IstRow = dataParse[0];
                } else {
                    allrows.push(dataParse[i]);
                }
            }
        }
        if (allrows && allrows.length > 0) {
            var objectList = [];
            for (const j in allrows) {
                var obj = {
                    familyId: uuidv4(),
                    admissionNumber: undefined,
                    studentName: undefined,
                    gender: undefined,
                    admissionClass: undefined,
                    phoneNumber: undefined,
                    email: undefined,
                    classroomId: undefined,
                    
                }
                objectList.push(obj)
            }

            for (const i in IstRow) {
                
                
                for (const j in allrows) {
                    if (IstRow[i] === firstRow[1]) {
                        objectList[j].studentName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[2]) {
                        var gen = allrows[j][i];
                        if (gen && Female.includes(gen.toLowerCase())) {
                            objectList[j].gender = "Female";
                        } else if (gen && Male.includes(gen.toLowerCase())) {
                            objectList[j].gender = "Male";
                        }
                    }else if (IstRow[i] === firstRow[3]) {
                        objectList[j].email = allrows[j][i];
                    } else if (IstRow[i] === firstRow[4]) {
                        objectList[j].phoneNumber = allrows[j][i];
                    }
                    // else if (IstRow[i] === "CLASSROOM") {
                    //     if (classNamesList.includes(allrows[j][i])) {
                    //         objectList[j].admissionClass = allrows[j][i];
                    //         let index = classNamesList.indexOf(allrows[j][i]);
                    //         objectList[j].classroomId = classroomIds[index];
                    //     }
                    // } 
                    else if (IstRow[i] === firstRow[5]) {
                        objectList[j].motherName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[6]) {
                        objectList[j].motherPhone = allrows[j][i];
                    } else if (IstRow[i] === firstRow[7]) {
                        objectList[j].motherEmail = allrows[j][i];
                    } else if (IstRow[i] === firstRow[8]) {
                        objectList[j].fatherName = allrows[j][i];
                    } else if (IstRow[i] === firstRow[9]) {
                        objectList[j].fatherPhone = allrows[j][i];
                    } else if (IstRow[i] === firstRow[10]) {
                        objectList[j].fatherEmail = allrows[j][i];
                    }
                }
            }

            setTableData(extList.concat(objectList));
            
        }
        
    }

    function ValidateEmail(value) {
        var mailformat = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        if (value.match(mailformat)) {
            return true;
        } else {
            return false;
        }
    }

    function handelSave() {
        setStudentData(undefined)
        if (tableData && tableData.length > 0) {
            let allKids = [...tableData];
            let newRows = [];
            var goodToSave = true;
            var rewievId = null;
            let emailIds = [];
            for (const i in allKids) {

                let tempKid = allKids[i];

                if (tempKid.studentName && tempKid.studentName.length > 0) {
                    tempKid.nameError = false;
                } else {
                    tempKid.nameError = "Name is mandatory";
                    goodToSave = false;
                    rewievId = rewievId ? rewievId : tempKid.familyId;
                }

                if (tempKid.email && tempKid.email.length > 0) {
                    if (ValidateEmail(tempKid.email)) {
                        if (emailIds.includes(tempKid.email)) {
                            tempKid.emailError = "Repeted email";
                            goodToSave = false;
                            rewievId = rewievId ? rewievId : tempKid.familyId;
                        } else {
                            tempKid.emailError = false;
                            emailIds.push(tempKid.email);
                        }
                        
                    } else {
                        tempKid.emailError = "Invalid email";
                        goodToSave = false;
                        rewievId = rewievId ? rewievId : tempKid.familyId;
                    }   
                } else {
                    tempKid.emailError = "Email is mandatory";
                    goodToSave = false;
                    rewievId = rewievId ? rewievId : tempKid.familyId;
                }

                if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 11) {
                    if (!tempKid.classroomId) {
                        tempKid.classError = "Classroom is mandatory";
                        goodToSave = false;
                        rewievId = rewievId ? rewievId : tempKid.familyId;
                    }
                }
                
                newRows.push(tempKid)

            }

            setStudentData({emailIds:emailIds,kids:newRows})

            if (goodToSave) {
                if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 11) {
                    // setPopupTimer(10);
                    // setShowBulkPopup(true);

                    setShowLimitModal(true)
                    setLoading(true)
                }
                else if (newRows.length == 1) {
                    setShowPopup(true);                    
                } else {
                    checkForUsersinDB(emailIds,newRows);
                }
                
            } else {
                setTableData(newRows);
                setRewievId(rewievId);
                
            }

        }

    }

    const checkForUsersinDB = (emailIds,newRows) => {
        let rewievId = "";

        setUserCheck(true);
        Parse.Cloud.run("function_checkUsersForEmails", {
           emailIds:emailIds
        }).then((response) => {
           
            let goodToSave = true;
            
            if (response && response.length > 0) {
                goodToSave = false;
                for (const exUser of response) {

                    var temp = newRows.filter(
                        (user) => user.email === exUser.email
                    );
                    if (temp.length > 0) {
                        var index = newRows.indexOf(temp[0]);
                        newRows[index].nameError = "Existing User";
                        newRows[index].emailError = "Use different email";
                        rewievId=rewievId ? rewievId : temp[0].familyId;
                    }
                }
            }
            setUserCheck(false);
            if (goodToSave) {
                // setPopupTimer(10);
                // setShowBulkPopup(true);

                setShowLimitModal(true)
                setLoading(true)
            } else {
                setTableData(newRows);
                setRewievId(rewievId);
                
            }
        }, (error) => {
            setUserCheck(false);
            console.log("error" + error.message)
        });
        
    }

    useEffect(() => {
        if (showBulkPopup && !bulkUploadLoading) {
            const timer = setTimeout(() => {
                setPopupTimer(popupTimer - 1);
            }, 1000);

            if (popupTimer > 0) {
        
            } else {
                setShowBulkPopup(false);
                return () => clearTimeout(timer);

            }
        }

    }, [showBulkPopup, popupTimer]);

    const handelCreateStudentUserBulk = () => {
        setbulkUploadLoading(true);
        setBulkUploadResponse(undefined);

         Parse.Cloud.run("function_createUserInBulkForKids", {
             kids: tableData,
             schoolId: schoolId,
             refferedBy:"asci"
             
         }).then((response) => {
            props.updateAllKids(response)
            setBulkUploadResponse({type:"success",response:response});
        }, (error) => {
            setBulkUploadResponse({type:"error",error:error.message});
            console.log("error" + error.message)
        });
    }

    function handelDbSave() {

        if (tableData && tableData.length > 0) {
            setbulkUploadLoading(true);
            var kidsList = [];
            var parents = [];

            let kidsQuery1 = new Parse.Query("Kid");
            kidsQuery1.equalTo("SchoolID", schoolId);
            let kidsQuery2 = new Parse.Query("Kid");
            kidsQuery2.equalTo("schoolAdmitted", schoolId);
            let kidsQuery = Parse.Query.or(kidsQuery1, kidsQuery2);
            kidsQuery.count().then((kidsCount) => {

                for (const i in tableData) {

                    var kidObject = tableData[i];

                    let familyId = kidObject.familyId;
                    let groupACL = new Parse.ACL();
                    groupACL.setRoleWriteAccess(schoolId + "_Admin", true);
                    groupACL.setRoleReadAccess(schoolId + "_Admin", true);
              
                    groupACL.setRoleWriteAccess(schoolId + "_Teacher", false);
                    groupACL.setRoleReadAccess(schoolId + "_Teacher", true);
              
                    groupACL.setRoleWriteAccess(familyId + "_Role", true);
                    groupACL.setRoleReadAccess(familyId + "_Role", true);
                
                    var publicACL = new Parse.ACL();
                    publicACL.setPublicReadAccess(true);
                    publicACL.setPublicWriteAccess(true);

                    const Kid = Parse.Object.extend("Kid");
                    let kid = new Kid();
                    kid.set("Name", kidObject.studentName);
                    kid.set("Gender", kidObject.gender);
                    kid.set("ClassRoomID", kidObject.classroomId);
                    if (kidObject.admissionNumber) {
                        kid.set("admissionNumber", kidObject.admissionNumber + "");
                    } else {

                        let count = kidsCount + parseInt(i) + 1;
                        let an = getAdmiisonNumber(props.selectedSchool, count) + '';
                        kid.set("admissionNumber", an);
                    }
                    kid.set("FamilyID", familyId);
                    kid.set("SchoolID", schoolId);
                    kid.setACL(publicACL);

                    kid.set("email", kidObject.email);
                    kid.set("phoneNumber", kidObject.phoneNumber?kidObject.phoneNumber+"":"");
                    kid.set("isBulkUpload", true);
                    kidsList.push(kid);


                    const FatherObject = Parse.Object.extend("Parent");
                    var fatherObject = new FatherObject();
                    fatherObject.set("Relation", "Father");
                    fatherObject.set("FamilyID", familyId);
                    fatherObject.set("NotificationChoice", 16127);
                    fatherObject.setACL(groupACL);
                    fatherObject.set("Name", kidObject.fatherName && kidObject.fatherName.length > 0 ? kidObject.fatherName : "Father");

                    if (kidObject.fatherName && kidObject.fatherName.length > 0) {
                        if (kidObject.fatherPhone) {
                            fatherObject.set("PhoneNumber", kidObject.fatherPhone + "");
                        }
                        if (kidObject.fatherEmail) {
                            fatherObject.set("EMail", kidObject.fatherEmail);
                        }
                        parents.push(fatherObject);
                    }

                    const MotherObject = Parse.Object.extend("Parent");
                    var motherObject = new MotherObject();
                    motherObject.set("Relation", "Mother");
                    motherObject.set("FamilyID", familyId);
                    motherObject.set("NotificationChoice", 16127);
                    motherObject.setACL(groupACL);
                    motherObject.set("Name", kidObject.motherName && kidObject.motherName.length > 0 ? kidObject.motherName : "Mother");
               
                    if (kidObject.motherName && kidObject.motherName.length > 0) {
                        if (kidObject.motherPhone) {
                            motherObject.set("PhoneNumber", kidObject.motherPhone + "");
                        }

                        if (kidObject.motherEmail) {
                            motherObject.set("EMail", kidObject.motherEmail);
                        }
                        parents.push(motherObject);
                    }
                    
            
                }

                console.log(parents);
                console.log(kidsList);

                if (parents.length > 0) {
                    Parse.Object.saveAll(parents)
                }
                
                Parse.Object.saveAll(kidsList).then(
                    (kRes) => {
                        Parse.Object.pinAll(kRes);
                        setShowPopup(false);
                        setTableData([]);
                        window.history.back();
                    },
                    (error) => {
                        console.log("Error", error);
                    }
                );
            });
        }
    }

    const getAdmiisonNumber = (school, kidsCount) => {

        if (school.get('admissionNumberFormat') && school.get('admissionNumberFormat').length > 0) {
            let runningNumber = school.get('admissionRunningNumber') ? school.get('admissionRunningNumber') : 0;
            runningNumber = runningNumber + kidsCount;
            let admissionNumber = school.get('admissionNumberFormat') + runningNumber;
            return admissionNumber;
        } else {
            let schoolCode = (school.get("schoolCode") && school.get("schoolCode").length > 0) ? school.get("schoolCode") : null;
            let admissionYear = "2021-22";
            
            let admissionNumber = '';
            if (schoolCode == null) {
                admissionNumber = admissionYear + "/" + kidsCount;
            } else {
                admissionNumber = admissionYear + "/" + schoolCode + "/" + kidsCount;
            }
            return admissionNumber;
        }
    }

    const downloadTemplate = () => {
        
        let fileName = `participant_import_template.xlsx`;

        let newSheet = [];
        newSheet.push(["slno", "Name", "Gender", "Email", "PhoneNumber"]);
        newSheet.push([1]);
        newSheet.push([2]);
        newSheet.push([3]);
        newSheet.push([4]);
        newSheet.push([5]);

        let wb = XLSX.utils.book_new();
        let ws = XLSX.utils.aoa_to_sheet(newSheet);
        XLSX.utils.book_append_sheet(wb, ws, "participants");
        XLSX.writeFile(wb, fileName);
    }

    const handelBack = () => {
        window.history.back();
    }

    const handelShowImportPopup = () => {
        setImportModal(true);
    }
    

    return (
        <React.Fragment>
            
            <div className="page-content" >
                <Topbar
                    title={props.selectedSchool && props.selectedSchool.attributes.schoolType == 10?"ADD PARTICIPANTS":"ADD STUDENTS"}
                    onClickBackButton={handelBack}
                    addbutton={true}
                    buttonName={props.selectedSchool && props.selectedSchool.attributes.schoolType == 10?"IMPORT PARTICIPANTS":"IMPORT STUDENTS"}
                    onClickAdd={handelShowImportPopup}>
                    
                </Topbar>

                {tableData && tableData.length > 0 &&
                    <Card>
                        <CardBody>
                                
                            <div className="table-responsive">
                                <Table responsive striped className="mb-0">
                                    <thead>
                                        <tr>
                                            <th style={{ width: "4%" }}># </th>
                                            <th style={{ width: "23%" }}>Name* </th>
                                            <th style={{ width: "15%" }}>Gender</th>
                                            <th style={{ width: "20%" }}>Email*</th>
                                            <th style={{ width: "20%" }}>Phone Number*</th>
                                            {props.selectedSchool && props.selectedSchool.attributes.schoolType == 11 &&<th style={{ width: "23%" }}>Classroom*</th>}
                                            <th style={{ width: "4%" }}>Remove</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        

                                    
                                        {tableData.map((kid, key) =>
                                       
                                            <tr id={"addrMain" + kid.familyId} key={key}>
                                                <td><h4 className="m-2">{(key + 1)}</h4></td>
                                                <td>
                                                    
                                                    <input
                                                        type="text"
                                                        className="inner form-control"
                                                        placeholder="Enter name"
                                                        value={kid.studentName}
                                                        onChange={(e) => {

                                                            let nc = kid;
                                                            nc.studentName = e.target.value;
                                                            nc.nameError = false;
                                                            handelUpdateKid(kid, nc)
                                                        }}
                                                    />
                                                    {kid.nameError && <p className="text-danger">{ kid.nameError}</p>}
                                                      
                                                </td>
                                                <td>

                                                        

                                                    <select
                                                        className="form-control "
                                                        value={kid.gender}
                                                        onChange={(e) => {
                                                            
                                                        }}
                                                        placeholder="Select Program"
                                                    >
                                                        <option value="Select">Select</option>
                                                            
                                                        <option value={"Male"}>Male</option>
                                                        <option value={"Female"}>Female</option>
                                                    </select>

                                                </td>
                                                <td>
                                                    <input
                                                        type="email"
                                                        id="email"
                                                        className="form-control"
                                                        value={kid.email}

                                                        placeholder="Enter email"
                                                        onChange={(e) => {
                                                            let nc = kid;
                                                            nc.email = e.target.value
                                                            handelUpdateKid(kid, nc)
                                                        }}
                                                        
                                                    />
                                                    {kid.emailError && <p className="text-danger">{ kid.emailError}</p>}
                                                </td>
                                                <td>
                                                    <input
                                                        type="text"
                                                        id="phoneNumber"
                                                        className="form-control"
                                                        placeholder="Enter phone number"
                                                        value={kid.phoneNumber}
                                                        onChange={(e) => {
                                                            let nc = kid;
                                                            nc.phoneNumber = e.target.value
                                                            handelUpdateKid(kid, nc)
                                                        }}
                                                    />
                                                </td>
                                                {
                                                    props.selectedSchool && props.selectedSchool.attributes.schoolType == 11 && <td>
                                                         <select
                                                        className="form-control "
                                                        value={kid.classroomId}
                                                        onChange={(e) => {
                                                            let nc = kid;
                                                            nc.classroomId = e.target.value
                                                            handelUpdateKid(kid, nc)
                                                        }}
                                                        placeholder="Select Program"
                                                    >
                                                        <option value="Select">Select</option>
                                                            
                                                            {
                                                                props.classrooms.map((cr) =>
                                                                    <option value={cr.id}>{ cr.attributes.Name}</option>
                                                                )
                                                        }
                                                    </select>

                                                        {kid.classError && <p className="text-danger">{ kid.classError}</p>}
                                                    </td>
                                                        
                                                }
                                                <td>
                                                    <Button
                                                        color="danger"
                                                        className="btn-block btn-sm inner mt-1"
                                                        style={{ width: "100%" }}
                                                        onClick={(e) => {
                                                            handelRemoveKid(kid);
                                                        }}
                                                        disabled ={tableData.length==1?true:false}
                                                    >
                                                            
                                                        Remove
                                                    </Button>
                                                </td>
                                            </tr>
                                            
                                        )
                                        }
                                         
                                    </tbody>
                                </Table>
                                <Row>
                                    {usersCheck ?
                                        <Col xs={12}>
                                            <button
                                                type="button" className="btn btn-light waves-effect float-right m-2">
                                    
                                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Prepering data ')}
                                                        
                                            </button>
                                        </Col>
                                        :
                                        <Col xs={12}>

                                        <Button
                                            onClick={() => handelAddNewRow()}
                                            type="button"
                                            color="success"
                                            className="btn waves-effect waves-light float-right  m-2"
                                        >
                                            <i className="mdi mdi-plus mr-1" />
                                            ADD
                                        </Button>
                                        <Button
                                            onClick={() => {
                                                handelSave();
                                                
                                            }}
                                            type="button"
                                            color="primary"
                                            className="btn waves-effect waves-light float-right  m-2"
                                        >
                                            <i className="mdi mdi-save " />
                                            SAVE
                                        </Button>
                                    </Col>}

                                </Row>
                               
                           

                            </div>
                        
                           
                        </CardBody>
                    </Card>
                }
                
                

                <Modal
                    size="lg"
                    isOpen={importModal}>
                    <ModalHeader>
                        <h5 className="modal-title mt-0" id="myModalLabel">
                           {props.selectedSchool && props.selectedSchool.attributes.schoolType == 10? props.t('Import Participants'): props.t('Import Students')}
                        </h5>
                    </ModalHeader>
                    <ModalBody >
                       
                        <div className="">
                            <ul className="verti-timeline list-unstyled">
                            
                        
                                <li className="event-list">
                                    <div className="event-timeline-dot">
                                        <i className={"bx bx-right-arrow-circle"}></i>
                                    </div>
                                    <div className="media d-flex justify-content-start">
                                        <div className="mr-3">
                                            <i className={"bx bx-download h2 text-primary"}></i>
                                        </div>
                                        <div className="media-bod m-2y">
                                            <div>
                                                <h5>{props.t('Download Excel Template')}</h5>
                                                <p className="text-muted">{"Please download the Excel template sheet to ﬁll in all student details quickly."}</p>
                
                                            </div>
                                            <Alert
                                                color="danger"
                                                role="alert"
                                            >
                                                <p className="lead mb-1"><strong><u>Guidelines</u></strong></p>
                                                <p className="mb-0">1. Name and email are mandatory</p>
                                                <p className="mb-0">2. Gender: Use male or female</p>
                                                <p className="mb-0">2. Email: Mandatory and should not repeat</p>
                                                       
                                            </Alert>
                                            <button
                                                onClick={() => { downloadTemplate() }}
                                                type="button"
                                                className="btn btn-primary waves-effect waves-light ">
                                                <i className="bx bx-download font-size-16 align-middle"></i> {props.t('Download Template')}
                                            </button>
                                        </div>
                                        
                                    </div>
                                </li>
                        

                        
                                <li className="event-list">
                                    
                                    <div className="event-timeline-dot">
                                        <i className={"bx bx-right-arrow-circle"}></i>
                                    </div>
                                        
                                    
                                    <div className="media d-flex justify-content-start">
                                        <div className="mr-3">
                                            <i className={"bx bx-copy-alt h2 text-primary"}></i>
                                        </div>
                                        <div className="media-body m-2">
                                            <div>
                                                <h5>{"Choose Excel"}</h5>
                                                <p className="text-muted">{"Now simply upload the Excel sheet that you just ﬁlled with all details."}</p>
            
                                                <div>
                                                    <Label className="btn btn-primary mt-1" htmlFor="inputImage">
                                                        <input type="file"
                                                            className="sr-only"
                                                            id="inputImage"
                                                            name="file"
                                                            accept=".xlsx,/*"
                                                            onChange={handleUpload} /> {props.t('Choose Excel')}
                                                    </Label>

                                                    {/* {tableData && tableData.length > 0 &&
                                                        <button
                                                            onClick={() => {
                                                                if (tableData && tableData.length > 0) {
                                                                    setTableData([]);
                                                                }
                                                            }}
                                                            type="button"
                                                            className="btn btn-light waves-effect waves-light ml-2 ">
                                                            {props.t('Clear')}
                                                        </button>
                                                    } */}
                                                
                                                </div>
                                            </div>
                                    
                                        </div>

                                
                                    
                                    </div>
                                    
                            
                                </li>
                            </ul>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Row className="text-center">
                            <button
                                type="button"
                                onClick={() => {
                                    setImportModal(false);
                                }}
                                className="btn btn-secondary waves-effect"
                                data-dismiss="modal"
                            >
                                {props.t('Close')}
                            </button>

                        </Row>
                    </ModalFooter>
                </Modal>

                <Modal isOpen={showPopup }>
                    <ModalHeader>
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            {props.t('Participant upload')}
                        </h5>
                    </ModalHeader>
                    <ModalBody >
                        {
                            props.studentUser ? <div>

                                {
                                    props.studentUser.status ? <div>

                                        <p>{"Invitation email has been sent to "+ props.studentUser.kid.attributes.Name} </p>
                                        
                                        <p>{"Username: " + props.studentUser.username} </p>
                                        <p>{"Password: " + props.studentUser.password}</p>
                                        
                                        
                                    </div> : <div>
                                        <p>{"Error: " + props.studentUser.error} </p>
                                    </div>
                                }
                                
                            </div> : <p className=" m-4">
                                    
                                    {props.t('Are you sure you want to upload this participant data?')}
                                </p>
                        }
                        
                    </ModalBody>
                    {props.studentUser ?
                        <ModalFooter>

                             <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light ml-2"
                                    onClick={() => {
                           

                                        setShowPopup(false);
                                        window.history.back();
                                        props.updateStudentUser(undefined, false);
                                
                                    }}
                                >
                                    {props.t('Done')}
                                </button>
                        </ModalFooter> :
                        < ModalFooter >
                            {!props.studentUserLoading ? <Row className="text-center">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowPopup(false);
                                    }}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    {props.t('Cancel')}
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light ml-2"
                                    onClick={() => {
                                        // props.createStudentUser(tableData[0], schoolId);

                                        setShowLimitModal(true)
                                        setLoading(true)
                                        
                                    }}
                                >
                                    {props.t('Yes, Upload')}
                                </button>

                            </Row> : <button
                                type="button" className="btn btn-light waves-effect">
                    
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Uploading participant data ')}
                                        
                            </button>
                        
                            }
                        </ModalFooter>}
                </Modal>

                <Modal isOpen={showBulkPopup }>
                    <ModalHeader>
                        <h5 className="modal-title mt-0" id="myModalLabel">
                            {props.t('Participants upload')}
                        </h5>
                    </ModalHeader>
                    <ModalBody >
                        {
                            bulkUploadResponse ? <div>

                                {
                                    bulkUploadResponse.type == "success" ? <div>
                                        <p>{"Invitation email has been sent to all the participents"} </p>
                                    </div> : <div>
                                        <p>{"Error: " + bulkUploadResponse.error} </p>
                                    </div>
                                }
                                
                            </div> : <p className=" m-4">
                                    {props.t('Are you sure you want to upload this participents data?')}
                                </p>
                        }
                        
                    </ModalBody>
                    {bulkUploadResponse ?
                        <ModalFooter>

                            {bulkUploadResponse.type == "success" ? <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light ml-2"
                                onClick={() => {
                           

                                    setShowBulkPopup(false);
                                    window.history.back();
                                    props.updateStudentUser(undefined, false);
                                
                                }}
                            >
                                {props.t('Done')}
                            </button> : <button
                                type="submit"
                                className="btn btn-primary waves-effect waves-light ml-2"
                                onClick={() => {
                           
                                    setUserCheck(false);
                                    setbulkUploadLoading(false);
                                    setBulkUploadResponse(undefined);
                                    setShowBulkPopup(false);
                                
                                }}
                            >
                                {props.t('OK')}
                            </button>
                            }
                        </ModalFooter>
                        :
                        < ModalFooter >
                            {
                               (!bulkUploadLoading && popupTimer > 0 )&& <p>{"Click Upload in " + popupTimer + " secs"}</p>
                            }
                            {!bulkUploadLoading ? <Row className="text-center">
                                <button
                                    type="button"
                                    onClick={() => {
                                        setShowBulkPopup(false);
                                    }}
                                    className="btn btn-secondary waves-effect"
                                    data-dismiss="modal"
                                >
                                    {props.t('Cancel')}
                                </button>
                                <button
                                    type="submit"
                                    className="btn btn-primary waves-effect waves-light ml-2"
                                    onClick={() => {
                                        if (props.selectedSchool && props.selectedSchool.attributes.schoolType ==11) {
                                            handelDbSave();
                                        }else{
                                            handelCreateStudentUserBulk(tableData);
                                        }
                                    }}
                                >
                                    {props.t('Yes, Upload')}
                                </button>

                            </Row> : <button
                                type="button" className="btn btn-light waves-effect">
                    
                                <i className="bx bx-hourglass bx-spin font-size-16 align-middle mr-2"></i>{props.t(' Uploading participant data ')}
                                        
                            </button>
                        
                            }
                        </ModalFooter>
                    }
                </Modal>
            </div>
            <Licence  
                showopup ={showLimitModal}
                handelAdd={()=>{

                    setShowLimitModal(false);
                    setLoading(false);

                    if (props.selectedSchool && props.selectedSchool.attributes.schoolType == 11) {
                        setPopupTimer(10);
                        setShowBulkPopup(true);
                    }else if ( studentData && studentData.kids.length == 1) {
                        props.createStudentUser(tableData[0], schoolId);
                    }else{
                        setPopupTimer(10);
                        setShowBulkPopup(true);
                    }
                    
                }}

                addCount={tableData.length}
                handelClose={()=>{
                    setShowLimitModal(false);
                    setLoading(false);
                }}
                >
      </Licence>
        </React.Fragment>
            
    );
        
}
        
const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    const { classrooms } = state.Classroom;
    const { studentUser,studentUserLoading } = state.Kid;
    return { selectedSchool,classrooms,studentUser,studentUserLoading};
}
            
export default withNamespaces()(withRouter(connect(mapStatetoProps, {  setSelecetdSchool,getClassroomsForSchoolId,createStudentUser,updateStudentUser,updateAllKids})(AcademyStudentImport)));