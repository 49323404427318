import * as actions from "./actionTypes";

export const getPurchaseOrdersForOwnerId = (ownerId, ownerType) =>{
    return{
    type: actions.GET_INVENTORY_PURCHASE_ORDERS,
    payload: {ownerId, ownerType},
    }
}

export const updatePurchaseOrders = (inventoryPurchaseOrders) =>{
    return{
        type:actions.UPDATE_INVENTORY_PURCHASE_ORDERS,
        payload: {inventoryPurchaseOrders},
    }
}

export const updateDeletePurchaseOrder = (inventoryPurchaseOrder) =>{
    return{
        type:actions.UPDATE_DELETE_PURCHASE_ORDER,
        payload: {inventoryPurchaseOrder},
    }
}

export const editPurchaseOrder = (inventoryPurchaseOrder) =>{
    return{
        type: actions.EDIT_INVENTORY_PURCHASE_ORDER,
        payload: {inventoryPurchaseOrder}
    }
}

export const deletePurchaseOrder = (inventoryPurchaseOrder) =>{
    return{
        type:actions.DELETE_INVENTORY_PURCHASE_ORDER,
        payload: {inventoryPurchaseOrder},
    }
}

export const getPurchaseOrderItemsForPurchaseOrderId = (inventoryPurchaseOrder) =>{
    return{
        type:actions.GET_INVENTORY_PURCHASE_ITEM,
        payload: {inventoryPurchaseOrder},
    }
}

export const addPurchaseOrder = (inventoryPurchaseOrderId) =>{
    return{
        type: actions.ADD_INVENTORY_PURCHASE_ORDER,
        payload: {inventoryPurchaseOrderId}
    }
}

export const setSelectedPurchaseOrder = (inventoryPurchaseOrderId) => {
    return {
        type: actions.SET_SELECTED_PURCHASE_ORDER,
        payload: {inventoryPurchaseOrderId }
    }
}

export const updateSelectedPurchaseOrder = (inventoryPurchaseOrder) => {
    return {
      type: actions.UPDATE_SELECTED_PURCHASE_ORDER,
      payload: { inventoryPurchaseOrder },
    };
  };

 