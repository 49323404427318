import * as actions from "./actionTypes";

export const updateEnquiryFollowup = (enquiryFollowup) => {
  return {
    type: actions.UPDATE_ENQUIRY_FOLLOWUP,
    payload: { enquiryFollowup },
  };
};

export const deleteEnquiryFollowup = (enquiryFollowupId) => {
  return {
    type: actions.DELETE_ENQUIRY_FOLLOWUP,
    payload: {enquiryFollowupId},
  };
};
export const updateDeleteEnquiryFollowup = (enquiryFollowup) => {
  return {
    type: actions.UPDATE_DELETE_ENQUIRY_FOLLOWUP,
    payload: {enquiryFollowup},
  };
};

export const updateEnquiryFollowups = (enquiryFollowups,replace) => {
  return {
    type: actions.UPDATE_ENQUIRY_FOLLOWUPS,
    payload: { enquiryFollowups,replace },
  };
};

export const getEnquiryFollowupForSchoolId = (schoolId) => {
  return {
    type: actions.GET_ENQUIRY_FOLLOWUPS,
    payload: { schoolId },
  };
};

export const editEnquiryFollowup = (enquiryFollowup) => {
  return {
      type: actions.EDIT_ENQUIRY_FOLLOWUP,
      payload: { enquiryFollowup }
  }
}

export const saveEnquiryFollowups = (enquiryFollowups) => {
  return {
    type: actions.SAVE_ENQUIRY_FOLLOWUPS,
    payload: { enquiryFollowups },
  };
};

export const setSaveLoding = (state) => {
  return {
    type: actions.SAVE_LODING,
    payload: { state },
  };
};
