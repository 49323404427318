import Parse from "parse";
import { printLog } from "./loghelper";

export const getQuizActivityBatchMapForBatch = (courseId, batchId) => {
  printLog("HL: quizActivityBatch_helper : getQuizActivityBatchMapForBatch")

    try {
        return new Promise((resolve, reject) => {
            var query = new Parse.Query("QuizActivityBatchMap");
            query.equalTo("courseId", courseId);
            query.equalTo("batchId", batchId);
            query.notEqualTo("isDeleted", true);
            query.limit(1000);
            query.descending("createdAt");
            query.find().then(
                (result) => {
                    resolve(result);
                },
                (error) => {
                    reject(null);
                }
            )
        })
    } catch (error) { }
}

export const saveQabMap = (object,schoolId,emailNotification) => {
  printLog("HL: quizActivityBatch_helper : saveQabMap")
  try {
    return new Promise((resolve, reject) => {
      let isNew = object.id?false:true;
      object.save().then(
        (result) => {
          resolve(result);

           if(emailNotification){
            Parse.Cloud.run("function_sendEmail", {
              schoolId: schoolId,
              type:"assessment_batch",
              quizMapId:result.id,
              emailType:isNew?"initial":"update"
            }).then((res) => {
                console.log("function_sendEmail r",res)
            }, (error) => {
                console.log("function_sendEmail e",error)
            });
          }
        },
        (error) => {
          console.log("saveQabMap",error)
          reject(error);
        }
      );
  
    });
  } catch (error) { }
}