import React, { useEffect, useState } from 'react';
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { Row, Col, Label, Table, Card, CardBody } from "reactstrap";
import { withNamespaces } from "react-i18next";
import { matchPath } from "react-router";
import Switch from "react-switch";
import Parse from 'parse';
import {
    getLearningCurriculumSchoolMapForCurriculumId,
    saveLearningCurriculumSchoolMap,
    setSelecetdSchool,
    getAcademicCycleForCurriculumId,
    setSelectedAcademicCycle
} from '../../../store/actions';

const LearningCategoryAccess = (props) => {

    const match = matchPath(props.history.location.pathname, {
        path: "/learning-category-access/:franchiseId/:curriculumId/:cycleId/:subjectId",
        exact: true,
        strict: false,
    });

    const curriculumId = match.params.curriculumId;
    const franchiseId = match.params.franchiseId;
    const cycleId = match.params.cycleId;
    const subjectId = match.params.subjectId;

    const [schoolDisplay, setSchoolDisplay] = useState([])

    useEffect(() => {
        if (curriculumId) {
            props.getLearningCurriculumSchoolMapForCurriculumId(curriculumId);
        }
        props.getAcademicCycleForCurriculumId(curriculumId);
        props.setSelectedAcademicCycle(cycleId);
    }, [curriculumId, cycleId]);


    const Offsymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                <small>No</small>
            </div>
        );
    };

    const OnSymbol = (props) => {
        return (
            <div
                style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    height: "100%",
                    fontSize: 12,
                    color: "#fff",
                    paddingRight: 2,
                }}
            >
                {" "}
                <small>Yes</small>
            </div>
        );
    };

    const getSchoolName = (schoolId) => {
        if (props.schools && props.schools.length > 0) {
            let temp = props.schools.filter(
                (school) => school.id === schoolId
            );
            if (temp.length > 0) {
                return (temp[0].attributes.Name ?? "-")
            }
        }
    }

    useEffect(() => {
        let list = [];
        for (let i = 0; i < props.learningCurriculumsSchoolMapForCurriculumId.length; i++) {
            const lc = props.learningCurriculumsSchoolMapForCurriculumId[i];

            if (lc.attributes.academicCycleIds && lc.attributes.academicCycleIds.includes(cycleId)) {
                list.push(lc);
            }

        }
        setSchoolDisplay(list);

    }, [props.learningCurriculumsSchoolMapForCurriculumId])

    return (
        <div className='page-content'>
            <Col lg={8} md={8} xs={12}>
                <Row >
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={() => { window.history.back() }}
                    >
                        <ul className="ml-2 list-unstyled">
                            <div >
                                <i className="bx bx-chevron-left h1 text-primary"></i>
                            </div>
                        </ul>
                    </div>
                    <h4 className="mt-2">Learning Category Access</h4>
                </Row>
            </Col>
            <Card>
                <CardBody>
                    {schoolDisplay.length > 0 ? <Table className="table-centered table-nowrap table-hover ">
                        <thead className="thead-light" style={{ height: "0" }}>
                            <tr>
                                <th scope="col">Schools</th>
                                <th scope="col">Permission</th>
                            </tr>
                        </thead>
                        <tbody>
                            {schoolDisplay.map((lc, key) => (
                                <tr key={key}>
                                    <td>
                                        <Label>{getSchoolName(lc.attributes.schoolId)}</Label>
                                    </td>
                                    <td>
                                        <Switch
                                            height={25}
                                            uncheckedIcon={<Offsymbol />}
                                            checkedIcon={<OnSymbol />}
                                            onColor="#626ed4"
                                            onChange={(isChecked) => {
                                                const updatedData = schoolDisplay.map((item) => {
                                                    if (item.attributes.schoolId === lc.attributes.schoolId && item.attributes.curriculumId === lc.attributes.curriculumId) {
                                                        const learningCategoryIds = item.attributes.learningCategoryIds || [];
                                                        const updatedAcademicCycleIds = [...learningCategoryIds];

                                                        if (isChecked && !updatedAcademicCycleIds.includes(subjectId)) {
                                                            updatedAcademicCycleIds.push(subjectId);
                                                        } else if (!isChecked && updatedAcademicCycleIds.includes(subjectId)) {
                                                            const index = updatedAcademicCycleIds.indexOf(subjectId);
                                                            updatedAcademicCycleIds.splice(index, 1);
                                                        }

                                                        item.set('learningCategoryIds', updatedAcademicCycleIds);

                                                        return item;
                                                    }

                                                    return item;
                                                });

                                                console.log(updatedData);

                                                Parse.Object.saveAll(updatedData)
                                                    .then((savedObjects) => {
                                                        console.log('Updated data:', savedObjects);
                                                    })
                                                    .catch((error) => {
                                                        console.error('error', error);
                                                    });
                                            }}
                                            checked={!(lc.attributes.learningCategoryIds && lc.attributes.learningCategoryIds.includes(subjectId))}
                                        />
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </Table> : <h4 className='text-center'>Please provide curriculum access to school!</h4>}
                </CardBody>
            </Card>
        </div>
    )
}

const mapStatetoProps = state => {
    const { learningCurriculumsSchoolMapForCurriculumId } = state.LearningCurriculumsSchoolMap;
    const { selectedSchool, schools } = state.School;
    const { curriculumAcademicCycles, selectedAcademicCycle } = state.AcademicCycles;

    return { learningCurriculumsSchoolMapForCurriculumId, selectedSchool, schools, curriculumAcademicCycles, selectedAcademicCycle };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, {
    getLearningCurriculumSchoolMapForCurriculumId,
    saveLearningCurriculumSchoolMap,
    setSelecetdSchool,
    getAcademicCycleForCurriculumId,
    setSelectedAcademicCycle
})(LearningCategoryAccess)));