import React, { useState, useEffect } from "react";
import {
    getTeachersForSchool,
    getClassroomsForSchoolId,
    getAllKids,
    getSchool,
    setSelecetdSchool,
} from "../../store/actions";
import Switch from "react-switch";
import SweetAlert from "react-bootstrap-sweetalert";
import toastr from "toastr";
import {
    Row,
    Col,
    Card,
    CardBody,
    Form,
    FormGroup,
    Label,
    Input,
    Container,
} from "reactstrap";


//Import Images
import avatar2 from "../../assets/images/users/user.png";

import { withNamespaces } from "react-i18next";
import { connect } from "react-redux";
import { withRouter, Link } from "react-router-dom";
import { Parse } from "parse";

const KidPicker = (props) => {

    var schoolIdParam = props.schoolId;


    useEffect(() => {
        if (
            schoolIdParam &&
            (!props.selectedSchool || props.selectedSchool.id !== schoolIdParam)
        ) {
            props.setSelecetdSchool(schoolIdParam);
        }
    }, [props.selectedSchool, schoolIdParam]);

    //SchoolId is schoolIdParam
    const [classroomList, setClassroomList] = useState([]);

    const [selectedKids, setSelectedKids] = useState([]);
    const [searchBarString, setSeachBarString] = useState("");
    const [selectedClassroomFilter, setSelectedClassroomFilter] = useState(
        undefined
    );

    const [isLoading, setIsLoading] = useState(true);
    const [intialLoading, setIntialLoading] = useState(true);

    const [masterKidList, setMasterKidList] = useState([]);


    useEffect(() => {
        if (props.selectedKids) {
            setSelectedKids(props.selectedKids)
        }
    }, [props.selectedKids])

    useEffect(() => {
        var schoolId = schoolIdParam;
        props.getClassroomsForSchoolId(schoolId);
        props.getAllKids(schoolId);
    }, [schoolIdParam]);

    useEffect(() => {
        if (props.kids || props.admissionKids) {
            let tempList = []
            tempList.push.apply(tempList, props.kids)
            if (props.showAdmissionKids && props.admissionKids) {
                // console.log("props.admissionKids", props.admissionKids);
                tempList.push.apply(tempList, props.admissionKids)
            }
            setMasterKidList(tempList)
        }
    }, [props.kids, props.admissionKids])


    useEffect(() => {
        if (props.classrooms && props.classrooms.length > 0) {
            var tempArray = [...props.classrooms];
            const ClassroomObject = Parse.Object.extend("ClassRoom");
            var tempClassroomObject = new ClassroomObject();
            tempClassroomObject.set("id", "All Classrooms");
            tempClassroomObject.set("Name", "All Classrooms");
            tempArray.unshift(tempClassroomObject);

            if (props.showAdmissionKids) {
                const AdmissionClass = Parse.Object.extend("ClassRoom");
                var admissionClass = new AdmissionClass();
                admissionClass.set("id", "Admission");
                admissionClass.set("Name", "Admission");
                tempArray.push(admissionClass);
            }

            setClassroomList(tempArray);
            setSelectedClassroomFilter(tempClassroomObject);
        }
    }, [props.classrooms]);


    function removeElement(array, elem) {
        var index = array.indexOf(elem);
        if (index > -1) {
            array.splice(index, 1);
        }
    }

    const getClassRoomName = (kid) => {
        if ((kid.attributes.SchoolID == undefined || kid.attributes.SchoolID.length === 0) && kid.attributes.schoolAdmitted) {
            return "Admission"
        } else {
            let classId = kid.attributes.ClassRoomID;
            var classrooms = classroomList.filter(
                (classroom) => classroom.id === classId
            );

            if (classrooms.length > 0) {
                return classrooms[0].attributes.Name
            }
        }
    }


    function handleSelectAll() {
        let tempList = [...getAllKidsFilteredList()];
        if (tempList.length > 0) {
            tempList.shift();
            if (tempList.length > 0) {
                let oldList = [...selectedKids];
                let temp = oldList.concat(tempList);
                setSelectedKids(temp);
            }
        }
    }
    const handleCheckboxChangeForSelected = (kObject) => {
        var tempSelectedList = [...selectedKids];
        removeElement(tempSelectedList, kObject);
        setSelectedKids(tempSelectedList);
    };

    const handleClassroomFilterChange = (value) => {
        var classrooms = classroomList.filter(
            (classroom) => classroom.id === value
        );

        if (classrooms.length > 0) {
            setSelectedClassroomFilter(classrooms[0]);
        }
    };

    const getAllKidsFilteredList = () => {
        var listToReturn = [];
        if (selectedClassroomFilter != undefined) {
            if (selectedClassroomFilter.id === "All Classrooms") {
                listToReturn = [...masterKidList];
            } else if (selectedClassroomFilter.id === "Admission") {
                var i = 0;
                for (i = 0; i < masterKidList.length; i++) {
                    if (
                        masterKidList[i].attributes.SchoolID == undefined || masterKidList[i].attributes.SchoolID.length === 0
                    ) {
                        listToReturn.push(masterKidList[i]);
                    }
                }
            } else {
                var i = 0;
                for (i = 0; i < masterKidList.length; i++) {
                    if (masterKidList[i].attributes.ClassRoomID === selectedClassroomFilter.id) {
                        listToReturn.push(masterKidList[i]);
                    } else if (selectedClassroomFilter.attributes.classroomType == 2 &&
                        masterKidList[i].attributes.daycareClassId === selectedClassroomFilter.id
                    ) {
                        listToReturn.push(masterKidList[i]);
                    }
                }
            }
        }
        if (searchBarString === "") {
        } else {
            listToReturn = listToReturn.filter((object) =>
                object.attributes.Name.toLowerCase().includes(
                    searchBarString.toLowerCase()
                )
            );
        }
        if (selectedKids.length > 0) {
            listToReturn = listToReturn.filter(
                (object) => !selectedKids.includes(object)
            );
        }
        if (
            listToReturn != null &&
            listToReturn.length > 0 &&
            listToReturn[0].attributes.Name !== "Pick All"
        ) {
            const KidObjectParse = Parse.Object.extend("Kid");
            var kidObjectParse = new KidObjectParse();
            kidObjectParse.set("Name", "Pick All");
            listToReturn.unshift(kidObjectParse);
        }
        return listToReturn;
    };

    const handleSearchBarTextChange = (value) => {
        setSeachBarString(value);
    };

    const getKidPhoto = (kObject) => {
        var kidPhoto = "";
        kidPhoto =
            kObject.attributes.Photo != undefined
                ? kObject.attributes.Photo._url
                : avatar2;
        return kidPhoto;
    };

    const handleCheckboxChange = (kObject) => {
        if (kObject.attributes.Name === "Pick All") {
            handleSelectAll();
        } else {
            var tempSelectedList = [...selectedKids];
            tempSelectedList.unshift(kObject);
            setSelectedKids(tempSelectedList);
        }
    };

    return (
        <React.Fragment>
            <Container fluid={true}>

                {(!isLoading) && (
                    <Row>
                        <Col xs="12">
                            <div className="text-center my-3">
                                <Label className="text-success">
                                    <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                                    Loading List...{" "}
                                </Label>
                            </div>
                        </Col>
                    </Row>
                )}

                {(isLoading) && (
                    <Row>
                        <Col lg={12} className="mx-auto">
                            <Card>
                                <CardBody>
                                    <Row>
                                        <div className="d-flex justify-content-between">
                                            <Label className="mb-0 ml-1">Select Student</Label>
                                            <button className="btn btn-primary mb-2" onClick={() => {
                                                if (selectedKids.length > 0) {
                                                    props.onButtonClick(selectedKids)
                                                } else {
                                                    toastr.warning("Please select at least one student to proceed")
                                                }
                                            }}>
                                                Next
                                            </button>
                                        </div>
                                    </Row>

                                    <hr />

                                    <Row lg={12}>
                                        <Col lg={6}>
                                            <Row>
                                                <Col lg={6}>
                                                    <FormGroup>
                                                        <select
                                                            className="form-control"
                                                            onChange={(e) => {
                                                                handleClassroomFilterChange(e.target.value);
                                                            }}
                                                        >
                                                            {classroomList.map((classroomObject, key) => (
                                                                <option key={key} value={classroomObject.id}>
                                                                    {classroomObject.attributes.Name}
                                                                </option>
                                                            ))}
                                                        </select>
                                                    </FormGroup>
                                                </Col>
                                                <Col lg={6}>
                                                    <Input
                                                        type="text"
                                                        name="searchAG"
                                                        className="inner form-control mb-3"
                                                        placeholder="Search"
                                                        onChange={(e) => {
                                                            handleSearchBarTextChange(e.target.value);
                                                        }}
                                                    />
                                                </Col>
                                            </Row>

                                            <Row>
                                                <div className="table-responsive">
                                                    <table className="table table-nowrap table-centered mb-0">
                                                        <tbody>
                                                            {getAllKidsFilteredList().length === 0 && (
                                                                <tr >
                                                                    <td> <p>No Student found</p></td>
                                                                    {/* <hr /> */}
                                                                </tr>
                                                            )}

                                                            {getAllKidsFilteredList().map(
                                                                (kObject, keyInside) => (
                                                                    <tr key={keyInside}>
                                                                        <td style={{ width: "60px" }}>
                                                                            <input
                                                                                type="checkbox"
                                                                                id={"customCheck12_" + keyInside}
                                                                                onChange={() => {
                                                                                    handleCheckboxChange(kObject);
                                                                                }}
                                                                                checked={selectedKids.includes(kObject)}
                                                                            />
                                                                        </td>

                                                                        <td>
                                                                            <Label className="text-truncate font-size-14 m-0">
                                                                                {kObject.attributes.Name !==
                                                                                    "Pick All" && (
                                                                                        <img
                                                                                            src={getKidPhoto(kObject)}
                                                                                            className="rounded-circle avatar-xs m-1"
                                                                                            alt=""
                                                                                        />
                                                                                    )}
                                                                                <Label className="text-dark ml-2">
                                                                                    {kObject.attributes.Name}
                                                                                </Label>
                                                                                {/* <br />
                                                                                <p>{getClassRoomName(kObject)}</p> */}
                                                                            </Label>
                                                                        </td>

                                                                    </tr>
                                                                )
                                                            )}
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </Row>
                                        </Col>
                                        {<Col lg={6}>
                                            <Row lg={12}>
                                                <Col lg={12}>
                                                    <div
                                                        className="text-center pt-2 py-auto bg-primary text-white"
                                                        style={{ borderRadius: "5px", height: "35px" }}
                                                    >
                                                        {props.headerForAlreadyIn ?? "Already In Program"}
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row>
                                                <div className="table-responsive mt-3">
                                                    <table className="table table-nowrap table-centered mb-0">
                                                        <tbody>
                                                            {selectedKids.length === 0 && (
                                                                <tr >
                                                                    <td> <p>No Student Selected</p></td>
                                                                    {/* <hr /> */}
                                                                </tr>
                                                            )}

                                                            {selectedKids.map((kObject, keyInside) => (
                                                                <tr key={keyInside}>
                                                                    <td style={{ width: "60px" }}>
                                                                        <input
                                                                            type="checkbox"
                                                                            id={"customCheck1_" + keyInside}
                                                                            onChange={() => {
                                                                                if (!props.disableRemoveKids) {
                                                                                    handleCheckboxChangeForSelected(
                                                                                        kObject
                                                                                    );
                                                                                }
                                                                            }}
                                                                            checked={selectedKids.includes(kObject)}
                                                                        />
                                                                    </td>

                                                                    <td>
                                                                        <Label className="text-truncate font-size-14 m-0">
                                                                            <img
                                                                                src={
                                                                                    kObject.attributes.Photo != undefined
                                                                                        ? kObject.attributes.Photo._url
                                                                                        : avatar2
                                                                                }
                                                                                className="rounded-circle avatar-xs m-1"
                                                                                alt=""
                                                                            />
                                                                            <Label className="text-dark ml-2">
                                                                                {kObject.attributes.Name}
                                                                            </Label>{" "}
                                                                        </Label>
                                                                        {/* <br />
                                                                        <p>{getClassRoomName(kObject)}</p> */}
                                                                    </td>
                                                                    <td></td>
                                                                    <td></td>
                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                </div>

                                            </Row>
                                        </Col>}
                                    </Row>
                                    <div className="d-flex justify-content-end"> 
                                        <button className="btn btn-primary d-flex justify-content-end" onClick={() => {
                                            props.onButtonClick(selectedKids)
                                        }}>
                                            Next
                                        </button>
                                    </div>
                                </CardBody>
                            </Card>
                        </Col>
                    </Row>
                )}
            </Container>
        </React.Fragment>
    );
}

const mapStatetoProps = (state) => {
    const { classrooms } = state.Classroom;
    const { kids } = state.Kid;
    const { selectedSchool } = state.School;

    return {
        classrooms,
        kids,
        selectedSchool,
    };
};

export default withNamespaces()(
    withRouter(
        connect(mapStatetoProps, {
            getTeachersForSchool,
            getClassroomsForSchoolId,
            getAllKids,
            setSelecetdSchool,
            getSchool,

        })(KidPicker)
    )
);
