import React, { useState,useEffect} from 'react';
import { Container, Modal, ModalHeader, ModalBody,Table ,Label, ModalFooter} from "reactstrap";
import Parse from 'parse';



const ContentCopyPopup = (props) => {

    
    const [selectedTab, setSelecetedTab] = useState("1");
    const [ownerIds, setOwnerIds] = useState([]);
    const [courseLoading, setCourseLoading] = useState(true);
    const [courseList, setCourseList] = useState([]);
    const [selectedCourse, setSelectedCourse] = useState(undefined);

    const [categoryLoading, setCategoryLoading] = useState(false);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategory, setSelectedCategory] = useState(undefined);

    const [activityLoading, setActivityLoading] = useState(false);
    const [activityList, setActivityList] = useState([]);
    const [selectedActivity, setSelectedActivity] = useState(undefined);

    const [filesLoading, setFilesLoading] = useState(false);
    const [filesList, setFilesList] = useState([]);
    const [selectedFile, setSelectedFile] = useState(undefined);


    useEffect(() => {

        var ids = [];
        if (props.schoolId === "franchise") {
            props.getCoursesForOwnerIds([Parse.User.current().attributes.franchiseId]);
        } else if (props.selectedSchool) {
            ids.push(props.selectedSchool.id);
            ids.push(props.selectedSchool.attributes.instituteId);
            if (props.selectedSchool.attributes.franchiseId) {
                ids.push(props.selectedSchool.attributes.franchiseId);
            }

            if (ownerIds.length === ids.length) {

                var call = false;
                for (const i in ownerIds) {
                    if (ownerIds[i] !== ids[i]) {
                        call = true;
                    }
                }
                if (call) {
                    getAllCourses(ids);
                }

            } else {
                setOwnerIds(ids);
                getAllCourses(ids);
            }
        }
    }, [props.selectedSchool]);

    const getAllCourses = (ids) => {
        var Course = Parse.Object.extend("Course");
        var query = new Parse.Query(Course);
        query.containedIn("ownerId", ids);
        if (true) {
            query.fromLocalDatastore();
        }
        query.notEqualTo("isDeleted", true);
        query.descending("createdAt");
        query.limit(1000);
        query.find().then(
            (result) => {
                setCourseList(result);
                setCourseLoading(false);
            },
            (error) => {
                
            }
        );
    }

    const getAllCategory = (coures) => {
        setCategoryLoading(true);
        var Course = Parse.Object.extend("LearningCategory");
        var query = new Parse.Query(Course);
        query.equalTo("courseId", coures.id);
        query.notEqualTo("isDeleted", true);
        query.ascending("order")
        query.limit(1000);
        query.find().then(
            (result) => {
                setCategoryList(result);
                setCategoryLoading(false);
            },
            (error) => {
                console.log('car', error)
            }
        );
    }

    const getAllActivity = (category) => {
        setActivityLoading(true);
        var Course = Parse.Object.extend("LearningActivity");
        var query = new Parse.Query(Course);
        query.equalTo("category", category.id);
        query.notEqualTo("isDeleted", true);
        query.ascending("orderBy");
        query.addAscending("createdAt");
        query.limit(1000);
        query.find().then(
            (result) => {
                setActivityList(result);
                setActivityLoading(false);
            },
            (error) => {
                console.log('car', error)
            }
        );
    }

    const getAllFiles = (activity) => {
        setFilesLoading(true);
        if (activity.attributes.resourceFolderId) {
            var Course = Parse.Object.extend("Files");
            var query = new Parse.Query(Course);
            query.equalTo("folderId", activity.attributes.resourceFolderId);
            query.notEqualTo("isDeleted", true);
            query.ascending("orderBy");
            query.limit(1000);
            query.find().then(
                (result) => {
                    let list = [];
                    for (const file of result) {
                        let ft = file.attributes.fileType;
                        if (ft != "meeting" && ft != "quiz") {
                            list.push(file);
                        }
                    }
                    setFilesList(list);
                    setFilesLoading(false);
                },
                (error) => {
                    console.log('files', error)
                }
            );
        } else {
            setFilesList([]);
            setFilesLoading(false);
        }
        
    }

    const getFileTypeImage = (file) => {
        let fileType = file.attributes.fileType;

        if (fileType == "vimeo" || fileType == "youtube") {
            return "bx bxs-video";
        }
        if (fileType == "image") {
            return "bx bxs-file-image";
        }
        if (fileType == "file") {
            return "bx bxs-file-doc";
        }
        if (fileType == "audio") {
            return "bx bxs-music";
        }
        if (fileType == "url") {
            return "bx bxs-music";
        }
        return "bx bxs-file-doc";
    }
    return (
        <React.Fragment>
            <Modal
                isOpen={props.showPopup}
                fade={false}
                className="modal-dialog"
                size='lg'
            >
                <ModalHeader className="bg-light" toggle={() => {
                    props.onClickCancel();
                }}>
                    Copy Content


                    <hr></hr>

                    <div>
                        {selectedCourse ?
                            <Label
                                className='text-primary'
                                onClick={() => {
                                    if (selectedTab != "1") {
                                        setSelecetedTab("1")
                                        setSelectedCourse(undefined)
                                        setSelectedCategory(undefined)
                                        setSelectedActivity(undefined)
                                        setSelectedFile(undefined)
                                    }
                                }}
                            >
                                <p className='mb-0 font-size-12'> <u>{selectedCourse.attributes.name} </u></p>
                            </Label> :
                            
                            <Label className='text-muted'>
                                <p className='mb-0'>  Course</p>
                            </Label>
                        
                        }
                        <Label className='ml-4 mr-4'> {">>"}</Label>

                        {selectedCategory ?
                            <Label
                                className='text-primary'
                                onClick={() => {
                                    if (selectedTab != "2") {
                                        setSelecetedTab("2")
                                        setSelectedCategory(undefined)
                                        setSelectedActivity(undefined)
                                        setSelectedFile(undefined)
                                    }
                                }}
                            >
                                <p className='mb-0 font-size-12' >  <u>{selectedCategory.attributes.name}</u></p>
                            </Label> :
                            
                            <Label className='text-muted'>
                                <p className='mb-0'>   Chapter</p>
                            </Label>
                        
                        }
                        <Label className='ml-4 mr-4'> {">>"}</Label>

                        {selectedActivity ?
                            <Label
                                className='text-primary'
                                onClick={() => {
                                    if (selectedTab != "3") {
                                        setSelecetedTab("3")
                                        setSelectedActivity(undefined)
                                        setSelectedFile(undefined)
                                    }
                                }}
                            >
                                <p className='mb-0 font-size-12'>  <u>{selectedActivity.attributes.name}</u></p>
                            </Label> :
                            
                            <Label className='text-muted' >
                                <p className='mb-0'>   Topic</p>
                            </Label>
                        
                        }
                        <Label className='ml-4 mr-4'> {">>"}</Label>
                        {selectedFile ?
                            <Label
                                className='text-primary'
                                onClick={() => {
                                    setSelectedFile(undefined)
                                }}
                            >
                                <p className='mb-0 font-size-12'>  <u>{selectedFile.attributes.name}</u></p>
                            </Label> :
                            
                            <Label className='text-muted' >
                                <p className='mb-0'>  Content</p>
                            </Label>
                        
                        }
                    </div>
                    

                    
                </ModalHeader>
                <ModalBody
                    
                    style={{ maxHeight: `calc(100vh - 200px)`, overflowY: "auto", minHeight: "500px" }}
                >
                    {selectedTab === "1" && <div>
                        {
                            courseLoading ?
                                <div Height="300px" className='text-success text-center'> <i className="bx bx-loader bx-spin font-size-16 align-middle "></i>  Loading</div> :
                                <div>
                                
                                    <p>Please Selecte Course</p>
                                    
                                    <div width={"50px"} className="table-responsive">
                                        <table className="table table-centered table-nowrap mb-0">
                                            <tbody>
                                                {courseList.map((course, key) =>
                                                    <tr key={course.id}
                                                        onClick={() => {
                                                            setSelectedCourse(course)
                                                            getAllCategory(course);
                                                            setSelecetedTab("2")
                                                        }}
                                                        style={{ cursor: 'pointer' }}
                                                        
                                                    >
                                                        <td className={selectedCourse && selectedCourse.id === course.id ? "border border-success rounded " : ""}>
                                                            {(key + 1)}
                                                        </td>
                                                        <td className={selectedCourse && selectedCourse.id === course.id ? "border border-success rounded " : ""}>
                                                            <h5 className="text-truncate font-size-14 mb-0">{course.attributes.name}</h5>
                                                            <p className="text-success mb-0">{course.attributes.state}</p>
                                                        </td>
                                               
                                                    </tr>)}
                                            </tbody>
                                    
                                        </table>
                                    </div>
                                </div>
                        }
                    </div>
                    }

                    {selectedTab === "2" && <div>
                        {
                            categoryLoading ?
                                <div Height="300px" className='text-success text-center'> <i className="bx bx-loader bx-spin font-size-16 align-middle "></i>  Loading</div> :
                                <div>
                                
                                    {categoryList.length > 0 ? <p>Please Selecte Chapter</p> : <p className='text-danger text-center'>No chapters to select </p>}
                                    <Table className="  table-centered ">
                                        <tbody>
                                            {categoryList.map((category, key) =>
                                                <tr key={category.id}
                                                    onClick={() => {
                                                        setSelectedCategory(category)
                                                        getAllActivity(category);
                                                        setSelecetedTab("3")
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                    
                                                >
                                                    <td width={"50px"}className={selectedCategory && selectedCategory.id === category.id ? "border border-success rounded " : ""}>
                                                        {(key + 1)}
                                                    </td>
                                                    <td className={selectedCategory && selectedCategory.id === category.id ? "border border-success rounded" : ""}>
                                                        <h5 className="text-truncate font-size-14">{category.attributes.name}</h5>
                                                        
                                                    </td>
                                               
                                                </tr>)}
                                        </tbody>
                                    </Table>
                                

                                </div>
                            
                        }
                    </div>
                    }


                    {selectedTab === "3" && <div>

                  
                        {
                            activityLoading ?
                                <div Height="300px" className='text-success text-center'> <i className="bx bx-loader bx-spin font-size-16 align-middle "></i>  Loading</div> :
                                <div>
                                
                                    {activityList.length > 0 ? <p>Please Selecte Topic</p> : <p className='text-danger text-center'>No topic to select </p>}
                                    <Table className="  table-centered ">
                                        <tbody>
                                            {activityList.map((activity, key) =>
                                                <tr key={activity.id}
                                                    onClick={() => {
                                                        setSelectedActivity(activity)
                                                        getAllFiles(activity);
                                                        setSelecetedTab("4")
                                                       
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                    
                                                >
                                                    <td width={"50px"}className={selectedActivity && selectedActivity.id === activity.id ? "border border-success rounded" : ""}>
                                                        {(key + 1)}
                                                    </td>
                                                    <td className={selectedActivity && selectedActivity.id === activity.id ? "border border-success rounded" : ""}>
                                                        <h5 className="text-truncate font-size-14">{activity.attributes.name}</h5>
                                                        
                                                    </td>
                                               
                                                </tr>)}
                                        </tbody>
                                    </Table>
                                

                                </div>
                            
                        }
                    </div>
                    }

                    {selectedTab === "4" && <div>
                        {
                            filesLoading ?
                                <div Height="300px" className='text-success text-center'> <i className="bx bx-loader bx-spin font-size-16 align-middle "></i>  Loading</div> :
                                <div>
                                
                                    
                                    {filesList.length > 0 ? <p>Please Selecte Content</p> : <p className='text-danger text-center'>No content to select </p>}
                                    <Table className="  table-centered ">
                                        <tbody>
                                            {filesList.map((file, key) =>
                                                <tr key={file.id}
                                                    onClick={() => {
                                                        setSelectedFile(file)
                                                       
                                                    }}
                                                    style={{ cursor: 'pointer' }}
                                                    
                                                >
                                                    <td width={"50px"} className={selectedFile && selectedFile.id === file.id ? "border border-success rounded " : ""}>
                                                        {(key + 1)}
                                                    </td>
                                                    <td width={"60px"} className={selectedFile && selectedFile.id === file.id ? "border border-success rounded " : ""}>
                                                        <div className="avatar-sm">
                                                            <span className="avatar-title rounded-circle bg-soft-primary text-primary font-size-20">
                                                                <i className={getFileTypeImage(file)}></i>
                                                            </span>
                                                        </div>

                                                    </td>
                                                    <td className={selectedFile && selectedFile.id === file.id ? "border border-success rounded " : ""} >
                                                        <h5 className="text-truncate font-size-14">{file.attributes.name}</h5>
                                                    </td>
                                               
                                                </tr>)}
                                        </tbody>
                                    </Table>
                                

                                </div>
                            
                        }
                    </div>
                    }




                
                   
                </ModalBody>

                <ModalFooter>
                    <button
                        className='btn btn-primary'
                        disabled={selectedFile ? false : true}
                        onClick={() => {

                            props.onSelectFileToCopy(selectedFile);
                            props.onClickCancel();
                        }}
                    >
                        Done
                    </button>
                </ModalFooter>
                
            </Modal>

               
        </React.Fragment>
    );
}
            
export default ContentCopyPopup;