import React, { useEffect, useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, Col, Row, Label} from "reactstrap";
import { setSelecetdSchool, showPricingPopup } from "../../store/actions";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import toastr from 'toastr';
import Parse from "parse";
import { withNamespaces } from "react-i18next";
import subscribe from "../../assets/images/studentsubscribe.png";

const Licence = (props) => {
    const [licenceResult, setLicenceResult] = useState(undefined);
    const [loading, setLoading] = useState(false);
    const [timer, setTimer] = useState(-1); // Timer countdown in seconds
    const [timerRunning, setTimerRunning] = useState(false);


    useEffect(() => {
        if (props.showopup && props.selectedSchool) {
            setLoading(true);
            setLicenceResult(undefined);
            checkLicence();
            
        } else {
            
            setTimerRunning(false);
            setTimer(-1); 
        }
    }, [props.showopup,]);

    const checkLicence=()=>{
        studentLimitCheck(
            props.selectedSchool.id,
            props.selectedSchool.attributes.instituteId,
            props.selectedSchool.attributes.franchiseId
        ).then((result) => {
            if (result) {

                const numberOfSubscriptions  = result.numberOfSubscriptions;
                const numberOfUsedSubscriptions = result.numberOfUsedSubscriptions;
                const graceLimit = result.grace ?? 5;
                const remaining = (numberOfSubscriptions+ graceLimit)-numberOfUsedSubscriptions;
                const extra = remaining -(props.addCount??1)

                if(result.canAdd){
                    if (extra>=0 ) {
                        props.handelAdd();
                    } else {

                        if(extra < 0  ){
                            setTimer(-1);
                            setTimerRunning(false);
                        }else {
                            setTimer(10);
                            setTimerRunning(true);
                        }

                        setLicenceResult(result);
                        setLoading(false);
                        
                    }
                }else{
                    setLicenceResult(result);
                    setLoading(false);
                }
                
            } else {
                toastr.warning("Please try again!!");
                setLoading(false);
                props.handelClose(); 
            }
        });
    }

    useEffect(() => {
        let interval;
        if (timerRunning) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        }
        return () => {
            clearInterval(interval);
            if (timer === 0) {
                //props.handelClose(); 
            }
        };
    }, [timerRunning, timer]);

    const studentLimitCheck = () => {
        return new Promise((resolve, reject) => {
            // console.log("window.location.host",window.location.host)
            Parse.Cloud.run("function_getLicenseSubscription", {
                schoolId: props.selectedSchool.id,
                instituteId: props.selectedSchool.attributes.instituteId,
                franchiseId: props.selectedSchool.attributes.franchiseId,
                hostName: window.location.host,
                hostType: "Web"
            }).then((res)=>{

                // console.log(res);

                resolve(res)
                // resolve({
                //     canAdd: true,
                //     numberOfSubscriptions: 50,
                //     numberOfUsedSubscriptions: 53,
                    
                // });
            })
            
        });
    };

    const handelEnquireNow = (title) => {
        if (Parse.User.current() && props.selectedSchool) {
            const lilPackageLeads = Parse.Object.extend("lilPackageLeads");
            const packageLeads = new lilPackageLeads();
            packageLeads.set("name", props.userTeacher.attributes.Name);
            packageLeads.set("schoolName", props.selectedSchool.attributes.Name);
            packageLeads.set("email", props.selectedSchool.attributes.EMail);
            packageLeads.set("phoneNumber", props.selectedSchool.attributes.primaryPhoneNumber);
            packageLeads.set("location", props.selectedSchool.attributes.city);
            packageLeads.set("schoolId", props.selectedSchool.id);
            packageLeads.set("packageSelected", "Licence Subscription");

            if (Parse.User.current().attributes.teacherId) {
                packageLeads.set("userId", Parse.User.current().attributes.teacherId);
            } else {
                packageLeads.set("userId", Parse.User.current().id);
            }

            packageLeads.save().then(
                (result) => {
                    toastr.success("Thank you for showing interest, One of our executive will  be in touch with you soon.", { timeOut: 4000 });
                },
                (error) => {
                    // console.log(error);
                    setLoading(false);
                }
            );
        } else {
            // setShowUserInfoPopup(licenceObject);
        }
    };
    

    const renderModalBody = () => {
        if (!licenceResult) return null;

        const canAdd = licenceResult.canAdd;
        const  numberOfSubscriptions  = licenceResult.numberOfSubscriptions;
        const  numberOfUsedSubscriptions = licenceResult.numberOfUsedSubscriptions;
        const  graceLimit = licenceResult.grace;

        const remaining = (numberOfSubscriptions + graceLimit)- numberOfUsedSubscriptions;

        if (remaining > 0 && canAdd) {
            return (
                <Row style={{ position: 'relative', zIndex: '1', }}>
                    <Col lg={8} className='text-center m-0'>
                        <h6><label className='m-2'>Number of Licence: {numberOfSubscriptions}</label></h6>
                        <h6><label className='m-2'>Number of Used Licence: {numberOfUsedSubscriptions}</label></h6>
                        {((props.addCount??1) > remaining ) ?
                            <div>
                                <h6 className='m-3'>{props.t('You are attempting to add more students than your current licenses allow. Please purchase additional licenses to accommodate more students.')}</h6>

                                <p className='m-4' style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
                                    <span style={{ color: 'green' }}>{props.t("To Buy More licences")}</span><br /><br />
                                    {props.t("Please contact lilTriangle support.")}
                                </p>
                                <p className="m-1" style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>+91 7676548853</p>
                                <p className="m-0" style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>hello@liltriangle.com</p>
                                Or Click on Enquire now
                            </div>
                        :<div>
                            <b style={{color: 'orange'}}className='m-5' >Reached Maximum Limit</b><br/>
                            <h6 className='m-3' >lilTriangle offers you add up to {graceLimit} students as grace</h6>
                            <h6 className='m-3'>Used {remaining} out of {graceLimit} </h6>
                        </div>
                        
                        }
                    </Col>
                    <Col lg={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={subscribe} alt="Subscribe" style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
                    </Col>
                </Row>
            );
        } else {
            return (
                <Row style={{ position: 'relative', zIndex: '1' }}>
                    <Col lg={8} className='text-center m-0'>
                        <h6><label className='m-2'>Number of Licence: {numberOfSubscriptions}</label></h6>
                        <h6><label className='m-2'>Number of Used Licence: {numberOfUsedSubscriptions}</label></h6>
                        <h2 className='text-danger m-3' style={{ fontWeight: 'bold' }}>Reached maximum</h2>
                        <p className='m-4' style={{ fontSize: '1.1em', fontWeight: 'bold' }}>
                            <span style={{ color: 'green' }}>{props.t("To Buy More licences")}</span><br /><br />
                            {props.t("Please contact lilTriangle support.")}
                        </p>
                        <p>
                            <p className="m-1" style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>+91 7676548853</p>
                            <p className="m-0" style={{ textDecoration: 'underline', cursor: 'pointer', color: 'blue' }}>hello@liltriangle.com</p>
                            Or Click on Enquire now
                        </p>
                    </Col>
                    <Col lg={4} style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <img src={subscribe} alt="Subscribe" style={{ width: '100%', height: 'auto', borderRadius: '10px' }} />
                    </Col>
                </Row>
            );
        }
    };

    const renderModalFooter = () => {
        if (!licenceResult) return null;

        const  numberOfSubscriptions  = licenceResult.numberOfSubscriptions;
        const  numberOfUsedSubscriptions = licenceResult.numberOfUsedSubscriptions;
        const  graceLimit = licenceResult.grace;
        const  remaining = (graceLimit + numberOfSubscriptions) - numberOfUsedSubscriptions;
        const  extra = remaining-(props.addCount??1)

        const showAdd = extra>=0 ? true:false

       if (showAdd) {
            return (
                <>
                    <Button color="secondary" onClick={() => { props.handelClose(); }}>
                        {props.t("Close")}
                    </Button>
                    {timer> 0 ?<Button color="primary" onClick={() => { props.handelAdd(); }}>
                        {props.t("Add")+"("+timer+")"}
                    </Button>:
                        <Button color="warning" onClick={() => { checkLicence(); }}>
                             {props.t("Retry")}
                        </Button>
                    }
                    <Button color="primary" onClick={() => { props.handelClose(); handelEnquireNow(); }}>
                        {props.t("Enquire Now")}
                    </Button>
                </>
            );
        } else {
            return (
                <>
                    <Button color="secondary" onClick={() => { props.handelClose(); }}>
                        {props.t("Close")}
                    </Button>
                    <Button color="primary" onClick={() => { props.handelClose(); handelEnquireNow(); }}>
                        {props.t("Enquire Now")}
                    </Button>
                </>
            );
        }
    };

    return (
        <React.Fragment>
            <Modal isOpen={props.showopup} size='lg' toggle={() => { props.handelClose(); }}>
                <ModalHeader toggle={() => { props.handelClose(); }}>
                    {props.t("Alert!!")}
                </ModalHeader>
                <ModalBody>
                    {renderModalBody()}
                </ModalBody>
                <ModalFooter>
                    {renderModalFooter()}
                </ModalFooter>
            </Modal>
            
        </React.Fragment>
    );
}

const mapStatetoProps = state => {
    const { selectedSchool } = state.School;
    return { selectedSchool };
}

export default withNamespaces()(withRouter(connect(mapStatetoProps, { setSelecetdSchool, showPricingPopup })(Licence)));