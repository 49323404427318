import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Card, CardBody, CardImg, CardText, CardTitle, Media, Nav, NavItem, NavLink, TabContent, TabPane, Button, Form, FormGroup, Label, Input, Progress, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import classnames from 'classnames';
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Parse from 'parse';
import Moment from 'moment';
import { matchPath } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import TopBar from '../Inventory/Topbar';
import DocumentViewerModal from '../Messaging/DocumentViewerModal'
import { setSelecetdSchool, getUserTeacher } from '../../store/actions';
import Iframe from "react-iframe";

function blog(props) {

    const match = matchPath(props.history.location.pathname, {
        path: "/blog/:schoolId/:id",
        exact: true,
        strict: false,
    });

    const frachiseMatch = matchPath(props.history.location.pathname, {
        path: "/franchise-blog/:franchiseId/:id",
        exact: true,
        strict: false,
    });

    const _imgExtensions = [".jpg", ".jpeg", ".png", "jpg", "jpeg", "png"];
    const _pdfExtensions = [".pdf", "pdf"];
    const _audioExtensions = [".mp3", "mp3", ".wma", "wma", ".acc", "acc", ".wav", "wav", ".flac", "flac",];

    const schoolId = match && match.params.schoolId;
    const franchiseId = frachiseMatch && frachiseMatch.params.franchiseId;
    const id = (frachiseMatch ?? match).params.id;


    const [mediaLink, setMediaLink] = useState([])
    const [showDeleteBlog, setShowDeleteBlog] = useState(false);
    const [deleteAlertBoxMessage, setDeleteAlertBoxMessage] = useState(false)
    const [showModel, setShowModel] = useState(false);
    const [clickedFile, setClickFile] = useState(undefined);

    useEffect(() => {
        if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
            props.setSelecetdSchool(schoolId);
        }
    }, [props.selectedSchool, schoolId]);

    useEffect(() => {
        if (Parse.User.current().attributes.teacherId) {
            props.getUserTeacher(Parse.User.current().attributes.teacherId)
        }
    }, [])

    useEffect(() => {

        let MediaLink = Parse.Object.extend("MediaLink");
        let mediaLinkQuery = new Parse.Query(MediaLink);

        mediaLinkQuery.equalTo('objectId', id);
        mediaLinkQuery.descending('createdAt');
        mediaLinkQuery.notEqualTo("isDeleted", true)
        mediaLinkQuery.first().then(
            (result) => {
                // console.log(result);
                setMediaLink(result)
            },
            (error) => {
                // (error);
            }
        );

    }, [])



    function getDateTimeStringForDate(date) {
        return Moment(date).format('DD-MMM-YYYY hh:mm a');
    }

    const handleDeleteBlog = () => {

        mediaLink.set("isDeleted", true);

        mediaLink.save().then(
            (result) => {
                // console.log(result)
                setShowDeleteBlog(false);
                window.history.back()
            },);
    }

    const checkDeleteConditions = () => {
        let user = props.userTeacher;
        let selectedSchool = props.selectedSchool;
        // console.log(mediaLink)

        if (mediaLink.attributes.selfDelete) {
            if (mediaLink.attributes.createdBy == user.id) {
                setShowDeleteBlog(true)
            } else {
                setDeleteAlertBoxMessage("You don't have permission to delete this blog")
                //don't have permission to delete
            }
        } else if (!mediaLink.attributes.targetAudienceId) {
            setDeleteAlertBoxMessage("You don't have permission to delete this blog")
            //Dont have permission to delete the card
        } else if (mediaLink.attributes.targetAudienceId == selectedSchool.id) {
            if (user.attributes.Role == 2 || user.attributes.Role == 3) {
                //Director and center head can delete
                setShowDeleteBlog(true)
            } else {
                setDeleteAlertBoxMessage("You don't have permission to delete this blog")
                //You Dont have permission to delete the card
            }
        } else if (mediaLink.attributes.targetAudienceId == selectedSchool.attributes.instituteId) {
            if (user.attributes.Role == 3) {
                setShowDeleteBlog(true)
                //Director can delete
            } else {
                setDeleteAlertBoxMessage("You don't have permission to delete this blog")
                //You Dont have permission to delete the card
            }
        } else if (mediaLink.attributes.targetAudienceId == selectedSchool.attributes.franchiseId) {
            if (user.attributes.Role > 10) {
                setShowDeleteBlog(true)
                //franchiser can delete
            } else {
                setDeleteAlertBoxMessage("You don't have permission to delete this blog")
                //You Dont have permission to delete the card
            }
        } else {
            setDeleteAlertBoxMessage("You don't have permission to delete this blog")
            //You Dont have permission to delete the card
        }
    }

    const getRandomColor = () => {
        const letters = "0123456789ABCDEF";
        let color = "#";
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };

    function getFileTypeImage(fileExtention) {

        if (fileExtention === "pdf") {
            return "bx bxs-file-pdf";
        } else if (fileExtention === "ppt" || fileExtention === "pptx") {
            return "bx bxs-file-doc";
        } else if (fileExtention === "xlsx") {
            return "bx bxs-file-doc";
        } else if (fileExtention === "doc" || fileExtention === "docx") {
            return "bx bxs-file-doc";
        } else if (fileExtention === "Video") {
            return "bx bx-play-circle";
        } else if (_audioExtensions && _audioExtensions.includes(fileExtention)) {
            return "bx bxs-music";
        }
        return "bx bxs-file-blank";

    }

    function getFileTypeFromUrl(url) {
        var res = url.split(".");
        var res = res[res.length - 1];
        if (_imgExtensions && _imgExtensions.includes(res)) {
            return { type: "image", ext: res };
        } else if (_audioExtensions.includes(res)) {
            return { type: "audio", ext: res };
        } else if (_pdfExtensions.includes(res)) {
            return { type: "pdf", ext: res };
        } else {
            return { type: "file", ext: res };
        }
    }

    function getURL() {

        if (clickedFile) {
            if (clickedFile.attributes.fileType === "url") {
                return clickedFile.attributes.url
            } else {
                return clickedFile.attributes.file._url;
            }
        }
    }

    function getFileName() {

        if (clickedFile) {
            if (clickedFile.attributes.fileType === "url") {
                return clickedFile.attributes.name
            } else {
                let res = clickedFile.attributes.file._name.split("_")
                return res[res.length - 1];
            }
        }
    }

    const isYouTubeUrl = mediaLink && mediaLink.attributes && mediaLink.attributes.url && mediaLink.attributes.url.includes('youtube.com');

    return (
        <div className='page-content'>
            {showDeleteBlog && (
                <SweetAlert
                    warning
                    showCancel
                    confirmBtnText="Delete"
                    cancelBtnText="Cancel"
                    confirmBtnBsStyle="danger"
                    cancelBtnBsStyle="success"
                    title="Alert!"
                    onCancel={() => {
                        setShowDeleteBlog(false);
                    }}
                    onConfirm={() => handleDeleteBlog()}
                >
                    {"Are you sure you want to delete"}
                    <br></br>
                </SweetAlert>
            )}
            {deleteAlertBoxMessage && <SweetAlert
                title="Alert"
                danger
                showConfirm={false}
            >
                {deleteAlertBoxMessage}
            </SweetAlert>}
            <DocumentViewerModal
                clickedFile={clickedFile}
                closeModal={() => {
                    setClickFile()
                }}
            ></DocumentViewerModal>
            <div className='ml-4'>
                <Row>
                    <Col xl='8'>
                        <Card>
                            <Row >
                                <Col lg={2} className='d-flex justify-content-center'>
                                    <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => { window.history.back() }}
                                    >
                                        <ul className="ml-4 list-unstyled">
                                            <div className='py-3'>
                                                <i className="bx bx-arrow-back h3 text-primary"></i>
                                            </div>
                                        </ul>
                                    </div>
                                </Col>
                                <Col lg={8} className="text-center">
                                    <h2 className="mt-2 text-center">{mediaLink && mediaLink.attributes && mediaLink.attributes.title}</h2>
                                </Col>
                                <Col lg={2} className='d-flex justify-content-center'>
                                    <div className='float-right py-3'>
                                        <i className="bx bxs-trash  text-danger font-size-16"
                                            onClick={() => {
                                                checkDeleteConditions()
                                                // setShowDeleteCard(true)
                                            }}
                                            style={{ cursor: 'pointer' }}></i>
                                    </div>
                                </Col>
                            </Row>
                            {/* <CardTitle className="mt-4 text-center py-2"><h2>{mediaLink && mediaLink.attributes && mediaLink.attributes.title}</h2></CardTitle> */}
                            <hr style={{ backgroundColor: 'black', height: '1px', margin: '0' }} />
                            {mediaLink && mediaLink.attributes && mediaLink.attributes.previewImage && (
                                <div className='py-2' style={{ display: 'flex', justifyContent: 'center', }}>
                                    <div
                                        style={{
                                            borderRadius: '15px',
                                            height: "400px",
                                            width: "600px",
                                            overflow: "hidden",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundImage: `url('${mediaLink.attributes.previewImage._url}')`, // Remove extra parentheses
                                            backgroundSize: "cover",
                                            backgroundPosition: "center",
                                        }}
                                        onClick={() => {
                                            if (mediaLink && mediaLink.attributes && mediaLink.attributes.previewImage) {
                                                setClickFile(mediaLink.attributes.previewImage)
                                            }
                                        }}
                                    />
                                </div>
                            )}
                            {mediaLink && mediaLink.attributes && mediaLink.attributes.file && <Col className='float-end'>
                                <Media className="border mt-2 p-2"
                                    style={{ cursor: "pointer", }}
                                    onClick={() => {
                                        if (mediaLink && mediaLink.attributes && mediaLink.attributes.file) {
                                            setClickFile(mediaLink.attributes.file)
                                        }
                                        setShowModel(true);
                                    }}
                                >
                                    <Row style={{ paddingRight: '2cm', paddingLeft: '2cm' }}>
                                        <Col lg={2}>
                                            <div className="avatar-md mr-4" >
                                                <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                                                    <div style={{ fontSize: "48px", cursor: "pointer" }}><i className={getFileTypeImage(getFileTypeFromUrl(mediaLink && mediaLink.attributes && mediaLink.attributes.file._url).ext)}></i></div>
                                                </span>
                                            </div>
                                        </Col>
                                        <Col>
                                            <Media className="overflow-hidden" body >

                                                <div style={{ cursor: "pointer" }}>
                                                    <h5 className="text-truncate font-size-15  mt-2 text-dark">{mediaLink &&
                                                        mediaLink.attributes &&
                                                        mediaLink.attributes.file._name &&
                                                        mediaLink.attributes.file._name.split('_')[1]}</h5>
                                                    <p className="text-muted ">{mediaLink && mediaLink.attributes && mediaLink.attributes.description}</p>
                                                </div>
                                                <Label className="text-primary" style={{ cursor: "pointer" }}><u>{props.t('Preview')}</u></Label>
                                            </Media>
                                        </Col>
                                    </Row>
                                </Media>
                            </Col>}
                            {mediaLink && mediaLink.attributes && mediaLink.attributes.url && (
                                <div className='text-center p-2'>
                                    {isYouTubeUrl ? (
                                        <iframe
                                            width="560"
                                            height="315"
                                            src={mediaLink.attributes.url.replace('watch?v=', 'embed/')}
                                            frameBorder="0"
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            allowFullScreen
                                            title="YouTube Video"
                                        />
                                    ) : (
                                        <a href={mediaLink && mediaLink.attributes && mediaLink.attributes.url} target='_blank' rel='noopener noreferrer'>
                                            {mediaLink && mediaLink.attributes && mediaLink.attributes.url}
                                        </a>
                                    )}
                                </div>

                            )}
                            < CardBody>
                                <Row>
                                    <Col>
                                        <CardText><h5 className='text-center' style={{ whiteSpace: 'pre-line', paddingRight: '2cm', paddingLeft: '2cm' }}>{mediaLink && mediaLink.attributes && mediaLink.attributes.description}</h5></CardText>
                                    </Col>
                                </Row>
                            </CardBody>
                        </Card>
                    </Col>
                </Row>
            </div>
        </div >
    )
}

const mapStatetoProps = state => {
    const { selectedSchool, schools } = state.School;
    const { userTeacher } = state.Login;
    return { selectedSchool, schools, userTeacher };
}

export default withRouter(connect(mapStatetoProps, { setSelecetdSchool, getUserTeacher })(withNamespaces()(blog)));
