import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Label,
  Card,
  Form,
  Button,
  CardBody,
} from "reactstrap";
import { Parse } from "parse";
import { withNamespaces } from "react-i18next";
import { withRouter, Link } from "react-router-dom";
import { connect } from "react-redux";
import { matchPath } from "react-router";
import SweetAlert from "react-bootstrap-sweetalert";
import { getCamerasForSchoolId, getTripsForSchoolId, getTeachersForSchool, saveCamera, deleteCamera, setSelecetdSchool, resetTrip } from "../../store/actions";
import * as Constants from "../../Constents";
import TripList from "./TripList";

const TripIndex = (props) => {

  const [displayList, setDisplayList] = useState([]);
  const [showDeleteAlert, setShowDeleteAlert] = useState(false);
  const [isLoading, setIsLoading] = useState(true);


  const match = matchPath(props.history.location.pathname, {
    path: "/triplist/:id",
    exact: true,
    strict: false,
  });

  const schoolId = match.params.id;
  useEffect(() => {
    if (!props.selectedSchool || props.selectedSchool.id !== schoolId) {
      props.setSelecetdSchool(schoolId);
    }
    props.getTeachersForSchool(schoolId);
    props.getTripsForSchoolId(schoolId)
  }, [props.selectedSchool]);

  useEffect(() => {
    if (props.trips) {
      setDisplayList(props.trips)
      setIsLoading(false)
    }
  }, [props.trips])

  const handelResetTrip = (trip) => {
    props.resetTrip(trip)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        {!isLoading && (
          <Container fluid>
            <Row className='d-flex align-items-start'>
              <Col className="d-flex align-items-start">
                <div className=' mb-3'>
                  <h4 className="m-0">Trips</h4>
                </div>
              </Col>
              <Col className='d-flex justify-content-end'>
                <div className="mt-4 mt-sm-0 d-flex justify-content-end mb-2" style={{ marginTop: '30px' }}>
                  <Link
                    className="btn btn-primary waves-effect waves-light "
                    to={"/tripAdd/" + schoolId}
                    role="button"
                  >
                    Add Trip
                  </Link>
                </div>
              </Col>
            </Row>
            {displayList.length === 0 && (
              <div className="text-center mt-0">
                <Card>
                  <CardBody>
                    <h5 className="p-5">No Trips to display !!</h5>
                  </CardBody>
                </Card>
              </div>
            )}
            {displayList.length > 0 && (
              <TripList trips={displayList} teachers={props.teachers ?? []} handelResetTrip={handelResetTrip} />
            )}
          </Container>
        )}
        {isLoading && (
          <Row>
            <Col xs="12">
              <div className="text-center my-3">
                <Link to="#" className="text-success">
                  <i className="bx bx-loader bx-spin font-size-18 align-middle mr-2"></i>{" "}
                  Loading Trips...{" "}
                </Link>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </React.Fragment>
  );
};

const mapStatetoProps = (state) => {
  const { cameras, resultCameraAfterSave } = state.Camera;
  const { selectedSchool } = state.School;
  const { trips } = state.Trips;
  const { teachers } = state.Teacher
  return { teachers, cameras, resultCameraAfterSave, selectedSchool, trips };
};

export default withNamespaces()(
  withRouter(
    connect(mapStatetoProps, { getTeachersForSchool, getTripsForSchoolId, getCamerasForSchoolId, saveCamera, deleteCamera, setSelecetdSchool, resetTrip })(
      TripIndex
    )
  )
);
