import React from "react";

import { Switch, BrowserRouter as Router, Route } from "react-router-dom";
import { connect } from "react-redux";

// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";

// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";

// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";

// Import scss
import "./assets/scss/theme.scss";

// Import Firebase Configuration file
//import { initFirebaseBackend } from "./helpers/firebase_helper";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { mixpanelInit } from "./components/Common/Analytics";

const firebaseConfig = {
  apiKey: "AIzaSyB9US0Aea64mcrnj6aAwJREhOAbat3jmZM",
  authDomain: "liltriangleweb.firebaseapp.com",
  databaseURL: "https://liltriangleweb.firebaseio.com",
  projectId: "liltriangleweb",
  storageBucket: "liltriangleweb.appspot.com",
  messagingSenderId: "724283861367",
  appId: "1:724283861367:web:0af3c05853a18961dfd972",
  measurementId: "G-47JGCHFMK4"
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

// import fakeBackend from "./helpers/AuthType/fakeBackend";
import * as Env from "./environments";
import Parse from "parse";
// Activating fake backend
//fakeBackend();

Parse.initialize(Env.APPLICATION_ID, Env.JAVASCRIPT_KEY);
Parse.serverURL = Env.SERVER_URL;
Parse.enableLocalDatastore();

mixpanelInit();

//DEVELOPMENT
// Parse.initialize(
//   process.env.REACT_APP_DEV_APPLICATION_ID,
//   process.env.REACT_APP_DEV_JAVASCRIPT_KEY
// );

//PRODUCTION
// Parse.initialize(process.env.REACT_APP_PROD_APPLICATION_ID, process.env.REACT_APP_PROD_JAVASCRIPT_KEY);
// Parse.serverURL = process.env.REACT_APP_SERVER_URL;

const App = (props) => {
  function getLayout() {
    let layoutCls = VerticalLayout;

    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();

  const NonAuthmiddleware = ({ component: Component, layout: Layout }) => (
    <Route
      render={(props) => {
        return (
          <Layout>
            <Component {...props} />
          </Layout>
        );
      }}
    />
  );

  return (
    <React.Fragment>
      <Router>
        <Switch>
          {authRoutes.map((route, idx) => (
            <NonAuthmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
            />
          ))}
        </Switch>
      </Router>
    </React.Fragment>
  );
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
