import React from 'react';
import { Link } from "react-router-dom";
import { Row, Col, Card, CardTitle, CardSubtitle, Table, CardBody, UncontrolledTooltip, Media, Badge } from "reactstrap";


const ListForProgram = (props) => {

  const isDeleteButtonHidden = (program) => {
    if (program.status && program.status.toLowerCase() === "upcoming") {
      return true
    }
    return false
  }

  console.log(props.programs)

  return (
    <React.Fragment>
      <Row>
        {

          <Card style={{ width: "100%" }}>
            <CardBody>


              <div className="table-responsive">
                <Table className="table-centered table-nowrap table-hover">
                  <thead className="thead-light">
                    <tr>
                      <th scope="col">{('Name')}</th>
                      <th scope="col">{'Batch'}</th>

                      <th scope="col">{'Type'}</th>
                      <th scope="col">{'Start Date'}</th>
                      <th scope="col">{'End Date'}</th>
                      <th scope="col" style={{ textAlign: "center" }}>{'Status'}</th>

                      <th scope="col" style={{ textAlign: "right" }}>{'Actions'}</th>
                    </tr>
                  </thead>
                  <tbody>{
                    props.programs.map((program, key) =>
                      <tr key={"_program_" + key}>
                        {/* <td>
                        < div className="avatar-md mr-4 text-center" >
                          <span className="avatar-title rounded-circle bg-light text-primary font-size-16">
                            <div style={{ fontSize: "25px" }}><i className="mr-2 bx bx-user"></i>{program.childrenCount}</div>
                          </span>
                        </div>
                      </td> */}

                        <td>
                          <Link to={"/programview/" + program.parseObjectGroup.attributes.schoolId + "/" + program.parseObjectProgram.id + "/" + program.parseObjectGroup.id}>
                            < h5 className="text-truncate font-size-15" > {program.programName}</h5></Link>
                          <p className='mb-0'>{"Participants : " + program.childrenCount ?? "0"}</p>
                          {program.parseObjectProgram.attributes.hasTerm && <p className='text-muted mt-0'>Includes terms</p>}
                        </td>

                        <td>
                          {program && program.programBatch && program.programBatch.map((option, key) => (
                            <div>
                              <Badge
                                key={key}
                                className="badge badge-soft-primary font-size-12 m-1"
                              >
                                {option.length > 20 ? option.slice(0, 19) + '...' : option}
                              </Badge>
                            </div>
                          ))}
                        </td>
                        <td>
                          <p className="text-muted mb-2">{program.programType}</p>
                        </td>
                        <td>
                          <li className="list-inline-item" id={"startDate" + key}>
                            <i className="bx bx-calendar mr-0"></i> {program.startDate}
                            <UncontrolledTooltip placement="top" target={"startDate" + key}>
                              Start Date
                            </UncontrolledTooltip>
                          </li>

                        </td>
                        <td>
                          <li className="list-inline-item" id={"endDate" + key}>
                            <i className="bx bx-calendar mr-1"></i> {program.endDate}
                            <UncontrolledTooltip placement="top" target={"endDate" + key}>
                              End Date
                            </UncontrolledTooltip>
                          </li>

                        </td>
                        <td style={{ textAlign: "center" }}>
                          <Badge color={program.color}>{program.status}</Badge>
                        </td>
                        <td style={{ textAlign: "center" }}>
                          <ul className="list-inline font-size-20 contact-links mb-0 float-right">
                            {/* {isDeleteButtonHidden(program) && <li className="list-inline-item px-2"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              props.programToDeleteButtonClicked(program)
                            }}
                            id={"deleteProgram" + key}
                          >
                            <i className="bx bx-trash text-danger"></i>
                            <UncontrolledTooltip placement="top" target={"deleteProgram" + key}>
                              Delete
                            </UncontrolledTooltip>

                          </li>

                          } */}
                            {/* {
                            <Link to={"/editprogram/" + program.parseObjectGroup.attributes.schoolId + "/" + program.parseObjectProgram.id + "/" + program.parseObjectGroup.id}>

                              <li className="list-inline-item px-2"
                                style={{ cursor: "pointer" }}

                                id={"sub-edit-program" + key}>
                                <i className="bx bx-edit-alt"></i>
                                <UncontrolledTooltip placement="top" target={"sub-edit-program" + key}>
                                  {'Edit'}
                                </UncontrolledTooltip>

                              </li></Link>} */}


                            <Link to={"/programview/" + program.parseObjectGroup.attributes.schoolId + "/" + program.parseObjectProgram.id + "/" + program.parseObjectGroup.id}>
                              <li className="list-inline-item px-2"
                                style={{ cursor: "pointer" }}

                                id={"viewDetailProgram" + key}>
                                <i className="bx bx-right-arrow-circle"></i>
                                <UncontrolledTooltip placement="top" target={"viewDetailProgram" + key}>
                                  {'Details'}
                                </UncontrolledTooltip>
                              </li></Link>
                          </ul>
                        </td>

                      </tr>
                    )
                  }
                  </tbody >
                </Table >
              </div >

            </CardBody >
          </Card >



        }
      </Row>
    </React.Fragment >
  );
}


export default ListForProgram;