import React, { Component, useEffect, useState } from "react";
import { Container, Row, Col, Table, Card, CardBody, Media, Label } from "reactstrap";
import Parse from "parse";
import { Link } from "react-router-dom";
import * as Constants from '../../../Constents';
import Moment from "moment"
import demoimage from "../../../assets/images/book_lover.svg";


const CoursesSubscribed = (props) => {

    const [myCourses, setMyCourses] = useState([]);
    const [initialLoading, setInitialLoading] = useState(false)

    useEffect(() => {

        if (props.courseSubscriptionsForUser && props.courseSubscriptionsForUser.length > 0 && props.allCourses && props.allCourses.length > 0) {
            let tempList = []

            for (var i = 0; i < props.courseSubscriptionsForUser.length; i++) {


                for (var j = 0; j < props.allCourses.length; j++) {
                    if (props.courseSubscriptionsForUser[i].attributes.courseId === props.allCourses[j].id && props.kidId === props.courseSubscriptionsForUser[i].attributes.kidId) {

                        let tempObject = {}
                        tempObject.title = props.allCourses[j].attributes.name ?? ""
                        tempObject.courseId = props.allCourses[j].id
                        tempObject.image = ""
                        if (props.allCourses[j].attributes.introImage) {
                            tempObject.image = props.allCourses[j].attributes.introImage

                        }
                        tempObject.createdAt = props.courseSubscriptionsForUser[i].createdAt
                        tempObject.mode = props.courseSubscriptionsForUser[i].attributes.mode ?? ""
                        tempObject.progress = props.courseSubscriptionsForUser[i].attributes.progress ? props.courseSubscriptionsForUser[i].attributes.progress + "%" : "0%"
                        tempList.push(tempObject)

                    }
                }
            }
            setMyCourses(tempList)
        }


    }, [props.courseSubscriptionsForUser, props.allCourses, props.courseRequests])

    const getDateInDDMMYYYY = (date) => {
        return Moment(date).format("DD/MM/YYYY");
    };


    return (
        <React.Fragment>
            <Container>
                <Row>



                    {myCourses !== null && myCourses.length > 0 && !initialLoading && (
                        <Col>
                            <Row>

                                <Container >
                                    <h5 className="mt-3 ml-1">Enrolled Courses</h5>

                                    <Row>
                                        <Col>
                                            <div className="table-responsive mt-1 bg-white shadow">
                                                <Table className="table-center">
                                                    <thead>
                                                        <tr>
                                                            <th > Courses</th>
                                                            <th className="text-center" >Enrolled On</th>
                                                            <th >Mode of Enrollment</th>
                                                            <th >Progress</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {myCourses.map((item, key) => (
                                                            <tr key={key}>

                                                                <td>
                                                                    <div className="d-flex align-items-center">
                                                                        <img
                                                                            src={item.image??demoimage}
                                                                            className="img-fluid avatar avatar-small rounded shadow"
                                                                            style={{ height: "80px", width: "80px" }}
                                                                            alt=""
                                                                        />

                                                                        <section>
                                                                            <h6 className="mb-0 ml-3">
                                                                                <Label
                                                                                    className="text-primary"
                                                                                >
                                                                                    {item.title}
                                                                                </Label>
                                                                            </h6>


                                                                        </section>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="mt-4 text-center"><h6>{getDateInDDMMYYYY(item.createdAt)}</h6></div>
                                                                </td>
                                                                <td>
                                                                    <div className="mt-4"><h6>{item.mode}</h6></div>

                                                                </td>
                                                                <td>
                                                                    <div className="mt-4"><h6>{item.progress}</h6></div>

                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </Table>
                                            </div>
                                        </Col>
                                    </Row>

                                </Container >
                            </Row>
                        </Col>
                    )}
                    {(!myCourses || myCourses.length == 0) && !initialLoading && (


                        <div className="ml-2">
                            <h6>No subscribed courses to show</h6>
                        </div>
                    )}

                    {/* coursebox */}
                </Row>

            </Container>
        </React.Fragment>
    );
}

export default CoursesSubscribed;



