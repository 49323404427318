import React,{useEffect} from 'react';
import { Container } from "reactstrap";

//Import Breadcrumb
import Breadcrumbs from '../../components/Common/Breadcrumb';

const ZoomAuth = (props) => {


    useEffect(() => {
        console.log("Path", window.location.href)
        let res = window.location.href.split("=");
        console.log("res", res)
        let zoom_authCode = res[1];
        console.log("ZOOM_AUTH_CODE", zoom_authCode)
        localStorage.setItem("ZOOM_AUTH_CODE", zoom_authCode);

    
    }, [window.location.pathname]);

    

        return (
            <React.Fragment>
                <div className="page-content">
                    <Container fluid>

                        {/* Render Breadcrumbs */}
                        <Breadcrumbs title="Zoom" breadcrumbItem="Starter Page" />

                        <p>Successfully connected to zoom</p>

                    </Container>
                </div>
            </React.Fragment>
              );
        }
            
export default ZoomAuth;